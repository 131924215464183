export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: string | number; output: string; }
  citext: { input: string; output: string; }
  date: { input: string; output: string; }
  interval: { input: string; output: string; }
  jsonb: { input: any; output: any; }
  numeric: { input: string | number; output: string; }
  timestamptz: { input: string; output: string; }
  tsvector: { input: string; output: string; }
};

export type AcceptCampaignOfferInput = {
  offerId: Scalars['Int']['input'];
  subscriberCampaignToken: Scalars['String']['input'];
};

export type AcceptCampaignOfferOutput = {
  __typename: 'AcceptCampaignOfferOutput';
  needsPaymentMethod: Scalars['Boolean']['output'];
  success: Scalars['Boolean']['output'];
};

export type AcceptOfferInput = {
  flowStepToken: Scalars['String']['input'];
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
  offerId: Scalars['Int']['input'];
  offerVariantId?: InputMaybe<Scalars['Int']['input']>;
  pauseAt?: InputMaybe<Scalars['String']['input']>;
  pauseReasonCode?: InputMaybe<Scalars['String']['input']>;
  platformVariantId?: InputMaybe<Scalars['String']['input']>;
  rescheduleTo?: InputMaybe<Scalars['String']['input']>;
  resumeAt?: InputMaybe<Scalars['String']['input']>;
  selectedOptionIndex: Scalars['Int']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type AcceptOfferOutput = {
  __typename: 'AcceptOfferOutput';
  success: Scalars['Boolean']['output'];
};

export type AcceptUserInvitationInput = {
  token: Scalars['String']['input'];
};

export type AcceptUserInvitationOutput = {
  __typename: 'AcceptUserInvitationOutput';
  success: Scalars['Boolean']['output'];
};

export type AccountSubscriptionOutput = {
  __typename: 'AccountSubscriptionOutput';
  cost?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['String']['output']>;
  intervalCount?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  trialExpiresAt?: Maybe<Scalars['String']['output']>;
};

export type AdminConfigureShopifyInput = {
  appId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  installLink: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type AdminConfigureShopifyOutput = {
  __typename: 'AdminConfigureShopifyOutput';
  success: Scalars['Boolean']['output'];
};

export type AdminCreateAccountInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  features: Scalars['jsonb']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  requirePaymentMethod: Scalars['Boolean']['input'];
};

export type AdminCreateAccountOutput = {
  __typename: 'AdminCreateAccountOutput';
  accountId?: Maybe<Scalars['Int']['output']>;
};

export type AdminUpdateAccountFeaturesInput = {
  accountId: Scalars['Int']['input'];
  features: Scalars['jsonb']['input'];
};

export type AdminUpdateAccountFeaturesOutput = {
  __typename: 'AdminUpdateAccountFeaturesOutput';
  success: Scalars['Boolean']['output'];
};

export type AdminUpdateAccountSettingsInput = {
  accountId: Scalars['Int']['input'];
  settings: Scalars['jsonb']['input'];
};

export type AdminUpdateAccountSettingsOutput = {
  __typename: 'AdminUpdateAccountSettingsOutput';
  success: Scalars['Boolean']['output'];
};

export type AttachPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
};

export type AttachPaymentMethodOutput = {
  __typename: 'AttachPaymentMethodOutput';
  success: Scalars['Boolean']['output'];
};

export type AvailableNavigaDomainsInput = {
  platformConnectionId: Scalars['Int']['input'];
};

export type AvailableNavigaDomainsOutput = {
  __typename: 'AvailableNavigaDomainsOutput';
  domains: Array<NavigaDomain>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_comparison_exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CancelSubscriptionOutput = {
  __typename: 'CancelSubscriptionOutput';
  success: Scalars['Boolean']['output'];
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ChangePasswordOutput = {
  __typename: 'ChangePasswordOutput';
  success: Scalars['Boolean']['output'];
};

export type ClickFlowActionInput = {
  deflectionId: Scalars['Int']['input'];
  flowActionId: Scalars['Int']['input'];
  flowStepToken: Scalars['String']['input'];
  subscriberFlowToken: Scalars['String']['input'];
  testMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClickFlowActionOutput = {
  __typename: 'ClickFlowActionOutput';
  newSubscriberFlowToken?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CompleteFlowStepInput = {
  flowStepToken: Scalars['String']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type CompleteFlowStepOutput = {
  __typename: 'CompleteFlowStepOutput';
  success: Scalars['Boolean']['output'];
};

export type CompleteSignUpOutput = {
  __typename: 'CompleteSignUpOutput';
  success: Scalars['Boolean']['output'];
};

export type CompleteSubscriberCampaignViewInput = {
  status: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CompleteSubscriberCampaignViewOutput = {
  __typename: 'CompleteSubscriberCampaignViewOutput';
  success: Scalars['Boolean']['output'];
};

export type CompleteSubscriberFlowInput = {
  status: CompleteSubscriberFlowStatus;
  token: Scalars['String']['input'];
};

export type CompleteSubscriberFlowOutput = {
  __typename: 'CompleteSubscriberFlowOutput';
  flowSession: Scalars['String']['output'];
};

export enum CompleteSubscriberFlowStatus {
  canceled = 'canceled',
  deflected = 'deflected',
  incomplete = 'incomplete',
  saved = 'saved'
}

export type ConnectBoldInput = {
  code: Scalars['String']['input'];
};

export type ConnectBoldOutput = {
  __typename: 'ConnectBoldOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectBraintreeInput = {
  merchantId: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

export type ConnectBraintreeOutput = {
  __typename: 'ConnectBraintreeOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectChargebeeInput = {
  apiKey: Scalars['String']['input'];
  publishableKey: Scalars['String']['input'];
  site: Scalars['String']['input'];
};

export type ConnectChargebeeOutput = {
  __typename: 'ConnectChargebeeOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectHubSpotInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type ConnectHubSpotOutput = {
  __typename: 'ConnectHubSpotOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectIntercomInput = {
  code: Scalars['String']['input'];
};

export type ConnectIntercomOutput = {
  __typename: 'ConnectIntercomOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectKlaviyoInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type ConnectKlaviyoOutput = {
  __typename: 'ConnectKlaviyoOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectMaxioInput = {
  apiKey: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
};

export type ConnectMaxioOutput = {
  __typename: 'ConnectMaxioOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectNavigaInput = {
  billingEndpoint?: InputMaybe<Scalars['String']['input']>;
  endpoint: Scalars['String']['input'];
  mediaGroupCode: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ConnectNavigaOutput = {
  __typename: 'ConnectNavigaOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectOpenPayInput = {
  apiToken: Scalars['String']['input'];
  endpoint: Scalars['String']['input'];
};

export type ConnectOpenPayOutput = {
  __typename: 'ConnectOpenPayOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectOrdergrooveInput = {
  apiKey: Scalars['String']['input'];
};

export type ConnectOrdergrooveOutput = {
  __typename: 'ConnectOrdergrooveOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectPaddleInput = {
  authCode: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type ConnectPaddleOutput = {
  __typename: 'ConnectPaddleOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectRechargeInput = {
  apiToken: Scalars['String']['input'];
};

export type ConnectRechargeOutput = {
  __typename: 'ConnectRechargeOutput';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ConnectRecurlyInput = {
  apiKey: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

export type ConnectRecurlyOutput = {
  __typename: 'ConnectRecurlyOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectSalesforceInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  useSandbox: Scalars['Boolean']['input'];
};

export type ConnectSalesforceOutput = {
  __typename: 'ConnectSalesforceOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectSegmentInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type ConnectSegmentOutput = {
  __typename: 'ConnectSegmentOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectShopifyInput = {
  code: Scalars['String']['input'];
  hmac: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
  shop: Scalars['String']['input'];
};

export type ConnectShopifyOutput = {
  __typename: 'ConnectShopifyOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectSlackInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type ConnectSlackOutput = {
  __typename: 'ConnectSlackOutput';
  integration?: Maybe<integration>;
  integrationId?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ConnectStripeApiKeyInput = {
  apiKey: Scalars['String']['input'];
  isReconnect: Scalars['Boolean']['input'];
  publishableKey: Scalars['String']['input'];
};

export type ConnectStripeApiKeyOutput = {
  __typename: 'ConnectStripeApiKeyOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectStripeAppInput = {
  stripeAccountId: Scalars['String']['input'];
  stripeMode: Scalars['String']['input'];
  stripeUserId: Scalars['String']['input'];
};

export type ConnectStripeAppOutput = {
  __typename: 'ConnectStripeAppOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectStripeInput = {
  code: Scalars['String']['input'];
  testMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectStripeOutput = {
  __typename: 'ConnectStripeOutput';
  success: Scalars['Boolean']['output'];
};

export type ConnectZuoraInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type ConnectZuoraOutput = {
  __typename: 'ConnectZuoraOutput';
  success: Scalars['Boolean']['output'];
};

export type CreateAccountInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAccountOutput = {
  __typename: 'CreateAccountOutput';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateApiKeyInput = {
  name: Scalars['String']['input'];
};

export type CreateApiKeyOutput = {
  __typename: 'CreateApiKeyOutput';
  apiKey?: Maybe<api_key>;
  apiKeyId: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type CreateCampaignInput = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  default_language?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['String']['input']>;
  is_default: Scalars['Boolean']['input'];
  offer_rule_group_id: Scalars['Int']['input'];
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trigger_type: Scalars['String']['input'];
  trigger_url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCampaignOutput = {
  __typename: 'CreateCampaignOutput';
  campaign?: Maybe<campaign>;
  campaignId: Scalars['Int']['output'];
};

export type CreateDeflectionInput = {
  contentFormat: Scalars['String']['input'];
  contentTranslations: Scalars['jsonb']['input'];
  flowActions: Array<InputMaybe<FlowActionInput>>;
  headingFormat: Scalars['String']['input'];
  headingTranslations: Scalars['jsonb']['input'];
  minimumItems?: InputMaybe<Scalars['Int']['input']>;
  snapshotItems: Array<InputMaybe<DeflectionSnapshotItemInput>>;
  tagIds: Array<InputMaybe<Scalars['Int']['input']>>;
  title: Scalars['String']['input'];
};

export type CreateDeflectionOutput = {
  __typename: 'CreateDeflectionOutput';
  deflection?: Maybe<deflection>;
  deflectionId: Scalars['Int']['output'];
};

export type CreateFlowInput = {
  title: Scalars['String']['input'];
  useNewOnCancelDefault?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateFlowOutput = {
  __typename: 'CreateFlowOutput';
  flow?: Maybe<flow>;
  flowId: Scalars['Int']['output'];
};

export type CreateKlaviyoCodeChallengeOutput = {
  __typename: 'CreateKlaviyoCodeChallengeOutput';
  codeChallenge: Scalars['String']['output'];
};

export type CreatePortalSessionStripeSetupIntentInput = {
  portalSessionToken: Scalars['String']['input'];
};

export type CreatePortalSessionStripeSetupIntentOutput = {
  __typename: 'CreatePortalSessionStripeSetupIntentOutput';
  clientSecret: Scalars['String']['output'];
};

export type CreateSegmentGroupInput = {
  andSegmentIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  notSegmentIds: Array<Scalars['Int']['input']>;
  orSegmentIds: Array<Scalars['Int']['input']>;
};

export type CreateSegmentGroupOutput = {
  __typename: 'CreateSegmentGroupOutput';
  segmentGroup?: Maybe<segment_group>;
  segmentGroupId: Scalars['Int']['output'];
};

export type CreateStripePortalSessionOutput = {
  __typename: 'CreateStripePortalSessionOutput';
  url: Scalars['String']['output'];
};

export type CreateSubscriberCampaignStripeSetupIntentInput = {
  subscriberCampaignToken: Scalars['String']['input'];
};

export type CreateSubscriberCampaignStripeSetupIntentOutput = {
  __typename: 'CreateSubscriberCampaignStripeSetupIntentOutput';
  clientSecret: Scalars['String']['output'];
};

export type DeclareOfferAutopilotRoundWinnerInput = {
  offerAutopilotRoundId: Scalars['Int']['input'];
  offerVariantId: Scalars['Int']['input'];
};

export type DeclareOfferAutopilotRoundWinnerOutput = {
  __typename: 'DeclareOfferAutopilotRoundWinnerOutput';
  success: Scalars['Boolean']['output'];
};

export type DeclineOfferGroupInput = {
  flowStepToken: Scalars['String']['input'];
  offerGroupId: Scalars['Int']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type DeclineOfferGroupOutput = {
  __typename: 'DeclineOfferGroupOutput';
  success: Scalars['Boolean']['output'];
};

export type DeclineOfferInput = {
  offerId: Scalars['Int']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type DeclineOfferOutput = {
  __typename: 'DeclineOfferOutput';
  success: Scalars['Boolean']['output'];
};

export type DeflectionSnapshotItemInput = {
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconPrefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  propertyConditionValue?: InputMaybe<Scalars['jsonb']['input']>;
  propertyId?: InputMaybe<Scalars['Int']['input']>;
  textFormat: Scalars['String']['input'];
  textTranslations: Scalars['jsonb']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type DisconnectSegmentSourceInput = {
  installName: Scalars['String']['input'];
};

export type DisconnectSegmentSourceOutput = {
  __typename: 'DisconnectSegmentSourceOutput';
  success: Scalars['Boolean']['output'];
};

export type DomainRouteInput = {
  domain: Scalars['String']['input'];
};

export type DomainRouteOutput = {
  __typename: 'DomainRouteOutput';
  clientId?: Maybe<Scalars['String']['output']>;
};

export type DuplicateFlowInput = {
  flowId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type DuplicateFlowOutput = {
  __typename: 'DuplicateFlowOutput';
  flow?: Maybe<flow>;
  flowId: Scalars['Int']['output'];
};

export type EvaluateOfferAutopilotRoundInput = {
  offerAutopilotRoundId: Scalars['Int']['input'];
};

export type EvaluateOfferAutopilotRoundOutput = {
  __typename: 'EvaluateOfferAutopilotRoundOutput';
  result: Array<Maybe<OfferAutopilotRoundVariantProbability>>;
};

export enum ExportCancellationsFormat {
  CSV = 'CSV'
}

export type ExportCancellationsInput = {
  format: ExportCancellationsFormat;
};

export type ExportCancellationsOutput = {
  __typename: 'ExportCancellationsOutput';
  url: Scalars['String']['output'];
};

export enum ExportSessionsFormat {
  CSV = 'CSV'
}

export type ExportSessionsInput = {
  format: ExportSessionsFormat;
};

export enum ExportSessionsNewFormat {
  CSV = 'CSV'
}

export type ExportSessionsNewInput = {
  format: ExportSessionsNewFormat;
  search?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['jsonb']['input']>;
};

export type ExportSessionsNewOutput = {
  __typename: 'ExportSessionsNewOutput';
  token: Scalars['String']['output'];
};

export type ExportSessionsOutput = {
  __typename: 'ExportSessionsOutput';
  url: Scalars['String']['output'];
};

export type FlowActionInput = {
  appearance: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rerouteToFlowId?: InputMaybe<Scalars['Int']['input']>;
  textFormat: Scalars['String']['input'];
  textTranslations: Scalars['jsonb']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FlowOfferGroupInput = {
  answersData: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  flowVersion: FlowVersion;
  previewPropertyValues?: InputMaybe<Scalars['jsonb']['input']>;
  previewSegmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  skipOffers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FlowOfferGroupOutput = {
  __typename: 'FlowOfferGroupOutput';
  offer?: Maybe<offer>;
  offerId?: Maybe<Scalars['Int']['output']>;
};

export type FlowTestOfferInput = {
  answersData: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  flowVersion: FlowVersion;
  previewPropertyValues?: InputMaybe<Scalars['jsonb']['input']>;
  previewSegmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  skipOffers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FlowTestOfferOutput = {
  __typename: 'FlowTestOfferOutput';
  flowOffer?: Maybe<Scalars['jsonb']['output']>;
  flowOfferGroup?: Maybe<Scalars['jsonb']['output']>;
  nextOrderDate?: Maybe<Scalars['String']['output']>;
  offer?: Maybe<offer>;
  offerGroup?: Maybe<offer_group>;
  offerGroupId?: Maybe<Scalars['Int']['output']>;
  offerId?: Maybe<Scalars['Int']['output']>;
  offer_group?: Maybe<offer_group>;
  pauseReasons?: Maybe<Array<PauseReason>>;
  platformConnectionId?: Maybe<Scalars['Int']['output']>;
  platform_connection?: Maybe<platform_connection>;
};

export enum FlowVersion {
  draft = 'draft',
  published = 'published'
}

export type FormQuestionAnswer = {
  questionId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type HubSpotPropertiesInput = {
  objectType: Scalars['String']['input'];
};

export type HubSpotProperty = {
  __typename: 'HubSpotProperty';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ImportNavigaCsvInput = {
  key: Scalars['String']['input'];
};

export type ImportNavigaCsvOutput = {
  __typename: 'ImportNavigaCsvOutput';
  jobId: Scalars['String']['output'];
};

export type InitializeSubscriberFlowInput = {
  flowToken: Scalars['String']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
};

export type InitializeSubscriberFlowOutput = {
  __typename: 'InitializeSubscriberFlowOutput';
  token: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_comparison_exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntercomDataAttribute = {
  __typename: 'IntercomDataAttribute';
  description: Scalars['String']['output'];
  isCustom: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type InviteUserOutput = {
  __typename: 'InviteUserOutput';
  success: Scalars['Boolean']['output'];
};

export type LogOfferGroupPresentedInput = {
  offerGroupId: Scalars['Int']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type LogOfferGroupPresentedOutput = {
  __typename: 'LogOfferGroupPresentedOutput';
  success: Scalars['Boolean']['output'];
};

export type LogOfferPresentedInput = {
  offerId: Scalars['Int']['input'];
  offerVariantId?: InputMaybe<Scalars['Int']['input']>;
  subscriberFlowToken: Scalars['String']['input'];
};

export type LogOfferPresentedOutput = {
  __typename: 'LogOfferPresentedOutput';
  success: Scalars['Boolean']['output'];
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
};

export type LoginUserOutput = {
  __typename: 'LoginUserOutput';
  accountMode?: Maybe<Scalars['String']['output']>;
  accountStatus?: Maybe<Scalars['String']['output']>;
  refresh: Scalars['Boolean']['output'];
  success: Scalars['Boolean']['output'];
};

export type NavigaDomain = {
  __typename: 'NavigaDomain';
  clientCode: Scalars['String']['output'];
  defaultPaperCode: Scalars['String']['output'];
  domain: Scalars['String']['output'];
};

export type NeedStripeReconnectOutput = {
  __typename: 'NeedStripeReconnectOutput';
  needReconnect: Scalars['Boolean']['output'];
};

export type OfferAutopilotRoundVariantProbability = {
  __typename: 'OfferAutopilotRoundVariantProbability';
  probability: Scalars['Float']['output'];
  variantId: Scalars['Int']['output'];
};

export type PauseReason = {
  __typename: 'PauseReason';
  code: Scalars['String']['output'];
  maximumDays?: Maybe<Scalars['Int']['output']>;
  maximumStartDate?: Maybe<Scalars['String']['output']>;
  minimumStartDate?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

export type PaymentMethodOutput = {
  __typename: 'PaymentMethodOutput';
  brand?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expireMonth?: Maybe<Scalars['Int']['output']>;
  expireYear?: Maybe<Scalars['Int']['output']>;
  lastFour?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>;
};

export type PlatformCustomerSubscription = {
  __typename: 'PlatformCustomerSubscription';
  id: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
  product: Scalars['String']['output'];
};

export type PlatformCustomerSubscriptionsInput = {
  paperCode?: InputMaybe<Scalars['String']['input']>;
  platformCustomerId: Scalars['String']['input'];
};

export type PlatformCustomerSubscriptionsOutput = {
  __typename: 'PlatformCustomerSubscriptionsOutput';
  subscriptions: Array<PlatformCustomerSubscription>;
};

export type PortalCustomerBillingDetailsInput = {
  platformCustomerId: Scalars['String']['input'];
  portalSessionToken: Scalars['String']['input'];
};

export type PortalCustomerBillingDetailsOutput = {
  __typename: 'PortalCustomerBillingDetailsOutput';
  address?: Maybe<PortalCustomerDetailsAddress>;
  card?: Maybe<PortalCustomerDetailsCard>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PortalCustomerDetailsAddress = {
  __typename: 'PortalCustomerDetailsAddress';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type PortalCustomerDetailsCard = {
  __typename: 'PortalCustomerDetailsCard';
  brand: Scalars['String']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
};

export type PortalDownloadInvoiceInput = {
  platformInvoiceId: Scalars['String']['input'];
  portalSessionToken: Scalars['String']['input'];
};

export type PortalDownloadInvoiceOutput = {
  __typename: 'PortalDownloadInvoiceOutput';
  url: Scalars['String']['output'];
};

export type PortalSessionPaymentInfoInput = {
  portalSessionToken: Scalars['String']['input'];
};

export type PortalSessionPaymentInfoOutput = {
  __typename: 'PortalSessionPaymentInfoOutput';
  addressRequirements?: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  platformId: Scalars['String']['output'];
  publishableKey: Scalars['String']['output'];
};

export type PortalSubscriptionDetailsInput = {
  platformSubscriptionId: Scalars['String']['input'];
  portalSessionToken: Scalars['String']['input'];
};

export type PortalSubscriptionDetailsInvoice = {
  __typename: 'PortalSubscriptionDetailsInvoice';
  amountPaid: Scalars['String']['output'];
  number: Scalars['String']['output'];
  periodEnd: Scalars['String']['output'];
  platformId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  total: Scalars['String']['output'];
};

export type PortalSubscriptionDetailsOutput = {
  __typename: 'PortalSubscriptionDetailsOutput';
  amount: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  interval: Scalars['String']['output'];
  intervalCount: Scalars['Int']['output'];
  invoices: Array<PortalSubscriptionDetailsInvoice>;
  name: Scalars['String']['output'];
  nextBillingAt?: Maybe<Scalars['String']['output']>;
  platformId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  trialEndsAt?: Maybe<Scalars['String']['output']>;
};

export type PricingPlanOutput = {
  __typename: 'PricingPlanOutput';
  monthlyCost: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PropertySegmentMatchesInput = {
  propertyValues: Scalars['jsonb']['input'];
};

export type PropertySegmentMatchesOutput = {
  __typename: 'PropertySegmentMatchesOutput';
  segmentIds: Array<Scalars['Int']['output']>;
};

export type PublishFlowInput = {
  flowId: Scalars['Int']['input'];
};

export type PublishFlowOutput = {
  __typename: 'PublishFlowOutput';
  flow?: Maybe<flow>;
  flowId: Scalars['Int']['output'];
};

export type RecurlyCustomField = {
  __typename: 'RecurlyCustomField';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  resourceType: Scalars['String']['output'];
};

export type RegenerateOfferVariantInput = {
  offerAutopilotRoundId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerVariantId: Scalars['Int']['input'];
};

export type RegenerateOfferVariantOutput = {
  __typename: 'RegenerateOfferVariantOutput';
  success: Scalars['Boolean']['output'];
};

export type RemoveAccountUserInput = {
  userId: Scalars['String']['input'];
};

export type RemoveAccountUserOutput = {
  __typename: 'RemoveAccountUserOutput';
  success: Scalars['Boolean']['output'];
};

export type RequestShopifyInstallInput = {
  hmac: Scalars['String']['input'];
  message: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type RequestShopifyInstallOutput = {
  __typename: 'RequestShopifyInstallOutput';
  needsInstall: Scalars['Boolean']['output'];
  success: Scalars['Boolean']['output'];
};

export type RoiCalculatorInput = {
  annualRevenue: Scalars['Int']['input'];
  cancellationsPerMonth: Scalars['Int']['input'];
  customers: Scalars['Int']['input'];
  newCustomersPerMonth: Scalars['Int']['input'];
  savePercent: Scalars['Int']['input'];
  sixMonthRetentionRate: Scalars['Int']['input'];
  twelveMonthRetentionRate: Scalars['Int']['input'];
};

export type RoiCalculatorOutput = {
  __typename: 'RoiCalculatorOutput';
  result: Scalars['String']['output'];
};

export type RunScriptInput = {
  args?: InputMaybe<Scalars['String']['input']>;
  script: Scalars['String']['input'];
};

export type RunScriptOutput = {
  __typename: 'RunScriptOutput';
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type SalesforceField = {
  __typename: 'SalesforceField';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SearchPlatformCustomersInput = {
  paperCode?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
  searchBy?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPlatformCustomersOutput = {
  __typename: 'SearchPlatformCustomersOutput';
  customers: Array<SearchPlatformCustomersResult>;
};

export type SearchPlatformCustomersResult = {
  __typename: 'SearchPlatformCustomersResult';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SegmentMatchInput = {
  accountId: Scalars['Int']['input'];
  segmentId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
};

export type SegmentMatchOutput = {
  __typename: 'SegmentMatchOutput';
  isMatch: Scalars['Boolean']['output'];
};

export type SendEmailVerificationInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type SendEmailVerificationOutput = {
  __typename: 'SendEmailVerificationOutput';
  rateLimited?: Maybe<Scalars['Boolean']['output']>;
  subscriberFound: Scalars['Boolean']['output'];
};

export type SetPortalSessionPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
  portalSessionToken: Scalars['String']['input'];
};

export type SetPortalSessionPaymentMethodOutput = {
  __typename: 'SetPortalSessionPaymentMethodOutput';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SetSubscriberCampaignPaymentMethodInput = {
  paymentMethodId: Scalars['String']['input'];
  subscriberCampaignToken: Scalars['String']['input'];
};

export type SetSubscriberCampaignPaymentMethodOutput = {
  __typename: 'SetSubscriberCampaignPaymentMethodOutput';
  errorMessage?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SignCustomerPortalFlowPayloadInput = {
  payload: Scalars['String']['input'];
  portalSessionToken: Scalars['String']['input'];
};

export type SignCustomerPortalFlowPayloadOutput = {
  __typename: 'SignCustomerPortalFlowPayloadOutput';
  signature: Scalars['String']['output'];
};

export type SignFlowPayloadInput = {
  payload: Scalars['String']['input'];
};

export type SignFlowPayloadOutput = {
  __typename: 'SignFlowPayloadOutput';
  signature: Scalars['String']['output'];
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignUpOutput = {
  __typename: 'SignUpOutput';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SkipConnectOutput = {
  __typename: 'SkipConnectOutput';
  success: Scalars['Boolean']['output'];
};

export type SsoLogInDetailsInput = {
  email: Scalars['String']['input'];
};

export type SsoLogInDetailsOutput = {
  __typename: 'SsoLogInDetailsOutput';
  connection?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type StartFlowStepInput = {
  deflectionId?: InputMaybe<Scalars['Int']['input']>;
  flowStepToken: Scalars['String']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type StartFlowStepOutput = {
  __typename: 'StartFlowStepOutput';
  success: Scalars['Boolean']['output'];
};

export type StartSubscriberCampaignViewInput = {
  offerId?: InputMaybe<Scalars['Int']['input']>;
  subscriberCampaignToken: Scalars['String']['input'];
};

export type StartSubscriberCampaignViewOutput = {
  __typename: 'StartSubscriberCampaignViewOutput';
  token: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_comparison_exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitCustomSubscriberDetailsInput = {
  answers: Scalars['jsonb']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subscriberFlowToken: Scalars['String']['input'];
};

export type SubmitCustomSubscriberDetailsOutput = {
  __typename: 'SubmitCustomSubscriberDetailsOutput';
  message?: Maybe<Scalars['String']['output']>;
  prevented?: Maybe<Scalars['Boolean']['output']>;
  rerouted: Scalars['Boolean']['output'];
  subscriberFlowId?: Maybe<Scalars['Int']['output']>;
  subscriber_flow?: Maybe<subscriber_flow>;
  success: Scalars['Boolean']['output'];
};

export type SubmitFormAnswersInput = {
  flowStepToken: Scalars['String']['input'];
  flowVersion: FlowVersion;
  questionAnswers: Array<FormQuestionAnswer>;
  subscriberFlowToken: Scalars['String']['input'];
};

export type SubmitFormAnswersOutput = {
  __typename: 'SubmitFormAnswersOutput';
  success: Scalars['Boolean']['output'];
};

export type SubmitQuestionAnswerInput = {
  flowStepToken: Scalars['String']['input'];
  flowVersion: FlowVersion;
  subscriberFlowToken: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SubmitQuestionAnswerOutput = {
  __typename: 'SubmitQuestionAnswerOutput';
  success: Scalars['Boolean']['output'];
};

export type SubscriberCampaignInput = {
  campaignToken?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  subscriber?: InputMaybe<SubscriberCampaignSubscriberInput>;
  subscription?: InputMaybe<SubscriberCampaignSubscriptionInput>;
};

export type SubscriberCampaignOfferInput = {
  subscriberCampaignToken: Scalars['String']['input'];
};

export type SubscriberCampaignOfferOutput = {
  __typename: 'SubscriberCampaignOfferOutput';
  expireAt?: Maybe<Scalars['timestamptz']['output']>;
  offer?: Maybe<offer>;
  offerId?: Maybe<Scalars['Int']['output']>;
};

export type SubscriberCampaignOutput = {
  __typename: 'SubscriberCampaignOutput';
  delay: Scalars['String']['output'];
  presentAfter?: Maybe<Scalars['timestamptz']['output']>;
  status: Scalars['String']['output'];
  token: Scalars['String']['output'];
  triggerType: Scalars['String']['output'];
  triggerUrl?: Maybe<Scalars['String']['output']>;
};

export type SubscriberCampaignPaymentInfoInput = {
  subscriberCampaignToken: Scalars['String']['input'];
};

export type SubscriberCampaignPaymentInfoOutput = {
  __typename: 'SubscriberCampaignPaymentInfoOutput';
  addressRequirements?: Maybe<Scalars['String']['output']>;
  platform: Scalars['String']['output'];
  platformId: Scalars['String']['output'];
  publishableKey: Scalars['String']['output'];
};

export type SubscriberCampaignSubscriberInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

export type SubscriberCampaignSubscriptionInput = {
  mrr?: InputMaybe<Scalars['Float']['input']>;
  platformId: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriberFlowOfferInput = {
  flowStepToken: Scalars['String']['input'];
  flowVersion: FlowVersion;
  skipOffers?: InputMaybe<Array<Scalars['String']['input']>>;
  subscriberFlowToken: Scalars['String']['input'];
};

export type SubscriberFlowOfferOutput = {
  __typename: 'SubscriberFlowOfferOutput';
  flowOffer?: Maybe<Scalars['jsonb']['output']>;
  flowOfferGroup?: Maybe<Scalars['jsonb']['output']>;
  nextOrderDate?: Maybe<Scalars['String']['output']>;
  offer?: Maybe<offer>;
  offerAutopilotRoundId?: Maybe<Scalars['Int']['output']>;
  offerGroupId?: Maybe<Scalars['Int']['output']>;
  offerId?: Maybe<Scalars['Int']['output']>;
  offer_group?: Maybe<offer_group>;
  pauseReasons?: Maybe<Array<PauseReason>>;
  platformConnectionId?: Maybe<Scalars['Int']['output']>;
  platform_connection?: Maybe<platform_connection>;
  subscription?: Maybe<subscription>;
  subscriptionId?: Maybe<Scalars['Int']['output']>;
};

export type SubscriberFlowSegmentMatchesInput = {
  flowVersion: FlowVersion;
  previewPropertyValues?: InputMaybe<Scalars['jsonb']['input']>;
  previewSegmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  token: Scalars['String']['input'];
};

export type SubscriberFlowSegmentMatchesOutput = {
  __typename: 'SubscriberFlowSegmentMatchesOutput';
  segmentGroupIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIds: Array<Scalars['Int']['output']>;
};

export type SubscriptionSegmentPropertyValuesInput = {
  subscriptionId: Scalars['Int']['input'];
};

export type SubscriptionSegmentPropertyValuesOutput = {
  __typename: 'SubscriptionSegmentPropertyValuesOutput';
  values: Scalars['jsonb']['output'];
};

export type SwitchAccountInput = {
  accountId: Scalars['Int']['input'];
};

export type SwitchAccountOutput = {
  __typename: 'SwitchAccountOutput';
  success: Scalars['Boolean']['output'];
};

export type SwitchTestModeInput = {
  isTestMode: Scalars['Boolean']['input'];
};

export type SwitchTestModeOutput = {
  __typename: 'SwitchTestModeOutput';
  success: Scalars['Boolean']['output'];
};

export type TrackEventInput = {
  event: Scalars['String']['input'];
  properties?: InputMaybe<Scalars['String']['input']>;
};

export type TrackEventOutput = {
  __typename: 'TrackEventOutput';
  success: Scalars['Boolean']['output'];
};

export type TransparentLoginInput = {
  accountId: Scalars['Int']['input'];
};

export type TransparentLoginOutput = {
  __typename: 'TransparentLoginOutput';
  success: Scalars['Boolean']['output'];
};

export type TransparentLogoutOutput = {
  __typename: 'TransparentLogoutOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateChargebeeApiKeyInput = {
  newApiKey: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type UpdateChargebeeApiKeyOutput = {
  __typename: 'UpdateChargebeeApiKeyOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateDeflectionInput = {
  contentFormat: Scalars['String']['input'];
  contentTranslations: Scalars['jsonb']['input'];
  flowActions: Array<InputMaybe<FlowActionInput>>;
  headingFormat: Scalars['String']['input'];
  headingTranslations: Scalars['jsonb']['input'];
  id: Scalars['Int']['input'];
  minimumItems?: InputMaybe<Scalars['Int']['input']>;
  snapshotItems: Array<InputMaybe<DeflectionSnapshotItemInput>>;
  tagIds: Array<InputMaybe<Scalars['Int']['input']>>;
  title: Scalars['String']['input'];
};

export type UpdateDeflectionOutput = {
  __typename: 'UpdateDeflectionOutput';
  deflection?: Maybe<deflection>;
  deflectionId: Scalars['Int']['output'];
};

export type UpdateMaxioApiKeyInput = {
  newApiKey: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type UpdateMaxioApiKeyOutput = {
  __typename: 'UpdateMaxioApiKeyOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateOpenPayApiTokenInput = {
  newApiToken: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type UpdateOpenPayApiTokenOutput = {
  __typename: 'UpdateOpenPayApiTokenOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateRechargeApiTokenInput = {
  newApiToken: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type UpdateRechargeApiTokenOutput = {
  __typename: 'UpdateRechargeApiTokenOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateRecurlyApiKeyInput = {
  newApiKey: Scalars['String']['input'];
  platformConnectionId: Scalars['Int']['input'];
};

export type UpdateRecurlyApiKeyOutput = {
  __typename: 'UpdateRecurlyApiKeyOutput';
  success: Scalars['Boolean']['output'];
};

export type UpdateSegmentGroupInput = {
  andSegmentIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  notSegmentIds: Array<Scalars['Int']['input']>;
  orSegmentIds: Array<Scalars['Int']['input']>;
  segmentGroupId: Scalars['Int']['input'];
};

export type UpdateSegmentGroupOutput = {
  __typename: 'UpdateSegmentGroupOutput';
  segmentGroup?: Maybe<segment_group>;
  segmentGroupId: Scalars['Int']['output'];
};

export type UpgradeAccountInput = {
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type UpgradeAccountOutput = {
  __typename: 'UpgradeAccountOutput';
  success: Scalars['Boolean']['output'];
};

export type UserInvitationByTokenInput = {
  token: Scalars['String']['input'];
};

export type UserInvitationByTokenOutput = {
  __typename: 'UserInvitationByTokenOutput';
  accountId: Scalars['Int']['output'];
  accountTitle: Scalars['String']['output'];
  expired: Scalars['Boolean']['output'];
  invitedByEmail: Scalars['String']['output'];
  invitedByName: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type VerifyEmailCodeInput = {
  clientId: Scalars['String']['input'];
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type VerifyEmailCodeOutput = {
  __typename: 'VerifyEmailCodeOutput';
  portalSessionToken?: Maybe<Scalars['String']['output']>;
};

export type ViewerOutput = {
  __typename: 'ViewerOutput';
  account?: Maybe<account>;
  accountId?: Maybe<Scalars['Int']['output']>;
  allowedRoles: Array<Scalars['String']['output']>;
  defaultRole: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

/** columns and relationships of "account" */
export type account = {
  __typename: 'account';
  /** An array relationship */
  account_features: Array<account_feature>;
  /** An aggregate relationship */
  account_features_aggregate: account_feature_aggregate;
  /** An array relationship */
  account_settings: Array<account_setting>;
  /** An aggregate relationship */
  account_settings_aggregate: account_setting_aggregate;
  /** An object relationship */
  account_status: account_status;
  /** An array relationship */
  account_user_settings: Array<account_user_setting>;
  /** An aggregate relationship */
  account_user_settings_aggregate: account_user_setting_aggregate;
  /** An array relationship */
  account_users: Array<account_user>;
  /** An aggregate relationship */
  account_users_aggregate: account_user_aggregate;
  /** An array relationship */
  acknowledgements: Array<acknowledgement>;
  /** An aggregate relationship */
  acknowledgements_aggregate: acknowledgement_aggregate;
  /** An array relationship */
  campaigns: Array<campaign>;
  /** An aggregate relationship */
  campaigns_aggregate: campaign_aggregate;
  create_missing_properties: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  currency: platform_base_currency_enum;
  customer_portal_enabled: Scalars['Boolean']['output'];
  /** An array relationship */
  data_connectors: Array<data_connector>;
  /** An aggregate relationship */
  data_connectors_aggregate: data_connector_aggregate;
  /** An object relationship */
  default_campaign?: Maybe<campaign>;
  default_campaign_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  default_flow?: Maybe<flow>;
  default_flow_id?: Maybe<Scalars['Int']['output']>;
  emails_disabled: Scalars['Boolean']['output'];
  enterprise: Scalars['Boolean']['output'];
  exclude_pii_in_flow_result: Scalars['Boolean']['output'];
  favicon_url?: Maybe<Scalars['String']['output']>;
  flow_css?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  flow_route_rules: Array<flow_route_rule>;
  /** An aggregate relationship */
  flow_route_rules_aggregate: flow_route_rule_aggregate;
  /** An array relationship */
  flows: Array<flow>;
  /** An aggregate relationship */
  flows_aggregate: flow_aggregate;
  id: Scalars['Int']['output'];
  intake_form_name_email: Scalars['Boolean']['output'];
  intake_require_match: Scalars['Boolean']['output'];
  /** An array relationship */
  integrations: Array<integration>;
  /** An aggregate relationship */
  integrations_aggregate: integration_aggregate;
  internal_testing: Scalars['Boolean']['output'];
  logo_url?: Maybe<Scalars['String']['output']>;
  mode: account_mode_enum;
  /** An array relationship */
  offers: Array<offer>;
  /** An aggregate relationship */
  offers_aggregate: offer_aggregate;
  /** An object relationship */
  owner?: Maybe<user>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_base_currency: platform_base_currency;
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_id_property_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  portal_sessions: Array<portal_session>;
  /** An aggregate relationship */
  portal_sessions_aggregate: portal_session_aggregate;
  /** An array relationship */
  products: Array<product>;
  /** An aggregate relationship */
  products_aggregate: product_aggregate;
  prompt_for_subscriber_details: Scalars['Boolean']['output'];
  /** An array relationship */
  properties: Array<property>;
  /** An aggregate relationship */
  properties_aggregate: property_aggregate;
  prosperstack: Scalars['Boolean']['output'];
  /** An array relationship */
  questions: Array<question>;
  /** An aggregate relationship */
  questions_aggregate: question_aggregate;
  require_payment_method: Scalars['Boolean']['output'];
  revenue_mode: account_revenue_mode_enum;
  secret: Scalars['String']['output'];
  /** An array relationship */
  segments: Array<segment>;
  /** An aggregate relationship */
  segments_aggregate: segment_aggregate;
  show_tour: Scalars['Boolean']['output'];
  sso_connection?: Maybe<Scalars['String']['output']>;
  sso_domain?: Maybe<Scalars['String']['output']>;
  sso_log_in_url?: Maybe<Scalars['String']['output']>;
  sso_required: Scalars['Boolean']['output'];
  status: account_status_enum;
  stripe_customer_id: Scalars['String']['output'];
  stripe_subscription_id: Scalars['String']['output'];
  /** An array relationship */
  subscribers: Array<subscriber>;
  /** An aggregate relationship */
  subscribers_aggregate: subscriber_aggregate;
  /** An array relationship */
  subscriptions: Array<subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: subscription_aggregate;
  /** An object relationship */
  test_mode_for_account?: Maybe<account>;
  test_mode_for_account_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
  /** An array relationship */
  translations: Array<translation>;
  /** An aggregate relationship */
  translations_aggregate: translation_aggregate;
  updated_at: Scalars['timestamptz']['output'];
  use_subscriber_id_as_subscription_id: Scalars['Boolean']['output'];
  /** An array relationship */
  user_invitations: Array<user_invitation>;
  /** An aggregate relationship */
  user_invitations_aggregate: user_invitation_aggregate;
  /** An array relationship */
  webhooks: Array<webhook>;
  /** An aggregate relationship */
  webhooks_aggregate: webhook_aggregate;
};


/** columns and relationships of "account" */
export type accountaccount_featuresArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_features_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_settingsArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_user_settingsArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_user_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_usersArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


/** columns and relationships of "account" */
export type accountaccount_users_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


/** columns and relationships of "account" */
export type accountacknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


/** columns and relationships of "account" */
export type accountacknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


/** columns and relationships of "account" */
export type accountcampaignsArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


/** columns and relationships of "account" */
export type accountcampaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


/** columns and relationships of "account" */
export type accountdata_connectorsArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


/** columns and relationships of "account" */
export type accountdata_connectors_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


/** columns and relationships of "account" */
export type accountflow_route_rulesArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


/** columns and relationships of "account" */
export type accountflow_route_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


/** columns and relationships of "account" */
export type accountflowsArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


/** columns and relationships of "account" */
export type accountflows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


/** columns and relationships of "account" */
export type accountintegrationsArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


/** columns and relationships of "account" */
export type accountintegrations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


/** columns and relationships of "account" */
export type accountoffersArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


/** columns and relationships of "account" */
export type accountoffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


/** columns and relationships of "account" */
export type accountportal_sessionsArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


/** columns and relationships of "account" */
export type accountportal_sessions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


/** columns and relationships of "account" */
export type accountproductsArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


/** columns and relationships of "account" */
export type accountproducts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


/** columns and relationships of "account" */
export type accountpropertiesArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


/** columns and relationships of "account" */
export type accountproperties_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


/** columns and relationships of "account" */
export type accountquestionsArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


/** columns and relationships of "account" */
export type accountquestions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsegmentsArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsegments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsubscribersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsubscribers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


/** columns and relationships of "account" */
export type accountsubscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


/** columns and relationships of "account" */
export type accounttranslationsArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


/** columns and relationships of "account" */
export type accounttranslations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


/** columns and relationships of "account" */
export type accountuser_invitationsArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


/** columns and relationships of "account" */
export type accountuser_invitations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


/** columns and relationships of "account" */
export type accountwebhooksArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};


/** columns and relationships of "account" */
export type accountwebhooks_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};

/** aggregated selection of "account" */
export type account_aggregate = {
  __typename: 'account_aggregate';
  aggregate?: Maybe<account_aggregate_fields>;
  nodes: Array<account>;
};

export type account_aggregate_bool_exp = {
  bool_and?: InputMaybe<account_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<account_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<account_aggregate_bool_exp_count>;
};

export type account_aggregate_bool_exp_bool_and = {
  arguments: account_select_column_account_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type account_aggregate_bool_exp_bool_or = {
  arguments: account_select_column_account_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type account_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<account_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "account" */
export type account_aggregate_fields = {
  __typename: 'account_aggregate_fields';
  avg?: Maybe<account_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_max_fields>;
  min?: Maybe<account_min_fields>;
  stddev?: Maybe<account_stddev_fields>;
  stddev_pop?: Maybe<account_stddev_pop_fields>;
  stddev_samp?: Maybe<account_stddev_samp_fields>;
  sum?: Maybe<account_sum_fields>;
  var_pop?: Maybe<account_var_pop_fields>;
  var_samp?: Maybe<account_var_samp_fields>;
  variance?: Maybe<account_variance_fields>;
};


/** aggregate fields of "account" */
export type account_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account" */
export type account_aggregate_order_by = {
  avg?: InputMaybe<account_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<account_max_order_by>;
  min?: InputMaybe<account_min_order_by>;
  stddev?: InputMaybe<account_stddev_order_by>;
  stddev_pop?: InputMaybe<account_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<account_stddev_samp_order_by>;
  sum?: InputMaybe<account_sum_order_by>;
  var_pop?: InputMaybe<account_var_pop_order_by>;
  var_samp?: InputMaybe<account_var_samp_order_by>;
  variance?: InputMaybe<account_variance_order_by>;
};

/** input type for inserting array relation for remote table "account" */
export type account_arr_rel_insert_input = {
  data: Array<account_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<account_on_conflict>;
};

/** aggregate avg on columns */
export type account_avg_fields = {
  __typename: 'account_avg_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account" */
export type account_avg_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type account_bool_exp = {
  _and?: InputMaybe<Array<account_bool_exp>>;
  _not?: InputMaybe<account_bool_exp>;
  _or?: InputMaybe<Array<account_bool_exp>>;
  account_features?: InputMaybe<account_feature_bool_exp>;
  account_features_aggregate?: InputMaybe<account_feature_aggregate_bool_exp>;
  account_settings?: InputMaybe<account_setting_bool_exp>;
  account_settings_aggregate?: InputMaybe<account_setting_aggregate_bool_exp>;
  account_status?: InputMaybe<account_status_bool_exp>;
  account_user_settings?: InputMaybe<account_user_setting_bool_exp>;
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_bool_exp>;
  account_users?: InputMaybe<account_user_bool_exp>;
  account_users_aggregate?: InputMaybe<account_user_aggregate_bool_exp>;
  acknowledgements?: InputMaybe<acknowledgement_bool_exp>;
  acknowledgements_aggregate?: InputMaybe<acknowledgement_aggregate_bool_exp>;
  campaigns?: InputMaybe<campaign_bool_exp>;
  campaigns_aggregate?: InputMaybe<campaign_aggregate_bool_exp>;
  create_missing_properties?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_base_currency_enum_comparison_exp>;
  customer_portal_enabled?: InputMaybe<Boolean_comparison_exp>;
  data_connectors?: InputMaybe<data_connector_bool_exp>;
  data_connectors_aggregate?: InputMaybe<data_connector_aggregate_bool_exp>;
  default_campaign?: InputMaybe<campaign_bool_exp>;
  default_campaign_id?: InputMaybe<Int_comparison_exp>;
  default_flow?: InputMaybe<flow_bool_exp>;
  default_flow_id?: InputMaybe<Int_comparison_exp>;
  emails_disabled?: InputMaybe<Boolean_comparison_exp>;
  enterprise?: InputMaybe<Boolean_comparison_exp>;
  exclude_pii_in_flow_result?: InputMaybe<Boolean_comparison_exp>;
  favicon_url?: InputMaybe<String_comparison_exp>;
  flow_css?: InputMaybe<String_comparison_exp>;
  flow_route_rules?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rules_aggregate?: InputMaybe<flow_route_rule_aggregate_bool_exp>;
  flows?: InputMaybe<flow_bool_exp>;
  flows_aggregate?: InputMaybe<flow_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  intake_form_name_email?: InputMaybe<Boolean_comparison_exp>;
  intake_require_match?: InputMaybe<Boolean_comparison_exp>;
  integrations?: InputMaybe<integration_bool_exp>;
  integrations_aggregate?: InputMaybe<integration_aggregate_bool_exp>;
  internal_testing?: InputMaybe<Boolean_comparison_exp>;
  logo_url?: InputMaybe<String_comparison_exp>;
  mode?: InputMaybe<account_mode_enum_comparison_exp>;
  offers?: InputMaybe<offer_bool_exp>;
  offers_aggregate?: InputMaybe<offer_aggregate_bool_exp>;
  owner?: InputMaybe<user_bool_exp>;
  owner_user_id?: InputMaybe<String_comparison_exp>;
  platform_base_currency?: InputMaybe<platform_base_currency_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_id_property_id?: InputMaybe<Int_comparison_exp>;
  portal_sessions?: InputMaybe<portal_session_bool_exp>;
  portal_sessions_aggregate?: InputMaybe<portal_session_aggregate_bool_exp>;
  products?: InputMaybe<product_bool_exp>;
  products_aggregate?: InputMaybe<product_aggregate_bool_exp>;
  prompt_for_subscriber_details?: InputMaybe<Boolean_comparison_exp>;
  properties?: InputMaybe<property_bool_exp>;
  properties_aggregate?: InputMaybe<property_aggregate_bool_exp>;
  prosperstack?: InputMaybe<Boolean_comparison_exp>;
  questions?: InputMaybe<question_bool_exp>;
  questions_aggregate?: InputMaybe<question_aggregate_bool_exp>;
  require_payment_method?: InputMaybe<Boolean_comparison_exp>;
  revenue_mode?: InputMaybe<account_revenue_mode_enum_comparison_exp>;
  secret?: InputMaybe<String_comparison_exp>;
  segments?: InputMaybe<segment_bool_exp>;
  segments_aggregate?: InputMaybe<segment_aggregate_bool_exp>;
  show_tour?: InputMaybe<Boolean_comparison_exp>;
  sso_connection?: InputMaybe<String_comparison_exp>;
  sso_domain?: InputMaybe<String_comparison_exp>;
  sso_log_in_url?: InputMaybe<String_comparison_exp>;
  sso_required?: InputMaybe<Boolean_comparison_exp>;
  status?: InputMaybe<account_status_enum_comparison_exp>;
  stripe_customer_id?: InputMaybe<String_comparison_exp>;
  stripe_subscription_id?: InputMaybe<String_comparison_exp>;
  subscribers?: InputMaybe<subscriber_bool_exp>;
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_bool_exp>;
  subscriptions?: InputMaybe<subscription_bool_exp>;
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_bool_exp>;
  test_mode_for_account?: InputMaybe<account_bool_exp>;
  test_mode_for_account_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  translations?: InputMaybe<translation_bool_exp>;
  translations_aggregate?: InputMaybe<translation_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  use_subscriber_id_as_subscription_id?: InputMaybe<Boolean_comparison_exp>;
  user_invitations?: InputMaybe<user_invitation_bool_exp>;
  user_invitations_aggregate?: InputMaybe<user_invitation_aggregate_bool_exp>;
  webhooks?: InputMaybe<webhook_bool_exp>;
  webhooks_aggregate?: InputMaybe<webhook_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "account" */
export enum account_constraint {
  /** unique or primary key constraint on columns "id" */
  account_pkey = 'account_pkey',
  /** unique or primary key constraint on columns "token" */
  account_token_key = 'account_token_key'
}

/** columns and relationships of "account_feature" */
export type account_feature = {
  __typename: 'account_feature';
  /** An object relationship */
  account: account;
  /** An object relationship */
  account_feature_key: account_feature_key;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  key: account_feature_key_enum;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "account_feature" */
export type account_featurevalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "account_feature" */
export type account_feature_aggregate = {
  __typename: 'account_feature_aggregate';
  aggregate?: Maybe<account_feature_aggregate_fields>;
  nodes: Array<account_feature>;
};

export type account_feature_aggregate_bool_exp = {
  count?: InputMaybe<account_feature_aggregate_bool_exp_count>;
};

export type account_feature_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<account_feature_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_feature_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "account_feature" */
export type account_feature_aggregate_fields = {
  __typename: 'account_feature_aggregate_fields';
  avg?: Maybe<account_feature_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_feature_max_fields>;
  min?: Maybe<account_feature_min_fields>;
  stddev?: Maybe<account_feature_stddev_fields>;
  stddev_pop?: Maybe<account_feature_stddev_pop_fields>;
  stddev_samp?: Maybe<account_feature_stddev_samp_fields>;
  sum?: Maybe<account_feature_sum_fields>;
  var_pop?: Maybe<account_feature_var_pop_fields>;
  var_samp?: Maybe<account_feature_var_samp_fields>;
  variance?: Maybe<account_feature_variance_fields>;
};


/** aggregate fields of "account_feature" */
export type account_feature_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_feature_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_feature" */
export type account_feature_aggregate_order_by = {
  avg?: InputMaybe<account_feature_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<account_feature_max_order_by>;
  min?: InputMaybe<account_feature_min_order_by>;
  stddev?: InputMaybe<account_feature_stddev_order_by>;
  stddev_pop?: InputMaybe<account_feature_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<account_feature_stddev_samp_order_by>;
  sum?: InputMaybe<account_feature_sum_order_by>;
  var_pop?: InputMaybe<account_feature_var_pop_order_by>;
  var_samp?: InputMaybe<account_feature_var_samp_order_by>;
  variance?: InputMaybe<account_feature_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type account_feature_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "account_feature" */
export type account_feature_arr_rel_insert_input = {
  data: Array<account_feature_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<account_feature_on_conflict>;
};

/** aggregate avg on columns */
export type account_feature_avg_fields = {
  __typename: 'account_feature_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_feature" */
export type account_feature_avg_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account_feature". All fields are combined with a logical 'AND'. */
export type account_feature_bool_exp = {
  _and?: InputMaybe<Array<account_feature_bool_exp>>;
  _not?: InputMaybe<account_feature_bool_exp>;
  _or?: InputMaybe<Array<account_feature_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_feature_key?: InputMaybe<account_feature_key_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  key?: InputMaybe<account_feature_key_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "account_feature" */
export enum account_feature_constraint {
  /** unique or primary key constraint on columns "key", "account_id" */
  account_feature_pkey = 'account_feature_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type account_feature_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type account_feature_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type account_feature_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "account_feature" */
export type account_feature_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "account_feature" */
export type account_feature_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_feature_key?: InputMaybe<account_feature_key_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_feature_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "account_feature_key" */
export type account_feature_key = {
  __typename: 'account_feature_key';
  /** An array relationship */
  account_features: Array<account_feature>;
  /** An aggregate relationship */
  account_features_aggregate: account_feature_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "account_feature_key" */
export type account_feature_keyaccount_featuresArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


/** columns and relationships of "account_feature_key" */
export type account_feature_keyaccount_features_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};

/** aggregated selection of "account_feature_key" */
export type account_feature_key_aggregate = {
  __typename: 'account_feature_key_aggregate';
  aggregate?: Maybe<account_feature_key_aggregate_fields>;
  nodes: Array<account_feature_key>;
};

/** aggregate fields of "account_feature_key" */
export type account_feature_key_aggregate_fields = {
  __typename: 'account_feature_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_feature_key_max_fields>;
  min?: Maybe<account_feature_key_min_fields>;
};


/** aggregate fields of "account_feature_key" */
export type account_feature_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_feature_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_feature_key". All fields are combined with a logical 'AND'. */
export type account_feature_key_bool_exp = {
  _and?: InputMaybe<Array<account_feature_key_bool_exp>>;
  _not?: InputMaybe<account_feature_key_bool_exp>;
  _or?: InputMaybe<Array<account_feature_key_bool_exp>>;
  account_features?: InputMaybe<account_feature_bool_exp>;
  account_features_aggregate?: InputMaybe<account_feature_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_feature_key" */
export enum account_feature_key_constraint {
  /** unique or primary key constraint on columns "value" */
  account_feature_key_pkey = 'account_feature_key_pkey'
}

export enum account_feature_key_enum {
  ab_test_weights = 'ab_test_weights',
  api = 'api',
  conversion = 'conversion',
  custom_css = 'custom_css',
  custom_properties = 'custom_properties',
  data_connectors = 'data_connectors',
  eligibility_messages = 'eligibility_messages',
  feedback_trends_report = 'feedback_trends_report',
  flow_ab_tests = 'flow_ab_tests',
  hubspot = 'hubspot',
  integrations = 'integrations',
  intercom = 'intercom',
  interventions = 'interventions',
  klaviyo = 'klaviyo',
  limit_users = 'limit_users',
  linked_accounts = 'linked_accounts',
  multiple_flows = 'multiple_flows',
  offer_ab_tests = 'offer_ab_tests',
  offer_autopilot = 'offer_autopilot',
  offer_groups = 'offer_groups',
  offer_rule_groups = 'offer_rule_groups',
  retention = 'retention',
  salesforce = 'salesforce',
  segment = 'segment',
  segment_groups = 'segment_groups',
  sessions = 'sessions',
  show_branding = 'show_branding',
  slack = 'slack',
  sso = 'sso',
  test_mode = 'test_mode',
  translations = 'translations',
  webhooks = 'webhooks',
  zapier = 'zapier'
}

/** Boolean expression to compare columns of type "account_feature_key_enum". All fields are combined with logical 'AND'. */
export type account_feature_key_enum_comparison_exp = {
  _eq?: InputMaybe<account_feature_key_enum>;
  _in?: InputMaybe<Array<account_feature_key_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_feature_key_enum>;
  _nin?: InputMaybe<Array<account_feature_key_enum>>;
};

/** input type for inserting data into table "account_feature_key" */
export type account_feature_key_insert_input = {
  account_features?: InputMaybe<account_feature_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_feature_key_max_fields = {
  __typename: 'account_feature_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_feature_key_min_fields = {
  __typename: 'account_feature_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_feature_key" */
export type account_feature_key_mutation_response = {
  __typename: 'account_feature_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_feature_key>;
};

/** input type for inserting object relation for remote table "account_feature_key" */
export type account_feature_key_obj_rel_insert_input = {
  data: account_feature_key_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<account_feature_key_on_conflict>;
};

/** on_conflict condition type for table "account_feature_key" */
export type account_feature_key_on_conflict = {
  constraint: account_feature_key_constraint;
  update_columns?: Array<account_feature_key_update_column>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};

/** Ordering options when selecting data from "account_feature_key". */
export type account_feature_key_order_by = {
  account_features_aggregate?: InputMaybe<account_feature_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_feature_key */
export type account_feature_key_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_feature_key" */
export enum account_feature_key_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_feature_key" */
export type account_feature_key_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_feature_key" */
export type account_feature_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_feature_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_feature_key_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_feature_key" */
export enum account_feature_key_update_column {
  /** column name */
  value = 'value'
}

export type account_feature_key_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_feature_key_set_input>;
  /** filter the rows which have to be updated */
  where: account_feature_key_bool_exp;
};

/** aggregate max on columns */
export type account_feature_max_fields = {
  __typename: 'account_feature_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "account_feature" */
export type account_feature_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_feature_min_fields = {
  __typename: 'account_feature_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "account_feature" */
export type account_feature_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "account_feature" */
export type account_feature_mutation_response = {
  __typename: 'account_feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_feature>;
};

/** on_conflict condition type for table "account_feature" */
export type account_feature_on_conflict = {
  constraint: account_feature_constraint;
  update_columns?: Array<account_feature_update_column>;
  where?: InputMaybe<account_feature_bool_exp>;
};

/** Ordering options when selecting data from "account_feature". */
export type account_feature_order_by = {
  account?: InputMaybe<account_order_by>;
  account_feature_key?: InputMaybe<account_feature_key_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_feature */
export type account_feature_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  key: account_feature_key_enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type account_feature_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "account_feature" */
export enum account_feature_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  key = 'key',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_feature" */
export type account_feature_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_feature_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type account_feature_stddev_fields = {
  __typename: 'account_feature_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_feature" */
export type account_feature_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_feature_stddev_pop_fields = {
  __typename: 'account_feature_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_feature" */
export type account_feature_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_feature_stddev_samp_fields = {
  __typename: 'account_feature_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_feature" */
export type account_feature_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "account_feature" */
export type account_feature_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_feature_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_feature_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_feature_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type account_feature_sum_fields = {
  __typename: 'account_feature_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "account_feature" */
export type account_feature_sum_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** update columns of table "account_feature" */
export enum account_feature_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  key = 'key',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

export type account_feature_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<account_feature_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<account_feature_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<account_feature_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<account_feature_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<account_feature_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<account_feature_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_feature_set_input>;
  /** filter the rows which have to be updated */
  where: account_feature_bool_exp;
};

/** aggregate var_pop on columns */
export type account_feature_var_pop_fields = {
  __typename: 'account_feature_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_feature" */
export type account_feature_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_feature_var_samp_fields = {
  __typename: 'account_feature_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_feature" */
export type account_feature_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_feature_variance_fields = {
  __typename: 'account_feature_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_feature" */
export type account_feature_variance_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "account" */
export type account_inc_input = {
  default_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  default_flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  platform_id_property_id?: InputMaybe<Scalars['Int']['input']>;
  test_mode_for_account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "account" */
export type account_insert_input = {
  account_features?: InputMaybe<account_feature_arr_rel_insert_input>;
  account_settings?: InputMaybe<account_setting_arr_rel_insert_input>;
  account_status?: InputMaybe<account_status_obj_rel_insert_input>;
  account_user_settings?: InputMaybe<account_user_setting_arr_rel_insert_input>;
  account_users?: InputMaybe<account_user_arr_rel_insert_input>;
  acknowledgements?: InputMaybe<acknowledgement_arr_rel_insert_input>;
  campaigns?: InputMaybe<campaign_arr_rel_insert_input>;
  create_missing_properties?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_base_currency_enum>;
  customer_portal_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  data_connectors?: InputMaybe<data_connector_arr_rel_insert_input>;
  default_campaign?: InputMaybe<campaign_obj_rel_insert_input>;
  default_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  default_flow?: InputMaybe<flow_obj_rel_insert_input>;
  default_flow_id?: InputMaybe<Scalars['Int']['input']>;
  emails_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  enterprise?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_pii_in_flow_result?: InputMaybe<Scalars['Boolean']['input']>;
  favicon_url?: InputMaybe<Scalars['String']['input']>;
  flow_css?: InputMaybe<Scalars['String']['input']>;
  flow_route_rules?: InputMaybe<flow_route_rule_arr_rel_insert_input>;
  flows?: InputMaybe<flow_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intake_form_name_email?: InputMaybe<Scalars['Boolean']['input']>;
  intake_require_match?: InputMaybe<Scalars['Boolean']['input']>;
  integrations?: InputMaybe<integration_arr_rel_insert_input>;
  internal_testing?: InputMaybe<Scalars['Boolean']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<account_mode_enum>;
  offers?: InputMaybe<offer_arr_rel_insert_input>;
  owner?: InputMaybe<user_obj_rel_insert_input>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  platform_base_currency?: InputMaybe<platform_base_currency_obj_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_id_property_id?: InputMaybe<Scalars['Int']['input']>;
  portal_sessions?: InputMaybe<portal_session_arr_rel_insert_input>;
  products?: InputMaybe<product_arr_rel_insert_input>;
  prompt_for_subscriber_details?: InputMaybe<Scalars['Boolean']['input']>;
  properties?: InputMaybe<property_arr_rel_insert_input>;
  prosperstack?: InputMaybe<Scalars['Boolean']['input']>;
  questions?: InputMaybe<question_arr_rel_insert_input>;
  require_payment_method?: InputMaybe<Scalars['Boolean']['input']>;
  revenue_mode?: InputMaybe<account_revenue_mode_enum>;
  secret?: InputMaybe<Scalars['String']['input']>;
  segments?: InputMaybe<segment_arr_rel_insert_input>;
  show_tour?: InputMaybe<Scalars['Boolean']['input']>;
  sso_connection?: InputMaybe<Scalars['String']['input']>;
  sso_domain?: InputMaybe<Scalars['String']['input']>;
  sso_log_in_url?: InputMaybe<Scalars['String']['input']>;
  sso_required?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<account_status_enum>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']['input']>;
  subscribers?: InputMaybe<subscriber_arr_rel_insert_input>;
  subscriptions?: InputMaybe<subscription_arr_rel_insert_input>;
  test_mode_for_account?: InputMaybe<account_obj_rel_insert_input>;
  test_mode_for_account_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<translation_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  use_subscriber_id_as_subscription_id?: InputMaybe<Scalars['Boolean']['input']>;
  user_invitations?: InputMaybe<user_invitation_arr_rel_insert_input>;
  webhooks?: InputMaybe<webhook_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type account_max_fields = {
  __typename: 'account_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_campaign_id?: Maybe<Scalars['Int']['output']>;
  default_flow_id?: Maybe<Scalars['Int']['output']>;
  favicon_url?: Maybe<Scalars['String']['output']>;
  flow_css?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  platform_id_property_id?: Maybe<Scalars['Int']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  sso_connection?: Maybe<Scalars['String']['output']>;
  sso_domain?: Maybe<Scalars['String']['output']>;
  sso_log_in_url?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  stripe_subscription_id?: Maybe<Scalars['String']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "account" */
export type account_max_order_by = {
  created_at?: InputMaybe<order_by>;
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  favicon_url?: InputMaybe<order_by>;
  flow_css?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  owner_user_id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  sso_connection?: InputMaybe<order_by>;
  sso_domain?: InputMaybe<order_by>;
  sso_log_in_url?: InputMaybe<order_by>;
  stripe_customer_id?: InputMaybe<order_by>;
  stripe_subscription_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_min_fields = {
  __typename: 'account_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_campaign_id?: Maybe<Scalars['Int']['output']>;
  default_flow_id?: Maybe<Scalars['Int']['output']>;
  favicon_url?: Maybe<Scalars['String']['output']>;
  flow_css?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  platform_id_property_id?: Maybe<Scalars['Int']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  sso_connection?: Maybe<Scalars['String']['output']>;
  sso_domain?: Maybe<Scalars['String']['output']>;
  sso_log_in_url?: Maybe<Scalars['String']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  stripe_subscription_id?: Maybe<Scalars['String']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "account" */
export type account_min_order_by = {
  created_at?: InputMaybe<order_by>;
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  favicon_url?: InputMaybe<order_by>;
  flow_css?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  owner_user_id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  sso_connection?: InputMaybe<order_by>;
  sso_domain?: InputMaybe<order_by>;
  sso_log_in_url?: InputMaybe<order_by>;
  stripe_customer_id?: InputMaybe<order_by>;
  stripe_subscription_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "account_mode" */
export type account_mode = {
  __typename: 'account_mode';
  value: Scalars['String']['output'];
};

/** aggregated selection of "account_mode" */
export type account_mode_aggregate = {
  __typename: 'account_mode_aggregate';
  aggregate?: Maybe<account_mode_aggregate_fields>;
  nodes: Array<account_mode>;
};

/** aggregate fields of "account_mode" */
export type account_mode_aggregate_fields = {
  __typename: 'account_mode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_mode_max_fields>;
  min?: Maybe<account_mode_min_fields>;
};


/** aggregate fields of "account_mode" */
export type account_mode_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_mode_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_mode". All fields are combined with a logical 'AND'. */
export type account_mode_bool_exp = {
  _and?: InputMaybe<Array<account_mode_bool_exp>>;
  _not?: InputMaybe<account_mode_bool_exp>;
  _or?: InputMaybe<Array<account_mode_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_mode" */
export enum account_mode_constraint {
  /** unique or primary key constraint on columns "value" */
  account_mode_pkey = 'account_mode_pkey'
}

export enum account_mode_enum {
  free = 'free',
  standard = 'standard'
}

/** Boolean expression to compare columns of type "account_mode_enum". All fields are combined with logical 'AND'. */
export type account_mode_enum_comparison_exp = {
  _eq?: InputMaybe<account_mode_enum>;
  _in?: InputMaybe<Array<account_mode_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_mode_enum>;
  _nin?: InputMaybe<Array<account_mode_enum>>;
};

/** input type for inserting data into table "account_mode" */
export type account_mode_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_mode_max_fields = {
  __typename: 'account_mode_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_mode_min_fields = {
  __typename: 'account_mode_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_mode" */
export type account_mode_mutation_response = {
  __typename: 'account_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_mode>;
};

/** on_conflict condition type for table "account_mode" */
export type account_mode_on_conflict = {
  constraint: account_mode_constraint;
  update_columns?: Array<account_mode_update_column>;
  where?: InputMaybe<account_mode_bool_exp>;
};

/** Ordering options when selecting data from "account_mode". */
export type account_mode_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_mode */
export type account_mode_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_mode" */
export enum account_mode_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_mode" */
export type account_mode_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_mode" */
export type account_mode_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_mode_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_mode_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_mode" */
export enum account_mode_update_column {
  /** column name */
  value = 'value'
}

export type account_mode_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_mode_set_input>;
  /** filter the rows which have to be updated */
  where: account_mode_bool_exp;
};

/** response of any mutation on the table "account" */
export type account_mutation_response = {
  __typename: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account>;
};

/** input type for inserting object relation for remote table "account" */
export type account_obj_rel_insert_input = {
  data: account_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<account_on_conflict>;
};

/** on_conflict condition type for table "account" */
export type account_on_conflict = {
  constraint: account_constraint;
  update_columns?: Array<account_update_column>;
  where?: InputMaybe<account_bool_exp>;
};

/** Ordering options when selecting data from "account". */
export type account_order_by = {
  account_features_aggregate?: InputMaybe<account_feature_aggregate_order_by>;
  account_settings_aggregate?: InputMaybe<account_setting_aggregate_order_by>;
  account_status?: InputMaybe<account_status_order_by>;
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_order_by>;
  account_users_aggregate?: InputMaybe<account_user_aggregate_order_by>;
  acknowledgements_aggregate?: InputMaybe<acknowledgement_aggregate_order_by>;
  campaigns_aggregate?: InputMaybe<campaign_aggregate_order_by>;
  create_missing_properties?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  customer_portal_enabled?: InputMaybe<order_by>;
  data_connectors_aggregate?: InputMaybe<data_connector_aggregate_order_by>;
  default_campaign?: InputMaybe<campaign_order_by>;
  default_campaign_id?: InputMaybe<order_by>;
  default_flow?: InputMaybe<flow_order_by>;
  default_flow_id?: InputMaybe<order_by>;
  emails_disabled?: InputMaybe<order_by>;
  enterprise?: InputMaybe<order_by>;
  exclude_pii_in_flow_result?: InputMaybe<order_by>;
  favicon_url?: InputMaybe<order_by>;
  flow_css?: InputMaybe<order_by>;
  flow_route_rules_aggregate?: InputMaybe<flow_route_rule_aggregate_order_by>;
  flows_aggregate?: InputMaybe<flow_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  intake_form_name_email?: InputMaybe<order_by>;
  intake_require_match?: InputMaybe<order_by>;
  integrations_aggregate?: InputMaybe<integration_aggregate_order_by>;
  internal_testing?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  mode?: InputMaybe<order_by>;
  offers_aggregate?: InputMaybe<offer_aggregate_order_by>;
  owner?: InputMaybe<user_order_by>;
  owner_user_id?: InputMaybe<order_by>;
  platform_base_currency?: InputMaybe<platform_base_currency_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  portal_sessions_aggregate?: InputMaybe<portal_session_aggregate_order_by>;
  products_aggregate?: InputMaybe<product_aggregate_order_by>;
  prompt_for_subscriber_details?: InputMaybe<order_by>;
  properties_aggregate?: InputMaybe<property_aggregate_order_by>;
  prosperstack?: InputMaybe<order_by>;
  questions_aggregate?: InputMaybe<question_aggregate_order_by>;
  require_payment_method?: InputMaybe<order_by>;
  revenue_mode?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  segments_aggregate?: InputMaybe<segment_aggregate_order_by>;
  show_tour?: InputMaybe<order_by>;
  sso_connection?: InputMaybe<order_by>;
  sso_domain?: InputMaybe<order_by>;
  sso_log_in_url?: InputMaybe<order_by>;
  sso_required?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  stripe_customer_id?: InputMaybe<order_by>;
  stripe_subscription_id?: InputMaybe<order_by>;
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_order_by>;
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_order_by>;
  test_mode_for_account?: InputMaybe<account_order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  translations_aggregate?: InputMaybe<translation_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
  use_subscriber_id_as_subscription_id?: InputMaybe<order_by>;
  user_invitations_aggregate?: InputMaybe<user_invitation_aggregate_order_by>;
  webhooks_aggregate?: InputMaybe<webhook_aggregate_order_by>;
};

/** primary key columns input for table: account */
export type account_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "account_revenue_mode" */
export type account_revenue_mode = {
  __typename: 'account_revenue_mode';
  value: Scalars['String']['output'];
};

/** aggregated selection of "account_revenue_mode" */
export type account_revenue_mode_aggregate = {
  __typename: 'account_revenue_mode_aggregate';
  aggregate?: Maybe<account_revenue_mode_aggregate_fields>;
  nodes: Array<account_revenue_mode>;
};

/** aggregate fields of "account_revenue_mode" */
export type account_revenue_mode_aggregate_fields = {
  __typename: 'account_revenue_mode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_revenue_mode_max_fields>;
  min?: Maybe<account_revenue_mode_min_fields>;
};


/** aggregate fields of "account_revenue_mode" */
export type account_revenue_mode_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_revenue_mode_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_revenue_mode". All fields are combined with a logical 'AND'. */
export type account_revenue_mode_bool_exp = {
  _and?: InputMaybe<Array<account_revenue_mode_bool_exp>>;
  _not?: InputMaybe<account_revenue_mode_bool_exp>;
  _or?: InputMaybe<Array<account_revenue_mode_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_revenue_mode" */
export enum account_revenue_mode_constraint {
  /** unique or primary key constraint on columns "value" */
  account_revenue_mode_pkey = 'account_revenue_mode_pkey'
}

export enum account_revenue_mode_enum {
  arr = 'arr',
  mrr = 'mrr'
}

/** Boolean expression to compare columns of type "account_revenue_mode_enum". All fields are combined with logical 'AND'. */
export type account_revenue_mode_enum_comparison_exp = {
  _eq?: InputMaybe<account_revenue_mode_enum>;
  _in?: InputMaybe<Array<account_revenue_mode_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_revenue_mode_enum>;
  _nin?: InputMaybe<Array<account_revenue_mode_enum>>;
};

/** input type for inserting data into table "account_revenue_mode" */
export type account_revenue_mode_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_revenue_mode_max_fields = {
  __typename: 'account_revenue_mode_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_revenue_mode_min_fields = {
  __typename: 'account_revenue_mode_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_revenue_mode" */
export type account_revenue_mode_mutation_response = {
  __typename: 'account_revenue_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_revenue_mode>;
};

/** on_conflict condition type for table "account_revenue_mode" */
export type account_revenue_mode_on_conflict = {
  constraint: account_revenue_mode_constraint;
  update_columns?: Array<account_revenue_mode_update_column>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};

/** Ordering options when selecting data from "account_revenue_mode". */
export type account_revenue_mode_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_revenue_mode */
export type account_revenue_mode_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_revenue_mode" */
export enum account_revenue_mode_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_revenue_mode" */
export type account_revenue_mode_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_revenue_mode" */
export type account_revenue_mode_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_revenue_mode_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_revenue_mode_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_revenue_mode" */
export enum account_revenue_mode_update_column {
  /** column name */
  value = 'value'
}

export type account_revenue_mode_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_revenue_mode_set_input>;
  /** filter the rows which have to be updated */
  where: account_revenue_mode_bool_exp;
};

/** select columns of table "account" */
export enum account_select_column {
  /** column name */
  create_missing_properties = 'create_missing_properties',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_portal_enabled = 'customer_portal_enabled',
  /** column name */
  default_campaign_id = 'default_campaign_id',
  /** column name */
  default_flow_id = 'default_flow_id',
  /** column name */
  emails_disabled = 'emails_disabled',
  /** column name */
  enterprise = 'enterprise',
  /** column name */
  exclude_pii_in_flow_result = 'exclude_pii_in_flow_result',
  /** column name */
  favicon_url = 'favicon_url',
  /** column name */
  flow_css = 'flow_css',
  /** column name */
  id = 'id',
  /** column name */
  intake_form_name_email = 'intake_form_name_email',
  /** column name */
  intake_require_match = 'intake_require_match',
  /** column name */
  internal_testing = 'internal_testing',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  mode = 'mode',
  /** column name */
  owner_user_id = 'owner_user_id',
  /** column name */
  platform_id_property_id = 'platform_id_property_id',
  /** column name */
  prompt_for_subscriber_details = 'prompt_for_subscriber_details',
  /** column name */
  prosperstack = 'prosperstack',
  /** column name */
  require_payment_method = 'require_payment_method',
  /** column name */
  revenue_mode = 'revenue_mode',
  /** column name */
  secret = 'secret',
  /** column name */
  show_tour = 'show_tour',
  /** column name */
  sso_connection = 'sso_connection',
  /** column name */
  sso_domain = 'sso_domain',
  /** column name */
  sso_log_in_url = 'sso_log_in_url',
  /** column name */
  sso_required = 'sso_required',
  /** column name */
  status = 'status',
  /** column name */
  stripe_customer_id = 'stripe_customer_id',
  /** column name */
  stripe_subscription_id = 'stripe_subscription_id',
  /** column name */
  test_mode_for_account_id = 'test_mode_for_account_id',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  use_subscriber_id_as_subscription_id = 'use_subscriber_id_as_subscription_id'
}

/** select "account_aggregate_bool_exp_bool_and_arguments_columns" columns of table "account" */
export enum account_select_column_account_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  create_missing_properties = 'create_missing_properties',
  /** column name */
  customer_portal_enabled = 'customer_portal_enabled',
  /** column name */
  emails_disabled = 'emails_disabled',
  /** column name */
  enterprise = 'enterprise',
  /** column name */
  exclude_pii_in_flow_result = 'exclude_pii_in_flow_result',
  /** column name */
  intake_form_name_email = 'intake_form_name_email',
  /** column name */
  intake_require_match = 'intake_require_match',
  /** column name */
  internal_testing = 'internal_testing',
  /** column name */
  prompt_for_subscriber_details = 'prompt_for_subscriber_details',
  /** column name */
  prosperstack = 'prosperstack',
  /** column name */
  require_payment_method = 'require_payment_method',
  /** column name */
  show_tour = 'show_tour',
  /** column name */
  sso_required = 'sso_required',
  /** column name */
  use_subscriber_id_as_subscription_id = 'use_subscriber_id_as_subscription_id'
}

/** select "account_aggregate_bool_exp_bool_or_arguments_columns" columns of table "account" */
export enum account_select_column_account_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  create_missing_properties = 'create_missing_properties',
  /** column name */
  customer_portal_enabled = 'customer_portal_enabled',
  /** column name */
  emails_disabled = 'emails_disabled',
  /** column name */
  enterprise = 'enterprise',
  /** column name */
  exclude_pii_in_flow_result = 'exclude_pii_in_flow_result',
  /** column name */
  intake_form_name_email = 'intake_form_name_email',
  /** column name */
  intake_require_match = 'intake_require_match',
  /** column name */
  internal_testing = 'internal_testing',
  /** column name */
  prompt_for_subscriber_details = 'prompt_for_subscriber_details',
  /** column name */
  prosperstack = 'prosperstack',
  /** column name */
  require_payment_method = 'require_payment_method',
  /** column name */
  show_tour = 'show_tour',
  /** column name */
  sso_required = 'sso_required',
  /** column name */
  use_subscriber_id_as_subscription_id = 'use_subscriber_id_as_subscription_id'
}

/** input type for updating data in table "account" */
export type account_set_input = {
  create_missing_properties?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_base_currency_enum>;
  customer_portal_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  default_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  default_flow_id?: InputMaybe<Scalars['Int']['input']>;
  emails_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  enterprise?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_pii_in_flow_result?: InputMaybe<Scalars['Boolean']['input']>;
  favicon_url?: InputMaybe<Scalars['String']['input']>;
  flow_css?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intake_form_name_email?: InputMaybe<Scalars['Boolean']['input']>;
  intake_require_match?: InputMaybe<Scalars['Boolean']['input']>;
  internal_testing?: InputMaybe<Scalars['Boolean']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<account_mode_enum>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  platform_id_property_id?: InputMaybe<Scalars['Int']['input']>;
  prompt_for_subscriber_details?: InputMaybe<Scalars['Boolean']['input']>;
  prosperstack?: InputMaybe<Scalars['Boolean']['input']>;
  require_payment_method?: InputMaybe<Scalars['Boolean']['input']>;
  revenue_mode?: InputMaybe<account_revenue_mode_enum>;
  secret?: InputMaybe<Scalars['String']['input']>;
  show_tour?: InputMaybe<Scalars['Boolean']['input']>;
  sso_connection?: InputMaybe<Scalars['String']['input']>;
  sso_domain?: InputMaybe<Scalars['String']['input']>;
  sso_log_in_url?: InputMaybe<Scalars['String']['input']>;
  sso_required?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<account_status_enum>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']['input']>;
  test_mode_for_account_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  use_subscriber_id_as_subscription_id?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "account_setting" */
export type account_setting = {
  __typename: 'account_setting';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  account_setting_key: account_setting_key;
  key: account_setting_key_enum;
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "account_setting" */
export type account_settingvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "account_setting" */
export type account_setting_aggregate = {
  __typename: 'account_setting_aggregate';
  aggregate?: Maybe<account_setting_aggregate_fields>;
  nodes: Array<account_setting>;
};

export type account_setting_aggregate_bool_exp = {
  count?: InputMaybe<account_setting_aggregate_bool_exp_count>;
};

export type account_setting_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<account_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_setting_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "account_setting" */
export type account_setting_aggregate_fields = {
  __typename: 'account_setting_aggregate_fields';
  avg?: Maybe<account_setting_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_setting_max_fields>;
  min?: Maybe<account_setting_min_fields>;
  stddev?: Maybe<account_setting_stddev_fields>;
  stddev_pop?: Maybe<account_setting_stddev_pop_fields>;
  stddev_samp?: Maybe<account_setting_stddev_samp_fields>;
  sum?: Maybe<account_setting_sum_fields>;
  var_pop?: Maybe<account_setting_var_pop_fields>;
  var_samp?: Maybe<account_setting_var_samp_fields>;
  variance?: Maybe<account_setting_variance_fields>;
};


/** aggregate fields of "account_setting" */
export type account_setting_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_setting" */
export type account_setting_aggregate_order_by = {
  avg?: InputMaybe<account_setting_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<account_setting_max_order_by>;
  min?: InputMaybe<account_setting_min_order_by>;
  stddev?: InputMaybe<account_setting_stddev_order_by>;
  stddev_pop?: InputMaybe<account_setting_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<account_setting_stddev_samp_order_by>;
  sum?: InputMaybe<account_setting_sum_order_by>;
  var_pop?: InputMaybe<account_setting_var_pop_order_by>;
  var_samp?: InputMaybe<account_setting_var_samp_order_by>;
  variance?: InputMaybe<account_setting_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type account_setting_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "account_setting" */
export type account_setting_arr_rel_insert_input = {
  data: Array<account_setting_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<account_setting_on_conflict>;
};

/** aggregate avg on columns */
export type account_setting_avg_fields = {
  __typename: 'account_setting_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_setting" */
export type account_setting_avg_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account_setting". All fields are combined with a logical 'AND'. */
export type account_setting_bool_exp = {
  _and?: InputMaybe<Array<account_setting_bool_exp>>;
  _not?: InputMaybe<account_setting_bool_exp>;
  _or?: InputMaybe<Array<account_setting_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  account_setting_key?: InputMaybe<account_setting_key_bool_exp>;
  key?: InputMaybe<account_setting_key_enum_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "account_setting" */
export enum account_setting_constraint {
  /** unique or primary key constraint on columns "key", "account_id" */
  account_setting_pkey = 'account_setting_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type account_setting_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type account_setting_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type account_setting_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "account_setting" */
export type account_setting_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "account_setting" */
export type account_setting_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  account_setting_key?: InputMaybe<account_setting_key_obj_rel_insert_input>;
  key?: InputMaybe<account_setting_key_enum>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "account_setting_key" */
export type account_setting_key = {
  __typename: 'account_setting_key';
  /** An array relationship */
  account_settings: Array<account_setting>;
  /** An aggregate relationship */
  account_settings_aggregate: account_setting_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "account_setting_key" */
export type account_setting_keyaccount_settingsArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


/** columns and relationships of "account_setting_key" */
export type account_setting_keyaccount_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};

/** aggregated selection of "account_setting_key" */
export type account_setting_key_aggregate = {
  __typename: 'account_setting_key_aggregate';
  aggregate?: Maybe<account_setting_key_aggregate_fields>;
  nodes: Array<account_setting_key>;
};

/** aggregate fields of "account_setting_key" */
export type account_setting_key_aggregate_fields = {
  __typename: 'account_setting_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_setting_key_max_fields>;
  min?: Maybe<account_setting_key_min_fields>;
};


/** aggregate fields of "account_setting_key" */
export type account_setting_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_setting_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_setting_key". All fields are combined with a logical 'AND'. */
export type account_setting_key_bool_exp = {
  _and?: InputMaybe<Array<account_setting_key_bool_exp>>;
  _not?: InputMaybe<account_setting_key_bool_exp>;
  _or?: InputMaybe<Array<account_setting_key_bool_exp>>;
  account_settings?: InputMaybe<account_setting_bool_exp>;
  account_settings_aggregate?: InputMaybe<account_setting_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_setting_key" */
export enum account_setting_key_constraint {
  /** unique or primary key constraint on columns "value" */
  account_setting_key_pkey = 'account_setting_key_pkey'
}

export enum account_setting_key_enum {
  autopilot_company_summary = 'autopilot_company_summary',
  convert_snippet_dismissed = 'convert_snippet_dismissed',
  csv_import_force_next = 'csv_import_force_next',
  setup_survey_embedded = 'setup_survey_embedded',
  setup_survey_sent = 'setup_survey_sent',
  survey_auto_send = 'survey_auto_send',
  survey_company_name = 'survey_company_name',
  survey_first_name = 'survey_first_name',
  survey_from_name = 'survey_from_name',
  survey_last_name = 'survey_last_name',
  survey_logo_url = 'survey_logo_url',
  survey_reply_to = 'survey_reply_to',
  survey_title = 'survey_title'
}

/** Boolean expression to compare columns of type "account_setting_key_enum". All fields are combined with logical 'AND'. */
export type account_setting_key_enum_comparison_exp = {
  _eq?: InputMaybe<account_setting_key_enum>;
  _in?: InputMaybe<Array<account_setting_key_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_setting_key_enum>;
  _nin?: InputMaybe<Array<account_setting_key_enum>>;
};

/** input type for inserting data into table "account_setting_key" */
export type account_setting_key_insert_input = {
  account_settings?: InputMaybe<account_setting_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_setting_key_max_fields = {
  __typename: 'account_setting_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_setting_key_min_fields = {
  __typename: 'account_setting_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_setting_key" */
export type account_setting_key_mutation_response = {
  __typename: 'account_setting_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_setting_key>;
};

/** input type for inserting object relation for remote table "account_setting_key" */
export type account_setting_key_obj_rel_insert_input = {
  data: account_setting_key_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<account_setting_key_on_conflict>;
};

/** on_conflict condition type for table "account_setting_key" */
export type account_setting_key_on_conflict = {
  constraint: account_setting_key_constraint;
  update_columns?: Array<account_setting_key_update_column>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};

/** Ordering options when selecting data from "account_setting_key". */
export type account_setting_key_order_by = {
  account_settings_aggregate?: InputMaybe<account_setting_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_setting_key */
export type account_setting_key_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_setting_key" */
export enum account_setting_key_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_setting_key" */
export type account_setting_key_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_setting_key" */
export type account_setting_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_setting_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_setting_key_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_setting_key" */
export enum account_setting_key_update_column {
  /** column name */
  value = 'value'
}

export type account_setting_key_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_setting_key_set_input>;
  /** filter the rows which have to be updated */
  where: account_setting_key_bool_exp;
};

/** aggregate max on columns */
export type account_setting_max_fields = {
  __typename: 'account_setting_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "account_setting" */
export type account_setting_max_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_setting_min_fields = {
  __typename: 'account_setting_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "account_setting" */
export type account_setting_min_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "account_setting" */
export type account_setting_mutation_response = {
  __typename: 'account_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_setting>;
};

/** on_conflict condition type for table "account_setting" */
export type account_setting_on_conflict = {
  constraint: account_setting_constraint;
  update_columns?: Array<account_setting_update_column>;
  where?: InputMaybe<account_setting_bool_exp>;
};

/** Ordering options when selecting data from "account_setting". */
export type account_setting_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  account_setting_key?: InputMaybe<account_setting_key_order_by>;
  key?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_setting */
export type account_setting_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  key: account_setting_key_enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type account_setting_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "account_setting" */
export enum account_setting_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  key = 'key',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_setting" */
export type account_setting_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<account_setting_key_enum>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type account_setting_stddev_fields = {
  __typename: 'account_setting_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_setting" */
export type account_setting_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_setting_stddev_pop_fields = {
  __typename: 'account_setting_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_setting" */
export type account_setting_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_setting_stddev_samp_fields = {
  __typename: 'account_setting_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_setting" */
export type account_setting_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "account_setting" */
export type account_setting_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_setting_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_setting_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<account_setting_key_enum>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type account_setting_sum_fields = {
  __typename: 'account_setting_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "account_setting" */
export type account_setting_sum_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** update columns of table "account_setting" */
export enum account_setting_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  key = 'key',
  /** column name */
  value = 'value'
}

export type account_setting_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<account_setting_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<account_setting_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<account_setting_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<account_setting_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<account_setting_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<account_setting_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_setting_set_input>;
  /** filter the rows which have to be updated */
  where: account_setting_bool_exp;
};

/** aggregate var_pop on columns */
export type account_setting_var_pop_fields = {
  __typename: 'account_setting_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_setting" */
export type account_setting_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_setting_var_samp_fields = {
  __typename: 'account_setting_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_setting" */
export type account_setting_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_setting_variance_fields = {
  __typename: 'account_setting_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_setting" */
export type account_setting_variance_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** columns and relationships of "account_status" */
export type account_status = {
  __typename: 'account_status';
  /** An array relationship */
  accounts: Array<account>;
  /** An aggregate relationship */
  accounts_aggregate: account_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "account_status" */
export type account_statusaccountsArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


/** columns and relationships of "account_status" */
export type account_statusaccounts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};

/** aggregated selection of "account_status" */
export type account_status_aggregate = {
  __typename: 'account_status_aggregate';
  aggregate?: Maybe<account_status_aggregate_fields>;
  nodes: Array<account_status>;
};

/** aggregate fields of "account_status" */
export type account_status_aggregate_fields = {
  __typename: 'account_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_status_max_fields>;
  min?: Maybe<account_status_min_fields>;
};


/** aggregate fields of "account_status" */
export type account_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_status". All fields are combined with a logical 'AND'. */
export type account_status_bool_exp = {
  _and?: InputMaybe<Array<account_status_bool_exp>>;
  _not?: InputMaybe<account_status_bool_exp>;
  _or?: InputMaybe<Array<account_status_bool_exp>>;
  accounts?: InputMaybe<account_bool_exp>;
  accounts_aggregate?: InputMaybe<account_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_status" */
export enum account_status_constraint {
  /** unique or primary key constraint on columns "value" */
  account_status_pkey = 'account_status_pkey'
}

export enum account_status_enum {
  active = 'active',
  canceled = 'canceled',
  initialized = 'initialized',
  trialing = 'trialing'
}

/** Boolean expression to compare columns of type "account_status_enum". All fields are combined with logical 'AND'. */
export type account_status_enum_comparison_exp = {
  _eq?: InputMaybe<account_status_enum>;
  _in?: InputMaybe<Array<account_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_status_enum>;
  _nin?: InputMaybe<Array<account_status_enum>>;
};

/** input type for inserting data into table "account_status" */
export type account_status_insert_input = {
  accounts?: InputMaybe<account_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_status_max_fields = {
  __typename: 'account_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_status_min_fields = {
  __typename: 'account_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_status" */
export type account_status_mutation_response = {
  __typename: 'account_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_status>;
};

/** input type for inserting object relation for remote table "account_status" */
export type account_status_obj_rel_insert_input = {
  data: account_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<account_status_on_conflict>;
};

/** on_conflict condition type for table "account_status" */
export type account_status_on_conflict = {
  constraint: account_status_constraint;
  update_columns?: Array<account_status_update_column>;
  where?: InputMaybe<account_status_bool_exp>;
};

/** Ordering options when selecting data from "account_status". */
export type account_status_order_by = {
  accounts_aggregate?: InputMaybe<account_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_status */
export type account_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_status" */
export enum account_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_status" */
export type account_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_status" */
export type account_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_status" */
export enum account_status_update_column {
  /** column name */
  value = 'value'
}

export type account_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_status_set_input>;
  /** filter the rows which have to be updated */
  where: account_status_bool_exp;
};

/** aggregate stddev on columns */
export type account_stddev_fields = {
  __typename: 'account_stddev_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account" */
export type account_stddev_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_stddev_pop_fields = {
  __typename: 'account_stddev_pop_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account" */
export type account_stddev_pop_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_stddev_samp_fields = {
  __typename: 'account_stddev_samp_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account" */
export type account_stddev_samp_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "account" */
export type account_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_stream_cursor_value_input = {
  create_missing_properties?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_base_currency_enum>;
  customer_portal_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  default_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  default_flow_id?: InputMaybe<Scalars['Int']['input']>;
  emails_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  enterprise?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_pii_in_flow_result?: InputMaybe<Scalars['Boolean']['input']>;
  favicon_url?: InputMaybe<Scalars['String']['input']>;
  flow_css?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intake_form_name_email?: InputMaybe<Scalars['Boolean']['input']>;
  intake_require_match?: InputMaybe<Scalars['Boolean']['input']>;
  internal_testing?: InputMaybe<Scalars['Boolean']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<account_mode_enum>;
  owner_user_id?: InputMaybe<Scalars['String']['input']>;
  platform_id_property_id?: InputMaybe<Scalars['Int']['input']>;
  prompt_for_subscriber_details?: InputMaybe<Scalars['Boolean']['input']>;
  prosperstack?: InputMaybe<Scalars['Boolean']['input']>;
  require_payment_method?: InputMaybe<Scalars['Boolean']['input']>;
  revenue_mode?: InputMaybe<account_revenue_mode_enum>;
  secret?: InputMaybe<Scalars['String']['input']>;
  show_tour?: InputMaybe<Scalars['Boolean']['input']>;
  sso_connection?: InputMaybe<Scalars['String']['input']>;
  sso_domain?: InputMaybe<Scalars['String']['input']>;
  sso_log_in_url?: InputMaybe<Scalars['String']['input']>;
  sso_required?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<account_status_enum>;
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>;
  stripe_subscription_id?: InputMaybe<Scalars['String']['input']>;
  test_mode_for_account_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  use_subscriber_id_as_subscription_id?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type account_sum_fields = {
  __typename: 'account_sum_fields';
  default_campaign_id?: Maybe<Scalars['Int']['output']>;
  default_flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  platform_id_property_id?: Maybe<Scalars['Int']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "account" */
export type account_sum_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** columns and relationships of "account_tsvector" */
export type account_tsvector = {
  __typename: 'account_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "account_tsvector" */
export type account_tsvector_aggregate = {
  __typename: 'account_tsvector_aggregate';
  aggregate?: Maybe<account_tsvector_aggregate_fields>;
  nodes: Array<account_tsvector>;
};

/** aggregate fields of "account_tsvector" */
export type account_tsvector_aggregate_fields = {
  __typename: 'account_tsvector_aggregate_fields';
  avg?: Maybe<account_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_tsvector_max_fields>;
  min?: Maybe<account_tsvector_min_fields>;
  stddev?: Maybe<account_tsvector_stddev_fields>;
  stddev_pop?: Maybe<account_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<account_tsvector_stddev_samp_fields>;
  sum?: Maybe<account_tsvector_sum_fields>;
  var_pop?: Maybe<account_tsvector_var_pop_fields>;
  var_samp?: Maybe<account_tsvector_var_samp_fields>;
  variance?: Maybe<account_tsvector_variance_fields>;
};


/** aggregate fields of "account_tsvector" */
export type account_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type account_tsvector_avg_fields = {
  __typename: 'account_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "account_tsvector". All fields are combined with a logical 'AND'. */
export type account_tsvector_bool_exp = {
  _and?: InputMaybe<Array<account_tsvector_bool_exp>>;
  _not?: InputMaybe<account_tsvector_bool_exp>;
  _or?: InputMaybe<Array<account_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type account_tsvector_max_fields = {
  __typename: 'account_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type account_tsvector_min_fields = {
  __typename: 'account_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "account_tsvector". */
export type account_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "account_tsvector" */
export enum account_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type account_tsvector_stddev_fields = {
  __typename: 'account_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type account_tsvector_stddev_pop_fields = {
  __typename: 'account_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type account_tsvector_stddev_samp_fields = {
  __typename: 'account_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "account_tsvector" */
export type account_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type account_tsvector_sum_fields = {
  __typename: 'account_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type account_tsvector_var_pop_fields = {
  __typename: 'account_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type account_tsvector_var_samp_fields = {
  __typename: 'account_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type account_tsvector_variance_fields = {
  __typename: 'account_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "account" */
export enum account_update_column {
  /** column name */
  create_missing_properties = 'create_missing_properties',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  customer_portal_enabled = 'customer_portal_enabled',
  /** column name */
  default_campaign_id = 'default_campaign_id',
  /** column name */
  default_flow_id = 'default_flow_id',
  /** column name */
  emails_disabled = 'emails_disabled',
  /** column name */
  enterprise = 'enterprise',
  /** column name */
  exclude_pii_in_flow_result = 'exclude_pii_in_flow_result',
  /** column name */
  favicon_url = 'favicon_url',
  /** column name */
  flow_css = 'flow_css',
  /** column name */
  id = 'id',
  /** column name */
  intake_form_name_email = 'intake_form_name_email',
  /** column name */
  intake_require_match = 'intake_require_match',
  /** column name */
  internal_testing = 'internal_testing',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  mode = 'mode',
  /** column name */
  owner_user_id = 'owner_user_id',
  /** column name */
  platform_id_property_id = 'platform_id_property_id',
  /** column name */
  prompt_for_subscriber_details = 'prompt_for_subscriber_details',
  /** column name */
  prosperstack = 'prosperstack',
  /** column name */
  require_payment_method = 'require_payment_method',
  /** column name */
  revenue_mode = 'revenue_mode',
  /** column name */
  secret = 'secret',
  /** column name */
  show_tour = 'show_tour',
  /** column name */
  sso_connection = 'sso_connection',
  /** column name */
  sso_domain = 'sso_domain',
  /** column name */
  sso_log_in_url = 'sso_log_in_url',
  /** column name */
  sso_required = 'sso_required',
  /** column name */
  status = 'status',
  /** column name */
  stripe_customer_id = 'stripe_customer_id',
  /** column name */
  stripe_subscription_id = 'stripe_subscription_id',
  /** column name */
  test_mode_for_account_id = 'test_mode_for_account_id',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  use_subscriber_id_as_subscription_id = 'use_subscriber_id_as_subscription_id'
}

export type account_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<account_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_set_input>;
  /** filter the rows which have to be updated */
  where: account_bool_exp;
};

/** columns and relationships of "account_user" */
export type account_user = {
  __typename: 'account_user';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  user: user;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "account_user" */
export type account_user_aggregate = {
  __typename: 'account_user_aggregate';
  aggregate?: Maybe<account_user_aggregate_fields>;
  nodes: Array<account_user>;
};

export type account_user_aggregate_bool_exp = {
  count?: InputMaybe<account_user_aggregate_bool_exp_count>;
};

export type account_user_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<account_user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_user_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "account_user" */
export type account_user_aggregate_fields = {
  __typename: 'account_user_aggregate_fields';
  avg?: Maybe<account_user_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_user_max_fields>;
  min?: Maybe<account_user_min_fields>;
  stddev?: Maybe<account_user_stddev_fields>;
  stddev_pop?: Maybe<account_user_stddev_pop_fields>;
  stddev_samp?: Maybe<account_user_stddev_samp_fields>;
  sum?: Maybe<account_user_sum_fields>;
  var_pop?: Maybe<account_user_var_pop_fields>;
  var_samp?: Maybe<account_user_var_samp_fields>;
  variance?: Maybe<account_user_variance_fields>;
};


/** aggregate fields of "account_user" */
export type account_user_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_user" */
export type account_user_aggregate_order_by = {
  avg?: InputMaybe<account_user_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<account_user_max_order_by>;
  min?: InputMaybe<account_user_min_order_by>;
  stddev?: InputMaybe<account_user_stddev_order_by>;
  stddev_pop?: InputMaybe<account_user_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<account_user_stddev_samp_order_by>;
  sum?: InputMaybe<account_user_sum_order_by>;
  var_pop?: InputMaybe<account_user_var_pop_order_by>;
  var_samp?: InputMaybe<account_user_var_samp_order_by>;
  variance?: InputMaybe<account_user_variance_order_by>;
};

/** input type for inserting array relation for remote table "account_user" */
export type account_user_arr_rel_insert_input = {
  data: Array<account_user_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<account_user_on_conflict>;
};

/** aggregate avg on columns */
export type account_user_avg_fields = {
  __typename: 'account_user_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_user" */
export type account_user_avg_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account_user". All fields are combined with a logical 'AND'. */
export type account_user_bool_exp = {
  _and?: InputMaybe<Array<account_user_bool_exp>>;
  _not?: InputMaybe<account_user_bool_exp>;
  _or?: InputMaybe<Array<account_user_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_user" */
export enum account_user_constraint {
  /** unique or primary key constraint on columns "account_id", "user_id" */
  account_user_pkey = 'account_user_pkey'
}

/** input type for incrementing numeric columns in table "account_user" */
export type account_user_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "account_user" */
export type account_user_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_user_max_fields = {
  __typename: 'account_user_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "account_user" */
export type account_user_max_order_by = {
  account_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_user_min_fields = {
  __typename: 'account_user_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "account_user" */
export type account_user_min_order_by = {
  account_id?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "account_user" */
export type account_user_mutation_response = {
  __typename: 'account_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_user>;
};

/** on_conflict condition type for table "account_user" */
export type account_user_on_conflict = {
  constraint: account_user_constraint;
  update_columns?: Array<account_user_update_column>;
  where?: InputMaybe<account_user_bool_exp>;
};

/** Ordering options when selecting data from "account_user". */
export type account_user_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_user */
export type account_user_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  user_id: Scalars['String']['input'];
};

/** select columns of table "account_user" */
export enum account_user_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "account_user" */
export type account_user_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "account_user_setting" */
export type account_user_setting = {
  __typename: 'account_user_setting';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  account_user_setting_key: account_user_setting_key;
  created_at: Scalars['timestamptz']['output'];
  key: account_user_setting_key_enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: user;
  user_id: Scalars['String']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "account_user_setting" */
export type account_user_settingvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "account_user_setting" */
export type account_user_setting_aggregate = {
  __typename: 'account_user_setting_aggregate';
  aggregate?: Maybe<account_user_setting_aggregate_fields>;
  nodes: Array<account_user_setting>;
};

export type account_user_setting_aggregate_bool_exp = {
  count?: InputMaybe<account_user_setting_aggregate_bool_exp_count>;
};

export type account_user_setting_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<account_user_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<account_user_setting_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "account_user_setting" */
export type account_user_setting_aggregate_fields = {
  __typename: 'account_user_setting_aggregate_fields';
  avg?: Maybe<account_user_setting_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<account_user_setting_max_fields>;
  min?: Maybe<account_user_setting_min_fields>;
  stddev?: Maybe<account_user_setting_stddev_fields>;
  stddev_pop?: Maybe<account_user_setting_stddev_pop_fields>;
  stddev_samp?: Maybe<account_user_setting_stddev_samp_fields>;
  sum?: Maybe<account_user_setting_sum_fields>;
  var_pop?: Maybe<account_user_setting_var_pop_fields>;
  var_samp?: Maybe<account_user_setting_var_samp_fields>;
  variance?: Maybe<account_user_setting_variance_fields>;
};


/** aggregate fields of "account_user_setting" */
export type account_user_setting_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_user_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_user_setting" */
export type account_user_setting_aggregate_order_by = {
  avg?: InputMaybe<account_user_setting_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<account_user_setting_max_order_by>;
  min?: InputMaybe<account_user_setting_min_order_by>;
  stddev?: InputMaybe<account_user_setting_stddev_order_by>;
  stddev_pop?: InputMaybe<account_user_setting_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<account_user_setting_stddev_samp_order_by>;
  sum?: InputMaybe<account_user_setting_sum_order_by>;
  var_pop?: InputMaybe<account_user_setting_var_pop_order_by>;
  var_samp?: InputMaybe<account_user_setting_var_samp_order_by>;
  variance?: InputMaybe<account_user_setting_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type account_user_setting_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "account_user_setting" */
export type account_user_setting_arr_rel_insert_input = {
  data: Array<account_user_setting_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<account_user_setting_on_conflict>;
};

/** aggregate avg on columns */
export type account_user_setting_avg_fields = {
  __typename: 'account_user_setting_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_user_setting" */
export type account_user_setting_avg_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "account_user_setting". All fields are combined with a logical 'AND'. */
export type account_user_setting_bool_exp = {
  _and?: InputMaybe<Array<account_user_setting_bool_exp>>;
  _not?: InputMaybe<account_user_setting_bool_exp>;
  _or?: InputMaybe<Array<account_user_setting_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  account_user_setting_key?: InputMaybe<account_user_setting_key_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  key?: InputMaybe<account_user_setting_key_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<String_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "account_user_setting" */
export enum account_user_setting_constraint {
  /** unique or primary key constraint on columns "key", "account_id", "user_id" */
  account_user_setting_pkey = 'account_user_setting_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type account_user_setting_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type account_user_setting_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type account_user_setting_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "account_user_setting" */
export type account_user_setting_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "account_user_setting" */
export type account_user_setting_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  account_user_setting_key?: InputMaybe<account_user_setting_key_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_user_setting_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "account_user_setting_key" */
export type account_user_setting_key = {
  __typename: 'account_user_setting_key';
  /** An array relationship */
  account_user_settings: Array<account_user_setting>;
  /** An aggregate relationship */
  account_user_settings_aggregate: account_user_setting_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "account_user_setting_key" */
export type account_user_setting_keyaccount_user_settingsArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


/** columns and relationships of "account_user_setting_key" */
export type account_user_setting_keyaccount_user_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};

/** aggregated selection of "account_user_setting_key" */
export type account_user_setting_key_aggregate = {
  __typename: 'account_user_setting_key_aggregate';
  aggregate?: Maybe<account_user_setting_key_aggregate_fields>;
  nodes: Array<account_user_setting_key>;
};

/** aggregate fields of "account_user_setting_key" */
export type account_user_setting_key_aggregate_fields = {
  __typename: 'account_user_setting_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<account_user_setting_key_max_fields>;
  min?: Maybe<account_user_setting_key_min_fields>;
};


/** aggregate fields of "account_user_setting_key" */
export type account_user_setting_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<account_user_setting_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "account_user_setting_key". All fields are combined with a logical 'AND'. */
export type account_user_setting_key_bool_exp = {
  _and?: InputMaybe<Array<account_user_setting_key_bool_exp>>;
  _not?: InputMaybe<account_user_setting_key_bool_exp>;
  _or?: InputMaybe<Array<account_user_setting_key_bool_exp>>;
  account_user_settings?: InputMaybe<account_user_setting_bool_exp>;
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "account_user_setting_key" */
export enum account_user_setting_key_constraint {
  /** unique or primary key constraint on columns "value" */
  account_user_setting_key_pkey = 'account_user_setting_key_pkey'
}

export enum account_user_setting_key_enum {
  email_canceled_saved = 'email_canceled_saved',
  email_cancels = 'email_cancels',
  email_deflections = 'email_deflections',
  email_new_sessions = 'email_new_sessions',
  email_saves = 'email_saves',
  setup_dismissed = 'setup_dismissed',
  start_session_warning_dismissed = 'start_session_warning_dismissed'
}

/** Boolean expression to compare columns of type "account_user_setting_key_enum". All fields are combined with logical 'AND'. */
export type account_user_setting_key_enum_comparison_exp = {
  _eq?: InputMaybe<account_user_setting_key_enum>;
  _in?: InputMaybe<Array<account_user_setting_key_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<account_user_setting_key_enum>;
  _nin?: InputMaybe<Array<account_user_setting_key_enum>>;
};

/** input type for inserting data into table "account_user_setting_key" */
export type account_user_setting_key_insert_input = {
  account_user_settings?: InputMaybe<account_user_setting_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type account_user_setting_key_max_fields = {
  __typename: 'account_user_setting_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type account_user_setting_key_min_fields = {
  __typename: 'account_user_setting_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "account_user_setting_key" */
export type account_user_setting_key_mutation_response = {
  __typename: 'account_user_setting_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_user_setting_key>;
};

/** input type for inserting object relation for remote table "account_user_setting_key" */
export type account_user_setting_key_obj_rel_insert_input = {
  data: account_user_setting_key_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<account_user_setting_key_on_conflict>;
};

/** on_conflict condition type for table "account_user_setting_key" */
export type account_user_setting_key_on_conflict = {
  constraint: account_user_setting_key_constraint;
  update_columns?: Array<account_user_setting_key_update_column>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};

/** Ordering options when selecting data from "account_user_setting_key". */
export type account_user_setting_key_order_by = {
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_user_setting_key */
export type account_user_setting_key_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "account_user_setting_key" */
export enum account_user_setting_key_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_user_setting_key" */
export type account_user_setting_key_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "account_user_setting_key" */
export type account_user_setting_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_user_setting_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_user_setting_key_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "account_user_setting_key" */
export enum account_user_setting_key_update_column {
  /** column name */
  value = 'value'
}

export type account_user_setting_key_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_user_setting_key_set_input>;
  /** filter the rows which have to be updated */
  where: account_user_setting_key_bool_exp;
};

/** aggregate max on columns */
export type account_user_setting_max_fields = {
  __typename: 'account_user_setting_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "account_user_setting" */
export type account_user_setting_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type account_user_setting_min_fields = {
  __typename: 'account_user_setting_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "account_user_setting" */
export type account_user_setting_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "account_user_setting" */
export type account_user_setting_mutation_response = {
  __typename: 'account_user_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<account_user_setting>;
};

/** on_conflict condition type for table "account_user_setting" */
export type account_user_setting_on_conflict = {
  constraint: account_user_setting_constraint;
  update_columns?: Array<account_user_setting_update_column>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};

/** Ordering options when selecting data from "account_user_setting". */
export type account_user_setting_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  account_user_setting_key?: InputMaybe<account_user_setting_key_order_by>;
  created_at?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: account_user_setting */
export type account_user_setting_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  key: account_user_setting_key_enum;
  user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type account_user_setting_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "account_user_setting" */
export enum account_user_setting_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  key = 'key',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "account_user_setting" */
export type account_user_setting_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_user_setting_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type account_user_setting_stddev_fields = {
  __typename: 'account_user_setting_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_user_setting" */
export type account_user_setting_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_user_setting_stddev_pop_fields = {
  __typename: 'account_user_setting_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_user_setting" */
export type account_user_setting_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_user_setting_stddev_samp_fields = {
  __typename: 'account_user_setting_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_user_setting" */
export type account_user_setting_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "account_user_setting" */
export type account_user_setting_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_user_setting_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_user_setting_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<account_user_setting_key_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type account_user_setting_sum_fields = {
  __typename: 'account_user_setting_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "account_user_setting" */
export type account_user_setting_sum_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** update columns of table "account_user_setting" */
export enum account_user_setting_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  key = 'key',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  value = 'value'
}

export type account_user_setting_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<account_user_setting_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<account_user_setting_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<account_user_setting_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<account_user_setting_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<account_user_setting_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<account_user_setting_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_user_setting_set_input>;
  /** filter the rows which have to be updated */
  where: account_user_setting_bool_exp;
};

/** aggregate var_pop on columns */
export type account_user_setting_var_pop_fields = {
  __typename: 'account_user_setting_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_user_setting" */
export type account_user_setting_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_user_setting_var_samp_fields = {
  __typename: 'account_user_setting_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_user_setting" */
export type account_user_setting_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_user_setting_variance_fields = {
  __typename: 'account_user_setting_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_user_setting" */
export type account_user_setting_variance_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev on columns */
export type account_user_stddev_fields = {
  __typename: 'account_user_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_user" */
export type account_user_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type account_user_stddev_pop_fields = {
  __typename: 'account_user_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_user" */
export type account_user_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type account_user_stddev_samp_fields = {
  __typename: 'account_user_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_user" */
export type account_user_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "account_user" */
export type account_user_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: account_user_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type account_user_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type account_user_sum_fields = {
  __typename: 'account_user_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "account_user" */
export type account_user_sum_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** update columns of table "account_user" */
export enum account_user_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  user_id = 'user_id'
}

export type account_user_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<account_user_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<account_user_set_input>;
  /** filter the rows which have to be updated */
  where: account_user_bool_exp;
};

/** aggregate var_pop on columns */
export type account_user_var_pop_fields = {
  __typename: 'account_user_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_user" */
export type account_user_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_user_var_samp_fields = {
  __typename: 'account_user_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_user" */
export type account_user_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_user_variance_fields = {
  __typename: 'account_user_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_user" */
export type account_user_variance_order_by = {
  account_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type account_var_pop_fields = {
  __typename: 'account_var_pop_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account" */
export type account_var_pop_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type account_var_samp_fields = {
  __typename: 'account_var_samp_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account" */
export type account_var_samp_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type account_variance_fields = {
  __typename: 'account_variance_fields';
  default_campaign_id?: Maybe<Scalars['Float']['output']>;
  default_flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  platform_id_property_id?: Maybe<Scalars['Float']['output']>;
  test_mode_for_account_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account" */
export type account_variance_order_by = {
  default_campaign_id?: InputMaybe<order_by>;
  default_flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id_property_id?: InputMaybe<order_by>;
  test_mode_for_account_id?: InputMaybe<order_by>;
};

/** columns and relationships of "acknowledgement" */
export type acknowledgement = {
  __typename: 'acknowledgement';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An array relationship */
  acknowledgement_group_version_acknowledgements: Array<acknowledgement_group_version_acknowledgement>;
  /** An aggregate relationship */
  acknowledgement_group_version_acknowledgements_aggregate: acknowledgement_group_version_acknowledgement_aggregate;
  /** An array relationship */
  acknowledgement_versions: Array<acknowledgement_version>;
  /** An aggregate relationship */
  acknowledgement_versions_aggregate: acknowledgement_version_aggregate;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  draft_version?: Maybe<acknowledgement_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<acknowledgement_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  subscriber_acknowledgements: Array<subscriber_acknowledgement>;
  /** An aggregate relationship */
  subscriber_acknowledgements_aggregate: subscriber_acknowledgement_aggregate;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementacknowledgement_group_version_acknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementacknowledgement_group_version_acknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementacknowledgement_versionsArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementacknowledgement_versions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementsubscriber_acknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


/** columns and relationships of "acknowledgement" */
export type acknowledgementsubscriber_acknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};

/** aggregated selection of "acknowledgement" */
export type acknowledgement_aggregate = {
  __typename: 'acknowledgement_aggregate';
  aggregate?: Maybe<acknowledgement_aggregate_fields>;
  nodes: Array<acknowledgement>;
};

export type acknowledgement_aggregate_bool_exp = {
  count?: InputMaybe<acknowledgement_aggregate_bool_exp_count>;
};

export type acknowledgement_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<acknowledgement_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "acknowledgement" */
export type acknowledgement_aggregate_fields = {
  __typename: 'acknowledgement_aggregate_fields';
  avg?: Maybe<acknowledgement_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<acknowledgement_max_fields>;
  min?: Maybe<acknowledgement_min_fields>;
  stddev?: Maybe<acknowledgement_stddev_fields>;
  stddev_pop?: Maybe<acknowledgement_stddev_pop_fields>;
  stddev_samp?: Maybe<acknowledgement_stddev_samp_fields>;
  sum?: Maybe<acknowledgement_sum_fields>;
  var_pop?: Maybe<acknowledgement_var_pop_fields>;
  var_samp?: Maybe<acknowledgement_var_samp_fields>;
  variance?: Maybe<acknowledgement_variance_fields>;
};


/** aggregate fields of "acknowledgement" */
export type acknowledgement_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "acknowledgement" */
export type acknowledgement_aggregate_order_by = {
  avg?: InputMaybe<acknowledgement_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<acknowledgement_max_order_by>;
  min?: InputMaybe<acknowledgement_min_order_by>;
  stddev?: InputMaybe<acknowledgement_stddev_order_by>;
  stddev_pop?: InputMaybe<acknowledgement_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<acknowledgement_stddev_samp_order_by>;
  sum?: InputMaybe<acknowledgement_sum_order_by>;
  var_pop?: InputMaybe<acknowledgement_var_pop_order_by>;
  var_samp?: InputMaybe<acknowledgement_var_samp_order_by>;
  variance?: InputMaybe<acknowledgement_variance_order_by>;
};

/** input type for inserting array relation for remote table "acknowledgement" */
export type acknowledgement_arr_rel_insert_input = {
  data: Array<acknowledgement_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_on_conflict>;
};

/** aggregate avg on columns */
export type acknowledgement_avg_fields = {
  __typename: 'acknowledgement_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "acknowledgement" */
export type acknowledgement_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "acknowledgement". All fields are combined with a logical 'AND'. */
export type acknowledgement_bool_exp = {
  _and?: InputMaybe<Array<acknowledgement_bool_exp>>;
  _not?: InputMaybe<acknowledgement_bool_exp>;
  _or?: InputMaybe<Array<acknowledgement_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  acknowledgement_group_version_acknowledgements?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
  acknowledgement_group_version_acknowledgements_aggregate?: InputMaybe<acknowledgement_group_version_acknowledgement_aggregate_bool_exp>;
  acknowledgement_versions?: InputMaybe<acknowledgement_version_bool_exp>;
  acknowledgement_versions_aggregate?: InputMaybe<acknowledgement_version_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<acknowledgement_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<acknowledgement_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_bool_exp>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_bool_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "acknowledgement" */
export enum acknowledgement_constraint {
  /** unique or primary key constraint on columns "id" */
  acknowledgement_pkey = 'acknowledgement_pkey'
}

/** columns and relationships of "acknowledgement_group" */
export type acknowledgement_group = {
  __typename: 'acknowledgement_group';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<acknowledgement_group_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_acknowledgement_groups: Array<flow_step_acknowledgement_group>;
  /** An aggregate relationship */
  flow_step_acknowledgement_groups_aggregate: flow_step_acknowledgement_group_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<acknowledgement_group_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "acknowledgement_group" */
export type acknowledgement_groupflow_step_acknowledgement_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


/** columns and relationships of "acknowledgement_group" */
export type acknowledgement_groupflow_step_acknowledgement_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};

/** aggregated selection of "acknowledgement_group" */
export type acknowledgement_group_aggregate = {
  __typename: 'acknowledgement_group_aggregate';
  aggregate?: Maybe<acknowledgement_group_aggregate_fields>;
  nodes: Array<acknowledgement_group>;
};

/** aggregate fields of "acknowledgement_group" */
export type acknowledgement_group_aggregate_fields = {
  __typename: 'acknowledgement_group_aggregate_fields';
  avg?: Maybe<acknowledgement_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<acknowledgement_group_max_fields>;
  min?: Maybe<acknowledgement_group_min_fields>;
  stddev?: Maybe<acknowledgement_group_stddev_fields>;
  stddev_pop?: Maybe<acknowledgement_group_stddev_pop_fields>;
  stddev_samp?: Maybe<acknowledgement_group_stddev_samp_fields>;
  sum?: Maybe<acknowledgement_group_sum_fields>;
  var_pop?: Maybe<acknowledgement_group_var_pop_fields>;
  var_samp?: Maybe<acknowledgement_group_var_samp_fields>;
  variance?: Maybe<acknowledgement_group_variance_fields>;
};


/** aggregate fields of "acknowledgement_group" */
export type acknowledgement_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<acknowledgement_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type acknowledgement_group_avg_fields = {
  __typename: 'acknowledgement_group_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "acknowledgement_group". All fields are combined with a logical 'AND'. */
export type acknowledgement_group_bool_exp = {
  _and?: InputMaybe<Array<acknowledgement_group_bool_exp>>;
  _not?: InputMaybe<acknowledgement_group_bool_exp>;
  _or?: InputMaybe<Array<acknowledgement_group_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<acknowledgement_group_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_acknowledgement_groups?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
  flow_step_acknowledgement_groups_aggregate?: InputMaybe<flow_step_acknowledgement_group_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<acknowledgement_group_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "acknowledgement_group" */
export enum acknowledgement_group_constraint {
  /** unique or primary key constraint on columns "id" */
  acknowledgement_group_pkey = 'acknowledgement_group_pkey'
}

/** input type for incrementing numeric columns in table "acknowledgement_group" */
export type acknowledgement_group_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "acknowledgement_group" */
export type acknowledgement_group_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<acknowledgement_group_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_acknowledgement_groups?: InputMaybe<flow_step_acknowledgement_group_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<acknowledgement_group_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type acknowledgement_group_max_fields = {
  __typename: 'acknowledgement_group_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type acknowledgement_group_min_fields = {
  __typename: 'acknowledgement_group_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "acknowledgement_group" */
export type acknowledgement_group_mutation_response = {
  __typename: 'acknowledgement_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<acknowledgement_group>;
};

/** input type for inserting object relation for remote table "acknowledgement_group" */
export type acknowledgement_group_obj_rel_insert_input = {
  data: acknowledgement_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_group_on_conflict>;
};

/** on_conflict condition type for table "acknowledgement_group" */
export type acknowledgement_group_on_conflict = {
  constraint: acknowledgement_group_constraint;
  update_columns?: Array<acknowledgement_group_update_column>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};

/** Ordering options when selecting data from "acknowledgement_group". */
export type acknowledgement_group_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<acknowledgement_group_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_acknowledgement_groups_aggregate?: InputMaybe<flow_step_acknowledgement_group_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<acknowledgement_group_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: acknowledgement_group */
export type acknowledgement_group_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "acknowledgement_group" */
export enum acknowledgement_group_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "acknowledgement_group" */
export type acknowledgement_group_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type acknowledgement_group_stddev_fields = {
  __typename: 'acknowledgement_group_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type acknowledgement_group_stddev_pop_fields = {
  __typename: 'acknowledgement_group_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type acknowledgement_group_stddev_samp_fields = {
  __typename: 'acknowledgement_group_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "acknowledgement_group" */
export type acknowledgement_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: acknowledgement_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type acknowledgement_group_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type acknowledgement_group_sum_fields = {
  __typename: 'acknowledgement_group_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "acknowledgement_group" */
export enum acknowledgement_group_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

export type acknowledgement_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<acknowledgement_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<acknowledgement_group_set_input>;
  /** filter the rows which have to be updated */
  where: acknowledgement_group_bool_exp;
};

/** aggregate var_pop on columns */
export type acknowledgement_group_var_pop_fields = {
  __typename: 'acknowledgement_group_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type acknowledgement_group_var_samp_fields = {
  __typename: 'acknowledgement_group_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type acknowledgement_group_variance_fields = {
  __typename: 'acknowledgement_group_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "acknowledgement_group_version" */
export type acknowledgement_group_version = {
  __typename: 'acknowledgement_group_version';
  /** An object relationship */
  acknowledgement_group: acknowledgement_group;
  acknowledgement_group_id: Scalars['Int']['output'];
  /** An array relationship */
  acknowledgement_group_version_acknowledgements: Array<acknowledgement_group_version_acknowledgement>;
  /** An aggregate relationship */
  acknowledgement_group_version_acknowledgements_aggregate: acknowledgement_group_version_acknowledgement_aggregate;
  acknowledgement_ids: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  title_translation: translation;
  title_translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "acknowledgement_group_version" */
export type acknowledgement_group_versionacknowledgement_group_version_acknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


/** columns and relationships of "acknowledgement_group_version" */
export type acknowledgement_group_versionacknowledgement_group_version_acknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


/** columns and relationships of "acknowledgement_group_version" */
export type acknowledgement_group_versionacknowledgement_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement = {
  __typename: 'acknowledgement_group_version_acknowledgement';
  /** An object relationship */
  acknowledgement?: Maybe<acknowledgement>;
  /** An object relationship */
  acknowledgement_group_version?: Maybe<acknowledgement_group_version>;
  acknowledgement_group_version_id?: Maybe<Scalars['Int']['output']>;
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_aggregate = {
  __typename: 'acknowledgement_group_version_acknowledgement_aggregate';
  aggregate?: Maybe<acknowledgement_group_version_acknowledgement_aggregate_fields>;
  nodes: Array<acknowledgement_group_version_acknowledgement>;
};

export type acknowledgement_group_version_acknowledgement_aggregate_bool_exp = {
  count?: InputMaybe<acknowledgement_group_version_acknowledgement_aggregate_bool_exp_count>;
};

export type acknowledgement_group_version_acknowledgement_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_aggregate_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_aggregate_fields';
  avg?: Maybe<acknowledgement_group_version_acknowledgement_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<acknowledgement_group_version_acknowledgement_max_fields>;
  min?: Maybe<acknowledgement_group_version_acknowledgement_min_fields>;
  stddev?: Maybe<acknowledgement_group_version_acknowledgement_stddev_fields>;
  stddev_pop?: Maybe<acknowledgement_group_version_acknowledgement_stddev_pop_fields>;
  stddev_samp?: Maybe<acknowledgement_group_version_acknowledgement_stddev_samp_fields>;
  sum?: Maybe<acknowledgement_group_version_acknowledgement_sum_fields>;
  var_pop?: Maybe<acknowledgement_group_version_acknowledgement_var_pop_fields>;
  var_samp?: Maybe<acknowledgement_group_version_acknowledgement_var_samp_fields>;
  variance?: Maybe<acknowledgement_group_version_acknowledgement_variance_fields>;
};


/** aggregate fields of "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_aggregate_order_by = {
  avg?: InputMaybe<acknowledgement_group_version_acknowledgement_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<acknowledgement_group_version_acknowledgement_max_order_by>;
  min?: InputMaybe<acknowledgement_group_version_acknowledgement_min_order_by>;
  stddev?: InputMaybe<acknowledgement_group_version_acknowledgement_stddev_order_by>;
  stddev_pop?: InputMaybe<acknowledgement_group_version_acknowledgement_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<acknowledgement_group_version_acknowledgement_stddev_samp_order_by>;
  sum?: InputMaybe<acknowledgement_group_version_acknowledgement_sum_order_by>;
  var_pop?: InputMaybe<acknowledgement_group_version_acknowledgement_var_pop_order_by>;
  var_samp?: InputMaybe<acknowledgement_group_version_acknowledgement_var_samp_order_by>;
  variance?: InputMaybe<acknowledgement_group_version_acknowledgement_variance_order_by>;
};

/** input type for inserting array relation for remote table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_arr_rel_insert_input = {
  data: Array<acknowledgement_group_version_acknowledgement_insert_input>;
};

/** aggregate avg on columns */
export type acknowledgement_group_version_acknowledgement_avg_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_avg_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_avg_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "acknowledgement_group_version_acknowledgement". All fields are combined with a logical 'AND'. */
export type acknowledgement_group_version_acknowledgement_bool_exp = {
  _and?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_bool_exp>>;
  _not?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
  _or?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_bool_exp>>;
  acknowledgement?: InputMaybe<acknowledgement_bool_exp>;
  acknowledgement_group_version?: InputMaybe<acknowledgement_group_version_bool_exp>;
  acknowledgement_group_version_id?: InputMaybe<Int_comparison_exp>;
  acknowledgement_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_insert_input = {
  acknowledgement?: InputMaybe<acknowledgement_obj_rel_insert_input>;
  acknowledgement_group_version?: InputMaybe<acknowledgement_group_version_obj_rel_insert_input>;
  acknowledgement_group_version_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type acknowledgement_group_version_acknowledgement_max_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_max_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Int']['output']>;
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_max_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type acknowledgement_group_version_acknowledgement_min_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_min_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Int']['output']>;
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_min_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "acknowledgement_group_version_acknowledgement". */
export type acknowledgement_group_version_acknowledgement_order_by = {
  acknowledgement?: InputMaybe<acknowledgement_order_by>;
  acknowledgement_group_version?: InputMaybe<acknowledgement_group_version_order_by>;
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "acknowledgement_group_version_acknowledgement" */
export enum acknowledgement_group_version_acknowledgement_select_column {
  /** column name */
  acknowledgement_group_version_id = 'acknowledgement_group_version_id',
  /** column name */
  acknowledgement_id = 'acknowledgement_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type acknowledgement_group_version_acknowledgement_stddev_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_stddev_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_stddev_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type acknowledgement_group_version_acknowledgement_stddev_pop_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_stddev_pop_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_stddev_pop_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type acknowledgement_group_version_acknowledgement_stddev_samp_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_stddev_samp_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_stddev_samp_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: acknowledgement_group_version_acknowledgement_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type acknowledgement_group_version_acknowledgement_stream_cursor_value_input = {
  acknowledgement_group_version_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type acknowledgement_group_version_acknowledgement_sum_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_sum_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Int']['output']>;
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_sum_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type acknowledgement_group_version_acknowledgement_var_pop_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_var_pop_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_var_pop_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type acknowledgement_group_version_acknowledgement_var_samp_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_var_samp_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_var_samp_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type acknowledgement_group_version_acknowledgement_variance_fields = {
  __typename: 'acknowledgement_group_version_acknowledgement_variance_fields';
  acknowledgement_group_version_id?: Maybe<Scalars['Float']['output']>;
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "acknowledgement_group_version_acknowledgement" */
export type acknowledgement_group_version_acknowledgement_variance_order_by = {
  acknowledgement_group_version_id?: InputMaybe<order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregated selection of "acknowledgement_group_version" */
export type acknowledgement_group_version_aggregate = {
  __typename: 'acknowledgement_group_version_aggregate';
  aggregate?: Maybe<acknowledgement_group_version_aggregate_fields>;
  nodes: Array<acknowledgement_group_version>;
};

/** aggregate fields of "acknowledgement_group_version" */
export type acknowledgement_group_version_aggregate_fields = {
  __typename: 'acknowledgement_group_version_aggregate_fields';
  avg?: Maybe<acknowledgement_group_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<acknowledgement_group_version_max_fields>;
  min?: Maybe<acknowledgement_group_version_min_fields>;
  stddev?: Maybe<acknowledgement_group_version_stddev_fields>;
  stddev_pop?: Maybe<acknowledgement_group_version_stddev_pop_fields>;
  stddev_samp?: Maybe<acknowledgement_group_version_stddev_samp_fields>;
  sum?: Maybe<acknowledgement_group_version_sum_fields>;
  var_pop?: Maybe<acknowledgement_group_version_var_pop_fields>;
  var_samp?: Maybe<acknowledgement_group_version_var_samp_fields>;
  variance?: Maybe<acknowledgement_group_version_variance_fields>;
};


/** aggregate fields of "acknowledgement_group_version" */
export type acknowledgement_group_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<acknowledgement_group_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type acknowledgement_group_version_append_input = {
  acknowledgement_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type acknowledgement_group_version_avg_fields = {
  __typename: 'acknowledgement_group_version_avg_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "acknowledgement_group_version". All fields are combined with a logical 'AND'. */
export type acknowledgement_group_version_bool_exp = {
  _and?: InputMaybe<Array<acknowledgement_group_version_bool_exp>>;
  _not?: InputMaybe<acknowledgement_group_version_bool_exp>;
  _or?: InputMaybe<Array<acknowledgement_group_version_bool_exp>>;
  acknowledgement_group?: InputMaybe<acknowledgement_group_bool_exp>;
  acknowledgement_group_id?: InputMaybe<Int_comparison_exp>;
  acknowledgement_group_version_acknowledgements?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
  acknowledgement_group_version_acknowledgements_aggregate?: InputMaybe<acknowledgement_group_version_acknowledgement_aggregate_bool_exp>;
  acknowledgement_ids?: InputMaybe<jsonb_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  title_translation?: InputMaybe<translation_bool_exp>;
  title_translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "acknowledgement_group_version" */
export enum acknowledgement_group_version_constraint {
  /** unique or primary key constraint on columns "id" */
  acknowledgement_group_version_pkey = 'acknowledgement_group_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type acknowledgement_group_version_delete_at_path_input = {
  acknowledgement_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type acknowledgement_group_version_delete_elem_input = {
  acknowledgement_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type acknowledgement_group_version_delete_key_input = {
  acknowledgement_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "acknowledgement_group_version" */
export type acknowledgement_group_version_inc_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "acknowledgement_group_version" */
export type acknowledgement_group_version_insert_input = {
  acknowledgement_group?: InputMaybe<acknowledgement_group_obj_rel_insert_input>;
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_group_version_acknowledgements?: InputMaybe<acknowledgement_group_version_acknowledgement_arr_rel_insert_input>;
  acknowledgement_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title_translation?: InputMaybe<translation_obj_rel_insert_input>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type acknowledgement_group_version_max_fields = {
  __typename: 'acknowledgement_group_version_max_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type acknowledgement_group_version_min_fields = {
  __typename: 'acknowledgement_group_version_min_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "acknowledgement_group_version" */
export type acknowledgement_group_version_mutation_response = {
  __typename: 'acknowledgement_group_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<acknowledgement_group_version>;
};

/** input type for inserting object relation for remote table "acknowledgement_group_version" */
export type acknowledgement_group_version_obj_rel_insert_input = {
  data: acknowledgement_group_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_group_version_on_conflict>;
};

/** on_conflict condition type for table "acknowledgement_group_version" */
export type acknowledgement_group_version_on_conflict = {
  constraint: acknowledgement_group_version_constraint;
  update_columns?: Array<acknowledgement_group_version_update_column>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};

/** Ordering options when selecting data from "acknowledgement_group_version". */
export type acknowledgement_group_version_order_by = {
  acknowledgement_group?: InputMaybe<acknowledgement_group_order_by>;
  acknowledgement_group_id?: InputMaybe<order_by>;
  acknowledgement_group_version_acknowledgements_aggregate?: InputMaybe<acknowledgement_group_version_acknowledgement_aggregate_order_by>;
  acknowledgement_ids?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  title_translation?: InputMaybe<translation_order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: acknowledgement_group_version */
export type acknowledgement_group_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type acknowledgement_group_version_prepend_input = {
  acknowledgement_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "acknowledgement_group_version" */
export enum acknowledgement_group_version_select_column {
  /** column name */
  acknowledgement_group_id = 'acknowledgement_group_id',
  /** column name */
  acknowledgement_ids = 'acknowledgement_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "acknowledgement_group_version" */
export type acknowledgement_group_version_set_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type acknowledgement_group_version_stddev_fields = {
  __typename: 'acknowledgement_group_version_stddev_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type acknowledgement_group_version_stddev_pop_fields = {
  __typename: 'acknowledgement_group_version_stddev_pop_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type acknowledgement_group_version_stddev_samp_fields = {
  __typename: 'acknowledgement_group_version_stddev_samp_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "acknowledgement_group_version" */
export type acknowledgement_group_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: acknowledgement_group_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type acknowledgement_group_version_stream_cursor_value_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type acknowledgement_group_version_sum_fields = {
  __typename: 'acknowledgement_group_version_sum_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "acknowledgement_group_version" */
export enum acknowledgement_group_version_update_column {
  /** column name */
  acknowledgement_group_id = 'acknowledgement_group_id',
  /** column name */
  acknowledgement_ids = 'acknowledgement_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type acknowledgement_group_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<acknowledgement_group_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<acknowledgement_group_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<acknowledgement_group_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<acknowledgement_group_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<acknowledgement_group_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<acknowledgement_group_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<acknowledgement_group_version_set_input>;
  /** filter the rows which have to be updated */
  where: acknowledgement_group_version_bool_exp;
};

/** aggregate var_pop on columns */
export type acknowledgement_group_version_var_pop_fields = {
  __typename: 'acknowledgement_group_version_var_pop_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type acknowledgement_group_version_var_samp_fields = {
  __typename: 'acknowledgement_group_version_var_samp_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type acknowledgement_group_version_variance_fields = {
  __typename: 'acknowledgement_group_version_variance_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "acknowledgement" */
export type acknowledgement_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "acknowledgement" */
export type acknowledgement_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  acknowledgement_group_version_acknowledgements?: InputMaybe<acknowledgement_group_version_acknowledgement_arr_rel_insert_input>;
  acknowledgement_versions?: InputMaybe<acknowledgement_version_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<acknowledgement_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<acknowledgement_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_arr_rel_insert_input>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type acknowledgement_max_fields = {
  __typename: 'acknowledgement_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "acknowledgement" */
export type acknowledgement_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type acknowledgement_min_fields = {
  __typename: 'acknowledgement_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "acknowledgement" */
export type acknowledgement_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "acknowledgement" */
export type acknowledgement_mutation_response = {
  __typename: 'acknowledgement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<acknowledgement>;
};

/** input type for inserting object relation for remote table "acknowledgement" */
export type acknowledgement_obj_rel_insert_input = {
  data: acknowledgement_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_on_conflict>;
};

/** on_conflict condition type for table "acknowledgement" */
export type acknowledgement_on_conflict = {
  constraint: acknowledgement_constraint;
  update_columns?: Array<acknowledgement_update_column>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};

/** Ordering options when selecting data from "acknowledgement". */
export type acknowledgement_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  acknowledgement_group_version_acknowledgements_aggregate?: InputMaybe<acknowledgement_group_version_acknowledgement_aggregate_order_by>;
  acknowledgement_versions_aggregate?: InputMaybe<acknowledgement_version_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<acknowledgement_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<acknowledgement_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: acknowledgement */
export type acknowledgement_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "acknowledgement" */
export enum acknowledgement_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "acknowledgement" */
export type acknowledgement_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type acknowledgement_stddev_fields = {
  __typename: 'acknowledgement_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "acknowledgement" */
export type acknowledgement_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type acknowledgement_stddev_pop_fields = {
  __typename: 'acknowledgement_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "acknowledgement" */
export type acknowledgement_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type acknowledgement_stddev_samp_fields = {
  __typename: 'acknowledgement_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "acknowledgement" */
export type acknowledgement_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "acknowledgement" */
export type acknowledgement_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: acknowledgement_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type acknowledgement_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type acknowledgement_sum_fields = {
  __typename: 'acknowledgement_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "acknowledgement" */
export type acknowledgement_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "acknowledgement" */
export enum acknowledgement_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

export type acknowledgement_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<acknowledgement_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<acknowledgement_set_input>;
  /** filter the rows which have to be updated */
  where: acknowledgement_bool_exp;
};

/** aggregate var_pop on columns */
export type acknowledgement_var_pop_fields = {
  __typename: 'acknowledgement_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "acknowledgement" */
export type acknowledgement_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type acknowledgement_var_samp_fields = {
  __typename: 'acknowledgement_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "acknowledgement" */
export type acknowledgement_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type acknowledgement_variance_fields = {
  __typename: 'acknowledgement_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "acknowledgement" */
export type acknowledgement_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "acknowledgement_version" */
export type acknowledgement_version = {
  __typename: 'acknowledgement_version';
  /** An object relationship */
  acknowledgement: acknowledgement;
  acknowledgement_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  label_translation: translation;
  label_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  published_acknowledgement?: Maybe<acknowledgement>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "acknowledgement_version" */
export type acknowledgement_version_aggregate = {
  __typename: 'acknowledgement_version_aggregate';
  aggregate?: Maybe<acknowledgement_version_aggregate_fields>;
  nodes: Array<acknowledgement_version>;
};

export type acknowledgement_version_aggregate_bool_exp = {
  count?: InputMaybe<acknowledgement_version_aggregate_bool_exp_count>;
};

export type acknowledgement_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<acknowledgement_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<acknowledgement_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "acknowledgement_version" */
export type acknowledgement_version_aggregate_fields = {
  __typename: 'acknowledgement_version_aggregate_fields';
  avg?: Maybe<acknowledgement_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<acknowledgement_version_max_fields>;
  min?: Maybe<acknowledgement_version_min_fields>;
  stddev?: Maybe<acknowledgement_version_stddev_fields>;
  stddev_pop?: Maybe<acknowledgement_version_stddev_pop_fields>;
  stddev_samp?: Maybe<acknowledgement_version_stddev_samp_fields>;
  sum?: Maybe<acknowledgement_version_sum_fields>;
  var_pop?: Maybe<acknowledgement_version_var_pop_fields>;
  var_samp?: Maybe<acknowledgement_version_var_samp_fields>;
  variance?: Maybe<acknowledgement_version_variance_fields>;
};


/** aggregate fields of "acknowledgement_version" */
export type acknowledgement_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<acknowledgement_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "acknowledgement_version" */
export type acknowledgement_version_aggregate_order_by = {
  avg?: InputMaybe<acknowledgement_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<acknowledgement_version_max_order_by>;
  min?: InputMaybe<acknowledgement_version_min_order_by>;
  stddev?: InputMaybe<acknowledgement_version_stddev_order_by>;
  stddev_pop?: InputMaybe<acknowledgement_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<acknowledgement_version_stddev_samp_order_by>;
  sum?: InputMaybe<acknowledgement_version_sum_order_by>;
  var_pop?: InputMaybe<acknowledgement_version_var_pop_order_by>;
  var_samp?: InputMaybe<acknowledgement_version_var_samp_order_by>;
  variance?: InputMaybe<acknowledgement_version_variance_order_by>;
};

/** input type for inserting array relation for remote table "acknowledgement_version" */
export type acknowledgement_version_arr_rel_insert_input = {
  data: Array<acknowledgement_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_version_on_conflict>;
};

/** aggregate avg on columns */
export type acknowledgement_version_avg_fields = {
  __typename: 'acknowledgement_version_avg_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "acknowledgement_version" */
export type acknowledgement_version_avg_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "acknowledgement_version". All fields are combined with a logical 'AND'. */
export type acknowledgement_version_bool_exp = {
  _and?: InputMaybe<Array<acknowledgement_version_bool_exp>>;
  _not?: InputMaybe<acknowledgement_version_bool_exp>;
  _or?: InputMaybe<Array<acknowledgement_version_bool_exp>>;
  acknowledgement?: InputMaybe<acknowledgement_bool_exp>;
  acknowledgement_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  label_translation?: InputMaybe<translation_bool_exp>;
  label_translation_id?: InputMaybe<Int_comparison_exp>;
  published_acknowledgement?: InputMaybe<acknowledgement_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "acknowledgement_version" */
export enum acknowledgement_version_constraint {
  /** unique or primary key constraint on columns "id" */
  acknowledgement_version_pkey = 'acknowledgement_version_pkey'
}

/** input type for incrementing numeric columns in table "acknowledgement_version" */
export type acknowledgement_version_inc_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "acknowledgement_version" */
export type acknowledgement_version_insert_input = {
  acknowledgement?: InputMaybe<acknowledgement_obj_rel_insert_input>;
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_translation?: InputMaybe<translation_obj_rel_insert_input>;
  label_translation_id?: InputMaybe<Scalars['Int']['input']>;
  published_acknowledgement?: InputMaybe<acknowledgement_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type acknowledgement_version_max_fields = {
  __typename: 'acknowledgement_version_max_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "acknowledgement_version" */
export type acknowledgement_version_max_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type acknowledgement_version_min_fields = {
  __typename: 'acknowledgement_version_min_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "acknowledgement_version" */
export type acknowledgement_version_min_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "acknowledgement_version" */
export type acknowledgement_version_mutation_response = {
  __typename: 'acknowledgement_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<acknowledgement_version>;
};

/** input type for inserting object relation for remote table "acknowledgement_version" */
export type acknowledgement_version_obj_rel_insert_input = {
  data: acknowledgement_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<acknowledgement_version_on_conflict>;
};

/** on_conflict condition type for table "acknowledgement_version" */
export type acknowledgement_version_on_conflict = {
  constraint: acknowledgement_version_constraint;
  update_columns?: Array<acknowledgement_version_update_column>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};

/** Ordering options when selecting data from "acknowledgement_version". */
export type acknowledgement_version_order_by = {
  acknowledgement?: InputMaybe<acknowledgement_order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation?: InputMaybe<translation_order_by>;
  label_translation_id?: InputMaybe<order_by>;
  published_acknowledgement?: InputMaybe<acknowledgement_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: acknowledgement_version */
export type acknowledgement_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "acknowledgement_version" */
export enum acknowledgement_version_select_column {
  /** column name */
  acknowledgement_id = 'acknowledgement_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  label_translation_id = 'label_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "acknowledgement_version" */
export type acknowledgement_version_set_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type acknowledgement_version_stddev_fields = {
  __typename: 'acknowledgement_version_stddev_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "acknowledgement_version" */
export type acknowledgement_version_stddev_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type acknowledgement_version_stddev_pop_fields = {
  __typename: 'acknowledgement_version_stddev_pop_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "acknowledgement_version" */
export type acknowledgement_version_stddev_pop_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type acknowledgement_version_stddev_samp_fields = {
  __typename: 'acknowledgement_version_stddev_samp_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "acknowledgement_version" */
export type acknowledgement_version_stddev_samp_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "acknowledgement_version" */
export type acknowledgement_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: acknowledgement_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type acknowledgement_version_stream_cursor_value_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type acknowledgement_version_sum_fields = {
  __typename: 'acknowledgement_version_sum_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "acknowledgement_version" */
export type acknowledgement_version_sum_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** update columns of table "acknowledgement_version" */
export enum acknowledgement_version_update_column {
  /** column name */
  acknowledgement_id = 'acknowledgement_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  label_translation_id = 'label_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type acknowledgement_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<acknowledgement_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<acknowledgement_version_set_input>;
  /** filter the rows which have to be updated */
  where: acknowledgement_version_bool_exp;
};

/** aggregate var_pop on columns */
export type acknowledgement_version_var_pop_fields = {
  __typename: 'acknowledgement_version_var_pop_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "acknowledgement_version" */
export type acknowledgement_version_var_pop_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type acknowledgement_version_var_samp_fields = {
  __typename: 'acknowledgement_version_var_samp_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "acknowledgement_version" */
export type acknowledgement_version_var_samp_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type acknowledgement_version_variance_fields = {
  __typename: 'acknowledgement_version_variance_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "acknowledgement_version" */
export type acknowledgement_version_variance_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label_translation_id?: InputMaybe<order_by>;
};

/** columns and relationships of "active_portal_session" */
export type active_portal_session = {
  __typename: 'active_portal_session';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  portal_session?: Maybe<portal_session>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "active_portal_session" */
export type active_portal_session_aggregate = {
  __typename: 'active_portal_session_aggregate';
  aggregate?: Maybe<active_portal_session_aggregate_fields>;
  nodes: Array<active_portal_session>;
};

/** aggregate fields of "active_portal_session" */
export type active_portal_session_aggregate_fields = {
  __typename: 'active_portal_session_aggregate_fields';
  avg?: Maybe<active_portal_session_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<active_portal_session_max_fields>;
  min?: Maybe<active_portal_session_min_fields>;
  stddev?: Maybe<active_portal_session_stddev_fields>;
  stddev_pop?: Maybe<active_portal_session_stddev_pop_fields>;
  stddev_samp?: Maybe<active_portal_session_stddev_samp_fields>;
  sum?: Maybe<active_portal_session_sum_fields>;
  var_pop?: Maybe<active_portal_session_var_pop_fields>;
  var_samp?: Maybe<active_portal_session_var_samp_fields>;
  variance?: Maybe<active_portal_session_variance_fields>;
};


/** aggregate fields of "active_portal_session" */
export type active_portal_session_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<active_portal_session_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type active_portal_session_avg_fields = {
  __typename: 'active_portal_session_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "active_portal_session". All fields are combined with a logical 'AND'. */
export type active_portal_session_bool_exp = {
  _and?: InputMaybe<Array<active_portal_session_bool_exp>>;
  _not?: InputMaybe<active_portal_session_bool_exp>;
  _or?: InputMaybe<Array<active_portal_session_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  portal_session?: InputMaybe<portal_session_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "active_portal_session" */
export type active_portal_session_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "active_portal_session" */
export type active_portal_session_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  portal_session?: InputMaybe<portal_session_obj_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type active_portal_session_max_fields = {
  __typename: 'active_portal_session_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type active_portal_session_min_fields = {
  __typename: 'active_portal_session_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "active_portal_session" */
export type active_portal_session_mutation_response = {
  __typename: 'active_portal_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<active_portal_session>;
};

/** input type for inserting object relation for remote table "active_portal_session" */
export type active_portal_session_obj_rel_insert_input = {
  data: active_portal_session_insert_input;
};

/** Ordering options when selecting data from "active_portal_session". */
export type active_portal_session_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  portal_session?: InputMaybe<portal_session_order_by>;
  token?: InputMaybe<order_by>;
};

/** select columns of table "active_portal_session" */
export enum active_portal_session_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token'
}

/** input type for updating data in table "active_portal_session" */
export type active_portal_session_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type active_portal_session_stddev_fields = {
  __typename: 'active_portal_session_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type active_portal_session_stddev_pop_fields = {
  __typename: 'active_portal_session_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type active_portal_session_stddev_samp_fields = {
  __typename: 'active_portal_session_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "active_portal_session" */
export type active_portal_session_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: active_portal_session_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type active_portal_session_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type active_portal_session_sum_fields = {
  __typename: 'active_portal_session_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type active_portal_session_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<active_portal_session_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<active_portal_session_set_input>;
  /** filter the rows which have to be updated */
  where: active_portal_session_bool_exp;
};

/** aggregate var_pop on columns */
export type active_portal_session_var_pop_fields = {
  __typename: 'active_portal_session_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type active_portal_session_var_samp_fields = {
  __typename: 'active_portal_session_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type active_portal_session_variance_fields = {
  __typename: 'active_portal_session_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "active_saved_mrr_by_month" */
export type active_saved_mrr_by_month = {
  __typename: 'active_saved_mrr_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  trial?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "active_saved_mrr_by_month" */
export type active_saved_mrr_by_month_aggregate = {
  __typename: 'active_saved_mrr_by_month_aggregate';
  aggregate?: Maybe<active_saved_mrr_by_month_aggregate_fields>;
  nodes: Array<active_saved_mrr_by_month>;
};

/** aggregate fields of "active_saved_mrr_by_month" */
export type active_saved_mrr_by_month_aggregate_fields = {
  __typename: 'active_saved_mrr_by_month_aggregate_fields';
  avg?: Maybe<active_saved_mrr_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<active_saved_mrr_by_month_max_fields>;
  min?: Maybe<active_saved_mrr_by_month_min_fields>;
  stddev?: Maybe<active_saved_mrr_by_month_stddev_fields>;
  stddev_pop?: Maybe<active_saved_mrr_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<active_saved_mrr_by_month_stddev_samp_fields>;
  sum?: Maybe<active_saved_mrr_by_month_sum_fields>;
  var_pop?: Maybe<active_saved_mrr_by_month_var_pop_fields>;
  var_samp?: Maybe<active_saved_mrr_by_month_var_samp_fields>;
  variance?: Maybe<active_saved_mrr_by_month_variance_fields>;
};


/** aggregate fields of "active_saved_mrr_by_month" */
export type active_saved_mrr_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<active_saved_mrr_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type active_saved_mrr_by_month_avg_fields = {
  __typename: 'active_saved_mrr_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "active_saved_mrr_by_month". All fields are combined with a logical 'AND'. */
export type active_saved_mrr_by_month_bool_exp = {
  _and?: InputMaybe<Array<active_saved_mrr_by_month_bool_exp>>;
  _not?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
  _or?: InputMaybe<Array<active_saved_mrr_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
  mrr?: InputMaybe<numeric_comparison_exp>;
  trial?: InputMaybe<Boolean_comparison_exp>;
};

/** aggregate max on columns */
export type active_saved_mrr_by_month_max_fields = {
  __typename: 'active_saved_mrr_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type active_saved_mrr_by_month_min_fields = {
  __typename: 'active_saved_mrr_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "active_saved_mrr_by_month". */
export type active_saved_mrr_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  trial?: InputMaybe<order_by>;
};

/** select columns of table "active_saved_mrr_by_month" */
export enum active_saved_mrr_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  month = 'month',
  /** column name */
  mrr = 'mrr',
  /** column name */
  trial = 'trial'
}

/** aggregate stddev on columns */
export type active_saved_mrr_by_month_stddev_fields = {
  __typename: 'active_saved_mrr_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type active_saved_mrr_by_month_stddev_pop_fields = {
  __typename: 'active_saved_mrr_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type active_saved_mrr_by_month_stddev_samp_fields = {
  __typename: 'active_saved_mrr_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "active_saved_mrr_by_month" */
export type active_saved_mrr_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: active_saved_mrr_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type active_saved_mrr_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type active_saved_mrr_by_month_sum_fields = {
  __typename: 'active_saved_mrr_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type active_saved_mrr_by_month_var_pop_fields = {
  __typename: 'active_saved_mrr_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type active_saved_mrr_by_month_var_samp_fields = {
  __typename: 'active_saved_mrr_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type active_saved_mrr_by_month_variance_fields = {
  __typename: 'active_saved_mrr_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "annotation" */
export type annotation = {
  __typename: 'annotation';
  account_id: Scalars['Int']['output'];
  date: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  type: annotation_type_enum;
  /** An object relationship */
  user?: Maybe<user>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "annotation" */
export type annotation_aggregate = {
  __typename: 'annotation_aggregate';
  aggregate?: Maybe<annotation_aggregate_fields>;
  nodes: Array<annotation>;
};

/** aggregate fields of "annotation" */
export type annotation_aggregate_fields = {
  __typename: 'annotation_aggregate_fields';
  avg?: Maybe<annotation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<annotation_max_fields>;
  min?: Maybe<annotation_min_fields>;
  stddev?: Maybe<annotation_stddev_fields>;
  stddev_pop?: Maybe<annotation_stddev_pop_fields>;
  stddev_samp?: Maybe<annotation_stddev_samp_fields>;
  sum?: Maybe<annotation_sum_fields>;
  var_pop?: Maybe<annotation_var_pop_fields>;
  var_samp?: Maybe<annotation_var_samp_fields>;
  variance?: Maybe<annotation_variance_fields>;
};


/** aggregate fields of "annotation" */
export type annotation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<annotation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type annotation_avg_fields = {
  __typename: 'annotation_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "annotation". All fields are combined with a logical 'AND'. */
export type annotation_bool_exp = {
  _and?: InputMaybe<Array<annotation_bool_exp>>;
  _not?: InputMaybe<annotation_bool_exp>;
  _or?: InputMaybe<Array<annotation_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  label?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<annotation_type_enum_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
  user_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "annotation" */
export enum annotation_constraint {
  /** unique or primary key constraint on columns "id" */
  annotation_pkey = 'annotation_pkey'
}

/** columns and relationships of "annotation_flow_version" */
export type annotation_flow_version = {
  __typename: 'annotation_flow_version';
  /** An object relationship */
  annotation: annotation;
  annotation_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_version: flow_version;
  flow_version_id: Scalars['Int']['output'];
};

/** aggregated selection of "annotation_flow_version" */
export type annotation_flow_version_aggregate = {
  __typename: 'annotation_flow_version_aggregate';
  aggregate?: Maybe<annotation_flow_version_aggregate_fields>;
  nodes: Array<annotation_flow_version>;
};

/** aggregate fields of "annotation_flow_version" */
export type annotation_flow_version_aggregate_fields = {
  __typename: 'annotation_flow_version_aggregate_fields';
  avg?: Maybe<annotation_flow_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<annotation_flow_version_max_fields>;
  min?: Maybe<annotation_flow_version_min_fields>;
  stddev?: Maybe<annotation_flow_version_stddev_fields>;
  stddev_pop?: Maybe<annotation_flow_version_stddev_pop_fields>;
  stddev_samp?: Maybe<annotation_flow_version_stddev_samp_fields>;
  sum?: Maybe<annotation_flow_version_sum_fields>;
  var_pop?: Maybe<annotation_flow_version_var_pop_fields>;
  var_samp?: Maybe<annotation_flow_version_var_samp_fields>;
  variance?: Maybe<annotation_flow_version_variance_fields>;
};


/** aggregate fields of "annotation_flow_version" */
export type annotation_flow_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<annotation_flow_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type annotation_flow_version_avg_fields = {
  __typename: 'annotation_flow_version_avg_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "annotation_flow_version". All fields are combined with a logical 'AND'. */
export type annotation_flow_version_bool_exp = {
  _and?: InputMaybe<Array<annotation_flow_version_bool_exp>>;
  _not?: InputMaybe<annotation_flow_version_bool_exp>;
  _or?: InputMaybe<Array<annotation_flow_version_bool_exp>>;
  annotation?: InputMaybe<annotation_bool_exp>;
  annotation_id?: InputMaybe<Int_comparison_exp>;
  flow_version?: InputMaybe<flow_version_bool_exp>;
  flow_version_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "annotation_flow_version" */
export enum annotation_flow_version_constraint {
  /** unique or primary key constraint on columns "annotation_id", "flow_version_id" */
  annotation_flow_version_pkey = 'annotation_flow_version_pkey'
}

/** input type for incrementing numeric columns in table "annotation_flow_version" */
export type annotation_flow_version_inc_input = {
  annotation_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "annotation_flow_version" */
export type annotation_flow_version_insert_input = {
  annotation?: InputMaybe<annotation_obj_rel_insert_input>;
  annotation_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version?: InputMaybe<flow_version_obj_rel_insert_input>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type annotation_flow_version_max_fields = {
  __typename: 'annotation_flow_version_max_fields';
  annotation_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type annotation_flow_version_min_fields = {
  __typename: 'annotation_flow_version_min_fields';
  annotation_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "annotation_flow_version" */
export type annotation_flow_version_mutation_response = {
  __typename: 'annotation_flow_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<annotation_flow_version>;
};

/** on_conflict condition type for table "annotation_flow_version" */
export type annotation_flow_version_on_conflict = {
  constraint: annotation_flow_version_constraint;
  update_columns?: Array<annotation_flow_version_update_column>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};

/** Ordering options when selecting data from "annotation_flow_version". */
export type annotation_flow_version_order_by = {
  annotation?: InputMaybe<annotation_order_by>;
  annotation_id?: InputMaybe<order_by>;
  flow_version?: InputMaybe<flow_version_order_by>;
  flow_version_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: annotation_flow_version */
export type annotation_flow_version_pk_columns_input = {
  annotation_id: Scalars['Int']['input'];
  flow_version_id: Scalars['Int']['input'];
};

/** select columns of table "annotation_flow_version" */
export enum annotation_flow_version_select_column {
  /** column name */
  annotation_id = 'annotation_id',
  /** column name */
  flow_version_id = 'flow_version_id'
}

/** input type for updating data in table "annotation_flow_version" */
export type annotation_flow_version_set_input = {
  annotation_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type annotation_flow_version_stddev_fields = {
  __typename: 'annotation_flow_version_stddev_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type annotation_flow_version_stddev_pop_fields = {
  __typename: 'annotation_flow_version_stddev_pop_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type annotation_flow_version_stddev_samp_fields = {
  __typename: 'annotation_flow_version_stddev_samp_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "annotation_flow_version" */
export type annotation_flow_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: annotation_flow_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type annotation_flow_version_stream_cursor_value_input = {
  annotation_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type annotation_flow_version_sum_fields = {
  __typename: 'annotation_flow_version_sum_fields';
  annotation_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "annotation_flow_version" */
export enum annotation_flow_version_update_column {
  /** column name */
  annotation_id = 'annotation_id',
  /** column name */
  flow_version_id = 'flow_version_id'
}

export type annotation_flow_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<annotation_flow_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<annotation_flow_version_set_input>;
  /** filter the rows which have to be updated */
  where: annotation_flow_version_bool_exp;
};

/** aggregate var_pop on columns */
export type annotation_flow_version_var_pop_fields = {
  __typename: 'annotation_flow_version_var_pop_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type annotation_flow_version_var_samp_fields = {
  __typename: 'annotation_flow_version_var_samp_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type annotation_flow_version_variance_fields = {
  __typename: 'annotation_flow_version_variance_fields';
  annotation_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "annotation" */
export type annotation_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "annotation" */
export type annotation_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<annotation_type_enum>;
  user?: InputMaybe<user_obj_rel_insert_input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type annotation_max_fields = {
  __typename: 'annotation_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type annotation_min_fields = {
  __typename: 'annotation_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "annotation" */
export type annotation_mutation_response = {
  __typename: 'annotation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<annotation>;
};

/** input type for inserting object relation for remote table "annotation" */
export type annotation_obj_rel_insert_input = {
  data: annotation_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<annotation_on_conflict>;
};

/** on_conflict condition type for table "annotation" */
export type annotation_on_conflict = {
  constraint: annotation_constraint;
  update_columns?: Array<annotation_update_column>;
  where?: InputMaybe<annotation_bool_exp>;
};

/** Ordering options when selecting data from "annotation". */
export type annotation_order_by = {
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  label?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
  user_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: annotation */
export type annotation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "annotation" */
export enum annotation_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  id = 'id',
  /** column name */
  label = 'label',
  /** column name */
  type = 'type',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "annotation" */
export type annotation_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<annotation_type_enum>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type annotation_stddev_fields = {
  __typename: 'annotation_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type annotation_stddev_pop_fields = {
  __typename: 'annotation_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type annotation_stddev_samp_fields = {
  __typename: 'annotation_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "annotation" */
export type annotation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: annotation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type annotation_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<annotation_type_enum>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type annotation_sum_fields = {
  __typename: 'annotation_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "annotation_type" */
export type annotation_type = {
  __typename: 'annotation_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "annotation_type" */
export type annotation_type_aggregate = {
  __typename: 'annotation_type_aggregate';
  aggregate?: Maybe<annotation_type_aggregate_fields>;
  nodes: Array<annotation_type>;
};

/** aggregate fields of "annotation_type" */
export type annotation_type_aggregate_fields = {
  __typename: 'annotation_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<annotation_type_max_fields>;
  min?: Maybe<annotation_type_min_fields>;
};


/** aggregate fields of "annotation_type" */
export type annotation_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<annotation_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "annotation_type". All fields are combined with a logical 'AND'. */
export type annotation_type_bool_exp = {
  _and?: InputMaybe<Array<annotation_type_bool_exp>>;
  _not?: InputMaybe<annotation_type_bool_exp>;
  _or?: InputMaybe<Array<annotation_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "annotation_type" */
export enum annotation_type_constraint {
  /** unique or primary key constraint on columns "value" */
  annotation_type_pkey = 'annotation_type_pkey'
}

export enum annotation_type_enum {
  published_flow = 'published_flow'
}

/** Boolean expression to compare columns of type "annotation_type_enum". All fields are combined with logical 'AND'. */
export type annotation_type_enum_comparison_exp = {
  _eq?: InputMaybe<annotation_type_enum>;
  _in?: InputMaybe<Array<annotation_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<annotation_type_enum>;
  _nin?: InputMaybe<Array<annotation_type_enum>>;
};

/** input type for inserting data into table "annotation_type" */
export type annotation_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type annotation_type_max_fields = {
  __typename: 'annotation_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type annotation_type_min_fields = {
  __typename: 'annotation_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "annotation_type" */
export type annotation_type_mutation_response = {
  __typename: 'annotation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<annotation_type>;
};

/** on_conflict condition type for table "annotation_type" */
export type annotation_type_on_conflict = {
  constraint: annotation_type_constraint;
  update_columns?: Array<annotation_type_update_column>;
  where?: InputMaybe<annotation_type_bool_exp>;
};

/** Ordering options when selecting data from "annotation_type". */
export type annotation_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: annotation_type */
export type annotation_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "annotation_type" */
export enum annotation_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "annotation_type" */
export type annotation_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "annotation_type" */
export type annotation_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: annotation_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type annotation_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "annotation_type" */
export enum annotation_type_update_column {
  /** column name */
  value = 'value'
}

export type annotation_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<annotation_type_set_input>;
  /** filter the rows which have to be updated */
  where: annotation_type_bool_exp;
};

/** update columns of table "annotation" */
export enum annotation_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  id = 'id',
  /** column name */
  label = 'label',
  /** column name */
  type = 'type',
  /** column name */
  user_id = 'user_id'
}

export type annotation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<annotation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<annotation_set_input>;
  /** filter the rows which have to be updated */
  where: annotation_bool_exp;
};

/** aggregate var_pop on columns */
export type annotation_var_pop_fields = {
  __typename: 'annotation_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type annotation_var_samp_fields = {
  __typename: 'annotation_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type annotation_variance_fields = {
  __typename: 'annotation_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "api_key" */
export type api_key = {
  __typename: 'api_key';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  purpose: api_key_purpose_enum;
  test_mode_key: Scalars['String']['output'];
};

/** aggregated selection of "api_key" */
export type api_key_aggregate = {
  __typename: 'api_key_aggregate';
  aggregate?: Maybe<api_key_aggregate_fields>;
  nodes: Array<api_key>;
};

/** aggregate fields of "api_key" */
export type api_key_aggregate_fields = {
  __typename: 'api_key_aggregate_fields';
  avg?: Maybe<api_key_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<api_key_max_fields>;
  min?: Maybe<api_key_min_fields>;
  stddev?: Maybe<api_key_stddev_fields>;
  stddev_pop?: Maybe<api_key_stddev_pop_fields>;
  stddev_samp?: Maybe<api_key_stddev_samp_fields>;
  sum?: Maybe<api_key_sum_fields>;
  var_pop?: Maybe<api_key_var_pop_fields>;
  var_samp?: Maybe<api_key_var_samp_fields>;
  variance?: Maybe<api_key_variance_fields>;
};


/** aggregate fields of "api_key" */
export type api_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<api_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type api_key_avg_fields = {
  __typename: 'api_key_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "api_key". All fields are combined with a logical 'AND'. */
export type api_key_bool_exp = {
  _and?: InputMaybe<Array<api_key_bool_exp>>;
  _not?: InputMaybe<api_key_bool_exp>;
  _or?: InputMaybe<Array<api_key_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  hidden?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  purpose?: InputMaybe<api_key_purpose_enum_comparison_exp>;
  test_mode_key?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "api_key" */
export enum api_key_constraint {
  /** unique or primary key constraint on columns "account_id", "hidden", "name" */
  api_key_account_id_name_hidden_key = 'api_key_account_id_name_hidden_key',
  /** unique or primary key constraint on columns "key" */
  api_key_key_key = 'api_key_key_key',
  /** unique or primary key constraint on columns "id" */
  api_key_pkey = 'api_key_pkey'
}

/** input type for incrementing numeric columns in table "api_key" */
export type api_key_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "api_key" */
export type api_key_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<api_key_purpose_enum>;
  test_mode_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type api_key_max_fields = {
  __typename: 'api_key_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  test_mode_key?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type api_key_min_fields = {
  __typename: 'api_key_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  test_mode_key?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "api_key" */
export type api_key_mutation_response = {
  __typename: 'api_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<api_key>;
};

/** on_conflict condition type for table "api_key" */
export type api_key_on_conflict = {
  constraint: api_key_constraint;
  update_columns?: Array<api_key_update_column>;
  where?: InputMaybe<api_key_bool_exp>;
};

/** Ordering options when selecting data from "api_key". */
export type api_key_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  hidden?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  purpose?: InputMaybe<order_by>;
  test_mode_key?: InputMaybe<order_by>;
};

/** primary key columns input for table: api_key */
export type api_key_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "api_key_purpose" */
export type api_key_purpose = {
  __typename: 'api_key_purpose';
  value: Scalars['String']['output'];
};

/** aggregated selection of "api_key_purpose" */
export type api_key_purpose_aggregate = {
  __typename: 'api_key_purpose_aggregate';
  aggregate?: Maybe<api_key_purpose_aggregate_fields>;
  nodes: Array<api_key_purpose>;
};

/** aggregate fields of "api_key_purpose" */
export type api_key_purpose_aggregate_fields = {
  __typename: 'api_key_purpose_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<api_key_purpose_max_fields>;
  min?: Maybe<api_key_purpose_min_fields>;
};


/** aggregate fields of "api_key_purpose" */
export type api_key_purpose_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<api_key_purpose_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "api_key_purpose". All fields are combined with a logical 'AND'. */
export type api_key_purpose_bool_exp = {
  _and?: InputMaybe<Array<api_key_purpose_bool_exp>>;
  _not?: InputMaybe<api_key_purpose_bool_exp>;
  _or?: InputMaybe<Array<api_key_purpose_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "api_key_purpose" */
export enum api_key_purpose_constraint {
  /** unique or primary key constraint on columns "value" */
  api_key_purpose_pkey = 'api_key_purpose_pkey'
}

export enum api_key_purpose_enum {
  segment_destination = 'segment_destination',
  stripe_app = 'stripe_app',
  user_created = 'user_created'
}

/** Boolean expression to compare columns of type "api_key_purpose_enum". All fields are combined with logical 'AND'. */
export type api_key_purpose_enum_comparison_exp = {
  _eq?: InputMaybe<api_key_purpose_enum>;
  _in?: InputMaybe<Array<api_key_purpose_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<api_key_purpose_enum>;
  _nin?: InputMaybe<Array<api_key_purpose_enum>>;
};

/** input type for inserting data into table "api_key_purpose" */
export type api_key_purpose_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type api_key_purpose_max_fields = {
  __typename: 'api_key_purpose_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type api_key_purpose_min_fields = {
  __typename: 'api_key_purpose_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "api_key_purpose" */
export type api_key_purpose_mutation_response = {
  __typename: 'api_key_purpose_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<api_key_purpose>;
};

/** on_conflict condition type for table "api_key_purpose" */
export type api_key_purpose_on_conflict = {
  constraint: api_key_purpose_constraint;
  update_columns?: Array<api_key_purpose_update_column>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};

/** Ordering options when selecting data from "api_key_purpose". */
export type api_key_purpose_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: api_key_purpose */
export type api_key_purpose_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "api_key_purpose" */
export enum api_key_purpose_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "api_key_purpose" */
export type api_key_purpose_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "api_key_purpose" */
export type api_key_purpose_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: api_key_purpose_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type api_key_purpose_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "api_key_purpose" */
export enum api_key_purpose_update_column {
  /** column name */
  value = 'value'
}

export type api_key_purpose_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<api_key_purpose_set_input>;
  /** filter the rows which have to be updated */
  where: api_key_purpose_bool_exp;
};

/** select columns of table "api_key" */
export enum api_key_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hidden = 'hidden',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  name = 'name',
  /** column name */
  purpose = 'purpose',
  /** column name */
  test_mode_key = 'test_mode_key'
}

/** input type for updating data in table "api_key" */
export type api_key_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<api_key_purpose_enum>;
  test_mode_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type api_key_stddev_fields = {
  __typename: 'api_key_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type api_key_stddev_pop_fields = {
  __typename: 'api_key_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type api_key_stddev_samp_fields = {
  __typename: 'api_key_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "api_key" */
export type api_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: api_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type api_key_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<api_key_purpose_enum>;
  test_mode_key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type api_key_sum_fields = {
  __typename: 'api_key_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "api_key" */
export enum api_key_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hidden = 'hidden',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  name = 'name',
  /** column name */
  purpose = 'purpose',
  /** column name */
  test_mode_key = 'test_mode_key'
}

export type api_key_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<api_key_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<api_key_set_input>;
  /** filter the rows which have to be updated */
  where: api_key_bool_exp;
};

/** aggregate var_pop on columns */
export type api_key_var_pop_fields = {
  __typename: 'api_key_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type api_key_var_samp_fields = {
  __typename: 'api_key_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type api_key_variance_fields = {
  __typename: 'api_key_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type bigint_comparison_exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "campaign" */
export type campaign = {
  __typename: 'campaign';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An array relationship */
  campaign_texts: Array<campaign_text>;
  /** An aggregate relationship */
  campaign_texts_aggregate: campaign_text_aggregate;
  created_at: Scalars['timestamptz']['output'];
  default_language: language_enum;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  language: language;
  /** An object relationship */
  published_version?: Maybe<campaign_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  signature_required: Scalars['Boolean']['output'];
  /** An array relationship */
  subscriber_campaigns: Array<subscriber_campaign>;
  /** An aggregate relationship */
  subscriber_campaigns_aggregate: subscriber_campaign_aggregate;
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "campaign" */
export type campaigncampaign_textsArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


/** columns and relationships of "campaign" */
export type campaigncampaign_texts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


/** columns and relationships of "campaign" */
export type campaignsubscriber_campaignsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "campaign" */
export type campaignsubscriber_campaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};

/** aggregated selection of "campaign" */
export type campaign_aggregate = {
  __typename: 'campaign_aggregate';
  aggregate?: Maybe<campaign_aggregate_fields>;
  nodes: Array<campaign>;
};

export type campaign_aggregate_bool_exp = {
  bool_and?: InputMaybe<campaign_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<campaign_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<campaign_aggregate_bool_exp_count>;
};

export type campaign_aggregate_bool_exp_bool_and = {
  arguments: campaign_select_column_campaign_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type campaign_aggregate_bool_exp_bool_or = {
  arguments: campaign_select_column_campaign_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type campaign_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "campaign" */
export type campaign_aggregate_fields = {
  __typename: 'campaign_aggregate_fields';
  avg?: Maybe<campaign_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<campaign_max_fields>;
  min?: Maybe<campaign_min_fields>;
  stddev?: Maybe<campaign_stddev_fields>;
  stddev_pop?: Maybe<campaign_stddev_pop_fields>;
  stddev_samp?: Maybe<campaign_stddev_samp_fields>;
  sum?: Maybe<campaign_sum_fields>;
  var_pop?: Maybe<campaign_var_pop_fields>;
  var_samp?: Maybe<campaign_var_samp_fields>;
  variance?: Maybe<campaign_variance_fields>;
};


/** aggregate fields of "campaign" */
export type campaign_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "campaign" */
export type campaign_aggregate_order_by = {
  avg?: InputMaybe<campaign_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<campaign_max_order_by>;
  min?: InputMaybe<campaign_min_order_by>;
  stddev?: InputMaybe<campaign_stddev_order_by>;
  stddev_pop?: InputMaybe<campaign_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<campaign_stddev_samp_order_by>;
  sum?: InputMaybe<campaign_sum_order_by>;
  var_pop?: InputMaybe<campaign_var_pop_order_by>;
  var_samp?: InputMaybe<campaign_var_samp_order_by>;
  variance?: InputMaybe<campaign_variance_order_by>;
};

/** input type for inserting array relation for remote table "campaign" */
export type campaign_arr_rel_insert_input = {
  data: Array<campaign_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_on_conflict>;
};

/** aggregate avg on columns */
export type campaign_avg_fields = {
  __typename: 'campaign_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "campaign" */
export type campaign_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
export type campaign_bool_exp = {
  _and?: InputMaybe<Array<campaign_bool_exp>>;
  _not?: InputMaybe<campaign_bool_exp>;
  _or?: InputMaybe<Array<campaign_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  campaign_texts?: InputMaybe<campaign_text_bool_exp>;
  campaign_texts_aggregate?: InputMaybe<campaign_text_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  default_language?: InputMaybe<language_enum_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<language_bool_exp>;
  published_version?: InputMaybe<campaign_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  signature_required?: InputMaybe<Boolean_comparison_exp>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_bool_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "campaign" */
export enum campaign_constraint {
  /** unique or primary key constraint on columns "id" */
  campaign_pkey = 'campaign_pkey',
  /** unique or primary key constraint on columns "token" */
  campaign_token_key = 'campaign_token_key'
}

/** input type for incrementing numeric columns in table "campaign" */
export type campaign_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "campaign" */
export type campaign_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_texts?: InputMaybe<campaign_text_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_obj_rel_insert_input>;
  published_version?: InputMaybe<campaign_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_arr_rel_insert_input>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type campaign_max_fields = {
  __typename: 'campaign_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "campaign" */
export type campaign_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type campaign_min_fields = {
  __typename: 'campaign_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "campaign" */
export type campaign_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "campaign" */
export type campaign_mutation_response = {
  __typename: 'campaign_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<campaign>;
};

/** input type for inserting object relation for remote table "campaign" */
export type campaign_obj_rel_insert_input = {
  data: campaign_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_on_conflict>;
};

/** on_conflict condition type for table "campaign" */
export type campaign_on_conflict = {
  constraint: campaign_constraint;
  update_columns?: Array<campaign_update_column>;
  where?: InputMaybe<campaign_bool_exp>;
};

/** Ordering options when selecting data from "campaign". */
export type campaign_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  campaign_texts_aggregate?: InputMaybe<campaign_text_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  default_language?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<language_order_by>;
  published_version?: InputMaybe<campaign_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  signature_required?: InputMaybe<order_by>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: campaign */
export type campaign_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "campaign" */
export enum campaign_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  default_language = 'default_language',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  signature_required = 'signature_required',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** select "campaign_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaign" */
export enum campaign_select_column_campaign_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  signature_required = 'signature_required'
}

/** select "campaign_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaign" */
export enum campaign_select_column_campaign_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  signature_required = 'signature_required'
}

/** input type for updating data in table "campaign" */
export type campaign_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type campaign_stddev_fields = {
  __typename: 'campaign_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "campaign" */
export type campaign_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type campaign_stddev_pop_fields = {
  __typename: 'campaign_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "campaign" */
export type campaign_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type campaign_stddev_samp_fields = {
  __typename: 'campaign_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "campaign" */
export type campaign_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "campaign" */
export type campaign_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: campaign_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type campaign_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type campaign_sum_fields = {
  __typename: 'campaign_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "campaign" */
export type campaign_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "campaign_text" */
export type campaign_text = {
  __typename: 'campaign_text';
  /** An object relationship */
  campaign: campaign;
  campaign_id: Scalars['Int']['output'];
  /** An object relationship */
  campaign_text_key: flow_text_key;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<campaign_text_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  key: flow_text_key_enum;
  /** An object relationship */
  published_version?: Maybe<campaign_text_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "campaign_text" */
export type campaign_text_aggregate = {
  __typename: 'campaign_text_aggregate';
  aggregate?: Maybe<campaign_text_aggregate_fields>;
  nodes: Array<campaign_text>;
};

export type campaign_text_aggregate_bool_exp = {
  count?: InputMaybe<campaign_text_aggregate_bool_exp_count>;
};

export type campaign_text_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<campaign_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_text_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "campaign_text" */
export type campaign_text_aggregate_fields = {
  __typename: 'campaign_text_aggregate_fields';
  avg?: Maybe<campaign_text_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<campaign_text_max_fields>;
  min?: Maybe<campaign_text_min_fields>;
  stddev?: Maybe<campaign_text_stddev_fields>;
  stddev_pop?: Maybe<campaign_text_stddev_pop_fields>;
  stddev_samp?: Maybe<campaign_text_stddev_samp_fields>;
  sum?: Maybe<campaign_text_sum_fields>;
  var_pop?: Maybe<campaign_text_var_pop_fields>;
  var_samp?: Maybe<campaign_text_var_samp_fields>;
  variance?: Maybe<campaign_text_variance_fields>;
};


/** aggregate fields of "campaign_text" */
export type campaign_text_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<campaign_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "campaign_text" */
export type campaign_text_aggregate_order_by = {
  avg?: InputMaybe<campaign_text_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<campaign_text_max_order_by>;
  min?: InputMaybe<campaign_text_min_order_by>;
  stddev?: InputMaybe<campaign_text_stddev_order_by>;
  stddev_pop?: InputMaybe<campaign_text_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<campaign_text_stddev_samp_order_by>;
  sum?: InputMaybe<campaign_text_sum_order_by>;
  var_pop?: InputMaybe<campaign_text_var_pop_order_by>;
  var_samp?: InputMaybe<campaign_text_var_samp_order_by>;
  variance?: InputMaybe<campaign_text_variance_order_by>;
};

/** input type for inserting array relation for remote table "campaign_text" */
export type campaign_text_arr_rel_insert_input = {
  data: Array<campaign_text_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_text_on_conflict>;
};

/** aggregate avg on columns */
export type campaign_text_avg_fields = {
  __typename: 'campaign_text_avg_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "campaign_text" */
export type campaign_text_avg_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "campaign_text". All fields are combined with a logical 'AND'. */
export type campaign_text_bool_exp = {
  _and?: InputMaybe<Array<campaign_text_bool_exp>>;
  _not?: InputMaybe<campaign_text_bool_exp>;
  _or?: InputMaybe<Array<campaign_text_bool_exp>>;
  campaign?: InputMaybe<campaign_bool_exp>;
  campaign_id?: InputMaybe<Int_comparison_exp>;
  campaign_text_key?: InputMaybe<flow_text_key_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<campaign_text_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<flow_text_key_enum_comparison_exp>;
  published_version?: InputMaybe<campaign_text_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "campaign_text" */
export enum campaign_text_constraint {
  /** unique or primary key constraint on columns "key", "campaign_id" */
  campaign_text_pkey = 'campaign_text_pkey'
}

/** input type for incrementing numeric columns in table "campaign_text" */
export type campaign_text_inc_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "campaign_text" */
export type campaign_text_insert_input = {
  campaign?: InputMaybe<campaign_obj_rel_insert_input>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_text_key?: InputMaybe<flow_text_key_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<campaign_text_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version?: InputMaybe<campaign_text_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type campaign_text_max_fields = {
  __typename: 'campaign_text_max_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "campaign_text" */
export type campaign_text_max_order_by = {
  campaign_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type campaign_text_min_fields = {
  __typename: 'campaign_text_min_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "campaign_text" */
export type campaign_text_min_order_by = {
  campaign_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "campaign_text" */
export type campaign_text_mutation_response = {
  __typename: 'campaign_text_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<campaign_text>;
};

/** input type for inserting object relation for remote table "campaign_text" */
export type campaign_text_obj_rel_insert_input = {
  data: campaign_text_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_text_on_conflict>;
};

/** on_conflict condition type for table "campaign_text" */
export type campaign_text_on_conflict = {
  constraint: campaign_text_constraint;
  update_columns?: Array<campaign_text_update_column>;
  where?: InputMaybe<campaign_text_bool_exp>;
};

/** Ordering options when selecting data from "campaign_text". */
export type campaign_text_order_by = {
  campaign?: InputMaybe<campaign_order_by>;
  campaign_id?: InputMaybe<order_by>;
  campaign_text_key?: InputMaybe<flow_text_key_order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<campaign_text_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  published_version?: InputMaybe<campaign_text_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: campaign_text */
export type campaign_text_pk_columns_input = {
  campaign_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};

/** select columns of table "campaign_text" */
export enum campaign_text_select_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "campaign_text" */
export type campaign_text_set_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type campaign_text_stddev_fields = {
  __typename: 'campaign_text_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "campaign_text" */
export type campaign_text_stddev_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type campaign_text_stddev_pop_fields = {
  __typename: 'campaign_text_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "campaign_text" */
export type campaign_text_stddev_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type campaign_text_stddev_samp_fields = {
  __typename: 'campaign_text_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "campaign_text" */
export type campaign_text_stddev_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "campaign_text" */
export type campaign_text_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: campaign_text_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type campaign_text_stream_cursor_value_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type campaign_text_sum_fields = {
  __typename: 'campaign_text_sum_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "campaign_text" */
export type campaign_text_sum_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "campaign_text" */
export enum campaign_text_update_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

export type campaign_text_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<campaign_text_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<campaign_text_set_input>;
  /** filter the rows which have to be updated */
  where: campaign_text_bool_exp;
};

/** aggregate var_pop on columns */
export type campaign_text_var_pop_fields = {
  __typename: 'campaign_text_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "campaign_text" */
export type campaign_text_var_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type campaign_text_var_samp_fields = {
  __typename: 'campaign_text_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "campaign_text" */
export type campaign_text_var_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type campaign_text_variance_fields = {
  __typename: 'campaign_text_variance_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "campaign_text" */
export type campaign_text_variance_order_by = {
  campaign_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "campaign_text_version" */
export type campaign_text_version = {
  __typename: 'campaign_text_version';
  /** An object relationship */
  campaign: campaign;
  campaign_id: Scalars['Int']['output'];
  /** An object relationship */
  campaign_text?: Maybe<campaign_text>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  key: flow_text_key_enum;
  /** An object relationship */
  translation: translation;
  translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "campaign_text_version" */
export type campaign_text_version_aggregate = {
  __typename: 'campaign_text_version_aggregate';
  aggregate?: Maybe<campaign_text_version_aggregate_fields>;
  nodes: Array<campaign_text_version>;
};

/** aggregate fields of "campaign_text_version" */
export type campaign_text_version_aggregate_fields = {
  __typename: 'campaign_text_version_aggregate_fields';
  avg?: Maybe<campaign_text_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<campaign_text_version_max_fields>;
  min?: Maybe<campaign_text_version_min_fields>;
  stddev?: Maybe<campaign_text_version_stddev_fields>;
  stddev_pop?: Maybe<campaign_text_version_stddev_pop_fields>;
  stddev_samp?: Maybe<campaign_text_version_stddev_samp_fields>;
  sum?: Maybe<campaign_text_version_sum_fields>;
  var_pop?: Maybe<campaign_text_version_var_pop_fields>;
  var_samp?: Maybe<campaign_text_version_var_samp_fields>;
  variance?: Maybe<campaign_text_version_variance_fields>;
};


/** aggregate fields of "campaign_text_version" */
export type campaign_text_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<campaign_text_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type campaign_text_version_avg_fields = {
  __typename: 'campaign_text_version_avg_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "campaign_text_version". All fields are combined with a logical 'AND'. */
export type campaign_text_version_bool_exp = {
  _and?: InputMaybe<Array<campaign_text_version_bool_exp>>;
  _not?: InputMaybe<campaign_text_version_bool_exp>;
  _or?: InputMaybe<Array<campaign_text_version_bool_exp>>;
  campaign?: InputMaybe<campaign_bool_exp>;
  campaign_id?: InputMaybe<Int_comparison_exp>;
  campaign_text?: InputMaybe<campaign_text_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<flow_text_key_enum_comparison_exp>;
  translation?: InputMaybe<translation_bool_exp>;
  translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "campaign_text_version" */
export enum campaign_text_version_constraint {
  /** unique or primary key constraint on columns "id" */
  campaign_text_version_pkey = 'campaign_text_version_pkey'
}

/** input type for incrementing numeric columns in table "campaign_text_version" */
export type campaign_text_version_inc_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "campaign_text_version" */
export type campaign_text_version_insert_input = {
  campaign?: InputMaybe<campaign_obj_rel_insert_input>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_text?: InputMaybe<campaign_text_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation?: InputMaybe<translation_obj_rel_insert_input>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type campaign_text_version_max_fields = {
  __typename: 'campaign_text_version_max_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type campaign_text_version_min_fields = {
  __typename: 'campaign_text_version_min_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "campaign_text_version" */
export type campaign_text_version_mutation_response = {
  __typename: 'campaign_text_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<campaign_text_version>;
};

/** input type for inserting object relation for remote table "campaign_text_version" */
export type campaign_text_version_obj_rel_insert_input = {
  data: campaign_text_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_text_version_on_conflict>;
};

/** on_conflict condition type for table "campaign_text_version" */
export type campaign_text_version_on_conflict = {
  constraint: campaign_text_version_constraint;
  update_columns?: Array<campaign_text_version_update_column>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};

/** Ordering options when selecting data from "campaign_text_version". */
export type campaign_text_version_order_by = {
  campaign?: InputMaybe<campaign_order_by>;
  campaign_id?: InputMaybe<order_by>;
  campaign_text?: InputMaybe<campaign_text_order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  translation?: InputMaybe<translation_order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: campaign_text_version */
export type campaign_text_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "campaign_text_version" */
export enum campaign_text_version_select_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "campaign_text_version" */
export type campaign_text_version_set_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type campaign_text_version_stddev_fields = {
  __typename: 'campaign_text_version_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type campaign_text_version_stddev_pop_fields = {
  __typename: 'campaign_text_version_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type campaign_text_version_stddev_samp_fields = {
  __typename: 'campaign_text_version_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "campaign_text_version" */
export type campaign_text_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: campaign_text_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type campaign_text_version_stream_cursor_value_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type campaign_text_version_sum_fields = {
  __typename: 'campaign_text_version_sum_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "campaign_text_version" */
export enum campaign_text_version_update_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type campaign_text_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<campaign_text_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<campaign_text_version_set_input>;
  /** filter the rows which have to be updated */
  where: campaign_text_version_bool_exp;
};

/** aggregate var_pop on columns */
export type campaign_text_version_var_pop_fields = {
  __typename: 'campaign_text_version_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type campaign_text_version_var_samp_fields = {
  __typename: 'campaign_text_version_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type campaign_text_version_variance_fields = {
  __typename: 'campaign_text_version_variance_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "campaign_trigger_type" */
export type campaign_trigger_type = {
  __typename: 'campaign_trigger_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "campaign_trigger_type" */
export type campaign_trigger_type_aggregate = {
  __typename: 'campaign_trigger_type_aggregate';
  aggregate?: Maybe<campaign_trigger_type_aggregate_fields>;
  nodes: Array<campaign_trigger_type>;
};

/** aggregate fields of "campaign_trigger_type" */
export type campaign_trigger_type_aggregate_fields = {
  __typename: 'campaign_trigger_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<campaign_trigger_type_max_fields>;
  min?: Maybe<campaign_trigger_type_min_fields>;
};


/** aggregate fields of "campaign_trigger_type" */
export type campaign_trigger_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<campaign_trigger_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "campaign_trigger_type". All fields are combined with a logical 'AND'. */
export type campaign_trigger_type_bool_exp = {
  _and?: InputMaybe<Array<campaign_trigger_type_bool_exp>>;
  _not?: InputMaybe<campaign_trigger_type_bool_exp>;
  _or?: InputMaybe<Array<campaign_trigger_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "campaign_trigger_type" */
export enum campaign_trigger_type_constraint {
  /** unique or primary key constraint on columns "value" */
  campaign_trigger_type_pkey = 'campaign_trigger_type_pkey'
}

export enum campaign_trigger_type_enum {
  immediate = 'immediate',
  manual = 'manual',
  specific_page = 'specific_page'
}

/** Boolean expression to compare columns of type "campaign_trigger_type_enum". All fields are combined with logical 'AND'. */
export type campaign_trigger_type_enum_comparison_exp = {
  _eq?: InputMaybe<campaign_trigger_type_enum>;
  _in?: InputMaybe<Array<campaign_trigger_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<campaign_trigger_type_enum>;
  _nin?: InputMaybe<Array<campaign_trigger_type_enum>>;
};

/** input type for inserting data into table "campaign_trigger_type" */
export type campaign_trigger_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type campaign_trigger_type_max_fields = {
  __typename: 'campaign_trigger_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type campaign_trigger_type_min_fields = {
  __typename: 'campaign_trigger_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "campaign_trigger_type" */
export type campaign_trigger_type_mutation_response = {
  __typename: 'campaign_trigger_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<campaign_trigger_type>;
};

/** on_conflict condition type for table "campaign_trigger_type" */
export type campaign_trigger_type_on_conflict = {
  constraint: campaign_trigger_type_constraint;
  update_columns?: Array<campaign_trigger_type_update_column>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};

/** Ordering options when selecting data from "campaign_trigger_type". */
export type campaign_trigger_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: campaign_trigger_type */
export type campaign_trigger_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "campaign_trigger_type" */
export enum campaign_trigger_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "campaign_trigger_type" */
export type campaign_trigger_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "campaign_trigger_type" */
export type campaign_trigger_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: campaign_trigger_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type campaign_trigger_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "campaign_trigger_type" */
export enum campaign_trigger_type_update_column {
  /** column name */
  value = 'value'
}

export type campaign_trigger_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<campaign_trigger_type_set_input>;
  /** filter the rows which have to be updated */
  where: campaign_trigger_type_bool_exp;
};

/** update columns of table "campaign" */
export enum campaign_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  default_language = 'default_language',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  signature_required = 'signature_required',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type campaign_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<campaign_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<campaign_set_input>;
  /** filter the rows which have to be updated */
  where: campaign_bool_exp;
};

/** aggregate var_pop on columns */
export type campaign_var_pop_fields = {
  __typename: 'campaign_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "campaign" */
export type campaign_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type campaign_var_samp_fields = {
  __typename: 'campaign_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "campaign" */
export type campaign_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type campaign_variance_fields = {
  __typename: 'campaign_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "campaign" */
export type campaign_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "campaign_version" */
export type campaign_version = {
  __typename: 'campaign_version';
  /** An object relationship */
  campaign: campaign;
  campaign_id: Scalars['Int']['output'];
  collect_payment: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  css?: Maybe<Scalars['String']['output']>;
  delay?: Maybe<Scalars['interval']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  offer_rule_group: offer_rule_group;
  offer_rule_group_id: Scalars['Int']['output'];
  redirect_url?: Maybe<Scalars['String']['output']>;
  trigger_type: campaign_trigger_type_enum;
  trigger_url?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "campaign_version" */
export type campaign_version_aggregate = {
  __typename: 'campaign_version_aggregate';
  aggregate?: Maybe<campaign_version_aggregate_fields>;
  nodes: Array<campaign_version>;
};

export type campaign_version_aggregate_bool_exp = {
  bool_and?: InputMaybe<campaign_version_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<campaign_version_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<campaign_version_aggregate_bool_exp_count>;
};

export type campaign_version_aggregate_bool_exp_bool_and = {
  arguments: campaign_version_select_column_campaign_version_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_version_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type campaign_version_aggregate_bool_exp_bool_or = {
  arguments: campaign_version_select_column_campaign_version_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_version_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type campaign_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<campaign_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<campaign_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "campaign_version" */
export type campaign_version_aggregate_fields = {
  __typename: 'campaign_version_aggregate_fields';
  avg?: Maybe<campaign_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<campaign_version_max_fields>;
  min?: Maybe<campaign_version_min_fields>;
  stddev?: Maybe<campaign_version_stddev_fields>;
  stddev_pop?: Maybe<campaign_version_stddev_pop_fields>;
  stddev_samp?: Maybe<campaign_version_stddev_samp_fields>;
  sum?: Maybe<campaign_version_sum_fields>;
  var_pop?: Maybe<campaign_version_var_pop_fields>;
  var_samp?: Maybe<campaign_version_var_samp_fields>;
  variance?: Maybe<campaign_version_variance_fields>;
};


/** aggregate fields of "campaign_version" */
export type campaign_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<campaign_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "campaign_version" */
export type campaign_version_aggregate_order_by = {
  avg?: InputMaybe<campaign_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<campaign_version_max_order_by>;
  min?: InputMaybe<campaign_version_min_order_by>;
  stddev?: InputMaybe<campaign_version_stddev_order_by>;
  stddev_pop?: InputMaybe<campaign_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<campaign_version_stddev_samp_order_by>;
  sum?: InputMaybe<campaign_version_sum_order_by>;
  var_pop?: InputMaybe<campaign_version_var_pop_order_by>;
  var_samp?: InputMaybe<campaign_version_var_samp_order_by>;
  variance?: InputMaybe<campaign_version_variance_order_by>;
};

/** input type for inserting array relation for remote table "campaign_version" */
export type campaign_version_arr_rel_insert_input = {
  data: Array<campaign_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_version_on_conflict>;
};

/** aggregate avg on columns */
export type campaign_version_avg_fields = {
  __typename: 'campaign_version_avg_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "campaign_version" */
export type campaign_version_avg_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "campaign_version". All fields are combined with a logical 'AND'. */
export type campaign_version_bool_exp = {
  _and?: InputMaybe<Array<campaign_version_bool_exp>>;
  _not?: InputMaybe<campaign_version_bool_exp>;
  _or?: InputMaybe<Array<campaign_version_bool_exp>>;
  campaign?: InputMaybe<campaign_bool_exp>;
  campaign_id?: InputMaybe<Int_comparison_exp>;
  collect_payment?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  css?: InputMaybe<String_comparison_exp>;
  delay?: InputMaybe<interval_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
  redirect_url?: InputMaybe<String_comparison_exp>;
  trigger_type?: InputMaybe<campaign_trigger_type_enum_comparison_exp>;
  trigger_url?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "campaign_version" */
export enum campaign_version_constraint {
  /** unique or primary key constraint on columns "id" */
  campaign_version_pkey = 'campaign_version_pkey'
}

/** input type for incrementing numeric columns in table "campaign_version" */
export type campaign_version_inc_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "campaign_version" */
export type campaign_version_insert_input = {
  campaign?: InputMaybe<campaign_obj_rel_insert_input>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  collect_payment?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  trigger_type?: InputMaybe<campaign_trigger_type_enum>;
  trigger_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type campaign_version_max_fields = {
  __typename: 'campaign_version_max_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  redirect_url?: Maybe<Scalars['String']['output']>;
  trigger_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "campaign_version" */
export type campaign_version_max_order_by = {
  campaign_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  redirect_url?: InputMaybe<order_by>;
  trigger_url?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type campaign_version_min_fields = {
  __typename: 'campaign_version_min_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  redirect_url?: Maybe<Scalars['String']['output']>;
  trigger_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "campaign_version" */
export type campaign_version_min_order_by = {
  campaign_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  redirect_url?: InputMaybe<order_by>;
  trigger_url?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "campaign_version" */
export type campaign_version_mutation_response = {
  __typename: 'campaign_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<campaign_version>;
};

/** input type for inserting object relation for remote table "campaign_version" */
export type campaign_version_obj_rel_insert_input = {
  data: campaign_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<campaign_version_on_conflict>;
};

/** on_conflict condition type for table "campaign_version" */
export type campaign_version_on_conflict = {
  constraint: campaign_version_constraint;
  update_columns?: Array<campaign_version_update_column>;
  where?: InputMaybe<campaign_version_bool_exp>;
};

/** Ordering options when selecting data from "campaign_version". */
export type campaign_version_order_by = {
  campaign?: InputMaybe<campaign_order_by>;
  campaign_id?: InputMaybe<order_by>;
  collect_payment?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  delay?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  redirect_url?: InputMaybe<order_by>;
  trigger_type?: InputMaybe<order_by>;
  trigger_url?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: campaign_version */
export type campaign_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "campaign_version" */
export enum campaign_version_select_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  collect_payment = 'collect_payment',
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  delay = 'delay',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  redirect_url = 'redirect_url',
  /** column name */
  trigger_type = 'trigger_type',
  /** column name */
  trigger_url = 'trigger_url',
  /** column name */
  updated_at = 'updated_at'
}

/** select "campaign_version_aggregate_bool_exp_bool_and_arguments_columns" columns of table "campaign_version" */
export enum campaign_version_select_column_campaign_version_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  collect_payment = 'collect_payment'
}

/** select "campaign_version_aggregate_bool_exp_bool_or_arguments_columns" columns of table "campaign_version" */
export enum campaign_version_select_column_campaign_version_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  collect_payment = 'collect_payment'
}

/** input type for updating data in table "campaign_version" */
export type campaign_version_set_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  collect_payment?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  trigger_type?: InputMaybe<campaign_trigger_type_enum>;
  trigger_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type campaign_version_stddev_fields = {
  __typename: 'campaign_version_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "campaign_version" */
export type campaign_version_stddev_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type campaign_version_stddev_pop_fields = {
  __typename: 'campaign_version_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "campaign_version" */
export type campaign_version_stddev_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type campaign_version_stddev_samp_fields = {
  __typename: 'campaign_version_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "campaign_version" */
export type campaign_version_stddev_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "campaign_version" */
export type campaign_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: campaign_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type campaign_version_stream_cursor_value_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  collect_payment?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['interval']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  trigger_type?: InputMaybe<campaign_trigger_type_enum>;
  trigger_url?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type campaign_version_sum_fields = {
  __typename: 'campaign_version_sum_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "campaign_version" */
export type campaign_version_sum_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** update columns of table "campaign_version" */
export enum campaign_version_update_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  collect_payment = 'collect_payment',
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  delay = 'delay',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  redirect_url = 'redirect_url',
  /** column name */
  trigger_type = 'trigger_type',
  /** column name */
  trigger_url = 'trigger_url',
  /** column name */
  updated_at = 'updated_at'
}

export type campaign_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<campaign_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<campaign_version_set_input>;
  /** filter the rows which have to be updated */
  where: campaign_version_bool_exp;
};

/** aggregate var_pop on columns */
export type campaign_version_var_pop_fields = {
  __typename: 'campaign_version_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "campaign_version" */
export type campaign_version_var_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type campaign_version_var_samp_fields = {
  __typename: 'campaign_version_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "campaign_version" */
export type campaign_version_var_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type campaign_version_variance_fields = {
  __typename: 'campaign_version_variance_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "campaign_version" */
export type campaign_version_variance_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** columns and relationships of "cancellations_by_month" */
export type cancellations_by_month = {
  __typename: 'cancellations_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "cancellations_by_month" */
export type cancellations_by_month_aggregate = {
  __typename: 'cancellations_by_month_aggregate';
  aggregate?: Maybe<cancellations_by_month_aggregate_fields>;
  nodes: Array<cancellations_by_month>;
};

/** aggregate fields of "cancellations_by_month" */
export type cancellations_by_month_aggregate_fields = {
  __typename: 'cancellations_by_month_aggregate_fields';
  avg?: Maybe<cancellations_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<cancellations_by_month_max_fields>;
  min?: Maybe<cancellations_by_month_min_fields>;
  stddev?: Maybe<cancellations_by_month_stddev_fields>;
  stddev_pop?: Maybe<cancellations_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<cancellations_by_month_stddev_samp_fields>;
  sum?: Maybe<cancellations_by_month_sum_fields>;
  var_pop?: Maybe<cancellations_by_month_var_pop_fields>;
  var_samp?: Maybe<cancellations_by_month_var_samp_fields>;
  variance?: Maybe<cancellations_by_month_variance_fields>;
};


/** aggregate fields of "cancellations_by_month" */
export type cancellations_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<cancellations_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type cancellations_by_month_avg_fields = {
  __typename: 'cancellations_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "cancellations_by_month". All fields are combined with a logical 'AND'. */
export type cancellations_by_month_bool_exp = {
  _and?: InputMaybe<Array<cancellations_by_month_bool_exp>>;
  _not?: InputMaybe<cancellations_by_month_bool_exp>;
  _or?: InputMaybe<Array<cancellations_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<bigint_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
  reason?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type cancellations_by_month_max_fields = {
  __typename: 'cancellations_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type cancellations_by_month_min_fields = {
  __typename: 'cancellations_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "cancellations_by_month". */
export type cancellations_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
  reason?: InputMaybe<order_by>;
};

/** select columns of table "cancellations_by_month" */
export enum cancellations_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  month = 'month',
  /** column name */
  reason = 'reason'
}

/** aggregate stddev on columns */
export type cancellations_by_month_stddev_fields = {
  __typename: 'cancellations_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type cancellations_by_month_stddev_pop_fields = {
  __typename: 'cancellations_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type cancellations_by_month_stddev_samp_fields = {
  __typename: 'cancellations_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "cancellations_by_month" */
export type cancellations_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: cancellations_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type cancellations_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['bigint']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type cancellations_by_month_sum_fields = {
  __typename: 'cancellations_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type cancellations_by_month_var_pop_fields = {
  __typename: 'cancellations_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type cancellations_by_month_var_samp_fields = {
  __typename: 'cancellations_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type cancellations_by_month_variance_fields = {
  __typename: 'cancellations_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type citext_comparison_exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** columns and relationships of "confirmation" */
export type confirmation = {
  __typename: 'confirmation';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<confirmation_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_confirmations: Array<flow_step_confirmation>;
  /** An aggregate relationship */
  flow_step_confirmations_aggregate: flow_step_confirmation_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<confirmation_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "confirmation" */
export type confirmationflow_step_confirmationsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


/** columns and relationships of "confirmation" */
export type confirmationflow_step_confirmations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};

/** aggregated selection of "confirmation" */
export type confirmation_aggregate = {
  __typename: 'confirmation_aggregate';
  aggregate?: Maybe<confirmation_aggregate_fields>;
  nodes: Array<confirmation>;
};

/** aggregate fields of "confirmation" */
export type confirmation_aggregate_fields = {
  __typename: 'confirmation_aggregate_fields';
  avg?: Maybe<confirmation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<confirmation_max_fields>;
  min?: Maybe<confirmation_min_fields>;
  stddev?: Maybe<confirmation_stddev_fields>;
  stddev_pop?: Maybe<confirmation_stddev_pop_fields>;
  stddev_samp?: Maybe<confirmation_stddev_samp_fields>;
  sum?: Maybe<confirmation_sum_fields>;
  var_pop?: Maybe<confirmation_var_pop_fields>;
  var_samp?: Maybe<confirmation_var_samp_fields>;
  variance?: Maybe<confirmation_variance_fields>;
};


/** aggregate fields of "confirmation" */
export type confirmation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<confirmation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type confirmation_avg_fields = {
  __typename: 'confirmation_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "confirmation". All fields are combined with a logical 'AND'. */
export type confirmation_bool_exp = {
  _and?: InputMaybe<Array<confirmation_bool_exp>>;
  _not?: InputMaybe<confirmation_bool_exp>;
  _or?: InputMaybe<Array<confirmation_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<confirmation_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_confirmations?: InputMaybe<flow_step_confirmation_bool_exp>;
  flow_step_confirmations_aggregate?: InputMaybe<flow_step_confirmation_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<confirmation_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "confirmation" */
export enum confirmation_constraint {
  /** unique or primary key constraint on columns "id" */
  confirmation_pkey = 'confirmation_pkey'
}

/** input type for incrementing numeric columns in table "confirmation" */
export type confirmation_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "confirmation" */
export type confirmation_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<confirmation_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_confirmations?: InputMaybe<flow_step_confirmation_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<confirmation_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type confirmation_max_fields = {
  __typename: 'confirmation_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type confirmation_min_fields = {
  __typename: 'confirmation_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "confirmation" */
export type confirmation_mutation_response = {
  __typename: 'confirmation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<confirmation>;
};

/** input type for inserting object relation for remote table "confirmation" */
export type confirmation_obj_rel_insert_input = {
  data: confirmation_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<confirmation_on_conflict>;
};

/** on_conflict condition type for table "confirmation" */
export type confirmation_on_conflict = {
  constraint: confirmation_constraint;
  update_columns?: Array<confirmation_update_column>;
  where?: InputMaybe<confirmation_bool_exp>;
};

/** Ordering options when selecting data from "confirmation". */
export type confirmation_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<confirmation_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_confirmations_aggregate?: InputMaybe<flow_step_confirmation_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<confirmation_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: confirmation */
export type confirmation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "confirmation" */
export enum confirmation_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "confirmation" */
export type confirmation_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type confirmation_stddev_fields = {
  __typename: 'confirmation_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type confirmation_stddev_pop_fields = {
  __typename: 'confirmation_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type confirmation_stddev_samp_fields = {
  __typename: 'confirmation_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "confirmation" */
export type confirmation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: confirmation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type confirmation_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type confirmation_sum_fields = {
  __typename: 'confirmation_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "confirmation" */
export enum confirmation_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type confirmation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<confirmation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<confirmation_set_input>;
  /** filter the rows which have to be updated */
  where: confirmation_bool_exp;
};

/** aggregate var_pop on columns */
export type confirmation_var_pop_fields = {
  __typename: 'confirmation_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type confirmation_var_samp_fields = {
  __typename: 'confirmation_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type confirmation_variance_fields = {
  __typename: 'confirmation_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "confirmation_version" */
export type confirmation_version = {
  __typename: 'confirmation_version';
  /** An object relationship */
  canceled_content_translation: translation;
  canceled_content_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  canceled_headline_translation: translation;
  canceled_headline_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  confirmation: confirmation;
  confirmation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  saved_content_translation: translation;
  saved_content_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  saved_headline_translation: translation;
  saved_headline_translation_id: Scalars['Int']['output'];
  show_on_cancel: Scalars['Boolean']['output'];
  show_on_save: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "confirmation_version" */
export type confirmation_version_aggregate = {
  __typename: 'confirmation_version_aggregate';
  aggregate?: Maybe<confirmation_version_aggregate_fields>;
  nodes: Array<confirmation_version>;
};

/** aggregate fields of "confirmation_version" */
export type confirmation_version_aggregate_fields = {
  __typename: 'confirmation_version_aggregate_fields';
  avg?: Maybe<confirmation_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<confirmation_version_max_fields>;
  min?: Maybe<confirmation_version_min_fields>;
  stddev?: Maybe<confirmation_version_stddev_fields>;
  stddev_pop?: Maybe<confirmation_version_stddev_pop_fields>;
  stddev_samp?: Maybe<confirmation_version_stddev_samp_fields>;
  sum?: Maybe<confirmation_version_sum_fields>;
  var_pop?: Maybe<confirmation_version_var_pop_fields>;
  var_samp?: Maybe<confirmation_version_var_samp_fields>;
  variance?: Maybe<confirmation_version_variance_fields>;
};


/** aggregate fields of "confirmation_version" */
export type confirmation_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<confirmation_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type confirmation_version_avg_fields = {
  __typename: 'confirmation_version_avg_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "confirmation_version". All fields are combined with a logical 'AND'. */
export type confirmation_version_bool_exp = {
  _and?: InputMaybe<Array<confirmation_version_bool_exp>>;
  _not?: InputMaybe<confirmation_version_bool_exp>;
  _or?: InputMaybe<Array<confirmation_version_bool_exp>>;
  canceled_content_translation?: InputMaybe<translation_bool_exp>;
  canceled_content_translation_id?: InputMaybe<Int_comparison_exp>;
  canceled_headline_translation?: InputMaybe<translation_bool_exp>;
  canceled_headline_translation_id?: InputMaybe<Int_comparison_exp>;
  confirmation?: InputMaybe<confirmation_bool_exp>;
  confirmation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  saved_content_translation?: InputMaybe<translation_bool_exp>;
  saved_content_translation_id?: InputMaybe<Int_comparison_exp>;
  saved_headline_translation?: InputMaybe<translation_bool_exp>;
  saved_headline_translation_id?: InputMaybe<Int_comparison_exp>;
  show_on_cancel?: InputMaybe<Boolean_comparison_exp>;
  show_on_save?: InputMaybe<Boolean_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "confirmation_version" */
export enum confirmation_version_constraint {
  /** unique or primary key constraint on columns "id" */
  confirmation_version_pkey = 'confirmation_version_pkey'
}

/** input type for incrementing numeric columns in table "confirmation_version" */
export type confirmation_version_inc_input = {
  canceled_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  canceled_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  saved_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  saved_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "confirmation_version" */
export type confirmation_version_insert_input = {
  canceled_content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  canceled_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  canceled_headline_translation?: InputMaybe<translation_obj_rel_insert_input>;
  canceled_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation?: InputMaybe<confirmation_obj_rel_insert_input>;
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  saved_content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  saved_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  saved_headline_translation?: InputMaybe<translation_obj_rel_insert_input>;
  saved_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  show_on_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  show_on_save?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type confirmation_version_max_fields = {
  __typename: 'confirmation_version_max_fields';
  canceled_content_translation_id?: Maybe<Scalars['Int']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Int']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type confirmation_version_min_fields = {
  __typename: 'confirmation_version_min_fields';
  canceled_content_translation_id?: Maybe<Scalars['Int']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Int']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "confirmation_version" */
export type confirmation_version_mutation_response = {
  __typename: 'confirmation_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<confirmation_version>;
};

/** input type for inserting object relation for remote table "confirmation_version" */
export type confirmation_version_obj_rel_insert_input = {
  data: confirmation_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<confirmation_version_on_conflict>;
};

/** on_conflict condition type for table "confirmation_version" */
export type confirmation_version_on_conflict = {
  constraint: confirmation_version_constraint;
  update_columns?: Array<confirmation_version_update_column>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};

/** Ordering options when selecting data from "confirmation_version". */
export type confirmation_version_order_by = {
  canceled_content_translation?: InputMaybe<translation_order_by>;
  canceled_content_translation_id?: InputMaybe<order_by>;
  canceled_headline_translation?: InputMaybe<translation_order_by>;
  canceled_headline_translation_id?: InputMaybe<order_by>;
  confirmation?: InputMaybe<confirmation_order_by>;
  confirmation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  saved_content_translation?: InputMaybe<translation_order_by>;
  saved_content_translation_id?: InputMaybe<order_by>;
  saved_headline_translation?: InputMaybe<translation_order_by>;
  saved_headline_translation_id?: InputMaybe<order_by>;
  show_on_cancel?: InputMaybe<order_by>;
  show_on_save?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: confirmation_version */
export type confirmation_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "confirmation_version" */
export enum confirmation_version_select_column {
  /** column name */
  canceled_content_translation_id = 'canceled_content_translation_id',
  /** column name */
  canceled_headline_translation_id = 'canceled_headline_translation_id',
  /** column name */
  confirmation_id = 'confirmation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  saved_content_translation_id = 'saved_content_translation_id',
  /** column name */
  saved_headline_translation_id = 'saved_headline_translation_id',
  /** column name */
  show_on_cancel = 'show_on_cancel',
  /** column name */
  show_on_save = 'show_on_save',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "confirmation_version" */
export type confirmation_version_set_input = {
  canceled_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  canceled_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  saved_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  saved_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  show_on_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  show_on_save?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type confirmation_version_stddev_fields = {
  __typename: 'confirmation_version_stddev_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type confirmation_version_stddev_pop_fields = {
  __typename: 'confirmation_version_stddev_pop_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type confirmation_version_stddev_samp_fields = {
  __typename: 'confirmation_version_stddev_samp_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "confirmation_version" */
export type confirmation_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: confirmation_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type confirmation_version_stream_cursor_value_input = {
  canceled_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  canceled_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  saved_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  saved_headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  show_on_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  show_on_save?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type confirmation_version_sum_fields = {
  __typename: 'confirmation_version_sum_fields';
  canceled_content_translation_id?: Maybe<Scalars['Int']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Int']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "confirmation_version" */
export enum confirmation_version_update_column {
  /** column name */
  canceled_content_translation_id = 'canceled_content_translation_id',
  /** column name */
  canceled_headline_translation_id = 'canceled_headline_translation_id',
  /** column name */
  confirmation_id = 'confirmation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  saved_content_translation_id = 'saved_content_translation_id',
  /** column name */
  saved_headline_translation_id = 'saved_headline_translation_id',
  /** column name */
  show_on_cancel = 'show_on_cancel',
  /** column name */
  show_on_save = 'show_on_save',
  /** column name */
  updated_at = 'updated_at'
}

export type confirmation_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<confirmation_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<confirmation_version_set_input>;
  /** filter the rows which have to be updated */
  where: confirmation_version_bool_exp;
};

/** aggregate var_pop on columns */
export type confirmation_version_var_pop_fields = {
  __typename: 'confirmation_version_var_pop_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type confirmation_version_var_samp_fields = {
  __typename: 'confirmation_version_var_samp_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type confirmation_version_variance_fields = {
  __typename: 'confirmation_version_variance_fields';
  canceled_content_translation_id?: Maybe<Scalars['Float']['output']>;
  canceled_headline_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  saved_content_translation_id?: Maybe<Scalars['Float']['output']>;
  saved_headline_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "country" */
export type country = {
  __typename: 'country';
  iso: Scalars['citext']['output'];
  name: Scalars['citext']['output'];
};

/** aggregated selection of "country" */
export type country_aggregate = {
  __typename: 'country_aggregate';
  aggregate?: Maybe<country_aggregate_fields>;
  nodes: Array<country>;
};

/** aggregate fields of "country" */
export type country_aggregate_fields = {
  __typename: 'country_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<country_max_fields>;
  min?: Maybe<country_min_fields>;
};


/** aggregate fields of "country" */
export type country_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<country_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type country_bool_exp = {
  _and?: InputMaybe<Array<country_bool_exp>>;
  _not?: InputMaybe<country_bool_exp>;
  _or?: InputMaybe<Array<country_bool_exp>>;
  iso?: InputMaybe<citext_comparison_exp>;
  name?: InputMaybe<citext_comparison_exp>;
};

/** unique or primary key constraints on table "country" */
export enum country_constraint {
  /** unique or primary key constraint on columns "iso" */
  country_pkey = 'country_pkey'
}

/** input type for inserting data into table "country" */
export type country_insert_input = {
  iso?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate max on columns */
export type country_max_fields = {
  __typename: 'country_max_fields';
  iso?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** aggregate min on columns */
export type country_min_fields = {
  __typename: 'country_min_fields';
  iso?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** response of any mutation on the table "country" */
export type country_mutation_response = {
  __typename: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<country>;
};

/** on_conflict condition type for table "country" */
export type country_on_conflict = {
  constraint: country_constraint;
  update_columns?: Array<country_update_column>;
  where?: InputMaybe<country_bool_exp>;
};

/** Ordering options when selecting data from "country". */
export type country_order_by = {
  iso?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: country */
export type country_pk_columns_input = {
  iso: Scalars['citext']['input'];
};

/** select columns of table "country" */
export enum country_select_column {
  /** column name */
  iso = 'iso',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "country" */
export type country_set_input = {
  iso?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** Streaming cursor of the table "country" */
export type country_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: country_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type country_stream_cursor_value_input = {
  iso?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** update columns of table "country" */
export enum country_update_column {
  /** column name */
  iso = 'iso',
  /** column name */
  name = 'name'
}

export type country_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<country_set_input>;
  /** filter the rows which have to be updated */
  where: country_bool_exp;
};

/** columns and relationships of "coupon_duration" */
export type coupon_duration = {
  __typename: 'coupon_duration';
  /** An array relationship */
  coupons: Array<offer_coupon>;
  /** An aggregate relationship */
  coupons_aggregate: offer_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "coupon_duration" */
export type coupon_durationcouponsArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


/** columns and relationships of "coupon_duration" */
export type coupon_durationcoupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};

/** aggregated selection of "coupon_duration" */
export type coupon_duration_aggregate = {
  __typename: 'coupon_duration_aggregate';
  aggregate?: Maybe<coupon_duration_aggregate_fields>;
  nodes: Array<coupon_duration>;
};

/** aggregate fields of "coupon_duration" */
export type coupon_duration_aggregate_fields = {
  __typename: 'coupon_duration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<coupon_duration_max_fields>;
  min?: Maybe<coupon_duration_min_fields>;
};


/** aggregate fields of "coupon_duration" */
export type coupon_duration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<coupon_duration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "coupon_duration". All fields are combined with a logical 'AND'. */
export type coupon_duration_bool_exp = {
  _and?: InputMaybe<Array<coupon_duration_bool_exp>>;
  _not?: InputMaybe<coupon_duration_bool_exp>;
  _or?: InputMaybe<Array<coupon_duration_bool_exp>>;
  coupons?: InputMaybe<offer_coupon_bool_exp>;
  coupons_aggregate?: InputMaybe<offer_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "coupon_duration" */
export enum coupon_duration_constraint {
  /** unique or primary key constraint on columns "value" */
  coupon_duration_pkey = 'coupon_duration_pkey'
}

export enum coupon_duration_enum {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating'
}

/** Boolean expression to compare columns of type "coupon_duration_enum". All fields are combined with logical 'AND'. */
export type coupon_duration_enum_comparison_exp = {
  _eq?: InputMaybe<coupon_duration_enum>;
  _in?: InputMaybe<Array<coupon_duration_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<coupon_duration_enum>;
  _nin?: InputMaybe<Array<coupon_duration_enum>>;
};

/** input type for inserting data into table "coupon_duration" */
export type coupon_duration_insert_input = {
  coupons?: InputMaybe<offer_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "coupon_duration_interval" */
export type coupon_duration_interval = {
  __typename: 'coupon_duration_interval';
  value: Scalars['String']['output'];
};

/** aggregated selection of "coupon_duration_interval" */
export type coupon_duration_interval_aggregate = {
  __typename: 'coupon_duration_interval_aggregate';
  aggregate?: Maybe<coupon_duration_interval_aggregate_fields>;
  nodes: Array<coupon_duration_interval>;
};

/** aggregate fields of "coupon_duration_interval" */
export type coupon_duration_interval_aggregate_fields = {
  __typename: 'coupon_duration_interval_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<coupon_duration_interval_max_fields>;
  min?: Maybe<coupon_duration_interval_min_fields>;
};


/** aggregate fields of "coupon_duration_interval" */
export type coupon_duration_interval_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<coupon_duration_interval_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "coupon_duration_interval". All fields are combined with a logical 'AND'. */
export type coupon_duration_interval_bool_exp = {
  _and?: InputMaybe<Array<coupon_duration_interval_bool_exp>>;
  _not?: InputMaybe<coupon_duration_interval_bool_exp>;
  _or?: InputMaybe<Array<coupon_duration_interval_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "coupon_duration_interval" */
export enum coupon_duration_interval_constraint {
  /** unique or primary key constraint on columns "value" */
  coupon_duration_interval_pkey = 'coupon_duration_interval_pkey'
}

export enum coupon_duration_interval_enum {
  billing_cycle = 'billing_cycle',
  day = 'day',
  month = 'month',
  week = 'week',
  year = 'year'
}

/** Boolean expression to compare columns of type "coupon_duration_interval_enum". All fields are combined with logical 'AND'. */
export type coupon_duration_interval_enum_comparison_exp = {
  _eq?: InputMaybe<coupon_duration_interval_enum>;
  _in?: InputMaybe<Array<coupon_duration_interval_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<coupon_duration_interval_enum>;
  _nin?: InputMaybe<Array<coupon_duration_interval_enum>>;
};

/** input type for inserting data into table "coupon_duration_interval" */
export type coupon_duration_interval_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type coupon_duration_interval_max_fields = {
  __typename: 'coupon_duration_interval_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type coupon_duration_interval_min_fields = {
  __typename: 'coupon_duration_interval_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "coupon_duration_interval" */
export type coupon_duration_interval_mutation_response = {
  __typename: 'coupon_duration_interval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<coupon_duration_interval>;
};

/** on_conflict condition type for table "coupon_duration_interval" */
export type coupon_duration_interval_on_conflict = {
  constraint: coupon_duration_interval_constraint;
  update_columns?: Array<coupon_duration_interval_update_column>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};

/** Ordering options when selecting data from "coupon_duration_interval". */
export type coupon_duration_interval_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: coupon_duration_interval */
export type coupon_duration_interval_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "coupon_duration_interval" */
export enum coupon_duration_interval_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "coupon_duration_interval" */
export type coupon_duration_interval_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "coupon_duration_interval" */
export type coupon_duration_interval_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: coupon_duration_interval_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type coupon_duration_interval_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "coupon_duration_interval" */
export enum coupon_duration_interval_update_column {
  /** column name */
  value = 'value'
}

export type coupon_duration_interval_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<coupon_duration_interval_set_input>;
  /** filter the rows which have to be updated */
  where: coupon_duration_interval_bool_exp;
};

/** aggregate max on columns */
export type coupon_duration_max_fields = {
  __typename: 'coupon_duration_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type coupon_duration_min_fields = {
  __typename: 'coupon_duration_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "coupon_duration" */
export type coupon_duration_mutation_response = {
  __typename: 'coupon_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<coupon_duration>;
};

/** input type for inserting object relation for remote table "coupon_duration" */
export type coupon_duration_obj_rel_insert_input = {
  data: coupon_duration_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<coupon_duration_on_conflict>;
};

/** on_conflict condition type for table "coupon_duration" */
export type coupon_duration_on_conflict = {
  constraint: coupon_duration_constraint;
  update_columns?: Array<coupon_duration_update_column>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};

/** Ordering options when selecting data from "coupon_duration". */
export type coupon_duration_order_by = {
  coupons_aggregate?: InputMaybe<offer_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: coupon_duration */
export type coupon_duration_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "coupon_duration" */
export enum coupon_duration_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "coupon_duration" */
export type coupon_duration_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "coupon_duration" */
export type coupon_duration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: coupon_duration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type coupon_duration_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "coupon_duration" */
export enum coupon_duration_update_column {
  /** column name */
  value = 'value'
}

export type coupon_duration_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<coupon_duration_set_input>;
  /** filter the rows which have to be updated */
  where: coupon_duration_bool_exp;
};

/** columns and relationships of "csv_delta_file" */
export type csv_delta_file = {
  __typename: 'csv_delta_file';
  account_id: Scalars['Int']['output'];
  base_import_file_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key: Scalars['String']['output'];
  previous_import_file_id?: Maybe<Scalars['Int']['output']>;
  row_count: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "csv_delta_file" */
export type csv_delta_file_aggregate = {
  __typename: 'csv_delta_file_aggregate';
  aggregate?: Maybe<csv_delta_file_aggregate_fields>;
  nodes: Array<csv_delta_file>;
};

/** aggregate fields of "csv_delta_file" */
export type csv_delta_file_aggregate_fields = {
  __typename: 'csv_delta_file_aggregate_fields';
  avg?: Maybe<csv_delta_file_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<csv_delta_file_max_fields>;
  min?: Maybe<csv_delta_file_min_fields>;
  stddev?: Maybe<csv_delta_file_stddev_fields>;
  stddev_pop?: Maybe<csv_delta_file_stddev_pop_fields>;
  stddev_samp?: Maybe<csv_delta_file_stddev_samp_fields>;
  sum?: Maybe<csv_delta_file_sum_fields>;
  var_pop?: Maybe<csv_delta_file_var_pop_fields>;
  var_samp?: Maybe<csv_delta_file_var_samp_fields>;
  variance?: Maybe<csv_delta_file_variance_fields>;
};


/** aggregate fields of "csv_delta_file" */
export type csv_delta_file_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<csv_delta_file_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type csv_delta_file_avg_fields = {
  __typename: 'csv_delta_file_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "csv_delta_file". All fields are combined with a logical 'AND'. */
export type csv_delta_file_bool_exp = {
  _and?: InputMaybe<Array<csv_delta_file_bool_exp>>;
  _not?: InputMaybe<csv_delta_file_bool_exp>;
  _or?: InputMaybe<Array<csv_delta_file_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  base_import_file_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  hash?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  imported_at?: InputMaybe<timestamptz_comparison_exp>;
  key?: InputMaybe<String_comparison_exp>;
  previous_import_file_id?: InputMaybe<Int_comparison_exp>;
  row_count?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "csv_delta_file" */
export enum csv_delta_file_constraint {
  /** unique or primary key constraint on columns "key" */
  csv_delta_file_key_key = 'csv_delta_file_key_key',
  /** unique or primary key constraint on columns "id" */
  csv_delta_file_pkey = 'csv_delta_file_pkey'
}

/** input type for incrementing numeric columns in table "csv_delta_file" */
export type csv_delta_file_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  previous_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "csv_delta_file" */
export type csv_delta_file_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type csv_delta_file_max_fields = {
  __typename: 'csv_delta_file_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_import_file_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  previous_import_file_id?: Maybe<Scalars['Int']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type csv_delta_file_min_fields = {
  __typename: 'csv_delta_file_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_import_file_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  previous_import_file_id?: Maybe<Scalars['Int']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "csv_delta_file" */
export type csv_delta_file_mutation_response = {
  __typename: 'csv_delta_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<csv_delta_file>;
};

/** on_conflict condition type for table "csv_delta_file" */
export type csv_delta_file_on_conflict = {
  constraint: csv_delta_file_constraint;
  update_columns?: Array<csv_delta_file_update_column>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};

/** Ordering options when selecting data from "csv_delta_file". */
export type csv_delta_file_order_by = {
  account_id?: InputMaybe<order_by>;
  base_import_file_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  hash?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  imported_at?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  previous_import_file_id?: InputMaybe<order_by>;
  row_count?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: csv_delta_file */
export type csv_delta_file_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "csv_delta_file" */
export enum csv_delta_file_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  base_import_file_id = 'base_import_file_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  imported_at = 'imported_at',
  /** column name */
  key = 'key',
  /** column name */
  previous_import_file_id = 'previous_import_file_id',
  /** column name */
  row_count = 'row_count',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "csv_delta_file" */
export type csv_delta_file_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type csv_delta_file_stddev_fields = {
  __typename: 'csv_delta_file_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type csv_delta_file_stddev_pop_fields = {
  __typename: 'csv_delta_file_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type csv_delta_file_stddev_samp_fields = {
  __typename: 'csv_delta_file_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "csv_delta_file" */
export type csv_delta_file_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: csv_delta_file_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type csv_delta_file_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  previous_import_file_id?: InputMaybe<Scalars['Int']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type csv_delta_file_sum_fields = {
  __typename: 'csv_delta_file_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_import_file_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  previous_import_file_id?: Maybe<Scalars['Int']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "csv_delta_file" */
export enum csv_delta_file_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  base_import_file_id = 'base_import_file_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  imported_at = 'imported_at',
  /** column name */
  key = 'key',
  /** column name */
  previous_import_file_id = 'previous_import_file_id',
  /** column name */
  row_count = 'row_count',
  /** column name */
  updated_at = 'updated_at'
}

export type csv_delta_file_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<csv_delta_file_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<csv_delta_file_set_input>;
  /** filter the rows which have to be updated */
  where: csv_delta_file_bool_exp;
};

/** aggregate var_pop on columns */
export type csv_delta_file_var_pop_fields = {
  __typename: 'csv_delta_file_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type csv_delta_file_var_samp_fields = {
  __typename: 'csv_delta_file_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type csv_delta_file_variance_fields = {
  __typename: 'csv_delta_file_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_import_file_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  previous_import_file_id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "csv_import" */
export type csv_import = {
  __typename: 'csv_import';
  account_id: Scalars['Int']['output'];
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  cursor?: Maybe<Scalars['jsonb']['output']>;
  delta_file_id: Scalars['Int']['output'];
  forced?: Maybe<Scalars['Boolean']['output']>;
  retry_count?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  status: csv_import_status_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "csv_import" */
export type csv_importcursorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "csv_import" */
export type csv_import_aggregate = {
  __typename: 'csv_import_aggregate';
  aggregate?: Maybe<csv_import_aggregate_fields>;
  nodes: Array<csv_import>;
};

/** aggregate fields of "csv_import" */
export type csv_import_aggregate_fields = {
  __typename: 'csv_import_aggregate_fields';
  avg?: Maybe<csv_import_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<csv_import_max_fields>;
  min?: Maybe<csv_import_min_fields>;
  stddev?: Maybe<csv_import_stddev_fields>;
  stddev_pop?: Maybe<csv_import_stddev_pop_fields>;
  stddev_samp?: Maybe<csv_import_stddev_samp_fields>;
  sum?: Maybe<csv_import_sum_fields>;
  var_pop?: Maybe<csv_import_var_pop_fields>;
  var_samp?: Maybe<csv_import_var_samp_fields>;
  variance?: Maybe<csv_import_variance_fields>;
};


/** aggregate fields of "csv_import" */
export type csv_import_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<csv_import_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type csv_import_append_input = {
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type csv_import_avg_fields = {
  __typename: 'csv_import_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "csv_import". All fields are combined with a logical 'AND'. */
export type csv_import_bool_exp = {
  _and?: InputMaybe<Array<csv_import_bool_exp>>;
  _not?: InputMaybe<csv_import_bool_exp>;
  _or?: InputMaybe<Array<csv_import_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  cursor?: InputMaybe<jsonb_comparison_exp>;
  delta_file_id?: InputMaybe<Int_comparison_exp>;
  forced?: InputMaybe<Boolean_comparison_exp>;
  retry_count?: InputMaybe<Int_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  status?: InputMaybe<csv_import_status_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "csv_import" */
export enum csv_import_constraint {
  /** unique or primary key constraint on columns "delta_file_id" */
  csv_import_pkey = 'csv_import_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type csv_import_delete_at_path_input = {
  cursor?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type csv_import_delete_elem_input = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type csv_import_delete_key_input = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "csv_import_file" */
export type csv_import_file = {
  __typename: 'csv_import_file';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key: Scalars['String']['output'];
  row_count?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "csv_import_file" */
export type csv_import_file_aggregate = {
  __typename: 'csv_import_file_aggregate';
  aggregate?: Maybe<csv_import_file_aggregate_fields>;
  nodes: Array<csv_import_file>;
};

/** aggregate fields of "csv_import_file" */
export type csv_import_file_aggregate_fields = {
  __typename: 'csv_import_file_aggregate_fields';
  avg?: Maybe<csv_import_file_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<csv_import_file_max_fields>;
  min?: Maybe<csv_import_file_min_fields>;
  stddev?: Maybe<csv_import_file_stddev_fields>;
  stddev_pop?: Maybe<csv_import_file_stddev_pop_fields>;
  stddev_samp?: Maybe<csv_import_file_stddev_samp_fields>;
  sum?: Maybe<csv_import_file_sum_fields>;
  var_pop?: Maybe<csv_import_file_var_pop_fields>;
  var_samp?: Maybe<csv_import_file_var_samp_fields>;
  variance?: Maybe<csv_import_file_variance_fields>;
};


/** aggregate fields of "csv_import_file" */
export type csv_import_file_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<csv_import_file_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type csv_import_file_avg_fields = {
  __typename: 'csv_import_file_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "csv_import_file". All fields are combined with a logical 'AND'. */
export type csv_import_file_bool_exp = {
  _and?: InputMaybe<Array<csv_import_file_bool_exp>>;
  _not?: InputMaybe<csv_import_file_bool_exp>;
  _or?: InputMaybe<Array<csv_import_file_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  hash?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  imported_at?: InputMaybe<timestamptz_comparison_exp>;
  key?: InputMaybe<String_comparison_exp>;
  row_count?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "csv_import_file" */
export enum csv_import_file_constraint {
  /** unique or primary key constraint on columns "key" */
  csv_import_file_key_key = 'csv_import_file_key_key',
  /** unique or primary key constraint on columns "id" */
  csv_import_file_pkey = 'csv_import_file_pkey'
}

/** input type for incrementing numeric columns in table "csv_import_file" */
export type csv_import_file_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "csv_import_file" */
export type csv_import_file_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type csv_import_file_max_fields = {
  __typename: 'csv_import_file_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type csv_import_file_min_fields = {
  __typename: 'csv_import_file_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imported_at?: Maybe<Scalars['timestamptz']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "csv_import_file" */
export type csv_import_file_mutation_response = {
  __typename: 'csv_import_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<csv_import_file>;
};

/** on_conflict condition type for table "csv_import_file" */
export type csv_import_file_on_conflict = {
  constraint: csv_import_file_constraint;
  update_columns?: Array<csv_import_file_update_column>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};

/** Ordering options when selecting data from "csv_import_file". */
export type csv_import_file_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  hash?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  imported_at?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  row_count?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: csv_import_file */
export type csv_import_file_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "csv_import_file" */
export enum csv_import_file_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  imported_at = 'imported_at',
  /** column name */
  key = 'key',
  /** column name */
  row_count = 'row_count',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "csv_import_file" */
export type csv_import_file_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type csv_import_file_stddev_fields = {
  __typename: 'csv_import_file_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type csv_import_file_stddev_pop_fields = {
  __typename: 'csv_import_file_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type csv_import_file_stddev_samp_fields = {
  __typename: 'csv_import_file_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "csv_import_file" */
export type csv_import_file_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: csv_import_file_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type csv_import_file_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported_at?: InputMaybe<Scalars['timestamptz']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  row_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type csv_import_file_sum_fields = {
  __typename: 'csv_import_file_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  row_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "csv_import_file" */
export enum csv_import_file_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hash = 'hash',
  /** column name */
  id = 'id',
  /** column name */
  imported_at = 'imported_at',
  /** column name */
  key = 'key',
  /** column name */
  row_count = 'row_count',
  /** column name */
  updated_at = 'updated_at'
}

export type csv_import_file_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<csv_import_file_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<csv_import_file_set_input>;
  /** filter the rows which have to be updated */
  where: csv_import_file_bool_exp;
};

/** aggregate var_pop on columns */
export type csv_import_file_var_pop_fields = {
  __typename: 'csv_import_file_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type csv_import_file_var_samp_fields = {
  __typename: 'csv_import_file_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type csv_import_file_variance_fields = {
  __typename: 'csv_import_file_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  row_count?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "csv_import" */
export type csv_import_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  delta_file_id?: InputMaybe<Scalars['Int']['input']>;
  retry_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "csv_import" */
export type csv_import_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  delta_file_id?: InputMaybe<Scalars['Int']['input']>;
  forced?: InputMaybe<Scalars['Boolean']['input']>;
  retry_count?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<csv_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type csv_import_max_fields = {
  __typename: 'csv_import_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  delta_file_id?: Maybe<Scalars['Int']['output']>;
  retry_count?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type csv_import_min_fields = {
  __typename: 'csv_import_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  delta_file_id?: Maybe<Scalars['Int']['output']>;
  retry_count?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "csv_import" */
export type csv_import_mutation_response = {
  __typename: 'csv_import_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<csv_import>;
};

/** on_conflict condition type for table "csv_import" */
export type csv_import_on_conflict = {
  constraint: csv_import_constraint;
  update_columns?: Array<csv_import_update_column>;
  where?: InputMaybe<csv_import_bool_exp>;
};

/** Ordering options when selecting data from "csv_import". */
export type csv_import_order_by = {
  account_id?: InputMaybe<order_by>;
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  cursor?: InputMaybe<order_by>;
  delta_file_id?: InputMaybe<order_by>;
  forced?: InputMaybe<order_by>;
  retry_count?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: csv_import */
export type csv_import_pk_columns_input = {
  delta_file_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type csv_import_prepend_input = {
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "csv_import" */
export enum csv_import_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  cursor = 'cursor',
  /** column name */
  delta_file_id = 'delta_file_id',
  /** column name */
  forced = 'forced',
  /** column name */
  retry_count = 'retry_count',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "csv_import" */
export type csv_import_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  delta_file_id?: InputMaybe<Scalars['Int']['input']>;
  forced?: InputMaybe<Scalars['Boolean']['input']>;
  retry_count?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<csv_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "csv_import_status" */
export type csv_import_status = {
  __typename: 'csv_import_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "csv_import_status" */
export type csv_import_status_aggregate = {
  __typename: 'csv_import_status_aggregate';
  aggregate?: Maybe<csv_import_status_aggregate_fields>;
  nodes: Array<csv_import_status>;
};

/** aggregate fields of "csv_import_status" */
export type csv_import_status_aggregate_fields = {
  __typename: 'csv_import_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<csv_import_status_max_fields>;
  min?: Maybe<csv_import_status_min_fields>;
};


/** aggregate fields of "csv_import_status" */
export type csv_import_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<csv_import_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "csv_import_status". All fields are combined with a logical 'AND'. */
export type csv_import_status_bool_exp = {
  _and?: InputMaybe<Array<csv_import_status_bool_exp>>;
  _not?: InputMaybe<csv_import_status_bool_exp>;
  _or?: InputMaybe<Array<csv_import_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "csv_import_status" */
export enum csv_import_status_constraint {
  /** unique or primary key constraint on columns "value" */
  csv_import_status_pkey = 'csv_import_status_pkey'
}

export enum csv_import_status_enum {
  aborted = 'aborted',
  complete = 'complete',
  created = 'created',
  failed = 'failed',
  running = 'running'
}

/** Boolean expression to compare columns of type "csv_import_status_enum". All fields are combined with logical 'AND'. */
export type csv_import_status_enum_comparison_exp = {
  _eq?: InputMaybe<csv_import_status_enum>;
  _in?: InputMaybe<Array<csv_import_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<csv_import_status_enum>;
  _nin?: InputMaybe<Array<csv_import_status_enum>>;
};

/** input type for inserting data into table "csv_import_status" */
export type csv_import_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type csv_import_status_max_fields = {
  __typename: 'csv_import_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type csv_import_status_min_fields = {
  __typename: 'csv_import_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "csv_import_status" */
export type csv_import_status_mutation_response = {
  __typename: 'csv_import_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<csv_import_status>;
};

/** on_conflict condition type for table "csv_import_status" */
export type csv_import_status_on_conflict = {
  constraint: csv_import_status_constraint;
  update_columns?: Array<csv_import_status_update_column>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};

/** Ordering options when selecting data from "csv_import_status". */
export type csv_import_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: csv_import_status */
export type csv_import_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "csv_import_status" */
export enum csv_import_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "csv_import_status" */
export type csv_import_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "csv_import_status" */
export type csv_import_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: csv_import_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type csv_import_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "csv_import_status" */
export enum csv_import_status_update_column {
  /** column name */
  value = 'value'
}

export type csv_import_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<csv_import_status_set_input>;
  /** filter the rows which have to be updated */
  where: csv_import_status_bool_exp;
};

/** aggregate stddev on columns */
export type csv_import_stddev_fields = {
  __typename: 'csv_import_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type csv_import_stddev_pop_fields = {
  __typename: 'csv_import_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type csv_import_stddev_samp_fields = {
  __typename: 'csv_import_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "csv_import" */
export type csv_import_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: csv_import_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type csv_import_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  delta_file_id?: InputMaybe<Scalars['Int']['input']>;
  forced?: InputMaybe<Scalars['Boolean']['input']>;
  retry_count?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<csv_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type csv_import_sum_fields = {
  __typename: 'csv_import_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  delta_file_id?: Maybe<Scalars['Int']['output']>;
  retry_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "csv_import" */
export enum csv_import_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  cursor = 'cursor',
  /** column name */
  delta_file_id = 'delta_file_id',
  /** column name */
  forced = 'forced',
  /** column name */
  retry_count = 'retry_count',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

export type csv_import_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<csv_import_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<csv_import_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<csv_import_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<csv_import_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<csv_import_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<csv_import_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<csv_import_set_input>;
  /** filter the rows which have to be updated */
  where: csv_import_bool_exp;
};

/** aggregate var_pop on columns */
export type csv_import_var_pop_fields = {
  __typename: 'csv_import_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type csv_import_var_samp_fields = {
  __typename: 'csv_import_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type csv_import_variance_fields = {
  __typename: 'csv_import_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  delta_file_id?: Maybe<Scalars['Float']['output']>;
  retry_count?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum cursor_ordering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

/** columns and relationships of "customers_by_month" */
export type customers_by_month = {
  __typename: 'customers_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "customers_by_month" */
export type customers_by_month_aggregate = {
  __typename: 'customers_by_month_aggregate';
  aggregate?: Maybe<customers_by_month_aggregate_fields>;
  nodes: Array<customers_by_month>;
};

/** aggregate fields of "customers_by_month" */
export type customers_by_month_aggregate_fields = {
  __typename: 'customers_by_month_aggregate_fields';
  avg?: Maybe<customers_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<customers_by_month_max_fields>;
  min?: Maybe<customers_by_month_min_fields>;
  stddev?: Maybe<customers_by_month_stddev_fields>;
  stddev_pop?: Maybe<customers_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<customers_by_month_stddev_samp_fields>;
  sum?: Maybe<customers_by_month_sum_fields>;
  var_pop?: Maybe<customers_by_month_var_pop_fields>;
  var_samp?: Maybe<customers_by_month_var_samp_fields>;
  variance?: Maybe<customers_by_month_variance_fields>;
};


/** aggregate fields of "customers_by_month" */
export type customers_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<customers_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type customers_by_month_avg_fields = {
  __typename: 'customers_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "customers_by_month". All fields are combined with a logical 'AND'. */
export type customers_by_month_bool_exp = {
  _and?: InputMaybe<Array<customers_by_month_bool_exp>>;
  _not?: InputMaybe<customers_by_month_bool_exp>;
  _or?: InputMaybe<Array<customers_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<bigint_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type customers_by_month_max_fields = {
  __typename: 'customers_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type customers_by_month_min_fields = {
  __typename: 'customers_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "customers_by_month". */
export type customers_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
};

/** select columns of table "customers_by_month" */
export enum customers_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  month = 'month'
}

/** aggregate stddev on columns */
export type customers_by_month_stddev_fields = {
  __typename: 'customers_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type customers_by_month_stddev_pop_fields = {
  __typename: 'customers_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type customers_by_month_stddev_samp_fields = {
  __typename: 'customers_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "customers_by_month" */
export type customers_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: customers_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type customers_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['bigint']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type customers_by_month_sum_fields = {
  __typename: 'customers_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type customers_by_month_var_pop_fields = {
  __typename: 'customers_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type customers_by_month_var_samp_fields = {
  __typename: 'customers_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type customers_by_month_variance_fields = {
  __typename: 'customers_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "data_connector" */
export type data_connector = {
  __typename: 'data_connector';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  disabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  url: Scalars['String']['output'];
};

/** aggregated selection of "data_connector" */
export type data_connector_aggregate = {
  __typename: 'data_connector_aggregate';
  aggregate?: Maybe<data_connector_aggregate_fields>;
  nodes: Array<data_connector>;
};

export type data_connector_aggregate_bool_exp = {
  bool_and?: InputMaybe<data_connector_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<data_connector_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<data_connector_aggregate_bool_exp_count>;
};

export type data_connector_aggregate_bool_exp_bool_and = {
  arguments: data_connector_select_column_data_connector_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<data_connector_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type data_connector_aggregate_bool_exp_bool_or = {
  arguments: data_connector_select_column_data_connector_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<data_connector_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type data_connector_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<data_connector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<data_connector_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "data_connector" */
export type data_connector_aggregate_fields = {
  __typename: 'data_connector_aggregate_fields';
  avg?: Maybe<data_connector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<data_connector_max_fields>;
  min?: Maybe<data_connector_min_fields>;
  stddev?: Maybe<data_connector_stddev_fields>;
  stddev_pop?: Maybe<data_connector_stddev_pop_fields>;
  stddev_samp?: Maybe<data_connector_stddev_samp_fields>;
  sum?: Maybe<data_connector_sum_fields>;
  var_pop?: Maybe<data_connector_var_pop_fields>;
  var_samp?: Maybe<data_connector_var_samp_fields>;
  variance?: Maybe<data_connector_variance_fields>;
};


/** aggregate fields of "data_connector" */
export type data_connector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<data_connector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "data_connector" */
export type data_connector_aggregate_order_by = {
  avg?: InputMaybe<data_connector_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<data_connector_max_order_by>;
  min?: InputMaybe<data_connector_min_order_by>;
  stddev?: InputMaybe<data_connector_stddev_order_by>;
  stddev_pop?: InputMaybe<data_connector_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<data_connector_stddev_samp_order_by>;
  sum?: InputMaybe<data_connector_sum_order_by>;
  var_pop?: InputMaybe<data_connector_var_pop_order_by>;
  var_samp?: InputMaybe<data_connector_var_samp_order_by>;
  variance?: InputMaybe<data_connector_variance_order_by>;
};

/** input type for inserting array relation for remote table "data_connector" */
export type data_connector_arr_rel_insert_input = {
  data: Array<data_connector_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<data_connector_on_conflict>;
};

/** aggregate avg on columns */
export type data_connector_avg_fields = {
  __typename: 'data_connector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "data_connector" */
export type data_connector_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "data_connector". All fields are combined with a logical 'AND'. */
export type data_connector_bool_exp = {
  _and?: InputMaybe<Array<data_connector_bool_exp>>;
  _not?: InputMaybe<data_connector_bool_exp>;
  _or?: InputMaybe<Array<data_connector_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  disabled?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "data_connector" */
export enum data_connector_constraint {
  /** unique or primary key constraint on columns "id" */
  data_connector_pkey = 'data_connector_pkey',
  /** unique or primary key constraint on columns "token" */
  data_connector_token_key = 'data_connector_token_key'
}

/** input type for incrementing numeric columns in table "data_connector" */
export type data_connector_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "data_connector" */
export type data_connector_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type data_connector_max_fields = {
  __typename: 'data_connector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "data_connector" */
export type data_connector_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type data_connector_min_fields = {
  __typename: 'data_connector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "data_connector" */
export type data_connector_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** response of any mutation on the table "data_connector" */
export type data_connector_mutation_response = {
  __typename: 'data_connector_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<data_connector>;
};

/** input type for inserting object relation for remote table "data_connector" */
export type data_connector_obj_rel_insert_input = {
  data: data_connector_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<data_connector_on_conflict>;
};

/** on_conflict condition type for table "data_connector" */
export type data_connector_on_conflict = {
  constraint: data_connector_constraint;
  update_columns?: Array<data_connector_update_column>;
  where?: InputMaybe<data_connector_bool_exp>;
};

/** Ordering options when selecting data from "data_connector". */
export type data_connector_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  disabled?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: data_connector */
export type data_connector_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "data_connector_request_log" */
export type data_connector_request_log = {
  __typename: 'data_connector_request_log';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  data_connector: data_connector;
  data_connector_id: Scalars['Int']['output'];
  har: Scalars['jsonb']['output'];
  id: Scalars['bigint']['output'];
  method: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** columns and relationships of "data_connector_request_log" */
export type data_connector_request_logharArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "data_connector_request_log" */
export type data_connector_request_log_aggregate = {
  __typename: 'data_connector_request_log_aggregate';
  aggregate?: Maybe<data_connector_request_log_aggregate_fields>;
  nodes: Array<data_connector_request_log>;
};

/** aggregate fields of "data_connector_request_log" */
export type data_connector_request_log_aggregate_fields = {
  __typename: 'data_connector_request_log_aggregate_fields';
  avg?: Maybe<data_connector_request_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<data_connector_request_log_max_fields>;
  min?: Maybe<data_connector_request_log_min_fields>;
  stddev?: Maybe<data_connector_request_log_stddev_fields>;
  stddev_pop?: Maybe<data_connector_request_log_stddev_pop_fields>;
  stddev_samp?: Maybe<data_connector_request_log_stddev_samp_fields>;
  sum?: Maybe<data_connector_request_log_sum_fields>;
  var_pop?: Maybe<data_connector_request_log_var_pop_fields>;
  var_samp?: Maybe<data_connector_request_log_var_samp_fields>;
  variance?: Maybe<data_connector_request_log_variance_fields>;
};


/** aggregate fields of "data_connector_request_log" */
export type data_connector_request_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<data_connector_request_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type data_connector_request_log_append_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type data_connector_request_log_avg_fields = {
  __typename: 'data_connector_request_log_avg_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "data_connector_request_log". All fields are combined with a logical 'AND'. */
export type data_connector_request_log_bool_exp = {
  _and?: InputMaybe<Array<data_connector_request_log_bool_exp>>;
  _not?: InputMaybe<data_connector_request_log_bool_exp>;
  _or?: InputMaybe<Array<data_connector_request_log_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data_connector?: InputMaybe<data_connector_bool_exp>;
  data_connector_id?: InputMaybe<Int_comparison_exp>;
  har?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<bigint_comparison_exp>;
  method?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "data_connector_request_log" */
export enum data_connector_request_log_constraint {
  /** unique or primary key constraint on columns "id" */
  data_connector_request_log_pkey = 'data_connector_request_log_pkey',
  /** unique or primary key constraint on columns "token" */
  data_connector_request_log_token_key = 'data_connector_request_log_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type data_connector_request_log_delete_at_path_input = {
  har?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type data_connector_request_log_delete_elem_input = {
  har?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type data_connector_request_log_delete_key_input = {
  har?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "data_connector_request_log" */
export type data_connector_request_log_inc_input = {
  data_connector_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "data_connector_request_log" */
export type data_connector_request_log_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_connector?: InputMaybe<data_connector_obj_rel_insert_input>;
  data_connector_id?: InputMaybe<Scalars['Int']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type data_connector_request_log_max_fields = {
  __typename: 'data_connector_request_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type data_connector_request_log_min_fields = {
  __typename: 'data_connector_request_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "data_connector_request_log" */
export type data_connector_request_log_mutation_response = {
  __typename: 'data_connector_request_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<data_connector_request_log>;
};

/** on_conflict condition type for table "data_connector_request_log" */
export type data_connector_request_log_on_conflict = {
  constraint: data_connector_request_log_constraint;
  update_columns?: Array<data_connector_request_log_update_column>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};

/** Ordering options when selecting data from "data_connector_request_log". */
export type data_connector_request_log_order_by = {
  created_at?: InputMaybe<order_by>;
  data_connector?: InputMaybe<data_connector_order_by>;
  data_connector_id?: InputMaybe<order_by>;
  har?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  method?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: data_connector_request_log */
export type data_connector_request_log_pk_columns_input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type data_connector_request_log_prepend_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "data_connector_request_log" */
export enum data_connector_request_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data_connector_id = 'data_connector_id',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

/** input type for updating data in table "data_connector_request_log" */
export type data_connector_request_log_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_connector_id?: InputMaybe<Scalars['Int']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type data_connector_request_log_stddev_fields = {
  __typename: 'data_connector_request_log_stddev_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type data_connector_request_log_stddev_pop_fields = {
  __typename: 'data_connector_request_log_stddev_pop_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type data_connector_request_log_stddev_samp_fields = {
  __typename: 'data_connector_request_log_stddev_samp_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "data_connector_request_log" */
export type data_connector_request_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: data_connector_request_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type data_connector_request_log_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data_connector_id?: InputMaybe<Scalars['Int']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type data_connector_request_log_sum_fields = {
  __typename: 'data_connector_request_log_sum_fields';
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "data_connector_request_log_tsvector" */
export type data_connector_request_log_tsvector = {
  __typename: 'data_connector_request_log_tsvector';
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['bigint']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "data_connector_request_log_tsvector" */
export type data_connector_request_log_tsvector_aggregate = {
  __typename: 'data_connector_request_log_tsvector_aggregate';
  aggregate?: Maybe<data_connector_request_log_tsvector_aggregate_fields>;
  nodes: Array<data_connector_request_log_tsvector>;
};

/** aggregate fields of "data_connector_request_log_tsvector" */
export type data_connector_request_log_tsvector_aggregate_fields = {
  __typename: 'data_connector_request_log_tsvector_aggregate_fields';
  avg?: Maybe<data_connector_request_log_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<data_connector_request_log_tsvector_max_fields>;
  min?: Maybe<data_connector_request_log_tsvector_min_fields>;
  stddev?: Maybe<data_connector_request_log_tsvector_stddev_fields>;
  stddev_pop?: Maybe<data_connector_request_log_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<data_connector_request_log_tsvector_stddev_samp_fields>;
  sum?: Maybe<data_connector_request_log_tsvector_sum_fields>;
  var_pop?: Maybe<data_connector_request_log_tsvector_var_pop_fields>;
  var_samp?: Maybe<data_connector_request_log_tsvector_var_samp_fields>;
  variance?: Maybe<data_connector_request_log_tsvector_variance_fields>;
};


/** aggregate fields of "data_connector_request_log_tsvector" */
export type data_connector_request_log_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<data_connector_request_log_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type data_connector_request_log_tsvector_avg_fields = {
  __typename: 'data_connector_request_log_tsvector_avg_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "data_connector_request_log_tsvector". All fields are combined with a logical 'AND'. */
export type data_connector_request_log_tsvector_bool_exp = {
  _and?: InputMaybe<Array<data_connector_request_log_tsvector_bool_exp>>;
  _not?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
  _or?: InputMaybe<Array<data_connector_request_log_tsvector_bool_exp>>;
  data_connector_id?: InputMaybe<Int_comparison_exp>;
  data_connector_request_log_id?: InputMaybe<bigint_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type data_connector_request_log_tsvector_max_fields = {
  __typename: 'data_connector_request_log_tsvector_max_fields';
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type data_connector_request_log_tsvector_min_fields = {
  __typename: 'data_connector_request_log_tsvector_min_fields';
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "data_connector_request_log_tsvector". */
export type data_connector_request_log_tsvector_order_by = {
  data_connector_id?: InputMaybe<order_by>;
  data_connector_request_log_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "data_connector_request_log_tsvector" */
export enum data_connector_request_log_tsvector_select_column {
  /** column name */
  data_connector_id = 'data_connector_id',
  /** column name */
  data_connector_request_log_id = 'data_connector_request_log_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type data_connector_request_log_tsvector_stddev_fields = {
  __typename: 'data_connector_request_log_tsvector_stddev_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type data_connector_request_log_tsvector_stddev_pop_fields = {
  __typename: 'data_connector_request_log_tsvector_stddev_pop_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type data_connector_request_log_tsvector_stddev_samp_fields = {
  __typename: 'data_connector_request_log_tsvector_stddev_samp_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "data_connector_request_log_tsvector" */
export type data_connector_request_log_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: data_connector_request_log_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type data_connector_request_log_tsvector_stream_cursor_value_input = {
  data_connector_id?: InputMaybe<Scalars['Int']['input']>;
  data_connector_request_log_id?: InputMaybe<Scalars['bigint']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type data_connector_request_log_tsvector_sum_fields = {
  __typename: 'data_connector_request_log_tsvector_sum_fields';
  data_connector_id?: Maybe<Scalars['Int']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type data_connector_request_log_tsvector_var_pop_fields = {
  __typename: 'data_connector_request_log_tsvector_var_pop_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type data_connector_request_log_tsvector_var_samp_fields = {
  __typename: 'data_connector_request_log_tsvector_var_samp_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type data_connector_request_log_tsvector_variance_fields = {
  __typename: 'data_connector_request_log_tsvector_variance_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  data_connector_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "data_connector_request_log" */
export enum data_connector_request_log_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data_connector_id = 'data_connector_id',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

export type data_connector_request_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<data_connector_request_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<data_connector_request_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<data_connector_request_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<data_connector_request_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<data_connector_request_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<data_connector_request_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<data_connector_request_log_set_input>;
  /** filter the rows which have to be updated */
  where: data_connector_request_log_bool_exp;
};

/** aggregate var_pop on columns */
export type data_connector_request_log_var_pop_fields = {
  __typename: 'data_connector_request_log_var_pop_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type data_connector_request_log_var_samp_fields = {
  __typename: 'data_connector_request_log_var_samp_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type data_connector_request_log_variance_fields = {
  __typename: 'data_connector_request_log_variance_fields';
  data_connector_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "data_connector" */
export enum data_connector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  disabled = 'disabled',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

/** select "data_connector_aggregate_bool_exp_bool_and_arguments_columns" columns of table "data_connector" */
export enum data_connector_select_column_data_connector_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  disabled = 'disabled'
}

/** select "data_connector_aggregate_bool_exp_bool_or_arguments_columns" columns of table "data_connector" */
export enum data_connector_select_column_data_connector_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  disabled = 'disabled'
}

/** input type for updating data in table "data_connector" */
export type data_connector_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type data_connector_stddev_fields = {
  __typename: 'data_connector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "data_connector" */
export type data_connector_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type data_connector_stddev_pop_fields = {
  __typename: 'data_connector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "data_connector" */
export type data_connector_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type data_connector_stddev_samp_fields = {
  __typename: 'data_connector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "data_connector" */
export type data_connector_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "data_connector" */
export type data_connector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: data_connector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type data_connector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type data_connector_sum_fields = {
  __typename: 'data_connector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "data_connector" */
export type data_connector_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "data_connector" */
export enum data_connector_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  disabled = 'disabled',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

export type data_connector_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<data_connector_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<data_connector_set_input>;
  /** filter the rows which have to be updated */
  where: data_connector_bool_exp;
};

/** aggregate var_pop on columns */
export type data_connector_var_pop_fields = {
  __typename: 'data_connector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "data_connector" */
export type data_connector_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type data_connector_var_samp_fields = {
  __typename: 'data_connector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "data_connector" */
export type data_connector_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type data_connector_variance_fields = {
  __typename: 'data_connector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "data_connector" */
export type data_connector_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type date_comparison_exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "deflection" */
export type deflection = {
  __typename: 'deflection';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  content_translation?: Maybe<translation>;
  content_translation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  deflection_flow_actions: Array<deflection_flow_action>;
  /** An aggregate relationship */
  deflection_flow_actions_aggregate: deflection_flow_action_aggregate;
  /** An array relationship */
  deflection_snapshot_item_positions: Array<deflection_snapshot_item_position>;
  /** An aggregate relationship */
  deflection_snapshot_item_positions_aggregate: deflection_snapshot_item_position_aggregate;
  /** An array relationship */
  deflection_tags: Array<deflection_tag>;
  /** An aggregate relationship */
  deflection_tags_aggregate: deflection_tag_aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_action_ids: Scalars['jsonb']['output'];
  /** An object relationship */
  heading_translation: translation;
  heading_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  minimum_items?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  offer_rule_rule_deflections: Array<offer_rule_rule_deflection>;
  /** An aggregate relationship */
  offer_rule_rule_deflections_aggregate: offer_rule_rule_deflection_aggregate;
  snapshot_item_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  subscriber_flow_deflections: Array<subscriber_flow_deflection>;
  /** An aggregate relationship */
  subscriber_flow_deflections_aggregate: subscriber_flow_deflection_aggregate;
  tag_ids: Scalars['jsonb']['output'];
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_flow_actionsArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_flow_actions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_snapshot_item_positionsArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_snapshot_item_positions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_tagsArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiondeflection_tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectionflow_action_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "deflection" */
export type deflectionoffer_rule_rule_deflectionsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectionoffer_rule_rule_deflections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectionsnapshot_item_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "deflection" */
export type deflectionsubscriber_flow_deflectionsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectionsubscriber_flow_deflections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "deflection" */
export type deflectiontag_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "deflection" */
export type deflection_aggregate = {
  __typename: 'deflection_aggregate';
  aggregate?: Maybe<deflection_aggregate_fields>;
  nodes: Array<deflection>;
};

/** aggregate fields of "deflection" */
export type deflection_aggregate_fields = {
  __typename: 'deflection_aggregate_fields';
  avg?: Maybe<deflection_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_max_fields>;
  min?: Maybe<deflection_min_fields>;
  stddev?: Maybe<deflection_stddev_fields>;
  stddev_pop?: Maybe<deflection_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_stddev_samp_fields>;
  sum?: Maybe<deflection_sum_fields>;
  var_pop?: Maybe<deflection_var_pop_fields>;
  var_samp?: Maybe<deflection_var_samp_fields>;
  variance?: Maybe<deflection_variance_fields>;
};


/** aggregate fields of "deflection" */
export type deflection_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type deflection_append_input = {
  flow_action_ids?: InputMaybe<Scalars['jsonb']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type deflection_avg_fields = {
  __typename: 'deflection_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "deflection". All fields are combined with a logical 'AND'. */
export type deflection_bool_exp = {
  _and?: InputMaybe<Array<deflection_bool_exp>>;
  _not?: InputMaybe<deflection_bool_exp>;
  _or?: InputMaybe<Array<deflection_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  archived_at?: InputMaybe<timestamptz_comparison_exp>;
  content_translation?: InputMaybe<translation_bool_exp>;
  content_translation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deflection_flow_actions?: InputMaybe<deflection_flow_action_bool_exp>;
  deflection_flow_actions_aggregate?: InputMaybe<deflection_flow_action_aggregate_bool_exp>;
  deflection_snapshot_item_positions?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
  deflection_snapshot_item_positions_aggregate?: InputMaybe<deflection_snapshot_item_position_aggregate_bool_exp>;
  deflection_tags?: InputMaybe<deflection_tag_bool_exp>;
  deflection_tags_aggregate?: InputMaybe<deflection_tag_aggregate_bool_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_action_ids?: InputMaybe<jsonb_comparison_exp>;
  heading_translation?: InputMaybe<translation_bool_exp>;
  heading_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  minimum_items?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule_deflections?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
  offer_rule_rule_deflections_aggregate?: InputMaybe<offer_rule_rule_deflection_aggregate_bool_exp>;
  snapshot_item_ids?: InputMaybe<jsonb_comparison_exp>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_bool_exp>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_bool_exp>;
  tag_ids?: InputMaybe<jsonb_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "deflection" */
export enum deflection_constraint {
  /** unique or primary key constraint on columns "id" */
  deflection_pkey = 'deflection_pkey',
  /** unique or primary key constraint on columns "token" */
  deflection_token_key = 'deflection_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type deflection_delete_at_path_input = {
  flow_action_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  snapshot_item_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  tag_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type deflection_delete_elem_input = {
  flow_action_ids?: InputMaybe<Scalars['Int']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['Int']['input']>;
  tag_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type deflection_delete_key_input = {
  flow_action_ids?: InputMaybe<Scalars['String']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['String']['input']>;
  tag_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "deflection_flow_action" */
export type deflection_flow_action = {
  __typename: 'deflection_flow_action';
  /** An object relationship */
  deflection?: Maybe<deflection>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_action?: Maybe<flow_action>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "deflection_flow_action" */
export type deflection_flow_action_aggregate = {
  __typename: 'deflection_flow_action_aggregate';
  aggregate?: Maybe<deflection_flow_action_aggregate_fields>;
  nodes: Array<deflection_flow_action>;
};

export type deflection_flow_action_aggregate_bool_exp = {
  count?: InputMaybe<deflection_flow_action_aggregate_bool_exp_count>;
};

export type deflection_flow_action_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<deflection_flow_action_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<deflection_flow_action_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "deflection_flow_action" */
export type deflection_flow_action_aggregate_fields = {
  __typename: 'deflection_flow_action_aggregate_fields';
  avg?: Maybe<deflection_flow_action_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_flow_action_max_fields>;
  min?: Maybe<deflection_flow_action_min_fields>;
  stddev?: Maybe<deflection_flow_action_stddev_fields>;
  stddev_pop?: Maybe<deflection_flow_action_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_flow_action_stddev_samp_fields>;
  sum?: Maybe<deflection_flow_action_sum_fields>;
  var_pop?: Maybe<deflection_flow_action_var_pop_fields>;
  var_samp?: Maybe<deflection_flow_action_var_samp_fields>;
  variance?: Maybe<deflection_flow_action_variance_fields>;
};


/** aggregate fields of "deflection_flow_action" */
export type deflection_flow_action_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_flow_action_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "deflection_flow_action" */
export type deflection_flow_action_aggregate_order_by = {
  avg?: InputMaybe<deflection_flow_action_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<deflection_flow_action_max_order_by>;
  min?: InputMaybe<deflection_flow_action_min_order_by>;
  stddev?: InputMaybe<deflection_flow_action_stddev_order_by>;
  stddev_pop?: InputMaybe<deflection_flow_action_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<deflection_flow_action_stddev_samp_order_by>;
  sum?: InputMaybe<deflection_flow_action_sum_order_by>;
  var_pop?: InputMaybe<deflection_flow_action_var_pop_order_by>;
  var_samp?: InputMaybe<deflection_flow_action_var_samp_order_by>;
  variance?: InputMaybe<deflection_flow_action_variance_order_by>;
};

/** input type for inserting array relation for remote table "deflection_flow_action" */
export type deflection_flow_action_arr_rel_insert_input = {
  data: Array<deflection_flow_action_insert_input>;
};

/** aggregate avg on columns */
export type deflection_flow_action_avg_fields = {
  __typename: 'deflection_flow_action_avg_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "deflection_flow_action" */
export type deflection_flow_action_avg_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "deflection_flow_action". All fields are combined with a logical 'AND'. */
export type deflection_flow_action_bool_exp = {
  _and?: InputMaybe<Array<deflection_flow_action_bool_exp>>;
  _not?: InputMaybe<deflection_flow_action_bool_exp>;
  _or?: InputMaybe<Array<deflection_flow_action_bool_exp>>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  flow_action?: InputMaybe<flow_action_bool_exp>;
  flow_action_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "deflection_flow_action" */
export type deflection_flow_action_insert_input = {
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_action?: InputMaybe<flow_action_obj_rel_insert_input>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type deflection_flow_action_max_fields = {
  __typename: 'deflection_flow_action_max_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "deflection_flow_action" */
export type deflection_flow_action_max_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type deflection_flow_action_min_fields = {
  __typename: 'deflection_flow_action_min_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "deflection_flow_action" */
export type deflection_flow_action_min_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "deflection_flow_action". */
export type deflection_flow_action_order_by = {
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_action?: InputMaybe<flow_action_order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "deflection_flow_action" */
export enum deflection_flow_action_select_column {
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  flow_action_id = 'flow_action_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type deflection_flow_action_stddev_fields = {
  __typename: 'deflection_flow_action_stddev_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "deflection_flow_action" */
export type deflection_flow_action_stddev_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type deflection_flow_action_stddev_pop_fields = {
  __typename: 'deflection_flow_action_stddev_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "deflection_flow_action" */
export type deflection_flow_action_stddev_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type deflection_flow_action_stddev_samp_fields = {
  __typename: 'deflection_flow_action_stddev_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "deflection_flow_action" */
export type deflection_flow_action_stddev_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "deflection_flow_action" */
export type deflection_flow_action_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_flow_action_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_flow_action_stream_cursor_value_input = {
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type deflection_flow_action_sum_fields = {
  __typename: 'deflection_flow_action_sum_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "deflection_flow_action" */
export type deflection_flow_action_sum_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type deflection_flow_action_var_pop_fields = {
  __typename: 'deflection_flow_action_var_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "deflection_flow_action" */
export type deflection_flow_action_var_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type deflection_flow_action_var_samp_fields = {
  __typename: 'deflection_flow_action_var_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "deflection_flow_action" */
export type deflection_flow_action_var_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type deflection_flow_action_variance_fields = {
  __typename: 'deflection_flow_action_variance_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "deflection_flow_action" */
export type deflection_flow_action_variance_order_by = {
  deflection_id?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "deflection" */
export type deflection_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  heading_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_items?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "deflection" */
export type deflection_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_flow_actions?: InputMaybe<deflection_flow_action_arr_rel_insert_input>;
  deflection_snapshot_item_positions?: InputMaybe<deflection_snapshot_item_position_arr_rel_insert_input>;
  deflection_tags?: InputMaybe<deflection_tag_arr_rel_insert_input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_action_ids?: InputMaybe<Scalars['jsonb']['input']>;
  heading_translation?: InputMaybe<translation_obj_rel_insert_input>;
  heading_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_items?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_deflections?: InputMaybe<offer_rule_rule_deflection_arr_rel_insert_input>;
  snapshot_item_ids?: InputMaybe<Scalars['jsonb']['input']>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_arr_rel_insert_input>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type deflection_max_fields = {
  __typename: 'deflection_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  heading_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_items?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type deflection_min_fields = {
  __typename: 'deflection_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  heading_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_items?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "deflection" */
export type deflection_mutation_response = {
  __typename: 'deflection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<deflection>;
};

/** input type for inserting object relation for remote table "deflection" */
export type deflection_obj_rel_insert_input = {
  data: deflection_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<deflection_on_conflict>;
};

/** on_conflict condition type for table "deflection" */
export type deflection_on_conflict = {
  constraint: deflection_constraint;
  update_columns?: Array<deflection_update_column>;
  where?: InputMaybe<deflection_bool_exp>;
};

/** Ordering options when selecting data from "deflection". */
export type deflection_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  archived_at?: InputMaybe<order_by>;
  content_translation?: InputMaybe<translation_order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflection_flow_actions_aggregate?: InputMaybe<deflection_flow_action_aggregate_order_by>;
  deflection_snapshot_item_positions_aggregate?: InputMaybe<deflection_snapshot_item_position_aggregate_order_by>;
  deflection_tags_aggregate?: InputMaybe<deflection_tag_aggregate_order_by>;
  deleted_at?: InputMaybe<order_by>;
  flow_action_ids?: InputMaybe<order_by>;
  heading_translation?: InputMaybe<translation_order_by>;
  heading_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  minimum_items?: InputMaybe<order_by>;
  offer_rule_rule_deflections_aggregate?: InputMaybe<offer_rule_rule_deflection_aggregate_order_by>;
  snapshot_item_ids?: InputMaybe<order_by>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_order_by>;
  tag_ids?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: deflection */
export type deflection_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type deflection_prepend_input = {
  flow_action_ids?: InputMaybe<Scalars['jsonb']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "deflection" */
export enum deflection_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  archived_at = 'archived_at',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  flow_action_ids = 'flow_action_ids',
  /** column name */
  heading_translation_id = 'heading_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  minimum_items = 'minimum_items',
  /** column name */
  snapshot_item_ids = 'snapshot_item_ids',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "deflection" */
export type deflection_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_action_ids?: InputMaybe<Scalars['jsonb']['input']>;
  heading_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_items?: InputMaybe<Scalars['Int']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "deflection_snapshot_item" */
export type deflection_snapshot_item = {
  __typename: 'deflection_snapshot_item';
  created_at: Scalars['timestamptz']['output'];
  date_format?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  deflection?: Maybe<deflection>;
  deflection_id: Scalars['Int']['output'];
  icon_name?: Maybe<Scalars['String']['output']>;
  icon_prefix?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  property?: Maybe<property>;
  property_condition_value?: Maybe<Scalars['jsonb']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  text_translation: translation;
  text_translation_id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "deflection_snapshot_item" */
export type deflection_snapshot_itemproperty_condition_valueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "deflection_snapshot_item" */
export type deflection_snapshot_item_aggregate = {
  __typename: 'deflection_snapshot_item_aggregate';
  aggregate?: Maybe<deflection_snapshot_item_aggregate_fields>;
  nodes: Array<deflection_snapshot_item>;
};

/** aggregate fields of "deflection_snapshot_item" */
export type deflection_snapshot_item_aggregate_fields = {
  __typename: 'deflection_snapshot_item_aggregate_fields';
  avg?: Maybe<deflection_snapshot_item_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_snapshot_item_max_fields>;
  min?: Maybe<deflection_snapshot_item_min_fields>;
  stddev?: Maybe<deflection_snapshot_item_stddev_fields>;
  stddev_pop?: Maybe<deflection_snapshot_item_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_snapshot_item_stddev_samp_fields>;
  sum?: Maybe<deflection_snapshot_item_sum_fields>;
  var_pop?: Maybe<deflection_snapshot_item_var_pop_fields>;
  var_samp?: Maybe<deflection_snapshot_item_var_samp_fields>;
  variance?: Maybe<deflection_snapshot_item_variance_fields>;
};


/** aggregate fields of "deflection_snapshot_item" */
export type deflection_snapshot_item_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_snapshot_item_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type deflection_snapshot_item_append_input = {
  property_condition_value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type deflection_snapshot_item_avg_fields = {
  __typename: 'deflection_snapshot_item_avg_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "deflection_snapshot_item". All fields are combined with a logical 'AND'. */
export type deflection_snapshot_item_bool_exp = {
  _and?: InputMaybe<Array<deflection_snapshot_item_bool_exp>>;
  _not?: InputMaybe<deflection_snapshot_item_bool_exp>;
  _or?: InputMaybe<Array<deflection_snapshot_item_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  date_format?: InputMaybe<String_comparison_exp>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  icon_name?: InputMaybe<String_comparison_exp>;
  icon_prefix?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  property?: InputMaybe<property_bool_exp>;
  property_condition_value?: InputMaybe<jsonb_comparison_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  text_translation?: InputMaybe<translation_bool_exp>;
  text_translation_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "deflection_snapshot_item" */
export enum deflection_snapshot_item_constraint {
  /** unique or primary key constraint on columns "id" */
  deflection_snapshot_item_pkey = 'deflection_snapshot_item_pkey',
  /** unique or primary key constraint on columns "token" */
  deflection_snapshot_item_token_key = 'deflection_snapshot_item_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type deflection_snapshot_item_delete_at_path_input = {
  property_condition_value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type deflection_snapshot_item_delete_elem_input = {
  property_condition_value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type deflection_snapshot_item_delete_key_input = {
  property_condition_value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "deflection_snapshot_item" */
export type deflection_snapshot_item_inc_input = {
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "deflection_snapshot_item" */
export type deflection_snapshot_item_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_format?: InputMaybe<Scalars['String']['input']>;
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  icon_name?: InputMaybe<Scalars['String']['input']>;
  icon_prefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_condition_value?: InputMaybe<Scalars['jsonb']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type deflection_snapshot_item_max_fields = {
  __typename: 'deflection_snapshot_item_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_format?: Maybe<Scalars['String']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  icon_name?: Maybe<Scalars['String']['output']>;
  icon_prefix?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type deflection_snapshot_item_min_fields = {
  __typename: 'deflection_snapshot_item_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_format?: Maybe<Scalars['String']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  icon_name?: Maybe<Scalars['String']['output']>;
  icon_prefix?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "deflection_snapshot_item" */
export type deflection_snapshot_item_mutation_response = {
  __typename: 'deflection_snapshot_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<deflection_snapshot_item>;
};

/** input type for inserting object relation for remote table "deflection_snapshot_item" */
export type deflection_snapshot_item_obj_rel_insert_input = {
  data: deflection_snapshot_item_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<deflection_snapshot_item_on_conflict>;
};

/** on_conflict condition type for table "deflection_snapshot_item" */
export type deflection_snapshot_item_on_conflict = {
  constraint: deflection_snapshot_item_constraint;
  update_columns?: Array<deflection_snapshot_item_update_column>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};

/** Ordering options when selecting data from "deflection_snapshot_item". */
export type deflection_snapshot_item_order_by = {
  created_at?: InputMaybe<order_by>;
  date_format?: InputMaybe<order_by>;
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  icon_name?: InputMaybe<order_by>;
  icon_prefix?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property?: InputMaybe<property_order_by>;
  property_condition_value?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  text_translation?: InputMaybe<translation_order_by>;
  text_translation_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: deflection_snapshot_item */
export type deflection_snapshot_item_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position = {
  __typename: 'deflection_snapshot_item_position';
  /** An object relationship */
  deflection?: Maybe<deflection>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  deflection_snapshot_item?: Maybe<deflection_snapshot_item>;
  position?: Maybe<Scalars['Int']['output']>;
  snapshot_item_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_aggregate = {
  __typename: 'deflection_snapshot_item_position_aggregate';
  aggregate?: Maybe<deflection_snapshot_item_position_aggregate_fields>;
  nodes: Array<deflection_snapshot_item_position>;
};

export type deflection_snapshot_item_position_aggregate_bool_exp = {
  count?: InputMaybe<deflection_snapshot_item_position_aggregate_bool_exp_count>;
};

export type deflection_snapshot_item_position_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_aggregate_fields = {
  __typename: 'deflection_snapshot_item_position_aggregate_fields';
  avg?: Maybe<deflection_snapshot_item_position_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_snapshot_item_position_max_fields>;
  min?: Maybe<deflection_snapshot_item_position_min_fields>;
  stddev?: Maybe<deflection_snapshot_item_position_stddev_fields>;
  stddev_pop?: Maybe<deflection_snapshot_item_position_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_snapshot_item_position_stddev_samp_fields>;
  sum?: Maybe<deflection_snapshot_item_position_sum_fields>;
  var_pop?: Maybe<deflection_snapshot_item_position_var_pop_fields>;
  var_samp?: Maybe<deflection_snapshot_item_position_var_samp_fields>;
  variance?: Maybe<deflection_snapshot_item_position_variance_fields>;
};


/** aggregate fields of "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_aggregate_order_by = {
  avg?: InputMaybe<deflection_snapshot_item_position_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<deflection_snapshot_item_position_max_order_by>;
  min?: InputMaybe<deflection_snapshot_item_position_min_order_by>;
  stddev?: InputMaybe<deflection_snapshot_item_position_stddev_order_by>;
  stddev_pop?: InputMaybe<deflection_snapshot_item_position_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<deflection_snapshot_item_position_stddev_samp_order_by>;
  sum?: InputMaybe<deflection_snapshot_item_position_sum_order_by>;
  var_pop?: InputMaybe<deflection_snapshot_item_position_var_pop_order_by>;
  var_samp?: InputMaybe<deflection_snapshot_item_position_var_samp_order_by>;
  variance?: InputMaybe<deflection_snapshot_item_position_variance_order_by>;
};

/** input type for inserting array relation for remote table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_arr_rel_insert_input = {
  data: Array<deflection_snapshot_item_position_insert_input>;
};

/** aggregate avg on columns */
export type deflection_snapshot_item_position_avg_fields = {
  __typename: 'deflection_snapshot_item_position_avg_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_avg_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "deflection_snapshot_item_position". All fields are combined with a logical 'AND'. */
export type deflection_snapshot_item_position_bool_exp = {
  _and?: InputMaybe<Array<deflection_snapshot_item_position_bool_exp>>;
  _not?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
  _or?: InputMaybe<Array<deflection_snapshot_item_position_bool_exp>>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  deflection_snapshot_item?: InputMaybe<deflection_snapshot_item_bool_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  snapshot_item_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_insert_input = {
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  deflection_snapshot_item?: InputMaybe<deflection_snapshot_item_obj_rel_insert_input>;
  position?: InputMaybe<Scalars['Int']['input']>;
  snapshot_item_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type deflection_snapshot_item_position_max_fields = {
  __typename: 'deflection_snapshot_item_position_max_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  snapshot_item_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_max_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type deflection_snapshot_item_position_min_fields = {
  __typename: 'deflection_snapshot_item_position_min_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  snapshot_item_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_min_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "deflection_snapshot_item_position". */
export type deflection_snapshot_item_position_order_by = {
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  deflection_snapshot_item?: InputMaybe<deflection_snapshot_item_order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** select columns of table "deflection_snapshot_item_position" */
export enum deflection_snapshot_item_position_select_column {
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  position = 'position',
  /** column name */
  snapshot_item_id = 'snapshot_item_id'
}

/** aggregate stddev on columns */
export type deflection_snapshot_item_position_stddev_fields = {
  __typename: 'deflection_snapshot_item_position_stddev_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_stddev_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type deflection_snapshot_item_position_stddev_pop_fields = {
  __typename: 'deflection_snapshot_item_position_stddev_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_stddev_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type deflection_snapshot_item_position_stddev_samp_fields = {
  __typename: 'deflection_snapshot_item_position_stddev_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_stddev_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_snapshot_item_position_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_snapshot_item_position_stream_cursor_value_input = {
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  snapshot_item_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type deflection_snapshot_item_position_sum_fields = {
  __typename: 'deflection_snapshot_item_position_sum_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  snapshot_item_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_sum_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type deflection_snapshot_item_position_var_pop_fields = {
  __typename: 'deflection_snapshot_item_position_var_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_var_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type deflection_snapshot_item_position_var_samp_fields = {
  __typename: 'deflection_snapshot_item_position_var_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_var_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type deflection_snapshot_item_position_variance_fields = {
  __typename: 'deflection_snapshot_item_position_variance_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  snapshot_item_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "deflection_snapshot_item_position" */
export type deflection_snapshot_item_position_variance_order_by = {
  deflection_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  snapshot_item_id?: InputMaybe<order_by>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type deflection_snapshot_item_prepend_input = {
  property_condition_value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "deflection_snapshot_item" */
export enum deflection_snapshot_item_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  date_format = 'date_format',
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  icon_name = 'icon_name',
  /** column name */
  icon_prefix = 'icon_prefix',
  /** column name */
  id = 'id',
  /** column name */
  property_condition_value = 'property_condition_value',
  /** column name */
  property_id = 'property_id',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "deflection_snapshot_item" */
export type deflection_snapshot_item_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_format?: InputMaybe<Scalars['String']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  icon_name?: InputMaybe<Scalars['String']['input']>;
  icon_prefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  property_condition_value?: InputMaybe<Scalars['jsonb']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type deflection_snapshot_item_stddev_fields = {
  __typename: 'deflection_snapshot_item_stddev_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type deflection_snapshot_item_stddev_pop_fields = {
  __typename: 'deflection_snapshot_item_stddev_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type deflection_snapshot_item_stddev_samp_fields = {
  __typename: 'deflection_snapshot_item_stddev_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "deflection_snapshot_item" */
export type deflection_snapshot_item_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_snapshot_item_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_snapshot_item_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_format?: InputMaybe<Scalars['String']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  icon_name?: InputMaybe<Scalars['String']['input']>;
  icon_prefix?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  property_condition_value?: InputMaybe<Scalars['jsonb']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type deflection_snapshot_item_sum_fields = {
  __typename: 'deflection_snapshot_item_sum_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "deflection_snapshot_item" */
export enum deflection_snapshot_item_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  date_format = 'date_format',
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  icon_name = 'icon_name',
  /** column name */
  icon_prefix = 'icon_prefix',
  /** column name */
  id = 'id',
  /** column name */
  property_condition_value = 'property_condition_value',
  /** column name */
  property_id = 'property_id',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type deflection_snapshot_item_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<deflection_snapshot_item_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<deflection_snapshot_item_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<deflection_snapshot_item_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<deflection_snapshot_item_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<deflection_snapshot_item_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<deflection_snapshot_item_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<deflection_snapshot_item_set_input>;
  /** filter the rows which have to be updated */
  where: deflection_snapshot_item_bool_exp;
};

/** aggregate var_pop on columns */
export type deflection_snapshot_item_var_pop_fields = {
  __typename: 'deflection_snapshot_item_var_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type deflection_snapshot_item_var_samp_fields = {
  __typename: 'deflection_snapshot_item_var_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type deflection_snapshot_item_variance_fields = {
  __typename: 'deflection_snapshot_item_variance_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type deflection_stddev_fields = {
  __typename: 'deflection_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type deflection_stddev_pop_fields = {
  __typename: 'deflection_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type deflection_stddev_samp_fields = {
  __typename: 'deflection_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "deflection" */
export type deflection_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_action_ids?: InputMaybe<Scalars['jsonb']['input']>;
  heading_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_items?: InputMaybe<Scalars['Int']['input']>;
  snapshot_item_ids?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type deflection_sum_fields = {
  __typename: 'deflection_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  heading_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_items?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "deflection_tag" */
export type deflection_tag = {
  __typename: 'deflection_tag';
  /** An object relationship */
  deflection?: Maybe<deflection>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tag?: Maybe<tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "deflection_tag" */
export type deflection_tag_aggregate = {
  __typename: 'deflection_tag_aggregate';
  aggregate?: Maybe<deflection_tag_aggregate_fields>;
  nodes: Array<deflection_tag>;
};

export type deflection_tag_aggregate_bool_exp = {
  count?: InputMaybe<deflection_tag_aggregate_bool_exp_count>;
};

export type deflection_tag_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<deflection_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<deflection_tag_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "deflection_tag" */
export type deflection_tag_aggregate_fields = {
  __typename: 'deflection_tag_aggregate_fields';
  avg?: Maybe<deflection_tag_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_tag_max_fields>;
  min?: Maybe<deflection_tag_min_fields>;
  stddev?: Maybe<deflection_tag_stddev_fields>;
  stddev_pop?: Maybe<deflection_tag_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_tag_stddev_samp_fields>;
  sum?: Maybe<deflection_tag_sum_fields>;
  var_pop?: Maybe<deflection_tag_var_pop_fields>;
  var_samp?: Maybe<deflection_tag_var_samp_fields>;
  variance?: Maybe<deflection_tag_variance_fields>;
};


/** aggregate fields of "deflection_tag" */
export type deflection_tag_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "deflection_tag" */
export type deflection_tag_aggregate_order_by = {
  avg?: InputMaybe<deflection_tag_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<deflection_tag_max_order_by>;
  min?: InputMaybe<deflection_tag_min_order_by>;
  stddev?: InputMaybe<deflection_tag_stddev_order_by>;
  stddev_pop?: InputMaybe<deflection_tag_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<deflection_tag_stddev_samp_order_by>;
  sum?: InputMaybe<deflection_tag_sum_order_by>;
  var_pop?: InputMaybe<deflection_tag_var_pop_order_by>;
  var_samp?: InputMaybe<deflection_tag_var_samp_order_by>;
  variance?: InputMaybe<deflection_tag_variance_order_by>;
};

/** input type for inserting array relation for remote table "deflection_tag" */
export type deflection_tag_arr_rel_insert_input = {
  data: Array<deflection_tag_insert_input>;
};

/** aggregate avg on columns */
export type deflection_tag_avg_fields = {
  __typename: 'deflection_tag_avg_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "deflection_tag" */
export type deflection_tag_avg_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "deflection_tag". All fields are combined with a logical 'AND'. */
export type deflection_tag_bool_exp = {
  _and?: InputMaybe<Array<deflection_tag_bool_exp>>;
  _not?: InputMaybe<deflection_tag_bool_exp>;
  _or?: InputMaybe<Array<deflection_tag_bool_exp>>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  tag?: InputMaybe<tag_bool_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "deflection_tag" */
export type deflection_tag_insert_input = {
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<tag_obj_rel_insert_input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type deflection_tag_max_fields = {
  __typename: 'deflection_tag_max_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "deflection_tag" */
export type deflection_tag_max_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type deflection_tag_min_fields = {
  __typename: 'deflection_tag_min_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "deflection_tag" */
export type deflection_tag_min_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "deflection_tag". */
export type deflection_tag_order_by = {
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  tag?: InputMaybe<tag_order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** select columns of table "deflection_tag" */
export enum deflection_tag_select_column {
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  tag_id = 'tag_id'
}

/** aggregate stddev on columns */
export type deflection_tag_stddev_fields = {
  __typename: 'deflection_tag_stddev_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "deflection_tag" */
export type deflection_tag_stddev_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type deflection_tag_stddev_pop_fields = {
  __typename: 'deflection_tag_stddev_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "deflection_tag" */
export type deflection_tag_stddev_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type deflection_tag_stddev_samp_fields = {
  __typename: 'deflection_tag_stddev_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "deflection_tag" */
export type deflection_tag_stddev_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "deflection_tag" */
export type deflection_tag_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_tag_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_tag_stream_cursor_value_input = {
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type deflection_tag_sum_fields = {
  __typename: 'deflection_tag_sum_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "deflection_tag" */
export type deflection_tag_sum_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type deflection_tag_var_pop_fields = {
  __typename: 'deflection_tag_var_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "deflection_tag" */
export type deflection_tag_var_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type deflection_tag_var_samp_fields = {
  __typename: 'deflection_tag_var_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "deflection_tag" */
export type deflection_tag_var_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type deflection_tag_variance_fields = {
  __typename: 'deflection_tag_variance_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "deflection_tag" */
export type deflection_tag_variance_order_by = {
  deflection_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** columns and relationships of "deflection_tsvector" */
export type deflection_tsvector = {
  __typename: 'deflection_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "deflection_tsvector" */
export type deflection_tsvector_aggregate = {
  __typename: 'deflection_tsvector_aggregate';
  aggregate?: Maybe<deflection_tsvector_aggregate_fields>;
  nodes: Array<deflection_tsvector>;
};

/** aggregate fields of "deflection_tsvector" */
export type deflection_tsvector_aggregate_fields = {
  __typename: 'deflection_tsvector_aggregate_fields';
  avg?: Maybe<deflection_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_tsvector_max_fields>;
  min?: Maybe<deflection_tsvector_min_fields>;
  stddev?: Maybe<deflection_tsvector_stddev_fields>;
  stddev_pop?: Maybe<deflection_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<deflection_tsvector_stddev_samp_fields>;
  sum?: Maybe<deflection_tsvector_sum_fields>;
  var_pop?: Maybe<deflection_tsvector_var_pop_fields>;
  var_samp?: Maybe<deflection_tsvector_var_samp_fields>;
  variance?: Maybe<deflection_tsvector_variance_fields>;
};


/** aggregate fields of "deflection_tsvector" */
export type deflection_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type deflection_tsvector_avg_fields = {
  __typename: 'deflection_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "deflection_tsvector". All fields are combined with a logical 'AND'. */
export type deflection_tsvector_bool_exp = {
  _and?: InputMaybe<Array<deflection_tsvector_bool_exp>>;
  _not?: InputMaybe<deflection_tsvector_bool_exp>;
  _or?: InputMaybe<Array<deflection_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type deflection_tsvector_max_fields = {
  __typename: 'deflection_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type deflection_tsvector_min_fields = {
  __typename: 'deflection_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "deflection_tsvector". */
export type deflection_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "deflection_tsvector" */
export enum deflection_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type deflection_tsvector_stddev_fields = {
  __typename: 'deflection_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type deflection_tsvector_stddev_pop_fields = {
  __typename: 'deflection_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type deflection_tsvector_stddev_samp_fields = {
  __typename: 'deflection_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "deflection_tsvector" */
export type deflection_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type deflection_tsvector_sum_fields = {
  __typename: 'deflection_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type deflection_tsvector_var_pop_fields = {
  __typename: 'deflection_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type deflection_tsvector_var_samp_fields = {
  __typename: 'deflection_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type deflection_tsvector_variance_fields = {
  __typename: 'deflection_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "deflection_type" */
export type deflection_type = {
  __typename: 'deflection_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "deflection_type" */
export type deflection_type_aggregate = {
  __typename: 'deflection_type_aggregate';
  aggregate?: Maybe<deflection_type_aggregate_fields>;
  nodes: Array<deflection_type>;
};

/** aggregate fields of "deflection_type" */
export type deflection_type_aggregate_fields = {
  __typename: 'deflection_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<deflection_type_max_fields>;
  min?: Maybe<deflection_type_min_fields>;
};


/** aggregate fields of "deflection_type" */
export type deflection_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<deflection_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "deflection_type". All fields are combined with a logical 'AND'. */
export type deflection_type_bool_exp = {
  _and?: InputMaybe<Array<deflection_type_bool_exp>>;
  _not?: InputMaybe<deflection_type_bool_exp>;
  _or?: InputMaybe<Array<deflection_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "deflection_type" */
export enum deflection_type_constraint {
  /** unique or primary key constraint on columns "value" */
  deflection_type_pkey = 'deflection_type_pkey'
}

/** input type for inserting data into table "deflection_type" */
export type deflection_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type deflection_type_max_fields = {
  __typename: 'deflection_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type deflection_type_min_fields = {
  __typename: 'deflection_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "deflection_type" */
export type deflection_type_mutation_response = {
  __typename: 'deflection_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<deflection_type>;
};

/** on_conflict condition type for table "deflection_type" */
export type deflection_type_on_conflict = {
  constraint: deflection_type_constraint;
  update_columns?: Array<deflection_type_update_column>;
  where?: InputMaybe<deflection_type_bool_exp>;
};

/** Ordering options when selecting data from "deflection_type". */
export type deflection_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: deflection_type */
export type deflection_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "deflection_type" */
export enum deflection_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "deflection_type" */
export type deflection_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "deflection_type" */
export type deflection_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: deflection_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type deflection_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "deflection_type" */
export enum deflection_type_update_column {
  /** column name */
  value = 'value'
}

export type deflection_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<deflection_type_set_input>;
  /** filter the rows which have to be updated */
  where: deflection_type_bool_exp;
};

/** update columns of table "deflection" */
export enum deflection_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  archived_at = 'archived_at',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  flow_action_ids = 'flow_action_ids',
  /** column name */
  heading_translation_id = 'heading_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  minimum_items = 'minimum_items',
  /** column name */
  snapshot_item_ids = 'snapshot_item_ids',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type deflection_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<deflection_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<deflection_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<deflection_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<deflection_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<deflection_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<deflection_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<deflection_set_input>;
  /** filter the rows which have to be updated */
  where: deflection_bool_exp;
};

/** aggregate var_pop on columns */
export type deflection_var_pop_fields = {
  __typename: 'deflection_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type deflection_var_samp_fields = {
  __typename: 'deflection_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type deflection_variance_fields = {
  __typename: 'deflection_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  heading_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_items?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "demo" */
export type demo = {
  __typename: 'demo';
  brand_color?: Maybe<Scalars['String']['output']>;
  client_id: Scalars['String']['output'];
  company_name: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  flow_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  logo_url?: Maybe<Scalars['String']['output']>;
  properties: Scalars['jsonb']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "demo" */
export type demopropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "demo" */
export type demo_aggregate = {
  __typename: 'demo_aggregate';
  aggregate?: Maybe<demo_aggregate_fields>;
  nodes: Array<demo>;
};

/** aggregate fields of "demo" */
export type demo_aggregate_fields = {
  __typename: 'demo_aggregate_fields';
  avg?: Maybe<demo_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<demo_max_fields>;
  min?: Maybe<demo_min_fields>;
  stddev?: Maybe<demo_stddev_fields>;
  stddev_pop?: Maybe<demo_stddev_pop_fields>;
  stddev_samp?: Maybe<demo_stddev_samp_fields>;
  sum?: Maybe<demo_sum_fields>;
  var_pop?: Maybe<demo_var_pop_fields>;
  var_samp?: Maybe<demo_var_samp_fields>;
  variance?: Maybe<demo_variance_fields>;
};


/** aggregate fields of "demo" */
export type demo_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<demo_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type demo_append_input = {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type demo_avg_fields = {
  __typename: 'demo_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "demo". All fields are combined with a logical 'AND'. */
export type demo_bool_exp = {
  _and?: InputMaybe<Array<demo_bool_exp>>;
  _not?: InputMaybe<demo_bool_exp>;
  _or?: InputMaybe<Array<demo_bool_exp>>;
  brand_color?: InputMaybe<String_comparison_exp>;
  client_id?: InputMaybe<String_comparison_exp>;
  company_name?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  logo_url?: InputMaybe<String_comparison_exp>;
  properties?: InputMaybe<jsonb_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "demo" */
export enum demo_constraint {
  /** unique or primary key constraint on columns "id" */
  demo_pkey = 'demo_pkey',
  /** unique or primary key constraint on columns "token" */
  demo_token_key = 'demo_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type demo_delete_at_path_input = {
  properties?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type demo_delete_elem_input = {
  properties?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type demo_delete_key_input = {
  properties?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "demo" */
export type demo_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "demo" */
export type demo_insert_input = {
  brand_color?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type demo_max_fields = {
  __typename: 'demo_max_fields';
  brand_color?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type demo_min_fields = {
  __typename: 'demo_min_fields';
  brand_color?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "demo" */
export type demo_mutation_response = {
  __typename: 'demo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<demo>;
};

/** on_conflict condition type for table "demo" */
export type demo_on_conflict = {
  constraint: demo_constraint;
  update_columns?: Array<demo_update_column>;
  where?: InputMaybe<demo_bool_exp>;
};

/** Ordering options when selecting data from "demo". */
export type demo_order_by = {
  brand_color?: InputMaybe<order_by>;
  client_id?: InputMaybe<order_by>;
  company_name?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  properties?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: demo */
export type demo_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type demo_prepend_input = {
  properties?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "demo" */
export enum demo_select_column {
  /** column name */
  brand_color = 'brand_color',
  /** column name */
  client_id = 'client_id',
  /** column name */
  company_name = 'company_name',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  properties = 'properties',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "demo" */
export type demo_set_input = {
  brand_color?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type demo_stddev_fields = {
  __typename: 'demo_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type demo_stddev_pop_fields = {
  __typename: 'demo_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type demo_stddev_samp_fields = {
  __typename: 'demo_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "demo" */
export type demo_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: demo_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type demo_stream_cursor_value_input = {
  brand_color?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type demo_sum_fields = {
  __typename: 'demo_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "demo" */
export enum demo_update_column {
  /** column name */
  brand_color = 'brand_color',
  /** column name */
  client_id = 'client_id',
  /** column name */
  company_name = 'company_name',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  properties = 'properties',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type demo_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<demo_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<demo_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<demo_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<demo_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<demo_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<demo_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<demo_set_input>;
  /** filter the rows which have to be updated */
  where: demo_bool_exp;
};

/** aggregate var_pop on columns */
export type demo_var_pop_fields = {
  __typename: 'demo_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type demo_var_samp_fields = {
  __typename: 'demo_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type demo_variance_fields = {
  __typename: 'demo_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "domain" */
export type domain = {
  __typename: 'domain';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  hostname: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  type: domain_type_enum;
  verified: Scalars['Boolean']['output'];
};

/** aggregated selection of "domain" */
export type domain_aggregate = {
  __typename: 'domain_aggregate';
  aggregate?: Maybe<domain_aggregate_fields>;
  nodes: Array<domain>;
};

/** aggregate fields of "domain" */
export type domain_aggregate_fields = {
  __typename: 'domain_aggregate_fields';
  avg?: Maybe<domain_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<domain_max_fields>;
  min?: Maybe<domain_min_fields>;
  stddev?: Maybe<domain_stddev_fields>;
  stddev_pop?: Maybe<domain_stddev_pop_fields>;
  stddev_samp?: Maybe<domain_stddev_samp_fields>;
  sum?: Maybe<domain_sum_fields>;
  var_pop?: Maybe<domain_var_pop_fields>;
  var_samp?: Maybe<domain_var_samp_fields>;
  variance?: Maybe<domain_variance_fields>;
};


/** aggregate fields of "domain" */
export type domain_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<domain_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type domain_avg_fields = {
  __typename: 'domain_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "domain". All fields are combined with a logical 'AND'. */
export type domain_bool_exp = {
  _and?: InputMaybe<Array<domain_bool_exp>>;
  _not?: InputMaybe<domain_bool_exp>;
  _or?: InputMaybe<Array<domain_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  hostname?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  type?: InputMaybe<domain_type_enum_comparison_exp>;
  verified?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "domain" */
export enum domain_constraint {
  /** unique or primary key constraint on columns "hostname" */
  domain_hostname_idx = 'domain_hostname_idx',
  /** unique or primary key constraint on columns "id" */
  domain_pkey = 'domain_pkey'
}

/** input type for incrementing numeric columns in table "domain" */
export type domain_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "domain" */
export type domain_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<domain_type_enum>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type domain_max_fields = {
  __typename: 'domain_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type domain_min_fields = {
  __typename: 'domain_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "domain" */
export type domain_mutation_response = {
  __typename: 'domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<domain>;
};

/** on_conflict condition type for table "domain" */
export type domain_on_conflict = {
  constraint: domain_constraint;
  update_columns?: Array<domain_update_column>;
  where?: InputMaybe<domain_bool_exp>;
};

/** Ordering options when selecting data from "domain". */
export type domain_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  hostname?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  verified?: InputMaybe<order_by>;
};

/** primary key columns input for table: domain */
export type domain_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "domain" */
export enum domain_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hostname = 'hostname',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  verified = 'verified'
}

/** input type for updating data in table "domain" */
export type domain_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<domain_type_enum>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type domain_stddev_fields = {
  __typename: 'domain_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type domain_stddev_pop_fields = {
  __typename: 'domain_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type domain_stddev_samp_fields = {
  __typename: 'domain_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "domain" */
export type domain_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: domain_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type domain_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<domain_type_enum>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type domain_sum_fields = {
  __typename: 'domain_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "domain_type" */
export type domain_type = {
  __typename: 'domain_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "domain_type" */
export type domain_type_aggregate = {
  __typename: 'domain_type_aggregate';
  aggregate?: Maybe<domain_type_aggregate_fields>;
  nodes: Array<domain_type>;
};

/** aggregate fields of "domain_type" */
export type domain_type_aggregate_fields = {
  __typename: 'domain_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<domain_type_max_fields>;
  min?: Maybe<domain_type_min_fields>;
};


/** aggregate fields of "domain_type" */
export type domain_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<domain_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "domain_type". All fields are combined with a logical 'AND'. */
export type domain_type_bool_exp = {
  _and?: InputMaybe<Array<domain_type_bool_exp>>;
  _not?: InputMaybe<domain_type_bool_exp>;
  _or?: InputMaybe<Array<domain_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "domain_type" */
export enum domain_type_constraint {
  /** unique or primary key constraint on columns "value" */
  domain_type_pkey = 'domain_type_pkey'
}

export enum domain_type_enum {
  flow = 'flow',
  portal = 'portal'
}

/** Boolean expression to compare columns of type "domain_type_enum". All fields are combined with logical 'AND'. */
export type domain_type_enum_comparison_exp = {
  _eq?: InputMaybe<domain_type_enum>;
  _in?: InputMaybe<Array<domain_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<domain_type_enum>;
  _nin?: InputMaybe<Array<domain_type_enum>>;
};

/** input type for inserting data into table "domain_type" */
export type domain_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type domain_type_max_fields = {
  __typename: 'domain_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type domain_type_min_fields = {
  __typename: 'domain_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "domain_type" */
export type domain_type_mutation_response = {
  __typename: 'domain_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<domain_type>;
};

/** on_conflict condition type for table "domain_type" */
export type domain_type_on_conflict = {
  constraint: domain_type_constraint;
  update_columns?: Array<domain_type_update_column>;
  where?: InputMaybe<domain_type_bool_exp>;
};

/** Ordering options when selecting data from "domain_type". */
export type domain_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: domain_type */
export type domain_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "domain_type" */
export enum domain_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "domain_type" */
export type domain_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "domain_type" */
export type domain_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: domain_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type domain_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "domain_type" */
export enum domain_type_update_column {
  /** column name */
  value = 'value'
}

export type domain_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<domain_type_set_input>;
  /** filter the rows which have to be updated */
  where: domain_type_bool_exp;
};

/** update columns of table "domain" */
export enum domain_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hostname = 'hostname',
  /** column name */
  id = 'id',
  /** column name */
  type = 'type',
  /** column name */
  verified = 'verified'
}

export type domain_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<domain_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<domain_set_input>;
  /** filter the rows which have to be updated */
  where: domain_bool_exp;
};

/** aggregate var_pop on columns */
export type domain_var_pop_fields = {
  __typename: 'domain_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type domain_var_samp_fields = {
  __typename: 'domain_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type domain_variance_fields = {
  __typename: 'domain_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "eligibility_message" */
export type eligibility_message = {
  __typename: 'eligibility_message';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  eligibility_header_translation?: Maybe<translation>;
  eligibility_header_translation_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  eligibility_message_translation?: Maybe<translation>;
  eligibility_message_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
};


/** columns and relationships of "eligibility_message" */
export type eligibility_messagesubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "eligibility_message" */
export type eligibility_messagesubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};

/** aggregated selection of "eligibility_message" */
export type eligibility_message_aggregate = {
  __typename: 'eligibility_message_aggregate';
  aggregate?: Maybe<eligibility_message_aggregate_fields>;
  nodes: Array<eligibility_message>;
};

/** aggregate fields of "eligibility_message" */
export type eligibility_message_aggregate_fields = {
  __typename: 'eligibility_message_aggregate_fields';
  avg?: Maybe<eligibility_message_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<eligibility_message_max_fields>;
  min?: Maybe<eligibility_message_min_fields>;
  stddev?: Maybe<eligibility_message_stddev_fields>;
  stddev_pop?: Maybe<eligibility_message_stddev_pop_fields>;
  stddev_samp?: Maybe<eligibility_message_stddev_samp_fields>;
  sum?: Maybe<eligibility_message_sum_fields>;
  var_pop?: Maybe<eligibility_message_var_pop_fields>;
  var_samp?: Maybe<eligibility_message_var_samp_fields>;
  variance?: Maybe<eligibility_message_variance_fields>;
};


/** aggregate fields of "eligibility_message" */
export type eligibility_message_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<eligibility_message_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type eligibility_message_avg_fields = {
  __typename: 'eligibility_message_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "eligibility_message". All fields are combined with a logical 'AND'. */
export type eligibility_message_bool_exp = {
  _and?: InputMaybe<Array<eligibility_message_bool_exp>>;
  _not?: InputMaybe<eligibility_message_bool_exp>;
  _or?: InputMaybe<Array<eligibility_message_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  eligibility_header_translation?: InputMaybe<translation_bool_exp>;
  eligibility_header_translation_id?: InputMaybe<Int_comparison_exp>;
  eligibility_message_translation?: InputMaybe<translation_bool_exp>;
  eligibility_message_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "eligibility_message" */
export enum eligibility_message_constraint {
  /** unique or primary key constraint on columns "id" */
  eligibility_message_pkey = 'eligibility_message_pkey'
}

/** input type for incrementing numeric columns in table "eligibility_message" */
export type eligibility_message_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  eligibility_header_translation_id?: InputMaybe<Scalars['Int']['input']>;
  eligibility_message_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "eligibility_message" */
export type eligibility_message_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_header_translation?: InputMaybe<translation_obj_rel_insert_input>;
  eligibility_header_translation_id?: InputMaybe<Scalars['Int']['input']>;
  eligibility_message_translation?: InputMaybe<translation_obj_rel_insert_input>;
  eligibility_message_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type eligibility_message_max_fields = {
  __typename: 'eligibility_message_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Int']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type eligibility_message_min_fields = {
  __typename: 'eligibility_message_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Int']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "eligibility_message" */
export type eligibility_message_mutation_response = {
  __typename: 'eligibility_message_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<eligibility_message>;
};

/** input type for inserting object relation for remote table "eligibility_message" */
export type eligibility_message_obj_rel_insert_input = {
  data: eligibility_message_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<eligibility_message_on_conflict>;
};

/** on_conflict condition type for table "eligibility_message" */
export type eligibility_message_on_conflict = {
  constraint: eligibility_message_constraint;
  update_columns?: Array<eligibility_message_update_column>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};

/** Ordering options when selecting data from "eligibility_message". */
export type eligibility_message_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  eligibility_header_translation?: InputMaybe<translation_order_by>;
  eligibility_header_translation_id?: InputMaybe<order_by>;
  eligibility_message_translation?: InputMaybe<translation_order_by>;
  eligibility_message_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
};

/** primary key columns input for table: eligibility_message */
export type eligibility_message_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "eligibility_message" */
export enum eligibility_message_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  eligibility_header_translation_id = 'eligibility_header_translation_id',
  /** column name */
  eligibility_message_translation_id = 'eligibility_message_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "eligibility_message" */
export type eligibility_message_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_header_translation_id?: InputMaybe<Scalars['Int']['input']>;
  eligibility_message_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type eligibility_message_stddev_fields = {
  __typename: 'eligibility_message_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type eligibility_message_stddev_pop_fields = {
  __typename: 'eligibility_message_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type eligibility_message_stddev_samp_fields = {
  __typename: 'eligibility_message_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "eligibility_message" */
export type eligibility_message_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: eligibility_message_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type eligibility_message_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_header_translation_id?: InputMaybe<Scalars['Int']['input']>;
  eligibility_message_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type eligibility_message_sum_fields = {
  __typename: 'eligibility_message_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Int']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "eligibility_message" */
export enum eligibility_message_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  eligibility_header_translation_id = 'eligibility_header_translation_id',
  /** column name */
  eligibility_message_translation_id = 'eligibility_message_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type eligibility_message_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<eligibility_message_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<eligibility_message_set_input>;
  /** filter the rows which have to be updated */
  where: eligibility_message_bool_exp;
};

/** aggregate var_pop on columns */
export type eligibility_message_var_pop_fields = {
  __typename: 'eligibility_message_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type eligibility_message_var_samp_fields = {
  __typename: 'eligibility_message_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type eligibility_message_variance_fields = {
  __typename: 'eligibility_message_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  eligibility_header_translation_id?: Maybe<Scalars['Float']['output']>;
  eligibility_message_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "event" */
export type event = {
  __typename: 'event';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  idempotency_key?: Maybe<Scalars['String']['output']>;
  payload: Scalars['jsonb']['output'];
  token: Scalars['String']['output'];
  type: event_type_enum;
};


/** columns and relationships of "event" */
export type eventpayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "event" */
export type event_aggregate = {
  __typename: 'event_aggregate';
  aggregate?: Maybe<event_aggregate_fields>;
  nodes: Array<event>;
};

/** aggregate fields of "event" */
export type event_aggregate_fields = {
  __typename: 'event_aggregate_fields';
  avg?: Maybe<event_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<event_max_fields>;
  min?: Maybe<event_min_fields>;
  stddev?: Maybe<event_stddev_fields>;
  stddev_pop?: Maybe<event_stddev_pop_fields>;
  stddev_samp?: Maybe<event_stddev_samp_fields>;
  sum?: Maybe<event_sum_fields>;
  var_pop?: Maybe<event_var_pop_fields>;
  var_samp?: Maybe<event_var_samp_fields>;
  variance?: Maybe<event_variance_fields>;
};


/** aggregate fields of "event" */
export type event_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<event_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type event_append_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type event_avg_fields = {
  __typename: 'event_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type event_bool_exp = {
  _and?: InputMaybe<Array<event_bool_exp>>;
  _not?: InputMaybe<event_bool_exp>;
  _or?: InputMaybe<Array<event_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  idempotency_key?: InputMaybe<String_comparison_exp>;
  payload?: InputMaybe<jsonb_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<event_type_enum_comparison_exp>;
};

/** unique or primary key constraints on table "event" */
export enum event_constraint {
  /** unique or primary key constraint on columns "idempotency_key" */
  event_idempotency_key_key = 'event_idempotency_key_key',
  /** unique or primary key constraint on columns "id" */
  event_pkey = 'event_pkey',
  /** unique or primary key constraint on columns "token" */
  event_token_key = 'event_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type event_delete_at_path_input = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type event_delete_elem_input = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type event_delete_key_input = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "event" */
export type event_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "event" */
export type event_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  idempotency_key?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<event_type_enum>;
};

/** aggregate max on columns */
export type event_max_fields = {
  __typename: 'event_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  idempotency_key?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type event_min_fields = {
  __typename: 'event_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  idempotency_key?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "event" */
export type event_mutation_response = {
  __typename: 'event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<event>;
};

/** input type for inserting object relation for remote table "event" */
export type event_obj_rel_insert_input = {
  data: event_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<event_on_conflict>;
};

/** on_conflict condition type for table "event" */
export type event_on_conflict = {
  constraint: event_constraint;
  update_columns?: Array<event_update_column>;
  where?: InputMaybe<event_bool_exp>;
};

/** Ordering options when selecting data from "event". */
export type event_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  idempotency_key?: InputMaybe<order_by>;
  payload?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
};

/** primary key columns input for table: event */
export type event_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type event_prepend_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "event" */
export enum event_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  idempotency_key = 'idempotency_key',
  /** column name */
  payload = 'payload',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "event" */
export type event_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  idempotency_key?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<event_type_enum>;
};

/** aggregate stddev on columns */
export type event_stddev_fields = {
  __typename: 'event_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type event_stddev_pop_fields = {
  __typename: 'event_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type event_stddev_samp_fields = {
  __typename: 'event_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "event" */
export type event_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: event_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type event_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  idempotency_key?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<event_type_enum>;
};

/** aggregate sum on columns */
export type event_sum_fields = {
  __typename: 'event_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "event_type" */
export type event_type = {
  __typename: 'event_type';
  value: Scalars['String']['output'];
  /** An array relationship */
  webhook_events: Array<webhook_event>;
  /** An aggregate relationship */
  webhook_events_aggregate: webhook_event_aggregate;
};


/** columns and relationships of "event_type" */
export type event_typewebhook_eventsArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


/** columns and relationships of "event_type" */
export type event_typewebhook_events_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};

/** aggregated selection of "event_type" */
export type event_type_aggregate = {
  __typename: 'event_type_aggregate';
  aggregate?: Maybe<event_type_aggregate_fields>;
  nodes: Array<event_type>;
};

/** aggregate fields of "event_type" */
export type event_type_aggregate_fields = {
  __typename: 'event_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<event_type_max_fields>;
  min?: Maybe<event_type_min_fields>;
};


/** aggregate fields of "event_type" */
export type event_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<event_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "event_type". All fields are combined with a logical 'AND'. */
export type event_type_bool_exp = {
  _and?: InputMaybe<Array<event_type_bool_exp>>;
  _not?: InputMaybe<event_type_bool_exp>;
  _or?: InputMaybe<Array<event_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
  webhook_events?: InputMaybe<webhook_event_bool_exp>;
  webhook_events_aggregate?: InputMaybe<webhook_event_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "event_type" */
export enum event_type_constraint {
  /** unique or primary key constraint on columns "value" */
  webhook_event_type_pkey = 'webhook_event_type_pkey'
}

export enum event_type_enum {
  campaign_view_completed = 'campaign_view_completed',
  campaign_view_started = 'campaign_view_started',
  flow_session_completed = 'flow_session_completed',
  flow_session_started = 'flow_session_started'
}

/** Boolean expression to compare columns of type "event_type_enum". All fields are combined with logical 'AND'. */
export type event_type_enum_comparison_exp = {
  _eq?: InputMaybe<event_type_enum>;
  _in?: InputMaybe<Array<event_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<event_type_enum>;
  _nin?: InputMaybe<Array<event_type_enum>>;
};

/** input type for inserting data into table "event_type" */
export type event_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
  webhook_events?: InputMaybe<webhook_event_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type event_type_max_fields = {
  __typename: 'event_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type event_type_min_fields = {
  __typename: 'event_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "event_type" */
export type event_type_mutation_response = {
  __typename: 'event_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<event_type>;
};

/** input type for inserting object relation for remote table "event_type" */
export type event_type_obj_rel_insert_input = {
  data: event_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<event_type_on_conflict>;
};

/** on_conflict condition type for table "event_type" */
export type event_type_on_conflict = {
  constraint: event_type_constraint;
  update_columns?: Array<event_type_update_column>;
  where?: InputMaybe<event_type_bool_exp>;
};

/** Ordering options when selecting data from "event_type". */
export type event_type_order_by = {
  value?: InputMaybe<order_by>;
  webhook_events_aggregate?: InputMaybe<webhook_event_aggregate_order_by>;
};

/** primary key columns input for table: event_type */
export type event_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "event_type" */
export enum event_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "event_type" */
export type event_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "event_type" */
export type event_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: event_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type event_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "event_type" */
export enum event_type_update_column {
  /** column name */
  value = 'value'
}

export type event_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<event_type_set_input>;
  /** filter the rows which have to be updated */
  where: event_type_bool_exp;
};

/** update columns of table "event" */
export enum event_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  idempotency_key = 'idempotency_key',
  /** column name */
  payload = 'payload',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type'
}

export type event_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<event_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<event_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<event_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<event_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<event_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<event_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<event_set_input>;
  /** filter the rows which have to be updated */
  where: event_bool_exp;
};

/** aggregate var_pop on columns */
export type event_var_pop_fields = {
  __typename: 'event_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type event_var_samp_fields = {
  __typename: 'event_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type event_variance_fields = {
  __typename: 'event_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "feedback_trends_grid" */
export type feedback_trends_grid = {
  __typename: 'feedback_trends_grid';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow?: Maybe<flow>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question?: Maybe<question>;
  /** An object relationship */
  question_answer?: Maybe<question_answer>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  sentiment_phrase_normalized?: Maybe<sentiment_phrase_normalized>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscriber_flow?: Maybe<subscriber_flow>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "feedback_trends_grid" */
export type feedback_trends_grid_aggregate = {
  __typename: 'feedback_trends_grid_aggregate';
  aggregate?: Maybe<feedback_trends_grid_aggregate_fields>;
  nodes: Array<feedback_trends_grid>;
};

/** aggregate fields of "feedback_trends_grid" */
export type feedback_trends_grid_aggregate_fields = {
  __typename: 'feedback_trends_grid_aggregate_fields';
  avg?: Maybe<feedback_trends_grid_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<feedback_trends_grid_max_fields>;
  min?: Maybe<feedback_trends_grid_min_fields>;
  stddev?: Maybe<feedback_trends_grid_stddev_fields>;
  stddev_pop?: Maybe<feedback_trends_grid_stddev_pop_fields>;
  stddev_samp?: Maybe<feedback_trends_grid_stddev_samp_fields>;
  sum?: Maybe<feedback_trends_grid_sum_fields>;
  var_pop?: Maybe<feedback_trends_grid_var_pop_fields>;
  var_samp?: Maybe<feedback_trends_grid_var_samp_fields>;
  variance?: Maybe<feedback_trends_grid_variance_fields>;
};


/** aggregate fields of "feedback_trends_grid" */
export type feedback_trends_grid_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<feedback_trends_grid_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type feedback_trends_grid_avg_fields = {
  __typename: 'feedback_trends_grid_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "feedback_trends_grid". All fields are combined with a logical 'AND'. */
export type feedback_trends_grid_bool_exp = {
  _and?: InputMaybe<Array<feedback_trends_grid_bool_exp>>;
  _not?: InputMaybe<feedback_trends_grid_bool_exp>;
  _or?: InputMaybe<Array<feedback_trends_grid_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  canceled?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deflected?: InputMaybe<Int_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  incidents?: InputMaybe<Int_comparison_exp>;
  lost_mrr?: InputMaybe<numeric_comparison_exp>;
  mixed?: InputMaybe<Int_comparison_exp>;
  negative?: InputMaybe<Int_comparison_exp>;
  neutral?: InputMaybe<Int_comparison_exp>;
  phrase?: InputMaybe<String_comparison_exp>;
  positive?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_answer?: InputMaybe<question_answer_bool_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  saved?: InputMaybe<Int_comparison_exp>;
  sentiment_phrase_normalized?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
  sentiment_phrase_normalized_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** aggregate max on columns */
export type feedback_trends_grid_max_fields = {
  __typename: 'feedback_trends_grid_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type feedback_trends_grid_min_fields = {
  __typename: 'feedback_trends_grid_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "feedback_trends_grid". */
export type feedback_trends_grid_order_by = {
  account_id?: InputMaybe<order_by>;
  canceled?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflected?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  incidents?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  mixed?: InputMaybe<order_by>;
  negative?: InputMaybe<order_by>;
  neutral?: InputMaybe<order_by>;
  phrase?: InputMaybe<order_by>;
  positive?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_answer?: InputMaybe<question_answer_order_by>;
  question_answer_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  saved?: InputMaybe<order_by>;
  sentiment_phrase_normalized?: InputMaybe<sentiment_phrase_normalized_order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** select columns of table "feedback_trends_grid" */
export enum feedback_trends_grid_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deflected = 'deflected',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  incidents = 'incidents',
  /** column name */
  lost_mrr = 'lost_mrr',
  /** column name */
  mixed = 'mixed',
  /** column name */
  negative = 'negative',
  /** column name */
  neutral = 'neutral',
  /** column name */
  phrase = 'phrase',
  /** column name */
  positive = 'positive',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  saved = 'saved',
  /** column name */
  sentiment_phrase_normalized_id = 'sentiment_phrase_normalized_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  subscription_id = 'subscription_id'
}

/** aggregate stddev on columns */
export type feedback_trends_grid_stddev_fields = {
  __typename: 'feedback_trends_grid_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type feedback_trends_grid_stddev_pop_fields = {
  __typename: 'feedback_trends_grid_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type feedback_trends_grid_stddev_samp_fields = {
  __typename: 'feedback_trends_grid_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "feedback_trends_grid" */
export type feedback_trends_grid_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: feedback_trends_grid_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type feedback_trends_grid_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  incidents?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  phrase?: InputMaybe<Scalars['String']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type feedback_trends_grid_sum_fields = {
  __typename: 'feedback_trends_grid_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type feedback_trends_grid_var_pop_fields = {
  __typename: 'feedback_trends_grid_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type feedback_trends_grid_var_samp_fields = {
  __typename: 'feedback_trends_grid_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type feedback_trends_grid_variance_fields = {
  __typename: 'feedback_trends_grid_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "flow" */
export type flow = {
  __typename: 'flow';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  auto_cancel: Scalars['Boolean']['output'];
  auto_cancel_at: flow_auto_cancel_at_enum;
  created_at: Scalars['timestamptz']['output'];
  css?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  default_flow_account?: Maybe<account>;
  default_language: language_enum;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  draft_version?: Maybe<flow_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_auto_cancel_at: flow_auto_cancel_at;
  /** An array relationship */
  flow_languages: Array<flow_language>;
  /** An aggregate relationship */
  flow_languages_aggregate: flow_language_aggregate;
  /** An array relationship */
  flow_on_cancel_change_plans: Array<flow_on_cancel_change_plan>;
  /** An aggregate relationship */
  flow_on_cancel_change_plans_aggregate: flow_on_cancel_change_plan_aggregate;
  /** An array relationship */
  flow_route_rule_flows: Array<flow_route_rule_flow>;
  /** An aggregate relationship */
  flow_route_rule_flows_aggregate: flow_route_rule_flow_aggregate;
  /** An array relationship */
  flow_steps: Array<flow_step>;
  /** An aggregate relationship */
  flow_steps_aggregate: flow_step_aggregate;
  /** An array relationship */
  flow_subscriber_form_questions: Array<flow_subscriber_form_question>;
  /** An aggregate relationship */
  flow_subscriber_form_questions_aggregate: flow_subscriber_form_question_aggregate;
  /** An array relationship */
  flow_tags: Array<flow_tag>;
  /** An aggregate relationship */
  flow_tags_aggregate: flow_tag_aggregate;
  /** An array relationship */
  flow_texts: Array<flow_text>;
  /** An aggregate relationship */
  flow_texts_aggregate: flow_text_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  language: language;
  logo_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  most_recent_subscriber_flow_version?: Maybe<most_recent_subscriber_flow_version>;
  on_cancel: flow_on_cancel_enum;
  on_cancel_perform_at: flow_on_cancel_perform_at_enum;
  prevent_if_canceled: Scalars['Boolean']['output'];
  /** An object relationship */
  prevent_if_canceled_translation?: Maybe<translation>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Int']['output']>;
  preview_property_values: Scalars['jsonb']['output'];
  /** An object relationship */
  published_version?: Maybe<flow_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  redirect_uri?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
  signature_required: Scalars['Boolean']['output'];
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
  tag_ids: Scalars['jsonb']['output'];
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "flow" */
export type flowflow_languagesArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_languages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_on_cancel_change_plansArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_on_cancel_change_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_route_rule_flowsArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_route_rule_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_stepsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_steps_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_subscriber_form_questionsArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_subscriber_form_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_tagsArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_textsArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowflow_texts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowpreview_property_valuesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow" */
export type flowsubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowsubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "flow" */
export type flowtag_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "flow_action" */
export type flow_action = {
  __typename: 'flow_action';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  appearance: flow_action_appearance_enum;
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  deflection_flow_actions: Array<deflection_flow_action>;
  /** An aggregate relationship */
  deflection_flow_actions_aggregate: deflection_flow_action_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  reroute_to_flow?: Maybe<flow>;
  reroute_to_flow_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  text_translation: translation;
  text_translation_id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  type: flow_action_type_enum;
  updated_at: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "flow_action" */
export type flow_actiondeflection_flow_actionsArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


/** columns and relationships of "flow_action" */
export type flow_actiondeflection_flow_actions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};

/** aggregated selection of "flow_action" */
export type flow_action_aggregate = {
  __typename: 'flow_action_aggregate';
  aggregate?: Maybe<flow_action_aggregate_fields>;
  nodes: Array<flow_action>;
};

/** aggregate fields of "flow_action" */
export type flow_action_aggregate_fields = {
  __typename: 'flow_action_aggregate_fields';
  avg?: Maybe<flow_action_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_action_max_fields>;
  min?: Maybe<flow_action_min_fields>;
  stddev?: Maybe<flow_action_stddev_fields>;
  stddev_pop?: Maybe<flow_action_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_action_stddev_samp_fields>;
  sum?: Maybe<flow_action_sum_fields>;
  var_pop?: Maybe<flow_action_var_pop_fields>;
  var_samp?: Maybe<flow_action_var_samp_fields>;
  variance?: Maybe<flow_action_variance_fields>;
};


/** aggregate fields of "flow_action" */
export type flow_action_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_action_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "flow_action_appearance" */
export type flow_action_appearance = {
  __typename: 'flow_action_appearance';
  value: Scalars['String']['output'];
};

/** aggregated selection of "flow_action_appearance" */
export type flow_action_appearance_aggregate = {
  __typename: 'flow_action_appearance_aggregate';
  aggregate?: Maybe<flow_action_appearance_aggregate_fields>;
  nodes: Array<flow_action_appearance>;
};

/** aggregate fields of "flow_action_appearance" */
export type flow_action_appearance_aggregate_fields = {
  __typename: 'flow_action_appearance_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_action_appearance_max_fields>;
  min?: Maybe<flow_action_appearance_min_fields>;
};


/** aggregate fields of "flow_action_appearance" */
export type flow_action_appearance_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_action_appearance_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_action_appearance". All fields are combined with a logical 'AND'. */
export type flow_action_appearance_bool_exp = {
  _and?: InputMaybe<Array<flow_action_appearance_bool_exp>>;
  _not?: InputMaybe<flow_action_appearance_bool_exp>;
  _or?: InputMaybe<Array<flow_action_appearance_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_action_appearance" */
export enum flow_action_appearance_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_action_appearance_pkey = 'flow_action_appearance_pkey'
}

export enum flow_action_appearance_enum {
  button = 'button',
  link = 'link'
}

/** Boolean expression to compare columns of type "flow_action_appearance_enum". All fields are combined with logical 'AND'. */
export type flow_action_appearance_enum_comparison_exp = {
  _eq?: InputMaybe<flow_action_appearance_enum>;
  _in?: InputMaybe<Array<flow_action_appearance_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_action_appearance_enum>;
  _nin?: InputMaybe<Array<flow_action_appearance_enum>>;
};

/** input type for inserting data into table "flow_action_appearance" */
export type flow_action_appearance_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_action_appearance_max_fields = {
  __typename: 'flow_action_appearance_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_action_appearance_min_fields = {
  __typename: 'flow_action_appearance_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_action_appearance" */
export type flow_action_appearance_mutation_response = {
  __typename: 'flow_action_appearance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_action_appearance>;
};

/** on_conflict condition type for table "flow_action_appearance" */
export type flow_action_appearance_on_conflict = {
  constraint: flow_action_appearance_constraint;
  update_columns?: Array<flow_action_appearance_update_column>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};

/** Ordering options when selecting data from "flow_action_appearance". */
export type flow_action_appearance_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_action_appearance */
export type flow_action_appearance_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_action_appearance" */
export enum flow_action_appearance_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_action_appearance" */
export type flow_action_appearance_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_action_appearance" */
export type flow_action_appearance_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_action_appearance_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_action_appearance_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_action_appearance" */
export enum flow_action_appearance_update_column {
  /** column name */
  value = 'value'
}

export type flow_action_appearance_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_action_appearance_set_input>;
  /** filter the rows which have to be updated */
  where: flow_action_appearance_bool_exp;
};

/** aggregate avg on columns */
export type flow_action_avg_fields = {
  __typename: 'flow_action_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "flow_action". All fields are combined with a logical 'AND'. */
export type flow_action_bool_exp = {
  _and?: InputMaybe<Array<flow_action_bool_exp>>;
  _not?: InputMaybe<flow_action_bool_exp>;
  _or?: InputMaybe<Array<flow_action_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  appearance?: InputMaybe<flow_action_appearance_enum_comparison_exp>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deflection_flow_actions?: InputMaybe<deflection_flow_action_bool_exp>;
  deflection_flow_actions_aggregate?: InputMaybe<deflection_flow_action_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  reroute_to_flow?: InputMaybe<flow_bool_exp>;
  reroute_to_flow_id?: InputMaybe<Int_comparison_exp>;
  text_translation?: InputMaybe<translation_bool_exp>;
  text_translation_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<flow_action_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_action" */
export enum flow_action_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_action_pkey = 'flow_action_pkey',
  /** unique or primary key constraint on columns "token" */
  flow_action_token_key = 'flow_action_token_key'
}

/** input type for incrementing numeric columns in table "flow_action" */
export type flow_action_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reroute_to_flow_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_action" */
export type flow_action_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  appearance?: InputMaybe<flow_action_appearance_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_flow_actions?: InputMaybe<deflection_flow_action_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reroute_to_flow?: InputMaybe<flow_obj_rel_insert_input>;
  reroute_to_flow_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_action_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_action_max_fields = {
  __typename: 'flow_action_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_action_min_fields = {
  __typename: 'flow_action_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_action" */
export type flow_action_mutation_response = {
  __typename: 'flow_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_action>;
};

/** input type for inserting object relation for remote table "flow_action" */
export type flow_action_obj_rel_insert_input = {
  data: flow_action_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_action_on_conflict>;
};

/** on_conflict condition type for table "flow_action" */
export type flow_action_on_conflict = {
  constraint: flow_action_constraint;
  update_columns?: Array<flow_action_update_column>;
  where?: InputMaybe<flow_action_bool_exp>;
};

/** Ordering options when selecting data from "flow_action". */
export type flow_action_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  appearance?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflection_flow_actions_aggregate?: InputMaybe<deflection_flow_action_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  reroute_to_flow?: InputMaybe<flow_order_by>;
  reroute_to_flow_id?: InputMaybe<order_by>;
  text_translation?: InputMaybe<translation_order_by>;
  text_translation_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_action */
export type flow_action_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "flow_action" */
export enum flow_action_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  appearance = 'appearance',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  reroute_to_flow_id = 'reroute_to_flow_id',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

/** input type for updating data in table "flow_action" */
export type flow_action_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  appearance?: InputMaybe<flow_action_appearance_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reroute_to_flow_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_action_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type flow_action_stddev_fields = {
  __typename: 'flow_action_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type flow_action_stddev_pop_fields = {
  __typename: 'flow_action_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type flow_action_stddev_samp_fields = {
  __typename: 'flow_action_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "flow_action" */
export type flow_action_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_action_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_action_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  appearance?: InputMaybe<flow_action_appearance_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  reroute_to_flow_id?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_action_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type flow_action_sum_fields = {
  __typename: 'flow_action_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "flow_action_type" */
export type flow_action_type = {
  __typename: 'flow_action_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "flow_action_type" */
export type flow_action_type_aggregate = {
  __typename: 'flow_action_type_aggregate';
  aggregate?: Maybe<flow_action_type_aggregate_fields>;
  nodes: Array<flow_action_type>;
};

/** aggregate fields of "flow_action_type" */
export type flow_action_type_aggregate_fields = {
  __typename: 'flow_action_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_action_type_max_fields>;
  min?: Maybe<flow_action_type_min_fields>;
};


/** aggregate fields of "flow_action_type" */
export type flow_action_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_action_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_action_type". All fields are combined with a logical 'AND'. */
export type flow_action_type_bool_exp = {
  _and?: InputMaybe<Array<flow_action_type_bool_exp>>;
  _not?: InputMaybe<flow_action_type_bool_exp>;
  _or?: InputMaybe<Array<flow_action_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_action_type" */
export enum flow_action_type_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_action_type_pkey = 'flow_action_type_pkey'
}

export enum flow_action_type_enum {
  close = 'close',
  reroute = 'reroute',
  url = 'url'
}

/** Boolean expression to compare columns of type "flow_action_type_enum". All fields are combined with logical 'AND'. */
export type flow_action_type_enum_comparison_exp = {
  _eq?: InputMaybe<flow_action_type_enum>;
  _in?: InputMaybe<Array<flow_action_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_action_type_enum>;
  _nin?: InputMaybe<Array<flow_action_type_enum>>;
};

/** input type for inserting data into table "flow_action_type" */
export type flow_action_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_action_type_max_fields = {
  __typename: 'flow_action_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_action_type_min_fields = {
  __typename: 'flow_action_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_action_type" */
export type flow_action_type_mutation_response = {
  __typename: 'flow_action_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_action_type>;
};

/** on_conflict condition type for table "flow_action_type" */
export type flow_action_type_on_conflict = {
  constraint: flow_action_type_constraint;
  update_columns?: Array<flow_action_type_update_column>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};

/** Ordering options when selecting data from "flow_action_type". */
export type flow_action_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_action_type */
export type flow_action_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_action_type" */
export enum flow_action_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_action_type" */
export type flow_action_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_action_type" */
export type flow_action_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_action_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_action_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_action_type" */
export enum flow_action_type_update_column {
  /** column name */
  value = 'value'
}

export type flow_action_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_action_type_set_input>;
  /** filter the rows which have to be updated */
  where: flow_action_type_bool_exp;
};

/** update columns of table "flow_action" */
export enum flow_action_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  appearance = 'appearance',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  reroute_to_flow_id = 'reroute_to_flow_id',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

export type flow_action_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_action_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_action_set_input>;
  /** filter the rows which have to be updated */
  where: flow_action_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_action_var_pop_fields = {
  __typename: 'flow_action_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type flow_action_var_samp_fields = {
  __typename: 'flow_action_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type flow_action_variance_fields = {
  __typename: 'flow_action_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reroute_to_flow_id?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregated selection of "flow" */
export type flow_aggregate = {
  __typename: 'flow_aggregate';
  aggregate?: Maybe<flow_aggregate_fields>;
  nodes: Array<flow>;
};

export type flow_aggregate_bool_exp = {
  bool_and?: InputMaybe<flow_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<flow_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<flow_aggregate_bool_exp_count>;
};

export type flow_aggregate_bool_exp_bool_and = {
  arguments: flow_select_column_flow_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_aggregate_bool_exp_bool_or = {
  arguments: flow_select_column_flow_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow" */
export type flow_aggregate_fields = {
  __typename: 'flow_aggregate_fields';
  avg?: Maybe<flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_max_fields>;
  min?: Maybe<flow_min_fields>;
  stddev?: Maybe<flow_stddev_fields>;
  stddev_pop?: Maybe<flow_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_stddev_samp_fields>;
  sum?: Maybe<flow_sum_fields>;
  var_pop?: Maybe<flow_var_pop_fields>;
  var_samp?: Maybe<flow_var_samp_fields>;
  variance?: Maybe<flow_variance_fields>;
};


/** aggregate fields of "flow" */
export type flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow" */
export type flow_aggregate_order_by = {
  avg?: InputMaybe<flow_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_max_order_by>;
  min?: InputMaybe<flow_min_order_by>;
  stddev?: InputMaybe<flow_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_stddev_samp_order_by>;
  sum?: InputMaybe<flow_sum_order_by>;
  var_pop?: InputMaybe<flow_var_pop_order_by>;
  var_samp?: InputMaybe<flow_var_samp_order_by>;
  variance?: InputMaybe<flow_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type flow_append_input = {
  preview_property_values?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "flow" */
export type flow_arr_rel_insert_input = {
  data: Array<flow_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_on_conflict>;
};

/** columns and relationships of "flow_auto_cancel_at" */
export type flow_auto_cancel_at = {
  __typename: 'flow_auto_cancel_at';
  /** An array relationship */
  flows: Array<flow>;
  /** An aggregate relationship */
  flows_aggregate: flow_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "flow_auto_cancel_at" */
export type flow_auto_cancel_atflowsArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


/** columns and relationships of "flow_auto_cancel_at" */
export type flow_auto_cancel_atflows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};

/** aggregated selection of "flow_auto_cancel_at" */
export type flow_auto_cancel_at_aggregate = {
  __typename: 'flow_auto_cancel_at_aggregate';
  aggregate?: Maybe<flow_auto_cancel_at_aggregate_fields>;
  nodes: Array<flow_auto_cancel_at>;
};

/** aggregate fields of "flow_auto_cancel_at" */
export type flow_auto_cancel_at_aggregate_fields = {
  __typename: 'flow_auto_cancel_at_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_auto_cancel_at_max_fields>;
  min?: Maybe<flow_auto_cancel_at_min_fields>;
};


/** aggregate fields of "flow_auto_cancel_at" */
export type flow_auto_cancel_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_auto_cancel_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_auto_cancel_at". All fields are combined with a logical 'AND'. */
export type flow_auto_cancel_at_bool_exp = {
  _and?: InputMaybe<Array<flow_auto_cancel_at_bool_exp>>;
  _not?: InputMaybe<flow_auto_cancel_at_bool_exp>;
  _or?: InputMaybe<Array<flow_auto_cancel_at_bool_exp>>;
  flows?: InputMaybe<flow_bool_exp>;
  flows_aggregate?: InputMaybe<flow_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_auto_cancel_at" */
export enum flow_auto_cancel_at_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_auto_cancel_at_pkey = 'flow_auto_cancel_at_pkey'
}

export enum flow_auto_cancel_at_enum {
  immediately = 'immediately',
  period_end = 'period_end'
}

/** Boolean expression to compare columns of type "flow_auto_cancel_at_enum". All fields are combined with logical 'AND'. */
export type flow_auto_cancel_at_enum_comparison_exp = {
  _eq?: InputMaybe<flow_auto_cancel_at_enum>;
  _in?: InputMaybe<Array<flow_auto_cancel_at_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_auto_cancel_at_enum>;
  _nin?: InputMaybe<Array<flow_auto_cancel_at_enum>>;
};

/** input type for inserting data into table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_insert_input = {
  flows?: InputMaybe<flow_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_auto_cancel_at_max_fields = {
  __typename: 'flow_auto_cancel_at_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_auto_cancel_at_min_fields = {
  __typename: 'flow_auto_cancel_at_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_mutation_response = {
  __typename: 'flow_auto_cancel_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_auto_cancel_at>;
};

/** input type for inserting object relation for remote table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_obj_rel_insert_input = {
  data: flow_auto_cancel_at_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_auto_cancel_at_on_conflict>;
};

/** on_conflict condition type for table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_on_conflict = {
  constraint: flow_auto_cancel_at_constraint;
  update_columns?: Array<flow_auto_cancel_at_update_column>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};

/** Ordering options when selecting data from "flow_auto_cancel_at". */
export type flow_auto_cancel_at_order_by = {
  flows_aggregate?: InputMaybe<flow_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_auto_cancel_at */
export type flow_auto_cancel_at_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_auto_cancel_at" */
export enum flow_auto_cancel_at_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_auto_cancel_at" */
export type flow_auto_cancel_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_auto_cancel_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_auto_cancel_at_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_auto_cancel_at" */
export enum flow_auto_cancel_at_update_column {
  /** column name */
  value = 'value'
}

export type flow_auto_cancel_at_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_auto_cancel_at_set_input>;
  /** filter the rows which have to be updated */
  where: flow_auto_cancel_at_bool_exp;
};

/** aggregate avg on columns */
export type flow_avg_fields = {
  __typename: 'flow_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow" */
export type flow_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow". All fields are combined with a logical 'AND'. */
export type flow_bool_exp = {
  _and?: InputMaybe<Array<flow_bool_exp>>;
  _not?: InputMaybe<flow_bool_exp>;
  _or?: InputMaybe<Array<flow_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  auto_cancel?: InputMaybe<Boolean_comparison_exp>;
  auto_cancel_at?: InputMaybe<flow_auto_cancel_at_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  css?: InputMaybe<String_comparison_exp>;
  default_flow_account?: InputMaybe<account_bool_exp>;
  default_language?: InputMaybe<language_enum_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<flow_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_auto_cancel_at?: InputMaybe<flow_auto_cancel_at_bool_exp>;
  flow_languages?: InputMaybe<flow_language_bool_exp>;
  flow_languages_aggregate?: InputMaybe<flow_language_aggregate_bool_exp>;
  flow_on_cancel_change_plans?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
  flow_on_cancel_change_plans_aggregate?: InputMaybe<flow_on_cancel_change_plan_aggregate_bool_exp>;
  flow_route_rule_flows?: InputMaybe<flow_route_rule_flow_bool_exp>;
  flow_route_rule_flows_aggregate?: InputMaybe<flow_route_rule_flow_aggregate_bool_exp>;
  flow_steps?: InputMaybe<flow_step_bool_exp>;
  flow_steps_aggregate?: InputMaybe<flow_step_aggregate_bool_exp>;
  flow_subscriber_form_questions?: InputMaybe<flow_subscriber_form_question_bool_exp>;
  flow_subscriber_form_questions_aggregate?: InputMaybe<flow_subscriber_form_question_aggregate_bool_exp>;
  flow_tags?: InputMaybe<flow_tag_bool_exp>;
  flow_tags_aggregate?: InputMaybe<flow_tag_aggregate_bool_exp>;
  flow_texts?: InputMaybe<flow_text_bool_exp>;
  flow_texts_aggregate?: InputMaybe<flow_text_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<language_bool_exp>;
  logo_url?: InputMaybe<String_comparison_exp>;
  most_recent_subscriber_flow_version?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
  on_cancel?: InputMaybe<flow_on_cancel_enum_comparison_exp>;
  on_cancel_perform_at?: InputMaybe<flow_on_cancel_perform_at_enum_comparison_exp>;
  prevent_if_canceled?: InputMaybe<Boolean_comparison_exp>;
  prevent_if_canceled_translation?: InputMaybe<translation_bool_exp>;
  prevent_if_canceled_translation_id?: InputMaybe<Int_comparison_exp>;
  preview_property_values?: InputMaybe<jsonb_comparison_exp>;
  published_version?: InputMaybe<flow_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  redirect_uri?: InputMaybe<String_comparison_exp>;
  secret?: InputMaybe<String_comparison_exp>;
  signature_required?: InputMaybe<Boolean_comparison_exp>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
  tag_ids?: InputMaybe<jsonb_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow" */
export enum flow_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_pkey = 'flow_pkey',
  /** unique or primary key constraint on columns "token" */
  flow_token_key = 'flow_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type flow_delete_at_path_input = {
  preview_property_values?: InputMaybe<Array<Scalars['String']['input']>>;
  tag_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type flow_delete_elem_input = {
  preview_property_values?: InputMaybe<Scalars['Int']['input']>;
  tag_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type flow_delete_key_input = {
  preview_property_values?: InputMaybe<Scalars['String']['input']>;
  tag_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "flow" */
export type flow_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  prevent_if_canceled_translation_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow" */
export type flow_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  auto_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  auto_cancel_at?: InputMaybe<flow_auto_cancel_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  default_flow_account?: InputMaybe<account_obj_rel_insert_input>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<flow_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_auto_cancel_at?: InputMaybe<flow_auto_cancel_at_obj_rel_insert_input>;
  flow_languages?: InputMaybe<flow_language_arr_rel_insert_input>;
  flow_on_cancel_change_plans?: InputMaybe<flow_on_cancel_change_plan_arr_rel_insert_input>;
  flow_route_rule_flows?: InputMaybe<flow_route_rule_flow_arr_rel_insert_input>;
  flow_steps?: InputMaybe<flow_step_arr_rel_insert_input>;
  flow_subscriber_form_questions?: InputMaybe<flow_subscriber_form_question_arr_rel_insert_input>;
  flow_tags?: InputMaybe<flow_tag_arr_rel_insert_input>;
  flow_texts?: InputMaybe<flow_text_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_obj_rel_insert_input>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  most_recent_subscriber_flow_version?: InputMaybe<most_recent_subscriber_flow_version_obj_rel_insert_input>;
  on_cancel?: InputMaybe<flow_on_cancel_enum>;
  on_cancel_perform_at?: InputMaybe<flow_on_cancel_perform_at_enum>;
  prevent_if_canceled?: InputMaybe<Scalars['Boolean']['input']>;
  prevent_if_canceled_translation?: InputMaybe<translation_obj_rel_insert_input>;
  prevent_if_canceled_translation_id?: InputMaybe<Scalars['Int']['input']>;
  preview_property_values?: InputMaybe<Scalars['jsonb']['input']>;
  published_version?: InputMaybe<flow_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_uri?: InputMaybe<Scalars['String']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "flow_language" */
export type flow_language = {
  __typename: 'flow_language';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  language: language_enum;
  /** An object relationship */
  languageByLanguage: language;
};

/** aggregated selection of "flow_language" */
export type flow_language_aggregate = {
  __typename: 'flow_language_aggregate';
  aggregate?: Maybe<flow_language_aggregate_fields>;
  nodes: Array<flow_language>;
};

export type flow_language_aggregate_bool_exp = {
  count?: InputMaybe<flow_language_aggregate_bool_exp_count>;
};

export type flow_language_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_language_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_language_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_language" */
export type flow_language_aggregate_fields = {
  __typename: 'flow_language_aggregate_fields';
  avg?: Maybe<flow_language_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_language_max_fields>;
  min?: Maybe<flow_language_min_fields>;
  stddev?: Maybe<flow_language_stddev_fields>;
  stddev_pop?: Maybe<flow_language_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_language_stddev_samp_fields>;
  sum?: Maybe<flow_language_sum_fields>;
  var_pop?: Maybe<flow_language_var_pop_fields>;
  var_samp?: Maybe<flow_language_var_samp_fields>;
  variance?: Maybe<flow_language_variance_fields>;
};


/** aggregate fields of "flow_language" */
export type flow_language_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_language_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_language" */
export type flow_language_aggregate_order_by = {
  avg?: InputMaybe<flow_language_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_language_max_order_by>;
  min?: InputMaybe<flow_language_min_order_by>;
  stddev?: InputMaybe<flow_language_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_language_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_language_stddev_samp_order_by>;
  sum?: InputMaybe<flow_language_sum_order_by>;
  var_pop?: InputMaybe<flow_language_var_pop_order_by>;
  var_samp?: InputMaybe<flow_language_var_samp_order_by>;
  variance?: InputMaybe<flow_language_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_language" */
export type flow_language_arr_rel_insert_input = {
  data: Array<flow_language_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_language_on_conflict>;
};

/** aggregate avg on columns */
export type flow_language_avg_fields = {
  __typename: 'flow_language_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_language" */
export type flow_language_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_language". All fields are combined with a logical 'AND'. */
export type flow_language_bool_exp = {
  _and?: InputMaybe<Array<flow_language_bool_exp>>;
  _not?: InputMaybe<flow_language_bool_exp>;
  _or?: InputMaybe<Array<flow_language_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<language_enum_comparison_exp>;
  languageByLanguage?: InputMaybe<language_bool_exp>;
};

/** unique or primary key constraints on table "flow_language" */
export enum flow_language_constraint {
  /** unique or primary key constraint on columns "language", "flow_id" */
  flow_language_pkey = 'flow_language_pkey'
}

/** input type for incrementing numeric columns in table "flow_language" */
export type flow_language_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_language" */
export type flow_language_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
  languageByLanguage?: InputMaybe<language_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type flow_language_max_fields = {
  __typename: 'flow_language_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_language" */
export type flow_language_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_language_min_fields = {
  __typename: 'flow_language_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_language" */
export type flow_language_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_language" */
export type flow_language_mutation_response = {
  __typename: 'flow_language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_language>;
};

/** on_conflict condition type for table "flow_language" */
export type flow_language_on_conflict = {
  constraint: flow_language_constraint;
  update_columns?: Array<flow_language_update_column>;
  where?: InputMaybe<flow_language_bool_exp>;
};

/** Ordering options when selecting data from "flow_language". */
export type flow_language_order_by = {
  created_at?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  languageByLanguage?: InputMaybe<language_order_by>;
};

/** primary key columns input for table: flow_language */
export type flow_language_pk_columns_input = {
  flow_id: Scalars['Int']['input'];
  language: language_enum;
};

/** select columns of table "flow_language" */
export enum flow_language_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  language = 'language'
}

/** input type for updating data in table "flow_language" */
export type flow_language_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
};

/** aggregate stddev on columns */
export type flow_language_stddev_fields = {
  __typename: 'flow_language_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_language" */
export type flow_language_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_language_stddev_pop_fields = {
  __typename: 'flow_language_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_language" */
export type flow_language_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_language_stddev_samp_fields = {
  __typename: 'flow_language_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_language" */
export type flow_language_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_language" */
export type flow_language_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_language_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_language_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
};

/** aggregate sum on columns */
export type flow_language_sum_fields = {
  __typename: 'flow_language_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_language" */
export type flow_language_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_language" */
export enum flow_language_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  language = 'language'
}

export type flow_language_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_language_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_language_set_input>;
  /** filter the rows which have to be updated */
  where: flow_language_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_language_var_pop_fields = {
  __typename: 'flow_language_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_language" */
export type flow_language_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_language_var_samp_fields = {
  __typename: 'flow_language_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_language" */
export type flow_language_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_language_variance_fields = {
  __typename: 'flow_language_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_language" */
export type flow_language_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type flow_max_fields = {
  __typename: 'flow_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  redirect_uri?: Maybe<Scalars['String']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow" */
export type flow_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  redirect_uri?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_min_fields = {
  __typename: 'flow_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  logo_url?: Maybe<Scalars['String']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  redirect_uri?: Maybe<Scalars['String']['output']>;
  secret?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow" */
export type flow_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  logo_url?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  redirect_uri?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow" */
export type flow_mutation_response = {
  __typename: 'flow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow>;
};

/** input type for inserting object relation for remote table "flow" */
export type flow_obj_rel_insert_input = {
  data: flow_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_on_conflict>;
};

/** columns and relationships of "flow_on_cancel" */
export type flow_on_cancel = {
  __typename: 'flow_on_cancel';
  value: Scalars['String']['output'];
};

/** aggregated selection of "flow_on_cancel" */
export type flow_on_cancel_aggregate = {
  __typename: 'flow_on_cancel_aggregate';
  aggregate?: Maybe<flow_on_cancel_aggregate_fields>;
  nodes: Array<flow_on_cancel>;
};

/** aggregate fields of "flow_on_cancel" */
export type flow_on_cancel_aggregate_fields = {
  __typename: 'flow_on_cancel_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_on_cancel_max_fields>;
  min?: Maybe<flow_on_cancel_min_fields>;
};


/** aggregate fields of "flow_on_cancel" */
export type flow_on_cancel_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_on_cancel_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_on_cancel". All fields are combined with a logical 'AND'. */
export type flow_on_cancel_bool_exp = {
  _and?: InputMaybe<Array<flow_on_cancel_bool_exp>>;
  _not?: InputMaybe<flow_on_cancel_bool_exp>;
  _or?: InputMaybe<Array<flow_on_cancel_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** columns and relationships of "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan = {
  __typename: 'flow_on_cancel_change_plan';
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  prorate: Scalars['Boolean']['output'];
  remove_add_ons: Scalars['Boolean']['output'];
};

/** aggregated selection of "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_aggregate = {
  __typename: 'flow_on_cancel_change_plan_aggregate';
  aggregate?: Maybe<flow_on_cancel_change_plan_aggregate_fields>;
  nodes: Array<flow_on_cancel_change_plan>;
};

export type flow_on_cancel_change_plan_aggregate_bool_exp = {
  bool_and?: InputMaybe<flow_on_cancel_change_plan_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<flow_on_cancel_change_plan_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<flow_on_cancel_change_plan_aggregate_bool_exp_count>;
};

export type flow_on_cancel_change_plan_aggregate_bool_exp_bool_and = {
  arguments: flow_on_cancel_change_plan_select_column_flow_on_cancel_change_plan_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_on_cancel_change_plan_aggregate_bool_exp_bool_or = {
  arguments: flow_on_cancel_change_plan_select_column_flow_on_cancel_change_plan_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_on_cancel_change_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_aggregate_fields = {
  __typename: 'flow_on_cancel_change_plan_aggregate_fields';
  avg?: Maybe<flow_on_cancel_change_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_on_cancel_change_plan_max_fields>;
  min?: Maybe<flow_on_cancel_change_plan_min_fields>;
  stddev?: Maybe<flow_on_cancel_change_plan_stddev_fields>;
  stddev_pop?: Maybe<flow_on_cancel_change_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_on_cancel_change_plan_stddev_samp_fields>;
  sum?: Maybe<flow_on_cancel_change_plan_sum_fields>;
  var_pop?: Maybe<flow_on_cancel_change_plan_var_pop_fields>;
  var_samp?: Maybe<flow_on_cancel_change_plan_var_samp_fields>;
  variance?: Maybe<flow_on_cancel_change_plan_variance_fields>;
};


/** aggregate fields of "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_aggregate_order_by = {
  avg?: InputMaybe<flow_on_cancel_change_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_on_cancel_change_plan_max_order_by>;
  min?: InputMaybe<flow_on_cancel_change_plan_min_order_by>;
  stddev?: InputMaybe<flow_on_cancel_change_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_on_cancel_change_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_on_cancel_change_plan_stddev_samp_order_by>;
  sum?: InputMaybe<flow_on_cancel_change_plan_sum_order_by>;
  var_pop?: InputMaybe<flow_on_cancel_change_plan_var_pop_order_by>;
  var_samp?: InputMaybe<flow_on_cancel_change_plan_var_samp_order_by>;
  variance?: InputMaybe<flow_on_cancel_change_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_arr_rel_insert_input = {
  data: Array<flow_on_cancel_change_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_on_cancel_change_plan_on_conflict>;
};

/** aggregate avg on columns */
export type flow_on_cancel_change_plan_avg_fields = {
  __typename: 'flow_on_cancel_change_plan_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_on_cancel_change_plan". All fields are combined with a logical 'AND'. */
export type flow_on_cancel_change_plan_bool_exp = {
  _and?: InputMaybe<Array<flow_on_cancel_change_plan_bool_exp>>;
  _not?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
  _or?: InputMaybe<Array<flow_on_cancel_change_plan_bool_exp>>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  prorate?: InputMaybe<Boolean_comparison_exp>;
  remove_add_ons?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "flow_on_cancel_change_plan" */
export enum flow_on_cancel_change_plan_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "flow_id" */
  flow_on_cancel_change_plan_pkey = 'flow_on_cancel_change_plan_pkey'
}

/** input type for incrementing numeric columns in table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_insert_input = {
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  remove_add_ons?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type flow_on_cancel_change_plan_max_fields = {
  __typename: 'flow_on_cancel_change_plan_max_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_max_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_on_cancel_change_plan_min_fields = {
  __typename: 'flow_on_cancel_change_plan_min_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_min_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_mutation_response = {
  __typename: 'flow_on_cancel_change_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_on_cancel_change_plan>;
};

/** on_conflict condition type for table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_on_conflict = {
  constraint: flow_on_cancel_change_plan_constraint;
  update_columns?: Array<flow_on_cancel_change_plan_update_column>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};

/** Ordering options when selecting data from "flow_on_cancel_change_plan". */
export type flow_on_cancel_change_plan_order_by = {
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  prorate?: InputMaybe<order_by>;
  remove_add_ons?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_on_cancel_change_plan */
export type flow_on_cancel_change_plan_pk_columns_input = {
  flow_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
};

/** select columns of table "flow_on_cancel_change_plan" */
export enum flow_on_cancel_change_plan_select_column {
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  prorate = 'prorate',
  /** column name */
  remove_add_ons = 'remove_add_ons'
}

/** select "flow_on_cancel_change_plan_aggregate_bool_exp_bool_and_arguments_columns" columns of table "flow_on_cancel_change_plan" */
export enum flow_on_cancel_change_plan_select_column_flow_on_cancel_change_plan_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  prorate = 'prorate',
  /** column name */
  remove_add_ons = 'remove_add_ons'
}

/** select "flow_on_cancel_change_plan_aggregate_bool_exp_bool_or_arguments_columns" columns of table "flow_on_cancel_change_plan" */
export enum flow_on_cancel_change_plan_select_column_flow_on_cancel_change_plan_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  prorate = 'prorate',
  /** column name */
  remove_add_ons = 'remove_add_ons'
}

/** input type for updating data in table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_set_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  remove_add_ons?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type flow_on_cancel_change_plan_stddev_fields = {
  __typename: 'flow_on_cancel_change_plan_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_on_cancel_change_plan_stddev_pop_fields = {
  __typename: 'flow_on_cancel_change_plan_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_on_cancel_change_plan_stddev_samp_fields = {
  __typename: 'flow_on_cancel_change_plan_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_on_cancel_change_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_on_cancel_change_plan_stream_cursor_value_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  remove_add_ons?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type flow_on_cancel_change_plan_sum_fields = {
  __typename: 'flow_on_cancel_change_plan_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_on_cancel_change_plan" */
export enum flow_on_cancel_change_plan_update_column {
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  prorate = 'prorate',
  /** column name */
  remove_add_ons = 'remove_add_ons'
}

export type flow_on_cancel_change_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_on_cancel_change_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_on_cancel_change_plan_set_input>;
  /** filter the rows which have to be updated */
  where: flow_on_cancel_change_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_on_cancel_change_plan_var_pop_fields = {
  __typename: 'flow_on_cancel_change_plan_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_on_cancel_change_plan_var_samp_fields = {
  __typename: 'flow_on_cancel_change_plan_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_on_cancel_change_plan_variance_fields = {
  __typename: 'flow_on_cancel_change_plan_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_on_cancel_change_plan" */
export type flow_on_cancel_change_plan_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "flow_on_cancel" */
export enum flow_on_cancel_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_on_cancel_pkey = 'flow_on_cancel_pkey'
}

export enum flow_on_cancel_enum {
  cancel_subscription = 'cancel_subscription',
  change_subscription_plan = 'change_subscription_plan',
  do_nothing = 'do_nothing'
}

/** Boolean expression to compare columns of type "flow_on_cancel_enum". All fields are combined with logical 'AND'. */
export type flow_on_cancel_enum_comparison_exp = {
  _eq?: InputMaybe<flow_on_cancel_enum>;
  _in?: InputMaybe<Array<flow_on_cancel_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_on_cancel_enum>;
  _nin?: InputMaybe<Array<flow_on_cancel_enum>>;
};

/** input type for inserting data into table "flow_on_cancel" */
export type flow_on_cancel_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_on_cancel_max_fields = {
  __typename: 'flow_on_cancel_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_on_cancel_min_fields = {
  __typename: 'flow_on_cancel_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_on_cancel" */
export type flow_on_cancel_mutation_response = {
  __typename: 'flow_on_cancel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_on_cancel>;
};

/** on_conflict condition type for table "flow_on_cancel" */
export type flow_on_cancel_on_conflict = {
  constraint: flow_on_cancel_constraint;
  update_columns?: Array<flow_on_cancel_update_column>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};

/** Ordering options when selecting data from "flow_on_cancel". */
export type flow_on_cancel_order_by = {
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at = {
  __typename: 'flow_on_cancel_perform_at';
  value: Scalars['String']['output'];
};

/** aggregated selection of "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_aggregate = {
  __typename: 'flow_on_cancel_perform_at_aggregate';
  aggregate?: Maybe<flow_on_cancel_perform_at_aggregate_fields>;
  nodes: Array<flow_on_cancel_perform_at>;
};

/** aggregate fields of "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_aggregate_fields = {
  __typename: 'flow_on_cancel_perform_at_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_on_cancel_perform_at_max_fields>;
  min?: Maybe<flow_on_cancel_perform_at_min_fields>;
};


/** aggregate fields of "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_on_cancel_perform_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_on_cancel_perform_at". All fields are combined with a logical 'AND'. */
export type flow_on_cancel_perform_at_bool_exp = {
  _and?: InputMaybe<Array<flow_on_cancel_perform_at_bool_exp>>;
  _not?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
  _or?: InputMaybe<Array<flow_on_cancel_perform_at_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_on_cancel_perform_at" */
export enum flow_on_cancel_perform_at_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_on_cancel_perform_at_pkey = 'flow_on_cancel_perform_at_pkey'
}

export enum flow_on_cancel_perform_at_enum {
  immediately = 'immediately',
  period_end = 'period_end'
}

/** Boolean expression to compare columns of type "flow_on_cancel_perform_at_enum". All fields are combined with logical 'AND'. */
export type flow_on_cancel_perform_at_enum_comparison_exp = {
  _eq?: InputMaybe<flow_on_cancel_perform_at_enum>;
  _in?: InputMaybe<Array<flow_on_cancel_perform_at_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_on_cancel_perform_at_enum>;
  _nin?: InputMaybe<Array<flow_on_cancel_perform_at_enum>>;
};

/** input type for inserting data into table "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_on_cancel_perform_at_max_fields = {
  __typename: 'flow_on_cancel_perform_at_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_on_cancel_perform_at_min_fields = {
  __typename: 'flow_on_cancel_perform_at_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_mutation_response = {
  __typename: 'flow_on_cancel_perform_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_on_cancel_perform_at>;
};

/** on_conflict condition type for table "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_on_conflict = {
  constraint: flow_on_cancel_perform_at_constraint;
  update_columns?: Array<flow_on_cancel_perform_at_update_column>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};

/** Ordering options when selecting data from "flow_on_cancel_perform_at". */
export type flow_on_cancel_perform_at_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_on_cancel_perform_at */
export type flow_on_cancel_perform_at_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_on_cancel_perform_at" */
export enum flow_on_cancel_perform_at_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_on_cancel_perform_at" */
export type flow_on_cancel_perform_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_on_cancel_perform_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_on_cancel_perform_at_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_on_cancel_perform_at" */
export enum flow_on_cancel_perform_at_update_column {
  /** column name */
  value = 'value'
}

export type flow_on_cancel_perform_at_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_on_cancel_perform_at_set_input>;
  /** filter the rows which have to be updated */
  where: flow_on_cancel_perform_at_bool_exp;
};

/** primary key columns input for table: flow_on_cancel */
export type flow_on_cancel_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_on_cancel" */
export enum flow_on_cancel_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_on_cancel" */
export type flow_on_cancel_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_on_cancel" */
export type flow_on_cancel_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_on_cancel_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_on_cancel_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_on_cancel" */
export enum flow_on_cancel_update_column {
  /** column name */
  value = 'value'
}

export type flow_on_cancel_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_on_cancel_set_input>;
  /** filter the rows which have to be updated */
  where: flow_on_cancel_bool_exp;
};

/** on_conflict condition type for table "flow" */
export type flow_on_conflict = {
  constraint: flow_constraint;
  update_columns?: Array<flow_update_column>;
  where?: InputMaybe<flow_bool_exp>;
};

/** Ordering options when selecting data from "flow". */
export type flow_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  auto_cancel?: InputMaybe<order_by>;
  auto_cancel_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  default_flow_account?: InputMaybe<account_order_by>;
  default_language?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<flow_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_auto_cancel_at?: InputMaybe<flow_auto_cancel_at_order_by>;
  flow_languages_aggregate?: InputMaybe<flow_language_aggregate_order_by>;
  flow_on_cancel_change_plans_aggregate?: InputMaybe<flow_on_cancel_change_plan_aggregate_order_by>;
  flow_route_rule_flows_aggregate?: InputMaybe<flow_route_rule_flow_aggregate_order_by>;
  flow_steps_aggregate?: InputMaybe<flow_step_aggregate_order_by>;
  flow_subscriber_form_questions_aggregate?: InputMaybe<flow_subscriber_form_question_aggregate_order_by>;
  flow_tags_aggregate?: InputMaybe<flow_tag_aggregate_order_by>;
  flow_texts_aggregate?: InputMaybe<flow_text_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<language_order_by>;
  logo_url?: InputMaybe<order_by>;
  most_recent_subscriber_flow_version?: InputMaybe<most_recent_subscriber_flow_version_order_by>;
  on_cancel?: InputMaybe<order_by>;
  on_cancel_perform_at?: InputMaybe<order_by>;
  prevent_if_canceled?: InputMaybe<order_by>;
  prevent_if_canceled_translation?: InputMaybe<translation_order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  preview_property_values?: InputMaybe<order_by>;
  published_version?: InputMaybe<flow_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  redirect_uri?: InputMaybe<order_by>;
  secret?: InputMaybe<order_by>;
  signature_required?: InputMaybe<order_by>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
  tag_ids?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow */
export type flow_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type flow_prepend_input = {
  preview_property_values?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "flow_route_rule" */
export type flow_route_rule = {
  __typename: 'flow_route_rule';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  eligibility_message_ids: Scalars['jsonb']['output'];
  flow_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  flow_route_rule_eligibility_messages: Array<flow_route_rule_eligibility_message>;
  /** An aggregate relationship */
  flow_route_rule_eligibility_messages_aggregate: flow_route_rule_eligibility_message_aggregate;
  /** An array relationship */
  flow_route_rule_flow_tests: Array<flow_route_rule_flow_test>;
  /** An aggregate relationship */
  flow_route_rule_flow_tests_aggregate: flow_route_rule_flow_test_aggregate;
  /** An array relationship */
  flow_route_rule_flows: Array<flow_route_rule_flow>;
  /** An aggregate relationship */
  flow_route_rule_flows_aggregate: flow_route_rule_flow_aggregate;
  /** An array relationship */
  flow_route_rule_segment_groups: Array<flow_route_rule_segment_group>;
  /** An aggregate relationship */
  flow_route_rule_segment_groups_aggregate: flow_route_rule_segment_group_aggregate;
  /** An array relationship */
  flow_route_rule_segments: Array<flow_route_rule_segment>;
  /** An aggregate relationship */
  flow_route_rule_segments_aggregate: flow_route_rule_segment_aggregate;
  flow_test_ids: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  segment_group_ids: Scalars['jsonb']['output'];
  segment_ids: Scalars['jsonb']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleeligibility_message_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_eligibility_messagesArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_eligibility_messages_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_flow_testsArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_flow_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_flowsArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_segment_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_segment_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_segmentsArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_route_rule_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_ruleflow_test_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_rulesegment_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_route_rule" */
export type flow_route_rulesegment_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "flow_route_rule" */
export type flow_route_rule_aggregate = {
  __typename: 'flow_route_rule_aggregate';
  aggregate?: Maybe<flow_route_rule_aggregate_fields>;
  nodes: Array<flow_route_rule>;
};

export type flow_route_rule_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_aggregate_bool_exp_count>;
};

export type flow_route_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule" */
export type flow_route_rule_aggregate_fields = {
  __typename: 'flow_route_rule_aggregate_fields';
  avg?: Maybe<flow_route_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_max_fields>;
  min?: Maybe<flow_route_rule_min_fields>;
  stddev?: Maybe<flow_route_rule_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_sum_fields>;
  var_pop?: Maybe<flow_route_rule_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_var_samp_fields>;
  variance?: Maybe<flow_route_rule_variance_fields>;
};


/** aggregate fields of "flow_route_rule" */
export type flow_route_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule" */
export type flow_route_rule_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_max_order_by>;
  min?: InputMaybe<flow_route_rule_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type flow_route_rule_append_input = {
  eligibility_message_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "flow_route_rule" */
export type flow_route_rule_arr_rel_insert_input = {
  data: Array<flow_route_rule_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_route_rule_on_conflict>;
};

/** aggregate avg on columns */
export type flow_route_rule_avg_fields = {
  __typename: 'flow_route_rule_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule" */
export type flow_route_rule_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule". All fields are combined with a logical 'AND'. */
export type flow_route_rule_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  eligibility_message_ids?: InputMaybe<jsonb_comparison_exp>;
  flow_ids?: InputMaybe<jsonb_comparison_exp>;
  flow_route_rule_eligibility_messages?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
  flow_route_rule_eligibility_messages_aggregate?: InputMaybe<flow_route_rule_eligibility_message_aggregate_bool_exp>;
  flow_route_rule_flow_tests?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
  flow_route_rule_flow_tests_aggregate?: InputMaybe<flow_route_rule_flow_test_aggregate_bool_exp>;
  flow_route_rule_flows?: InputMaybe<flow_route_rule_flow_bool_exp>;
  flow_route_rule_flows_aggregate?: InputMaybe<flow_route_rule_flow_aggregate_bool_exp>;
  flow_route_rule_segment_groups?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
  flow_route_rule_segment_groups_aggregate?: InputMaybe<flow_route_rule_segment_group_aggregate_bool_exp>;
  flow_route_rule_segments?: InputMaybe<flow_route_rule_segment_bool_exp>;
  flow_route_rule_segments_aggregate?: InputMaybe<flow_route_rule_segment_aggregate_bool_exp>;
  flow_test_ids?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment_group_ids?: InputMaybe<jsonb_comparison_exp>;
  segment_ids?: InputMaybe<jsonb_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow_route_rule" */
export enum flow_route_rule_constraint {
  /** unique or primary key constraint on columns "account_id", "position" */
  flow_route_rule_account_id_position_key = 'flow_route_rule_account_id_position_key',
  /** unique or primary key constraint on columns "id" */
  flow_route_rule_pkey = 'flow_route_rule_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type flow_route_rule_delete_at_path_input = {
  eligibility_message_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  flow_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  flow_test_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  segment_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  segment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type flow_route_rule_delete_elem_input = {
  eligibility_message_ids?: InputMaybe<Scalars['Int']['input']>;
  flow_ids?: InputMaybe<Scalars['Int']['input']>;
  flow_test_ids?: InputMaybe<Scalars['Int']['input']>;
  segment_group_ids?: InputMaybe<Scalars['Int']['input']>;
  segment_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type flow_route_rule_delete_key_input = {
  eligibility_message_ids?: InputMaybe<Scalars['String']['input']>;
  flow_ids?: InputMaybe<Scalars['String']['input']>;
  flow_test_ids?: InputMaybe<Scalars['String']['input']>;
  segment_group_ids?: InputMaybe<Scalars['String']['input']>;
  segment_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message = {
  __typename: 'flow_route_rule_eligibility_message';
  /** An object relationship */
  eligibility_message?: Maybe<eligibility_message>;
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_route_rule?: Maybe<flow_route_rule>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_aggregate = {
  __typename: 'flow_route_rule_eligibility_message_aggregate';
  aggregate?: Maybe<flow_route_rule_eligibility_message_aggregate_fields>;
  nodes: Array<flow_route_rule_eligibility_message>;
};

export type flow_route_rule_eligibility_message_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_eligibility_message_aggregate_bool_exp_count>;
};

export type flow_route_rule_eligibility_message_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_aggregate_fields = {
  __typename: 'flow_route_rule_eligibility_message_aggregate_fields';
  avg?: Maybe<flow_route_rule_eligibility_message_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_eligibility_message_max_fields>;
  min?: Maybe<flow_route_rule_eligibility_message_min_fields>;
  stddev?: Maybe<flow_route_rule_eligibility_message_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_eligibility_message_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_eligibility_message_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_eligibility_message_sum_fields>;
  var_pop?: Maybe<flow_route_rule_eligibility_message_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_eligibility_message_var_samp_fields>;
  variance?: Maybe<flow_route_rule_eligibility_message_variance_fields>;
};


/** aggregate fields of "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_eligibility_message_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_eligibility_message_max_order_by>;
  min?: InputMaybe<flow_route_rule_eligibility_message_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_eligibility_message_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_eligibility_message_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_eligibility_message_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_eligibility_message_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_eligibility_message_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_eligibility_message_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_eligibility_message_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_arr_rel_insert_input = {
  data: Array<flow_route_rule_eligibility_message_insert_input>;
};

/** aggregate avg on columns */
export type flow_route_rule_eligibility_message_avg_fields = {
  __typename: 'flow_route_rule_eligibility_message_avg_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_avg_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule_eligibility_message". All fields are combined with a logical 'AND'. */
export type flow_route_rule_eligibility_message_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_eligibility_message_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_eligibility_message_bool_exp>>;
  eligibility_message?: InputMaybe<eligibility_message_bool_exp>;
  eligibility_message_id?: InputMaybe<Int_comparison_exp>;
  flow_route_rule?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_insert_input = {
  eligibility_message?: InputMaybe<eligibility_message_obj_rel_insert_input>;
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow_route_rule?: InputMaybe<flow_route_rule_obj_rel_insert_input>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_eligibility_message_max_fields = {
  __typename: 'flow_route_rule_eligibility_message_max_fields';
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_max_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_eligibility_message_min_fields = {
  __typename: 'flow_route_rule_eligibility_message_min_fields';
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_min_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_route_rule_eligibility_message". */
export type flow_route_rule_eligibility_message_order_by = {
  eligibility_message?: InputMaybe<eligibility_message_order_by>;
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule?: InputMaybe<flow_route_rule_order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule_eligibility_message" */
export enum flow_route_rule_eligibility_message_select_column {
  /** column name */
  eligibility_message_id = 'eligibility_message_id',
  /** column name */
  flow_route_rule_id = 'flow_route_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type flow_route_rule_eligibility_message_stddev_fields = {
  __typename: 'flow_route_rule_eligibility_message_stddev_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_stddev_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_eligibility_message_stddev_pop_fields = {
  __typename: 'flow_route_rule_eligibility_message_stddev_pop_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_stddev_pop_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_eligibility_message_stddev_samp_fields = {
  __typename: 'flow_route_rule_eligibility_message_stddev_samp_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_stddev_samp_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_eligibility_message_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_eligibility_message_stream_cursor_value_input = {
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_eligibility_message_sum_fields = {
  __typename: 'flow_route_rule_eligibility_message_sum_fields';
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_sum_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_route_rule_eligibility_message_var_pop_fields = {
  __typename: 'flow_route_rule_eligibility_message_var_pop_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_var_pop_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_eligibility_message_var_samp_fields = {
  __typename: 'flow_route_rule_eligibility_message_var_samp_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_var_samp_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_eligibility_message_variance_fields = {
  __typename: 'flow_route_rule_eligibility_message_variance_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule_eligibility_message" */
export type flow_route_rule_eligibility_message_variance_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_route_rule_flow" */
export type flow_route_rule_flow = {
  __typename: 'flow_route_rule_flow';
  /** An object relationship */
  flow?: Maybe<flow>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_route_rule?: Maybe<flow_route_rule>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_route_rule_flow" */
export type flow_route_rule_flow_aggregate = {
  __typename: 'flow_route_rule_flow_aggregate';
  aggregate?: Maybe<flow_route_rule_flow_aggregate_fields>;
  nodes: Array<flow_route_rule_flow>;
};

export type flow_route_rule_flow_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_flow_aggregate_bool_exp_count>;
};

export type flow_route_rule_flow_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_flow_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule_flow" */
export type flow_route_rule_flow_aggregate_fields = {
  __typename: 'flow_route_rule_flow_aggregate_fields';
  avg?: Maybe<flow_route_rule_flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_flow_max_fields>;
  min?: Maybe<flow_route_rule_flow_min_fields>;
  stddev?: Maybe<flow_route_rule_flow_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_flow_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_flow_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_flow_sum_fields>;
  var_pop?: Maybe<flow_route_rule_flow_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_flow_var_samp_fields>;
  variance?: Maybe<flow_route_rule_flow_variance_fields>;
};


/** aggregate fields of "flow_route_rule_flow" */
export type flow_route_rule_flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule_flow" */
export type flow_route_rule_flow_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_flow_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_flow_max_order_by>;
  min?: InputMaybe<flow_route_rule_flow_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_flow_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_flow_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_flow_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_flow_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_flow_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_flow_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_flow_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_route_rule_flow" */
export type flow_route_rule_flow_arr_rel_insert_input = {
  data: Array<flow_route_rule_flow_insert_input>;
};

/** aggregate avg on columns */
export type flow_route_rule_flow_avg_fields = {
  __typename: 'flow_route_rule_flow_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule_flow". All fields are combined with a logical 'AND'. */
export type flow_route_rule_flow_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_flow_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_flow_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_flow_bool_exp>>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_route_rule?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_route_rule_flow" */
export type flow_route_rule_flow_insert_input = {
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_route_rule?: InputMaybe<flow_route_rule_obj_rel_insert_input>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_flow_max_fields = {
  __typename: 'flow_route_rule_flow_max_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_max_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_flow_min_fields = {
  __typename: 'flow_route_rule_flow_min_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_min_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_route_rule_flow". */
export type flow_route_rule_flow_order_by = {
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_route_rule?: InputMaybe<flow_route_rule_order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule_flow" */
export enum flow_route_rule_flow_select_column {
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_route_rule_id = 'flow_route_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type flow_route_rule_flow_stddev_fields = {
  __typename: 'flow_route_rule_flow_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_flow_stddev_pop_fields = {
  __typename: 'flow_route_rule_flow_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_flow_stddev_samp_fields = {
  __typename: 'flow_route_rule_flow_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule_flow" */
export type flow_route_rule_flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_flow_stream_cursor_value_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_flow_sum_fields = {
  __typename: 'flow_route_rule_flow_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test = {
  __typename: 'flow_route_rule_flow_test';
  /** An object relationship */
  flow_route_rule?: Maybe<flow_route_rule>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_test?: Maybe<flow_test>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_aggregate = {
  __typename: 'flow_route_rule_flow_test_aggregate';
  aggregate?: Maybe<flow_route_rule_flow_test_aggregate_fields>;
  nodes: Array<flow_route_rule_flow_test>;
};

export type flow_route_rule_flow_test_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_flow_test_aggregate_bool_exp_count>;
};

export type flow_route_rule_flow_test_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_aggregate_fields = {
  __typename: 'flow_route_rule_flow_test_aggregate_fields';
  avg?: Maybe<flow_route_rule_flow_test_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_flow_test_max_fields>;
  min?: Maybe<flow_route_rule_flow_test_min_fields>;
  stddev?: Maybe<flow_route_rule_flow_test_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_flow_test_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_flow_test_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_flow_test_sum_fields>;
  var_pop?: Maybe<flow_route_rule_flow_test_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_flow_test_var_samp_fields>;
  variance?: Maybe<flow_route_rule_flow_test_variance_fields>;
};


/** aggregate fields of "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_flow_test_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_flow_test_max_order_by>;
  min?: InputMaybe<flow_route_rule_flow_test_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_flow_test_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_flow_test_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_flow_test_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_flow_test_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_flow_test_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_flow_test_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_flow_test_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_arr_rel_insert_input = {
  data: Array<flow_route_rule_flow_test_insert_input>;
};

/** aggregate avg on columns */
export type flow_route_rule_flow_test_avg_fields = {
  __typename: 'flow_route_rule_flow_test_avg_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_avg_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule_flow_test". All fields are combined with a logical 'AND'. */
export type flow_route_rule_flow_test_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_flow_test_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_flow_test_bool_exp>>;
  flow_route_rule?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rule_id?: InputMaybe<Int_comparison_exp>;
  flow_test?: InputMaybe<flow_test_bool_exp>;
  flow_test_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_insert_input = {
  flow_route_rule?: InputMaybe<flow_route_rule_obj_rel_insert_input>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test?: InputMaybe<flow_test_obj_rel_insert_input>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_flow_test_max_fields = {
  __typename: 'flow_route_rule_flow_test_max_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_max_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_flow_test_min_fields = {
  __typename: 'flow_route_rule_flow_test_min_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_min_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_route_rule_flow_test". */
export type flow_route_rule_flow_test_order_by = {
  flow_route_rule?: InputMaybe<flow_route_rule_order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test?: InputMaybe<flow_test_order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule_flow_test" */
export enum flow_route_rule_flow_test_select_column {
  /** column name */
  flow_route_rule_id = 'flow_route_rule_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type flow_route_rule_flow_test_stddev_fields = {
  __typename: 'flow_route_rule_flow_test_stddev_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_stddev_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_flow_test_stddev_pop_fields = {
  __typename: 'flow_route_rule_flow_test_stddev_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_stddev_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_flow_test_stddev_samp_fields = {
  __typename: 'flow_route_rule_flow_test_stddev_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_stddev_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_flow_test_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_flow_test_stream_cursor_value_input = {
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_flow_test_sum_fields = {
  __typename: 'flow_route_rule_flow_test_sum_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_sum_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_route_rule_flow_test_var_pop_fields = {
  __typename: 'flow_route_rule_flow_test_var_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_var_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_flow_test_var_samp_fields = {
  __typename: 'flow_route_rule_flow_test_var_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_var_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_flow_test_variance_fields = {
  __typename: 'flow_route_rule_flow_test_variance_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule_flow_test" */
export type flow_route_rule_flow_test_variance_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_route_rule_flow_var_pop_fields = {
  __typename: 'flow_route_rule_flow_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_flow_var_samp_fields = {
  __typename: 'flow_route_rule_flow_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_flow_variance_fields = {
  __typename: 'flow_route_rule_flow_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule_flow" */
export type flow_route_rule_flow_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "flow_route_rule" */
export type flow_route_rule_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_route_rule" */
export type flow_route_rule_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_route_rule_eligibility_messages?: InputMaybe<flow_route_rule_eligibility_message_arr_rel_insert_input>;
  flow_route_rule_flow_tests?: InputMaybe<flow_route_rule_flow_test_arr_rel_insert_input>;
  flow_route_rule_flows?: InputMaybe<flow_route_rule_flow_arr_rel_insert_input>;
  flow_route_rule_segment_groups?: InputMaybe<flow_route_rule_segment_group_arr_rel_insert_input>;
  flow_route_rule_segments?: InputMaybe<flow_route_rule_segment_arr_rel_insert_input>;
  flow_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_max_fields = {
  __typename: 'flow_route_rule_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow_route_rule" */
export type flow_route_rule_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_min_fields = {
  __typename: 'flow_route_rule_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow_route_rule" */
export type flow_route_rule_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_route_rule" */
export type flow_route_rule_mutation_response = {
  __typename: 'flow_route_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_route_rule>;
};

/** input type for inserting object relation for remote table "flow_route_rule" */
export type flow_route_rule_obj_rel_insert_input = {
  data: flow_route_rule_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_route_rule_on_conflict>;
};

/** on_conflict condition type for table "flow_route_rule" */
export type flow_route_rule_on_conflict = {
  constraint: flow_route_rule_constraint;
  update_columns?: Array<flow_route_rule_update_column>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};

/** Ordering options when selecting data from "flow_route_rule". */
export type flow_route_rule_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  eligibility_message_ids?: InputMaybe<order_by>;
  flow_ids?: InputMaybe<order_by>;
  flow_route_rule_eligibility_messages_aggregate?: InputMaybe<flow_route_rule_eligibility_message_aggregate_order_by>;
  flow_route_rule_flow_tests_aggregate?: InputMaybe<flow_route_rule_flow_test_aggregate_order_by>;
  flow_route_rule_flows_aggregate?: InputMaybe<flow_route_rule_flow_aggregate_order_by>;
  flow_route_rule_segment_groups_aggregate?: InputMaybe<flow_route_rule_segment_group_aggregate_order_by>;
  flow_route_rule_segments_aggregate?: InputMaybe<flow_route_rule_segment_aggregate_order_by>;
  flow_test_ids?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_ids?: InputMaybe<order_by>;
  segment_ids?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_route_rule */
export type flow_route_rule_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type flow_route_rule_prepend_input = {
  eligibility_message_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "flow_route_rule_segment" */
export type flow_route_rule_segment = {
  __typename: 'flow_route_rule_segment';
  /** An object relationship */
  flow_route_rule?: Maybe<flow_route_rule>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment?: Maybe<segment>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_route_rule_segment" */
export type flow_route_rule_segment_aggregate = {
  __typename: 'flow_route_rule_segment_aggregate';
  aggregate?: Maybe<flow_route_rule_segment_aggregate_fields>;
  nodes: Array<flow_route_rule_segment>;
};

export type flow_route_rule_segment_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_segment_aggregate_bool_exp_count>;
};

export type flow_route_rule_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule_segment" */
export type flow_route_rule_segment_aggregate_fields = {
  __typename: 'flow_route_rule_segment_aggregate_fields';
  avg?: Maybe<flow_route_rule_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_segment_max_fields>;
  min?: Maybe<flow_route_rule_segment_min_fields>;
  stddev?: Maybe<flow_route_rule_segment_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_segment_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_segment_sum_fields>;
  var_pop?: Maybe<flow_route_rule_segment_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_segment_var_samp_fields>;
  variance?: Maybe<flow_route_rule_segment_variance_fields>;
};


/** aggregate fields of "flow_route_rule_segment" */
export type flow_route_rule_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule_segment" */
export type flow_route_rule_segment_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_segment_max_order_by>;
  min?: InputMaybe<flow_route_rule_segment_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_segment_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_segment_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_segment_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_segment_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_route_rule_segment" */
export type flow_route_rule_segment_arr_rel_insert_input = {
  data: Array<flow_route_rule_segment_insert_input>;
};

/** aggregate avg on columns */
export type flow_route_rule_segment_avg_fields = {
  __typename: 'flow_route_rule_segment_avg_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_avg_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule_segment". All fields are combined with a logical 'AND'. */
export type flow_route_rule_segment_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_segment_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_segment_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_segment_bool_exp>>;
  flow_route_rule?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group = {
  __typename: 'flow_route_rule_segment_group';
  /** An object relationship */
  flow_route_rule?: Maybe<flow_route_rule>;
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment_group?: Maybe<segment_group>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_aggregate = {
  __typename: 'flow_route_rule_segment_group_aggregate';
  aggregate?: Maybe<flow_route_rule_segment_group_aggregate_fields>;
  nodes: Array<flow_route_rule_segment_group>;
};

export type flow_route_rule_segment_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_route_rule_segment_group_aggregate_bool_exp_count>;
};

export type flow_route_rule_segment_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_aggregate_fields = {
  __typename: 'flow_route_rule_segment_group_aggregate_fields';
  avg?: Maybe<flow_route_rule_segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_route_rule_segment_group_max_fields>;
  min?: Maybe<flow_route_rule_segment_group_min_fields>;
  stddev?: Maybe<flow_route_rule_segment_group_stddev_fields>;
  stddev_pop?: Maybe<flow_route_rule_segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_route_rule_segment_group_stddev_samp_fields>;
  sum?: Maybe<flow_route_rule_segment_group_sum_fields>;
  var_pop?: Maybe<flow_route_rule_segment_group_var_pop_fields>;
  var_samp?: Maybe<flow_route_rule_segment_group_var_samp_fields>;
  variance?: Maybe<flow_route_rule_segment_group_variance_fields>;
};


/** aggregate fields of "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_aggregate_order_by = {
  avg?: InputMaybe<flow_route_rule_segment_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_route_rule_segment_group_max_order_by>;
  min?: InputMaybe<flow_route_rule_segment_group_min_order_by>;
  stddev?: InputMaybe<flow_route_rule_segment_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_route_rule_segment_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_route_rule_segment_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_route_rule_segment_group_sum_order_by>;
  var_pop?: InputMaybe<flow_route_rule_segment_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_route_rule_segment_group_var_samp_order_by>;
  variance?: InputMaybe<flow_route_rule_segment_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_arr_rel_insert_input = {
  data: Array<flow_route_rule_segment_group_insert_input>;
};

/** aggregate avg on columns */
export type flow_route_rule_segment_group_avg_fields = {
  __typename: 'flow_route_rule_segment_group_avg_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_avg_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_route_rule_segment_group". All fields are combined with a logical 'AND'. */
export type flow_route_rule_segment_group_bool_exp = {
  _and?: InputMaybe<Array<flow_route_rule_segment_group_bool_exp>>;
  _not?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
  _or?: InputMaybe<Array<flow_route_rule_segment_group_bool_exp>>;
  flow_route_rule?: InputMaybe<flow_route_rule_bool_exp>;
  flow_route_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_insert_input = {
  flow_route_rule?: InputMaybe<flow_route_rule_obj_rel_insert_input>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_segment_group_max_fields = {
  __typename: 'flow_route_rule_segment_group_max_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_max_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_segment_group_min_fields = {
  __typename: 'flow_route_rule_segment_group_min_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_min_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_route_rule_segment_group". */
export type flow_route_rule_segment_group_order_by = {
  flow_route_rule?: InputMaybe<flow_route_rule_order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule_segment_group" */
export enum flow_route_rule_segment_group_select_column {
  /** column name */
  flow_route_rule_id = 'flow_route_rule_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_group_id = 'segment_group_id'
}

/** aggregate stddev on columns */
export type flow_route_rule_segment_group_stddev_fields = {
  __typename: 'flow_route_rule_segment_group_stddev_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_stddev_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_segment_group_stddev_pop_fields = {
  __typename: 'flow_route_rule_segment_group_stddev_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_stddev_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_segment_group_stddev_samp_fields = {
  __typename: 'flow_route_rule_segment_group_stddev_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_stddev_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_segment_group_stream_cursor_value_input = {
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_segment_group_sum_fields = {
  __typename: 'flow_route_rule_segment_group_sum_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_sum_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_route_rule_segment_group_var_pop_fields = {
  __typename: 'flow_route_rule_segment_group_var_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_var_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_segment_group_var_samp_fields = {
  __typename: 'flow_route_rule_segment_group_var_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_var_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_segment_group_variance_fields = {
  __typename: 'flow_route_rule_segment_group_variance_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule_segment_group" */
export type flow_route_rule_segment_group_variance_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** input type for inserting data into table "flow_route_rule_segment" */
export type flow_route_rule_segment_insert_input = {
  flow_route_rule?: InputMaybe<flow_route_rule_obj_rel_insert_input>;
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_route_rule_segment_max_fields = {
  __typename: 'flow_route_rule_segment_max_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_max_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_route_rule_segment_min_fields = {
  __typename: 'flow_route_rule_segment_min_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_min_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_route_rule_segment". */
export type flow_route_rule_segment_order_by = {
  flow_route_rule?: InputMaybe<flow_route_rule_order_by>;
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule_segment" */
export enum flow_route_rule_segment_select_column {
  /** column name */
  flow_route_rule_id = 'flow_route_rule_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_id = 'segment_id'
}

/** aggregate stddev on columns */
export type flow_route_rule_segment_stddev_fields = {
  __typename: 'flow_route_rule_segment_stddev_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_stddev_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_segment_stddev_pop_fields = {
  __typename: 'flow_route_rule_segment_stddev_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_stddev_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_segment_stddev_samp_fields = {
  __typename: 'flow_route_rule_segment_stddev_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_stddev_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule_segment" */
export type flow_route_rule_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_segment_stream_cursor_value_input = {
  flow_route_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_segment_sum_fields = {
  __typename: 'flow_route_rule_segment_sum_fields';
  flow_route_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_sum_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_route_rule_segment_var_pop_fields = {
  __typename: 'flow_route_rule_segment_var_pop_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_var_pop_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_segment_var_samp_fields = {
  __typename: 'flow_route_rule_segment_var_samp_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_var_samp_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_segment_variance_fields = {
  __typename: 'flow_route_rule_segment_variance_fields';
  flow_route_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule_segment" */
export type flow_route_rule_segment_variance_order_by = {
  flow_route_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_route_rule" */
export enum flow_route_rule_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  eligibility_message_ids = 'eligibility_message_ids',
  /** column name */
  flow_ids = 'flow_ids',
  /** column name */
  flow_test_ids = 'flow_test_ids',
  /** column name */
  id = 'id',
  /** column name */
  position = 'position',
  /** column name */
  segment_group_ids = 'segment_group_ids',
  /** column name */
  segment_ids = 'segment_ids',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_route_rule" */
export type flow_route_rule_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_route_rule_stddev_fields = {
  __typename: 'flow_route_rule_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_route_rule" */
export type flow_route_rule_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_route_rule_stddev_pop_fields = {
  __typename: 'flow_route_rule_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_route_rule" */
export type flow_route_rule_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_route_rule_stddev_samp_fields = {
  __typename: 'flow_route_rule_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_route_rule" */
export type flow_route_rule_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_route_rule" */
export type flow_route_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_route_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_route_rule_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_route_rule_sum_fields = {
  __typename: 'flow_route_rule_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_route_rule" */
export type flow_route_rule_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** update columns of table "flow_route_rule" */
export enum flow_route_rule_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  eligibility_message_ids = 'eligibility_message_ids',
  /** column name */
  flow_ids = 'flow_ids',
  /** column name */
  flow_test_ids = 'flow_test_ids',
  /** column name */
  id = 'id',
  /** column name */
  position = 'position',
  /** column name */
  segment_group_ids = 'segment_group_ids',
  /** column name */
  segment_ids = 'segment_ids',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_route_rule_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<flow_route_rule_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<flow_route_rule_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<flow_route_rule_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<flow_route_rule_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_route_rule_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<flow_route_rule_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_route_rule_set_input>;
  /** filter the rows which have to be updated */
  where: flow_route_rule_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_route_rule_var_pop_fields = {
  __typename: 'flow_route_rule_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_route_rule" */
export type flow_route_rule_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_route_rule_var_samp_fields = {
  __typename: 'flow_route_rule_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_route_rule" */
export type flow_route_rule_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_route_rule_variance_fields = {
  __typename: 'flow_route_rule_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_route_rule" */
export type flow_route_rule_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "flow" */
export enum flow_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  auto_cancel = 'auto_cancel',
  /** column name */
  auto_cancel_at = 'auto_cancel_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  default_language = 'default_language',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  on_cancel = 'on_cancel',
  /** column name */
  on_cancel_perform_at = 'on_cancel_perform_at',
  /** column name */
  prevent_if_canceled = 'prevent_if_canceled',
  /** column name */
  prevent_if_canceled_translation_id = 'prevent_if_canceled_translation_id',
  /** column name */
  preview_property_values = 'preview_property_values',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  redirect_uri = 'redirect_uri',
  /** column name */
  secret = 'secret',
  /** column name */
  signature_required = 'signature_required',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** select "flow_aggregate_bool_exp_bool_and_arguments_columns" columns of table "flow" */
export enum flow_select_column_flow_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  auto_cancel = 'auto_cancel',
  /** column name */
  prevent_if_canceled = 'prevent_if_canceled',
  /** column name */
  signature_required = 'signature_required'
}

/** select "flow_aggregate_bool_exp_bool_or_arguments_columns" columns of table "flow" */
export enum flow_select_column_flow_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  auto_cancel = 'auto_cancel',
  /** column name */
  prevent_if_canceled = 'prevent_if_canceled',
  /** column name */
  signature_required = 'signature_required'
}

/** input type for updating data in table "flow" */
export type flow_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  auto_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  auto_cancel_at?: InputMaybe<flow_auto_cancel_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  on_cancel?: InputMaybe<flow_on_cancel_enum>;
  on_cancel_perform_at?: InputMaybe<flow_on_cancel_perform_at_enum>;
  prevent_if_canceled?: InputMaybe<Scalars['Boolean']['input']>;
  prevent_if_canceled_translation_id?: InputMaybe<Scalars['Int']['input']>;
  preview_property_values?: InputMaybe<Scalars['jsonb']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_uri?: InputMaybe<Scalars['String']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_stddev_fields = {
  __typename: 'flow_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow" */
export type flow_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_stddev_pop_fields = {
  __typename: 'flow_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow" */
export type flow_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_stddev_samp_fields = {
  __typename: 'flow_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow" */
export type flow_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step" */
export type flow_step = {
  __typename: 'flow_step';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<flow_step_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_step_acknowledgement_group?: Maybe<flow_step_acknowledgement_group>;
  /** An array relationship */
  flow_step_acknowledgement_groups: Array<flow_step_acknowledgement_group>;
  /** An aggregate relationship */
  flow_step_acknowledgement_groups_aggregate: flow_step_acknowledgement_group_aggregate;
  /** An object relationship */
  flow_step_confirmation?: Maybe<flow_step_confirmation>;
  /** An array relationship */
  flow_step_confirmations: Array<flow_step_confirmation>;
  /** An aggregate relationship */
  flow_step_confirmations_aggregate: flow_step_confirmation_aggregate;
  /** An object relationship */
  flow_step_deflection_rule_group?: Maybe<flow_step_deflection_rule_group>;
  /** An array relationship */
  flow_step_deflection_rule_groups: Array<flow_step_deflection_rule_group>;
  /** An aggregate relationship */
  flow_step_deflection_rule_groups_aggregate: flow_step_deflection_rule_group_aggregate;
  /** An object relationship */
  flow_step_form?: Maybe<flow_step_form>;
  /** An array relationship */
  flow_step_forms: Array<flow_step_form>;
  /** An aggregate relationship */
  flow_step_forms_aggregate: flow_step_form_aggregate;
  /** An object relationship */
  flow_step_intervention?: Maybe<flow_step_intervention>;
  /** An array relationship */
  flow_step_interventions: Array<flow_step_intervention>;
  /** An aggregate relationship */
  flow_step_interventions_aggregate: flow_step_intervention_aggregate;
  /** An object relationship */
  flow_step_offer_rule_group?: Maybe<flow_step_offer_rule_group>;
  /** An array relationship */
  flow_step_offer_rule_groups: Array<flow_step_offer_rule_group>;
  /** An aggregate relationship */
  flow_step_offer_rule_groups_aggregate: flow_step_offer_rule_group_aggregate;
  /** An object relationship */
  flow_step_question?: Maybe<flow_step_question>;
  /** An array relationship */
  flow_step_questions: Array<flow_step_question>;
  /** An aggregate relationship */
  flow_step_questions_aggregate: flow_step_question_aggregate;
  /** An object relationship */
  flow_step_rule_group?: Maybe<flow_step_rule_group>;
  /** An array relationship */
  flow_step_rule_groups: Array<flow_step_rule_group>;
  /** An aggregate relationship */
  flow_step_rule_groups_aggregate: flow_step_rule_group_aggregate;
  /** An object relationship */
  flow_step_type: flow_step_type;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<flow_step_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token: Scalars['String']['output'];
  type: flow_step_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_acknowledgement_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_acknowledgement_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_confirmationsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_confirmations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_deflection_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_deflection_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_formsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_forms_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_interventionsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_interventions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_offer_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_offer_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_questionsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


/** columns and relationships of "flow_step" */
export type flow_stepflow_step_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};

/** columns and relationships of "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group = {
  __typename: 'flow_step_acknowledgement_group';
  /** An object relationship */
  acknowledgement_group: acknowledgement_group;
  acknowledgement_group_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_aggregate = {
  __typename: 'flow_step_acknowledgement_group_aggregate';
  aggregate?: Maybe<flow_step_acknowledgement_group_aggregate_fields>;
  nodes: Array<flow_step_acknowledgement_group>;
};

export type flow_step_acknowledgement_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_acknowledgement_group_aggregate_bool_exp_count>;
};

export type flow_step_acknowledgement_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_aggregate_fields = {
  __typename: 'flow_step_acknowledgement_group_aggregate_fields';
  avg?: Maybe<flow_step_acknowledgement_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_acknowledgement_group_max_fields>;
  min?: Maybe<flow_step_acknowledgement_group_min_fields>;
  stddev?: Maybe<flow_step_acknowledgement_group_stddev_fields>;
  stddev_pop?: Maybe<flow_step_acknowledgement_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_acknowledgement_group_stddev_samp_fields>;
  sum?: Maybe<flow_step_acknowledgement_group_sum_fields>;
  var_pop?: Maybe<flow_step_acknowledgement_group_var_pop_fields>;
  var_samp?: Maybe<flow_step_acknowledgement_group_var_samp_fields>;
  variance?: Maybe<flow_step_acknowledgement_group_variance_fields>;
};


/** aggregate fields of "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_aggregate_order_by = {
  avg?: InputMaybe<flow_step_acknowledgement_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_acknowledgement_group_max_order_by>;
  min?: InputMaybe<flow_step_acknowledgement_group_min_order_by>;
  stddev?: InputMaybe<flow_step_acknowledgement_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_acknowledgement_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_acknowledgement_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_acknowledgement_group_sum_order_by>;
  var_pop?: InputMaybe<flow_step_acknowledgement_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_acknowledgement_group_var_samp_order_by>;
  variance?: InputMaybe<flow_step_acknowledgement_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_arr_rel_insert_input = {
  data: Array<flow_step_acknowledgement_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_acknowledgement_group_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_acknowledgement_group_avg_fields = {
  __typename: 'flow_step_acknowledgement_group_avg_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_avg_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_acknowledgement_group". All fields are combined with a logical 'AND'. */
export type flow_step_acknowledgement_group_bool_exp = {
  _and?: InputMaybe<Array<flow_step_acknowledgement_group_bool_exp>>;
  _not?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
  _or?: InputMaybe<Array<flow_step_acknowledgement_group_bool_exp>>;
  acknowledgement_group?: InputMaybe<acknowledgement_group_bool_exp>;
  acknowledgement_group_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_acknowledgement_group" */
export enum flow_step_acknowledgement_group_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_acknowledgement_group_pkey = 'flow_step_acknowledgement_group_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_inc_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_insert_input = {
  acknowledgement_group?: InputMaybe<acknowledgement_group_obj_rel_insert_input>;
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_acknowledgement_group_max_fields = {
  __typename: 'flow_step_acknowledgement_group_max_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_max_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_acknowledgement_group_min_fields = {
  __typename: 'flow_step_acknowledgement_group_min_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_min_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_mutation_response = {
  __typename: 'flow_step_acknowledgement_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_acknowledgement_group>;
};

/** input type for inserting object relation for remote table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_obj_rel_insert_input = {
  data: flow_step_acknowledgement_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_acknowledgement_group_on_conflict>;
};

/** on_conflict condition type for table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_on_conflict = {
  constraint: flow_step_acknowledgement_group_constraint;
  update_columns?: Array<flow_step_acknowledgement_group_update_column>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_acknowledgement_group". */
export type flow_step_acknowledgement_group_order_by = {
  acknowledgement_group?: InputMaybe<acknowledgement_group_order_by>;
  acknowledgement_group_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_acknowledgement_group */
export type flow_step_acknowledgement_group_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_acknowledgement_group" */
export enum flow_step_acknowledgement_group_select_column {
  /** column name */
  acknowledgement_group_id = 'acknowledgement_group_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id'
}

/** input type for updating data in table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_set_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_acknowledgement_group_stddev_fields = {
  __typename: 'flow_step_acknowledgement_group_stddev_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_stddev_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_acknowledgement_group_stddev_pop_fields = {
  __typename: 'flow_step_acknowledgement_group_stddev_pop_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_stddev_pop_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_acknowledgement_group_stddev_samp_fields = {
  __typename: 'flow_step_acknowledgement_group_stddev_samp_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_stddev_samp_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_acknowledgement_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_acknowledgement_group_stream_cursor_value_input = {
  acknowledgement_group_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_acknowledgement_group_sum_fields = {
  __typename: 'flow_step_acknowledgement_group_sum_fields';
  acknowledgement_group_id?: Maybe<Scalars['Int']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_sum_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_acknowledgement_group" */
export enum flow_step_acknowledgement_group_update_column {
  /** column name */
  acknowledgement_group_id = 'acknowledgement_group_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id'
}

export type flow_step_acknowledgement_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_acknowledgement_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_acknowledgement_group_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_acknowledgement_group_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_acknowledgement_group_var_pop_fields = {
  __typename: 'flow_step_acknowledgement_group_var_pop_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_var_pop_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_acknowledgement_group_var_samp_fields = {
  __typename: 'flow_step_acknowledgement_group_var_samp_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_var_samp_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_acknowledgement_group_variance_fields = {
  __typename: 'flow_step_acknowledgement_group_variance_fields';
  acknowledgement_group_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_acknowledgement_group" */
export type flow_step_acknowledgement_group_variance_order_by = {
  acknowledgement_group_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregated selection of "flow_step" */
export type flow_step_aggregate = {
  __typename: 'flow_step_aggregate';
  aggregate?: Maybe<flow_step_aggregate_fields>;
  nodes: Array<flow_step>;
};

export type flow_step_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_aggregate_bool_exp_count>;
};

export type flow_step_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step" */
export type flow_step_aggregate_fields = {
  __typename: 'flow_step_aggregate_fields';
  avg?: Maybe<flow_step_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_max_fields>;
  min?: Maybe<flow_step_min_fields>;
  stddev?: Maybe<flow_step_stddev_fields>;
  stddev_pop?: Maybe<flow_step_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_stddev_samp_fields>;
  sum?: Maybe<flow_step_sum_fields>;
  var_pop?: Maybe<flow_step_var_pop_fields>;
  var_samp?: Maybe<flow_step_var_samp_fields>;
  variance?: Maybe<flow_step_variance_fields>;
};


/** aggregate fields of "flow_step" */
export type flow_step_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step" */
export type flow_step_aggregate_order_by = {
  avg?: InputMaybe<flow_step_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_max_order_by>;
  min?: InputMaybe<flow_step_min_order_by>;
  stddev?: InputMaybe<flow_step_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_sum_order_by>;
  var_pop?: InputMaybe<flow_step_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_var_samp_order_by>;
  variance?: InputMaybe<flow_step_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step" */
export type flow_step_arr_rel_insert_input = {
  data: Array<flow_step_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_avg_fields = {
  __typename: 'flow_step_avg_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step" */
export type flow_step_avg_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step". All fields are combined with a logical 'AND'. */
export type flow_step_bool_exp = {
  _and?: InputMaybe<Array<flow_step_bool_exp>>;
  _not?: InputMaybe<flow_step_bool_exp>;
  _or?: InputMaybe<Array<flow_step_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<flow_step_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_step_acknowledgement_group?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
  flow_step_acknowledgement_groups?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
  flow_step_acknowledgement_groups_aggregate?: InputMaybe<flow_step_acknowledgement_group_aggregate_bool_exp>;
  flow_step_confirmation?: InputMaybe<flow_step_confirmation_bool_exp>;
  flow_step_confirmations?: InputMaybe<flow_step_confirmation_bool_exp>;
  flow_step_confirmations_aggregate?: InputMaybe<flow_step_confirmation_aggregate_bool_exp>;
  flow_step_deflection_rule_group?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
  flow_step_deflection_rule_groups?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
  flow_step_deflection_rule_groups_aggregate?: InputMaybe<flow_step_deflection_rule_group_aggregate_bool_exp>;
  flow_step_form?: InputMaybe<flow_step_form_bool_exp>;
  flow_step_forms?: InputMaybe<flow_step_form_bool_exp>;
  flow_step_forms_aggregate?: InputMaybe<flow_step_form_aggregate_bool_exp>;
  flow_step_intervention?: InputMaybe<flow_step_intervention_bool_exp>;
  flow_step_interventions?: InputMaybe<flow_step_intervention_bool_exp>;
  flow_step_interventions_aggregate?: InputMaybe<flow_step_intervention_aggregate_bool_exp>;
  flow_step_offer_rule_group?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
  flow_step_offer_rule_groups?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
  flow_step_offer_rule_groups_aggregate?: InputMaybe<flow_step_offer_rule_group_aggregate_bool_exp>;
  flow_step_question?: InputMaybe<flow_step_question_bool_exp>;
  flow_step_questions?: InputMaybe<flow_step_question_bool_exp>;
  flow_step_questions_aggregate?: InputMaybe<flow_step_question_aggregate_bool_exp>;
  flow_step_rule_group?: InputMaybe<flow_step_rule_group_bool_exp>;
  flow_step_rule_groups?: InputMaybe<flow_step_rule_group_bool_exp>;
  flow_step_rule_groups_aggregate?: InputMaybe<flow_step_rule_group_aggregate_bool_exp>;
  flow_step_type?: InputMaybe<flow_step_type_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<flow_step_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<flow_step_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "flow_step_confirmation" */
export type flow_step_confirmation = {
  __typename: 'flow_step_confirmation';
  /** An object relationship */
  confirmation: confirmation;
  confirmation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_confirmation" */
export type flow_step_confirmation_aggregate = {
  __typename: 'flow_step_confirmation_aggregate';
  aggregate?: Maybe<flow_step_confirmation_aggregate_fields>;
  nodes: Array<flow_step_confirmation>;
};

export type flow_step_confirmation_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_confirmation_aggregate_bool_exp_count>;
};

export type flow_step_confirmation_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_confirmation_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_confirmation" */
export type flow_step_confirmation_aggregate_fields = {
  __typename: 'flow_step_confirmation_aggregate_fields';
  avg?: Maybe<flow_step_confirmation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_confirmation_max_fields>;
  min?: Maybe<flow_step_confirmation_min_fields>;
  stddev?: Maybe<flow_step_confirmation_stddev_fields>;
  stddev_pop?: Maybe<flow_step_confirmation_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_confirmation_stddev_samp_fields>;
  sum?: Maybe<flow_step_confirmation_sum_fields>;
  var_pop?: Maybe<flow_step_confirmation_var_pop_fields>;
  var_samp?: Maybe<flow_step_confirmation_var_samp_fields>;
  variance?: Maybe<flow_step_confirmation_variance_fields>;
};


/** aggregate fields of "flow_step_confirmation" */
export type flow_step_confirmation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_confirmation" */
export type flow_step_confirmation_aggregate_order_by = {
  avg?: InputMaybe<flow_step_confirmation_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_confirmation_max_order_by>;
  min?: InputMaybe<flow_step_confirmation_min_order_by>;
  stddev?: InputMaybe<flow_step_confirmation_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_confirmation_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_confirmation_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_confirmation_sum_order_by>;
  var_pop?: InputMaybe<flow_step_confirmation_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_confirmation_var_samp_order_by>;
  variance?: InputMaybe<flow_step_confirmation_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_confirmation" */
export type flow_step_confirmation_arr_rel_insert_input = {
  data: Array<flow_step_confirmation_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_confirmation_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_confirmation_avg_fields = {
  __typename: 'flow_step_confirmation_avg_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_avg_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_confirmation". All fields are combined with a logical 'AND'. */
export type flow_step_confirmation_bool_exp = {
  _and?: InputMaybe<Array<flow_step_confirmation_bool_exp>>;
  _not?: InputMaybe<flow_step_confirmation_bool_exp>;
  _or?: InputMaybe<Array<flow_step_confirmation_bool_exp>>;
  confirmation?: InputMaybe<confirmation_bool_exp>;
  confirmation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_confirmation" */
export enum flow_step_confirmation_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_confirmation_pkey = 'flow_step_confirmation_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_confirmation" */
export type flow_step_confirmation_inc_input = {
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_confirmation" */
export type flow_step_confirmation_insert_input = {
  confirmation?: InputMaybe<confirmation_obj_rel_insert_input>;
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_confirmation_max_fields = {
  __typename: 'flow_step_confirmation_max_fields';
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_max_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_confirmation_min_fields = {
  __typename: 'flow_step_confirmation_min_fields';
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_min_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_confirmation" */
export type flow_step_confirmation_mutation_response = {
  __typename: 'flow_step_confirmation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_confirmation>;
};

/** input type for inserting object relation for remote table "flow_step_confirmation" */
export type flow_step_confirmation_obj_rel_insert_input = {
  data: flow_step_confirmation_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_confirmation_on_conflict>;
};

/** on_conflict condition type for table "flow_step_confirmation" */
export type flow_step_confirmation_on_conflict = {
  constraint: flow_step_confirmation_constraint;
  update_columns?: Array<flow_step_confirmation_update_column>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_confirmation". */
export type flow_step_confirmation_order_by = {
  confirmation?: InputMaybe<confirmation_order_by>;
  confirmation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_confirmation */
export type flow_step_confirmation_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_confirmation" */
export enum flow_step_confirmation_select_column {
  /** column name */
  confirmation_id = 'confirmation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id'
}

/** input type for updating data in table "flow_step_confirmation" */
export type flow_step_confirmation_set_input = {
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_confirmation_stddev_fields = {
  __typename: 'flow_step_confirmation_stddev_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_stddev_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_confirmation_stddev_pop_fields = {
  __typename: 'flow_step_confirmation_stddev_pop_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_stddev_pop_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_confirmation_stddev_samp_fields = {
  __typename: 'flow_step_confirmation_stddev_samp_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_stddev_samp_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_confirmation" */
export type flow_step_confirmation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_confirmation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_confirmation_stream_cursor_value_input = {
  confirmation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_confirmation_sum_fields = {
  __typename: 'flow_step_confirmation_sum_fields';
  confirmation_id?: Maybe<Scalars['Int']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_sum_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_confirmation" */
export enum flow_step_confirmation_update_column {
  /** column name */
  confirmation_id = 'confirmation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id'
}

export type flow_step_confirmation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_confirmation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_confirmation_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_confirmation_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_confirmation_var_pop_fields = {
  __typename: 'flow_step_confirmation_var_pop_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_var_pop_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_confirmation_var_samp_fields = {
  __typename: 'flow_step_confirmation_var_samp_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_var_samp_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_confirmation_variance_fields = {
  __typename: 'flow_step_confirmation_variance_fields';
  confirmation_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_confirmation" */
export type flow_step_confirmation_variance_order_by = {
  confirmation_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "flow_step" */
export enum flow_step_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_step_pkey = 'flow_step_pkey',
  /** unique or primary key constraint on columns "token" */
  flow_step_token_key = 'flow_step_token_key'
}

/** columns and relationships of "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group = {
  __typename: 'flow_step_deflection_rule_group';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  flow_step?: Maybe<flow_step>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_group?: Maybe<offer_rule_group>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_aggregate = {
  __typename: 'flow_step_deflection_rule_group_aggregate';
  aggregate?: Maybe<flow_step_deflection_rule_group_aggregate_fields>;
  nodes: Array<flow_step_deflection_rule_group>;
};

export type flow_step_deflection_rule_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_deflection_rule_group_aggregate_bool_exp_count>;
};

export type flow_step_deflection_rule_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_aggregate_fields = {
  __typename: 'flow_step_deflection_rule_group_aggregate_fields';
  avg?: Maybe<flow_step_deflection_rule_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_deflection_rule_group_max_fields>;
  min?: Maybe<flow_step_deflection_rule_group_min_fields>;
  stddev?: Maybe<flow_step_deflection_rule_group_stddev_fields>;
  stddev_pop?: Maybe<flow_step_deflection_rule_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_deflection_rule_group_stddev_samp_fields>;
  sum?: Maybe<flow_step_deflection_rule_group_sum_fields>;
  var_pop?: Maybe<flow_step_deflection_rule_group_var_pop_fields>;
  var_samp?: Maybe<flow_step_deflection_rule_group_var_samp_fields>;
  variance?: Maybe<flow_step_deflection_rule_group_variance_fields>;
};


/** aggregate fields of "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_aggregate_order_by = {
  avg?: InputMaybe<flow_step_deflection_rule_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_deflection_rule_group_max_order_by>;
  min?: InputMaybe<flow_step_deflection_rule_group_min_order_by>;
  stddev?: InputMaybe<flow_step_deflection_rule_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_deflection_rule_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_deflection_rule_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_deflection_rule_group_sum_order_by>;
  var_pop?: InputMaybe<flow_step_deflection_rule_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_deflection_rule_group_var_samp_order_by>;
  variance?: InputMaybe<flow_step_deflection_rule_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_arr_rel_insert_input = {
  data: Array<flow_step_deflection_rule_group_insert_input>;
};

/** aggregate avg on columns */
export type flow_step_deflection_rule_group_avg_fields = {
  __typename: 'flow_step_deflection_rule_group_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_deflection_rule_group". All fields are combined with a logical 'AND'. */
export type flow_step_deflection_rule_group_bool_exp = {
  _and?: InputMaybe<Array<flow_step_deflection_rule_group_bool_exp>>;
  _not?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
  _or?: InputMaybe<Array<flow_step_deflection_rule_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for incrementing numeric columns in table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_deflection_rule_group_max_fields = {
  __typename: 'flow_step_deflection_rule_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_deflection_rule_group_min_fields = {
  __typename: 'flow_step_deflection_rule_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_mutation_response = {
  __typename: 'flow_step_deflection_rule_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_deflection_rule_group>;
};

/** input type for inserting object relation for remote table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_obj_rel_insert_input = {
  data: flow_step_deflection_rule_group_insert_input;
};

/** Ordering options when selecting data from "flow_step_deflection_rule_group". */
export type flow_step_deflection_rule_group_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step_deflection_rule_group" */
export enum flow_step_deflection_rule_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id'
}

/** input type for updating data in table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_deflection_rule_group_stddev_fields = {
  __typename: 'flow_step_deflection_rule_group_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_deflection_rule_group_stddev_pop_fields = {
  __typename: 'flow_step_deflection_rule_group_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_deflection_rule_group_stddev_samp_fields = {
  __typename: 'flow_step_deflection_rule_group_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_deflection_rule_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_deflection_rule_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_deflection_rule_group_sum_fields = {
  __typename: 'flow_step_deflection_rule_group_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

export type flow_step_deflection_rule_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_deflection_rule_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_deflection_rule_group_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_deflection_rule_group_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_deflection_rule_group_var_pop_fields = {
  __typename: 'flow_step_deflection_rule_group_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_deflection_rule_group_var_samp_fields = {
  __typename: 'flow_step_deflection_rule_group_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_deflection_rule_group_variance_fields = {
  __typename: 'flow_step_deflection_rule_group_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_deflection_rule_group" */
export type flow_step_deflection_rule_group_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step_form" */
export type flow_step_form = {
  __typename: 'flow_step_form';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  form: form;
  form_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_form" */
export type flow_step_form_aggregate = {
  __typename: 'flow_step_form_aggregate';
  aggregate?: Maybe<flow_step_form_aggregate_fields>;
  nodes: Array<flow_step_form>;
};

export type flow_step_form_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_form_aggregate_bool_exp_count>;
};

export type flow_step_form_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_form_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_form_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_form" */
export type flow_step_form_aggregate_fields = {
  __typename: 'flow_step_form_aggregate_fields';
  avg?: Maybe<flow_step_form_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_form_max_fields>;
  min?: Maybe<flow_step_form_min_fields>;
  stddev?: Maybe<flow_step_form_stddev_fields>;
  stddev_pop?: Maybe<flow_step_form_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_form_stddev_samp_fields>;
  sum?: Maybe<flow_step_form_sum_fields>;
  var_pop?: Maybe<flow_step_form_var_pop_fields>;
  var_samp?: Maybe<flow_step_form_var_samp_fields>;
  variance?: Maybe<flow_step_form_variance_fields>;
};


/** aggregate fields of "flow_step_form" */
export type flow_step_form_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_form_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_form" */
export type flow_step_form_aggregate_order_by = {
  avg?: InputMaybe<flow_step_form_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_form_max_order_by>;
  min?: InputMaybe<flow_step_form_min_order_by>;
  stddev?: InputMaybe<flow_step_form_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_form_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_form_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_form_sum_order_by>;
  var_pop?: InputMaybe<flow_step_form_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_form_var_samp_order_by>;
  variance?: InputMaybe<flow_step_form_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_form" */
export type flow_step_form_arr_rel_insert_input = {
  data: Array<flow_step_form_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_form_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_form_avg_fields = {
  __typename: 'flow_step_form_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_form" */
export type flow_step_form_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_form". All fields are combined with a logical 'AND'. */
export type flow_step_form_bool_exp = {
  _and?: InputMaybe<Array<flow_step_form_bool_exp>>;
  _not?: InputMaybe<flow_step_form_bool_exp>;
  _or?: InputMaybe<Array<flow_step_form_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  form?: InputMaybe<form_bool_exp>;
  form_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_form" */
export enum flow_step_form_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_form_pkey = 'flow_step_form_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_form" */
export type flow_step_form_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_form" */
export type flow_step_form_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  form?: InputMaybe<form_obj_rel_insert_input>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_form_max_fields = {
  __typename: 'flow_step_form_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  form_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_form" */
export type flow_step_form_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_form_min_fields = {
  __typename: 'flow_step_form_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  form_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_form" */
export type flow_step_form_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_form" */
export type flow_step_form_mutation_response = {
  __typename: 'flow_step_form_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_form>;
};

/** input type for inserting object relation for remote table "flow_step_form" */
export type flow_step_form_obj_rel_insert_input = {
  data: flow_step_form_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_form_on_conflict>;
};

/** on_conflict condition type for table "flow_step_form" */
export type flow_step_form_on_conflict = {
  constraint: flow_step_form_constraint;
  update_columns?: Array<flow_step_form_update_column>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_form". */
export type flow_step_form_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  form?: InputMaybe<form_order_by>;
  form_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_form */
export type flow_step_form_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_form" */
export enum flow_step_form_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  form_id = 'form_id'
}

/** input type for updating data in table "flow_step_form" */
export type flow_step_form_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_form_stddev_fields = {
  __typename: 'flow_step_form_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_form" */
export type flow_step_form_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_form_stddev_pop_fields = {
  __typename: 'flow_step_form_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_form" */
export type flow_step_form_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_form_stddev_samp_fields = {
  __typename: 'flow_step_form_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_form" */
export type flow_step_form_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_form" */
export type flow_step_form_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_form_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_form_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_form_sum_fields = {
  __typename: 'flow_step_form_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  form_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_form" */
export type flow_step_form_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_form" */
export enum flow_step_form_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  form_id = 'form_id'
}

export type flow_step_form_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_form_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_form_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_form_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_form_var_pop_fields = {
  __typename: 'flow_step_form_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_form" */
export type flow_step_form_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_form_var_samp_fields = {
  __typename: 'flow_step_form_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_form" */
export type flow_step_form_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_form_variance_fields = {
  __typename: 'flow_step_form_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  form_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_form" */
export type flow_step_form_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "flow_step" */
export type flow_step_inc_input = {
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step" */
export type flow_step_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_acknowledgement_group?: InputMaybe<flow_step_acknowledgement_group_obj_rel_insert_input>;
  flow_step_acknowledgement_groups?: InputMaybe<flow_step_acknowledgement_group_arr_rel_insert_input>;
  flow_step_confirmation?: InputMaybe<flow_step_confirmation_obj_rel_insert_input>;
  flow_step_confirmations?: InputMaybe<flow_step_confirmation_arr_rel_insert_input>;
  flow_step_deflection_rule_group?: InputMaybe<flow_step_deflection_rule_group_obj_rel_insert_input>;
  flow_step_deflection_rule_groups?: InputMaybe<flow_step_deflection_rule_group_arr_rel_insert_input>;
  flow_step_form?: InputMaybe<flow_step_form_obj_rel_insert_input>;
  flow_step_forms?: InputMaybe<flow_step_form_arr_rel_insert_input>;
  flow_step_intervention?: InputMaybe<flow_step_intervention_obj_rel_insert_input>;
  flow_step_interventions?: InputMaybe<flow_step_intervention_arr_rel_insert_input>;
  flow_step_offer_rule_group?: InputMaybe<flow_step_offer_rule_group_obj_rel_insert_input>;
  flow_step_offer_rule_groups?: InputMaybe<flow_step_offer_rule_group_arr_rel_insert_input>;
  flow_step_question?: InputMaybe<flow_step_question_obj_rel_insert_input>;
  flow_step_questions?: InputMaybe<flow_step_question_arr_rel_insert_input>;
  flow_step_rule_group?: InputMaybe<flow_step_rule_group_obj_rel_insert_input>;
  flow_step_rule_groups?: InputMaybe<flow_step_rule_group_arr_rel_insert_input>;
  flow_step_type?: InputMaybe<flow_step_type_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "flow_step_intervention" */
export type flow_step_intervention = {
  __typename: 'flow_step_intervention';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  intervention: intervention;
  intervention_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_intervention" */
export type flow_step_intervention_aggregate = {
  __typename: 'flow_step_intervention_aggregate';
  aggregate?: Maybe<flow_step_intervention_aggregate_fields>;
  nodes: Array<flow_step_intervention>;
};

export type flow_step_intervention_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_intervention_aggregate_bool_exp_count>;
};

export type flow_step_intervention_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_intervention_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_intervention_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_intervention" */
export type flow_step_intervention_aggregate_fields = {
  __typename: 'flow_step_intervention_aggregate_fields';
  avg?: Maybe<flow_step_intervention_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_intervention_max_fields>;
  min?: Maybe<flow_step_intervention_min_fields>;
  stddev?: Maybe<flow_step_intervention_stddev_fields>;
  stddev_pop?: Maybe<flow_step_intervention_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_intervention_stddev_samp_fields>;
  sum?: Maybe<flow_step_intervention_sum_fields>;
  var_pop?: Maybe<flow_step_intervention_var_pop_fields>;
  var_samp?: Maybe<flow_step_intervention_var_samp_fields>;
  variance?: Maybe<flow_step_intervention_variance_fields>;
};


/** aggregate fields of "flow_step_intervention" */
export type flow_step_intervention_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_intervention_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_intervention" */
export type flow_step_intervention_aggregate_order_by = {
  avg?: InputMaybe<flow_step_intervention_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_intervention_max_order_by>;
  min?: InputMaybe<flow_step_intervention_min_order_by>;
  stddev?: InputMaybe<flow_step_intervention_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_intervention_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_intervention_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_intervention_sum_order_by>;
  var_pop?: InputMaybe<flow_step_intervention_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_intervention_var_samp_order_by>;
  variance?: InputMaybe<flow_step_intervention_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_intervention" */
export type flow_step_intervention_arr_rel_insert_input = {
  data: Array<flow_step_intervention_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_intervention_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_intervention_avg_fields = {
  __typename: 'flow_step_intervention_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_intervention" */
export type flow_step_intervention_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_intervention". All fields are combined with a logical 'AND'. */
export type flow_step_intervention_bool_exp = {
  _and?: InputMaybe<Array<flow_step_intervention_bool_exp>>;
  _not?: InputMaybe<flow_step_intervention_bool_exp>;
  _or?: InputMaybe<Array<flow_step_intervention_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  intervention?: InputMaybe<intervention_bool_exp>;
  intervention_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_intervention" */
export enum flow_step_intervention_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_intervention_pkey = 'flow_step_intervention_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_intervention" */
export type flow_step_intervention_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_intervention" */
export type flow_step_intervention_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  intervention?: InputMaybe<intervention_obj_rel_insert_input>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_intervention_max_fields = {
  __typename: 'flow_step_intervention_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_intervention" */
export type flow_step_intervention_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_intervention_min_fields = {
  __typename: 'flow_step_intervention_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_intervention" */
export type flow_step_intervention_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_intervention" */
export type flow_step_intervention_mutation_response = {
  __typename: 'flow_step_intervention_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_intervention>;
};

/** input type for inserting object relation for remote table "flow_step_intervention" */
export type flow_step_intervention_obj_rel_insert_input = {
  data: flow_step_intervention_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_intervention_on_conflict>;
};

/** on_conflict condition type for table "flow_step_intervention" */
export type flow_step_intervention_on_conflict = {
  constraint: flow_step_intervention_constraint;
  update_columns?: Array<flow_step_intervention_update_column>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_intervention". */
export type flow_step_intervention_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  intervention?: InputMaybe<intervention_order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_intervention */
export type flow_step_intervention_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_intervention" */
export enum flow_step_intervention_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  intervention_id = 'intervention_id'
}

/** input type for updating data in table "flow_step_intervention" */
export type flow_step_intervention_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_intervention_stddev_fields = {
  __typename: 'flow_step_intervention_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_intervention" */
export type flow_step_intervention_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_intervention_stddev_pop_fields = {
  __typename: 'flow_step_intervention_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_intervention" */
export type flow_step_intervention_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_intervention_stddev_samp_fields = {
  __typename: 'flow_step_intervention_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_intervention" */
export type flow_step_intervention_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_intervention" */
export type flow_step_intervention_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_intervention_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_intervention_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_intervention_sum_fields = {
  __typename: 'flow_step_intervention_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_intervention" */
export type flow_step_intervention_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_intervention" */
export enum flow_step_intervention_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  intervention_id = 'intervention_id'
}

export type flow_step_intervention_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_intervention_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_intervention_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_intervention_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_intervention_var_pop_fields = {
  __typename: 'flow_step_intervention_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_intervention" */
export type flow_step_intervention_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_intervention_var_samp_fields = {
  __typename: 'flow_step_intervention_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_intervention" */
export type flow_step_intervention_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_intervention_variance_fields = {
  __typename: 'flow_step_intervention_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_intervention" */
export type flow_step_intervention_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  intervention_id?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type flow_step_max_fields = {
  __typename: 'flow_step_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow_step" */
export type flow_step_max_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_min_fields = {
  __typename: 'flow_step_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow_step" */
export type flow_step_min_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step" */
export type flow_step_mutation_response = {
  __typename: 'flow_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step>;
};

/** input type for inserting object relation for remote table "flow_step" */
export type flow_step_obj_rel_insert_input = {
  data: flow_step_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_on_conflict>;
};

/** columns and relationships of "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group = {
  __typename: 'flow_step_offer_rule_group';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  flow_step?: Maybe<flow_step>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_group?: Maybe<offer_rule_group>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_aggregate = {
  __typename: 'flow_step_offer_rule_group_aggregate';
  aggregate?: Maybe<flow_step_offer_rule_group_aggregate_fields>;
  nodes: Array<flow_step_offer_rule_group>;
};

export type flow_step_offer_rule_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_offer_rule_group_aggregate_bool_exp_count>;
};

export type flow_step_offer_rule_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_aggregate_fields = {
  __typename: 'flow_step_offer_rule_group_aggregate_fields';
  avg?: Maybe<flow_step_offer_rule_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_offer_rule_group_max_fields>;
  min?: Maybe<flow_step_offer_rule_group_min_fields>;
  stddev?: Maybe<flow_step_offer_rule_group_stddev_fields>;
  stddev_pop?: Maybe<flow_step_offer_rule_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_offer_rule_group_stddev_samp_fields>;
  sum?: Maybe<flow_step_offer_rule_group_sum_fields>;
  var_pop?: Maybe<flow_step_offer_rule_group_var_pop_fields>;
  var_samp?: Maybe<flow_step_offer_rule_group_var_samp_fields>;
  variance?: Maybe<flow_step_offer_rule_group_variance_fields>;
};


/** aggregate fields of "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_aggregate_order_by = {
  avg?: InputMaybe<flow_step_offer_rule_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_offer_rule_group_max_order_by>;
  min?: InputMaybe<flow_step_offer_rule_group_min_order_by>;
  stddev?: InputMaybe<flow_step_offer_rule_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_offer_rule_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_offer_rule_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_offer_rule_group_sum_order_by>;
  var_pop?: InputMaybe<flow_step_offer_rule_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_offer_rule_group_var_samp_order_by>;
  variance?: InputMaybe<flow_step_offer_rule_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_arr_rel_insert_input = {
  data: Array<flow_step_offer_rule_group_insert_input>;
};

/** aggregate avg on columns */
export type flow_step_offer_rule_group_avg_fields = {
  __typename: 'flow_step_offer_rule_group_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_offer_rule_group". All fields are combined with a logical 'AND'. */
export type flow_step_offer_rule_group_bool_exp = {
  _and?: InputMaybe<Array<flow_step_offer_rule_group_bool_exp>>;
  _not?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
  _or?: InputMaybe<Array<flow_step_offer_rule_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for incrementing numeric columns in table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_offer_rule_group_max_fields = {
  __typename: 'flow_step_offer_rule_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_offer_rule_group_min_fields = {
  __typename: 'flow_step_offer_rule_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_mutation_response = {
  __typename: 'flow_step_offer_rule_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_offer_rule_group>;
};

/** input type for inserting object relation for remote table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_obj_rel_insert_input = {
  data: flow_step_offer_rule_group_insert_input;
};

/** Ordering options when selecting data from "flow_step_offer_rule_group". */
export type flow_step_offer_rule_group_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step_offer_rule_group" */
export enum flow_step_offer_rule_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id'
}

/** input type for updating data in table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_offer_rule_group_stddev_fields = {
  __typename: 'flow_step_offer_rule_group_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_offer_rule_group_stddev_pop_fields = {
  __typename: 'flow_step_offer_rule_group_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_offer_rule_group_stddev_samp_fields = {
  __typename: 'flow_step_offer_rule_group_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_offer_rule_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_offer_rule_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_offer_rule_group_sum_fields = {
  __typename: 'flow_step_offer_rule_group_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

export type flow_step_offer_rule_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_offer_rule_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_offer_rule_group_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_offer_rule_group_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_offer_rule_group_var_pop_fields = {
  __typename: 'flow_step_offer_rule_group_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_offer_rule_group_var_samp_fields = {
  __typename: 'flow_step_offer_rule_group_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_offer_rule_group_variance_fields = {
  __typename: 'flow_step_offer_rule_group_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_offer_rule_group" */
export type flow_step_offer_rule_group_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "flow_step" */
export type flow_step_on_conflict = {
  constraint: flow_step_constraint;
  update_columns?: Array<flow_step_update_column>;
  where?: InputMaybe<flow_step_bool_exp>;
};

/** Ordering options when selecting data from "flow_step". */
export type flow_step_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<flow_step_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_step_acknowledgement_group?: InputMaybe<flow_step_acknowledgement_group_order_by>;
  flow_step_acknowledgement_groups_aggregate?: InputMaybe<flow_step_acknowledgement_group_aggregate_order_by>;
  flow_step_confirmation?: InputMaybe<flow_step_confirmation_order_by>;
  flow_step_confirmations_aggregate?: InputMaybe<flow_step_confirmation_aggregate_order_by>;
  flow_step_deflection_rule_group?: InputMaybe<flow_step_deflection_rule_group_order_by>;
  flow_step_deflection_rule_groups_aggregate?: InputMaybe<flow_step_deflection_rule_group_aggregate_order_by>;
  flow_step_form?: InputMaybe<flow_step_form_order_by>;
  flow_step_forms_aggregate?: InputMaybe<flow_step_form_aggregate_order_by>;
  flow_step_intervention?: InputMaybe<flow_step_intervention_order_by>;
  flow_step_interventions_aggregate?: InputMaybe<flow_step_intervention_aggregate_order_by>;
  flow_step_offer_rule_group?: InputMaybe<flow_step_offer_rule_group_order_by>;
  flow_step_offer_rule_groups_aggregate?: InputMaybe<flow_step_offer_rule_group_aggregate_order_by>;
  flow_step_question?: InputMaybe<flow_step_question_order_by>;
  flow_step_questions_aggregate?: InputMaybe<flow_step_question_aggregate_order_by>;
  flow_step_rule_group?: InputMaybe<flow_step_rule_group_order_by>;
  flow_step_rule_groups_aggregate?: InputMaybe<flow_step_rule_group_aggregate_order_by>;
  flow_step_type?: InputMaybe<flow_step_type_order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<flow_step_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step */
export type flow_step_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "flow_step_question" */
export type flow_step_question = {
  __typename: 'flow_step_question';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_question" */
export type flow_step_question_aggregate = {
  __typename: 'flow_step_question_aggregate';
  aggregate?: Maybe<flow_step_question_aggregate_fields>;
  nodes: Array<flow_step_question>;
};

export type flow_step_question_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_question_aggregate_bool_exp_count>;
};

export type flow_step_question_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_question_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_question" */
export type flow_step_question_aggregate_fields = {
  __typename: 'flow_step_question_aggregate_fields';
  avg?: Maybe<flow_step_question_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_question_max_fields>;
  min?: Maybe<flow_step_question_min_fields>;
  stddev?: Maybe<flow_step_question_stddev_fields>;
  stddev_pop?: Maybe<flow_step_question_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_question_stddev_samp_fields>;
  sum?: Maybe<flow_step_question_sum_fields>;
  var_pop?: Maybe<flow_step_question_var_pop_fields>;
  var_samp?: Maybe<flow_step_question_var_samp_fields>;
  variance?: Maybe<flow_step_question_variance_fields>;
};


/** aggregate fields of "flow_step_question" */
export type flow_step_question_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_question" */
export type flow_step_question_aggregate_order_by = {
  avg?: InputMaybe<flow_step_question_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_question_max_order_by>;
  min?: InputMaybe<flow_step_question_min_order_by>;
  stddev?: InputMaybe<flow_step_question_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_question_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_question_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_question_sum_order_by>;
  var_pop?: InputMaybe<flow_step_question_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_question_var_samp_order_by>;
  variance?: InputMaybe<flow_step_question_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_question" */
export type flow_step_question_arr_rel_insert_input = {
  data: Array<flow_step_question_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_question_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_question_avg_fields = {
  __typename: 'flow_step_question_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_question" */
export type flow_step_question_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_question". All fields are combined with a logical 'AND'. */
export type flow_step_question_bool_exp = {
  _and?: InputMaybe<Array<flow_step_question_bool_exp>>;
  _not?: InputMaybe<flow_step_question_bool_exp>;
  _or?: InputMaybe<Array<flow_step_question_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_question" */
export enum flow_step_question_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_question_pkey = 'flow_step_question_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_question" */
export type flow_step_question_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_question" */
export type flow_step_question_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_question_max_fields = {
  __typename: 'flow_step_question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_question" */
export type flow_step_question_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_question_min_fields = {
  __typename: 'flow_step_question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_question" */
export type flow_step_question_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_question" */
export type flow_step_question_mutation_response = {
  __typename: 'flow_step_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_question>;
};

/** input type for inserting object relation for remote table "flow_step_question" */
export type flow_step_question_obj_rel_insert_input = {
  data: flow_step_question_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_question_on_conflict>;
};

/** on_conflict condition type for table "flow_step_question" */
export type flow_step_question_on_conflict = {
  constraint: flow_step_question_constraint;
  update_columns?: Array<flow_step_question_update_column>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_question". */
export type flow_step_question_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_question */
export type flow_step_question_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_question" */
export enum flow_step_question_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  question_id = 'question_id'
}

/** input type for updating data in table "flow_step_question" */
export type flow_step_question_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_question_stddev_fields = {
  __typename: 'flow_step_question_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_question" */
export type flow_step_question_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_question_stddev_pop_fields = {
  __typename: 'flow_step_question_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_question" */
export type flow_step_question_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_question_stddev_samp_fields = {
  __typename: 'flow_step_question_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_question" */
export type flow_step_question_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_question" */
export type flow_step_question_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_question_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_question_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_question_sum_fields = {
  __typename: 'flow_step_question_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_question" */
export type flow_step_question_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_question" */
export enum flow_step_question_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  question_id = 'question_id'
}

export type flow_step_question_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_question_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_question_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_question_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_question_var_pop_fields = {
  __typename: 'flow_step_question_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_question" */
export type flow_step_question_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_question_var_samp_fields = {
  __typename: 'flow_step_question_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_question" */
export type flow_step_question_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_question_variance_fields = {
  __typename: 'flow_step_question_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_question" */
export type flow_step_question_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step_rule_group" */
export type flow_step_rule_group = {
  __typename: 'flow_step_rule_group';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  mode: rule_group_mode_enum;
  /** An object relationship */
  offer_rule_group: offer_rule_group;
  offer_rule_group_id: Scalars['Int']['output'];
};

/** aggregated selection of "flow_step_rule_group" */
export type flow_step_rule_group_aggregate = {
  __typename: 'flow_step_rule_group_aggregate';
  aggregate?: Maybe<flow_step_rule_group_aggregate_fields>;
  nodes: Array<flow_step_rule_group>;
};

export type flow_step_rule_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_rule_group_aggregate_bool_exp_count>;
};

export type flow_step_rule_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_rule_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_rule_group" */
export type flow_step_rule_group_aggregate_fields = {
  __typename: 'flow_step_rule_group_aggregate_fields';
  avg?: Maybe<flow_step_rule_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_rule_group_max_fields>;
  min?: Maybe<flow_step_rule_group_min_fields>;
  stddev?: Maybe<flow_step_rule_group_stddev_fields>;
  stddev_pop?: Maybe<flow_step_rule_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_rule_group_stddev_samp_fields>;
  sum?: Maybe<flow_step_rule_group_sum_fields>;
  var_pop?: Maybe<flow_step_rule_group_var_pop_fields>;
  var_samp?: Maybe<flow_step_rule_group_var_samp_fields>;
  variance?: Maybe<flow_step_rule_group_variance_fields>;
};


/** aggregate fields of "flow_step_rule_group" */
export type flow_step_rule_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_rule_group" */
export type flow_step_rule_group_aggregate_order_by = {
  avg?: InputMaybe<flow_step_rule_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_rule_group_max_order_by>;
  min?: InputMaybe<flow_step_rule_group_min_order_by>;
  stddev?: InputMaybe<flow_step_rule_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_rule_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_rule_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_rule_group_sum_order_by>;
  var_pop?: InputMaybe<flow_step_rule_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_rule_group_var_samp_order_by>;
  variance?: InputMaybe<flow_step_rule_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_rule_group" */
export type flow_step_rule_group_arr_rel_insert_input = {
  data: Array<flow_step_rule_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_rule_group_on_conflict>;
};

/** aggregate avg on columns */
export type flow_step_rule_group_avg_fields = {
  __typename: 'flow_step_rule_group_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_rule_group". All fields are combined with a logical 'AND'. */
export type flow_step_rule_group_bool_exp = {
  _and?: InputMaybe<Array<flow_step_rule_group_bool_exp>>;
  _not?: InputMaybe<flow_step_rule_group_bool_exp>;
  _or?: InputMaybe<Array<flow_step_rule_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  mode?: InputMaybe<rule_group_mode_enum_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_rule_group" */
export enum flow_step_rule_group_constraint {
  /** unique or primary key constraint on columns "flow_step_id" */
  flow_step_offer_rule_group_pkey = 'flow_step_offer_rule_group_pkey'
}

/** input type for incrementing numeric columns in table "flow_step_rule_group" */
export type flow_step_rule_group_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_rule_group" */
export type flow_step_rule_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<rule_group_mode_enum>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_rule_group_max_fields = {
  __typename: 'flow_step_rule_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_rule_group_min_fields = {
  __typename: 'flow_step_rule_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_step_rule_group" */
export type flow_step_rule_group_mutation_response = {
  __typename: 'flow_step_rule_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_rule_group>;
};

/** input type for inserting object relation for remote table "flow_step_rule_group" */
export type flow_step_rule_group_obj_rel_insert_input = {
  data: flow_step_rule_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_rule_group_on_conflict>;
};

/** on_conflict condition type for table "flow_step_rule_group" */
export type flow_step_rule_group_on_conflict = {
  constraint: flow_step_rule_group_constraint;
  update_columns?: Array<flow_step_rule_group_update_column>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_rule_group". */
export type flow_step_rule_group_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  mode?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_rule_group */
export type flow_step_rule_group_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
};

/** select columns of table "flow_step_rule_group" */
export enum flow_step_rule_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  mode = 'mode',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id'
}

/** input type for updating data in table "flow_step_rule_group" */
export type flow_step_rule_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<rule_group_mode_enum>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_rule_group_stddev_fields = {
  __typename: 'flow_step_rule_group_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_rule_group_stddev_pop_fields = {
  __typename: 'flow_step_rule_group_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_rule_group_stddev_samp_fields = {
  __typename: 'flow_step_rule_group_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_rule_group" */
export type flow_step_rule_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_rule_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_rule_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<rule_group_mode_enum>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_rule_group_sum_fields = {
  __typename: 'flow_step_rule_group_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_step_rule_group" */
export enum flow_step_rule_group_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  mode = 'mode',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id'
}

export type flow_step_rule_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_rule_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_rule_group_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_rule_group_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_rule_group_var_pop_fields = {
  __typename: 'flow_step_rule_group_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_rule_group_var_samp_fields = {
  __typename: 'flow_step_rule_group_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_rule_group_variance_fields = {
  __typename: 'flow_step_rule_group_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_rule_group" */
export type flow_step_rule_group_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step" */
export enum flow_step_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_step" */
export type flow_step_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_stddev_fields = {
  __typename: 'flow_step_stddev_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step" */
export type flow_step_stddev_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_stddev_pop_fields = {
  __typename: 'flow_step_stddev_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step" */
export type flow_step_stddev_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_stddev_samp_fields = {
  __typename: 'flow_step_stddev_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step" */
export type flow_step_stddev_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step" */
export type flow_step_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<flow_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_step_sum_fields = {
  __typename: 'flow_step_sum_fields';
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step" */
export type flow_step_sum_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step_type" */
export type flow_step_type = {
  __typename: 'flow_step_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "flow_step_type" */
export type flow_step_type_aggregate = {
  __typename: 'flow_step_type_aggregate';
  aggregate?: Maybe<flow_step_type_aggregate_fields>;
  nodes: Array<flow_step_type>;
};

/** aggregate fields of "flow_step_type" */
export type flow_step_type_aggregate_fields = {
  __typename: 'flow_step_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_type_max_fields>;
  min?: Maybe<flow_step_type_min_fields>;
};


/** aggregate fields of "flow_step_type" */
export type flow_step_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_step_type". All fields are combined with a logical 'AND'. */
export type flow_step_type_bool_exp = {
  _and?: InputMaybe<Array<flow_step_type_bool_exp>>;
  _not?: InputMaybe<flow_step_type_bool_exp>;
  _or?: InputMaybe<Array<flow_step_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_step_type" */
export enum flow_step_type_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_step_type_pkey = 'flow_step_type_pkey'
}

export enum flow_step_type_enum {
  acknowledgement_group = 'acknowledgement_group',
  confirmation = 'confirmation',
  deflection_rule_group = 'deflection_rule_group',
  form = 'form',
  intervention = 'intervention',
  offer_rule_group = 'offer_rule_group',
  question = 'question'
}

/** Boolean expression to compare columns of type "flow_step_type_enum". All fields are combined with logical 'AND'. */
export type flow_step_type_enum_comparison_exp = {
  _eq?: InputMaybe<flow_step_type_enum>;
  _in?: InputMaybe<Array<flow_step_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_step_type_enum>;
  _nin?: InputMaybe<Array<flow_step_type_enum>>;
};

/** input type for inserting data into table "flow_step_type" */
export type flow_step_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_step_type_max_fields = {
  __typename: 'flow_step_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_step_type_min_fields = {
  __typename: 'flow_step_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_step_type" */
export type flow_step_type_mutation_response = {
  __typename: 'flow_step_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_type>;
};

/** input type for inserting object relation for remote table "flow_step_type" */
export type flow_step_type_obj_rel_insert_input = {
  data: flow_step_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_type_on_conflict>;
};

/** on_conflict condition type for table "flow_step_type" */
export type flow_step_type_on_conflict = {
  constraint: flow_step_type_constraint;
  update_columns?: Array<flow_step_type_update_column>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_type". */
export type flow_step_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_type */
export type flow_step_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_step_type" */
export enum flow_step_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_step_type" */
export type flow_step_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_step_type" */
export type flow_step_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_step_type" */
export enum flow_step_type_update_column {
  /** column name */
  value = 'value'
}

export type flow_step_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_type_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_type_bool_exp;
};

/** update columns of table "flow_step" */
export enum flow_step_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_step_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_var_pop_fields = {
  __typename: 'flow_step_var_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step" */
export type flow_step_var_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_var_samp_fields = {
  __typename: 'flow_step_var_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step" */
export type flow_step_var_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_variance_fields = {
  __typename: 'flow_step_variance_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step" */
export type flow_step_variance_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step_version" */
export type flow_step_version = {
  __typename: 'flow_step_version';
  condition_include_other_in_question_ids: Scalars['jsonb']['output'];
  condition_question_option_ids: Scalars['jsonb']['output'];
  condition_segment_group_ids: Scalars['jsonb']['output'];
  condition_segment_ids: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  /** An array relationship */
  flow_step_version_condition_question_options: Array<flow_step_version_condition_question_option>;
  /** An aggregate relationship */
  flow_step_version_condition_question_options_aggregate: flow_step_version_condition_question_option_aggregate;
  /** An array relationship */
  flow_step_version_condition_segment_groups: Array<flow_step_version_condition_segment_group>;
  /** An aggregate relationship */
  flow_step_version_condition_segment_groups_aggregate: flow_step_version_condition_segment_group_aggregate;
  /** An array relationship */
  flow_step_version_condition_segments: Array<flow_step_version_condition_segment>;
  /** An aggregate relationship */
  flow_step_version_condition_segments_aggregate: flow_step_version_condition_segment_aggregate;
  id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versioncondition_include_other_in_question_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versioncondition_question_option_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versioncondition_segment_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versioncondition_segment_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_question_optionsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_question_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_segment_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_segment_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_segmentsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


/** columns and relationships of "flow_step_version" */
export type flow_step_versionflow_step_version_condition_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};

/** aggregated selection of "flow_step_version" */
export type flow_step_version_aggregate = {
  __typename: 'flow_step_version_aggregate';
  aggregate?: Maybe<flow_step_version_aggregate_fields>;
  nodes: Array<flow_step_version>;
};

/** aggregate fields of "flow_step_version" */
export type flow_step_version_aggregate_fields = {
  __typename: 'flow_step_version_aggregate_fields';
  avg?: Maybe<flow_step_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_version_max_fields>;
  min?: Maybe<flow_step_version_min_fields>;
  stddev?: Maybe<flow_step_version_stddev_fields>;
  stddev_pop?: Maybe<flow_step_version_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_version_stddev_samp_fields>;
  sum?: Maybe<flow_step_version_sum_fields>;
  var_pop?: Maybe<flow_step_version_var_pop_fields>;
  var_samp?: Maybe<flow_step_version_var_samp_fields>;
  variance?: Maybe<flow_step_version_variance_fields>;
};


/** aggregate fields of "flow_step_version" */
export type flow_step_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type flow_step_version_append_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type flow_step_version_avg_fields = {
  __typename: 'flow_step_version_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "flow_step_version". All fields are combined with a logical 'AND'. */
export type flow_step_version_bool_exp = {
  _and?: InputMaybe<Array<flow_step_version_bool_exp>>;
  _not?: InputMaybe<flow_step_version_bool_exp>;
  _or?: InputMaybe<Array<flow_step_version_bool_exp>>;
  condition_include_other_in_question_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_question_option_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_segment_group_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_segment_ids?: InputMaybe<jsonb_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  flow_step_version_condition_question_options?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
  flow_step_version_condition_question_options_aggregate?: InputMaybe<flow_step_version_condition_question_option_aggregate_bool_exp>;
  flow_step_version_condition_segment_groups?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
  flow_step_version_condition_segment_groups_aggregate?: InputMaybe<flow_step_version_condition_segment_group_aggregate_bool_exp>;
  flow_step_version_condition_segments?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
  flow_step_version_condition_segments_aggregate?: InputMaybe<flow_step_version_condition_segment_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option = {
  __typename: 'flow_step_version_condition_question_option';
  /** An object relationship */
  flow_step_version?: Maybe<flow_step_version>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_aggregate = {
  __typename: 'flow_step_version_condition_question_option_aggregate';
  aggregate?: Maybe<flow_step_version_condition_question_option_aggregate_fields>;
  nodes: Array<flow_step_version_condition_question_option>;
};

export type flow_step_version_condition_question_option_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_version_condition_question_option_aggregate_bool_exp_count>;
};

export type flow_step_version_condition_question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_aggregate_fields = {
  __typename: 'flow_step_version_condition_question_option_aggregate_fields';
  avg?: Maybe<flow_step_version_condition_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_version_condition_question_option_max_fields>;
  min?: Maybe<flow_step_version_condition_question_option_min_fields>;
  stddev?: Maybe<flow_step_version_condition_question_option_stddev_fields>;
  stddev_pop?: Maybe<flow_step_version_condition_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_version_condition_question_option_stddev_samp_fields>;
  sum?: Maybe<flow_step_version_condition_question_option_sum_fields>;
  var_pop?: Maybe<flow_step_version_condition_question_option_var_pop_fields>;
  var_samp?: Maybe<flow_step_version_condition_question_option_var_samp_fields>;
  variance?: Maybe<flow_step_version_condition_question_option_variance_fields>;
};


/** aggregate fields of "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_aggregate_order_by = {
  avg?: InputMaybe<flow_step_version_condition_question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_version_condition_question_option_max_order_by>;
  min?: InputMaybe<flow_step_version_condition_question_option_min_order_by>;
  stddev?: InputMaybe<flow_step_version_condition_question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_version_condition_question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_version_condition_question_option_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_version_condition_question_option_sum_order_by>;
  var_pop?: InputMaybe<flow_step_version_condition_question_option_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_version_condition_question_option_var_samp_order_by>;
  variance?: InputMaybe<flow_step_version_condition_question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_arr_rel_insert_input = {
  data: Array<flow_step_version_condition_question_option_insert_input>;
};

/** aggregate avg on columns */
export type flow_step_version_condition_question_option_avg_fields = {
  __typename: 'flow_step_version_condition_question_option_avg_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_avg_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_version_condition_question_option". All fields are combined with a logical 'AND'. */
export type flow_step_version_condition_question_option_bool_exp = {
  _and?: InputMaybe<Array<flow_step_version_condition_question_option_bool_exp>>;
  _not?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
  _or?: InputMaybe<Array<flow_step_version_condition_question_option_bool_exp>>;
  flow_step_version?: InputMaybe<flow_step_version_bool_exp>;
  flow_step_version_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_insert_input = {
  flow_step_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_version_condition_question_option_max_fields = {
  __typename: 'flow_step_version_condition_question_option_max_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_max_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_version_condition_question_option_min_fields = {
  __typename: 'flow_step_version_condition_question_option_min_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_min_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_step_version_condition_question_option". */
export type flow_step_version_condition_question_option_order_by = {
  flow_step_version?: InputMaybe<flow_step_version_order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step_version_condition_question_option" */
export enum flow_step_version_condition_question_option_select_column {
  /** column name */
  flow_step_version_id = 'flow_step_version_id',
  /** column name */
  question_option_id = 'question_option_id'
}

/** aggregate stddev on columns */
export type flow_step_version_condition_question_option_stddev_fields = {
  __typename: 'flow_step_version_condition_question_option_stddev_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_stddev_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_version_condition_question_option_stddev_pop_fields = {
  __typename: 'flow_step_version_condition_question_option_stddev_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_stddev_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_version_condition_question_option_stddev_samp_fields = {
  __typename: 'flow_step_version_condition_question_option_stddev_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_stddev_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_version_condition_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_version_condition_question_option_stream_cursor_value_input = {
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_version_condition_question_option_sum_fields = {
  __typename: 'flow_step_version_condition_question_option_sum_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_sum_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_step_version_condition_question_option_var_pop_fields = {
  __typename: 'flow_step_version_condition_question_option_var_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_var_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_version_condition_question_option_var_samp_fields = {
  __typename: 'flow_step_version_condition_question_option_var_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_var_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_version_condition_question_option_variance_fields = {
  __typename: 'flow_step_version_condition_question_option_variance_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_version_condition_question_option" */
export type flow_step_version_condition_question_option_variance_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment = {
  __typename: 'flow_step_version_condition_segment';
  /** An object relationship */
  flow_step_version?: Maybe<flow_step_version>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment?: Maybe<segment>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_aggregate = {
  __typename: 'flow_step_version_condition_segment_aggregate';
  aggregate?: Maybe<flow_step_version_condition_segment_aggregate_fields>;
  nodes: Array<flow_step_version_condition_segment>;
};

export type flow_step_version_condition_segment_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_version_condition_segment_aggregate_bool_exp_count>;
};

export type flow_step_version_condition_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_aggregate_fields = {
  __typename: 'flow_step_version_condition_segment_aggregate_fields';
  avg?: Maybe<flow_step_version_condition_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_version_condition_segment_max_fields>;
  min?: Maybe<flow_step_version_condition_segment_min_fields>;
  stddev?: Maybe<flow_step_version_condition_segment_stddev_fields>;
  stddev_pop?: Maybe<flow_step_version_condition_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_version_condition_segment_stddev_samp_fields>;
  sum?: Maybe<flow_step_version_condition_segment_sum_fields>;
  var_pop?: Maybe<flow_step_version_condition_segment_var_pop_fields>;
  var_samp?: Maybe<flow_step_version_condition_segment_var_samp_fields>;
  variance?: Maybe<flow_step_version_condition_segment_variance_fields>;
};


/** aggregate fields of "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_aggregate_order_by = {
  avg?: InputMaybe<flow_step_version_condition_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_version_condition_segment_max_order_by>;
  min?: InputMaybe<flow_step_version_condition_segment_min_order_by>;
  stddev?: InputMaybe<flow_step_version_condition_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_version_condition_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_version_condition_segment_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_version_condition_segment_sum_order_by>;
  var_pop?: InputMaybe<flow_step_version_condition_segment_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_version_condition_segment_var_samp_order_by>;
  variance?: InputMaybe<flow_step_version_condition_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_arr_rel_insert_input = {
  data: Array<flow_step_version_condition_segment_insert_input>;
};

/** aggregate avg on columns */
export type flow_step_version_condition_segment_avg_fields = {
  __typename: 'flow_step_version_condition_segment_avg_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_avg_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_version_condition_segment". All fields are combined with a logical 'AND'. */
export type flow_step_version_condition_segment_bool_exp = {
  _and?: InputMaybe<Array<flow_step_version_condition_segment_bool_exp>>;
  _not?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
  _or?: InputMaybe<Array<flow_step_version_condition_segment_bool_exp>>;
  flow_step_version?: InputMaybe<flow_step_version_bool_exp>;
  flow_step_version_id?: InputMaybe<Int_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group = {
  __typename: 'flow_step_version_condition_segment_group';
  /** An object relationship */
  flow_step_version?: Maybe<flow_step_version>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment_group?: Maybe<segment_group>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_aggregate = {
  __typename: 'flow_step_version_condition_segment_group_aggregate';
  aggregate?: Maybe<flow_step_version_condition_segment_group_aggregate_fields>;
  nodes: Array<flow_step_version_condition_segment_group>;
};

export type flow_step_version_condition_segment_group_aggregate_bool_exp = {
  count?: InputMaybe<flow_step_version_condition_segment_group_aggregate_bool_exp_count>;
};

export type flow_step_version_condition_segment_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_aggregate_fields = {
  __typename: 'flow_step_version_condition_segment_group_aggregate_fields';
  avg?: Maybe<flow_step_version_condition_segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_step_version_condition_segment_group_max_fields>;
  min?: Maybe<flow_step_version_condition_segment_group_min_fields>;
  stddev?: Maybe<flow_step_version_condition_segment_group_stddev_fields>;
  stddev_pop?: Maybe<flow_step_version_condition_segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_step_version_condition_segment_group_stddev_samp_fields>;
  sum?: Maybe<flow_step_version_condition_segment_group_sum_fields>;
  var_pop?: Maybe<flow_step_version_condition_segment_group_var_pop_fields>;
  var_samp?: Maybe<flow_step_version_condition_segment_group_var_samp_fields>;
  variance?: Maybe<flow_step_version_condition_segment_group_variance_fields>;
};


/** aggregate fields of "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_aggregate_order_by = {
  avg?: InputMaybe<flow_step_version_condition_segment_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_step_version_condition_segment_group_max_order_by>;
  min?: InputMaybe<flow_step_version_condition_segment_group_min_order_by>;
  stddev?: InputMaybe<flow_step_version_condition_segment_group_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_step_version_condition_segment_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_step_version_condition_segment_group_stddev_samp_order_by>;
  sum?: InputMaybe<flow_step_version_condition_segment_group_sum_order_by>;
  var_pop?: InputMaybe<flow_step_version_condition_segment_group_var_pop_order_by>;
  var_samp?: InputMaybe<flow_step_version_condition_segment_group_var_samp_order_by>;
  variance?: InputMaybe<flow_step_version_condition_segment_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_arr_rel_insert_input = {
  data: Array<flow_step_version_condition_segment_group_insert_input>;
};

/** aggregate avg on columns */
export type flow_step_version_condition_segment_group_avg_fields = {
  __typename: 'flow_step_version_condition_segment_group_avg_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_avg_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_step_version_condition_segment_group". All fields are combined with a logical 'AND'. */
export type flow_step_version_condition_segment_group_bool_exp = {
  _and?: InputMaybe<Array<flow_step_version_condition_segment_group_bool_exp>>;
  _not?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
  _or?: InputMaybe<Array<flow_step_version_condition_segment_group_bool_exp>>;
  flow_step_version?: InputMaybe<flow_step_version_bool_exp>;
  flow_step_version_id?: InputMaybe<Int_comparison_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_insert_input = {
  flow_step_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_version_condition_segment_group_max_fields = {
  __typename: 'flow_step_version_condition_segment_group_max_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_max_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_version_condition_segment_group_min_fields = {
  __typename: 'flow_step_version_condition_segment_group_min_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_min_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** input type for inserting object relation for remote table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_obj_rel_insert_input = {
  data: flow_step_version_condition_segment_group_insert_input;
};

/** Ordering options when selecting data from "flow_step_version_condition_segment_group". */
export type flow_step_version_condition_segment_group_order_by = {
  flow_step_version?: InputMaybe<flow_step_version_order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step_version_condition_segment_group" */
export enum flow_step_version_condition_segment_group_select_column {
  /** column name */
  flow_step_version_id = 'flow_step_version_id',
  /** column name */
  segment_group_id = 'segment_group_id'
}

/** aggregate stddev on columns */
export type flow_step_version_condition_segment_group_stddev_fields = {
  __typename: 'flow_step_version_condition_segment_group_stddev_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_stddev_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_version_condition_segment_group_stddev_pop_fields = {
  __typename: 'flow_step_version_condition_segment_group_stddev_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_stddev_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_version_condition_segment_group_stddev_samp_fields = {
  __typename: 'flow_step_version_condition_segment_group_stddev_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_stddev_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_version_condition_segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_version_condition_segment_group_stream_cursor_value_input = {
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_version_condition_segment_group_sum_fields = {
  __typename: 'flow_step_version_condition_segment_group_sum_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_sum_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_step_version_condition_segment_group_var_pop_fields = {
  __typename: 'flow_step_version_condition_segment_group_var_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_var_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_version_condition_segment_group_var_samp_fields = {
  __typename: 'flow_step_version_condition_segment_group_var_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_var_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_version_condition_segment_group_variance_fields = {
  __typename: 'flow_step_version_condition_segment_group_variance_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_version_condition_segment_group" */
export type flow_step_version_condition_segment_group_variance_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** input type for inserting data into table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_insert_input = {
  flow_step_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_step_version_condition_segment_max_fields = {
  __typename: 'flow_step_version_condition_segment_max_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_max_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_step_version_condition_segment_min_fields = {
  __typename: 'flow_step_version_condition_segment_min_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_min_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_step_version_condition_segment". */
export type flow_step_version_condition_segment_order_by = {
  flow_step_version?: InputMaybe<flow_step_version_order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_step_version_condition_segment" */
export enum flow_step_version_condition_segment_select_column {
  /** column name */
  flow_step_version_id = 'flow_step_version_id',
  /** column name */
  segment_id = 'segment_id'
}

/** aggregate stddev on columns */
export type flow_step_version_condition_segment_stddev_fields = {
  __typename: 'flow_step_version_condition_segment_stddev_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_stddev_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_step_version_condition_segment_stddev_pop_fields = {
  __typename: 'flow_step_version_condition_segment_stddev_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_stddev_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_step_version_condition_segment_stddev_samp_fields = {
  __typename: 'flow_step_version_condition_segment_stddev_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_stddev_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_version_condition_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_version_condition_segment_stream_cursor_value_input = {
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_step_version_condition_segment_sum_fields = {
  __typename: 'flow_step_version_condition_segment_sum_fields';
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_sum_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_step_version_condition_segment_var_pop_fields = {
  __typename: 'flow_step_version_condition_segment_var_pop_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_var_pop_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_step_version_condition_segment_var_samp_fields = {
  __typename: 'flow_step_version_condition_segment_var_samp_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_var_samp_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_step_version_condition_segment_variance_fields = {
  __typename: 'flow_step_version_condition_segment_variance_fields';
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_step_version_condition_segment" */
export type flow_step_version_condition_segment_variance_order_by = {
  flow_step_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "flow_step_version" */
export enum flow_step_version_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_step_version_pkey = 'flow_step_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type flow_step_version_delete_at_path_input = {
  condition_include_other_in_question_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_question_option_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_segment_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_segment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type flow_step_version_delete_elem_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type flow_step_version_delete_key_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['String']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['String']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['String']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "flow_step_version" */
export type flow_step_version_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_step_version" */
export type flow_step_version_insert_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_version_condition_question_options?: InputMaybe<flow_step_version_condition_question_option_arr_rel_insert_input>;
  flow_step_version_condition_segment_groups?: InputMaybe<flow_step_version_condition_segment_group_arr_rel_insert_input>;
  flow_step_version_condition_segments?: InputMaybe<flow_step_version_condition_segment_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type flow_step_version_max_fields = {
  __typename: 'flow_step_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type flow_step_version_min_fields = {
  __typename: 'flow_step_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "flow_step_version" */
export type flow_step_version_mutation_response = {
  __typename: 'flow_step_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_step_version>;
};

/** input type for inserting object relation for remote table "flow_step_version" */
export type flow_step_version_obj_rel_insert_input = {
  data: flow_step_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_step_version_on_conflict>;
};

/** on_conflict condition type for table "flow_step_version" */
export type flow_step_version_on_conflict = {
  constraint: flow_step_version_constraint;
  update_columns?: Array<flow_step_version_update_column>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};

/** Ordering options when selecting data from "flow_step_version". */
export type flow_step_version_order_by = {
  condition_include_other_in_question_ids?: InputMaybe<order_by>;
  condition_question_option_ids?: InputMaybe<order_by>;
  condition_segment_group_ids?: InputMaybe<order_by>;
  condition_segment_ids?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_condition_question_options_aggregate?: InputMaybe<flow_step_version_condition_question_option_aggregate_order_by>;
  flow_step_version_condition_segment_groups_aggregate?: InputMaybe<flow_step_version_condition_segment_group_aggregate_order_by>;
  flow_step_version_condition_segments_aggregate?: InputMaybe<flow_step_version_condition_segment_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_step_version */
export type flow_step_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type flow_step_version_prepend_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "flow_step_version" */
export enum flow_step_version_select_column {
  /** column name */
  condition_include_other_in_question_ids = 'condition_include_other_in_question_ids',
  /** column name */
  condition_question_option_ids = 'condition_question_option_ids',
  /** column name */
  condition_segment_group_ids = 'condition_segment_group_ids',
  /** column name */
  condition_segment_ids = 'condition_segment_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_step_version" */
export type flow_step_version_set_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_step_version_stddev_fields = {
  __typename: 'flow_step_version_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type flow_step_version_stddev_pop_fields = {
  __typename: 'flow_step_version_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type flow_step_version_stddev_samp_fields = {
  __typename: 'flow_step_version_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "flow_step_version" */
export type flow_step_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_step_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_step_version_stream_cursor_value_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_step_version_sum_fields = {
  __typename: 'flow_step_version_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "flow_step_version" */
export enum flow_step_version_update_column {
  /** column name */
  condition_include_other_in_question_ids = 'condition_include_other_in_question_ids',
  /** column name */
  condition_question_option_ids = 'condition_question_option_ids',
  /** column name */
  condition_segment_group_ids = 'condition_segment_group_ids',
  /** column name */
  condition_segment_ids = 'condition_segment_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_step_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<flow_step_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<flow_step_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<flow_step_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<flow_step_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_step_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<flow_step_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_step_version_set_input>;
  /** filter the rows which have to be updated */
  where: flow_step_version_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_step_version_var_pop_fields = {
  __typename: 'flow_step_version_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type flow_step_version_var_samp_fields = {
  __typename: 'flow_step_version_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type flow_step_version_variance_fields = {
  __typename: 'flow_step_version_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "flow" */
export type flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  auto_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  auto_cancel_at?: InputMaybe<flow_auto_cancel_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  default_language?: InputMaybe<language_enum>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  on_cancel?: InputMaybe<flow_on_cancel_enum>;
  on_cancel_perform_at?: InputMaybe<flow_on_cancel_perform_at_enum>;
  prevent_if_canceled?: InputMaybe<Scalars['Boolean']['input']>;
  prevent_if_canceled_translation_id?: InputMaybe<Scalars['Int']['input']>;
  preview_property_values?: InputMaybe<Scalars['jsonb']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  redirect_uri?: InputMaybe<Scalars['String']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
  signature_required?: InputMaybe<Scalars['Boolean']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "flow_subscriber_form_question" */
export type flow_subscriber_form_question = {
  __typename: 'flow_subscriber_form_question';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  hint: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  /** An object relationship */
  property: property;
  property_id: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "flow_subscriber_form_question" */
export type flow_subscriber_form_question_aggregate = {
  __typename: 'flow_subscriber_form_question_aggregate';
  aggregate?: Maybe<flow_subscriber_form_question_aggregate_fields>;
  nodes: Array<flow_subscriber_form_question>;
};

export type flow_subscriber_form_question_aggregate_bool_exp = {
  bool_and?: InputMaybe<flow_subscriber_form_question_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<flow_subscriber_form_question_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<flow_subscriber_form_question_aggregate_bool_exp_count>;
};

export type flow_subscriber_form_question_aggregate_bool_exp_bool_and = {
  arguments: flow_subscriber_form_question_select_column_flow_subscriber_form_question_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_subscriber_form_question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_subscriber_form_question_aggregate_bool_exp_bool_or = {
  arguments: flow_subscriber_form_question_select_column_flow_subscriber_form_question_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_subscriber_form_question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type flow_subscriber_form_question_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_subscriber_form_question_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_subscriber_form_question" */
export type flow_subscriber_form_question_aggregate_fields = {
  __typename: 'flow_subscriber_form_question_aggregate_fields';
  avg?: Maybe<flow_subscriber_form_question_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_subscriber_form_question_max_fields>;
  min?: Maybe<flow_subscriber_form_question_min_fields>;
  stddev?: Maybe<flow_subscriber_form_question_stddev_fields>;
  stddev_pop?: Maybe<flow_subscriber_form_question_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_subscriber_form_question_stddev_samp_fields>;
  sum?: Maybe<flow_subscriber_form_question_sum_fields>;
  var_pop?: Maybe<flow_subscriber_form_question_var_pop_fields>;
  var_samp?: Maybe<flow_subscriber_form_question_var_samp_fields>;
  variance?: Maybe<flow_subscriber_form_question_variance_fields>;
};


/** aggregate fields of "flow_subscriber_form_question" */
export type flow_subscriber_form_question_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_aggregate_order_by = {
  avg?: InputMaybe<flow_subscriber_form_question_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_subscriber_form_question_max_order_by>;
  min?: InputMaybe<flow_subscriber_form_question_min_order_by>;
  stddev?: InputMaybe<flow_subscriber_form_question_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_subscriber_form_question_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_subscriber_form_question_stddev_samp_order_by>;
  sum?: InputMaybe<flow_subscriber_form_question_sum_order_by>;
  var_pop?: InputMaybe<flow_subscriber_form_question_var_pop_order_by>;
  var_samp?: InputMaybe<flow_subscriber_form_question_var_samp_order_by>;
  variance?: InputMaybe<flow_subscriber_form_question_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_arr_rel_insert_input = {
  data: Array<flow_subscriber_form_question_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_subscriber_form_question_on_conflict>;
};

/** aggregate avg on columns */
export type flow_subscriber_form_question_avg_fields = {
  __typename: 'flow_subscriber_form_question_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_subscriber_form_question". All fields are combined with a logical 'AND'. */
export type flow_subscriber_form_question_bool_exp = {
  _and?: InputMaybe<Array<flow_subscriber_form_question_bool_exp>>;
  _not?: InputMaybe<flow_subscriber_form_question_bool_exp>;
  _or?: InputMaybe<Array<flow_subscriber_form_question_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  hint?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  property?: InputMaybe<property_bool_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  required?: InputMaybe<Boolean_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow_subscriber_form_question" */
export enum flow_subscriber_form_question_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_subscriber_form_question_pkey = 'flow_subscriber_form_question_pkey'
}

/** input type for incrementing numeric columns in table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  hint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type flow_subscriber_form_question_max_fields = {
  __typename: 'flow_subscriber_form_question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  hint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  hint?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_subscriber_form_question_min_fields = {
  __typename: 'flow_subscriber_form_question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  hint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  hint?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_mutation_response = {
  __typename: 'flow_subscriber_form_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_subscriber_form_question>;
};

/** on_conflict condition type for table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_on_conflict = {
  constraint: flow_subscriber_form_question_constraint;
  update_columns?: Array<flow_subscriber_form_question_update_column>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};

/** Ordering options when selecting data from "flow_subscriber_form_question". */
export type flow_subscriber_form_question_order_by = {
  created_at?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  hint?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property?: InputMaybe<property_order_by>;
  property_id?: InputMaybe<order_by>;
  required?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_subscriber_form_question */
export type flow_subscriber_form_question_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "flow_subscriber_form_question" */
export enum flow_subscriber_form_question_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  hint = 'hint',
  /** column name */
  id = 'id',
  /** column name */
  position = 'position',
  /** column name */
  property_id = 'property_id',
  /** column name */
  required = 'required',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** select "flow_subscriber_form_question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "flow_subscriber_form_question" */
export enum flow_subscriber_form_question_select_column_flow_subscriber_form_question_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  required = 'required'
}

/** select "flow_subscriber_form_question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "flow_subscriber_form_question" */
export enum flow_subscriber_form_question_select_column_flow_subscriber_form_question_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  required = 'required'
}

/** input type for updating data in table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  hint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_subscriber_form_question_stddev_fields = {
  __typename: 'flow_subscriber_form_question_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_subscriber_form_question_stddev_pop_fields = {
  __typename: 'flow_subscriber_form_question_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_subscriber_form_question_stddev_samp_fields = {
  __typename: 'flow_subscriber_form_question_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_subscriber_form_question_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_subscriber_form_question_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  hint?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_subscriber_form_question_sum_fields = {
  __typename: 'flow_subscriber_form_question_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_subscriber_form_question" */
export enum flow_subscriber_form_question_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  hint = 'hint',
  /** column name */
  id = 'id',
  /** column name */
  position = 'position',
  /** column name */
  property_id = 'property_id',
  /** column name */
  required = 'required',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_subscriber_form_question_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_subscriber_form_question_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_subscriber_form_question_set_input>;
  /** filter the rows which have to be updated */
  where: flow_subscriber_form_question_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_subscriber_form_question_var_pop_fields = {
  __typename: 'flow_subscriber_form_question_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_subscriber_form_question_var_samp_fields = {
  __typename: 'flow_subscriber_form_question_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_subscriber_form_question_variance_fields = {
  __typename: 'flow_subscriber_form_question_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_subscriber_form_question" */
export type flow_subscriber_form_question_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type flow_sum_fields = {
  __typename: 'flow_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow" */
export type flow_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_tag" */
export type flow_tag = {
  __typename: 'flow_tag';
  /** An object relationship */
  flow?: Maybe<flow>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tag?: Maybe<tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_tag" */
export type flow_tag_aggregate = {
  __typename: 'flow_tag_aggregate';
  aggregate?: Maybe<flow_tag_aggregate_fields>;
  nodes: Array<flow_tag>;
};

export type flow_tag_aggregate_bool_exp = {
  count?: InputMaybe<flow_tag_aggregate_bool_exp_count>;
};

export type flow_tag_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_tag_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_tag" */
export type flow_tag_aggregate_fields = {
  __typename: 'flow_tag_aggregate_fields';
  avg?: Maybe<flow_tag_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_tag_max_fields>;
  min?: Maybe<flow_tag_min_fields>;
  stddev?: Maybe<flow_tag_stddev_fields>;
  stddev_pop?: Maybe<flow_tag_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_tag_stddev_samp_fields>;
  sum?: Maybe<flow_tag_sum_fields>;
  var_pop?: Maybe<flow_tag_var_pop_fields>;
  var_samp?: Maybe<flow_tag_var_samp_fields>;
  variance?: Maybe<flow_tag_variance_fields>;
};


/** aggregate fields of "flow_tag" */
export type flow_tag_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_tag" */
export type flow_tag_aggregate_order_by = {
  avg?: InputMaybe<flow_tag_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_tag_max_order_by>;
  min?: InputMaybe<flow_tag_min_order_by>;
  stddev?: InputMaybe<flow_tag_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_tag_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_tag_stddev_samp_order_by>;
  sum?: InputMaybe<flow_tag_sum_order_by>;
  var_pop?: InputMaybe<flow_tag_var_pop_order_by>;
  var_samp?: InputMaybe<flow_tag_var_samp_order_by>;
  variance?: InputMaybe<flow_tag_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_tag" */
export type flow_tag_arr_rel_insert_input = {
  data: Array<flow_tag_insert_input>;
};

/** aggregate avg on columns */
export type flow_tag_avg_fields = {
  __typename: 'flow_tag_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_tag" */
export type flow_tag_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_tag". All fields are combined with a logical 'AND'. */
export type flow_tag_bool_exp = {
  _and?: InputMaybe<Array<flow_tag_bool_exp>>;
  _not?: InputMaybe<flow_tag_bool_exp>;
  _or?: InputMaybe<Array<flow_tag_bool_exp>>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  tag?: InputMaybe<tag_bool_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_tag" */
export type flow_tag_insert_input = {
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<tag_obj_rel_insert_input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_tag_max_fields = {
  __typename: 'flow_tag_max_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_tag" */
export type flow_tag_max_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_tag_min_fields = {
  __typename: 'flow_tag_min_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_tag" */
export type flow_tag_min_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_tag". */
export type flow_tag_order_by = {
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  tag?: InputMaybe<tag_order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** select columns of table "flow_tag" */
export enum flow_tag_select_column {
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  tag_id = 'tag_id'
}

/** aggregate stddev on columns */
export type flow_tag_stddev_fields = {
  __typename: 'flow_tag_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_tag" */
export type flow_tag_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_tag_stddev_pop_fields = {
  __typename: 'flow_tag_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_tag" */
export type flow_tag_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_tag_stddev_samp_fields = {
  __typename: 'flow_tag_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_tag" */
export type flow_tag_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_tag" */
export type flow_tag_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_tag_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_tag_stream_cursor_value_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_tag_sum_fields = {
  __typename: 'flow_tag_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_tag" */
export type flow_tag_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_tag_var_pop_fields = {
  __typename: 'flow_tag_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_tag" */
export type flow_tag_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_tag_var_samp_fields = {
  __typename: 'flow_tag_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_tag" */
export type flow_tag_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_tag_variance_fields = {
  __typename: 'flow_tag_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_tag" */
export type flow_tag_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_test" */
export type flow_test = {
  __typename: 'flow_test';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  confidence_threshold: Scalars['numeric']['output'];
  /** An object relationship */
  control_flow: flow;
  control_flow_id: Scalars['Int']['output'];
  control_weight: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  flow_test_flows: Array<flow_test_flow>;
  /** An aggregate relationship */
  flow_test_flows_aggregate: flow_test_flow_aggregate;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  report_flow_test_stats: Array<report_flow_test_stats>;
  /** An aggregate relationship */
  report_flow_test_stats_aggregate: report_flow_test_stats_aggregate;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  winning_flow?: Maybe<flow>;
  winning_flow_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "flow_test" */
export type flow_testflow_test_flowsArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


/** columns and relationships of "flow_test" */
export type flow_testflow_test_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


/** columns and relationships of "flow_test" */
export type flow_testreport_flow_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


/** columns and relationships of "flow_test" */
export type flow_testreport_flow_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};

/** aggregated selection of "flow_test" */
export type flow_test_aggregate = {
  __typename: 'flow_test_aggregate';
  aggregate?: Maybe<flow_test_aggregate_fields>;
  nodes: Array<flow_test>;
};

/** aggregate fields of "flow_test" */
export type flow_test_aggregate_fields = {
  __typename: 'flow_test_aggregate_fields';
  avg?: Maybe<flow_test_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_test_max_fields>;
  min?: Maybe<flow_test_min_fields>;
  stddev?: Maybe<flow_test_stddev_fields>;
  stddev_pop?: Maybe<flow_test_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_test_stddev_samp_fields>;
  sum?: Maybe<flow_test_sum_fields>;
  var_pop?: Maybe<flow_test_var_pop_fields>;
  var_samp?: Maybe<flow_test_var_samp_fields>;
  variance?: Maybe<flow_test_variance_fields>;
};


/** aggregate fields of "flow_test" */
export type flow_test_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type flow_test_avg_fields = {
  __typename: 'flow_test_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "flow_test". All fields are combined with a logical 'AND'. */
export type flow_test_bool_exp = {
  _and?: InputMaybe<Array<flow_test_bool_exp>>;
  _not?: InputMaybe<flow_test_bool_exp>;
  _or?: InputMaybe<Array<flow_test_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  confidence_threshold?: InputMaybe<numeric_comparison_exp>;
  control_flow?: InputMaybe<flow_bool_exp>;
  control_flow_id?: InputMaybe<Int_comparison_exp>;
  control_weight?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_test_flows?: InputMaybe<flow_test_flow_bool_exp>;
  flow_test_flows_aggregate?: InputMaybe<flow_test_flow_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  report_flow_test_stats?: InputMaybe<report_flow_test_stats_bool_exp>;
  report_flow_test_stats_aggregate?: InputMaybe<report_flow_test_stats_aggregate_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  winning_flow?: InputMaybe<flow_bool_exp>;
  winning_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_test" */
export enum flow_test_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_test_pkey = 'flow_test_pkey',
  /** unique or primary key constraint on columns "token" */
  flow_test_token_key = 'flow_test_token_key'
}

/** columns and relationships of "flow_test_flow" */
export type flow_test_flow = {
  __typename: 'flow_test_flow';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_test: flow_test;
  flow_test_id: Scalars['Int']['output'];
  weight: Scalars['Int']['output'];
};

/** aggregated selection of "flow_test_flow" */
export type flow_test_flow_aggregate = {
  __typename: 'flow_test_flow_aggregate';
  aggregate?: Maybe<flow_test_flow_aggregate_fields>;
  nodes: Array<flow_test_flow>;
};

export type flow_test_flow_aggregate_bool_exp = {
  count?: InputMaybe<flow_test_flow_aggregate_bool_exp_count>;
};

export type flow_test_flow_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_test_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_test_flow_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_test_flow" */
export type flow_test_flow_aggregate_fields = {
  __typename: 'flow_test_flow_aggregate_fields';
  avg?: Maybe<flow_test_flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_test_flow_max_fields>;
  min?: Maybe<flow_test_flow_min_fields>;
  stddev?: Maybe<flow_test_flow_stddev_fields>;
  stddev_pop?: Maybe<flow_test_flow_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_test_flow_stddev_samp_fields>;
  sum?: Maybe<flow_test_flow_sum_fields>;
  var_pop?: Maybe<flow_test_flow_var_pop_fields>;
  var_samp?: Maybe<flow_test_flow_var_samp_fields>;
  variance?: Maybe<flow_test_flow_variance_fields>;
};


/** aggregate fields of "flow_test_flow" */
export type flow_test_flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_test_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_test_flow" */
export type flow_test_flow_aggregate_order_by = {
  avg?: InputMaybe<flow_test_flow_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_test_flow_max_order_by>;
  min?: InputMaybe<flow_test_flow_min_order_by>;
  stddev?: InputMaybe<flow_test_flow_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_test_flow_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_test_flow_stddev_samp_order_by>;
  sum?: InputMaybe<flow_test_flow_sum_order_by>;
  var_pop?: InputMaybe<flow_test_flow_var_pop_order_by>;
  var_samp?: InputMaybe<flow_test_flow_var_samp_order_by>;
  variance?: InputMaybe<flow_test_flow_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_test_flow" */
export type flow_test_flow_arr_rel_insert_input = {
  data: Array<flow_test_flow_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_test_flow_on_conflict>;
};

/** aggregate avg on columns */
export type flow_test_flow_avg_fields = {
  __typename: 'flow_test_flow_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_test_flow" */
export type flow_test_flow_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_test_flow". All fields are combined with a logical 'AND'. */
export type flow_test_flow_bool_exp = {
  _and?: InputMaybe<Array<flow_test_flow_bool_exp>>;
  _not?: InputMaybe<flow_test_flow_bool_exp>;
  _or?: InputMaybe<Array<flow_test_flow_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_test?: InputMaybe<flow_test_bool_exp>;
  flow_test_id?: InputMaybe<Int_comparison_exp>;
  weight?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "flow_test_flow" */
export enum flow_test_flow_constraint {
  /** unique or primary key constraint on columns "flow_test_id", "flow_id" */
  flow_test_flow_pkey = 'flow_test_flow_pkey'
}

/** input type for incrementing numeric columns in table "flow_test_flow" */
export type flow_test_flow_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_test_flow" */
export type flow_test_flow_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test?: InputMaybe<flow_test_obj_rel_insert_input>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_test_flow_max_fields = {
  __typename: 'flow_test_flow_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_test_flow" */
export type flow_test_flow_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_test_flow_min_fields = {
  __typename: 'flow_test_flow_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_test_flow" */
export type flow_test_flow_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_test_flow" */
export type flow_test_flow_mutation_response = {
  __typename: 'flow_test_flow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_test_flow>;
};

/** on_conflict condition type for table "flow_test_flow" */
export type flow_test_flow_on_conflict = {
  constraint: flow_test_flow_constraint;
  update_columns?: Array<flow_test_flow_update_column>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};

/** Ordering options when selecting data from "flow_test_flow". */
export type flow_test_flow_order_by = {
  created_at?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test?: InputMaybe<flow_test_order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_test_flow */
export type flow_test_flow_pk_columns_input = {
  flow_id: Scalars['Int']['input'];
  flow_test_id: Scalars['Int']['input'];
};

/** select columns of table "flow_test_flow" */
export enum flow_test_flow_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  weight = 'weight'
}

/** input type for updating data in table "flow_test_flow" */
export type flow_test_flow_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_test_flow_stddev_fields = {
  __typename: 'flow_test_flow_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_test_flow" */
export type flow_test_flow_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_test_flow_stddev_pop_fields = {
  __typename: 'flow_test_flow_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_test_flow" */
export type flow_test_flow_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_test_flow_stddev_samp_fields = {
  __typename: 'flow_test_flow_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_test_flow" */
export type flow_test_flow_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_test_flow" */
export type flow_test_flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_test_flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_test_flow_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_test_flow_sum_fields = {
  __typename: 'flow_test_flow_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_test_flow" */
export type flow_test_flow_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** update columns of table "flow_test_flow" */
export enum flow_test_flow_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  weight = 'weight'
}

export type flow_test_flow_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_test_flow_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_test_flow_set_input>;
  /** filter the rows which have to be updated */
  where: flow_test_flow_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_test_flow_var_pop_fields = {
  __typename: 'flow_test_flow_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_test_flow" */
export type flow_test_flow_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_test_flow_var_samp_fields = {
  __typename: 'flow_test_flow_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_test_flow" */
export type flow_test_flow_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_test_flow_variance_fields = {
  __typename: 'flow_test_flow_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_test_flow" */
export type flow_test_flow_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "flow_test" */
export type flow_test_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_flow_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  winning_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_test" */
export type flow_test_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_flow?: InputMaybe<flow_obj_rel_insert_input>;
  control_flow_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_test_flows?: InputMaybe<flow_test_flow_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  report_flow_test_stats?: InputMaybe<report_flow_test_stats_arr_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_flow?: InputMaybe<flow_obj_rel_insert_input>;
  winning_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_test_max_fields = {
  __typename: 'flow_test_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_flow_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type flow_test_min_fields = {
  __typename: 'flow_test_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_flow_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "flow_test" */
export type flow_test_mutation_response = {
  __typename: 'flow_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_test>;
};

/** input type for inserting object relation for remote table "flow_test" */
export type flow_test_obj_rel_insert_input = {
  data: flow_test_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_test_on_conflict>;
};

/** on_conflict condition type for table "flow_test" */
export type flow_test_on_conflict = {
  constraint: flow_test_constraint;
  update_columns?: Array<flow_test_update_column>;
  where?: InputMaybe<flow_test_bool_exp>;
};

/** Ordering options when selecting data from "flow_test". */
export type flow_test_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_flow?: InputMaybe<flow_order_by>;
  control_flow_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  flow_test_flows_aggregate?: InputMaybe<flow_test_flow_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  report_flow_test_stats_aggregate?: InputMaybe<report_flow_test_stats_aggregate_order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_flow?: InputMaybe<flow_order_by>;
  winning_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_test */
export type flow_test_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "flow_test" */
export enum flow_test_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  control_flow_id = 'control_flow_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_flow_id = 'winning_flow_id'
}

/** input type for updating data in table "flow_test" */
export type flow_test_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_flow_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type flow_test_stddev_fields = {
  __typename: 'flow_test_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type flow_test_stddev_pop_fields = {
  __typename: 'flow_test_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type flow_test_stddev_samp_fields = {
  __typename: 'flow_test_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "flow_test" */
export type flow_test_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_test_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_test_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_flow_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_test_sum_fields = {
  __typename: 'flow_test_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_flow_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  winning_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "flow_test" */
export enum flow_test_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  control_flow_id = 'control_flow_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_flow_id = 'winning_flow_id'
}

export type flow_test_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_test_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_test_set_input>;
  /** filter the rows which have to be updated */
  where: flow_test_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_test_var_pop_fields = {
  __typename: 'flow_test_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type flow_test_var_samp_fields = {
  __typename: 'flow_test_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type flow_test_variance_fields = {
  __typename: 'flow_test_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_flow_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "flow_text" */
export type flow_text = {
  __typename: 'flow_text';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<flow_text_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_text_key: flow_text_key;
  key: flow_text_key_enum;
  /** An object relationship */
  published_version?: Maybe<flow_text_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "flow_text" */
export type flow_text_aggregate = {
  __typename: 'flow_text_aggregate';
  aggregate?: Maybe<flow_text_aggregate_fields>;
  nodes: Array<flow_text>;
};

export type flow_text_aggregate_bool_exp = {
  count?: InputMaybe<flow_text_aggregate_bool_exp_count>;
};

export type flow_text_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_text_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_text" */
export type flow_text_aggregate_fields = {
  __typename: 'flow_text_aggregate_fields';
  avg?: Maybe<flow_text_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_text_max_fields>;
  min?: Maybe<flow_text_min_fields>;
  stddev?: Maybe<flow_text_stddev_fields>;
  stddev_pop?: Maybe<flow_text_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_text_stddev_samp_fields>;
  sum?: Maybe<flow_text_sum_fields>;
  var_pop?: Maybe<flow_text_var_pop_fields>;
  var_samp?: Maybe<flow_text_var_samp_fields>;
  variance?: Maybe<flow_text_variance_fields>;
};


/** aggregate fields of "flow_text" */
export type flow_text_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_text" */
export type flow_text_aggregate_order_by = {
  avg?: InputMaybe<flow_text_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_text_max_order_by>;
  min?: InputMaybe<flow_text_min_order_by>;
  stddev?: InputMaybe<flow_text_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_text_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_text_stddev_samp_order_by>;
  sum?: InputMaybe<flow_text_sum_order_by>;
  var_pop?: InputMaybe<flow_text_var_pop_order_by>;
  var_samp?: InputMaybe<flow_text_var_samp_order_by>;
  variance?: InputMaybe<flow_text_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_text" */
export type flow_text_arr_rel_insert_input = {
  data: Array<flow_text_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_text_on_conflict>;
};

/** aggregate avg on columns */
export type flow_text_avg_fields = {
  __typename: 'flow_text_avg_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_text" */
export type flow_text_avg_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_text". All fields are combined with a logical 'AND'. */
export type flow_text_bool_exp = {
  _and?: InputMaybe<Array<flow_text_bool_exp>>;
  _not?: InputMaybe<flow_text_bool_exp>;
  _or?: InputMaybe<Array<flow_text_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<flow_text_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_text_key?: InputMaybe<flow_text_key_bool_exp>;
  key?: InputMaybe<flow_text_key_enum_comparison_exp>;
  published_version?: InputMaybe<flow_text_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow_text" */
export enum flow_text_constraint {
  /** unique or primary key constraint on columns "key", "flow_id" */
  flow_text_pkey = 'flow_text_pkey'
}

/** input type for incrementing numeric columns in table "flow_text" */
export type flow_text_inc_input = {
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_text" */
export type flow_text_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<flow_text_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_text_key?: InputMaybe<flow_text_key_obj_rel_insert_input>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version?: InputMaybe<flow_text_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "flow_text_key" */
export type flow_text_key = {
  __typename: 'flow_text_key';
  /** An array relationship */
  flow_texts: Array<flow_text>;
  /** An aggregate relationship */
  flow_texts_aggregate: flow_text_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "flow_text_key" */
export type flow_text_keyflow_textsArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


/** columns and relationships of "flow_text_key" */
export type flow_text_keyflow_texts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};

/** aggregated selection of "flow_text_key" */
export type flow_text_key_aggregate = {
  __typename: 'flow_text_key_aggregate';
  aggregate?: Maybe<flow_text_key_aggregate_fields>;
  nodes: Array<flow_text_key>;
};

/** aggregate fields of "flow_text_key" */
export type flow_text_key_aggregate_fields = {
  __typename: 'flow_text_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<flow_text_key_max_fields>;
  min?: Maybe<flow_text_key_min_fields>;
};


/** aggregate fields of "flow_text_key" */
export type flow_text_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_text_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "flow_text_key". All fields are combined with a logical 'AND'. */
export type flow_text_key_bool_exp = {
  _and?: InputMaybe<Array<flow_text_key_bool_exp>>;
  _not?: InputMaybe<flow_text_key_bool_exp>;
  _or?: InputMaybe<Array<flow_text_key_bool_exp>>;
  flow_texts?: InputMaybe<flow_text_bool_exp>;
  flow_texts_aggregate?: InputMaybe<flow_text_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "flow_text_key" */
export enum flow_text_key_constraint {
  /** unique or primary key constraint on columns "value" */
  flow_text_key_pkey = 'flow_text_key_pkey'
}

export enum flow_text_key_enum {
  abort_button = 'abort_button',
  cancel_button = 'cancel_button',
  close_button = 'close_button',
  decline_offer_button = 'decline_offer_button',
  headline = 'headline',
  immediate_cancel_button = 'immediate_cancel_button',
  next_button = 'next_button',
  other_option = 'other_option',
  specify_placeholder = 'specify_placeholder',
  subheadline = 'subheadline'
}

/** Boolean expression to compare columns of type "flow_text_key_enum". All fields are combined with logical 'AND'. */
export type flow_text_key_enum_comparison_exp = {
  _eq?: InputMaybe<flow_text_key_enum>;
  _in?: InputMaybe<Array<flow_text_key_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<flow_text_key_enum>;
  _nin?: InputMaybe<Array<flow_text_key_enum>>;
};

/** input type for inserting data into table "flow_text_key" */
export type flow_text_key_insert_input = {
  flow_texts?: InputMaybe<flow_text_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type flow_text_key_max_fields = {
  __typename: 'flow_text_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type flow_text_key_min_fields = {
  __typename: 'flow_text_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "flow_text_key" */
export type flow_text_key_mutation_response = {
  __typename: 'flow_text_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_text_key>;
};

/** input type for inserting object relation for remote table "flow_text_key" */
export type flow_text_key_obj_rel_insert_input = {
  data: flow_text_key_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_text_key_on_conflict>;
};

/** on_conflict condition type for table "flow_text_key" */
export type flow_text_key_on_conflict = {
  constraint: flow_text_key_constraint;
  update_columns?: Array<flow_text_key_update_column>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};

/** Ordering options when selecting data from "flow_text_key". */
export type flow_text_key_order_by = {
  flow_texts_aggregate?: InputMaybe<flow_text_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_text_key */
export type flow_text_key_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "flow_text_key" */
export enum flow_text_key_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "flow_text_key" */
export type flow_text_key_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "flow_text_key" */
export type flow_text_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_text_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_text_key_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "flow_text_key" */
export enum flow_text_key_update_column {
  /** column name */
  value = 'value'
}

export type flow_text_key_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_text_key_set_input>;
  /** filter the rows which have to be updated */
  where: flow_text_key_bool_exp;
};

/** aggregate max on columns */
export type flow_text_max_fields = {
  __typename: 'flow_text_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow_text" */
export type flow_text_max_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_text_min_fields = {
  __typename: 'flow_text_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow_text" */
export type flow_text_min_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_text" */
export type flow_text_mutation_response = {
  __typename: 'flow_text_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_text>;
};

/** input type for inserting object relation for remote table "flow_text" */
export type flow_text_obj_rel_insert_input = {
  data: flow_text_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_text_on_conflict>;
};

/** on_conflict condition type for table "flow_text" */
export type flow_text_on_conflict = {
  constraint: flow_text_constraint;
  update_columns?: Array<flow_text_update_column>;
  where?: InputMaybe<flow_text_bool_exp>;
};

/** Ordering options when selecting data from "flow_text". */
export type flow_text_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<flow_text_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_text_key?: InputMaybe<flow_text_key_order_by>;
  key?: InputMaybe<order_by>;
  published_version?: InputMaybe<flow_text_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_text */
export type flow_text_pk_columns_input = {
  flow_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};

/** select columns of table "flow_text" */
export enum flow_text_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_text" */
export type flow_text_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_text_stddev_fields = {
  __typename: 'flow_text_stddev_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_text" */
export type flow_text_stddev_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_text_stddev_pop_fields = {
  __typename: 'flow_text_stddev_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_text" */
export type flow_text_stddev_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_text_stddev_samp_fields = {
  __typename: 'flow_text_stddev_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_text" */
export type flow_text_stddev_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_text" */
export type flow_text_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_text_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_text_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_text_sum_fields = {
  __typename: 'flow_text_sum_fields';
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_text" */
export type flow_text_sum_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_text" */
export enum flow_text_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_text_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_text_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_text_set_input>;
  /** filter the rows which have to be updated */
  where: flow_text_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_text_var_pop_fields = {
  __typename: 'flow_text_var_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_text" */
export type flow_text_var_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_text_var_samp_fields = {
  __typename: 'flow_text_var_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_text" */
export type flow_text_var_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_text_variance_fields = {
  __typename: 'flow_text_variance_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_text" */
export type flow_text_variance_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_text_version" */
export type flow_text_version = {
  __typename: 'flow_text_version';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_text?: Maybe<flow_text>;
  id: Scalars['Int']['output'];
  key: flow_text_key_enum;
  /** An object relationship */
  translation: translation;
  translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "flow_text_version" */
export type flow_text_version_aggregate = {
  __typename: 'flow_text_version_aggregate';
  aggregate?: Maybe<flow_text_version_aggregate_fields>;
  nodes: Array<flow_text_version>;
};

export type flow_text_version_aggregate_bool_exp = {
  count?: InputMaybe<flow_text_version_aggregate_bool_exp_count>;
};

export type flow_text_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_text_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_text_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_text_version" */
export type flow_text_version_aggregate_fields = {
  __typename: 'flow_text_version_aggregate_fields';
  avg?: Maybe<flow_text_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_text_version_max_fields>;
  min?: Maybe<flow_text_version_min_fields>;
  stddev?: Maybe<flow_text_version_stddev_fields>;
  stddev_pop?: Maybe<flow_text_version_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_text_version_stddev_samp_fields>;
  sum?: Maybe<flow_text_version_sum_fields>;
  var_pop?: Maybe<flow_text_version_var_pop_fields>;
  var_samp?: Maybe<flow_text_version_var_samp_fields>;
  variance?: Maybe<flow_text_version_variance_fields>;
};


/** aggregate fields of "flow_text_version" */
export type flow_text_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_text_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_text_version" */
export type flow_text_version_aggregate_order_by = {
  avg?: InputMaybe<flow_text_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_text_version_max_order_by>;
  min?: InputMaybe<flow_text_version_min_order_by>;
  stddev?: InputMaybe<flow_text_version_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_text_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_text_version_stddev_samp_order_by>;
  sum?: InputMaybe<flow_text_version_sum_order_by>;
  var_pop?: InputMaybe<flow_text_version_var_pop_order_by>;
  var_samp?: InputMaybe<flow_text_version_var_samp_order_by>;
  variance?: InputMaybe<flow_text_version_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_text_version" */
export type flow_text_version_arr_rel_insert_input = {
  data: Array<flow_text_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_text_version_on_conflict>;
};

/** aggregate avg on columns */
export type flow_text_version_avg_fields = {
  __typename: 'flow_text_version_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_text_version" */
export type flow_text_version_avg_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_text_version". All fields are combined with a logical 'AND'. */
export type flow_text_version_bool_exp = {
  _and?: InputMaybe<Array<flow_text_version_bool_exp>>;
  _not?: InputMaybe<flow_text_version_bool_exp>;
  _or?: InputMaybe<Array<flow_text_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_text?: InputMaybe<flow_text_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<flow_text_key_enum_comparison_exp>;
  translation?: InputMaybe<translation_bool_exp>;
  translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow_text_version" */
export enum flow_text_version_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_text_version_pkey = 'flow_text_version_pkey'
}

/** input type for incrementing numeric columns in table "flow_text_version" */
export type flow_text_version_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_text_version" */
export type flow_text_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_text?: InputMaybe<flow_text_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation?: InputMaybe<translation_obj_rel_insert_input>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type flow_text_version_max_fields = {
  __typename: 'flow_text_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "flow_text_version" */
export type flow_text_version_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_text_version_min_fields = {
  __typename: 'flow_text_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "flow_text_version" */
export type flow_text_version_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "flow_text_version" */
export type flow_text_version_mutation_response = {
  __typename: 'flow_text_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_text_version>;
};

/** input type for inserting object relation for remote table "flow_text_version" */
export type flow_text_version_obj_rel_insert_input = {
  data: flow_text_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_text_version_on_conflict>;
};

/** on_conflict condition type for table "flow_text_version" */
export type flow_text_version_on_conflict = {
  constraint: flow_text_version_constraint;
  update_columns?: Array<flow_text_version_update_column>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};

/** Ordering options when selecting data from "flow_text_version". */
export type flow_text_version_order_by = {
  created_at?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_text?: InputMaybe<flow_text_order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  translation?: InputMaybe<translation_order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_text_version */
export type flow_text_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "flow_text_version" */
export enum flow_text_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_text_version" */
export type flow_text_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_text_version_stddev_fields = {
  __typename: 'flow_text_version_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_text_version" */
export type flow_text_version_stddev_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_text_version_stddev_pop_fields = {
  __typename: 'flow_text_version_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_text_version" */
export type flow_text_version_stddev_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_text_version_stddev_samp_fields = {
  __typename: 'flow_text_version_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_text_version" */
export type flow_text_version_stddev_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_text_version" */
export type flow_text_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_text_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_text_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<flow_text_key_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_text_version_sum_fields = {
  __typename: 'flow_text_version_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_text_version" */
export type flow_text_version_sum_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** update columns of table "flow_text_version" */
export enum flow_text_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_text_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_text_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_text_version_set_input>;
  /** filter the rows which have to be updated */
  where: flow_text_version_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_text_version_var_pop_fields = {
  __typename: 'flow_text_version_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_text_version" */
export type flow_text_version_var_pop_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_text_version_var_samp_fields = {
  __typename: 'flow_text_version_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_text_version" */
export type flow_text_version_var_samp_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_text_version_variance_fields = {
  __typename: 'flow_text_version_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_text_version" */
export type flow_text_version_variance_order_by = {
  flow_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
};

/** update columns of table "flow" */
export enum flow_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  auto_cancel = 'auto_cancel',
  /** column name */
  auto_cancel_at = 'auto_cancel_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  default_language = 'default_language',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  logo_url = 'logo_url',
  /** column name */
  on_cancel = 'on_cancel',
  /** column name */
  on_cancel_perform_at = 'on_cancel_perform_at',
  /** column name */
  prevent_if_canceled = 'prevent_if_canceled',
  /** column name */
  prevent_if_canceled_translation_id = 'prevent_if_canceled_translation_id',
  /** column name */
  preview_property_values = 'preview_property_values',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  redirect_uri = 'redirect_uri',
  /** column name */
  secret = 'secret',
  /** column name */
  signature_required = 'signature_required',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<flow_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<flow_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<flow_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<flow_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<flow_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_set_input>;
  /** filter the rows which have to be updated */
  where: flow_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_var_pop_fields = {
  __typename: 'flow_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow" */
export type flow_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_var_samp_fields = {
  __typename: 'flow_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow" */
export type flow_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_variance_fields = {
  __typename: 'flow_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  prevent_if_canceled_translation_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow" */
export type flow_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  prevent_if_canceled_translation_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "flow_version" */
export type flow_version = {
  __typename: 'flow_version';
  created_at: Scalars['timestamptz']['output'];
  css?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  flow_step_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  flow_version_flow_steps: Array<flow_version_flow_step>;
  /** An aggregate relationship */
  flow_version_flow_steps_aggregate: flow_version_flow_step_aggregate;
  id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "flow_version" */
export type flow_versionflow_step_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "flow_version" */
export type flow_versionflow_version_flow_stepsArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


/** columns and relationships of "flow_version" */
export type flow_versionflow_version_flow_steps_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};

/** aggregated selection of "flow_version" */
export type flow_version_aggregate = {
  __typename: 'flow_version_aggregate';
  aggregate?: Maybe<flow_version_aggregate_fields>;
  nodes: Array<flow_version>;
};

/** aggregate fields of "flow_version" */
export type flow_version_aggregate_fields = {
  __typename: 'flow_version_aggregate_fields';
  avg?: Maybe<flow_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_version_max_fields>;
  min?: Maybe<flow_version_min_fields>;
  stddev?: Maybe<flow_version_stddev_fields>;
  stddev_pop?: Maybe<flow_version_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_version_stddev_samp_fields>;
  sum?: Maybe<flow_version_sum_fields>;
  var_pop?: Maybe<flow_version_var_pop_fields>;
  var_samp?: Maybe<flow_version_var_samp_fields>;
  variance?: Maybe<flow_version_variance_fields>;
};


/** aggregate fields of "flow_version" */
export type flow_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type flow_version_append_input = {
  flow_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type flow_version_avg_fields = {
  __typename: 'flow_version_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "flow_version". All fields are combined with a logical 'AND'. */
export type flow_version_bool_exp = {
  _and?: InputMaybe<Array<flow_version_bool_exp>>;
  _not?: InputMaybe<flow_version_bool_exp>;
  _or?: InputMaybe<Array<flow_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  css?: InputMaybe<String_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_step_ids?: InputMaybe<jsonb_comparison_exp>;
  flow_version_flow_steps?: InputMaybe<flow_version_flow_step_bool_exp>;
  flow_version_flow_steps_aggregate?: InputMaybe<flow_version_flow_step_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "flow_version" */
export enum flow_version_constraint {
  /** unique or primary key constraint on columns "id" */
  flow_version_pkey = 'flow_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type flow_version_delete_at_path_input = {
  flow_step_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type flow_version_delete_elem_input = {
  flow_step_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type flow_version_delete_key_input = {
  flow_step_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "flow_version_flow_step" */
export type flow_version_flow_step = {
  __typename: 'flow_version_flow_step';
  /** An object relationship */
  flow_step?: Maybe<flow_step>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_version?: Maybe<flow_version>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "flow_version_flow_step" */
export type flow_version_flow_step_aggregate = {
  __typename: 'flow_version_flow_step_aggregate';
  aggregate?: Maybe<flow_version_flow_step_aggregate_fields>;
  nodes: Array<flow_version_flow_step>;
};

export type flow_version_flow_step_aggregate_bool_exp = {
  count?: InputMaybe<flow_version_flow_step_aggregate_bool_exp_count>;
};

export type flow_version_flow_step_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<flow_version_flow_step_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "flow_version_flow_step" */
export type flow_version_flow_step_aggregate_fields = {
  __typename: 'flow_version_flow_step_aggregate_fields';
  avg?: Maybe<flow_version_flow_step_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<flow_version_flow_step_max_fields>;
  min?: Maybe<flow_version_flow_step_min_fields>;
  stddev?: Maybe<flow_version_flow_step_stddev_fields>;
  stddev_pop?: Maybe<flow_version_flow_step_stddev_pop_fields>;
  stddev_samp?: Maybe<flow_version_flow_step_stddev_samp_fields>;
  sum?: Maybe<flow_version_flow_step_sum_fields>;
  var_pop?: Maybe<flow_version_flow_step_var_pop_fields>;
  var_samp?: Maybe<flow_version_flow_step_var_samp_fields>;
  variance?: Maybe<flow_version_flow_step_variance_fields>;
};


/** aggregate fields of "flow_version_flow_step" */
export type flow_version_flow_step_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "flow_version_flow_step" */
export type flow_version_flow_step_aggregate_order_by = {
  avg?: InputMaybe<flow_version_flow_step_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<flow_version_flow_step_max_order_by>;
  min?: InputMaybe<flow_version_flow_step_min_order_by>;
  stddev?: InputMaybe<flow_version_flow_step_stddev_order_by>;
  stddev_pop?: InputMaybe<flow_version_flow_step_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<flow_version_flow_step_stddev_samp_order_by>;
  sum?: InputMaybe<flow_version_flow_step_sum_order_by>;
  var_pop?: InputMaybe<flow_version_flow_step_var_pop_order_by>;
  var_samp?: InputMaybe<flow_version_flow_step_var_samp_order_by>;
  variance?: InputMaybe<flow_version_flow_step_variance_order_by>;
};

/** input type for inserting array relation for remote table "flow_version_flow_step" */
export type flow_version_flow_step_arr_rel_insert_input = {
  data: Array<flow_version_flow_step_insert_input>;
};

/** aggregate avg on columns */
export type flow_version_flow_step_avg_fields = {
  __typename: 'flow_version_flow_step_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "flow_version_flow_step". All fields are combined with a logical 'AND'. */
export type flow_version_flow_step_bool_exp = {
  _and?: InputMaybe<Array<flow_version_flow_step_bool_exp>>;
  _not?: InputMaybe<flow_version_flow_step_bool_exp>;
  _or?: InputMaybe<Array<flow_version_flow_step_bool_exp>>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  flow_version?: InputMaybe<flow_version_bool_exp>;
  flow_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "flow_version_flow_step" */
export type flow_version_flow_step_insert_input = {
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version?: InputMaybe<flow_version_obj_rel_insert_input>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type flow_version_flow_step_max_fields = {
  __typename: 'flow_version_flow_step_max_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_max_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type flow_version_flow_step_min_fields = {
  __typename: 'flow_version_flow_step_min_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_min_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "flow_version_flow_step". */
export type flow_version_flow_step_order_by = {
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  flow_version?: InputMaybe<flow_version_order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "flow_version_flow_step" */
export enum flow_version_flow_step_select_column {
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  flow_version_id = 'flow_version_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type flow_version_flow_step_stddev_fields = {
  __typename: 'flow_version_flow_step_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type flow_version_flow_step_stddev_pop_fields = {
  __typename: 'flow_version_flow_step_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type flow_version_flow_step_stddev_samp_fields = {
  __typename: 'flow_version_flow_step_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "flow_version_flow_step" */
export type flow_version_flow_step_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_version_flow_step_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_version_flow_step_stream_cursor_value_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type flow_version_flow_step_sum_fields = {
  __typename: 'flow_version_flow_step_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type flow_version_flow_step_var_pop_fields = {
  __typename: 'flow_version_flow_step_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type flow_version_flow_step_var_samp_fields = {
  __typename: 'flow_version_flow_step_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type flow_version_flow_step_variance_fields = {
  __typename: 'flow_version_flow_step_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "flow_version_flow_step" */
export type flow_version_flow_step_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "flow_version" */
export type flow_version_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "flow_version" */
export type flow_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
  flow_version_flow_steps?: InputMaybe<flow_version_flow_step_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type flow_version_max_fields = {
  __typename: 'flow_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type flow_version_min_fields = {
  __typename: 'flow_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  css?: Maybe<Scalars['String']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "flow_version" */
export type flow_version_mutation_response = {
  __typename: 'flow_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<flow_version>;
};

/** input type for inserting object relation for remote table "flow_version" */
export type flow_version_obj_rel_insert_input = {
  data: flow_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<flow_version_on_conflict>;
};

/** on_conflict condition type for table "flow_version" */
export type flow_version_on_conflict = {
  constraint: flow_version_constraint;
  update_columns?: Array<flow_version_update_column>;
  where?: InputMaybe<flow_version_bool_exp>;
};

/** Ordering options when selecting data from "flow_version". */
export type flow_version_order_by = {
  created_at?: InputMaybe<order_by>;
  css?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_step_ids?: InputMaybe<order_by>;
  flow_version_flow_steps_aggregate?: InputMaybe<flow_version_flow_step_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: flow_version */
export type flow_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type flow_version_prepend_input = {
  flow_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "flow_version" */
export enum flow_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_step_ids = 'flow_step_ids',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "flow_version" */
export type flow_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type flow_version_stddev_fields = {
  __typename: 'flow_version_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type flow_version_stddev_pop_fields = {
  __typename: 'flow_version_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type flow_version_stddev_samp_fields = {
  __typename: 'flow_version_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "flow_version" */
export type flow_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: flow_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type flow_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  css?: InputMaybe<Scalars['String']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type flow_version_sum_fields = {
  __typename: 'flow_version_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "flow_version" */
export enum flow_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  css = 'css',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_step_ids = 'flow_step_ids',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

export type flow_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<flow_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<flow_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<flow_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<flow_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<flow_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<flow_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<flow_version_set_input>;
  /** filter the rows which have to be updated */
  where: flow_version_bool_exp;
};

/** aggregate var_pop on columns */
export type flow_version_var_pop_fields = {
  __typename: 'flow_version_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type flow_version_var_samp_fields = {
  __typename: 'flow_version_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type flow_version_variance_fields = {
  __typename: 'flow_version_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "form" */
export type form = {
  __typename: 'form';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  draft_version?: Maybe<form_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_forms: Array<flow_step_form>;
  /** An aggregate relationship */
  flow_step_forms_aggregate: flow_step_form_aggregate;
  /** An array relationship */
  form_versions: Array<form_version>;
  /** An aggregate relationship */
  form_versions_aggregate: form_version_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<form_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "form" */
export type formflow_step_formsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


/** columns and relationships of "form" */
export type formflow_step_forms_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


/** columns and relationships of "form" */
export type formform_versionsArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};


/** columns and relationships of "form" */
export type formform_versions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};

/** aggregated selection of "form" */
export type form_aggregate = {
  __typename: 'form_aggregate';
  aggregate?: Maybe<form_aggregate_fields>;
  nodes: Array<form>;
};

/** aggregate fields of "form" */
export type form_aggregate_fields = {
  __typename: 'form_aggregate_fields';
  avg?: Maybe<form_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<form_max_fields>;
  min?: Maybe<form_min_fields>;
  stddev?: Maybe<form_stddev_fields>;
  stddev_pop?: Maybe<form_stddev_pop_fields>;
  stddev_samp?: Maybe<form_stddev_samp_fields>;
  sum?: Maybe<form_sum_fields>;
  var_pop?: Maybe<form_var_pop_fields>;
  var_samp?: Maybe<form_var_samp_fields>;
  variance?: Maybe<form_variance_fields>;
};


/** aggregate fields of "form" */
export type form_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<form_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type form_avg_fields = {
  __typename: 'form_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "form". All fields are combined with a logical 'AND'. */
export type form_bool_exp = {
  _and?: InputMaybe<Array<form_bool_exp>>;
  _not?: InputMaybe<form_bool_exp>;
  _or?: InputMaybe<Array<form_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<form_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_forms?: InputMaybe<flow_step_form_bool_exp>;
  flow_step_forms_aggregate?: InputMaybe<flow_step_form_aggregate_bool_exp>;
  form_versions?: InputMaybe<form_version_bool_exp>;
  form_versions_aggregate?: InputMaybe<form_version_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<form_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "form" */
export enum form_constraint {
  /** unique or primary key constraint on columns "id" */
  form_pkey = 'form_pkey'
}

/** input type for incrementing numeric columns in table "form" */
export type form_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "form" */
export type form_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<form_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_forms?: InputMaybe<flow_step_form_arr_rel_insert_input>;
  form_versions?: InputMaybe<form_version_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<form_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type form_max_fields = {
  __typename: 'form_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type form_min_fields = {
  __typename: 'form_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "form" */
export type form_mutation_response = {
  __typename: 'form_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<form>;
};

/** input type for inserting object relation for remote table "form" */
export type form_obj_rel_insert_input = {
  data: form_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<form_on_conflict>;
};

/** on_conflict condition type for table "form" */
export type form_on_conflict = {
  constraint: form_constraint;
  update_columns?: Array<form_update_column>;
  where?: InputMaybe<form_bool_exp>;
};

/** Ordering options when selecting data from "form". */
export type form_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<form_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_forms_aggregate?: InputMaybe<flow_step_form_aggregate_order_by>;
  form_versions_aggregate?: InputMaybe<form_version_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<form_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: form */
export type form_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "form" */
export enum form_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "form" */
export type form_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type form_stddev_fields = {
  __typename: 'form_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type form_stddev_pop_fields = {
  __typename: 'form_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type form_stddev_samp_fields = {
  __typename: 'form_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "form" */
export type form_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: form_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type form_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type form_sum_fields = {
  __typename: 'form_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "form" */
export enum form_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

export type form_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<form_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<form_set_input>;
  /** filter the rows which have to be updated */
  where: form_bool_exp;
};

/** aggregate var_pop on columns */
export type form_var_pop_fields = {
  __typename: 'form_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type form_var_samp_fields = {
  __typename: 'form_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type form_variance_fields = {
  __typename: 'form_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "form_version" */
export type form_version = {
  __typename: 'form_version';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  form: form;
  form_id: Scalars['Int']['output'];
  /** An array relationship */
  form_version_questions: Array<form_version_question>;
  /** An aggregate relationship */
  form_version_questions_aggregate: form_version_question_aggregate;
  id: Scalars['Int']['output'];
  question_ids: Scalars['jsonb']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "form_version" */
export type form_versionform_version_questionsArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


/** columns and relationships of "form_version" */
export type form_versionform_version_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


/** columns and relationships of "form_version" */
export type form_versionquestion_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "form_version" */
export type form_version_aggregate = {
  __typename: 'form_version_aggregate';
  aggregate?: Maybe<form_version_aggregate_fields>;
  nodes: Array<form_version>;
};

export type form_version_aggregate_bool_exp = {
  count?: InputMaybe<form_version_aggregate_bool_exp_count>;
};

export type form_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<form_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<form_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "form_version" */
export type form_version_aggregate_fields = {
  __typename: 'form_version_aggregate_fields';
  avg?: Maybe<form_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<form_version_max_fields>;
  min?: Maybe<form_version_min_fields>;
  stddev?: Maybe<form_version_stddev_fields>;
  stddev_pop?: Maybe<form_version_stddev_pop_fields>;
  stddev_samp?: Maybe<form_version_stddev_samp_fields>;
  sum?: Maybe<form_version_sum_fields>;
  var_pop?: Maybe<form_version_var_pop_fields>;
  var_samp?: Maybe<form_version_var_samp_fields>;
  variance?: Maybe<form_version_variance_fields>;
};


/** aggregate fields of "form_version" */
export type form_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<form_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "form_version" */
export type form_version_aggregate_order_by = {
  avg?: InputMaybe<form_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<form_version_max_order_by>;
  min?: InputMaybe<form_version_min_order_by>;
  stddev?: InputMaybe<form_version_stddev_order_by>;
  stddev_pop?: InputMaybe<form_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<form_version_stddev_samp_order_by>;
  sum?: InputMaybe<form_version_sum_order_by>;
  var_pop?: InputMaybe<form_version_var_pop_order_by>;
  var_samp?: InputMaybe<form_version_var_samp_order_by>;
  variance?: InputMaybe<form_version_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type form_version_append_input = {
  question_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "form_version" */
export type form_version_arr_rel_insert_input = {
  data: Array<form_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<form_version_on_conflict>;
};

/** aggregate avg on columns */
export type form_version_avg_fields = {
  __typename: 'form_version_avg_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "form_version" */
export type form_version_avg_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "form_version". All fields are combined with a logical 'AND'. */
export type form_version_bool_exp = {
  _and?: InputMaybe<Array<form_version_bool_exp>>;
  _not?: InputMaybe<form_version_bool_exp>;
  _or?: InputMaybe<Array<form_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  form?: InputMaybe<form_bool_exp>;
  form_id?: InputMaybe<Int_comparison_exp>;
  form_version_questions?: InputMaybe<form_version_question_bool_exp>;
  form_version_questions_aggregate?: InputMaybe<form_version_question_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  question_ids?: InputMaybe<jsonb_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "form_version" */
export enum form_version_constraint {
  /** unique or primary key constraint on columns "id" */
  form_version_pkey = 'form_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type form_version_delete_at_path_input = {
  question_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type form_version_delete_elem_input = {
  question_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type form_version_delete_key_input = {
  question_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "form_version" */
export type form_version_inc_input = {
  form_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "form_version" */
export type form_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  form?: InputMaybe<form_obj_rel_insert_input>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
  form_version_questions?: InputMaybe<form_version_question_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type form_version_max_fields = {
  __typename: 'form_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  form_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "form_version" */
export type form_version_max_order_by = {
  created_at?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type form_version_min_fields = {
  __typename: 'form_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  form_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "form_version" */
export type form_version_min_order_by = {
  created_at?: InputMaybe<order_by>;
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "form_version" */
export type form_version_mutation_response = {
  __typename: 'form_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<form_version>;
};

/** input type for inserting object relation for remote table "form_version" */
export type form_version_obj_rel_insert_input = {
  data: form_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<form_version_on_conflict>;
};

/** on_conflict condition type for table "form_version" */
export type form_version_on_conflict = {
  constraint: form_version_constraint;
  update_columns?: Array<form_version_update_column>;
  where?: InputMaybe<form_version_bool_exp>;
};

/** Ordering options when selecting data from "form_version". */
export type form_version_order_by = {
  created_at?: InputMaybe<order_by>;
  form?: InputMaybe<form_order_by>;
  form_id?: InputMaybe<order_by>;
  form_version_questions_aggregate?: InputMaybe<form_version_question_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  question_ids?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: form_version */
export type form_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type form_version_prepend_input = {
  question_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "form_version_question" */
export type form_version_question = {
  __typename: 'form_version_question';
  /** An object relationship */
  form_version?: Maybe<form_version>;
  form_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question?: Maybe<question>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "form_version_question" */
export type form_version_question_aggregate = {
  __typename: 'form_version_question_aggregate';
  aggregate?: Maybe<form_version_question_aggregate_fields>;
  nodes: Array<form_version_question>;
};

export type form_version_question_aggregate_bool_exp = {
  count?: InputMaybe<form_version_question_aggregate_bool_exp_count>;
};

export type form_version_question_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<form_version_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<form_version_question_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "form_version_question" */
export type form_version_question_aggregate_fields = {
  __typename: 'form_version_question_aggregate_fields';
  avg?: Maybe<form_version_question_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<form_version_question_max_fields>;
  min?: Maybe<form_version_question_min_fields>;
  stddev?: Maybe<form_version_question_stddev_fields>;
  stddev_pop?: Maybe<form_version_question_stddev_pop_fields>;
  stddev_samp?: Maybe<form_version_question_stddev_samp_fields>;
  sum?: Maybe<form_version_question_sum_fields>;
  var_pop?: Maybe<form_version_question_var_pop_fields>;
  var_samp?: Maybe<form_version_question_var_samp_fields>;
  variance?: Maybe<form_version_question_variance_fields>;
};


/** aggregate fields of "form_version_question" */
export type form_version_question_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<form_version_question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "form_version_question" */
export type form_version_question_aggregate_order_by = {
  avg?: InputMaybe<form_version_question_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<form_version_question_max_order_by>;
  min?: InputMaybe<form_version_question_min_order_by>;
  stddev?: InputMaybe<form_version_question_stddev_order_by>;
  stddev_pop?: InputMaybe<form_version_question_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<form_version_question_stddev_samp_order_by>;
  sum?: InputMaybe<form_version_question_sum_order_by>;
  var_pop?: InputMaybe<form_version_question_var_pop_order_by>;
  var_samp?: InputMaybe<form_version_question_var_samp_order_by>;
  variance?: InputMaybe<form_version_question_variance_order_by>;
};

/** input type for inserting array relation for remote table "form_version_question" */
export type form_version_question_arr_rel_insert_input = {
  data: Array<form_version_question_insert_input>;
};

/** aggregate avg on columns */
export type form_version_question_avg_fields = {
  __typename: 'form_version_question_avg_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "form_version_question" */
export type form_version_question_avg_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "form_version_question". All fields are combined with a logical 'AND'. */
export type form_version_question_bool_exp = {
  _and?: InputMaybe<Array<form_version_question_bool_exp>>;
  _not?: InputMaybe<form_version_question_bool_exp>;
  _or?: InputMaybe<Array<form_version_question_bool_exp>>;
  form_version?: InputMaybe<form_version_bool_exp>;
  form_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "form_version_question" */
export type form_version_question_insert_input = {
  form_version?: InputMaybe<form_version_obj_rel_insert_input>;
  form_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type form_version_question_max_fields = {
  __typename: 'form_version_question_max_fields';
  form_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "form_version_question" */
export type form_version_question_max_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type form_version_question_min_fields = {
  __typename: 'form_version_question_min_fields';
  form_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "form_version_question" */
export type form_version_question_min_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "form_version_question". */
export type form_version_question_order_by = {
  form_version?: InputMaybe<form_version_order_by>;
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
};

/** select columns of table "form_version_question" */
export enum form_version_question_select_column {
  /** column name */
  form_version_id = 'form_version_id',
  /** column name */
  position = 'position',
  /** column name */
  question_id = 'question_id'
}

/** aggregate stddev on columns */
export type form_version_question_stddev_fields = {
  __typename: 'form_version_question_stddev_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "form_version_question" */
export type form_version_question_stddev_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type form_version_question_stddev_pop_fields = {
  __typename: 'form_version_question_stddev_pop_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "form_version_question" */
export type form_version_question_stddev_pop_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type form_version_question_stddev_samp_fields = {
  __typename: 'form_version_question_stddev_samp_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "form_version_question" */
export type form_version_question_stddev_samp_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "form_version_question" */
export type form_version_question_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: form_version_question_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type form_version_question_stream_cursor_value_input = {
  form_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type form_version_question_sum_fields = {
  __typename: 'form_version_question_sum_fields';
  form_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "form_version_question" */
export type form_version_question_sum_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type form_version_question_var_pop_fields = {
  __typename: 'form_version_question_var_pop_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "form_version_question" */
export type form_version_question_var_pop_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type form_version_question_var_samp_fields = {
  __typename: 'form_version_question_var_samp_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "form_version_question" */
export type form_version_question_var_samp_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type form_version_question_variance_fields = {
  __typename: 'form_version_question_variance_fields';
  form_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "form_version_question" */
export type form_version_question_variance_order_by = {
  form_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** select columns of table "form_version" */
export enum form_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  form_id = 'form_id',
  /** column name */
  id = 'id',
  /** column name */
  question_ids = 'question_ids',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "form_version" */
export type form_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type form_version_stddev_fields = {
  __typename: 'form_version_stddev_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "form_version" */
export type form_version_stddev_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type form_version_stddev_pop_fields = {
  __typename: 'form_version_stddev_pop_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "form_version" */
export type form_version_stddev_pop_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type form_version_stddev_samp_fields = {
  __typename: 'form_version_stddev_samp_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "form_version" */
export type form_version_stddev_samp_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "form_version" */
export type form_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: form_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type form_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  form_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type form_version_sum_fields = {
  __typename: 'form_version_sum_fields';
  form_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "form_version" */
export type form_version_sum_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "form_version" */
export enum form_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  form_id = 'form_id',
  /** column name */
  id = 'id',
  /** column name */
  question_ids = 'question_ids',
  /** column name */
  updated_at = 'updated_at'
}

export type form_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<form_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<form_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<form_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<form_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<form_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<form_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<form_version_set_input>;
  /** filter the rows which have to be updated */
  where: form_version_bool_exp;
};

/** aggregate var_pop on columns */
export type form_version_var_pop_fields = {
  __typename: 'form_version_var_pop_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "form_version" */
export type form_version_var_pop_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type form_version_var_samp_fields = {
  __typename: 'form_version_var_samp_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "form_version" */
export type form_version_var_samp_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type form_version_variance_fields = {
  __typename: 'form_version_variance_fields';
  form_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "form_version" */
export type form_version_variance_order_by = {
  form_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "function_log" */
export type function_log = {
  __typename: 'function_log';
  ended_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  log: Scalars['jsonb']['output'];
  started_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "function_log" */
export type function_loglogArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "function_log" */
export type function_log_aggregate = {
  __typename: 'function_log_aggregate';
  aggregate?: Maybe<function_log_aggregate_fields>;
  nodes: Array<function_log>;
};

/** aggregate fields of "function_log" */
export type function_log_aggregate_fields = {
  __typename: 'function_log_aggregate_fields';
  avg?: Maybe<function_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<function_log_max_fields>;
  min?: Maybe<function_log_min_fields>;
  stddev?: Maybe<function_log_stddev_fields>;
  stddev_pop?: Maybe<function_log_stddev_pop_fields>;
  stddev_samp?: Maybe<function_log_stddev_samp_fields>;
  sum?: Maybe<function_log_sum_fields>;
  var_pop?: Maybe<function_log_var_pop_fields>;
  var_samp?: Maybe<function_log_var_samp_fields>;
  variance?: Maybe<function_log_variance_fields>;
};


/** aggregate fields of "function_log" */
export type function_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<function_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type function_log_append_input = {
  log?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type function_log_avg_fields = {
  __typename: 'function_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "function_log". All fields are combined with a logical 'AND'. */
export type function_log_bool_exp = {
  _and?: InputMaybe<Array<function_log_bool_exp>>;
  _not?: InputMaybe<function_log_bool_exp>;
  _or?: InputMaybe<Array<function_log_bool_exp>>;
  ended_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  log?: InputMaybe<jsonb_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "function_log" */
export enum function_log_constraint {
  /** unique or primary key constraint on columns "id" */
  function_log_pkey = 'function_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type function_log_delete_at_path_input = {
  log?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type function_log_delete_elem_input = {
  log?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type function_log_delete_key_input = {
  log?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "function_log" */
export type function_log_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "function_log" */
export type function_log_insert_input = {
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type function_log_max_fields = {
  __typename: 'function_log_max_fields';
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type function_log_min_fields = {
  __typename: 'function_log_min_fields';
  ended_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "function_log" */
export type function_log_mutation_response = {
  __typename: 'function_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<function_log>;
};

/** on_conflict condition type for table "function_log" */
export type function_log_on_conflict = {
  constraint: function_log_constraint;
  update_columns?: Array<function_log_update_column>;
  where?: InputMaybe<function_log_bool_exp>;
};

/** Ordering options when selecting data from "function_log". */
export type function_log_order_by = {
  ended_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  log?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: function_log */
export type function_log_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type function_log_prepend_input = {
  log?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "function_log" */
export enum function_log_select_column {
  /** column name */
  ended_at = 'ended_at',
  /** column name */
  id = 'id',
  /** column name */
  log = 'log',
  /** column name */
  started_at = 'started_at'
}

/** input type for updating data in table "function_log" */
export type function_log_set_input = {
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type function_log_stddev_fields = {
  __typename: 'function_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type function_log_stddev_pop_fields = {
  __typename: 'function_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type function_log_stddev_samp_fields = {
  __typename: 'function_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "function_log" */
export type function_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: function_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type function_log_stream_cursor_value_input = {
  ended_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type function_log_sum_fields = {
  __typename: 'function_log_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "function_log" */
export enum function_log_update_column {
  /** column name */
  ended_at = 'ended_at',
  /** column name */
  id = 'id',
  /** column name */
  log = 'log',
  /** column name */
  started_at = 'started_at'
}

export type function_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<function_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<function_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<function_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<function_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<function_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<function_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<function_log_set_input>;
  /** filter the rows which have to be updated */
  where: function_log_bool_exp;
};

/** aggregate var_pop on columns */
export type function_log_var_pop_fields = {
  __typename: 'function_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type function_log_var_samp_fields = {
  __typename: 'function_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type function_log_variance_fields = {
  __typename: 'function_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "integration" */
export type integration = {
  __typename: 'integration';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  credentials: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  meta: Scalars['jsonb']['output'];
  options: Scalars['jsonb']['output'];
  type: integration_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "integration" */
export type integrationcredentialsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "integration" */
export type integrationmetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "integration" */
export type integrationoptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "integration" */
export type integration_aggregate = {
  __typename: 'integration_aggregate';
  aggregate?: Maybe<integration_aggregate_fields>;
  nodes: Array<integration>;
};

export type integration_aggregate_bool_exp = {
  count?: InputMaybe<integration_aggregate_bool_exp_count>;
};

export type integration_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<integration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<integration_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "integration" */
export type integration_aggregate_fields = {
  __typename: 'integration_aggregate_fields';
  avg?: Maybe<integration_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<integration_max_fields>;
  min?: Maybe<integration_min_fields>;
  stddev?: Maybe<integration_stddev_fields>;
  stddev_pop?: Maybe<integration_stddev_pop_fields>;
  stddev_samp?: Maybe<integration_stddev_samp_fields>;
  sum?: Maybe<integration_sum_fields>;
  var_pop?: Maybe<integration_var_pop_fields>;
  var_samp?: Maybe<integration_var_samp_fields>;
  variance?: Maybe<integration_variance_fields>;
};


/** aggregate fields of "integration" */
export type integration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<integration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "integration" */
export type integration_aggregate_order_by = {
  avg?: InputMaybe<integration_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<integration_max_order_by>;
  min?: InputMaybe<integration_min_order_by>;
  stddev?: InputMaybe<integration_stddev_order_by>;
  stddev_pop?: InputMaybe<integration_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<integration_stddev_samp_order_by>;
  sum?: InputMaybe<integration_sum_order_by>;
  var_pop?: InputMaybe<integration_var_pop_order_by>;
  var_samp?: InputMaybe<integration_var_samp_order_by>;
  variance?: InputMaybe<integration_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type integration_append_input = {
  credentials?: InputMaybe<Scalars['jsonb']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "integration" */
export type integration_arr_rel_insert_input = {
  data: Array<integration_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<integration_on_conflict>;
};

/** aggregate avg on columns */
export type integration_avg_fields = {
  __typename: 'integration_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "integration" */
export type integration_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "integration". All fields are combined with a logical 'AND'. */
export type integration_bool_exp = {
  _and?: InputMaybe<Array<integration_bool_exp>>;
  _not?: InputMaybe<integration_bool_exp>;
  _or?: InputMaybe<Array<integration_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  credentials?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  meta?: InputMaybe<jsonb_comparison_exp>;
  options?: InputMaybe<jsonb_comparison_exp>;
  type?: InputMaybe<integration_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "integration" */
export enum integration_constraint {
  /** unique or primary key constraint on columns "account_id", "type" */
  integration_account_id_type_key = 'integration_account_id_type_key',
  /** unique or primary key constraint on columns "id" */
  integration_pkey = 'integration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type integration_delete_at_path_input = {
  credentials?: InputMaybe<Array<Scalars['String']['input']>>;
  meta?: InputMaybe<Array<Scalars['String']['input']>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type integration_delete_elem_input = {
  credentials?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type integration_delete_key_input = {
  credentials?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "integration" */
export type integration_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "integration" */
export type integration_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  credentials?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<integration_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type integration_max_fields = {
  __typename: 'integration_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "integration" */
export type integration_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type integration_min_fields = {
  __typename: 'integration_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "integration" */
export type integration_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "integration" */
export type integration_mutation_response = {
  __typename: 'integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<integration>;
};

/** input type for inserting object relation for remote table "integration" */
export type integration_obj_rel_insert_input = {
  data: integration_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<integration_on_conflict>;
};

/** on_conflict condition type for table "integration" */
export type integration_on_conflict = {
  constraint: integration_constraint;
  update_columns?: Array<integration_update_column>;
  where?: InputMaybe<integration_bool_exp>;
};

/** Ordering options when selecting data from "integration". */
export type integration_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  credentials?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  meta?: InputMaybe<order_by>;
  options?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: integration */
export type integration_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type integration_prepend_input = {
  credentials?: InputMaybe<Scalars['jsonb']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "integration" */
export enum integration_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  credentials = 'credentials',
  /** column name */
  id = 'id',
  /** column name */
  meta = 'meta',
  /** column name */
  options = 'options',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "integration" */
export type integration_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  credentials?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<integration_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type integration_stddev_fields = {
  __typename: 'integration_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "integration" */
export type integration_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type integration_stddev_pop_fields = {
  __typename: 'integration_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "integration" */
export type integration_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type integration_stddev_samp_fields = {
  __typename: 'integration_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "integration" */
export type integration_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "integration" */
export type integration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: integration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type integration_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  credentials?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<integration_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type integration_sum_fields = {
  __typename: 'integration_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "integration" */
export type integration_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "integration_type" */
export type integration_type = {
  __typename: 'integration_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "integration_type" */
export type integration_type_aggregate = {
  __typename: 'integration_type_aggregate';
  aggregate?: Maybe<integration_type_aggregate_fields>;
  nodes: Array<integration_type>;
};

/** aggregate fields of "integration_type" */
export type integration_type_aggregate_fields = {
  __typename: 'integration_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<integration_type_max_fields>;
  min?: Maybe<integration_type_min_fields>;
};


/** aggregate fields of "integration_type" */
export type integration_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<integration_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "integration_type". All fields are combined with a logical 'AND'. */
export type integration_type_bool_exp = {
  _and?: InputMaybe<Array<integration_type_bool_exp>>;
  _not?: InputMaybe<integration_type_bool_exp>;
  _or?: InputMaybe<Array<integration_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "integration_type" */
export enum integration_type_constraint {
  /** unique or primary key constraint on columns "value" */
  integration_type_pkey = 'integration_type_pkey'
}

export enum integration_type_enum {
  hubspot = 'hubspot',
  intercom = 'intercom',
  klaviyo = 'klaviyo',
  salesforce = 'salesforce',
  segment = 'segment',
  slack = 'slack'
}

/** Boolean expression to compare columns of type "integration_type_enum". All fields are combined with logical 'AND'. */
export type integration_type_enum_comparison_exp = {
  _eq?: InputMaybe<integration_type_enum>;
  _in?: InputMaybe<Array<integration_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<integration_type_enum>;
  _nin?: InputMaybe<Array<integration_type_enum>>;
};

/** input type for inserting data into table "integration_type" */
export type integration_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type integration_type_max_fields = {
  __typename: 'integration_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type integration_type_min_fields = {
  __typename: 'integration_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "integration_type" */
export type integration_type_mutation_response = {
  __typename: 'integration_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<integration_type>;
};

/** on_conflict condition type for table "integration_type" */
export type integration_type_on_conflict = {
  constraint: integration_type_constraint;
  update_columns?: Array<integration_type_update_column>;
  where?: InputMaybe<integration_type_bool_exp>;
};

/** Ordering options when selecting data from "integration_type". */
export type integration_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: integration_type */
export type integration_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "integration_type" */
export enum integration_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "integration_type" */
export type integration_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "integration_type" */
export type integration_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: integration_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type integration_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "integration_type" */
export enum integration_type_update_column {
  /** column name */
  value = 'value'
}

export type integration_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<integration_type_set_input>;
  /** filter the rows which have to be updated */
  where: integration_type_bool_exp;
};

/** update columns of table "integration" */
export enum integration_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  credentials = 'credentials',
  /** column name */
  id = 'id',
  /** column name */
  meta = 'meta',
  /** column name */
  options = 'options',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type integration_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<integration_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<integration_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<integration_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<integration_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<integration_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<integration_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<integration_set_input>;
  /** filter the rows which have to be updated */
  where: integration_bool_exp;
};

/** aggregate var_pop on columns */
export type integration_var_pop_fields = {
  __typename: 'integration_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "integration" */
export type integration_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type integration_var_samp_fields = {
  __typename: 'integration_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "integration" */
export type integration_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type integration_variance_fields = {
  __typename: 'integration_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "integration" */
export type integration_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type interval_comparison_exp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

/** columns and relationships of "intervention" */
export type intervention = {
  __typename: 'intervention';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<intervention_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_interventions: Array<flow_step_intervention>;
  /** An aggregate relationship */
  flow_step_interventions_aggregate: flow_step_intervention_aggregate;
  id: Scalars['Int']['output'];
  /** An object relationship */
  offer_rule_group: offer_rule_group;
  offer_rule_group_id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<intervention_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "intervention" */
export type interventionflow_step_interventionsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


/** columns and relationships of "intervention" */
export type interventionflow_step_interventions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};

/** aggregated selection of "intervention" */
export type intervention_aggregate = {
  __typename: 'intervention_aggregate';
  aggregate?: Maybe<intervention_aggregate_fields>;
  nodes: Array<intervention>;
};

export type intervention_aggregate_bool_exp = {
  count?: InputMaybe<intervention_aggregate_bool_exp_count>;
};

export type intervention_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<intervention_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<intervention_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "intervention" */
export type intervention_aggregate_fields = {
  __typename: 'intervention_aggregate_fields';
  avg?: Maybe<intervention_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<intervention_max_fields>;
  min?: Maybe<intervention_min_fields>;
  stddev?: Maybe<intervention_stddev_fields>;
  stddev_pop?: Maybe<intervention_stddev_pop_fields>;
  stddev_samp?: Maybe<intervention_stddev_samp_fields>;
  sum?: Maybe<intervention_sum_fields>;
  var_pop?: Maybe<intervention_var_pop_fields>;
  var_samp?: Maybe<intervention_var_samp_fields>;
  variance?: Maybe<intervention_variance_fields>;
};


/** aggregate fields of "intervention" */
export type intervention_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<intervention_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "intervention" */
export type intervention_aggregate_order_by = {
  avg?: InputMaybe<intervention_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<intervention_max_order_by>;
  min?: InputMaybe<intervention_min_order_by>;
  stddev?: InputMaybe<intervention_stddev_order_by>;
  stddev_pop?: InputMaybe<intervention_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<intervention_stddev_samp_order_by>;
  sum?: InputMaybe<intervention_sum_order_by>;
  var_pop?: InputMaybe<intervention_var_pop_order_by>;
  var_samp?: InputMaybe<intervention_var_samp_order_by>;
  variance?: InputMaybe<intervention_variance_order_by>;
};

/** input type for inserting array relation for remote table "intervention" */
export type intervention_arr_rel_insert_input = {
  data: Array<intervention_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<intervention_on_conflict>;
};

/** aggregate avg on columns */
export type intervention_avg_fields = {
  __typename: 'intervention_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "intervention" */
export type intervention_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "intervention". All fields are combined with a logical 'AND'. */
export type intervention_bool_exp = {
  _and?: InputMaybe<Array<intervention_bool_exp>>;
  _not?: InputMaybe<intervention_bool_exp>;
  _or?: InputMaybe<Array<intervention_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<intervention_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_interventions?: InputMaybe<flow_step_intervention_bool_exp>;
  flow_step_interventions_aggregate?: InputMaybe<flow_step_intervention_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<intervention_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "intervention" */
export enum intervention_constraint {
  /** unique or primary key constraint on columns "id" */
  intervention_pkey = 'intervention_pkey',
  /** unique or primary key constraint on columns "token" */
  intervention_token_key = 'intervention_token_key'
}

/** input type for incrementing numeric columns in table "intervention" */
export type intervention_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "intervention" */
export type intervention_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<intervention_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_interventions?: InputMaybe<flow_step_intervention_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<intervention_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type intervention_max_fields = {
  __typename: 'intervention_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "intervention" */
export type intervention_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type intervention_min_fields = {
  __typename: 'intervention_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "intervention" */
export type intervention_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "intervention" */
export type intervention_mutation_response = {
  __typename: 'intervention_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<intervention>;
};

/** input type for inserting object relation for remote table "intervention" */
export type intervention_obj_rel_insert_input = {
  data: intervention_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<intervention_on_conflict>;
};

/** on_conflict condition type for table "intervention" */
export type intervention_on_conflict = {
  constraint: intervention_constraint;
  update_columns?: Array<intervention_update_column>;
  where?: InputMaybe<intervention_bool_exp>;
};

/** Ordering options when selecting data from "intervention". */
export type intervention_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<intervention_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_interventions_aggregate?: InputMaybe<flow_step_intervention_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version?: InputMaybe<intervention_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: intervention */
export type intervention_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "intervention" */
export enum intervention_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "intervention" */
export type intervention_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type intervention_stddev_fields = {
  __typename: 'intervention_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "intervention" */
export type intervention_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type intervention_stddev_pop_fields = {
  __typename: 'intervention_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "intervention" */
export type intervention_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type intervention_stddev_samp_fields = {
  __typename: 'intervention_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "intervention" */
export type intervention_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "intervention" */
export type intervention_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: intervention_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type intervention_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type intervention_sum_fields = {
  __typename: 'intervention_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "intervention" */
export type intervention_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "intervention" */
export enum intervention_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type intervention_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<intervention_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<intervention_set_input>;
  /** filter the rows which have to be updated */
  where: intervention_bool_exp;
};

/** aggregate var_pop on columns */
export type intervention_var_pop_fields = {
  __typename: 'intervention_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "intervention" */
export type intervention_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type intervention_var_samp_fields = {
  __typename: 'intervention_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "intervention" */
export type intervention_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type intervention_variance_fields = {
  __typename: 'intervention_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "intervention" */
export type intervention_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "intervention_version" */
export type intervention_version = {
  __typename: 'intervention_version';
  /** An object relationship */
  cancel_button_translation: translation;
  cancel_button_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  content_translation: translation;
  content_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  continue_button_translation: translation;
  continue_button_translation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  intervention: intervention;
  intervention_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "intervention_version" */
export type intervention_version_aggregate = {
  __typename: 'intervention_version_aggregate';
  aggregate?: Maybe<intervention_version_aggregate_fields>;
  nodes: Array<intervention_version>;
};

/** aggregate fields of "intervention_version" */
export type intervention_version_aggregate_fields = {
  __typename: 'intervention_version_aggregate_fields';
  avg?: Maybe<intervention_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<intervention_version_max_fields>;
  min?: Maybe<intervention_version_min_fields>;
  stddev?: Maybe<intervention_version_stddev_fields>;
  stddev_pop?: Maybe<intervention_version_stddev_pop_fields>;
  stddev_samp?: Maybe<intervention_version_stddev_samp_fields>;
  sum?: Maybe<intervention_version_sum_fields>;
  var_pop?: Maybe<intervention_version_var_pop_fields>;
  var_samp?: Maybe<intervention_version_var_samp_fields>;
  variance?: Maybe<intervention_version_variance_fields>;
};


/** aggregate fields of "intervention_version" */
export type intervention_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<intervention_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type intervention_version_avg_fields = {
  __typename: 'intervention_version_avg_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "intervention_version". All fields are combined with a logical 'AND'. */
export type intervention_version_bool_exp = {
  _and?: InputMaybe<Array<intervention_version_bool_exp>>;
  _not?: InputMaybe<intervention_version_bool_exp>;
  _or?: InputMaybe<Array<intervention_version_bool_exp>>;
  cancel_button_translation?: InputMaybe<translation_bool_exp>;
  cancel_button_translation_id?: InputMaybe<Int_comparison_exp>;
  content_translation?: InputMaybe<translation_bool_exp>;
  content_translation_id?: InputMaybe<Int_comparison_exp>;
  continue_button_translation?: InputMaybe<translation_bool_exp>;
  continue_button_translation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  intervention?: InputMaybe<intervention_bool_exp>;
  intervention_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "intervention_version" */
export enum intervention_version_constraint {
  /** unique or primary key constraint on columns "id" */
  intervention_version_pkey = 'intervention_version_pkey'
}

/** input type for incrementing numeric columns in table "intervention_version" */
export type intervention_version_inc_input = {
  cancel_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  continue_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "intervention_version" */
export type intervention_version_insert_input = {
  cancel_button_translation?: InputMaybe<translation_obj_rel_insert_input>;
  cancel_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  continue_button_translation?: InputMaybe<translation_obj_rel_insert_input>;
  continue_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intervention?: InputMaybe<intervention_obj_rel_insert_input>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type intervention_version_max_fields = {
  __typename: 'intervention_version_max_fields';
  cancel_button_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type intervention_version_min_fields = {
  __typename: 'intervention_version_min_fields';
  cancel_button_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "intervention_version" */
export type intervention_version_mutation_response = {
  __typename: 'intervention_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<intervention_version>;
};

/** input type for inserting object relation for remote table "intervention_version" */
export type intervention_version_obj_rel_insert_input = {
  data: intervention_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<intervention_version_on_conflict>;
};

/** on_conflict condition type for table "intervention_version" */
export type intervention_version_on_conflict = {
  constraint: intervention_version_constraint;
  update_columns?: Array<intervention_version_update_column>;
  where?: InputMaybe<intervention_version_bool_exp>;
};

/** Ordering options when selecting data from "intervention_version". */
export type intervention_version_order_by = {
  cancel_button_translation?: InputMaybe<translation_order_by>;
  cancel_button_translation_id?: InputMaybe<order_by>;
  content_translation?: InputMaybe<translation_order_by>;
  content_translation_id?: InputMaybe<order_by>;
  continue_button_translation?: InputMaybe<translation_order_by>;
  continue_button_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  intervention?: InputMaybe<intervention_order_by>;
  intervention_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: intervention_version */
export type intervention_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "intervention_version" */
export enum intervention_version_select_column {
  /** column name */
  cancel_button_translation_id = 'cancel_button_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  continue_button_translation_id = 'continue_button_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  intervention_id = 'intervention_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "intervention_version" */
export type intervention_version_set_input = {
  cancel_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  continue_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type intervention_version_stddev_fields = {
  __typename: 'intervention_version_stddev_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type intervention_version_stddev_pop_fields = {
  __typename: 'intervention_version_stddev_pop_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type intervention_version_stddev_samp_fields = {
  __typename: 'intervention_version_stddev_samp_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "intervention_version" */
export type intervention_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: intervention_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type intervention_version_stream_cursor_value_input = {
  cancel_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  continue_button_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intervention_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type intervention_version_sum_fields = {
  __typename: 'intervention_version_sum_fields';
  cancel_button_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  intervention_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "intervention_version" */
export enum intervention_version_update_column {
  /** column name */
  cancel_button_translation_id = 'cancel_button_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  continue_button_translation_id = 'continue_button_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  intervention_id = 'intervention_id',
  /** column name */
  updated_at = 'updated_at'
}

export type intervention_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<intervention_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<intervention_version_set_input>;
  /** filter the rows which have to be updated */
  where: intervention_version_bool_exp;
};

/** aggregate var_pop on columns */
export type intervention_version_var_pop_fields = {
  __typename: 'intervention_version_var_pop_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type intervention_version_var_samp_fields = {
  __typename: 'intervention_version_var_samp_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type intervention_version_variance_fields = {
  __typename: 'intervention_version_variance_fields';
  cancel_button_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  continue_button_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  intervention_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "job" */
export type job = {
  __typename: 'job';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "job" */
export type jobdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "job" */
export type job_aggregate = {
  __typename: 'job_aggregate';
  aggregate?: Maybe<job_aggregate_fields>;
  nodes: Array<job>;
};

/** aggregate fields of "job" */
export type job_aggregate_fields = {
  __typename: 'job_aggregate_fields';
  avg?: Maybe<job_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<job_max_fields>;
  min?: Maybe<job_min_fields>;
  stddev?: Maybe<job_stddev_fields>;
  stddev_pop?: Maybe<job_stddev_pop_fields>;
  stddev_samp?: Maybe<job_stddev_samp_fields>;
  sum?: Maybe<job_sum_fields>;
  var_pop?: Maybe<job_var_pop_fields>;
  var_samp?: Maybe<job_var_samp_fields>;
  variance?: Maybe<job_variance_fields>;
};


/** aggregate fields of "job" */
export type job_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<job_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type job_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type job_avg_fields = {
  __typename: 'job_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type job_bool_exp = {
  _and?: InputMaybe<Array<job_bool_exp>>;
  _not?: InputMaybe<job_bool_exp>;
  _or?: InputMaybe<Array<job_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "job" */
export enum job_constraint {
  /** unique or primary key constraint on columns "id" */
  job_pkey = 'job_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type job_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type job_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type job_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "job" */
export type job_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "job" */
export type job_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type job_max_fields = {
  __typename: 'job_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type job_min_fields = {
  __typename: 'job_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "job" */
export type job_mutation_response = {
  __typename: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<job>;
};

/** on_conflict condition type for table "job" */
export type job_on_conflict = {
  constraint: job_constraint;
  update_columns?: Array<job_update_column>;
  where?: InputMaybe<job_bool_exp>;
};

/** Ordering options when selecting data from "job". */
export type job_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: job */
export type job_pk_columns_input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type job_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "job" */
export enum job_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "job" */
export type job_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type job_stddev_fields = {
  __typename: 'job_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type job_stddev_pop_fields = {
  __typename: 'job_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type job_stddev_samp_fields = {
  __typename: 'job_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "job" */
export type job_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: job_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type job_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type job_sum_fields = {
  __typename: 'job_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "job" */
export enum job_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at'
}

export type job_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<job_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<job_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<job_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<job_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<job_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<job_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<job_set_input>;
  /** filter the rows which have to be updated */
  where: job_bool_exp;
};

/** aggregate var_pop on columns */
export type job_var_pop_fields = {
  __typename: 'job_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type job_var_samp_fields = {
  __typename: 'job_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type job_variance_fields = {
  __typename: 'job_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

export type jsonb_cast_exp = {
  String?: InputMaybe<String_comparison_exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type jsonb_comparison_exp = {
  _cast?: InputMaybe<jsonb_cast_exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "language" */
export type language = {
  __typename: 'language';
  value: Scalars['String']['output'];
};

/** aggregated selection of "language" */
export type language_aggregate = {
  __typename: 'language_aggregate';
  aggregate?: Maybe<language_aggregate_fields>;
  nodes: Array<language>;
};

/** aggregate fields of "language" */
export type language_aggregate_fields = {
  __typename: 'language_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<language_max_fields>;
  min?: Maybe<language_min_fields>;
};


/** aggregate fields of "language" */
export type language_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<language_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type language_bool_exp = {
  _and?: InputMaybe<Array<language_bool_exp>>;
  _not?: InputMaybe<language_bool_exp>;
  _or?: InputMaybe<Array<language_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "language" */
export enum language_constraint {
  /** unique or primary key constraint on columns "value" */
  language_pkey = 'language_pkey'
}

export enum language_enum {
  de = 'de',
  en_gb = 'en_gb',
  en_us = 'en_us',
  es = 'es',
  es_mx = 'es_mx',
  fi = 'fi',
  fr = 'fr',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  nl = 'nl',
  pl = 'pl',
  pt = 'pt',
  pt_br = 'pt_br',
  ru = 'ru',
  sv = 'sv',
  zh_cn = 'zh_cn',
  zh_tw = 'zh_tw'
}

/** Boolean expression to compare columns of type "language_enum". All fields are combined with logical 'AND'. */
export type language_enum_comparison_exp = {
  _eq?: InputMaybe<language_enum>;
  _in?: InputMaybe<Array<language_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<language_enum>;
  _nin?: InputMaybe<Array<language_enum>>;
};

/** input type for inserting data into table "language" */
export type language_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type language_max_fields = {
  __typename: 'language_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type language_min_fields = {
  __typename: 'language_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "language" */
export type language_mutation_response = {
  __typename: 'language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<language>;
};

/** input type for inserting object relation for remote table "language" */
export type language_obj_rel_insert_input = {
  data: language_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<language_on_conflict>;
};

/** on_conflict condition type for table "language" */
export type language_on_conflict = {
  constraint: language_constraint;
  update_columns?: Array<language_update_column>;
  where?: InputMaybe<language_bool_exp>;
};

/** Ordering options when selecting data from "language". */
export type language_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: language */
export type language_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "language" */
export enum language_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "language" */
export type language_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "language" */
export type language_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: language_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type language_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "language" */
export enum language_update_column {
  /** column name */
  value = 'value'
}

export type language_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<language_set_input>;
  /** filter the rows which have to be updated */
  where: language_bool_exp;
};

/** columns and relationships of "lost_mrr_by_month" */
export type lost_mrr_by_month = {
  __typename: 'lost_mrr_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "lost_mrr_by_month" */
export type lost_mrr_by_month_aggregate = {
  __typename: 'lost_mrr_by_month_aggregate';
  aggregate?: Maybe<lost_mrr_by_month_aggregate_fields>;
  nodes: Array<lost_mrr_by_month>;
};

/** aggregate fields of "lost_mrr_by_month" */
export type lost_mrr_by_month_aggregate_fields = {
  __typename: 'lost_mrr_by_month_aggregate_fields';
  avg?: Maybe<lost_mrr_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<lost_mrr_by_month_max_fields>;
  min?: Maybe<lost_mrr_by_month_min_fields>;
  stddev?: Maybe<lost_mrr_by_month_stddev_fields>;
  stddev_pop?: Maybe<lost_mrr_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<lost_mrr_by_month_stddev_samp_fields>;
  sum?: Maybe<lost_mrr_by_month_sum_fields>;
  var_pop?: Maybe<lost_mrr_by_month_var_pop_fields>;
  var_samp?: Maybe<lost_mrr_by_month_var_samp_fields>;
  variance?: Maybe<lost_mrr_by_month_variance_fields>;
};


/** aggregate fields of "lost_mrr_by_month" */
export type lost_mrr_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<lost_mrr_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type lost_mrr_by_month_avg_fields = {
  __typename: 'lost_mrr_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "lost_mrr_by_month". All fields are combined with a logical 'AND'. */
export type lost_mrr_by_month_bool_exp = {
  _and?: InputMaybe<Array<lost_mrr_by_month_bool_exp>>;
  _not?: InputMaybe<lost_mrr_by_month_bool_exp>;
  _or?: InputMaybe<Array<lost_mrr_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  lost_mrr?: InputMaybe<numeric_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
  reason?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type lost_mrr_by_month_max_fields = {
  __typename: 'lost_mrr_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type lost_mrr_by_month_min_fields = {
  __typename: 'lost_mrr_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "lost_mrr_by_month". */
export type lost_mrr_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
  reason?: InputMaybe<order_by>;
};

/** select columns of table "lost_mrr_by_month" */
export enum lost_mrr_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  lost_mrr = 'lost_mrr',
  /** column name */
  month = 'month',
  /** column name */
  reason = 'reason'
}

/** aggregate stddev on columns */
export type lost_mrr_by_month_stddev_fields = {
  __typename: 'lost_mrr_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type lost_mrr_by_month_stddev_pop_fields = {
  __typename: 'lost_mrr_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type lost_mrr_by_month_stddev_samp_fields = {
  __typename: 'lost_mrr_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "lost_mrr_by_month" */
export type lost_mrr_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: lost_mrr_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type lost_mrr_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type lost_mrr_by_month_sum_fields = {
  __typename: 'lost_mrr_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type lost_mrr_by_month_var_pop_fields = {
  __typename: 'lost_mrr_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type lost_mrr_by_month_var_samp_fields = {
  __typename: 'lost_mrr_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type lost_mrr_by_month_variance_fields = {
  __typename: 'lost_mrr_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version = {
  __typename: 'most_recent_subscriber_flow_version';
  /** An object relationship */
  flow?: Maybe<flow>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_aggregate = {
  __typename: 'most_recent_subscriber_flow_version_aggregate';
  aggregate?: Maybe<most_recent_subscriber_flow_version_aggregate_fields>;
  nodes: Array<most_recent_subscriber_flow_version>;
};

/** aggregate fields of "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_aggregate_fields = {
  __typename: 'most_recent_subscriber_flow_version_aggregate_fields';
  avg?: Maybe<most_recent_subscriber_flow_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<most_recent_subscriber_flow_version_max_fields>;
  min?: Maybe<most_recent_subscriber_flow_version_min_fields>;
  stddev?: Maybe<most_recent_subscriber_flow_version_stddev_fields>;
  stddev_pop?: Maybe<most_recent_subscriber_flow_version_stddev_pop_fields>;
  stddev_samp?: Maybe<most_recent_subscriber_flow_version_stddev_samp_fields>;
  sum?: Maybe<most_recent_subscriber_flow_version_sum_fields>;
  var_pop?: Maybe<most_recent_subscriber_flow_version_var_pop_fields>;
  var_samp?: Maybe<most_recent_subscriber_flow_version_var_samp_fields>;
  variance?: Maybe<most_recent_subscriber_flow_version_variance_fields>;
};


/** aggregate fields of "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<most_recent_subscriber_flow_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type most_recent_subscriber_flow_version_avg_fields = {
  __typename: 'most_recent_subscriber_flow_version_avg_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "most_recent_subscriber_flow_version". All fields are combined with a logical 'AND'. */
export type most_recent_subscriber_flow_version_bool_exp = {
  _and?: InputMaybe<Array<most_recent_subscriber_flow_version_bool_exp>>;
  _not?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
  _or?: InputMaybe<Array<most_recent_subscriber_flow_version_bool_exp>>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_inc_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_insert_input = {
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type most_recent_subscriber_flow_version_max_fields = {
  __typename: 'most_recent_subscriber_flow_version_max_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type most_recent_subscriber_flow_version_min_fields = {
  __typename: 'most_recent_subscriber_flow_version_min_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_mutation_response = {
  __typename: 'most_recent_subscriber_flow_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<most_recent_subscriber_flow_version>;
};

/** input type for inserting object relation for remote table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_obj_rel_insert_input = {
  data: most_recent_subscriber_flow_version_insert_input;
};

/** Ordering options when selecting data from "most_recent_subscriber_flow_version". */
export type most_recent_subscriber_flow_version_order_by = {
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** select columns of table "most_recent_subscriber_flow_version" */
export enum most_recent_subscriber_flow_version_select_column {
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  version = 'version'
}

/** input type for updating data in table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_set_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type most_recent_subscriber_flow_version_stddev_fields = {
  __typename: 'most_recent_subscriber_flow_version_stddev_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type most_recent_subscriber_flow_version_stddev_pop_fields = {
  __typename: 'most_recent_subscriber_flow_version_stddev_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type most_recent_subscriber_flow_version_stddev_samp_fields = {
  __typename: 'most_recent_subscriber_flow_version_stddev_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "most_recent_subscriber_flow_version" */
export type most_recent_subscriber_flow_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: most_recent_subscriber_flow_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type most_recent_subscriber_flow_version_stream_cursor_value_input = {
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type most_recent_subscriber_flow_version_sum_fields = {
  __typename: 'most_recent_subscriber_flow_version_sum_fields';
  flow_id?: Maybe<Scalars['Int']['output']>;
};

export type most_recent_subscriber_flow_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<most_recent_subscriber_flow_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<most_recent_subscriber_flow_version_set_input>;
  /** filter the rows which have to be updated */
  where: most_recent_subscriber_flow_version_bool_exp;
};

/** aggregate var_pop on columns */
export type most_recent_subscriber_flow_version_var_pop_fields = {
  __typename: 'most_recent_subscriber_flow_version_var_pop_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type most_recent_subscriber_flow_version_var_samp_fields = {
  __typename: 'most_recent_subscriber_flow_version_var_samp_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type most_recent_subscriber_flow_version_variance_fields = {
  __typename: 'most_recent_subscriber_flow_version_variance_fields';
  flow_id?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type mutation_root = {
  __typename: 'mutation_root';
  acceptCampaignOffer: AcceptCampaignOfferOutput;
  acceptOffer: AcceptOfferOutput;
  acceptUserInvitation: AcceptUserInvitationOutput;
  adminConfigureShopify: AdminConfigureShopifyOutput;
  adminCreateAccount: AdminCreateAccountOutput;
  adminUpdateAccountFeatures: AdminUpdateAccountFeaturesOutput;
  adminUpdateAccountSettings: AdminUpdateAccountSettingsOutput;
  attachPaymentMethod: AttachPaymentMethodOutput;
  cancelSubscription: CancelSubscriptionOutput;
  changePassword: ChangePasswordOutput;
  clickFlowAction: ClickFlowActionOutput;
  completeFlowStep: CompleteFlowStepOutput;
  completeSignUp: CompleteSignUpOutput;
  completeSubscriberCampaignView: CompleteSubscriberCampaignViewOutput;
  completeSubscriberFlow: CompleteSubscriberFlowOutput;
  connectBold: ConnectBoldOutput;
  connectBraintree: ConnectBraintreeOutput;
  connectChargebee: ConnectChargebeeOutput;
  connectHubSpot: ConnectHubSpotOutput;
  connectIntercom: ConnectIntercomOutput;
  connectKlaviyo: ConnectKlaviyoOutput;
  connectMaxio: ConnectMaxioOutput;
  connectNaviga: ConnectNavigaOutput;
  connectOpenPay: ConnectOpenPayOutput;
  connectOrdergroove: ConnectOrdergrooveOutput;
  connectPaddle: ConnectPaddleOutput;
  connectRecharge: ConnectRechargeOutput;
  connectRecurly: ConnectRecurlyOutput;
  connectSalesforce: ConnectSalesforceOutput;
  connectSegment: ConnectSegmentOutput;
  connectShopify: ConnectShopifyOutput;
  connectSlack: ConnectSlackOutput;
  connectStripe?: Maybe<ConnectStripeOutput>;
  connectStripeApiKey: ConnectStripeApiKeyOutput;
  connectStripeApp: ConnectStripeAppOutput;
  connectZuora: ConnectZuoraOutput;
  createAccount?: Maybe<CreateAccountOutput>;
  createApiKey: CreateApiKeyOutput;
  createCampaign: CreateCampaignOutput;
  createDeflection: CreateDeflectionOutput;
  createFlow: CreateFlowOutput;
  createKlaviyoCodeChallenge: CreateKlaviyoCodeChallengeOutput;
  createPortalSessionStripeSetupIntent: CreatePortalSessionStripeSetupIntentOutput;
  createSegmentGroup: CreateSegmentGroupOutput;
  createStripePortalSession: CreateStripePortalSessionOutput;
  createSubscriberCampaignStripeSetupIntent: CreateSubscriberCampaignStripeSetupIntentOutput;
  declareOfferAutopilotRoundWinner: DeclareOfferAutopilotRoundWinnerOutput;
  declineOffer: DeclineOfferOutput;
  declineOfferGroup: DeclineOfferGroupOutput;
  /** delete data from the table: "account" */
  delete_account?: Maybe<account_mutation_response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<account>;
  /** delete data from the table: "account_feature" */
  delete_account_feature?: Maybe<account_feature_mutation_response>;
  /** delete single row from the table: "account_feature" */
  delete_account_feature_by_pk?: Maybe<account_feature>;
  /** delete data from the table: "account_feature_key" */
  delete_account_feature_key?: Maybe<account_feature_key_mutation_response>;
  /** delete single row from the table: "account_feature_key" */
  delete_account_feature_key_by_pk?: Maybe<account_feature_key>;
  /** delete data from the table: "account_mode" */
  delete_account_mode?: Maybe<account_mode_mutation_response>;
  /** delete single row from the table: "account_mode" */
  delete_account_mode_by_pk?: Maybe<account_mode>;
  /** delete data from the table: "account_revenue_mode" */
  delete_account_revenue_mode?: Maybe<account_revenue_mode_mutation_response>;
  /** delete single row from the table: "account_revenue_mode" */
  delete_account_revenue_mode_by_pk?: Maybe<account_revenue_mode>;
  /** delete data from the table: "account_setting" */
  delete_account_setting?: Maybe<account_setting_mutation_response>;
  /** delete single row from the table: "account_setting" */
  delete_account_setting_by_pk?: Maybe<account_setting>;
  /** delete data from the table: "account_setting_key" */
  delete_account_setting_key?: Maybe<account_setting_key_mutation_response>;
  /** delete single row from the table: "account_setting_key" */
  delete_account_setting_key_by_pk?: Maybe<account_setting_key>;
  /** delete data from the table: "account_status" */
  delete_account_status?: Maybe<account_status_mutation_response>;
  /** delete single row from the table: "account_status" */
  delete_account_status_by_pk?: Maybe<account_status>;
  /** delete data from the table: "account_user" */
  delete_account_user?: Maybe<account_user_mutation_response>;
  /** delete single row from the table: "account_user" */
  delete_account_user_by_pk?: Maybe<account_user>;
  /** delete data from the table: "account_user_setting" */
  delete_account_user_setting?: Maybe<account_user_setting_mutation_response>;
  /** delete single row from the table: "account_user_setting" */
  delete_account_user_setting_by_pk?: Maybe<account_user_setting>;
  /** delete data from the table: "account_user_setting_key" */
  delete_account_user_setting_key?: Maybe<account_user_setting_key_mutation_response>;
  /** delete single row from the table: "account_user_setting_key" */
  delete_account_user_setting_key_by_pk?: Maybe<account_user_setting_key>;
  /** delete data from the table: "acknowledgement" */
  delete_acknowledgement?: Maybe<acknowledgement_mutation_response>;
  /** delete single row from the table: "acknowledgement" */
  delete_acknowledgement_by_pk?: Maybe<acknowledgement>;
  /** delete data from the table: "acknowledgement_group" */
  delete_acknowledgement_group?: Maybe<acknowledgement_group_mutation_response>;
  /** delete single row from the table: "acknowledgement_group" */
  delete_acknowledgement_group_by_pk?: Maybe<acknowledgement_group>;
  /** delete data from the table: "acknowledgement_group_version" */
  delete_acknowledgement_group_version?: Maybe<acknowledgement_group_version_mutation_response>;
  /** delete single row from the table: "acknowledgement_group_version" */
  delete_acknowledgement_group_version_by_pk?: Maybe<acknowledgement_group_version>;
  /** delete data from the table: "acknowledgement_version" */
  delete_acknowledgement_version?: Maybe<acknowledgement_version_mutation_response>;
  /** delete single row from the table: "acknowledgement_version" */
  delete_acknowledgement_version_by_pk?: Maybe<acknowledgement_version>;
  /** delete data from the table: "active_portal_session" */
  delete_active_portal_session?: Maybe<active_portal_session_mutation_response>;
  /** delete data from the table: "annotation" */
  delete_annotation?: Maybe<annotation_mutation_response>;
  /** delete single row from the table: "annotation" */
  delete_annotation_by_pk?: Maybe<annotation>;
  /** delete data from the table: "annotation_flow_version" */
  delete_annotation_flow_version?: Maybe<annotation_flow_version_mutation_response>;
  /** delete single row from the table: "annotation_flow_version" */
  delete_annotation_flow_version_by_pk?: Maybe<annotation_flow_version>;
  /** delete data from the table: "annotation_type" */
  delete_annotation_type?: Maybe<annotation_type_mutation_response>;
  /** delete single row from the table: "annotation_type" */
  delete_annotation_type_by_pk?: Maybe<annotation_type>;
  /** delete data from the table: "api_key" */
  delete_api_key?: Maybe<api_key_mutation_response>;
  /** delete single row from the table: "api_key" */
  delete_api_key_by_pk?: Maybe<api_key>;
  /** delete data from the table: "api_key_purpose" */
  delete_api_key_purpose?: Maybe<api_key_purpose_mutation_response>;
  /** delete single row from the table: "api_key_purpose" */
  delete_api_key_purpose_by_pk?: Maybe<api_key_purpose>;
  /** delete data from the table: "campaign" */
  delete_campaign?: Maybe<campaign_mutation_response>;
  /** delete single row from the table: "campaign" */
  delete_campaign_by_pk?: Maybe<campaign>;
  /** delete data from the table: "campaign_text" */
  delete_campaign_text?: Maybe<campaign_text_mutation_response>;
  /** delete single row from the table: "campaign_text" */
  delete_campaign_text_by_pk?: Maybe<campaign_text>;
  /** delete data from the table: "campaign_text_version" */
  delete_campaign_text_version?: Maybe<campaign_text_version_mutation_response>;
  /** delete single row from the table: "campaign_text_version" */
  delete_campaign_text_version_by_pk?: Maybe<campaign_text_version>;
  /** delete data from the table: "campaign_trigger_type" */
  delete_campaign_trigger_type?: Maybe<campaign_trigger_type_mutation_response>;
  /** delete single row from the table: "campaign_trigger_type" */
  delete_campaign_trigger_type_by_pk?: Maybe<campaign_trigger_type>;
  /** delete data from the table: "campaign_version" */
  delete_campaign_version?: Maybe<campaign_version_mutation_response>;
  /** delete single row from the table: "campaign_version" */
  delete_campaign_version_by_pk?: Maybe<campaign_version>;
  /** delete data from the table: "confirmation" */
  delete_confirmation?: Maybe<confirmation_mutation_response>;
  /** delete single row from the table: "confirmation" */
  delete_confirmation_by_pk?: Maybe<confirmation>;
  /** delete data from the table: "confirmation_version" */
  delete_confirmation_version?: Maybe<confirmation_version_mutation_response>;
  /** delete single row from the table: "confirmation_version" */
  delete_confirmation_version_by_pk?: Maybe<confirmation_version>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<country_mutation_response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<country>;
  /** delete data from the table: "coupon_duration" */
  delete_coupon_duration?: Maybe<coupon_duration_mutation_response>;
  /** delete single row from the table: "coupon_duration" */
  delete_coupon_duration_by_pk?: Maybe<coupon_duration>;
  /** delete data from the table: "coupon_duration_interval" */
  delete_coupon_duration_interval?: Maybe<coupon_duration_interval_mutation_response>;
  /** delete single row from the table: "coupon_duration_interval" */
  delete_coupon_duration_interval_by_pk?: Maybe<coupon_duration_interval>;
  /** delete data from the table: "csv_delta_file" */
  delete_csv_delta_file?: Maybe<csv_delta_file_mutation_response>;
  /** delete single row from the table: "csv_delta_file" */
  delete_csv_delta_file_by_pk?: Maybe<csv_delta_file>;
  /** delete data from the table: "csv_import" */
  delete_csv_import?: Maybe<csv_import_mutation_response>;
  /** delete single row from the table: "csv_import" */
  delete_csv_import_by_pk?: Maybe<csv_import>;
  /** delete data from the table: "csv_import_file" */
  delete_csv_import_file?: Maybe<csv_import_file_mutation_response>;
  /** delete single row from the table: "csv_import_file" */
  delete_csv_import_file_by_pk?: Maybe<csv_import_file>;
  /** delete data from the table: "csv_import_status" */
  delete_csv_import_status?: Maybe<csv_import_status_mutation_response>;
  /** delete single row from the table: "csv_import_status" */
  delete_csv_import_status_by_pk?: Maybe<csv_import_status>;
  /** delete data from the table: "data_connector" */
  delete_data_connector?: Maybe<data_connector_mutation_response>;
  /** delete single row from the table: "data_connector" */
  delete_data_connector_by_pk?: Maybe<data_connector>;
  /** delete data from the table: "data_connector_request_log" */
  delete_data_connector_request_log?: Maybe<data_connector_request_log_mutation_response>;
  /** delete single row from the table: "data_connector_request_log" */
  delete_data_connector_request_log_by_pk?: Maybe<data_connector_request_log>;
  /** delete data from the table: "deflection" */
  delete_deflection?: Maybe<deflection_mutation_response>;
  /** delete single row from the table: "deflection" */
  delete_deflection_by_pk?: Maybe<deflection>;
  /** delete data from the table: "deflection_snapshot_item" */
  delete_deflection_snapshot_item?: Maybe<deflection_snapshot_item_mutation_response>;
  /** delete single row from the table: "deflection_snapshot_item" */
  delete_deflection_snapshot_item_by_pk?: Maybe<deflection_snapshot_item>;
  /** delete data from the table: "deflection_type" */
  delete_deflection_type?: Maybe<deflection_type_mutation_response>;
  /** delete single row from the table: "deflection_type" */
  delete_deflection_type_by_pk?: Maybe<deflection_type>;
  /** delete data from the table: "demo" */
  delete_demo?: Maybe<demo_mutation_response>;
  /** delete single row from the table: "demo" */
  delete_demo_by_pk?: Maybe<demo>;
  /** delete data from the table: "domain" */
  delete_domain?: Maybe<domain_mutation_response>;
  /** delete single row from the table: "domain" */
  delete_domain_by_pk?: Maybe<domain>;
  /** delete data from the table: "domain_type" */
  delete_domain_type?: Maybe<domain_type_mutation_response>;
  /** delete single row from the table: "domain_type" */
  delete_domain_type_by_pk?: Maybe<domain_type>;
  /** delete data from the table: "eligibility_message" */
  delete_eligibility_message?: Maybe<eligibility_message_mutation_response>;
  /** delete single row from the table: "eligibility_message" */
  delete_eligibility_message_by_pk?: Maybe<eligibility_message>;
  /** delete data from the table: "event" */
  delete_event?: Maybe<event_mutation_response>;
  /** delete single row from the table: "event" */
  delete_event_by_pk?: Maybe<event>;
  /** delete data from the table: "event_type" */
  delete_event_type?: Maybe<event_type_mutation_response>;
  /** delete single row from the table: "event_type" */
  delete_event_type_by_pk?: Maybe<event_type>;
  /** delete data from the table: "flow" */
  delete_flow?: Maybe<flow_mutation_response>;
  /** delete data from the table: "flow_action" */
  delete_flow_action?: Maybe<flow_action_mutation_response>;
  /** delete data from the table: "flow_action_appearance" */
  delete_flow_action_appearance?: Maybe<flow_action_appearance_mutation_response>;
  /** delete single row from the table: "flow_action_appearance" */
  delete_flow_action_appearance_by_pk?: Maybe<flow_action_appearance>;
  /** delete single row from the table: "flow_action" */
  delete_flow_action_by_pk?: Maybe<flow_action>;
  /** delete data from the table: "flow_action_type" */
  delete_flow_action_type?: Maybe<flow_action_type_mutation_response>;
  /** delete single row from the table: "flow_action_type" */
  delete_flow_action_type_by_pk?: Maybe<flow_action_type>;
  /** delete data from the table: "flow_auto_cancel_at" */
  delete_flow_auto_cancel_at?: Maybe<flow_auto_cancel_at_mutation_response>;
  /** delete single row from the table: "flow_auto_cancel_at" */
  delete_flow_auto_cancel_at_by_pk?: Maybe<flow_auto_cancel_at>;
  /** delete single row from the table: "flow" */
  delete_flow_by_pk?: Maybe<flow>;
  /** delete data from the table: "flow_language" */
  delete_flow_language?: Maybe<flow_language_mutation_response>;
  /** delete single row from the table: "flow_language" */
  delete_flow_language_by_pk?: Maybe<flow_language>;
  /** delete data from the table: "flow_on_cancel" */
  delete_flow_on_cancel?: Maybe<flow_on_cancel_mutation_response>;
  /** delete single row from the table: "flow_on_cancel" */
  delete_flow_on_cancel_by_pk?: Maybe<flow_on_cancel>;
  /** delete data from the table: "flow_on_cancel_change_plan" */
  delete_flow_on_cancel_change_plan?: Maybe<flow_on_cancel_change_plan_mutation_response>;
  /** delete single row from the table: "flow_on_cancel_change_plan" */
  delete_flow_on_cancel_change_plan_by_pk?: Maybe<flow_on_cancel_change_plan>;
  /** delete data from the table: "flow_on_cancel_perform_at" */
  delete_flow_on_cancel_perform_at?: Maybe<flow_on_cancel_perform_at_mutation_response>;
  /** delete single row from the table: "flow_on_cancel_perform_at" */
  delete_flow_on_cancel_perform_at_by_pk?: Maybe<flow_on_cancel_perform_at>;
  /** delete data from the table: "flow_route_rule" */
  delete_flow_route_rule?: Maybe<flow_route_rule_mutation_response>;
  /** delete single row from the table: "flow_route_rule" */
  delete_flow_route_rule_by_pk?: Maybe<flow_route_rule>;
  /** delete data from the table: "flow_step" */
  delete_flow_step?: Maybe<flow_step_mutation_response>;
  /** delete data from the table: "flow_step_acknowledgement_group" */
  delete_flow_step_acknowledgement_group?: Maybe<flow_step_acknowledgement_group_mutation_response>;
  /** delete single row from the table: "flow_step_acknowledgement_group" */
  delete_flow_step_acknowledgement_group_by_pk?: Maybe<flow_step_acknowledgement_group>;
  /** delete single row from the table: "flow_step" */
  delete_flow_step_by_pk?: Maybe<flow_step>;
  /** delete data from the table: "flow_step_confirmation" */
  delete_flow_step_confirmation?: Maybe<flow_step_confirmation_mutation_response>;
  /** delete single row from the table: "flow_step_confirmation" */
  delete_flow_step_confirmation_by_pk?: Maybe<flow_step_confirmation>;
  /** delete data from the table: "flow_step_deflection_rule_group" */
  delete_flow_step_deflection_rule_group?: Maybe<flow_step_deflection_rule_group_mutation_response>;
  /** delete data from the table: "flow_step_form" */
  delete_flow_step_form?: Maybe<flow_step_form_mutation_response>;
  /** delete single row from the table: "flow_step_form" */
  delete_flow_step_form_by_pk?: Maybe<flow_step_form>;
  /** delete data from the table: "flow_step_intervention" */
  delete_flow_step_intervention?: Maybe<flow_step_intervention_mutation_response>;
  /** delete single row from the table: "flow_step_intervention" */
  delete_flow_step_intervention_by_pk?: Maybe<flow_step_intervention>;
  /** delete data from the table: "flow_step_offer_rule_group" */
  delete_flow_step_offer_rule_group?: Maybe<flow_step_offer_rule_group_mutation_response>;
  /** delete data from the table: "flow_step_question" */
  delete_flow_step_question?: Maybe<flow_step_question_mutation_response>;
  /** delete single row from the table: "flow_step_question" */
  delete_flow_step_question_by_pk?: Maybe<flow_step_question>;
  /** delete data from the table: "flow_step_rule_group" */
  delete_flow_step_rule_group?: Maybe<flow_step_rule_group_mutation_response>;
  /** delete single row from the table: "flow_step_rule_group" */
  delete_flow_step_rule_group_by_pk?: Maybe<flow_step_rule_group>;
  /** delete data from the table: "flow_step_type" */
  delete_flow_step_type?: Maybe<flow_step_type_mutation_response>;
  /** delete single row from the table: "flow_step_type" */
  delete_flow_step_type_by_pk?: Maybe<flow_step_type>;
  /** delete data from the table: "flow_step_version" */
  delete_flow_step_version?: Maybe<flow_step_version_mutation_response>;
  /** delete single row from the table: "flow_step_version" */
  delete_flow_step_version_by_pk?: Maybe<flow_step_version>;
  /** delete data from the table: "flow_subscriber_form_question" */
  delete_flow_subscriber_form_question?: Maybe<flow_subscriber_form_question_mutation_response>;
  /** delete single row from the table: "flow_subscriber_form_question" */
  delete_flow_subscriber_form_question_by_pk?: Maybe<flow_subscriber_form_question>;
  /** delete data from the table: "flow_test" */
  delete_flow_test?: Maybe<flow_test_mutation_response>;
  /** delete single row from the table: "flow_test" */
  delete_flow_test_by_pk?: Maybe<flow_test>;
  /** delete data from the table: "flow_test_flow" */
  delete_flow_test_flow?: Maybe<flow_test_flow_mutation_response>;
  /** delete single row from the table: "flow_test_flow" */
  delete_flow_test_flow_by_pk?: Maybe<flow_test_flow>;
  /** delete data from the table: "flow_text" */
  delete_flow_text?: Maybe<flow_text_mutation_response>;
  /** delete single row from the table: "flow_text" */
  delete_flow_text_by_pk?: Maybe<flow_text>;
  /** delete data from the table: "flow_text_key" */
  delete_flow_text_key?: Maybe<flow_text_key_mutation_response>;
  /** delete single row from the table: "flow_text_key" */
  delete_flow_text_key_by_pk?: Maybe<flow_text_key>;
  /** delete data from the table: "flow_text_version" */
  delete_flow_text_version?: Maybe<flow_text_version_mutation_response>;
  /** delete single row from the table: "flow_text_version" */
  delete_flow_text_version_by_pk?: Maybe<flow_text_version>;
  /** delete data from the table: "flow_version" */
  delete_flow_version?: Maybe<flow_version_mutation_response>;
  /** delete single row from the table: "flow_version" */
  delete_flow_version_by_pk?: Maybe<flow_version>;
  /** delete data from the table: "form" */
  delete_form?: Maybe<form_mutation_response>;
  /** delete single row from the table: "form" */
  delete_form_by_pk?: Maybe<form>;
  /** delete data from the table: "form_version" */
  delete_form_version?: Maybe<form_version_mutation_response>;
  /** delete single row from the table: "form_version" */
  delete_form_version_by_pk?: Maybe<form_version>;
  /** delete data from the table: "function_log" */
  delete_function_log?: Maybe<function_log_mutation_response>;
  /** delete single row from the table: "function_log" */
  delete_function_log_by_pk?: Maybe<function_log>;
  /** delete data from the table: "integration" */
  delete_integration?: Maybe<integration_mutation_response>;
  /** delete single row from the table: "integration" */
  delete_integration_by_pk?: Maybe<integration>;
  /** delete data from the table: "integration_type" */
  delete_integration_type?: Maybe<integration_type_mutation_response>;
  /** delete single row from the table: "integration_type" */
  delete_integration_type_by_pk?: Maybe<integration_type>;
  /** delete data from the table: "intervention" */
  delete_intervention?: Maybe<intervention_mutation_response>;
  /** delete single row from the table: "intervention" */
  delete_intervention_by_pk?: Maybe<intervention>;
  /** delete data from the table: "intervention_version" */
  delete_intervention_version?: Maybe<intervention_version_mutation_response>;
  /** delete single row from the table: "intervention_version" */
  delete_intervention_version_by_pk?: Maybe<intervention_version>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<job_mutation_response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<job>;
  /** delete data from the table: "language" */
  delete_language?: Maybe<language_mutation_response>;
  /** delete single row from the table: "language" */
  delete_language_by_pk?: Maybe<language>;
  /** delete data from the table: "most_recent_subscriber_flow_version" */
  delete_most_recent_subscriber_flow_version?: Maybe<most_recent_subscriber_flow_version_mutation_response>;
  /** delete data from the table: "naviga_cancel_link" */
  delete_naviga_cancel_link?: Maybe<naviga_cancel_link_mutation_response>;
  /** delete single row from the table: "naviga_cancel_link" */
  delete_naviga_cancel_link_by_pk?: Maybe<naviga_cancel_link>;
  /** delete data from the table: "naviga_domain" */
  delete_naviga_domain?: Maybe<naviga_domain_mutation_response>;
  /** delete single row from the table: "naviga_domain" */
  delete_naviga_domain_by_pk?: Maybe<naviga_domain>;
  /** delete data from the table: "naviga_paper_code" */
  delete_naviga_paper_code?: Maybe<naviga_paper_code_mutation_response>;
  /** delete single row from the table: "naviga_paper_code" */
  delete_naviga_paper_code_by_pk?: Maybe<naviga_paper_code>;
  /** delete data from the table: "offer" */
  delete_offer?: Maybe<offer_mutation_response>;
  /** delete data from the table: "offer_autopilot" */
  delete_offer_autopilot?: Maybe<offer_autopilot_mutation_response>;
  /** delete single row from the table: "offer_autopilot" */
  delete_offer_autopilot_by_pk?: Maybe<offer_autopilot>;
  /** delete data from the table: "offer_autopilot_round" */
  delete_offer_autopilot_round?: Maybe<offer_autopilot_round_mutation_response>;
  /** delete single row from the table: "offer_autopilot_round" */
  delete_offer_autopilot_round_by_pk?: Maybe<offer_autopilot_round>;
  /** delete data from the table: "offer_autopilot_round_log" */
  delete_offer_autopilot_round_log?: Maybe<offer_autopilot_round_log_mutation_response>;
  /** delete single row from the table: "offer_autopilot_round_log" */
  delete_offer_autopilot_round_log_by_pk?: Maybe<offer_autopilot_round_log>;
  /** delete data from the table: "offer_autopilot_round_strategy" */
  delete_offer_autopilot_round_strategy?: Maybe<offer_autopilot_round_strategy_mutation_response>;
  /** delete single row from the table: "offer_autopilot_round_strategy" */
  delete_offer_autopilot_round_strategy_by_pk?: Maybe<offer_autopilot_round_strategy>;
  /** delete data from the table: "offer_autopilot_strategy" */
  delete_offer_autopilot_strategy?: Maybe<offer_autopilot_strategy_mutation_response>;
  /** delete single row from the table: "offer_autopilot_strategy" */
  delete_offer_autopilot_strategy_by_pk?: Maybe<offer_autopilot_strategy>;
  /** delete data from the table: "offer_autopilot_success_metric" */
  delete_offer_autopilot_success_metric?: Maybe<offer_autopilot_success_metric_mutation_response>;
  /** delete single row from the table: "offer_autopilot_success_metric" */
  delete_offer_autopilot_success_metric_by_pk?: Maybe<offer_autopilot_success_metric>;
  /** delete single row from the table: "offer" */
  delete_offer_by_pk?: Maybe<offer>;
  /** delete data from the table: "offer_change_plan" */
  delete_offer_change_plan?: Maybe<offer_change_plan_mutation_response>;
  /** delete single row from the table: "offer_change_plan" */
  delete_offer_change_plan_by_pk?: Maybe<offer_change_plan>;
  /** delete data from the table: "offer_change_plan_change_at" */
  delete_offer_change_plan_change_at?: Maybe<offer_change_plan_change_at_mutation_response>;
  /** delete single row from the table: "offer_change_plan_change_at" */
  delete_offer_change_plan_change_at_by_pk?: Maybe<offer_change_plan_change_at>;
  /** delete data from the table: "offer_coupon" */
  delete_offer_coupon?: Maybe<offer_coupon_mutation_response>;
  /** delete data from the table: "offer_coupon_apply_to" */
  delete_offer_coupon_apply_to?: Maybe<offer_coupon_apply_to_mutation_response>;
  /** delete single row from the table: "offer_coupon_apply_to" */
  delete_offer_coupon_apply_to_by_pk?: Maybe<offer_coupon_apply_to>;
  /** delete single row from the table: "offer_coupon" */
  delete_offer_coupon_by_pk?: Maybe<offer_coupon>;
  /** delete data from the table: "offer_coupon_type" */
  delete_offer_coupon_type?: Maybe<offer_coupon_type_mutation_response>;
  /** delete single row from the table: "offer_coupon_type" */
  delete_offer_coupon_type_by_pk?: Maybe<offer_coupon_type>;
  /** delete data from the table: "offer_custom" */
  delete_offer_custom?: Maybe<offer_custom_mutation_response>;
  /** delete single row from the table: "offer_custom" */
  delete_offer_custom_by_pk?: Maybe<offer_custom>;
  /** delete data from the table: "offer_downgrade" */
  delete_offer_downgrade?: Maybe<offer_downgrade_mutation_response>;
  /** delete single row from the table: "offer_downgrade" */
  delete_offer_downgrade_by_pk?: Maybe<offer_downgrade>;
  /** delete data from the table: "offer_goal" */
  delete_offer_goal?: Maybe<offer_goal_mutation_response>;
  /** delete single row from the table: "offer_goal" */
  delete_offer_goal_by_pk?: Maybe<offer_goal>;
  /** delete data from the table: "offer_group" */
  delete_offer_group?: Maybe<offer_group_mutation_response>;
  /** delete single row from the table: "offer_group" */
  delete_offer_group_by_pk?: Maybe<offer_group>;
  /** delete data from the table: "offer_modify_subscription" */
  delete_offer_modify_subscription?: Maybe<offer_modify_subscription_mutation_response>;
  /** delete single row from the table: "offer_modify_subscription" */
  delete_offer_modify_subscription_by_pk?: Maybe<offer_modify_subscription>;
  /** delete data from the table: "offer_modify_subscription_modify_at" */
  delete_offer_modify_subscription_modify_at?: Maybe<offer_modify_subscription_modify_at_mutation_response>;
  /** delete single row from the table: "offer_modify_subscription_modify_at" */
  delete_offer_modify_subscription_modify_at_by_pk?: Maybe<offer_modify_subscription_modify_at>;
  /** delete data from the table: "offer_modify_subscription_option" */
  delete_offer_modify_subscription_option?: Maybe<offer_modify_subscription_option_mutation_response>;
  /** delete single row from the table: "offer_modify_subscription_option" */
  delete_offer_modify_subscription_option_by_pk?: Maybe<offer_modify_subscription_option>;
  /** delete data from the table: "offer_modify_subscription_option_type" */
  delete_offer_modify_subscription_option_type?: Maybe<offer_modify_subscription_option_type_mutation_response>;
  /** delete single row from the table: "offer_modify_subscription_option_type" */
  delete_offer_modify_subscription_option_type_by_pk?: Maybe<offer_modify_subscription_option_type>;
  /** delete data from the table: "offer_pause_subscription" */
  delete_offer_pause_subscription?: Maybe<offer_pause_subscription_mutation_response>;
  /** delete single row from the table: "offer_pause_subscription" */
  delete_offer_pause_subscription_by_pk?: Maybe<offer_pause_subscription>;
  /** delete data from the table: "offer_pause_subscription_interval" */
  delete_offer_pause_subscription_interval?: Maybe<offer_pause_subscription_interval_mutation_response>;
  /** delete single row from the table: "offer_pause_subscription_interval" */
  delete_offer_pause_subscription_interval_by_pk?: Maybe<offer_pause_subscription_interval>;
  /** delete data from the table: "offer_pause_subscription_option" */
  delete_offer_pause_subscription_option?: Maybe<offer_pause_subscription_option_mutation_response>;
  /** delete single row from the table: "offer_pause_subscription_option" */
  delete_offer_pause_subscription_option_by_pk?: Maybe<offer_pause_subscription_option>;
  /** delete data from the table: "offer_pause_subscription_option_type" */
  delete_offer_pause_subscription_option_type?: Maybe<offer_pause_subscription_option_type_mutation_response>;
  /** delete single row from the table: "offer_pause_subscription_option_type" */
  delete_offer_pause_subscription_option_type_by_pk?: Maybe<offer_pause_subscription_option_type>;
  /** delete data from the table: "offer_pause_subscription_pause_at" */
  delete_offer_pause_subscription_pause_at?: Maybe<offer_pause_subscription_pause_at_mutation_response>;
  /** delete single row from the table: "offer_pause_subscription_pause_at" */
  delete_offer_pause_subscription_pause_at_by_pk?: Maybe<offer_pause_subscription_pause_at>;
  /** delete data from the table: "offer_product_swap" */
  delete_offer_product_swap?: Maybe<offer_product_swap_mutation_response>;
  /** delete single row from the table: "offer_product_swap" */
  delete_offer_product_swap_by_pk?: Maybe<offer_product_swap>;
  /** delete data from the table: "offer_product_swap_platform_ecommerce_product" */
  delete_offer_product_swap_platform_ecommerce_product?: Maybe<offer_product_swap_platform_ecommerce_product_mutation_response>;
  /** delete single row from the table: "offer_product_swap_platform_ecommerce_product" */
  delete_offer_product_swap_platform_ecommerce_product_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** delete data from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  delete_offer_product_swap_platform_ecommerce_product_platform_variant?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response>;
  /** delete single row from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  delete_offer_product_swap_platform_ecommerce_product_platform_variant_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** delete data from the table: "offer_reschedule_order" */
  delete_offer_reschedule_order?: Maybe<offer_reschedule_order_mutation_response>;
  /** delete single row from the table: "offer_reschedule_order" */
  delete_offer_reschedule_order_by_pk?: Maybe<offer_reschedule_order>;
  /** delete data from the table: "offer_rule" */
  delete_offer_rule?: Maybe<offer_rule_mutation_response>;
  /** delete single row from the table: "offer_rule" */
  delete_offer_rule_by_pk?: Maybe<offer_rule>;
  /** delete data from the table: "offer_rule_group" */
  delete_offer_rule_group?: Maybe<offer_rule_group_mutation_response>;
  /** delete single row from the table: "offer_rule_group" */
  delete_offer_rule_group_by_pk?: Maybe<offer_rule_group>;
  /** delete data from the table: "offer_rule_group_version" */
  delete_offer_rule_group_version?: Maybe<offer_rule_group_version_mutation_response>;
  /** delete single row from the table: "offer_rule_group_version" */
  delete_offer_rule_group_version_by_pk?: Maybe<offer_rule_group_version>;
  /** delete data from the table: "offer_rule_rule" */
  delete_offer_rule_rule?: Maybe<offer_rule_rule_mutation_response>;
  /** delete single row from the table: "offer_rule_rule" */
  delete_offer_rule_rule_by_pk?: Maybe<offer_rule_rule>;
  /** delete data from the table: "offer_rule_version" */
  delete_offer_rule_version?: Maybe<offer_rule_version_mutation_response>;
  /** delete single row from the table: "offer_rule_version" */
  delete_offer_rule_version_by_pk?: Maybe<offer_rule_version>;
  /** delete data from the table: "offer_style" */
  delete_offer_style?: Maybe<offer_style_mutation_response>;
  /** delete single row from the table: "offer_style" */
  delete_offer_style_by_pk?: Maybe<offer_style>;
  /** delete data from the table: "offer_test" */
  delete_offer_test?: Maybe<offer_test_mutation_response>;
  /** delete single row from the table: "offer_test" */
  delete_offer_test_by_pk?: Maybe<offer_test>;
  /** delete data from the table: "offer_test_offer" */
  delete_offer_test_offer?: Maybe<offer_test_offer_mutation_response>;
  /** delete single row from the table: "offer_test_offer" */
  delete_offer_test_offer_by_pk?: Maybe<offer_test_offer>;
  /** delete data from the table: "offer_timer" */
  delete_offer_timer?: Maybe<offer_timer_mutation_response>;
  /** delete single row from the table: "offer_timer" */
  delete_offer_timer_by_pk?: Maybe<offer_timer>;
  /** delete data from the table: "offer_timer_mode" */
  delete_offer_timer_mode?: Maybe<offer_timer_mode_mutation_response>;
  /** delete single row from the table: "offer_timer_mode" */
  delete_offer_timer_mode_by_pk?: Maybe<offer_timer_mode>;
  /** delete data from the table: "offer_trial_extension" */
  delete_offer_trial_extension?: Maybe<offer_trial_extension_mutation_response>;
  /** delete single row from the table: "offer_trial_extension" */
  delete_offer_trial_extension_by_pk?: Maybe<offer_trial_extension>;
  /** delete data from the table: "offer_type" */
  delete_offer_type?: Maybe<offer_type_mutation_response>;
  /** delete single row from the table: "offer_type" */
  delete_offer_type_by_pk?: Maybe<offer_type>;
  /** delete data from the table: "offer_upgrade" */
  delete_offer_upgrade?: Maybe<offer_upgrade_mutation_response>;
  /** delete single row from the table: "offer_upgrade" */
  delete_offer_upgrade_by_pk?: Maybe<offer_upgrade>;
  /** delete data from the table: "offer_variant" */
  delete_offer_variant?: Maybe<offer_variant_mutation_response>;
  /** delete single row from the table: "offer_variant" */
  delete_offer_variant_by_pk?: Maybe<offer_variant>;
  /** delete data from the table: "offer_variant_coupon" */
  delete_offer_variant_coupon?: Maybe<offer_variant_coupon_mutation_response>;
  /** delete single row from the table: "offer_variant_coupon" */
  delete_offer_variant_coupon_by_pk?: Maybe<offer_variant_coupon>;
  /** delete data from the table: "platform" */
  delete_platform?: Maybe<platform_mutation_response>;
  /** delete data from the table: "platform_addon" */
  delete_platform_addon?: Maybe<platform_addon_mutation_response>;
  /** delete single row from the table: "platform_addon" */
  delete_platform_addon_by_pk?: Maybe<platform_addon>;
  /** delete data from the table: "platform_addon_plan" */
  delete_platform_addon_plan?: Maybe<platform_addon_plan_mutation_response>;
  /** delete single row from the table: "platform_addon_plan" */
  delete_platform_addon_plan_by_pk?: Maybe<platform_addon_plan>;
  /** delete data from the table: "platform_addon_price" */
  delete_platform_addon_price?: Maybe<platform_addon_price_mutation_response>;
  /** delete single row from the table: "platform_addon_price" */
  delete_platform_addon_price_by_pk?: Maybe<platform_addon_price>;
  /** delete data from the table: "platform_addon_price_tier" */
  delete_platform_addon_price_tier?: Maybe<platform_addon_price_tier_mutation_response>;
  /** delete single row from the table: "platform_addon_price_tier" */
  delete_platform_addon_price_tier_by_pk?: Maybe<platform_addon_price_tier>;
  /** delete data from the table: "platform_api_log" */
  delete_platform_api_log?: Maybe<platform_api_log_mutation_response>;
  /** delete single row from the table: "platform_api_log" */
  delete_platform_api_log_by_pk?: Maybe<platform_api_log>;
  /** delete data from the table: "platform_base_currency" */
  delete_platform_base_currency?: Maybe<platform_base_currency_mutation_response>;
  /** delete single row from the table: "platform_base_currency" */
  delete_platform_base_currency_by_pk?: Maybe<platform_base_currency>;
  /** delete single row from the table: "platform" */
  delete_platform_by_pk?: Maybe<platform>;
  /** delete data from the table: "platform_cancel_reason" */
  delete_platform_cancel_reason?: Maybe<platform_cancel_reason_mutation_response>;
  /** delete single row from the table: "platform_cancel_reason" */
  delete_platform_cancel_reason_by_pk?: Maybe<platform_cancel_reason>;
  /** delete data from the table: "platform_connection" */
  delete_platform_connection?: Maybe<platform_connection_mutation_response>;
  /** delete single row from the table: "platform_connection" */
  delete_platform_connection_by_pk?: Maybe<platform_connection>;
  /** delete data from the table: "platform_connection_naviga_domain" */
  delete_platform_connection_naviga_domain?: Maybe<platform_connection_naviga_domain_mutation_response>;
  /** delete single row from the table: "platform_connection_naviga_domain" */
  delete_platform_connection_naviga_domain_by_pk?: Maybe<platform_connection_naviga_domain>;
  /** delete data from the table: "platform_connection_naviga_paper_code_endpoint" */
  delete_platform_connection_naviga_paper_code_endpoint?: Maybe<platform_connection_naviga_paper_code_endpoint_mutation_response>;
  /** delete single row from the table: "platform_connection_naviga_paper_code_endpoint" */
  delete_platform_connection_naviga_paper_code_endpoint_by_pk?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** delete data from the table: "platform_connection_shopify_store" */
  delete_platform_connection_shopify_store?: Maybe<platform_connection_shopify_store_mutation_response>;
  /** delete single row from the table: "platform_connection_shopify_store" */
  delete_platform_connection_shopify_store_by_pk?: Maybe<platform_connection_shopify_store>;
  /** delete data from the table: "platform_coupon" */
  delete_platform_coupon?: Maybe<platform_coupon_mutation_response>;
  /** delete data from the table: "platform_coupon_addon" */
  delete_platform_coupon_addon?: Maybe<platform_coupon_addon_mutation_response>;
  /** delete single row from the table: "platform_coupon_addon" */
  delete_platform_coupon_addon_by_pk?: Maybe<platform_coupon_addon>;
  /** delete data from the table: "platform_coupon_apply_to" */
  delete_platform_coupon_apply_to?: Maybe<platform_coupon_apply_to_mutation_response>;
  /** delete single row from the table: "platform_coupon_apply_to" */
  delete_platform_coupon_apply_to_by_pk?: Maybe<platform_coupon_apply_to>;
  /** delete data from the table: "platform_coupon_apply_to_restriction" */
  delete_platform_coupon_apply_to_restriction?: Maybe<platform_coupon_apply_to_restriction_mutation_response>;
  /** delete single row from the table: "platform_coupon_apply_to_restriction" */
  delete_platform_coupon_apply_to_restriction_by_pk?: Maybe<platform_coupon_apply_to_restriction>;
  /** delete single row from the table: "platform_coupon" */
  delete_platform_coupon_by_pk?: Maybe<platform_coupon>;
  /** delete data from the table: "platform_coupon_duration" */
  delete_platform_coupon_duration?: Maybe<platform_coupon_duration_mutation_response>;
  /** delete single row from the table: "platform_coupon_duration" */
  delete_platform_coupon_duration_by_pk?: Maybe<platform_coupon_duration>;
  /** delete data from the table: "platform_coupon_duration_interval" */
  delete_platform_coupon_duration_interval?: Maybe<platform_coupon_duration_interval_mutation_response>;
  /** delete single row from the table: "platform_coupon_duration_interval" */
  delete_platform_coupon_duration_interval_by_pk?: Maybe<platform_coupon_duration_interval>;
  /** delete data from the table: "platform_coupon_plan" */
  delete_platform_coupon_plan?: Maybe<platform_coupon_plan_mutation_response>;
  /** delete single row from the table: "platform_coupon_plan" */
  delete_platform_coupon_plan_by_pk?: Maybe<platform_coupon_plan>;
  /** delete data from the table: "platform_coupon_product" */
  delete_platform_coupon_product?: Maybe<platform_coupon_product_mutation_response>;
  /** delete single row from the table: "platform_coupon_product" */
  delete_platform_coupon_product_by_pk?: Maybe<platform_coupon_product>;
  /** delete data from the table: "platform_coupon_product_group" */
  delete_platform_coupon_product_group?: Maybe<platform_coupon_product_group_mutation_response>;
  /** delete single row from the table: "platform_coupon_product_group" */
  delete_platform_coupon_product_group_by_pk?: Maybe<platform_coupon_product_group>;
  /** delete data from the table: "platform_coupon_type" */
  delete_platform_coupon_type?: Maybe<platform_coupon_type_mutation_response>;
  /** delete single row from the table: "platform_coupon_type" */
  delete_platform_coupon_type_by_pk?: Maybe<platform_coupon_type>;
  /** delete data from the table: "platform_credentials" */
  delete_platform_credentials?: Maybe<platform_credentials_mutation_response>;
  /** delete single row from the table: "platform_credentials" */
  delete_platform_credentials_by_pk?: Maybe<platform_credentials>;
  /** delete data from the table: "platform_currency" */
  delete_platform_currency?: Maybe<platform_currency_mutation_response>;
  /** delete single row from the table: "platform_currency" */
  delete_platform_currency_by_pk?: Maybe<platform_currency>;
  /** delete data from the table: "platform_currency_exchange_rate" */
  delete_platform_currency_exchange_rate?: Maybe<platform_currency_exchange_rate_mutation_response>;
  /** delete single row from the table: "platform_currency_exchange_rate" */
  delete_platform_currency_exchange_rate_by_pk?: Maybe<platform_currency_exchange_rate>;
  /** delete data from the table: "platform_customer" */
  delete_platform_customer?: Maybe<platform_customer_mutation_response>;
  /** delete single row from the table: "platform_customer" */
  delete_platform_customer_by_pk?: Maybe<platform_customer>;
  /** delete data from the table: "platform_ecommerce_product" */
  delete_platform_ecommerce_product?: Maybe<platform_ecommerce_product_mutation_response>;
  /** delete single row from the table: "platform_ecommerce_product" */
  delete_platform_ecommerce_product_by_pk?: Maybe<platform_ecommerce_product>;
  /** delete data from the table: "platform_import" */
  delete_platform_import?: Maybe<platform_import_mutation_response>;
  /** delete single row from the table: "platform_import" */
  delete_platform_import_by_pk?: Maybe<platform_import>;
  /** delete data from the table: "platform_import_status" */
  delete_platform_import_status?: Maybe<platform_import_status_mutation_response>;
  /** delete single row from the table: "platform_import_status" */
  delete_platform_import_status_by_pk?: Maybe<platform_import_status>;
  /** delete data from the table: "platform_import_step" */
  delete_platform_import_step?: Maybe<platform_import_step_mutation_response>;
  /** delete single row from the table: "platform_import_step" */
  delete_platform_import_step_by_pk?: Maybe<platform_import_step>;
  /** delete data from the table: "platform_import_step_type" */
  delete_platform_import_step_type?: Maybe<platform_import_step_type_mutation_response>;
  /** delete single row from the table: "platform_import_step_type" */
  delete_platform_import_step_type_by_pk?: Maybe<platform_import_step_type>;
  /** delete data from the table: "platform_invoice" */
  delete_platform_invoice?: Maybe<platform_invoice_mutation_response>;
  /** delete single row from the table: "platform_invoice" */
  delete_platform_invoice_by_pk?: Maybe<platform_invoice>;
  /** delete data from the table: "platform_invoice_status" */
  delete_platform_invoice_status?: Maybe<platform_invoice_status_mutation_response>;
  /** delete single row from the table: "platform_invoice_status" */
  delete_platform_invoice_status_by_pk?: Maybe<platform_invoice_status>;
  /** delete data from the table: "platform_newspaper_rate_code" */
  delete_platform_newspaper_rate_code?: Maybe<platform_newspaper_rate_code_mutation_response>;
  /** delete single row from the table: "platform_newspaper_rate_code" */
  delete_platform_newspaper_rate_code_by_pk?: Maybe<platform_newspaper_rate_code>;
  /** delete data from the table: "platform_newspaper_subscription" */
  delete_platform_newspaper_subscription?: Maybe<platform_newspaper_subscription_mutation_response>;
  /** delete single row from the table: "platform_newspaper_subscription" */
  delete_platform_newspaper_subscription_by_pk?: Maybe<platform_newspaper_subscription>;
  /** delete data from the table: "platform_newspaper_subscription_stop_date" */
  delete_platform_newspaper_subscription_stop_date?: Maybe<platform_newspaper_subscription_stop_date_mutation_response>;
  /** delete data from the table: "platform_plan" */
  delete_platform_plan?: Maybe<platform_plan_mutation_response>;
  /** delete single row from the table: "platform_plan" */
  delete_platform_plan_by_pk?: Maybe<platform_plan>;
  /** delete data from the table: "platform_plan_ecommerce_product" */
  delete_platform_plan_ecommerce_product?: Maybe<platform_plan_ecommerce_product_mutation_response>;
  /** delete single row from the table: "platform_plan_ecommerce_product" */
  delete_platform_plan_ecommerce_product_by_pk?: Maybe<platform_plan_ecommerce_product>;
  /** delete data from the table: "platform_plan_group" */
  delete_platform_plan_group?: Maybe<platform_plan_group_mutation_response>;
  /** delete single row from the table: "platform_plan_group" */
  delete_platform_plan_group_by_pk?: Maybe<platform_plan_group>;
  /** delete data from the table: "platform_plan_interval" */
  delete_platform_plan_interval?: Maybe<platform_plan_interval_mutation_response>;
  /** delete single row from the table: "platform_plan_interval" */
  delete_platform_plan_interval_by_pk?: Maybe<platform_plan_interval>;
  /** delete data from the table: "platform_plan_pricing_model" */
  delete_platform_plan_pricing_model?: Maybe<platform_plan_pricing_model_mutation_response>;
  /** delete single row from the table: "platform_plan_pricing_model" */
  delete_platform_plan_pricing_model_by_pk?: Maybe<platform_plan_pricing_model>;
  /** delete data from the table: "platform_plan_tier" */
  delete_platform_plan_tier?: Maybe<platform_plan_tier_mutation_response>;
  /** delete single row from the table: "platform_plan_tier" */
  delete_platform_plan_tier_by_pk?: Maybe<platform_plan_tier>;
  /** delete data from the table: "platform_product" */
  delete_platform_product?: Maybe<platform_product_mutation_response>;
  /** delete single row from the table: "platform_product" */
  delete_platform_product_by_pk?: Maybe<platform_product>;
  /** delete data from the table: "platform_product_group" */
  delete_platform_product_group?: Maybe<platform_product_group_mutation_response>;
  /** delete single row from the table: "platform_product_group" */
  delete_platform_product_group_by_pk?: Maybe<platform_product_group>;
  /** delete data from the table: "platform_raw_object" */
  delete_platform_raw_object?: Maybe<platform_raw_object_mutation_response>;
  /** delete single row from the table: "platform_raw_object" */
  delete_platform_raw_object_by_pk?: Maybe<platform_raw_object>;
  /** delete data from the table: "platform_raw_object_type" */
  delete_platform_raw_object_type?: Maybe<platform_raw_object_type_mutation_response>;
  /** delete single row from the table: "platform_raw_object_type" */
  delete_platform_raw_object_type_by_pk?: Maybe<platform_raw_object_type>;
  /** delete data from the table: "platform_refund" */
  delete_platform_refund?: Maybe<platform_refund_mutation_response>;
  /** delete single row from the table: "platform_refund" */
  delete_platform_refund_by_pk?: Maybe<platform_refund>;
  /** delete data from the table: "platform_subscription" */
  delete_platform_subscription?: Maybe<platform_subscription_mutation_response>;
  /** delete data from the table: "platform_subscription_addon" */
  delete_platform_subscription_addon?: Maybe<platform_subscription_addon_mutation_response>;
  /** delete single row from the table: "platform_subscription_addon" */
  delete_platform_subscription_addon_by_pk?: Maybe<platform_subscription_addon>;
  /** delete single row from the table: "platform_subscription" */
  delete_platform_subscription_by_pk?: Maybe<platform_subscription>;
  /** delete data from the table: "platform_subscription_next_billing_at" */
  delete_platform_subscription_next_billing_at?: Maybe<platform_subscription_next_billing_at_mutation_response>;
  /** delete data from the table: "platform_subscription_plan" */
  delete_platform_subscription_plan?: Maybe<platform_subscription_plan_mutation_response>;
  /** delete single row from the table: "platform_subscription_plan" */
  delete_platform_subscription_plan_by_pk?: Maybe<platform_subscription_plan>;
  /** delete data from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  delete_platform_subscription_plan_swappable_ecommerce_product?: Maybe<platform_subscription_plan_swappable_ecommerce_product_mutation_response>;
  /** delete single row from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  delete_platform_subscription_plan_swappable_ecommerce_product_by_pk?: Maybe<platform_subscription_plan_swappable_ecommerce_product>;
  /** delete data from the table: "platform_subscription_status" */
  delete_platform_subscription_status?: Maybe<platform_subscription_status_mutation_response>;
  /** delete single row from the table: "platform_subscription_status" */
  delete_platform_subscription_status_by_pk?: Maybe<platform_subscription_status>;
  /** delete data from the table: "platform_variant" */
  delete_platform_variant?: Maybe<platform_variant_mutation_response>;
  /** delete single row from the table: "platform_variant" */
  delete_platform_variant_by_pk?: Maybe<platform_variant>;
  /** delete data from the table: "platform_variant_option" */
  delete_platform_variant_option?: Maybe<platform_variant_option_mutation_response>;
  /** delete single row from the table: "platform_variant_option" */
  delete_platform_variant_option_by_pk?: Maybe<platform_variant_option>;
  /** delete data from the table: "portal_email_code" */
  delete_portal_email_code?: Maybe<portal_email_code_mutation_response>;
  /** delete single row from the table: "portal_email_code" */
  delete_portal_email_code_by_pk?: Maybe<portal_email_code>;
  /** delete data from the table: "portal_session" */
  delete_portal_session?: Maybe<portal_session_mutation_response>;
  /** delete single row from the table: "portal_session" */
  delete_portal_session_by_pk?: Maybe<portal_session>;
  /** delete data from the table: "portal_session_subscriber" */
  delete_portal_session_subscriber?: Maybe<portal_session_subscriber_mutation_response>;
  /** delete single row from the table: "portal_session_subscriber" */
  delete_portal_session_subscriber_by_pk?: Maybe<portal_session_subscriber>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<product_mutation_response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<product>;
  /** delete data from the table: "property" */
  delete_property?: Maybe<property_mutation_response>;
  /** delete single row from the table: "property" */
  delete_property_by_pk?: Maybe<property>;
  /** delete data from the table: "property_entity" */
  delete_property_entity?: Maybe<property_entity_mutation_response>;
  /** delete single row from the table: "property_entity" */
  delete_property_entity_by_pk?: Maybe<property_entity>;
  /** delete data from the table: "property_format" */
  delete_property_format?: Maybe<property_format_mutation_response>;
  /** delete single row from the table: "property_format" */
  delete_property_format_by_pk?: Maybe<property_format>;
  /** delete data from the table: "property_type" */
  delete_property_type?: Maybe<property_type_mutation_response>;
  /** delete single row from the table: "property_type" */
  delete_property_type_by_pk?: Maybe<property_type>;
  /** delete data from the table: "question" */
  delete_question?: Maybe<question_mutation_response>;
  /** delete data from the table: "question_answer" */
  delete_question_answer?: Maybe<question_answer_mutation_response>;
  /** delete single row from the table: "question_answer" */
  delete_question_answer_by_pk?: Maybe<question_answer>;
  /** delete data from the table: "question_answer_likelihood_to_return" */
  delete_question_answer_likelihood_to_return?: Maybe<question_answer_likelihood_to_return_mutation_response>;
  /** delete single row from the table: "question_answer_likelihood_to_return" */
  delete_question_answer_likelihood_to_return_by_pk?: Maybe<question_answer_likelihood_to_return>;
  /** delete data from the table: "question_answer_radio" */
  delete_question_answer_radio?: Maybe<question_answer_radio_mutation_response>;
  /** delete single row from the table: "question_answer_radio" */
  delete_question_answer_radio_by_pk?: Maybe<question_answer_radio>;
  /** delete data from the table: "question_answer_sentiment_phrase" */
  delete_question_answer_sentiment_phrase?: Maybe<question_answer_sentiment_phrase_mutation_response>;
  /** delete single row from the table: "question_answer_sentiment_phrase" */
  delete_question_answer_sentiment_phrase_by_pk?: Maybe<question_answer_sentiment_phrase>;
  /** delete data from the table: "question_answer_text" */
  delete_question_answer_text?: Maybe<question_answer_text_mutation_response>;
  /** delete single row from the table: "question_answer_text" */
  delete_question_answer_text_by_pk?: Maybe<question_answer_text>;
  /** delete single row from the table: "question" */
  delete_question_by_pk?: Maybe<question>;
  /** delete data from the table: "question_option" */
  delete_question_option?: Maybe<question_option_mutation_response>;
  /** delete single row from the table: "question_option" */
  delete_question_option_by_pk?: Maybe<question_option>;
  /** delete data from the table: "question_option_version" */
  delete_question_option_version?: Maybe<question_option_version_mutation_response>;
  /** delete single row from the table: "question_option_version" */
  delete_question_option_version_by_pk?: Maybe<question_option_version>;
  /** delete data from the table: "question_setting" */
  delete_question_setting?: Maybe<question_setting_mutation_response>;
  /** delete single row from the table: "question_setting" */
  delete_question_setting_by_pk?: Maybe<question_setting>;
  /** delete data from the table: "question_setting_key" */
  delete_question_setting_key?: Maybe<question_setting_key_mutation_response>;
  /** delete single row from the table: "question_setting_key" */
  delete_question_setting_key_by_pk?: Maybe<question_setting_key>;
  /** delete data from the table: "question_setting_version" */
  delete_question_setting_version?: Maybe<question_setting_version_mutation_response>;
  /** delete single row from the table: "question_setting_version" */
  delete_question_setting_version_by_pk?: Maybe<question_setting_version>;
  /** delete data from the table: "question_type" */
  delete_question_type?: Maybe<question_type_mutation_response>;
  /** delete single row from the table: "question_type" */
  delete_question_type_by_pk?: Maybe<question_type>;
  /** delete data from the table: "question_version" */
  delete_question_version?: Maybe<question_version_mutation_response>;
  /** delete single row from the table: "question_version" */
  delete_question_version_by_pk?: Maybe<question_version>;
  /** delete data from the table: "report_recurring_revenue" */
  delete_report_recurring_revenue?: Maybe<report_recurring_revenue_mutation_response>;
  /** delete single row from the table: "report_recurring_revenue" */
  delete_report_recurring_revenue_by_pk?: Maybe<report_recurring_revenue>;
  /** delete data from the table: "revenue_log" */
  delete_revenue_log?: Maybe<revenue_log_mutation_response>;
  /** delete single row from the table: "revenue_log" */
  delete_revenue_log_by_pk?: Maybe<revenue_log>;
  /** delete data from the table: "revenue_log_revenue_type" */
  delete_revenue_log_revenue_type?: Maybe<revenue_log_revenue_type_mutation_response>;
  /** delete single row from the table: "revenue_log_revenue_type" */
  delete_revenue_log_revenue_type_by_pk?: Maybe<revenue_log_revenue_type>;
  /** delete data from the table: "revenue_log_type" */
  delete_revenue_log_type?: Maybe<revenue_log_type_mutation_response>;
  /** delete single row from the table: "revenue_log_type" */
  delete_revenue_log_type_by_pk?: Maybe<revenue_log_type>;
  /** delete data from the table: "rule_group_mode" */
  delete_rule_group_mode?: Maybe<rule_group_mode_mutation_response>;
  /** delete single row from the table: "rule_group_mode" */
  delete_rule_group_mode_by_pk?: Maybe<rule_group_mode>;
  /** delete data from the table: "script_log" */
  delete_script_log?: Maybe<script_log_mutation_response>;
  /** delete single row from the table: "script_log" */
  delete_script_log_by_pk?: Maybe<script_log>;
  /** delete data from the table: "segment" */
  delete_segment?: Maybe<segment_mutation_response>;
  /** delete single row from the table: "segment" */
  delete_segment_by_pk?: Maybe<segment>;
  /** delete data from the table: "segment_condition" */
  delete_segment_condition?: Maybe<segment_condition_mutation_response>;
  /** delete data from the table: "segment_condition_boolean_operator" */
  delete_segment_condition_boolean_operator?: Maybe<segment_condition_boolean_operator_mutation_response>;
  /** delete single row from the table: "segment_condition_boolean_operator" */
  delete_segment_condition_boolean_operator_by_pk?: Maybe<segment_condition_boolean_operator>;
  /** delete single row from the table: "segment_condition" */
  delete_segment_condition_by_pk?: Maybe<segment_condition>;
  /** delete data from the table: "segment_condition_group" */
  delete_segment_condition_group?: Maybe<segment_condition_group_mutation_response>;
  /** delete single row from the table: "segment_condition_group" */
  delete_segment_condition_group_by_pk?: Maybe<segment_condition_group>;
  /** delete data from the table: "segment_condition_group_entry" */
  delete_segment_condition_group_entry?: Maybe<segment_condition_group_entry_mutation_response>;
  /** delete single row from the table: "segment_condition_group_entry" */
  delete_segment_condition_group_entry_by_pk?: Maybe<segment_condition_group_entry>;
  /** delete data from the table: "segment_condition_operator" */
  delete_segment_condition_operator?: Maybe<segment_condition_operator_mutation_response>;
  /** delete single row from the table: "segment_condition_operator" */
  delete_segment_condition_operator_by_pk?: Maybe<segment_condition_operator>;
  /** delete data from the table: "segment_condition_property" */
  delete_segment_condition_property?: Maybe<segment_condition_property_mutation_response>;
  /** delete single row from the table: "segment_condition_property" */
  delete_segment_condition_property_by_pk?: Maybe<segment_condition_property>;
  /** delete data from the table: "segment_group" */
  delete_segment_group?: Maybe<segment_group_mutation_response>;
  /** delete single row from the table: "segment_group" */
  delete_segment_group_by_pk?: Maybe<segment_group>;
  /** delete data from the table: "segment_group_operator" */
  delete_segment_group_operator?: Maybe<segment_group_operator_mutation_response>;
  /** delete single row from the table: "segment_group_operator" */
  delete_segment_group_operator_by_pk?: Maybe<segment_group_operator>;
  /** delete data from the table: "segment_group_segment" */
  delete_segment_group_segment?: Maybe<segment_group_segment_mutation_response>;
  /** delete single row from the table: "segment_group_segment" */
  delete_segment_group_segment_by_pk?: Maybe<segment_group_segment>;
  /** delete data from the table: "segment_subscription_cache" */
  delete_segment_subscription_cache?: Maybe<segment_subscription_cache_mutation_response>;
  /** delete single row from the table: "segment_subscription_cache" */
  delete_segment_subscription_cache_by_pk?: Maybe<segment_subscription_cache>;
  /** delete data from the table: "sentiment" */
  delete_sentiment?: Maybe<sentiment_mutation_response>;
  /** delete single row from the table: "sentiment" */
  delete_sentiment_by_pk?: Maybe<sentiment>;
  /** delete data from the table: "sentiment_phrase" */
  delete_sentiment_phrase?: Maybe<sentiment_phrase_mutation_response>;
  /** delete single row from the table: "sentiment_phrase" */
  delete_sentiment_phrase_by_pk?: Maybe<sentiment_phrase>;
  /** delete data from the table: "sentiment_phrase_normalized" */
  delete_sentiment_phrase_normalized?: Maybe<sentiment_phrase_normalized_mutation_response>;
  /** delete single row from the table: "sentiment_phrase_normalized" */
  delete_sentiment_phrase_normalized_by_pk?: Maybe<sentiment_phrase_normalized>;
  /** delete data from the table: "state" */
  delete_state?: Maybe<state_mutation_response>;
  /** delete single row from the table: "state" */
  delete_state_by_pk?: Maybe<state>;
  /** delete data from the table: "state_name" */
  delete_state_name?: Maybe<state_name_mutation_response>;
  /** delete single row from the table: "state_name" */
  delete_state_name_by_pk?: Maybe<state_name>;
  /** delete data from the table: "stripe_app" */
  delete_stripe_app?: Maybe<stripe_app_mutation_response>;
  /** delete single row from the table: "stripe_app" */
  delete_stripe_app_by_pk?: Maybe<stripe_app>;
  /** delete data from the table: "subscriber" */
  delete_subscriber?: Maybe<subscriber_mutation_response>;
  /** delete data from the table: "subscriber_acknowledgement" */
  delete_subscriber_acknowledgement?: Maybe<subscriber_acknowledgement_mutation_response>;
  /** delete single row from the table: "subscriber_acknowledgement" */
  delete_subscriber_acknowledgement_by_pk?: Maybe<subscriber_acknowledgement>;
  /** delete data from the table: "subscriber_arr" */
  delete_subscriber_arr?: Maybe<subscriber_arr_mutation_response>;
  /** delete single row from the table: "subscriber" */
  delete_subscriber_by_pk?: Maybe<subscriber>;
  /** delete data from the table: "subscriber_campaign" */
  delete_subscriber_campaign?: Maybe<subscriber_campaign_mutation_response>;
  /** delete single row from the table: "subscriber_campaign" */
  delete_subscriber_campaign_by_pk?: Maybe<subscriber_campaign>;
  /** delete data from the table: "subscriber_campaign_offer" */
  delete_subscriber_campaign_offer?: Maybe<subscriber_campaign_offer_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_offer" */
  delete_subscriber_campaign_offer_by_pk?: Maybe<subscriber_campaign_offer>;
  /** delete data from the table: "subscriber_campaign_offer_rule" */
  delete_subscriber_campaign_offer_rule?: Maybe<subscriber_campaign_offer_rule_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_offer_rule" */
  delete_subscriber_campaign_offer_rule_by_pk?: Maybe<subscriber_campaign_offer_rule>;
  /** delete data from the table: "subscriber_campaign_override" */
  delete_subscriber_campaign_override?: Maybe<subscriber_campaign_override_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_override" */
  delete_subscriber_campaign_override_by_pk?: Maybe<subscriber_campaign_override>;
  /** delete data from the table: "subscriber_campaign_segment" */
  delete_subscriber_campaign_segment?: Maybe<subscriber_campaign_segment_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_segment" */
  delete_subscriber_campaign_segment_by_pk?: Maybe<subscriber_campaign_segment>;
  /** delete data from the table: "subscriber_campaign_status" */
  delete_subscriber_campaign_status?: Maybe<subscriber_campaign_status_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_status" */
  delete_subscriber_campaign_status_by_pk?: Maybe<subscriber_campaign_status>;
  /** delete data from the table: "subscriber_campaign_view" */
  delete_subscriber_campaign_view?: Maybe<subscriber_campaign_view_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_view" */
  delete_subscriber_campaign_view_by_pk?: Maybe<subscriber_campaign_view>;
  /** delete data from the table: "subscriber_campaign_view_status" */
  delete_subscriber_campaign_view_status?: Maybe<subscriber_campaign_view_status_mutation_response>;
  /** delete single row from the table: "subscriber_campaign_view_status" */
  delete_subscriber_campaign_view_status_by_pk?: Maybe<subscriber_campaign_view_status>;
  /** delete data from the table: "subscriber_flow" */
  delete_subscriber_flow?: Maybe<subscriber_flow_mutation_response>;
  /** delete single row from the table: "subscriber_flow" */
  delete_subscriber_flow_by_pk?: Maybe<subscriber_flow>;
  /** delete data from the table: "subscriber_flow_deflection" */
  delete_subscriber_flow_deflection?: Maybe<subscriber_flow_deflection_mutation_response>;
  /** delete single row from the table: "subscriber_flow_deflection" */
  delete_subscriber_flow_deflection_by_pk?: Maybe<subscriber_flow_deflection>;
  /** delete data from the table: "subscriber_flow_export" */
  delete_subscriber_flow_export?: Maybe<subscriber_flow_export_mutation_response>;
  /** delete single row from the table: "subscriber_flow_export" */
  delete_subscriber_flow_export_by_pk?: Maybe<subscriber_flow_export>;
  /** delete data from the table: "subscriber_flow_flow_step" */
  delete_subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step_mutation_response>;
  /** delete single row from the table: "subscriber_flow_flow_step" */
  delete_subscriber_flow_flow_step_by_pk?: Maybe<subscriber_flow_flow_step>;
  /** delete data from the table: "subscriber_flow_offer" */
  delete_subscriber_flow_offer?: Maybe<subscriber_flow_offer_mutation_response>;
  /** delete single row from the table: "subscriber_flow_offer" */
  delete_subscriber_flow_offer_by_pk?: Maybe<subscriber_flow_offer>;
  /** delete data from the table: "subscriber_flow_offer_group" */
  delete_subscriber_flow_offer_group?: Maybe<subscriber_flow_offer_group_mutation_response>;
  /** delete single row from the table: "subscriber_flow_offer_group" */
  delete_subscriber_flow_offer_group_by_pk?: Maybe<subscriber_flow_offer_group>;
  /** delete data from the table: "subscriber_flow_offer_group_offer" */
  delete_subscriber_flow_offer_group_offer?: Maybe<subscriber_flow_offer_group_offer_mutation_response>;
  /** delete single row from the table: "subscriber_flow_offer_group_offer" */
  delete_subscriber_flow_offer_group_offer_by_pk?: Maybe<subscriber_flow_offer_group_offer>;
  /** delete data from the table: "subscriber_flow_offer_rule" */
  delete_subscriber_flow_offer_rule?: Maybe<subscriber_flow_offer_rule_mutation_response>;
  /** delete single row from the table: "subscriber_flow_offer_rule" */
  delete_subscriber_flow_offer_rule_by_pk?: Maybe<subscriber_flow_offer_rule>;
  /** delete data from the table: "subscriber_flow_offer_status" */
  delete_subscriber_flow_offer_status?: Maybe<subscriber_flow_offer_status_mutation_response>;
  /** delete single row from the table: "subscriber_flow_offer_status" */
  delete_subscriber_flow_offer_status_by_pk?: Maybe<subscriber_flow_offer_status>;
  /** delete data from the table: "subscriber_flow_on_cancel_override" */
  delete_subscriber_flow_on_cancel_override?: Maybe<subscriber_flow_on_cancel_override_mutation_response>;
  /** delete single row from the table: "subscriber_flow_on_cancel_override" */
  delete_subscriber_flow_on_cancel_override_by_pk?: Maybe<subscriber_flow_on_cancel_override>;
  /** delete data from the table: "subscriber_flow_override" */
  delete_subscriber_flow_override?: Maybe<subscriber_flow_override_mutation_response>;
  /** delete single row from the table: "subscriber_flow_override" */
  delete_subscriber_flow_override_by_pk?: Maybe<subscriber_flow_override>;
  /** delete data from the table: "subscriber_flow_override_reason" */
  delete_subscriber_flow_override_reason?: Maybe<subscriber_flow_override_reason_mutation_response>;
  /** delete single row from the table: "subscriber_flow_override_reason" */
  delete_subscriber_flow_override_reason_by_pk?: Maybe<subscriber_flow_override_reason>;
  /** delete data from the table: "subscriber_flow_question_answer" */
  delete_subscriber_flow_question_answer?: Maybe<subscriber_flow_question_answer_mutation_response>;
  /** delete single row from the table: "subscriber_flow_question_answer" */
  delete_subscriber_flow_question_answer_by_pk?: Maybe<subscriber_flow_question_answer>;
  /** delete data from the table: "subscriber_flow_segment" */
  delete_subscriber_flow_segment?: Maybe<subscriber_flow_segment_mutation_response>;
  /** delete single row from the table: "subscriber_flow_segment" */
  delete_subscriber_flow_segment_by_pk?: Maybe<subscriber_flow_segment>;
  /** delete data from the table: "subscriber_flow_segment_group" */
  delete_subscriber_flow_segment_group?: Maybe<subscriber_flow_segment_group_mutation_response>;
  /** delete single row from the table: "subscriber_flow_segment_group" */
  delete_subscriber_flow_segment_group_by_pk?: Maybe<subscriber_flow_segment_group>;
  /** delete data from the table: "subscriber_flow_status" */
  delete_subscriber_flow_status?: Maybe<subscriber_flow_status_mutation_response>;
  /** delete single row from the table: "subscriber_flow_status" */
  delete_subscriber_flow_status_by_pk?: Maybe<subscriber_flow_status>;
  /** delete data from the table: "subscriber_log" */
  delete_subscriber_log?: Maybe<subscriber_log_mutation_response>;
  /** delete single row from the table: "subscriber_log" */
  delete_subscriber_log_by_pk?: Maybe<subscriber_log>;
  /** delete data from the table: "subscriber_log_event" */
  delete_subscriber_log_event?: Maybe<subscriber_log_event_mutation_response>;
  /** delete single row from the table: "subscriber_log_event" */
  delete_subscriber_log_event_by_pk?: Maybe<subscriber_log_event>;
  /** delete data from the table: "subscriber_log_offer" */
  delete_subscriber_log_offer?: Maybe<subscriber_log_offer_mutation_response>;
  /** delete single row from the table: "subscriber_log_offer" */
  delete_subscriber_log_offer_by_pk?: Maybe<subscriber_log_offer>;
  /** delete data from the table: "subscriber_log_offer_group" */
  delete_subscriber_log_offer_group?: Maybe<subscriber_log_offer_group_mutation_response>;
  /** delete single row from the table: "subscriber_log_offer_group" */
  delete_subscriber_log_offer_group_by_pk?: Maybe<subscriber_log_offer_group>;
  /** delete data from the table: "subscriber_mrr" */
  delete_subscriber_mrr?: Maybe<subscriber_mrr_mutation_response>;
  /** delete data from the table: "subscriber_property" */
  delete_subscriber_property?: Maybe<subscriber_property_mutation_response>;
  /** delete single row from the table: "subscriber_property" */
  delete_subscriber_property_by_pk?: Maybe<subscriber_property>;
  /** delete data from the table: "subscriber_property_value" */
  delete_subscriber_property_value?: Maybe<subscriber_property_value_mutation_response>;
  /** delete data from the table: "subscriber_status" */
  delete_subscriber_status?: Maybe<subscriber_status_mutation_response>;
  /** delete single row from the table: "subscriber_status" */
  delete_subscriber_status_by_pk?: Maybe<subscriber_status>;
  /** delete data from the table: "subscription" */
  delete_subscription?: Maybe<subscription_mutation_response>;
  /** delete data from the table: "subscription_arr" */
  delete_subscription_arr?: Maybe<subscription_arr_mutation_response>;
  /** delete single row from the table: "subscription" */
  delete_subscription_by_pk?: Maybe<subscription>;
  /** delete data from the table: "subscription_product" */
  delete_subscription_product?: Maybe<subscription_product_mutation_response>;
  /** delete single row from the table: "subscription_product" */
  delete_subscription_product_by_pk?: Maybe<subscription_product>;
  /** delete data from the table: "subscription_property" */
  delete_subscription_property?: Maybe<subscription_property_mutation_response>;
  /** delete single row from the table: "subscription_property" */
  delete_subscription_property_by_pk?: Maybe<subscription_property>;
  /** delete data from the table: "subscription_property_value" */
  delete_subscription_property_value?: Maybe<subscription_property_value_mutation_response>;
  /** delete data from the table: "subscription_segment" */
  delete_subscription_segment?: Maybe<subscription_segment_mutation_response>;
  /** delete single row from the table: "subscription_segment" */
  delete_subscription_segment_by_pk?: Maybe<subscription_segment>;
  /** delete data from the table: "subscription_segment_status" */
  delete_subscription_segment_status?: Maybe<subscription_segment_status_mutation_response>;
  /** delete single row from the table: "subscription_segment_status" */
  delete_subscription_segment_status_by_pk?: Maybe<subscription_segment_status>;
  /** delete data from the table: "subscription_status" */
  delete_subscription_status?: Maybe<subscription_status_mutation_response>;
  /** delete single row from the table: "subscription_status" */
  delete_subscription_status_by_pk?: Maybe<subscription_status>;
  /** delete data from the table: "survey" */
  delete_survey?: Maybe<survey_mutation_response>;
  /** delete single row from the table: "survey" */
  delete_survey_by_pk?: Maybe<survey>;
  /** delete data from the table: "tag" */
  delete_tag?: Maybe<tag_mutation_response>;
  /** delete single row from the table: "tag" */
  delete_tag_by_pk?: Maybe<tag>;
  /** delete data from the table: "template_report_deflection_trends" */
  delete_template_report_deflection_trends?: Maybe<template_report_deflection_trends_mutation_response>;
  /** delete single row from the table: "template_report_deflection_trends" */
  delete_template_report_deflection_trends_by_pk?: Maybe<template_report_deflection_trends>;
  /** delete data from the table: "template_report_feedback_trends" */
  delete_template_report_feedback_trends?: Maybe<template_report_feedback_trends_mutation_response>;
  /** delete single row from the table: "template_report_feedback_trends" */
  delete_template_report_feedback_trends_by_pk?: Maybe<template_report_feedback_trends>;
  /** delete data from the table: "template_report_offer_accept_rate" */
  delete_template_report_offer_accept_rate?: Maybe<template_report_offer_accept_rate_mutation_response>;
  /** delete single row from the table: "template_report_offer_accept_rate" */
  delete_template_report_offer_accept_rate_by_pk?: Maybe<template_report_offer_accept_rate>;
  /** delete data from the table: "template_report_offer_group_accept_rate" */
  delete_template_report_offer_group_accept_rate?: Maybe<template_report_offer_group_accept_rate_mutation_response>;
  /** delete single row from the table: "template_report_offer_group_accept_rate" */
  delete_template_report_offer_group_accept_rate_by_pk?: Maybe<template_report_offer_group_accept_rate>;
  /** delete data from the table: "template_report_offer_group_retained_revenue" */
  delete_template_report_offer_group_retained_revenue?: Maybe<template_report_offer_group_retained_revenue_mutation_response>;
  /** delete single row from the table: "template_report_offer_group_retained_revenue" */
  delete_template_report_offer_group_retained_revenue_by_pk?: Maybe<template_report_offer_group_retained_revenue>;
  /** delete data from the table: "template_report_offer_rate" */
  delete_template_report_offer_rate?: Maybe<template_report_offer_rate_mutation_response>;
  /** delete single row from the table: "template_report_offer_rate" */
  delete_template_report_offer_rate_by_pk?: Maybe<template_report_offer_rate>;
  /** delete data from the table: "template_report_offer_resolution" */
  delete_template_report_offer_resolution?: Maybe<template_report_offer_resolution_mutation_response>;
  /** delete single row from the table: "template_report_offer_resolution" */
  delete_template_report_offer_resolution_by_pk?: Maybe<template_report_offer_resolution>;
  /** delete data from the table: "template_report_offer_retained_revenue" */
  delete_template_report_offer_retained_revenue?: Maybe<template_report_offer_retained_revenue_mutation_response>;
  /** delete single row from the table: "template_report_offer_retained_revenue" */
  delete_template_report_offer_retained_revenue_by_pk?: Maybe<template_report_offer_retained_revenue>;
  /** delete data from the table: "template_report_question_answer_radio" */
  delete_template_report_question_answer_radio?: Maybe<template_report_question_answer_radio_mutation_response>;
  /** delete single row from the table: "template_report_question_answer_radio" */
  delete_template_report_question_answer_radio_by_pk?: Maybe<template_report_question_answer_radio>;
  /** delete data from the table: "template_report_question_answer_radio_inactive_mrr" */
  delete_template_report_question_answer_radio_inactive_mrr?: Maybe<template_report_question_answer_radio_inactive_mrr_mutation_response>;
  /** delete single row from the table: "template_report_question_answer_radio_inactive_mrr" */
  delete_template_report_question_answer_radio_inactive_mrr_by_pk?: Maybe<template_report_question_answer_radio_inactive_mrr>;
  /** delete data from the table: "template_report_question_answer_sentiment" */
  delete_template_report_question_answer_sentiment?: Maybe<template_report_question_answer_sentiment_mutation_response>;
  /** delete single row from the table: "template_report_question_answer_sentiment" */
  delete_template_report_question_answer_sentiment_by_pk?: Maybe<template_report_question_answer_sentiment>;
  /** delete data from the table: "template_report_retained_revenue" */
  delete_template_report_retained_revenue?: Maybe<template_report_retained_revenue_mutation_response>;
  /** delete single row from the table: "template_report_retained_revenue" */
  delete_template_report_retained_revenue_by_pk?: Maybe<template_report_retained_revenue>;
  /** delete data from the table: "template_report_retention_trends" */
  delete_template_report_retention_trends?: Maybe<template_report_retention_trends_mutation_response>;
  /** delete single row from the table: "template_report_retention_trends" */
  delete_template_report_retention_trends_by_pk?: Maybe<template_report_retention_trends>;
  /** delete data from the table: "template_report_subscriber_flow_status" */
  delete_template_report_subscriber_flow_status?: Maybe<template_report_subscriber_flow_status_mutation_response>;
  /** delete single row from the table: "template_report_subscriber_flow_status" */
  delete_template_report_subscriber_flow_status_by_pk?: Maybe<template_report_subscriber_flow_status>;
  /** delete data from the table: "translation" */
  delete_translation?: Maybe<translation_mutation_response>;
  /** delete single row from the table: "translation" */
  delete_translation_by_pk?: Maybe<translation>;
  /** delete data from the table: "translation_value" */
  delete_translation_value?: Maybe<translation_value_mutation_response>;
  /** delete single row from the table: "translation_value" */
  delete_translation_value_by_pk?: Maybe<translation_value>;
  /** delete data from the table: "translation_value_format" */
  delete_translation_value_format?: Maybe<translation_value_format_mutation_response>;
  /** delete single row from the table: "translation_value_format" */
  delete_translation_value_format_by_pk?: Maybe<translation_value_format>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<user_mutation_response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<user>;
  /** delete data from the table: "user_invitation" */
  delete_user_invitation?: Maybe<user_invitation_mutation_response>;
  /** delete single row from the table: "user_invitation" */
  delete_user_invitation_by_pk?: Maybe<user_invitation>;
  /** delete data from the table: "user_profile" */
  delete_user_profile?: Maybe<user_profile_mutation_response>;
  /** delete single row from the table: "user_profile" */
  delete_user_profile_by_pk?: Maybe<user_profile>;
  /** delete data from the table: "webhook" */
  delete_webhook?: Maybe<webhook_mutation_response>;
  /** delete single row from the table: "webhook" */
  delete_webhook_by_pk?: Maybe<webhook>;
  /** delete data from the table: "webhook_event" */
  delete_webhook_event?: Maybe<webhook_event_mutation_response>;
  /** delete single row from the table: "webhook_event" */
  delete_webhook_event_by_pk?: Maybe<webhook_event>;
  /** delete data from the table: "webhook_log" */
  delete_webhook_log?: Maybe<webhook_log_mutation_response>;
  /** delete single row from the table: "webhook_log" */
  delete_webhook_log_by_pk?: Maybe<webhook_log>;
  /** delete data from the table: "webhook_request_log" */
  delete_webhook_request_log?: Maybe<webhook_request_log_mutation_response>;
  /** delete single row from the table: "webhook_request_log" */
  delete_webhook_request_log_by_pk?: Maybe<webhook_request_log>;
  /** delete data from the table: "webhook_status" */
  delete_webhook_status?: Maybe<webhook_status_mutation_response>;
  /** delete single row from the table: "webhook_status" */
  delete_webhook_status_by_pk?: Maybe<webhook_status>;
  disconnectSegmentSource: DisconnectSegmentSourceOutput;
  duplicateFlow: DuplicateFlowOutput;
  evaluateOfferAutopilotRound: EvaluateOfferAutopilotRoundOutput;
  exportCancellations: ExportCancellationsOutput;
  exportSessions: ExportSessionsOutput;
  exportSessionsNew: ExportSessionsNewOutput;
  importNavigaCsv: ImportNavigaCsvOutput;
  initializeSubscriberFlow: InitializeSubscriberFlowOutput;
  /** insert data into the table: "account" */
  insert_account?: Maybe<account_mutation_response>;
  /** insert data into the table: "account_feature" */
  insert_account_feature?: Maybe<account_feature_mutation_response>;
  /** insert data into the table: "account_feature_key" */
  insert_account_feature_key?: Maybe<account_feature_key_mutation_response>;
  /** insert a single row into the table: "account_feature_key" */
  insert_account_feature_key_one?: Maybe<account_feature_key>;
  /** insert a single row into the table: "account_feature" */
  insert_account_feature_one?: Maybe<account_feature>;
  /** insert data into the table: "account_mode" */
  insert_account_mode?: Maybe<account_mode_mutation_response>;
  /** insert a single row into the table: "account_mode" */
  insert_account_mode_one?: Maybe<account_mode>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<account>;
  /** insert data into the table: "account_revenue_mode" */
  insert_account_revenue_mode?: Maybe<account_revenue_mode_mutation_response>;
  /** insert a single row into the table: "account_revenue_mode" */
  insert_account_revenue_mode_one?: Maybe<account_revenue_mode>;
  /** insert data into the table: "account_setting" */
  insert_account_setting?: Maybe<account_setting_mutation_response>;
  /** insert data into the table: "account_setting_key" */
  insert_account_setting_key?: Maybe<account_setting_key_mutation_response>;
  /** insert a single row into the table: "account_setting_key" */
  insert_account_setting_key_one?: Maybe<account_setting_key>;
  /** insert a single row into the table: "account_setting" */
  insert_account_setting_one?: Maybe<account_setting>;
  /** insert data into the table: "account_status" */
  insert_account_status?: Maybe<account_status_mutation_response>;
  /** insert a single row into the table: "account_status" */
  insert_account_status_one?: Maybe<account_status>;
  /** insert data into the table: "account_user" */
  insert_account_user?: Maybe<account_user_mutation_response>;
  /** insert a single row into the table: "account_user" */
  insert_account_user_one?: Maybe<account_user>;
  /** insert data into the table: "account_user_setting" */
  insert_account_user_setting?: Maybe<account_user_setting_mutation_response>;
  /** insert data into the table: "account_user_setting_key" */
  insert_account_user_setting_key?: Maybe<account_user_setting_key_mutation_response>;
  /** insert a single row into the table: "account_user_setting_key" */
  insert_account_user_setting_key_one?: Maybe<account_user_setting_key>;
  /** insert a single row into the table: "account_user_setting" */
  insert_account_user_setting_one?: Maybe<account_user_setting>;
  /** insert data into the table: "acknowledgement" */
  insert_acknowledgement?: Maybe<acknowledgement_mutation_response>;
  /** insert data into the table: "acknowledgement_group" */
  insert_acknowledgement_group?: Maybe<acknowledgement_group_mutation_response>;
  /** insert a single row into the table: "acknowledgement_group" */
  insert_acknowledgement_group_one?: Maybe<acknowledgement_group>;
  /** insert data into the table: "acknowledgement_group_version" */
  insert_acknowledgement_group_version?: Maybe<acknowledgement_group_version_mutation_response>;
  /** insert a single row into the table: "acknowledgement_group_version" */
  insert_acknowledgement_group_version_one?: Maybe<acknowledgement_group_version>;
  /** insert a single row into the table: "acknowledgement" */
  insert_acknowledgement_one?: Maybe<acknowledgement>;
  /** insert data into the table: "acknowledgement_version" */
  insert_acknowledgement_version?: Maybe<acknowledgement_version_mutation_response>;
  /** insert a single row into the table: "acknowledgement_version" */
  insert_acknowledgement_version_one?: Maybe<acknowledgement_version>;
  /** insert data into the table: "active_portal_session" */
  insert_active_portal_session?: Maybe<active_portal_session_mutation_response>;
  /** insert a single row into the table: "active_portal_session" */
  insert_active_portal_session_one?: Maybe<active_portal_session>;
  /** insert data into the table: "annotation" */
  insert_annotation?: Maybe<annotation_mutation_response>;
  /** insert data into the table: "annotation_flow_version" */
  insert_annotation_flow_version?: Maybe<annotation_flow_version_mutation_response>;
  /** insert a single row into the table: "annotation_flow_version" */
  insert_annotation_flow_version_one?: Maybe<annotation_flow_version>;
  /** insert a single row into the table: "annotation" */
  insert_annotation_one?: Maybe<annotation>;
  /** insert data into the table: "annotation_type" */
  insert_annotation_type?: Maybe<annotation_type_mutation_response>;
  /** insert a single row into the table: "annotation_type" */
  insert_annotation_type_one?: Maybe<annotation_type>;
  /** insert data into the table: "api_key" */
  insert_api_key?: Maybe<api_key_mutation_response>;
  /** insert a single row into the table: "api_key" */
  insert_api_key_one?: Maybe<api_key>;
  /** insert data into the table: "api_key_purpose" */
  insert_api_key_purpose?: Maybe<api_key_purpose_mutation_response>;
  /** insert a single row into the table: "api_key_purpose" */
  insert_api_key_purpose_one?: Maybe<api_key_purpose>;
  /** insert data into the table: "campaign" */
  insert_campaign?: Maybe<campaign_mutation_response>;
  /** insert a single row into the table: "campaign" */
  insert_campaign_one?: Maybe<campaign>;
  /** insert data into the table: "campaign_text" */
  insert_campaign_text?: Maybe<campaign_text_mutation_response>;
  /** insert a single row into the table: "campaign_text" */
  insert_campaign_text_one?: Maybe<campaign_text>;
  /** insert data into the table: "campaign_text_version" */
  insert_campaign_text_version?: Maybe<campaign_text_version_mutation_response>;
  /** insert a single row into the table: "campaign_text_version" */
  insert_campaign_text_version_one?: Maybe<campaign_text_version>;
  /** insert data into the table: "campaign_trigger_type" */
  insert_campaign_trigger_type?: Maybe<campaign_trigger_type_mutation_response>;
  /** insert a single row into the table: "campaign_trigger_type" */
  insert_campaign_trigger_type_one?: Maybe<campaign_trigger_type>;
  /** insert data into the table: "campaign_version" */
  insert_campaign_version?: Maybe<campaign_version_mutation_response>;
  /** insert a single row into the table: "campaign_version" */
  insert_campaign_version_one?: Maybe<campaign_version>;
  /** insert data into the table: "confirmation" */
  insert_confirmation?: Maybe<confirmation_mutation_response>;
  /** insert a single row into the table: "confirmation" */
  insert_confirmation_one?: Maybe<confirmation>;
  /** insert data into the table: "confirmation_version" */
  insert_confirmation_version?: Maybe<confirmation_version_mutation_response>;
  /** insert a single row into the table: "confirmation_version" */
  insert_confirmation_version_one?: Maybe<confirmation_version>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<country_mutation_response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<country>;
  /** insert data into the table: "coupon_duration" */
  insert_coupon_duration?: Maybe<coupon_duration_mutation_response>;
  /** insert data into the table: "coupon_duration_interval" */
  insert_coupon_duration_interval?: Maybe<coupon_duration_interval_mutation_response>;
  /** insert a single row into the table: "coupon_duration_interval" */
  insert_coupon_duration_interval_one?: Maybe<coupon_duration_interval>;
  /** insert a single row into the table: "coupon_duration" */
  insert_coupon_duration_one?: Maybe<coupon_duration>;
  /** insert data into the table: "csv_delta_file" */
  insert_csv_delta_file?: Maybe<csv_delta_file_mutation_response>;
  /** insert a single row into the table: "csv_delta_file" */
  insert_csv_delta_file_one?: Maybe<csv_delta_file>;
  /** insert data into the table: "csv_import" */
  insert_csv_import?: Maybe<csv_import_mutation_response>;
  /** insert data into the table: "csv_import_file" */
  insert_csv_import_file?: Maybe<csv_import_file_mutation_response>;
  /** insert a single row into the table: "csv_import_file" */
  insert_csv_import_file_one?: Maybe<csv_import_file>;
  /** insert a single row into the table: "csv_import" */
  insert_csv_import_one?: Maybe<csv_import>;
  /** insert data into the table: "csv_import_status" */
  insert_csv_import_status?: Maybe<csv_import_status_mutation_response>;
  /** insert a single row into the table: "csv_import_status" */
  insert_csv_import_status_one?: Maybe<csv_import_status>;
  /** insert data into the table: "data_connector" */
  insert_data_connector?: Maybe<data_connector_mutation_response>;
  /** insert a single row into the table: "data_connector" */
  insert_data_connector_one?: Maybe<data_connector>;
  /** insert data into the table: "data_connector_request_log" */
  insert_data_connector_request_log?: Maybe<data_connector_request_log_mutation_response>;
  /** insert a single row into the table: "data_connector_request_log" */
  insert_data_connector_request_log_one?: Maybe<data_connector_request_log>;
  /** insert data into the table: "deflection" */
  insert_deflection?: Maybe<deflection_mutation_response>;
  /** insert a single row into the table: "deflection" */
  insert_deflection_one?: Maybe<deflection>;
  /** insert data into the table: "deflection_snapshot_item" */
  insert_deflection_snapshot_item?: Maybe<deflection_snapshot_item_mutation_response>;
  /** insert a single row into the table: "deflection_snapshot_item" */
  insert_deflection_snapshot_item_one?: Maybe<deflection_snapshot_item>;
  /** insert data into the table: "deflection_type" */
  insert_deflection_type?: Maybe<deflection_type_mutation_response>;
  /** insert a single row into the table: "deflection_type" */
  insert_deflection_type_one?: Maybe<deflection_type>;
  /** insert data into the table: "demo" */
  insert_demo?: Maybe<demo_mutation_response>;
  /** insert a single row into the table: "demo" */
  insert_demo_one?: Maybe<demo>;
  /** insert data into the table: "domain" */
  insert_domain?: Maybe<domain_mutation_response>;
  /** insert a single row into the table: "domain" */
  insert_domain_one?: Maybe<domain>;
  /** insert data into the table: "domain_type" */
  insert_domain_type?: Maybe<domain_type_mutation_response>;
  /** insert a single row into the table: "domain_type" */
  insert_domain_type_one?: Maybe<domain_type>;
  /** insert data into the table: "eligibility_message" */
  insert_eligibility_message?: Maybe<eligibility_message_mutation_response>;
  /** insert a single row into the table: "eligibility_message" */
  insert_eligibility_message_one?: Maybe<eligibility_message>;
  /** insert data into the table: "event" */
  insert_event?: Maybe<event_mutation_response>;
  /** insert a single row into the table: "event" */
  insert_event_one?: Maybe<event>;
  /** insert data into the table: "event_type" */
  insert_event_type?: Maybe<event_type_mutation_response>;
  /** insert a single row into the table: "event_type" */
  insert_event_type_one?: Maybe<event_type>;
  /** insert data into the table: "flow" */
  insert_flow?: Maybe<flow_mutation_response>;
  /** insert data into the table: "flow_action" */
  insert_flow_action?: Maybe<flow_action_mutation_response>;
  /** insert data into the table: "flow_action_appearance" */
  insert_flow_action_appearance?: Maybe<flow_action_appearance_mutation_response>;
  /** insert a single row into the table: "flow_action_appearance" */
  insert_flow_action_appearance_one?: Maybe<flow_action_appearance>;
  /** insert a single row into the table: "flow_action" */
  insert_flow_action_one?: Maybe<flow_action>;
  /** insert data into the table: "flow_action_type" */
  insert_flow_action_type?: Maybe<flow_action_type_mutation_response>;
  /** insert a single row into the table: "flow_action_type" */
  insert_flow_action_type_one?: Maybe<flow_action_type>;
  /** insert data into the table: "flow_auto_cancel_at" */
  insert_flow_auto_cancel_at?: Maybe<flow_auto_cancel_at_mutation_response>;
  /** insert a single row into the table: "flow_auto_cancel_at" */
  insert_flow_auto_cancel_at_one?: Maybe<flow_auto_cancel_at>;
  /** insert data into the table: "flow_language" */
  insert_flow_language?: Maybe<flow_language_mutation_response>;
  /** insert a single row into the table: "flow_language" */
  insert_flow_language_one?: Maybe<flow_language>;
  /** insert data into the table: "flow_on_cancel" */
  insert_flow_on_cancel?: Maybe<flow_on_cancel_mutation_response>;
  /** insert data into the table: "flow_on_cancel_change_plan" */
  insert_flow_on_cancel_change_plan?: Maybe<flow_on_cancel_change_plan_mutation_response>;
  /** insert a single row into the table: "flow_on_cancel_change_plan" */
  insert_flow_on_cancel_change_plan_one?: Maybe<flow_on_cancel_change_plan>;
  /** insert a single row into the table: "flow_on_cancel" */
  insert_flow_on_cancel_one?: Maybe<flow_on_cancel>;
  /** insert data into the table: "flow_on_cancel_perform_at" */
  insert_flow_on_cancel_perform_at?: Maybe<flow_on_cancel_perform_at_mutation_response>;
  /** insert a single row into the table: "flow_on_cancel_perform_at" */
  insert_flow_on_cancel_perform_at_one?: Maybe<flow_on_cancel_perform_at>;
  /** insert a single row into the table: "flow" */
  insert_flow_one?: Maybe<flow>;
  /** insert data into the table: "flow_route_rule" */
  insert_flow_route_rule?: Maybe<flow_route_rule_mutation_response>;
  /** insert a single row into the table: "flow_route_rule" */
  insert_flow_route_rule_one?: Maybe<flow_route_rule>;
  /** insert data into the table: "flow_step" */
  insert_flow_step?: Maybe<flow_step_mutation_response>;
  /** insert data into the table: "flow_step_acknowledgement_group" */
  insert_flow_step_acknowledgement_group?: Maybe<flow_step_acknowledgement_group_mutation_response>;
  /** insert a single row into the table: "flow_step_acknowledgement_group" */
  insert_flow_step_acknowledgement_group_one?: Maybe<flow_step_acknowledgement_group>;
  /** insert data into the table: "flow_step_confirmation" */
  insert_flow_step_confirmation?: Maybe<flow_step_confirmation_mutation_response>;
  /** insert a single row into the table: "flow_step_confirmation" */
  insert_flow_step_confirmation_one?: Maybe<flow_step_confirmation>;
  /** insert data into the table: "flow_step_deflection_rule_group" */
  insert_flow_step_deflection_rule_group?: Maybe<flow_step_deflection_rule_group_mutation_response>;
  /** insert a single row into the table: "flow_step_deflection_rule_group" */
  insert_flow_step_deflection_rule_group_one?: Maybe<flow_step_deflection_rule_group>;
  /** insert data into the table: "flow_step_form" */
  insert_flow_step_form?: Maybe<flow_step_form_mutation_response>;
  /** insert a single row into the table: "flow_step_form" */
  insert_flow_step_form_one?: Maybe<flow_step_form>;
  /** insert data into the table: "flow_step_intervention" */
  insert_flow_step_intervention?: Maybe<flow_step_intervention_mutation_response>;
  /** insert a single row into the table: "flow_step_intervention" */
  insert_flow_step_intervention_one?: Maybe<flow_step_intervention>;
  /** insert data into the table: "flow_step_offer_rule_group" */
  insert_flow_step_offer_rule_group?: Maybe<flow_step_offer_rule_group_mutation_response>;
  /** insert a single row into the table: "flow_step_offer_rule_group" */
  insert_flow_step_offer_rule_group_one?: Maybe<flow_step_offer_rule_group>;
  /** insert a single row into the table: "flow_step" */
  insert_flow_step_one?: Maybe<flow_step>;
  /** insert data into the table: "flow_step_question" */
  insert_flow_step_question?: Maybe<flow_step_question_mutation_response>;
  /** insert a single row into the table: "flow_step_question" */
  insert_flow_step_question_one?: Maybe<flow_step_question>;
  /** insert data into the table: "flow_step_rule_group" */
  insert_flow_step_rule_group?: Maybe<flow_step_rule_group_mutation_response>;
  /** insert a single row into the table: "flow_step_rule_group" */
  insert_flow_step_rule_group_one?: Maybe<flow_step_rule_group>;
  /** insert data into the table: "flow_step_type" */
  insert_flow_step_type?: Maybe<flow_step_type_mutation_response>;
  /** insert a single row into the table: "flow_step_type" */
  insert_flow_step_type_one?: Maybe<flow_step_type>;
  /** insert data into the table: "flow_step_version" */
  insert_flow_step_version?: Maybe<flow_step_version_mutation_response>;
  /** insert a single row into the table: "flow_step_version" */
  insert_flow_step_version_one?: Maybe<flow_step_version>;
  /** insert data into the table: "flow_subscriber_form_question" */
  insert_flow_subscriber_form_question?: Maybe<flow_subscriber_form_question_mutation_response>;
  /** insert a single row into the table: "flow_subscriber_form_question" */
  insert_flow_subscriber_form_question_one?: Maybe<flow_subscriber_form_question>;
  /** insert data into the table: "flow_test" */
  insert_flow_test?: Maybe<flow_test_mutation_response>;
  /** insert data into the table: "flow_test_flow" */
  insert_flow_test_flow?: Maybe<flow_test_flow_mutation_response>;
  /** insert a single row into the table: "flow_test_flow" */
  insert_flow_test_flow_one?: Maybe<flow_test_flow>;
  /** insert a single row into the table: "flow_test" */
  insert_flow_test_one?: Maybe<flow_test>;
  /** insert data into the table: "flow_text" */
  insert_flow_text?: Maybe<flow_text_mutation_response>;
  /** insert data into the table: "flow_text_key" */
  insert_flow_text_key?: Maybe<flow_text_key_mutation_response>;
  /** insert a single row into the table: "flow_text_key" */
  insert_flow_text_key_one?: Maybe<flow_text_key>;
  /** insert a single row into the table: "flow_text" */
  insert_flow_text_one?: Maybe<flow_text>;
  /** insert data into the table: "flow_text_version" */
  insert_flow_text_version?: Maybe<flow_text_version_mutation_response>;
  /** insert a single row into the table: "flow_text_version" */
  insert_flow_text_version_one?: Maybe<flow_text_version>;
  /** insert data into the table: "flow_version" */
  insert_flow_version?: Maybe<flow_version_mutation_response>;
  /** insert a single row into the table: "flow_version" */
  insert_flow_version_one?: Maybe<flow_version>;
  /** insert data into the table: "form" */
  insert_form?: Maybe<form_mutation_response>;
  /** insert a single row into the table: "form" */
  insert_form_one?: Maybe<form>;
  /** insert data into the table: "form_version" */
  insert_form_version?: Maybe<form_version_mutation_response>;
  /** insert a single row into the table: "form_version" */
  insert_form_version_one?: Maybe<form_version>;
  /** insert data into the table: "function_log" */
  insert_function_log?: Maybe<function_log_mutation_response>;
  /** insert a single row into the table: "function_log" */
  insert_function_log_one?: Maybe<function_log>;
  /** insert data into the table: "integration" */
  insert_integration?: Maybe<integration_mutation_response>;
  /** insert a single row into the table: "integration" */
  insert_integration_one?: Maybe<integration>;
  /** insert data into the table: "integration_type" */
  insert_integration_type?: Maybe<integration_type_mutation_response>;
  /** insert a single row into the table: "integration_type" */
  insert_integration_type_one?: Maybe<integration_type>;
  /** insert data into the table: "intervention" */
  insert_intervention?: Maybe<intervention_mutation_response>;
  /** insert a single row into the table: "intervention" */
  insert_intervention_one?: Maybe<intervention>;
  /** insert data into the table: "intervention_version" */
  insert_intervention_version?: Maybe<intervention_version_mutation_response>;
  /** insert a single row into the table: "intervention_version" */
  insert_intervention_version_one?: Maybe<intervention_version>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<job_mutation_response>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<job>;
  /** insert data into the table: "language" */
  insert_language?: Maybe<language_mutation_response>;
  /** insert a single row into the table: "language" */
  insert_language_one?: Maybe<language>;
  /** insert data into the table: "most_recent_subscriber_flow_version" */
  insert_most_recent_subscriber_flow_version?: Maybe<most_recent_subscriber_flow_version_mutation_response>;
  /** insert a single row into the table: "most_recent_subscriber_flow_version" */
  insert_most_recent_subscriber_flow_version_one?: Maybe<most_recent_subscriber_flow_version>;
  /** insert data into the table: "naviga_cancel_link" */
  insert_naviga_cancel_link?: Maybe<naviga_cancel_link_mutation_response>;
  /** insert a single row into the table: "naviga_cancel_link" */
  insert_naviga_cancel_link_one?: Maybe<naviga_cancel_link>;
  /** insert data into the table: "naviga_domain" */
  insert_naviga_domain?: Maybe<naviga_domain_mutation_response>;
  /** insert a single row into the table: "naviga_domain" */
  insert_naviga_domain_one?: Maybe<naviga_domain>;
  /** insert data into the table: "naviga_paper_code" */
  insert_naviga_paper_code?: Maybe<naviga_paper_code_mutation_response>;
  /** insert a single row into the table: "naviga_paper_code" */
  insert_naviga_paper_code_one?: Maybe<naviga_paper_code>;
  /** insert data into the table: "offer" */
  insert_offer?: Maybe<offer_mutation_response>;
  /** insert data into the table: "offer_autopilot" */
  insert_offer_autopilot?: Maybe<offer_autopilot_mutation_response>;
  /** insert a single row into the table: "offer_autopilot" */
  insert_offer_autopilot_one?: Maybe<offer_autopilot>;
  /** insert data into the table: "offer_autopilot_round" */
  insert_offer_autopilot_round?: Maybe<offer_autopilot_round_mutation_response>;
  /** insert data into the table: "offer_autopilot_round_log" */
  insert_offer_autopilot_round_log?: Maybe<offer_autopilot_round_log_mutation_response>;
  /** insert a single row into the table: "offer_autopilot_round_log" */
  insert_offer_autopilot_round_log_one?: Maybe<offer_autopilot_round_log>;
  /** insert a single row into the table: "offer_autopilot_round" */
  insert_offer_autopilot_round_one?: Maybe<offer_autopilot_round>;
  /** insert data into the table: "offer_autopilot_round_strategy" */
  insert_offer_autopilot_round_strategy?: Maybe<offer_autopilot_round_strategy_mutation_response>;
  /** insert a single row into the table: "offer_autopilot_round_strategy" */
  insert_offer_autopilot_round_strategy_one?: Maybe<offer_autopilot_round_strategy>;
  /** insert data into the table: "offer_autopilot_strategy" */
  insert_offer_autopilot_strategy?: Maybe<offer_autopilot_strategy_mutation_response>;
  /** insert a single row into the table: "offer_autopilot_strategy" */
  insert_offer_autopilot_strategy_one?: Maybe<offer_autopilot_strategy>;
  /** insert data into the table: "offer_autopilot_success_metric" */
  insert_offer_autopilot_success_metric?: Maybe<offer_autopilot_success_metric_mutation_response>;
  /** insert a single row into the table: "offer_autopilot_success_metric" */
  insert_offer_autopilot_success_metric_one?: Maybe<offer_autopilot_success_metric>;
  /** insert data into the table: "offer_change_plan" */
  insert_offer_change_plan?: Maybe<offer_change_plan_mutation_response>;
  /** insert data into the table: "offer_change_plan_change_at" */
  insert_offer_change_plan_change_at?: Maybe<offer_change_plan_change_at_mutation_response>;
  /** insert a single row into the table: "offer_change_plan_change_at" */
  insert_offer_change_plan_change_at_one?: Maybe<offer_change_plan_change_at>;
  /** insert a single row into the table: "offer_change_plan" */
  insert_offer_change_plan_one?: Maybe<offer_change_plan>;
  /** insert data into the table: "offer_coupon" */
  insert_offer_coupon?: Maybe<offer_coupon_mutation_response>;
  /** insert data into the table: "offer_coupon_apply_to" */
  insert_offer_coupon_apply_to?: Maybe<offer_coupon_apply_to_mutation_response>;
  /** insert a single row into the table: "offer_coupon_apply_to" */
  insert_offer_coupon_apply_to_one?: Maybe<offer_coupon_apply_to>;
  /** insert a single row into the table: "offer_coupon" */
  insert_offer_coupon_one?: Maybe<offer_coupon>;
  /** insert data into the table: "offer_coupon_type" */
  insert_offer_coupon_type?: Maybe<offer_coupon_type_mutation_response>;
  /** insert a single row into the table: "offer_coupon_type" */
  insert_offer_coupon_type_one?: Maybe<offer_coupon_type>;
  /** insert data into the table: "offer_custom" */
  insert_offer_custom?: Maybe<offer_custom_mutation_response>;
  /** insert a single row into the table: "offer_custom" */
  insert_offer_custom_one?: Maybe<offer_custom>;
  /** insert data into the table: "offer_downgrade" */
  insert_offer_downgrade?: Maybe<offer_downgrade_mutation_response>;
  /** insert a single row into the table: "offer_downgrade" */
  insert_offer_downgrade_one?: Maybe<offer_downgrade>;
  /** insert data into the table: "offer_goal" */
  insert_offer_goal?: Maybe<offer_goal_mutation_response>;
  /** insert a single row into the table: "offer_goal" */
  insert_offer_goal_one?: Maybe<offer_goal>;
  /** insert data into the table: "offer_group" */
  insert_offer_group?: Maybe<offer_group_mutation_response>;
  /** insert a single row into the table: "offer_group" */
  insert_offer_group_one?: Maybe<offer_group>;
  /** insert data into the table: "offer_modify_subscription" */
  insert_offer_modify_subscription?: Maybe<offer_modify_subscription_mutation_response>;
  /** insert data into the table: "offer_modify_subscription_modify_at" */
  insert_offer_modify_subscription_modify_at?: Maybe<offer_modify_subscription_modify_at_mutation_response>;
  /** insert a single row into the table: "offer_modify_subscription_modify_at" */
  insert_offer_modify_subscription_modify_at_one?: Maybe<offer_modify_subscription_modify_at>;
  /** insert a single row into the table: "offer_modify_subscription" */
  insert_offer_modify_subscription_one?: Maybe<offer_modify_subscription>;
  /** insert data into the table: "offer_modify_subscription_option" */
  insert_offer_modify_subscription_option?: Maybe<offer_modify_subscription_option_mutation_response>;
  /** insert a single row into the table: "offer_modify_subscription_option" */
  insert_offer_modify_subscription_option_one?: Maybe<offer_modify_subscription_option>;
  /** insert data into the table: "offer_modify_subscription_option_type" */
  insert_offer_modify_subscription_option_type?: Maybe<offer_modify_subscription_option_type_mutation_response>;
  /** insert a single row into the table: "offer_modify_subscription_option_type" */
  insert_offer_modify_subscription_option_type_one?: Maybe<offer_modify_subscription_option_type>;
  /** insert a single row into the table: "offer" */
  insert_offer_one?: Maybe<offer>;
  /** insert data into the table: "offer_pause_subscription" */
  insert_offer_pause_subscription?: Maybe<offer_pause_subscription_mutation_response>;
  /** insert data into the table: "offer_pause_subscription_interval" */
  insert_offer_pause_subscription_interval?: Maybe<offer_pause_subscription_interval_mutation_response>;
  /** insert a single row into the table: "offer_pause_subscription_interval" */
  insert_offer_pause_subscription_interval_one?: Maybe<offer_pause_subscription_interval>;
  /** insert a single row into the table: "offer_pause_subscription" */
  insert_offer_pause_subscription_one?: Maybe<offer_pause_subscription>;
  /** insert data into the table: "offer_pause_subscription_option" */
  insert_offer_pause_subscription_option?: Maybe<offer_pause_subscription_option_mutation_response>;
  /** insert a single row into the table: "offer_pause_subscription_option" */
  insert_offer_pause_subscription_option_one?: Maybe<offer_pause_subscription_option>;
  /** insert data into the table: "offer_pause_subscription_option_type" */
  insert_offer_pause_subscription_option_type?: Maybe<offer_pause_subscription_option_type_mutation_response>;
  /** insert a single row into the table: "offer_pause_subscription_option_type" */
  insert_offer_pause_subscription_option_type_one?: Maybe<offer_pause_subscription_option_type>;
  /** insert data into the table: "offer_pause_subscription_pause_at" */
  insert_offer_pause_subscription_pause_at?: Maybe<offer_pause_subscription_pause_at_mutation_response>;
  /** insert a single row into the table: "offer_pause_subscription_pause_at" */
  insert_offer_pause_subscription_pause_at_one?: Maybe<offer_pause_subscription_pause_at>;
  /** insert data into the table: "offer_product_swap" */
  insert_offer_product_swap?: Maybe<offer_product_swap_mutation_response>;
  /** insert a single row into the table: "offer_product_swap" */
  insert_offer_product_swap_one?: Maybe<offer_product_swap>;
  /** insert data into the table: "offer_product_swap_platform_ecommerce_product" */
  insert_offer_product_swap_platform_ecommerce_product?: Maybe<offer_product_swap_platform_ecommerce_product_mutation_response>;
  /** insert a single row into the table: "offer_product_swap_platform_ecommerce_product" */
  insert_offer_product_swap_platform_ecommerce_product_one?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** insert data into the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  insert_offer_product_swap_platform_ecommerce_product_platform_variant?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response>;
  /** insert a single row into the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  insert_offer_product_swap_platform_ecommerce_product_platform_variant_one?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** insert data into the table: "offer_reschedule_order" */
  insert_offer_reschedule_order?: Maybe<offer_reschedule_order_mutation_response>;
  /** insert a single row into the table: "offer_reschedule_order" */
  insert_offer_reschedule_order_one?: Maybe<offer_reschedule_order>;
  /** insert data into the table: "offer_rule" */
  insert_offer_rule?: Maybe<offer_rule_mutation_response>;
  /** insert data into the table: "offer_rule_group" */
  insert_offer_rule_group?: Maybe<offer_rule_group_mutation_response>;
  /** insert a single row into the table: "offer_rule_group" */
  insert_offer_rule_group_one?: Maybe<offer_rule_group>;
  /** insert data into the table: "offer_rule_group_version" */
  insert_offer_rule_group_version?: Maybe<offer_rule_group_version_mutation_response>;
  /** insert a single row into the table: "offer_rule_group_version" */
  insert_offer_rule_group_version_one?: Maybe<offer_rule_group_version>;
  /** insert a single row into the table: "offer_rule" */
  insert_offer_rule_one?: Maybe<offer_rule>;
  /** insert data into the table: "offer_rule_rule" */
  insert_offer_rule_rule?: Maybe<offer_rule_rule_mutation_response>;
  /** insert a single row into the table: "offer_rule_rule" */
  insert_offer_rule_rule_one?: Maybe<offer_rule_rule>;
  /** insert data into the table: "offer_rule_version" */
  insert_offer_rule_version?: Maybe<offer_rule_version_mutation_response>;
  /** insert a single row into the table: "offer_rule_version" */
  insert_offer_rule_version_one?: Maybe<offer_rule_version>;
  /** insert data into the table: "offer_style" */
  insert_offer_style?: Maybe<offer_style_mutation_response>;
  /** insert a single row into the table: "offer_style" */
  insert_offer_style_one?: Maybe<offer_style>;
  /** insert data into the table: "offer_test" */
  insert_offer_test?: Maybe<offer_test_mutation_response>;
  /** insert data into the table: "offer_test_offer" */
  insert_offer_test_offer?: Maybe<offer_test_offer_mutation_response>;
  /** insert a single row into the table: "offer_test_offer" */
  insert_offer_test_offer_one?: Maybe<offer_test_offer>;
  /** insert a single row into the table: "offer_test" */
  insert_offer_test_one?: Maybe<offer_test>;
  /** insert data into the table: "offer_timer" */
  insert_offer_timer?: Maybe<offer_timer_mutation_response>;
  /** insert data into the table: "offer_timer_mode" */
  insert_offer_timer_mode?: Maybe<offer_timer_mode_mutation_response>;
  /** insert a single row into the table: "offer_timer_mode" */
  insert_offer_timer_mode_one?: Maybe<offer_timer_mode>;
  /** insert a single row into the table: "offer_timer" */
  insert_offer_timer_one?: Maybe<offer_timer>;
  /** insert data into the table: "offer_trial_extension" */
  insert_offer_trial_extension?: Maybe<offer_trial_extension_mutation_response>;
  /** insert a single row into the table: "offer_trial_extension" */
  insert_offer_trial_extension_one?: Maybe<offer_trial_extension>;
  /** insert data into the table: "offer_type" */
  insert_offer_type?: Maybe<offer_type_mutation_response>;
  /** insert a single row into the table: "offer_type" */
  insert_offer_type_one?: Maybe<offer_type>;
  /** insert data into the table: "offer_upgrade" */
  insert_offer_upgrade?: Maybe<offer_upgrade_mutation_response>;
  /** insert a single row into the table: "offer_upgrade" */
  insert_offer_upgrade_one?: Maybe<offer_upgrade>;
  /** insert data into the table: "offer_variant" */
  insert_offer_variant?: Maybe<offer_variant_mutation_response>;
  /** insert data into the table: "offer_variant_coupon" */
  insert_offer_variant_coupon?: Maybe<offer_variant_coupon_mutation_response>;
  /** insert a single row into the table: "offer_variant_coupon" */
  insert_offer_variant_coupon_one?: Maybe<offer_variant_coupon>;
  /** insert a single row into the table: "offer_variant" */
  insert_offer_variant_one?: Maybe<offer_variant>;
  /** insert data into the table: "platform" */
  insert_platform?: Maybe<platform_mutation_response>;
  /** insert data into the table: "platform_addon" */
  insert_platform_addon?: Maybe<platform_addon_mutation_response>;
  /** insert a single row into the table: "platform_addon" */
  insert_platform_addon_one?: Maybe<platform_addon>;
  /** insert data into the table: "platform_addon_plan" */
  insert_platform_addon_plan?: Maybe<platform_addon_plan_mutation_response>;
  /** insert a single row into the table: "platform_addon_plan" */
  insert_platform_addon_plan_one?: Maybe<platform_addon_plan>;
  /** insert data into the table: "platform_addon_price" */
  insert_platform_addon_price?: Maybe<platform_addon_price_mutation_response>;
  /** insert a single row into the table: "platform_addon_price" */
  insert_platform_addon_price_one?: Maybe<platform_addon_price>;
  /** insert data into the table: "platform_addon_price_tier" */
  insert_platform_addon_price_tier?: Maybe<platform_addon_price_tier_mutation_response>;
  /** insert a single row into the table: "platform_addon_price_tier" */
  insert_platform_addon_price_tier_one?: Maybe<platform_addon_price_tier>;
  /** insert data into the table: "platform_api_log" */
  insert_platform_api_log?: Maybe<platform_api_log_mutation_response>;
  /** insert a single row into the table: "platform_api_log" */
  insert_platform_api_log_one?: Maybe<platform_api_log>;
  /** insert data into the table: "platform_base_currency" */
  insert_platform_base_currency?: Maybe<platform_base_currency_mutation_response>;
  /** insert a single row into the table: "platform_base_currency" */
  insert_platform_base_currency_one?: Maybe<platform_base_currency>;
  /** insert data into the table: "platform_cancel_reason" */
  insert_platform_cancel_reason?: Maybe<platform_cancel_reason_mutation_response>;
  /** insert a single row into the table: "platform_cancel_reason" */
  insert_platform_cancel_reason_one?: Maybe<platform_cancel_reason>;
  /** insert data into the table: "platform_connection" */
  insert_platform_connection?: Maybe<platform_connection_mutation_response>;
  /** insert data into the table: "platform_connection_naviga_domain" */
  insert_platform_connection_naviga_domain?: Maybe<platform_connection_naviga_domain_mutation_response>;
  /** insert a single row into the table: "platform_connection_naviga_domain" */
  insert_platform_connection_naviga_domain_one?: Maybe<platform_connection_naviga_domain>;
  /** insert data into the table: "platform_connection_naviga_paper_code_endpoint" */
  insert_platform_connection_naviga_paper_code_endpoint?: Maybe<platform_connection_naviga_paper_code_endpoint_mutation_response>;
  /** insert a single row into the table: "platform_connection_naviga_paper_code_endpoint" */
  insert_platform_connection_naviga_paper_code_endpoint_one?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** insert a single row into the table: "platform_connection" */
  insert_platform_connection_one?: Maybe<platform_connection>;
  /** insert data into the table: "platform_connection_shopify_store" */
  insert_platform_connection_shopify_store?: Maybe<platform_connection_shopify_store_mutation_response>;
  /** insert a single row into the table: "platform_connection_shopify_store" */
  insert_platform_connection_shopify_store_one?: Maybe<platform_connection_shopify_store>;
  /** insert data into the table: "platform_coupon" */
  insert_platform_coupon?: Maybe<platform_coupon_mutation_response>;
  /** insert data into the table: "platform_coupon_addon" */
  insert_platform_coupon_addon?: Maybe<platform_coupon_addon_mutation_response>;
  /** insert a single row into the table: "platform_coupon_addon" */
  insert_platform_coupon_addon_one?: Maybe<platform_coupon_addon>;
  /** insert data into the table: "platform_coupon_apply_to" */
  insert_platform_coupon_apply_to?: Maybe<platform_coupon_apply_to_mutation_response>;
  /** insert a single row into the table: "platform_coupon_apply_to" */
  insert_platform_coupon_apply_to_one?: Maybe<platform_coupon_apply_to>;
  /** insert data into the table: "platform_coupon_apply_to_restriction" */
  insert_platform_coupon_apply_to_restriction?: Maybe<platform_coupon_apply_to_restriction_mutation_response>;
  /** insert a single row into the table: "platform_coupon_apply_to_restriction" */
  insert_platform_coupon_apply_to_restriction_one?: Maybe<platform_coupon_apply_to_restriction>;
  /** insert data into the table: "platform_coupon_duration" */
  insert_platform_coupon_duration?: Maybe<platform_coupon_duration_mutation_response>;
  /** insert data into the table: "platform_coupon_duration_interval" */
  insert_platform_coupon_duration_interval?: Maybe<platform_coupon_duration_interval_mutation_response>;
  /** insert a single row into the table: "platform_coupon_duration_interval" */
  insert_platform_coupon_duration_interval_one?: Maybe<platform_coupon_duration_interval>;
  /** insert a single row into the table: "platform_coupon_duration" */
  insert_platform_coupon_duration_one?: Maybe<platform_coupon_duration>;
  /** insert a single row into the table: "platform_coupon" */
  insert_platform_coupon_one?: Maybe<platform_coupon>;
  /** insert data into the table: "platform_coupon_plan" */
  insert_platform_coupon_plan?: Maybe<platform_coupon_plan_mutation_response>;
  /** insert a single row into the table: "platform_coupon_plan" */
  insert_platform_coupon_plan_one?: Maybe<platform_coupon_plan>;
  /** insert data into the table: "platform_coupon_product" */
  insert_platform_coupon_product?: Maybe<platform_coupon_product_mutation_response>;
  /** insert data into the table: "platform_coupon_product_group" */
  insert_platform_coupon_product_group?: Maybe<platform_coupon_product_group_mutation_response>;
  /** insert a single row into the table: "platform_coupon_product_group" */
  insert_platform_coupon_product_group_one?: Maybe<platform_coupon_product_group>;
  /** insert a single row into the table: "platform_coupon_product" */
  insert_platform_coupon_product_one?: Maybe<platform_coupon_product>;
  /** insert data into the table: "platform_coupon_type" */
  insert_platform_coupon_type?: Maybe<platform_coupon_type_mutation_response>;
  /** insert a single row into the table: "platform_coupon_type" */
  insert_platform_coupon_type_one?: Maybe<platform_coupon_type>;
  /** insert data into the table: "platform_credentials" */
  insert_platform_credentials?: Maybe<platform_credentials_mutation_response>;
  /** insert a single row into the table: "platform_credentials" */
  insert_platform_credentials_one?: Maybe<platform_credentials>;
  /** insert data into the table: "platform_currency" */
  insert_platform_currency?: Maybe<platform_currency_mutation_response>;
  /** insert data into the table: "platform_currency_exchange_rate" */
  insert_platform_currency_exchange_rate?: Maybe<platform_currency_exchange_rate_mutation_response>;
  /** insert a single row into the table: "platform_currency_exchange_rate" */
  insert_platform_currency_exchange_rate_one?: Maybe<platform_currency_exchange_rate>;
  /** insert a single row into the table: "platform_currency" */
  insert_platform_currency_one?: Maybe<platform_currency>;
  /** insert data into the table: "platform_customer" */
  insert_platform_customer?: Maybe<platform_customer_mutation_response>;
  /** insert a single row into the table: "platform_customer" */
  insert_platform_customer_one?: Maybe<platform_customer>;
  /** insert data into the table: "platform_ecommerce_product" */
  insert_platform_ecommerce_product?: Maybe<platform_ecommerce_product_mutation_response>;
  /** insert a single row into the table: "platform_ecommerce_product" */
  insert_platform_ecommerce_product_one?: Maybe<platform_ecommerce_product>;
  /** insert data into the table: "platform_import" */
  insert_platform_import?: Maybe<platform_import_mutation_response>;
  /** insert a single row into the table: "platform_import" */
  insert_platform_import_one?: Maybe<platform_import>;
  /** insert data into the table: "platform_import_status" */
  insert_platform_import_status?: Maybe<platform_import_status_mutation_response>;
  /** insert a single row into the table: "platform_import_status" */
  insert_platform_import_status_one?: Maybe<platform_import_status>;
  /** insert data into the table: "platform_import_step" */
  insert_platform_import_step?: Maybe<platform_import_step_mutation_response>;
  /** insert a single row into the table: "platform_import_step" */
  insert_platform_import_step_one?: Maybe<platform_import_step>;
  /** insert data into the table: "platform_import_step_type" */
  insert_platform_import_step_type?: Maybe<platform_import_step_type_mutation_response>;
  /** insert a single row into the table: "platform_import_step_type" */
  insert_platform_import_step_type_one?: Maybe<platform_import_step_type>;
  /** insert data into the table: "platform_invoice" */
  insert_platform_invoice?: Maybe<platform_invoice_mutation_response>;
  /** insert a single row into the table: "platform_invoice" */
  insert_platform_invoice_one?: Maybe<platform_invoice>;
  /** insert data into the table: "platform_invoice_status" */
  insert_platform_invoice_status?: Maybe<platform_invoice_status_mutation_response>;
  /** insert a single row into the table: "platform_invoice_status" */
  insert_platform_invoice_status_one?: Maybe<platform_invoice_status>;
  /** insert data into the table: "platform_newspaper_rate_code" */
  insert_platform_newspaper_rate_code?: Maybe<platform_newspaper_rate_code_mutation_response>;
  /** insert a single row into the table: "platform_newspaper_rate_code" */
  insert_platform_newspaper_rate_code_one?: Maybe<platform_newspaper_rate_code>;
  /** insert data into the table: "platform_newspaper_subscription" */
  insert_platform_newspaper_subscription?: Maybe<platform_newspaper_subscription_mutation_response>;
  /** insert a single row into the table: "platform_newspaper_subscription" */
  insert_platform_newspaper_subscription_one?: Maybe<platform_newspaper_subscription>;
  /** insert data into the table: "platform_newspaper_subscription_stop_date" */
  insert_platform_newspaper_subscription_stop_date?: Maybe<platform_newspaper_subscription_stop_date_mutation_response>;
  /** insert a single row into the table: "platform_newspaper_subscription_stop_date" */
  insert_platform_newspaper_subscription_stop_date_one?: Maybe<platform_newspaper_subscription_stop_date>;
  /** insert a single row into the table: "platform" */
  insert_platform_one?: Maybe<platform>;
  /** insert data into the table: "platform_plan" */
  insert_platform_plan?: Maybe<platform_plan_mutation_response>;
  /** insert data into the table: "platform_plan_ecommerce_product" */
  insert_platform_plan_ecommerce_product?: Maybe<platform_plan_ecommerce_product_mutation_response>;
  /** insert a single row into the table: "platform_plan_ecommerce_product" */
  insert_platform_plan_ecommerce_product_one?: Maybe<platform_plan_ecommerce_product>;
  /** insert data into the table: "platform_plan_group" */
  insert_platform_plan_group?: Maybe<platform_plan_group_mutation_response>;
  /** insert a single row into the table: "platform_plan_group" */
  insert_platform_plan_group_one?: Maybe<platform_plan_group>;
  /** insert data into the table: "platform_plan_interval" */
  insert_platform_plan_interval?: Maybe<platform_plan_interval_mutation_response>;
  /** insert a single row into the table: "platform_plan_interval" */
  insert_platform_plan_interval_one?: Maybe<platform_plan_interval>;
  /** insert a single row into the table: "platform_plan" */
  insert_platform_plan_one?: Maybe<platform_plan>;
  /** insert data into the table: "platform_plan_pricing_model" */
  insert_platform_plan_pricing_model?: Maybe<platform_plan_pricing_model_mutation_response>;
  /** insert a single row into the table: "platform_plan_pricing_model" */
  insert_platform_plan_pricing_model_one?: Maybe<platform_plan_pricing_model>;
  /** insert data into the table: "platform_plan_tier" */
  insert_platform_plan_tier?: Maybe<platform_plan_tier_mutation_response>;
  /** insert a single row into the table: "platform_plan_tier" */
  insert_platform_plan_tier_one?: Maybe<platform_plan_tier>;
  /** insert data into the table: "platform_product" */
  insert_platform_product?: Maybe<platform_product_mutation_response>;
  /** insert data into the table: "platform_product_group" */
  insert_platform_product_group?: Maybe<platform_product_group_mutation_response>;
  /** insert a single row into the table: "platform_product_group" */
  insert_platform_product_group_one?: Maybe<platform_product_group>;
  /** insert a single row into the table: "platform_product" */
  insert_platform_product_one?: Maybe<platform_product>;
  /** insert data into the table: "platform_raw_object" */
  insert_platform_raw_object?: Maybe<platform_raw_object_mutation_response>;
  /** insert a single row into the table: "platform_raw_object" */
  insert_platform_raw_object_one?: Maybe<platform_raw_object>;
  /** insert data into the table: "platform_raw_object_type" */
  insert_platform_raw_object_type?: Maybe<platform_raw_object_type_mutation_response>;
  /** insert a single row into the table: "platform_raw_object_type" */
  insert_platform_raw_object_type_one?: Maybe<platform_raw_object_type>;
  /** insert data into the table: "platform_refund" */
  insert_platform_refund?: Maybe<platform_refund_mutation_response>;
  /** insert a single row into the table: "platform_refund" */
  insert_platform_refund_one?: Maybe<platform_refund>;
  /** insert data into the table: "platform_subscription" */
  insert_platform_subscription?: Maybe<platform_subscription_mutation_response>;
  /** insert data into the table: "platform_subscription_addon" */
  insert_platform_subscription_addon?: Maybe<platform_subscription_addon_mutation_response>;
  /** insert a single row into the table: "platform_subscription_addon" */
  insert_platform_subscription_addon_one?: Maybe<platform_subscription_addon>;
  /** insert data into the table: "platform_subscription_next_billing_at" */
  insert_platform_subscription_next_billing_at?: Maybe<platform_subscription_next_billing_at_mutation_response>;
  /** insert a single row into the table: "platform_subscription_next_billing_at" */
  insert_platform_subscription_next_billing_at_one?: Maybe<platform_subscription_next_billing_at>;
  /** insert a single row into the table: "platform_subscription" */
  insert_platform_subscription_one?: Maybe<platform_subscription>;
  /** insert data into the table: "platform_subscription_plan" */
  insert_platform_subscription_plan?: Maybe<platform_subscription_plan_mutation_response>;
  /** insert a single row into the table: "platform_subscription_plan" */
  insert_platform_subscription_plan_one?: Maybe<platform_subscription_plan>;
  /** insert data into the table: "platform_subscription_plan_swappable_ecommerce_product" */
  insert_platform_subscription_plan_swappable_ecommerce_product?: Maybe<platform_subscription_plan_swappable_ecommerce_product_mutation_response>;
  /** insert a single row into the table: "platform_subscription_plan_swappable_ecommerce_product" */
  insert_platform_subscription_plan_swappable_ecommerce_product_one?: Maybe<platform_subscription_plan_swappable_ecommerce_product>;
  /** insert data into the table: "platform_subscription_status" */
  insert_platform_subscription_status?: Maybe<platform_subscription_status_mutation_response>;
  /** insert a single row into the table: "platform_subscription_status" */
  insert_platform_subscription_status_one?: Maybe<platform_subscription_status>;
  /** insert data into the table: "platform_variant" */
  insert_platform_variant?: Maybe<platform_variant_mutation_response>;
  /** insert a single row into the table: "platform_variant" */
  insert_platform_variant_one?: Maybe<platform_variant>;
  /** insert data into the table: "platform_variant_option" */
  insert_platform_variant_option?: Maybe<platform_variant_option_mutation_response>;
  /** insert a single row into the table: "platform_variant_option" */
  insert_platform_variant_option_one?: Maybe<platform_variant_option>;
  /** insert data into the table: "portal_email_code" */
  insert_portal_email_code?: Maybe<portal_email_code_mutation_response>;
  /** insert a single row into the table: "portal_email_code" */
  insert_portal_email_code_one?: Maybe<portal_email_code>;
  /** insert data into the table: "portal_session" */
  insert_portal_session?: Maybe<portal_session_mutation_response>;
  /** insert a single row into the table: "portal_session" */
  insert_portal_session_one?: Maybe<portal_session>;
  /** insert data into the table: "portal_session_subscriber" */
  insert_portal_session_subscriber?: Maybe<portal_session_subscriber_mutation_response>;
  /** insert a single row into the table: "portal_session_subscriber" */
  insert_portal_session_subscriber_one?: Maybe<portal_session_subscriber>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<product_mutation_response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<product>;
  /** insert data into the table: "property" */
  insert_property?: Maybe<property_mutation_response>;
  /** insert data into the table: "property_entity" */
  insert_property_entity?: Maybe<property_entity_mutation_response>;
  /** insert a single row into the table: "property_entity" */
  insert_property_entity_one?: Maybe<property_entity>;
  /** insert data into the table: "property_format" */
  insert_property_format?: Maybe<property_format_mutation_response>;
  /** insert a single row into the table: "property_format" */
  insert_property_format_one?: Maybe<property_format>;
  /** insert a single row into the table: "property" */
  insert_property_one?: Maybe<property>;
  /** insert data into the table: "property_type" */
  insert_property_type?: Maybe<property_type_mutation_response>;
  /** insert a single row into the table: "property_type" */
  insert_property_type_one?: Maybe<property_type>;
  /** insert data into the table: "question" */
  insert_question?: Maybe<question_mutation_response>;
  /** insert data into the table: "question_answer" */
  insert_question_answer?: Maybe<question_answer_mutation_response>;
  /** insert data into the table: "question_answer_likelihood_to_return" */
  insert_question_answer_likelihood_to_return?: Maybe<question_answer_likelihood_to_return_mutation_response>;
  /** insert a single row into the table: "question_answer_likelihood_to_return" */
  insert_question_answer_likelihood_to_return_one?: Maybe<question_answer_likelihood_to_return>;
  /** insert a single row into the table: "question_answer" */
  insert_question_answer_one?: Maybe<question_answer>;
  /** insert data into the table: "question_answer_radio" */
  insert_question_answer_radio?: Maybe<question_answer_radio_mutation_response>;
  /** insert a single row into the table: "question_answer_radio" */
  insert_question_answer_radio_one?: Maybe<question_answer_radio>;
  /** insert data into the table: "question_answer_sentiment_phrase" */
  insert_question_answer_sentiment_phrase?: Maybe<question_answer_sentiment_phrase_mutation_response>;
  /** insert a single row into the table: "question_answer_sentiment_phrase" */
  insert_question_answer_sentiment_phrase_one?: Maybe<question_answer_sentiment_phrase>;
  /** insert data into the table: "question_answer_text" */
  insert_question_answer_text?: Maybe<question_answer_text_mutation_response>;
  /** insert a single row into the table: "question_answer_text" */
  insert_question_answer_text_one?: Maybe<question_answer_text>;
  /** insert a single row into the table: "question" */
  insert_question_one?: Maybe<question>;
  /** insert data into the table: "question_option" */
  insert_question_option?: Maybe<question_option_mutation_response>;
  /** insert a single row into the table: "question_option" */
  insert_question_option_one?: Maybe<question_option>;
  /** insert data into the table: "question_option_version" */
  insert_question_option_version?: Maybe<question_option_version_mutation_response>;
  /** insert a single row into the table: "question_option_version" */
  insert_question_option_version_one?: Maybe<question_option_version>;
  /** insert data into the table: "question_setting" */
  insert_question_setting?: Maybe<question_setting_mutation_response>;
  /** insert data into the table: "question_setting_key" */
  insert_question_setting_key?: Maybe<question_setting_key_mutation_response>;
  /** insert a single row into the table: "question_setting_key" */
  insert_question_setting_key_one?: Maybe<question_setting_key>;
  /** insert a single row into the table: "question_setting" */
  insert_question_setting_one?: Maybe<question_setting>;
  /** insert data into the table: "question_setting_version" */
  insert_question_setting_version?: Maybe<question_setting_version_mutation_response>;
  /** insert a single row into the table: "question_setting_version" */
  insert_question_setting_version_one?: Maybe<question_setting_version>;
  /** insert data into the table: "question_type" */
  insert_question_type?: Maybe<question_type_mutation_response>;
  /** insert a single row into the table: "question_type" */
  insert_question_type_one?: Maybe<question_type>;
  /** insert data into the table: "question_version" */
  insert_question_version?: Maybe<question_version_mutation_response>;
  /** insert a single row into the table: "question_version" */
  insert_question_version_one?: Maybe<question_version>;
  /** insert data into the table: "report_recurring_revenue" */
  insert_report_recurring_revenue?: Maybe<report_recurring_revenue_mutation_response>;
  /** insert a single row into the table: "report_recurring_revenue" */
  insert_report_recurring_revenue_one?: Maybe<report_recurring_revenue>;
  /** insert data into the table: "revenue_log" */
  insert_revenue_log?: Maybe<revenue_log_mutation_response>;
  /** insert a single row into the table: "revenue_log" */
  insert_revenue_log_one?: Maybe<revenue_log>;
  /** insert data into the table: "revenue_log_revenue_type" */
  insert_revenue_log_revenue_type?: Maybe<revenue_log_revenue_type_mutation_response>;
  /** insert a single row into the table: "revenue_log_revenue_type" */
  insert_revenue_log_revenue_type_one?: Maybe<revenue_log_revenue_type>;
  /** insert data into the table: "revenue_log_type" */
  insert_revenue_log_type?: Maybe<revenue_log_type_mutation_response>;
  /** insert a single row into the table: "revenue_log_type" */
  insert_revenue_log_type_one?: Maybe<revenue_log_type>;
  /** insert data into the table: "rule_group_mode" */
  insert_rule_group_mode?: Maybe<rule_group_mode_mutation_response>;
  /** insert a single row into the table: "rule_group_mode" */
  insert_rule_group_mode_one?: Maybe<rule_group_mode>;
  /** insert data into the table: "script_log" */
  insert_script_log?: Maybe<script_log_mutation_response>;
  /** insert a single row into the table: "script_log" */
  insert_script_log_one?: Maybe<script_log>;
  /** insert data into the table: "segment" */
  insert_segment?: Maybe<segment_mutation_response>;
  /** insert data into the table: "segment_condition" */
  insert_segment_condition?: Maybe<segment_condition_mutation_response>;
  /** insert data into the table: "segment_condition_boolean_operator" */
  insert_segment_condition_boolean_operator?: Maybe<segment_condition_boolean_operator_mutation_response>;
  /** insert a single row into the table: "segment_condition_boolean_operator" */
  insert_segment_condition_boolean_operator_one?: Maybe<segment_condition_boolean_operator>;
  /** insert data into the table: "segment_condition_group" */
  insert_segment_condition_group?: Maybe<segment_condition_group_mutation_response>;
  /** insert data into the table: "segment_condition_group_entry" */
  insert_segment_condition_group_entry?: Maybe<segment_condition_group_entry_mutation_response>;
  /** insert a single row into the table: "segment_condition_group_entry" */
  insert_segment_condition_group_entry_one?: Maybe<segment_condition_group_entry>;
  /** insert a single row into the table: "segment_condition_group" */
  insert_segment_condition_group_one?: Maybe<segment_condition_group>;
  /** insert a single row into the table: "segment_condition" */
  insert_segment_condition_one?: Maybe<segment_condition>;
  /** insert data into the table: "segment_condition_operator" */
  insert_segment_condition_operator?: Maybe<segment_condition_operator_mutation_response>;
  /** insert a single row into the table: "segment_condition_operator" */
  insert_segment_condition_operator_one?: Maybe<segment_condition_operator>;
  /** insert data into the table: "segment_condition_property" */
  insert_segment_condition_property?: Maybe<segment_condition_property_mutation_response>;
  /** insert a single row into the table: "segment_condition_property" */
  insert_segment_condition_property_one?: Maybe<segment_condition_property>;
  /** insert data into the table: "segment_group" */
  insert_segment_group?: Maybe<segment_group_mutation_response>;
  /** insert a single row into the table: "segment_group" */
  insert_segment_group_one?: Maybe<segment_group>;
  /** insert data into the table: "segment_group_operator" */
  insert_segment_group_operator?: Maybe<segment_group_operator_mutation_response>;
  /** insert a single row into the table: "segment_group_operator" */
  insert_segment_group_operator_one?: Maybe<segment_group_operator>;
  /** insert data into the table: "segment_group_segment" */
  insert_segment_group_segment?: Maybe<segment_group_segment_mutation_response>;
  /** insert a single row into the table: "segment_group_segment" */
  insert_segment_group_segment_one?: Maybe<segment_group_segment>;
  /** insert a single row into the table: "segment" */
  insert_segment_one?: Maybe<segment>;
  /** insert data into the table: "segment_subscription_cache" */
  insert_segment_subscription_cache?: Maybe<segment_subscription_cache_mutation_response>;
  /** insert a single row into the table: "segment_subscription_cache" */
  insert_segment_subscription_cache_one?: Maybe<segment_subscription_cache>;
  /** insert data into the table: "sentiment" */
  insert_sentiment?: Maybe<sentiment_mutation_response>;
  /** insert a single row into the table: "sentiment" */
  insert_sentiment_one?: Maybe<sentiment>;
  /** insert data into the table: "sentiment_phrase" */
  insert_sentiment_phrase?: Maybe<sentiment_phrase_mutation_response>;
  /** insert data into the table: "sentiment_phrase_normalized" */
  insert_sentiment_phrase_normalized?: Maybe<sentiment_phrase_normalized_mutation_response>;
  /** insert a single row into the table: "sentiment_phrase_normalized" */
  insert_sentiment_phrase_normalized_one?: Maybe<sentiment_phrase_normalized>;
  /** insert a single row into the table: "sentiment_phrase" */
  insert_sentiment_phrase_one?: Maybe<sentiment_phrase>;
  /** insert data into the table: "state" */
  insert_state?: Maybe<state_mutation_response>;
  /** insert data into the table: "state_name" */
  insert_state_name?: Maybe<state_name_mutation_response>;
  /** insert a single row into the table: "state_name" */
  insert_state_name_one?: Maybe<state_name>;
  /** insert a single row into the table: "state" */
  insert_state_one?: Maybe<state>;
  /** insert data into the table: "stripe_app" */
  insert_stripe_app?: Maybe<stripe_app_mutation_response>;
  /** insert a single row into the table: "stripe_app" */
  insert_stripe_app_one?: Maybe<stripe_app>;
  /** insert data into the table: "subscriber" */
  insert_subscriber?: Maybe<subscriber_mutation_response>;
  /** insert data into the table: "subscriber_acknowledgement" */
  insert_subscriber_acknowledgement?: Maybe<subscriber_acknowledgement_mutation_response>;
  /** insert a single row into the table: "subscriber_acknowledgement" */
  insert_subscriber_acknowledgement_one?: Maybe<subscriber_acknowledgement>;
  /** insert data into the table: "subscriber_arr" */
  insert_subscriber_arr?: Maybe<subscriber_arr_mutation_response>;
  /** insert a single row into the table: "subscriber_arr" */
  insert_subscriber_arr_one?: Maybe<subscriber_arr>;
  /** insert data into the table: "subscriber_campaign" */
  insert_subscriber_campaign?: Maybe<subscriber_campaign_mutation_response>;
  /** insert data into the table: "subscriber_campaign_offer" */
  insert_subscriber_campaign_offer?: Maybe<subscriber_campaign_offer_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_offer" */
  insert_subscriber_campaign_offer_one?: Maybe<subscriber_campaign_offer>;
  /** insert data into the table: "subscriber_campaign_offer_rule" */
  insert_subscriber_campaign_offer_rule?: Maybe<subscriber_campaign_offer_rule_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_offer_rule" */
  insert_subscriber_campaign_offer_rule_one?: Maybe<subscriber_campaign_offer_rule>;
  /** insert a single row into the table: "subscriber_campaign" */
  insert_subscriber_campaign_one?: Maybe<subscriber_campaign>;
  /** insert data into the table: "subscriber_campaign_override" */
  insert_subscriber_campaign_override?: Maybe<subscriber_campaign_override_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_override" */
  insert_subscriber_campaign_override_one?: Maybe<subscriber_campaign_override>;
  /** insert data into the table: "subscriber_campaign_segment" */
  insert_subscriber_campaign_segment?: Maybe<subscriber_campaign_segment_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_segment" */
  insert_subscriber_campaign_segment_one?: Maybe<subscriber_campaign_segment>;
  /** insert data into the table: "subscriber_campaign_status" */
  insert_subscriber_campaign_status?: Maybe<subscriber_campaign_status_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_status" */
  insert_subscriber_campaign_status_one?: Maybe<subscriber_campaign_status>;
  /** insert data into the table: "subscriber_campaign_view" */
  insert_subscriber_campaign_view?: Maybe<subscriber_campaign_view_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_view" */
  insert_subscriber_campaign_view_one?: Maybe<subscriber_campaign_view>;
  /** insert data into the table: "subscriber_campaign_view_status" */
  insert_subscriber_campaign_view_status?: Maybe<subscriber_campaign_view_status_mutation_response>;
  /** insert a single row into the table: "subscriber_campaign_view_status" */
  insert_subscriber_campaign_view_status_one?: Maybe<subscriber_campaign_view_status>;
  /** insert data into the table: "subscriber_flow" */
  insert_subscriber_flow?: Maybe<subscriber_flow_mutation_response>;
  /** insert data into the table: "subscriber_flow_deflection" */
  insert_subscriber_flow_deflection?: Maybe<subscriber_flow_deflection_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_deflection" */
  insert_subscriber_flow_deflection_one?: Maybe<subscriber_flow_deflection>;
  /** insert data into the table: "subscriber_flow_export" */
  insert_subscriber_flow_export?: Maybe<subscriber_flow_export_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_export" */
  insert_subscriber_flow_export_one?: Maybe<subscriber_flow_export>;
  /** insert data into the table: "subscriber_flow_flow_step" */
  insert_subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_flow_step" */
  insert_subscriber_flow_flow_step_one?: Maybe<subscriber_flow_flow_step>;
  /** insert data into the table: "subscriber_flow_offer" */
  insert_subscriber_flow_offer?: Maybe<subscriber_flow_offer_mutation_response>;
  /** insert data into the table: "subscriber_flow_offer_group" */
  insert_subscriber_flow_offer_group?: Maybe<subscriber_flow_offer_group_mutation_response>;
  /** insert data into the table: "subscriber_flow_offer_group_offer" */
  insert_subscriber_flow_offer_group_offer?: Maybe<subscriber_flow_offer_group_offer_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_offer_group_offer" */
  insert_subscriber_flow_offer_group_offer_one?: Maybe<subscriber_flow_offer_group_offer>;
  /** insert a single row into the table: "subscriber_flow_offer_group" */
  insert_subscriber_flow_offer_group_one?: Maybe<subscriber_flow_offer_group>;
  /** insert a single row into the table: "subscriber_flow_offer" */
  insert_subscriber_flow_offer_one?: Maybe<subscriber_flow_offer>;
  /** insert data into the table: "subscriber_flow_offer_rule" */
  insert_subscriber_flow_offer_rule?: Maybe<subscriber_flow_offer_rule_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_offer_rule" */
  insert_subscriber_flow_offer_rule_one?: Maybe<subscriber_flow_offer_rule>;
  /** insert data into the table: "subscriber_flow_offer_status" */
  insert_subscriber_flow_offer_status?: Maybe<subscriber_flow_offer_status_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_offer_status" */
  insert_subscriber_flow_offer_status_one?: Maybe<subscriber_flow_offer_status>;
  /** insert data into the table: "subscriber_flow_on_cancel_override" */
  insert_subscriber_flow_on_cancel_override?: Maybe<subscriber_flow_on_cancel_override_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_on_cancel_override" */
  insert_subscriber_flow_on_cancel_override_one?: Maybe<subscriber_flow_on_cancel_override>;
  /** insert a single row into the table: "subscriber_flow" */
  insert_subscriber_flow_one?: Maybe<subscriber_flow>;
  /** insert data into the table: "subscriber_flow_override" */
  insert_subscriber_flow_override?: Maybe<subscriber_flow_override_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_override" */
  insert_subscriber_flow_override_one?: Maybe<subscriber_flow_override>;
  /** insert data into the table: "subscriber_flow_override_reason" */
  insert_subscriber_flow_override_reason?: Maybe<subscriber_flow_override_reason_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_override_reason" */
  insert_subscriber_flow_override_reason_one?: Maybe<subscriber_flow_override_reason>;
  /** insert data into the table: "subscriber_flow_question_answer" */
  insert_subscriber_flow_question_answer?: Maybe<subscriber_flow_question_answer_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_question_answer" */
  insert_subscriber_flow_question_answer_one?: Maybe<subscriber_flow_question_answer>;
  /** insert data into the table: "subscriber_flow_segment" */
  insert_subscriber_flow_segment?: Maybe<subscriber_flow_segment_mutation_response>;
  /** insert data into the table: "subscriber_flow_segment_group" */
  insert_subscriber_flow_segment_group?: Maybe<subscriber_flow_segment_group_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_segment_group" */
  insert_subscriber_flow_segment_group_one?: Maybe<subscriber_flow_segment_group>;
  /** insert a single row into the table: "subscriber_flow_segment" */
  insert_subscriber_flow_segment_one?: Maybe<subscriber_flow_segment>;
  /** insert data into the table: "subscriber_flow_status" */
  insert_subscriber_flow_status?: Maybe<subscriber_flow_status_mutation_response>;
  /** insert a single row into the table: "subscriber_flow_status" */
  insert_subscriber_flow_status_one?: Maybe<subscriber_flow_status>;
  /** insert data into the table: "subscriber_log" */
  insert_subscriber_log?: Maybe<subscriber_log_mutation_response>;
  /** insert data into the table: "subscriber_log_event" */
  insert_subscriber_log_event?: Maybe<subscriber_log_event_mutation_response>;
  /** insert a single row into the table: "subscriber_log_event" */
  insert_subscriber_log_event_one?: Maybe<subscriber_log_event>;
  /** insert data into the table: "subscriber_log_offer" */
  insert_subscriber_log_offer?: Maybe<subscriber_log_offer_mutation_response>;
  /** insert data into the table: "subscriber_log_offer_group" */
  insert_subscriber_log_offer_group?: Maybe<subscriber_log_offer_group_mutation_response>;
  /** insert a single row into the table: "subscriber_log_offer_group" */
  insert_subscriber_log_offer_group_one?: Maybe<subscriber_log_offer_group>;
  /** insert a single row into the table: "subscriber_log_offer" */
  insert_subscriber_log_offer_one?: Maybe<subscriber_log_offer>;
  /** insert a single row into the table: "subscriber_log" */
  insert_subscriber_log_one?: Maybe<subscriber_log>;
  /** insert data into the table: "subscriber_mrr" */
  insert_subscriber_mrr?: Maybe<subscriber_mrr_mutation_response>;
  /** insert a single row into the table: "subscriber_mrr" */
  insert_subscriber_mrr_one?: Maybe<subscriber_mrr>;
  /** insert a single row into the table: "subscriber" */
  insert_subscriber_one?: Maybe<subscriber>;
  /** insert data into the table: "subscriber_property" */
  insert_subscriber_property?: Maybe<subscriber_property_mutation_response>;
  /** insert a single row into the table: "subscriber_property" */
  insert_subscriber_property_one?: Maybe<subscriber_property>;
  /** insert data into the table: "subscriber_property_value" */
  insert_subscriber_property_value?: Maybe<subscriber_property_value_mutation_response>;
  /** insert a single row into the table: "subscriber_property_value" */
  insert_subscriber_property_value_one?: Maybe<subscriber_property_value>;
  /** insert data into the table: "subscriber_status" */
  insert_subscriber_status?: Maybe<subscriber_status_mutation_response>;
  /** insert a single row into the table: "subscriber_status" */
  insert_subscriber_status_one?: Maybe<subscriber_status>;
  /** insert data into the table: "subscription" */
  insert_subscription?: Maybe<subscription_mutation_response>;
  /** insert data into the table: "subscription_arr" */
  insert_subscription_arr?: Maybe<subscription_arr_mutation_response>;
  /** insert a single row into the table: "subscription_arr" */
  insert_subscription_arr_one?: Maybe<subscription_arr>;
  /** insert a single row into the table: "subscription" */
  insert_subscription_one?: Maybe<subscription>;
  /** insert data into the table: "subscription_product" */
  insert_subscription_product?: Maybe<subscription_product_mutation_response>;
  /** insert a single row into the table: "subscription_product" */
  insert_subscription_product_one?: Maybe<subscription_product>;
  /** insert data into the table: "subscription_property" */
  insert_subscription_property?: Maybe<subscription_property_mutation_response>;
  /** insert a single row into the table: "subscription_property" */
  insert_subscription_property_one?: Maybe<subscription_property>;
  /** insert data into the table: "subscription_property_value" */
  insert_subscription_property_value?: Maybe<subscription_property_value_mutation_response>;
  /** insert a single row into the table: "subscription_property_value" */
  insert_subscription_property_value_one?: Maybe<subscription_property_value>;
  /** insert data into the table: "subscription_segment" */
  insert_subscription_segment?: Maybe<subscription_segment_mutation_response>;
  /** insert a single row into the table: "subscription_segment" */
  insert_subscription_segment_one?: Maybe<subscription_segment>;
  /** insert data into the table: "subscription_segment_status" */
  insert_subscription_segment_status?: Maybe<subscription_segment_status_mutation_response>;
  /** insert a single row into the table: "subscription_segment_status" */
  insert_subscription_segment_status_one?: Maybe<subscription_segment_status>;
  /** insert data into the table: "subscription_status" */
  insert_subscription_status?: Maybe<subscription_status_mutation_response>;
  /** insert a single row into the table: "subscription_status" */
  insert_subscription_status_one?: Maybe<subscription_status>;
  /** insert data into the table: "survey" */
  insert_survey?: Maybe<survey_mutation_response>;
  /** insert a single row into the table: "survey" */
  insert_survey_one?: Maybe<survey>;
  /** insert data into the table: "tag" */
  insert_tag?: Maybe<tag_mutation_response>;
  /** insert a single row into the table: "tag" */
  insert_tag_one?: Maybe<tag>;
  /** insert data into the table: "template_report_deflection_trends" */
  insert_template_report_deflection_trends?: Maybe<template_report_deflection_trends_mutation_response>;
  /** insert a single row into the table: "template_report_deflection_trends" */
  insert_template_report_deflection_trends_one?: Maybe<template_report_deflection_trends>;
  /** insert data into the table: "template_report_feedback_trends" */
  insert_template_report_feedback_trends?: Maybe<template_report_feedback_trends_mutation_response>;
  /** insert a single row into the table: "template_report_feedback_trends" */
  insert_template_report_feedback_trends_one?: Maybe<template_report_feedback_trends>;
  /** insert data into the table: "template_report_offer_accept_rate" */
  insert_template_report_offer_accept_rate?: Maybe<template_report_offer_accept_rate_mutation_response>;
  /** insert a single row into the table: "template_report_offer_accept_rate" */
  insert_template_report_offer_accept_rate_one?: Maybe<template_report_offer_accept_rate>;
  /** insert data into the table: "template_report_offer_group_accept_rate" */
  insert_template_report_offer_group_accept_rate?: Maybe<template_report_offer_group_accept_rate_mutation_response>;
  /** insert a single row into the table: "template_report_offer_group_accept_rate" */
  insert_template_report_offer_group_accept_rate_one?: Maybe<template_report_offer_group_accept_rate>;
  /** insert data into the table: "template_report_offer_group_retained_revenue" */
  insert_template_report_offer_group_retained_revenue?: Maybe<template_report_offer_group_retained_revenue_mutation_response>;
  /** insert a single row into the table: "template_report_offer_group_retained_revenue" */
  insert_template_report_offer_group_retained_revenue_one?: Maybe<template_report_offer_group_retained_revenue>;
  /** insert data into the table: "template_report_offer_rate" */
  insert_template_report_offer_rate?: Maybe<template_report_offer_rate_mutation_response>;
  /** insert a single row into the table: "template_report_offer_rate" */
  insert_template_report_offer_rate_one?: Maybe<template_report_offer_rate>;
  /** insert data into the table: "template_report_offer_resolution" */
  insert_template_report_offer_resolution?: Maybe<template_report_offer_resolution_mutation_response>;
  /** insert a single row into the table: "template_report_offer_resolution" */
  insert_template_report_offer_resolution_one?: Maybe<template_report_offer_resolution>;
  /** insert data into the table: "template_report_offer_retained_revenue" */
  insert_template_report_offer_retained_revenue?: Maybe<template_report_offer_retained_revenue_mutation_response>;
  /** insert a single row into the table: "template_report_offer_retained_revenue" */
  insert_template_report_offer_retained_revenue_one?: Maybe<template_report_offer_retained_revenue>;
  /** insert data into the table: "template_report_question_answer_radio" */
  insert_template_report_question_answer_radio?: Maybe<template_report_question_answer_radio_mutation_response>;
  /** insert data into the table: "template_report_question_answer_radio_inactive_mrr" */
  insert_template_report_question_answer_radio_inactive_mrr?: Maybe<template_report_question_answer_radio_inactive_mrr_mutation_response>;
  /** insert a single row into the table: "template_report_question_answer_radio_inactive_mrr" */
  insert_template_report_question_answer_radio_inactive_mrr_one?: Maybe<template_report_question_answer_radio_inactive_mrr>;
  /** insert a single row into the table: "template_report_question_answer_radio" */
  insert_template_report_question_answer_radio_one?: Maybe<template_report_question_answer_radio>;
  /** insert data into the table: "template_report_question_answer_sentiment" */
  insert_template_report_question_answer_sentiment?: Maybe<template_report_question_answer_sentiment_mutation_response>;
  /** insert a single row into the table: "template_report_question_answer_sentiment" */
  insert_template_report_question_answer_sentiment_one?: Maybe<template_report_question_answer_sentiment>;
  /** insert data into the table: "template_report_retained_revenue" */
  insert_template_report_retained_revenue?: Maybe<template_report_retained_revenue_mutation_response>;
  /** insert a single row into the table: "template_report_retained_revenue" */
  insert_template_report_retained_revenue_one?: Maybe<template_report_retained_revenue>;
  /** insert data into the table: "template_report_retention_trends" */
  insert_template_report_retention_trends?: Maybe<template_report_retention_trends_mutation_response>;
  /** insert a single row into the table: "template_report_retention_trends" */
  insert_template_report_retention_trends_one?: Maybe<template_report_retention_trends>;
  /** insert data into the table: "template_report_subscriber_flow_status" */
  insert_template_report_subscriber_flow_status?: Maybe<template_report_subscriber_flow_status_mutation_response>;
  /** insert a single row into the table: "template_report_subscriber_flow_status" */
  insert_template_report_subscriber_flow_status_one?: Maybe<template_report_subscriber_flow_status>;
  /** insert data into the table: "translation" */
  insert_translation?: Maybe<translation_mutation_response>;
  /** insert a single row into the table: "translation" */
  insert_translation_one?: Maybe<translation>;
  /** insert data into the table: "translation_value" */
  insert_translation_value?: Maybe<translation_value_mutation_response>;
  /** insert data into the table: "translation_value_format" */
  insert_translation_value_format?: Maybe<translation_value_format_mutation_response>;
  /** insert a single row into the table: "translation_value_format" */
  insert_translation_value_format_one?: Maybe<translation_value_format>;
  /** insert a single row into the table: "translation_value" */
  insert_translation_value_one?: Maybe<translation_value>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<user_mutation_response>;
  /** insert data into the table: "user_invitation" */
  insert_user_invitation?: Maybe<user_invitation_mutation_response>;
  /** insert a single row into the table: "user_invitation" */
  insert_user_invitation_one?: Maybe<user_invitation>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<user>;
  /** insert data into the table: "user_profile" */
  insert_user_profile?: Maybe<user_profile_mutation_response>;
  /** insert a single row into the table: "user_profile" */
  insert_user_profile_one?: Maybe<user_profile>;
  /** insert data into the table: "webhook" */
  insert_webhook?: Maybe<webhook_mutation_response>;
  /** insert data into the table: "webhook_event" */
  insert_webhook_event?: Maybe<webhook_event_mutation_response>;
  /** insert a single row into the table: "webhook_event" */
  insert_webhook_event_one?: Maybe<webhook_event>;
  /** insert data into the table: "webhook_log" */
  insert_webhook_log?: Maybe<webhook_log_mutation_response>;
  /** insert a single row into the table: "webhook_log" */
  insert_webhook_log_one?: Maybe<webhook_log>;
  /** insert a single row into the table: "webhook" */
  insert_webhook_one?: Maybe<webhook>;
  /** insert data into the table: "webhook_request_log" */
  insert_webhook_request_log?: Maybe<webhook_request_log_mutation_response>;
  /** insert a single row into the table: "webhook_request_log" */
  insert_webhook_request_log_one?: Maybe<webhook_request_log>;
  /** insert data into the table: "webhook_status" */
  insert_webhook_status?: Maybe<webhook_status_mutation_response>;
  /** insert a single row into the table: "webhook_status" */
  insert_webhook_status_one?: Maybe<webhook_status>;
  inviteUser: InviteUserOutput;
  logOfferGroupPresented: LogOfferGroupPresentedOutput;
  logOfferPresented: LogOfferPresentedOutput;
  loginUser?: Maybe<LoginUserOutput>;
  /** execute VOLATILE function "populate_segment_subscription_cache" which returns "function_log" */
  populate_segment_subscription_cache?: Maybe<function_log>;
  portalDownloadInvoice: PortalDownloadInvoiceOutput;
  publishFlow: PublishFlowOutput;
  /** execute VOLATILE function "refresh_materialized_view" which returns "function_log" */
  refresh_materialized_view?: Maybe<function_log>;
  regenerateOfferVariant: RegenerateOfferVariantOutput;
  removeAccountUser: RemoveAccountUserOutput;
  requestShopifyInstall: RequestShopifyInstallOutput;
  runScript: RunScriptOutput;
  sendEmailVerification: SendEmailVerificationOutput;
  setPortalSessionPaymentMethod: SetPortalSessionPaymentMethodOutput;
  setSubscriberCampaignPaymentMethod: SetSubscriberCampaignPaymentMethodOutput;
  signCustomerPortalFlowPayload: SignCustomerPortalFlowPayloadOutput;
  signFlowPayload: SignFlowPayloadOutput;
  signUp: SignUpOutput;
  skipConnect?: Maybe<SkipConnectOutput>;
  startFlowStep: StartFlowStepOutput;
  startSubscriberCampaignView?: Maybe<StartSubscriberCampaignViewOutput>;
  submitCustomSubscriberDetails: SubmitCustomSubscriberDetailsOutput;
  submitFormAnswers: SubmitFormAnswersOutput;
  submitQuestionAnswer: SubmitQuestionAnswerOutput;
  subscriberCampaign: SubscriberCampaignOutput;
  subscriberCampaignOffer: SubscriberCampaignOfferOutput;
  switchAccount: SwitchAccountOutput;
  switchTestMode: SwitchTestModeOutput;
  trackEvent: TrackEventOutput;
  transparentLogin: TransparentLoginOutput;
  transparentLogout: TransparentLogoutOutput;
  updateChargebeeApiKey: UpdateChargebeeApiKeyOutput;
  updateDeflection: UpdateDeflectionOutput;
  updateMaxioApiKey: UpdateMaxioApiKeyOutput;
  updateOpenPayApiToken: UpdateOpenPayApiTokenOutput;
  updateRechargeApiToken: UpdateRechargeApiTokenOutput;
  updateRecurlyApiKey: UpdateRecurlyApiKeyOutput;
  updateSegmentGroup: UpdateSegmentGroupOutput;
  /** update data of the table: "account" */
  update_account?: Maybe<account_mutation_response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<account>;
  /** update data of the table: "account_feature" */
  update_account_feature?: Maybe<account_feature_mutation_response>;
  /** update single row of the table: "account_feature" */
  update_account_feature_by_pk?: Maybe<account_feature>;
  /** update data of the table: "account_feature_key" */
  update_account_feature_key?: Maybe<account_feature_key_mutation_response>;
  /** update single row of the table: "account_feature_key" */
  update_account_feature_key_by_pk?: Maybe<account_feature_key>;
  /** update multiples rows of table: "account_feature_key" */
  update_account_feature_key_many?: Maybe<Array<Maybe<account_feature_key_mutation_response>>>;
  /** update multiples rows of table: "account_feature" */
  update_account_feature_many?: Maybe<Array<Maybe<account_feature_mutation_response>>>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<account_mutation_response>>>;
  /** update data of the table: "account_mode" */
  update_account_mode?: Maybe<account_mode_mutation_response>;
  /** update single row of the table: "account_mode" */
  update_account_mode_by_pk?: Maybe<account_mode>;
  /** update multiples rows of table: "account_mode" */
  update_account_mode_many?: Maybe<Array<Maybe<account_mode_mutation_response>>>;
  /** update data of the table: "account_revenue_mode" */
  update_account_revenue_mode?: Maybe<account_revenue_mode_mutation_response>;
  /** update single row of the table: "account_revenue_mode" */
  update_account_revenue_mode_by_pk?: Maybe<account_revenue_mode>;
  /** update multiples rows of table: "account_revenue_mode" */
  update_account_revenue_mode_many?: Maybe<Array<Maybe<account_revenue_mode_mutation_response>>>;
  /** update data of the table: "account_setting" */
  update_account_setting?: Maybe<account_setting_mutation_response>;
  /** update single row of the table: "account_setting" */
  update_account_setting_by_pk?: Maybe<account_setting>;
  /** update data of the table: "account_setting_key" */
  update_account_setting_key?: Maybe<account_setting_key_mutation_response>;
  /** update single row of the table: "account_setting_key" */
  update_account_setting_key_by_pk?: Maybe<account_setting_key>;
  /** update multiples rows of table: "account_setting_key" */
  update_account_setting_key_many?: Maybe<Array<Maybe<account_setting_key_mutation_response>>>;
  /** update multiples rows of table: "account_setting" */
  update_account_setting_many?: Maybe<Array<Maybe<account_setting_mutation_response>>>;
  /** update data of the table: "account_status" */
  update_account_status?: Maybe<account_status_mutation_response>;
  /** update single row of the table: "account_status" */
  update_account_status_by_pk?: Maybe<account_status>;
  /** update multiples rows of table: "account_status" */
  update_account_status_many?: Maybe<Array<Maybe<account_status_mutation_response>>>;
  /** update data of the table: "account_user" */
  update_account_user?: Maybe<account_user_mutation_response>;
  /** update single row of the table: "account_user" */
  update_account_user_by_pk?: Maybe<account_user>;
  /** update multiples rows of table: "account_user" */
  update_account_user_many?: Maybe<Array<Maybe<account_user_mutation_response>>>;
  /** update data of the table: "account_user_setting" */
  update_account_user_setting?: Maybe<account_user_setting_mutation_response>;
  /** update single row of the table: "account_user_setting" */
  update_account_user_setting_by_pk?: Maybe<account_user_setting>;
  /** update data of the table: "account_user_setting_key" */
  update_account_user_setting_key?: Maybe<account_user_setting_key_mutation_response>;
  /** update single row of the table: "account_user_setting_key" */
  update_account_user_setting_key_by_pk?: Maybe<account_user_setting_key>;
  /** update multiples rows of table: "account_user_setting_key" */
  update_account_user_setting_key_many?: Maybe<Array<Maybe<account_user_setting_key_mutation_response>>>;
  /** update multiples rows of table: "account_user_setting" */
  update_account_user_setting_many?: Maybe<Array<Maybe<account_user_setting_mutation_response>>>;
  /** update data of the table: "acknowledgement" */
  update_acknowledgement?: Maybe<acknowledgement_mutation_response>;
  /** update single row of the table: "acknowledgement" */
  update_acknowledgement_by_pk?: Maybe<acknowledgement>;
  /** update data of the table: "acknowledgement_group" */
  update_acknowledgement_group?: Maybe<acknowledgement_group_mutation_response>;
  /** update single row of the table: "acknowledgement_group" */
  update_acknowledgement_group_by_pk?: Maybe<acknowledgement_group>;
  /** update multiples rows of table: "acknowledgement_group" */
  update_acknowledgement_group_many?: Maybe<Array<Maybe<acknowledgement_group_mutation_response>>>;
  /** update data of the table: "acknowledgement_group_version" */
  update_acknowledgement_group_version?: Maybe<acknowledgement_group_version_mutation_response>;
  /** update single row of the table: "acknowledgement_group_version" */
  update_acknowledgement_group_version_by_pk?: Maybe<acknowledgement_group_version>;
  /** update multiples rows of table: "acknowledgement_group_version" */
  update_acknowledgement_group_version_many?: Maybe<Array<Maybe<acknowledgement_group_version_mutation_response>>>;
  /** update multiples rows of table: "acknowledgement" */
  update_acknowledgement_many?: Maybe<Array<Maybe<acknowledgement_mutation_response>>>;
  /** update data of the table: "acknowledgement_version" */
  update_acknowledgement_version?: Maybe<acknowledgement_version_mutation_response>;
  /** update single row of the table: "acknowledgement_version" */
  update_acknowledgement_version_by_pk?: Maybe<acknowledgement_version>;
  /** update multiples rows of table: "acknowledgement_version" */
  update_acknowledgement_version_many?: Maybe<Array<Maybe<acknowledgement_version_mutation_response>>>;
  /** update data of the table: "active_portal_session" */
  update_active_portal_session?: Maybe<active_portal_session_mutation_response>;
  /** update multiples rows of table: "active_portal_session" */
  update_active_portal_session_many?: Maybe<Array<Maybe<active_portal_session_mutation_response>>>;
  /** update data of the table: "annotation" */
  update_annotation?: Maybe<annotation_mutation_response>;
  /** update single row of the table: "annotation" */
  update_annotation_by_pk?: Maybe<annotation>;
  /** update data of the table: "annotation_flow_version" */
  update_annotation_flow_version?: Maybe<annotation_flow_version_mutation_response>;
  /** update single row of the table: "annotation_flow_version" */
  update_annotation_flow_version_by_pk?: Maybe<annotation_flow_version>;
  /** update multiples rows of table: "annotation_flow_version" */
  update_annotation_flow_version_many?: Maybe<Array<Maybe<annotation_flow_version_mutation_response>>>;
  /** update multiples rows of table: "annotation" */
  update_annotation_many?: Maybe<Array<Maybe<annotation_mutation_response>>>;
  /** update data of the table: "annotation_type" */
  update_annotation_type?: Maybe<annotation_type_mutation_response>;
  /** update single row of the table: "annotation_type" */
  update_annotation_type_by_pk?: Maybe<annotation_type>;
  /** update multiples rows of table: "annotation_type" */
  update_annotation_type_many?: Maybe<Array<Maybe<annotation_type_mutation_response>>>;
  /** update data of the table: "api_key" */
  update_api_key?: Maybe<api_key_mutation_response>;
  /** update single row of the table: "api_key" */
  update_api_key_by_pk?: Maybe<api_key>;
  /** update multiples rows of table: "api_key" */
  update_api_key_many?: Maybe<Array<Maybe<api_key_mutation_response>>>;
  /** update data of the table: "api_key_purpose" */
  update_api_key_purpose?: Maybe<api_key_purpose_mutation_response>;
  /** update single row of the table: "api_key_purpose" */
  update_api_key_purpose_by_pk?: Maybe<api_key_purpose>;
  /** update multiples rows of table: "api_key_purpose" */
  update_api_key_purpose_many?: Maybe<Array<Maybe<api_key_purpose_mutation_response>>>;
  /** update data of the table: "campaign" */
  update_campaign?: Maybe<campaign_mutation_response>;
  /** update single row of the table: "campaign" */
  update_campaign_by_pk?: Maybe<campaign>;
  /** update multiples rows of table: "campaign" */
  update_campaign_many?: Maybe<Array<Maybe<campaign_mutation_response>>>;
  /** update data of the table: "campaign_text" */
  update_campaign_text?: Maybe<campaign_text_mutation_response>;
  /** update single row of the table: "campaign_text" */
  update_campaign_text_by_pk?: Maybe<campaign_text>;
  /** update multiples rows of table: "campaign_text" */
  update_campaign_text_many?: Maybe<Array<Maybe<campaign_text_mutation_response>>>;
  /** update data of the table: "campaign_text_version" */
  update_campaign_text_version?: Maybe<campaign_text_version_mutation_response>;
  /** update single row of the table: "campaign_text_version" */
  update_campaign_text_version_by_pk?: Maybe<campaign_text_version>;
  /** update multiples rows of table: "campaign_text_version" */
  update_campaign_text_version_many?: Maybe<Array<Maybe<campaign_text_version_mutation_response>>>;
  /** update data of the table: "campaign_trigger_type" */
  update_campaign_trigger_type?: Maybe<campaign_trigger_type_mutation_response>;
  /** update single row of the table: "campaign_trigger_type" */
  update_campaign_trigger_type_by_pk?: Maybe<campaign_trigger_type>;
  /** update multiples rows of table: "campaign_trigger_type" */
  update_campaign_trigger_type_many?: Maybe<Array<Maybe<campaign_trigger_type_mutation_response>>>;
  /** update data of the table: "campaign_version" */
  update_campaign_version?: Maybe<campaign_version_mutation_response>;
  /** update single row of the table: "campaign_version" */
  update_campaign_version_by_pk?: Maybe<campaign_version>;
  /** update multiples rows of table: "campaign_version" */
  update_campaign_version_many?: Maybe<Array<Maybe<campaign_version_mutation_response>>>;
  /** update data of the table: "confirmation" */
  update_confirmation?: Maybe<confirmation_mutation_response>;
  /** update single row of the table: "confirmation" */
  update_confirmation_by_pk?: Maybe<confirmation>;
  /** update multiples rows of table: "confirmation" */
  update_confirmation_many?: Maybe<Array<Maybe<confirmation_mutation_response>>>;
  /** update data of the table: "confirmation_version" */
  update_confirmation_version?: Maybe<confirmation_version_mutation_response>;
  /** update single row of the table: "confirmation_version" */
  update_confirmation_version_by_pk?: Maybe<confirmation_version>;
  /** update multiples rows of table: "confirmation_version" */
  update_confirmation_version_many?: Maybe<Array<Maybe<confirmation_version_mutation_response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<country_mutation_response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<country_mutation_response>>>;
  /** update data of the table: "coupon_duration" */
  update_coupon_duration?: Maybe<coupon_duration_mutation_response>;
  /** update single row of the table: "coupon_duration" */
  update_coupon_duration_by_pk?: Maybe<coupon_duration>;
  /** update data of the table: "coupon_duration_interval" */
  update_coupon_duration_interval?: Maybe<coupon_duration_interval_mutation_response>;
  /** update single row of the table: "coupon_duration_interval" */
  update_coupon_duration_interval_by_pk?: Maybe<coupon_duration_interval>;
  /** update multiples rows of table: "coupon_duration_interval" */
  update_coupon_duration_interval_many?: Maybe<Array<Maybe<coupon_duration_interval_mutation_response>>>;
  /** update multiples rows of table: "coupon_duration" */
  update_coupon_duration_many?: Maybe<Array<Maybe<coupon_duration_mutation_response>>>;
  /** update data of the table: "csv_delta_file" */
  update_csv_delta_file?: Maybe<csv_delta_file_mutation_response>;
  /** update single row of the table: "csv_delta_file" */
  update_csv_delta_file_by_pk?: Maybe<csv_delta_file>;
  /** update multiples rows of table: "csv_delta_file" */
  update_csv_delta_file_many?: Maybe<Array<Maybe<csv_delta_file_mutation_response>>>;
  /** update data of the table: "csv_import" */
  update_csv_import?: Maybe<csv_import_mutation_response>;
  /** update single row of the table: "csv_import" */
  update_csv_import_by_pk?: Maybe<csv_import>;
  /** update data of the table: "csv_import_file" */
  update_csv_import_file?: Maybe<csv_import_file_mutation_response>;
  /** update single row of the table: "csv_import_file" */
  update_csv_import_file_by_pk?: Maybe<csv_import_file>;
  /** update multiples rows of table: "csv_import_file" */
  update_csv_import_file_many?: Maybe<Array<Maybe<csv_import_file_mutation_response>>>;
  /** update multiples rows of table: "csv_import" */
  update_csv_import_many?: Maybe<Array<Maybe<csv_import_mutation_response>>>;
  /** update data of the table: "csv_import_status" */
  update_csv_import_status?: Maybe<csv_import_status_mutation_response>;
  /** update single row of the table: "csv_import_status" */
  update_csv_import_status_by_pk?: Maybe<csv_import_status>;
  /** update multiples rows of table: "csv_import_status" */
  update_csv_import_status_many?: Maybe<Array<Maybe<csv_import_status_mutation_response>>>;
  /** update data of the table: "data_connector" */
  update_data_connector?: Maybe<data_connector_mutation_response>;
  /** update single row of the table: "data_connector" */
  update_data_connector_by_pk?: Maybe<data_connector>;
  /** update multiples rows of table: "data_connector" */
  update_data_connector_many?: Maybe<Array<Maybe<data_connector_mutation_response>>>;
  /** update data of the table: "data_connector_request_log" */
  update_data_connector_request_log?: Maybe<data_connector_request_log_mutation_response>;
  /** update single row of the table: "data_connector_request_log" */
  update_data_connector_request_log_by_pk?: Maybe<data_connector_request_log>;
  /** update multiples rows of table: "data_connector_request_log" */
  update_data_connector_request_log_many?: Maybe<Array<Maybe<data_connector_request_log_mutation_response>>>;
  /** update data of the table: "deflection" */
  update_deflection?: Maybe<deflection_mutation_response>;
  /** update single row of the table: "deflection" */
  update_deflection_by_pk?: Maybe<deflection>;
  /** update multiples rows of table: "deflection" */
  update_deflection_many?: Maybe<Array<Maybe<deflection_mutation_response>>>;
  /** update data of the table: "deflection_snapshot_item" */
  update_deflection_snapshot_item?: Maybe<deflection_snapshot_item_mutation_response>;
  /** update single row of the table: "deflection_snapshot_item" */
  update_deflection_snapshot_item_by_pk?: Maybe<deflection_snapshot_item>;
  /** update multiples rows of table: "deflection_snapshot_item" */
  update_deflection_snapshot_item_many?: Maybe<Array<Maybe<deflection_snapshot_item_mutation_response>>>;
  /** update data of the table: "deflection_type" */
  update_deflection_type?: Maybe<deflection_type_mutation_response>;
  /** update single row of the table: "deflection_type" */
  update_deflection_type_by_pk?: Maybe<deflection_type>;
  /** update multiples rows of table: "deflection_type" */
  update_deflection_type_many?: Maybe<Array<Maybe<deflection_type_mutation_response>>>;
  /** update data of the table: "demo" */
  update_demo?: Maybe<demo_mutation_response>;
  /** update single row of the table: "demo" */
  update_demo_by_pk?: Maybe<demo>;
  /** update multiples rows of table: "demo" */
  update_demo_many?: Maybe<Array<Maybe<demo_mutation_response>>>;
  /** update data of the table: "domain" */
  update_domain?: Maybe<domain_mutation_response>;
  /** update single row of the table: "domain" */
  update_domain_by_pk?: Maybe<domain>;
  /** update multiples rows of table: "domain" */
  update_domain_many?: Maybe<Array<Maybe<domain_mutation_response>>>;
  /** update data of the table: "domain_type" */
  update_domain_type?: Maybe<domain_type_mutation_response>;
  /** update single row of the table: "domain_type" */
  update_domain_type_by_pk?: Maybe<domain_type>;
  /** update multiples rows of table: "domain_type" */
  update_domain_type_many?: Maybe<Array<Maybe<domain_type_mutation_response>>>;
  /** update data of the table: "eligibility_message" */
  update_eligibility_message?: Maybe<eligibility_message_mutation_response>;
  /** update single row of the table: "eligibility_message" */
  update_eligibility_message_by_pk?: Maybe<eligibility_message>;
  /** update multiples rows of table: "eligibility_message" */
  update_eligibility_message_many?: Maybe<Array<Maybe<eligibility_message_mutation_response>>>;
  /** update data of the table: "event" */
  update_event?: Maybe<event_mutation_response>;
  /** update single row of the table: "event" */
  update_event_by_pk?: Maybe<event>;
  /** update multiples rows of table: "event" */
  update_event_many?: Maybe<Array<Maybe<event_mutation_response>>>;
  /** update data of the table: "event_type" */
  update_event_type?: Maybe<event_type_mutation_response>;
  /** update single row of the table: "event_type" */
  update_event_type_by_pk?: Maybe<event_type>;
  /** update multiples rows of table: "event_type" */
  update_event_type_many?: Maybe<Array<Maybe<event_type_mutation_response>>>;
  /** update data of the table: "flow" */
  update_flow?: Maybe<flow_mutation_response>;
  /** update data of the table: "flow_action" */
  update_flow_action?: Maybe<flow_action_mutation_response>;
  /** update data of the table: "flow_action_appearance" */
  update_flow_action_appearance?: Maybe<flow_action_appearance_mutation_response>;
  /** update single row of the table: "flow_action_appearance" */
  update_flow_action_appearance_by_pk?: Maybe<flow_action_appearance>;
  /** update multiples rows of table: "flow_action_appearance" */
  update_flow_action_appearance_many?: Maybe<Array<Maybe<flow_action_appearance_mutation_response>>>;
  /** update single row of the table: "flow_action" */
  update_flow_action_by_pk?: Maybe<flow_action>;
  /** update multiples rows of table: "flow_action" */
  update_flow_action_many?: Maybe<Array<Maybe<flow_action_mutation_response>>>;
  /** update data of the table: "flow_action_type" */
  update_flow_action_type?: Maybe<flow_action_type_mutation_response>;
  /** update single row of the table: "flow_action_type" */
  update_flow_action_type_by_pk?: Maybe<flow_action_type>;
  /** update multiples rows of table: "flow_action_type" */
  update_flow_action_type_many?: Maybe<Array<Maybe<flow_action_type_mutation_response>>>;
  /** update data of the table: "flow_auto_cancel_at" */
  update_flow_auto_cancel_at?: Maybe<flow_auto_cancel_at_mutation_response>;
  /** update single row of the table: "flow_auto_cancel_at" */
  update_flow_auto_cancel_at_by_pk?: Maybe<flow_auto_cancel_at>;
  /** update multiples rows of table: "flow_auto_cancel_at" */
  update_flow_auto_cancel_at_many?: Maybe<Array<Maybe<flow_auto_cancel_at_mutation_response>>>;
  /** update single row of the table: "flow" */
  update_flow_by_pk?: Maybe<flow>;
  /** update data of the table: "flow_language" */
  update_flow_language?: Maybe<flow_language_mutation_response>;
  /** update single row of the table: "flow_language" */
  update_flow_language_by_pk?: Maybe<flow_language>;
  /** update multiples rows of table: "flow_language" */
  update_flow_language_many?: Maybe<Array<Maybe<flow_language_mutation_response>>>;
  /** update multiples rows of table: "flow" */
  update_flow_many?: Maybe<Array<Maybe<flow_mutation_response>>>;
  /** update data of the table: "flow_on_cancel" */
  update_flow_on_cancel?: Maybe<flow_on_cancel_mutation_response>;
  /** update single row of the table: "flow_on_cancel" */
  update_flow_on_cancel_by_pk?: Maybe<flow_on_cancel>;
  /** update data of the table: "flow_on_cancel_change_plan" */
  update_flow_on_cancel_change_plan?: Maybe<flow_on_cancel_change_plan_mutation_response>;
  /** update single row of the table: "flow_on_cancel_change_plan" */
  update_flow_on_cancel_change_plan_by_pk?: Maybe<flow_on_cancel_change_plan>;
  /** update multiples rows of table: "flow_on_cancel_change_plan" */
  update_flow_on_cancel_change_plan_many?: Maybe<Array<Maybe<flow_on_cancel_change_plan_mutation_response>>>;
  /** update multiples rows of table: "flow_on_cancel" */
  update_flow_on_cancel_many?: Maybe<Array<Maybe<flow_on_cancel_mutation_response>>>;
  /** update data of the table: "flow_on_cancel_perform_at" */
  update_flow_on_cancel_perform_at?: Maybe<flow_on_cancel_perform_at_mutation_response>;
  /** update single row of the table: "flow_on_cancel_perform_at" */
  update_flow_on_cancel_perform_at_by_pk?: Maybe<flow_on_cancel_perform_at>;
  /** update multiples rows of table: "flow_on_cancel_perform_at" */
  update_flow_on_cancel_perform_at_many?: Maybe<Array<Maybe<flow_on_cancel_perform_at_mutation_response>>>;
  /** update data of the table: "flow_route_rule" */
  update_flow_route_rule?: Maybe<flow_route_rule_mutation_response>;
  /** update single row of the table: "flow_route_rule" */
  update_flow_route_rule_by_pk?: Maybe<flow_route_rule>;
  /** update multiples rows of table: "flow_route_rule" */
  update_flow_route_rule_many?: Maybe<Array<Maybe<flow_route_rule_mutation_response>>>;
  /** update data of the table: "flow_step" */
  update_flow_step?: Maybe<flow_step_mutation_response>;
  /** update data of the table: "flow_step_acknowledgement_group" */
  update_flow_step_acknowledgement_group?: Maybe<flow_step_acknowledgement_group_mutation_response>;
  /** update single row of the table: "flow_step_acknowledgement_group" */
  update_flow_step_acknowledgement_group_by_pk?: Maybe<flow_step_acknowledgement_group>;
  /** update multiples rows of table: "flow_step_acknowledgement_group" */
  update_flow_step_acknowledgement_group_many?: Maybe<Array<Maybe<flow_step_acknowledgement_group_mutation_response>>>;
  /** update single row of the table: "flow_step" */
  update_flow_step_by_pk?: Maybe<flow_step>;
  /** update data of the table: "flow_step_confirmation" */
  update_flow_step_confirmation?: Maybe<flow_step_confirmation_mutation_response>;
  /** update single row of the table: "flow_step_confirmation" */
  update_flow_step_confirmation_by_pk?: Maybe<flow_step_confirmation>;
  /** update multiples rows of table: "flow_step_confirmation" */
  update_flow_step_confirmation_many?: Maybe<Array<Maybe<flow_step_confirmation_mutation_response>>>;
  /** update data of the table: "flow_step_deflection_rule_group" */
  update_flow_step_deflection_rule_group?: Maybe<flow_step_deflection_rule_group_mutation_response>;
  /** update multiples rows of table: "flow_step_deflection_rule_group" */
  update_flow_step_deflection_rule_group_many?: Maybe<Array<Maybe<flow_step_deflection_rule_group_mutation_response>>>;
  /** update data of the table: "flow_step_form" */
  update_flow_step_form?: Maybe<flow_step_form_mutation_response>;
  /** update single row of the table: "flow_step_form" */
  update_flow_step_form_by_pk?: Maybe<flow_step_form>;
  /** update multiples rows of table: "flow_step_form" */
  update_flow_step_form_many?: Maybe<Array<Maybe<flow_step_form_mutation_response>>>;
  /** update data of the table: "flow_step_intervention" */
  update_flow_step_intervention?: Maybe<flow_step_intervention_mutation_response>;
  /** update single row of the table: "flow_step_intervention" */
  update_flow_step_intervention_by_pk?: Maybe<flow_step_intervention>;
  /** update multiples rows of table: "flow_step_intervention" */
  update_flow_step_intervention_many?: Maybe<Array<Maybe<flow_step_intervention_mutation_response>>>;
  /** update multiples rows of table: "flow_step" */
  update_flow_step_many?: Maybe<Array<Maybe<flow_step_mutation_response>>>;
  /** update data of the table: "flow_step_offer_rule_group" */
  update_flow_step_offer_rule_group?: Maybe<flow_step_offer_rule_group_mutation_response>;
  /** update multiples rows of table: "flow_step_offer_rule_group" */
  update_flow_step_offer_rule_group_many?: Maybe<Array<Maybe<flow_step_offer_rule_group_mutation_response>>>;
  /** update data of the table: "flow_step_question" */
  update_flow_step_question?: Maybe<flow_step_question_mutation_response>;
  /** update single row of the table: "flow_step_question" */
  update_flow_step_question_by_pk?: Maybe<flow_step_question>;
  /** update multiples rows of table: "flow_step_question" */
  update_flow_step_question_many?: Maybe<Array<Maybe<flow_step_question_mutation_response>>>;
  /** update data of the table: "flow_step_rule_group" */
  update_flow_step_rule_group?: Maybe<flow_step_rule_group_mutation_response>;
  /** update single row of the table: "flow_step_rule_group" */
  update_flow_step_rule_group_by_pk?: Maybe<flow_step_rule_group>;
  /** update multiples rows of table: "flow_step_rule_group" */
  update_flow_step_rule_group_many?: Maybe<Array<Maybe<flow_step_rule_group_mutation_response>>>;
  /** update data of the table: "flow_step_type" */
  update_flow_step_type?: Maybe<flow_step_type_mutation_response>;
  /** update single row of the table: "flow_step_type" */
  update_flow_step_type_by_pk?: Maybe<flow_step_type>;
  /** update multiples rows of table: "flow_step_type" */
  update_flow_step_type_many?: Maybe<Array<Maybe<flow_step_type_mutation_response>>>;
  /** update data of the table: "flow_step_version" */
  update_flow_step_version?: Maybe<flow_step_version_mutation_response>;
  /** update single row of the table: "flow_step_version" */
  update_flow_step_version_by_pk?: Maybe<flow_step_version>;
  /** update multiples rows of table: "flow_step_version" */
  update_flow_step_version_many?: Maybe<Array<Maybe<flow_step_version_mutation_response>>>;
  /** update data of the table: "flow_subscriber_form_question" */
  update_flow_subscriber_form_question?: Maybe<flow_subscriber_form_question_mutation_response>;
  /** update single row of the table: "flow_subscriber_form_question" */
  update_flow_subscriber_form_question_by_pk?: Maybe<flow_subscriber_form_question>;
  /** update multiples rows of table: "flow_subscriber_form_question" */
  update_flow_subscriber_form_question_many?: Maybe<Array<Maybe<flow_subscriber_form_question_mutation_response>>>;
  /** update data of the table: "flow_test" */
  update_flow_test?: Maybe<flow_test_mutation_response>;
  /** update single row of the table: "flow_test" */
  update_flow_test_by_pk?: Maybe<flow_test>;
  /** update data of the table: "flow_test_flow" */
  update_flow_test_flow?: Maybe<flow_test_flow_mutation_response>;
  /** update single row of the table: "flow_test_flow" */
  update_flow_test_flow_by_pk?: Maybe<flow_test_flow>;
  /** update multiples rows of table: "flow_test_flow" */
  update_flow_test_flow_many?: Maybe<Array<Maybe<flow_test_flow_mutation_response>>>;
  /** update multiples rows of table: "flow_test" */
  update_flow_test_many?: Maybe<Array<Maybe<flow_test_mutation_response>>>;
  /** update data of the table: "flow_text" */
  update_flow_text?: Maybe<flow_text_mutation_response>;
  /** update single row of the table: "flow_text" */
  update_flow_text_by_pk?: Maybe<flow_text>;
  /** update data of the table: "flow_text_key" */
  update_flow_text_key?: Maybe<flow_text_key_mutation_response>;
  /** update single row of the table: "flow_text_key" */
  update_flow_text_key_by_pk?: Maybe<flow_text_key>;
  /** update multiples rows of table: "flow_text_key" */
  update_flow_text_key_many?: Maybe<Array<Maybe<flow_text_key_mutation_response>>>;
  /** update multiples rows of table: "flow_text" */
  update_flow_text_many?: Maybe<Array<Maybe<flow_text_mutation_response>>>;
  /** update data of the table: "flow_text_version" */
  update_flow_text_version?: Maybe<flow_text_version_mutation_response>;
  /** update single row of the table: "flow_text_version" */
  update_flow_text_version_by_pk?: Maybe<flow_text_version>;
  /** update multiples rows of table: "flow_text_version" */
  update_flow_text_version_many?: Maybe<Array<Maybe<flow_text_version_mutation_response>>>;
  /** update data of the table: "flow_version" */
  update_flow_version?: Maybe<flow_version_mutation_response>;
  /** update single row of the table: "flow_version" */
  update_flow_version_by_pk?: Maybe<flow_version>;
  /** update multiples rows of table: "flow_version" */
  update_flow_version_many?: Maybe<Array<Maybe<flow_version_mutation_response>>>;
  /** update data of the table: "form" */
  update_form?: Maybe<form_mutation_response>;
  /** update single row of the table: "form" */
  update_form_by_pk?: Maybe<form>;
  /** update multiples rows of table: "form" */
  update_form_many?: Maybe<Array<Maybe<form_mutation_response>>>;
  /** update data of the table: "form_version" */
  update_form_version?: Maybe<form_version_mutation_response>;
  /** update single row of the table: "form_version" */
  update_form_version_by_pk?: Maybe<form_version>;
  /** update multiples rows of table: "form_version" */
  update_form_version_many?: Maybe<Array<Maybe<form_version_mutation_response>>>;
  /** update data of the table: "function_log" */
  update_function_log?: Maybe<function_log_mutation_response>;
  /** update single row of the table: "function_log" */
  update_function_log_by_pk?: Maybe<function_log>;
  /** update multiples rows of table: "function_log" */
  update_function_log_many?: Maybe<Array<Maybe<function_log_mutation_response>>>;
  /** update data of the table: "integration" */
  update_integration?: Maybe<integration_mutation_response>;
  /** update single row of the table: "integration" */
  update_integration_by_pk?: Maybe<integration>;
  /** update multiples rows of table: "integration" */
  update_integration_many?: Maybe<Array<Maybe<integration_mutation_response>>>;
  /** update data of the table: "integration_type" */
  update_integration_type?: Maybe<integration_type_mutation_response>;
  /** update single row of the table: "integration_type" */
  update_integration_type_by_pk?: Maybe<integration_type>;
  /** update multiples rows of table: "integration_type" */
  update_integration_type_many?: Maybe<Array<Maybe<integration_type_mutation_response>>>;
  /** update data of the table: "intervention" */
  update_intervention?: Maybe<intervention_mutation_response>;
  /** update single row of the table: "intervention" */
  update_intervention_by_pk?: Maybe<intervention>;
  /** update multiples rows of table: "intervention" */
  update_intervention_many?: Maybe<Array<Maybe<intervention_mutation_response>>>;
  /** update data of the table: "intervention_version" */
  update_intervention_version?: Maybe<intervention_version_mutation_response>;
  /** update single row of the table: "intervention_version" */
  update_intervention_version_by_pk?: Maybe<intervention_version>;
  /** update multiples rows of table: "intervention_version" */
  update_intervention_version_many?: Maybe<Array<Maybe<intervention_version_mutation_response>>>;
  /** update data of the table: "job" */
  update_job?: Maybe<job_mutation_response>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<job>;
  /** update multiples rows of table: "job" */
  update_job_many?: Maybe<Array<Maybe<job_mutation_response>>>;
  /** update data of the table: "language" */
  update_language?: Maybe<language_mutation_response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<language>;
  /** update multiples rows of table: "language" */
  update_language_many?: Maybe<Array<Maybe<language_mutation_response>>>;
  /** update data of the table: "most_recent_subscriber_flow_version" */
  update_most_recent_subscriber_flow_version?: Maybe<most_recent_subscriber_flow_version_mutation_response>;
  /** update multiples rows of table: "most_recent_subscriber_flow_version" */
  update_most_recent_subscriber_flow_version_many?: Maybe<Array<Maybe<most_recent_subscriber_flow_version_mutation_response>>>;
  /** update data of the table: "naviga_cancel_link" */
  update_naviga_cancel_link?: Maybe<naviga_cancel_link_mutation_response>;
  /** update single row of the table: "naviga_cancel_link" */
  update_naviga_cancel_link_by_pk?: Maybe<naviga_cancel_link>;
  /** update multiples rows of table: "naviga_cancel_link" */
  update_naviga_cancel_link_many?: Maybe<Array<Maybe<naviga_cancel_link_mutation_response>>>;
  /** update data of the table: "naviga_domain" */
  update_naviga_domain?: Maybe<naviga_domain_mutation_response>;
  /** update single row of the table: "naviga_domain" */
  update_naviga_domain_by_pk?: Maybe<naviga_domain>;
  /** update multiples rows of table: "naviga_domain" */
  update_naviga_domain_many?: Maybe<Array<Maybe<naviga_domain_mutation_response>>>;
  /** update data of the table: "naviga_paper_code" */
  update_naviga_paper_code?: Maybe<naviga_paper_code_mutation_response>;
  /** update single row of the table: "naviga_paper_code" */
  update_naviga_paper_code_by_pk?: Maybe<naviga_paper_code>;
  /** update multiples rows of table: "naviga_paper_code" */
  update_naviga_paper_code_many?: Maybe<Array<Maybe<naviga_paper_code_mutation_response>>>;
  /** update data of the table: "offer" */
  update_offer?: Maybe<offer_mutation_response>;
  /** update data of the table: "offer_autopilot" */
  update_offer_autopilot?: Maybe<offer_autopilot_mutation_response>;
  /** update single row of the table: "offer_autopilot" */
  update_offer_autopilot_by_pk?: Maybe<offer_autopilot>;
  /** update multiples rows of table: "offer_autopilot" */
  update_offer_autopilot_many?: Maybe<Array<Maybe<offer_autopilot_mutation_response>>>;
  /** update data of the table: "offer_autopilot_round" */
  update_offer_autopilot_round?: Maybe<offer_autopilot_round_mutation_response>;
  /** update single row of the table: "offer_autopilot_round" */
  update_offer_autopilot_round_by_pk?: Maybe<offer_autopilot_round>;
  /** update data of the table: "offer_autopilot_round_log" */
  update_offer_autopilot_round_log?: Maybe<offer_autopilot_round_log_mutation_response>;
  /** update single row of the table: "offer_autopilot_round_log" */
  update_offer_autopilot_round_log_by_pk?: Maybe<offer_autopilot_round_log>;
  /** update multiples rows of table: "offer_autopilot_round_log" */
  update_offer_autopilot_round_log_many?: Maybe<Array<Maybe<offer_autopilot_round_log_mutation_response>>>;
  /** update multiples rows of table: "offer_autopilot_round" */
  update_offer_autopilot_round_many?: Maybe<Array<Maybe<offer_autopilot_round_mutation_response>>>;
  /** update data of the table: "offer_autopilot_round_strategy" */
  update_offer_autopilot_round_strategy?: Maybe<offer_autopilot_round_strategy_mutation_response>;
  /** update single row of the table: "offer_autopilot_round_strategy" */
  update_offer_autopilot_round_strategy_by_pk?: Maybe<offer_autopilot_round_strategy>;
  /** update multiples rows of table: "offer_autopilot_round_strategy" */
  update_offer_autopilot_round_strategy_many?: Maybe<Array<Maybe<offer_autopilot_round_strategy_mutation_response>>>;
  /** update data of the table: "offer_autopilot_strategy" */
  update_offer_autopilot_strategy?: Maybe<offer_autopilot_strategy_mutation_response>;
  /** update single row of the table: "offer_autopilot_strategy" */
  update_offer_autopilot_strategy_by_pk?: Maybe<offer_autopilot_strategy>;
  /** update multiples rows of table: "offer_autopilot_strategy" */
  update_offer_autopilot_strategy_many?: Maybe<Array<Maybe<offer_autopilot_strategy_mutation_response>>>;
  /** update data of the table: "offer_autopilot_success_metric" */
  update_offer_autopilot_success_metric?: Maybe<offer_autopilot_success_metric_mutation_response>;
  /** update single row of the table: "offer_autopilot_success_metric" */
  update_offer_autopilot_success_metric_by_pk?: Maybe<offer_autopilot_success_metric>;
  /** update multiples rows of table: "offer_autopilot_success_metric" */
  update_offer_autopilot_success_metric_many?: Maybe<Array<Maybe<offer_autopilot_success_metric_mutation_response>>>;
  /** update single row of the table: "offer" */
  update_offer_by_pk?: Maybe<offer>;
  /** update data of the table: "offer_change_plan" */
  update_offer_change_plan?: Maybe<offer_change_plan_mutation_response>;
  /** update single row of the table: "offer_change_plan" */
  update_offer_change_plan_by_pk?: Maybe<offer_change_plan>;
  /** update data of the table: "offer_change_plan_change_at" */
  update_offer_change_plan_change_at?: Maybe<offer_change_plan_change_at_mutation_response>;
  /** update single row of the table: "offer_change_plan_change_at" */
  update_offer_change_plan_change_at_by_pk?: Maybe<offer_change_plan_change_at>;
  /** update multiples rows of table: "offer_change_plan_change_at" */
  update_offer_change_plan_change_at_many?: Maybe<Array<Maybe<offer_change_plan_change_at_mutation_response>>>;
  /** update multiples rows of table: "offer_change_plan" */
  update_offer_change_plan_many?: Maybe<Array<Maybe<offer_change_plan_mutation_response>>>;
  /** update data of the table: "offer_coupon" */
  update_offer_coupon?: Maybe<offer_coupon_mutation_response>;
  /** update data of the table: "offer_coupon_apply_to" */
  update_offer_coupon_apply_to?: Maybe<offer_coupon_apply_to_mutation_response>;
  /** update single row of the table: "offer_coupon_apply_to" */
  update_offer_coupon_apply_to_by_pk?: Maybe<offer_coupon_apply_to>;
  /** update multiples rows of table: "offer_coupon_apply_to" */
  update_offer_coupon_apply_to_many?: Maybe<Array<Maybe<offer_coupon_apply_to_mutation_response>>>;
  /** update single row of the table: "offer_coupon" */
  update_offer_coupon_by_pk?: Maybe<offer_coupon>;
  /** update multiples rows of table: "offer_coupon" */
  update_offer_coupon_many?: Maybe<Array<Maybe<offer_coupon_mutation_response>>>;
  /** update data of the table: "offer_coupon_type" */
  update_offer_coupon_type?: Maybe<offer_coupon_type_mutation_response>;
  /** update single row of the table: "offer_coupon_type" */
  update_offer_coupon_type_by_pk?: Maybe<offer_coupon_type>;
  /** update multiples rows of table: "offer_coupon_type" */
  update_offer_coupon_type_many?: Maybe<Array<Maybe<offer_coupon_type_mutation_response>>>;
  /** update data of the table: "offer_custom" */
  update_offer_custom?: Maybe<offer_custom_mutation_response>;
  /** update single row of the table: "offer_custom" */
  update_offer_custom_by_pk?: Maybe<offer_custom>;
  /** update multiples rows of table: "offer_custom" */
  update_offer_custom_many?: Maybe<Array<Maybe<offer_custom_mutation_response>>>;
  /** update data of the table: "offer_downgrade" */
  update_offer_downgrade?: Maybe<offer_downgrade_mutation_response>;
  /** update single row of the table: "offer_downgrade" */
  update_offer_downgrade_by_pk?: Maybe<offer_downgrade>;
  /** update multiples rows of table: "offer_downgrade" */
  update_offer_downgrade_many?: Maybe<Array<Maybe<offer_downgrade_mutation_response>>>;
  /** update data of the table: "offer_goal" */
  update_offer_goal?: Maybe<offer_goal_mutation_response>;
  /** update single row of the table: "offer_goal" */
  update_offer_goal_by_pk?: Maybe<offer_goal>;
  /** update multiples rows of table: "offer_goal" */
  update_offer_goal_many?: Maybe<Array<Maybe<offer_goal_mutation_response>>>;
  /** update data of the table: "offer_group" */
  update_offer_group?: Maybe<offer_group_mutation_response>;
  /** update single row of the table: "offer_group" */
  update_offer_group_by_pk?: Maybe<offer_group>;
  /** update multiples rows of table: "offer_group" */
  update_offer_group_many?: Maybe<Array<Maybe<offer_group_mutation_response>>>;
  /** update multiples rows of table: "offer" */
  update_offer_many?: Maybe<Array<Maybe<offer_mutation_response>>>;
  /** update data of the table: "offer_modify_subscription" */
  update_offer_modify_subscription?: Maybe<offer_modify_subscription_mutation_response>;
  /** update single row of the table: "offer_modify_subscription" */
  update_offer_modify_subscription_by_pk?: Maybe<offer_modify_subscription>;
  /** update multiples rows of table: "offer_modify_subscription" */
  update_offer_modify_subscription_many?: Maybe<Array<Maybe<offer_modify_subscription_mutation_response>>>;
  /** update data of the table: "offer_modify_subscription_modify_at" */
  update_offer_modify_subscription_modify_at?: Maybe<offer_modify_subscription_modify_at_mutation_response>;
  /** update single row of the table: "offer_modify_subscription_modify_at" */
  update_offer_modify_subscription_modify_at_by_pk?: Maybe<offer_modify_subscription_modify_at>;
  /** update multiples rows of table: "offer_modify_subscription_modify_at" */
  update_offer_modify_subscription_modify_at_many?: Maybe<Array<Maybe<offer_modify_subscription_modify_at_mutation_response>>>;
  /** update data of the table: "offer_modify_subscription_option" */
  update_offer_modify_subscription_option?: Maybe<offer_modify_subscription_option_mutation_response>;
  /** update single row of the table: "offer_modify_subscription_option" */
  update_offer_modify_subscription_option_by_pk?: Maybe<offer_modify_subscription_option>;
  /** update multiples rows of table: "offer_modify_subscription_option" */
  update_offer_modify_subscription_option_many?: Maybe<Array<Maybe<offer_modify_subscription_option_mutation_response>>>;
  /** update data of the table: "offer_modify_subscription_option_type" */
  update_offer_modify_subscription_option_type?: Maybe<offer_modify_subscription_option_type_mutation_response>;
  /** update single row of the table: "offer_modify_subscription_option_type" */
  update_offer_modify_subscription_option_type_by_pk?: Maybe<offer_modify_subscription_option_type>;
  /** update multiples rows of table: "offer_modify_subscription_option_type" */
  update_offer_modify_subscription_option_type_many?: Maybe<Array<Maybe<offer_modify_subscription_option_type_mutation_response>>>;
  /** update data of the table: "offer_pause_subscription" */
  update_offer_pause_subscription?: Maybe<offer_pause_subscription_mutation_response>;
  /** update single row of the table: "offer_pause_subscription" */
  update_offer_pause_subscription_by_pk?: Maybe<offer_pause_subscription>;
  /** update data of the table: "offer_pause_subscription_interval" */
  update_offer_pause_subscription_interval?: Maybe<offer_pause_subscription_interval_mutation_response>;
  /** update single row of the table: "offer_pause_subscription_interval" */
  update_offer_pause_subscription_interval_by_pk?: Maybe<offer_pause_subscription_interval>;
  /** update multiples rows of table: "offer_pause_subscription_interval" */
  update_offer_pause_subscription_interval_many?: Maybe<Array<Maybe<offer_pause_subscription_interval_mutation_response>>>;
  /** update multiples rows of table: "offer_pause_subscription" */
  update_offer_pause_subscription_many?: Maybe<Array<Maybe<offer_pause_subscription_mutation_response>>>;
  /** update data of the table: "offer_pause_subscription_option" */
  update_offer_pause_subscription_option?: Maybe<offer_pause_subscription_option_mutation_response>;
  /** update single row of the table: "offer_pause_subscription_option" */
  update_offer_pause_subscription_option_by_pk?: Maybe<offer_pause_subscription_option>;
  /** update multiples rows of table: "offer_pause_subscription_option" */
  update_offer_pause_subscription_option_many?: Maybe<Array<Maybe<offer_pause_subscription_option_mutation_response>>>;
  /** update data of the table: "offer_pause_subscription_option_type" */
  update_offer_pause_subscription_option_type?: Maybe<offer_pause_subscription_option_type_mutation_response>;
  /** update single row of the table: "offer_pause_subscription_option_type" */
  update_offer_pause_subscription_option_type_by_pk?: Maybe<offer_pause_subscription_option_type>;
  /** update multiples rows of table: "offer_pause_subscription_option_type" */
  update_offer_pause_subscription_option_type_many?: Maybe<Array<Maybe<offer_pause_subscription_option_type_mutation_response>>>;
  /** update data of the table: "offer_pause_subscription_pause_at" */
  update_offer_pause_subscription_pause_at?: Maybe<offer_pause_subscription_pause_at_mutation_response>;
  /** update single row of the table: "offer_pause_subscription_pause_at" */
  update_offer_pause_subscription_pause_at_by_pk?: Maybe<offer_pause_subscription_pause_at>;
  /** update multiples rows of table: "offer_pause_subscription_pause_at" */
  update_offer_pause_subscription_pause_at_many?: Maybe<Array<Maybe<offer_pause_subscription_pause_at_mutation_response>>>;
  /** update data of the table: "offer_product_swap" */
  update_offer_product_swap?: Maybe<offer_product_swap_mutation_response>;
  /** update single row of the table: "offer_product_swap" */
  update_offer_product_swap_by_pk?: Maybe<offer_product_swap>;
  /** update multiples rows of table: "offer_product_swap" */
  update_offer_product_swap_many?: Maybe<Array<Maybe<offer_product_swap_mutation_response>>>;
  /** update data of the table: "offer_product_swap_platform_ecommerce_product" */
  update_offer_product_swap_platform_ecommerce_product?: Maybe<offer_product_swap_platform_ecommerce_product_mutation_response>;
  /** update single row of the table: "offer_product_swap_platform_ecommerce_product" */
  update_offer_product_swap_platform_ecommerce_product_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** update multiples rows of table: "offer_product_swap_platform_ecommerce_product" */
  update_offer_product_swap_platform_ecommerce_product_many?: Maybe<Array<Maybe<offer_product_swap_platform_ecommerce_product_mutation_response>>>;
  /** update data of the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  update_offer_product_swap_platform_ecommerce_product_platform_variant?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response>;
  /** update single row of the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  update_offer_product_swap_platform_ecommerce_product_platform_variant_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** update multiples rows of table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  update_offer_product_swap_platform_ecommerce_product_platform_variant_many?: Maybe<Array<Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response>>>;
  /** update data of the table: "offer_reschedule_order" */
  update_offer_reschedule_order?: Maybe<offer_reschedule_order_mutation_response>;
  /** update single row of the table: "offer_reschedule_order" */
  update_offer_reschedule_order_by_pk?: Maybe<offer_reschedule_order>;
  /** update multiples rows of table: "offer_reschedule_order" */
  update_offer_reschedule_order_many?: Maybe<Array<Maybe<offer_reschedule_order_mutation_response>>>;
  /** update data of the table: "offer_rule" */
  update_offer_rule?: Maybe<offer_rule_mutation_response>;
  /** update single row of the table: "offer_rule" */
  update_offer_rule_by_pk?: Maybe<offer_rule>;
  /** update data of the table: "offer_rule_group" */
  update_offer_rule_group?: Maybe<offer_rule_group_mutation_response>;
  /** update single row of the table: "offer_rule_group" */
  update_offer_rule_group_by_pk?: Maybe<offer_rule_group>;
  /** update multiples rows of table: "offer_rule_group" */
  update_offer_rule_group_many?: Maybe<Array<Maybe<offer_rule_group_mutation_response>>>;
  /** update data of the table: "offer_rule_group_version" */
  update_offer_rule_group_version?: Maybe<offer_rule_group_version_mutation_response>;
  /** update single row of the table: "offer_rule_group_version" */
  update_offer_rule_group_version_by_pk?: Maybe<offer_rule_group_version>;
  /** update multiples rows of table: "offer_rule_group_version" */
  update_offer_rule_group_version_many?: Maybe<Array<Maybe<offer_rule_group_version_mutation_response>>>;
  /** update multiples rows of table: "offer_rule" */
  update_offer_rule_many?: Maybe<Array<Maybe<offer_rule_mutation_response>>>;
  /** update data of the table: "offer_rule_rule" */
  update_offer_rule_rule?: Maybe<offer_rule_rule_mutation_response>;
  /** update single row of the table: "offer_rule_rule" */
  update_offer_rule_rule_by_pk?: Maybe<offer_rule_rule>;
  /** update multiples rows of table: "offer_rule_rule" */
  update_offer_rule_rule_many?: Maybe<Array<Maybe<offer_rule_rule_mutation_response>>>;
  /** update data of the table: "offer_rule_version" */
  update_offer_rule_version?: Maybe<offer_rule_version_mutation_response>;
  /** update single row of the table: "offer_rule_version" */
  update_offer_rule_version_by_pk?: Maybe<offer_rule_version>;
  /** update multiples rows of table: "offer_rule_version" */
  update_offer_rule_version_many?: Maybe<Array<Maybe<offer_rule_version_mutation_response>>>;
  /** update data of the table: "offer_style" */
  update_offer_style?: Maybe<offer_style_mutation_response>;
  /** update single row of the table: "offer_style" */
  update_offer_style_by_pk?: Maybe<offer_style>;
  /** update multiples rows of table: "offer_style" */
  update_offer_style_many?: Maybe<Array<Maybe<offer_style_mutation_response>>>;
  /** update data of the table: "offer_test" */
  update_offer_test?: Maybe<offer_test_mutation_response>;
  /** update single row of the table: "offer_test" */
  update_offer_test_by_pk?: Maybe<offer_test>;
  /** update multiples rows of table: "offer_test" */
  update_offer_test_many?: Maybe<Array<Maybe<offer_test_mutation_response>>>;
  /** update data of the table: "offer_test_offer" */
  update_offer_test_offer?: Maybe<offer_test_offer_mutation_response>;
  /** update single row of the table: "offer_test_offer" */
  update_offer_test_offer_by_pk?: Maybe<offer_test_offer>;
  /** update multiples rows of table: "offer_test_offer" */
  update_offer_test_offer_many?: Maybe<Array<Maybe<offer_test_offer_mutation_response>>>;
  /** update data of the table: "offer_timer" */
  update_offer_timer?: Maybe<offer_timer_mutation_response>;
  /** update single row of the table: "offer_timer" */
  update_offer_timer_by_pk?: Maybe<offer_timer>;
  /** update multiples rows of table: "offer_timer" */
  update_offer_timer_many?: Maybe<Array<Maybe<offer_timer_mutation_response>>>;
  /** update data of the table: "offer_timer_mode" */
  update_offer_timer_mode?: Maybe<offer_timer_mode_mutation_response>;
  /** update single row of the table: "offer_timer_mode" */
  update_offer_timer_mode_by_pk?: Maybe<offer_timer_mode>;
  /** update multiples rows of table: "offer_timer_mode" */
  update_offer_timer_mode_many?: Maybe<Array<Maybe<offer_timer_mode_mutation_response>>>;
  /** update data of the table: "offer_trial_extension" */
  update_offer_trial_extension?: Maybe<offer_trial_extension_mutation_response>;
  /** update single row of the table: "offer_trial_extension" */
  update_offer_trial_extension_by_pk?: Maybe<offer_trial_extension>;
  /** update multiples rows of table: "offer_trial_extension" */
  update_offer_trial_extension_many?: Maybe<Array<Maybe<offer_trial_extension_mutation_response>>>;
  /** update data of the table: "offer_type" */
  update_offer_type?: Maybe<offer_type_mutation_response>;
  /** update single row of the table: "offer_type" */
  update_offer_type_by_pk?: Maybe<offer_type>;
  /** update multiples rows of table: "offer_type" */
  update_offer_type_many?: Maybe<Array<Maybe<offer_type_mutation_response>>>;
  /** update data of the table: "offer_upgrade" */
  update_offer_upgrade?: Maybe<offer_upgrade_mutation_response>;
  /** update single row of the table: "offer_upgrade" */
  update_offer_upgrade_by_pk?: Maybe<offer_upgrade>;
  /** update multiples rows of table: "offer_upgrade" */
  update_offer_upgrade_many?: Maybe<Array<Maybe<offer_upgrade_mutation_response>>>;
  /** update data of the table: "offer_variant" */
  update_offer_variant?: Maybe<offer_variant_mutation_response>;
  /** update single row of the table: "offer_variant" */
  update_offer_variant_by_pk?: Maybe<offer_variant>;
  /** update data of the table: "offer_variant_coupon" */
  update_offer_variant_coupon?: Maybe<offer_variant_coupon_mutation_response>;
  /** update single row of the table: "offer_variant_coupon" */
  update_offer_variant_coupon_by_pk?: Maybe<offer_variant_coupon>;
  /** update multiples rows of table: "offer_variant_coupon" */
  update_offer_variant_coupon_many?: Maybe<Array<Maybe<offer_variant_coupon_mutation_response>>>;
  /** update multiples rows of table: "offer_variant" */
  update_offer_variant_many?: Maybe<Array<Maybe<offer_variant_mutation_response>>>;
  /** update data of the table: "platform" */
  update_platform?: Maybe<platform_mutation_response>;
  /** update data of the table: "platform_addon" */
  update_platform_addon?: Maybe<platform_addon_mutation_response>;
  /** update single row of the table: "platform_addon" */
  update_platform_addon_by_pk?: Maybe<platform_addon>;
  /** update multiples rows of table: "platform_addon" */
  update_platform_addon_many?: Maybe<Array<Maybe<platform_addon_mutation_response>>>;
  /** update data of the table: "platform_addon_plan" */
  update_platform_addon_plan?: Maybe<platform_addon_plan_mutation_response>;
  /** update single row of the table: "platform_addon_plan" */
  update_platform_addon_plan_by_pk?: Maybe<platform_addon_plan>;
  /** update multiples rows of table: "platform_addon_plan" */
  update_platform_addon_plan_many?: Maybe<Array<Maybe<platform_addon_plan_mutation_response>>>;
  /** update data of the table: "platform_addon_price" */
  update_platform_addon_price?: Maybe<platform_addon_price_mutation_response>;
  /** update single row of the table: "platform_addon_price" */
  update_platform_addon_price_by_pk?: Maybe<platform_addon_price>;
  /** update multiples rows of table: "platform_addon_price" */
  update_platform_addon_price_many?: Maybe<Array<Maybe<platform_addon_price_mutation_response>>>;
  /** update data of the table: "platform_addon_price_tier" */
  update_platform_addon_price_tier?: Maybe<platform_addon_price_tier_mutation_response>;
  /** update single row of the table: "platform_addon_price_tier" */
  update_platform_addon_price_tier_by_pk?: Maybe<platform_addon_price_tier>;
  /** update multiples rows of table: "platform_addon_price_tier" */
  update_platform_addon_price_tier_many?: Maybe<Array<Maybe<platform_addon_price_tier_mutation_response>>>;
  /** update data of the table: "platform_api_log" */
  update_platform_api_log?: Maybe<platform_api_log_mutation_response>;
  /** update single row of the table: "platform_api_log" */
  update_platform_api_log_by_pk?: Maybe<platform_api_log>;
  /** update multiples rows of table: "platform_api_log" */
  update_platform_api_log_many?: Maybe<Array<Maybe<platform_api_log_mutation_response>>>;
  /** update data of the table: "platform_base_currency" */
  update_platform_base_currency?: Maybe<platform_base_currency_mutation_response>;
  /** update single row of the table: "platform_base_currency" */
  update_platform_base_currency_by_pk?: Maybe<platform_base_currency>;
  /** update multiples rows of table: "platform_base_currency" */
  update_platform_base_currency_many?: Maybe<Array<Maybe<platform_base_currency_mutation_response>>>;
  /** update single row of the table: "platform" */
  update_platform_by_pk?: Maybe<platform>;
  /** update data of the table: "platform_cancel_reason" */
  update_platform_cancel_reason?: Maybe<platform_cancel_reason_mutation_response>;
  /** update single row of the table: "platform_cancel_reason" */
  update_platform_cancel_reason_by_pk?: Maybe<platform_cancel_reason>;
  /** update multiples rows of table: "platform_cancel_reason" */
  update_platform_cancel_reason_many?: Maybe<Array<Maybe<platform_cancel_reason_mutation_response>>>;
  /** update data of the table: "platform_connection" */
  update_platform_connection?: Maybe<platform_connection_mutation_response>;
  /** update single row of the table: "platform_connection" */
  update_platform_connection_by_pk?: Maybe<platform_connection>;
  /** update multiples rows of table: "platform_connection" */
  update_platform_connection_many?: Maybe<Array<Maybe<platform_connection_mutation_response>>>;
  /** update data of the table: "platform_connection_naviga_domain" */
  update_platform_connection_naviga_domain?: Maybe<platform_connection_naviga_domain_mutation_response>;
  /** update single row of the table: "platform_connection_naviga_domain" */
  update_platform_connection_naviga_domain_by_pk?: Maybe<platform_connection_naviga_domain>;
  /** update multiples rows of table: "platform_connection_naviga_domain" */
  update_platform_connection_naviga_domain_many?: Maybe<Array<Maybe<platform_connection_naviga_domain_mutation_response>>>;
  /** update data of the table: "platform_connection_naviga_paper_code_endpoint" */
  update_platform_connection_naviga_paper_code_endpoint?: Maybe<platform_connection_naviga_paper_code_endpoint_mutation_response>;
  /** update single row of the table: "platform_connection_naviga_paper_code_endpoint" */
  update_platform_connection_naviga_paper_code_endpoint_by_pk?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** update multiples rows of table: "platform_connection_naviga_paper_code_endpoint" */
  update_platform_connection_naviga_paper_code_endpoint_many?: Maybe<Array<Maybe<platform_connection_naviga_paper_code_endpoint_mutation_response>>>;
  /** update data of the table: "platform_connection_shopify_store" */
  update_platform_connection_shopify_store?: Maybe<platform_connection_shopify_store_mutation_response>;
  /** update single row of the table: "platform_connection_shopify_store" */
  update_platform_connection_shopify_store_by_pk?: Maybe<platform_connection_shopify_store>;
  /** update multiples rows of table: "platform_connection_shopify_store" */
  update_platform_connection_shopify_store_many?: Maybe<Array<Maybe<platform_connection_shopify_store_mutation_response>>>;
  /** update data of the table: "platform_coupon" */
  update_platform_coupon?: Maybe<platform_coupon_mutation_response>;
  /** update data of the table: "platform_coupon_addon" */
  update_platform_coupon_addon?: Maybe<platform_coupon_addon_mutation_response>;
  /** update single row of the table: "platform_coupon_addon" */
  update_platform_coupon_addon_by_pk?: Maybe<platform_coupon_addon>;
  /** update multiples rows of table: "platform_coupon_addon" */
  update_platform_coupon_addon_many?: Maybe<Array<Maybe<platform_coupon_addon_mutation_response>>>;
  /** update data of the table: "platform_coupon_apply_to" */
  update_platform_coupon_apply_to?: Maybe<platform_coupon_apply_to_mutation_response>;
  /** update single row of the table: "platform_coupon_apply_to" */
  update_platform_coupon_apply_to_by_pk?: Maybe<platform_coupon_apply_to>;
  /** update multiples rows of table: "platform_coupon_apply_to" */
  update_platform_coupon_apply_to_many?: Maybe<Array<Maybe<platform_coupon_apply_to_mutation_response>>>;
  /** update data of the table: "platform_coupon_apply_to_restriction" */
  update_platform_coupon_apply_to_restriction?: Maybe<platform_coupon_apply_to_restriction_mutation_response>;
  /** update single row of the table: "platform_coupon_apply_to_restriction" */
  update_platform_coupon_apply_to_restriction_by_pk?: Maybe<platform_coupon_apply_to_restriction>;
  /** update multiples rows of table: "platform_coupon_apply_to_restriction" */
  update_platform_coupon_apply_to_restriction_many?: Maybe<Array<Maybe<platform_coupon_apply_to_restriction_mutation_response>>>;
  /** update single row of the table: "platform_coupon" */
  update_platform_coupon_by_pk?: Maybe<platform_coupon>;
  /** update data of the table: "platform_coupon_duration" */
  update_platform_coupon_duration?: Maybe<platform_coupon_duration_mutation_response>;
  /** update single row of the table: "platform_coupon_duration" */
  update_platform_coupon_duration_by_pk?: Maybe<platform_coupon_duration>;
  /** update data of the table: "platform_coupon_duration_interval" */
  update_platform_coupon_duration_interval?: Maybe<platform_coupon_duration_interval_mutation_response>;
  /** update single row of the table: "platform_coupon_duration_interval" */
  update_platform_coupon_duration_interval_by_pk?: Maybe<platform_coupon_duration_interval>;
  /** update multiples rows of table: "platform_coupon_duration_interval" */
  update_platform_coupon_duration_interval_many?: Maybe<Array<Maybe<platform_coupon_duration_interval_mutation_response>>>;
  /** update multiples rows of table: "platform_coupon_duration" */
  update_platform_coupon_duration_many?: Maybe<Array<Maybe<platform_coupon_duration_mutation_response>>>;
  /** update multiples rows of table: "platform_coupon" */
  update_platform_coupon_many?: Maybe<Array<Maybe<platform_coupon_mutation_response>>>;
  /** update data of the table: "platform_coupon_plan" */
  update_platform_coupon_plan?: Maybe<platform_coupon_plan_mutation_response>;
  /** update single row of the table: "platform_coupon_plan" */
  update_platform_coupon_plan_by_pk?: Maybe<platform_coupon_plan>;
  /** update multiples rows of table: "platform_coupon_plan" */
  update_platform_coupon_plan_many?: Maybe<Array<Maybe<platform_coupon_plan_mutation_response>>>;
  /** update data of the table: "platform_coupon_product" */
  update_platform_coupon_product?: Maybe<platform_coupon_product_mutation_response>;
  /** update single row of the table: "platform_coupon_product" */
  update_platform_coupon_product_by_pk?: Maybe<platform_coupon_product>;
  /** update data of the table: "platform_coupon_product_group" */
  update_platform_coupon_product_group?: Maybe<platform_coupon_product_group_mutation_response>;
  /** update single row of the table: "platform_coupon_product_group" */
  update_platform_coupon_product_group_by_pk?: Maybe<platform_coupon_product_group>;
  /** update multiples rows of table: "platform_coupon_product_group" */
  update_platform_coupon_product_group_many?: Maybe<Array<Maybe<platform_coupon_product_group_mutation_response>>>;
  /** update multiples rows of table: "platform_coupon_product" */
  update_platform_coupon_product_many?: Maybe<Array<Maybe<platform_coupon_product_mutation_response>>>;
  /** update data of the table: "platform_coupon_type" */
  update_platform_coupon_type?: Maybe<platform_coupon_type_mutation_response>;
  /** update single row of the table: "platform_coupon_type" */
  update_platform_coupon_type_by_pk?: Maybe<platform_coupon_type>;
  /** update multiples rows of table: "platform_coupon_type" */
  update_platform_coupon_type_many?: Maybe<Array<Maybe<platform_coupon_type_mutation_response>>>;
  /** update data of the table: "platform_credentials" */
  update_platform_credentials?: Maybe<platform_credentials_mutation_response>;
  /** update single row of the table: "platform_credentials" */
  update_platform_credentials_by_pk?: Maybe<platform_credentials>;
  /** update multiples rows of table: "platform_credentials" */
  update_platform_credentials_many?: Maybe<Array<Maybe<platform_credentials_mutation_response>>>;
  /** update data of the table: "platform_currency" */
  update_platform_currency?: Maybe<platform_currency_mutation_response>;
  /** update single row of the table: "platform_currency" */
  update_platform_currency_by_pk?: Maybe<platform_currency>;
  /** update data of the table: "platform_currency_exchange_rate" */
  update_platform_currency_exchange_rate?: Maybe<platform_currency_exchange_rate_mutation_response>;
  /** update single row of the table: "platform_currency_exchange_rate" */
  update_platform_currency_exchange_rate_by_pk?: Maybe<platform_currency_exchange_rate>;
  /** update multiples rows of table: "platform_currency_exchange_rate" */
  update_platform_currency_exchange_rate_many?: Maybe<Array<Maybe<platform_currency_exchange_rate_mutation_response>>>;
  /** update multiples rows of table: "platform_currency" */
  update_platform_currency_many?: Maybe<Array<Maybe<platform_currency_mutation_response>>>;
  /** update data of the table: "platform_customer" */
  update_platform_customer?: Maybe<platform_customer_mutation_response>;
  /** update single row of the table: "platform_customer" */
  update_platform_customer_by_pk?: Maybe<platform_customer>;
  /** update multiples rows of table: "platform_customer" */
  update_platform_customer_many?: Maybe<Array<Maybe<platform_customer_mutation_response>>>;
  /** update data of the table: "platform_ecommerce_product" */
  update_platform_ecommerce_product?: Maybe<platform_ecommerce_product_mutation_response>;
  /** update single row of the table: "platform_ecommerce_product" */
  update_platform_ecommerce_product_by_pk?: Maybe<platform_ecommerce_product>;
  /** update multiples rows of table: "platform_ecommerce_product" */
  update_platform_ecommerce_product_many?: Maybe<Array<Maybe<platform_ecommerce_product_mutation_response>>>;
  /** update data of the table: "platform_import" */
  update_platform_import?: Maybe<platform_import_mutation_response>;
  /** update single row of the table: "platform_import" */
  update_platform_import_by_pk?: Maybe<platform_import>;
  /** update multiples rows of table: "platform_import" */
  update_platform_import_many?: Maybe<Array<Maybe<platform_import_mutation_response>>>;
  /** update data of the table: "platform_import_status" */
  update_platform_import_status?: Maybe<platform_import_status_mutation_response>;
  /** update single row of the table: "platform_import_status" */
  update_platform_import_status_by_pk?: Maybe<platform_import_status>;
  /** update multiples rows of table: "platform_import_status" */
  update_platform_import_status_many?: Maybe<Array<Maybe<platform_import_status_mutation_response>>>;
  /** update data of the table: "platform_import_step" */
  update_platform_import_step?: Maybe<platform_import_step_mutation_response>;
  /** update single row of the table: "platform_import_step" */
  update_platform_import_step_by_pk?: Maybe<platform_import_step>;
  /** update multiples rows of table: "platform_import_step" */
  update_platform_import_step_many?: Maybe<Array<Maybe<platform_import_step_mutation_response>>>;
  /** update data of the table: "platform_import_step_type" */
  update_platform_import_step_type?: Maybe<platform_import_step_type_mutation_response>;
  /** update single row of the table: "platform_import_step_type" */
  update_platform_import_step_type_by_pk?: Maybe<platform_import_step_type>;
  /** update multiples rows of table: "platform_import_step_type" */
  update_platform_import_step_type_many?: Maybe<Array<Maybe<platform_import_step_type_mutation_response>>>;
  /** update data of the table: "platform_invoice" */
  update_platform_invoice?: Maybe<platform_invoice_mutation_response>;
  /** update single row of the table: "platform_invoice" */
  update_platform_invoice_by_pk?: Maybe<platform_invoice>;
  /** update multiples rows of table: "platform_invoice" */
  update_platform_invoice_many?: Maybe<Array<Maybe<platform_invoice_mutation_response>>>;
  /** update data of the table: "platform_invoice_status" */
  update_platform_invoice_status?: Maybe<platform_invoice_status_mutation_response>;
  /** update single row of the table: "platform_invoice_status" */
  update_platform_invoice_status_by_pk?: Maybe<platform_invoice_status>;
  /** update multiples rows of table: "platform_invoice_status" */
  update_platform_invoice_status_many?: Maybe<Array<Maybe<platform_invoice_status_mutation_response>>>;
  /** update multiples rows of table: "platform" */
  update_platform_many?: Maybe<Array<Maybe<platform_mutation_response>>>;
  /** update data of the table: "platform_newspaper_rate_code" */
  update_platform_newspaper_rate_code?: Maybe<platform_newspaper_rate_code_mutation_response>;
  /** update single row of the table: "platform_newspaper_rate_code" */
  update_platform_newspaper_rate_code_by_pk?: Maybe<platform_newspaper_rate_code>;
  /** update multiples rows of table: "platform_newspaper_rate_code" */
  update_platform_newspaper_rate_code_many?: Maybe<Array<Maybe<platform_newspaper_rate_code_mutation_response>>>;
  /** update data of the table: "platform_newspaper_subscription" */
  update_platform_newspaper_subscription?: Maybe<platform_newspaper_subscription_mutation_response>;
  /** update single row of the table: "platform_newspaper_subscription" */
  update_platform_newspaper_subscription_by_pk?: Maybe<platform_newspaper_subscription>;
  /** update multiples rows of table: "platform_newspaper_subscription" */
  update_platform_newspaper_subscription_many?: Maybe<Array<Maybe<platform_newspaper_subscription_mutation_response>>>;
  /** update data of the table: "platform_newspaper_subscription_stop_date" */
  update_platform_newspaper_subscription_stop_date?: Maybe<platform_newspaper_subscription_stop_date_mutation_response>;
  /** update multiples rows of table: "platform_newspaper_subscription_stop_date" */
  update_platform_newspaper_subscription_stop_date_many?: Maybe<Array<Maybe<platform_newspaper_subscription_stop_date_mutation_response>>>;
  /** update data of the table: "platform_plan" */
  update_platform_plan?: Maybe<platform_plan_mutation_response>;
  /** update single row of the table: "platform_plan" */
  update_platform_plan_by_pk?: Maybe<platform_plan>;
  /** update data of the table: "platform_plan_ecommerce_product" */
  update_platform_plan_ecommerce_product?: Maybe<platform_plan_ecommerce_product_mutation_response>;
  /** update single row of the table: "platform_plan_ecommerce_product" */
  update_platform_plan_ecommerce_product_by_pk?: Maybe<platform_plan_ecommerce_product>;
  /** update multiples rows of table: "platform_plan_ecommerce_product" */
  update_platform_plan_ecommerce_product_many?: Maybe<Array<Maybe<platform_plan_ecommerce_product_mutation_response>>>;
  /** update data of the table: "platform_plan_group" */
  update_platform_plan_group?: Maybe<platform_plan_group_mutation_response>;
  /** update single row of the table: "platform_plan_group" */
  update_platform_plan_group_by_pk?: Maybe<platform_plan_group>;
  /** update multiples rows of table: "platform_plan_group" */
  update_platform_plan_group_many?: Maybe<Array<Maybe<platform_plan_group_mutation_response>>>;
  /** update data of the table: "platform_plan_interval" */
  update_platform_plan_interval?: Maybe<platform_plan_interval_mutation_response>;
  /** update single row of the table: "platform_plan_interval" */
  update_platform_plan_interval_by_pk?: Maybe<platform_plan_interval>;
  /** update multiples rows of table: "platform_plan_interval" */
  update_platform_plan_interval_many?: Maybe<Array<Maybe<platform_plan_interval_mutation_response>>>;
  /** update multiples rows of table: "platform_plan" */
  update_platform_plan_many?: Maybe<Array<Maybe<platform_plan_mutation_response>>>;
  /** update data of the table: "platform_plan_pricing_model" */
  update_platform_plan_pricing_model?: Maybe<platform_plan_pricing_model_mutation_response>;
  /** update single row of the table: "platform_plan_pricing_model" */
  update_platform_plan_pricing_model_by_pk?: Maybe<platform_plan_pricing_model>;
  /** update multiples rows of table: "platform_plan_pricing_model" */
  update_platform_plan_pricing_model_many?: Maybe<Array<Maybe<platform_plan_pricing_model_mutation_response>>>;
  /** update data of the table: "platform_plan_tier" */
  update_platform_plan_tier?: Maybe<platform_plan_tier_mutation_response>;
  /** update single row of the table: "platform_plan_tier" */
  update_platform_plan_tier_by_pk?: Maybe<platform_plan_tier>;
  /** update multiples rows of table: "platform_plan_tier" */
  update_platform_plan_tier_many?: Maybe<Array<Maybe<platform_plan_tier_mutation_response>>>;
  /** update data of the table: "platform_product" */
  update_platform_product?: Maybe<platform_product_mutation_response>;
  /** update single row of the table: "platform_product" */
  update_platform_product_by_pk?: Maybe<platform_product>;
  /** update data of the table: "platform_product_group" */
  update_platform_product_group?: Maybe<platform_product_group_mutation_response>;
  /** update single row of the table: "platform_product_group" */
  update_platform_product_group_by_pk?: Maybe<platform_product_group>;
  /** update multiples rows of table: "platform_product_group" */
  update_platform_product_group_many?: Maybe<Array<Maybe<platform_product_group_mutation_response>>>;
  /** update multiples rows of table: "platform_product" */
  update_platform_product_many?: Maybe<Array<Maybe<platform_product_mutation_response>>>;
  /** update data of the table: "platform_raw_object" */
  update_platform_raw_object?: Maybe<platform_raw_object_mutation_response>;
  /** update single row of the table: "platform_raw_object" */
  update_platform_raw_object_by_pk?: Maybe<platform_raw_object>;
  /** update multiples rows of table: "platform_raw_object" */
  update_platform_raw_object_many?: Maybe<Array<Maybe<platform_raw_object_mutation_response>>>;
  /** update data of the table: "platform_raw_object_type" */
  update_platform_raw_object_type?: Maybe<platform_raw_object_type_mutation_response>;
  /** update single row of the table: "platform_raw_object_type" */
  update_platform_raw_object_type_by_pk?: Maybe<platform_raw_object_type>;
  /** update multiples rows of table: "platform_raw_object_type" */
  update_platform_raw_object_type_many?: Maybe<Array<Maybe<platform_raw_object_type_mutation_response>>>;
  /** update data of the table: "platform_refund" */
  update_platform_refund?: Maybe<platform_refund_mutation_response>;
  /** update single row of the table: "platform_refund" */
  update_platform_refund_by_pk?: Maybe<platform_refund>;
  /** update multiples rows of table: "platform_refund" */
  update_platform_refund_many?: Maybe<Array<Maybe<platform_refund_mutation_response>>>;
  /** update data of the table: "platform_subscription" */
  update_platform_subscription?: Maybe<platform_subscription_mutation_response>;
  /** update data of the table: "platform_subscription_addon" */
  update_platform_subscription_addon?: Maybe<platform_subscription_addon_mutation_response>;
  /** update single row of the table: "platform_subscription_addon" */
  update_platform_subscription_addon_by_pk?: Maybe<platform_subscription_addon>;
  /** update multiples rows of table: "platform_subscription_addon" */
  update_platform_subscription_addon_many?: Maybe<Array<Maybe<platform_subscription_addon_mutation_response>>>;
  /** update single row of the table: "platform_subscription" */
  update_platform_subscription_by_pk?: Maybe<platform_subscription>;
  /** update multiples rows of table: "platform_subscription" */
  update_platform_subscription_many?: Maybe<Array<Maybe<platform_subscription_mutation_response>>>;
  /** update data of the table: "platform_subscription_next_billing_at" */
  update_platform_subscription_next_billing_at?: Maybe<platform_subscription_next_billing_at_mutation_response>;
  /** update multiples rows of table: "platform_subscription_next_billing_at" */
  update_platform_subscription_next_billing_at_many?: Maybe<Array<Maybe<platform_subscription_next_billing_at_mutation_response>>>;
  /** update data of the table: "platform_subscription_plan" */
  update_platform_subscription_plan?: Maybe<platform_subscription_plan_mutation_response>;
  /** update single row of the table: "platform_subscription_plan" */
  update_platform_subscription_plan_by_pk?: Maybe<platform_subscription_plan>;
  /** update multiples rows of table: "platform_subscription_plan" */
  update_platform_subscription_plan_many?: Maybe<Array<Maybe<platform_subscription_plan_mutation_response>>>;
  /** update data of the table: "platform_subscription_plan_swappable_ecommerce_product" */
  update_platform_subscription_plan_swappable_ecommerce_product?: Maybe<platform_subscription_plan_swappable_ecommerce_product_mutation_response>;
  /** update single row of the table: "platform_subscription_plan_swappable_ecommerce_product" */
  update_platform_subscription_plan_swappable_ecommerce_product_by_pk?: Maybe<platform_subscription_plan_swappable_ecommerce_product>;
  /** update multiples rows of table: "platform_subscription_plan_swappable_ecommerce_product" */
  update_platform_subscription_plan_swappable_ecommerce_product_many?: Maybe<Array<Maybe<platform_subscription_plan_swappable_ecommerce_product_mutation_response>>>;
  /** update data of the table: "platform_subscription_status" */
  update_platform_subscription_status?: Maybe<platform_subscription_status_mutation_response>;
  /** update single row of the table: "platform_subscription_status" */
  update_platform_subscription_status_by_pk?: Maybe<platform_subscription_status>;
  /** update multiples rows of table: "platform_subscription_status" */
  update_platform_subscription_status_many?: Maybe<Array<Maybe<platform_subscription_status_mutation_response>>>;
  /** update data of the table: "platform_variant" */
  update_platform_variant?: Maybe<platform_variant_mutation_response>;
  /** update single row of the table: "platform_variant" */
  update_platform_variant_by_pk?: Maybe<platform_variant>;
  /** update multiples rows of table: "platform_variant" */
  update_platform_variant_many?: Maybe<Array<Maybe<platform_variant_mutation_response>>>;
  /** update data of the table: "platform_variant_option" */
  update_platform_variant_option?: Maybe<platform_variant_option_mutation_response>;
  /** update single row of the table: "platform_variant_option" */
  update_platform_variant_option_by_pk?: Maybe<platform_variant_option>;
  /** update multiples rows of table: "platform_variant_option" */
  update_platform_variant_option_many?: Maybe<Array<Maybe<platform_variant_option_mutation_response>>>;
  /** update data of the table: "portal_email_code" */
  update_portal_email_code?: Maybe<portal_email_code_mutation_response>;
  /** update single row of the table: "portal_email_code" */
  update_portal_email_code_by_pk?: Maybe<portal_email_code>;
  /** update multiples rows of table: "portal_email_code" */
  update_portal_email_code_many?: Maybe<Array<Maybe<portal_email_code_mutation_response>>>;
  /** update data of the table: "portal_session" */
  update_portal_session?: Maybe<portal_session_mutation_response>;
  /** update single row of the table: "portal_session" */
  update_portal_session_by_pk?: Maybe<portal_session>;
  /** update multiples rows of table: "portal_session" */
  update_portal_session_many?: Maybe<Array<Maybe<portal_session_mutation_response>>>;
  /** update data of the table: "portal_session_subscriber" */
  update_portal_session_subscriber?: Maybe<portal_session_subscriber_mutation_response>;
  /** update single row of the table: "portal_session_subscriber" */
  update_portal_session_subscriber_by_pk?: Maybe<portal_session_subscriber>;
  /** update multiples rows of table: "portal_session_subscriber" */
  update_portal_session_subscriber_many?: Maybe<Array<Maybe<portal_session_subscriber_mutation_response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<product_mutation_response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<product_mutation_response>>>;
  /** update data of the table: "property" */
  update_property?: Maybe<property_mutation_response>;
  /** update single row of the table: "property" */
  update_property_by_pk?: Maybe<property>;
  /** update data of the table: "property_entity" */
  update_property_entity?: Maybe<property_entity_mutation_response>;
  /** update single row of the table: "property_entity" */
  update_property_entity_by_pk?: Maybe<property_entity>;
  /** update multiples rows of table: "property_entity" */
  update_property_entity_many?: Maybe<Array<Maybe<property_entity_mutation_response>>>;
  /** update data of the table: "property_format" */
  update_property_format?: Maybe<property_format_mutation_response>;
  /** update single row of the table: "property_format" */
  update_property_format_by_pk?: Maybe<property_format>;
  /** update multiples rows of table: "property_format" */
  update_property_format_many?: Maybe<Array<Maybe<property_format_mutation_response>>>;
  /** update multiples rows of table: "property" */
  update_property_many?: Maybe<Array<Maybe<property_mutation_response>>>;
  /** update data of the table: "property_type" */
  update_property_type?: Maybe<property_type_mutation_response>;
  /** update single row of the table: "property_type" */
  update_property_type_by_pk?: Maybe<property_type>;
  /** update multiples rows of table: "property_type" */
  update_property_type_many?: Maybe<Array<Maybe<property_type_mutation_response>>>;
  /** update data of the table: "question" */
  update_question?: Maybe<question_mutation_response>;
  /** update data of the table: "question_answer" */
  update_question_answer?: Maybe<question_answer_mutation_response>;
  /** update single row of the table: "question_answer" */
  update_question_answer_by_pk?: Maybe<question_answer>;
  /** update data of the table: "question_answer_likelihood_to_return" */
  update_question_answer_likelihood_to_return?: Maybe<question_answer_likelihood_to_return_mutation_response>;
  /** update single row of the table: "question_answer_likelihood_to_return" */
  update_question_answer_likelihood_to_return_by_pk?: Maybe<question_answer_likelihood_to_return>;
  /** update multiples rows of table: "question_answer_likelihood_to_return" */
  update_question_answer_likelihood_to_return_many?: Maybe<Array<Maybe<question_answer_likelihood_to_return_mutation_response>>>;
  /** update multiples rows of table: "question_answer" */
  update_question_answer_many?: Maybe<Array<Maybe<question_answer_mutation_response>>>;
  /** update data of the table: "question_answer_radio" */
  update_question_answer_radio?: Maybe<question_answer_radio_mutation_response>;
  /** update single row of the table: "question_answer_radio" */
  update_question_answer_radio_by_pk?: Maybe<question_answer_radio>;
  /** update multiples rows of table: "question_answer_radio" */
  update_question_answer_radio_many?: Maybe<Array<Maybe<question_answer_radio_mutation_response>>>;
  /** update data of the table: "question_answer_sentiment_phrase" */
  update_question_answer_sentiment_phrase?: Maybe<question_answer_sentiment_phrase_mutation_response>;
  /** update single row of the table: "question_answer_sentiment_phrase" */
  update_question_answer_sentiment_phrase_by_pk?: Maybe<question_answer_sentiment_phrase>;
  /** update multiples rows of table: "question_answer_sentiment_phrase" */
  update_question_answer_sentiment_phrase_many?: Maybe<Array<Maybe<question_answer_sentiment_phrase_mutation_response>>>;
  /** update data of the table: "question_answer_text" */
  update_question_answer_text?: Maybe<question_answer_text_mutation_response>;
  /** update single row of the table: "question_answer_text" */
  update_question_answer_text_by_pk?: Maybe<question_answer_text>;
  /** update multiples rows of table: "question_answer_text" */
  update_question_answer_text_many?: Maybe<Array<Maybe<question_answer_text_mutation_response>>>;
  /** update single row of the table: "question" */
  update_question_by_pk?: Maybe<question>;
  /** update multiples rows of table: "question" */
  update_question_many?: Maybe<Array<Maybe<question_mutation_response>>>;
  /** update data of the table: "question_option" */
  update_question_option?: Maybe<question_option_mutation_response>;
  /** update single row of the table: "question_option" */
  update_question_option_by_pk?: Maybe<question_option>;
  /** update multiples rows of table: "question_option" */
  update_question_option_many?: Maybe<Array<Maybe<question_option_mutation_response>>>;
  /** update data of the table: "question_option_version" */
  update_question_option_version?: Maybe<question_option_version_mutation_response>;
  /** update single row of the table: "question_option_version" */
  update_question_option_version_by_pk?: Maybe<question_option_version>;
  /** update multiples rows of table: "question_option_version" */
  update_question_option_version_many?: Maybe<Array<Maybe<question_option_version_mutation_response>>>;
  /** update data of the table: "question_setting" */
  update_question_setting?: Maybe<question_setting_mutation_response>;
  /** update single row of the table: "question_setting" */
  update_question_setting_by_pk?: Maybe<question_setting>;
  /** update data of the table: "question_setting_key" */
  update_question_setting_key?: Maybe<question_setting_key_mutation_response>;
  /** update single row of the table: "question_setting_key" */
  update_question_setting_key_by_pk?: Maybe<question_setting_key>;
  /** update multiples rows of table: "question_setting_key" */
  update_question_setting_key_many?: Maybe<Array<Maybe<question_setting_key_mutation_response>>>;
  /** update multiples rows of table: "question_setting" */
  update_question_setting_many?: Maybe<Array<Maybe<question_setting_mutation_response>>>;
  /** update data of the table: "question_setting_version" */
  update_question_setting_version?: Maybe<question_setting_version_mutation_response>;
  /** update single row of the table: "question_setting_version" */
  update_question_setting_version_by_pk?: Maybe<question_setting_version>;
  /** update multiples rows of table: "question_setting_version" */
  update_question_setting_version_many?: Maybe<Array<Maybe<question_setting_version_mutation_response>>>;
  /** update data of the table: "question_type" */
  update_question_type?: Maybe<question_type_mutation_response>;
  /** update single row of the table: "question_type" */
  update_question_type_by_pk?: Maybe<question_type>;
  /** update multiples rows of table: "question_type" */
  update_question_type_many?: Maybe<Array<Maybe<question_type_mutation_response>>>;
  /** update data of the table: "question_version" */
  update_question_version?: Maybe<question_version_mutation_response>;
  /** update single row of the table: "question_version" */
  update_question_version_by_pk?: Maybe<question_version>;
  /** update multiples rows of table: "question_version" */
  update_question_version_many?: Maybe<Array<Maybe<question_version_mutation_response>>>;
  /** update data of the table: "report_recurring_revenue" */
  update_report_recurring_revenue?: Maybe<report_recurring_revenue_mutation_response>;
  /** update single row of the table: "report_recurring_revenue" */
  update_report_recurring_revenue_by_pk?: Maybe<report_recurring_revenue>;
  /** update multiples rows of table: "report_recurring_revenue" */
  update_report_recurring_revenue_many?: Maybe<Array<Maybe<report_recurring_revenue_mutation_response>>>;
  /** update data of the table: "revenue_log" */
  update_revenue_log?: Maybe<revenue_log_mutation_response>;
  /** update single row of the table: "revenue_log" */
  update_revenue_log_by_pk?: Maybe<revenue_log>;
  /** update multiples rows of table: "revenue_log" */
  update_revenue_log_many?: Maybe<Array<Maybe<revenue_log_mutation_response>>>;
  /** update data of the table: "revenue_log_revenue_type" */
  update_revenue_log_revenue_type?: Maybe<revenue_log_revenue_type_mutation_response>;
  /** update single row of the table: "revenue_log_revenue_type" */
  update_revenue_log_revenue_type_by_pk?: Maybe<revenue_log_revenue_type>;
  /** update multiples rows of table: "revenue_log_revenue_type" */
  update_revenue_log_revenue_type_many?: Maybe<Array<Maybe<revenue_log_revenue_type_mutation_response>>>;
  /** update data of the table: "revenue_log_type" */
  update_revenue_log_type?: Maybe<revenue_log_type_mutation_response>;
  /** update single row of the table: "revenue_log_type" */
  update_revenue_log_type_by_pk?: Maybe<revenue_log_type>;
  /** update multiples rows of table: "revenue_log_type" */
  update_revenue_log_type_many?: Maybe<Array<Maybe<revenue_log_type_mutation_response>>>;
  /** update data of the table: "rule_group_mode" */
  update_rule_group_mode?: Maybe<rule_group_mode_mutation_response>;
  /** update single row of the table: "rule_group_mode" */
  update_rule_group_mode_by_pk?: Maybe<rule_group_mode>;
  /** update multiples rows of table: "rule_group_mode" */
  update_rule_group_mode_many?: Maybe<Array<Maybe<rule_group_mode_mutation_response>>>;
  /** update data of the table: "script_log" */
  update_script_log?: Maybe<script_log_mutation_response>;
  /** update single row of the table: "script_log" */
  update_script_log_by_pk?: Maybe<script_log>;
  /** update multiples rows of table: "script_log" */
  update_script_log_many?: Maybe<Array<Maybe<script_log_mutation_response>>>;
  /** update data of the table: "segment" */
  update_segment?: Maybe<segment_mutation_response>;
  /** update single row of the table: "segment" */
  update_segment_by_pk?: Maybe<segment>;
  /** update data of the table: "segment_condition" */
  update_segment_condition?: Maybe<segment_condition_mutation_response>;
  /** update data of the table: "segment_condition_boolean_operator" */
  update_segment_condition_boolean_operator?: Maybe<segment_condition_boolean_operator_mutation_response>;
  /** update single row of the table: "segment_condition_boolean_operator" */
  update_segment_condition_boolean_operator_by_pk?: Maybe<segment_condition_boolean_operator>;
  /** update multiples rows of table: "segment_condition_boolean_operator" */
  update_segment_condition_boolean_operator_many?: Maybe<Array<Maybe<segment_condition_boolean_operator_mutation_response>>>;
  /** update single row of the table: "segment_condition" */
  update_segment_condition_by_pk?: Maybe<segment_condition>;
  /** update data of the table: "segment_condition_group" */
  update_segment_condition_group?: Maybe<segment_condition_group_mutation_response>;
  /** update single row of the table: "segment_condition_group" */
  update_segment_condition_group_by_pk?: Maybe<segment_condition_group>;
  /** update data of the table: "segment_condition_group_entry" */
  update_segment_condition_group_entry?: Maybe<segment_condition_group_entry_mutation_response>;
  /** update single row of the table: "segment_condition_group_entry" */
  update_segment_condition_group_entry_by_pk?: Maybe<segment_condition_group_entry>;
  /** update multiples rows of table: "segment_condition_group_entry" */
  update_segment_condition_group_entry_many?: Maybe<Array<Maybe<segment_condition_group_entry_mutation_response>>>;
  /** update multiples rows of table: "segment_condition_group" */
  update_segment_condition_group_many?: Maybe<Array<Maybe<segment_condition_group_mutation_response>>>;
  /** update multiples rows of table: "segment_condition" */
  update_segment_condition_many?: Maybe<Array<Maybe<segment_condition_mutation_response>>>;
  /** update data of the table: "segment_condition_operator" */
  update_segment_condition_operator?: Maybe<segment_condition_operator_mutation_response>;
  /** update single row of the table: "segment_condition_operator" */
  update_segment_condition_operator_by_pk?: Maybe<segment_condition_operator>;
  /** update multiples rows of table: "segment_condition_operator" */
  update_segment_condition_operator_many?: Maybe<Array<Maybe<segment_condition_operator_mutation_response>>>;
  /** update data of the table: "segment_condition_property" */
  update_segment_condition_property?: Maybe<segment_condition_property_mutation_response>;
  /** update single row of the table: "segment_condition_property" */
  update_segment_condition_property_by_pk?: Maybe<segment_condition_property>;
  /** update multiples rows of table: "segment_condition_property" */
  update_segment_condition_property_many?: Maybe<Array<Maybe<segment_condition_property_mutation_response>>>;
  /** update data of the table: "segment_group" */
  update_segment_group?: Maybe<segment_group_mutation_response>;
  /** update single row of the table: "segment_group" */
  update_segment_group_by_pk?: Maybe<segment_group>;
  /** update multiples rows of table: "segment_group" */
  update_segment_group_many?: Maybe<Array<Maybe<segment_group_mutation_response>>>;
  /** update data of the table: "segment_group_operator" */
  update_segment_group_operator?: Maybe<segment_group_operator_mutation_response>;
  /** update single row of the table: "segment_group_operator" */
  update_segment_group_operator_by_pk?: Maybe<segment_group_operator>;
  /** update multiples rows of table: "segment_group_operator" */
  update_segment_group_operator_many?: Maybe<Array<Maybe<segment_group_operator_mutation_response>>>;
  /** update data of the table: "segment_group_segment" */
  update_segment_group_segment?: Maybe<segment_group_segment_mutation_response>;
  /** update single row of the table: "segment_group_segment" */
  update_segment_group_segment_by_pk?: Maybe<segment_group_segment>;
  /** update multiples rows of table: "segment_group_segment" */
  update_segment_group_segment_many?: Maybe<Array<Maybe<segment_group_segment_mutation_response>>>;
  /** update multiples rows of table: "segment" */
  update_segment_many?: Maybe<Array<Maybe<segment_mutation_response>>>;
  /** update data of the table: "segment_subscription_cache" */
  update_segment_subscription_cache?: Maybe<segment_subscription_cache_mutation_response>;
  /** update single row of the table: "segment_subscription_cache" */
  update_segment_subscription_cache_by_pk?: Maybe<segment_subscription_cache>;
  /** update multiples rows of table: "segment_subscription_cache" */
  update_segment_subscription_cache_many?: Maybe<Array<Maybe<segment_subscription_cache_mutation_response>>>;
  /** update data of the table: "sentiment" */
  update_sentiment?: Maybe<sentiment_mutation_response>;
  /** update single row of the table: "sentiment" */
  update_sentiment_by_pk?: Maybe<sentiment>;
  /** update multiples rows of table: "sentiment" */
  update_sentiment_many?: Maybe<Array<Maybe<sentiment_mutation_response>>>;
  /** update data of the table: "sentiment_phrase" */
  update_sentiment_phrase?: Maybe<sentiment_phrase_mutation_response>;
  /** update single row of the table: "sentiment_phrase" */
  update_sentiment_phrase_by_pk?: Maybe<sentiment_phrase>;
  /** update multiples rows of table: "sentiment_phrase" */
  update_sentiment_phrase_many?: Maybe<Array<Maybe<sentiment_phrase_mutation_response>>>;
  /** update data of the table: "sentiment_phrase_normalized" */
  update_sentiment_phrase_normalized?: Maybe<sentiment_phrase_normalized_mutation_response>;
  /** update single row of the table: "sentiment_phrase_normalized" */
  update_sentiment_phrase_normalized_by_pk?: Maybe<sentiment_phrase_normalized>;
  /** update multiples rows of table: "sentiment_phrase_normalized" */
  update_sentiment_phrase_normalized_many?: Maybe<Array<Maybe<sentiment_phrase_normalized_mutation_response>>>;
  /** update data of the table: "state" */
  update_state?: Maybe<state_mutation_response>;
  /** update single row of the table: "state" */
  update_state_by_pk?: Maybe<state>;
  /** update multiples rows of table: "state" */
  update_state_many?: Maybe<Array<Maybe<state_mutation_response>>>;
  /** update data of the table: "state_name" */
  update_state_name?: Maybe<state_name_mutation_response>;
  /** update single row of the table: "state_name" */
  update_state_name_by_pk?: Maybe<state_name>;
  /** update multiples rows of table: "state_name" */
  update_state_name_many?: Maybe<Array<Maybe<state_name_mutation_response>>>;
  /** update data of the table: "stripe_app" */
  update_stripe_app?: Maybe<stripe_app_mutation_response>;
  /** update single row of the table: "stripe_app" */
  update_stripe_app_by_pk?: Maybe<stripe_app>;
  /** update multiples rows of table: "stripe_app" */
  update_stripe_app_many?: Maybe<Array<Maybe<stripe_app_mutation_response>>>;
  /** update data of the table: "subscriber" */
  update_subscriber?: Maybe<subscriber_mutation_response>;
  /** update data of the table: "subscriber_acknowledgement" */
  update_subscriber_acknowledgement?: Maybe<subscriber_acknowledgement_mutation_response>;
  /** update single row of the table: "subscriber_acknowledgement" */
  update_subscriber_acknowledgement_by_pk?: Maybe<subscriber_acknowledgement>;
  /** update multiples rows of table: "subscriber_acknowledgement" */
  update_subscriber_acknowledgement_many?: Maybe<Array<Maybe<subscriber_acknowledgement_mutation_response>>>;
  /** update data of the table: "subscriber_arr" */
  update_subscriber_arr?: Maybe<subscriber_arr_mutation_response>;
  /** update multiples rows of table: "subscriber_arr" */
  update_subscriber_arr_many?: Maybe<Array<Maybe<subscriber_arr_mutation_response>>>;
  /** update single row of the table: "subscriber" */
  update_subscriber_by_pk?: Maybe<subscriber>;
  /** update data of the table: "subscriber_campaign" */
  update_subscriber_campaign?: Maybe<subscriber_campaign_mutation_response>;
  /** update single row of the table: "subscriber_campaign" */
  update_subscriber_campaign_by_pk?: Maybe<subscriber_campaign>;
  /** update multiples rows of table: "subscriber_campaign" */
  update_subscriber_campaign_many?: Maybe<Array<Maybe<subscriber_campaign_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_offer" */
  update_subscriber_campaign_offer?: Maybe<subscriber_campaign_offer_mutation_response>;
  /** update single row of the table: "subscriber_campaign_offer" */
  update_subscriber_campaign_offer_by_pk?: Maybe<subscriber_campaign_offer>;
  /** update multiples rows of table: "subscriber_campaign_offer" */
  update_subscriber_campaign_offer_many?: Maybe<Array<Maybe<subscriber_campaign_offer_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_offer_rule" */
  update_subscriber_campaign_offer_rule?: Maybe<subscriber_campaign_offer_rule_mutation_response>;
  /** update single row of the table: "subscriber_campaign_offer_rule" */
  update_subscriber_campaign_offer_rule_by_pk?: Maybe<subscriber_campaign_offer_rule>;
  /** update multiples rows of table: "subscriber_campaign_offer_rule" */
  update_subscriber_campaign_offer_rule_many?: Maybe<Array<Maybe<subscriber_campaign_offer_rule_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_override" */
  update_subscriber_campaign_override?: Maybe<subscriber_campaign_override_mutation_response>;
  /** update single row of the table: "subscriber_campaign_override" */
  update_subscriber_campaign_override_by_pk?: Maybe<subscriber_campaign_override>;
  /** update multiples rows of table: "subscriber_campaign_override" */
  update_subscriber_campaign_override_many?: Maybe<Array<Maybe<subscriber_campaign_override_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_segment" */
  update_subscriber_campaign_segment?: Maybe<subscriber_campaign_segment_mutation_response>;
  /** update single row of the table: "subscriber_campaign_segment" */
  update_subscriber_campaign_segment_by_pk?: Maybe<subscriber_campaign_segment>;
  /** update multiples rows of table: "subscriber_campaign_segment" */
  update_subscriber_campaign_segment_many?: Maybe<Array<Maybe<subscriber_campaign_segment_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_status" */
  update_subscriber_campaign_status?: Maybe<subscriber_campaign_status_mutation_response>;
  /** update single row of the table: "subscriber_campaign_status" */
  update_subscriber_campaign_status_by_pk?: Maybe<subscriber_campaign_status>;
  /** update multiples rows of table: "subscriber_campaign_status" */
  update_subscriber_campaign_status_many?: Maybe<Array<Maybe<subscriber_campaign_status_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_view" */
  update_subscriber_campaign_view?: Maybe<subscriber_campaign_view_mutation_response>;
  /** update single row of the table: "subscriber_campaign_view" */
  update_subscriber_campaign_view_by_pk?: Maybe<subscriber_campaign_view>;
  /** update multiples rows of table: "subscriber_campaign_view" */
  update_subscriber_campaign_view_many?: Maybe<Array<Maybe<subscriber_campaign_view_mutation_response>>>;
  /** update data of the table: "subscriber_campaign_view_status" */
  update_subscriber_campaign_view_status?: Maybe<subscriber_campaign_view_status_mutation_response>;
  /** update single row of the table: "subscriber_campaign_view_status" */
  update_subscriber_campaign_view_status_by_pk?: Maybe<subscriber_campaign_view_status>;
  /** update multiples rows of table: "subscriber_campaign_view_status" */
  update_subscriber_campaign_view_status_many?: Maybe<Array<Maybe<subscriber_campaign_view_status_mutation_response>>>;
  /** update data of the table: "subscriber_flow" */
  update_subscriber_flow?: Maybe<subscriber_flow_mutation_response>;
  /** update single row of the table: "subscriber_flow" */
  update_subscriber_flow_by_pk?: Maybe<subscriber_flow>;
  /** update data of the table: "subscriber_flow_deflection" */
  update_subscriber_flow_deflection?: Maybe<subscriber_flow_deflection_mutation_response>;
  /** update single row of the table: "subscriber_flow_deflection" */
  update_subscriber_flow_deflection_by_pk?: Maybe<subscriber_flow_deflection>;
  /** update multiples rows of table: "subscriber_flow_deflection" */
  update_subscriber_flow_deflection_many?: Maybe<Array<Maybe<subscriber_flow_deflection_mutation_response>>>;
  /** update data of the table: "subscriber_flow_export" */
  update_subscriber_flow_export?: Maybe<subscriber_flow_export_mutation_response>;
  /** update single row of the table: "subscriber_flow_export" */
  update_subscriber_flow_export_by_pk?: Maybe<subscriber_flow_export>;
  /** update multiples rows of table: "subscriber_flow_export" */
  update_subscriber_flow_export_many?: Maybe<Array<Maybe<subscriber_flow_export_mutation_response>>>;
  /** update data of the table: "subscriber_flow_flow_step" */
  update_subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step_mutation_response>;
  /** update single row of the table: "subscriber_flow_flow_step" */
  update_subscriber_flow_flow_step_by_pk?: Maybe<subscriber_flow_flow_step>;
  /** update multiples rows of table: "subscriber_flow_flow_step" */
  update_subscriber_flow_flow_step_many?: Maybe<Array<Maybe<subscriber_flow_flow_step_mutation_response>>>;
  /** update multiples rows of table: "subscriber_flow" */
  update_subscriber_flow_many?: Maybe<Array<Maybe<subscriber_flow_mutation_response>>>;
  /** update data of the table: "subscriber_flow_offer" */
  update_subscriber_flow_offer?: Maybe<subscriber_flow_offer_mutation_response>;
  /** update single row of the table: "subscriber_flow_offer" */
  update_subscriber_flow_offer_by_pk?: Maybe<subscriber_flow_offer>;
  /** update data of the table: "subscriber_flow_offer_group" */
  update_subscriber_flow_offer_group?: Maybe<subscriber_flow_offer_group_mutation_response>;
  /** update single row of the table: "subscriber_flow_offer_group" */
  update_subscriber_flow_offer_group_by_pk?: Maybe<subscriber_flow_offer_group>;
  /** update multiples rows of table: "subscriber_flow_offer_group" */
  update_subscriber_flow_offer_group_many?: Maybe<Array<Maybe<subscriber_flow_offer_group_mutation_response>>>;
  /** update data of the table: "subscriber_flow_offer_group_offer" */
  update_subscriber_flow_offer_group_offer?: Maybe<subscriber_flow_offer_group_offer_mutation_response>;
  /** update single row of the table: "subscriber_flow_offer_group_offer" */
  update_subscriber_flow_offer_group_offer_by_pk?: Maybe<subscriber_flow_offer_group_offer>;
  /** update multiples rows of table: "subscriber_flow_offer_group_offer" */
  update_subscriber_flow_offer_group_offer_many?: Maybe<Array<Maybe<subscriber_flow_offer_group_offer_mutation_response>>>;
  /** update multiples rows of table: "subscriber_flow_offer" */
  update_subscriber_flow_offer_many?: Maybe<Array<Maybe<subscriber_flow_offer_mutation_response>>>;
  /** update data of the table: "subscriber_flow_offer_rule" */
  update_subscriber_flow_offer_rule?: Maybe<subscriber_flow_offer_rule_mutation_response>;
  /** update single row of the table: "subscriber_flow_offer_rule" */
  update_subscriber_flow_offer_rule_by_pk?: Maybe<subscriber_flow_offer_rule>;
  /** update multiples rows of table: "subscriber_flow_offer_rule" */
  update_subscriber_flow_offer_rule_many?: Maybe<Array<Maybe<subscriber_flow_offer_rule_mutation_response>>>;
  /** update data of the table: "subscriber_flow_offer_status" */
  update_subscriber_flow_offer_status?: Maybe<subscriber_flow_offer_status_mutation_response>;
  /** update single row of the table: "subscriber_flow_offer_status" */
  update_subscriber_flow_offer_status_by_pk?: Maybe<subscriber_flow_offer_status>;
  /** update multiples rows of table: "subscriber_flow_offer_status" */
  update_subscriber_flow_offer_status_many?: Maybe<Array<Maybe<subscriber_flow_offer_status_mutation_response>>>;
  /** update data of the table: "subscriber_flow_on_cancel_override" */
  update_subscriber_flow_on_cancel_override?: Maybe<subscriber_flow_on_cancel_override_mutation_response>;
  /** update single row of the table: "subscriber_flow_on_cancel_override" */
  update_subscriber_flow_on_cancel_override_by_pk?: Maybe<subscriber_flow_on_cancel_override>;
  /** update multiples rows of table: "subscriber_flow_on_cancel_override" */
  update_subscriber_flow_on_cancel_override_many?: Maybe<Array<Maybe<subscriber_flow_on_cancel_override_mutation_response>>>;
  /** update data of the table: "subscriber_flow_override" */
  update_subscriber_flow_override?: Maybe<subscriber_flow_override_mutation_response>;
  /** update single row of the table: "subscriber_flow_override" */
  update_subscriber_flow_override_by_pk?: Maybe<subscriber_flow_override>;
  /** update multiples rows of table: "subscriber_flow_override" */
  update_subscriber_flow_override_many?: Maybe<Array<Maybe<subscriber_flow_override_mutation_response>>>;
  /** update data of the table: "subscriber_flow_override_reason" */
  update_subscriber_flow_override_reason?: Maybe<subscriber_flow_override_reason_mutation_response>;
  /** update single row of the table: "subscriber_flow_override_reason" */
  update_subscriber_flow_override_reason_by_pk?: Maybe<subscriber_flow_override_reason>;
  /** update multiples rows of table: "subscriber_flow_override_reason" */
  update_subscriber_flow_override_reason_many?: Maybe<Array<Maybe<subscriber_flow_override_reason_mutation_response>>>;
  /** update data of the table: "subscriber_flow_question_answer" */
  update_subscriber_flow_question_answer?: Maybe<subscriber_flow_question_answer_mutation_response>;
  /** update single row of the table: "subscriber_flow_question_answer" */
  update_subscriber_flow_question_answer_by_pk?: Maybe<subscriber_flow_question_answer>;
  /** update multiples rows of table: "subscriber_flow_question_answer" */
  update_subscriber_flow_question_answer_many?: Maybe<Array<Maybe<subscriber_flow_question_answer_mutation_response>>>;
  /** update data of the table: "subscriber_flow_segment" */
  update_subscriber_flow_segment?: Maybe<subscriber_flow_segment_mutation_response>;
  /** update single row of the table: "subscriber_flow_segment" */
  update_subscriber_flow_segment_by_pk?: Maybe<subscriber_flow_segment>;
  /** update data of the table: "subscriber_flow_segment_group" */
  update_subscriber_flow_segment_group?: Maybe<subscriber_flow_segment_group_mutation_response>;
  /** update single row of the table: "subscriber_flow_segment_group" */
  update_subscriber_flow_segment_group_by_pk?: Maybe<subscriber_flow_segment_group>;
  /** update multiples rows of table: "subscriber_flow_segment_group" */
  update_subscriber_flow_segment_group_many?: Maybe<Array<Maybe<subscriber_flow_segment_group_mutation_response>>>;
  /** update multiples rows of table: "subscriber_flow_segment" */
  update_subscriber_flow_segment_many?: Maybe<Array<Maybe<subscriber_flow_segment_mutation_response>>>;
  /** update data of the table: "subscriber_flow_status" */
  update_subscriber_flow_status?: Maybe<subscriber_flow_status_mutation_response>;
  /** update single row of the table: "subscriber_flow_status" */
  update_subscriber_flow_status_by_pk?: Maybe<subscriber_flow_status>;
  /** update multiples rows of table: "subscriber_flow_status" */
  update_subscriber_flow_status_many?: Maybe<Array<Maybe<subscriber_flow_status_mutation_response>>>;
  /** update data of the table: "subscriber_log" */
  update_subscriber_log?: Maybe<subscriber_log_mutation_response>;
  /** update single row of the table: "subscriber_log" */
  update_subscriber_log_by_pk?: Maybe<subscriber_log>;
  /** update data of the table: "subscriber_log_event" */
  update_subscriber_log_event?: Maybe<subscriber_log_event_mutation_response>;
  /** update single row of the table: "subscriber_log_event" */
  update_subscriber_log_event_by_pk?: Maybe<subscriber_log_event>;
  /** update multiples rows of table: "subscriber_log_event" */
  update_subscriber_log_event_many?: Maybe<Array<Maybe<subscriber_log_event_mutation_response>>>;
  /** update multiples rows of table: "subscriber_log" */
  update_subscriber_log_many?: Maybe<Array<Maybe<subscriber_log_mutation_response>>>;
  /** update data of the table: "subscriber_log_offer" */
  update_subscriber_log_offer?: Maybe<subscriber_log_offer_mutation_response>;
  /** update single row of the table: "subscriber_log_offer" */
  update_subscriber_log_offer_by_pk?: Maybe<subscriber_log_offer>;
  /** update data of the table: "subscriber_log_offer_group" */
  update_subscriber_log_offer_group?: Maybe<subscriber_log_offer_group_mutation_response>;
  /** update single row of the table: "subscriber_log_offer_group" */
  update_subscriber_log_offer_group_by_pk?: Maybe<subscriber_log_offer_group>;
  /** update multiples rows of table: "subscriber_log_offer_group" */
  update_subscriber_log_offer_group_many?: Maybe<Array<Maybe<subscriber_log_offer_group_mutation_response>>>;
  /** update multiples rows of table: "subscriber_log_offer" */
  update_subscriber_log_offer_many?: Maybe<Array<Maybe<subscriber_log_offer_mutation_response>>>;
  /** update multiples rows of table: "subscriber" */
  update_subscriber_many?: Maybe<Array<Maybe<subscriber_mutation_response>>>;
  /** update data of the table: "subscriber_mrr" */
  update_subscriber_mrr?: Maybe<subscriber_mrr_mutation_response>;
  /** update multiples rows of table: "subscriber_mrr" */
  update_subscriber_mrr_many?: Maybe<Array<Maybe<subscriber_mrr_mutation_response>>>;
  /** update data of the table: "subscriber_property" */
  update_subscriber_property?: Maybe<subscriber_property_mutation_response>;
  /** update single row of the table: "subscriber_property" */
  update_subscriber_property_by_pk?: Maybe<subscriber_property>;
  /** update multiples rows of table: "subscriber_property" */
  update_subscriber_property_many?: Maybe<Array<Maybe<subscriber_property_mutation_response>>>;
  /** update data of the table: "subscriber_property_value" */
  update_subscriber_property_value?: Maybe<subscriber_property_value_mutation_response>;
  /** update multiples rows of table: "subscriber_property_value" */
  update_subscriber_property_value_many?: Maybe<Array<Maybe<subscriber_property_value_mutation_response>>>;
  /** update data of the table: "subscriber_status" */
  update_subscriber_status?: Maybe<subscriber_status_mutation_response>;
  /** update single row of the table: "subscriber_status" */
  update_subscriber_status_by_pk?: Maybe<subscriber_status>;
  /** update multiples rows of table: "subscriber_status" */
  update_subscriber_status_many?: Maybe<Array<Maybe<subscriber_status_mutation_response>>>;
  /** update data of the table: "subscription" */
  update_subscription?: Maybe<subscription_mutation_response>;
  /** update data of the table: "subscription_arr" */
  update_subscription_arr?: Maybe<subscription_arr_mutation_response>;
  /** update multiples rows of table: "subscription_arr" */
  update_subscription_arr_many?: Maybe<Array<Maybe<subscription_arr_mutation_response>>>;
  /** update single row of the table: "subscription" */
  update_subscription_by_pk?: Maybe<subscription>;
  /** update multiples rows of table: "subscription" */
  update_subscription_many?: Maybe<Array<Maybe<subscription_mutation_response>>>;
  /** update data of the table: "subscription_product" */
  update_subscription_product?: Maybe<subscription_product_mutation_response>;
  /** update single row of the table: "subscription_product" */
  update_subscription_product_by_pk?: Maybe<subscription_product>;
  /** update multiples rows of table: "subscription_product" */
  update_subscription_product_many?: Maybe<Array<Maybe<subscription_product_mutation_response>>>;
  /** update data of the table: "subscription_property" */
  update_subscription_property?: Maybe<subscription_property_mutation_response>;
  /** update single row of the table: "subscription_property" */
  update_subscription_property_by_pk?: Maybe<subscription_property>;
  /** update multiples rows of table: "subscription_property" */
  update_subscription_property_many?: Maybe<Array<Maybe<subscription_property_mutation_response>>>;
  /** update data of the table: "subscription_property_value" */
  update_subscription_property_value?: Maybe<subscription_property_value_mutation_response>;
  /** update multiples rows of table: "subscription_property_value" */
  update_subscription_property_value_many?: Maybe<Array<Maybe<subscription_property_value_mutation_response>>>;
  /** update data of the table: "subscription_segment" */
  update_subscription_segment?: Maybe<subscription_segment_mutation_response>;
  /** update single row of the table: "subscription_segment" */
  update_subscription_segment_by_pk?: Maybe<subscription_segment>;
  /** update multiples rows of table: "subscription_segment" */
  update_subscription_segment_many?: Maybe<Array<Maybe<subscription_segment_mutation_response>>>;
  /** update data of the table: "subscription_segment_status" */
  update_subscription_segment_status?: Maybe<subscription_segment_status_mutation_response>;
  /** update single row of the table: "subscription_segment_status" */
  update_subscription_segment_status_by_pk?: Maybe<subscription_segment_status>;
  /** update multiples rows of table: "subscription_segment_status" */
  update_subscription_segment_status_many?: Maybe<Array<Maybe<subscription_segment_status_mutation_response>>>;
  /** update data of the table: "subscription_status" */
  update_subscription_status?: Maybe<subscription_status_mutation_response>;
  /** update single row of the table: "subscription_status" */
  update_subscription_status_by_pk?: Maybe<subscription_status>;
  /** update multiples rows of table: "subscription_status" */
  update_subscription_status_many?: Maybe<Array<Maybe<subscription_status_mutation_response>>>;
  /** update data of the table: "survey" */
  update_survey?: Maybe<survey_mutation_response>;
  /** update single row of the table: "survey" */
  update_survey_by_pk?: Maybe<survey>;
  /** update multiples rows of table: "survey" */
  update_survey_many?: Maybe<Array<Maybe<survey_mutation_response>>>;
  /** update data of the table: "tag" */
  update_tag?: Maybe<tag_mutation_response>;
  /** update single row of the table: "tag" */
  update_tag_by_pk?: Maybe<tag>;
  /** update multiples rows of table: "tag" */
  update_tag_many?: Maybe<Array<Maybe<tag_mutation_response>>>;
  /** update data of the table: "template_report_deflection_trends" */
  update_template_report_deflection_trends?: Maybe<template_report_deflection_trends_mutation_response>;
  /** update single row of the table: "template_report_deflection_trends" */
  update_template_report_deflection_trends_by_pk?: Maybe<template_report_deflection_trends>;
  /** update multiples rows of table: "template_report_deflection_trends" */
  update_template_report_deflection_trends_many?: Maybe<Array<Maybe<template_report_deflection_trends_mutation_response>>>;
  /** update data of the table: "template_report_feedback_trends" */
  update_template_report_feedback_trends?: Maybe<template_report_feedback_trends_mutation_response>;
  /** update single row of the table: "template_report_feedback_trends" */
  update_template_report_feedback_trends_by_pk?: Maybe<template_report_feedback_trends>;
  /** update multiples rows of table: "template_report_feedback_trends" */
  update_template_report_feedback_trends_many?: Maybe<Array<Maybe<template_report_feedback_trends_mutation_response>>>;
  /** update data of the table: "template_report_offer_accept_rate" */
  update_template_report_offer_accept_rate?: Maybe<template_report_offer_accept_rate_mutation_response>;
  /** update single row of the table: "template_report_offer_accept_rate" */
  update_template_report_offer_accept_rate_by_pk?: Maybe<template_report_offer_accept_rate>;
  /** update multiples rows of table: "template_report_offer_accept_rate" */
  update_template_report_offer_accept_rate_many?: Maybe<Array<Maybe<template_report_offer_accept_rate_mutation_response>>>;
  /** update data of the table: "template_report_offer_group_accept_rate" */
  update_template_report_offer_group_accept_rate?: Maybe<template_report_offer_group_accept_rate_mutation_response>;
  /** update single row of the table: "template_report_offer_group_accept_rate" */
  update_template_report_offer_group_accept_rate_by_pk?: Maybe<template_report_offer_group_accept_rate>;
  /** update multiples rows of table: "template_report_offer_group_accept_rate" */
  update_template_report_offer_group_accept_rate_many?: Maybe<Array<Maybe<template_report_offer_group_accept_rate_mutation_response>>>;
  /** update data of the table: "template_report_offer_group_retained_revenue" */
  update_template_report_offer_group_retained_revenue?: Maybe<template_report_offer_group_retained_revenue_mutation_response>;
  /** update single row of the table: "template_report_offer_group_retained_revenue" */
  update_template_report_offer_group_retained_revenue_by_pk?: Maybe<template_report_offer_group_retained_revenue>;
  /** update multiples rows of table: "template_report_offer_group_retained_revenue" */
  update_template_report_offer_group_retained_revenue_many?: Maybe<Array<Maybe<template_report_offer_group_retained_revenue_mutation_response>>>;
  /** update data of the table: "template_report_offer_rate" */
  update_template_report_offer_rate?: Maybe<template_report_offer_rate_mutation_response>;
  /** update single row of the table: "template_report_offer_rate" */
  update_template_report_offer_rate_by_pk?: Maybe<template_report_offer_rate>;
  /** update multiples rows of table: "template_report_offer_rate" */
  update_template_report_offer_rate_many?: Maybe<Array<Maybe<template_report_offer_rate_mutation_response>>>;
  /** update data of the table: "template_report_offer_resolution" */
  update_template_report_offer_resolution?: Maybe<template_report_offer_resolution_mutation_response>;
  /** update single row of the table: "template_report_offer_resolution" */
  update_template_report_offer_resolution_by_pk?: Maybe<template_report_offer_resolution>;
  /** update multiples rows of table: "template_report_offer_resolution" */
  update_template_report_offer_resolution_many?: Maybe<Array<Maybe<template_report_offer_resolution_mutation_response>>>;
  /** update data of the table: "template_report_offer_retained_revenue" */
  update_template_report_offer_retained_revenue?: Maybe<template_report_offer_retained_revenue_mutation_response>;
  /** update single row of the table: "template_report_offer_retained_revenue" */
  update_template_report_offer_retained_revenue_by_pk?: Maybe<template_report_offer_retained_revenue>;
  /** update multiples rows of table: "template_report_offer_retained_revenue" */
  update_template_report_offer_retained_revenue_many?: Maybe<Array<Maybe<template_report_offer_retained_revenue_mutation_response>>>;
  /** update data of the table: "template_report_question_answer_radio" */
  update_template_report_question_answer_radio?: Maybe<template_report_question_answer_radio_mutation_response>;
  /** update single row of the table: "template_report_question_answer_radio" */
  update_template_report_question_answer_radio_by_pk?: Maybe<template_report_question_answer_radio>;
  /** update data of the table: "template_report_question_answer_radio_inactive_mrr" */
  update_template_report_question_answer_radio_inactive_mrr?: Maybe<template_report_question_answer_radio_inactive_mrr_mutation_response>;
  /** update single row of the table: "template_report_question_answer_radio_inactive_mrr" */
  update_template_report_question_answer_radio_inactive_mrr_by_pk?: Maybe<template_report_question_answer_radio_inactive_mrr>;
  /** update multiples rows of table: "template_report_question_answer_radio_inactive_mrr" */
  update_template_report_question_answer_radio_inactive_mrr_many?: Maybe<Array<Maybe<template_report_question_answer_radio_inactive_mrr_mutation_response>>>;
  /** update multiples rows of table: "template_report_question_answer_radio" */
  update_template_report_question_answer_radio_many?: Maybe<Array<Maybe<template_report_question_answer_radio_mutation_response>>>;
  /** update data of the table: "template_report_question_answer_sentiment" */
  update_template_report_question_answer_sentiment?: Maybe<template_report_question_answer_sentiment_mutation_response>;
  /** update single row of the table: "template_report_question_answer_sentiment" */
  update_template_report_question_answer_sentiment_by_pk?: Maybe<template_report_question_answer_sentiment>;
  /** update multiples rows of table: "template_report_question_answer_sentiment" */
  update_template_report_question_answer_sentiment_many?: Maybe<Array<Maybe<template_report_question_answer_sentiment_mutation_response>>>;
  /** update data of the table: "template_report_retained_revenue" */
  update_template_report_retained_revenue?: Maybe<template_report_retained_revenue_mutation_response>;
  /** update single row of the table: "template_report_retained_revenue" */
  update_template_report_retained_revenue_by_pk?: Maybe<template_report_retained_revenue>;
  /** update multiples rows of table: "template_report_retained_revenue" */
  update_template_report_retained_revenue_many?: Maybe<Array<Maybe<template_report_retained_revenue_mutation_response>>>;
  /** update data of the table: "template_report_retention_trends" */
  update_template_report_retention_trends?: Maybe<template_report_retention_trends_mutation_response>;
  /** update single row of the table: "template_report_retention_trends" */
  update_template_report_retention_trends_by_pk?: Maybe<template_report_retention_trends>;
  /** update multiples rows of table: "template_report_retention_trends" */
  update_template_report_retention_trends_many?: Maybe<Array<Maybe<template_report_retention_trends_mutation_response>>>;
  /** update data of the table: "template_report_subscriber_flow_status" */
  update_template_report_subscriber_flow_status?: Maybe<template_report_subscriber_flow_status_mutation_response>;
  /** update single row of the table: "template_report_subscriber_flow_status" */
  update_template_report_subscriber_flow_status_by_pk?: Maybe<template_report_subscriber_flow_status>;
  /** update multiples rows of table: "template_report_subscriber_flow_status" */
  update_template_report_subscriber_flow_status_many?: Maybe<Array<Maybe<template_report_subscriber_flow_status_mutation_response>>>;
  /** update data of the table: "translation" */
  update_translation?: Maybe<translation_mutation_response>;
  /** update single row of the table: "translation" */
  update_translation_by_pk?: Maybe<translation>;
  /** update multiples rows of table: "translation" */
  update_translation_many?: Maybe<Array<Maybe<translation_mutation_response>>>;
  /** update data of the table: "translation_value" */
  update_translation_value?: Maybe<translation_value_mutation_response>;
  /** update single row of the table: "translation_value" */
  update_translation_value_by_pk?: Maybe<translation_value>;
  /** update data of the table: "translation_value_format" */
  update_translation_value_format?: Maybe<translation_value_format_mutation_response>;
  /** update single row of the table: "translation_value_format" */
  update_translation_value_format_by_pk?: Maybe<translation_value_format>;
  /** update multiples rows of table: "translation_value_format" */
  update_translation_value_format_many?: Maybe<Array<Maybe<translation_value_format_mutation_response>>>;
  /** update multiples rows of table: "translation_value" */
  update_translation_value_many?: Maybe<Array<Maybe<translation_value_mutation_response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<user_mutation_response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<user>;
  /** update data of the table: "user_invitation" */
  update_user_invitation?: Maybe<user_invitation_mutation_response>;
  /** update single row of the table: "user_invitation" */
  update_user_invitation_by_pk?: Maybe<user_invitation>;
  /** update multiples rows of table: "user_invitation" */
  update_user_invitation_many?: Maybe<Array<Maybe<user_invitation_mutation_response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<user_mutation_response>>>;
  /** update data of the table: "user_profile" */
  update_user_profile?: Maybe<user_profile_mutation_response>;
  /** update single row of the table: "user_profile" */
  update_user_profile_by_pk?: Maybe<user_profile>;
  /** update multiples rows of table: "user_profile" */
  update_user_profile_many?: Maybe<Array<Maybe<user_profile_mutation_response>>>;
  /** update data of the table: "webhook" */
  update_webhook?: Maybe<webhook_mutation_response>;
  /** update single row of the table: "webhook" */
  update_webhook_by_pk?: Maybe<webhook>;
  /** update data of the table: "webhook_event" */
  update_webhook_event?: Maybe<webhook_event_mutation_response>;
  /** update single row of the table: "webhook_event" */
  update_webhook_event_by_pk?: Maybe<webhook_event>;
  /** update multiples rows of table: "webhook_event" */
  update_webhook_event_many?: Maybe<Array<Maybe<webhook_event_mutation_response>>>;
  /** update data of the table: "webhook_log" */
  update_webhook_log?: Maybe<webhook_log_mutation_response>;
  /** update single row of the table: "webhook_log" */
  update_webhook_log_by_pk?: Maybe<webhook_log>;
  /** update multiples rows of table: "webhook_log" */
  update_webhook_log_many?: Maybe<Array<Maybe<webhook_log_mutation_response>>>;
  /** update multiples rows of table: "webhook" */
  update_webhook_many?: Maybe<Array<Maybe<webhook_mutation_response>>>;
  /** update data of the table: "webhook_request_log" */
  update_webhook_request_log?: Maybe<webhook_request_log_mutation_response>;
  /** update single row of the table: "webhook_request_log" */
  update_webhook_request_log_by_pk?: Maybe<webhook_request_log>;
  /** update multiples rows of table: "webhook_request_log" */
  update_webhook_request_log_many?: Maybe<Array<Maybe<webhook_request_log_mutation_response>>>;
  /** update data of the table: "webhook_status" */
  update_webhook_status?: Maybe<webhook_status_mutation_response>;
  /** update single row of the table: "webhook_status" */
  update_webhook_status_by_pk?: Maybe<webhook_status>;
  /** update multiples rows of table: "webhook_status" */
  update_webhook_status_many?: Maybe<Array<Maybe<webhook_status_mutation_response>>>;
  upgradeAccount: UpgradeAccountOutput;
  verifyEmailCode: VerifyEmailCodeOutput;
};


/** mutation root */
export type mutation_rootacceptCampaignOfferArgs = {
  input: AcceptCampaignOfferInput;
};


/** mutation root */
export type mutation_rootacceptOfferArgs = {
  input: AcceptOfferInput;
};


/** mutation root */
export type mutation_rootacceptUserInvitationArgs = {
  input: AcceptUserInvitationInput;
};


/** mutation root */
export type mutation_rootadminConfigureShopifyArgs = {
  input: AdminConfigureShopifyInput;
};


/** mutation root */
export type mutation_rootadminCreateAccountArgs = {
  input: AdminCreateAccountInput;
};


/** mutation root */
export type mutation_rootadminUpdateAccountFeaturesArgs = {
  input: AdminUpdateAccountFeaturesInput;
};


/** mutation root */
export type mutation_rootadminUpdateAccountSettingsArgs = {
  input: AdminUpdateAccountSettingsInput;
};


/** mutation root */
export type mutation_rootattachPaymentMethodArgs = {
  input: AttachPaymentMethodInput;
};


/** mutation root */
export type mutation_rootchangePasswordArgs = {
  input: ChangePasswordInput;
};


/** mutation root */
export type mutation_rootclickFlowActionArgs = {
  input: ClickFlowActionInput;
};


/** mutation root */
export type mutation_rootcompleteFlowStepArgs = {
  input: CompleteFlowStepInput;
};


/** mutation root */
export type mutation_rootcompleteSubscriberCampaignViewArgs = {
  input: CompleteSubscriberCampaignViewInput;
};


/** mutation root */
export type mutation_rootcompleteSubscriberFlowArgs = {
  input: CompleteSubscriberFlowInput;
};


/** mutation root */
export type mutation_rootconnectBoldArgs = {
  input: ConnectBoldInput;
};


/** mutation root */
export type mutation_rootconnectBraintreeArgs = {
  input: ConnectBraintreeInput;
};


/** mutation root */
export type mutation_rootconnectChargebeeArgs = {
  input: ConnectChargebeeInput;
};


/** mutation root */
export type mutation_rootconnectHubSpotArgs = {
  input: ConnectHubSpotInput;
};


/** mutation root */
export type mutation_rootconnectIntercomArgs = {
  input: ConnectIntercomInput;
};


/** mutation root */
export type mutation_rootconnectKlaviyoArgs = {
  input: ConnectKlaviyoInput;
};


/** mutation root */
export type mutation_rootconnectMaxioArgs = {
  input: ConnectMaxioInput;
};


/** mutation root */
export type mutation_rootconnectNavigaArgs = {
  input: ConnectNavigaInput;
};


/** mutation root */
export type mutation_rootconnectOpenPayArgs = {
  input: ConnectOpenPayInput;
};


/** mutation root */
export type mutation_rootconnectOrdergrooveArgs = {
  input: ConnectOrdergrooveInput;
};


/** mutation root */
export type mutation_rootconnectPaddleArgs = {
  input: ConnectPaddleInput;
};


/** mutation root */
export type mutation_rootconnectRechargeArgs = {
  input: ConnectRechargeInput;
};


/** mutation root */
export type mutation_rootconnectRecurlyArgs = {
  input: ConnectRecurlyInput;
};


/** mutation root */
export type mutation_rootconnectSalesforceArgs = {
  input: ConnectSalesforceInput;
};


/** mutation root */
export type mutation_rootconnectSegmentArgs = {
  input: ConnectSegmentInput;
};


/** mutation root */
export type mutation_rootconnectShopifyArgs = {
  input: ConnectShopifyInput;
};


/** mutation root */
export type mutation_rootconnectSlackArgs = {
  input: ConnectSlackInput;
};


/** mutation root */
export type mutation_rootconnectStripeArgs = {
  input: ConnectStripeInput;
};


/** mutation root */
export type mutation_rootconnectStripeApiKeyArgs = {
  input: ConnectStripeApiKeyInput;
};


/** mutation root */
export type mutation_rootconnectStripeAppArgs = {
  input: ConnectStripeAppInput;
};


/** mutation root */
export type mutation_rootconnectZuoraArgs = {
  input: ConnectZuoraInput;
};


/** mutation root */
export type mutation_rootcreateAccountArgs = {
  input: CreateAccountInput;
};


/** mutation root */
export type mutation_rootcreateApiKeyArgs = {
  input: CreateApiKeyInput;
};


/** mutation root */
export type mutation_rootcreateCampaignArgs = {
  input: CreateCampaignInput;
};


/** mutation root */
export type mutation_rootcreateDeflectionArgs = {
  input: CreateDeflectionInput;
};


/** mutation root */
export type mutation_rootcreateFlowArgs = {
  input: CreateFlowInput;
};


/** mutation root */
export type mutation_rootcreatePortalSessionStripeSetupIntentArgs = {
  input: CreatePortalSessionStripeSetupIntentInput;
};


/** mutation root */
export type mutation_rootcreateSegmentGroupArgs = {
  input: CreateSegmentGroupInput;
};


/** mutation root */
export type mutation_rootcreateSubscriberCampaignStripeSetupIntentArgs = {
  input: CreateSubscriberCampaignStripeSetupIntentInput;
};


/** mutation root */
export type mutation_rootdeclareOfferAutopilotRoundWinnerArgs = {
  input: DeclareOfferAutopilotRoundWinnerInput;
};


/** mutation root */
export type mutation_rootdeclineOfferArgs = {
  input: DeclineOfferInput;
};


/** mutation root */
export type mutation_rootdeclineOfferGroupArgs = {
  input: DeclineOfferGroupInput;
};


/** mutation root */
export type mutation_rootdelete_accountArgs = {
  where: account_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_featureArgs = {
  where: account_feature_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_feature_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_feature_key_enum;
};


/** mutation root */
export type mutation_rootdelete_account_feature_keyArgs = {
  where: account_feature_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_feature_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_modeArgs = {
  where: account_mode_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_revenue_modeArgs = {
  where: account_revenue_mode_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_revenue_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_settingArgs = {
  where: account_setting_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_setting_key_enum;
};


/** mutation root */
export type mutation_rootdelete_account_setting_keyArgs = {
  where: account_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_statusArgs = {
  where: account_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_userArgs = {
  where: account_user_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_user_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_user_settingArgs = {
  where: account_user_setting_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_user_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_user_setting_key_enum;
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_account_user_setting_keyArgs = {
  where: account_user_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_account_user_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_acknowledgementArgs = {
  where: acknowledgement_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_groupArgs = {
  where: acknowledgement_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_group_versionArgs = {
  where: acknowledgement_group_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_versionArgs = {
  where: acknowledgement_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_acknowledgement_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_active_portal_sessionArgs = {
  where: active_portal_session_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_annotationArgs = {
  where: annotation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_annotation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_annotation_flow_versionArgs = {
  where: annotation_flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_annotation_flow_version_by_pkArgs = {
  annotation_id: Scalars['Int']['input'];
  flow_version_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_annotation_typeArgs = {
  where: annotation_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_annotation_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_api_keyArgs = {
  where: api_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_api_key_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_api_key_purposeArgs = {
  where: api_key_purpose_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_api_key_purpose_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_campaignArgs = {
  where: campaign_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_campaign_textArgs = {
  where: campaign_text_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_campaign_text_by_pkArgs = {
  campaign_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


/** mutation root */
export type mutation_rootdelete_campaign_text_versionArgs = {
  where: campaign_text_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_campaign_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_campaign_trigger_typeArgs = {
  where: campaign_trigger_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_campaign_trigger_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_campaign_versionArgs = {
  where: campaign_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_campaign_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_confirmationArgs = {
  where: confirmation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_confirmation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_confirmation_versionArgs = {
  where: confirmation_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_confirmation_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_countryArgs = {
  where: country_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_country_by_pkArgs = {
  iso: Scalars['citext']['input'];
};


/** mutation root */
export type mutation_rootdelete_coupon_durationArgs = {
  where: coupon_duration_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_coupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_coupon_duration_intervalArgs = {
  where: coupon_duration_interval_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_coupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_csv_delta_fileArgs = {
  where: csv_delta_file_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_csv_delta_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_csv_importArgs = {
  where: csv_import_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_csv_import_by_pkArgs = {
  delta_file_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_csv_import_fileArgs = {
  where: csv_import_file_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_csv_import_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_csv_import_statusArgs = {
  where: csv_import_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_csv_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_data_connectorArgs = {
  where: data_connector_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_data_connector_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_data_connector_request_logArgs = {
  where: data_connector_request_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_data_connector_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type mutation_rootdelete_deflectionArgs = {
  where: deflection_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_deflection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_deflection_snapshot_itemArgs = {
  where: deflection_snapshot_item_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_deflection_snapshot_item_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_deflection_typeArgs = {
  where: deflection_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_deflection_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_demoArgs = {
  where: demo_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_demo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_domainArgs = {
  where: domain_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_domain_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_domain_typeArgs = {
  where: domain_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_domain_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_eligibility_messageArgs = {
  where: eligibility_message_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_eligibility_message_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_eventArgs = {
  where: event_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_event_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_event_typeArgs = {
  where: event_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_event_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flowArgs = {
  where: flow_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_actionArgs = {
  where: flow_action_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_action_appearanceArgs = {
  where: flow_action_appearance_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_action_appearance_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_action_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_action_typeArgs = {
  where: flow_action_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_action_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_auto_cancel_atArgs = {
  where: flow_auto_cancel_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_auto_cancel_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_languageArgs = {
  where: flow_language_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_language_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  language: language_enum;
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancelArgs = {
  where: flow_on_cancel_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancel_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancel_change_planArgs = {
  where: flow_on_cancel_change_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancel_change_plan_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancel_perform_atArgs = {
  where: flow_on_cancel_perform_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_on_cancel_perform_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_route_ruleArgs = {
  where: flow_route_rule_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_route_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_stepArgs = {
  where: flow_step_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_acknowledgement_groupArgs = {
  where: flow_step_acknowledgement_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_acknowledgement_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_confirmationArgs = {
  where: flow_step_confirmation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_confirmation_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_deflection_rule_groupArgs = {
  where: flow_step_deflection_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_formArgs = {
  where: flow_step_form_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_form_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_interventionArgs = {
  where: flow_step_intervention_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_intervention_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_offer_rule_groupArgs = {
  where: flow_step_offer_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_questionArgs = {
  where: flow_step_question_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_question_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_rule_groupArgs = {
  where: flow_step_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_rule_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_typeArgs = {
  where: flow_step_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_step_versionArgs = {
  where: flow_step_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_step_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_subscriber_form_questionArgs = {
  where: flow_subscriber_form_question_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_subscriber_form_question_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_testArgs = {
  where: flow_test_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_test_flowArgs = {
  where: flow_test_flow_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_test_flow_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  flow_test_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_textArgs = {
  where: flow_text_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_text_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


/** mutation root */
export type mutation_rootdelete_flow_text_keyArgs = {
  where: flow_text_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_text_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_text_versionArgs = {
  where: flow_text_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_flow_versionArgs = {
  where: flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_flow_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_formArgs = {
  where: form_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_form_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_form_versionArgs = {
  where: form_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_form_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_function_logArgs = {
  where: function_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_function_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_integrationArgs = {
  where: integration_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_integration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_integration_typeArgs = {
  where: integration_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_integration_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_interventionArgs = {
  where: intervention_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_intervention_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_intervention_versionArgs = {
  where: intervention_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_intervention_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_jobArgs = {
  where: job_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_job_by_pkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_languageArgs = {
  where: language_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_language_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_most_recent_subscriber_flow_versionArgs = {
  where: most_recent_subscriber_flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_naviga_cancel_linkArgs = {
  where: naviga_cancel_link_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_naviga_cancel_link_by_pkArgs = {
  client_code: Scalars['String']['input'];
  link_text: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_naviga_domainArgs = {
  where: naviga_domain_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_naviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_naviga_paper_codeArgs = {
  where: naviga_paper_code_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_naviga_paper_code_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offerArgs = {
  where: offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilotArgs = {
  where: offer_autopilot_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_roundArgs = {
  where: offer_autopilot_round_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_round_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_round_logArgs = {
  where: offer_autopilot_round_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_round_log_by_pkArgs = {
  offer_autopilot_round_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_round_strategyArgs = {
  where: offer_autopilot_round_strategy_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_round_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_strategyArgs = {
  where: offer_autopilot_strategy_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_success_metricArgs = {
  where: offer_autopilot_success_metric_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_autopilot_success_metric_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_change_planArgs = {
  where: offer_change_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_change_plan_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_change_plan_change_atArgs = {
  where: offer_change_plan_change_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_change_plan_change_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_couponArgs = {
  where: offer_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_coupon_apply_toArgs = {
  where: offer_coupon_apply_to_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_coupon_typeArgs = {
  where: offer_coupon_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_customArgs = {
  where: offer_custom_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_custom_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_downgradeArgs = {
  where: offer_downgrade_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_downgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_goalArgs = {
  where: offer_goal_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_goal_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_groupArgs = {
  where: offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscriptionArgs = {
  where: offer_modify_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_modify_atArgs = {
  where: offer_modify_subscription_modify_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_modify_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_optionArgs = {
  where: offer_modify_subscription_option_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_option_typeArgs = {
  where: offer_modify_subscription_option_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_modify_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscriptionArgs = {
  where: offer_pause_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_intervalArgs = {
  where: offer_pause_subscription_interval_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_optionArgs = {
  where: offer_pause_subscription_option_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_option_typeArgs = {
  where: offer_pause_subscription_option_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_pause_atArgs = {
  where: offer_pause_subscription_pause_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_pause_subscription_pause_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_product_swapArgs = {
  where: offer_product_swap_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_product_swap_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_product_swap_platform_ecommerce_productArgs = {
  where: offer_product_swap_platform_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_product_swap_platform_ecommerce_product_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_product_swap_platform_ecommerce_product_platform_variantArgs = {
  where: offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_product_swap_platform_ecommerce_product_platform_variant_by_pkArgs = {
  offer_product_swap_platform_ecommerce_product_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_reschedule_orderArgs = {
  where: offer_reschedule_order_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_reschedule_order_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_ruleArgs = {
  where: offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_rule_groupArgs = {
  where: offer_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_rule_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_rule_group_versionArgs = {
  where: offer_rule_group_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_rule_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_rule_ruleArgs = {
  where: offer_rule_rule_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_rule_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_rule_versionArgs = {
  where: offer_rule_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_rule_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_styleArgs = {
  where: offer_style_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_style_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_testArgs = {
  where: offer_test_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_test_offerArgs = {
  where: offer_test_offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_test_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  offer_test_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_timerArgs = {
  where: offer_timer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_timer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_timer_modeArgs = {
  where: offer_timer_mode_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_timer_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_trial_extensionArgs = {
  where: offer_trial_extension_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_trial_extension_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_typeArgs = {
  where: offer_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_upgradeArgs = {
  where: offer_upgrade_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_upgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_variantArgs = {
  where: offer_variant_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_variant_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_offer_variant_couponArgs = {
  where: offer_variant_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_offer_variant_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platformArgs = {
  where: platform_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_addonArgs = {
  where: platform_addon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_addon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_addon_planArgs = {
  where: platform_addon_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_addon_plan_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_addon_priceArgs = {
  where: platform_addon_price_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_addon_price_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_addon_price_tierArgs = {
  where: platform_addon_price_tier_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_addon_price_tier_by_pkArgs = {
  platform_addon_price_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  up_to: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_api_logArgs = {
  where: platform_api_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_api_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_base_currencyArgs = {
  where: platform_base_currency_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_base_currency_by_pkArgs = {
  value: platform_currency_enum;
};


/** mutation root */
export type mutation_rootdelete_platform_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_cancel_reasonArgs = {
  where: platform_cancel_reason_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_cancel_reason_by_pkArgs = {
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_connectionArgs = {
  where: platform_connection_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_connection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_connection_naviga_domainArgs = {
  where: platform_connection_naviga_domain_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_connection_naviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_connection_naviga_paper_code_endpointArgs = {
  where: platform_connection_naviga_paper_code_endpoint_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_connection_naviga_paper_code_endpoint_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_connection_shopify_storeArgs = {
  where: platform_connection_shopify_store_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_connection_shopify_store_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_couponArgs = {
  where: platform_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_addonArgs = {
  where: platform_coupon_addon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_apply_toArgs = {
  where: platform_coupon_apply_to_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_apply_to_restrictionArgs = {
  where: platform_coupon_apply_to_restriction_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_apply_to_restriction_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_durationArgs = {
  where: platform_coupon_duration_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_duration_intervalArgs = {
  where: platform_coupon_duration_interval_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_planArgs = {
  where: platform_coupon_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_productArgs = {
  where: platform_coupon_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_product_groupArgs = {
  where: platform_coupon_product_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_group_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_typeArgs = {
  where: platform_coupon_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_credentialsArgs = {
  where: platform_credentials_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_credentials_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  platform: platform_enum;
};


/** mutation root */
export type mutation_rootdelete_platform_currencyArgs = {
  where: platform_currency_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_currency_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_currency_exchange_rateArgs = {
  where: platform_currency_exchange_rate_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_currency_exchange_rate_by_pkArgs = {
  base: platform_base_currency_enum;
  currency: platform_currency_enum;
};


/** mutation root */
export type mutation_rootdelete_platform_customerArgs = {
  where: platform_customer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_customer_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_ecommerce_productArgs = {
  where: platform_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_importArgs = {
  where: platform_import_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_import_by_pkArgs = {
  job_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_import_statusArgs = {
  where: platform_import_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_import_stepArgs = {
  where: platform_import_step_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_import_step_by_pkArgs = {
  job_id: Scalars['String']['input'];
  type: platform_import_step_type_enum;
};


/** mutation root */
export type mutation_rootdelete_platform_import_step_typeArgs = {
  where: platform_import_step_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_import_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_invoiceArgs = {
  where: platform_invoice_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_invoice_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_invoice_statusArgs = {
  where: platform_invoice_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_invoice_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_newspaper_rate_codeArgs = {
  where: platform_newspaper_rate_code_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_newspaper_rate_code_by_pkArgs = {
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  rate_code: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_newspaper_subscriptionArgs = {
  where: platform_newspaper_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_newspaper_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_newspaper_subscription_stop_dateArgs = {
  where: platform_newspaper_subscription_stop_date_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_planArgs = {
  where: platform_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_plan_ecommerce_productArgs = {
  where: platform_plan_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_plan_groupArgs = {
  where: platform_plan_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_plan_intervalArgs = {
  where: platform_plan_interval_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_plan_pricing_modelArgs = {
  where: platform_plan_pricing_model_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_pricing_model_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_plan_tierArgs = {
  where: platform_plan_tier_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_plan_tier_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  up_to: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_productArgs = {
  where: platform_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_product_groupArgs = {
  where: platform_product_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_raw_objectArgs = {
  where: platform_raw_object_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_raw_object_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
  type: platform_raw_object_type_enum;
};


/** mutation root */
export type mutation_rootdelete_platform_raw_object_typeArgs = {
  where: platform_raw_object_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_raw_object_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_refundArgs = {
  where: platform_refund_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_refund_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_subscriptionArgs = {
  where: platform_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_addonArgs = {
  where: platform_subscription_addon_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_next_billing_atArgs = {
  where: platform_subscription_next_billing_at_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_planArgs = {
  where: platform_subscription_plan_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_plan_swappable_ecommerce_productArgs = {
  where: platform_subscription_plan_swappable_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_plan_swappable_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_statusArgs = {
  where: platform_subscription_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_subscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_variantArgs = {
  where: platform_variant_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_variant_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_platform_variant_optionArgs = {
  where: platform_variant_option_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_platform_variant_option_by_pkArgs = {
  key: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_portal_email_codeArgs = {
  where: portal_email_code_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_portal_email_code_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_portal_sessionArgs = {
  where: portal_session_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_portal_session_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_portal_session_subscriberArgs = {
  where: portal_session_subscriber_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_portal_session_subscriber_by_pkArgs = {
  portal_session_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_productArgs = {
  where: product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_product_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_propertyArgs = {
  where: property_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_property_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_property_entityArgs = {
  where: property_entity_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_property_entity_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_property_formatArgs = {
  where: property_format_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_property_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_property_typeArgs = {
  where: property_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_property_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_questionArgs = {
  where: question_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answerArgs = {
  where: question_answer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_answer_likelihood_to_returnArgs = {
  where: question_answer_likelihood_to_return_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answer_likelihood_to_return_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_answer_radioArgs = {
  where: question_answer_radio_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answer_radio_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_answer_sentiment_phraseArgs = {
  where: question_answer_sentiment_phrase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answer_sentiment_phrase_by_pkArgs = {
  begin_offset: Scalars['Int']['input'];
  question_answer_id: Scalars['Int']['input'];
  sentiment_phrase_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_answer_textArgs = {
  where: question_answer_text_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_answer_text_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_optionArgs = {
  where: question_option_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_option_versionArgs = {
  where: question_option_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_option_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_settingArgs = {
  where: question_setting_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_setting_by_pkArgs = {
  key: question_setting_key_enum;
  question_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_setting_keyArgs = {
  where: question_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_setting_versionArgs = {
  where: question_setting_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_setting_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_typeArgs = {
  where: question_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_question_versionArgs = {
  where: question_version_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_question_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_report_recurring_revenueArgs = {
  where: report_recurring_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_report_recurring_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['date']['input'];
};


/** mutation root */
export type mutation_rootdelete_revenue_logArgs = {
  where: revenue_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_revenue_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_revenue_log_revenue_typeArgs = {
  where: revenue_log_revenue_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_revenue_log_revenue_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_revenue_log_typeArgs = {
  where: revenue_log_type_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_revenue_log_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_rule_group_modeArgs = {
  where: rule_group_mode_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_rule_group_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_script_logArgs = {
  where: script_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_script_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segmentArgs = {
  where: segment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_conditionArgs = {
  where: segment_condition_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_boolean_operatorArgs = {
  where: segment_condition_boolean_operator_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_boolean_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_condition_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_condition_groupArgs = {
  where: segment_condition_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_condition_group_entryArgs = {
  where: segment_condition_group_entry_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_group_entry_by_pkArgs = {
  position: Scalars['Int']['input'];
  segment_condition_group_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_condition_operatorArgs = {
  where: segment_condition_operator_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_condition_propertyArgs = {
  where: segment_condition_property_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_condition_property_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_groupArgs = {
  where: segment_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_group_operatorArgs = {
  where: segment_group_operator_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_group_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_group_segmentArgs = {
  where: segment_group_segment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_group_segment_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_segment_subscription_cacheArgs = {
  where: segment_subscription_cache_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_segment_subscription_cache_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_sentimentArgs = {
  where: sentiment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_sentiment_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_sentiment_phraseArgs = {
  where: sentiment_phrase_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_sentiment_phrase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_sentiment_phrase_normalizedArgs = {
  where: sentiment_phrase_normalized_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_sentiment_phrase_normalized_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_stateArgs = {
  where: state_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_state_by_pkArgs = {
  code: Scalars['citext']['input'];
  country: Scalars['citext']['input'];
};


/** mutation root */
export type mutation_rootdelete_state_nameArgs = {
  where: state_name_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_state_name_by_pkArgs = {
  country: Scalars['citext']['input'];
  name: Scalars['citext']['input'];
};


/** mutation root */
export type mutation_rootdelete_stripe_appArgs = {
  where: stripe_app_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_stripe_app_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriberArgs = {
  where: subscriber_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_acknowledgementArgs = {
  where: subscriber_acknowledgement_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_acknowledgement_by_pkArgs = {
  acknowledgement_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_arrArgs = {
  where: subscriber_arr_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaignArgs = {
  where: subscriber_campaign_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_offerArgs = {
  where: subscriber_campaign_offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_offer_ruleArgs = {
  where: subscriber_campaign_offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_overrideArgs = {
  where: subscriber_campaign_override_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_segmentArgs = {
  where: subscriber_campaign_segment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_statusArgs = {
  where: subscriber_campaign_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_viewArgs = {
  where: subscriber_campaign_view_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_view_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_view_statusArgs = {
  where: subscriber_campaign_view_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_campaign_view_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flowArgs = {
  where: subscriber_flow_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_deflectionArgs = {
  where: subscriber_flow_deflection_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_deflection_by_pkArgs = {
  deflection_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_exportArgs = {
  where: subscriber_flow_export_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_export_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_flow_stepArgs = {
  where: subscriber_flow_flow_step_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_flow_step_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offerArgs = {
  where: subscriber_flow_offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_groupArgs = {
  where: subscriber_flow_offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_group_offerArgs = {
  where: subscriber_flow_offer_group_offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_group_offer_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_ruleArgs = {
  where: subscriber_flow_offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_statusArgs = {
  where: subscriber_flow_offer_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_offer_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_on_cancel_overrideArgs = {
  where: subscriber_flow_on_cancel_override_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_on_cancel_override_by_pkArgs = {
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_overrideArgs = {
  where: subscriber_flow_override_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_override_reasonArgs = {
  where: subscriber_flow_override_reason_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_override_reason_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_question_answerArgs = {
  where: subscriber_flow_question_answer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_question_answer_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_segmentArgs = {
  where: subscriber_flow_segment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_segment_groupArgs = {
  where: subscriber_flow_segment_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_segment_group_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_statusArgs = {
  where: subscriber_flow_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_flow_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_logArgs = {
  where: subscriber_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_eventArgs = {
  where: subscriber_log_event_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_event_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_offerArgs = {
  where: subscriber_log_offer_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_offer_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_offer_groupArgs = {
  where: subscriber_log_offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_log_offer_group_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_mrrArgs = {
  where: subscriber_mrr_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_propertyArgs = {
  where: subscriber_property_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriber_property_valueArgs = {
  where: subscriber_property_value_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_statusArgs = {
  where: subscriber_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscriber_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscriptionArgs = {
  where: subscription_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_arrArgs = {
  where: subscription_arr_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscription_productArgs = {
  where: subscription_product_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_product_by_pkArgs = {
  product_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscription_propertyArgs = {
  where: subscription_property_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscription_property_valueArgs = {
  where: subscription_property_value_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_segmentArgs = {
  where: subscription_segment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscription_segment_statusArgs = {
  where: subscription_segment_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_segment_status_by_pkArgs = {
  subscription_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_subscription_statusArgs = {
  where: subscription_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_subscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_surveyArgs = {
  where: survey_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_survey_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_tagArgs = {
  where: tag_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_tag_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_deflection_trendsArgs = {
  where: template_report_deflection_trends_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_deflection_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  flow_action_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_feedback_trendsArgs = {
  where: template_report_feedback_trends_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_feedback_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  sentiment_phrase_normalized_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_accept_rateArgs = {
  where: template_report_offer_accept_rate_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_group_accept_rateArgs = {
  where: template_report_offer_group_accept_rate_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_group_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_group_retained_revenueArgs = {
  where: template_report_offer_group_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_group_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_rateArgs = {
  where: template_report_offer_rate_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_resolutionArgs = {
  where: template_report_offer_resolution_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_resolution_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_retained_revenueArgs = {
  where: template_report_offer_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_offer_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_radioArgs = {
  where: template_report_question_answer_radio_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_radio_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_radio_inactive_mrrArgs = {
  where: template_report_question_answer_radio_inactive_mrr_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_radio_inactive_mrr_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_sentimentArgs = {
  where: template_report_question_answer_sentiment_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_question_answer_sentiment_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_retained_revenueArgs = {
  where: template_report_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_retention_trendsArgs = {
  where: template_report_retention_trends_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_retention_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_template_report_subscriber_flow_statusArgs = {
  where: template_report_subscriber_flow_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_template_report_subscriber_flow_status_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_translationArgs = {
  where: translation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_translation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_translation_valueArgs = {
  where: translation_value_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_translation_value_by_pkArgs = {
  language: language_enum;
  translation_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_translation_value_formatArgs = {
  where: translation_value_format_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_translation_value_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_userArgs = {
  where: user_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_user_by_pkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_user_invitationArgs = {
  where: user_invitation_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_user_invitation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_user_profileArgs = {
  where: user_profile_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_user_profile_by_pkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdelete_webhookArgs = {
  where: webhook_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_webhook_by_pkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_webhook_eventArgs = {
  where: webhook_event_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_webhook_event_by_pkArgs = {
  event_type: event_type_enum;
  webhook_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_webhook_logArgs = {
  where: webhook_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_webhook_log_by_pkArgs = {
  attempt: Scalars['Int']['input'];
  event_id: Scalars['Int']['input'];
  webhook_id: Scalars['Int']['input'];
};


/** mutation root */
export type mutation_rootdelete_webhook_request_logArgs = {
  where: webhook_request_log_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_webhook_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type mutation_rootdelete_webhook_statusArgs = {
  where: webhook_status_bool_exp;
};


/** mutation root */
export type mutation_rootdelete_webhook_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type mutation_rootdisconnectSegmentSourceArgs = {
  input: DisconnectSegmentSourceInput;
};


/** mutation root */
export type mutation_rootduplicateFlowArgs = {
  input: DuplicateFlowInput;
};


/** mutation root */
export type mutation_rootevaluateOfferAutopilotRoundArgs = {
  input: EvaluateOfferAutopilotRoundInput;
};


/** mutation root */
export type mutation_rootexportCancellationsArgs = {
  input: ExportCancellationsInput;
};


/** mutation root */
export type mutation_rootexportSessionsArgs = {
  input: ExportSessionsInput;
};


/** mutation root */
export type mutation_rootexportSessionsNewArgs = {
  input: ExportSessionsNewInput;
};


/** mutation root */
export type mutation_rootimportNavigaCsvArgs = {
  input: ImportNavigaCsvInput;
};


/** mutation root */
export type mutation_rootinitializeSubscriberFlowArgs = {
  input: InitializeSubscriberFlowInput;
};


/** mutation root */
export type mutation_rootinsert_accountArgs = {
  objects: Array<account_insert_input>;
  on_conflict?: InputMaybe<account_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_featureArgs = {
  objects: Array<account_feature_insert_input>;
  on_conflict?: InputMaybe<account_feature_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_feature_keyArgs = {
  objects: Array<account_feature_key_insert_input>;
  on_conflict?: InputMaybe<account_feature_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_feature_key_oneArgs = {
  object: account_feature_key_insert_input;
  on_conflict?: InputMaybe<account_feature_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_feature_oneArgs = {
  object: account_feature_insert_input;
  on_conflict?: InputMaybe<account_feature_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_modeArgs = {
  objects: Array<account_mode_insert_input>;
  on_conflict?: InputMaybe<account_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_mode_oneArgs = {
  object: account_mode_insert_input;
  on_conflict?: InputMaybe<account_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_oneArgs = {
  object: account_insert_input;
  on_conflict?: InputMaybe<account_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_revenue_modeArgs = {
  objects: Array<account_revenue_mode_insert_input>;
  on_conflict?: InputMaybe<account_revenue_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_revenue_mode_oneArgs = {
  object: account_revenue_mode_insert_input;
  on_conflict?: InputMaybe<account_revenue_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_settingArgs = {
  objects: Array<account_setting_insert_input>;
  on_conflict?: InputMaybe<account_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_setting_keyArgs = {
  objects: Array<account_setting_key_insert_input>;
  on_conflict?: InputMaybe<account_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_setting_key_oneArgs = {
  object: account_setting_key_insert_input;
  on_conflict?: InputMaybe<account_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_setting_oneArgs = {
  object: account_setting_insert_input;
  on_conflict?: InputMaybe<account_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_statusArgs = {
  objects: Array<account_status_insert_input>;
  on_conflict?: InputMaybe<account_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_status_oneArgs = {
  object: account_status_insert_input;
  on_conflict?: InputMaybe<account_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_userArgs = {
  objects: Array<account_user_insert_input>;
  on_conflict?: InputMaybe<account_user_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_user_oneArgs = {
  object: account_user_insert_input;
  on_conflict?: InputMaybe<account_user_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_user_settingArgs = {
  objects: Array<account_user_setting_insert_input>;
  on_conflict?: InputMaybe<account_user_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_user_setting_keyArgs = {
  objects: Array<account_user_setting_key_insert_input>;
  on_conflict?: InputMaybe<account_user_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_user_setting_key_oneArgs = {
  object: account_user_setting_key_insert_input;
  on_conflict?: InputMaybe<account_user_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_account_user_setting_oneArgs = {
  object: account_user_setting_insert_input;
  on_conflict?: InputMaybe<account_user_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgementArgs = {
  objects: Array<acknowledgement_insert_input>;
  on_conflict?: InputMaybe<acknowledgement_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_groupArgs = {
  objects: Array<acknowledgement_group_insert_input>;
  on_conflict?: InputMaybe<acknowledgement_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_group_oneArgs = {
  object: acknowledgement_group_insert_input;
  on_conflict?: InputMaybe<acknowledgement_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_group_versionArgs = {
  objects: Array<acknowledgement_group_version_insert_input>;
  on_conflict?: InputMaybe<acknowledgement_group_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_group_version_oneArgs = {
  object: acknowledgement_group_version_insert_input;
  on_conflict?: InputMaybe<acknowledgement_group_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_oneArgs = {
  object: acknowledgement_insert_input;
  on_conflict?: InputMaybe<acknowledgement_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_versionArgs = {
  objects: Array<acknowledgement_version_insert_input>;
  on_conflict?: InputMaybe<acknowledgement_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_acknowledgement_version_oneArgs = {
  object: acknowledgement_version_insert_input;
  on_conflict?: InputMaybe<acknowledgement_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_active_portal_sessionArgs = {
  objects: Array<active_portal_session_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_active_portal_session_oneArgs = {
  object: active_portal_session_insert_input;
};


/** mutation root */
export type mutation_rootinsert_annotationArgs = {
  objects: Array<annotation_insert_input>;
  on_conflict?: InputMaybe<annotation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_annotation_flow_versionArgs = {
  objects: Array<annotation_flow_version_insert_input>;
  on_conflict?: InputMaybe<annotation_flow_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_annotation_flow_version_oneArgs = {
  object: annotation_flow_version_insert_input;
  on_conflict?: InputMaybe<annotation_flow_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_annotation_oneArgs = {
  object: annotation_insert_input;
  on_conflict?: InputMaybe<annotation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_annotation_typeArgs = {
  objects: Array<annotation_type_insert_input>;
  on_conflict?: InputMaybe<annotation_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_annotation_type_oneArgs = {
  object: annotation_type_insert_input;
  on_conflict?: InputMaybe<annotation_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_api_keyArgs = {
  objects: Array<api_key_insert_input>;
  on_conflict?: InputMaybe<api_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_api_key_oneArgs = {
  object: api_key_insert_input;
  on_conflict?: InputMaybe<api_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_api_key_purposeArgs = {
  objects: Array<api_key_purpose_insert_input>;
  on_conflict?: InputMaybe<api_key_purpose_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_api_key_purpose_oneArgs = {
  object: api_key_purpose_insert_input;
  on_conflict?: InputMaybe<api_key_purpose_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaignArgs = {
  objects: Array<campaign_insert_input>;
  on_conflict?: InputMaybe<campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_oneArgs = {
  object: campaign_insert_input;
  on_conflict?: InputMaybe<campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_textArgs = {
  objects: Array<campaign_text_insert_input>;
  on_conflict?: InputMaybe<campaign_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_text_oneArgs = {
  object: campaign_text_insert_input;
  on_conflict?: InputMaybe<campaign_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_text_versionArgs = {
  objects: Array<campaign_text_version_insert_input>;
  on_conflict?: InputMaybe<campaign_text_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_text_version_oneArgs = {
  object: campaign_text_version_insert_input;
  on_conflict?: InputMaybe<campaign_text_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_trigger_typeArgs = {
  objects: Array<campaign_trigger_type_insert_input>;
  on_conflict?: InputMaybe<campaign_trigger_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_trigger_type_oneArgs = {
  object: campaign_trigger_type_insert_input;
  on_conflict?: InputMaybe<campaign_trigger_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_versionArgs = {
  objects: Array<campaign_version_insert_input>;
  on_conflict?: InputMaybe<campaign_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_campaign_version_oneArgs = {
  object: campaign_version_insert_input;
  on_conflict?: InputMaybe<campaign_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_confirmationArgs = {
  objects: Array<confirmation_insert_input>;
  on_conflict?: InputMaybe<confirmation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_confirmation_oneArgs = {
  object: confirmation_insert_input;
  on_conflict?: InputMaybe<confirmation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_confirmation_versionArgs = {
  objects: Array<confirmation_version_insert_input>;
  on_conflict?: InputMaybe<confirmation_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_confirmation_version_oneArgs = {
  object: confirmation_version_insert_input;
  on_conflict?: InputMaybe<confirmation_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_countryArgs = {
  objects: Array<country_insert_input>;
  on_conflict?: InputMaybe<country_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_country_oneArgs = {
  object: country_insert_input;
  on_conflict?: InputMaybe<country_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_coupon_durationArgs = {
  objects: Array<coupon_duration_insert_input>;
  on_conflict?: InputMaybe<coupon_duration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_coupon_duration_intervalArgs = {
  objects: Array<coupon_duration_interval_insert_input>;
  on_conflict?: InputMaybe<coupon_duration_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_coupon_duration_interval_oneArgs = {
  object: coupon_duration_interval_insert_input;
  on_conflict?: InputMaybe<coupon_duration_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_coupon_duration_oneArgs = {
  object: coupon_duration_insert_input;
  on_conflict?: InputMaybe<coupon_duration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_delta_fileArgs = {
  objects: Array<csv_delta_file_insert_input>;
  on_conflict?: InputMaybe<csv_delta_file_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_delta_file_oneArgs = {
  object: csv_delta_file_insert_input;
  on_conflict?: InputMaybe<csv_delta_file_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_importArgs = {
  objects: Array<csv_import_insert_input>;
  on_conflict?: InputMaybe<csv_import_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_import_fileArgs = {
  objects: Array<csv_import_file_insert_input>;
  on_conflict?: InputMaybe<csv_import_file_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_import_file_oneArgs = {
  object: csv_import_file_insert_input;
  on_conflict?: InputMaybe<csv_import_file_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_import_oneArgs = {
  object: csv_import_insert_input;
  on_conflict?: InputMaybe<csv_import_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_import_statusArgs = {
  objects: Array<csv_import_status_insert_input>;
  on_conflict?: InputMaybe<csv_import_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_csv_import_status_oneArgs = {
  object: csv_import_status_insert_input;
  on_conflict?: InputMaybe<csv_import_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_data_connectorArgs = {
  objects: Array<data_connector_insert_input>;
  on_conflict?: InputMaybe<data_connector_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_data_connector_oneArgs = {
  object: data_connector_insert_input;
  on_conflict?: InputMaybe<data_connector_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_data_connector_request_logArgs = {
  objects: Array<data_connector_request_log_insert_input>;
  on_conflict?: InputMaybe<data_connector_request_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_data_connector_request_log_oneArgs = {
  object: data_connector_request_log_insert_input;
  on_conflict?: InputMaybe<data_connector_request_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflectionArgs = {
  objects: Array<deflection_insert_input>;
  on_conflict?: InputMaybe<deflection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflection_oneArgs = {
  object: deflection_insert_input;
  on_conflict?: InputMaybe<deflection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflection_snapshot_itemArgs = {
  objects: Array<deflection_snapshot_item_insert_input>;
  on_conflict?: InputMaybe<deflection_snapshot_item_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflection_snapshot_item_oneArgs = {
  object: deflection_snapshot_item_insert_input;
  on_conflict?: InputMaybe<deflection_snapshot_item_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflection_typeArgs = {
  objects: Array<deflection_type_insert_input>;
  on_conflict?: InputMaybe<deflection_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_deflection_type_oneArgs = {
  object: deflection_type_insert_input;
  on_conflict?: InputMaybe<deflection_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_demoArgs = {
  objects: Array<demo_insert_input>;
  on_conflict?: InputMaybe<demo_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_demo_oneArgs = {
  object: demo_insert_input;
  on_conflict?: InputMaybe<demo_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_domainArgs = {
  objects: Array<domain_insert_input>;
  on_conflict?: InputMaybe<domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_domain_oneArgs = {
  object: domain_insert_input;
  on_conflict?: InputMaybe<domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_domain_typeArgs = {
  objects: Array<domain_type_insert_input>;
  on_conflict?: InputMaybe<domain_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_domain_type_oneArgs = {
  object: domain_type_insert_input;
  on_conflict?: InputMaybe<domain_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_eligibility_messageArgs = {
  objects: Array<eligibility_message_insert_input>;
  on_conflict?: InputMaybe<eligibility_message_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_eligibility_message_oneArgs = {
  object: eligibility_message_insert_input;
  on_conflict?: InputMaybe<eligibility_message_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_eventArgs = {
  objects: Array<event_insert_input>;
  on_conflict?: InputMaybe<event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_event_oneArgs = {
  object: event_insert_input;
  on_conflict?: InputMaybe<event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_event_typeArgs = {
  objects: Array<event_type_insert_input>;
  on_conflict?: InputMaybe<event_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_event_type_oneArgs = {
  object: event_type_insert_input;
  on_conflict?: InputMaybe<event_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flowArgs = {
  objects: Array<flow_insert_input>;
  on_conflict?: InputMaybe<flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_actionArgs = {
  objects: Array<flow_action_insert_input>;
  on_conflict?: InputMaybe<flow_action_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_action_appearanceArgs = {
  objects: Array<flow_action_appearance_insert_input>;
  on_conflict?: InputMaybe<flow_action_appearance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_action_appearance_oneArgs = {
  object: flow_action_appearance_insert_input;
  on_conflict?: InputMaybe<flow_action_appearance_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_action_oneArgs = {
  object: flow_action_insert_input;
  on_conflict?: InputMaybe<flow_action_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_action_typeArgs = {
  objects: Array<flow_action_type_insert_input>;
  on_conflict?: InputMaybe<flow_action_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_action_type_oneArgs = {
  object: flow_action_type_insert_input;
  on_conflict?: InputMaybe<flow_action_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_auto_cancel_atArgs = {
  objects: Array<flow_auto_cancel_at_insert_input>;
  on_conflict?: InputMaybe<flow_auto_cancel_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_auto_cancel_at_oneArgs = {
  object: flow_auto_cancel_at_insert_input;
  on_conflict?: InputMaybe<flow_auto_cancel_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_languageArgs = {
  objects: Array<flow_language_insert_input>;
  on_conflict?: InputMaybe<flow_language_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_language_oneArgs = {
  object: flow_language_insert_input;
  on_conflict?: InputMaybe<flow_language_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancelArgs = {
  objects: Array<flow_on_cancel_insert_input>;
  on_conflict?: InputMaybe<flow_on_cancel_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancel_change_planArgs = {
  objects: Array<flow_on_cancel_change_plan_insert_input>;
  on_conflict?: InputMaybe<flow_on_cancel_change_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancel_change_plan_oneArgs = {
  object: flow_on_cancel_change_plan_insert_input;
  on_conflict?: InputMaybe<flow_on_cancel_change_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancel_oneArgs = {
  object: flow_on_cancel_insert_input;
  on_conflict?: InputMaybe<flow_on_cancel_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancel_perform_atArgs = {
  objects: Array<flow_on_cancel_perform_at_insert_input>;
  on_conflict?: InputMaybe<flow_on_cancel_perform_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_on_cancel_perform_at_oneArgs = {
  object: flow_on_cancel_perform_at_insert_input;
  on_conflict?: InputMaybe<flow_on_cancel_perform_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_oneArgs = {
  object: flow_insert_input;
  on_conflict?: InputMaybe<flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_route_ruleArgs = {
  objects: Array<flow_route_rule_insert_input>;
  on_conflict?: InputMaybe<flow_route_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_route_rule_oneArgs = {
  object: flow_route_rule_insert_input;
  on_conflict?: InputMaybe<flow_route_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_stepArgs = {
  objects: Array<flow_step_insert_input>;
  on_conflict?: InputMaybe<flow_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_acknowledgement_groupArgs = {
  objects: Array<flow_step_acknowledgement_group_insert_input>;
  on_conflict?: InputMaybe<flow_step_acknowledgement_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_acknowledgement_group_oneArgs = {
  object: flow_step_acknowledgement_group_insert_input;
  on_conflict?: InputMaybe<flow_step_acknowledgement_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_confirmationArgs = {
  objects: Array<flow_step_confirmation_insert_input>;
  on_conflict?: InputMaybe<flow_step_confirmation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_confirmation_oneArgs = {
  object: flow_step_confirmation_insert_input;
  on_conflict?: InputMaybe<flow_step_confirmation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_deflection_rule_groupArgs = {
  objects: Array<flow_step_deflection_rule_group_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_deflection_rule_group_oneArgs = {
  object: flow_step_deflection_rule_group_insert_input;
};


/** mutation root */
export type mutation_rootinsert_flow_step_formArgs = {
  objects: Array<flow_step_form_insert_input>;
  on_conflict?: InputMaybe<flow_step_form_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_form_oneArgs = {
  object: flow_step_form_insert_input;
  on_conflict?: InputMaybe<flow_step_form_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_interventionArgs = {
  objects: Array<flow_step_intervention_insert_input>;
  on_conflict?: InputMaybe<flow_step_intervention_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_intervention_oneArgs = {
  object: flow_step_intervention_insert_input;
  on_conflict?: InputMaybe<flow_step_intervention_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_offer_rule_groupArgs = {
  objects: Array<flow_step_offer_rule_group_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_offer_rule_group_oneArgs = {
  object: flow_step_offer_rule_group_insert_input;
};


/** mutation root */
export type mutation_rootinsert_flow_step_oneArgs = {
  object: flow_step_insert_input;
  on_conflict?: InputMaybe<flow_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_questionArgs = {
  objects: Array<flow_step_question_insert_input>;
  on_conflict?: InputMaybe<flow_step_question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_question_oneArgs = {
  object: flow_step_question_insert_input;
  on_conflict?: InputMaybe<flow_step_question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_rule_groupArgs = {
  objects: Array<flow_step_rule_group_insert_input>;
  on_conflict?: InputMaybe<flow_step_rule_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_rule_group_oneArgs = {
  object: flow_step_rule_group_insert_input;
  on_conflict?: InputMaybe<flow_step_rule_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_typeArgs = {
  objects: Array<flow_step_type_insert_input>;
  on_conflict?: InputMaybe<flow_step_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_type_oneArgs = {
  object: flow_step_type_insert_input;
  on_conflict?: InputMaybe<flow_step_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_versionArgs = {
  objects: Array<flow_step_version_insert_input>;
  on_conflict?: InputMaybe<flow_step_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_step_version_oneArgs = {
  object: flow_step_version_insert_input;
  on_conflict?: InputMaybe<flow_step_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_subscriber_form_questionArgs = {
  objects: Array<flow_subscriber_form_question_insert_input>;
  on_conflict?: InputMaybe<flow_subscriber_form_question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_subscriber_form_question_oneArgs = {
  object: flow_subscriber_form_question_insert_input;
  on_conflict?: InputMaybe<flow_subscriber_form_question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_testArgs = {
  objects: Array<flow_test_insert_input>;
  on_conflict?: InputMaybe<flow_test_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_test_flowArgs = {
  objects: Array<flow_test_flow_insert_input>;
  on_conflict?: InputMaybe<flow_test_flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_test_flow_oneArgs = {
  object: flow_test_flow_insert_input;
  on_conflict?: InputMaybe<flow_test_flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_test_oneArgs = {
  object: flow_test_insert_input;
  on_conflict?: InputMaybe<flow_test_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_textArgs = {
  objects: Array<flow_text_insert_input>;
  on_conflict?: InputMaybe<flow_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_text_keyArgs = {
  objects: Array<flow_text_key_insert_input>;
  on_conflict?: InputMaybe<flow_text_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_text_key_oneArgs = {
  object: flow_text_key_insert_input;
  on_conflict?: InputMaybe<flow_text_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_text_oneArgs = {
  object: flow_text_insert_input;
  on_conflict?: InputMaybe<flow_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_text_versionArgs = {
  objects: Array<flow_text_version_insert_input>;
  on_conflict?: InputMaybe<flow_text_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_text_version_oneArgs = {
  object: flow_text_version_insert_input;
  on_conflict?: InputMaybe<flow_text_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_versionArgs = {
  objects: Array<flow_version_insert_input>;
  on_conflict?: InputMaybe<flow_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_flow_version_oneArgs = {
  object: flow_version_insert_input;
  on_conflict?: InputMaybe<flow_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_formArgs = {
  objects: Array<form_insert_input>;
  on_conflict?: InputMaybe<form_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_form_oneArgs = {
  object: form_insert_input;
  on_conflict?: InputMaybe<form_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_form_versionArgs = {
  objects: Array<form_version_insert_input>;
  on_conflict?: InputMaybe<form_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_form_version_oneArgs = {
  object: form_version_insert_input;
  on_conflict?: InputMaybe<form_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_function_logArgs = {
  objects: Array<function_log_insert_input>;
  on_conflict?: InputMaybe<function_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_function_log_oneArgs = {
  object: function_log_insert_input;
  on_conflict?: InputMaybe<function_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_integrationArgs = {
  objects: Array<integration_insert_input>;
  on_conflict?: InputMaybe<integration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_integration_oneArgs = {
  object: integration_insert_input;
  on_conflict?: InputMaybe<integration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_integration_typeArgs = {
  objects: Array<integration_type_insert_input>;
  on_conflict?: InputMaybe<integration_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_integration_type_oneArgs = {
  object: integration_type_insert_input;
  on_conflict?: InputMaybe<integration_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_interventionArgs = {
  objects: Array<intervention_insert_input>;
  on_conflict?: InputMaybe<intervention_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_intervention_oneArgs = {
  object: intervention_insert_input;
  on_conflict?: InputMaybe<intervention_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_intervention_versionArgs = {
  objects: Array<intervention_version_insert_input>;
  on_conflict?: InputMaybe<intervention_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_intervention_version_oneArgs = {
  object: intervention_version_insert_input;
  on_conflict?: InputMaybe<intervention_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_jobArgs = {
  objects: Array<job_insert_input>;
  on_conflict?: InputMaybe<job_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_job_oneArgs = {
  object: job_insert_input;
  on_conflict?: InputMaybe<job_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_languageArgs = {
  objects: Array<language_insert_input>;
  on_conflict?: InputMaybe<language_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_language_oneArgs = {
  object: language_insert_input;
  on_conflict?: InputMaybe<language_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_most_recent_subscriber_flow_versionArgs = {
  objects: Array<most_recent_subscriber_flow_version_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_most_recent_subscriber_flow_version_oneArgs = {
  object: most_recent_subscriber_flow_version_insert_input;
};


/** mutation root */
export type mutation_rootinsert_naviga_cancel_linkArgs = {
  objects: Array<naviga_cancel_link_insert_input>;
  on_conflict?: InputMaybe<naviga_cancel_link_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_naviga_cancel_link_oneArgs = {
  object: naviga_cancel_link_insert_input;
  on_conflict?: InputMaybe<naviga_cancel_link_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_naviga_domainArgs = {
  objects: Array<naviga_domain_insert_input>;
  on_conflict?: InputMaybe<naviga_domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_naviga_domain_oneArgs = {
  object: naviga_domain_insert_input;
  on_conflict?: InputMaybe<naviga_domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_naviga_paper_codeArgs = {
  objects: Array<naviga_paper_code_insert_input>;
  on_conflict?: InputMaybe<naviga_paper_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_naviga_paper_code_oneArgs = {
  object: naviga_paper_code_insert_input;
  on_conflict?: InputMaybe<naviga_paper_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offerArgs = {
  objects: Array<offer_insert_input>;
  on_conflict?: InputMaybe<offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilotArgs = {
  objects: Array<offer_autopilot_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_oneArgs = {
  object: offer_autopilot_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_roundArgs = {
  objects: Array<offer_autopilot_round_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_round_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_round_logArgs = {
  objects: Array<offer_autopilot_round_log_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_round_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_round_log_oneArgs = {
  object: offer_autopilot_round_log_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_round_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_round_oneArgs = {
  object: offer_autopilot_round_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_round_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_round_strategyArgs = {
  objects: Array<offer_autopilot_round_strategy_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_round_strategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_round_strategy_oneArgs = {
  object: offer_autopilot_round_strategy_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_round_strategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_strategyArgs = {
  objects: Array<offer_autopilot_strategy_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_strategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_strategy_oneArgs = {
  object: offer_autopilot_strategy_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_strategy_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_success_metricArgs = {
  objects: Array<offer_autopilot_success_metric_insert_input>;
  on_conflict?: InputMaybe<offer_autopilot_success_metric_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_autopilot_success_metric_oneArgs = {
  object: offer_autopilot_success_metric_insert_input;
  on_conflict?: InputMaybe<offer_autopilot_success_metric_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_change_planArgs = {
  objects: Array<offer_change_plan_insert_input>;
  on_conflict?: InputMaybe<offer_change_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_change_plan_change_atArgs = {
  objects: Array<offer_change_plan_change_at_insert_input>;
  on_conflict?: InputMaybe<offer_change_plan_change_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_change_plan_change_at_oneArgs = {
  object: offer_change_plan_change_at_insert_input;
  on_conflict?: InputMaybe<offer_change_plan_change_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_change_plan_oneArgs = {
  object: offer_change_plan_insert_input;
  on_conflict?: InputMaybe<offer_change_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_couponArgs = {
  objects: Array<offer_coupon_insert_input>;
  on_conflict?: InputMaybe<offer_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_coupon_apply_toArgs = {
  objects: Array<offer_coupon_apply_to_insert_input>;
  on_conflict?: InputMaybe<offer_coupon_apply_to_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_coupon_apply_to_oneArgs = {
  object: offer_coupon_apply_to_insert_input;
  on_conflict?: InputMaybe<offer_coupon_apply_to_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_coupon_oneArgs = {
  object: offer_coupon_insert_input;
  on_conflict?: InputMaybe<offer_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_coupon_typeArgs = {
  objects: Array<offer_coupon_type_insert_input>;
  on_conflict?: InputMaybe<offer_coupon_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_coupon_type_oneArgs = {
  object: offer_coupon_type_insert_input;
  on_conflict?: InputMaybe<offer_coupon_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_customArgs = {
  objects: Array<offer_custom_insert_input>;
  on_conflict?: InputMaybe<offer_custom_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_custom_oneArgs = {
  object: offer_custom_insert_input;
  on_conflict?: InputMaybe<offer_custom_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_downgradeArgs = {
  objects: Array<offer_downgrade_insert_input>;
  on_conflict?: InputMaybe<offer_downgrade_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_downgrade_oneArgs = {
  object: offer_downgrade_insert_input;
  on_conflict?: InputMaybe<offer_downgrade_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_goalArgs = {
  objects: Array<offer_goal_insert_input>;
  on_conflict?: InputMaybe<offer_goal_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_goal_oneArgs = {
  object: offer_goal_insert_input;
  on_conflict?: InputMaybe<offer_goal_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_groupArgs = {
  objects: Array<offer_group_insert_input>;
  on_conflict?: InputMaybe<offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_group_oneArgs = {
  object: offer_group_insert_input;
  on_conflict?: InputMaybe<offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscriptionArgs = {
  objects: Array<offer_modify_subscription_insert_input>;
  on_conflict?: InputMaybe<offer_modify_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_modify_atArgs = {
  objects: Array<offer_modify_subscription_modify_at_insert_input>;
  on_conflict?: InputMaybe<offer_modify_subscription_modify_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_modify_at_oneArgs = {
  object: offer_modify_subscription_modify_at_insert_input;
  on_conflict?: InputMaybe<offer_modify_subscription_modify_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_oneArgs = {
  object: offer_modify_subscription_insert_input;
  on_conflict?: InputMaybe<offer_modify_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_optionArgs = {
  objects: Array<offer_modify_subscription_option_insert_input>;
  on_conflict?: InputMaybe<offer_modify_subscription_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_option_oneArgs = {
  object: offer_modify_subscription_option_insert_input;
  on_conflict?: InputMaybe<offer_modify_subscription_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_option_typeArgs = {
  objects: Array<offer_modify_subscription_option_type_insert_input>;
  on_conflict?: InputMaybe<offer_modify_subscription_option_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_modify_subscription_option_type_oneArgs = {
  object: offer_modify_subscription_option_type_insert_input;
  on_conflict?: InputMaybe<offer_modify_subscription_option_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_oneArgs = {
  object: offer_insert_input;
  on_conflict?: InputMaybe<offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscriptionArgs = {
  objects: Array<offer_pause_subscription_insert_input>;
  on_conflict?: InputMaybe<offer_pause_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_intervalArgs = {
  objects: Array<offer_pause_subscription_interval_insert_input>;
  on_conflict?: InputMaybe<offer_pause_subscription_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_interval_oneArgs = {
  object: offer_pause_subscription_interval_insert_input;
  on_conflict?: InputMaybe<offer_pause_subscription_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_oneArgs = {
  object: offer_pause_subscription_insert_input;
  on_conflict?: InputMaybe<offer_pause_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_optionArgs = {
  objects: Array<offer_pause_subscription_option_insert_input>;
  on_conflict?: InputMaybe<offer_pause_subscription_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_option_oneArgs = {
  object: offer_pause_subscription_option_insert_input;
  on_conflict?: InputMaybe<offer_pause_subscription_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_option_typeArgs = {
  objects: Array<offer_pause_subscription_option_type_insert_input>;
  on_conflict?: InputMaybe<offer_pause_subscription_option_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_option_type_oneArgs = {
  object: offer_pause_subscription_option_type_insert_input;
  on_conflict?: InputMaybe<offer_pause_subscription_option_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_pause_atArgs = {
  objects: Array<offer_pause_subscription_pause_at_insert_input>;
  on_conflict?: InputMaybe<offer_pause_subscription_pause_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_pause_subscription_pause_at_oneArgs = {
  object: offer_pause_subscription_pause_at_insert_input;
  on_conflict?: InputMaybe<offer_pause_subscription_pause_at_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swapArgs = {
  objects: Array<offer_product_swap_insert_input>;
  on_conflict?: InputMaybe<offer_product_swap_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swap_oneArgs = {
  object: offer_product_swap_insert_input;
  on_conflict?: InputMaybe<offer_product_swap_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swap_platform_ecommerce_productArgs = {
  objects: Array<offer_product_swap_platform_ecommerce_product_insert_input>;
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swap_platform_ecommerce_product_oneArgs = {
  object: offer_product_swap_platform_ecommerce_product_insert_input;
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swap_platform_ecommerce_product_platform_variantArgs = {
  objects: Array<offer_product_swap_platform_ecommerce_product_platform_variant_insert_input>;
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_product_swap_platform_ecommerce_product_platform_variant_oneArgs = {
  object: offer_product_swap_platform_ecommerce_product_platform_variant_insert_input;
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_reschedule_orderArgs = {
  objects: Array<offer_reschedule_order_insert_input>;
  on_conflict?: InputMaybe<offer_reschedule_order_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_reschedule_order_oneArgs = {
  object: offer_reschedule_order_insert_input;
  on_conflict?: InputMaybe<offer_reschedule_order_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_ruleArgs = {
  objects: Array<offer_rule_insert_input>;
  on_conflict?: InputMaybe<offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_groupArgs = {
  objects: Array<offer_rule_group_insert_input>;
  on_conflict?: InputMaybe<offer_rule_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_group_oneArgs = {
  object: offer_rule_group_insert_input;
  on_conflict?: InputMaybe<offer_rule_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_group_versionArgs = {
  objects: Array<offer_rule_group_version_insert_input>;
  on_conflict?: InputMaybe<offer_rule_group_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_group_version_oneArgs = {
  object: offer_rule_group_version_insert_input;
  on_conflict?: InputMaybe<offer_rule_group_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_oneArgs = {
  object: offer_rule_insert_input;
  on_conflict?: InputMaybe<offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_ruleArgs = {
  objects: Array<offer_rule_rule_insert_input>;
  on_conflict?: InputMaybe<offer_rule_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_rule_oneArgs = {
  object: offer_rule_rule_insert_input;
  on_conflict?: InputMaybe<offer_rule_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_versionArgs = {
  objects: Array<offer_rule_version_insert_input>;
  on_conflict?: InputMaybe<offer_rule_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_rule_version_oneArgs = {
  object: offer_rule_version_insert_input;
  on_conflict?: InputMaybe<offer_rule_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_styleArgs = {
  objects: Array<offer_style_insert_input>;
  on_conflict?: InputMaybe<offer_style_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_style_oneArgs = {
  object: offer_style_insert_input;
  on_conflict?: InputMaybe<offer_style_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_testArgs = {
  objects: Array<offer_test_insert_input>;
  on_conflict?: InputMaybe<offer_test_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_test_offerArgs = {
  objects: Array<offer_test_offer_insert_input>;
  on_conflict?: InputMaybe<offer_test_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_test_offer_oneArgs = {
  object: offer_test_offer_insert_input;
  on_conflict?: InputMaybe<offer_test_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_test_oneArgs = {
  object: offer_test_insert_input;
  on_conflict?: InputMaybe<offer_test_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_timerArgs = {
  objects: Array<offer_timer_insert_input>;
  on_conflict?: InputMaybe<offer_timer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_timer_modeArgs = {
  objects: Array<offer_timer_mode_insert_input>;
  on_conflict?: InputMaybe<offer_timer_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_timer_mode_oneArgs = {
  object: offer_timer_mode_insert_input;
  on_conflict?: InputMaybe<offer_timer_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_timer_oneArgs = {
  object: offer_timer_insert_input;
  on_conflict?: InputMaybe<offer_timer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_trial_extensionArgs = {
  objects: Array<offer_trial_extension_insert_input>;
  on_conflict?: InputMaybe<offer_trial_extension_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_trial_extension_oneArgs = {
  object: offer_trial_extension_insert_input;
  on_conflict?: InputMaybe<offer_trial_extension_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_typeArgs = {
  objects: Array<offer_type_insert_input>;
  on_conflict?: InputMaybe<offer_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_type_oneArgs = {
  object: offer_type_insert_input;
  on_conflict?: InputMaybe<offer_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_upgradeArgs = {
  objects: Array<offer_upgrade_insert_input>;
  on_conflict?: InputMaybe<offer_upgrade_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_upgrade_oneArgs = {
  object: offer_upgrade_insert_input;
  on_conflict?: InputMaybe<offer_upgrade_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_variantArgs = {
  objects: Array<offer_variant_insert_input>;
  on_conflict?: InputMaybe<offer_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_variant_couponArgs = {
  objects: Array<offer_variant_coupon_insert_input>;
  on_conflict?: InputMaybe<offer_variant_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_variant_coupon_oneArgs = {
  object: offer_variant_coupon_insert_input;
  on_conflict?: InputMaybe<offer_variant_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_offer_variant_oneArgs = {
  object: offer_variant_insert_input;
  on_conflict?: InputMaybe<offer_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platformArgs = {
  objects: Array<platform_insert_input>;
  on_conflict?: InputMaybe<platform_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addonArgs = {
  objects: Array<platform_addon_insert_input>;
  on_conflict?: InputMaybe<platform_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_oneArgs = {
  object: platform_addon_insert_input;
  on_conflict?: InputMaybe<platform_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_planArgs = {
  objects: Array<platform_addon_plan_insert_input>;
  on_conflict?: InputMaybe<platform_addon_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_plan_oneArgs = {
  object: platform_addon_plan_insert_input;
  on_conflict?: InputMaybe<platform_addon_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_priceArgs = {
  objects: Array<platform_addon_price_insert_input>;
  on_conflict?: InputMaybe<platform_addon_price_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_price_oneArgs = {
  object: platform_addon_price_insert_input;
  on_conflict?: InputMaybe<platform_addon_price_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_price_tierArgs = {
  objects: Array<platform_addon_price_tier_insert_input>;
  on_conflict?: InputMaybe<platform_addon_price_tier_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_addon_price_tier_oneArgs = {
  object: platform_addon_price_tier_insert_input;
  on_conflict?: InputMaybe<platform_addon_price_tier_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_api_logArgs = {
  objects: Array<platform_api_log_insert_input>;
  on_conflict?: InputMaybe<platform_api_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_api_log_oneArgs = {
  object: platform_api_log_insert_input;
  on_conflict?: InputMaybe<platform_api_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_base_currencyArgs = {
  objects: Array<platform_base_currency_insert_input>;
  on_conflict?: InputMaybe<platform_base_currency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_base_currency_oneArgs = {
  object: platform_base_currency_insert_input;
  on_conflict?: InputMaybe<platform_base_currency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_cancel_reasonArgs = {
  objects: Array<platform_cancel_reason_insert_input>;
  on_conflict?: InputMaybe<platform_cancel_reason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_cancel_reason_oneArgs = {
  object: platform_cancel_reason_insert_input;
  on_conflict?: InputMaybe<platform_cancel_reason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connectionArgs = {
  objects: Array<platform_connection_insert_input>;
  on_conflict?: InputMaybe<platform_connection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_naviga_domainArgs = {
  objects: Array<platform_connection_naviga_domain_insert_input>;
  on_conflict?: InputMaybe<platform_connection_naviga_domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_naviga_domain_oneArgs = {
  object: platform_connection_naviga_domain_insert_input;
  on_conflict?: InputMaybe<platform_connection_naviga_domain_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_naviga_paper_code_endpointArgs = {
  objects: Array<platform_connection_naviga_paper_code_endpoint_insert_input>;
  on_conflict?: InputMaybe<platform_connection_naviga_paper_code_endpoint_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_naviga_paper_code_endpoint_oneArgs = {
  object: platform_connection_naviga_paper_code_endpoint_insert_input;
  on_conflict?: InputMaybe<platform_connection_naviga_paper_code_endpoint_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_oneArgs = {
  object: platform_connection_insert_input;
  on_conflict?: InputMaybe<platform_connection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_shopify_storeArgs = {
  objects: Array<platform_connection_shopify_store_insert_input>;
  on_conflict?: InputMaybe<platform_connection_shopify_store_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_connection_shopify_store_oneArgs = {
  object: platform_connection_shopify_store_insert_input;
  on_conflict?: InputMaybe<platform_connection_shopify_store_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_couponArgs = {
  objects: Array<platform_coupon_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_addonArgs = {
  objects: Array<platform_coupon_addon_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_addon_oneArgs = {
  object: platform_coupon_addon_insert_input;
  on_conflict?: InputMaybe<platform_coupon_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_apply_toArgs = {
  objects: Array<platform_coupon_apply_to_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_apply_to_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_apply_to_oneArgs = {
  object: platform_coupon_apply_to_insert_input;
  on_conflict?: InputMaybe<platform_coupon_apply_to_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_apply_to_restrictionArgs = {
  objects: Array<platform_coupon_apply_to_restriction_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_apply_to_restriction_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_apply_to_restriction_oneArgs = {
  object: platform_coupon_apply_to_restriction_insert_input;
  on_conflict?: InputMaybe<platform_coupon_apply_to_restriction_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_durationArgs = {
  objects: Array<platform_coupon_duration_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_duration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_duration_intervalArgs = {
  objects: Array<platform_coupon_duration_interval_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_duration_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_duration_interval_oneArgs = {
  object: platform_coupon_duration_interval_insert_input;
  on_conflict?: InputMaybe<platform_coupon_duration_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_duration_oneArgs = {
  object: platform_coupon_duration_insert_input;
  on_conflict?: InputMaybe<platform_coupon_duration_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_oneArgs = {
  object: platform_coupon_insert_input;
  on_conflict?: InputMaybe<platform_coupon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_planArgs = {
  objects: Array<platform_coupon_plan_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_plan_oneArgs = {
  object: platform_coupon_plan_insert_input;
  on_conflict?: InputMaybe<platform_coupon_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_productArgs = {
  objects: Array<platform_coupon_product_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_product_groupArgs = {
  objects: Array<platform_coupon_product_group_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_product_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_product_group_oneArgs = {
  object: platform_coupon_product_group_insert_input;
  on_conflict?: InputMaybe<platform_coupon_product_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_product_oneArgs = {
  object: platform_coupon_product_insert_input;
  on_conflict?: InputMaybe<platform_coupon_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_typeArgs = {
  objects: Array<platform_coupon_type_insert_input>;
  on_conflict?: InputMaybe<platform_coupon_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_coupon_type_oneArgs = {
  object: platform_coupon_type_insert_input;
  on_conflict?: InputMaybe<platform_coupon_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_credentialsArgs = {
  objects: Array<platform_credentials_insert_input>;
  on_conflict?: InputMaybe<platform_credentials_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_credentials_oneArgs = {
  object: platform_credentials_insert_input;
  on_conflict?: InputMaybe<platform_credentials_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_currencyArgs = {
  objects: Array<platform_currency_insert_input>;
  on_conflict?: InputMaybe<platform_currency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_currency_exchange_rateArgs = {
  objects: Array<platform_currency_exchange_rate_insert_input>;
  on_conflict?: InputMaybe<platform_currency_exchange_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_currency_exchange_rate_oneArgs = {
  object: platform_currency_exchange_rate_insert_input;
  on_conflict?: InputMaybe<platform_currency_exchange_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_currency_oneArgs = {
  object: platform_currency_insert_input;
  on_conflict?: InputMaybe<platform_currency_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_customerArgs = {
  objects: Array<platform_customer_insert_input>;
  on_conflict?: InputMaybe<platform_customer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_customer_oneArgs = {
  object: platform_customer_insert_input;
  on_conflict?: InputMaybe<platform_customer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_ecommerce_productArgs = {
  objects: Array<platform_ecommerce_product_insert_input>;
  on_conflict?: InputMaybe<platform_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_ecommerce_product_oneArgs = {
  object: platform_ecommerce_product_insert_input;
  on_conflict?: InputMaybe<platform_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_importArgs = {
  objects: Array<platform_import_insert_input>;
  on_conflict?: InputMaybe<platform_import_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_oneArgs = {
  object: platform_import_insert_input;
  on_conflict?: InputMaybe<platform_import_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_statusArgs = {
  objects: Array<platform_import_status_insert_input>;
  on_conflict?: InputMaybe<platform_import_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_status_oneArgs = {
  object: platform_import_status_insert_input;
  on_conflict?: InputMaybe<platform_import_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_stepArgs = {
  objects: Array<platform_import_step_insert_input>;
  on_conflict?: InputMaybe<platform_import_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_step_oneArgs = {
  object: platform_import_step_insert_input;
  on_conflict?: InputMaybe<platform_import_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_step_typeArgs = {
  objects: Array<platform_import_step_type_insert_input>;
  on_conflict?: InputMaybe<platform_import_step_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_import_step_type_oneArgs = {
  object: platform_import_step_type_insert_input;
  on_conflict?: InputMaybe<platform_import_step_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_invoiceArgs = {
  objects: Array<platform_invoice_insert_input>;
  on_conflict?: InputMaybe<platform_invoice_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_invoice_oneArgs = {
  object: platform_invoice_insert_input;
  on_conflict?: InputMaybe<platform_invoice_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_invoice_statusArgs = {
  objects: Array<platform_invoice_status_insert_input>;
  on_conflict?: InputMaybe<platform_invoice_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_invoice_status_oneArgs = {
  object: platform_invoice_status_insert_input;
  on_conflict?: InputMaybe<platform_invoice_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_rate_codeArgs = {
  objects: Array<platform_newspaper_rate_code_insert_input>;
  on_conflict?: InputMaybe<platform_newspaper_rate_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_rate_code_oneArgs = {
  object: platform_newspaper_rate_code_insert_input;
  on_conflict?: InputMaybe<platform_newspaper_rate_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_subscriptionArgs = {
  objects: Array<platform_newspaper_subscription_insert_input>;
  on_conflict?: InputMaybe<platform_newspaper_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_subscription_oneArgs = {
  object: platform_newspaper_subscription_insert_input;
  on_conflict?: InputMaybe<platform_newspaper_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_subscription_stop_dateArgs = {
  objects: Array<platform_newspaper_subscription_stop_date_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_platform_newspaper_subscription_stop_date_oneArgs = {
  object: platform_newspaper_subscription_stop_date_insert_input;
};


/** mutation root */
export type mutation_rootinsert_platform_oneArgs = {
  object: platform_insert_input;
  on_conflict?: InputMaybe<platform_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_planArgs = {
  objects: Array<platform_plan_insert_input>;
  on_conflict?: InputMaybe<platform_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_ecommerce_productArgs = {
  objects: Array<platform_plan_ecommerce_product_insert_input>;
  on_conflict?: InputMaybe<platform_plan_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_ecommerce_product_oneArgs = {
  object: platform_plan_ecommerce_product_insert_input;
  on_conflict?: InputMaybe<platform_plan_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_groupArgs = {
  objects: Array<platform_plan_group_insert_input>;
  on_conflict?: InputMaybe<platform_plan_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_group_oneArgs = {
  object: platform_plan_group_insert_input;
  on_conflict?: InputMaybe<platform_plan_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_intervalArgs = {
  objects: Array<platform_plan_interval_insert_input>;
  on_conflict?: InputMaybe<platform_plan_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_interval_oneArgs = {
  object: platform_plan_interval_insert_input;
  on_conflict?: InputMaybe<platform_plan_interval_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_oneArgs = {
  object: platform_plan_insert_input;
  on_conflict?: InputMaybe<platform_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_pricing_modelArgs = {
  objects: Array<platform_plan_pricing_model_insert_input>;
  on_conflict?: InputMaybe<platform_plan_pricing_model_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_pricing_model_oneArgs = {
  object: platform_plan_pricing_model_insert_input;
  on_conflict?: InputMaybe<platform_plan_pricing_model_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_tierArgs = {
  objects: Array<platform_plan_tier_insert_input>;
  on_conflict?: InputMaybe<platform_plan_tier_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_plan_tier_oneArgs = {
  object: platform_plan_tier_insert_input;
  on_conflict?: InputMaybe<platform_plan_tier_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_productArgs = {
  objects: Array<platform_product_insert_input>;
  on_conflict?: InputMaybe<platform_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_product_groupArgs = {
  objects: Array<platform_product_group_insert_input>;
  on_conflict?: InputMaybe<platform_product_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_product_group_oneArgs = {
  object: platform_product_group_insert_input;
  on_conflict?: InputMaybe<platform_product_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_product_oneArgs = {
  object: platform_product_insert_input;
  on_conflict?: InputMaybe<platform_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_raw_objectArgs = {
  objects: Array<platform_raw_object_insert_input>;
  on_conflict?: InputMaybe<platform_raw_object_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_raw_object_oneArgs = {
  object: platform_raw_object_insert_input;
  on_conflict?: InputMaybe<platform_raw_object_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_raw_object_typeArgs = {
  objects: Array<platform_raw_object_type_insert_input>;
  on_conflict?: InputMaybe<platform_raw_object_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_raw_object_type_oneArgs = {
  object: platform_raw_object_type_insert_input;
  on_conflict?: InputMaybe<platform_raw_object_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_refundArgs = {
  objects: Array<platform_refund_insert_input>;
  on_conflict?: InputMaybe<platform_refund_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_refund_oneArgs = {
  object: platform_refund_insert_input;
  on_conflict?: InputMaybe<platform_refund_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscriptionArgs = {
  objects: Array<platform_subscription_insert_input>;
  on_conflict?: InputMaybe<platform_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_addonArgs = {
  objects: Array<platform_subscription_addon_insert_input>;
  on_conflict?: InputMaybe<platform_subscription_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_addon_oneArgs = {
  object: platform_subscription_addon_insert_input;
  on_conflict?: InputMaybe<platform_subscription_addon_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_next_billing_atArgs = {
  objects: Array<platform_subscription_next_billing_at_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_next_billing_at_oneArgs = {
  object: platform_subscription_next_billing_at_insert_input;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_oneArgs = {
  object: platform_subscription_insert_input;
  on_conflict?: InputMaybe<platform_subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_planArgs = {
  objects: Array<platform_subscription_plan_insert_input>;
  on_conflict?: InputMaybe<platform_subscription_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_plan_oneArgs = {
  object: platform_subscription_plan_insert_input;
  on_conflict?: InputMaybe<platform_subscription_plan_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_plan_swappable_ecommerce_productArgs = {
  objects: Array<platform_subscription_plan_swappable_ecommerce_product_insert_input>;
  on_conflict?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_plan_swappable_ecommerce_product_oneArgs = {
  object: platform_subscription_plan_swappable_ecommerce_product_insert_input;
  on_conflict?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_statusArgs = {
  objects: Array<platform_subscription_status_insert_input>;
  on_conflict?: InputMaybe<platform_subscription_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_subscription_status_oneArgs = {
  object: platform_subscription_status_insert_input;
  on_conflict?: InputMaybe<platform_subscription_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_variantArgs = {
  objects: Array<platform_variant_insert_input>;
  on_conflict?: InputMaybe<platform_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_variant_oneArgs = {
  object: platform_variant_insert_input;
  on_conflict?: InputMaybe<platform_variant_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_variant_optionArgs = {
  objects: Array<platform_variant_option_insert_input>;
  on_conflict?: InputMaybe<platform_variant_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_platform_variant_option_oneArgs = {
  object: platform_variant_option_insert_input;
  on_conflict?: InputMaybe<platform_variant_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_email_codeArgs = {
  objects: Array<portal_email_code_insert_input>;
  on_conflict?: InputMaybe<portal_email_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_email_code_oneArgs = {
  object: portal_email_code_insert_input;
  on_conflict?: InputMaybe<portal_email_code_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_sessionArgs = {
  objects: Array<portal_session_insert_input>;
  on_conflict?: InputMaybe<portal_session_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_session_oneArgs = {
  object: portal_session_insert_input;
  on_conflict?: InputMaybe<portal_session_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_session_subscriberArgs = {
  objects: Array<portal_session_subscriber_insert_input>;
  on_conflict?: InputMaybe<portal_session_subscriber_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_portal_session_subscriber_oneArgs = {
  object: portal_session_subscriber_insert_input;
  on_conflict?: InputMaybe<portal_session_subscriber_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_productArgs = {
  objects: Array<product_insert_input>;
  on_conflict?: InputMaybe<product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_product_oneArgs = {
  object: product_insert_input;
  on_conflict?: InputMaybe<product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_propertyArgs = {
  objects: Array<property_insert_input>;
  on_conflict?: InputMaybe<property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_entityArgs = {
  objects: Array<property_entity_insert_input>;
  on_conflict?: InputMaybe<property_entity_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_entity_oneArgs = {
  object: property_entity_insert_input;
  on_conflict?: InputMaybe<property_entity_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_formatArgs = {
  objects: Array<property_format_insert_input>;
  on_conflict?: InputMaybe<property_format_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_format_oneArgs = {
  object: property_format_insert_input;
  on_conflict?: InputMaybe<property_format_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_oneArgs = {
  object: property_insert_input;
  on_conflict?: InputMaybe<property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_typeArgs = {
  objects: Array<property_type_insert_input>;
  on_conflict?: InputMaybe<property_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_property_type_oneArgs = {
  object: property_type_insert_input;
  on_conflict?: InputMaybe<property_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_questionArgs = {
  objects: Array<question_insert_input>;
  on_conflict?: InputMaybe<question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answerArgs = {
  objects: Array<question_answer_insert_input>;
  on_conflict?: InputMaybe<question_answer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_likelihood_to_returnArgs = {
  objects: Array<question_answer_likelihood_to_return_insert_input>;
  on_conflict?: InputMaybe<question_answer_likelihood_to_return_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_likelihood_to_return_oneArgs = {
  object: question_answer_likelihood_to_return_insert_input;
  on_conflict?: InputMaybe<question_answer_likelihood_to_return_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_oneArgs = {
  object: question_answer_insert_input;
  on_conflict?: InputMaybe<question_answer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_radioArgs = {
  objects: Array<question_answer_radio_insert_input>;
  on_conflict?: InputMaybe<question_answer_radio_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_radio_oneArgs = {
  object: question_answer_radio_insert_input;
  on_conflict?: InputMaybe<question_answer_radio_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_sentiment_phraseArgs = {
  objects: Array<question_answer_sentiment_phrase_insert_input>;
  on_conflict?: InputMaybe<question_answer_sentiment_phrase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_sentiment_phrase_oneArgs = {
  object: question_answer_sentiment_phrase_insert_input;
  on_conflict?: InputMaybe<question_answer_sentiment_phrase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_textArgs = {
  objects: Array<question_answer_text_insert_input>;
  on_conflict?: InputMaybe<question_answer_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_answer_text_oneArgs = {
  object: question_answer_text_insert_input;
  on_conflict?: InputMaybe<question_answer_text_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_oneArgs = {
  object: question_insert_input;
  on_conflict?: InputMaybe<question_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_optionArgs = {
  objects: Array<question_option_insert_input>;
  on_conflict?: InputMaybe<question_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_option_oneArgs = {
  object: question_option_insert_input;
  on_conflict?: InputMaybe<question_option_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_option_versionArgs = {
  objects: Array<question_option_version_insert_input>;
  on_conflict?: InputMaybe<question_option_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_option_version_oneArgs = {
  object: question_option_version_insert_input;
  on_conflict?: InputMaybe<question_option_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_settingArgs = {
  objects: Array<question_setting_insert_input>;
  on_conflict?: InputMaybe<question_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_setting_keyArgs = {
  objects: Array<question_setting_key_insert_input>;
  on_conflict?: InputMaybe<question_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_setting_key_oneArgs = {
  object: question_setting_key_insert_input;
  on_conflict?: InputMaybe<question_setting_key_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_setting_oneArgs = {
  object: question_setting_insert_input;
  on_conflict?: InputMaybe<question_setting_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_setting_versionArgs = {
  objects: Array<question_setting_version_insert_input>;
  on_conflict?: InputMaybe<question_setting_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_setting_version_oneArgs = {
  object: question_setting_version_insert_input;
  on_conflict?: InputMaybe<question_setting_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_typeArgs = {
  objects: Array<question_type_insert_input>;
  on_conflict?: InputMaybe<question_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_type_oneArgs = {
  object: question_type_insert_input;
  on_conflict?: InputMaybe<question_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_versionArgs = {
  objects: Array<question_version_insert_input>;
  on_conflict?: InputMaybe<question_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_question_version_oneArgs = {
  object: question_version_insert_input;
  on_conflict?: InputMaybe<question_version_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_report_recurring_revenueArgs = {
  objects: Array<report_recurring_revenue_insert_input>;
  on_conflict?: InputMaybe<report_recurring_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_report_recurring_revenue_oneArgs = {
  object: report_recurring_revenue_insert_input;
  on_conflict?: InputMaybe<report_recurring_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_logArgs = {
  objects: Array<revenue_log_insert_input>;
  on_conflict?: InputMaybe<revenue_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_log_oneArgs = {
  object: revenue_log_insert_input;
  on_conflict?: InputMaybe<revenue_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_log_revenue_typeArgs = {
  objects: Array<revenue_log_revenue_type_insert_input>;
  on_conflict?: InputMaybe<revenue_log_revenue_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_log_revenue_type_oneArgs = {
  object: revenue_log_revenue_type_insert_input;
  on_conflict?: InputMaybe<revenue_log_revenue_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_log_typeArgs = {
  objects: Array<revenue_log_type_insert_input>;
  on_conflict?: InputMaybe<revenue_log_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_revenue_log_type_oneArgs = {
  object: revenue_log_type_insert_input;
  on_conflict?: InputMaybe<revenue_log_type_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_rule_group_modeArgs = {
  objects: Array<rule_group_mode_insert_input>;
  on_conflict?: InputMaybe<rule_group_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_rule_group_mode_oneArgs = {
  object: rule_group_mode_insert_input;
  on_conflict?: InputMaybe<rule_group_mode_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_script_logArgs = {
  objects: Array<script_log_insert_input>;
  on_conflict?: InputMaybe<script_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_script_log_oneArgs = {
  object: script_log_insert_input;
  on_conflict?: InputMaybe<script_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segmentArgs = {
  objects: Array<segment_insert_input>;
  on_conflict?: InputMaybe<segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_conditionArgs = {
  objects: Array<segment_condition_insert_input>;
  on_conflict?: InputMaybe<segment_condition_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_boolean_operatorArgs = {
  objects: Array<segment_condition_boolean_operator_insert_input>;
  on_conflict?: InputMaybe<segment_condition_boolean_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_boolean_operator_oneArgs = {
  object: segment_condition_boolean_operator_insert_input;
  on_conflict?: InputMaybe<segment_condition_boolean_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_groupArgs = {
  objects: Array<segment_condition_group_insert_input>;
  on_conflict?: InputMaybe<segment_condition_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_group_entryArgs = {
  objects: Array<segment_condition_group_entry_insert_input>;
  on_conflict?: InputMaybe<segment_condition_group_entry_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_group_entry_oneArgs = {
  object: segment_condition_group_entry_insert_input;
  on_conflict?: InputMaybe<segment_condition_group_entry_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_group_oneArgs = {
  object: segment_condition_group_insert_input;
  on_conflict?: InputMaybe<segment_condition_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_oneArgs = {
  object: segment_condition_insert_input;
  on_conflict?: InputMaybe<segment_condition_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_operatorArgs = {
  objects: Array<segment_condition_operator_insert_input>;
  on_conflict?: InputMaybe<segment_condition_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_operator_oneArgs = {
  object: segment_condition_operator_insert_input;
  on_conflict?: InputMaybe<segment_condition_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_propertyArgs = {
  objects: Array<segment_condition_property_insert_input>;
  on_conflict?: InputMaybe<segment_condition_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_condition_property_oneArgs = {
  object: segment_condition_property_insert_input;
  on_conflict?: InputMaybe<segment_condition_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_groupArgs = {
  objects: Array<segment_group_insert_input>;
  on_conflict?: InputMaybe<segment_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_group_oneArgs = {
  object: segment_group_insert_input;
  on_conflict?: InputMaybe<segment_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_group_operatorArgs = {
  objects: Array<segment_group_operator_insert_input>;
  on_conflict?: InputMaybe<segment_group_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_group_operator_oneArgs = {
  object: segment_group_operator_insert_input;
  on_conflict?: InputMaybe<segment_group_operator_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_group_segmentArgs = {
  objects: Array<segment_group_segment_insert_input>;
  on_conflict?: InputMaybe<segment_group_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_group_segment_oneArgs = {
  object: segment_group_segment_insert_input;
  on_conflict?: InputMaybe<segment_group_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_oneArgs = {
  object: segment_insert_input;
  on_conflict?: InputMaybe<segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_subscription_cacheArgs = {
  objects: Array<segment_subscription_cache_insert_input>;
  on_conflict?: InputMaybe<segment_subscription_cache_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_segment_subscription_cache_oneArgs = {
  object: segment_subscription_cache_insert_input;
  on_conflict?: InputMaybe<segment_subscription_cache_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentimentArgs = {
  objects: Array<sentiment_insert_input>;
  on_conflict?: InputMaybe<sentiment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentiment_oneArgs = {
  object: sentiment_insert_input;
  on_conflict?: InputMaybe<sentiment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentiment_phraseArgs = {
  objects: Array<sentiment_phrase_insert_input>;
  on_conflict?: InputMaybe<sentiment_phrase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentiment_phrase_normalizedArgs = {
  objects: Array<sentiment_phrase_normalized_insert_input>;
  on_conflict?: InputMaybe<sentiment_phrase_normalized_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentiment_phrase_normalized_oneArgs = {
  object: sentiment_phrase_normalized_insert_input;
  on_conflict?: InputMaybe<sentiment_phrase_normalized_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_sentiment_phrase_oneArgs = {
  object: sentiment_phrase_insert_input;
  on_conflict?: InputMaybe<sentiment_phrase_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_stateArgs = {
  objects: Array<state_insert_input>;
  on_conflict?: InputMaybe<state_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_state_nameArgs = {
  objects: Array<state_name_insert_input>;
  on_conflict?: InputMaybe<state_name_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_state_name_oneArgs = {
  object: state_name_insert_input;
  on_conflict?: InputMaybe<state_name_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_state_oneArgs = {
  object: state_insert_input;
  on_conflict?: InputMaybe<state_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_stripe_appArgs = {
  objects: Array<stripe_app_insert_input>;
  on_conflict?: InputMaybe<stripe_app_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_stripe_app_oneArgs = {
  object: stripe_app_insert_input;
  on_conflict?: InputMaybe<stripe_app_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriberArgs = {
  objects: Array<subscriber_insert_input>;
  on_conflict?: InputMaybe<subscriber_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_acknowledgementArgs = {
  objects: Array<subscriber_acknowledgement_insert_input>;
  on_conflict?: InputMaybe<subscriber_acknowledgement_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_acknowledgement_oneArgs = {
  object: subscriber_acknowledgement_insert_input;
  on_conflict?: InputMaybe<subscriber_acknowledgement_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_arrArgs = {
  objects: Array<subscriber_arr_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_arr_oneArgs = {
  object: subscriber_arr_insert_input;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaignArgs = {
  objects: Array<subscriber_campaign_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_offerArgs = {
  objects: Array<subscriber_campaign_offer_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_offer_oneArgs = {
  object: subscriber_campaign_offer_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_offer_ruleArgs = {
  objects: Array<subscriber_campaign_offer_rule_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_offer_rule_oneArgs = {
  object: subscriber_campaign_offer_rule_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_oneArgs = {
  object: subscriber_campaign_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_overrideArgs = {
  objects: Array<subscriber_campaign_override_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_override_oneArgs = {
  object: subscriber_campaign_override_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_segmentArgs = {
  objects: Array<subscriber_campaign_segment_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_segment_oneArgs = {
  object: subscriber_campaign_segment_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_statusArgs = {
  objects: Array<subscriber_campaign_status_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_status_oneArgs = {
  object: subscriber_campaign_status_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_viewArgs = {
  objects: Array<subscriber_campaign_view_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_view_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_view_oneArgs = {
  object: subscriber_campaign_view_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_view_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_view_statusArgs = {
  objects: Array<subscriber_campaign_view_status_insert_input>;
  on_conflict?: InputMaybe<subscriber_campaign_view_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_campaign_view_status_oneArgs = {
  object: subscriber_campaign_view_status_insert_input;
  on_conflict?: InputMaybe<subscriber_campaign_view_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flowArgs = {
  objects: Array<subscriber_flow_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_deflectionArgs = {
  objects: Array<subscriber_flow_deflection_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_deflection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_deflection_oneArgs = {
  object: subscriber_flow_deflection_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_deflection_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_exportArgs = {
  objects: Array<subscriber_flow_export_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_export_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_export_oneArgs = {
  object: subscriber_flow_export_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_export_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_flow_stepArgs = {
  objects: Array<subscriber_flow_flow_step_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_flow_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_flow_step_oneArgs = {
  object: subscriber_flow_flow_step_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_flow_step_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offerArgs = {
  objects: Array<subscriber_flow_offer_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_groupArgs = {
  objects: Array<subscriber_flow_offer_group_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_group_offerArgs = {
  objects: Array<subscriber_flow_offer_group_offer_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_offer_group_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_group_offer_oneArgs = {
  object: subscriber_flow_offer_group_offer_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_offer_group_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_group_oneArgs = {
  object: subscriber_flow_offer_group_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_oneArgs = {
  object: subscriber_flow_offer_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_ruleArgs = {
  objects: Array<subscriber_flow_offer_rule_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_rule_oneArgs = {
  object: subscriber_flow_offer_rule_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_offer_rule_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_statusArgs = {
  objects: Array<subscriber_flow_offer_status_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_offer_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_offer_status_oneArgs = {
  object: subscriber_flow_offer_status_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_offer_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_on_cancel_overrideArgs = {
  objects: Array<subscriber_flow_on_cancel_override_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_on_cancel_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_on_cancel_override_oneArgs = {
  object: subscriber_flow_on_cancel_override_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_on_cancel_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_oneArgs = {
  object: subscriber_flow_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_overrideArgs = {
  objects: Array<subscriber_flow_override_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_override_oneArgs = {
  object: subscriber_flow_override_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_override_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_override_reasonArgs = {
  objects: Array<subscriber_flow_override_reason_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_override_reason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_override_reason_oneArgs = {
  object: subscriber_flow_override_reason_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_override_reason_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_question_answerArgs = {
  objects: Array<subscriber_flow_question_answer_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_question_answer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_question_answer_oneArgs = {
  object: subscriber_flow_question_answer_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_question_answer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_segmentArgs = {
  objects: Array<subscriber_flow_segment_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_segment_groupArgs = {
  objects: Array<subscriber_flow_segment_group_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_segment_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_segment_group_oneArgs = {
  object: subscriber_flow_segment_group_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_segment_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_segment_oneArgs = {
  object: subscriber_flow_segment_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_statusArgs = {
  objects: Array<subscriber_flow_status_insert_input>;
  on_conflict?: InputMaybe<subscriber_flow_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_flow_status_oneArgs = {
  object: subscriber_flow_status_insert_input;
  on_conflict?: InputMaybe<subscriber_flow_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_logArgs = {
  objects: Array<subscriber_log_insert_input>;
  on_conflict?: InputMaybe<subscriber_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_eventArgs = {
  objects: Array<subscriber_log_event_insert_input>;
  on_conflict?: InputMaybe<subscriber_log_event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_event_oneArgs = {
  object: subscriber_log_event_insert_input;
  on_conflict?: InputMaybe<subscriber_log_event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_offerArgs = {
  objects: Array<subscriber_log_offer_insert_input>;
  on_conflict?: InputMaybe<subscriber_log_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_offer_groupArgs = {
  objects: Array<subscriber_log_offer_group_insert_input>;
  on_conflict?: InputMaybe<subscriber_log_offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_offer_group_oneArgs = {
  object: subscriber_log_offer_group_insert_input;
  on_conflict?: InputMaybe<subscriber_log_offer_group_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_offer_oneArgs = {
  object: subscriber_log_offer_insert_input;
  on_conflict?: InputMaybe<subscriber_log_offer_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_log_oneArgs = {
  object: subscriber_log_insert_input;
  on_conflict?: InputMaybe<subscriber_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_mrrArgs = {
  objects: Array<subscriber_mrr_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_mrr_oneArgs = {
  object: subscriber_mrr_insert_input;
};


/** mutation root */
export type mutation_rootinsert_subscriber_oneArgs = {
  object: subscriber_insert_input;
  on_conflict?: InputMaybe<subscriber_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_propertyArgs = {
  objects: Array<subscriber_property_insert_input>;
  on_conflict?: InputMaybe<subscriber_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_property_oneArgs = {
  object: subscriber_property_insert_input;
  on_conflict?: InputMaybe<subscriber_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_property_valueArgs = {
  objects: Array<subscriber_property_value_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_property_value_oneArgs = {
  object: subscriber_property_value_insert_input;
};


/** mutation root */
export type mutation_rootinsert_subscriber_statusArgs = {
  objects: Array<subscriber_status_insert_input>;
  on_conflict?: InputMaybe<subscriber_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriber_status_oneArgs = {
  object: subscriber_status_insert_input;
  on_conflict?: InputMaybe<subscriber_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscriptionArgs = {
  objects: Array<subscription_insert_input>;
  on_conflict?: InputMaybe<subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_arrArgs = {
  objects: Array<subscription_arr_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_subscription_arr_oneArgs = {
  object: subscription_arr_insert_input;
};


/** mutation root */
export type mutation_rootinsert_subscription_oneArgs = {
  object: subscription_insert_input;
  on_conflict?: InputMaybe<subscription_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_productArgs = {
  objects: Array<subscription_product_insert_input>;
  on_conflict?: InputMaybe<subscription_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_product_oneArgs = {
  object: subscription_product_insert_input;
  on_conflict?: InputMaybe<subscription_product_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_propertyArgs = {
  objects: Array<subscription_property_insert_input>;
  on_conflict?: InputMaybe<subscription_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_property_oneArgs = {
  object: subscription_property_insert_input;
  on_conflict?: InputMaybe<subscription_property_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_property_valueArgs = {
  objects: Array<subscription_property_value_insert_input>;
};


/** mutation root */
export type mutation_rootinsert_subscription_property_value_oneArgs = {
  object: subscription_property_value_insert_input;
};


/** mutation root */
export type mutation_rootinsert_subscription_segmentArgs = {
  objects: Array<subscription_segment_insert_input>;
  on_conflict?: InputMaybe<subscription_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_segment_oneArgs = {
  object: subscription_segment_insert_input;
  on_conflict?: InputMaybe<subscription_segment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_segment_statusArgs = {
  objects: Array<subscription_segment_status_insert_input>;
  on_conflict?: InputMaybe<subscription_segment_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_segment_status_oneArgs = {
  object: subscription_segment_status_insert_input;
  on_conflict?: InputMaybe<subscription_segment_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_statusArgs = {
  objects: Array<subscription_status_insert_input>;
  on_conflict?: InputMaybe<subscription_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_subscription_status_oneArgs = {
  object: subscription_status_insert_input;
  on_conflict?: InputMaybe<subscription_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_surveyArgs = {
  objects: Array<survey_insert_input>;
  on_conflict?: InputMaybe<survey_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_survey_oneArgs = {
  object: survey_insert_input;
  on_conflict?: InputMaybe<survey_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_tagArgs = {
  objects: Array<tag_insert_input>;
  on_conflict?: InputMaybe<tag_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_tag_oneArgs = {
  object: tag_insert_input;
  on_conflict?: InputMaybe<tag_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_deflection_trendsArgs = {
  objects: Array<template_report_deflection_trends_insert_input>;
  on_conflict?: InputMaybe<template_report_deflection_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_deflection_trends_oneArgs = {
  object: template_report_deflection_trends_insert_input;
  on_conflict?: InputMaybe<template_report_deflection_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_feedback_trendsArgs = {
  objects: Array<template_report_feedback_trends_insert_input>;
  on_conflict?: InputMaybe<template_report_feedback_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_feedback_trends_oneArgs = {
  object: template_report_feedback_trends_insert_input;
  on_conflict?: InputMaybe<template_report_feedback_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_accept_rateArgs = {
  objects: Array<template_report_offer_accept_rate_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_accept_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_accept_rate_oneArgs = {
  object: template_report_offer_accept_rate_insert_input;
  on_conflict?: InputMaybe<template_report_offer_accept_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_group_accept_rateArgs = {
  objects: Array<template_report_offer_group_accept_rate_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_group_accept_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_group_accept_rate_oneArgs = {
  object: template_report_offer_group_accept_rate_insert_input;
  on_conflict?: InputMaybe<template_report_offer_group_accept_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_group_retained_revenueArgs = {
  objects: Array<template_report_offer_group_retained_revenue_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_group_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_group_retained_revenue_oneArgs = {
  object: template_report_offer_group_retained_revenue_insert_input;
  on_conflict?: InputMaybe<template_report_offer_group_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_rateArgs = {
  objects: Array<template_report_offer_rate_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_rate_oneArgs = {
  object: template_report_offer_rate_insert_input;
  on_conflict?: InputMaybe<template_report_offer_rate_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_resolutionArgs = {
  objects: Array<template_report_offer_resolution_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_resolution_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_resolution_oneArgs = {
  object: template_report_offer_resolution_insert_input;
  on_conflict?: InputMaybe<template_report_offer_resolution_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_retained_revenueArgs = {
  objects: Array<template_report_offer_retained_revenue_insert_input>;
  on_conflict?: InputMaybe<template_report_offer_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_offer_retained_revenue_oneArgs = {
  object: template_report_offer_retained_revenue_insert_input;
  on_conflict?: InputMaybe<template_report_offer_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_radioArgs = {
  objects: Array<template_report_question_answer_radio_insert_input>;
  on_conflict?: InputMaybe<template_report_question_answer_radio_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_radio_inactive_mrrArgs = {
  objects: Array<template_report_question_answer_radio_inactive_mrr_insert_input>;
  on_conflict?: InputMaybe<template_report_question_answer_radio_inactive_mrr_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_radio_inactive_mrr_oneArgs = {
  object: template_report_question_answer_radio_inactive_mrr_insert_input;
  on_conflict?: InputMaybe<template_report_question_answer_radio_inactive_mrr_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_radio_oneArgs = {
  object: template_report_question_answer_radio_insert_input;
  on_conflict?: InputMaybe<template_report_question_answer_radio_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_sentimentArgs = {
  objects: Array<template_report_question_answer_sentiment_insert_input>;
  on_conflict?: InputMaybe<template_report_question_answer_sentiment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_question_answer_sentiment_oneArgs = {
  object: template_report_question_answer_sentiment_insert_input;
  on_conflict?: InputMaybe<template_report_question_answer_sentiment_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_retained_revenueArgs = {
  objects: Array<template_report_retained_revenue_insert_input>;
  on_conflict?: InputMaybe<template_report_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_retained_revenue_oneArgs = {
  object: template_report_retained_revenue_insert_input;
  on_conflict?: InputMaybe<template_report_retained_revenue_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_retention_trendsArgs = {
  objects: Array<template_report_retention_trends_insert_input>;
  on_conflict?: InputMaybe<template_report_retention_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_retention_trends_oneArgs = {
  object: template_report_retention_trends_insert_input;
  on_conflict?: InputMaybe<template_report_retention_trends_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_subscriber_flow_statusArgs = {
  objects: Array<template_report_subscriber_flow_status_insert_input>;
  on_conflict?: InputMaybe<template_report_subscriber_flow_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_template_report_subscriber_flow_status_oneArgs = {
  object: template_report_subscriber_flow_status_insert_input;
  on_conflict?: InputMaybe<template_report_subscriber_flow_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translationArgs = {
  objects: Array<translation_insert_input>;
  on_conflict?: InputMaybe<translation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translation_oneArgs = {
  object: translation_insert_input;
  on_conflict?: InputMaybe<translation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translation_valueArgs = {
  objects: Array<translation_value_insert_input>;
  on_conflict?: InputMaybe<translation_value_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translation_value_formatArgs = {
  objects: Array<translation_value_format_insert_input>;
  on_conflict?: InputMaybe<translation_value_format_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translation_value_format_oneArgs = {
  object: translation_value_format_insert_input;
  on_conflict?: InputMaybe<translation_value_format_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_translation_value_oneArgs = {
  object: translation_value_insert_input;
  on_conflict?: InputMaybe<translation_value_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_userArgs = {
  objects: Array<user_insert_input>;
  on_conflict?: InputMaybe<user_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_user_invitationArgs = {
  objects: Array<user_invitation_insert_input>;
  on_conflict?: InputMaybe<user_invitation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_user_invitation_oneArgs = {
  object: user_invitation_insert_input;
  on_conflict?: InputMaybe<user_invitation_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_user_oneArgs = {
  object: user_insert_input;
  on_conflict?: InputMaybe<user_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_user_profileArgs = {
  objects: Array<user_profile_insert_input>;
  on_conflict?: InputMaybe<user_profile_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_user_profile_oneArgs = {
  object: user_profile_insert_input;
  on_conflict?: InputMaybe<user_profile_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhookArgs = {
  objects: Array<webhook_insert_input>;
  on_conflict?: InputMaybe<webhook_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_eventArgs = {
  objects: Array<webhook_event_insert_input>;
  on_conflict?: InputMaybe<webhook_event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_event_oneArgs = {
  object: webhook_event_insert_input;
  on_conflict?: InputMaybe<webhook_event_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_logArgs = {
  objects: Array<webhook_log_insert_input>;
  on_conflict?: InputMaybe<webhook_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_log_oneArgs = {
  object: webhook_log_insert_input;
  on_conflict?: InputMaybe<webhook_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_oneArgs = {
  object: webhook_insert_input;
  on_conflict?: InputMaybe<webhook_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_request_logArgs = {
  objects: Array<webhook_request_log_insert_input>;
  on_conflict?: InputMaybe<webhook_request_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_request_log_oneArgs = {
  object: webhook_request_log_insert_input;
  on_conflict?: InputMaybe<webhook_request_log_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_statusArgs = {
  objects: Array<webhook_status_insert_input>;
  on_conflict?: InputMaybe<webhook_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinsert_webhook_status_oneArgs = {
  object: webhook_status_insert_input;
  on_conflict?: InputMaybe<webhook_status_on_conflict>;
};


/** mutation root */
export type mutation_rootinviteUserArgs = {
  input: InviteUserInput;
};


/** mutation root */
export type mutation_rootlogOfferGroupPresentedArgs = {
  input: LogOfferGroupPresentedInput;
};


/** mutation root */
export type mutation_rootlogOfferPresentedArgs = {
  input: LogOfferPresentedInput;
};


/** mutation root */
export type mutation_rootloginUserArgs = {
  input: LoginUserInput;
};


/** mutation root */
export type mutation_rootpopulate_segment_subscription_cacheArgs = {
  args: populate_segment_subscription_cache_args;
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


/** mutation root */
export type mutation_rootportalDownloadInvoiceArgs = {
  input: PortalDownloadInvoiceInput;
};


/** mutation root */
export type mutation_rootpublishFlowArgs = {
  input: PublishFlowInput;
};


/** mutation root */
export type mutation_rootrefresh_materialized_viewArgs = {
  args: refresh_materialized_view_args;
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


/** mutation root */
export type mutation_rootregenerateOfferVariantArgs = {
  input: RegenerateOfferVariantInput;
};


/** mutation root */
export type mutation_rootremoveAccountUserArgs = {
  input: RemoveAccountUserInput;
};


/** mutation root */
export type mutation_rootrequestShopifyInstallArgs = {
  input: RequestShopifyInstallInput;
};


/** mutation root */
export type mutation_rootrunScriptArgs = {
  input: RunScriptInput;
};


/** mutation root */
export type mutation_rootsendEmailVerificationArgs = {
  input: SendEmailVerificationInput;
};


/** mutation root */
export type mutation_rootsetPortalSessionPaymentMethodArgs = {
  input: SetPortalSessionPaymentMethodInput;
};


/** mutation root */
export type mutation_rootsetSubscriberCampaignPaymentMethodArgs = {
  input: SetSubscriberCampaignPaymentMethodInput;
};


/** mutation root */
export type mutation_rootsignCustomerPortalFlowPayloadArgs = {
  input: SignCustomerPortalFlowPayloadInput;
};


/** mutation root */
export type mutation_rootsignFlowPayloadArgs = {
  input: SignFlowPayloadInput;
};


/** mutation root */
export type mutation_rootsignUpArgs = {
  input: SignUpInput;
};


/** mutation root */
export type mutation_rootstartFlowStepArgs = {
  input: StartFlowStepInput;
};


/** mutation root */
export type mutation_rootstartSubscriberCampaignViewArgs = {
  input: StartSubscriberCampaignViewInput;
};


/** mutation root */
export type mutation_rootsubmitCustomSubscriberDetailsArgs = {
  input: SubmitCustomSubscriberDetailsInput;
};


/** mutation root */
export type mutation_rootsubmitFormAnswersArgs = {
  input: SubmitFormAnswersInput;
};


/** mutation root */
export type mutation_rootsubmitQuestionAnswerArgs = {
  input: SubmitQuestionAnswerInput;
};


/** mutation root */
export type mutation_rootsubscriberCampaignArgs = {
  input: SubscriberCampaignInput;
};


/** mutation root */
export type mutation_rootsubscriberCampaignOfferArgs = {
  input: SubscriberCampaignOfferInput;
};


/** mutation root */
export type mutation_rootswitchAccountArgs = {
  input: SwitchAccountInput;
};


/** mutation root */
export type mutation_rootswitchTestModeArgs = {
  input: SwitchTestModeInput;
};


/** mutation root */
export type mutation_roottrackEventArgs = {
  input: TrackEventInput;
};


/** mutation root */
export type mutation_roottransparentLoginArgs = {
  input: TransparentLoginInput;
};


/** mutation root */
export type mutation_roottransparentLogoutArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type mutation_rootupdateChargebeeApiKeyArgs = {
  input: UpdateChargebeeApiKeyInput;
};


/** mutation root */
export type mutation_rootupdateDeflectionArgs = {
  input: UpdateDeflectionInput;
};


/** mutation root */
export type mutation_rootupdateMaxioApiKeyArgs = {
  input: UpdateMaxioApiKeyInput;
};


/** mutation root */
export type mutation_rootupdateOpenPayApiTokenArgs = {
  input: UpdateOpenPayApiTokenInput;
};


/** mutation root */
export type mutation_rootupdateRechargeApiTokenArgs = {
  input: UpdateRechargeApiTokenInput;
};


/** mutation root */
export type mutation_rootupdateRecurlyApiKeyArgs = {
  input: UpdateRecurlyApiKeyInput;
};


/** mutation root */
export type mutation_rootupdateSegmentGroupArgs = {
  input: UpdateSegmentGroupInput;
};


/** mutation root */
export type mutation_rootupdate_accountArgs = {
  _inc?: InputMaybe<account_inc_input>;
  _set?: InputMaybe<account_set_input>;
  where: account_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_by_pkArgs = {
  _inc?: InputMaybe<account_inc_input>;
  _set?: InputMaybe<account_set_input>;
  pk_columns: account_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_featureArgs = {
  _append?: InputMaybe<account_feature_append_input>;
  _delete_at_path?: InputMaybe<account_feature_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_feature_delete_elem_input>;
  _delete_key?: InputMaybe<account_feature_delete_key_input>;
  _inc?: InputMaybe<account_feature_inc_input>;
  _prepend?: InputMaybe<account_feature_prepend_input>;
  _set?: InputMaybe<account_feature_set_input>;
  where: account_feature_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_feature_by_pkArgs = {
  _append?: InputMaybe<account_feature_append_input>;
  _delete_at_path?: InputMaybe<account_feature_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_feature_delete_elem_input>;
  _delete_key?: InputMaybe<account_feature_delete_key_input>;
  _inc?: InputMaybe<account_feature_inc_input>;
  _prepend?: InputMaybe<account_feature_prepend_input>;
  _set?: InputMaybe<account_feature_set_input>;
  pk_columns: account_feature_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_feature_keyArgs = {
  _set?: InputMaybe<account_feature_key_set_input>;
  where: account_feature_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_feature_key_by_pkArgs = {
  _set?: InputMaybe<account_feature_key_set_input>;
  pk_columns: account_feature_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_feature_key_manyArgs = {
  updates: Array<account_feature_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_feature_manyArgs = {
  updates: Array<account_feature_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_manyArgs = {
  updates: Array<account_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_modeArgs = {
  _set?: InputMaybe<account_mode_set_input>;
  where: account_mode_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_mode_by_pkArgs = {
  _set?: InputMaybe<account_mode_set_input>;
  pk_columns: account_mode_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_mode_manyArgs = {
  updates: Array<account_mode_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_revenue_modeArgs = {
  _set?: InputMaybe<account_revenue_mode_set_input>;
  where: account_revenue_mode_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_revenue_mode_by_pkArgs = {
  _set?: InputMaybe<account_revenue_mode_set_input>;
  pk_columns: account_revenue_mode_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_revenue_mode_manyArgs = {
  updates: Array<account_revenue_mode_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_settingArgs = {
  _append?: InputMaybe<account_setting_append_input>;
  _delete_at_path?: InputMaybe<account_setting_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_setting_delete_elem_input>;
  _delete_key?: InputMaybe<account_setting_delete_key_input>;
  _inc?: InputMaybe<account_setting_inc_input>;
  _prepend?: InputMaybe<account_setting_prepend_input>;
  _set?: InputMaybe<account_setting_set_input>;
  where: account_setting_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_setting_by_pkArgs = {
  _append?: InputMaybe<account_setting_append_input>;
  _delete_at_path?: InputMaybe<account_setting_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_setting_delete_elem_input>;
  _delete_key?: InputMaybe<account_setting_delete_key_input>;
  _inc?: InputMaybe<account_setting_inc_input>;
  _prepend?: InputMaybe<account_setting_prepend_input>;
  _set?: InputMaybe<account_setting_set_input>;
  pk_columns: account_setting_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_setting_keyArgs = {
  _set?: InputMaybe<account_setting_key_set_input>;
  where: account_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_setting_key_by_pkArgs = {
  _set?: InputMaybe<account_setting_key_set_input>;
  pk_columns: account_setting_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_setting_key_manyArgs = {
  updates: Array<account_setting_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_setting_manyArgs = {
  updates: Array<account_setting_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_statusArgs = {
  _set?: InputMaybe<account_status_set_input>;
  where: account_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_status_by_pkArgs = {
  _set?: InputMaybe<account_status_set_input>;
  pk_columns: account_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_status_manyArgs = {
  updates: Array<account_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_userArgs = {
  _inc?: InputMaybe<account_user_inc_input>;
  _set?: InputMaybe<account_user_set_input>;
  where: account_user_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_user_by_pkArgs = {
  _inc?: InputMaybe<account_user_inc_input>;
  _set?: InputMaybe<account_user_set_input>;
  pk_columns: account_user_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_user_manyArgs = {
  updates: Array<account_user_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_user_settingArgs = {
  _append?: InputMaybe<account_user_setting_append_input>;
  _delete_at_path?: InputMaybe<account_user_setting_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_user_setting_delete_elem_input>;
  _delete_key?: InputMaybe<account_user_setting_delete_key_input>;
  _inc?: InputMaybe<account_user_setting_inc_input>;
  _prepend?: InputMaybe<account_user_setting_prepend_input>;
  _set?: InputMaybe<account_user_setting_set_input>;
  where: account_user_setting_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_user_setting_by_pkArgs = {
  _append?: InputMaybe<account_user_setting_append_input>;
  _delete_at_path?: InputMaybe<account_user_setting_delete_at_path_input>;
  _delete_elem?: InputMaybe<account_user_setting_delete_elem_input>;
  _delete_key?: InputMaybe<account_user_setting_delete_key_input>;
  _inc?: InputMaybe<account_user_setting_inc_input>;
  _prepend?: InputMaybe<account_user_setting_prepend_input>;
  _set?: InputMaybe<account_user_setting_set_input>;
  pk_columns: account_user_setting_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_user_setting_keyArgs = {
  _set?: InputMaybe<account_user_setting_key_set_input>;
  where: account_user_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_account_user_setting_key_by_pkArgs = {
  _set?: InputMaybe<account_user_setting_key_set_input>;
  pk_columns: account_user_setting_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_account_user_setting_key_manyArgs = {
  updates: Array<account_user_setting_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_account_user_setting_manyArgs = {
  updates: Array<account_user_setting_updates>;
};


/** mutation root */
export type mutation_rootupdate_acknowledgementArgs = {
  _inc?: InputMaybe<acknowledgement_inc_input>;
  _set?: InputMaybe<acknowledgement_set_input>;
  where: acknowledgement_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_by_pkArgs = {
  _inc?: InputMaybe<acknowledgement_inc_input>;
  _set?: InputMaybe<acknowledgement_set_input>;
  pk_columns: acknowledgement_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_groupArgs = {
  _inc?: InputMaybe<acknowledgement_group_inc_input>;
  _set?: InputMaybe<acknowledgement_group_set_input>;
  where: acknowledgement_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_group_by_pkArgs = {
  _inc?: InputMaybe<acknowledgement_group_inc_input>;
  _set?: InputMaybe<acknowledgement_group_set_input>;
  pk_columns: acknowledgement_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_group_manyArgs = {
  updates: Array<acknowledgement_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_group_versionArgs = {
  _append?: InputMaybe<acknowledgement_group_version_append_input>;
  _delete_at_path?: InputMaybe<acknowledgement_group_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<acknowledgement_group_version_delete_elem_input>;
  _delete_key?: InputMaybe<acknowledgement_group_version_delete_key_input>;
  _inc?: InputMaybe<acknowledgement_group_version_inc_input>;
  _prepend?: InputMaybe<acknowledgement_group_version_prepend_input>;
  _set?: InputMaybe<acknowledgement_group_version_set_input>;
  where: acknowledgement_group_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_group_version_by_pkArgs = {
  _append?: InputMaybe<acknowledgement_group_version_append_input>;
  _delete_at_path?: InputMaybe<acknowledgement_group_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<acknowledgement_group_version_delete_elem_input>;
  _delete_key?: InputMaybe<acknowledgement_group_version_delete_key_input>;
  _inc?: InputMaybe<acknowledgement_group_version_inc_input>;
  _prepend?: InputMaybe<acknowledgement_group_version_prepend_input>;
  _set?: InputMaybe<acknowledgement_group_version_set_input>;
  pk_columns: acknowledgement_group_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_group_version_manyArgs = {
  updates: Array<acknowledgement_group_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_manyArgs = {
  updates: Array<acknowledgement_updates>;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_versionArgs = {
  _inc?: InputMaybe<acknowledgement_version_inc_input>;
  _set?: InputMaybe<acknowledgement_version_set_input>;
  where: acknowledgement_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_version_by_pkArgs = {
  _inc?: InputMaybe<acknowledgement_version_inc_input>;
  _set?: InputMaybe<acknowledgement_version_set_input>;
  pk_columns: acknowledgement_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_acknowledgement_version_manyArgs = {
  updates: Array<acknowledgement_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_active_portal_sessionArgs = {
  _inc?: InputMaybe<active_portal_session_inc_input>;
  _set?: InputMaybe<active_portal_session_set_input>;
  where: active_portal_session_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_active_portal_session_manyArgs = {
  updates: Array<active_portal_session_updates>;
};


/** mutation root */
export type mutation_rootupdate_annotationArgs = {
  _inc?: InputMaybe<annotation_inc_input>;
  _set?: InputMaybe<annotation_set_input>;
  where: annotation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_annotation_by_pkArgs = {
  _inc?: InputMaybe<annotation_inc_input>;
  _set?: InputMaybe<annotation_set_input>;
  pk_columns: annotation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_annotation_flow_versionArgs = {
  _inc?: InputMaybe<annotation_flow_version_inc_input>;
  _set?: InputMaybe<annotation_flow_version_set_input>;
  where: annotation_flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_annotation_flow_version_by_pkArgs = {
  _inc?: InputMaybe<annotation_flow_version_inc_input>;
  _set?: InputMaybe<annotation_flow_version_set_input>;
  pk_columns: annotation_flow_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_annotation_flow_version_manyArgs = {
  updates: Array<annotation_flow_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_annotation_manyArgs = {
  updates: Array<annotation_updates>;
};


/** mutation root */
export type mutation_rootupdate_annotation_typeArgs = {
  _set?: InputMaybe<annotation_type_set_input>;
  where: annotation_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_annotation_type_by_pkArgs = {
  _set?: InputMaybe<annotation_type_set_input>;
  pk_columns: annotation_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_annotation_type_manyArgs = {
  updates: Array<annotation_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_api_keyArgs = {
  _inc?: InputMaybe<api_key_inc_input>;
  _set?: InputMaybe<api_key_set_input>;
  where: api_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_api_key_by_pkArgs = {
  _inc?: InputMaybe<api_key_inc_input>;
  _set?: InputMaybe<api_key_set_input>;
  pk_columns: api_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_api_key_manyArgs = {
  updates: Array<api_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_api_key_purposeArgs = {
  _set?: InputMaybe<api_key_purpose_set_input>;
  where: api_key_purpose_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_api_key_purpose_by_pkArgs = {
  _set?: InputMaybe<api_key_purpose_set_input>;
  pk_columns: api_key_purpose_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_api_key_purpose_manyArgs = {
  updates: Array<api_key_purpose_updates>;
};


/** mutation root */
export type mutation_rootupdate_campaignArgs = {
  _inc?: InputMaybe<campaign_inc_input>;
  _set?: InputMaybe<campaign_set_input>;
  where: campaign_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_campaign_by_pkArgs = {
  _inc?: InputMaybe<campaign_inc_input>;
  _set?: InputMaybe<campaign_set_input>;
  pk_columns: campaign_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_campaign_manyArgs = {
  updates: Array<campaign_updates>;
};


/** mutation root */
export type mutation_rootupdate_campaign_textArgs = {
  _inc?: InputMaybe<campaign_text_inc_input>;
  _set?: InputMaybe<campaign_text_set_input>;
  where: campaign_text_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_campaign_text_by_pkArgs = {
  _inc?: InputMaybe<campaign_text_inc_input>;
  _set?: InputMaybe<campaign_text_set_input>;
  pk_columns: campaign_text_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_campaign_text_manyArgs = {
  updates: Array<campaign_text_updates>;
};


/** mutation root */
export type mutation_rootupdate_campaign_text_versionArgs = {
  _inc?: InputMaybe<campaign_text_version_inc_input>;
  _set?: InputMaybe<campaign_text_version_set_input>;
  where: campaign_text_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_campaign_text_version_by_pkArgs = {
  _inc?: InputMaybe<campaign_text_version_inc_input>;
  _set?: InputMaybe<campaign_text_version_set_input>;
  pk_columns: campaign_text_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_campaign_text_version_manyArgs = {
  updates: Array<campaign_text_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_campaign_trigger_typeArgs = {
  _set?: InputMaybe<campaign_trigger_type_set_input>;
  where: campaign_trigger_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_campaign_trigger_type_by_pkArgs = {
  _set?: InputMaybe<campaign_trigger_type_set_input>;
  pk_columns: campaign_trigger_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_campaign_trigger_type_manyArgs = {
  updates: Array<campaign_trigger_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_campaign_versionArgs = {
  _inc?: InputMaybe<campaign_version_inc_input>;
  _set?: InputMaybe<campaign_version_set_input>;
  where: campaign_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_campaign_version_by_pkArgs = {
  _inc?: InputMaybe<campaign_version_inc_input>;
  _set?: InputMaybe<campaign_version_set_input>;
  pk_columns: campaign_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_campaign_version_manyArgs = {
  updates: Array<campaign_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_confirmationArgs = {
  _inc?: InputMaybe<confirmation_inc_input>;
  _set?: InputMaybe<confirmation_set_input>;
  where: confirmation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_confirmation_by_pkArgs = {
  _inc?: InputMaybe<confirmation_inc_input>;
  _set?: InputMaybe<confirmation_set_input>;
  pk_columns: confirmation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_confirmation_manyArgs = {
  updates: Array<confirmation_updates>;
};


/** mutation root */
export type mutation_rootupdate_confirmation_versionArgs = {
  _inc?: InputMaybe<confirmation_version_inc_input>;
  _set?: InputMaybe<confirmation_version_set_input>;
  where: confirmation_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_confirmation_version_by_pkArgs = {
  _inc?: InputMaybe<confirmation_version_inc_input>;
  _set?: InputMaybe<confirmation_version_set_input>;
  pk_columns: confirmation_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_confirmation_version_manyArgs = {
  updates: Array<confirmation_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_countryArgs = {
  _set?: InputMaybe<country_set_input>;
  where: country_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_country_by_pkArgs = {
  _set?: InputMaybe<country_set_input>;
  pk_columns: country_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_country_manyArgs = {
  updates: Array<country_updates>;
};


/** mutation root */
export type mutation_rootupdate_coupon_durationArgs = {
  _set?: InputMaybe<coupon_duration_set_input>;
  where: coupon_duration_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_coupon_duration_by_pkArgs = {
  _set?: InputMaybe<coupon_duration_set_input>;
  pk_columns: coupon_duration_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_coupon_duration_intervalArgs = {
  _set?: InputMaybe<coupon_duration_interval_set_input>;
  where: coupon_duration_interval_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_coupon_duration_interval_by_pkArgs = {
  _set?: InputMaybe<coupon_duration_interval_set_input>;
  pk_columns: coupon_duration_interval_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_coupon_duration_interval_manyArgs = {
  updates: Array<coupon_duration_interval_updates>;
};


/** mutation root */
export type mutation_rootupdate_coupon_duration_manyArgs = {
  updates: Array<coupon_duration_updates>;
};


/** mutation root */
export type mutation_rootupdate_csv_delta_fileArgs = {
  _inc?: InputMaybe<csv_delta_file_inc_input>;
  _set?: InputMaybe<csv_delta_file_set_input>;
  where: csv_delta_file_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_csv_delta_file_by_pkArgs = {
  _inc?: InputMaybe<csv_delta_file_inc_input>;
  _set?: InputMaybe<csv_delta_file_set_input>;
  pk_columns: csv_delta_file_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_csv_delta_file_manyArgs = {
  updates: Array<csv_delta_file_updates>;
};


/** mutation root */
export type mutation_rootupdate_csv_importArgs = {
  _append?: InputMaybe<csv_import_append_input>;
  _delete_at_path?: InputMaybe<csv_import_delete_at_path_input>;
  _delete_elem?: InputMaybe<csv_import_delete_elem_input>;
  _delete_key?: InputMaybe<csv_import_delete_key_input>;
  _inc?: InputMaybe<csv_import_inc_input>;
  _prepend?: InputMaybe<csv_import_prepend_input>;
  _set?: InputMaybe<csv_import_set_input>;
  where: csv_import_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_csv_import_by_pkArgs = {
  _append?: InputMaybe<csv_import_append_input>;
  _delete_at_path?: InputMaybe<csv_import_delete_at_path_input>;
  _delete_elem?: InputMaybe<csv_import_delete_elem_input>;
  _delete_key?: InputMaybe<csv_import_delete_key_input>;
  _inc?: InputMaybe<csv_import_inc_input>;
  _prepend?: InputMaybe<csv_import_prepend_input>;
  _set?: InputMaybe<csv_import_set_input>;
  pk_columns: csv_import_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_csv_import_fileArgs = {
  _inc?: InputMaybe<csv_import_file_inc_input>;
  _set?: InputMaybe<csv_import_file_set_input>;
  where: csv_import_file_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_csv_import_file_by_pkArgs = {
  _inc?: InputMaybe<csv_import_file_inc_input>;
  _set?: InputMaybe<csv_import_file_set_input>;
  pk_columns: csv_import_file_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_csv_import_file_manyArgs = {
  updates: Array<csv_import_file_updates>;
};


/** mutation root */
export type mutation_rootupdate_csv_import_manyArgs = {
  updates: Array<csv_import_updates>;
};


/** mutation root */
export type mutation_rootupdate_csv_import_statusArgs = {
  _set?: InputMaybe<csv_import_status_set_input>;
  where: csv_import_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_csv_import_status_by_pkArgs = {
  _set?: InputMaybe<csv_import_status_set_input>;
  pk_columns: csv_import_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_csv_import_status_manyArgs = {
  updates: Array<csv_import_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_data_connectorArgs = {
  _inc?: InputMaybe<data_connector_inc_input>;
  _set?: InputMaybe<data_connector_set_input>;
  where: data_connector_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_data_connector_by_pkArgs = {
  _inc?: InputMaybe<data_connector_inc_input>;
  _set?: InputMaybe<data_connector_set_input>;
  pk_columns: data_connector_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_data_connector_manyArgs = {
  updates: Array<data_connector_updates>;
};


/** mutation root */
export type mutation_rootupdate_data_connector_request_logArgs = {
  _append?: InputMaybe<data_connector_request_log_append_input>;
  _delete_at_path?: InputMaybe<data_connector_request_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<data_connector_request_log_delete_elem_input>;
  _delete_key?: InputMaybe<data_connector_request_log_delete_key_input>;
  _inc?: InputMaybe<data_connector_request_log_inc_input>;
  _prepend?: InputMaybe<data_connector_request_log_prepend_input>;
  _set?: InputMaybe<data_connector_request_log_set_input>;
  where: data_connector_request_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_data_connector_request_log_by_pkArgs = {
  _append?: InputMaybe<data_connector_request_log_append_input>;
  _delete_at_path?: InputMaybe<data_connector_request_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<data_connector_request_log_delete_elem_input>;
  _delete_key?: InputMaybe<data_connector_request_log_delete_key_input>;
  _inc?: InputMaybe<data_connector_request_log_inc_input>;
  _prepend?: InputMaybe<data_connector_request_log_prepend_input>;
  _set?: InputMaybe<data_connector_request_log_set_input>;
  pk_columns: data_connector_request_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_data_connector_request_log_manyArgs = {
  updates: Array<data_connector_request_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_deflectionArgs = {
  _append?: InputMaybe<deflection_append_input>;
  _delete_at_path?: InputMaybe<deflection_delete_at_path_input>;
  _delete_elem?: InputMaybe<deflection_delete_elem_input>;
  _delete_key?: InputMaybe<deflection_delete_key_input>;
  _inc?: InputMaybe<deflection_inc_input>;
  _prepend?: InputMaybe<deflection_prepend_input>;
  _set?: InputMaybe<deflection_set_input>;
  where: deflection_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_deflection_by_pkArgs = {
  _append?: InputMaybe<deflection_append_input>;
  _delete_at_path?: InputMaybe<deflection_delete_at_path_input>;
  _delete_elem?: InputMaybe<deflection_delete_elem_input>;
  _delete_key?: InputMaybe<deflection_delete_key_input>;
  _inc?: InputMaybe<deflection_inc_input>;
  _prepend?: InputMaybe<deflection_prepend_input>;
  _set?: InputMaybe<deflection_set_input>;
  pk_columns: deflection_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_deflection_manyArgs = {
  updates: Array<deflection_updates>;
};


/** mutation root */
export type mutation_rootupdate_deflection_snapshot_itemArgs = {
  _append?: InputMaybe<deflection_snapshot_item_append_input>;
  _delete_at_path?: InputMaybe<deflection_snapshot_item_delete_at_path_input>;
  _delete_elem?: InputMaybe<deflection_snapshot_item_delete_elem_input>;
  _delete_key?: InputMaybe<deflection_snapshot_item_delete_key_input>;
  _inc?: InputMaybe<deflection_snapshot_item_inc_input>;
  _prepend?: InputMaybe<deflection_snapshot_item_prepend_input>;
  _set?: InputMaybe<deflection_snapshot_item_set_input>;
  where: deflection_snapshot_item_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_deflection_snapshot_item_by_pkArgs = {
  _append?: InputMaybe<deflection_snapshot_item_append_input>;
  _delete_at_path?: InputMaybe<deflection_snapshot_item_delete_at_path_input>;
  _delete_elem?: InputMaybe<deflection_snapshot_item_delete_elem_input>;
  _delete_key?: InputMaybe<deflection_snapshot_item_delete_key_input>;
  _inc?: InputMaybe<deflection_snapshot_item_inc_input>;
  _prepend?: InputMaybe<deflection_snapshot_item_prepend_input>;
  _set?: InputMaybe<deflection_snapshot_item_set_input>;
  pk_columns: deflection_snapshot_item_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_deflection_snapshot_item_manyArgs = {
  updates: Array<deflection_snapshot_item_updates>;
};


/** mutation root */
export type mutation_rootupdate_deflection_typeArgs = {
  _set?: InputMaybe<deflection_type_set_input>;
  where: deflection_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_deflection_type_by_pkArgs = {
  _set?: InputMaybe<deflection_type_set_input>;
  pk_columns: deflection_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_deflection_type_manyArgs = {
  updates: Array<deflection_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_demoArgs = {
  _append?: InputMaybe<demo_append_input>;
  _delete_at_path?: InputMaybe<demo_delete_at_path_input>;
  _delete_elem?: InputMaybe<demo_delete_elem_input>;
  _delete_key?: InputMaybe<demo_delete_key_input>;
  _inc?: InputMaybe<demo_inc_input>;
  _prepend?: InputMaybe<demo_prepend_input>;
  _set?: InputMaybe<demo_set_input>;
  where: demo_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_demo_by_pkArgs = {
  _append?: InputMaybe<demo_append_input>;
  _delete_at_path?: InputMaybe<demo_delete_at_path_input>;
  _delete_elem?: InputMaybe<demo_delete_elem_input>;
  _delete_key?: InputMaybe<demo_delete_key_input>;
  _inc?: InputMaybe<demo_inc_input>;
  _prepend?: InputMaybe<demo_prepend_input>;
  _set?: InputMaybe<demo_set_input>;
  pk_columns: demo_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_demo_manyArgs = {
  updates: Array<demo_updates>;
};


/** mutation root */
export type mutation_rootupdate_domainArgs = {
  _inc?: InputMaybe<domain_inc_input>;
  _set?: InputMaybe<domain_set_input>;
  where: domain_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_domain_by_pkArgs = {
  _inc?: InputMaybe<domain_inc_input>;
  _set?: InputMaybe<domain_set_input>;
  pk_columns: domain_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_domain_manyArgs = {
  updates: Array<domain_updates>;
};


/** mutation root */
export type mutation_rootupdate_domain_typeArgs = {
  _set?: InputMaybe<domain_type_set_input>;
  where: domain_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_domain_type_by_pkArgs = {
  _set?: InputMaybe<domain_type_set_input>;
  pk_columns: domain_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_domain_type_manyArgs = {
  updates: Array<domain_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_eligibility_messageArgs = {
  _inc?: InputMaybe<eligibility_message_inc_input>;
  _set?: InputMaybe<eligibility_message_set_input>;
  where: eligibility_message_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_eligibility_message_by_pkArgs = {
  _inc?: InputMaybe<eligibility_message_inc_input>;
  _set?: InputMaybe<eligibility_message_set_input>;
  pk_columns: eligibility_message_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_eligibility_message_manyArgs = {
  updates: Array<eligibility_message_updates>;
};


/** mutation root */
export type mutation_rootupdate_eventArgs = {
  _append?: InputMaybe<event_append_input>;
  _delete_at_path?: InputMaybe<event_delete_at_path_input>;
  _delete_elem?: InputMaybe<event_delete_elem_input>;
  _delete_key?: InputMaybe<event_delete_key_input>;
  _inc?: InputMaybe<event_inc_input>;
  _prepend?: InputMaybe<event_prepend_input>;
  _set?: InputMaybe<event_set_input>;
  where: event_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_event_by_pkArgs = {
  _append?: InputMaybe<event_append_input>;
  _delete_at_path?: InputMaybe<event_delete_at_path_input>;
  _delete_elem?: InputMaybe<event_delete_elem_input>;
  _delete_key?: InputMaybe<event_delete_key_input>;
  _inc?: InputMaybe<event_inc_input>;
  _prepend?: InputMaybe<event_prepend_input>;
  _set?: InputMaybe<event_set_input>;
  pk_columns: event_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_event_manyArgs = {
  updates: Array<event_updates>;
};


/** mutation root */
export type mutation_rootupdate_event_typeArgs = {
  _set?: InputMaybe<event_type_set_input>;
  where: event_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_event_type_by_pkArgs = {
  _set?: InputMaybe<event_type_set_input>;
  pk_columns: event_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_event_type_manyArgs = {
  updates: Array<event_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_flowArgs = {
  _append?: InputMaybe<flow_append_input>;
  _delete_at_path?: InputMaybe<flow_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_delete_elem_input>;
  _delete_key?: InputMaybe<flow_delete_key_input>;
  _inc?: InputMaybe<flow_inc_input>;
  _prepend?: InputMaybe<flow_prepend_input>;
  _set?: InputMaybe<flow_set_input>;
  where: flow_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_actionArgs = {
  _inc?: InputMaybe<flow_action_inc_input>;
  _set?: InputMaybe<flow_action_set_input>;
  where: flow_action_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_action_appearanceArgs = {
  _set?: InputMaybe<flow_action_appearance_set_input>;
  where: flow_action_appearance_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_action_appearance_by_pkArgs = {
  _set?: InputMaybe<flow_action_appearance_set_input>;
  pk_columns: flow_action_appearance_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_action_appearance_manyArgs = {
  updates: Array<flow_action_appearance_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_action_by_pkArgs = {
  _inc?: InputMaybe<flow_action_inc_input>;
  _set?: InputMaybe<flow_action_set_input>;
  pk_columns: flow_action_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_action_manyArgs = {
  updates: Array<flow_action_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_action_typeArgs = {
  _set?: InputMaybe<flow_action_type_set_input>;
  where: flow_action_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_action_type_by_pkArgs = {
  _set?: InputMaybe<flow_action_type_set_input>;
  pk_columns: flow_action_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_action_type_manyArgs = {
  updates: Array<flow_action_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_auto_cancel_atArgs = {
  _set?: InputMaybe<flow_auto_cancel_at_set_input>;
  where: flow_auto_cancel_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_auto_cancel_at_by_pkArgs = {
  _set?: InputMaybe<flow_auto_cancel_at_set_input>;
  pk_columns: flow_auto_cancel_at_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_auto_cancel_at_manyArgs = {
  updates: Array<flow_auto_cancel_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_by_pkArgs = {
  _append?: InputMaybe<flow_append_input>;
  _delete_at_path?: InputMaybe<flow_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_delete_elem_input>;
  _delete_key?: InputMaybe<flow_delete_key_input>;
  _inc?: InputMaybe<flow_inc_input>;
  _prepend?: InputMaybe<flow_prepend_input>;
  _set?: InputMaybe<flow_set_input>;
  pk_columns: flow_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_languageArgs = {
  _inc?: InputMaybe<flow_language_inc_input>;
  _set?: InputMaybe<flow_language_set_input>;
  where: flow_language_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_language_by_pkArgs = {
  _inc?: InputMaybe<flow_language_inc_input>;
  _set?: InputMaybe<flow_language_set_input>;
  pk_columns: flow_language_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_language_manyArgs = {
  updates: Array<flow_language_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_manyArgs = {
  updates: Array<flow_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancelArgs = {
  _set?: InputMaybe<flow_on_cancel_set_input>;
  where: flow_on_cancel_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_by_pkArgs = {
  _set?: InputMaybe<flow_on_cancel_set_input>;
  pk_columns: flow_on_cancel_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_change_planArgs = {
  _inc?: InputMaybe<flow_on_cancel_change_plan_inc_input>;
  _set?: InputMaybe<flow_on_cancel_change_plan_set_input>;
  where: flow_on_cancel_change_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_change_plan_by_pkArgs = {
  _inc?: InputMaybe<flow_on_cancel_change_plan_inc_input>;
  _set?: InputMaybe<flow_on_cancel_change_plan_set_input>;
  pk_columns: flow_on_cancel_change_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_change_plan_manyArgs = {
  updates: Array<flow_on_cancel_change_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_manyArgs = {
  updates: Array<flow_on_cancel_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_perform_atArgs = {
  _set?: InputMaybe<flow_on_cancel_perform_at_set_input>;
  where: flow_on_cancel_perform_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_perform_at_by_pkArgs = {
  _set?: InputMaybe<flow_on_cancel_perform_at_set_input>;
  pk_columns: flow_on_cancel_perform_at_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_on_cancel_perform_at_manyArgs = {
  updates: Array<flow_on_cancel_perform_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_route_ruleArgs = {
  _append?: InputMaybe<flow_route_rule_append_input>;
  _delete_at_path?: InputMaybe<flow_route_rule_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_route_rule_delete_elem_input>;
  _delete_key?: InputMaybe<flow_route_rule_delete_key_input>;
  _inc?: InputMaybe<flow_route_rule_inc_input>;
  _prepend?: InputMaybe<flow_route_rule_prepend_input>;
  _set?: InputMaybe<flow_route_rule_set_input>;
  where: flow_route_rule_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_route_rule_by_pkArgs = {
  _append?: InputMaybe<flow_route_rule_append_input>;
  _delete_at_path?: InputMaybe<flow_route_rule_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_route_rule_delete_elem_input>;
  _delete_key?: InputMaybe<flow_route_rule_delete_key_input>;
  _inc?: InputMaybe<flow_route_rule_inc_input>;
  _prepend?: InputMaybe<flow_route_rule_prepend_input>;
  _set?: InputMaybe<flow_route_rule_set_input>;
  pk_columns: flow_route_rule_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_route_rule_manyArgs = {
  updates: Array<flow_route_rule_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_stepArgs = {
  _inc?: InputMaybe<flow_step_inc_input>;
  _set?: InputMaybe<flow_step_set_input>;
  where: flow_step_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_acknowledgement_groupArgs = {
  _inc?: InputMaybe<flow_step_acknowledgement_group_inc_input>;
  _set?: InputMaybe<flow_step_acknowledgement_group_set_input>;
  where: flow_step_acknowledgement_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_acknowledgement_group_by_pkArgs = {
  _inc?: InputMaybe<flow_step_acknowledgement_group_inc_input>;
  _set?: InputMaybe<flow_step_acknowledgement_group_set_input>;
  pk_columns: flow_step_acknowledgement_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_acknowledgement_group_manyArgs = {
  updates: Array<flow_step_acknowledgement_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_by_pkArgs = {
  _inc?: InputMaybe<flow_step_inc_input>;
  _set?: InputMaybe<flow_step_set_input>;
  pk_columns: flow_step_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_confirmationArgs = {
  _inc?: InputMaybe<flow_step_confirmation_inc_input>;
  _set?: InputMaybe<flow_step_confirmation_set_input>;
  where: flow_step_confirmation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_confirmation_by_pkArgs = {
  _inc?: InputMaybe<flow_step_confirmation_inc_input>;
  _set?: InputMaybe<flow_step_confirmation_set_input>;
  pk_columns: flow_step_confirmation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_confirmation_manyArgs = {
  updates: Array<flow_step_confirmation_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_deflection_rule_groupArgs = {
  _inc?: InputMaybe<flow_step_deflection_rule_group_inc_input>;
  _set?: InputMaybe<flow_step_deflection_rule_group_set_input>;
  where: flow_step_deflection_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_deflection_rule_group_manyArgs = {
  updates: Array<flow_step_deflection_rule_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_formArgs = {
  _inc?: InputMaybe<flow_step_form_inc_input>;
  _set?: InputMaybe<flow_step_form_set_input>;
  where: flow_step_form_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_form_by_pkArgs = {
  _inc?: InputMaybe<flow_step_form_inc_input>;
  _set?: InputMaybe<flow_step_form_set_input>;
  pk_columns: flow_step_form_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_form_manyArgs = {
  updates: Array<flow_step_form_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_interventionArgs = {
  _inc?: InputMaybe<flow_step_intervention_inc_input>;
  _set?: InputMaybe<flow_step_intervention_set_input>;
  where: flow_step_intervention_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_intervention_by_pkArgs = {
  _inc?: InputMaybe<flow_step_intervention_inc_input>;
  _set?: InputMaybe<flow_step_intervention_set_input>;
  pk_columns: flow_step_intervention_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_intervention_manyArgs = {
  updates: Array<flow_step_intervention_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_manyArgs = {
  updates: Array<flow_step_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_offer_rule_groupArgs = {
  _inc?: InputMaybe<flow_step_offer_rule_group_inc_input>;
  _set?: InputMaybe<flow_step_offer_rule_group_set_input>;
  where: flow_step_offer_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_offer_rule_group_manyArgs = {
  updates: Array<flow_step_offer_rule_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_questionArgs = {
  _inc?: InputMaybe<flow_step_question_inc_input>;
  _set?: InputMaybe<flow_step_question_set_input>;
  where: flow_step_question_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_question_by_pkArgs = {
  _inc?: InputMaybe<flow_step_question_inc_input>;
  _set?: InputMaybe<flow_step_question_set_input>;
  pk_columns: flow_step_question_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_question_manyArgs = {
  updates: Array<flow_step_question_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_rule_groupArgs = {
  _inc?: InputMaybe<flow_step_rule_group_inc_input>;
  _set?: InputMaybe<flow_step_rule_group_set_input>;
  where: flow_step_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_rule_group_by_pkArgs = {
  _inc?: InputMaybe<flow_step_rule_group_inc_input>;
  _set?: InputMaybe<flow_step_rule_group_set_input>;
  pk_columns: flow_step_rule_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_rule_group_manyArgs = {
  updates: Array<flow_step_rule_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_typeArgs = {
  _set?: InputMaybe<flow_step_type_set_input>;
  where: flow_step_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_type_by_pkArgs = {
  _set?: InputMaybe<flow_step_type_set_input>;
  pk_columns: flow_step_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_type_manyArgs = {
  updates: Array<flow_step_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_step_versionArgs = {
  _append?: InputMaybe<flow_step_version_append_input>;
  _delete_at_path?: InputMaybe<flow_step_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_step_version_delete_elem_input>;
  _delete_key?: InputMaybe<flow_step_version_delete_key_input>;
  _inc?: InputMaybe<flow_step_version_inc_input>;
  _prepend?: InputMaybe<flow_step_version_prepend_input>;
  _set?: InputMaybe<flow_step_version_set_input>;
  where: flow_step_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_step_version_by_pkArgs = {
  _append?: InputMaybe<flow_step_version_append_input>;
  _delete_at_path?: InputMaybe<flow_step_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_step_version_delete_elem_input>;
  _delete_key?: InputMaybe<flow_step_version_delete_key_input>;
  _inc?: InputMaybe<flow_step_version_inc_input>;
  _prepend?: InputMaybe<flow_step_version_prepend_input>;
  _set?: InputMaybe<flow_step_version_set_input>;
  pk_columns: flow_step_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_step_version_manyArgs = {
  updates: Array<flow_step_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_subscriber_form_questionArgs = {
  _inc?: InputMaybe<flow_subscriber_form_question_inc_input>;
  _set?: InputMaybe<flow_subscriber_form_question_set_input>;
  where: flow_subscriber_form_question_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_subscriber_form_question_by_pkArgs = {
  _inc?: InputMaybe<flow_subscriber_form_question_inc_input>;
  _set?: InputMaybe<flow_subscriber_form_question_set_input>;
  pk_columns: flow_subscriber_form_question_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_subscriber_form_question_manyArgs = {
  updates: Array<flow_subscriber_form_question_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_testArgs = {
  _inc?: InputMaybe<flow_test_inc_input>;
  _set?: InputMaybe<flow_test_set_input>;
  where: flow_test_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_test_by_pkArgs = {
  _inc?: InputMaybe<flow_test_inc_input>;
  _set?: InputMaybe<flow_test_set_input>;
  pk_columns: flow_test_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_test_flowArgs = {
  _inc?: InputMaybe<flow_test_flow_inc_input>;
  _set?: InputMaybe<flow_test_flow_set_input>;
  where: flow_test_flow_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_test_flow_by_pkArgs = {
  _inc?: InputMaybe<flow_test_flow_inc_input>;
  _set?: InputMaybe<flow_test_flow_set_input>;
  pk_columns: flow_test_flow_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_test_flow_manyArgs = {
  updates: Array<flow_test_flow_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_test_manyArgs = {
  updates: Array<flow_test_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_textArgs = {
  _inc?: InputMaybe<flow_text_inc_input>;
  _set?: InputMaybe<flow_text_set_input>;
  where: flow_text_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_text_by_pkArgs = {
  _inc?: InputMaybe<flow_text_inc_input>;
  _set?: InputMaybe<flow_text_set_input>;
  pk_columns: flow_text_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_text_keyArgs = {
  _set?: InputMaybe<flow_text_key_set_input>;
  where: flow_text_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_text_key_by_pkArgs = {
  _set?: InputMaybe<flow_text_key_set_input>;
  pk_columns: flow_text_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_text_key_manyArgs = {
  updates: Array<flow_text_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_text_manyArgs = {
  updates: Array<flow_text_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_text_versionArgs = {
  _inc?: InputMaybe<flow_text_version_inc_input>;
  _set?: InputMaybe<flow_text_version_set_input>;
  where: flow_text_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_text_version_by_pkArgs = {
  _inc?: InputMaybe<flow_text_version_inc_input>;
  _set?: InputMaybe<flow_text_version_set_input>;
  pk_columns: flow_text_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_text_version_manyArgs = {
  updates: Array<flow_text_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_flow_versionArgs = {
  _append?: InputMaybe<flow_version_append_input>;
  _delete_at_path?: InputMaybe<flow_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_version_delete_elem_input>;
  _delete_key?: InputMaybe<flow_version_delete_key_input>;
  _inc?: InputMaybe<flow_version_inc_input>;
  _prepend?: InputMaybe<flow_version_prepend_input>;
  _set?: InputMaybe<flow_version_set_input>;
  where: flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_flow_version_by_pkArgs = {
  _append?: InputMaybe<flow_version_append_input>;
  _delete_at_path?: InputMaybe<flow_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<flow_version_delete_elem_input>;
  _delete_key?: InputMaybe<flow_version_delete_key_input>;
  _inc?: InputMaybe<flow_version_inc_input>;
  _prepend?: InputMaybe<flow_version_prepend_input>;
  _set?: InputMaybe<flow_version_set_input>;
  pk_columns: flow_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_flow_version_manyArgs = {
  updates: Array<flow_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_formArgs = {
  _inc?: InputMaybe<form_inc_input>;
  _set?: InputMaybe<form_set_input>;
  where: form_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_form_by_pkArgs = {
  _inc?: InputMaybe<form_inc_input>;
  _set?: InputMaybe<form_set_input>;
  pk_columns: form_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_form_manyArgs = {
  updates: Array<form_updates>;
};


/** mutation root */
export type mutation_rootupdate_form_versionArgs = {
  _append?: InputMaybe<form_version_append_input>;
  _delete_at_path?: InputMaybe<form_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<form_version_delete_elem_input>;
  _delete_key?: InputMaybe<form_version_delete_key_input>;
  _inc?: InputMaybe<form_version_inc_input>;
  _prepend?: InputMaybe<form_version_prepend_input>;
  _set?: InputMaybe<form_version_set_input>;
  where: form_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_form_version_by_pkArgs = {
  _append?: InputMaybe<form_version_append_input>;
  _delete_at_path?: InputMaybe<form_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<form_version_delete_elem_input>;
  _delete_key?: InputMaybe<form_version_delete_key_input>;
  _inc?: InputMaybe<form_version_inc_input>;
  _prepend?: InputMaybe<form_version_prepend_input>;
  _set?: InputMaybe<form_version_set_input>;
  pk_columns: form_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_form_version_manyArgs = {
  updates: Array<form_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_function_logArgs = {
  _append?: InputMaybe<function_log_append_input>;
  _delete_at_path?: InputMaybe<function_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<function_log_delete_elem_input>;
  _delete_key?: InputMaybe<function_log_delete_key_input>;
  _inc?: InputMaybe<function_log_inc_input>;
  _prepend?: InputMaybe<function_log_prepend_input>;
  _set?: InputMaybe<function_log_set_input>;
  where: function_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_function_log_by_pkArgs = {
  _append?: InputMaybe<function_log_append_input>;
  _delete_at_path?: InputMaybe<function_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<function_log_delete_elem_input>;
  _delete_key?: InputMaybe<function_log_delete_key_input>;
  _inc?: InputMaybe<function_log_inc_input>;
  _prepend?: InputMaybe<function_log_prepend_input>;
  _set?: InputMaybe<function_log_set_input>;
  pk_columns: function_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_function_log_manyArgs = {
  updates: Array<function_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_integrationArgs = {
  _append?: InputMaybe<integration_append_input>;
  _delete_at_path?: InputMaybe<integration_delete_at_path_input>;
  _delete_elem?: InputMaybe<integration_delete_elem_input>;
  _delete_key?: InputMaybe<integration_delete_key_input>;
  _inc?: InputMaybe<integration_inc_input>;
  _prepend?: InputMaybe<integration_prepend_input>;
  _set?: InputMaybe<integration_set_input>;
  where: integration_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_integration_by_pkArgs = {
  _append?: InputMaybe<integration_append_input>;
  _delete_at_path?: InputMaybe<integration_delete_at_path_input>;
  _delete_elem?: InputMaybe<integration_delete_elem_input>;
  _delete_key?: InputMaybe<integration_delete_key_input>;
  _inc?: InputMaybe<integration_inc_input>;
  _prepend?: InputMaybe<integration_prepend_input>;
  _set?: InputMaybe<integration_set_input>;
  pk_columns: integration_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_integration_manyArgs = {
  updates: Array<integration_updates>;
};


/** mutation root */
export type mutation_rootupdate_integration_typeArgs = {
  _set?: InputMaybe<integration_type_set_input>;
  where: integration_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_integration_type_by_pkArgs = {
  _set?: InputMaybe<integration_type_set_input>;
  pk_columns: integration_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_integration_type_manyArgs = {
  updates: Array<integration_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_interventionArgs = {
  _inc?: InputMaybe<intervention_inc_input>;
  _set?: InputMaybe<intervention_set_input>;
  where: intervention_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_intervention_by_pkArgs = {
  _inc?: InputMaybe<intervention_inc_input>;
  _set?: InputMaybe<intervention_set_input>;
  pk_columns: intervention_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_intervention_manyArgs = {
  updates: Array<intervention_updates>;
};


/** mutation root */
export type mutation_rootupdate_intervention_versionArgs = {
  _inc?: InputMaybe<intervention_version_inc_input>;
  _set?: InputMaybe<intervention_version_set_input>;
  where: intervention_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_intervention_version_by_pkArgs = {
  _inc?: InputMaybe<intervention_version_inc_input>;
  _set?: InputMaybe<intervention_version_set_input>;
  pk_columns: intervention_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_intervention_version_manyArgs = {
  updates: Array<intervention_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_jobArgs = {
  _append?: InputMaybe<job_append_input>;
  _delete_at_path?: InputMaybe<job_delete_at_path_input>;
  _delete_elem?: InputMaybe<job_delete_elem_input>;
  _delete_key?: InputMaybe<job_delete_key_input>;
  _inc?: InputMaybe<job_inc_input>;
  _prepend?: InputMaybe<job_prepend_input>;
  _set?: InputMaybe<job_set_input>;
  where: job_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_job_by_pkArgs = {
  _append?: InputMaybe<job_append_input>;
  _delete_at_path?: InputMaybe<job_delete_at_path_input>;
  _delete_elem?: InputMaybe<job_delete_elem_input>;
  _delete_key?: InputMaybe<job_delete_key_input>;
  _inc?: InputMaybe<job_inc_input>;
  _prepend?: InputMaybe<job_prepend_input>;
  _set?: InputMaybe<job_set_input>;
  pk_columns: job_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_job_manyArgs = {
  updates: Array<job_updates>;
};


/** mutation root */
export type mutation_rootupdate_languageArgs = {
  _set?: InputMaybe<language_set_input>;
  where: language_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_language_by_pkArgs = {
  _set?: InputMaybe<language_set_input>;
  pk_columns: language_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_language_manyArgs = {
  updates: Array<language_updates>;
};


/** mutation root */
export type mutation_rootupdate_most_recent_subscriber_flow_versionArgs = {
  _inc?: InputMaybe<most_recent_subscriber_flow_version_inc_input>;
  _set?: InputMaybe<most_recent_subscriber_flow_version_set_input>;
  where: most_recent_subscriber_flow_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_most_recent_subscriber_flow_version_manyArgs = {
  updates: Array<most_recent_subscriber_flow_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_naviga_cancel_linkArgs = {
  _set?: InputMaybe<naviga_cancel_link_set_input>;
  where: naviga_cancel_link_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_naviga_cancel_link_by_pkArgs = {
  _set?: InputMaybe<naviga_cancel_link_set_input>;
  pk_columns: naviga_cancel_link_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_naviga_cancel_link_manyArgs = {
  updates: Array<naviga_cancel_link_updates>;
};


/** mutation root */
export type mutation_rootupdate_naviga_domainArgs = {
  _set?: InputMaybe<naviga_domain_set_input>;
  where: naviga_domain_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_naviga_domain_by_pkArgs = {
  _set?: InputMaybe<naviga_domain_set_input>;
  pk_columns: naviga_domain_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_naviga_domain_manyArgs = {
  updates: Array<naviga_domain_updates>;
};


/** mutation root */
export type mutation_rootupdate_naviga_paper_codeArgs = {
  _set?: InputMaybe<naviga_paper_code_set_input>;
  where: naviga_paper_code_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_naviga_paper_code_by_pkArgs = {
  _set?: InputMaybe<naviga_paper_code_set_input>;
  pk_columns: naviga_paper_code_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_naviga_paper_code_manyArgs = {
  updates: Array<naviga_paper_code_updates>;
};


/** mutation root */
export type mutation_rootupdate_offerArgs = {
  _append?: InputMaybe<offer_append_input>;
  _delete_at_path?: InputMaybe<offer_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_delete_elem_input>;
  _delete_key?: InputMaybe<offer_delete_key_input>;
  _inc?: InputMaybe<offer_inc_input>;
  _prepend?: InputMaybe<offer_prepend_input>;
  _set?: InputMaybe<offer_set_input>;
  where: offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilotArgs = {
  _append?: InputMaybe<offer_autopilot_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_prepend_input>;
  _set?: InputMaybe<offer_autopilot_set_input>;
  where: offer_autopilot_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_by_pkArgs = {
  _append?: InputMaybe<offer_autopilot_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_prepend_input>;
  _set?: InputMaybe<offer_autopilot_set_input>;
  pk_columns: offer_autopilot_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_manyArgs = {
  updates: Array<offer_autopilot_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_roundArgs = {
  _append?: InputMaybe<offer_autopilot_round_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_round_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_round_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_round_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_round_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_round_prepend_input>;
  _set?: InputMaybe<offer_autopilot_round_set_input>;
  where: offer_autopilot_round_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_by_pkArgs = {
  _append?: InputMaybe<offer_autopilot_round_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_round_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_round_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_round_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_round_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_round_prepend_input>;
  _set?: InputMaybe<offer_autopilot_round_set_input>;
  pk_columns: offer_autopilot_round_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_logArgs = {
  _append?: InputMaybe<offer_autopilot_round_log_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_round_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_round_log_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_round_log_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_round_log_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_round_log_prepend_input>;
  _set?: InputMaybe<offer_autopilot_round_log_set_input>;
  where: offer_autopilot_round_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_log_by_pkArgs = {
  _append?: InputMaybe<offer_autopilot_round_log_append_input>;
  _delete_at_path?: InputMaybe<offer_autopilot_round_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_autopilot_round_log_delete_elem_input>;
  _delete_key?: InputMaybe<offer_autopilot_round_log_delete_key_input>;
  _inc?: InputMaybe<offer_autopilot_round_log_inc_input>;
  _prepend?: InputMaybe<offer_autopilot_round_log_prepend_input>;
  _set?: InputMaybe<offer_autopilot_round_log_set_input>;
  pk_columns: offer_autopilot_round_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_log_manyArgs = {
  updates: Array<offer_autopilot_round_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_manyArgs = {
  updates: Array<offer_autopilot_round_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_strategyArgs = {
  _set?: InputMaybe<offer_autopilot_round_strategy_set_input>;
  where: offer_autopilot_round_strategy_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_strategy_by_pkArgs = {
  _set?: InputMaybe<offer_autopilot_round_strategy_set_input>;
  pk_columns: offer_autopilot_round_strategy_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_round_strategy_manyArgs = {
  updates: Array<offer_autopilot_round_strategy_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_strategyArgs = {
  _set?: InputMaybe<offer_autopilot_strategy_set_input>;
  where: offer_autopilot_strategy_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_strategy_by_pkArgs = {
  _set?: InputMaybe<offer_autopilot_strategy_set_input>;
  pk_columns: offer_autopilot_strategy_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_strategy_manyArgs = {
  updates: Array<offer_autopilot_strategy_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_success_metricArgs = {
  _set?: InputMaybe<offer_autopilot_success_metric_set_input>;
  where: offer_autopilot_success_metric_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_success_metric_by_pkArgs = {
  _set?: InputMaybe<offer_autopilot_success_metric_set_input>;
  pk_columns: offer_autopilot_success_metric_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_autopilot_success_metric_manyArgs = {
  updates: Array<offer_autopilot_success_metric_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_by_pkArgs = {
  _append?: InputMaybe<offer_append_input>;
  _delete_at_path?: InputMaybe<offer_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_delete_elem_input>;
  _delete_key?: InputMaybe<offer_delete_key_input>;
  _inc?: InputMaybe<offer_inc_input>;
  _prepend?: InputMaybe<offer_prepend_input>;
  _set?: InputMaybe<offer_set_input>;
  pk_columns: offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_change_planArgs = {
  _inc?: InputMaybe<offer_change_plan_inc_input>;
  _set?: InputMaybe<offer_change_plan_set_input>;
  where: offer_change_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_change_plan_by_pkArgs = {
  _inc?: InputMaybe<offer_change_plan_inc_input>;
  _set?: InputMaybe<offer_change_plan_set_input>;
  pk_columns: offer_change_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_change_plan_change_atArgs = {
  _set?: InputMaybe<offer_change_plan_change_at_set_input>;
  where: offer_change_plan_change_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_change_plan_change_at_by_pkArgs = {
  _set?: InputMaybe<offer_change_plan_change_at_set_input>;
  pk_columns: offer_change_plan_change_at_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_change_plan_change_at_manyArgs = {
  updates: Array<offer_change_plan_change_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_change_plan_manyArgs = {
  updates: Array<offer_change_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_couponArgs = {
  _inc?: InputMaybe<offer_coupon_inc_input>;
  _set?: InputMaybe<offer_coupon_set_input>;
  where: offer_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_apply_toArgs = {
  _set?: InputMaybe<offer_coupon_apply_to_set_input>;
  where: offer_coupon_apply_to_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_apply_to_by_pkArgs = {
  _set?: InputMaybe<offer_coupon_apply_to_set_input>;
  pk_columns: offer_coupon_apply_to_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_apply_to_manyArgs = {
  updates: Array<offer_coupon_apply_to_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_by_pkArgs = {
  _inc?: InputMaybe<offer_coupon_inc_input>;
  _set?: InputMaybe<offer_coupon_set_input>;
  pk_columns: offer_coupon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_manyArgs = {
  updates: Array<offer_coupon_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_typeArgs = {
  _set?: InputMaybe<offer_coupon_type_set_input>;
  where: offer_coupon_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_type_by_pkArgs = {
  _set?: InputMaybe<offer_coupon_type_set_input>;
  pk_columns: offer_coupon_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_coupon_type_manyArgs = {
  updates: Array<offer_coupon_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_customArgs = {
  _inc?: InputMaybe<offer_custom_inc_input>;
  _set?: InputMaybe<offer_custom_set_input>;
  where: offer_custom_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_custom_by_pkArgs = {
  _inc?: InputMaybe<offer_custom_inc_input>;
  _set?: InputMaybe<offer_custom_set_input>;
  pk_columns: offer_custom_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_custom_manyArgs = {
  updates: Array<offer_custom_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_downgradeArgs = {
  _inc?: InputMaybe<offer_downgrade_inc_input>;
  _set?: InputMaybe<offer_downgrade_set_input>;
  where: offer_downgrade_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_downgrade_by_pkArgs = {
  _inc?: InputMaybe<offer_downgrade_inc_input>;
  _set?: InputMaybe<offer_downgrade_set_input>;
  pk_columns: offer_downgrade_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_downgrade_manyArgs = {
  updates: Array<offer_downgrade_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_goalArgs = {
  _set?: InputMaybe<offer_goal_set_input>;
  where: offer_goal_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_goal_by_pkArgs = {
  _set?: InputMaybe<offer_goal_set_input>;
  pk_columns: offer_goal_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_goal_manyArgs = {
  updates: Array<offer_goal_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_groupArgs = {
  _append?: InputMaybe<offer_group_append_input>;
  _delete_at_path?: InputMaybe<offer_group_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_group_delete_elem_input>;
  _delete_key?: InputMaybe<offer_group_delete_key_input>;
  _inc?: InputMaybe<offer_group_inc_input>;
  _prepend?: InputMaybe<offer_group_prepend_input>;
  _set?: InputMaybe<offer_group_set_input>;
  where: offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_group_by_pkArgs = {
  _append?: InputMaybe<offer_group_append_input>;
  _delete_at_path?: InputMaybe<offer_group_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_group_delete_elem_input>;
  _delete_key?: InputMaybe<offer_group_delete_key_input>;
  _inc?: InputMaybe<offer_group_inc_input>;
  _prepend?: InputMaybe<offer_group_prepend_input>;
  _set?: InputMaybe<offer_group_set_input>;
  pk_columns: offer_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_group_manyArgs = {
  updates: Array<offer_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_manyArgs = {
  updates: Array<offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscriptionArgs = {
  _inc?: InputMaybe<offer_modify_subscription_inc_input>;
  _set?: InputMaybe<offer_modify_subscription_set_input>;
  where: offer_modify_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_by_pkArgs = {
  _inc?: InputMaybe<offer_modify_subscription_inc_input>;
  _set?: InputMaybe<offer_modify_subscription_set_input>;
  pk_columns: offer_modify_subscription_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_manyArgs = {
  updates: Array<offer_modify_subscription_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_modify_atArgs = {
  _set?: InputMaybe<offer_modify_subscription_modify_at_set_input>;
  where: offer_modify_subscription_modify_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_modify_at_by_pkArgs = {
  _set?: InputMaybe<offer_modify_subscription_modify_at_set_input>;
  pk_columns: offer_modify_subscription_modify_at_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_modify_at_manyArgs = {
  updates: Array<offer_modify_subscription_modify_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_optionArgs = {
  _inc?: InputMaybe<offer_modify_subscription_option_inc_input>;
  _set?: InputMaybe<offer_modify_subscription_option_set_input>;
  where: offer_modify_subscription_option_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_option_by_pkArgs = {
  _inc?: InputMaybe<offer_modify_subscription_option_inc_input>;
  _set?: InputMaybe<offer_modify_subscription_option_set_input>;
  pk_columns: offer_modify_subscription_option_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_option_manyArgs = {
  updates: Array<offer_modify_subscription_option_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_option_typeArgs = {
  _set?: InputMaybe<offer_modify_subscription_option_type_set_input>;
  where: offer_modify_subscription_option_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_option_type_by_pkArgs = {
  _set?: InputMaybe<offer_modify_subscription_option_type_set_input>;
  pk_columns: offer_modify_subscription_option_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_modify_subscription_option_type_manyArgs = {
  updates: Array<offer_modify_subscription_option_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscriptionArgs = {
  _inc?: InputMaybe<offer_pause_subscription_inc_input>;
  _set?: InputMaybe<offer_pause_subscription_set_input>;
  where: offer_pause_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_by_pkArgs = {
  _inc?: InputMaybe<offer_pause_subscription_inc_input>;
  _set?: InputMaybe<offer_pause_subscription_set_input>;
  pk_columns: offer_pause_subscription_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_intervalArgs = {
  _set?: InputMaybe<offer_pause_subscription_interval_set_input>;
  where: offer_pause_subscription_interval_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_interval_by_pkArgs = {
  _set?: InputMaybe<offer_pause_subscription_interval_set_input>;
  pk_columns: offer_pause_subscription_interval_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_interval_manyArgs = {
  updates: Array<offer_pause_subscription_interval_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_manyArgs = {
  updates: Array<offer_pause_subscription_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_optionArgs = {
  _append?: InputMaybe<offer_pause_subscription_option_append_input>;
  _delete_at_path?: InputMaybe<offer_pause_subscription_option_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_pause_subscription_option_delete_elem_input>;
  _delete_key?: InputMaybe<offer_pause_subscription_option_delete_key_input>;
  _inc?: InputMaybe<offer_pause_subscription_option_inc_input>;
  _prepend?: InputMaybe<offer_pause_subscription_option_prepend_input>;
  _set?: InputMaybe<offer_pause_subscription_option_set_input>;
  where: offer_pause_subscription_option_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_option_by_pkArgs = {
  _append?: InputMaybe<offer_pause_subscription_option_append_input>;
  _delete_at_path?: InputMaybe<offer_pause_subscription_option_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_pause_subscription_option_delete_elem_input>;
  _delete_key?: InputMaybe<offer_pause_subscription_option_delete_key_input>;
  _inc?: InputMaybe<offer_pause_subscription_option_inc_input>;
  _prepend?: InputMaybe<offer_pause_subscription_option_prepend_input>;
  _set?: InputMaybe<offer_pause_subscription_option_set_input>;
  pk_columns: offer_pause_subscription_option_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_option_manyArgs = {
  updates: Array<offer_pause_subscription_option_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_option_typeArgs = {
  _set?: InputMaybe<offer_pause_subscription_option_type_set_input>;
  where: offer_pause_subscription_option_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_option_type_by_pkArgs = {
  _set?: InputMaybe<offer_pause_subscription_option_type_set_input>;
  pk_columns: offer_pause_subscription_option_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_option_type_manyArgs = {
  updates: Array<offer_pause_subscription_option_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_pause_atArgs = {
  _set?: InputMaybe<offer_pause_subscription_pause_at_set_input>;
  where: offer_pause_subscription_pause_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_pause_at_by_pkArgs = {
  _set?: InputMaybe<offer_pause_subscription_pause_at_set_input>;
  pk_columns: offer_pause_subscription_pause_at_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_pause_subscription_pause_at_manyArgs = {
  updates: Array<offer_pause_subscription_pause_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swapArgs = {
  _inc?: InputMaybe<offer_product_swap_inc_input>;
  _set?: InputMaybe<offer_product_swap_set_input>;
  where: offer_product_swap_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_by_pkArgs = {
  _inc?: InputMaybe<offer_product_swap_inc_input>;
  _set?: InputMaybe<offer_product_swap_set_input>;
  pk_columns: offer_product_swap_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_manyArgs = {
  updates: Array<offer_product_swap_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_productArgs = {
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_inc_input>;
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_set_input>;
  where: offer_product_swap_platform_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_product_by_pkArgs = {
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_inc_input>;
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_set_input>;
  pk_columns: offer_product_swap_platform_ecommerce_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_product_manyArgs = {
  updates: Array<offer_product_swap_platform_ecommerce_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_product_platform_variantArgs = {
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_inc_input>;
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_set_input>;
  where: offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_product_platform_variant_by_pkArgs = {
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_inc_input>;
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_set_input>;
  pk_columns: offer_product_swap_platform_ecommerce_product_platform_variant_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_product_swap_platform_ecommerce_product_platform_variant_manyArgs = {
  updates: Array<offer_product_swap_platform_ecommerce_product_platform_variant_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_reschedule_orderArgs = {
  _inc?: InputMaybe<offer_reschedule_order_inc_input>;
  _set?: InputMaybe<offer_reschedule_order_set_input>;
  where: offer_reschedule_order_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_reschedule_order_by_pkArgs = {
  _inc?: InputMaybe<offer_reschedule_order_inc_input>;
  _set?: InputMaybe<offer_reschedule_order_set_input>;
  pk_columns: offer_reschedule_order_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_reschedule_order_manyArgs = {
  updates: Array<offer_reschedule_order_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_ruleArgs = {
  _inc?: InputMaybe<offer_rule_inc_input>;
  _set?: InputMaybe<offer_rule_set_input>;
  where: offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_by_pkArgs = {
  _inc?: InputMaybe<offer_rule_inc_input>;
  _set?: InputMaybe<offer_rule_set_input>;
  pk_columns: offer_rule_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_groupArgs = {
  _inc?: InputMaybe<offer_rule_group_inc_input>;
  _set?: InputMaybe<offer_rule_group_set_input>;
  where: offer_rule_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_group_by_pkArgs = {
  _inc?: InputMaybe<offer_rule_group_inc_input>;
  _set?: InputMaybe<offer_rule_group_set_input>;
  pk_columns: offer_rule_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_group_manyArgs = {
  updates: Array<offer_rule_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_group_versionArgs = {
  _append?: InputMaybe<offer_rule_group_version_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_group_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_group_version_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_group_version_delete_key_input>;
  _inc?: InputMaybe<offer_rule_group_version_inc_input>;
  _prepend?: InputMaybe<offer_rule_group_version_prepend_input>;
  _set?: InputMaybe<offer_rule_group_version_set_input>;
  where: offer_rule_group_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_group_version_by_pkArgs = {
  _append?: InputMaybe<offer_rule_group_version_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_group_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_group_version_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_group_version_delete_key_input>;
  _inc?: InputMaybe<offer_rule_group_version_inc_input>;
  _prepend?: InputMaybe<offer_rule_group_version_prepend_input>;
  _set?: InputMaybe<offer_rule_group_version_set_input>;
  pk_columns: offer_rule_group_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_group_version_manyArgs = {
  updates: Array<offer_rule_group_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_manyArgs = {
  updates: Array<offer_rule_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_ruleArgs = {
  _append?: InputMaybe<offer_rule_rule_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_rule_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_rule_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_rule_delete_key_input>;
  _inc?: InputMaybe<offer_rule_rule_inc_input>;
  _prepend?: InputMaybe<offer_rule_rule_prepend_input>;
  _set?: InputMaybe<offer_rule_rule_set_input>;
  where: offer_rule_rule_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_rule_by_pkArgs = {
  _append?: InputMaybe<offer_rule_rule_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_rule_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_rule_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_rule_delete_key_input>;
  _inc?: InputMaybe<offer_rule_rule_inc_input>;
  _prepend?: InputMaybe<offer_rule_rule_prepend_input>;
  _set?: InputMaybe<offer_rule_rule_set_input>;
  pk_columns: offer_rule_rule_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_rule_manyArgs = {
  updates: Array<offer_rule_rule_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_versionArgs = {
  _append?: InputMaybe<offer_rule_version_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_version_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_version_delete_key_input>;
  _inc?: InputMaybe<offer_rule_version_inc_input>;
  _prepend?: InputMaybe<offer_rule_version_prepend_input>;
  _set?: InputMaybe<offer_rule_version_set_input>;
  where: offer_rule_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_version_by_pkArgs = {
  _append?: InputMaybe<offer_rule_version_append_input>;
  _delete_at_path?: InputMaybe<offer_rule_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_rule_version_delete_elem_input>;
  _delete_key?: InputMaybe<offer_rule_version_delete_key_input>;
  _inc?: InputMaybe<offer_rule_version_inc_input>;
  _prepend?: InputMaybe<offer_rule_version_prepend_input>;
  _set?: InputMaybe<offer_rule_version_set_input>;
  pk_columns: offer_rule_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_rule_version_manyArgs = {
  updates: Array<offer_rule_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_styleArgs = {
  _set?: InputMaybe<offer_style_set_input>;
  where: offer_style_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_style_by_pkArgs = {
  _set?: InputMaybe<offer_style_set_input>;
  pk_columns: offer_style_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_style_manyArgs = {
  updates: Array<offer_style_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_testArgs = {
  _inc?: InputMaybe<offer_test_inc_input>;
  _set?: InputMaybe<offer_test_set_input>;
  where: offer_test_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_test_by_pkArgs = {
  _inc?: InputMaybe<offer_test_inc_input>;
  _set?: InputMaybe<offer_test_set_input>;
  pk_columns: offer_test_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_test_manyArgs = {
  updates: Array<offer_test_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_test_offerArgs = {
  _inc?: InputMaybe<offer_test_offer_inc_input>;
  _set?: InputMaybe<offer_test_offer_set_input>;
  where: offer_test_offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_test_offer_by_pkArgs = {
  _inc?: InputMaybe<offer_test_offer_inc_input>;
  _set?: InputMaybe<offer_test_offer_set_input>;
  pk_columns: offer_test_offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_test_offer_manyArgs = {
  updates: Array<offer_test_offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_timerArgs = {
  _inc?: InputMaybe<offer_timer_inc_input>;
  _set?: InputMaybe<offer_timer_set_input>;
  where: offer_timer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_timer_by_pkArgs = {
  _inc?: InputMaybe<offer_timer_inc_input>;
  _set?: InputMaybe<offer_timer_set_input>;
  pk_columns: offer_timer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_timer_manyArgs = {
  updates: Array<offer_timer_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_timer_modeArgs = {
  _set?: InputMaybe<offer_timer_mode_set_input>;
  where: offer_timer_mode_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_timer_mode_by_pkArgs = {
  _set?: InputMaybe<offer_timer_mode_set_input>;
  pk_columns: offer_timer_mode_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_timer_mode_manyArgs = {
  updates: Array<offer_timer_mode_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_trial_extensionArgs = {
  _inc?: InputMaybe<offer_trial_extension_inc_input>;
  _set?: InputMaybe<offer_trial_extension_set_input>;
  where: offer_trial_extension_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_trial_extension_by_pkArgs = {
  _inc?: InputMaybe<offer_trial_extension_inc_input>;
  _set?: InputMaybe<offer_trial_extension_set_input>;
  pk_columns: offer_trial_extension_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_trial_extension_manyArgs = {
  updates: Array<offer_trial_extension_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_typeArgs = {
  _set?: InputMaybe<offer_type_set_input>;
  where: offer_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_type_by_pkArgs = {
  _set?: InputMaybe<offer_type_set_input>;
  pk_columns: offer_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_type_manyArgs = {
  updates: Array<offer_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_upgradeArgs = {
  _inc?: InputMaybe<offer_upgrade_inc_input>;
  _set?: InputMaybe<offer_upgrade_set_input>;
  where: offer_upgrade_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_upgrade_by_pkArgs = {
  _inc?: InputMaybe<offer_upgrade_inc_input>;
  _set?: InputMaybe<offer_upgrade_set_input>;
  pk_columns: offer_upgrade_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_upgrade_manyArgs = {
  updates: Array<offer_upgrade_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_variantArgs = {
  _append?: InputMaybe<offer_variant_append_input>;
  _delete_at_path?: InputMaybe<offer_variant_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_variant_delete_elem_input>;
  _delete_key?: InputMaybe<offer_variant_delete_key_input>;
  _inc?: InputMaybe<offer_variant_inc_input>;
  _prepend?: InputMaybe<offer_variant_prepend_input>;
  _set?: InputMaybe<offer_variant_set_input>;
  where: offer_variant_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_variant_by_pkArgs = {
  _append?: InputMaybe<offer_variant_append_input>;
  _delete_at_path?: InputMaybe<offer_variant_delete_at_path_input>;
  _delete_elem?: InputMaybe<offer_variant_delete_elem_input>;
  _delete_key?: InputMaybe<offer_variant_delete_key_input>;
  _inc?: InputMaybe<offer_variant_inc_input>;
  _prepend?: InputMaybe<offer_variant_prepend_input>;
  _set?: InputMaybe<offer_variant_set_input>;
  pk_columns: offer_variant_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_variant_couponArgs = {
  _inc?: InputMaybe<offer_variant_coupon_inc_input>;
  _set?: InputMaybe<offer_variant_coupon_set_input>;
  where: offer_variant_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_offer_variant_coupon_by_pkArgs = {
  _inc?: InputMaybe<offer_variant_coupon_inc_input>;
  _set?: InputMaybe<offer_variant_coupon_set_input>;
  pk_columns: offer_variant_coupon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_offer_variant_coupon_manyArgs = {
  updates: Array<offer_variant_coupon_updates>;
};


/** mutation root */
export type mutation_rootupdate_offer_variant_manyArgs = {
  updates: Array<offer_variant_updates>;
};


/** mutation root */
export type mutation_rootupdate_platformArgs = {
  _set?: InputMaybe<platform_set_input>;
  where: platform_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_addonArgs = {
  _inc?: InputMaybe<platform_addon_inc_input>;
  _set?: InputMaybe<platform_addon_set_input>;
  where: platform_addon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_by_pkArgs = {
  _inc?: InputMaybe<platform_addon_inc_input>;
  _set?: InputMaybe<platform_addon_set_input>;
  pk_columns: platform_addon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_manyArgs = {
  updates: Array<platform_addon_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_planArgs = {
  _inc?: InputMaybe<platform_addon_plan_inc_input>;
  _set?: InputMaybe<platform_addon_plan_set_input>;
  where: platform_addon_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_plan_by_pkArgs = {
  _inc?: InputMaybe<platform_addon_plan_inc_input>;
  _set?: InputMaybe<platform_addon_plan_set_input>;
  pk_columns: platform_addon_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_plan_manyArgs = {
  updates: Array<platform_addon_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_priceArgs = {
  _inc?: InputMaybe<platform_addon_price_inc_input>;
  _set?: InputMaybe<platform_addon_price_set_input>;
  where: platform_addon_price_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_price_by_pkArgs = {
  _inc?: InputMaybe<platform_addon_price_inc_input>;
  _set?: InputMaybe<platform_addon_price_set_input>;
  pk_columns: platform_addon_price_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_price_manyArgs = {
  updates: Array<platform_addon_price_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_price_tierArgs = {
  _inc?: InputMaybe<platform_addon_price_tier_inc_input>;
  _set?: InputMaybe<platform_addon_price_tier_set_input>;
  where: platform_addon_price_tier_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_price_tier_by_pkArgs = {
  _inc?: InputMaybe<platform_addon_price_tier_inc_input>;
  _set?: InputMaybe<platform_addon_price_tier_set_input>;
  pk_columns: platform_addon_price_tier_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_addon_price_tier_manyArgs = {
  updates: Array<platform_addon_price_tier_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_api_logArgs = {
  _append?: InputMaybe<platform_api_log_append_input>;
  _delete_at_path?: InputMaybe<platform_api_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_api_log_delete_elem_input>;
  _delete_key?: InputMaybe<platform_api_log_delete_key_input>;
  _inc?: InputMaybe<platform_api_log_inc_input>;
  _prepend?: InputMaybe<platform_api_log_prepend_input>;
  _set?: InputMaybe<platform_api_log_set_input>;
  where: platform_api_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_api_log_by_pkArgs = {
  _append?: InputMaybe<platform_api_log_append_input>;
  _delete_at_path?: InputMaybe<platform_api_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_api_log_delete_elem_input>;
  _delete_key?: InputMaybe<platform_api_log_delete_key_input>;
  _inc?: InputMaybe<platform_api_log_inc_input>;
  _prepend?: InputMaybe<platform_api_log_prepend_input>;
  _set?: InputMaybe<platform_api_log_set_input>;
  pk_columns: platform_api_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_api_log_manyArgs = {
  updates: Array<platform_api_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_base_currencyArgs = {
  _set?: InputMaybe<platform_base_currency_set_input>;
  where: platform_base_currency_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_base_currency_by_pkArgs = {
  _set?: InputMaybe<platform_base_currency_set_input>;
  pk_columns: platform_base_currency_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_base_currency_manyArgs = {
  updates: Array<platform_base_currency_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_by_pkArgs = {
  _set?: InputMaybe<platform_set_input>;
  pk_columns: platform_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_cancel_reasonArgs = {
  _inc?: InputMaybe<platform_cancel_reason_inc_input>;
  _set?: InputMaybe<platform_cancel_reason_set_input>;
  where: platform_cancel_reason_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_cancel_reason_by_pkArgs = {
  _inc?: InputMaybe<platform_cancel_reason_inc_input>;
  _set?: InputMaybe<platform_cancel_reason_set_input>;
  pk_columns: platform_cancel_reason_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_cancel_reason_manyArgs = {
  updates: Array<platform_cancel_reason_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_connectionArgs = {
  _append?: InputMaybe<platform_connection_append_input>;
  _delete_at_path?: InputMaybe<platform_connection_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_connection_delete_elem_input>;
  _delete_key?: InputMaybe<platform_connection_delete_key_input>;
  _inc?: InputMaybe<platform_connection_inc_input>;
  _prepend?: InputMaybe<platform_connection_prepend_input>;
  _set?: InputMaybe<platform_connection_set_input>;
  where: platform_connection_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_by_pkArgs = {
  _append?: InputMaybe<platform_connection_append_input>;
  _delete_at_path?: InputMaybe<platform_connection_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_connection_delete_elem_input>;
  _delete_key?: InputMaybe<platform_connection_delete_key_input>;
  _inc?: InputMaybe<platform_connection_inc_input>;
  _prepend?: InputMaybe<platform_connection_prepend_input>;
  _set?: InputMaybe<platform_connection_set_input>;
  pk_columns: platform_connection_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_manyArgs = {
  updates: Array<platform_connection_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_domainArgs = {
  _inc?: InputMaybe<platform_connection_naviga_domain_inc_input>;
  _set?: InputMaybe<platform_connection_naviga_domain_set_input>;
  where: platform_connection_naviga_domain_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_domain_by_pkArgs = {
  _inc?: InputMaybe<platform_connection_naviga_domain_inc_input>;
  _set?: InputMaybe<platform_connection_naviga_domain_set_input>;
  pk_columns: platform_connection_naviga_domain_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_domain_manyArgs = {
  updates: Array<platform_connection_naviga_domain_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_paper_code_endpointArgs = {
  _inc?: InputMaybe<platform_connection_naviga_paper_code_endpoint_inc_input>;
  _set?: InputMaybe<platform_connection_naviga_paper_code_endpoint_set_input>;
  where: platform_connection_naviga_paper_code_endpoint_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_paper_code_endpoint_by_pkArgs = {
  _inc?: InputMaybe<platform_connection_naviga_paper_code_endpoint_inc_input>;
  _set?: InputMaybe<platform_connection_naviga_paper_code_endpoint_set_input>;
  pk_columns: platform_connection_naviga_paper_code_endpoint_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_naviga_paper_code_endpoint_manyArgs = {
  updates: Array<platform_connection_naviga_paper_code_endpoint_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_shopify_storeArgs = {
  _append?: InputMaybe<platform_connection_shopify_store_append_input>;
  _delete_at_path?: InputMaybe<platform_connection_shopify_store_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_connection_shopify_store_delete_elem_input>;
  _delete_key?: InputMaybe<platform_connection_shopify_store_delete_key_input>;
  _inc?: InputMaybe<platform_connection_shopify_store_inc_input>;
  _prepend?: InputMaybe<platform_connection_shopify_store_prepend_input>;
  _set?: InputMaybe<platform_connection_shopify_store_set_input>;
  where: platform_connection_shopify_store_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_shopify_store_by_pkArgs = {
  _append?: InputMaybe<platform_connection_shopify_store_append_input>;
  _delete_at_path?: InputMaybe<platform_connection_shopify_store_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_connection_shopify_store_delete_elem_input>;
  _delete_key?: InputMaybe<platform_connection_shopify_store_delete_key_input>;
  _inc?: InputMaybe<platform_connection_shopify_store_inc_input>;
  _prepend?: InputMaybe<platform_connection_shopify_store_prepend_input>;
  _set?: InputMaybe<platform_connection_shopify_store_set_input>;
  pk_columns: platform_connection_shopify_store_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_connection_shopify_store_manyArgs = {
  updates: Array<platform_connection_shopify_store_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_couponArgs = {
  _inc?: InputMaybe<platform_coupon_inc_input>;
  _set?: InputMaybe<platform_coupon_set_input>;
  where: platform_coupon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_addonArgs = {
  _inc?: InputMaybe<platform_coupon_addon_inc_input>;
  _set?: InputMaybe<platform_coupon_addon_set_input>;
  where: platform_coupon_addon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_addon_by_pkArgs = {
  _inc?: InputMaybe<platform_coupon_addon_inc_input>;
  _set?: InputMaybe<platform_coupon_addon_set_input>;
  pk_columns: platform_coupon_addon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_addon_manyArgs = {
  updates: Array<platform_coupon_addon_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_toArgs = {
  _set?: InputMaybe<platform_coupon_apply_to_set_input>;
  where: platform_coupon_apply_to_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_to_by_pkArgs = {
  _set?: InputMaybe<platform_coupon_apply_to_set_input>;
  pk_columns: platform_coupon_apply_to_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_to_manyArgs = {
  updates: Array<platform_coupon_apply_to_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_to_restrictionArgs = {
  _set?: InputMaybe<platform_coupon_apply_to_restriction_set_input>;
  where: platform_coupon_apply_to_restriction_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_to_restriction_by_pkArgs = {
  _set?: InputMaybe<platform_coupon_apply_to_restriction_set_input>;
  pk_columns: platform_coupon_apply_to_restriction_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_apply_to_restriction_manyArgs = {
  updates: Array<platform_coupon_apply_to_restriction_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_by_pkArgs = {
  _inc?: InputMaybe<platform_coupon_inc_input>;
  _set?: InputMaybe<platform_coupon_set_input>;
  pk_columns: platform_coupon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_durationArgs = {
  _set?: InputMaybe<platform_coupon_duration_set_input>;
  where: platform_coupon_duration_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_duration_by_pkArgs = {
  _set?: InputMaybe<platform_coupon_duration_set_input>;
  pk_columns: platform_coupon_duration_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_duration_intervalArgs = {
  _set?: InputMaybe<platform_coupon_duration_interval_set_input>;
  where: platform_coupon_duration_interval_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_duration_interval_by_pkArgs = {
  _set?: InputMaybe<platform_coupon_duration_interval_set_input>;
  pk_columns: platform_coupon_duration_interval_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_duration_interval_manyArgs = {
  updates: Array<platform_coupon_duration_interval_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_duration_manyArgs = {
  updates: Array<platform_coupon_duration_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_manyArgs = {
  updates: Array<platform_coupon_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_planArgs = {
  _inc?: InputMaybe<platform_coupon_plan_inc_input>;
  _set?: InputMaybe<platform_coupon_plan_set_input>;
  where: platform_coupon_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_plan_by_pkArgs = {
  _inc?: InputMaybe<platform_coupon_plan_inc_input>;
  _set?: InputMaybe<platform_coupon_plan_set_input>;
  pk_columns: platform_coupon_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_plan_manyArgs = {
  updates: Array<platform_coupon_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_productArgs = {
  _inc?: InputMaybe<platform_coupon_product_inc_input>;
  _set?: InputMaybe<platform_coupon_product_set_input>;
  where: platform_coupon_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_product_by_pkArgs = {
  _inc?: InputMaybe<platform_coupon_product_inc_input>;
  _set?: InputMaybe<platform_coupon_product_set_input>;
  pk_columns: platform_coupon_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_product_groupArgs = {
  _inc?: InputMaybe<platform_coupon_product_group_inc_input>;
  _set?: InputMaybe<platform_coupon_product_group_set_input>;
  where: platform_coupon_product_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_product_group_by_pkArgs = {
  _inc?: InputMaybe<platform_coupon_product_group_inc_input>;
  _set?: InputMaybe<platform_coupon_product_group_set_input>;
  pk_columns: platform_coupon_product_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_product_group_manyArgs = {
  updates: Array<platform_coupon_product_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_product_manyArgs = {
  updates: Array<platform_coupon_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_typeArgs = {
  _set?: InputMaybe<platform_coupon_type_set_input>;
  where: platform_coupon_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_type_by_pkArgs = {
  _set?: InputMaybe<platform_coupon_type_set_input>;
  pk_columns: platform_coupon_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_coupon_type_manyArgs = {
  updates: Array<platform_coupon_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_credentialsArgs = {
  _append?: InputMaybe<platform_credentials_append_input>;
  _delete_at_path?: InputMaybe<platform_credentials_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_credentials_delete_elem_input>;
  _delete_key?: InputMaybe<platform_credentials_delete_key_input>;
  _inc?: InputMaybe<platform_credentials_inc_input>;
  _prepend?: InputMaybe<platform_credentials_prepend_input>;
  _set?: InputMaybe<platform_credentials_set_input>;
  where: platform_credentials_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_credentials_by_pkArgs = {
  _append?: InputMaybe<platform_credentials_append_input>;
  _delete_at_path?: InputMaybe<platform_credentials_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_credentials_delete_elem_input>;
  _delete_key?: InputMaybe<platform_credentials_delete_key_input>;
  _inc?: InputMaybe<platform_credentials_inc_input>;
  _prepend?: InputMaybe<platform_credentials_prepend_input>;
  _set?: InputMaybe<platform_credentials_set_input>;
  pk_columns: platform_credentials_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_credentials_manyArgs = {
  updates: Array<platform_credentials_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_currencyArgs = {
  _set?: InputMaybe<platform_currency_set_input>;
  where: platform_currency_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_currency_by_pkArgs = {
  _set?: InputMaybe<platform_currency_set_input>;
  pk_columns: platform_currency_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_currency_exchange_rateArgs = {
  _inc?: InputMaybe<platform_currency_exchange_rate_inc_input>;
  _set?: InputMaybe<platform_currency_exchange_rate_set_input>;
  where: platform_currency_exchange_rate_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_currency_exchange_rate_by_pkArgs = {
  _inc?: InputMaybe<platform_currency_exchange_rate_inc_input>;
  _set?: InputMaybe<platform_currency_exchange_rate_set_input>;
  pk_columns: platform_currency_exchange_rate_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_currency_exchange_rate_manyArgs = {
  updates: Array<platform_currency_exchange_rate_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_currency_manyArgs = {
  updates: Array<platform_currency_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_customerArgs = {
  _append?: InputMaybe<platform_customer_append_input>;
  _delete_at_path?: InputMaybe<platform_customer_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_customer_delete_elem_input>;
  _delete_key?: InputMaybe<platform_customer_delete_key_input>;
  _inc?: InputMaybe<platform_customer_inc_input>;
  _prepend?: InputMaybe<platform_customer_prepend_input>;
  _set?: InputMaybe<platform_customer_set_input>;
  where: platform_customer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_customer_by_pkArgs = {
  _append?: InputMaybe<platform_customer_append_input>;
  _delete_at_path?: InputMaybe<platform_customer_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_customer_delete_elem_input>;
  _delete_key?: InputMaybe<platform_customer_delete_key_input>;
  _inc?: InputMaybe<platform_customer_inc_input>;
  _prepend?: InputMaybe<platform_customer_prepend_input>;
  _set?: InputMaybe<platform_customer_set_input>;
  pk_columns: platform_customer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_customer_manyArgs = {
  updates: Array<platform_customer_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_ecommerce_productArgs = {
  _inc?: InputMaybe<platform_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_ecommerce_product_set_input>;
  where: platform_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_ecommerce_product_by_pkArgs = {
  _inc?: InputMaybe<platform_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_ecommerce_product_set_input>;
  pk_columns: platform_ecommerce_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_ecommerce_product_manyArgs = {
  updates: Array<platform_ecommerce_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_importArgs = {
  _inc?: InputMaybe<platform_import_inc_input>;
  _set?: InputMaybe<platform_import_set_input>;
  where: platform_import_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_import_by_pkArgs = {
  _inc?: InputMaybe<platform_import_inc_input>;
  _set?: InputMaybe<platform_import_set_input>;
  pk_columns: platform_import_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_import_manyArgs = {
  updates: Array<platform_import_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_import_statusArgs = {
  _set?: InputMaybe<platform_import_status_set_input>;
  where: platform_import_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_import_status_by_pkArgs = {
  _set?: InputMaybe<platform_import_status_set_input>;
  pk_columns: platform_import_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_import_status_manyArgs = {
  updates: Array<platform_import_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_import_stepArgs = {
  _append?: InputMaybe<platform_import_step_append_input>;
  _delete_at_path?: InputMaybe<platform_import_step_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_import_step_delete_elem_input>;
  _delete_key?: InputMaybe<platform_import_step_delete_key_input>;
  _prepend?: InputMaybe<platform_import_step_prepend_input>;
  _set?: InputMaybe<platform_import_step_set_input>;
  where: platform_import_step_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_import_step_by_pkArgs = {
  _append?: InputMaybe<platform_import_step_append_input>;
  _delete_at_path?: InputMaybe<platform_import_step_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_import_step_delete_elem_input>;
  _delete_key?: InputMaybe<platform_import_step_delete_key_input>;
  _prepend?: InputMaybe<platform_import_step_prepend_input>;
  _set?: InputMaybe<platform_import_step_set_input>;
  pk_columns: platform_import_step_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_import_step_manyArgs = {
  updates: Array<platform_import_step_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_import_step_typeArgs = {
  _set?: InputMaybe<platform_import_step_type_set_input>;
  where: platform_import_step_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_import_step_type_by_pkArgs = {
  _set?: InputMaybe<platform_import_step_type_set_input>;
  pk_columns: platform_import_step_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_import_step_type_manyArgs = {
  updates: Array<platform_import_step_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_invoiceArgs = {
  _inc?: InputMaybe<platform_invoice_inc_input>;
  _set?: InputMaybe<platform_invoice_set_input>;
  where: platform_invoice_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_invoice_by_pkArgs = {
  _inc?: InputMaybe<platform_invoice_inc_input>;
  _set?: InputMaybe<platform_invoice_set_input>;
  pk_columns: platform_invoice_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_invoice_manyArgs = {
  updates: Array<platform_invoice_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_invoice_statusArgs = {
  _set?: InputMaybe<platform_invoice_status_set_input>;
  where: platform_invoice_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_invoice_status_by_pkArgs = {
  _set?: InputMaybe<platform_invoice_status_set_input>;
  pk_columns: platform_invoice_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_invoice_status_manyArgs = {
  updates: Array<platform_invoice_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_manyArgs = {
  updates: Array<platform_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_rate_codeArgs = {
  _inc?: InputMaybe<platform_newspaper_rate_code_inc_input>;
  _set?: InputMaybe<platform_newspaper_rate_code_set_input>;
  where: platform_newspaper_rate_code_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_rate_code_by_pkArgs = {
  _inc?: InputMaybe<platform_newspaper_rate_code_inc_input>;
  _set?: InputMaybe<platform_newspaper_rate_code_set_input>;
  pk_columns: platform_newspaper_rate_code_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_rate_code_manyArgs = {
  updates: Array<platform_newspaper_rate_code_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_subscriptionArgs = {
  _append?: InputMaybe<platform_newspaper_subscription_append_input>;
  _delete_at_path?: InputMaybe<platform_newspaper_subscription_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_newspaper_subscription_delete_elem_input>;
  _delete_key?: InputMaybe<platform_newspaper_subscription_delete_key_input>;
  _inc?: InputMaybe<platform_newspaper_subscription_inc_input>;
  _prepend?: InputMaybe<platform_newspaper_subscription_prepend_input>;
  _set?: InputMaybe<platform_newspaper_subscription_set_input>;
  where: platform_newspaper_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_subscription_by_pkArgs = {
  _append?: InputMaybe<platform_newspaper_subscription_append_input>;
  _delete_at_path?: InputMaybe<platform_newspaper_subscription_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_newspaper_subscription_delete_elem_input>;
  _delete_key?: InputMaybe<platform_newspaper_subscription_delete_key_input>;
  _inc?: InputMaybe<platform_newspaper_subscription_inc_input>;
  _prepend?: InputMaybe<platform_newspaper_subscription_prepend_input>;
  _set?: InputMaybe<platform_newspaper_subscription_set_input>;
  pk_columns: platform_newspaper_subscription_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_subscription_manyArgs = {
  updates: Array<platform_newspaper_subscription_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_subscription_stop_dateArgs = {
  _inc?: InputMaybe<platform_newspaper_subscription_stop_date_inc_input>;
  _set?: InputMaybe<platform_newspaper_subscription_stop_date_set_input>;
  where: platform_newspaper_subscription_stop_date_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_newspaper_subscription_stop_date_manyArgs = {
  updates: Array<platform_newspaper_subscription_stop_date_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_planArgs = {
  _inc?: InputMaybe<platform_plan_inc_input>;
  _set?: InputMaybe<platform_plan_set_input>;
  where: platform_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_by_pkArgs = {
  _inc?: InputMaybe<platform_plan_inc_input>;
  _set?: InputMaybe<platform_plan_set_input>;
  pk_columns: platform_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_ecommerce_productArgs = {
  _inc?: InputMaybe<platform_plan_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_plan_ecommerce_product_set_input>;
  where: platform_plan_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_ecommerce_product_by_pkArgs = {
  _inc?: InputMaybe<platform_plan_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_plan_ecommerce_product_set_input>;
  pk_columns: platform_plan_ecommerce_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_ecommerce_product_manyArgs = {
  updates: Array<platform_plan_ecommerce_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_groupArgs = {
  _inc?: InputMaybe<platform_plan_group_inc_input>;
  _set?: InputMaybe<platform_plan_group_set_input>;
  where: platform_plan_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_group_by_pkArgs = {
  _inc?: InputMaybe<platform_plan_group_inc_input>;
  _set?: InputMaybe<platform_plan_group_set_input>;
  pk_columns: platform_plan_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_group_manyArgs = {
  updates: Array<platform_plan_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_intervalArgs = {
  _set?: InputMaybe<platform_plan_interval_set_input>;
  where: platform_plan_interval_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_interval_by_pkArgs = {
  _set?: InputMaybe<platform_plan_interval_set_input>;
  pk_columns: platform_plan_interval_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_interval_manyArgs = {
  updates: Array<platform_plan_interval_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_manyArgs = {
  updates: Array<platform_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_pricing_modelArgs = {
  _set?: InputMaybe<platform_plan_pricing_model_set_input>;
  where: platform_plan_pricing_model_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_pricing_model_by_pkArgs = {
  _set?: InputMaybe<platform_plan_pricing_model_set_input>;
  pk_columns: platform_plan_pricing_model_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_pricing_model_manyArgs = {
  updates: Array<platform_plan_pricing_model_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_tierArgs = {
  _inc?: InputMaybe<platform_plan_tier_inc_input>;
  _set?: InputMaybe<platform_plan_tier_set_input>;
  where: platform_plan_tier_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_tier_by_pkArgs = {
  _inc?: InputMaybe<platform_plan_tier_inc_input>;
  _set?: InputMaybe<platform_plan_tier_set_input>;
  pk_columns: platform_plan_tier_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_plan_tier_manyArgs = {
  updates: Array<platform_plan_tier_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_productArgs = {
  _inc?: InputMaybe<platform_product_inc_input>;
  _set?: InputMaybe<platform_product_set_input>;
  where: platform_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_product_by_pkArgs = {
  _inc?: InputMaybe<platform_product_inc_input>;
  _set?: InputMaybe<platform_product_set_input>;
  pk_columns: platform_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_product_groupArgs = {
  _inc?: InputMaybe<platform_product_group_inc_input>;
  _set?: InputMaybe<platform_product_group_set_input>;
  where: platform_product_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_product_group_by_pkArgs = {
  _inc?: InputMaybe<platform_product_group_inc_input>;
  _set?: InputMaybe<platform_product_group_set_input>;
  pk_columns: platform_product_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_product_group_manyArgs = {
  updates: Array<platform_product_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_product_manyArgs = {
  updates: Array<platform_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_objectArgs = {
  _append?: InputMaybe<platform_raw_object_append_input>;
  _delete_at_path?: InputMaybe<platform_raw_object_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_raw_object_delete_elem_input>;
  _delete_key?: InputMaybe<platform_raw_object_delete_key_input>;
  _inc?: InputMaybe<platform_raw_object_inc_input>;
  _prepend?: InputMaybe<platform_raw_object_prepend_input>;
  _set?: InputMaybe<platform_raw_object_set_input>;
  where: platform_raw_object_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_object_by_pkArgs = {
  _append?: InputMaybe<platform_raw_object_append_input>;
  _delete_at_path?: InputMaybe<platform_raw_object_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_raw_object_delete_elem_input>;
  _delete_key?: InputMaybe<platform_raw_object_delete_key_input>;
  _inc?: InputMaybe<platform_raw_object_inc_input>;
  _prepend?: InputMaybe<platform_raw_object_prepend_input>;
  _set?: InputMaybe<platform_raw_object_set_input>;
  pk_columns: platform_raw_object_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_object_manyArgs = {
  updates: Array<platform_raw_object_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_object_typeArgs = {
  _set?: InputMaybe<platform_raw_object_type_set_input>;
  where: platform_raw_object_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_object_type_by_pkArgs = {
  _set?: InputMaybe<platform_raw_object_type_set_input>;
  pk_columns: platform_raw_object_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_raw_object_type_manyArgs = {
  updates: Array<platform_raw_object_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_refundArgs = {
  _inc?: InputMaybe<platform_refund_inc_input>;
  _set?: InputMaybe<platform_refund_set_input>;
  where: platform_refund_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_refund_by_pkArgs = {
  _inc?: InputMaybe<platform_refund_inc_input>;
  _set?: InputMaybe<platform_refund_set_input>;
  pk_columns: platform_refund_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_refund_manyArgs = {
  updates: Array<platform_refund_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscriptionArgs = {
  _append?: InputMaybe<platform_subscription_append_input>;
  _delete_at_path?: InputMaybe<platform_subscription_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_subscription_delete_elem_input>;
  _delete_key?: InputMaybe<platform_subscription_delete_key_input>;
  _inc?: InputMaybe<platform_subscription_inc_input>;
  _prepend?: InputMaybe<platform_subscription_prepend_input>;
  _set?: InputMaybe<platform_subscription_set_input>;
  where: platform_subscription_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_addonArgs = {
  _inc?: InputMaybe<platform_subscription_addon_inc_input>;
  _set?: InputMaybe<platform_subscription_addon_set_input>;
  where: platform_subscription_addon_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_addon_by_pkArgs = {
  _inc?: InputMaybe<platform_subscription_addon_inc_input>;
  _set?: InputMaybe<platform_subscription_addon_set_input>;
  pk_columns: platform_subscription_addon_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_addon_manyArgs = {
  updates: Array<platform_subscription_addon_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_by_pkArgs = {
  _append?: InputMaybe<platform_subscription_append_input>;
  _delete_at_path?: InputMaybe<platform_subscription_delete_at_path_input>;
  _delete_elem?: InputMaybe<platform_subscription_delete_elem_input>;
  _delete_key?: InputMaybe<platform_subscription_delete_key_input>;
  _inc?: InputMaybe<platform_subscription_inc_input>;
  _prepend?: InputMaybe<platform_subscription_prepend_input>;
  _set?: InputMaybe<platform_subscription_set_input>;
  pk_columns: platform_subscription_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_manyArgs = {
  updates: Array<platform_subscription_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_next_billing_atArgs = {
  _inc?: InputMaybe<platform_subscription_next_billing_at_inc_input>;
  _set?: InputMaybe<platform_subscription_next_billing_at_set_input>;
  where: platform_subscription_next_billing_at_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_next_billing_at_manyArgs = {
  updates: Array<platform_subscription_next_billing_at_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_planArgs = {
  _inc?: InputMaybe<platform_subscription_plan_inc_input>;
  _set?: InputMaybe<platform_subscription_plan_set_input>;
  where: platform_subscription_plan_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_plan_by_pkArgs = {
  _inc?: InputMaybe<platform_subscription_plan_inc_input>;
  _set?: InputMaybe<platform_subscription_plan_set_input>;
  pk_columns: platform_subscription_plan_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_plan_manyArgs = {
  updates: Array<platform_subscription_plan_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_plan_swappable_ecommerce_productArgs = {
  _inc?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_set_input>;
  where: platform_subscription_plan_swappable_ecommerce_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_plan_swappable_ecommerce_product_by_pkArgs = {
  _inc?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_inc_input>;
  _set?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_set_input>;
  pk_columns: platform_subscription_plan_swappable_ecommerce_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_plan_swappable_ecommerce_product_manyArgs = {
  updates: Array<platform_subscription_plan_swappable_ecommerce_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_statusArgs = {
  _set?: InputMaybe<platform_subscription_status_set_input>;
  where: platform_subscription_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_status_by_pkArgs = {
  _set?: InputMaybe<platform_subscription_status_set_input>;
  pk_columns: platform_subscription_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_subscription_status_manyArgs = {
  updates: Array<platform_subscription_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_variantArgs = {
  _inc?: InputMaybe<platform_variant_inc_input>;
  _set?: InputMaybe<platform_variant_set_input>;
  where: platform_variant_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_variant_by_pkArgs = {
  _inc?: InputMaybe<platform_variant_inc_input>;
  _set?: InputMaybe<platform_variant_set_input>;
  pk_columns: platform_variant_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_variant_manyArgs = {
  updates: Array<platform_variant_updates>;
};


/** mutation root */
export type mutation_rootupdate_platform_variant_optionArgs = {
  _inc?: InputMaybe<platform_variant_option_inc_input>;
  _set?: InputMaybe<platform_variant_option_set_input>;
  where: platform_variant_option_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_platform_variant_option_by_pkArgs = {
  _inc?: InputMaybe<platform_variant_option_inc_input>;
  _set?: InputMaybe<platform_variant_option_set_input>;
  pk_columns: platform_variant_option_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_platform_variant_option_manyArgs = {
  updates: Array<platform_variant_option_updates>;
};


/** mutation root */
export type mutation_rootupdate_portal_email_codeArgs = {
  _inc?: InputMaybe<portal_email_code_inc_input>;
  _set?: InputMaybe<portal_email_code_set_input>;
  where: portal_email_code_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_portal_email_code_by_pkArgs = {
  _inc?: InputMaybe<portal_email_code_inc_input>;
  _set?: InputMaybe<portal_email_code_set_input>;
  pk_columns: portal_email_code_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_portal_email_code_manyArgs = {
  updates: Array<portal_email_code_updates>;
};


/** mutation root */
export type mutation_rootupdate_portal_sessionArgs = {
  _inc?: InputMaybe<portal_session_inc_input>;
  _set?: InputMaybe<portal_session_set_input>;
  where: portal_session_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_portal_session_by_pkArgs = {
  _inc?: InputMaybe<portal_session_inc_input>;
  _set?: InputMaybe<portal_session_set_input>;
  pk_columns: portal_session_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_portal_session_manyArgs = {
  updates: Array<portal_session_updates>;
};


/** mutation root */
export type mutation_rootupdate_portal_session_subscriberArgs = {
  _inc?: InputMaybe<portal_session_subscriber_inc_input>;
  _set?: InputMaybe<portal_session_subscriber_set_input>;
  where: portal_session_subscriber_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_portal_session_subscriber_by_pkArgs = {
  _inc?: InputMaybe<portal_session_subscriber_inc_input>;
  _set?: InputMaybe<portal_session_subscriber_set_input>;
  pk_columns: portal_session_subscriber_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_portal_session_subscriber_manyArgs = {
  updates: Array<portal_session_subscriber_updates>;
};


/** mutation root */
export type mutation_rootupdate_productArgs = {
  _inc?: InputMaybe<product_inc_input>;
  _set?: InputMaybe<product_set_input>;
  where: product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_product_by_pkArgs = {
  _inc?: InputMaybe<product_inc_input>;
  _set?: InputMaybe<product_set_input>;
  pk_columns: product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_product_manyArgs = {
  updates: Array<product_updates>;
};


/** mutation root */
export type mutation_rootupdate_propertyArgs = {
  _inc?: InputMaybe<property_inc_input>;
  _set?: InputMaybe<property_set_input>;
  where: property_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_property_by_pkArgs = {
  _inc?: InputMaybe<property_inc_input>;
  _set?: InputMaybe<property_set_input>;
  pk_columns: property_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_property_entityArgs = {
  _set?: InputMaybe<property_entity_set_input>;
  where: property_entity_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_property_entity_by_pkArgs = {
  _set?: InputMaybe<property_entity_set_input>;
  pk_columns: property_entity_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_property_entity_manyArgs = {
  updates: Array<property_entity_updates>;
};


/** mutation root */
export type mutation_rootupdate_property_formatArgs = {
  _set?: InputMaybe<property_format_set_input>;
  where: property_format_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_property_format_by_pkArgs = {
  _set?: InputMaybe<property_format_set_input>;
  pk_columns: property_format_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_property_format_manyArgs = {
  updates: Array<property_format_updates>;
};


/** mutation root */
export type mutation_rootupdate_property_manyArgs = {
  updates: Array<property_updates>;
};


/** mutation root */
export type mutation_rootupdate_property_typeArgs = {
  _set?: InputMaybe<property_type_set_input>;
  where: property_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_property_type_by_pkArgs = {
  _set?: InputMaybe<property_type_set_input>;
  pk_columns: property_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_property_type_manyArgs = {
  updates: Array<property_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_questionArgs = {
  _inc?: InputMaybe<question_inc_input>;
  _set?: InputMaybe<question_set_input>;
  where: question_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answerArgs = {
  _inc?: InputMaybe<question_answer_inc_input>;
  _set?: InputMaybe<question_answer_set_input>;
  where: question_answer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answer_by_pkArgs = {
  _inc?: InputMaybe<question_answer_inc_input>;
  _set?: InputMaybe<question_answer_set_input>;
  pk_columns: question_answer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_answer_likelihood_to_returnArgs = {
  _inc?: InputMaybe<question_answer_likelihood_to_return_inc_input>;
  _set?: InputMaybe<question_answer_likelihood_to_return_set_input>;
  where: question_answer_likelihood_to_return_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answer_likelihood_to_return_by_pkArgs = {
  _inc?: InputMaybe<question_answer_likelihood_to_return_inc_input>;
  _set?: InputMaybe<question_answer_likelihood_to_return_set_input>;
  pk_columns: question_answer_likelihood_to_return_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_answer_likelihood_to_return_manyArgs = {
  updates: Array<question_answer_likelihood_to_return_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_answer_manyArgs = {
  updates: Array<question_answer_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_answer_radioArgs = {
  _inc?: InputMaybe<question_answer_radio_inc_input>;
  _set?: InputMaybe<question_answer_radio_set_input>;
  where: question_answer_radio_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answer_radio_by_pkArgs = {
  _inc?: InputMaybe<question_answer_radio_inc_input>;
  _set?: InputMaybe<question_answer_radio_set_input>;
  pk_columns: question_answer_radio_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_answer_radio_manyArgs = {
  updates: Array<question_answer_radio_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_answer_sentiment_phraseArgs = {
  _inc?: InputMaybe<question_answer_sentiment_phrase_inc_input>;
  _set?: InputMaybe<question_answer_sentiment_phrase_set_input>;
  where: question_answer_sentiment_phrase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answer_sentiment_phrase_by_pkArgs = {
  _inc?: InputMaybe<question_answer_sentiment_phrase_inc_input>;
  _set?: InputMaybe<question_answer_sentiment_phrase_set_input>;
  pk_columns: question_answer_sentiment_phrase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_answer_sentiment_phrase_manyArgs = {
  updates: Array<question_answer_sentiment_phrase_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_answer_textArgs = {
  _append?: InputMaybe<question_answer_text_append_input>;
  _delete_at_path?: InputMaybe<question_answer_text_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_answer_text_delete_elem_input>;
  _delete_key?: InputMaybe<question_answer_text_delete_key_input>;
  _inc?: InputMaybe<question_answer_text_inc_input>;
  _prepend?: InputMaybe<question_answer_text_prepend_input>;
  _set?: InputMaybe<question_answer_text_set_input>;
  where: question_answer_text_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_answer_text_by_pkArgs = {
  _append?: InputMaybe<question_answer_text_append_input>;
  _delete_at_path?: InputMaybe<question_answer_text_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_answer_text_delete_elem_input>;
  _delete_key?: InputMaybe<question_answer_text_delete_key_input>;
  _inc?: InputMaybe<question_answer_text_inc_input>;
  _prepend?: InputMaybe<question_answer_text_prepend_input>;
  _set?: InputMaybe<question_answer_text_set_input>;
  pk_columns: question_answer_text_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_answer_text_manyArgs = {
  updates: Array<question_answer_text_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_by_pkArgs = {
  _inc?: InputMaybe<question_inc_input>;
  _set?: InputMaybe<question_set_input>;
  pk_columns: question_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_manyArgs = {
  updates: Array<question_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_optionArgs = {
  _inc?: InputMaybe<question_option_inc_input>;
  _set?: InputMaybe<question_option_set_input>;
  where: question_option_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_option_by_pkArgs = {
  _inc?: InputMaybe<question_option_inc_input>;
  _set?: InputMaybe<question_option_set_input>;
  pk_columns: question_option_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_option_manyArgs = {
  updates: Array<question_option_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_option_versionArgs = {
  _inc?: InputMaybe<question_option_version_inc_input>;
  _set?: InputMaybe<question_option_version_set_input>;
  where: question_option_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_option_version_by_pkArgs = {
  _inc?: InputMaybe<question_option_version_inc_input>;
  _set?: InputMaybe<question_option_version_set_input>;
  pk_columns: question_option_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_option_version_manyArgs = {
  updates: Array<question_option_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_settingArgs = {
  _inc?: InputMaybe<question_setting_inc_input>;
  _set?: InputMaybe<question_setting_set_input>;
  where: question_setting_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_setting_by_pkArgs = {
  _inc?: InputMaybe<question_setting_inc_input>;
  _set?: InputMaybe<question_setting_set_input>;
  pk_columns: question_setting_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_setting_keyArgs = {
  _set?: InputMaybe<question_setting_key_set_input>;
  where: question_setting_key_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_setting_key_by_pkArgs = {
  _set?: InputMaybe<question_setting_key_set_input>;
  pk_columns: question_setting_key_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_setting_key_manyArgs = {
  updates: Array<question_setting_key_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_setting_manyArgs = {
  updates: Array<question_setting_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_setting_versionArgs = {
  _append?: InputMaybe<question_setting_version_append_input>;
  _delete_at_path?: InputMaybe<question_setting_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_setting_version_delete_elem_input>;
  _delete_key?: InputMaybe<question_setting_version_delete_key_input>;
  _inc?: InputMaybe<question_setting_version_inc_input>;
  _prepend?: InputMaybe<question_setting_version_prepend_input>;
  _set?: InputMaybe<question_setting_version_set_input>;
  where: question_setting_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_setting_version_by_pkArgs = {
  _append?: InputMaybe<question_setting_version_append_input>;
  _delete_at_path?: InputMaybe<question_setting_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_setting_version_delete_elem_input>;
  _delete_key?: InputMaybe<question_setting_version_delete_key_input>;
  _inc?: InputMaybe<question_setting_version_inc_input>;
  _prepend?: InputMaybe<question_setting_version_prepend_input>;
  _set?: InputMaybe<question_setting_version_set_input>;
  pk_columns: question_setting_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_setting_version_manyArgs = {
  updates: Array<question_setting_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_typeArgs = {
  _set?: InputMaybe<question_type_set_input>;
  where: question_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_type_by_pkArgs = {
  _set?: InputMaybe<question_type_set_input>;
  pk_columns: question_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_type_manyArgs = {
  updates: Array<question_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_question_versionArgs = {
  _append?: InputMaybe<question_version_append_input>;
  _delete_at_path?: InputMaybe<question_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_version_delete_elem_input>;
  _delete_key?: InputMaybe<question_version_delete_key_input>;
  _inc?: InputMaybe<question_version_inc_input>;
  _prepend?: InputMaybe<question_version_prepend_input>;
  _set?: InputMaybe<question_version_set_input>;
  where: question_version_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_question_version_by_pkArgs = {
  _append?: InputMaybe<question_version_append_input>;
  _delete_at_path?: InputMaybe<question_version_delete_at_path_input>;
  _delete_elem?: InputMaybe<question_version_delete_elem_input>;
  _delete_key?: InputMaybe<question_version_delete_key_input>;
  _inc?: InputMaybe<question_version_inc_input>;
  _prepend?: InputMaybe<question_version_prepend_input>;
  _set?: InputMaybe<question_version_set_input>;
  pk_columns: question_version_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_question_version_manyArgs = {
  updates: Array<question_version_updates>;
};


/** mutation root */
export type mutation_rootupdate_report_recurring_revenueArgs = {
  _inc?: InputMaybe<report_recurring_revenue_inc_input>;
  _set?: InputMaybe<report_recurring_revenue_set_input>;
  where: report_recurring_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_report_recurring_revenue_by_pkArgs = {
  _inc?: InputMaybe<report_recurring_revenue_inc_input>;
  _set?: InputMaybe<report_recurring_revenue_set_input>;
  pk_columns: report_recurring_revenue_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_report_recurring_revenue_manyArgs = {
  updates: Array<report_recurring_revenue_updates>;
};


/** mutation root */
export type mutation_rootupdate_revenue_logArgs = {
  _inc?: InputMaybe<revenue_log_inc_input>;
  _set?: InputMaybe<revenue_log_set_input>;
  where: revenue_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_by_pkArgs = {
  _inc?: InputMaybe<revenue_log_inc_input>;
  _set?: InputMaybe<revenue_log_set_input>;
  pk_columns: revenue_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_manyArgs = {
  updates: Array<revenue_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_revenue_typeArgs = {
  _set?: InputMaybe<revenue_log_revenue_type_set_input>;
  where: revenue_log_revenue_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_revenue_type_by_pkArgs = {
  _set?: InputMaybe<revenue_log_revenue_type_set_input>;
  pk_columns: revenue_log_revenue_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_revenue_type_manyArgs = {
  updates: Array<revenue_log_revenue_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_typeArgs = {
  _set?: InputMaybe<revenue_log_type_set_input>;
  where: revenue_log_type_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_type_by_pkArgs = {
  _set?: InputMaybe<revenue_log_type_set_input>;
  pk_columns: revenue_log_type_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_revenue_log_type_manyArgs = {
  updates: Array<revenue_log_type_updates>;
};


/** mutation root */
export type mutation_rootupdate_rule_group_modeArgs = {
  _set?: InputMaybe<rule_group_mode_set_input>;
  where: rule_group_mode_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_rule_group_mode_by_pkArgs = {
  _set?: InputMaybe<rule_group_mode_set_input>;
  pk_columns: rule_group_mode_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_rule_group_mode_manyArgs = {
  updates: Array<rule_group_mode_updates>;
};


/** mutation root */
export type mutation_rootupdate_script_logArgs = {
  _append?: InputMaybe<script_log_append_input>;
  _delete_at_path?: InputMaybe<script_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<script_log_delete_elem_input>;
  _delete_key?: InputMaybe<script_log_delete_key_input>;
  _inc?: InputMaybe<script_log_inc_input>;
  _prepend?: InputMaybe<script_log_prepend_input>;
  _set?: InputMaybe<script_log_set_input>;
  where: script_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_script_log_by_pkArgs = {
  _append?: InputMaybe<script_log_append_input>;
  _delete_at_path?: InputMaybe<script_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<script_log_delete_elem_input>;
  _delete_key?: InputMaybe<script_log_delete_key_input>;
  _inc?: InputMaybe<script_log_inc_input>;
  _prepend?: InputMaybe<script_log_prepend_input>;
  _set?: InputMaybe<script_log_set_input>;
  pk_columns: script_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_script_log_manyArgs = {
  updates: Array<script_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_segmentArgs = {
  _append?: InputMaybe<segment_append_input>;
  _delete_at_path?: InputMaybe<segment_delete_at_path_input>;
  _delete_elem?: InputMaybe<segment_delete_elem_input>;
  _delete_key?: InputMaybe<segment_delete_key_input>;
  _inc?: InputMaybe<segment_inc_input>;
  _prepend?: InputMaybe<segment_prepend_input>;
  _set?: InputMaybe<segment_set_input>;
  where: segment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_by_pkArgs = {
  _append?: InputMaybe<segment_append_input>;
  _delete_at_path?: InputMaybe<segment_delete_at_path_input>;
  _delete_elem?: InputMaybe<segment_delete_elem_input>;
  _delete_key?: InputMaybe<segment_delete_key_input>;
  _inc?: InputMaybe<segment_inc_input>;
  _prepend?: InputMaybe<segment_prepend_input>;
  _set?: InputMaybe<segment_set_input>;
  pk_columns: segment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_conditionArgs = {
  _append?: InputMaybe<segment_condition_append_input>;
  _delete_at_path?: InputMaybe<segment_condition_delete_at_path_input>;
  _delete_elem?: InputMaybe<segment_condition_delete_elem_input>;
  _delete_key?: InputMaybe<segment_condition_delete_key_input>;
  _inc?: InputMaybe<segment_condition_inc_input>;
  _prepend?: InputMaybe<segment_condition_prepend_input>;
  _set?: InputMaybe<segment_condition_set_input>;
  where: segment_condition_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_boolean_operatorArgs = {
  _set?: InputMaybe<segment_condition_boolean_operator_set_input>;
  where: segment_condition_boolean_operator_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_boolean_operator_by_pkArgs = {
  _set?: InputMaybe<segment_condition_boolean_operator_set_input>;
  pk_columns: segment_condition_boolean_operator_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_boolean_operator_manyArgs = {
  updates: Array<segment_condition_boolean_operator_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_by_pkArgs = {
  _append?: InputMaybe<segment_condition_append_input>;
  _delete_at_path?: InputMaybe<segment_condition_delete_at_path_input>;
  _delete_elem?: InputMaybe<segment_condition_delete_elem_input>;
  _delete_key?: InputMaybe<segment_condition_delete_key_input>;
  _inc?: InputMaybe<segment_condition_inc_input>;
  _prepend?: InputMaybe<segment_condition_prepend_input>;
  _set?: InputMaybe<segment_condition_set_input>;
  pk_columns: segment_condition_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_groupArgs = {
  _inc?: InputMaybe<segment_condition_group_inc_input>;
  _set?: InputMaybe<segment_condition_group_set_input>;
  where: segment_condition_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_group_by_pkArgs = {
  _inc?: InputMaybe<segment_condition_group_inc_input>;
  _set?: InputMaybe<segment_condition_group_set_input>;
  pk_columns: segment_condition_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_group_entryArgs = {
  _inc?: InputMaybe<segment_condition_group_entry_inc_input>;
  _set?: InputMaybe<segment_condition_group_entry_set_input>;
  where: segment_condition_group_entry_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_group_entry_by_pkArgs = {
  _inc?: InputMaybe<segment_condition_group_entry_inc_input>;
  _set?: InputMaybe<segment_condition_group_entry_set_input>;
  pk_columns: segment_condition_group_entry_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_group_entry_manyArgs = {
  updates: Array<segment_condition_group_entry_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_group_manyArgs = {
  updates: Array<segment_condition_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_manyArgs = {
  updates: Array<segment_condition_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_operatorArgs = {
  _set?: InputMaybe<segment_condition_operator_set_input>;
  where: segment_condition_operator_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_operator_by_pkArgs = {
  _set?: InputMaybe<segment_condition_operator_set_input>;
  pk_columns: segment_condition_operator_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_operator_manyArgs = {
  updates: Array<segment_condition_operator_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_propertyArgs = {
  _set?: InputMaybe<segment_condition_property_set_input>;
  where: segment_condition_property_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_property_by_pkArgs = {
  _set?: InputMaybe<segment_condition_property_set_input>;
  pk_columns: segment_condition_property_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_condition_property_manyArgs = {
  updates: Array<segment_condition_property_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_groupArgs = {
  _inc?: InputMaybe<segment_group_inc_input>;
  _set?: InputMaybe<segment_group_set_input>;
  where: segment_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_group_by_pkArgs = {
  _inc?: InputMaybe<segment_group_inc_input>;
  _set?: InputMaybe<segment_group_set_input>;
  pk_columns: segment_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_group_manyArgs = {
  updates: Array<segment_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_group_operatorArgs = {
  _set?: InputMaybe<segment_group_operator_set_input>;
  where: segment_group_operator_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_group_operator_by_pkArgs = {
  _set?: InputMaybe<segment_group_operator_set_input>;
  pk_columns: segment_group_operator_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_group_operator_manyArgs = {
  updates: Array<segment_group_operator_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_group_segmentArgs = {
  _inc?: InputMaybe<segment_group_segment_inc_input>;
  _set?: InputMaybe<segment_group_segment_set_input>;
  where: segment_group_segment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_group_segment_by_pkArgs = {
  _inc?: InputMaybe<segment_group_segment_inc_input>;
  _set?: InputMaybe<segment_group_segment_set_input>;
  pk_columns: segment_group_segment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_group_segment_manyArgs = {
  updates: Array<segment_group_segment_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_manyArgs = {
  updates: Array<segment_updates>;
};


/** mutation root */
export type mutation_rootupdate_segment_subscription_cacheArgs = {
  _inc?: InputMaybe<segment_subscription_cache_inc_input>;
  _set?: InputMaybe<segment_subscription_cache_set_input>;
  where: segment_subscription_cache_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_segment_subscription_cache_by_pkArgs = {
  _inc?: InputMaybe<segment_subscription_cache_inc_input>;
  _set?: InputMaybe<segment_subscription_cache_set_input>;
  pk_columns: segment_subscription_cache_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_segment_subscription_cache_manyArgs = {
  updates: Array<segment_subscription_cache_updates>;
};


/** mutation root */
export type mutation_rootupdate_sentimentArgs = {
  _set?: InputMaybe<sentiment_set_input>;
  where: sentiment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_sentiment_by_pkArgs = {
  _set?: InputMaybe<sentiment_set_input>;
  pk_columns: sentiment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_sentiment_manyArgs = {
  updates: Array<sentiment_updates>;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phraseArgs = {
  _inc?: InputMaybe<sentiment_phrase_inc_input>;
  _set?: InputMaybe<sentiment_phrase_set_input>;
  where: sentiment_phrase_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phrase_by_pkArgs = {
  _inc?: InputMaybe<sentiment_phrase_inc_input>;
  _set?: InputMaybe<sentiment_phrase_set_input>;
  pk_columns: sentiment_phrase_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phrase_manyArgs = {
  updates: Array<sentiment_phrase_updates>;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phrase_normalizedArgs = {
  _inc?: InputMaybe<sentiment_phrase_normalized_inc_input>;
  _set?: InputMaybe<sentiment_phrase_normalized_set_input>;
  where: sentiment_phrase_normalized_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phrase_normalized_by_pkArgs = {
  _inc?: InputMaybe<sentiment_phrase_normalized_inc_input>;
  _set?: InputMaybe<sentiment_phrase_normalized_set_input>;
  pk_columns: sentiment_phrase_normalized_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_sentiment_phrase_normalized_manyArgs = {
  updates: Array<sentiment_phrase_normalized_updates>;
};


/** mutation root */
export type mutation_rootupdate_stateArgs = {
  _set?: InputMaybe<state_set_input>;
  where: state_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_state_by_pkArgs = {
  _set?: InputMaybe<state_set_input>;
  pk_columns: state_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_state_manyArgs = {
  updates: Array<state_updates>;
};


/** mutation root */
export type mutation_rootupdate_state_nameArgs = {
  _set?: InputMaybe<state_name_set_input>;
  where: state_name_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_state_name_by_pkArgs = {
  _set?: InputMaybe<state_name_set_input>;
  pk_columns: state_name_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_state_name_manyArgs = {
  updates: Array<state_name_updates>;
};


/** mutation root */
export type mutation_rootupdate_stripe_appArgs = {
  _inc?: InputMaybe<stripe_app_inc_input>;
  _set?: InputMaybe<stripe_app_set_input>;
  where: stripe_app_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_stripe_app_by_pkArgs = {
  _inc?: InputMaybe<stripe_app_inc_input>;
  _set?: InputMaybe<stripe_app_set_input>;
  pk_columns: stripe_app_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_stripe_app_manyArgs = {
  updates: Array<stripe_app_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriberArgs = {
  _inc?: InputMaybe<subscriber_inc_input>;
  _set?: InputMaybe<subscriber_set_input>;
  where: subscriber_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_acknowledgementArgs = {
  _inc?: InputMaybe<subscriber_acknowledgement_inc_input>;
  _set?: InputMaybe<subscriber_acknowledgement_set_input>;
  where: subscriber_acknowledgement_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_acknowledgement_by_pkArgs = {
  _inc?: InputMaybe<subscriber_acknowledgement_inc_input>;
  _set?: InputMaybe<subscriber_acknowledgement_set_input>;
  pk_columns: subscriber_acknowledgement_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_acknowledgement_manyArgs = {
  updates: Array<subscriber_acknowledgement_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_arrArgs = {
  _inc?: InputMaybe<subscriber_arr_inc_input>;
  _set?: InputMaybe<subscriber_arr_set_input>;
  where: subscriber_arr_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_arr_manyArgs = {
  updates: Array<subscriber_arr_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_by_pkArgs = {
  _inc?: InputMaybe<subscriber_inc_input>;
  _set?: InputMaybe<subscriber_set_input>;
  pk_columns: subscriber_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaignArgs = {
  _append?: InputMaybe<subscriber_campaign_append_input>;
  _delete_at_path?: InputMaybe<subscriber_campaign_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_campaign_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_campaign_delete_key_input>;
  _inc?: InputMaybe<subscriber_campaign_inc_input>;
  _prepend?: InputMaybe<subscriber_campaign_prepend_input>;
  _set?: InputMaybe<subscriber_campaign_set_input>;
  where: subscriber_campaign_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_by_pkArgs = {
  _append?: InputMaybe<subscriber_campaign_append_input>;
  _delete_at_path?: InputMaybe<subscriber_campaign_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_campaign_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_campaign_delete_key_input>;
  _inc?: InputMaybe<subscriber_campaign_inc_input>;
  _prepend?: InputMaybe<subscriber_campaign_prepend_input>;
  _set?: InputMaybe<subscriber_campaign_set_input>;
  pk_columns: subscriber_campaign_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_manyArgs = {
  updates: Array<subscriber_campaign_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offerArgs = {
  _inc?: InputMaybe<subscriber_campaign_offer_inc_input>;
  _set?: InputMaybe<subscriber_campaign_offer_set_input>;
  where: subscriber_campaign_offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offer_by_pkArgs = {
  _inc?: InputMaybe<subscriber_campaign_offer_inc_input>;
  _set?: InputMaybe<subscriber_campaign_offer_set_input>;
  pk_columns: subscriber_campaign_offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offer_manyArgs = {
  updates: Array<subscriber_campaign_offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offer_ruleArgs = {
  _inc?: InputMaybe<subscriber_campaign_offer_rule_inc_input>;
  _set?: InputMaybe<subscriber_campaign_offer_rule_set_input>;
  where: subscriber_campaign_offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offer_rule_by_pkArgs = {
  _inc?: InputMaybe<subscriber_campaign_offer_rule_inc_input>;
  _set?: InputMaybe<subscriber_campaign_offer_rule_set_input>;
  pk_columns: subscriber_campaign_offer_rule_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_offer_rule_manyArgs = {
  updates: Array<subscriber_campaign_offer_rule_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_overrideArgs = {
  _inc?: InputMaybe<subscriber_campaign_override_inc_input>;
  _set?: InputMaybe<subscriber_campaign_override_set_input>;
  where: subscriber_campaign_override_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_override_by_pkArgs = {
  _inc?: InputMaybe<subscriber_campaign_override_inc_input>;
  _set?: InputMaybe<subscriber_campaign_override_set_input>;
  pk_columns: subscriber_campaign_override_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_override_manyArgs = {
  updates: Array<subscriber_campaign_override_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_segmentArgs = {
  _inc?: InputMaybe<subscriber_campaign_segment_inc_input>;
  _set?: InputMaybe<subscriber_campaign_segment_set_input>;
  where: subscriber_campaign_segment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_segment_by_pkArgs = {
  _inc?: InputMaybe<subscriber_campaign_segment_inc_input>;
  _set?: InputMaybe<subscriber_campaign_segment_set_input>;
  pk_columns: subscriber_campaign_segment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_segment_manyArgs = {
  updates: Array<subscriber_campaign_segment_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_statusArgs = {
  _set?: InputMaybe<subscriber_campaign_status_set_input>;
  where: subscriber_campaign_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_status_by_pkArgs = {
  _set?: InputMaybe<subscriber_campaign_status_set_input>;
  pk_columns: subscriber_campaign_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_status_manyArgs = {
  updates: Array<subscriber_campaign_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_viewArgs = {
  _inc?: InputMaybe<subscriber_campaign_view_inc_input>;
  _set?: InputMaybe<subscriber_campaign_view_set_input>;
  where: subscriber_campaign_view_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_view_by_pkArgs = {
  _inc?: InputMaybe<subscriber_campaign_view_inc_input>;
  _set?: InputMaybe<subscriber_campaign_view_set_input>;
  pk_columns: subscriber_campaign_view_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_view_manyArgs = {
  updates: Array<subscriber_campaign_view_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_view_statusArgs = {
  _set?: InputMaybe<subscriber_campaign_view_status_set_input>;
  where: subscriber_campaign_view_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_view_status_by_pkArgs = {
  _set?: InputMaybe<subscriber_campaign_view_status_set_input>;
  pk_columns: subscriber_campaign_view_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_campaign_view_status_manyArgs = {
  updates: Array<subscriber_campaign_view_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flowArgs = {
  _append?: InputMaybe<subscriber_flow_append_input>;
  _delete_at_path?: InputMaybe<subscriber_flow_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_flow_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_flow_delete_key_input>;
  _inc?: InputMaybe<subscriber_flow_inc_input>;
  _prepend?: InputMaybe<subscriber_flow_prepend_input>;
  _set?: InputMaybe<subscriber_flow_set_input>;
  where: subscriber_flow_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_by_pkArgs = {
  _append?: InputMaybe<subscriber_flow_append_input>;
  _delete_at_path?: InputMaybe<subscriber_flow_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_flow_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_flow_delete_key_input>;
  _inc?: InputMaybe<subscriber_flow_inc_input>;
  _prepend?: InputMaybe<subscriber_flow_prepend_input>;
  _set?: InputMaybe<subscriber_flow_set_input>;
  pk_columns: subscriber_flow_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_deflectionArgs = {
  _inc?: InputMaybe<subscriber_flow_deflection_inc_input>;
  _set?: InputMaybe<subscriber_flow_deflection_set_input>;
  where: subscriber_flow_deflection_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_deflection_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_deflection_inc_input>;
  _set?: InputMaybe<subscriber_flow_deflection_set_input>;
  pk_columns: subscriber_flow_deflection_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_deflection_manyArgs = {
  updates: Array<subscriber_flow_deflection_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_exportArgs = {
  _inc?: InputMaybe<subscriber_flow_export_inc_input>;
  _set?: InputMaybe<subscriber_flow_export_set_input>;
  where: subscriber_flow_export_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_export_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_export_inc_input>;
  _set?: InputMaybe<subscriber_flow_export_set_input>;
  pk_columns: subscriber_flow_export_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_export_manyArgs = {
  updates: Array<subscriber_flow_export_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_flow_stepArgs = {
  _inc?: InputMaybe<subscriber_flow_flow_step_inc_input>;
  _set?: InputMaybe<subscriber_flow_flow_step_set_input>;
  where: subscriber_flow_flow_step_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_flow_step_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_flow_step_inc_input>;
  _set?: InputMaybe<subscriber_flow_flow_step_set_input>;
  pk_columns: subscriber_flow_flow_step_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_flow_step_manyArgs = {
  updates: Array<subscriber_flow_flow_step_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_manyArgs = {
  updates: Array<subscriber_flow_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offerArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_set_input>;
  where: subscriber_flow_offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_set_input>;
  pk_columns: subscriber_flow_offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_groupArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_group_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_group_set_input>;
  where: subscriber_flow_offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_group_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_group_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_group_set_input>;
  pk_columns: subscriber_flow_offer_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_group_manyArgs = {
  updates: Array<subscriber_flow_offer_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_group_offerArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_group_offer_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_group_offer_set_input>;
  where: subscriber_flow_offer_group_offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_group_offer_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_group_offer_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_group_offer_set_input>;
  pk_columns: subscriber_flow_offer_group_offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_group_offer_manyArgs = {
  updates: Array<subscriber_flow_offer_group_offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_manyArgs = {
  updates: Array<subscriber_flow_offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_ruleArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_rule_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_rule_set_input>;
  where: subscriber_flow_offer_rule_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_rule_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_offer_rule_inc_input>;
  _set?: InputMaybe<subscriber_flow_offer_rule_set_input>;
  pk_columns: subscriber_flow_offer_rule_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_rule_manyArgs = {
  updates: Array<subscriber_flow_offer_rule_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_statusArgs = {
  _set?: InputMaybe<subscriber_flow_offer_status_set_input>;
  where: subscriber_flow_offer_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_status_by_pkArgs = {
  _set?: InputMaybe<subscriber_flow_offer_status_set_input>;
  pk_columns: subscriber_flow_offer_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_offer_status_manyArgs = {
  updates: Array<subscriber_flow_offer_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_on_cancel_overrideArgs = {
  _inc?: InputMaybe<subscriber_flow_on_cancel_override_inc_input>;
  _set?: InputMaybe<subscriber_flow_on_cancel_override_set_input>;
  where: subscriber_flow_on_cancel_override_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_on_cancel_override_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_on_cancel_override_inc_input>;
  _set?: InputMaybe<subscriber_flow_on_cancel_override_set_input>;
  pk_columns: subscriber_flow_on_cancel_override_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_on_cancel_override_manyArgs = {
  updates: Array<subscriber_flow_on_cancel_override_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_overrideArgs = {
  _inc?: InputMaybe<subscriber_flow_override_inc_input>;
  _set?: InputMaybe<subscriber_flow_override_set_input>;
  where: subscriber_flow_override_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_override_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_override_inc_input>;
  _set?: InputMaybe<subscriber_flow_override_set_input>;
  pk_columns: subscriber_flow_override_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_override_manyArgs = {
  updates: Array<subscriber_flow_override_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_override_reasonArgs = {
  _set?: InputMaybe<subscriber_flow_override_reason_set_input>;
  where: subscriber_flow_override_reason_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_override_reason_by_pkArgs = {
  _set?: InputMaybe<subscriber_flow_override_reason_set_input>;
  pk_columns: subscriber_flow_override_reason_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_override_reason_manyArgs = {
  updates: Array<subscriber_flow_override_reason_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_question_answerArgs = {
  _inc?: InputMaybe<subscriber_flow_question_answer_inc_input>;
  _set?: InputMaybe<subscriber_flow_question_answer_set_input>;
  where: subscriber_flow_question_answer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_question_answer_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_question_answer_inc_input>;
  _set?: InputMaybe<subscriber_flow_question_answer_set_input>;
  pk_columns: subscriber_flow_question_answer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_question_answer_manyArgs = {
  updates: Array<subscriber_flow_question_answer_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segmentArgs = {
  _inc?: InputMaybe<subscriber_flow_segment_inc_input>;
  _set?: InputMaybe<subscriber_flow_segment_set_input>;
  where: subscriber_flow_segment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segment_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_segment_inc_input>;
  _set?: InputMaybe<subscriber_flow_segment_set_input>;
  pk_columns: subscriber_flow_segment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segment_groupArgs = {
  _inc?: InputMaybe<subscriber_flow_segment_group_inc_input>;
  _set?: InputMaybe<subscriber_flow_segment_group_set_input>;
  where: subscriber_flow_segment_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segment_group_by_pkArgs = {
  _inc?: InputMaybe<subscriber_flow_segment_group_inc_input>;
  _set?: InputMaybe<subscriber_flow_segment_group_set_input>;
  pk_columns: subscriber_flow_segment_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segment_group_manyArgs = {
  updates: Array<subscriber_flow_segment_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_segment_manyArgs = {
  updates: Array<subscriber_flow_segment_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_statusArgs = {
  _set?: InputMaybe<subscriber_flow_status_set_input>;
  where: subscriber_flow_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_status_by_pkArgs = {
  _set?: InputMaybe<subscriber_flow_status_set_input>;
  pk_columns: subscriber_flow_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_flow_status_manyArgs = {
  updates: Array<subscriber_flow_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_logArgs = {
  _append?: InputMaybe<subscriber_log_append_input>;
  _delete_at_path?: InputMaybe<subscriber_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_log_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_log_delete_key_input>;
  _inc?: InputMaybe<subscriber_log_inc_input>;
  _prepend?: InputMaybe<subscriber_log_prepend_input>;
  _set?: InputMaybe<subscriber_log_set_input>;
  where: subscriber_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_by_pkArgs = {
  _append?: InputMaybe<subscriber_log_append_input>;
  _delete_at_path?: InputMaybe<subscriber_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_log_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_log_delete_key_input>;
  _inc?: InputMaybe<subscriber_log_inc_input>;
  _prepend?: InputMaybe<subscriber_log_prepend_input>;
  _set?: InputMaybe<subscriber_log_set_input>;
  pk_columns: subscriber_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_eventArgs = {
  _set?: InputMaybe<subscriber_log_event_set_input>;
  where: subscriber_log_event_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_event_by_pkArgs = {
  _set?: InputMaybe<subscriber_log_event_set_input>;
  pk_columns: subscriber_log_event_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_event_manyArgs = {
  updates: Array<subscriber_log_event_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_manyArgs = {
  updates: Array<subscriber_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offerArgs = {
  _inc?: InputMaybe<subscriber_log_offer_inc_input>;
  _set?: InputMaybe<subscriber_log_offer_set_input>;
  where: subscriber_log_offer_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offer_by_pkArgs = {
  _inc?: InputMaybe<subscriber_log_offer_inc_input>;
  _set?: InputMaybe<subscriber_log_offer_set_input>;
  pk_columns: subscriber_log_offer_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offer_groupArgs = {
  _inc?: InputMaybe<subscriber_log_offer_group_inc_input>;
  _set?: InputMaybe<subscriber_log_offer_group_set_input>;
  where: subscriber_log_offer_group_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offer_group_by_pkArgs = {
  _inc?: InputMaybe<subscriber_log_offer_group_inc_input>;
  _set?: InputMaybe<subscriber_log_offer_group_set_input>;
  pk_columns: subscriber_log_offer_group_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offer_group_manyArgs = {
  updates: Array<subscriber_log_offer_group_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_log_offer_manyArgs = {
  updates: Array<subscriber_log_offer_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_manyArgs = {
  updates: Array<subscriber_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_mrrArgs = {
  _inc?: InputMaybe<subscriber_mrr_inc_input>;
  _set?: InputMaybe<subscriber_mrr_set_input>;
  where: subscriber_mrr_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_mrr_manyArgs = {
  updates: Array<subscriber_mrr_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_propertyArgs = {
  _append?: InputMaybe<subscriber_property_append_input>;
  _delete_at_path?: InputMaybe<subscriber_property_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_property_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_property_delete_key_input>;
  _inc?: InputMaybe<subscriber_property_inc_input>;
  _prepend?: InputMaybe<subscriber_property_prepend_input>;
  _set?: InputMaybe<subscriber_property_set_input>;
  where: subscriber_property_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_property_by_pkArgs = {
  _append?: InputMaybe<subscriber_property_append_input>;
  _delete_at_path?: InputMaybe<subscriber_property_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_property_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_property_delete_key_input>;
  _inc?: InputMaybe<subscriber_property_inc_input>;
  _prepend?: InputMaybe<subscriber_property_prepend_input>;
  _set?: InputMaybe<subscriber_property_set_input>;
  pk_columns: subscriber_property_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_property_manyArgs = {
  updates: Array<subscriber_property_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_property_valueArgs = {
  _append?: InputMaybe<subscriber_property_value_append_input>;
  _delete_at_path?: InputMaybe<subscriber_property_value_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscriber_property_value_delete_elem_input>;
  _delete_key?: InputMaybe<subscriber_property_value_delete_key_input>;
  _inc?: InputMaybe<subscriber_property_value_inc_input>;
  _prepend?: InputMaybe<subscriber_property_value_prepend_input>;
  _set?: InputMaybe<subscriber_property_value_set_input>;
  where: subscriber_property_value_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_property_value_manyArgs = {
  updates: Array<subscriber_property_value_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriber_statusArgs = {
  _set?: InputMaybe<subscriber_status_set_input>;
  where: subscriber_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscriber_status_by_pkArgs = {
  _set?: InputMaybe<subscriber_status_set_input>;
  pk_columns: subscriber_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscriber_status_manyArgs = {
  updates: Array<subscriber_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscriptionArgs = {
  _inc?: InputMaybe<subscription_inc_input>;
  _set?: InputMaybe<subscription_set_input>;
  where: subscription_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_arrArgs = {
  _inc?: InputMaybe<subscription_arr_inc_input>;
  _set?: InputMaybe<subscription_arr_set_input>;
  where: subscription_arr_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_arr_manyArgs = {
  updates: Array<subscription_arr_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_by_pkArgs = {
  _inc?: InputMaybe<subscription_inc_input>;
  _set?: InputMaybe<subscription_set_input>;
  pk_columns: subscription_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_manyArgs = {
  updates: Array<subscription_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_productArgs = {
  _inc?: InputMaybe<subscription_product_inc_input>;
  _set?: InputMaybe<subscription_product_set_input>;
  where: subscription_product_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_product_by_pkArgs = {
  _inc?: InputMaybe<subscription_product_inc_input>;
  _set?: InputMaybe<subscription_product_set_input>;
  pk_columns: subscription_product_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_product_manyArgs = {
  updates: Array<subscription_product_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_propertyArgs = {
  _append?: InputMaybe<subscription_property_append_input>;
  _delete_at_path?: InputMaybe<subscription_property_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscription_property_delete_elem_input>;
  _delete_key?: InputMaybe<subscription_property_delete_key_input>;
  _inc?: InputMaybe<subscription_property_inc_input>;
  _prepend?: InputMaybe<subscription_property_prepend_input>;
  _set?: InputMaybe<subscription_property_set_input>;
  where: subscription_property_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_property_by_pkArgs = {
  _append?: InputMaybe<subscription_property_append_input>;
  _delete_at_path?: InputMaybe<subscription_property_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscription_property_delete_elem_input>;
  _delete_key?: InputMaybe<subscription_property_delete_key_input>;
  _inc?: InputMaybe<subscription_property_inc_input>;
  _prepend?: InputMaybe<subscription_property_prepend_input>;
  _set?: InputMaybe<subscription_property_set_input>;
  pk_columns: subscription_property_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_property_manyArgs = {
  updates: Array<subscription_property_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_property_valueArgs = {
  _append?: InputMaybe<subscription_property_value_append_input>;
  _delete_at_path?: InputMaybe<subscription_property_value_delete_at_path_input>;
  _delete_elem?: InputMaybe<subscription_property_value_delete_elem_input>;
  _delete_key?: InputMaybe<subscription_property_value_delete_key_input>;
  _inc?: InputMaybe<subscription_property_value_inc_input>;
  _prepend?: InputMaybe<subscription_property_value_prepend_input>;
  _set?: InputMaybe<subscription_property_value_set_input>;
  where: subscription_property_value_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_property_value_manyArgs = {
  updates: Array<subscription_property_value_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_segmentArgs = {
  _inc?: InputMaybe<subscription_segment_inc_input>;
  _set?: InputMaybe<subscription_segment_set_input>;
  where: subscription_segment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_segment_by_pkArgs = {
  _inc?: InputMaybe<subscription_segment_inc_input>;
  _set?: InputMaybe<subscription_segment_set_input>;
  pk_columns: subscription_segment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_segment_manyArgs = {
  updates: Array<subscription_segment_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_segment_statusArgs = {
  _inc?: InputMaybe<subscription_segment_status_inc_input>;
  _set?: InputMaybe<subscription_segment_status_set_input>;
  where: subscription_segment_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_segment_status_by_pkArgs = {
  _inc?: InputMaybe<subscription_segment_status_inc_input>;
  _set?: InputMaybe<subscription_segment_status_set_input>;
  pk_columns: subscription_segment_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_segment_status_manyArgs = {
  updates: Array<subscription_segment_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_subscription_statusArgs = {
  _set?: InputMaybe<subscription_status_set_input>;
  where: subscription_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_subscription_status_by_pkArgs = {
  _set?: InputMaybe<subscription_status_set_input>;
  pk_columns: subscription_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_subscription_status_manyArgs = {
  updates: Array<subscription_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_surveyArgs = {
  _inc?: InputMaybe<survey_inc_input>;
  _set?: InputMaybe<survey_set_input>;
  where: survey_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_survey_by_pkArgs = {
  _inc?: InputMaybe<survey_inc_input>;
  _set?: InputMaybe<survey_set_input>;
  pk_columns: survey_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_survey_manyArgs = {
  updates: Array<survey_updates>;
};


/** mutation root */
export type mutation_rootupdate_tagArgs = {
  _inc?: InputMaybe<tag_inc_input>;
  _set?: InputMaybe<tag_set_input>;
  where: tag_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_tag_by_pkArgs = {
  _inc?: InputMaybe<tag_inc_input>;
  _set?: InputMaybe<tag_set_input>;
  pk_columns: tag_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_tag_manyArgs = {
  updates: Array<tag_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_deflection_trendsArgs = {
  _inc?: InputMaybe<template_report_deflection_trends_inc_input>;
  _set?: InputMaybe<template_report_deflection_trends_set_input>;
  where: template_report_deflection_trends_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_deflection_trends_by_pkArgs = {
  _inc?: InputMaybe<template_report_deflection_trends_inc_input>;
  _set?: InputMaybe<template_report_deflection_trends_set_input>;
  pk_columns: template_report_deflection_trends_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_deflection_trends_manyArgs = {
  updates: Array<template_report_deflection_trends_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_feedback_trendsArgs = {
  _inc?: InputMaybe<template_report_feedback_trends_inc_input>;
  _set?: InputMaybe<template_report_feedback_trends_set_input>;
  where: template_report_feedback_trends_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_feedback_trends_by_pkArgs = {
  _inc?: InputMaybe<template_report_feedback_trends_inc_input>;
  _set?: InputMaybe<template_report_feedback_trends_set_input>;
  pk_columns: template_report_feedback_trends_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_feedback_trends_manyArgs = {
  updates: Array<template_report_feedback_trends_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_accept_rateArgs = {
  _inc?: InputMaybe<template_report_offer_accept_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_accept_rate_set_input>;
  where: template_report_offer_accept_rate_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_accept_rate_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_accept_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_accept_rate_set_input>;
  pk_columns: template_report_offer_accept_rate_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_accept_rate_manyArgs = {
  updates: Array<template_report_offer_accept_rate_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_accept_rateArgs = {
  _inc?: InputMaybe<template_report_offer_group_accept_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_group_accept_rate_set_input>;
  where: template_report_offer_group_accept_rate_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_accept_rate_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_group_accept_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_group_accept_rate_set_input>;
  pk_columns: template_report_offer_group_accept_rate_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_accept_rate_manyArgs = {
  updates: Array<template_report_offer_group_accept_rate_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_retained_revenueArgs = {
  _inc?: InputMaybe<template_report_offer_group_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_offer_group_retained_revenue_set_input>;
  where: template_report_offer_group_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_retained_revenue_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_group_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_offer_group_retained_revenue_set_input>;
  pk_columns: template_report_offer_group_retained_revenue_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_group_retained_revenue_manyArgs = {
  updates: Array<template_report_offer_group_retained_revenue_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_rateArgs = {
  _inc?: InputMaybe<template_report_offer_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_rate_set_input>;
  where: template_report_offer_rate_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_rate_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_rate_inc_input>;
  _set?: InputMaybe<template_report_offer_rate_set_input>;
  pk_columns: template_report_offer_rate_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_rate_manyArgs = {
  updates: Array<template_report_offer_rate_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_resolutionArgs = {
  _inc?: InputMaybe<template_report_offer_resolution_inc_input>;
  _set?: InputMaybe<template_report_offer_resolution_set_input>;
  where: template_report_offer_resolution_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_resolution_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_resolution_inc_input>;
  _set?: InputMaybe<template_report_offer_resolution_set_input>;
  pk_columns: template_report_offer_resolution_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_resolution_manyArgs = {
  updates: Array<template_report_offer_resolution_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_retained_revenueArgs = {
  _inc?: InputMaybe<template_report_offer_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_offer_retained_revenue_set_input>;
  where: template_report_offer_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_retained_revenue_by_pkArgs = {
  _inc?: InputMaybe<template_report_offer_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_offer_retained_revenue_set_input>;
  pk_columns: template_report_offer_retained_revenue_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_offer_retained_revenue_manyArgs = {
  updates: Array<template_report_offer_retained_revenue_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radioArgs = {
  _inc?: InputMaybe<template_report_question_answer_radio_inc_input>;
  _set?: InputMaybe<template_report_question_answer_radio_set_input>;
  where: template_report_question_answer_radio_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radio_by_pkArgs = {
  _inc?: InputMaybe<template_report_question_answer_radio_inc_input>;
  _set?: InputMaybe<template_report_question_answer_radio_set_input>;
  pk_columns: template_report_question_answer_radio_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radio_inactive_mrrArgs = {
  _inc?: InputMaybe<template_report_question_answer_radio_inactive_mrr_inc_input>;
  _set?: InputMaybe<template_report_question_answer_radio_inactive_mrr_set_input>;
  where: template_report_question_answer_radio_inactive_mrr_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radio_inactive_mrr_by_pkArgs = {
  _inc?: InputMaybe<template_report_question_answer_radio_inactive_mrr_inc_input>;
  _set?: InputMaybe<template_report_question_answer_radio_inactive_mrr_set_input>;
  pk_columns: template_report_question_answer_radio_inactive_mrr_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radio_inactive_mrr_manyArgs = {
  updates: Array<template_report_question_answer_radio_inactive_mrr_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_radio_manyArgs = {
  updates: Array<template_report_question_answer_radio_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_sentimentArgs = {
  _inc?: InputMaybe<template_report_question_answer_sentiment_inc_input>;
  _set?: InputMaybe<template_report_question_answer_sentiment_set_input>;
  where: template_report_question_answer_sentiment_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_sentiment_by_pkArgs = {
  _inc?: InputMaybe<template_report_question_answer_sentiment_inc_input>;
  _set?: InputMaybe<template_report_question_answer_sentiment_set_input>;
  pk_columns: template_report_question_answer_sentiment_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_question_answer_sentiment_manyArgs = {
  updates: Array<template_report_question_answer_sentiment_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_retained_revenueArgs = {
  _inc?: InputMaybe<template_report_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_retained_revenue_set_input>;
  where: template_report_retained_revenue_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_retained_revenue_by_pkArgs = {
  _inc?: InputMaybe<template_report_retained_revenue_inc_input>;
  _set?: InputMaybe<template_report_retained_revenue_set_input>;
  pk_columns: template_report_retained_revenue_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_retained_revenue_manyArgs = {
  updates: Array<template_report_retained_revenue_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_retention_trendsArgs = {
  _inc?: InputMaybe<template_report_retention_trends_inc_input>;
  _set?: InputMaybe<template_report_retention_trends_set_input>;
  where: template_report_retention_trends_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_retention_trends_by_pkArgs = {
  _inc?: InputMaybe<template_report_retention_trends_inc_input>;
  _set?: InputMaybe<template_report_retention_trends_set_input>;
  pk_columns: template_report_retention_trends_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_retention_trends_manyArgs = {
  updates: Array<template_report_retention_trends_updates>;
};


/** mutation root */
export type mutation_rootupdate_template_report_subscriber_flow_statusArgs = {
  _inc?: InputMaybe<template_report_subscriber_flow_status_inc_input>;
  _set?: InputMaybe<template_report_subscriber_flow_status_set_input>;
  where: template_report_subscriber_flow_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_template_report_subscriber_flow_status_by_pkArgs = {
  _inc?: InputMaybe<template_report_subscriber_flow_status_inc_input>;
  _set?: InputMaybe<template_report_subscriber_flow_status_set_input>;
  pk_columns: template_report_subscriber_flow_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_template_report_subscriber_flow_status_manyArgs = {
  updates: Array<template_report_subscriber_flow_status_updates>;
};


/** mutation root */
export type mutation_rootupdate_translationArgs = {
  _inc?: InputMaybe<translation_inc_input>;
  _set?: InputMaybe<translation_set_input>;
  where: translation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_translation_by_pkArgs = {
  _inc?: InputMaybe<translation_inc_input>;
  _set?: InputMaybe<translation_set_input>;
  pk_columns: translation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_translation_manyArgs = {
  updates: Array<translation_updates>;
};


/** mutation root */
export type mutation_rootupdate_translation_valueArgs = {
  _append?: InputMaybe<translation_value_append_input>;
  _delete_at_path?: InputMaybe<translation_value_delete_at_path_input>;
  _delete_elem?: InputMaybe<translation_value_delete_elem_input>;
  _delete_key?: InputMaybe<translation_value_delete_key_input>;
  _inc?: InputMaybe<translation_value_inc_input>;
  _prepend?: InputMaybe<translation_value_prepend_input>;
  _set?: InputMaybe<translation_value_set_input>;
  where: translation_value_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_translation_value_by_pkArgs = {
  _append?: InputMaybe<translation_value_append_input>;
  _delete_at_path?: InputMaybe<translation_value_delete_at_path_input>;
  _delete_elem?: InputMaybe<translation_value_delete_elem_input>;
  _delete_key?: InputMaybe<translation_value_delete_key_input>;
  _inc?: InputMaybe<translation_value_inc_input>;
  _prepend?: InputMaybe<translation_value_prepend_input>;
  _set?: InputMaybe<translation_value_set_input>;
  pk_columns: translation_value_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_translation_value_formatArgs = {
  _set?: InputMaybe<translation_value_format_set_input>;
  where: translation_value_format_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_translation_value_format_by_pkArgs = {
  _set?: InputMaybe<translation_value_format_set_input>;
  pk_columns: translation_value_format_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_translation_value_format_manyArgs = {
  updates: Array<translation_value_format_updates>;
};


/** mutation root */
export type mutation_rootupdate_translation_value_manyArgs = {
  updates: Array<translation_value_updates>;
};


/** mutation root */
export type mutation_rootupdate_userArgs = {
  _set?: InputMaybe<user_set_input>;
  where: user_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_user_by_pkArgs = {
  _set?: InputMaybe<user_set_input>;
  pk_columns: user_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_user_invitationArgs = {
  _inc?: InputMaybe<user_invitation_inc_input>;
  _set?: InputMaybe<user_invitation_set_input>;
  where: user_invitation_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_user_invitation_by_pkArgs = {
  _inc?: InputMaybe<user_invitation_inc_input>;
  _set?: InputMaybe<user_invitation_set_input>;
  pk_columns: user_invitation_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_user_invitation_manyArgs = {
  updates: Array<user_invitation_updates>;
};


/** mutation root */
export type mutation_rootupdate_user_manyArgs = {
  updates: Array<user_updates>;
};


/** mutation root */
export type mutation_rootupdate_user_profileArgs = {
  _set?: InputMaybe<user_profile_set_input>;
  where: user_profile_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_user_profile_by_pkArgs = {
  _set?: InputMaybe<user_profile_set_input>;
  pk_columns: user_profile_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_user_profile_manyArgs = {
  updates: Array<user_profile_updates>;
};


/** mutation root */
export type mutation_rootupdate_webhookArgs = {
  _inc?: InputMaybe<webhook_inc_input>;
  _set?: InputMaybe<webhook_set_input>;
  where: webhook_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_webhook_by_pkArgs = {
  _inc?: InputMaybe<webhook_inc_input>;
  _set?: InputMaybe<webhook_set_input>;
  pk_columns: webhook_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_webhook_eventArgs = {
  _inc?: InputMaybe<webhook_event_inc_input>;
  _set?: InputMaybe<webhook_event_set_input>;
  where: webhook_event_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_webhook_event_by_pkArgs = {
  _inc?: InputMaybe<webhook_event_inc_input>;
  _set?: InputMaybe<webhook_event_set_input>;
  pk_columns: webhook_event_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_webhook_event_manyArgs = {
  updates: Array<webhook_event_updates>;
};


/** mutation root */
export type mutation_rootupdate_webhook_logArgs = {
  _inc?: InputMaybe<webhook_log_inc_input>;
  _set?: InputMaybe<webhook_log_set_input>;
  where: webhook_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_webhook_log_by_pkArgs = {
  _inc?: InputMaybe<webhook_log_inc_input>;
  _set?: InputMaybe<webhook_log_set_input>;
  pk_columns: webhook_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_webhook_log_manyArgs = {
  updates: Array<webhook_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_webhook_manyArgs = {
  updates: Array<webhook_updates>;
};


/** mutation root */
export type mutation_rootupdate_webhook_request_logArgs = {
  _append?: InputMaybe<webhook_request_log_append_input>;
  _delete_at_path?: InputMaybe<webhook_request_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<webhook_request_log_delete_elem_input>;
  _delete_key?: InputMaybe<webhook_request_log_delete_key_input>;
  _inc?: InputMaybe<webhook_request_log_inc_input>;
  _prepend?: InputMaybe<webhook_request_log_prepend_input>;
  _set?: InputMaybe<webhook_request_log_set_input>;
  where: webhook_request_log_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_webhook_request_log_by_pkArgs = {
  _append?: InputMaybe<webhook_request_log_append_input>;
  _delete_at_path?: InputMaybe<webhook_request_log_delete_at_path_input>;
  _delete_elem?: InputMaybe<webhook_request_log_delete_elem_input>;
  _delete_key?: InputMaybe<webhook_request_log_delete_key_input>;
  _inc?: InputMaybe<webhook_request_log_inc_input>;
  _prepend?: InputMaybe<webhook_request_log_prepend_input>;
  _set?: InputMaybe<webhook_request_log_set_input>;
  pk_columns: webhook_request_log_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_webhook_request_log_manyArgs = {
  updates: Array<webhook_request_log_updates>;
};


/** mutation root */
export type mutation_rootupdate_webhook_statusArgs = {
  _set?: InputMaybe<webhook_status_set_input>;
  where: webhook_status_bool_exp;
};


/** mutation root */
export type mutation_rootupdate_webhook_status_by_pkArgs = {
  _set?: InputMaybe<webhook_status_set_input>;
  pk_columns: webhook_status_pk_columns_input;
};


/** mutation root */
export type mutation_rootupdate_webhook_status_manyArgs = {
  updates: Array<webhook_status_updates>;
};


/** mutation root */
export type mutation_rootupgradeAccountArgs = {
  input: UpgradeAccountInput;
};


/** mutation root */
export type mutation_rootverifyEmailCodeArgs = {
  input: VerifyEmailCodeInput;
};

/** columns and relationships of "naviga_cancel_link" */
export type naviga_cancel_link = {
  __typename: 'naviga_cancel_link';
  client_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  link_text: Scalars['String']['output'];
  media_group_code: Scalars['String']['output'];
};

/** aggregated selection of "naviga_cancel_link" */
export type naviga_cancel_link_aggregate = {
  __typename: 'naviga_cancel_link_aggregate';
  aggregate?: Maybe<naviga_cancel_link_aggregate_fields>;
  nodes: Array<naviga_cancel_link>;
};

/** aggregate fields of "naviga_cancel_link" */
export type naviga_cancel_link_aggregate_fields = {
  __typename: 'naviga_cancel_link_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<naviga_cancel_link_max_fields>;
  min?: Maybe<naviga_cancel_link_min_fields>;
};


/** aggregate fields of "naviga_cancel_link" */
export type naviga_cancel_link_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<naviga_cancel_link_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "naviga_cancel_link". All fields are combined with a logical 'AND'. */
export type naviga_cancel_link_bool_exp = {
  _and?: InputMaybe<Array<naviga_cancel_link_bool_exp>>;
  _not?: InputMaybe<naviga_cancel_link_bool_exp>;
  _or?: InputMaybe<Array<naviga_cancel_link_bool_exp>>;
  client_code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  link_text?: InputMaybe<String_comparison_exp>;
  media_group_code?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "naviga_cancel_link" */
export enum naviga_cancel_link_constraint {
  /** unique or primary key constraint on columns "media_group_code", "client_code", "link_text" */
  naviga_cancel_link_pkey = 'naviga_cancel_link_pkey'
}

/** input type for inserting data into table "naviga_cancel_link" */
export type naviga_cancel_link_insert_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type naviga_cancel_link_max_fields = {
  __typename: 'naviga_cancel_link_max_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type naviga_cancel_link_min_fields = {
  __typename: 'naviga_cancel_link_min_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "naviga_cancel_link" */
export type naviga_cancel_link_mutation_response = {
  __typename: 'naviga_cancel_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<naviga_cancel_link>;
};

/** on_conflict condition type for table "naviga_cancel_link" */
export type naviga_cancel_link_on_conflict = {
  constraint: naviga_cancel_link_constraint;
  update_columns?: Array<naviga_cancel_link_update_column>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};

/** Ordering options when selecting data from "naviga_cancel_link". */
export type naviga_cancel_link_order_by = {
  client_code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  link_text?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
};

/** primary key columns input for table: naviga_cancel_link */
export type naviga_cancel_link_pk_columns_input = {
  client_code: Scalars['String']['input'];
  link_text: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
};

/** select columns of table "naviga_cancel_link" */
export enum naviga_cancel_link_select_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  link_text = 'link_text',
  /** column name */
  media_group_code = 'media_group_code'
}

/** input type for updating data in table "naviga_cancel_link" */
export type naviga_cancel_link_set_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "naviga_cancel_link" */
export type naviga_cancel_link_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: naviga_cancel_link_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type naviga_cancel_link_stream_cursor_value_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "naviga_cancel_link" */
export enum naviga_cancel_link_update_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  link_text = 'link_text',
  /** column name */
  media_group_code = 'media_group_code'
}

export type naviga_cancel_link_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<naviga_cancel_link_set_input>;
  /** filter the rows which have to be updated */
  where: naviga_cancel_link_bool_exp;
};

/** columns and relationships of "naviga_domain" */
export type naviga_domain = {
  __typename: 'naviga_domain';
  client_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  default_paper_code: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  media_group_code: Scalars['String']['output'];
};

/** aggregated selection of "naviga_domain" */
export type naviga_domain_aggregate = {
  __typename: 'naviga_domain_aggregate';
  aggregate?: Maybe<naviga_domain_aggregate_fields>;
  nodes: Array<naviga_domain>;
};

/** aggregate fields of "naviga_domain" */
export type naviga_domain_aggregate_fields = {
  __typename: 'naviga_domain_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<naviga_domain_max_fields>;
  min?: Maybe<naviga_domain_min_fields>;
};


/** aggregate fields of "naviga_domain" */
export type naviga_domain_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<naviga_domain_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "naviga_domain". All fields are combined with a logical 'AND'. */
export type naviga_domain_bool_exp = {
  _and?: InputMaybe<Array<naviga_domain_bool_exp>>;
  _not?: InputMaybe<naviga_domain_bool_exp>;
  _or?: InputMaybe<Array<naviga_domain_bool_exp>>;
  client_code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  default_paper_code?: InputMaybe<String_comparison_exp>;
  domain?: InputMaybe<String_comparison_exp>;
  media_group_code?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "naviga_domain" */
export enum naviga_domain_constraint {
  /** unique or primary key constraint on columns "domain" */
  naviga_domain_pkey = 'naviga_domain_pkey'
}

/** input type for inserting data into table "naviga_domain" */
export type naviga_domain_insert_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_paper_code?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type naviga_domain_max_fields = {
  __typename: 'naviga_domain_max_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_paper_code?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type naviga_domain_min_fields = {
  __typename: 'naviga_domain_min_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  default_paper_code?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "naviga_domain" */
export type naviga_domain_mutation_response = {
  __typename: 'naviga_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<naviga_domain>;
};

/** input type for inserting object relation for remote table "naviga_domain" */
export type naviga_domain_obj_rel_insert_input = {
  data: naviga_domain_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<naviga_domain_on_conflict>;
};

/** on_conflict condition type for table "naviga_domain" */
export type naviga_domain_on_conflict = {
  constraint: naviga_domain_constraint;
  update_columns?: Array<naviga_domain_update_column>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};

/** Ordering options when selecting data from "naviga_domain". */
export type naviga_domain_order_by = {
  client_code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  default_paper_code?: InputMaybe<order_by>;
  domain?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
};

/** primary key columns input for table: naviga_domain */
export type naviga_domain_pk_columns_input = {
  domain: Scalars['String']['input'];
};

/** select columns of table "naviga_domain" */
export enum naviga_domain_select_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  default_paper_code = 'default_paper_code',
  /** column name */
  domain = 'domain',
  /** column name */
  media_group_code = 'media_group_code'
}

/** input type for updating data in table "naviga_domain" */
export type naviga_domain_set_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_paper_code?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "naviga_domain" */
export type naviga_domain_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: naviga_domain_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type naviga_domain_stream_cursor_value_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  default_paper_code?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "naviga_domain" */
export enum naviga_domain_update_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  default_paper_code = 'default_paper_code',
  /** column name */
  domain = 'domain',
  /** column name */
  media_group_code = 'media_group_code'
}

export type naviga_domain_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<naviga_domain_set_input>;
  /** filter the rows which have to be updated */
  where: naviga_domain_bool_exp;
};

/** columns and relationships of "naviga_paper_code" */
export type naviga_paper_code = {
  __typename: 'naviga_paper_code';
  client_code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  media_group_code: Scalars['String']['output'];
  paper_code: Scalars['String']['output'];
  /** An object relationship */
  platform_connection_naviga_paper_code_endpoint?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** An array relationship */
  platform_connections: Array<platform_connection>;
  /** An aggregate relationship */
  platform_connections_aggregate: platform_connection_aggregate;
};


/** columns and relationships of "naviga_paper_code" */
export type naviga_paper_codeplatform_connectionsArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


/** columns and relationships of "naviga_paper_code" */
export type naviga_paper_codeplatform_connections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};

/** aggregated selection of "naviga_paper_code" */
export type naviga_paper_code_aggregate = {
  __typename: 'naviga_paper_code_aggregate';
  aggregate?: Maybe<naviga_paper_code_aggregate_fields>;
  nodes: Array<naviga_paper_code>;
};

export type naviga_paper_code_aggregate_bool_exp = {
  count?: InputMaybe<naviga_paper_code_aggregate_bool_exp_count>;
};

export type naviga_paper_code_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<naviga_paper_code_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<naviga_paper_code_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "naviga_paper_code" */
export type naviga_paper_code_aggregate_fields = {
  __typename: 'naviga_paper_code_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<naviga_paper_code_max_fields>;
  min?: Maybe<naviga_paper_code_min_fields>;
};


/** aggregate fields of "naviga_paper_code" */
export type naviga_paper_code_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<naviga_paper_code_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "naviga_paper_code" */
export type naviga_paper_code_aggregate_order_by = {
  count?: InputMaybe<order_by>;
  max?: InputMaybe<naviga_paper_code_max_order_by>;
  min?: InputMaybe<naviga_paper_code_min_order_by>;
};

/** input type for inserting array relation for remote table "naviga_paper_code" */
export type naviga_paper_code_arr_rel_insert_input = {
  data: Array<naviga_paper_code_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<naviga_paper_code_on_conflict>;
};

/** Boolean expression to filter rows from the table "naviga_paper_code". All fields are combined with a logical 'AND'. */
export type naviga_paper_code_bool_exp = {
  _and?: InputMaybe<Array<naviga_paper_code_bool_exp>>;
  _not?: InputMaybe<naviga_paper_code_bool_exp>;
  _or?: InputMaybe<Array<naviga_paper_code_bool_exp>>;
  client_code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  media_group_code?: InputMaybe<String_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_connection_naviga_paper_code_endpoint?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
  platform_connections?: InputMaybe<platform_connection_bool_exp>;
  platform_connections_aggregate?: InputMaybe<platform_connection_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "naviga_paper_code" */
export enum naviga_paper_code_constraint {
  /** unique or primary key constraint on columns "paper_code", "media_group_code", "client_code" */
  naviga_paper_code_pkey = 'naviga_paper_code_pkey'
}

/** input type for inserting data into table "naviga_paper_code" */
export type naviga_paper_code_insert_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_naviga_paper_code_endpoint?: InputMaybe<platform_connection_naviga_paper_code_endpoint_obj_rel_insert_input>;
  platform_connections?: InputMaybe<platform_connection_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type naviga_paper_code_max_fields = {
  __typename: 'naviga_paper_code_max_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "naviga_paper_code" */
export type naviga_paper_code_max_order_by = {
  client_code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type naviga_paper_code_min_fields = {
  __typename: 'naviga_paper_code_min_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "naviga_paper_code" */
export type naviga_paper_code_min_order_by = {
  client_code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
};

/** response of any mutation on the table "naviga_paper_code" */
export type naviga_paper_code_mutation_response = {
  __typename: 'naviga_paper_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<naviga_paper_code>;
};

/** input type for inserting object relation for remote table "naviga_paper_code" */
export type naviga_paper_code_obj_rel_insert_input = {
  data: naviga_paper_code_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<naviga_paper_code_on_conflict>;
};

/** on_conflict condition type for table "naviga_paper_code" */
export type naviga_paper_code_on_conflict = {
  constraint: naviga_paper_code_constraint;
  update_columns?: Array<naviga_paper_code_update_column>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};

/** Ordering options when selecting data from "naviga_paper_code". */
export type naviga_paper_code_order_by = {
  client_code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_naviga_paper_code_endpoint?: InputMaybe<platform_connection_naviga_paper_code_endpoint_order_by>;
  platform_connections_aggregate?: InputMaybe<platform_connection_aggregate_order_by>;
};

/** primary key columns input for table: naviga_paper_code */
export type naviga_paper_code_pk_columns_input = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
};

/** select columns of table "naviga_paper_code" */
export enum naviga_paper_code_select_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  media_group_code = 'media_group_code',
  /** column name */
  paper_code = 'paper_code'
}

/** input type for updating data in table "naviga_paper_code" */
export type naviga_paper_code_set_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "naviga_paper_code" */
export type naviga_paper_code_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: naviga_paper_code_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type naviga_paper_code_stream_cursor_value_input = {
  client_code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "naviga_paper_code" */
export enum naviga_paper_code_update_column {
  /** column name */
  client_code = 'client_code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  media_group_code = 'media_group_code',
  /** column name */
  paper_code = 'paper_code'
}

export type naviga_paper_code_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<naviga_paper_code_set_input>;
  /** filter the rows which have to be updated */
  where: naviga_paper_code_bool_exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type numeric_comparison_exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "offer" */
export type offer = {
  __typename: 'offer';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  button_text_translation: translation;
  button_text_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  confirmation_cancel_button_text_translation?: Maybe<translation>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  confirmation_confirm_button_text_translation?: Maybe<translation>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  confirmation_content_translation?: Maybe<translation>;
  confirmation_content_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_enabled: Scalars['Boolean']['output'];
  /** An object relationship */
  content_translation: translation;
  content_translation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  goal: offer_goal_enum;
  /** An object relationship */
  headline_translation: translation;
  headline_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  metadata: Scalars['jsonb']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  /** An object relationship */
  offer_change_plan?: Maybe<offer_change_plan>;
  /** An object relationship */
  offer_coupon?: Maybe<offer_coupon>;
  /** An array relationship */
  offer_coupons: Array<offer_coupon>;
  /** An aggregate relationship */
  offer_coupons_aggregate: offer_coupon_aggregate;
  /** An object relationship */
  offer_custom?: Maybe<offer_custom>;
  /** An object relationship */
  offer_downgrade?: Maybe<offer_downgrade>;
  /** An array relationship */
  offer_group_offers: Array<offer_group_offer>;
  /** An aggregate relationship */
  offer_group_offers_aggregate: offer_group_offer_aggregate;
  /** An object relationship */
  offer_modify_subscription?: Maybe<offer_modify_subscription>;
  /** An object relationship */
  offer_pause_subscription?: Maybe<offer_pause_subscription>;
  /** An object relationship */
  offer_product_swap?: Maybe<offer_product_swap>;
  /** An object relationship */
  offer_reschedule_order?: Maybe<offer_reschedule_order>;
  /** An array relationship */
  offer_rule_rule_offers: Array<offer_rule_rule_offer>;
  /** An aggregate relationship */
  offer_rule_rule_offers_aggregate: offer_rule_rule_offer_aggregate;
  /** An array relationship */
  offer_rule_version_offers: Array<offer_rule_version_offer>;
  /** An aggregate relationship */
  offer_rule_version_offers_aggregate: offer_rule_version_offer_aggregate;
  /** An array relationship */
  offer_tags: Array<offer_tag>;
  /** An aggregate relationship */
  offer_tags_aggregate: offer_tag_aggregate;
  /** An array relationship */
  offer_test_offers: Array<offer_test_offer>;
  /** An aggregate relationship */
  offer_test_offers_aggregate: offer_test_offer_aggregate;
  /** An array relationship */
  offer_tests: Array<offer_test>;
  /** An aggregate relationship */
  offer_tests_aggregate: offer_test_aggregate;
  /** An object relationship */
  offer_timer?: Maybe<offer_timer>;
  /** An object relationship */
  offer_trial_extension?: Maybe<offer_trial_extension>;
  /** An object relationship */
  offer_type: offer_type;
  /** An object relationship */
  offer_upgrade?: Maybe<offer_upgrade>;
  override_active_offer: Scalars['Boolean']['output'];
  /** An array relationship */
  revenue_logs: Array<revenue_log>;
  /** An aggregate relationship */
  revenue_logs_aggregate: revenue_log_aggregate;
  show_banner: Scalars['Boolean']['output'];
  style: offer_style_enum;
  /** An array relationship */
  subscriber_campaign_offers: Array<subscriber_campaign_offer>;
  /** An aggregate relationship */
  subscriber_campaign_offers_aggregate: subscriber_campaign_offer_aggregate;
  /** An array relationship */
  subscriber_campaign_overrides: Array<subscriber_campaign_override>;
  /** An aggregate relationship */
  subscriber_campaign_overrides_aggregate: subscriber_campaign_override_aggregate;
  /** An array relationship */
  subscriber_campaigns: Array<subscriber_campaign>;
  /** An aggregate relationship */
  subscriber_campaigns_aggregate: subscriber_campaign_aggregate;
  /** An array relationship */
  subscriber_flow_offers: Array<subscriber_flow_offer>;
  /** An aggregate relationship */
  subscriber_flow_offers_aggregate: subscriber_flow_offer_aggregate;
  /** An array relationship */
  subscriber_flow_overrides: Array<subscriber_flow_override>;
  /** An aggregate relationship */
  subscriber_flow_overrides_aggregate: subscriber_flow_override_aggregate;
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
  /** An array relationship */
  subscriber_log_offers: Array<subscriber_log_offer>;
  /** An aggregate relationship */
  subscriber_log_offers_aggregate: subscriber_log_offer_aggregate;
  /** An array relationship */
  subscribers: Array<subscriber>;
  /** An aggregate relationship */
  subscribers_aggregate: subscriber_aggregate;
  tag_ids: Scalars['jsonb']['output'];
  token: Scalars['String']['output'];
  type: offer_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer" */
export type offermetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer" */
export type offeroffer_couponsArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_group_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_group_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_rule_rule_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_rule_rule_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_rule_version_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_rule_version_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_tagsArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_test_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_test_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_testsArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


/** columns and relationships of "offer" */
export type offeroffer_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


/** columns and relationships of "offer" */
export type offerrevenue_logsArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "offer" */
export type offerrevenue_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaign_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaign_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaign_overridesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaign_overrides_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaignsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_campaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flow_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flow_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flow_overridesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flow_overrides_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_log_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscriber_log_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscribersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


/** columns and relationships of "offer" */
export type offersubscribers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


/** columns and relationships of "offer" */
export type offertag_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "offer" */
export type offer_aggregate = {
  __typename: 'offer_aggregate';
  aggregate?: Maybe<offer_aggregate_fields>;
  nodes: Array<offer>;
};

export type offer_aggregate_bool_exp = {
  bool_and?: InputMaybe<offer_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<offer_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<offer_aggregate_bool_exp_count>;
};

export type offer_aggregate_bool_exp_bool_and = {
  arguments: offer_select_column_offer_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type offer_aggregate_bool_exp_bool_or = {
  arguments: offer_select_column_offer_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer" */
export type offer_aggregate_fields = {
  __typename: 'offer_aggregate_fields';
  avg?: Maybe<offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_max_fields>;
  min?: Maybe<offer_min_fields>;
  stddev?: Maybe<offer_stddev_fields>;
  stddev_pop?: Maybe<offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_stddev_samp_fields>;
  sum?: Maybe<offer_sum_fields>;
  var_pop?: Maybe<offer_var_pop_fields>;
  var_samp?: Maybe<offer_var_samp_fields>;
  variance?: Maybe<offer_variance_fields>;
};


/** aggregate fields of "offer" */
export type offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer" */
export type offer_aggregate_order_by = {
  avg?: InputMaybe<offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_max_order_by>;
  min?: InputMaybe<offer_min_order_by>;
  stddev?: InputMaybe<offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_sum_order_by>;
  var_pop?: InputMaybe<offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_var_samp_order_by>;
  variance?: InputMaybe<offer_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_append_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "offer" */
export type offer_arr_rel_insert_input = {
  data: Array<offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_on_conflict>;
};

/** columns and relationships of "offer_autopilot" */
export type offer_autopilot = {
  __typename: 'offer_autopilot';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  confidence_threshold: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  current_round?: Maybe<offer_autopilot_round>;
  current_round_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An array relationship */
  offer_autopilot_offer_autopilot_rounds: Array<offer_autopilot_offer_autopilot_round>;
  /** An aggregate relationship */
  offer_autopilot_offer_autopilot_rounds_aggregate: offer_autopilot_offer_autopilot_round_aggregate;
  /** An array relationship */
  offer_autopilot_offer_variants: Array<offer_autopilot_offer_variant>;
  /** An aggregate relationship */
  offer_autopilot_offer_variants_aggregate: offer_autopilot_offer_variant_aggregate;
  offer_autopilot_round_ids: Scalars['jsonb']['output'];
  offer_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_rule_rule_offer_autopilot_offers: Array<offer_rule_rule_offer_autopilot_offer>;
  /** An aggregate relationship */
  offer_rule_rule_offer_autopilot_offers_aggregate: offer_rule_rule_offer_autopilot_offer_aggregate;
  offer_variant_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  report_offer_autopilot_stats: Array<report_offer_autopilot_stats>;
  /** An aggregate relationship */
  report_offer_autopilot_stats_aggregate: report_offer_autopilot_stats_aggregate;
  strategy: offer_autopilot_strategy_enum;
  success_metric: offer_autopilot_success_metric_enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  winning_variant?: Maybe<offer_variant>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_autopilot_offer_autopilot_roundsArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_autopilot_offer_autopilot_rounds_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_autopilot_offer_variantsArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_autopilot_offer_variants_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_autopilot_round_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_rule_rule_offer_autopilot_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_rule_rule_offer_autopilot_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotoffer_variant_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotreport_offer_autopilot_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


/** columns and relationships of "offer_autopilot" */
export type offer_autopilotreport_offer_autopilot_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};

/** aggregated selection of "offer_autopilot" */
export type offer_autopilot_aggregate = {
  __typename: 'offer_autopilot_aggregate';
  aggregate?: Maybe<offer_autopilot_aggregate_fields>;
  nodes: Array<offer_autopilot>;
};

/** aggregate fields of "offer_autopilot" */
export type offer_autopilot_aggregate_fields = {
  __typename: 'offer_autopilot_aggregate_fields';
  avg?: Maybe<offer_autopilot_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_max_fields>;
  min?: Maybe<offer_autopilot_min_fields>;
  stddev?: Maybe<offer_autopilot_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_sum_fields>;
  var_pop?: Maybe<offer_autopilot_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_var_samp_fields>;
  variance?: Maybe<offer_autopilot_variance_fields>;
};


/** aggregate fields of "offer_autopilot" */
export type offer_autopilot_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_append_input = {
  offer_autopilot_round_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_autopilot_avg_fields = {
  __typename: 'offer_autopilot_avg_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_autopilot". All fields are combined with a logical 'AND'. */
export type offer_autopilot_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  confidence_threshold?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  current_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  current_round_id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_autopilot_offer_autopilot_rounds?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
  offer_autopilot_offer_autopilot_rounds_aggregate?: InputMaybe<offer_autopilot_offer_autopilot_round_aggregate_bool_exp>;
  offer_autopilot_offer_variants?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
  offer_autopilot_offer_variants_aggregate?: InputMaybe<offer_autopilot_offer_variant_aggregate_bool_exp>;
  offer_autopilot_round_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule_offer_autopilot_offers?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
  offer_rule_rule_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_rule_offer_autopilot_offer_aggregate_bool_exp>;
  offer_variant_ids?: InputMaybe<jsonb_comparison_exp>;
  report_offer_autopilot_stats?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
  report_offer_autopilot_stats_aggregate?: InputMaybe<report_offer_autopilot_stats_aggregate_bool_exp>;
  strategy?: InputMaybe<offer_autopilot_strategy_enum_comparison_exp>;
  success_metric?: InputMaybe<offer_autopilot_success_metric_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  winning_variant?: InputMaybe<offer_variant_bool_exp>;
  winning_variant_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot" */
export enum offer_autopilot_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_autopilot_pkey = 'offer_autopilot_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_autopilot_delete_at_path_input = {
  offer_autopilot_round_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_variant_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_autopilot_delete_elem_input = {
  offer_autopilot_round_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_autopilot_delete_key_input = {
  offer_autopilot_round_ids?: InputMaybe<Scalars['String']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_autopilot" */
export type offer_autopilot_inc_input = {
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  current_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_autopilot" */
export type offer_autopilot_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  current_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_autopilot_offer_autopilot_rounds?: InputMaybe<offer_autopilot_offer_autopilot_round_arr_rel_insert_input>;
  offer_autopilot_offer_variants?: InputMaybe<offer_autopilot_offer_variant_arr_rel_insert_input>;
  offer_autopilot_round_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_offer_autopilot_offers?: InputMaybe<offer_rule_rule_offer_autopilot_offer_arr_rel_insert_input>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  report_offer_autopilot_stats?: InputMaybe<report_offer_autopilot_stats_arr_rel_insert_input>;
  strategy?: InputMaybe<offer_autopilot_strategy_enum>;
  success_metric?: InputMaybe<offer_autopilot_success_metric_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_max_fields = {
  __typename: 'offer_autopilot_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  current_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_autopilot_min_fields = {
  __typename: 'offer_autopilot_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  current_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "offer_autopilot" */
export type offer_autopilot_mutation_response = {
  __typename: 'offer_autopilot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot>;
};

/** input type for inserting object relation for remote table "offer_autopilot" */
export type offer_autopilot_obj_rel_insert_input = {
  data: offer_autopilot_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_autopilot_on_conflict>;
};

/** columns and relationships of "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round = {
  __typename: 'offer_autopilot_offer_autopilot_round';
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  /** An object relationship */
  offer_autopilot_round?: Maybe<offer_autopilot_round>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_aggregate = {
  __typename: 'offer_autopilot_offer_autopilot_round_aggregate';
  aggregate?: Maybe<offer_autopilot_offer_autopilot_round_aggregate_fields>;
  nodes: Array<offer_autopilot_offer_autopilot_round>;
};

export type offer_autopilot_offer_autopilot_round_aggregate_bool_exp = {
  count?: InputMaybe<offer_autopilot_offer_autopilot_round_aggregate_bool_exp_count>;
};

export type offer_autopilot_offer_autopilot_round_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_aggregate_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_aggregate_fields';
  avg?: Maybe<offer_autopilot_offer_autopilot_round_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_offer_autopilot_round_max_fields>;
  min?: Maybe<offer_autopilot_offer_autopilot_round_min_fields>;
  stddev?: Maybe<offer_autopilot_offer_autopilot_round_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_offer_autopilot_round_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_offer_autopilot_round_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_offer_autopilot_round_sum_fields>;
  var_pop?: Maybe<offer_autopilot_offer_autopilot_round_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_offer_autopilot_round_var_samp_fields>;
  variance?: Maybe<offer_autopilot_offer_autopilot_round_variance_fields>;
};


/** aggregate fields of "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_aggregate_order_by = {
  avg?: InputMaybe<offer_autopilot_offer_autopilot_round_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_autopilot_offer_autopilot_round_max_order_by>;
  min?: InputMaybe<offer_autopilot_offer_autopilot_round_min_order_by>;
  stddev?: InputMaybe<offer_autopilot_offer_autopilot_round_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_autopilot_offer_autopilot_round_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_autopilot_offer_autopilot_round_stddev_samp_order_by>;
  sum?: InputMaybe<offer_autopilot_offer_autopilot_round_sum_order_by>;
  var_pop?: InputMaybe<offer_autopilot_offer_autopilot_round_var_pop_order_by>;
  var_samp?: InputMaybe<offer_autopilot_offer_autopilot_round_var_samp_order_by>;
  variance?: InputMaybe<offer_autopilot_offer_autopilot_round_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_arr_rel_insert_input = {
  data: Array<offer_autopilot_offer_autopilot_round_insert_input>;
};

/** aggregate avg on columns */
export type offer_autopilot_offer_autopilot_round_avg_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_avg_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_avg_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_offer_autopilot_round". All fields are combined with a logical 'AND'. */
export type offer_autopilot_offer_autopilot_round_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_bool_exp>>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  round_index?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_insert_input = {
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  round_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_offer_autopilot_round_max_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_max_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_max_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_autopilot_offer_autopilot_round_min_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_min_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_min_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_autopilot_offer_autopilot_round". */
export type offer_autopilot_offer_autopilot_round_order_by = {
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** select columns of table "offer_autopilot_offer_autopilot_round" */
export enum offer_autopilot_offer_autopilot_round_select_column {
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  round_index = 'round_index'
}

/** aggregate stddev on columns */
export type offer_autopilot_offer_autopilot_round_stddev_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_stddev_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_stddev_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_offer_autopilot_round_stddev_pop_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_stddev_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_stddev_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_offer_autopilot_round_stddev_samp_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_stddev_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_stddev_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_offer_autopilot_round_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_offer_autopilot_round_stream_cursor_value_input = {
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  round_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_autopilot_offer_autopilot_round_sum_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_sum_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_sum_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_autopilot_offer_autopilot_round_var_pop_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_var_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_var_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_offer_autopilot_round_var_samp_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_var_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_var_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_autopilot_offer_autopilot_round_variance_fields = {
  __typename: 'offer_autopilot_offer_autopilot_round_variance_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_autopilot_offer_autopilot_round" */
export type offer_autopilot_offer_autopilot_round_variance_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant = {
  __typename: 'offer_autopilot_offer_variant';
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_variant?: Maybe<offer_variant>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_aggregate = {
  __typename: 'offer_autopilot_offer_variant_aggregate';
  aggregate?: Maybe<offer_autopilot_offer_variant_aggregate_fields>;
  nodes: Array<offer_autopilot_offer_variant>;
};

export type offer_autopilot_offer_variant_aggregate_bool_exp = {
  count?: InputMaybe<offer_autopilot_offer_variant_aggregate_bool_exp_count>;
};

export type offer_autopilot_offer_variant_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_aggregate_fields = {
  __typename: 'offer_autopilot_offer_variant_aggregate_fields';
  avg?: Maybe<offer_autopilot_offer_variant_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_offer_variant_max_fields>;
  min?: Maybe<offer_autopilot_offer_variant_min_fields>;
  stddev?: Maybe<offer_autopilot_offer_variant_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_offer_variant_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_offer_variant_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_offer_variant_sum_fields>;
  var_pop?: Maybe<offer_autopilot_offer_variant_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_offer_variant_var_samp_fields>;
  variance?: Maybe<offer_autopilot_offer_variant_variance_fields>;
};


/** aggregate fields of "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_aggregate_order_by = {
  avg?: InputMaybe<offer_autopilot_offer_variant_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_autopilot_offer_variant_max_order_by>;
  min?: InputMaybe<offer_autopilot_offer_variant_min_order_by>;
  stddev?: InputMaybe<offer_autopilot_offer_variant_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_autopilot_offer_variant_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_autopilot_offer_variant_stddev_samp_order_by>;
  sum?: InputMaybe<offer_autopilot_offer_variant_sum_order_by>;
  var_pop?: InputMaybe<offer_autopilot_offer_variant_var_pop_order_by>;
  var_samp?: InputMaybe<offer_autopilot_offer_variant_var_samp_order_by>;
  variance?: InputMaybe<offer_autopilot_offer_variant_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_arr_rel_insert_input = {
  data: Array<offer_autopilot_offer_variant_insert_input>;
};

/** aggregate avg on columns */
export type offer_autopilot_offer_variant_avg_fields = {
  __typename: 'offer_autopilot_offer_variant_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_offer_variant". All fields are combined with a logical 'AND'. */
export type offer_autopilot_offer_variant_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_offer_variant_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_offer_variant_bool_exp>>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_variant?: InputMaybe<offer_variant_bool_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  variant_index?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_insert_input = {
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  variant_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_offer_variant_max_fields = {
  __typename: 'offer_autopilot_offer_variant_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_autopilot_offer_variant_min_fields = {
  __typename: 'offer_autopilot_offer_variant_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** input type for inserting object relation for remote table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_obj_rel_insert_input = {
  data: offer_autopilot_offer_variant_insert_input;
};

/** Ordering options when selecting data from "offer_autopilot_offer_variant". */
export type offer_autopilot_offer_variant_order_by = {
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant?: InputMaybe<offer_variant_order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** select columns of table "offer_autopilot_offer_variant" */
export enum offer_autopilot_offer_variant_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  variant_index = 'variant_index'
}

/** aggregate stddev on columns */
export type offer_autopilot_offer_variant_stddev_fields = {
  __typename: 'offer_autopilot_offer_variant_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_offer_variant_stddev_pop_fields = {
  __typename: 'offer_autopilot_offer_variant_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_offer_variant_stddev_samp_fields = {
  __typename: 'offer_autopilot_offer_variant_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_offer_variant_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_offer_variant_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  variant_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_autopilot_offer_variant_sum_fields = {
  __typename: 'offer_autopilot_offer_variant_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_autopilot_offer_variant_var_pop_fields = {
  __typename: 'offer_autopilot_offer_variant_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_offer_variant_var_samp_fields = {
  __typename: 'offer_autopilot_offer_variant_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_autopilot_offer_variant_variance_fields = {
  __typename: 'offer_autopilot_offer_variant_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_autopilot_offer_variant" */
export type offer_autopilot_offer_variant_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_autopilot" */
export type offer_autopilot_on_conflict = {
  constraint: offer_autopilot_constraint;
  update_columns?: Array<offer_autopilot_update_column>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot". */
export type offer_autopilot_order_by = {
  completed_at?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  current_round?: InputMaybe<offer_autopilot_round_order_by>;
  current_round_id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_autopilot_offer_autopilot_rounds_aggregate?: InputMaybe<offer_autopilot_offer_autopilot_round_aggregate_order_by>;
  offer_autopilot_offer_variants_aggregate?: InputMaybe<offer_autopilot_offer_variant_aggregate_order_by>;
  offer_autopilot_round_ids?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_rule_offer_autopilot_offer_aggregate_order_by>;
  offer_variant_ids?: InputMaybe<order_by>;
  report_offer_autopilot_stats_aggregate?: InputMaybe<report_offer_autopilot_stats_aggregate_order_by>;
  strategy?: InputMaybe<order_by>;
  success_metric?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_variant?: InputMaybe<offer_variant_order_by>;
  winning_variant_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot */
export type offer_autopilot_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_prepend_input = {
  offer_autopilot_round_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_round = {
  __typename: 'offer_autopilot_round';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  control_variant?: Maybe<offer_variant>;
  control_variant_id: Scalars['Int']['output'];
  control_weight: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  notification_sent: Scalars['Boolean']['output'];
  /** An object relationship */
  offer: offer;
  /** An array relationship */
  offer_autopilot_round_logs: Array<offer_autopilot_round_log>;
  /** An aggregate relationship */
  offer_autopilot_round_logs_aggregate: offer_autopilot_round_log_aggregate;
  /** An array relationship */
  offer_autopilot_round_offer_variants: Array<offer_autopilot_round_offer_variant>;
  /** An aggregate relationship */
  offer_autopilot_round_offer_variants_aggregate: offer_autopilot_round_offer_variant_aggregate;
  offer_id: Scalars['Int']['output'];
  offer_variant_ids?: Maybe<Scalars['jsonb']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  strategy: offer_autopilot_round_strategy_enum;
  /** An array relationship */
  subscriber_flow_offer_group_offers: Array<subscriber_flow_offer_group_offer>;
  /** An aggregate relationship */
  subscriber_flow_offer_group_offers_aggregate: subscriber_flow_offer_group_offer_aggregate;
  /** An array relationship */
  subscriber_flow_offers: Array<subscriber_flow_offer>;
  /** An aggregate relationship */
  subscriber_flow_offers_aggregate: subscriber_flow_offer_aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  winning_variant?: Maybe<offer_variant>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundoffer_autopilot_round_logsArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundoffer_autopilot_round_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundoffer_autopilot_round_offer_variantsArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundoffer_autopilot_round_offer_variants_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundoffer_variant_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundsubscriber_flow_offer_group_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundsubscriber_flow_offer_group_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundsubscriber_flow_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "offer_autopilot_round" */
export type offer_autopilot_roundsubscriber_flow_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};

/** aggregated selection of "offer_autopilot_round" */
export type offer_autopilot_round_aggregate = {
  __typename: 'offer_autopilot_round_aggregate';
  aggregate?: Maybe<offer_autopilot_round_aggregate_fields>;
  nodes: Array<offer_autopilot_round>;
};

/** aggregate fields of "offer_autopilot_round" */
export type offer_autopilot_round_aggregate_fields = {
  __typename: 'offer_autopilot_round_aggregate_fields';
  avg?: Maybe<offer_autopilot_round_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_round_max_fields>;
  min?: Maybe<offer_autopilot_round_min_fields>;
  stddev?: Maybe<offer_autopilot_round_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_round_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_round_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_round_sum_fields>;
  var_pop?: Maybe<offer_autopilot_round_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_round_var_samp_fields>;
  variance?: Maybe<offer_autopilot_round_variance_fields>;
};


/** aggregate fields of "offer_autopilot_round" */
export type offer_autopilot_round_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_round_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_round_append_input = {
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_autopilot_round_avg_fields = {
  __typename: 'offer_autopilot_round_avg_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_round". All fields are combined with a logical 'AND'. */
export type offer_autopilot_round_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_round_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_round_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_round_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  control_variant?: InputMaybe<offer_variant_bool_exp>;
  control_variant_id?: InputMaybe<Int_comparison_exp>;
  control_weight?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  notification_sent?: InputMaybe<Boolean_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_autopilot_round_logs?: InputMaybe<offer_autopilot_round_log_bool_exp>;
  offer_autopilot_round_logs_aggregate?: InputMaybe<offer_autopilot_round_log_aggregate_bool_exp>;
  offer_autopilot_round_offer_variants?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
  offer_autopilot_round_offer_variants_aggregate?: InputMaybe<offer_autopilot_round_offer_variant_aggregate_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_variant_ids?: InputMaybe<jsonb_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  strategy?: InputMaybe<offer_autopilot_round_strategy_enum_comparison_exp>;
  subscriber_flow_offer_group_offers?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
  subscriber_flow_offer_group_offers_aggregate?: InputMaybe<subscriber_flow_offer_group_offer_aggregate_bool_exp>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_bool_exp>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  winning_variant?: InputMaybe<offer_variant_bool_exp>;
  winning_variant_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot_round" */
export enum offer_autopilot_round_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_autopilot_round_pkey = 'offer_autopilot_round_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_autopilot_round_delete_at_path_input = {
  offer_variant_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_autopilot_round_delete_elem_input = {
  offer_variant_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_autopilot_round_delete_key_input = {
  offer_variant_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_autopilot_round" */
export type offer_autopilot_round_inc_input = {
  control_variant_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_autopilot_round" */
export type offer_autopilot_round_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  control_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  control_variant_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_autopilot_round_logs?: InputMaybe<offer_autopilot_round_log_arr_rel_insert_input>;
  offer_autopilot_round_offer_variants?: InputMaybe<offer_autopilot_round_offer_variant_arr_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  strategy?: InputMaybe<offer_autopilot_round_strategy_enum>;
  subscriber_flow_offer_group_offers?: InputMaybe<subscriber_flow_offer_group_offer_arr_rel_insert_input>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "offer_autopilot_round_log" */
export type offer_autopilot_round_log = {
  __typename: 'offer_autopilot_round_log';
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  /** An object relationship */
  offer_autopilot_round: offer_autopilot_round;
  offer_autopilot_round_id: Scalars['Int']['output'];
  subscriber_flow_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_autopilot_round_log" */
export type offer_autopilot_round_logdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "offer_autopilot_round_log" */
export type offer_autopilot_round_log_aggregate = {
  __typename: 'offer_autopilot_round_log_aggregate';
  aggregate?: Maybe<offer_autopilot_round_log_aggregate_fields>;
  nodes: Array<offer_autopilot_round_log>;
};

export type offer_autopilot_round_log_aggregate_bool_exp = {
  count?: InputMaybe<offer_autopilot_round_log_aggregate_bool_exp_count>;
};

export type offer_autopilot_round_log_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_autopilot_round_log_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_autopilot_round_log" */
export type offer_autopilot_round_log_aggregate_fields = {
  __typename: 'offer_autopilot_round_log_aggregate_fields';
  avg?: Maybe<offer_autopilot_round_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_round_log_max_fields>;
  min?: Maybe<offer_autopilot_round_log_min_fields>;
  stddev?: Maybe<offer_autopilot_round_log_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_round_log_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_round_log_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_round_log_sum_fields>;
  var_pop?: Maybe<offer_autopilot_round_log_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_round_log_var_samp_fields>;
  variance?: Maybe<offer_autopilot_round_log_variance_fields>;
};


/** aggregate fields of "offer_autopilot_round_log" */
export type offer_autopilot_round_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_aggregate_order_by = {
  avg?: InputMaybe<offer_autopilot_round_log_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_autopilot_round_log_max_order_by>;
  min?: InputMaybe<offer_autopilot_round_log_min_order_by>;
  stddev?: InputMaybe<offer_autopilot_round_log_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_autopilot_round_log_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_autopilot_round_log_stddev_samp_order_by>;
  sum?: InputMaybe<offer_autopilot_round_log_sum_order_by>;
  var_pop?: InputMaybe<offer_autopilot_round_log_var_pop_order_by>;
  var_samp?: InputMaybe<offer_autopilot_round_log_var_samp_order_by>;
  variance?: InputMaybe<offer_autopilot_round_log_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_round_log_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_arr_rel_insert_input = {
  data: Array<offer_autopilot_round_log_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_autopilot_round_log_on_conflict>;
};

/** aggregate avg on columns */
export type offer_autopilot_round_log_avg_fields = {
  __typename: 'offer_autopilot_round_log_avg_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_avg_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_round_log". All fields are combined with a logical 'AND'. */
export type offer_autopilot_round_log_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_round_log_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_round_log_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_round_log_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot_round_log" */
export enum offer_autopilot_round_log_constraint {
  /** unique or primary key constraint on columns "offer_autopilot_round_id", "subscriber_flow_id" */
  offer_autopilot_round_log_pkey = 'offer_autopilot_round_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_autopilot_round_log_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_autopilot_round_log_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_autopilot_round_log_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_inc_input = {
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_round_log_max_fields = {
  __typename: 'offer_autopilot_round_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_autopilot_round_log_min_fields = {
  __typename: 'offer_autopilot_round_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_mutation_response = {
  __typename: 'offer_autopilot_round_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot_round_log>;
};

/** on_conflict condition type for table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_on_conflict = {
  constraint: offer_autopilot_round_log_constraint;
  update_columns?: Array<offer_autopilot_round_log_update_column>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot_round_log". */
export type offer_autopilot_round_log_order_by = {
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot_round_log */
export type offer_autopilot_round_log_pk_columns_input = {
  offer_autopilot_round_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_round_log_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_autopilot_round_log" */
export enum offer_autopilot_round_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_autopilot_round_log_stddev_fields = {
  __typename: 'offer_autopilot_round_log_stddev_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_stddev_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_round_log_stddev_pop_fields = {
  __typename: 'offer_autopilot_round_log_stddev_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_stddev_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_round_log_stddev_samp_fields = {
  __typename: 'offer_autopilot_round_log_stddev_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_stddev_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_round_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_round_log_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_autopilot_round_log_sum_fields = {
  __typename: 'offer_autopilot_round_log_sum_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_sum_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_autopilot_round_log" */
export enum offer_autopilot_round_log_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_autopilot_round_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_autopilot_round_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_autopilot_round_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_autopilot_round_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_autopilot_round_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_autopilot_round_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_autopilot_round_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_round_log_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_round_log_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_autopilot_round_log_var_pop_fields = {
  __typename: 'offer_autopilot_round_log_var_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_var_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_round_log_var_samp_fields = {
  __typename: 'offer_autopilot_round_log_var_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_var_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_autopilot_round_log_variance_fields = {
  __typename: 'offer_autopilot_round_log_variance_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_autopilot_round_log" */
export type offer_autopilot_round_log_variance_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type offer_autopilot_round_max_fields = {
  __typename: 'offer_autopilot_round_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  control_variant_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_autopilot_round_min_fields = {
  __typename: 'offer_autopilot_round_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  control_variant_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "offer_autopilot_round" */
export type offer_autopilot_round_mutation_response = {
  __typename: 'offer_autopilot_round_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot_round>;
};

/** input type for inserting object relation for remote table "offer_autopilot_round" */
export type offer_autopilot_round_obj_rel_insert_input = {
  data: offer_autopilot_round_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_autopilot_round_on_conflict>;
};

/** columns and relationships of "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant = {
  __typename: 'offer_autopilot_round_offer_variant';
  /** An object relationship */
  offer_autopilot_round?: Maybe<offer_autopilot_round>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_variant?: Maybe<offer_variant>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  report_offer_autopilot_stats?: Maybe<report_offer_autopilot_stats>;
  round_index?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_aggregate = {
  __typename: 'offer_autopilot_round_offer_variant_aggregate';
  aggregate?: Maybe<offer_autopilot_round_offer_variant_aggregate_fields>;
  nodes: Array<offer_autopilot_round_offer_variant>;
};

export type offer_autopilot_round_offer_variant_aggregate_bool_exp = {
  count?: InputMaybe<offer_autopilot_round_offer_variant_aggregate_bool_exp_count>;
};

export type offer_autopilot_round_offer_variant_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_aggregate_fields = {
  __typename: 'offer_autopilot_round_offer_variant_aggregate_fields';
  avg?: Maybe<offer_autopilot_round_offer_variant_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_round_offer_variant_max_fields>;
  min?: Maybe<offer_autopilot_round_offer_variant_min_fields>;
  stddev?: Maybe<offer_autopilot_round_offer_variant_stddev_fields>;
  stddev_pop?: Maybe<offer_autopilot_round_offer_variant_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_autopilot_round_offer_variant_stddev_samp_fields>;
  sum?: Maybe<offer_autopilot_round_offer_variant_sum_fields>;
  var_pop?: Maybe<offer_autopilot_round_offer_variant_var_pop_fields>;
  var_samp?: Maybe<offer_autopilot_round_offer_variant_var_samp_fields>;
  variance?: Maybe<offer_autopilot_round_offer_variant_variance_fields>;
};


/** aggregate fields of "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_aggregate_order_by = {
  avg?: InputMaybe<offer_autopilot_round_offer_variant_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_autopilot_round_offer_variant_max_order_by>;
  min?: InputMaybe<offer_autopilot_round_offer_variant_min_order_by>;
  stddev?: InputMaybe<offer_autopilot_round_offer_variant_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_autopilot_round_offer_variant_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_autopilot_round_offer_variant_stddev_samp_order_by>;
  sum?: InputMaybe<offer_autopilot_round_offer_variant_sum_order_by>;
  var_pop?: InputMaybe<offer_autopilot_round_offer_variant_var_pop_order_by>;
  var_samp?: InputMaybe<offer_autopilot_round_offer_variant_var_samp_order_by>;
  variance?: InputMaybe<offer_autopilot_round_offer_variant_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_arr_rel_insert_input = {
  data: Array<offer_autopilot_round_offer_variant_insert_input>;
};

/** aggregate avg on columns */
export type offer_autopilot_round_offer_variant_avg_fields = {
  __typename: 'offer_autopilot_round_offer_variant_avg_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_avg_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_round_offer_variant". All fields are combined with a logical 'AND'. */
export type offer_autopilot_round_offer_variant_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_round_offer_variant_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_round_offer_variant_bool_exp>>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  offer_variant?: InputMaybe<offer_variant_bool_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  report_offer_autopilot_stats?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
  round_index?: InputMaybe<Int_comparison_exp>;
  variant_index?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_insert_input = {
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  report_offer_autopilot_stats?: InputMaybe<report_offer_autopilot_stats_obj_rel_insert_input>;
  round_index?: InputMaybe<Scalars['Int']['input']>;
  variant_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_round_offer_variant_max_fields = {
  __typename: 'offer_autopilot_round_offer_variant_max_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_max_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_autopilot_round_offer_variant_min_fields = {
  __typename: 'offer_autopilot_round_offer_variant_min_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_min_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_autopilot_round_offer_variant". */
export type offer_autopilot_round_offer_variant_order_by = {
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant?: InputMaybe<offer_variant_order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  report_offer_autopilot_stats?: InputMaybe<report_offer_autopilot_stats_order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** select columns of table "offer_autopilot_round_offer_variant" */
export enum offer_autopilot_round_offer_variant_select_column {
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  round_index = 'round_index',
  /** column name */
  variant_index = 'variant_index'
}

/** aggregate stddev on columns */
export type offer_autopilot_round_offer_variant_stddev_fields = {
  __typename: 'offer_autopilot_round_offer_variant_stddev_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_stddev_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_round_offer_variant_stddev_pop_fields = {
  __typename: 'offer_autopilot_round_offer_variant_stddev_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_stddev_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_round_offer_variant_stddev_samp_fields = {
  __typename: 'offer_autopilot_round_offer_variant_stddev_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_stddev_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_round_offer_variant_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_round_offer_variant_stream_cursor_value_input = {
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  round_index?: InputMaybe<Scalars['Int']['input']>;
  variant_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_autopilot_round_offer_variant_sum_fields = {
  __typename: 'offer_autopilot_round_offer_variant_sum_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  round_index?: Maybe<Scalars['Int']['output']>;
  variant_index?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_sum_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_autopilot_round_offer_variant_var_pop_fields = {
  __typename: 'offer_autopilot_round_offer_variant_var_pop_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_var_pop_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_round_offer_variant_var_samp_fields = {
  __typename: 'offer_autopilot_round_offer_variant_var_samp_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_var_samp_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_autopilot_round_offer_variant_variance_fields = {
  __typename: 'offer_autopilot_round_offer_variant_variance_fields';
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  round_index?: Maybe<Scalars['Float']['output']>;
  variant_index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_autopilot_round_offer_variant" */
export type offer_autopilot_round_offer_variant_variance_order_by = {
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  round_index?: InputMaybe<order_by>;
  variant_index?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_autopilot_round" */
export type offer_autopilot_round_on_conflict = {
  constraint: offer_autopilot_round_constraint;
  update_columns?: Array<offer_autopilot_round_update_column>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot_round". */
export type offer_autopilot_round_order_by = {
  completed_at?: InputMaybe<order_by>;
  control_variant?: InputMaybe<offer_variant_order_by>;
  control_variant_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  notification_sent?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_autopilot_round_logs_aggregate?: InputMaybe<offer_autopilot_round_log_aggregate_order_by>;
  offer_autopilot_round_offer_variants_aggregate?: InputMaybe<offer_autopilot_round_offer_variant_aggregate_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_ids?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  strategy?: InputMaybe<order_by>;
  subscriber_flow_offer_group_offers_aggregate?: InputMaybe<subscriber_flow_offer_group_offer_aggregate_order_by>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_variant?: InputMaybe<offer_variant_order_by>;
  winning_variant_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot_round */
export type offer_autopilot_round_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_autopilot_round_prepend_input = {
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_autopilot_round" */
export enum offer_autopilot_round_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  control_variant_id = 'control_variant_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  notification_sent = 'notification_sent',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_ids = 'offer_variant_ids',
  /** column name */
  started_at = 'started_at',
  /** column name */
  strategy = 'strategy',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_variant_id = 'winning_variant_id'
}

/** input type for updating data in table "offer_autopilot_round" */
export type offer_autopilot_round_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  control_variant_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  strategy?: InputMaybe<offer_autopilot_round_strategy_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type offer_autopilot_round_stddev_fields = {
  __typename: 'offer_autopilot_round_stddev_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_round_stddev_pop_fields = {
  __typename: 'offer_autopilot_round_stddev_pop_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_round_stddev_samp_fields = {
  __typename: 'offer_autopilot_round_stddev_samp_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy = {
  __typename: 'offer_autopilot_round_strategy';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_aggregate = {
  __typename: 'offer_autopilot_round_strategy_aggregate';
  aggregate?: Maybe<offer_autopilot_round_strategy_aggregate_fields>;
  nodes: Array<offer_autopilot_round_strategy>;
};

/** aggregate fields of "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_aggregate_fields = {
  __typename: 'offer_autopilot_round_strategy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_round_strategy_max_fields>;
  min?: Maybe<offer_autopilot_round_strategy_min_fields>;
};


/** aggregate fields of "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_round_strategy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_round_strategy". All fields are combined with a logical 'AND'. */
export type offer_autopilot_round_strategy_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_round_strategy_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_round_strategy_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot_round_strategy" */
export enum offer_autopilot_round_strategy_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_autopilot_round_strategy_pkey = 'offer_autopilot_round_strategy_pkey'
}

export enum offer_autopilot_round_strategy_enum {
  copy = 'copy',
  discount = 'discount'
}

/** Boolean expression to compare columns of type "offer_autopilot_round_strategy_enum". All fields are combined with logical 'AND'. */
export type offer_autopilot_round_strategy_enum_comparison_exp = {
  _eq?: InputMaybe<offer_autopilot_round_strategy_enum>;
  _in?: InputMaybe<Array<offer_autopilot_round_strategy_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_autopilot_round_strategy_enum>;
  _nin?: InputMaybe<Array<offer_autopilot_round_strategy_enum>>;
};

/** input type for inserting data into table "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_round_strategy_max_fields = {
  __typename: 'offer_autopilot_round_strategy_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_autopilot_round_strategy_min_fields = {
  __typename: 'offer_autopilot_round_strategy_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_mutation_response = {
  __typename: 'offer_autopilot_round_strategy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot_round_strategy>;
};

/** on_conflict condition type for table "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_on_conflict = {
  constraint: offer_autopilot_round_strategy_constraint;
  update_columns?: Array<offer_autopilot_round_strategy_update_column>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot_round_strategy". */
export type offer_autopilot_round_strategy_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot_round_strategy */
export type offer_autopilot_round_strategy_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_autopilot_round_strategy" */
export enum offer_autopilot_round_strategy_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_autopilot_round_strategy" */
export type offer_autopilot_round_strategy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_round_strategy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_round_strategy_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_autopilot_round_strategy" */
export enum offer_autopilot_round_strategy_update_column {
  /** column name */
  value = 'value'
}

export type offer_autopilot_round_strategy_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_round_strategy_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_round_strategy_bool_exp;
};

/** Streaming cursor of the table "offer_autopilot_round" */
export type offer_autopilot_round_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_round_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_round_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  control_variant_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  strategy?: InputMaybe<offer_autopilot_round_strategy_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_autopilot_round_sum_fields = {
  __typename: 'offer_autopilot_round_sum_fields';
  control_variant_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_autopilot_round" */
export enum offer_autopilot_round_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  control_variant_id = 'control_variant_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  notification_sent = 'notification_sent',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_ids = 'offer_variant_ids',
  /** column name */
  started_at = 'started_at',
  /** column name */
  strategy = 'strategy',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_variant_id = 'winning_variant_id'
}

export type offer_autopilot_round_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_autopilot_round_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_autopilot_round_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_autopilot_round_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_autopilot_round_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_autopilot_round_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_autopilot_round_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_round_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_round_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_autopilot_round_var_pop_fields = {
  __typename: 'offer_autopilot_round_var_pop_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_round_var_samp_fields = {
  __typename: 'offer_autopilot_round_var_samp_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_autopilot_round_variance_fields = {
  __typename: 'offer_autopilot_round_variance_fields';
  control_variant_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "offer_autopilot" */
export enum offer_autopilot_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  created_at = 'created_at',
  /** column name */
  current_round_id = 'current_round_id',
  /** column name */
  offer_autopilot_round_ids = 'offer_autopilot_round_ids',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_ids = 'offer_variant_ids',
  /** column name */
  strategy = 'strategy',
  /** column name */
  success_metric = 'success_metric',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_variant_id = 'winning_variant_id'
}

/** input type for updating data in table "offer_autopilot" */
export type offer_autopilot_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  strategy?: InputMaybe<offer_autopilot_strategy_enum>;
  success_metric?: InputMaybe<offer_autopilot_success_metric_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type offer_autopilot_stddev_fields = {
  __typename: 'offer_autopilot_stddev_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_autopilot_stddev_pop_fields = {
  __typename: 'offer_autopilot_stddev_pop_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_autopilot_stddev_samp_fields = {
  __typename: 'offer_autopilot_stddev_samp_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_autopilot_strategy" */
export type offer_autopilot_strategy = {
  __typename: 'offer_autopilot_strategy';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_autopilot_strategy" */
export type offer_autopilot_strategy_aggregate = {
  __typename: 'offer_autopilot_strategy_aggregate';
  aggregate?: Maybe<offer_autopilot_strategy_aggregate_fields>;
  nodes: Array<offer_autopilot_strategy>;
};

/** aggregate fields of "offer_autopilot_strategy" */
export type offer_autopilot_strategy_aggregate_fields = {
  __typename: 'offer_autopilot_strategy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_strategy_max_fields>;
  min?: Maybe<offer_autopilot_strategy_min_fields>;
};


/** aggregate fields of "offer_autopilot_strategy" */
export type offer_autopilot_strategy_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_strategy_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_strategy". All fields are combined with a logical 'AND'. */
export type offer_autopilot_strategy_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_strategy_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_strategy_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_strategy_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot_strategy" */
export enum offer_autopilot_strategy_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_autopilot_strategy_pkey = 'offer_autopilot_strategy_pkey'
}

export enum offer_autopilot_strategy_enum {
  copy = 'copy',
  discount = 'discount',
  discount_copy = 'discount_copy'
}

/** Boolean expression to compare columns of type "offer_autopilot_strategy_enum". All fields are combined with logical 'AND'. */
export type offer_autopilot_strategy_enum_comparison_exp = {
  _eq?: InputMaybe<offer_autopilot_strategy_enum>;
  _in?: InputMaybe<Array<offer_autopilot_strategy_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_autopilot_strategy_enum>;
  _nin?: InputMaybe<Array<offer_autopilot_strategy_enum>>;
};

/** input type for inserting data into table "offer_autopilot_strategy" */
export type offer_autopilot_strategy_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_strategy_max_fields = {
  __typename: 'offer_autopilot_strategy_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_autopilot_strategy_min_fields = {
  __typename: 'offer_autopilot_strategy_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_autopilot_strategy" */
export type offer_autopilot_strategy_mutation_response = {
  __typename: 'offer_autopilot_strategy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot_strategy>;
};

/** on_conflict condition type for table "offer_autopilot_strategy" */
export type offer_autopilot_strategy_on_conflict = {
  constraint: offer_autopilot_strategy_constraint;
  update_columns?: Array<offer_autopilot_strategy_update_column>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot_strategy". */
export type offer_autopilot_strategy_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot_strategy */
export type offer_autopilot_strategy_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_autopilot_strategy" */
export enum offer_autopilot_strategy_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_autopilot_strategy" */
export type offer_autopilot_strategy_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_autopilot_strategy" */
export type offer_autopilot_strategy_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_strategy_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_strategy_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_autopilot_strategy" */
export enum offer_autopilot_strategy_update_column {
  /** column name */
  value = 'value'
}

export type offer_autopilot_strategy_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_strategy_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_strategy_bool_exp;
};

/** Streaming cursor of the table "offer_autopilot" */
export type offer_autopilot_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_ids?: InputMaybe<Scalars['jsonb']['input']>;
  strategy?: InputMaybe<offer_autopilot_strategy_enum>;
  success_metric?: InputMaybe<offer_autopilot_success_metric_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric = {
  __typename: 'offer_autopilot_success_metric';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_aggregate = {
  __typename: 'offer_autopilot_success_metric_aggregate';
  aggregate?: Maybe<offer_autopilot_success_metric_aggregate_fields>;
  nodes: Array<offer_autopilot_success_metric>;
};

/** aggregate fields of "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_aggregate_fields = {
  __typename: 'offer_autopilot_success_metric_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_autopilot_success_metric_max_fields>;
  min?: Maybe<offer_autopilot_success_metric_min_fields>;
};


/** aggregate fields of "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_autopilot_success_metric_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_autopilot_success_metric". All fields are combined with a logical 'AND'. */
export type offer_autopilot_success_metric_bool_exp = {
  _and?: InputMaybe<Array<offer_autopilot_success_metric_bool_exp>>;
  _not?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
  _or?: InputMaybe<Array<offer_autopilot_success_metric_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_autopilot_success_metric" */
export enum offer_autopilot_success_metric_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_autopilot_success_metric_pkey = 'offer_autopilot_success_metric_pkey'
}

export enum offer_autopilot_success_metric_enum {
  acceptance_rate = 'acceptance_rate',
  revenue_potential = 'revenue_potential'
}

/** Boolean expression to compare columns of type "offer_autopilot_success_metric_enum". All fields are combined with logical 'AND'. */
export type offer_autopilot_success_metric_enum_comparison_exp = {
  _eq?: InputMaybe<offer_autopilot_success_metric_enum>;
  _in?: InputMaybe<Array<offer_autopilot_success_metric_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_autopilot_success_metric_enum>;
  _nin?: InputMaybe<Array<offer_autopilot_success_metric_enum>>;
};

/** input type for inserting data into table "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_autopilot_success_metric_max_fields = {
  __typename: 'offer_autopilot_success_metric_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_autopilot_success_metric_min_fields = {
  __typename: 'offer_autopilot_success_metric_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_mutation_response = {
  __typename: 'offer_autopilot_success_metric_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_autopilot_success_metric>;
};

/** on_conflict condition type for table "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_on_conflict = {
  constraint: offer_autopilot_success_metric_constraint;
  update_columns?: Array<offer_autopilot_success_metric_update_column>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};

/** Ordering options when selecting data from "offer_autopilot_success_metric". */
export type offer_autopilot_success_metric_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_autopilot_success_metric */
export type offer_autopilot_success_metric_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_autopilot_success_metric" */
export enum offer_autopilot_success_metric_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_autopilot_success_metric" */
export type offer_autopilot_success_metric_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_autopilot_success_metric_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_autopilot_success_metric_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_autopilot_success_metric" */
export enum offer_autopilot_success_metric_update_column {
  /** column name */
  value = 'value'
}

export type offer_autopilot_success_metric_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_success_metric_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_success_metric_bool_exp;
};

/** aggregate sum on columns */
export type offer_autopilot_sum_fields = {
  __typename: 'offer_autopilot_sum_fields';
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  current_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  winning_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_autopilot" */
export enum offer_autopilot_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  created_at = 'created_at',
  /** column name */
  current_round_id = 'current_round_id',
  /** column name */
  offer_autopilot_round_ids = 'offer_autopilot_round_ids',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_ids = 'offer_variant_ids',
  /** column name */
  strategy = 'strategy',
  /** column name */
  success_metric = 'success_metric',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_variant_id = 'winning_variant_id'
}

export type offer_autopilot_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_autopilot_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_autopilot_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_autopilot_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_autopilot_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_autopilot_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_autopilot_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_autopilot_set_input>;
  /** filter the rows which have to be updated */
  where: offer_autopilot_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_autopilot_var_pop_fields = {
  __typename: 'offer_autopilot_var_pop_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_autopilot_var_samp_fields = {
  __typename: 'offer_autopilot_var_samp_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_autopilot_variance_fields = {
  __typename: 'offer_autopilot_variance_fields';
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  current_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  winning_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type offer_avg_fields = {
  __typename: 'offer_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer" */
export type offer_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer". All fields are combined with a logical 'AND'. */
export type offer_bool_exp = {
  _and?: InputMaybe<Array<offer_bool_exp>>;
  _not?: InputMaybe<offer_bool_exp>;
  _or?: InputMaybe<Array<offer_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  archived_at?: InputMaybe<timestamptz_comparison_exp>;
  button_text_translation?: InputMaybe<translation_bool_exp>;
  button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  confirmation_cancel_button_text_translation?: InputMaybe<translation_bool_exp>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  confirmation_confirm_button_text_translation?: InputMaybe<translation_bool_exp>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  confirmation_content_translation?: InputMaybe<translation_bool_exp>;
  confirmation_content_translation_id?: InputMaybe<Int_comparison_exp>;
  confirmation_enabled?: InputMaybe<Boolean_comparison_exp>;
  content_translation?: InputMaybe<translation_bool_exp>;
  content_translation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  goal?: InputMaybe<offer_goal_enum_comparison_exp>;
  headline_translation?: InputMaybe<translation_bool_exp>;
  headline_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  image_url?: InputMaybe<String_comparison_exp>;
  metadata?: InputMaybe<jsonb_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_change_plan?: InputMaybe<offer_change_plan_bool_exp>;
  offer_coupon?: InputMaybe<offer_coupon_bool_exp>;
  offer_coupons?: InputMaybe<offer_coupon_bool_exp>;
  offer_coupons_aggregate?: InputMaybe<offer_coupon_aggregate_bool_exp>;
  offer_custom?: InputMaybe<offer_custom_bool_exp>;
  offer_downgrade?: InputMaybe<offer_downgrade_bool_exp>;
  offer_group_offers?: InputMaybe<offer_group_offer_bool_exp>;
  offer_group_offers_aggregate?: InputMaybe<offer_group_offer_aggregate_bool_exp>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_bool_exp>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_bool_exp>;
  offer_product_swap?: InputMaybe<offer_product_swap_bool_exp>;
  offer_reschedule_order?: InputMaybe<offer_reschedule_order_bool_exp>;
  offer_rule_rule_offers?: InputMaybe<offer_rule_rule_offer_bool_exp>;
  offer_rule_rule_offers_aggregate?: InputMaybe<offer_rule_rule_offer_aggregate_bool_exp>;
  offer_rule_version_offers?: InputMaybe<offer_rule_version_offer_bool_exp>;
  offer_rule_version_offers_aggregate?: InputMaybe<offer_rule_version_offer_aggregate_bool_exp>;
  offer_tags?: InputMaybe<offer_tag_bool_exp>;
  offer_tags_aggregate?: InputMaybe<offer_tag_aggregate_bool_exp>;
  offer_test_offers?: InputMaybe<offer_test_offer_bool_exp>;
  offer_test_offers_aggregate?: InputMaybe<offer_test_offer_aggregate_bool_exp>;
  offer_tests?: InputMaybe<offer_test_bool_exp>;
  offer_tests_aggregate?: InputMaybe<offer_test_aggregate_bool_exp>;
  offer_timer?: InputMaybe<offer_timer_bool_exp>;
  offer_trial_extension?: InputMaybe<offer_trial_extension_bool_exp>;
  offer_type?: InputMaybe<offer_type_bool_exp>;
  offer_upgrade?: InputMaybe<offer_upgrade_bool_exp>;
  override_active_offer?: InputMaybe<Boolean_comparison_exp>;
  revenue_logs?: InputMaybe<revenue_log_bool_exp>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_bool_exp>;
  show_banner?: InputMaybe<Boolean_comparison_exp>;
  style?: InputMaybe<offer_style_enum_comparison_exp>;
  subscriber_campaign_offers?: InputMaybe<subscriber_campaign_offer_bool_exp>;
  subscriber_campaign_offers_aggregate?: InputMaybe<subscriber_campaign_offer_aggregate_bool_exp>;
  subscriber_campaign_overrides?: InputMaybe<subscriber_campaign_override_bool_exp>;
  subscriber_campaign_overrides_aggregate?: InputMaybe<subscriber_campaign_override_aggregate_bool_exp>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_bool_exp>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_bool_exp>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_bool_exp>;
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_bool_exp>;
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_bool_exp>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
  subscriber_log_offers?: InputMaybe<subscriber_log_offer_bool_exp>;
  subscriber_log_offers_aggregate?: InputMaybe<subscriber_log_offer_aggregate_bool_exp>;
  subscribers?: InputMaybe<subscriber_bool_exp>;
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_bool_exp>;
  tag_ids?: InputMaybe<jsonb_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<offer_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "offer_change_plan" */
export type offer_change_plan = {
  __typename: 'offer_change_plan';
  change_at: offer_change_plan_change_at_enum;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_coupon_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_coupon_offer?: Maybe<offer>;
  offer_id: Scalars['Int']['output'];
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  prorate: Scalars['Boolean']['output'];
  reset_billing_date: Scalars['Boolean']['output'];
};

/** aggregated selection of "offer_change_plan" */
export type offer_change_plan_aggregate = {
  __typename: 'offer_change_plan_aggregate';
  aggregate?: Maybe<offer_change_plan_aggregate_fields>;
  nodes: Array<offer_change_plan>;
};

/** aggregate fields of "offer_change_plan" */
export type offer_change_plan_aggregate_fields = {
  __typename: 'offer_change_plan_aggregate_fields';
  avg?: Maybe<offer_change_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_change_plan_max_fields>;
  min?: Maybe<offer_change_plan_min_fields>;
  stddev?: Maybe<offer_change_plan_stddev_fields>;
  stddev_pop?: Maybe<offer_change_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_change_plan_stddev_samp_fields>;
  sum?: Maybe<offer_change_plan_sum_fields>;
  var_pop?: Maybe<offer_change_plan_var_pop_fields>;
  var_samp?: Maybe<offer_change_plan_var_samp_fields>;
  variance?: Maybe<offer_change_plan_variance_fields>;
};


/** aggregate fields of "offer_change_plan" */
export type offer_change_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_change_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_change_plan_avg_fields = {
  __typename: 'offer_change_plan_avg_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_change_plan". All fields are combined with a logical 'AND'. */
export type offer_change_plan_bool_exp = {
  _and?: InputMaybe<Array<offer_change_plan_bool_exp>>;
  _not?: InputMaybe<offer_change_plan_bool_exp>;
  _or?: InputMaybe<Array<offer_change_plan_bool_exp>>;
  change_at?: InputMaybe<offer_change_plan_change_at_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_coupon_id?: InputMaybe<Int_comparison_exp>;
  offer_coupon_offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  prorate?: InputMaybe<Boolean_comparison_exp>;
  reset_billing_date?: InputMaybe<Boolean_comparison_exp>;
};

/** columns and relationships of "offer_change_plan_change_at" */
export type offer_change_plan_change_at = {
  __typename: 'offer_change_plan_change_at';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_change_plan_change_at" */
export type offer_change_plan_change_at_aggregate = {
  __typename: 'offer_change_plan_change_at_aggregate';
  aggregate?: Maybe<offer_change_plan_change_at_aggregate_fields>;
  nodes: Array<offer_change_plan_change_at>;
};

/** aggregate fields of "offer_change_plan_change_at" */
export type offer_change_plan_change_at_aggregate_fields = {
  __typename: 'offer_change_plan_change_at_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_change_plan_change_at_max_fields>;
  min?: Maybe<offer_change_plan_change_at_min_fields>;
};


/** aggregate fields of "offer_change_plan_change_at" */
export type offer_change_plan_change_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_change_plan_change_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_change_plan_change_at". All fields are combined with a logical 'AND'. */
export type offer_change_plan_change_at_bool_exp = {
  _and?: InputMaybe<Array<offer_change_plan_change_at_bool_exp>>;
  _not?: InputMaybe<offer_change_plan_change_at_bool_exp>;
  _or?: InputMaybe<Array<offer_change_plan_change_at_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_change_plan_change_at" */
export enum offer_change_plan_change_at_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_change_plan_change_at_pkey = 'offer_change_plan_change_at_pkey'
}

export enum offer_change_plan_change_at_enum {
  immediately = 'immediately',
  period_end = 'period_end'
}

/** Boolean expression to compare columns of type "offer_change_plan_change_at_enum". All fields are combined with logical 'AND'. */
export type offer_change_plan_change_at_enum_comparison_exp = {
  _eq?: InputMaybe<offer_change_plan_change_at_enum>;
  _in?: InputMaybe<Array<offer_change_plan_change_at_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_change_plan_change_at_enum>;
  _nin?: InputMaybe<Array<offer_change_plan_change_at_enum>>;
};

/** input type for inserting data into table "offer_change_plan_change_at" */
export type offer_change_plan_change_at_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_change_plan_change_at_max_fields = {
  __typename: 'offer_change_plan_change_at_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_change_plan_change_at_min_fields = {
  __typename: 'offer_change_plan_change_at_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_change_plan_change_at" */
export type offer_change_plan_change_at_mutation_response = {
  __typename: 'offer_change_plan_change_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_change_plan_change_at>;
};

/** on_conflict condition type for table "offer_change_plan_change_at" */
export type offer_change_plan_change_at_on_conflict = {
  constraint: offer_change_plan_change_at_constraint;
  update_columns?: Array<offer_change_plan_change_at_update_column>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};

/** Ordering options when selecting data from "offer_change_plan_change_at". */
export type offer_change_plan_change_at_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_change_plan_change_at */
export type offer_change_plan_change_at_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_change_plan_change_at" */
export enum offer_change_plan_change_at_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_change_plan_change_at" */
export type offer_change_plan_change_at_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_change_plan_change_at" */
export type offer_change_plan_change_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_change_plan_change_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_change_plan_change_at_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_change_plan_change_at" */
export enum offer_change_plan_change_at_update_column {
  /** column name */
  value = 'value'
}

export type offer_change_plan_change_at_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_change_plan_change_at_set_input>;
  /** filter the rows which have to be updated */
  where: offer_change_plan_change_at_bool_exp;
};

/** unique or primary key constraints on table "offer_change_plan" */
export enum offer_change_plan_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_change_plan_pkey = 'offer_change_plan_pkey'
}

/** input type for incrementing numeric columns in table "offer_change_plan" */
export type offer_change_plan_inc_input = {
  offer_coupon_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_change_plan" */
export type offer_change_plan_insert_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_coupon_id?: InputMaybe<Scalars['Int']['input']>;
  offer_coupon_offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  reset_billing_date?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type offer_change_plan_max_fields = {
  __typename: 'offer_change_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_coupon_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_change_plan_min_fields = {
  __typename: 'offer_change_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_coupon_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_change_plan" */
export type offer_change_plan_mutation_response = {
  __typename: 'offer_change_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_change_plan>;
};

/** input type for inserting object relation for remote table "offer_change_plan" */
export type offer_change_plan_obj_rel_insert_input = {
  data: offer_change_plan_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_change_plan_on_conflict>;
};

/** on_conflict condition type for table "offer_change_plan" */
export type offer_change_plan_on_conflict = {
  constraint: offer_change_plan_constraint;
  update_columns?: Array<offer_change_plan_update_column>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};

/** Ordering options when selecting data from "offer_change_plan". */
export type offer_change_plan_order_by = {
  change_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_coupon_id?: InputMaybe<order_by>;
  offer_coupon_offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  prorate?: InputMaybe<order_by>;
  reset_billing_date?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_change_plan */
export type offer_change_plan_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_change_plan" */
export enum offer_change_plan_select_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_coupon_id = 'offer_coupon_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  prorate = 'prorate',
  /** column name */
  reset_billing_date = 'reset_billing_date'
}

/** input type for updating data in table "offer_change_plan" */
export type offer_change_plan_set_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_coupon_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  reset_billing_date?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type offer_change_plan_stddev_fields = {
  __typename: 'offer_change_plan_stddev_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_change_plan_stddev_pop_fields = {
  __typename: 'offer_change_plan_stddev_pop_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_change_plan_stddev_samp_fields = {
  __typename: 'offer_change_plan_stddev_samp_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_change_plan" */
export type offer_change_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_change_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_change_plan_stream_cursor_value_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_coupon_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
  reset_billing_date?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type offer_change_plan_sum_fields = {
  __typename: 'offer_change_plan_sum_fields';
  offer_coupon_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_change_plan" */
export enum offer_change_plan_update_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_coupon_id = 'offer_coupon_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  prorate = 'prorate',
  /** column name */
  reset_billing_date = 'reset_billing_date'
}

export type offer_change_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_change_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_change_plan_set_input>;
  /** filter the rows which have to be updated */
  where: offer_change_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_change_plan_var_pop_fields = {
  __typename: 'offer_change_plan_var_pop_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_change_plan_var_samp_fields = {
  __typename: 'offer_change_plan_var_samp_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_change_plan_variance_fields = {
  __typename: 'offer_change_plan_variance_fields';
  offer_coupon_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "offer" */
export enum offer_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_pkey = 'offer_pkey',
  /** unique or primary key constraint on columns "token" */
  offer_token_key = 'offer_token_key'
}

/** columns and relationships of "offer_coupon" */
export type offer_coupon = {
  __typename: 'offer_coupon';
  amount_off: Scalars['numeric']['output'];
  apply_to: offer_coupon_apply_to_enum;
  /** An object relationship */
  coupon_duration: coupon_duration;
  /** An object relationship */
  coupon_type: offer_coupon_type;
  created_at: Scalars['timestamptz']['output'];
  duration: coupon_duration_enum;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval?: Maybe<coupon_duration_interval_enum>;
  end_trial: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  months?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  override_active_coupon: Scalars['Boolean']['output'];
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  platform_coupon?: Maybe<platform_coupon>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  type: offer_coupon_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "offer_coupon" */
export type offer_coupon_aggregate = {
  __typename: 'offer_coupon_aggregate';
  aggregate?: Maybe<offer_coupon_aggregate_fields>;
  nodes: Array<offer_coupon>;
};

export type offer_coupon_aggregate_bool_exp = {
  bool_and?: InputMaybe<offer_coupon_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<offer_coupon_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<offer_coupon_aggregate_bool_exp_count>;
};

export type offer_coupon_aggregate_bool_exp_bool_and = {
  arguments: offer_coupon_select_column_offer_coupon_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_coupon_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type offer_coupon_aggregate_bool_exp_bool_or = {
  arguments: offer_coupon_select_column_offer_coupon_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_coupon_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type offer_coupon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_coupon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_coupon" */
export type offer_coupon_aggregate_fields = {
  __typename: 'offer_coupon_aggregate_fields';
  avg?: Maybe<offer_coupon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_coupon_max_fields>;
  min?: Maybe<offer_coupon_min_fields>;
  stddev?: Maybe<offer_coupon_stddev_fields>;
  stddev_pop?: Maybe<offer_coupon_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_coupon_stddev_samp_fields>;
  sum?: Maybe<offer_coupon_sum_fields>;
  var_pop?: Maybe<offer_coupon_var_pop_fields>;
  var_samp?: Maybe<offer_coupon_var_samp_fields>;
  variance?: Maybe<offer_coupon_variance_fields>;
};


/** aggregate fields of "offer_coupon" */
export type offer_coupon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_coupon" */
export type offer_coupon_aggregate_order_by = {
  avg?: InputMaybe<offer_coupon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_coupon_max_order_by>;
  min?: InputMaybe<offer_coupon_min_order_by>;
  stddev?: InputMaybe<offer_coupon_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_coupon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_coupon_stddev_samp_order_by>;
  sum?: InputMaybe<offer_coupon_sum_order_by>;
  var_pop?: InputMaybe<offer_coupon_var_pop_order_by>;
  var_samp?: InputMaybe<offer_coupon_var_samp_order_by>;
  variance?: InputMaybe<offer_coupon_variance_order_by>;
};

/** columns and relationships of "offer_coupon_apply_to" */
export type offer_coupon_apply_to = {
  __typename: 'offer_coupon_apply_to';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_coupon_apply_to" */
export type offer_coupon_apply_to_aggregate = {
  __typename: 'offer_coupon_apply_to_aggregate';
  aggregate?: Maybe<offer_coupon_apply_to_aggregate_fields>;
  nodes: Array<offer_coupon_apply_to>;
};

/** aggregate fields of "offer_coupon_apply_to" */
export type offer_coupon_apply_to_aggregate_fields = {
  __typename: 'offer_coupon_apply_to_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_coupon_apply_to_max_fields>;
  min?: Maybe<offer_coupon_apply_to_min_fields>;
};


/** aggregate fields of "offer_coupon_apply_to" */
export type offer_coupon_apply_to_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_coupon_apply_to_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_coupon_apply_to". All fields are combined with a logical 'AND'. */
export type offer_coupon_apply_to_bool_exp = {
  _and?: InputMaybe<Array<offer_coupon_apply_to_bool_exp>>;
  _not?: InputMaybe<offer_coupon_apply_to_bool_exp>;
  _or?: InputMaybe<Array<offer_coupon_apply_to_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_coupon_apply_to" */
export enum offer_coupon_apply_to_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_coupon_apply_to_pkey = 'offer_coupon_apply_to_pkey'
}

export enum offer_coupon_apply_to_enum {
  subscriber = 'subscriber',
  subscription = 'subscription'
}

/** Boolean expression to compare columns of type "offer_coupon_apply_to_enum". All fields are combined with logical 'AND'. */
export type offer_coupon_apply_to_enum_comparison_exp = {
  _eq?: InputMaybe<offer_coupon_apply_to_enum>;
  _in?: InputMaybe<Array<offer_coupon_apply_to_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_coupon_apply_to_enum>;
  _nin?: InputMaybe<Array<offer_coupon_apply_to_enum>>;
};

/** input type for inserting data into table "offer_coupon_apply_to" */
export type offer_coupon_apply_to_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_coupon_apply_to_max_fields = {
  __typename: 'offer_coupon_apply_to_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_coupon_apply_to_min_fields = {
  __typename: 'offer_coupon_apply_to_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_coupon_apply_to" */
export type offer_coupon_apply_to_mutation_response = {
  __typename: 'offer_coupon_apply_to_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_coupon_apply_to>;
};

/** on_conflict condition type for table "offer_coupon_apply_to" */
export type offer_coupon_apply_to_on_conflict = {
  constraint: offer_coupon_apply_to_constraint;
  update_columns?: Array<offer_coupon_apply_to_update_column>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};

/** Ordering options when selecting data from "offer_coupon_apply_to". */
export type offer_coupon_apply_to_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_coupon_apply_to */
export type offer_coupon_apply_to_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_coupon_apply_to" */
export enum offer_coupon_apply_to_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_coupon_apply_to" */
export type offer_coupon_apply_to_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_coupon_apply_to" */
export type offer_coupon_apply_to_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_coupon_apply_to_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_coupon_apply_to_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_coupon_apply_to" */
export enum offer_coupon_apply_to_update_column {
  /** column name */
  value = 'value'
}

export type offer_coupon_apply_to_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_coupon_apply_to_set_input>;
  /** filter the rows which have to be updated */
  where: offer_coupon_apply_to_bool_exp;
};

/** input type for inserting array relation for remote table "offer_coupon" */
export type offer_coupon_arr_rel_insert_input = {
  data: Array<offer_coupon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_coupon_on_conflict>;
};

/** aggregate avg on columns */
export type offer_coupon_avg_fields = {
  __typename: 'offer_coupon_avg_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_coupon" */
export type offer_coupon_avg_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_coupon". All fields are combined with a logical 'AND'. */
export type offer_coupon_bool_exp = {
  _and?: InputMaybe<Array<offer_coupon_bool_exp>>;
  _not?: InputMaybe<offer_coupon_bool_exp>;
  _or?: InputMaybe<Array<offer_coupon_bool_exp>>;
  amount_off?: InputMaybe<numeric_comparison_exp>;
  apply_to?: InputMaybe<offer_coupon_apply_to_enum_comparison_exp>;
  coupon_duration?: InputMaybe<coupon_duration_bool_exp>;
  coupon_type?: InputMaybe<offer_coupon_type_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  duration?: InputMaybe<coupon_duration_enum_comparison_exp>;
  duration_count?: InputMaybe<Int_comparison_exp>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum_comparison_exp>;
  end_trial?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  months?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  override_active_coupon?: InputMaybe<Boolean_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon?: InputMaybe<platform_coupon_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<offer_coupon_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_coupon" */
export enum offer_coupon_constraint {
  /** unique or primary key constraint on columns "platform_product_group_id", "offer_id" */
  offer_coupon_offer_id_platform_product_group_id_key = 'offer_coupon_offer_id_platform_product_group_id_key',
  /** unique or primary key constraint on columns "id" */
  offer_coupon_pkey = 'offer_coupon_pkey'
}

/** input type for incrementing numeric columns in table "offer_coupon" */
export type offer_coupon_inc_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_coupon" */
export type offer_coupon_insert_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<offer_coupon_apply_to_enum>;
  coupon_duration?: InputMaybe<coupon_duration_obj_rel_insert_input>;
  coupon_type?: InputMaybe<offer_coupon_type_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  end_trial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  override_active_coupon?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon?: InputMaybe<platform_coupon_obj_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_coupon_max_fields = {
  __typename: 'offer_coupon_max_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer_coupon" */
export type offer_coupon_max_order_by = {
  amount_off?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_coupon_min_fields = {
  __typename: 'offer_coupon_min_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer_coupon" */
export type offer_coupon_min_order_by = {
  amount_off?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_coupon" */
export type offer_coupon_mutation_response = {
  __typename: 'offer_coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_coupon>;
};

/** input type for inserting object relation for remote table "offer_coupon" */
export type offer_coupon_obj_rel_insert_input = {
  data: offer_coupon_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_coupon_on_conflict>;
};

/** on_conflict condition type for table "offer_coupon" */
export type offer_coupon_on_conflict = {
  constraint: offer_coupon_constraint;
  update_columns?: Array<offer_coupon_update_column>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};

/** Ordering options when selecting data from "offer_coupon". */
export type offer_coupon_order_by = {
  amount_off?: InputMaybe<order_by>;
  apply_to?: InputMaybe<order_by>;
  coupon_duration?: InputMaybe<coupon_duration_order_by>;
  coupon_type?: InputMaybe<offer_coupon_type_order_by>;
  created_at?: InputMaybe<order_by>;
  duration?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval?: InputMaybe<order_by>;
  end_trial?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  override_active_coupon?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon?: InputMaybe<platform_coupon_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_coupon */
export type offer_coupon_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "offer_coupon" */
export enum offer_coupon_select_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  apply_to = 'apply_to',
  /** column name */
  created_at = 'created_at',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  end_trial = 'end_trial',
  /** column name */
  id = 'id',
  /** column name */
  months = 'months',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  override_active_coupon = 'override_active_coupon',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** select "offer_coupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "offer_coupon" */
export enum offer_coupon_select_column_offer_coupon_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  end_trial = 'end_trial',
  /** column name */
  override_active_coupon = 'override_active_coupon'
}

/** select "offer_coupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "offer_coupon" */
export enum offer_coupon_select_column_offer_coupon_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  end_trial = 'end_trial',
  /** column name */
  override_active_coupon = 'override_active_coupon'
}

/** input type for updating data in table "offer_coupon" */
export type offer_coupon_set_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<offer_coupon_apply_to_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  end_trial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  override_active_coupon?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_coupon_stddev_fields = {
  __typename: 'offer_coupon_stddev_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_coupon" */
export type offer_coupon_stddev_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_coupon_stddev_pop_fields = {
  __typename: 'offer_coupon_stddev_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_coupon" */
export type offer_coupon_stddev_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_coupon_stddev_samp_fields = {
  __typename: 'offer_coupon_stddev_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_coupon" */
export type offer_coupon_stddev_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_coupon" */
export type offer_coupon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_coupon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_coupon_stream_cursor_value_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<offer_coupon_apply_to_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  end_trial?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  override_active_coupon?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_coupon_sum_fields = {
  __typename: 'offer_coupon_sum_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_coupon" */
export type offer_coupon_sum_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_coupon_type" */
export type offer_coupon_type = {
  __typename: 'offer_coupon_type';
  /** An array relationship */
  coupons: Array<offer_coupon>;
  /** An aggregate relationship */
  coupons_aggregate: offer_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "offer_coupon_type" */
export type offer_coupon_typecouponsArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


/** columns and relationships of "offer_coupon_type" */
export type offer_coupon_typecoupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};

/** aggregated selection of "offer_coupon_type" */
export type offer_coupon_type_aggregate = {
  __typename: 'offer_coupon_type_aggregate';
  aggregate?: Maybe<offer_coupon_type_aggregate_fields>;
  nodes: Array<offer_coupon_type>;
};

/** aggregate fields of "offer_coupon_type" */
export type offer_coupon_type_aggregate_fields = {
  __typename: 'offer_coupon_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_coupon_type_max_fields>;
  min?: Maybe<offer_coupon_type_min_fields>;
};


/** aggregate fields of "offer_coupon_type" */
export type offer_coupon_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_coupon_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_coupon_type". All fields are combined with a logical 'AND'. */
export type offer_coupon_type_bool_exp = {
  _and?: InputMaybe<Array<offer_coupon_type_bool_exp>>;
  _not?: InputMaybe<offer_coupon_type_bool_exp>;
  _or?: InputMaybe<Array<offer_coupon_type_bool_exp>>;
  coupons?: InputMaybe<offer_coupon_bool_exp>;
  coupons_aggregate?: InputMaybe<offer_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_coupon_type" */
export enum offer_coupon_type_constraint {
  /** unique or primary key constraint on columns "value" */
  coupon_type_pkey = 'coupon_type_pkey'
}

export enum offer_coupon_type_enum {
  fixed = 'fixed',
  percentage = 'percentage'
}

/** Boolean expression to compare columns of type "offer_coupon_type_enum". All fields are combined with logical 'AND'. */
export type offer_coupon_type_enum_comparison_exp = {
  _eq?: InputMaybe<offer_coupon_type_enum>;
  _in?: InputMaybe<Array<offer_coupon_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_coupon_type_enum>;
  _nin?: InputMaybe<Array<offer_coupon_type_enum>>;
};

/** input type for inserting data into table "offer_coupon_type" */
export type offer_coupon_type_insert_input = {
  coupons?: InputMaybe<offer_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_coupon_type_max_fields = {
  __typename: 'offer_coupon_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_coupon_type_min_fields = {
  __typename: 'offer_coupon_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_coupon_type" */
export type offer_coupon_type_mutation_response = {
  __typename: 'offer_coupon_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_coupon_type>;
};

/** input type for inserting object relation for remote table "offer_coupon_type" */
export type offer_coupon_type_obj_rel_insert_input = {
  data: offer_coupon_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_coupon_type_on_conflict>;
};

/** on_conflict condition type for table "offer_coupon_type" */
export type offer_coupon_type_on_conflict = {
  constraint: offer_coupon_type_constraint;
  update_columns?: Array<offer_coupon_type_update_column>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};

/** Ordering options when selecting data from "offer_coupon_type". */
export type offer_coupon_type_order_by = {
  coupons_aggregate?: InputMaybe<offer_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_coupon_type */
export type offer_coupon_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_coupon_type" */
export enum offer_coupon_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_coupon_type" */
export type offer_coupon_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_coupon_type" */
export type offer_coupon_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_coupon_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_coupon_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_coupon_type" */
export enum offer_coupon_type_update_column {
  /** column name */
  value = 'value'
}

export type offer_coupon_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_coupon_type_set_input>;
  /** filter the rows which have to be updated */
  where: offer_coupon_type_bool_exp;
};

/** update columns of table "offer_coupon" */
export enum offer_coupon_update_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  apply_to = 'apply_to',
  /** column name */
  created_at = 'created_at',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  end_trial = 'end_trial',
  /** column name */
  id = 'id',
  /** column name */
  months = 'months',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  override_active_coupon = 'override_active_coupon',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_coupon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_coupon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_coupon_set_input>;
  /** filter the rows which have to be updated */
  where: offer_coupon_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_coupon_var_pop_fields = {
  __typename: 'offer_coupon_var_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_coupon" */
export type offer_coupon_var_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_coupon_var_samp_fields = {
  __typename: 'offer_coupon_var_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_coupon" */
export type offer_coupon_var_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_coupon_variance_fields = {
  __typename: 'offer_coupon_variance_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_coupon" */
export type offer_coupon_variance_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_custom" */
export type offer_custom = {
  __typename: 'offer_custom';
  created_at: Scalars['timestamptz']['output'];
  link_href?: Maybe<Scalars['String']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "offer_custom" */
export type offer_custom_aggregate = {
  __typename: 'offer_custom_aggregate';
  aggregate?: Maybe<offer_custom_aggregate_fields>;
  nodes: Array<offer_custom>;
};

/** aggregate fields of "offer_custom" */
export type offer_custom_aggregate_fields = {
  __typename: 'offer_custom_aggregate_fields';
  avg?: Maybe<offer_custom_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_custom_max_fields>;
  min?: Maybe<offer_custom_min_fields>;
  stddev?: Maybe<offer_custom_stddev_fields>;
  stddev_pop?: Maybe<offer_custom_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_custom_stddev_samp_fields>;
  sum?: Maybe<offer_custom_sum_fields>;
  var_pop?: Maybe<offer_custom_var_pop_fields>;
  var_samp?: Maybe<offer_custom_var_samp_fields>;
  variance?: Maybe<offer_custom_variance_fields>;
};


/** aggregate fields of "offer_custom" */
export type offer_custom_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_custom_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_custom_avg_fields = {
  __typename: 'offer_custom_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_custom". All fields are combined with a logical 'AND'. */
export type offer_custom_bool_exp = {
  _and?: InputMaybe<Array<offer_custom_bool_exp>>;
  _not?: InputMaybe<offer_custom_bool_exp>;
  _or?: InputMaybe<Array<offer_custom_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  link_href?: InputMaybe<String_comparison_exp>;
  link_text?: InputMaybe<String_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_custom" */
export enum offer_custom_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  custom_offer_pkey = 'custom_offer_pkey'
}

/** input type for incrementing numeric columns in table "offer_custom" */
export type offer_custom_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_custom" */
export type offer_custom_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_href?: InputMaybe<Scalars['String']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_custom_max_fields = {
  __typename: 'offer_custom_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  link_href?: Maybe<Scalars['String']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_custom_min_fields = {
  __typename: 'offer_custom_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  link_href?: Maybe<Scalars['String']['output']>;
  link_text?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_custom" */
export type offer_custom_mutation_response = {
  __typename: 'offer_custom_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_custom>;
};

/** input type for inserting object relation for remote table "offer_custom" */
export type offer_custom_obj_rel_insert_input = {
  data: offer_custom_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_custom_on_conflict>;
};

/** on_conflict condition type for table "offer_custom" */
export type offer_custom_on_conflict = {
  constraint: offer_custom_constraint;
  update_columns?: Array<offer_custom_update_column>;
  where?: InputMaybe<offer_custom_bool_exp>;
};

/** Ordering options when selecting data from "offer_custom". */
export type offer_custom_order_by = {
  created_at?: InputMaybe<order_by>;
  link_href?: InputMaybe<order_by>;
  link_text?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_custom */
export type offer_custom_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_custom" */
export enum offer_custom_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  link_href = 'link_href',
  /** column name */
  link_text = 'link_text',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_custom" */
export type offer_custom_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_href?: InputMaybe<Scalars['String']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_custom_stddev_fields = {
  __typename: 'offer_custom_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_custom_stddev_pop_fields = {
  __typename: 'offer_custom_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_custom_stddev_samp_fields = {
  __typename: 'offer_custom_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_custom" */
export type offer_custom_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_custom_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_custom_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  link_href?: InputMaybe<Scalars['String']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_custom_sum_fields = {
  __typename: 'offer_custom_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_custom" */
export enum offer_custom_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  link_href = 'link_href',
  /** column name */
  link_text = 'link_text',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_custom_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_custom_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_custom_set_input>;
  /** filter the rows which have to be updated */
  where: offer_custom_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_custom_var_pop_fields = {
  __typename: 'offer_custom_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_custom_var_samp_fields = {
  __typename: 'offer_custom_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_custom_variance_fields = {
  __typename: 'offer_custom_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_delete_at_path_input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  tag_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_delete_elem_input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
  tag_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_delete_key_input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
  tag_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "offer_downgrade" */
export type offer_downgrade = {
  __typename: 'offer_downgrade';
  change_at: offer_change_plan_change_at_enum;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "offer_downgrade" */
export type offer_downgrade_aggregate = {
  __typename: 'offer_downgrade_aggregate';
  aggregate?: Maybe<offer_downgrade_aggregate_fields>;
  nodes: Array<offer_downgrade>;
};

/** aggregate fields of "offer_downgrade" */
export type offer_downgrade_aggregate_fields = {
  __typename: 'offer_downgrade_aggregate_fields';
  avg?: Maybe<offer_downgrade_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_downgrade_max_fields>;
  min?: Maybe<offer_downgrade_min_fields>;
  stddev?: Maybe<offer_downgrade_stddev_fields>;
  stddev_pop?: Maybe<offer_downgrade_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_downgrade_stddev_samp_fields>;
  sum?: Maybe<offer_downgrade_sum_fields>;
  var_pop?: Maybe<offer_downgrade_var_pop_fields>;
  var_samp?: Maybe<offer_downgrade_var_samp_fields>;
  variance?: Maybe<offer_downgrade_variance_fields>;
};


/** aggregate fields of "offer_downgrade" */
export type offer_downgrade_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_downgrade_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_downgrade_avg_fields = {
  __typename: 'offer_downgrade_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_downgrade". All fields are combined with a logical 'AND'. */
export type offer_downgrade_bool_exp = {
  _and?: InputMaybe<Array<offer_downgrade_bool_exp>>;
  _not?: InputMaybe<offer_downgrade_bool_exp>;
  _or?: InputMaybe<Array<offer_downgrade_bool_exp>>;
  change_at?: InputMaybe<offer_change_plan_change_at_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_downgrade" */
export enum offer_downgrade_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_downgrade_pkey = 'offer_downgrade_pkey'
}

/** input type for incrementing numeric columns in table "offer_downgrade" */
export type offer_downgrade_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_downgrade" */
export type offer_downgrade_insert_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_downgrade_max_fields = {
  __typename: 'offer_downgrade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_downgrade_min_fields = {
  __typename: 'offer_downgrade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_downgrade" */
export type offer_downgrade_mutation_response = {
  __typename: 'offer_downgrade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_downgrade>;
};

/** input type for inserting object relation for remote table "offer_downgrade" */
export type offer_downgrade_obj_rel_insert_input = {
  data: offer_downgrade_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_downgrade_on_conflict>;
};

/** on_conflict condition type for table "offer_downgrade" */
export type offer_downgrade_on_conflict = {
  constraint: offer_downgrade_constraint;
  update_columns?: Array<offer_downgrade_update_column>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};

/** Ordering options when selecting data from "offer_downgrade". */
export type offer_downgrade_order_by = {
  change_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_downgrade */
export type offer_downgrade_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_downgrade" */
export enum offer_downgrade_select_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

/** input type for updating data in table "offer_downgrade" */
export type offer_downgrade_set_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type offer_downgrade_stddev_fields = {
  __typename: 'offer_downgrade_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_downgrade_stddev_pop_fields = {
  __typename: 'offer_downgrade_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_downgrade_stddev_samp_fields = {
  __typename: 'offer_downgrade_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_downgrade" */
export type offer_downgrade_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_downgrade_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_downgrade_stream_cursor_value_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type offer_downgrade_sum_fields = {
  __typename: 'offer_downgrade_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_downgrade" */
export enum offer_downgrade_update_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

export type offer_downgrade_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_downgrade_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_downgrade_set_input>;
  /** filter the rows which have to be updated */
  where: offer_downgrade_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_downgrade_var_pop_fields = {
  __typename: 'offer_downgrade_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_downgrade_var_samp_fields = {
  __typename: 'offer_downgrade_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_downgrade_variance_fields = {
  __typename: 'offer_downgrade_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_goal" */
export type offer_goal = {
  __typename: 'offer_goal';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_goal" */
export type offer_goal_aggregate = {
  __typename: 'offer_goal_aggregate';
  aggregate?: Maybe<offer_goal_aggregate_fields>;
  nodes: Array<offer_goal>;
};

/** aggregate fields of "offer_goal" */
export type offer_goal_aggregate_fields = {
  __typename: 'offer_goal_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_goal_max_fields>;
  min?: Maybe<offer_goal_min_fields>;
};


/** aggregate fields of "offer_goal" */
export type offer_goal_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_goal_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_goal". All fields are combined with a logical 'AND'. */
export type offer_goal_bool_exp = {
  _and?: InputMaybe<Array<offer_goal_bool_exp>>;
  _not?: InputMaybe<offer_goal_bool_exp>;
  _or?: InputMaybe<Array<offer_goal_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_goal" */
export enum offer_goal_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_goal_pkey = 'offer_goal_pkey'
}

export enum offer_goal_enum {
  conversion = 'conversion',
  retention = 'retention'
}

/** Boolean expression to compare columns of type "offer_goal_enum". All fields are combined with logical 'AND'. */
export type offer_goal_enum_comparison_exp = {
  _eq?: InputMaybe<offer_goal_enum>;
  _in?: InputMaybe<Array<offer_goal_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_goal_enum>;
  _nin?: InputMaybe<Array<offer_goal_enum>>;
};

/** input type for inserting data into table "offer_goal" */
export type offer_goal_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_goal_max_fields = {
  __typename: 'offer_goal_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_goal_min_fields = {
  __typename: 'offer_goal_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_goal" */
export type offer_goal_mutation_response = {
  __typename: 'offer_goal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_goal>;
};

/** on_conflict condition type for table "offer_goal" */
export type offer_goal_on_conflict = {
  constraint: offer_goal_constraint;
  update_columns?: Array<offer_goal_update_column>;
  where?: InputMaybe<offer_goal_bool_exp>;
};

/** Ordering options when selecting data from "offer_goal". */
export type offer_goal_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_goal */
export type offer_goal_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_goal" */
export enum offer_goal_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_goal" */
export type offer_goal_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_goal" */
export type offer_goal_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_goal_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_goal_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_goal" */
export enum offer_goal_update_column {
  /** column name */
  value = 'value'
}

export type offer_goal_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_goal_set_input>;
  /** filter the rows which have to be updated */
  where: offer_goal_bool_exp;
};

/** columns and relationships of "offer_group" */
export type offer_group = {
  __typename: 'offer_group';
  /** An object relationship */
  account?: Maybe<account>;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  cancel_text_translation: translation;
  cancel_text_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  content_translation: translation;
  content_translation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  goal: offer_goal_enum;
  /** An object relationship */
  headline_translation?: Maybe<translation>;
  headline_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  offer_group_offers: Array<offer_group_offer>;
  /** An aggregate relationship */
  offer_group_offers_aggregate: offer_group_offer_aggregate;
  offer_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  offer_rule_rule_offer_groups: Array<offer_rule_rule_offer_group>;
  /** An aggregate relationship */
  offer_rule_rule_offer_groups_aggregate: offer_rule_rule_offer_group_aggregate;
  randomize: Scalars['Boolean']['output'];
  show_images: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_group" */
export type offer_groupoffer_group_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer_group" */
export type offer_groupoffer_group_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


/** columns and relationships of "offer_group" */
export type offer_groupoffer_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_group" */
export type offer_groupoffer_rule_rule_offer_groupsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


/** columns and relationships of "offer_group" */
export type offer_groupoffer_rule_rule_offer_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};

/** aggregated selection of "offer_group" */
export type offer_group_aggregate = {
  __typename: 'offer_group_aggregate';
  aggregate?: Maybe<offer_group_aggregate_fields>;
  nodes: Array<offer_group>;
};

/** aggregate fields of "offer_group" */
export type offer_group_aggregate_fields = {
  __typename: 'offer_group_aggregate_fields';
  avg?: Maybe<offer_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_group_max_fields>;
  min?: Maybe<offer_group_min_fields>;
  stddev?: Maybe<offer_group_stddev_fields>;
  stddev_pop?: Maybe<offer_group_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_group_stddev_samp_fields>;
  sum?: Maybe<offer_group_sum_fields>;
  var_pop?: Maybe<offer_group_var_pop_fields>;
  var_samp?: Maybe<offer_group_var_samp_fields>;
  variance?: Maybe<offer_group_variance_fields>;
};


/** aggregate fields of "offer_group" */
export type offer_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_group_append_input = {
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_group_avg_fields = {
  __typename: 'offer_group_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_group". All fields are combined with a logical 'AND'. */
export type offer_group_bool_exp = {
  _and?: InputMaybe<Array<offer_group_bool_exp>>;
  _not?: InputMaybe<offer_group_bool_exp>;
  _or?: InputMaybe<Array<offer_group_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  cancel_text_translation?: InputMaybe<translation_bool_exp>;
  cancel_text_translation_id?: InputMaybe<Int_comparison_exp>;
  content_translation?: InputMaybe<translation_bool_exp>;
  content_translation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  goal?: InputMaybe<offer_goal_enum_comparison_exp>;
  headline_translation?: InputMaybe<translation_bool_exp>;
  headline_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer_group_offers?: InputMaybe<offer_group_offer_bool_exp>;
  offer_group_offers_aggregate?: InputMaybe<offer_group_offer_aggregate_bool_exp>;
  offer_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_rule_rule_offer_groups?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
  offer_rule_rule_offer_groups_aggregate?: InputMaybe<offer_rule_rule_offer_group_aggregate_bool_exp>;
  randomize?: InputMaybe<Boolean_comparison_exp>;
  show_images?: InputMaybe<Boolean_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_group" */
export enum offer_group_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_group_pkey = 'offer_group_pkey',
  /** unique or primary key constraint on columns "token" */
  offer_group_token_key = 'offer_group_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_group_delete_at_path_input = {
  offer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_group_delete_elem_input = {
  offer_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_group_delete_key_input = {
  offer_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_group" */
export type offer_group_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  cancel_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_group" */
export type offer_group_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  cancel_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  cancel_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation?: InputMaybe<translation_obj_rel_insert_input>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_group_offers?: InputMaybe<offer_group_offer_arr_rel_insert_input>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_rule_offer_groups?: InputMaybe<offer_rule_rule_offer_group_arr_rel_insert_input>;
  randomize?: InputMaybe<Scalars['Boolean']['input']>;
  show_images?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_group_max_fields = {
  __typename: 'offer_group_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_group_min_fields = {
  __typename: 'offer_group_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_group" */
export type offer_group_mutation_response = {
  __typename: 'offer_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_group>;
};

/** input type for inserting object relation for remote table "offer_group" */
export type offer_group_obj_rel_insert_input = {
  data: offer_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_group_on_conflict>;
};

/** columns and relationships of "offer_group_offer" */
export type offer_group_offer = {
  __typename: 'offer_group_offer';
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_group_offer" */
export type offer_group_offer_aggregate = {
  __typename: 'offer_group_offer_aggregate';
  aggregate?: Maybe<offer_group_offer_aggregate_fields>;
  nodes: Array<offer_group_offer>;
};

export type offer_group_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_group_offer_aggregate_bool_exp_count>;
};

export type offer_group_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_group_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_group_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_group_offer" */
export type offer_group_offer_aggregate_fields = {
  __typename: 'offer_group_offer_aggregate_fields';
  avg?: Maybe<offer_group_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_group_offer_max_fields>;
  min?: Maybe<offer_group_offer_min_fields>;
  stddev?: Maybe<offer_group_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_group_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_group_offer_stddev_samp_fields>;
  sum?: Maybe<offer_group_offer_sum_fields>;
  var_pop?: Maybe<offer_group_offer_var_pop_fields>;
  var_samp?: Maybe<offer_group_offer_var_samp_fields>;
  variance?: Maybe<offer_group_offer_variance_fields>;
};


/** aggregate fields of "offer_group_offer" */
export type offer_group_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_group_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_group_offer" */
export type offer_group_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_group_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_group_offer_max_order_by>;
  min?: InputMaybe<offer_group_offer_min_order_by>;
  stddev?: InputMaybe<offer_group_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_group_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_group_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_group_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_group_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_group_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_group_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_group_offer" */
export type offer_group_offer_arr_rel_insert_input = {
  data: Array<offer_group_offer_insert_input>;
};

/** aggregate avg on columns */
export type offer_group_offer_avg_fields = {
  __typename: 'offer_group_offer_avg_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_group_offer" */
export type offer_group_offer_avg_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_group_offer". All fields are combined with a logical 'AND'. */
export type offer_group_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_group_offer_bool_exp>>;
  _not?: InputMaybe<offer_group_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_group_offer_bool_exp>>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_group_offer" */
export type offer_group_offer_insert_input = {
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_group_offer_max_fields = {
  __typename: 'offer_group_offer_max_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_group_offer" */
export type offer_group_offer_max_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_group_offer_min_fields = {
  __typename: 'offer_group_offer_min_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_group_offer" */
export type offer_group_offer_min_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_group_offer". */
export type offer_group_offer_order_by = {
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_group_offer" */
export enum offer_group_offer_select_column {
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_group_offer_stddev_fields = {
  __typename: 'offer_group_offer_stddev_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_group_offer" */
export type offer_group_offer_stddev_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_group_offer_stddev_pop_fields = {
  __typename: 'offer_group_offer_stddev_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_group_offer" */
export type offer_group_offer_stddev_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_group_offer_stddev_samp_fields = {
  __typename: 'offer_group_offer_stddev_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_group_offer" */
export type offer_group_offer_stddev_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_group_offer" */
export type offer_group_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_group_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_group_offer_stream_cursor_value_input = {
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_group_offer_sum_fields = {
  __typename: 'offer_group_offer_sum_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_group_offer" */
export type offer_group_offer_sum_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_group_offer_var_pop_fields = {
  __typename: 'offer_group_offer_var_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_group_offer" */
export type offer_group_offer_var_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_group_offer_var_samp_fields = {
  __typename: 'offer_group_offer_var_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_group_offer" */
export type offer_group_offer_var_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_group_offer_variance_fields = {
  __typename: 'offer_group_offer_variance_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_group_offer" */
export type offer_group_offer_variance_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_group" */
export type offer_group_on_conflict = {
  constraint: offer_group_constraint;
  update_columns?: Array<offer_group_update_column>;
  where?: InputMaybe<offer_group_bool_exp>;
};

/** Ordering options when selecting data from "offer_group". */
export type offer_group_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  cancel_text_translation?: InputMaybe<translation_order_by>;
  cancel_text_translation_id?: InputMaybe<order_by>;
  content_translation?: InputMaybe<translation_order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  goal?: InputMaybe<order_by>;
  headline_translation?: InputMaybe<translation_order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  offer_group_offers_aggregate?: InputMaybe<offer_group_offer_aggregate_order_by>;
  offer_ids?: InputMaybe<order_by>;
  offer_rule_rule_offer_groups_aggregate?: InputMaybe<offer_rule_rule_offer_group_aggregate_order_by>;
  randomize?: InputMaybe<order_by>;
  show_images?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_group */
export type offer_group_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_group_prepend_input = {
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_group" */
export enum offer_group_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  cancel_text_translation_id = 'cancel_text_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  randomize = 'randomize',
  /** column name */
  show_images = 'show_images',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_group" */
export type offer_group_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  cancel_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  randomize?: InputMaybe<Scalars['Boolean']['input']>;
  show_images?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_group_stddev_fields = {
  __typename: 'offer_group_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_group_stddev_pop_fields = {
  __typename: 'offer_group_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_group_stddev_samp_fields = {
  __typename: 'offer_group_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_group" */
export type offer_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_group_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  cancel_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  randomize?: InputMaybe<Scalars['Boolean']['input']>;
  show_images?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_group_sum_fields = {
  __typename: 'offer_group_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_group" */
export enum offer_group_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  cancel_text_translation_id = 'cancel_text_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  randomize = 'randomize',
  /** column name */
  show_images = 'show_images',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_group_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_group_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_group_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_group_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_group_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_group_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_group_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_group_set_input>;
  /** filter the rows which have to be updated */
  where: offer_group_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_group_var_pop_fields = {
  __typename: 'offer_group_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_group_var_samp_fields = {
  __typename: 'offer_group_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_group_variance_fields = {
  __typename: 'offer_group_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  cancel_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "offer" */
export type offer_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer" */
export type offer_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_cancel_button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_confirm_button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  confirmation_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation?: InputMaybe<translation_obj_rel_insert_input>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_change_plan?: InputMaybe<offer_change_plan_obj_rel_insert_input>;
  offer_coupon?: InputMaybe<offer_coupon_obj_rel_insert_input>;
  offer_coupons?: InputMaybe<offer_coupon_arr_rel_insert_input>;
  offer_custom?: InputMaybe<offer_custom_obj_rel_insert_input>;
  offer_downgrade?: InputMaybe<offer_downgrade_obj_rel_insert_input>;
  offer_group_offers?: InputMaybe<offer_group_offer_arr_rel_insert_input>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_obj_rel_insert_input>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_obj_rel_insert_input>;
  offer_product_swap?: InputMaybe<offer_product_swap_obj_rel_insert_input>;
  offer_reschedule_order?: InputMaybe<offer_reschedule_order_obj_rel_insert_input>;
  offer_rule_rule_offers?: InputMaybe<offer_rule_rule_offer_arr_rel_insert_input>;
  offer_rule_version_offers?: InputMaybe<offer_rule_version_offer_arr_rel_insert_input>;
  offer_tags?: InputMaybe<offer_tag_arr_rel_insert_input>;
  offer_test_offers?: InputMaybe<offer_test_offer_arr_rel_insert_input>;
  offer_tests?: InputMaybe<offer_test_arr_rel_insert_input>;
  offer_timer?: InputMaybe<offer_timer_obj_rel_insert_input>;
  offer_trial_extension?: InputMaybe<offer_trial_extension_obj_rel_insert_input>;
  offer_type?: InputMaybe<offer_type_obj_rel_insert_input>;
  offer_upgrade?: InputMaybe<offer_upgrade_obj_rel_insert_input>;
  override_active_offer?: InputMaybe<Scalars['Boolean']['input']>;
  revenue_logs?: InputMaybe<revenue_log_arr_rel_insert_input>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<offer_style_enum>;
  subscriber_campaign_offers?: InputMaybe<subscriber_campaign_offer_arr_rel_insert_input>;
  subscriber_campaign_overrides?: InputMaybe<subscriber_campaign_override_arr_rel_insert_input>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_arr_rel_insert_input>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_arr_rel_insert_input>;
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_arr_rel_insert_input>;
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
  subscriber_log_offers?: InputMaybe<subscriber_log_offer_arr_rel_insert_input>;
  subscribers?: InputMaybe<subscriber_arr_rel_insert_input>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_max_fields = {
  __typename: 'offer_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer" */
export type offer_max_order_by = {
  account_id?: InputMaybe<order_by>;
  archived_at?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_min_fields = {
  __typename: 'offer_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer" */
export type offer_min_order_by = {
  account_id?: InputMaybe<order_by>;
  archived_at?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_modify_subscription" */
export type offer_modify_subscription = {
  __typename: 'offer_modify_subscription';
  modify_at: offer_modify_subscription_modify_at_enum;
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_modify_subscription_options: Array<offer_modify_subscription_option>;
  /** An aggregate relationship */
  offer_modify_subscription_options_aggregate: offer_modify_subscription_option_aggregate;
  prorate: Scalars['Boolean']['output'];
};


/** columns and relationships of "offer_modify_subscription" */
export type offer_modify_subscriptionoffer_modify_subscription_optionsArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


/** columns and relationships of "offer_modify_subscription" */
export type offer_modify_subscriptionoffer_modify_subscription_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};

/** aggregated selection of "offer_modify_subscription" */
export type offer_modify_subscription_aggregate = {
  __typename: 'offer_modify_subscription_aggregate';
  aggregate?: Maybe<offer_modify_subscription_aggregate_fields>;
  nodes: Array<offer_modify_subscription>;
};

/** aggregate fields of "offer_modify_subscription" */
export type offer_modify_subscription_aggregate_fields = {
  __typename: 'offer_modify_subscription_aggregate_fields';
  avg?: Maybe<offer_modify_subscription_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_modify_subscription_max_fields>;
  min?: Maybe<offer_modify_subscription_min_fields>;
  stddev?: Maybe<offer_modify_subscription_stddev_fields>;
  stddev_pop?: Maybe<offer_modify_subscription_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_modify_subscription_stddev_samp_fields>;
  sum?: Maybe<offer_modify_subscription_sum_fields>;
  var_pop?: Maybe<offer_modify_subscription_var_pop_fields>;
  var_samp?: Maybe<offer_modify_subscription_var_samp_fields>;
  variance?: Maybe<offer_modify_subscription_variance_fields>;
};


/** aggregate fields of "offer_modify_subscription" */
export type offer_modify_subscription_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_modify_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_modify_subscription_avg_fields = {
  __typename: 'offer_modify_subscription_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_modify_subscription". All fields are combined with a logical 'AND'. */
export type offer_modify_subscription_bool_exp = {
  _and?: InputMaybe<Array<offer_modify_subscription_bool_exp>>;
  _not?: InputMaybe<offer_modify_subscription_bool_exp>;
  _or?: InputMaybe<Array<offer_modify_subscription_bool_exp>>;
  modify_at?: InputMaybe<offer_modify_subscription_modify_at_enum_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_modify_subscription_options?: InputMaybe<offer_modify_subscription_option_bool_exp>;
  offer_modify_subscription_options_aggregate?: InputMaybe<offer_modify_subscription_option_aggregate_bool_exp>;
  prorate?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "offer_modify_subscription" */
export enum offer_modify_subscription_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_modify_subscription_pkey = 'offer_modify_subscription_pkey'
}

/** input type for incrementing numeric columns in table "offer_modify_subscription" */
export type offer_modify_subscription_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_modify_subscription" */
export type offer_modify_subscription_insert_input = {
  modify_at?: InputMaybe<offer_modify_subscription_modify_at_enum>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_modify_subscription_options?: InputMaybe<offer_modify_subscription_option_arr_rel_insert_input>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type offer_modify_subscription_max_fields = {
  __typename: 'offer_modify_subscription_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_modify_subscription_min_fields = {
  __typename: 'offer_modify_subscription_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at = {
  __typename: 'offer_modify_subscription_modify_at';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_aggregate = {
  __typename: 'offer_modify_subscription_modify_at_aggregate';
  aggregate?: Maybe<offer_modify_subscription_modify_at_aggregate_fields>;
  nodes: Array<offer_modify_subscription_modify_at>;
};

/** aggregate fields of "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_aggregate_fields = {
  __typename: 'offer_modify_subscription_modify_at_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_modify_subscription_modify_at_max_fields>;
  min?: Maybe<offer_modify_subscription_modify_at_min_fields>;
};


/** aggregate fields of "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_modify_subscription_modify_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_modify_subscription_modify_at". All fields are combined with a logical 'AND'. */
export type offer_modify_subscription_modify_at_bool_exp = {
  _and?: InputMaybe<Array<offer_modify_subscription_modify_at_bool_exp>>;
  _not?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
  _or?: InputMaybe<Array<offer_modify_subscription_modify_at_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_modify_subscription_modify_at" */
export enum offer_modify_subscription_modify_at_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_modify_subscription_modify_at_pkey = 'offer_modify_subscription_modify_at_pkey'
}

export enum offer_modify_subscription_modify_at_enum {
  immediately = 'immediately',
  period_end = 'period_end'
}

/** Boolean expression to compare columns of type "offer_modify_subscription_modify_at_enum". All fields are combined with logical 'AND'. */
export type offer_modify_subscription_modify_at_enum_comparison_exp = {
  _eq?: InputMaybe<offer_modify_subscription_modify_at_enum>;
  _in?: InputMaybe<Array<offer_modify_subscription_modify_at_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_modify_subscription_modify_at_enum>;
  _nin?: InputMaybe<Array<offer_modify_subscription_modify_at_enum>>;
};

/** input type for inserting data into table "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_modify_subscription_modify_at_max_fields = {
  __typename: 'offer_modify_subscription_modify_at_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_modify_subscription_modify_at_min_fields = {
  __typename: 'offer_modify_subscription_modify_at_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_mutation_response = {
  __typename: 'offer_modify_subscription_modify_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_modify_subscription_modify_at>;
};

/** on_conflict condition type for table "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_on_conflict = {
  constraint: offer_modify_subscription_modify_at_constraint;
  update_columns?: Array<offer_modify_subscription_modify_at_update_column>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};

/** Ordering options when selecting data from "offer_modify_subscription_modify_at". */
export type offer_modify_subscription_modify_at_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_modify_subscription_modify_at */
export type offer_modify_subscription_modify_at_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_modify_subscription_modify_at" */
export enum offer_modify_subscription_modify_at_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_modify_subscription_modify_at" */
export type offer_modify_subscription_modify_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_modify_subscription_modify_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_modify_subscription_modify_at_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_modify_subscription_modify_at" */
export enum offer_modify_subscription_modify_at_update_column {
  /** column name */
  value = 'value'
}

export type offer_modify_subscription_modify_at_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_modify_subscription_modify_at_set_input>;
  /** filter the rows which have to be updated */
  where: offer_modify_subscription_modify_at_bool_exp;
};

/** response of any mutation on the table "offer_modify_subscription" */
export type offer_modify_subscription_mutation_response = {
  __typename: 'offer_modify_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_modify_subscription>;
};

/** input type for inserting object relation for remote table "offer_modify_subscription" */
export type offer_modify_subscription_obj_rel_insert_input = {
  data: offer_modify_subscription_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_modify_subscription_on_conflict>;
};

/** on_conflict condition type for table "offer_modify_subscription" */
export type offer_modify_subscription_on_conflict = {
  constraint: offer_modify_subscription_constraint;
  update_columns?: Array<offer_modify_subscription_update_column>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};

/** columns and relationships of "offer_modify_subscription_option" */
export type offer_modify_subscription_option = {
  __typename: 'offer_modify_subscription_option';
  custom_plan_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_modify_subscription: offer_modify_subscription;
  /** An object relationship */
  platform_addon?: Maybe<platform_addon>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  type: offer_modify_subscription_option_type_enum;
};

/** aggregated selection of "offer_modify_subscription_option" */
export type offer_modify_subscription_option_aggregate = {
  __typename: 'offer_modify_subscription_option_aggregate';
  aggregate?: Maybe<offer_modify_subscription_option_aggregate_fields>;
  nodes: Array<offer_modify_subscription_option>;
};

export type offer_modify_subscription_option_aggregate_bool_exp = {
  count?: InputMaybe<offer_modify_subscription_option_aggregate_bool_exp_count>;
};

export type offer_modify_subscription_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_modify_subscription_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_modify_subscription_option" */
export type offer_modify_subscription_option_aggregate_fields = {
  __typename: 'offer_modify_subscription_option_aggregate_fields';
  avg?: Maybe<offer_modify_subscription_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_modify_subscription_option_max_fields>;
  min?: Maybe<offer_modify_subscription_option_min_fields>;
  stddev?: Maybe<offer_modify_subscription_option_stddev_fields>;
  stddev_pop?: Maybe<offer_modify_subscription_option_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_modify_subscription_option_stddev_samp_fields>;
  sum?: Maybe<offer_modify_subscription_option_sum_fields>;
  var_pop?: Maybe<offer_modify_subscription_option_var_pop_fields>;
  var_samp?: Maybe<offer_modify_subscription_option_var_samp_fields>;
  variance?: Maybe<offer_modify_subscription_option_variance_fields>;
};


/** aggregate fields of "offer_modify_subscription_option" */
export type offer_modify_subscription_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_aggregate_order_by = {
  avg?: InputMaybe<offer_modify_subscription_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_modify_subscription_option_max_order_by>;
  min?: InputMaybe<offer_modify_subscription_option_min_order_by>;
  stddev?: InputMaybe<offer_modify_subscription_option_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_modify_subscription_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_modify_subscription_option_stddev_samp_order_by>;
  sum?: InputMaybe<offer_modify_subscription_option_sum_order_by>;
  var_pop?: InputMaybe<offer_modify_subscription_option_var_pop_order_by>;
  var_samp?: InputMaybe<offer_modify_subscription_option_var_samp_order_by>;
  variance?: InputMaybe<offer_modify_subscription_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_arr_rel_insert_input = {
  data: Array<offer_modify_subscription_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_modify_subscription_option_on_conflict>;
};

/** aggregate avg on columns */
export type offer_modify_subscription_option_avg_fields = {
  __typename: 'offer_modify_subscription_option_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_avg_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_modify_subscription_option". All fields are combined with a logical 'AND'. */
export type offer_modify_subscription_option_bool_exp = {
  _and?: InputMaybe<Array<offer_modify_subscription_option_bool_exp>>;
  _not?: InputMaybe<offer_modify_subscription_option_bool_exp>;
  _or?: InputMaybe<Array<offer_modify_subscription_option_bool_exp>>;
  custom_plan_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_bool_exp>;
  platform_addon?: InputMaybe<platform_addon_bool_exp>;
  platform_addon_id?: InputMaybe<String_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<offer_modify_subscription_option_type_enum_comparison_exp>;
};

/** unique or primary key constraints on table "offer_modify_subscription_option" */
export enum offer_modify_subscription_option_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_modify_subscription_option_pkey = 'offer_modify_subscription_option_pkey'
}

/** input type for incrementing numeric columns in table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_insert_input = {
  custom_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_obj_rel_insert_input>;
  platform_addon?: InputMaybe<platform_addon_obj_rel_insert_input>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_modify_subscription_option_type_enum>;
};

/** aggregate max on columns */
export type offer_modify_subscription_option_max_fields = {
  __typename: 'offer_modify_subscription_option_max_fields';
  custom_plan_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_max_order_by = {
  custom_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_modify_subscription_option_min_fields = {
  __typename: 'offer_modify_subscription_option_min_fields';
  custom_plan_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_min_order_by = {
  custom_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_mutation_response = {
  __typename: 'offer_modify_subscription_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_modify_subscription_option>;
};

/** on_conflict condition type for table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_on_conflict = {
  constraint: offer_modify_subscription_option_constraint;
  update_columns?: Array<offer_modify_subscription_option_update_column>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};

/** Ordering options when selecting data from "offer_modify_subscription_option". */
export type offer_modify_subscription_option_order_by = {
  custom_plan_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_order_by>;
  platform_addon?: InputMaybe<platform_addon_order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_modify_subscription_option */
export type offer_modify_subscription_option_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "offer_modify_subscription_option" */
export enum offer_modify_subscription_option_select_column {
  /** column name */
  custom_plan_id = 'custom_plan_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_set_input = {
  custom_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_modify_subscription_option_type_enum>;
};

/** aggregate stddev on columns */
export type offer_modify_subscription_option_stddev_fields = {
  __typename: 'offer_modify_subscription_option_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_stddev_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_modify_subscription_option_stddev_pop_fields = {
  __typename: 'offer_modify_subscription_option_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_modify_subscription_option_stddev_samp_fields = {
  __typename: 'offer_modify_subscription_option_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_modify_subscription_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_modify_subscription_option_stream_cursor_value_input = {
  custom_plan_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_modify_subscription_option_type_enum>;
};

/** aggregate sum on columns */
export type offer_modify_subscription_option_sum_fields = {
  __typename: 'offer_modify_subscription_option_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_sum_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type = {
  __typename: 'offer_modify_subscription_option_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_aggregate = {
  __typename: 'offer_modify_subscription_option_type_aggregate';
  aggregate?: Maybe<offer_modify_subscription_option_type_aggregate_fields>;
  nodes: Array<offer_modify_subscription_option_type>;
};

/** aggregate fields of "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_aggregate_fields = {
  __typename: 'offer_modify_subscription_option_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_modify_subscription_option_type_max_fields>;
  min?: Maybe<offer_modify_subscription_option_type_min_fields>;
};


/** aggregate fields of "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_modify_subscription_option_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_modify_subscription_option_type". All fields are combined with a logical 'AND'. */
export type offer_modify_subscription_option_type_bool_exp = {
  _and?: InputMaybe<Array<offer_modify_subscription_option_type_bool_exp>>;
  _not?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
  _or?: InputMaybe<Array<offer_modify_subscription_option_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_modify_subscription_option_type" */
export enum offer_modify_subscription_option_type_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_modify_subscription_option_type_pkey = 'offer_modify_subscription_option_type_pkey'
}

export enum offer_modify_subscription_option_type_enum {
  add_addon = 'add_addon',
  add_plan = 'add_plan',
  remove_addon = 'remove_addon',
  remove_plan = 'remove_plan'
}

/** Boolean expression to compare columns of type "offer_modify_subscription_option_type_enum". All fields are combined with logical 'AND'. */
export type offer_modify_subscription_option_type_enum_comparison_exp = {
  _eq?: InputMaybe<offer_modify_subscription_option_type_enum>;
  _in?: InputMaybe<Array<offer_modify_subscription_option_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_modify_subscription_option_type_enum>;
  _nin?: InputMaybe<Array<offer_modify_subscription_option_type_enum>>;
};

/** input type for inserting data into table "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_modify_subscription_option_type_max_fields = {
  __typename: 'offer_modify_subscription_option_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_modify_subscription_option_type_min_fields = {
  __typename: 'offer_modify_subscription_option_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_mutation_response = {
  __typename: 'offer_modify_subscription_option_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_modify_subscription_option_type>;
};

/** on_conflict condition type for table "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_on_conflict = {
  constraint: offer_modify_subscription_option_type_constraint;
  update_columns?: Array<offer_modify_subscription_option_type_update_column>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};

/** Ordering options when selecting data from "offer_modify_subscription_option_type". */
export type offer_modify_subscription_option_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_modify_subscription_option_type */
export type offer_modify_subscription_option_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_modify_subscription_option_type" */
export enum offer_modify_subscription_option_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_modify_subscription_option_type" */
export type offer_modify_subscription_option_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_modify_subscription_option_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_modify_subscription_option_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_modify_subscription_option_type" */
export enum offer_modify_subscription_option_type_update_column {
  /** column name */
  value = 'value'
}

export type offer_modify_subscription_option_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_modify_subscription_option_type_set_input>;
  /** filter the rows which have to be updated */
  where: offer_modify_subscription_option_type_bool_exp;
};

/** update columns of table "offer_modify_subscription_option" */
export enum offer_modify_subscription_option_update_column {
  /** column name */
  custom_plan_id = 'custom_plan_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  type = 'type'
}

export type offer_modify_subscription_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_modify_subscription_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_modify_subscription_option_set_input>;
  /** filter the rows which have to be updated */
  where: offer_modify_subscription_option_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_modify_subscription_option_var_pop_fields = {
  __typename: 'offer_modify_subscription_option_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_modify_subscription_option_var_samp_fields = {
  __typename: 'offer_modify_subscription_option_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_modify_subscription_option_variance_fields = {
  __typename: 'offer_modify_subscription_option_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_modify_subscription_option" */
export type offer_modify_subscription_option_variance_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_modify_subscription". */
export type offer_modify_subscription_order_by = {
  modify_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_modify_subscription_options_aggregate?: InputMaybe<offer_modify_subscription_option_aggregate_order_by>;
  prorate?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_modify_subscription */
export type offer_modify_subscription_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_modify_subscription" */
export enum offer_modify_subscription_select_column {
  /** column name */
  modify_at = 'modify_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  prorate = 'prorate'
}

/** input type for updating data in table "offer_modify_subscription" */
export type offer_modify_subscription_set_input = {
  modify_at?: InputMaybe<offer_modify_subscription_modify_at_enum>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type offer_modify_subscription_stddev_fields = {
  __typename: 'offer_modify_subscription_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_modify_subscription_stddev_pop_fields = {
  __typename: 'offer_modify_subscription_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_modify_subscription_stddev_samp_fields = {
  __typename: 'offer_modify_subscription_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_modify_subscription" */
export type offer_modify_subscription_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_modify_subscription_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_modify_subscription_stream_cursor_value_input = {
  modify_at?: InputMaybe<offer_modify_subscription_modify_at_enum>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  prorate?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type offer_modify_subscription_sum_fields = {
  __typename: 'offer_modify_subscription_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_modify_subscription" */
export enum offer_modify_subscription_update_column {
  /** column name */
  modify_at = 'modify_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  prorate = 'prorate'
}

export type offer_modify_subscription_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_modify_subscription_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_modify_subscription_set_input>;
  /** filter the rows which have to be updated */
  where: offer_modify_subscription_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_modify_subscription_var_pop_fields = {
  __typename: 'offer_modify_subscription_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_modify_subscription_var_samp_fields = {
  __typename: 'offer_modify_subscription_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_modify_subscription_variance_fields = {
  __typename: 'offer_modify_subscription_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** response of any mutation on the table "offer" */
export type offer_mutation_response = {
  __typename: 'offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer>;
};

/** input type for inserting object relation for remote table "offer" */
export type offer_obj_rel_insert_input = {
  data: offer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_on_conflict>;
};

/** on_conflict condition type for table "offer" */
export type offer_on_conflict = {
  constraint: offer_constraint;
  update_columns?: Array<offer_update_column>;
  where?: InputMaybe<offer_bool_exp>;
};

/** Ordering options when selecting data from "offer". */
export type offer_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  archived_at?: InputMaybe<order_by>;
  button_text_translation?: InputMaybe<translation_order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation?: InputMaybe<translation_order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation?: InputMaybe<translation_order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation?: InputMaybe<translation_order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  confirmation_enabled?: InputMaybe<order_by>;
  content_translation?: InputMaybe<translation_order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  goal?: InputMaybe<order_by>;
  headline_translation?: InputMaybe<translation_order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  metadata?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_change_plan?: InputMaybe<offer_change_plan_order_by>;
  offer_coupon?: InputMaybe<offer_coupon_order_by>;
  offer_coupons_aggregate?: InputMaybe<offer_coupon_aggregate_order_by>;
  offer_custom?: InputMaybe<offer_custom_order_by>;
  offer_downgrade?: InputMaybe<offer_downgrade_order_by>;
  offer_group_offers_aggregate?: InputMaybe<offer_group_offer_aggregate_order_by>;
  offer_modify_subscription?: InputMaybe<offer_modify_subscription_order_by>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_order_by>;
  offer_product_swap?: InputMaybe<offer_product_swap_order_by>;
  offer_reschedule_order?: InputMaybe<offer_reschedule_order_order_by>;
  offer_rule_rule_offers_aggregate?: InputMaybe<offer_rule_rule_offer_aggregate_order_by>;
  offer_rule_version_offers_aggregate?: InputMaybe<offer_rule_version_offer_aggregate_order_by>;
  offer_tags_aggregate?: InputMaybe<offer_tag_aggregate_order_by>;
  offer_test_offers_aggregate?: InputMaybe<offer_test_offer_aggregate_order_by>;
  offer_tests_aggregate?: InputMaybe<offer_test_aggregate_order_by>;
  offer_timer?: InputMaybe<offer_timer_order_by>;
  offer_trial_extension?: InputMaybe<offer_trial_extension_order_by>;
  offer_type?: InputMaybe<offer_type_order_by>;
  offer_upgrade?: InputMaybe<offer_upgrade_order_by>;
  override_active_offer?: InputMaybe<order_by>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_order_by>;
  show_banner?: InputMaybe<order_by>;
  style?: InputMaybe<order_by>;
  subscriber_campaign_offers_aggregate?: InputMaybe<subscriber_campaign_offer_aggregate_order_by>;
  subscriber_campaign_overrides_aggregate?: InputMaybe<subscriber_campaign_override_aggregate_order_by>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_order_by>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_order_by>;
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_order_by>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
  subscriber_log_offers_aggregate?: InputMaybe<subscriber_log_offer_aggregate_order_by>;
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_order_by>;
  tag_ids?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_pause_subscription" */
export type offer_pause_subscription = {
  __typename: 'offer_pause_subscription';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_pause_subscription_options: Array<offer_pause_subscription_option>;
  /** An aggregate relationship */
  offer_pause_subscription_options_aggregate: offer_pause_subscription_option_aggregate;
  pause_at: offer_pause_subscription_pause_at_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_pause_subscription" */
export type offer_pause_subscriptionoffer_pause_subscription_optionsArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


/** columns and relationships of "offer_pause_subscription" */
export type offer_pause_subscriptionoffer_pause_subscription_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};

/** aggregated selection of "offer_pause_subscription" */
export type offer_pause_subscription_aggregate = {
  __typename: 'offer_pause_subscription_aggregate';
  aggregate?: Maybe<offer_pause_subscription_aggregate_fields>;
  nodes: Array<offer_pause_subscription>;
};

/** aggregate fields of "offer_pause_subscription" */
export type offer_pause_subscription_aggregate_fields = {
  __typename: 'offer_pause_subscription_aggregate_fields';
  avg?: Maybe<offer_pause_subscription_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_pause_subscription_max_fields>;
  min?: Maybe<offer_pause_subscription_min_fields>;
  stddev?: Maybe<offer_pause_subscription_stddev_fields>;
  stddev_pop?: Maybe<offer_pause_subscription_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_pause_subscription_stddev_samp_fields>;
  sum?: Maybe<offer_pause_subscription_sum_fields>;
  var_pop?: Maybe<offer_pause_subscription_var_pop_fields>;
  var_samp?: Maybe<offer_pause_subscription_var_samp_fields>;
  variance?: Maybe<offer_pause_subscription_variance_fields>;
};


/** aggregate fields of "offer_pause_subscription" */
export type offer_pause_subscription_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_pause_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_pause_subscription_avg_fields = {
  __typename: 'offer_pause_subscription_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_pause_subscription". All fields are combined with a logical 'AND'. */
export type offer_pause_subscription_bool_exp = {
  _and?: InputMaybe<Array<offer_pause_subscription_bool_exp>>;
  _not?: InputMaybe<offer_pause_subscription_bool_exp>;
  _or?: InputMaybe<Array<offer_pause_subscription_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_pause_subscription_options?: InputMaybe<offer_pause_subscription_option_bool_exp>;
  offer_pause_subscription_options_aggregate?: InputMaybe<offer_pause_subscription_option_aggregate_bool_exp>;
  pause_at?: InputMaybe<offer_pause_subscription_pause_at_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_pause_subscription" */
export enum offer_pause_subscription_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_pause_subscription_pkey = 'offer_pause_subscription_pkey'
}

/** input type for incrementing numeric columns in table "offer_pause_subscription" */
export type offer_pause_subscription_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_pause_subscription" */
export type offer_pause_subscription_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_options?: InputMaybe<offer_pause_subscription_option_arr_rel_insert_input>;
  pause_at?: InputMaybe<offer_pause_subscription_pause_at_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval = {
  __typename: 'offer_pause_subscription_interval';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_aggregate = {
  __typename: 'offer_pause_subscription_interval_aggregate';
  aggregate?: Maybe<offer_pause_subscription_interval_aggregate_fields>;
  nodes: Array<offer_pause_subscription_interval>;
};

/** aggregate fields of "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_aggregate_fields = {
  __typename: 'offer_pause_subscription_interval_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_pause_subscription_interval_max_fields>;
  min?: Maybe<offer_pause_subscription_interval_min_fields>;
};


/** aggregate fields of "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_pause_subscription_interval_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_pause_subscription_interval". All fields are combined with a logical 'AND'. */
export type offer_pause_subscription_interval_bool_exp = {
  _and?: InputMaybe<Array<offer_pause_subscription_interval_bool_exp>>;
  _not?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
  _or?: InputMaybe<Array<offer_pause_subscription_interval_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_pause_subscription_interval" */
export enum offer_pause_subscription_interval_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_pause_subscription_interval_pkey = 'offer_pause_subscription_interval_pkey'
}

export enum offer_pause_subscription_interval_enum {
  billing_cycle = 'billing_cycle',
  day = 'day',
  indefinitely = 'indefinitely',
  month = 'month',
  week = 'week'
}

/** Boolean expression to compare columns of type "offer_pause_subscription_interval_enum". All fields are combined with logical 'AND'. */
export type offer_pause_subscription_interval_enum_comparison_exp = {
  _eq?: InputMaybe<offer_pause_subscription_interval_enum>;
  _in?: InputMaybe<Array<offer_pause_subscription_interval_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_pause_subscription_interval_enum>;
  _nin?: InputMaybe<Array<offer_pause_subscription_interval_enum>>;
};

/** input type for inserting data into table "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_pause_subscription_interval_max_fields = {
  __typename: 'offer_pause_subscription_interval_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_pause_subscription_interval_min_fields = {
  __typename: 'offer_pause_subscription_interval_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_mutation_response = {
  __typename: 'offer_pause_subscription_interval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_pause_subscription_interval>;
};

/** on_conflict condition type for table "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_on_conflict = {
  constraint: offer_pause_subscription_interval_constraint;
  update_columns?: Array<offer_pause_subscription_interval_update_column>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};

/** Ordering options when selecting data from "offer_pause_subscription_interval". */
export type offer_pause_subscription_interval_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_pause_subscription_interval */
export type offer_pause_subscription_interval_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_pause_subscription_interval" */
export enum offer_pause_subscription_interval_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_pause_subscription_interval" */
export type offer_pause_subscription_interval_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_pause_subscription_interval_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_pause_subscription_interval_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_pause_subscription_interval" */
export enum offer_pause_subscription_interval_update_column {
  /** column name */
  value = 'value'
}

export type offer_pause_subscription_interval_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_pause_subscription_interval_set_input>;
  /** filter the rows which have to be updated */
  where: offer_pause_subscription_interval_bool_exp;
};

/** aggregate max on columns */
export type offer_pause_subscription_max_fields = {
  __typename: 'offer_pause_subscription_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_pause_subscription_min_fields = {
  __typename: 'offer_pause_subscription_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_pause_subscription" */
export type offer_pause_subscription_mutation_response = {
  __typename: 'offer_pause_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_pause_subscription>;
};

/** input type for inserting object relation for remote table "offer_pause_subscription" */
export type offer_pause_subscription_obj_rel_insert_input = {
  data: offer_pause_subscription_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_pause_subscription_on_conflict>;
};

/** on_conflict condition type for table "offer_pause_subscription" */
export type offer_pause_subscription_on_conflict = {
  constraint: offer_pause_subscription_constraint;
  update_columns?: Array<offer_pause_subscription_update_column>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};

/** columns and relationships of "offer_pause_subscription_option" */
export type offer_pause_subscription_option = {
  __typename: 'offer_pause_subscription_option';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  interval?: Maybe<offer_pause_subscription_interval_enum>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  metadata: Scalars['jsonb']['output'];
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_pause_subscription: offer_pause_subscription;
  position: Scalars['Int']['output'];
  /** An object relationship */
  text_translation?: Maybe<translation>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
  type: offer_pause_subscription_option_type_enum;
};


/** columns and relationships of "offer_pause_subscription_option" */
export type offer_pause_subscription_optionmetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "offer_pause_subscription_option" */
export type offer_pause_subscription_option_aggregate = {
  __typename: 'offer_pause_subscription_option_aggregate';
  aggregate?: Maybe<offer_pause_subscription_option_aggregate_fields>;
  nodes: Array<offer_pause_subscription_option>;
};

export type offer_pause_subscription_option_aggregate_bool_exp = {
  count?: InputMaybe<offer_pause_subscription_option_aggregate_bool_exp_count>;
};

export type offer_pause_subscription_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_pause_subscription_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_pause_subscription_option" */
export type offer_pause_subscription_option_aggregate_fields = {
  __typename: 'offer_pause_subscription_option_aggregate_fields';
  avg?: Maybe<offer_pause_subscription_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_pause_subscription_option_max_fields>;
  min?: Maybe<offer_pause_subscription_option_min_fields>;
  stddev?: Maybe<offer_pause_subscription_option_stddev_fields>;
  stddev_pop?: Maybe<offer_pause_subscription_option_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_pause_subscription_option_stddev_samp_fields>;
  sum?: Maybe<offer_pause_subscription_option_sum_fields>;
  var_pop?: Maybe<offer_pause_subscription_option_var_pop_fields>;
  var_samp?: Maybe<offer_pause_subscription_option_var_samp_fields>;
  variance?: Maybe<offer_pause_subscription_option_variance_fields>;
};


/** aggregate fields of "offer_pause_subscription_option" */
export type offer_pause_subscription_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_aggregate_order_by = {
  avg?: InputMaybe<offer_pause_subscription_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_pause_subscription_option_max_order_by>;
  min?: InputMaybe<offer_pause_subscription_option_min_order_by>;
  stddev?: InputMaybe<offer_pause_subscription_option_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_pause_subscription_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_pause_subscription_option_stddev_samp_order_by>;
  sum?: InputMaybe<offer_pause_subscription_option_sum_order_by>;
  var_pop?: InputMaybe<offer_pause_subscription_option_var_pop_order_by>;
  var_samp?: InputMaybe<offer_pause_subscription_option_var_samp_order_by>;
  variance?: InputMaybe<offer_pause_subscription_option_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_pause_subscription_option_append_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_arr_rel_insert_input = {
  data: Array<offer_pause_subscription_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_pause_subscription_option_on_conflict>;
};

/** aggregate avg on columns */
export type offer_pause_subscription_option_avg_fields = {
  __typename: 'offer_pause_subscription_option_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_avg_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_pause_subscription_option". All fields are combined with a logical 'AND'. */
export type offer_pause_subscription_option_bool_exp = {
  _and?: InputMaybe<Array<offer_pause_subscription_option_bool_exp>>;
  _not?: InputMaybe<offer_pause_subscription_option_bool_exp>;
  _or?: InputMaybe<Array<offer_pause_subscription_option_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  interval?: InputMaybe<offer_pause_subscription_interval_enum_comparison_exp>;
  interval_count?: InputMaybe<Int_comparison_exp>;
  metadata?: InputMaybe<jsonb_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_bool_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  text_translation?: InputMaybe<translation_bool_exp>;
  text_translation_id?: InputMaybe<Int_comparison_exp>;
  type?: InputMaybe<offer_pause_subscription_option_type_enum_comparison_exp>;
};

/** unique or primary key constraints on table "offer_pause_subscription_option" */
export enum offer_pause_subscription_option_constraint {
  /** unique or primary key constraint on columns "offer_id", "interval_count", "interval" */
  offer_pause_subscription_opti_offer_id_interval_interval_co_key = 'offer_pause_subscription_opti_offer_id_interval_interval_co_key',
  /** unique or primary key constraint on columns "id" */
  offer_pause_subscription_option_pkey = 'offer_pause_subscription_option_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_pause_subscription_option_delete_at_path_input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_pause_subscription_option_delete_elem_input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_pause_subscription_option_delete_key_input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<offer_pause_subscription_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_obj_rel_insert_input>;
  position?: InputMaybe<Scalars['Int']['input']>;
  text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<offer_pause_subscription_option_type_enum>;
};

/** aggregate max on columns */
export type offer_pause_subscription_option_max_fields = {
  __typename: 'offer_pause_subscription_option_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_pause_subscription_option_min_fields = {
  __typename: 'offer_pause_subscription_option_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_mutation_response = {
  __typename: 'offer_pause_subscription_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_pause_subscription_option>;
};

/** input type for inserting object relation for remote table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_obj_rel_insert_input = {
  data: offer_pause_subscription_option_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_pause_subscription_option_on_conflict>;
};

/** on_conflict condition type for table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_on_conflict = {
  constraint: offer_pause_subscription_option_constraint;
  update_columns?: Array<offer_pause_subscription_option_update_column>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};

/** Ordering options when selecting data from "offer_pause_subscription_option". */
export type offer_pause_subscription_option_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  interval?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  metadata?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription?: InputMaybe<offer_pause_subscription_order_by>;
  position?: InputMaybe<order_by>;
  text_translation?: InputMaybe<translation_order_by>;
  text_translation_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_pause_subscription_option */
export type offer_pause_subscription_option_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_pause_subscription_option_prepend_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_pause_subscription_option" */
export enum offer_pause_subscription_option_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  metadata = 'metadata',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  position = 'position',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<offer_pause_subscription_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<offer_pause_subscription_option_type_enum>;
};

/** aggregate stddev on columns */
export type offer_pause_subscription_option_stddev_fields = {
  __typename: 'offer_pause_subscription_option_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_stddev_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_pause_subscription_option_stddev_pop_fields = {
  __typename: 'offer_pause_subscription_option_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_pause_subscription_option_stddev_samp_fields = {
  __typename: 'offer_pause_subscription_option_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_pause_subscription_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_pause_subscription_option_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interval?: InputMaybe<offer_pause_subscription_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<offer_pause_subscription_option_type_enum>;
};

/** aggregate sum on columns */
export type offer_pause_subscription_option_sum_fields = {
  __typename: 'offer_pause_subscription_option_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_sum_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type = {
  __typename: 'offer_pause_subscription_option_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_aggregate = {
  __typename: 'offer_pause_subscription_option_type_aggregate';
  aggregate?: Maybe<offer_pause_subscription_option_type_aggregate_fields>;
  nodes: Array<offer_pause_subscription_option_type>;
};

/** aggregate fields of "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_aggregate_fields = {
  __typename: 'offer_pause_subscription_option_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_pause_subscription_option_type_max_fields>;
  min?: Maybe<offer_pause_subscription_option_type_min_fields>;
};


/** aggregate fields of "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_pause_subscription_option_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_pause_subscription_option_type". All fields are combined with a logical 'AND'. */
export type offer_pause_subscription_option_type_bool_exp = {
  _and?: InputMaybe<Array<offer_pause_subscription_option_type_bool_exp>>;
  _not?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
  _or?: InputMaybe<Array<offer_pause_subscription_option_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_pause_subscription_option_type" */
export enum offer_pause_subscription_option_type_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_pause_subscription_option_type_pkey = 'offer_pause_subscription_option_type_pkey'
}

export enum offer_pause_subscription_option_type_enum {
  date = 'date',
  interval = 'interval'
}

/** Boolean expression to compare columns of type "offer_pause_subscription_option_type_enum". All fields are combined with logical 'AND'. */
export type offer_pause_subscription_option_type_enum_comparison_exp = {
  _eq?: InputMaybe<offer_pause_subscription_option_type_enum>;
  _in?: InputMaybe<Array<offer_pause_subscription_option_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_pause_subscription_option_type_enum>;
  _nin?: InputMaybe<Array<offer_pause_subscription_option_type_enum>>;
};

/** input type for inserting data into table "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_pause_subscription_option_type_max_fields = {
  __typename: 'offer_pause_subscription_option_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_pause_subscription_option_type_min_fields = {
  __typename: 'offer_pause_subscription_option_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_mutation_response = {
  __typename: 'offer_pause_subscription_option_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_pause_subscription_option_type>;
};

/** on_conflict condition type for table "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_on_conflict = {
  constraint: offer_pause_subscription_option_type_constraint;
  update_columns?: Array<offer_pause_subscription_option_type_update_column>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};

/** Ordering options when selecting data from "offer_pause_subscription_option_type". */
export type offer_pause_subscription_option_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_pause_subscription_option_type */
export type offer_pause_subscription_option_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_pause_subscription_option_type" */
export enum offer_pause_subscription_option_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_pause_subscription_option_type" */
export type offer_pause_subscription_option_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_pause_subscription_option_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_pause_subscription_option_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_pause_subscription_option_type" */
export enum offer_pause_subscription_option_type_update_column {
  /** column name */
  value = 'value'
}

export type offer_pause_subscription_option_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_pause_subscription_option_type_set_input>;
  /** filter the rows which have to be updated */
  where: offer_pause_subscription_option_type_bool_exp;
};

/** update columns of table "offer_pause_subscription_option" */
export enum offer_pause_subscription_option_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  metadata = 'metadata',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  position = 'position',
  /** column name */
  text_translation_id = 'text_translation_id',
  /** column name */
  type = 'type'
}

export type offer_pause_subscription_option_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_pause_subscription_option_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_pause_subscription_option_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_pause_subscription_option_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_pause_subscription_option_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_pause_subscription_option_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_pause_subscription_option_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_pause_subscription_option_set_input>;
  /** filter the rows which have to be updated */
  where: offer_pause_subscription_option_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_pause_subscription_option_var_pop_fields = {
  __typename: 'offer_pause_subscription_option_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_pause_subscription_option_var_samp_fields = {
  __typename: 'offer_pause_subscription_option_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_pause_subscription_option_variance_fields = {
  __typename: 'offer_pause_subscription_option_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  interval_count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_pause_subscription_option" */
export type offer_pause_subscription_option_variance_order_by = {
  id?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  text_translation_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_pause_subscription". */
export type offer_pause_subscription_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_options_aggregate?: InputMaybe<offer_pause_subscription_option_aggregate_order_by>;
  pause_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at = {
  __typename: 'offer_pause_subscription_pause_at';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_aggregate = {
  __typename: 'offer_pause_subscription_pause_at_aggregate';
  aggregate?: Maybe<offer_pause_subscription_pause_at_aggregate_fields>;
  nodes: Array<offer_pause_subscription_pause_at>;
};

/** aggregate fields of "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_aggregate_fields = {
  __typename: 'offer_pause_subscription_pause_at_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_pause_subscription_pause_at_max_fields>;
  min?: Maybe<offer_pause_subscription_pause_at_min_fields>;
};


/** aggregate fields of "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_pause_subscription_pause_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_pause_subscription_pause_at". All fields are combined with a logical 'AND'. */
export type offer_pause_subscription_pause_at_bool_exp = {
  _and?: InputMaybe<Array<offer_pause_subscription_pause_at_bool_exp>>;
  _not?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
  _or?: InputMaybe<Array<offer_pause_subscription_pause_at_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_pause_subscription_pause_at" */
export enum offer_pause_subscription_pause_at_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_pause_subscription_pause_at_pkey = 'offer_pause_subscription_pause_at_pkey'
}

export enum offer_pause_subscription_pause_at_enum {
  immediately = 'immediately',
  period_end = 'period_end'
}

/** Boolean expression to compare columns of type "offer_pause_subscription_pause_at_enum". All fields are combined with logical 'AND'. */
export type offer_pause_subscription_pause_at_enum_comparison_exp = {
  _eq?: InputMaybe<offer_pause_subscription_pause_at_enum>;
  _in?: InputMaybe<Array<offer_pause_subscription_pause_at_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_pause_subscription_pause_at_enum>;
  _nin?: InputMaybe<Array<offer_pause_subscription_pause_at_enum>>;
};

/** input type for inserting data into table "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_pause_subscription_pause_at_max_fields = {
  __typename: 'offer_pause_subscription_pause_at_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_pause_subscription_pause_at_min_fields = {
  __typename: 'offer_pause_subscription_pause_at_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_mutation_response = {
  __typename: 'offer_pause_subscription_pause_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_pause_subscription_pause_at>;
};

/** on_conflict condition type for table "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_on_conflict = {
  constraint: offer_pause_subscription_pause_at_constraint;
  update_columns?: Array<offer_pause_subscription_pause_at_update_column>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};

/** Ordering options when selecting data from "offer_pause_subscription_pause_at". */
export type offer_pause_subscription_pause_at_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_pause_subscription_pause_at */
export type offer_pause_subscription_pause_at_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_pause_subscription_pause_at" */
export enum offer_pause_subscription_pause_at_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_pause_subscription_pause_at" */
export type offer_pause_subscription_pause_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_pause_subscription_pause_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_pause_subscription_pause_at_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_pause_subscription_pause_at" */
export enum offer_pause_subscription_pause_at_update_column {
  /** column name */
  value = 'value'
}

export type offer_pause_subscription_pause_at_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_pause_subscription_pause_at_set_input>;
  /** filter the rows which have to be updated */
  where: offer_pause_subscription_pause_at_bool_exp;
};

/** primary key columns input for table: offer_pause_subscription */
export type offer_pause_subscription_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_pause_subscription" */
export enum offer_pause_subscription_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_pause_subscription" */
export type offer_pause_subscription_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<offer_pause_subscription_pause_at_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_pause_subscription_stddev_fields = {
  __typename: 'offer_pause_subscription_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_pause_subscription_stddev_pop_fields = {
  __typename: 'offer_pause_subscription_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_pause_subscription_stddev_samp_fields = {
  __typename: 'offer_pause_subscription_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_pause_subscription" */
export type offer_pause_subscription_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_pause_subscription_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_pause_subscription_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<offer_pause_subscription_pause_at_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_pause_subscription_sum_fields = {
  __typename: 'offer_pause_subscription_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_pause_subscription" */
export enum offer_pause_subscription_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_pause_subscription_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_pause_subscription_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_pause_subscription_set_input>;
  /** filter the rows which have to be updated */
  where: offer_pause_subscription_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_pause_subscription_var_pop_fields = {
  __typename: 'offer_pause_subscription_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_pause_subscription_var_samp_fields = {
  __typename: 'offer_pause_subscription_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_pause_subscription_variance_fields = {
  __typename: 'offer_pause_subscription_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** primary key columns input for table: offer */
export type offer_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_prepend_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "offer_product_swap" */
export type offer_product_swap = {
  __typename: 'offer_product_swap';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_product_swap_platform_ecommerce_products: Array<offer_product_swap_platform_ecommerce_product>;
  /** An aggregate relationship */
  offer_product_swap_platform_ecommerce_products_aggregate: offer_product_swap_platform_ecommerce_product_aggregate;
  specific_products: Scalars['Boolean']['output'];
};


/** columns and relationships of "offer_product_swap" */
export type offer_product_swapoffer_product_swap_platform_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


/** columns and relationships of "offer_product_swap" */
export type offer_product_swapoffer_product_swap_platform_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};

/** aggregated selection of "offer_product_swap" */
export type offer_product_swap_aggregate = {
  __typename: 'offer_product_swap_aggregate';
  aggregate?: Maybe<offer_product_swap_aggregate_fields>;
  nodes: Array<offer_product_swap>;
};

/** aggregate fields of "offer_product_swap" */
export type offer_product_swap_aggregate_fields = {
  __typename: 'offer_product_swap_aggregate_fields';
  avg?: Maybe<offer_product_swap_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_product_swap_max_fields>;
  min?: Maybe<offer_product_swap_min_fields>;
  stddev?: Maybe<offer_product_swap_stddev_fields>;
  stddev_pop?: Maybe<offer_product_swap_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_product_swap_stddev_samp_fields>;
  sum?: Maybe<offer_product_swap_sum_fields>;
  var_pop?: Maybe<offer_product_swap_var_pop_fields>;
  var_samp?: Maybe<offer_product_swap_var_samp_fields>;
  variance?: Maybe<offer_product_swap_variance_fields>;
};


/** aggregate fields of "offer_product_swap" */
export type offer_product_swap_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_product_swap_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_product_swap_avg_fields = {
  __typename: 'offer_product_swap_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_product_swap". All fields are combined with a logical 'AND'. */
export type offer_product_swap_bool_exp = {
  _and?: InputMaybe<Array<offer_product_swap_bool_exp>>;
  _not?: InputMaybe<offer_product_swap_bool_exp>;
  _or?: InputMaybe<Array<offer_product_swap_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_product_swap_platform_ecommerce_products?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
  offer_product_swap_platform_ecommerce_products_aggregate?: InputMaybe<offer_product_swap_platform_ecommerce_product_aggregate_bool_exp>;
  specific_products?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "offer_product_swap" */
export enum offer_product_swap_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_product_swap_pkey = 'offer_product_swap_pkey'
}

/** input type for incrementing numeric columns in table "offer_product_swap" */
export type offer_product_swap_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_product_swap" */
export type offer_product_swap_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_product_swap_platform_ecommerce_products?: InputMaybe<offer_product_swap_platform_ecommerce_product_arr_rel_insert_input>;
  specific_products?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type offer_product_swap_max_fields = {
  __typename: 'offer_product_swap_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_product_swap_min_fields = {
  __typename: 'offer_product_swap_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "offer_product_swap" */
export type offer_product_swap_mutation_response = {
  __typename: 'offer_product_swap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_product_swap>;
};

/** input type for inserting object relation for remote table "offer_product_swap" */
export type offer_product_swap_obj_rel_insert_input = {
  data: offer_product_swap_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_product_swap_on_conflict>;
};

/** on_conflict condition type for table "offer_product_swap" */
export type offer_product_swap_on_conflict = {
  constraint: offer_product_swap_constraint;
  update_columns?: Array<offer_product_swap_update_column>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};

/** Ordering options when selecting data from "offer_product_swap". */
export type offer_product_swap_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_product_swap_platform_ecommerce_products_aggregate?: InputMaybe<offer_product_swap_platform_ecommerce_product_aggregate_order_by>;
  specific_products?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_product_swap */
export type offer_product_swap_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** columns and relationships of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product = {
  __typename: 'offer_product_swap_platform_ecommerce_product';
  id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_product_swap?: Maybe<offer_product_swap>;
  /** An array relationship */
  offer_product_swap_platform_ecommerce_product_platform_variants: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** An aggregate relationship */
  offer_product_swap_platform_ecommerce_product_platform_variants_aggregate: offer_product_swap_platform_ecommerce_product_platform_variant_aggregate;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_ecommerce_product?: Maybe<platform_ecommerce_product>;
  platform_ecommerce_product_id: Scalars['String']['output'];
};


/** columns and relationships of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_productoffer_product_swap_platform_ecommerce_product_platform_variantsArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


/** columns and relationships of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_productoffer_product_swap_platform_ecommerce_product_platform_variants_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};

/** aggregated selection of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_aggregate = {
  __typename: 'offer_product_swap_platform_ecommerce_product_aggregate';
  aggregate?: Maybe<offer_product_swap_platform_ecommerce_product_aggregate_fields>;
  nodes: Array<offer_product_swap_platform_ecommerce_product>;
};

export type offer_product_swap_platform_ecommerce_product_aggregate_bool_exp = {
  count?: InputMaybe<offer_product_swap_platform_ecommerce_product_aggregate_bool_exp_count>;
};

export type offer_product_swap_platform_ecommerce_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_aggregate_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_aggregate_fields';
  avg?: Maybe<offer_product_swap_platform_ecommerce_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_product_swap_platform_ecommerce_product_max_fields>;
  min?: Maybe<offer_product_swap_platform_ecommerce_product_min_fields>;
  stddev?: Maybe<offer_product_swap_platform_ecommerce_product_stddev_fields>;
  stddev_pop?: Maybe<offer_product_swap_platform_ecommerce_product_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_product_swap_platform_ecommerce_product_stddev_samp_fields>;
  sum?: Maybe<offer_product_swap_platform_ecommerce_product_sum_fields>;
  var_pop?: Maybe<offer_product_swap_platform_ecommerce_product_var_pop_fields>;
  var_samp?: Maybe<offer_product_swap_platform_ecommerce_product_var_samp_fields>;
  variance?: Maybe<offer_product_swap_platform_ecommerce_product_variance_fields>;
};


/** aggregate fields of "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_aggregate_order_by = {
  avg?: InputMaybe<offer_product_swap_platform_ecommerce_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_product_swap_platform_ecommerce_product_max_order_by>;
  min?: InputMaybe<offer_product_swap_platform_ecommerce_product_min_order_by>;
  stddev?: InputMaybe<offer_product_swap_platform_ecommerce_product_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_product_swap_platform_ecommerce_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_product_swap_platform_ecommerce_product_stddev_samp_order_by>;
  sum?: InputMaybe<offer_product_swap_platform_ecommerce_product_sum_order_by>;
  var_pop?: InputMaybe<offer_product_swap_platform_ecommerce_product_var_pop_order_by>;
  var_samp?: InputMaybe<offer_product_swap_platform_ecommerce_product_var_samp_order_by>;
  variance?: InputMaybe<offer_product_swap_platform_ecommerce_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_arr_rel_insert_input = {
  data: Array<offer_product_swap_platform_ecommerce_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_on_conflict>;
};

/** aggregate avg on columns */
export type offer_product_swap_platform_ecommerce_product_avg_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_avg_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_product_swap_platform_ecommerce_product". All fields are combined with a logical 'AND'. */
export type offer_product_swap_platform_ecommerce_product_bool_exp = {
  _and?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_bool_exp>>;
  _not?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
  _or?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_product_swap?: InputMaybe<offer_product_swap_bool_exp>;
  offer_product_swap_platform_ecommerce_product_platform_variants?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
  offer_product_swap_platform_ecommerce_product_platform_variants_aggregate?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_bool_exp>;
  platform_ecommerce_product_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_product_swap_platform_ecommerce_product" */
export enum offer_product_swap_platform_ecommerce_product_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_product_swap_platform_plan_pkey = 'offer_product_swap_platform_plan_pkey'
}

/** input type for incrementing numeric columns in table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_product_swap?: InputMaybe<offer_product_swap_obj_rel_insert_input>;
  offer_product_swap_platform_ecommerce_product_platform_variants?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_arr_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_obj_rel_insert_input>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_product_swap_platform_ecommerce_product_max_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_max_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_product_swap_platform_ecommerce_product_min_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_min_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_mutation_response = {
  __typename: 'offer_product_swap_platform_ecommerce_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_product_swap_platform_ecommerce_product>;
};

/** input type for inserting object relation for remote table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_obj_rel_insert_input = {
  data: offer_product_swap_platform_ecommerce_product_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_on_conflict>;
};

/** on_conflict condition type for table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_on_conflict = {
  constraint: offer_product_swap_platform_ecommerce_product_constraint;
  update_columns?: Array<offer_product_swap_platform_ecommerce_product_update_column>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};

/** Ordering options when selecting data from "offer_product_swap_platform_ecommerce_product". */
export type offer_product_swap_platform_ecommerce_product_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_product_swap?: InputMaybe<offer_product_swap_order_by>;
  offer_product_swap_platform_ecommerce_product_platform_variants_aggregate?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_product_swap_platform_ecommerce_product */
export type offer_product_swap_platform_ecommerce_product_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant';
  /** An object relationship */
  offer_product_swap_platform_ecommerce_product?: Maybe<offer_product_swap_platform_ecommerce_product>;
  offer_product_swap_platform_ecommerce_product_id: Scalars['Int']['output'];
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_variant?: Maybe<platform_variant>;
  platform_variant_id: Scalars['String']['output'];
};

/** aggregated selection of "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_aggregate';
  aggregate?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_fields>;
  nodes: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
};

export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_bool_exp = {
  count?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_bool_exp_count>;
};

export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_fields';
  avg?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_max_fields>;
  min?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_min_fields>;
  stddev?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_fields>;
  stddev_pop?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_samp_fields>;
  sum?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_sum_fields>;
  var_pop?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_var_pop_fields>;
  var_samp?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_var_samp_fields>;
  variance?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant_variance_fields>;
};


/** aggregate fields of "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_aggregate_order_by = {
  avg?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_max_order_by>;
  min?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_min_order_by>;
  stddev?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_stddev_samp_order_by>;
  sum?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_sum_order_by>;
  var_pop?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_var_pop_order_by>;
  var_samp?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_var_samp_order_by>;
  variance?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_arr_rel_insert_input = {
  data: Array<offer_product_swap_platform_ecommerce_product_platform_variant_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_on_conflict>;
};

/** aggregate avg on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_avg_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_avg_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_avg_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_product_swap_platform_ecommerce_product_platform_variant". All fields are combined with a logical 'AND'. */
export type offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp = {
  _and?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>>;
  _not?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
  _or?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>>;
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_variant?: InputMaybe<platform_variant_bool_exp>;
  platform_variant_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export enum offer_product_swap_platform_ecommerce_product_platform_variant_constraint {
  /** unique or primary key constraint on columns "offer_product_swap_platform_ecommerce_product_id", "platform_variant_id", "platform_connection_id" */
  offer_product_swap_platform_plan_platform_variant_pkey = 'offer_product_swap_platform_plan_platform_variant_pkey'
}

/** input type for incrementing numeric columns in table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_inc_input = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_insert_input = {
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_obj_rel_insert_input>;
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant?: InputMaybe<platform_variant_obj_rel_insert_input>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_max_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_max_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_max_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_min_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_min_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_min_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
};

/** input type for inserting object relation for remote table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_obj_rel_insert_input = {
  data: offer_product_swap_platform_ecommerce_product_platform_variant_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_on_conflict>;
};

/** on_conflict condition type for table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_on_conflict = {
  constraint: offer_product_swap_platform_ecommerce_product_platform_variant_constraint;
  update_columns?: Array<offer_product_swap_platform_ecommerce_product_platform_variant_update_column>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};

/** Ordering options when selecting data from "offer_product_swap_platform_ecommerce_product_platform_variant". */
export type offer_product_swap_platform_ecommerce_product_platform_variant_order_by = {
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_order_by>;
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant?: InputMaybe<platform_variant_order_by>;
  platform_variant_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_product_swap_platform_ecommerce_product_platform_variant */
export type offer_product_swap_platform_ecommerce_product_platform_variant_pk_columns_input = {
  offer_product_swap_platform_ecommerce_product_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
};

/** select columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export enum offer_product_swap_platform_ecommerce_product_platform_variant_select_column {
  /** column name */
  offer_product_swap_platform_ecommerce_product_id = 'offer_product_swap_platform_ecommerce_product_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_variant_id = 'platform_variant_id'
}

/** input type for updating data in table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_set_input = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_stddev_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_pop_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_stddev_pop_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_pop_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_samp_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_stddev_samp_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stddev_samp_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_product_swap_platform_ecommerce_product_platform_variant_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_product_swap_platform_ecommerce_product_platform_variant_stream_cursor_value_input = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_sum_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_sum_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_sum_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export enum offer_product_swap_platform_ecommerce_product_platform_variant_update_column {
  /** column name */
  offer_product_swap_platform_ecommerce_product_id = 'offer_product_swap_platform_ecommerce_product_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_variant_id = 'platform_variant_id'
}

export type offer_product_swap_platform_ecommerce_product_platform_variant_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_set_input>;
  /** filter the rows which have to be updated */
  where: offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_var_pop_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_var_pop_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_var_pop_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_var_samp_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_var_samp_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_var_samp_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_product_swap_platform_ecommerce_product_platform_variant_variance_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant_variance_fields';
  offer_product_swap_platform_ecommerce_product_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_product_swap_platform_ecommerce_product_platform_variant" */
export type offer_product_swap_platform_ecommerce_product_platform_variant_variance_order_by = {
  offer_product_swap_platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_product_swap_platform_ecommerce_product" */
export enum offer_product_swap_platform_ecommerce_product_select_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id'
}

/** input type for updating data in table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type offer_product_swap_platform_ecommerce_product_stddev_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_stddev_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_product_swap_platform_ecommerce_product_stddev_pop_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_product_swap_platform_ecommerce_product_stddev_samp_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_product_swap_platform_ecommerce_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_product_swap_platform_ecommerce_product_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type offer_product_swap_platform_ecommerce_product_sum_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_sum_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_product_swap_platform_ecommerce_product" */
export enum offer_product_swap_platform_ecommerce_product_update_column {
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id'
}

export type offer_product_swap_platform_ecommerce_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_product_swap_platform_ecommerce_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_product_swap_platform_ecommerce_product_set_input>;
  /** filter the rows which have to be updated */
  where: offer_product_swap_platform_ecommerce_product_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_product_swap_platform_ecommerce_product_var_pop_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_product_swap_platform_ecommerce_product_var_samp_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_product_swap_platform_ecommerce_product_variance_fields = {
  __typename: 'offer_product_swap_platform_ecommerce_product_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_product_swap_platform_ecommerce_product" */
export type offer_product_swap_platform_ecommerce_product_variance_order_by = {
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_product_swap" */
export enum offer_product_swap_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  specific_products = 'specific_products'
}

/** input type for updating data in table "offer_product_swap" */
export type offer_product_swap_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  specific_products?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type offer_product_swap_stddev_fields = {
  __typename: 'offer_product_swap_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_product_swap_stddev_pop_fields = {
  __typename: 'offer_product_swap_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_product_swap_stddev_samp_fields = {
  __typename: 'offer_product_swap_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_product_swap" */
export type offer_product_swap_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_product_swap_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_product_swap_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  specific_products?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type offer_product_swap_sum_fields = {
  __typename: 'offer_product_swap_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_product_swap" */
export enum offer_product_swap_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  specific_products = 'specific_products'
}

export type offer_product_swap_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_product_swap_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_product_swap_set_input>;
  /** filter the rows which have to be updated */
  where: offer_product_swap_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_product_swap_var_pop_fields = {
  __typename: 'offer_product_swap_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_product_swap_var_samp_fields = {
  __typename: 'offer_product_swap_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_product_swap_variance_fields = {
  __typename: 'offer_product_swap_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_reschedule_order" */
export type offer_reschedule_order = {
  __typename: 'offer_reschedule_order';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  reschedule: Scalars['Boolean']['output'];
  /** An object relationship */
  reschedule_button_text_translation: translation;
  reschedule_button_text_translation_id: Scalars['Int']['output'];
  skip: Scalars['Boolean']['output'];
  /** An object relationship */
  skip_button_text_translation?: Maybe<translation>;
  skip_button_text_translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "offer_reschedule_order" */
export type offer_reschedule_order_aggregate = {
  __typename: 'offer_reschedule_order_aggregate';
  aggregate?: Maybe<offer_reschedule_order_aggregate_fields>;
  nodes: Array<offer_reschedule_order>;
};

/** aggregate fields of "offer_reschedule_order" */
export type offer_reschedule_order_aggregate_fields = {
  __typename: 'offer_reschedule_order_aggregate_fields';
  avg?: Maybe<offer_reschedule_order_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_reschedule_order_max_fields>;
  min?: Maybe<offer_reschedule_order_min_fields>;
  stddev?: Maybe<offer_reschedule_order_stddev_fields>;
  stddev_pop?: Maybe<offer_reschedule_order_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_reschedule_order_stddev_samp_fields>;
  sum?: Maybe<offer_reschedule_order_sum_fields>;
  var_pop?: Maybe<offer_reschedule_order_var_pop_fields>;
  var_samp?: Maybe<offer_reschedule_order_var_samp_fields>;
  variance?: Maybe<offer_reschedule_order_variance_fields>;
};


/** aggregate fields of "offer_reschedule_order" */
export type offer_reschedule_order_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_reschedule_order_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_reschedule_order_avg_fields = {
  __typename: 'offer_reschedule_order_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_reschedule_order". All fields are combined with a logical 'AND'. */
export type offer_reschedule_order_bool_exp = {
  _and?: InputMaybe<Array<offer_reschedule_order_bool_exp>>;
  _not?: InputMaybe<offer_reschedule_order_bool_exp>;
  _or?: InputMaybe<Array<offer_reschedule_order_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  reschedule?: InputMaybe<Boolean_comparison_exp>;
  reschedule_button_text_translation?: InputMaybe<translation_bool_exp>;
  reschedule_button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  skip?: InputMaybe<Boolean_comparison_exp>;
  skip_button_text_translation?: InputMaybe<translation_bool_exp>;
  skip_button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_reschedule_order" */
export enum offer_reschedule_order_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_reschedule_order_pkey = 'offer_reschedule_order_pkey'
}

/** input type for incrementing numeric columns in table "offer_reschedule_order" */
export type offer_reschedule_order_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reschedule_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  skip_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_reschedule_order" */
export type offer_reschedule_order_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reschedule?: InputMaybe<Scalars['Boolean']['input']>;
  reschedule_button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  reschedule_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Boolean']['input']>;
  skip_button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  skip_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_reschedule_order_max_fields = {
  __typename: 'offer_reschedule_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_reschedule_order_min_fields = {
  __typename: 'offer_reschedule_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_reschedule_order" */
export type offer_reschedule_order_mutation_response = {
  __typename: 'offer_reschedule_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_reschedule_order>;
};

/** input type for inserting object relation for remote table "offer_reschedule_order" */
export type offer_reschedule_order_obj_rel_insert_input = {
  data: offer_reschedule_order_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_reschedule_order_on_conflict>;
};

/** on_conflict condition type for table "offer_reschedule_order" */
export type offer_reschedule_order_on_conflict = {
  constraint: offer_reschedule_order_constraint;
  update_columns?: Array<offer_reschedule_order_update_column>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};

/** Ordering options when selecting data from "offer_reschedule_order". */
export type offer_reschedule_order_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  reschedule?: InputMaybe<order_by>;
  reschedule_button_text_translation?: InputMaybe<translation_order_by>;
  reschedule_button_text_translation_id?: InputMaybe<order_by>;
  skip?: InputMaybe<order_by>;
  skip_button_text_translation?: InputMaybe<translation_order_by>;
  skip_button_text_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_reschedule_order */
export type offer_reschedule_order_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_reschedule_order" */
export enum offer_reschedule_order_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reschedule = 'reschedule',
  /** column name */
  reschedule_button_text_translation_id = 'reschedule_button_text_translation_id',
  /** column name */
  skip = 'skip',
  /** column name */
  skip_button_text_translation_id = 'skip_button_text_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_reschedule_order" */
export type offer_reschedule_order_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reschedule?: InputMaybe<Scalars['Boolean']['input']>;
  reschedule_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Boolean']['input']>;
  skip_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_reschedule_order_stddev_fields = {
  __typename: 'offer_reschedule_order_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_reschedule_order_stddev_pop_fields = {
  __typename: 'offer_reschedule_order_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_reschedule_order_stddev_samp_fields = {
  __typename: 'offer_reschedule_order_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_reschedule_order" */
export type offer_reschedule_order_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_reschedule_order_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_reschedule_order_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reschedule?: InputMaybe<Scalars['Boolean']['input']>;
  reschedule_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Boolean']['input']>;
  skip_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_reschedule_order_sum_fields = {
  __typename: 'offer_reschedule_order_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_reschedule_order" */
export enum offer_reschedule_order_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reschedule = 'reschedule',
  /** column name */
  reschedule_button_text_translation_id = 'reschedule_button_text_translation_id',
  /** column name */
  skip = 'skip',
  /** column name */
  skip_button_text_translation_id = 'skip_button_text_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_reschedule_order_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_reschedule_order_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_reschedule_order_set_input>;
  /** filter the rows which have to be updated */
  where: offer_reschedule_order_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_reschedule_order_var_pop_fields = {
  __typename: 'offer_reschedule_order_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_reschedule_order_var_samp_fields = {
  __typename: 'offer_reschedule_order_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_reschedule_order_variance_fields = {
  __typename: 'offer_reschedule_order_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  reschedule_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  skip_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_rule" */
export type offer_rule = {
  __typename: 'offer_rule';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<offer_rule_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  offer_rule_group_version_offer_rules: Array<offer_rule_group_version_offer_rule>;
  /** An aggregate relationship */
  offer_rule_group_version_offer_rules_aggregate: offer_rule_group_version_offer_rule_aggregate;
  /** An object relationship */
  published_version?: Maybe<offer_rule_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_rule" */
export type offer_ruleoffer_rule_group_version_offer_rulesArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


/** columns and relationships of "offer_rule" */
export type offer_ruleoffer_rule_group_version_offer_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};

/** aggregated selection of "offer_rule" */
export type offer_rule_aggregate = {
  __typename: 'offer_rule_aggregate';
  aggregate?: Maybe<offer_rule_aggregate_fields>;
  nodes: Array<offer_rule>;
};

export type offer_rule_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_aggregate_bool_exp_count>;
};

export type offer_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule" */
export type offer_rule_aggregate_fields = {
  __typename: 'offer_rule_aggregate_fields';
  avg?: Maybe<offer_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_max_fields>;
  min?: Maybe<offer_rule_min_fields>;
  stddev?: Maybe<offer_rule_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_stddev_samp_fields>;
  sum?: Maybe<offer_rule_sum_fields>;
  var_pop?: Maybe<offer_rule_var_pop_fields>;
  var_samp?: Maybe<offer_rule_var_samp_fields>;
  variance?: Maybe<offer_rule_variance_fields>;
};


/** aggregate fields of "offer_rule" */
export type offer_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule" */
export type offer_rule_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_max_order_by>;
  min?: InputMaybe<offer_rule_min_order_by>;
  stddev?: InputMaybe<offer_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule" */
export type offer_rule_arr_rel_insert_input = {
  data: Array<offer_rule_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_on_conflict>;
};

/** aggregate avg on columns */
export type offer_rule_avg_fields = {
  __typename: 'offer_rule_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule" */
export type offer_rule_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule". All fields are combined with a logical 'AND'. */
export type offer_rule_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_bool_exp>>;
  _not?: InputMaybe<offer_rule_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<offer_rule_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group_version_offer_rules?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
  offer_rule_group_version_offer_rules_aggregate?: InputMaybe<offer_rule_group_version_offer_rule_aggregate_bool_exp>;
  published_version?: InputMaybe<offer_rule_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_rule" */
export enum offer_rule_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_rule_pkey = 'offer_rule_pkey'
}

/** columns and relationships of "offer_rule_group" */
export type offer_rule_group = {
  __typename: 'offer_rule_group';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An array relationship */
  campaign_version_offer_rule_groups: Array<campaign_version>;
  /** An aggregate relationship */
  campaign_version_offer_rule_groups_aggregate: campaign_version_aggregate;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<offer_rule_group_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_deflection_rule_groups: Array<flow_step_deflection_rule_group>;
  /** An aggregate relationship */
  flow_step_deflection_rule_groups_aggregate: flow_step_deflection_rule_group_aggregate;
  /** An array relationship */
  flow_step_offer_rule_groups: Array<flow_step_offer_rule_group>;
  /** An aggregate relationship */
  flow_step_offer_rule_groups_aggregate: flow_step_offer_rule_group_aggregate;
  /** An array relationship */
  flow_step_rule_groups: Array<flow_step_rule_group>;
  /** An aggregate relationship */
  flow_step_rule_groups_aggregate: flow_step_rule_group_aggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  interventions: Array<intervention>;
  /** An aggregate relationship */
  interventions_aggregate: intervention_aggregate;
  /** An object relationship */
  published_version?: Maybe<offer_rule_group_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupcampaign_version_offer_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupcampaign_version_offer_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_deflection_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_deflection_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_offer_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_offer_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupflow_step_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupinterventionsArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};


/** columns and relationships of "offer_rule_group" */
export type offer_rule_groupinterventions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};

/** aggregated selection of "offer_rule_group" */
export type offer_rule_group_aggregate = {
  __typename: 'offer_rule_group_aggregate';
  aggregate?: Maybe<offer_rule_group_aggregate_fields>;
  nodes: Array<offer_rule_group>;
};

export type offer_rule_group_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_group_aggregate_bool_exp_count>;
};

export type offer_rule_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_group" */
export type offer_rule_group_aggregate_fields = {
  __typename: 'offer_rule_group_aggregate_fields';
  avg?: Maybe<offer_rule_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_group_max_fields>;
  min?: Maybe<offer_rule_group_min_fields>;
  stddev?: Maybe<offer_rule_group_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_group_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_group_stddev_samp_fields>;
  sum?: Maybe<offer_rule_group_sum_fields>;
  var_pop?: Maybe<offer_rule_group_var_pop_fields>;
  var_samp?: Maybe<offer_rule_group_var_samp_fields>;
  variance?: Maybe<offer_rule_group_variance_fields>;
};


/** aggregate fields of "offer_rule_group" */
export type offer_rule_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_group" */
export type offer_rule_group_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_group_max_order_by>;
  min?: InputMaybe<offer_rule_group_min_order_by>;
  stddev?: InputMaybe<offer_rule_group_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_group_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_group_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_group_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_group_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_group" */
export type offer_rule_group_arr_rel_insert_input = {
  data: Array<offer_rule_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_group_on_conflict>;
};

/** aggregate avg on columns */
export type offer_rule_group_avg_fields = {
  __typename: 'offer_rule_group_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_group" */
export type offer_rule_group_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_group". All fields are combined with a logical 'AND'. */
export type offer_rule_group_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_group_bool_exp>>;
  _not?: InputMaybe<offer_rule_group_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_group_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  campaign_version_offer_rule_groups?: InputMaybe<campaign_version_bool_exp>;
  campaign_version_offer_rule_groups_aggregate?: InputMaybe<campaign_version_aggregate_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<offer_rule_group_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_deflection_rule_groups?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
  flow_step_deflection_rule_groups_aggregate?: InputMaybe<flow_step_deflection_rule_group_aggregate_bool_exp>;
  flow_step_offer_rule_groups?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
  flow_step_offer_rule_groups_aggregate?: InputMaybe<flow_step_offer_rule_group_aggregate_bool_exp>;
  flow_step_rule_groups?: InputMaybe<flow_step_rule_group_bool_exp>;
  flow_step_rule_groups_aggregate?: InputMaybe<flow_step_rule_group_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  interventions?: InputMaybe<intervention_bool_exp>;
  interventions_aggregate?: InputMaybe<intervention_aggregate_bool_exp>;
  published_version?: InputMaybe<offer_rule_group_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_rule_group" */
export enum offer_rule_group_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_rule_group_pkey = 'offer_rule_group_pkey'
}

/** input type for incrementing numeric columns in table "offer_rule_group" */
export type offer_rule_group_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_rule_group" */
export type offer_rule_group_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  campaign_version_offer_rule_groups?: InputMaybe<campaign_version_arr_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<offer_rule_group_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_deflection_rule_groups?: InputMaybe<flow_step_deflection_rule_group_arr_rel_insert_input>;
  flow_step_offer_rule_groups?: InputMaybe<flow_step_offer_rule_group_arr_rel_insert_input>;
  flow_step_rule_groups?: InputMaybe<flow_step_rule_group_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  interventions?: InputMaybe<intervention_arr_rel_insert_input>;
  published_version?: InputMaybe<offer_rule_group_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_rule_group_max_fields = {
  __typename: 'offer_rule_group_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer_rule_group" */
export type offer_rule_group_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_group_min_fields = {
  __typename: 'offer_rule_group_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer_rule_group" */
export type offer_rule_group_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_rule_group" */
export type offer_rule_group_mutation_response = {
  __typename: 'offer_rule_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_rule_group>;
};

/** input type for inserting object relation for remote table "offer_rule_group" */
export type offer_rule_group_obj_rel_insert_input = {
  data: offer_rule_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_group_on_conflict>;
};

/** on_conflict condition type for table "offer_rule_group" */
export type offer_rule_group_on_conflict = {
  constraint: offer_rule_group_constraint;
  update_columns?: Array<offer_rule_group_update_column>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};

/** Ordering options when selecting data from "offer_rule_group". */
export type offer_rule_group_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  campaign_version_offer_rule_groups_aggregate?: InputMaybe<campaign_version_aggregate_order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<offer_rule_group_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_deflection_rule_groups_aggregate?: InputMaybe<flow_step_deflection_rule_group_aggregate_order_by>;
  flow_step_offer_rule_groups_aggregate?: InputMaybe<flow_step_offer_rule_group_aggregate_order_by>;
  flow_step_rule_groups_aggregate?: InputMaybe<flow_step_rule_group_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  interventions_aggregate?: InputMaybe<intervention_aggregate_order_by>;
  published_version?: InputMaybe<offer_rule_group_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_rule_group */
export type offer_rule_group_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "offer_rule_group" */
export enum offer_rule_group_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_rule_group" */
export type offer_rule_group_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_rule_group_stddev_fields = {
  __typename: 'offer_rule_group_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_group" */
export type offer_rule_group_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_group_stddev_pop_fields = {
  __typename: 'offer_rule_group_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_group" */
export type offer_rule_group_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_group_stddev_samp_fields = {
  __typename: 'offer_rule_group_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_group" */
export type offer_rule_group_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_group" */
export type offer_rule_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_group_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_group_sum_fields = {
  __typename: 'offer_rule_group_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_group" */
export type offer_rule_group_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_rule_group" */
export enum offer_rule_group_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_rule_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_rule_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_rule_group_set_input>;
  /** filter the rows which have to be updated */
  where: offer_rule_group_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_rule_group_var_pop_fields = {
  __typename: 'offer_rule_group_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_group" */
export type offer_rule_group_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_group_var_samp_fields = {
  __typename: 'offer_rule_group_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_group" */
export type offer_rule_group_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_group_variance_fields = {
  __typename: 'offer_rule_group_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_group" */
export type offer_rule_group_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_version = {
  __typename: 'offer_rule_group_version';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  offer_rule_group: offer_rule_group;
  offer_rule_group_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_rule_group_version_offer_rules: Array<offer_rule_group_version_offer_rule>;
  /** An aggregate relationship */
  offer_rule_group_version_offer_rules_aggregate: offer_rule_group_version_offer_rule_aggregate;
  offer_rule_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  published_offer_rule_groups: Array<offer_rule_group>;
  /** An aggregate relationship */
  published_offer_rule_groups_aggregate: offer_rule_group_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_versionoffer_rule_group_version_offer_rulesArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_versionoffer_rule_group_version_offer_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_versionoffer_rule_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_versionpublished_offer_rule_groupsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


/** columns and relationships of "offer_rule_group_version" */
export type offer_rule_group_versionpublished_offer_rule_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};

/** aggregated selection of "offer_rule_group_version" */
export type offer_rule_group_version_aggregate = {
  __typename: 'offer_rule_group_version_aggregate';
  aggregate?: Maybe<offer_rule_group_version_aggregate_fields>;
  nodes: Array<offer_rule_group_version>;
};

/** aggregate fields of "offer_rule_group_version" */
export type offer_rule_group_version_aggregate_fields = {
  __typename: 'offer_rule_group_version_aggregate_fields';
  avg?: Maybe<offer_rule_group_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_group_version_max_fields>;
  min?: Maybe<offer_rule_group_version_min_fields>;
  stddev?: Maybe<offer_rule_group_version_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_group_version_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_group_version_stddev_samp_fields>;
  sum?: Maybe<offer_rule_group_version_sum_fields>;
  var_pop?: Maybe<offer_rule_group_version_var_pop_fields>;
  var_samp?: Maybe<offer_rule_group_version_var_samp_fields>;
  variance?: Maybe<offer_rule_group_version_variance_fields>;
};


/** aggregate fields of "offer_rule_group_version" */
export type offer_rule_group_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_group_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_group_version_append_input = {
  offer_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_rule_group_version_avg_fields = {
  __typename: 'offer_rule_group_version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_rule_group_version". All fields are combined with a logical 'AND'. */
export type offer_rule_group_version_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_group_version_bool_exp>>;
  _not?: InputMaybe<offer_rule_group_version_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_group_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group?: InputMaybe<offer_rule_group_bool_exp>;
  offer_rule_group_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_group_version_offer_rules?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
  offer_rule_group_version_offer_rules_aggregate?: InputMaybe<offer_rule_group_version_offer_rule_aggregate_bool_exp>;
  offer_rule_ids?: InputMaybe<jsonb_comparison_exp>;
  published_offer_rule_groups?: InputMaybe<offer_rule_group_bool_exp>;
  published_offer_rule_groups_aggregate?: InputMaybe<offer_rule_group_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_rule_group_version" */
export enum offer_rule_group_version_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_rule_group_version_pkey = 'offer_rule_group_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_rule_group_version_delete_at_path_input = {
  offer_rule_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_rule_group_version_delete_elem_input = {
  offer_rule_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_rule_group_version_delete_key_input = {
  offer_rule_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_rule_group_version" */
export type offer_rule_group_version_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_rule_group_version" */
export type offer_rule_group_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group?: InputMaybe<offer_rule_group_obj_rel_insert_input>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_version_offer_rules?: InputMaybe<offer_rule_group_version_offer_rule_arr_rel_insert_input>;
  offer_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  published_offer_rule_groups?: InputMaybe<offer_rule_group_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_rule_group_version_max_fields = {
  __typename: 'offer_rule_group_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_rule_group_version_min_fields = {
  __typename: 'offer_rule_group_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_rule_group_version" */
export type offer_rule_group_version_mutation_response = {
  __typename: 'offer_rule_group_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_rule_group_version>;
};

/** input type for inserting object relation for remote table "offer_rule_group_version" */
export type offer_rule_group_version_obj_rel_insert_input = {
  data: offer_rule_group_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_group_version_on_conflict>;
};

/** columns and relationships of "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule = {
  __typename: 'offer_rule_group_version_offer_rule';
  /** An object relationship */
  offer_rule?: Maybe<offer_rule>;
  /** An object relationship */
  offer_rule_group_version?: Maybe<offer_rule_group_version>;
  offer_rule_group_version_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_aggregate = {
  __typename: 'offer_rule_group_version_offer_rule_aggregate';
  aggregate?: Maybe<offer_rule_group_version_offer_rule_aggregate_fields>;
  nodes: Array<offer_rule_group_version_offer_rule>;
};

export type offer_rule_group_version_offer_rule_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_group_version_offer_rule_aggregate_bool_exp_count>;
};

export type offer_rule_group_version_offer_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_aggregate_fields = {
  __typename: 'offer_rule_group_version_offer_rule_aggregate_fields';
  avg?: Maybe<offer_rule_group_version_offer_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_group_version_offer_rule_max_fields>;
  min?: Maybe<offer_rule_group_version_offer_rule_min_fields>;
  stddev?: Maybe<offer_rule_group_version_offer_rule_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_group_version_offer_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_group_version_offer_rule_stddev_samp_fields>;
  sum?: Maybe<offer_rule_group_version_offer_rule_sum_fields>;
  var_pop?: Maybe<offer_rule_group_version_offer_rule_var_pop_fields>;
  var_samp?: Maybe<offer_rule_group_version_offer_rule_var_samp_fields>;
  variance?: Maybe<offer_rule_group_version_offer_rule_variance_fields>;
};


/** aggregate fields of "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_group_version_offer_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_group_version_offer_rule_max_order_by>;
  min?: InputMaybe<offer_rule_group_version_offer_rule_min_order_by>;
  stddev?: InputMaybe<offer_rule_group_version_offer_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_group_version_offer_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_group_version_offer_rule_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_group_version_offer_rule_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_group_version_offer_rule_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_group_version_offer_rule_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_group_version_offer_rule_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_arr_rel_insert_input = {
  data: Array<offer_rule_group_version_offer_rule_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_group_version_offer_rule_avg_fields = {
  __typename: 'offer_rule_group_version_offer_rule_avg_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_avg_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_group_version_offer_rule". All fields are combined with a logical 'AND'. */
export type offer_rule_group_version_offer_rule_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_group_version_offer_rule_bool_exp>>;
  _not?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_group_version_offer_rule_bool_exp>>;
  offer_rule?: InputMaybe<offer_rule_bool_exp>;
  offer_rule_group_version?: InputMaybe<offer_rule_group_version_bool_exp>;
  offer_rule_group_version_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_insert_input = {
  offer_rule?: InputMaybe<offer_rule_obj_rel_insert_input>;
  offer_rule_group_version?: InputMaybe<offer_rule_group_version_obj_rel_insert_input>;
  offer_rule_group_version_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_group_version_offer_rule_max_fields = {
  __typename: 'offer_rule_group_version_offer_rule_max_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_max_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_group_version_offer_rule_min_fields = {
  __typename: 'offer_rule_group_version_offer_rule_min_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_min_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_group_version_offer_rule". */
export type offer_rule_group_version_offer_rule_order_by = {
  offer_rule?: InputMaybe<offer_rule_order_by>;
  offer_rule_group_version?: InputMaybe<offer_rule_group_version_order_by>;
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_group_version_offer_rule" */
export enum offer_rule_group_version_offer_rule_select_column {
  /** column name */
  offer_rule_group_version_id = 'offer_rule_group_version_id',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_group_version_offer_rule_stddev_fields = {
  __typename: 'offer_rule_group_version_offer_rule_stddev_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_stddev_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_group_version_offer_rule_stddev_pop_fields = {
  __typename: 'offer_rule_group_version_offer_rule_stddev_pop_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_stddev_pop_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_group_version_offer_rule_stddev_samp_fields = {
  __typename: 'offer_rule_group_version_offer_rule_stddev_samp_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_stddev_samp_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_group_version_offer_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_group_version_offer_rule_stream_cursor_value_input = {
  offer_rule_group_version_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_group_version_offer_rule_sum_fields = {
  __typename: 'offer_rule_group_version_offer_rule_sum_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_sum_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_group_version_offer_rule_var_pop_fields = {
  __typename: 'offer_rule_group_version_offer_rule_var_pop_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_var_pop_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_group_version_offer_rule_var_samp_fields = {
  __typename: 'offer_rule_group_version_offer_rule_var_samp_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_var_samp_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_group_version_offer_rule_variance_fields = {
  __typename: 'offer_rule_group_version_offer_rule_variance_fields';
  offer_rule_group_version_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_group_version_offer_rule" */
export type offer_rule_group_version_offer_rule_variance_order_by = {
  offer_rule_group_version_id?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_rule_group_version" */
export type offer_rule_group_version_on_conflict = {
  constraint: offer_rule_group_version_constraint;
  update_columns?: Array<offer_rule_group_version_update_column>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};

/** Ordering options when selecting data from "offer_rule_group_version". */
export type offer_rule_group_version_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group?: InputMaybe<offer_rule_group_order_by>;
  offer_rule_group_id?: InputMaybe<order_by>;
  offer_rule_group_version_offer_rules_aggregate?: InputMaybe<offer_rule_group_version_offer_rule_aggregate_order_by>;
  offer_rule_ids?: InputMaybe<order_by>;
  published_offer_rule_groups_aggregate?: InputMaybe<offer_rule_group_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_rule_group_version */
export type offer_rule_group_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_group_version_prepend_input = {
  offer_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_rule_group_version" */
export enum offer_rule_group_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  offer_rule_ids = 'offer_rule_ids',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_rule_group_version" */
export type offer_rule_group_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_rule_group_version_stddev_fields = {
  __typename: 'offer_rule_group_version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_group_version_stddev_pop_fields = {
  __typename: 'offer_rule_group_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_group_version_stddev_samp_fields = {
  __typename: 'offer_rule_group_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_rule_group_version" */
export type offer_rule_group_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_group_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_group_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_group_version_sum_fields = {
  __typename: 'offer_rule_group_version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_rule_group_version" */
export enum offer_rule_group_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  offer_rule_group_id = 'offer_rule_group_id',
  /** column name */
  offer_rule_ids = 'offer_rule_ids',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_rule_group_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_rule_group_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_rule_group_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_rule_group_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_rule_group_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_rule_group_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_rule_group_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_rule_group_version_set_input>;
  /** filter the rows which have to be updated */
  where: offer_rule_group_version_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_rule_group_version_var_pop_fields = {
  __typename: 'offer_rule_group_version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_rule_group_version_var_samp_fields = {
  __typename: 'offer_rule_group_version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_rule_group_version_variance_fields = {
  __typename: 'offer_rule_group_version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_group_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "offer_rule" */
export type offer_rule_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_rule" */
export type offer_rule_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_group_version_offer_rules?: InputMaybe<offer_rule_group_version_offer_rule_arr_rel_insert_input>;
  published_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_rule_max_fields = {
  __typename: 'offer_rule_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer_rule" */
export type offer_rule_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_min_fields = {
  __typename: 'offer_rule_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer_rule" */
export type offer_rule_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_rule" */
export type offer_rule_mutation_response = {
  __typename: 'offer_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_rule>;
};

/** input type for inserting object relation for remote table "offer_rule" */
export type offer_rule_obj_rel_insert_input = {
  data: offer_rule_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_on_conflict>;
};

/** on_conflict condition type for table "offer_rule" */
export type offer_rule_on_conflict = {
  constraint: offer_rule_constraint;
  update_columns?: Array<offer_rule_update_column>;
  where?: InputMaybe<offer_rule_bool_exp>;
};

/** Ordering options when selecting data from "offer_rule". */
export type offer_rule_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<offer_rule_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_rule_group_version_offer_rules_aggregate?: InputMaybe<offer_rule_group_version_offer_rule_aggregate_order_by>;
  published_version?: InputMaybe<offer_rule_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_rule */
export type offer_rule_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "offer_rule_rule" */
export type offer_rule_rule = {
  __typename: 'offer_rule_rule';
  created_at: Scalars['timestamptz']['output'];
  deflection_ids: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  include_present_no_offer: Scalars['Boolean']['output'];
  offer_autopilot_offer_ids: Scalars['jsonb']['output'];
  offer_group_ids: Scalars['jsonb']['output'];
  offer_ids: Scalars['jsonb']['output'];
  /** An object relationship */
  offer_rule: offer_rule;
  offer_rule_id: Scalars['Int']['output'];
  /** An array relationship */
  offer_rule_rule_deflections: Array<offer_rule_rule_deflection>;
  /** An aggregate relationship */
  offer_rule_rule_deflections_aggregate: offer_rule_rule_deflection_aggregate;
  /** An array relationship */
  offer_rule_rule_offer_autopilot_offers: Array<offer_rule_rule_offer_autopilot_offer>;
  /** An aggregate relationship */
  offer_rule_rule_offer_autopilot_offers_aggregate: offer_rule_rule_offer_autopilot_offer_aggregate;
  /** An array relationship */
  offer_rule_rule_offer_groups: Array<offer_rule_rule_offer_group>;
  /** An aggregate relationship */
  offer_rule_rule_offer_groups_aggregate: offer_rule_rule_offer_group_aggregate;
  /** An array relationship */
  offer_rule_rule_offer_tests: Array<offer_rule_rule_offer_test>;
  /** An aggregate relationship */
  offer_rule_rule_offer_tests_aggregate: offer_rule_rule_offer_test_aggregate;
  /** An array relationship */
  offer_rule_rule_offers: Array<offer_rule_rule_offer>;
  /** An aggregate relationship */
  offer_rule_rule_offers_aggregate: offer_rule_rule_offer_aggregate;
  /** An array relationship */
  offer_rule_version_offer_rule_rules: Array<offer_rule_version_offer_rule_rule>;
  /** An aggregate relationship */
  offer_rule_version_offer_rule_rules_aggregate: offer_rule_version_offer_rule_rule_aggregate;
  offer_test_ids: Scalars['jsonb']['output'];
  weight: Scalars['Int']['output'];
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruledeflection_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_autopilot_offer_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_deflectionsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_deflections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_autopilot_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_autopilot_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_groupsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_testsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offer_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_rule_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_version_offer_rule_rulesArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_rule_version_offer_rule_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_rule" */
export type offer_rule_ruleoffer_test_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "offer_rule_rule" */
export type offer_rule_rule_aggregate = {
  __typename: 'offer_rule_rule_aggregate';
  aggregate?: Maybe<offer_rule_rule_aggregate_fields>;
  nodes: Array<offer_rule_rule>;
};

/** aggregate fields of "offer_rule_rule" */
export type offer_rule_rule_aggregate_fields = {
  __typename: 'offer_rule_rule_aggregate_fields';
  avg?: Maybe<offer_rule_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_max_fields>;
  min?: Maybe<offer_rule_rule_min_fields>;
  stddev?: Maybe<offer_rule_rule_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_variance_fields>;
};


/** aggregate fields of "offer_rule_rule" */
export type offer_rule_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_rule_append_input = {
  deflection_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_rule_rule_avg_fields = {
  __typename: 'offer_rule_rule_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deflection_ids?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  include_present_no_offer?: InputMaybe<Boolean_comparison_exp>;
  offer_autopilot_offer_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_group_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_rule?: InputMaybe<offer_rule_bool_exp>;
  offer_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule_deflections?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
  offer_rule_rule_deflections_aggregate?: InputMaybe<offer_rule_rule_deflection_aggregate_bool_exp>;
  offer_rule_rule_offer_autopilot_offers?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
  offer_rule_rule_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_rule_offer_autopilot_offer_aggregate_bool_exp>;
  offer_rule_rule_offer_groups?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
  offer_rule_rule_offer_groups_aggregate?: InputMaybe<offer_rule_rule_offer_group_aggregate_bool_exp>;
  offer_rule_rule_offer_tests?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
  offer_rule_rule_offer_tests_aggregate?: InputMaybe<offer_rule_rule_offer_test_aggregate_bool_exp>;
  offer_rule_rule_offers?: InputMaybe<offer_rule_rule_offer_bool_exp>;
  offer_rule_rule_offers_aggregate?: InputMaybe<offer_rule_rule_offer_aggregate_bool_exp>;
  offer_rule_version_offer_rule_rules?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
  offer_rule_version_offer_rule_rules_aggregate?: InputMaybe<offer_rule_version_offer_rule_rule_aggregate_bool_exp>;
  offer_test_ids?: InputMaybe<jsonb_comparison_exp>;
  weight?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "offer_rule_rule" */
export enum offer_rule_rule_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_rule_rule_pkey = 'offer_rule_rule_pkey'
}

/** columns and relationships of "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection = {
  __typename: 'offer_rule_rule_deflection';
  /** An object relationship */
  deflection?: Maybe<deflection>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_aggregate = {
  __typename: 'offer_rule_rule_deflection_aggregate';
  aggregate?: Maybe<offer_rule_rule_deflection_aggregate_fields>;
  nodes: Array<offer_rule_rule_deflection>;
};

export type offer_rule_rule_deflection_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_rule_deflection_aggregate_bool_exp_count>;
};

export type offer_rule_rule_deflection_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_aggregate_fields = {
  __typename: 'offer_rule_rule_deflection_aggregate_fields';
  avg?: Maybe<offer_rule_rule_deflection_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_deflection_max_fields>;
  min?: Maybe<offer_rule_rule_deflection_min_fields>;
  stddev?: Maybe<offer_rule_rule_deflection_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_deflection_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_deflection_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_deflection_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_deflection_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_deflection_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_deflection_variance_fields>;
};


/** aggregate fields of "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_rule_deflection_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_rule_deflection_max_order_by>;
  min?: InputMaybe<offer_rule_rule_deflection_min_order_by>;
  stddev?: InputMaybe<offer_rule_rule_deflection_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_rule_deflection_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_rule_deflection_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_rule_deflection_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_rule_deflection_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_rule_deflection_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_rule_deflection_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_arr_rel_insert_input = {
  data: Array<offer_rule_rule_deflection_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_rule_deflection_avg_fields = {
  __typename: 'offer_rule_rule_deflection_avg_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_avg_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule_deflection". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_deflection_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_deflection_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_deflection_bool_exp>>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_insert_input = {
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_deflection_max_fields = {
  __typename: 'offer_rule_rule_deflection_max_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_max_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_rule_deflection_min_fields = {
  __typename: 'offer_rule_rule_deflection_min_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_min_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_rule_deflection". */
export type offer_rule_rule_deflection_order_by = {
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_rule_deflection" */
export enum offer_rule_rule_deflection_select_column {
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_rule_deflection_stddev_fields = {
  __typename: 'offer_rule_rule_deflection_stddev_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_stddev_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_deflection_stddev_pop_fields = {
  __typename: 'offer_rule_rule_deflection_stddev_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_stddev_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_deflection_stddev_samp_fields = {
  __typename: 'offer_rule_rule_deflection_stddev_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_stddev_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_deflection_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_deflection_stream_cursor_value_input = {
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_deflection_sum_fields = {
  __typename: 'offer_rule_rule_deflection_sum_fields';
  deflection_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_sum_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_deflection_var_pop_fields = {
  __typename: 'offer_rule_rule_deflection_var_pop_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_var_pop_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_deflection_var_samp_fields = {
  __typename: 'offer_rule_rule_deflection_var_samp_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_var_samp_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_rule_deflection_variance_fields = {
  __typename: 'offer_rule_rule_deflection_variance_fields';
  deflection_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_rule_deflection" */
export type offer_rule_rule_deflection_variance_order_by = {
  deflection_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_rule_rule_delete_at_path_input = {
  deflection_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_autopilot_offer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_test_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_rule_rule_delete_elem_input = {
  deflection_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_group_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_test_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_rule_rule_delete_key_input = {
  deflection_ids?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['String']['input']>;
  offer_group_ids?: InputMaybe<Scalars['String']['input']>;
  offer_ids?: InputMaybe<Scalars['String']['input']>;
  offer_test_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_rule_rule" */
export type offer_rule_rule_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_rule_rule" */
export type offer_rule_rule_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule?: InputMaybe<offer_rule_obj_rel_insert_input>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_deflections?: InputMaybe<offer_rule_rule_deflection_arr_rel_insert_input>;
  offer_rule_rule_offer_autopilot_offers?: InputMaybe<offer_rule_rule_offer_autopilot_offer_arr_rel_insert_input>;
  offer_rule_rule_offer_groups?: InputMaybe<offer_rule_rule_offer_group_arr_rel_insert_input>;
  offer_rule_rule_offer_tests?: InputMaybe<offer_rule_rule_offer_test_arr_rel_insert_input>;
  offer_rule_rule_offers?: InputMaybe<offer_rule_rule_offer_arr_rel_insert_input>;
  offer_rule_version_offer_rule_rules?: InputMaybe<offer_rule_version_offer_rule_rule_arr_rel_insert_input>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_max_fields = {
  __typename: 'offer_rule_rule_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_rule_rule_min_fields = {
  __typename: 'offer_rule_rule_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "offer_rule_rule" */
export type offer_rule_rule_mutation_response = {
  __typename: 'offer_rule_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_rule_rule>;
};

/** input type for inserting object relation for remote table "offer_rule_rule" */
export type offer_rule_rule_obj_rel_insert_input = {
  data: offer_rule_rule_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_rule_on_conflict>;
};

/** columns and relationships of "offer_rule_rule_offer" */
export type offer_rule_rule_offer = {
  __typename: 'offer_rule_rule_offer';
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_rule_offer" */
export type offer_rule_rule_offer_aggregate = {
  __typename: 'offer_rule_rule_offer_aggregate';
  aggregate?: Maybe<offer_rule_rule_offer_aggregate_fields>;
  nodes: Array<offer_rule_rule_offer>;
};

export type offer_rule_rule_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_rule_offer_aggregate_bool_exp_count>;
};

export type offer_rule_rule_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_rule_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_rule_offer" */
export type offer_rule_rule_offer_aggregate_fields = {
  __typename: 'offer_rule_rule_offer_aggregate_fields';
  avg?: Maybe<offer_rule_rule_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_offer_max_fields>;
  min?: Maybe<offer_rule_rule_offer_min_fields>;
  stddev?: Maybe<offer_rule_rule_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_offer_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_offer_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_offer_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_offer_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_offer_variance_fields>;
};


/** aggregate fields of "offer_rule_rule_offer" */
export type offer_rule_rule_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_rule_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_rule_offer_max_order_by>;
  min?: InputMaybe<offer_rule_rule_offer_min_order_by>;
  stddev?: InputMaybe<offer_rule_rule_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_rule_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_rule_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_rule_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_rule_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_rule_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_rule_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_arr_rel_insert_input = {
  data: Array<offer_rule_rule_offer_insert_input>;
};

/** columns and relationships of "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer = {
  __typename: 'offer_rule_rule_offer_autopilot_offer';
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_aggregate = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_aggregate';
  aggregate?: Maybe<offer_rule_rule_offer_autopilot_offer_aggregate_fields>;
  nodes: Array<offer_rule_rule_offer_autopilot_offer>;
};

export type offer_rule_rule_offer_autopilot_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_rule_offer_autopilot_offer_aggregate_bool_exp_count>;
};

export type offer_rule_rule_offer_autopilot_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_aggregate_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_aggregate_fields';
  avg?: Maybe<offer_rule_rule_offer_autopilot_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_offer_autopilot_offer_max_fields>;
  min?: Maybe<offer_rule_rule_offer_autopilot_offer_min_fields>;
  stddev?: Maybe<offer_rule_rule_offer_autopilot_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_offer_autopilot_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_offer_autopilot_offer_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_offer_autopilot_offer_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_offer_autopilot_offer_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_offer_autopilot_offer_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_offer_autopilot_offer_variance_fields>;
};


/** aggregate fields of "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_rule_offer_autopilot_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_rule_offer_autopilot_offer_max_order_by>;
  min?: InputMaybe<offer_rule_rule_offer_autopilot_offer_min_order_by>;
  stddev?: InputMaybe<offer_rule_rule_offer_autopilot_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_rule_offer_autopilot_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_rule_offer_autopilot_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_rule_offer_autopilot_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_rule_offer_autopilot_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_rule_offer_autopilot_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_rule_offer_autopilot_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_arr_rel_insert_input = {
  data: Array<offer_rule_rule_offer_autopilot_offer_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_rule_offer_autopilot_offer_avg_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule_offer_autopilot_offer". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_offer_autopilot_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_bool_exp>>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_insert_input = {
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_offer_autopilot_offer_max_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_rule_offer_autopilot_offer_min_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_rule_offer_autopilot_offer". */
export type offer_rule_rule_offer_autopilot_offer_order_by = {
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_rule_offer_autopilot_offer" */
export enum offer_rule_rule_offer_autopilot_offer_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_rule_offer_autopilot_offer_stddev_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_offer_autopilot_offer_stddev_pop_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_offer_autopilot_offer_stddev_samp_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_offer_autopilot_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_offer_autopilot_offer_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_offer_autopilot_offer_sum_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_offer_autopilot_offer_var_pop_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_offer_autopilot_offer_var_samp_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_rule_offer_autopilot_offer_variance_fields = {
  __typename: 'offer_rule_rule_offer_autopilot_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_rule_offer_autopilot_offer" */
export type offer_rule_rule_offer_autopilot_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate avg on columns */
export type offer_rule_rule_offer_avg_fields = {
  __typename: 'offer_rule_rule_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule_offer". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_offer_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_offer_bool_exp>>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group = {
  __typename: 'offer_rule_rule_offer_group';
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_aggregate = {
  __typename: 'offer_rule_rule_offer_group_aggregate';
  aggregate?: Maybe<offer_rule_rule_offer_group_aggregate_fields>;
  nodes: Array<offer_rule_rule_offer_group>;
};

export type offer_rule_rule_offer_group_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_rule_offer_group_aggregate_bool_exp_count>;
};

export type offer_rule_rule_offer_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_aggregate_fields = {
  __typename: 'offer_rule_rule_offer_group_aggregate_fields';
  avg?: Maybe<offer_rule_rule_offer_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_offer_group_max_fields>;
  min?: Maybe<offer_rule_rule_offer_group_min_fields>;
  stddev?: Maybe<offer_rule_rule_offer_group_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_offer_group_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_offer_group_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_offer_group_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_offer_group_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_offer_group_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_offer_group_variance_fields>;
};


/** aggregate fields of "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_rule_offer_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_rule_offer_group_max_order_by>;
  min?: InputMaybe<offer_rule_rule_offer_group_min_order_by>;
  stddev?: InputMaybe<offer_rule_rule_offer_group_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_rule_offer_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_rule_offer_group_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_rule_offer_group_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_rule_offer_group_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_rule_offer_group_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_rule_offer_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_arr_rel_insert_input = {
  data: Array<offer_rule_rule_offer_group_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_rule_offer_group_avg_fields = {
  __typename: 'offer_rule_rule_offer_group_avg_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_avg_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule_offer_group". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_offer_group_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_offer_group_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_offer_group_bool_exp>>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_insert_input = {
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_offer_group_max_fields = {
  __typename: 'offer_rule_rule_offer_group_max_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_max_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_rule_offer_group_min_fields = {
  __typename: 'offer_rule_rule_offer_group_min_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_min_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_rule_offer_group". */
export type offer_rule_rule_offer_group_order_by = {
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_rule_offer_group" */
export enum offer_rule_rule_offer_group_select_column {
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_rule_offer_group_stddev_fields = {
  __typename: 'offer_rule_rule_offer_group_stddev_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_stddev_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_offer_group_stddev_pop_fields = {
  __typename: 'offer_rule_rule_offer_group_stddev_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_stddev_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_offer_group_stddev_samp_fields = {
  __typename: 'offer_rule_rule_offer_group_stddev_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_stddev_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_offer_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_offer_group_stream_cursor_value_input = {
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_offer_group_sum_fields = {
  __typename: 'offer_rule_rule_offer_group_sum_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_sum_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_offer_group_var_pop_fields = {
  __typename: 'offer_rule_rule_offer_group_var_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_var_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_offer_group_var_samp_fields = {
  __typename: 'offer_rule_rule_offer_group_var_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_var_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_rule_offer_group_variance_fields = {
  __typename: 'offer_rule_rule_offer_group_variance_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_rule_offer_group" */
export type offer_rule_rule_offer_group_variance_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** input type for inserting data into table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_insert_input = {
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_offer_max_fields = {
  __typename: 'offer_rule_rule_offer_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_rule_offer_min_fields = {
  __typename: 'offer_rule_rule_offer_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_rule_offer". */
export type offer_rule_rule_offer_order_by = {
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_rule_offer" */
export enum offer_rule_rule_offer_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_rule_offer_stddev_fields = {
  __typename: 'offer_rule_rule_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_offer_stddev_pop_fields = {
  __typename: 'offer_rule_rule_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_offer_stddev_samp_fields = {
  __typename: 'offer_rule_rule_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_offer_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_offer_sum_fields = {
  __typename: 'offer_rule_rule_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test = {
  __typename: 'offer_rule_rule_offer_test';
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_test?: Maybe<offer_test>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_aggregate = {
  __typename: 'offer_rule_rule_offer_test_aggregate';
  aggregate?: Maybe<offer_rule_rule_offer_test_aggregate_fields>;
  nodes: Array<offer_rule_rule_offer_test>;
};

export type offer_rule_rule_offer_test_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_rule_offer_test_aggregate_bool_exp_count>;
};

export type offer_rule_rule_offer_test_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_aggregate_fields = {
  __typename: 'offer_rule_rule_offer_test_aggregate_fields';
  avg?: Maybe<offer_rule_rule_offer_test_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_rule_offer_test_max_fields>;
  min?: Maybe<offer_rule_rule_offer_test_min_fields>;
  stddev?: Maybe<offer_rule_rule_offer_test_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_rule_offer_test_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_rule_offer_test_stddev_samp_fields>;
  sum?: Maybe<offer_rule_rule_offer_test_sum_fields>;
  var_pop?: Maybe<offer_rule_rule_offer_test_var_pop_fields>;
  var_samp?: Maybe<offer_rule_rule_offer_test_var_samp_fields>;
  variance?: Maybe<offer_rule_rule_offer_test_variance_fields>;
};


/** aggregate fields of "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_rule_offer_test_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_rule_offer_test_max_order_by>;
  min?: InputMaybe<offer_rule_rule_offer_test_min_order_by>;
  stddev?: InputMaybe<offer_rule_rule_offer_test_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_rule_offer_test_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_rule_offer_test_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_rule_offer_test_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_rule_offer_test_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_rule_offer_test_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_rule_offer_test_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_arr_rel_insert_input = {
  data: Array<offer_rule_rule_offer_test_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_rule_offer_test_avg_fields = {
  __typename: 'offer_rule_rule_offer_test_avg_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_avg_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_rule_offer_test". All fields are combined with a logical 'AND'. */
export type offer_rule_rule_offer_test_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_rule_offer_test_bool_exp>>;
  _not?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_rule_offer_test_bool_exp>>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_insert_input = {
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_rule_offer_test_max_fields = {
  __typename: 'offer_rule_rule_offer_test_max_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_max_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_rule_offer_test_min_fields = {
  __typename: 'offer_rule_rule_offer_test_min_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_min_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_rule_offer_test". */
export type offer_rule_rule_offer_test_order_by = {
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_rule_offer_test" */
export enum offer_rule_rule_offer_test_select_column {
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_rule_offer_test_stddev_fields = {
  __typename: 'offer_rule_rule_offer_test_stddev_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_stddev_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_offer_test_stddev_pop_fields = {
  __typename: 'offer_rule_rule_offer_test_stddev_pop_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_stddev_pop_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_offer_test_stddev_samp_fields = {
  __typename: 'offer_rule_rule_offer_test_stddev_samp_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_stddev_samp_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_offer_test_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_offer_test_stream_cursor_value_input = {
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_offer_test_sum_fields = {
  __typename: 'offer_rule_rule_offer_test_sum_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_sum_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_offer_test_var_pop_fields = {
  __typename: 'offer_rule_rule_offer_test_var_pop_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_var_pop_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_offer_test_var_samp_fields = {
  __typename: 'offer_rule_rule_offer_test_var_samp_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_var_samp_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_rule_offer_test_variance_fields = {
  __typename: 'offer_rule_rule_offer_test_variance_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_rule_offer_test" */
export type offer_rule_rule_offer_test_variance_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_offer_var_pop_fields = {
  __typename: 'offer_rule_rule_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_offer_var_samp_fields = {
  __typename: 'offer_rule_rule_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_rule_offer_variance_fields = {
  __typename: 'offer_rule_rule_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_rule_offer" */
export type offer_rule_rule_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_rule_rule" */
export type offer_rule_rule_on_conflict = {
  constraint: offer_rule_rule_constraint;
  update_columns?: Array<offer_rule_rule_update_column>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};

/** Ordering options when selecting data from "offer_rule_rule". */
export type offer_rule_rule_order_by = {
  created_at?: InputMaybe<order_by>;
  deflection_ids?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  include_present_no_offer?: InputMaybe<order_by>;
  offer_autopilot_offer_ids?: InputMaybe<order_by>;
  offer_group_ids?: InputMaybe<order_by>;
  offer_ids?: InputMaybe<order_by>;
  offer_rule?: InputMaybe<offer_rule_order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_rule_deflections_aggregate?: InputMaybe<offer_rule_rule_deflection_aggregate_order_by>;
  offer_rule_rule_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_rule_offer_autopilot_offer_aggregate_order_by>;
  offer_rule_rule_offer_groups_aggregate?: InputMaybe<offer_rule_rule_offer_group_aggregate_order_by>;
  offer_rule_rule_offer_tests_aggregate?: InputMaybe<offer_rule_rule_offer_test_aggregate_order_by>;
  offer_rule_rule_offers_aggregate?: InputMaybe<offer_rule_rule_offer_aggregate_order_by>;
  offer_rule_version_offer_rule_rules_aggregate?: InputMaybe<offer_rule_version_offer_rule_rule_aggregate_order_by>;
  offer_test_ids?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_rule_rule */
export type offer_rule_rule_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_rule_prepend_input = {
  deflection_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_rule_rule" */
export enum offer_rule_rule_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  deflection_ids = 'deflection_ids',
  /** column name */
  id = 'id',
  /** column name */
  include_present_no_offer = 'include_present_no_offer',
  /** column name */
  offer_autopilot_offer_ids = 'offer_autopilot_offer_ids',
  /** column name */
  offer_group_ids = 'offer_group_ids',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_test_ids = 'offer_test_ids',
  /** column name */
  weight = 'weight'
}

/** input type for updating data in table "offer_rule_rule" */
export type offer_rule_rule_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type offer_rule_rule_stddev_fields = {
  __typename: 'offer_rule_rule_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_rule_stddev_pop_fields = {
  __typename: 'offer_rule_rule_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_rule_stddev_samp_fields = {
  __typename: 'offer_rule_rule_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_rule_rule" */
export type offer_rule_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_rule_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_ids?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_rule_sum_fields = {
  __typename: 'offer_rule_rule_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_rule_rule" */
export enum offer_rule_rule_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  deflection_ids = 'deflection_ids',
  /** column name */
  id = 'id',
  /** column name */
  include_present_no_offer = 'include_present_no_offer',
  /** column name */
  offer_autopilot_offer_ids = 'offer_autopilot_offer_ids',
  /** column name */
  offer_group_ids = 'offer_group_ids',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_test_ids = 'offer_test_ids',
  /** column name */
  weight = 'weight'
}

export type offer_rule_rule_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_rule_rule_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_rule_rule_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_rule_rule_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_rule_rule_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_rule_rule_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_rule_rule_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_rule_rule_set_input>;
  /** filter the rows which have to be updated */
  where: offer_rule_rule_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_rule_rule_var_pop_fields = {
  __typename: 'offer_rule_rule_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_rule_rule_var_samp_fields = {
  __typename: 'offer_rule_rule_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_rule_rule_variance_fields = {
  __typename: 'offer_rule_rule_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "offer_rule" */
export enum offer_rule_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_rule" */
export type offer_rule_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_rule_stddev_fields = {
  __typename: 'offer_rule_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule" */
export type offer_rule_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_stddev_pop_fields = {
  __typename: 'offer_rule_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule" */
export type offer_rule_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_stddev_samp_fields = {
  __typename: 'offer_rule_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule" */
export type offer_rule_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule" */
export type offer_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_sum_fields = {
  __typename: 'offer_rule_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule" */
export type offer_rule_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_rule" */
export enum offer_rule_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_rule_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_rule_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_rule_set_input>;
  /** filter the rows which have to be updated */
  where: offer_rule_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_rule_var_pop_fields = {
  __typename: 'offer_rule_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule" */
export type offer_rule_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_var_samp_fields = {
  __typename: 'offer_rule_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule" */
export type offer_rule_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_variance_fields = {
  __typename: 'offer_rule_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule" */
export type offer_rule_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_version" */
export type offer_rule_version = {
  __typename: 'offer_rule_version';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  include_other_in_question_ids: Scalars['jsonb']['output'];
  include_present_no_offer: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  offer_autopilot_offer_ids: Scalars['jsonb']['output'];
  offer_group_ids: Scalars['jsonb']['output'];
  offer_ids: Scalars['jsonb']['output'];
  /** An object relationship */
  offer_rule: offer_rule;
  offer_rule_id: Scalars['Int']['output'];
  offer_rule_rule_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  offer_rule_version_offer_autopilot_offers: Array<offer_rule_version_offer_autopilot_offer>;
  /** An aggregate relationship */
  offer_rule_version_offer_autopilot_offers_aggregate: offer_rule_version_offer_autopilot_offer_aggregate;
  /** An array relationship */
  offer_rule_version_offer_groups: Array<offer_rule_version_offer_group>;
  /** An aggregate relationship */
  offer_rule_version_offer_groups_aggregate: offer_rule_version_offer_group_aggregate;
  /** An array relationship */
  offer_rule_version_offer_rule_rules: Array<offer_rule_version_offer_rule_rule>;
  /** An aggregate relationship */
  offer_rule_version_offer_rule_rules_aggregate: offer_rule_version_offer_rule_rule_aggregate;
  /** An array relationship */
  offer_rule_version_offer_tests: Array<offer_rule_version_offer_test>;
  /** An aggregate relationship */
  offer_rule_version_offer_tests_aggregate: offer_rule_version_offer_test_aggregate;
  /** An array relationship */
  offer_rule_version_offers: Array<offer_rule_version_offer>;
  /** An aggregate relationship */
  offer_rule_version_offers_aggregate: offer_rule_version_offer_aggregate;
  /** An array relationship */
  offer_rule_version_question_options: Array<offer_rule_version_question_option>;
  /** An aggregate relationship */
  offer_rule_version_question_options_aggregate: offer_rule_version_question_option_aggregate;
  /** An array relationship */
  offer_rule_version_segment_groups: Array<offer_rule_version_segment_group>;
  /** An aggregate relationship */
  offer_rule_version_segment_groups_aggregate: offer_rule_version_segment_group_aggregate;
  /** An array relationship */
  offer_rule_version_segments: Array<offer_rule_version_segment>;
  /** An aggregate relationship */
  offer_rule_version_segments_aggregate: offer_rule_version_segment_aggregate;
  offer_test_ids: Scalars['jsonb']['output'];
  /** An array relationship */
  published_offer_rules: Array<offer_rule>;
  /** An aggregate relationship */
  published_offer_rules_aggregate: offer_rule_aggregate;
  question_option_ids: Scalars['jsonb']['output'];
  segment_group_ids: Scalars['jsonb']['output'];
  segment_ids: Scalars['jsonb']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versioninclude_other_in_question_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_autopilot_offer_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_rule_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_autopilot_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_autopilot_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_groupsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_rule_rulesArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_rule_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_testsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offer_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_question_optionsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_question_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_segment_groupsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_segment_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_segmentsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_rule_version_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionoffer_test_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionpublished_offer_rulesArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionpublished_offer_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionquestion_option_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionsegment_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_rule_version" */
export type offer_rule_versionsegment_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "offer_rule_version" */
export type offer_rule_version_aggregate = {
  __typename: 'offer_rule_version_aggregate';
  aggregate?: Maybe<offer_rule_version_aggregate_fields>;
  nodes: Array<offer_rule_version>;
};

/** aggregate fields of "offer_rule_version" */
export type offer_rule_version_aggregate_fields = {
  __typename: 'offer_rule_version_aggregate_fields';
  avg?: Maybe<offer_rule_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_max_fields>;
  min?: Maybe<offer_rule_version_min_fields>;
  stddev?: Maybe<offer_rule_version_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_sum_fields>;
  var_pop?: Maybe<offer_rule_version_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_var_samp_fields>;
  variance?: Maybe<offer_rule_version_variance_fields>;
};


/** aggregate fields of "offer_rule_version" */
export type offer_rule_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_version_append_input = {
  include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_rule_version_avg_fields = {
  __typename: 'offer_rule_version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_rule_version". All fields are combined with a logical 'AND'. */
export type offer_rule_version_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  include_other_in_question_ids?: InputMaybe<jsonb_comparison_exp>;
  include_present_no_offer?: InputMaybe<Boolean_comparison_exp>;
  label?: InputMaybe<String_comparison_exp>;
  offer_autopilot_offer_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_group_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_rule?: InputMaybe<offer_rule_bool_exp>;
  offer_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_rule_ids?: InputMaybe<jsonb_comparison_exp>;
  offer_rule_version_offer_autopilot_offers?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
  offer_rule_version_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_version_offer_autopilot_offer_aggregate_bool_exp>;
  offer_rule_version_offer_groups?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
  offer_rule_version_offer_groups_aggregate?: InputMaybe<offer_rule_version_offer_group_aggregate_bool_exp>;
  offer_rule_version_offer_rule_rules?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
  offer_rule_version_offer_rule_rules_aggregate?: InputMaybe<offer_rule_version_offer_rule_rule_aggregate_bool_exp>;
  offer_rule_version_offer_tests?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
  offer_rule_version_offer_tests_aggregate?: InputMaybe<offer_rule_version_offer_test_aggregate_bool_exp>;
  offer_rule_version_offers?: InputMaybe<offer_rule_version_offer_bool_exp>;
  offer_rule_version_offers_aggregate?: InputMaybe<offer_rule_version_offer_aggregate_bool_exp>;
  offer_rule_version_question_options?: InputMaybe<offer_rule_version_question_option_bool_exp>;
  offer_rule_version_question_options_aggregate?: InputMaybe<offer_rule_version_question_option_aggregate_bool_exp>;
  offer_rule_version_segment_groups?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
  offer_rule_version_segment_groups_aggregate?: InputMaybe<offer_rule_version_segment_group_aggregate_bool_exp>;
  offer_rule_version_segments?: InputMaybe<offer_rule_version_segment_bool_exp>;
  offer_rule_version_segments_aggregate?: InputMaybe<offer_rule_version_segment_aggregate_bool_exp>;
  offer_test_ids?: InputMaybe<jsonb_comparison_exp>;
  published_offer_rules?: InputMaybe<offer_rule_bool_exp>;
  published_offer_rules_aggregate?: InputMaybe<offer_rule_aggregate_bool_exp>;
  question_option_ids?: InputMaybe<jsonb_comparison_exp>;
  segment_group_ids?: InputMaybe<jsonb_comparison_exp>;
  segment_ids?: InputMaybe<jsonb_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_rule_version" */
export enum offer_rule_version_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_rule_version_pkey = 'offer_rule_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_rule_version_delete_at_path_input = {
  include_other_in_question_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_autopilot_offer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_rule_rule_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offer_test_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  question_option_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  segment_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  segment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_rule_version_delete_elem_input = {
  include_other_in_question_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_group_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['Int']['input']>;
  offer_test_ids?: InputMaybe<Scalars['Int']['input']>;
  question_option_ids?: InputMaybe<Scalars['Int']['input']>;
  segment_group_ids?: InputMaybe<Scalars['Int']['input']>;
  segment_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_rule_version_delete_key_input = {
  include_other_in_question_ids?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['String']['input']>;
  offer_group_ids?: InputMaybe<Scalars['String']['input']>;
  offer_ids?: InputMaybe<Scalars['String']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['String']['input']>;
  offer_test_ids?: InputMaybe<Scalars['String']['input']>;
  question_option_ids?: InputMaybe<Scalars['String']['input']>;
  segment_group_ids?: InputMaybe<Scalars['String']['input']>;
  segment_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_rule_version" */
export type offer_rule_version_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_rule_version" */
export type offer_rule_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule?: InputMaybe<offer_rule_obj_rel_insert_input>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_version_offer_autopilot_offers?: InputMaybe<offer_rule_version_offer_autopilot_offer_arr_rel_insert_input>;
  offer_rule_version_offer_groups?: InputMaybe<offer_rule_version_offer_group_arr_rel_insert_input>;
  offer_rule_version_offer_rule_rules?: InputMaybe<offer_rule_version_offer_rule_rule_arr_rel_insert_input>;
  offer_rule_version_offer_tests?: InputMaybe<offer_rule_version_offer_test_arr_rel_insert_input>;
  offer_rule_version_offers?: InputMaybe<offer_rule_version_offer_arr_rel_insert_input>;
  offer_rule_version_question_options?: InputMaybe<offer_rule_version_question_option_arr_rel_insert_input>;
  offer_rule_version_segment_groups?: InputMaybe<offer_rule_version_segment_group_arr_rel_insert_input>;
  offer_rule_version_segments?: InputMaybe<offer_rule_version_segment_arr_rel_insert_input>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  published_offer_rules?: InputMaybe<offer_rule_arr_rel_insert_input>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_max_fields = {
  __typename: 'offer_rule_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_rule_version_min_fields = {
  __typename: 'offer_rule_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_rule_version" */
export type offer_rule_version_mutation_response = {
  __typename: 'offer_rule_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_rule_version>;
};

/** input type for inserting object relation for remote table "offer_rule_version" */
export type offer_rule_version_obj_rel_insert_input = {
  data: offer_rule_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_rule_version_on_conflict>;
};

/** columns and relationships of "offer_rule_version_offer" */
export type offer_rule_version_offer = {
  __typename: 'offer_rule_version_offer';
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_offer" */
export type offer_rule_version_offer_aggregate = {
  __typename: 'offer_rule_version_offer_aggregate';
  aggregate?: Maybe<offer_rule_version_offer_aggregate_fields>;
  nodes: Array<offer_rule_version_offer>;
};

export type offer_rule_version_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_offer_aggregate_bool_exp_count>;
};

export type offer_rule_version_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_offer" */
export type offer_rule_version_offer_aggregate_fields = {
  __typename: 'offer_rule_version_offer_aggregate_fields';
  avg?: Maybe<offer_rule_version_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_offer_max_fields>;
  min?: Maybe<offer_rule_version_offer_min_fields>;
  stddev?: Maybe<offer_rule_version_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_offer_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_offer_sum_fields>;
  var_pop?: Maybe<offer_rule_version_offer_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_offer_var_samp_fields>;
  variance?: Maybe<offer_rule_version_offer_variance_fields>;
};


/** aggregate fields of "offer_rule_version_offer" */
export type offer_rule_version_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_offer" */
export type offer_rule_version_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_offer_max_order_by>;
  min?: InputMaybe<offer_rule_version_offer_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_offer" */
export type offer_rule_version_offer_arr_rel_insert_input = {
  data: Array<offer_rule_version_offer_insert_input>;
};

/** columns and relationships of "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer = {
  __typename: 'offer_rule_version_offer_autopilot_offer';
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_aggregate = {
  __typename: 'offer_rule_version_offer_autopilot_offer_aggregate';
  aggregate?: Maybe<offer_rule_version_offer_autopilot_offer_aggregate_fields>;
  nodes: Array<offer_rule_version_offer_autopilot_offer>;
};

export type offer_rule_version_offer_autopilot_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_offer_autopilot_offer_aggregate_bool_exp_count>;
};

export type offer_rule_version_offer_autopilot_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_aggregate_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_aggregate_fields';
  avg?: Maybe<offer_rule_version_offer_autopilot_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_offer_autopilot_offer_max_fields>;
  min?: Maybe<offer_rule_version_offer_autopilot_offer_min_fields>;
  stddev?: Maybe<offer_rule_version_offer_autopilot_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_offer_autopilot_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_offer_autopilot_offer_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_offer_autopilot_offer_sum_fields>;
  var_pop?: Maybe<offer_rule_version_offer_autopilot_offer_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_offer_autopilot_offer_var_samp_fields>;
  variance?: Maybe<offer_rule_version_offer_autopilot_offer_variance_fields>;
};


/** aggregate fields of "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_offer_autopilot_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_offer_autopilot_offer_max_order_by>;
  min?: InputMaybe<offer_rule_version_offer_autopilot_offer_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_offer_autopilot_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_offer_autopilot_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_offer_autopilot_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_offer_autopilot_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_offer_autopilot_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_offer_autopilot_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_offer_autopilot_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_arr_rel_insert_input = {
  data: Array<offer_rule_version_offer_autopilot_offer_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_offer_autopilot_offer_avg_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_offer_autopilot_offer". All fields are combined with a logical 'AND'. */
export type offer_rule_version_offer_autopilot_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_bool_exp>>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_insert_input = {
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_offer_autopilot_offer_max_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_offer_autopilot_offer_min_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_offer_autopilot_offer". */
export type offer_rule_version_offer_autopilot_offer_order_by = {
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_offer_autopilot_offer" */
export enum offer_rule_version_offer_autopilot_offer_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_version_offer_autopilot_offer_stddev_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_offer_autopilot_offer_stddev_pop_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_offer_autopilot_offer_stddev_samp_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_offer_autopilot_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_offer_autopilot_offer_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_offer_autopilot_offer_sum_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_offer_autopilot_offer_var_pop_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_offer_autopilot_offer_var_samp_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_offer_autopilot_offer_variance_fields = {
  __typename: 'offer_rule_version_offer_autopilot_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_offer_autopilot_offer" */
export type offer_rule_version_offer_autopilot_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate avg on columns */
export type offer_rule_version_offer_avg_fields = {
  __typename: 'offer_rule_version_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_offer". All fields are combined with a logical 'AND'. */
export type offer_rule_version_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_offer_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_offer_bool_exp>>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group = {
  __typename: 'offer_rule_version_offer_group';
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_aggregate = {
  __typename: 'offer_rule_version_offer_group_aggregate';
  aggregate?: Maybe<offer_rule_version_offer_group_aggregate_fields>;
  nodes: Array<offer_rule_version_offer_group>;
};

export type offer_rule_version_offer_group_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_offer_group_aggregate_bool_exp_count>;
};

export type offer_rule_version_offer_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_aggregate_fields = {
  __typename: 'offer_rule_version_offer_group_aggregate_fields';
  avg?: Maybe<offer_rule_version_offer_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_offer_group_max_fields>;
  min?: Maybe<offer_rule_version_offer_group_min_fields>;
  stddev?: Maybe<offer_rule_version_offer_group_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_offer_group_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_offer_group_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_offer_group_sum_fields>;
  var_pop?: Maybe<offer_rule_version_offer_group_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_offer_group_var_samp_fields>;
  variance?: Maybe<offer_rule_version_offer_group_variance_fields>;
};


/** aggregate fields of "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_offer_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_offer_group_max_order_by>;
  min?: InputMaybe<offer_rule_version_offer_group_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_offer_group_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_offer_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_offer_group_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_offer_group_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_offer_group_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_offer_group_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_offer_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_arr_rel_insert_input = {
  data: Array<offer_rule_version_offer_group_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_offer_group_avg_fields = {
  __typename: 'offer_rule_version_offer_group_avg_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_avg_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_offer_group". All fields are combined with a logical 'AND'. */
export type offer_rule_version_offer_group_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_offer_group_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_offer_group_bool_exp>>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_insert_input = {
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_offer_group_max_fields = {
  __typename: 'offer_rule_version_offer_group_max_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_max_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_offer_group_min_fields = {
  __typename: 'offer_rule_version_offer_group_min_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_min_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_offer_group". */
export type offer_rule_version_offer_group_order_by = {
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_offer_group" */
export enum offer_rule_version_offer_group_select_column {
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_version_offer_group_stddev_fields = {
  __typename: 'offer_rule_version_offer_group_stddev_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_stddev_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_offer_group_stddev_pop_fields = {
  __typename: 'offer_rule_version_offer_group_stddev_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_stddev_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_offer_group_stddev_samp_fields = {
  __typename: 'offer_rule_version_offer_group_stddev_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_stddev_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_offer_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_offer_group_stream_cursor_value_input = {
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_offer_group_sum_fields = {
  __typename: 'offer_rule_version_offer_group_sum_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_sum_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_offer_group_var_pop_fields = {
  __typename: 'offer_rule_version_offer_group_var_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_var_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_offer_group_var_samp_fields = {
  __typename: 'offer_rule_version_offer_group_var_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_var_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_offer_group_variance_fields = {
  __typename: 'offer_rule_version_offer_group_variance_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_offer_group" */
export type offer_rule_version_offer_group_variance_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** input type for inserting data into table "offer_rule_version_offer" */
export type offer_rule_version_offer_insert_input = {
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_offer_max_fields = {
  __typename: 'offer_rule_version_offer_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_offer_min_fields = {
  __typename: 'offer_rule_version_offer_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_offer". */
export type offer_rule_version_offer_order_by = {
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule = {
  __typename: 'offer_rule_version_offer_rule_rule';
  /** An object relationship */
  offer_rule_rule?: Maybe<offer_rule_rule>;
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_aggregate = {
  __typename: 'offer_rule_version_offer_rule_rule_aggregate';
  aggregate?: Maybe<offer_rule_version_offer_rule_rule_aggregate_fields>;
  nodes: Array<offer_rule_version_offer_rule_rule>;
};

export type offer_rule_version_offer_rule_rule_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_offer_rule_rule_aggregate_bool_exp_count>;
};

export type offer_rule_version_offer_rule_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_aggregate_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_aggregate_fields';
  avg?: Maybe<offer_rule_version_offer_rule_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_offer_rule_rule_max_fields>;
  min?: Maybe<offer_rule_version_offer_rule_rule_min_fields>;
  stddev?: Maybe<offer_rule_version_offer_rule_rule_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_offer_rule_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_offer_rule_rule_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_offer_rule_rule_sum_fields>;
  var_pop?: Maybe<offer_rule_version_offer_rule_rule_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_offer_rule_rule_var_samp_fields>;
  variance?: Maybe<offer_rule_version_offer_rule_rule_variance_fields>;
};


/** aggregate fields of "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_offer_rule_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_offer_rule_rule_max_order_by>;
  min?: InputMaybe<offer_rule_version_offer_rule_rule_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_offer_rule_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_offer_rule_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_offer_rule_rule_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_offer_rule_rule_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_offer_rule_rule_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_offer_rule_rule_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_offer_rule_rule_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_arr_rel_insert_input = {
  data: Array<offer_rule_version_offer_rule_rule_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_offer_rule_rule_avg_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_avg_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_avg_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_offer_rule_rule". All fields are combined with a logical 'AND'. */
export type offer_rule_version_offer_rule_rule_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_offer_rule_rule_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_offer_rule_rule_bool_exp>>;
  offer_rule_rule?: InputMaybe<offer_rule_rule_bool_exp>;
  offer_rule_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_insert_input = {
  offer_rule_rule?: InputMaybe<offer_rule_rule_obj_rel_insert_input>;
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_offer_rule_rule_max_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_max_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_max_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_offer_rule_rule_min_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_min_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_min_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_offer_rule_rule". */
export type offer_rule_version_offer_rule_rule_order_by = {
  offer_rule_rule?: InputMaybe<offer_rule_rule_order_by>;
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_offer_rule_rule" */
export enum offer_rule_version_offer_rule_rule_select_column {
  /** column name */
  offer_rule_rule_id = 'offer_rule_rule_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_version_offer_rule_rule_stddev_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_stddev_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_stddev_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_offer_rule_rule_stddev_pop_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_stddev_pop_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_stddev_pop_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_offer_rule_rule_stddev_samp_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_stddev_samp_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_stddev_samp_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_offer_rule_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_offer_rule_rule_stream_cursor_value_input = {
  offer_rule_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_offer_rule_rule_sum_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_sum_fields';
  offer_rule_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_sum_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_offer_rule_rule_var_pop_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_var_pop_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_var_pop_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_offer_rule_rule_var_samp_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_var_samp_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_var_samp_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_offer_rule_rule_variance_fields = {
  __typename: 'offer_rule_version_offer_rule_rule_variance_fields';
  offer_rule_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_offer_rule_rule" */
export type offer_rule_version_offer_rule_rule_variance_order_by = {
  offer_rule_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_offer" */
export enum offer_rule_version_offer_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_version_offer_stddev_fields = {
  __typename: 'offer_rule_version_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_offer_stddev_pop_fields = {
  __typename: 'offer_rule_version_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_offer_stddev_samp_fields = {
  __typename: 'offer_rule_version_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_offer" */
export type offer_rule_version_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_offer_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_offer_sum_fields = {
  __typename: 'offer_rule_version_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test = {
  __typename: 'offer_rule_version_offer_test';
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_test?: Maybe<offer_test>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_aggregate = {
  __typename: 'offer_rule_version_offer_test_aggregate';
  aggregate?: Maybe<offer_rule_version_offer_test_aggregate_fields>;
  nodes: Array<offer_rule_version_offer_test>;
};

export type offer_rule_version_offer_test_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_offer_test_aggregate_bool_exp_count>;
};

export type offer_rule_version_offer_test_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_aggregate_fields = {
  __typename: 'offer_rule_version_offer_test_aggregate_fields';
  avg?: Maybe<offer_rule_version_offer_test_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_offer_test_max_fields>;
  min?: Maybe<offer_rule_version_offer_test_min_fields>;
  stddev?: Maybe<offer_rule_version_offer_test_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_offer_test_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_offer_test_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_offer_test_sum_fields>;
  var_pop?: Maybe<offer_rule_version_offer_test_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_offer_test_var_samp_fields>;
  variance?: Maybe<offer_rule_version_offer_test_variance_fields>;
};


/** aggregate fields of "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_offer_test_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_offer_test_max_order_by>;
  min?: InputMaybe<offer_rule_version_offer_test_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_offer_test_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_offer_test_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_offer_test_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_offer_test_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_offer_test_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_offer_test_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_offer_test_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_arr_rel_insert_input = {
  data: Array<offer_rule_version_offer_test_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_offer_test_avg_fields = {
  __typename: 'offer_rule_version_offer_test_avg_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_avg_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_offer_test". All fields are combined with a logical 'AND'. */
export type offer_rule_version_offer_test_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_offer_test_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_offer_test_bool_exp>>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_insert_input = {
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_offer_test_max_fields = {
  __typename: 'offer_rule_version_offer_test_max_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_max_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_offer_test_min_fields = {
  __typename: 'offer_rule_version_offer_test_min_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_min_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_offer_test". */
export type offer_rule_version_offer_test_order_by = {
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_offer_test" */
export enum offer_rule_version_offer_test_select_column {
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  position = 'position'
}

/** aggregate stddev on columns */
export type offer_rule_version_offer_test_stddev_fields = {
  __typename: 'offer_rule_version_offer_test_stddev_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_stddev_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_offer_test_stddev_pop_fields = {
  __typename: 'offer_rule_version_offer_test_stddev_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_stddev_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_offer_test_stddev_samp_fields = {
  __typename: 'offer_rule_version_offer_test_stddev_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_stddev_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_offer_test_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_offer_test_stream_cursor_value_input = {
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_offer_test_sum_fields = {
  __typename: 'offer_rule_version_offer_test_sum_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_sum_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_offer_test_var_pop_fields = {
  __typename: 'offer_rule_version_offer_test_var_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_var_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_offer_test_var_samp_fields = {
  __typename: 'offer_rule_version_offer_test_var_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_var_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_offer_test_variance_fields = {
  __typename: 'offer_rule_version_offer_test_variance_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_offer_test" */
export type offer_rule_version_offer_test_variance_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_offer_var_pop_fields = {
  __typename: 'offer_rule_version_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_offer_var_samp_fields = {
  __typename: 'offer_rule_version_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_offer_variance_fields = {
  __typename: 'offer_rule_version_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_offer" */
export type offer_rule_version_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_rule_version" */
export type offer_rule_version_on_conflict = {
  constraint: offer_rule_version_constraint;
  update_columns?: Array<offer_rule_version_update_column>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};

/** Ordering options when selecting data from "offer_rule_version". */
export type offer_rule_version_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  include_other_in_question_ids?: InputMaybe<order_by>;
  include_present_no_offer?: InputMaybe<order_by>;
  label?: InputMaybe<order_by>;
  offer_autopilot_offer_ids?: InputMaybe<order_by>;
  offer_group_ids?: InputMaybe<order_by>;
  offer_ids?: InputMaybe<order_by>;
  offer_rule?: InputMaybe<offer_rule_order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_rule_ids?: InputMaybe<order_by>;
  offer_rule_version_offer_autopilot_offers_aggregate?: InputMaybe<offer_rule_version_offer_autopilot_offer_aggregate_order_by>;
  offer_rule_version_offer_groups_aggregate?: InputMaybe<offer_rule_version_offer_group_aggregate_order_by>;
  offer_rule_version_offer_rule_rules_aggregate?: InputMaybe<offer_rule_version_offer_rule_rule_aggregate_order_by>;
  offer_rule_version_offer_tests_aggregate?: InputMaybe<offer_rule_version_offer_test_aggregate_order_by>;
  offer_rule_version_offers_aggregate?: InputMaybe<offer_rule_version_offer_aggregate_order_by>;
  offer_rule_version_question_options_aggregate?: InputMaybe<offer_rule_version_question_option_aggregate_order_by>;
  offer_rule_version_segment_groups_aggregate?: InputMaybe<offer_rule_version_segment_group_aggregate_order_by>;
  offer_rule_version_segments_aggregate?: InputMaybe<offer_rule_version_segment_aggregate_order_by>;
  offer_test_ids?: InputMaybe<order_by>;
  published_offer_rules_aggregate?: InputMaybe<offer_rule_aggregate_order_by>;
  question_option_ids?: InputMaybe<order_by>;
  segment_group_ids?: InputMaybe<order_by>;
  segment_ids?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_rule_version */
export type offer_rule_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_rule_version_prepend_input = {
  include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "offer_rule_version_question_option" */
export type offer_rule_version_question_option = {
  __typename: 'offer_rule_version_question_option';
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_question_option" */
export type offer_rule_version_question_option_aggregate = {
  __typename: 'offer_rule_version_question_option_aggregate';
  aggregate?: Maybe<offer_rule_version_question_option_aggregate_fields>;
  nodes: Array<offer_rule_version_question_option>;
};

export type offer_rule_version_question_option_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_question_option_aggregate_bool_exp_count>;
};

export type offer_rule_version_question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_question_option" */
export type offer_rule_version_question_option_aggregate_fields = {
  __typename: 'offer_rule_version_question_option_aggregate_fields';
  avg?: Maybe<offer_rule_version_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_question_option_max_fields>;
  min?: Maybe<offer_rule_version_question_option_min_fields>;
  stddev?: Maybe<offer_rule_version_question_option_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_question_option_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_question_option_sum_fields>;
  var_pop?: Maybe<offer_rule_version_question_option_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_question_option_var_samp_fields>;
  variance?: Maybe<offer_rule_version_question_option_variance_fields>;
};


/** aggregate fields of "offer_rule_version_question_option" */
export type offer_rule_version_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_question_option_max_order_by>;
  min?: InputMaybe<offer_rule_version_question_option_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_question_option_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_question_option_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_question_option_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_question_option_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_arr_rel_insert_input = {
  data: Array<offer_rule_version_question_option_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_question_option_avg_fields = {
  __typename: 'offer_rule_version_question_option_avg_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_avg_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_question_option". All fields are combined with a logical 'AND'. */
export type offer_rule_version_question_option_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_question_option_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_question_option_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_question_option_bool_exp>>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_insert_input = {
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_question_option_max_fields = {
  __typename: 'offer_rule_version_question_option_max_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_max_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_question_option_min_fields = {
  __typename: 'offer_rule_version_question_option_min_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_min_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_question_option". */
export type offer_rule_version_question_option_order_by = {
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_question_option" */
export enum offer_rule_version_question_option_select_column {
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position',
  /** column name */
  question_option_id = 'question_option_id'
}

/** aggregate stddev on columns */
export type offer_rule_version_question_option_stddev_fields = {
  __typename: 'offer_rule_version_question_option_stddev_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_stddev_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_question_option_stddev_pop_fields = {
  __typename: 'offer_rule_version_question_option_stddev_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_stddev_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_question_option_stddev_samp_fields = {
  __typename: 'offer_rule_version_question_option_stddev_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_stddev_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_question_option_stream_cursor_value_input = {
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_question_option_sum_fields = {
  __typename: 'offer_rule_version_question_option_sum_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_sum_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_question_option_var_pop_fields = {
  __typename: 'offer_rule_version_question_option_var_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_var_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_question_option_var_samp_fields = {
  __typename: 'offer_rule_version_question_option_var_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_var_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_question_option_variance_fields = {
  __typename: 'offer_rule_version_question_option_variance_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_question_option" */
export type offer_rule_version_question_option_variance_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_rule_version_segment" */
export type offer_rule_version_segment = {
  __typename: 'offer_rule_version_segment';
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment?: Maybe<segment>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_segment" */
export type offer_rule_version_segment_aggregate = {
  __typename: 'offer_rule_version_segment_aggregate';
  aggregate?: Maybe<offer_rule_version_segment_aggregate_fields>;
  nodes: Array<offer_rule_version_segment>;
};

export type offer_rule_version_segment_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_segment_aggregate_bool_exp_count>;
};

export type offer_rule_version_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_segment" */
export type offer_rule_version_segment_aggregate_fields = {
  __typename: 'offer_rule_version_segment_aggregate_fields';
  avg?: Maybe<offer_rule_version_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_segment_max_fields>;
  min?: Maybe<offer_rule_version_segment_min_fields>;
  stddev?: Maybe<offer_rule_version_segment_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_segment_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_segment_sum_fields>;
  var_pop?: Maybe<offer_rule_version_segment_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_segment_var_samp_fields>;
  variance?: Maybe<offer_rule_version_segment_variance_fields>;
};


/** aggregate fields of "offer_rule_version_segment" */
export type offer_rule_version_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_segment" */
export type offer_rule_version_segment_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_segment_max_order_by>;
  min?: InputMaybe<offer_rule_version_segment_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_segment_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_segment_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_segment_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_segment_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_segment" */
export type offer_rule_version_segment_arr_rel_insert_input = {
  data: Array<offer_rule_version_segment_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_segment_avg_fields = {
  __typename: 'offer_rule_version_segment_avg_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_avg_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_segment". All fields are combined with a logical 'AND'. */
export type offer_rule_version_segment_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_segment_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_segment_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_segment_bool_exp>>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group = {
  __typename: 'offer_rule_version_segment_group';
  /** An object relationship */
  offer_rule_version?: Maybe<offer_rule_version>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment_group?: Maybe<segment_group>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_aggregate = {
  __typename: 'offer_rule_version_segment_group_aggregate';
  aggregate?: Maybe<offer_rule_version_segment_group_aggregate_fields>;
  nodes: Array<offer_rule_version_segment_group>;
};

export type offer_rule_version_segment_group_aggregate_bool_exp = {
  count?: InputMaybe<offer_rule_version_segment_group_aggregate_bool_exp_count>;
};

export type offer_rule_version_segment_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_aggregate_fields = {
  __typename: 'offer_rule_version_segment_group_aggregate_fields';
  avg?: Maybe<offer_rule_version_segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_rule_version_segment_group_max_fields>;
  min?: Maybe<offer_rule_version_segment_group_min_fields>;
  stddev?: Maybe<offer_rule_version_segment_group_stddev_fields>;
  stddev_pop?: Maybe<offer_rule_version_segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_rule_version_segment_group_stddev_samp_fields>;
  sum?: Maybe<offer_rule_version_segment_group_sum_fields>;
  var_pop?: Maybe<offer_rule_version_segment_group_var_pop_fields>;
  var_samp?: Maybe<offer_rule_version_segment_group_var_samp_fields>;
  variance?: Maybe<offer_rule_version_segment_group_variance_fields>;
};


/** aggregate fields of "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_aggregate_order_by = {
  avg?: InputMaybe<offer_rule_version_segment_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_rule_version_segment_group_max_order_by>;
  min?: InputMaybe<offer_rule_version_segment_group_min_order_by>;
  stddev?: InputMaybe<offer_rule_version_segment_group_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_rule_version_segment_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_rule_version_segment_group_stddev_samp_order_by>;
  sum?: InputMaybe<offer_rule_version_segment_group_sum_order_by>;
  var_pop?: InputMaybe<offer_rule_version_segment_group_var_pop_order_by>;
  var_samp?: InputMaybe<offer_rule_version_segment_group_var_samp_order_by>;
  variance?: InputMaybe<offer_rule_version_segment_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_arr_rel_insert_input = {
  data: Array<offer_rule_version_segment_group_insert_input>;
};

/** aggregate avg on columns */
export type offer_rule_version_segment_group_avg_fields = {
  __typename: 'offer_rule_version_segment_group_avg_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_avg_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_rule_version_segment_group". All fields are combined with a logical 'AND'. */
export type offer_rule_version_segment_group_bool_exp = {
  _and?: InputMaybe<Array<offer_rule_version_segment_group_bool_exp>>;
  _not?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
  _or?: InputMaybe<Array<offer_rule_version_segment_group_bool_exp>>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_insert_input = {
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_segment_group_max_fields = {
  __typename: 'offer_rule_version_segment_group_max_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_max_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_segment_group_min_fields = {
  __typename: 'offer_rule_version_segment_group_min_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_min_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_segment_group". */
export type offer_rule_version_segment_group_order_by = {
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_segment_group" */
export enum offer_rule_version_segment_group_select_column {
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_group_id = 'segment_group_id'
}

/** aggregate stddev on columns */
export type offer_rule_version_segment_group_stddev_fields = {
  __typename: 'offer_rule_version_segment_group_stddev_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_stddev_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_segment_group_stddev_pop_fields = {
  __typename: 'offer_rule_version_segment_group_stddev_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_stddev_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_segment_group_stddev_samp_fields = {
  __typename: 'offer_rule_version_segment_group_stddev_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_stddev_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_segment_group_stream_cursor_value_input = {
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_segment_group_sum_fields = {
  __typename: 'offer_rule_version_segment_group_sum_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_sum_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_segment_group_var_pop_fields = {
  __typename: 'offer_rule_version_segment_group_var_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_var_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_segment_group_var_samp_fields = {
  __typename: 'offer_rule_version_segment_group_var_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_var_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_segment_group_variance_fields = {
  __typename: 'offer_rule_version_segment_group_variance_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_segment_group" */
export type offer_rule_version_segment_group_variance_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** input type for inserting data into table "offer_rule_version_segment" */
export type offer_rule_version_segment_insert_input = {
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_rule_version_segment_max_fields = {
  __typename: 'offer_rule_version_segment_max_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_max_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_rule_version_segment_min_fields = {
  __typename: 'offer_rule_version_segment_min_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_min_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_rule_version_segment". */
export type offer_rule_version_segment_order_by = {
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version_segment" */
export enum offer_rule_version_segment_select_column {
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_id = 'segment_id'
}

/** aggregate stddev on columns */
export type offer_rule_version_segment_stddev_fields = {
  __typename: 'offer_rule_version_segment_stddev_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_stddev_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_segment_stddev_pop_fields = {
  __typename: 'offer_rule_version_segment_stddev_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_stddev_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_segment_stddev_samp_fields = {
  __typename: 'offer_rule_version_segment_stddev_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_stddev_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_rule_version_segment" */
export type offer_rule_version_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_segment_stream_cursor_value_input = {
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_segment_sum_fields = {
  __typename: 'offer_rule_version_segment_sum_fields';
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_sum_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_rule_version_segment_var_pop_fields = {
  __typename: 'offer_rule_version_segment_var_pop_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_var_pop_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_segment_var_samp_fields = {
  __typename: 'offer_rule_version_segment_var_samp_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_var_samp_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_rule_version_segment_variance_fields = {
  __typename: 'offer_rule_version_segment_variance_fields';
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_rule_version_segment" */
export type offer_rule_version_segment_variance_order_by = {
  offer_rule_version_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_rule_version" */
export enum offer_rule_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  include_other_in_question_ids = 'include_other_in_question_ids',
  /** column name */
  include_present_no_offer = 'include_present_no_offer',
  /** column name */
  label = 'label',
  /** column name */
  offer_autopilot_offer_ids = 'offer_autopilot_offer_ids',
  /** column name */
  offer_group_ids = 'offer_group_ids',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_rule_ids = 'offer_rule_rule_ids',
  /** column name */
  offer_test_ids = 'offer_test_ids',
  /** column name */
  question_option_ids = 'question_option_ids',
  /** column name */
  segment_group_ids = 'segment_group_ids',
  /** column name */
  segment_ids = 'segment_ids',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_rule_version" */
export type offer_rule_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_rule_version_stddev_fields = {
  __typename: 'offer_rule_version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_rule_version_stddev_pop_fields = {
  __typename: 'offer_rule_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_rule_version_stddev_samp_fields = {
  __typename: 'offer_rule_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_rule_version" */
export type offer_rule_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_rule_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_rule_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  include_present_no_offer?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  offer_autopilot_offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_rule_ids?: InputMaybe<Scalars['jsonb']['input']>;
  offer_test_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_rule_version_sum_fields = {
  __typename: 'offer_rule_version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_rule_version" */
export enum offer_rule_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  include_other_in_question_ids = 'include_other_in_question_ids',
  /** column name */
  include_present_no_offer = 'include_present_no_offer',
  /** column name */
  label = 'label',
  /** column name */
  offer_autopilot_offer_ids = 'offer_autopilot_offer_ids',
  /** column name */
  offer_group_ids = 'offer_group_ids',
  /** column name */
  offer_ids = 'offer_ids',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_rule_ids = 'offer_rule_rule_ids',
  /** column name */
  offer_test_ids = 'offer_test_ids',
  /** column name */
  question_option_ids = 'question_option_ids',
  /** column name */
  segment_group_ids = 'segment_group_ids',
  /** column name */
  segment_ids = 'segment_ids',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_rule_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_rule_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_rule_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_rule_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_rule_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_rule_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_rule_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_rule_version_set_input>;
  /** filter the rows which have to be updated */
  where: offer_rule_version_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_rule_version_var_pop_fields = {
  __typename: 'offer_rule_version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_rule_version_var_samp_fields = {
  __typename: 'offer_rule_version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_rule_version_variance_fields = {
  __typename: 'offer_rule_version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "offer" */
export enum offer_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  archived_at = 'archived_at',
  /** column name */
  button_text_translation_id = 'button_text_translation_id',
  /** column name */
  confirmation_cancel_button_text_translation_id = 'confirmation_cancel_button_text_translation_id',
  /** column name */
  confirmation_confirm_button_text_translation_id = 'confirmation_confirm_button_text_translation_id',
  /** column name */
  confirmation_content_translation_id = 'confirmation_content_translation_id',
  /** column name */
  confirmation_enabled = 'confirmation_enabled',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  image_url = 'image_url',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  override_active_offer = 'override_active_offer',
  /** column name */
  show_banner = 'show_banner',
  /** column name */
  style = 'style',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** select "offer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "offer" */
export enum offer_select_column_offer_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  confirmation_enabled = 'confirmation_enabled',
  /** column name */
  override_active_offer = 'override_active_offer',
  /** column name */
  show_banner = 'show_banner'
}

/** select "offer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "offer" */
export enum offer_select_column_offer_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  confirmation_enabled = 'confirmation_enabled',
  /** column name */
  override_active_offer = 'override_active_offer',
  /** column name */
  show_banner = 'show_banner'
}

/** input type for updating data in table "offer" */
export type offer_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  override_active_offer?: InputMaybe<Scalars['Boolean']['input']>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<offer_style_enum>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_stddev_fields = {
  __typename: 'offer_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer" */
export type offer_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_stddev_pop_fields = {
  __typename: 'offer_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer" */
export type offer_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_stddev_samp_fields = {
  __typename: 'offer_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer" */
export type offer_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer" */
export type offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  confirmation_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  override_active_offer?: InputMaybe<Scalars['Boolean']['input']>;
  show_banner?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<offer_style_enum>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "offer_style" */
export type offer_style = {
  __typename: 'offer_style';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_style" */
export type offer_style_aggregate = {
  __typename: 'offer_style_aggregate';
  aggregate?: Maybe<offer_style_aggregate_fields>;
  nodes: Array<offer_style>;
};

/** aggregate fields of "offer_style" */
export type offer_style_aggregate_fields = {
  __typename: 'offer_style_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_style_max_fields>;
  min?: Maybe<offer_style_min_fields>;
};


/** aggregate fields of "offer_style" */
export type offer_style_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_style_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_style". All fields are combined with a logical 'AND'. */
export type offer_style_bool_exp = {
  _and?: InputMaybe<Array<offer_style_bool_exp>>;
  _not?: InputMaybe<offer_style_bool_exp>;
  _or?: InputMaybe<Array<offer_style_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_style" */
export enum offer_style_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_style_pkey = 'offer_style_pkey'
}

export enum offer_style_enum {
  banner = 'banner',
  modal = 'modal',
  step = 'step'
}

/** Boolean expression to compare columns of type "offer_style_enum". All fields are combined with logical 'AND'. */
export type offer_style_enum_comparison_exp = {
  _eq?: InputMaybe<offer_style_enum>;
  _in?: InputMaybe<Array<offer_style_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_style_enum>;
  _nin?: InputMaybe<Array<offer_style_enum>>;
};

/** input type for inserting data into table "offer_style" */
export type offer_style_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_style_max_fields = {
  __typename: 'offer_style_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_style_min_fields = {
  __typename: 'offer_style_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_style" */
export type offer_style_mutation_response = {
  __typename: 'offer_style_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_style>;
};

/** on_conflict condition type for table "offer_style" */
export type offer_style_on_conflict = {
  constraint: offer_style_constraint;
  update_columns?: Array<offer_style_update_column>;
  where?: InputMaybe<offer_style_bool_exp>;
};

/** Ordering options when selecting data from "offer_style". */
export type offer_style_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_style */
export type offer_style_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_style" */
export enum offer_style_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_style" */
export type offer_style_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_style" */
export type offer_style_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_style_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_style_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_style" */
export enum offer_style_update_column {
  /** column name */
  value = 'value'
}

export type offer_style_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_style_set_input>;
  /** filter the rows which have to be updated */
  where: offer_style_bool_exp;
};

/** aggregate sum on columns */
export type offer_sum_fields = {
  __typename: 'offer_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer" */
export type offer_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_tag" */
export type offer_tag = {
  __typename: 'offer_tag';
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tag?: Maybe<tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "offer_tag" */
export type offer_tag_aggregate = {
  __typename: 'offer_tag_aggregate';
  aggregate?: Maybe<offer_tag_aggregate_fields>;
  nodes: Array<offer_tag>;
};

export type offer_tag_aggregate_bool_exp = {
  count?: InputMaybe<offer_tag_aggregate_bool_exp_count>;
};

export type offer_tag_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_tag_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_tag" */
export type offer_tag_aggregate_fields = {
  __typename: 'offer_tag_aggregate_fields';
  avg?: Maybe<offer_tag_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_tag_max_fields>;
  min?: Maybe<offer_tag_min_fields>;
  stddev?: Maybe<offer_tag_stddev_fields>;
  stddev_pop?: Maybe<offer_tag_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_tag_stddev_samp_fields>;
  sum?: Maybe<offer_tag_sum_fields>;
  var_pop?: Maybe<offer_tag_var_pop_fields>;
  var_samp?: Maybe<offer_tag_var_samp_fields>;
  variance?: Maybe<offer_tag_variance_fields>;
};


/** aggregate fields of "offer_tag" */
export type offer_tag_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_tag" */
export type offer_tag_aggregate_order_by = {
  avg?: InputMaybe<offer_tag_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_tag_max_order_by>;
  min?: InputMaybe<offer_tag_min_order_by>;
  stddev?: InputMaybe<offer_tag_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_tag_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_tag_stddev_samp_order_by>;
  sum?: InputMaybe<offer_tag_sum_order_by>;
  var_pop?: InputMaybe<offer_tag_var_pop_order_by>;
  var_samp?: InputMaybe<offer_tag_var_samp_order_by>;
  variance?: InputMaybe<offer_tag_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_tag" */
export type offer_tag_arr_rel_insert_input = {
  data: Array<offer_tag_insert_input>;
};

/** aggregate avg on columns */
export type offer_tag_avg_fields = {
  __typename: 'offer_tag_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_tag" */
export type offer_tag_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_tag". All fields are combined with a logical 'AND'. */
export type offer_tag_bool_exp = {
  _and?: InputMaybe<Array<offer_tag_bool_exp>>;
  _not?: InputMaybe<offer_tag_bool_exp>;
  _or?: InputMaybe<Array<offer_tag_bool_exp>>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  tag?: InputMaybe<tag_bool_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "offer_tag" */
export type offer_tag_insert_input = {
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<tag_obj_rel_insert_input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_tag_max_fields = {
  __typename: 'offer_tag_max_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_tag" */
export type offer_tag_max_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_tag_min_fields = {
  __typename: 'offer_tag_min_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_tag" */
export type offer_tag_min_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "offer_tag". */
export type offer_tag_order_by = {
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  tag?: InputMaybe<tag_order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** select columns of table "offer_tag" */
export enum offer_tag_select_column {
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  tag_id = 'tag_id'
}

/** aggregate stddev on columns */
export type offer_tag_stddev_fields = {
  __typename: 'offer_tag_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_tag" */
export type offer_tag_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_tag_stddev_pop_fields = {
  __typename: 'offer_tag_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_tag" */
export type offer_tag_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_tag_stddev_samp_fields = {
  __typename: 'offer_tag_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_tag" */
export type offer_tag_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_tag" */
export type offer_tag_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_tag_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_tag_stream_cursor_value_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_tag_sum_fields = {
  __typename: 'offer_tag_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_tag" */
export type offer_tag_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type offer_tag_var_pop_fields = {
  __typename: 'offer_tag_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_tag" */
export type offer_tag_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_tag_var_samp_fields = {
  __typename: 'offer_tag_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_tag" */
export type offer_tag_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_tag_variance_fields = {
  __typename: 'offer_tag_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_tag" */
export type offer_tag_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_test" */
export type offer_test = {
  __typename: 'offer_test';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  confidence_threshold: Scalars['numeric']['output'];
  /** An object relationship */
  control_offer: offer;
  control_offer_id: Scalars['Int']['output'];
  control_weight: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  goal: offer_goal_enum;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  offer_rule_rule_offer_tests: Array<offer_rule_rule_offer_test>;
  /** An aggregate relationship */
  offer_rule_rule_offer_tests_aggregate: offer_rule_rule_offer_test_aggregate;
  /** An array relationship */
  offer_rule_version_offer_tests: Array<offer_rule_version_offer_test>;
  /** An aggregate relationship */
  offer_rule_version_offer_tests_aggregate: offer_rule_version_offer_test_aggregate;
  /** An array relationship */
  offer_test_offers: Array<offer_test_offer>;
  /** An aggregate relationship */
  offer_test_offers_aggregate: offer_test_offer_aggregate;
  /** An array relationship */
  report_offer_test_stats: Array<report_offer_test_stats>;
  /** An aggregate relationship */
  report_offer_test_stats_aggregate: report_offer_test_stats_aggregate;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  winning_offer?: Maybe<offer>;
  winning_offer_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_rule_rule_offer_testsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_rule_rule_offer_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_rule_version_offer_testsArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_rule_version_offer_tests_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_test_offersArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testoffer_test_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testreport_offer_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


/** columns and relationships of "offer_test" */
export type offer_testreport_offer_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};

/** aggregated selection of "offer_test" */
export type offer_test_aggregate = {
  __typename: 'offer_test_aggregate';
  aggregate?: Maybe<offer_test_aggregate_fields>;
  nodes: Array<offer_test>;
};

export type offer_test_aggregate_bool_exp = {
  count?: InputMaybe<offer_test_aggregate_bool_exp_count>;
};

export type offer_test_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_test_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_test" */
export type offer_test_aggregate_fields = {
  __typename: 'offer_test_aggregate_fields';
  avg?: Maybe<offer_test_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_test_max_fields>;
  min?: Maybe<offer_test_min_fields>;
  stddev?: Maybe<offer_test_stddev_fields>;
  stddev_pop?: Maybe<offer_test_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_test_stddev_samp_fields>;
  sum?: Maybe<offer_test_sum_fields>;
  var_pop?: Maybe<offer_test_var_pop_fields>;
  var_samp?: Maybe<offer_test_var_samp_fields>;
  variance?: Maybe<offer_test_variance_fields>;
};


/** aggregate fields of "offer_test" */
export type offer_test_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_test_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_test" */
export type offer_test_aggregate_order_by = {
  avg?: InputMaybe<offer_test_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_test_max_order_by>;
  min?: InputMaybe<offer_test_min_order_by>;
  stddev?: InputMaybe<offer_test_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_test_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_test_stddev_samp_order_by>;
  sum?: InputMaybe<offer_test_sum_order_by>;
  var_pop?: InputMaybe<offer_test_var_pop_order_by>;
  var_samp?: InputMaybe<offer_test_var_samp_order_by>;
  variance?: InputMaybe<offer_test_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_test" */
export type offer_test_arr_rel_insert_input = {
  data: Array<offer_test_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_test_on_conflict>;
};

/** aggregate avg on columns */
export type offer_test_avg_fields = {
  __typename: 'offer_test_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_test" */
export type offer_test_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_test". All fields are combined with a logical 'AND'. */
export type offer_test_bool_exp = {
  _and?: InputMaybe<Array<offer_test_bool_exp>>;
  _not?: InputMaybe<offer_test_bool_exp>;
  _or?: InputMaybe<Array<offer_test_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  confidence_threshold?: InputMaybe<numeric_comparison_exp>;
  control_offer?: InputMaybe<offer_bool_exp>;
  control_offer_id?: InputMaybe<Int_comparison_exp>;
  control_weight?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  goal?: InputMaybe<offer_goal_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer_rule_rule_offer_tests?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
  offer_rule_rule_offer_tests_aggregate?: InputMaybe<offer_rule_rule_offer_test_aggregate_bool_exp>;
  offer_rule_version_offer_tests?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
  offer_rule_version_offer_tests_aggregate?: InputMaybe<offer_rule_version_offer_test_aggregate_bool_exp>;
  offer_test_offers?: InputMaybe<offer_test_offer_bool_exp>;
  offer_test_offers_aggregate?: InputMaybe<offer_test_offer_aggregate_bool_exp>;
  report_offer_test_stats?: InputMaybe<report_offer_test_stats_bool_exp>;
  report_offer_test_stats_aggregate?: InputMaybe<report_offer_test_stats_aggregate_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  winning_offer?: InputMaybe<offer_bool_exp>;
  winning_offer_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "offer_test" */
export enum offer_test_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_test_pkey = 'offer_test_pkey',
  /** unique or primary key constraint on columns "token" */
  offer_test_token_key = 'offer_test_token_key'
}

/** input type for incrementing numeric columns in table "offer_test" */
export type offer_test_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_offer_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  winning_offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_test" */
export type offer_test_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_offer?: InputMaybe<offer_obj_rel_insert_input>;
  control_offer_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_rule_rule_offer_tests?: InputMaybe<offer_rule_rule_offer_test_arr_rel_insert_input>;
  offer_rule_version_offer_tests?: InputMaybe<offer_rule_version_offer_test_arr_rel_insert_input>;
  offer_test_offers?: InputMaybe<offer_test_offer_arr_rel_insert_input>;
  report_offer_test_stats?: InputMaybe<report_offer_test_stats_arr_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_offer?: InputMaybe<offer_obj_rel_insert_input>;
  winning_offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_test_max_fields = {
  __typename: 'offer_test_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_offer_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_test" */
export type offer_test_max_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_test_min_fields = {
  __typename: 'offer_test_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_offer_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  winning_offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_test" */
export type offer_test_min_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_test" */
export type offer_test_mutation_response = {
  __typename: 'offer_test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_test>;
};

/** input type for inserting object relation for remote table "offer_test" */
export type offer_test_obj_rel_insert_input = {
  data: offer_test_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_test_on_conflict>;
};

/** columns and relationships of "offer_test_offer" */
export type offer_test_offer = {
  __typename: 'offer_test_offer';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_test: offer_test;
  offer_test_id: Scalars['Int']['output'];
  weight: Scalars['Int']['output'];
};

/** aggregated selection of "offer_test_offer" */
export type offer_test_offer_aggregate = {
  __typename: 'offer_test_offer_aggregate';
  aggregate?: Maybe<offer_test_offer_aggregate_fields>;
  nodes: Array<offer_test_offer>;
};

export type offer_test_offer_aggregate_bool_exp = {
  count?: InputMaybe<offer_test_offer_aggregate_bool_exp_count>;
};

export type offer_test_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_test_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_test_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_test_offer" */
export type offer_test_offer_aggregate_fields = {
  __typename: 'offer_test_offer_aggregate_fields';
  avg?: Maybe<offer_test_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_test_offer_max_fields>;
  min?: Maybe<offer_test_offer_min_fields>;
  stddev?: Maybe<offer_test_offer_stddev_fields>;
  stddev_pop?: Maybe<offer_test_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_test_offer_stddev_samp_fields>;
  sum?: Maybe<offer_test_offer_sum_fields>;
  var_pop?: Maybe<offer_test_offer_var_pop_fields>;
  var_samp?: Maybe<offer_test_offer_var_samp_fields>;
  variance?: Maybe<offer_test_offer_variance_fields>;
};


/** aggregate fields of "offer_test_offer" */
export type offer_test_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_test_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_test_offer" */
export type offer_test_offer_aggregate_order_by = {
  avg?: InputMaybe<offer_test_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_test_offer_max_order_by>;
  min?: InputMaybe<offer_test_offer_min_order_by>;
  stddev?: InputMaybe<offer_test_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_test_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_test_offer_stddev_samp_order_by>;
  sum?: InputMaybe<offer_test_offer_sum_order_by>;
  var_pop?: InputMaybe<offer_test_offer_var_pop_order_by>;
  var_samp?: InputMaybe<offer_test_offer_var_samp_order_by>;
  variance?: InputMaybe<offer_test_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_test_offer" */
export type offer_test_offer_arr_rel_insert_input = {
  data: Array<offer_test_offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_test_offer_on_conflict>;
};

/** aggregate avg on columns */
export type offer_test_offer_avg_fields = {
  __typename: 'offer_test_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_test_offer" */
export type offer_test_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_test_offer". All fields are combined with a logical 'AND'. */
export type offer_test_offer_bool_exp = {
  _and?: InputMaybe<Array<offer_test_offer_bool_exp>>;
  _not?: InputMaybe<offer_test_offer_bool_exp>;
  _or?: InputMaybe<Array<offer_test_offer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  weight?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "offer_test_offer" */
export enum offer_test_offer_constraint {
  /** unique or primary key constraint on columns "offer_id", "offer_test_id" */
  offer_test_offer_pkey = 'offer_test_offer_pkey'
}

/** input type for incrementing numeric columns in table "offer_test_offer" */
export type offer_test_offer_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_test_offer" */
export type offer_test_offer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type offer_test_offer_max_fields = {
  __typename: 'offer_test_offer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "offer_test_offer" */
export type offer_test_offer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_test_offer_min_fields = {
  __typename: 'offer_test_offer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "offer_test_offer" */
export type offer_test_offer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_test_offer" */
export type offer_test_offer_mutation_response = {
  __typename: 'offer_test_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_test_offer>;
};

/** on_conflict condition type for table "offer_test_offer" */
export type offer_test_offer_on_conflict = {
  constraint: offer_test_offer_constraint;
  update_columns?: Array<offer_test_offer_update_column>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};

/** Ordering options when selecting data from "offer_test_offer". */
export type offer_test_offer_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_test_offer */
export type offer_test_offer_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
  offer_test_id: Scalars['Int']['input'];
};

/** select columns of table "offer_test_offer" */
export enum offer_test_offer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  weight = 'weight'
}

/** input type for updating data in table "offer_test_offer" */
export type offer_test_offer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type offer_test_offer_stddev_fields = {
  __typename: 'offer_test_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_test_offer" */
export type offer_test_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_test_offer_stddev_pop_fields = {
  __typename: 'offer_test_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_test_offer" */
export type offer_test_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_test_offer_stddev_samp_fields = {
  __typename: 'offer_test_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_test_offer" */
export type offer_test_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_test_offer" */
export type offer_test_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_test_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_test_offer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_test_offer_sum_fields = {
  __typename: 'offer_test_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_test_offer" */
export type offer_test_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** update columns of table "offer_test_offer" */
export enum offer_test_offer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  weight = 'weight'
}

export type offer_test_offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_test_offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_test_offer_set_input>;
  /** filter the rows which have to be updated */
  where: offer_test_offer_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_test_offer_var_pop_fields = {
  __typename: 'offer_test_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_test_offer" */
export type offer_test_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_test_offer_var_samp_fields = {
  __typename: 'offer_test_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_test_offer" */
export type offer_test_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_test_offer_variance_fields = {
  __typename: 'offer_test_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_test_offer" */
export type offer_test_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  weight?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "offer_test" */
export type offer_test_on_conflict = {
  constraint: offer_test_constraint;
  update_columns?: Array<offer_test_update_column>;
  where?: InputMaybe<offer_test_bool_exp>;
};

/** Ordering options when selecting data from "offer_test". */
export type offer_test_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer?: InputMaybe<offer_order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  goal?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  offer_rule_rule_offer_tests_aggregate?: InputMaybe<offer_rule_rule_offer_test_aggregate_order_by>;
  offer_rule_version_offer_tests_aggregate?: InputMaybe<offer_rule_version_offer_test_aggregate_order_by>;
  offer_test_offers_aggregate?: InputMaybe<offer_test_offer_aggregate_order_by>;
  report_offer_test_stats_aggregate?: InputMaybe<report_offer_test_stats_aggregate_order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  winning_offer?: InputMaybe<offer_order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_test */
export type offer_test_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "offer_test" */
export enum offer_test_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  control_offer_id = 'control_offer_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_offer_id = 'winning_offer_id'
}

/** input type for updating data in table "offer_test" */
export type offer_test_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_offer_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type offer_test_stddev_fields = {
  __typename: 'offer_test_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_test" */
export type offer_test_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_test_stddev_pop_fields = {
  __typename: 'offer_test_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_test" */
export type offer_test_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_test_stddev_samp_fields = {
  __typename: 'offer_test_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_test" */
export type offer_test_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_test" */
export type offer_test_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_test_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_test_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_threshold?: InputMaybe<Scalars['numeric']['input']>;
  control_offer_id?: InputMaybe<Scalars['Int']['input']>;
  control_weight?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  goal?: InputMaybe<offer_goal_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  winning_offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type offer_test_sum_fields = {
  __typename: 'offer_test_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  confidence_threshold?: Maybe<Scalars['numeric']['output']>;
  control_offer_id?: Maybe<Scalars['Int']['output']>;
  control_weight?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  winning_offer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_test" */
export type offer_test_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_test" */
export enum offer_test_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  confidence_threshold = 'confidence_threshold',
  /** column name */
  control_offer_id = 'control_offer_id',
  /** column name */
  control_weight = 'control_weight',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  winning_offer_id = 'winning_offer_id'
}

export type offer_test_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_test_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_test_set_input>;
  /** filter the rows which have to be updated */
  where: offer_test_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_test_var_pop_fields = {
  __typename: 'offer_test_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_test" */
export type offer_test_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_test_var_samp_fields = {
  __typename: 'offer_test_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_test" */
export type offer_test_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_test_variance_fields = {
  __typename: 'offer_test_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  confidence_threshold?: Maybe<Scalars['Float']['output']>;
  control_offer_id?: Maybe<Scalars['Float']['output']>;
  control_weight?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  winning_offer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_test" */
export type offer_test_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  confidence_threshold?: InputMaybe<order_by>;
  control_offer_id?: InputMaybe<order_by>;
  control_weight?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  winning_offer_id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_timer" */
export type offer_timer = {
  __typename: 'offer_timer';
  duration?: Maybe<Scalars['interval']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  offer_timer_mode: offer_timer_mode_enum;
  show_timer?: Maybe<Scalars['Boolean']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "offer_timer" */
export type offer_timer_aggregate = {
  __typename: 'offer_timer_aggregate';
  aggregate?: Maybe<offer_timer_aggregate_fields>;
  nodes: Array<offer_timer>;
};

/** aggregate fields of "offer_timer" */
export type offer_timer_aggregate_fields = {
  __typename: 'offer_timer_aggregate_fields';
  avg?: Maybe<offer_timer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_timer_max_fields>;
  min?: Maybe<offer_timer_min_fields>;
  stddev?: Maybe<offer_timer_stddev_fields>;
  stddev_pop?: Maybe<offer_timer_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_timer_stddev_samp_fields>;
  sum?: Maybe<offer_timer_sum_fields>;
  var_pop?: Maybe<offer_timer_var_pop_fields>;
  var_samp?: Maybe<offer_timer_var_samp_fields>;
  variance?: Maybe<offer_timer_variance_fields>;
};


/** aggregate fields of "offer_timer" */
export type offer_timer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_timer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_timer_avg_fields = {
  __typename: 'offer_timer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_timer". All fields are combined with a logical 'AND'. */
export type offer_timer_bool_exp = {
  _and?: InputMaybe<Array<offer_timer_bool_exp>>;
  _not?: InputMaybe<offer_timer_bool_exp>;
  _or?: InputMaybe<Array<offer_timer_bool_exp>>;
  duration?: InputMaybe<interval_comparison_exp>;
  end_time?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_timer_mode?: InputMaybe<offer_timer_mode_enum_comparison_exp>;
  show_timer?: InputMaybe<Boolean_comparison_exp>;
  start_time?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_timer" */
export enum offer_timer_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_timer_pkey = 'offer_timer_pkey'
}

/** input type for incrementing numeric columns in table "offer_timer" */
export type offer_timer_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_timer" */
export type offer_timer_insert_input = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_timer_mode?: InputMaybe<offer_timer_mode_enum>;
  show_timer?: InputMaybe<Scalars['Boolean']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_timer_max_fields = {
  __typename: 'offer_timer_max_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_timer_min_fields = {
  __typename: 'offer_timer_min_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "offer_timer_mode" */
export type offer_timer_mode = {
  __typename: 'offer_timer_mode';
  value: Scalars['String']['output'];
};

/** aggregated selection of "offer_timer_mode" */
export type offer_timer_mode_aggregate = {
  __typename: 'offer_timer_mode_aggregate';
  aggregate?: Maybe<offer_timer_mode_aggregate_fields>;
  nodes: Array<offer_timer_mode>;
};

/** aggregate fields of "offer_timer_mode" */
export type offer_timer_mode_aggregate_fields = {
  __typename: 'offer_timer_mode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_timer_mode_max_fields>;
  min?: Maybe<offer_timer_mode_min_fields>;
};


/** aggregate fields of "offer_timer_mode" */
export type offer_timer_mode_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_timer_mode_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_timer_mode". All fields are combined with a logical 'AND'. */
export type offer_timer_mode_bool_exp = {
  _and?: InputMaybe<Array<offer_timer_mode_bool_exp>>;
  _not?: InputMaybe<offer_timer_mode_bool_exp>;
  _or?: InputMaybe<Array<offer_timer_mode_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_timer_mode" */
export enum offer_timer_mode_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_timer_mode_pkey = 'offer_timer_mode_pkey'
}

export enum offer_timer_mode_enum {
  fixed = 'fixed',
  from_first_seen = 'from_first_seen'
}

/** Boolean expression to compare columns of type "offer_timer_mode_enum". All fields are combined with logical 'AND'. */
export type offer_timer_mode_enum_comparison_exp = {
  _eq?: InputMaybe<offer_timer_mode_enum>;
  _in?: InputMaybe<Array<offer_timer_mode_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_timer_mode_enum>;
  _nin?: InputMaybe<Array<offer_timer_mode_enum>>;
};

/** input type for inserting data into table "offer_timer_mode" */
export type offer_timer_mode_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_timer_mode_max_fields = {
  __typename: 'offer_timer_mode_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_timer_mode_min_fields = {
  __typename: 'offer_timer_mode_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_timer_mode" */
export type offer_timer_mode_mutation_response = {
  __typename: 'offer_timer_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_timer_mode>;
};

/** on_conflict condition type for table "offer_timer_mode" */
export type offer_timer_mode_on_conflict = {
  constraint: offer_timer_mode_constraint;
  update_columns?: Array<offer_timer_mode_update_column>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};

/** Ordering options when selecting data from "offer_timer_mode". */
export type offer_timer_mode_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_timer_mode */
export type offer_timer_mode_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_timer_mode" */
export enum offer_timer_mode_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_timer_mode" */
export type offer_timer_mode_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_timer_mode" */
export type offer_timer_mode_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_timer_mode_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_timer_mode_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_timer_mode" */
export enum offer_timer_mode_update_column {
  /** column name */
  value = 'value'
}

export type offer_timer_mode_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_timer_mode_set_input>;
  /** filter the rows which have to be updated */
  where: offer_timer_mode_bool_exp;
};

/** response of any mutation on the table "offer_timer" */
export type offer_timer_mutation_response = {
  __typename: 'offer_timer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_timer>;
};

/** input type for inserting object relation for remote table "offer_timer" */
export type offer_timer_obj_rel_insert_input = {
  data: offer_timer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_timer_on_conflict>;
};

/** on_conflict condition type for table "offer_timer" */
export type offer_timer_on_conflict = {
  constraint: offer_timer_constraint;
  update_columns?: Array<offer_timer_update_column>;
  where?: InputMaybe<offer_timer_bool_exp>;
};

/** Ordering options when selecting data from "offer_timer". */
export type offer_timer_order_by = {
  duration?: InputMaybe<order_by>;
  end_time?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_timer_mode?: InputMaybe<order_by>;
  show_timer?: InputMaybe<order_by>;
  start_time?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_timer */
export type offer_timer_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_timer" */
export enum offer_timer_select_column {
  /** column name */
  duration = 'duration',
  /** column name */
  end_time = 'end_time',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_timer_mode = 'offer_timer_mode',
  /** column name */
  show_timer = 'show_timer',
  /** column name */
  start_time = 'start_time'
}

/** input type for updating data in table "offer_timer" */
export type offer_timer_set_input = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_timer_mode?: InputMaybe<offer_timer_mode_enum>;
  show_timer?: InputMaybe<Scalars['Boolean']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_timer_stddev_fields = {
  __typename: 'offer_timer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_timer_stddev_pop_fields = {
  __typename: 'offer_timer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_timer_stddev_samp_fields = {
  __typename: 'offer_timer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_timer" */
export type offer_timer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_timer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_timer_stream_cursor_value_input = {
  duration?: InputMaybe<Scalars['interval']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_timer_mode?: InputMaybe<offer_timer_mode_enum>;
  show_timer?: InputMaybe<Scalars['Boolean']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_timer_sum_fields = {
  __typename: 'offer_timer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_timer" */
export enum offer_timer_update_column {
  /** column name */
  duration = 'duration',
  /** column name */
  end_time = 'end_time',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_timer_mode = 'offer_timer_mode',
  /** column name */
  show_timer = 'show_timer',
  /** column name */
  start_time = 'start_time'
}

export type offer_timer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_timer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_timer_set_input>;
  /** filter the rows which have to be updated */
  where: offer_timer_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_timer_var_pop_fields = {
  __typename: 'offer_timer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_timer_var_samp_fields = {
  __typename: 'offer_timer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_timer_variance_fields = {
  __typename: 'offer_timer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_trial_extension" */
export type offer_trial_extension = {
  __typename: 'offer_trial_extension';
  created_at: Scalars['timestamptz']['output'];
  days: Scalars['Int']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "offer_trial_extension" */
export type offer_trial_extension_aggregate = {
  __typename: 'offer_trial_extension_aggregate';
  aggregate?: Maybe<offer_trial_extension_aggregate_fields>;
  nodes: Array<offer_trial_extension>;
};

/** aggregate fields of "offer_trial_extension" */
export type offer_trial_extension_aggregate_fields = {
  __typename: 'offer_trial_extension_aggregate_fields';
  avg?: Maybe<offer_trial_extension_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_trial_extension_max_fields>;
  min?: Maybe<offer_trial_extension_min_fields>;
  stddev?: Maybe<offer_trial_extension_stddev_fields>;
  stddev_pop?: Maybe<offer_trial_extension_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_trial_extension_stddev_samp_fields>;
  sum?: Maybe<offer_trial_extension_sum_fields>;
  var_pop?: Maybe<offer_trial_extension_var_pop_fields>;
  var_samp?: Maybe<offer_trial_extension_var_samp_fields>;
  variance?: Maybe<offer_trial_extension_variance_fields>;
};


/** aggregate fields of "offer_trial_extension" */
export type offer_trial_extension_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_trial_extension_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_trial_extension_avg_fields = {
  __typename: 'offer_trial_extension_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_trial_extension". All fields are combined with a logical 'AND'. */
export type offer_trial_extension_bool_exp = {
  _and?: InputMaybe<Array<offer_trial_extension_bool_exp>>;
  _not?: InputMaybe<offer_trial_extension_bool_exp>;
  _or?: InputMaybe<Array<offer_trial_extension_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  days?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_trial_extension" */
export enum offer_trial_extension_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  trial_extension_pkey = 'trial_extension_pkey'
}

/** input type for incrementing numeric columns in table "offer_trial_extension" */
export type offer_trial_extension_inc_input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_trial_extension" */
export type offer_trial_extension_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_trial_extension_max_fields = {
  __typename: 'offer_trial_extension_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_trial_extension_min_fields = {
  __typename: 'offer_trial_extension_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  days?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_trial_extension" */
export type offer_trial_extension_mutation_response = {
  __typename: 'offer_trial_extension_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_trial_extension>;
};

/** input type for inserting object relation for remote table "offer_trial_extension" */
export type offer_trial_extension_obj_rel_insert_input = {
  data: offer_trial_extension_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_trial_extension_on_conflict>;
};

/** on_conflict condition type for table "offer_trial_extension" */
export type offer_trial_extension_on_conflict = {
  constraint: offer_trial_extension_constraint;
  update_columns?: Array<offer_trial_extension_update_column>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};

/** Ordering options when selecting data from "offer_trial_extension". */
export type offer_trial_extension_order_by = {
  created_at?: InputMaybe<order_by>;
  days?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_trial_extension */
export type offer_trial_extension_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_trial_extension" */
export enum offer_trial_extension_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  days = 'days',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_trial_extension" */
export type offer_trial_extension_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_trial_extension_stddev_fields = {
  __typename: 'offer_trial_extension_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_trial_extension_stddev_pop_fields = {
  __typename: 'offer_trial_extension_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_trial_extension_stddev_samp_fields = {
  __typename: 'offer_trial_extension_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_trial_extension" */
export type offer_trial_extension_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_trial_extension_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_trial_extension_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_trial_extension_sum_fields = {
  __typename: 'offer_trial_extension_sum_fields';
  days?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_trial_extension" */
export enum offer_trial_extension_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  days = 'days',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_trial_extension_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_trial_extension_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_trial_extension_set_input>;
  /** filter the rows which have to be updated */
  where: offer_trial_extension_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_trial_extension_var_pop_fields = {
  __typename: 'offer_trial_extension_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_trial_extension_var_samp_fields = {
  __typename: 'offer_trial_extension_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_trial_extension_variance_fields = {
  __typename: 'offer_trial_extension_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_tsvector" */
export type offer_tsvector = {
  __typename: 'offer_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "offer_tsvector" */
export type offer_tsvector_aggregate = {
  __typename: 'offer_tsvector_aggregate';
  aggregate?: Maybe<offer_tsvector_aggregate_fields>;
  nodes: Array<offer_tsvector>;
};

/** aggregate fields of "offer_tsvector" */
export type offer_tsvector_aggregate_fields = {
  __typename: 'offer_tsvector_aggregate_fields';
  avg?: Maybe<offer_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_tsvector_max_fields>;
  min?: Maybe<offer_tsvector_min_fields>;
  stddev?: Maybe<offer_tsvector_stddev_fields>;
  stddev_pop?: Maybe<offer_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_tsvector_stddev_samp_fields>;
  sum?: Maybe<offer_tsvector_sum_fields>;
  var_pop?: Maybe<offer_tsvector_var_pop_fields>;
  var_samp?: Maybe<offer_tsvector_var_samp_fields>;
  variance?: Maybe<offer_tsvector_variance_fields>;
};


/** aggregate fields of "offer_tsvector" */
export type offer_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_tsvector_avg_fields = {
  __typename: 'offer_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_tsvector". All fields are combined with a logical 'AND'. */
export type offer_tsvector_bool_exp = {
  _and?: InputMaybe<Array<offer_tsvector_bool_exp>>;
  _not?: InputMaybe<offer_tsvector_bool_exp>;
  _or?: InputMaybe<Array<offer_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type offer_tsvector_max_fields = {
  __typename: 'offer_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type offer_tsvector_min_fields = {
  __typename: 'offer_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "offer_tsvector". */
export type offer_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "offer_tsvector" */
export enum offer_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type offer_tsvector_stddev_fields = {
  __typename: 'offer_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_tsvector_stddev_pop_fields = {
  __typename: 'offer_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_tsvector_stddev_samp_fields = {
  __typename: 'offer_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_tsvector" */
export type offer_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type offer_tsvector_sum_fields = {
  __typename: 'offer_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type offer_tsvector_var_pop_fields = {
  __typename: 'offer_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_tsvector_var_samp_fields = {
  __typename: 'offer_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_tsvector_variance_fields = {
  __typename: 'offer_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "offer_type" */
export type offer_type = {
  __typename: 'offer_type';
  /** An array relationship */
  offers: Array<offer>;
  /** An aggregate relationship */
  offers_aggregate: offer_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "offer_type" */
export type offer_typeoffersArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


/** columns and relationships of "offer_type" */
export type offer_typeoffers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};

/** aggregated selection of "offer_type" */
export type offer_type_aggregate = {
  __typename: 'offer_type_aggregate';
  aggregate?: Maybe<offer_type_aggregate_fields>;
  nodes: Array<offer_type>;
};

/** aggregate fields of "offer_type" */
export type offer_type_aggregate_fields = {
  __typename: 'offer_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<offer_type_max_fields>;
  min?: Maybe<offer_type_min_fields>;
};


/** aggregate fields of "offer_type" */
export type offer_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "offer_type". All fields are combined with a logical 'AND'. */
export type offer_type_bool_exp = {
  _and?: InputMaybe<Array<offer_type_bool_exp>>;
  _not?: InputMaybe<offer_type_bool_exp>;
  _or?: InputMaybe<Array<offer_type_bool_exp>>;
  offers?: InputMaybe<offer_bool_exp>;
  offers_aggregate?: InputMaybe<offer_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_type" */
export enum offer_type_constraint {
  /** unique or primary key constraint on columns "value" */
  offer_type_pkey = 'offer_type_pkey'
}

export enum offer_type_enum {
  change_plan = 'change_plan',
  coupon = 'coupon',
  custom = 'custom',
  downgrade = 'downgrade',
  modify_subscription = 'modify_subscription',
  pause_subscription = 'pause_subscription',
  product_swap = 'product_swap',
  reschedule_order = 'reschedule_order',
  trial_extension = 'trial_extension',
  upgrade = 'upgrade'
}

/** Boolean expression to compare columns of type "offer_type_enum". All fields are combined with logical 'AND'. */
export type offer_type_enum_comparison_exp = {
  _eq?: InputMaybe<offer_type_enum>;
  _in?: InputMaybe<Array<offer_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<offer_type_enum>;
  _nin?: InputMaybe<Array<offer_type_enum>>;
};

/** input type for inserting data into table "offer_type" */
export type offer_type_insert_input = {
  offers?: InputMaybe<offer_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_type_max_fields = {
  __typename: 'offer_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_type_min_fields = {
  __typename: 'offer_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_type" */
export type offer_type_mutation_response = {
  __typename: 'offer_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_type>;
};

/** input type for inserting object relation for remote table "offer_type" */
export type offer_type_obj_rel_insert_input = {
  data: offer_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_type_on_conflict>;
};

/** on_conflict condition type for table "offer_type" */
export type offer_type_on_conflict = {
  constraint: offer_type_constraint;
  update_columns?: Array<offer_type_update_column>;
  where?: InputMaybe<offer_type_bool_exp>;
};

/** Ordering options when selecting data from "offer_type". */
export type offer_type_order_by = {
  offers_aggregate?: InputMaybe<offer_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_type */
export type offer_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "offer_type" */
export enum offer_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "offer_type" */
export type offer_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "offer_type" */
export type offer_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "offer_type" */
export enum offer_type_update_column {
  /** column name */
  value = 'value'
}

export type offer_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_type_set_input>;
  /** filter the rows which have to be updated */
  where: offer_type_bool_exp;
};

/** update columns of table "offer" */
export enum offer_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  archived_at = 'archived_at',
  /** column name */
  button_text_translation_id = 'button_text_translation_id',
  /** column name */
  confirmation_cancel_button_text_translation_id = 'confirmation_cancel_button_text_translation_id',
  /** column name */
  confirmation_confirm_button_text_translation_id = 'confirmation_confirm_button_text_translation_id',
  /** column name */
  confirmation_content_translation_id = 'confirmation_content_translation_id',
  /** column name */
  confirmation_enabled = 'confirmation_enabled',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  goal = 'goal',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  image_url = 'image_url',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  override_active_offer = 'override_active_offer',
  /** column name */
  show_banner = 'show_banner',
  /** column name */
  style = 'style',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_set_input>;
  /** filter the rows which have to be updated */
  where: offer_bool_exp;
};

/** columns and relationships of "offer_upgrade" */
export type offer_upgrade = {
  __typename: 'offer_upgrade';
  change_at: offer_change_plan_change_at_enum;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "offer_upgrade" */
export type offer_upgrade_aggregate = {
  __typename: 'offer_upgrade_aggregate';
  aggregate?: Maybe<offer_upgrade_aggregate_fields>;
  nodes: Array<offer_upgrade>;
};

/** aggregate fields of "offer_upgrade" */
export type offer_upgrade_aggregate_fields = {
  __typename: 'offer_upgrade_aggregate_fields';
  avg?: Maybe<offer_upgrade_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_upgrade_max_fields>;
  min?: Maybe<offer_upgrade_min_fields>;
  stddev?: Maybe<offer_upgrade_stddev_fields>;
  stddev_pop?: Maybe<offer_upgrade_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_upgrade_stddev_samp_fields>;
  sum?: Maybe<offer_upgrade_sum_fields>;
  var_pop?: Maybe<offer_upgrade_var_pop_fields>;
  var_samp?: Maybe<offer_upgrade_var_samp_fields>;
  variance?: Maybe<offer_upgrade_variance_fields>;
};


/** aggregate fields of "offer_upgrade" */
export type offer_upgrade_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_upgrade_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type offer_upgrade_avg_fields = {
  __typename: 'offer_upgrade_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_upgrade". All fields are combined with a logical 'AND'. */
export type offer_upgrade_bool_exp = {
  _and?: InputMaybe<Array<offer_upgrade_bool_exp>>;
  _not?: InputMaybe<offer_upgrade_bool_exp>;
  _or?: InputMaybe<Array<offer_upgrade_bool_exp>>;
  change_at?: InputMaybe<offer_change_plan_change_at_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "offer_upgrade" */
export enum offer_upgrade_constraint {
  /** unique or primary key constraint on columns "offer_id" */
  offer_upgrade_pkey = 'offer_upgrade_pkey'
}

/** input type for incrementing numeric columns in table "offer_upgrade" */
export type offer_upgrade_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_upgrade" */
export type offer_upgrade_insert_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type offer_upgrade_max_fields = {
  __typename: 'offer_upgrade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type offer_upgrade_min_fields = {
  __typename: 'offer_upgrade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "offer_upgrade" */
export type offer_upgrade_mutation_response = {
  __typename: 'offer_upgrade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_upgrade>;
};

/** input type for inserting object relation for remote table "offer_upgrade" */
export type offer_upgrade_obj_rel_insert_input = {
  data: offer_upgrade_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_upgrade_on_conflict>;
};

/** on_conflict condition type for table "offer_upgrade" */
export type offer_upgrade_on_conflict = {
  constraint: offer_upgrade_constraint;
  update_columns?: Array<offer_upgrade_update_column>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};

/** Ordering options when selecting data from "offer_upgrade". */
export type offer_upgrade_order_by = {
  change_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_upgrade */
export type offer_upgrade_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "offer_upgrade" */
export enum offer_upgrade_select_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

/** input type for updating data in table "offer_upgrade" */
export type offer_upgrade_set_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type offer_upgrade_stddev_fields = {
  __typename: 'offer_upgrade_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_upgrade_stddev_pop_fields = {
  __typename: 'offer_upgrade_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_upgrade_stddev_samp_fields = {
  __typename: 'offer_upgrade_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_upgrade" */
export type offer_upgrade_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_upgrade_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_upgrade_stream_cursor_value_input = {
  change_at?: InputMaybe<offer_change_plan_change_at_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type offer_upgrade_sum_fields = {
  __typename: 'offer_upgrade_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_upgrade" */
export enum offer_upgrade_update_column {
  /** column name */
  change_at = 'change_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

export type offer_upgrade_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_upgrade_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_upgrade_set_input>;
  /** filter the rows which have to be updated */
  where: offer_upgrade_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_upgrade_var_pop_fields = {
  __typename: 'offer_upgrade_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_upgrade_var_samp_fields = {
  __typename: 'offer_upgrade_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_upgrade_variance_fields = {
  __typename: 'offer_upgrade_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_pop on columns */
export type offer_var_pop_fields = {
  __typename: 'offer_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer" */
export type offer_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_var_samp_fields = {
  __typename: 'offer_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer" */
export type offer_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_variance_fields = {
  __typename: 'offer_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_cancel_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_confirm_button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  confirmation_content_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer" */
export type offer_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  confirmation_cancel_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_confirm_button_text_translation_id?: InputMaybe<order_by>;
  confirmation_content_translation_id?: InputMaybe<order_by>;
  content_translation_id?: InputMaybe<order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "offer_variant" */
export type offer_variant = {
  __typename: 'offer_variant';
  approval_user_id?: Maybe<Scalars['String']['output']>;
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  button_text_translation: translation;
  button_text_translation_id: Scalars['Int']['output'];
  /** An object relationship */
  content_translation: translation;
  content_translation_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  /** An object relationship */
  headline_translation: translation;
  headline_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  offer: offer;
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_variant_coupon?: Maybe<offer_variant_coupon>;
  /** An array relationship */
  offer_variant_coupons: Array<offer_variant_coupon>;
  /** An aggregate relationship */
  offer_variant_coupons_aggregate: offer_variant_coupon_aggregate;
  type: offer_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "offer_variant" */
export type offer_variantdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "offer_variant" */
export type offer_variantoffer_variant_couponsArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


/** columns and relationships of "offer_variant" */
export type offer_variantoffer_variant_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};

/** aggregated selection of "offer_variant" */
export type offer_variant_aggregate = {
  __typename: 'offer_variant_aggregate';
  aggregate?: Maybe<offer_variant_aggregate_fields>;
  nodes: Array<offer_variant>;
};

/** aggregate fields of "offer_variant" */
export type offer_variant_aggregate_fields = {
  __typename: 'offer_variant_aggregate_fields';
  avg?: Maybe<offer_variant_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_variant_max_fields>;
  min?: Maybe<offer_variant_min_fields>;
  stddev?: Maybe<offer_variant_stddev_fields>;
  stddev_pop?: Maybe<offer_variant_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_variant_stddev_samp_fields>;
  sum?: Maybe<offer_variant_sum_fields>;
  var_pop?: Maybe<offer_variant_var_pop_fields>;
  var_samp?: Maybe<offer_variant_var_samp_fields>;
  variance?: Maybe<offer_variant_variance_fields>;
};


/** aggregate fields of "offer_variant" */
export type offer_variant_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type offer_variant_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type offer_variant_avg_fields = {
  __typename: 'offer_variant_avg_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "offer_variant". All fields are combined with a logical 'AND'. */
export type offer_variant_bool_exp = {
  _and?: InputMaybe<Array<offer_variant_bool_exp>>;
  _not?: InputMaybe<offer_variant_bool_exp>;
  _or?: InputMaybe<Array<offer_variant_bool_exp>>;
  approval_user_id?: InputMaybe<String_comparison_exp>;
  approved_at?: InputMaybe<timestamptz_comparison_exp>;
  button_text_translation?: InputMaybe<translation_bool_exp>;
  button_text_translation_id?: InputMaybe<Int_comparison_exp>;
  content_translation?: InputMaybe<translation_bool_exp>;
  content_translation_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  headline_translation?: InputMaybe<translation_bool_exp>;
  headline_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_variant_coupon?: InputMaybe<offer_variant_coupon_bool_exp>;
  offer_variant_coupons?: InputMaybe<offer_variant_coupon_bool_exp>;
  offer_variant_coupons_aggregate?: InputMaybe<offer_variant_coupon_aggregate_bool_exp>;
  type?: InputMaybe<offer_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_variant" */
export enum offer_variant_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_variant_pkey = 'offer_variant_pkey'
}

/** columns and relationships of "offer_variant_coupon" */
export type offer_variant_coupon = {
  __typename: 'offer_variant_coupon';
  amount_off: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  duration: coupon_duration_enum;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval?: Maybe<coupon_duration_interval_enum>;
  id: Scalars['Int']['output'];
  months?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  offer_variant_id: Scalars['Int']['output'];
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  type: offer_coupon_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "offer_variant_coupon" */
export type offer_variant_coupon_aggregate = {
  __typename: 'offer_variant_coupon_aggregate';
  aggregate?: Maybe<offer_variant_coupon_aggregate_fields>;
  nodes: Array<offer_variant_coupon>;
};

export type offer_variant_coupon_aggregate_bool_exp = {
  count?: InputMaybe<offer_variant_coupon_aggregate_bool_exp_count>;
};

export type offer_variant_coupon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<offer_variant_coupon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "offer_variant_coupon" */
export type offer_variant_coupon_aggregate_fields = {
  __typename: 'offer_variant_coupon_aggregate_fields';
  avg?: Maybe<offer_variant_coupon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<offer_variant_coupon_max_fields>;
  min?: Maybe<offer_variant_coupon_min_fields>;
  stddev?: Maybe<offer_variant_coupon_stddev_fields>;
  stddev_pop?: Maybe<offer_variant_coupon_stddev_pop_fields>;
  stddev_samp?: Maybe<offer_variant_coupon_stddev_samp_fields>;
  sum?: Maybe<offer_variant_coupon_sum_fields>;
  var_pop?: Maybe<offer_variant_coupon_var_pop_fields>;
  var_samp?: Maybe<offer_variant_coupon_var_samp_fields>;
  variance?: Maybe<offer_variant_coupon_variance_fields>;
};


/** aggregate fields of "offer_variant_coupon" */
export type offer_variant_coupon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "offer_variant_coupon" */
export type offer_variant_coupon_aggregate_order_by = {
  avg?: InputMaybe<offer_variant_coupon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<offer_variant_coupon_max_order_by>;
  min?: InputMaybe<offer_variant_coupon_min_order_by>;
  stddev?: InputMaybe<offer_variant_coupon_stddev_order_by>;
  stddev_pop?: InputMaybe<offer_variant_coupon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<offer_variant_coupon_stddev_samp_order_by>;
  sum?: InputMaybe<offer_variant_coupon_sum_order_by>;
  var_pop?: InputMaybe<offer_variant_coupon_var_pop_order_by>;
  var_samp?: InputMaybe<offer_variant_coupon_var_samp_order_by>;
  variance?: InputMaybe<offer_variant_coupon_variance_order_by>;
};

/** input type for inserting array relation for remote table "offer_variant_coupon" */
export type offer_variant_coupon_arr_rel_insert_input = {
  data: Array<offer_variant_coupon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_variant_coupon_on_conflict>;
};

/** aggregate avg on columns */
export type offer_variant_coupon_avg_fields = {
  __typename: 'offer_variant_coupon_avg_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_avg_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "offer_variant_coupon". All fields are combined with a logical 'AND'. */
export type offer_variant_coupon_bool_exp = {
  _and?: InputMaybe<Array<offer_variant_coupon_bool_exp>>;
  _not?: InputMaybe<offer_variant_coupon_bool_exp>;
  _or?: InputMaybe<Array<offer_variant_coupon_bool_exp>>;
  amount_off?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  duration?: InputMaybe<coupon_duration_enum_comparison_exp>;
  duration_count?: InputMaybe<Int_comparison_exp>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  months?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<offer_coupon_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "offer_variant_coupon" */
export enum offer_variant_coupon_constraint {
  /** unique or primary key constraint on columns "id" */
  offer_variant_coupon_pkey = 'offer_variant_coupon_pkey'
}

/** input type for incrementing numeric columns in table "offer_variant_coupon" */
export type offer_variant_coupon_inc_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_variant_coupon" */
export type offer_variant_coupon_insert_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_variant_coupon_max_fields = {
  __typename: 'offer_variant_coupon_max_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_max_order_by = {
  amount_off?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type offer_variant_coupon_min_fields = {
  __typename: 'offer_variant_coupon_min_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_min_order_by = {
  amount_off?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "offer_variant_coupon" */
export type offer_variant_coupon_mutation_response = {
  __typename: 'offer_variant_coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_variant_coupon>;
};

/** input type for inserting object relation for remote table "offer_variant_coupon" */
export type offer_variant_coupon_obj_rel_insert_input = {
  data: offer_variant_coupon_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_variant_coupon_on_conflict>;
};

/** on_conflict condition type for table "offer_variant_coupon" */
export type offer_variant_coupon_on_conflict = {
  constraint: offer_variant_coupon_constraint;
  update_columns?: Array<offer_variant_coupon_update_column>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};

/** Ordering options when selecting data from "offer_variant_coupon". */
export type offer_variant_coupon_order_by = {
  amount_off?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_variant_coupon */
export type offer_variant_coupon_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "offer_variant_coupon" */
export enum offer_variant_coupon_select_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  created_at = 'created_at',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  id = 'id',
  /** column name */
  months = 'months',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_variant_coupon" */
export type offer_variant_coupon_set_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_variant_coupon_stddev_fields = {
  __typename: 'offer_variant_coupon_stddev_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_stddev_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type offer_variant_coupon_stddev_pop_fields = {
  __typename: 'offer_variant_coupon_stddev_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_stddev_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type offer_variant_coupon_stddev_samp_fields = {
  __typename: 'offer_variant_coupon_stddev_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_stddev_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "offer_variant_coupon" */
export type offer_variant_coupon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_variant_coupon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_variant_coupon_stream_cursor_value_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  duration?: InputMaybe<coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<coupon_duration_interval_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  months?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<offer_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_variant_coupon_sum_fields = {
  __typename: 'offer_variant_coupon_sum_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_sum_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** update columns of table "offer_variant_coupon" */
export enum offer_variant_coupon_update_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  created_at = 'created_at',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  id = 'id',
  /** column name */
  months = 'months',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_variant_coupon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_variant_coupon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_variant_coupon_set_input>;
  /** filter the rows which have to be updated */
  where: offer_variant_coupon_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_variant_coupon_var_pop_fields = {
  __typename: 'offer_variant_coupon_var_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_var_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type offer_variant_coupon_var_samp_fields = {
  __typename: 'offer_variant_coupon_var_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_var_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type offer_variant_coupon_variance_fields = {
  __typename: 'offer_variant_coupon_variance_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  months?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "offer_variant_coupon" */
export type offer_variant_coupon_variance_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  months?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type offer_variant_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type offer_variant_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type offer_variant_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "offer_variant" */
export type offer_variant_inc_input = {
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "offer_variant" */
export type offer_variant_insert_input = {
  approval_user_id?: InputMaybe<Scalars['String']['input']>;
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation?: InputMaybe<translation_obj_rel_insert_input>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation?: InputMaybe<translation_obj_rel_insert_input>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  headline_translation?: InputMaybe<translation_obj_rel_insert_input>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_coupon?: InputMaybe<offer_variant_coupon_obj_rel_insert_input>;
  offer_variant_coupons?: InputMaybe<offer_variant_coupon_arr_rel_insert_input>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type offer_variant_max_fields = {
  __typename: 'offer_variant_max_fields';
  approval_user_id?: Maybe<Scalars['String']['output']>;
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type offer_variant_min_fields = {
  __typename: 'offer_variant_min_fields';
  approval_user_id?: Maybe<Scalars['String']['output']>;
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "offer_variant" */
export type offer_variant_mutation_response = {
  __typename: 'offer_variant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<offer_variant>;
};

/** input type for inserting object relation for remote table "offer_variant" */
export type offer_variant_obj_rel_insert_input = {
  data: offer_variant_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<offer_variant_on_conflict>;
};

/** on_conflict condition type for table "offer_variant" */
export type offer_variant_on_conflict = {
  constraint: offer_variant_constraint;
  update_columns?: Array<offer_variant_update_column>;
  where?: InputMaybe<offer_variant_bool_exp>;
};

/** Ordering options when selecting data from "offer_variant". */
export type offer_variant_order_by = {
  approval_user_id?: InputMaybe<order_by>;
  approved_at?: InputMaybe<order_by>;
  button_text_translation?: InputMaybe<translation_order_by>;
  button_text_translation_id?: InputMaybe<order_by>;
  content_translation?: InputMaybe<translation_order_by>;
  content_translation_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  headline_translation?: InputMaybe<translation_order_by>;
  headline_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_coupon?: InputMaybe<offer_variant_coupon_order_by>;
  offer_variant_coupons_aggregate?: InputMaybe<offer_variant_coupon_aggregate_order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: offer_variant */
export type offer_variant_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type offer_variant_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "offer_variant" */
export enum offer_variant_select_column {
  /** column name */
  approval_user_id = 'approval_user_id',
  /** column name */
  approved_at = 'approved_at',
  /** column name */
  button_text_translation_id = 'button_text_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "offer_variant" */
export type offer_variant_set_input = {
  approval_user_id?: InputMaybe<Scalars['String']['input']>;
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type offer_variant_stddev_fields = {
  __typename: 'offer_variant_stddev_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type offer_variant_stddev_pop_fields = {
  __typename: 'offer_variant_stddev_pop_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type offer_variant_stddev_samp_fields = {
  __typename: 'offer_variant_stddev_samp_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "offer_variant" */
export type offer_variant_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: offer_variant_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type offer_variant_stream_cursor_value_input = {
  approval_user_id?: InputMaybe<Scalars['String']['input']>;
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  button_text_translation_id?: InputMaybe<Scalars['Int']['input']>;
  content_translation_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  headline_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<offer_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type offer_variant_sum_fields = {
  __typename: 'offer_variant_sum_fields';
  button_text_translation_id?: Maybe<Scalars['Int']['output']>;
  content_translation_id?: Maybe<Scalars['Int']['output']>;
  headline_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "offer_variant" */
export enum offer_variant_update_column {
  /** column name */
  approval_user_id = 'approval_user_id',
  /** column name */
  approved_at = 'approved_at',
  /** column name */
  button_text_translation_id = 'button_text_translation_id',
  /** column name */
  content_translation_id = 'content_translation_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  headline_translation_id = 'headline_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type offer_variant_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<offer_variant_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<offer_variant_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<offer_variant_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<offer_variant_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<offer_variant_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<offer_variant_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<offer_variant_set_input>;
  /** filter the rows which have to be updated */
  where: offer_variant_bool_exp;
};

/** aggregate var_pop on columns */
export type offer_variant_var_pop_fields = {
  __typename: 'offer_variant_var_pop_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type offer_variant_var_samp_fields = {
  __typename: 'offer_variant_var_samp_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type offer_variant_variance_fields = {
  __typename: 'offer_variant_variance_fields';
  button_text_translation_id?: Maybe<Scalars['Float']['output']>;
  content_translation_id?: Maybe<Scalars['Float']['output']>;
  headline_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
};

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last'
}

/** columns and relationships of "platform" */
export type platform = {
  __typename: 'platform';
  /** An array relationship */
  platform_connections: Array<platform_connection>;
  /** An aggregate relationship */
  platform_connections_aggregate: platform_connection_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform" */
export type platformplatform_connectionsArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


/** columns and relationships of "platform" */
export type platformplatform_connections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};

/** columns and relationships of "platform_addon" */
export type platform_addon = {
  __typename: 'platform_addon';
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  paper_code?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  platform_addon_plans: Array<platform_addon_plan>;
  /** An aggregate relationship */
  platform_addon_plans_aggregate: platform_addon_plan_aggregate;
  /** An array relationship */
  platform_addon_prices: Array<platform_addon_price>;
  /** An aggregate relationship */
  platform_addon_prices_aggregate: platform_addon_price_aggregate;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An array relationship */
  platform_coupon_addons: Array<platform_coupon_addon>;
  /** An aggregate relationship */
  platform_coupon_addons_aggregate: platform_coupon_addon_aggregate;
  platform_id: Scalars['String']['output'];
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  platform_subscription_addons: Array<platform_subscription_addon>;
  /** An aggregate relationship */
  platform_subscription_addons_aggregate: platform_subscription_addon_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_addon_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_addon_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_addon_pricesArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_addon_prices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_coupon_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_coupon_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_subscription_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


/** columns and relationships of "platform_addon" */
export type platform_addonplatform_subscription_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};

/** aggregated selection of "platform_addon" */
export type platform_addon_aggregate = {
  __typename: 'platform_addon_aggregate';
  aggregate?: Maybe<platform_addon_aggregate_fields>;
  nodes: Array<platform_addon>;
};

export type platform_addon_aggregate_bool_exp = {
  count?: InputMaybe<platform_addon_aggregate_bool_exp_count>;
};

export type platform_addon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_addon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_addon" */
export type platform_addon_aggregate_fields = {
  __typename: 'platform_addon_aggregate_fields';
  avg?: Maybe<platform_addon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_addon_max_fields>;
  min?: Maybe<platform_addon_min_fields>;
  stddev?: Maybe<platform_addon_stddev_fields>;
  stddev_pop?: Maybe<platform_addon_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_addon_stddev_samp_fields>;
  sum?: Maybe<platform_addon_sum_fields>;
  var_pop?: Maybe<platform_addon_var_pop_fields>;
  var_samp?: Maybe<platform_addon_var_samp_fields>;
  variance?: Maybe<platform_addon_variance_fields>;
};


/** aggregate fields of "platform_addon" */
export type platform_addon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_addon" */
export type platform_addon_aggregate_order_by = {
  avg?: InputMaybe<platform_addon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_addon_max_order_by>;
  min?: InputMaybe<platform_addon_min_order_by>;
  stddev?: InputMaybe<platform_addon_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_addon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_addon_stddev_samp_order_by>;
  sum?: InputMaybe<platform_addon_sum_order_by>;
  var_pop?: InputMaybe<platform_addon_var_pop_order_by>;
  var_samp?: InputMaybe<platform_addon_var_samp_order_by>;
  variance?: InputMaybe<platform_addon_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_addon" */
export type platform_addon_arr_rel_insert_input = {
  data: Array<platform_addon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_on_conflict>;
};

/** aggregate avg on columns */
export type platform_addon_avg_fields = {
  __typename: 'platform_addon_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_addon" */
export type platform_addon_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_addon". All fields are combined with a logical 'AND'. */
export type platform_addon_bool_exp = {
  _and?: InputMaybe<Array<platform_addon_bool_exp>>;
  _not?: InputMaybe<platform_addon_bool_exp>;
  _or?: InputMaybe<Array<platform_addon_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_addon_plans?: InputMaybe<platform_addon_plan_bool_exp>;
  platform_addon_plans_aggregate?: InputMaybe<platform_addon_plan_aggregate_bool_exp>;
  platform_addon_prices?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_bool_exp>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_bool_exp>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_addon" */
export enum platform_addon_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_addon_pkey = 'platform_addon_pkey'
}

/** input type for incrementing numeric columns in table "platform_addon" */
export type platform_addon_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_addon" */
export type platform_addon_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_addon_plans?: InputMaybe<platform_addon_plan_arr_rel_insert_input>;
  platform_addon_prices?: InputMaybe<platform_addon_price_arr_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_arr_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_addon_max_fields = {
  __typename: 'platform_addon_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_addon" */
export type platform_addon_max_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_addon_min_fields = {
  __typename: 'platform_addon_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_addon" */
export type platform_addon_min_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_addon" */
export type platform_addon_mutation_response = {
  __typename: 'platform_addon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_addon>;
};

/** input type for inserting object relation for remote table "platform_addon" */
export type platform_addon_obj_rel_insert_input = {
  data: platform_addon_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_on_conflict>;
};

/** on_conflict condition type for table "platform_addon" */
export type platform_addon_on_conflict = {
  constraint: platform_addon_constraint;
  update_columns?: Array<platform_addon_update_column>;
  where?: InputMaybe<platform_addon_bool_exp>;
};

/** Ordering options when selecting data from "platform_addon". */
export type platform_addon_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_addon_plans_aggregate?: InputMaybe<platform_addon_plan_aggregate_order_by>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_addon */
export type platform_addon_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** columns and relationships of "platform_addon_plan" */
export type platform_addon_plan = {
  __typename: 'platform_addon_plan';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_addon: platform_addon;
  platform_addon_id: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan: platform_plan;
  platform_plan_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_addon_plan" */
export type platform_addon_plan_aggregate = {
  __typename: 'platform_addon_plan_aggregate';
  aggregate?: Maybe<platform_addon_plan_aggregate_fields>;
  nodes: Array<platform_addon_plan>;
};

export type platform_addon_plan_aggregate_bool_exp = {
  count?: InputMaybe<platform_addon_plan_aggregate_bool_exp_count>;
};

export type platform_addon_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_addon_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_addon_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_addon_plan" */
export type platform_addon_plan_aggregate_fields = {
  __typename: 'platform_addon_plan_aggregate_fields';
  avg?: Maybe<platform_addon_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_addon_plan_max_fields>;
  min?: Maybe<platform_addon_plan_min_fields>;
  stddev?: Maybe<platform_addon_plan_stddev_fields>;
  stddev_pop?: Maybe<platform_addon_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_addon_plan_stddev_samp_fields>;
  sum?: Maybe<platform_addon_plan_sum_fields>;
  var_pop?: Maybe<platform_addon_plan_var_pop_fields>;
  var_samp?: Maybe<platform_addon_plan_var_samp_fields>;
  variance?: Maybe<platform_addon_plan_variance_fields>;
};


/** aggregate fields of "platform_addon_plan" */
export type platform_addon_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_addon_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_addon_plan" */
export type platform_addon_plan_aggregate_order_by = {
  avg?: InputMaybe<platform_addon_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_addon_plan_max_order_by>;
  min?: InputMaybe<platform_addon_plan_min_order_by>;
  stddev?: InputMaybe<platform_addon_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_addon_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_addon_plan_stddev_samp_order_by>;
  sum?: InputMaybe<platform_addon_plan_sum_order_by>;
  var_pop?: InputMaybe<platform_addon_plan_var_pop_order_by>;
  var_samp?: InputMaybe<platform_addon_plan_var_samp_order_by>;
  variance?: InputMaybe<platform_addon_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_addon_plan" */
export type platform_addon_plan_arr_rel_insert_input = {
  data: Array<platform_addon_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_plan_on_conflict>;
};

/** aggregate avg on columns */
export type platform_addon_plan_avg_fields = {
  __typename: 'platform_addon_plan_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_addon_plan" */
export type platform_addon_plan_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_addon_plan". All fields are combined with a logical 'AND'. */
export type platform_addon_plan_bool_exp = {
  _and?: InputMaybe<Array<platform_addon_plan_bool_exp>>;
  _not?: InputMaybe<platform_addon_plan_bool_exp>;
  _or?: InputMaybe<Array<platform_addon_plan_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_addon?: InputMaybe<platform_addon_bool_exp>;
  platform_addon_id?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_addon_plan" */
export enum platform_addon_plan_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_connection_id", "platform_addon_id" */
  platform_addon_plan_pkey = 'platform_addon_plan_pkey'
}

/** input type for incrementing numeric columns in table "platform_addon_plan" */
export type platform_addon_plan_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_addon_plan" */
export type platform_addon_plan_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon?: InputMaybe<platform_addon_obj_rel_insert_input>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_addon_plan_max_fields = {
  __typename: 'platform_addon_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_addon_plan" */
export type platform_addon_plan_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_addon_plan_min_fields = {
  __typename: 'platform_addon_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_addon_plan" */
export type platform_addon_plan_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_addon_plan" */
export type platform_addon_plan_mutation_response = {
  __typename: 'platform_addon_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_addon_plan>;
};

/** on_conflict condition type for table "platform_addon_plan" */
export type platform_addon_plan_on_conflict = {
  constraint: platform_addon_plan_constraint;
  update_columns?: Array<platform_addon_plan_update_column>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};

/** Ordering options when selecting data from "platform_addon_plan". */
export type platform_addon_plan_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon?: InputMaybe<platform_addon_order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_addon_plan */
export type platform_addon_plan_pk_columns_input = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
};

/** select columns of table "platform_addon_plan" */
export enum platform_addon_plan_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

/** input type for updating data in table "platform_addon_plan" */
export type platform_addon_plan_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_addon_plan_stddev_fields = {
  __typename: 'platform_addon_plan_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_addon_plan" */
export type platform_addon_plan_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_addon_plan_stddev_pop_fields = {
  __typename: 'platform_addon_plan_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_addon_plan" */
export type platform_addon_plan_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_addon_plan_stddev_samp_fields = {
  __typename: 'platform_addon_plan_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_addon_plan" */
export type platform_addon_plan_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_addon_plan" */
export type platform_addon_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_addon_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_addon_plan_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_addon_plan_sum_fields = {
  __typename: 'platform_addon_plan_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_addon_plan" */
export type platform_addon_plan_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_addon_plan" */
export enum platform_addon_plan_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

export type platform_addon_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_addon_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_addon_plan_set_input>;
  /** filter the rows which have to be updated */
  where: platform_addon_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_addon_plan_var_pop_fields = {
  __typename: 'platform_addon_plan_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_addon_plan" */
export type platform_addon_plan_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_addon_plan_var_samp_fields = {
  __typename: 'platform_addon_plan_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_addon_plan" */
export type platform_addon_plan_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_addon_plan_variance_fields = {
  __typename: 'platform_addon_plan_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_addon_plan" */
export type platform_addon_plan_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_addon_price" */
export type platform_addon_price = {
  __typename: 'platform_addon_price';
  created_at: Scalars['timestamptz']['output'];
  currency: platform_currency_enum;
  interval?: Maybe<platform_plan_interval_enum>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  platform_addon?: Maybe<platform_addon>;
  platform_addon_id: Scalars['String']['output'];
  /** An array relationship */
  platform_addon_price_tiers: Array<platform_addon_price_tier>;
  /** An aggregate relationship */
  platform_addon_price_tiers_aggregate: platform_addon_price_tier_aggregate;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_currency: platform_currency;
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_plan_interval?: Maybe<platform_plan_interval>;
  /** An object relationship */
  platform_plan_pricing_model: platform_plan_pricing_model;
  pricing_model: platform_plan_pricing_model_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_addon_price" */
export type platform_addon_priceplatform_addon_price_tiersArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


/** columns and relationships of "platform_addon_price" */
export type platform_addon_priceplatform_addon_price_tiers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};

/** aggregated selection of "platform_addon_price" */
export type platform_addon_price_aggregate = {
  __typename: 'platform_addon_price_aggregate';
  aggregate?: Maybe<platform_addon_price_aggregate_fields>;
  nodes: Array<platform_addon_price>;
};

export type platform_addon_price_aggregate_bool_exp = {
  count?: InputMaybe<platform_addon_price_aggregate_bool_exp_count>;
};

export type platform_addon_price_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_addon_price_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_addon_price_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_addon_price" */
export type platform_addon_price_aggregate_fields = {
  __typename: 'platform_addon_price_aggregate_fields';
  avg?: Maybe<platform_addon_price_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_addon_price_max_fields>;
  min?: Maybe<platform_addon_price_min_fields>;
  stddev?: Maybe<platform_addon_price_stddev_fields>;
  stddev_pop?: Maybe<platform_addon_price_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_addon_price_stddev_samp_fields>;
  sum?: Maybe<platform_addon_price_sum_fields>;
  var_pop?: Maybe<platform_addon_price_var_pop_fields>;
  var_samp?: Maybe<platform_addon_price_var_samp_fields>;
  variance?: Maybe<platform_addon_price_variance_fields>;
};


/** aggregate fields of "platform_addon_price" */
export type platform_addon_price_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_addon_price_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_addon_price" */
export type platform_addon_price_aggregate_order_by = {
  avg?: InputMaybe<platform_addon_price_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_addon_price_max_order_by>;
  min?: InputMaybe<platform_addon_price_min_order_by>;
  stddev?: InputMaybe<platform_addon_price_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_addon_price_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_addon_price_stddev_samp_order_by>;
  sum?: InputMaybe<platform_addon_price_sum_order_by>;
  var_pop?: InputMaybe<platform_addon_price_var_pop_order_by>;
  var_samp?: InputMaybe<platform_addon_price_var_samp_order_by>;
  variance?: InputMaybe<platform_addon_price_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_addon_price" */
export type platform_addon_price_arr_rel_insert_input = {
  data: Array<platform_addon_price_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_price_on_conflict>;
};

/** aggregate avg on columns */
export type platform_addon_price_avg_fields = {
  __typename: 'platform_addon_price_avg_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_addon_price" */
export type platform_addon_price_avg_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_addon_price". All fields are combined with a logical 'AND'. */
export type platform_addon_price_bool_exp = {
  _and?: InputMaybe<Array<platform_addon_price_bool_exp>>;
  _not?: InputMaybe<platform_addon_price_bool_exp>;
  _or?: InputMaybe<Array<platform_addon_price_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_currency_enum_comparison_exp>;
  interval?: InputMaybe<platform_plan_interval_enum_comparison_exp>;
  interval_count?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  platform_addon?: InputMaybe<platform_addon_bool_exp>;
  platform_addon_id?: InputMaybe<String_comparison_exp>;
  platform_addon_price_tiers?: InputMaybe<platform_addon_price_tier_bool_exp>;
  platform_addon_price_tiers_aggregate?: InputMaybe<platform_addon_price_tier_aggregate_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_bool_exp>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_bool_exp>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_addon_price" */
export enum platform_addon_price_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_addon_price_pkey = 'platform_addon_price_pkey'
}

/** input type for incrementing numeric columns in table "platform_addon_price" */
export type platform_addon_price_inc_input = {
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_addon_price" */
export type platform_addon_price_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_addon?: InputMaybe<platform_addon_obj_rel_insert_input>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_addon_price_tiers?: InputMaybe<platform_addon_price_tier_arr_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_obj_rel_insert_input>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_obj_rel_insert_input>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_addon_price_max_fields = {
  __typename: 'platform_addon_price_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_addon_price" */
export type platform_addon_price_max_order_by = {
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_addon_price_min_fields = {
  __typename: 'platform_addon_price_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_addon_price" */
export type platform_addon_price_min_order_by = {
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_addon_price" */
export type platform_addon_price_mutation_response = {
  __typename: 'platform_addon_price_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_addon_price>;
};

/** input type for inserting object relation for remote table "platform_addon_price" */
export type platform_addon_price_obj_rel_insert_input = {
  data: platform_addon_price_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_price_on_conflict>;
};

/** on_conflict condition type for table "platform_addon_price" */
export type platform_addon_price_on_conflict = {
  constraint: platform_addon_price_constraint;
  update_columns?: Array<platform_addon_price_update_column>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};

/** Ordering options when selecting data from "platform_addon_price". */
export type platform_addon_price_order_by = {
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  interval?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_addon?: InputMaybe<platform_addon_order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_addon_price_tiers_aggregate?: InputMaybe<platform_addon_price_tier_aggregate_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_order_by>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_order_by>;
  pricing_model?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_addon_price */
export type platform_addon_price_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_addon_price" */
export enum platform_addon_price_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  name = 'name',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  pricing_model = 'pricing_model',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_addon_price" */
export type platform_addon_price_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_addon_price_stddev_fields = {
  __typename: 'platform_addon_price_stddev_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_addon_price" */
export type platform_addon_price_stddev_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_addon_price_stddev_pop_fields = {
  __typename: 'platform_addon_price_stddev_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_addon_price" */
export type platform_addon_price_stddev_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_addon_price_stddev_samp_fields = {
  __typename: 'platform_addon_price_stddev_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_addon_price" */
export type platform_addon_price_stddev_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_addon_price" */
export type platform_addon_price_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_addon_price_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_addon_price_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_addon_price_sum_fields = {
  __typename: 'platform_addon_price_sum_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_addon_price" */
export type platform_addon_price_sum_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_addon_price_tier" */
export type platform_addon_price_tier = {
  __typename: 'platform_addon_price_tier';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_addon_price?: Maybe<platform_addon_price>;
  platform_addon_price_id: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  price: Scalars['numeric']['output'];
  up_to: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_addon_price_tier" */
export type platform_addon_price_tier_aggregate = {
  __typename: 'platform_addon_price_tier_aggregate';
  aggregate?: Maybe<platform_addon_price_tier_aggregate_fields>;
  nodes: Array<platform_addon_price_tier>;
};

export type platform_addon_price_tier_aggregate_bool_exp = {
  count?: InputMaybe<platform_addon_price_tier_aggregate_bool_exp_count>;
};

export type platform_addon_price_tier_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_addon_price_tier_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_addon_price_tier" */
export type platform_addon_price_tier_aggregate_fields = {
  __typename: 'platform_addon_price_tier_aggregate_fields';
  avg?: Maybe<platform_addon_price_tier_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_addon_price_tier_max_fields>;
  min?: Maybe<platform_addon_price_tier_min_fields>;
  stddev?: Maybe<platform_addon_price_tier_stddev_fields>;
  stddev_pop?: Maybe<platform_addon_price_tier_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_addon_price_tier_stddev_samp_fields>;
  sum?: Maybe<platform_addon_price_tier_sum_fields>;
  var_pop?: Maybe<platform_addon_price_tier_var_pop_fields>;
  var_samp?: Maybe<platform_addon_price_tier_var_samp_fields>;
  variance?: Maybe<platform_addon_price_tier_variance_fields>;
};


/** aggregate fields of "platform_addon_price_tier" */
export type platform_addon_price_tier_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_addon_price_tier" */
export type platform_addon_price_tier_aggregate_order_by = {
  avg?: InputMaybe<platform_addon_price_tier_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_addon_price_tier_max_order_by>;
  min?: InputMaybe<platform_addon_price_tier_min_order_by>;
  stddev?: InputMaybe<platform_addon_price_tier_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_addon_price_tier_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_addon_price_tier_stddev_samp_order_by>;
  sum?: InputMaybe<platform_addon_price_tier_sum_order_by>;
  var_pop?: InputMaybe<platform_addon_price_tier_var_pop_order_by>;
  var_samp?: InputMaybe<platform_addon_price_tier_var_samp_order_by>;
  variance?: InputMaybe<platform_addon_price_tier_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_addon_price_tier" */
export type platform_addon_price_tier_arr_rel_insert_input = {
  data: Array<platform_addon_price_tier_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_addon_price_tier_on_conflict>;
};

/** aggregate avg on columns */
export type platform_addon_price_tier_avg_fields = {
  __typename: 'platform_addon_price_tier_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_addon_price_tier". All fields are combined with a logical 'AND'. */
export type platform_addon_price_tier_bool_exp = {
  _and?: InputMaybe<Array<platform_addon_price_tier_bool_exp>>;
  _not?: InputMaybe<platform_addon_price_tier_bool_exp>;
  _or?: InputMaybe<Array<platform_addon_price_tier_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_addon_price?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_price_id?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  up_to?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_addon_price_tier" */
export enum platform_addon_price_tier_constraint {
  /** unique or primary key constraint on columns "platform_addon_price_id", "platform_connection_id", "up_to" */
  platform_addon_price_tier_pkey = 'platform_addon_price_tier_pkey'
}

/** input type for incrementing numeric columns in table "platform_addon_price_tier" */
export type platform_addon_price_tier_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_addon_price_tier" */
export type platform_addon_price_tier_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_price?: InputMaybe<platform_addon_price_obj_rel_insert_input>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_addon_price_tier_max_fields = {
  __typename: 'platform_addon_price_tier_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_price_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_addon_price_tier_min_fields = {
  __typename: 'platform_addon_price_tier_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_price_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_addon_price_tier" */
export type platform_addon_price_tier_mutation_response = {
  __typename: 'platform_addon_price_tier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_addon_price_tier>;
};

/** on_conflict condition type for table "platform_addon_price_tier" */
export type platform_addon_price_tier_on_conflict = {
  constraint: platform_addon_price_tier_constraint;
  update_columns?: Array<platform_addon_price_tier_update_column>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};

/** Ordering options when selecting data from "platform_addon_price_tier". */
export type platform_addon_price_tier_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_price?: InputMaybe<platform_addon_price_order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_addon_price_tier */
export type platform_addon_price_tier_pk_columns_input = {
  platform_addon_price_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  up_to: Scalars['Int']['input'];
};

/** select columns of table "platform_addon_price_tier" */
export enum platform_addon_price_tier_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_price_id = 'platform_addon_price_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  price = 'price',
  /** column name */
  up_to = 'up_to',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_addon_price_tier" */
export type platform_addon_price_tier_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_addon_price_tier_stddev_fields = {
  __typename: 'platform_addon_price_tier_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_addon_price_tier_stddev_pop_fields = {
  __typename: 'platform_addon_price_tier_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_addon_price_tier_stddev_samp_fields = {
  __typename: 'platform_addon_price_tier_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_addon_price_tier" */
export type platform_addon_price_tier_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_addon_price_tier_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_addon_price_tier_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_addon_price_tier_sum_fields = {
  __typename: 'platform_addon_price_tier_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** update columns of table "platform_addon_price_tier" */
export enum platform_addon_price_tier_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_price_id = 'platform_addon_price_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  price = 'price',
  /** column name */
  up_to = 'up_to',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_addon_price_tier_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_addon_price_tier_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_addon_price_tier_set_input>;
  /** filter the rows which have to be updated */
  where: platform_addon_price_tier_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_addon_price_tier_var_pop_fields = {
  __typename: 'platform_addon_price_tier_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_addon_price_tier_var_samp_fields = {
  __typename: 'platform_addon_price_tier_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_addon_price_tier_variance_fields = {
  __typename: 'platform_addon_price_tier_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_addon_price_tier" */
export type platform_addon_price_tier_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** update columns of table "platform_addon_price" */
export enum platform_addon_price_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  name = 'name',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  pricing_model = 'pricing_model',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_addon_price_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_addon_price_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_addon_price_set_input>;
  /** filter the rows which have to be updated */
  where: platform_addon_price_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_addon_price_var_pop_fields = {
  __typename: 'platform_addon_price_var_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_addon_price" */
export type platform_addon_price_var_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_addon_price_var_samp_fields = {
  __typename: 'platform_addon_price_var_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_addon_price" */
export type platform_addon_price_var_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_addon_price_variance_fields = {
  __typename: 'platform_addon_price_variance_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_addon_price" */
export type platform_addon_price_variance_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** select columns of table "platform_addon" */
export enum platform_addon_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_addon" */
export type platform_addon_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_addon_stddev_fields = {
  __typename: 'platform_addon_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_addon" */
export type platform_addon_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_addon_stddev_pop_fields = {
  __typename: 'platform_addon_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_addon" */
export type platform_addon_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_addon_stddev_samp_fields = {
  __typename: 'platform_addon_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_addon" */
export type platform_addon_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_addon" */
export type platform_addon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_addon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_addon_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_addon_sum_fields = {
  __typename: 'platform_addon_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_addon" */
export type platform_addon_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_addon" */
export enum platform_addon_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_addon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_addon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_addon_set_input>;
  /** filter the rows which have to be updated */
  where: platform_addon_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_addon_var_pop_fields = {
  __typename: 'platform_addon_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_addon" */
export type platform_addon_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_addon_var_samp_fields = {
  __typename: 'platform_addon_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_addon" */
export type platform_addon_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_addon_variance_fields = {
  __typename: 'platform_addon_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_addon" */
export type platform_addon_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregated selection of "platform" */
export type platform_aggregate = {
  __typename: 'platform_aggregate';
  aggregate?: Maybe<platform_aggregate_fields>;
  nodes: Array<platform>;
};

/** aggregate fields of "platform" */
export type platform_aggregate_fields = {
  __typename: 'platform_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_max_fields>;
  min?: Maybe<platform_min_fields>;
};


/** aggregate fields of "platform" */
export type platform_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "platform_api_log" */
export type platform_api_log = {
  __typename: 'platform_api_log';
  created_at: Scalars['timestamptz']['output'];
  har: Scalars['jsonb']['output'];
  id: Scalars['bigint']['output'];
  method: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


/** columns and relationships of "platform_api_log" */
export type platform_api_logharArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_api_log" */
export type platform_api_log_aggregate = {
  __typename: 'platform_api_log_aggregate';
  aggregate?: Maybe<platform_api_log_aggregate_fields>;
  nodes: Array<platform_api_log>;
};

/** aggregate fields of "platform_api_log" */
export type platform_api_log_aggregate_fields = {
  __typename: 'platform_api_log_aggregate_fields';
  avg?: Maybe<platform_api_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_api_log_max_fields>;
  min?: Maybe<platform_api_log_min_fields>;
  stddev?: Maybe<platform_api_log_stddev_fields>;
  stddev_pop?: Maybe<platform_api_log_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_api_log_stddev_samp_fields>;
  sum?: Maybe<platform_api_log_sum_fields>;
  var_pop?: Maybe<platform_api_log_var_pop_fields>;
  var_samp?: Maybe<platform_api_log_var_samp_fields>;
  variance?: Maybe<platform_api_log_variance_fields>;
};


/** aggregate fields of "platform_api_log" */
export type platform_api_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_api_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_api_log_append_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type platform_api_log_avg_fields = {
  __typename: 'platform_api_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_api_log". All fields are combined with a logical 'AND'. */
export type platform_api_log_bool_exp = {
  _and?: InputMaybe<Array<platform_api_log_bool_exp>>;
  _not?: InputMaybe<platform_api_log_bool_exp>;
  _or?: InputMaybe<Array<platform_api_log_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  har?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<bigint_comparison_exp>;
  method?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  status?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_api_log" */
export enum platform_api_log_constraint {
  /** unique or primary key constraint on columns "id" */
  platform_api_log_pkey = 'platform_api_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_api_log_delete_at_path_input = {
  har?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_api_log_delete_elem_input = {
  har?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_api_log_delete_key_input = {
  har?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_api_log" */
export type platform_api_log_inc_input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_api_log" */
export type platform_api_log_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_api_log_max_fields = {
  __typename: 'platform_api_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_api_log_min_fields = {
  __typename: 'platform_api_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_api_log" */
export type platform_api_log_mutation_response = {
  __typename: 'platform_api_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_api_log>;
};

/** on_conflict condition type for table "platform_api_log" */
export type platform_api_log_on_conflict = {
  constraint: platform_api_log_constraint;
  update_columns?: Array<platform_api_log_update_column>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};

/** Ordering options when selecting data from "platform_api_log". */
export type platform_api_log_order_by = {
  created_at?: InputMaybe<order_by>;
  har?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  method?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_api_log */
export type platform_api_log_pk_columns_input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_api_log_prepend_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_api_log" */
export enum platform_api_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

/** input type for updating data in table "platform_api_log" */
export type platform_api_log_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_api_log_stddev_fields = {
  __typename: 'platform_api_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_api_log_stddev_pop_fields = {
  __typename: 'platform_api_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_api_log_stddev_samp_fields = {
  __typename: 'platform_api_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_api_log" */
export type platform_api_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_api_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_api_log_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_api_log_sum_fields = {
  __typename: 'platform_api_log_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "platform_api_log_tsvector" */
export type platform_api_log_tsvector = {
  __typename: 'platform_api_log_tsvector';
  platform_api_log_id?: Maybe<Scalars['bigint']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "platform_api_log_tsvector" */
export type platform_api_log_tsvector_aggregate = {
  __typename: 'platform_api_log_tsvector_aggregate';
  aggregate?: Maybe<platform_api_log_tsvector_aggregate_fields>;
  nodes: Array<platform_api_log_tsvector>;
};

/** aggregate fields of "platform_api_log_tsvector" */
export type platform_api_log_tsvector_aggregate_fields = {
  __typename: 'platform_api_log_tsvector_aggregate_fields';
  avg?: Maybe<platform_api_log_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_api_log_tsvector_max_fields>;
  min?: Maybe<platform_api_log_tsvector_min_fields>;
  stddev?: Maybe<platform_api_log_tsvector_stddev_fields>;
  stddev_pop?: Maybe<platform_api_log_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_api_log_tsvector_stddev_samp_fields>;
  sum?: Maybe<platform_api_log_tsvector_sum_fields>;
  var_pop?: Maybe<platform_api_log_tsvector_var_pop_fields>;
  var_samp?: Maybe<platform_api_log_tsvector_var_samp_fields>;
  variance?: Maybe<platform_api_log_tsvector_variance_fields>;
};


/** aggregate fields of "platform_api_log_tsvector" */
export type platform_api_log_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_api_log_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_api_log_tsvector_avg_fields = {
  __typename: 'platform_api_log_tsvector_avg_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_api_log_tsvector". All fields are combined with a logical 'AND'. */
export type platform_api_log_tsvector_bool_exp = {
  _and?: InputMaybe<Array<platform_api_log_tsvector_bool_exp>>;
  _not?: InputMaybe<platform_api_log_tsvector_bool_exp>;
  _or?: InputMaybe<Array<platform_api_log_tsvector_bool_exp>>;
  platform_api_log_id?: InputMaybe<bigint_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type platform_api_log_tsvector_max_fields = {
  __typename: 'platform_api_log_tsvector_max_fields';
  platform_api_log_id?: Maybe<Scalars['bigint']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type platform_api_log_tsvector_min_fields = {
  __typename: 'platform_api_log_tsvector_min_fields';
  platform_api_log_id?: Maybe<Scalars['bigint']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "platform_api_log_tsvector". */
export type platform_api_log_tsvector_order_by = {
  platform_api_log_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "platform_api_log_tsvector" */
export enum platform_api_log_tsvector_select_column {
  /** column name */
  platform_api_log_id = 'platform_api_log_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type platform_api_log_tsvector_stddev_fields = {
  __typename: 'platform_api_log_tsvector_stddev_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_api_log_tsvector_stddev_pop_fields = {
  __typename: 'platform_api_log_tsvector_stddev_pop_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_api_log_tsvector_stddev_samp_fields = {
  __typename: 'platform_api_log_tsvector_stddev_samp_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_api_log_tsvector" */
export type platform_api_log_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_api_log_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_api_log_tsvector_stream_cursor_value_input = {
  platform_api_log_id?: InputMaybe<Scalars['bigint']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type platform_api_log_tsvector_sum_fields = {
  __typename: 'platform_api_log_tsvector_sum_fields';
  platform_api_log_id?: Maybe<Scalars['bigint']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type platform_api_log_tsvector_var_pop_fields = {
  __typename: 'platform_api_log_tsvector_var_pop_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_api_log_tsvector_var_samp_fields = {
  __typename: 'platform_api_log_tsvector_var_samp_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_api_log_tsvector_variance_fields = {
  __typename: 'platform_api_log_tsvector_variance_fields';
  platform_api_log_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "platform_api_log" */
export enum platform_api_log_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

export type platform_api_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_api_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_api_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_api_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_api_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_api_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_api_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_api_log_set_input>;
  /** filter the rows which have to be updated */
  where: platform_api_log_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_api_log_var_pop_fields = {
  __typename: 'platform_api_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_api_log_var_samp_fields = {
  __typename: 'platform_api_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_api_log_variance_fields = {
  __typename: 'platform_api_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_base_currency" */
export type platform_base_currency = {
  __typename: 'platform_base_currency';
  /** An array relationship */
  accounts: Array<account>;
  /** An aggregate relationship */
  accounts_aggregate: account_aggregate;
  /** An object relationship */
  platform_currency: platform_currency;
  /** An array relationship */
  platform_currency_exchange_rates: Array<platform_currency_exchange_rate>;
  /** An aggregate relationship */
  platform_currency_exchange_rates_aggregate: platform_currency_exchange_rate_aggregate;
  value: platform_currency_enum;
};


/** columns and relationships of "platform_base_currency" */
export type platform_base_currencyaccountsArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


/** columns and relationships of "platform_base_currency" */
export type platform_base_currencyaccounts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


/** columns and relationships of "platform_base_currency" */
export type platform_base_currencyplatform_currency_exchange_ratesArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


/** columns and relationships of "platform_base_currency" */
export type platform_base_currencyplatform_currency_exchange_rates_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};

/** aggregated selection of "platform_base_currency" */
export type platform_base_currency_aggregate = {
  __typename: 'platform_base_currency_aggregate';
  aggregate?: Maybe<platform_base_currency_aggregate_fields>;
  nodes: Array<platform_base_currency>;
};

export type platform_base_currency_aggregate_bool_exp = {
  count?: InputMaybe<platform_base_currency_aggregate_bool_exp_count>;
};

export type platform_base_currency_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_base_currency_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_base_currency_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_base_currency" */
export type platform_base_currency_aggregate_fields = {
  __typename: 'platform_base_currency_aggregate_fields';
  count: Scalars['Int']['output'];
};


/** aggregate fields of "platform_base_currency" */
export type platform_base_currency_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_base_currency_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_base_currency" */
export type platform_base_currency_aggregate_order_by = {
  count?: InputMaybe<order_by>;
};

/** input type for inserting array relation for remote table "platform_base_currency" */
export type platform_base_currency_arr_rel_insert_input = {
  data: Array<platform_base_currency_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_base_currency_on_conflict>;
};

/** Boolean expression to filter rows from the table "platform_base_currency". All fields are combined with a logical 'AND'. */
export type platform_base_currency_bool_exp = {
  _and?: InputMaybe<Array<platform_base_currency_bool_exp>>;
  _not?: InputMaybe<platform_base_currency_bool_exp>;
  _or?: InputMaybe<Array<platform_base_currency_bool_exp>>;
  accounts?: InputMaybe<account_bool_exp>;
  accounts_aggregate?: InputMaybe<account_aggregate_bool_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  platform_currency_exchange_rates?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
  platform_currency_exchange_rates_aggregate?: InputMaybe<platform_currency_exchange_rate_aggregate_bool_exp>;
  value?: InputMaybe<platform_currency_enum_comparison_exp>;
};

/** unique or primary key constraints on table "platform_base_currency" */
export enum platform_base_currency_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_base_currency_pkey = 'platform_base_currency_pkey'
}

export enum platform_base_currency_enum {
  usd = 'usd'
}

/** Boolean expression to compare columns of type "platform_base_currency_enum". All fields are combined with logical 'AND'. */
export type platform_base_currency_enum_comparison_exp = {
  _eq?: InputMaybe<platform_base_currency_enum>;
  _in?: InputMaybe<Array<platform_base_currency_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_base_currency_enum>;
  _nin?: InputMaybe<Array<platform_base_currency_enum>>;
};

/** input type for inserting data into table "platform_base_currency" */
export type platform_base_currency_insert_input = {
  accounts?: InputMaybe<account_arr_rel_insert_input>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  platform_currency_exchange_rates?: InputMaybe<platform_currency_exchange_rate_arr_rel_insert_input>;
  value?: InputMaybe<platform_currency_enum>;
};

/** response of any mutation on the table "platform_base_currency" */
export type platform_base_currency_mutation_response = {
  __typename: 'platform_base_currency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_base_currency>;
};

/** input type for inserting object relation for remote table "platform_base_currency" */
export type platform_base_currency_obj_rel_insert_input = {
  data: platform_base_currency_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_base_currency_on_conflict>;
};

/** on_conflict condition type for table "platform_base_currency" */
export type platform_base_currency_on_conflict = {
  constraint: platform_base_currency_constraint;
  update_columns?: Array<platform_base_currency_update_column>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};

/** Ordering options when selecting data from "platform_base_currency". */
export type platform_base_currency_order_by = {
  accounts_aggregate?: InputMaybe<account_aggregate_order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  platform_currency_exchange_rates_aggregate?: InputMaybe<platform_currency_exchange_rate_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_base_currency */
export type platform_base_currency_pk_columns_input = {
  value: platform_currency_enum;
};

/** select columns of table "platform_base_currency" */
export enum platform_base_currency_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_base_currency" */
export type platform_base_currency_set_input = {
  value?: InputMaybe<platform_currency_enum>;
};

/** Streaming cursor of the table "platform_base_currency" */
export type platform_base_currency_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_base_currency_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_base_currency_stream_cursor_value_input = {
  value?: InputMaybe<platform_currency_enum>;
};

/** update columns of table "platform_base_currency" */
export enum platform_base_currency_update_column {
  /** column name */
  value = 'value'
}

export type platform_base_currency_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_base_currency_set_input>;
  /** filter the rows which have to be updated */
  where: platform_base_currency_bool_exp;
};

/** Boolean expression to filter rows from the table "platform". All fields are combined with a logical 'AND'. */
export type platform_bool_exp = {
  _and?: InputMaybe<Array<platform_bool_exp>>;
  _not?: InputMaybe<platform_bool_exp>;
  _or?: InputMaybe<Array<platform_bool_exp>>;
  platform_connections?: InputMaybe<platform_connection_bool_exp>;
  platform_connections_aggregate?: InputMaybe<platform_connection_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** columns and relationships of "platform_cancel_reason" */
export type platform_cancel_reason = {
  __typename: 'platform_cancel_reason';
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  paper_code: Scalars['String']['output'];
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_cancel_reason" */
export type platform_cancel_reason_aggregate = {
  __typename: 'platform_cancel_reason_aggregate';
  aggregate?: Maybe<platform_cancel_reason_aggregate_fields>;
  nodes: Array<platform_cancel_reason>;
};

export type platform_cancel_reason_aggregate_bool_exp = {
  count?: InputMaybe<platform_cancel_reason_aggregate_bool_exp_count>;
};

export type platform_cancel_reason_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_cancel_reason_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_cancel_reason" */
export type platform_cancel_reason_aggregate_fields = {
  __typename: 'platform_cancel_reason_aggregate_fields';
  avg?: Maybe<platform_cancel_reason_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_cancel_reason_max_fields>;
  min?: Maybe<platform_cancel_reason_min_fields>;
  stddev?: Maybe<platform_cancel_reason_stddev_fields>;
  stddev_pop?: Maybe<platform_cancel_reason_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_cancel_reason_stddev_samp_fields>;
  sum?: Maybe<platform_cancel_reason_sum_fields>;
  var_pop?: Maybe<platform_cancel_reason_var_pop_fields>;
  var_samp?: Maybe<platform_cancel_reason_var_samp_fields>;
  variance?: Maybe<platform_cancel_reason_variance_fields>;
};


/** aggregate fields of "platform_cancel_reason" */
export type platform_cancel_reason_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_cancel_reason" */
export type platform_cancel_reason_aggregate_order_by = {
  avg?: InputMaybe<platform_cancel_reason_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_cancel_reason_max_order_by>;
  min?: InputMaybe<platform_cancel_reason_min_order_by>;
  stddev?: InputMaybe<platform_cancel_reason_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_cancel_reason_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_cancel_reason_stddev_samp_order_by>;
  sum?: InputMaybe<platform_cancel_reason_sum_order_by>;
  var_pop?: InputMaybe<platform_cancel_reason_var_pop_order_by>;
  var_samp?: InputMaybe<platform_cancel_reason_var_samp_order_by>;
  variance?: InputMaybe<platform_cancel_reason_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_cancel_reason" */
export type platform_cancel_reason_arr_rel_insert_input = {
  data: Array<platform_cancel_reason_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_cancel_reason_on_conflict>;
};

/** aggregate avg on columns */
export type platform_cancel_reason_avg_fields = {
  __typename: 'platform_cancel_reason_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_cancel_reason". All fields are combined with a logical 'AND'. */
export type platform_cancel_reason_bool_exp = {
  _and?: InputMaybe<Array<platform_cancel_reason_bool_exp>>;
  _not?: InputMaybe<platform_cancel_reason_bool_exp>;
  _or?: InputMaybe<Array<platform_cancel_reason_bool_exp>>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_cancel_reason" */
export enum platform_cancel_reason_constraint {
  /** unique or primary key constraint on columns "paper_code", "platform_connection_id", "platform_id" */
  platform_cancel_reason_pkey = 'platform_cancel_reason_pkey'
}

/** input type for incrementing numeric columns in table "platform_cancel_reason" */
export type platform_cancel_reason_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_cancel_reason" */
export type platform_cancel_reason_insert_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_cancel_reason_max_fields = {
  __typename: 'platform_cancel_reason_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_max_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_cancel_reason_min_fields = {
  __typename: 'platform_cancel_reason_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_min_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_cancel_reason" */
export type platform_cancel_reason_mutation_response = {
  __typename: 'platform_cancel_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_cancel_reason>;
};

/** on_conflict condition type for table "platform_cancel_reason" */
export type platform_cancel_reason_on_conflict = {
  constraint: platform_cancel_reason_constraint;
  update_columns?: Array<platform_cancel_reason_update_column>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};

/** Ordering options when selecting data from "platform_cancel_reason". */
export type platform_cancel_reason_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_cancel_reason */
export type platform_cancel_reason_pk_columns_input = {
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_cancel_reason" */
export enum platform_cancel_reason_select_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_cancel_reason" */
export type platform_cancel_reason_set_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_cancel_reason_stddev_fields = {
  __typename: 'platform_cancel_reason_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_cancel_reason_stddev_pop_fields = {
  __typename: 'platform_cancel_reason_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_cancel_reason_stddev_samp_fields = {
  __typename: 'platform_cancel_reason_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_cancel_reason" */
export type platform_cancel_reason_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_cancel_reason_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_cancel_reason_stream_cursor_value_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_cancel_reason_sum_fields = {
  __typename: 'platform_cancel_reason_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_cancel_reason" */
export enum platform_cancel_reason_update_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_cancel_reason_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_cancel_reason_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_cancel_reason_set_input>;
  /** filter the rows which have to be updated */
  where: platform_cancel_reason_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_cancel_reason_var_pop_fields = {
  __typename: 'platform_cancel_reason_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_cancel_reason_var_samp_fields = {
  __typename: 'platform_cancel_reason_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_cancel_reason_variance_fields = {
  __typename: 'platform_cancel_reason_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_cancel_reason" */
export type platform_cancel_reason_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_connection" */
export type platform_connection = {
  __typename: 'platform_connection';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  authentication_hash?: Maybe<Scalars['String']['output']>;
  data: Scalars['jsonb']['output'];
  data_text?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  import_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  import_started_at?: Maybe<Scalars['timestamptz']['output']>;
  last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  meta: Scalars['jsonb']['output'];
  /** An array relationship */
  naviga_paper_codes: Array<naviga_paper_code>;
  /** An aggregate relationship */
  naviga_paper_codes_aggregate: naviga_paper_code_aggregate;
  options: Scalars['jsonb']['output'];
  platform: platform_enum;
  /** An object relationship */
  platformByPlatform: platform;
  /** An array relationship */
  platform_addon_price_tiers: Array<platform_addon_price_tier>;
  /** An aggregate relationship */
  platform_addon_price_tiers_aggregate: platform_addon_price_tier_aggregate;
  /** An array relationship */
  platform_addon_prices: Array<platform_addon_price>;
  /** An aggregate relationship */
  platform_addon_prices_aggregate: platform_addon_price_aggregate;
  /** An array relationship */
  platform_addons: Array<platform_addon>;
  /** An aggregate relationship */
  platform_addons_aggregate: platform_addon_aggregate;
  /** An array relationship */
  platform_cancel_reasons: Array<platform_cancel_reason>;
  /** An aggregate relationship */
  platform_cancel_reasons_aggregate: platform_cancel_reason_aggregate;
  /** An object relationship */
  platform_connection_shopify_store?: Maybe<platform_connection_shopify_store>;
  /** An array relationship */
  platform_coupon_addons: Array<platform_coupon_addon>;
  /** An aggregate relationship */
  platform_coupon_addons_aggregate: platform_coupon_addon_aggregate;
  /** An array relationship */
  platform_coupon_plans: Array<platform_coupon_plan>;
  /** An aggregate relationship */
  platform_coupon_plans_aggregate: platform_coupon_plan_aggregate;
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  /** An array relationship */
  platform_customers: Array<platform_customer>;
  /** An aggregate relationship */
  platform_customers_aggregate: platform_customer_aggregate;
  /** An array relationship */
  platform_ecommerce_products: Array<platform_ecommerce_product>;
  /** An aggregate relationship */
  platform_ecommerce_products_aggregate: platform_ecommerce_product_aggregate;
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_invoices: Array<platform_invoice>;
  /** An aggregate relationship */
  platform_invoices_aggregate: platform_invoice_aggregate;
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  /** An array relationship */
  platform_product_groups: Array<platform_product_group>;
  /** An aggregate relationship */
  platform_product_groups_aggregate: platform_product_group_aggregate;
  /** An array relationship */
  platform_products: Array<platform_product>;
  /** An aggregate relationship */
  platform_products_aggregate: platform_product_aggregate;
  /** An array relationship */
  platform_refunds: Array<platform_refund>;
  /** An aggregate relationship */
  platform_refunds_aggregate: platform_refund_aggregate;
  /** An array relationship */
  platform_subscription_addons: Array<platform_subscription_addon>;
  /** An aggregate relationship */
  platform_subscription_addons_aggregate: platform_subscription_addon_aggregate;
  /** An array relationship */
  platform_subscriptions: Array<platform_subscription>;
  /** An aggregate relationship */
  platform_subscriptions_aggregate: platform_subscription_aggregate;
  publishable_key?: Maybe<Scalars['String']['output']>;
  ready: Scalars['Boolean']['output'];
  show_error: Scalars['Boolean']['output'];
  sync_disabled: Scalars['Boolean']['output'];
};


/** columns and relationships of "platform_connection" */
export type platform_connectiondataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionmetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionnaviga_paper_codesArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionnaviga_paper_codes_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionoptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addon_price_tiersArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addon_price_tiers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addon_pricesArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addon_prices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_cancel_reasonsArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_cancel_reasons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_coupon_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_coupon_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_coupon_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_coupon_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_customersArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_customers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_invoicesArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_invoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_product_groupsArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_product_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_refundsArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_refunds_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_subscription_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_subscription_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_subscriptionsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


/** columns and relationships of "platform_connection" */
export type platform_connectionplatform_subscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};

/** aggregated selection of "platform_connection" */
export type platform_connection_aggregate = {
  __typename: 'platform_connection_aggregate';
  aggregate?: Maybe<platform_connection_aggregate_fields>;
  nodes: Array<platform_connection>;
};

export type platform_connection_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_connection_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_connection_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_connection_aggregate_bool_exp_count>;
};

export type platform_connection_aggregate_bool_exp_bool_and = {
  arguments: platform_connection_select_column_platform_connection_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_connection_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_connection_aggregate_bool_exp_bool_or = {
  arguments: platform_connection_select_column_platform_connection_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_connection_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_connection_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_connection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_connection_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_connection" */
export type platform_connection_aggregate_fields = {
  __typename: 'platform_connection_aggregate_fields';
  avg?: Maybe<platform_connection_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_connection_max_fields>;
  min?: Maybe<platform_connection_min_fields>;
  stddev?: Maybe<platform_connection_stddev_fields>;
  stddev_pop?: Maybe<platform_connection_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_connection_stddev_samp_fields>;
  sum?: Maybe<platform_connection_sum_fields>;
  var_pop?: Maybe<platform_connection_var_pop_fields>;
  var_samp?: Maybe<platform_connection_var_samp_fields>;
  variance?: Maybe<platform_connection_variance_fields>;
};


/** aggregate fields of "platform_connection" */
export type platform_connection_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_connection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_connection" */
export type platform_connection_aggregate_order_by = {
  avg?: InputMaybe<platform_connection_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_connection_max_order_by>;
  min?: InputMaybe<platform_connection_min_order_by>;
  stddev?: InputMaybe<platform_connection_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_connection_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_connection_stddev_samp_order_by>;
  sum?: InputMaybe<platform_connection_sum_order_by>;
  var_pop?: InputMaybe<platform_connection_var_pop_order_by>;
  var_samp?: InputMaybe<platform_connection_var_samp_order_by>;
  variance?: InputMaybe<platform_connection_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_connection_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "platform_connection" */
export type platform_connection_arr_rel_insert_input = {
  data: Array<platform_connection_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_connection_on_conflict>;
};

/** aggregate avg on columns */
export type platform_connection_avg_fields = {
  __typename: 'platform_connection_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_connection" */
export type platform_connection_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_connection". All fields are combined with a logical 'AND'. */
export type platform_connection_bool_exp = {
  _and?: InputMaybe<Array<platform_connection_bool_exp>>;
  _not?: InputMaybe<platform_connection_bool_exp>;
  _or?: InputMaybe<Array<platform_connection_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  authentication_hash?: InputMaybe<String_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  data_text?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  import_completed_at?: InputMaybe<timestamptz_comparison_exp>;
  import_started_at?: InputMaybe<timestamptz_comparison_exp>;
  last_synced_at?: InputMaybe<timestamptz_comparison_exp>;
  meta?: InputMaybe<jsonb_comparison_exp>;
  naviga_paper_codes?: InputMaybe<naviga_paper_code_bool_exp>;
  naviga_paper_codes_aggregate?: InputMaybe<naviga_paper_code_aggregate_bool_exp>;
  options?: InputMaybe<jsonb_comparison_exp>;
  platform?: InputMaybe<platform_enum_comparison_exp>;
  platformByPlatform?: InputMaybe<platform_bool_exp>;
  platform_addon_price_tiers?: InputMaybe<platform_addon_price_tier_bool_exp>;
  platform_addon_price_tiers_aggregate?: InputMaybe<platform_addon_price_tier_aggregate_bool_exp>;
  platform_addon_prices?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_bool_exp>;
  platform_addons?: InputMaybe<platform_addon_bool_exp>;
  platform_addons_aggregate?: InputMaybe<platform_addon_aggregate_bool_exp>;
  platform_cancel_reasons?: InputMaybe<platform_cancel_reason_bool_exp>;
  platform_cancel_reasons_aggregate?: InputMaybe<platform_cancel_reason_aggregate_bool_exp>;
  platform_connection_shopify_store?: InputMaybe<platform_connection_shopify_store_bool_exp>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_bool_exp>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_bool_exp>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_bool_exp>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_bool_exp>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  platform_customers?: InputMaybe<platform_customer_bool_exp>;
  platform_customers_aggregate?: InputMaybe<platform_customer_aggregate_bool_exp>;
  platform_ecommerce_products?: InputMaybe<platform_ecommerce_product_bool_exp>;
  platform_ecommerce_products_aggregate?: InputMaybe<platform_ecommerce_product_aggregate_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_invoices?: InputMaybe<platform_invoice_bool_exp>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_bool_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  platform_product_groups?: InputMaybe<platform_product_group_bool_exp>;
  platform_product_groups_aggregate?: InputMaybe<platform_product_group_aggregate_bool_exp>;
  platform_products?: InputMaybe<platform_product_bool_exp>;
  platform_products_aggregate?: InputMaybe<platform_product_aggregate_bool_exp>;
  platform_refunds?: InputMaybe<platform_refund_bool_exp>;
  platform_refunds_aggregate?: InputMaybe<platform_refund_aggregate_bool_exp>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_bool_exp>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_bool_exp>;
  platform_subscriptions?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_bool_exp>;
  publishable_key?: InputMaybe<String_comparison_exp>;
  ready?: InputMaybe<Boolean_comparison_exp>;
  show_error?: InputMaybe<Boolean_comparison_exp>;
  sync_disabled?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "platform_connection" */
export enum platform_connection_constraint {
  /** unique or primary key constraint on columns "account_id" */
  platform_connection_account_id_key = 'platform_connection_account_id_key',
  /** unique or primary key constraint on columns "id" */
  platform_connection_pkey = 'platform_connection_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_connection_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  meta?: InputMaybe<Array<Scalars['String']['input']>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_connection_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['Int']['input']>;
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_connection_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_connection" */
export type platform_connection_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_connection" */
export type platform_connection_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  import_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  naviga_paper_codes?: InputMaybe<naviga_paper_code_arr_rel_insert_input>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  platform?: InputMaybe<platform_enum>;
  platformByPlatform?: InputMaybe<platform_obj_rel_insert_input>;
  platform_addon_price_tiers?: InputMaybe<platform_addon_price_tier_arr_rel_insert_input>;
  platform_addon_prices?: InputMaybe<platform_addon_price_arr_rel_insert_input>;
  platform_addons?: InputMaybe<platform_addon_arr_rel_insert_input>;
  platform_cancel_reasons?: InputMaybe<platform_cancel_reason_arr_rel_insert_input>;
  platform_connection_shopify_store?: InputMaybe<platform_connection_shopify_store_obj_rel_insert_input>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_arr_rel_insert_input>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_arr_rel_insert_input>;
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  platform_customers?: InputMaybe<platform_customer_arr_rel_insert_input>;
  platform_ecommerce_products?: InputMaybe<platform_ecommerce_product_arr_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoices?: InputMaybe<platform_invoice_arr_rel_insert_input>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  platform_product_groups?: InputMaybe<platform_product_group_arr_rel_insert_input>;
  platform_products?: InputMaybe<platform_product_arr_rel_insert_input>;
  platform_refunds?: InputMaybe<platform_refund_arr_rel_insert_input>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_arr_rel_insert_input>;
  platform_subscriptions?: InputMaybe<platform_subscription_arr_rel_insert_input>;
  publishable_key?: InputMaybe<Scalars['String']['input']>;
  ready?: InputMaybe<Scalars['Boolean']['input']>;
  show_error?: InputMaybe<Scalars['Boolean']['input']>;
  sync_disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type platform_connection_max_fields = {
  __typename: 'platform_connection_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  authentication_hash?: Maybe<Scalars['String']['output']>;
  data_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  import_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  import_started_at?: Maybe<Scalars['timestamptz']['output']>;
  last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  publishable_key?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_connection" */
export type platform_connection_max_order_by = {
  account_id?: InputMaybe<order_by>;
  authentication_hash?: InputMaybe<order_by>;
  data_text?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  import_completed_at?: InputMaybe<order_by>;
  import_started_at?: InputMaybe<order_by>;
  last_synced_at?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  publishable_key?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_connection_min_fields = {
  __typename: 'platform_connection_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  authentication_hash?: Maybe<Scalars['String']['output']>;
  data_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  import_completed_at?: Maybe<Scalars['timestamptz']['output']>;
  import_started_at?: Maybe<Scalars['timestamptz']['output']>;
  last_synced_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  publishable_key?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_connection" */
export type platform_connection_min_order_by = {
  account_id?: InputMaybe<order_by>;
  authentication_hash?: InputMaybe<order_by>;
  data_text?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  import_completed_at?: InputMaybe<order_by>;
  import_started_at?: InputMaybe<order_by>;
  last_synced_at?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  publishable_key?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_connection" */
export type platform_connection_mutation_response = {
  __typename: 'platform_connection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_connection>;
};

/** columns and relationships of "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain = {
  __typename: 'platform_connection_naviga_domain';
  created_at: Scalars['timestamptz']['output'];
  domain: Scalars['String']['output'];
  /** An object relationship */
  naviga_domain: naviga_domain;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  use_account_number: Scalars['Boolean']['output'];
};

/** aggregated selection of "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_aggregate = {
  __typename: 'platform_connection_naviga_domain_aggregate';
  aggregate?: Maybe<platform_connection_naviga_domain_aggregate_fields>;
  nodes: Array<platform_connection_naviga_domain>;
};

/** aggregate fields of "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_aggregate_fields = {
  __typename: 'platform_connection_naviga_domain_aggregate_fields';
  avg?: Maybe<platform_connection_naviga_domain_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_connection_naviga_domain_max_fields>;
  min?: Maybe<platform_connection_naviga_domain_min_fields>;
  stddev?: Maybe<platform_connection_naviga_domain_stddev_fields>;
  stddev_pop?: Maybe<platform_connection_naviga_domain_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_connection_naviga_domain_stddev_samp_fields>;
  sum?: Maybe<platform_connection_naviga_domain_sum_fields>;
  var_pop?: Maybe<platform_connection_naviga_domain_var_pop_fields>;
  var_samp?: Maybe<platform_connection_naviga_domain_var_samp_fields>;
  variance?: Maybe<platform_connection_naviga_domain_variance_fields>;
};


/** aggregate fields of "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_connection_naviga_domain_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_connection_naviga_domain_avg_fields = {
  __typename: 'platform_connection_naviga_domain_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_connection_naviga_domain". All fields are combined with a logical 'AND'. */
export type platform_connection_naviga_domain_bool_exp = {
  _and?: InputMaybe<Array<platform_connection_naviga_domain_bool_exp>>;
  _not?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
  _or?: InputMaybe<Array<platform_connection_naviga_domain_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  domain?: InputMaybe<String_comparison_exp>;
  naviga_domain?: InputMaybe<naviga_domain_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  use_account_number?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "platform_connection_naviga_domain" */
export enum platform_connection_naviga_domain_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "domain" */
  platform_connection_naviga_domain_pkey = 'platform_connection_naviga_domain_pkey'
}

/** input type for incrementing numeric columns in table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  naviga_domain?: InputMaybe<naviga_domain_obj_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  use_account_number?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type platform_connection_naviga_domain_max_fields = {
  __typename: 'platform_connection_naviga_domain_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type platform_connection_naviga_domain_min_fields = {
  __typename: 'platform_connection_naviga_domain_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_mutation_response = {
  __typename: 'platform_connection_naviga_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_connection_naviga_domain>;
};

/** on_conflict condition type for table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_on_conflict = {
  constraint: platform_connection_naviga_domain_constraint;
  update_columns?: Array<platform_connection_naviga_domain_update_column>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};

/** Ordering options when selecting data from "platform_connection_naviga_domain". */
export type platform_connection_naviga_domain_order_by = {
  created_at?: InputMaybe<order_by>;
  domain?: InputMaybe<order_by>;
  naviga_domain?: InputMaybe<naviga_domain_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  use_account_number?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_connection_naviga_domain */
export type platform_connection_naviga_domain_pk_columns_input = {
  domain: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};

/** select columns of table "platform_connection_naviga_domain" */
export enum platform_connection_naviga_domain_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  domain = 'domain',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  use_account_number = 'use_account_number'
}

/** input type for updating data in table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  use_account_number?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type platform_connection_naviga_domain_stddev_fields = {
  __typename: 'platform_connection_naviga_domain_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_connection_naviga_domain_stddev_pop_fields = {
  __typename: 'platform_connection_naviga_domain_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_connection_naviga_domain_stddev_samp_fields = {
  __typename: 'platform_connection_naviga_domain_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_connection_naviga_domain" */
export type platform_connection_naviga_domain_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_connection_naviga_domain_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_connection_naviga_domain_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  use_account_number?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type platform_connection_naviga_domain_sum_fields = {
  __typename: 'platform_connection_naviga_domain_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_connection_naviga_domain" */
export enum platform_connection_naviga_domain_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  domain = 'domain',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  use_account_number = 'use_account_number'
}

export type platform_connection_naviga_domain_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_connection_naviga_domain_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_connection_naviga_domain_set_input>;
  /** filter the rows which have to be updated */
  where: platform_connection_naviga_domain_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_connection_naviga_domain_var_pop_fields = {
  __typename: 'platform_connection_naviga_domain_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_connection_naviga_domain_var_samp_fields = {
  __typename: 'platform_connection_naviga_domain_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_connection_naviga_domain_variance_fields = {
  __typename: 'platform_connection_naviga_domain_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint = {
  __typename: 'platform_connection_naviga_paper_code_endpoint';
  access_controlled: Scalars['Boolean']['output'];
  client_code: Scalars['String']['output'];
  endpoint: Scalars['String']['output'];
  media_group_code: Scalars['String']['output'];
  /** An object relationship */
  naviga_paper_code: naviga_paper_code;
  paper_code: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
};

/** aggregated selection of "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_aggregate = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_aggregate';
  aggregate?: Maybe<platform_connection_naviga_paper_code_endpoint_aggregate_fields>;
  nodes: Array<platform_connection_naviga_paper_code_endpoint>;
};

/** aggregate fields of "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_aggregate_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_aggregate_fields';
  avg?: Maybe<platform_connection_naviga_paper_code_endpoint_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_connection_naviga_paper_code_endpoint_max_fields>;
  min?: Maybe<platform_connection_naviga_paper_code_endpoint_min_fields>;
  stddev?: Maybe<platform_connection_naviga_paper_code_endpoint_stddev_fields>;
  stddev_pop?: Maybe<platform_connection_naviga_paper_code_endpoint_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_connection_naviga_paper_code_endpoint_stddev_samp_fields>;
  sum?: Maybe<platform_connection_naviga_paper_code_endpoint_sum_fields>;
  var_pop?: Maybe<platform_connection_naviga_paper_code_endpoint_var_pop_fields>;
  var_samp?: Maybe<platform_connection_naviga_paper_code_endpoint_var_samp_fields>;
  variance?: Maybe<platform_connection_naviga_paper_code_endpoint_variance_fields>;
};


/** aggregate fields of "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_connection_naviga_paper_code_endpoint_avg_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_connection_naviga_paper_code_endpoint". All fields are combined with a logical 'AND'. */
export type platform_connection_naviga_paper_code_endpoint_bool_exp = {
  _and?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_bool_exp>>;
  _not?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
  _or?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_bool_exp>>;
  access_controlled?: InputMaybe<Boolean_comparison_exp>;
  client_code?: InputMaybe<String_comparison_exp>;
  endpoint?: InputMaybe<String_comparison_exp>;
  media_group_code?: InputMaybe<String_comparison_exp>;
  naviga_paper_code?: InputMaybe<naviga_paper_code_bool_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "platform_connection_naviga_paper_code_endpoint" */
export enum platform_connection_naviga_paper_code_endpoint_constraint {
  /** unique or primary key constraint on columns "paper_code", "media_group_code", "platform_connection_id", "client_code" */
  platform_connection_naviga_paper_code_endpoint_pkey = 'platform_connection_naviga_paper_code_endpoint_pkey'
}

/** input type for incrementing numeric columns in table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_insert_input = {
  access_controlled?: InputMaybe<Scalars['Boolean']['input']>;
  client_code?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  naviga_paper_code?: InputMaybe<naviga_paper_code_obj_rel_insert_input>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type platform_connection_naviga_paper_code_endpoint_max_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_max_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type platform_connection_naviga_paper_code_endpoint_min_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_min_fields';
  client_code?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  media_group_code?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_mutation_response = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_connection_naviga_paper_code_endpoint>;
};

/** input type for inserting object relation for remote table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_obj_rel_insert_input = {
  data: platform_connection_naviga_paper_code_endpoint_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_connection_naviga_paper_code_endpoint_on_conflict>;
};

/** on_conflict condition type for table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_on_conflict = {
  constraint: platform_connection_naviga_paper_code_endpoint_constraint;
  update_columns?: Array<platform_connection_naviga_paper_code_endpoint_update_column>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};

/** Ordering options when selecting data from "platform_connection_naviga_paper_code_endpoint". */
export type platform_connection_naviga_paper_code_endpoint_order_by = {
  access_controlled?: InputMaybe<order_by>;
  client_code?: InputMaybe<order_by>;
  endpoint?: InputMaybe<order_by>;
  media_group_code?: InputMaybe<order_by>;
  naviga_paper_code?: InputMaybe<naviga_paper_code_order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_connection_naviga_paper_code_endpoint */
export type platform_connection_naviga_paper_code_endpoint_pk_columns_input = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};

/** select columns of table "platform_connection_naviga_paper_code_endpoint" */
export enum platform_connection_naviga_paper_code_endpoint_select_column {
  /** column name */
  access_controlled = 'access_controlled',
  /** column name */
  client_code = 'client_code',
  /** column name */
  endpoint = 'endpoint',
  /** column name */
  media_group_code = 'media_group_code',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id'
}

/** input type for updating data in table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_set_input = {
  access_controlled?: InputMaybe<Scalars['Boolean']['input']>;
  client_code?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type platform_connection_naviga_paper_code_endpoint_stddev_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_connection_naviga_paper_code_endpoint_stddev_pop_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_connection_naviga_paper_code_endpoint_stddev_samp_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_connection_naviga_paper_code_endpoint" */
export type platform_connection_naviga_paper_code_endpoint_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_connection_naviga_paper_code_endpoint_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_connection_naviga_paper_code_endpoint_stream_cursor_value_input = {
  access_controlled?: InputMaybe<Scalars['Boolean']['input']>;
  client_code?: InputMaybe<Scalars['String']['input']>;
  endpoint?: InputMaybe<Scalars['String']['input']>;
  media_group_code?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type platform_connection_naviga_paper_code_endpoint_sum_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_connection_naviga_paper_code_endpoint" */
export enum platform_connection_naviga_paper_code_endpoint_update_column {
  /** column name */
  access_controlled = 'access_controlled',
  /** column name */
  client_code = 'client_code',
  /** column name */
  endpoint = 'endpoint',
  /** column name */
  media_group_code = 'media_group_code',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id'
}

export type platform_connection_naviga_paper_code_endpoint_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_connection_naviga_paper_code_endpoint_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_connection_naviga_paper_code_endpoint_set_input>;
  /** filter the rows which have to be updated */
  where: platform_connection_naviga_paper_code_endpoint_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_connection_naviga_paper_code_endpoint_var_pop_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_connection_naviga_paper_code_endpoint_var_samp_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_connection_naviga_paper_code_endpoint_variance_fields = {
  __typename: 'platform_connection_naviga_paper_code_endpoint_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting object relation for remote table "platform_connection" */
export type platform_connection_obj_rel_insert_input = {
  data: platform_connection_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_connection_on_conflict>;
};

/** on_conflict condition type for table "platform_connection" */
export type platform_connection_on_conflict = {
  constraint: platform_connection_constraint;
  update_columns?: Array<platform_connection_update_column>;
  where?: InputMaybe<platform_connection_bool_exp>;
};

/** Ordering options when selecting data from "platform_connection". */
export type platform_connection_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  authentication_hash?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  data_text?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  import_completed_at?: InputMaybe<order_by>;
  import_started_at?: InputMaybe<order_by>;
  last_synced_at?: InputMaybe<order_by>;
  meta?: InputMaybe<order_by>;
  naviga_paper_codes_aggregate?: InputMaybe<naviga_paper_code_aggregate_order_by>;
  options?: InputMaybe<order_by>;
  platform?: InputMaybe<order_by>;
  platformByPlatform?: InputMaybe<platform_order_by>;
  platform_addon_price_tiers_aggregate?: InputMaybe<platform_addon_price_tier_aggregate_order_by>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_order_by>;
  platform_addons_aggregate?: InputMaybe<platform_addon_aggregate_order_by>;
  platform_cancel_reasons_aggregate?: InputMaybe<platform_cancel_reason_aggregate_order_by>;
  platform_connection_shopify_store?: InputMaybe<platform_connection_shopify_store_order_by>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_order_by>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_order_by>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  platform_customers_aggregate?: InputMaybe<platform_customer_aggregate_order_by>;
  platform_ecommerce_products_aggregate?: InputMaybe<platform_ecommerce_product_aggregate_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  platform_product_groups_aggregate?: InputMaybe<platform_product_group_aggregate_order_by>;
  platform_products_aggregate?: InputMaybe<platform_product_aggregate_order_by>;
  platform_refunds_aggregate?: InputMaybe<platform_refund_aggregate_order_by>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_order_by>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_order_by>;
  publishable_key?: InputMaybe<order_by>;
  ready?: InputMaybe<order_by>;
  show_error?: InputMaybe<order_by>;
  sync_disabled?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_connection */
export type platform_connection_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_connection_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_connection" */
export enum platform_connection_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  authentication_hash = 'authentication_hash',
  /** column name */
  data = 'data',
  /** column name */
  data_text = 'data_text',
  /** column name */
  id = 'id',
  /** column name */
  import_completed_at = 'import_completed_at',
  /** column name */
  import_started_at = 'import_started_at',
  /** column name */
  last_synced_at = 'last_synced_at',
  /** column name */
  meta = 'meta',
  /** column name */
  options = 'options',
  /** column name */
  platform = 'platform',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  publishable_key = 'publishable_key',
  /** column name */
  ready = 'ready',
  /** column name */
  show_error = 'show_error',
  /** column name */
  sync_disabled = 'sync_disabled'
}

/** select "platform_connection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_connection" */
export enum platform_connection_select_column_platform_connection_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  ready = 'ready',
  /** column name */
  show_error = 'show_error',
  /** column name */
  sync_disabled = 'sync_disabled'
}

/** select "platform_connection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_connection" */
export enum platform_connection_select_column_platform_connection_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  ready = 'ready',
  /** column name */
  show_error = 'show_error',
  /** column name */
  sync_disabled = 'sync_disabled'
}

/** input type for updating data in table "platform_connection" */
export type platform_connection_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  import_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  platform?: InputMaybe<platform_enum>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  publishable_key?: InputMaybe<Scalars['String']['input']>;
  ready?: InputMaybe<Scalars['Boolean']['input']>;
  show_error?: InputMaybe<Scalars['Boolean']['input']>;
  sync_disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "platform_connection_shopify_store" */
export type platform_connection_shopify_store = {
  __typename: 'platform_connection_shopify_store';
  access_token?: Maybe<Scalars['String']['output']>;
  app_id?: Maybe<Scalars['String']['output']>;
  authentication_hash?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  client_secret?: Maybe<Scalars['String']['output']>;
  connected: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  customer_portal_origin: Scalars['String']['output'];
  install_link?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  scopes?: Maybe<Scalars['jsonb']['output']>;
  store: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_connection_shopify_store" */
export type platform_connection_shopify_storescopesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_connection_shopify_store" */
export type platform_connection_shopify_store_aggregate = {
  __typename: 'platform_connection_shopify_store_aggregate';
  aggregate?: Maybe<platform_connection_shopify_store_aggregate_fields>;
  nodes: Array<platform_connection_shopify_store>;
};

/** aggregate fields of "platform_connection_shopify_store" */
export type platform_connection_shopify_store_aggregate_fields = {
  __typename: 'platform_connection_shopify_store_aggregate_fields';
  avg?: Maybe<platform_connection_shopify_store_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_connection_shopify_store_max_fields>;
  min?: Maybe<platform_connection_shopify_store_min_fields>;
  stddev?: Maybe<platform_connection_shopify_store_stddev_fields>;
  stddev_pop?: Maybe<platform_connection_shopify_store_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_connection_shopify_store_stddev_samp_fields>;
  sum?: Maybe<platform_connection_shopify_store_sum_fields>;
  var_pop?: Maybe<platform_connection_shopify_store_var_pop_fields>;
  var_samp?: Maybe<platform_connection_shopify_store_var_samp_fields>;
  variance?: Maybe<platform_connection_shopify_store_variance_fields>;
};


/** aggregate fields of "platform_connection_shopify_store" */
export type platform_connection_shopify_store_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_connection_shopify_store_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_connection_shopify_store_append_input = {
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type platform_connection_shopify_store_avg_fields = {
  __typename: 'platform_connection_shopify_store_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_connection_shopify_store". All fields are combined with a logical 'AND'. */
export type platform_connection_shopify_store_bool_exp = {
  _and?: InputMaybe<Array<platform_connection_shopify_store_bool_exp>>;
  _not?: InputMaybe<platform_connection_shopify_store_bool_exp>;
  _or?: InputMaybe<Array<platform_connection_shopify_store_bool_exp>>;
  access_token?: InputMaybe<String_comparison_exp>;
  app_id?: InputMaybe<String_comparison_exp>;
  authentication_hash?: InputMaybe<String_comparison_exp>;
  client_id?: InputMaybe<String_comparison_exp>;
  client_secret?: InputMaybe<String_comparison_exp>;
  connected?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  customer_portal_origin?: InputMaybe<String_comparison_exp>;
  install_link?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  scopes?: InputMaybe<jsonb_comparison_exp>;
  store?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_connection_shopify_store" */
export enum platform_connection_shopify_store_constraint {
  /** unique or primary key constraint on columns "platform_connection_id" */
  platform_connection_shopify_store_pkey = 'platform_connection_shopify_store_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_connection_shopify_store_delete_at_path_input = {
  scopes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_connection_shopify_store_delete_elem_input = {
  scopes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_connection_shopify_store_delete_key_input = {
  scopes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_insert_input = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  client_secret?: InputMaybe<Scalars['String']['input']>;
  connected?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_portal_origin?: InputMaybe<Scalars['String']['input']>;
  install_link?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  store?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_connection_shopify_store_max_fields = {
  __typename: 'platform_connection_shopify_store_max_fields';
  access_token?: Maybe<Scalars['String']['output']>;
  app_id?: Maybe<Scalars['String']['output']>;
  authentication_hash?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  client_secret?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_portal_origin?: Maybe<Scalars['String']['output']>;
  install_link?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type platform_connection_shopify_store_min_fields = {
  __typename: 'platform_connection_shopify_store_min_fields';
  access_token?: Maybe<Scalars['String']['output']>;
  app_id?: Maybe<Scalars['String']['output']>;
  authentication_hash?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  client_secret?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  customer_portal_origin?: Maybe<Scalars['String']['output']>;
  install_link?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_mutation_response = {
  __typename: 'platform_connection_shopify_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_connection_shopify_store>;
};

/** input type for inserting object relation for remote table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_obj_rel_insert_input = {
  data: platform_connection_shopify_store_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_connection_shopify_store_on_conflict>;
};

/** on_conflict condition type for table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_on_conflict = {
  constraint: platform_connection_shopify_store_constraint;
  update_columns?: Array<platform_connection_shopify_store_update_column>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};

/** Ordering options when selecting data from "platform_connection_shopify_store". */
export type platform_connection_shopify_store_order_by = {
  access_token?: InputMaybe<order_by>;
  app_id?: InputMaybe<order_by>;
  authentication_hash?: InputMaybe<order_by>;
  client_id?: InputMaybe<order_by>;
  client_secret?: InputMaybe<order_by>;
  connected?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  customer_portal_origin?: InputMaybe<order_by>;
  install_link?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  scopes?: InputMaybe<order_by>;
  store?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_connection_shopify_store */
export type platform_connection_shopify_store_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_connection_shopify_store_prepend_input = {
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_connection_shopify_store" */
export enum platform_connection_shopify_store_select_column {
  /** column name */
  access_token = 'access_token',
  /** column name */
  app_id = 'app_id',
  /** column name */
  authentication_hash = 'authentication_hash',
  /** column name */
  client_id = 'client_id',
  /** column name */
  client_secret = 'client_secret',
  /** column name */
  connected = 'connected',
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_portal_origin = 'customer_portal_origin',
  /** column name */
  install_link = 'install_link',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  scopes = 'scopes',
  /** column name */
  store = 'store',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_set_input = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  client_secret?: InputMaybe<Scalars['String']['input']>;
  connected?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_portal_origin?: InputMaybe<Scalars['String']['input']>;
  install_link?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  store?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_connection_shopify_store_stddev_fields = {
  __typename: 'platform_connection_shopify_store_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_connection_shopify_store_stddev_pop_fields = {
  __typename: 'platform_connection_shopify_store_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_connection_shopify_store_stddev_samp_fields = {
  __typename: 'platform_connection_shopify_store_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_connection_shopify_store" */
export type platform_connection_shopify_store_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_connection_shopify_store_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_connection_shopify_store_stream_cursor_value_input = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  app_id?: InputMaybe<Scalars['String']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  client_secret?: InputMaybe<Scalars['String']['input']>;
  connected?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  customer_portal_origin?: InputMaybe<Scalars['String']['input']>;
  install_link?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  scopes?: InputMaybe<Scalars['jsonb']['input']>;
  store?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_connection_shopify_store_sum_fields = {
  __typename: 'platform_connection_shopify_store_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_connection_shopify_store" */
export enum platform_connection_shopify_store_update_column {
  /** column name */
  access_token = 'access_token',
  /** column name */
  app_id = 'app_id',
  /** column name */
  authentication_hash = 'authentication_hash',
  /** column name */
  client_id = 'client_id',
  /** column name */
  client_secret = 'client_secret',
  /** column name */
  connected = 'connected',
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_portal_origin = 'customer_portal_origin',
  /** column name */
  install_link = 'install_link',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  scopes = 'scopes',
  /** column name */
  store = 'store',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_connection_shopify_store_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_connection_shopify_store_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_connection_shopify_store_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_connection_shopify_store_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_connection_shopify_store_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_connection_shopify_store_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_connection_shopify_store_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_connection_shopify_store_set_input>;
  /** filter the rows which have to be updated */
  where: platform_connection_shopify_store_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_connection_shopify_store_var_pop_fields = {
  __typename: 'platform_connection_shopify_store_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_connection_shopify_store_var_samp_fields = {
  __typename: 'platform_connection_shopify_store_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_connection_shopify_store_variance_fields = {
  __typename: 'platform_connection_shopify_store_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type platform_connection_stddev_fields = {
  __typename: 'platform_connection_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_connection" */
export type platform_connection_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_connection_stddev_pop_fields = {
  __typename: 'platform_connection_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_connection" */
export type platform_connection_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_connection_stddev_samp_fields = {
  __typename: 'platform_connection_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_connection" */
export type platform_connection_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_connection" */
export type platform_connection_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_connection_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_connection_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  authentication_hash?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  import_completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  import_started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_synced_at?: InputMaybe<Scalars['timestamptz']['input']>;
  meta?: InputMaybe<Scalars['jsonb']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
  platform?: InputMaybe<platform_enum>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  publishable_key?: InputMaybe<Scalars['String']['input']>;
  ready?: InputMaybe<Scalars['Boolean']['input']>;
  show_error?: InputMaybe<Scalars['Boolean']['input']>;
  sync_disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type platform_connection_sum_fields = {
  __typename: 'platform_connection_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_connection" */
export type platform_connection_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "platform_connection" */
export enum platform_connection_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  authentication_hash = 'authentication_hash',
  /** column name */
  data = 'data',
  /** column name */
  data_text = 'data_text',
  /** column name */
  id = 'id',
  /** column name */
  import_completed_at = 'import_completed_at',
  /** column name */
  import_started_at = 'import_started_at',
  /** column name */
  last_synced_at = 'last_synced_at',
  /** column name */
  meta = 'meta',
  /** column name */
  options = 'options',
  /** column name */
  platform = 'platform',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  publishable_key = 'publishable_key',
  /** column name */
  ready = 'ready',
  /** column name */
  show_error = 'show_error',
  /** column name */
  sync_disabled = 'sync_disabled'
}

export type platform_connection_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_connection_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_connection_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_connection_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_connection_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_connection_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_connection_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_connection_set_input>;
  /** filter the rows which have to be updated */
  where: platform_connection_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_connection_var_pop_fields = {
  __typename: 'platform_connection_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_connection" */
export type platform_connection_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_connection_var_samp_fields = {
  __typename: 'platform_connection_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_connection" */
export type platform_connection_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_connection_variance_fields = {
  __typename: 'platform_connection_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_connection" */
export type platform_connection_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "platform" */
export enum platform_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_pkey = 'platform_pkey'
}

/** columns and relationships of "platform_coupon" */
export type platform_coupon = {
  __typename: 'platform_coupon';
  amount_off: Scalars['numeric']['output'];
  apply_to: platform_coupon_apply_to_enum;
  apply_to_addons?: Maybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_plans?: Maybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_product_groups?: Maybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_products?: Maybe<platform_coupon_apply_to_restriction_enum>;
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency?: Maybe<platform_currency_enum>;
  duration: platform_coupon_duration_enum;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval?: Maybe<platform_coupon_duration_interval_enum>;
  duration_interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platformCouponApplyToConstraintByApplyToPlans?: Maybe<platform_coupon_apply_to_restriction>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An array relationship */
  platform_coupon_addons: Array<platform_coupon_addon>;
  /** An aggregate relationship */
  platform_coupon_addons_aggregate: platform_coupon_addon_aggregate;
  /** An object relationship */
  platform_coupon_apply_to: platform_coupon_apply_to;
  /** An object relationship */
  platform_coupon_apply_to_constraint?: Maybe<platform_coupon_apply_to_restriction>;
  /** An object relationship */
  platform_coupon_duration: platform_coupon_duration;
  /** An object relationship */
  platform_coupon_duration_interval?: Maybe<platform_coupon_duration_interval>;
  /** An array relationship */
  platform_coupon_plans: Array<platform_coupon_plan>;
  /** An aggregate relationship */
  platform_coupon_plans_aggregate: platform_coupon_plan_aggregate;
  /** An array relationship */
  platform_coupon_product_groups: Array<platform_coupon_product_group>;
  /** An aggregate relationship */
  platform_coupon_product_groups_aggregate: platform_coupon_product_group_aggregate;
  /** An array relationship */
  platform_coupon_products: Array<platform_coupon_product>;
  /** An aggregate relationship */
  platform_coupon_products_aggregate: platform_coupon_product_aggregate;
  /** An object relationship */
  platform_coupon_type: platform_coupon_type;
  /** An object relationship */
  platform_currency?: Maybe<platform_currency>;
  /** An array relationship */
  platform_customers: Array<platform_customer>;
  /** An aggregate relationship */
  platform_customers_aggregate: platform_customer_aggregate;
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_product_group?: Maybe<platform_product_group>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  platform_subscriptions: Array<platform_subscription>;
  /** An aggregate relationship */
  platform_subscriptions_aggregate: platform_subscription_aggregate;
  type: platform_coupon_type_enum;
  updated_at: Scalars['timestamptz']['output'];
  valid: Scalars['Boolean']['output'];
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_product_groupsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_product_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_coupon_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_customersArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_customers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_subscriptionsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


/** columns and relationships of "platform_coupon" */
export type platform_couponplatform_subscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};

/** columns and relationships of "platform_coupon_addon" */
export type platform_coupon_addon = {
  __typename: 'platform_coupon_addon';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_addon?: Maybe<platform_addon>;
  platform_addon_id: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_coupon?: Maybe<platform_coupon>;
  platform_coupon_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_coupon_addon" */
export type platform_coupon_addon_aggregate = {
  __typename: 'platform_coupon_addon_aggregate';
  aggregate?: Maybe<platform_coupon_addon_aggregate_fields>;
  nodes: Array<platform_coupon_addon>;
};

export type platform_coupon_addon_aggregate_bool_exp = {
  count?: InputMaybe<platform_coupon_addon_aggregate_bool_exp_count>;
};

export type platform_coupon_addon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_addon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_coupon_addon" */
export type platform_coupon_addon_aggregate_fields = {
  __typename: 'platform_coupon_addon_aggregate_fields';
  avg?: Maybe<platform_coupon_addon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_addon_max_fields>;
  min?: Maybe<platform_coupon_addon_min_fields>;
  stddev?: Maybe<platform_coupon_addon_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_addon_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_addon_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_addon_sum_fields>;
  var_pop?: Maybe<platform_coupon_addon_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_addon_var_samp_fields>;
  variance?: Maybe<platform_coupon_addon_variance_fields>;
};


/** aggregate fields of "platform_coupon_addon" */
export type platform_coupon_addon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_coupon_addon" */
export type platform_coupon_addon_aggregate_order_by = {
  avg?: InputMaybe<platform_coupon_addon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_coupon_addon_max_order_by>;
  min?: InputMaybe<platform_coupon_addon_min_order_by>;
  stddev?: InputMaybe<platform_coupon_addon_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_coupon_addon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_coupon_addon_stddev_samp_order_by>;
  sum?: InputMaybe<platform_coupon_addon_sum_order_by>;
  var_pop?: InputMaybe<platform_coupon_addon_var_pop_order_by>;
  var_samp?: InputMaybe<platform_coupon_addon_var_samp_order_by>;
  variance?: InputMaybe<platform_coupon_addon_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_coupon_addon" */
export type platform_coupon_addon_arr_rel_insert_input = {
  data: Array<platform_coupon_addon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_addon_on_conflict>;
};

/** aggregate avg on columns */
export type platform_coupon_addon_avg_fields = {
  __typename: 'platform_coupon_addon_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_coupon_addon". All fields are combined with a logical 'AND'. */
export type platform_coupon_addon_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_addon_bool_exp>>;
  _not?: InputMaybe<platform_coupon_addon_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_addon_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_addon?: InputMaybe<platform_addon_bool_exp>;
  platform_addon_id?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_addon" */
export enum platform_coupon_addon_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_addon_id", "platform_coupon_id" */
  platform_coupon_addon_pkey = 'platform_coupon_addon_pkey'
}

/** input type for incrementing numeric columns in table "platform_coupon_addon" */
export type platform_coupon_addon_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_coupon_addon" */
export type platform_coupon_addon_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon?: InputMaybe<platform_addon_obj_rel_insert_input>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon?: InputMaybe<platform_coupon_obj_rel_insert_input>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_addon_max_fields = {
  __typename: 'platform_coupon_addon_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_coupon_addon_min_fields = {
  __typename: 'platform_coupon_addon_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_coupon_addon" */
export type platform_coupon_addon_mutation_response = {
  __typename: 'platform_coupon_addon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_addon>;
};

/** on_conflict condition type for table "platform_coupon_addon" */
export type platform_coupon_addon_on_conflict = {
  constraint: platform_coupon_addon_constraint;
  update_columns?: Array<platform_coupon_addon_update_column>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_addon". */
export type platform_coupon_addon_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon?: InputMaybe<platform_addon_order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon?: InputMaybe<platform_coupon_order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_addon */
export type platform_coupon_addon_pk_columns_input = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_addon" */
export enum platform_coupon_addon_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id'
}

/** input type for updating data in table "platform_coupon_addon" */
export type platform_coupon_addon_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_coupon_addon_stddev_fields = {
  __typename: 'platform_coupon_addon_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_addon_stddev_pop_fields = {
  __typename: 'platform_coupon_addon_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_addon_stddev_samp_fields = {
  __typename: 'platform_coupon_addon_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_coupon_addon" */
export type platform_coupon_addon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_addon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_addon_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_addon_sum_fields = {
  __typename: 'platform_coupon_addon_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_coupon_addon" */
export enum platform_coupon_addon_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id'
}

export type platform_coupon_addon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_coupon_addon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_addon_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_addon_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_coupon_addon_var_pop_fields = {
  __typename: 'platform_coupon_addon_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_coupon_addon_var_samp_fields = {
  __typename: 'platform_coupon_addon_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_coupon_addon_variance_fields = {
  __typename: 'platform_coupon_addon_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_coupon_addon" */
export type platform_coupon_addon_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregated selection of "platform_coupon" */
export type platform_coupon_aggregate = {
  __typename: 'platform_coupon_aggregate';
  aggregate?: Maybe<platform_coupon_aggregate_fields>;
  nodes: Array<platform_coupon>;
};

export type platform_coupon_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_coupon_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_coupon_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_coupon_aggregate_bool_exp_count>;
};

export type platform_coupon_aggregate_bool_exp_bool_and = {
  arguments: platform_coupon_select_column_platform_coupon_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_coupon_aggregate_bool_exp_bool_or = {
  arguments: platform_coupon_select_column_platform_coupon_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_coupon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_coupon" */
export type platform_coupon_aggregate_fields = {
  __typename: 'platform_coupon_aggregate_fields';
  avg?: Maybe<platform_coupon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_max_fields>;
  min?: Maybe<platform_coupon_min_fields>;
  stddev?: Maybe<platform_coupon_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_sum_fields>;
  var_pop?: Maybe<platform_coupon_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_var_samp_fields>;
  variance?: Maybe<platform_coupon_variance_fields>;
};


/** aggregate fields of "platform_coupon" */
export type platform_coupon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_coupon" */
export type platform_coupon_aggregate_order_by = {
  avg?: InputMaybe<platform_coupon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_coupon_max_order_by>;
  min?: InputMaybe<platform_coupon_min_order_by>;
  stddev?: InputMaybe<platform_coupon_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_coupon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_coupon_stddev_samp_order_by>;
  sum?: InputMaybe<platform_coupon_sum_order_by>;
  var_pop?: InputMaybe<platform_coupon_var_pop_order_by>;
  var_samp?: InputMaybe<platform_coupon_var_samp_order_by>;
  variance?: InputMaybe<platform_coupon_variance_order_by>;
};

/** columns and relationships of "platform_coupon_apply_to" */
export type platform_coupon_apply_to = {
  __typename: 'platform_coupon_apply_to';
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_coupon_apply_to" */
export type platform_coupon_apply_toplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_apply_to" */
export type platform_coupon_apply_toplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** aggregated selection of "platform_coupon_apply_to" */
export type platform_coupon_apply_to_aggregate = {
  __typename: 'platform_coupon_apply_to_aggregate';
  aggregate?: Maybe<platform_coupon_apply_to_aggregate_fields>;
  nodes: Array<platform_coupon_apply_to>;
};

/** aggregate fields of "platform_coupon_apply_to" */
export type platform_coupon_apply_to_aggregate_fields = {
  __typename: 'platform_coupon_apply_to_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_apply_to_max_fields>;
  min?: Maybe<platform_coupon_apply_to_min_fields>;
};


/** aggregate fields of "platform_coupon_apply_to" */
export type platform_coupon_apply_to_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_apply_to_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_apply_to". All fields are combined with a logical 'AND'. */
export type platform_coupon_apply_to_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_apply_to_bool_exp>>;
  _not?: InputMaybe<platform_coupon_apply_to_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_apply_to_bool_exp>>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_apply_to" */
export enum platform_coupon_apply_to_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_coupon_apply_to_pkey = 'platform_coupon_apply_to_pkey'
}

export enum platform_coupon_apply_to_enum {
  invoice = 'invoice',
  items = 'items',
  shipping = 'shipping'
}

/** Boolean expression to compare columns of type "platform_coupon_apply_to_enum". All fields are combined with logical 'AND'. */
export type platform_coupon_apply_to_enum_comparison_exp = {
  _eq?: InputMaybe<platform_coupon_apply_to_enum>;
  _in?: InputMaybe<Array<platform_coupon_apply_to_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_coupon_apply_to_enum>;
  _nin?: InputMaybe<Array<platform_coupon_apply_to_enum>>;
};

/** input type for inserting data into table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_insert_input = {
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_apply_to_max_fields = {
  __typename: 'platform_coupon_apply_to_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_apply_to_min_fields = {
  __typename: 'platform_coupon_apply_to_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_mutation_response = {
  __typename: 'platform_coupon_apply_to_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_apply_to>;
};

/** input type for inserting object relation for remote table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_obj_rel_insert_input = {
  data: platform_coupon_apply_to_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_apply_to_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_on_conflict = {
  constraint: platform_coupon_apply_to_constraint;
  update_columns?: Array<platform_coupon_apply_to_update_column>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_apply_to". */
export type platform_coupon_apply_to_order_by = {
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_apply_to */
export type platform_coupon_apply_to_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction = {
  __typename: 'platform_coupon_apply_to_restriction';
  /** An array relationship */
  platformCouponsByApplyToAddons: Array<platform_coupon>;
  /** An aggregate relationship */
  platformCouponsByApplyToAddons_aggregate: platform_coupon_aggregate;
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restrictionplatformCouponsByApplyToAddonsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restrictionplatformCouponsByApplyToAddons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restrictionplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restrictionplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** aggregated selection of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_aggregate = {
  __typename: 'platform_coupon_apply_to_restriction_aggregate';
  aggregate?: Maybe<platform_coupon_apply_to_restriction_aggregate_fields>;
  nodes: Array<platform_coupon_apply_to_restriction>;
};

/** aggregate fields of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_aggregate_fields = {
  __typename: 'platform_coupon_apply_to_restriction_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_apply_to_restriction_max_fields>;
  min?: Maybe<platform_coupon_apply_to_restriction_min_fields>;
};


/** aggregate fields of "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_apply_to_restriction_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_apply_to_restriction". All fields are combined with a logical 'AND'. */
export type platform_coupon_apply_to_restriction_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_apply_to_restriction_bool_exp>>;
  _not?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_apply_to_restriction_bool_exp>>;
  platformCouponsByApplyToAddons?: InputMaybe<platform_coupon_bool_exp>;
  platformCouponsByApplyToAddons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_apply_to_restriction" */
export enum platform_coupon_apply_to_restriction_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_coupon_apply_to_constraint_pkey = 'platform_coupon_apply_to_constraint_pkey'
}

export enum platform_coupon_apply_to_restriction_enum {
  all = 'all',
  specific = 'specific'
}

/** Boolean expression to compare columns of type "platform_coupon_apply_to_restriction_enum". All fields are combined with logical 'AND'. */
export type platform_coupon_apply_to_restriction_enum_comparison_exp = {
  _eq?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  _in?: InputMaybe<Array<platform_coupon_apply_to_restriction_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  _nin?: InputMaybe<Array<platform_coupon_apply_to_restriction_enum>>;
};

/** input type for inserting data into table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_insert_input = {
  platformCouponsByApplyToAddons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_apply_to_restriction_max_fields = {
  __typename: 'platform_coupon_apply_to_restriction_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_apply_to_restriction_min_fields = {
  __typename: 'platform_coupon_apply_to_restriction_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_mutation_response = {
  __typename: 'platform_coupon_apply_to_restriction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_apply_to_restriction>;
};

/** input type for inserting object relation for remote table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_obj_rel_insert_input = {
  data: platform_coupon_apply_to_restriction_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_apply_to_restriction_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_on_conflict = {
  constraint: platform_coupon_apply_to_restriction_constraint;
  update_columns?: Array<platform_coupon_apply_to_restriction_update_column>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_apply_to_restriction". */
export type platform_coupon_apply_to_restriction_order_by = {
  platformCouponsByApplyToAddons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_apply_to_restriction */
export type platform_coupon_apply_to_restriction_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_apply_to_restriction" */
export enum platform_coupon_apply_to_restriction_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_coupon_apply_to_restriction" */
export type platform_coupon_apply_to_restriction_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_apply_to_restriction_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_apply_to_restriction_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_coupon_apply_to_restriction" */
export enum platform_coupon_apply_to_restriction_update_column {
  /** column name */
  value = 'value'
}

export type platform_coupon_apply_to_restriction_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_apply_to_restriction_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_apply_to_restriction_bool_exp;
};

/** select columns of table "platform_coupon_apply_to" */
export enum platform_coupon_apply_to_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_coupon_apply_to" */
export type platform_coupon_apply_to_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_apply_to_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_apply_to_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_coupon_apply_to" */
export enum platform_coupon_apply_to_update_column {
  /** column name */
  value = 'value'
}

export type platform_coupon_apply_to_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_apply_to_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_apply_to_bool_exp;
};

/** input type for inserting array relation for remote table "platform_coupon" */
export type platform_coupon_arr_rel_insert_input = {
  data: Array<platform_coupon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_on_conflict>;
};

/** aggregate avg on columns */
export type platform_coupon_avg_fields = {
  __typename: 'platform_coupon_avg_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_coupon" */
export type platform_coupon_avg_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_coupon". All fields are combined with a logical 'AND'. */
export type platform_coupon_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_bool_exp>>;
  _not?: InputMaybe<platform_coupon_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_bool_exp>>;
  amount_off?: InputMaybe<numeric_comparison_exp>;
  apply_to?: InputMaybe<platform_coupon_apply_to_enum_comparison_exp>;
  apply_to_addons?: InputMaybe<platform_coupon_apply_to_restriction_enum_comparison_exp>;
  apply_to_plans?: InputMaybe<platform_coupon_apply_to_restriction_enum_comparison_exp>;
  apply_to_product_groups?: InputMaybe<platform_coupon_apply_to_restriction_enum_comparison_exp>;
  apply_to_products?: InputMaybe<platform_coupon_apply_to_restriction_enum_comparison_exp>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_currency_enum_comparison_exp>;
  duration?: InputMaybe<platform_coupon_duration_enum_comparison_exp>;
  duration_count?: InputMaybe<Int_comparison_exp>;
  duration_interval?: InputMaybe<platform_coupon_duration_interval_enum_comparison_exp>;
  duration_interval_count?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  platformCouponApplyToConstraintByApplyToPlans?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_bool_exp>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_bool_exp>;
  platform_coupon_apply_to?: InputMaybe<platform_coupon_apply_to_bool_exp>;
  platform_coupon_apply_to_constraint?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
  platform_coupon_duration?: InputMaybe<platform_coupon_duration_bool_exp>;
  platform_coupon_duration_interval?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_bool_exp>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_bool_exp>;
  platform_coupon_product_groups?: InputMaybe<platform_coupon_product_group_bool_exp>;
  platform_coupon_product_groups_aggregate?: InputMaybe<platform_coupon_product_group_aggregate_bool_exp>;
  platform_coupon_products?: InputMaybe<platform_coupon_product_bool_exp>;
  platform_coupon_products_aggregate?: InputMaybe<platform_coupon_product_aggregate_bool_exp>;
  platform_coupon_type?: InputMaybe<platform_coupon_type_bool_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  platform_customers?: InputMaybe<platform_customer_bool_exp>;
  platform_customers_aggregate?: InputMaybe<platform_customer_aggregate_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_product_group?: InputMaybe<platform_product_group_bool_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
  platform_subscriptions?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_bool_exp>;
  type?: InputMaybe<platform_coupon_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  valid?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon" */
export enum platform_coupon_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_coupon_pkey = 'platform_coupon_pkey'
}

/** columns and relationships of "platform_coupon_duration" */
export type platform_coupon_duration = {
  __typename: 'platform_coupon_duration';
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_coupon_duration" */
export type platform_coupon_durationplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_duration" */
export type platform_coupon_durationplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** aggregated selection of "platform_coupon_duration" */
export type platform_coupon_duration_aggregate = {
  __typename: 'platform_coupon_duration_aggregate';
  aggregate?: Maybe<platform_coupon_duration_aggregate_fields>;
  nodes: Array<platform_coupon_duration>;
};

/** aggregate fields of "platform_coupon_duration" */
export type platform_coupon_duration_aggregate_fields = {
  __typename: 'platform_coupon_duration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_duration_max_fields>;
  min?: Maybe<platform_coupon_duration_min_fields>;
};


/** aggregate fields of "platform_coupon_duration" */
export type platform_coupon_duration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_duration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_duration". All fields are combined with a logical 'AND'. */
export type platform_coupon_duration_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_duration_bool_exp>>;
  _not?: InputMaybe<platform_coupon_duration_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_duration_bool_exp>>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_duration" */
export enum platform_coupon_duration_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_coupon_duration_pkey = 'platform_coupon_duration_pkey'
}

export enum platform_coupon_duration_enum {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating'
}

/** Boolean expression to compare columns of type "platform_coupon_duration_enum". All fields are combined with logical 'AND'. */
export type platform_coupon_duration_enum_comparison_exp = {
  _eq?: InputMaybe<platform_coupon_duration_enum>;
  _in?: InputMaybe<Array<platform_coupon_duration_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_coupon_duration_enum>;
  _nin?: InputMaybe<Array<platform_coupon_duration_enum>>;
};

/** input type for inserting data into table "platform_coupon_duration" */
export type platform_coupon_duration_insert_input = {
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval = {
  __typename: 'platform_coupon_duration_interval';
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_coupon_duration_interval" */
export type platform_coupon_duration_intervalplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_duration_interval" */
export type platform_coupon_duration_intervalplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** aggregated selection of "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_aggregate = {
  __typename: 'platform_coupon_duration_interval_aggregate';
  aggregate?: Maybe<platform_coupon_duration_interval_aggregate_fields>;
  nodes: Array<platform_coupon_duration_interval>;
};

/** aggregate fields of "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_aggregate_fields = {
  __typename: 'platform_coupon_duration_interval_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_duration_interval_max_fields>;
  min?: Maybe<platform_coupon_duration_interval_min_fields>;
};


/** aggregate fields of "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_duration_interval_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_duration_interval". All fields are combined with a logical 'AND'. */
export type platform_coupon_duration_interval_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_duration_interval_bool_exp>>;
  _not?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_duration_interval_bool_exp>>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_duration_interval" */
export enum platform_coupon_duration_interval_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_coupon_duration_interval_pkey = 'platform_coupon_duration_interval_pkey'
}

export enum platform_coupon_duration_interval_enum {
  billing_cycle = 'billing_cycle',
  day = 'day',
  month = 'month',
  week = 'week',
  year = 'year'
}

/** Boolean expression to compare columns of type "platform_coupon_duration_interval_enum". All fields are combined with logical 'AND'. */
export type platform_coupon_duration_interval_enum_comparison_exp = {
  _eq?: InputMaybe<platform_coupon_duration_interval_enum>;
  _in?: InputMaybe<Array<platform_coupon_duration_interval_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_coupon_duration_interval_enum>;
  _nin?: InputMaybe<Array<platform_coupon_duration_interval_enum>>;
};

/** input type for inserting data into table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_insert_input = {
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_duration_interval_max_fields = {
  __typename: 'platform_coupon_duration_interval_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_duration_interval_min_fields = {
  __typename: 'platform_coupon_duration_interval_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_mutation_response = {
  __typename: 'platform_coupon_duration_interval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_duration_interval>;
};

/** input type for inserting object relation for remote table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_obj_rel_insert_input = {
  data: platform_coupon_duration_interval_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_duration_interval_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_on_conflict = {
  constraint: platform_coupon_duration_interval_constraint;
  update_columns?: Array<platform_coupon_duration_interval_update_column>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_duration_interval". */
export type platform_coupon_duration_interval_order_by = {
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_duration_interval */
export type platform_coupon_duration_interval_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_duration_interval" */
export enum platform_coupon_duration_interval_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_coupon_duration_interval" */
export type platform_coupon_duration_interval_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_duration_interval_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_duration_interval_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_coupon_duration_interval" */
export enum platform_coupon_duration_interval_update_column {
  /** column name */
  value = 'value'
}

export type platform_coupon_duration_interval_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_duration_interval_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_duration_interval_bool_exp;
};

/** aggregate max on columns */
export type platform_coupon_duration_max_fields = {
  __typename: 'platform_coupon_duration_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_duration_min_fields = {
  __typename: 'platform_coupon_duration_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_coupon_duration" */
export type platform_coupon_duration_mutation_response = {
  __typename: 'platform_coupon_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_duration>;
};

/** input type for inserting object relation for remote table "platform_coupon_duration" */
export type platform_coupon_duration_obj_rel_insert_input = {
  data: platform_coupon_duration_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_duration_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon_duration" */
export type platform_coupon_duration_on_conflict = {
  constraint: platform_coupon_duration_constraint;
  update_columns?: Array<platform_coupon_duration_update_column>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_duration". */
export type platform_coupon_duration_order_by = {
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_duration */
export type platform_coupon_duration_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_duration" */
export enum platform_coupon_duration_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_coupon_duration" */
export type platform_coupon_duration_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_coupon_duration" */
export type platform_coupon_duration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_duration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_duration_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_coupon_duration" */
export enum platform_coupon_duration_update_column {
  /** column name */
  value = 'value'
}

export type platform_coupon_duration_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_duration_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_duration_bool_exp;
};

/** input type for incrementing numeric columns in table "platform_coupon" */
export type platform_coupon_inc_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_coupon" */
export type platform_coupon_insert_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<platform_coupon_apply_to_enum>;
  apply_to_addons?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_plans?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_product_groups?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_products?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  duration?: InputMaybe<platform_coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<platform_coupon_duration_interval_enum>;
  duration_interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platformCouponApplyToConstraintByApplyToPlans?: InputMaybe<platform_coupon_apply_to_restriction_obj_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_addons?: InputMaybe<platform_coupon_addon_arr_rel_insert_input>;
  platform_coupon_apply_to?: InputMaybe<platform_coupon_apply_to_obj_rel_insert_input>;
  platform_coupon_apply_to_constraint?: InputMaybe<platform_coupon_apply_to_restriction_obj_rel_insert_input>;
  platform_coupon_duration?: InputMaybe<platform_coupon_duration_obj_rel_insert_input>;
  platform_coupon_duration_interval?: InputMaybe<platform_coupon_duration_interval_obj_rel_insert_input>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_arr_rel_insert_input>;
  platform_coupon_product_groups?: InputMaybe<platform_coupon_product_group_arr_rel_insert_input>;
  platform_coupon_products?: InputMaybe<platform_coupon_product_arr_rel_insert_input>;
  platform_coupon_type?: InputMaybe<platform_coupon_type_obj_rel_insert_input>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  platform_customers?: InputMaybe<platform_customer_arr_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group?: InputMaybe<platform_product_group_obj_rel_insert_input>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscriptions?: InputMaybe<platform_subscription_arr_rel_insert_input>;
  type?: InputMaybe<platform_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_max_fields = {
  __typename: 'platform_coupon_max_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_coupon" */
export type platform_coupon_max_order_by = {
  amount_off?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_coupon_min_fields = {
  __typename: 'platform_coupon_min_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_coupon" */
export type platform_coupon_min_order_by = {
  amount_off?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_coupon" */
export type platform_coupon_mutation_response = {
  __typename: 'platform_coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon>;
};

/** input type for inserting object relation for remote table "platform_coupon" */
export type platform_coupon_obj_rel_insert_input = {
  data: platform_coupon_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon" */
export type platform_coupon_on_conflict = {
  constraint: platform_coupon_constraint;
  update_columns?: Array<platform_coupon_update_column>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon". */
export type platform_coupon_order_by = {
  amount_off?: InputMaybe<order_by>;
  apply_to?: InputMaybe<order_by>;
  apply_to_addons?: InputMaybe<order_by>;
  apply_to_plans?: InputMaybe<order_by>;
  apply_to_product_groups?: InputMaybe<order_by>;
  apply_to_products?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  duration?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platformCouponApplyToConstraintByApplyToPlans?: InputMaybe<platform_coupon_apply_to_restriction_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_addons_aggregate?: InputMaybe<platform_coupon_addon_aggregate_order_by>;
  platform_coupon_apply_to?: InputMaybe<platform_coupon_apply_to_order_by>;
  platform_coupon_apply_to_constraint?: InputMaybe<platform_coupon_apply_to_restriction_order_by>;
  platform_coupon_duration?: InputMaybe<platform_coupon_duration_order_by>;
  platform_coupon_duration_interval?: InputMaybe<platform_coupon_duration_interval_order_by>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_order_by>;
  platform_coupon_product_groups_aggregate?: InputMaybe<platform_coupon_product_group_aggregate_order_by>;
  platform_coupon_products_aggregate?: InputMaybe<platform_coupon_product_aggregate_order_by>;
  platform_coupon_type?: InputMaybe<platform_coupon_type_order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  platform_customers_aggregate?: InputMaybe<platform_customer_aggregate_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group?: InputMaybe<platform_product_group_order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  valid?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon */
export type platform_coupon_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** columns and relationships of "platform_coupon_plan" */
export type platform_coupon_plan = {
  __typename: 'platform_coupon_plan';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_coupon?: Maybe<platform_coupon>;
  platform_coupon_id: Scalars['String']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_coupon_plan" */
export type platform_coupon_plan_aggregate = {
  __typename: 'platform_coupon_plan_aggregate';
  aggregate?: Maybe<platform_coupon_plan_aggregate_fields>;
  nodes: Array<platform_coupon_plan>;
};

export type platform_coupon_plan_aggregate_bool_exp = {
  count?: InputMaybe<platform_coupon_plan_aggregate_bool_exp_count>;
};

export type platform_coupon_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_coupon_plan" */
export type platform_coupon_plan_aggregate_fields = {
  __typename: 'platform_coupon_plan_aggregate_fields';
  avg?: Maybe<platform_coupon_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_plan_max_fields>;
  min?: Maybe<platform_coupon_plan_min_fields>;
  stddev?: Maybe<platform_coupon_plan_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_plan_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_plan_sum_fields>;
  var_pop?: Maybe<platform_coupon_plan_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_plan_var_samp_fields>;
  variance?: Maybe<platform_coupon_plan_variance_fields>;
};


/** aggregate fields of "platform_coupon_plan" */
export type platform_coupon_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_coupon_plan" */
export type platform_coupon_plan_aggregate_order_by = {
  avg?: InputMaybe<platform_coupon_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_coupon_plan_max_order_by>;
  min?: InputMaybe<platform_coupon_plan_min_order_by>;
  stddev?: InputMaybe<platform_coupon_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_coupon_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_coupon_plan_stddev_samp_order_by>;
  sum?: InputMaybe<platform_coupon_plan_sum_order_by>;
  var_pop?: InputMaybe<platform_coupon_plan_var_pop_order_by>;
  var_samp?: InputMaybe<platform_coupon_plan_var_samp_order_by>;
  variance?: InputMaybe<platform_coupon_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_coupon_plan" */
export type platform_coupon_plan_arr_rel_insert_input = {
  data: Array<platform_coupon_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_plan_on_conflict>;
};

/** aggregate avg on columns */
export type platform_coupon_plan_avg_fields = {
  __typename: 'platform_coupon_plan_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_coupon_plan". All fields are combined with a logical 'AND'. */
export type platform_coupon_plan_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_plan_bool_exp>>;
  _not?: InputMaybe<platform_coupon_plan_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_plan_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_plan" */
export enum platform_coupon_plan_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_connection_id", "platform_coupon_id" */
  platform_coupon_plan_pkey = 'platform_coupon_plan_pkey'
}

/** input type for incrementing numeric columns in table "platform_coupon_plan" */
export type platform_coupon_plan_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_coupon_plan" */
export type platform_coupon_plan_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon?: InputMaybe<platform_coupon_obj_rel_insert_input>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_plan_max_fields = {
  __typename: 'platform_coupon_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_coupon_plan_min_fields = {
  __typename: 'platform_coupon_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_coupon_plan" */
export type platform_coupon_plan_mutation_response = {
  __typename: 'platform_coupon_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_plan>;
};

/** on_conflict condition type for table "platform_coupon_plan" */
export type platform_coupon_plan_on_conflict = {
  constraint: platform_coupon_plan_constraint;
  update_columns?: Array<platform_coupon_plan_update_column>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_plan". */
export type platform_coupon_plan_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon?: InputMaybe<platform_coupon_order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_plan */
export type platform_coupon_plan_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_plan" */
export enum platform_coupon_plan_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

/** input type for updating data in table "platform_coupon_plan" */
export type platform_coupon_plan_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_coupon_plan_stddev_fields = {
  __typename: 'platform_coupon_plan_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_plan_stddev_pop_fields = {
  __typename: 'platform_coupon_plan_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_plan_stddev_samp_fields = {
  __typename: 'platform_coupon_plan_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_coupon_plan" */
export type platform_coupon_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_plan_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_plan_sum_fields = {
  __typename: 'platform_coupon_plan_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_coupon_plan" */
export enum platform_coupon_plan_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_plan_id = 'platform_plan_id'
}

export type platform_coupon_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_coupon_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_plan_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_coupon_plan_var_pop_fields = {
  __typename: 'platform_coupon_plan_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_coupon_plan_var_samp_fields = {
  __typename: 'platform_coupon_plan_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_coupon_plan_variance_fields = {
  __typename: 'platform_coupon_plan_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_coupon_plan" */
export type platform_coupon_plan_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_coupon_product" */
export type platform_coupon_product = {
  __typename: 'platform_coupon_product';
  created_at: Scalars['timestamptz']['output'];
  platform_connection_id: Scalars['Int']['output'];
  platform_coupon_id: Scalars['String']['output'];
  platform_product_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_coupon_product" */
export type platform_coupon_product_aggregate = {
  __typename: 'platform_coupon_product_aggregate';
  aggregate?: Maybe<platform_coupon_product_aggregate_fields>;
  nodes: Array<platform_coupon_product>;
};

export type platform_coupon_product_aggregate_bool_exp = {
  count?: InputMaybe<platform_coupon_product_aggregate_bool_exp_count>;
};

export type platform_coupon_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_coupon_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_coupon_product" */
export type platform_coupon_product_aggregate_fields = {
  __typename: 'platform_coupon_product_aggregate_fields';
  avg?: Maybe<platform_coupon_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_product_max_fields>;
  min?: Maybe<platform_coupon_product_min_fields>;
  stddev?: Maybe<platform_coupon_product_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_product_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_product_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_product_sum_fields>;
  var_pop?: Maybe<platform_coupon_product_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_product_var_samp_fields>;
  variance?: Maybe<platform_coupon_product_variance_fields>;
};


/** aggregate fields of "platform_coupon_product" */
export type platform_coupon_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_coupon_product" */
export type platform_coupon_product_aggregate_order_by = {
  avg?: InputMaybe<platform_coupon_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_coupon_product_max_order_by>;
  min?: InputMaybe<platform_coupon_product_min_order_by>;
  stddev?: InputMaybe<platform_coupon_product_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_coupon_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_coupon_product_stddev_samp_order_by>;
  sum?: InputMaybe<platform_coupon_product_sum_order_by>;
  var_pop?: InputMaybe<platform_coupon_product_var_pop_order_by>;
  var_samp?: InputMaybe<platform_coupon_product_var_samp_order_by>;
  variance?: InputMaybe<platform_coupon_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_coupon_product" */
export type platform_coupon_product_arr_rel_insert_input = {
  data: Array<platform_coupon_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_product_on_conflict>;
};

/** aggregate avg on columns */
export type platform_coupon_product_avg_fields = {
  __typename: 'platform_coupon_product_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_coupon_product" */
export type platform_coupon_product_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_coupon_product". All fields are combined with a logical 'AND'. */
export type platform_coupon_product_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_product_bool_exp>>;
  _not?: InputMaybe<platform_coupon_product_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_product_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
  platform_product_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_product" */
export enum platform_coupon_product_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_product_id", "platform_coupon_id" */
  platform_coupon_product_pkey = 'platform_coupon_product_pkey'
}

/** columns and relationships of "platform_coupon_product_group" */
export type platform_coupon_product_group = {
  __typename: 'platform_coupon_product_group';
  created_at: Scalars['timestamptz']['output'];
  platform_connection_id: Scalars['Int']['output'];
  platform_coupon_id: Scalars['String']['output'];
  platform_product_group_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_coupon_product_group" */
export type platform_coupon_product_group_aggregate = {
  __typename: 'platform_coupon_product_group_aggregate';
  aggregate?: Maybe<platform_coupon_product_group_aggregate_fields>;
  nodes: Array<platform_coupon_product_group>;
};

export type platform_coupon_product_group_aggregate_bool_exp = {
  count?: InputMaybe<platform_coupon_product_group_aggregate_bool_exp_count>;
};

export type platform_coupon_product_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_coupon_product_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_coupon_product_group" */
export type platform_coupon_product_group_aggregate_fields = {
  __typename: 'platform_coupon_product_group_aggregate_fields';
  avg?: Maybe<platform_coupon_product_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_product_group_max_fields>;
  min?: Maybe<platform_coupon_product_group_min_fields>;
  stddev?: Maybe<platform_coupon_product_group_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_product_group_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_product_group_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_product_group_sum_fields>;
  var_pop?: Maybe<platform_coupon_product_group_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_product_group_var_samp_fields>;
  variance?: Maybe<platform_coupon_product_group_variance_fields>;
};


/** aggregate fields of "platform_coupon_product_group" */
export type platform_coupon_product_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_coupon_product_group" */
export type platform_coupon_product_group_aggregate_order_by = {
  avg?: InputMaybe<platform_coupon_product_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_coupon_product_group_max_order_by>;
  min?: InputMaybe<platform_coupon_product_group_min_order_by>;
  stddev?: InputMaybe<platform_coupon_product_group_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_coupon_product_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_coupon_product_group_stddev_samp_order_by>;
  sum?: InputMaybe<platform_coupon_product_group_sum_order_by>;
  var_pop?: InputMaybe<platform_coupon_product_group_var_pop_order_by>;
  var_samp?: InputMaybe<platform_coupon_product_group_var_samp_order_by>;
  variance?: InputMaybe<platform_coupon_product_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_coupon_product_group" */
export type platform_coupon_product_group_arr_rel_insert_input = {
  data: Array<platform_coupon_product_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_product_group_on_conflict>;
};

/** aggregate avg on columns */
export type platform_coupon_product_group_avg_fields = {
  __typename: 'platform_coupon_product_group_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_coupon_product_group". All fields are combined with a logical 'AND'. */
export type platform_coupon_product_group_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_product_group_bool_exp>>;
  _not?: InputMaybe<platform_coupon_product_group_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_product_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_product_group" */
export enum platform_coupon_product_group_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_product_group_id", "platform_coupon_id" */
  platform_coupon_product_group_pkey = 'platform_coupon_product_group_pkey'
}

/** input type for incrementing numeric columns in table "platform_coupon_product_group" */
export type platform_coupon_product_group_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_coupon_product_group" */
export type platform_coupon_product_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_product_group_max_fields = {
  __typename: 'platform_coupon_product_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_coupon_product_group_min_fields = {
  __typename: 'platform_coupon_product_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_coupon_product_group" */
export type platform_coupon_product_group_mutation_response = {
  __typename: 'platform_coupon_product_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_product_group>;
};

/** on_conflict condition type for table "platform_coupon_product_group" */
export type platform_coupon_product_group_on_conflict = {
  constraint: platform_coupon_product_group_constraint;
  update_columns?: Array<platform_coupon_product_group_update_column>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_product_group". */
export type platform_coupon_product_group_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_product_group */
export type platform_coupon_product_group_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_group_id: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_product_group" */
export enum platform_coupon_product_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id'
}

/** input type for updating data in table "platform_coupon_product_group" */
export type platform_coupon_product_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_coupon_product_group_stddev_fields = {
  __typename: 'platform_coupon_product_group_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_product_group_stddev_pop_fields = {
  __typename: 'platform_coupon_product_group_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_product_group_stddev_samp_fields = {
  __typename: 'platform_coupon_product_group_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_coupon_product_group" */
export type platform_coupon_product_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_product_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_product_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_product_group_sum_fields = {
  __typename: 'platform_coupon_product_group_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_coupon_product_group" */
export enum platform_coupon_product_group_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id'
}

export type platform_coupon_product_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_coupon_product_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_product_group_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_product_group_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_coupon_product_group_var_pop_fields = {
  __typename: 'platform_coupon_product_group_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_coupon_product_group_var_samp_fields = {
  __typename: 'platform_coupon_product_group_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_coupon_product_group_variance_fields = {
  __typename: 'platform_coupon_product_group_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_coupon_product_group" */
export type platform_coupon_product_group_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "platform_coupon_product" */
export type platform_coupon_product_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_coupon_product" */
export type platform_coupon_product_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_product_max_fields = {
  __typename: 'platform_coupon_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_coupon_product" */
export type platform_coupon_product_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_coupon_product_min_fields = {
  __typename: 'platform_coupon_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_product_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_coupon_product" */
export type platform_coupon_product_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_coupon_product" */
export type platform_coupon_product_mutation_response = {
  __typename: 'platform_coupon_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_product>;
};

/** on_conflict condition type for table "platform_coupon_product" */
export type platform_coupon_product_on_conflict = {
  constraint: platform_coupon_product_constraint;
  update_columns?: Array<platform_coupon_product_update_column>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_product". */
export type platform_coupon_product_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_product_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_product */
export type platform_coupon_product_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_id: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_product" */
export enum platform_coupon_product_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_product_id = 'platform_product_id'
}

/** input type for updating data in table "platform_coupon_product" */
export type platform_coupon_product_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_coupon_product_stddev_fields = {
  __typename: 'platform_coupon_product_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_coupon_product" */
export type platform_coupon_product_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_product_stddev_pop_fields = {
  __typename: 'platform_coupon_product_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_coupon_product" */
export type platform_coupon_product_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_product_stddev_samp_fields = {
  __typename: 'platform_coupon_product_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_coupon_product" */
export type platform_coupon_product_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_coupon_product" */
export type platform_coupon_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_product_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_product_sum_fields = {
  __typename: 'platform_coupon_product_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_coupon_product" */
export type platform_coupon_product_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_coupon_product" */
export enum platform_coupon_product_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_product_id = 'platform_product_id'
}

export type platform_coupon_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_coupon_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_product_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_product_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_coupon_product_var_pop_fields = {
  __typename: 'platform_coupon_product_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_coupon_product" */
export type platform_coupon_product_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_coupon_product_var_samp_fields = {
  __typename: 'platform_coupon_product_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_coupon_product" */
export type platform_coupon_product_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_coupon_product_variance_fields = {
  __typename: 'platform_coupon_product_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_coupon_product" */
export type platform_coupon_product_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** select columns of table "platform_coupon" */
export enum platform_coupon_select_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  apply_to = 'apply_to',
  /** column name */
  apply_to_addons = 'apply_to_addons',
  /** column name */
  apply_to_plans = 'apply_to_plans',
  /** column name */
  apply_to_product_groups = 'apply_to_product_groups',
  /** column name */
  apply_to_products = 'apply_to_products',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  duration_interval_count = 'duration_interval_count',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid = 'valid'
}

/** select "platform_coupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_coupon" */
export enum platform_coupon_select_column_platform_coupon_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  valid = 'valid'
}

/** select "platform_coupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_coupon" */
export enum platform_coupon_select_column_platform_coupon_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  valid = 'valid'
}

/** input type for updating data in table "platform_coupon" */
export type platform_coupon_set_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<platform_coupon_apply_to_enum>;
  apply_to_addons?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_plans?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_product_groups?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_products?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  duration?: InputMaybe<platform_coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<platform_coupon_duration_interval_enum>;
  duration_interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<platform_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type platform_coupon_stddev_fields = {
  __typename: 'platform_coupon_stddev_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_coupon" */
export type platform_coupon_stddev_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_stddev_pop_fields = {
  __typename: 'platform_coupon_stddev_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_coupon" */
export type platform_coupon_stddev_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_stddev_samp_fields = {
  __typename: 'platform_coupon_stddev_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_coupon" */
export type platform_coupon_stddev_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_coupon" */
export type platform_coupon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_stream_cursor_value_input = {
  amount_off?: InputMaybe<Scalars['numeric']['input']>;
  apply_to?: InputMaybe<platform_coupon_apply_to_enum>;
  apply_to_addons?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_plans?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_product_groups?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  apply_to_products?: InputMaybe<platform_coupon_apply_to_restriction_enum>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  duration?: InputMaybe<platform_coupon_duration_enum>;
  duration_count?: InputMaybe<Scalars['Int']['input']>;
  duration_interval?: InputMaybe<platform_coupon_duration_interval_enum>;
  duration_interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<platform_coupon_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_sum_fields = {
  __typename: 'platform_coupon_sum_fields';
  amount_off?: Maybe<Scalars['numeric']['output']>;
  duration_count?: Maybe<Scalars['Int']['output']>;
  duration_interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_coupon" */
export type platform_coupon_sum_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_coupon_tsvector" */
export type platform_coupon_tsvector = {
  __typename: 'platform_coupon_tsvector';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "platform_coupon_tsvector" */
export type platform_coupon_tsvector_aggregate = {
  __typename: 'platform_coupon_tsvector_aggregate';
  aggregate?: Maybe<platform_coupon_tsvector_aggregate_fields>;
  nodes: Array<platform_coupon_tsvector>;
};

/** aggregate fields of "platform_coupon_tsvector" */
export type platform_coupon_tsvector_aggregate_fields = {
  __typename: 'platform_coupon_tsvector_aggregate_fields';
  avg?: Maybe<platform_coupon_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_tsvector_max_fields>;
  min?: Maybe<platform_coupon_tsvector_min_fields>;
  stddev?: Maybe<platform_coupon_tsvector_stddev_fields>;
  stddev_pop?: Maybe<platform_coupon_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_coupon_tsvector_stddev_samp_fields>;
  sum?: Maybe<platform_coupon_tsvector_sum_fields>;
  var_pop?: Maybe<platform_coupon_tsvector_var_pop_fields>;
  var_samp?: Maybe<platform_coupon_tsvector_var_samp_fields>;
  variance?: Maybe<platform_coupon_tsvector_variance_fields>;
};


/** aggregate fields of "platform_coupon_tsvector" */
export type platform_coupon_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_coupon_tsvector_avg_fields = {
  __typename: 'platform_coupon_tsvector_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_tsvector". All fields are combined with a logical 'AND'. */
export type platform_coupon_tsvector_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_tsvector_bool_exp>>;
  _not?: InputMaybe<platform_coupon_tsvector_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_tsvector_bool_exp>>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type platform_coupon_tsvector_max_fields = {
  __typename: 'platform_coupon_tsvector_max_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_tsvector_min_fields = {
  __typename: 'platform_coupon_tsvector_min_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "platform_coupon_tsvector". */
export type platform_coupon_tsvector_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "platform_coupon_tsvector" */
export enum platform_coupon_tsvector_select_column {
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type platform_coupon_tsvector_stddev_fields = {
  __typename: 'platform_coupon_tsvector_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_coupon_tsvector_stddev_pop_fields = {
  __typename: 'platform_coupon_tsvector_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_coupon_tsvector_stddev_samp_fields = {
  __typename: 'platform_coupon_tsvector_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_coupon_tsvector" */
export type platform_coupon_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_tsvector_stream_cursor_value_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type platform_coupon_tsvector_sum_fields = {
  __typename: 'platform_coupon_tsvector_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type platform_coupon_tsvector_var_pop_fields = {
  __typename: 'platform_coupon_tsvector_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_coupon_tsvector_var_samp_fields = {
  __typename: 'platform_coupon_tsvector_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_coupon_tsvector_variance_fields = {
  __typename: 'platform_coupon_tsvector_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_coupon_type" */
export type platform_coupon_type = {
  __typename: 'platform_coupon_type';
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_coupon_type" */
export type platform_coupon_typeplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_coupon_type" */
export type platform_coupon_typeplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};

/** aggregated selection of "platform_coupon_type" */
export type platform_coupon_type_aggregate = {
  __typename: 'platform_coupon_type_aggregate';
  aggregate?: Maybe<platform_coupon_type_aggregate_fields>;
  nodes: Array<platform_coupon_type>;
};

/** aggregate fields of "platform_coupon_type" */
export type platform_coupon_type_aggregate_fields = {
  __typename: 'platform_coupon_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_coupon_type_max_fields>;
  min?: Maybe<platform_coupon_type_min_fields>;
};


/** aggregate fields of "platform_coupon_type" */
export type platform_coupon_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_coupon_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_coupon_type". All fields are combined with a logical 'AND'. */
export type platform_coupon_type_bool_exp = {
  _and?: InputMaybe<Array<platform_coupon_type_bool_exp>>;
  _not?: InputMaybe<platform_coupon_type_bool_exp>;
  _or?: InputMaybe<Array<platform_coupon_type_bool_exp>>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_coupon_type" */
export enum platform_coupon_type_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_coupon_type_pkey = 'platform_coupon_type_pkey'
}

export enum platform_coupon_type_enum {
  fixed = 'fixed',
  percentage = 'percentage'
}

/** Boolean expression to compare columns of type "platform_coupon_type_enum". All fields are combined with logical 'AND'. */
export type platform_coupon_type_enum_comparison_exp = {
  _eq?: InputMaybe<platform_coupon_type_enum>;
  _in?: InputMaybe<Array<platform_coupon_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_coupon_type_enum>;
  _nin?: InputMaybe<Array<platform_coupon_type_enum>>;
};

/** input type for inserting data into table "platform_coupon_type" */
export type platform_coupon_type_insert_input = {
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_coupon_type_max_fields = {
  __typename: 'platform_coupon_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_coupon_type_min_fields = {
  __typename: 'platform_coupon_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_coupon_type" */
export type platform_coupon_type_mutation_response = {
  __typename: 'platform_coupon_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_coupon_type>;
};

/** input type for inserting object relation for remote table "platform_coupon_type" */
export type platform_coupon_type_obj_rel_insert_input = {
  data: platform_coupon_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_coupon_type_on_conflict>;
};

/** on_conflict condition type for table "platform_coupon_type" */
export type platform_coupon_type_on_conflict = {
  constraint: platform_coupon_type_constraint;
  update_columns?: Array<platform_coupon_type_update_column>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};

/** Ordering options when selecting data from "platform_coupon_type". */
export type platform_coupon_type_order_by = {
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_coupon_type */
export type platform_coupon_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_coupon_type" */
export enum platform_coupon_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_coupon_type" */
export type platform_coupon_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_coupon_type" */
export type platform_coupon_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_coupon_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_coupon_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_coupon_type" */
export enum platform_coupon_type_update_column {
  /** column name */
  value = 'value'
}

export type platform_coupon_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_type_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_type_bool_exp;
};

/** update columns of table "platform_coupon" */
export enum platform_coupon_update_column {
  /** column name */
  amount_off = 'amount_off',
  /** column name */
  apply_to = 'apply_to',
  /** column name */
  apply_to_addons = 'apply_to_addons',
  /** column name */
  apply_to_plans = 'apply_to_plans',
  /** column name */
  apply_to_product_groups = 'apply_to_product_groups',
  /** column name */
  apply_to_products = 'apply_to_products',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  duration = 'duration',
  /** column name */
  duration_count = 'duration_count',
  /** column name */
  duration_interval = 'duration_interval',
  /** column name */
  duration_interval_count = 'duration_interval_count',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid = 'valid'
}

export type platform_coupon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_coupon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_coupon_set_input>;
  /** filter the rows which have to be updated */
  where: platform_coupon_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_coupon_var_pop_fields = {
  __typename: 'platform_coupon_var_pop_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_coupon" */
export type platform_coupon_var_pop_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_coupon_var_samp_fields = {
  __typename: 'platform_coupon_var_samp_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_coupon" */
export type platform_coupon_var_samp_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_coupon_variance_fields = {
  __typename: 'platform_coupon_variance_fields';
  amount_off?: Maybe<Scalars['Float']['output']>;
  duration_count?: Maybe<Scalars['Float']['output']>;
  duration_interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_coupon" */
export type platform_coupon_variance_order_by = {
  amount_off?: InputMaybe<order_by>;
  duration_count?: InputMaybe<order_by>;
  duration_interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_credentials" */
export type platform_credentials = {
  __typename: 'platform_credentials';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  data_text?: Maybe<Scalars['String']['output']>;
  platform: platform_enum;
};


/** columns and relationships of "platform_credentials" */
export type platform_credentialsdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_credentials" */
export type platform_credentials_aggregate = {
  __typename: 'platform_credentials_aggregate';
  aggregate?: Maybe<platform_credentials_aggregate_fields>;
  nodes: Array<platform_credentials>;
};

/** aggregate fields of "platform_credentials" */
export type platform_credentials_aggregate_fields = {
  __typename: 'platform_credentials_aggregate_fields';
  avg?: Maybe<platform_credentials_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_credentials_max_fields>;
  min?: Maybe<platform_credentials_min_fields>;
  stddev?: Maybe<platform_credentials_stddev_fields>;
  stddev_pop?: Maybe<platform_credentials_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_credentials_stddev_samp_fields>;
  sum?: Maybe<platform_credentials_sum_fields>;
  var_pop?: Maybe<platform_credentials_var_pop_fields>;
  var_samp?: Maybe<platform_credentials_var_samp_fields>;
  variance?: Maybe<platform_credentials_variance_fields>;
};


/** aggregate fields of "platform_credentials" */
export type platform_credentials_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_credentials_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_credentials_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type platform_credentials_avg_fields = {
  __typename: 'platform_credentials_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_credentials". All fields are combined with a logical 'AND'. */
export type platform_credentials_bool_exp = {
  _and?: InputMaybe<Array<platform_credentials_bool_exp>>;
  _not?: InputMaybe<platform_credentials_bool_exp>;
  _or?: InputMaybe<Array<platform_credentials_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  data_text?: InputMaybe<String_comparison_exp>;
  platform?: InputMaybe<platform_enum_comparison_exp>;
};

/** unique or primary key constraints on table "platform_credentials" */
export enum platform_credentials_constraint {
  /** unique or primary key constraint on columns "account_id", "platform" */
  platform_credentials_pkey = 'platform_credentials_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_credentials_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_credentials_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_credentials_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_credentials" */
export type platform_credentials_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_credentials" */
export type platform_credentials_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<platform_enum>;
};

/** aggregate max on columns */
export type platform_credentials_max_fields = {
  __typename: 'platform_credentials_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  data_text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_credentials_min_fields = {
  __typename: 'platform_credentials_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  data_text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_credentials" */
export type platform_credentials_mutation_response = {
  __typename: 'platform_credentials_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_credentials>;
};

/** on_conflict condition type for table "platform_credentials" */
export type platform_credentials_on_conflict = {
  constraint: platform_credentials_constraint;
  update_columns?: Array<platform_credentials_update_column>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};

/** Ordering options when selecting data from "platform_credentials". */
export type platform_credentials_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  data_text?: InputMaybe<order_by>;
  platform?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_credentials */
export type platform_credentials_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  platform: platform_enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_credentials_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_credentials" */
export enum platform_credentials_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  data_text = 'data_text',
  /** column name */
  platform = 'platform'
}

/** input type for updating data in table "platform_credentials" */
export type platform_credentials_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<platform_enum>;
};

/** aggregate stddev on columns */
export type platform_credentials_stddev_fields = {
  __typename: 'platform_credentials_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_credentials_stddev_pop_fields = {
  __typename: 'platform_credentials_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_credentials_stddev_samp_fields = {
  __typename: 'platform_credentials_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_credentials" */
export type platform_credentials_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_credentials_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_credentials_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_text?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<platform_enum>;
};

/** aggregate sum on columns */
export type platform_credentials_sum_fields = {
  __typename: 'platform_credentials_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_credentials" */
export enum platform_credentials_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  data_text = 'data_text',
  /** column name */
  platform = 'platform'
}

export type platform_credentials_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_credentials_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_credentials_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_credentials_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_credentials_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_credentials_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_credentials_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_credentials_set_input>;
  /** filter the rows which have to be updated */
  where: platform_credentials_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_credentials_var_pop_fields = {
  __typename: 'platform_credentials_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_credentials_var_samp_fields = {
  __typename: 'platform_credentials_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_credentials_variance_fields = {
  __typename: 'platform_credentials_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_currency" */
export type platform_currency = {
  __typename: 'platform_currency';
  /** An array relationship */
  platform_addon_prices: Array<platform_addon_price>;
  /** An aggregate relationship */
  platform_addon_prices_aggregate: platform_addon_price_aggregate;
  /** An array relationship */
  platform_base_currencies: Array<platform_base_currency>;
  /** An aggregate relationship */
  platform_base_currencies_aggregate: platform_base_currency_aggregate;
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  /** An array relationship */
  platform_currency_exchange_rates: Array<platform_currency_exchange_rate>;
  /** An aggregate relationship */
  platform_currency_exchange_rates_aggregate: platform_currency_exchange_rate_aggregate;
  /** An array relationship */
  platform_invoices: Array<platform_invoice>;
  /** An aggregate relationship */
  platform_invoices_aggregate: platform_invoice_aggregate;
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_addon_pricesArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_addon_prices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_base_currenciesArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_base_currencies_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_currency_exchange_ratesArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_currency_exchange_rates_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_invoicesArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_invoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_currency" */
export type platform_currencyplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** aggregated selection of "platform_currency" */
export type platform_currency_aggregate = {
  __typename: 'platform_currency_aggregate';
  aggregate?: Maybe<platform_currency_aggregate_fields>;
  nodes: Array<platform_currency>;
};

/** aggregate fields of "platform_currency" */
export type platform_currency_aggregate_fields = {
  __typename: 'platform_currency_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_currency_max_fields>;
  min?: Maybe<platform_currency_min_fields>;
};


/** aggregate fields of "platform_currency" */
export type platform_currency_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_currency_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_currency". All fields are combined with a logical 'AND'. */
export type platform_currency_bool_exp = {
  _and?: InputMaybe<Array<platform_currency_bool_exp>>;
  _not?: InputMaybe<platform_currency_bool_exp>;
  _or?: InputMaybe<Array<platform_currency_bool_exp>>;
  platform_addon_prices?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_bool_exp>;
  platform_base_currencies?: InputMaybe<platform_base_currency_bool_exp>;
  platform_base_currencies_aggregate?: InputMaybe<platform_base_currency_aggregate_bool_exp>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  platform_currency_exchange_rates?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
  platform_currency_exchange_rates_aggregate?: InputMaybe<platform_currency_exchange_rate_aggregate_bool_exp>;
  platform_invoices?: InputMaybe<platform_invoice_bool_exp>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_bool_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_currency" */
export enum platform_currency_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_currency_pkey = 'platform_currency_pkey'
}

export enum platform_currency_enum {
  aed = 'aed',
  afn = 'afn',
  all = 'all',
  amd = 'amd',
  ang = 'ang',
  aoa = 'aoa',
  ars = 'ars',
  aud = 'aud',
  awg = 'awg',
  azn = 'azn',
  bam = 'bam',
  bbd = 'bbd',
  bdt = 'bdt',
  bgn = 'bgn',
  bhd = 'bhd',
  bif = 'bif',
  bmd = 'bmd',
  bnd = 'bnd',
  bob = 'bob',
  brl = 'brl',
  bsd = 'bsd',
  btc = 'btc',
  btn = 'btn',
  bwp = 'bwp',
  byn = 'byn',
  bzd = 'bzd',
  cad = 'cad',
  cdf = 'cdf',
  chf = 'chf',
  clf = 'clf',
  clp = 'clp',
  cnh = 'cnh',
  cny = 'cny',
  cop = 'cop',
  crc = 'crc',
  cuc = 'cuc',
  cup = 'cup',
  cve = 'cve',
  czk = 'czk',
  djf = 'djf',
  dkk = 'dkk',
  dop = 'dop',
  dzd = 'dzd',
  egp = 'egp',
  ern = 'ern',
  etb = 'etb',
  eur = 'eur',
  fjd = 'fjd',
  fkp = 'fkp',
  gbp = 'gbp',
  gel = 'gel',
  ggp = 'ggp',
  ghs = 'ghs',
  gip = 'gip',
  gmd = 'gmd',
  gnf = 'gnf',
  gtq = 'gtq',
  gyd = 'gyd',
  hkd = 'hkd',
  hnl = 'hnl',
  hrk = 'hrk',
  htg = 'htg',
  huf = 'huf',
  idr = 'idr',
  ils = 'ils',
  imp = 'imp',
  inr = 'inr',
  iqd = 'iqd',
  irr = 'irr',
  isk = 'isk',
  jep = 'jep',
  jmd = 'jmd',
  jod = 'jod',
  jpy = 'jpy',
  kes = 'kes',
  kgs = 'kgs',
  khr = 'khr',
  kmf = 'kmf',
  kpw = 'kpw',
  krw = 'krw',
  kwd = 'kwd',
  kyd = 'kyd',
  kzt = 'kzt',
  lak = 'lak',
  lbp = 'lbp',
  lkr = 'lkr',
  lrd = 'lrd',
  lsl = 'lsl',
  lyd = 'lyd',
  mad = 'mad',
  mdl = 'mdl',
  mga = 'mga',
  mkd = 'mkd',
  mmk = 'mmk',
  mnt = 'mnt',
  mop = 'mop',
  mro = 'mro',
  mru = 'mru',
  mur = 'mur',
  mvr = 'mvr',
  mwk = 'mwk',
  mxn = 'mxn',
  myr = 'myr',
  mzn = 'mzn',
  nad = 'nad',
  ngn = 'ngn',
  nio = 'nio',
  nok = 'nok',
  npr = 'npr',
  nzd = 'nzd',
  omr = 'omr',
  pab = 'pab',
  pen = 'pen',
  pgk = 'pgk',
  php = 'php',
  pkr = 'pkr',
  pln = 'pln',
  pyg = 'pyg',
  qar = 'qar',
  ron = 'ron',
  rsd = 'rsd',
  rub = 'rub',
  rwf = 'rwf',
  sar = 'sar',
  sbd = 'sbd',
  scr = 'scr',
  sdg = 'sdg',
  sek = 'sek',
  sgd = 'sgd',
  shp = 'shp',
  sll = 'sll',
  sos = 'sos',
  srd = 'srd',
  ssp = 'ssp',
  std = 'std',
  stn = 'stn',
  svc = 'svc',
  syp = 'syp',
  szl = 'szl',
  thb = 'thb',
  tjs = 'tjs',
  tmt = 'tmt',
  tnd = 'tnd',
  top = 'top',
  try = 'try',
  ttd = 'ttd',
  twd = 'twd',
  tzs = 'tzs',
  uah = 'uah',
  ugx = 'ugx',
  usd = 'usd',
  uyu = 'uyu',
  uzs = 'uzs',
  vef = 'vef',
  ves = 'ves',
  vnd = 'vnd',
  vuv = 'vuv',
  wst = 'wst',
  xaf = 'xaf',
  xag = 'xag',
  xau = 'xau',
  xcd = 'xcd',
  xdr = 'xdr',
  xof = 'xof',
  xpd = 'xpd',
  xpf = 'xpf',
  xpt = 'xpt',
  yer = 'yer',
  zar = 'zar',
  zmw = 'zmw',
  zwl = 'zwl'
}

/** Boolean expression to compare columns of type "platform_currency_enum". All fields are combined with logical 'AND'. */
export type platform_currency_enum_comparison_exp = {
  _eq?: InputMaybe<platform_currency_enum>;
  _in?: InputMaybe<Array<platform_currency_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_currency_enum>;
  _nin?: InputMaybe<Array<platform_currency_enum>>;
};

/** columns and relationships of "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate = {
  __typename: 'platform_currency_exchange_rate';
  base: platform_base_currency_enum;
  created_at: Scalars['timestamptz']['output'];
  currency: platform_currency_enum;
  /** An object relationship */
  platform_base_currency: platform_base_currency;
  /** An object relationship */
  platform_currency: platform_currency;
  rate: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_aggregate = {
  __typename: 'platform_currency_exchange_rate_aggregate';
  aggregate?: Maybe<platform_currency_exchange_rate_aggregate_fields>;
  nodes: Array<platform_currency_exchange_rate>;
};

export type platform_currency_exchange_rate_aggregate_bool_exp = {
  count?: InputMaybe<platform_currency_exchange_rate_aggregate_bool_exp_count>;
};

export type platform_currency_exchange_rate_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_aggregate_fields = {
  __typename: 'platform_currency_exchange_rate_aggregate_fields';
  avg?: Maybe<platform_currency_exchange_rate_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_currency_exchange_rate_max_fields>;
  min?: Maybe<platform_currency_exchange_rate_min_fields>;
  stddev?: Maybe<platform_currency_exchange_rate_stddev_fields>;
  stddev_pop?: Maybe<platform_currency_exchange_rate_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_currency_exchange_rate_stddev_samp_fields>;
  sum?: Maybe<platform_currency_exchange_rate_sum_fields>;
  var_pop?: Maybe<platform_currency_exchange_rate_var_pop_fields>;
  var_samp?: Maybe<platform_currency_exchange_rate_var_samp_fields>;
  variance?: Maybe<platform_currency_exchange_rate_variance_fields>;
};


/** aggregate fields of "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_aggregate_order_by = {
  avg?: InputMaybe<platform_currency_exchange_rate_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_currency_exchange_rate_max_order_by>;
  min?: InputMaybe<platform_currency_exchange_rate_min_order_by>;
  stddev?: InputMaybe<platform_currency_exchange_rate_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_currency_exchange_rate_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_currency_exchange_rate_stddev_samp_order_by>;
  sum?: InputMaybe<platform_currency_exchange_rate_sum_order_by>;
  var_pop?: InputMaybe<platform_currency_exchange_rate_var_pop_order_by>;
  var_samp?: InputMaybe<platform_currency_exchange_rate_var_samp_order_by>;
  variance?: InputMaybe<platform_currency_exchange_rate_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_arr_rel_insert_input = {
  data: Array<platform_currency_exchange_rate_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_currency_exchange_rate_on_conflict>;
};

/** aggregate avg on columns */
export type platform_currency_exchange_rate_avg_fields = {
  __typename: 'platform_currency_exchange_rate_avg_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_avg_order_by = {
  rate?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_currency_exchange_rate". All fields are combined with a logical 'AND'. */
export type platform_currency_exchange_rate_bool_exp = {
  _and?: InputMaybe<Array<platform_currency_exchange_rate_bool_exp>>;
  _not?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
  _or?: InputMaybe<Array<platform_currency_exchange_rate_bool_exp>>;
  base?: InputMaybe<platform_base_currency_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_currency_enum_comparison_exp>;
  platform_base_currency?: InputMaybe<platform_base_currency_bool_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  rate?: InputMaybe<numeric_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_currency_exchange_rate" */
export enum platform_currency_exchange_rate_constraint {
  /** unique or primary key constraint on columns "currency", "base" */
  platform_currency_exchange_rate_pkey = 'platform_currency_exchange_rate_pkey'
}

/** input type for incrementing numeric columns in table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_inc_input = {
  rate?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_insert_input = {
  base?: InputMaybe<platform_base_currency_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  platform_base_currency?: InputMaybe<platform_base_currency_obj_rel_insert_input>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_currency_exchange_rate_max_fields = {
  __typename: 'platform_currency_exchange_rate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_max_order_by = {
  created_at?: InputMaybe<order_by>;
  rate?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_currency_exchange_rate_min_fields = {
  __typename: 'platform_currency_exchange_rate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_min_order_by = {
  created_at?: InputMaybe<order_by>;
  rate?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_mutation_response = {
  __typename: 'platform_currency_exchange_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_currency_exchange_rate>;
};

/** on_conflict condition type for table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_on_conflict = {
  constraint: platform_currency_exchange_rate_constraint;
  update_columns?: Array<platform_currency_exchange_rate_update_column>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};

/** Ordering options when selecting data from "platform_currency_exchange_rate". */
export type platform_currency_exchange_rate_order_by = {
  base?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  platform_base_currency?: InputMaybe<platform_base_currency_order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  rate?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_currency_exchange_rate */
export type platform_currency_exchange_rate_pk_columns_input = {
  base: platform_base_currency_enum;
  currency: platform_currency_enum;
};

/** select columns of table "platform_currency_exchange_rate" */
export enum platform_currency_exchange_rate_select_column {
  /** column name */
  base = 'base',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  rate = 'rate',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_set_input = {
  base?: InputMaybe<platform_base_currency_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_currency_exchange_rate_stddev_fields = {
  __typename: 'platform_currency_exchange_rate_stddev_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_stddev_order_by = {
  rate?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_currency_exchange_rate_stddev_pop_fields = {
  __typename: 'platform_currency_exchange_rate_stddev_pop_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_stddev_pop_order_by = {
  rate?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_currency_exchange_rate_stddev_samp_fields = {
  __typename: 'platform_currency_exchange_rate_stddev_samp_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_stddev_samp_order_by = {
  rate?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_currency_exchange_rate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_currency_exchange_rate_stream_cursor_value_input = {
  base?: InputMaybe<platform_base_currency_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_currency_exchange_rate_sum_fields = {
  __typename: 'platform_currency_exchange_rate_sum_fields';
  rate?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_sum_order_by = {
  rate?: InputMaybe<order_by>;
};

/** update columns of table "platform_currency_exchange_rate" */
export enum platform_currency_exchange_rate_update_column {
  /** column name */
  base = 'base',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  rate = 'rate',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_currency_exchange_rate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_currency_exchange_rate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_currency_exchange_rate_set_input>;
  /** filter the rows which have to be updated */
  where: platform_currency_exchange_rate_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_currency_exchange_rate_var_pop_fields = {
  __typename: 'platform_currency_exchange_rate_var_pop_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_var_pop_order_by = {
  rate?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_currency_exchange_rate_var_samp_fields = {
  __typename: 'platform_currency_exchange_rate_var_samp_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_var_samp_order_by = {
  rate?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_currency_exchange_rate_variance_fields = {
  __typename: 'platform_currency_exchange_rate_variance_fields';
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_currency_exchange_rate" */
export type platform_currency_exchange_rate_variance_order_by = {
  rate?: InputMaybe<order_by>;
};

/** input type for inserting data into table "platform_currency" */
export type platform_currency_insert_input = {
  platform_addon_prices?: InputMaybe<platform_addon_price_arr_rel_insert_input>;
  platform_base_currencies?: InputMaybe<platform_base_currency_arr_rel_insert_input>;
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  platform_currency_exchange_rates?: InputMaybe<platform_currency_exchange_rate_arr_rel_insert_input>;
  platform_invoices?: InputMaybe<platform_invoice_arr_rel_insert_input>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_currency_max_fields = {
  __typename: 'platform_currency_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_currency_min_fields = {
  __typename: 'platform_currency_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_currency" */
export type platform_currency_mutation_response = {
  __typename: 'platform_currency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_currency>;
};

/** input type for inserting object relation for remote table "platform_currency" */
export type platform_currency_obj_rel_insert_input = {
  data: platform_currency_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_currency_on_conflict>;
};

/** on_conflict condition type for table "platform_currency" */
export type platform_currency_on_conflict = {
  constraint: platform_currency_constraint;
  update_columns?: Array<platform_currency_update_column>;
  where?: InputMaybe<platform_currency_bool_exp>;
};

/** Ordering options when selecting data from "platform_currency". */
export type platform_currency_order_by = {
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_order_by>;
  platform_base_currencies_aggregate?: InputMaybe<platform_base_currency_aggregate_order_by>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  platform_currency_exchange_rates_aggregate?: InputMaybe<platform_currency_exchange_rate_aggregate_order_by>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_currency */
export type platform_currency_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_currency" */
export enum platform_currency_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_currency" */
export type platform_currency_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_currency" */
export type platform_currency_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_currency_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_currency_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_currency" */
export enum platform_currency_update_column {
  /** column name */
  value = 'value'
}

export type platform_currency_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_currency_set_input>;
  /** filter the rows which have to be updated */
  where: platform_currency_bool_exp;
};

/** columns and relationships of "platform_customer" */
export type platform_customer = {
  __typename: 'platform_customer';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['citext']['output']>;
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  imported: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  past_due?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_coupon?: Maybe<platform_coupon>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_subscriptions: Array<platform_subscription>;
  /** An aggregate relationship */
  platform_subscriptions_aggregate: platform_subscription_aggregate;
  platform_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  state?: Maybe<Scalars['citext']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  tags?: Maybe<Scalars['jsonb']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_customer" */
export type platform_customermetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_customer" */
export type platform_customerplatform_subscriptionsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


/** columns and relationships of "platform_customer" */
export type platform_customerplatform_subscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


/** columns and relationships of "platform_customer" */
export type platform_customertagsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_customer" */
export type platform_customer_aggregate = {
  __typename: 'platform_customer_aggregate';
  aggregate?: Maybe<platform_customer_aggregate_fields>;
  nodes: Array<platform_customer>;
};

export type platform_customer_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_customer_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_customer_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_customer_aggregate_bool_exp_count>;
};

export type platform_customer_aggregate_bool_exp_bool_and = {
  arguments: platform_customer_select_column_platform_customer_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_customer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_customer_aggregate_bool_exp_bool_or = {
  arguments: platform_customer_select_column_platform_customer_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_customer_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_customer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_customer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_customer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_customer" */
export type platform_customer_aggregate_fields = {
  __typename: 'platform_customer_aggregate_fields';
  avg?: Maybe<platform_customer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_customer_max_fields>;
  min?: Maybe<platform_customer_min_fields>;
  stddev?: Maybe<platform_customer_stddev_fields>;
  stddev_pop?: Maybe<platform_customer_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_customer_stddev_samp_fields>;
  sum?: Maybe<platform_customer_sum_fields>;
  var_pop?: Maybe<platform_customer_var_pop_fields>;
  var_samp?: Maybe<platform_customer_var_samp_fields>;
  variance?: Maybe<platform_customer_variance_fields>;
};


/** aggregate fields of "platform_customer" */
export type platform_customer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_customer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_customer" */
export type platform_customer_aggregate_order_by = {
  avg?: InputMaybe<platform_customer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_customer_max_order_by>;
  min?: InputMaybe<platform_customer_min_order_by>;
  stddev?: InputMaybe<platform_customer_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_customer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_customer_stddev_samp_order_by>;
  sum?: InputMaybe<platform_customer_sum_order_by>;
  var_pop?: InputMaybe<platform_customer_var_pop_order_by>;
  var_samp?: InputMaybe<platform_customer_var_samp_order_by>;
  variance?: InputMaybe<platform_customer_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_customer_append_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "platform_customer" */
export type platform_customer_arr_rel_insert_input = {
  data: Array<platform_customer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_customer_on_conflict>;
};

/** aggregate avg on columns */
export type platform_customer_avg_fields = {
  __typename: 'platform_customer_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_customer" */
export type platform_customer_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_customer". All fields are combined with a logical 'AND'. */
export type platform_customer_bool_exp = {
  _and?: InputMaybe<Array<platform_customer_bool_exp>>;
  _not?: InputMaybe<platform_customer_bool_exp>;
  _or?: InputMaybe<Array<platform_customer_bool_exp>>;
  billing_country?: InputMaybe<citext_comparison_exp>;
  billing_state?: InputMaybe<citext_comparison_exp>;
  code?: InputMaybe<String_comparison_exp>;
  country?: InputMaybe<citext_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  imported?: InputMaybe<Boolean_comparison_exp>;
  metadata?: InputMaybe<jsonb_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  past_due?: InputMaybe<Boolean_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
  platform_created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_subscriptions?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_bool_exp>;
  platform_updated_at?: InputMaybe<timestamptz_comparison_exp>;
  shipping_country?: InputMaybe<citext_comparison_exp>;
  shipping_state?: InputMaybe<citext_comparison_exp>;
  state?: InputMaybe<citext_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  tags?: InputMaybe<jsonb_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_customer" */
export enum platform_customer_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_customer_pkey = 'platform_customer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_customer_delete_at_path_input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_customer_delete_elem_input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_customer_delete_key_input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_customer" */
export type platform_customer_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_customer" */
export type platform_customer_insert_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon?: InputMaybe<platform_coupon_obj_rel_insert_input>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscriptions?: InputMaybe<platform_subscription_arr_rel_insert_input>;
  platform_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_customer_max_fields = {
  __typename: 'platform_customer_max_fields';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['citext']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  state?: Maybe<Scalars['citext']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_customer" */
export type platform_customer_max_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_created_at?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_updated_at?: InputMaybe<order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_customer_min_fields = {
  __typename: 'platform_customer_min_fields';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['citext']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  state?: Maybe<Scalars['citext']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_customer" */
export type platform_customer_min_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_created_at?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_updated_at?: InputMaybe<order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_customer" */
export type platform_customer_mutation_response = {
  __typename: 'platform_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_customer>;
};

/** input type for inserting object relation for remote table "platform_customer" */
export type platform_customer_obj_rel_insert_input = {
  data: platform_customer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_customer_on_conflict>;
};

/** on_conflict condition type for table "platform_customer" */
export type platform_customer_on_conflict = {
  constraint: platform_customer_constraint;
  update_columns?: Array<platform_customer_update_column>;
  where?: InputMaybe<platform_customer_bool_exp>;
};

/** Ordering options when selecting data from "platform_customer". */
export type platform_customer_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  imported?: InputMaybe<order_by>;
  metadata?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  past_due?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon?: InputMaybe<platform_coupon_order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_created_at?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_order_by>;
  platform_updated_at?: InputMaybe<order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  tags?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_customer */
export type platform_customer_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_customer_prepend_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_customer" */
export enum platform_customer_select_column {
  /** column name */
  billing_country = 'billing_country',
  /** column name */
  billing_state = 'billing_state',
  /** column name */
  code = 'code',
  /** column name */
  country = 'country',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  imported = 'imported',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  past_due = 'past_due',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_created_at = 'platform_created_at',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_updated_at = 'platform_updated_at',
  /** column name */
  shipping_country = 'shipping_country',
  /** column name */
  shipping_state = 'shipping_state',
  /** column name */
  state = 'state',
  /** column name */
  tags = 'tags',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_customer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_customer" */
export enum platform_customer_select_column_platform_customer_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  imported = 'imported',
  /** column name */
  past_due = 'past_due'
}

/** select "platform_customer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_customer" */
export enum platform_customer_select_column_platform_customer_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  imported = 'imported',
  /** column name */
  past_due = 'past_due'
}

/** input type for updating data in table "platform_customer" */
export type platform_customer_set_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_customer_stddev_fields = {
  __typename: 'platform_customer_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_customer" */
export type platform_customer_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_customer_stddev_pop_fields = {
  __typename: 'platform_customer_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_customer" */
export type platform_customer_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_customer_stddev_samp_fields = {
  __typename: 'platform_customer_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_customer" */
export type platform_customer_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_customer" */
export type platform_customer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_customer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_customer_stream_cursor_value_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_customer_sum_fields = {
  __typename: 'platform_customer_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_customer" */
export type platform_customer_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_customer" */
export enum platform_customer_update_column {
  /** column name */
  billing_country = 'billing_country',
  /** column name */
  billing_state = 'billing_state',
  /** column name */
  code = 'code',
  /** column name */
  country = 'country',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  imported = 'imported',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  past_due = 'past_due',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_created_at = 'platform_created_at',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_updated_at = 'platform_updated_at',
  /** column name */
  shipping_country = 'shipping_country',
  /** column name */
  shipping_state = 'shipping_state',
  /** column name */
  state = 'state',
  /** column name */
  tags = 'tags',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_customer_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_customer_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_customer_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_customer_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_customer_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_customer_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_customer_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_customer_set_input>;
  /** filter the rows which have to be updated */
  where: platform_customer_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_customer_var_pop_fields = {
  __typename: 'platform_customer_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_customer" */
export type platform_customer_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_customer_var_samp_fields = {
  __typename: 'platform_customer_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_customer" */
export type platform_customer_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_customer_variance_fields = {
  __typename: 'platform_customer_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_customer" */
export type platform_customer_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_ecommerce_product" */
export type platform_ecommerce_product = {
  __typename: 'platform_ecommerce_product';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  offer_product_swap_platform_ecommerce_product?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_plan_ecommerce_products: Array<platform_plan_ecommerce_product>;
  /** An aggregate relationship */
  platform_plan_ecommerce_products_aggregate: platform_plan_ecommerce_product_aggregate;
  /** An array relationship */
  platform_variants: Array<platform_variant>;
  /** An aggregate relationship */
  platform_variants_aggregate: platform_variant_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_ecommerce_product" */
export type platform_ecommerce_productplatform_plan_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_ecommerce_product" */
export type platform_ecommerce_productplatform_plan_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_ecommerce_product" */
export type platform_ecommerce_productplatform_variantsArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


/** columns and relationships of "platform_ecommerce_product" */
export type platform_ecommerce_productplatform_variants_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};

/** aggregated selection of "platform_ecommerce_product" */
export type platform_ecommerce_product_aggregate = {
  __typename: 'platform_ecommerce_product_aggregate';
  aggregate?: Maybe<platform_ecommerce_product_aggregate_fields>;
  nodes: Array<platform_ecommerce_product>;
};

export type platform_ecommerce_product_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_ecommerce_product_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_ecommerce_product_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_ecommerce_product_aggregate_bool_exp_count>;
};

export type platform_ecommerce_product_aggregate_bool_exp_bool_and = {
  arguments: platform_ecommerce_product_select_column_platform_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_ecommerce_product_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_ecommerce_product_aggregate_bool_exp_bool_or = {
  arguments: platform_ecommerce_product_select_column_platform_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_ecommerce_product_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_ecommerce_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_ecommerce_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_ecommerce_product" */
export type platform_ecommerce_product_aggregate_fields = {
  __typename: 'platform_ecommerce_product_aggregate_fields';
  avg?: Maybe<platform_ecommerce_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_ecommerce_product_max_fields>;
  min?: Maybe<platform_ecommerce_product_min_fields>;
  stddev?: Maybe<platform_ecommerce_product_stddev_fields>;
  stddev_pop?: Maybe<platform_ecommerce_product_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_ecommerce_product_stddev_samp_fields>;
  sum?: Maybe<platform_ecommerce_product_sum_fields>;
  var_pop?: Maybe<platform_ecommerce_product_var_pop_fields>;
  var_samp?: Maybe<platform_ecommerce_product_var_samp_fields>;
  variance?: Maybe<platform_ecommerce_product_variance_fields>;
};


/** aggregate fields of "platform_ecommerce_product" */
export type platform_ecommerce_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_ecommerce_product" */
export type platform_ecommerce_product_aggregate_order_by = {
  avg?: InputMaybe<platform_ecommerce_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_ecommerce_product_max_order_by>;
  min?: InputMaybe<platform_ecommerce_product_min_order_by>;
  stddev?: InputMaybe<platform_ecommerce_product_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_ecommerce_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_ecommerce_product_stddev_samp_order_by>;
  sum?: InputMaybe<platform_ecommerce_product_sum_order_by>;
  var_pop?: InputMaybe<platform_ecommerce_product_var_pop_order_by>;
  var_samp?: InputMaybe<platform_ecommerce_product_var_samp_order_by>;
  variance?: InputMaybe<platform_ecommerce_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_ecommerce_product" */
export type platform_ecommerce_product_arr_rel_insert_input = {
  data: Array<platform_ecommerce_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_ecommerce_product_on_conflict>;
};

/** aggregate avg on columns */
export type platform_ecommerce_product_avg_fields = {
  __typename: 'platform_ecommerce_product_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_ecommerce_product". All fields are combined with a logical 'AND'. */
export type platform_ecommerce_product_bool_exp = {
  _and?: InputMaybe<Array<platform_ecommerce_product_bool_exp>>;
  _not?: InputMaybe<platform_ecommerce_product_bool_exp>;
  _or?: InputMaybe<Array<platform_ecommerce_product_bool_exp>>;
  active?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  image_url?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plan_ecommerce_products?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  platform_plan_ecommerce_products_aggregate?: InputMaybe<platform_plan_ecommerce_product_aggregate_bool_exp>;
  platform_variants?: InputMaybe<platform_variant_bool_exp>;
  platform_variants_aggregate?: InputMaybe<platform_variant_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_ecommerce_product" */
export enum platform_ecommerce_product_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_ecommerce_product_pkey = 'platform_ecommerce_product_pkey'
}

/** input type for incrementing numeric columns in table "platform_ecommerce_product" */
export type platform_ecommerce_product_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_ecommerce_product" */
export type platform_ecommerce_product_insert_input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_obj_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_ecommerce_products?: InputMaybe<platform_plan_ecommerce_product_arr_rel_insert_input>;
  platform_variants?: InputMaybe<platform_variant_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_ecommerce_product_max_fields = {
  __typename: 'platform_ecommerce_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_max_order_by = {
  created_at?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_ecommerce_product_min_fields = {
  __typename: 'platform_ecommerce_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_min_order_by = {
  created_at?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_ecommerce_product" */
export type platform_ecommerce_product_mutation_response = {
  __typename: 'platform_ecommerce_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_ecommerce_product>;
};

/** input type for inserting object relation for remote table "platform_ecommerce_product" */
export type platform_ecommerce_product_obj_rel_insert_input = {
  data: platform_ecommerce_product_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_ecommerce_product_on_conflict>;
};

/** on_conflict condition type for table "platform_ecommerce_product" */
export type platform_ecommerce_product_on_conflict = {
  constraint: platform_ecommerce_product_constraint;
  update_columns?: Array<platform_ecommerce_product_update_column>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};

/** Ordering options when selecting data from "platform_ecommerce_product". */
export type platform_ecommerce_product_order_by = {
  active?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  image_url?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  offer_product_swap_platform_ecommerce_product?: InputMaybe<offer_product_swap_platform_ecommerce_product_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_ecommerce_products_aggregate?: InputMaybe<platform_plan_ecommerce_product_aggregate_order_by>;
  platform_variants_aggregate?: InputMaybe<platform_variant_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_ecommerce_product */
export type platform_ecommerce_product_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_ecommerce_product" */
export enum platform_ecommerce_product_select_column {
  /** column name */
  active = 'active',
  /** column name */
  created_at = 'created_at',
  /** column name */
  image_url = 'image_url',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_ecommerce_product" */
export enum platform_ecommerce_product_select_column_platform_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  active = 'active'
}

/** select "platform_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_ecommerce_product" */
export enum platform_ecommerce_product_select_column_platform_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  active = 'active'
}

/** input type for updating data in table "platform_ecommerce_product" */
export type platform_ecommerce_product_set_input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_ecommerce_product_stddev_fields = {
  __typename: 'platform_ecommerce_product_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_ecommerce_product_stddev_pop_fields = {
  __typename: 'platform_ecommerce_product_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_ecommerce_product_stddev_samp_fields = {
  __typename: 'platform_ecommerce_product_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_ecommerce_product" */
export type platform_ecommerce_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_ecommerce_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_ecommerce_product_stream_cursor_value_input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_ecommerce_product_sum_fields = {
  __typename: 'platform_ecommerce_product_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_ecommerce_product" */
export enum platform_ecommerce_product_update_column {
  /** column name */
  active = 'active',
  /** column name */
  created_at = 'created_at',
  /** column name */
  image_url = 'image_url',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_ecommerce_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_ecommerce_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_ecommerce_product_set_input>;
  /** filter the rows which have to be updated */
  where: platform_ecommerce_product_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_ecommerce_product_var_pop_fields = {
  __typename: 'platform_ecommerce_product_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_ecommerce_product_var_samp_fields = {
  __typename: 'platform_ecommerce_product_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_ecommerce_product_variance_fields = {
  __typename: 'platform_ecommerce_product_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_ecommerce_product" */
export type platform_ecommerce_product_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

export enum platform_enum {
  bold = 'bold',
  braintree = 'braintree',
  chargebee = 'chargebee',
  chargify = 'chargify',
  custom = 'custom',
  naviga = 'naviga',
  openpay = 'openpay',
  ordergroove = 'ordergroove',
  paddle = 'paddle',
  recharge = 'recharge',
  recurly = 'recurly',
  stripe = 'stripe',
  zuora = 'zuora'
}

/** Boolean expression to compare columns of type "platform_enum". All fields are combined with logical 'AND'. */
export type platform_enum_comparison_exp = {
  _eq?: InputMaybe<platform_enum>;
  _in?: InputMaybe<Array<platform_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_enum>;
  _nin?: InputMaybe<Array<platform_enum>>;
};

/** columns and relationships of "platform_import" */
export type platform_import = {
  __typename: 'platform_import';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  failed_at?: Maybe<Scalars['timestamptz']['output']>;
  initial: Scalars['Boolean']['output'];
  job_id: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An array relationship */
  platform_import_steps: Array<platform_import_step>;
  /** An aggregate relationship */
  platform_import_steps_aggregate: platform_import_step_aggregate;
  retries: Scalars['Int']['output'];
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  status: platform_import_status_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_import" */
export type platform_importplatform_import_stepsArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


/** columns and relationships of "platform_import" */
export type platform_importplatform_import_steps_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};

/** aggregated selection of "platform_import" */
export type platform_import_aggregate = {
  __typename: 'platform_import_aggregate';
  aggregate?: Maybe<platform_import_aggregate_fields>;
  nodes: Array<platform_import>;
};

/** aggregate fields of "platform_import" */
export type platform_import_aggregate_fields = {
  __typename: 'platform_import_aggregate_fields';
  avg?: Maybe<platform_import_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_import_max_fields>;
  min?: Maybe<platform_import_min_fields>;
  stddev?: Maybe<platform_import_stddev_fields>;
  stddev_pop?: Maybe<platform_import_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_import_stddev_samp_fields>;
  sum?: Maybe<platform_import_sum_fields>;
  var_pop?: Maybe<platform_import_var_pop_fields>;
  var_samp?: Maybe<platform_import_var_samp_fields>;
  variance?: Maybe<platform_import_variance_fields>;
};


/** aggregate fields of "platform_import" */
export type platform_import_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_import_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_import_avg_fields = {
  __typename: 'platform_import_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_import". All fields are combined with a logical 'AND'. */
export type platform_import_bool_exp = {
  _and?: InputMaybe<Array<platform_import_bool_exp>>;
  _not?: InputMaybe<platform_import_bool_exp>;
  _or?: InputMaybe<Array<platform_import_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  failed_at?: InputMaybe<timestamptz_comparison_exp>;
  initial?: InputMaybe<Boolean_comparison_exp>;
  job_id?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_import_steps?: InputMaybe<platform_import_step_bool_exp>;
  platform_import_steps_aggregate?: InputMaybe<platform_import_step_aggregate_bool_exp>;
  retries?: InputMaybe<Int_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  status?: InputMaybe<platform_import_status_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_import" */
export enum platform_import_constraint {
  /** unique or primary key constraint on columns "job_id" */
  platform_import_pkey = 'platform_import_pkey'
}

/** input type for incrementing numeric columns in table "platform_import" */
export type platform_import_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  retries?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_import" */
export type platform_import_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  initial?: InputMaybe<Scalars['Boolean']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_import_steps?: InputMaybe<platform_import_step_arr_rel_insert_input>;
  retries?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_import_max_fields = {
  __typename: 'platform_import_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  failed_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  retries?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type platform_import_min_fields = {
  __typename: 'platform_import_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  failed_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  retries?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "platform_import" */
export type platform_import_mutation_response = {
  __typename: 'platform_import_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_import>;
};

/** on_conflict condition type for table "platform_import" */
export type platform_import_on_conflict = {
  constraint: platform_import_constraint;
  update_columns?: Array<platform_import_update_column>;
  where?: InputMaybe<platform_import_bool_exp>;
};

/** Ordering options when selecting data from "platform_import". */
export type platform_import_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  failed_at?: InputMaybe<order_by>;
  initial?: InputMaybe<order_by>;
  job_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_import_steps_aggregate?: InputMaybe<platform_import_step_aggregate_order_by>;
  retries?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_import */
export type platform_import_pk_columns_input = {
  job_id: Scalars['String']['input'];
};

/** select columns of table "platform_import" */
export enum platform_import_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  failed_at = 'failed_at',
  /** column name */
  initial = 'initial',
  /** column name */
  job_id = 'job_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  retries = 'retries',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_import" */
export type platform_import_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  initial?: InputMaybe<Scalars['Boolean']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  retries?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "platform_import_status" */
export type platform_import_status = {
  __typename: 'platform_import_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "platform_import_status" */
export type platform_import_status_aggregate = {
  __typename: 'platform_import_status_aggregate';
  aggregate?: Maybe<platform_import_status_aggregate_fields>;
  nodes: Array<platform_import_status>;
};

/** aggregate fields of "platform_import_status" */
export type platform_import_status_aggregate_fields = {
  __typename: 'platform_import_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_import_status_max_fields>;
  min?: Maybe<platform_import_status_min_fields>;
};


/** aggregate fields of "platform_import_status" */
export type platform_import_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_import_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_import_status". All fields are combined with a logical 'AND'. */
export type platform_import_status_bool_exp = {
  _and?: InputMaybe<Array<platform_import_status_bool_exp>>;
  _not?: InputMaybe<platform_import_status_bool_exp>;
  _or?: InputMaybe<Array<platform_import_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_import_status" */
export enum platform_import_status_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_import_status_pkey = 'platform_import_status_pkey'
}

export enum platform_import_status_enum {
  complete = 'complete',
  created = 'created',
  failed = 'failed',
  running = 'running'
}

/** Boolean expression to compare columns of type "platform_import_status_enum". All fields are combined with logical 'AND'. */
export type platform_import_status_enum_comparison_exp = {
  _eq?: InputMaybe<platform_import_status_enum>;
  _in?: InputMaybe<Array<platform_import_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_import_status_enum>;
  _nin?: InputMaybe<Array<platform_import_status_enum>>;
};

/** input type for inserting data into table "platform_import_status" */
export type platform_import_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_import_status_max_fields = {
  __typename: 'platform_import_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_import_status_min_fields = {
  __typename: 'platform_import_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_import_status" */
export type platform_import_status_mutation_response = {
  __typename: 'platform_import_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_import_status>;
};

/** on_conflict condition type for table "platform_import_status" */
export type platform_import_status_on_conflict = {
  constraint: platform_import_status_constraint;
  update_columns?: Array<platform_import_status_update_column>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};

/** Ordering options when selecting data from "platform_import_status". */
export type platform_import_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_import_status */
export type platform_import_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_import_status" */
export enum platform_import_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_import_status" */
export type platform_import_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_import_status" */
export type platform_import_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_import_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_import_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_import_status" */
export enum platform_import_status_update_column {
  /** column name */
  value = 'value'
}

export type platform_import_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_import_status_set_input>;
  /** filter the rows which have to be updated */
  where: platform_import_status_bool_exp;
};

/** aggregate stddev on columns */
export type platform_import_stddev_fields = {
  __typename: 'platform_import_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_import_stddev_pop_fields = {
  __typename: 'platform_import_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_import_stddev_samp_fields = {
  __typename: 'platform_import_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_import_step" */
export type platform_import_step = {
  __typename: 'platform_import_step';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  cursor?: Maybe<Scalars['jsonb']['output']>;
  job_id: Scalars['String']['output'];
  started_at: Scalars['timestamptz']['output'];
  type: platform_import_step_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_import_step" */
export type platform_import_stepcursorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_import_step" */
export type platform_import_step_aggregate = {
  __typename: 'platform_import_step_aggregate';
  aggregate?: Maybe<platform_import_step_aggregate_fields>;
  nodes: Array<platform_import_step>;
};

export type platform_import_step_aggregate_bool_exp = {
  count?: InputMaybe<platform_import_step_aggregate_bool_exp_count>;
};

export type platform_import_step_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_import_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_import_step_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_import_step" */
export type platform_import_step_aggregate_fields = {
  __typename: 'platform_import_step_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_import_step_max_fields>;
  min?: Maybe<platform_import_step_min_fields>;
};


/** aggregate fields of "platform_import_step" */
export type platform_import_step_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_import_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_import_step" */
export type platform_import_step_aggregate_order_by = {
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_import_step_max_order_by>;
  min?: InputMaybe<platform_import_step_min_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_import_step_append_input = {
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "platform_import_step" */
export type platform_import_step_arr_rel_insert_input = {
  data: Array<platform_import_step_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_import_step_on_conflict>;
};

/** Boolean expression to filter rows from the table "platform_import_step". All fields are combined with a logical 'AND'. */
export type platform_import_step_bool_exp = {
  _and?: InputMaybe<Array<platform_import_step_bool_exp>>;
  _not?: InputMaybe<platform_import_step_bool_exp>;
  _or?: InputMaybe<Array<platform_import_step_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  cursor?: InputMaybe<jsonb_comparison_exp>;
  job_id?: InputMaybe<String_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  type?: InputMaybe<platform_import_step_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_import_step" */
export enum platform_import_step_constraint {
  /** unique or primary key constraint on columns "type", "job_id" */
  platform_import_step_pkey = 'platform_import_step_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_import_step_delete_at_path_input = {
  cursor?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_import_step_delete_elem_input = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_import_step_delete_key_input = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "platform_import_step" */
export type platform_import_step_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<platform_import_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_import_step_max_fields = {
  __typename: 'platform_import_step_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_import_step" */
export type platform_import_step_max_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  job_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_import_step_min_fields = {
  __typename: 'platform_import_step_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_import_step" */
export type platform_import_step_min_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  job_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_import_step" */
export type platform_import_step_mutation_response = {
  __typename: 'platform_import_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_import_step>;
};

/** on_conflict condition type for table "platform_import_step" */
export type platform_import_step_on_conflict = {
  constraint: platform_import_step_constraint;
  update_columns?: Array<platform_import_step_update_column>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};

/** Ordering options when selecting data from "platform_import_step". */
export type platform_import_step_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  cursor?: InputMaybe<order_by>;
  job_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_import_step */
export type platform_import_step_pk_columns_input = {
  job_id: Scalars['String']['input'];
  type: platform_import_step_type_enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_import_step_prepend_input = {
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_import_step" */
export enum platform_import_step_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  cursor = 'cursor',
  /** column name */
  job_id = 'job_id',
  /** column name */
  started_at = 'started_at',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_import_step" */
export type platform_import_step_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<platform_import_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "platform_import_step" */
export type platform_import_step_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_import_step_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_import_step_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  cursor?: InputMaybe<Scalars['jsonb']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<platform_import_step_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "platform_import_step_type" */
export type platform_import_step_type = {
  __typename: 'platform_import_step_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "platform_import_step_type" */
export type platform_import_step_type_aggregate = {
  __typename: 'platform_import_step_type_aggregate';
  aggregate?: Maybe<platform_import_step_type_aggregate_fields>;
  nodes: Array<platform_import_step_type>;
};

/** aggregate fields of "platform_import_step_type" */
export type platform_import_step_type_aggregate_fields = {
  __typename: 'platform_import_step_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_import_step_type_max_fields>;
  min?: Maybe<platform_import_step_type_min_fields>;
};


/** aggregate fields of "platform_import_step_type" */
export type platform_import_step_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_import_step_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_import_step_type". All fields are combined with a logical 'AND'. */
export type platform_import_step_type_bool_exp = {
  _and?: InputMaybe<Array<platform_import_step_type_bool_exp>>;
  _not?: InputMaybe<platform_import_step_type_bool_exp>;
  _or?: InputMaybe<Array<platform_import_step_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_import_step_type" */
export enum platform_import_step_type_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_import_step_type_pkey = 'platform_import_step_type_pkey'
}

export enum platform_import_step_type_enum {
  import_addons = 'import_addons',
  import_cancel_reasons = 'import_cancel_reasons',
  import_coupons = 'import_coupons',
  import_customers = 'import_customers',
  import_ecommerce_products = 'import_ecommerce_products',
  import_invoices = 'import_invoices',
  import_plans = 'import_plans',
  import_platform = 'import_platform',
  import_product_groups = 'import_product_groups',
  import_products = 'import_products',
  import_subscriptions = 'import_subscriptions',
  process_addons = 'process_addons',
  process_coupons = 'process_coupons',
  process_customers = 'process_customers',
  process_invoices = 'process_invoices',
  process_plans = 'process_plans',
  process_product_groups = 'process_product_groups',
  process_products = 'process_products',
  process_subscriptions = 'process_subscriptions'
}

/** Boolean expression to compare columns of type "platform_import_step_type_enum". All fields are combined with logical 'AND'. */
export type platform_import_step_type_enum_comparison_exp = {
  _eq?: InputMaybe<platform_import_step_type_enum>;
  _in?: InputMaybe<Array<platform_import_step_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_import_step_type_enum>;
  _nin?: InputMaybe<Array<platform_import_step_type_enum>>;
};

/** input type for inserting data into table "platform_import_step_type" */
export type platform_import_step_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_import_step_type_max_fields = {
  __typename: 'platform_import_step_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_import_step_type_min_fields = {
  __typename: 'platform_import_step_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_import_step_type" */
export type platform_import_step_type_mutation_response = {
  __typename: 'platform_import_step_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_import_step_type>;
};

/** on_conflict condition type for table "platform_import_step_type" */
export type platform_import_step_type_on_conflict = {
  constraint: platform_import_step_type_constraint;
  update_columns?: Array<platform_import_step_type_update_column>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};

/** Ordering options when selecting data from "platform_import_step_type". */
export type platform_import_step_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_import_step_type */
export type platform_import_step_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_import_step_type" */
export enum platform_import_step_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_import_step_type" */
export type platform_import_step_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_import_step_type" */
export type platform_import_step_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_import_step_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_import_step_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_import_step_type" */
export enum platform_import_step_type_update_column {
  /** column name */
  value = 'value'
}

export type platform_import_step_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_import_step_type_set_input>;
  /** filter the rows which have to be updated */
  where: platform_import_step_type_bool_exp;
};

/** update columns of table "platform_import_step" */
export enum platform_import_step_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  cursor = 'cursor',
  /** column name */
  job_id = 'job_id',
  /** column name */
  started_at = 'started_at',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_import_step_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_import_step_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_import_step_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_import_step_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_import_step_delete_key_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_import_step_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_import_step_set_input>;
  /** filter the rows which have to be updated */
  where: platform_import_step_bool_exp;
};

/** Streaming cursor of the table "platform_import" */
export type platform_import_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_import_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_import_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  initial?: InputMaybe<Scalars['Boolean']['input']>;
  job_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  retries?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_import_status_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_import_sum_fields = {
  __typename: 'platform_import_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  retries?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_import" */
export enum platform_import_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  failed_at = 'failed_at',
  /** column name */
  initial = 'initial',
  /** column name */
  job_id = 'job_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  retries = 'retries',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_import_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_import_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_import_set_input>;
  /** filter the rows which have to be updated */
  where: platform_import_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_import_var_pop_fields = {
  __typename: 'platform_import_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_import_var_samp_fields = {
  __typename: 'platform_import_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_import_variance_fields = {
  __typename: 'platform_import_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "platform" */
export type platform_insert_input = {
  platform_connections?: InputMaybe<platform_connection_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "platform_invoice" */
export type platform_invoice = {
  __typename: 'platform_invoice';
  amount_paid: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  currency: platform_currency_enum;
  imported: Scalars['Boolean']['output'];
  number?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  period_end: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_currency: platform_currency;
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_invoice_status: platform_invoice_status;
  /** An array relationship */
  platform_refunds: Array<platform_refund>;
  /** An aggregate relationship */
  platform_refunds_aggregate: platform_refund_aggregate;
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  platform_subscription_id: Scalars['String']['output'];
  status: platform_invoice_status_enum;
  total: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_invoice" */
export type platform_invoiceplatform_refundsArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


/** columns and relationships of "platform_invoice" */
export type platform_invoiceplatform_refunds_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};

/** aggregated selection of "platform_invoice" */
export type platform_invoice_aggregate = {
  __typename: 'platform_invoice_aggregate';
  aggregate?: Maybe<platform_invoice_aggregate_fields>;
  nodes: Array<platform_invoice>;
};

export type platform_invoice_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_invoice_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_invoice_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_invoice_aggregate_bool_exp_count>;
};

export type platform_invoice_aggregate_bool_exp_bool_and = {
  arguments: platform_invoice_select_column_platform_invoice_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_invoice_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_invoice_aggregate_bool_exp_bool_or = {
  arguments: platform_invoice_select_column_platform_invoice_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_invoice_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_invoice_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_invoice_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_invoice_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_invoice" */
export type platform_invoice_aggregate_fields = {
  __typename: 'platform_invoice_aggregate_fields';
  avg?: Maybe<platform_invoice_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_invoice_max_fields>;
  min?: Maybe<platform_invoice_min_fields>;
  stddev?: Maybe<platform_invoice_stddev_fields>;
  stddev_pop?: Maybe<platform_invoice_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_invoice_stddev_samp_fields>;
  sum?: Maybe<platform_invoice_sum_fields>;
  var_pop?: Maybe<platform_invoice_var_pop_fields>;
  var_samp?: Maybe<platform_invoice_var_samp_fields>;
  variance?: Maybe<platform_invoice_variance_fields>;
};


/** aggregate fields of "platform_invoice" */
export type platform_invoice_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_invoice_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_invoice" */
export type platform_invoice_aggregate_order_by = {
  avg?: InputMaybe<platform_invoice_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_invoice_max_order_by>;
  min?: InputMaybe<platform_invoice_min_order_by>;
  stddev?: InputMaybe<platform_invoice_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_invoice_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_invoice_stddev_samp_order_by>;
  sum?: InputMaybe<platform_invoice_sum_order_by>;
  var_pop?: InputMaybe<platform_invoice_var_pop_order_by>;
  var_samp?: InputMaybe<platform_invoice_var_samp_order_by>;
  variance?: InputMaybe<platform_invoice_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_invoice" */
export type platform_invoice_arr_rel_insert_input = {
  data: Array<platform_invoice_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_invoice_on_conflict>;
};

/** aggregate avg on columns */
export type platform_invoice_avg_fields = {
  __typename: 'platform_invoice_avg_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_invoice" */
export type platform_invoice_avg_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_invoice". All fields are combined with a logical 'AND'. */
export type platform_invoice_bool_exp = {
  _and?: InputMaybe<Array<platform_invoice_bool_exp>>;
  _not?: InputMaybe<platform_invoice_bool_exp>;
  _or?: InputMaybe<Array<platform_invoice_bool_exp>>;
  amount_paid?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_currency_enum_comparison_exp>;
  imported?: InputMaybe<Boolean_comparison_exp>;
  number?: InputMaybe<String_comparison_exp>;
  paid_at?: InputMaybe<timestamptz_comparison_exp>;
  period_end?: InputMaybe<timestamptz_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_invoice_status?: InputMaybe<platform_invoice_status_bool_exp>;
  platform_refunds?: InputMaybe<platform_refund_bool_exp>;
  platform_refunds_aggregate?: InputMaybe<platform_refund_aggregate_bool_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<platform_invoice_status_enum_comparison_exp>;
  total?: InputMaybe<numeric_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_invoice" */
export enum platform_invoice_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_invoice_pkey = 'platform_invoice_pkey'
}

/** input type for incrementing numeric columns in table "platform_invoice" */
export type platform_invoice_inc_input = {
  amount_paid?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_invoice" */
export type platform_invoice_insert_input = {
  amount_paid?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoice_status?: InputMaybe<platform_invoice_status_obj_rel_insert_input>;
  platform_refunds?: InputMaybe<platform_refund_arr_rel_insert_input>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<platform_invoice_status_enum>;
  total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_invoice_max_fields = {
  __typename: 'platform_invoice_max_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  period_end?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_invoice" */
export type platform_invoice_max_order_by = {
  amount_paid?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  number?: InputMaybe<order_by>;
  paid_at?: InputMaybe<order_by>;
  period_end?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_invoice_min_fields = {
  __typename: 'platform_invoice_min_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paid_at?: Maybe<Scalars['timestamptz']['output']>;
  period_end?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_invoice" */
export type platform_invoice_min_order_by = {
  amount_paid?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  number?: InputMaybe<order_by>;
  paid_at?: InputMaybe<order_by>;
  period_end?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_invoice" */
export type platform_invoice_mutation_response = {
  __typename: 'platform_invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_invoice>;
};

/** input type for inserting object relation for remote table "platform_invoice" */
export type platform_invoice_obj_rel_insert_input = {
  data: platform_invoice_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_invoice_on_conflict>;
};

/** on_conflict condition type for table "platform_invoice" */
export type platform_invoice_on_conflict = {
  constraint: platform_invoice_constraint;
  update_columns?: Array<platform_invoice_update_column>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};

/** Ordering options when selecting data from "platform_invoice". */
export type platform_invoice_order_by = {
  amount_paid?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  imported?: InputMaybe<order_by>;
  number?: InputMaybe<order_by>;
  paid_at?: InputMaybe<order_by>;
  period_end?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoice_status?: InputMaybe<platform_invoice_status_order_by>;
  platform_refunds_aggregate?: InputMaybe<platform_refund_aggregate_order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_invoice */
export type platform_invoice_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_invoice" */
export enum platform_invoice_select_column {
  /** column name */
  amount_paid = 'amount_paid',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  imported = 'imported',
  /** column name */
  number = 'number',
  /** column name */
  paid_at = 'paid_at',
  /** column name */
  period_end = 'period_end',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  status = 'status',
  /** column name */
  total = 'total',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_invoice" */
export enum platform_invoice_select_column_platform_invoice_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  imported = 'imported'
}

/** select "platform_invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_invoice" */
export enum platform_invoice_select_column_platform_invoice_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  imported = 'imported'
}

/** input type for updating data in table "platform_invoice" */
export type platform_invoice_set_input = {
  amount_paid?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<platform_invoice_status_enum>;
  total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "platform_invoice_status" */
export type platform_invoice_status = {
  __typename: 'platform_invoice_status';
  /** An array relationship */
  platform_invoices: Array<platform_invoice>;
  /** An aggregate relationship */
  platform_invoices_aggregate: platform_invoice_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_invoice_status" */
export type platform_invoice_statusplatform_invoicesArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_invoice_status" */
export type platform_invoice_statusplatform_invoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};

/** aggregated selection of "platform_invoice_status" */
export type platform_invoice_status_aggregate = {
  __typename: 'platform_invoice_status_aggregate';
  aggregate?: Maybe<platform_invoice_status_aggregate_fields>;
  nodes: Array<platform_invoice_status>;
};

/** aggregate fields of "platform_invoice_status" */
export type platform_invoice_status_aggregate_fields = {
  __typename: 'platform_invoice_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_invoice_status_max_fields>;
  min?: Maybe<platform_invoice_status_min_fields>;
};


/** aggregate fields of "platform_invoice_status" */
export type platform_invoice_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_invoice_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_invoice_status". All fields are combined with a logical 'AND'. */
export type platform_invoice_status_bool_exp = {
  _and?: InputMaybe<Array<platform_invoice_status_bool_exp>>;
  _not?: InputMaybe<platform_invoice_status_bool_exp>;
  _or?: InputMaybe<Array<platform_invoice_status_bool_exp>>;
  platform_invoices?: InputMaybe<platform_invoice_bool_exp>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_invoice_status" */
export enum platform_invoice_status_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_invoice_status_pkey = 'platform_invoice_status_pkey'
}

export enum platform_invoice_status_enum {
  draft = 'draft',
  open = 'open',
  paid = 'paid',
  unpaid = 'unpaid',
  void = 'void'
}

/** Boolean expression to compare columns of type "platform_invoice_status_enum". All fields are combined with logical 'AND'. */
export type platform_invoice_status_enum_comparison_exp = {
  _eq?: InputMaybe<platform_invoice_status_enum>;
  _in?: InputMaybe<Array<platform_invoice_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_invoice_status_enum>;
  _nin?: InputMaybe<Array<platform_invoice_status_enum>>;
};

/** input type for inserting data into table "platform_invoice_status" */
export type platform_invoice_status_insert_input = {
  platform_invoices?: InputMaybe<platform_invoice_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_invoice_status_max_fields = {
  __typename: 'platform_invoice_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_invoice_status_min_fields = {
  __typename: 'platform_invoice_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_invoice_status" */
export type platform_invoice_status_mutation_response = {
  __typename: 'platform_invoice_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_invoice_status>;
};

/** input type for inserting object relation for remote table "platform_invoice_status" */
export type platform_invoice_status_obj_rel_insert_input = {
  data: platform_invoice_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_invoice_status_on_conflict>;
};

/** on_conflict condition type for table "platform_invoice_status" */
export type platform_invoice_status_on_conflict = {
  constraint: platform_invoice_status_constraint;
  update_columns?: Array<platform_invoice_status_update_column>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};

/** Ordering options when selecting data from "platform_invoice_status". */
export type platform_invoice_status_order_by = {
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_invoice_status */
export type platform_invoice_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_invoice_status" */
export enum platform_invoice_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_invoice_status" */
export type platform_invoice_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_invoice_status" */
export type platform_invoice_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_invoice_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_invoice_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_invoice_status" */
export enum platform_invoice_status_update_column {
  /** column name */
  value = 'value'
}

export type platform_invoice_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_invoice_status_set_input>;
  /** filter the rows which have to be updated */
  where: platform_invoice_status_bool_exp;
};

/** aggregate stddev on columns */
export type platform_invoice_stddev_fields = {
  __typename: 'platform_invoice_stddev_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_invoice" */
export type platform_invoice_stddev_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_invoice_stddev_pop_fields = {
  __typename: 'platform_invoice_stddev_pop_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_invoice" */
export type platform_invoice_stddev_pop_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_invoice_stddev_samp_fields = {
  __typename: 'platform_invoice_stddev_samp_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_invoice" */
export type platform_invoice_stddev_samp_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_invoice" */
export type platform_invoice_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_invoice_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_invoice_stream_cursor_value_input = {
  amount_paid?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  paid_at?: InputMaybe<Scalars['timestamptz']['input']>;
  period_end?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<platform_invoice_status_enum>;
  total?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_invoice_sum_fields = {
  __typename: 'platform_invoice_sum_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "platform_invoice" */
export type platform_invoice_sum_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** update columns of table "platform_invoice" */
export enum platform_invoice_update_column {
  /** column name */
  amount_paid = 'amount_paid',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  imported = 'imported',
  /** column name */
  number = 'number',
  /** column name */
  paid_at = 'paid_at',
  /** column name */
  period_end = 'period_end',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  status = 'status',
  /** column name */
  total = 'total',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_invoice_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_invoice_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_invoice_set_input>;
  /** filter the rows which have to be updated */
  where: platform_invoice_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_invoice_var_pop_fields = {
  __typename: 'platform_invoice_var_pop_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_invoice" */
export type platform_invoice_var_pop_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_invoice_var_samp_fields = {
  __typename: 'platform_invoice_var_samp_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_invoice" */
export type platform_invoice_var_samp_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_invoice_variance_fields = {
  __typename: 'platform_invoice_variance_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_invoice" */
export type platform_invoice_variance_order_by = {
  amount_paid?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type platform_max_fields = {
  __typename: 'platform_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_min_fields = {
  __typename: 'platform_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform" */
export type platform_mutation_response = {
  __typename: 'platform_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform>;
};

/** columns and relationships of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code = {
  __typename: 'platform_newspaper_rate_code';
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['output'];
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  /** An object relationship */
  platform_plan_interval?: Maybe<platform_plan_interval>;
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  price: Scalars['numeric']['output'];
  rate_code: Scalars['String']['output'];
};


/** columns and relationships of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_codeplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_codeplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** aggregated selection of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_aggregate = {
  __typename: 'platform_newspaper_rate_code_aggregate';
  aggregate?: Maybe<platform_newspaper_rate_code_aggregate_fields>;
  nodes: Array<platform_newspaper_rate_code>;
};

/** aggregate fields of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_aggregate_fields = {
  __typename: 'platform_newspaper_rate_code_aggregate_fields';
  avg?: Maybe<platform_newspaper_rate_code_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_newspaper_rate_code_max_fields>;
  min?: Maybe<platform_newspaper_rate_code_min_fields>;
  stddev?: Maybe<platform_newspaper_rate_code_stddev_fields>;
  stddev_pop?: Maybe<platform_newspaper_rate_code_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_newspaper_rate_code_stddev_samp_fields>;
  sum?: Maybe<platform_newspaper_rate_code_sum_fields>;
  var_pop?: Maybe<platform_newspaper_rate_code_var_pop_fields>;
  var_samp?: Maybe<platform_newspaper_rate_code_var_samp_fields>;
  variance?: Maybe<platform_newspaper_rate_code_variance_fields>;
};


/** aggregate fields of "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_newspaper_rate_code_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_newspaper_rate_code_avg_fields = {
  __typename: 'platform_newspaper_rate_code_avg_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_newspaper_rate_code". All fields are combined with a logical 'AND'. */
export type platform_newspaper_rate_code_bool_exp = {
  _and?: InputMaybe<Array<platform_newspaper_rate_code_bool_exp>>;
  _not?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
  _or?: InputMaybe<Array<platform_newspaper_rate_code_bool_exp>>;
  interval?: InputMaybe<platform_plan_interval_enum_comparison_exp>;
  interval_count?: InputMaybe<Int_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_bool_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  rate_code?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_newspaper_rate_code" */
export enum platform_newspaper_rate_code_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "interval_count", "rate_code", "interval" */
  platform_newspaper_rate_code_pkey = 'platform_newspaper_rate_code_pkey'
}

/** input type for incrementing numeric columns in table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_inc_input = {
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_insert_input = {
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_obj_rel_insert_input>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_newspaper_rate_code_max_fields = {
  __typename: 'platform_newspaper_rate_code_max_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  rate_code?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_newspaper_rate_code_min_fields = {
  __typename: 'platform_newspaper_rate_code_min_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  rate_code?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_mutation_response = {
  __typename: 'platform_newspaper_rate_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_newspaper_rate_code>;
};

/** on_conflict condition type for table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_on_conflict = {
  constraint: platform_newspaper_rate_code_constraint;
  update_columns?: Array<platform_newspaper_rate_code_update_column>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};

/** Ordering options when selecting data from "platform_newspaper_rate_code". */
export type platform_newspaper_rate_code_order_by = {
  interval?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  price?: InputMaybe<order_by>;
  rate_code?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_newspaper_rate_code */
export type platform_newspaper_rate_code_pk_columns_input = {
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  rate_code: Scalars['String']['input'];
};

/** select columns of table "platform_newspaper_rate_code" */
export enum platform_newspaper_rate_code_select_column {
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  price = 'price',
  /** column name */
  rate_code = 'rate_code'
}

/** input type for updating data in table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_set_input = {
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_newspaper_rate_code_stddev_fields = {
  __typename: 'platform_newspaper_rate_code_stddev_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_newspaper_rate_code_stddev_pop_fields = {
  __typename: 'platform_newspaper_rate_code_stddev_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_newspaper_rate_code_stddev_samp_fields = {
  __typename: 'platform_newspaper_rate_code_stddev_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_newspaper_rate_code" */
export type platform_newspaper_rate_code_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_newspaper_rate_code_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_newspaper_rate_code_stream_cursor_value_input = {
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_newspaper_rate_code_sum_fields = {
  __typename: 'platform_newspaper_rate_code_sum_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "platform_newspaper_rate_code" */
export enum platform_newspaper_rate_code_update_column {
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  price = 'price',
  /** column name */
  rate_code = 'rate_code'
}

export type platform_newspaper_rate_code_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_newspaper_rate_code_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_newspaper_rate_code_set_input>;
  /** filter the rows which have to be updated */
  where: platform_newspaper_rate_code_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_newspaper_rate_code_var_pop_fields = {
  __typename: 'platform_newspaper_rate_code_var_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_newspaper_rate_code_var_samp_fields = {
  __typename: 'platform_newspaper_rate_code_var_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_newspaper_rate_code_variance_fields = {
  __typename: 'platform_newspaper_rate_code_variance_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_newspaper_subscription" */
export type platform_newspaper_subscription = {
  __typename: 'platform_newspaper_subscription';
  current_balance?: Maybe<Scalars['numeric']['output']>;
  digital_frequency?: Maybe<Scalars['jsonb']['output']>;
  is_digital?: Maybe<Scalars['Boolean']['output']>;
  is_ez_pay?: Maybe<Scalars['Boolean']['output']>;
  is_print?: Maybe<Scalars['Boolean']['output']>;
  last_payment_amount?: Maybe<Scalars['numeric']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  paper_name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_newspaper_subscription_stop_date?: Maybe<platform_newspaper_subscription_stop_date>;
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  print_frequency?: Maybe<Scalars['jsonb']['output']>;
  rate_code?: Maybe<Scalars['String']['output']>;
  start_source?: Maybe<Scalars['String']['output']>;
  stop_date?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "platform_newspaper_subscription" */
export type platform_newspaper_subscriptiondigital_frequencyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_newspaper_subscription" */
export type platform_newspaper_subscriptionprint_frequencyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_newspaper_subscription" */
export type platform_newspaper_subscription_aggregate = {
  __typename: 'platform_newspaper_subscription_aggregate';
  aggregate?: Maybe<platform_newspaper_subscription_aggregate_fields>;
  nodes: Array<platform_newspaper_subscription>;
};

export type platform_newspaper_subscription_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_newspaper_subscription_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_newspaper_subscription_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_newspaper_subscription_aggregate_bool_exp_count>;
};

export type platform_newspaper_subscription_aggregate_bool_exp_bool_and = {
  arguments: platform_newspaper_subscription_select_column_platform_newspaper_subscription_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_newspaper_subscription_aggregate_bool_exp_bool_or = {
  arguments: platform_newspaper_subscription_select_column_platform_newspaper_subscription_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_newspaper_subscription_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_newspaper_subscription" */
export type platform_newspaper_subscription_aggregate_fields = {
  __typename: 'platform_newspaper_subscription_aggregate_fields';
  avg?: Maybe<platform_newspaper_subscription_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_newspaper_subscription_max_fields>;
  min?: Maybe<platform_newspaper_subscription_min_fields>;
  stddev?: Maybe<platform_newspaper_subscription_stddev_fields>;
  stddev_pop?: Maybe<platform_newspaper_subscription_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_newspaper_subscription_stddev_samp_fields>;
  sum?: Maybe<platform_newspaper_subscription_sum_fields>;
  var_pop?: Maybe<platform_newspaper_subscription_var_pop_fields>;
  var_samp?: Maybe<platform_newspaper_subscription_var_samp_fields>;
  variance?: Maybe<platform_newspaper_subscription_variance_fields>;
};


/** aggregate fields of "platform_newspaper_subscription" */
export type platform_newspaper_subscription_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_aggregate_order_by = {
  avg?: InputMaybe<platform_newspaper_subscription_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_newspaper_subscription_max_order_by>;
  min?: InputMaybe<platform_newspaper_subscription_min_order_by>;
  stddev?: InputMaybe<platform_newspaper_subscription_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_newspaper_subscription_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_newspaper_subscription_stddev_samp_order_by>;
  sum?: InputMaybe<platform_newspaper_subscription_sum_order_by>;
  var_pop?: InputMaybe<platform_newspaper_subscription_var_pop_order_by>;
  var_samp?: InputMaybe<platform_newspaper_subscription_var_samp_order_by>;
  variance?: InputMaybe<platform_newspaper_subscription_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_newspaper_subscription_append_input = {
  digital_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  print_frequency?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_arr_rel_insert_input = {
  data: Array<platform_newspaper_subscription_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_newspaper_subscription_on_conflict>;
};

/** aggregate avg on columns */
export type platform_newspaper_subscription_avg_fields = {
  __typename: 'platform_newspaper_subscription_avg_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_avg_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_newspaper_subscription". All fields are combined with a logical 'AND'. */
export type platform_newspaper_subscription_bool_exp = {
  _and?: InputMaybe<Array<platform_newspaper_subscription_bool_exp>>;
  _not?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  _or?: InputMaybe<Array<platform_newspaper_subscription_bool_exp>>;
  current_balance?: InputMaybe<numeric_comparison_exp>;
  digital_frequency?: InputMaybe<jsonb_comparison_exp>;
  is_digital?: InputMaybe<Boolean_comparison_exp>;
  is_ez_pay?: InputMaybe<Boolean_comparison_exp>;
  is_print?: InputMaybe<Boolean_comparison_exp>;
  last_payment_amount?: InputMaybe<numeric_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  paper_name?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_newspaper_subscription_stop_date?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  print_frequency?: InputMaybe<jsonb_comparison_exp>;
  rate_code?: InputMaybe<String_comparison_exp>;
  start_source?: InputMaybe<String_comparison_exp>;
  stop_date?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_newspaper_subscription" */
export enum platform_newspaper_subscription_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_newspaper_subscription_pkey = 'platform_newspaper_subscription_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_newspaper_subscription_delete_at_path_input = {
  digital_frequency?: InputMaybe<Array<Scalars['String']['input']>>;
  print_frequency?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_newspaper_subscription_delete_elem_input = {
  digital_frequency?: InputMaybe<Scalars['Int']['input']>;
  print_frequency?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_newspaper_subscription_delete_key_input = {
  digital_frequency?: InputMaybe<Scalars['String']['input']>;
  print_frequency?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_inc_input = {
  current_balance?: InputMaybe<Scalars['numeric']['input']>;
  last_payment_amount?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_insert_input = {
  current_balance?: InputMaybe<Scalars['numeric']['input']>;
  digital_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  is_digital?: InputMaybe<Scalars['Boolean']['input']>;
  is_ez_pay?: InputMaybe<Scalars['Boolean']['input']>;
  is_print?: InputMaybe<Scalars['Boolean']['input']>;
  last_payment_amount?: InputMaybe<Scalars['numeric']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  paper_name?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_newspaper_subscription_stop_date?: InputMaybe<platform_newspaper_subscription_stop_date_obj_rel_insert_input>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  print_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
  start_source?: InputMaybe<Scalars['String']['input']>;
  stop_date?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_newspaper_subscription_max_fields = {
  __typename: 'platform_newspaper_subscription_max_fields';
  current_balance?: Maybe<Scalars['numeric']['output']>;
  last_payment_amount?: Maybe<Scalars['numeric']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  paper_name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  rate_code?: Maybe<Scalars['String']['output']>;
  start_source?: Maybe<Scalars['String']['output']>;
  stop_date?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_max_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  paper_name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  rate_code?: InputMaybe<order_by>;
  start_source?: InputMaybe<order_by>;
  stop_date?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_newspaper_subscription_min_fields = {
  __typename: 'platform_newspaper_subscription_min_fields';
  current_balance?: Maybe<Scalars['numeric']['output']>;
  last_payment_amount?: Maybe<Scalars['numeric']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  paper_name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  rate_code?: Maybe<Scalars['String']['output']>;
  start_source?: Maybe<Scalars['String']['output']>;
  stop_date?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_min_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  paper_name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  rate_code?: InputMaybe<order_by>;
  start_source?: InputMaybe<order_by>;
  stop_date?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_mutation_response = {
  __typename: 'platform_newspaper_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_newspaper_subscription>;
};

/** input type for inserting object relation for remote table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_obj_rel_insert_input = {
  data: platform_newspaper_subscription_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_newspaper_subscription_on_conflict>;
};

/** on_conflict condition type for table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_on_conflict = {
  constraint: platform_newspaper_subscription_constraint;
  update_columns?: Array<platform_newspaper_subscription_update_column>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};

/** Ordering options when selecting data from "platform_newspaper_subscription". */
export type platform_newspaper_subscription_order_by = {
  current_balance?: InputMaybe<order_by>;
  digital_frequency?: InputMaybe<order_by>;
  is_digital?: InputMaybe<order_by>;
  is_ez_pay?: InputMaybe<order_by>;
  is_print?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  paper_name?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_newspaper_subscription_stop_date?: InputMaybe<platform_newspaper_subscription_stop_date_order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  print_frequency?: InputMaybe<order_by>;
  rate_code?: InputMaybe<order_by>;
  start_source?: InputMaybe<order_by>;
  stop_date?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_newspaper_subscription */
export type platform_newspaper_subscription_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_newspaper_subscription_prepend_input = {
  digital_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  print_frequency?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_newspaper_subscription" */
export enum platform_newspaper_subscription_select_column {
  /** column name */
  current_balance = 'current_balance',
  /** column name */
  digital_frequency = 'digital_frequency',
  /** column name */
  is_digital = 'is_digital',
  /** column name */
  is_ez_pay = 'is_ez_pay',
  /** column name */
  is_print = 'is_print',
  /** column name */
  last_payment_amount = 'last_payment_amount',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  paper_name = 'paper_name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  print_frequency = 'print_frequency',
  /** column name */
  rate_code = 'rate_code',
  /** column name */
  start_source = 'start_source',
  /** column name */
  stop_date = 'stop_date'
}

/** select "platform_newspaper_subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_newspaper_subscription" */
export enum platform_newspaper_subscription_select_column_platform_newspaper_subscription_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  is_digital = 'is_digital',
  /** column name */
  is_ez_pay = 'is_ez_pay',
  /** column name */
  is_print = 'is_print'
}

/** select "platform_newspaper_subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_newspaper_subscription" */
export enum platform_newspaper_subscription_select_column_platform_newspaper_subscription_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  is_digital = 'is_digital',
  /** column name */
  is_ez_pay = 'is_ez_pay',
  /** column name */
  is_print = 'is_print'
}

/** input type for updating data in table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_set_input = {
  current_balance?: InputMaybe<Scalars['numeric']['input']>;
  digital_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  is_digital?: InputMaybe<Scalars['Boolean']['input']>;
  is_ez_pay?: InputMaybe<Scalars['Boolean']['input']>;
  is_print?: InputMaybe<Scalars['Boolean']['input']>;
  last_payment_amount?: InputMaybe<Scalars['numeric']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  paper_name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  print_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
  start_source?: InputMaybe<Scalars['String']['input']>;
  stop_date?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_newspaper_subscription_stddev_fields = {
  __typename: 'platform_newspaper_subscription_stddev_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_stddev_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_newspaper_subscription_stddev_pop_fields = {
  __typename: 'platform_newspaper_subscription_stddev_pop_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_stddev_pop_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_newspaper_subscription_stddev_samp_fields = {
  __typename: 'platform_newspaper_subscription_stddev_samp_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_stddev_samp_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date = {
  __typename: 'platform_newspaper_subscription_stop_date';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_newspaper_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_aggregate = {
  __typename: 'platform_newspaper_subscription_stop_date_aggregate';
  aggregate?: Maybe<platform_newspaper_subscription_stop_date_aggregate_fields>;
  nodes: Array<platform_newspaper_subscription_stop_date>;
};

/** aggregate fields of "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_aggregate_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_aggregate_fields';
  avg?: Maybe<platform_newspaper_subscription_stop_date_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_newspaper_subscription_stop_date_max_fields>;
  min?: Maybe<platform_newspaper_subscription_stop_date_min_fields>;
  stddev?: Maybe<platform_newspaper_subscription_stop_date_stddev_fields>;
  stddev_pop?: Maybe<platform_newspaper_subscription_stop_date_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_newspaper_subscription_stop_date_stddev_samp_fields>;
  sum?: Maybe<platform_newspaper_subscription_stop_date_sum_fields>;
  var_pop?: Maybe<platform_newspaper_subscription_stop_date_var_pop_fields>;
  var_samp?: Maybe<platform_newspaper_subscription_stop_date_var_samp_fields>;
  variance?: Maybe<platform_newspaper_subscription_stop_date_variance_fields>;
};


/** aggregate fields of "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_newspaper_subscription_stop_date_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_newspaper_subscription_stop_date_avg_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_newspaper_subscription_stop_date". All fields are combined with a logical 'AND'. */
export type platform_newspaper_subscription_stop_date_bool_exp = {
  _and?: InputMaybe<Array<platform_newspaper_subscription_stop_date_bool_exp>>;
  _not?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
  _or?: InputMaybe<Array<platform_newspaper_subscription_stop_date_bool_exp>>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_newspaper_subscription_id?: InputMaybe<String_comparison_exp>;
  value_date?: InputMaybe<date_comparison_exp>;
  value_text?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_insert_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_newspaper_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_newspaper_subscription_stop_date_max_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_max_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_newspaper_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_newspaper_subscription_stop_date_min_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_min_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_newspaper_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_mutation_response = {
  __typename: 'platform_newspaper_subscription_stop_date_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_newspaper_subscription_stop_date>;
};

/** input type for inserting object relation for remote table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_obj_rel_insert_input = {
  data: platform_newspaper_subscription_stop_date_insert_input;
};

/** Ordering options when selecting data from "platform_newspaper_subscription_stop_date". */
export type platform_newspaper_subscription_stop_date_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_newspaper_subscription_id?: InputMaybe<order_by>;
  value_date?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
};

/** select columns of table "platform_newspaper_subscription_stop_date" */
export enum platform_newspaper_subscription_stop_date_select_column {
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_newspaper_subscription_id = 'platform_newspaper_subscription_id',
  /** column name */
  value_date = 'value_date',
  /** column name */
  value_text = 'value_text'
}

/** input type for updating data in table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_set_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_newspaper_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_newspaper_subscription_stop_date_stddev_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_newspaper_subscription_stop_date_stddev_pop_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_newspaper_subscription_stop_date_stddev_samp_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_newspaper_subscription_stop_date" */
export type platform_newspaper_subscription_stop_date_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_newspaper_subscription_stop_date_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_newspaper_subscription_stop_date_stream_cursor_value_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_newspaper_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_newspaper_subscription_stop_date_sum_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

export type platform_newspaper_subscription_stop_date_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_newspaper_subscription_stop_date_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_newspaper_subscription_stop_date_set_input>;
  /** filter the rows which have to be updated */
  where: platform_newspaper_subscription_stop_date_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_newspaper_subscription_stop_date_var_pop_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_newspaper_subscription_stop_date_var_samp_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_newspaper_subscription_stop_date_variance_fields = {
  __typename: 'platform_newspaper_subscription_stop_date_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_newspaper_subscription_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_newspaper_subscription_stream_cursor_value_input = {
  current_balance?: InputMaybe<Scalars['numeric']['input']>;
  digital_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  is_digital?: InputMaybe<Scalars['Boolean']['input']>;
  is_ez_pay?: InputMaybe<Scalars['Boolean']['input']>;
  is_print?: InputMaybe<Scalars['Boolean']['input']>;
  last_payment_amount?: InputMaybe<Scalars['numeric']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  paper_name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  print_frequency?: InputMaybe<Scalars['jsonb']['input']>;
  rate_code?: InputMaybe<Scalars['String']['input']>;
  start_source?: InputMaybe<Scalars['String']['input']>;
  stop_date?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_newspaper_subscription_sum_fields = {
  __typename: 'platform_newspaper_subscription_sum_fields';
  current_balance?: Maybe<Scalars['numeric']['output']>;
  last_payment_amount?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_sum_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_newspaper_subscription" */
export enum platform_newspaper_subscription_update_column {
  /** column name */
  current_balance = 'current_balance',
  /** column name */
  digital_frequency = 'digital_frequency',
  /** column name */
  is_digital = 'is_digital',
  /** column name */
  is_ez_pay = 'is_ez_pay',
  /** column name */
  is_print = 'is_print',
  /** column name */
  last_payment_amount = 'last_payment_amount',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  paper_name = 'paper_name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  print_frequency = 'print_frequency',
  /** column name */
  rate_code = 'rate_code',
  /** column name */
  start_source = 'start_source',
  /** column name */
  stop_date = 'stop_date'
}

export type platform_newspaper_subscription_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_newspaper_subscription_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_newspaper_subscription_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_newspaper_subscription_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_newspaper_subscription_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_newspaper_subscription_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_newspaper_subscription_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_newspaper_subscription_set_input>;
  /** filter the rows which have to be updated */
  where: platform_newspaper_subscription_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_newspaper_subscription_var_pop_fields = {
  __typename: 'platform_newspaper_subscription_var_pop_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_var_pop_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_newspaper_subscription_var_samp_fields = {
  __typename: 'platform_newspaper_subscription_var_samp_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_var_samp_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_newspaper_subscription_variance_fields = {
  __typename: 'platform_newspaper_subscription_variance_fields';
  current_balance?: Maybe<Scalars['Float']['output']>;
  last_payment_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_newspaper_subscription" */
export type platform_newspaper_subscription_variance_order_by = {
  current_balance?: InputMaybe<order_by>;
  last_payment_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** input type for inserting object relation for remote table "platform" */
export type platform_obj_rel_insert_input = {
  data: platform_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_on_conflict>;
};

/** on_conflict condition type for table "platform" */
export type platform_on_conflict = {
  constraint: platform_constraint;
  update_columns?: Array<platform_update_column>;
  where?: InputMaybe<platform_bool_exp>;
};

/** Ordering options when selecting data from "platform". */
export type platform_order_by = {
  platform_connections_aggregate?: InputMaybe<platform_connection_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform */
export type platform_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "platform_plan" */
export type platform_plan = {
  __typename: 'platform_plan';
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  currency: platform_currency_enum;
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paper_code?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An array relationship */
  platform_coupon_plans: Array<platform_coupon_plan>;
  /** An aggregate relationship */
  platform_coupon_plans_aggregate: platform_coupon_plan_aggregate;
  /** An object relationship */
  platform_currency: platform_currency;
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_plan_ecommerce_products: Array<platform_plan_ecommerce_product>;
  /** An aggregate relationship */
  platform_plan_ecommerce_products_aggregate: platform_plan_ecommerce_product_aggregate;
  /** An object relationship */
  platform_plan_group?: Maybe<platform_plan_group>;
  platform_plan_group_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_plan_interval: platform_plan_interval;
  /** An object relationship */
  platform_plan_pricing_model: platform_plan_pricing_model;
  /** An array relationship */
  platform_plan_tiers: Array<platform_plan_tier>;
  /** An aggregate relationship */
  platform_plan_tiers_aggregate: platform_plan_tier_aggregate;
  /** An object relationship */
  platform_product?: Maybe<platform_product>;
  platform_product_id: Scalars['String']['output'];
  /** An array relationship */
  platform_subscription_plans: Array<platform_subscription_plan>;
  /** An aggregate relationship */
  platform_subscription_plans_aggregate: platform_subscription_plan_aggregate;
  pricing_model: platform_plan_pricing_model_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_coupon_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_coupon_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_plan_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_plan_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_plan_tiersArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_plan_tiers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_subscription_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


/** columns and relationships of "platform_plan" */
export type platform_planplatform_subscription_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};

/** aggregated selection of "platform_plan" */
export type platform_plan_aggregate = {
  __typename: 'platform_plan_aggregate';
  aggregate?: Maybe<platform_plan_aggregate_fields>;
  nodes: Array<platform_plan>;
};

export type platform_plan_aggregate_bool_exp = {
  count?: InputMaybe<platform_plan_aggregate_bool_exp_count>;
};

export type platform_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_plan" */
export type platform_plan_aggregate_fields = {
  __typename: 'platform_plan_aggregate_fields';
  avg?: Maybe<platform_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_max_fields>;
  min?: Maybe<platform_plan_min_fields>;
  stddev?: Maybe<platform_plan_stddev_fields>;
  stddev_pop?: Maybe<platform_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_plan_stddev_samp_fields>;
  sum?: Maybe<platform_plan_sum_fields>;
  var_pop?: Maybe<platform_plan_var_pop_fields>;
  var_samp?: Maybe<platform_plan_var_samp_fields>;
  variance?: Maybe<platform_plan_variance_fields>;
};


/** aggregate fields of "platform_plan" */
export type platform_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_plan" */
export type platform_plan_aggregate_order_by = {
  avg?: InputMaybe<platform_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_plan_max_order_by>;
  min?: InputMaybe<platform_plan_min_order_by>;
  stddev?: InputMaybe<platform_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_plan_stddev_samp_order_by>;
  sum?: InputMaybe<platform_plan_sum_order_by>;
  var_pop?: InputMaybe<platform_plan_var_pop_order_by>;
  var_samp?: InputMaybe<platform_plan_var_samp_order_by>;
  variance?: InputMaybe<platform_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_plan" */
export type platform_plan_arr_rel_insert_input = {
  data: Array<platform_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_on_conflict>;
};

/** aggregate avg on columns */
export type platform_plan_avg_fields = {
  __typename: 'platform_plan_avg_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_plan" */
export type platform_plan_avg_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_plan". All fields are combined with a logical 'AND'. */
export type platform_plan_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_bool_exp>>;
  _not?: InputMaybe<platform_plan_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_bool_exp>>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  currency?: InputMaybe<platform_currency_enum_comparison_exp>;
  interval?: InputMaybe<platform_plan_interval_enum_comparison_exp>;
  interval_count?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_bool_exp>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_bool_exp>;
  platform_currency?: InputMaybe<platform_currency_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plan_ecommerce_products?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  platform_plan_ecommerce_products_aggregate?: InputMaybe<platform_plan_ecommerce_product_aggregate_bool_exp>;
  platform_plan_group?: InputMaybe<platform_plan_group_bool_exp>;
  platform_plan_group_id?: InputMaybe<String_comparison_exp>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_bool_exp>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_bool_exp>;
  platform_plan_tiers?: InputMaybe<platform_plan_tier_bool_exp>;
  platform_plan_tiers_aggregate?: InputMaybe<platform_plan_tier_aggregate_bool_exp>;
  platform_product?: InputMaybe<platform_product_bool_exp>;
  platform_product_id?: InputMaybe<String_comparison_exp>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_bool_exp>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_bool_exp>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan" */
export enum platform_plan_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_plan_pkey = 'platform_plan_pkey'
}

/** columns and relationships of "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product = {
  __typename: 'platform_plan_ecommerce_product';
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  discount_type?: Maybe<Scalars['String']['output']>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_ecommerce_product?: Maybe<platform_ecommerce_product>;
  platform_ecommerce_product_id: Scalars['String']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id: Scalars['String']['output'];
  primary: Scalars['Boolean']['output'];
};

/** aggregated selection of "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_aggregate = {
  __typename: 'platform_plan_ecommerce_product_aggregate';
  aggregate?: Maybe<platform_plan_ecommerce_product_aggregate_fields>;
  nodes: Array<platform_plan_ecommerce_product>;
};

export type platform_plan_ecommerce_product_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_plan_ecommerce_product_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_plan_ecommerce_product_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_plan_ecommerce_product_aggregate_bool_exp_count>;
};

export type platform_plan_ecommerce_product_aggregate_bool_exp_bool_and = {
  arguments: platform_plan_ecommerce_product_select_column_platform_plan_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_plan_ecommerce_product_aggregate_bool_exp_bool_or = {
  arguments: platform_plan_ecommerce_product_select_column_platform_plan_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_plan_ecommerce_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_aggregate_fields = {
  __typename: 'platform_plan_ecommerce_product_aggregate_fields';
  avg?: Maybe<platform_plan_ecommerce_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_ecommerce_product_max_fields>;
  min?: Maybe<platform_plan_ecommerce_product_min_fields>;
  stddev?: Maybe<platform_plan_ecommerce_product_stddev_fields>;
  stddev_pop?: Maybe<platform_plan_ecommerce_product_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_plan_ecommerce_product_stddev_samp_fields>;
  sum?: Maybe<platform_plan_ecommerce_product_sum_fields>;
  var_pop?: Maybe<platform_plan_ecommerce_product_var_pop_fields>;
  var_samp?: Maybe<platform_plan_ecommerce_product_var_samp_fields>;
  variance?: Maybe<platform_plan_ecommerce_product_variance_fields>;
};


/** aggregate fields of "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_aggregate_order_by = {
  avg?: InputMaybe<platform_plan_ecommerce_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_plan_ecommerce_product_max_order_by>;
  min?: InputMaybe<platform_plan_ecommerce_product_min_order_by>;
  stddev?: InputMaybe<platform_plan_ecommerce_product_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_plan_ecommerce_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_plan_ecommerce_product_stddev_samp_order_by>;
  sum?: InputMaybe<platform_plan_ecommerce_product_sum_order_by>;
  var_pop?: InputMaybe<platform_plan_ecommerce_product_var_pop_order_by>;
  var_samp?: InputMaybe<platform_plan_ecommerce_product_var_samp_order_by>;
  variance?: InputMaybe<platform_plan_ecommerce_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_arr_rel_insert_input = {
  data: Array<platform_plan_ecommerce_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_ecommerce_product_on_conflict>;
};

/** aggregate avg on columns */
export type platform_plan_ecommerce_product_avg_fields = {
  __typename: 'platform_plan_ecommerce_product_avg_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_avg_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_plan_ecommerce_product". All fields are combined with a logical 'AND'. */
export type platform_plan_ecommerce_product_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_ecommerce_product_bool_exp>>;
  _not?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_ecommerce_product_bool_exp>>;
  discount_amount?: InputMaybe<numeric_comparison_exp>;
  discount_type?: InputMaybe<String_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_bool_exp>;
  platform_ecommerce_product_id?: InputMaybe<String_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  primary?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan_ecommerce_product" */
export enum platform_plan_ecommerce_product_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_connection_id", "platform_ecommerce_product_id" */
  platform_plan_ecommerce_product_pkey = 'platform_plan_ecommerce_product_pkey'
}

/** input type for incrementing numeric columns in table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_inc_input = {
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_insert_input = {
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  discount_type?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_obj_rel_insert_input>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type platform_plan_ecommerce_product_max_fields = {
  __typename: 'platform_plan_ecommerce_product_max_fields';
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  discount_type?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_max_order_by = {
  discount_amount?: InputMaybe<order_by>;
  discount_type?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_plan_ecommerce_product_min_fields = {
  __typename: 'platform_plan_ecommerce_product_min_fields';
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  discount_type?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_min_order_by = {
  discount_amount?: InputMaybe<order_by>;
  discount_type?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_mutation_response = {
  __typename: 'platform_plan_ecommerce_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan_ecommerce_product>;
};

/** on_conflict condition type for table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_on_conflict = {
  constraint: platform_plan_ecommerce_product_constraint;
  update_columns?: Array<platform_plan_ecommerce_product_update_column>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan_ecommerce_product". */
export type platform_plan_ecommerce_product_order_by = {
  discount_amount?: InputMaybe<order_by>;
  discount_type?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  primary?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan_ecommerce_product */
export type platform_plan_ecommerce_product_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};

/** select columns of table "platform_plan_ecommerce_product" */
export enum platform_plan_ecommerce_product_select_column {
  /** column name */
  discount_amount = 'discount_amount',
  /** column name */
  discount_type = 'discount_type',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  primary = 'primary'
}

/** select "platform_plan_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_plan_ecommerce_product" */
export enum platform_plan_ecommerce_product_select_column_platform_plan_ecommerce_product_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  primary = 'primary'
}

/** select "platform_plan_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_plan_ecommerce_product" */
export enum platform_plan_ecommerce_product_select_column_platform_plan_ecommerce_product_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  primary = 'primary'
}

/** input type for updating data in table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_set_input = {
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  discount_type?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type platform_plan_ecommerce_product_stddev_fields = {
  __typename: 'platform_plan_ecommerce_product_stddev_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_stddev_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_plan_ecommerce_product_stddev_pop_fields = {
  __typename: 'platform_plan_ecommerce_product_stddev_pop_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_stddev_pop_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_plan_ecommerce_product_stddev_samp_fields = {
  __typename: 'platform_plan_ecommerce_product_stddev_samp_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_stddev_samp_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_ecommerce_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_ecommerce_product_stream_cursor_value_input = {
  discount_amount?: InputMaybe<Scalars['numeric']['input']>;
  discount_type?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type platform_plan_ecommerce_product_sum_fields = {
  __typename: 'platform_plan_ecommerce_product_sum_fields';
  discount_amount?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_sum_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_plan_ecommerce_product" */
export enum platform_plan_ecommerce_product_update_column {
  /** column name */
  discount_amount = 'discount_amount',
  /** column name */
  discount_type = 'discount_type',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  primary = 'primary'
}

export type platform_plan_ecommerce_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_plan_ecommerce_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_ecommerce_product_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_ecommerce_product_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_plan_ecommerce_product_var_pop_fields = {
  __typename: 'platform_plan_ecommerce_product_var_pop_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_var_pop_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_plan_ecommerce_product_var_samp_fields = {
  __typename: 'platform_plan_ecommerce_product_var_samp_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_var_samp_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_plan_ecommerce_product_variance_fields = {
  __typename: 'platform_plan_ecommerce_product_variance_fields';
  discount_amount?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_plan_ecommerce_product" */
export type platform_plan_ecommerce_product_variance_order_by = {
  discount_amount?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_plan_group" */
export type platform_plan_group = {
  __typename: 'platform_plan_group';
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_plan_group" */
export type platform_plan_group_aggregate = {
  __typename: 'platform_plan_group_aggregate';
  aggregate?: Maybe<platform_plan_group_aggregate_fields>;
  nodes: Array<platform_plan_group>;
};

/** aggregate fields of "platform_plan_group" */
export type platform_plan_group_aggregate_fields = {
  __typename: 'platform_plan_group_aggregate_fields';
  avg?: Maybe<platform_plan_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_group_max_fields>;
  min?: Maybe<platform_plan_group_min_fields>;
  stddev?: Maybe<platform_plan_group_stddev_fields>;
  stddev_pop?: Maybe<platform_plan_group_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_plan_group_stddev_samp_fields>;
  sum?: Maybe<platform_plan_group_sum_fields>;
  var_pop?: Maybe<platform_plan_group_var_pop_fields>;
  var_samp?: Maybe<platform_plan_group_var_samp_fields>;
  variance?: Maybe<platform_plan_group_variance_fields>;
};


/** aggregate fields of "platform_plan_group" */
export type platform_plan_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_plan_group_avg_fields = {
  __typename: 'platform_plan_group_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_plan_group". All fields are combined with a logical 'AND'. */
export type platform_plan_group_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_group_bool_exp>>;
  _not?: InputMaybe<platform_plan_group_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_group_bool_exp>>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan_group" */
export enum platform_plan_group_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_plan_group_pkey = 'platform_plan_group_pkey'
}

/** input type for incrementing numeric columns in table "platform_plan_group" */
export type platform_plan_group_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_plan_group" */
export type platform_plan_group_insert_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_plan_group_max_fields = {
  __typename: 'platform_plan_group_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type platform_plan_group_min_fields = {
  __typename: 'platform_plan_group_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "platform_plan_group" */
export type platform_plan_group_mutation_response = {
  __typename: 'platform_plan_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan_group>;
};

/** input type for inserting object relation for remote table "platform_plan_group" */
export type platform_plan_group_obj_rel_insert_input = {
  data: platform_plan_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_group_on_conflict>;
};

/** on_conflict condition type for table "platform_plan_group" */
export type platform_plan_group_on_conflict = {
  constraint: platform_plan_group_constraint;
  update_columns?: Array<platform_plan_group_update_column>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan_group". */
export type platform_plan_group_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan_group */
export type platform_plan_group_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_plan_group" */
export enum platform_plan_group_select_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_plan_group" */
export type platform_plan_group_set_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_plan_group_stddev_fields = {
  __typename: 'platform_plan_group_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_plan_group_stddev_pop_fields = {
  __typename: 'platform_plan_group_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_plan_group_stddev_samp_fields = {
  __typename: 'platform_plan_group_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_plan_group" */
export type platform_plan_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_group_stream_cursor_value_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_plan_group_sum_fields = {
  __typename: 'platform_plan_group_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "platform_plan_group" */
export enum platform_plan_group_update_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_plan_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_plan_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_group_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_group_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_plan_group_var_pop_fields = {
  __typename: 'platform_plan_group_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_plan_group_var_samp_fields = {
  __typename: 'platform_plan_group_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_plan_group_variance_fields = {
  __typename: 'platform_plan_group_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "platform_plan" */
export type platform_plan_inc_input = {
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_plan" */
export type platform_plan_insert_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_plans?: InputMaybe<platform_coupon_plan_arr_rel_insert_input>;
  platform_currency?: InputMaybe<platform_currency_obj_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_ecommerce_products?: InputMaybe<platform_plan_ecommerce_product_arr_rel_insert_input>;
  platform_plan_group?: InputMaybe<platform_plan_group_obj_rel_insert_input>;
  platform_plan_group_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_obj_rel_insert_input>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_obj_rel_insert_input>;
  platform_plan_tiers?: InputMaybe<platform_plan_tier_arr_rel_insert_input>;
  platform_product?: InputMaybe<platform_product_obj_rel_insert_input>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_arr_rel_insert_input>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "platform_plan_interval" */
export type platform_plan_interval = {
  __typename: 'platform_plan_interval';
  /** An array relationship */
  platform_addon_prices: Array<platform_addon_price>;
  /** An aggregate relationship */
  platform_addon_prices_aggregate: platform_addon_price_aggregate;
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_plan_interval" */
export type platform_plan_intervalplatform_addon_pricesArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_plan_interval" */
export type platform_plan_intervalplatform_addon_prices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_plan_interval" */
export type platform_plan_intervalplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_plan_interval" */
export type platform_plan_intervalplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** aggregated selection of "platform_plan_interval" */
export type platform_plan_interval_aggregate = {
  __typename: 'platform_plan_interval_aggregate';
  aggregate?: Maybe<platform_plan_interval_aggregate_fields>;
  nodes: Array<platform_plan_interval>;
};

/** aggregate fields of "platform_plan_interval" */
export type platform_plan_interval_aggregate_fields = {
  __typename: 'platform_plan_interval_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_interval_max_fields>;
  min?: Maybe<platform_plan_interval_min_fields>;
};


/** aggregate fields of "platform_plan_interval" */
export type platform_plan_interval_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_interval_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_plan_interval". All fields are combined with a logical 'AND'. */
export type platform_plan_interval_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_interval_bool_exp>>;
  _not?: InputMaybe<platform_plan_interval_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_interval_bool_exp>>;
  platform_addon_prices?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_bool_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan_interval" */
export enum platform_plan_interval_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_plan_interval_pkey = 'platform_plan_interval_pkey'
}

export enum platform_plan_interval_enum {
  day = 'day',
  month = 'month',
  week = 'week',
  year = 'year'
}

/** Boolean expression to compare columns of type "platform_plan_interval_enum". All fields are combined with logical 'AND'. */
export type platform_plan_interval_enum_comparison_exp = {
  _eq?: InputMaybe<platform_plan_interval_enum>;
  _in?: InputMaybe<Array<platform_plan_interval_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_plan_interval_enum>;
  _nin?: InputMaybe<Array<platform_plan_interval_enum>>;
};

/** input type for inserting data into table "platform_plan_interval" */
export type platform_plan_interval_insert_input = {
  platform_addon_prices?: InputMaybe<platform_addon_price_arr_rel_insert_input>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_plan_interval_max_fields = {
  __typename: 'platform_plan_interval_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_plan_interval_min_fields = {
  __typename: 'platform_plan_interval_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_plan_interval" */
export type platform_plan_interval_mutation_response = {
  __typename: 'platform_plan_interval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan_interval>;
};

/** input type for inserting object relation for remote table "platform_plan_interval" */
export type platform_plan_interval_obj_rel_insert_input = {
  data: platform_plan_interval_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_interval_on_conflict>;
};

/** on_conflict condition type for table "platform_plan_interval" */
export type platform_plan_interval_on_conflict = {
  constraint: platform_plan_interval_constraint;
  update_columns?: Array<platform_plan_interval_update_column>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan_interval". */
export type platform_plan_interval_order_by = {
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan_interval */
export type platform_plan_interval_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_plan_interval" */
export enum platform_plan_interval_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_plan_interval" */
export type platform_plan_interval_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_plan_interval" */
export type platform_plan_interval_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_interval_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_interval_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_plan_interval" */
export enum platform_plan_interval_update_column {
  /** column name */
  value = 'value'
}

export type platform_plan_interval_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_interval_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_interval_bool_exp;
};

/** aggregate max on columns */
export type platform_plan_max_fields = {
  __typename: 'platform_plan_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_plan_group_id?: Maybe<Scalars['String']['output']>;
  platform_product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_plan" */
export type platform_plan_max_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_group_id?: InputMaybe<order_by>;
  platform_product_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_plan_min_fields = {
  __typename: 'platform_plan_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_plan_group_id?: Maybe<Scalars['String']['output']>;
  platform_product_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_plan" */
export type platform_plan_min_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_group_id?: InputMaybe<order_by>;
  platform_product_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_plan" */
export type platform_plan_mutation_response = {
  __typename: 'platform_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan>;
};

/** input type for inserting object relation for remote table "platform_plan" */
export type platform_plan_obj_rel_insert_input = {
  data: platform_plan_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_on_conflict>;
};

/** on_conflict condition type for table "platform_plan" */
export type platform_plan_on_conflict = {
  constraint: platform_plan_constraint;
  update_columns?: Array<platform_plan_update_column>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan". */
export type platform_plan_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  currency?: InputMaybe<order_by>;
  interval?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_plans_aggregate?: InputMaybe<platform_coupon_plan_aggregate_order_by>;
  platform_currency?: InputMaybe<platform_currency_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plan_ecommerce_products_aggregate?: InputMaybe<platform_plan_ecommerce_product_aggregate_order_by>;
  platform_plan_group?: InputMaybe<platform_plan_group_order_by>;
  platform_plan_group_id?: InputMaybe<order_by>;
  platform_plan_interval?: InputMaybe<platform_plan_interval_order_by>;
  platform_plan_pricing_model?: InputMaybe<platform_plan_pricing_model_order_by>;
  platform_plan_tiers_aggregate?: InputMaybe<platform_plan_tier_aggregate_order_by>;
  platform_product?: InputMaybe<platform_product_order_by>;
  platform_product_id?: InputMaybe<order_by>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_order_by>;
  pricing_model?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan */
export type platform_plan_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** columns and relationships of "platform_plan_pricing_model" */
export type platform_plan_pricing_model = {
  __typename: 'platform_plan_pricing_model';
  /** An array relationship */
  platform_addon_prices: Array<platform_addon_price>;
  /** An aggregate relationship */
  platform_addon_prices_aggregate: platform_addon_price_aggregate;
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_plan_pricing_model" */
export type platform_plan_pricing_modelplatform_addon_pricesArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_plan_pricing_model" */
export type platform_plan_pricing_modelplatform_addon_prices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


/** columns and relationships of "platform_plan_pricing_model" */
export type platform_plan_pricing_modelplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_plan_pricing_model" */
export type platform_plan_pricing_modelplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** aggregated selection of "platform_plan_pricing_model" */
export type platform_plan_pricing_model_aggregate = {
  __typename: 'platform_plan_pricing_model_aggregate';
  aggregate?: Maybe<platform_plan_pricing_model_aggregate_fields>;
  nodes: Array<platform_plan_pricing_model>;
};

/** aggregate fields of "platform_plan_pricing_model" */
export type platform_plan_pricing_model_aggregate_fields = {
  __typename: 'platform_plan_pricing_model_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_pricing_model_max_fields>;
  min?: Maybe<platform_plan_pricing_model_min_fields>;
};


/** aggregate fields of "platform_plan_pricing_model" */
export type platform_plan_pricing_model_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_pricing_model_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_plan_pricing_model". All fields are combined with a logical 'AND'. */
export type platform_plan_pricing_model_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_pricing_model_bool_exp>>;
  _not?: InputMaybe<platform_plan_pricing_model_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_pricing_model_bool_exp>>;
  platform_addon_prices?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_bool_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan_pricing_model" */
export enum platform_plan_pricing_model_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_plan_pricing_model_pkey = 'platform_plan_pricing_model_pkey'
}

export enum platform_plan_pricing_model_enum {
  per_unit = 'per_unit',
  stairstep = 'stairstep',
  tiered = 'tiered',
  volume = 'volume'
}

/** Boolean expression to compare columns of type "platform_plan_pricing_model_enum". All fields are combined with logical 'AND'. */
export type platform_plan_pricing_model_enum_comparison_exp = {
  _eq?: InputMaybe<platform_plan_pricing_model_enum>;
  _in?: InputMaybe<Array<platform_plan_pricing_model_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_plan_pricing_model_enum>;
  _nin?: InputMaybe<Array<platform_plan_pricing_model_enum>>;
};

/** input type for inserting data into table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_insert_input = {
  platform_addon_prices?: InputMaybe<platform_addon_price_arr_rel_insert_input>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_plan_pricing_model_max_fields = {
  __typename: 'platform_plan_pricing_model_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_plan_pricing_model_min_fields = {
  __typename: 'platform_plan_pricing_model_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_mutation_response = {
  __typename: 'platform_plan_pricing_model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan_pricing_model>;
};

/** input type for inserting object relation for remote table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_obj_rel_insert_input = {
  data: platform_plan_pricing_model_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_pricing_model_on_conflict>;
};

/** on_conflict condition type for table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_on_conflict = {
  constraint: platform_plan_pricing_model_constraint;
  update_columns?: Array<platform_plan_pricing_model_update_column>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan_pricing_model". */
export type platform_plan_pricing_model_order_by = {
  platform_addon_prices_aggregate?: InputMaybe<platform_addon_price_aggregate_order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan_pricing_model */
export type platform_plan_pricing_model_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_plan_pricing_model" */
export enum platform_plan_pricing_model_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_plan_pricing_model" */
export type platform_plan_pricing_model_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_pricing_model_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_pricing_model_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_plan_pricing_model" */
export enum platform_plan_pricing_model_update_column {
  /** column name */
  value = 'value'
}

export type platform_plan_pricing_model_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_pricing_model_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_pricing_model_bool_exp;
};

/** select columns of table "platform_plan" */
export enum platform_plan_select_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_plan_group_id = 'platform_plan_group_id',
  /** column name */
  platform_product_id = 'platform_product_id',
  /** column name */
  pricing_model = 'pricing_model',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_plan" */
export type platform_plan_set_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_group_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_plan_stddev_fields = {
  __typename: 'platform_plan_stddev_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_plan" */
export type platform_plan_stddev_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_plan_stddev_pop_fields = {
  __typename: 'platform_plan_stddev_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_plan" */
export type platform_plan_stddev_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_plan_stddev_samp_fields = {
  __typename: 'platform_plan_stddev_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_plan" */
export type platform_plan_stddev_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_plan" */
export type platform_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_stream_cursor_value_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<platform_currency_enum>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_group_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_id?: InputMaybe<Scalars['String']['input']>;
  pricing_model?: InputMaybe<platform_plan_pricing_model_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_plan_sum_fields = {
  __typename: 'platform_plan_sum_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_plan" */
export type platform_plan_sum_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_plan_tier" */
export type platform_plan_tier = {
  __typename: 'platform_plan_tier';
  created_at: Scalars['timestamptz']['output'];
  flat_fee: Scalars['numeric']['output'];
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id: Scalars['String']['output'];
  price: Scalars['numeric']['output'];
  up_to: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_plan_tier" */
export type platform_plan_tier_aggregate = {
  __typename: 'platform_plan_tier_aggregate';
  aggregate?: Maybe<platform_plan_tier_aggregate_fields>;
  nodes: Array<platform_plan_tier>;
};

export type platform_plan_tier_aggregate_bool_exp = {
  count?: InputMaybe<platform_plan_tier_aggregate_bool_exp_count>;
};

export type platform_plan_tier_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_plan_tier_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_plan_tier_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_plan_tier" */
export type platform_plan_tier_aggregate_fields = {
  __typename: 'platform_plan_tier_aggregate_fields';
  avg?: Maybe<platform_plan_tier_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_plan_tier_max_fields>;
  min?: Maybe<platform_plan_tier_min_fields>;
  stddev?: Maybe<platform_plan_tier_stddev_fields>;
  stddev_pop?: Maybe<platform_plan_tier_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_plan_tier_stddev_samp_fields>;
  sum?: Maybe<platform_plan_tier_sum_fields>;
  var_pop?: Maybe<platform_plan_tier_var_pop_fields>;
  var_samp?: Maybe<platform_plan_tier_var_samp_fields>;
  variance?: Maybe<platform_plan_tier_variance_fields>;
};


/** aggregate fields of "platform_plan_tier" */
export type platform_plan_tier_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_plan_tier_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_plan_tier" */
export type platform_plan_tier_aggregate_order_by = {
  avg?: InputMaybe<platform_plan_tier_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_plan_tier_max_order_by>;
  min?: InputMaybe<platform_plan_tier_min_order_by>;
  stddev?: InputMaybe<platform_plan_tier_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_plan_tier_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_plan_tier_stddev_samp_order_by>;
  sum?: InputMaybe<platform_plan_tier_sum_order_by>;
  var_pop?: InputMaybe<platform_plan_tier_var_pop_order_by>;
  var_samp?: InputMaybe<platform_plan_tier_var_samp_order_by>;
  variance?: InputMaybe<platform_plan_tier_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_plan_tier" */
export type platform_plan_tier_arr_rel_insert_input = {
  data: Array<platform_plan_tier_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_plan_tier_on_conflict>;
};

/** aggregate avg on columns */
export type platform_plan_tier_avg_fields = {
  __typename: 'platform_plan_tier_avg_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_plan_tier" */
export type platform_plan_tier_avg_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_plan_tier". All fields are combined with a logical 'AND'. */
export type platform_plan_tier_bool_exp = {
  _and?: InputMaybe<Array<platform_plan_tier_bool_exp>>;
  _not?: InputMaybe<platform_plan_tier_bool_exp>;
  _or?: InputMaybe<Array<platform_plan_tier_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flat_fee?: InputMaybe<numeric_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  up_to?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_plan_tier" */
export enum platform_plan_tier_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_connection_id", "up_to" */
  platform_plan_tier_pkey = 'platform_plan_tier_pkey'
}

/** input type for incrementing numeric columns in table "platform_plan_tier" */
export type platform_plan_tier_inc_input = {
  flat_fee?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_plan_tier" */
export type platform_plan_tier_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flat_fee?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_plan_tier_max_fields = {
  __typename: 'platform_plan_tier_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flat_fee?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_plan_tier" */
export type platform_plan_tier_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_plan_tier_min_fields = {
  __typename: 'platform_plan_tier_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flat_fee?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_plan_tier" */
export type platform_plan_tier_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_plan_tier" */
export type platform_plan_tier_mutation_response = {
  __typename: 'platform_plan_tier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_plan_tier>;
};

/** on_conflict condition type for table "platform_plan_tier" */
export type platform_plan_tier_on_conflict = {
  constraint: platform_plan_tier_constraint;
  update_columns?: Array<platform_plan_tier_update_column>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};

/** Ordering options when selecting data from "platform_plan_tier". */
export type platform_plan_tier_order_by = {
  created_at?: InputMaybe<order_by>;
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_plan_tier */
export type platform_plan_tier_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  up_to: Scalars['Int']['input'];
};

/** select columns of table "platform_plan_tier" */
export enum platform_plan_tier_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flat_fee = 'flat_fee',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  price = 'price',
  /** column name */
  up_to = 'up_to',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_plan_tier" */
export type platform_plan_tier_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flat_fee?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_plan_tier_stddev_fields = {
  __typename: 'platform_plan_tier_stddev_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_plan_tier" */
export type platform_plan_tier_stddev_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_plan_tier_stddev_pop_fields = {
  __typename: 'platform_plan_tier_stddev_pop_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_plan_tier" */
export type platform_plan_tier_stddev_pop_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_plan_tier_stddev_samp_fields = {
  __typename: 'platform_plan_tier_stddev_samp_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_plan_tier" */
export type platform_plan_tier_stddev_samp_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_plan_tier" */
export type platform_plan_tier_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_plan_tier_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_plan_tier_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flat_fee?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  up_to?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_plan_tier_sum_fields = {
  __typename: 'platform_plan_tier_sum_fields';
  flat_fee?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  up_to?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_plan_tier" */
export type platform_plan_tier_sum_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** update columns of table "platform_plan_tier" */
export enum platform_plan_tier_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flat_fee = 'flat_fee',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  price = 'price',
  /** column name */
  up_to = 'up_to',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_plan_tier_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_plan_tier_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_tier_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_tier_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_plan_tier_var_pop_fields = {
  __typename: 'platform_plan_tier_var_pop_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_plan_tier" */
export type platform_plan_tier_var_pop_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_plan_tier_var_samp_fields = {
  __typename: 'platform_plan_tier_var_samp_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_plan_tier" */
export type platform_plan_tier_var_samp_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_plan_tier_variance_fields = {
  __typename: 'platform_plan_tier_variance_fields';
  flat_fee?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  up_to?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_plan_tier" */
export type platform_plan_tier_variance_order_by = {
  flat_fee?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  up_to?: InputMaybe<order_by>;
};

/** update columns of table "platform_plan" */
export enum platform_plan_update_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  currency = 'currency',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_plan_group_id = 'platform_plan_group_id',
  /** column name */
  platform_product_id = 'platform_product_id',
  /** column name */
  pricing_model = 'pricing_model',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_plan_set_input>;
  /** filter the rows which have to be updated */
  where: platform_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_plan_var_pop_fields = {
  __typename: 'platform_plan_var_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_plan" */
export type platform_plan_var_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_plan_var_samp_fields = {
  __typename: 'platform_plan_var_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_plan" */
export type platform_plan_var_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_plan_variance_fields = {
  __typename: 'platform_plan_variance_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_plan" */
export type platform_plan_variance_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_product" */
export type platform_product = {
  __typename: 'platform_product';
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  paper_code?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_plans: Array<platform_plan>;
  /** An aggregate relationship */
  platform_plans_aggregate: platform_plan_aggregate;
  /** An object relationship */
  platform_product_group?: Maybe<platform_product_group>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_product" */
export type platform_productplatform_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


/** columns and relationships of "platform_product" */
export type platform_productplatform_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};

/** aggregated selection of "platform_product" */
export type platform_product_aggregate = {
  __typename: 'platform_product_aggregate';
  aggregate?: Maybe<platform_product_aggregate_fields>;
  nodes: Array<platform_product>;
};

export type platform_product_aggregate_bool_exp = {
  count?: InputMaybe<platform_product_aggregate_bool_exp_count>;
};

export type platform_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_product" */
export type platform_product_aggregate_fields = {
  __typename: 'platform_product_aggregate_fields';
  avg?: Maybe<platform_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_product_max_fields>;
  min?: Maybe<platform_product_min_fields>;
  stddev?: Maybe<platform_product_stddev_fields>;
  stddev_pop?: Maybe<platform_product_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_product_stddev_samp_fields>;
  sum?: Maybe<platform_product_sum_fields>;
  var_pop?: Maybe<platform_product_var_pop_fields>;
  var_samp?: Maybe<platform_product_var_samp_fields>;
  variance?: Maybe<platform_product_variance_fields>;
};


/** aggregate fields of "platform_product" */
export type platform_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_product" */
export type platform_product_aggregate_order_by = {
  avg?: InputMaybe<platform_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_product_max_order_by>;
  min?: InputMaybe<platform_product_min_order_by>;
  stddev?: InputMaybe<platform_product_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_product_stddev_samp_order_by>;
  sum?: InputMaybe<platform_product_sum_order_by>;
  var_pop?: InputMaybe<platform_product_var_pop_order_by>;
  var_samp?: InputMaybe<platform_product_var_samp_order_by>;
  variance?: InputMaybe<platform_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_product" */
export type platform_product_arr_rel_insert_input = {
  data: Array<platform_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_product_on_conflict>;
};

/** aggregate avg on columns */
export type platform_product_avg_fields = {
  __typename: 'platform_product_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_product" */
export type platform_product_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_product". All fields are combined with a logical 'AND'. */
export type platform_product_bool_exp = {
  _and?: InputMaybe<Array<platform_product_bool_exp>>;
  _not?: InputMaybe<platform_product_bool_exp>;
  _or?: InputMaybe<Array<platform_product_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_plans?: InputMaybe<platform_plan_bool_exp>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_bool_exp>;
  platform_product_group?: InputMaybe<platform_product_group_bool_exp>;
  platform_product_group_id?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_product" */
export enum platform_product_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_product_pkey = 'platform_product_pkey'
}

/** columns and relationships of "platform_product_group" */
export type platform_product_group = {
  __typename: 'platform_product_group';
  archived: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  platform_addons: Array<platform_addon>;
  /** An aggregate relationship */
  platform_addons_aggregate: platform_addon_aggregate;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An array relationship */
  platform_coupons: Array<platform_coupon>;
  /** An aggregate relationship */
  platform_coupons_aggregate: platform_coupon_aggregate;
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_products: Array<platform_product>;
  /** An aggregate relationship */
  platform_products_aggregate: platform_product_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_couponsArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_coupons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


/** columns and relationships of "platform_product_group" */
export type platform_product_groupplatform_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};

/** aggregated selection of "platform_product_group" */
export type platform_product_group_aggregate = {
  __typename: 'platform_product_group_aggregate';
  aggregate?: Maybe<platform_product_group_aggregate_fields>;
  nodes: Array<platform_product_group>;
};

export type platform_product_group_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_product_group_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_product_group_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_product_group_aggregate_bool_exp_count>;
};

export type platform_product_group_aggregate_bool_exp_bool_and = {
  arguments: platform_product_group_select_column_platform_product_group_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_product_group_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_product_group_aggregate_bool_exp_bool_or = {
  arguments: platform_product_group_select_column_platform_product_group_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_product_group_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_product_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_product_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_product_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_product_group" */
export type platform_product_group_aggregate_fields = {
  __typename: 'platform_product_group_aggregate_fields';
  avg?: Maybe<platform_product_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_product_group_max_fields>;
  min?: Maybe<platform_product_group_min_fields>;
  stddev?: Maybe<platform_product_group_stddev_fields>;
  stddev_pop?: Maybe<platform_product_group_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_product_group_stddev_samp_fields>;
  sum?: Maybe<platform_product_group_sum_fields>;
  var_pop?: Maybe<platform_product_group_var_pop_fields>;
  var_samp?: Maybe<platform_product_group_var_samp_fields>;
  variance?: Maybe<platform_product_group_variance_fields>;
};


/** aggregate fields of "platform_product_group" */
export type platform_product_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_product_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_product_group" */
export type platform_product_group_aggregate_order_by = {
  avg?: InputMaybe<platform_product_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_product_group_max_order_by>;
  min?: InputMaybe<platform_product_group_min_order_by>;
  stddev?: InputMaybe<platform_product_group_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_product_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_product_group_stddev_samp_order_by>;
  sum?: InputMaybe<platform_product_group_sum_order_by>;
  var_pop?: InputMaybe<platform_product_group_var_pop_order_by>;
  var_samp?: InputMaybe<platform_product_group_var_samp_order_by>;
  variance?: InputMaybe<platform_product_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_product_group" */
export type platform_product_group_arr_rel_insert_input = {
  data: Array<platform_product_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_product_group_on_conflict>;
};

/** aggregate avg on columns */
export type platform_product_group_avg_fields = {
  __typename: 'platform_product_group_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_product_group" */
export type platform_product_group_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_product_group". All fields are combined with a logical 'AND'. */
export type platform_product_group_bool_exp = {
  _and?: InputMaybe<Array<platform_product_group_bool_exp>>;
  _not?: InputMaybe<platform_product_group_bool_exp>;
  _or?: InputMaybe<Array<platform_product_group_bool_exp>>;
  archived?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  platform_addons?: InputMaybe<platform_addon_bool_exp>;
  platform_addons_aggregate?: InputMaybe<platform_addon_aggregate_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupons?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_products?: InputMaybe<platform_product_bool_exp>;
  platform_products_aggregate?: InputMaybe<platform_product_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_product_group" */
export enum platform_product_group_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_product_group_pkey = 'platform_product_group_pkey'
}

/** input type for incrementing numeric columns in table "platform_product_group" */
export type platform_product_group_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_product_group" */
export type platform_product_group_insert_input = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_addons?: InputMaybe<platform_addon_arr_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupons?: InputMaybe<platform_coupon_arr_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_products?: InputMaybe<platform_product_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_product_group_max_fields = {
  __typename: 'platform_product_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_product_group" */
export type platform_product_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_product_group_min_fields = {
  __typename: 'platform_product_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_product_group" */
export type platform_product_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_product_group" */
export type platform_product_group_mutation_response = {
  __typename: 'platform_product_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_product_group>;
};

/** input type for inserting object relation for remote table "platform_product_group" */
export type platform_product_group_obj_rel_insert_input = {
  data: platform_product_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_product_group_on_conflict>;
};

/** on_conflict condition type for table "platform_product_group" */
export type platform_product_group_on_conflict = {
  constraint: platform_product_group_constraint;
  update_columns?: Array<platform_product_group_update_column>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};

/** Ordering options when selecting data from "platform_product_group". */
export type platform_product_group_order_by = {
  archived?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_addons_aggregate?: InputMaybe<platform_addon_aggregate_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupons_aggregate?: InputMaybe<platform_coupon_aggregate_order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_products_aggregate?: InputMaybe<platform_product_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_product_group */
export type platform_product_group_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_product_group" */
export enum platform_product_group_select_column {
  /** column name */
  archived = 'archived',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_product_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_product_group" */
export enum platform_product_group_select_column_platform_product_group_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  archived = 'archived'
}

/** select "platform_product_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_product_group" */
export enum platform_product_group_select_column_platform_product_group_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  archived = 'archived'
}

/** input type for updating data in table "platform_product_group" */
export type platform_product_group_set_input = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_product_group_stddev_fields = {
  __typename: 'platform_product_group_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_product_group" */
export type platform_product_group_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_product_group_stddev_pop_fields = {
  __typename: 'platform_product_group_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_product_group" */
export type platform_product_group_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_product_group_stddev_samp_fields = {
  __typename: 'platform_product_group_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_product_group" */
export type platform_product_group_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_product_group" */
export type platform_product_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_product_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_product_group_stream_cursor_value_input = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_product_group_sum_fields = {
  __typename: 'platform_product_group_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_product_group" */
export type platform_product_group_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_product_group" */
export enum platform_product_group_update_column {
  /** column name */
  archived = 'archived',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_product_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_product_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_product_group_set_input>;
  /** filter the rows which have to be updated */
  where: platform_product_group_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_product_group_var_pop_fields = {
  __typename: 'platform_product_group_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_product_group" */
export type platform_product_group_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_product_group_var_samp_fields = {
  __typename: 'platform_product_group_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_product_group" */
export type platform_product_group_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_product_group_variance_fields = {
  __typename: 'platform_product_group_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_product_group" */
export type platform_product_group_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "platform_product" */
export type platform_product_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_product" */
export type platform_product_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_plans?: InputMaybe<platform_plan_arr_rel_insert_input>;
  platform_product_group?: InputMaybe<platform_product_group_obj_rel_insert_input>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_product_max_fields = {
  __typename: 'platform_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_product" */
export type platform_product_max_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_product_min_fields = {
  __typename: 'platform_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_product_group_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_product" */
export type platform_product_min_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_product" */
export type platform_product_mutation_response = {
  __typename: 'platform_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_product>;
};

/** input type for inserting object relation for remote table "platform_product" */
export type platform_product_obj_rel_insert_input = {
  data: platform_product_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_product_on_conflict>;
};

/** on_conflict condition type for table "platform_product" */
export type platform_product_on_conflict = {
  constraint: platform_product_constraint;
  update_columns?: Array<platform_product_update_column>;
  where?: InputMaybe<platform_product_bool_exp>;
};

/** Ordering options when selecting data from "platform_product". */
export type platform_product_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_plans_aggregate?: InputMaybe<platform_plan_aggregate_order_by>;
  platform_product_group?: InputMaybe<platform_product_group_order_by>;
  platform_product_group_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_product */
export type platform_product_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_product" */
export enum platform_product_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_product" */
export type platform_product_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_product_stddev_fields = {
  __typename: 'platform_product_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_product" */
export type platform_product_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_product_stddev_pop_fields = {
  __typename: 'platform_product_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_product" */
export type platform_product_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_product_stddev_samp_fields = {
  __typename: 'platform_product_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_product" */
export type platform_product_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_product" */
export type platform_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_product_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_product_group_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_product_sum_fields = {
  __typename: 'platform_product_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_product" */
export type platform_product_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_product" */
export enum platform_product_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_product_group_id = 'platform_product_group_id',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_product_set_input>;
  /** filter the rows which have to be updated */
  where: platform_product_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_product_var_pop_fields = {
  __typename: 'platform_product_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_product" */
export type platform_product_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_product_var_samp_fields = {
  __typename: 'platform_product_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_product" */
export type platform_product_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_product_variance_fields = {
  __typename: 'platform_product_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_product" */
export type platform_product_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_raw_object" */
export type platform_raw_object = {
  __typename: 'platform_raw_object';
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  type: platform_raw_object_type_enum;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "platform_raw_object" */
export type platform_raw_objectdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "platform_raw_object" */
export type platform_raw_object_aggregate = {
  __typename: 'platform_raw_object_aggregate';
  aggregate?: Maybe<platform_raw_object_aggregate_fields>;
  nodes: Array<platform_raw_object>;
};

/** aggregate fields of "platform_raw_object" */
export type platform_raw_object_aggregate_fields = {
  __typename: 'platform_raw_object_aggregate_fields';
  avg?: Maybe<platform_raw_object_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_raw_object_max_fields>;
  min?: Maybe<platform_raw_object_min_fields>;
  stddev?: Maybe<platform_raw_object_stddev_fields>;
  stddev_pop?: Maybe<platform_raw_object_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_raw_object_stddev_samp_fields>;
  sum?: Maybe<platform_raw_object_sum_fields>;
  var_pop?: Maybe<platform_raw_object_var_pop_fields>;
  var_samp?: Maybe<platform_raw_object_var_samp_fields>;
  variance?: Maybe<platform_raw_object_variance_fields>;
};


/** aggregate fields of "platform_raw_object" */
export type platform_raw_object_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_raw_object_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_raw_object_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type platform_raw_object_avg_fields = {
  __typename: 'platform_raw_object_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_raw_object". All fields are combined with a logical 'AND'. */
export type platform_raw_object_bool_exp = {
  _and?: InputMaybe<Array<platform_raw_object_bool_exp>>;
  _not?: InputMaybe<platform_raw_object_bool_exp>;
  _or?: InputMaybe<Array<platform_raw_object_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<platform_raw_object_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_raw_object" */
export enum platform_raw_object_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id", "type" */
  platform_raw_object_pkey = 'platform_raw_object_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_raw_object_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_raw_object_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_raw_object_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_raw_object" */
export type platform_raw_object_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_raw_object" */
export type platform_raw_object_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<platform_raw_object_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_raw_object_max_fields = {
  __typename: 'platform_raw_object_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type platform_raw_object_min_fields = {
  __typename: 'platform_raw_object_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "platform_raw_object" */
export type platform_raw_object_mutation_response = {
  __typename: 'platform_raw_object_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_raw_object>;
};

/** on_conflict condition type for table "platform_raw_object" */
export type platform_raw_object_on_conflict = {
  constraint: platform_raw_object_constraint;
  update_columns?: Array<platform_raw_object_update_column>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};

/** Ordering options when selecting data from "platform_raw_object". */
export type platform_raw_object_order_by = {
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_raw_object */
export type platform_raw_object_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
  type: platform_raw_object_type_enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_raw_object_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_raw_object" */
export enum platform_raw_object_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_raw_object" */
export type platform_raw_object_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<platform_raw_object_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_raw_object_stddev_fields = {
  __typename: 'platform_raw_object_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_raw_object_stddev_pop_fields = {
  __typename: 'platform_raw_object_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_raw_object_stddev_samp_fields = {
  __typename: 'platform_raw_object_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_raw_object" */
export type platform_raw_object_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_raw_object_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_raw_object_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<platform_raw_object_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_raw_object_sum_fields = {
  __typename: 'platform_raw_object_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "platform_raw_object_type" */
export type platform_raw_object_type = {
  __typename: 'platform_raw_object_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "platform_raw_object_type" */
export type platform_raw_object_type_aggregate = {
  __typename: 'platform_raw_object_type_aggregate';
  aggregate?: Maybe<platform_raw_object_type_aggregate_fields>;
  nodes: Array<platform_raw_object_type>;
};

/** aggregate fields of "platform_raw_object_type" */
export type platform_raw_object_type_aggregate_fields = {
  __typename: 'platform_raw_object_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_raw_object_type_max_fields>;
  min?: Maybe<platform_raw_object_type_min_fields>;
};


/** aggregate fields of "platform_raw_object_type" */
export type platform_raw_object_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_raw_object_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_raw_object_type". All fields are combined with a logical 'AND'. */
export type platform_raw_object_type_bool_exp = {
  _and?: InputMaybe<Array<platform_raw_object_type_bool_exp>>;
  _not?: InputMaybe<platform_raw_object_type_bool_exp>;
  _or?: InputMaybe<Array<platform_raw_object_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_raw_object_type" */
export enum platform_raw_object_type_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_raw_object_type_pkey = 'platform_raw_object_type_pkey'
}

export enum platform_raw_object_type_enum {
  addon = 'addon',
  cancel_reason = 'cancel_reason',
  coupon = 'coupon',
  customer = 'customer',
  ecommerce_product = 'ecommerce_product',
  invoice = 'invoice',
  plan = 'plan',
  plan_group = 'plan_group',
  product = 'product',
  product_group = 'product_group',
  subscription = 'subscription',
  variant = 'variant'
}

/** Boolean expression to compare columns of type "platform_raw_object_type_enum". All fields are combined with logical 'AND'. */
export type platform_raw_object_type_enum_comparison_exp = {
  _eq?: InputMaybe<platform_raw_object_type_enum>;
  _in?: InputMaybe<Array<platform_raw_object_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_raw_object_type_enum>;
  _nin?: InputMaybe<Array<platform_raw_object_type_enum>>;
};

/** input type for inserting data into table "platform_raw_object_type" */
export type platform_raw_object_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_raw_object_type_max_fields = {
  __typename: 'platform_raw_object_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_raw_object_type_min_fields = {
  __typename: 'platform_raw_object_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_raw_object_type" */
export type platform_raw_object_type_mutation_response = {
  __typename: 'platform_raw_object_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_raw_object_type>;
};

/** on_conflict condition type for table "platform_raw_object_type" */
export type platform_raw_object_type_on_conflict = {
  constraint: platform_raw_object_type_constraint;
  update_columns?: Array<platform_raw_object_type_update_column>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};

/** Ordering options when selecting data from "platform_raw_object_type". */
export type platform_raw_object_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_raw_object_type */
export type platform_raw_object_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_raw_object_type" */
export enum platform_raw_object_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_raw_object_type" */
export type platform_raw_object_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_raw_object_type" */
export type platform_raw_object_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_raw_object_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_raw_object_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_raw_object_type" */
export enum platform_raw_object_type_update_column {
  /** column name */
  value = 'value'
}

export type platform_raw_object_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_raw_object_type_set_input>;
  /** filter the rows which have to be updated */
  where: platform_raw_object_type_bool_exp;
};

/** update columns of table "platform_raw_object" */
export enum platform_raw_object_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_raw_object_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_raw_object_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_raw_object_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_raw_object_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_raw_object_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_raw_object_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_raw_object_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_raw_object_set_input>;
  /** filter the rows which have to be updated */
  where: platform_raw_object_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_raw_object_var_pop_fields = {
  __typename: 'platform_raw_object_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_raw_object_var_samp_fields = {
  __typename: 'platform_raw_object_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_raw_object_variance_fields = {
  __typename: 'platform_raw_object_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "platform_refund" */
export type platform_refund = {
  __typename: 'platform_refund';
  amount_refunded: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  issued_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_invoice?: Maybe<platform_invoice>;
  platform_invoice_id: Scalars['String']['output'];
};

/** aggregated selection of "platform_refund" */
export type platform_refund_aggregate = {
  __typename: 'platform_refund_aggregate';
  aggregate?: Maybe<platform_refund_aggregate_fields>;
  nodes: Array<platform_refund>;
};

export type platform_refund_aggregate_bool_exp = {
  count?: InputMaybe<platform_refund_aggregate_bool_exp_count>;
};

export type platform_refund_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_refund_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_refund_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_refund" */
export type platform_refund_aggregate_fields = {
  __typename: 'platform_refund_aggregate_fields';
  avg?: Maybe<platform_refund_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_refund_max_fields>;
  min?: Maybe<platform_refund_min_fields>;
  stddev?: Maybe<platform_refund_stddev_fields>;
  stddev_pop?: Maybe<platform_refund_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_refund_stddev_samp_fields>;
  sum?: Maybe<platform_refund_sum_fields>;
  var_pop?: Maybe<platform_refund_var_pop_fields>;
  var_samp?: Maybe<platform_refund_var_samp_fields>;
  variance?: Maybe<platform_refund_variance_fields>;
};


/** aggregate fields of "platform_refund" */
export type platform_refund_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_refund_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_refund" */
export type platform_refund_aggregate_order_by = {
  avg?: InputMaybe<platform_refund_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_refund_max_order_by>;
  min?: InputMaybe<platform_refund_min_order_by>;
  stddev?: InputMaybe<platform_refund_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_refund_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_refund_stddev_samp_order_by>;
  sum?: InputMaybe<platform_refund_sum_order_by>;
  var_pop?: InputMaybe<platform_refund_var_pop_order_by>;
  var_samp?: InputMaybe<platform_refund_var_samp_order_by>;
  variance?: InputMaybe<platform_refund_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_refund" */
export type platform_refund_arr_rel_insert_input = {
  data: Array<platform_refund_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_refund_on_conflict>;
};

/** aggregate avg on columns */
export type platform_refund_avg_fields = {
  __typename: 'platform_refund_avg_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_refund" */
export type platform_refund_avg_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_refund". All fields are combined with a logical 'AND'. */
export type platform_refund_bool_exp = {
  _and?: InputMaybe<Array<platform_refund_bool_exp>>;
  _not?: InputMaybe<platform_refund_bool_exp>;
  _or?: InputMaybe<Array<platform_refund_bool_exp>>;
  amount_refunded?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  issued_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_invoice?: InputMaybe<platform_invoice_bool_exp>;
  platform_invoice_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_refund" */
export enum platform_refund_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_refund_pkey = 'platform_refund_pkey'
}

/** input type for incrementing numeric columns in table "platform_refund" */
export type platform_refund_inc_input = {
  amount_refunded?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_refund" */
export type platform_refund_insert_input = {
  amount_refunded?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  issued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoice?: InputMaybe<platform_invoice_obj_rel_insert_input>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_refund_max_fields = {
  __typename: 'platform_refund_max_fields';
  amount_refunded?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  issued_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_invoice_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_refund" */
export type platform_refund_max_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  issued_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_refund_min_fields = {
  __typename: 'platform_refund_min_fields';
  amount_refunded?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  issued_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  platform_invoice_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_refund" */
export type platform_refund_min_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  issued_at?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_refund" */
export type platform_refund_mutation_response = {
  __typename: 'platform_refund_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_refund>;
};

/** on_conflict condition type for table "platform_refund" */
export type platform_refund_on_conflict = {
  constraint: platform_refund_constraint;
  update_columns?: Array<platform_refund_update_column>;
  where?: InputMaybe<platform_refund_bool_exp>;
};

/** Ordering options when selecting data from "platform_refund". */
export type platform_refund_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  issued_at?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoice?: InputMaybe<platform_invoice_order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_refund */
export type platform_refund_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_refund" */
export enum platform_refund_select_column {
  /** column name */
  amount_refunded = 'amount_refunded',
  /** column name */
  created_at = 'created_at',
  /** column name */
  issued_at = 'issued_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_invoice_id = 'platform_invoice_id'
}

/** input type for updating data in table "platform_refund" */
export type platform_refund_set_input = {
  amount_refunded?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  issued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_refund_stddev_fields = {
  __typename: 'platform_refund_stddev_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_refund" */
export type platform_refund_stddev_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_refund_stddev_pop_fields = {
  __typename: 'platform_refund_stddev_pop_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_refund" */
export type platform_refund_stddev_pop_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_refund_stddev_samp_fields = {
  __typename: 'platform_refund_stddev_samp_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_refund" */
export type platform_refund_stddev_samp_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_refund" */
export type platform_refund_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_refund_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_refund_stream_cursor_value_input = {
  amount_refunded?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  issued_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_refund_sum_fields = {
  __typename: 'platform_refund_sum_fields';
  amount_refunded?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_refund" */
export type platform_refund_sum_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_refund" */
export enum platform_refund_update_column {
  /** column name */
  amount_refunded = 'amount_refunded',
  /** column name */
  created_at = 'created_at',
  /** column name */
  issued_at = 'issued_at',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  platform_invoice_id = 'platform_invoice_id'
}

export type platform_refund_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_refund_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_refund_set_input>;
  /** filter the rows which have to be updated */
  where: platform_refund_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_refund_var_pop_fields = {
  __typename: 'platform_refund_var_pop_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_refund" */
export type platform_refund_var_pop_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_refund_var_samp_fields = {
  __typename: 'platform_refund_var_samp_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_refund" */
export type platform_refund_var_samp_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_refund_variance_fields = {
  __typename: 'platform_refund_variance_fields';
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_refund" */
export type platform_refund_variance_order_by = {
  amount_refunded?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** select columns of table "platform" */
export enum platform_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform" */
export type platform_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform" */
export type platform_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "platform_subscription" */
export type platform_subscription = {
  __typename: 'platform_subscription';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  can_cancel: Scalars['Boolean']['output'];
  can_pause: Scalars['Boolean']['output'];
  cancel_at_period_end?: Maybe<Scalars['Boolean']['output']>;
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  imported: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_billing_at?: Maybe<Scalars['timestamptz']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  past_due?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_coupon?: Maybe<platform_coupon>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_customer?: Maybe<platform_customer>;
  platform_customer_id: Scalars['String']['output'];
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_invoices: Array<platform_invoice>;
  /** An aggregate relationship */
  platform_invoices_aggregate: platform_invoice_aggregate;
  /** An object relationship */
  platform_newspaper_subscription?: Maybe<platform_newspaper_subscription>;
  /** An array relationship */
  platform_newspaper_subscriptions: Array<platform_newspaper_subscription>;
  /** An aggregate relationship */
  platform_newspaper_subscriptions_aggregate: platform_newspaper_subscription_aggregate;
  /** An array relationship */
  platform_subscription_addons: Array<platform_subscription_addon>;
  /** An aggregate relationship */
  platform_subscription_addons_aggregate: platform_subscription_addon_aggregate;
  /** An object relationship */
  platform_subscription_next_billing_at?: Maybe<platform_subscription_next_billing_at>;
  /** An array relationship */
  platform_subscription_plans: Array<platform_subscription_plan>;
  /** An aggregate relationship */
  platform_subscription_plans_aggregate: platform_subscription_plan_aggregate;
  /** An object relationship */
  platform_subscription_status: platform_subscription_status;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  started_at: Scalars['timestamptz']['output'];
  status: platform_subscription_status_enum;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  /** An array relationship */
  swappable_ecommerce_products: Array<platform_subscription_plan_swappable_ecommerce_product>;
  /** An aggregate relationship */
  swappable_ecommerce_products_aggregate: platform_subscription_plan_swappable_ecommerce_product_aggregate;
  trial_end?: Maybe<Scalars['timestamptz']['output']>;
  trial_start?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionmetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_invoicesArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_invoices_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_newspaper_subscriptionsArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_newspaper_subscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_subscription_addonsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_subscription_addons_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_subscription_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionplatform_subscription_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionswappable_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_subscription" */
export type platform_subscriptionswappable_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};

/** columns and relationships of "platform_subscription_addon" */
export type platform_subscription_addon = {
  __typename: 'platform_subscription_addon';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  platform_addon?: Maybe<platform_addon>;
  platform_addon_id: Scalars['String']['output'];
  /** An object relationship */
  platform_addon_price?: Maybe<platform_addon_price>;
  platform_addon_price_id: Scalars['String']['output'];
  /** An object relationship */
  platform_connection: platform_connection;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  platform_subscription_id: Scalars['String']['output'];
  price?: Maybe<Scalars['numeric']['output']>;
  quantity: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "platform_subscription_addon" */
export type platform_subscription_addon_aggregate = {
  __typename: 'platform_subscription_addon_aggregate';
  aggregate?: Maybe<platform_subscription_addon_aggregate_fields>;
  nodes: Array<platform_subscription_addon>;
};

export type platform_subscription_addon_aggregate_bool_exp = {
  count?: InputMaybe<platform_subscription_addon_aggregate_bool_exp_count>;
};

export type platform_subscription_addon_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_addon_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_subscription_addon" */
export type platform_subscription_addon_aggregate_fields = {
  __typename: 'platform_subscription_addon_aggregate_fields';
  avg?: Maybe<platform_subscription_addon_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_addon_max_fields>;
  min?: Maybe<platform_subscription_addon_min_fields>;
  stddev?: Maybe<platform_subscription_addon_stddev_fields>;
  stddev_pop?: Maybe<platform_subscription_addon_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_subscription_addon_stddev_samp_fields>;
  sum?: Maybe<platform_subscription_addon_sum_fields>;
  var_pop?: Maybe<platform_subscription_addon_var_pop_fields>;
  var_samp?: Maybe<platform_subscription_addon_var_samp_fields>;
  variance?: Maybe<platform_subscription_addon_variance_fields>;
};


/** aggregate fields of "platform_subscription_addon" */
export type platform_subscription_addon_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_subscription_addon" */
export type platform_subscription_addon_aggregate_order_by = {
  avg?: InputMaybe<platform_subscription_addon_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_subscription_addon_max_order_by>;
  min?: InputMaybe<platform_subscription_addon_min_order_by>;
  stddev?: InputMaybe<platform_subscription_addon_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_subscription_addon_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_subscription_addon_stddev_samp_order_by>;
  sum?: InputMaybe<platform_subscription_addon_sum_order_by>;
  var_pop?: InputMaybe<platform_subscription_addon_var_pop_order_by>;
  var_samp?: InputMaybe<platform_subscription_addon_var_samp_order_by>;
  variance?: InputMaybe<platform_subscription_addon_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_subscription_addon" */
export type platform_subscription_addon_arr_rel_insert_input = {
  data: Array<platform_subscription_addon_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_addon_on_conflict>;
};

/** aggregate avg on columns */
export type platform_subscription_addon_avg_fields = {
  __typename: 'platform_subscription_addon_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_subscription_addon". All fields are combined with a logical 'AND'. */
export type platform_subscription_addon_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_addon_bool_exp>>;
  _not?: InputMaybe<platform_subscription_addon_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_addon_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  platform_addon?: InputMaybe<platform_addon_bool_exp>;
  platform_addon_id?: InputMaybe<String_comparison_exp>;
  platform_addon_price?: InputMaybe<platform_addon_price_bool_exp>;
  platform_addon_price_id?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  quantity?: InputMaybe<numeric_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_subscription_addon" */
export enum platform_subscription_addon_constraint {
  /** unique or primary key constraint on columns "platform_subscription_id", "platform_connection_id", "platform_addon_id" */
  platform_subscription_addon_pkey = 'platform_subscription_addon_pkey'
}

/** input type for incrementing numeric columns in table "platform_subscription_addon" */
export type platform_subscription_addon_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_subscription_addon" */
export type platform_subscription_addon_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon?: InputMaybe<platform_addon_obj_rel_insert_input>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_addon_price?: InputMaybe<platform_addon_price_obj_rel_insert_input>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_subscription_addon_max_fields = {
  __typename: 'platform_subscription_addon_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_addon_price_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_max_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_subscription_addon_min_fields = {
  __typename: 'platform_subscription_addon_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  platform_addon_id?: Maybe<Scalars['String']['output']>;
  platform_addon_price_id?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_min_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_subscription_addon" */
export type platform_subscription_addon_mutation_response = {
  __typename: 'platform_subscription_addon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription_addon>;
};

/** on_conflict condition type for table "platform_subscription_addon" */
export type platform_subscription_addon_on_conflict = {
  constraint: platform_subscription_addon_constraint;
  update_columns?: Array<platform_subscription_addon_update_column>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};

/** Ordering options when selecting data from "platform_subscription_addon". */
export type platform_subscription_addon_order_by = {
  created_at?: InputMaybe<order_by>;
  platform_addon?: InputMaybe<platform_addon_order_by>;
  platform_addon_id?: InputMaybe<order_by>;
  platform_addon_price?: InputMaybe<platform_addon_price_order_by>;
  platform_addon_price_id?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_subscription_addon */
export type platform_subscription_addon_pk_columns_input = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_subscription_id: Scalars['String']['input'];
};

/** select columns of table "platform_subscription_addon" */
export enum platform_subscription_addon_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_addon_price_id = 'platform_addon_price_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  price = 'price',
  /** column name */
  quantity = 'quantity',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_subscription_addon" */
export type platform_subscription_addon_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_subscription_addon_stddev_fields = {
  __typename: 'platform_subscription_addon_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_subscription_addon_stddev_pop_fields = {
  __typename: 'platform_subscription_addon_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_subscription_addon_stddev_samp_fields = {
  __typename: 'platform_subscription_addon_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_subscription_addon" */
export type platform_subscription_addon_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_addon_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_addon_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  platform_addon_id?: InputMaybe<Scalars['String']['input']>;
  platform_addon_price_id?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_subscription_addon_sum_fields = {
  __typename: 'platform_subscription_addon_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** update columns of table "platform_subscription_addon" */
export enum platform_subscription_addon_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  platform_addon_id = 'platform_addon_id',
  /** column name */
  platform_addon_price_id = 'platform_addon_price_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  price = 'price',
  /** column name */
  quantity = 'quantity',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_subscription_addon_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_subscription_addon_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_addon_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_addon_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_subscription_addon_var_pop_fields = {
  __typename: 'platform_subscription_addon_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_subscription_addon_var_samp_fields = {
  __typename: 'platform_subscription_addon_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_subscription_addon_variance_fields = {
  __typename: 'platform_subscription_addon_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_subscription_addon" */
export type platform_subscription_addon_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregated selection of "platform_subscription" */
export type platform_subscription_aggregate = {
  __typename: 'platform_subscription_aggregate';
  aggregate?: Maybe<platform_subscription_aggregate_fields>;
  nodes: Array<platform_subscription>;
};

export type platform_subscription_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_subscription_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_subscription_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_subscription_aggregate_bool_exp_count>;
};

export type platform_subscription_aggregate_bool_exp_bool_and = {
  arguments: platform_subscription_select_column_platform_subscription_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_subscription_aggregate_bool_exp_bool_or = {
  arguments: platform_subscription_select_column_platform_subscription_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_subscription_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_subscription" */
export type platform_subscription_aggregate_fields = {
  __typename: 'platform_subscription_aggregate_fields';
  avg?: Maybe<platform_subscription_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_max_fields>;
  min?: Maybe<platform_subscription_min_fields>;
  stddev?: Maybe<platform_subscription_stddev_fields>;
  stddev_pop?: Maybe<platform_subscription_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_subscription_stddev_samp_fields>;
  sum?: Maybe<platform_subscription_sum_fields>;
  var_pop?: Maybe<platform_subscription_var_pop_fields>;
  var_samp?: Maybe<platform_subscription_var_samp_fields>;
  variance?: Maybe<platform_subscription_variance_fields>;
};


/** aggregate fields of "platform_subscription" */
export type platform_subscription_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_subscription" */
export type platform_subscription_aggregate_order_by = {
  avg?: InputMaybe<platform_subscription_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_subscription_max_order_by>;
  min?: InputMaybe<platform_subscription_min_order_by>;
  stddev?: InputMaybe<platform_subscription_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_subscription_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_subscription_stddev_samp_order_by>;
  sum?: InputMaybe<platform_subscription_sum_order_by>;
  var_pop?: InputMaybe<platform_subscription_var_pop_order_by>;
  var_samp?: InputMaybe<platform_subscription_var_samp_order_by>;
  variance?: InputMaybe<platform_subscription_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type platform_subscription_append_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "platform_subscription" */
export type platform_subscription_arr_rel_insert_input = {
  data: Array<platform_subscription_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_on_conflict>;
};

/** aggregate avg on columns */
export type platform_subscription_avg_fields = {
  __typename: 'platform_subscription_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_subscription" */
export type platform_subscription_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_subscription". All fields are combined with a logical 'AND'. */
export type platform_subscription_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_bool_exp>>;
  _not?: InputMaybe<platform_subscription_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_bool_exp>>;
  billing_country?: InputMaybe<citext_comparison_exp>;
  billing_state?: InputMaybe<citext_comparison_exp>;
  can_cancel?: InputMaybe<Boolean_comparison_exp>;
  can_pause?: InputMaybe<Boolean_comparison_exp>;
  cancel_at_period_end?: InputMaybe<Boolean_comparison_exp>;
  canceled_at?: InputMaybe<timestamptz_comparison_exp>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  imported?: InputMaybe<Boolean_comparison_exp>;
  metadata?: InputMaybe<jsonb_comparison_exp>;
  next_billing_at?: InputMaybe<timestamptz_comparison_exp>;
  paper_code?: InputMaybe<String_comparison_exp>;
  past_due?: InputMaybe<Boolean_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_coupon?: InputMaybe<platform_coupon_bool_exp>;
  platform_coupon_id?: InputMaybe<String_comparison_exp>;
  platform_customer?: InputMaybe<platform_customer_bool_exp>;
  platform_customer_id?: InputMaybe<String_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_invoices?: InputMaybe<platform_invoice_bool_exp>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_bool_exp>;
  platform_newspaper_subscription?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  platform_newspaper_subscriptions?: InputMaybe<platform_newspaper_subscription_bool_exp>;
  platform_newspaper_subscriptions_aggregate?: InputMaybe<platform_newspaper_subscription_aggregate_bool_exp>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_bool_exp>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_bool_exp>;
  platform_subscription_next_billing_at?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_bool_exp>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_bool_exp>;
  platform_subscription_status?: InputMaybe<platform_subscription_status_bool_exp>;
  shipping_country?: InputMaybe<citext_comparison_exp>;
  shipping_state?: InputMaybe<citext_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  status?: InputMaybe<platform_subscription_status_enum_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  swappable_ecommerce_products?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
  swappable_ecommerce_products_aggregate?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_bool_exp>;
  trial_end?: InputMaybe<timestamptz_comparison_exp>;
  trial_start?: InputMaybe<timestamptz_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_subscription" */
export enum platform_subscription_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_subscription_pkey = 'platform_subscription_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type platform_subscription_delete_at_path_input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type platform_subscription_delete_elem_input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type platform_subscription_delete_key_input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "platform_subscription" */
export type platform_subscription_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_subscription" */
export type platform_subscription_insert_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  can_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  can_pause?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_billing_at?: InputMaybe<Scalars['timestamptz']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon?: InputMaybe<platform_coupon_obj_rel_insert_input>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_customer?: InputMaybe<platform_customer_obj_rel_insert_input>;
  platform_customer_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_invoices?: InputMaybe<platform_invoice_arr_rel_insert_input>;
  platform_newspaper_subscription?: InputMaybe<platform_newspaper_subscription_obj_rel_insert_input>;
  platform_newspaper_subscriptions?: InputMaybe<platform_newspaper_subscription_arr_rel_insert_input>;
  platform_subscription_addons?: InputMaybe<platform_subscription_addon_arr_rel_insert_input>;
  platform_subscription_next_billing_at?: InputMaybe<platform_subscription_next_billing_at_obj_rel_insert_input>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_arr_rel_insert_input>;
  platform_subscription_status?: InputMaybe<platform_subscription_status_obj_rel_insert_input>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_subscription_status_enum>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  swappable_ecommerce_products?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_arr_rel_insert_input>;
  trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_subscription_max_fields = {
  __typename: 'platform_subscription_max_fields';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  next_billing_at?: Maybe<Scalars['timestamptz']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_customer_id?: Maybe<Scalars['String']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  trial_end?: Maybe<Scalars['timestamptz']['output']>;
  trial_start?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_subscription" */
export type platform_subscription_max_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  canceled_at?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  next_billing_at?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_customer_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  trial_end?: InputMaybe<order_by>;
  trial_start?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_subscription_min_fields = {
  __typename: 'platform_subscription_min_fields';
  billing_country?: Maybe<Scalars['citext']['output']>;
  billing_state?: Maybe<Scalars['citext']['output']>;
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  next_billing_at?: Maybe<Scalars['timestamptz']['output']>;
  paper_code?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_coupon_id?: Maybe<Scalars['String']['output']>;
  platform_customer_id?: Maybe<Scalars['String']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  shipping_country?: Maybe<Scalars['citext']['output']>;
  shipping_state?: Maybe<Scalars['citext']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  trial_end?: Maybe<Scalars['timestamptz']['output']>;
  trial_start?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_subscription" */
export type platform_subscription_min_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  canceled_at?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  next_billing_at?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_customer_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  trial_end?: InputMaybe<order_by>;
  trial_start?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_subscription" */
export type platform_subscription_mutation_response = {
  __typename: 'platform_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription>;
};

/** columns and relationships of "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at = {
  __typename: 'platform_subscription_next_billing_at';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_aggregate = {
  __typename: 'platform_subscription_next_billing_at_aggregate';
  aggregate?: Maybe<platform_subscription_next_billing_at_aggregate_fields>;
  nodes: Array<platform_subscription_next_billing_at>;
};

/** aggregate fields of "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_aggregate_fields = {
  __typename: 'platform_subscription_next_billing_at_aggregate_fields';
  avg?: Maybe<platform_subscription_next_billing_at_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_next_billing_at_max_fields>;
  min?: Maybe<platform_subscription_next_billing_at_min_fields>;
  stddev?: Maybe<platform_subscription_next_billing_at_stddev_fields>;
  stddev_pop?: Maybe<platform_subscription_next_billing_at_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_subscription_next_billing_at_stddev_samp_fields>;
  sum?: Maybe<platform_subscription_next_billing_at_sum_fields>;
  var_pop?: Maybe<platform_subscription_next_billing_at_var_pop_fields>;
  var_samp?: Maybe<platform_subscription_next_billing_at_var_samp_fields>;
  variance?: Maybe<platform_subscription_next_billing_at_variance_fields>;
};


/** aggregate fields of "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_next_billing_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type platform_subscription_next_billing_at_avg_fields = {
  __typename: 'platform_subscription_next_billing_at_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "platform_subscription_next_billing_at". All fields are combined with a logical 'AND'. */
export type platform_subscription_next_billing_at_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_next_billing_at_bool_exp>>;
  _not?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_next_billing_at_bool_exp>>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
  value_date?: InputMaybe<date_comparison_exp>;
  value_text?: InputMaybe<String_comparison_exp>;
};

/** input type for incrementing numeric columns in table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_insert_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_subscription_next_billing_at_max_fields = {
  __typename: 'platform_subscription_next_billing_at_max_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_subscription_next_billing_at_min_fields = {
  __typename: 'platform_subscription_next_billing_at_min_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  value_date?: Maybe<Scalars['date']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_mutation_response = {
  __typename: 'platform_subscription_next_billing_at_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription_next_billing_at>;
};

/** input type for inserting object relation for remote table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_obj_rel_insert_input = {
  data: platform_subscription_next_billing_at_insert_input;
};

/** Ordering options when selecting data from "platform_subscription_next_billing_at". */
export type platform_subscription_next_billing_at_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  value_date?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
};

/** select columns of table "platform_subscription_next_billing_at" */
export enum platform_subscription_next_billing_at_select_column {
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  value_date = 'value_date',
  /** column name */
  value_text = 'value_text'
}

/** input type for updating data in table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_set_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_subscription_next_billing_at_stddev_fields = {
  __typename: 'platform_subscription_next_billing_at_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type platform_subscription_next_billing_at_stddev_pop_fields = {
  __typename: 'platform_subscription_next_billing_at_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type platform_subscription_next_billing_at_stddev_samp_fields = {
  __typename: 'platform_subscription_next_billing_at_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "platform_subscription_next_billing_at" */
export type platform_subscription_next_billing_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_next_billing_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_next_billing_at_stream_cursor_value_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  value_date?: InputMaybe<Scalars['date']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_subscription_next_billing_at_sum_fields = {
  __typename: 'platform_subscription_next_billing_at_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

export type platform_subscription_next_billing_at_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_subscription_next_billing_at_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_next_billing_at_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_next_billing_at_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_subscription_next_billing_at_var_pop_fields = {
  __typename: 'platform_subscription_next_billing_at_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type platform_subscription_next_billing_at_var_samp_fields = {
  __typename: 'platform_subscription_next_billing_at_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type platform_subscription_next_billing_at_variance_fields = {
  __typename: 'platform_subscription_next_billing_at_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting object relation for remote table "platform_subscription" */
export type platform_subscription_obj_rel_insert_input = {
  data: platform_subscription_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_on_conflict>;
};

/** on_conflict condition type for table "platform_subscription" */
export type platform_subscription_on_conflict = {
  constraint: platform_subscription_constraint;
  update_columns?: Array<platform_subscription_update_column>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};

/** Ordering options when selecting data from "platform_subscription". */
export type platform_subscription_order_by = {
  billing_country?: InputMaybe<order_by>;
  billing_state?: InputMaybe<order_by>;
  can_cancel?: InputMaybe<order_by>;
  can_pause?: InputMaybe<order_by>;
  cancel_at_period_end?: InputMaybe<order_by>;
  canceled_at?: InputMaybe<order_by>;
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  imported?: InputMaybe<order_by>;
  metadata?: InputMaybe<order_by>;
  next_billing_at?: InputMaybe<order_by>;
  paper_code?: InputMaybe<order_by>;
  past_due?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_coupon?: InputMaybe<platform_coupon_order_by>;
  platform_coupon_id?: InputMaybe<order_by>;
  platform_customer?: InputMaybe<platform_customer_order_by>;
  platform_customer_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_invoices_aggregate?: InputMaybe<platform_invoice_aggregate_order_by>;
  platform_newspaper_subscription?: InputMaybe<platform_newspaper_subscription_order_by>;
  platform_newspaper_subscriptions_aggregate?: InputMaybe<platform_newspaper_subscription_aggregate_order_by>;
  platform_subscription_addons_aggregate?: InputMaybe<platform_subscription_addon_aggregate_order_by>;
  platform_subscription_next_billing_at?: InputMaybe<platform_subscription_next_billing_at_order_by>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_order_by>;
  platform_subscription_status?: InputMaybe<platform_subscription_status_order_by>;
  shipping_country?: InputMaybe<order_by>;
  shipping_state?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  swappable_ecommerce_products_aggregate?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_order_by>;
  trial_end?: InputMaybe<order_by>;
  trial_start?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_subscription */
export type platform_subscription_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** columns and relationships of "platform_subscription_plan" */
export type platform_subscription_plan = {
  __typename: 'platform_subscription_plan';
  created_at: Scalars['timestamptz']['output'];
  interval?: Maybe<platform_plan_interval_enum>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id: Scalars['String']['output'];
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  platform_subscription_id: Scalars['String']['output'];
  /** An object relationship */
  platform_variant?: Maybe<platform_variant>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity: Scalars['numeric']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  swappable_ecommerce_products: Array<platform_subscription_plan_swappable_ecommerce_product>;
  /** An aggregate relationship */
  swappable_ecommerce_products_aggregate: platform_subscription_plan_swappable_ecommerce_product_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_subscription_plan" */
export type platform_subscription_planswappable_ecommerce_productsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


/** columns and relationships of "platform_subscription_plan" */
export type platform_subscription_planswappable_ecommerce_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};

/** aggregated selection of "platform_subscription_plan" */
export type platform_subscription_plan_aggregate = {
  __typename: 'platform_subscription_plan_aggregate';
  aggregate?: Maybe<platform_subscription_plan_aggregate_fields>;
  nodes: Array<platform_subscription_plan>;
};

export type platform_subscription_plan_aggregate_bool_exp = {
  count?: InputMaybe<platform_subscription_plan_aggregate_bool_exp_count>;
};

export type platform_subscription_plan_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_plan_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_subscription_plan" */
export type platform_subscription_plan_aggregate_fields = {
  __typename: 'platform_subscription_plan_aggregate_fields';
  avg?: Maybe<platform_subscription_plan_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_plan_max_fields>;
  min?: Maybe<platform_subscription_plan_min_fields>;
  stddev?: Maybe<platform_subscription_plan_stddev_fields>;
  stddev_pop?: Maybe<platform_subscription_plan_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_subscription_plan_stddev_samp_fields>;
  sum?: Maybe<platform_subscription_plan_sum_fields>;
  var_pop?: Maybe<platform_subscription_plan_var_pop_fields>;
  var_samp?: Maybe<platform_subscription_plan_var_samp_fields>;
  variance?: Maybe<platform_subscription_plan_variance_fields>;
};


/** aggregate fields of "platform_subscription_plan" */
export type platform_subscription_plan_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_subscription_plan" */
export type platform_subscription_plan_aggregate_order_by = {
  avg?: InputMaybe<platform_subscription_plan_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_subscription_plan_max_order_by>;
  min?: InputMaybe<platform_subscription_plan_min_order_by>;
  stddev?: InputMaybe<platform_subscription_plan_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_subscription_plan_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_subscription_plan_stddev_samp_order_by>;
  sum?: InputMaybe<platform_subscription_plan_sum_order_by>;
  var_pop?: InputMaybe<platform_subscription_plan_var_pop_order_by>;
  var_samp?: InputMaybe<platform_subscription_plan_var_samp_order_by>;
  variance?: InputMaybe<platform_subscription_plan_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_subscription_plan" */
export type platform_subscription_plan_arr_rel_insert_input = {
  data: Array<platform_subscription_plan_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_plan_on_conflict>;
};

/** aggregate avg on columns */
export type platform_subscription_plan_avg_fields = {
  __typename: 'platform_subscription_plan_avg_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_avg_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_subscription_plan". All fields are combined with a logical 'AND'. */
export type platform_subscription_plan_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_plan_bool_exp>>;
  _not?: InputMaybe<platform_subscription_plan_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_plan_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  interval?: InputMaybe<platform_plan_interval_enum_comparison_exp>;
  interval_count?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
  platform_variant?: InputMaybe<platform_variant_bool_exp>;
  platform_variant_id?: InputMaybe<String_comparison_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  quantity?: InputMaybe<numeric_comparison_exp>;
  sku?: InputMaybe<String_comparison_exp>;
  swappable_ecommerce_products?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
  swappable_ecommerce_products_aggregate?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_subscription_plan" */
export enum platform_subscription_plan_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_subscription_id", "platform_connection_id" */
  platform_subscription_plan_pkey = 'platform_subscription_plan_pkey'
}

/** input type for incrementing numeric columns in table "platform_subscription_plan" */
export type platform_subscription_plan_inc_input = {
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_subscription_plan" */
export type platform_subscription_plan_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  platform_variant?: InputMaybe<platform_variant_obj_rel_insert_input>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  swappable_ecommerce_products?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_subscription_plan_max_fields = {
  __typename: 'platform_subscription_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_max_order_by = {
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_subscription_plan_min_fields = {
  __typename: 'platform_subscription_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_min_order_by = {
  created_at?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_subscription_plan" */
export type platform_subscription_plan_mutation_response = {
  __typename: 'platform_subscription_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription_plan>;
};

/** input type for inserting object relation for remote table "platform_subscription_plan" */
export type platform_subscription_plan_obj_rel_insert_input = {
  data: platform_subscription_plan_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_plan_on_conflict>;
};

/** on_conflict condition type for table "platform_subscription_plan" */
export type platform_subscription_plan_on_conflict = {
  constraint: platform_subscription_plan_constraint;
  update_columns?: Array<platform_subscription_plan_update_column>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};

/** Ordering options when selecting data from "platform_subscription_plan". */
export type platform_subscription_plan_order_by = {
  created_at?: InputMaybe<order_by>;
  interval?: InputMaybe<order_by>;
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  platform_variant?: InputMaybe<platform_variant_order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  swappable_ecommerce_products_aggregate?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_subscription_plan */
export type platform_subscription_plan_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};

/** select columns of table "platform_subscription_plan" */
export enum platform_subscription_plan_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  platform_variant_id = 'platform_variant_id',
  /** column name */
  price = 'price',
  /** column name */
  quantity = 'quantity',
  /** column name */
  sku = 'sku',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "platform_subscription_plan" */
export type platform_subscription_plan_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_subscription_plan_stddev_fields = {
  __typename: 'platform_subscription_plan_stddev_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_stddev_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_subscription_plan_stddev_pop_fields = {
  __typename: 'platform_subscription_plan_stddev_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_stddev_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_subscription_plan_stddev_samp_fields = {
  __typename: 'platform_subscription_plan_stddev_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_stddev_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_subscription_plan" */
export type platform_subscription_plan_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_plan_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_plan_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  interval?: InputMaybe<platform_plan_interval_enum>;
  interval_count?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  quantity?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_subscription_plan_sum_fields = {
  __typename: 'platform_subscription_plan_sum_fields';
  interval_count?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  quantity?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_sum_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** columns and relationships of "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product';
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_ecommerce_product?: Maybe<platform_ecommerce_product>;
  platform_ecommerce_product_id: Scalars['String']['output'];
  /** An object relationship */
  platform_plan?: Maybe<platform_plan>;
  platform_plan_id: Scalars['String']['output'];
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  platform_subscription_id: Scalars['String']['output'];
  /** An object relationship */
  platform_subscription_plan?: Maybe<platform_subscription_plan>;
};

/** aggregated selection of "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_aggregate = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_aggregate';
  aggregate?: Maybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_fields>;
  nodes: Array<platform_subscription_plan_swappable_ecommerce_product>;
};

export type platform_subscription_plan_swappable_ecommerce_product_aggregate_bool_exp = {
  count?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_aggregate_bool_exp_count>;
};

export type platform_subscription_plan_swappable_ecommerce_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_aggregate_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_aggregate_fields';
  avg?: Maybe<platform_subscription_plan_swappable_ecommerce_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_plan_swappable_ecommerce_product_max_fields>;
  min?: Maybe<platform_subscription_plan_swappable_ecommerce_product_min_fields>;
  stddev?: Maybe<platform_subscription_plan_swappable_ecommerce_product_stddev_fields>;
  stddev_pop?: Maybe<platform_subscription_plan_swappable_ecommerce_product_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_subscription_plan_swappable_ecommerce_product_stddev_samp_fields>;
  sum?: Maybe<platform_subscription_plan_swappable_ecommerce_product_sum_fields>;
  var_pop?: Maybe<platform_subscription_plan_swappable_ecommerce_product_var_pop_fields>;
  var_samp?: Maybe<platform_subscription_plan_swappable_ecommerce_product_var_samp_fields>;
  variance?: Maybe<platform_subscription_plan_swappable_ecommerce_product_variance_fields>;
};


/** aggregate fields of "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_aggregate_order_by = {
  avg?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_max_order_by>;
  min?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_min_order_by>;
  stddev?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_stddev_samp_order_by>;
  sum?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_sum_order_by>;
  var_pop?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_var_pop_order_by>;
  var_samp?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_var_samp_order_by>;
  variance?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_arr_rel_insert_input = {
  data: Array<platform_subscription_plan_swappable_ecommerce_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_on_conflict>;
};

/** aggregate avg on columns */
export type platform_subscription_plan_swappable_ecommerce_product_avg_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_subscription_plan_swappable_ecommerce_product". All fields are combined with a logical 'AND'. */
export type platform_subscription_plan_swappable_ecommerce_product_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_bool_exp>>;
  _not?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_bool_exp>>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_bool_exp>;
  platform_ecommerce_product_id?: InputMaybe<String_comparison_exp>;
  platform_plan?: InputMaybe<platform_plan_bool_exp>;
  platform_plan_id?: InputMaybe<String_comparison_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
  platform_subscription_plan?: InputMaybe<platform_subscription_plan_bool_exp>;
};

/** unique or primary key constraints on table "platform_subscription_plan_swappable_ecommerce_product" */
export enum platform_subscription_plan_swappable_ecommerce_product_constraint {
  /** unique or primary key constraint on columns "platform_plan_id", "platform_subscription_id", "platform_connection_id", "platform_ecommerce_product_id" */
  platform_subscription_plan_swappable_ecommerce_product_pkey = 'platform_subscription_plan_swappable_ecommerce_product_pkey'
}

/** input type for incrementing numeric columns in table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_insert_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_obj_rel_insert_input>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan?: InputMaybe<platform_plan_obj_rel_insert_input>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_plan?: InputMaybe<platform_subscription_plan_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type platform_subscription_plan_swappable_ecommerce_product_max_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_max_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_max_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_subscription_plan_swappable_ecommerce_product_min_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_min_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_plan_id?: Maybe<Scalars['String']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_min_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_mutation_response = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription_plan_swappable_ecommerce_product>;
};

/** on_conflict condition type for table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_on_conflict = {
  constraint: platform_subscription_plan_swappable_ecommerce_product_constraint;
  update_columns?: Array<platform_subscription_plan_swappable_ecommerce_product_update_column>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};

/** Ordering options when selecting data from "platform_subscription_plan_swappable_ecommerce_product". */
export type platform_subscription_plan_swappable_ecommerce_product_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_plan?: InputMaybe<platform_plan_order_by>;
  platform_plan_id?: InputMaybe<order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
  platform_subscription_plan?: InputMaybe<platform_subscription_plan_order_by>;
};

/** primary key columns input for table: platform_subscription_plan_swappable_ecommerce_product */
export type platform_subscription_plan_swappable_ecommerce_product_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};

/** select columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export enum platform_subscription_plan_swappable_ecommerce_product_select_column {
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id'
}

/** input type for updating data in table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_set_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_pop_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_samp_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_plan_swappable_ecommerce_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_plan_swappable_ecommerce_product_stream_cursor_value_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_plan_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_subscription_plan_swappable_ecommerce_product_sum_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export enum platform_subscription_plan_swappable_ecommerce_product_update_column {
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id'
}

export type platform_subscription_plan_swappable_ecommerce_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_plan_swappable_ecommerce_product_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_subscription_plan_swappable_ecommerce_product_var_pop_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_subscription_plan_swappable_ecommerce_product_var_samp_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_subscription_plan_swappable_ecommerce_product_variance_fields = {
  __typename: 'platform_subscription_plan_swappable_ecommerce_product_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_subscription_plan_swappable_ecommerce_product" */
export type platform_subscription_plan_swappable_ecommerce_product_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_subscription_plan" */
export enum platform_subscription_plan_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  interval = 'interval',
  /** column name */
  interval_count = 'interval_count',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_plan_id = 'platform_plan_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id',
  /** column name */
  platform_variant_id = 'platform_variant_id',
  /** column name */
  price = 'price',
  /** column name */
  quantity = 'quantity',
  /** column name */
  sku = 'sku',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_subscription_plan_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_subscription_plan_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_plan_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_plan_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_subscription_plan_var_pop_fields = {
  __typename: 'platform_subscription_plan_var_pop_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_var_pop_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_subscription_plan_var_samp_fields = {
  __typename: 'platform_subscription_plan_var_samp_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_var_samp_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_subscription_plan_variance_fields = {
  __typename: 'platform_subscription_plan_variance_fields';
  interval_count?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_subscription_plan" */
export type platform_subscription_plan_variance_order_by = {
  interval_count?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  quantity?: InputMaybe<order_by>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type platform_subscription_prepend_input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "platform_subscription" */
export enum platform_subscription_select_column {
  /** column name */
  billing_country = 'billing_country',
  /** column name */
  billing_state = 'billing_state',
  /** column name */
  can_cancel = 'can_cancel',
  /** column name */
  can_pause = 'can_pause',
  /** column name */
  cancel_at_period_end = 'cancel_at_period_end',
  /** column name */
  canceled_at = 'canceled_at',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  imported = 'imported',
  /** column name */
  metadata = 'metadata',
  /** column name */
  next_billing_at = 'next_billing_at',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  past_due = 'past_due',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_customer_id = 'platform_customer_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  shipping_country = 'shipping_country',
  /** column name */
  shipping_state = 'shipping_state',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  trial_end = 'trial_end',
  /** column name */
  trial_start = 'trial_start',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_subscription" */
export enum platform_subscription_select_column_platform_subscription_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  can_cancel = 'can_cancel',
  /** column name */
  can_pause = 'can_pause',
  /** column name */
  cancel_at_period_end = 'cancel_at_period_end',
  /** column name */
  imported = 'imported',
  /** column name */
  past_due = 'past_due'
}

/** select "platform_subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_subscription" */
export enum platform_subscription_select_column_platform_subscription_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  can_cancel = 'can_cancel',
  /** column name */
  can_pause = 'can_pause',
  /** column name */
  cancel_at_period_end = 'cancel_at_period_end',
  /** column name */
  imported = 'imported',
  /** column name */
  past_due = 'past_due'
}

/** input type for updating data in table "platform_subscription" */
export type platform_subscription_set_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  can_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  can_pause?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_billing_at?: InputMaybe<Scalars['timestamptz']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_customer_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_subscription_status_enum>;
  trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "platform_subscription_status" */
export type platform_subscription_status = {
  __typename: 'platform_subscription_status';
  /** An array relationship */
  platform_subscriptions: Array<platform_subscription>;
  /** An aggregate relationship */
  platform_subscriptions_aggregate: platform_subscription_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "platform_subscription_status" */
export type platform_subscription_statusplatform_subscriptionsArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


/** columns and relationships of "platform_subscription_status" */
export type platform_subscription_statusplatform_subscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};

/** aggregated selection of "platform_subscription_status" */
export type platform_subscription_status_aggregate = {
  __typename: 'platform_subscription_status_aggregate';
  aggregate?: Maybe<platform_subscription_status_aggregate_fields>;
  nodes: Array<platform_subscription_status>;
};

/** aggregate fields of "platform_subscription_status" */
export type platform_subscription_status_aggregate_fields = {
  __typename: 'platform_subscription_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<platform_subscription_status_max_fields>;
  min?: Maybe<platform_subscription_status_min_fields>;
};


/** aggregate fields of "platform_subscription_status" */
export type platform_subscription_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_subscription_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "platform_subscription_status". All fields are combined with a logical 'AND'. */
export type platform_subscription_status_bool_exp = {
  _and?: InputMaybe<Array<platform_subscription_status_bool_exp>>;
  _not?: InputMaybe<platform_subscription_status_bool_exp>;
  _or?: InputMaybe<Array<platform_subscription_status_bool_exp>>;
  platform_subscriptions?: InputMaybe<platform_subscription_bool_exp>;
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_subscription_status" */
export enum platform_subscription_status_constraint {
  /** unique or primary key constraint on columns "value" */
  platform_subscription_status_pkey = 'platform_subscription_status_pkey'
}

export enum platform_subscription_status_enum {
  active = 'active',
  canceled = 'canceled',
  future = 'future',
  paused = 'paused',
  trialing = 'trialing'
}

/** Boolean expression to compare columns of type "platform_subscription_status_enum". All fields are combined with logical 'AND'. */
export type platform_subscription_status_enum_comparison_exp = {
  _eq?: InputMaybe<platform_subscription_status_enum>;
  _in?: InputMaybe<Array<platform_subscription_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<platform_subscription_status_enum>;
  _nin?: InputMaybe<Array<platform_subscription_status_enum>>;
};

/** input type for inserting data into table "platform_subscription_status" */
export type platform_subscription_status_insert_input = {
  platform_subscriptions?: InputMaybe<platform_subscription_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_subscription_status_max_fields = {
  __typename: 'platform_subscription_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type platform_subscription_status_min_fields = {
  __typename: 'platform_subscription_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "platform_subscription_status" */
export type platform_subscription_status_mutation_response = {
  __typename: 'platform_subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_subscription_status>;
};

/** input type for inserting object relation for remote table "platform_subscription_status" */
export type platform_subscription_status_obj_rel_insert_input = {
  data: platform_subscription_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_subscription_status_on_conflict>;
};

/** on_conflict condition type for table "platform_subscription_status" */
export type platform_subscription_status_on_conflict = {
  constraint: platform_subscription_status_constraint;
  update_columns?: Array<platform_subscription_status_update_column>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};

/** Ordering options when selecting data from "platform_subscription_status". */
export type platform_subscription_status_order_by = {
  platform_subscriptions_aggregate?: InputMaybe<platform_subscription_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_subscription_status */
export type platform_subscription_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "platform_subscription_status" */
export enum platform_subscription_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_subscription_status" */
export type platform_subscription_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "platform_subscription_status" */
export type platform_subscription_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "platform_subscription_status" */
export enum platform_subscription_status_update_column {
  /** column name */
  value = 'value'
}

export type platform_subscription_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_status_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_status_bool_exp;
};

/** aggregate stddev on columns */
export type platform_subscription_stddev_fields = {
  __typename: 'platform_subscription_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_subscription" */
export type platform_subscription_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_subscription_stddev_pop_fields = {
  __typename: 'platform_subscription_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_subscription" */
export type platform_subscription_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_subscription_stddev_samp_fields = {
  __typename: 'platform_subscription_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_subscription" */
export type platform_subscription_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_subscription" */
export type platform_subscription_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_subscription_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_subscription_stream_cursor_value_input = {
  billing_country?: InputMaybe<Scalars['citext']['input']>;
  billing_state?: InputMaybe<Scalars['citext']['input']>;
  can_cancel?: InputMaybe<Scalars['Boolean']['input']>;
  can_pause?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_billing_at?: InputMaybe<Scalars['timestamptz']['input']>;
  paper_code?: InputMaybe<Scalars['String']['input']>;
  past_due?: InputMaybe<Scalars['Boolean']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_coupon_id?: InputMaybe<Scalars['String']['input']>;
  platform_customer_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  shipping_country?: InputMaybe<Scalars['citext']['input']>;
  shipping_state?: InputMaybe<Scalars['citext']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<platform_subscription_status_enum>;
  trial_end?: InputMaybe<Scalars['timestamptz']['input']>;
  trial_start?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_subscription_sum_fields = {
  __typename: 'platform_subscription_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_subscription" */
export type platform_subscription_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_subscription" */
export enum platform_subscription_update_column {
  /** column name */
  billing_country = 'billing_country',
  /** column name */
  billing_state = 'billing_state',
  /** column name */
  can_cancel = 'can_cancel',
  /** column name */
  can_pause = 'can_pause',
  /** column name */
  cancel_at_period_end = 'cancel_at_period_end',
  /** column name */
  canceled_at = 'canceled_at',
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  imported = 'imported',
  /** column name */
  metadata = 'metadata',
  /** column name */
  next_billing_at = 'next_billing_at',
  /** column name */
  paper_code = 'paper_code',
  /** column name */
  past_due = 'past_due',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_coupon_id = 'platform_coupon_id',
  /** column name */
  platform_customer_id = 'platform_customer_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  shipping_country = 'shipping_country',
  /** column name */
  shipping_state = 'shipping_state',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  trial_end = 'trial_end',
  /** column name */
  trial_start = 'trial_start',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_subscription_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<platform_subscription_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<platform_subscription_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<platform_subscription_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<platform_subscription_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_subscription_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<platform_subscription_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_subscription_set_input>;
  /** filter the rows which have to be updated */
  where: platform_subscription_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_subscription_var_pop_fields = {
  __typename: 'platform_subscription_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_subscription" */
export type platform_subscription_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_subscription_var_samp_fields = {
  __typename: 'platform_subscription_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_subscription" */
export type platform_subscription_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_subscription_variance_fields = {
  __typename: 'platform_subscription_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_subscription" */
export type platform_subscription_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform" */
export enum platform_update_column {
  /** column name */
  value = 'value'
}

export type platform_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_set_input>;
  /** filter the rows which have to be updated */
  where: platform_bool_exp;
};

/** columns and relationships of "platform_variant" */
export type platform_variant = {
  __typename: 'platform_variant';
  available: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  offer_product_swap_platform_ecommerce_product_platform_variant?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_ecommerce_product?: Maybe<platform_ecommerce_product>;
  platform_ecommerce_product_id: Scalars['String']['output'];
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  platform_subscription_plans: Array<platform_subscription_plan>;
  /** An aggregate relationship */
  platform_subscription_plans_aggregate: platform_subscription_plan_aggregate;
  /** An array relationship */
  platform_variant_options: Array<platform_variant_option>;
  /** An aggregate relationship */
  platform_variant_options_aggregate: platform_variant_option_aggregate;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "platform_variant" */
export type platform_variantplatform_subscription_plansArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


/** columns and relationships of "platform_variant" */
export type platform_variantplatform_subscription_plans_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


/** columns and relationships of "platform_variant" */
export type platform_variantplatform_variant_optionsArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


/** columns and relationships of "platform_variant" */
export type platform_variantplatform_variant_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};

/** aggregated selection of "platform_variant" */
export type platform_variant_aggregate = {
  __typename: 'platform_variant_aggregate';
  aggregate?: Maybe<platform_variant_aggregate_fields>;
  nodes: Array<platform_variant>;
};

export type platform_variant_aggregate_bool_exp = {
  bool_and?: InputMaybe<platform_variant_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<platform_variant_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<platform_variant_aggregate_bool_exp_count>;
};

export type platform_variant_aggregate_bool_exp_bool_and = {
  arguments: platform_variant_select_column_platform_variant_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_variant_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_variant_aggregate_bool_exp_bool_or = {
  arguments: platform_variant_select_column_platform_variant_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_variant_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type platform_variant_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_variant_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_variant" */
export type platform_variant_aggregate_fields = {
  __typename: 'platform_variant_aggregate_fields';
  avg?: Maybe<platform_variant_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_variant_max_fields>;
  min?: Maybe<platform_variant_min_fields>;
  stddev?: Maybe<platform_variant_stddev_fields>;
  stddev_pop?: Maybe<platform_variant_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_variant_stddev_samp_fields>;
  sum?: Maybe<platform_variant_sum_fields>;
  var_pop?: Maybe<platform_variant_var_pop_fields>;
  var_samp?: Maybe<platform_variant_var_samp_fields>;
  variance?: Maybe<platform_variant_variance_fields>;
};


/** aggregate fields of "platform_variant" */
export type platform_variant_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_variant_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_variant" */
export type platform_variant_aggregate_order_by = {
  avg?: InputMaybe<platform_variant_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_variant_max_order_by>;
  min?: InputMaybe<platform_variant_min_order_by>;
  stddev?: InputMaybe<platform_variant_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_variant_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_variant_stddev_samp_order_by>;
  sum?: InputMaybe<platform_variant_sum_order_by>;
  var_pop?: InputMaybe<platform_variant_var_pop_order_by>;
  var_samp?: InputMaybe<platform_variant_var_samp_order_by>;
  variance?: InputMaybe<platform_variant_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_variant" */
export type platform_variant_arr_rel_insert_input = {
  data: Array<platform_variant_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_variant_on_conflict>;
};

/** aggregate avg on columns */
export type platform_variant_avg_fields = {
  __typename: 'platform_variant_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_variant" */
export type platform_variant_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_variant". All fields are combined with a logical 'AND'. */
export type platform_variant_bool_exp = {
  _and?: InputMaybe<Array<platform_variant_bool_exp>>;
  _not?: InputMaybe<platform_variant_bool_exp>;
  _or?: InputMaybe<Array<platform_variant_bool_exp>>;
  available?: InputMaybe<Boolean_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer_product_swap_platform_ecommerce_product_platform_variant?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_bool_exp>;
  platform_ecommerce_product_id?: InputMaybe<String_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_bool_exp>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_bool_exp>;
  platform_variant_options?: InputMaybe<platform_variant_option_bool_exp>;
  platform_variant_options_aggregate?: InputMaybe<platform_variant_option_aggregate_bool_exp>;
  price?: InputMaybe<numeric_comparison_exp>;
  sku?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "platform_variant" */
export enum platform_variant_constraint {
  /** unique or primary key constraint on columns "platform_connection_id", "platform_id" */
  platform_variant_pkey = 'platform_variant_pkey'
}

/** input type for incrementing numeric columns in table "platform_variant" */
export type platform_variant_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "platform_variant" */
export type platform_variant_insert_input = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer_product_swap_platform_ecommerce_product_platform_variant?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_obj_rel_insert_input>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_obj_rel_insert_input>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription_plans?: InputMaybe<platform_subscription_plan_arr_rel_insert_input>;
  platform_variant_options?: InputMaybe<platform_variant_option_arr_rel_insert_input>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type platform_variant_max_fields = {
  __typename: 'platform_variant_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "platform_variant" */
export type platform_variant_max_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_variant_min_fields = {
  __typename: 'platform_variant_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_ecommerce_product_id?: Maybe<Scalars['String']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "platform_variant" */
export type platform_variant_min_order_by = {
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_variant" */
export type platform_variant_mutation_response = {
  __typename: 'platform_variant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_variant>;
};

/** input type for inserting object relation for remote table "platform_variant" */
export type platform_variant_obj_rel_insert_input = {
  data: platform_variant_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_variant_on_conflict>;
};

/** on_conflict condition type for table "platform_variant" */
export type platform_variant_on_conflict = {
  constraint: platform_variant_constraint;
  update_columns?: Array<platform_variant_update_column>;
  where?: InputMaybe<platform_variant_bool_exp>;
};

/** columns and relationships of "platform_variant_option" */
export type platform_variant_option = {
  __typename: 'platform_variant_option';
  key: Scalars['String']['output'];
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_variant?: Maybe<platform_variant>;
  platform_variant_id: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "platform_variant_option" */
export type platform_variant_option_aggregate = {
  __typename: 'platform_variant_option_aggregate';
  aggregate?: Maybe<platform_variant_option_aggregate_fields>;
  nodes: Array<platform_variant_option>;
};

export type platform_variant_option_aggregate_bool_exp = {
  count?: InputMaybe<platform_variant_option_aggregate_bool_exp_count>;
};

export type platform_variant_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<platform_variant_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<platform_variant_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "platform_variant_option" */
export type platform_variant_option_aggregate_fields = {
  __typename: 'platform_variant_option_aggregate_fields';
  avg?: Maybe<platform_variant_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<platform_variant_option_max_fields>;
  min?: Maybe<platform_variant_option_min_fields>;
  stddev?: Maybe<platform_variant_option_stddev_fields>;
  stddev_pop?: Maybe<platform_variant_option_stddev_pop_fields>;
  stddev_samp?: Maybe<platform_variant_option_stddev_samp_fields>;
  sum?: Maybe<platform_variant_option_sum_fields>;
  var_pop?: Maybe<platform_variant_option_var_pop_fields>;
  var_samp?: Maybe<platform_variant_option_var_samp_fields>;
  variance?: Maybe<platform_variant_option_variance_fields>;
};


/** aggregate fields of "platform_variant_option" */
export type platform_variant_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<platform_variant_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "platform_variant_option" */
export type platform_variant_option_aggregate_order_by = {
  avg?: InputMaybe<platform_variant_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<platform_variant_option_max_order_by>;
  min?: InputMaybe<platform_variant_option_min_order_by>;
  stddev?: InputMaybe<platform_variant_option_stddev_order_by>;
  stddev_pop?: InputMaybe<platform_variant_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<platform_variant_option_stddev_samp_order_by>;
  sum?: InputMaybe<platform_variant_option_sum_order_by>;
  var_pop?: InputMaybe<platform_variant_option_var_pop_order_by>;
  var_samp?: InputMaybe<platform_variant_option_var_samp_order_by>;
  variance?: InputMaybe<platform_variant_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "platform_variant_option" */
export type platform_variant_option_arr_rel_insert_input = {
  data: Array<platform_variant_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<platform_variant_option_on_conflict>;
};

/** aggregate avg on columns */
export type platform_variant_option_avg_fields = {
  __typename: 'platform_variant_option_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "platform_variant_option" */
export type platform_variant_option_avg_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "platform_variant_option". All fields are combined with a logical 'AND'. */
export type platform_variant_option_bool_exp = {
  _and?: InputMaybe<Array<platform_variant_option_bool_exp>>;
  _not?: InputMaybe<platform_variant_option_bool_exp>;
  _or?: InputMaybe<Array<platform_variant_option_bool_exp>>;
  key?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_variant?: InputMaybe<platform_variant_bool_exp>;
  platform_variant_id?: InputMaybe<String_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "platform_variant_option" */
export enum platform_variant_option_constraint {
  /** unique or primary key constraint on columns "key", "platform_variant_id", "platform_connection_id", "value" */
  platform_variant_option_pkey = 'platform_variant_option_pkey'
}

/** input type for incrementing numeric columns in table "platform_variant_option" */
export type platform_variant_option_inc_input = {
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "platform_variant_option" */
export type platform_variant_option_insert_input = {
  key?: InputMaybe<Scalars['String']['input']>;
  platform_connection?: InputMaybe<platform_connection_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant?: InputMaybe<platform_variant_obj_rel_insert_input>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type platform_variant_option_max_fields = {
  __typename: 'platform_variant_option_max_fields';
  key?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "platform_variant_option" */
export type platform_variant_option_max_order_by = {
  key?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type platform_variant_option_min_fields = {
  __typename: 'platform_variant_option_min_fields';
  key?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_variant_id?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "platform_variant_option" */
export type platform_variant_option_min_order_by = {
  key?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "platform_variant_option" */
export type platform_variant_option_mutation_response = {
  __typename: 'platform_variant_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<platform_variant_option>;
};

/** on_conflict condition type for table "platform_variant_option" */
export type platform_variant_option_on_conflict = {
  constraint: platform_variant_option_constraint;
  update_columns?: Array<platform_variant_option_update_column>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};

/** Ordering options when selecting data from "platform_variant_option". */
export type platform_variant_option_order_by = {
  key?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_variant?: InputMaybe<platform_variant_order_by>;
  platform_variant_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_variant_option */
export type platform_variant_option_pk_columns_input = {
  key: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** select columns of table "platform_variant_option" */
export enum platform_variant_option_select_column {
  /** column name */
  key = 'key',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_variant_id = 'platform_variant_id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "platform_variant_option" */
export type platform_variant_option_set_input = {
  key?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type platform_variant_option_stddev_fields = {
  __typename: 'platform_variant_option_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_variant_option" */
export type platform_variant_option_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_variant_option_stddev_pop_fields = {
  __typename: 'platform_variant_option_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_variant_option" */
export type platform_variant_option_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_variant_option_stddev_samp_fields = {
  __typename: 'platform_variant_option_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_variant_option" */
export type platform_variant_option_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_variant_option" */
export type platform_variant_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_variant_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_variant_option_stream_cursor_value_input = {
  key?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_variant_id?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type platform_variant_option_sum_fields = {
  __typename: 'platform_variant_option_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "platform_variant_option" */
export type platform_variant_option_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** update columns of table "platform_variant_option" */
export enum platform_variant_option_update_column {
  /** column name */
  key = 'key',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_variant_id = 'platform_variant_id',
  /** column name */
  value = 'value'
}

export type platform_variant_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_variant_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_variant_option_set_input>;
  /** filter the rows which have to be updated */
  where: platform_variant_option_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_variant_option_var_pop_fields = {
  __typename: 'platform_variant_option_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_variant_option" */
export type platform_variant_option_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_variant_option_var_samp_fields = {
  __typename: 'platform_variant_option_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_variant_option" */
export type platform_variant_option_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_variant_option_variance_fields = {
  __typename: 'platform_variant_option_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_variant_option" */
export type platform_variant_option_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "platform_variant". */
export type platform_variant_order_by = {
  available?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  offer_product_swap_platform_ecommerce_product_platform_variant?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_ecommerce_product?: InputMaybe<platform_ecommerce_product_order_by>;
  platform_ecommerce_product_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_subscription_plans_aggregate?: InputMaybe<platform_subscription_plan_aggregate_order_by>;
  platform_variant_options_aggregate?: InputMaybe<platform_variant_option_aggregate_order_by>;
  price?: InputMaybe<order_by>;
  sku?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: platform_variant */
export type platform_variant_pk_columns_input = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};

/** select columns of table "platform_variant" */
export enum platform_variant_select_column {
  /** column name */
  available = 'available',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  price = 'price',
  /** column name */
  sku = 'sku',
  /** column name */
  updated_at = 'updated_at'
}

/** select "platform_variant_aggregate_bool_exp_bool_and_arguments_columns" columns of table "platform_variant" */
export enum platform_variant_select_column_platform_variant_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  available = 'available'
}

/** select "platform_variant_aggregate_bool_exp_bool_or_arguments_columns" columns of table "platform_variant" */
export enum platform_variant_select_column_platform_variant_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  available = 'available'
}

/** input type for updating data in table "platform_variant" */
export type platform_variant_set_input = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type platform_variant_stddev_fields = {
  __typename: 'platform_variant_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "platform_variant" */
export type platform_variant_stddev_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type platform_variant_stddev_pop_fields = {
  __typename: 'platform_variant_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "platform_variant" */
export type platform_variant_stddev_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type platform_variant_stddev_samp_fields = {
  __typename: 'platform_variant_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "platform_variant" */
export type platform_variant_stddev_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "platform_variant" */
export type platform_variant_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: platform_variant_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type platform_variant_stream_cursor_value_input = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_ecommerce_product_id?: InputMaybe<Scalars['String']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type platform_variant_sum_fields = {
  __typename: 'platform_variant_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "platform_variant" */
export type platform_variant_sum_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** update columns of table "platform_variant" */
export enum platform_variant_update_column {
  /** column name */
  available = 'available',
  /** column name */
  created_at = 'created_at',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_ecommerce_product_id = 'platform_ecommerce_product_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  price = 'price',
  /** column name */
  sku = 'sku',
  /** column name */
  updated_at = 'updated_at'
}

export type platform_variant_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<platform_variant_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<platform_variant_set_input>;
  /** filter the rows which have to be updated */
  where: platform_variant_bool_exp;
};

/** aggregate var_pop on columns */
export type platform_variant_var_pop_fields = {
  __typename: 'platform_variant_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "platform_variant" */
export type platform_variant_var_pop_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type platform_variant_var_samp_fields = {
  __typename: 'platform_variant_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "platform_variant" */
export type platform_variant_var_samp_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type platform_variant_variance_fields = {
  __typename: 'platform_variant_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "platform_variant" */
export type platform_variant_variance_order_by = {
  platform_connection_id?: InputMaybe<order_by>;
  price?: InputMaybe<order_by>;
};

export type populate_segment_subscription_cache_args = {
  populate_account_id?: InputMaybe<Scalars['Int']['input']>;
  populate_segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "portal_email_code" */
export type portal_email_code = {
  __typename: 'portal_email_code';
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

/** aggregated selection of "portal_email_code" */
export type portal_email_code_aggregate = {
  __typename: 'portal_email_code_aggregate';
  aggregate?: Maybe<portal_email_code_aggregate_fields>;
  nodes: Array<portal_email_code>;
};

/** aggregate fields of "portal_email_code" */
export type portal_email_code_aggregate_fields = {
  __typename: 'portal_email_code_aggregate_fields';
  avg?: Maybe<portal_email_code_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<portal_email_code_max_fields>;
  min?: Maybe<portal_email_code_min_fields>;
  stddev?: Maybe<portal_email_code_stddev_fields>;
  stddev_pop?: Maybe<portal_email_code_stddev_pop_fields>;
  stddev_samp?: Maybe<portal_email_code_stddev_samp_fields>;
  sum?: Maybe<portal_email_code_sum_fields>;
  var_pop?: Maybe<portal_email_code_var_pop_fields>;
  var_samp?: Maybe<portal_email_code_var_samp_fields>;
  variance?: Maybe<portal_email_code_variance_fields>;
};


/** aggregate fields of "portal_email_code" */
export type portal_email_code_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<portal_email_code_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type portal_email_code_avg_fields = {
  __typename: 'portal_email_code_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "portal_email_code". All fields are combined with a logical 'AND'. */
export type portal_email_code_bool_exp = {
  _and?: InputMaybe<Array<portal_email_code_bool_exp>>;
  _not?: InputMaybe<portal_email_code_bool_exp>;
  _or?: InputMaybe<Array<portal_email_code_bool_exp>>;
  code?: InputMaybe<String_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "portal_email_code" */
export enum portal_email_code_constraint {
  /** unique or primary key constraint on columns "id" */
  portal_email_code_pkey = 'portal_email_code_pkey'
}

/** input type for incrementing numeric columns in table "portal_email_code" */
export type portal_email_code_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "portal_email_code" */
export type portal_email_code_insert_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type portal_email_code_max_fields = {
  __typename: 'portal_email_code_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type portal_email_code_min_fields = {
  __typename: 'portal_email_code_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "portal_email_code" */
export type portal_email_code_mutation_response = {
  __typename: 'portal_email_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<portal_email_code>;
};

/** on_conflict condition type for table "portal_email_code" */
export type portal_email_code_on_conflict = {
  constraint: portal_email_code_constraint;
  update_columns?: Array<portal_email_code_update_column>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};

/** Ordering options when selecting data from "portal_email_code". */
export type portal_email_code_order_by = {
  code?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** primary key columns input for table: portal_email_code */
export type portal_email_code_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "portal_email_code" */
export enum portal_email_code_select_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "portal_email_code" */
export type portal_email_code_set_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type portal_email_code_stddev_fields = {
  __typename: 'portal_email_code_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type portal_email_code_stddev_pop_fields = {
  __typename: 'portal_email_code_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type portal_email_code_stddev_samp_fields = {
  __typename: 'portal_email_code_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "portal_email_code" */
export type portal_email_code_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: portal_email_code_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type portal_email_code_stream_cursor_value_input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type portal_email_code_sum_fields = {
  __typename: 'portal_email_code_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "portal_email_code" */
export enum portal_email_code_update_column {
  /** column name */
  code = 'code',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id'
}

export type portal_email_code_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<portal_email_code_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<portal_email_code_set_input>;
  /** filter the rows which have to be updated */
  where: portal_email_code_bool_exp;
};

/** aggregate var_pop on columns */
export type portal_email_code_var_pop_fields = {
  __typename: 'portal_email_code_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type portal_email_code_var_samp_fields = {
  __typename: 'portal_email_code_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type portal_email_code_variance_fields = {
  __typename: 'portal_email_code_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "portal_session" */
export type portal_session = {
  __typename: 'portal_session';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  active_portal_session?: Maybe<active_portal_session>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  portal_session_subscribers: Array<portal_session_subscriber>;
  /** An aggregate relationship */
  portal_session_subscribers_aggregate: portal_session_subscriber_aggregate;
  return_url?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};


/** columns and relationships of "portal_session" */
export type portal_sessionportal_session_subscribersArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


/** columns and relationships of "portal_session" */
export type portal_sessionportal_session_subscribers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};

/** aggregated selection of "portal_session" */
export type portal_session_aggregate = {
  __typename: 'portal_session_aggregate';
  aggregate?: Maybe<portal_session_aggregate_fields>;
  nodes: Array<portal_session>;
};

export type portal_session_aggregate_bool_exp = {
  count?: InputMaybe<portal_session_aggregate_bool_exp_count>;
};

export type portal_session_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<portal_session_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<portal_session_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "portal_session" */
export type portal_session_aggregate_fields = {
  __typename: 'portal_session_aggregate_fields';
  avg?: Maybe<portal_session_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<portal_session_max_fields>;
  min?: Maybe<portal_session_min_fields>;
  stddev?: Maybe<portal_session_stddev_fields>;
  stddev_pop?: Maybe<portal_session_stddev_pop_fields>;
  stddev_samp?: Maybe<portal_session_stddev_samp_fields>;
  sum?: Maybe<portal_session_sum_fields>;
  var_pop?: Maybe<portal_session_var_pop_fields>;
  var_samp?: Maybe<portal_session_var_samp_fields>;
  variance?: Maybe<portal_session_variance_fields>;
};


/** aggregate fields of "portal_session" */
export type portal_session_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<portal_session_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "portal_session" */
export type portal_session_aggregate_order_by = {
  avg?: InputMaybe<portal_session_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<portal_session_max_order_by>;
  min?: InputMaybe<portal_session_min_order_by>;
  stddev?: InputMaybe<portal_session_stddev_order_by>;
  stddev_pop?: InputMaybe<portal_session_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<portal_session_stddev_samp_order_by>;
  sum?: InputMaybe<portal_session_sum_order_by>;
  var_pop?: InputMaybe<portal_session_var_pop_order_by>;
  var_samp?: InputMaybe<portal_session_var_samp_order_by>;
  variance?: InputMaybe<portal_session_variance_order_by>;
};

/** input type for inserting array relation for remote table "portal_session" */
export type portal_session_arr_rel_insert_input = {
  data: Array<portal_session_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<portal_session_on_conflict>;
};

/** aggregate avg on columns */
export type portal_session_avg_fields = {
  __typename: 'portal_session_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "portal_session" */
export type portal_session_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "portal_session". All fields are combined with a logical 'AND'. */
export type portal_session_bool_exp = {
  _and?: InputMaybe<Array<portal_session_bool_exp>>;
  _not?: InputMaybe<portal_session_bool_exp>;
  _or?: InputMaybe<Array<portal_session_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  active_portal_session?: InputMaybe<active_portal_session_bool_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  portal_session_subscribers?: InputMaybe<portal_session_subscriber_bool_exp>;
  portal_session_subscribers_aggregate?: InputMaybe<portal_session_subscriber_aggregate_bool_exp>;
  return_url?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "portal_session" */
export enum portal_session_constraint {
  /** unique or primary key constraint on columns "id" */
  portal_session_pkey = 'portal_session_pkey',
  /** unique or primary key constraint on columns "token" */
  portal_session_token_key = 'portal_session_token_key'
}

/** input type for incrementing numeric columns in table "portal_session" */
export type portal_session_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "portal_session" */
export type portal_session_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  active_portal_session?: InputMaybe<active_portal_session_obj_rel_insert_input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  portal_session_subscribers?: InputMaybe<portal_session_subscriber_arr_rel_insert_input>;
  return_url?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type portal_session_max_fields = {
  __typename: 'portal_session_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  return_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "portal_session" */
export type portal_session_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  return_url?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type portal_session_min_fields = {
  __typename: 'portal_session_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  return_url?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "portal_session" */
export type portal_session_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  return_url?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** response of any mutation on the table "portal_session" */
export type portal_session_mutation_response = {
  __typename: 'portal_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<portal_session>;
};

/** input type for inserting object relation for remote table "portal_session" */
export type portal_session_obj_rel_insert_input = {
  data: portal_session_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<portal_session_on_conflict>;
};

/** on_conflict condition type for table "portal_session" */
export type portal_session_on_conflict = {
  constraint: portal_session_constraint;
  update_columns?: Array<portal_session_update_column>;
  where?: InputMaybe<portal_session_bool_exp>;
};

/** Ordering options when selecting data from "portal_session". */
export type portal_session_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  active_portal_session?: InputMaybe<active_portal_session_order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  portal_session_subscribers_aggregate?: InputMaybe<portal_session_subscriber_aggregate_order_by>;
  return_url?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** primary key columns input for table: portal_session */
export type portal_session_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "portal_session" */
export enum portal_session_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  id = 'id',
  /** column name */
  return_url = 'return_url',
  /** column name */
  token = 'token'
}

/** input type for updating data in table "portal_session" */
export type portal_session_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  return_url?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type portal_session_stddev_fields = {
  __typename: 'portal_session_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "portal_session" */
export type portal_session_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type portal_session_stddev_pop_fields = {
  __typename: 'portal_session_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "portal_session" */
export type portal_session_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type portal_session_stddev_samp_fields = {
  __typename: 'portal_session_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "portal_session" */
export type portal_session_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "portal_session" */
export type portal_session_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: portal_session_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type portal_session_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  return_url?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "portal_session_subscriber" */
export type portal_session_subscriber = {
  __typename: 'portal_session_subscriber';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  portal_session: portal_session;
  portal_session_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber: subscriber;
  subscriber_id: Scalars['Int']['output'];
};

/** aggregated selection of "portal_session_subscriber" */
export type portal_session_subscriber_aggregate = {
  __typename: 'portal_session_subscriber_aggregate';
  aggregate?: Maybe<portal_session_subscriber_aggregate_fields>;
  nodes: Array<portal_session_subscriber>;
};

export type portal_session_subscriber_aggregate_bool_exp = {
  count?: InputMaybe<portal_session_subscriber_aggregate_bool_exp_count>;
};

export type portal_session_subscriber_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<portal_session_subscriber_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "portal_session_subscriber" */
export type portal_session_subscriber_aggregate_fields = {
  __typename: 'portal_session_subscriber_aggregate_fields';
  avg?: Maybe<portal_session_subscriber_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<portal_session_subscriber_max_fields>;
  min?: Maybe<portal_session_subscriber_min_fields>;
  stddev?: Maybe<portal_session_subscriber_stddev_fields>;
  stddev_pop?: Maybe<portal_session_subscriber_stddev_pop_fields>;
  stddev_samp?: Maybe<portal_session_subscriber_stddev_samp_fields>;
  sum?: Maybe<portal_session_subscriber_sum_fields>;
  var_pop?: Maybe<portal_session_subscriber_var_pop_fields>;
  var_samp?: Maybe<portal_session_subscriber_var_samp_fields>;
  variance?: Maybe<portal_session_subscriber_variance_fields>;
};


/** aggregate fields of "portal_session_subscriber" */
export type portal_session_subscriber_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "portal_session_subscriber" */
export type portal_session_subscriber_aggregate_order_by = {
  avg?: InputMaybe<portal_session_subscriber_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<portal_session_subscriber_max_order_by>;
  min?: InputMaybe<portal_session_subscriber_min_order_by>;
  stddev?: InputMaybe<portal_session_subscriber_stddev_order_by>;
  stddev_pop?: InputMaybe<portal_session_subscriber_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<portal_session_subscriber_stddev_samp_order_by>;
  sum?: InputMaybe<portal_session_subscriber_sum_order_by>;
  var_pop?: InputMaybe<portal_session_subscriber_var_pop_order_by>;
  var_samp?: InputMaybe<portal_session_subscriber_var_samp_order_by>;
  variance?: InputMaybe<portal_session_subscriber_variance_order_by>;
};

/** input type for inserting array relation for remote table "portal_session_subscriber" */
export type portal_session_subscriber_arr_rel_insert_input = {
  data: Array<portal_session_subscriber_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<portal_session_subscriber_on_conflict>;
};

/** aggregate avg on columns */
export type portal_session_subscriber_avg_fields = {
  __typename: 'portal_session_subscriber_avg_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_avg_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "portal_session_subscriber". All fields are combined with a logical 'AND'. */
export type portal_session_subscriber_bool_exp = {
  _and?: InputMaybe<Array<portal_session_subscriber_bool_exp>>;
  _not?: InputMaybe<portal_session_subscriber_bool_exp>;
  _or?: InputMaybe<Array<portal_session_subscriber_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  portal_session?: InputMaybe<portal_session_bool_exp>;
  portal_session_id?: InputMaybe<Int_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "portal_session_subscriber" */
export enum portal_session_subscriber_constraint {
  /** unique or primary key constraint on columns "portal_session_id", "subscriber_id" */
  portal_session_subscriber_pkey = 'portal_session_subscriber_pkey'
}

/** input type for incrementing numeric columns in table "portal_session_subscriber" */
export type portal_session_subscriber_inc_input = {
  portal_session_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "portal_session_subscriber" */
export type portal_session_subscriber_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  portal_session?: InputMaybe<portal_session_obj_rel_insert_input>;
  portal_session_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type portal_session_subscriber_max_fields = {
  __typename: 'portal_session_subscriber_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  portal_session_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_max_order_by = {
  created_at?: InputMaybe<order_by>;
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type portal_session_subscriber_min_fields = {
  __typename: 'portal_session_subscriber_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  portal_session_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_min_order_by = {
  created_at?: InputMaybe<order_by>;
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "portal_session_subscriber" */
export type portal_session_subscriber_mutation_response = {
  __typename: 'portal_session_subscriber_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<portal_session_subscriber>;
};

/** on_conflict condition type for table "portal_session_subscriber" */
export type portal_session_subscriber_on_conflict = {
  constraint: portal_session_subscriber_constraint;
  update_columns?: Array<portal_session_subscriber_update_column>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};

/** Ordering options when selecting data from "portal_session_subscriber". */
export type portal_session_subscriber_order_by = {
  created_at?: InputMaybe<order_by>;
  portal_session?: InputMaybe<portal_session_order_by>;
  portal_session_id?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: portal_session_subscriber */
export type portal_session_subscriber_pk_columns_input = {
  portal_session_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};

/** select columns of table "portal_session_subscriber" */
export enum portal_session_subscriber_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  portal_session_id = 'portal_session_id',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** input type for updating data in table "portal_session_subscriber" */
export type portal_session_subscriber_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  portal_session_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type portal_session_subscriber_stddev_fields = {
  __typename: 'portal_session_subscriber_stddev_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_stddev_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type portal_session_subscriber_stddev_pop_fields = {
  __typename: 'portal_session_subscriber_stddev_pop_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_stddev_pop_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type portal_session_subscriber_stddev_samp_fields = {
  __typename: 'portal_session_subscriber_stddev_samp_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_stddev_samp_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "portal_session_subscriber" */
export type portal_session_subscriber_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: portal_session_subscriber_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type portal_session_subscriber_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  portal_session_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type portal_session_subscriber_sum_fields = {
  __typename: 'portal_session_subscriber_sum_fields';
  portal_session_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_sum_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** update columns of table "portal_session_subscriber" */
export enum portal_session_subscriber_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  portal_session_id = 'portal_session_id',
  /** column name */
  subscriber_id = 'subscriber_id'
}

export type portal_session_subscriber_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<portal_session_subscriber_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<portal_session_subscriber_set_input>;
  /** filter the rows which have to be updated */
  where: portal_session_subscriber_bool_exp;
};

/** aggregate var_pop on columns */
export type portal_session_subscriber_var_pop_fields = {
  __typename: 'portal_session_subscriber_var_pop_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_var_pop_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type portal_session_subscriber_var_samp_fields = {
  __typename: 'portal_session_subscriber_var_samp_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_var_samp_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type portal_session_subscriber_variance_fields = {
  __typename: 'portal_session_subscriber_variance_fields';
  portal_session_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "portal_session_subscriber" */
export type portal_session_subscriber_variance_order_by = {
  portal_session_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate sum on columns */
export type portal_session_sum_fields = {
  __typename: 'portal_session_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "portal_session" */
export type portal_session_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "portal_session" */
export enum portal_session_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  id = 'id',
  /** column name */
  return_url = 'return_url',
  /** column name */
  token = 'token'
}

export type portal_session_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<portal_session_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<portal_session_set_input>;
  /** filter the rows which have to be updated */
  where: portal_session_bool_exp;
};

/** aggregate var_pop on columns */
export type portal_session_var_pop_fields = {
  __typename: 'portal_session_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "portal_session" */
export type portal_session_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type portal_session_var_samp_fields = {
  __typename: 'portal_session_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "portal_session" */
export type portal_session_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type portal_session_variance_fields = {
  __typename: 'portal_session_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "portal_session" */
export type portal_session_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "product" */
export type product = {
  __typename: 'product';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  subscription_products: Array<subscription_product>;
  /** An aggregate relationship */
  subscription_products_aggregate: subscription_product_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "product" */
export type productsubscription_productsArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


/** columns and relationships of "product" */
export type productsubscription_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};

/** aggregated selection of "product" */
export type product_aggregate = {
  __typename: 'product_aggregate';
  aggregate?: Maybe<product_aggregate_fields>;
  nodes: Array<product>;
};

export type product_aggregate_bool_exp = {
  count?: InputMaybe<product_aggregate_bool_exp_count>;
};

export type product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "product" */
export type product_aggregate_fields = {
  __typename: 'product_aggregate_fields';
  avg?: Maybe<product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<product_max_fields>;
  min?: Maybe<product_min_fields>;
  stddev?: Maybe<product_stddev_fields>;
  stddev_pop?: Maybe<product_stddev_pop_fields>;
  stddev_samp?: Maybe<product_stddev_samp_fields>;
  sum?: Maybe<product_sum_fields>;
  var_pop?: Maybe<product_var_pop_fields>;
  var_samp?: Maybe<product_var_samp_fields>;
  variance?: Maybe<product_variance_fields>;
};


/** aggregate fields of "product" */
export type product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "product" */
export type product_aggregate_order_by = {
  avg?: InputMaybe<product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<product_max_order_by>;
  min?: InputMaybe<product_min_order_by>;
  stddev?: InputMaybe<product_stddev_order_by>;
  stddev_pop?: InputMaybe<product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<product_stddev_samp_order_by>;
  sum?: InputMaybe<product_sum_order_by>;
  var_pop?: InputMaybe<product_var_pop_order_by>;
  var_samp?: InputMaybe<product_var_samp_order_by>;
  variance?: InputMaybe<product_variance_order_by>;
};

/** input type for inserting array relation for remote table "product" */
export type product_arr_rel_insert_input = {
  data: Array<product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<product_on_conflict>;
};

/** aggregate avg on columns */
export type product_avg_fields = {
  __typename: 'product_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "product" */
export type product_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type product_bool_exp = {
  _and?: InputMaybe<Array<product_bool_exp>>;
  _not?: InputMaybe<product_bool_exp>;
  _or?: InputMaybe<Array<product_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  subscription_products?: InputMaybe<subscription_product_bool_exp>;
  subscription_products_aggregate?: InputMaybe<subscription_product_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "product" */
export enum product_constraint {
  /** unique or primary key constraint on columns "account_id", "platform_id" */
  product_account_id_platform_id_key = 'product_account_id_platform_id_key',
  /** unique or primary key constraint on columns "id" */
  product_pkey = 'product_pkey'
}

/** input type for incrementing numeric columns in table "product" */
export type product_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "product" */
export type product_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  subscription_products?: InputMaybe<subscription_product_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type product_max_fields = {
  __typename: 'product_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "product" */
export type product_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type product_min_fields = {
  __typename: 'product_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "product" */
export type product_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "product" */
export type product_mutation_response = {
  __typename: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<product>;
};

/** input type for inserting object relation for remote table "product" */
export type product_obj_rel_insert_input = {
  data: product_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<product_on_conflict>;
};

/** on_conflict condition type for table "product" */
export type product_on_conflict = {
  constraint: product_constraint;
  update_columns?: Array<product_update_column>;
  where?: InputMaybe<product_bool_exp>;
};

/** Ordering options when selecting data from "product". */
export type product_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  subscription_products_aggregate?: InputMaybe<subscription_product_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: product */
export type product_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "product" */
export enum product_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "product" */
export type product_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type product_stddev_fields = {
  __typename: 'product_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "product" */
export type product_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type product_stddev_pop_fields = {
  __typename: 'product_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "product" */
export type product_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type product_stddev_samp_fields = {
  __typename: 'product_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "product" */
export type product_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "product" */
export type product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type product_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type product_sum_fields = {
  __typename: 'product_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "product" */
export type product_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "product" */
export enum product_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  updated_at = 'updated_at'
}

export type product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<product_set_input>;
  /** filter the rows which have to be updated */
  where: product_bool_exp;
};

/** aggregate var_pop on columns */
export type product_var_pop_fields = {
  __typename: 'product_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "product" */
export type product_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type product_var_samp_fields = {
  __typename: 'product_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "product" */
export type product_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type product_variance_fields = {
  __typename: 'product_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "product" */
export type product_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "property" */
export type property = {
  __typename: 'property';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  entity: property_entity_enum;
  format?: Maybe<property_format_enum>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
  type: property_type_enum;
};

/** aggregated selection of "property" */
export type property_aggregate = {
  __typename: 'property_aggregate';
  aggregate?: Maybe<property_aggregate_fields>;
  nodes: Array<property>;
};

export type property_aggregate_bool_exp = {
  count?: InputMaybe<property_aggregate_bool_exp_count>;
};

export type property_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<property_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "property" */
export type property_aggregate_fields = {
  __typename: 'property_aggregate_fields';
  avg?: Maybe<property_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<property_max_fields>;
  min?: Maybe<property_min_fields>;
  stddev?: Maybe<property_stddev_fields>;
  stddev_pop?: Maybe<property_stddev_pop_fields>;
  stddev_samp?: Maybe<property_stddev_samp_fields>;
  sum?: Maybe<property_sum_fields>;
  var_pop?: Maybe<property_var_pop_fields>;
  var_samp?: Maybe<property_var_samp_fields>;
  variance?: Maybe<property_variance_fields>;
};


/** aggregate fields of "property" */
export type property_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "property" */
export type property_aggregate_order_by = {
  avg?: InputMaybe<property_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<property_max_order_by>;
  min?: InputMaybe<property_min_order_by>;
  stddev?: InputMaybe<property_stddev_order_by>;
  stddev_pop?: InputMaybe<property_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<property_stddev_samp_order_by>;
  sum?: InputMaybe<property_sum_order_by>;
  var_pop?: InputMaybe<property_var_pop_order_by>;
  var_samp?: InputMaybe<property_var_samp_order_by>;
  variance?: InputMaybe<property_variance_order_by>;
};

/** input type for inserting array relation for remote table "property" */
export type property_arr_rel_insert_input = {
  data: Array<property_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<property_on_conflict>;
};

/** aggregate avg on columns */
export type property_avg_fields = {
  __typename: 'property_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "property" */
export type property_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
export type property_bool_exp = {
  _and?: InputMaybe<Array<property_bool_exp>>;
  _not?: InputMaybe<property_bool_exp>;
  _or?: InputMaybe<Array<property_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  entity?: InputMaybe<property_entity_enum_comparison_exp>;
  format?: InputMaybe<property_format_enum_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<property_type_enum_comparison_exp>;
};

/** unique or primary key constraints on table "property" */
export enum property_constraint {
  /** unique or primary key constraint on columns "key", "account_id", "entity", "deleted_at" */
  property_account_id_entity_key_deleted_at_key = 'property_account_id_entity_key_deleted_at_key',
  /** unique or primary key constraint on columns "account_id", "entity", "deleted_at", "name" */
  property_account_id_entity_name_deleted_at_key = 'property_account_id_entity_name_deleted_at_key',
  /** unique or primary key constraint on columns "id" */
  property_pkey = 'property_pkey',
  /** unique or primary key constraint on columns "token" */
  property_token_key = 'property_token_key'
}

/** columns and relationships of "property_entity" */
export type property_entity = {
  __typename: 'property_entity';
  value: Scalars['String']['output'];
};

/** aggregated selection of "property_entity" */
export type property_entity_aggregate = {
  __typename: 'property_entity_aggregate';
  aggregate?: Maybe<property_entity_aggregate_fields>;
  nodes: Array<property_entity>;
};

/** aggregate fields of "property_entity" */
export type property_entity_aggregate_fields = {
  __typename: 'property_entity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<property_entity_max_fields>;
  min?: Maybe<property_entity_min_fields>;
};


/** aggregate fields of "property_entity" */
export type property_entity_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<property_entity_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_entity". All fields are combined with a logical 'AND'. */
export type property_entity_bool_exp = {
  _and?: InputMaybe<Array<property_entity_bool_exp>>;
  _not?: InputMaybe<property_entity_bool_exp>;
  _or?: InputMaybe<Array<property_entity_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "property_entity" */
export enum property_entity_constraint {
  /** unique or primary key constraint on columns "value" */
  property_entity_pkey = 'property_entity_pkey'
}

export enum property_entity_enum {
  subscriber = 'subscriber',
  subscription = 'subscription'
}

/** Boolean expression to compare columns of type "property_entity_enum". All fields are combined with logical 'AND'. */
export type property_entity_enum_comparison_exp = {
  _eq?: InputMaybe<property_entity_enum>;
  _in?: InputMaybe<Array<property_entity_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<property_entity_enum>;
  _nin?: InputMaybe<Array<property_entity_enum>>;
};

/** input type for inserting data into table "property_entity" */
export type property_entity_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type property_entity_max_fields = {
  __typename: 'property_entity_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type property_entity_min_fields = {
  __typename: 'property_entity_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_entity" */
export type property_entity_mutation_response = {
  __typename: 'property_entity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<property_entity>;
};

/** on_conflict condition type for table "property_entity" */
export type property_entity_on_conflict = {
  constraint: property_entity_constraint;
  update_columns?: Array<property_entity_update_column>;
  where?: InputMaybe<property_entity_bool_exp>;
};

/** Ordering options when selecting data from "property_entity". */
export type property_entity_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: property_entity */
export type property_entity_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "property_entity" */
export enum property_entity_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "property_entity" */
export type property_entity_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_entity" */
export type property_entity_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: property_entity_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type property_entity_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_entity" */
export enum property_entity_update_column {
  /** column name */
  value = 'value'
}

export type property_entity_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<property_entity_set_input>;
  /** filter the rows which have to be updated */
  where: property_entity_bool_exp;
};

/** columns and relationships of "property_format" */
export type property_format = {
  __typename: 'property_format';
  value: Scalars['String']['output'];
};

/** aggregated selection of "property_format" */
export type property_format_aggregate = {
  __typename: 'property_format_aggregate';
  aggregate?: Maybe<property_format_aggregate_fields>;
  nodes: Array<property_format>;
};

/** aggregate fields of "property_format" */
export type property_format_aggregate_fields = {
  __typename: 'property_format_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<property_format_max_fields>;
  min?: Maybe<property_format_min_fields>;
};


/** aggregate fields of "property_format" */
export type property_format_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<property_format_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_format". All fields are combined with a logical 'AND'. */
export type property_format_bool_exp = {
  _and?: InputMaybe<Array<property_format_bool_exp>>;
  _not?: InputMaybe<property_format_bool_exp>;
  _or?: InputMaybe<Array<property_format_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "property_format" */
export enum property_format_constraint {
  /** unique or primary key constraint on columns "value" */
  property_format_pkey = 'property_format_pkey'
}

export enum property_format_enum {
  currency = 'currency',
  number = 'number'
}

/** Boolean expression to compare columns of type "property_format_enum". All fields are combined with logical 'AND'. */
export type property_format_enum_comparison_exp = {
  _eq?: InputMaybe<property_format_enum>;
  _in?: InputMaybe<Array<property_format_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<property_format_enum>;
  _nin?: InputMaybe<Array<property_format_enum>>;
};

/** input type for inserting data into table "property_format" */
export type property_format_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type property_format_max_fields = {
  __typename: 'property_format_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type property_format_min_fields = {
  __typename: 'property_format_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_format" */
export type property_format_mutation_response = {
  __typename: 'property_format_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<property_format>;
};

/** on_conflict condition type for table "property_format" */
export type property_format_on_conflict = {
  constraint: property_format_constraint;
  update_columns?: Array<property_format_update_column>;
  where?: InputMaybe<property_format_bool_exp>;
};

/** Ordering options when selecting data from "property_format". */
export type property_format_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: property_format */
export type property_format_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "property_format" */
export enum property_format_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "property_format" */
export type property_format_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_format" */
export type property_format_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: property_format_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type property_format_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_format" */
export enum property_format_update_column {
  /** column name */
  value = 'value'
}

export type property_format_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<property_format_set_input>;
  /** filter the rows which have to be updated */
  where: property_format_bool_exp;
};

/** input type for incrementing numeric columns in table "property" */
export type property_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "property" */
export type property_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity?: InputMaybe<property_entity_enum>;
  format?: InputMaybe<property_format_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<property_type_enum>;
};

/** aggregate max on columns */
export type property_max_fields = {
  __typename: 'property_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "property" */
export type property_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type property_min_fields = {
  __typename: 'property_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "property" */
export type property_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** response of any mutation on the table "property" */
export type property_mutation_response = {
  __typename: 'property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<property>;
};

/** input type for inserting object relation for remote table "property" */
export type property_obj_rel_insert_input = {
  data: property_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<property_on_conflict>;
};

/** on_conflict condition type for table "property" */
export type property_on_conflict = {
  constraint: property_constraint;
  update_columns?: Array<property_update_column>;
  where?: InputMaybe<property_bool_exp>;
};

/** Ordering options when selecting data from "property". */
export type property_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  entity?: InputMaybe<order_by>;
  format?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
};

/** primary key columns input for table: property */
export type property_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "property" */
export enum property_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  entity = 'entity',
  /** column name */
  format = 'format',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "property" */
export type property_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity?: InputMaybe<property_entity_enum>;
  format?: InputMaybe<property_format_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<property_type_enum>;
};

/** aggregate stddev on columns */
export type property_stddev_fields = {
  __typename: 'property_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "property" */
export type property_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type property_stddev_pop_fields = {
  __typename: 'property_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "property" */
export type property_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type property_stddev_samp_fields = {
  __typename: 'property_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "property" */
export type property_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "property" */
export type property_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: property_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type property_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity?: InputMaybe<property_entity_enum>;
  format?: InputMaybe<property_format_enum>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<property_type_enum>;
};

/** aggregate sum on columns */
export type property_sum_fields = {
  __typename: 'property_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "property" */
export type property_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** columns and relationships of "property_tsvector" */
export type property_tsvector = {
  __typename: 'property_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "property_tsvector" */
export type property_tsvector_aggregate = {
  __typename: 'property_tsvector_aggregate';
  aggregate?: Maybe<property_tsvector_aggregate_fields>;
  nodes: Array<property_tsvector>;
};

/** aggregate fields of "property_tsvector" */
export type property_tsvector_aggregate_fields = {
  __typename: 'property_tsvector_aggregate_fields';
  avg?: Maybe<property_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<property_tsvector_max_fields>;
  min?: Maybe<property_tsvector_min_fields>;
  stddev?: Maybe<property_tsvector_stddev_fields>;
  stddev_pop?: Maybe<property_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<property_tsvector_stddev_samp_fields>;
  sum?: Maybe<property_tsvector_sum_fields>;
  var_pop?: Maybe<property_tsvector_var_pop_fields>;
  var_samp?: Maybe<property_tsvector_var_samp_fields>;
  variance?: Maybe<property_tsvector_variance_fields>;
};


/** aggregate fields of "property_tsvector" */
export type property_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<property_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type property_tsvector_avg_fields = {
  __typename: 'property_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "property_tsvector". All fields are combined with a logical 'AND'. */
export type property_tsvector_bool_exp = {
  _and?: InputMaybe<Array<property_tsvector_bool_exp>>;
  _not?: InputMaybe<property_tsvector_bool_exp>;
  _or?: InputMaybe<Array<property_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type property_tsvector_max_fields = {
  __typename: 'property_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type property_tsvector_min_fields = {
  __typename: 'property_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "property_tsvector". */
export type property_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "property_tsvector" */
export enum property_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  property_id = 'property_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type property_tsvector_stddev_fields = {
  __typename: 'property_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type property_tsvector_stddev_pop_fields = {
  __typename: 'property_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type property_tsvector_stddev_samp_fields = {
  __typename: 'property_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "property_tsvector" */
export type property_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: property_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type property_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type property_tsvector_sum_fields = {
  __typename: 'property_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type property_tsvector_var_pop_fields = {
  __typename: 'property_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type property_tsvector_var_samp_fields = {
  __typename: 'property_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type property_tsvector_variance_fields = {
  __typename: 'property_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "property_type" */
export type property_type = {
  __typename: 'property_type';
  value: Scalars['String']['output'];
};

/** aggregated selection of "property_type" */
export type property_type_aggregate = {
  __typename: 'property_type_aggregate';
  aggregate?: Maybe<property_type_aggregate_fields>;
  nodes: Array<property_type>;
};

/** aggregate fields of "property_type" */
export type property_type_aggregate_fields = {
  __typename: 'property_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<property_type_max_fields>;
  min?: Maybe<property_type_min_fields>;
};


/** aggregate fields of "property_type" */
export type property_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<property_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
export type property_type_bool_exp = {
  _and?: InputMaybe<Array<property_type_bool_exp>>;
  _not?: InputMaybe<property_type_bool_exp>;
  _or?: InputMaybe<Array<property_type_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "property_type" */
export enum property_type_constraint {
  /** unique or primary key constraint on columns "value" */
  property_type_pkey = 'property_type_pkey'
}

export enum property_type_enum {
  boolean = 'boolean',
  date = 'date',
  number = 'number',
  text = 'text',
  timestamp = 'timestamp'
}

/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
export type property_type_enum_comparison_exp = {
  _eq?: InputMaybe<property_type_enum>;
  _in?: InputMaybe<Array<property_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<property_type_enum>;
  _nin?: InputMaybe<Array<property_type_enum>>;
};

/** input type for inserting data into table "property_type" */
export type property_type_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type property_type_max_fields = {
  __typename: 'property_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type property_type_min_fields = {
  __typename: 'property_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "property_type" */
export type property_type_mutation_response = {
  __typename: 'property_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<property_type>;
};

/** on_conflict condition type for table "property_type" */
export type property_type_on_conflict = {
  constraint: property_type_constraint;
  update_columns?: Array<property_type_update_column>;
  where?: InputMaybe<property_type_bool_exp>;
};

/** Ordering options when selecting data from "property_type". */
export type property_type_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: property_type */
export type property_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "property_type" */
export enum property_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "property_type" */
export type property_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "property_type" */
export type property_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: property_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type property_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "property_type" */
export enum property_type_update_column {
  /** column name */
  value = 'value'
}

export type property_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<property_type_set_input>;
  /** filter the rows which have to be updated */
  where: property_type_bool_exp;
};

/** update columns of table "property" */
export enum property_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  entity = 'entity',
  /** column name */
  format = 'format',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type'
}

export type property_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<property_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<property_set_input>;
  /** filter the rows which have to be updated */
  where: property_bool_exp;
};

/** aggregate var_pop on columns */
export type property_var_pop_fields = {
  __typename: 'property_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "property" */
export type property_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type property_var_samp_fields = {
  __typename: 'property_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "property" */
export type property_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type property_variance_fields = {
  __typename: 'property_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "property" */
export type property_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export type query_root = {
  __typename: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<account>;
  accountSubscription: AccountSubscriptionOutput;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: account_aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<account>;
  /** fetch data from the table: "account_feature" */
  account_feature: Array<account_feature>;
  /** fetch aggregated fields from the table: "account_feature" */
  account_feature_aggregate: account_feature_aggregate;
  /** fetch data from the table: "account_feature" using primary key columns */
  account_feature_by_pk?: Maybe<account_feature>;
  /** fetch data from the table: "account_feature_key" */
  account_feature_key: Array<account_feature_key>;
  /** fetch aggregated fields from the table: "account_feature_key" */
  account_feature_key_aggregate: account_feature_key_aggregate;
  /** fetch data from the table: "account_feature_key" using primary key columns */
  account_feature_key_by_pk?: Maybe<account_feature_key>;
  /** fetch data from the table: "account_mode" */
  account_mode: Array<account_mode>;
  /** fetch aggregated fields from the table: "account_mode" */
  account_mode_aggregate: account_mode_aggregate;
  /** fetch data from the table: "account_mode" using primary key columns */
  account_mode_by_pk?: Maybe<account_mode>;
  /** fetch data from the table: "account_revenue_mode" */
  account_revenue_mode: Array<account_revenue_mode>;
  /** fetch aggregated fields from the table: "account_revenue_mode" */
  account_revenue_mode_aggregate: account_revenue_mode_aggregate;
  /** fetch data from the table: "account_revenue_mode" using primary key columns */
  account_revenue_mode_by_pk?: Maybe<account_revenue_mode>;
  /** fetch data from the table: "account_setting" */
  account_setting: Array<account_setting>;
  /** fetch aggregated fields from the table: "account_setting" */
  account_setting_aggregate: account_setting_aggregate;
  /** fetch data from the table: "account_setting" using primary key columns */
  account_setting_by_pk?: Maybe<account_setting>;
  /** fetch data from the table: "account_setting_key" */
  account_setting_key: Array<account_setting_key>;
  /** fetch aggregated fields from the table: "account_setting_key" */
  account_setting_key_aggregate: account_setting_key_aggregate;
  /** fetch data from the table: "account_setting_key" using primary key columns */
  account_setting_key_by_pk?: Maybe<account_setting_key>;
  /** fetch data from the table: "account_status" */
  account_status: Array<account_status>;
  /** fetch aggregated fields from the table: "account_status" */
  account_status_aggregate: account_status_aggregate;
  /** fetch data from the table: "account_status" using primary key columns */
  account_status_by_pk?: Maybe<account_status>;
  /** fetch data from the table: "account_tsvector" */
  account_tsvector: Array<account_tsvector>;
  /** fetch aggregated fields from the table: "account_tsvector" */
  account_tsvector_aggregate: account_tsvector_aggregate;
  /** fetch data from the table: "account_user" */
  account_user: Array<account_user>;
  /** fetch aggregated fields from the table: "account_user" */
  account_user_aggregate: account_user_aggregate;
  /** fetch data from the table: "account_user" using primary key columns */
  account_user_by_pk?: Maybe<account_user>;
  /** fetch data from the table: "account_user_setting" */
  account_user_setting: Array<account_user_setting>;
  /** fetch aggregated fields from the table: "account_user_setting" */
  account_user_setting_aggregate: account_user_setting_aggregate;
  /** fetch data from the table: "account_user_setting" using primary key columns */
  account_user_setting_by_pk?: Maybe<account_user_setting>;
  /** fetch data from the table: "account_user_setting_key" */
  account_user_setting_key: Array<account_user_setting_key>;
  /** fetch aggregated fields from the table: "account_user_setting_key" */
  account_user_setting_key_aggregate: account_user_setting_key_aggregate;
  /** fetch data from the table: "account_user_setting_key" using primary key columns */
  account_user_setting_key_by_pk?: Maybe<account_user_setting_key>;
  /** fetch data from the table: "acknowledgement" */
  acknowledgement: Array<acknowledgement>;
  /** fetch aggregated fields from the table: "acknowledgement" */
  acknowledgement_aggregate: acknowledgement_aggregate;
  /** fetch data from the table: "acknowledgement" using primary key columns */
  acknowledgement_by_pk?: Maybe<acknowledgement>;
  /** fetch data from the table: "acknowledgement_group" */
  acknowledgement_group: Array<acknowledgement_group>;
  /** fetch aggregated fields from the table: "acknowledgement_group" */
  acknowledgement_group_aggregate: acknowledgement_group_aggregate;
  /** fetch data from the table: "acknowledgement_group" using primary key columns */
  acknowledgement_group_by_pk?: Maybe<acknowledgement_group>;
  /** fetch data from the table: "acknowledgement_group_version" */
  acknowledgement_group_version: Array<acknowledgement_group_version>;
  /** fetch data from the table: "acknowledgement_group_version_acknowledgement" */
  acknowledgement_group_version_acknowledgement: Array<acknowledgement_group_version_acknowledgement>;
  /** fetch aggregated fields from the table: "acknowledgement_group_version_acknowledgement" */
  acknowledgement_group_version_acknowledgement_aggregate: acknowledgement_group_version_acknowledgement_aggregate;
  /** fetch aggregated fields from the table: "acknowledgement_group_version" */
  acknowledgement_group_version_aggregate: acknowledgement_group_version_aggregate;
  /** fetch data from the table: "acknowledgement_group_version" using primary key columns */
  acknowledgement_group_version_by_pk?: Maybe<acknowledgement_group_version>;
  /** fetch data from the table: "acknowledgement_version" */
  acknowledgement_version: Array<acknowledgement_version>;
  /** fetch aggregated fields from the table: "acknowledgement_version" */
  acknowledgement_version_aggregate: acknowledgement_version_aggregate;
  /** fetch data from the table: "acknowledgement_version" using primary key columns */
  acknowledgement_version_by_pk?: Maybe<acknowledgement_version>;
  /** fetch data from the table: "active_portal_session" */
  active_portal_session: Array<active_portal_session>;
  /** fetch aggregated fields from the table: "active_portal_session" */
  active_portal_session_aggregate: active_portal_session_aggregate;
  /** fetch data from the table: "active_saved_mrr_by_month" */
  active_saved_mrr_by_month: Array<active_saved_mrr_by_month>;
  /** fetch aggregated fields from the table: "active_saved_mrr_by_month" */
  active_saved_mrr_by_month_aggregate: active_saved_mrr_by_month_aggregate;
  /** fetch data from the table: "annotation" */
  annotation: Array<annotation>;
  /** fetch aggregated fields from the table: "annotation" */
  annotation_aggregate: annotation_aggregate;
  /** fetch data from the table: "annotation" using primary key columns */
  annotation_by_pk?: Maybe<annotation>;
  /** fetch data from the table: "annotation_flow_version" */
  annotation_flow_version: Array<annotation_flow_version>;
  /** fetch aggregated fields from the table: "annotation_flow_version" */
  annotation_flow_version_aggregate: annotation_flow_version_aggregate;
  /** fetch data from the table: "annotation_flow_version" using primary key columns */
  annotation_flow_version_by_pk?: Maybe<annotation_flow_version>;
  /** fetch data from the table: "annotation_type" */
  annotation_type: Array<annotation_type>;
  /** fetch aggregated fields from the table: "annotation_type" */
  annotation_type_aggregate: annotation_type_aggregate;
  /** fetch data from the table: "annotation_type" using primary key columns */
  annotation_type_by_pk?: Maybe<annotation_type>;
  /** fetch data from the table: "api_key" */
  api_key: Array<api_key>;
  /** fetch aggregated fields from the table: "api_key" */
  api_key_aggregate: api_key_aggregate;
  /** fetch data from the table: "api_key" using primary key columns */
  api_key_by_pk?: Maybe<api_key>;
  /** fetch data from the table: "api_key_purpose" */
  api_key_purpose: Array<api_key_purpose>;
  /** fetch aggregated fields from the table: "api_key_purpose" */
  api_key_purpose_aggregate: api_key_purpose_aggregate;
  /** fetch data from the table: "api_key_purpose" using primary key columns */
  api_key_purpose_by_pk?: Maybe<api_key_purpose>;
  availableNavigaDomains: AvailableNavigaDomainsOutput;
  /** fetch data from the table: "campaign" */
  campaign: Array<campaign>;
  /** fetch aggregated fields from the table: "campaign" */
  campaign_aggregate: campaign_aggregate;
  /** fetch data from the table: "campaign" using primary key columns */
  campaign_by_pk?: Maybe<campaign>;
  /** fetch data from the table: "campaign_text" */
  campaign_text: Array<campaign_text>;
  /** fetch aggregated fields from the table: "campaign_text" */
  campaign_text_aggregate: campaign_text_aggregate;
  /** fetch data from the table: "campaign_text" using primary key columns */
  campaign_text_by_pk?: Maybe<campaign_text>;
  /** fetch data from the table: "campaign_text_version" */
  campaign_text_version: Array<campaign_text_version>;
  /** fetch aggregated fields from the table: "campaign_text_version" */
  campaign_text_version_aggregate: campaign_text_version_aggregate;
  /** fetch data from the table: "campaign_text_version" using primary key columns */
  campaign_text_version_by_pk?: Maybe<campaign_text_version>;
  /** fetch data from the table: "campaign_trigger_type" */
  campaign_trigger_type: Array<campaign_trigger_type>;
  /** fetch aggregated fields from the table: "campaign_trigger_type" */
  campaign_trigger_type_aggregate: campaign_trigger_type_aggregate;
  /** fetch data from the table: "campaign_trigger_type" using primary key columns */
  campaign_trigger_type_by_pk?: Maybe<campaign_trigger_type>;
  /** fetch data from the table: "campaign_version" */
  campaign_version: Array<campaign_version>;
  /** fetch aggregated fields from the table: "campaign_version" */
  campaign_version_aggregate: campaign_version_aggregate;
  /** fetch data from the table: "campaign_version" using primary key columns */
  campaign_version_by_pk?: Maybe<campaign_version>;
  /** fetch data from the table: "cancellations_by_month" */
  cancellations_by_month: Array<cancellations_by_month>;
  /** fetch aggregated fields from the table: "cancellations_by_month" */
  cancellations_by_month_aggregate: cancellations_by_month_aggregate;
  /** fetch data from the table: "confirmation" */
  confirmation: Array<confirmation>;
  /** fetch aggregated fields from the table: "confirmation" */
  confirmation_aggregate: confirmation_aggregate;
  /** fetch data from the table: "confirmation" using primary key columns */
  confirmation_by_pk?: Maybe<confirmation>;
  /** fetch data from the table: "confirmation_version" */
  confirmation_version: Array<confirmation_version>;
  /** fetch aggregated fields from the table: "confirmation_version" */
  confirmation_version_aggregate: confirmation_version_aggregate;
  /** fetch data from the table: "confirmation_version" using primary key columns */
  confirmation_version_by_pk?: Maybe<confirmation_version>;
  /** fetch data from the table: "country" */
  country: Array<country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: country_aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<country>;
  /** fetch data from the table: "coupon_duration" */
  coupon_duration: Array<coupon_duration>;
  /** fetch aggregated fields from the table: "coupon_duration" */
  coupon_duration_aggregate: coupon_duration_aggregate;
  /** fetch data from the table: "coupon_duration" using primary key columns */
  coupon_duration_by_pk?: Maybe<coupon_duration>;
  /** fetch data from the table: "coupon_duration_interval" */
  coupon_duration_interval: Array<coupon_duration_interval>;
  /** fetch aggregated fields from the table: "coupon_duration_interval" */
  coupon_duration_interval_aggregate: coupon_duration_interval_aggregate;
  /** fetch data from the table: "coupon_duration_interval" using primary key columns */
  coupon_duration_interval_by_pk?: Maybe<coupon_duration_interval>;
  /** fetch data from the table: "csv_delta_file" */
  csv_delta_file: Array<csv_delta_file>;
  /** fetch aggregated fields from the table: "csv_delta_file" */
  csv_delta_file_aggregate: csv_delta_file_aggregate;
  /** fetch data from the table: "csv_delta_file" using primary key columns */
  csv_delta_file_by_pk?: Maybe<csv_delta_file>;
  /** fetch data from the table: "csv_import" */
  csv_import: Array<csv_import>;
  /** fetch aggregated fields from the table: "csv_import" */
  csv_import_aggregate: csv_import_aggregate;
  /** fetch data from the table: "csv_import" using primary key columns */
  csv_import_by_pk?: Maybe<csv_import>;
  /** fetch data from the table: "csv_import_file" */
  csv_import_file: Array<csv_import_file>;
  /** fetch aggregated fields from the table: "csv_import_file" */
  csv_import_file_aggregate: csv_import_file_aggregate;
  /** fetch data from the table: "csv_import_file" using primary key columns */
  csv_import_file_by_pk?: Maybe<csv_import_file>;
  /** fetch data from the table: "csv_import_status" */
  csv_import_status: Array<csv_import_status>;
  /** fetch aggregated fields from the table: "csv_import_status" */
  csv_import_status_aggregate: csv_import_status_aggregate;
  /** fetch data from the table: "csv_import_status" using primary key columns */
  csv_import_status_by_pk?: Maybe<csv_import_status>;
  /** fetch data from the table: "customers_by_month" */
  customers_by_month: Array<customers_by_month>;
  /** fetch aggregated fields from the table: "customers_by_month" */
  customers_by_month_aggregate: customers_by_month_aggregate;
  /** fetch data from the table: "data_connector" */
  data_connector: Array<data_connector>;
  /** fetch aggregated fields from the table: "data_connector" */
  data_connector_aggregate: data_connector_aggregate;
  /** fetch data from the table: "data_connector" using primary key columns */
  data_connector_by_pk?: Maybe<data_connector>;
  /** fetch data from the table: "data_connector_request_log" */
  data_connector_request_log: Array<data_connector_request_log>;
  /** fetch aggregated fields from the table: "data_connector_request_log" */
  data_connector_request_log_aggregate: data_connector_request_log_aggregate;
  /** fetch data from the table: "data_connector_request_log" using primary key columns */
  data_connector_request_log_by_pk?: Maybe<data_connector_request_log>;
  /** fetch data from the table: "data_connector_request_log_tsvector" */
  data_connector_request_log_tsvector: Array<data_connector_request_log_tsvector>;
  /** fetch aggregated fields from the table: "data_connector_request_log_tsvector" */
  data_connector_request_log_tsvector_aggregate: data_connector_request_log_tsvector_aggregate;
  /** fetch data from the table: "deflection" */
  deflection: Array<deflection>;
  /** fetch aggregated fields from the table: "deflection" */
  deflection_aggregate: deflection_aggregate;
  /** fetch data from the table: "deflection" using primary key columns */
  deflection_by_pk?: Maybe<deflection>;
  /** fetch data from the table: "deflection_flow_action" */
  deflection_flow_action: Array<deflection_flow_action>;
  /** fetch aggregated fields from the table: "deflection_flow_action" */
  deflection_flow_action_aggregate: deflection_flow_action_aggregate;
  /** fetch data from the table: "deflection_snapshot_item" */
  deflection_snapshot_item: Array<deflection_snapshot_item>;
  /** fetch aggregated fields from the table: "deflection_snapshot_item" */
  deflection_snapshot_item_aggregate: deflection_snapshot_item_aggregate;
  /** fetch data from the table: "deflection_snapshot_item" using primary key columns */
  deflection_snapshot_item_by_pk?: Maybe<deflection_snapshot_item>;
  /** fetch data from the table: "deflection_snapshot_item_position" */
  deflection_snapshot_item_position: Array<deflection_snapshot_item_position>;
  /** fetch aggregated fields from the table: "deflection_snapshot_item_position" */
  deflection_snapshot_item_position_aggregate: deflection_snapshot_item_position_aggregate;
  /** fetch data from the table: "deflection_tag" */
  deflection_tag: Array<deflection_tag>;
  /** fetch aggregated fields from the table: "deflection_tag" */
  deflection_tag_aggregate: deflection_tag_aggregate;
  /** fetch data from the table: "deflection_tsvector" */
  deflection_tsvector: Array<deflection_tsvector>;
  /** fetch aggregated fields from the table: "deflection_tsvector" */
  deflection_tsvector_aggregate: deflection_tsvector_aggregate;
  /** fetch data from the table: "deflection_type" */
  deflection_type: Array<deflection_type>;
  /** fetch aggregated fields from the table: "deflection_type" */
  deflection_type_aggregate: deflection_type_aggregate;
  /** fetch data from the table: "deflection_type" using primary key columns */
  deflection_type_by_pk?: Maybe<deflection_type>;
  /** fetch data from the table: "demo" */
  demo: Array<demo>;
  /** fetch aggregated fields from the table: "demo" */
  demo_aggregate: demo_aggregate;
  /** fetch data from the table: "demo" using primary key columns */
  demo_by_pk?: Maybe<demo>;
  /** fetch data from the table: "domain" */
  domain: Array<domain>;
  domainRoute: DomainRouteOutput;
  /** fetch aggregated fields from the table: "domain" */
  domain_aggregate: domain_aggregate;
  /** fetch data from the table: "domain" using primary key columns */
  domain_by_pk?: Maybe<domain>;
  /** fetch data from the table: "domain_type" */
  domain_type: Array<domain_type>;
  /** fetch aggregated fields from the table: "domain_type" */
  domain_type_aggregate: domain_type_aggregate;
  /** fetch data from the table: "domain_type" using primary key columns */
  domain_type_by_pk?: Maybe<domain_type>;
  /** fetch data from the table: "eligibility_message" */
  eligibility_message: Array<eligibility_message>;
  /** fetch aggregated fields from the table: "eligibility_message" */
  eligibility_message_aggregate: eligibility_message_aggregate;
  /** fetch data from the table: "eligibility_message" using primary key columns */
  eligibility_message_by_pk?: Maybe<eligibility_message>;
  /** fetch data from the table: "event" */
  event: Array<event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: event_aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<event>;
  /** fetch data from the table: "event_type" */
  event_type: Array<event_type>;
  /** fetch aggregated fields from the table: "event_type" */
  event_type_aggregate: event_type_aggregate;
  /** fetch data from the table: "event_type" using primary key columns */
  event_type_by_pk?: Maybe<event_type>;
  /** fetch data from the table: "feedback_trends_grid" */
  feedback_trends_grid: Array<feedback_trends_grid>;
  /** fetch aggregated fields from the table: "feedback_trends_grid" */
  feedback_trends_grid_aggregate: feedback_trends_grid_aggregate;
  /** fetch data from the table: "flow" */
  flow: Array<flow>;
  flowOfferGroup: FlowOfferGroupOutput;
  flowTestOffer: FlowTestOfferOutput;
  /** fetch data from the table: "flow_action" */
  flow_action: Array<flow_action>;
  /** fetch aggregated fields from the table: "flow_action" */
  flow_action_aggregate: flow_action_aggregate;
  /** fetch data from the table: "flow_action_appearance" */
  flow_action_appearance: Array<flow_action_appearance>;
  /** fetch aggregated fields from the table: "flow_action_appearance" */
  flow_action_appearance_aggregate: flow_action_appearance_aggregate;
  /** fetch data from the table: "flow_action_appearance" using primary key columns */
  flow_action_appearance_by_pk?: Maybe<flow_action_appearance>;
  /** fetch data from the table: "flow_action" using primary key columns */
  flow_action_by_pk?: Maybe<flow_action>;
  /** fetch data from the table: "flow_action_type" */
  flow_action_type: Array<flow_action_type>;
  /** fetch aggregated fields from the table: "flow_action_type" */
  flow_action_type_aggregate: flow_action_type_aggregate;
  /** fetch data from the table: "flow_action_type" using primary key columns */
  flow_action_type_by_pk?: Maybe<flow_action_type>;
  /** fetch aggregated fields from the table: "flow" */
  flow_aggregate: flow_aggregate;
  /** fetch data from the table: "flow_auto_cancel_at" */
  flow_auto_cancel_at: Array<flow_auto_cancel_at>;
  /** fetch aggregated fields from the table: "flow_auto_cancel_at" */
  flow_auto_cancel_at_aggregate: flow_auto_cancel_at_aggregate;
  /** fetch data from the table: "flow_auto_cancel_at" using primary key columns */
  flow_auto_cancel_at_by_pk?: Maybe<flow_auto_cancel_at>;
  /** fetch data from the table: "flow" using primary key columns */
  flow_by_pk?: Maybe<flow>;
  /** fetch data from the table: "flow_language" */
  flow_language: Array<flow_language>;
  /** fetch aggregated fields from the table: "flow_language" */
  flow_language_aggregate: flow_language_aggregate;
  /** fetch data from the table: "flow_language" using primary key columns */
  flow_language_by_pk?: Maybe<flow_language>;
  /** fetch data from the table: "flow_on_cancel" */
  flow_on_cancel: Array<flow_on_cancel>;
  /** fetch aggregated fields from the table: "flow_on_cancel" */
  flow_on_cancel_aggregate: flow_on_cancel_aggregate;
  /** fetch data from the table: "flow_on_cancel" using primary key columns */
  flow_on_cancel_by_pk?: Maybe<flow_on_cancel>;
  /** fetch data from the table: "flow_on_cancel_change_plan" */
  flow_on_cancel_change_plan: Array<flow_on_cancel_change_plan>;
  /** fetch aggregated fields from the table: "flow_on_cancel_change_plan" */
  flow_on_cancel_change_plan_aggregate: flow_on_cancel_change_plan_aggregate;
  /** fetch data from the table: "flow_on_cancel_change_plan" using primary key columns */
  flow_on_cancel_change_plan_by_pk?: Maybe<flow_on_cancel_change_plan>;
  /** fetch data from the table: "flow_on_cancel_perform_at" */
  flow_on_cancel_perform_at: Array<flow_on_cancel_perform_at>;
  /** fetch aggregated fields from the table: "flow_on_cancel_perform_at" */
  flow_on_cancel_perform_at_aggregate: flow_on_cancel_perform_at_aggregate;
  /** fetch data from the table: "flow_on_cancel_perform_at" using primary key columns */
  flow_on_cancel_perform_at_by_pk?: Maybe<flow_on_cancel_perform_at>;
  /** fetch data from the table: "flow_route_rule" */
  flow_route_rule: Array<flow_route_rule>;
  /** fetch aggregated fields from the table: "flow_route_rule" */
  flow_route_rule_aggregate: flow_route_rule_aggregate;
  /** fetch data from the table: "flow_route_rule" using primary key columns */
  flow_route_rule_by_pk?: Maybe<flow_route_rule>;
  /** fetch data from the table: "flow_route_rule_eligibility_message" */
  flow_route_rule_eligibility_message: Array<flow_route_rule_eligibility_message>;
  /** fetch aggregated fields from the table: "flow_route_rule_eligibility_message" */
  flow_route_rule_eligibility_message_aggregate: flow_route_rule_eligibility_message_aggregate;
  /** fetch data from the table: "flow_route_rule_flow" */
  flow_route_rule_flow: Array<flow_route_rule_flow>;
  /** fetch aggregated fields from the table: "flow_route_rule_flow" */
  flow_route_rule_flow_aggregate: flow_route_rule_flow_aggregate;
  /** fetch data from the table: "flow_route_rule_flow_test" */
  flow_route_rule_flow_test: Array<flow_route_rule_flow_test>;
  /** fetch aggregated fields from the table: "flow_route_rule_flow_test" */
  flow_route_rule_flow_test_aggregate: flow_route_rule_flow_test_aggregate;
  /** fetch data from the table: "flow_route_rule_segment" */
  flow_route_rule_segment: Array<flow_route_rule_segment>;
  /** fetch aggregated fields from the table: "flow_route_rule_segment" */
  flow_route_rule_segment_aggregate: flow_route_rule_segment_aggregate;
  /** fetch data from the table: "flow_route_rule_segment_group" */
  flow_route_rule_segment_group: Array<flow_route_rule_segment_group>;
  /** fetch aggregated fields from the table: "flow_route_rule_segment_group" */
  flow_route_rule_segment_group_aggregate: flow_route_rule_segment_group_aggregate;
  /** fetch data from the table: "flow_step" */
  flow_step: Array<flow_step>;
  /** fetch data from the table: "flow_step_acknowledgement_group" */
  flow_step_acknowledgement_group: Array<flow_step_acknowledgement_group>;
  /** fetch aggregated fields from the table: "flow_step_acknowledgement_group" */
  flow_step_acknowledgement_group_aggregate: flow_step_acknowledgement_group_aggregate;
  /** fetch data from the table: "flow_step_acknowledgement_group" using primary key columns */
  flow_step_acknowledgement_group_by_pk?: Maybe<flow_step_acknowledgement_group>;
  /** fetch aggregated fields from the table: "flow_step" */
  flow_step_aggregate: flow_step_aggregate;
  /** fetch data from the table: "flow_step" using primary key columns */
  flow_step_by_pk?: Maybe<flow_step>;
  /** fetch data from the table: "flow_step_confirmation" */
  flow_step_confirmation: Array<flow_step_confirmation>;
  /** fetch aggregated fields from the table: "flow_step_confirmation" */
  flow_step_confirmation_aggregate: flow_step_confirmation_aggregate;
  /** fetch data from the table: "flow_step_confirmation" using primary key columns */
  flow_step_confirmation_by_pk?: Maybe<flow_step_confirmation>;
  /** fetch data from the table: "flow_step_deflection_rule_group" */
  flow_step_deflection_rule_group: Array<flow_step_deflection_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_deflection_rule_group" */
  flow_step_deflection_rule_group_aggregate: flow_step_deflection_rule_group_aggregate;
  /** fetch data from the table: "flow_step_form" */
  flow_step_form: Array<flow_step_form>;
  /** fetch aggregated fields from the table: "flow_step_form" */
  flow_step_form_aggregate: flow_step_form_aggregate;
  /** fetch data from the table: "flow_step_form" using primary key columns */
  flow_step_form_by_pk?: Maybe<flow_step_form>;
  /** fetch data from the table: "flow_step_intervention" */
  flow_step_intervention: Array<flow_step_intervention>;
  /** fetch aggregated fields from the table: "flow_step_intervention" */
  flow_step_intervention_aggregate: flow_step_intervention_aggregate;
  /** fetch data from the table: "flow_step_intervention" using primary key columns */
  flow_step_intervention_by_pk?: Maybe<flow_step_intervention>;
  /** fetch data from the table: "flow_step_offer_rule_group" */
  flow_step_offer_rule_group: Array<flow_step_offer_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_offer_rule_group" */
  flow_step_offer_rule_group_aggregate: flow_step_offer_rule_group_aggregate;
  /** fetch data from the table: "flow_step_question" */
  flow_step_question: Array<flow_step_question>;
  /** fetch aggregated fields from the table: "flow_step_question" */
  flow_step_question_aggregate: flow_step_question_aggregate;
  /** fetch data from the table: "flow_step_question" using primary key columns */
  flow_step_question_by_pk?: Maybe<flow_step_question>;
  /** fetch data from the table: "flow_step_rule_group" */
  flow_step_rule_group: Array<flow_step_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_rule_group" */
  flow_step_rule_group_aggregate: flow_step_rule_group_aggregate;
  /** fetch data from the table: "flow_step_rule_group" using primary key columns */
  flow_step_rule_group_by_pk?: Maybe<flow_step_rule_group>;
  /** fetch data from the table: "flow_step_type" */
  flow_step_type: Array<flow_step_type>;
  /** fetch aggregated fields from the table: "flow_step_type" */
  flow_step_type_aggregate: flow_step_type_aggregate;
  /** fetch data from the table: "flow_step_type" using primary key columns */
  flow_step_type_by_pk?: Maybe<flow_step_type>;
  /** fetch data from the table: "flow_step_version" */
  flow_step_version: Array<flow_step_version>;
  /** fetch aggregated fields from the table: "flow_step_version" */
  flow_step_version_aggregate: flow_step_version_aggregate;
  /** fetch data from the table: "flow_step_version" using primary key columns */
  flow_step_version_by_pk?: Maybe<flow_step_version>;
  /** fetch data from the table: "flow_step_version_condition_question_option" */
  flow_step_version_condition_question_option: Array<flow_step_version_condition_question_option>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_question_option" */
  flow_step_version_condition_question_option_aggregate: flow_step_version_condition_question_option_aggregate;
  /** fetch data from the table: "flow_step_version_condition_segment" */
  flow_step_version_condition_segment: Array<flow_step_version_condition_segment>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_segment" */
  flow_step_version_condition_segment_aggregate: flow_step_version_condition_segment_aggregate;
  /** fetch data from the table: "flow_step_version_condition_segment_group" */
  flow_step_version_condition_segment_group: Array<flow_step_version_condition_segment_group>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_segment_group" */
  flow_step_version_condition_segment_group_aggregate: flow_step_version_condition_segment_group_aggregate;
  /** fetch data from the table: "flow_subscriber_form_question" */
  flow_subscriber_form_question: Array<flow_subscriber_form_question>;
  /** fetch aggregated fields from the table: "flow_subscriber_form_question" */
  flow_subscriber_form_question_aggregate: flow_subscriber_form_question_aggregate;
  /** fetch data from the table: "flow_subscriber_form_question" using primary key columns */
  flow_subscriber_form_question_by_pk?: Maybe<flow_subscriber_form_question>;
  /** fetch data from the table: "flow_tag" */
  flow_tag: Array<flow_tag>;
  /** fetch aggregated fields from the table: "flow_tag" */
  flow_tag_aggregate: flow_tag_aggregate;
  /** fetch data from the table: "flow_test" */
  flow_test: Array<flow_test>;
  /** fetch aggregated fields from the table: "flow_test" */
  flow_test_aggregate: flow_test_aggregate;
  /** fetch data from the table: "flow_test" using primary key columns */
  flow_test_by_pk?: Maybe<flow_test>;
  /** fetch data from the table: "flow_test_flow" */
  flow_test_flow: Array<flow_test_flow>;
  /** fetch aggregated fields from the table: "flow_test_flow" */
  flow_test_flow_aggregate: flow_test_flow_aggregate;
  /** fetch data from the table: "flow_test_flow" using primary key columns */
  flow_test_flow_by_pk?: Maybe<flow_test_flow>;
  /** fetch data from the table: "flow_text" */
  flow_text: Array<flow_text>;
  /** fetch aggregated fields from the table: "flow_text" */
  flow_text_aggregate: flow_text_aggregate;
  /** fetch data from the table: "flow_text" using primary key columns */
  flow_text_by_pk?: Maybe<flow_text>;
  /** fetch data from the table: "flow_text_key" */
  flow_text_key: Array<flow_text_key>;
  /** fetch aggregated fields from the table: "flow_text_key" */
  flow_text_key_aggregate: flow_text_key_aggregate;
  /** fetch data from the table: "flow_text_key" using primary key columns */
  flow_text_key_by_pk?: Maybe<flow_text_key>;
  /** fetch data from the table: "flow_text_version" */
  flow_text_version: Array<flow_text_version>;
  /** fetch aggregated fields from the table: "flow_text_version" */
  flow_text_version_aggregate: flow_text_version_aggregate;
  /** fetch data from the table: "flow_text_version" using primary key columns */
  flow_text_version_by_pk?: Maybe<flow_text_version>;
  /** fetch data from the table: "flow_version" */
  flow_version: Array<flow_version>;
  /** fetch aggregated fields from the table: "flow_version" */
  flow_version_aggregate: flow_version_aggregate;
  /** fetch data from the table: "flow_version" using primary key columns */
  flow_version_by_pk?: Maybe<flow_version>;
  /** fetch data from the table: "flow_version_flow_step" */
  flow_version_flow_step: Array<flow_version_flow_step>;
  /** fetch aggregated fields from the table: "flow_version_flow_step" */
  flow_version_flow_step_aggregate: flow_version_flow_step_aggregate;
  /** fetch data from the table: "form" */
  form: Array<form>;
  /** fetch aggregated fields from the table: "form" */
  form_aggregate: form_aggregate;
  /** fetch data from the table: "form" using primary key columns */
  form_by_pk?: Maybe<form>;
  /** fetch data from the table: "form_version" */
  form_version: Array<form_version>;
  /** fetch aggregated fields from the table: "form_version" */
  form_version_aggregate: form_version_aggregate;
  /** fetch data from the table: "form_version" using primary key columns */
  form_version_by_pk?: Maybe<form_version>;
  /** fetch data from the table: "form_version_question" */
  form_version_question: Array<form_version_question>;
  /** fetch aggregated fields from the table: "form_version_question" */
  form_version_question_aggregate: form_version_question_aggregate;
  /** fetch data from the table: "function_log" */
  function_log: Array<function_log>;
  /** fetch aggregated fields from the table: "function_log" */
  function_log_aggregate: function_log_aggregate;
  /** fetch data from the table: "function_log" using primary key columns */
  function_log_by_pk?: Maybe<function_log>;
  hubSpotProperties: Array<HubSpotProperty>;
  /** fetch data from the table: "integration" */
  integration: Array<integration>;
  /** fetch aggregated fields from the table: "integration" */
  integration_aggregate: integration_aggregate;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<integration>;
  /** fetch data from the table: "integration_type" */
  integration_type: Array<integration_type>;
  /** fetch aggregated fields from the table: "integration_type" */
  integration_type_aggregate: integration_type_aggregate;
  /** fetch data from the table: "integration_type" using primary key columns */
  integration_type_by_pk?: Maybe<integration_type>;
  intercomDataAttributes: Array<IntercomDataAttribute>;
  /** fetch data from the table: "intervention" */
  intervention: Array<intervention>;
  /** fetch aggregated fields from the table: "intervention" */
  intervention_aggregate: intervention_aggregate;
  /** fetch data from the table: "intervention" using primary key columns */
  intervention_by_pk?: Maybe<intervention>;
  /** fetch data from the table: "intervention_version" */
  intervention_version: Array<intervention_version>;
  /** fetch aggregated fields from the table: "intervention_version" */
  intervention_version_aggregate: intervention_version_aggregate;
  /** fetch data from the table: "intervention_version" using primary key columns */
  intervention_version_by_pk?: Maybe<intervention_version>;
  /** fetch data from the table: "job" */
  job: Array<job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: job_aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<job>;
  /** fetch data from the table: "language" */
  language: Array<language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: language_aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<language>;
  /** fetch data from the table: "lost_mrr_by_month" */
  lost_mrr_by_month: Array<lost_mrr_by_month>;
  /** fetch aggregated fields from the table: "lost_mrr_by_month" */
  lost_mrr_by_month_aggregate: lost_mrr_by_month_aggregate;
  /** fetch data from the table: "most_recent_subscriber_flow_version" */
  most_recent_subscriber_flow_version: Array<most_recent_subscriber_flow_version>;
  /** fetch aggregated fields from the table: "most_recent_subscriber_flow_version" */
  most_recent_subscriber_flow_version_aggregate: most_recent_subscriber_flow_version_aggregate;
  /** fetch data from the table: "naviga_cancel_link" */
  naviga_cancel_link: Array<naviga_cancel_link>;
  /** fetch aggregated fields from the table: "naviga_cancel_link" */
  naviga_cancel_link_aggregate: naviga_cancel_link_aggregate;
  /** fetch data from the table: "naviga_cancel_link" using primary key columns */
  naviga_cancel_link_by_pk?: Maybe<naviga_cancel_link>;
  /** fetch data from the table: "naviga_domain" */
  naviga_domain: Array<naviga_domain>;
  /** fetch aggregated fields from the table: "naviga_domain" */
  naviga_domain_aggregate: naviga_domain_aggregate;
  /** fetch data from the table: "naviga_domain" using primary key columns */
  naviga_domain_by_pk?: Maybe<naviga_domain>;
  /** fetch data from the table: "naviga_paper_code" */
  naviga_paper_code: Array<naviga_paper_code>;
  /** fetch aggregated fields from the table: "naviga_paper_code" */
  naviga_paper_code_aggregate: naviga_paper_code_aggregate;
  /** fetch data from the table: "naviga_paper_code" using primary key columns */
  naviga_paper_code_by_pk?: Maybe<naviga_paper_code>;
  needStripeReconnect: NeedStripeReconnectOutput;
  /** fetch data from the table: "offer" */
  offer: Array<offer>;
  /** fetch aggregated fields from the table: "offer" */
  offer_aggregate: offer_aggregate;
  /** fetch data from the table: "offer_autopilot" */
  offer_autopilot: Array<offer_autopilot>;
  /** fetch aggregated fields from the table: "offer_autopilot" */
  offer_autopilot_aggregate: offer_autopilot_aggregate;
  /** fetch data from the table: "offer_autopilot" using primary key columns */
  offer_autopilot_by_pk?: Maybe<offer_autopilot>;
  /** fetch data from the table: "offer_autopilot_offer_autopilot_round" */
  offer_autopilot_offer_autopilot_round: Array<offer_autopilot_offer_autopilot_round>;
  /** fetch aggregated fields from the table: "offer_autopilot_offer_autopilot_round" */
  offer_autopilot_offer_autopilot_round_aggregate: offer_autopilot_offer_autopilot_round_aggregate;
  /** fetch data from the table: "offer_autopilot_offer_variant" */
  offer_autopilot_offer_variant: Array<offer_autopilot_offer_variant>;
  /** fetch aggregated fields from the table: "offer_autopilot_offer_variant" */
  offer_autopilot_offer_variant_aggregate: offer_autopilot_offer_variant_aggregate;
  /** fetch data from the table: "offer_autopilot_round" */
  offer_autopilot_round: Array<offer_autopilot_round>;
  /** fetch aggregated fields from the table: "offer_autopilot_round" */
  offer_autopilot_round_aggregate: offer_autopilot_round_aggregate;
  /** fetch data from the table: "offer_autopilot_round" using primary key columns */
  offer_autopilot_round_by_pk?: Maybe<offer_autopilot_round>;
  /** fetch data from the table: "offer_autopilot_round_log" */
  offer_autopilot_round_log: Array<offer_autopilot_round_log>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_log" */
  offer_autopilot_round_log_aggregate: offer_autopilot_round_log_aggregate;
  /** fetch data from the table: "offer_autopilot_round_log" using primary key columns */
  offer_autopilot_round_log_by_pk?: Maybe<offer_autopilot_round_log>;
  /** fetch data from the table: "offer_autopilot_round_offer_variant" */
  offer_autopilot_round_offer_variant: Array<offer_autopilot_round_offer_variant>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_offer_variant" */
  offer_autopilot_round_offer_variant_aggregate: offer_autopilot_round_offer_variant_aggregate;
  /** fetch data from the table: "offer_autopilot_round_strategy" */
  offer_autopilot_round_strategy: Array<offer_autopilot_round_strategy>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_strategy" */
  offer_autopilot_round_strategy_aggregate: offer_autopilot_round_strategy_aggregate;
  /** fetch data from the table: "offer_autopilot_round_strategy" using primary key columns */
  offer_autopilot_round_strategy_by_pk?: Maybe<offer_autopilot_round_strategy>;
  /** fetch data from the table: "offer_autopilot_strategy" */
  offer_autopilot_strategy: Array<offer_autopilot_strategy>;
  /** fetch aggregated fields from the table: "offer_autopilot_strategy" */
  offer_autopilot_strategy_aggregate: offer_autopilot_strategy_aggregate;
  /** fetch data from the table: "offer_autopilot_strategy" using primary key columns */
  offer_autopilot_strategy_by_pk?: Maybe<offer_autopilot_strategy>;
  /** fetch data from the table: "offer_autopilot_success_metric" */
  offer_autopilot_success_metric: Array<offer_autopilot_success_metric>;
  /** fetch aggregated fields from the table: "offer_autopilot_success_metric" */
  offer_autopilot_success_metric_aggregate: offer_autopilot_success_metric_aggregate;
  /** fetch data from the table: "offer_autopilot_success_metric" using primary key columns */
  offer_autopilot_success_metric_by_pk?: Maybe<offer_autopilot_success_metric>;
  /** fetch data from the table: "offer" using primary key columns */
  offer_by_pk?: Maybe<offer>;
  /** fetch data from the table: "offer_change_plan" */
  offer_change_plan: Array<offer_change_plan>;
  /** fetch aggregated fields from the table: "offer_change_plan" */
  offer_change_plan_aggregate: offer_change_plan_aggregate;
  /** fetch data from the table: "offer_change_plan" using primary key columns */
  offer_change_plan_by_pk?: Maybe<offer_change_plan>;
  /** fetch data from the table: "offer_change_plan_change_at" */
  offer_change_plan_change_at: Array<offer_change_plan_change_at>;
  /** fetch aggregated fields from the table: "offer_change_plan_change_at" */
  offer_change_plan_change_at_aggregate: offer_change_plan_change_at_aggregate;
  /** fetch data from the table: "offer_change_plan_change_at" using primary key columns */
  offer_change_plan_change_at_by_pk?: Maybe<offer_change_plan_change_at>;
  /** fetch data from the table: "offer_coupon" */
  offer_coupon: Array<offer_coupon>;
  /** fetch aggregated fields from the table: "offer_coupon" */
  offer_coupon_aggregate: offer_coupon_aggregate;
  /** fetch data from the table: "offer_coupon_apply_to" */
  offer_coupon_apply_to: Array<offer_coupon_apply_to>;
  /** fetch aggregated fields from the table: "offer_coupon_apply_to" */
  offer_coupon_apply_to_aggregate: offer_coupon_apply_to_aggregate;
  /** fetch data from the table: "offer_coupon_apply_to" using primary key columns */
  offer_coupon_apply_to_by_pk?: Maybe<offer_coupon_apply_to>;
  /** fetch data from the table: "offer_coupon" using primary key columns */
  offer_coupon_by_pk?: Maybe<offer_coupon>;
  /** fetch data from the table: "offer_coupon_type" */
  offer_coupon_type: Array<offer_coupon_type>;
  /** fetch aggregated fields from the table: "offer_coupon_type" */
  offer_coupon_type_aggregate: offer_coupon_type_aggregate;
  /** fetch data from the table: "offer_coupon_type" using primary key columns */
  offer_coupon_type_by_pk?: Maybe<offer_coupon_type>;
  /** fetch data from the table: "offer_custom" */
  offer_custom: Array<offer_custom>;
  /** fetch aggregated fields from the table: "offer_custom" */
  offer_custom_aggregate: offer_custom_aggregate;
  /** fetch data from the table: "offer_custom" using primary key columns */
  offer_custom_by_pk?: Maybe<offer_custom>;
  /** fetch data from the table: "offer_downgrade" */
  offer_downgrade: Array<offer_downgrade>;
  /** fetch aggregated fields from the table: "offer_downgrade" */
  offer_downgrade_aggregate: offer_downgrade_aggregate;
  /** fetch data from the table: "offer_downgrade" using primary key columns */
  offer_downgrade_by_pk?: Maybe<offer_downgrade>;
  /** fetch data from the table: "offer_goal" */
  offer_goal: Array<offer_goal>;
  /** fetch aggregated fields from the table: "offer_goal" */
  offer_goal_aggregate: offer_goal_aggregate;
  /** fetch data from the table: "offer_goal" using primary key columns */
  offer_goal_by_pk?: Maybe<offer_goal>;
  /** fetch data from the table: "offer_group" */
  offer_group: Array<offer_group>;
  /** fetch aggregated fields from the table: "offer_group" */
  offer_group_aggregate: offer_group_aggregate;
  /** fetch data from the table: "offer_group" using primary key columns */
  offer_group_by_pk?: Maybe<offer_group>;
  /** fetch data from the table: "offer_group_offer" */
  offer_group_offer: Array<offer_group_offer>;
  /** fetch aggregated fields from the table: "offer_group_offer" */
  offer_group_offer_aggregate: offer_group_offer_aggregate;
  /** fetch data from the table: "offer_modify_subscription" */
  offer_modify_subscription: Array<offer_modify_subscription>;
  /** fetch aggregated fields from the table: "offer_modify_subscription" */
  offer_modify_subscription_aggregate: offer_modify_subscription_aggregate;
  /** fetch data from the table: "offer_modify_subscription" using primary key columns */
  offer_modify_subscription_by_pk?: Maybe<offer_modify_subscription>;
  /** fetch data from the table: "offer_modify_subscription_modify_at" */
  offer_modify_subscription_modify_at: Array<offer_modify_subscription_modify_at>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_modify_at" */
  offer_modify_subscription_modify_at_aggregate: offer_modify_subscription_modify_at_aggregate;
  /** fetch data from the table: "offer_modify_subscription_modify_at" using primary key columns */
  offer_modify_subscription_modify_at_by_pk?: Maybe<offer_modify_subscription_modify_at>;
  /** fetch data from the table: "offer_modify_subscription_option" */
  offer_modify_subscription_option: Array<offer_modify_subscription_option>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_option" */
  offer_modify_subscription_option_aggregate: offer_modify_subscription_option_aggregate;
  /** fetch data from the table: "offer_modify_subscription_option" using primary key columns */
  offer_modify_subscription_option_by_pk?: Maybe<offer_modify_subscription_option>;
  /** fetch data from the table: "offer_modify_subscription_option_type" */
  offer_modify_subscription_option_type: Array<offer_modify_subscription_option_type>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_option_type" */
  offer_modify_subscription_option_type_aggregate: offer_modify_subscription_option_type_aggregate;
  /** fetch data from the table: "offer_modify_subscription_option_type" using primary key columns */
  offer_modify_subscription_option_type_by_pk?: Maybe<offer_modify_subscription_option_type>;
  /** fetch data from the table: "offer_pause_subscription" */
  offer_pause_subscription: Array<offer_pause_subscription>;
  /** fetch aggregated fields from the table: "offer_pause_subscription" */
  offer_pause_subscription_aggregate: offer_pause_subscription_aggregate;
  /** fetch data from the table: "offer_pause_subscription" using primary key columns */
  offer_pause_subscription_by_pk?: Maybe<offer_pause_subscription>;
  /** fetch data from the table: "offer_pause_subscription_interval" */
  offer_pause_subscription_interval: Array<offer_pause_subscription_interval>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_interval" */
  offer_pause_subscription_interval_aggregate: offer_pause_subscription_interval_aggregate;
  /** fetch data from the table: "offer_pause_subscription_interval" using primary key columns */
  offer_pause_subscription_interval_by_pk?: Maybe<offer_pause_subscription_interval>;
  /** fetch data from the table: "offer_pause_subscription_option" */
  offer_pause_subscription_option: Array<offer_pause_subscription_option>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_option" */
  offer_pause_subscription_option_aggregate: offer_pause_subscription_option_aggregate;
  /** fetch data from the table: "offer_pause_subscription_option" using primary key columns */
  offer_pause_subscription_option_by_pk?: Maybe<offer_pause_subscription_option>;
  /** fetch data from the table: "offer_pause_subscription_option_type" */
  offer_pause_subscription_option_type: Array<offer_pause_subscription_option_type>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_option_type" */
  offer_pause_subscription_option_type_aggregate: offer_pause_subscription_option_type_aggregate;
  /** fetch data from the table: "offer_pause_subscription_option_type" using primary key columns */
  offer_pause_subscription_option_type_by_pk?: Maybe<offer_pause_subscription_option_type>;
  /** fetch data from the table: "offer_pause_subscription_pause_at" */
  offer_pause_subscription_pause_at: Array<offer_pause_subscription_pause_at>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_pause_at" */
  offer_pause_subscription_pause_at_aggregate: offer_pause_subscription_pause_at_aggregate;
  /** fetch data from the table: "offer_pause_subscription_pause_at" using primary key columns */
  offer_pause_subscription_pause_at_by_pk?: Maybe<offer_pause_subscription_pause_at>;
  /** fetch data from the table: "offer_product_swap" */
  offer_product_swap: Array<offer_product_swap>;
  /** fetch aggregated fields from the table: "offer_product_swap" */
  offer_product_swap_aggregate: offer_product_swap_aggregate;
  /** fetch data from the table: "offer_product_swap" using primary key columns */
  offer_product_swap_by_pk?: Maybe<offer_product_swap>;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product" */
  offer_product_swap_platform_ecommerce_product: Array<offer_product_swap_platform_ecommerce_product>;
  /** fetch aggregated fields from the table: "offer_product_swap_platform_ecommerce_product" */
  offer_product_swap_platform_ecommerce_product_aggregate: offer_product_swap_platform_ecommerce_product_aggregate;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product" using primary key columns */
  offer_product_swap_platform_ecommerce_product_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  offer_product_swap_platform_ecommerce_product_platform_variant: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** fetch aggregated fields from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  offer_product_swap_platform_ecommerce_product_platform_variant_aggregate: offer_product_swap_platform_ecommerce_product_platform_variant_aggregate;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" using primary key columns */
  offer_product_swap_platform_ecommerce_product_platform_variant_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** fetch data from the table: "offer_reschedule_order" */
  offer_reschedule_order: Array<offer_reschedule_order>;
  /** fetch aggregated fields from the table: "offer_reschedule_order" */
  offer_reschedule_order_aggregate: offer_reschedule_order_aggregate;
  /** fetch data from the table: "offer_reschedule_order" using primary key columns */
  offer_reschedule_order_by_pk?: Maybe<offer_reschedule_order>;
  /** fetch data from the table: "offer_rule" */
  offer_rule: Array<offer_rule>;
  /** fetch aggregated fields from the table: "offer_rule" */
  offer_rule_aggregate: offer_rule_aggregate;
  /** fetch data from the table: "offer_rule" using primary key columns */
  offer_rule_by_pk?: Maybe<offer_rule>;
  /** fetch data from the table: "offer_rule_group" */
  offer_rule_group: Array<offer_rule_group>;
  /** fetch aggregated fields from the table: "offer_rule_group" */
  offer_rule_group_aggregate: offer_rule_group_aggregate;
  /** fetch data from the table: "offer_rule_group" using primary key columns */
  offer_rule_group_by_pk?: Maybe<offer_rule_group>;
  /** fetch data from the table: "offer_rule_group_version" */
  offer_rule_group_version: Array<offer_rule_group_version>;
  /** fetch aggregated fields from the table: "offer_rule_group_version" */
  offer_rule_group_version_aggregate: offer_rule_group_version_aggregate;
  /** fetch data from the table: "offer_rule_group_version" using primary key columns */
  offer_rule_group_version_by_pk?: Maybe<offer_rule_group_version>;
  /** fetch data from the table: "offer_rule_group_version_offer_rule" */
  offer_rule_group_version_offer_rule: Array<offer_rule_group_version_offer_rule>;
  /** fetch aggregated fields from the table: "offer_rule_group_version_offer_rule" */
  offer_rule_group_version_offer_rule_aggregate: offer_rule_group_version_offer_rule_aggregate;
  /** fetch data from the table: "offer_rule_rule" */
  offer_rule_rule: Array<offer_rule_rule>;
  /** fetch aggregated fields from the table: "offer_rule_rule" */
  offer_rule_rule_aggregate: offer_rule_rule_aggregate;
  /** fetch data from the table: "offer_rule_rule" using primary key columns */
  offer_rule_rule_by_pk?: Maybe<offer_rule_rule>;
  /** fetch data from the table: "offer_rule_rule_deflection" */
  offer_rule_rule_deflection: Array<offer_rule_rule_deflection>;
  /** fetch aggregated fields from the table: "offer_rule_rule_deflection" */
  offer_rule_rule_deflection_aggregate: offer_rule_rule_deflection_aggregate;
  /** fetch data from the table: "offer_rule_rule_offer" */
  offer_rule_rule_offer: Array<offer_rule_rule_offer>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer" */
  offer_rule_rule_offer_aggregate: offer_rule_rule_offer_aggregate;
  /** fetch data from the table: "offer_rule_rule_offer_autopilot_offer" */
  offer_rule_rule_offer_autopilot_offer: Array<offer_rule_rule_offer_autopilot_offer>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_autopilot_offer" */
  offer_rule_rule_offer_autopilot_offer_aggregate: offer_rule_rule_offer_autopilot_offer_aggregate;
  /** fetch data from the table: "offer_rule_rule_offer_group" */
  offer_rule_rule_offer_group: Array<offer_rule_rule_offer_group>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_group" */
  offer_rule_rule_offer_group_aggregate: offer_rule_rule_offer_group_aggregate;
  /** fetch data from the table: "offer_rule_rule_offer_test" */
  offer_rule_rule_offer_test: Array<offer_rule_rule_offer_test>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_test" */
  offer_rule_rule_offer_test_aggregate: offer_rule_rule_offer_test_aggregate;
  /** fetch data from the table: "offer_rule_version" */
  offer_rule_version: Array<offer_rule_version>;
  /** fetch aggregated fields from the table: "offer_rule_version" */
  offer_rule_version_aggregate: offer_rule_version_aggregate;
  /** fetch data from the table: "offer_rule_version" using primary key columns */
  offer_rule_version_by_pk?: Maybe<offer_rule_version>;
  /** fetch data from the table: "offer_rule_version_offer" */
  offer_rule_version_offer: Array<offer_rule_version_offer>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer" */
  offer_rule_version_offer_aggregate: offer_rule_version_offer_aggregate;
  /** fetch data from the table: "offer_rule_version_offer_autopilot_offer" */
  offer_rule_version_offer_autopilot_offer: Array<offer_rule_version_offer_autopilot_offer>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_autopilot_offer" */
  offer_rule_version_offer_autopilot_offer_aggregate: offer_rule_version_offer_autopilot_offer_aggregate;
  /** fetch data from the table: "offer_rule_version_offer_group" */
  offer_rule_version_offer_group: Array<offer_rule_version_offer_group>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_group" */
  offer_rule_version_offer_group_aggregate: offer_rule_version_offer_group_aggregate;
  /** fetch data from the table: "offer_rule_version_offer_rule_rule" */
  offer_rule_version_offer_rule_rule: Array<offer_rule_version_offer_rule_rule>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_rule_rule" */
  offer_rule_version_offer_rule_rule_aggregate: offer_rule_version_offer_rule_rule_aggregate;
  /** fetch data from the table: "offer_rule_version_offer_test" */
  offer_rule_version_offer_test: Array<offer_rule_version_offer_test>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_test" */
  offer_rule_version_offer_test_aggregate: offer_rule_version_offer_test_aggregate;
  /** fetch data from the table: "offer_rule_version_question_option" */
  offer_rule_version_question_option: Array<offer_rule_version_question_option>;
  /** fetch aggregated fields from the table: "offer_rule_version_question_option" */
  offer_rule_version_question_option_aggregate: offer_rule_version_question_option_aggregate;
  /** fetch data from the table: "offer_rule_version_segment" */
  offer_rule_version_segment: Array<offer_rule_version_segment>;
  /** fetch aggregated fields from the table: "offer_rule_version_segment" */
  offer_rule_version_segment_aggregate: offer_rule_version_segment_aggregate;
  /** fetch data from the table: "offer_rule_version_segment_group" */
  offer_rule_version_segment_group: Array<offer_rule_version_segment_group>;
  /** fetch aggregated fields from the table: "offer_rule_version_segment_group" */
  offer_rule_version_segment_group_aggregate: offer_rule_version_segment_group_aggregate;
  /** fetch data from the table: "offer_style" */
  offer_style: Array<offer_style>;
  /** fetch aggregated fields from the table: "offer_style" */
  offer_style_aggregate: offer_style_aggregate;
  /** fetch data from the table: "offer_style" using primary key columns */
  offer_style_by_pk?: Maybe<offer_style>;
  /** fetch data from the table: "offer_tag" */
  offer_tag: Array<offer_tag>;
  /** fetch aggregated fields from the table: "offer_tag" */
  offer_tag_aggregate: offer_tag_aggregate;
  /** fetch data from the table: "offer_test" */
  offer_test: Array<offer_test>;
  /** fetch aggregated fields from the table: "offer_test" */
  offer_test_aggregate: offer_test_aggregate;
  /** fetch data from the table: "offer_test" using primary key columns */
  offer_test_by_pk?: Maybe<offer_test>;
  /** fetch data from the table: "offer_test_offer" */
  offer_test_offer: Array<offer_test_offer>;
  /** fetch aggregated fields from the table: "offer_test_offer" */
  offer_test_offer_aggregate: offer_test_offer_aggregate;
  /** fetch data from the table: "offer_test_offer" using primary key columns */
  offer_test_offer_by_pk?: Maybe<offer_test_offer>;
  /** fetch data from the table: "offer_timer" */
  offer_timer: Array<offer_timer>;
  /** fetch aggregated fields from the table: "offer_timer" */
  offer_timer_aggregate: offer_timer_aggregate;
  /** fetch data from the table: "offer_timer" using primary key columns */
  offer_timer_by_pk?: Maybe<offer_timer>;
  /** fetch data from the table: "offer_timer_mode" */
  offer_timer_mode: Array<offer_timer_mode>;
  /** fetch aggregated fields from the table: "offer_timer_mode" */
  offer_timer_mode_aggregate: offer_timer_mode_aggregate;
  /** fetch data from the table: "offer_timer_mode" using primary key columns */
  offer_timer_mode_by_pk?: Maybe<offer_timer_mode>;
  /** fetch data from the table: "offer_trial_extension" */
  offer_trial_extension: Array<offer_trial_extension>;
  /** fetch aggregated fields from the table: "offer_trial_extension" */
  offer_trial_extension_aggregate: offer_trial_extension_aggregate;
  /** fetch data from the table: "offer_trial_extension" using primary key columns */
  offer_trial_extension_by_pk?: Maybe<offer_trial_extension>;
  /** fetch data from the table: "offer_tsvector" */
  offer_tsvector: Array<offer_tsvector>;
  /** fetch aggregated fields from the table: "offer_tsvector" */
  offer_tsvector_aggregate: offer_tsvector_aggregate;
  /** fetch data from the table: "offer_type" */
  offer_type: Array<offer_type>;
  /** fetch aggregated fields from the table: "offer_type" */
  offer_type_aggregate: offer_type_aggregate;
  /** fetch data from the table: "offer_type" using primary key columns */
  offer_type_by_pk?: Maybe<offer_type>;
  /** fetch data from the table: "offer_upgrade" */
  offer_upgrade: Array<offer_upgrade>;
  /** fetch aggregated fields from the table: "offer_upgrade" */
  offer_upgrade_aggregate: offer_upgrade_aggregate;
  /** fetch data from the table: "offer_upgrade" using primary key columns */
  offer_upgrade_by_pk?: Maybe<offer_upgrade>;
  /** fetch data from the table: "offer_variant" */
  offer_variant: Array<offer_variant>;
  /** fetch aggregated fields from the table: "offer_variant" */
  offer_variant_aggregate: offer_variant_aggregate;
  /** fetch data from the table: "offer_variant" using primary key columns */
  offer_variant_by_pk?: Maybe<offer_variant>;
  /** fetch data from the table: "offer_variant_coupon" */
  offer_variant_coupon: Array<offer_variant_coupon>;
  /** fetch aggregated fields from the table: "offer_variant_coupon" */
  offer_variant_coupon_aggregate: offer_variant_coupon_aggregate;
  /** fetch data from the table: "offer_variant_coupon" using primary key columns */
  offer_variant_coupon_by_pk?: Maybe<offer_variant_coupon>;
  paymentMethod: PaymentMethodOutput;
  /** fetch data from the table: "platform" */
  platform: Array<platform>;
  platformCustomerSubscriptions: PlatformCustomerSubscriptionsOutput;
  /** fetch data from the table: "platform_addon" */
  platform_addon: Array<platform_addon>;
  /** fetch aggregated fields from the table: "platform_addon" */
  platform_addon_aggregate: platform_addon_aggregate;
  /** fetch data from the table: "platform_addon" using primary key columns */
  platform_addon_by_pk?: Maybe<platform_addon>;
  /** fetch data from the table: "platform_addon_plan" */
  platform_addon_plan: Array<platform_addon_plan>;
  /** fetch aggregated fields from the table: "platform_addon_plan" */
  platform_addon_plan_aggregate: platform_addon_plan_aggregate;
  /** fetch data from the table: "platform_addon_plan" using primary key columns */
  platform_addon_plan_by_pk?: Maybe<platform_addon_plan>;
  /** fetch data from the table: "platform_addon_price" */
  platform_addon_price: Array<platform_addon_price>;
  /** fetch aggregated fields from the table: "platform_addon_price" */
  platform_addon_price_aggregate: platform_addon_price_aggregate;
  /** fetch data from the table: "platform_addon_price" using primary key columns */
  platform_addon_price_by_pk?: Maybe<platform_addon_price>;
  /** fetch data from the table: "platform_addon_price_tier" */
  platform_addon_price_tier: Array<platform_addon_price_tier>;
  /** fetch aggregated fields from the table: "platform_addon_price_tier" */
  platform_addon_price_tier_aggregate: platform_addon_price_tier_aggregate;
  /** fetch data from the table: "platform_addon_price_tier" using primary key columns */
  platform_addon_price_tier_by_pk?: Maybe<platform_addon_price_tier>;
  /** fetch aggregated fields from the table: "platform" */
  platform_aggregate: platform_aggregate;
  /** fetch data from the table: "platform_api_log" */
  platform_api_log: Array<platform_api_log>;
  /** fetch aggregated fields from the table: "platform_api_log" */
  platform_api_log_aggregate: platform_api_log_aggregate;
  /** fetch data from the table: "platform_api_log" using primary key columns */
  platform_api_log_by_pk?: Maybe<platform_api_log>;
  /** fetch data from the table: "platform_api_log_tsvector" */
  platform_api_log_tsvector: Array<platform_api_log_tsvector>;
  /** fetch aggregated fields from the table: "platform_api_log_tsvector" */
  platform_api_log_tsvector_aggregate: platform_api_log_tsvector_aggregate;
  /** fetch data from the table: "platform_base_currency" */
  platform_base_currency: Array<platform_base_currency>;
  /** fetch aggregated fields from the table: "platform_base_currency" */
  platform_base_currency_aggregate: platform_base_currency_aggregate;
  /** fetch data from the table: "platform_base_currency" using primary key columns */
  platform_base_currency_by_pk?: Maybe<platform_base_currency>;
  /** fetch data from the table: "platform" using primary key columns */
  platform_by_pk?: Maybe<platform>;
  /** fetch data from the table: "platform_cancel_reason" */
  platform_cancel_reason: Array<platform_cancel_reason>;
  /** fetch aggregated fields from the table: "platform_cancel_reason" */
  platform_cancel_reason_aggregate: platform_cancel_reason_aggregate;
  /** fetch data from the table: "platform_cancel_reason" using primary key columns */
  platform_cancel_reason_by_pk?: Maybe<platform_cancel_reason>;
  /** fetch data from the table: "platform_connection" */
  platform_connection: Array<platform_connection>;
  /** fetch aggregated fields from the table: "platform_connection" */
  platform_connection_aggregate: platform_connection_aggregate;
  /** fetch data from the table: "platform_connection" using primary key columns */
  platform_connection_by_pk?: Maybe<platform_connection>;
  /** fetch data from the table: "platform_connection_naviga_domain" */
  platform_connection_naviga_domain: Array<platform_connection_naviga_domain>;
  /** fetch aggregated fields from the table: "platform_connection_naviga_domain" */
  platform_connection_naviga_domain_aggregate: platform_connection_naviga_domain_aggregate;
  /** fetch data from the table: "platform_connection_naviga_domain" using primary key columns */
  platform_connection_naviga_domain_by_pk?: Maybe<platform_connection_naviga_domain>;
  /** fetch data from the table: "platform_connection_naviga_paper_code_endpoint" */
  platform_connection_naviga_paper_code_endpoint: Array<platform_connection_naviga_paper_code_endpoint>;
  /** fetch aggregated fields from the table: "platform_connection_naviga_paper_code_endpoint" */
  platform_connection_naviga_paper_code_endpoint_aggregate: platform_connection_naviga_paper_code_endpoint_aggregate;
  /** fetch data from the table: "platform_connection_naviga_paper_code_endpoint" using primary key columns */
  platform_connection_naviga_paper_code_endpoint_by_pk?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** fetch data from the table: "platform_connection_shopify_store" */
  platform_connection_shopify_store: Array<platform_connection_shopify_store>;
  /** fetch aggregated fields from the table: "platform_connection_shopify_store" */
  platform_connection_shopify_store_aggregate: platform_connection_shopify_store_aggregate;
  /** fetch data from the table: "platform_connection_shopify_store" using primary key columns */
  platform_connection_shopify_store_by_pk?: Maybe<platform_connection_shopify_store>;
  /** fetch data from the table: "platform_coupon" */
  platform_coupon: Array<platform_coupon>;
  /** fetch data from the table: "platform_coupon_addon" */
  platform_coupon_addon: Array<platform_coupon_addon>;
  /** fetch aggregated fields from the table: "platform_coupon_addon" */
  platform_coupon_addon_aggregate: platform_coupon_addon_aggregate;
  /** fetch data from the table: "platform_coupon_addon" using primary key columns */
  platform_coupon_addon_by_pk?: Maybe<platform_coupon_addon>;
  /** fetch aggregated fields from the table: "platform_coupon" */
  platform_coupon_aggregate: platform_coupon_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to" */
  platform_coupon_apply_to: Array<platform_coupon_apply_to>;
  /** fetch aggregated fields from the table: "platform_coupon_apply_to" */
  platform_coupon_apply_to_aggregate: platform_coupon_apply_to_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to" using primary key columns */
  platform_coupon_apply_to_by_pk?: Maybe<platform_coupon_apply_to>;
  /** fetch data from the table: "platform_coupon_apply_to_restriction" */
  platform_coupon_apply_to_restriction: Array<platform_coupon_apply_to_restriction>;
  /** fetch aggregated fields from the table: "platform_coupon_apply_to_restriction" */
  platform_coupon_apply_to_restriction_aggregate: platform_coupon_apply_to_restriction_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to_restriction" using primary key columns */
  platform_coupon_apply_to_restriction_by_pk?: Maybe<platform_coupon_apply_to_restriction>;
  /** fetch data from the table: "platform_coupon" using primary key columns */
  platform_coupon_by_pk?: Maybe<platform_coupon>;
  /** fetch data from the table: "platform_coupon_duration" */
  platform_coupon_duration: Array<platform_coupon_duration>;
  /** fetch aggregated fields from the table: "platform_coupon_duration" */
  platform_coupon_duration_aggregate: platform_coupon_duration_aggregate;
  /** fetch data from the table: "platform_coupon_duration" using primary key columns */
  platform_coupon_duration_by_pk?: Maybe<platform_coupon_duration>;
  /** fetch data from the table: "platform_coupon_duration_interval" */
  platform_coupon_duration_interval: Array<platform_coupon_duration_interval>;
  /** fetch aggregated fields from the table: "platform_coupon_duration_interval" */
  platform_coupon_duration_interval_aggregate: platform_coupon_duration_interval_aggregate;
  /** fetch data from the table: "platform_coupon_duration_interval" using primary key columns */
  platform_coupon_duration_interval_by_pk?: Maybe<platform_coupon_duration_interval>;
  /** fetch data from the table: "platform_coupon_plan" */
  platform_coupon_plan: Array<platform_coupon_plan>;
  /** fetch aggregated fields from the table: "platform_coupon_plan" */
  platform_coupon_plan_aggregate: platform_coupon_plan_aggregate;
  /** fetch data from the table: "platform_coupon_plan" using primary key columns */
  platform_coupon_plan_by_pk?: Maybe<platform_coupon_plan>;
  /** fetch data from the table: "platform_coupon_product" */
  platform_coupon_product: Array<platform_coupon_product>;
  /** fetch aggregated fields from the table: "platform_coupon_product" */
  platform_coupon_product_aggregate: platform_coupon_product_aggregate;
  /** fetch data from the table: "platform_coupon_product" using primary key columns */
  platform_coupon_product_by_pk?: Maybe<platform_coupon_product>;
  /** fetch data from the table: "platform_coupon_product_group" */
  platform_coupon_product_group: Array<platform_coupon_product_group>;
  /** fetch aggregated fields from the table: "platform_coupon_product_group" */
  platform_coupon_product_group_aggregate: platform_coupon_product_group_aggregate;
  /** fetch data from the table: "platform_coupon_product_group" using primary key columns */
  platform_coupon_product_group_by_pk?: Maybe<platform_coupon_product_group>;
  /** fetch data from the table: "platform_coupon_tsvector" */
  platform_coupon_tsvector: Array<platform_coupon_tsvector>;
  /** fetch aggregated fields from the table: "platform_coupon_tsvector" */
  platform_coupon_tsvector_aggregate: platform_coupon_tsvector_aggregate;
  /** fetch data from the table: "platform_coupon_type" */
  platform_coupon_type: Array<platform_coupon_type>;
  /** fetch aggregated fields from the table: "platform_coupon_type" */
  platform_coupon_type_aggregate: platform_coupon_type_aggregate;
  /** fetch data from the table: "platform_coupon_type" using primary key columns */
  platform_coupon_type_by_pk?: Maybe<platform_coupon_type>;
  /** fetch data from the table: "platform_credentials" */
  platform_credentials: Array<platform_credentials>;
  /** fetch aggregated fields from the table: "platform_credentials" */
  platform_credentials_aggregate: platform_credentials_aggregate;
  /** fetch data from the table: "platform_credentials" using primary key columns */
  platform_credentials_by_pk?: Maybe<platform_credentials>;
  /** fetch data from the table: "platform_currency" */
  platform_currency: Array<platform_currency>;
  /** fetch aggregated fields from the table: "platform_currency" */
  platform_currency_aggregate: platform_currency_aggregate;
  /** fetch data from the table: "platform_currency" using primary key columns */
  platform_currency_by_pk?: Maybe<platform_currency>;
  /** fetch data from the table: "platform_currency_exchange_rate" */
  platform_currency_exchange_rate: Array<platform_currency_exchange_rate>;
  /** fetch aggregated fields from the table: "platform_currency_exchange_rate" */
  platform_currency_exchange_rate_aggregate: platform_currency_exchange_rate_aggregate;
  /** fetch data from the table: "platform_currency_exchange_rate" using primary key columns */
  platform_currency_exchange_rate_by_pk?: Maybe<platform_currency_exchange_rate>;
  /** fetch data from the table: "platform_customer" */
  platform_customer: Array<platform_customer>;
  /** fetch aggregated fields from the table: "platform_customer" */
  platform_customer_aggregate: platform_customer_aggregate;
  /** fetch data from the table: "platform_customer" using primary key columns */
  platform_customer_by_pk?: Maybe<platform_customer>;
  /** fetch data from the table: "platform_ecommerce_product" */
  platform_ecommerce_product: Array<platform_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_ecommerce_product" */
  platform_ecommerce_product_aggregate: platform_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_ecommerce_product" using primary key columns */
  platform_ecommerce_product_by_pk?: Maybe<platform_ecommerce_product>;
  /** fetch data from the table: "platform_import" */
  platform_import: Array<platform_import>;
  /** fetch aggregated fields from the table: "platform_import" */
  platform_import_aggregate: platform_import_aggregate;
  /** fetch data from the table: "platform_import" using primary key columns */
  platform_import_by_pk?: Maybe<platform_import>;
  /** fetch data from the table: "platform_import_status" */
  platform_import_status: Array<platform_import_status>;
  /** fetch aggregated fields from the table: "platform_import_status" */
  platform_import_status_aggregate: platform_import_status_aggregate;
  /** fetch data from the table: "platform_import_status" using primary key columns */
  platform_import_status_by_pk?: Maybe<platform_import_status>;
  /** fetch data from the table: "platform_import_step" */
  platform_import_step: Array<platform_import_step>;
  /** fetch aggregated fields from the table: "platform_import_step" */
  platform_import_step_aggregate: platform_import_step_aggregate;
  /** fetch data from the table: "platform_import_step" using primary key columns */
  platform_import_step_by_pk?: Maybe<platform_import_step>;
  /** fetch data from the table: "platform_import_step_type" */
  platform_import_step_type: Array<platform_import_step_type>;
  /** fetch aggregated fields from the table: "platform_import_step_type" */
  platform_import_step_type_aggregate: platform_import_step_type_aggregate;
  /** fetch data from the table: "platform_import_step_type" using primary key columns */
  platform_import_step_type_by_pk?: Maybe<platform_import_step_type>;
  /** fetch data from the table: "platform_invoice" */
  platform_invoice: Array<platform_invoice>;
  /** fetch aggregated fields from the table: "platform_invoice" */
  platform_invoice_aggregate: platform_invoice_aggregate;
  /** fetch data from the table: "platform_invoice" using primary key columns */
  platform_invoice_by_pk?: Maybe<platform_invoice>;
  /** fetch data from the table: "platform_invoice_status" */
  platform_invoice_status: Array<platform_invoice_status>;
  /** fetch aggregated fields from the table: "platform_invoice_status" */
  platform_invoice_status_aggregate: platform_invoice_status_aggregate;
  /** fetch data from the table: "platform_invoice_status" using primary key columns */
  platform_invoice_status_by_pk?: Maybe<platform_invoice_status>;
  /** fetch data from the table: "platform_newspaper_rate_code" */
  platform_newspaper_rate_code: Array<platform_newspaper_rate_code>;
  /** fetch aggregated fields from the table: "platform_newspaper_rate_code" */
  platform_newspaper_rate_code_aggregate: platform_newspaper_rate_code_aggregate;
  /** fetch data from the table: "platform_newspaper_rate_code" using primary key columns */
  platform_newspaper_rate_code_by_pk?: Maybe<platform_newspaper_rate_code>;
  /** fetch data from the table: "platform_newspaper_subscription" */
  platform_newspaper_subscription: Array<platform_newspaper_subscription>;
  /** fetch aggregated fields from the table: "platform_newspaper_subscription" */
  platform_newspaper_subscription_aggregate: platform_newspaper_subscription_aggregate;
  /** fetch data from the table: "platform_newspaper_subscription" using primary key columns */
  platform_newspaper_subscription_by_pk?: Maybe<platform_newspaper_subscription>;
  /** fetch data from the table: "platform_newspaper_subscription_stop_date" */
  platform_newspaper_subscription_stop_date: Array<platform_newspaper_subscription_stop_date>;
  /** fetch aggregated fields from the table: "platform_newspaper_subscription_stop_date" */
  platform_newspaper_subscription_stop_date_aggregate: platform_newspaper_subscription_stop_date_aggregate;
  /** fetch data from the table: "platform_plan" */
  platform_plan: Array<platform_plan>;
  /** fetch aggregated fields from the table: "platform_plan" */
  platform_plan_aggregate: platform_plan_aggregate;
  /** fetch data from the table: "platform_plan" using primary key columns */
  platform_plan_by_pk?: Maybe<platform_plan>;
  /** fetch data from the table: "platform_plan_ecommerce_product" */
  platform_plan_ecommerce_product: Array<platform_plan_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_plan_ecommerce_product" */
  platform_plan_ecommerce_product_aggregate: platform_plan_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_plan_ecommerce_product" using primary key columns */
  platform_plan_ecommerce_product_by_pk?: Maybe<platform_plan_ecommerce_product>;
  /** fetch data from the table: "platform_plan_group" */
  platform_plan_group: Array<platform_plan_group>;
  /** fetch aggregated fields from the table: "platform_plan_group" */
  platform_plan_group_aggregate: platform_plan_group_aggregate;
  /** fetch data from the table: "platform_plan_group" using primary key columns */
  platform_plan_group_by_pk?: Maybe<platform_plan_group>;
  /** fetch data from the table: "platform_plan_interval" */
  platform_plan_interval: Array<platform_plan_interval>;
  /** fetch aggregated fields from the table: "platform_plan_interval" */
  platform_plan_interval_aggregate: platform_plan_interval_aggregate;
  /** fetch data from the table: "platform_plan_interval" using primary key columns */
  platform_plan_interval_by_pk?: Maybe<platform_plan_interval>;
  /** fetch data from the table: "platform_plan_pricing_model" */
  platform_plan_pricing_model: Array<platform_plan_pricing_model>;
  /** fetch aggregated fields from the table: "platform_plan_pricing_model" */
  platform_plan_pricing_model_aggregate: platform_plan_pricing_model_aggregate;
  /** fetch data from the table: "platform_plan_pricing_model" using primary key columns */
  platform_plan_pricing_model_by_pk?: Maybe<platform_plan_pricing_model>;
  /** fetch data from the table: "platform_plan_tier" */
  platform_plan_tier: Array<platform_plan_tier>;
  /** fetch aggregated fields from the table: "platform_plan_tier" */
  platform_plan_tier_aggregate: platform_plan_tier_aggregate;
  /** fetch data from the table: "platform_plan_tier" using primary key columns */
  platform_plan_tier_by_pk?: Maybe<platform_plan_tier>;
  /** fetch data from the table: "platform_product" */
  platform_product: Array<platform_product>;
  /** fetch aggregated fields from the table: "platform_product" */
  platform_product_aggregate: platform_product_aggregate;
  /** fetch data from the table: "platform_product" using primary key columns */
  platform_product_by_pk?: Maybe<platform_product>;
  /** fetch data from the table: "platform_product_group" */
  platform_product_group: Array<platform_product_group>;
  /** fetch aggregated fields from the table: "platform_product_group" */
  platform_product_group_aggregate: platform_product_group_aggregate;
  /** fetch data from the table: "platform_product_group" using primary key columns */
  platform_product_group_by_pk?: Maybe<platform_product_group>;
  /** fetch data from the table: "platform_raw_object" */
  platform_raw_object: Array<platform_raw_object>;
  /** fetch aggregated fields from the table: "platform_raw_object" */
  platform_raw_object_aggregate: platform_raw_object_aggregate;
  /** fetch data from the table: "platform_raw_object" using primary key columns */
  platform_raw_object_by_pk?: Maybe<platform_raw_object>;
  /** fetch data from the table: "platform_raw_object_type" */
  platform_raw_object_type: Array<platform_raw_object_type>;
  /** fetch aggregated fields from the table: "platform_raw_object_type" */
  platform_raw_object_type_aggregate: platform_raw_object_type_aggregate;
  /** fetch data from the table: "platform_raw_object_type" using primary key columns */
  platform_raw_object_type_by_pk?: Maybe<platform_raw_object_type>;
  /** fetch data from the table: "platform_refund" */
  platform_refund: Array<platform_refund>;
  /** fetch aggregated fields from the table: "platform_refund" */
  platform_refund_aggregate: platform_refund_aggregate;
  /** fetch data from the table: "platform_refund" using primary key columns */
  platform_refund_by_pk?: Maybe<platform_refund>;
  /** fetch data from the table: "platform_subscription" */
  platform_subscription: Array<platform_subscription>;
  /** fetch data from the table: "platform_subscription_addon" */
  platform_subscription_addon: Array<platform_subscription_addon>;
  /** fetch aggregated fields from the table: "platform_subscription_addon" */
  platform_subscription_addon_aggregate: platform_subscription_addon_aggregate;
  /** fetch data from the table: "platform_subscription_addon" using primary key columns */
  platform_subscription_addon_by_pk?: Maybe<platform_subscription_addon>;
  /** fetch aggregated fields from the table: "platform_subscription" */
  platform_subscription_aggregate: platform_subscription_aggregate;
  /** fetch data from the table: "platform_subscription" using primary key columns */
  platform_subscription_by_pk?: Maybe<platform_subscription>;
  /** fetch data from the table: "platform_subscription_next_billing_at" */
  platform_subscription_next_billing_at: Array<platform_subscription_next_billing_at>;
  /** fetch aggregated fields from the table: "platform_subscription_next_billing_at" */
  platform_subscription_next_billing_at_aggregate: platform_subscription_next_billing_at_aggregate;
  /** fetch data from the table: "platform_subscription_plan" */
  platform_subscription_plan: Array<platform_subscription_plan>;
  /** fetch aggregated fields from the table: "platform_subscription_plan" */
  platform_subscription_plan_aggregate: platform_subscription_plan_aggregate;
  /** fetch data from the table: "platform_subscription_plan" using primary key columns */
  platform_subscription_plan_by_pk?: Maybe<platform_subscription_plan>;
  /** fetch data from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  platform_subscription_plan_swappable_ecommerce_product: Array<platform_subscription_plan_swappable_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  platform_subscription_plan_swappable_ecommerce_product_aggregate: platform_subscription_plan_swappable_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_subscription_plan_swappable_ecommerce_product" using primary key columns */
  platform_subscription_plan_swappable_ecommerce_product_by_pk?: Maybe<platform_subscription_plan_swappable_ecommerce_product>;
  /** fetch data from the table: "platform_subscription_status" */
  platform_subscription_status: Array<platform_subscription_status>;
  /** fetch aggregated fields from the table: "platform_subscription_status" */
  platform_subscription_status_aggregate: platform_subscription_status_aggregate;
  /** fetch data from the table: "platform_subscription_status" using primary key columns */
  platform_subscription_status_by_pk?: Maybe<platform_subscription_status>;
  /** fetch data from the table: "platform_variant" */
  platform_variant: Array<platform_variant>;
  /** fetch aggregated fields from the table: "platform_variant" */
  platform_variant_aggregate: platform_variant_aggregate;
  /** fetch data from the table: "platform_variant" using primary key columns */
  platform_variant_by_pk?: Maybe<platform_variant>;
  /** fetch data from the table: "platform_variant_option" */
  platform_variant_option: Array<platform_variant_option>;
  /** fetch aggregated fields from the table: "platform_variant_option" */
  platform_variant_option_aggregate: platform_variant_option_aggregate;
  /** fetch data from the table: "platform_variant_option" using primary key columns */
  platform_variant_option_by_pk?: Maybe<platform_variant_option>;
  portalCustomerBillingDetails: PortalCustomerBillingDetailsOutput;
  portalSessionPaymentInfo: PortalSessionPaymentInfoOutput;
  portalSubscriptionDetails: PortalSubscriptionDetailsOutput;
  /** fetch data from the table: "portal_email_code" */
  portal_email_code: Array<portal_email_code>;
  /** fetch aggregated fields from the table: "portal_email_code" */
  portal_email_code_aggregate: portal_email_code_aggregate;
  /** fetch data from the table: "portal_email_code" using primary key columns */
  portal_email_code_by_pk?: Maybe<portal_email_code>;
  /** fetch data from the table: "portal_session" */
  portal_session: Array<portal_session>;
  /** fetch aggregated fields from the table: "portal_session" */
  portal_session_aggregate: portal_session_aggregate;
  /** fetch data from the table: "portal_session" using primary key columns */
  portal_session_by_pk?: Maybe<portal_session>;
  /** fetch data from the table: "portal_session_subscriber" */
  portal_session_subscriber: Array<portal_session_subscriber>;
  /** fetch aggregated fields from the table: "portal_session_subscriber" */
  portal_session_subscriber_aggregate: portal_session_subscriber_aggregate;
  /** fetch data from the table: "portal_session_subscriber" using primary key columns */
  portal_session_subscriber_by_pk?: Maybe<portal_session_subscriber>;
  pricingPlan: PricingPlanOutput;
  /** fetch data from the table: "product" */
  product: Array<product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: product_aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<product>;
  /** fetch data from the table: "property" */
  property: Array<property>;
  propertySegmentMatches: PropertySegmentMatchesOutput;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: property_aggregate;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<property>;
  /** fetch data from the table: "property_entity" */
  property_entity: Array<property_entity>;
  /** fetch aggregated fields from the table: "property_entity" */
  property_entity_aggregate: property_entity_aggregate;
  /** fetch data from the table: "property_entity" using primary key columns */
  property_entity_by_pk?: Maybe<property_entity>;
  /** fetch data from the table: "property_format" */
  property_format: Array<property_format>;
  /** fetch aggregated fields from the table: "property_format" */
  property_format_aggregate: property_format_aggregate;
  /** fetch data from the table: "property_format" using primary key columns */
  property_format_by_pk?: Maybe<property_format>;
  /** fetch data from the table: "property_tsvector" */
  property_tsvector: Array<property_tsvector>;
  /** fetch aggregated fields from the table: "property_tsvector" */
  property_tsvector_aggregate: property_tsvector_aggregate;
  /** fetch data from the table: "property_type" */
  property_type: Array<property_type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: property_type_aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<property_type>;
  /** fetch data from the table: "question" */
  question: Array<question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: question_aggregate;
  /** fetch data from the table: "question_answer" */
  question_answer: Array<question_answer>;
  /** fetch aggregated fields from the table: "question_answer" */
  question_answer_aggregate: question_answer_aggregate;
  /** fetch data from the table: "question_answer" using primary key columns */
  question_answer_by_pk?: Maybe<question_answer>;
  /** fetch data from the table: "question_answer_likelihood_to_return" */
  question_answer_likelihood_to_return: Array<question_answer_likelihood_to_return>;
  /** fetch aggregated fields from the table: "question_answer_likelihood_to_return" */
  question_answer_likelihood_to_return_aggregate: question_answer_likelihood_to_return_aggregate;
  /** fetch data from the table: "question_answer_likelihood_to_return" using primary key columns */
  question_answer_likelihood_to_return_by_pk?: Maybe<question_answer_likelihood_to_return>;
  /** fetch data from the table: "question_answer_radio" */
  question_answer_radio: Array<question_answer_radio>;
  /** fetch aggregated fields from the table: "question_answer_radio" */
  question_answer_radio_aggregate: question_answer_radio_aggregate;
  /** fetch data from the table: "question_answer_radio" using primary key columns */
  question_answer_radio_by_pk?: Maybe<question_answer_radio>;
  /** fetch data from the table: "question_answer_sentiment_phrase" */
  question_answer_sentiment_phrase: Array<question_answer_sentiment_phrase>;
  /** fetch aggregated fields from the table: "question_answer_sentiment_phrase" */
  question_answer_sentiment_phrase_aggregate: question_answer_sentiment_phrase_aggregate;
  /** fetch data from the table: "question_answer_sentiment_phrase" using primary key columns */
  question_answer_sentiment_phrase_by_pk?: Maybe<question_answer_sentiment_phrase>;
  /** fetch data from the table: "question_answer_text" */
  question_answer_text: Array<question_answer_text>;
  /** fetch aggregated fields from the table: "question_answer_text" */
  question_answer_text_aggregate: question_answer_text_aggregate;
  /** fetch data from the table: "question_answer_text" using primary key columns */
  question_answer_text_by_pk?: Maybe<question_answer_text>;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<question>;
  /** fetch data from the table: "question_option" */
  question_option: Array<question_option>;
  /** fetch aggregated fields from the table: "question_option" */
  question_option_aggregate: question_option_aggregate;
  /** fetch data from the table: "question_option" using primary key columns */
  question_option_by_pk?: Maybe<question_option>;
  /** fetch data from the table: "question_option_version" */
  question_option_version: Array<question_option_version>;
  /** fetch aggregated fields from the table: "question_option_version" */
  question_option_version_aggregate: question_option_version_aggregate;
  /** fetch data from the table: "question_option_version" using primary key columns */
  question_option_version_by_pk?: Maybe<question_option_version>;
  /** fetch data from the table: "question_setting" */
  question_setting: Array<question_setting>;
  /** fetch aggregated fields from the table: "question_setting" */
  question_setting_aggregate: question_setting_aggregate;
  /** fetch data from the table: "question_setting" using primary key columns */
  question_setting_by_pk?: Maybe<question_setting>;
  /** fetch data from the table: "question_setting_key" */
  question_setting_key: Array<question_setting_key>;
  /** fetch aggregated fields from the table: "question_setting_key" */
  question_setting_key_aggregate: question_setting_key_aggregate;
  /** fetch data from the table: "question_setting_key" using primary key columns */
  question_setting_key_by_pk?: Maybe<question_setting_key>;
  /** fetch data from the table: "question_setting_version" */
  question_setting_version: Array<question_setting_version>;
  /** fetch aggregated fields from the table: "question_setting_version" */
  question_setting_version_aggregate: question_setting_version_aggregate;
  /** fetch data from the table: "question_setting_version" using primary key columns */
  question_setting_version_by_pk?: Maybe<question_setting_version>;
  /** fetch data from the table: "question_type" */
  question_type: Array<question_type>;
  /** fetch aggregated fields from the table: "question_type" */
  question_type_aggregate: question_type_aggregate;
  /** fetch data from the table: "question_type" using primary key columns */
  question_type_by_pk?: Maybe<question_type>;
  /** fetch data from the table: "question_version" */
  question_version: Array<question_version>;
  /** fetch aggregated fields from the table: "question_version" */
  question_version_aggregate: question_version_aggregate;
  /** fetch data from the table: "question_version" using primary key columns */
  question_version_by_pk?: Maybe<question_version>;
  /** fetch data from the table: "question_version_condition_question_option" */
  question_version_condition_question_option: Array<question_version_condition_question_option>;
  /** fetch aggregated fields from the table: "question_version_condition_question_option" */
  question_version_condition_question_option_aggregate: question_version_condition_question_option_aggregate;
  /** fetch data from the table: "question_version_condition_segment" */
  question_version_condition_segment: Array<question_version_condition_segment>;
  /** fetch aggregated fields from the table: "question_version_condition_segment" */
  question_version_condition_segment_aggregate: question_version_condition_segment_aggregate;
  /** fetch data from the table: "question_version_condition_segment_group" */
  question_version_condition_segment_group: Array<question_version_condition_segment_group>;
  /** fetch aggregated fields from the table: "question_version_condition_segment_group" */
  question_version_condition_segment_group_aggregate: question_version_condition_segment_group_aggregate;
  /** fetch data from the table: "question_version_question_option" */
  question_version_question_option: Array<question_version_question_option>;
  /** fetch aggregated fields from the table: "question_version_question_option" */
  question_version_question_option_aggregate: question_version_question_option_aggregate;
  recurlyCustomFields: Array<RecurlyCustomField>;
  /** execute function "report_deflection_trends" which returns "template_report_deflection_trends" */
  report_deflection_trends: Array<template_report_deflection_trends>;
  /** execute function "report_deflection_trends" and query aggregates on result of table type "template_report_deflection_trends" */
  report_deflection_trends_aggregate: template_report_deflection_trends_aggregate;
  /** execute function "report_feedback_trends" which returns "template_report_feedback_trends" */
  report_feedback_trends: Array<template_report_feedback_trends>;
  /** execute function "report_feedback_trends" and query aggregates on result of table type "template_report_feedback_trends" */
  report_feedback_trends_aggregate: template_report_feedback_trends_aggregate;
  /** An array relationship */
  report_flow_test_stats: Array<report_flow_test_stats>;
  /** An aggregate relationship */
  report_flow_test_stats_aggregate: report_flow_test_stats_aggregate;
  /** fetch data from the table: "report_inactive_mrr_by_question_option" */
  report_inactive_mrr_by_question_option: Array<report_inactive_mrr_by_question_option>;
  /** fetch aggregated fields from the table: "report_inactive_mrr_by_question_option" */
  report_inactive_mrr_by_question_option_aggregate: report_inactive_mrr_by_question_option_aggregate;
  /** execute function "report_offer_accept_rate" which returns "template_report_offer_accept_rate" */
  report_offer_accept_rate: Array<template_report_offer_accept_rate>;
  /** execute function "report_offer_accept_rate" and query aggregates on result of table type "template_report_offer_accept_rate" */
  report_offer_accept_rate_aggregate: template_report_offer_accept_rate_aggregate;
  /** An array relationship */
  report_offer_autopilot_stats: Array<report_offer_autopilot_stats>;
  /** An aggregate relationship */
  report_offer_autopilot_stats_aggregate: report_offer_autopilot_stats_aggregate;
  /** execute function "report_offer_group_accept_rate" which returns "template_report_offer_group_accept_rate" */
  report_offer_group_accept_rate: Array<template_report_offer_group_accept_rate>;
  /** execute function "report_offer_group_accept_rate" and query aggregates on result of table type "template_report_offer_group_accept_rate" */
  report_offer_group_accept_rate_aggregate: template_report_offer_group_accept_rate_aggregate;
  /** fetch data from the table: "report_offer_group_resolution_by_question_option" */
  report_offer_group_resolution_by_question_option: Array<report_offer_group_resolution_by_question_option>;
  /** fetch aggregated fields from the table: "report_offer_group_resolution_by_question_option" */
  report_offer_group_resolution_by_question_option_aggregate: report_offer_group_resolution_by_question_option_aggregate;
  /** execute function "report_offer_group_retained_revenue" which returns "template_report_offer_group_retained_revenue" */
  report_offer_group_retained_revenue: Array<template_report_offer_group_retained_revenue>;
  /** execute function "report_offer_group_retained_revenue" and query aggregates on result of table type "template_report_offer_group_retained_revenue" */
  report_offer_group_retained_revenue_aggregate: template_report_offer_group_retained_revenue_aggregate;
  /** execute function "report_offer_rate" which returns "template_report_offer_rate" */
  report_offer_rate: Array<template_report_offer_rate>;
  /** execute function "report_offer_rate" and query aggregates on result of table type "template_report_offer_rate" */
  report_offer_rate_aggregate: template_report_offer_rate_aggregate;
  /** execute function "report_offer_resolution" which returns "template_report_offer_resolution" */
  report_offer_resolution: Array<template_report_offer_resolution>;
  /** execute function "report_offer_resolution" and query aggregates on result of table type "template_report_offer_resolution" */
  report_offer_resolution_aggregate: template_report_offer_resolution_aggregate;
  /** fetch data from the table: "report_offer_resolution_by_question_option" */
  report_offer_resolution_by_question_option: Array<report_offer_resolution_by_question_option>;
  /** fetch aggregated fields from the table: "report_offer_resolution_by_question_option" */
  report_offer_resolution_by_question_option_aggregate: report_offer_resolution_by_question_option_aggregate;
  /** execute function "report_offer_retained_revenue" which returns "template_report_offer_retained_revenue" */
  report_offer_retained_revenue: Array<template_report_offer_retained_revenue>;
  /** execute function "report_offer_retained_revenue" and query aggregates on result of table type "template_report_offer_retained_revenue" */
  report_offer_retained_revenue_aggregate: template_report_offer_retained_revenue_aggregate;
  /** An array relationship */
  report_offer_test_stats: Array<report_offer_test_stats>;
  /** An aggregate relationship */
  report_offer_test_stats_aggregate: report_offer_test_stats_aggregate;
  /** execute function "report_question_answer_radio" which returns "template_report_question_answer_radio" */
  report_question_answer_radio: Array<template_report_question_answer_radio>;
  /** execute function "report_question_answer_radio" and query aggregates on result of table type "template_report_question_answer_radio" */
  report_question_answer_radio_aggregate: template_report_question_answer_radio_aggregate;
  /** execute function "report_question_answer_radio_inactive_mrr" which returns "template_report_question_answer_radio_inactive_mrr" */
  report_question_answer_radio_inactive_mrr: Array<template_report_question_answer_radio_inactive_mrr>;
  /** execute function "report_question_answer_radio_inactive_mrr" and query aggregates on result of table type "template_report_question_answer_radio_inactive_mrr" */
  report_question_answer_radio_inactive_mrr_aggregate: template_report_question_answer_radio_inactive_mrr_aggregate;
  /** execute function "report_question_answer_sentiment" which returns "template_report_question_answer_sentiment" */
  report_question_answer_sentiment: Array<template_report_question_answer_sentiment>;
  /** execute function "report_question_answer_sentiment" and query aggregates on result of table type "template_report_question_answer_sentiment" */
  report_question_answer_sentiment_aggregate: template_report_question_answer_sentiment_aggregate;
  /** fetch data from the table: "report_recurring_revenue" */
  report_recurring_revenue: Array<report_recurring_revenue>;
  /** fetch aggregated fields from the table: "report_recurring_revenue" */
  report_recurring_revenue_aggregate: report_recurring_revenue_aggregate;
  /** fetch data from the table: "report_recurring_revenue" using primary key columns */
  report_recurring_revenue_by_pk?: Maybe<report_recurring_revenue>;
  /** execute function "report_retained_revenue" which returns "template_report_retained_revenue" */
  report_retained_revenue: Array<template_report_retained_revenue>;
  /** execute function "report_retained_revenue" and query aggregates on result of table type "template_report_retained_revenue" */
  report_retained_revenue_aggregate: template_report_retained_revenue_aggregate;
  /** execute function "report_retention_trends" which returns "template_report_retention_trends" */
  report_retention_trends: Array<template_report_retention_trends>;
  /** execute function "report_retention_trends" and query aggregates on result of table type "template_report_retention_trends" */
  report_retention_trends_aggregate: template_report_retention_trends_aggregate;
  /** execute function "report_subscriber_flow_status" which returns "template_report_subscriber_flow_status" */
  report_subscriber_flow_status: Array<template_report_subscriber_flow_status>;
  /** execute function "report_subscriber_flow_status" and query aggregates on result of table type "template_report_subscriber_flow_status" */
  report_subscriber_flow_status_aggregate: template_report_subscriber_flow_status_aggregate;
  /** fetch data from the table: "revenue_log" */
  revenue_log: Array<revenue_log>;
  /** fetch aggregated fields from the table: "revenue_log" */
  revenue_log_aggregate: revenue_log_aggregate;
  /** fetch data from the table: "revenue_log" using primary key columns */
  revenue_log_by_pk?: Maybe<revenue_log>;
  /** fetch data from the table: "revenue_log_revenue_type" */
  revenue_log_revenue_type: Array<revenue_log_revenue_type>;
  /** fetch aggregated fields from the table: "revenue_log_revenue_type" */
  revenue_log_revenue_type_aggregate: revenue_log_revenue_type_aggregate;
  /** fetch data from the table: "revenue_log_revenue_type" using primary key columns */
  revenue_log_revenue_type_by_pk?: Maybe<revenue_log_revenue_type>;
  /** fetch data from the table: "revenue_log_type" */
  revenue_log_type: Array<revenue_log_type>;
  /** fetch aggregated fields from the table: "revenue_log_type" */
  revenue_log_type_aggregate: revenue_log_type_aggregate;
  /** fetch data from the table: "revenue_log_type" using primary key columns */
  revenue_log_type_by_pk?: Maybe<revenue_log_type>;
  roiCalculator: RoiCalculatorOutput;
  /** fetch data from the table: "rule_group_mode" */
  rule_group_mode: Array<rule_group_mode>;
  /** fetch aggregated fields from the table: "rule_group_mode" */
  rule_group_mode_aggregate: rule_group_mode_aggregate;
  /** fetch data from the table: "rule_group_mode" using primary key columns */
  rule_group_mode_by_pk?: Maybe<rule_group_mode>;
  salesforceFields: Array<SalesforceField>;
  /** fetch data from the table: "script_log" */
  script_log: Array<script_log>;
  /** fetch aggregated fields from the table: "script_log" */
  script_log_aggregate: script_log_aggregate;
  /** fetch data from the table: "script_log" using primary key columns */
  script_log_by_pk?: Maybe<script_log>;
  searchPlatformCustomers: SearchPlatformCustomersOutput;
  /** execute function "search_accounts" which returns "account" */
  search_accounts: Array<account>;
  /** execute function "search_accounts" and query aggregates on result of table type "account" */
  search_accounts_aggregate: account_aggregate;
  /** execute function "search_data_connector_request_logs" which returns "data_connector_request_log" */
  search_data_connector_request_logs: Array<data_connector_request_log>;
  /** execute function "search_data_connector_request_logs" and query aggregates on result of table type "data_connector_request_log" */
  search_data_connector_request_logs_aggregate: data_connector_request_log_aggregate;
  /** execute function "search_deflections" which returns "deflection" */
  search_deflections: Array<deflection>;
  /** execute function "search_deflections" and query aggregates on result of table type "deflection" */
  search_deflections_aggregate: deflection_aggregate;
  /** execute function "search_offers" which returns "offer" */
  search_offers: Array<offer>;
  /** execute function "search_offers" and query aggregates on result of table type "offer" */
  search_offers_aggregate: offer_aggregate;
  /** execute function "search_platform_api_logs" which returns "platform_api_log" */
  search_platform_api_logs: Array<platform_api_log>;
  /** execute function "search_platform_api_logs" and query aggregates on result of table type "platform_api_log" */
  search_platform_api_logs_aggregate: platform_api_log_aggregate;
  /** execute function "search_platform_coupons" which returns "platform_coupon" */
  search_platform_coupons: Array<platform_coupon>;
  /** execute function "search_platform_coupons" and query aggregates on result of table type "platform_coupon" */
  search_platform_coupons_aggregate: platform_coupon_aggregate;
  /** execute function "search_properties" which returns "property" */
  search_properties: Array<property>;
  /** execute function "search_properties" and query aggregates on result of table type "property" */
  search_properties_aggregate: property_aggregate;
  /** execute function "search_segment_groups" which returns "segment_group" */
  search_segment_groups: Array<segment_group>;
  /** execute function "search_segment_groups" and query aggregates on result of table type "segment_group" */
  search_segment_groups_aggregate: segment_group_aggregate;
  /** execute function "search_segments" which returns "segment" */
  search_segments: Array<segment>;
  /** execute function "search_segments" and query aggregates on result of table type "segment" */
  search_segments_aggregate: segment_aggregate;
  /** execute function "search_subscriber_flows" which returns "subscriber_flow" */
  search_subscriber_flows: Array<subscriber_flow>;
  /** execute function "search_subscriber_flows" and query aggregates on result of table type "subscriber_flow" */
  search_subscriber_flows_aggregate: subscriber_flow_aggregate;
  /** execute function "search_subscriber_grid" which returns "subscriber_grid" */
  search_subscriber_grid: Array<subscriber_grid>;
  /** execute function "search_subscriber_grid" and query aggregates on result of table type "subscriber_grid" */
  search_subscriber_grid_aggregate: subscriber_grid_aggregate;
  /** execute function "search_subscribers" which returns "subscriber" */
  search_subscribers: Array<subscriber>;
  /** execute function "search_subscribers" and query aggregates on result of table type "subscriber" */
  search_subscribers_aggregate: subscriber_aggregate;
  /** execute function "search_webhook_request_logs" which returns "webhook_request_log" */
  search_webhook_request_logs: Array<webhook_request_log>;
  /** execute function "search_webhook_request_logs" and query aggregates on result of table type "webhook_request_log" */
  search_webhook_request_logs_aggregate: webhook_request_log_aggregate;
  /** fetch data from the table: "segment" */
  segment: Array<segment>;
  segmentMatch?: Maybe<SegmentMatchOutput>;
  /** fetch aggregated fields from the table: "segment" */
  segment_aggregate: segment_aggregate;
  /** fetch data from the table: "segment" using primary key columns */
  segment_by_pk?: Maybe<segment>;
  /** fetch data from the table: "segment_condition" */
  segment_condition: Array<segment_condition>;
  /** fetch aggregated fields from the table: "segment_condition" */
  segment_condition_aggregate: segment_condition_aggregate;
  /** fetch data from the table: "segment_condition_boolean_operator" */
  segment_condition_boolean_operator: Array<segment_condition_boolean_operator>;
  /** fetch aggregated fields from the table: "segment_condition_boolean_operator" */
  segment_condition_boolean_operator_aggregate: segment_condition_boolean_operator_aggregate;
  /** fetch data from the table: "segment_condition_boolean_operator" using primary key columns */
  segment_condition_boolean_operator_by_pk?: Maybe<segment_condition_boolean_operator>;
  /** fetch data from the table: "segment_condition" using primary key columns */
  segment_condition_by_pk?: Maybe<segment_condition>;
  /** fetch data from the table: "segment_condition_group" */
  segment_condition_group: Array<segment_condition_group>;
  /** fetch aggregated fields from the table: "segment_condition_group" */
  segment_condition_group_aggregate: segment_condition_group_aggregate;
  /** fetch data from the table: "segment_condition_group" using primary key columns */
  segment_condition_group_by_pk?: Maybe<segment_condition_group>;
  /** fetch data from the table: "segment_condition_group_entry" */
  segment_condition_group_entry: Array<segment_condition_group_entry>;
  /** fetch aggregated fields from the table: "segment_condition_group_entry" */
  segment_condition_group_entry_aggregate: segment_condition_group_entry_aggregate;
  /** fetch data from the table: "segment_condition_group_entry" using primary key columns */
  segment_condition_group_entry_by_pk?: Maybe<segment_condition_group_entry>;
  /** fetch data from the table: "segment_condition_operator" */
  segment_condition_operator: Array<segment_condition_operator>;
  /** fetch aggregated fields from the table: "segment_condition_operator" */
  segment_condition_operator_aggregate: segment_condition_operator_aggregate;
  /** fetch data from the table: "segment_condition_operator" using primary key columns */
  segment_condition_operator_by_pk?: Maybe<segment_condition_operator>;
  /** fetch data from the table: "segment_condition_property" */
  segment_condition_property: Array<segment_condition_property>;
  /** fetch aggregated fields from the table: "segment_condition_property" */
  segment_condition_property_aggregate: segment_condition_property_aggregate;
  /** fetch data from the table: "segment_condition_property" using primary key columns */
  segment_condition_property_by_pk?: Maybe<segment_condition_property>;
  /** fetch data from the table: "segment_group" */
  segment_group: Array<segment_group>;
  /** fetch aggregated fields from the table: "segment_group" */
  segment_group_aggregate: segment_group_aggregate;
  /** fetch data from the table: "segment_group" using primary key columns */
  segment_group_by_pk?: Maybe<segment_group>;
  /** fetch data from the table: "segment_group_operator" */
  segment_group_operator: Array<segment_group_operator>;
  /** fetch aggregated fields from the table: "segment_group_operator" */
  segment_group_operator_aggregate: segment_group_operator_aggregate;
  /** fetch data from the table: "segment_group_operator" using primary key columns */
  segment_group_operator_by_pk?: Maybe<segment_group_operator>;
  /** fetch data from the table: "segment_group_segment" */
  segment_group_segment: Array<segment_group_segment>;
  /** fetch aggregated fields from the table: "segment_group_segment" */
  segment_group_segment_aggregate: segment_group_segment_aggregate;
  /** fetch data from the table: "segment_group_segment" using primary key columns */
  segment_group_segment_by_pk?: Maybe<segment_group_segment>;
  /** fetch data from the table: "segment_group_tsvector" */
  segment_group_tsvector: Array<segment_group_tsvector>;
  /** fetch aggregated fields from the table: "segment_group_tsvector" */
  segment_group_tsvector_aggregate: segment_group_tsvector_aggregate;
  /** execute function "segment_subscriber" which returns "subscriber" */
  segment_subscriber: Array<subscriber>;
  /** execute function "segment_subscriber" and query aggregates on result of table type "subscriber" */
  segment_subscriber_aggregate: subscriber_aggregate;
  /** execute function "segment_subscriber_flow" which returns "subscriber_flow" */
  segment_subscriber_flow: Array<subscriber_flow>;
  /** execute function "segment_subscriber_flow" and query aggregates on result of table type "subscriber_flow" */
  segment_subscriber_flow_aggregate: subscriber_flow_aggregate;
  /** execute function "segment_subscriber_log" which returns "subscriber_log" */
  segment_subscriber_log: Array<subscriber_log>;
  /** execute function "segment_subscriber_log" and query aggregates on result of table type "subscriber_log" */
  segment_subscriber_log_aggregate: subscriber_log_aggregate;
  /** execute function "segment_subscription" which returns "subscription" */
  segment_subscription: Array<subscription>;
  /** execute function "segment_subscription" and query aggregates on result of table type "subscription" */
  segment_subscription_aggregate: subscription_aggregate;
  /** fetch data from the table: "segment_subscription_cache" */
  segment_subscription_cache: Array<segment_subscription_cache>;
  /** fetch aggregated fields from the table: "segment_subscription_cache" */
  segment_subscription_cache_aggregate: segment_subscription_cache_aggregate;
  /** fetch data from the table: "segment_subscription_cache" using primary key columns */
  segment_subscription_cache_by_pk?: Maybe<segment_subscription_cache>;
  /** fetch data from the table: "segment_tag" */
  segment_tag: Array<segment_tag>;
  /** fetch aggregated fields from the table: "segment_tag" */
  segment_tag_aggregate: segment_tag_aggregate;
  /** fetch data from the table: "segment_tsvector" */
  segment_tsvector: Array<segment_tsvector>;
  /** fetch aggregated fields from the table: "segment_tsvector" */
  segment_tsvector_aggregate: segment_tsvector_aggregate;
  /** fetch data from the table: "sentiment" */
  sentiment: Array<sentiment>;
  /** fetch aggregated fields from the table: "sentiment" */
  sentiment_aggregate: sentiment_aggregate;
  /** fetch data from the table: "sentiment" using primary key columns */
  sentiment_by_pk?: Maybe<sentiment>;
  /** fetch data from the table: "sentiment_phrase" */
  sentiment_phrase: Array<sentiment_phrase>;
  /** fetch aggregated fields from the table: "sentiment_phrase" */
  sentiment_phrase_aggregate: sentiment_phrase_aggregate;
  /** fetch data from the table: "sentiment_phrase" using primary key columns */
  sentiment_phrase_by_pk?: Maybe<sentiment_phrase>;
  /** fetch data from the table: "sentiment_phrase_normalized" */
  sentiment_phrase_normalized: Array<sentiment_phrase_normalized>;
  /** fetch aggregated fields from the table: "sentiment_phrase_normalized" */
  sentiment_phrase_normalized_aggregate: sentiment_phrase_normalized_aggregate;
  /** fetch data from the table: "sentiment_phrase_normalized" using primary key columns */
  sentiment_phrase_normalized_by_pk?: Maybe<sentiment_phrase_normalized>;
  ssoLogInDetails: SsoLogInDetailsOutput;
  /** fetch data from the table: "state" */
  state: Array<state>;
  /** fetch aggregated fields from the table: "state" */
  state_aggregate: state_aggregate;
  /** fetch data from the table: "state" using primary key columns */
  state_by_pk?: Maybe<state>;
  /** fetch data from the table: "state_name" */
  state_name: Array<state_name>;
  /** fetch aggregated fields from the table: "state_name" */
  state_name_aggregate: state_name_aggregate;
  /** fetch data from the table: "state_name" using primary key columns */
  state_name_by_pk?: Maybe<state_name>;
  /** fetch data from the table: "stripe_app" */
  stripe_app: Array<stripe_app>;
  /** fetch aggregated fields from the table: "stripe_app" */
  stripe_app_aggregate: stripe_app_aggregate;
  /** fetch data from the table: "stripe_app" using primary key columns */
  stripe_app_by_pk?: Maybe<stripe_app>;
  /** fetch data from the table: "subscriber" */
  subscriber: Array<subscriber>;
  subscriberCampaignPaymentInfo: SubscriberCampaignPaymentInfoOutput;
  subscriberFlowOffer: SubscriberFlowOfferOutput;
  subscriberFlowSegmentMatches: SubscriberFlowSegmentMatchesOutput;
  /** fetch data from the table: "subscriber_acknowledgement" */
  subscriber_acknowledgement: Array<subscriber_acknowledgement>;
  /** fetch aggregated fields from the table: "subscriber_acknowledgement" */
  subscriber_acknowledgement_aggregate: subscriber_acknowledgement_aggregate;
  /** fetch data from the table: "subscriber_acknowledgement" using primary key columns */
  subscriber_acknowledgement_by_pk?: Maybe<subscriber_acknowledgement>;
  /** fetch aggregated fields from the table: "subscriber" */
  subscriber_aggregate: subscriber_aggregate;
  /** fetch data from the table: "subscriber_arr" */
  subscriber_arr: Array<subscriber_arr>;
  /** fetch aggregated fields from the table: "subscriber_arr" */
  subscriber_arr_aggregate: subscriber_arr_aggregate;
  /** fetch data from the table: "subscriber" using primary key columns */
  subscriber_by_pk?: Maybe<subscriber>;
  /** fetch data from the table: "subscriber_campaign" */
  subscriber_campaign: Array<subscriber_campaign>;
  /** fetch aggregated fields from the table: "subscriber_campaign" */
  subscriber_campaign_aggregate: subscriber_campaign_aggregate;
  /** fetch data from the table: "subscriber_campaign" using primary key columns */
  subscriber_campaign_by_pk?: Maybe<subscriber_campaign>;
  /** fetch data from the table: "subscriber_campaign_offer" */
  subscriber_campaign_offer: Array<subscriber_campaign_offer>;
  /** fetch aggregated fields from the table: "subscriber_campaign_offer" */
  subscriber_campaign_offer_aggregate: subscriber_campaign_offer_aggregate;
  /** fetch data from the table: "subscriber_campaign_offer" using primary key columns */
  subscriber_campaign_offer_by_pk?: Maybe<subscriber_campaign_offer>;
  /** fetch data from the table: "subscriber_campaign_offer_rule" */
  subscriber_campaign_offer_rule: Array<subscriber_campaign_offer_rule>;
  /** fetch aggregated fields from the table: "subscriber_campaign_offer_rule" */
  subscriber_campaign_offer_rule_aggregate: subscriber_campaign_offer_rule_aggregate;
  /** fetch data from the table: "subscriber_campaign_offer_rule" using primary key columns */
  subscriber_campaign_offer_rule_by_pk?: Maybe<subscriber_campaign_offer_rule>;
  /** fetch data from the table: "subscriber_campaign_override" */
  subscriber_campaign_override: Array<subscriber_campaign_override>;
  /** fetch aggregated fields from the table: "subscriber_campaign_override" */
  subscriber_campaign_override_aggregate: subscriber_campaign_override_aggregate;
  /** fetch data from the table: "subscriber_campaign_override" using primary key columns */
  subscriber_campaign_override_by_pk?: Maybe<subscriber_campaign_override>;
  /** fetch data from the table: "subscriber_campaign_segment" */
  subscriber_campaign_segment: Array<subscriber_campaign_segment>;
  /** fetch aggregated fields from the table: "subscriber_campaign_segment" */
  subscriber_campaign_segment_aggregate: subscriber_campaign_segment_aggregate;
  /** fetch data from the table: "subscriber_campaign_segment" using primary key columns */
  subscriber_campaign_segment_by_pk?: Maybe<subscriber_campaign_segment>;
  /** fetch data from the table: "subscriber_campaign_status" */
  subscriber_campaign_status: Array<subscriber_campaign_status>;
  /** fetch aggregated fields from the table: "subscriber_campaign_status" */
  subscriber_campaign_status_aggregate: subscriber_campaign_status_aggregate;
  /** fetch data from the table: "subscriber_campaign_status" using primary key columns */
  subscriber_campaign_status_by_pk?: Maybe<subscriber_campaign_status>;
  /** fetch data from the table: "subscriber_campaign_view" */
  subscriber_campaign_view: Array<subscriber_campaign_view>;
  /** fetch aggregated fields from the table: "subscriber_campaign_view" */
  subscriber_campaign_view_aggregate: subscriber_campaign_view_aggregate;
  /** fetch data from the table: "subscriber_campaign_view" using primary key columns */
  subscriber_campaign_view_by_pk?: Maybe<subscriber_campaign_view>;
  /** fetch data from the table: "subscriber_campaign_view_status" */
  subscriber_campaign_view_status: Array<subscriber_campaign_view_status>;
  /** fetch aggregated fields from the table: "subscriber_campaign_view_status" */
  subscriber_campaign_view_status_aggregate: subscriber_campaign_view_status_aggregate;
  /** fetch data from the table: "subscriber_campaign_view_status" using primary key columns */
  subscriber_campaign_view_status_by_pk?: Maybe<subscriber_campaign_view_status>;
  /** fetch data from the table: "subscriber_created_at" */
  subscriber_created_at: Array<subscriber_created_at>;
  /** fetch aggregated fields from the table: "subscriber_created_at" */
  subscriber_created_at_aggregate: subscriber_created_at_aggregate;
  /** fetch data from the table: "subscriber_days_since_saved" */
  subscriber_days_since_saved: Array<subscriber_days_since_saved>;
  /** fetch aggregated fields from the table: "subscriber_days_since_saved" */
  subscriber_days_since_saved_aggregate: subscriber_days_since_saved_aggregate;
  /** fetch data from the table: "subscriber_flow" */
  subscriber_flow: Array<subscriber_flow>;
  /** fetch aggregated fields from the table: "subscriber_flow" */
  subscriber_flow_aggregate: subscriber_flow_aggregate;
  /** fetch data from the table: "subscriber_flow" using primary key columns */
  subscriber_flow_by_pk?: Maybe<subscriber_flow>;
  /** fetch data from the table: "subscriber_flow_deflection" */
  subscriber_flow_deflection: Array<subscriber_flow_deflection>;
  /** fetch aggregated fields from the table: "subscriber_flow_deflection" */
  subscriber_flow_deflection_aggregate: subscriber_flow_deflection_aggregate;
  /** fetch data from the table: "subscriber_flow_deflection" using primary key columns */
  subscriber_flow_deflection_by_pk?: Maybe<subscriber_flow_deflection>;
  /** fetch data from the table: "subscriber_flow_export" */
  subscriber_flow_export: Array<subscriber_flow_export>;
  /** fetch aggregated fields from the table: "subscriber_flow_export" */
  subscriber_flow_export_aggregate: subscriber_flow_export_aggregate;
  /** fetch data from the table: "subscriber_flow_export" using primary key columns */
  subscriber_flow_export_by_pk?: Maybe<subscriber_flow_export>;
  /** fetch data from the table: "subscriber_flow_flow_step" */
  subscriber_flow_flow_step: Array<subscriber_flow_flow_step>;
  /** fetch aggregated fields from the table: "subscriber_flow_flow_step" */
  subscriber_flow_flow_step_aggregate: subscriber_flow_flow_step_aggregate;
  /** fetch data from the table: "subscriber_flow_flow_step" using primary key columns */
  subscriber_flow_flow_step_by_pk?: Maybe<subscriber_flow_flow_step>;
  /** fetch data from the table: "subscriber_flow_offer" */
  subscriber_flow_offer: Array<subscriber_flow_offer>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer" */
  subscriber_flow_offer_aggregate: subscriber_flow_offer_aggregate;
  /** fetch data from the table: "subscriber_flow_offer" using primary key columns */
  subscriber_flow_offer_by_pk?: Maybe<subscriber_flow_offer>;
  /** fetch data from the table: "subscriber_flow_offer_group" */
  subscriber_flow_offer_group: Array<subscriber_flow_offer_group>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_group" */
  subscriber_flow_offer_group_aggregate: subscriber_flow_offer_group_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_group" using primary key columns */
  subscriber_flow_offer_group_by_pk?: Maybe<subscriber_flow_offer_group>;
  /** fetch data from the table: "subscriber_flow_offer_group_offer" */
  subscriber_flow_offer_group_offer: Array<subscriber_flow_offer_group_offer>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_group_offer" */
  subscriber_flow_offer_group_offer_aggregate: subscriber_flow_offer_group_offer_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_group_offer" using primary key columns */
  subscriber_flow_offer_group_offer_by_pk?: Maybe<subscriber_flow_offer_group_offer>;
  /** fetch data from the table: "subscriber_flow_offer_rule" */
  subscriber_flow_offer_rule: Array<subscriber_flow_offer_rule>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_rule" */
  subscriber_flow_offer_rule_aggregate: subscriber_flow_offer_rule_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_rule" using primary key columns */
  subscriber_flow_offer_rule_by_pk?: Maybe<subscriber_flow_offer_rule>;
  /** fetch data from the table: "subscriber_flow_offer_status" */
  subscriber_flow_offer_status: Array<subscriber_flow_offer_status>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_status" */
  subscriber_flow_offer_status_aggregate: subscriber_flow_offer_status_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_status" using primary key columns */
  subscriber_flow_offer_status_by_pk?: Maybe<subscriber_flow_offer_status>;
  /** fetch data from the table: "subscriber_flow_on_cancel_override" */
  subscriber_flow_on_cancel_override: Array<subscriber_flow_on_cancel_override>;
  /** fetch aggregated fields from the table: "subscriber_flow_on_cancel_override" */
  subscriber_flow_on_cancel_override_aggregate: subscriber_flow_on_cancel_override_aggregate;
  /** fetch data from the table: "subscriber_flow_on_cancel_override" using primary key columns */
  subscriber_flow_on_cancel_override_by_pk?: Maybe<subscriber_flow_on_cancel_override>;
  /** fetch data from the table: "subscriber_flow_override" */
  subscriber_flow_override: Array<subscriber_flow_override>;
  /** fetch aggregated fields from the table: "subscriber_flow_override" */
  subscriber_flow_override_aggregate: subscriber_flow_override_aggregate;
  /** fetch data from the table: "subscriber_flow_override" using primary key columns */
  subscriber_flow_override_by_pk?: Maybe<subscriber_flow_override>;
  /** fetch data from the table: "subscriber_flow_override_reason" */
  subscriber_flow_override_reason: Array<subscriber_flow_override_reason>;
  /** fetch aggregated fields from the table: "subscriber_flow_override_reason" */
  subscriber_flow_override_reason_aggregate: subscriber_flow_override_reason_aggregate;
  /** fetch data from the table: "subscriber_flow_override_reason" using primary key columns */
  subscriber_flow_override_reason_by_pk?: Maybe<subscriber_flow_override_reason>;
  /** fetch data from the table: "subscriber_flow_question_answer" */
  subscriber_flow_question_answer: Array<subscriber_flow_question_answer>;
  /** fetch aggregated fields from the table: "subscriber_flow_question_answer" */
  subscriber_flow_question_answer_aggregate: subscriber_flow_question_answer_aggregate;
  /** fetch data from the table: "subscriber_flow_question_answer" using primary key columns */
  subscriber_flow_question_answer_by_pk?: Maybe<subscriber_flow_question_answer>;
  /** fetch data from the table: "subscriber_flow_segment" */
  subscriber_flow_segment: Array<subscriber_flow_segment>;
  /** fetch aggregated fields from the table: "subscriber_flow_segment" */
  subscriber_flow_segment_aggregate: subscriber_flow_segment_aggregate;
  /** fetch data from the table: "subscriber_flow_segment" using primary key columns */
  subscriber_flow_segment_by_pk?: Maybe<subscriber_flow_segment>;
  /** fetch data from the table: "subscriber_flow_segment_group" */
  subscriber_flow_segment_group: Array<subscriber_flow_segment_group>;
  /** fetch aggregated fields from the table: "subscriber_flow_segment_group" */
  subscriber_flow_segment_group_aggregate: subscriber_flow_segment_group_aggregate;
  /** fetch data from the table: "subscriber_flow_segment_group" using primary key columns */
  subscriber_flow_segment_group_by_pk?: Maybe<subscriber_flow_segment_group>;
  /** fetch data from the table: "subscriber_flow_status" */
  subscriber_flow_status: Array<subscriber_flow_status>;
  /** fetch aggregated fields from the table: "subscriber_flow_status" */
  subscriber_flow_status_aggregate: subscriber_flow_status_aggregate;
  /** fetch data from the table: "subscriber_flow_status_by_month" */
  subscriber_flow_status_by_month: Array<subscriber_flow_status_by_month>;
  /** fetch aggregated fields from the table: "subscriber_flow_status_by_month" */
  subscriber_flow_status_by_month_aggregate: subscriber_flow_status_by_month_aggregate;
  /** fetch data from the table: "subscriber_flow_status" using primary key columns */
  subscriber_flow_status_by_pk?: Maybe<subscriber_flow_status>;
  /** fetch data from the table: "subscriber_flow_tsvector" */
  subscriber_flow_tsvector: Array<subscriber_flow_tsvector>;
  /** fetch aggregated fields from the table: "subscriber_flow_tsvector" */
  subscriber_flow_tsvector_aggregate: subscriber_flow_tsvector_aggregate;
  /** fetch data from the table: "subscriber_flow_url" */
  subscriber_flow_url: Array<subscriber_flow_url>;
  /** fetch aggregated fields from the table: "subscriber_flow_url" */
  subscriber_flow_url_aggregate: subscriber_flow_url_aggregate;
  /** fetch data from the table: "subscriber_flow_version_last_60_days" */
  subscriber_flow_version_last_60_days: Array<subscriber_flow_version_last_60_days>;
  /** fetch aggregated fields from the table: "subscriber_flow_version_last_60_days" */
  subscriber_flow_version_last_60_days_aggregate: subscriber_flow_version_last_60_days_aggregate;
  /** fetch data from the table: "subscriber_grid" */
  subscriber_grid: Array<subscriber_grid>;
  /** fetch aggregated fields from the table: "subscriber_grid" */
  subscriber_grid_aggregate: subscriber_grid_aggregate;
  /** fetch data from the table: "subscriber_log" */
  subscriber_log: Array<subscriber_log>;
  /** fetch aggregated fields from the table: "subscriber_log" */
  subscriber_log_aggregate: subscriber_log_aggregate;
  /** fetch data from the table: "subscriber_log" using primary key columns */
  subscriber_log_by_pk?: Maybe<subscriber_log>;
  /** fetch data from the table: "subscriber_log_event" */
  subscriber_log_event: Array<subscriber_log_event>;
  /** fetch aggregated fields from the table: "subscriber_log_event" */
  subscriber_log_event_aggregate: subscriber_log_event_aggregate;
  /** fetch data from the table: "subscriber_log_event_by_month" */
  subscriber_log_event_by_month: Array<subscriber_log_event_by_month>;
  /** fetch aggregated fields from the table: "subscriber_log_event_by_month" */
  subscriber_log_event_by_month_aggregate: subscriber_log_event_by_month_aggregate;
  /** fetch data from the table: "subscriber_log_event" using primary key columns */
  subscriber_log_event_by_pk?: Maybe<subscriber_log_event>;
  /** fetch data from the table: "subscriber_log_offer" */
  subscriber_log_offer: Array<subscriber_log_offer>;
  /** fetch aggregated fields from the table: "subscriber_log_offer" */
  subscriber_log_offer_aggregate: subscriber_log_offer_aggregate;
  /** fetch data from the table: "subscriber_log_offer" using primary key columns */
  subscriber_log_offer_by_pk?: Maybe<subscriber_log_offer>;
  /** fetch data from the table: "subscriber_log_offer_group" */
  subscriber_log_offer_group: Array<subscriber_log_offer_group>;
  /** fetch aggregated fields from the table: "subscriber_log_offer_group" */
  subscriber_log_offer_group_aggregate: subscriber_log_offer_group_aggregate;
  /** fetch data from the table: "subscriber_log_offer_group" using primary key columns */
  subscriber_log_offer_group_by_pk?: Maybe<subscriber_log_offer_group>;
  /** fetch data from the table: "subscriber_ltv" */
  subscriber_ltv: Array<subscriber_ltv>;
  /** fetch aggregated fields from the table: "subscriber_ltv" */
  subscriber_ltv_aggregate: subscriber_ltv_aggregate;
  /** fetch data from the table: "subscriber_most_recent_subscriber_flow" */
  subscriber_most_recent_subscriber_flow: Array<subscriber_most_recent_subscriber_flow>;
  /** fetch aggregated fields from the table: "subscriber_most_recent_subscriber_flow" */
  subscriber_most_recent_subscriber_flow_aggregate: subscriber_most_recent_subscriber_flow_aggregate;
  /** fetch data from the table: "subscriber_mrr" */
  subscriber_mrr: Array<subscriber_mrr>;
  /** fetch aggregated fields from the table: "subscriber_mrr" */
  subscriber_mrr_aggregate: subscriber_mrr_aggregate;
  /** fetch data from the table: "subscriber_property" */
  subscriber_property: Array<subscriber_property>;
  /** fetch aggregated fields from the table: "subscriber_property" */
  subscriber_property_aggregate: subscriber_property_aggregate;
  /** fetch data from the table: "subscriber_property" using primary key columns */
  subscriber_property_by_pk?: Maybe<subscriber_property>;
  /** fetch data from the table: "subscriber_property_value" */
  subscriber_property_value: Array<subscriber_property_value>;
  /** fetch aggregated fields from the table: "subscriber_property_value" */
  subscriber_property_value_aggregate: subscriber_property_value_aggregate;
  /** fetch data from the table: "subscriber_status" */
  subscriber_status: Array<subscriber_status>;
  /** fetch aggregated fields from the table: "subscriber_status" */
  subscriber_status_aggregate: subscriber_status_aggregate;
  /** fetch data from the table: "subscriber_status" using primary key columns */
  subscriber_status_by_pk?: Maybe<subscriber_status>;
  /** fetch data from the table: "subscriber_tsvector" */
  subscriber_tsvector: Array<subscriber_tsvector>;
  /** fetch aggregated fields from the table: "subscriber_tsvector" */
  subscriber_tsvector_aggregate: subscriber_tsvector_aggregate;
  /** fetch data from the table: "subscription" */
  subscription: Array<subscription>;
  subscriptionSegmentPropertyValues: SubscriptionSegmentPropertyValuesOutput;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: subscription_aggregate;
  /** fetch data from the table: "subscription_amount_paid_by_month" */
  subscription_amount_paid_by_month: Array<subscription_amount_paid_by_month>;
  /** fetch aggregated fields from the table: "subscription_amount_paid_by_month" */
  subscription_amount_paid_by_month_aggregate: subscription_amount_paid_by_month_aggregate;
  /** fetch data from the table: "subscription_arr" */
  subscription_arr: Array<subscription_arr>;
  /** fetch aggregated fields from the table: "subscription_arr" */
  subscription_arr_aggregate: subscription_arr_aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<subscription>;
  /** fetch data from the table: "subscription_duration" */
  subscription_duration: Array<subscription_duration>;
  /** fetch aggregated fields from the table: "subscription_duration" */
  subscription_duration_aggregate: subscription_duration_aggregate;
  /** fetch data from the table: "subscription_ltv" */
  subscription_ltv: Array<subscription_ltv>;
  /** fetch aggregated fields from the table: "subscription_ltv" */
  subscription_ltv_aggregate: subscription_ltv_aggregate;
  /** fetch data from the table: "subscription_most_recent_subscriber_flow" */
  subscription_most_recent_subscriber_flow: Array<subscription_most_recent_subscriber_flow>;
  /** fetch aggregated fields from the table: "subscription_most_recent_subscriber_flow" */
  subscription_most_recent_subscriber_flow_aggregate: subscription_most_recent_subscriber_flow_aggregate;
  /** fetch data from the table: "subscription_product" */
  subscription_product: Array<subscription_product>;
  /** fetch aggregated fields from the table: "subscription_product" */
  subscription_product_aggregate: subscription_product_aggregate;
  /** fetch data from the table: "subscription_product" using primary key columns */
  subscription_product_by_pk?: Maybe<subscription_product>;
  /** fetch data from the table: "subscription_property" */
  subscription_property: Array<subscription_property>;
  /** fetch aggregated fields from the table: "subscription_property" */
  subscription_property_aggregate: subscription_property_aggregate;
  /** fetch data from the table: "subscription_property" using primary key columns */
  subscription_property_by_pk?: Maybe<subscription_property>;
  /** fetch data from the table: "subscription_property_value" */
  subscription_property_value: Array<subscription_property_value>;
  /** fetch aggregated fields from the table: "subscription_property_value" */
  subscription_property_value_aggregate: subscription_property_value_aggregate;
  /** fetch data from the table: "subscription_revenue_by_month" */
  subscription_revenue_by_month: Array<subscription_revenue_by_month>;
  /** fetch aggregated fields from the table: "subscription_revenue_by_month" */
  subscription_revenue_by_month_aggregate: subscription_revenue_by_month_aggregate;
  /** fetch data from the table: "subscription_segment" */
  subscription_segment: Array<subscription_segment>;
  /** fetch aggregated fields from the table: "subscription_segment" */
  subscription_segment_aggregate: subscription_segment_aggregate;
  /** fetch data from the table: "subscription_segment" using primary key columns */
  subscription_segment_by_pk?: Maybe<subscription_segment>;
  /** fetch data from the table: "subscription_segment_property" */
  subscription_segment_property: Array<subscription_segment_property>;
  /** fetch aggregated fields from the table: "subscription_segment_property" */
  subscription_segment_property_aggregate: subscription_segment_property_aggregate;
  /** fetch data from the table: "subscription_segment_status" */
  subscription_segment_status: Array<subscription_segment_status>;
  /** fetch aggregated fields from the table: "subscription_segment_status" */
  subscription_segment_status_aggregate: subscription_segment_status_aggregate;
  /** fetch data from the table: "subscription_segment_status" using primary key columns */
  subscription_segment_status_by_pk?: Maybe<subscription_segment_status>;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<subscription_status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: subscription_status_aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<subscription_status>;
  /** fetch data from the table: "survey" */
  survey: Array<survey>;
  /** fetch aggregated fields from the table: "survey" */
  survey_aggregate: survey_aggregate;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<survey>;
  /** fetch data from the table: "tag" */
  tag: Array<tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: tag_aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<tag>;
  /** fetch data from the table: "template_report_deflection_trends" */
  template_report_deflection_trends: Array<template_report_deflection_trends>;
  /** fetch aggregated fields from the table: "template_report_deflection_trends" */
  template_report_deflection_trends_aggregate: template_report_deflection_trends_aggregate;
  /** fetch data from the table: "template_report_deflection_trends" using primary key columns */
  template_report_deflection_trends_by_pk?: Maybe<template_report_deflection_trends>;
  /** fetch data from the table: "template_report_feedback_trends" */
  template_report_feedback_trends: Array<template_report_feedback_trends>;
  /** fetch aggregated fields from the table: "template_report_feedback_trends" */
  template_report_feedback_trends_aggregate: template_report_feedback_trends_aggregate;
  /** fetch data from the table: "template_report_feedback_trends" using primary key columns */
  template_report_feedback_trends_by_pk?: Maybe<template_report_feedback_trends>;
  /** fetch data from the table: "template_report_offer_accept_rate" */
  template_report_offer_accept_rate: Array<template_report_offer_accept_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_accept_rate" */
  template_report_offer_accept_rate_aggregate: template_report_offer_accept_rate_aggregate;
  /** fetch data from the table: "template_report_offer_accept_rate" using primary key columns */
  template_report_offer_accept_rate_by_pk?: Maybe<template_report_offer_accept_rate>;
  /** fetch data from the table: "template_report_offer_group_accept_rate" */
  template_report_offer_group_accept_rate: Array<template_report_offer_group_accept_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_group_accept_rate" */
  template_report_offer_group_accept_rate_aggregate: template_report_offer_group_accept_rate_aggregate;
  /** fetch data from the table: "template_report_offer_group_accept_rate" using primary key columns */
  template_report_offer_group_accept_rate_by_pk?: Maybe<template_report_offer_group_accept_rate>;
  /** fetch data from the table: "template_report_offer_group_retained_revenue" */
  template_report_offer_group_retained_revenue: Array<template_report_offer_group_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_offer_group_retained_revenue" */
  template_report_offer_group_retained_revenue_aggregate: template_report_offer_group_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_offer_group_retained_revenue" using primary key columns */
  template_report_offer_group_retained_revenue_by_pk?: Maybe<template_report_offer_group_retained_revenue>;
  /** fetch data from the table: "template_report_offer_rate" */
  template_report_offer_rate: Array<template_report_offer_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_rate" */
  template_report_offer_rate_aggregate: template_report_offer_rate_aggregate;
  /** fetch data from the table: "template_report_offer_rate" using primary key columns */
  template_report_offer_rate_by_pk?: Maybe<template_report_offer_rate>;
  /** fetch data from the table: "template_report_offer_resolution" */
  template_report_offer_resolution: Array<template_report_offer_resolution>;
  /** fetch aggregated fields from the table: "template_report_offer_resolution" */
  template_report_offer_resolution_aggregate: template_report_offer_resolution_aggregate;
  /** fetch data from the table: "template_report_offer_resolution" using primary key columns */
  template_report_offer_resolution_by_pk?: Maybe<template_report_offer_resolution>;
  /** fetch data from the table: "template_report_offer_retained_revenue" */
  template_report_offer_retained_revenue: Array<template_report_offer_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_offer_retained_revenue" */
  template_report_offer_retained_revenue_aggregate: template_report_offer_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_offer_retained_revenue" using primary key columns */
  template_report_offer_retained_revenue_by_pk?: Maybe<template_report_offer_retained_revenue>;
  /** fetch data from the table: "template_report_question_answer_radio" */
  template_report_question_answer_radio: Array<template_report_question_answer_radio>;
  /** fetch aggregated fields from the table: "template_report_question_answer_radio" */
  template_report_question_answer_radio_aggregate: template_report_question_answer_radio_aggregate;
  /** fetch data from the table: "template_report_question_answer_radio" using primary key columns */
  template_report_question_answer_radio_by_pk?: Maybe<template_report_question_answer_radio>;
  /** fetch data from the table: "template_report_question_answer_radio_inactive_mrr" */
  template_report_question_answer_radio_inactive_mrr: Array<template_report_question_answer_radio_inactive_mrr>;
  /** fetch aggregated fields from the table: "template_report_question_answer_radio_inactive_mrr" */
  template_report_question_answer_radio_inactive_mrr_aggregate: template_report_question_answer_radio_inactive_mrr_aggregate;
  /** fetch data from the table: "template_report_question_answer_radio_inactive_mrr" using primary key columns */
  template_report_question_answer_radio_inactive_mrr_by_pk?: Maybe<template_report_question_answer_radio_inactive_mrr>;
  /** fetch data from the table: "template_report_question_answer_sentiment" */
  template_report_question_answer_sentiment: Array<template_report_question_answer_sentiment>;
  /** fetch aggregated fields from the table: "template_report_question_answer_sentiment" */
  template_report_question_answer_sentiment_aggregate: template_report_question_answer_sentiment_aggregate;
  /** fetch data from the table: "template_report_question_answer_sentiment" using primary key columns */
  template_report_question_answer_sentiment_by_pk?: Maybe<template_report_question_answer_sentiment>;
  /** fetch data from the table: "template_report_retained_revenue" */
  template_report_retained_revenue: Array<template_report_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_retained_revenue" */
  template_report_retained_revenue_aggregate: template_report_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_retained_revenue" using primary key columns */
  template_report_retained_revenue_by_pk?: Maybe<template_report_retained_revenue>;
  /** fetch data from the table: "template_report_retention_trends" */
  template_report_retention_trends: Array<template_report_retention_trends>;
  /** fetch aggregated fields from the table: "template_report_retention_trends" */
  template_report_retention_trends_aggregate: template_report_retention_trends_aggregate;
  /** fetch data from the table: "template_report_retention_trends" using primary key columns */
  template_report_retention_trends_by_pk?: Maybe<template_report_retention_trends>;
  /** fetch data from the table: "template_report_subscriber_flow_status" */
  template_report_subscriber_flow_status: Array<template_report_subscriber_flow_status>;
  /** fetch aggregated fields from the table: "template_report_subscriber_flow_status" */
  template_report_subscriber_flow_status_aggregate: template_report_subscriber_flow_status_aggregate;
  /** fetch data from the table: "template_report_subscriber_flow_status" using primary key columns */
  template_report_subscriber_flow_status_by_pk?: Maybe<template_report_subscriber_flow_status>;
  /** fetch data from the table: "translation" */
  translation: Array<translation>;
  /** fetch aggregated fields from the table: "translation" */
  translation_aggregate: translation_aggregate;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<translation>;
  /** fetch data from the table: "translation_value" */
  translation_value: Array<translation_value>;
  /** fetch aggregated fields from the table: "translation_value" */
  translation_value_aggregate: translation_value_aggregate;
  /** fetch data from the table: "translation_value" using primary key columns */
  translation_value_by_pk?: Maybe<translation_value>;
  /** fetch data from the table: "translation_value_format" */
  translation_value_format: Array<translation_value_format>;
  /** fetch aggregated fields from the table: "translation_value_format" */
  translation_value_format_aggregate: translation_value_format_aggregate;
  /** fetch data from the table: "translation_value_format" using primary key columns */
  translation_value_format_by_pk?: Maybe<translation_value_format>;
  /** fetch data from the table: "user" */
  user: Array<user>;
  userInvitationByToken: UserInvitationByTokenOutput;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: user_aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<user>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<user_invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: user_invitation_aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk?: Maybe<user_invitation>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<user_profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: user_profile_aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<user_profile>;
  viewer?: Maybe<ViewerOutput>;
  /** fetch data from the table: "webhook" */
  webhook: Array<webhook>;
  /** fetch aggregated fields from the table: "webhook" */
  webhook_aggregate: webhook_aggregate;
  /** fetch data from the table: "webhook" using primary key columns */
  webhook_by_pk?: Maybe<webhook>;
  /** fetch data from the table: "webhook_event" */
  webhook_event: Array<webhook_event>;
  /** fetch aggregated fields from the table: "webhook_event" */
  webhook_event_aggregate: webhook_event_aggregate;
  /** fetch data from the table: "webhook_event" using primary key columns */
  webhook_event_by_pk?: Maybe<webhook_event>;
  /** fetch data from the table: "webhook_log" */
  webhook_log: Array<webhook_log>;
  /** fetch aggregated fields from the table: "webhook_log" */
  webhook_log_aggregate: webhook_log_aggregate;
  /** fetch data from the table: "webhook_log" using primary key columns */
  webhook_log_by_pk?: Maybe<webhook_log>;
  /** fetch data from the table: "webhook_request_log" */
  webhook_request_log: Array<webhook_request_log>;
  /** fetch aggregated fields from the table: "webhook_request_log" */
  webhook_request_log_aggregate: webhook_request_log_aggregate;
  /** fetch data from the table: "webhook_request_log" using primary key columns */
  webhook_request_log_by_pk?: Maybe<webhook_request_log>;
  /** fetch data from the table: "webhook_request_log_tsvector" */
  webhook_request_log_tsvector: Array<webhook_request_log_tsvector>;
  /** fetch aggregated fields from the table: "webhook_request_log_tsvector" */
  webhook_request_log_tsvector_aggregate: webhook_request_log_tsvector_aggregate;
  /** fetch data from the table: "webhook_status" */
  webhook_status: Array<webhook_status>;
  /** fetch aggregated fields from the table: "webhook_status" */
  webhook_status_aggregate: webhook_status_aggregate;
  /** fetch data from the table: "webhook_status" using primary key columns */
  webhook_status_by_pk?: Maybe<webhook_status>;
};


export type query_rootaccountArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type query_rootaccountSubscriptionArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootaccount_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type query_rootaccount_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootaccount_featureArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


export type query_rootaccount_feature_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


export type query_rootaccount_feature_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_feature_key_enum;
};


export type query_rootaccount_feature_keyArgs = {
  distinct_on?: InputMaybe<Array<account_feature_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_key_order_by>>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};


export type query_rootaccount_feature_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_key_order_by>>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};


export type query_rootaccount_feature_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootaccount_modeArgs = {
  distinct_on?: InputMaybe<Array<account_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_mode_order_by>>;
  where?: InputMaybe<account_mode_bool_exp>;
};


export type query_rootaccount_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_mode_order_by>>;
  where?: InputMaybe<account_mode_bool_exp>;
};


export type query_rootaccount_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootaccount_revenue_modeArgs = {
  distinct_on?: InputMaybe<Array<account_revenue_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_revenue_mode_order_by>>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};


export type query_rootaccount_revenue_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_revenue_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_revenue_mode_order_by>>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};


export type query_rootaccount_revenue_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootaccount_settingArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


export type query_rootaccount_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


export type query_rootaccount_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_setting_key_enum;
};


export type query_rootaccount_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<account_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_key_order_by>>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};


export type query_rootaccount_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_key_order_by>>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};


export type query_rootaccount_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootaccount_statusArgs = {
  distinct_on?: InputMaybe<Array<account_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_status_order_by>>;
  where?: InputMaybe<account_status_bool_exp>;
};


export type query_rootaccount_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_status_order_by>>;
  where?: InputMaybe<account_status_bool_exp>;
};


export type query_rootaccount_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootaccount_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<account_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_tsvector_order_by>>;
  where?: InputMaybe<account_tsvector_bool_exp>;
};


export type query_rootaccount_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_tsvector_order_by>>;
  where?: InputMaybe<account_tsvector_bool_exp>;
};


export type query_rootaccount_userArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


export type query_rootaccount_user_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


export type query_rootaccount_user_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  user_id: Scalars['String']['input'];
};


export type query_rootaccount_user_settingArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


export type query_rootaccount_user_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


export type query_rootaccount_user_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_user_setting_key_enum;
  user_id: Scalars['String']['input'];
};


export type query_rootaccount_user_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_key_order_by>>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};


export type query_rootaccount_user_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_key_order_by>>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};


export type query_rootaccount_user_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootacknowledgementArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


export type query_rootacknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


export type query_rootacknowledgement_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootacknowledgement_groupArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_order_by>>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};


export type query_rootacknowledgement_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_order_by>>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};


export type query_rootacknowledgement_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootacknowledgement_group_versionArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};


export type query_rootacknowledgement_group_version_acknowledgementArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


export type query_rootacknowledgement_group_version_acknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


export type query_rootacknowledgement_group_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};


export type query_rootacknowledgement_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootacknowledgement_versionArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


export type query_rootacknowledgement_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


export type query_rootacknowledgement_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootactive_portal_sessionArgs = {
  distinct_on?: InputMaybe<Array<active_portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_portal_session_order_by>>;
  where?: InputMaybe<active_portal_session_bool_exp>;
};


export type query_rootactive_portal_session_aggregateArgs = {
  distinct_on?: InputMaybe<Array<active_portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_portal_session_order_by>>;
  where?: InputMaybe<active_portal_session_bool_exp>;
};


export type query_rootactive_saved_mrr_by_monthArgs = {
  distinct_on?: InputMaybe<Array<active_saved_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_saved_mrr_by_month_order_by>>;
  where?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
};


export type query_rootactive_saved_mrr_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<active_saved_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_saved_mrr_by_month_order_by>>;
  where?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
};


export type query_rootannotationArgs = {
  distinct_on?: InputMaybe<Array<annotation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_order_by>>;
  where?: InputMaybe<annotation_bool_exp>;
};


export type query_rootannotation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_order_by>>;
  where?: InputMaybe<annotation_bool_exp>;
};


export type query_rootannotation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootannotation_flow_versionArgs = {
  distinct_on?: InputMaybe<Array<annotation_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_flow_version_order_by>>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};


export type query_rootannotation_flow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_flow_version_order_by>>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};


export type query_rootannotation_flow_version_by_pkArgs = {
  annotation_id: Scalars['Int']['input'];
  flow_version_id: Scalars['Int']['input'];
};


export type query_rootannotation_typeArgs = {
  distinct_on?: InputMaybe<Array<annotation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_type_order_by>>;
  where?: InputMaybe<annotation_type_bool_exp>;
};


export type query_rootannotation_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_type_order_by>>;
  where?: InputMaybe<annotation_type_bool_exp>;
};


export type query_rootannotation_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootapi_keyArgs = {
  distinct_on?: InputMaybe<Array<api_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_order_by>>;
  where?: InputMaybe<api_key_bool_exp>;
};


export type query_rootapi_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_order_by>>;
  where?: InputMaybe<api_key_bool_exp>;
};


export type query_rootapi_key_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootapi_key_purposeArgs = {
  distinct_on?: InputMaybe<Array<api_key_purpose_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_purpose_order_by>>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};


export type query_rootapi_key_purpose_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_key_purpose_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_purpose_order_by>>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};


export type query_rootapi_key_purpose_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootavailableNavigaDomainsArgs = {
  input: AvailableNavigaDomainsInput;
};


export type query_rootcampaignArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


export type query_rootcampaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


export type query_rootcampaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcampaign_textArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


export type query_rootcampaign_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


export type query_rootcampaign_text_by_pkArgs = {
  campaign_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


export type query_rootcampaign_text_versionArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_version_order_by>>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};


export type query_rootcampaign_text_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_version_order_by>>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};


export type query_rootcampaign_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcampaign_trigger_typeArgs = {
  distinct_on?: InputMaybe<Array<campaign_trigger_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_trigger_type_order_by>>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};


export type query_rootcampaign_trigger_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_trigger_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_trigger_type_order_by>>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};


export type query_rootcampaign_trigger_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootcampaign_versionArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


export type query_rootcampaign_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


export type query_rootcampaign_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcancellations_by_monthArgs = {
  distinct_on?: InputMaybe<Array<cancellations_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<cancellations_by_month_order_by>>;
  where?: InputMaybe<cancellations_by_month_bool_exp>;
};


export type query_rootcancellations_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<cancellations_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<cancellations_by_month_order_by>>;
  where?: InputMaybe<cancellations_by_month_bool_exp>;
};


export type query_rootconfirmationArgs = {
  distinct_on?: InputMaybe<Array<confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_order_by>>;
  where?: InputMaybe<confirmation_bool_exp>;
};


export type query_rootconfirmation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_order_by>>;
  where?: InputMaybe<confirmation_bool_exp>;
};


export type query_rootconfirmation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootconfirmation_versionArgs = {
  distinct_on?: InputMaybe<Array<confirmation_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_version_order_by>>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};


export type query_rootconfirmation_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<confirmation_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_version_order_by>>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};


export type query_rootconfirmation_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcountryArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};


export type query_rootcountry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};


export type query_rootcountry_by_pkArgs = {
  iso: Scalars['citext']['input'];
};


export type query_rootcoupon_durationArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_order_by>>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};


export type query_rootcoupon_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_order_by>>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};


export type query_rootcoupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootcoupon_duration_intervalArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_interval_order_by>>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};


export type query_rootcoupon_duration_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_interval_order_by>>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};


export type query_rootcoupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootcsv_delta_fileArgs = {
  distinct_on?: InputMaybe<Array<csv_delta_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_delta_file_order_by>>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};


export type query_rootcsv_delta_file_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_delta_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_delta_file_order_by>>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};


export type query_rootcsv_delta_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcsv_importArgs = {
  distinct_on?: InputMaybe<Array<csv_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_order_by>>;
  where?: InputMaybe<csv_import_bool_exp>;
};


export type query_rootcsv_import_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_order_by>>;
  where?: InputMaybe<csv_import_bool_exp>;
};


export type query_rootcsv_import_by_pkArgs = {
  delta_file_id: Scalars['Int']['input'];
};


export type query_rootcsv_import_fileArgs = {
  distinct_on?: InputMaybe<Array<csv_import_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_file_order_by>>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};


export type query_rootcsv_import_file_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_file_order_by>>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};


export type query_rootcsv_import_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootcsv_import_statusArgs = {
  distinct_on?: InputMaybe<Array<csv_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_status_order_by>>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};


export type query_rootcsv_import_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_status_order_by>>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};


export type query_rootcsv_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootcustomers_by_monthArgs = {
  distinct_on?: InputMaybe<Array<customers_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<customers_by_month_order_by>>;
  where?: InputMaybe<customers_by_month_bool_exp>;
};


export type query_rootcustomers_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<customers_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<customers_by_month_order_by>>;
  where?: InputMaybe<customers_by_month_bool_exp>;
};


export type query_rootdata_connectorArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


export type query_rootdata_connector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


export type query_rootdata_connector_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootdata_connector_request_logArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type query_rootdata_connector_request_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type query_rootdata_connector_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type query_rootdata_connector_request_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_tsvector_order_by>>;
  where?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
};


export type query_rootdata_connector_request_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_tsvector_order_by>>;
  where?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
};


export type query_rootdeflectionArgs = {
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type query_rootdeflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type query_rootdeflection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootdeflection_flow_actionArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


export type query_rootdeflection_flow_action_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


export type query_rootdeflection_snapshot_itemArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};


export type query_rootdeflection_snapshot_item_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};


export type query_rootdeflection_snapshot_item_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootdeflection_snapshot_item_positionArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


export type query_rootdeflection_snapshot_item_position_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


export type query_rootdeflection_tagArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


export type query_rootdeflection_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


export type query_rootdeflection_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<deflection_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tsvector_order_by>>;
  where?: InputMaybe<deflection_tsvector_bool_exp>;
};


export type query_rootdeflection_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tsvector_order_by>>;
  where?: InputMaybe<deflection_tsvector_bool_exp>;
};


export type query_rootdeflection_typeArgs = {
  distinct_on?: InputMaybe<Array<deflection_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_type_order_by>>;
  where?: InputMaybe<deflection_type_bool_exp>;
};


export type query_rootdeflection_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_type_order_by>>;
  where?: InputMaybe<deflection_type_bool_exp>;
};


export type query_rootdeflection_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootdemoArgs = {
  distinct_on?: InputMaybe<Array<demo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<demo_order_by>>;
  where?: InputMaybe<demo_bool_exp>;
};


export type query_rootdemo_aggregateArgs = {
  distinct_on?: InputMaybe<Array<demo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<demo_order_by>>;
  where?: InputMaybe<demo_bool_exp>;
};


export type query_rootdemo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootdomainArgs = {
  distinct_on?: InputMaybe<Array<domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_order_by>>;
  where?: InputMaybe<domain_bool_exp>;
};


export type query_rootdomainRouteArgs = {
  input: DomainRouteInput;
};


export type query_rootdomain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_order_by>>;
  where?: InputMaybe<domain_bool_exp>;
};


export type query_rootdomain_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootdomain_typeArgs = {
  distinct_on?: InputMaybe<Array<domain_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_type_order_by>>;
  where?: InputMaybe<domain_type_bool_exp>;
};


export type query_rootdomain_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<domain_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_type_order_by>>;
  where?: InputMaybe<domain_type_bool_exp>;
};


export type query_rootdomain_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rooteligibility_messageArgs = {
  distinct_on?: InputMaybe<Array<eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<eligibility_message_order_by>>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};


export type query_rooteligibility_message_aggregateArgs = {
  distinct_on?: InputMaybe<Array<eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<eligibility_message_order_by>>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};


export type query_rooteligibility_message_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rooteventArgs = {
  distinct_on?: InputMaybe<Array<event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_order_by>>;
  where?: InputMaybe<event_bool_exp>;
};


export type query_rootevent_aggregateArgs = {
  distinct_on?: InputMaybe<Array<event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_order_by>>;
  where?: InputMaybe<event_bool_exp>;
};


export type query_rootevent_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootevent_typeArgs = {
  distinct_on?: InputMaybe<Array<event_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_type_order_by>>;
  where?: InputMaybe<event_type_bool_exp>;
};


export type query_rootevent_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<event_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_type_order_by>>;
  where?: InputMaybe<event_type_bool_exp>;
};


export type query_rootevent_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootfeedback_trends_gridArgs = {
  distinct_on?: InputMaybe<Array<feedback_trends_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<feedback_trends_grid_order_by>>;
  where?: InputMaybe<feedback_trends_grid_bool_exp>;
};


export type query_rootfeedback_trends_grid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<feedback_trends_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<feedback_trends_grid_order_by>>;
  where?: InputMaybe<feedback_trends_grid_bool_exp>;
};


export type query_rootflowArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


export type query_rootflowOfferGroupArgs = {
  input: FlowOfferGroupInput;
};


export type query_rootflowTestOfferArgs = {
  input: FlowTestOfferInput;
};


export type query_rootflow_actionArgs = {
  distinct_on?: InputMaybe<Array<flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_order_by>>;
  where?: InputMaybe<flow_action_bool_exp>;
};


export type query_rootflow_action_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_order_by>>;
  where?: InputMaybe<flow_action_bool_exp>;
};


export type query_rootflow_action_appearanceArgs = {
  distinct_on?: InputMaybe<Array<flow_action_appearance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_appearance_order_by>>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};


export type query_rootflow_action_appearance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_appearance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_appearance_order_by>>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};


export type query_rootflow_action_appearance_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_action_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_action_typeArgs = {
  distinct_on?: InputMaybe<Array<flow_action_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_type_order_by>>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};


export type query_rootflow_action_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_type_order_by>>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};


export type query_rootflow_action_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


export type query_rootflow_auto_cancel_atArgs = {
  distinct_on?: InputMaybe<Array<flow_auto_cancel_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_auto_cancel_at_order_by>>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};


export type query_rootflow_auto_cancel_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_auto_cancel_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_auto_cancel_at_order_by>>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};


export type query_rootflow_auto_cancel_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_languageArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


export type query_rootflow_language_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


export type query_rootflow_language_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  language: language_enum;
};


export type query_rootflow_on_cancelArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_order_by>>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};


export type query_rootflow_on_cancel_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_order_by>>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};


export type query_rootflow_on_cancel_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_on_cancel_change_planArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


export type query_rootflow_on_cancel_change_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


export type query_rootflow_on_cancel_change_plan_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type query_rootflow_on_cancel_perform_atArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_perform_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_perform_at_order_by>>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};


export type query_rootflow_on_cancel_perform_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_perform_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_perform_at_order_by>>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};


export type query_rootflow_on_cancel_perform_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_route_ruleArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


export type query_rootflow_route_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


export type query_rootflow_route_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_route_rule_eligibility_messageArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


export type query_rootflow_route_rule_eligibility_message_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


export type query_rootflow_route_rule_flowArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


export type query_rootflow_route_rule_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


export type query_rootflow_route_rule_flow_testArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


export type query_rootflow_route_rule_flow_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


export type query_rootflow_route_rule_segmentArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


export type query_rootflow_route_rule_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


export type query_rootflow_route_rule_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


export type query_rootflow_route_rule_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


export type query_rootflow_stepArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


export type query_rootflow_step_acknowledgement_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


export type query_rootflow_step_acknowledgement_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


export type query_rootflow_step_acknowledgement_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


export type query_rootflow_step_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_step_confirmationArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


export type query_rootflow_step_confirmation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


export type query_rootflow_step_confirmation_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_deflection_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


export type query_rootflow_step_deflection_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


export type query_rootflow_step_formArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


export type query_rootflow_step_form_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


export type query_rootflow_step_form_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_interventionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


export type query_rootflow_step_intervention_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


export type query_rootflow_step_intervention_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_offer_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


export type query_rootflow_step_offer_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


export type query_rootflow_step_questionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


export type query_rootflow_step_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


export type query_rootflow_step_question_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


export type query_rootflow_step_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


export type query_rootflow_step_rule_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type query_rootflow_step_typeArgs = {
  distinct_on?: InputMaybe<Array<flow_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_type_order_by>>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};


export type query_rootflow_step_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_type_order_by>>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};


export type query_rootflow_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_step_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_order_by>>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};


export type query_rootflow_step_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_order_by>>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};


export type query_rootflow_step_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_step_version_condition_question_optionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


export type query_rootflow_step_version_condition_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


export type query_rootflow_step_version_condition_segmentArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


export type query_rootflow_step_version_condition_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


export type query_rootflow_step_version_condition_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


export type query_rootflow_step_version_condition_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


export type query_rootflow_subscriber_form_questionArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


export type query_rootflow_subscriber_form_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


export type query_rootflow_subscriber_form_question_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_tagArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


export type query_rootflow_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


export type query_rootflow_testArgs = {
  distinct_on?: InputMaybe<Array<flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_order_by>>;
  where?: InputMaybe<flow_test_bool_exp>;
};


export type query_rootflow_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_order_by>>;
  where?: InputMaybe<flow_test_bool_exp>;
};


export type query_rootflow_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_test_flowArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


export type query_rootflow_test_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


export type query_rootflow_test_flow_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  flow_test_id: Scalars['Int']['input'];
};


export type query_rootflow_textArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


export type query_rootflow_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


export type query_rootflow_text_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


export type query_rootflow_text_keyArgs = {
  distinct_on?: InputMaybe<Array<flow_text_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_key_order_by>>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};


export type query_rootflow_text_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_key_order_by>>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};


export type query_rootflow_text_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootflow_text_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


export type query_rootflow_text_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


export type query_rootflow_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_order_by>>;
  where?: InputMaybe<flow_version_bool_exp>;
};


export type query_rootflow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_order_by>>;
  where?: InputMaybe<flow_version_bool_exp>;
};


export type query_rootflow_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootflow_version_flow_stepArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


export type query_rootflow_version_flow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


export type query_rootformArgs = {
  distinct_on?: InputMaybe<Array<form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_order_by>>;
  where?: InputMaybe<form_bool_exp>;
};


export type query_rootform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_order_by>>;
  where?: InputMaybe<form_bool_exp>;
};


export type query_rootform_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootform_versionArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};


export type query_rootform_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};


export type query_rootform_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootform_version_questionArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


export type query_rootform_version_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


export type query_rootfunction_logArgs = {
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


export type query_rootfunction_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


export type query_rootfunction_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_roothubSpotPropertiesArgs = {
  input: HubSpotPropertiesInput;
};


export type query_rootintegrationArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


export type query_rootintegration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


export type query_rootintegration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootintegration_typeArgs = {
  distinct_on?: InputMaybe<Array<integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_type_order_by>>;
  where?: InputMaybe<integration_type_bool_exp>;
};


export type query_rootintegration_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_type_order_by>>;
  where?: InputMaybe<integration_type_bool_exp>;
};


export type query_rootintegration_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootintercomDataAttributesArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootinterventionArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};


export type query_rootintervention_aggregateArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};


export type query_rootintervention_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootintervention_versionArgs = {
  distinct_on?: InputMaybe<Array<intervention_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_version_order_by>>;
  where?: InputMaybe<intervention_version_bool_exp>;
};


export type query_rootintervention_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<intervention_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_version_order_by>>;
  where?: InputMaybe<intervention_version_bool_exp>;
};


export type query_rootintervention_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootjobArgs = {
  distinct_on?: InputMaybe<Array<job_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<job_order_by>>;
  where?: InputMaybe<job_bool_exp>;
};


export type query_rootjob_aggregateArgs = {
  distinct_on?: InputMaybe<Array<job_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<job_order_by>>;
  where?: InputMaybe<job_bool_exp>;
};


export type query_rootjob_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type query_rootlanguageArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};


export type query_rootlanguage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};


export type query_rootlanguage_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootlost_mrr_by_monthArgs = {
  distinct_on?: InputMaybe<Array<lost_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<lost_mrr_by_month_order_by>>;
  where?: InputMaybe<lost_mrr_by_month_bool_exp>;
};


export type query_rootlost_mrr_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<lost_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<lost_mrr_by_month_order_by>>;
  where?: InputMaybe<lost_mrr_by_month_bool_exp>;
};


export type query_rootmost_recent_subscriber_flow_versionArgs = {
  distinct_on?: InputMaybe<Array<most_recent_subscriber_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<most_recent_subscriber_flow_version_order_by>>;
  where?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
};


export type query_rootmost_recent_subscriber_flow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<most_recent_subscriber_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<most_recent_subscriber_flow_version_order_by>>;
  where?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
};


export type query_rootnaviga_cancel_linkArgs = {
  distinct_on?: InputMaybe<Array<naviga_cancel_link_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_cancel_link_order_by>>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};


export type query_rootnaviga_cancel_link_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_cancel_link_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_cancel_link_order_by>>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};


export type query_rootnaviga_cancel_link_by_pkArgs = {
  client_code: Scalars['String']['input'];
  link_text: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
};


export type query_rootnaviga_domainArgs = {
  distinct_on?: InputMaybe<Array<naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_domain_order_by>>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};


export type query_rootnaviga_domain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_domain_order_by>>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};


export type query_rootnaviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
};


export type query_rootnaviga_paper_codeArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


export type query_rootnaviga_paper_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


export type query_rootnaviga_paper_code_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
};


export type query_rootneedStripeReconnectArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootofferArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type query_rootoffer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type query_rootoffer_autopilotArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_order_by>>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};


export type query_rootoffer_autopilot_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_order_by>>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};


export type query_rootoffer_autopilot_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_autopilot_offer_autopilot_roundArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


export type query_rootoffer_autopilot_offer_autopilot_round_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


export type query_rootoffer_autopilot_offer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


export type query_rootoffer_autopilot_offer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


export type query_rootoffer_autopilot_roundArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};


export type query_rootoffer_autopilot_round_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};


export type query_rootoffer_autopilot_round_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_autopilot_round_logArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


export type query_rootoffer_autopilot_round_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


export type query_rootoffer_autopilot_round_log_by_pkArgs = {
  offer_autopilot_round_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootoffer_autopilot_round_offer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


export type query_rootoffer_autopilot_round_offer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


export type query_rootoffer_autopilot_round_strategyArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};


export type query_rootoffer_autopilot_round_strategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};


export type query_rootoffer_autopilot_round_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_autopilot_strategyArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};


export type query_rootoffer_autopilot_strategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};


export type query_rootoffer_autopilot_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_autopilot_success_metricArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_success_metric_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_success_metric_order_by>>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};


export type query_rootoffer_autopilot_success_metric_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_success_metric_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_success_metric_order_by>>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};


export type query_rootoffer_autopilot_success_metric_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_change_planArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_order_by>>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};


export type query_rootoffer_change_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_order_by>>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};


export type query_rootoffer_change_plan_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_change_plan_change_atArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_change_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_change_at_order_by>>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};


export type query_rootoffer_change_plan_change_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_change_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_change_at_order_by>>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};


export type query_rootoffer_change_plan_change_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_couponArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


export type query_rootoffer_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


export type query_rootoffer_coupon_apply_toArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_apply_to_order_by>>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};


export type query_rootoffer_coupon_apply_to_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_apply_to_order_by>>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};


export type query_rootoffer_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_coupon_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_type_order_by>>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};


export type query_rootoffer_coupon_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_type_order_by>>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};


export type query_rootoffer_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_customArgs = {
  distinct_on?: InputMaybe<Array<offer_custom_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_custom_order_by>>;
  where?: InputMaybe<offer_custom_bool_exp>;
};


export type query_rootoffer_custom_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_custom_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_custom_order_by>>;
  where?: InputMaybe<offer_custom_bool_exp>;
};


export type query_rootoffer_custom_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_downgradeArgs = {
  distinct_on?: InputMaybe<Array<offer_downgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_downgrade_order_by>>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};


export type query_rootoffer_downgrade_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_downgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_downgrade_order_by>>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};


export type query_rootoffer_downgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_goalArgs = {
  distinct_on?: InputMaybe<Array<offer_goal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_goal_order_by>>;
  where?: InputMaybe<offer_goal_bool_exp>;
};


export type query_rootoffer_goal_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_goal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_goal_order_by>>;
  where?: InputMaybe<offer_goal_bool_exp>;
};


export type query_rootoffer_goal_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_order_by>>;
  where?: InputMaybe<offer_group_bool_exp>;
};


export type query_rootoffer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_order_by>>;
  where?: InputMaybe<offer_group_bool_exp>;
};


export type query_rootoffer_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_group_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


export type query_rootoffer_group_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


export type query_rootoffer_modify_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_order_by>>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};


export type query_rootoffer_modify_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_order_by>>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};


export type query_rootoffer_modify_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_modify_subscription_modify_atArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_modify_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_modify_at_order_by>>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};


export type query_rootoffer_modify_subscription_modify_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_modify_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_modify_at_order_by>>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};


export type query_rootoffer_modify_subscription_modify_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_modify_subscription_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


export type query_rootoffer_modify_subscription_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


export type query_rootoffer_modify_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_modify_subscription_option_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};


export type query_rootoffer_modify_subscription_option_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};


export type query_rootoffer_modify_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_pause_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_order_by>>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};


export type query_rootoffer_pause_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_order_by>>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};


export type query_rootoffer_pause_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_pause_subscription_intervalArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_interval_order_by>>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};


export type query_rootoffer_pause_subscription_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_interval_order_by>>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};


export type query_rootoffer_pause_subscription_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_pause_subscription_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


export type query_rootoffer_pause_subscription_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


export type query_rootoffer_pause_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_pause_subscription_option_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};


export type query_rootoffer_pause_subscription_option_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};


export type query_rootoffer_pause_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_pause_subscription_pause_atArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_pause_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_pause_at_order_by>>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};


export type query_rootoffer_pause_subscription_pause_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_pause_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_pause_at_order_by>>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};


export type query_rootoffer_pause_subscription_pause_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_product_swapArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_order_by>>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};


export type query_rootoffer_product_swap_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_order_by>>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};


export type query_rootoffer_product_swap_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_product_swap_platform_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


export type query_rootoffer_product_swap_platform_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


export type query_rootoffer_product_swap_platform_ecommerce_product_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_product_swap_platform_ecommerce_product_platform_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


export type query_rootoffer_product_swap_platform_ecommerce_product_platform_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


export type query_rootoffer_product_swap_platform_ecommerce_product_platform_variant_by_pkArgs = {
  offer_product_swap_platform_ecommerce_product_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
};


export type query_rootoffer_reschedule_orderArgs = {
  distinct_on?: InputMaybe<Array<offer_reschedule_order_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_reschedule_order_order_by>>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};


export type query_rootoffer_reschedule_order_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_reschedule_order_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_reschedule_order_order_by>>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};


export type query_rootoffer_reschedule_order_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


export type query_rootoffer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


export type query_rootoffer_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


export type query_rootoffer_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


export type query_rootoffer_rule_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_rule_group_versionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_order_by>>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};


export type query_rootoffer_rule_group_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_order_by>>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};


export type query_rootoffer_rule_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_rule_group_version_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


export type query_rootoffer_rule_group_version_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


export type query_rootoffer_rule_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};


export type query_rootoffer_rule_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};


export type query_rootoffer_rule_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_rule_rule_deflectionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


export type query_rootoffer_rule_rule_deflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


export type query_rootoffer_rule_rule_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_autopilot_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_autopilot_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


export type query_rootoffer_rule_rule_offer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


export type query_rootoffer_rule_versionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_order_by>>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};


export type query_rootoffer_rule_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_order_by>>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};


export type query_rootoffer_rule_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_rule_version_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


export type query_rootoffer_rule_version_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


export type query_rootoffer_rule_version_offer_autopilot_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


export type query_rootoffer_rule_version_offer_autopilot_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


export type query_rootoffer_rule_version_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


export type query_rootoffer_rule_version_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


export type query_rootoffer_rule_version_offer_rule_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


export type query_rootoffer_rule_version_offer_rule_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


export type query_rootoffer_rule_version_offer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


export type query_rootoffer_rule_version_offer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


export type query_rootoffer_rule_version_question_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


export type query_rootoffer_rule_version_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


export type query_rootoffer_rule_version_segmentArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


export type query_rootoffer_rule_version_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


export type query_rootoffer_rule_version_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


export type query_rootoffer_rule_version_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


export type query_rootoffer_styleArgs = {
  distinct_on?: InputMaybe<Array<offer_style_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_style_order_by>>;
  where?: InputMaybe<offer_style_bool_exp>;
};


export type query_rootoffer_style_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_style_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_style_order_by>>;
  where?: InputMaybe<offer_style_bool_exp>;
};


export type query_rootoffer_style_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_tagArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


export type query_rootoffer_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


export type query_rootoffer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


export type query_rootoffer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


export type query_rootoffer_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_test_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


export type query_rootoffer_test_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


export type query_rootoffer_test_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  offer_test_id: Scalars['Int']['input'];
};


export type query_rootoffer_timerArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_order_by>>;
  where?: InputMaybe<offer_timer_bool_exp>;
};


export type query_rootoffer_timer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_order_by>>;
  where?: InputMaybe<offer_timer_bool_exp>;
};


export type query_rootoffer_timer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_timer_modeArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_mode_order_by>>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};


export type query_rootoffer_timer_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_mode_order_by>>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};


export type query_rootoffer_timer_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_trial_extensionArgs = {
  distinct_on?: InputMaybe<Array<offer_trial_extension_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_trial_extension_order_by>>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};


export type query_rootoffer_trial_extension_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_trial_extension_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_trial_extension_order_by>>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};


export type query_rootoffer_trial_extension_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<offer_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tsvector_order_by>>;
  where?: InputMaybe<offer_tsvector_bool_exp>;
};


export type query_rootoffer_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tsvector_order_by>>;
  where?: InputMaybe<offer_tsvector_bool_exp>;
};


export type query_rootoffer_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_type_order_by>>;
  where?: InputMaybe<offer_type_bool_exp>;
};


export type query_rootoffer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_type_order_by>>;
  where?: InputMaybe<offer_type_bool_exp>;
};


export type query_rootoffer_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootoffer_upgradeArgs = {
  distinct_on?: InputMaybe<Array<offer_upgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_upgrade_order_by>>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};


export type query_rootoffer_upgrade_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_upgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_upgrade_order_by>>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};


export type query_rootoffer_upgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type query_rootoffer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_order_by>>;
  where?: InputMaybe<offer_variant_bool_exp>;
};


export type query_rootoffer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_order_by>>;
  where?: InputMaybe<offer_variant_bool_exp>;
};


export type query_rootoffer_variant_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootoffer_variant_couponArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


export type query_rootoffer_variant_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


export type query_rootoffer_variant_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootpaymentMethodArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootplatformArgs = {
  distinct_on?: InputMaybe<Array<platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_order_by>>;
  where?: InputMaybe<platform_bool_exp>;
};


export type query_rootplatformCustomerSubscriptionsArgs = {
  input: PlatformCustomerSubscriptionsInput;
};


export type query_rootplatform_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


export type query_rootplatform_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


export type query_rootplatform_addon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_addon_planArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


export type query_rootplatform_addon_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


export type query_rootplatform_addon_plan_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type query_rootplatform_addon_priceArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


export type query_rootplatform_addon_price_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


export type query_rootplatform_addon_price_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_addon_price_tierArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


export type query_rootplatform_addon_price_tier_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


export type query_rootplatform_addon_price_tier_by_pkArgs = {
  platform_addon_price_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  up_to: Scalars['Int']['input'];
};


export type query_rootplatform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_order_by>>;
  where?: InputMaybe<platform_bool_exp>;
};


export type query_rootplatform_api_logArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type query_rootplatform_api_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type query_rootplatform_api_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type query_rootplatform_api_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_tsvector_order_by>>;
  where?: InputMaybe<platform_api_log_tsvector_bool_exp>;
};


export type query_rootplatform_api_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_tsvector_order_by>>;
  where?: InputMaybe<platform_api_log_tsvector_bool_exp>;
};


export type query_rootplatform_base_currencyArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


export type query_rootplatform_base_currency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


export type query_rootplatform_base_currency_by_pkArgs = {
  value: platform_currency_enum;
};


export type query_rootplatform_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_cancel_reasonArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


export type query_rootplatform_cancel_reason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


export type query_rootplatform_cancel_reason_by_pkArgs = {
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_connectionArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


export type query_rootplatform_connection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


export type query_rootplatform_connection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootplatform_connection_naviga_domainArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_domain_order_by>>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};


export type query_rootplatform_connection_naviga_domain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_domain_order_by>>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};


export type query_rootplatform_connection_naviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type query_rootplatform_connection_naviga_paper_code_endpointArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_order_by>>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};


export type query_rootplatform_connection_naviga_paper_code_endpoint_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_order_by>>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};


export type query_rootplatform_connection_naviga_paper_code_endpoint_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type query_rootplatform_connection_shopify_storeArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_shopify_store_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_shopify_store_order_by>>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};


export type query_rootplatform_connection_shopify_store_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_shopify_store_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_shopify_store_order_by>>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};


export type query_rootplatform_connection_shopify_store_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
};


export type query_rootplatform_couponArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type query_rootplatform_coupon_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


export type query_rootplatform_coupon_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


export type query_rootplatform_coupon_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
};


export type query_rootplatform_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type query_rootplatform_coupon_apply_toArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};


export type query_rootplatform_coupon_apply_to_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};


export type query_rootplatform_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_coupon_apply_to_restrictionArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_restriction_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_restriction_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};


export type query_rootplatform_coupon_apply_to_restriction_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_restriction_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_restriction_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};


export type query_rootplatform_coupon_apply_to_restriction_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_coupon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_coupon_durationArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_order_by>>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};


export type query_rootplatform_coupon_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_order_by>>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};


export type query_rootplatform_coupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_coupon_duration_intervalArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_interval_order_by>>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};


export type query_rootplatform_coupon_duration_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_interval_order_by>>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};


export type query_rootplatform_coupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_coupon_planArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


export type query_rootplatform_coupon_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


export type query_rootplatform_coupon_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type query_rootplatform_coupon_productArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


export type query_rootplatform_coupon_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


export type query_rootplatform_coupon_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_id: Scalars['String']['input'];
};


export type query_rootplatform_coupon_product_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


export type query_rootplatform_coupon_product_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


export type query_rootplatform_coupon_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_group_id: Scalars['String']['input'];
};


export type query_rootplatform_coupon_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_tsvector_order_by>>;
  where?: InputMaybe<platform_coupon_tsvector_bool_exp>;
};


export type query_rootplatform_coupon_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_tsvector_order_by>>;
  where?: InputMaybe<platform_coupon_tsvector_bool_exp>;
};


export type query_rootplatform_coupon_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_type_order_by>>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};


export type query_rootplatform_coupon_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_type_order_by>>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};


export type query_rootplatform_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_credentialsArgs = {
  distinct_on?: InputMaybe<Array<platform_credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_credentials_order_by>>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};


export type query_rootplatform_credentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_credentials_order_by>>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};


export type query_rootplatform_credentials_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  platform: platform_enum;
};


export type query_rootplatform_currencyArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_order_by>>;
  where?: InputMaybe<platform_currency_bool_exp>;
};


export type query_rootplatform_currency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_order_by>>;
  where?: InputMaybe<platform_currency_bool_exp>;
};


export type query_rootplatform_currency_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_currency_exchange_rateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


export type query_rootplatform_currency_exchange_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


export type query_rootplatform_currency_exchange_rate_by_pkArgs = {
  base: platform_base_currency_enum;
  currency: platform_currency_enum;
};


export type query_rootplatform_customerArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


export type query_rootplatform_customer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


export type query_rootplatform_customer_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


export type query_rootplatform_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


export type query_rootplatform_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_importArgs = {
  distinct_on?: InputMaybe<Array<platform_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_order_by>>;
  where?: InputMaybe<platform_import_bool_exp>;
};


export type query_rootplatform_import_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_order_by>>;
  where?: InputMaybe<platform_import_bool_exp>;
};


export type query_rootplatform_import_by_pkArgs = {
  job_id: Scalars['String']['input'];
};


export type query_rootplatform_import_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_status_order_by>>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};


export type query_rootplatform_import_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_status_order_by>>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};


export type query_rootplatform_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_import_stepArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


export type query_rootplatform_import_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


export type query_rootplatform_import_step_by_pkArgs = {
  job_id: Scalars['String']['input'];
  type: platform_import_step_type_enum;
};


export type query_rootplatform_import_step_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_type_order_by>>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};


export type query_rootplatform_import_step_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_type_order_by>>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};


export type query_rootplatform_import_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_invoiceArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


export type query_rootplatform_invoice_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


export type query_rootplatform_invoice_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_invoice_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_status_order_by>>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};


export type query_rootplatform_invoice_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_status_order_by>>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};


export type query_rootplatform_invoice_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_newspaper_rate_codeArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_rate_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_rate_code_order_by>>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};


export type query_rootplatform_newspaper_rate_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_rate_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_rate_code_order_by>>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};


export type query_rootplatform_newspaper_rate_code_by_pkArgs = {
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  rate_code: Scalars['String']['input'];
};


export type query_rootplatform_newspaper_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


export type query_rootplatform_newspaper_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


export type query_rootplatform_newspaper_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_newspaper_subscription_stop_dateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_stop_date_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_stop_date_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
};


export type query_rootplatform_newspaper_subscription_stop_date_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_stop_date_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_stop_date_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
};


export type query_rootplatform_planArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


export type query_rootplatform_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


export type query_rootplatform_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_plan_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


export type query_rootplatform_plan_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


export type query_rootplatform_plan_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type query_rootplatform_plan_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_group_order_by>>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};


export type query_rootplatform_plan_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_group_order_by>>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};


export type query_rootplatform_plan_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_plan_intervalArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_interval_order_by>>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};


export type query_rootplatform_plan_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_interval_order_by>>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};


export type query_rootplatform_plan_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_plan_pricing_modelArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_pricing_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_pricing_model_order_by>>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};


export type query_rootplatform_plan_pricing_model_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_pricing_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_pricing_model_order_by>>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};


export type query_rootplatform_plan_pricing_model_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_plan_tierArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


export type query_rootplatform_plan_tier_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


export type query_rootplatform_plan_tier_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  up_to: Scalars['Int']['input'];
};


export type query_rootplatform_productArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


export type query_rootplatform_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


export type query_rootplatform_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_product_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


export type query_rootplatform_product_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


export type query_rootplatform_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_raw_objectArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_order_by>>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};


export type query_rootplatform_raw_object_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_order_by>>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};


export type query_rootplatform_raw_object_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
  type: platform_raw_object_type_enum;
};


export type query_rootplatform_raw_object_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_type_order_by>>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};


export type query_rootplatform_raw_object_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_type_order_by>>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};


export type query_rootplatform_raw_object_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_refundArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


export type query_rootplatform_refund_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


export type query_rootplatform_refund_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


export type query_rootplatform_subscription_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


export type query_rootplatform_subscription_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


export type query_rootplatform_subscription_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type query_rootplatform_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


export type query_rootplatform_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_subscription_next_billing_atArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_next_billing_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_next_billing_at_order_by>>;
  where?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
};


export type query_rootplatform_subscription_next_billing_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_next_billing_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_next_billing_at_order_by>>;
  where?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
};


export type query_rootplatform_subscription_planArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


export type query_rootplatform_subscription_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


export type query_rootplatform_subscription_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type query_rootplatform_subscription_plan_swappable_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


export type query_rootplatform_subscription_plan_swappable_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


export type query_rootplatform_subscription_plan_swappable_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type query_rootplatform_subscription_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_status_order_by>>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};


export type query_rootplatform_subscription_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_status_order_by>>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};


export type query_rootplatform_subscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootplatform_variantArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


export type query_rootplatform_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


export type query_rootplatform_variant_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type query_rootplatform_variant_optionArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


export type query_rootplatform_variant_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


export type query_rootplatform_variant_option_by_pkArgs = {
  key: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type query_rootportalCustomerBillingDetailsArgs = {
  input: PortalCustomerBillingDetailsInput;
};


export type query_rootportalSessionPaymentInfoArgs = {
  input: PortalSessionPaymentInfoInput;
};


export type query_rootportalSubscriptionDetailsArgs = {
  input: PortalSubscriptionDetailsInput;
};


export type query_rootportal_email_codeArgs = {
  distinct_on?: InputMaybe<Array<portal_email_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_email_code_order_by>>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};


export type query_rootportal_email_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_email_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_email_code_order_by>>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};


export type query_rootportal_email_code_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootportal_sessionArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


export type query_rootportal_session_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


export type query_rootportal_session_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootportal_session_subscriberArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


export type query_rootportal_session_subscriber_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


export type query_rootportal_session_subscriber_by_pkArgs = {
  portal_session_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type query_rootpricingPlanArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootproductArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


export type query_rootproduct_aggregateArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


export type query_rootproduct_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootpropertyArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type query_rootpropertySegmentMatchesArgs = {
  input: PropertySegmentMatchesInput;
};


export type query_rootproperty_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type query_rootproperty_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootproperty_entityArgs = {
  distinct_on?: InputMaybe<Array<property_entity_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_entity_order_by>>;
  where?: InputMaybe<property_entity_bool_exp>;
};


export type query_rootproperty_entity_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_entity_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_entity_order_by>>;
  where?: InputMaybe<property_entity_bool_exp>;
};


export type query_rootproperty_entity_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootproperty_formatArgs = {
  distinct_on?: InputMaybe<Array<property_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_format_order_by>>;
  where?: InputMaybe<property_format_bool_exp>;
};


export type query_rootproperty_format_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_format_order_by>>;
  where?: InputMaybe<property_format_bool_exp>;
};


export type query_rootproperty_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootproperty_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<property_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_tsvector_order_by>>;
  where?: InputMaybe<property_tsvector_bool_exp>;
};


export type query_rootproperty_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_tsvector_order_by>>;
  where?: InputMaybe<property_tsvector_bool_exp>;
};


export type query_rootproperty_typeArgs = {
  distinct_on?: InputMaybe<Array<property_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_type_order_by>>;
  where?: InputMaybe<property_type_bool_exp>;
};


export type query_rootproperty_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_type_order_by>>;
  where?: InputMaybe<property_type_bool_exp>;
};


export type query_rootproperty_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootquestionArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


export type query_rootquestion_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


export type query_rootquestion_answerArgs = {
  distinct_on?: InputMaybe<Array<question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_order_by>>;
  where?: InputMaybe<question_answer_bool_exp>;
};


export type query_rootquestion_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_order_by>>;
  where?: InputMaybe<question_answer_bool_exp>;
};


export type query_rootquestion_answer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_answer_likelihood_to_returnArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


export type query_rootquestion_answer_likelihood_to_return_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


export type query_rootquestion_answer_likelihood_to_return_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


export type query_rootquestion_answer_radioArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


export type query_rootquestion_answer_radio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


export type query_rootquestion_answer_radio_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type query_rootquestion_answer_sentiment_phraseArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


export type query_rootquestion_answer_sentiment_phrase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


export type query_rootquestion_answer_sentiment_phrase_by_pkArgs = {
  begin_offset: Scalars['Int']['input'];
  question_answer_id: Scalars['Int']['input'];
  sentiment_phrase_id: Scalars['Int']['input'];
};


export type query_rootquestion_answer_textArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


export type query_rootquestion_answer_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


export type query_rootquestion_answer_text_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


export type query_rootquestion_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_optionArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


export type query_rootquestion_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


export type query_rootquestion_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_option_versionArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


export type query_rootquestion_option_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


export type query_rootquestion_option_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_settingArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


export type query_rootquestion_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


export type query_rootquestion_setting_by_pkArgs = {
  key: question_setting_key_enum;
  question_id: Scalars['Int']['input'];
};


export type query_rootquestion_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<question_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_key_order_by>>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};


export type query_rootquestion_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_key_order_by>>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};


export type query_rootquestion_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootquestion_setting_versionArgs = {
  distinct_on?: InputMaybe<Array<question_setting_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_version_order_by>>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};


export type query_rootquestion_setting_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_version_order_by>>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};


export type query_rootquestion_setting_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_typeArgs = {
  distinct_on?: InputMaybe<Array<question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_type_order_by>>;
  where?: InputMaybe<question_type_bool_exp>;
};


export type query_rootquestion_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_type_order_by>>;
  where?: InputMaybe<question_type_bool_exp>;
};


export type query_rootquestion_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootquestion_versionArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};


export type query_rootquestion_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};


export type query_rootquestion_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootquestion_version_condition_question_optionArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


export type query_rootquestion_version_condition_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


export type query_rootquestion_version_condition_segmentArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


export type query_rootquestion_version_condition_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


export type query_rootquestion_version_condition_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


export type query_rootquestion_version_condition_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


export type query_rootquestion_version_question_optionArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


export type query_rootquestion_version_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


export type query_rootrecurlyCustomFieldsArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootreport_deflection_trendsArgs = {
  args: report_deflection_trends_args;
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type query_rootreport_deflection_trends_aggregateArgs = {
  args: report_deflection_trends_args;
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type query_rootreport_feedback_trendsArgs = {
  args: report_feedback_trends_args;
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type query_rootreport_feedback_trends_aggregateArgs = {
  args: report_feedback_trends_args;
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type query_rootreport_flow_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


export type query_rootreport_flow_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


export type query_rootreport_inactive_mrr_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_inactive_mrr_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_inactive_mrr_by_question_option_order_by>>;
  where?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
};


export type query_rootreport_inactive_mrr_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_inactive_mrr_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_inactive_mrr_by_question_option_order_by>>;
  where?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
};


export type query_rootreport_offer_accept_rateArgs = {
  args: report_offer_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type query_rootreport_offer_accept_rate_aggregateArgs = {
  args: report_offer_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type query_rootreport_offer_autopilot_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


export type query_rootreport_offer_autopilot_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


export type query_rootreport_offer_group_accept_rateArgs = {
  args: report_offer_group_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type query_rootreport_offer_group_accept_rate_aggregateArgs = {
  args: report_offer_group_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type query_rootreport_offer_group_resolution_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_offer_group_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_group_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
};


export type query_rootreport_offer_group_resolution_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_group_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_group_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
};


export type query_rootreport_offer_group_retained_revenueArgs = {
  args: report_offer_group_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type query_rootreport_offer_group_retained_revenue_aggregateArgs = {
  args: report_offer_group_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type query_rootreport_offer_rateArgs = {
  args: report_offer_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type query_rootreport_offer_rate_aggregateArgs = {
  args: report_offer_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type query_rootreport_offer_resolutionArgs = {
  args: report_offer_resolution_args;
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type query_rootreport_offer_resolution_aggregateArgs = {
  args: report_offer_resolution_args;
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type query_rootreport_offer_resolution_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_offer_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
};


export type query_rootreport_offer_resolution_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
};


export type query_rootreport_offer_retained_revenueArgs = {
  args: report_offer_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type query_rootreport_offer_retained_revenue_aggregateArgs = {
  args: report_offer_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type query_rootreport_offer_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


export type query_rootreport_offer_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


export type query_rootreport_question_answer_radioArgs = {
  args: report_question_answer_radio_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type query_rootreport_question_answer_radio_aggregateArgs = {
  args: report_question_answer_radio_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type query_rootreport_question_answer_radio_inactive_mrrArgs = {
  args: report_question_answer_radio_inactive_mrr_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type query_rootreport_question_answer_radio_inactive_mrr_aggregateArgs = {
  args: report_question_answer_radio_inactive_mrr_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type query_rootreport_question_answer_sentimentArgs = {
  args: report_question_answer_sentiment_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type query_rootreport_question_answer_sentiment_aggregateArgs = {
  args: report_question_answer_sentiment_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type query_rootreport_recurring_revenueArgs = {
  distinct_on?: InputMaybe<Array<report_recurring_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_recurring_revenue_order_by>>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};


export type query_rootreport_recurring_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_recurring_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_recurring_revenue_order_by>>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};


export type query_rootreport_recurring_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['date']['input'];
};


export type query_rootreport_retained_revenueArgs = {
  args: report_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type query_rootreport_retained_revenue_aggregateArgs = {
  args: report_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type query_rootreport_retention_trendsArgs = {
  args: report_retention_trends_args;
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type query_rootreport_retention_trends_aggregateArgs = {
  args: report_retention_trends_args;
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type query_rootreport_subscriber_flow_statusArgs = {
  args: report_subscriber_flow_status_args;
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type query_rootreport_subscriber_flow_status_aggregateArgs = {
  args: report_subscriber_flow_status_args;
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type query_rootrevenue_logArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


export type query_rootrevenue_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


export type query_rootrevenue_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootrevenue_log_revenue_typeArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_revenue_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_revenue_type_order_by>>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};


export type query_rootrevenue_log_revenue_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_revenue_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_revenue_type_order_by>>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};


export type query_rootrevenue_log_revenue_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootrevenue_log_typeArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_type_order_by>>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};


export type query_rootrevenue_log_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_type_order_by>>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};


export type query_rootrevenue_log_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootroiCalculatorArgs = {
  input: RoiCalculatorInput;
};


export type query_rootrule_group_modeArgs = {
  distinct_on?: InputMaybe<Array<rule_group_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<rule_group_mode_order_by>>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};


export type query_rootrule_group_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<rule_group_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<rule_group_mode_order_by>>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};


export type query_rootrule_group_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsalesforceFieldsArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootscript_logArgs = {
  distinct_on?: InputMaybe<Array<script_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<script_log_order_by>>;
  where?: InputMaybe<script_log_bool_exp>;
};


export type query_rootscript_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<script_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<script_log_order_by>>;
  where?: InputMaybe<script_log_bool_exp>;
};


export type query_rootscript_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsearchPlatformCustomersArgs = {
  input: SearchPlatformCustomersInput;
};


export type query_rootsearch_accountsArgs = {
  args: search_accounts_args;
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type query_rootsearch_accounts_aggregateArgs = {
  args: search_accounts_args;
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type query_rootsearch_data_connector_request_logsArgs = {
  args: search_data_connector_request_logs_args;
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type query_rootsearch_data_connector_request_logs_aggregateArgs = {
  args: search_data_connector_request_logs_args;
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type query_rootsearch_deflectionsArgs = {
  args: search_deflections_args;
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type query_rootsearch_deflections_aggregateArgs = {
  args: search_deflections_args;
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type query_rootsearch_offersArgs = {
  args: search_offers_args;
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type query_rootsearch_offers_aggregateArgs = {
  args: search_offers_args;
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type query_rootsearch_platform_api_logsArgs = {
  args: search_platform_api_logs_args;
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type query_rootsearch_platform_api_logs_aggregateArgs = {
  args: search_platform_api_logs_args;
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type query_rootsearch_platform_couponsArgs = {
  args: search_platform_coupons_args;
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type query_rootsearch_platform_coupons_aggregateArgs = {
  args: search_platform_coupons_args;
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type query_rootsearch_propertiesArgs = {
  args: search_properties_args;
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type query_rootsearch_properties_aggregateArgs = {
  args: search_properties_args;
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type query_rootsearch_segment_groupsArgs = {
  args: search_segment_groups_args;
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type query_rootsearch_segment_groups_aggregateArgs = {
  args: search_segment_groups_args;
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type query_rootsearch_segmentsArgs = {
  args: search_segments_args;
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type query_rootsearch_segments_aggregateArgs = {
  args: search_segments_args;
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type query_rootsearch_subscriber_flowsArgs = {
  args: search_subscriber_flows_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsearch_subscriber_flows_aggregateArgs = {
  args: search_subscriber_flows_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsearch_subscriber_gridArgs = {
  args: search_subscriber_grid_args;
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type query_rootsearch_subscriber_grid_aggregateArgs = {
  args: search_subscriber_grid_args;
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type query_rootsearch_subscribersArgs = {
  args: search_subscribers_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsearch_subscribers_aggregateArgs = {
  args: search_subscribers_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsearch_webhook_request_logsArgs = {
  args: search_webhook_request_logs_args;
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type query_rootsearch_webhook_request_logs_aggregateArgs = {
  args: search_webhook_request_logs_args;
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type query_rootsegmentArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type query_rootsegmentMatchArgs = {
  input: SegmentMatchInput;
};


export type query_rootsegment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type query_rootsegment_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsegment_conditionArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


export type query_rootsegment_condition_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


export type query_rootsegment_condition_boolean_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_boolean_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_boolean_operator_order_by>>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};


export type query_rootsegment_condition_boolean_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_boolean_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_boolean_operator_order_by>>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};


export type query_rootsegment_condition_boolean_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsegment_condition_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsegment_condition_groupArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_order_by>>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};


export type query_rootsegment_condition_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_order_by>>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};


export type query_rootsegment_condition_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsegment_condition_group_entryArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


export type query_rootsegment_condition_group_entry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


export type query_rootsegment_condition_group_entry_by_pkArgs = {
  position: Scalars['Int']['input'];
  segment_condition_group_id: Scalars['Int']['input'];
};


export type query_rootsegment_condition_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_operator_order_by>>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};


export type query_rootsegment_condition_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_operator_order_by>>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};


export type query_rootsegment_condition_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsegment_condition_propertyArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_property_order_by>>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};


export type query_rootsegment_condition_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_property_order_by>>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};


export type query_rootsegment_condition_property_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsegment_groupArgs = {
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type query_rootsegment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type query_rootsegment_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsegment_group_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_group_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_operator_order_by>>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};


export type query_rootsegment_group_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_operator_order_by>>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};


export type query_rootsegment_group_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsegment_group_segmentArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


export type query_rootsegment_group_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


export type query_rootsegment_group_segment_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
};


export type query_rootsegment_group_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<segment_group_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_tsvector_order_by>>;
  where?: InputMaybe<segment_group_tsvector_bool_exp>;
};


export type query_rootsegment_group_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_tsvector_order_by>>;
  where?: InputMaybe<segment_group_tsvector_bool_exp>;
};


export type query_rootsegment_subscriberArgs = {
  args: segment_subscriber_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsegment_subscriber_aggregateArgs = {
  args: segment_subscriber_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsegment_subscriber_flowArgs = {
  args: segment_subscriber_flow_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsegment_subscriber_flow_aggregateArgs = {
  args: segment_subscriber_flow_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsegment_subscriber_logArgs = {
  args: segment_subscriber_log_args;
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type query_rootsegment_subscriber_log_aggregateArgs = {
  args: segment_subscriber_log_args;
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type query_rootsegment_subscriptionArgs = {
  args: segment_subscription_args;
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type query_rootsegment_subscription_aggregateArgs = {
  args: segment_subscription_args;
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type query_rootsegment_subscription_cacheArgs = {
  distinct_on?: InputMaybe<Array<segment_subscription_cache_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_subscription_cache_order_by>>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};


export type query_rootsegment_subscription_cache_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_subscription_cache_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_subscription_cache_order_by>>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};


export type query_rootsegment_subscription_cache_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type query_rootsegment_tagArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


export type query_rootsegment_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


export type query_rootsegment_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<segment_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tsvector_order_by>>;
  where?: InputMaybe<segment_tsvector_bool_exp>;
};


export type query_rootsegment_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tsvector_order_by>>;
  where?: InputMaybe<segment_tsvector_bool_exp>;
};


export type query_rootsentimentArgs = {
  distinct_on?: InputMaybe<Array<sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_order_by>>;
  where?: InputMaybe<sentiment_bool_exp>;
};


export type query_rootsentiment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_order_by>>;
  where?: InputMaybe<sentiment_bool_exp>;
};


export type query_rootsentiment_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsentiment_phraseArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


export type query_rootsentiment_phrase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


export type query_rootsentiment_phrase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsentiment_phrase_normalizedArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_normalized_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_normalized_order_by>>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};


export type query_rootsentiment_phrase_normalized_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_normalized_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_normalized_order_by>>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};


export type query_rootsentiment_phrase_normalized_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootssoLogInDetailsArgs = {
  input: SsoLogInDetailsInput;
};


export type query_rootstateArgs = {
  distinct_on?: InputMaybe<Array<state_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_order_by>>;
  where?: InputMaybe<state_bool_exp>;
};


export type query_rootstate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<state_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_order_by>>;
  where?: InputMaybe<state_bool_exp>;
};


export type query_rootstate_by_pkArgs = {
  code: Scalars['citext']['input'];
  country: Scalars['citext']['input'];
};


export type query_rootstate_nameArgs = {
  distinct_on?: InputMaybe<Array<state_name_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_name_order_by>>;
  where?: InputMaybe<state_name_bool_exp>;
};


export type query_rootstate_name_aggregateArgs = {
  distinct_on?: InputMaybe<Array<state_name_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_name_order_by>>;
  where?: InputMaybe<state_name_bool_exp>;
};


export type query_rootstate_name_by_pkArgs = {
  country: Scalars['citext']['input'];
  name: Scalars['citext']['input'];
};


export type query_rootstripe_appArgs = {
  distinct_on?: InputMaybe<Array<stripe_app_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<stripe_app_order_by>>;
  where?: InputMaybe<stripe_app_bool_exp>;
};


export type query_rootstripe_app_aggregateArgs = {
  distinct_on?: InputMaybe<Array<stripe_app_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<stripe_app_order_by>>;
  where?: InputMaybe<stripe_app_bool_exp>;
};


export type query_rootstripe_app_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriberArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsubscriberCampaignPaymentInfoArgs = {
  input: SubscriberCampaignPaymentInfoInput;
};


export type query_rootsubscriberFlowOfferArgs = {
  input: SubscriberFlowOfferInput;
};


export type query_rootsubscriberFlowSegmentMatchesArgs = {
  input: SubscriberFlowSegmentMatchesInput;
};


export type query_rootsubscriber_acknowledgementArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


export type query_rootsubscriber_acknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


export type query_rootsubscriber_acknowledgement_by_pkArgs = {
  acknowledgement_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type query_rootsubscriber_arrArgs = {
  distinct_on?: InputMaybe<Array<subscriber_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_arr_order_by>>;
  where?: InputMaybe<subscriber_arr_bool_exp>;
};


export type query_rootsubscriber_arr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_arr_order_by>>;
  where?: InputMaybe<subscriber_arr_bool_exp>;
};


export type query_rootsubscriber_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaignArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


export type query_rootsubscriber_campaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


export type query_rootsubscriber_campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


export type query_rootsubscriber_campaign_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


export type query_rootsubscriber_campaign_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


export type query_rootsubscriber_campaign_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


export type query_rootsubscriber_campaign_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


export type query_rootsubscriber_campaign_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


export type query_rootsubscriber_campaign_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


export type query_rootsubscriber_campaign_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


export type query_rootsubscriber_campaign_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};


export type query_rootsubscriber_campaign_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};


export type query_rootsubscriber_campaign_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_campaign_viewArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


export type query_rootsubscriber_campaign_view_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


export type query_rootsubscriber_campaign_view_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_campaign_view_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};


export type query_rootsubscriber_campaign_view_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};


export type query_rootsubscriber_campaign_view_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_created_atArgs = {
  distinct_on?: InputMaybe<Array<subscriber_created_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_created_at_order_by>>;
  where?: InputMaybe<subscriber_created_at_bool_exp>;
};


export type query_rootsubscriber_created_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_created_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_created_at_order_by>>;
  where?: InputMaybe<subscriber_created_at_bool_exp>;
};


export type query_rootsubscriber_days_since_savedArgs = {
  distinct_on?: InputMaybe<Array<subscriber_days_since_saved_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_days_since_saved_order_by>>;
  where?: InputMaybe<subscriber_days_since_saved_bool_exp>;
};


export type query_rootsubscriber_days_since_saved_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_days_since_saved_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_days_since_saved_order_by>>;
  where?: InputMaybe<subscriber_days_since_saved_bool_exp>;
};


export type query_rootsubscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsubscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type query_rootsubscriber_flow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_deflectionArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


export type query_rootsubscriber_flow_deflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


export type query_rootsubscriber_flow_deflection_by_pkArgs = {
  deflection_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_exportArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_export_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_export_order_by>>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};


export type query_rootsubscriber_flow_export_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_export_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_export_order_by>>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};


export type query_rootsubscriber_flow_export_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_flow_stepArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


export type query_rootsubscriber_flow_flow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


export type query_rootsubscriber_flow_flow_step_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


export type query_rootsubscriber_flow_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


export type query_rootsubscriber_flow_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


export type query_rootsubscriber_flow_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


export type query_rootsubscriber_flow_offer_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_offer_group_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


export type query_rootsubscriber_flow_offer_group_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


export type query_rootsubscriber_flow_offer_group_offer_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


export type query_rootsubscriber_flow_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


export type query_rootsubscriber_flow_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_offer_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_status_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};


export type query_rootsubscriber_flow_offer_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_status_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};


export type query_rootsubscriber_flow_offer_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_flow_on_cancel_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_on_cancel_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_on_cancel_override_order_by>>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};


export type query_rootsubscriber_flow_on_cancel_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_on_cancel_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_on_cancel_override_order_by>>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};


export type query_rootsubscriber_flow_on_cancel_override_by_pkArgs = {
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


export type query_rootsubscriber_flow_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


export type query_rootsubscriber_flow_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_override_reasonArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_reason_order_by>>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};


export type query_rootsubscriber_flow_override_reason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_reason_order_by>>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};


export type query_rootsubscriber_flow_override_reason_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_flow_question_answerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


export type query_rootsubscriber_flow_question_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


export type query_rootsubscriber_flow_question_answer_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


export type query_rootsubscriber_flow_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


export type query_rootsubscriber_flow_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_group_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};


export type query_rootsubscriber_flow_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_group_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};


export type query_rootsubscriber_flow_segment_group_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_flow_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_order_by>>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};


export type query_rootsubscriber_flow_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_order_by>>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};


export type query_rootsubscriber_flow_status_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_by_month_order_by>>;
  where?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
};


export type query_rootsubscriber_flow_status_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_by_month_order_by>>;
  where?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
};


export type query_rootsubscriber_flow_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_flow_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_tsvector_order_by>>;
  where?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
};


export type query_rootsubscriber_flow_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_tsvector_order_by>>;
  where?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
};


export type query_rootsubscriber_flow_urlArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_url_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_url_order_by>>;
  where?: InputMaybe<subscriber_flow_url_bool_exp>;
};


export type query_rootsubscriber_flow_url_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_url_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_url_order_by>>;
  where?: InputMaybe<subscriber_flow_url_bool_exp>;
};


export type query_rootsubscriber_flow_version_last_60_daysArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_version_last_60_days_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_version_last_60_days_order_by>>;
  where?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
};


export type query_rootsubscriber_flow_version_last_60_days_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_version_last_60_days_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_version_last_60_days_order_by>>;
  where?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
};


export type query_rootsubscriber_gridArgs = {
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type query_rootsubscriber_grid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type query_rootsubscriber_logArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type query_rootsubscriber_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type query_rootsubscriber_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscriber_log_eventArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_order_by>>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};


export type query_rootsubscriber_log_event_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_order_by>>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};


export type query_rootsubscriber_log_event_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_by_month_order_by>>;
  where?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
};


export type query_rootsubscriber_log_event_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_by_month_order_by>>;
  where?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
};


export type query_rootsubscriber_log_event_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_log_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


export type query_rootsubscriber_log_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


export type query_rootsubscriber_log_offer_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_log_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_group_order_by>>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};


export type query_rootsubscriber_log_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_group_order_by>>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};


export type query_rootsubscriber_log_offer_group_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_ltvArgs = {
  distinct_on?: InputMaybe<Array<subscriber_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_ltv_order_by>>;
  where?: InputMaybe<subscriber_ltv_bool_exp>;
};


export type query_rootsubscriber_ltv_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_ltv_order_by>>;
  where?: InputMaybe<subscriber_ltv_bool_exp>;
};


export type query_rootsubscriber_most_recent_subscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
};


export type query_rootsubscriber_most_recent_subscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
};


export type query_rootsubscriber_mrrArgs = {
  distinct_on?: InputMaybe<Array<subscriber_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_mrr_order_by>>;
  where?: InputMaybe<subscriber_mrr_bool_exp>;
};


export type query_rootsubscriber_mrr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_mrr_order_by>>;
  where?: InputMaybe<subscriber_mrr_bool_exp>;
};


export type query_rootsubscriber_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


export type query_rootsubscriber_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


export type query_rootsubscriber_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type query_rootsubscriber_property_valueArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


export type query_rootsubscriber_property_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


export type query_rootsubscriber_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_status_order_by>>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};


export type query_rootsubscriber_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_status_order_by>>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};


export type query_rootsubscriber_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsubscriber_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<subscriber_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_tsvector_order_by>>;
  where?: InputMaybe<subscriber_tsvector_bool_exp>;
};


export type query_rootsubscriber_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_tsvector_order_by>>;
  where?: InputMaybe<subscriber_tsvector_bool_exp>;
};


export type query_rootsubscriptionArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type query_rootsubscriptionSegmentPropertyValuesArgs = {
  input: SubscriptionSegmentPropertyValuesInput;
};


export type query_rootsubscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type query_rootsubscription_amount_paid_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscription_amount_paid_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_amount_paid_by_month_order_by>>;
  where?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
};


export type query_rootsubscription_amount_paid_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_amount_paid_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_amount_paid_by_month_order_by>>;
  where?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
};


export type query_rootsubscription_arrArgs = {
  distinct_on?: InputMaybe<Array<subscription_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_arr_order_by>>;
  where?: InputMaybe<subscription_arr_bool_exp>;
};


export type query_rootsubscription_arr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_arr_order_by>>;
  where?: InputMaybe<subscription_arr_bool_exp>;
};


export type query_rootsubscription_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootsubscription_durationArgs = {
  distinct_on?: InputMaybe<Array<subscription_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_duration_order_by>>;
  where?: InputMaybe<subscription_duration_bool_exp>;
};


export type query_rootsubscription_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_duration_order_by>>;
  where?: InputMaybe<subscription_duration_bool_exp>;
};


export type query_rootsubscription_ltvArgs = {
  distinct_on?: InputMaybe<Array<subscription_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_ltv_order_by>>;
  where?: InputMaybe<subscription_ltv_bool_exp>;
};


export type query_rootsubscription_ltv_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_ltv_order_by>>;
  where?: InputMaybe<subscription_ltv_bool_exp>;
};


export type query_rootsubscription_most_recent_subscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscription_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
};


export type query_rootsubscription_most_recent_subscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
};


export type query_rootsubscription_productArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


export type query_rootsubscription_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


export type query_rootsubscription_product_by_pkArgs = {
  product_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type query_rootsubscription_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


export type query_rootsubscription_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


export type query_rootsubscription_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type query_rootsubscription_property_valueArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


export type query_rootsubscription_property_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


export type query_rootsubscription_revenue_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscription_revenue_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_revenue_by_month_order_by>>;
  where?: InputMaybe<subscription_revenue_by_month_bool_exp>;
};


export type query_rootsubscription_revenue_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_revenue_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_revenue_by_month_order_by>>;
  where?: InputMaybe<subscription_revenue_by_month_bool_exp>;
};


export type query_rootsubscription_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_order_by>>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};


export type query_rootsubscription_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_order_by>>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};


export type query_rootsubscription_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type query_rootsubscription_segment_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_property_order_by>>;
  where?: InputMaybe<subscription_segment_property_bool_exp>;
};


export type query_rootsubscription_segment_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_property_order_by>>;
  where?: InputMaybe<subscription_segment_property_bool_exp>;
};


export type query_rootsubscription_segment_statusArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_status_order_by>>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};


export type query_rootsubscription_segment_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_status_order_by>>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};


export type query_rootsubscription_segment_status_by_pkArgs = {
  subscription_id: Scalars['Int']['input'];
};


export type query_rootsubscription_statusArgs = {
  distinct_on?: InputMaybe<Array<subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_status_order_by>>;
  where?: InputMaybe<subscription_status_bool_exp>;
};


export type query_rootsubscription_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_status_order_by>>;
  where?: InputMaybe<subscription_status_bool_exp>;
};


export type query_rootsubscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootsurveyArgs = {
  distinct_on?: InputMaybe<Array<survey_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<survey_order_by>>;
  where?: InputMaybe<survey_bool_exp>;
};


export type query_rootsurvey_aggregateArgs = {
  distinct_on?: InputMaybe<Array<survey_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<survey_order_by>>;
  where?: InputMaybe<survey_bool_exp>;
};


export type query_rootsurvey_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_roottagArgs = {
  distinct_on?: InputMaybe<Array<tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<tag_order_by>>;
  where?: InputMaybe<tag_bool_exp>;
};


export type query_roottag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<tag_order_by>>;
  where?: InputMaybe<tag_bool_exp>;
};


export type query_roottag_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_roottemplate_report_deflection_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type query_roottemplate_report_deflection_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type query_roottemplate_report_deflection_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  flow_action_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_feedback_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type query_roottemplate_report_feedback_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type query_roottemplate_report_feedback_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  sentiment_phrase_normalized_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_offer_accept_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type query_roottemplate_report_offer_accept_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type query_roottemplate_report_offer_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_offer_group_accept_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type query_roottemplate_report_offer_group_accept_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type query_roottemplate_report_offer_group_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_offer_group_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_offer_group_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_offer_group_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_offer_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type query_roottemplate_report_offer_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type query_roottemplate_report_offer_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottemplate_report_offer_resolutionArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type query_roottemplate_report_offer_resolution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type query_roottemplate_report_offer_resolution_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottemplate_report_offer_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_offer_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_offer_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_question_answer_radioArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type query_roottemplate_report_question_answer_radio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type query_roottemplate_report_question_answer_radio_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_question_answer_radio_inactive_mrrArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type query_roottemplate_report_question_answer_radio_inactive_mrr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type query_roottemplate_report_question_answer_radio_inactive_mrr_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type query_roottemplate_report_question_answer_sentimentArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type query_roottemplate_report_question_answer_sentiment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type query_roottemplate_report_question_answer_sentiment_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottemplate_report_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type query_roottemplate_report_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottemplate_report_retention_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type query_roottemplate_report_retention_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type query_roottemplate_report_retention_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottemplate_report_subscriber_flow_statusArgs = {
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type query_roottemplate_report_subscriber_flow_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type query_roottemplate_report_subscriber_flow_status_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type query_roottranslationArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


export type query_roottranslation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


export type query_roottranslation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_roottranslation_valueArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


export type query_roottranslation_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


export type query_roottranslation_value_by_pkArgs = {
  language: language_enum;
  translation_id: Scalars['Int']['input'];
};


export type query_roottranslation_value_formatArgs = {
  distinct_on?: InputMaybe<Array<translation_value_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_format_order_by>>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};


export type query_roottranslation_value_format_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_value_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_format_order_by>>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};


export type query_roottranslation_value_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type query_rootuserArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};


export type query_rootuserInvitationByTokenArgs = {
  input: UserInvitationByTokenInput;
};


export type query_rootuser_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};


export type query_rootuser_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type query_rootuser_invitationArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


export type query_rootuser_invitation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


export type query_rootuser_invitation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootuser_profileArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


export type query_rootuser_profile_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


export type query_rootuser_profile_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type query_rootviewerArgs = {
  nothing?: InputMaybe<Scalars['String']['input']>;
};


export type query_rootwebhookArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};


export type query_rootwebhook_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};


export type query_rootwebhook_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type query_rootwebhook_eventArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


export type query_rootwebhook_event_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


export type query_rootwebhook_event_by_pkArgs = {
  event_type: event_type_enum;
  webhook_id: Scalars['Int']['input'];
};


export type query_rootwebhook_logArgs = {
  distinct_on?: InputMaybe<Array<webhook_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_log_order_by>>;
  where?: InputMaybe<webhook_log_bool_exp>;
};


export type query_rootwebhook_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_log_order_by>>;
  where?: InputMaybe<webhook_log_bool_exp>;
};


export type query_rootwebhook_log_by_pkArgs = {
  attempt: Scalars['Int']['input'];
  event_id: Scalars['Int']['input'];
  webhook_id: Scalars['Int']['input'];
};


export type query_rootwebhook_request_logArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type query_rootwebhook_request_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type query_rootwebhook_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type query_rootwebhook_request_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_tsvector_order_by>>;
  where?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
};


export type query_rootwebhook_request_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_tsvector_order_by>>;
  where?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
};


export type query_rootwebhook_statusArgs = {
  distinct_on?: InputMaybe<Array<webhook_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_status_order_by>>;
  where?: InputMaybe<webhook_status_bool_exp>;
};


export type query_rootwebhook_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_status_order_by>>;
  where?: InputMaybe<webhook_status_bool_exp>;
};


export type query_rootwebhook_status_by_pkArgs = {
  value: Scalars['String']['input'];
};

/** columns and relationships of "question" */
export type question = {
  __typename: 'question';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  draft_version?: Maybe<question_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  flow_step_questions: Array<flow_step_question>;
  /** An aggregate relationship */
  flow_step_questions_aggregate: flow_step_question_aggregate;
  /** An array relationship */
  form_version_questions: Array<form_version_question>;
  /** An aggregate relationship */
  form_version_questions_aggregate: form_version_question_aggregate;
  id: Scalars['Int']['output'];
  primary: Scalars['Boolean']['output'];
  /** An object relationship */
  published_version?: Maybe<question_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  question_options: Array<question_option>;
  /** An aggregate relationship */
  question_options_aggregate: question_option_aggregate;
  /** An array relationship */
  question_settings: Array<question_setting>;
  /** An aggregate relationship */
  question_settings_aggregate: question_setting_aggregate;
  /** An object relationship */
  question_type: question_type;
  /** An array relationship */
  question_versions: Array<question_version>;
  /** An aggregate relationship */
  question_versions_aggregate: question_version_aggregate;
  token: Scalars['String']['output'];
  type: question_type_enum;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "question" */
export type questionflow_step_questionsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


/** columns and relationships of "question" */
export type questionflow_step_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


/** columns and relationships of "question" */
export type questionform_version_questionsArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


/** columns and relationships of "question" */
export type questionform_version_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_optionsArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_settingsArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_versionsArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};


/** columns and relationships of "question" */
export type questionquestion_versions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};

/** aggregated selection of "question" */
export type question_aggregate = {
  __typename: 'question_aggregate';
  aggregate?: Maybe<question_aggregate_fields>;
  nodes: Array<question>;
};

export type question_aggregate_bool_exp = {
  bool_and?: InputMaybe<question_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<question_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<question_aggregate_bool_exp_count>;
};

export type question_aggregate_bool_exp_bool_and = {
  arguments: question_select_column_question_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type question_aggregate_bool_exp_bool_or = {
  arguments: question_select_column_question_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type question_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question" */
export type question_aggregate_fields = {
  __typename: 'question_aggregate_fields';
  avg?: Maybe<question_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_max_fields>;
  min?: Maybe<question_min_fields>;
  stddev?: Maybe<question_stddev_fields>;
  stddev_pop?: Maybe<question_stddev_pop_fields>;
  stddev_samp?: Maybe<question_stddev_samp_fields>;
  sum?: Maybe<question_sum_fields>;
  var_pop?: Maybe<question_var_pop_fields>;
  var_samp?: Maybe<question_var_samp_fields>;
  variance?: Maybe<question_variance_fields>;
};


/** aggregate fields of "question" */
export type question_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question" */
export type question_aggregate_order_by = {
  avg?: InputMaybe<question_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_max_order_by>;
  min?: InputMaybe<question_min_order_by>;
  stddev?: InputMaybe<question_stddev_order_by>;
  stddev_pop?: InputMaybe<question_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_stddev_samp_order_by>;
  sum?: InputMaybe<question_sum_order_by>;
  var_pop?: InputMaybe<question_var_pop_order_by>;
  var_samp?: InputMaybe<question_var_samp_order_by>;
  variance?: InputMaybe<question_variance_order_by>;
};

/** columns and relationships of "question_answer" */
export type question_answer = {
  __typename: 'question_answer';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  question: question;
  /** An object relationship */
  question_answer_likelihood_to_return?: Maybe<question_answer_likelihood_to_return>;
  /** An array relationship */
  question_answer_likelihoods_to_return: Array<question_answer_likelihood_to_return>;
  /** An aggregate relationship */
  question_answer_likelihoods_to_return_aggregate: question_answer_likelihood_to_return_aggregate;
  /** An array relationship */
  question_answer_radios: Array<question_answer_radio>;
  /** An aggregate relationship */
  question_answer_radios_aggregate: question_answer_radio_aggregate;
  /** An array relationship */
  question_answer_sentiment_phrases: Array<question_answer_sentiment_phrase>;
  /** An aggregate relationship */
  question_answer_sentiment_phrases_aggregate: question_answer_sentiment_phrase_aggregate;
  /** An object relationship */
  question_answer_text?: Maybe<question_answer_text>;
  /** An array relationship */
  question_answer_texts: Array<question_answer_text>;
  /** An aggregate relationship */
  question_answer_texts_aggregate: question_answer_text_aggregate;
  question_id: Scalars['Int']['output'];
  /** An object relationship */
  question_version: question_version;
  question_version_id: Scalars['Int']['output'];
  /** An array relationship */
  subscriber_flow_question_answers: Array<subscriber_flow_question_answer>;
  /** An aggregate relationship */
  subscriber_flow_question_answers_aggregate: subscriber_flow_question_answer_aggregate;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_likelihoods_to_returnArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_likelihoods_to_return_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_radiosArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_radios_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_sentiment_phrasesArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_sentiment_phrases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_textsArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answerquestion_answer_texts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answersubscriber_flow_question_answersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


/** columns and relationships of "question_answer" */
export type question_answersubscriber_flow_question_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};

/** aggregated selection of "question_answer" */
export type question_answer_aggregate = {
  __typename: 'question_answer_aggregate';
  aggregate?: Maybe<question_answer_aggregate_fields>;
  nodes: Array<question_answer>;
};

/** aggregate fields of "question_answer" */
export type question_answer_aggregate_fields = {
  __typename: 'question_answer_aggregate_fields';
  avg?: Maybe<question_answer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_answer_max_fields>;
  min?: Maybe<question_answer_min_fields>;
  stddev?: Maybe<question_answer_stddev_fields>;
  stddev_pop?: Maybe<question_answer_stddev_pop_fields>;
  stddev_samp?: Maybe<question_answer_stddev_samp_fields>;
  sum?: Maybe<question_answer_sum_fields>;
  var_pop?: Maybe<question_answer_var_pop_fields>;
  var_samp?: Maybe<question_answer_var_samp_fields>;
  variance?: Maybe<question_answer_variance_fields>;
};


/** aggregate fields of "question_answer" */
export type question_answer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_answer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type question_answer_avg_fields = {
  __typename: 'question_answer_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "question_answer". All fields are combined with a logical 'AND'. */
export type question_answer_bool_exp = {
  _and?: InputMaybe<Array<question_answer_bool_exp>>;
  _not?: InputMaybe<question_answer_bool_exp>;
  _or?: InputMaybe<Array<question_answer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_answer_likelihood_to_return?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
  question_answer_likelihoods_to_return?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
  question_answer_likelihoods_to_return_aggregate?: InputMaybe<question_answer_likelihood_to_return_aggregate_bool_exp>;
  question_answer_radios?: InputMaybe<question_answer_radio_bool_exp>;
  question_answer_radios_aggregate?: InputMaybe<question_answer_radio_aggregate_bool_exp>;
  question_answer_sentiment_phrases?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
  question_answer_sentiment_phrases_aggregate?: InputMaybe<question_answer_sentiment_phrase_aggregate_bool_exp>;
  question_answer_text?: InputMaybe<question_answer_text_bool_exp>;
  question_answer_texts?: InputMaybe<question_answer_text_bool_exp>;
  question_answer_texts_aggregate?: InputMaybe<question_answer_text_aggregate_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_version?: InputMaybe<question_version_bool_exp>;
  question_version_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "question_answer" */
export enum question_answer_constraint {
  /** unique or primary key constraint on columns "id" */
  question_answer_pkey = 'question_answer_pkey'
}

/** input type for incrementing numeric columns in table "question_answer" */
export type question_answer_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_answer" */
export type question_answer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_answer_likelihood_to_return?: InputMaybe<question_answer_likelihood_to_return_obj_rel_insert_input>;
  question_answer_likelihoods_to_return?: InputMaybe<question_answer_likelihood_to_return_arr_rel_insert_input>;
  question_answer_radios?: InputMaybe<question_answer_radio_arr_rel_insert_input>;
  question_answer_sentiment_phrases?: InputMaybe<question_answer_sentiment_phrase_arr_rel_insert_input>;
  question_answer_text?: InputMaybe<question_answer_text_obj_rel_insert_input>;
  question_answer_texts?: InputMaybe<question_answer_text_arr_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_version?: InputMaybe<question_version_obj_rel_insert_input>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_arr_rel_insert_input>;
};

/** columns and relationships of "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return = {
  __typename: 'question_answer_likelihood_to_return';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  question_answer: question_answer;
  question_answer_id: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

/** aggregated selection of "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_aggregate = {
  __typename: 'question_answer_likelihood_to_return_aggregate';
  aggregate?: Maybe<question_answer_likelihood_to_return_aggregate_fields>;
  nodes: Array<question_answer_likelihood_to_return>;
};

export type question_answer_likelihood_to_return_aggregate_bool_exp = {
  count?: InputMaybe<question_answer_likelihood_to_return_aggregate_bool_exp_count>;
};

export type question_answer_likelihood_to_return_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_aggregate_fields = {
  __typename: 'question_answer_likelihood_to_return_aggregate_fields';
  avg?: Maybe<question_answer_likelihood_to_return_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_answer_likelihood_to_return_max_fields>;
  min?: Maybe<question_answer_likelihood_to_return_min_fields>;
  stddev?: Maybe<question_answer_likelihood_to_return_stddev_fields>;
  stddev_pop?: Maybe<question_answer_likelihood_to_return_stddev_pop_fields>;
  stddev_samp?: Maybe<question_answer_likelihood_to_return_stddev_samp_fields>;
  sum?: Maybe<question_answer_likelihood_to_return_sum_fields>;
  var_pop?: Maybe<question_answer_likelihood_to_return_var_pop_fields>;
  var_samp?: Maybe<question_answer_likelihood_to_return_var_samp_fields>;
  variance?: Maybe<question_answer_likelihood_to_return_variance_fields>;
};


/** aggregate fields of "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_aggregate_order_by = {
  avg?: InputMaybe<question_answer_likelihood_to_return_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_answer_likelihood_to_return_max_order_by>;
  min?: InputMaybe<question_answer_likelihood_to_return_min_order_by>;
  stddev?: InputMaybe<question_answer_likelihood_to_return_stddev_order_by>;
  stddev_pop?: InputMaybe<question_answer_likelihood_to_return_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_answer_likelihood_to_return_stddev_samp_order_by>;
  sum?: InputMaybe<question_answer_likelihood_to_return_sum_order_by>;
  var_pop?: InputMaybe<question_answer_likelihood_to_return_var_pop_order_by>;
  var_samp?: InputMaybe<question_answer_likelihood_to_return_var_samp_order_by>;
  variance?: InputMaybe<question_answer_likelihood_to_return_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_arr_rel_insert_input = {
  data: Array<question_answer_likelihood_to_return_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_likelihood_to_return_on_conflict>;
};

/** aggregate avg on columns */
export type question_answer_likelihood_to_return_avg_fields = {
  __typename: 'question_answer_likelihood_to_return_avg_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_avg_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_answer_likelihood_to_return". All fields are combined with a logical 'AND'. */
export type question_answer_likelihood_to_return_bool_exp = {
  _and?: InputMaybe<Array<question_answer_likelihood_to_return_bool_exp>>;
  _not?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
  _or?: InputMaybe<Array<question_answer_likelihood_to_return_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  question_answer?: InputMaybe<question_answer_bool_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "question_answer_likelihood_to_return" */
export enum question_answer_likelihood_to_return_constraint {
  /** unique or primary key constraint on columns "question_answer_id" */
  question_answer_likelihood_to_return_pkey = 'question_answer_likelihood_to_return_pkey'
}

/** input type for incrementing numeric columns in table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_inc_input = {
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer?: InputMaybe<question_answer_obj_rel_insert_input>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_answer_likelihood_to_return_max_fields = {
  __typename: 'question_answer_likelihood_to_return_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_max_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_answer_likelihood_to_return_min_fields = {
  __typename: 'question_answer_likelihood_to_return_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_min_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_mutation_response = {
  __typename: 'question_answer_likelihood_to_return_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_answer_likelihood_to_return>;
};

/** input type for inserting object relation for remote table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_obj_rel_insert_input = {
  data: question_answer_likelihood_to_return_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_likelihood_to_return_on_conflict>;
};

/** on_conflict condition type for table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_on_conflict = {
  constraint: question_answer_likelihood_to_return_constraint;
  update_columns?: Array<question_answer_likelihood_to_return_update_column>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};

/** Ordering options when selecting data from "question_answer_likelihood_to_return". */
export type question_answer_likelihood_to_return_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer?: InputMaybe<question_answer_order_by>;
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_answer_likelihood_to_return */
export type question_answer_likelihood_to_return_pk_columns_input = {
  question_answer_id: Scalars['Int']['input'];
};

/** select columns of table "question_answer_likelihood_to_return" */
export enum question_answer_likelihood_to_return_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type question_answer_likelihood_to_return_stddev_fields = {
  __typename: 'question_answer_likelihood_to_return_stddev_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_stddev_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_answer_likelihood_to_return_stddev_pop_fields = {
  __typename: 'question_answer_likelihood_to_return_stddev_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_stddev_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_answer_likelihood_to_return_stddev_samp_fields = {
  __typename: 'question_answer_likelihood_to_return_stddev_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_stddev_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_answer_likelihood_to_return_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_answer_likelihood_to_return_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_answer_likelihood_to_return_sum_fields = {
  __typename: 'question_answer_likelihood_to_return_sum_fields';
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_sum_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** update columns of table "question_answer_likelihood_to_return" */
export enum question_answer_likelihood_to_return_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  value = 'value'
}

export type question_answer_likelihood_to_return_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_answer_likelihood_to_return_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_answer_likelihood_to_return_set_input>;
  /** filter the rows which have to be updated */
  where: question_answer_likelihood_to_return_bool_exp;
};

/** aggregate var_pop on columns */
export type question_answer_likelihood_to_return_var_pop_fields = {
  __typename: 'question_answer_likelihood_to_return_var_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_var_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_answer_likelihood_to_return_var_samp_fields = {
  __typename: 'question_answer_likelihood_to_return_var_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_var_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_answer_likelihood_to_return_variance_fields = {
  __typename: 'question_answer_likelihood_to_return_variance_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_answer_likelihood_to_return" */
export type question_answer_likelihood_to_return_variance_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type question_answer_max_fields = {
  __typename: 'question_answer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type question_answer_min_fields = {
  __typename: 'question_answer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "question_answer" */
export type question_answer_mutation_response = {
  __typename: 'question_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_answer>;
};

/** input type for inserting object relation for remote table "question_answer" */
export type question_answer_obj_rel_insert_input = {
  data: question_answer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_on_conflict>;
};

/** on_conflict condition type for table "question_answer" */
export type question_answer_on_conflict = {
  constraint: question_answer_constraint;
  update_columns?: Array<question_answer_update_column>;
  where?: InputMaybe<question_answer_bool_exp>;
};

/** Ordering options when selecting data from "question_answer". */
export type question_answer_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_answer_likelihood_to_return?: InputMaybe<question_answer_likelihood_to_return_order_by>;
  question_answer_likelihoods_to_return_aggregate?: InputMaybe<question_answer_likelihood_to_return_aggregate_order_by>;
  question_answer_radios_aggregate?: InputMaybe<question_answer_radio_aggregate_order_by>;
  question_answer_sentiment_phrases_aggregate?: InputMaybe<question_answer_sentiment_phrase_aggregate_order_by>;
  question_answer_text?: InputMaybe<question_answer_text_order_by>;
  question_answer_texts_aggregate?: InputMaybe<question_answer_text_aggregate_order_by>;
  question_id?: InputMaybe<order_by>;
  question_version?: InputMaybe<question_version_order_by>;
  question_version_id?: InputMaybe<order_by>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_order_by>;
};

/** primary key columns input for table: question_answer */
export type question_answer_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "question_answer_radio" */
export type question_answer_radio = {
  __typename: 'question_answer_radio';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  question_answer: question_answer;
  question_answer_id: Scalars['Int']['output'];
  /** An object relationship */
  question_option: question_option;
  question_option_id: Scalars['Int']['output'];
  /** An object relationship */
  question_option_version: question_option_version;
  question_option_version_id: Scalars['Int']['output'];
};

/** aggregated selection of "question_answer_radio" */
export type question_answer_radio_aggregate = {
  __typename: 'question_answer_radio_aggregate';
  aggregate?: Maybe<question_answer_radio_aggregate_fields>;
  nodes: Array<question_answer_radio>;
};

export type question_answer_radio_aggregate_bool_exp = {
  count?: InputMaybe<question_answer_radio_aggregate_bool_exp_count>;
};

export type question_answer_radio_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_answer_radio_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_answer_radio_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_answer_radio" */
export type question_answer_radio_aggregate_fields = {
  __typename: 'question_answer_radio_aggregate_fields';
  avg?: Maybe<question_answer_radio_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_answer_radio_max_fields>;
  min?: Maybe<question_answer_radio_min_fields>;
  stddev?: Maybe<question_answer_radio_stddev_fields>;
  stddev_pop?: Maybe<question_answer_radio_stddev_pop_fields>;
  stddev_samp?: Maybe<question_answer_radio_stddev_samp_fields>;
  sum?: Maybe<question_answer_radio_sum_fields>;
  var_pop?: Maybe<question_answer_radio_var_pop_fields>;
  var_samp?: Maybe<question_answer_radio_var_samp_fields>;
  variance?: Maybe<question_answer_radio_variance_fields>;
};


/** aggregate fields of "question_answer_radio" */
export type question_answer_radio_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_answer_radio_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_answer_radio" */
export type question_answer_radio_aggregate_order_by = {
  avg?: InputMaybe<question_answer_radio_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_answer_radio_max_order_by>;
  min?: InputMaybe<question_answer_radio_min_order_by>;
  stddev?: InputMaybe<question_answer_radio_stddev_order_by>;
  stddev_pop?: InputMaybe<question_answer_radio_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_answer_radio_stddev_samp_order_by>;
  sum?: InputMaybe<question_answer_radio_sum_order_by>;
  var_pop?: InputMaybe<question_answer_radio_var_pop_order_by>;
  var_samp?: InputMaybe<question_answer_radio_var_samp_order_by>;
  variance?: InputMaybe<question_answer_radio_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_answer_radio" */
export type question_answer_radio_arr_rel_insert_input = {
  data: Array<question_answer_radio_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_radio_on_conflict>;
};

/** aggregate avg on columns */
export type question_answer_radio_avg_fields = {
  __typename: 'question_answer_radio_avg_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_answer_radio" */
export type question_answer_radio_avg_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_answer_radio". All fields are combined with a logical 'AND'. */
export type question_answer_radio_bool_exp = {
  _and?: InputMaybe<Array<question_answer_radio_bool_exp>>;
  _not?: InputMaybe<question_answer_radio_bool_exp>;
  _or?: InputMaybe<Array<question_answer_radio_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  question_answer?: InputMaybe<question_answer_bool_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  question_option_version?: InputMaybe<question_option_version_bool_exp>;
  question_option_version_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "question_answer_radio" */
export enum question_answer_radio_constraint {
  /** unique or primary key constraint on columns "question_answer_id", "question_option_id" */
  question_answer_radio_pkey = 'question_answer_radio_pkey'
}

/** input type for incrementing numeric columns in table "question_answer_radio" */
export type question_answer_radio_inc_input = {
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_answer_radio" */
export type question_answer_radio_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer?: InputMaybe<question_answer_obj_rel_insert_input>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_version?: InputMaybe<question_option_version_obj_rel_insert_input>;
  question_option_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_answer_radio_max_fields = {
  __typename: 'question_answer_radio_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_option_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_answer_radio" */
export type question_answer_radio_max_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_answer_radio_min_fields = {
  __typename: 'question_answer_radio_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_option_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_answer_radio" */
export type question_answer_radio_min_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_answer_radio" */
export type question_answer_radio_mutation_response = {
  __typename: 'question_answer_radio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_answer_radio>;
};

/** on_conflict condition type for table "question_answer_radio" */
export type question_answer_radio_on_conflict = {
  constraint: question_answer_radio_constraint;
  update_columns?: Array<question_answer_radio_update_column>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};

/** Ordering options when selecting data from "question_answer_radio". */
export type question_answer_radio_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer?: InputMaybe<question_answer_order_by>;
  question_answer_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version?: InputMaybe<question_option_version_order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_answer_radio */
export type question_answer_radio_pk_columns_input = {
  question_answer_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};

/** select columns of table "question_answer_radio" */
export enum question_answer_radio_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  question_option_version_id = 'question_option_version_id'
}

/** input type for updating data in table "question_answer_radio" */
export type question_answer_radio_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type question_answer_radio_stddev_fields = {
  __typename: 'question_answer_radio_stddev_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_answer_radio" */
export type question_answer_radio_stddev_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_answer_radio_stddev_pop_fields = {
  __typename: 'question_answer_radio_stddev_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_answer_radio" */
export type question_answer_radio_stddev_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_answer_radio_stddev_samp_fields = {
  __typename: 'question_answer_radio_stddev_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_answer_radio" */
export type question_answer_radio_stddev_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_answer_radio" */
export type question_answer_radio_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_answer_radio_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_answer_radio_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_answer_radio_sum_fields = {
  __typename: 'question_answer_radio_sum_fields';
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_option_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_answer_radio" */
export type question_answer_radio_sum_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** update columns of table "question_answer_radio" */
export enum question_answer_radio_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  question_option_version_id = 'question_option_version_id'
}

export type question_answer_radio_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_answer_radio_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_answer_radio_set_input>;
  /** filter the rows which have to be updated */
  where: question_answer_radio_bool_exp;
};

/** aggregate var_pop on columns */
export type question_answer_radio_var_pop_fields = {
  __typename: 'question_answer_radio_var_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_answer_radio" */
export type question_answer_radio_var_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_answer_radio_var_samp_fields = {
  __typename: 'question_answer_radio_var_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_answer_radio" */
export type question_answer_radio_var_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_answer_radio_variance_fields = {
  __typename: 'question_answer_radio_variance_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_option_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_answer_radio" */
export type question_answer_radio_variance_order_by = {
  question_answer_id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_option_version_id?: InputMaybe<order_by>;
};

/** select columns of table "question_answer" */
export enum question_answer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_version_id = 'question_version_id'
}

/** columns and relationships of "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase = {
  __typename: 'question_answer_sentiment_phrase';
  begin_offset: Scalars['Int']['output'];
  end_offset: Scalars['Int']['output'];
  question_answer_id: Scalars['Int']['output'];
  /** An object relationship */
  question_answer_text: question_answer_text;
  score: Scalars['numeric']['output'];
  /** An object relationship */
  sentiment_phrase: sentiment_phrase;
  sentiment_phrase_id: Scalars['Int']['output'];
};

/** aggregated selection of "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_aggregate = {
  __typename: 'question_answer_sentiment_phrase_aggregate';
  aggregate?: Maybe<question_answer_sentiment_phrase_aggregate_fields>;
  nodes: Array<question_answer_sentiment_phrase>;
};

export type question_answer_sentiment_phrase_aggregate_bool_exp = {
  count?: InputMaybe<question_answer_sentiment_phrase_aggregate_bool_exp_count>;
};

export type question_answer_sentiment_phrase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_aggregate_fields = {
  __typename: 'question_answer_sentiment_phrase_aggregate_fields';
  avg?: Maybe<question_answer_sentiment_phrase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_answer_sentiment_phrase_max_fields>;
  min?: Maybe<question_answer_sentiment_phrase_min_fields>;
  stddev?: Maybe<question_answer_sentiment_phrase_stddev_fields>;
  stddev_pop?: Maybe<question_answer_sentiment_phrase_stddev_pop_fields>;
  stddev_samp?: Maybe<question_answer_sentiment_phrase_stddev_samp_fields>;
  sum?: Maybe<question_answer_sentiment_phrase_sum_fields>;
  var_pop?: Maybe<question_answer_sentiment_phrase_var_pop_fields>;
  var_samp?: Maybe<question_answer_sentiment_phrase_var_samp_fields>;
  variance?: Maybe<question_answer_sentiment_phrase_variance_fields>;
};


/** aggregate fields of "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_aggregate_order_by = {
  avg?: InputMaybe<question_answer_sentiment_phrase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_answer_sentiment_phrase_max_order_by>;
  min?: InputMaybe<question_answer_sentiment_phrase_min_order_by>;
  stddev?: InputMaybe<question_answer_sentiment_phrase_stddev_order_by>;
  stddev_pop?: InputMaybe<question_answer_sentiment_phrase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_answer_sentiment_phrase_stddev_samp_order_by>;
  sum?: InputMaybe<question_answer_sentiment_phrase_sum_order_by>;
  var_pop?: InputMaybe<question_answer_sentiment_phrase_var_pop_order_by>;
  var_samp?: InputMaybe<question_answer_sentiment_phrase_var_samp_order_by>;
  variance?: InputMaybe<question_answer_sentiment_phrase_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_arr_rel_insert_input = {
  data: Array<question_answer_sentiment_phrase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_sentiment_phrase_on_conflict>;
};

/** aggregate avg on columns */
export type question_answer_sentiment_phrase_avg_fields = {
  __typename: 'question_answer_sentiment_phrase_avg_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_avg_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_answer_sentiment_phrase". All fields are combined with a logical 'AND'. */
export type question_answer_sentiment_phrase_bool_exp = {
  _and?: InputMaybe<Array<question_answer_sentiment_phrase_bool_exp>>;
  _not?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
  _or?: InputMaybe<Array<question_answer_sentiment_phrase_bool_exp>>;
  begin_offset?: InputMaybe<Int_comparison_exp>;
  end_offset?: InputMaybe<Int_comparison_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  question_answer_text?: InputMaybe<question_answer_text_bool_exp>;
  score?: InputMaybe<numeric_comparison_exp>;
  sentiment_phrase?: InputMaybe<sentiment_phrase_bool_exp>;
  sentiment_phrase_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "question_answer_sentiment_phrase" */
export enum question_answer_sentiment_phrase_constraint {
  /** unique or primary key constraint on columns "question_answer_id", "begin_offset", "sentiment_phrase_id" */
  question_answer_sentiment_phrase_pkey = 'question_answer_sentiment_phrase_pkey'
}

/** input type for incrementing numeric columns in table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_inc_input = {
  begin_offset?: InputMaybe<Scalars['Int']['input']>;
  end_offset?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['numeric']['input']>;
  sentiment_phrase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_insert_input = {
  begin_offset?: InputMaybe<Scalars['Int']['input']>;
  end_offset?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  question_answer_text?: InputMaybe<question_answer_text_obj_rel_insert_input>;
  score?: InputMaybe<Scalars['numeric']['input']>;
  sentiment_phrase?: InputMaybe<sentiment_phrase_obj_rel_insert_input>;
  sentiment_phrase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_answer_sentiment_phrase_max_fields = {
  __typename: 'question_answer_sentiment_phrase_max_fields';
  begin_offset?: Maybe<Scalars['Int']['output']>;
  end_offset?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['numeric']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_max_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_answer_sentiment_phrase_min_fields = {
  __typename: 'question_answer_sentiment_phrase_min_fields';
  begin_offset?: Maybe<Scalars['Int']['output']>;
  end_offset?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['numeric']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_min_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_mutation_response = {
  __typename: 'question_answer_sentiment_phrase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_answer_sentiment_phrase>;
};

/** on_conflict condition type for table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_on_conflict = {
  constraint: question_answer_sentiment_phrase_constraint;
  update_columns?: Array<question_answer_sentiment_phrase_update_column>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};

/** Ordering options when selecting data from "question_answer_sentiment_phrase". */
export type question_answer_sentiment_phrase_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  question_answer_text?: InputMaybe<question_answer_text_order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase?: InputMaybe<sentiment_phrase_order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_answer_sentiment_phrase */
export type question_answer_sentiment_phrase_pk_columns_input = {
  begin_offset: Scalars['Int']['input'];
  question_answer_id: Scalars['Int']['input'];
  sentiment_phrase_id: Scalars['Int']['input'];
};

/** select columns of table "question_answer_sentiment_phrase" */
export enum question_answer_sentiment_phrase_select_column {
  /** column name */
  begin_offset = 'begin_offset',
  /** column name */
  end_offset = 'end_offset',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  score = 'score',
  /** column name */
  sentiment_phrase_id = 'sentiment_phrase_id'
}

/** input type for updating data in table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_set_input = {
  begin_offset?: InputMaybe<Scalars['Int']['input']>;
  end_offset?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['numeric']['input']>;
  sentiment_phrase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type question_answer_sentiment_phrase_stddev_fields = {
  __typename: 'question_answer_sentiment_phrase_stddev_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_stddev_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_answer_sentiment_phrase_stddev_pop_fields = {
  __typename: 'question_answer_sentiment_phrase_stddev_pop_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_stddev_pop_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_answer_sentiment_phrase_stddev_samp_fields = {
  __typename: 'question_answer_sentiment_phrase_stddev_samp_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_stddev_samp_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_answer_sentiment_phrase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_answer_sentiment_phrase_stream_cursor_value_input = {
  begin_offset?: InputMaybe<Scalars['Int']['input']>;
  end_offset?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  score?: InputMaybe<Scalars['numeric']['input']>;
  sentiment_phrase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_answer_sentiment_phrase_sum_fields = {
  __typename: 'question_answer_sentiment_phrase_sum_fields';
  begin_offset?: Maybe<Scalars['Int']['output']>;
  end_offset?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['numeric']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_sum_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** update columns of table "question_answer_sentiment_phrase" */
export enum question_answer_sentiment_phrase_update_column {
  /** column name */
  begin_offset = 'begin_offset',
  /** column name */
  end_offset = 'end_offset',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  score = 'score',
  /** column name */
  sentiment_phrase_id = 'sentiment_phrase_id'
}

export type question_answer_sentiment_phrase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_answer_sentiment_phrase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_answer_sentiment_phrase_set_input>;
  /** filter the rows which have to be updated */
  where: question_answer_sentiment_phrase_bool_exp;
};

/** aggregate var_pop on columns */
export type question_answer_sentiment_phrase_var_pop_fields = {
  __typename: 'question_answer_sentiment_phrase_var_pop_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_var_pop_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_answer_sentiment_phrase_var_samp_fields = {
  __typename: 'question_answer_sentiment_phrase_var_samp_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_var_samp_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_answer_sentiment_phrase_variance_fields = {
  __typename: 'question_answer_sentiment_phrase_variance_fields';
  begin_offset?: Maybe<Scalars['Float']['output']>;
  end_offset?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_answer_sentiment_phrase" */
export type question_answer_sentiment_phrase_variance_order_by = {
  begin_offset?: InputMaybe<order_by>;
  end_offset?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  score?: InputMaybe<order_by>;
  sentiment_phrase_id?: InputMaybe<order_by>;
};

/** input type for updating data in table "question_answer" */
export type question_answer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type question_answer_stddev_fields = {
  __typename: 'question_answer_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type question_answer_stddev_pop_fields = {
  __typename: 'question_answer_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type question_answer_stddev_samp_fields = {
  __typename: 'question_answer_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "question_answer" */
export type question_answer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_answer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_answer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_answer_sum_fields = {
  __typename: 'question_answer_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "question_answer_text" */
export type question_answer_text = {
  __typename: 'question_answer_text';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  question_answer: question_answer;
  question_answer_id: Scalars['Int']['output'];
  sentiment?: Maybe<sentiment_enum>;
  sentiment_score?: Maybe<Scalars['jsonb']['output']>;
  value: Scalars['String']['output'];
};


/** columns and relationships of "question_answer_text" */
export type question_answer_textsentiment_scoreArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "question_answer_text" */
export type question_answer_text_aggregate = {
  __typename: 'question_answer_text_aggregate';
  aggregate?: Maybe<question_answer_text_aggregate_fields>;
  nodes: Array<question_answer_text>;
};

export type question_answer_text_aggregate_bool_exp = {
  count?: InputMaybe<question_answer_text_aggregate_bool_exp_count>;
};

export type question_answer_text_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_answer_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_answer_text_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_answer_text" */
export type question_answer_text_aggregate_fields = {
  __typename: 'question_answer_text_aggregate_fields';
  avg?: Maybe<question_answer_text_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_answer_text_max_fields>;
  min?: Maybe<question_answer_text_min_fields>;
  stddev?: Maybe<question_answer_text_stddev_fields>;
  stddev_pop?: Maybe<question_answer_text_stddev_pop_fields>;
  stddev_samp?: Maybe<question_answer_text_stddev_samp_fields>;
  sum?: Maybe<question_answer_text_sum_fields>;
  var_pop?: Maybe<question_answer_text_var_pop_fields>;
  var_samp?: Maybe<question_answer_text_var_samp_fields>;
  variance?: Maybe<question_answer_text_variance_fields>;
};


/** aggregate fields of "question_answer_text" */
export type question_answer_text_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_answer_text_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_answer_text" */
export type question_answer_text_aggregate_order_by = {
  avg?: InputMaybe<question_answer_text_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_answer_text_max_order_by>;
  min?: InputMaybe<question_answer_text_min_order_by>;
  stddev?: InputMaybe<question_answer_text_stddev_order_by>;
  stddev_pop?: InputMaybe<question_answer_text_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_answer_text_stddev_samp_order_by>;
  sum?: InputMaybe<question_answer_text_sum_order_by>;
  var_pop?: InputMaybe<question_answer_text_var_pop_order_by>;
  var_samp?: InputMaybe<question_answer_text_var_samp_order_by>;
  variance?: InputMaybe<question_answer_text_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type question_answer_text_append_input = {
  sentiment_score?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "question_answer_text" */
export type question_answer_text_arr_rel_insert_input = {
  data: Array<question_answer_text_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_text_on_conflict>;
};

/** aggregate avg on columns */
export type question_answer_text_avg_fields = {
  __typename: 'question_answer_text_avg_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_answer_text" */
export type question_answer_text_avg_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_answer_text". All fields are combined with a logical 'AND'. */
export type question_answer_text_bool_exp = {
  _and?: InputMaybe<Array<question_answer_text_bool_exp>>;
  _not?: InputMaybe<question_answer_text_bool_exp>;
  _or?: InputMaybe<Array<question_answer_text_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  question_answer?: InputMaybe<question_answer_bool_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  sentiment?: InputMaybe<sentiment_enum_comparison_exp>;
  sentiment_score?: InputMaybe<jsonb_comparison_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "question_answer_text" */
export enum question_answer_text_constraint {
  /** unique or primary key constraint on columns "question_answer_id" */
  question_answer_text_pkey = 'question_answer_text_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type question_answer_text_delete_at_path_input = {
  sentiment_score?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type question_answer_text_delete_elem_input = {
  sentiment_score?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type question_answer_text_delete_key_input = {
  sentiment_score?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "question_answer_text" */
export type question_answer_text_inc_input = {
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_answer_text" */
export type question_answer_text_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer?: InputMaybe<question_answer_obj_rel_insert_input>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  sentiment?: InputMaybe<sentiment_enum>;
  sentiment_score?: InputMaybe<Scalars['jsonb']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type question_answer_text_max_fields = {
  __typename: 'question_answer_text_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "question_answer_text" */
export type question_answer_text_max_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_answer_text_min_fields = {
  __typename: 'question_answer_text_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "question_answer_text" */
export type question_answer_text_min_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_answer_text" */
export type question_answer_text_mutation_response = {
  __typename: 'question_answer_text_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_answer_text>;
};

/** input type for inserting object relation for remote table "question_answer_text" */
export type question_answer_text_obj_rel_insert_input = {
  data: question_answer_text_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_answer_text_on_conflict>;
};

/** on_conflict condition type for table "question_answer_text" */
export type question_answer_text_on_conflict = {
  constraint: question_answer_text_constraint;
  update_columns?: Array<question_answer_text_update_column>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};

/** Ordering options when selecting data from "question_answer_text". */
export type question_answer_text_order_by = {
  created_at?: InputMaybe<order_by>;
  question_answer?: InputMaybe<question_answer_order_by>;
  question_answer_id?: InputMaybe<order_by>;
  sentiment?: InputMaybe<order_by>;
  sentiment_score?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_answer_text */
export type question_answer_text_pk_columns_input = {
  question_answer_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type question_answer_text_prepend_input = {
  sentiment_score?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "question_answer_text" */
export enum question_answer_text_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  sentiment = 'sentiment',
  /** column name */
  sentiment_score = 'sentiment_score',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_answer_text" */
export type question_answer_text_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  sentiment?: InputMaybe<sentiment_enum>;
  sentiment_score?: InputMaybe<Scalars['jsonb']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type question_answer_text_stddev_fields = {
  __typename: 'question_answer_text_stddev_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_answer_text" */
export type question_answer_text_stddev_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_answer_text_stddev_pop_fields = {
  __typename: 'question_answer_text_stddev_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_answer_text" */
export type question_answer_text_stddev_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_answer_text_stddev_samp_fields = {
  __typename: 'question_answer_text_stddev_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_answer_text" */
export type question_answer_text_stddev_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_answer_text" */
export type question_answer_text_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_answer_text_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_answer_text_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  sentiment?: InputMaybe<sentiment_enum>;
  sentiment_score?: InputMaybe<Scalars['jsonb']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type question_answer_text_sum_fields = {
  __typename: 'question_answer_text_sum_fields';
  question_answer_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_answer_text" */
export type question_answer_text_sum_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** update columns of table "question_answer_text" */
export enum question_answer_text_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  sentiment = 'sentiment',
  /** column name */
  sentiment_score = 'sentiment_score',
  /** column name */
  value = 'value'
}

export type question_answer_text_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<question_answer_text_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<question_answer_text_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<question_answer_text_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<question_answer_text_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_answer_text_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<question_answer_text_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_answer_text_set_input>;
  /** filter the rows which have to be updated */
  where: question_answer_text_bool_exp;
};

/** aggregate var_pop on columns */
export type question_answer_text_var_pop_fields = {
  __typename: 'question_answer_text_var_pop_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_answer_text" */
export type question_answer_text_var_pop_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_answer_text_var_samp_fields = {
  __typename: 'question_answer_text_var_samp_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_answer_text" */
export type question_answer_text_var_samp_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_answer_text_variance_fields = {
  __typename: 'question_answer_text_variance_fields';
  question_answer_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_answer_text" */
export type question_answer_text_variance_order_by = {
  question_answer_id?: InputMaybe<order_by>;
};

/** update columns of table "question_answer" */
export enum question_answer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_version_id = 'question_version_id'
}

export type question_answer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_answer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_answer_set_input>;
  /** filter the rows which have to be updated */
  where: question_answer_bool_exp;
};

/** aggregate var_pop on columns */
export type question_answer_var_pop_fields = {
  __typename: 'question_answer_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type question_answer_var_samp_fields = {
  __typename: 'question_answer_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type question_answer_variance_fields = {
  __typename: 'question_answer_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting array relation for remote table "question" */
export type question_arr_rel_insert_input = {
  data: Array<question_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_on_conflict>;
};

/** aggregate avg on columns */
export type question_avg_fields = {
  __typename: 'question_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question" */
export type question_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question". All fields are combined with a logical 'AND'. */
export type question_bool_exp = {
  _and?: InputMaybe<Array<question_bool_exp>>;
  _not?: InputMaybe<question_bool_exp>;
  _or?: InputMaybe<Array<question_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<question_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  flow_step_questions?: InputMaybe<flow_step_question_bool_exp>;
  flow_step_questions_aggregate?: InputMaybe<flow_step_question_aggregate_bool_exp>;
  form_version_questions?: InputMaybe<form_version_question_bool_exp>;
  form_version_questions_aggregate?: InputMaybe<form_version_question_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  primary?: InputMaybe<Boolean_comparison_exp>;
  published_version?: InputMaybe<question_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  question_options?: InputMaybe<question_option_bool_exp>;
  question_options_aggregate?: InputMaybe<question_option_aggregate_bool_exp>;
  question_settings?: InputMaybe<question_setting_bool_exp>;
  question_settings_aggregate?: InputMaybe<question_setting_aggregate_bool_exp>;
  question_type?: InputMaybe<question_type_bool_exp>;
  question_versions?: InputMaybe<question_version_bool_exp>;
  question_versions_aggregate?: InputMaybe<question_version_aggregate_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  type?: InputMaybe<question_type_enum_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "question" */
export enum question_constraint {
  /** unique or primary key constraint on columns "id" */
  survey_question_pkey = 'survey_question_pkey',
  /** unique or primary key constraint on columns "token" */
  survey_question_token_key = 'survey_question_token_key'
}

/** input type for incrementing numeric columns in table "question" */
export type question_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question" */
export type question_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<question_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_questions?: InputMaybe<flow_step_question_arr_rel_insert_input>;
  form_version_questions?: InputMaybe<form_version_question_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  published_version?: InputMaybe<question_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_options?: InputMaybe<question_option_arr_rel_insert_input>;
  question_settings?: InputMaybe<question_setting_arr_rel_insert_input>;
  question_type?: InputMaybe<question_type_obj_rel_insert_input>;
  question_versions?: InputMaybe<question_version_arr_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type question_max_fields = {
  __typename: 'question_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "question" */
export type question_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_min_fields = {
  __typename: 'question_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "question" */
export type question_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question" */
export type question_mutation_response = {
  __typename: 'question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question>;
};

/** input type for inserting object relation for remote table "question" */
export type question_obj_rel_insert_input = {
  data: question_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_on_conflict>;
};

/** on_conflict condition type for table "question" */
export type question_on_conflict = {
  constraint: question_constraint;
  update_columns?: Array<question_update_column>;
  where?: InputMaybe<question_bool_exp>;
};

/** columns and relationships of "question_option" */
export type question_option = {
  __typename: 'question_option';
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  draft_version?: Maybe<question_option_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  published_version?: Maybe<question_option_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question: question;
  /** An array relationship */
  question_answer_radios: Array<question_answer_radio>;
  /** An aggregate relationship */
  question_answer_radios_aggregate: question_answer_radio_aggregate;
  question_id: Scalars['Int']['output'];
  /** An array relationship */
  question_option_versions: Array<question_option_version>;
  /** An aggregate relationship */
  question_option_versions_aggregate: question_option_version_aggregate;
  reason_code?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "question_option" */
export type question_optionquestion_answer_radiosArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


/** columns and relationships of "question_option" */
export type question_optionquestion_answer_radios_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


/** columns and relationships of "question_option" */
export type question_optionquestion_option_versionsArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


/** columns and relationships of "question_option" */
export type question_optionquestion_option_versions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};

/** aggregated selection of "question_option" */
export type question_option_aggregate = {
  __typename: 'question_option_aggregate';
  aggregate?: Maybe<question_option_aggregate_fields>;
  nodes: Array<question_option>;
};

export type question_option_aggregate_bool_exp = {
  count?: InputMaybe<question_option_aggregate_bool_exp_count>;
};

export type question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_option" */
export type question_option_aggregate_fields = {
  __typename: 'question_option_aggregate_fields';
  avg?: Maybe<question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_option_max_fields>;
  min?: Maybe<question_option_min_fields>;
  stddev?: Maybe<question_option_stddev_fields>;
  stddev_pop?: Maybe<question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<question_option_stddev_samp_fields>;
  sum?: Maybe<question_option_sum_fields>;
  var_pop?: Maybe<question_option_var_pop_fields>;
  var_samp?: Maybe<question_option_var_samp_fields>;
  variance?: Maybe<question_option_variance_fields>;
};


/** aggregate fields of "question_option" */
export type question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_option" */
export type question_option_aggregate_order_by = {
  avg?: InputMaybe<question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_option_max_order_by>;
  min?: InputMaybe<question_option_min_order_by>;
  stddev?: InputMaybe<question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_option_stddev_samp_order_by>;
  sum?: InputMaybe<question_option_sum_order_by>;
  var_pop?: InputMaybe<question_option_var_pop_order_by>;
  var_samp?: InputMaybe<question_option_var_samp_order_by>;
  variance?: InputMaybe<question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_option" */
export type question_option_arr_rel_insert_input = {
  data: Array<question_option_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_option_on_conflict>;
};

/** aggregate avg on columns */
export type question_option_avg_fields = {
  __typename: 'question_option_avg_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_option" */
export type question_option_avg_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_option". All fields are combined with a logical 'AND'. */
export type question_option_bool_exp = {
  _and?: InputMaybe<Array<question_option_bool_exp>>;
  _not?: InputMaybe<question_option_bool_exp>;
  _or?: InputMaybe<Array<question_option_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<question_option_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  published_version?: InputMaybe<question_option_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_answer_radios?: InputMaybe<question_answer_radio_bool_exp>;
  question_answer_radios_aggregate?: InputMaybe<question_answer_radio_aggregate_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_option_versions?: InputMaybe<question_option_version_bool_exp>;
  question_option_versions_aggregate?: InputMaybe<question_option_version_aggregate_bool_exp>;
  reason_code?: InputMaybe<String_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "question_option" */
export enum question_option_constraint {
  /** unique or primary key constraint on columns "id" */
  survey_question_option_pkey = 'survey_question_option_pkey',
  /** unique or primary key constraint on columns "token" */
  survey_question_option_token_key = 'survey_question_option_token_key'
}

/** input type for incrementing numeric columns in table "question_option" */
export type question_option_inc_input = {
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_option" */
export type question_option_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<question_option_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version?: InputMaybe<question_option_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_answer_radios?: InputMaybe<question_answer_radio_arr_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_versions?: InputMaybe<question_option_version_arr_rel_insert_input>;
  reason_code?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type question_option_max_fields = {
  __typename: 'question_option_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  reason_code?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "question_option" */
export type question_option_max_order_by = {
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  reason_code?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_option_min_fields = {
  __typename: 'question_option_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  reason_code?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "question_option" */
export type question_option_min_order_by = {
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  reason_code?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_option" */
export type question_option_mutation_response = {
  __typename: 'question_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_option>;
};

/** input type for inserting object relation for remote table "question_option" */
export type question_option_obj_rel_insert_input = {
  data: question_option_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_option_on_conflict>;
};

/** on_conflict condition type for table "question_option" */
export type question_option_on_conflict = {
  constraint: question_option_constraint;
  update_columns?: Array<question_option_update_column>;
  where?: InputMaybe<question_option_bool_exp>;
};

/** Ordering options when selecting data from "question_option". */
export type question_option_order_by = {
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<question_option_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version?: InputMaybe<question_option_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_answer_radios_aggregate?: InputMaybe<question_answer_radio_aggregate_order_by>;
  question_id?: InputMaybe<order_by>;
  question_option_versions_aggregate?: InputMaybe<question_option_version_aggregate_order_by>;
  reason_code?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_option */
export type question_option_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "question_option" */
export enum question_option_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  reason_code = 'reason_code',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "question_option" */
export type question_option_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  reason_code?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type question_option_stddev_fields = {
  __typename: 'question_option_stddev_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_option" */
export type question_option_stddev_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_option_stddev_pop_fields = {
  __typename: 'question_option_stddev_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_option" */
export type question_option_stddev_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_option_stddev_samp_fields = {
  __typename: 'question_option_stddev_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_option" */
export type question_option_stddev_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_option" */
export type question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_option_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  reason_code?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type question_option_sum_fields = {
  __typename: 'question_option_sum_fields';
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_option" */
export type question_option_sum_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** update columns of table "question_option" */
export enum question_option_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  reason_code = 'reason_code',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type question_option_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_option_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_option_set_input>;
  /** filter the rows which have to be updated */
  where: question_option_bool_exp;
};

/** aggregate var_pop on columns */
export type question_option_var_pop_fields = {
  __typename: 'question_option_var_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_option" */
export type question_option_var_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_option_var_samp_fields = {
  __typename: 'question_option_var_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_option" */
export type question_option_var_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_option_variance_fields = {
  __typename: 'question_option_variance_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_option" */
export type question_option_variance_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** columns and relationships of "question_option_version" */
export type question_option_version = {
  __typename: 'question_option_version';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  question_option: question_option;
  question_option_id: Scalars['Int']['output'];
  /** An array relationship */
  question_options: Array<question_option>;
  /** An aggregate relationship */
  question_options_aggregate: question_option_aggregate;
  /** An object relationship */
  title_translation: translation;
  title_translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "question_option_version" */
export type question_option_versionquestion_optionsArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


/** columns and relationships of "question_option_version" */
export type question_option_versionquestion_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};

/** aggregated selection of "question_option_version" */
export type question_option_version_aggregate = {
  __typename: 'question_option_version_aggregate';
  aggregate?: Maybe<question_option_version_aggregate_fields>;
  nodes: Array<question_option_version>;
};

export type question_option_version_aggregate_bool_exp = {
  count?: InputMaybe<question_option_version_aggregate_bool_exp_count>;
};

export type question_option_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_option_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_option_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_option_version" */
export type question_option_version_aggregate_fields = {
  __typename: 'question_option_version_aggregate_fields';
  avg?: Maybe<question_option_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_option_version_max_fields>;
  min?: Maybe<question_option_version_min_fields>;
  stddev?: Maybe<question_option_version_stddev_fields>;
  stddev_pop?: Maybe<question_option_version_stddev_pop_fields>;
  stddev_samp?: Maybe<question_option_version_stddev_samp_fields>;
  sum?: Maybe<question_option_version_sum_fields>;
  var_pop?: Maybe<question_option_version_var_pop_fields>;
  var_samp?: Maybe<question_option_version_var_samp_fields>;
  variance?: Maybe<question_option_version_variance_fields>;
};


/** aggregate fields of "question_option_version" */
export type question_option_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_option_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_option_version" */
export type question_option_version_aggregate_order_by = {
  avg?: InputMaybe<question_option_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_option_version_max_order_by>;
  min?: InputMaybe<question_option_version_min_order_by>;
  stddev?: InputMaybe<question_option_version_stddev_order_by>;
  stddev_pop?: InputMaybe<question_option_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_option_version_stddev_samp_order_by>;
  sum?: InputMaybe<question_option_version_sum_order_by>;
  var_pop?: InputMaybe<question_option_version_var_pop_order_by>;
  var_samp?: InputMaybe<question_option_version_var_samp_order_by>;
  variance?: InputMaybe<question_option_version_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_option_version" */
export type question_option_version_arr_rel_insert_input = {
  data: Array<question_option_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_option_version_on_conflict>;
};

/** aggregate avg on columns */
export type question_option_version_avg_fields = {
  __typename: 'question_option_version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_option_version" */
export type question_option_version_avg_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_option_version". All fields are combined with a logical 'AND'. */
export type question_option_version_bool_exp = {
  _and?: InputMaybe<Array<question_option_version_bool_exp>>;
  _not?: InputMaybe<question_option_version_bool_exp>;
  _or?: InputMaybe<Array<question_option_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  question_options?: InputMaybe<question_option_bool_exp>;
  question_options_aggregate?: InputMaybe<question_option_aggregate_bool_exp>;
  title_translation?: InputMaybe<translation_bool_exp>;
  title_translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "question_option_version" */
export enum question_option_version_constraint {
  /** unique or primary key constraint on columns "id" */
  survey_question_option_version_pkey = 'survey_question_option_version_pkey'
}

/** input type for incrementing numeric columns in table "question_option_version" */
export type question_option_version_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_option_version" */
export type question_option_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_options?: InputMaybe<question_option_arr_rel_insert_input>;
  title_translation?: InputMaybe<translation_obj_rel_insert_input>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type question_option_version_max_fields = {
  __typename: 'question_option_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "question_option_version" */
export type question_option_version_max_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_option_version_min_fields = {
  __typename: 'question_option_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "question_option_version" */
export type question_option_version_min_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_option_version" */
export type question_option_version_mutation_response = {
  __typename: 'question_option_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_option_version>;
};

/** input type for inserting object relation for remote table "question_option_version" */
export type question_option_version_obj_rel_insert_input = {
  data: question_option_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_option_version_on_conflict>;
};

/** on_conflict condition type for table "question_option_version" */
export type question_option_version_on_conflict = {
  constraint: question_option_version_constraint;
  update_columns?: Array<question_option_version_update_column>;
  where?: InputMaybe<question_option_version_bool_exp>;
};

/** Ordering options when selecting data from "question_option_version". */
export type question_option_version_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_options_aggregate?: InputMaybe<question_option_aggregate_order_by>;
  title_translation?: InputMaybe<translation_order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_option_version */
export type question_option_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "question_option_version" */
export enum question_option_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "question_option_version" */
export type question_option_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type question_option_version_stddev_fields = {
  __typename: 'question_option_version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_option_version" */
export type question_option_version_stddev_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_option_version_stddev_pop_fields = {
  __typename: 'question_option_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_option_version" */
export type question_option_version_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_option_version_stddev_samp_fields = {
  __typename: 'question_option_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_option_version" */
export type question_option_version_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_option_version" */
export type question_option_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_option_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_option_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type question_option_version_sum_fields = {
  __typename: 'question_option_version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_option_version" */
export type question_option_version_sum_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** update columns of table "question_option_version" */
export enum question_option_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type question_option_version_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_option_version_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_option_version_set_input>;
  /** filter the rows which have to be updated */
  where: question_option_version_bool_exp;
};

/** aggregate var_pop on columns */
export type question_option_version_var_pop_fields = {
  __typename: 'question_option_version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_option_version" */
export type question_option_version_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_option_version_var_samp_fields = {
  __typename: 'question_option_version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_option_version" */
export type question_option_version_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_option_version_variance_fields = {
  __typename: 'question_option_version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_option_version" */
export type question_option_version_variance_order_by = {
  id?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "question". */
export type question_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<question_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  flow_step_questions_aggregate?: InputMaybe<flow_step_question_aggregate_order_by>;
  form_version_questions_aggregate?: InputMaybe<form_version_question_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  primary?: InputMaybe<order_by>;
  published_version?: InputMaybe<question_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_options_aggregate?: InputMaybe<question_option_aggregate_order_by>;
  question_settings_aggregate?: InputMaybe<question_setting_aggregate_order_by>;
  question_type?: InputMaybe<question_type_order_by>;
  question_versions_aggregate?: InputMaybe<question_version_aggregate_order_by>;
  token?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: question */
export type question_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "question" */
export enum question_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  primary = 'primary',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

/** select "question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "question" */
export enum question_select_column_question_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  primary = 'primary'
}

/** select "question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "question" */
export enum question_select_column_question_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  primary = 'primary'
}

/** input type for updating data in table "question" */
export type question_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "question_setting" */
export type question_setting = {
  __typename: 'question_setting';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  draft_version?: Maybe<question_setting_version>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  key: question_setting_key_enum;
  /** An object relationship */
  published_version?: Maybe<question_setting_version>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
  /** An object relationship */
  question_setting_key: question_setting_key;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "question_setting" */
export type question_setting_aggregate = {
  __typename: 'question_setting_aggregate';
  aggregate?: Maybe<question_setting_aggregate_fields>;
  nodes: Array<question_setting>;
};

export type question_setting_aggregate_bool_exp = {
  count?: InputMaybe<question_setting_aggregate_bool_exp_count>;
};

export type question_setting_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_setting_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_setting" */
export type question_setting_aggregate_fields = {
  __typename: 'question_setting_aggregate_fields';
  avg?: Maybe<question_setting_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_setting_max_fields>;
  min?: Maybe<question_setting_min_fields>;
  stddev?: Maybe<question_setting_stddev_fields>;
  stddev_pop?: Maybe<question_setting_stddev_pop_fields>;
  stddev_samp?: Maybe<question_setting_stddev_samp_fields>;
  sum?: Maybe<question_setting_sum_fields>;
  var_pop?: Maybe<question_setting_var_pop_fields>;
  var_samp?: Maybe<question_setting_var_samp_fields>;
  variance?: Maybe<question_setting_variance_fields>;
};


/** aggregate fields of "question_setting" */
export type question_setting_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_setting_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_setting" */
export type question_setting_aggregate_order_by = {
  avg?: InputMaybe<question_setting_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_setting_max_order_by>;
  min?: InputMaybe<question_setting_min_order_by>;
  stddev?: InputMaybe<question_setting_stddev_order_by>;
  stddev_pop?: InputMaybe<question_setting_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_setting_stddev_samp_order_by>;
  sum?: InputMaybe<question_setting_sum_order_by>;
  var_pop?: InputMaybe<question_setting_var_pop_order_by>;
  var_samp?: InputMaybe<question_setting_var_samp_order_by>;
  variance?: InputMaybe<question_setting_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_setting" */
export type question_setting_arr_rel_insert_input = {
  data: Array<question_setting_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_setting_on_conflict>;
};

/** aggregate avg on columns */
export type question_setting_avg_fields = {
  __typename: 'question_setting_avg_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_setting" */
export type question_setting_avg_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_setting". All fields are combined with a logical 'AND'. */
export type question_setting_bool_exp = {
  _and?: InputMaybe<Array<question_setting_bool_exp>>;
  _not?: InputMaybe<question_setting_bool_exp>;
  _or?: InputMaybe<Array<question_setting_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  draft_version?: InputMaybe<question_setting_version_bool_exp>;
  draft_version_id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<question_setting_key_enum_comparison_exp>;
  published_version?: InputMaybe<question_setting_version_bool_exp>;
  published_version_id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_setting_key?: InputMaybe<question_setting_key_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "question_setting" */
export enum question_setting_constraint {
  /** unique or primary key constraint on columns "key", "question_id" */
  survey_question_setting_pkey = 'survey_question_setting_pkey'
}

/** input type for incrementing numeric columns in table "question_setting" */
export type question_setting_inc_input = {
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_setting" */
export type question_setting_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version?: InputMaybe<question_setting_version_obj_rel_insert_input>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  published_version?: InputMaybe<question_setting_version_obj_rel_insert_input>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_setting_key?: InputMaybe<question_setting_key_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "question_setting_key" */
export type question_setting_key = {
  __typename: 'question_setting_key';
  value: Scalars['String']['output'];
};

/** aggregated selection of "question_setting_key" */
export type question_setting_key_aggregate = {
  __typename: 'question_setting_key_aggregate';
  aggregate?: Maybe<question_setting_key_aggregate_fields>;
  nodes: Array<question_setting_key>;
};

/** aggregate fields of "question_setting_key" */
export type question_setting_key_aggregate_fields = {
  __typename: 'question_setting_key_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<question_setting_key_max_fields>;
  min?: Maybe<question_setting_key_min_fields>;
};


/** aggregate fields of "question_setting_key" */
export type question_setting_key_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_setting_key_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "question_setting_key". All fields are combined with a logical 'AND'. */
export type question_setting_key_bool_exp = {
  _and?: InputMaybe<Array<question_setting_key_bool_exp>>;
  _not?: InputMaybe<question_setting_key_bool_exp>;
  _or?: InputMaybe<Array<question_setting_key_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "question_setting_key" */
export enum question_setting_key_constraint {
  /** unique or primary key constraint on columns "value" */
  survey_question_setting_key_pkey = 'survey_question_setting_key_pkey'
}

export enum question_setting_key_enum {
  other_specify = 'other_specify',
  randomize = 'randomize',
  select_multiple = 'select_multiple'
}

/** Boolean expression to compare columns of type "question_setting_key_enum". All fields are combined with logical 'AND'. */
export type question_setting_key_enum_comparison_exp = {
  _eq?: InputMaybe<question_setting_key_enum>;
  _in?: InputMaybe<Array<question_setting_key_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<question_setting_key_enum>;
  _nin?: InputMaybe<Array<question_setting_key_enum>>;
};

/** input type for inserting data into table "question_setting_key" */
export type question_setting_key_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type question_setting_key_max_fields = {
  __typename: 'question_setting_key_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type question_setting_key_min_fields = {
  __typename: 'question_setting_key_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "question_setting_key" */
export type question_setting_key_mutation_response = {
  __typename: 'question_setting_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_setting_key>;
};

/** input type for inserting object relation for remote table "question_setting_key" */
export type question_setting_key_obj_rel_insert_input = {
  data: question_setting_key_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_setting_key_on_conflict>;
};

/** on_conflict condition type for table "question_setting_key" */
export type question_setting_key_on_conflict = {
  constraint: question_setting_key_constraint;
  update_columns?: Array<question_setting_key_update_column>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};

/** Ordering options when selecting data from "question_setting_key". */
export type question_setting_key_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_setting_key */
export type question_setting_key_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "question_setting_key" */
export enum question_setting_key_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_setting_key" */
export type question_setting_key_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "question_setting_key" */
export type question_setting_key_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_setting_key_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_setting_key_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "question_setting_key" */
export enum question_setting_key_update_column {
  /** column name */
  value = 'value'
}

export type question_setting_key_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_setting_key_set_input>;
  /** filter the rows which have to be updated */
  where: question_setting_key_bool_exp;
};

/** aggregate max on columns */
export type question_setting_max_fields = {
  __typename: 'question_setting_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "question_setting" */
export type question_setting_max_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_setting_min_fields = {
  __typename: 'question_setting_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "question_setting" */
export type question_setting_min_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_setting" */
export type question_setting_mutation_response = {
  __typename: 'question_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_setting>;
};

/** input type for inserting object relation for remote table "question_setting" */
export type question_setting_obj_rel_insert_input = {
  data: question_setting_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_setting_on_conflict>;
};

/** on_conflict condition type for table "question_setting" */
export type question_setting_on_conflict = {
  constraint: question_setting_constraint;
  update_columns?: Array<question_setting_update_column>;
  where?: InputMaybe<question_setting_bool_exp>;
};

/** Ordering options when selecting data from "question_setting". */
export type question_setting_order_by = {
  created_at?: InputMaybe<order_by>;
  draft_version?: InputMaybe<question_setting_version_order_by>;
  draft_version_id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  published_version?: InputMaybe<question_setting_version_order_by>;
  published_version_id?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_setting_key?: InputMaybe<question_setting_key_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_setting */
export type question_setting_pk_columns_input = {
  key: question_setting_key_enum;
  question_id: Scalars['Int']['input'];
};

/** select columns of table "question_setting" */
export enum question_setting_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "question_setting" */
export type question_setting_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type question_setting_stddev_fields = {
  __typename: 'question_setting_stddev_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_setting" */
export type question_setting_stddev_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_setting_stddev_pop_fields = {
  __typename: 'question_setting_stddev_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_setting" */
export type question_setting_stddev_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_setting_stddev_samp_fields = {
  __typename: 'question_setting_stddev_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_setting" */
export type question_setting_stddev_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_setting" */
export type question_setting_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_setting_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_setting_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type question_setting_sum_fields = {
  __typename: 'question_setting_sum_fields';
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_setting" */
export type question_setting_sum_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** update columns of table "question_setting" */
export enum question_setting_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  key = 'key',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  updated_at = 'updated_at'
}

export type question_setting_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_setting_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_setting_set_input>;
  /** filter the rows which have to be updated */
  where: question_setting_bool_exp;
};

/** aggregate var_pop on columns */
export type question_setting_var_pop_fields = {
  __typename: 'question_setting_var_pop_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_setting" */
export type question_setting_var_pop_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_setting_var_samp_fields = {
  __typename: 'question_setting_var_samp_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_setting" */
export type question_setting_var_samp_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_setting_variance_fields = {
  __typename: 'question_setting_variance_fields';
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_setting" */
export type question_setting_variance_order_by = {
  draft_version_id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
};

/** columns and relationships of "question_setting_version" */
export type question_setting_version = {
  __typename: 'question_setting_version';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  key: question_setting_key_enum;
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
  /** An object relationship */
  question_setting?: Maybe<question_setting>;
  /** An object relationship */
  question_setting_key: question_setting_key;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "question_setting_version" */
export type question_setting_versionvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "question_setting_version" */
export type question_setting_version_aggregate = {
  __typename: 'question_setting_version_aggregate';
  aggregate?: Maybe<question_setting_version_aggregate_fields>;
  nodes: Array<question_setting_version>;
};

/** aggregate fields of "question_setting_version" */
export type question_setting_version_aggregate_fields = {
  __typename: 'question_setting_version_aggregate_fields';
  avg?: Maybe<question_setting_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_setting_version_max_fields>;
  min?: Maybe<question_setting_version_min_fields>;
  stddev?: Maybe<question_setting_version_stddev_fields>;
  stddev_pop?: Maybe<question_setting_version_stddev_pop_fields>;
  stddev_samp?: Maybe<question_setting_version_stddev_samp_fields>;
  sum?: Maybe<question_setting_version_sum_fields>;
  var_pop?: Maybe<question_setting_version_var_pop_fields>;
  var_samp?: Maybe<question_setting_version_var_samp_fields>;
  variance?: Maybe<question_setting_version_variance_fields>;
};


/** aggregate fields of "question_setting_version" */
export type question_setting_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_setting_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type question_setting_version_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type question_setting_version_avg_fields = {
  __typename: 'question_setting_version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "question_setting_version". All fields are combined with a logical 'AND'. */
export type question_setting_version_bool_exp = {
  _and?: InputMaybe<Array<question_setting_version_bool_exp>>;
  _not?: InputMaybe<question_setting_version_bool_exp>;
  _or?: InputMaybe<Array<question_setting_version_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  key?: InputMaybe<question_setting_key_enum_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_setting?: InputMaybe<question_setting_bool_exp>;
  question_setting_key?: InputMaybe<question_setting_key_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "question_setting_version" */
export enum question_setting_version_constraint {
  /** unique or primary key constraint on columns "id" */
  survey_question_setting_version_pkey = 'survey_question_setting_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type question_setting_version_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type question_setting_version_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type question_setting_version_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "question_setting_version" */
export type question_setting_version_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_setting_version" */
export type question_setting_version_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_setting?: InputMaybe<question_setting_obj_rel_insert_input>;
  question_setting_key?: InputMaybe<question_setting_key_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type question_setting_version_max_fields = {
  __typename: 'question_setting_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type question_setting_version_min_fields = {
  __typename: 'question_setting_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "question_setting_version" */
export type question_setting_version_mutation_response = {
  __typename: 'question_setting_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_setting_version>;
};

/** input type for inserting object relation for remote table "question_setting_version" */
export type question_setting_version_obj_rel_insert_input = {
  data: question_setting_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_setting_version_on_conflict>;
};

/** on_conflict condition type for table "question_setting_version" */
export type question_setting_version_on_conflict = {
  constraint: question_setting_version_constraint;
  update_columns?: Array<question_setting_version_update_column>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};

/** Ordering options when selecting data from "question_setting_version". */
export type question_setting_version_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  key?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_setting?: InputMaybe<question_setting_order_by>;
  question_setting_key?: InputMaybe<question_setting_key_order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_setting_version */
export type question_setting_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type question_setting_version_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "question_setting_version" */
export enum question_setting_version_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  question_id = 'question_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_setting_version" */
export type question_setting_version_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type question_setting_version_stddev_fields = {
  __typename: 'question_setting_version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type question_setting_version_stddev_pop_fields = {
  __typename: 'question_setting_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type question_setting_version_stddev_samp_fields = {
  __typename: 'question_setting_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "question_setting_version" */
export type question_setting_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_setting_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_setting_version_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<question_setting_key_enum>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type question_setting_version_sum_fields = {
  __typename: 'question_setting_version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "question_setting_version" */
export enum question_setting_version_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  key = 'key',
  /** column name */
  question_id = 'question_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

export type question_setting_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<question_setting_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<question_setting_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<question_setting_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<question_setting_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_setting_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<question_setting_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_setting_version_set_input>;
  /** filter the rows which have to be updated */
  where: question_setting_version_bool_exp;
};

/** aggregate var_pop on columns */
export type question_setting_version_var_pop_fields = {
  __typename: 'question_setting_version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type question_setting_version_var_samp_fields = {
  __typename: 'question_setting_version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type question_setting_version_variance_fields = {
  __typename: 'question_setting_version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type question_stddev_fields = {
  __typename: 'question_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question" */
export type question_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_stddev_pop_fields = {
  __typename: 'question_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question" */
export type question_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_stddev_samp_fields = {
  __typename: 'question_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question" */
export type question_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question" */
export type question_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  draft_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  published_version_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<question_type_enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type question_sum_fields = {
  __typename: 'question_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  draft_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  published_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question" */
export type question_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "question_type" */
export type question_type = {
  __typename: 'question_type';
  /** An array relationship */
  survey_questions: Array<question>;
  /** An aggregate relationship */
  survey_questions_aggregate: question_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "question_type" */
export type question_typesurvey_questionsArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


/** columns and relationships of "question_type" */
export type question_typesurvey_questions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};

/** aggregated selection of "question_type" */
export type question_type_aggregate = {
  __typename: 'question_type_aggregate';
  aggregate?: Maybe<question_type_aggregate_fields>;
  nodes: Array<question_type>;
};

/** aggregate fields of "question_type" */
export type question_type_aggregate_fields = {
  __typename: 'question_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<question_type_max_fields>;
  min?: Maybe<question_type_min_fields>;
};


/** aggregate fields of "question_type" */
export type question_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "question_type". All fields are combined with a logical 'AND'. */
export type question_type_bool_exp = {
  _and?: InputMaybe<Array<question_type_bool_exp>>;
  _not?: InputMaybe<question_type_bool_exp>;
  _or?: InputMaybe<Array<question_type_bool_exp>>;
  survey_questions?: InputMaybe<question_bool_exp>;
  survey_questions_aggregate?: InputMaybe<question_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "question_type" */
export enum question_type_constraint {
  /** unique or primary key constraint on columns "value" */
  survey_question_type_pkey = 'survey_question_type_pkey'
}

export enum question_type_enum {
  likelihood_to_return = 'likelihood_to_return',
  radio = 'radio',
  text = 'text'
}

/** Boolean expression to compare columns of type "question_type_enum". All fields are combined with logical 'AND'. */
export type question_type_enum_comparison_exp = {
  _eq?: InputMaybe<question_type_enum>;
  _in?: InputMaybe<Array<question_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<question_type_enum>;
  _nin?: InputMaybe<Array<question_type_enum>>;
};

/** input type for inserting data into table "question_type" */
export type question_type_insert_input = {
  survey_questions?: InputMaybe<question_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type question_type_max_fields = {
  __typename: 'question_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type question_type_min_fields = {
  __typename: 'question_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "question_type" */
export type question_type_mutation_response = {
  __typename: 'question_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_type>;
};

/** input type for inserting object relation for remote table "question_type" */
export type question_type_obj_rel_insert_input = {
  data: question_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_type_on_conflict>;
};

/** on_conflict condition type for table "question_type" */
export type question_type_on_conflict = {
  constraint: question_type_constraint;
  update_columns?: Array<question_type_update_column>;
  where?: InputMaybe<question_type_bool_exp>;
};

/** Ordering options when selecting data from "question_type". */
export type question_type_order_by = {
  survey_questions_aggregate?: InputMaybe<question_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_type */
export type question_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "question_type" */
export enum question_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "question_type" */
export type question_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "question_type" */
export type question_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "question_type" */
export enum question_type_update_column {
  /** column name */
  value = 'value'
}

export type question_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_type_set_input>;
  /** filter the rows which have to be updated */
  where: question_type_bool_exp;
};

/** update columns of table "question" */
export enum question_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  draft_version_id = 'draft_version_id',
  /** column name */
  id = 'id',
  /** column name */
  primary = 'primary',
  /** column name */
  published_version_id = 'published_version_id',
  /** column name */
  token = 'token',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at'
}

export type question_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_set_input>;
  /** filter the rows which have to be updated */
  where: question_bool_exp;
};

/** aggregate var_pop on columns */
export type question_var_pop_fields = {
  __typename: 'question_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question" */
export type question_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_var_samp_fields = {
  __typename: 'question_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question" */
export type question_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_variance_fields = {
  __typename: 'question_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  draft_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  published_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question" */
export type question_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  draft_version_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  published_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "question_version" */
export type question_version = {
  __typename: 'question_version';
  condition_include_other_in_question_ids?: Maybe<Scalars['jsonb']['output']>;
  condition_question_option_ids?: Maybe<Scalars['jsonb']['output']>;
  condition_segment_group_ids?: Maybe<Scalars['jsonb']['output']>;
  condition_segment_ids?: Maybe<Scalars['jsonb']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  hint_translation?: Maybe<translation>;
  hint_translation_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  placeholder_translation?: Maybe<translation>;
  placeholder_translation_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
  question_option_ids?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  question_version_condition_question_options: Array<question_version_condition_question_option>;
  /** An aggregate relationship */
  question_version_condition_question_options_aggregate: question_version_condition_question_option_aggregate;
  /** An array relationship */
  question_version_condition_segment_groups: Array<question_version_condition_segment_group>;
  /** An aggregate relationship */
  question_version_condition_segment_groups_aggregate: question_version_condition_segment_group_aggregate;
  /** An array relationship */
  question_version_condition_segments: Array<question_version_condition_segment>;
  /** An aggregate relationship */
  question_version_condition_segments_aggregate: question_version_condition_segment_aggregate;
  /** An array relationship */
  question_version_question_options: Array<question_version_question_option>;
  /** An aggregate relationship */
  question_version_question_options_aggregate: question_version_question_option_aggregate;
  required: Scalars['Boolean']['output'];
  /** An object relationship */
  title_translation?: Maybe<translation>;
  title_translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "question_version" */
export type question_versioncondition_include_other_in_question_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "question_version" */
export type question_versioncondition_question_option_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "question_version" */
export type question_versioncondition_segment_group_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "question_version" */
export type question_versioncondition_segment_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_option_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_question_optionsArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_question_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_segment_groupsArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_segment_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_segmentsArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_condition_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_question_optionsArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


/** columns and relationships of "question_version" */
export type question_versionquestion_version_question_options_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};

/** aggregated selection of "question_version" */
export type question_version_aggregate = {
  __typename: 'question_version_aggregate';
  aggregate?: Maybe<question_version_aggregate_fields>;
  nodes: Array<question_version>;
};

export type question_version_aggregate_bool_exp = {
  bool_and?: InputMaybe<question_version_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<question_version_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<question_version_aggregate_bool_exp_count>;
};

export type question_version_aggregate_bool_exp_bool_and = {
  arguments: question_version_select_column_question_version_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type question_version_aggregate_bool_exp_bool_or = {
  arguments: question_version_select_column_question_version_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type question_version_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_version" */
export type question_version_aggregate_fields = {
  __typename: 'question_version_aggregate_fields';
  avg?: Maybe<question_version_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_version_max_fields>;
  min?: Maybe<question_version_min_fields>;
  stddev?: Maybe<question_version_stddev_fields>;
  stddev_pop?: Maybe<question_version_stddev_pop_fields>;
  stddev_samp?: Maybe<question_version_stddev_samp_fields>;
  sum?: Maybe<question_version_sum_fields>;
  var_pop?: Maybe<question_version_var_pop_fields>;
  var_samp?: Maybe<question_version_var_samp_fields>;
  variance?: Maybe<question_version_variance_fields>;
};


/** aggregate fields of "question_version" */
export type question_version_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_version_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_version" */
export type question_version_aggregate_order_by = {
  avg?: InputMaybe<question_version_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_version_max_order_by>;
  min?: InputMaybe<question_version_min_order_by>;
  stddev?: InputMaybe<question_version_stddev_order_by>;
  stddev_pop?: InputMaybe<question_version_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_version_stddev_samp_order_by>;
  sum?: InputMaybe<question_version_sum_order_by>;
  var_pop?: InputMaybe<question_version_var_pop_order_by>;
  var_samp?: InputMaybe<question_version_var_samp_order_by>;
  variance?: InputMaybe<question_version_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type question_version_append_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "question_version" */
export type question_version_arr_rel_insert_input = {
  data: Array<question_version_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<question_version_on_conflict>;
};

/** aggregate avg on columns */
export type question_version_avg_fields = {
  __typename: 'question_version_avg_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_version" */
export type question_version_avg_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_version". All fields are combined with a logical 'AND'. */
export type question_version_bool_exp = {
  _and?: InputMaybe<Array<question_version_bool_exp>>;
  _not?: InputMaybe<question_version_bool_exp>;
  _or?: InputMaybe<Array<question_version_bool_exp>>;
  condition_include_other_in_question_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_question_option_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_segment_group_ids?: InputMaybe<jsonb_comparison_exp>;
  condition_segment_ids?: InputMaybe<jsonb_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  hint_translation?: InputMaybe<translation_bool_exp>;
  hint_translation_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  placeholder_translation?: InputMaybe<translation_bool_exp>;
  placeholder_translation_id?: InputMaybe<Int_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_option_ids?: InputMaybe<jsonb_comparison_exp>;
  question_version_condition_question_options?: InputMaybe<question_version_condition_question_option_bool_exp>;
  question_version_condition_question_options_aggregate?: InputMaybe<question_version_condition_question_option_aggregate_bool_exp>;
  question_version_condition_segment_groups?: InputMaybe<question_version_condition_segment_group_bool_exp>;
  question_version_condition_segment_groups_aggregate?: InputMaybe<question_version_condition_segment_group_aggregate_bool_exp>;
  question_version_condition_segments?: InputMaybe<question_version_condition_segment_bool_exp>;
  question_version_condition_segments_aggregate?: InputMaybe<question_version_condition_segment_aggregate_bool_exp>;
  question_version_question_options?: InputMaybe<question_version_question_option_bool_exp>;
  question_version_question_options_aggregate?: InputMaybe<question_version_question_option_aggregate_bool_exp>;
  required?: InputMaybe<Boolean_comparison_exp>;
  title_translation?: InputMaybe<translation_bool_exp>;
  title_translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "question_version_condition_question_option" */
export type question_version_condition_question_option = {
  __typename: 'question_version_condition_question_option';
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_version?: Maybe<question_version>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "question_version_condition_question_option" */
export type question_version_condition_question_option_aggregate = {
  __typename: 'question_version_condition_question_option_aggregate';
  aggregate?: Maybe<question_version_condition_question_option_aggregate_fields>;
  nodes: Array<question_version_condition_question_option>;
};

export type question_version_condition_question_option_aggregate_bool_exp = {
  count?: InputMaybe<question_version_condition_question_option_aggregate_bool_exp_count>;
};

export type question_version_condition_question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_condition_question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_version_condition_question_option" */
export type question_version_condition_question_option_aggregate_fields = {
  __typename: 'question_version_condition_question_option_aggregate_fields';
  avg?: Maybe<question_version_condition_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_version_condition_question_option_max_fields>;
  min?: Maybe<question_version_condition_question_option_min_fields>;
  stddev?: Maybe<question_version_condition_question_option_stddev_fields>;
  stddev_pop?: Maybe<question_version_condition_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<question_version_condition_question_option_stddev_samp_fields>;
  sum?: Maybe<question_version_condition_question_option_sum_fields>;
  var_pop?: Maybe<question_version_condition_question_option_var_pop_fields>;
  var_samp?: Maybe<question_version_condition_question_option_var_samp_fields>;
  variance?: Maybe<question_version_condition_question_option_variance_fields>;
};


/** aggregate fields of "question_version_condition_question_option" */
export type question_version_condition_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_version_condition_question_option" */
export type question_version_condition_question_option_aggregate_order_by = {
  avg?: InputMaybe<question_version_condition_question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_version_condition_question_option_max_order_by>;
  min?: InputMaybe<question_version_condition_question_option_min_order_by>;
  stddev?: InputMaybe<question_version_condition_question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<question_version_condition_question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_version_condition_question_option_stddev_samp_order_by>;
  sum?: InputMaybe<question_version_condition_question_option_sum_order_by>;
  var_pop?: InputMaybe<question_version_condition_question_option_var_pop_order_by>;
  var_samp?: InputMaybe<question_version_condition_question_option_var_samp_order_by>;
  variance?: InputMaybe<question_version_condition_question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_version_condition_question_option" */
export type question_version_condition_question_option_arr_rel_insert_input = {
  data: Array<question_version_condition_question_option_insert_input>;
};

/** aggregate avg on columns */
export type question_version_condition_question_option_avg_fields = {
  __typename: 'question_version_condition_question_option_avg_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_avg_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_version_condition_question_option". All fields are combined with a logical 'AND'. */
export type question_version_condition_question_option_bool_exp = {
  _and?: InputMaybe<Array<question_version_condition_question_option_bool_exp>>;
  _not?: InputMaybe<question_version_condition_question_option_bool_exp>;
  _or?: InputMaybe<Array<question_version_condition_question_option_bool_exp>>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  question_version?: InputMaybe<question_version_bool_exp>;
  question_version_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "question_version_condition_question_option" */
export type question_version_condition_question_option_insert_input = {
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_version?: InputMaybe<question_version_obj_rel_insert_input>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_version_condition_question_option_max_fields = {
  __typename: 'question_version_condition_question_option_max_fields';
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_max_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_version_condition_question_option_min_fields = {
  __typename: 'question_version_condition_question_option_min_fields';
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_min_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "question_version_condition_question_option". */
export type question_version_condition_question_option_order_by = {
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version?: InputMaybe<question_version_order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** select columns of table "question_version_condition_question_option" */
export enum question_version_condition_question_option_select_column {
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  question_version_id = 'question_version_id'
}

/** aggregate stddev on columns */
export type question_version_condition_question_option_stddev_fields = {
  __typename: 'question_version_condition_question_option_stddev_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_stddev_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_version_condition_question_option_stddev_pop_fields = {
  __typename: 'question_version_condition_question_option_stddev_pop_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_stddev_pop_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_version_condition_question_option_stddev_samp_fields = {
  __typename: 'question_version_condition_question_option_stddev_samp_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_stddev_samp_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_version_condition_question_option" */
export type question_version_condition_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_version_condition_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_version_condition_question_option_stream_cursor_value_input = {
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_version_condition_question_option_sum_fields = {
  __typename: 'question_version_condition_question_option_sum_fields';
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_sum_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type question_version_condition_question_option_var_pop_fields = {
  __typename: 'question_version_condition_question_option_var_pop_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_var_pop_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_version_condition_question_option_var_samp_fields = {
  __typename: 'question_version_condition_question_option_var_samp_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_var_samp_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_version_condition_question_option_variance_fields = {
  __typename: 'question_version_condition_question_option_variance_fields';
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_version_condition_question_option" */
export type question_version_condition_question_option_variance_order_by = {
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** columns and relationships of "question_version_condition_segment" */
export type question_version_condition_segment = {
  __typename: 'question_version_condition_segment';
  /** An object relationship */
  question_version?: Maybe<question_version>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment?: Maybe<segment>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "question_version_condition_segment" */
export type question_version_condition_segment_aggregate = {
  __typename: 'question_version_condition_segment_aggregate';
  aggregate?: Maybe<question_version_condition_segment_aggregate_fields>;
  nodes: Array<question_version_condition_segment>;
};

export type question_version_condition_segment_aggregate_bool_exp = {
  count?: InputMaybe<question_version_condition_segment_aggregate_bool_exp_count>;
};

export type question_version_condition_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_condition_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_version_condition_segment" */
export type question_version_condition_segment_aggregate_fields = {
  __typename: 'question_version_condition_segment_aggregate_fields';
  avg?: Maybe<question_version_condition_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_version_condition_segment_max_fields>;
  min?: Maybe<question_version_condition_segment_min_fields>;
  stddev?: Maybe<question_version_condition_segment_stddev_fields>;
  stddev_pop?: Maybe<question_version_condition_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<question_version_condition_segment_stddev_samp_fields>;
  sum?: Maybe<question_version_condition_segment_sum_fields>;
  var_pop?: Maybe<question_version_condition_segment_var_pop_fields>;
  var_samp?: Maybe<question_version_condition_segment_var_samp_fields>;
  variance?: Maybe<question_version_condition_segment_variance_fields>;
};


/** aggregate fields of "question_version_condition_segment" */
export type question_version_condition_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_version_condition_segment" */
export type question_version_condition_segment_aggregate_order_by = {
  avg?: InputMaybe<question_version_condition_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_version_condition_segment_max_order_by>;
  min?: InputMaybe<question_version_condition_segment_min_order_by>;
  stddev?: InputMaybe<question_version_condition_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<question_version_condition_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_version_condition_segment_stddev_samp_order_by>;
  sum?: InputMaybe<question_version_condition_segment_sum_order_by>;
  var_pop?: InputMaybe<question_version_condition_segment_var_pop_order_by>;
  var_samp?: InputMaybe<question_version_condition_segment_var_samp_order_by>;
  variance?: InputMaybe<question_version_condition_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_version_condition_segment" */
export type question_version_condition_segment_arr_rel_insert_input = {
  data: Array<question_version_condition_segment_insert_input>;
};

/** aggregate avg on columns */
export type question_version_condition_segment_avg_fields = {
  __typename: 'question_version_condition_segment_avg_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_avg_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_version_condition_segment". All fields are combined with a logical 'AND'. */
export type question_version_condition_segment_bool_exp = {
  _and?: InputMaybe<Array<question_version_condition_segment_bool_exp>>;
  _not?: InputMaybe<question_version_condition_segment_bool_exp>;
  _or?: InputMaybe<Array<question_version_condition_segment_bool_exp>>;
  question_version?: InputMaybe<question_version_bool_exp>;
  question_version_id?: InputMaybe<Int_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
};

/** columns and relationships of "question_version_condition_segment_group" */
export type question_version_condition_segment_group = {
  __typename: 'question_version_condition_segment_group';
  /** An object relationship */
  question_version?: Maybe<question_version>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  segment_group?: Maybe<segment_group>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "question_version_condition_segment_group" */
export type question_version_condition_segment_group_aggregate = {
  __typename: 'question_version_condition_segment_group_aggregate';
  aggregate?: Maybe<question_version_condition_segment_group_aggregate_fields>;
  nodes: Array<question_version_condition_segment_group>;
};

export type question_version_condition_segment_group_aggregate_bool_exp = {
  count?: InputMaybe<question_version_condition_segment_group_aggregate_bool_exp_count>;
};

export type question_version_condition_segment_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_condition_segment_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_version_condition_segment_group" */
export type question_version_condition_segment_group_aggregate_fields = {
  __typename: 'question_version_condition_segment_group_aggregate_fields';
  avg?: Maybe<question_version_condition_segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_version_condition_segment_group_max_fields>;
  min?: Maybe<question_version_condition_segment_group_min_fields>;
  stddev?: Maybe<question_version_condition_segment_group_stddev_fields>;
  stddev_pop?: Maybe<question_version_condition_segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<question_version_condition_segment_group_stddev_samp_fields>;
  sum?: Maybe<question_version_condition_segment_group_sum_fields>;
  var_pop?: Maybe<question_version_condition_segment_group_var_pop_fields>;
  var_samp?: Maybe<question_version_condition_segment_group_var_samp_fields>;
  variance?: Maybe<question_version_condition_segment_group_variance_fields>;
};


/** aggregate fields of "question_version_condition_segment_group" */
export type question_version_condition_segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_aggregate_order_by = {
  avg?: InputMaybe<question_version_condition_segment_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_version_condition_segment_group_max_order_by>;
  min?: InputMaybe<question_version_condition_segment_group_min_order_by>;
  stddev?: InputMaybe<question_version_condition_segment_group_stddev_order_by>;
  stddev_pop?: InputMaybe<question_version_condition_segment_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_version_condition_segment_group_stddev_samp_order_by>;
  sum?: InputMaybe<question_version_condition_segment_group_sum_order_by>;
  var_pop?: InputMaybe<question_version_condition_segment_group_var_pop_order_by>;
  var_samp?: InputMaybe<question_version_condition_segment_group_var_samp_order_by>;
  variance?: InputMaybe<question_version_condition_segment_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_arr_rel_insert_input = {
  data: Array<question_version_condition_segment_group_insert_input>;
};

/** aggregate avg on columns */
export type question_version_condition_segment_group_avg_fields = {
  __typename: 'question_version_condition_segment_group_avg_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_avg_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_version_condition_segment_group". All fields are combined with a logical 'AND'. */
export type question_version_condition_segment_group_bool_exp = {
  _and?: InputMaybe<Array<question_version_condition_segment_group_bool_exp>>;
  _not?: InputMaybe<question_version_condition_segment_group_bool_exp>;
  _or?: InputMaybe<Array<question_version_condition_segment_group_bool_exp>>;
  question_version?: InputMaybe<question_version_bool_exp>;
  question_version_id?: InputMaybe<Int_comparison_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_insert_input = {
  question_version?: InputMaybe<question_version_obj_rel_insert_input>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_version_condition_segment_group_max_fields = {
  __typename: 'question_version_condition_segment_group_max_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_max_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_version_condition_segment_group_min_fields = {
  __typename: 'question_version_condition_segment_group_min_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_min_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "question_version_condition_segment_group". */
export type question_version_condition_segment_group_order_by = {
  question_version?: InputMaybe<question_version_order_by>;
  question_version_id?: InputMaybe<order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** select columns of table "question_version_condition_segment_group" */
export enum question_version_condition_segment_group_select_column {
  /** column name */
  question_version_id = 'question_version_id',
  /** column name */
  segment_group_id = 'segment_group_id'
}

/** aggregate stddev on columns */
export type question_version_condition_segment_group_stddev_fields = {
  __typename: 'question_version_condition_segment_group_stddev_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_stddev_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_version_condition_segment_group_stddev_pop_fields = {
  __typename: 'question_version_condition_segment_group_stddev_pop_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_stddev_pop_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_version_condition_segment_group_stddev_samp_fields = {
  __typename: 'question_version_condition_segment_group_stddev_samp_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_stddev_samp_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_version_condition_segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_version_condition_segment_group_stream_cursor_value_input = {
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_version_condition_segment_group_sum_fields = {
  __typename: 'question_version_condition_segment_group_sum_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_sum_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type question_version_condition_segment_group_var_pop_fields = {
  __typename: 'question_version_condition_segment_group_var_pop_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_var_pop_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_version_condition_segment_group_var_samp_fields = {
  __typename: 'question_version_condition_segment_group_var_samp_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_var_samp_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_version_condition_segment_group_variance_fields = {
  __typename: 'question_version_condition_segment_group_variance_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_version_condition_segment_group" */
export type question_version_condition_segment_group_variance_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
};

/** input type for inserting data into table "question_version_condition_segment" */
export type question_version_condition_segment_insert_input = {
  question_version?: InputMaybe<question_version_obj_rel_insert_input>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_version_condition_segment_max_fields = {
  __typename: 'question_version_condition_segment_max_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_max_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_version_condition_segment_min_fields = {
  __typename: 'question_version_condition_segment_min_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_min_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "question_version_condition_segment". */
export type question_version_condition_segment_order_by = {
  question_version?: InputMaybe<question_version_order_by>;
  question_version_id?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "question_version_condition_segment" */
export enum question_version_condition_segment_select_column {
  /** column name */
  question_version_id = 'question_version_id',
  /** column name */
  segment_id = 'segment_id'
}

/** aggregate stddev on columns */
export type question_version_condition_segment_stddev_fields = {
  __typename: 'question_version_condition_segment_stddev_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_stddev_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_version_condition_segment_stddev_pop_fields = {
  __typename: 'question_version_condition_segment_stddev_pop_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_stddev_pop_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_version_condition_segment_stddev_samp_fields = {
  __typename: 'question_version_condition_segment_stddev_samp_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_stddev_samp_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_version_condition_segment" */
export type question_version_condition_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_version_condition_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_version_condition_segment_stream_cursor_value_input = {
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_version_condition_segment_sum_fields = {
  __typename: 'question_version_condition_segment_sum_fields';
  question_version_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_sum_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type question_version_condition_segment_var_pop_fields = {
  __typename: 'question_version_condition_segment_var_pop_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_var_pop_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_version_condition_segment_var_samp_fields = {
  __typename: 'question_version_condition_segment_var_samp_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_var_samp_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_version_condition_segment_variance_fields = {
  __typename: 'question_version_condition_segment_variance_fields';
  question_version_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_version_condition_segment" */
export type question_version_condition_segment_variance_order_by = {
  question_version_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "question_version" */
export enum question_version_constraint {
  /** unique or primary key constraint on columns "id" */
  survey_question_version_pkey = 'survey_question_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type question_version_delete_at_path_input = {
  condition_include_other_in_question_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_question_option_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_segment_group_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  condition_segment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  question_option_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type question_version_delete_elem_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['Int']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['Int']['input']>;
  question_option_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type question_version_delete_key_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['String']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['String']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['String']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['String']['input']>;
  question_option_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "question_version" */
export type question_version_inc_input = {
  hint_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  placeholder_translation_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "question_version" */
export type question_version_insert_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hint_translation?: InputMaybe<translation_obj_rel_insert_input>;
  hint_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  placeholder_translation?: InputMaybe<translation_obj_rel_insert_input>;
  placeholder_translation_id?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_version_condition_question_options?: InputMaybe<question_version_condition_question_option_arr_rel_insert_input>;
  question_version_condition_segment_groups?: InputMaybe<question_version_condition_segment_group_arr_rel_insert_input>;
  question_version_condition_segments?: InputMaybe<question_version_condition_segment_arr_rel_insert_input>;
  question_version_question_options?: InputMaybe<question_version_question_option_arr_rel_insert_input>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title_translation?: InputMaybe<translation_obj_rel_insert_input>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type question_version_max_fields = {
  __typename: 'question_version_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hint_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "question_version" */
export type question_version_max_order_by = {
  created_at?: InputMaybe<order_by>;
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_version_min_fields = {
  __typename: 'question_version_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  hint_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "question_version" */
export type question_version_min_order_by = {
  created_at?: InputMaybe<order_by>;
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "question_version" */
export type question_version_mutation_response = {
  __typename: 'question_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<question_version>;
};

/** input type for inserting object relation for remote table "question_version" */
export type question_version_obj_rel_insert_input = {
  data: question_version_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<question_version_on_conflict>;
};

/** on_conflict condition type for table "question_version" */
export type question_version_on_conflict = {
  constraint: question_version_constraint;
  update_columns?: Array<question_version_update_column>;
  where?: InputMaybe<question_version_bool_exp>;
};

/** Ordering options when selecting data from "question_version". */
export type question_version_order_by = {
  condition_include_other_in_question_ids?: InputMaybe<order_by>;
  condition_question_option_ids?: InputMaybe<order_by>;
  condition_segment_group_ids?: InputMaybe<order_by>;
  condition_segment_ids?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  hint_translation?: InputMaybe<translation_order_by>;
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation?: InputMaybe<translation_order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_option_ids?: InputMaybe<order_by>;
  question_version_condition_question_options_aggregate?: InputMaybe<question_version_condition_question_option_aggregate_order_by>;
  question_version_condition_segment_groups_aggregate?: InputMaybe<question_version_condition_segment_group_aggregate_order_by>;
  question_version_condition_segments_aggregate?: InputMaybe<question_version_condition_segment_aggregate_order_by>;
  question_version_question_options_aggregate?: InputMaybe<question_version_question_option_aggregate_order_by>;
  required?: InputMaybe<order_by>;
  title_translation?: InputMaybe<translation_order_by>;
  title_translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: question_version */
export type question_version_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type question_version_prepend_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "question_version_question_option" */
export type question_version_question_option = {
  __typename: 'question_version_question_option';
  position?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_version?: Maybe<question_version>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "question_version_question_option" */
export type question_version_question_option_aggregate = {
  __typename: 'question_version_question_option_aggregate';
  aggregate?: Maybe<question_version_question_option_aggregate_fields>;
  nodes: Array<question_version_question_option>;
};

export type question_version_question_option_aggregate_bool_exp = {
  count?: InputMaybe<question_version_question_option_aggregate_bool_exp_count>;
};

export type question_version_question_option_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<question_version_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<question_version_question_option_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "question_version_question_option" */
export type question_version_question_option_aggregate_fields = {
  __typename: 'question_version_question_option_aggregate_fields';
  avg?: Maybe<question_version_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<question_version_question_option_max_fields>;
  min?: Maybe<question_version_question_option_min_fields>;
  stddev?: Maybe<question_version_question_option_stddev_fields>;
  stddev_pop?: Maybe<question_version_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<question_version_question_option_stddev_samp_fields>;
  sum?: Maybe<question_version_question_option_sum_fields>;
  var_pop?: Maybe<question_version_question_option_var_pop_fields>;
  var_samp?: Maybe<question_version_question_option_var_samp_fields>;
  variance?: Maybe<question_version_question_option_variance_fields>;
};


/** aggregate fields of "question_version_question_option" */
export type question_version_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<question_version_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "question_version_question_option" */
export type question_version_question_option_aggregate_order_by = {
  avg?: InputMaybe<question_version_question_option_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<question_version_question_option_max_order_by>;
  min?: InputMaybe<question_version_question_option_min_order_by>;
  stddev?: InputMaybe<question_version_question_option_stddev_order_by>;
  stddev_pop?: InputMaybe<question_version_question_option_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<question_version_question_option_stddev_samp_order_by>;
  sum?: InputMaybe<question_version_question_option_sum_order_by>;
  var_pop?: InputMaybe<question_version_question_option_var_pop_order_by>;
  var_samp?: InputMaybe<question_version_question_option_var_samp_order_by>;
  variance?: InputMaybe<question_version_question_option_variance_order_by>;
};

/** input type for inserting array relation for remote table "question_version_question_option" */
export type question_version_question_option_arr_rel_insert_input = {
  data: Array<question_version_question_option_insert_input>;
};

/** aggregate avg on columns */
export type question_version_question_option_avg_fields = {
  __typename: 'question_version_question_option_avg_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "question_version_question_option" */
export type question_version_question_option_avg_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "question_version_question_option". All fields are combined with a logical 'AND'. */
export type question_version_question_option_bool_exp = {
  _and?: InputMaybe<Array<question_version_question_option_bool_exp>>;
  _not?: InputMaybe<question_version_question_option_bool_exp>;
  _or?: InputMaybe<Array<question_version_question_option_bool_exp>>;
  position?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  question_version?: InputMaybe<question_version_bool_exp>;
  question_version_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "question_version_question_option" */
export type question_version_question_option_insert_input = {
  position?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_version?: InputMaybe<question_version_obj_rel_insert_input>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type question_version_question_option_max_fields = {
  __typename: 'question_version_question_option_max_fields';
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "question_version_question_option" */
export type question_version_question_option_max_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type question_version_question_option_min_fields = {
  __typename: 'question_version_question_option_min_fields';
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "question_version_question_option" */
export type question_version_question_option_min_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "question_version_question_option". */
export type question_version_question_option_order_by = {
  position?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version?: InputMaybe<question_version_order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** select columns of table "question_version_question_option" */
export enum question_version_question_option_select_column {
  /** column name */
  position = 'position',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  question_version_id = 'question_version_id'
}

/** aggregate stddev on columns */
export type question_version_question_option_stddev_fields = {
  __typename: 'question_version_question_option_stddev_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_version_question_option" */
export type question_version_question_option_stddev_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_version_question_option_stddev_pop_fields = {
  __typename: 'question_version_question_option_stddev_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_version_question_option" */
export type question_version_question_option_stddev_pop_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_version_question_option_stddev_samp_fields = {
  __typename: 'question_version_question_option_stddev_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_version_question_option" */
export type question_version_question_option_stddev_samp_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_version_question_option" */
export type question_version_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_version_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_version_question_option_stream_cursor_value_input = {
  position?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  question_version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type question_version_question_option_sum_fields = {
  __typename: 'question_version_question_option_sum_fields';
  position?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  question_version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_version_question_option" */
export type question_version_question_option_sum_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type question_version_question_option_var_pop_fields = {
  __typename: 'question_version_question_option_var_pop_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_version_question_option" */
export type question_version_question_option_var_pop_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_version_question_option_var_samp_fields = {
  __typename: 'question_version_question_option_var_samp_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_version_question_option" */
export type question_version_question_option_var_samp_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_version_question_option_variance_fields = {
  __typename: 'question_version_question_option_variance_fields';
  position?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
  question_version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_version_question_option" */
export type question_version_question_option_variance_order_by = {
  position?: InputMaybe<order_by>;
  question_option_id?: InputMaybe<order_by>;
  question_version_id?: InputMaybe<order_by>;
};

/** select columns of table "question_version" */
export enum question_version_select_column {
  /** column name */
  condition_include_other_in_question_ids = 'condition_include_other_in_question_ids',
  /** column name */
  condition_question_option_ids = 'condition_question_option_ids',
  /** column name */
  condition_segment_group_ids = 'condition_segment_group_ids',
  /** column name */
  condition_segment_ids = 'condition_segment_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hint_translation_id = 'hint_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  placeholder_translation_id = 'placeholder_translation_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_ids = 'question_option_ids',
  /** column name */
  required = 'required',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

/** select "question_version_aggregate_bool_exp_bool_and_arguments_columns" columns of table "question_version" */
export enum question_version_select_column_question_version_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  required = 'required'
}

/** select "question_version_aggregate_bool_exp_bool_or_arguments_columns" columns of table "question_version" */
export enum question_version_select_column_question_version_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  required = 'required'
}

/** input type for updating data in table "question_version" */
export type question_version_set_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hint_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  placeholder_translation_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type question_version_stddev_fields = {
  __typename: 'question_version_stddev_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "question_version" */
export type question_version_stddev_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type question_version_stddev_pop_fields = {
  __typename: 'question_version_stddev_pop_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "question_version" */
export type question_version_stddev_pop_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type question_version_stddev_samp_fields = {
  __typename: 'question_version_stddev_samp_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "question_version" */
export type question_version_stddev_samp_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "question_version" */
export type question_version_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: question_version_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type question_version_stream_cursor_value_input = {
  condition_include_other_in_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_group_ids?: InputMaybe<Scalars['jsonb']['input']>;
  condition_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  hint_translation_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  placeholder_translation_id?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_ids?: InputMaybe<Scalars['jsonb']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  title_translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type question_version_sum_fields = {
  __typename: 'question_version_sum_fields';
  hint_translation_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  title_translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "question_version" */
export type question_version_sum_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** update columns of table "question_version" */
export enum question_version_update_column {
  /** column name */
  condition_include_other_in_question_ids = 'condition_include_other_in_question_ids',
  /** column name */
  condition_question_option_ids = 'condition_question_option_ids',
  /** column name */
  condition_segment_group_ids = 'condition_segment_group_ids',
  /** column name */
  condition_segment_ids = 'condition_segment_ids',
  /** column name */
  created_at = 'created_at',
  /** column name */
  hint_translation_id = 'hint_translation_id',
  /** column name */
  id = 'id',
  /** column name */
  placeholder_translation_id = 'placeholder_translation_id',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_ids = 'question_option_ids',
  /** column name */
  required = 'required',
  /** column name */
  title_translation_id = 'title_translation_id',
  /** column name */
  updated_at = 'updated_at'
}

export type question_version_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<question_version_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<question_version_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<question_version_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<question_version_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<question_version_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<question_version_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<question_version_set_input>;
  /** filter the rows which have to be updated */
  where: question_version_bool_exp;
};

/** aggregate var_pop on columns */
export type question_version_var_pop_fields = {
  __typename: 'question_version_var_pop_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "question_version" */
export type question_version_var_pop_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type question_version_var_samp_fields = {
  __typename: 'question_version_var_samp_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "question_version" */
export type question_version_var_samp_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type question_version_variance_fields = {
  __typename: 'question_version_variance_fields';
  hint_translation_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  placeholder_translation_id?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  title_translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "question_version" */
export type question_version_variance_order_by = {
  hint_translation_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  placeholder_translation_id?: InputMaybe<order_by>;
  question_id?: InputMaybe<order_by>;
  title_translation_id?: InputMaybe<order_by>;
};

export type refresh_materialized_view_args = {
  view?: InputMaybe<Scalars['String']['input']>;
};

export type report_deflection_trends_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_step_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_feedback_trends_args = {
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_question_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "report_flow_test_stats" */
export type report_flow_test_stats = {
  __typename: 'report_flow_test_stats';
  cancels?: Maybe<Scalars['Int']['output']>;
  deflections?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow?: Maybe<flow>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow_test?: Maybe<flow_test>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  retains?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "report_flow_test_stats" */
export type report_flow_test_stats_aggregate = {
  __typename: 'report_flow_test_stats_aggregate';
  aggregate?: Maybe<report_flow_test_stats_aggregate_fields>;
  nodes: Array<report_flow_test_stats>;
};

export type report_flow_test_stats_aggregate_bool_exp = {
  count?: InputMaybe<report_flow_test_stats_aggregate_bool_exp_count>;
};

export type report_flow_test_stats_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<report_flow_test_stats_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_flow_test_stats" */
export type report_flow_test_stats_aggregate_fields = {
  __typename: 'report_flow_test_stats_aggregate_fields';
  avg?: Maybe<report_flow_test_stats_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_flow_test_stats_max_fields>;
  min?: Maybe<report_flow_test_stats_min_fields>;
  stddev?: Maybe<report_flow_test_stats_stddev_fields>;
  stddev_pop?: Maybe<report_flow_test_stats_stddev_pop_fields>;
  stddev_samp?: Maybe<report_flow_test_stats_stddev_samp_fields>;
  sum?: Maybe<report_flow_test_stats_sum_fields>;
  var_pop?: Maybe<report_flow_test_stats_var_pop_fields>;
  var_samp?: Maybe<report_flow_test_stats_var_samp_fields>;
  variance?: Maybe<report_flow_test_stats_variance_fields>;
};


/** aggregate fields of "report_flow_test_stats" */
export type report_flow_test_stats_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "report_flow_test_stats" */
export type report_flow_test_stats_aggregate_order_by = {
  avg?: InputMaybe<report_flow_test_stats_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_flow_test_stats_max_order_by>;
  min?: InputMaybe<report_flow_test_stats_min_order_by>;
  stddev?: InputMaybe<report_flow_test_stats_stddev_order_by>;
  stddev_pop?: InputMaybe<report_flow_test_stats_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_flow_test_stats_stddev_samp_order_by>;
  sum?: InputMaybe<report_flow_test_stats_sum_order_by>;
  var_pop?: InputMaybe<report_flow_test_stats_var_pop_order_by>;
  var_samp?: InputMaybe<report_flow_test_stats_var_samp_order_by>;
  variance?: InputMaybe<report_flow_test_stats_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_flow_test_stats" */
export type report_flow_test_stats_arr_rel_insert_input = {
  data: Array<report_flow_test_stats_insert_input>;
};

/** aggregate avg on columns */
export type report_flow_test_stats_avg_fields = {
  __typename: 'report_flow_test_stats_avg_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_avg_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_flow_test_stats". All fields are combined with a logical 'AND'. */
export type report_flow_test_stats_bool_exp = {
  _and?: InputMaybe<Array<report_flow_test_stats_bool_exp>>;
  _not?: InputMaybe<report_flow_test_stats_bool_exp>;
  _or?: InputMaybe<Array<report_flow_test_stats_bool_exp>>;
  cancels?: InputMaybe<Int_comparison_exp>;
  deflections?: InputMaybe<Int_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_test?: InputMaybe<flow_test_bool_exp>;
  flow_test_id?: InputMaybe<Int_comparison_exp>;
  retains?: InputMaybe<Int_comparison_exp>;
  saves?: InputMaybe<Int_comparison_exp>;
  views?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "report_flow_test_stats" */
export type report_flow_test_stats_insert_input = {
  cancels?: InputMaybe<Scalars['Int']['input']>;
  deflections?: InputMaybe<Scalars['Int']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test?: InputMaybe<flow_test_obj_rel_insert_input>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  retains?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type report_flow_test_stats_max_fields = {
  __typename: 'report_flow_test_stats_max_fields';
  cancels?: Maybe<Scalars['Int']['output']>;
  deflections?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  retains?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_max_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_flow_test_stats_min_fields = {
  __typename: 'report_flow_test_stats_min_fields';
  cancels?: Maybe<Scalars['Int']['output']>;
  deflections?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  retains?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_min_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "report_flow_test_stats". */
export type report_flow_test_stats_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test?: InputMaybe<flow_test_order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** select columns of table "report_flow_test_stats" */
export enum report_flow_test_stats_select_column {
  /** column name */
  cancels = 'cancels',
  /** column name */
  deflections = 'deflections',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  retains = 'retains',
  /** column name */
  saves = 'saves',
  /** column name */
  views = 'views'
}

/** aggregate stddev on columns */
export type report_flow_test_stats_stddev_fields = {
  __typename: 'report_flow_test_stats_stddev_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_stddev_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_flow_test_stats_stddev_pop_fields = {
  __typename: 'report_flow_test_stats_stddev_pop_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_stddev_pop_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_flow_test_stats_stddev_samp_fields = {
  __typename: 'report_flow_test_stats_stddev_samp_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_stddev_samp_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_flow_test_stats" */
export type report_flow_test_stats_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_flow_test_stats_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_flow_test_stats_stream_cursor_value_input = {
  cancels?: InputMaybe<Scalars['Int']['input']>;
  deflections?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  retains?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type report_flow_test_stats_sum_fields = {
  __typename: 'report_flow_test_stats_sum_fields';
  cancels?: Maybe<Scalars['Int']['output']>;
  deflections?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  retains?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_sum_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type report_flow_test_stats_var_pop_fields = {
  __typename: 'report_flow_test_stats_var_pop_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_var_pop_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_flow_test_stats_var_samp_fields = {
  __typename: 'report_flow_test_stats_var_samp_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_var_samp_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_flow_test_stats_variance_fields = {
  __typename: 'report_flow_test_stats_variance_fields';
  cancels?: Maybe<Scalars['Float']['output']>;
  deflections?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  retains?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "report_flow_test_stats" */
export type report_flow_test_stats_variance_order_by = {
  cancels?: InputMaybe<order_by>;
  deflections?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  retains?: InputMaybe<order_by>;
  saves?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** columns and relationships of "report_inactive_mrr_by_question_option" */
export type report_inactive_mrr_by_question_option = {
  __typename: 'report_inactive_mrr_by_question_option';
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  question?: Maybe<question>;
  question_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "report_inactive_mrr_by_question_option" */
export type report_inactive_mrr_by_question_option_aggregate = {
  __typename: 'report_inactive_mrr_by_question_option_aggregate';
  aggregate?: Maybe<report_inactive_mrr_by_question_option_aggregate_fields>;
  nodes: Array<report_inactive_mrr_by_question_option>;
};

/** aggregate fields of "report_inactive_mrr_by_question_option" */
export type report_inactive_mrr_by_question_option_aggregate_fields = {
  __typename: 'report_inactive_mrr_by_question_option_aggregate_fields';
  avg?: Maybe<report_inactive_mrr_by_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_inactive_mrr_by_question_option_max_fields>;
  min?: Maybe<report_inactive_mrr_by_question_option_min_fields>;
  stddev?: Maybe<report_inactive_mrr_by_question_option_stddev_fields>;
  stddev_pop?: Maybe<report_inactive_mrr_by_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_inactive_mrr_by_question_option_stddev_samp_fields>;
  sum?: Maybe<report_inactive_mrr_by_question_option_sum_fields>;
  var_pop?: Maybe<report_inactive_mrr_by_question_option_var_pop_fields>;
  var_samp?: Maybe<report_inactive_mrr_by_question_option_var_samp_fields>;
  variance?: Maybe<report_inactive_mrr_by_question_option_variance_fields>;
};


/** aggregate fields of "report_inactive_mrr_by_question_option" */
export type report_inactive_mrr_by_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_inactive_mrr_by_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type report_inactive_mrr_by_question_option_avg_fields = {
  __typename: 'report_inactive_mrr_by_question_option_avg_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "report_inactive_mrr_by_question_option". All fields are combined with a logical 'AND'. */
export type report_inactive_mrr_by_question_option_bool_exp = {
  _and?: InputMaybe<Array<report_inactive_mrr_by_question_option_bool_exp>>;
  _not?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
  _or?: InputMaybe<Array<report_inactive_mrr_by_question_option_bool_exp>>;
  inactive_mrr?: InputMaybe<numeric_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
};

/** aggregate max on columns */
export type report_inactive_mrr_by_question_option_max_fields = {
  __typename: 'report_inactive_mrr_by_question_option_max_fields';
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type report_inactive_mrr_by_question_option_min_fields = {
  __typename: 'report_inactive_mrr_by_question_option_min_fields';
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "report_inactive_mrr_by_question_option". */
export type report_inactive_mrr_by_question_option_order_by = {
  inactive_mrr?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** select columns of table "report_inactive_mrr_by_question_option" */
export enum report_inactive_mrr_by_question_option_select_column {
  /** column name */
  inactive_mrr = 'inactive_mrr',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_id = 'question_option_id'
}

/** aggregate stddev on columns */
export type report_inactive_mrr_by_question_option_stddev_fields = {
  __typename: 'report_inactive_mrr_by_question_option_stddev_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type report_inactive_mrr_by_question_option_stddev_pop_fields = {
  __typename: 'report_inactive_mrr_by_question_option_stddev_pop_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type report_inactive_mrr_by_question_option_stddev_samp_fields = {
  __typename: 'report_inactive_mrr_by_question_option_stddev_samp_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "report_inactive_mrr_by_question_option" */
export type report_inactive_mrr_by_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_inactive_mrr_by_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_inactive_mrr_by_question_option_stream_cursor_value_input = {
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type report_inactive_mrr_by_question_option_sum_fields = {
  __typename: 'report_inactive_mrr_by_question_option_sum_fields';
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type report_inactive_mrr_by_question_option_var_pop_fields = {
  __typename: 'report_inactive_mrr_by_question_option_var_pop_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type report_inactive_mrr_by_question_option_var_samp_fields = {
  __typename: 'report_inactive_mrr_by_question_option_var_samp_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type report_inactive_mrr_by_question_option_variance_fields = {
  __typename: 'report_inactive_mrr_by_question_option_variance_fields';
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

export type report_offer_accept_rate_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  group_by_account?: InputMaybe<Scalars['Boolean']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats = {
  __typename: 'report_offer_autopilot_stats';
  accepts?: Maybe<Scalars['Int']['output']>;
  avg_mrr?: Maybe<Scalars['numeric']['output']>;
  bypassed_mrr?: Maybe<Scalars['numeric']['output']>;
  bypasses?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  offer_autopilot?: Maybe<offer_autopilot>;
  /** An object relationship */
  offer_autopilot_round?: Maybe<offer_autopilot_round>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_variant?: Maybe<offer_variant>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
  total_mrr?: Maybe<Scalars['numeric']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_aggregate = {
  __typename: 'report_offer_autopilot_stats_aggregate';
  aggregate?: Maybe<report_offer_autopilot_stats_aggregate_fields>;
  nodes: Array<report_offer_autopilot_stats>;
};

export type report_offer_autopilot_stats_aggregate_bool_exp = {
  count?: InputMaybe<report_offer_autopilot_stats_aggregate_bool_exp_count>;
};

export type report_offer_autopilot_stats_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_aggregate_fields = {
  __typename: 'report_offer_autopilot_stats_aggregate_fields';
  avg?: Maybe<report_offer_autopilot_stats_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_offer_autopilot_stats_max_fields>;
  min?: Maybe<report_offer_autopilot_stats_min_fields>;
  stddev?: Maybe<report_offer_autopilot_stats_stddev_fields>;
  stddev_pop?: Maybe<report_offer_autopilot_stats_stddev_pop_fields>;
  stddev_samp?: Maybe<report_offer_autopilot_stats_stddev_samp_fields>;
  sum?: Maybe<report_offer_autopilot_stats_sum_fields>;
  var_pop?: Maybe<report_offer_autopilot_stats_var_pop_fields>;
  var_samp?: Maybe<report_offer_autopilot_stats_var_samp_fields>;
  variance?: Maybe<report_offer_autopilot_stats_variance_fields>;
};


/** aggregate fields of "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_aggregate_order_by = {
  avg?: InputMaybe<report_offer_autopilot_stats_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_offer_autopilot_stats_max_order_by>;
  min?: InputMaybe<report_offer_autopilot_stats_min_order_by>;
  stddev?: InputMaybe<report_offer_autopilot_stats_stddev_order_by>;
  stddev_pop?: InputMaybe<report_offer_autopilot_stats_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_offer_autopilot_stats_stddev_samp_order_by>;
  sum?: InputMaybe<report_offer_autopilot_stats_sum_order_by>;
  var_pop?: InputMaybe<report_offer_autopilot_stats_var_pop_order_by>;
  var_samp?: InputMaybe<report_offer_autopilot_stats_var_samp_order_by>;
  variance?: InputMaybe<report_offer_autopilot_stats_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_arr_rel_insert_input = {
  data: Array<report_offer_autopilot_stats_insert_input>;
};

/** aggregate avg on columns */
export type report_offer_autopilot_stats_avg_fields = {
  __typename: 'report_offer_autopilot_stats_avg_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_avg_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_offer_autopilot_stats". All fields are combined with a logical 'AND'. */
export type report_offer_autopilot_stats_bool_exp = {
  _and?: InputMaybe<Array<report_offer_autopilot_stats_bool_exp>>;
  _not?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
  _or?: InputMaybe<Array<report_offer_autopilot_stats_bool_exp>>;
  accepts?: InputMaybe<Int_comparison_exp>;
  avg_mrr?: InputMaybe<numeric_comparison_exp>;
  bypassed_mrr?: InputMaybe<numeric_comparison_exp>;
  bypasses?: InputMaybe<Int_comparison_exp>;
  declines?: InputMaybe<Int_comparison_exp>;
  lost_mrr?: InputMaybe<numeric_comparison_exp>;
  offer_autopilot?: InputMaybe<offer_autopilot_bool_exp>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_variant?: InputMaybe<offer_variant_bool_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  saved_mrr?: InputMaybe<numeric_comparison_exp>;
  total_mrr?: InputMaybe<numeric_comparison_exp>;
  views?: InputMaybe<bigint_comparison_exp>;
};

/** input type for inserting data into table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_insert_input = {
  accepts?: InputMaybe<Scalars['Int']['input']>;
  avg_mrr?: InputMaybe<Scalars['numeric']['input']>;
  bypassed_mrr?: InputMaybe<Scalars['numeric']['input']>;
  bypasses?: InputMaybe<Scalars['Int']['input']>;
  declines?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  offer_autopilot?: InputMaybe<offer_autopilot_obj_rel_insert_input>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
  total_mrr?: InputMaybe<Scalars['numeric']['input']>;
  views?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type report_offer_autopilot_stats_max_fields = {
  __typename: 'report_offer_autopilot_stats_max_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  avg_mrr?: Maybe<Scalars['numeric']['output']>;
  bypassed_mrr?: Maybe<Scalars['numeric']['output']>;
  bypasses?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
  total_mrr?: Maybe<Scalars['numeric']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_max_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_offer_autopilot_stats_min_fields = {
  __typename: 'report_offer_autopilot_stats_min_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  avg_mrr?: Maybe<Scalars['numeric']['output']>;
  bypassed_mrr?: Maybe<Scalars['numeric']['output']>;
  bypasses?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
  total_mrr?: Maybe<Scalars['numeric']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_min_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** input type for inserting object relation for remote table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_obj_rel_insert_input = {
  data: report_offer_autopilot_stats_insert_input;
};

/** Ordering options when selecting data from "report_offer_autopilot_stats". */
export type report_offer_autopilot_stats_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot?: InputMaybe<offer_autopilot_order_by>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant?: InputMaybe<offer_variant_order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** select columns of table "report_offer_autopilot_stats" */
export enum report_offer_autopilot_stats_select_column {
  /** column name */
  accepts = 'accepts',
  /** column name */
  avg_mrr = 'avg_mrr',
  /** column name */
  bypassed_mrr = 'bypassed_mrr',
  /** column name */
  bypasses = 'bypasses',
  /** column name */
  declines = 'declines',
  /** column name */
  lost_mrr = 'lost_mrr',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  saved_mrr = 'saved_mrr',
  /** column name */
  total_mrr = 'total_mrr',
  /** column name */
  views = 'views'
}

/** aggregate stddev on columns */
export type report_offer_autopilot_stats_stddev_fields = {
  __typename: 'report_offer_autopilot_stats_stddev_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_stddev_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_offer_autopilot_stats_stddev_pop_fields = {
  __typename: 'report_offer_autopilot_stats_stddev_pop_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_stddev_pop_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_offer_autopilot_stats_stddev_samp_fields = {
  __typename: 'report_offer_autopilot_stats_stddev_samp_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_stddev_samp_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_offer_autopilot_stats_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_offer_autopilot_stats_stream_cursor_value_input = {
  accepts?: InputMaybe<Scalars['Int']['input']>;
  avg_mrr?: InputMaybe<Scalars['numeric']['input']>;
  bypassed_mrr?: InputMaybe<Scalars['numeric']['input']>;
  bypasses?: InputMaybe<Scalars['Int']['input']>;
  declines?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
  total_mrr?: InputMaybe<Scalars['numeric']['input']>;
  views?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type report_offer_autopilot_stats_sum_fields = {
  __typename: 'report_offer_autopilot_stats_sum_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  avg_mrr?: Maybe<Scalars['numeric']['output']>;
  bypassed_mrr?: Maybe<Scalars['numeric']['output']>;
  bypasses?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
  total_mrr?: Maybe<Scalars['numeric']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_sum_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type report_offer_autopilot_stats_var_pop_fields = {
  __typename: 'report_offer_autopilot_stats_var_pop_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_var_pop_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_offer_autopilot_stats_var_samp_fields = {
  __typename: 'report_offer_autopilot_stats_var_samp_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_var_samp_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_offer_autopilot_stats_variance_fields = {
  __typename: 'report_offer_autopilot_stats_variance_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  avg_mrr?: Maybe<Scalars['Float']['output']>;
  bypassed_mrr?: Maybe<Scalars['Float']['output']>;
  bypasses?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
  total_mrr?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "report_offer_autopilot_stats" */
export type report_offer_autopilot_stats_variance_order_by = {
  accepts?: InputMaybe<order_by>;
  avg_mrr?: InputMaybe<order_by>;
  bypassed_mrr?: InputMaybe<order_by>;
  bypasses?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
  total_mrr?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

export type report_offer_group_accept_rate_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  group_by_account?: InputMaybe<Scalars['Boolean']['input']>;
  group_by_offer?: InputMaybe<Scalars['Boolean']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "report_offer_group_resolution_by_question_option" */
export type report_offer_group_resolution_by_question_option = {
  __typename: 'report_offer_group_resolution_by_question_option';
  count?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "report_offer_group_resolution_by_question_option" */
export type report_offer_group_resolution_by_question_option_aggregate = {
  __typename: 'report_offer_group_resolution_by_question_option_aggregate';
  aggregate?: Maybe<report_offer_group_resolution_by_question_option_aggregate_fields>;
  nodes: Array<report_offer_group_resolution_by_question_option>;
};

/** aggregate fields of "report_offer_group_resolution_by_question_option" */
export type report_offer_group_resolution_by_question_option_aggregate_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_aggregate_fields';
  avg?: Maybe<report_offer_group_resolution_by_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_offer_group_resolution_by_question_option_max_fields>;
  min?: Maybe<report_offer_group_resolution_by_question_option_min_fields>;
  stddev?: Maybe<report_offer_group_resolution_by_question_option_stddev_fields>;
  stddev_pop?: Maybe<report_offer_group_resolution_by_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_offer_group_resolution_by_question_option_stddev_samp_fields>;
  sum?: Maybe<report_offer_group_resolution_by_question_option_sum_fields>;
  var_pop?: Maybe<report_offer_group_resolution_by_question_option_var_pop_fields>;
  var_samp?: Maybe<report_offer_group_resolution_by_question_option_var_samp_fields>;
  variance?: Maybe<report_offer_group_resolution_by_question_option_variance_fields>;
};


/** aggregate fields of "report_offer_group_resolution_by_question_option" */
export type report_offer_group_resolution_by_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_offer_group_resolution_by_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type report_offer_group_resolution_by_question_option_avg_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "report_offer_group_resolution_by_question_option". All fields are combined with a logical 'AND'. */
export type report_offer_group_resolution_by_question_option_bool_exp = {
  _and?: InputMaybe<Array<report_offer_group_resolution_by_question_option_bool_exp>>;
  _not?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
  _or?: InputMaybe<Array<report_offer_group_resolution_by_question_option_bool_exp>>;
  count?: InputMaybe<bigint_comparison_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  resolution?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type report_offer_group_resolution_by_question_option_max_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_max_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type report_offer_group_resolution_by_question_option_min_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_min_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "report_offer_group_resolution_by_question_option". */
export type report_offer_group_resolution_by_question_option_order_by = {
  count?: InputMaybe<order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  resolution?: InputMaybe<order_by>;
};

/** select columns of table "report_offer_group_resolution_by_question_option" */
export enum report_offer_group_resolution_by_question_option_select_column {
  /** column name */
  count = 'count',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  resolution = 'resolution'
}

/** aggregate stddev on columns */
export type report_offer_group_resolution_by_question_option_stddev_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type report_offer_group_resolution_by_question_option_stddev_pop_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type report_offer_group_resolution_by_question_option_stddev_samp_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "report_offer_group_resolution_by_question_option" */
export type report_offer_group_resolution_by_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_offer_group_resolution_by_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_offer_group_resolution_by_question_option_stream_cursor_value_input = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  resolution?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type report_offer_group_resolution_by_question_option_sum_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_sum_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type report_offer_group_resolution_by_question_option_var_pop_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type report_offer_group_resolution_by_question_option_var_samp_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type report_offer_group_resolution_by_question_option_variance_fields = {
  __typename: 'report_offer_group_resolution_by_question_option_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

export type report_offer_group_retained_revenue_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_offer_rate_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  filter_by_offer_id?: InputMaybe<Scalars['Int']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_offer_resolution_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "report_offer_resolution_by_question_option" */
export type report_offer_resolution_by_question_option = {
  __typename: 'report_offer_resolution_by_question_option';
  count?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_option?: Maybe<question_option>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "report_offer_resolution_by_question_option" */
export type report_offer_resolution_by_question_option_aggregate = {
  __typename: 'report_offer_resolution_by_question_option_aggregate';
  aggregate?: Maybe<report_offer_resolution_by_question_option_aggregate_fields>;
  nodes: Array<report_offer_resolution_by_question_option>;
};

/** aggregate fields of "report_offer_resolution_by_question_option" */
export type report_offer_resolution_by_question_option_aggregate_fields = {
  __typename: 'report_offer_resolution_by_question_option_aggregate_fields';
  avg?: Maybe<report_offer_resolution_by_question_option_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_offer_resolution_by_question_option_max_fields>;
  min?: Maybe<report_offer_resolution_by_question_option_min_fields>;
  stddev?: Maybe<report_offer_resolution_by_question_option_stddev_fields>;
  stddev_pop?: Maybe<report_offer_resolution_by_question_option_stddev_pop_fields>;
  stddev_samp?: Maybe<report_offer_resolution_by_question_option_stddev_samp_fields>;
  sum?: Maybe<report_offer_resolution_by_question_option_sum_fields>;
  var_pop?: Maybe<report_offer_resolution_by_question_option_var_pop_fields>;
  var_samp?: Maybe<report_offer_resolution_by_question_option_var_samp_fields>;
  variance?: Maybe<report_offer_resolution_by_question_option_variance_fields>;
};


/** aggregate fields of "report_offer_resolution_by_question_option" */
export type report_offer_resolution_by_question_option_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_offer_resolution_by_question_option_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type report_offer_resolution_by_question_option_avg_fields = {
  __typename: 'report_offer_resolution_by_question_option_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "report_offer_resolution_by_question_option". All fields are combined with a logical 'AND'. */
export type report_offer_resolution_by_question_option_bool_exp = {
  _and?: InputMaybe<Array<report_offer_resolution_by_question_option_bool_exp>>;
  _not?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
  _or?: InputMaybe<Array<report_offer_resolution_by_question_option_bool_exp>>;
  count?: InputMaybe<bigint_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
  resolution?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type report_offer_resolution_by_question_option_max_fields = {
  __typename: 'report_offer_resolution_by_question_option_max_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type report_offer_resolution_by_question_option_min_fields = {
  __typename: 'report_offer_resolution_by_question_option_min_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "report_offer_resolution_by_question_option". */
export type report_offer_resolution_by_question_option_order_by = {
  count?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
  resolution?: InputMaybe<order_by>;
};

/** select columns of table "report_offer_resolution_by_question_option" */
export enum report_offer_resolution_by_question_option_select_column {
  /** column name */
  count = 'count',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  question_option_id = 'question_option_id',
  /** column name */
  resolution = 'resolution'
}

/** aggregate stddev on columns */
export type report_offer_resolution_by_question_option_stddev_fields = {
  __typename: 'report_offer_resolution_by_question_option_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type report_offer_resolution_by_question_option_stddev_pop_fields = {
  __typename: 'report_offer_resolution_by_question_option_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type report_offer_resolution_by_question_option_stddev_samp_fields = {
  __typename: 'report_offer_resolution_by_question_option_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "report_offer_resolution_by_question_option" */
export type report_offer_resolution_by_question_option_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_offer_resolution_by_question_option_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_offer_resolution_by_question_option_stream_cursor_value_input = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
  resolution?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type report_offer_resolution_by_question_option_sum_fields = {
  __typename: 'report_offer_resolution_by_question_option_sum_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type report_offer_resolution_by_question_option_var_pop_fields = {
  __typename: 'report_offer_resolution_by_question_option_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type report_offer_resolution_by_question_option_var_samp_fields = {
  __typename: 'report_offer_resolution_by_question_option_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type report_offer_resolution_by_question_option_variance_fields = {
  __typename: 'report_offer_resolution_by_question_option_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

export type report_offer_retained_revenue_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "report_offer_test_stats" */
export type report_offer_test_stats = {
  __typename: 'report_offer_test_stats';
  accepts?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_test?: Maybe<offer_test>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "report_offer_test_stats" */
export type report_offer_test_stats_aggregate = {
  __typename: 'report_offer_test_stats_aggregate';
  aggregate?: Maybe<report_offer_test_stats_aggregate_fields>;
  nodes: Array<report_offer_test_stats>;
};

export type report_offer_test_stats_aggregate_bool_exp = {
  count?: InputMaybe<report_offer_test_stats_aggregate_bool_exp_count>;
};

export type report_offer_test_stats_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<report_offer_test_stats_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "report_offer_test_stats" */
export type report_offer_test_stats_aggregate_fields = {
  __typename: 'report_offer_test_stats_aggregate_fields';
  avg?: Maybe<report_offer_test_stats_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_offer_test_stats_max_fields>;
  min?: Maybe<report_offer_test_stats_min_fields>;
  stddev?: Maybe<report_offer_test_stats_stddev_fields>;
  stddev_pop?: Maybe<report_offer_test_stats_stddev_pop_fields>;
  stddev_samp?: Maybe<report_offer_test_stats_stddev_samp_fields>;
  sum?: Maybe<report_offer_test_stats_sum_fields>;
  var_pop?: Maybe<report_offer_test_stats_var_pop_fields>;
  var_samp?: Maybe<report_offer_test_stats_var_samp_fields>;
  variance?: Maybe<report_offer_test_stats_variance_fields>;
};


/** aggregate fields of "report_offer_test_stats" */
export type report_offer_test_stats_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "report_offer_test_stats" */
export type report_offer_test_stats_aggregate_order_by = {
  avg?: InputMaybe<report_offer_test_stats_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<report_offer_test_stats_max_order_by>;
  min?: InputMaybe<report_offer_test_stats_min_order_by>;
  stddev?: InputMaybe<report_offer_test_stats_stddev_order_by>;
  stddev_pop?: InputMaybe<report_offer_test_stats_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<report_offer_test_stats_stddev_samp_order_by>;
  sum?: InputMaybe<report_offer_test_stats_sum_order_by>;
  var_pop?: InputMaybe<report_offer_test_stats_var_pop_order_by>;
  var_samp?: InputMaybe<report_offer_test_stats_var_samp_order_by>;
  variance?: InputMaybe<report_offer_test_stats_variance_order_by>;
};

/** input type for inserting array relation for remote table "report_offer_test_stats" */
export type report_offer_test_stats_arr_rel_insert_input = {
  data: Array<report_offer_test_stats_insert_input>;
};

/** aggregate avg on columns */
export type report_offer_test_stats_avg_fields = {
  __typename: 'report_offer_test_stats_avg_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_avg_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "report_offer_test_stats". All fields are combined with a logical 'AND'. */
export type report_offer_test_stats_bool_exp = {
  _and?: InputMaybe<Array<report_offer_test_stats_bool_exp>>;
  _not?: InputMaybe<report_offer_test_stats_bool_exp>;
  _or?: InputMaybe<Array<report_offer_test_stats_bool_exp>>;
  accepts?: InputMaybe<Int_comparison_exp>;
  declines?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  views?: InputMaybe<bigint_comparison_exp>;
};

/** input type for inserting data into table "report_offer_test_stats" */
export type report_offer_test_stats_insert_input = {
  accepts?: InputMaybe<Scalars['Int']['input']>;
  declines?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type report_offer_test_stats_max_fields = {
  __typename: 'report_offer_test_stats_max_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_max_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type report_offer_test_stats_min_fields = {
  __typename: 'report_offer_test_stats_min_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_min_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "report_offer_test_stats". */
export type report_offer_test_stats_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** select columns of table "report_offer_test_stats" */
export enum report_offer_test_stats_select_column {
  /** column name */
  accepts = 'accepts',
  /** column name */
  declines = 'declines',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  views = 'views'
}

/** aggregate stddev on columns */
export type report_offer_test_stats_stddev_fields = {
  __typename: 'report_offer_test_stats_stddev_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_stddev_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type report_offer_test_stats_stddev_pop_fields = {
  __typename: 'report_offer_test_stats_stddev_pop_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_stddev_pop_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type report_offer_test_stats_stddev_samp_fields = {
  __typename: 'report_offer_test_stats_stddev_samp_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_stddev_samp_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "report_offer_test_stats" */
export type report_offer_test_stats_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_offer_test_stats_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_offer_test_stats_stream_cursor_value_input = {
  accepts?: InputMaybe<Scalars['Int']['input']>;
  declines?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type report_offer_test_stats_sum_fields = {
  __typename: 'report_offer_test_stats_sum_fields';
  accepts?: Maybe<Scalars['Int']['output']>;
  declines?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_sum_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type report_offer_test_stats_var_pop_fields = {
  __typename: 'report_offer_test_stats_var_pop_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_var_pop_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type report_offer_test_stats_var_samp_fields = {
  __typename: 'report_offer_test_stats_var_samp_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_var_samp_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type report_offer_test_stats_variance_fields = {
  __typename: 'report_offer_test_stats_variance_fields';
  accepts?: Maybe<Scalars['Float']['output']>;
  declines?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "report_offer_test_stats" */
export type report_offer_test_stats_variance_order_by = {
  accepts?: InputMaybe<order_by>;
  declines?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  views?: InputMaybe<order_by>;
};

export type report_question_answer_radio_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_question_answer_radio_inactive_mrr_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_question_answer_sentiment_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "report_recurring_revenue" */
export type report_recurring_revenue = {
  __typename: 'report_recurring_revenue';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  date: Scalars['date']['output'];
  deflected_mrr: Scalars['numeric']['output'];
  saved_mrr: Scalars['numeric']['output'];
};

/** aggregated selection of "report_recurring_revenue" */
export type report_recurring_revenue_aggregate = {
  __typename: 'report_recurring_revenue_aggregate';
  aggregate?: Maybe<report_recurring_revenue_aggregate_fields>;
  nodes: Array<report_recurring_revenue>;
};

/** aggregate fields of "report_recurring_revenue" */
export type report_recurring_revenue_aggregate_fields = {
  __typename: 'report_recurring_revenue_aggregate_fields';
  avg?: Maybe<report_recurring_revenue_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<report_recurring_revenue_max_fields>;
  min?: Maybe<report_recurring_revenue_min_fields>;
  stddev?: Maybe<report_recurring_revenue_stddev_fields>;
  stddev_pop?: Maybe<report_recurring_revenue_stddev_pop_fields>;
  stddev_samp?: Maybe<report_recurring_revenue_stddev_samp_fields>;
  sum?: Maybe<report_recurring_revenue_sum_fields>;
  var_pop?: Maybe<report_recurring_revenue_var_pop_fields>;
  var_samp?: Maybe<report_recurring_revenue_var_samp_fields>;
  variance?: Maybe<report_recurring_revenue_variance_fields>;
};


/** aggregate fields of "report_recurring_revenue" */
export type report_recurring_revenue_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<report_recurring_revenue_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type report_recurring_revenue_avg_fields = {
  __typename: 'report_recurring_revenue_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "report_recurring_revenue". All fields are combined with a logical 'AND'. */
export type report_recurring_revenue_bool_exp = {
  _and?: InputMaybe<Array<report_recurring_revenue_bool_exp>>;
  _not?: InputMaybe<report_recurring_revenue_bool_exp>;
  _or?: InputMaybe<Array<report_recurring_revenue_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<date_comparison_exp>;
  deflected_mrr?: InputMaybe<numeric_comparison_exp>;
  saved_mrr?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "report_recurring_revenue" */
export enum report_recurring_revenue_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  report_recurring_revenue_pkey = 'report_recurring_revenue_pkey'
}

/** input type for incrementing numeric columns in table "report_recurring_revenue" */
export type report_recurring_revenue_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deflected_mrr?: InputMaybe<Scalars['numeric']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "report_recurring_revenue" */
export type report_recurring_revenue_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  deflected_mrr?: InputMaybe<Scalars['numeric']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type report_recurring_revenue_max_fields = {
  __typename: 'report_recurring_revenue_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  deflected_mrr?: Maybe<Scalars['numeric']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type report_recurring_revenue_min_fields = {
  __typename: 'report_recurring_revenue_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  deflected_mrr?: Maybe<Scalars['numeric']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "report_recurring_revenue" */
export type report_recurring_revenue_mutation_response = {
  __typename: 'report_recurring_revenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<report_recurring_revenue>;
};

/** on_conflict condition type for table "report_recurring_revenue" */
export type report_recurring_revenue_on_conflict = {
  constraint: report_recurring_revenue_constraint;
  update_columns?: Array<report_recurring_revenue_update_column>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};

/** Ordering options when selecting data from "report_recurring_revenue". */
export type report_recurring_revenue_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  deflected_mrr?: InputMaybe<order_by>;
  saved_mrr?: InputMaybe<order_by>;
};

/** primary key columns input for table: report_recurring_revenue */
export type report_recurring_revenue_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['date']['input'];
};

/** select columns of table "report_recurring_revenue" */
export enum report_recurring_revenue_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  deflected_mrr = 'deflected_mrr',
  /** column name */
  saved_mrr = 'saved_mrr'
}

/** input type for updating data in table "report_recurring_revenue" */
export type report_recurring_revenue_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  deflected_mrr?: InputMaybe<Scalars['numeric']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type report_recurring_revenue_stddev_fields = {
  __typename: 'report_recurring_revenue_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type report_recurring_revenue_stddev_pop_fields = {
  __typename: 'report_recurring_revenue_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type report_recurring_revenue_stddev_samp_fields = {
  __typename: 'report_recurring_revenue_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "report_recurring_revenue" */
export type report_recurring_revenue_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: report_recurring_revenue_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type report_recurring_revenue_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  deflected_mrr?: InputMaybe<Scalars['numeric']['input']>;
  saved_mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type report_recurring_revenue_sum_fields = {
  __typename: 'report_recurring_revenue_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflected_mrr?: Maybe<Scalars['numeric']['output']>;
  saved_mrr?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "report_recurring_revenue" */
export enum report_recurring_revenue_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  deflected_mrr = 'deflected_mrr',
  /** column name */
  saved_mrr = 'saved_mrr'
}

export type report_recurring_revenue_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<report_recurring_revenue_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<report_recurring_revenue_set_input>;
  /** filter the rows which have to be updated */
  where: report_recurring_revenue_bool_exp;
};

/** aggregate var_pop on columns */
export type report_recurring_revenue_var_pop_fields = {
  __typename: 'report_recurring_revenue_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type report_recurring_revenue_var_samp_fields = {
  __typename: 'report_recurring_revenue_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type report_recurring_revenue_variance_fields = {
  __typename: 'report_recurring_revenue_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected_mrr?: Maybe<Scalars['Float']['output']>;
  saved_mrr?: Maybe<Scalars['Float']['output']>;
};

export type report_retained_revenue_args = {
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_retention_trends_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

export type report_subscriber_flow_status_args = {
  filter_by_flow_ids?: InputMaybe<Scalars['jsonb']['input']>;
  filter_by_segment_ids?: InputMaybe<Scalars['jsonb']['input']>;
  group_by?: InputMaybe<Scalars['String']['input']>;
  time_zone?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "revenue_log" */
export type revenue_log = {
  __typename: 'revenue_log';
  amount: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  occurred_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id: Scalars['Int']['output'];
  platform_invoice_id: Scalars['String']['output'];
  platform_refund_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  revenue_log_revenue_type: revenue_log_revenue_type;
  /** An object relationship */
  revenue_log_type: revenue_log_type;
  revenue_type: revenue_log_revenue_type_enum;
  /** An object relationship */
  subscriber: subscriber;
  subscriber_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
  type: revenue_log_type_enum;
};

/** aggregated selection of "revenue_log" */
export type revenue_log_aggregate = {
  __typename: 'revenue_log_aggregate';
  aggregate?: Maybe<revenue_log_aggregate_fields>;
  nodes: Array<revenue_log>;
};

export type revenue_log_aggregate_bool_exp = {
  count?: InputMaybe<revenue_log_aggregate_bool_exp_count>;
};

export type revenue_log_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<revenue_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<revenue_log_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "revenue_log" */
export type revenue_log_aggregate_fields = {
  __typename: 'revenue_log_aggregate_fields';
  avg?: Maybe<revenue_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<revenue_log_max_fields>;
  min?: Maybe<revenue_log_min_fields>;
  stddev?: Maybe<revenue_log_stddev_fields>;
  stddev_pop?: Maybe<revenue_log_stddev_pop_fields>;
  stddev_samp?: Maybe<revenue_log_stddev_samp_fields>;
  sum?: Maybe<revenue_log_sum_fields>;
  var_pop?: Maybe<revenue_log_var_pop_fields>;
  var_samp?: Maybe<revenue_log_var_samp_fields>;
  variance?: Maybe<revenue_log_variance_fields>;
};


/** aggregate fields of "revenue_log" */
export type revenue_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<revenue_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "revenue_log" */
export type revenue_log_aggregate_order_by = {
  avg?: InputMaybe<revenue_log_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<revenue_log_max_order_by>;
  min?: InputMaybe<revenue_log_min_order_by>;
  stddev?: InputMaybe<revenue_log_stddev_order_by>;
  stddev_pop?: InputMaybe<revenue_log_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<revenue_log_stddev_samp_order_by>;
  sum?: InputMaybe<revenue_log_sum_order_by>;
  var_pop?: InputMaybe<revenue_log_var_pop_order_by>;
  var_samp?: InputMaybe<revenue_log_var_samp_order_by>;
  variance?: InputMaybe<revenue_log_variance_order_by>;
};

/** input type for inserting array relation for remote table "revenue_log" */
export type revenue_log_arr_rel_insert_input = {
  data: Array<revenue_log_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<revenue_log_on_conflict>;
};

/** aggregate avg on columns */
export type revenue_log_avg_fields = {
  __typename: 'revenue_log_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "revenue_log" */
export type revenue_log_avg_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "revenue_log". All fields are combined with a logical 'AND'. */
export type revenue_log_bool_exp = {
  _and?: InputMaybe<Array<revenue_log_bool_exp>>;
  _not?: InputMaybe<revenue_log_bool_exp>;
  _or?: InputMaybe<Array<revenue_log_bool_exp>>;
  amount?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  occurred_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_invoice_id?: InputMaybe<String_comparison_exp>;
  platform_refund_id?: InputMaybe<String_comparison_exp>;
  revenue_log_revenue_type?: InputMaybe<revenue_log_revenue_type_bool_exp>;
  revenue_log_type?: InputMaybe<revenue_log_type_bool_exp>;
  revenue_type?: InputMaybe<revenue_log_revenue_type_enum_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  type?: InputMaybe<revenue_log_type_enum_comparison_exp>;
};

/** unique or primary key constraints on table "revenue_log" */
export enum revenue_log_constraint {
  /** unique or primary key constraint on columns "id" */
  recovered_revenue_pkey = 'recovered_revenue_pkey',
  /** unique or primary key constraint on columns "platform_connection_id", "platform_invoice_id", "platform_refund_id" */
  revenue_log_platform_connection_id_platform_inv = 'revenue_log_platform_connection_id_platform_inv'
}

/** input type for incrementing numeric columns in table "revenue_log" */
export type revenue_log_inc_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "revenue_log" */
export type revenue_log_insert_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
  platform_refund_id?: InputMaybe<Scalars['String']['input']>;
  revenue_log_revenue_type?: InputMaybe<revenue_log_revenue_type_obj_rel_insert_input>;
  revenue_log_type?: InputMaybe<revenue_log_type_obj_rel_insert_input>;
  revenue_type?: InputMaybe<revenue_log_revenue_type_enum>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<revenue_log_type_enum>;
};

/** aggregate max on columns */
export type revenue_log_max_fields = {
  __typename: 'revenue_log_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_invoice_id?: Maybe<Scalars['String']['output']>;
  platform_refund_id?: Maybe<Scalars['String']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "revenue_log" */
export type revenue_log_max_order_by = {
  amount?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
  platform_refund_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type revenue_log_min_fields = {
  __typename: 'revenue_log_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_invoice_id?: Maybe<Scalars['String']['output']>;
  platform_refund_id?: Maybe<Scalars['String']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "revenue_log" */
export type revenue_log_min_order_by = {
  amount?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
  platform_refund_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "revenue_log" */
export type revenue_log_mutation_response = {
  __typename: 'revenue_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<revenue_log>;
};

/** on_conflict condition type for table "revenue_log" */
export type revenue_log_on_conflict = {
  constraint: revenue_log_constraint;
  update_columns?: Array<revenue_log_update_column>;
  where?: InputMaybe<revenue_log_bool_exp>;
};

/** Ordering options when selecting data from "revenue_log". */
export type revenue_log_order_by = {
  amount?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_invoice_id?: InputMaybe<order_by>;
  platform_refund_id?: InputMaybe<order_by>;
  revenue_log_revenue_type?: InputMaybe<revenue_log_revenue_type_order_by>;
  revenue_log_type?: InputMaybe<revenue_log_type_order_by>;
  revenue_type?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  type?: InputMaybe<order_by>;
};

/** primary key columns input for table: revenue_log */
export type revenue_log_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "revenue_log_revenue_type" */
export type revenue_log_revenue_type = {
  __typename: 'revenue_log_revenue_type';
  /** An array relationship */
  revenue_logs: Array<revenue_log>;
  /** An aggregate relationship */
  revenue_logs_aggregate: revenue_log_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "revenue_log_revenue_type" */
export type revenue_log_revenue_typerevenue_logsArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "revenue_log_revenue_type" */
export type revenue_log_revenue_typerevenue_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};

/** aggregated selection of "revenue_log_revenue_type" */
export type revenue_log_revenue_type_aggregate = {
  __typename: 'revenue_log_revenue_type_aggregate';
  aggregate?: Maybe<revenue_log_revenue_type_aggregate_fields>;
  nodes: Array<revenue_log_revenue_type>;
};

/** aggregate fields of "revenue_log_revenue_type" */
export type revenue_log_revenue_type_aggregate_fields = {
  __typename: 'revenue_log_revenue_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<revenue_log_revenue_type_max_fields>;
  min?: Maybe<revenue_log_revenue_type_min_fields>;
};


/** aggregate fields of "revenue_log_revenue_type" */
export type revenue_log_revenue_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<revenue_log_revenue_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "revenue_log_revenue_type". All fields are combined with a logical 'AND'. */
export type revenue_log_revenue_type_bool_exp = {
  _and?: InputMaybe<Array<revenue_log_revenue_type_bool_exp>>;
  _not?: InputMaybe<revenue_log_revenue_type_bool_exp>;
  _or?: InputMaybe<Array<revenue_log_revenue_type_bool_exp>>;
  revenue_logs?: InputMaybe<revenue_log_bool_exp>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "revenue_log_revenue_type" */
export enum revenue_log_revenue_type_constraint {
  /** unique or primary key constraint on columns "value" */
  revenue_log_revenue_type_pkey = 'revenue_log_revenue_type_pkey'
}

export enum revenue_log_revenue_type_enum {
  deflected = 'deflected',
  saved = 'saved'
}

/** Boolean expression to compare columns of type "revenue_log_revenue_type_enum". All fields are combined with logical 'AND'. */
export type revenue_log_revenue_type_enum_comparison_exp = {
  _eq?: InputMaybe<revenue_log_revenue_type_enum>;
  _in?: InputMaybe<Array<revenue_log_revenue_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<revenue_log_revenue_type_enum>;
  _nin?: InputMaybe<Array<revenue_log_revenue_type_enum>>;
};

/** input type for inserting data into table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_insert_input = {
  revenue_logs?: InputMaybe<revenue_log_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type revenue_log_revenue_type_max_fields = {
  __typename: 'revenue_log_revenue_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type revenue_log_revenue_type_min_fields = {
  __typename: 'revenue_log_revenue_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_mutation_response = {
  __typename: 'revenue_log_revenue_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<revenue_log_revenue_type>;
};

/** input type for inserting object relation for remote table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_obj_rel_insert_input = {
  data: revenue_log_revenue_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<revenue_log_revenue_type_on_conflict>;
};

/** on_conflict condition type for table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_on_conflict = {
  constraint: revenue_log_revenue_type_constraint;
  update_columns?: Array<revenue_log_revenue_type_update_column>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};

/** Ordering options when selecting data from "revenue_log_revenue_type". */
export type revenue_log_revenue_type_order_by = {
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: revenue_log_revenue_type */
export type revenue_log_revenue_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "revenue_log_revenue_type" */
export enum revenue_log_revenue_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "revenue_log_revenue_type" */
export type revenue_log_revenue_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: revenue_log_revenue_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type revenue_log_revenue_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "revenue_log_revenue_type" */
export enum revenue_log_revenue_type_update_column {
  /** column name */
  value = 'value'
}

export type revenue_log_revenue_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<revenue_log_revenue_type_set_input>;
  /** filter the rows which have to be updated */
  where: revenue_log_revenue_type_bool_exp;
};

/** select columns of table "revenue_log" */
export enum revenue_log_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_invoice_id = 'platform_invoice_id',
  /** column name */
  platform_refund_id = 'platform_refund_id',
  /** column name */
  revenue_type = 'revenue_type',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "revenue_log" */
export type revenue_log_set_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
  platform_refund_id?: InputMaybe<Scalars['String']['input']>;
  revenue_type?: InputMaybe<revenue_log_revenue_type_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<revenue_log_type_enum>;
};

/** aggregate stddev on columns */
export type revenue_log_stddev_fields = {
  __typename: 'revenue_log_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "revenue_log" */
export type revenue_log_stddev_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type revenue_log_stddev_pop_fields = {
  __typename: 'revenue_log_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "revenue_log" */
export type revenue_log_stddev_pop_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type revenue_log_stddev_samp_fields = {
  __typename: 'revenue_log_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "revenue_log" */
export type revenue_log_stddev_samp_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "revenue_log" */
export type revenue_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: revenue_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type revenue_log_stream_cursor_value_input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_invoice_id?: InputMaybe<Scalars['String']['input']>;
  platform_refund_id?: InputMaybe<Scalars['String']['input']>;
  revenue_type?: InputMaybe<revenue_log_revenue_type_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<revenue_log_type_enum>;
};

/** aggregate sum on columns */
export type revenue_log_sum_fields = {
  __typename: 'revenue_log_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "revenue_log" */
export type revenue_log_sum_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "revenue_log_type" */
export type revenue_log_type = {
  __typename: 'revenue_log_type';
  /** An array relationship */
  revenue_logs: Array<revenue_log>;
  /** An aggregate relationship */
  revenue_logs_aggregate: revenue_log_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "revenue_log_type" */
export type revenue_log_typerevenue_logsArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "revenue_log_type" */
export type revenue_log_typerevenue_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};

/** aggregated selection of "revenue_log_type" */
export type revenue_log_type_aggregate = {
  __typename: 'revenue_log_type_aggregate';
  aggregate?: Maybe<revenue_log_type_aggregate_fields>;
  nodes: Array<revenue_log_type>;
};

/** aggregate fields of "revenue_log_type" */
export type revenue_log_type_aggregate_fields = {
  __typename: 'revenue_log_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<revenue_log_type_max_fields>;
  min?: Maybe<revenue_log_type_min_fields>;
};


/** aggregate fields of "revenue_log_type" */
export type revenue_log_type_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<revenue_log_type_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "revenue_log_type". All fields are combined with a logical 'AND'. */
export type revenue_log_type_bool_exp = {
  _and?: InputMaybe<Array<revenue_log_type_bool_exp>>;
  _not?: InputMaybe<revenue_log_type_bool_exp>;
  _or?: InputMaybe<Array<revenue_log_type_bool_exp>>;
  revenue_logs?: InputMaybe<revenue_log_bool_exp>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "revenue_log_type" */
export enum revenue_log_type_constraint {
  /** unique or primary key constraint on columns "value" */
  recovered_revenue_type_pkey = 'recovered_revenue_type_pkey'
}

export enum revenue_log_type_enum {
  payment = 'payment',
  refund = 'refund'
}

/** Boolean expression to compare columns of type "revenue_log_type_enum". All fields are combined with logical 'AND'. */
export type revenue_log_type_enum_comparison_exp = {
  _eq?: InputMaybe<revenue_log_type_enum>;
  _in?: InputMaybe<Array<revenue_log_type_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<revenue_log_type_enum>;
  _nin?: InputMaybe<Array<revenue_log_type_enum>>;
};

/** input type for inserting data into table "revenue_log_type" */
export type revenue_log_type_insert_input = {
  revenue_logs?: InputMaybe<revenue_log_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type revenue_log_type_max_fields = {
  __typename: 'revenue_log_type_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type revenue_log_type_min_fields = {
  __typename: 'revenue_log_type_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "revenue_log_type" */
export type revenue_log_type_mutation_response = {
  __typename: 'revenue_log_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<revenue_log_type>;
};

/** input type for inserting object relation for remote table "revenue_log_type" */
export type revenue_log_type_obj_rel_insert_input = {
  data: revenue_log_type_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<revenue_log_type_on_conflict>;
};

/** on_conflict condition type for table "revenue_log_type" */
export type revenue_log_type_on_conflict = {
  constraint: revenue_log_type_constraint;
  update_columns?: Array<revenue_log_type_update_column>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};

/** Ordering options when selecting data from "revenue_log_type". */
export type revenue_log_type_order_by = {
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: revenue_log_type */
export type revenue_log_type_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "revenue_log_type" */
export enum revenue_log_type_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "revenue_log_type" */
export type revenue_log_type_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "revenue_log_type" */
export type revenue_log_type_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: revenue_log_type_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type revenue_log_type_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "revenue_log_type" */
export enum revenue_log_type_update_column {
  /** column name */
  value = 'value'
}

export type revenue_log_type_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<revenue_log_type_set_input>;
  /** filter the rows which have to be updated */
  where: revenue_log_type_bool_exp;
};

/** update columns of table "revenue_log" */
export enum revenue_log_update_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_invoice_id = 'platform_invoice_id',
  /** column name */
  platform_refund_id = 'platform_refund_id',
  /** column name */
  revenue_type = 'revenue_type',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  type = 'type'
}

export type revenue_log_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<revenue_log_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<revenue_log_set_input>;
  /** filter the rows which have to be updated */
  where: revenue_log_bool_exp;
};

/** aggregate var_pop on columns */
export type revenue_log_var_pop_fields = {
  __typename: 'revenue_log_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "revenue_log" */
export type revenue_log_var_pop_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type revenue_log_var_samp_fields = {
  __typename: 'revenue_log_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "revenue_log" */
export type revenue_log_var_samp_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type revenue_log_variance_fields = {
  __typename: 'revenue_log_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "revenue_log" */
export type revenue_log_variance_order_by = {
  amount?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "rule_group_mode" */
export type rule_group_mode = {
  __typename: 'rule_group_mode';
  value: Scalars['String']['output'];
};

/** aggregated selection of "rule_group_mode" */
export type rule_group_mode_aggregate = {
  __typename: 'rule_group_mode_aggregate';
  aggregate?: Maybe<rule_group_mode_aggregate_fields>;
  nodes: Array<rule_group_mode>;
};

/** aggregate fields of "rule_group_mode" */
export type rule_group_mode_aggregate_fields = {
  __typename: 'rule_group_mode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<rule_group_mode_max_fields>;
  min?: Maybe<rule_group_mode_min_fields>;
};


/** aggregate fields of "rule_group_mode" */
export type rule_group_mode_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<rule_group_mode_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "rule_group_mode". All fields are combined with a logical 'AND'. */
export type rule_group_mode_bool_exp = {
  _and?: InputMaybe<Array<rule_group_mode_bool_exp>>;
  _not?: InputMaybe<rule_group_mode_bool_exp>;
  _or?: InputMaybe<Array<rule_group_mode_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "rule_group_mode" */
export enum rule_group_mode_constraint {
  /** unique or primary key constraint on columns "value" */
  rule_group_mode_pkey = 'rule_group_mode_pkey'
}

export enum rule_group_mode_enum {
  deflection = 'deflection',
  offer = 'offer'
}

/** Boolean expression to compare columns of type "rule_group_mode_enum". All fields are combined with logical 'AND'. */
export type rule_group_mode_enum_comparison_exp = {
  _eq?: InputMaybe<rule_group_mode_enum>;
  _in?: InputMaybe<Array<rule_group_mode_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<rule_group_mode_enum>;
  _nin?: InputMaybe<Array<rule_group_mode_enum>>;
};

/** input type for inserting data into table "rule_group_mode" */
export type rule_group_mode_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type rule_group_mode_max_fields = {
  __typename: 'rule_group_mode_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type rule_group_mode_min_fields = {
  __typename: 'rule_group_mode_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "rule_group_mode" */
export type rule_group_mode_mutation_response = {
  __typename: 'rule_group_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<rule_group_mode>;
};

/** on_conflict condition type for table "rule_group_mode" */
export type rule_group_mode_on_conflict = {
  constraint: rule_group_mode_constraint;
  update_columns?: Array<rule_group_mode_update_column>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};

/** Ordering options when selecting data from "rule_group_mode". */
export type rule_group_mode_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: rule_group_mode */
export type rule_group_mode_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "rule_group_mode" */
export enum rule_group_mode_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "rule_group_mode" */
export type rule_group_mode_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "rule_group_mode" */
export type rule_group_mode_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: rule_group_mode_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type rule_group_mode_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "rule_group_mode" */
export enum rule_group_mode_update_column {
  /** column name */
  value = 'value'
}

export type rule_group_mode_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<rule_group_mode_set_input>;
  /** filter the rows which have to be updated */
  where: rule_group_mode_bool_exp;
};

/** columns and relationships of "script_log" */
export type script_log = {
  __typename: 'script_log';
  id: Scalars['Int']['output'];
  log: Scalars['jsonb']['output'];
  occurred_at: Scalars['timestamptz']['output'];
  token: Scalars['String']['output'];
};


/** columns and relationships of "script_log" */
export type script_loglogArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "script_log" */
export type script_log_aggregate = {
  __typename: 'script_log_aggregate';
  aggregate?: Maybe<script_log_aggregate_fields>;
  nodes: Array<script_log>;
};

/** aggregate fields of "script_log" */
export type script_log_aggregate_fields = {
  __typename: 'script_log_aggregate_fields';
  avg?: Maybe<script_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<script_log_max_fields>;
  min?: Maybe<script_log_min_fields>;
  stddev?: Maybe<script_log_stddev_fields>;
  stddev_pop?: Maybe<script_log_stddev_pop_fields>;
  stddev_samp?: Maybe<script_log_stddev_samp_fields>;
  sum?: Maybe<script_log_sum_fields>;
  var_pop?: Maybe<script_log_var_pop_fields>;
  var_samp?: Maybe<script_log_var_samp_fields>;
  variance?: Maybe<script_log_variance_fields>;
};


/** aggregate fields of "script_log" */
export type script_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<script_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type script_log_append_input = {
  log?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type script_log_avg_fields = {
  __typename: 'script_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "script_log". All fields are combined with a logical 'AND'. */
export type script_log_bool_exp = {
  _and?: InputMaybe<Array<script_log_bool_exp>>;
  _not?: InputMaybe<script_log_bool_exp>;
  _or?: InputMaybe<Array<script_log_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  log?: InputMaybe<jsonb_comparison_exp>;
  occurred_at?: InputMaybe<timestamptz_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "script_log" */
export enum script_log_constraint {
  /** unique or primary key constraint on columns "id" */
  script_log_pkey = 'script_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type script_log_delete_at_path_input = {
  log?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type script_log_delete_elem_input = {
  log?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type script_log_delete_key_input = {
  log?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "script_log" */
export type script_log_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "script_log" */
export type script_log_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type script_log_max_fields = {
  __typename: 'script_log_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type script_log_min_fields = {
  __typename: 'script_log_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "script_log" */
export type script_log_mutation_response = {
  __typename: 'script_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<script_log>;
};

/** on_conflict condition type for table "script_log" */
export type script_log_on_conflict = {
  constraint: script_log_constraint;
  update_columns?: Array<script_log_update_column>;
  where?: InputMaybe<script_log_bool_exp>;
};

/** Ordering options when selecting data from "script_log". */
export type script_log_order_by = {
  id?: InputMaybe<order_by>;
  log?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** primary key columns input for table: script_log */
export type script_log_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type script_log_prepend_input = {
  log?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "script_log" */
export enum script_log_select_column {
  /** column name */
  id = 'id',
  /** column name */
  log = 'log',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  token = 'token'
}

/** input type for updating data in table "script_log" */
export type script_log_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type script_log_stddev_fields = {
  __typename: 'script_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type script_log_stddev_pop_fields = {
  __typename: 'script_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type script_log_stddev_samp_fields = {
  __typename: 'script_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "script_log" */
export type script_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: script_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type script_log_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  log?: InputMaybe<Scalars['jsonb']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type script_log_sum_fields = {
  __typename: 'script_log_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "script_log" */
export enum script_log_update_column {
  /** column name */
  id = 'id',
  /** column name */
  log = 'log',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  token = 'token'
}

export type script_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<script_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<script_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<script_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<script_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<script_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<script_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<script_log_set_input>;
  /** filter the rows which have to be updated */
  where: script_log_bool_exp;
};

/** aggregate var_pop on columns */
export type script_log_var_pop_fields = {
  __typename: 'script_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type script_log_var_samp_fields = {
  __typename: 'script_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type script_log_variance_fields = {
  __typename: 'script_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type search_accounts_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_data_connector_request_logs_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_deflections_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_offers_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_platform_api_logs_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_platform_coupons_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_properties_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_segment_groups_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_segments_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_subscriber_flows_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_subscriber_grid_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_subscribers_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type search_webhook_request_logs_args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "segment" */
export type segment = {
  __typename: 'segment';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  flow_step_version_condition_segments: Array<flow_step_version_condition_segment>;
  /** An aggregate relationship */
  flow_step_version_condition_segments_aggregate: flow_step_version_condition_segment_aggregate;
  id: Scalars['Int']['output'];
  inline: Scalars['Boolean']['output'];
  /** An object relationship */
  integration?: Maybe<integration>;
  integration_id?: Maybe<Scalars['Int']['output']>;
  integration_segment_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  primary_segment_condition_group?: Maybe<segment_condition_group>;
  primary_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  segment_tags: Array<segment_tag>;
  /** An aggregate relationship */
  segment_tags_aggregate: segment_tag_aggregate;
  /** An array relationship */
  subscriber_flow_segments: Array<subscriber_flow_segment>;
  /** An aggregate relationship */
  subscriber_flow_segments_aggregate: subscriber_flow_segment_aggregate;
  tag_ids: Scalars['jsonb']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "segment" */
export type segmentflow_step_version_condition_segmentsArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmentflow_step_version_condition_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmentsegment_tagsArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmentsegment_tags_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmentsubscriber_flow_segmentsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmentsubscriber_flow_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


/** columns and relationships of "segment" */
export type segmenttag_idsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "segment" */
export type segment_aggregate = {
  __typename: 'segment_aggregate';
  aggregate?: Maybe<segment_aggregate_fields>;
  nodes: Array<segment>;
};

export type segment_aggregate_bool_exp = {
  bool_and?: InputMaybe<segment_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<segment_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<segment_aggregate_bool_exp_count>;
};

export type segment_aggregate_bool_exp_bool_and = {
  arguments: segment_select_column_segment_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type segment_aggregate_bool_exp_bool_or = {
  arguments: segment_select_column_segment_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "segment" */
export type segment_aggregate_fields = {
  __typename: 'segment_aggregate_fields';
  avg?: Maybe<segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_max_fields>;
  min?: Maybe<segment_min_fields>;
  stddev?: Maybe<segment_stddev_fields>;
  stddev_pop?: Maybe<segment_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_stddev_samp_fields>;
  sum?: Maybe<segment_sum_fields>;
  var_pop?: Maybe<segment_var_pop_fields>;
  var_samp?: Maybe<segment_var_samp_fields>;
  variance?: Maybe<segment_variance_fields>;
};


/** aggregate fields of "segment" */
export type segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment" */
export type segment_aggregate_order_by = {
  avg?: InputMaybe<segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<segment_max_order_by>;
  min?: InputMaybe<segment_min_order_by>;
  stddev?: InputMaybe<segment_stddev_order_by>;
  stddev_pop?: InputMaybe<segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<segment_stddev_samp_order_by>;
  sum?: InputMaybe<segment_sum_order_by>;
  var_pop?: InputMaybe<segment_var_pop_order_by>;
  var_samp?: InputMaybe<segment_var_samp_order_by>;
  variance?: InputMaybe<segment_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type segment_append_input = {
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "segment" */
export type segment_arr_rel_insert_input = {
  data: Array<segment_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_on_conflict>;
};

/** aggregate avg on columns */
export type segment_avg_fields = {
  __typename: 'segment_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment" */
export type segment_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "segment". All fields are combined with a logical 'AND'. */
export type segment_bool_exp = {
  _and?: InputMaybe<Array<segment_bool_exp>>;
  _not?: InputMaybe<segment_bool_exp>;
  _or?: InputMaybe<Array<segment_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_version_condition_segments?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
  flow_step_version_condition_segments_aggregate?: InputMaybe<flow_step_version_condition_segment_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  inline?: InputMaybe<Boolean_comparison_exp>;
  integration?: InputMaybe<integration_bool_exp>;
  integration_id?: InputMaybe<Int_comparison_exp>;
  integration_segment_id?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  primary_segment_condition_group?: InputMaybe<segment_condition_group_bool_exp>;
  primary_segment_condition_group_id?: InputMaybe<Int_comparison_exp>;
  segment_tags?: InputMaybe<segment_tag_bool_exp>;
  segment_tags_aggregate?: InputMaybe<segment_tag_aggregate_bool_exp>;
  subscriber_flow_segments?: InputMaybe<subscriber_flow_segment_bool_exp>;
  subscriber_flow_segments_aggregate?: InputMaybe<subscriber_flow_segment_aggregate_bool_exp>;
  tag_ids?: InputMaybe<jsonb_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "segment_condition" */
export type segment_condition = {
  __typename: 'segment_condition';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  custom_property?: Maybe<property>;
  id: Scalars['Int']['output'];
  operator: segment_condition_operator_enum;
  property: segment_condition_property_enum;
  property_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  segment_condition_group_entries: Array<segment_condition_group_entry>;
  /** An aggregate relationship */
  segment_condition_group_entries_aggregate: segment_condition_group_entry_aggregate;
  /** An object relationship */
  segment_condition_operator: segment_condition_operator;
  /** An object relationship */
  segment_condition_property: segment_condition_property;
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "segment_condition" */
export type segment_conditionsegment_condition_group_entriesArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


/** columns and relationships of "segment_condition" */
export type segment_conditionsegment_condition_group_entries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


/** columns and relationships of "segment_condition" */
export type segment_conditionvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "segment_condition" */
export type segment_condition_aggregate = {
  __typename: 'segment_condition_aggregate';
  aggregate?: Maybe<segment_condition_aggregate_fields>;
  nodes: Array<segment_condition>;
};

export type segment_condition_aggregate_bool_exp = {
  count?: InputMaybe<segment_condition_aggregate_bool_exp_count>;
};

export type segment_condition_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<segment_condition_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_condition_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "segment_condition" */
export type segment_condition_aggregate_fields = {
  __typename: 'segment_condition_aggregate_fields';
  avg?: Maybe<segment_condition_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_max_fields>;
  min?: Maybe<segment_condition_min_fields>;
  stddev?: Maybe<segment_condition_stddev_fields>;
  stddev_pop?: Maybe<segment_condition_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_condition_stddev_samp_fields>;
  sum?: Maybe<segment_condition_sum_fields>;
  var_pop?: Maybe<segment_condition_var_pop_fields>;
  var_samp?: Maybe<segment_condition_var_samp_fields>;
  variance?: Maybe<segment_condition_variance_fields>;
};


/** aggregate fields of "segment_condition" */
export type segment_condition_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment_condition" */
export type segment_condition_aggregate_order_by = {
  avg?: InputMaybe<segment_condition_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<segment_condition_max_order_by>;
  min?: InputMaybe<segment_condition_min_order_by>;
  stddev?: InputMaybe<segment_condition_stddev_order_by>;
  stddev_pop?: InputMaybe<segment_condition_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<segment_condition_stddev_samp_order_by>;
  sum?: InputMaybe<segment_condition_sum_order_by>;
  var_pop?: InputMaybe<segment_condition_var_pop_order_by>;
  var_samp?: InputMaybe<segment_condition_var_samp_order_by>;
  variance?: InputMaybe<segment_condition_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type segment_condition_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "segment_condition" */
export type segment_condition_arr_rel_insert_input = {
  data: Array<segment_condition_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_on_conflict>;
};

/** aggregate avg on columns */
export type segment_condition_avg_fields = {
  __typename: 'segment_condition_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment_condition" */
export type segment_condition_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "segment_condition". All fields are combined with a logical 'AND'. */
export type segment_condition_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_bool_exp>>;
  _not?: InputMaybe<segment_condition_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  custom_property?: InputMaybe<property_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  operator?: InputMaybe<segment_condition_operator_enum_comparison_exp>;
  property?: InputMaybe<segment_condition_property_enum_comparison_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  segment_condition_group_entries?: InputMaybe<segment_condition_group_entry_bool_exp>;
  segment_condition_group_entries_aggregate?: InputMaybe<segment_condition_group_entry_aggregate_bool_exp>;
  segment_condition_operator?: InputMaybe<segment_condition_operator_bool_exp>;
  segment_condition_property?: InputMaybe<segment_condition_property_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** columns and relationships of "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator = {
  __typename: 'segment_condition_boolean_operator';
  value: Scalars['String']['output'];
};

/** aggregated selection of "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_aggregate = {
  __typename: 'segment_condition_boolean_operator_aggregate';
  aggregate?: Maybe<segment_condition_boolean_operator_aggregate_fields>;
  nodes: Array<segment_condition_boolean_operator>;
};

/** aggregate fields of "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_aggregate_fields = {
  __typename: 'segment_condition_boolean_operator_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_boolean_operator_max_fields>;
  min?: Maybe<segment_condition_boolean_operator_min_fields>;
};


/** aggregate fields of "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_boolean_operator_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "segment_condition_boolean_operator". All fields are combined with a logical 'AND'. */
export type segment_condition_boolean_operator_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_boolean_operator_bool_exp>>;
  _not?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_boolean_operator_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "segment_condition_boolean_operator" */
export enum segment_condition_boolean_operator_constraint {
  /** unique or primary key constraint on columns "value" */
  segment_condition_boolean_operator_pkey = 'segment_condition_boolean_operator_pkey'
}

export enum segment_condition_boolean_operator_enum {
  and = 'and',
  or = 'or'
}

/** Boolean expression to compare columns of type "segment_condition_boolean_operator_enum". All fields are combined with logical 'AND'. */
export type segment_condition_boolean_operator_enum_comparison_exp = {
  _eq?: InputMaybe<segment_condition_boolean_operator_enum>;
  _in?: InputMaybe<Array<segment_condition_boolean_operator_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<segment_condition_boolean_operator_enum>;
  _nin?: InputMaybe<Array<segment_condition_boolean_operator_enum>>;
};

/** input type for inserting data into table "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type segment_condition_boolean_operator_max_fields = {
  __typename: 'segment_condition_boolean_operator_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type segment_condition_boolean_operator_min_fields = {
  __typename: 'segment_condition_boolean_operator_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_mutation_response = {
  __typename: 'segment_condition_boolean_operator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition_boolean_operator>;
};

/** on_conflict condition type for table "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_on_conflict = {
  constraint: segment_condition_boolean_operator_constraint;
  update_columns?: Array<segment_condition_boolean_operator_update_column>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};

/** Ordering options when selecting data from "segment_condition_boolean_operator". */
export type segment_condition_boolean_operator_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_condition_boolean_operator */
export type segment_condition_boolean_operator_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "segment_condition_boolean_operator" */
export enum segment_condition_boolean_operator_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "segment_condition_boolean_operator" */
export type segment_condition_boolean_operator_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_boolean_operator_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_boolean_operator_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "segment_condition_boolean_operator" */
export enum segment_condition_boolean_operator_update_column {
  /** column name */
  value = 'value'
}

export type segment_condition_boolean_operator_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_boolean_operator_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_boolean_operator_bool_exp;
};

/** unique or primary key constraints on table "segment_condition" */
export enum segment_condition_constraint {
  /** unique or primary key constraint on columns "id" */
  segment_condition_pkey = 'segment_condition_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type segment_condition_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type segment_condition_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type segment_condition_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "segment_condition_group" */
export type segment_condition_group = {
  __typename: 'segment_condition_group';
  account_id: Scalars['Int']['output'];
  boolean_operator: segment_condition_boolean_operator_enum;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  segment_condition_group_entries: Array<segment_condition_group_entry>;
  /** An aggregate relationship */
  segment_condition_group_entries_aggregate: segment_condition_group_entry_aggregate;
};


/** columns and relationships of "segment_condition_group" */
export type segment_condition_groupsegment_condition_group_entriesArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


/** columns and relationships of "segment_condition_group" */
export type segment_condition_groupsegment_condition_group_entries_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};

/** aggregated selection of "segment_condition_group" */
export type segment_condition_group_aggregate = {
  __typename: 'segment_condition_group_aggregate';
  aggregate?: Maybe<segment_condition_group_aggregate_fields>;
  nodes: Array<segment_condition_group>;
};

/** aggregate fields of "segment_condition_group" */
export type segment_condition_group_aggregate_fields = {
  __typename: 'segment_condition_group_aggregate_fields';
  avg?: Maybe<segment_condition_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_group_max_fields>;
  min?: Maybe<segment_condition_group_min_fields>;
  stddev?: Maybe<segment_condition_group_stddev_fields>;
  stddev_pop?: Maybe<segment_condition_group_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_condition_group_stddev_samp_fields>;
  sum?: Maybe<segment_condition_group_sum_fields>;
  var_pop?: Maybe<segment_condition_group_var_pop_fields>;
  var_samp?: Maybe<segment_condition_group_var_samp_fields>;
  variance?: Maybe<segment_condition_group_variance_fields>;
};


/** aggregate fields of "segment_condition_group" */
export type segment_condition_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type segment_condition_group_avg_fields = {
  __typename: 'segment_condition_group_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "segment_condition_group". All fields are combined with a logical 'AND'. */
export type segment_condition_group_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_group_bool_exp>>;
  _not?: InputMaybe<segment_condition_group_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_group_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  boolean_operator?: InputMaybe<segment_condition_boolean_operator_enum_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  segment_condition_group_entries?: InputMaybe<segment_condition_group_entry_bool_exp>;
  segment_condition_group_entries_aggregate?: InputMaybe<segment_condition_group_entry_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "segment_condition_group" */
export enum segment_condition_group_constraint {
  /** unique or primary key constraint on columns "id" */
  segment_condition_group_pkey = 'segment_condition_group_pkey'
}

/** columns and relationships of "segment_condition_group_entry" */
export type segment_condition_group_entry = {
  __typename: 'segment_condition_group_entry';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  entry_segment_condition?: Maybe<segment_condition>;
  /** An object relationship */
  entry_segment_condition_group?: Maybe<segment_condition_group>;
  entry_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  /** An object relationship */
  segment_condition_group: segment_condition_group;
  segment_condition_group_id: Scalars['Int']['output'];
};

/** aggregated selection of "segment_condition_group_entry" */
export type segment_condition_group_entry_aggregate = {
  __typename: 'segment_condition_group_entry_aggregate';
  aggregate?: Maybe<segment_condition_group_entry_aggregate_fields>;
  nodes: Array<segment_condition_group_entry>;
};

export type segment_condition_group_entry_aggregate_bool_exp = {
  count?: InputMaybe<segment_condition_group_entry_aggregate_bool_exp_count>;
};

export type segment_condition_group_entry_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_condition_group_entry_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "segment_condition_group_entry" */
export type segment_condition_group_entry_aggregate_fields = {
  __typename: 'segment_condition_group_entry_aggregate_fields';
  avg?: Maybe<segment_condition_group_entry_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_group_entry_max_fields>;
  min?: Maybe<segment_condition_group_entry_min_fields>;
  stddev?: Maybe<segment_condition_group_entry_stddev_fields>;
  stddev_pop?: Maybe<segment_condition_group_entry_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_condition_group_entry_stddev_samp_fields>;
  sum?: Maybe<segment_condition_group_entry_sum_fields>;
  var_pop?: Maybe<segment_condition_group_entry_var_pop_fields>;
  var_samp?: Maybe<segment_condition_group_entry_var_samp_fields>;
  variance?: Maybe<segment_condition_group_entry_variance_fields>;
};


/** aggregate fields of "segment_condition_group_entry" */
export type segment_condition_group_entry_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment_condition_group_entry" */
export type segment_condition_group_entry_aggregate_order_by = {
  avg?: InputMaybe<segment_condition_group_entry_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<segment_condition_group_entry_max_order_by>;
  min?: InputMaybe<segment_condition_group_entry_min_order_by>;
  stddev?: InputMaybe<segment_condition_group_entry_stddev_order_by>;
  stddev_pop?: InputMaybe<segment_condition_group_entry_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<segment_condition_group_entry_stddev_samp_order_by>;
  sum?: InputMaybe<segment_condition_group_entry_sum_order_by>;
  var_pop?: InputMaybe<segment_condition_group_entry_var_pop_order_by>;
  var_samp?: InputMaybe<segment_condition_group_entry_var_samp_order_by>;
  variance?: InputMaybe<segment_condition_group_entry_variance_order_by>;
};

/** input type for inserting array relation for remote table "segment_condition_group_entry" */
export type segment_condition_group_entry_arr_rel_insert_input = {
  data: Array<segment_condition_group_entry_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_group_entry_on_conflict>;
};

/** aggregate avg on columns */
export type segment_condition_group_entry_avg_fields = {
  __typename: 'segment_condition_group_entry_avg_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_avg_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "segment_condition_group_entry". All fields are combined with a logical 'AND'. */
export type segment_condition_group_entry_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_group_entry_bool_exp>>;
  _not?: InputMaybe<segment_condition_group_entry_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_group_entry_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  entry_segment_condition?: InputMaybe<segment_condition_bool_exp>;
  entry_segment_condition_group?: InputMaybe<segment_condition_group_bool_exp>;
  entry_segment_condition_group_id?: InputMaybe<Int_comparison_exp>;
  entry_segment_condition_id?: InputMaybe<Int_comparison_exp>;
  position?: InputMaybe<Int_comparison_exp>;
  segment_condition_group?: InputMaybe<segment_condition_group_bool_exp>;
  segment_condition_group_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "segment_condition_group_entry" */
export enum segment_condition_group_entry_constraint {
  /** unique or primary key constraint on columns "segment_condition_group_id", "position" */
  segment_condition_group_entry_pkey = 'segment_condition_group_entry_pkey'
}

/** input type for incrementing numeric columns in table "segment_condition_group_entry" */
export type segment_condition_group_entry_inc_input = {
  entry_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  entry_segment_condition_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_condition_group_entry" */
export type segment_condition_group_entry_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entry_segment_condition?: InputMaybe<segment_condition_obj_rel_insert_input>;
  entry_segment_condition_group?: InputMaybe<segment_condition_group_obj_rel_insert_input>;
  entry_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  entry_segment_condition_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group?: InputMaybe<segment_condition_group_obj_rel_insert_input>;
  segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type segment_condition_group_entry_max_fields = {
  __typename: 'segment_condition_group_entry_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  entry_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_max_order_by = {
  created_at?: InputMaybe<order_by>;
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type segment_condition_group_entry_min_fields = {
  __typename: 'segment_condition_group_entry_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  entry_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_min_order_by = {
  created_at?: InputMaybe<order_by>;
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "segment_condition_group_entry" */
export type segment_condition_group_entry_mutation_response = {
  __typename: 'segment_condition_group_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition_group_entry>;
};

/** on_conflict condition type for table "segment_condition_group_entry" */
export type segment_condition_group_entry_on_conflict = {
  constraint: segment_condition_group_entry_constraint;
  update_columns?: Array<segment_condition_group_entry_update_column>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};

/** Ordering options when selecting data from "segment_condition_group_entry". */
export type segment_condition_group_entry_order_by = {
  created_at?: InputMaybe<order_by>;
  entry_segment_condition?: InputMaybe<segment_condition_order_by>;
  entry_segment_condition_group?: InputMaybe<segment_condition_group_order_by>;
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group?: InputMaybe<segment_condition_group_order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_condition_group_entry */
export type segment_condition_group_entry_pk_columns_input = {
  position: Scalars['Int']['input'];
  segment_condition_group_id: Scalars['Int']['input'];
};

/** select columns of table "segment_condition_group_entry" */
export enum segment_condition_group_entry_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  entry_segment_condition_group_id = 'entry_segment_condition_group_id',
  /** column name */
  entry_segment_condition_id = 'entry_segment_condition_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_condition_group_id = 'segment_condition_group_id'
}

/** input type for updating data in table "segment_condition_group_entry" */
export type segment_condition_group_entry_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entry_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  entry_segment_condition_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type segment_condition_group_entry_stddev_fields = {
  __typename: 'segment_condition_group_entry_stddev_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_stddev_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type segment_condition_group_entry_stddev_pop_fields = {
  __typename: 'segment_condition_group_entry_stddev_pop_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_stddev_pop_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type segment_condition_group_entry_stddev_samp_fields = {
  __typename: 'segment_condition_group_entry_stddev_samp_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_stddev_samp_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "segment_condition_group_entry" */
export type segment_condition_group_entry_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_group_entry_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_group_entry_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entry_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  entry_segment_condition_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type segment_condition_group_entry_sum_fields = {
  __typename: 'segment_condition_group_entry_sum_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_sum_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** update columns of table "segment_condition_group_entry" */
export enum segment_condition_group_entry_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  entry_segment_condition_group_id = 'entry_segment_condition_group_id',
  /** column name */
  entry_segment_condition_id = 'entry_segment_condition_id',
  /** column name */
  position = 'position',
  /** column name */
  segment_condition_group_id = 'segment_condition_group_id'
}

export type segment_condition_group_entry_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_condition_group_entry_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_group_entry_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_group_entry_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_condition_group_entry_var_pop_fields = {
  __typename: 'segment_condition_group_entry_var_pop_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_var_pop_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type segment_condition_group_entry_var_samp_fields = {
  __typename: 'segment_condition_group_entry_var_samp_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_var_samp_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type segment_condition_group_entry_variance_fields = {
  __typename: 'segment_condition_group_entry_variance_fields';
  entry_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
  entry_segment_condition_id?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment_condition_group_entry" */
export type segment_condition_group_entry_variance_order_by = {
  entry_segment_condition_group_id?: InputMaybe<order_by>;
  entry_segment_condition_id?: InputMaybe<order_by>;
  position?: InputMaybe<order_by>;
  segment_condition_group_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "segment_condition_group" */
export type segment_condition_group_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_condition_group" */
export type segment_condition_group_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  boolean_operator?: InputMaybe<segment_condition_boolean_operator_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group_entries?: InputMaybe<segment_condition_group_entry_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type segment_condition_group_max_fields = {
  __typename: 'segment_condition_group_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type segment_condition_group_min_fields = {
  __typename: 'segment_condition_group_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "segment_condition_group" */
export type segment_condition_group_mutation_response = {
  __typename: 'segment_condition_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition_group>;
};

/** input type for inserting object relation for remote table "segment_condition_group" */
export type segment_condition_group_obj_rel_insert_input = {
  data: segment_condition_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_group_on_conflict>;
};

/** on_conflict condition type for table "segment_condition_group" */
export type segment_condition_group_on_conflict = {
  constraint: segment_condition_group_constraint;
  update_columns?: Array<segment_condition_group_update_column>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};

/** Ordering options when selecting data from "segment_condition_group". */
export type segment_condition_group_order_by = {
  account_id?: InputMaybe<order_by>;
  boolean_operator?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  segment_condition_group_entries_aggregate?: InputMaybe<segment_condition_group_entry_aggregate_order_by>;
};

/** primary key columns input for table: segment_condition_group */
export type segment_condition_group_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "segment_condition_group" */
export enum segment_condition_group_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  boolean_operator = 'boolean_operator',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "segment_condition_group" */
export type segment_condition_group_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  boolean_operator?: InputMaybe<segment_condition_boolean_operator_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type segment_condition_group_stddev_fields = {
  __typename: 'segment_condition_group_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type segment_condition_group_stddev_pop_fields = {
  __typename: 'segment_condition_group_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type segment_condition_group_stddev_samp_fields = {
  __typename: 'segment_condition_group_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "segment_condition_group" */
export type segment_condition_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_group_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  boolean_operator?: InputMaybe<segment_condition_boolean_operator_enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type segment_condition_group_sum_fields = {
  __typename: 'segment_condition_group_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "segment_condition_group" */
export enum segment_condition_group_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  boolean_operator = 'boolean_operator',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id'
}

export type segment_condition_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_condition_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_group_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_group_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_condition_group_var_pop_fields = {
  __typename: 'segment_condition_group_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type segment_condition_group_var_samp_fields = {
  __typename: 'segment_condition_group_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type segment_condition_group_variance_fields = {
  __typename: 'segment_condition_group_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "segment_condition" */
export type segment_condition_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_condition" */
export type segment_condition_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_property?: InputMaybe<property_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  operator?: InputMaybe<segment_condition_operator_enum>;
  property?: InputMaybe<segment_condition_property_enum>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  segment_condition_group_entries?: InputMaybe<segment_condition_group_entry_arr_rel_insert_input>;
  segment_condition_operator?: InputMaybe<segment_condition_operator_obj_rel_insert_input>;
  segment_condition_property?: InputMaybe<segment_condition_property_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type segment_condition_max_fields = {
  __typename: 'segment_condition_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "segment_condition" */
export type segment_condition_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type segment_condition_min_fields = {
  __typename: 'segment_condition_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "segment_condition" */
export type segment_condition_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "segment_condition" */
export type segment_condition_mutation_response = {
  __typename: 'segment_condition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition>;
};

/** input type for inserting object relation for remote table "segment_condition" */
export type segment_condition_obj_rel_insert_input = {
  data: segment_condition_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_on_conflict>;
};

/** on_conflict condition type for table "segment_condition" */
export type segment_condition_on_conflict = {
  constraint: segment_condition_constraint;
  update_columns?: Array<segment_condition_update_column>;
  where?: InputMaybe<segment_condition_bool_exp>;
};

/** columns and relationships of "segment_condition_operator" */
export type segment_condition_operator = {
  __typename: 'segment_condition_operator';
  /** An array relationship */
  segment_conditions: Array<segment_condition>;
  /** An aggregate relationship */
  segment_conditions_aggregate: segment_condition_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "segment_condition_operator" */
export type segment_condition_operatorsegment_conditionsArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


/** columns and relationships of "segment_condition_operator" */
export type segment_condition_operatorsegment_conditions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};

/** aggregated selection of "segment_condition_operator" */
export type segment_condition_operator_aggregate = {
  __typename: 'segment_condition_operator_aggregate';
  aggregate?: Maybe<segment_condition_operator_aggregate_fields>;
  nodes: Array<segment_condition_operator>;
};

/** aggregate fields of "segment_condition_operator" */
export type segment_condition_operator_aggregate_fields = {
  __typename: 'segment_condition_operator_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_operator_max_fields>;
  min?: Maybe<segment_condition_operator_min_fields>;
};


/** aggregate fields of "segment_condition_operator" */
export type segment_condition_operator_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_operator_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "segment_condition_operator". All fields are combined with a logical 'AND'. */
export type segment_condition_operator_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_operator_bool_exp>>;
  _not?: InputMaybe<segment_condition_operator_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_operator_bool_exp>>;
  segment_conditions?: InputMaybe<segment_condition_bool_exp>;
  segment_conditions_aggregate?: InputMaybe<segment_condition_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "segment_condition_operator" */
export enum segment_condition_operator_constraint {
  /** unique or primary key constraint on columns "value" */
  segment_condition_operator_pkey = 'segment_condition_operator_pkey'
}

export enum segment_condition_operator_enum {
  contains = 'contains',
  ends_with = 'ends_with',
  eq = 'eq',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  is_not_set = 'is_not_set',
  is_set = 'is_set',
  lt = 'lt',
  lte = 'lte',
  more_than_days_ago = 'more_than_days_ago',
  more_than_days_from_now = 'more_than_days_from_now',
  neq = 'neq',
  nin = 'nin',
  starts_with = 'starts_with',
  within_last_days = 'within_last_days',
  within_next_days = 'within_next_days'
}

/** Boolean expression to compare columns of type "segment_condition_operator_enum". All fields are combined with logical 'AND'. */
export type segment_condition_operator_enum_comparison_exp = {
  _eq?: InputMaybe<segment_condition_operator_enum>;
  _in?: InputMaybe<Array<segment_condition_operator_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<segment_condition_operator_enum>;
  _nin?: InputMaybe<Array<segment_condition_operator_enum>>;
};

/** input type for inserting data into table "segment_condition_operator" */
export type segment_condition_operator_insert_input = {
  segment_conditions?: InputMaybe<segment_condition_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type segment_condition_operator_max_fields = {
  __typename: 'segment_condition_operator_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type segment_condition_operator_min_fields = {
  __typename: 'segment_condition_operator_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "segment_condition_operator" */
export type segment_condition_operator_mutation_response = {
  __typename: 'segment_condition_operator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition_operator>;
};

/** input type for inserting object relation for remote table "segment_condition_operator" */
export type segment_condition_operator_obj_rel_insert_input = {
  data: segment_condition_operator_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_operator_on_conflict>;
};

/** on_conflict condition type for table "segment_condition_operator" */
export type segment_condition_operator_on_conflict = {
  constraint: segment_condition_operator_constraint;
  update_columns?: Array<segment_condition_operator_update_column>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};

/** Ordering options when selecting data from "segment_condition_operator". */
export type segment_condition_operator_order_by = {
  segment_conditions_aggregate?: InputMaybe<segment_condition_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_condition_operator */
export type segment_condition_operator_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "segment_condition_operator" */
export enum segment_condition_operator_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "segment_condition_operator" */
export type segment_condition_operator_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "segment_condition_operator" */
export type segment_condition_operator_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_operator_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_operator_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "segment_condition_operator" */
export enum segment_condition_operator_update_column {
  /** column name */
  value = 'value'
}

export type segment_condition_operator_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_operator_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_operator_bool_exp;
};

/** Ordering options when selecting data from "segment_condition". */
export type segment_condition_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  custom_property?: InputMaybe<property_order_by>;
  id?: InputMaybe<order_by>;
  operator?: InputMaybe<order_by>;
  property?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  segment_condition_group_entries_aggregate?: InputMaybe<segment_condition_group_entry_aggregate_order_by>;
  segment_condition_operator?: InputMaybe<segment_condition_operator_order_by>;
  segment_condition_property?: InputMaybe<segment_condition_property_order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_condition */
export type segment_condition_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type segment_condition_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "segment_condition_property" */
export type segment_condition_property = {
  __typename: 'segment_condition_property';
  /** An array relationship */
  segment_conditions: Array<segment_condition>;
  /** An aggregate relationship */
  segment_conditions_aggregate: segment_condition_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "segment_condition_property" */
export type segment_condition_propertysegment_conditionsArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


/** columns and relationships of "segment_condition_property" */
export type segment_condition_propertysegment_conditions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};

/** aggregated selection of "segment_condition_property" */
export type segment_condition_property_aggregate = {
  __typename: 'segment_condition_property_aggregate';
  aggregate?: Maybe<segment_condition_property_aggregate_fields>;
  nodes: Array<segment_condition_property>;
};

/** aggregate fields of "segment_condition_property" */
export type segment_condition_property_aggregate_fields = {
  __typename: 'segment_condition_property_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<segment_condition_property_max_fields>;
  min?: Maybe<segment_condition_property_min_fields>;
};


/** aggregate fields of "segment_condition_property" */
export type segment_condition_property_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_condition_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "segment_condition_property". All fields are combined with a logical 'AND'. */
export type segment_condition_property_bool_exp = {
  _and?: InputMaybe<Array<segment_condition_property_bool_exp>>;
  _not?: InputMaybe<segment_condition_property_bool_exp>;
  _or?: InputMaybe<Array<segment_condition_property_bool_exp>>;
  segment_conditions?: InputMaybe<segment_condition_bool_exp>;
  segment_conditions_aggregate?: InputMaybe<segment_condition_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "segment_condition_property" */
export enum segment_condition_property_constraint {
  /** unique or primary key constraint on columns "value" */
  segment_condition_property_pkey = 'segment_condition_property_pkey'
}

export enum segment_condition_property_enum {
  addon_id = 'addon_id',
  addon_name = 'addon_name',
  custom_property = 'custom_property',
  newspaper_subscription_current_balance = 'newspaper_subscription_current_balance',
  newspaper_subscription_digital_frequency = 'newspaper_subscription_digital_frequency',
  newspaper_subscription_is_digital = 'newspaper_subscription_is_digital',
  newspaper_subscription_is_ez_pay = 'newspaper_subscription_is_ez_pay',
  newspaper_subscription_is_print = 'newspaper_subscription_is_print',
  newspaper_subscription_last_payment_amount = 'newspaper_subscription_last_payment_amount',
  newspaper_subscription_newspaper_name = 'newspaper_subscription_newspaper_name',
  newspaper_subscription_paper_code = 'newspaper_subscription_paper_code',
  newspaper_subscription_print_frequency = 'newspaper_subscription_print_frequency',
  newspaper_subscription_rate_code = 'newspaper_subscription_rate_code',
  newspaper_subscription_start_source = 'newspaper_subscription_start_source',
  newspaper_subscription_stop_date = 'newspaper_subscription_stop_date',
  plan_code = 'plan_code',
  plan_id = 'plan_id',
  plan_interval = 'plan_interval',
  plan_interval_count = 'plan_interval_count',
  plan_name = 'plan_name',
  plan_quantity = 'plan_quantity',
  product_id = 'product_id',
  product_name = 'product_name',
  subscriber_arr = 'subscriber_arr',
  subscriber_billing_location = 'subscriber_billing_location',
  subscriber_coupon_id = 'subscriber_coupon_id',
  subscriber_days_since_saved = 'subscriber_days_since_saved',
  subscriber_email = 'subscriber_email',
  subscriber_first_name = 'subscriber_first_name',
  subscriber_last_name = 'subscriber_last_name',
  subscriber_location = 'subscriber_location',
  subscriber_ltv = 'subscriber_ltv',
  subscriber_mrr = 'subscriber_mrr',
  subscriber_offer_id = 'subscriber_offer_id',
  subscriber_paid_invoice_count = 'subscriber_paid_invoice_count',
  subscriber_plan_id = 'subscriber_plan_id',
  subscriber_session_date = 'subscriber_session_date',
  subscriber_session_domain = 'subscriber_session_domain',
  subscriber_session_url = 'subscriber_session_url',
  subscriber_shipping_location = 'subscriber_shipping_location',
  subscriber_status = 'subscriber_status',
  subscriber_tags = 'subscriber_tags',
  subscriber_variant_sku = 'subscriber_variant_sku',
  subscription_arr = 'subscription_arr',
  subscription_billing_location = 'subscription_billing_location',
  subscription_coupon_discount_fixed_amount = 'subscription_coupon_discount_fixed_amount',
  subscription_coupon_discount_percentage = 'subscription_coupon_discount_percentage',
  subscription_coupon_id = 'subscription_coupon_id',
  subscription_duration = 'subscription_duration',
  subscription_free_trial = 'subscription_free_trial',
  subscription_ltv = 'subscription_ltv',
  subscription_mrr = 'subscription_mrr',
  subscription_next_billing_at = 'subscription_next_billing_at',
  subscription_paid_invoice_count = 'subscription_paid_invoice_count',
  subscription_past_due = 'subscription_past_due',
  subscription_session_date = 'subscription_session_date',
  subscription_session_domain = 'subscription_session_domain',
  subscription_session_url = 'subscription_session_url',
  subscription_shipping_location = 'subscription_shipping_location',
  subscription_status = 'subscription_status',
  subscription_trial_days = 'subscription_trial_days',
  subscription_trial_end_date = 'subscription_trial_end_date',
  subscription_trial_start_date = 'subscription_trial_start_date',
  subscription_variant_sku = 'subscription_variant_sku',
  subscription_will_renew = 'subscription_will_renew'
}

/** Boolean expression to compare columns of type "segment_condition_property_enum". All fields are combined with logical 'AND'. */
export type segment_condition_property_enum_comparison_exp = {
  _eq?: InputMaybe<segment_condition_property_enum>;
  _in?: InputMaybe<Array<segment_condition_property_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<segment_condition_property_enum>;
  _nin?: InputMaybe<Array<segment_condition_property_enum>>;
};

/** input type for inserting data into table "segment_condition_property" */
export type segment_condition_property_insert_input = {
  segment_conditions?: InputMaybe<segment_condition_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type segment_condition_property_max_fields = {
  __typename: 'segment_condition_property_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type segment_condition_property_min_fields = {
  __typename: 'segment_condition_property_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "segment_condition_property" */
export type segment_condition_property_mutation_response = {
  __typename: 'segment_condition_property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_condition_property>;
};

/** input type for inserting object relation for remote table "segment_condition_property" */
export type segment_condition_property_obj_rel_insert_input = {
  data: segment_condition_property_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_condition_property_on_conflict>;
};

/** on_conflict condition type for table "segment_condition_property" */
export type segment_condition_property_on_conflict = {
  constraint: segment_condition_property_constraint;
  update_columns?: Array<segment_condition_property_update_column>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};

/** Ordering options when selecting data from "segment_condition_property". */
export type segment_condition_property_order_by = {
  segment_conditions_aggregate?: InputMaybe<segment_condition_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_condition_property */
export type segment_condition_property_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "segment_condition_property" */
export enum segment_condition_property_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "segment_condition_property" */
export type segment_condition_property_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "segment_condition_property" */
export type segment_condition_property_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_property_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_property_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "segment_condition_property" */
export enum segment_condition_property_update_column {
  /** column name */
  value = 'value'
}

export type segment_condition_property_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_property_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_property_bool_exp;
};

/** select columns of table "segment_condition" */
export enum segment_condition_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  operator = 'operator',
  /** column name */
  property = 'property',
  /** column name */
  property_id = 'property_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "segment_condition" */
export type segment_condition_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  operator?: InputMaybe<segment_condition_operator_enum>;
  property?: InputMaybe<segment_condition_property_enum>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type segment_condition_stddev_fields = {
  __typename: 'segment_condition_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment_condition" */
export type segment_condition_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type segment_condition_stddev_pop_fields = {
  __typename: 'segment_condition_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment_condition" */
export type segment_condition_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type segment_condition_stddev_samp_fields = {
  __typename: 'segment_condition_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment_condition" */
export type segment_condition_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "segment_condition" */
export type segment_condition_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_condition_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_condition_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  operator?: InputMaybe<segment_condition_operator_enum>;
  property?: InputMaybe<segment_condition_property_enum>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type segment_condition_sum_fields = {
  __typename: 'segment_condition_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "segment_condition" */
export type segment_condition_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** update columns of table "segment_condition" */
export enum segment_condition_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  operator = 'operator',
  /** column name */
  property = 'property',
  /** column name */
  property_id = 'property_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

export type segment_condition_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<segment_condition_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<segment_condition_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<segment_condition_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<segment_condition_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_condition_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<segment_condition_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_condition_set_input>;
  /** filter the rows which have to be updated */
  where: segment_condition_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_condition_var_pop_fields = {
  __typename: 'segment_condition_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment_condition" */
export type segment_condition_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type segment_condition_var_samp_fields = {
  __typename: 'segment_condition_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment_condition" */
export type segment_condition_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type segment_condition_variance_fields = {
  __typename: 'segment_condition_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  property_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment_condition" */
export type segment_condition_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "segment" */
export enum segment_constraint {
  /** unique or primary key constraint on columns "account_id", "name" */
  segment_account_id_name_key = 'segment_account_id_name_key',
  /** unique or primary key constraint on columns "integration_segment_id", "integration_id" */
  segment_integration_id_integration_segment_id_key = 'segment_integration_id_integration_segment_id_key',
  /** unique or primary key constraint on columns "id" */
  segment_pkey = 'segment_pkey',
  /** unique or primary key constraint on columns "token" */
  segment_token_key = 'segment_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type segment_delete_at_path_input = {
  tag_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type segment_delete_elem_input = {
  tag_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type segment_delete_key_input = {
  tag_ids?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "segment_group" */
export type segment_group = {
  __typename: 'segment_group';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  flow_step_version_condition_segment_groups?: Maybe<flow_step_version_condition_segment_group>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  segment_group_segments: Array<segment_group_segment>;
  /** An aggregate relationship */
  segment_group_segments_aggregate: segment_group_segment_aggregate;
  token: Scalars['String']['output'];
};


/** columns and relationships of "segment_group" */
export type segment_groupsegment_group_segmentsArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


/** columns and relationships of "segment_group" */
export type segment_groupsegment_group_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};

export type segment_group_aggregate = {
  __typename: 'segment_group_aggregate';
  aggregate?: Maybe<segment_group_aggregate_fields>;
  nodes: Array<segment_group>;
};

/** aggregate fields of "segment_group" */
export type segment_group_aggregate_fields = {
  __typename: 'segment_group_aggregate_fields';
  avg?: Maybe<segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_group_max_fields>;
  min?: Maybe<segment_group_min_fields>;
  stddev?: Maybe<segment_group_stddev_fields>;
  stddev_pop?: Maybe<segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_group_stddev_samp_fields>;
  sum?: Maybe<segment_group_sum_fields>;
  var_pop?: Maybe<segment_group_var_pop_fields>;
  var_samp?: Maybe<segment_group_var_samp_fields>;
  variance?: Maybe<segment_group_variance_fields>;
};


/** aggregate fields of "segment_group" */
export type segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type segment_group_avg_fields = {
  __typename: 'segment_group_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "segment_group". All fields are combined with a logical 'AND'. */
export type segment_group_bool_exp = {
  _and?: InputMaybe<Array<segment_group_bool_exp>>;
  _not?: InputMaybe<segment_group_bool_exp>;
  _or?: InputMaybe<Array<segment_group_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_version_condition_segment_groups?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  segment_group_segments?: InputMaybe<segment_group_segment_bool_exp>;
  segment_group_segments_aggregate?: InputMaybe<segment_group_segment_aggregate_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "segment_group" */
export enum segment_group_constraint {
  /** unique or primary key constraint on columns "id" */
  segment_group_pkey = 'segment_group_pkey',
  /** unique or primary key constraint on columns "token" */
  segment_group_token_key = 'segment_group_token_key'
}

/** input type for incrementing numeric columns in table "segment_group" */
export type segment_group_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_group" */
export type segment_group_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_version_condition_segment_groups?: InputMaybe<flow_step_version_condition_segment_group_obj_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segment_group_segments?: InputMaybe<segment_group_segment_arr_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type segment_group_max_fields = {
  __typename: 'segment_group_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type segment_group_min_fields = {
  __typename: 'segment_group_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "segment_group" */
export type segment_group_mutation_response = {
  __typename: 'segment_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_group>;
};

/** input type for inserting object relation for remote table "segment_group" */
export type segment_group_obj_rel_insert_input = {
  data: segment_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_group_on_conflict>;
};

/** on_conflict condition type for table "segment_group" */
export type segment_group_on_conflict = {
  constraint: segment_group_constraint;
  update_columns?: Array<segment_group_update_column>;
  where?: InputMaybe<segment_group_bool_exp>;
};

/** columns and relationships of "segment_group_operator" */
export type segment_group_operator = {
  __typename: 'segment_group_operator';
  value: Scalars['String']['output'];
};

/** aggregated selection of "segment_group_operator" */
export type segment_group_operator_aggregate = {
  __typename: 'segment_group_operator_aggregate';
  aggregate?: Maybe<segment_group_operator_aggregate_fields>;
  nodes: Array<segment_group_operator>;
};

/** aggregate fields of "segment_group_operator" */
export type segment_group_operator_aggregate_fields = {
  __typename: 'segment_group_operator_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<segment_group_operator_max_fields>;
  min?: Maybe<segment_group_operator_min_fields>;
};


/** aggregate fields of "segment_group_operator" */
export type segment_group_operator_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_group_operator_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "segment_group_operator". All fields are combined with a logical 'AND'. */
export type segment_group_operator_bool_exp = {
  _and?: InputMaybe<Array<segment_group_operator_bool_exp>>;
  _not?: InputMaybe<segment_group_operator_bool_exp>;
  _or?: InputMaybe<Array<segment_group_operator_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "segment_group_operator" */
export enum segment_group_operator_constraint {
  /** unique or primary key constraint on columns "value" */
  segment_group_operator_pkey = 'segment_group_operator_pkey'
}

export enum segment_group_operator_enum {
  and = 'and',
  not = 'not',
  or = 'or'
}

/** Boolean expression to compare columns of type "segment_group_operator_enum". All fields are combined with logical 'AND'. */
export type segment_group_operator_enum_comparison_exp = {
  _eq?: InputMaybe<segment_group_operator_enum>;
  _in?: InputMaybe<Array<segment_group_operator_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<segment_group_operator_enum>;
  _nin?: InputMaybe<Array<segment_group_operator_enum>>;
};

/** input type for inserting data into table "segment_group_operator" */
export type segment_group_operator_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type segment_group_operator_max_fields = {
  __typename: 'segment_group_operator_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type segment_group_operator_min_fields = {
  __typename: 'segment_group_operator_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "segment_group_operator" */
export type segment_group_operator_mutation_response = {
  __typename: 'segment_group_operator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_group_operator>;
};

/** on_conflict condition type for table "segment_group_operator" */
export type segment_group_operator_on_conflict = {
  constraint: segment_group_operator_constraint;
  update_columns?: Array<segment_group_operator_update_column>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};

/** Ordering options when selecting data from "segment_group_operator". */
export type segment_group_operator_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_group_operator */
export type segment_group_operator_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "segment_group_operator" */
export enum segment_group_operator_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "segment_group_operator" */
export type segment_group_operator_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "segment_group_operator" */
export type segment_group_operator_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_group_operator_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_group_operator_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "segment_group_operator" */
export enum segment_group_operator_update_column {
  /** column name */
  value = 'value'
}

export type segment_group_operator_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_group_operator_set_input>;
  /** filter the rows which have to be updated */
  where: segment_group_operator_bool_exp;
};

/** Ordering options when selecting data from "segment_group". */
export type segment_group_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_version_condition_segment_groups?: InputMaybe<flow_step_version_condition_segment_group_order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  segment_group_segments_aggregate?: InputMaybe<segment_group_segment_aggregate_order_by>;
  token?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_group */
export type segment_group_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "segment_group_segment" */
export type segment_group_segment = {
  __typename: 'segment_group_segment';
  created_at: Scalars['timestamptz']['output'];
  operator: segment_group_operator_enum;
  /** An object relationship */
  segment: segment;
  /** An object relationship */
  segment_group: segment_group;
  segment_group_id: Scalars['Int']['output'];
  segment_id: Scalars['Int']['output'];
};

/** aggregated selection of "segment_group_segment" */
export type segment_group_segment_aggregate = {
  __typename: 'segment_group_segment_aggregate';
  aggregate?: Maybe<segment_group_segment_aggregate_fields>;
  nodes: Array<segment_group_segment>;
};

export type segment_group_segment_aggregate_bool_exp = {
  count?: InputMaybe<segment_group_segment_aggregate_bool_exp_count>;
};

export type segment_group_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<segment_group_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_group_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "segment_group_segment" */
export type segment_group_segment_aggregate_fields = {
  __typename: 'segment_group_segment_aggregate_fields';
  avg?: Maybe<segment_group_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_group_segment_max_fields>;
  min?: Maybe<segment_group_segment_min_fields>;
  stddev?: Maybe<segment_group_segment_stddev_fields>;
  stddev_pop?: Maybe<segment_group_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_group_segment_stddev_samp_fields>;
  sum?: Maybe<segment_group_segment_sum_fields>;
  var_pop?: Maybe<segment_group_segment_var_pop_fields>;
  var_samp?: Maybe<segment_group_segment_var_samp_fields>;
  variance?: Maybe<segment_group_segment_variance_fields>;
};


/** aggregate fields of "segment_group_segment" */
export type segment_group_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_group_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment_group_segment" */
export type segment_group_segment_aggregate_order_by = {
  avg?: InputMaybe<segment_group_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<segment_group_segment_max_order_by>;
  min?: InputMaybe<segment_group_segment_min_order_by>;
  stddev?: InputMaybe<segment_group_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<segment_group_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<segment_group_segment_stddev_samp_order_by>;
  sum?: InputMaybe<segment_group_segment_sum_order_by>;
  var_pop?: InputMaybe<segment_group_segment_var_pop_order_by>;
  var_samp?: InputMaybe<segment_group_segment_var_samp_order_by>;
  variance?: InputMaybe<segment_group_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "segment_group_segment" */
export type segment_group_segment_arr_rel_insert_input = {
  data: Array<segment_group_segment_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_group_segment_on_conflict>;
};

/** aggregate avg on columns */
export type segment_group_segment_avg_fields = {
  __typename: 'segment_group_segment_avg_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment_group_segment" */
export type segment_group_segment_avg_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "segment_group_segment". All fields are combined with a logical 'AND'. */
export type segment_group_segment_bool_exp = {
  _and?: InputMaybe<Array<segment_group_segment_bool_exp>>;
  _not?: InputMaybe<segment_group_segment_bool_exp>;
  _or?: InputMaybe<Array<segment_group_segment_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  operator?: InputMaybe<segment_group_operator_enum_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "segment_group_segment" */
export enum segment_group_segment_constraint {
  /** unique or primary key constraint on columns "segment_group_id", "segment_id" */
  segment_group_segment_pkey = 'segment_group_segment_pkey'
}

/** input type for incrementing numeric columns in table "segment_group_segment" */
export type segment_group_segment_inc_input = {
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_group_segment" */
export type segment_group_segment_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  operator?: InputMaybe<segment_group_operator_enum>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type segment_group_segment_max_fields = {
  __typename: 'segment_group_segment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "segment_group_segment" */
export type segment_group_segment_max_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type segment_group_segment_min_fields = {
  __typename: 'segment_group_segment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "segment_group_segment" */
export type segment_group_segment_min_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "segment_group_segment" */
export type segment_group_segment_mutation_response = {
  __typename: 'segment_group_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_group_segment>;
};

/** on_conflict condition type for table "segment_group_segment" */
export type segment_group_segment_on_conflict = {
  constraint: segment_group_segment_constraint;
  update_columns?: Array<segment_group_segment_update_column>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};

/** Ordering options when selecting data from "segment_group_segment". */
export type segment_group_segment_order_by = {
  created_at?: InputMaybe<order_by>;
  operator?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_group_segment */
export type segment_group_segment_pk_columns_input = {
  segment_group_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
};

/** select columns of table "segment_group_segment" */
export enum segment_group_segment_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  operator = 'operator',
  /** column name */
  segment_group_id = 'segment_group_id',
  /** column name */
  segment_id = 'segment_id'
}

/** input type for updating data in table "segment_group_segment" */
export type segment_group_segment_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  operator?: InputMaybe<segment_group_operator_enum>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type segment_group_segment_stddev_fields = {
  __typename: 'segment_group_segment_stddev_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment_group_segment" */
export type segment_group_segment_stddev_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type segment_group_segment_stddev_pop_fields = {
  __typename: 'segment_group_segment_stddev_pop_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment_group_segment" */
export type segment_group_segment_stddev_pop_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type segment_group_segment_stddev_samp_fields = {
  __typename: 'segment_group_segment_stddev_samp_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment_group_segment" */
export type segment_group_segment_stddev_samp_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "segment_group_segment" */
export type segment_group_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_group_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_group_segment_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  operator?: InputMaybe<segment_group_operator_enum>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type segment_group_segment_sum_fields = {
  __typename: 'segment_group_segment_sum_fields';
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "segment_group_segment" */
export type segment_group_segment_sum_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** update columns of table "segment_group_segment" */
export enum segment_group_segment_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  operator = 'operator',
  /** column name */
  segment_group_id = 'segment_group_id',
  /** column name */
  segment_id = 'segment_id'
}

export type segment_group_segment_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_group_segment_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_group_segment_set_input>;
  /** filter the rows which have to be updated */
  where: segment_group_segment_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_group_segment_var_pop_fields = {
  __typename: 'segment_group_segment_var_pop_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment_group_segment" */
export type segment_group_segment_var_pop_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type segment_group_segment_var_samp_fields = {
  __typename: 'segment_group_segment_var_samp_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment_group_segment" */
export type segment_group_segment_var_samp_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type segment_group_segment_variance_fields = {
  __typename: 'segment_group_segment_variance_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment_group_segment" */
export type segment_group_segment_variance_order_by = {
  segment_group_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
};

/** select columns of table "segment_group" */
export enum segment_group_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token'
}

/** input type for updating data in table "segment_group" */
export type segment_group_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type segment_group_stddev_fields = {
  __typename: 'segment_group_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type segment_group_stddev_pop_fields = {
  __typename: 'segment_group_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type segment_group_stddev_samp_fields = {
  __typename: 'segment_group_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "segment_group" */
export type segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_group_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type segment_group_sum_fields = {
  __typename: 'segment_group_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "segment_group_tsvector" */
export type segment_group_tsvector = {
  __typename: 'segment_group_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "segment_group_tsvector" */
export type segment_group_tsvector_aggregate = {
  __typename: 'segment_group_tsvector_aggregate';
  aggregate?: Maybe<segment_group_tsvector_aggregate_fields>;
  nodes: Array<segment_group_tsvector>;
};

/** aggregate fields of "segment_group_tsvector" */
export type segment_group_tsvector_aggregate_fields = {
  __typename: 'segment_group_tsvector_aggregate_fields';
  avg?: Maybe<segment_group_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_group_tsvector_max_fields>;
  min?: Maybe<segment_group_tsvector_min_fields>;
  stddev?: Maybe<segment_group_tsvector_stddev_fields>;
  stddev_pop?: Maybe<segment_group_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_group_tsvector_stddev_samp_fields>;
  sum?: Maybe<segment_group_tsvector_sum_fields>;
  var_pop?: Maybe<segment_group_tsvector_var_pop_fields>;
  var_samp?: Maybe<segment_group_tsvector_var_samp_fields>;
  variance?: Maybe<segment_group_tsvector_variance_fields>;
};


/** aggregate fields of "segment_group_tsvector" */
export type segment_group_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_group_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type segment_group_tsvector_avg_fields = {
  __typename: 'segment_group_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "segment_group_tsvector". All fields are combined with a logical 'AND'. */
export type segment_group_tsvector_bool_exp = {
  _and?: InputMaybe<Array<segment_group_tsvector_bool_exp>>;
  _not?: InputMaybe<segment_group_tsvector_bool_exp>;
  _or?: InputMaybe<Array<segment_group_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type segment_group_tsvector_max_fields = {
  __typename: 'segment_group_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type segment_group_tsvector_min_fields = {
  __typename: 'segment_group_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "segment_group_tsvector". */
export type segment_group_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  segment_group_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "segment_group_tsvector" */
export enum segment_group_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  segment_group_id = 'segment_group_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type segment_group_tsvector_stddev_fields = {
  __typename: 'segment_group_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type segment_group_tsvector_stddev_pop_fields = {
  __typename: 'segment_group_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type segment_group_tsvector_stddev_samp_fields = {
  __typename: 'segment_group_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "segment_group_tsvector" */
export type segment_group_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_group_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_group_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type segment_group_tsvector_sum_fields = {
  __typename: 'segment_group_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type segment_group_tsvector_var_pop_fields = {
  __typename: 'segment_group_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type segment_group_tsvector_var_samp_fields = {
  __typename: 'segment_group_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type segment_group_tsvector_variance_fields = {
  __typename: 'segment_group_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_group_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "segment_group" */
export enum segment_group_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  token = 'token'
}

export type segment_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_group_set_input>;
  /** filter the rows which have to be updated */
  where: segment_group_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_group_var_pop_fields = {
  __typename: 'segment_group_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type segment_group_var_samp_fields = {
  __typename: 'segment_group_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type segment_group_variance_fields = {
  __typename: 'segment_group_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "segment" */
export type segment_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  integration_id?: InputMaybe<Scalars['Int']['input']>;
  primary_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment" */
export type segment_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_version_condition_segments?: InputMaybe<flow_step_version_condition_segment_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inline?: InputMaybe<Scalars['Boolean']['input']>;
  integration?: InputMaybe<integration_obj_rel_insert_input>;
  integration_id?: InputMaybe<Scalars['Int']['input']>;
  integration_segment_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_segment_condition_group?: InputMaybe<segment_condition_group_obj_rel_insert_input>;
  primary_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  segment_tags?: InputMaybe<segment_tag_arr_rel_insert_input>;
  subscriber_flow_segments?: InputMaybe<subscriber_flow_segment_arr_rel_insert_input>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type segment_max_fields = {
  __typename: 'segment_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  integration_id?: Maybe<Scalars['Int']['output']>;
  integration_segment_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "segment" */
export type segment_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  integration_segment_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type segment_min_fields = {
  __typename: 'segment_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  integration_id?: Maybe<Scalars['Int']['output']>;
  integration_segment_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "segment" */
export type segment_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  integration_segment_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "segment" */
export type segment_mutation_response = {
  __typename: 'segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment>;
};

/** input type for inserting object relation for remote table "segment" */
export type segment_obj_rel_insert_input = {
  data: segment_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<segment_on_conflict>;
};

/** on_conflict condition type for table "segment" */
export type segment_on_conflict = {
  constraint: segment_constraint;
  update_columns?: Array<segment_update_column>;
  where?: InputMaybe<segment_bool_exp>;
};

/** Ordering options when selecting data from "segment". */
export type segment_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_step_version_condition_segments_aggregate?: InputMaybe<flow_step_version_condition_segment_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  inline?: InputMaybe<order_by>;
  integration?: InputMaybe<integration_order_by>;
  integration_id?: InputMaybe<order_by>;
  integration_segment_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  primary_segment_condition_group?: InputMaybe<segment_condition_group_order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
  segment_tags_aggregate?: InputMaybe<segment_tag_aggregate_order_by>;
  subscriber_flow_segments_aggregate?: InputMaybe<subscriber_flow_segment_aggregate_order_by>;
  tag_ids?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment */
export type segment_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type segment_prepend_input = {
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "segment" */
export enum segment_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  inline = 'inline',
  /** column name */
  integration_id = 'integration_id',
  /** column name */
  integration_segment_id = 'integration_segment_id',
  /** column name */
  name = 'name',
  /** column name */
  primary_segment_condition_group_id = 'primary_segment_condition_group_id',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** select "segment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "segment" */
export enum segment_select_column_segment_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  inline = 'inline'
}

/** select "segment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "segment" */
export enum segment_select_column_segment_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  inline = 'inline'
}

/** input type for updating data in table "segment" */
export type segment_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inline?: InputMaybe<Scalars['Boolean']['input']>;
  integration_id?: InputMaybe<Scalars['Int']['input']>;
  integration_segment_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type segment_stddev_fields = {
  __typename: 'segment_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment" */
export type segment_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type segment_stddev_pop_fields = {
  __typename: 'segment_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment" */
export type segment_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type segment_stddev_samp_fields = {
  __typename: 'segment_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment" */
export type segment_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "segment" */
export type segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inline?: InputMaybe<Scalars['Boolean']['input']>;
  integration_id?: InputMaybe<Scalars['Int']['input']>;
  integration_segment_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_segment_condition_group_id?: InputMaybe<Scalars['Int']['input']>;
  tag_ids?: InputMaybe<Scalars['jsonb']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type segment_subscriber_args = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

export type segment_subscriber_flow_args = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

export type segment_subscriber_log_args = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

export type segment_subscription_args = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "segment_subscription_cache" */
export type segment_subscription_cache = {
  __typename: 'segment_subscription_cache';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  /** An object relationship */
  segment: segment;
  segment_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
};

/** aggregated selection of "segment_subscription_cache" */
export type segment_subscription_cache_aggregate = {
  __typename: 'segment_subscription_cache_aggregate';
  aggregate?: Maybe<segment_subscription_cache_aggregate_fields>;
  nodes: Array<segment_subscription_cache>;
};

/** aggregate fields of "segment_subscription_cache" */
export type segment_subscription_cache_aggregate_fields = {
  __typename: 'segment_subscription_cache_aggregate_fields';
  avg?: Maybe<segment_subscription_cache_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_subscription_cache_max_fields>;
  min?: Maybe<segment_subscription_cache_min_fields>;
  stddev?: Maybe<segment_subscription_cache_stddev_fields>;
  stddev_pop?: Maybe<segment_subscription_cache_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_subscription_cache_stddev_samp_fields>;
  sum?: Maybe<segment_subscription_cache_sum_fields>;
  var_pop?: Maybe<segment_subscription_cache_var_pop_fields>;
  var_samp?: Maybe<segment_subscription_cache_var_samp_fields>;
  variance?: Maybe<segment_subscription_cache_variance_fields>;
};


/** aggregate fields of "segment_subscription_cache" */
export type segment_subscription_cache_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_subscription_cache_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type segment_subscription_cache_avg_fields = {
  __typename: 'segment_subscription_cache_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "segment_subscription_cache". All fields are combined with a logical 'AND'. */
export type segment_subscription_cache_bool_exp = {
  _and?: InputMaybe<Array<segment_subscription_cache_bool_exp>>;
  _not?: InputMaybe<segment_subscription_cache_bool_exp>;
  _or?: InputMaybe<Array<segment_subscription_cache_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "segment_subscription_cache" */
export enum segment_subscription_cache_constraint {
  /** unique or primary key constraint on columns "subscription_id", "account_id", "segment_id" */
  segment_subscription_cache_pkey = 'segment_subscription_cache_pkey'
}

/** input type for incrementing numeric columns in table "segment_subscription_cache" */
export type segment_subscription_cache_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "segment_subscription_cache" */
export type segment_subscription_cache_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type segment_subscription_cache_max_fields = {
  __typename: 'segment_subscription_cache_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type segment_subscription_cache_min_fields = {
  __typename: 'segment_subscription_cache_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "segment_subscription_cache" */
export type segment_subscription_cache_mutation_response = {
  __typename: 'segment_subscription_cache_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<segment_subscription_cache>;
};

/** on_conflict condition type for table "segment_subscription_cache" */
export type segment_subscription_cache_on_conflict = {
  constraint: segment_subscription_cache_constraint;
  update_columns?: Array<segment_subscription_cache_update_column>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};

/** Ordering options when selecting data from "segment_subscription_cache". */
export type segment_subscription_cache_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: segment_subscription_cache */
export type segment_subscription_cache_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};

/** select columns of table "segment_subscription_cache" */
export enum segment_subscription_cache_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscription_id = 'subscription_id'
}

/** input type for updating data in table "segment_subscription_cache" */
export type segment_subscription_cache_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type segment_subscription_cache_stddev_fields = {
  __typename: 'segment_subscription_cache_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type segment_subscription_cache_stddev_pop_fields = {
  __typename: 'segment_subscription_cache_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type segment_subscription_cache_stddev_samp_fields = {
  __typename: 'segment_subscription_cache_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "segment_subscription_cache" */
export type segment_subscription_cache_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_subscription_cache_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_subscription_cache_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type segment_subscription_cache_sum_fields = {
  __typename: 'segment_subscription_cache_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "segment_subscription_cache" */
export enum segment_subscription_cache_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscription_id = 'subscription_id'
}

export type segment_subscription_cache_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_subscription_cache_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_subscription_cache_set_input>;
  /** filter the rows which have to be updated */
  where: segment_subscription_cache_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_subscription_cache_var_pop_fields = {
  __typename: 'segment_subscription_cache_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type segment_subscription_cache_var_samp_fields = {
  __typename: 'segment_subscription_cache_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type segment_subscription_cache_variance_fields = {
  __typename: 'segment_subscription_cache_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate sum on columns */
export type segment_sum_fields = {
  __typename: 'segment_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  integration_id?: Maybe<Scalars['Int']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "segment" */
export type segment_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** columns and relationships of "segment_tag" */
export type segment_tag = {
  __typename: 'segment_tag';
  /** An object relationship */
  segment?: Maybe<segment>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tag?: Maybe<tag>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "segment_tag" */
export type segment_tag_aggregate = {
  __typename: 'segment_tag_aggregate';
  aggregate?: Maybe<segment_tag_aggregate_fields>;
  nodes: Array<segment_tag>;
};

export type segment_tag_aggregate_bool_exp = {
  count?: InputMaybe<segment_tag_aggregate_bool_exp_count>;
};

export type segment_tag_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<segment_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<segment_tag_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "segment_tag" */
export type segment_tag_aggregate_fields = {
  __typename: 'segment_tag_aggregate_fields';
  avg?: Maybe<segment_tag_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_tag_max_fields>;
  min?: Maybe<segment_tag_min_fields>;
  stddev?: Maybe<segment_tag_stddev_fields>;
  stddev_pop?: Maybe<segment_tag_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_tag_stddev_samp_fields>;
  sum?: Maybe<segment_tag_sum_fields>;
  var_pop?: Maybe<segment_tag_var_pop_fields>;
  var_samp?: Maybe<segment_tag_var_samp_fields>;
  variance?: Maybe<segment_tag_variance_fields>;
};


/** aggregate fields of "segment_tag" */
export type segment_tag_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment_tag" */
export type segment_tag_aggregate_order_by = {
  avg?: InputMaybe<segment_tag_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<segment_tag_max_order_by>;
  min?: InputMaybe<segment_tag_min_order_by>;
  stddev?: InputMaybe<segment_tag_stddev_order_by>;
  stddev_pop?: InputMaybe<segment_tag_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<segment_tag_stddev_samp_order_by>;
  sum?: InputMaybe<segment_tag_sum_order_by>;
  var_pop?: InputMaybe<segment_tag_var_pop_order_by>;
  var_samp?: InputMaybe<segment_tag_var_samp_order_by>;
  variance?: InputMaybe<segment_tag_variance_order_by>;
};

/** input type for inserting array relation for remote table "segment_tag" */
export type segment_tag_arr_rel_insert_input = {
  data: Array<segment_tag_insert_input>;
};

/** aggregate avg on columns */
export type segment_tag_avg_fields = {
  __typename: 'segment_tag_avg_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment_tag" */
export type segment_tag_avg_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "segment_tag". All fields are combined with a logical 'AND'. */
export type segment_tag_bool_exp = {
  _and?: InputMaybe<Array<segment_tag_bool_exp>>;
  _not?: InputMaybe<segment_tag_bool_exp>;
  _or?: InputMaybe<Array<segment_tag_bool_exp>>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  tag?: InputMaybe<tag_bool_exp>;
  tag_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "segment_tag" */
export type segment_tag_insert_input = {
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<tag_obj_rel_insert_input>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type segment_tag_max_fields = {
  __typename: 'segment_tag_max_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "segment_tag" */
export type segment_tag_max_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type segment_tag_min_fields = {
  __typename: 'segment_tag_min_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "segment_tag" */
export type segment_tag_min_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Ordering options when selecting data from "segment_tag". */
export type segment_tag_order_by = {
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
  tag?: InputMaybe<tag_order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** select columns of table "segment_tag" */
export enum segment_tag_select_column {
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  tag_id = 'tag_id'
}

/** aggregate stddev on columns */
export type segment_tag_stddev_fields = {
  __typename: 'segment_tag_stddev_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment_tag" */
export type segment_tag_stddev_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type segment_tag_stddev_pop_fields = {
  __typename: 'segment_tag_stddev_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment_tag" */
export type segment_tag_stddev_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type segment_tag_stddev_samp_fields = {
  __typename: 'segment_tag_stddev_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment_tag" */
export type segment_tag_stddev_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "segment_tag" */
export type segment_tag_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_tag_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_tag_stream_cursor_value_input = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  tag_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type segment_tag_sum_fields = {
  __typename: 'segment_tag_sum_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  tag_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "segment_tag" */
export type segment_tag_sum_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type segment_tag_var_pop_fields = {
  __typename: 'segment_tag_var_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment_tag" */
export type segment_tag_var_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type segment_tag_var_samp_fields = {
  __typename: 'segment_tag_var_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment_tag" */
export type segment_tag_var_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type segment_tag_variance_fields = {
  __typename: 'segment_tag_variance_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment_tag" */
export type segment_tag_variance_order_by = {
  segment_id?: InputMaybe<order_by>;
  tag_id?: InputMaybe<order_by>;
};

/** columns and relationships of "segment_tsvector" */
export type segment_tsvector = {
  __typename: 'segment_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "segment_tsvector" */
export type segment_tsvector_aggregate = {
  __typename: 'segment_tsvector_aggregate';
  aggregate?: Maybe<segment_tsvector_aggregate_fields>;
  nodes: Array<segment_tsvector>;
};

/** aggregate fields of "segment_tsvector" */
export type segment_tsvector_aggregate_fields = {
  __typename: 'segment_tsvector_aggregate_fields';
  avg?: Maybe<segment_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<segment_tsvector_max_fields>;
  min?: Maybe<segment_tsvector_min_fields>;
  stddev?: Maybe<segment_tsvector_stddev_fields>;
  stddev_pop?: Maybe<segment_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<segment_tsvector_stddev_samp_fields>;
  sum?: Maybe<segment_tsvector_sum_fields>;
  var_pop?: Maybe<segment_tsvector_var_pop_fields>;
  var_samp?: Maybe<segment_tsvector_var_samp_fields>;
  variance?: Maybe<segment_tsvector_variance_fields>;
};


/** aggregate fields of "segment_tsvector" */
export type segment_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<segment_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type segment_tsvector_avg_fields = {
  __typename: 'segment_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "segment_tsvector". All fields are combined with a logical 'AND'. */
export type segment_tsvector_bool_exp = {
  _and?: InputMaybe<Array<segment_tsvector_bool_exp>>;
  _not?: InputMaybe<segment_tsvector_bool_exp>;
  _or?: InputMaybe<Array<segment_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type segment_tsvector_max_fields = {
  __typename: 'segment_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type segment_tsvector_min_fields = {
  __typename: 'segment_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "segment_tsvector". */
export type segment_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "segment_tsvector" */
export enum segment_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type segment_tsvector_stddev_fields = {
  __typename: 'segment_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type segment_tsvector_stddev_pop_fields = {
  __typename: 'segment_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type segment_tsvector_stddev_samp_fields = {
  __typename: 'segment_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "segment_tsvector" */
export type segment_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: segment_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type segment_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type segment_tsvector_sum_fields = {
  __typename: 'segment_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type segment_tsvector_var_pop_fields = {
  __typename: 'segment_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type segment_tsvector_var_samp_fields = {
  __typename: 'segment_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type segment_tsvector_variance_fields = {
  __typename: 'segment_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  segment_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "segment" */
export enum segment_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  inline = 'inline',
  /** column name */
  integration_id = 'integration_id',
  /** column name */
  integration_segment_id = 'integration_segment_id',
  /** column name */
  name = 'name',
  /** column name */
  primary_segment_condition_group_id = 'primary_segment_condition_group_id',
  /** column name */
  tag_ids = 'tag_ids',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type segment_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<segment_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<segment_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<segment_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<segment_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<segment_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<segment_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<segment_set_input>;
  /** filter the rows which have to be updated */
  where: segment_bool_exp;
};

/** aggregate var_pop on columns */
export type segment_var_pop_fields = {
  __typename: 'segment_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment" */
export type segment_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type segment_var_samp_fields = {
  __typename: 'segment_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment" */
export type segment_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type segment_variance_fields = {
  __typename: 'segment_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  integration_id?: Maybe<Scalars['Float']['output']>;
  primary_segment_condition_group_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment" */
export type segment_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  integration_id?: InputMaybe<order_by>;
  primary_segment_condition_group_id?: InputMaybe<order_by>;
};

/** columns and relationships of "sentiment" */
export type sentiment = {
  __typename: 'sentiment';
  value: Scalars['String']['output'];
};

/** aggregated selection of "sentiment" */
export type sentiment_aggregate = {
  __typename: 'sentiment_aggregate';
  aggregate?: Maybe<sentiment_aggregate_fields>;
  nodes: Array<sentiment>;
};

/** aggregate fields of "sentiment" */
export type sentiment_aggregate_fields = {
  __typename: 'sentiment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<sentiment_max_fields>;
  min?: Maybe<sentiment_min_fields>;
};


/** aggregate fields of "sentiment" */
export type sentiment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sentiment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "sentiment". All fields are combined with a logical 'AND'. */
export type sentiment_bool_exp = {
  _and?: InputMaybe<Array<sentiment_bool_exp>>;
  _not?: InputMaybe<sentiment_bool_exp>;
  _or?: InputMaybe<Array<sentiment_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "sentiment" */
export enum sentiment_constraint {
  /** unique or primary key constraint on columns "value" */
  sentiment_pkey = 'sentiment_pkey'
}

export enum sentiment_enum {
  mixed = 'mixed',
  negative = 'negative',
  neutral = 'neutral',
  positive = 'positive'
}

/** Boolean expression to compare columns of type "sentiment_enum". All fields are combined with logical 'AND'. */
export type sentiment_enum_comparison_exp = {
  _eq?: InputMaybe<sentiment_enum>;
  _in?: InputMaybe<Array<sentiment_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<sentiment_enum>;
  _nin?: InputMaybe<Array<sentiment_enum>>;
};

/** input type for inserting data into table "sentiment" */
export type sentiment_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type sentiment_max_fields = {
  __typename: 'sentiment_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type sentiment_min_fields = {
  __typename: 'sentiment_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sentiment" */
export type sentiment_mutation_response = {
  __typename: 'sentiment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<sentiment>;
};

/** on_conflict condition type for table "sentiment" */
export type sentiment_on_conflict = {
  constraint: sentiment_constraint;
  update_columns?: Array<sentiment_update_column>;
  where?: InputMaybe<sentiment_bool_exp>;
};

/** Ordering options when selecting data from "sentiment". */
export type sentiment_order_by = {
  value?: InputMaybe<order_by>;
};

/** columns and relationships of "sentiment_phrase" */
export type sentiment_phrase = {
  __typename: 'sentiment_phrase';
  id: Scalars['Int']['output'];
  /** An object relationship */
  sentiment_phrase_normalized?: Maybe<sentiment_phrase_normalized>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  sentiment_phrase_question_answers: Array<question_answer_sentiment_phrase>;
  /** An aggregate relationship */
  sentiment_phrase_question_answers_aggregate: question_answer_sentiment_phrase_aggregate;
  text: Scalars['String']['output'];
};


/** columns and relationships of "sentiment_phrase" */
export type sentiment_phrasesentiment_phrase_question_answersArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


/** columns and relationships of "sentiment_phrase" */
export type sentiment_phrasesentiment_phrase_question_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};

/** aggregated selection of "sentiment_phrase" */
export type sentiment_phrase_aggregate = {
  __typename: 'sentiment_phrase_aggregate';
  aggregate?: Maybe<sentiment_phrase_aggregate_fields>;
  nodes: Array<sentiment_phrase>;
};

export type sentiment_phrase_aggregate_bool_exp = {
  count?: InputMaybe<sentiment_phrase_aggregate_bool_exp_count>;
};

export type sentiment_phrase_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<sentiment_phrase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<sentiment_phrase_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "sentiment_phrase" */
export type sentiment_phrase_aggregate_fields = {
  __typename: 'sentiment_phrase_aggregate_fields';
  avg?: Maybe<sentiment_phrase_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<sentiment_phrase_max_fields>;
  min?: Maybe<sentiment_phrase_min_fields>;
  stddev?: Maybe<sentiment_phrase_stddev_fields>;
  stddev_pop?: Maybe<sentiment_phrase_stddev_pop_fields>;
  stddev_samp?: Maybe<sentiment_phrase_stddev_samp_fields>;
  sum?: Maybe<sentiment_phrase_sum_fields>;
  var_pop?: Maybe<sentiment_phrase_var_pop_fields>;
  var_samp?: Maybe<sentiment_phrase_var_samp_fields>;
  variance?: Maybe<sentiment_phrase_variance_fields>;
};


/** aggregate fields of "sentiment_phrase" */
export type sentiment_phrase_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sentiment_phrase_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "sentiment_phrase" */
export type sentiment_phrase_aggregate_order_by = {
  avg?: InputMaybe<sentiment_phrase_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<sentiment_phrase_max_order_by>;
  min?: InputMaybe<sentiment_phrase_min_order_by>;
  stddev?: InputMaybe<sentiment_phrase_stddev_order_by>;
  stddev_pop?: InputMaybe<sentiment_phrase_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<sentiment_phrase_stddev_samp_order_by>;
  sum?: InputMaybe<sentiment_phrase_sum_order_by>;
  var_pop?: InputMaybe<sentiment_phrase_var_pop_order_by>;
  var_samp?: InputMaybe<sentiment_phrase_var_samp_order_by>;
  variance?: InputMaybe<sentiment_phrase_variance_order_by>;
};

/** input type for inserting array relation for remote table "sentiment_phrase" */
export type sentiment_phrase_arr_rel_insert_input = {
  data: Array<sentiment_phrase_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<sentiment_phrase_on_conflict>;
};

/** aggregate avg on columns */
export type sentiment_phrase_avg_fields = {
  __typename: 'sentiment_phrase_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "sentiment_phrase" */
export type sentiment_phrase_avg_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "sentiment_phrase". All fields are combined with a logical 'AND'. */
export type sentiment_phrase_bool_exp = {
  _and?: InputMaybe<Array<sentiment_phrase_bool_exp>>;
  _not?: InputMaybe<sentiment_phrase_bool_exp>;
  _or?: InputMaybe<Array<sentiment_phrase_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  sentiment_phrase_normalized?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
  sentiment_phrase_normalized_id?: InputMaybe<Int_comparison_exp>;
  sentiment_phrase_question_answers?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
  sentiment_phrase_question_answers_aggregate?: InputMaybe<question_answer_sentiment_phrase_aggregate_bool_exp>;
  text?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "sentiment_phrase" */
export enum sentiment_phrase_constraint {
  /** unique or primary key constraint on columns "id" */
  sentiment_phrase_pkey = 'sentiment_phrase_pkey',
  /** unique or primary key constraint on columns "text" */
  sentiment_phrase_text_key = 'sentiment_phrase_text_key'
}

/** input type for incrementing numeric columns in table "sentiment_phrase" */
export type sentiment_phrase_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sentiment_phrase" */
export type sentiment_phrase_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized?: InputMaybe<sentiment_phrase_normalized_obj_rel_insert_input>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_question_answers?: InputMaybe<question_answer_sentiment_phrase_arr_rel_insert_input>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type sentiment_phrase_max_fields = {
  __typename: 'sentiment_phrase_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "sentiment_phrase" */
export type sentiment_phrase_max_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
  text?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type sentiment_phrase_min_fields = {
  __typename: 'sentiment_phrase_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "sentiment_phrase" */
export type sentiment_phrase_min_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
  text?: InputMaybe<order_by>;
};

/** response of any mutation on the table "sentiment_phrase" */
export type sentiment_phrase_mutation_response = {
  __typename: 'sentiment_phrase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<sentiment_phrase>;
};

/** columns and relationships of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized = {
  __typename: 'sentiment_phrase_normalized';
  id: Scalars['Int']['output'];
  /** An array relationship */
  sentiment_phrases: Array<sentiment_phrase>;
  /** An aggregate relationship */
  sentiment_phrases_aggregate: sentiment_phrase_aggregate;
  text: Scalars['String']['output'];
};


/** columns and relationships of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalizedsentiment_phrasesArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


/** columns and relationships of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalizedsentiment_phrases_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};

/** aggregated selection of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_aggregate = {
  __typename: 'sentiment_phrase_normalized_aggregate';
  aggregate?: Maybe<sentiment_phrase_normalized_aggregate_fields>;
  nodes: Array<sentiment_phrase_normalized>;
};

/** aggregate fields of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_aggregate_fields = {
  __typename: 'sentiment_phrase_normalized_aggregate_fields';
  avg?: Maybe<sentiment_phrase_normalized_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<sentiment_phrase_normalized_max_fields>;
  min?: Maybe<sentiment_phrase_normalized_min_fields>;
  stddev?: Maybe<sentiment_phrase_normalized_stddev_fields>;
  stddev_pop?: Maybe<sentiment_phrase_normalized_stddev_pop_fields>;
  stddev_samp?: Maybe<sentiment_phrase_normalized_stddev_samp_fields>;
  sum?: Maybe<sentiment_phrase_normalized_sum_fields>;
  var_pop?: Maybe<sentiment_phrase_normalized_var_pop_fields>;
  var_samp?: Maybe<sentiment_phrase_normalized_var_samp_fields>;
  variance?: Maybe<sentiment_phrase_normalized_variance_fields>;
};


/** aggregate fields of "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<sentiment_phrase_normalized_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type sentiment_phrase_normalized_avg_fields = {
  __typename: 'sentiment_phrase_normalized_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sentiment_phrase_normalized". All fields are combined with a logical 'AND'. */
export type sentiment_phrase_normalized_bool_exp = {
  _and?: InputMaybe<Array<sentiment_phrase_normalized_bool_exp>>;
  _not?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
  _or?: InputMaybe<Array<sentiment_phrase_normalized_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  sentiment_phrases?: InputMaybe<sentiment_phrase_bool_exp>;
  sentiment_phrases_aggregate?: InputMaybe<sentiment_phrase_aggregate_bool_exp>;
  text?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "sentiment_phrase_normalized" */
export enum sentiment_phrase_normalized_constraint {
  /** unique or primary key constraint on columns "id" */
  sentiment_phrase_normalized_pkey = 'sentiment_phrase_normalized_pkey',
  /** unique or primary key constraint on columns "text" */
  sentiment_phrase_normalized_text_key = 'sentiment_phrase_normalized_text_key'
}

/** input type for incrementing numeric columns in table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrases?: InputMaybe<sentiment_phrase_arr_rel_insert_input>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type sentiment_phrase_normalized_max_fields = {
  __typename: 'sentiment_phrase_normalized_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type sentiment_phrase_normalized_min_fields = {
  __typename: 'sentiment_phrase_normalized_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_mutation_response = {
  __typename: 'sentiment_phrase_normalized_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<sentiment_phrase_normalized>;
};

/** input type for inserting object relation for remote table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_obj_rel_insert_input = {
  data: sentiment_phrase_normalized_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<sentiment_phrase_normalized_on_conflict>;
};

/** on_conflict condition type for table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_on_conflict = {
  constraint: sentiment_phrase_normalized_constraint;
  update_columns?: Array<sentiment_phrase_normalized_update_column>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};

/** Ordering options when selecting data from "sentiment_phrase_normalized". */
export type sentiment_phrase_normalized_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrases_aggregate?: InputMaybe<sentiment_phrase_aggregate_order_by>;
  text?: InputMaybe<order_by>;
};

/** primary key columns input for table: sentiment_phrase_normalized */
export type sentiment_phrase_normalized_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sentiment_phrase_normalized" */
export enum sentiment_phrase_normalized_select_column {
  /** column name */
  id = 'id',
  /** column name */
  text = 'text'
}

/** input type for updating data in table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type sentiment_phrase_normalized_stddev_fields = {
  __typename: 'sentiment_phrase_normalized_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type sentiment_phrase_normalized_stddev_pop_fields = {
  __typename: 'sentiment_phrase_normalized_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type sentiment_phrase_normalized_stddev_samp_fields = {
  __typename: 'sentiment_phrase_normalized_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sentiment_phrase_normalized" */
export type sentiment_phrase_normalized_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sentiment_phrase_normalized_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sentiment_phrase_normalized_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type sentiment_phrase_normalized_sum_fields = {
  __typename: 'sentiment_phrase_normalized_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "sentiment_phrase_normalized" */
export enum sentiment_phrase_normalized_update_column {
  /** column name */
  id = 'id',
  /** column name */
  text = 'text'
}

export type sentiment_phrase_normalized_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<sentiment_phrase_normalized_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sentiment_phrase_normalized_set_input>;
  /** filter the rows which have to be updated */
  where: sentiment_phrase_normalized_bool_exp;
};

/** aggregate var_pop on columns */
export type sentiment_phrase_normalized_var_pop_fields = {
  __typename: 'sentiment_phrase_normalized_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type sentiment_phrase_normalized_var_samp_fields = {
  __typename: 'sentiment_phrase_normalized_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type sentiment_phrase_normalized_variance_fields = {
  __typename: 'sentiment_phrase_normalized_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting object relation for remote table "sentiment_phrase" */
export type sentiment_phrase_obj_rel_insert_input = {
  data: sentiment_phrase_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<sentiment_phrase_on_conflict>;
};

/** on_conflict condition type for table "sentiment_phrase" */
export type sentiment_phrase_on_conflict = {
  constraint: sentiment_phrase_constraint;
  update_columns?: Array<sentiment_phrase_update_column>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};

/** Ordering options when selecting data from "sentiment_phrase". */
export type sentiment_phrase_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized?: InputMaybe<sentiment_phrase_normalized_order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
  sentiment_phrase_question_answers_aggregate?: InputMaybe<question_answer_sentiment_phrase_aggregate_order_by>;
  text?: InputMaybe<order_by>;
};

/** primary key columns input for table: sentiment_phrase */
export type sentiment_phrase_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sentiment_phrase" */
export enum sentiment_phrase_select_column {
  /** column name */
  id = 'id',
  /** column name */
  sentiment_phrase_normalized_id = 'sentiment_phrase_normalized_id',
  /** column name */
  text = 'text'
}

/** input type for updating data in table "sentiment_phrase" */
export type sentiment_phrase_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type sentiment_phrase_stddev_fields = {
  __typename: 'sentiment_phrase_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "sentiment_phrase" */
export type sentiment_phrase_stddev_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type sentiment_phrase_stddev_pop_fields = {
  __typename: 'sentiment_phrase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "sentiment_phrase" */
export type sentiment_phrase_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type sentiment_phrase_stddev_samp_fields = {
  __typename: 'sentiment_phrase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "sentiment_phrase" */
export type sentiment_phrase_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "sentiment_phrase" */
export type sentiment_phrase_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sentiment_phrase_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sentiment_phrase_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type sentiment_phrase_sum_fields = {
  __typename: 'sentiment_phrase_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "sentiment_phrase" */
export type sentiment_phrase_sum_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** update columns of table "sentiment_phrase" */
export enum sentiment_phrase_update_column {
  /** column name */
  id = 'id',
  /** column name */
  sentiment_phrase_normalized_id = 'sentiment_phrase_normalized_id',
  /** column name */
  text = 'text'
}

export type sentiment_phrase_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<sentiment_phrase_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sentiment_phrase_set_input>;
  /** filter the rows which have to be updated */
  where: sentiment_phrase_bool_exp;
};

/** aggregate var_pop on columns */
export type sentiment_phrase_var_pop_fields = {
  __typename: 'sentiment_phrase_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "sentiment_phrase" */
export type sentiment_phrase_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type sentiment_phrase_var_samp_fields = {
  __typename: 'sentiment_phrase_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "sentiment_phrase" */
export type sentiment_phrase_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type sentiment_phrase_variance_fields = {
  __typename: 'sentiment_phrase_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "sentiment_phrase" */
export type sentiment_phrase_variance_order_by = {
  id?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: sentiment */
export type sentiment_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "sentiment" */
export enum sentiment_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "sentiment" */
export type sentiment_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "sentiment" */
export type sentiment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: sentiment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type sentiment_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "sentiment" */
export enum sentiment_update_column {
  /** column name */
  value = 'value'
}

export type sentiment_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<sentiment_set_input>;
  /** filter the rows which have to be updated */
  where: sentiment_bool_exp;
};

/** columns and relationships of "state" */
export type state = {
  __typename: 'state';
  code: Scalars['citext']['output'];
  country: Scalars['citext']['output'];
  name: Scalars['citext']['output'];
};

/** aggregated selection of "state" */
export type state_aggregate = {
  __typename: 'state_aggregate';
  aggregate?: Maybe<state_aggregate_fields>;
  nodes: Array<state>;
};

/** aggregate fields of "state" */
export type state_aggregate_fields = {
  __typename: 'state_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<state_max_fields>;
  min?: Maybe<state_min_fields>;
};


/** aggregate fields of "state" */
export type state_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<state_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "state". All fields are combined with a logical 'AND'. */
export type state_bool_exp = {
  _and?: InputMaybe<Array<state_bool_exp>>;
  _not?: InputMaybe<state_bool_exp>;
  _or?: InputMaybe<Array<state_bool_exp>>;
  code?: InputMaybe<citext_comparison_exp>;
  country?: InputMaybe<citext_comparison_exp>;
  name?: InputMaybe<citext_comparison_exp>;
};

/** unique or primary key constraints on table "state" */
export enum state_constraint {
  /** unique or primary key constraint on columns "country", "code" */
  state_pkey = 'state_pkey'
}

/** input type for inserting data into table "state" */
export type state_insert_input = {
  code?: InputMaybe<Scalars['citext']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate max on columns */
export type state_max_fields = {
  __typename: 'state_max_fields';
  code?: Maybe<Scalars['citext']['output']>;
  country?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** aggregate min on columns */
export type state_min_fields = {
  __typename: 'state_min_fields';
  code?: Maybe<Scalars['citext']['output']>;
  country?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** response of any mutation on the table "state" */
export type state_mutation_response = {
  __typename: 'state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<state>;
};

/** columns and relationships of "state_name" */
export type state_name = {
  __typename: 'state_name';
  country: Scalars['citext']['output'];
  name: Scalars['citext']['output'];
  state: Scalars['citext']['output'];
};

/** aggregated selection of "state_name" */
export type state_name_aggregate = {
  __typename: 'state_name_aggregate';
  aggregate?: Maybe<state_name_aggregate_fields>;
  nodes: Array<state_name>;
};

/** aggregate fields of "state_name" */
export type state_name_aggregate_fields = {
  __typename: 'state_name_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<state_name_max_fields>;
  min?: Maybe<state_name_min_fields>;
};


/** aggregate fields of "state_name" */
export type state_name_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<state_name_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "state_name". All fields are combined with a logical 'AND'. */
export type state_name_bool_exp = {
  _and?: InputMaybe<Array<state_name_bool_exp>>;
  _not?: InputMaybe<state_name_bool_exp>;
  _or?: InputMaybe<Array<state_name_bool_exp>>;
  country?: InputMaybe<citext_comparison_exp>;
  name?: InputMaybe<citext_comparison_exp>;
  state?: InputMaybe<citext_comparison_exp>;
};

/** unique or primary key constraints on table "state_name" */
export enum state_name_constraint {
  /** unique or primary key constraint on columns "country", "name" */
  state_name_pkey = 'state_name_pkey'
}

/** input type for inserting data into table "state_name" */
export type state_name_insert_input = {
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate max on columns */
export type state_name_max_fields = {
  __typename: 'state_name_max_fields';
  country?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
  state?: Maybe<Scalars['citext']['output']>;
};

/** aggregate min on columns */
export type state_name_min_fields = {
  __typename: 'state_name_min_fields';
  country?: Maybe<Scalars['citext']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
  state?: Maybe<Scalars['citext']['output']>;
};

/** response of any mutation on the table "state_name" */
export type state_name_mutation_response = {
  __typename: 'state_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<state_name>;
};

/** on_conflict condition type for table "state_name" */
export type state_name_on_conflict = {
  constraint: state_name_constraint;
  update_columns?: Array<state_name_update_column>;
  where?: InputMaybe<state_name_bool_exp>;
};

/** Ordering options when selecting data from "state_name". */
export type state_name_order_by = {
  country?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  state?: InputMaybe<order_by>;
};

/** primary key columns input for table: state_name */
export type state_name_pk_columns_input = {
  country: Scalars['citext']['input'];
  name: Scalars['citext']['input'];
};

/** select columns of table "state_name" */
export enum state_name_select_column {
  /** column name */
  country = 'country',
  /** column name */
  name = 'name',
  /** column name */
  state = 'state'
}

/** input type for updating data in table "state_name" */
export type state_name_set_input = {
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
};

/** Streaming cursor of the table "state_name" */
export type state_name_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: state_name_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type state_name_stream_cursor_value_input = {
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
  state?: InputMaybe<Scalars['citext']['input']>;
};

/** update columns of table "state_name" */
export enum state_name_update_column {
  /** column name */
  country = 'country',
  /** column name */
  name = 'name',
  /** column name */
  state = 'state'
}

export type state_name_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<state_name_set_input>;
  /** filter the rows which have to be updated */
  where: state_name_bool_exp;
};

/** on_conflict condition type for table "state" */
export type state_on_conflict = {
  constraint: state_constraint;
  update_columns?: Array<state_update_column>;
  where?: InputMaybe<state_bool_exp>;
};

/** Ordering options when selecting data from "state". */
export type state_order_by = {
  code?: InputMaybe<order_by>;
  country?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: state */
export type state_pk_columns_input = {
  code: Scalars['citext']['input'];
  country: Scalars['citext']['input'];
};

/** select columns of table "state" */
export enum state_select_column {
  /** column name */
  code = 'code',
  /** column name */
  country = 'country',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "state" */
export type state_set_input = {
  code?: InputMaybe<Scalars['citext']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** Streaming cursor of the table "state" */
export type state_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: state_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type state_stream_cursor_value_input = {
  code?: InputMaybe<Scalars['citext']['input']>;
  country?: InputMaybe<Scalars['citext']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** update columns of table "state" */
export enum state_update_column {
  /** column name */
  code = 'code',
  /** column name */
  country = 'country',
  /** column name */
  name = 'name'
}

export type state_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<state_set_input>;
  /** filter the rows which have to be updated */
  where: state_bool_exp;
};

/** columns and relationships of "stripe_app" */
export type stripe_app = {
  __typename: 'stripe_app';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  stripe_account_id: Scalars['String']['output'];
};

/** aggregated selection of "stripe_app" */
export type stripe_app_aggregate = {
  __typename: 'stripe_app_aggregate';
  aggregate?: Maybe<stripe_app_aggregate_fields>;
  nodes: Array<stripe_app>;
};

/** aggregate fields of "stripe_app" */
export type stripe_app_aggregate_fields = {
  __typename: 'stripe_app_aggregate_fields';
  avg?: Maybe<stripe_app_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<stripe_app_max_fields>;
  min?: Maybe<stripe_app_min_fields>;
  stddev?: Maybe<stripe_app_stddev_fields>;
  stddev_pop?: Maybe<stripe_app_stddev_pop_fields>;
  stddev_samp?: Maybe<stripe_app_stddev_samp_fields>;
  sum?: Maybe<stripe_app_sum_fields>;
  var_pop?: Maybe<stripe_app_var_pop_fields>;
  var_samp?: Maybe<stripe_app_var_samp_fields>;
  variance?: Maybe<stripe_app_variance_fields>;
};


/** aggregate fields of "stripe_app" */
export type stripe_app_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<stripe_app_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type stripe_app_avg_fields = {
  __typename: 'stripe_app_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_app". All fields are combined with a logical 'AND'. */
export type stripe_app_bool_exp = {
  _and?: InputMaybe<Array<stripe_app_bool_exp>>;
  _not?: InputMaybe<stripe_app_bool_exp>;
  _or?: InputMaybe<Array<stripe_app_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  stripe_account_id?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "stripe_app" */
export enum stripe_app_constraint {
  /** unique or primary key constraint on columns "id" */
  stripe_app_pkey = 'stripe_app_pkey',
  /** unique or primary key constraint on columns "stripe_account_id" */
  stripe_app_stripe_account_id_key = 'stripe_app_stripe_account_id_key'
}

/** input type for incrementing numeric columns in table "stripe_app" */
export type stripe_app_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "stripe_app" */
export type stripe_app_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type stripe_app_max_fields = {
  __typename: 'stripe_app_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  stripe_account_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type stripe_app_min_fields = {
  __typename: 'stripe_app_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  stripe_account_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_app" */
export type stripe_app_mutation_response = {
  __typename: 'stripe_app_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<stripe_app>;
};

/** on_conflict condition type for table "stripe_app" */
export type stripe_app_on_conflict = {
  constraint: stripe_app_constraint;
  update_columns?: Array<stripe_app_update_column>;
  where?: InputMaybe<stripe_app_bool_exp>;
};

/** Ordering options when selecting data from "stripe_app". */
export type stripe_app_order_by = {
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  stripe_account_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: stripe_app */
export type stripe_app_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "stripe_app" */
export enum stripe_app_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  stripe_account_id = 'stripe_account_id'
}

/** input type for updating data in table "stripe_app" */
export type stripe_app_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type stripe_app_stddev_fields = {
  __typename: 'stripe_app_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type stripe_app_stddev_pop_fields = {
  __typename: 'stripe_app_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type stripe_app_stddev_samp_fields = {
  __typename: 'stripe_app_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_app" */
export type stripe_app_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: stripe_app_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type stripe_app_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  stripe_account_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type stripe_app_sum_fields = {
  __typename: 'stripe_app_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "stripe_app" */
export enum stripe_app_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  stripe_account_id = 'stripe_account_id'
}

export type stripe_app_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<stripe_app_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<stripe_app_set_input>;
  /** filter the rows which have to be updated */
  where: stripe_app_bool_exp;
};

/** aggregate var_pop on columns */
export type stripe_app_var_pop_fields = {
  __typename: 'stripe_app_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type stripe_app_var_samp_fields = {
  __typename: 'stripe_app_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type stripe_app_variance_fields = {
  __typename: 'stripe_app_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber" */
export type subscriber = {
  __typename: 'subscriber';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  active_mrr: Scalars['numeric']['output'];
  active_offer_group_id?: Maybe<Scalars['Int']['output']>;
  active_offer_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email: Scalars['String']['output'];
  hubspot_company_id?: Maybe<Scalars['String']['output']>;
  hubspot_contact_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inactive_mrr: Scalars['numeric']['output'];
  intercom_contact_id?: Maybe<Scalars['String']['output']>;
  klaviyo_profile_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  most_recent_subscriber_flow?: Maybe<subscriber_most_recent_subscriber_flow>;
  name: Scalars['String']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  platform_connection_id: Scalars['Int']['output'];
  /** An object relationship */
  platform_customer?: Maybe<platform_customer>;
  platform_id: Scalars['String']['output'];
  /** An array relationship */
  portal_session_subscribers: Array<portal_session_subscriber>;
  /** An aggregate relationship */
  portal_session_subscribers_aggregate: portal_session_subscriber_aggregate;
  /** An array relationship */
  revenue_logs: Array<revenue_log>;
  /** An aggregate relationship */
  revenue_logs_aggregate: revenue_log_aggregate;
  salesforce_contact_id?: Maybe<Scalars['String']['output']>;
  segment_user_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<subscriber_status_enum>;
  /** An array relationship */
  subscriber_acknowledgements: Array<subscriber_acknowledgement>;
  /** An aggregate relationship */
  subscriber_acknowledgements_aggregate: subscriber_acknowledgement_aggregate;
  /** An object relationship */
  subscriber_arr?: Maybe<subscriber_arr>;
  /** An array relationship */
  subscriber_campaigns: Array<subscriber_campaign>;
  /** An aggregate relationship */
  subscriber_campaigns_aggregate: subscriber_campaign_aggregate;
  /** An object relationship */
  subscriber_created_at?: Maybe<subscriber_created_at>;
  /** An object relationship */
  subscriber_days_since_saved?: Maybe<subscriber_days_since_saved>;
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
  /** An array relationship */
  subscriber_logs: Array<subscriber_log>;
  /** An aggregate relationship */
  subscriber_logs_aggregate: subscriber_log_aggregate;
  /** An object relationship */
  subscriber_ltv?: Maybe<subscriber_ltv>;
  /** An object relationship */
  subscriber_mrr?: Maybe<subscriber_mrr>;
  /** An array relationship */
  subscriber_properties: Array<subscriber_property>;
  /** An aggregate relationship */
  subscriber_properties_aggregate: subscriber_property_aggregate;
  /** An array relationship */
  subscriber_property_values: Array<subscriber_property_value>;
  /** An aggregate relationship */
  subscriber_property_values_aggregate: subscriber_property_value_aggregate;
  /** An object relationship */
  subscriber_status?: Maybe<subscriber_status>;
  /** An array relationship */
  subscriptions: Array<subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: subscription_aggregate;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "subscriber" */
export type subscriberportal_session_subscribersArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscriberportal_session_subscribers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscriberrevenue_logsArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscriberrevenue_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_acknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_acknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_campaignsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_campaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_logsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_propertiesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_properties_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_property_valuesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriber_property_values_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


/** columns and relationships of "subscriber" */
export type subscribersubscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};

/** columns and relationships of "subscriber_acknowledgement" */
export type subscriber_acknowledgement = {
  __typename: 'subscriber_acknowledgement';
  /** An object relationship */
  acknowledgement: acknowledgement;
  acknowledgement_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  subscriber: subscriber;
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
  subscriber_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_acknowledgement" */
export type subscriber_acknowledgement_aggregate = {
  __typename: 'subscriber_acknowledgement_aggregate';
  aggregate?: Maybe<subscriber_acknowledgement_aggregate_fields>;
  nodes: Array<subscriber_acknowledgement>;
};

export type subscriber_acknowledgement_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_acknowledgement_aggregate_bool_exp_count>;
};

export type subscriber_acknowledgement_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_acknowledgement_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_acknowledgement" */
export type subscriber_acknowledgement_aggregate_fields = {
  __typename: 'subscriber_acknowledgement_aggregate_fields';
  avg?: Maybe<subscriber_acknowledgement_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_acknowledgement_max_fields>;
  min?: Maybe<subscriber_acknowledgement_min_fields>;
  stddev?: Maybe<subscriber_acknowledgement_stddev_fields>;
  stddev_pop?: Maybe<subscriber_acknowledgement_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_acknowledgement_stddev_samp_fields>;
  sum?: Maybe<subscriber_acknowledgement_sum_fields>;
  var_pop?: Maybe<subscriber_acknowledgement_var_pop_fields>;
  var_samp?: Maybe<subscriber_acknowledgement_var_samp_fields>;
  variance?: Maybe<subscriber_acknowledgement_variance_fields>;
};


/** aggregate fields of "subscriber_acknowledgement" */
export type subscriber_acknowledgement_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_aggregate_order_by = {
  avg?: InputMaybe<subscriber_acknowledgement_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_acknowledgement_max_order_by>;
  min?: InputMaybe<subscriber_acknowledgement_min_order_by>;
  stddev?: InputMaybe<subscriber_acknowledgement_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_acknowledgement_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_acknowledgement_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_acknowledgement_sum_order_by>;
  var_pop?: InputMaybe<subscriber_acknowledgement_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_acknowledgement_var_samp_order_by>;
  variance?: InputMaybe<subscriber_acknowledgement_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_arr_rel_insert_input = {
  data: Array<subscriber_acknowledgement_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_acknowledgement_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_acknowledgement_avg_fields = {
  __typename: 'subscriber_acknowledgement_avg_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_avg_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_acknowledgement". All fields are combined with a logical 'AND'. */
export type subscriber_acknowledgement_bool_exp = {
  _and?: InputMaybe<Array<subscriber_acknowledgement_bool_exp>>;
  _not?: InputMaybe<subscriber_acknowledgement_bool_exp>;
  _or?: InputMaybe<Array<subscriber_acknowledgement_bool_exp>>;
  acknowledgement?: InputMaybe<acknowledgement_bool_exp>;
  acknowledgement_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_acknowledgement" */
export enum subscriber_acknowledgement_constraint {
  /** unique or primary key constraint on columns "acknowledgement_id", "subscriber_id", "subscriber_flow_id" */
  subscriber_acknowledgement_pkey = 'subscriber_acknowledgement_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_inc_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_insert_input = {
  acknowledgement?: InputMaybe<acknowledgement_obj_rel_insert_input>;
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_acknowledgement_max_fields = {
  __typename: 'subscriber_acknowledgement_max_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_max_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_acknowledgement_min_fields = {
  __typename: 'subscriber_acknowledgement_min_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_min_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_mutation_response = {
  __typename: 'subscriber_acknowledgement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_acknowledgement>;
};

/** on_conflict condition type for table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_on_conflict = {
  constraint: subscriber_acknowledgement_constraint;
  update_columns?: Array<subscriber_acknowledgement_update_column>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_acknowledgement". */
export type subscriber_acknowledgement_order_by = {
  acknowledgement?: InputMaybe<acknowledgement_order_by>;
  acknowledgement_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_acknowledgement */
export type subscriber_acknowledgement_pk_columns_input = {
  acknowledgement_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_acknowledgement" */
export enum subscriber_acknowledgement_select_column {
  /** column name */
  acknowledgement_id = 'acknowledgement_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** input type for updating data in table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_set_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_acknowledgement_stddev_fields = {
  __typename: 'subscriber_acknowledgement_stddev_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_stddev_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_acknowledgement_stddev_pop_fields = {
  __typename: 'subscriber_acknowledgement_stddev_pop_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_stddev_pop_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_acknowledgement_stddev_samp_fields = {
  __typename: 'subscriber_acknowledgement_stddev_samp_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_stddev_samp_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_acknowledgement_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_acknowledgement_stream_cursor_value_input = {
  acknowledgement_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_acknowledgement_sum_fields = {
  __typename: 'subscriber_acknowledgement_sum_fields';
  acknowledgement_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_sum_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_acknowledgement" */
export enum subscriber_acknowledgement_update_column {
  /** column name */
  acknowledgement_id = 'acknowledgement_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  subscriber_id = 'subscriber_id'
}

export type subscriber_acknowledgement_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_acknowledgement_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_acknowledgement_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_acknowledgement_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_acknowledgement_var_pop_fields = {
  __typename: 'subscriber_acknowledgement_var_pop_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_var_pop_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_acknowledgement_var_samp_fields = {
  __typename: 'subscriber_acknowledgement_var_samp_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_var_samp_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_acknowledgement_variance_fields = {
  __typename: 'subscriber_acknowledgement_variance_fields';
  acknowledgement_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_acknowledgement" */
export type subscriber_acknowledgement_variance_order_by = {
  acknowledgement_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregated selection of "subscriber" */
export type subscriber_aggregate = {
  __typename: 'subscriber_aggregate';
  aggregate?: Maybe<subscriber_aggregate_fields>;
  nodes: Array<subscriber>;
};

export type subscriber_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_aggregate_bool_exp_count>;
};

export type subscriber_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber" */
export type subscriber_aggregate_fields = {
  __typename: 'subscriber_aggregate_fields';
  avg?: Maybe<subscriber_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_max_fields>;
  min?: Maybe<subscriber_min_fields>;
  stddev?: Maybe<subscriber_stddev_fields>;
  stddev_pop?: Maybe<subscriber_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_stddev_samp_fields>;
  sum?: Maybe<subscriber_sum_fields>;
  var_pop?: Maybe<subscriber_var_pop_fields>;
  var_samp?: Maybe<subscriber_var_samp_fields>;
  variance?: Maybe<subscriber_variance_fields>;
};


/** aggregate fields of "subscriber" */
export type subscriber_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber" */
export type subscriber_aggregate_order_by = {
  avg?: InputMaybe<subscriber_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_max_order_by>;
  min?: InputMaybe<subscriber_min_order_by>;
  stddev?: InputMaybe<subscriber_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_sum_order_by>;
  var_pop?: InputMaybe<subscriber_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_var_samp_order_by>;
  variance?: InputMaybe<subscriber_variance_order_by>;
};

/** columns and relationships of "subscriber_arr" */
export type subscriber_arr = {
  __typename: 'subscriber_arr';
  arr?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscriber_arr" */
export type subscriber_arr_aggregate = {
  __typename: 'subscriber_arr_aggregate';
  aggregate?: Maybe<subscriber_arr_aggregate_fields>;
  nodes: Array<subscriber_arr>;
};

/** aggregate fields of "subscriber_arr" */
export type subscriber_arr_aggregate_fields = {
  __typename: 'subscriber_arr_aggregate_fields';
  avg?: Maybe<subscriber_arr_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_arr_max_fields>;
  min?: Maybe<subscriber_arr_min_fields>;
  stddev?: Maybe<subscriber_arr_stddev_fields>;
  stddev_pop?: Maybe<subscriber_arr_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_arr_stddev_samp_fields>;
  sum?: Maybe<subscriber_arr_sum_fields>;
  var_pop?: Maybe<subscriber_arr_var_pop_fields>;
  var_samp?: Maybe<subscriber_arr_var_samp_fields>;
  variance?: Maybe<subscriber_arr_variance_fields>;
};


/** aggregate fields of "subscriber_arr" */
export type subscriber_arr_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_arr_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_arr_avg_fields = {
  __typename: 'subscriber_arr_avg_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_arr". All fields are combined with a logical 'AND'. */
export type subscriber_arr_bool_exp = {
  _and?: InputMaybe<Array<subscriber_arr_bool_exp>>;
  _not?: InputMaybe<subscriber_arr_bool_exp>;
  _or?: InputMaybe<Array<subscriber_arr_bool_exp>>;
  arr?: InputMaybe<numeric_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for incrementing numeric columns in table "subscriber_arr" */
export type subscriber_arr_inc_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_arr" */
export type subscriber_arr_insert_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_arr_max_fields = {
  __typename: 'subscriber_arr_max_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_arr_min_fields = {
  __typename: 'subscriber_arr_min_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscriber_arr" */
export type subscriber_arr_mutation_response = {
  __typename: 'subscriber_arr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_arr>;
};

/** input type for inserting object relation for remote table "subscriber_arr" */
export type subscriber_arr_obj_rel_insert_input = {
  data: subscriber_arr_insert_input;
};

/** Ordering options when selecting data from "subscriber_arr". */
export type subscriber_arr_order_by = {
  arr?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** input type for inserting array relation for remote table "subscriber" */
export type subscriber_arr_rel_insert_input = {
  data: Array<subscriber_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_on_conflict>;
};

/** select columns of table "subscriber_arr" */
export enum subscriber_arr_select_column {
  /** column name */
  arr = 'arr',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** input type for updating data in table "subscriber_arr" */
export type subscriber_arr_set_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_arr_stddev_fields = {
  __typename: 'subscriber_arr_stddev_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_arr_stddev_pop_fields = {
  __typename: 'subscriber_arr_stddev_pop_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_arr_stddev_samp_fields = {
  __typename: 'subscriber_arr_stddev_samp_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_arr" */
export type subscriber_arr_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_arr_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_arr_stream_cursor_value_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_arr_sum_fields = {
  __typename: 'subscriber_arr_sum_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

export type subscriber_arr_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_arr_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_arr_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_arr_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_arr_var_pop_fields = {
  __typename: 'subscriber_arr_var_pop_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_arr_var_samp_fields = {
  __typename: 'subscriber_arr_var_samp_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_arr_variance_fields = {
  __typename: 'subscriber_arr_variance_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type subscriber_avg_fields = {
  __typename: 'subscriber_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber" */
export type subscriber_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber". All fields are combined with a logical 'AND'. */
export type subscriber_bool_exp = {
  _and?: InputMaybe<Array<subscriber_bool_exp>>;
  _not?: InputMaybe<subscriber_bool_exp>;
  _or?: InputMaybe<Array<subscriber_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  active_mrr?: InputMaybe<numeric_comparison_exp>;
  active_offer_group_id?: InputMaybe<Int_comparison_exp>;
  active_offer_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  hubspot_company_id?: InputMaybe<String_comparison_exp>;
  hubspot_contact_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  inactive_mrr?: InputMaybe<numeric_comparison_exp>;
  intercom_contact_id?: InputMaybe<String_comparison_exp>;
  klaviyo_profile_id?: InputMaybe<String_comparison_exp>;
  most_recent_subscriber_flow?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
  name?: InputMaybe<String_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_customer?: InputMaybe<platform_customer_bool_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  portal_session_subscribers?: InputMaybe<portal_session_subscriber_bool_exp>;
  portal_session_subscribers_aggregate?: InputMaybe<portal_session_subscriber_aggregate_bool_exp>;
  revenue_logs?: InputMaybe<revenue_log_bool_exp>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_bool_exp>;
  salesforce_contact_id?: InputMaybe<String_comparison_exp>;
  segment_user_id?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<subscriber_status_enum_comparison_exp>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_bool_exp>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_bool_exp>;
  subscriber_arr?: InputMaybe<subscriber_arr_bool_exp>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_bool_exp>;
  subscriber_created_at?: InputMaybe<subscriber_created_at_bool_exp>;
  subscriber_days_since_saved?: InputMaybe<subscriber_days_since_saved_bool_exp>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
  subscriber_logs?: InputMaybe<subscriber_log_bool_exp>;
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_bool_exp>;
  subscriber_ltv?: InputMaybe<subscriber_ltv_bool_exp>;
  subscriber_mrr?: InputMaybe<subscriber_mrr_bool_exp>;
  subscriber_properties?: InputMaybe<subscriber_property_bool_exp>;
  subscriber_properties_aggregate?: InputMaybe<subscriber_property_aggregate_bool_exp>;
  subscriber_property_values?: InputMaybe<subscriber_property_value_bool_exp>;
  subscriber_property_values_aggregate?: InputMaybe<subscriber_property_value_aggregate_bool_exp>;
  subscriber_status?: InputMaybe<subscriber_status_bool_exp>;
  subscriptions?: InputMaybe<subscription_bool_exp>;
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaign = {
  __typename: 'subscriber_campaign';
  /** An object relationship */
  campaign: campaign;
  campaign_id: Scalars['Int']['output'];
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  payload?: Maybe<Scalars['jsonb']['output']>;
  present_after?: Maybe<Scalars['timestamptz']['output']>;
  signed: Scalars['Boolean']['output'];
  status: subscriber_campaign_status_enum;
  /** An object relationship */
  subscriber: subscriber;
  /** An array relationship */
  subscriber_campaign_offer_rules: Array<subscriber_campaign_offer_rule>;
  /** An aggregate relationship */
  subscriber_campaign_offer_rules_aggregate: subscriber_campaign_offer_rule_aggregate;
  /** An array relationship */
  subscriber_campaign_offers: Array<subscriber_campaign_offer>;
  /** An aggregate relationship */
  subscriber_campaign_offers_aggregate: subscriber_campaign_offer_aggregate;
  /** An array relationship */
  subscriber_campaign_overrides: Array<subscriber_campaign_override>;
  /** An aggregate relationship */
  subscriber_campaign_overrides_aggregate: subscriber_campaign_override_aggregate;
  /** An array relationship */
  subscriber_campaign_segments: Array<subscriber_campaign_segment>;
  /** An aggregate relationship */
  subscriber_campaign_segments_aggregate: subscriber_campaign_segment_aggregate;
  /** An array relationship */
  subscriber_campaign_views: Array<subscriber_campaign_view>;
  /** An aggregate relationship */
  subscriber_campaign_views_aggregate: subscriber_campaign_view_aggregate;
  subscriber_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  triggered_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignpayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_offer_rulesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_offer_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_overridesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_overrides_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_segmentsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_viewsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


/** columns and relationships of "subscriber_campaign" */
export type subscriber_campaignsubscriber_campaign_views_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};

/** aggregated selection of "subscriber_campaign" */
export type subscriber_campaign_aggregate = {
  __typename: 'subscriber_campaign_aggregate';
  aggregate?: Maybe<subscriber_campaign_aggregate_fields>;
  nodes: Array<subscriber_campaign>;
};

export type subscriber_campaign_aggregate_bool_exp = {
  bool_and?: InputMaybe<subscriber_campaign_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<subscriber_campaign_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<subscriber_campaign_aggregate_bool_exp_count>;
};

export type subscriber_campaign_aggregate_bool_exp_bool_and = {
  arguments: subscriber_campaign_select_column_subscriber_campaign_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_campaign_aggregate_bool_exp_bool_or = {
  arguments: subscriber_campaign_select_column_subscriber_campaign_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_campaign_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign" */
export type subscriber_campaign_aggregate_fields = {
  __typename: 'subscriber_campaign_aggregate_fields';
  avg?: Maybe<subscriber_campaign_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_max_fields>;
  min?: Maybe<subscriber_campaign_min_fields>;
  stddev?: Maybe<subscriber_campaign_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_variance_fields>;
};


/** aggregate fields of "subscriber_campaign" */
export type subscriber_campaign_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign" */
export type subscriber_campaign_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_max_order_by>;
  min?: InputMaybe<subscriber_campaign_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscriber_campaign_append_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriber_campaign" */
export type subscriber_campaign_arr_rel_insert_input = {
  data: Array<subscriber_campaign_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_avg_fields = {
  __typename: 'subscriber_campaign_avg_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign" */
export type subscriber_campaign_avg_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_bool_exp>>;
  campaign?: InputMaybe<campaign_bool_exp>;
  campaign_id?: InputMaybe<Int_comparison_exp>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<String_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  payload?: InputMaybe<jsonb_comparison_exp>;
  present_after?: InputMaybe<timestamptz_comparison_exp>;
  signed?: InputMaybe<Boolean_comparison_exp>;
  status?: InputMaybe<subscriber_campaign_status_enum_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_campaign_offer_rules?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
  subscriber_campaign_offer_rules_aggregate?: InputMaybe<subscriber_campaign_offer_rule_aggregate_bool_exp>;
  subscriber_campaign_offers?: InputMaybe<subscriber_campaign_offer_bool_exp>;
  subscriber_campaign_offers_aggregate?: InputMaybe<subscriber_campaign_offer_aggregate_bool_exp>;
  subscriber_campaign_overrides?: InputMaybe<subscriber_campaign_override_bool_exp>;
  subscriber_campaign_overrides_aggregate?: InputMaybe<subscriber_campaign_override_aggregate_bool_exp>;
  subscriber_campaign_segments?: InputMaybe<subscriber_campaign_segment_bool_exp>;
  subscriber_campaign_segments_aggregate?: InputMaybe<subscriber_campaign_segment_aggregate_bool_exp>;
  subscriber_campaign_views?: InputMaybe<subscriber_campaign_view_bool_exp>;
  subscriber_campaign_views_aggregate?: InputMaybe<subscriber_campaign_view_aggregate_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  triggered_at?: InputMaybe<timestamptz_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user_agent?: InputMaybe<String_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign" */
export enum subscriber_campaign_constraint {
  /** unique or primary key constraint on columns "id" */
  subscriber_campaign_pkey = 'subscriber_campaign_pkey',
  /** unique or primary key constraint on columns "subscription_id", "campaign_id", "subscriber_id" */
  subscriber_campaign_subscriber_id_campaign_id_subscription_id_k = 'subscriber_campaign_subscriber_id_campaign_id_subscription_id_k',
  /** unique or primary key constraint on columns "token" */
  subscriber_campaign_token_key = 'subscriber_campaign_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscriber_campaign_delete_at_path_input = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscriber_campaign_delete_elem_input = {
  payload?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscriber_campaign_delete_key_input = {
  payload?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscriber_campaign" */
export type subscriber_campaign_inc_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign" */
export type subscriber_campaign_insert_input = {
  campaign?: InputMaybe<campaign_obj_rel_insert_input>;
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  present_after?: InputMaybe<Scalars['timestamptz']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<subscriber_campaign_status_enum>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_campaign_offer_rules?: InputMaybe<subscriber_campaign_offer_rule_arr_rel_insert_input>;
  subscriber_campaign_offers?: InputMaybe<subscriber_campaign_offer_arr_rel_insert_input>;
  subscriber_campaign_overrides?: InputMaybe<subscriber_campaign_override_arr_rel_insert_input>;
  subscriber_campaign_segments?: InputMaybe<subscriber_campaign_segment_arr_rel_insert_input>;
  subscriber_campaign_views?: InputMaybe<subscriber_campaign_view_arr_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  triggered_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_max_fields = {
  __typename: 'subscriber_campaign_max_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  present_after?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  triggered_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "subscriber_campaign" */
export type subscriber_campaign_max_order_by = {
  campaign_id?: InputMaybe<order_by>;
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  present_after?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  triggered_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_min_fields = {
  __typename: 'subscriber_campaign_min_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  present_after?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  triggered_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "subscriber_campaign" */
export type subscriber_campaign_min_order_by = {
  campaign_id?: InputMaybe<order_by>;
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  present_after?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  triggered_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign" */
export type subscriber_campaign_mutation_response = {
  __typename: 'subscriber_campaign_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign>;
};

/** input type for inserting object relation for remote table "subscriber_campaign" */
export type subscriber_campaign_obj_rel_insert_input = {
  data: subscriber_campaign_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_on_conflict>;
};

/** columns and relationships of "subscriber_campaign_offer" */
export type subscriber_campaign_offer = {
  __typename: 'subscriber_campaign_offer';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_test?: Maybe<offer_test>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscriber_campaign: subscriber_campaign;
  subscriber_campaign_id: Scalars['Int']['output'];
  /** An array relationship */
  subscriber_campaign_views: Array<subscriber_campaign_view>;
  /** An aggregate relationship */
  subscriber_campaign_views_aggregate: subscriber_campaign_view_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "subscriber_campaign_offer" */
export type subscriber_campaign_offersubscriber_campaign_viewsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


/** columns and relationships of "subscriber_campaign_offer" */
export type subscriber_campaign_offersubscriber_campaign_views_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};

/** aggregated selection of "subscriber_campaign_offer" */
export type subscriber_campaign_offer_aggregate = {
  __typename: 'subscriber_campaign_offer_aggregate';
  aggregate?: Maybe<subscriber_campaign_offer_aggregate_fields>;
  nodes: Array<subscriber_campaign_offer>;
};

export type subscriber_campaign_offer_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_campaign_offer_aggregate_bool_exp_count>;
};

export type subscriber_campaign_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign_offer" */
export type subscriber_campaign_offer_aggregate_fields = {
  __typename: 'subscriber_campaign_offer_aggregate_fields';
  avg?: Maybe<subscriber_campaign_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_offer_max_fields>;
  min?: Maybe<subscriber_campaign_offer_min_fields>;
  stddev?: Maybe<subscriber_campaign_offer_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_offer_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_offer_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_offer_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_offer_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_offer_variance_fields>;
};


/** aggregate fields of "subscriber_campaign_offer" */
export type subscriber_campaign_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_offer_max_order_by>;
  min?: InputMaybe<subscriber_campaign_offer_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_offer_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_offer_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_offer_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_offer_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_arr_rel_insert_input = {
  data: Array<subscriber_campaign_offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_offer_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_offer_avg_fields = {
  __typename: 'subscriber_campaign_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_offer". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_offer_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_offer_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_offer_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_offer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaign_id?: InputMaybe<Int_comparison_exp>;
  subscriber_campaign_views?: InputMaybe<subscriber_campaign_view_bool_exp>;
  subscriber_campaign_views_aggregate?: InputMaybe<subscriber_campaign_view_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_offer" */
export enum subscriber_campaign_offer_constraint {
  /** unique or primary key constraint on columns "offer_id", "subscriber_campaign_id" */
  subscriber_campaign_offer_pkey = 'subscriber_campaign_offer_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_obj_rel_insert_input>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_views?: InputMaybe<subscriber_campaign_view_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_offer_max_fields = {
  __typename: 'subscriber_campaign_offer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_offer_min_fields = {
  __typename: 'subscriber_campaign_offer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_mutation_response = {
  __typename: 'subscriber_campaign_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_offer>;
};

/** input type for inserting object relation for remote table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_obj_rel_insert_input = {
  data: subscriber_campaign_offer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_offer_on_conflict>;
};

/** on_conflict condition type for table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_on_conflict = {
  constraint: subscriber_campaign_offer_constraint;
  update_columns?: Array<subscriber_campaign_offer_update_column>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_offer". */
export type subscriber_campaign_offer_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  subscriber_campaign_views_aggregate?: InputMaybe<subscriber_campaign_view_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_offer */
export type subscriber_campaign_offer_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};

/** columns and relationships of "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule = {
  __typename: 'subscriber_campaign_offer_rule';
  /** An object relationship */
  offer_rule: offer_rule;
  offer_rule_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_rule_version: offer_rule_version;
  offer_rule_version_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_campaign: subscriber_campaign;
  subscriber_campaign_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_aggregate = {
  __typename: 'subscriber_campaign_offer_rule_aggregate';
  aggregate?: Maybe<subscriber_campaign_offer_rule_aggregate_fields>;
  nodes: Array<subscriber_campaign_offer_rule>;
};

export type subscriber_campaign_offer_rule_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_campaign_offer_rule_aggregate_bool_exp_count>;
};

export type subscriber_campaign_offer_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_aggregate_fields = {
  __typename: 'subscriber_campaign_offer_rule_aggregate_fields';
  avg?: Maybe<subscriber_campaign_offer_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_offer_rule_max_fields>;
  min?: Maybe<subscriber_campaign_offer_rule_min_fields>;
  stddev?: Maybe<subscriber_campaign_offer_rule_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_offer_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_offer_rule_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_offer_rule_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_offer_rule_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_offer_rule_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_offer_rule_variance_fields>;
};


/** aggregate fields of "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_offer_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_offer_rule_max_order_by>;
  min?: InputMaybe<subscriber_campaign_offer_rule_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_offer_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_offer_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_offer_rule_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_offer_rule_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_offer_rule_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_offer_rule_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_offer_rule_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_arr_rel_insert_input = {
  data: Array<subscriber_campaign_offer_rule_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_offer_rule_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_offer_rule_avg_fields = {
  __typename: 'subscriber_campaign_offer_rule_avg_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_avg_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_offer_rule". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_offer_rule_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_offer_rule_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_offer_rule_bool_exp>>;
  offer_rule?: InputMaybe<offer_rule_bool_exp>;
  offer_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaign_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_offer_rule" */
export enum subscriber_campaign_offer_rule_constraint {
  /** unique or primary key constraint on columns "subscriber_campaign_id", "offer_rule_id" */
  subscriber_campaign_offer_rule_pkey = 'subscriber_campaign_offer_rule_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_inc_input = {
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_insert_input = {
  offer_rule?: InputMaybe<offer_rule_obj_rel_insert_input>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_obj_rel_insert_input>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_offer_rule_max_fields = {
  __typename: 'subscriber_campaign_offer_rule_max_fields';
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_max_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_offer_rule_min_fields = {
  __typename: 'subscriber_campaign_offer_rule_min_fields';
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_min_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_mutation_response = {
  __typename: 'subscriber_campaign_offer_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_offer_rule>;
};

/** on_conflict condition type for table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_on_conflict = {
  constraint: subscriber_campaign_offer_rule_constraint;
  update_columns?: Array<subscriber_campaign_offer_rule_update_column>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_offer_rule". */
export type subscriber_campaign_offer_rule_order_by = {
  offer_rule?: InputMaybe<offer_rule_order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_offer_rule */
export type subscriber_campaign_offer_rule_pk_columns_input = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_campaign_offer_rule" */
export enum subscriber_campaign_offer_rule_select_column {
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

/** input type for updating data in table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_set_input = {
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_campaign_offer_rule_stddev_fields = {
  __typename: 'subscriber_campaign_offer_rule_stddev_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_stddev_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_offer_rule_stddev_pop_fields = {
  __typename: 'subscriber_campaign_offer_rule_stddev_pop_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_stddev_pop_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_offer_rule_stddev_samp_fields = {
  __typename: 'subscriber_campaign_offer_rule_stddev_samp_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_stddev_samp_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_offer_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_offer_rule_stream_cursor_value_input = {
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_offer_rule_sum_fields = {
  __typename: 'subscriber_campaign_offer_rule_sum_fields';
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_sum_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign_offer_rule" */
export enum subscriber_campaign_offer_rule_update_column {
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

export type subscriber_campaign_offer_rule_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_offer_rule_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_offer_rule_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_offer_rule_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_offer_rule_var_pop_fields = {
  __typename: 'subscriber_campaign_offer_rule_var_pop_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_var_pop_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_offer_rule_var_samp_fields = {
  __typename: 'subscriber_campaign_offer_rule_var_samp_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_var_samp_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_offer_rule_variance_fields = {
  __typename: 'subscriber_campaign_offer_rule_variance_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign_offer_rule" */
export type subscriber_campaign_offer_rule_variance_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_campaign_offer" */
export enum subscriber_campaign_offer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_campaign_offer_stddev_fields = {
  __typename: 'subscriber_campaign_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_offer_stddev_pop_fields = {
  __typename: 'subscriber_campaign_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_offer_stddev_samp_fields = {
  __typename: 'subscriber_campaign_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_offer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_offer_sum_fields = {
  __typename: 'subscriber_campaign_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign_offer" */
export enum subscriber_campaign_offer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_campaign_offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_offer_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_offer_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_offer_var_pop_fields = {
  __typename: 'subscriber_campaign_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_offer_var_samp_fields = {
  __typename: 'subscriber_campaign_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_offer_variance_fields = {
  __typename: 'subscriber_campaign_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign_offer" */
export type subscriber_campaign_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "subscriber_campaign" */
export type subscriber_campaign_on_conflict = {
  constraint: subscriber_campaign_constraint;
  update_columns?: Array<subscriber_campaign_update_column>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign". */
export type subscriber_campaign_order_by = {
  campaign?: InputMaybe<campaign_order_by>;
  campaign_id?: InputMaybe<order_by>;
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  payload?: InputMaybe<order_by>;
  present_after?: InputMaybe<order_by>;
  signed?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_campaign_offer_rules_aggregate?: InputMaybe<subscriber_campaign_offer_rule_aggregate_order_by>;
  subscriber_campaign_offers_aggregate?: InputMaybe<subscriber_campaign_offer_aggregate_order_by>;
  subscriber_campaign_overrides_aggregate?: InputMaybe<subscriber_campaign_override_aggregate_order_by>;
  subscriber_campaign_segments_aggregate?: InputMaybe<subscriber_campaign_segment_aggregate_order_by>;
  subscriber_campaign_views_aggregate?: InputMaybe<subscriber_campaign_view_aggregate_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  triggered_at?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_campaign_override" */
export type subscriber_campaign_override = {
  __typename: 'subscriber_campaign_override';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  reason: subscriber_flow_override_reason_enum;
  /** An object relationship */
  subscriber_campaign: subscriber_campaign;
  subscriber_campaign_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_campaign_override" */
export type subscriber_campaign_override_aggregate = {
  __typename: 'subscriber_campaign_override_aggregate';
  aggregate?: Maybe<subscriber_campaign_override_aggregate_fields>;
  nodes: Array<subscriber_campaign_override>;
};

export type subscriber_campaign_override_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_campaign_override_aggregate_bool_exp_count>;
};

export type subscriber_campaign_override_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_override_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign_override" */
export type subscriber_campaign_override_aggregate_fields = {
  __typename: 'subscriber_campaign_override_aggregate_fields';
  avg?: Maybe<subscriber_campaign_override_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_override_max_fields>;
  min?: Maybe<subscriber_campaign_override_min_fields>;
  stddev?: Maybe<subscriber_campaign_override_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_override_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_override_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_override_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_override_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_override_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_override_variance_fields>;
};


/** aggregate fields of "subscriber_campaign_override" */
export type subscriber_campaign_override_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign_override" */
export type subscriber_campaign_override_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_override_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_override_max_order_by>;
  min?: InputMaybe<subscriber_campaign_override_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_override_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_override_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_override_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_override_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_override_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_override_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_override_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_campaign_override" */
export type subscriber_campaign_override_arr_rel_insert_input = {
  data: Array<subscriber_campaign_override_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_override_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_override_avg_fields = {
  __typename: 'subscriber_campaign_override_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_override". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_override_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_override_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_override_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_override_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum_comparison_exp>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaign_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_override" */
export enum subscriber_campaign_override_constraint {
  /** unique or primary key constraint on columns "offer_id", "subscriber_campaign_id" */
  subscriber_campaign_override_pkey = 'subscriber_campaign_override_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_campaign_override" */
export type subscriber_campaign_override_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign_override" */
export type subscriber_campaign_override_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_obj_rel_insert_input>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_override_max_fields = {
  __typename: 'subscriber_campaign_override_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_override_min_fields = {
  __typename: 'subscriber_campaign_override_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign_override" */
export type subscriber_campaign_override_mutation_response = {
  __typename: 'subscriber_campaign_override_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_override>;
};

/** on_conflict condition type for table "subscriber_campaign_override" */
export type subscriber_campaign_override_on_conflict = {
  constraint: subscriber_campaign_override_constraint;
  update_columns?: Array<subscriber_campaign_override_update_column>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_override". */
export type subscriber_campaign_override_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  reason?: InputMaybe<order_by>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_override */
export type subscriber_campaign_override_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_campaign_override" */
export enum subscriber_campaign_override_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

/** input type for updating data in table "subscriber_campaign_override" */
export type subscriber_campaign_override_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_campaign_override_stddev_fields = {
  __typename: 'subscriber_campaign_override_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_override_stddev_pop_fields = {
  __typename: 'subscriber_campaign_override_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_override_stddev_samp_fields = {
  __typename: 'subscriber_campaign_override_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign_override" */
export type subscriber_campaign_override_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_override_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_override_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_override_sum_fields = {
  __typename: 'subscriber_campaign_override_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign_override" */
export enum subscriber_campaign_override_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

export type subscriber_campaign_override_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_override_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_override_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_override_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_override_var_pop_fields = {
  __typename: 'subscriber_campaign_override_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_override_var_samp_fields = {
  __typename: 'subscriber_campaign_override_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_override_variance_fields = {
  __typename: 'subscriber_campaign_override_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign_override" */
export type subscriber_campaign_override_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign */
export type subscriber_campaign_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscriber_campaign_prepend_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "subscriber_campaign_segment" */
export type subscriber_campaign_segment = {
  __typename: 'subscriber_campaign_segment';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  segment: segment;
  segment_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_campaign: subscriber_campaign;
  subscriber_campaign_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_campaign_segment" */
export type subscriber_campaign_segment_aggregate = {
  __typename: 'subscriber_campaign_segment_aggregate';
  aggregate?: Maybe<subscriber_campaign_segment_aggregate_fields>;
  nodes: Array<subscriber_campaign_segment>;
};

export type subscriber_campaign_segment_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_campaign_segment_aggregate_bool_exp_count>;
};

export type subscriber_campaign_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign_segment" */
export type subscriber_campaign_segment_aggregate_fields = {
  __typename: 'subscriber_campaign_segment_aggregate_fields';
  avg?: Maybe<subscriber_campaign_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_segment_max_fields>;
  min?: Maybe<subscriber_campaign_segment_min_fields>;
  stddev?: Maybe<subscriber_campaign_segment_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_segment_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_segment_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_segment_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_segment_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_segment_variance_fields>;
};


/** aggregate fields of "subscriber_campaign_segment" */
export type subscriber_campaign_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_segment_max_order_by>;
  min?: InputMaybe<subscriber_campaign_segment_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_segment_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_segment_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_segment_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_segment_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_arr_rel_insert_input = {
  data: Array<subscriber_campaign_segment_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_segment_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_segment_avg_fields = {
  __typename: 'subscriber_campaign_segment_avg_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_avg_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_segment". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_segment_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_segment_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_segment_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_segment_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaign_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_segment" */
export enum subscriber_campaign_segment_constraint {
  /** unique or primary key constraint on columns "subscriber_campaign_id", "segment_id" */
  subscriber_campaign_segment_pkey = 'subscriber_campaign_segment_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_inc_input = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_obj_rel_insert_input>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_segment_max_fields = {
  __typename: 'subscriber_campaign_segment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_max_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_segment_min_fields = {
  __typename: 'subscriber_campaign_segment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_min_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_mutation_response = {
  __typename: 'subscriber_campaign_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_segment>;
};

/** on_conflict condition type for table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_on_conflict = {
  constraint: subscriber_campaign_segment_constraint;
  update_columns?: Array<subscriber_campaign_segment_update_column>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_segment". */
export type subscriber_campaign_segment_order_by = {
  created_at?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_segment */
export type subscriber_campaign_segment_pk_columns_input = {
  segment_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_campaign_segment" */
export enum subscriber_campaign_segment_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

/** input type for updating data in table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_campaign_segment_stddev_fields = {
  __typename: 'subscriber_campaign_segment_stddev_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_stddev_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_segment_stddev_pop_fields = {
  __typename: 'subscriber_campaign_segment_stddev_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_stddev_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_segment_stddev_samp_fields = {
  __typename: 'subscriber_campaign_segment_stddev_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_stddev_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_segment_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_segment_sum_fields = {
  __typename: 'subscriber_campaign_segment_sum_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_sum_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign_segment" */
export enum subscriber_campaign_segment_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id'
}

export type subscriber_campaign_segment_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_segment_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_segment_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_segment_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_segment_var_pop_fields = {
  __typename: 'subscriber_campaign_segment_var_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_var_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_segment_var_samp_fields = {
  __typename: 'subscriber_campaign_segment_var_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_var_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_segment_variance_fields = {
  __typename: 'subscriber_campaign_segment_variance_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign_segment" */
export type subscriber_campaign_segment_variance_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_campaign" */
export enum subscriber_campaign_select_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  payload = 'payload',
  /** column name */
  present_after = 'present_after',
  /** column name */
  signed = 'signed',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  token = 'token',
  /** column name */
  triggered_at = 'triggered_at',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_agent = 'user_agent',
  /** column name */
  version = 'version'
}

/** select "subscriber_campaign_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriber_campaign" */
export enum subscriber_campaign_select_column_subscriber_campaign_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  signed = 'signed'
}

/** select "subscriber_campaign_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriber_campaign" */
export enum subscriber_campaign_select_column_subscriber_campaign_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  signed = 'signed'
}

/** input type for updating data in table "subscriber_campaign" */
export type subscriber_campaign_set_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  present_after?: InputMaybe<Scalars['timestamptz']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<subscriber_campaign_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  triggered_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "subscriber_campaign_status" */
export type subscriber_campaign_status = {
  __typename: 'subscriber_campaign_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "subscriber_campaign_status" */
export type subscriber_campaign_status_aggregate = {
  __typename: 'subscriber_campaign_status_aggregate';
  aggregate?: Maybe<subscriber_campaign_status_aggregate_fields>;
  nodes: Array<subscriber_campaign_status>;
};

/** aggregate fields of "subscriber_campaign_status" */
export type subscriber_campaign_status_aggregate_fields = {
  __typename: 'subscriber_campaign_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_status_max_fields>;
  min?: Maybe<subscriber_campaign_status_min_fields>;
};


/** aggregate fields of "subscriber_campaign_status" */
export type subscriber_campaign_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_status". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_status_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_status_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_status_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_status" */
export enum subscriber_campaign_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_campaign_status_pkey = 'subscriber_campaign_status_pkey'
}

export enum subscriber_campaign_status_enum {
  converted = 'converted',
  created = 'created',
  declined = 'declined',
  delayed = 'delayed',
  expired = 'expired',
  triggered = 'triggered'
}

/** Boolean expression to compare columns of type "subscriber_campaign_status_enum". All fields are combined with logical 'AND'. */
export type subscriber_campaign_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_campaign_status_enum>;
  _in?: InputMaybe<Array<subscriber_campaign_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_campaign_status_enum>;
  _nin?: InputMaybe<Array<subscriber_campaign_status_enum>>;
};

/** input type for inserting data into table "subscriber_campaign_status" */
export type subscriber_campaign_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_status_max_fields = {
  __typename: 'subscriber_campaign_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_campaign_status_min_fields = {
  __typename: 'subscriber_campaign_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_campaign_status" */
export type subscriber_campaign_status_mutation_response = {
  __typename: 'subscriber_campaign_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_status>;
};

/** on_conflict condition type for table "subscriber_campaign_status" */
export type subscriber_campaign_status_on_conflict = {
  constraint: subscriber_campaign_status_constraint;
  update_columns?: Array<subscriber_campaign_status_update_column>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_status". */
export type subscriber_campaign_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_status */
export type subscriber_campaign_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_campaign_status" */
export enum subscriber_campaign_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_campaign_status" */
export type subscriber_campaign_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_campaign_status" */
export type subscriber_campaign_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_campaign_status" */
export enum subscriber_campaign_status_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_campaign_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscriber_campaign_stddev_fields = {
  __typename: 'subscriber_campaign_stddev_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign" */
export type subscriber_campaign_stddev_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_stddev_pop_fields = {
  __typename: 'subscriber_campaign_stddev_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign" */
export type subscriber_campaign_stddev_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_stddev_samp_fields = {
  __typename: 'subscriber_campaign_stddev_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign" */
export type subscriber_campaign_stddev_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign" */
export type subscriber_campaign_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_stream_cursor_value_input = {
  campaign_id?: InputMaybe<Scalars['Int']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  present_after?: InputMaybe<Scalars['timestamptz']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<subscriber_campaign_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  triggered_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_sum_fields = {
  __typename: 'subscriber_campaign_sum_fields';
  campaign_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign" */
export type subscriber_campaign_sum_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign" */
export enum subscriber_campaign_update_column {
  /** column name */
  campaign_id = 'campaign_id',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  payload = 'payload',
  /** column name */
  present_after = 'present_after',
  /** column name */
  signed = 'signed',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  token = 'token',
  /** column name */
  triggered_at = 'triggered_at',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_agent = 'user_agent',
  /** column name */
  version = 'version'
}

export type subscriber_campaign_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscriber_campaign_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscriber_campaign_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscriber_campaign_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscriber_campaign_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscriber_campaign_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_var_pop_fields = {
  __typename: 'subscriber_campaign_var_pop_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign" */
export type subscriber_campaign_var_pop_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_var_samp_fields = {
  __typename: 'subscriber_campaign_var_samp_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign" */
export type subscriber_campaign_var_samp_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_variance_fields = {
  __typename: 'subscriber_campaign_variance_fields';
  campaign_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign" */
export type subscriber_campaign_variance_order_by = {
  campaign_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_campaign_view" */
export type subscriber_campaign_view = {
  __typename: 'subscriber_campaign_view';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  status: subscriber_campaign_view_status_enum;
  /** An object relationship */
  subscriber_campaign: subscriber_campaign;
  subscriber_campaign_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_campaign_offer?: Maybe<subscriber_campaign_offer>;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "subscriber_campaign_view" */
export type subscriber_campaign_view_aggregate = {
  __typename: 'subscriber_campaign_view_aggregate';
  aggregate?: Maybe<subscriber_campaign_view_aggregate_fields>;
  nodes: Array<subscriber_campaign_view>;
};

export type subscriber_campaign_view_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_campaign_view_aggregate_bool_exp_count>;
};

export type subscriber_campaign_view_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_campaign_view_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_campaign_view" */
export type subscriber_campaign_view_aggregate_fields = {
  __typename: 'subscriber_campaign_view_aggregate_fields';
  avg?: Maybe<subscriber_campaign_view_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_view_max_fields>;
  min?: Maybe<subscriber_campaign_view_min_fields>;
  stddev?: Maybe<subscriber_campaign_view_stddev_fields>;
  stddev_pop?: Maybe<subscriber_campaign_view_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_campaign_view_stddev_samp_fields>;
  sum?: Maybe<subscriber_campaign_view_sum_fields>;
  var_pop?: Maybe<subscriber_campaign_view_var_pop_fields>;
  var_samp?: Maybe<subscriber_campaign_view_var_samp_fields>;
  variance?: Maybe<subscriber_campaign_view_variance_fields>;
};


/** aggregate fields of "subscriber_campaign_view" */
export type subscriber_campaign_view_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_campaign_view" */
export type subscriber_campaign_view_aggregate_order_by = {
  avg?: InputMaybe<subscriber_campaign_view_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_campaign_view_max_order_by>;
  min?: InputMaybe<subscriber_campaign_view_min_order_by>;
  stddev?: InputMaybe<subscriber_campaign_view_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_campaign_view_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_campaign_view_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_campaign_view_sum_order_by>;
  var_pop?: InputMaybe<subscriber_campaign_view_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_campaign_view_var_samp_order_by>;
  variance?: InputMaybe<subscriber_campaign_view_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_campaign_view" */
export type subscriber_campaign_view_arr_rel_insert_input = {
  data: Array<subscriber_campaign_view_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_campaign_view_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_campaign_view_avg_fields = {
  __typename: 'subscriber_campaign_view_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_avg_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_view". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_view_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_view_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_view_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_view_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  status?: InputMaybe<subscriber_campaign_view_status_enum_comparison_exp>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaign_id?: InputMaybe<Int_comparison_exp>;
  subscriber_campaign_offer?: InputMaybe<subscriber_campaign_offer_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_view" */
export enum subscriber_campaign_view_constraint {
  /** unique or primary key constraint on columns "id" */
  subscriber_campaign_session_pkey = 'subscriber_campaign_session_pkey',
  /** unique or primary key constraint on columns "token" */
  subscriber_campaign_session_token_key = 'subscriber_campaign_session_token_key'
}

/** input type for incrementing numeric columns in table "subscriber_campaign_view" */
export type subscriber_campaign_view_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_campaign_view" */
export type subscriber_campaign_view_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<subscriber_campaign_view_status_enum>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_obj_rel_insert_input>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_campaign_offer?: InputMaybe<subscriber_campaign_offer_obj_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_view_max_fields = {
  __typename: 'subscriber_campaign_view_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_max_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_campaign_view_min_fields = {
  __typename: 'subscriber_campaign_view_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_min_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_campaign_view" */
export type subscriber_campaign_view_mutation_response = {
  __typename: 'subscriber_campaign_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_view>;
};

/** on_conflict condition type for table "subscriber_campaign_view" */
export type subscriber_campaign_view_on_conflict = {
  constraint: subscriber_campaign_view_constraint;
  update_columns?: Array<subscriber_campaign_view_update_column>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_view". */
export type subscriber_campaign_view_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber_campaign?: InputMaybe<subscriber_campaign_order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
  subscriber_campaign_offer?: InputMaybe<subscriber_campaign_offer_order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_view */
export type subscriber_campaign_view_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_campaign_view" */
export enum subscriber_campaign_view_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_campaign_view" */
export type subscriber_campaign_view_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<subscriber_campaign_view_status_enum>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status = {
  __typename: 'subscriber_campaign_view_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_aggregate = {
  __typename: 'subscriber_campaign_view_status_aggregate';
  aggregate?: Maybe<subscriber_campaign_view_status_aggregate_fields>;
  nodes: Array<subscriber_campaign_view_status>;
};

/** aggregate fields of "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_aggregate_fields = {
  __typename: 'subscriber_campaign_view_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_campaign_view_status_max_fields>;
  min?: Maybe<subscriber_campaign_view_status_min_fields>;
};


/** aggregate fields of "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_campaign_view_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_campaign_view_status". All fields are combined with a logical 'AND'. */
export type subscriber_campaign_view_status_bool_exp = {
  _and?: InputMaybe<Array<subscriber_campaign_view_status_bool_exp>>;
  _not?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
  _or?: InputMaybe<Array<subscriber_campaign_view_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_campaign_view_status" */
export enum subscriber_campaign_view_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_campaign_session_status_pkey = 'subscriber_campaign_session_status_pkey'
}

export enum subscriber_campaign_view_status_enum {
  accepted = 'accepted',
  declined = 'declined',
  dismissed = 'dismissed',
  shown = 'shown'
}

/** Boolean expression to compare columns of type "subscriber_campaign_view_status_enum". All fields are combined with logical 'AND'. */
export type subscriber_campaign_view_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_campaign_view_status_enum>;
  _in?: InputMaybe<Array<subscriber_campaign_view_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_campaign_view_status_enum>;
  _nin?: InputMaybe<Array<subscriber_campaign_view_status_enum>>;
};

/** input type for inserting data into table "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_campaign_view_status_max_fields = {
  __typename: 'subscriber_campaign_view_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_campaign_view_status_min_fields = {
  __typename: 'subscriber_campaign_view_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_mutation_response = {
  __typename: 'subscriber_campaign_view_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_campaign_view_status>;
};

/** on_conflict condition type for table "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_on_conflict = {
  constraint: subscriber_campaign_view_status_constraint;
  update_columns?: Array<subscriber_campaign_view_status_update_column>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_campaign_view_status". */
export type subscriber_campaign_view_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_campaign_view_status */
export type subscriber_campaign_view_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_campaign_view_status" */
export enum subscriber_campaign_view_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_campaign_view_status" */
export type subscriber_campaign_view_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_view_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_view_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_campaign_view_status" */
export enum subscriber_campaign_view_status_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_campaign_view_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_view_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_view_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscriber_campaign_view_stddev_fields = {
  __typename: 'subscriber_campaign_view_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_stddev_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_campaign_view_stddev_pop_fields = {
  __typename: 'subscriber_campaign_view_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_campaign_view_stddev_samp_fields = {
  __typename: 'subscriber_campaign_view_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_campaign_view" */
export type subscriber_campaign_view_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_campaign_view_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_campaign_view_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<subscriber_campaign_view_status_enum>;
  subscriber_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_campaign_view_sum_fields = {
  __typename: 'subscriber_campaign_view_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_sum_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_campaign_view" */
export enum subscriber_campaign_view_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_campaign_id = 'subscriber_campaign_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_campaign_view_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_campaign_view_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_campaign_view_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_campaign_view_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_campaign_view_var_pop_fields = {
  __typename: 'subscriber_campaign_view_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_campaign_view_var_samp_fields = {
  __typename: 'subscriber_campaign_view_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_campaign_view_variance_fields = {
  __typename: 'subscriber_campaign_view_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_campaign_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_campaign_view" */
export type subscriber_campaign_view_variance_order_by = {
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_campaign_id?: InputMaybe<order_by>;
};

/** unique or primary key constraints on table "subscriber" */
export enum subscriber_constraint {
  /** unique or primary key constraint on columns "account_id", "platform_id" */
  subscriber_account_id_platform_id_key = 'subscriber_account_id_platform_id_key',
  /** unique or primary key constraint on columns "id" */
  subscriber_pkey = 'subscriber_pkey',
  /** unique or primary key constraint on columns "token" */
  subscriber_token_key = 'subscriber_token_key'
}

/** columns and relationships of "subscriber_created_at" */
export type subscriber_created_at = {
  __typename: 'subscriber_created_at';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscriber_created_at" */
export type subscriber_created_at_aggregate = {
  __typename: 'subscriber_created_at_aggregate';
  aggregate?: Maybe<subscriber_created_at_aggregate_fields>;
  nodes: Array<subscriber_created_at>;
};

/** aggregate fields of "subscriber_created_at" */
export type subscriber_created_at_aggregate_fields = {
  __typename: 'subscriber_created_at_aggregate_fields';
  avg?: Maybe<subscriber_created_at_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_created_at_max_fields>;
  min?: Maybe<subscriber_created_at_min_fields>;
  stddev?: Maybe<subscriber_created_at_stddev_fields>;
  stddev_pop?: Maybe<subscriber_created_at_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_created_at_stddev_samp_fields>;
  sum?: Maybe<subscriber_created_at_sum_fields>;
  var_pop?: Maybe<subscriber_created_at_var_pop_fields>;
  var_samp?: Maybe<subscriber_created_at_var_samp_fields>;
  variance?: Maybe<subscriber_created_at_variance_fields>;
};


/** aggregate fields of "subscriber_created_at" */
export type subscriber_created_at_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_created_at_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_created_at_avg_fields = {
  __typename: 'subscriber_created_at_avg_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_created_at". All fields are combined with a logical 'AND'. */
export type subscriber_created_at_bool_exp = {
  _and?: InputMaybe<Array<subscriber_created_at_bool_exp>>;
  _not?: InputMaybe<subscriber_created_at_bool_exp>;
  _or?: InputMaybe<Array<subscriber_created_at_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscriber_created_at" */
export type subscriber_created_at_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_created_at_max_fields = {
  __typename: 'subscriber_created_at_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_created_at_min_fields = {
  __typename: 'subscriber_created_at_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscriber_created_at" */
export type subscriber_created_at_obj_rel_insert_input = {
  data: subscriber_created_at_insert_input;
};

/** Ordering options when selecting data from "subscriber_created_at". */
export type subscriber_created_at_order_by = {
  created_at?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_created_at" */
export enum subscriber_created_at_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** aggregate stddev on columns */
export type subscriber_created_at_stddev_fields = {
  __typename: 'subscriber_created_at_stddev_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_created_at_stddev_pop_fields = {
  __typename: 'subscriber_created_at_stddev_pop_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_created_at_stddev_samp_fields = {
  __typename: 'subscriber_created_at_stddev_samp_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_created_at" */
export type subscriber_created_at_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_created_at_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_created_at_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_created_at_sum_fields = {
  __typename: 'subscriber_created_at_sum_fields';
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_created_at_var_pop_fields = {
  __typename: 'subscriber_created_at_var_pop_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_created_at_var_samp_fields = {
  __typename: 'subscriber_created_at_var_samp_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_created_at_variance_fields = {
  __typename: 'subscriber_created_at_variance_fields';
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber_days_since_saved" */
export type subscriber_days_since_saved = {
  __typename: 'subscriber_days_since_saved';
  days_since_saved?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscriber_days_since_saved" */
export type subscriber_days_since_saved_aggregate = {
  __typename: 'subscriber_days_since_saved_aggregate';
  aggregate?: Maybe<subscriber_days_since_saved_aggregate_fields>;
  nodes: Array<subscriber_days_since_saved>;
};

/** aggregate fields of "subscriber_days_since_saved" */
export type subscriber_days_since_saved_aggregate_fields = {
  __typename: 'subscriber_days_since_saved_aggregate_fields';
  avg?: Maybe<subscriber_days_since_saved_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_days_since_saved_max_fields>;
  min?: Maybe<subscriber_days_since_saved_min_fields>;
  stddev?: Maybe<subscriber_days_since_saved_stddev_fields>;
  stddev_pop?: Maybe<subscriber_days_since_saved_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_days_since_saved_stddev_samp_fields>;
  sum?: Maybe<subscriber_days_since_saved_sum_fields>;
  var_pop?: Maybe<subscriber_days_since_saved_var_pop_fields>;
  var_samp?: Maybe<subscriber_days_since_saved_var_samp_fields>;
  variance?: Maybe<subscriber_days_since_saved_variance_fields>;
};


/** aggregate fields of "subscriber_days_since_saved" */
export type subscriber_days_since_saved_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_days_since_saved_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_days_since_saved_avg_fields = {
  __typename: 'subscriber_days_since_saved_avg_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_days_since_saved". All fields are combined with a logical 'AND'. */
export type subscriber_days_since_saved_bool_exp = {
  _and?: InputMaybe<Array<subscriber_days_since_saved_bool_exp>>;
  _not?: InputMaybe<subscriber_days_since_saved_bool_exp>;
  _or?: InputMaybe<Array<subscriber_days_since_saved_bool_exp>>;
  days_since_saved?: InputMaybe<Int_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscriber_days_since_saved" */
export type subscriber_days_since_saved_insert_input = {
  days_since_saved?: InputMaybe<Scalars['Int']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_days_since_saved_max_fields = {
  __typename: 'subscriber_days_since_saved_max_fields';
  days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_days_since_saved_min_fields = {
  __typename: 'subscriber_days_since_saved_min_fields';
  days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscriber_days_since_saved" */
export type subscriber_days_since_saved_obj_rel_insert_input = {
  data: subscriber_days_since_saved_insert_input;
};

/** Ordering options when selecting data from "subscriber_days_since_saved". */
export type subscriber_days_since_saved_order_by = {
  days_since_saved?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_days_since_saved" */
export enum subscriber_days_since_saved_select_column {
  /** column name */
  days_since_saved = 'days_since_saved',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** aggregate stddev on columns */
export type subscriber_days_since_saved_stddev_fields = {
  __typename: 'subscriber_days_since_saved_stddev_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_days_since_saved_stddev_pop_fields = {
  __typename: 'subscriber_days_since_saved_stddev_pop_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_days_since_saved_stddev_samp_fields = {
  __typename: 'subscriber_days_since_saved_stddev_samp_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_days_since_saved" */
export type subscriber_days_since_saved_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_days_since_saved_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_days_since_saved_stream_cursor_value_input = {
  days_since_saved?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_days_since_saved_sum_fields = {
  __typename: 'subscriber_days_since_saved_sum_fields';
  days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_days_since_saved_var_pop_fields = {
  __typename: 'subscriber_days_since_saved_var_pop_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_days_since_saved_var_samp_fields = {
  __typename: 'subscriber_days_since_saved_var_samp_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_days_since_saved_variance_fields = {
  __typename: 'subscriber_days_since_saved_variance_fields';
  days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber_flow" */
export type subscriber_flow = {
  __typename: 'subscriber_flow';
  automatically_deflected: Scalars['Boolean']['output'];
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  eligibility_message?: Maybe<eligibility_message>;
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  flow: flow;
  flow_id: Scalars['Int']['output'];
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  language: language_enum;
  /** An object relationship */
  offer?: Maybe<offer>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  payload?: Maybe<Scalars['jsonb']['output']>;
  reroute_allowed: Scalars['Boolean']['output'];
  /** An object relationship */
  rerouted_from_subscriber_flow?: Maybe<subscriber_flow>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  segment_values?: Maybe<Scalars['jsonb']['output']>;
  signed: Scalars['Boolean']['output'];
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  status: subscriber_flow_status_enum;
  /** An object relationship */
  subscriber: subscriber;
  /** An array relationship */
  subscriber_acknowledgements: Array<subscriber_acknowledgement>;
  /** An aggregate relationship */
  subscriber_acknowledgements_aggregate: subscriber_acknowledgement_aggregate;
  /** An array relationship */
  subscriber_flow_deflections: Array<subscriber_flow_deflection>;
  /** An aggregate relationship */
  subscriber_flow_deflections_aggregate: subscriber_flow_deflection_aggregate;
  /** An array relationship */
  subscriber_flow_flow_steps: Array<subscriber_flow_flow_step>;
  /** An aggregate relationship */
  subscriber_flow_flow_steps_aggregate: subscriber_flow_flow_step_aggregate;
  /** An array relationship */
  subscriber_flow_offer_groups: Array<subscriber_flow_offer_group>;
  /** An aggregate relationship */
  subscriber_flow_offer_groups_aggregate: subscriber_flow_offer_group_aggregate;
  /** An array relationship */
  subscriber_flow_offer_rules: Array<subscriber_flow_offer_rule>;
  /** An aggregate relationship */
  subscriber_flow_offer_rules_aggregate: subscriber_flow_offer_rule_aggregate;
  /** An array relationship */
  subscriber_flow_offers: Array<subscriber_flow_offer>;
  /** An aggregate relationship */
  subscriber_flow_offers_aggregate: subscriber_flow_offer_aggregate;
  /** An array relationship */
  subscriber_flow_overrides: Array<subscriber_flow_override>;
  /** An aggregate relationship */
  subscriber_flow_overrides_aggregate: subscriber_flow_override_aggregate;
  /** An array relationship */
  subscriber_flow_question_answers: Array<subscriber_flow_question_answer>;
  /** An aggregate relationship */
  subscriber_flow_question_answers_aggregate: subscriber_flow_question_answer_aggregate;
  /** An array relationship */
  subscriber_flow_segments: Array<subscriber_flow_segment>;
  /** An aggregate relationship */
  subscriber_flow_segments_aggregate: subscriber_flow_segment_aggregate;
  /** An object relationship */
  subscriber_flow_status: subscriber_flow_status;
  /** An object relationship */
  subscriber_flow_url?: Maybe<subscriber_flow_url>;
  subscriber_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  trial: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowpayloadArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsegment_valuesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_acknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_acknowledgements_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_deflectionsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_deflections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_flow_stepsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_flow_steps_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offer_groupsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offer_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offer_rulesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offer_rules_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_overridesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_overrides_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_question_answersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_question_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_segmentsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


/** columns and relationships of "subscriber_flow" */
export type subscriber_flowsubscriber_flow_segments_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};

/** aggregated selection of "subscriber_flow" */
export type subscriber_flow_aggregate = {
  __typename: 'subscriber_flow_aggregate';
  aggregate?: Maybe<subscriber_flow_aggregate_fields>;
  nodes: Array<subscriber_flow>;
};

export type subscriber_flow_aggregate_bool_exp = {
  bool_and?: InputMaybe<subscriber_flow_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<subscriber_flow_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<subscriber_flow_aggregate_bool_exp_count>;
};

export type subscriber_flow_aggregate_bool_exp_bool_and = {
  arguments: subscriber_flow_select_column_subscriber_flow_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_flow_aggregate_bool_exp_bool_or = {
  arguments: subscriber_flow_select_column_subscriber_flow_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_flow_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow" */
export type subscriber_flow_aggregate_fields = {
  __typename: 'subscriber_flow_aggregate_fields';
  avg?: Maybe<subscriber_flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_max_fields>;
  min?: Maybe<subscriber_flow_min_fields>;
  stddev?: Maybe<subscriber_flow_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_sum_fields>;
  var_pop?: Maybe<subscriber_flow_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_var_samp_fields>;
  variance?: Maybe<subscriber_flow_variance_fields>;
};


/** aggregate fields of "subscriber_flow" */
export type subscriber_flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow" */
export type subscriber_flow_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_max_order_by>;
  min?: InputMaybe<subscriber_flow_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscriber_flow_append_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  segment_values?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriber_flow" */
export type subscriber_flow_arr_rel_insert_input = {
  data: Array<subscriber_flow_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_avg_fields = {
  __typename: 'subscriber_flow_avg_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow" */
export type subscriber_flow_avg_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow". All fields are combined with a logical 'AND'. */
export type subscriber_flow_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_bool_exp>>;
  automatically_deflected?: InputMaybe<Boolean_comparison_exp>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  eligibility_message?: InputMaybe<eligibility_message_bool_exp>;
  eligibility_message_id?: InputMaybe<Int_comparison_exp>;
  flow?: InputMaybe<flow_bool_exp>;
  flow_id?: InputMaybe<Int_comparison_exp>;
  flow_test_id?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  language?: InputMaybe<language_enum_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  payload?: InputMaybe<jsonb_comparison_exp>;
  reroute_allowed?: InputMaybe<Boolean_comparison_exp>;
  rerouted_from_subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  rerouted_from_subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  segment_values?: InputMaybe<jsonb_comparison_exp>;
  signed?: InputMaybe<Boolean_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  status?: InputMaybe<subscriber_flow_status_enum_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_bool_exp>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_bool_exp>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_bool_exp>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_bool_exp>;
  subscriber_flow_flow_steps?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  subscriber_flow_flow_steps_aggregate?: InputMaybe<subscriber_flow_flow_step_aggregate_bool_exp>;
  subscriber_flow_offer_groups?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
  subscriber_flow_offer_groups_aggregate?: InputMaybe<subscriber_flow_offer_group_aggregate_bool_exp>;
  subscriber_flow_offer_rules?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
  subscriber_flow_offer_rules_aggregate?: InputMaybe<subscriber_flow_offer_rule_aggregate_bool_exp>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_bool_exp>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_bool_exp>;
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_bool_exp>;
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_bool_exp>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_bool_exp>;
  subscriber_flow_segments?: InputMaybe<subscriber_flow_segment_bool_exp>;
  subscriber_flow_segments_aggregate?: InputMaybe<subscriber_flow_segment_aggregate_bool_exp>;
  subscriber_flow_status?: InputMaybe<subscriber_flow_status_bool_exp>;
  subscriber_flow_url?: InputMaybe<subscriber_flow_url_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  trial?: InputMaybe<Boolean_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  user_agent?: InputMaybe<String_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow" */
export enum subscriber_flow_constraint {
  /** unique or primary key constraint on columns "id" */
  subscriber_flow_pkey = 'subscriber_flow_pkey',
  /** unique or primary key constraint on columns "token" */
  subscriber_flow_token_key = 'subscriber_flow_token_key'
}

/** columns and relationships of "subscriber_flow_deflection" */
export type subscriber_flow_deflection = {
  __typename: 'subscriber_flow_deflection';
  /** An object relationship */
  clicked_flow_action?: Maybe<flow_action>;
  clicked_flow_action_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deflection: deflection;
  deflection_id: Scalars['Int']['output'];
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  /** An object relationship */
  subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step>;
  subscriber_flow_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "subscriber_flow_deflection" */
export type subscriber_flow_deflection_aggregate = {
  __typename: 'subscriber_flow_deflection_aggregate';
  aggregate?: Maybe<subscriber_flow_deflection_aggregate_fields>;
  nodes: Array<subscriber_flow_deflection>;
};

export type subscriber_flow_deflection_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_deflection_aggregate_bool_exp_count>;
};

export type subscriber_flow_deflection_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_deflection_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_deflection" */
export type subscriber_flow_deflection_aggregate_fields = {
  __typename: 'subscriber_flow_deflection_aggregate_fields';
  avg?: Maybe<subscriber_flow_deflection_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_deflection_max_fields>;
  min?: Maybe<subscriber_flow_deflection_min_fields>;
  stddev?: Maybe<subscriber_flow_deflection_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_deflection_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_deflection_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_deflection_sum_fields>;
  var_pop?: Maybe<subscriber_flow_deflection_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_deflection_var_samp_fields>;
  variance?: Maybe<subscriber_flow_deflection_variance_fields>;
};


/** aggregate fields of "subscriber_flow_deflection" */
export type subscriber_flow_deflection_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_deflection_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_deflection_max_order_by>;
  min?: InputMaybe<subscriber_flow_deflection_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_deflection_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_deflection_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_deflection_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_deflection_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_deflection_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_deflection_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_deflection_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_arr_rel_insert_input = {
  data: Array<subscriber_flow_deflection_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_deflection_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_deflection_avg_fields = {
  __typename: 'subscriber_flow_deflection_avg_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_avg_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_deflection". All fields are combined with a logical 'AND'. */
export type subscriber_flow_deflection_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_deflection_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_deflection_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_deflection_bool_exp>>;
  clicked_flow_action?: InputMaybe<flow_action_bool_exp>;
  clicked_flow_action_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deflection?: InputMaybe<deflection_bool_exp>;
  deflection_id?: InputMaybe<Int_comparison_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_deflection" */
export enum subscriber_flow_deflection_constraint {
  /** unique or primary key constraint on columns "subscriber_flow_id", "deflection_id" */
  subscriber_flow_deflection_pkey = 'subscriber_flow_deflection_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_inc_input = {
  clicked_flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_insert_input = {
  clicked_flow_action?: InputMaybe<flow_action_obj_rel_insert_input>;
  clicked_flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection?: InputMaybe<deflection_obj_rel_insert_input>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_deflection_max_fields = {
  __typename: 'subscriber_flow_deflection_max_fields';
  clicked_flow_action_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_max_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_deflection_min_fields = {
  __typename: 'subscriber_flow_deflection_min_fields';
  clicked_flow_action_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_min_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_mutation_response = {
  __typename: 'subscriber_flow_deflection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_deflection>;
};

/** on_conflict condition type for table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_on_conflict = {
  constraint: subscriber_flow_deflection_constraint;
  update_columns?: Array<subscriber_flow_deflection_update_column>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_deflection". */
export type subscriber_flow_deflection_order_by = {
  clicked_flow_action?: InputMaybe<flow_action_order_by>;
  clicked_flow_action_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deflection?: InputMaybe<deflection_order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_deflection */
export type subscriber_flow_deflection_pk_columns_input = {
  deflection_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_deflection" */
export enum subscriber_flow_deflection_select_column {
  /** column name */
  clicked_flow_action_id = 'clicked_flow_action_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_set_input = {
  clicked_flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_deflection_stddev_fields = {
  __typename: 'subscriber_flow_deflection_stddev_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_stddev_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_deflection_stddev_pop_fields = {
  __typename: 'subscriber_flow_deflection_stddev_pop_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_stddev_pop_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_deflection_stddev_samp_fields = {
  __typename: 'subscriber_flow_deflection_stddev_samp_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_stddev_samp_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_deflection_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_deflection_stream_cursor_value_input = {
  clicked_flow_action_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deflection_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_deflection_sum_fields = {
  __typename: 'subscriber_flow_deflection_sum_fields';
  clicked_flow_action_id?: Maybe<Scalars['Int']['output']>;
  deflection_id?: Maybe<Scalars['Int']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_sum_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_deflection" */
export enum subscriber_flow_deflection_update_column {
  /** column name */
  clicked_flow_action_id = 'clicked_flow_action_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deflection_id = 'deflection_id',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_flow_deflection_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_deflection_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_deflection_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_deflection_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_deflection_var_pop_fields = {
  __typename: 'subscriber_flow_deflection_var_pop_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_var_pop_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_deflection_var_samp_fields = {
  __typename: 'subscriber_flow_deflection_var_samp_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_var_samp_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_deflection_variance_fields = {
  __typename: 'subscriber_flow_deflection_variance_fields';
  clicked_flow_action_id?: Maybe<Scalars['Float']['output']>;
  deflection_id?: Maybe<Scalars['Float']['output']>;
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_deflection" */
export type subscriber_flow_deflection_variance_order_by = {
  clicked_flow_action_id?: InputMaybe<order_by>;
  deflection_id?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscriber_flow_delete_at_path_input = {
  payload?: InputMaybe<Array<Scalars['String']['input']>>;
  segment_values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscriber_flow_delete_elem_input = {
  payload?: InputMaybe<Scalars['Int']['input']>;
  segment_values?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscriber_flow_delete_key_input = {
  payload?: InputMaybe<Scalars['String']['input']>;
  segment_values?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "subscriber_flow_export" */
export type subscriber_flow_export = {
  __typename: 'subscriber_flow_export';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  exported_by_user?: Maybe<user>;
  exported_by_user_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriber_flow_export" */
export type subscriber_flow_export_aggregate = {
  __typename: 'subscriber_flow_export_aggregate';
  aggregate?: Maybe<subscriber_flow_export_aggregate_fields>;
  nodes: Array<subscriber_flow_export>;
};

/** aggregate fields of "subscriber_flow_export" */
export type subscriber_flow_export_aggregate_fields = {
  __typename: 'subscriber_flow_export_aggregate_fields';
  avg?: Maybe<subscriber_flow_export_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_export_max_fields>;
  min?: Maybe<subscriber_flow_export_min_fields>;
  stddev?: Maybe<subscriber_flow_export_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_export_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_export_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_export_sum_fields>;
  var_pop?: Maybe<subscriber_flow_export_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_export_var_samp_fields>;
  variance?: Maybe<subscriber_flow_export_variance_fields>;
};


/** aggregate fields of "subscriber_flow_export" */
export type subscriber_flow_export_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_export_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_export_avg_fields = {
  __typename: 'subscriber_flow_export_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_export". All fields are combined with a logical 'AND'. */
export type subscriber_flow_export_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_export_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_export_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_export_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  expires_at?: InputMaybe<timestamptz_comparison_exp>;
  exported_by_user?: InputMaybe<user_bool_exp>;
  exported_by_user_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_export" */
export enum subscriber_flow_export_constraint {
  /** unique or primary key constraint on columns "id" */
  subscriber_flow_export_pkey = 'subscriber_flow_export_pkey',
  /** unique or primary key constraint on columns "token" */
  subscriber_flow_export_token_key = 'subscriber_flow_export_token_key'
}

/** input type for incrementing numeric columns in table "subscriber_flow_export" */
export type subscriber_flow_export_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_export" */
export type subscriber_flow_export_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  exported_by_user?: InputMaybe<user_obj_rel_insert_input>;
  exported_by_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_export_max_fields = {
  __typename: 'subscriber_flow_export_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  exported_by_user_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_export_min_fields = {
  __typename: 'subscriber_flow_export_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  exported_by_user_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_flow_export" */
export type subscriber_flow_export_mutation_response = {
  __typename: 'subscriber_flow_export_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_export>;
};

/** on_conflict condition type for table "subscriber_flow_export" */
export type subscriber_flow_export_on_conflict = {
  constraint: subscriber_flow_export_constraint;
  update_columns?: Array<subscriber_flow_export_update_column>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_export". */
export type subscriber_flow_export_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  expires_at?: InputMaybe<order_by>;
  exported_by_user?: InputMaybe<user_order_by>;
  exported_by_user_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_export */
export type subscriber_flow_export_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_export" */
export enum subscriber_flow_export_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  exported_by_user_id = 'exported_by_user_id',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

/** input type for updating data in table "subscriber_flow_export" */
export type subscriber_flow_export_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  exported_by_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_export_stddev_fields = {
  __typename: 'subscriber_flow_export_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_export_stddev_pop_fields = {
  __typename: 'subscriber_flow_export_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_export_stddev_samp_fields = {
  __typename: 'subscriber_flow_export_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_export" */
export type subscriber_flow_export_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_export_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_export_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  exported_by_user_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_export_sum_fields = {
  __typename: 'subscriber_flow_export_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscriber_flow_export" */
export enum subscriber_flow_export_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  exported_by_user_id = 'exported_by_user_id',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url'
}

export type subscriber_flow_export_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_export_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_export_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_export_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_export_var_pop_fields = {
  __typename: 'subscriber_flow_export_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_export_var_samp_fields = {
  __typename: 'subscriber_flow_export_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_export_variance_fields = {
  __typename: 'subscriber_flow_export_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step = {
  __typename: 'subscriber_flow_flow_step';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  flow_step: flow_step;
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  flow_step_version: flow_step_version;
  flow_step_version_id: Scalars['Int']['output'];
  started_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  /** An array relationship */
  subscriber_flow_deflections: Array<subscriber_flow_deflection>;
  /** An aggregate relationship */
  subscriber_flow_deflections_aggregate: subscriber_flow_deflection_aggregate;
  subscriber_flow_id: Scalars['Int']['output'];
  /** An array relationship */
  subscriber_flow_offer_groups: Array<subscriber_flow_offer_group>;
  /** An aggregate relationship */
  subscriber_flow_offer_groups_aggregate: subscriber_flow_offer_group_aggregate;
  /** An array relationship */
  subscriber_flow_offers: Array<subscriber_flow_offer>;
  /** An aggregate relationship */
  subscriber_flow_offers_aggregate: subscriber_flow_offer_aggregate;
  /** An array relationship */
  subscriber_flow_question_answers: Array<subscriber_flow_question_answer>;
  /** An aggregate relationship */
  subscriber_flow_question_answers_aggregate: subscriber_flow_question_answer_aggregate;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_deflectionsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_deflections_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_offer_groupsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_offer_groups_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_question_answersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


/** columns and relationships of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_stepsubscriber_flow_question_answers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};

/** aggregated selection of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_aggregate = {
  __typename: 'subscriber_flow_flow_step_aggregate';
  aggregate?: Maybe<subscriber_flow_flow_step_aggregate_fields>;
  nodes: Array<subscriber_flow_flow_step>;
};

export type subscriber_flow_flow_step_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_flow_step_aggregate_bool_exp_count>;
};

export type subscriber_flow_flow_step_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_aggregate_fields = {
  __typename: 'subscriber_flow_flow_step_aggregate_fields';
  avg?: Maybe<subscriber_flow_flow_step_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_flow_step_max_fields>;
  min?: Maybe<subscriber_flow_flow_step_min_fields>;
  stddev?: Maybe<subscriber_flow_flow_step_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_flow_step_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_flow_step_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_flow_step_sum_fields>;
  var_pop?: Maybe<subscriber_flow_flow_step_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_flow_step_var_samp_fields>;
  variance?: Maybe<subscriber_flow_flow_step_variance_fields>;
};


/** aggregate fields of "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_flow_step_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_flow_step_max_order_by>;
  min?: InputMaybe<subscriber_flow_flow_step_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_flow_step_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_flow_step_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_flow_step_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_flow_step_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_flow_step_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_flow_step_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_flow_step_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_arr_rel_insert_input = {
  data: Array<subscriber_flow_flow_step_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_flow_step_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_flow_step_avg_fields = {
  __typename: 'subscriber_flow_flow_step_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_flow_step". All fields are combined with a logical 'AND'. */
export type subscriber_flow_flow_step_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_flow_step_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_flow_step_bool_exp>>;
  completed_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step?: InputMaybe<flow_step_bool_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  flow_step_version?: InputMaybe<flow_step_version_bool_exp>;
  flow_step_version_id?: InputMaybe<Int_comparison_exp>;
  started_at?: InputMaybe<timestamptz_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_bool_exp>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_offer_groups?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
  subscriber_flow_offer_groups_aggregate?: InputMaybe<subscriber_flow_offer_group_aggregate_bool_exp>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_bool_exp>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_bool_exp>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_flow_step" */
export enum subscriber_flow_flow_step_constraint {
  /** unique or primary key constraint on columns "flow_step_id", "subscriber_flow_id" */
  subscriber_flow_flow_step_pkey = 'subscriber_flow_flow_step_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_insert_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step?: InputMaybe<flow_step_obj_rel_insert_input>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_version?: InputMaybe<flow_step_version_obj_rel_insert_input>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_arr_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_offer_groups?: InputMaybe<subscriber_flow_offer_group_arr_rel_insert_input>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_arr_rel_insert_input>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type subscriber_flow_flow_step_max_fields = {
  __typename: 'subscriber_flow_flow_step_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_max_order_by = {
  completed_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_flow_step_min_fields = {
  __typename: 'subscriber_flow_flow_step_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_min_order_by = {
  completed_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_mutation_response = {
  __typename: 'subscriber_flow_flow_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_flow_step>;
};

/** input type for inserting object relation for remote table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_obj_rel_insert_input = {
  data: subscriber_flow_flow_step_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_flow_step_on_conflict>;
};

/** on_conflict condition type for table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_on_conflict = {
  constraint: subscriber_flow_flow_step_constraint;
  update_columns?: Array<subscriber_flow_flow_step_update_column>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_flow_step". */
export type subscriber_flow_flow_step_order_by = {
  completed_at?: InputMaybe<order_by>;
  flow_step?: InputMaybe<flow_step_order_by>;
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version?: InputMaybe<flow_step_version_order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_flow_offer_groups_aggregate?: InputMaybe<subscriber_flow_offer_group_aggregate_order_by>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_order_by>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_order_by>;
};

/** primary key columns input for table: subscriber_flow_flow_step */
export type subscriber_flow_flow_step_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_flow_step" */
export enum subscriber_flow_flow_step_select_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  flow_step_version_id = 'flow_step_version_id',
  /** column name */
  started_at = 'started_at',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_set_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_flow_step_stddev_fields = {
  __typename: 'subscriber_flow_flow_step_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_flow_step_stddev_pop_fields = {
  __typename: 'subscriber_flow_flow_step_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_flow_step_stddev_samp_fields = {
  __typename: 'subscriber_flow_flow_step_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_flow_step_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_flow_step_stream_cursor_value_input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  flow_step_version_id?: InputMaybe<Scalars['Int']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_flow_step_sum_fields = {
  __typename: 'subscriber_flow_flow_step_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  flow_step_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_flow_step" */
export enum subscriber_flow_flow_step_update_column {
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  flow_step_version_id = 'flow_step_version_id',
  /** column name */
  started_at = 'started_at',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_flow_step_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_flow_step_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_flow_step_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_flow_step_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_flow_step_var_pop_fields = {
  __typename: 'subscriber_flow_flow_step_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_flow_step_var_samp_fields = {
  __typename: 'subscriber_flow_flow_step_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_flow_step_variance_fields = {
  __typename: 'subscriber_flow_flow_step_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  flow_step_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_flow_step" */
export type subscriber_flow_flow_step_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  flow_step_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "subscriber_flow" */
export type subscriber_flow_inc_input = {
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  rerouted_from_subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow" */
export type subscriber_flow_insert_input = {
  automatically_deflected?: InputMaybe<Scalars['Boolean']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message?: InputMaybe<eligibility_message_obj_rel_insert_input>;
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow?: InputMaybe<flow_obj_rel_insert_input>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  reroute_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  rerouted_from_subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  rerouted_from_subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  segment_values?: InputMaybe<Scalars['jsonb']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<subscriber_flow_status_enum>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_arr_rel_insert_input>;
  subscriber_flow_deflections?: InputMaybe<subscriber_flow_deflection_arr_rel_insert_input>;
  subscriber_flow_flow_steps?: InputMaybe<subscriber_flow_flow_step_arr_rel_insert_input>;
  subscriber_flow_offer_groups?: InputMaybe<subscriber_flow_offer_group_arr_rel_insert_input>;
  subscriber_flow_offer_rules?: InputMaybe<subscriber_flow_offer_rule_arr_rel_insert_input>;
  subscriber_flow_offers?: InputMaybe<subscriber_flow_offer_arr_rel_insert_input>;
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_arr_rel_insert_input>;
  subscriber_flow_question_answers?: InputMaybe<subscriber_flow_question_answer_arr_rel_insert_input>;
  subscriber_flow_segments?: InputMaybe<subscriber_flow_segment_arr_rel_insert_input>;
  subscriber_flow_status?: InputMaybe<subscriber_flow_status_obj_rel_insert_input>;
  subscriber_flow_url?: InputMaybe<subscriber_flow_url_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_max_fields = {
  __typename: 'subscriber_flow_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "subscriber_flow" */
export type subscriber_flow_max_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_min_fields = {
  __typename: 'subscriber_flow_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  started_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_agent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "subscriber_flow" */
export type subscriber_flow_min_order_by = {
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow" */
export type subscriber_flow_mutation_response = {
  __typename: 'subscriber_flow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow>;
};

/** input type for inserting object relation for remote table "subscriber_flow" */
export type subscriber_flow_obj_rel_insert_input = {
  data: subscriber_flow_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_on_conflict>;
};

/** columns and relationships of "subscriber_flow_offer" */
export type subscriber_flow_offer = {
  __typename: 'subscriber_flow_offer';
  created_at: Scalars['timestamptz']['output'];
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer: offer;
  /** An object relationship */
  offer_autopilot_offer_variant?: Maybe<offer_autopilot_offer_variant>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_pause_subscription_option?: Maybe<offer_pause_subscription_option>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_test?: Maybe<offer_test>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  status?: Maybe<subscriber_flow_offer_status_enum>;
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  /** An object relationship */
  subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step>;
  subscriber_flow_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "subscriber_flow_offer" */
export type subscriber_flow_offer_aggregate = {
  __typename: 'subscriber_flow_offer_aggregate';
  aggregate?: Maybe<subscriber_flow_offer_aggregate_fields>;
  nodes: Array<subscriber_flow_offer>;
};

export type subscriber_flow_offer_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_offer_aggregate_bool_exp_count>;
};

export type subscriber_flow_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_offer" */
export type subscriber_flow_offer_aggregate_fields = {
  __typename: 'subscriber_flow_offer_aggregate_fields';
  avg?: Maybe<subscriber_flow_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_offer_max_fields>;
  min?: Maybe<subscriber_flow_offer_min_fields>;
  stddev?: Maybe<subscriber_flow_offer_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_offer_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_offer_sum_fields>;
  var_pop?: Maybe<subscriber_flow_offer_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_offer_var_samp_fields>;
  variance?: Maybe<subscriber_flow_offer_variance_fields>;
};


/** aggregate fields of "subscriber_flow_offer" */
export type subscriber_flow_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_offer" */
export type subscriber_flow_offer_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_offer_max_order_by>;
  min?: InputMaybe<subscriber_flow_offer_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_offer_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_offer_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_offer_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_offer_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_offer" */
export type subscriber_flow_offer_arr_rel_insert_input = {
  data: Array<subscriber_flow_offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_offer_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_offer_avg_fields = {
  __typename: 'subscriber_flow_offer_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_offer". All fields are combined with a logical 'AND'. */
export type subscriber_flow_offer_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_offer_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_offer_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_offer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_autopilot_offer_variant?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_bool_exp>;
  offer_pause_subscription_option_id?: InputMaybe<Int_comparison_exp>;
  offer_test?: InputMaybe<offer_test_bool_exp>;
  offer_test_id?: InputMaybe<Int_comparison_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  pause_at?: InputMaybe<date_comparison_exp>;
  rescheduled_to?: InputMaybe<date_comparison_exp>;
  resume_at?: InputMaybe<date_comparison_exp>;
  status?: InputMaybe<subscriber_flow_offer_status_enum_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_offer" */
export enum subscriber_flow_offer_constraint {
  /** unique or primary key constraint on columns "offer_id", "subscriber_flow_id" */
  subscriber_flow_offer_pkey = 'subscriber_flow_offer_pkey'
}

/** columns and relationships of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group = {
  __typename: 'subscriber_flow_offer_group';
  created_at: Scalars['timestamptz']['output'];
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An object relationship */
  offer_group: offer_group;
  offer_group_id: Scalars['Int']['output'];
  offer_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_pause_subscription_option?: Maybe<offer_pause_subscription_option>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  status?: Maybe<subscriber_flow_offer_status_enum>;
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
  /** An array relationship */
  subscriber_flow_offer_group_offers: Array<subscriber_flow_offer_group_offer>;
  /** An aggregate relationship */
  subscriber_flow_offer_group_offers_aggregate: subscriber_flow_offer_group_offer_aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_groupsubscriber_flow_offer_group_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


/** columns and relationships of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_groupsubscriber_flow_offer_group_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};

/** aggregated selection of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_aggregate = {
  __typename: 'subscriber_flow_offer_group_aggregate';
  aggregate?: Maybe<subscriber_flow_offer_group_aggregate_fields>;
  nodes: Array<subscriber_flow_offer_group>;
};

export type subscriber_flow_offer_group_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_offer_group_aggregate_bool_exp_count>;
};

export type subscriber_flow_offer_group_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_aggregate_fields = {
  __typename: 'subscriber_flow_offer_group_aggregate_fields';
  avg?: Maybe<subscriber_flow_offer_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_offer_group_max_fields>;
  min?: Maybe<subscriber_flow_offer_group_min_fields>;
  stddev?: Maybe<subscriber_flow_offer_group_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_offer_group_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_offer_group_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_offer_group_sum_fields>;
  var_pop?: Maybe<subscriber_flow_offer_group_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_offer_group_var_samp_fields>;
  variance?: Maybe<subscriber_flow_offer_group_variance_fields>;
};


/** aggregate fields of "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_offer_group_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_offer_group_max_order_by>;
  min?: InputMaybe<subscriber_flow_offer_group_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_offer_group_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_offer_group_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_offer_group_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_offer_group_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_offer_group_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_offer_group_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_offer_group_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_arr_rel_insert_input = {
  data: Array<subscriber_flow_offer_group_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_offer_group_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_offer_group_avg_fields = {
  __typename: 'subscriber_flow_offer_group_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_offer_group". All fields are combined with a logical 'AND'. */
export type subscriber_flow_offer_group_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_offer_group_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_offer_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_bool_exp>;
  offer_pause_subscription_option_id?: InputMaybe<Int_comparison_exp>;
  pause_at?: InputMaybe<date_comparison_exp>;
  rescheduled_to?: InputMaybe<date_comparison_exp>;
  resume_at?: InputMaybe<date_comparison_exp>;
  status?: InputMaybe<subscriber_flow_offer_status_enum_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_offer_group_offers?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
  subscriber_flow_offer_group_offers_aggregate?: InputMaybe<subscriber_flow_offer_group_offer_aggregate_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_offer_group" */
export enum subscriber_flow_offer_group_constraint {
  /** unique or primary key constraint on columns "flow_step_id", "subscriber_flow_id", "offer_group_id" */
  subscriber_flow_offer_group_pkey = 'subscriber_flow_offer_group_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_obj_rel_insert_input>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_offer_group_offers?: InputMaybe<subscriber_flow_offer_group_offer_arr_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_offer_group_max_fields = {
  __typename: 'subscriber_flow_offer_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_offer_group_min_fields = {
  __typename: 'subscriber_flow_offer_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_mutation_response = {
  __typename: 'subscriber_flow_offer_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_offer_group>;
};

/** input type for inserting object relation for remote table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_obj_rel_insert_input = {
  data: subscriber_flow_offer_group_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_offer_group_on_conflict>;
};

/** columns and relationships of "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer = {
  __typename: 'subscriber_flow_offer_group_offer';
  created_at: Scalars['timestamptz']['output'];
  flow_step_id: Scalars['Int']['output'];
  /** An object relationship */
  offer: offer;
  /** An object relationship */
  offer_autopilot_round?: Maybe<offer_autopilot_round>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_group: offer_group;
  offer_group_id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  offer_variant?: Maybe<offer_variant>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  status?: Maybe<subscriber_flow_offer_status_enum>;
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow_offer_group?: Maybe<subscriber_flow_offer_group>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_aggregate = {
  __typename: 'subscriber_flow_offer_group_offer_aggregate';
  aggregate?: Maybe<subscriber_flow_offer_group_offer_aggregate_fields>;
  nodes: Array<subscriber_flow_offer_group_offer>;
};

export type subscriber_flow_offer_group_offer_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_offer_group_offer_aggregate_bool_exp_count>;
};

export type subscriber_flow_offer_group_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_aggregate_fields = {
  __typename: 'subscriber_flow_offer_group_offer_aggregate_fields';
  avg?: Maybe<subscriber_flow_offer_group_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_offer_group_offer_max_fields>;
  min?: Maybe<subscriber_flow_offer_group_offer_min_fields>;
  stddev?: Maybe<subscriber_flow_offer_group_offer_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_offer_group_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_offer_group_offer_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_offer_group_offer_sum_fields>;
  var_pop?: Maybe<subscriber_flow_offer_group_offer_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_offer_group_offer_var_samp_fields>;
  variance?: Maybe<subscriber_flow_offer_group_offer_variance_fields>;
};


/** aggregate fields of "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_offer_group_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_offer_group_offer_max_order_by>;
  min?: InputMaybe<subscriber_flow_offer_group_offer_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_offer_group_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_offer_group_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_offer_group_offer_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_offer_group_offer_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_offer_group_offer_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_offer_group_offer_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_offer_group_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_arr_rel_insert_input = {
  data: Array<subscriber_flow_offer_group_offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_offer_group_offer_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_offer_group_offer_avg_fields = {
  __typename: 'subscriber_flow_offer_group_offer_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_offer_group_offer". All fields are combined with a logical 'AND'. */
export type subscriber_flow_offer_group_offer_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_offer_group_offer_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_offer_group_offer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_bool_exp>;
  offer_autopilot_round_id?: InputMaybe<Int_comparison_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  offer_pause_subscription_option_id?: InputMaybe<Int_comparison_exp>;
  offer_variant?: InputMaybe<offer_variant_bool_exp>;
  offer_variant_id?: InputMaybe<Int_comparison_exp>;
  pause_at?: InputMaybe<date_comparison_exp>;
  rescheduled_to?: InputMaybe<date_comparison_exp>;
  resume_at?: InputMaybe<date_comparison_exp>;
  status?: InputMaybe<subscriber_flow_offer_status_enum_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_offer_group?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_offer_group_offer" */
export enum subscriber_flow_offer_group_offer_constraint {
  /** unique or primary key constraint on columns "flow_step_id", "offer_id", "subscriber_flow_id", "offer_group_id" */
  subscriber_flow_offer_group_offer_pkey = 'subscriber_flow_offer_group_offer_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant?: InputMaybe<offer_variant_obj_rel_insert_input>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_offer_group?: InputMaybe<subscriber_flow_offer_group_obj_rel_insert_input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_offer_group_offer_max_fields = {
  __typename: 'subscriber_flow_offer_group_offer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_offer_group_offer_min_fields = {
  __typename: 'subscriber_flow_offer_group_offer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_mutation_response = {
  __typename: 'subscriber_flow_offer_group_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_offer_group_offer>;
};

/** on_conflict condition type for table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_on_conflict = {
  constraint: subscriber_flow_offer_group_offer_constraint;
  update_columns?: Array<subscriber_flow_offer_group_offer_update_column>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_offer_group_offer". */
export type subscriber_flow_offer_group_offer_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_autopilot_round?: InputMaybe<offer_autopilot_round_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant?: InputMaybe<offer_variant_order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_flow_offer_group?: InputMaybe<subscriber_flow_offer_group_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_offer_group_offer */
export type subscriber_flow_offer_group_offer_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_offer_group_offer" */
export enum subscriber_flow_offer_group_offer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_offer_group_offer_stddev_fields = {
  __typename: 'subscriber_flow_offer_group_offer_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_offer_group_offer_stddev_pop_fields = {
  __typename: 'subscriber_flow_offer_group_offer_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_offer_group_offer_stddev_samp_fields = {
  __typename: 'subscriber_flow_offer_group_offer_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_offer_group_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_offer_group_offer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_offer_group_offer_sum_fields = {
  __typename: 'subscriber_flow_offer_group_offer_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_offer_group_offer" */
export enum subscriber_flow_offer_group_offer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_flow_offer_group_offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_offer_group_offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_offer_group_offer_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_offer_group_offer_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_offer_group_offer_var_pop_fields = {
  __typename: 'subscriber_flow_offer_group_offer_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_offer_group_offer_var_samp_fields = {
  __typename: 'subscriber_flow_offer_group_offer_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_offer_group_offer_variance_fields = {
  __typename: 'subscriber_flow_offer_group_offer_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_offer_group_offer" */
export type subscriber_flow_offer_group_offer_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_on_conflict = {
  constraint: subscriber_flow_offer_group_constraint;
  update_columns?: Array<subscriber_flow_offer_group_update_column>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_offer_group". */
export type subscriber_flow_offer_group_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_flow_offer_group_offers_aggregate?: InputMaybe<subscriber_flow_offer_group_offer_aggregate_order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_offer_group */
export type subscriber_flow_offer_group_pk_columns_input = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_offer_group" */
export enum subscriber_flow_offer_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_offer_group_stddev_fields = {
  __typename: 'subscriber_flow_offer_group_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_offer_group_stddev_pop_fields = {
  __typename: 'subscriber_flow_offer_group_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_offer_group_stddev_samp_fields = {
  __typename: 'subscriber_flow_offer_group_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_offer_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_offer_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_offer_group_sum_fields = {
  __typename: 'subscriber_flow_offer_group_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_offer_group" */
export enum subscriber_flow_offer_group_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_flow_offer_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_offer_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_offer_group_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_offer_group_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_offer_group_var_pop_fields = {
  __typename: 'subscriber_flow_offer_group_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_offer_group_var_samp_fields = {
  __typename: 'subscriber_flow_offer_group_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_offer_group_variance_fields = {
  __typename: 'subscriber_flow_offer_group_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_offer_group" */
export type subscriber_flow_offer_group_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "subscriber_flow_offer" */
export type subscriber_flow_offer_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_offer" */
export type subscriber_flow_offer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_autopilot_offer_variant?: InputMaybe<offer_autopilot_offer_variant_obj_rel_insert_input>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_obj_rel_insert_input>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test?: InputMaybe<offer_test_obj_rel_insert_input>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_offer_max_fields = {
  __typename: 'subscriber_flow_offer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_offer_min_fields = {
  __typename: 'subscriber_flow_offer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  pause_at?: Maybe<Scalars['date']['output']>;
  rescheduled_to?: Maybe<Scalars['date']['output']>;
  resume_at?: Maybe<Scalars['date']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_offer" */
export type subscriber_flow_offer_mutation_response = {
  __typename: 'subscriber_flow_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_offer>;
};

/** on_conflict condition type for table "subscriber_flow_offer" */
export type subscriber_flow_offer_on_conflict = {
  constraint: subscriber_flow_offer_constraint;
  update_columns?: Array<subscriber_flow_offer_update_column>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_offer". */
export type subscriber_flow_offer_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_autopilot_offer_variant?: InputMaybe<offer_autopilot_offer_variant_order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option?: InputMaybe<offer_pause_subscription_option_order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test?: InputMaybe<offer_test_order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  pause_at?: InputMaybe<order_by>;
  rescheduled_to?: InputMaybe<order_by>;
  resume_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_offer */
export type subscriber_flow_offer_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** columns and relationships of "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule = {
  __typename: 'subscriber_flow_offer_rule';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer_rule: offer_rule;
  offer_rule_id: Scalars['Int']['output'];
  /** An object relationship */
  offer_rule_version: offer_rule_version;
  offer_rule_version_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_aggregate = {
  __typename: 'subscriber_flow_offer_rule_aggregate';
  aggregate?: Maybe<subscriber_flow_offer_rule_aggregate_fields>;
  nodes: Array<subscriber_flow_offer_rule>;
};

export type subscriber_flow_offer_rule_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_offer_rule_aggregate_bool_exp_count>;
};

export type subscriber_flow_offer_rule_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_aggregate_fields = {
  __typename: 'subscriber_flow_offer_rule_aggregate_fields';
  avg?: Maybe<subscriber_flow_offer_rule_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_offer_rule_max_fields>;
  min?: Maybe<subscriber_flow_offer_rule_min_fields>;
  stddev?: Maybe<subscriber_flow_offer_rule_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_offer_rule_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_offer_rule_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_offer_rule_sum_fields>;
  var_pop?: Maybe<subscriber_flow_offer_rule_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_offer_rule_var_samp_fields>;
  variance?: Maybe<subscriber_flow_offer_rule_variance_fields>;
};


/** aggregate fields of "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_offer_rule_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_offer_rule_max_order_by>;
  min?: InputMaybe<subscriber_flow_offer_rule_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_offer_rule_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_offer_rule_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_offer_rule_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_offer_rule_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_offer_rule_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_offer_rule_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_offer_rule_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_arr_rel_insert_input = {
  data: Array<subscriber_flow_offer_rule_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_offer_rule_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_offer_rule_avg_fields = {
  __typename: 'subscriber_flow_offer_rule_avg_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_avg_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_offer_rule". All fields are combined with a logical 'AND'. */
export type subscriber_flow_offer_rule_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_offer_rule_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_offer_rule_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer_rule?: InputMaybe<offer_rule_bool_exp>;
  offer_rule_id?: InputMaybe<Int_comparison_exp>;
  offer_rule_version?: InputMaybe<offer_rule_version_bool_exp>;
  offer_rule_version_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_offer_rule" */
export enum subscriber_flow_offer_rule_constraint {
  /** unique or primary key constraint on columns "subscriber_flow_id", "offer_rule_id" */
  subscriber_flow_offer_rule_pkey = 'subscriber_flow_offer_rule_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_inc_input = {
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_rule?: InputMaybe<offer_rule_obj_rel_insert_input>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version?: InputMaybe<offer_rule_version_obj_rel_insert_input>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_offer_rule_max_fields = {
  __typename: 'subscriber_flow_offer_rule_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_offer_rule_min_fields = {
  __typename: 'subscriber_flow_offer_rule_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_mutation_response = {
  __typename: 'subscriber_flow_offer_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_offer_rule>;
};

/** on_conflict condition type for table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_on_conflict = {
  constraint: subscriber_flow_offer_rule_constraint;
  update_columns?: Array<subscriber_flow_offer_rule_update_column>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_offer_rule". */
export type subscriber_flow_offer_rule_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_rule?: InputMaybe<offer_rule_order_by>;
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version?: InputMaybe<offer_rule_version_order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_offer_rule */
export type subscriber_flow_offer_rule_pk_columns_input = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_offer_rule" */
export enum subscriber_flow_offer_rule_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_offer_rule_stddev_fields = {
  __typename: 'subscriber_flow_offer_rule_stddev_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_stddev_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_offer_rule_stddev_pop_fields = {
  __typename: 'subscriber_flow_offer_rule_stddev_pop_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_stddev_pop_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_offer_rule_stddev_samp_fields = {
  __typename: 'subscriber_flow_offer_rule_stddev_samp_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_stddev_samp_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_offer_rule_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_offer_rule_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_rule_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rule_version_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_offer_rule_sum_fields = {
  __typename: 'subscriber_flow_offer_rule_sum_fields';
  offer_rule_id?: Maybe<Scalars['Int']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_sum_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_offer_rule" */
export enum subscriber_flow_offer_rule_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_rule_id = 'offer_rule_id',
  /** column name */
  offer_rule_version_id = 'offer_rule_version_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_offer_rule_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_offer_rule_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_offer_rule_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_offer_rule_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_offer_rule_var_pop_fields = {
  __typename: 'subscriber_flow_offer_rule_var_pop_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_var_pop_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_offer_rule_var_samp_fields = {
  __typename: 'subscriber_flow_offer_rule_var_samp_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_var_samp_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_offer_rule_variance_fields = {
  __typename: 'subscriber_flow_offer_rule_variance_fields';
  offer_rule_id?: Maybe<Scalars['Float']['output']>;
  offer_rule_version_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_offer_rule" */
export type subscriber_flow_offer_rule_variance_order_by = {
  offer_rule_id?: InputMaybe<order_by>;
  offer_rule_version_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow_offer" */
export enum subscriber_flow_offer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber_flow_offer" */
export type subscriber_flow_offer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status = {
  __typename: 'subscriber_flow_offer_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_aggregate = {
  __typename: 'subscriber_flow_offer_status_aggregate';
  aggregate?: Maybe<subscriber_flow_offer_status_aggregate_fields>;
  nodes: Array<subscriber_flow_offer_status>;
};

/** aggregate fields of "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_aggregate_fields = {
  __typename: 'subscriber_flow_offer_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_offer_status_max_fields>;
  min?: Maybe<subscriber_flow_offer_status_min_fields>;
};


/** aggregate fields of "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_offer_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_offer_status". All fields are combined with a logical 'AND'. */
export type subscriber_flow_offer_status_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_offer_status_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_offer_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_offer_status" */
export enum subscriber_flow_offer_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_flow_offer_status_pkey = 'subscriber_flow_offer_status_pkey'
}

export enum subscriber_flow_offer_status_enum {
  accepted = 'accepted',
  bypassed = 'bypassed',
  declined = 'declined'
}

/** Boolean expression to compare columns of type "subscriber_flow_offer_status_enum". All fields are combined with logical 'AND'. */
export type subscriber_flow_offer_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_flow_offer_status_enum>;
  _in?: InputMaybe<Array<subscriber_flow_offer_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_flow_offer_status_enum>;
  _nin?: InputMaybe<Array<subscriber_flow_offer_status_enum>>;
};

/** input type for inserting data into table "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_offer_status_max_fields = {
  __typename: 'subscriber_flow_offer_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_offer_status_min_fields = {
  __typename: 'subscriber_flow_offer_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_mutation_response = {
  __typename: 'subscriber_flow_offer_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_offer_status>;
};

/** on_conflict condition type for table "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_on_conflict = {
  constraint: subscriber_flow_offer_status_constraint;
  update_columns?: Array<subscriber_flow_offer_status_update_column>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_offer_status". */
export type subscriber_flow_offer_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_offer_status */
export type subscriber_flow_offer_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_flow_offer_status" */
export enum subscriber_flow_offer_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_flow_offer_status" */
export type subscriber_flow_offer_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_offer_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_offer_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_flow_offer_status" */
export enum subscriber_flow_offer_status_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_flow_offer_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_offer_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_offer_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscriber_flow_offer_stddev_fields = {
  __typename: 'subscriber_flow_offer_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_offer_stddev_pop_fields = {
  __typename: 'subscriber_flow_offer_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_offer_stddev_samp_fields = {
  __typename: 'subscriber_flow_offer_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_offer" */
export type subscriber_flow_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_offer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  offer_autopilot_round_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  offer_pause_subscription_option_id?: InputMaybe<Scalars['Int']['input']>;
  offer_test_id?: InputMaybe<Scalars['Int']['input']>;
  offer_variant_id?: InputMaybe<Scalars['Int']['input']>;
  pause_at?: InputMaybe<Scalars['date']['input']>;
  rescheduled_to?: InputMaybe<Scalars['date']['input']>;
  resume_at?: InputMaybe<Scalars['date']['input']>;
  status?: InputMaybe<subscriber_flow_offer_status_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_offer_sum_fields = {
  __typename: 'subscriber_flow_offer_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Int']['output']>;
  offer_test_id?: Maybe<Scalars['Int']['output']>;
  offer_variant_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_offer" */
export enum subscriber_flow_offer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  offer_autopilot_round_id = 'offer_autopilot_round_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  offer_pause_subscription_option_id = 'offer_pause_subscription_option_id',
  /** column name */
  offer_test_id = 'offer_test_id',
  /** column name */
  offer_variant_id = 'offer_variant_id',
  /** column name */
  pause_at = 'pause_at',
  /** column name */
  rescheduled_to = 'rescheduled_to',
  /** column name */
  resume_at = 'resume_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_flow_offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_offer_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_offer_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_offer_var_pop_fields = {
  __typename: 'subscriber_flow_offer_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_offer_var_samp_fields = {
  __typename: 'subscriber_flow_offer_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_offer_variance_fields = {
  __typename: 'subscriber_flow_offer_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  offer_autopilot_round_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  offer_pause_subscription_option_id?: Maybe<Scalars['Float']['output']>;
  offer_test_id?: Maybe<Scalars['Float']['output']>;
  offer_variant_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_offer" */
export type subscriber_flow_offer_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  offer_autopilot_round_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  offer_pause_subscription_option_id?: InputMaybe<order_by>;
  offer_test_id?: InputMaybe<order_by>;
  offer_variant_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override = {
  __typename: 'subscriber_flow_on_cancel_override';
  created_at: Scalars['timestamptz']['output'];
  reason: subscriber_flow_override_reason_enum;
  subscriber_flow_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_aggregate = {
  __typename: 'subscriber_flow_on_cancel_override_aggregate';
  aggregate?: Maybe<subscriber_flow_on_cancel_override_aggregate_fields>;
  nodes: Array<subscriber_flow_on_cancel_override>;
};

/** aggregate fields of "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_aggregate_fields = {
  __typename: 'subscriber_flow_on_cancel_override_aggregate_fields';
  avg?: Maybe<subscriber_flow_on_cancel_override_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_on_cancel_override_max_fields>;
  min?: Maybe<subscriber_flow_on_cancel_override_min_fields>;
  stddev?: Maybe<subscriber_flow_on_cancel_override_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_on_cancel_override_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_on_cancel_override_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_on_cancel_override_sum_fields>;
  var_pop?: Maybe<subscriber_flow_on_cancel_override_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_on_cancel_override_var_samp_fields>;
  variance?: Maybe<subscriber_flow_on_cancel_override_variance_fields>;
};


/** aggregate fields of "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_on_cancel_override_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_on_cancel_override_avg_fields = {
  __typename: 'subscriber_flow_on_cancel_override_avg_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_on_cancel_override". All fields are combined with a logical 'AND'. */
export type subscriber_flow_on_cancel_override_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_on_cancel_override_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_on_cancel_override_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum_comparison_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_on_cancel_override" */
export enum subscriber_flow_on_cancel_override_constraint {
  /** unique or primary key constraint on columns "subscriber_flow_id" */
  subscriber_flow_on_cancel_override_pkey = 'subscriber_flow_on_cancel_override_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_inc_input = {
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_on_cancel_override_max_fields = {
  __typename: 'subscriber_flow_on_cancel_override_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_on_cancel_override_min_fields = {
  __typename: 'subscriber_flow_on_cancel_override_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_mutation_response = {
  __typename: 'subscriber_flow_on_cancel_override_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_on_cancel_override>;
};

/** on_conflict condition type for table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_on_conflict = {
  constraint: subscriber_flow_on_cancel_override_constraint;
  update_columns?: Array<subscriber_flow_on_cancel_override_update_column>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_on_cancel_override". */
export type subscriber_flow_on_cancel_override_order_by = {
  created_at?: InputMaybe<order_by>;
  reason?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_on_cancel_override */
export type subscriber_flow_on_cancel_override_pk_columns_input = {
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_on_cancel_override" */
export enum subscriber_flow_on_cancel_override_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_on_cancel_override_stddev_fields = {
  __typename: 'subscriber_flow_on_cancel_override_stddev_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_on_cancel_override_stddev_pop_fields = {
  __typename: 'subscriber_flow_on_cancel_override_stddev_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_on_cancel_override_stddev_samp_fields = {
  __typename: 'subscriber_flow_on_cancel_override_stddev_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_on_cancel_override" */
export type subscriber_flow_on_cancel_override_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_on_cancel_override_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_on_cancel_override_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_on_cancel_override_sum_fields = {
  __typename: 'subscriber_flow_on_cancel_override_sum_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscriber_flow_on_cancel_override" */
export enum subscriber_flow_on_cancel_override_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_on_cancel_override_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_on_cancel_override_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_on_cancel_override_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_on_cancel_override_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_on_cancel_override_var_pop_fields = {
  __typename: 'subscriber_flow_on_cancel_override_var_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_on_cancel_override_var_samp_fields = {
  __typename: 'subscriber_flow_on_cancel_override_var_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_on_cancel_override_variance_fields = {
  __typename: 'subscriber_flow_on_cancel_override_variance_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** on_conflict condition type for table "subscriber_flow" */
export type subscriber_flow_on_conflict = {
  constraint: subscriber_flow_constraint;
  update_columns?: Array<subscriber_flow_update_column>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow". */
export type subscriber_flow_order_by = {
  automatically_deflected?: InputMaybe<order_by>;
  completed_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  eligibility_message?: InputMaybe<eligibility_message_order_by>;
  eligibility_message_id?: InputMaybe<order_by>;
  flow?: InputMaybe<flow_order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  payload?: InputMaybe<order_by>;
  reroute_allowed?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  segment_values?: InputMaybe<order_by>;
  signed?: InputMaybe<order_by>;
  started_at?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_order_by>;
  subscriber_flow_deflections_aggregate?: InputMaybe<subscriber_flow_deflection_aggregate_order_by>;
  subscriber_flow_flow_steps_aggregate?: InputMaybe<subscriber_flow_flow_step_aggregate_order_by>;
  subscriber_flow_offer_groups_aggregate?: InputMaybe<subscriber_flow_offer_group_aggregate_order_by>;
  subscriber_flow_offer_rules_aggregate?: InputMaybe<subscriber_flow_offer_rule_aggregate_order_by>;
  subscriber_flow_offers_aggregate?: InputMaybe<subscriber_flow_offer_aggregate_order_by>;
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_order_by>;
  subscriber_flow_question_answers_aggregate?: InputMaybe<subscriber_flow_question_answer_aggregate_order_by>;
  subscriber_flow_segments_aggregate?: InputMaybe<subscriber_flow_segment_aggregate_order_by>;
  subscriber_flow_status?: InputMaybe<subscriber_flow_status_order_by>;
  subscriber_flow_url?: InputMaybe<subscriber_flow_url_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  trial?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  user_agent?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_flow_override" */
export type subscriber_flow_override = {
  __typename: 'subscriber_flow_override';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id: Scalars['Int']['output'];
  reason: subscriber_flow_override_reason_enum;
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow_override_reason: subscriber_flow_override_reason;
};

/** aggregated selection of "subscriber_flow_override" */
export type subscriber_flow_override_aggregate = {
  __typename: 'subscriber_flow_override_aggregate';
  aggregate?: Maybe<subscriber_flow_override_aggregate_fields>;
  nodes: Array<subscriber_flow_override>;
};

export type subscriber_flow_override_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_override_aggregate_bool_exp_count>;
};

export type subscriber_flow_override_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_override_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_override" */
export type subscriber_flow_override_aggregate_fields = {
  __typename: 'subscriber_flow_override_aggregate_fields';
  avg?: Maybe<subscriber_flow_override_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_override_max_fields>;
  min?: Maybe<subscriber_flow_override_min_fields>;
  stddev?: Maybe<subscriber_flow_override_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_override_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_override_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_override_sum_fields>;
  var_pop?: Maybe<subscriber_flow_override_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_override_var_samp_fields>;
  variance?: Maybe<subscriber_flow_override_variance_fields>;
};


/** aggregate fields of "subscriber_flow_override" */
export type subscriber_flow_override_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_override" */
export type subscriber_flow_override_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_override_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_override_max_order_by>;
  min?: InputMaybe<subscriber_flow_override_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_override_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_override_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_override_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_override_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_override_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_override_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_override_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_override" */
export type subscriber_flow_override_arr_rel_insert_input = {
  data: Array<subscriber_flow_override_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_override_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_override_avg_fields = {
  __typename: 'subscriber_flow_override_avg_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_avg_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_override". All fields are combined with a logical 'AND'. */
export type subscriber_flow_override_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_override_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_override_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_override_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_override_reason?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_override" */
export enum subscriber_flow_override_constraint {
  /** unique or primary key constraint on columns "offer_id", "subscriber_flow_id" */
  subscriber_flow_override_pkey = 'subscriber_flow_override_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_override" */
export type subscriber_flow_override_inc_input = {
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_override" */
export type subscriber_flow_override_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_override_reason?: InputMaybe<subscriber_flow_override_reason_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type subscriber_flow_override_max_fields = {
  __typename: 'subscriber_flow_override_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_override_min_fields = {
  __typename: 'subscriber_flow_override_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_override" */
export type subscriber_flow_override_mutation_response = {
  __typename: 'subscriber_flow_override_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_override>;
};

/** on_conflict condition type for table "subscriber_flow_override" */
export type subscriber_flow_override_on_conflict = {
  constraint: subscriber_flow_override_constraint;
  update_columns?: Array<subscriber_flow_override_update_column>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_override". */
export type subscriber_flow_override_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  reason?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_flow_override_reason?: InputMaybe<subscriber_flow_override_reason_order_by>;
};

/** primary key columns input for table: subscriber_flow_override */
export type subscriber_flow_override_pk_columns_input = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** columns and relationships of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason = {
  __typename: 'subscriber_flow_override_reason';
  /** An array relationship */
  subscriber_flow_overrides: Array<subscriber_flow_override>;
  /** An aggregate relationship */
  subscriber_flow_overrides_aggregate: subscriber_flow_override_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reasonsubscriber_flow_overridesArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


/** columns and relationships of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reasonsubscriber_flow_overrides_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};

/** aggregated selection of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_aggregate = {
  __typename: 'subscriber_flow_override_reason_aggregate';
  aggregate?: Maybe<subscriber_flow_override_reason_aggregate_fields>;
  nodes: Array<subscriber_flow_override_reason>;
};

/** aggregate fields of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_aggregate_fields = {
  __typename: 'subscriber_flow_override_reason_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_override_reason_max_fields>;
  min?: Maybe<subscriber_flow_override_reason_min_fields>;
};


/** aggregate fields of "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_override_reason_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_override_reason". All fields are combined with a logical 'AND'. */
export type subscriber_flow_override_reason_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_override_reason_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_override_reason_bool_exp>>;
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_bool_exp>;
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_override_reason" */
export enum subscriber_flow_override_reason_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_flow_override_reason_pkey = 'subscriber_flow_override_reason_pkey'
}

export enum subscriber_flow_override_reason_enum {
  coupon_cannot_be_applied_to_subscription = 'coupon_cannot_be_applied_to_subscription',
  coupon_not_valid = 'coupon_not_valid',
  modifications_would_remove_all_plans = 'modifications_would_remove_all_plans',
  no_valid_modifications = 'no_valid_modifications',
  skipped_by_embed_configuration = 'skipped_by_embed_configuration',
  subscriber_has_active_offer = 'subscriber_has_active_offer',
  subscriber_is_already_on_plan = 'subscriber_is_already_on_plan',
  subscriber_is_past_due = 'subscriber_is_past_due',
  subscription_cannot_be_paused = 'subscription_cannot_be_paused',
  subscription_has_coupon = 'subscription_has_coupon',
  subscription_has_multiple_plans = 'subscription_has_multiple_plans',
  subscription_is_already_on_plan = 'subscription_is_already_on_plan',
  subscription_is_canceled = 'subscription_is_canceled',
  subscription_is_future = 'subscription_is_future',
  subscription_is_not_trial = 'subscription_is_not_trial',
  subscription_is_past_due = 'subscription_is_past_due',
  subscription_is_paused = 'subscription_is_paused',
  subscription_is_trial = 'subscription_is_trial',
  subscription_plan_has_no_swappable_products = 'subscription_plan_has_no_swappable_products',
  subscription_plan_is_incompatible = 'subscription_plan_is_incompatible',
  subscription_plan_swappable_products_are_incompatible = 'subscription_plan_swappable_products_are_incompatible',
  subscription_will_not_renew = 'subscription_will_not_renew'
}

/** Boolean expression to compare columns of type "subscriber_flow_override_reason_enum". All fields are combined with logical 'AND'. */
export type subscriber_flow_override_reason_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_flow_override_reason_enum>;
  _in?: InputMaybe<Array<subscriber_flow_override_reason_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_flow_override_reason_enum>;
  _nin?: InputMaybe<Array<subscriber_flow_override_reason_enum>>;
};

/** input type for inserting data into table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_insert_input = {
  subscriber_flow_overrides?: InputMaybe<subscriber_flow_override_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_override_reason_max_fields = {
  __typename: 'subscriber_flow_override_reason_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_override_reason_min_fields = {
  __typename: 'subscriber_flow_override_reason_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_mutation_response = {
  __typename: 'subscriber_flow_override_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_override_reason>;
};

/** input type for inserting object relation for remote table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_obj_rel_insert_input = {
  data: subscriber_flow_override_reason_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_override_reason_on_conflict>;
};

/** on_conflict condition type for table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_on_conflict = {
  constraint: subscriber_flow_override_reason_constraint;
  update_columns?: Array<subscriber_flow_override_reason_update_column>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_override_reason". */
export type subscriber_flow_override_reason_order_by = {
  subscriber_flow_overrides_aggregate?: InputMaybe<subscriber_flow_override_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_override_reason */
export type subscriber_flow_override_reason_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_flow_override_reason" */
export enum subscriber_flow_override_reason_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_flow_override_reason" */
export type subscriber_flow_override_reason_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_override_reason_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_override_reason_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_flow_override_reason" */
export enum subscriber_flow_override_reason_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_flow_override_reason_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_override_reason_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_override_reason_bool_exp;
};

/** select columns of table "subscriber_flow_override" */
export enum subscriber_flow_override_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_override" */
export type subscriber_flow_override_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_override_stddev_fields = {
  __typename: 'subscriber_flow_override_stddev_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_stddev_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_override_stddev_pop_fields = {
  __typename: 'subscriber_flow_override_stddev_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_stddev_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_override_stddev_samp_fields = {
  __typename: 'subscriber_flow_override_stddev_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_stddev_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_override" */
export type subscriber_flow_override_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_override_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_override_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  reason?: InputMaybe<subscriber_flow_override_reason_enum>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_override_sum_fields = {
  __typename: 'subscriber_flow_override_sum_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_sum_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_override" */
export enum subscriber_flow_override_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  reason = 'reason',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_override_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_override_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_override_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_override_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_override_var_pop_fields = {
  __typename: 'subscriber_flow_override_var_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_var_pop_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_override_var_samp_fields = {
  __typename: 'subscriber_flow_override_var_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_var_samp_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_override_variance_fields = {
  __typename: 'subscriber_flow_override_variance_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_override" */
export type subscriber_flow_override_variance_order_by = {
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow */
export type subscriber_flow_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscriber_flow_prepend_input = {
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  segment_values?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer = {
  __typename: 'subscriber_flow_question_answer';
  created_at: Scalars['timestamptz']['output'];
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  question_answer: question_answer;
  question_answer_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  /** An object relationship */
  subscriber_flow_flow_step?: Maybe<subscriber_flow_flow_step>;
  subscriber_flow_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_aggregate = {
  __typename: 'subscriber_flow_question_answer_aggregate';
  aggregate?: Maybe<subscriber_flow_question_answer_aggregate_fields>;
  nodes: Array<subscriber_flow_question_answer>;
};

export type subscriber_flow_question_answer_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_question_answer_aggregate_bool_exp_count>;
};

export type subscriber_flow_question_answer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_aggregate_fields = {
  __typename: 'subscriber_flow_question_answer_aggregate_fields';
  avg?: Maybe<subscriber_flow_question_answer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_question_answer_max_fields>;
  min?: Maybe<subscriber_flow_question_answer_min_fields>;
  stddev?: Maybe<subscriber_flow_question_answer_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_question_answer_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_question_answer_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_question_answer_sum_fields>;
  var_pop?: Maybe<subscriber_flow_question_answer_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_question_answer_var_samp_fields>;
  variance?: Maybe<subscriber_flow_question_answer_variance_fields>;
};


/** aggregate fields of "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_question_answer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_question_answer_max_order_by>;
  min?: InputMaybe<subscriber_flow_question_answer_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_question_answer_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_question_answer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_question_answer_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_question_answer_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_question_answer_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_question_answer_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_question_answer_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_arr_rel_insert_input = {
  data: Array<subscriber_flow_question_answer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_question_answer_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_question_answer_avg_fields = {
  __typename: 'subscriber_flow_question_answer_avg_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_avg_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_question_answer". All fields are combined with a logical 'AND'. */
export type subscriber_flow_question_answer_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_question_answer_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_question_answer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_step_id?: InputMaybe<Int_comparison_exp>;
  question_answer?: InputMaybe<question_answer_bool_exp>;
  question_answer_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_question_answer" */
export enum subscriber_flow_question_answer_constraint {
  /** unique or primary key constraint on columns "subscriber_flow_id", "question_answer_id" */
  subscriber_flow_question_answer_pkey = 'subscriber_flow_question_answer_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_inc_input = {
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_answer?: InputMaybe<question_answer_obj_rel_insert_input>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_question_answer_max_fields = {
  __typename: 'subscriber_flow_question_answer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_question_answer_min_fields = {
  __typename: 'subscriber_flow_question_answer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_mutation_response = {
  __typename: 'subscriber_flow_question_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_question_answer>;
};

/** on_conflict condition type for table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_on_conflict = {
  constraint: subscriber_flow_question_answer_constraint;
  update_columns?: Array<subscriber_flow_question_answer_update_column>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_question_answer". */
export type subscriber_flow_question_answer_order_by = {
  created_at?: InputMaybe<order_by>;
  flow_step_id?: InputMaybe<order_by>;
  question_answer?: InputMaybe<question_answer_order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_flow_step?: InputMaybe<subscriber_flow_flow_step_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_question_answer */
export type subscriber_flow_question_answer_pk_columns_input = {
  question_answer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_question_answer" */
export enum subscriber_flow_question_answer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_question_answer_stddev_fields = {
  __typename: 'subscriber_flow_question_answer_stddev_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_stddev_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_question_answer_stddev_pop_fields = {
  __typename: 'subscriber_flow_question_answer_stddev_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_stddev_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_question_answer_stddev_samp_fields = {
  __typename: 'subscriber_flow_question_answer_stddev_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_stddev_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_question_answer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_question_answer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_step_id?: InputMaybe<Scalars['Int']['input']>;
  question_answer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_question_answer_sum_fields = {
  __typename: 'subscriber_flow_question_answer_sum_fields';
  flow_step_id?: Maybe<Scalars['Int']['output']>;
  question_answer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_sum_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_question_answer" */
export enum subscriber_flow_question_answer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  flow_step_id = 'flow_step_id',
  /** column name */
  question_answer_id = 'question_answer_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_question_answer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_question_answer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_question_answer_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_question_answer_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_question_answer_var_pop_fields = {
  __typename: 'subscriber_flow_question_answer_var_pop_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_var_pop_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_question_answer_var_samp_fields = {
  __typename: 'subscriber_flow_question_answer_var_samp_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_var_samp_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_question_answer_variance_fields = {
  __typename: 'subscriber_flow_question_answer_variance_fields';
  flow_step_id?: Maybe<Scalars['Float']['output']>;
  question_answer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_question_answer" */
export type subscriber_flow_question_answer_variance_order_by = {
  flow_step_id?: InputMaybe<order_by>;
  question_answer_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_flow_segment" */
export type subscriber_flow_segment = {
  __typename: 'subscriber_flow_segment';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  segment: segment;
  segment_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_flow_segment" */
export type subscriber_flow_segment_aggregate = {
  __typename: 'subscriber_flow_segment_aggregate';
  aggregate?: Maybe<subscriber_flow_segment_aggregate_fields>;
  nodes: Array<subscriber_flow_segment>;
};

export type subscriber_flow_segment_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_flow_segment_aggregate_bool_exp_count>;
};

export type subscriber_flow_segment_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_flow_segment_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_flow_segment" */
export type subscriber_flow_segment_aggregate_fields = {
  __typename: 'subscriber_flow_segment_aggregate_fields';
  avg?: Maybe<subscriber_flow_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_segment_max_fields>;
  min?: Maybe<subscriber_flow_segment_min_fields>;
  stddev?: Maybe<subscriber_flow_segment_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_segment_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_segment_sum_fields>;
  var_pop?: Maybe<subscriber_flow_segment_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_segment_var_samp_fields>;
  variance?: Maybe<subscriber_flow_segment_variance_fields>;
};


/** aggregate fields of "subscriber_flow_segment" */
export type subscriber_flow_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_flow_segment" */
export type subscriber_flow_segment_aggregate_order_by = {
  avg?: InputMaybe<subscriber_flow_segment_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_flow_segment_max_order_by>;
  min?: InputMaybe<subscriber_flow_segment_min_order_by>;
  stddev?: InputMaybe<subscriber_flow_segment_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_flow_segment_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_flow_segment_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_flow_segment_sum_order_by>;
  var_pop?: InputMaybe<subscriber_flow_segment_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_flow_segment_var_samp_order_by>;
  variance?: InputMaybe<subscriber_flow_segment_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_flow_segment" */
export type subscriber_flow_segment_arr_rel_insert_input = {
  data: Array<subscriber_flow_segment_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_segment_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_flow_segment_avg_fields = {
  __typename: 'subscriber_flow_segment_avg_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_avg_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_segment". All fields are combined with a logical 'AND'. */
export type subscriber_flow_segment_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_segment_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_segment_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_segment_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_segment" */
export enum subscriber_flow_segment_constraint {
  /** unique or primary key constraint on columns "subscriber_flow_id", "segment_id" */
  subscriber_flow_segment_pkey = 'subscriber_flow_segment_pkey'
}

/** columns and relationships of "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group = {
  __typename: 'subscriber_flow_segment_group';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  segment_group: segment_group;
  segment_group_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_flow: subscriber_flow;
  subscriber_flow_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_aggregate = {
  __typename: 'subscriber_flow_segment_group_aggregate';
  aggregate?: Maybe<subscriber_flow_segment_group_aggregate_fields>;
  nodes: Array<subscriber_flow_segment_group>;
};

/** aggregate fields of "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_aggregate_fields = {
  __typename: 'subscriber_flow_segment_group_aggregate_fields';
  avg?: Maybe<subscriber_flow_segment_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_segment_group_max_fields>;
  min?: Maybe<subscriber_flow_segment_group_min_fields>;
  stddev?: Maybe<subscriber_flow_segment_group_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_segment_group_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_segment_group_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_segment_group_sum_fields>;
  var_pop?: Maybe<subscriber_flow_segment_group_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_segment_group_var_samp_fields>;
  variance?: Maybe<subscriber_flow_segment_group_variance_fields>;
};


/** aggregate fields of "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_segment_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_segment_group_avg_fields = {
  __typename: 'subscriber_flow_segment_group_avg_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_segment_group". All fields are combined with a logical 'AND'. */
export type subscriber_flow_segment_group_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_segment_group_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_segment_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  segment_group?: InputMaybe<segment_group_bool_exp>;
  segment_group_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_flow_segment_group" */
export enum subscriber_flow_segment_group_constraint {
  /** unique or primary key constraint on columns "segment_group_id", "subscriber_flow_id" */
  subscriber_flow_segment_group_pkey = 'subscriber_flow_segment_group_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_inc_input = {
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_group?: InputMaybe<segment_group_obj_rel_insert_input>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_segment_group_max_fields = {
  __typename: 'subscriber_flow_segment_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_segment_group_min_fields = {
  __typename: 'subscriber_flow_segment_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_mutation_response = {
  __typename: 'subscriber_flow_segment_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_segment_group>;
};

/** on_conflict condition type for table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_on_conflict = {
  constraint: subscriber_flow_segment_group_constraint;
  update_columns?: Array<subscriber_flow_segment_group_update_column>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_segment_group". */
export type subscriber_flow_segment_group_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_group?: InputMaybe<segment_group_order_by>;
  segment_group_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_segment_group */
export type subscriber_flow_segment_group_pk_columns_input = {
  segment_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_segment_group" */
export enum subscriber_flow_segment_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_group_id = 'segment_group_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_segment_group_stddev_fields = {
  __typename: 'subscriber_flow_segment_group_stddev_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_segment_group_stddev_pop_fields = {
  __typename: 'subscriber_flow_segment_group_stddev_pop_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_segment_group_stddev_samp_fields = {
  __typename: 'subscriber_flow_segment_group_stddev_samp_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_segment_group" */
export type subscriber_flow_segment_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_segment_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_segment_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_segment_group_sum_fields = {
  __typename: 'subscriber_flow_segment_group_sum_fields';
  segment_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscriber_flow_segment_group" */
export enum subscriber_flow_segment_group_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_group_id = 'segment_group_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_segment_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_segment_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_segment_group_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_segment_group_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_segment_group_var_pop_fields = {
  __typename: 'subscriber_flow_segment_group_var_pop_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_segment_group_var_samp_fields = {
  __typename: 'subscriber_flow_segment_group_var_samp_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_segment_group_variance_fields = {
  __typename: 'subscriber_flow_segment_group_variance_fields';
  segment_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "subscriber_flow_segment" */
export type subscriber_flow_segment_inc_input = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_flow_segment" */
export type subscriber_flow_segment_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_segment_max_fields = {
  __typename: 'subscriber_flow_segment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_max_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_flow_segment_min_fields = {
  __typename: 'subscriber_flow_segment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_min_order_by = {
  created_at?: InputMaybe<order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_flow_segment" */
export type subscriber_flow_segment_mutation_response = {
  __typename: 'subscriber_flow_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_segment>;
};

/** on_conflict condition type for table "subscriber_flow_segment" */
export type subscriber_flow_segment_on_conflict = {
  constraint: subscriber_flow_segment_constraint;
  update_columns?: Array<subscriber_flow_segment_update_column>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_segment". */
export type subscriber_flow_segment_order_by = {
  created_at?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_segment */
export type subscriber_flow_segment_pk_columns_input = {
  segment_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_flow_segment" */
export enum subscriber_flow_segment_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

/** input type for updating data in table "subscriber_flow_segment" */
export type subscriber_flow_segment_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_flow_segment_stddev_fields = {
  __typename: 'subscriber_flow_segment_stddev_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_stddev_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_segment_stddev_pop_fields = {
  __typename: 'subscriber_flow_segment_stddev_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_stddev_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_segment_stddev_samp_fields = {
  __typename: 'subscriber_flow_segment_stddev_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_stddev_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow_segment" */
export type subscriber_flow_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_segment_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_segment_sum_fields = {
  __typename: 'subscriber_flow_segment_sum_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_sum_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_flow_segment" */
export enum subscriber_flow_segment_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id'
}

export type subscriber_flow_segment_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_segment_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_segment_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_segment_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_flow_segment_var_pop_fields = {
  __typename: 'subscriber_flow_segment_var_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_var_pop_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_segment_var_samp_fields = {
  __typename: 'subscriber_flow_segment_var_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_var_samp_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_segment_variance_fields = {
  __typename: 'subscriber_flow_segment_variance_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow_segment" */
export type subscriber_flow_segment_variance_order_by = {
  segment_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow" */
export enum subscriber_flow_select_column {
  /** column name */
  automatically_deflected = 'automatically_deflected',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  eligibility_message_id = 'eligibility_message_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  payload = 'payload',
  /** column name */
  reroute_allowed = 'reroute_allowed',
  /** column name */
  rerouted_from_subscriber_flow_id = 'rerouted_from_subscriber_flow_id',
  /** column name */
  segment_values = 'segment_values',
  /** column name */
  signed = 'signed',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  token = 'token',
  /** column name */
  trial = 'trial',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
  /** column name */
  user_agent = 'user_agent',
  /** column name */
  version = 'version'
}

/** select "subscriber_flow_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriber_flow" */
export enum subscriber_flow_select_column_subscriber_flow_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  automatically_deflected = 'automatically_deflected',
  /** column name */
  reroute_allowed = 'reroute_allowed',
  /** column name */
  signed = 'signed',
  /** column name */
  trial = 'trial'
}

/** select "subscriber_flow_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriber_flow" */
export enum subscriber_flow_select_column_subscriber_flow_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  automatically_deflected = 'automatically_deflected',
  /** column name */
  reroute_allowed = 'reroute_allowed',
  /** column name */
  signed = 'signed',
  /** column name */
  trial = 'trial'
}

/** input type for updating data in table "subscriber_flow" */
export type subscriber_flow_set_input = {
  automatically_deflected?: InputMaybe<Scalars['Boolean']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  reroute_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  rerouted_from_subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  segment_values?: InputMaybe<Scalars['jsonb']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<subscriber_flow_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "subscriber_flow_status" */
export type subscriber_flow_status = {
  __typename: 'subscriber_flow_status';
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "subscriber_flow_status" */
export type subscriber_flow_statussubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "subscriber_flow_status" */
export type subscriber_flow_statussubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};

/** aggregated selection of "subscriber_flow_status" */
export type subscriber_flow_status_aggregate = {
  __typename: 'subscriber_flow_status_aggregate';
  aggregate?: Maybe<subscriber_flow_status_aggregate_fields>;
  nodes: Array<subscriber_flow_status>;
};

/** aggregate fields of "subscriber_flow_status" */
export type subscriber_flow_status_aggregate_fields = {
  __typename: 'subscriber_flow_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_status_max_fields>;
  min?: Maybe<subscriber_flow_status_min_fields>;
};


/** aggregate fields of "subscriber_flow_status" */
export type subscriber_flow_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_status". All fields are combined with a logical 'AND'. */
export type subscriber_flow_status_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_status_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_status_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_status_bool_exp>>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** columns and relationships of "subscriber_flow_status_by_month" */
export type subscriber_flow_status_by_month = {
  __typename: 'subscriber_flow_status_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriber_flow_status_by_month" */
export type subscriber_flow_status_by_month_aggregate = {
  __typename: 'subscriber_flow_status_by_month_aggregate';
  aggregate?: Maybe<subscriber_flow_status_by_month_aggregate_fields>;
  nodes: Array<subscriber_flow_status_by_month>;
};

/** aggregate fields of "subscriber_flow_status_by_month" */
export type subscriber_flow_status_by_month_aggregate_fields = {
  __typename: 'subscriber_flow_status_by_month_aggregate_fields';
  avg?: Maybe<subscriber_flow_status_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_status_by_month_max_fields>;
  min?: Maybe<subscriber_flow_status_by_month_min_fields>;
  stddev?: Maybe<subscriber_flow_status_by_month_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_status_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_status_by_month_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_status_by_month_sum_fields>;
  var_pop?: Maybe<subscriber_flow_status_by_month_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_status_by_month_var_samp_fields>;
  variance?: Maybe<subscriber_flow_status_by_month_variance_fields>;
};


/** aggregate fields of "subscriber_flow_status_by_month" */
export type subscriber_flow_status_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_status_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_status_by_month_avg_fields = {
  __typename: 'subscriber_flow_status_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_status_by_month". All fields are combined with a logical 'AND'. */
export type subscriber_flow_status_by_month_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_status_by_month_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_status_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<bigint_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_flow_status_by_month_max_fields = {
  __typename: 'subscriber_flow_status_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_status_by_month_min_fields = {
  __typename: 'subscriber_flow_status_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscriber_flow_status_by_month". */
export type subscriber_flow_status_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow_status_by_month" */
export enum subscriber_flow_status_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  month = 'month',
  /** column name */
  status = 'status'
}

/** aggregate stddev on columns */
export type subscriber_flow_status_by_month_stddev_fields = {
  __typename: 'subscriber_flow_status_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_status_by_month_stddev_pop_fields = {
  __typename: 'subscriber_flow_status_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_status_by_month_stddev_samp_fields = {
  __typename: 'subscriber_flow_status_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_status_by_month" */
export type subscriber_flow_status_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_status_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_status_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['bigint']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_status_by_month_sum_fields = {
  __typename: 'subscriber_flow_status_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_flow_status_by_month_var_pop_fields = {
  __typename: 'subscriber_flow_status_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_status_by_month_var_samp_fields = {
  __typename: 'subscriber_flow_status_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_status_by_month_variance_fields = {
  __typename: 'subscriber_flow_status_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "subscriber_flow_status" */
export enum subscriber_flow_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_flow_status_pkey = 'subscriber_flow_status_pkey'
}

export enum subscriber_flow_status_enum {
  aborted = 'aborted',
  canceled = 'canceled',
  created = 'created',
  deflected = 'deflected',
  in_progress = 'in_progress',
  incomplete = 'incomplete',
  prevented = 'prevented',
  rerouted = 'rerouted',
  saved = 'saved'
}

/** Boolean expression to compare columns of type "subscriber_flow_status_enum". All fields are combined with logical 'AND'. */
export type subscriber_flow_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_flow_status_enum>;
  _in?: InputMaybe<Array<subscriber_flow_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_flow_status_enum>;
  _nin?: InputMaybe<Array<subscriber_flow_status_enum>>;
};

/** input type for inserting data into table "subscriber_flow_status" */
export type subscriber_flow_status_insert_input = {
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_status_max_fields = {
  __typename: 'subscriber_flow_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_status_min_fields = {
  __typename: 'subscriber_flow_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_flow_status" */
export type subscriber_flow_status_mutation_response = {
  __typename: 'subscriber_flow_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_flow_status>;
};

/** input type for inserting object relation for remote table "subscriber_flow_status" */
export type subscriber_flow_status_obj_rel_insert_input = {
  data: subscriber_flow_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_flow_status_on_conflict>;
};

/** on_conflict condition type for table "subscriber_flow_status" */
export type subscriber_flow_status_on_conflict = {
  constraint: subscriber_flow_status_constraint;
  update_columns?: Array<subscriber_flow_status_update_column>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_flow_status". */
export type subscriber_flow_status_order_by = {
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_flow_status */
export type subscriber_flow_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_flow_status" */
export enum subscriber_flow_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_flow_status" */
export type subscriber_flow_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_flow_status" */
export type subscriber_flow_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_flow_status" */
export enum subscriber_flow_status_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_flow_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscriber_flow_stddev_fields = {
  __typename: 'subscriber_flow_stddev_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_flow" */
export type subscriber_flow_stddev_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_stddev_pop_fields = {
  __typename: 'subscriber_flow_stddev_pop_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_flow" */
export type subscriber_flow_stddev_pop_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_stddev_samp_fields = {
  __typename: 'subscriber_flow_stddev_samp_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_flow" */
export type subscriber_flow_stddev_samp_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_flow" */
export type subscriber_flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_stream_cursor_value_input = {
  automatically_deflected?: InputMaybe<Scalars['Boolean']['input']>;
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  eligibility_message_id?: InputMaybe<Scalars['Int']['input']>;
  flow_id?: InputMaybe<Scalars['Int']['input']>;
  flow_test_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<language_enum>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  payload?: InputMaybe<Scalars['jsonb']['input']>;
  reroute_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  rerouted_from_subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  segment_values?: InputMaybe<Scalars['jsonb']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  started_at?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<subscriber_flow_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_agent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_sum_fields = {
  __typename: 'subscriber_flow_sum_fields';
  eligibility_message_id?: Maybe<Scalars['Int']['output']>;
  flow_id?: Maybe<Scalars['Int']['output']>;
  flow_test_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_flow" */
export type subscriber_flow_sum_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_flow_tsvector" */
export type subscriber_flow_tsvector = {
  __typename: 'subscriber_flow_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "subscriber_flow_tsvector" */
export type subscriber_flow_tsvector_aggregate = {
  __typename: 'subscriber_flow_tsvector_aggregate';
  aggregate?: Maybe<subscriber_flow_tsvector_aggregate_fields>;
  nodes: Array<subscriber_flow_tsvector>;
};

/** aggregate fields of "subscriber_flow_tsvector" */
export type subscriber_flow_tsvector_aggregate_fields = {
  __typename: 'subscriber_flow_tsvector_aggregate_fields';
  avg?: Maybe<subscriber_flow_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_tsvector_max_fields>;
  min?: Maybe<subscriber_flow_tsvector_min_fields>;
  stddev?: Maybe<subscriber_flow_tsvector_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_tsvector_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_tsvector_sum_fields>;
  var_pop?: Maybe<subscriber_flow_tsvector_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_tsvector_var_samp_fields>;
  variance?: Maybe<subscriber_flow_tsvector_variance_fields>;
};


/** aggregate fields of "subscriber_flow_tsvector" */
export type subscriber_flow_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_tsvector_avg_fields = {
  __typename: 'subscriber_flow_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_tsvector". All fields are combined with a logical 'AND'. */
export type subscriber_flow_tsvector_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_tsvector_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_flow_tsvector_max_fields = {
  __typename: 'subscriber_flow_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_tsvector_min_fields = {
  __typename: 'subscriber_flow_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "subscriber_flow_tsvector". */
export type subscriber_flow_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow_tsvector" */
export enum subscriber_flow_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type subscriber_flow_tsvector_stddev_fields = {
  __typename: 'subscriber_flow_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_tsvector_stddev_pop_fields = {
  __typename: 'subscriber_flow_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_tsvector_stddev_samp_fields = {
  __typename: 'subscriber_flow_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_tsvector" */
export type subscriber_flow_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_tsvector_sum_fields = {
  __typename: 'subscriber_flow_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_flow_tsvector_var_pop_fields = {
  __typename: 'subscriber_flow_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_tsvector_var_samp_fields = {
  __typename: 'subscriber_flow_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_tsvector_variance_fields = {
  __typename: 'subscriber_flow_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "subscriber_flow" */
export enum subscriber_flow_update_column {
  /** column name */
  automatically_deflected = 'automatically_deflected',
  /** column name */
  completed_at = 'completed_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  eligibility_message_id = 'eligibility_message_id',
  /** column name */
  flow_id = 'flow_id',
  /** column name */
  flow_test_id = 'flow_test_id',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  payload = 'payload',
  /** column name */
  reroute_allowed = 'reroute_allowed',
  /** column name */
  rerouted_from_subscriber_flow_id = 'rerouted_from_subscriber_flow_id',
  /** column name */
  segment_values = 'segment_values',
  /** column name */
  signed = 'signed',
  /** column name */
  started_at = 'started_at',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  token = 'token',
  /** column name */
  trial = 'trial',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
  /** column name */
  user_agent = 'user_agent',
  /** column name */
  version = 'version'
}

export type subscriber_flow_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscriber_flow_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscriber_flow_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscriber_flow_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscriber_flow_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_flow_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscriber_flow_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_flow_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_flow_bool_exp;
};

/** columns and relationships of "subscriber_flow_url" */
export type subscriber_flow_url = {
  __typename: 'subscriber_flow_url';
  domain?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  subscriber_flow?: Maybe<subscriber_flow>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriber_flow_url" */
export type subscriber_flow_url_aggregate = {
  __typename: 'subscriber_flow_url_aggregate';
  aggregate?: Maybe<subscriber_flow_url_aggregate_fields>;
  nodes: Array<subscriber_flow_url>;
};

/** aggregate fields of "subscriber_flow_url" */
export type subscriber_flow_url_aggregate_fields = {
  __typename: 'subscriber_flow_url_aggregate_fields';
  avg?: Maybe<subscriber_flow_url_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_url_max_fields>;
  min?: Maybe<subscriber_flow_url_min_fields>;
  stddev?: Maybe<subscriber_flow_url_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_url_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_url_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_url_sum_fields>;
  var_pop?: Maybe<subscriber_flow_url_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_url_var_samp_fields>;
  variance?: Maybe<subscriber_flow_url_variance_fields>;
};


/** aggregate fields of "subscriber_flow_url" */
export type subscriber_flow_url_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_url_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_url_avg_fields = {
  __typename: 'subscriber_flow_url_avg_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_url". All fields are combined with a logical 'AND'. */
export type subscriber_flow_url_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_url_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_url_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_url_bool_exp>>;
  domain?: InputMaybe<String_comparison_exp>;
  host?: InputMaybe<String_comparison_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
};

/** input type for inserting data into table "subscriber_flow_url" */
export type subscriber_flow_url_insert_input = {
  domain?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_flow_url_max_fields = {
  __typename: 'subscriber_flow_url_max_fields';
  domain?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_url_min_fields = {
  __typename: 'subscriber_flow_url_min_fields';
  domain?: Maybe<Scalars['String']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "subscriber_flow_url" */
export type subscriber_flow_url_obj_rel_insert_input = {
  data: subscriber_flow_url_insert_input;
};

/** Ordering options when selecting data from "subscriber_flow_url". */
export type subscriber_flow_url_order_by = {
  domain?: InputMaybe<order_by>;
  host?: InputMaybe<order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow_url" */
export enum subscriber_flow_url_select_column {
  /** column name */
  domain = 'domain',
  /** column name */
  host = 'host',
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  url = 'url'
}

/** aggregate stddev on columns */
export type subscriber_flow_url_stddev_fields = {
  __typename: 'subscriber_flow_url_stddev_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_url_stddev_pop_fields = {
  __typename: 'subscriber_flow_url_stddev_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_url_stddev_samp_fields = {
  __typename: 'subscriber_flow_url_stddev_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_url" */
export type subscriber_flow_url_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_url_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_url_stream_cursor_value_input = {
  domain?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_url_sum_fields = {
  __typename: 'subscriber_flow_url_sum_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_flow_url_var_pop_fields = {
  __typename: 'subscriber_flow_url_var_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_url_var_samp_fields = {
  __typename: 'subscriber_flow_url_var_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_url_variance_fields = {
  __typename: 'subscriber_flow_url_variance_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_flow_var_pop_fields = {
  __typename: 'subscriber_flow_var_pop_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_flow" */
export type subscriber_flow_var_pop_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_var_samp_fields = {
  __typename: 'subscriber_flow_var_samp_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_flow" */
export type subscriber_flow_var_samp_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_flow_variance_fields = {
  __typename: 'subscriber_flow_variance_fields';
  eligibility_message_id?: Maybe<Scalars['Float']['output']>;
  flow_id?: Maybe<Scalars['Float']['output']>;
  flow_test_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  rerouted_from_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_flow" */
export type subscriber_flow_variance_order_by = {
  eligibility_message_id?: InputMaybe<order_by>;
  flow_id?: InputMaybe<order_by>;
  flow_test_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  rerouted_from_subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_flow_version_last_60_days" */
export type subscriber_flow_version_last_60_days = {
  __typename: 'subscriber_flow_version_last_60_days';
  count?: Maybe<Scalars['bigint']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriber_flow_version_last_60_days" */
export type subscriber_flow_version_last_60_days_aggregate = {
  __typename: 'subscriber_flow_version_last_60_days_aggregate';
  aggregate?: Maybe<subscriber_flow_version_last_60_days_aggregate_fields>;
  nodes: Array<subscriber_flow_version_last_60_days>;
};

/** aggregate fields of "subscriber_flow_version_last_60_days" */
export type subscriber_flow_version_last_60_days_aggregate_fields = {
  __typename: 'subscriber_flow_version_last_60_days_aggregate_fields';
  avg?: Maybe<subscriber_flow_version_last_60_days_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_flow_version_last_60_days_max_fields>;
  min?: Maybe<subscriber_flow_version_last_60_days_min_fields>;
  stddev?: Maybe<subscriber_flow_version_last_60_days_stddev_fields>;
  stddev_pop?: Maybe<subscriber_flow_version_last_60_days_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_flow_version_last_60_days_stddev_samp_fields>;
  sum?: Maybe<subscriber_flow_version_last_60_days_sum_fields>;
  var_pop?: Maybe<subscriber_flow_version_last_60_days_var_pop_fields>;
  var_samp?: Maybe<subscriber_flow_version_last_60_days_var_samp_fields>;
  variance?: Maybe<subscriber_flow_version_last_60_days_variance_fields>;
};


/** aggregate fields of "subscriber_flow_version_last_60_days" */
export type subscriber_flow_version_last_60_days_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_flow_version_last_60_days_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_flow_version_last_60_days_avg_fields = {
  __typename: 'subscriber_flow_version_last_60_days_avg_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_flow_version_last_60_days". All fields are combined with a logical 'AND'. */
export type subscriber_flow_version_last_60_days_bool_exp = {
  _and?: InputMaybe<Array<subscriber_flow_version_last_60_days_bool_exp>>;
  _not?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
  _or?: InputMaybe<Array<subscriber_flow_version_last_60_days_bool_exp>>;
  count?: InputMaybe<bigint_comparison_exp>;
  version?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_flow_version_last_60_days_max_fields = {
  __typename: 'subscriber_flow_version_last_60_days_max_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_flow_version_last_60_days_min_fields = {
  __typename: 'subscriber_flow_version_last_60_days_min_fields';
  count?: Maybe<Scalars['bigint']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscriber_flow_version_last_60_days". */
export type subscriber_flow_version_last_60_days_order_by = {
  count?: InputMaybe<order_by>;
  version?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_flow_version_last_60_days" */
export enum subscriber_flow_version_last_60_days_select_column {
  /** column name */
  count = 'count',
  /** column name */
  version = 'version'
}

/** aggregate stddev on columns */
export type subscriber_flow_version_last_60_days_stddev_fields = {
  __typename: 'subscriber_flow_version_last_60_days_stddev_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_flow_version_last_60_days_stddev_pop_fields = {
  __typename: 'subscriber_flow_version_last_60_days_stddev_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_flow_version_last_60_days_stddev_samp_fields = {
  __typename: 'subscriber_flow_version_last_60_days_stddev_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_flow_version_last_60_days" */
export type subscriber_flow_version_last_60_days_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_flow_version_last_60_days_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_flow_version_last_60_days_stream_cursor_value_input = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_flow_version_last_60_days_sum_fields = {
  __typename: 'subscriber_flow_version_last_60_days_sum_fields';
  count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_flow_version_last_60_days_var_pop_fields = {
  __typename: 'subscriber_flow_version_last_60_days_var_pop_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_flow_version_last_60_days_var_samp_fields = {
  __typename: 'subscriber_flow_version_last_60_days_var_samp_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_flow_version_last_60_days_variance_fields = {
  __typename: 'subscriber_flow_version_last_60_days_variance_fields';
  count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber_grid" */
export type subscriber_grid = {
  __typename: 'subscriber_grid';
  account_id?: Maybe<Scalars['Int']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  last_subscriber_campaign_view?: Maybe<subscriber_campaign_view>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_campaign_view_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_campaign_view_status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  last_subscriber_flow?: Maybe<subscriber_flow>;
  last_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_flow_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_flow_status?: Maybe<Scalars['String']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  token?: Maybe<Scalars['String']['output']>;
};

export type subscriber_grid_aggregate = {
  __typename: 'subscriber_grid_aggregate';
  aggregate?: Maybe<subscriber_grid_aggregate_fields>;
  nodes: Array<subscriber_grid>;
};

/** aggregate fields of "subscriber_grid" */
export type subscriber_grid_aggregate_fields = {
  __typename: 'subscriber_grid_aggregate_fields';
  avg?: Maybe<subscriber_grid_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_grid_max_fields>;
  min?: Maybe<subscriber_grid_min_fields>;
  stddev?: Maybe<subscriber_grid_stddev_fields>;
  stddev_pop?: Maybe<subscriber_grid_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_grid_stddev_samp_fields>;
  sum?: Maybe<subscriber_grid_sum_fields>;
  var_pop?: Maybe<subscriber_grid_var_pop_fields>;
  var_samp?: Maybe<subscriber_grid_var_samp_fields>;
  variance?: Maybe<subscriber_grid_variance_fields>;
};


/** aggregate fields of "subscriber_grid" */
export type subscriber_grid_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_grid_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_grid_avg_fields = {
  __typename: 'subscriber_grid_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_grid". All fields are combined with a logical 'AND'. */
export type subscriber_grid_bool_exp = {
  _and?: InputMaybe<Array<subscriber_grid_bool_exp>>;
  _not?: InputMaybe<subscriber_grid_bool_exp>;
  _or?: InputMaybe<Array<subscriber_grid_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  display_name?: InputMaybe<String_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  last_seen_at?: InputMaybe<timestamptz_comparison_exp>;
  last_subscriber_campaign_view?: InputMaybe<subscriber_campaign_view_bool_exp>;
  last_subscriber_campaign_view_id?: InputMaybe<Int_comparison_exp>;
  last_subscriber_campaign_view_seen_at?: InputMaybe<timestamptz_comparison_exp>;
  last_subscriber_campaign_view_status?: InputMaybe<String_comparison_exp>;
  last_subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  last_subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  last_subscriber_flow_seen_at?: InputMaybe<timestamptz_comparison_exp>;
  last_subscriber_flow_status?: InputMaybe<String_comparison_exp>;
  last_subscriber_likelihood_to_return?: InputMaybe<Int_comparison_exp>;
  mrr?: InputMaybe<numeric_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<String_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_grid_max_fields = {
  __typename: 'subscriber_grid_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_campaign_view_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_campaign_view_status?: Maybe<Scalars['String']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_flow_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_flow_status?: Maybe<Scalars['String']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_grid_min_fields = {
  __typename: 'subscriber_grid_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_campaign_view_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_campaign_view_status?: Maybe<Scalars['String']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_flow_seen_at?: Maybe<Scalars['timestamptz']['output']>;
  last_subscriber_flow_status?: Maybe<Scalars['String']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscriber_grid". */
export type subscriber_grid_order_by = {
  account_id?: InputMaybe<order_by>;
  display_name?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_seen_at?: InputMaybe<order_by>;
  last_subscriber_campaign_view?: InputMaybe<subscriber_campaign_view_order_by>;
  last_subscriber_campaign_view_id?: InputMaybe<order_by>;
  last_subscriber_campaign_view_seen_at?: InputMaybe<order_by>;
  last_subscriber_campaign_view_status?: InputMaybe<order_by>;
  last_subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  last_subscriber_flow_id?: InputMaybe<order_by>;
  last_subscriber_flow_seen_at?: InputMaybe<order_by>;
  last_subscriber_flow_status?: InputMaybe<order_by>;
  last_subscriber_likelihood_to_return?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  token?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_grid" */
export enum subscriber_grid_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  display_name = 'display_name',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  last_seen_at = 'last_seen_at',
  /** column name */
  last_subscriber_campaign_view_id = 'last_subscriber_campaign_view_id',
  /** column name */
  last_subscriber_campaign_view_seen_at = 'last_subscriber_campaign_view_seen_at',
  /** column name */
  last_subscriber_campaign_view_status = 'last_subscriber_campaign_view_status',
  /** column name */
  last_subscriber_flow_id = 'last_subscriber_flow_id',
  /** column name */
  last_subscriber_flow_seen_at = 'last_subscriber_flow_seen_at',
  /** column name */
  last_subscriber_flow_status = 'last_subscriber_flow_status',
  /** column name */
  last_subscriber_likelihood_to_return = 'last_subscriber_likelihood_to_return',
  /** column name */
  mrr = 'mrr',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token'
}

/** aggregate stddev on columns */
export type subscriber_grid_stddev_fields = {
  __typename: 'subscriber_grid_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_grid_stddev_pop_fields = {
  __typename: 'subscriber_grid_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_grid_stddev_samp_fields = {
  __typename: 'subscriber_grid_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_grid" */
export type subscriber_grid_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_grid_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_grid_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_subscriber_campaign_view_id?: InputMaybe<Scalars['Int']['input']>;
  last_subscriber_campaign_view_seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_subscriber_campaign_view_status?: InputMaybe<Scalars['String']['input']>;
  last_subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  last_subscriber_flow_seen_at?: InputMaybe<Scalars['timestamptz']['input']>;
  last_subscriber_flow_status?: InputMaybe<Scalars['String']['input']>;
  last_subscriber_likelihood_to_return?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_grid_sum_fields = {
  __typename: 'subscriber_grid_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_grid_var_pop_fields = {
  __typename: 'subscriber_grid_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_grid_var_samp_fields = {
  __typename: 'subscriber_grid_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_grid_variance_fields = {
  __typename: 'subscriber_grid_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_campaign_view_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  last_subscriber_likelihood_to_return?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "subscriber" */
export type subscriber_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  active_mrr?: InputMaybe<Scalars['numeric']['input']>;
  active_offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  active_offer_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber" */
export type subscriber_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  active_mrr?: InputMaybe<Scalars['numeric']['input']>;
  active_offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  active_offer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_contact_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  intercom_contact_id?: InputMaybe<Scalars['String']['input']>;
  klaviyo_profile_id?: InputMaybe<Scalars['String']['input']>;
  most_recent_subscriber_flow?: InputMaybe<subscriber_most_recent_subscriber_flow_obj_rel_insert_input>;
  name?: InputMaybe<Scalars['String']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_customer?: InputMaybe<platform_customer_obj_rel_insert_input>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  portal_session_subscribers?: InputMaybe<portal_session_subscriber_arr_rel_insert_input>;
  revenue_logs?: InputMaybe<revenue_log_arr_rel_insert_input>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
  segment_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<subscriber_status_enum>;
  subscriber_acknowledgements?: InputMaybe<subscriber_acknowledgement_arr_rel_insert_input>;
  subscriber_arr?: InputMaybe<subscriber_arr_obj_rel_insert_input>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_arr_rel_insert_input>;
  subscriber_created_at?: InputMaybe<subscriber_created_at_obj_rel_insert_input>;
  subscriber_days_since_saved?: InputMaybe<subscriber_days_since_saved_obj_rel_insert_input>;
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
  subscriber_logs?: InputMaybe<subscriber_log_arr_rel_insert_input>;
  subscriber_ltv?: InputMaybe<subscriber_ltv_obj_rel_insert_input>;
  subscriber_mrr?: InputMaybe<subscriber_mrr_obj_rel_insert_input>;
  subscriber_properties?: InputMaybe<subscriber_property_arr_rel_insert_input>;
  subscriber_property_values?: InputMaybe<subscriber_property_value_arr_rel_insert_input>;
  subscriber_status?: InputMaybe<subscriber_status_obj_rel_insert_input>;
  subscriptions?: InputMaybe<subscription_arr_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscriber_log" */
export type subscriber_log = {
  __typename: 'subscriber_log';
  created_at: Scalars['timestamptz']['output'];
  data: Scalars['jsonb']['output'];
  event: subscriber_log_event_enum;
  event_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imported: Scalars['Boolean']['output'];
  occurred_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  subscriber: subscriber;
  subscriber_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_log_event: subscriber_log_event;
  /** An object relationship */
  subscriber_log_offer?: Maybe<subscriber_log_offer>;
  /** An array relationship */
  subscriber_log_offers: Array<subscriber_log_offer>;
  /** An aggregate relationship */
  subscriber_log_offers_aggregate: subscriber_log_offer_aggregate;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  trial: Scalars['Boolean']['output'];
};


/** columns and relationships of "subscriber_log" */
export type subscriber_logdataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "subscriber_log" */
export type subscriber_logsubscriber_log_offersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


/** columns and relationships of "subscriber_log" */
export type subscriber_logsubscriber_log_offers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};

/** aggregated selection of "subscriber_log" */
export type subscriber_log_aggregate = {
  __typename: 'subscriber_log_aggregate';
  aggregate?: Maybe<subscriber_log_aggregate_fields>;
  nodes: Array<subscriber_log>;
};

export type subscriber_log_aggregate_bool_exp = {
  bool_and?: InputMaybe<subscriber_log_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<subscriber_log_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<subscriber_log_aggregate_bool_exp_count>;
};

export type subscriber_log_aggregate_bool_exp_bool_and = {
  arguments: subscriber_log_select_column_subscriber_log_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_log_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_log_aggregate_bool_exp_bool_or = {
  arguments: subscriber_log_select_column_subscriber_log_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_log_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_log_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_log_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_log" */
export type subscriber_log_aggregate_fields = {
  __typename: 'subscriber_log_aggregate_fields';
  avg?: Maybe<subscriber_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_log_max_fields>;
  min?: Maybe<subscriber_log_min_fields>;
  stddev?: Maybe<subscriber_log_stddev_fields>;
  stddev_pop?: Maybe<subscriber_log_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_log_stddev_samp_fields>;
  sum?: Maybe<subscriber_log_sum_fields>;
  var_pop?: Maybe<subscriber_log_var_pop_fields>;
  var_samp?: Maybe<subscriber_log_var_samp_fields>;
  variance?: Maybe<subscriber_log_variance_fields>;
};


/** aggregate fields of "subscriber_log" */
export type subscriber_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_log" */
export type subscriber_log_aggregate_order_by = {
  avg?: InputMaybe<subscriber_log_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_log_max_order_by>;
  min?: InputMaybe<subscriber_log_min_order_by>;
  stddev?: InputMaybe<subscriber_log_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_log_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_log_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_log_sum_order_by>;
  var_pop?: InputMaybe<subscriber_log_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_log_var_samp_order_by>;
  variance?: InputMaybe<subscriber_log_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscriber_log_append_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriber_log" */
export type subscriber_log_arr_rel_insert_input = {
  data: Array<subscriber_log_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_log_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_log_avg_fields = {
  __typename: 'subscriber_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_log" */
export type subscriber_log_avg_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_log". All fields are combined with a logical 'AND'. */
export type subscriber_log_bool_exp = {
  _and?: InputMaybe<Array<subscriber_log_bool_exp>>;
  _not?: InputMaybe<subscriber_log_bool_exp>;
  _or?: InputMaybe<Array<subscriber_log_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  data?: InputMaybe<jsonb_comparison_exp>;
  event?: InputMaybe<subscriber_log_event_enum_comparison_exp>;
  event_id?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  imported?: InputMaybe<Boolean_comparison_exp>;
  occurred_at?: InputMaybe<timestamptz_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscriber_log_event?: InputMaybe<subscriber_log_event_bool_exp>;
  subscriber_log_offer?: InputMaybe<subscriber_log_offer_bool_exp>;
  subscriber_log_offers?: InputMaybe<subscriber_log_offer_bool_exp>;
  subscriber_log_offers_aggregate?: InputMaybe<subscriber_log_offer_aggregate_bool_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  trial?: InputMaybe<Boolean_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_log" */
export enum subscriber_log_constraint {
  /** unique or primary key constraint on columns "event", "event_id" */
  subscriber_log_event_event_id_key = 'subscriber_log_event_event_id_key',
  /** unique or primary key constraint on columns "id" */
  subscriber_log_pkey = 'subscriber_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscriber_log_delete_at_path_input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscriber_log_delete_elem_input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscriber_log_delete_key_input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "subscriber_log_event" */
export type subscriber_log_event = {
  __typename: 'subscriber_log_event';
  /** An array relationship */
  subscriber_logs: Array<subscriber_log>;
  /** An aggregate relationship */
  subscriber_logs_aggregate: subscriber_log_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "subscriber_log_event" */
export type subscriber_log_eventsubscriber_logsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


/** columns and relationships of "subscriber_log_event" */
export type subscriber_log_eventsubscriber_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};

/** aggregated selection of "subscriber_log_event" */
export type subscriber_log_event_aggregate = {
  __typename: 'subscriber_log_event_aggregate';
  aggregate?: Maybe<subscriber_log_event_aggregate_fields>;
  nodes: Array<subscriber_log_event>;
};

/** aggregate fields of "subscriber_log_event" */
export type subscriber_log_event_aggregate_fields = {
  __typename: 'subscriber_log_event_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_log_event_max_fields>;
  min?: Maybe<subscriber_log_event_min_fields>;
};


/** aggregate fields of "subscriber_log_event" */
export type subscriber_log_event_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_log_event_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_log_event". All fields are combined with a logical 'AND'. */
export type subscriber_log_event_bool_exp = {
  _and?: InputMaybe<Array<subscriber_log_event_bool_exp>>;
  _not?: InputMaybe<subscriber_log_event_bool_exp>;
  _or?: InputMaybe<Array<subscriber_log_event_bool_exp>>;
  subscriber_logs?: InputMaybe<subscriber_log_bool_exp>;
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** columns and relationships of "subscriber_log_event_by_month" */
export type subscriber_log_event_by_month = {
  __typename: 'subscriber_log_event_by_month';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscriber_log_event_by_month" */
export type subscriber_log_event_by_month_aggregate = {
  __typename: 'subscriber_log_event_by_month_aggregate';
  aggregate?: Maybe<subscriber_log_event_by_month_aggregate_fields>;
  nodes: Array<subscriber_log_event_by_month>;
};

/** aggregate fields of "subscriber_log_event_by_month" */
export type subscriber_log_event_by_month_aggregate_fields = {
  __typename: 'subscriber_log_event_by_month_aggregate_fields';
  avg?: Maybe<subscriber_log_event_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_log_event_by_month_max_fields>;
  min?: Maybe<subscriber_log_event_by_month_min_fields>;
  stddev?: Maybe<subscriber_log_event_by_month_stddev_fields>;
  stddev_pop?: Maybe<subscriber_log_event_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_log_event_by_month_stddev_samp_fields>;
  sum?: Maybe<subscriber_log_event_by_month_sum_fields>;
  var_pop?: Maybe<subscriber_log_event_by_month_var_pop_fields>;
  var_samp?: Maybe<subscriber_log_event_by_month_var_samp_fields>;
  variance?: Maybe<subscriber_log_event_by_month_variance_fields>;
};


/** aggregate fields of "subscriber_log_event_by_month" */
export type subscriber_log_event_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_log_event_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_log_event_by_month_avg_fields = {
  __typename: 'subscriber_log_event_by_month_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_log_event_by_month". All fields are combined with a logical 'AND'. */
export type subscriber_log_event_by_month_bool_exp = {
  _and?: InputMaybe<Array<subscriber_log_event_by_month_bool_exp>>;
  _not?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
  _or?: InputMaybe<Array<subscriber_log_event_by_month_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<bigint_comparison_exp>;
  event?: InputMaybe<String_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_log_event_by_month_max_fields = {
  __typename: 'subscriber_log_event_by_month_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_log_event_by_month_min_fields = {
  __typename: 'subscriber_log_event_by_month_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscriber_log_event_by_month". */
export type subscriber_log_event_by_month_order_by = {
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  event?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_log_event_by_month" */
export enum subscriber_log_event_by_month_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  event = 'event',
  /** column name */
  month = 'month'
}

/** aggregate stddev on columns */
export type subscriber_log_event_by_month_stddev_fields = {
  __typename: 'subscriber_log_event_by_month_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_log_event_by_month_stddev_pop_fields = {
  __typename: 'subscriber_log_event_by_month_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_log_event_by_month_stddev_samp_fields = {
  __typename: 'subscriber_log_event_by_month_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_log_event_by_month" */
export type subscriber_log_event_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_log_event_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_log_event_by_month_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['bigint']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscriber_log_event_by_month_sum_fields = {
  __typename: 'subscriber_log_event_by_month_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_log_event_by_month_var_pop_fields = {
  __typename: 'subscriber_log_event_by_month_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_log_event_by_month_var_samp_fields = {
  __typename: 'subscriber_log_event_by_month_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_log_event_by_month_variance_fields = {
  __typename: 'subscriber_log_event_by_month_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "subscriber_log_event" */
export enum subscriber_log_event_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_log_event_pkey = 'subscriber_log_event_pkey'
}

export enum subscriber_log_event_enum {
  canceled = 'canceled',
  converted = 'converted',
  deflected = 'deflected',
  offer_group_presented = 'offer_group_presented',
  offer_presented = 'offer_presented',
  reactivated = 'reactivated',
  saved = 'saved',
  subscription_ended = 'subscription_ended',
  subscription_paused = 'subscription_paused',
  subscription_resumed = 'subscription_resumed',
  subscription_started = 'subscription_started',
  subscription_updated = 'subscription_updated',
  survey_answered = 'survey_answered',
  survey_sent = 'survey_sent'
}

/** Boolean expression to compare columns of type "subscriber_log_event_enum". All fields are combined with logical 'AND'. */
export type subscriber_log_event_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_log_event_enum>;
  _in?: InputMaybe<Array<subscriber_log_event_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_log_event_enum>;
  _nin?: InputMaybe<Array<subscriber_log_event_enum>>;
};

/** input type for inserting data into table "subscriber_log_event" */
export type subscriber_log_event_insert_input = {
  subscriber_logs?: InputMaybe<subscriber_log_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_log_event_max_fields = {
  __typename: 'subscriber_log_event_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_log_event_min_fields = {
  __typename: 'subscriber_log_event_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_log_event" */
export type subscriber_log_event_mutation_response = {
  __typename: 'subscriber_log_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_log_event>;
};

/** input type for inserting object relation for remote table "subscriber_log_event" */
export type subscriber_log_event_obj_rel_insert_input = {
  data: subscriber_log_event_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_log_event_on_conflict>;
};

/** on_conflict condition type for table "subscriber_log_event" */
export type subscriber_log_event_on_conflict = {
  constraint: subscriber_log_event_constraint;
  update_columns?: Array<subscriber_log_event_update_column>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_log_event". */
export type subscriber_log_event_order_by = {
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_log_event */
export type subscriber_log_event_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_log_event" */
export enum subscriber_log_event_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_log_event" */
export type subscriber_log_event_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_log_event" */
export type subscriber_log_event_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_log_event_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_log_event_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_log_event" */
export enum subscriber_log_event_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_log_event_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_log_event_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_log_event_bool_exp;
};

/** input type for incrementing numeric columns in table "subscriber_log" */
export type subscriber_log_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_log" */
export type subscriber_log_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<subscriber_log_event_enum>;
  event_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_event?: InputMaybe<subscriber_log_event_obj_rel_insert_input>;
  subscriber_log_offer?: InputMaybe<subscriber_log_offer_obj_rel_insert_input>;
  subscriber_log_offers?: InputMaybe<subscriber_log_offer_arr_rel_insert_input>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type subscriber_log_max_fields = {
  __typename: 'subscriber_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_log" */
export type subscriber_log_max_order_by = {
  created_at?: InputMaybe<order_by>;
  event_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_log_min_fields = {
  __typename: 'subscriber_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  occurred_at?: Maybe<Scalars['timestamptz']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_log" */
export type subscriber_log_min_order_by = {
  created_at?: InputMaybe<order_by>;
  event_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_log" */
export type subscriber_log_mutation_response = {
  __typename: 'subscriber_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_log>;
};

/** input type for inserting object relation for remote table "subscriber_log" */
export type subscriber_log_obj_rel_insert_input = {
  data: subscriber_log_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_log_on_conflict>;
};

/** columns and relationships of "subscriber_log_offer" */
export type subscriber_log_offer = {
  __typename: 'subscriber_log_offer';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber_log: subscriber_log;
  subscriber_log_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_log_offer" */
export type subscriber_log_offer_aggregate = {
  __typename: 'subscriber_log_offer_aggregate';
  aggregate?: Maybe<subscriber_log_offer_aggregate_fields>;
  nodes: Array<subscriber_log_offer>;
};

export type subscriber_log_offer_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_log_offer_aggregate_bool_exp_count>;
};

export type subscriber_log_offer_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_log_offer_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_log_offer" */
export type subscriber_log_offer_aggregate_fields = {
  __typename: 'subscriber_log_offer_aggregate_fields';
  avg?: Maybe<subscriber_log_offer_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_log_offer_max_fields>;
  min?: Maybe<subscriber_log_offer_min_fields>;
  stddev?: Maybe<subscriber_log_offer_stddev_fields>;
  stddev_pop?: Maybe<subscriber_log_offer_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_log_offer_stddev_samp_fields>;
  sum?: Maybe<subscriber_log_offer_sum_fields>;
  var_pop?: Maybe<subscriber_log_offer_var_pop_fields>;
  var_samp?: Maybe<subscriber_log_offer_var_samp_fields>;
  variance?: Maybe<subscriber_log_offer_variance_fields>;
};


/** aggregate fields of "subscriber_log_offer" */
export type subscriber_log_offer_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_log_offer" */
export type subscriber_log_offer_aggregate_order_by = {
  avg?: InputMaybe<subscriber_log_offer_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_log_offer_max_order_by>;
  min?: InputMaybe<subscriber_log_offer_min_order_by>;
  stddev?: InputMaybe<subscriber_log_offer_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_log_offer_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_log_offer_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_log_offer_sum_order_by>;
  var_pop?: InputMaybe<subscriber_log_offer_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_log_offer_var_samp_order_by>;
  variance?: InputMaybe<subscriber_log_offer_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscriber_log_offer" */
export type subscriber_log_offer_arr_rel_insert_input = {
  data: Array<subscriber_log_offer_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_log_offer_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_log_offer_avg_fields = {
  __typename: 'subscriber_log_offer_avg_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_avg_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_log_offer". All fields are combined with a logical 'AND'. */
export type subscriber_log_offer_bool_exp = {
  _and?: InputMaybe<Array<subscriber_log_offer_bool_exp>>;
  _not?: InputMaybe<subscriber_log_offer_bool_exp>;
  _or?: InputMaybe<Array<subscriber_log_offer_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  subscriber_log?: InputMaybe<subscriber_log_bool_exp>;
  subscriber_log_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_log_offer" */
export enum subscriber_log_offer_constraint {
  /** unique or primary key constraint on columns "subscriber_log_id" */
  subscriber_log_offer_pkey = 'subscriber_log_offer_pkey'
}

/** columns and relationships of "subscriber_log_offer_group" */
export type subscriber_log_offer_group = {
  __typename: 'subscriber_log_offer_group';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id: Scalars['Int']['output'];
  subscriber_log_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscriber_log_offer_group" */
export type subscriber_log_offer_group_aggregate = {
  __typename: 'subscriber_log_offer_group_aggregate';
  aggregate?: Maybe<subscriber_log_offer_group_aggregate_fields>;
  nodes: Array<subscriber_log_offer_group>;
};

/** aggregate fields of "subscriber_log_offer_group" */
export type subscriber_log_offer_group_aggregate_fields = {
  __typename: 'subscriber_log_offer_group_aggregate_fields';
  avg?: Maybe<subscriber_log_offer_group_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_log_offer_group_max_fields>;
  min?: Maybe<subscriber_log_offer_group_min_fields>;
  stddev?: Maybe<subscriber_log_offer_group_stddev_fields>;
  stddev_pop?: Maybe<subscriber_log_offer_group_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_log_offer_group_stddev_samp_fields>;
  sum?: Maybe<subscriber_log_offer_group_sum_fields>;
  var_pop?: Maybe<subscriber_log_offer_group_var_pop_fields>;
  var_samp?: Maybe<subscriber_log_offer_group_var_samp_fields>;
  variance?: Maybe<subscriber_log_offer_group_variance_fields>;
};


/** aggregate fields of "subscriber_log_offer_group" */
export type subscriber_log_offer_group_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_log_offer_group_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_log_offer_group_avg_fields = {
  __typename: 'subscriber_log_offer_group_avg_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_log_offer_group". All fields are combined with a logical 'AND'. */
export type subscriber_log_offer_group_bool_exp = {
  _and?: InputMaybe<Array<subscriber_log_offer_group_bool_exp>>;
  _not?: InputMaybe<subscriber_log_offer_group_bool_exp>;
  _or?: InputMaybe<Array<subscriber_log_offer_group_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  subscriber_log_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_log_offer_group" */
export enum subscriber_log_offer_group_constraint {
  /** unique or primary key constraint on columns "subscriber_log_id" */
  subscriber_log_offer_group_pkey = 'subscriber_log_offer_group_pkey'
}

/** input type for incrementing numeric columns in table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_inc_input = {
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_log_offer_group_max_fields = {
  __typename: 'subscriber_log_offer_group_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_log_offer_group_min_fields = {
  __typename: 'subscriber_log_offer_group_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_mutation_response = {
  __typename: 'subscriber_log_offer_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_log_offer_group>;
};

/** on_conflict condition type for table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_on_conflict = {
  constraint: subscriber_log_offer_group_constraint;
  update_columns?: Array<subscriber_log_offer_group_update_column>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_log_offer_group". */
export type subscriber_log_offer_group_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_log_offer_group */
export type subscriber_log_offer_group_pk_columns_input = {
  subscriber_log_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_log_offer_group" */
export enum subscriber_log_offer_group_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  subscriber_log_id = 'subscriber_log_id'
}

/** input type for updating data in table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_log_offer_group_stddev_fields = {
  __typename: 'subscriber_log_offer_group_stddev_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_log_offer_group_stddev_pop_fields = {
  __typename: 'subscriber_log_offer_group_stddev_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_log_offer_group_stddev_samp_fields = {
  __typename: 'subscriber_log_offer_group_stddev_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_log_offer_group" */
export type subscriber_log_offer_group_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_log_offer_group_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_log_offer_group_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_log_offer_group_sum_fields = {
  __typename: 'subscriber_log_offer_group_sum_fields';
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscriber_log_offer_group" */
export enum subscriber_log_offer_group_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  subscriber_log_id = 'subscriber_log_id'
}

export type subscriber_log_offer_group_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_log_offer_group_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_log_offer_group_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_log_offer_group_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_log_offer_group_var_pop_fields = {
  __typename: 'subscriber_log_offer_group_var_pop_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_log_offer_group_var_samp_fields = {
  __typename: 'subscriber_log_offer_group_var_samp_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_log_offer_group_variance_fields = {
  __typename: 'subscriber_log_offer_group_variance_fields';
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "subscriber_log_offer" */
export type subscriber_log_offer_inc_input = {
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_log_offer" */
export type subscriber_log_offer_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log?: InputMaybe<subscriber_log_obj_rel_insert_input>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_log_offer_max_fields = {
  __typename: 'subscriber_log_offer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_max_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_log_offer_min_fields = {
  __typename: 'subscriber_log_offer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_min_order_by = {
  created_at?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_log_offer" */
export type subscriber_log_offer_mutation_response = {
  __typename: 'subscriber_log_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_log_offer>;
};

/** input type for inserting object relation for remote table "subscriber_log_offer" */
export type subscriber_log_offer_obj_rel_insert_input = {
  data: subscriber_log_offer_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_log_offer_on_conflict>;
};

/** on_conflict condition type for table "subscriber_log_offer" */
export type subscriber_log_offer_on_conflict = {
  constraint: subscriber_log_offer_constraint;
  update_columns?: Array<subscriber_log_offer_update_column>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_log_offer". */
export type subscriber_log_offer_order_by = {
  created_at?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  subscriber_log?: InputMaybe<subscriber_log_order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_log_offer */
export type subscriber_log_offer_pk_columns_input = {
  subscriber_log_id: Scalars['Int']['input'];
};

/** select columns of table "subscriber_log_offer" */
export enum subscriber_log_offer_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  subscriber_log_id = 'subscriber_log_id'
}

/** input type for updating data in table "subscriber_log_offer" */
export type subscriber_log_offer_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_log_offer_stddev_fields = {
  __typename: 'subscriber_log_offer_stddev_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_stddev_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_log_offer_stddev_pop_fields = {
  __typename: 'subscriber_log_offer_stddev_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_stddev_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_log_offer_stddev_samp_fields = {
  __typename: 'subscriber_log_offer_stddev_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_stddev_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_log_offer" */
export type subscriber_log_offer_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_log_offer_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_log_offer_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_log_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_log_offer_sum_fields = {
  __typename: 'subscriber_log_offer_sum_fields';
  offer_id?: Maybe<Scalars['Int']['output']>;
  subscriber_log_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_sum_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_log_offer" */
export enum subscriber_log_offer_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  subscriber_log_id = 'subscriber_log_id'
}

export type subscriber_log_offer_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_log_offer_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_log_offer_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_log_offer_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_log_offer_var_pop_fields = {
  __typename: 'subscriber_log_offer_var_pop_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_var_pop_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_log_offer_var_samp_fields = {
  __typename: 'subscriber_log_offer_var_samp_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_var_samp_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_log_offer_variance_fields = {
  __typename: 'subscriber_log_offer_variance_fields';
  offer_id?: Maybe<Scalars['Float']['output']>;
  subscriber_log_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_log_offer" */
export type subscriber_log_offer_variance_order_by = {
  offer_id?: InputMaybe<order_by>;
  subscriber_log_id?: InputMaybe<order_by>;
};

/** on_conflict condition type for table "subscriber_log" */
export type subscriber_log_on_conflict = {
  constraint: subscriber_log_constraint;
  update_columns?: Array<subscriber_log_update_column>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_log". */
export type subscriber_log_order_by = {
  created_at?: InputMaybe<order_by>;
  data?: InputMaybe<order_by>;
  event?: InputMaybe<order_by>;
  event_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  imported?: InputMaybe<order_by>;
  occurred_at?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscriber_log_event?: InputMaybe<subscriber_log_event_order_by>;
  subscriber_log_offer?: InputMaybe<subscriber_log_offer_order_by>;
  subscriber_log_offers_aggregate?: InputMaybe<subscriber_log_offer_aggregate_order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  trial?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_log */
export type subscriber_log_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscriber_log_prepend_input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscriber_log" */
export enum subscriber_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  event = 'event',
  /** column name */
  event_id = 'event_id',
  /** column name */
  id = 'id',
  /** column name */
  imported = 'imported',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  trial = 'trial'
}

/** select "subscriber_log_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriber_log" */
export enum subscriber_log_select_column_subscriber_log_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  imported = 'imported',
  /** column name */
  trial = 'trial'
}

/** select "subscriber_log_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriber_log" */
export enum subscriber_log_select_column_subscriber_log_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  imported = 'imported',
  /** column name */
  trial = 'trial'
}

/** input type for updating data in table "subscriber_log" */
export type subscriber_log_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<subscriber_log_event_enum>;
  event_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_log_stddev_fields = {
  __typename: 'subscriber_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_log" */
export type subscriber_log_stddev_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_log_stddev_pop_fields = {
  __typename: 'subscriber_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_log" */
export type subscriber_log_stddev_pop_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_log_stddev_samp_fields = {
  __typename: 'subscriber_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_log" */
export type subscriber_log_stddev_samp_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_log" */
export type subscriber_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_log_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<subscriber_log_event_enum>;
  event_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imported?: InputMaybe<Scalars['Boolean']['input']>;
  occurred_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  trial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type subscriber_log_sum_fields = {
  __typename: 'subscriber_log_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_log" */
export type subscriber_log_sum_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_log" */
export enum subscriber_log_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  event = 'event',
  /** column name */
  event_id = 'event_id',
  /** column name */
  id = 'id',
  /** column name */
  imported = 'imported',
  /** column name */
  occurred_at = 'occurred_at',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  trial = 'trial'
}

export type subscriber_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscriber_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscriber_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscriber_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscriber_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscriber_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_log_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_log_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_log_var_pop_fields = {
  __typename: 'subscriber_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_log" */
export type subscriber_log_var_pop_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_log_var_samp_fields = {
  __typename: 'subscriber_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_log" */
export type subscriber_log_var_samp_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_log_variance_fields = {
  __typename: 'subscriber_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_log" */
export type subscriber_log_variance_order_by = {
  id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_ltv" */
export type subscriber_ltv = {
  __typename: 'subscriber_ltv';
  ltv?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscriber_ltv" */
export type subscriber_ltv_aggregate = {
  __typename: 'subscriber_ltv_aggregate';
  aggregate?: Maybe<subscriber_ltv_aggregate_fields>;
  nodes: Array<subscriber_ltv>;
};

/** aggregate fields of "subscriber_ltv" */
export type subscriber_ltv_aggregate_fields = {
  __typename: 'subscriber_ltv_aggregate_fields';
  avg?: Maybe<subscriber_ltv_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_ltv_max_fields>;
  min?: Maybe<subscriber_ltv_min_fields>;
  stddev?: Maybe<subscriber_ltv_stddev_fields>;
  stddev_pop?: Maybe<subscriber_ltv_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_ltv_stddev_samp_fields>;
  sum?: Maybe<subscriber_ltv_sum_fields>;
  var_pop?: Maybe<subscriber_ltv_var_pop_fields>;
  var_samp?: Maybe<subscriber_ltv_var_samp_fields>;
  variance?: Maybe<subscriber_ltv_variance_fields>;
};


/** aggregate fields of "subscriber_ltv" */
export type subscriber_ltv_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_ltv_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_ltv_avg_fields = {
  __typename: 'subscriber_ltv_avg_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_ltv". All fields are combined with a logical 'AND'. */
export type subscriber_ltv_bool_exp = {
  _and?: InputMaybe<Array<subscriber_ltv_bool_exp>>;
  _not?: InputMaybe<subscriber_ltv_bool_exp>;
  _or?: InputMaybe<Array<subscriber_ltv_bool_exp>>;
  ltv?: InputMaybe<numeric_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscriber_ltv" */
export type subscriber_ltv_insert_input = {
  ltv?: InputMaybe<Scalars['numeric']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_ltv_max_fields = {
  __typename: 'subscriber_ltv_max_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_ltv_min_fields = {
  __typename: 'subscriber_ltv_min_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscriber_ltv" */
export type subscriber_ltv_obj_rel_insert_input = {
  data: subscriber_ltv_insert_input;
};

/** Ordering options when selecting data from "subscriber_ltv". */
export type subscriber_ltv_order_by = {
  ltv?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_ltv" */
export enum subscriber_ltv_select_column {
  /** column name */
  ltv = 'ltv',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** aggregate stddev on columns */
export type subscriber_ltv_stddev_fields = {
  __typename: 'subscriber_ltv_stddev_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_ltv_stddev_pop_fields = {
  __typename: 'subscriber_ltv_stddev_pop_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_ltv_stddev_samp_fields = {
  __typename: 'subscriber_ltv_stddev_samp_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_ltv" */
export type subscriber_ltv_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_ltv_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_ltv_stream_cursor_value_input = {
  ltv?: InputMaybe<Scalars['numeric']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_ltv_sum_fields = {
  __typename: 'subscriber_ltv_sum_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_ltv_var_pop_fields = {
  __typename: 'subscriber_ltv_var_pop_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_ltv_var_samp_fields = {
  __typename: 'subscriber_ltv_var_samp_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_ltv_variance_fields = {
  __typename: 'subscriber_ltv_variance_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type subscriber_max_fields = {
  __typename: 'subscriber_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  active_mrr?: Maybe<Scalars['numeric']['output']>;
  active_offer_group_id?: Maybe<Scalars['Int']['output']>;
  active_offer_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hubspot_company_id?: Maybe<Scalars['String']['output']>;
  hubspot_contact_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  intercom_contact_id?: Maybe<Scalars['String']['output']>;
  klaviyo_profile_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  salesforce_contact_id?: Maybe<Scalars['String']['output']>;
  segment_user_id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber" */
export type subscriber_max_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  hubspot_company_id?: InputMaybe<order_by>;
  hubspot_contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  intercom_contact_id?: InputMaybe<order_by>;
  klaviyo_profile_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  salesforce_contact_id?: InputMaybe<order_by>;
  segment_user_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_min_fields = {
  __typename: 'subscriber_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  active_mrr?: Maybe<Scalars['numeric']['output']>;
  active_offer_group_id?: Maybe<Scalars['Int']['output']>;
  active_offer_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hubspot_company_id?: Maybe<Scalars['String']['output']>;
  hubspot_contact_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  intercom_contact_id?: Maybe<Scalars['String']['output']>;
  klaviyo_profile_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  salesforce_contact_id?: Maybe<Scalars['String']['output']>;
  segment_user_id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber" */
export type subscriber_min_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  hubspot_company_id?: InputMaybe<order_by>;
  hubspot_contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  intercom_contact_id?: InputMaybe<order_by>;
  klaviyo_profile_id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  salesforce_contact_id?: InputMaybe<order_by>;
  segment_user_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow = {
  __typename: 'subscriber_most_recent_subscriber_flow';
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  /** An object relationship */
  subscriber_flow?: Maybe<subscriber_flow>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_aggregate = {
  __typename: 'subscriber_most_recent_subscriber_flow_aggregate';
  aggregate?: Maybe<subscriber_most_recent_subscriber_flow_aggregate_fields>;
  nodes: Array<subscriber_most_recent_subscriber_flow>;
};

/** aggregate fields of "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_aggregate_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_aggregate_fields';
  avg?: Maybe<subscriber_most_recent_subscriber_flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_most_recent_subscriber_flow_max_fields>;
  min?: Maybe<subscriber_most_recent_subscriber_flow_min_fields>;
  stddev?: Maybe<subscriber_most_recent_subscriber_flow_stddev_fields>;
  stddev_pop?: Maybe<subscriber_most_recent_subscriber_flow_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_most_recent_subscriber_flow_stddev_samp_fields>;
  sum?: Maybe<subscriber_most_recent_subscriber_flow_sum_fields>;
  var_pop?: Maybe<subscriber_most_recent_subscriber_flow_var_pop_fields>;
  var_samp?: Maybe<subscriber_most_recent_subscriber_flow_var_samp_fields>;
  variance?: Maybe<subscriber_most_recent_subscriber_flow_variance_fields>;
};


/** aggregate fields of "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_most_recent_subscriber_flow_avg_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_avg_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_most_recent_subscriber_flow". All fields are combined with a logical 'AND'. */
export type subscriber_most_recent_subscriber_flow_bool_exp = {
  _and?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_bool_exp>>;
  _not?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
  _or?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_bool_exp>>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_insert_input = {
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscriber_most_recent_subscriber_flow_max_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_max_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_most_recent_subscriber_flow_min_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_min_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_obj_rel_insert_input = {
  data: subscriber_most_recent_subscriber_flow_insert_input;
};

/** Ordering options when selecting data from "subscriber_most_recent_subscriber_flow". */
export type subscriber_most_recent_subscriber_flow_order_by = {
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_most_recent_subscriber_flow" */
export enum subscriber_most_recent_subscriber_flow_select_column {
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  subscriber_id = 'subscriber_id'
}

/** aggregate stddev on columns */
export type subscriber_most_recent_subscriber_flow_stddev_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_stddev_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_most_recent_subscriber_flow_stddev_pop_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_stddev_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_most_recent_subscriber_flow_stddev_samp_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_stddev_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_most_recent_subscriber_flow" */
export type subscriber_most_recent_subscriber_flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_most_recent_subscriber_flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_most_recent_subscriber_flow_stream_cursor_value_input = {
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscriber_most_recent_subscriber_flow_sum_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_sum_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_most_recent_subscriber_flow_var_pop_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_var_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_most_recent_subscriber_flow_var_samp_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_var_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_most_recent_subscriber_flow_variance_fields = {
  __typename: 'subscriber_most_recent_subscriber_flow_variance_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscriber_mrr" */
export type subscriber_mrr = {
  __typename: 'subscriber_mrr';
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
};

/** aggregated selection of "subscriber_mrr" */
export type subscriber_mrr_aggregate = {
  __typename: 'subscriber_mrr_aggregate';
  aggregate?: Maybe<subscriber_mrr_aggregate_fields>;
  nodes: Array<subscriber_mrr>;
};

/** aggregate fields of "subscriber_mrr" */
export type subscriber_mrr_aggregate_fields = {
  __typename: 'subscriber_mrr_aggregate_fields';
  avg?: Maybe<subscriber_mrr_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_mrr_max_fields>;
  min?: Maybe<subscriber_mrr_min_fields>;
  stddev?: Maybe<subscriber_mrr_stddev_fields>;
  stddev_pop?: Maybe<subscriber_mrr_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_mrr_stddev_samp_fields>;
  sum?: Maybe<subscriber_mrr_sum_fields>;
  var_pop?: Maybe<subscriber_mrr_var_pop_fields>;
  var_samp?: Maybe<subscriber_mrr_var_samp_fields>;
  variance?: Maybe<subscriber_mrr_variance_fields>;
};


/** aggregate fields of "subscriber_mrr" */
export type subscriber_mrr_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_mrr_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_mrr_avg_fields = {
  __typename: 'subscriber_mrr_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_mrr". All fields are combined with a logical 'AND'. */
export type subscriber_mrr_bool_exp = {
  _and?: InputMaybe<Array<subscriber_mrr_bool_exp>>;
  _not?: InputMaybe<subscriber_mrr_bool_exp>;
  _or?: InputMaybe<Array<subscriber_mrr_bool_exp>>;
  id?: InputMaybe<Int_comparison_exp>;
  mrr?: InputMaybe<numeric_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
};

/** input type for incrementing numeric columns in table "subscriber_mrr" */
export type subscriber_mrr_inc_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "subscriber_mrr" */
export type subscriber_mrr_insert_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type subscriber_mrr_max_fields = {
  __typename: 'subscriber_mrr_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type subscriber_mrr_min_fields = {
  __typename: 'subscriber_mrr_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "subscriber_mrr" */
export type subscriber_mrr_mutation_response = {
  __typename: 'subscriber_mrr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_mrr>;
};

/** input type for inserting object relation for remote table "subscriber_mrr" */
export type subscriber_mrr_obj_rel_insert_input = {
  data: subscriber_mrr_insert_input;
};

/** Ordering options when selecting data from "subscriber_mrr". */
export type subscriber_mrr_order_by = {
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
};

/** select columns of table "subscriber_mrr" */
export enum subscriber_mrr_select_column {
  /** column name */
  id = 'id',
  /** column name */
  mrr = 'mrr'
}

/** input type for updating data in table "subscriber_mrr" */
export type subscriber_mrr_set_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_mrr_stddev_fields = {
  __typename: 'subscriber_mrr_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_mrr_stddev_pop_fields = {
  __typename: 'subscriber_mrr_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_mrr_stddev_samp_fields = {
  __typename: 'subscriber_mrr_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_mrr" */
export type subscriber_mrr_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_mrr_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_mrr_stream_cursor_value_input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type subscriber_mrr_sum_fields = {
  __typename: 'subscriber_mrr_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
};

export type subscriber_mrr_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_mrr_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_mrr_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_mrr_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_mrr_var_pop_fields = {
  __typename: 'subscriber_mrr_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_mrr_var_samp_fields = {
  __typename: 'subscriber_mrr_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_mrr_variance_fields = {
  __typename: 'subscriber_mrr_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

/** response of any mutation on the table "subscriber" */
export type subscriber_mutation_response = {
  __typename: 'subscriber_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber>;
};

/** input type for inserting object relation for remote table "subscriber" */
export type subscriber_obj_rel_insert_input = {
  data: subscriber_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_on_conflict>;
};

/** on_conflict condition type for table "subscriber" */
export type subscriber_on_conflict = {
  constraint: subscriber_constraint;
  update_columns?: Array<subscriber_update_column>;
  where?: InputMaybe<subscriber_bool_exp>;
};

/** Ordering options when selecting data from "subscriber". */
export type subscriber_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  hubspot_company_id?: InputMaybe<order_by>;
  hubspot_contact_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  intercom_contact_id?: InputMaybe<order_by>;
  klaviyo_profile_id?: InputMaybe<order_by>;
  most_recent_subscriber_flow?: InputMaybe<subscriber_most_recent_subscriber_flow_order_by>;
  name?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_customer?: InputMaybe<platform_customer_order_by>;
  platform_id?: InputMaybe<order_by>;
  portal_session_subscribers_aggregate?: InputMaybe<portal_session_subscriber_aggregate_order_by>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_order_by>;
  salesforce_contact_id?: InputMaybe<order_by>;
  segment_user_id?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  subscriber_acknowledgements_aggregate?: InputMaybe<subscriber_acknowledgement_aggregate_order_by>;
  subscriber_arr?: InputMaybe<subscriber_arr_order_by>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_order_by>;
  subscriber_created_at?: InputMaybe<subscriber_created_at_order_by>;
  subscriber_days_since_saved?: InputMaybe<subscriber_days_since_saved_order_by>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_order_by>;
  subscriber_ltv?: InputMaybe<subscriber_ltv_order_by>;
  subscriber_mrr?: InputMaybe<subscriber_mrr_order_by>;
  subscriber_properties_aggregate?: InputMaybe<subscriber_property_aggregate_order_by>;
  subscriber_property_values_aggregate?: InputMaybe<subscriber_property_value_aggregate_order_by>;
  subscriber_status?: InputMaybe<subscriber_status_order_by>;
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber */
export type subscriber_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "subscriber_property" */
export type subscriber_property = {
  __typename: 'subscriber_property';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  property: property;
  property_id: Scalars['Int']['output'];
  /** An object relationship */
  subscriber: subscriber;
  subscriber_id: Scalars['Int']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "subscriber_property" */
export type subscriber_propertyvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "subscriber_property" */
export type subscriber_property_aggregate = {
  __typename: 'subscriber_property_aggregate';
  aggregate?: Maybe<subscriber_property_aggregate_fields>;
  nodes: Array<subscriber_property>;
};

export type subscriber_property_aggregate_bool_exp = {
  count?: InputMaybe<subscriber_property_aggregate_bool_exp_count>;
};

export type subscriber_property_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_property_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_property" */
export type subscriber_property_aggregate_fields = {
  __typename: 'subscriber_property_aggregate_fields';
  avg?: Maybe<subscriber_property_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_property_max_fields>;
  min?: Maybe<subscriber_property_min_fields>;
  stddev?: Maybe<subscriber_property_stddev_fields>;
  stddev_pop?: Maybe<subscriber_property_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_property_stddev_samp_fields>;
  sum?: Maybe<subscriber_property_sum_fields>;
  var_pop?: Maybe<subscriber_property_var_pop_fields>;
  var_samp?: Maybe<subscriber_property_var_samp_fields>;
  variance?: Maybe<subscriber_property_variance_fields>;
};


/** aggregate fields of "subscriber_property" */
export type subscriber_property_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_property" */
export type subscriber_property_aggregate_order_by = {
  avg?: InputMaybe<subscriber_property_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_property_max_order_by>;
  min?: InputMaybe<subscriber_property_min_order_by>;
  stddev?: InputMaybe<subscriber_property_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_property_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_property_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_property_sum_order_by>;
  var_pop?: InputMaybe<subscriber_property_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_property_var_samp_order_by>;
  variance?: InputMaybe<subscriber_property_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscriber_property_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriber_property" */
export type subscriber_property_arr_rel_insert_input = {
  data: Array<subscriber_property_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_property_on_conflict>;
};

/** aggregate avg on columns */
export type subscriber_property_avg_fields = {
  __typename: 'subscriber_property_avg_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_property" */
export type subscriber_property_avg_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_property". All fields are combined with a logical 'AND'. */
export type subscriber_property_bool_exp = {
  _and?: InputMaybe<Array<subscriber_property_bool_exp>>;
  _not?: InputMaybe<subscriber_property_bool_exp>;
  _or?: InputMaybe<Array<subscriber_property_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  property?: InputMaybe<property_bool_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_property" */
export enum subscriber_property_constraint {
  /** unique or primary key constraint on columns "property_id", "subscriber_id" */
  subscriber_property_pkey = 'subscriber_property_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscriber_property_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscriber_property_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscriber_property_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscriber_property" */
export type subscriber_property_inc_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscriber_property" */
export type subscriber_property_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type subscriber_property_max_fields = {
  __typename: 'subscriber_property_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscriber_property" */
export type subscriber_property_max_order_by = {
  created_at?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_property_min_fields = {
  __typename: 'subscriber_property_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscriber_property" */
export type subscriber_property_min_order_by = {
  created_at?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_property" */
export type subscriber_property_mutation_response = {
  __typename: 'subscriber_property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_property>;
};

/** on_conflict condition type for table "subscriber_property" */
export type subscriber_property_on_conflict = {
  constraint: subscriber_property_constraint;
  update_columns?: Array<subscriber_property_update_column>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_property". */
export type subscriber_property_order_by = {
  created_at?: InputMaybe<order_by>;
  property?: InputMaybe<property_order_by>;
  property_id?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_property */
export type subscriber_property_pk_columns_input = {
  property_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscriber_property_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscriber_property" */
export enum subscriber_property_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_property" */
export type subscriber_property_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_property_stddev_fields = {
  __typename: 'subscriber_property_stddev_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_property" */
export type subscriber_property_stddev_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_property_stddev_pop_fields = {
  __typename: 'subscriber_property_stddev_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_property" */
export type subscriber_property_stddev_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_property_stddev_samp_fields = {
  __typename: 'subscriber_property_stddev_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_property" */
export type subscriber_property_stddev_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_property" */
export type subscriber_property_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_property_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_property_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type subscriber_property_sum_fields = {
  __typename: 'subscriber_property_sum_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber_property" */
export type subscriber_property_sum_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** update columns of table "subscriber_property" */
export enum subscriber_property_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  value = 'value'
}

export type subscriber_property_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscriber_property_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscriber_property_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscriber_property_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscriber_property_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_property_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscriber_property_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_property_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_property_bool_exp;
};

/** columns and relationships of "subscriber_property_value" */
export type subscriber_property_value = {
  __typename: 'subscriber_property_value';
  /** An object relationship */
  property?: Maybe<property>;
  property_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscriber?: Maybe<subscriber>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['jsonb']['output']>;
  value_boolean?: Maybe<Scalars['Boolean']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "subscriber_property_value" */
export type subscriber_property_valuevalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "subscriber_property_value" */
export type subscriber_property_value_aggregate = {
  __typename: 'subscriber_property_value_aggregate';
  aggregate?: Maybe<subscriber_property_value_aggregate_fields>;
  nodes: Array<subscriber_property_value>;
};

export type subscriber_property_value_aggregate_bool_exp = {
  bool_and?: InputMaybe<subscriber_property_value_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<subscriber_property_value_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<subscriber_property_value_aggregate_bool_exp_count>;
};

export type subscriber_property_value_aggregate_bool_exp_bool_and = {
  arguments: subscriber_property_value_select_column_subscriber_property_value_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_property_value_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_property_value_aggregate_bool_exp_bool_or = {
  arguments: subscriber_property_value_select_column_subscriber_property_value_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_property_value_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscriber_property_value_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscriber_property_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscriber_property_value_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscriber_property_value" */
export type subscriber_property_value_aggregate_fields = {
  __typename: 'subscriber_property_value_aggregate_fields';
  avg?: Maybe<subscriber_property_value_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_property_value_max_fields>;
  min?: Maybe<subscriber_property_value_min_fields>;
  stddev?: Maybe<subscriber_property_value_stddev_fields>;
  stddev_pop?: Maybe<subscriber_property_value_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_property_value_stddev_samp_fields>;
  sum?: Maybe<subscriber_property_value_sum_fields>;
  var_pop?: Maybe<subscriber_property_value_var_pop_fields>;
  var_samp?: Maybe<subscriber_property_value_var_samp_fields>;
  variance?: Maybe<subscriber_property_value_variance_fields>;
};


/** aggregate fields of "subscriber_property_value" */
export type subscriber_property_value_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_property_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscriber_property_value" */
export type subscriber_property_value_aggregate_order_by = {
  avg?: InputMaybe<subscriber_property_value_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscriber_property_value_max_order_by>;
  min?: InputMaybe<subscriber_property_value_min_order_by>;
  stddev?: InputMaybe<subscriber_property_value_stddev_order_by>;
  stddev_pop?: InputMaybe<subscriber_property_value_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscriber_property_value_stddev_samp_order_by>;
  sum?: InputMaybe<subscriber_property_value_sum_order_by>;
  var_pop?: InputMaybe<subscriber_property_value_var_pop_order_by>;
  var_samp?: InputMaybe<subscriber_property_value_var_samp_order_by>;
  variance?: InputMaybe<subscriber_property_value_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscriber_property_value_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscriber_property_value" */
export type subscriber_property_value_arr_rel_insert_input = {
  data: Array<subscriber_property_value_insert_input>;
};

/** aggregate avg on columns */
export type subscriber_property_value_avg_fields = {
  __typename: 'subscriber_property_value_avg_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscriber_property_value" */
export type subscriber_property_value_avg_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscriber_property_value". All fields are combined with a logical 'AND'. */
export type subscriber_property_value_bool_exp = {
  _and?: InputMaybe<Array<subscriber_property_value_bool_exp>>;
  _not?: InputMaybe<subscriber_property_value_bool_exp>;
  _or?: InputMaybe<Array<subscriber_property_value_bool_exp>>;
  property?: InputMaybe<property_bool_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
  value_boolean?: InputMaybe<Boolean_comparison_exp>;
  value_numeric?: InputMaybe<numeric_comparison_exp>;
  value_text?: InputMaybe<String_comparison_exp>;
  value_text_lower?: InputMaybe<String_comparison_exp>;
  value_timestamptz?: InputMaybe<timestamptz_comparison_exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscriber_property_value_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscriber_property_value_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscriber_property_value_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscriber_property_value" */
export type subscriber_property_value_inc_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "subscriber_property_value" */
export type subscriber_property_value_insert_input = {
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscriber_property_value_max_fields = {
  __typename: 'subscriber_property_value_max_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscriber_property_value" */
export type subscriber_property_value_max_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscriber_property_value_min_fields = {
  __typename: 'subscriber_property_value_min_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscriber_property_value" */
export type subscriber_property_value_min_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscriber_property_value" */
export type subscriber_property_value_mutation_response = {
  __typename: 'subscriber_property_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_property_value>;
};

/** Ordering options when selecting data from "subscriber_property_value". */
export type subscriber_property_value_order_by = {
  property?: InputMaybe<property_order_by>;
  property_id?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_boolean?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscriber_property_value_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscriber_property_value" */
export enum subscriber_property_value_select_column {
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  value = 'value',
  /** column name */
  value_boolean = 'value_boolean',
  /** column name */
  value_numeric = 'value_numeric',
  /** column name */
  value_text = 'value_text',
  /** column name */
  value_text_lower = 'value_text_lower',
  /** column name */
  value_timestamptz = 'value_timestamptz'
}

/** select "subscriber_property_value_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscriber_property_value" */
export enum subscriber_property_value_select_column_subscriber_property_value_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  value_boolean = 'value_boolean'
}

/** select "subscriber_property_value_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscriber_property_value" */
export enum subscriber_property_value_select_column_subscriber_property_value_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  value_boolean = 'value_boolean'
}

/** input type for updating data in table "subscriber_property_value" */
export type subscriber_property_value_set_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscriber_property_value_stddev_fields = {
  __typename: 'subscriber_property_value_stddev_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber_property_value" */
export type subscriber_property_value_stddev_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_property_value_stddev_pop_fields = {
  __typename: 'subscriber_property_value_stddev_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber_property_value" */
export type subscriber_property_value_stddev_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_property_value_stddev_samp_fields = {
  __typename: 'subscriber_property_value_stddev_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber_property_value" */
export type subscriber_property_value_stddev_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber_property_value" */
export type subscriber_property_value_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_property_value_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_property_value_stream_cursor_value_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_property_value_sum_fields = {
  __typename: 'subscriber_property_value_sum_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "subscriber_property_value" */
export type subscriber_property_value_sum_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

export type subscriber_property_value_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscriber_property_value_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscriber_property_value_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscriber_property_value_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscriber_property_value_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_property_value_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscriber_property_value_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_property_value_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_property_value_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_property_value_var_pop_fields = {
  __typename: 'subscriber_property_value_var_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_property_value" */
export type subscriber_property_value_var_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_property_value_var_samp_fields = {
  __typename: 'subscriber_property_value_var_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_property_value" */
export type subscriber_property_value_var_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_property_value_variance_fields = {
  __typename: 'subscriber_property_value_variance_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_property_value" */
export type subscriber_property_value_variance_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type subscriber_property_var_pop_fields = {
  __typename: 'subscriber_property_var_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber_property" */
export type subscriber_property_var_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_property_var_samp_fields = {
  __typename: 'subscriber_property_var_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber_property" */
export type subscriber_property_var_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_property_variance_fields = {
  __typename: 'subscriber_property_variance_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber_property" */
export type subscriber_property_variance_order_by = {
  property_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** select columns of table "subscriber" */
export enum subscriber_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  active_mrr = 'active_mrr',
  /** column name */
  active_offer_group_id = 'active_offer_group_id',
  /** column name */
  active_offer_id = 'active_offer_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  email = 'email',
  /** column name */
  hubspot_company_id = 'hubspot_company_id',
  /** column name */
  hubspot_contact_id = 'hubspot_contact_id',
  /** column name */
  id = 'id',
  /** column name */
  inactive_mrr = 'inactive_mrr',
  /** column name */
  intercom_contact_id = 'intercom_contact_id',
  /** column name */
  klaviyo_profile_id = 'klaviyo_profile_id',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  salesforce_contact_id = 'salesforce_contact_id',
  /** column name */
  segment_user_id = 'segment_user_id',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscriber" */
export type subscriber_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  active_mrr?: InputMaybe<Scalars['numeric']['input']>;
  active_offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  active_offer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_contact_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  intercom_contact_id?: InputMaybe<Scalars['String']['input']>;
  klaviyo_profile_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
  segment_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<subscriber_status_enum>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscriber_status" */
export type subscriber_status = {
  __typename: 'subscriber_status';
  /** An array relationship */
  subscribers: Array<subscriber>;
  /** An aggregate relationship */
  subscribers_aggregate: subscriber_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "subscriber_status" */
export type subscriber_statussubscribersArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


/** columns and relationships of "subscriber_status" */
export type subscriber_statussubscribers_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};

/** aggregated selection of "subscriber_status" */
export type subscriber_status_aggregate = {
  __typename: 'subscriber_status_aggregate';
  aggregate?: Maybe<subscriber_status_aggregate_fields>;
  nodes: Array<subscriber_status>;
};

/** aggregate fields of "subscriber_status" */
export type subscriber_status_aggregate_fields = {
  __typename: 'subscriber_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_status_max_fields>;
  min?: Maybe<subscriber_status_min_fields>;
};


/** aggregate fields of "subscriber_status" */
export type subscriber_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscriber_status". All fields are combined with a logical 'AND'. */
export type subscriber_status_bool_exp = {
  _and?: InputMaybe<Array<subscriber_status_bool_exp>>;
  _not?: InputMaybe<subscriber_status_bool_exp>;
  _or?: InputMaybe<Array<subscriber_status_bool_exp>>;
  subscribers?: InputMaybe<subscriber_bool_exp>;
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscriber_status" */
export enum subscriber_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscriber_status_pkey = 'subscriber_status_pkey'
}

export enum subscriber_status_enum {
  active = 'active',
  canceled = 'canceled',
  converted = 'converted',
  deflected = 'deflected',
  inactive = 'inactive',
  reactivated = 'reactivated',
  saved = 'saved'
}

/** Boolean expression to compare columns of type "subscriber_status_enum". All fields are combined with logical 'AND'. */
export type subscriber_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscriber_status_enum>;
  _in?: InputMaybe<Array<subscriber_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscriber_status_enum>;
  _nin?: InputMaybe<Array<subscriber_status_enum>>;
};

/** input type for inserting data into table "subscriber_status" */
export type subscriber_status_insert_input = {
  subscribers?: InputMaybe<subscriber_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscriber_status_max_fields = {
  __typename: 'subscriber_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscriber_status_min_fields = {
  __typename: 'subscriber_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscriber_status" */
export type subscriber_status_mutation_response = {
  __typename: 'subscriber_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscriber_status>;
};

/** input type for inserting object relation for remote table "subscriber_status" */
export type subscriber_status_obj_rel_insert_input = {
  data: subscriber_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscriber_status_on_conflict>;
};

/** on_conflict condition type for table "subscriber_status" */
export type subscriber_status_on_conflict = {
  constraint: subscriber_status_constraint;
  update_columns?: Array<subscriber_status_update_column>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};

/** Ordering options when selecting data from "subscriber_status". */
export type subscriber_status_order_by = {
  subscribers_aggregate?: InputMaybe<subscriber_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscriber_status */
export type subscriber_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscriber_status" */
export enum subscriber_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscriber_status" */
export type subscriber_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscriber_status" */
export type subscriber_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscriber_status" */
export enum subscriber_status_update_column {
  /** column name */
  value = 'value'
}

export type subscriber_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscriber_stddev_fields = {
  __typename: 'subscriber_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscriber" */
export type subscriber_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscriber_stddev_pop_fields = {
  __typename: 'subscriber_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscriber" */
export type subscriber_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscriber_stddev_samp_fields = {
  __typename: 'subscriber_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscriber" */
export type subscriber_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscriber" */
export type subscriber_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  active_mrr?: InputMaybe<Scalars['numeric']['input']>;
  active_offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  active_offer_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hubspot_company_id?: InputMaybe<Scalars['String']['input']>;
  hubspot_contact_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  intercom_contact_id?: InputMaybe<Scalars['String']['input']>;
  klaviyo_profile_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  salesforce_contact_id?: InputMaybe<Scalars['String']['input']>;
  segment_user_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<subscriber_status_enum>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscriber_sum_fields = {
  __typename: 'subscriber_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  active_mrr?: Maybe<Scalars['numeric']['output']>;
  active_offer_group_id?: Maybe<Scalars['Int']['output']>;
  active_offer_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscriber" */
export type subscriber_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscriber_tsvector" */
export type subscriber_tsvector = {
  __typename: 'subscriber_tsvector';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  tsvector?: Maybe<Scalars['tsvector']['output']>;
};

/** aggregated selection of "subscriber_tsvector" */
export type subscriber_tsvector_aggregate = {
  __typename: 'subscriber_tsvector_aggregate';
  aggregate?: Maybe<subscriber_tsvector_aggregate_fields>;
  nodes: Array<subscriber_tsvector>;
};

/** aggregate fields of "subscriber_tsvector" */
export type subscriber_tsvector_aggregate_fields = {
  __typename: 'subscriber_tsvector_aggregate_fields';
  avg?: Maybe<subscriber_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscriber_tsvector_max_fields>;
  min?: Maybe<subscriber_tsvector_min_fields>;
  stddev?: Maybe<subscriber_tsvector_stddev_fields>;
  stddev_pop?: Maybe<subscriber_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<subscriber_tsvector_stddev_samp_fields>;
  sum?: Maybe<subscriber_tsvector_sum_fields>;
  var_pop?: Maybe<subscriber_tsvector_var_pop_fields>;
  var_samp?: Maybe<subscriber_tsvector_var_samp_fields>;
  variance?: Maybe<subscriber_tsvector_variance_fields>;
};


/** aggregate fields of "subscriber_tsvector" */
export type subscriber_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscriber_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscriber_tsvector_avg_fields = {
  __typename: 'subscriber_tsvector_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscriber_tsvector". All fields are combined with a logical 'AND'. */
export type subscriber_tsvector_bool_exp = {
  _and?: InputMaybe<Array<subscriber_tsvector_bool_exp>>;
  _not?: InputMaybe<subscriber_tsvector_bool_exp>;
  _or?: InputMaybe<Array<subscriber_tsvector_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
};

/** aggregate max on columns */
export type subscriber_tsvector_max_fields = {
  __typename: 'subscriber_tsvector_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscriber_tsvector_min_fields = {
  __typename: 'subscriber_tsvector_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "subscriber_tsvector". */
export type subscriber_tsvector_order_by = {
  account_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  tsvector?: InputMaybe<order_by>;
};

/** select columns of table "subscriber_tsvector" */
export enum subscriber_tsvector_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  tsvector = 'tsvector'
}

/** aggregate stddev on columns */
export type subscriber_tsvector_stddev_fields = {
  __typename: 'subscriber_tsvector_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscriber_tsvector_stddev_pop_fields = {
  __typename: 'subscriber_tsvector_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscriber_tsvector_stddev_samp_fields = {
  __typename: 'subscriber_tsvector_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscriber_tsvector" */
export type subscriber_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscriber_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscriber_tsvector_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
};

/** aggregate sum on columns */
export type subscriber_tsvector_sum_fields = {
  __typename: 'subscriber_tsvector_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscriber_tsvector_var_pop_fields = {
  __typename: 'subscriber_tsvector_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscriber_tsvector_var_samp_fields = {
  __typename: 'subscriber_tsvector_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscriber_tsvector_variance_fields = {
  __typename: 'subscriber_tsvector_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "subscriber" */
export enum subscriber_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  active_mrr = 'active_mrr',
  /** column name */
  active_offer_group_id = 'active_offer_group_id',
  /** column name */
  active_offer_id = 'active_offer_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  email = 'email',
  /** column name */
  hubspot_company_id = 'hubspot_company_id',
  /** column name */
  hubspot_contact_id = 'hubspot_contact_id',
  /** column name */
  id = 'id',
  /** column name */
  inactive_mrr = 'inactive_mrr',
  /** column name */
  intercom_contact_id = 'intercom_contact_id',
  /** column name */
  klaviyo_profile_id = 'klaviyo_profile_id',
  /** column name */
  name = 'name',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  salesforce_contact_id = 'salesforce_contact_id',
  /** column name */
  segment_user_id = 'segment_user_id',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type subscriber_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscriber_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscriber_set_input>;
  /** filter the rows which have to be updated */
  where: subscriber_bool_exp;
};

/** aggregate var_pop on columns */
export type subscriber_var_pop_fields = {
  __typename: 'subscriber_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscriber" */
export type subscriber_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscriber_var_samp_fields = {
  __typename: 'subscriber_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscriber" */
export type subscriber_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscriber_variance_fields = {
  __typename: 'subscriber_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  active_mrr?: Maybe<Scalars['Float']['output']>;
  active_offer_group_id?: Maybe<Scalars['Float']['output']>;
  active_offer_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscriber" */
export type subscriber_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  active_mrr?: InputMaybe<order_by>;
  active_offer_group_id?: InputMaybe<order_by>;
  active_offer_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscription" */
export type subscription = {
  __typename: 'subscription';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  base_currency?: Maybe<Scalars['String']['output']>;
  base_mrr?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  most_recent_subscriber_flow?: Maybe<subscription_most_recent_subscriber_flow>;
  mrr: Scalars['numeric']['output'];
  platform_connection_id: Scalars['Int']['output'];
  platform_id: Scalars['String']['output'];
  /** An object relationship */
  platform_subscription?: Maybe<platform_subscription>;
  /** An array relationship */
  revenue_logs: Array<revenue_log>;
  /** An aggregate relationship */
  revenue_logs_aggregate: revenue_log_aggregate;
  status: subscription_status_enum;
  /** An object relationship */
  subscriber: subscriber;
  /** An array relationship */
  subscriber_campaigns: Array<subscriber_campaign>;
  /** An aggregate relationship */
  subscriber_campaigns_aggregate: subscriber_campaign_aggregate;
  /** An array relationship */
  subscriber_flows: Array<subscriber_flow>;
  /** An aggregate relationship */
  subscriber_flows_aggregate: subscriber_flow_aggregate;
  subscriber_id: Scalars['Int']['output'];
  /** An array relationship */
  subscriber_logs: Array<subscriber_log>;
  /** An aggregate relationship */
  subscriber_logs_aggregate: subscriber_log_aggregate;
  /** An object relationship */
  subscription_arr?: Maybe<subscription_arr>;
  /** An object relationship */
  subscription_duration?: Maybe<subscription_duration>;
  /** An object relationship */
  subscription_ltv?: Maybe<subscription_ltv>;
  /** An array relationship */
  subscription_products: Array<subscription_product>;
  /** An aggregate relationship */
  subscription_products_aggregate: subscription_product_aggregate;
  /** An array relationship */
  subscription_properties: Array<subscription_property>;
  /** An aggregate relationship */
  subscription_properties_aggregate: subscription_property_aggregate;
  /** An array relationship */
  subscription_property_values: Array<subscription_property_value>;
  /** An aggregate relationship */
  subscription_property_values_aggregate: subscription_property_value_aggregate;
  /** An object relationship */
  subscription_status: subscription_status;
  token: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "subscription" */
export type subscriptionrevenue_logsArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionrevenue_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_campaignsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_campaigns_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_flowsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_flows_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_logsArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscriber_logs_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_productsArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_products_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_propertiesArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_properties_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_property_valuesArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


/** columns and relationships of "subscription" */
export type subscriptionsubscription_property_values_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};

/** aggregated selection of "subscription" */
export type subscription_aggregate = {
  __typename: 'subscription_aggregate';
  aggregate?: Maybe<subscription_aggregate_fields>;
  nodes: Array<subscription>;
};

export type subscription_aggregate_bool_exp = {
  count?: InputMaybe<subscription_aggregate_bool_exp_count>;
};

export type subscription_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscription" */
export type subscription_aggregate_fields = {
  __typename: 'subscription_aggregate_fields';
  avg?: Maybe<subscription_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_max_fields>;
  min?: Maybe<subscription_min_fields>;
  stddev?: Maybe<subscription_stddev_fields>;
  stddev_pop?: Maybe<subscription_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_stddev_samp_fields>;
  sum?: Maybe<subscription_sum_fields>;
  var_pop?: Maybe<subscription_var_pop_fields>;
  var_samp?: Maybe<subscription_var_samp_fields>;
  variance?: Maybe<subscription_variance_fields>;
};


/** aggregate fields of "subscription" */
export type subscription_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscription" */
export type subscription_aggregate_order_by = {
  avg?: InputMaybe<subscription_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscription_max_order_by>;
  min?: InputMaybe<subscription_min_order_by>;
  stddev?: InputMaybe<subscription_stddev_order_by>;
  stddev_pop?: InputMaybe<subscription_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscription_stddev_samp_order_by>;
  sum?: InputMaybe<subscription_sum_order_by>;
  var_pop?: InputMaybe<subscription_var_pop_order_by>;
  var_samp?: InputMaybe<subscription_var_samp_order_by>;
  variance?: InputMaybe<subscription_variance_order_by>;
};

/** columns and relationships of "subscription_amount_paid_by_month" */
export type subscription_amount_paid_by_month = {
  __typename: 'subscription_amount_paid_by_month';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "subscription_amount_paid_by_month" */
export type subscription_amount_paid_by_month_aggregate = {
  __typename: 'subscription_amount_paid_by_month_aggregate';
  aggregate?: Maybe<subscription_amount_paid_by_month_aggregate_fields>;
  nodes: Array<subscription_amount_paid_by_month>;
};

/** aggregate fields of "subscription_amount_paid_by_month" */
export type subscription_amount_paid_by_month_aggregate_fields = {
  __typename: 'subscription_amount_paid_by_month_aggregate_fields';
  avg?: Maybe<subscription_amount_paid_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_amount_paid_by_month_max_fields>;
  min?: Maybe<subscription_amount_paid_by_month_min_fields>;
  stddev?: Maybe<subscription_amount_paid_by_month_stddev_fields>;
  stddev_pop?: Maybe<subscription_amount_paid_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_amount_paid_by_month_stddev_samp_fields>;
  sum?: Maybe<subscription_amount_paid_by_month_sum_fields>;
  var_pop?: Maybe<subscription_amount_paid_by_month_var_pop_fields>;
  var_samp?: Maybe<subscription_amount_paid_by_month_var_samp_fields>;
  variance?: Maybe<subscription_amount_paid_by_month_variance_fields>;
};


/** aggregate fields of "subscription_amount_paid_by_month" */
export type subscription_amount_paid_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_amount_paid_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_amount_paid_by_month_avg_fields = {
  __typename: 'subscription_amount_paid_by_month_avg_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_amount_paid_by_month". All fields are combined with a logical 'AND'. */
export type subscription_amount_paid_by_month_bool_exp = {
  _and?: InputMaybe<Array<subscription_amount_paid_by_month_bool_exp>>;
  _not?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
  _or?: InputMaybe<Array<subscription_amount_paid_by_month_bool_exp>>;
  amount_paid?: InputMaybe<numeric_comparison_exp>;
  month?: InputMaybe<String_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_subscription_id?: InputMaybe<String_comparison_exp>;
};

/** aggregate max on columns */
export type subscription_amount_paid_by_month_max_fields = {
  __typename: 'subscription_amount_paid_by_month_max_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscription_amount_paid_by_month_min_fields = {
  __typename: 'subscription_amount_paid_by_month_min_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_subscription_id?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscription_amount_paid_by_month". */
export type subscription_amount_paid_by_month_order_by = {
  amount_paid?: InputMaybe<order_by>;
  month?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_subscription_id?: InputMaybe<order_by>;
};

/** select columns of table "subscription_amount_paid_by_month" */
export enum subscription_amount_paid_by_month_select_column {
  /** column name */
  amount_paid = 'amount_paid',
  /** column name */
  month = 'month',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_subscription_id = 'platform_subscription_id'
}

/** aggregate stddev on columns */
export type subscription_amount_paid_by_month_stddev_fields = {
  __typename: 'subscription_amount_paid_by_month_stddev_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_amount_paid_by_month_stddev_pop_fields = {
  __typename: 'subscription_amount_paid_by_month_stddev_pop_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_amount_paid_by_month_stddev_samp_fields = {
  __typename: 'subscription_amount_paid_by_month_stddev_samp_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_amount_paid_by_month" */
export type subscription_amount_paid_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_amount_paid_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_amount_paid_by_month_stream_cursor_value_input = {
  amount_paid?: InputMaybe<Scalars['numeric']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_subscription_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type subscription_amount_paid_by_month_sum_fields = {
  __typename: 'subscription_amount_paid_by_month_sum_fields';
  amount_paid?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_amount_paid_by_month_var_pop_fields = {
  __typename: 'subscription_amount_paid_by_month_var_pop_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_amount_paid_by_month_var_samp_fields = {
  __typename: 'subscription_amount_paid_by_month_var_samp_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_amount_paid_by_month_variance_fields = {
  __typename: 'subscription_amount_paid_by_month_variance_fields';
  amount_paid?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "subscription_arr" */
export type subscription_arr = {
  __typename: 'subscription_arr';
  arr?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscription_arr" */
export type subscription_arr_aggregate = {
  __typename: 'subscription_arr_aggregate';
  aggregate?: Maybe<subscription_arr_aggregate_fields>;
  nodes: Array<subscription_arr>;
};

/** aggregate fields of "subscription_arr" */
export type subscription_arr_aggregate_fields = {
  __typename: 'subscription_arr_aggregate_fields';
  avg?: Maybe<subscription_arr_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_arr_max_fields>;
  min?: Maybe<subscription_arr_min_fields>;
  stddev?: Maybe<subscription_arr_stddev_fields>;
  stddev_pop?: Maybe<subscription_arr_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_arr_stddev_samp_fields>;
  sum?: Maybe<subscription_arr_sum_fields>;
  var_pop?: Maybe<subscription_arr_var_pop_fields>;
  var_samp?: Maybe<subscription_arr_var_samp_fields>;
  variance?: Maybe<subscription_arr_variance_fields>;
};


/** aggregate fields of "subscription_arr" */
export type subscription_arr_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_arr_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_arr_avg_fields = {
  __typename: 'subscription_arr_avg_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_arr". All fields are combined with a logical 'AND'. */
export type subscription_arr_bool_exp = {
  _and?: InputMaybe<Array<subscription_arr_bool_exp>>;
  _not?: InputMaybe<subscription_arr_bool_exp>;
  _or?: InputMaybe<Array<subscription_arr_bool_exp>>;
  arr?: InputMaybe<numeric_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for incrementing numeric columns in table "subscription_arr" */
export type subscription_arr_inc_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription_arr" */
export type subscription_arr_insert_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscription_arr_max_fields = {
  __typename: 'subscription_arr_max_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscription_arr_min_fields = {
  __typename: 'subscription_arr_min_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscription_arr" */
export type subscription_arr_mutation_response = {
  __typename: 'subscription_arr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_arr>;
};

/** input type for inserting object relation for remote table "subscription_arr" */
export type subscription_arr_obj_rel_insert_input = {
  data: subscription_arr_insert_input;
};

/** Ordering options when selecting data from "subscription_arr". */
export type subscription_arr_order_by = {
  arr?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** input type for inserting array relation for remote table "subscription" */
export type subscription_arr_rel_insert_input = {
  data: Array<subscription_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscription_on_conflict>;
};

/** select columns of table "subscription_arr" */
export enum subscription_arr_select_column {
  /** column name */
  arr = 'arr',
  /** column name */
  subscription_id = 'subscription_id'
}

/** input type for updating data in table "subscription_arr" */
export type subscription_arr_set_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscription_arr_stddev_fields = {
  __typename: 'subscription_arr_stddev_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_arr_stddev_pop_fields = {
  __typename: 'subscription_arr_stddev_pop_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_arr_stddev_samp_fields = {
  __typename: 'subscription_arr_stddev_samp_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_arr" */
export type subscription_arr_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_arr_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_arr_stream_cursor_value_input = {
  arr?: InputMaybe<Scalars['numeric']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_arr_sum_fields = {
  __typename: 'subscription_arr_sum_fields';
  arr?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

export type subscription_arr_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_arr_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_arr_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_arr_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_arr_var_pop_fields = {
  __typename: 'subscription_arr_var_pop_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_arr_var_samp_fields = {
  __typename: 'subscription_arr_var_samp_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_arr_variance_fields = {
  __typename: 'subscription_arr_variance_fields';
  arr?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type subscription_avg_fields = {
  __typename: 'subscription_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscription" */
export type subscription_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscription". All fields are combined with a logical 'AND'. */
export type subscription_bool_exp = {
  _and?: InputMaybe<Array<subscription_bool_exp>>;
  _not?: InputMaybe<subscription_bool_exp>;
  _or?: InputMaybe<Array<subscription_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  base_currency?: InputMaybe<String_comparison_exp>;
  base_mrr?: InputMaybe<numeric_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  most_recent_subscriber_flow?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
  mrr?: InputMaybe<numeric_comparison_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  platform_id?: InputMaybe<String_comparison_exp>;
  platform_subscription?: InputMaybe<platform_subscription_bool_exp>;
  revenue_logs?: InputMaybe<revenue_log_bool_exp>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_bool_exp>;
  status?: InputMaybe<subscription_status_enum_comparison_exp>;
  subscriber?: InputMaybe<subscriber_bool_exp>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_bool_exp>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_bool_exp>;
  subscriber_flows?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_bool_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscriber_logs?: InputMaybe<subscriber_log_bool_exp>;
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_bool_exp>;
  subscription_arr?: InputMaybe<subscription_arr_bool_exp>;
  subscription_duration?: InputMaybe<subscription_duration_bool_exp>;
  subscription_ltv?: InputMaybe<subscription_ltv_bool_exp>;
  subscription_products?: InputMaybe<subscription_product_bool_exp>;
  subscription_products_aggregate?: InputMaybe<subscription_product_aggregate_bool_exp>;
  subscription_properties?: InputMaybe<subscription_property_bool_exp>;
  subscription_properties_aggregate?: InputMaybe<subscription_property_aggregate_bool_exp>;
  subscription_property_values?: InputMaybe<subscription_property_value_bool_exp>;
  subscription_property_values_aggregate?: InputMaybe<subscription_property_value_aggregate_bool_exp>;
  subscription_status?: InputMaybe<subscription_status_bool_exp>;
  token?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscription" */
export enum subscription_constraint {
  /** unique or primary key constraint on columns "account_id", "platform_id" */
  subscription_account_id_platform_id_key = 'subscription_account_id_platform_id_key',
  /** unique or primary key constraint on columns "id" */
  subscription_pkey = 'subscription_pkey',
  /** unique or primary key constraint on columns "token" */
  subscription_token_key = 'subscription_token_key'
}

/** columns and relationships of "subscription_duration" */
export type subscription_duration = {
  __typename: 'subscription_duration';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscription?: Maybe<subscription>;
};

/** aggregated selection of "subscription_duration" */
export type subscription_duration_aggregate = {
  __typename: 'subscription_duration_aggregate';
  aggregate?: Maybe<subscription_duration_aggregate_fields>;
  nodes: Array<subscription_duration>;
};

/** aggregate fields of "subscription_duration" */
export type subscription_duration_aggregate_fields = {
  __typename: 'subscription_duration_aggregate_fields';
  avg?: Maybe<subscription_duration_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_duration_max_fields>;
  min?: Maybe<subscription_duration_min_fields>;
  stddev?: Maybe<subscription_duration_stddev_fields>;
  stddev_pop?: Maybe<subscription_duration_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_duration_stddev_samp_fields>;
  sum?: Maybe<subscription_duration_sum_fields>;
  var_pop?: Maybe<subscription_duration_var_pop_fields>;
  var_samp?: Maybe<subscription_duration_var_samp_fields>;
  variance?: Maybe<subscription_duration_variance_fields>;
};


/** aggregate fields of "subscription_duration" */
export type subscription_duration_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_duration_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_duration_avg_fields = {
  __typename: 'subscription_duration_avg_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_duration". All fields are combined with a logical 'AND'. */
export type subscription_duration_bool_exp = {
  _and?: InputMaybe<Array<subscription_duration_bool_exp>>;
  _not?: InputMaybe<subscription_duration_bool_exp>;
  _or?: InputMaybe<Array<subscription_duration_bool_exp>>;
  days?: InputMaybe<Int_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
};

/** input type for inserting data into table "subscription_duration" */
export type subscription_duration_insert_input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type subscription_duration_max_fields = {
  __typename: 'subscription_duration_max_fields';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscription_duration_min_fields = {
  __typename: 'subscription_duration_min_fields';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscription_duration" */
export type subscription_duration_obj_rel_insert_input = {
  data: subscription_duration_insert_input;
};

/** Ordering options when selecting data from "subscription_duration". */
export type subscription_duration_order_by = {
  days?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
};

/** select columns of table "subscription_duration" */
export enum subscription_duration_select_column {
  /** column name */
  days = 'days',
  /** column name */
  id = 'id'
}

/** aggregate stddev on columns */
export type subscription_duration_stddev_fields = {
  __typename: 'subscription_duration_stddev_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_duration_stddev_pop_fields = {
  __typename: 'subscription_duration_stddev_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_duration_stddev_samp_fields = {
  __typename: 'subscription_duration_stddev_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_duration" */
export type subscription_duration_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_duration_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_duration_stream_cursor_value_input = {
  days?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_duration_sum_fields = {
  __typename: 'subscription_duration_sum_fields';
  days?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_duration_var_pop_fields = {
  __typename: 'subscription_duration_var_pop_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_duration_var_samp_fields = {
  __typename: 'subscription_duration_var_samp_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_duration_variance_fields = {
  __typename: 'subscription_duration_variance_fields';
  days?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "subscription" */
export type subscription_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_mrr?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription" */
export type subscription_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_currency?: InputMaybe<Scalars['String']['input']>;
  base_mrr?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  most_recent_subscriber_flow?: InputMaybe<subscription_most_recent_subscriber_flow_obj_rel_insert_input>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  platform_subscription?: InputMaybe<platform_subscription_obj_rel_insert_input>;
  revenue_logs?: InputMaybe<revenue_log_arr_rel_insert_input>;
  status?: InputMaybe<subscription_status_enum>;
  subscriber?: InputMaybe<subscriber_obj_rel_insert_input>;
  subscriber_campaigns?: InputMaybe<subscriber_campaign_arr_rel_insert_input>;
  subscriber_flows?: InputMaybe<subscriber_flow_arr_rel_insert_input>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_logs?: InputMaybe<subscriber_log_arr_rel_insert_input>;
  subscription_arr?: InputMaybe<subscription_arr_obj_rel_insert_input>;
  subscription_duration?: InputMaybe<subscription_duration_obj_rel_insert_input>;
  subscription_ltv?: InputMaybe<subscription_ltv_obj_rel_insert_input>;
  subscription_products?: InputMaybe<subscription_product_arr_rel_insert_input>;
  subscription_properties?: InputMaybe<subscription_property_arr_rel_insert_input>;
  subscription_property_values?: InputMaybe<subscription_property_value_arr_rel_insert_input>;
  subscription_status?: InputMaybe<subscription_status_obj_rel_insert_input>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscription_ltv" */
export type subscription_ltv = {
  __typename: 'subscription_ltv';
  ltv?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscription_ltv" */
export type subscription_ltv_aggregate = {
  __typename: 'subscription_ltv_aggregate';
  aggregate?: Maybe<subscription_ltv_aggregate_fields>;
  nodes: Array<subscription_ltv>;
};

/** aggregate fields of "subscription_ltv" */
export type subscription_ltv_aggregate_fields = {
  __typename: 'subscription_ltv_aggregate_fields';
  avg?: Maybe<subscription_ltv_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_ltv_max_fields>;
  min?: Maybe<subscription_ltv_min_fields>;
  stddev?: Maybe<subscription_ltv_stddev_fields>;
  stddev_pop?: Maybe<subscription_ltv_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_ltv_stddev_samp_fields>;
  sum?: Maybe<subscription_ltv_sum_fields>;
  var_pop?: Maybe<subscription_ltv_var_pop_fields>;
  var_samp?: Maybe<subscription_ltv_var_samp_fields>;
  variance?: Maybe<subscription_ltv_variance_fields>;
};


/** aggregate fields of "subscription_ltv" */
export type subscription_ltv_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_ltv_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_ltv_avg_fields = {
  __typename: 'subscription_ltv_avg_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_ltv". All fields are combined with a logical 'AND'. */
export type subscription_ltv_bool_exp = {
  _and?: InputMaybe<Array<subscription_ltv_bool_exp>>;
  _not?: InputMaybe<subscription_ltv_bool_exp>;
  _or?: InputMaybe<Array<subscription_ltv_bool_exp>>;
  ltv?: InputMaybe<numeric_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscription_ltv" */
export type subscription_ltv_insert_input = {
  ltv?: InputMaybe<Scalars['numeric']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscription_ltv_max_fields = {
  __typename: 'subscription_ltv_max_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscription_ltv_min_fields = {
  __typename: 'subscription_ltv_min_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscription_ltv" */
export type subscription_ltv_obj_rel_insert_input = {
  data: subscription_ltv_insert_input;
};

/** Ordering options when selecting data from "subscription_ltv". */
export type subscription_ltv_order_by = {
  ltv?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** select columns of table "subscription_ltv" */
export enum subscription_ltv_select_column {
  /** column name */
  ltv = 'ltv',
  /** column name */
  subscription_id = 'subscription_id'
}

/** aggregate stddev on columns */
export type subscription_ltv_stddev_fields = {
  __typename: 'subscription_ltv_stddev_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_ltv_stddev_pop_fields = {
  __typename: 'subscription_ltv_stddev_pop_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_ltv_stddev_samp_fields = {
  __typename: 'subscription_ltv_stddev_samp_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_ltv" */
export type subscription_ltv_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_ltv_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_ltv_stream_cursor_value_input = {
  ltv?: InputMaybe<Scalars['numeric']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_ltv_sum_fields = {
  __typename: 'subscription_ltv_sum_fields';
  ltv?: Maybe<Scalars['numeric']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_ltv_var_pop_fields = {
  __typename: 'subscription_ltv_var_pop_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_ltv_var_samp_fields = {
  __typename: 'subscription_ltv_var_samp_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_ltv_variance_fields = {
  __typename: 'subscription_ltv_variance_fields';
  ltv?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type subscription_max_fields = {
  __typename: 'subscription_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_currency?: Maybe<Scalars['String']['output']>;
  base_mrr?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscription" */
export type subscription_max_order_by = {
  account_id?: InputMaybe<order_by>;
  base_currency?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscription_min_fields = {
  __typename: 'subscription_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_currency?: Maybe<Scalars['String']['output']>;
  base_mrr?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  platform_id?: Maybe<Scalars['String']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscription" */
export type subscription_min_order_by = {
  account_id?: InputMaybe<order_by>;
  base_currency?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** columns and relationships of "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow = {
  __typename: 'subscription_most_recent_subscriber_flow';
  /** An object relationship */
  subscriber_flow?: Maybe<subscriber_flow>;
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_aggregate = {
  __typename: 'subscription_most_recent_subscriber_flow_aggregate';
  aggregate?: Maybe<subscription_most_recent_subscriber_flow_aggregate_fields>;
  nodes: Array<subscription_most_recent_subscriber_flow>;
};

/** aggregate fields of "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_aggregate_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_aggregate_fields';
  avg?: Maybe<subscription_most_recent_subscriber_flow_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_most_recent_subscriber_flow_max_fields>;
  min?: Maybe<subscription_most_recent_subscriber_flow_min_fields>;
  stddev?: Maybe<subscription_most_recent_subscriber_flow_stddev_fields>;
  stddev_pop?: Maybe<subscription_most_recent_subscriber_flow_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_most_recent_subscriber_flow_stddev_samp_fields>;
  sum?: Maybe<subscription_most_recent_subscriber_flow_sum_fields>;
  var_pop?: Maybe<subscription_most_recent_subscriber_flow_var_pop_fields>;
  var_samp?: Maybe<subscription_most_recent_subscriber_flow_var_samp_fields>;
  variance?: Maybe<subscription_most_recent_subscriber_flow_variance_fields>;
};


/** aggregate fields of "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_most_recent_subscriber_flow_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_most_recent_subscriber_flow_avg_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_avg_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_most_recent_subscriber_flow". All fields are combined with a logical 'AND'. */
export type subscription_most_recent_subscriber_flow_bool_exp = {
  _and?: InputMaybe<Array<subscription_most_recent_subscriber_flow_bool_exp>>;
  _not?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
  _or?: InputMaybe<Array<subscription_most_recent_subscriber_flow_bool_exp>>;
  subscriber_flow?: InputMaybe<subscriber_flow_bool_exp>;
  subscriber_flow_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** input type for inserting data into table "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_insert_input = {
  subscriber_flow?: InputMaybe<subscriber_flow_obj_rel_insert_input>;
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscription_most_recent_subscriber_flow_max_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_max_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscription_most_recent_subscriber_flow_min_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_min_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_obj_rel_insert_input = {
  data: subscription_most_recent_subscriber_flow_insert_input;
};

/** Ordering options when selecting data from "subscription_most_recent_subscriber_flow". */
export type subscription_most_recent_subscriber_flow_order_by = {
  subscriber_flow?: InputMaybe<subscriber_flow_order_by>;
  subscriber_flow_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** select columns of table "subscription_most_recent_subscriber_flow" */
export enum subscription_most_recent_subscriber_flow_select_column {
  /** column name */
  subscriber_flow_id = 'subscriber_flow_id',
  /** column name */
  subscription_id = 'subscription_id'
}

/** aggregate stddev on columns */
export type subscription_most_recent_subscriber_flow_stddev_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_stddev_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_most_recent_subscriber_flow_stddev_pop_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_stddev_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_most_recent_subscriber_flow_stddev_samp_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_stddev_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_most_recent_subscriber_flow" */
export type subscription_most_recent_subscriber_flow_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_most_recent_subscriber_flow_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_most_recent_subscriber_flow_stream_cursor_value_input = {
  subscriber_flow_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_most_recent_subscriber_flow_sum_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_sum_fields';
  subscriber_flow_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_most_recent_subscriber_flow_var_pop_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_var_pop_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_most_recent_subscriber_flow_var_samp_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_var_samp_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_most_recent_subscriber_flow_variance_fields = {
  __typename: 'subscription_most_recent_subscriber_flow_variance_fields';
  subscriber_flow_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** response of any mutation on the table "subscription" */
export type subscription_mutation_response = {
  __typename: 'subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription>;
};

/** input type for inserting object relation for remote table "subscription" */
export type subscription_obj_rel_insert_input = {
  data: subscription_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscription_on_conflict>;
};

/** on_conflict condition type for table "subscription" */
export type subscription_on_conflict = {
  constraint: subscription_constraint;
  update_columns?: Array<subscription_update_column>;
  where?: InputMaybe<subscription_bool_exp>;
};

/** Ordering options when selecting data from "subscription". */
export type subscription_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  base_currency?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  most_recent_subscriber_flow?: InputMaybe<subscription_most_recent_subscriber_flow_order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  platform_id?: InputMaybe<order_by>;
  platform_subscription?: InputMaybe<platform_subscription_order_by>;
  revenue_logs_aggregate?: InputMaybe<revenue_log_aggregate_order_by>;
  status?: InputMaybe<order_by>;
  subscriber?: InputMaybe<subscriber_order_by>;
  subscriber_campaigns_aggregate?: InputMaybe<subscriber_campaign_aggregate_order_by>;
  subscriber_flows_aggregate?: InputMaybe<subscriber_flow_aggregate_order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscriber_logs_aggregate?: InputMaybe<subscriber_log_aggregate_order_by>;
  subscription_arr?: InputMaybe<subscription_arr_order_by>;
  subscription_duration?: InputMaybe<subscription_duration_order_by>;
  subscription_ltv?: InputMaybe<subscription_ltv_order_by>;
  subscription_products_aggregate?: InputMaybe<subscription_product_aggregate_order_by>;
  subscription_properties_aggregate?: InputMaybe<subscription_property_aggregate_order_by>;
  subscription_property_values_aggregate?: InputMaybe<subscription_property_value_aggregate_order_by>;
  subscription_status?: InputMaybe<subscription_status_order_by>;
  token?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription */
export type subscription_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "subscription_product" */
export type subscription_product = {
  __typename: 'subscription_product';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  product: product;
  product_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscription_product" */
export type subscription_product_aggregate = {
  __typename: 'subscription_product_aggregate';
  aggregate?: Maybe<subscription_product_aggregate_fields>;
  nodes: Array<subscription_product>;
};

export type subscription_product_aggregate_bool_exp = {
  count?: InputMaybe<subscription_product_aggregate_bool_exp_count>;
};

export type subscription_product_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscription_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_product_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscription_product" */
export type subscription_product_aggregate_fields = {
  __typename: 'subscription_product_aggregate_fields';
  avg?: Maybe<subscription_product_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_product_max_fields>;
  min?: Maybe<subscription_product_min_fields>;
  stddev?: Maybe<subscription_product_stddev_fields>;
  stddev_pop?: Maybe<subscription_product_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_product_stddev_samp_fields>;
  sum?: Maybe<subscription_product_sum_fields>;
  var_pop?: Maybe<subscription_product_var_pop_fields>;
  var_samp?: Maybe<subscription_product_var_samp_fields>;
  variance?: Maybe<subscription_product_variance_fields>;
};


/** aggregate fields of "subscription_product" */
export type subscription_product_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_product_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscription_product" */
export type subscription_product_aggregate_order_by = {
  avg?: InputMaybe<subscription_product_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscription_product_max_order_by>;
  min?: InputMaybe<subscription_product_min_order_by>;
  stddev?: InputMaybe<subscription_product_stddev_order_by>;
  stddev_pop?: InputMaybe<subscription_product_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscription_product_stddev_samp_order_by>;
  sum?: InputMaybe<subscription_product_sum_order_by>;
  var_pop?: InputMaybe<subscription_product_var_pop_order_by>;
  var_samp?: InputMaybe<subscription_product_var_samp_order_by>;
  variance?: InputMaybe<subscription_product_variance_order_by>;
};

/** input type for inserting array relation for remote table "subscription_product" */
export type subscription_product_arr_rel_insert_input = {
  data: Array<subscription_product_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscription_product_on_conflict>;
};

/** aggregate avg on columns */
export type subscription_product_avg_fields = {
  __typename: 'subscription_product_avg_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscription_product" */
export type subscription_product_avg_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscription_product". All fields are combined with a logical 'AND'. */
export type subscription_product_bool_exp = {
  _and?: InputMaybe<Array<subscription_product_bool_exp>>;
  _not?: InputMaybe<subscription_product_bool_exp>;
  _or?: InputMaybe<Array<subscription_product_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  product?: InputMaybe<product_bool_exp>;
  product_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscription_product" */
export enum subscription_product_constraint {
  /** unique or primary key constraint on columns "subscription_id", "product_id" */
  subscription_product_pkey = 'subscription_product_pkey'
}

/** input type for incrementing numeric columns in table "subscription_product" */
export type subscription_product_inc_input = {
  product_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription_product" */
export type subscription_product_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  product?: InputMaybe<product_obj_rel_insert_input>;
  product_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscription_product_max_fields = {
  __typename: 'subscription_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  product_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscription_product" */
export type subscription_product_max_order_by = {
  created_at?: InputMaybe<order_by>;
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscription_product_min_fields = {
  __typename: 'subscription_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  product_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscription_product" */
export type subscription_product_min_order_by = {
  created_at?: InputMaybe<order_by>;
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscription_product" */
export type subscription_product_mutation_response = {
  __typename: 'subscription_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_product>;
};

/** on_conflict condition type for table "subscription_product" */
export type subscription_product_on_conflict = {
  constraint: subscription_product_constraint;
  update_columns?: Array<subscription_product_update_column>;
  where?: InputMaybe<subscription_product_bool_exp>;
};

/** Ordering options when selecting data from "subscription_product". */
export type subscription_product_order_by = {
  created_at?: InputMaybe<order_by>;
  product?: InputMaybe<product_order_by>;
  product_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription_product */
export type subscription_product_pk_columns_input = {
  product_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};

/** select columns of table "subscription_product" */
export enum subscription_product_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  product_id = 'product_id',
  /** column name */
  subscription_id = 'subscription_id'
}

/** input type for updating data in table "subscription_product" */
export type subscription_product_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  product_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type subscription_product_stddev_fields = {
  __typename: 'subscription_product_stddev_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscription_product" */
export type subscription_product_stddev_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscription_product_stddev_pop_fields = {
  __typename: 'subscription_product_stddev_pop_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscription_product" */
export type subscription_product_stddev_pop_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscription_product_stddev_samp_fields = {
  __typename: 'subscription_product_stddev_samp_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscription_product" */
export type subscription_product_stddev_samp_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscription_product" */
export type subscription_product_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_product_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_product_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  product_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_product_sum_fields = {
  __typename: 'subscription_product_sum_fields';
  product_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscription_product" */
export type subscription_product_sum_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** update columns of table "subscription_product" */
export enum subscription_product_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  product_id = 'product_id',
  /** column name */
  subscription_id = 'subscription_id'
}

export type subscription_product_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_product_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_product_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_product_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_product_var_pop_fields = {
  __typename: 'subscription_product_var_pop_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscription_product" */
export type subscription_product_var_pop_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscription_product_var_samp_fields = {
  __typename: 'subscription_product_var_samp_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscription_product" */
export type subscription_product_var_samp_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscription_product_variance_fields = {
  __typename: 'subscription_product_variance_fields';
  product_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscription_product" */
export type subscription_product_variance_order_by = {
  product_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscription_property" */
export type subscription_property = {
  __typename: 'subscription_property';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  property: property;
  property_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "subscription_property" */
export type subscription_propertyvalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "subscription_property" */
export type subscription_property_aggregate = {
  __typename: 'subscription_property_aggregate';
  aggregate?: Maybe<subscription_property_aggregate_fields>;
  nodes: Array<subscription_property>;
};

export type subscription_property_aggregate_bool_exp = {
  count?: InputMaybe<subscription_property_aggregate_bool_exp_count>;
};

export type subscription_property_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscription_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_property_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscription_property" */
export type subscription_property_aggregate_fields = {
  __typename: 'subscription_property_aggregate_fields';
  avg?: Maybe<subscription_property_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_property_max_fields>;
  min?: Maybe<subscription_property_min_fields>;
  stddev?: Maybe<subscription_property_stddev_fields>;
  stddev_pop?: Maybe<subscription_property_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_property_stddev_samp_fields>;
  sum?: Maybe<subscription_property_sum_fields>;
  var_pop?: Maybe<subscription_property_var_pop_fields>;
  var_samp?: Maybe<subscription_property_var_samp_fields>;
  variance?: Maybe<subscription_property_variance_fields>;
};


/** aggregate fields of "subscription_property" */
export type subscription_property_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscription_property" */
export type subscription_property_aggregate_order_by = {
  avg?: InputMaybe<subscription_property_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscription_property_max_order_by>;
  min?: InputMaybe<subscription_property_min_order_by>;
  stddev?: InputMaybe<subscription_property_stddev_order_by>;
  stddev_pop?: InputMaybe<subscription_property_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscription_property_stddev_samp_order_by>;
  sum?: InputMaybe<subscription_property_sum_order_by>;
  var_pop?: InputMaybe<subscription_property_var_pop_order_by>;
  var_samp?: InputMaybe<subscription_property_var_samp_order_by>;
  variance?: InputMaybe<subscription_property_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscription_property_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscription_property" */
export type subscription_property_arr_rel_insert_input = {
  data: Array<subscription_property_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<subscription_property_on_conflict>;
};

/** aggregate avg on columns */
export type subscription_property_avg_fields = {
  __typename: 'subscription_property_avg_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscription_property" */
export type subscription_property_avg_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscription_property". All fields are combined with a logical 'AND'. */
export type subscription_property_bool_exp = {
  _and?: InputMaybe<Array<subscription_property_bool_exp>>;
  _not?: InputMaybe<subscription_property_bool_exp>;
  _or?: InputMaybe<Array<subscription_property_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  property?: InputMaybe<property_bool_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "subscription_property" */
export enum subscription_property_constraint {
  /** unique or primary key constraint on columns "subscription_id", "property_id" */
  subscription_property_pkey = 'subscription_property_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscription_property_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscription_property_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscription_property_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscription_property" */
export type subscription_property_inc_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription_property" */
export type subscription_property_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type subscription_property_max_fields = {
  __typename: 'subscription_property_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "subscription_property" */
export type subscription_property_max_order_by = {
  created_at?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscription_property_min_fields = {
  __typename: 'subscription_property_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "subscription_property" */
export type subscription_property_min_order_by = {
  created_at?: InputMaybe<order_by>;
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscription_property" */
export type subscription_property_mutation_response = {
  __typename: 'subscription_property_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_property>;
};

/** on_conflict condition type for table "subscription_property" */
export type subscription_property_on_conflict = {
  constraint: subscription_property_constraint;
  update_columns?: Array<subscription_property_update_column>;
  where?: InputMaybe<subscription_property_bool_exp>;
};

/** Ordering options when selecting data from "subscription_property". */
export type subscription_property_order_by = {
  created_at?: InputMaybe<order_by>;
  property?: InputMaybe<property_order_by>;
  property_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription_property */
export type subscription_property_pk_columns_input = {
  property_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscription_property_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscription_property" */
export enum subscription_property_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscription_property" */
export type subscription_property_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type subscription_property_stddev_fields = {
  __typename: 'subscription_property_stddev_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscription_property" */
export type subscription_property_stddev_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscription_property_stddev_pop_fields = {
  __typename: 'subscription_property_stddev_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscription_property" */
export type subscription_property_stddev_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscription_property_stddev_samp_fields = {
  __typename: 'subscription_property_stddev_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscription_property" */
export type subscription_property_stddev_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscription_property" */
export type subscription_property_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_property_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_property_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type subscription_property_sum_fields = {
  __typename: 'subscription_property_sum_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscription_property" */
export type subscription_property_sum_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** update columns of table "subscription_property" */
export enum subscription_property_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  value = 'value'
}

export type subscription_property_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscription_property_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscription_property_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscription_property_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscription_property_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_property_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscription_property_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_property_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_property_bool_exp;
};

/** columns and relationships of "subscription_property_value" */
export type subscription_property_value = {
  __typename: 'subscription_property_value';
  /** An object relationship */
  property?: Maybe<property>;
  property_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  subscription?: Maybe<subscription>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['jsonb']['output']>;
  value_boolean?: Maybe<Scalars['Boolean']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "subscription_property_value" */
export type subscription_property_valuevalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "subscription_property_value" */
export type subscription_property_value_aggregate = {
  __typename: 'subscription_property_value_aggregate';
  aggregate?: Maybe<subscription_property_value_aggregate_fields>;
  nodes: Array<subscription_property_value>;
};

export type subscription_property_value_aggregate_bool_exp = {
  bool_and?: InputMaybe<subscription_property_value_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<subscription_property_value_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<subscription_property_value_aggregate_bool_exp_count>;
};

export type subscription_property_value_aggregate_bool_exp_bool_and = {
  arguments: subscription_property_value_select_column_subscription_property_value_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_property_value_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscription_property_value_aggregate_bool_exp_bool_or = {
  arguments: subscription_property_value_select_column_subscription_property_value_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_property_value_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type subscription_property_value_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<subscription_property_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<subscription_property_value_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "subscription_property_value" */
export type subscription_property_value_aggregate_fields = {
  __typename: 'subscription_property_value_aggregate_fields';
  avg?: Maybe<subscription_property_value_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_property_value_max_fields>;
  min?: Maybe<subscription_property_value_min_fields>;
  stddev?: Maybe<subscription_property_value_stddev_fields>;
  stddev_pop?: Maybe<subscription_property_value_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_property_value_stddev_samp_fields>;
  sum?: Maybe<subscription_property_value_sum_fields>;
  var_pop?: Maybe<subscription_property_value_var_pop_fields>;
  var_samp?: Maybe<subscription_property_value_var_samp_fields>;
  variance?: Maybe<subscription_property_value_variance_fields>;
};


/** aggregate fields of "subscription_property_value" */
export type subscription_property_value_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_property_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "subscription_property_value" */
export type subscription_property_value_aggregate_order_by = {
  avg?: InputMaybe<subscription_property_value_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<subscription_property_value_max_order_by>;
  min?: InputMaybe<subscription_property_value_min_order_by>;
  stddev?: InputMaybe<subscription_property_value_stddev_order_by>;
  stddev_pop?: InputMaybe<subscription_property_value_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<subscription_property_value_stddev_samp_order_by>;
  sum?: InputMaybe<subscription_property_value_sum_order_by>;
  var_pop?: InputMaybe<subscription_property_value_var_pop_order_by>;
  var_samp?: InputMaybe<subscription_property_value_var_samp_order_by>;
  variance?: InputMaybe<subscription_property_value_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type subscription_property_value_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "subscription_property_value" */
export type subscription_property_value_arr_rel_insert_input = {
  data: Array<subscription_property_value_insert_input>;
};

/** aggregate avg on columns */
export type subscription_property_value_avg_fields = {
  __typename: 'subscription_property_value_avg_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "subscription_property_value" */
export type subscription_property_value_avg_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "subscription_property_value". All fields are combined with a logical 'AND'. */
export type subscription_property_value_bool_exp = {
  _and?: InputMaybe<Array<subscription_property_value_bool_exp>>;
  _not?: InputMaybe<subscription_property_value_bool_exp>;
  _or?: InputMaybe<Array<subscription_property_value_bool_exp>>;
  property?: InputMaybe<property_bool_exp>;
  property_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
  value_boolean?: InputMaybe<Boolean_comparison_exp>;
  value_numeric?: InputMaybe<numeric_comparison_exp>;
  value_text?: InputMaybe<String_comparison_exp>;
  value_text_lower?: InputMaybe<String_comparison_exp>;
  value_timestamptz?: InputMaybe<timestamptz_comparison_exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type subscription_property_value_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type subscription_property_value_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type subscription_property_value_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "subscription_property_value" */
export type subscription_property_value_inc_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "subscription_property_value" */
export type subscription_property_value_insert_input = {
  property?: InputMaybe<property_obj_rel_insert_input>;
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscription_property_value_max_fields = {
  __typename: 'subscription_property_value_max_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "subscription_property_value" */
export type subscription_property_value_max_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type subscription_property_value_min_fields = {
  __typename: 'subscription_property_value_min_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
  value_text?: Maybe<Scalars['String']['output']>;
  value_text_lower?: Maybe<Scalars['String']['output']>;
  value_timestamptz?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "subscription_property_value" */
export type subscription_property_value_min_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** response of any mutation on the table "subscription_property_value" */
export type subscription_property_value_mutation_response = {
  __typename: 'subscription_property_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_property_value>;
};

/** Ordering options when selecting data from "subscription_property_value". */
export type subscription_property_value_order_by = {
  property?: InputMaybe<property_order_by>;
  property_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
  value_boolean?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
  value_text?: InputMaybe<order_by>;
  value_text_lower?: InputMaybe<order_by>;
  value_timestamptz?: InputMaybe<order_by>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type subscription_property_value_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "subscription_property_value" */
export enum subscription_property_value_select_column {
  /** column name */
  property_id = 'property_id',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  value = 'value',
  /** column name */
  value_boolean = 'value_boolean',
  /** column name */
  value_numeric = 'value_numeric',
  /** column name */
  value_text = 'value_text',
  /** column name */
  value_text_lower = 'value_text_lower',
  /** column name */
  value_timestamptz = 'value_timestamptz'
}

/** select "subscription_property_value_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscription_property_value" */
export enum subscription_property_value_select_column_subscription_property_value_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  value_boolean = 'value_boolean'
}

/** select "subscription_property_value_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscription_property_value" */
export enum subscription_property_value_select_column_subscription_property_value_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  value_boolean = 'value_boolean'
}

/** input type for updating data in table "subscription_property_value" */
export type subscription_property_value_set_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscription_property_value_stddev_fields = {
  __typename: 'subscription_property_value_stddev_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscription_property_value" */
export type subscription_property_value_stddev_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscription_property_value_stddev_pop_fields = {
  __typename: 'subscription_property_value_stddev_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscription_property_value" */
export type subscription_property_value_stddev_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscription_property_value_stddev_samp_fields = {
  __typename: 'subscription_property_value_stddev_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscription_property_value" */
export type subscription_property_value_stddev_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscription_property_value" */
export type subscription_property_value_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_property_value_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_property_value_stream_cursor_value_input = {
  property_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  value_boolean?: InputMaybe<Scalars['Boolean']['input']>;
  value_numeric?: InputMaybe<Scalars['numeric']['input']>;
  value_text?: InputMaybe<Scalars['String']['input']>;
  value_text_lower?: InputMaybe<Scalars['String']['input']>;
  value_timestamptz?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscription_property_value_sum_fields = {
  __typename: 'subscription_property_value_sum_fields';
  property_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  value_numeric?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "subscription_property_value" */
export type subscription_property_value_sum_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

export type subscription_property_value_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<subscription_property_value_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<subscription_property_value_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<subscription_property_value_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<subscription_property_value_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_property_value_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<subscription_property_value_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_property_value_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_property_value_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_property_value_var_pop_fields = {
  __typename: 'subscription_property_value_var_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscription_property_value" */
export type subscription_property_value_var_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscription_property_value_var_samp_fields = {
  __typename: 'subscription_property_value_var_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscription_property_value" */
export type subscription_property_value_var_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscription_property_value_variance_fields = {
  __typename: 'subscription_property_value_variance_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  value_numeric?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscription_property_value" */
export type subscription_property_value_variance_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  value_numeric?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type subscription_property_var_pop_fields = {
  __typename: 'subscription_property_var_pop_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscription_property" */
export type subscription_property_var_pop_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscription_property_var_samp_fields = {
  __typename: 'subscription_property_var_samp_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscription_property" */
export type subscription_property_var_samp_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscription_property_variance_fields = {
  __typename: 'subscription_property_variance_fields';
  property_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscription_property" */
export type subscription_property_variance_order_by = {
  property_id?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** columns and relationships of "subscription_revenue_by_month" */
export type subscription_revenue_by_month = {
  __typename: 'subscription_revenue_by_month';
  month?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  platform_connection?: Maybe<platform_connection>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "subscription_revenue_by_month" */
export type subscription_revenue_by_month_aggregate = {
  __typename: 'subscription_revenue_by_month_aggregate';
  aggregate?: Maybe<subscription_revenue_by_month_aggregate_fields>;
  nodes: Array<subscription_revenue_by_month>;
};

/** aggregate fields of "subscription_revenue_by_month" */
export type subscription_revenue_by_month_aggregate_fields = {
  __typename: 'subscription_revenue_by_month_aggregate_fields';
  avg?: Maybe<subscription_revenue_by_month_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_revenue_by_month_max_fields>;
  min?: Maybe<subscription_revenue_by_month_min_fields>;
  stddev?: Maybe<subscription_revenue_by_month_stddev_fields>;
  stddev_pop?: Maybe<subscription_revenue_by_month_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_revenue_by_month_stddev_samp_fields>;
  sum?: Maybe<subscription_revenue_by_month_sum_fields>;
  var_pop?: Maybe<subscription_revenue_by_month_var_pop_fields>;
  var_samp?: Maybe<subscription_revenue_by_month_var_samp_fields>;
  variance?: Maybe<subscription_revenue_by_month_variance_fields>;
};


/** aggregate fields of "subscription_revenue_by_month" */
export type subscription_revenue_by_month_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_revenue_by_month_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_revenue_by_month_avg_fields = {
  __typename: 'subscription_revenue_by_month_avg_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_revenue_by_month". All fields are combined with a logical 'AND'. */
export type subscription_revenue_by_month_bool_exp = {
  _and?: InputMaybe<Array<subscription_revenue_by_month_bool_exp>>;
  _not?: InputMaybe<subscription_revenue_by_month_bool_exp>;
  _or?: InputMaybe<Array<subscription_revenue_by_month_bool_exp>>;
  month?: InputMaybe<String_comparison_exp>;
  platform_connection?: InputMaybe<platform_connection_bool_exp>;
  platform_connection_id?: InputMaybe<Int_comparison_exp>;
  revenue?: InputMaybe<numeric_comparison_exp>;
};

/** aggregate max on columns */
export type subscription_revenue_by_month_max_fields = {
  __typename: 'subscription_revenue_by_month_max_fields';
  month?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type subscription_revenue_by_month_min_fields = {
  __typename: 'subscription_revenue_by_month_min_fields';
  month?: Maybe<Scalars['String']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['numeric']['output']>;
};

/** Ordering options when selecting data from "subscription_revenue_by_month". */
export type subscription_revenue_by_month_order_by = {
  month?: InputMaybe<order_by>;
  platform_connection?: InputMaybe<platform_connection_order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  revenue?: InputMaybe<order_by>;
};

/** select columns of table "subscription_revenue_by_month" */
export enum subscription_revenue_by_month_select_column {
  /** column name */
  month = 'month',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  revenue = 'revenue'
}

/** aggregate stddev on columns */
export type subscription_revenue_by_month_stddev_fields = {
  __typename: 'subscription_revenue_by_month_stddev_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_revenue_by_month_stddev_pop_fields = {
  __typename: 'subscription_revenue_by_month_stddev_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_revenue_by_month_stddev_samp_fields = {
  __typename: 'subscription_revenue_by_month_stddev_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_revenue_by_month" */
export type subscription_revenue_by_month_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_revenue_by_month_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_revenue_by_month_stream_cursor_value_input = {
  month?: InputMaybe<Scalars['String']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  revenue?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type subscription_revenue_by_month_sum_fields = {
  __typename: 'subscription_revenue_by_month_sum_fields';
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  revenue?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_revenue_by_month_var_pop_fields = {
  __typename: 'subscription_revenue_by_month_var_pop_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_revenue_by_month_var_samp_fields = {
  __typename: 'subscription_revenue_by_month_var_samp_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_revenue_by_month_variance_fields = {
  __typename: 'subscription_revenue_by_month_variance_fields';
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
};

export type subscription_root = {
  __typename: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: account_aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<account>;
  /** fetch data from the table: "account_feature" */
  account_feature: Array<account_feature>;
  /** fetch aggregated fields from the table: "account_feature" */
  account_feature_aggregate: account_feature_aggregate;
  /** fetch data from the table: "account_feature" using primary key columns */
  account_feature_by_pk?: Maybe<account_feature>;
  /** fetch data from the table: "account_feature_key" */
  account_feature_key: Array<account_feature_key>;
  /** fetch aggregated fields from the table: "account_feature_key" */
  account_feature_key_aggregate: account_feature_key_aggregate;
  /** fetch data from the table: "account_feature_key" using primary key columns */
  account_feature_key_by_pk?: Maybe<account_feature_key>;
  /** fetch data from the table in a streaming manner: "account_feature_key" */
  account_feature_key_stream: Array<account_feature_key>;
  /** fetch data from the table in a streaming manner: "account_feature" */
  account_feature_stream: Array<account_feature>;
  /** fetch data from the table: "account_mode" */
  account_mode: Array<account_mode>;
  /** fetch aggregated fields from the table: "account_mode" */
  account_mode_aggregate: account_mode_aggregate;
  /** fetch data from the table: "account_mode" using primary key columns */
  account_mode_by_pk?: Maybe<account_mode>;
  /** fetch data from the table in a streaming manner: "account_mode" */
  account_mode_stream: Array<account_mode>;
  /** fetch data from the table: "account_revenue_mode" */
  account_revenue_mode: Array<account_revenue_mode>;
  /** fetch aggregated fields from the table: "account_revenue_mode" */
  account_revenue_mode_aggregate: account_revenue_mode_aggregate;
  /** fetch data from the table: "account_revenue_mode" using primary key columns */
  account_revenue_mode_by_pk?: Maybe<account_revenue_mode>;
  /** fetch data from the table in a streaming manner: "account_revenue_mode" */
  account_revenue_mode_stream: Array<account_revenue_mode>;
  /** fetch data from the table: "account_setting" */
  account_setting: Array<account_setting>;
  /** fetch aggregated fields from the table: "account_setting" */
  account_setting_aggregate: account_setting_aggregate;
  /** fetch data from the table: "account_setting" using primary key columns */
  account_setting_by_pk?: Maybe<account_setting>;
  /** fetch data from the table: "account_setting_key" */
  account_setting_key: Array<account_setting_key>;
  /** fetch aggregated fields from the table: "account_setting_key" */
  account_setting_key_aggregate: account_setting_key_aggregate;
  /** fetch data from the table: "account_setting_key" using primary key columns */
  account_setting_key_by_pk?: Maybe<account_setting_key>;
  /** fetch data from the table in a streaming manner: "account_setting_key" */
  account_setting_key_stream: Array<account_setting_key>;
  /** fetch data from the table in a streaming manner: "account_setting" */
  account_setting_stream: Array<account_setting>;
  /** fetch data from the table: "account_status" */
  account_status: Array<account_status>;
  /** fetch aggregated fields from the table: "account_status" */
  account_status_aggregate: account_status_aggregate;
  /** fetch data from the table: "account_status" using primary key columns */
  account_status_by_pk?: Maybe<account_status>;
  /** fetch data from the table in a streaming manner: "account_status" */
  account_status_stream: Array<account_status>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<account>;
  /** fetch data from the table: "account_tsvector" */
  account_tsvector: Array<account_tsvector>;
  /** fetch aggregated fields from the table: "account_tsvector" */
  account_tsvector_aggregate: account_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "account_tsvector" */
  account_tsvector_stream: Array<account_tsvector>;
  /** fetch data from the table: "account_user" */
  account_user: Array<account_user>;
  /** fetch aggregated fields from the table: "account_user" */
  account_user_aggregate: account_user_aggregate;
  /** fetch data from the table: "account_user" using primary key columns */
  account_user_by_pk?: Maybe<account_user>;
  /** fetch data from the table: "account_user_setting" */
  account_user_setting: Array<account_user_setting>;
  /** fetch aggregated fields from the table: "account_user_setting" */
  account_user_setting_aggregate: account_user_setting_aggregate;
  /** fetch data from the table: "account_user_setting" using primary key columns */
  account_user_setting_by_pk?: Maybe<account_user_setting>;
  /** fetch data from the table: "account_user_setting_key" */
  account_user_setting_key: Array<account_user_setting_key>;
  /** fetch aggregated fields from the table: "account_user_setting_key" */
  account_user_setting_key_aggregate: account_user_setting_key_aggregate;
  /** fetch data from the table: "account_user_setting_key" using primary key columns */
  account_user_setting_key_by_pk?: Maybe<account_user_setting_key>;
  /** fetch data from the table in a streaming manner: "account_user_setting_key" */
  account_user_setting_key_stream: Array<account_user_setting_key>;
  /** fetch data from the table in a streaming manner: "account_user_setting" */
  account_user_setting_stream: Array<account_user_setting>;
  /** fetch data from the table in a streaming manner: "account_user" */
  account_user_stream: Array<account_user>;
  /** fetch data from the table: "acknowledgement" */
  acknowledgement: Array<acknowledgement>;
  /** fetch aggregated fields from the table: "acknowledgement" */
  acknowledgement_aggregate: acknowledgement_aggregate;
  /** fetch data from the table: "acknowledgement" using primary key columns */
  acknowledgement_by_pk?: Maybe<acknowledgement>;
  /** fetch data from the table: "acknowledgement_group" */
  acknowledgement_group: Array<acknowledgement_group>;
  /** fetch aggregated fields from the table: "acknowledgement_group" */
  acknowledgement_group_aggregate: acknowledgement_group_aggregate;
  /** fetch data from the table: "acknowledgement_group" using primary key columns */
  acknowledgement_group_by_pk?: Maybe<acknowledgement_group>;
  /** fetch data from the table in a streaming manner: "acknowledgement_group" */
  acknowledgement_group_stream: Array<acknowledgement_group>;
  /** fetch data from the table: "acknowledgement_group_version" */
  acknowledgement_group_version: Array<acknowledgement_group_version>;
  /** fetch data from the table: "acknowledgement_group_version_acknowledgement" */
  acknowledgement_group_version_acknowledgement: Array<acknowledgement_group_version_acknowledgement>;
  /** fetch aggregated fields from the table: "acknowledgement_group_version_acknowledgement" */
  acknowledgement_group_version_acknowledgement_aggregate: acknowledgement_group_version_acknowledgement_aggregate;
  /** fetch data from the table in a streaming manner: "acknowledgement_group_version_acknowledgement" */
  acknowledgement_group_version_acknowledgement_stream: Array<acknowledgement_group_version_acknowledgement>;
  /** fetch aggregated fields from the table: "acknowledgement_group_version" */
  acknowledgement_group_version_aggregate: acknowledgement_group_version_aggregate;
  /** fetch data from the table: "acknowledgement_group_version" using primary key columns */
  acknowledgement_group_version_by_pk?: Maybe<acknowledgement_group_version>;
  /** fetch data from the table in a streaming manner: "acknowledgement_group_version" */
  acknowledgement_group_version_stream: Array<acknowledgement_group_version>;
  /** fetch data from the table in a streaming manner: "acknowledgement" */
  acknowledgement_stream: Array<acknowledgement>;
  /** fetch data from the table: "acknowledgement_version" */
  acknowledgement_version: Array<acknowledgement_version>;
  /** fetch aggregated fields from the table: "acknowledgement_version" */
  acknowledgement_version_aggregate: acknowledgement_version_aggregate;
  /** fetch data from the table: "acknowledgement_version" using primary key columns */
  acknowledgement_version_by_pk?: Maybe<acknowledgement_version>;
  /** fetch data from the table in a streaming manner: "acknowledgement_version" */
  acknowledgement_version_stream: Array<acknowledgement_version>;
  /** fetch data from the table: "active_portal_session" */
  active_portal_session: Array<active_portal_session>;
  /** fetch aggregated fields from the table: "active_portal_session" */
  active_portal_session_aggregate: active_portal_session_aggregate;
  /** fetch data from the table in a streaming manner: "active_portal_session" */
  active_portal_session_stream: Array<active_portal_session>;
  /** fetch data from the table: "active_saved_mrr_by_month" */
  active_saved_mrr_by_month: Array<active_saved_mrr_by_month>;
  /** fetch aggregated fields from the table: "active_saved_mrr_by_month" */
  active_saved_mrr_by_month_aggregate: active_saved_mrr_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "active_saved_mrr_by_month" */
  active_saved_mrr_by_month_stream: Array<active_saved_mrr_by_month>;
  /** fetch data from the table: "annotation" */
  annotation: Array<annotation>;
  /** fetch aggregated fields from the table: "annotation" */
  annotation_aggregate: annotation_aggregate;
  /** fetch data from the table: "annotation" using primary key columns */
  annotation_by_pk?: Maybe<annotation>;
  /** fetch data from the table: "annotation_flow_version" */
  annotation_flow_version: Array<annotation_flow_version>;
  /** fetch aggregated fields from the table: "annotation_flow_version" */
  annotation_flow_version_aggregate: annotation_flow_version_aggregate;
  /** fetch data from the table: "annotation_flow_version" using primary key columns */
  annotation_flow_version_by_pk?: Maybe<annotation_flow_version>;
  /** fetch data from the table in a streaming manner: "annotation_flow_version" */
  annotation_flow_version_stream: Array<annotation_flow_version>;
  /** fetch data from the table in a streaming manner: "annotation" */
  annotation_stream: Array<annotation>;
  /** fetch data from the table: "annotation_type" */
  annotation_type: Array<annotation_type>;
  /** fetch aggregated fields from the table: "annotation_type" */
  annotation_type_aggregate: annotation_type_aggregate;
  /** fetch data from the table: "annotation_type" using primary key columns */
  annotation_type_by_pk?: Maybe<annotation_type>;
  /** fetch data from the table in a streaming manner: "annotation_type" */
  annotation_type_stream: Array<annotation_type>;
  /** fetch data from the table: "api_key" */
  api_key: Array<api_key>;
  /** fetch aggregated fields from the table: "api_key" */
  api_key_aggregate: api_key_aggregate;
  /** fetch data from the table: "api_key" using primary key columns */
  api_key_by_pk?: Maybe<api_key>;
  /** fetch data from the table: "api_key_purpose" */
  api_key_purpose: Array<api_key_purpose>;
  /** fetch aggregated fields from the table: "api_key_purpose" */
  api_key_purpose_aggregate: api_key_purpose_aggregate;
  /** fetch data from the table: "api_key_purpose" using primary key columns */
  api_key_purpose_by_pk?: Maybe<api_key_purpose>;
  /** fetch data from the table in a streaming manner: "api_key_purpose" */
  api_key_purpose_stream: Array<api_key_purpose>;
  /** fetch data from the table in a streaming manner: "api_key" */
  api_key_stream: Array<api_key>;
  /** fetch data from the table: "campaign" */
  campaign: Array<campaign>;
  /** fetch aggregated fields from the table: "campaign" */
  campaign_aggregate: campaign_aggregate;
  /** fetch data from the table: "campaign" using primary key columns */
  campaign_by_pk?: Maybe<campaign>;
  /** fetch data from the table in a streaming manner: "campaign" */
  campaign_stream: Array<campaign>;
  /** fetch data from the table: "campaign_text" */
  campaign_text: Array<campaign_text>;
  /** fetch aggregated fields from the table: "campaign_text" */
  campaign_text_aggregate: campaign_text_aggregate;
  /** fetch data from the table: "campaign_text" using primary key columns */
  campaign_text_by_pk?: Maybe<campaign_text>;
  /** fetch data from the table in a streaming manner: "campaign_text" */
  campaign_text_stream: Array<campaign_text>;
  /** fetch data from the table: "campaign_text_version" */
  campaign_text_version: Array<campaign_text_version>;
  /** fetch aggregated fields from the table: "campaign_text_version" */
  campaign_text_version_aggregate: campaign_text_version_aggregate;
  /** fetch data from the table: "campaign_text_version" using primary key columns */
  campaign_text_version_by_pk?: Maybe<campaign_text_version>;
  /** fetch data from the table in a streaming manner: "campaign_text_version" */
  campaign_text_version_stream: Array<campaign_text_version>;
  /** fetch data from the table: "campaign_trigger_type" */
  campaign_trigger_type: Array<campaign_trigger_type>;
  /** fetch aggregated fields from the table: "campaign_trigger_type" */
  campaign_trigger_type_aggregate: campaign_trigger_type_aggregate;
  /** fetch data from the table: "campaign_trigger_type" using primary key columns */
  campaign_trigger_type_by_pk?: Maybe<campaign_trigger_type>;
  /** fetch data from the table in a streaming manner: "campaign_trigger_type" */
  campaign_trigger_type_stream: Array<campaign_trigger_type>;
  /** fetch data from the table: "campaign_version" */
  campaign_version: Array<campaign_version>;
  /** fetch aggregated fields from the table: "campaign_version" */
  campaign_version_aggregate: campaign_version_aggregate;
  /** fetch data from the table: "campaign_version" using primary key columns */
  campaign_version_by_pk?: Maybe<campaign_version>;
  /** fetch data from the table in a streaming manner: "campaign_version" */
  campaign_version_stream: Array<campaign_version>;
  /** fetch data from the table: "cancellations_by_month" */
  cancellations_by_month: Array<cancellations_by_month>;
  /** fetch aggregated fields from the table: "cancellations_by_month" */
  cancellations_by_month_aggregate: cancellations_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "cancellations_by_month" */
  cancellations_by_month_stream: Array<cancellations_by_month>;
  /** fetch data from the table: "confirmation" */
  confirmation: Array<confirmation>;
  /** fetch aggregated fields from the table: "confirmation" */
  confirmation_aggregate: confirmation_aggregate;
  /** fetch data from the table: "confirmation" using primary key columns */
  confirmation_by_pk?: Maybe<confirmation>;
  /** fetch data from the table in a streaming manner: "confirmation" */
  confirmation_stream: Array<confirmation>;
  /** fetch data from the table: "confirmation_version" */
  confirmation_version: Array<confirmation_version>;
  /** fetch aggregated fields from the table: "confirmation_version" */
  confirmation_version_aggregate: confirmation_version_aggregate;
  /** fetch data from the table: "confirmation_version" using primary key columns */
  confirmation_version_by_pk?: Maybe<confirmation_version>;
  /** fetch data from the table in a streaming manner: "confirmation_version" */
  confirmation_version_stream: Array<confirmation_version>;
  /** fetch data from the table: "country" */
  country: Array<country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: country_aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<country>;
  /** fetch data from the table: "coupon_duration" */
  coupon_duration: Array<coupon_duration>;
  /** fetch aggregated fields from the table: "coupon_duration" */
  coupon_duration_aggregate: coupon_duration_aggregate;
  /** fetch data from the table: "coupon_duration" using primary key columns */
  coupon_duration_by_pk?: Maybe<coupon_duration>;
  /** fetch data from the table: "coupon_duration_interval" */
  coupon_duration_interval: Array<coupon_duration_interval>;
  /** fetch aggregated fields from the table: "coupon_duration_interval" */
  coupon_duration_interval_aggregate: coupon_duration_interval_aggregate;
  /** fetch data from the table: "coupon_duration_interval" using primary key columns */
  coupon_duration_interval_by_pk?: Maybe<coupon_duration_interval>;
  /** fetch data from the table in a streaming manner: "coupon_duration_interval" */
  coupon_duration_interval_stream: Array<coupon_duration_interval>;
  /** fetch data from the table in a streaming manner: "coupon_duration" */
  coupon_duration_stream: Array<coupon_duration>;
  /** fetch data from the table: "csv_delta_file" */
  csv_delta_file: Array<csv_delta_file>;
  /** fetch aggregated fields from the table: "csv_delta_file" */
  csv_delta_file_aggregate: csv_delta_file_aggregate;
  /** fetch data from the table: "csv_delta_file" using primary key columns */
  csv_delta_file_by_pk?: Maybe<csv_delta_file>;
  /** fetch data from the table in a streaming manner: "csv_delta_file" */
  csv_delta_file_stream: Array<csv_delta_file>;
  /** fetch data from the table: "csv_import" */
  csv_import: Array<csv_import>;
  /** fetch aggregated fields from the table: "csv_import" */
  csv_import_aggregate: csv_import_aggregate;
  /** fetch data from the table: "csv_import" using primary key columns */
  csv_import_by_pk?: Maybe<csv_import>;
  /** fetch data from the table: "csv_import_file" */
  csv_import_file: Array<csv_import_file>;
  /** fetch aggregated fields from the table: "csv_import_file" */
  csv_import_file_aggregate: csv_import_file_aggregate;
  /** fetch data from the table: "csv_import_file" using primary key columns */
  csv_import_file_by_pk?: Maybe<csv_import_file>;
  /** fetch data from the table in a streaming manner: "csv_import_file" */
  csv_import_file_stream: Array<csv_import_file>;
  /** fetch data from the table: "csv_import_status" */
  csv_import_status: Array<csv_import_status>;
  /** fetch aggregated fields from the table: "csv_import_status" */
  csv_import_status_aggregate: csv_import_status_aggregate;
  /** fetch data from the table: "csv_import_status" using primary key columns */
  csv_import_status_by_pk?: Maybe<csv_import_status>;
  /** fetch data from the table in a streaming manner: "csv_import_status" */
  csv_import_status_stream: Array<csv_import_status>;
  /** fetch data from the table in a streaming manner: "csv_import" */
  csv_import_stream: Array<csv_import>;
  /** fetch data from the table: "customers_by_month" */
  customers_by_month: Array<customers_by_month>;
  /** fetch aggregated fields from the table: "customers_by_month" */
  customers_by_month_aggregate: customers_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "customers_by_month" */
  customers_by_month_stream: Array<customers_by_month>;
  /** fetch data from the table: "data_connector" */
  data_connector: Array<data_connector>;
  /** fetch aggregated fields from the table: "data_connector" */
  data_connector_aggregate: data_connector_aggregate;
  /** fetch data from the table: "data_connector" using primary key columns */
  data_connector_by_pk?: Maybe<data_connector>;
  /** fetch data from the table: "data_connector_request_log" */
  data_connector_request_log: Array<data_connector_request_log>;
  /** fetch aggregated fields from the table: "data_connector_request_log" */
  data_connector_request_log_aggregate: data_connector_request_log_aggregate;
  /** fetch data from the table: "data_connector_request_log" using primary key columns */
  data_connector_request_log_by_pk?: Maybe<data_connector_request_log>;
  /** fetch data from the table in a streaming manner: "data_connector_request_log" */
  data_connector_request_log_stream: Array<data_connector_request_log>;
  /** fetch data from the table: "data_connector_request_log_tsvector" */
  data_connector_request_log_tsvector: Array<data_connector_request_log_tsvector>;
  /** fetch aggregated fields from the table: "data_connector_request_log_tsvector" */
  data_connector_request_log_tsvector_aggregate: data_connector_request_log_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "data_connector_request_log_tsvector" */
  data_connector_request_log_tsvector_stream: Array<data_connector_request_log_tsvector>;
  /** fetch data from the table in a streaming manner: "data_connector" */
  data_connector_stream: Array<data_connector>;
  /** fetch data from the table: "deflection" */
  deflection: Array<deflection>;
  /** fetch aggregated fields from the table: "deflection" */
  deflection_aggregate: deflection_aggregate;
  /** fetch data from the table: "deflection" using primary key columns */
  deflection_by_pk?: Maybe<deflection>;
  /** fetch data from the table: "deflection_flow_action" */
  deflection_flow_action: Array<deflection_flow_action>;
  /** fetch aggregated fields from the table: "deflection_flow_action" */
  deflection_flow_action_aggregate: deflection_flow_action_aggregate;
  /** fetch data from the table in a streaming manner: "deflection_flow_action" */
  deflection_flow_action_stream: Array<deflection_flow_action>;
  /** fetch data from the table: "deflection_snapshot_item" */
  deflection_snapshot_item: Array<deflection_snapshot_item>;
  /** fetch aggregated fields from the table: "deflection_snapshot_item" */
  deflection_snapshot_item_aggregate: deflection_snapshot_item_aggregate;
  /** fetch data from the table: "deflection_snapshot_item" using primary key columns */
  deflection_snapshot_item_by_pk?: Maybe<deflection_snapshot_item>;
  /** fetch data from the table: "deflection_snapshot_item_position" */
  deflection_snapshot_item_position: Array<deflection_snapshot_item_position>;
  /** fetch aggregated fields from the table: "deflection_snapshot_item_position" */
  deflection_snapshot_item_position_aggregate: deflection_snapshot_item_position_aggregate;
  /** fetch data from the table in a streaming manner: "deflection_snapshot_item_position" */
  deflection_snapshot_item_position_stream: Array<deflection_snapshot_item_position>;
  /** fetch data from the table in a streaming manner: "deflection_snapshot_item" */
  deflection_snapshot_item_stream: Array<deflection_snapshot_item>;
  /** fetch data from the table in a streaming manner: "deflection" */
  deflection_stream: Array<deflection>;
  /** fetch data from the table: "deflection_tag" */
  deflection_tag: Array<deflection_tag>;
  /** fetch aggregated fields from the table: "deflection_tag" */
  deflection_tag_aggregate: deflection_tag_aggregate;
  /** fetch data from the table in a streaming manner: "deflection_tag" */
  deflection_tag_stream: Array<deflection_tag>;
  /** fetch data from the table: "deflection_tsvector" */
  deflection_tsvector: Array<deflection_tsvector>;
  /** fetch aggregated fields from the table: "deflection_tsvector" */
  deflection_tsvector_aggregate: deflection_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "deflection_tsvector" */
  deflection_tsvector_stream: Array<deflection_tsvector>;
  /** fetch data from the table: "deflection_type" */
  deflection_type: Array<deflection_type>;
  /** fetch aggregated fields from the table: "deflection_type" */
  deflection_type_aggregate: deflection_type_aggregate;
  /** fetch data from the table: "deflection_type" using primary key columns */
  deflection_type_by_pk?: Maybe<deflection_type>;
  /** fetch data from the table in a streaming manner: "deflection_type" */
  deflection_type_stream: Array<deflection_type>;
  /** fetch data from the table: "demo" */
  demo: Array<demo>;
  /** fetch aggregated fields from the table: "demo" */
  demo_aggregate: demo_aggregate;
  /** fetch data from the table: "demo" using primary key columns */
  demo_by_pk?: Maybe<demo>;
  /** fetch data from the table in a streaming manner: "demo" */
  demo_stream: Array<demo>;
  /** fetch data from the table: "domain" */
  domain: Array<domain>;
  /** fetch aggregated fields from the table: "domain" */
  domain_aggregate: domain_aggregate;
  /** fetch data from the table: "domain" using primary key columns */
  domain_by_pk?: Maybe<domain>;
  /** fetch data from the table in a streaming manner: "domain" */
  domain_stream: Array<domain>;
  /** fetch data from the table: "domain_type" */
  domain_type: Array<domain_type>;
  /** fetch aggregated fields from the table: "domain_type" */
  domain_type_aggregate: domain_type_aggregate;
  /** fetch data from the table: "domain_type" using primary key columns */
  domain_type_by_pk?: Maybe<domain_type>;
  /** fetch data from the table in a streaming manner: "domain_type" */
  domain_type_stream: Array<domain_type>;
  /** fetch data from the table: "eligibility_message" */
  eligibility_message: Array<eligibility_message>;
  /** fetch aggregated fields from the table: "eligibility_message" */
  eligibility_message_aggregate: eligibility_message_aggregate;
  /** fetch data from the table: "eligibility_message" using primary key columns */
  eligibility_message_by_pk?: Maybe<eligibility_message>;
  /** fetch data from the table in a streaming manner: "eligibility_message" */
  eligibility_message_stream: Array<eligibility_message>;
  /** fetch data from the table: "event" */
  event: Array<event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: event_aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<event>;
  /** fetch data from the table in a streaming manner: "event" */
  event_stream: Array<event>;
  /** fetch data from the table: "event_type" */
  event_type: Array<event_type>;
  /** fetch aggregated fields from the table: "event_type" */
  event_type_aggregate: event_type_aggregate;
  /** fetch data from the table: "event_type" using primary key columns */
  event_type_by_pk?: Maybe<event_type>;
  /** fetch data from the table in a streaming manner: "event_type" */
  event_type_stream: Array<event_type>;
  /** fetch data from the table: "feedback_trends_grid" */
  feedback_trends_grid: Array<feedback_trends_grid>;
  /** fetch aggregated fields from the table: "feedback_trends_grid" */
  feedback_trends_grid_aggregate: feedback_trends_grid_aggregate;
  /** fetch data from the table in a streaming manner: "feedback_trends_grid" */
  feedback_trends_grid_stream: Array<feedback_trends_grid>;
  /** fetch data from the table: "flow" */
  flow: Array<flow>;
  /** fetch data from the table: "flow_action" */
  flow_action: Array<flow_action>;
  /** fetch aggregated fields from the table: "flow_action" */
  flow_action_aggregate: flow_action_aggregate;
  /** fetch data from the table: "flow_action_appearance" */
  flow_action_appearance: Array<flow_action_appearance>;
  /** fetch aggregated fields from the table: "flow_action_appearance" */
  flow_action_appearance_aggregate: flow_action_appearance_aggregate;
  /** fetch data from the table: "flow_action_appearance" using primary key columns */
  flow_action_appearance_by_pk?: Maybe<flow_action_appearance>;
  /** fetch data from the table in a streaming manner: "flow_action_appearance" */
  flow_action_appearance_stream: Array<flow_action_appearance>;
  /** fetch data from the table: "flow_action" using primary key columns */
  flow_action_by_pk?: Maybe<flow_action>;
  /** fetch data from the table in a streaming manner: "flow_action" */
  flow_action_stream: Array<flow_action>;
  /** fetch data from the table: "flow_action_type" */
  flow_action_type: Array<flow_action_type>;
  /** fetch aggregated fields from the table: "flow_action_type" */
  flow_action_type_aggregate: flow_action_type_aggregate;
  /** fetch data from the table: "flow_action_type" using primary key columns */
  flow_action_type_by_pk?: Maybe<flow_action_type>;
  /** fetch data from the table in a streaming manner: "flow_action_type" */
  flow_action_type_stream: Array<flow_action_type>;
  /** fetch aggregated fields from the table: "flow" */
  flow_aggregate: flow_aggregate;
  /** fetch data from the table: "flow_auto_cancel_at" */
  flow_auto_cancel_at: Array<flow_auto_cancel_at>;
  /** fetch aggregated fields from the table: "flow_auto_cancel_at" */
  flow_auto_cancel_at_aggregate: flow_auto_cancel_at_aggregate;
  /** fetch data from the table: "flow_auto_cancel_at" using primary key columns */
  flow_auto_cancel_at_by_pk?: Maybe<flow_auto_cancel_at>;
  /** fetch data from the table in a streaming manner: "flow_auto_cancel_at" */
  flow_auto_cancel_at_stream: Array<flow_auto_cancel_at>;
  /** fetch data from the table: "flow" using primary key columns */
  flow_by_pk?: Maybe<flow>;
  /** fetch data from the table: "flow_language" */
  flow_language: Array<flow_language>;
  /** fetch aggregated fields from the table: "flow_language" */
  flow_language_aggregate: flow_language_aggregate;
  /** fetch data from the table: "flow_language" using primary key columns */
  flow_language_by_pk?: Maybe<flow_language>;
  /** fetch data from the table in a streaming manner: "flow_language" */
  flow_language_stream: Array<flow_language>;
  /** fetch data from the table: "flow_on_cancel" */
  flow_on_cancel: Array<flow_on_cancel>;
  /** fetch aggregated fields from the table: "flow_on_cancel" */
  flow_on_cancel_aggregate: flow_on_cancel_aggregate;
  /** fetch data from the table: "flow_on_cancel" using primary key columns */
  flow_on_cancel_by_pk?: Maybe<flow_on_cancel>;
  /** fetch data from the table: "flow_on_cancel_change_plan" */
  flow_on_cancel_change_plan: Array<flow_on_cancel_change_plan>;
  /** fetch aggregated fields from the table: "flow_on_cancel_change_plan" */
  flow_on_cancel_change_plan_aggregate: flow_on_cancel_change_plan_aggregate;
  /** fetch data from the table: "flow_on_cancel_change_plan" using primary key columns */
  flow_on_cancel_change_plan_by_pk?: Maybe<flow_on_cancel_change_plan>;
  /** fetch data from the table in a streaming manner: "flow_on_cancel_change_plan" */
  flow_on_cancel_change_plan_stream: Array<flow_on_cancel_change_plan>;
  /** fetch data from the table: "flow_on_cancel_perform_at" */
  flow_on_cancel_perform_at: Array<flow_on_cancel_perform_at>;
  /** fetch aggregated fields from the table: "flow_on_cancel_perform_at" */
  flow_on_cancel_perform_at_aggregate: flow_on_cancel_perform_at_aggregate;
  /** fetch data from the table: "flow_on_cancel_perform_at" using primary key columns */
  flow_on_cancel_perform_at_by_pk?: Maybe<flow_on_cancel_perform_at>;
  /** fetch data from the table in a streaming manner: "flow_on_cancel_perform_at" */
  flow_on_cancel_perform_at_stream: Array<flow_on_cancel_perform_at>;
  /** fetch data from the table in a streaming manner: "flow_on_cancel" */
  flow_on_cancel_stream: Array<flow_on_cancel>;
  /** fetch data from the table: "flow_route_rule" */
  flow_route_rule: Array<flow_route_rule>;
  /** fetch aggregated fields from the table: "flow_route_rule" */
  flow_route_rule_aggregate: flow_route_rule_aggregate;
  /** fetch data from the table: "flow_route_rule" using primary key columns */
  flow_route_rule_by_pk?: Maybe<flow_route_rule>;
  /** fetch data from the table: "flow_route_rule_eligibility_message" */
  flow_route_rule_eligibility_message: Array<flow_route_rule_eligibility_message>;
  /** fetch aggregated fields from the table: "flow_route_rule_eligibility_message" */
  flow_route_rule_eligibility_message_aggregate: flow_route_rule_eligibility_message_aggregate;
  /** fetch data from the table in a streaming manner: "flow_route_rule_eligibility_message" */
  flow_route_rule_eligibility_message_stream: Array<flow_route_rule_eligibility_message>;
  /** fetch data from the table: "flow_route_rule_flow" */
  flow_route_rule_flow: Array<flow_route_rule_flow>;
  /** fetch aggregated fields from the table: "flow_route_rule_flow" */
  flow_route_rule_flow_aggregate: flow_route_rule_flow_aggregate;
  /** fetch data from the table in a streaming manner: "flow_route_rule_flow" */
  flow_route_rule_flow_stream: Array<flow_route_rule_flow>;
  /** fetch data from the table: "flow_route_rule_flow_test" */
  flow_route_rule_flow_test: Array<flow_route_rule_flow_test>;
  /** fetch aggregated fields from the table: "flow_route_rule_flow_test" */
  flow_route_rule_flow_test_aggregate: flow_route_rule_flow_test_aggregate;
  /** fetch data from the table in a streaming manner: "flow_route_rule_flow_test" */
  flow_route_rule_flow_test_stream: Array<flow_route_rule_flow_test>;
  /** fetch data from the table: "flow_route_rule_segment" */
  flow_route_rule_segment: Array<flow_route_rule_segment>;
  /** fetch aggregated fields from the table: "flow_route_rule_segment" */
  flow_route_rule_segment_aggregate: flow_route_rule_segment_aggregate;
  /** fetch data from the table: "flow_route_rule_segment_group" */
  flow_route_rule_segment_group: Array<flow_route_rule_segment_group>;
  /** fetch aggregated fields from the table: "flow_route_rule_segment_group" */
  flow_route_rule_segment_group_aggregate: flow_route_rule_segment_group_aggregate;
  /** fetch data from the table in a streaming manner: "flow_route_rule_segment_group" */
  flow_route_rule_segment_group_stream: Array<flow_route_rule_segment_group>;
  /** fetch data from the table in a streaming manner: "flow_route_rule_segment" */
  flow_route_rule_segment_stream: Array<flow_route_rule_segment>;
  /** fetch data from the table in a streaming manner: "flow_route_rule" */
  flow_route_rule_stream: Array<flow_route_rule>;
  /** fetch data from the table: "flow_step" */
  flow_step: Array<flow_step>;
  /** fetch data from the table: "flow_step_acknowledgement_group" */
  flow_step_acknowledgement_group: Array<flow_step_acknowledgement_group>;
  /** fetch aggregated fields from the table: "flow_step_acknowledgement_group" */
  flow_step_acknowledgement_group_aggregate: flow_step_acknowledgement_group_aggregate;
  /** fetch data from the table: "flow_step_acknowledgement_group" using primary key columns */
  flow_step_acknowledgement_group_by_pk?: Maybe<flow_step_acknowledgement_group>;
  /** fetch data from the table in a streaming manner: "flow_step_acknowledgement_group" */
  flow_step_acknowledgement_group_stream: Array<flow_step_acknowledgement_group>;
  /** fetch aggregated fields from the table: "flow_step" */
  flow_step_aggregate: flow_step_aggregate;
  /** fetch data from the table: "flow_step" using primary key columns */
  flow_step_by_pk?: Maybe<flow_step>;
  /** fetch data from the table: "flow_step_confirmation" */
  flow_step_confirmation: Array<flow_step_confirmation>;
  /** fetch aggregated fields from the table: "flow_step_confirmation" */
  flow_step_confirmation_aggregate: flow_step_confirmation_aggregate;
  /** fetch data from the table: "flow_step_confirmation" using primary key columns */
  flow_step_confirmation_by_pk?: Maybe<flow_step_confirmation>;
  /** fetch data from the table in a streaming manner: "flow_step_confirmation" */
  flow_step_confirmation_stream: Array<flow_step_confirmation>;
  /** fetch data from the table: "flow_step_deflection_rule_group" */
  flow_step_deflection_rule_group: Array<flow_step_deflection_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_deflection_rule_group" */
  flow_step_deflection_rule_group_aggregate: flow_step_deflection_rule_group_aggregate;
  /** fetch data from the table in a streaming manner: "flow_step_deflection_rule_group" */
  flow_step_deflection_rule_group_stream: Array<flow_step_deflection_rule_group>;
  /** fetch data from the table: "flow_step_form" */
  flow_step_form: Array<flow_step_form>;
  /** fetch aggregated fields from the table: "flow_step_form" */
  flow_step_form_aggregate: flow_step_form_aggregate;
  /** fetch data from the table: "flow_step_form" using primary key columns */
  flow_step_form_by_pk?: Maybe<flow_step_form>;
  /** fetch data from the table in a streaming manner: "flow_step_form" */
  flow_step_form_stream: Array<flow_step_form>;
  /** fetch data from the table: "flow_step_intervention" */
  flow_step_intervention: Array<flow_step_intervention>;
  /** fetch aggregated fields from the table: "flow_step_intervention" */
  flow_step_intervention_aggregate: flow_step_intervention_aggregate;
  /** fetch data from the table: "flow_step_intervention" using primary key columns */
  flow_step_intervention_by_pk?: Maybe<flow_step_intervention>;
  /** fetch data from the table in a streaming manner: "flow_step_intervention" */
  flow_step_intervention_stream: Array<flow_step_intervention>;
  /** fetch data from the table: "flow_step_offer_rule_group" */
  flow_step_offer_rule_group: Array<flow_step_offer_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_offer_rule_group" */
  flow_step_offer_rule_group_aggregate: flow_step_offer_rule_group_aggregate;
  /** fetch data from the table in a streaming manner: "flow_step_offer_rule_group" */
  flow_step_offer_rule_group_stream: Array<flow_step_offer_rule_group>;
  /** fetch data from the table: "flow_step_question" */
  flow_step_question: Array<flow_step_question>;
  /** fetch aggregated fields from the table: "flow_step_question" */
  flow_step_question_aggregate: flow_step_question_aggregate;
  /** fetch data from the table: "flow_step_question" using primary key columns */
  flow_step_question_by_pk?: Maybe<flow_step_question>;
  /** fetch data from the table in a streaming manner: "flow_step_question" */
  flow_step_question_stream: Array<flow_step_question>;
  /** fetch data from the table: "flow_step_rule_group" */
  flow_step_rule_group: Array<flow_step_rule_group>;
  /** fetch aggregated fields from the table: "flow_step_rule_group" */
  flow_step_rule_group_aggregate: flow_step_rule_group_aggregate;
  /** fetch data from the table: "flow_step_rule_group" using primary key columns */
  flow_step_rule_group_by_pk?: Maybe<flow_step_rule_group>;
  /** fetch data from the table in a streaming manner: "flow_step_rule_group" */
  flow_step_rule_group_stream: Array<flow_step_rule_group>;
  /** fetch data from the table in a streaming manner: "flow_step" */
  flow_step_stream: Array<flow_step>;
  /** fetch data from the table: "flow_step_type" */
  flow_step_type: Array<flow_step_type>;
  /** fetch aggregated fields from the table: "flow_step_type" */
  flow_step_type_aggregate: flow_step_type_aggregate;
  /** fetch data from the table: "flow_step_type" using primary key columns */
  flow_step_type_by_pk?: Maybe<flow_step_type>;
  /** fetch data from the table in a streaming manner: "flow_step_type" */
  flow_step_type_stream: Array<flow_step_type>;
  /** fetch data from the table: "flow_step_version" */
  flow_step_version: Array<flow_step_version>;
  /** fetch aggregated fields from the table: "flow_step_version" */
  flow_step_version_aggregate: flow_step_version_aggregate;
  /** fetch data from the table: "flow_step_version" using primary key columns */
  flow_step_version_by_pk?: Maybe<flow_step_version>;
  /** fetch data from the table: "flow_step_version_condition_question_option" */
  flow_step_version_condition_question_option: Array<flow_step_version_condition_question_option>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_question_option" */
  flow_step_version_condition_question_option_aggregate: flow_step_version_condition_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "flow_step_version_condition_question_option" */
  flow_step_version_condition_question_option_stream: Array<flow_step_version_condition_question_option>;
  /** fetch data from the table: "flow_step_version_condition_segment" */
  flow_step_version_condition_segment: Array<flow_step_version_condition_segment>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_segment" */
  flow_step_version_condition_segment_aggregate: flow_step_version_condition_segment_aggregate;
  /** fetch data from the table: "flow_step_version_condition_segment_group" */
  flow_step_version_condition_segment_group: Array<flow_step_version_condition_segment_group>;
  /** fetch aggregated fields from the table: "flow_step_version_condition_segment_group" */
  flow_step_version_condition_segment_group_aggregate: flow_step_version_condition_segment_group_aggregate;
  /** fetch data from the table in a streaming manner: "flow_step_version_condition_segment_group" */
  flow_step_version_condition_segment_group_stream: Array<flow_step_version_condition_segment_group>;
  /** fetch data from the table in a streaming manner: "flow_step_version_condition_segment" */
  flow_step_version_condition_segment_stream: Array<flow_step_version_condition_segment>;
  /** fetch data from the table in a streaming manner: "flow_step_version" */
  flow_step_version_stream: Array<flow_step_version>;
  /** fetch data from the table in a streaming manner: "flow" */
  flow_stream: Array<flow>;
  /** fetch data from the table: "flow_subscriber_form_question" */
  flow_subscriber_form_question: Array<flow_subscriber_form_question>;
  /** fetch aggregated fields from the table: "flow_subscriber_form_question" */
  flow_subscriber_form_question_aggregate: flow_subscriber_form_question_aggregate;
  /** fetch data from the table: "flow_subscriber_form_question" using primary key columns */
  flow_subscriber_form_question_by_pk?: Maybe<flow_subscriber_form_question>;
  /** fetch data from the table in a streaming manner: "flow_subscriber_form_question" */
  flow_subscriber_form_question_stream: Array<flow_subscriber_form_question>;
  /** fetch data from the table: "flow_tag" */
  flow_tag: Array<flow_tag>;
  /** fetch aggregated fields from the table: "flow_tag" */
  flow_tag_aggregate: flow_tag_aggregate;
  /** fetch data from the table in a streaming manner: "flow_tag" */
  flow_tag_stream: Array<flow_tag>;
  /** fetch data from the table: "flow_test" */
  flow_test: Array<flow_test>;
  /** fetch aggregated fields from the table: "flow_test" */
  flow_test_aggregate: flow_test_aggregate;
  /** fetch data from the table: "flow_test" using primary key columns */
  flow_test_by_pk?: Maybe<flow_test>;
  /** fetch data from the table: "flow_test_flow" */
  flow_test_flow: Array<flow_test_flow>;
  /** fetch aggregated fields from the table: "flow_test_flow" */
  flow_test_flow_aggregate: flow_test_flow_aggregate;
  /** fetch data from the table: "flow_test_flow" using primary key columns */
  flow_test_flow_by_pk?: Maybe<flow_test_flow>;
  /** fetch data from the table in a streaming manner: "flow_test_flow" */
  flow_test_flow_stream: Array<flow_test_flow>;
  /** fetch data from the table in a streaming manner: "flow_test" */
  flow_test_stream: Array<flow_test>;
  /** fetch data from the table: "flow_text" */
  flow_text: Array<flow_text>;
  /** fetch aggregated fields from the table: "flow_text" */
  flow_text_aggregate: flow_text_aggregate;
  /** fetch data from the table: "flow_text" using primary key columns */
  flow_text_by_pk?: Maybe<flow_text>;
  /** fetch data from the table: "flow_text_key" */
  flow_text_key: Array<flow_text_key>;
  /** fetch aggregated fields from the table: "flow_text_key" */
  flow_text_key_aggregate: flow_text_key_aggregate;
  /** fetch data from the table: "flow_text_key" using primary key columns */
  flow_text_key_by_pk?: Maybe<flow_text_key>;
  /** fetch data from the table in a streaming manner: "flow_text_key" */
  flow_text_key_stream: Array<flow_text_key>;
  /** fetch data from the table in a streaming manner: "flow_text" */
  flow_text_stream: Array<flow_text>;
  /** fetch data from the table: "flow_text_version" */
  flow_text_version: Array<flow_text_version>;
  /** fetch aggregated fields from the table: "flow_text_version" */
  flow_text_version_aggregate: flow_text_version_aggregate;
  /** fetch data from the table: "flow_text_version" using primary key columns */
  flow_text_version_by_pk?: Maybe<flow_text_version>;
  /** fetch data from the table in a streaming manner: "flow_text_version" */
  flow_text_version_stream: Array<flow_text_version>;
  /** fetch data from the table: "flow_version" */
  flow_version: Array<flow_version>;
  /** fetch aggregated fields from the table: "flow_version" */
  flow_version_aggregate: flow_version_aggregate;
  /** fetch data from the table: "flow_version" using primary key columns */
  flow_version_by_pk?: Maybe<flow_version>;
  /** fetch data from the table: "flow_version_flow_step" */
  flow_version_flow_step: Array<flow_version_flow_step>;
  /** fetch aggregated fields from the table: "flow_version_flow_step" */
  flow_version_flow_step_aggregate: flow_version_flow_step_aggregate;
  /** fetch data from the table in a streaming manner: "flow_version_flow_step" */
  flow_version_flow_step_stream: Array<flow_version_flow_step>;
  /** fetch data from the table in a streaming manner: "flow_version" */
  flow_version_stream: Array<flow_version>;
  /** fetch data from the table: "form" */
  form: Array<form>;
  /** fetch aggregated fields from the table: "form" */
  form_aggregate: form_aggregate;
  /** fetch data from the table: "form" using primary key columns */
  form_by_pk?: Maybe<form>;
  /** fetch data from the table in a streaming manner: "form" */
  form_stream: Array<form>;
  /** fetch data from the table: "form_version" */
  form_version: Array<form_version>;
  /** fetch aggregated fields from the table: "form_version" */
  form_version_aggregate: form_version_aggregate;
  /** fetch data from the table: "form_version" using primary key columns */
  form_version_by_pk?: Maybe<form_version>;
  /** fetch data from the table: "form_version_question" */
  form_version_question: Array<form_version_question>;
  /** fetch aggregated fields from the table: "form_version_question" */
  form_version_question_aggregate: form_version_question_aggregate;
  /** fetch data from the table in a streaming manner: "form_version_question" */
  form_version_question_stream: Array<form_version_question>;
  /** fetch data from the table in a streaming manner: "form_version" */
  form_version_stream: Array<form_version>;
  /** fetch data from the table: "function_log" */
  function_log: Array<function_log>;
  /** fetch aggregated fields from the table: "function_log" */
  function_log_aggregate: function_log_aggregate;
  /** fetch data from the table: "function_log" using primary key columns */
  function_log_by_pk?: Maybe<function_log>;
  /** fetch data from the table in a streaming manner: "function_log" */
  function_log_stream: Array<function_log>;
  /** fetch data from the table: "integration" */
  integration: Array<integration>;
  /** fetch aggregated fields from the table: "integration" */
  integration_aggregate: integration_aggregate;
  /** fetch data from the table: "integration" using primary key columns */
  integration_by_pk?: Maybe<integration>;
  /** fetch data from the table in a streaming manner: "integration" */
  integration_stream: Array<integration>;
  /** fetch data from the table: "integration_type" */
  integration_type: Array<integration_type>;
  /** fetch aggregated fields from the table: "integration_type" */
  integration_type_aggregate: integration_type_aggregate;
  /** fetch data from the table: "integration_type" using primary key columns */
  integration_type_by_pk?: Maybe<integration_type>;
  /** fetch data from the table in a streaming manner: "integration_type" */
  integration_type_stream: Array<integration_type>;
  /** fetch data from the table: "intervention" */
  intervention: Array<intervention>;
  /** fetch aggregated fields from the table: "intervention" */
  intervention_aggregate: intervention_aggregate;
  /** fetch data from the table: "intervention" using primary key columns */
  intervention_by_pk?: Maybe<intervention>;
  /** fetch data from the table in a streaming manner: "intervention" */
  intervention_stream: Array<intervention>;
  /** fetch data from the table: "intervention_version" */
  intervention_version: Array<intervention_version>;
  /** fetch aggregated fields from the table: "intervention_version" */
  intervention_version_aggregate: intervention_version_aggregate;
  /** fetch data from the table: "intervention_version" using primary key columns */
  intervention_version_by_pk?: Maybe<intervention_version>;
  /** fetch data from the table in a streaming manner: "intervention_version" */
  intervention_version_stream: Array<intervention_version>;
  /** fetch data from the table: "job" */
  job: Array<job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: job_aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<job>;
  /** fetch data from the table in a streaming manner: "job" */
  job_stream: Array<job>;
  /** fetch data from the table: "language" */
  language: Array<language>;
  /** fetch aggregated fields from the table: "language" */
  language_aggregate: language_aggregate;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<language>;
  /** fetch data from the table in a streaming manner: "language" */
  language_stream: Array<language>;
  /** fetch data from the table: "lost_mrr_by_month" */
  lost_mrr_by_month: Array<lost_mrr_by_month>;
  /** fetch aggregated fields from the table: "lost_mrr_by_month" */
  lost_mrr_by_month_aggregate: lost_mrr_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "lost_mrr_by_month" */
  lost_mrr_by_month_stream: Array<lost_mrr_by_month>;
  /** fetch data from the table: "most_recent_subscriber_flow_version" */
  most_recent_subscriber_flow_version: Array<most_recent_subscriber_flow_version>;
  /** fetch aggregated fields from the table: "most_recent_subscriber_flow_version" */
  most_recent_subscriber_flow_version_aggregate: most_recent_subscriber_flow_version_aggregate;
  /** fetch data from the table in a streaming manner: "most_recent_subscriber_flow_version" */
  most_recent_subscriber_flow_version_stream: Array<most_recent_subscriber_flow_version>;
  /** fetch data from the table: "naviga_cancel_link" */
  naviga_cancel_link: Array<naviga_cancel_link>;
  /** fetch aggregated fields from the table: "naviga_cancel_link" */
  naviga_cancel_link_aggregate: naviga_cancel_link_aggregate;
  /** fetch data from the table: "naviga_cancel_link" using primary key columns */
  naviga_cancel_link_by_pk?: Maybe<naviga_cancel_link>;
  /** fetch data from the table in a streaming manner: "naviga_cancel_link" */
  naviga_cancel_link_stream: Array<naviga_cancel_link>;
  /** fetch data from the table: "naviga_domain" */
  naviga_domain: Array<naviga_domain>;
  /** fetch aggregated fields from the table: "naviga_domain" */
  naviga_domain_aggregate: naviga_domain_aggregate;
  /** fetch data from the table: "naviga_domain" using primary key columns */
  naviga_domain_by_pk?: Maybe<naviga_domain>;
  /** fetch data from the table in a streaming manner: "naviga_domain" */
  naviga_domain_stream: Array<naviga_domain>;
  /** fetch data from the table: "naviga_paper_code" */
  naviga_paper_code: Array<naviga_paper_code>;
  /** fetch aggregated fields from the table: "naviga_paper_code" */
  naviga_paper_code_aggregate: naviga_paper_code_aggregate;
  /** fetch data from the table: "naviga_paper_code" using primary key columns */
  naviga_paper_code_by_pk?: Maybe<naviga_paper_code>;
  /** fetch data from the table in a streaming manner: "naviga_paper_code" */
  naviga_paper_code_stream: Array<naviga_paper_code>;
  /** fetch data from the table: "offer" */
  offer: Array<offer>;
  /** fetch aggregated fields from the table: "offer" */
  offer_aggregate: offer_aggregate;
  /** fetch data from the table: "offer_autopilot" */
  offer_autopilot: Array<offer_autopilot>;
  /** fetch aggregated fields from the table: "offer_autopilot" */
  offer_autopilot_aggregate: offer_autopilot_aggregate;
  /** fetch data from the table: "offer_autopilot" using primary key columns */
  offer_autopilot_by_pk?: Maybe<offer_autopilot>;
  /** fetch data from the table: "offer_autopilot_offer_autopilot_round" */
  offer_autopilot_offer_autopilot_round: Array<offer_autopilot_offer_autopilot_round>;
  /** fetch aggregated fields from the table: "offer_autopilot_offer_autopilot_round" */
  offer_autopilot_offer_autopilot_round_aggregate: offer_autopilot_offer_autopilot_round_aggregate;
  /** fetch data from the table in a streaming manner: "offer_autopilot_offer_autopilot_round" */
  offer_autopilot_offer_autopilot_round_stream: Array<offer_autopilot_offer_autopilot_round>;
  /** fetch data from the table: "offer_autopilot_offer_variant" */
  offer_autopilot_offer_variant: Array<offer_autopilot_offer_variant>;
  /** fetch aggregated fields from the table: "offer_autopilot_offer_variant" */
  offer_autopilot_offer_variant_aggregate: offer_autopilot_offer_variant_aggregate;
  /** fetch data from the table in a streaming manner: "offer_autopilot_offer_variant" */
  offer_autopilot_offer_variant_stream: Array<offer_autopilot_offer_variant>;
  /** fetch data from the table: "offer_autopilot_round" */
  offer_autopilot_round: Array<offer_autopilot_round>;
  /** fetch aggregated fields from the table: "offer_autopilot_round" */
  offer_autopilot_round_aggregate: offer_autopilot_round_aggregate;
  /** fetch data from the table: "offer_autopilot_round" using primary key columns */
  offer_autopilot_round_by_pk?: Maybe<offer_autopilot_round>;
  /** fetch data from the table: "offer_autopilot_round_log" */
  offer_autopilot_round_log: Array<offer_autopilot_round_log>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_log" */
  offer_autopilot_round_log_aggregate: offer_autopilot_round_log_aggregate;
  /** fetch data from the table: "offer_autopilot_round_log" using primary key columns */
  offer_autopilot_round_log_by_pk?: Maybe<offer_autopilot_round_log>;
  /** fetch data from the table in a streaming manner: "offer_autopilot_round_log" */
  offer_autopilot_round_log_stream: Array<offer_autopilot_round_log>;
  /** fetch data from the table: "offer_autopilot_round_offer_variant" */
  offer_autopilot_round_offer_variant: Array<offer_autopilot_round_offer_variant>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_offer_variant" */
  offer_autopilot_round_offer_variant_aggregate: offer_autopilot_round_offer_variant_aggregate;
  /** fetch data from the table in a streaming manner: "offer_autopilot_round_offer_variant" */
  offer_autopilot_round_offer_variant_stream: Array<offer_autopilot_round_offer_variant>;
  /** fetch data from the table: "offer_autopilot_round_strategy" */
  offer_autopilot_round_strategy: Array<offer_autopilot_round_strategy>;
  /** fetch aggregated fields from the table: "offer_autopilot_round_strategy" */
  offer_autopilot_round_strategy_aggregate: offer_autopilot_round_strategy_aggregate;
  /** fetch data from the table: "offer_autopilot_round_strategy" using primary key columns */
  offer_autopilot_round_strategy_by_pk?: Maybe<offer_autopilot_round_strategy>;
  /** fetch data from the table in a streaming manner: "offer_autopilot_round_strategy" */
  offer_autopilot_round_strategy_stream: Array<offer_autopilot_round_strategy>;
  /** fetch data from the table in a streaming manner: "offer_autopilot_round" */
  offer_autopilot_round_stream: Array<offer_autopilot_round>;
  /** fetch data from the table: "offer_autopilot_strategy" */
  offer_autopilot_strategy: Array<offer_autopilot_strategy>;
  /** fetch aggregated fields from the table: "offer_autopilot_strategy" */
  offer_autopilot_strategy_aggregate: offer_autopilot_strategy_aggregate;
  /** fetch data from the table: "offer_autopilot_strategy" using primary key columns */
  offer_autopilot_strategy_by_pk?: Maybe<offer_autopilot_strategy>;
  /** fetch data from the table in a streaming manner: "offer_autopilot_strategy" */
  offer_autopilot_strategy_stream: Array<offer_autopilot_strategy>;
  /** fetch data from the table in a streaming manner: "offer_autopilot" */
  offer_autopilot_stream: Array<offer_autopilot>;
  /** fetch data from the table: "offer_autopilot_success_metric" */
  offer_autopilot_success_metric: Array<offer_autopilot_success_metric>;
  /** fetch aggregated fields from the table: "offer_autopilot_success_metric" */
  offer_autopilot_success_metric_aggregate: offer_autopilot_success_metric_aggregate;
  /** fetch data from the table: "offer_autopilot_success_metric" using primary key columns */
  offer_autopilot_success_metric_by_pk?: Maybe<offer_autopilot_success_metric>;
  /** fetch data from the table in a streaming manner: "offer_autopilot_success_metric" */
  offer_autopilot_success_metric_stream: Array<offer_autopilot_success_metric>;
  /** fetch data from the table: "offer" using primary key columns */
  offer_by_pk?: Maybe<offer>;
  /** fetch data from the table: "offer_change_plan" */
  offer_change_plan: Array<offer_change_plan>;
  /** fetch aggregated fields from the table: "offer_change_plan" */
  offer_change_plan_aggregate: offer_change_plan_aggregate;
  /** fetch data from the table: "offer_change_plan" using primary key columns */
  offer_change_plan_by_pk?: Maybe<offer_change_plan>;
  /** fetch data from the table: "offer_change_plan_change_at" */
  offer_change_plan_change_at: Array<offer_change_plan_change_at>;
  /** fetch aggregated fields from the table: "offer_change_plan_change_at" */
  offer_change_plan_change_at_aggregate: offer_change_plan_change_at_aggregate;
  /** fetch data from the table: "offer_change_plan_change_at" using primary key columns */
  offer_change_plan_change_at_by_pk?: Maybe<offer_change_plan_change_at>;
  /** fetch data from the table in a streaming manner: "offer_change_plan_change_at" */
  offer_change_plan_change_at_stream: Array<offer_change_plan_change_at>;
  /** fetch data from the table in a streaming manner: "offer_change_plan" */
  offer_change_plan_stream: Array<offer_change_plan>;
  /** fetch data from the table: "offer_coupon" */
  offer_coupon: Array<offer_coupon>;
  /** fetch aggregated fields from the table: "offer_coupon" */
  offer_coupon_aggregate: offer_coupon_aggregate;
  /** fetch data from the table: "offer_coupon_apply_to" */
  offer_coupon_apply_to: Array<offer_coupon_apply_to>;
  /** fetch aggregated fields from the table: "offer_coupon_apply_to" */
  offer_coupon_apply_to_aggregate: offer_coupon_apply_to_aggregate;
  /** fetch data from the table: "offer_coupon_apply_to" using primary key columns */
  offer_coupon_apply_to_by_pk?: Maybe<offer_coupon_apply_to>;
  /** fetch data from the table in a streaming manner: "offer_coupon_apply_to" */
  offer_coupon_apply_to_stream: Array<offer_coupon_apply_to>;
  /** fetch data from the table: "offer_coupon" using primary key columns */
  offer_coupon_by_pk?: Maybe<offer_coupon>;
  /** fetch data from the table in a streaming manner: "offer_coupon" */
  offer_coupon_stream: Array<offer_coupon>;
  /** fetch data from the table: "offer_coupon_type" */
  offer_coupon_type: Array<offer_coupon_type>;
  /** fetch aggregated fields from the table: "offer_coupon_type" */
  offer_coupon_type_aggregate: offer_coupon_type_aggregate;
  /** fetch data from the table: "offer_coupon_type" using primary key columns */
  offer_coupon_type_by_pk?: Maybe<offer_coupon_type>;
  /** fetch data from the table in a streaming manner: "offer_coupon_type" */
  offer_coupon_type_stream: Array<offer_coupon_type>;
  /** fetch data from the table: "offer_custom" */
  offer_custom: Array<offer_custom>;
  /** fetch aggregated fields from the table: "offer_custom" */
  offer_custom_aggregate: offer_custom_aggregate;
  /** fetch data from the table: "offer_custom" using primary key columns */
  offer_custom_by_pk?: Maybe<offer_custom>;
  /** fetch data from the table in a streaming manner: "offer_custom" */
  offer_custom_stream: Array<offer_custom>;
  /** fetch data from the table: "offer_downgrade" */
  offer_downgrade: Array<offer_downgrade>;
  /** fetch aggregated fields from the table: "offer_downgrade" */
  offer_downgrade_aggregate: offer_downgrade_aggregate;
  /** fetch data from the table: "offer_downgrade" using primary key columns */
  offer_downgrade_by_pk?: Maybe<offer_downgrade>;
  /** fetch data from the table in a streaming manner: "offer_downgrade" */
  offer_downgrade_stream: Array<offer_downgrade>;
  /** fetch data from the table: "offer_goal" */
  offer_goal: Array<offer_goal>;
  /** fetch aggregated fields from the table: "offer_goal" */
  offer_goal_aggregate: offer_goal_aggregate;
  /** fetch data from the table: "offer_goal" using primary key columns */
  offer_goal_by_pk?: Maybe<offer_goal>;
  /** fetch data from the table in a streaming manner: "offer_goal" */
  offer_goal_stream: Array<offer_goal>;
  /** fetch data from the table: "offer_group" */
  offer_group: Array<offer_group>;
  /** fetch aggregated fields from the table: "offer_group" */
  offer_group_aggregate: offer_group_aggregate;
  /** fetch data from the table: "offer_group" using primary key columns */
  offer_group_by_pk?: Maybe<offer_group>;
  /** fetch data from the table: "offer_group_offer" */
  offer_group_offer: Array<offer_group_offer>;
  /** fetch aggregated fields from the table: "offer_group_offer" */
  offer_group_offer_aggregate: offer_group_offer_aggregate;
  /** fetch data from the table in a streaming manner: "offer_group_offer" */
  offer_group_offer_stream: Array<offer_group_offer>;
  /** fetch data from the table in a streaming manner: "offer_group" */
  offer_group_stream: Array<offer_group>;
  /** fetch data from the table: "offer_modify_subscription" */
  offer_modify_subscription: Array<offer_modify_subscription>;
  /** fetch aggregated fields from the table: "offer_modify_subscription" */
  offer_modify_subscription_aggregate: offer_modify_subscription_aggregate;
  /** fetch data from the table: "offer_modify_subscription" using primary key columns */
  offer_modify_subscription_by_pk?: Maybe<offer_modify_subscription>;
  /** fetch data from the table: "offer_modify_subscription_modify_at" */
  offer_modify_subscription_modify_at: Array<offer_modify_subscription_modify_at>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_modify_at" */
  offer_modify_subscription_modify_at_aggregate: offer_modify_subscription_modify_at_aggregate;
  /** fetch data from the table: "offer_modify_subscription_modify_at" using primary key columns */
  offer_modify_subscription_modify_at_by_pk?: Maybe<offer_modify_subscription_modify_at>;
  /** fetch data from the table in a streaming manner: "offer_modify_subscription_modify_at" */
  offer_modify_subscription_modify_at_stream: Array<offer_modify_subscription_modify_at>;
  /** fetch data from the table: "offer_modify_subscription_option" */
  offer_modify_subscription_option: Array<offer_modify_subscription_option>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_option" */
  offer_modify_subscription_option_aggregate: offer_modify_subscription_option_aggregate;
  /** fetch data from the table: "offer_modify_subscription_option" using primary key columns */
  offer_modify_subscription_option_by_pk?: Maybe<offer_modify_subscription_option>;
  /** fetch data from the table in a streaming manner: "offer_modify_subscription_option" */
  offer_modify_subscription_option_stream: Array<offer_modify_subscription_option>;
  /** fetch data from the table: "offer_modify_subscription_option_type" */
  offer_modify_subscription_option_type: Array<offer_modify_subscription_option_type>;
  /** fetch aggregated fields from the table: "offer_modify_subscription_option_type" */
  offer_modify_subscription_option_type_aggregate: offer_modify_subscription_option_type_aggregate;
  /** fetch data from the table: "offer_modify_subscription_option_type" using primary key columns */
  offer_modify_subscription_option_type_by_pk?: Maybe<offer_modify_subscription_option_type>;
  /** fetch data from the table in a streaming manner: "offer_modify_subscription_option_type" */
  offer_modify_subscription_option_type_stream: Array<offer_modify_subscription_option_type>;
  /** fetch data from the table in a streaming manner: "offer_modify_subscription" */
  offer_modify_subscription_stream: Array<offer_modify_subscription>;
  /** fetch data from the table: "offer_pause_subscription" */
  offer_pause_subscription: Array<offer_pause_subscription>;
  /** fetch aggregated fields from the table: "offer_pause_subscription" */
  offer_pause_subscription_aggregate: offer_pause_subscription_aggregate;
  /** fetch data from the table: "offer_pause_subscription" using primary key columns */
  offer_pause_subscription_by_pk?: Maybe<offer_pause_subscription>;
  /** fetch data from the table: "offer_pause_subscription_interval" */
  offer_pause_subscription_interval: Array<offer_pause_subscription_interval>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_interval" */
  offer_pause_subscription_interval_aggregate: offer_pause_subscription_interval_aggregate;
  /** fetch data from the table: "offer_pause_subscription_interval" using primary key columns */
  offer_pause_subscription_interval_by_pk?: Maybe<offer_pause_subscription_interval>;
  /** fetch data from the table in a streaming manner: "offer_pause_subscription_interval" */
  offer_pause_subscription_interval_stream: Array<offer_pause_subscription_interval>;
  /** fetch data from the table: "offer_pause_subscription_option" */
  offer_pause_subscription_option: Array<offer_pause_subscription_option>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_option" */
  offer_pause_subscription_option_aggregate: offer_pause_subscription_option_aggregate;
  /** fetch data from the table: "offer_pause_subscription_option" using primary key columns */
  offer_pause_subscription_option_by_pk?: Maybe<offer_pause_subscription_option>;
  /** fetch data from the table in a streaming manner: "offer_pause_subscription_option" */
  offer_pause_subscription_option_stream: Array<offer_pause_subscription_option>;
  /** fetch data from the table: "offer_pause_subscription_option_type" */
  offer_pause_subscription_option_type: Array<offer_pause_subscription_option_type>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_option_type" */
  offer_pause_subscription_option_type_aggregate: offer_pause_subscription_option_type_aggregate;
  /** fetch data from the table: "offer_pause_subscription_option_type" using primary key columns */
  offer_pause_subscription_option_type_by_pk?: Maybe<offer_pause_subscription_option_type>;
  /** fetch data from the table in a streaming manner: "offer_pause_subscription_option_type" */
  offer_pause_subscription_option_type_stream: Array<offer_pause_subscription_option_type>;
  /** fetch data from the table: "offer_pause_subscription_pause_at" */
  offer_pause_subscription_pause_at: Array<offer_pause_subscription_pause_at>;
  /** fetch aggregated fields from the table: "offer_pause_subscription_pause_at" */
  offer_pause_subscription_pause_at_aggregate: offer_pause_subscription_pause_at_aggregate;
  /** fetch data from the table: "offer_pause_subscription_pause_at" using primary key columns */
  offer_pause_subscription_pause_at_by_pk?: Maybe<offer_pause_subscription_pause_at>;
  /** fetch data from the table in a streaming manner: "offer_pause_subscription_pause_at" */
  offer_pause_subscription_pause_at_stream: Array<offer_pause_subscription_pause_at>;
  /** fetch data from the table in a streaming manner: "offer_pause_subscription" */
  offer_pause_subscription_stream: Array<offer_pause_subscription>;
  /** fetch data from the table: "offer_product_swap" */
  offer_product_swap: Array<offer_product_swap>;
  /** fetch aggregated fields from the table: "offer_product_swap" */
  offer_product_swap_aggregate: offer_product_swap_aggregate;
  /** fetch data from the table: "offer_product_swap" using primary key columns */
  offer_product_swap_by_pk?: Maybe<offer_product_swap>;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product" */
  offer_product_swap_platform_ecommerce_product: Array<offer_product_swap_platform_ecommerce_product>;
  /** fetch aggregated fields from the table: "offer_product_swap_platform_ecommerce_product" */
  offer_product_swap_platform_ecommerce_product_aggregate: offer_product_swap_platform_ecommerce_product_aggregate;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product" using primary key columns */
  offer_product_swap_platform_ecommerce_product_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product>;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  offer_product_swap_platform_ecommerce_product_platform_variant: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** fetch aggregated fields from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  offer_product_swap_platform_ecommerce_product_platform_variant_aggregate: offer_product_swap_platform_ecommerce_product_platform_variant_aggregate;
  /** fetch data from the table: "offer_product_swap_platform_ecommerce_product_platform_variant" using primary key columns */
  offer_product_swap_platform_ecommerce_product_platform_variant_by_pk?: Maybe<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** fetch data from the table in a streaming manner: "offer_product_swap_platform_ecommerce_product_platform_variant" */
  offer_product_swap_platform_ecommerce_product_platform_variant_stream: Array<offer_product_swap_platform_ecommerce_product_platform_variant>;
  /** fetch data from the table in a streaming manner: "offer_product_swap_platform_ecommerce_product" */
  offer_product_swap_platform_ecommerce_product_stream: Array<offer_product_swap_platform_ecommerce_product>;
  /** fetch data from the table in a streaming manner: "offer_product_swap" */
  offer_product_swap_stream: Array<offer_product_swap>;
  /** fetch data from the table: "offer_reschedule_order" */
  offer_reschedule_order: Array<offer_reschedule_order>;
  /** fetch aggregated fields from the table: "offer_reschedule_order" */
  offer_reschedule_order_aggregate: offer_reschedule_order_aggregate;
  /** fetch data from the table: "offer_reschedule_order" using primary key columns */
  offer_reschedule_order_by_pk?: Maybe<offer_reschedule_order>;
  /** fetch data from the table in a streaming manner: "offer_reschedule_order" */
  offer_reschedule_order_stream: Array<offer_reschedule_order>;
  /** fetch data from the table: "offer_rule" */
  offer_rule: Array<offer_rule>;
  /** fetch aggregated fields from the table: "offer_rule" */
  offer_rule_aggregate: offer_rule_aggregate;
  /** fetch data from the table: "offer_rule" using primary key columns */
  offer_rule_by_pk?: Maybe<offer_rule>;
  /** fetch data from the table: "offer_rule_group" */
  offer_rule_group: Array<offer_rule_group>;
  /** fetch aggregated fields from the table: "offer_rule_group" */
  offer_rule_group_aggregate: offer_rule_group_aggregate;
  /** fetch data from the table: "offer_rule_group" using primary key columns */
  offer_rule_group_by_pk?: Maybe<offer_rule_group>;
  /** fetch data from the table in a streaming manner: "offer_rule_group" */
  offer_rule_group_stream: Array<offer_rule_group>;
  /** fetch data from the table: "offer_rule_group_version" */
  offer_rule_group_version: Array<offer_rule_group_version>;
  /** fetch aggregated fields from the table: "offer_rule_group_version" */
  offer_rule_group_version_aggregate: offer_rule_group_version_aggregate;
  /** fetch data from the table: "offer_rule_group_version" using primary key columns */
  offer_rule_group_version_by_pk?: Maybe<offer_rule_group_version>;
  /** fetch data from the table: "offer_rule_group_version_offer_rule" */
  offer_rule_group_version_offer_rule: Array<offer_rule_group_version_offer_rule>;
  /** fetch aggregated fields from the table: "offer_rule_group_version_offer_rule" */
  offer_rule_group_version_offer_rule_aggregate: offer_rule_group_version_offer_rule_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_group_version_offer_rule" */
  offer_rule_group_version_offer_rule_stream: Array<offer_rule_group_version_offer_rule>;
  /** fetch data from the table in a streaming manner: "offer_rule_group_version" */
  offer_rule_group_version_stream: Array<offer_rule_group_version>;
  /** fetch data from the table: "offer_rule_rule" */
  offer_rule_rule: Array<offer_rule_rule>;
  /** fetch aggregated fields from the table: "offer_rule_rule" */
  offer_rule_rule_aggregate: offer_rule_rule_aggregate;
  /** fetch data from the table: "offer_rule_rule" using primary key columns */
  offer_rule_rule_by_pk?: Maybe<offer_rule_rule>;
  /** fetch data from the table: "offer_rule_rule_deflection" */
  offer_rule_rule_deflection: Array<offer_rule_rule_deflection>;
  /** fetch aggregated fields from the table: "offer_rule_rule_deflection" */
  offer_rule_rule_deflection_aggregate: offer_rule_rule_deflection_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_rule_deflection" */
  offer_rule_rule_deflection_stream: Array<offer_rule_rule_deflection>;
  /** fetch data from the table: "offer_rule_rule_offer" */
  offer_rule_rule_offer: Array<offer_rule_rule_offer>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer" */
  offer_rule_rule_offer_aggregate: offer_rule_rule_offer_aggregate;
  /** fetch data from the table: "offer_rule_rule_offer_autopilot_offer" */
  offer_rule_rule_offer_autopilot_offer: Array<offer_rule_rule_offer_autopilot_offer>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_autopilot_offer" */
  offer_rule_rule_offer_autopilot_offer_aggregate: offer_rule_rule_offer_autopilot_offer_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_rule_offer_autopilot_offer" */
  offer_rule_rule_offer_autopilot_offer_stream: Array<offer_rule_rule_offer_autopilot_offer>;
  /** fetch data from the table: "offer_rule_rule_offer_group" */
  offer_rule_rule_offer_group: Array<offer_rule_rule_offer_group>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_group" */
  offer_rule_rule_offer_group_aggregate: offer_rule_rule_offer_group_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_rule_offer_group" */
  offer_rule_rule_offer_group_stream: Array<offer_rule_rule_offer_group>;
  /** fetch data from the table in a streaming manner: "offer_rule_rule_offer" */
  offer_rule_rule_offer_stream: Array<offer_rule_rule_offer>;
  /** fetch data from the table: "offer_rule_rule_offer_test" */
  offer_rule_rule_offer_test: Array<offer_rule_rule_offer_test>;
  /** fetch aggregated fields from the table: "offer_rule_rule_offer_test" */
  offer_rule_rule_offer_test_aggregate: offer_rule_rule_offer_test_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_rule_offer_test" */
  offer_rule_rule_offer_test_stream: Array<offer_rule_rule_offer_test>;
  /** fetch data from the table in a streaming manner: "offer_rule_rule" */
  offer_rule_rule_stream: Array<offer_rule_rule>;
  /** fetch data from the table in a streaming manner: "offer_rule" */
  offer_rule_stream: Array<offer_rule>;
  /** fetch data from the table: "offer_rule_version" */
  offer_rule_version: Array<offer_rule_version>;
  /** fetch aggregated fields from the table: "offer_rule_version" */
  offer_rule_version_aggregate: offer_rule_version_aggregate;
  /** fetch data from the table: "offer_rule_version" using primary key columns */
  offer_rule_version_by_pk?: Maybe<offer_rule_version>;
  /** fetch data from the table: "offer_rule_version_offer" */
  offer_rule_version_offer: Array<offer_rule_version_offer>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer" */
  offer_rule_version_offer_aggregate: offer_rule_version_offer_aggregate;
  /** fetch data from the table: "offer_rule_version_offer_autopilot_offer" */
  offer_rule_version_offer_autopilot_offer: Array<offer_rule_version_offer_autopilot_offer>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_autopilot_offer" */
  offer_rule_version_offer_autopilot_offer_aggregate: offer_rule_version_offer_autopilot_offer_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_offer_autopilot_offer" */
  offer_rule_version_offer_autopilot_offer_stream: Array<offer_rule_version_offer_autopilot_offer>;
  /** fetch data from the table: "offer_rule_version_offer_group" */
  offer_rule_version_offer_group: Array<offer_rule_version_offer_group>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_group" */
  offer_rule_version_offer_group_aggregate: offer_rule_version_offer_group_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_offer_group" */
  offer_rule_version_offer_group_stream: Array<offer_rule_version_offer_group>;
  /** fetch data from the table: "offer_rule_version_offer_rule_rule" */
  offer_rule_version_offer_rule_rule: Array<offer_rule_version_offer_rule_rule>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_rule_rule" */
  offer_rule_version_offer_rule_rule_aggregate: offer_rule_version_offer_rule_rule_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_offer_rule_rule" */
  offer_rule_version_offer_rule_rule_stream: Array<offer_rule_version_offer_rule_rule>;
  /** fetch data from the table in a streaming manner: "offer_rule_version_offer" */
  offer_rule_version_offer_stream: Array<offer_rule_version_offer>;
  /** fetch data from the table: "offer_rule_version_offer_test" */
  offer_rule_version_offer_test: Array<offer_rule_version_offer_test>;
  /** fetch aggregated fields from the table: "offer_rule_version_offer_test" */
  offer_rule_version_offer_test_aggregate: offer_rule_version_offer_test_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_offer_test" */
  offer_rule_version_offer_test_stream: Array<offer_rule_version_offer_test>;
  /** fetch data from the table: "offer_rule_version_question_option" */
  offer_rule_version_question_option: Array<offer_rule_version_question_option>;
  /** fetch aggregated fields from the table: "offer_rule_version_question_option" */
  offer_rule_version_question_option_aggregate: offer_rule_version_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_question_option" */
  offer_rule_version_question_option_stream: Array<offer_rule_version_question_option>;
  /** fetch data from the table: "offer_rule_version_segment" */
  offer_rule_version_segment: Array<offer_rule_version_segment>;
  /** fetch aggregated fields from the table: "offer_rule_version_segment" */
  offer_rule_version_segment_aggregate: offer_rule_version_segment_aggregate;
  /** fetch data from the table: "offer_rule_version_segment_group" */
  offer_rule_version_segment_group: Array<offer_rule_version_segment_group>;
  /** fetch aggregated fields from the table: "offer_rule_version_segment_group" */
  offer_rule_version_segment_group_aggregate: offer_rule_version_segment_group_aggregate;
  /** fetch data from the table in a streaming manner: "offer_rule_version_segment_group" */
  offer_rule_version_segment_group_stream: Array<offer_rule_version_segment_group>;
  /** fetch data from the table in a streaming manner: "offer_rule_version_segment" */
  offer_rule_version_segment_stream: Array<offer_rule_version_segment>;
  /** fetch data from the table in a streaming manner: "offer_rule_version" */
  offer_rule_version_stream: Array<offer_rule_version>;
  /** fetch data from the table in a streaming manner: "offer" */
  offer_stream: Array<offer>;
  /** fetch data from the table: "offer_style" */
  offer_style: Array<offer_style>;
  /** fetch aggregated fields from the table: "offer_style" */
  offer_style_aggregate: offer_style_aggregate;
  /** fetch data from the table: "offer_style" using primary key columns */
  offer_style_by_pk?: Maybe<offer_style>;
  /** fetch data from the table in a streaming manner: "offer_style" */
  offer_style_stream: Array<offer_style>;
  /** fetch data from the table: "offer_tag" */
  offer_tag: Array<offer_tag>;
  /** fetch aggregated fields from the table: "offer_tag" */
  offer_tag_aggregate: offer_tag_aggregate;
  /** fetch data from the table in a streaming manner: "offer_tag" */
  offer_tag_stream: Array<offer_tag>;
  /** fetch data from the table: "offer_test" */
  offer_test: Array<offer_test>;
  /** fetch aggregated fields from the table: "offer_test" */
  offer_test_aggregate: offer_test_aggregate;
  /** fetch data from the table: "offer_test" using primary key columns */
  offer_test_by_pk?: Maybe<offer_test>;
  /** fetch data from the table: "offer_test_offer" */
  offer_test_offer: Array<offer_test_offer>;
  /** fetch aggregated fields from the table: "offer_test_offer" */
  offer_test_offer_aggregate: offer_test_offer_aggregate;
  /** fetch data from the table: "offer_test_offer" using primary key columns */
  offer_test_offer_by_pk?: Maybe<offer_test_offer>;
  /** fetch data from the table in a streaming manner: "offer_test_offer" */
  offer_test_offer_stream: Array<offer_test_offer>;
  /** fetch data from the table in a streaming manner: "offer_test" */
  offer_test_stream: Array<offer_test>;
  /** fetch data from the table: "offer_timer" */
  offer_timer: Array<offer_timer>;
  /** fetch aggregated fields from the table: "offer_timer" */
  offer_timer_aggregate: offer_timer_aggregate;
  /** fetch data from the table: "offer_timer" using primary key columns */
  offer_timer_by_pk?: Maybe<offer_timer>;
  /** fetch data from the table: "offer_timer_mode" */
  offer_timer_mode: Array<offer_timer_mode>;
  /** fetch aggregated fields from the table: "offer_timer_mode" */
  offer_timer_mode_aggregate: offer_timer_mode_aggregate;
  /** fetch data from the table: "offer_timer_mode" using primary key columns */
  offer_timer_mode_by_pk?: Maybe<offer_timer_mode>;
  /** fetch data from the table in a streaming manner: "offer_timer_mode" */
  offer_timer_mode_stream: Array<offer_timer_mode>;
  /** fetch data from the table in a streaming manner: "offer_timer" */
  offer_timer_stream: Array<offer_timer>;
  /** fetch data from the table: "offer_trial_extension" */
  offer_trial_extension: Array<offer_trial_extension>;
  /** fetch aggregated fields from the table: "offer_trial_extension" */
  offer_trial_extension_aggregate: offer_trial_extension_aggregate;
  /** fetch data from the table: "offer_trial_extension" using primary key columns */
  offer_trial_extension_by_pk?: Maybe<offer_trial_extension>;
  /** fetch data from the table in a streaming manner: "offer_trial_extension" */
  offer_trial_extension_stream: Array<offer_trial_extension>;
  /** fetch data from the table: "offer_tsvector" */
  offer_tsvector: Array<offer_tsvector>;
  /** fetch aggregated fields from the table: "offer_tsvector" */
  offer_tsvector_aggregate: offer_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "offer_tsvector" */
  offer_tsvector_stream: Array<offer_tsvector>;
  /** fetch data from the table: "offer_type" */
  offer_type: Array<offer_type>;
  /** fetch aggregated fields from the table: "offer_type" */
  offer_type_aggregate: offer_type_aggregate;
  /** fetch data from the table: "offer_type" using primary key columns */
  offer_type_by_pk?: Maybe<offer_type>;
  /** fetch data from the table in a streaming manner: "offer_type" */
  offer_type_stream: Array<offer_type>;
  /** fetch data from the table: "offer_upgrade" */
  offer_upgrade: Array<offer_upgrade>;
  /** fetch aggregated fields from the table: "offer_upgrade" */
  offer_upgrade_aggregate: offer_upgrade_aggregate;
  /** fetch data from the table: "offer_upgrade" using primary key columns */
  offer_upgrade_by_pk?: Maybe<offer_upgrade>;
  /** fetch data from the table in a streaming manner: "offer_upgrade" */
  offer_upgrade_stream: Array<offer_upgrade>;
  /** fetch data from the table: "offer_variant" */
  offer_variant: Array<offer_variant>;
  /** fetch aggregated fields from the table: "offer_variant" */
  offer_variant_aggregate: offer_variant_aggregate;
  /** fetch data from the table: "offer_variant" using primary key columns */
  offer_variant_by_pk?: Maybe<offer_variant>;
  /** fetch data from the table: "offer_variant_coupon" */
  offer_variant_coupon: Array<offer_variant_coupon>;
  /** fetch aggregated fields from the table: "offer_variant_coupon" */
  offer_variant_coupon_aggregate: offer_variant_coupon_aggregate;
  /** fetch data from the table: "offer_variant_coupon" using primary key columns */
  offer_variant_coupon_by_pk?: Maybe<offer_variant_coupon>;
  /** fetch data from the table in a streaming manner: "offer_variant_coupon" */
  offer_variant_coupon_stream: Array<offer_variant_coupon>;
  /** fetch data from the table in a streaming manner: "offer_variant" */
  offer_variant_stream: Array<offer_variant>;
  /** fetch data from the table: "platform" */
  platform: Array<platform>;
  /** fetch data from the table: "platform_addon" */
  platform_addon: Array<platform_addon>;
  /** fetch aggregated fields from the table: "platform_addon" */
  platform_addon_aggregate: platform_addon_aggregate;
  /** fetch data from the table: "platform_addon" using primary key columns */
  platform_addon_by_pk?: Maybe<platform_addon>;
  /** fetch data from the table: "platform_addon_plan" */
  platform_addon_plan: Array<platform_addon_plan>;
  /** fetch aggregated fields from the table: "platform_addon_plan" */
  platform_addon_plan_aggregate: platform_addon_plan_aggregate;
  /** fetch data from the table: "platform_addon_plan" using primary key columns */
  platform_addon_plan_by_pk?: Maybe<platform_addon_plan>;
  /** fetch data from the table in a streaming manner: "platform_addon_plan" */
  platform_addon_plan_stream: Array<platform_addon_plan>;
  /** fetch data from the table: "platform_addon_price" */
  platform_addon_price: Array<platform_addon_price>;
  /** fetch aggregated fields from the table: "platform_addon_price" */
  platform_addon_price_aggregate: platform_addon_price_aggregate;
  /** fetch data from the table: "platform_addon_price" using primary key columns */
  platform_addon_price_by_pk?: Maybe<platform_addon_price>;
  /** fetch data from the table in a streaming manner: "platform_addon_price" */
  platform_addon_price_stream: Array<platform_addon_price>;
  /** fetch data from the table: "platform_addon_price_tier" */
  platform_addon_price_tier: Array<platform_addon_price_tier>;
  /** fetch aggregated fields from the table: "platform_addon_price_tier" */
  platform_addon_price_tier_aggregate: platform_addon_price_tier_aggregate;
  /** fetch data from the table: "platform_addon_price_tier" using primary key columns */
  platform_addon_price_tier_by_pk?: Maybe<platform_addon_price_tier>;
  /** fetch data from the table in a streaming manner: "platform_addon_price_tier" */
  platform_addon_price_tier_stream: Array<platform_addon_price_tier>;
  /** fetch data from the table in a streaming manner: "platform_addon" */
  platform_addon_stream: Array<platform_addon>;
  /** fetch aggregated fields from the table: "platform" */
  platform_aggregate: platform_aggregate;
  /** fetch data from the table: "platform_api_log" */
  platform_api_log: Array<platform_api_log>;
  /** fetch aggregated fields from the table: "platform_api_log" */
  platform_api_log_aggregate: platform_api_log_aggregate;
  /** fetch data from the table: "platform_api_log" using primary key columns */
  platform_api_log_by_pk?: Maybe<platform_api_log>;
  /** fetch data from the table in a streaming manner: "platform_api_log" */
  platform_api_log_stream: Array<platform_api_log>;
  /** fetch data from the table: "platform_api_log_tsvector" */
  platform_api_log_tsvector: Array<platform_api_log_tsvector>;
  /** fetch aggregated fields from the table: "platform_api_log_tsvector" */
  platform_api_log_tsvector_aggregate: platform_api_log_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "platform_api_log_tsvector" */
  platform_api_log_tsvector_stream: Array<platform_api_log_tsvector>;
  /** fetch data from the table: "platform_base_currency" */
  platform_base_currency: Array<platform_base_currency>;
  /** fetch aggregated fields from the table: "platform_base_currency" */
  platform_base_currency_aggregate: platform_base_currency_aggregate;
  /** fetch data from the table: "platform_base_currency" using primary key columns */
  platform_base_currency_by_pk?: Maybe<platform_base_currency>;
  /** fetch data from the table in a streaming manner: "platform_base_currency" */
  platform_base_currency_stream: Array<platform_base_currency>;
  /** fetch data from the table: "platform" using primary key columns */
  platform_by_pk?: Maybe<platform>;
  /** fetch data from the table: "platform_cancel_reason" */
  platform_cancel_reason: Array<platform_cancel_reason>;
  /** fetch aggregated fields from the table: "platform_cancel_reason" */
  platform_cancel_reason_aggregate: platform_cancel_reason_aggregate;
  /** fetch data from the table: "platform_cancel_reason" using primary key columns */
  platform_cancel_reason_by_pk?: Maybe<platform_cancel_reason>;
  /** fetch data from the table in a streaming manner: "platform_cancel_reason" */
  platform_cancel_reason_stream: Array<platform_cancel_reason>;
  /** fetch data from the table: "platform_connection" */
  platform_connection: Array<platform_connection>;
  /** fetch aggregated fields from the table: "platform_connection" */
  platform_connection_aggregate: platform_connection_aggregate;
  /** fetch data from the table: "platform_connection" using primary key columns */
  platform_connection_by_pk?: Maybe<platform_connection>;
  /** fetch data from the table: "platform_connection_naviga_domain" */
  platform_connection_naviga_domain: Array<platform_connection_naviga_domain>;
  /** fetch aggregated fields from the table: "platform_connection_naviga_domain" */
  platform_connection_naviga_domain_aggregate: platform_connection_naviga_domain_aggregate;
  /** fetch data from the table: "platform_connection_naviga_domain" using primary key columns */
  platform_connection_naviga_domain_by_pk?: Maybe<platform_connection_naviga_domain>;
  /** fetch data from the table in a streaming manner: "platform_connection_naviga_domain" */
  platform_connection_naviga_domain_stream: Array<platform_connection_naviga_domain>;
  /** fetch data from the table: "platform_connection_naviga_paper_code_endpoint" */
  platform_connection_naviga_paper_code_endpoint: Array<platform_connection_naviga_paper_code_endpoint>;
  /** fetch aggregated fields from the table: "platform_connection_naviga_paper_code_endpoint" */
  platform_connection_naviga_paper_code_endpoint_aggregate: platform_connection_naviga_paper_code_endpoint_aggregate;
  /** fetch data from the table: "platform_connection_naviga_paper_code_endpoint" using primary key columns */
  platform_connection_naviga_paper_code_endpoint_by_pk?: Maybe<platform_connection_naviga_paper_code_endpoint>;
  /** fetch data from the table in a streaming manner: "platform_connection_naviga_paper_code_endpoint" */
  platform_connection_naviga_paper_code_endpoint_stream: Array<platform_connection_naviga_paper_code_endpoint>;
  /** fetch data from the table: "platform_connection_shopify_store" */
  platform_connection_shopify_store: Array<platform_connection_shopify_store>;
  /** fetch aggregated fields from the table: "platform_connection_shopify_store" */
  platform_connection_shopify_store_aggregate: platform_connection_shopify_store_aggregate;
  /** fetch data from the table: "platform_connection_shopify_store" using primary key columns */
  platform_connection_shopify_store_by_pk?: Maybe<platform_connection_shopify_store>;
  /** fetch data from the table in a streaming manner: "platform_connection_shopify_store" */
  platform_connection_shopify_store_stream: Array<platform_connection_shopify_store>;
  /** fetch data from the table in a streaming manner: "platform_connection" */
  platform_connection_stream: Array<platform_connection>;
  /** fetch data from the table: "platform_coupon" */
  platform_coupon: Array<platform_coupon>;
  /** fetch data from the table: "platform_coupon_addon" */
  platform_coupon_addon: Array<platform_coupon_addon>;
  /** fetch aggregated fields from the table: "platform_coupon_addon" */
  platform_coupon_addon_aggregate: platform_coupon_addon_aggregate;
  /** fetch data from the table: "platform_coupon_addon" using primary key columns */
  platform_coupon_addon_by_pk?: Maybe<platform_coupon_addon>;
  /** fetch data from the table in a streaming manner: "platform_coupon_addon" */
  platform_coupon_addon_stream: Array<platform_coupon_addon>;
  /** fetch aggregated fields from the table: "platform_coupon" */
  platform_coupon_aggregate: platform_coupon_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to" */
  platform_coupon_apply_to: Array<platform_coupon_apply_to>;
  /** fetch aggregated fields from the table: "platform_coupon_apply_to" */
  platform_coupon_apply_to_aggregate: platform_coupon_apply_to_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to" using primary key columns */
  platform_coupon_apply_to_by_pk?: Maybe<platform_coupon_apply_to>;
  /** fetch data from the table: "platform_coupon_apply_to_restriction" */
  platform_coupon_apply_to_restriction: Array<platform_coupon_apply_to_restriction>;
  /** fetch aggregated fields from the table: "platform_coupon_apply_to_restriction" */
  platform_coupon_apply_to_restriction_aggregate: platform_coupon_apply_to_restriction_aggregate;
  /** fetch data from the table: "platform_coupon_apply_to_restriction" using primary key columns */
  platform_coupon_apply_to_restriction_by_pk?: Maybe<platform_coupon_apply_to_restriction>;
  /** fetch data from the table in a streaming manner: "platform_coupon_apply_to_restriction" */
  platform_coupon_apply_to_restriction_stream: Array<platform_coupon_apply_to_restriction>;
  /** fetch data from the table in a streaming manner: "platform_coupon_apply_to" */
  platform_coupon_apply_to_stream: Array<platform_coupon_apply_to>;
  /** fetch data from the table: "platform_coupon" using primary key columns */
  platform_coupon_by_pk?: Maybe<platform_coupon>;
  /** fetch data from the table: "platform_coupon_duration" */
  platform_coupon_duration: Array<platform_coupon_duration>;
  /** fetch aggregated fields from the table: "platform_coupon_duration" */
  platform_coupon_duration_aggregate: platform_coupon_duration_aggregate;
  /** fetch data from the table: "platform_coupon_duration" using primary key columns */
  platform_coupon_duration_by_pk?: Maybe<platform_coupon_duration>;
  /** fetch data from the table: "platform_coupon_duration_interval" */
  platform_coupon_duration_interval: Array<platform_coupon_duration_interval>;
  /** fetch aggregated fields from the table: "platform_coupon_duration_interval" */
  platform_coupon_duration_interval_aggregate: platform_coupon_duration_interval_aggregate;
  /** fetch data from the table: "platform_coupon_duration_interval" using primary key columns */
  platform_coupon_duration_interval_by_pk?: Maybe<platform_coupon_duration_interval>;
  /** fetch data from the table in a streaming manner: "platform_coupon_duration_interval" */
  platform_coupon_duration_interval_stream: Array<platform_coupon_duration_interval>;
  /** fetch data from the table in a streaming manner: "platform_coupon_duration" */
  platform_coupon_duration_stream: Array<platform_coupon_duration>;
  /** fetch data from the table: "platform_coupon_plan" */
  platform_coupon_plan: Array<platform_coupon_plan>;
  /** fetch aggregated fields from the table: "platform_coupon_plan" */
  platform_coupon_plan_aggregate: platform_coupon_plan_aggregate;
  /** fetch data from the table: "platform_coupon_plan" using primary key columns */
  platform_coupon_plan_by_pk?: Maybe<platform_coupon_plan>;
  /** fetch data from the table in a streaming manner: "platform_coupon_plan" */
  platform_coupon_plan_stream: Array<platform_coupon_plan>;
  /** fetch data from the table: "platform_coupon_product" */
  platform_coupon_product: Array<platform_coupon_product>;
  /** fetch aggregated fields from the table: "platform_coupon_product" */
  platform_coupon_product_aggregate: platform_coupon_product_aggregate;
  /** fetch data from the table: "platform_coupon_product" using primary key columns */
  platform_coupon_product_by_pk?: Maybe<platform_coupon_product>;
  /** fetch data from the table: "platform_coupon_product_group" */
  platform_coupon_product_group: Array<platform_coupon_product_group>;
  /** fetch aggregated fields from the table: "platform_coupon_product_group" */
  platform_coupon_product_group_aggregate: platform_coupon_product_group_aggregate;
  /** fetch data from the table: "platform_coupon_product_group" using primary key columns */
  platform_coupon_product_group_by_pk?: Maybe<platform_coupon_product_group>;
  /** fetch data from the table in a streaming manner: "platform_coupon_product_group" */
  platform_coupon_product_group_stream: Array<platform_coupon_product_group>;
  /** fetch data from the table in a streaming manner: "platform_coupon_product" */
  platform_coupon_product_stream: Array<platform_coupon_product>;
  /** fetch data from the table in a streaming manner: "platform_coupon" */
  platform_coupon_stream: Array<platform_coupon>;
  /** fetch data from the table: "platform_coupon_tsvector" */
  platform_coupon_tsvector: Array<platform_coupon_tsvector>;
  /** fetch aggregated fields from the table: "platform_coupon_tsvector" */
  platform_coupon_tsvector_aggregate: platform_coupon_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "platform_coupon_tsvector" */
  platform_coupon_tsvector_stream: Array<platform_coupon_tsvector>;
  /** fetch data from the table: "platform_coupon_type" */
  platform_coupon_type: Array<platform_coupon_type>;
  /** fetch aggregated fields from the table: "platform_coupon_type" */
  platform_coupon_type_aggregate: platform_coupon_type_aggregate;
  /** fetch data from the table: "platform_coupon_type" using primary key columns */
  platform_coupon_type_by_pk?: Maybe<platform_coupon_type>;
  /** fetch data from the table in a streaming manner: "platform_coupon_type" */
  platform_coupon_type_stream: Array<platform_coupon_type>;
  /** fetch data from the table: "platform_credentials" */
  platform_credentials: Array<platform_credentials>;
  /** fetch aggregated fields from the table: "platform_credentials" */
  platform_credentials_aggregate: platform_credentials_aggregate;
  /** fetch data from the table: "platform_credentials" using primary key columns */
  platform_credentials_by_pk?: Maybe<platform_credentials>;
  /** fetch data from the table in a streaming manner: "platform_credentials" */
  platform_credentials_stream: Array<platform_credentials>;
  /** fetch data from the table: "platform_currency" */
  platform_currency: Array<platform_currency>;
  /** fetch aggregated fields from the table: "platform_currency" */
  platform_currency_aggregate: platform_currency_aggregate;
  /** fetch data from the table: "platform_currency" using primary key columns */
  platform_currency_by_pk?: Maybe<platform_currency>;
  /** fetch data from the table: "platform_currency_exchange_rate" */
  platform_currency_exchange_rate: Array<platform_currency_exchange_rate>;
  /** fetch aggregated fields from the table: "platform_currency_exchange_rate" */
  platform_currency_exchange_rate_aggregate: platform_currency_exchange_rate_aggregate;
  /** fetch data from the table: "platform_currency_exchange_rate" using primary key columns */
  platform_currency_exchange_rate_by_pk?: Maybe<platform_currency_exchange_rate>;
  /** fetch data from the table in a streaming manner: "platform_currency_exchange_rate" */
  platform_currency_exchange_rate_stream: Array<platform_currency_exchange_rate>;
  /** fetch data from the table in a streaming manner: "platform_currency" */
  platform_currency_stream: Array<platform_currency>;
  /** fetch data from the table: "platform_customer" */
  platform_customer: Array<platform_customer>;
  /** fetch aggregated fields from the table: "platform_customer" */
  platform_customer_aggregate: platform_customer_aggregate;
  /** fetch data from the table: "platform_customer" using primary key columns */
  platform_customer_by_pk?: Maybe<platform_customer>;
  /** fetch data from the table in a streaming manner: "platform_customer" */
  platform_customer_stream: Array<platform_customer>;
  /** fetch data from the table: "platform_ecommerce_product" */
  platform_ecommerce_product: Array<platform_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_ecommerce_product" */
  platform_ecommerce_product_aggregate: platform_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_ecommerce_product" using primary key columns */
  platform_ecommerce_product_by_pk?: Maybe<platform_ecommerce_product>;
  /** fetch data from the table in a streaming manner: "platform_ecommerce_product" */
  platform_ecommerce_product_stream: Array<platform_ecommerce_product>;
  /** fetch data from the table: "platform_import" */
  platform_import: Array<platform_import>;
  /** fetch aggregated fields from the table: "platform_import" */
  platform_import_aggregate: platform_import_aggregate;
  /** fetch data from the table: "platform_import" using primary key columns */
  platform_import_by_pk?: Maybe<platform_import>;
  /** fetch data from the table: "platform_import_status" */
  platform_import_status: Array<platform_import_status>;
  /** fetch aggregated fields from the table: "platform_import_status" */
  platform_import_status_aggregate: platform_import_status_aggregate;
  /** fetch data from the table: "platform_import_status" using primary key columns */
  platform_import_status_by_pk?: Maybe<platform_import_status>;
  /** fetch data from the table in a streaming manner: "platform_import_status" */
  platform_import_status_stream: Array<platform_import_status>;
  /** fetch data from the table: "platform_import_step" */
  platform_import_step: Array<platform_import_step>;
  /** fetch aggregated fields from the table: "platform_import_step" */
  platform_import_step_aggregate: platform_import_step_aggregate;
  /** fetch data from the table: "platform_import_step" using primary key columns */
  platform_import_step_by_pk?: Maybe<platform_import_step>;
  /** fetch data from the table in a streaming manner: "platform_import_step" */
  platform_import_step_stream: Array<platform_import_step>;
  /** fetch data from the table: "platform_import_step_type" */
  platform_import_step_type: Array<platform_import_step_type>;
  /** fetch aggregated fields from the table: "platform_import_step_type" */
  platform_import_step_type_aggregate: platform_import_step_type_aggregate;
  /** fetch data from the table: "platform_import_step_type" using primary key columns */
  platform_import_step_type_by_pk?: Maybe<platform_import_step_type>;
  /** fetch data from the table in a streaming manner: "platform_import_step_type" */
  platform_import_step_type_stream: Array<platform_import_step_type>;
  /** fetch data from the table in a streaming manner: "platform_import" */
  platform_import_stream: Array<platform_import>;
  /** fetch data from the table: "platform_invoice" */
  platform_invoice: Array<platform_invoice>;
  /** fetch aggregated fields from the table: "platform_invoice" */
  platform_invoice_aggregate: platform_invoice_aggregate;
  /** fetch data from the table: "platform_invoice" using primary key columns */
  platform_invoice_by_pk?: Maybe<platform_invoice>;
  /** fetch data from the table: "platform_invoice_status" */
  platform_invoice_status: Array<platform_invoice_status>;
  /** fetch aggregated fields from the table: "platform_invoice_status" */
  platform_invoice_status_aggregate: platform_invoice_status_aggregate;
  /** fetch data from the table: "platform_invoice_status" using primary key columns */
  platform_invoice_status_by_pk?: Maybe<platform_invoice_status>;
  /** fetch data from the table in a streaming manner: "platform_invoice_status" */
  platform_invoice_status_stream: Array<platform_invoice_status>;
  /** fetch data from the table in a streaming manner: "platform_invoice" */
  platform_invoice_stream: Array<platform_invoice>;
  /** fetch data from the table: "platform_newspaper_rate_code" */
  platform_newspaper_rate_code: Array<platform_newspaper_rate_code>;
  /** fetch aggregated fields from the table: "platform_newspaper_rate_code" */
  platform_newspaper_rate_code_aggregate: platform_newspaper_rate_code_aggregate;
  /** fetch data from the table: "platform_newspaper_rate_code" using primary key columns */
  platform_newspaper_rate_code_by_pk?: Maybe<platform_newspaper_rate_code>;
  /** fetch data from the table in a streaming manner: "platform_newspaper_rate_code" */
  platform_newspaper_rate_code_stream: Array<platform_newspaper_rate_code>;
  /** fetch data from the table: "platform_newspaper_subscription" */
  platform_newspaper_subscription: Array<platform_newspaper_subscription>;
  /** fetch aggregated fields from the table: "platform_newspaper_subscription" */
  platform_newspaper_subscription_aggregate: platform_newspaper_subscription_aggregate;
  /** fetch data from the table: "platform_newspaper_subscription" using primary key columns */
  platform_newspaper_subscription_by_pk?: Maybe<platform_newspaper_subscription>;
  /** fetch data from the table: "platform_newspaper_subscription_stop_date" */
  platform_newspaper_subscription_stop_date: Array<platform_newspaper_subscription_stop_date>;
  /** fetch aggregated fields from the table: "platform_newspaper_subscription_stop_date" */
  platform_newspaper_subscription_stop_date_aggregate: platform_newspaper_subscription_stop_date_aggregate;
  /** fetch data from the table in a streaming manner: "platform_newspaper_subscription_stop_date" */
  platform_newspaper_subscription_stop_date_stream: Array<platform_newspaper_subscription_stop_date>;
  /** fetch data from the table in a streaming manner: "platform_newspaper_subscription" */
  platform_newspaper_subscription_stream: Array<platform_newspaper_subscription>;
  /** fetch data from the table: "platform_plan" */
  platform_plan: Array<platform_plan>;
  /** fetch aggregated fields from the table: "platform_plan" */
  platform_plan_aggregate: platform_plan_aggregate;
  /** fetch data from the table: "platform_plan" using primary key columns */
  platform_plan_by_pk?: Maybe<platform_plan>;
  /** fetch data from the table: "platform_plan_ecommerce_product" */
  platform_plan_ecommerce_product: Array<platform_plan_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_plan_ecommerce_product" */
  platform_plan_ecommerce_product_aggregate: platform_plan_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_plan_ecommerce_product" using primary key columns */
  platform_plan_ecommerce_product_by_pk?: Maybe<platform_plan_ecommerce_product>;
  /** fetch data from the table in a streaming manner: "platform_plan_ecommerce_product" */
  platform_plan_ecommerce_product_stream: Array<platform_plan_ecommerce_product>;
  /** fetch data from the table: "platform_plan_group" */
  platform_plan_group: Array<platform_plan_group>;
  /** fetch aggregated fields from the table: "platform_plan_group" */
  platform_plan_group_aggregate: platform_plan_group_aggregate;
  /** fetch data from the table: "platform_plan_group" using primary key columns */
  platform_plan_group_by_pk?: Maybe<platform_plan_group>;
  /** fetch data from the table in a streaming manner: "platform_plan_group" */
  platform_plan_group_stream: Array<platform_plan_group>;
  /** fetch data from the table: "platform_plan_interval" */
  platform_plan_interval: Array<platform_plan_interval>;
  /** fetch aggregated fields from the table: "platform_plan_interval" */
  platform_plan_interval_aggregate: platform_plan_interval_aggregate;
  /** fetch data from the table: "platform_plan_interval" using primary key columns */
  platform_plan_interval_by_pk?: Maybe<platform_plan_interval>;
  /** fetch data from the table in a streaming manner: "platform_plan_interval" */
  platform_plan_interval_stream: Array<platform_plan_interval>;
  /** fetch data from the table: "platform_plan_pricing_model" */
  platform_plan_pricing_model: Array<platform_plan_pricing_model>;
  /** fetch aggregated fields from the table: "platform_plan_pricing_model" */
  platform_plan_pricing_model_aggregate: platform_plan_pricing_model_aggregate;
  /** fetch data from the table: "platform_plan_pricing_model" using primary key columns */
  platform_plan_pricing_model_by_pk?: Maybe<platform_plan_pricing_model>;
  /** fetch data from the table in a streaming manner: "platform_plan_pricing_model" */
  platform_plan_pricing_model_stream: Array<platform_plan_pricing_model>;
  /** fetch data from the table in a streaming manner: "platform_plan" */
  platform_plan_stream: Array<platform_plan>;
  /** fetch data from the table: "platform_plan_tier" */
  platform_plan_tier: Array<platform_plan_tier>;
  /** fetch aggregated fields from the table: "platform_plan_tier" */
  platform_plan_tier_aggregate: platform_plan_tier_aggregate;
  /** fetch data from the table: "platform_plan_tier" using primary key columns */
  platform_plan_tier_by_pk?: Maybe<platform_plan_tier>;
  /** fetch data from the table in a streaming manner: "platform_plan_tier" */
  platform_plan_tier_stream: Array<platform_plan_tier>;
  /** fetch data from the table: "platform_product" */
  platform_product: Array<platform_product>;
  /** fetch aggregated fields from the table: "platform_product" */
  platform_product_aggregate: platform_product_aggregate;
  /** fetch data from the table: "platform_product" using primary key columns */
  platform_product_by_pk?: Maybe<platform_product>;
  /** fetch data from the table: "platform_product_group" */
  platform_product_group: Array<platform_product_group>;
  /** fetch aggregated fields from the table: "platform_product_group" */
  platform_product_group_aggregate: platform_product_group_aggregate;
  /** fetch data from the table: "platform_product_group" using primary key columns */
  platform_product_group_by_pk?: Maybe<platform_product_group>;
  /** fetch data from the table in a streaming manner: "platform_product_group" */
  platform_product_group_stream: Array<platform_product_group>;
  /** fetch data from the table in a streaming manner: "platform_product" */
  platform_product_stream: Array<platform_product>;
  /** fetch data from the table: "platform_raw_object" */
  platform_raw_object: Array<platform_raw_object>;
  /** fetch aggregated fields from the table: "platform_raw_object" */
  platform_raw_object_aggregate: platform_raw_object_aggregate;
  /** fetch data from the table: "platform_raw_object" using primary key columns */
  platform_raw_object_by_pk?: Maybe<platform_raw_object>;
  /** fetch data from the table in a streaming manner: "platform_raw_object" */
  platform_raw_object_stream: Array<platform_raw_object>;
  /** fetch data from the table: "platform_raw_object_type" */
  platform_raw_object_type: Array<platform_raw_object_type>;
  /** fetch aggregated fields from the table: "platform_raw_object_type" */
  platform_raw_object_type_aggregate: platform_raw_object_type_aggregate;
  /** fetch data from the table: "platform_raw_object_type" using primary key columns */
  platform_raw_object_type_by_pk?: Maybe<platform_raw_object_type>;
  /** fetch data from the table in a streaming manner: "platform_raw_object_type" */
  platform_raw_object_type_stream: Array<platform_raw_object_type>;
  /** fetch data from the table: "platform_refund" */
  platform_refund: Array<platform_refund>;
  /** fetch aggregated fields from the table: "platform_refund" */
  platform_refund_aggregate: platform_refund_aggregate;
  /** fetch data from the table: "platform_refund" using primary key columns */
  platform_refund_by_pk?: Maybe<platform_refund>;
  /** fetch data from the table in a streaming manner: "platform_refund" */
  platform_refund_stream: Array<platform_refund>;
  /** fetch data from the table in a streaming manner: "platform" */
  platform_stream: Array<platform>;
  /** fetch data from the table: "platform_subscription" */
  platform_subscription: Array<platform_subscription>;
  /** fetch data from the table: "platform_subscription_addon" */
  platform_subscription_addon: Array<platform_subscription_addon>;
  /** fetch aggregated fields from the table: "platform_subscription_addon" */
  platform_subscription_addon_aggregate: platform_subscription_addon_aggregate;
  /** fetch data from the table: "platform_subscription_addon" using primary key columns */
  platform_subscription_addon_by_pk?: Maybe<platform_subscription_addon>;
  /** fetch data from the table in a streaming manner: "platform_subscription_addon" */
  platform_subscription_addon_stream: Array<platform_subscription_addon>;
  /** fetch aggregated fields from the table: "platform_subscription" */
  platform_subscription_aggregate: platform_subscription_aggregate;
  /** fetch data from the table: "platform_subscription" using primary key columns */
  platform_subscription_by_pk?: Maybe<platform_subscription>;
  /** fetch data from the table: "platform_subscription_next_billing_at" */
  platform_subscription_next_billing_at: Array<platform_subscription_next_billing_at>;
  /** fetch aggregated fields from the table: "platform_subscription_next_billing_at" */
  platform_subscription_next_billing_at_aggregate: platform_subscription_next_billing_at_aggregate;
  /** fetch data from the table in a streaming manner: "platform_subscription_next_billing_at" */
  platform_subscription_next_billing_at_stream: Array<platform_subscription_next_billing_at>;
  /** fetch data from the table: "platform_subscription_plan" */
  platform_subscription_plan: Array<platform_subscription_plan>;
  /** fetch aggregated fields from the table: "platform_subscription_plan" */
  platform_subscription_plan_aggregate: platform_subscription_plan_aggregate;
  /** fetch data from the table: "platform_subscription_plan" using primary key columns */
  platform_subscription_plan_by_pk?: Maybe<platform_subscription_plan>;
  /** fetch data from the table in a streaming manner: "platform_subscription_plan" */
  platform_subscription_plan_stream: Array<platform_subscription_plan>;
  /** fetch data from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  platform_subscription_plan_swappable_ecommerce_product: Array<platform_subscription_plan_swappable_ecommerce_product>;
  /** fetch aggregated fields from the table: "platform_subscription_plan_swappable_ecommerce_product" */
  platform_subscription_plan_swappable_ecommerce_product_aggregate: platform_subscription_plan_swappable_ecommerce_product_aggregate;
  /** fetch data from the table: "platform_subscription_plan_swappable_ecommerce_product" using primary key columns */
  platform_subscription_plan_swappable_ecommerce_product_by_pk?: Maybe<platform_subscription_plan_swappable_ecommerce_product>;
  /** fetch data from the table in a streaming manner: "platform_subscription_plan_swappable_ecommerce_product" */
  platform_subscription_plan_swappable_ecommerce_product_stream: Array<platform_subscription_plan_swappable_ecommerce_product>;
  /** fetch data from the table: "platform_subscription_status" */
  platform_subscription_status: Array<platform_subscription_status>;
  /** fetch aggregated fields from the table: "platform_subscription_status" */
  platform_subscription_status_aggregate: platform_subscription_status_aggregate;
  /** fetch data from the table: "platform_subscription_status" using primary key columns */
  platform_subscription_status_by_pk?: Maybe<platform_subscription_status>;
  /** fetch data from the table in a streaming manner: "platform_subscription_status" */
  platform_subscription_status_stream: Array<platform_subscription_status>;
  /** fetch data from the table in a streaming manner: "platform_subscription" */
  platform_subscription_stream: Array<platform_subscription>;
  /** fetch data from the table: "platform_variant" */
  platform_variant: Array<platform_variant>;
  /** fetch aggregated fields from the table: "platform_variant" */
  platform_variant_aggregate: platform_variant_aggregate;
  /** fetch data from the table: "platform_variant" using primary key columns */
  platform_variant_by_pk?: Maybe<platform_variant>;
  /** fetch data from the table: "platform_variant_option" */
  platform_variant_option: Array<platform_variant_option>;
  /** fetch aggregated fields from the table: "platform_variant_option" */
  platform_variant_option_aggregate: platform_variant_option_aggregate;
  /** fetch data from the table: "platform_variant_option" using primary key columns */
  platform_variant_option_by_pk?: Maybe<platform_variant_option>;
  /** fetch data from the table in a streaming manner: "platform_variant_option" */
  platform_variant_option_stream: Array<platform_variant_option>;
  /** fetch data from the table in a streaming manner: "platform_variant" */
  platform_variant_stream: Array<platform_variant>;
  /** fetch data from the table: "portal_email_code" */
  portal_email_code: Array<portal_email_code>;
  /** fetch aggregated fields from the table: "portal_email_code" */
  portal_email_code_aggregate: portal_email_code_aggregate;
  /** fetch data from the table: "portal_email_code" using primary key columns */
  portal_email_code_by_pk?: Maybe<portal_email_code>;
  /** fetch data from the table in a streaming manner: "portal_email_code" */
  portal_email_code_stream: Array<portal_email_code>;
  /** fetch data from the table: "portal_session" */
  portal_session: Array<portal_session>;
  /** fetch aggregated fields from the table: "portal_session" */
  portal_session_aggregate: portal_session_aggregate;
  /** fetch data from the table: "portal_session" using primary key columns */
  portal_session_by_pk?: Maybe<portal_session>;
  /** fetch data from the table in a streaming manner: "portal_session" */
  portal_session_stream: Array<portal_session>;
  /** fetch data from the table: "portal_session_subscriber" */
  portal_session_subscriber: Array<portal_session_subscriber>;
  /** fetch aggregated fields from the table: "portal_session_subscriber" */
  portal_session_subscriber_aggregate: portal_session_subscriber_aggregate;
  /** fetch data from the table: "portal_session_subscriber" using primary key columns */
  portal_session_subscriber_by_pk?: Maybe<portal_session_subscriber>;
  /** fetch data from the table in a streaming manner: "portal_session_subscriber" */
  portal_session_subscriber_stream: Array<portal_session_subscriber>;
  /** fetch data from the table: "product" */
  product: Array<product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: product_aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<product>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<product>;
  /** fetch data from the table: "property" */
  property: Array<property>;
  /** fetch aggregated fields from the table: "property" */
  property_aggregate: property_aggregate;
  /** fetch data from the table: "property" using primary key columns */
  property_by_pk?: Maybe<property>;
  /** fetch data from the table: "property_entity" */
  property_entity: Array<property_entity>;
  /** fetch aggregated fields from the table: "property_entity" */
  property_entity_aggregate: property_entity_aggregate;
  /** fetch data from the table: "property_entity" using primary key columns */
  property_entity_by_pk?: Maybe<property_entity>;
  /** fetch data from the table in a streaming manner: "property_entity" */
  property_entity_stream: Array<property_entity>;
  /** fetch data from the table: "property_format" */
  property_format: Array<property_format>;
  /** fetch aggregated fields from the table: "property_format" */
  property_format_aggregate: property_format_aggregate;
  /** fetch data from the table: "property_format" using primary key columns */
  property_format_by_pk?: Maybe<property_format>;
  /** fetch data from the table in a streaming manner: "property_format" */
  property_format_stream: Array<property_format>;
  /** fetch data from the table in a streaming manner: "property" */
  property_stream: Array<property>;
  /** fetch data from the table: "property_tsvector" */
  property_tsvector: Array<property_tsvector>;
  /** fetch aggregated fields from the table: "property_tsvector" */
  property_tsvector_aggregate: property_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "property_tsvector" */
  property_tsvector_stream: Array<property_tsvector>;
  /** fetch data from the table: "property_type" */
  property_type: Array<property_type>;
  /** fetch aggregated fields from the table: "property_type" */
  property_type_aggregate: property_type_aggregate;
  /** fetch data from the table: "property_type" using primary key columns */
  property_type_by_pk?: Maybe<property_type>;
  /** fetch data from the table in a streaming manner: "property_type" */
  property_type_stream: Array<property_type>;
  /** fetch data from the table: "question" */
  question: Array<question>;
  /** fetch aggregated fields from the table: "question" */
  question_aggregate: question_aggregate;
  /** fetch data from the table: "question_answer" */
  question_answer: Array<question_answer>;
  /** fetch aggregated fields from the table: "question_answer" */
  question_answer_aggregate: question_answer_aggregate;
  /** fetch data from the table: "question_answer" using primary key columns */
  question_answer_by_pk?: Maybe<question_answer>;
  /** fetch data from the table: "question_answer_likelihood_to_return" */
  question_answer_likelihood_to_return: Array<question_answer_likelihood_to_return>;
  /** fetch aggregated fields from the table: "question_answer_likelihood_to_return" */
  question_answer_likelihood_to_return_aggregate: question_answer_likelihood_to_return_aggregate;
  /** fetch data from the table: "question_answer_likelihood_to_return" using primary key columns */
  question_answer_likelihood_to_return_by_pk?: Maybe<question_answer_likelihood_to_return>;
  /** fetch data from the table in a streaming manner: "question_answer_likelihood_to_return" */
  question_answer_likelihood_to_return_stream: Array<question_answer_likelihood_to_return>;
  /** fetch data from the table: "question_answer_radio" */
  question_answer_radio: Array<question_answer_radio>;
  /** fetch aggregated fields from the table: "question_answer_radio" */
  question_answer_radio_aggregate: question_answer_radio_aggregate;
  /** fetch data from the table: "question_answer_radio" using primary key columns */
  question_answer_radio_by_pk?: Maybe<question_answer_radio>;
  /** fetch data from the table in a streaming manner: "question_answer_radio" */
  question_answer_radio_stream: Array<question_answer_radio>;
  /** fetch data from the table: "question_answer_sentiment_phrase" */
  question_answer_sentiment_phrase: Array<question_answer_sentiment_phrase>;
  /** fetch aggregated fields from the table: "question_answer_sentiment_phrase" */
  question_answer_sentiment_phrase_aggregate: question_answer_sentiment_phrase_aggregate;
  /** fetch data from the table: "question_answer_sentiment_phrase" using primary key columns */
  question_answer_sentiment_phrase_by_pk?: Maybe<question_answer_sentiment_phrase>;
  /** fetch data from the table in a streaming manner: "question_answer_sentiment_phrase" */
  question_answer_sentiment_phrase_stream: Array<question_answer_sentiment_phrase>;
  /** fetch data from the table in a streaming manner: "question_answer" */
  question_answer_stream: Array<question_answer>;
  /** fetch data from the table: "question_answer_text" */
  question_answer_text: Array<question_answer_text>;
  /** fetch aggregated fields from the table: "question_answer_text" */
  question_answer_text_aggregate: question_answer_text_aggregate;
  /** fetch data from the table: "question_answer_text" using primary key columns */
  question_answer_text_by_pk?: Maybe<question_answer_text>;
  /** fetch data from the table in a streaming manner: "question_answer_text" */
  question_answer_text_stream: Array<question_answer_text>;
  /** fetch data from the table: "question" using primary key columns */
  question_by_pk?: Maybe<question>;
  /** fetch data from the table: "question_option" */
  question_option: Array<question_option>;
  /** fetch aggregated fields from the table: "question_option" */
  question_option_aggregate: question_option_aggregate;
  /** fetch data from the table: "question_option" using primary key columns */
  question_option_by_pk?: Maybe<question_option>;
  /** fetch data from the table in a streaming manner: "question_option" */
  question_option_stream: Array<question_option>;
  /** fetch data from the table: "question_option_version" */
  question_option_version: Array<question_option_version>;
  /** fetch aggregated fields from the table: "question_option_version" */
  question_option_version_aggregate: question_option_version_aggregate;
  /** fetch data from the table: "question_option_version" using primary key columns */
  question_option_version_by_pk?: Maybe<question_option_version>;
  /** fetch data from the table in a streaming manner: "question_option_version" */
  question_option_version_stream: Array<question_option_version>;
  /** fetch data from the table: "question_setting" */
  question_setting: Array<question_setting>;
  /** fetch aggregated fields from the table: "question_setting" */
  question_setting_aggregate: question_setting_aggregate;
  /** fetch data from the table: "question_setting" using primary key columns */
  question_setting_by_pk?: Maybe<question_setting>;
  /** fetch data from the table: "question_setting_key" */
  question_setting_key: Array<question_setting_key>;
  /** fetch aggregated fields from the table: "question_setting_key" */
  question_setting_key_aggregate: question_setting_key_aggregate;
  /** fetch data from the table: "question_setting_key" using primary key columns */
  question_setting_key_by_pk?: Maybe<question_setting_key>;
  /** fetch data from the table in a streaming manner: "question_setting_key" */
  question_setting_key_stream: Array<question_setting_key>;
  /** fetch data from the table in a streaming manner: "question_setting" */
  question_setting_stream: Array<question_setting>;
  /** fetch data from the table: "question_setting_version" */
  question_setting_version: Array<question_setting_version>;
  /** fetch aggregated fields from the table: "question_setting_version" */
  question_setting_version_aggregate: question_setting_version_aggregate;
  /** fetch data from the table: "question_setting_version" using primary key columns */
  question_setting_version_by_pk?: Maybe<question_setting_version>;
  /** fetch data from the table in a streaming manner: "question_setting_version" */
  question_setting_version_stream: Array<question_setting_version>;
  /** fetch data from the table in a streaming manner: "question" */
  question_stream: Array<question>;
  /** fetch data from the table: "question_type" */
  question_type: Array<question_type>;
  /** fetch aggregated fields from the table: "question_type" */
  question_type_aggregate: question_type_aggregate;
  /** fetch data from the table: "question_type" using primary key columns */
  question_type_by_pk?: Maybe<question_type>;
  /** fetch data from the table in a streaming manner: "question_type" */
  question_type_stream: Array<question_type>;
  /** fetch data from the table: "question_version" */
  question_version: Array<question_version>;
  /** fetch aggregated fields from the table: "question_version" */
  question_version_aggregate: question_version_aggregate;
  /** fetch data from the table: "question_version" using primary key columns */
  question_version_by_pk?: Maybe<question_version>;
  /** fetch data from the table: "question_version_condition_question_option" */
  question_version_condition_question_option: Array<question_version_condition_question_option>;
  /** fetch aggregated fields from the table: "question_version_condition_question_option" */
  question_version_condition_question_option_aggregate: question_version_condition_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "question_version_condition_question_option" */
  question_version_condition_question_option_stream: Array<question_version_condition_question_option>;
  /** fetch data from the table: "question_version_condition_segment" */
  question_version_condition_segment: Array<question_version_condition_segment>;
  /** fetch aggregated fields from the table: "question_version_condition_segment" */
  question_version_condition_segment_aggregate: question_version_condition_segment_aggregate;
  /** fetch data from the table: "question_version_condition_segment_group" */
  question_version_condition_segment_group: Array<question_version_condition_segment_group>;
  /** fetch aggregated fields from the table: "question_version_condition_segment_group" */
  question_version_condition_segment_group_aggregate: question_version_condition_segment_group_aggregate;
  /** fetch data from the table in a streaming manner: "question_version_condition_segment_group" */
  question_version_condition_segment_group_stream: Array<question_version_condition_segment_group>;
  /** fetch data from the table in a streaming manner: "question_version_condition_segment" */
  question_version_condition_segment_stream: Array<question_version_condition_segment>;
  /** fetch data from the table: "question_version_question_option" */
  question_version_question_option: Array<question_version_question_option>;
  /** fetch aggregated fields from the table: "question_version_question_option" */
  question_version_question_option_aggregate: question_version_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "question_version_question_option" */
  question_version_question_option_stream: Array<question_version_question_option>;
  /** fetch data from the table in a streaming manner: "question_version" */
  question_version_stream: Array<question_version>;
  /** execute function "report_deflection_trends" which returns "template_report_deflection_trends" */
  report_deflection_trends: Array<template_report_deflection_trends>;
  /** execute function "report_deflection_trends" and query aggregates on result of table type "template_report_deflection_trends" */
  report_deflection_trends_aggregate: template_report_deflection_trends_aggregate;
  /** execute function "report_feedback_trends" which returns "template_report_feedback_trends" */
  report_feedback_trends: Array<template_report_feedback_trends>;
  /** execute function "report_feedback_trends" and query aggregates on result of table type "template_report_feedback_trends" */
  report_feedback_trends_aggregate: template_report_feedback_trends_aggregate;
  /** An array relationship */
  report_flow_test_stats: Array<report_flow_test_stats>;
  /** An aggregate relationship */
  report_flow_test_stats_aggregate: report_flow_test_stats_aggregate;
  /** fetch data from the table in a streaming manner: "report_flow_test_stats" */
  report_flow_test_stats_stream: Array<report_flow_test_stats>;
  /** fetch data from the table: "report_inactive_mrr_by_question_option" */
  report_inactive_mrr_by_question_option: Array<report_inactive_mrr_by_question_option>;
  /** fetch aggregated fields from the table: "report_inactive_mrr_by_question_option" */
  report_inactive_mrr_by_question_option_aggregate: report_inactive_mrr_by_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "report_inactive_mrr_by_question_option" */
  report_inactive_mrr_by_question_option_stream: Array<report_inactive_mrr_by_question_option>;
  /** execute function "report_offer_accept_rate" which returns "template_report_offer_accept_rate" */
  report_offer_accept_rate: Array<template_report_offer_accept_rate>;
  /** execute function "report_offer_accept_rate" and query aggregates on result of table type "template_report_offer_accept_rate" */
  report_offer_accept_rate_aggregate: template_report_offer_accept_rate_aggregate;
  /** An array relationship */
  report_offer_autopilot_stats: Array<report_offer_autopilot_stats>;
  /** An aggregate relationship */
  report_offer_autopilot_stats_aggregate: report_offer_autopilot_stats_aggregate;
  /** fetch data from the table in a streaming manner: "report_offer_autopilot_stats" */
  report_offer_autopilot_stats_stream: Array<report_offer_autopilot_stats>;
  /** execute function "report_offer_group_accept_rate" which returns "template_report_offer_group_accept_rate" */
  report_offer_group_accept_rate: Array<template_report_offer_group_accept_rate>;
  /** execute function "report_offer_group_accept_rate" and query aggregates on result of table type "template_report_offer_group_accept_rate" */
  report_offer_group_accept_rate_aggregate: template_report_offer_group_accept_rate_aggregate;
  /** fetch data from the table: "report_offer_group_resolution_by_question_option" */
  report_offer_group_resolution_by_question_option: Array<report_offer_group_resolution_by_question_option>;
  /** fetch aggregated fields from the table: "report_offer_group_resolution_by_question_option" */
  report_offer_group_resolution_by_question_option_aggregate: report_offer_group_resolution_by_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "report_offer_group_resolution_by_question_option" */
  report_offer_group_resolution_by_question_option_stream: Array<report_offer_group_resolution_by_question_option>;
  /** execute function "report_offer_group_retained_revenue" which returns "template_report_offer_group_retained_revenue" */
  report_offer_group_retained_revenue: Array<template_report_offer_group_retained_revenue>;
  /** execute function "report_offer_group_retained_revenue" and query aggregates on result of table type "template_report_offer_group_retained_revenue" */
  report_offer_group_retained_revenue_aggregate: template_report_offer_group_retained_revenue_aggregate;
  /** execute function "report_offer_rate" which returns "template_report_offer_rate" */
  report_offer_rate: Array<template_report_offer_rate>;
  /** execute function "report_offer_rate" and query aggregates on result of table type "template_report_offer_rate" */
  report_offer_rate_aggregate: template_report_offer_rate_aggregate;
  /** execute function "report_offer_resolution" which returns "template_report_offer_resolution" */
  report_offer_resolution: Array<template_report_offer_resolution>;
  /** execute function "report_offer_resolution" and query aggregates on result of table type "template_report_offer_resolution" */
  report_offer_resolution_aggregate: template_report_offer_resolution_aggregate;
  /** fetch data from the table: "report_offer_resolution_by_question_option" */
  report_offer_resolution_by_question_option: Array<report_offer_resolution_by_question_option>;
  /** fetch aggregated fields from the table: "report_offer_resolution_by_question_option" */
  report_offer_resolution_by_question_option_aggregate: report_offer_resolution_by_question_option_aggregate;
  /** fetch data from the table in a streaming manner: "report_offer_resolution_by_question_option" */
  report_offer_resolution_by_question_option_stream: Array<report_offer_resolution_by_question_option>;
  /** execute function "report_offer_retained_revenue" which returns "template_report_offer_retained_revenue" */
  report_offer_retained_revenue: Array<template_report_offer_retained_revenue>;
  /** execute function "report_offer_retained_revenue" and query aggregates on result of table type "template_report_offer_retained_revenue" */
  report_offer_retained_revenue_aggregate: template_report_offer_retained_revenue_aggregate;
  /** An array relationship */
  report_offer_test_stats: Array<report_offer_test_stats>;
  /** An aggregate relationship */
  report_offer_test_stats_aggregate: report_offer_test_stats_aggregate;
  /** fetch data from the table in a streaming manner: "report_offer_test_stats" */
  report_offer_test_stats_stream: Array<report_offer_test_stats>;
  /** execute function "report_question_answer_radio" which returns "template_report_question_answer_radio" */
  report_question_answer_radio: Array<template_report_question_answer_radio>;
  /** execute function "report_question_answer_radio" and query aggregates on result of table type "template_report_question_answer_radio" */
  report_question_answer_radio_aggregate: template_report_question_answer_radio_aggregate;
  /** execute function "report_question_answer_radio_inactive_mrr" which returns "template_report_question_answer_radio_inactive_mrr" */
  report_question_answer_radio_inactive_mrr: Array<template_report_question_answer_radio_inactive_mrr>;
  /** execute function "report_question_answer_radio_inactive_mrr" and query aggregates on result of table type "template_report_question_answer_radio_inactive_mrr" */
  report_question_answer_radio_inactive_mrr_aggregate: template_report_question_answer_radio_inactive_mrr_aggregate;
  /** execute function "report_question_answer_sentiment" which returns "template_report_question_answer_sentiment" */
  report_question_answer_sentiment: Array<template_report_question_answer_sentiment>;
  /** execute function "report_question_answer_sentiment" and query aggregates on result of table type "template_report_question_answer_sentiment" */
  report_question_answer_sentiment_aggregate: template_report_question_answer_sentiment_aggregate;
  /** fetch data from the table: "report_recurring_revenue" */
  report_recurring_revenue: Array<report_recurring_revenue>;
  /** fetch aggregated fields from the table: "report_recurring_revenue" */
  report_recurring_revenue_aggregate: report_recurring_revenue_aggregate;
  /** fetch data from the table: "report_recurring_revenue" using primary key columns */
  report_recurring_revenue_by_pk?: Maybe<report_recurring_revenue>;
  /** fetch data from the table in a streaming manner: "report_recurring_revenue" */
  report_recurring_revenue_stream: Array<report_recurring_revenue>;
  /** execute function "report_retained_revenue" which returns "template_report_retained_revenue" */
  report_retained_revenue: Array<template_report_retained_revenue>;
  /** execute function "report_retained_revenue" and query aggregates on result of table type "template_report_retained_revenue" */
  report_retained_revenue_aggregate: template_report_retained_revenue_aggregate;
  /** execute function "report_retention_trends" which returns "template_report_retention_trends" */
  report_retention_trends: Array<template_report_retention_trends>;
  /** execute function "report_retention_trends" and query aggregates on result of table type "template_report_retention_trends" */
  report_retention_trends_aggregate: template_report_retention_trends_aggregate;
  /** execute function "report_subscriber_flow_status" which returns "template_report_subscriber_flow_status" */
  report_subscriber_flow_status: Array<template_report_subscriber_flow_status>;
  /** execute function "report_subscriber_flow_status" and query aggregates on result of table type "template_report_subscriber_flow_status" */
  report_subscriber_flow_status_aggregate: template_report_subscriber_flow_status_aggregate;
  /** fetch data from the table: "revenue_log" */
  revenue_log: Array<revenue_log>;
  /** fetch aggregated fields from the table: "revenue_log" */
  revenue_log_aggregate: revenue_log_aggregate;
  /** fetch data from the table: "revenue_log" using primary key columns */
  revenue_log_by_pk?: Maybe<revenue_log>;
  /** fetch data from the table: "revenue_log_revenue_type" */
  revenue_log_revenue_type: Array<revenue_log_revenue_type>;
  /** fetch aggregated fields from the table: "revenue_log_revenue_type" */
  revenue_log_revenue_type_aggregate: revenue_log_revenue_type_aggregate;
  /** fetch data from the table: "revenue_log_revenue_type" using primary key columns */
  revenue_log_revenue_type_by_pk?: Maybe<revenue_log_revenue_type>;
  /** fetch data from the table in a streaming manner: "revenue_log_revenue_type" */
  revenue_log_revenue_type_stream: Array<revenue_log_revenue_type>;
  /** fetch data from the table in a streaming manner: "revenue_log" */
  revenue_log_stream: Array<revenue_log>;
  /** fetch data from the table: "revenue_log_type" */
  revenue_log_type: Array<revenue_log_type>;
  /** fetch aggregated fields from the table: "revenue_log_type" */
  revenue_log_type_aggregate: revenue_log_type_aggregate;
  /** fetch data from the table: "revenue_log_type" using primary key columns */
  revenue_log_type_by_pk?: Maybe<revenue_log_type>;
  /** fetch data from the table in a streaming manner: "revenue_log_type" */
  revenue_log_type_stream: Array<revenue_log_type>;
  /** fetch data from the table: "rule_group_mode" */
  rule_group_mode: Array<rule_group_mode>;
  /** fetch aggregated fields from the table: "rule_group_mode" */
  rule_group_mode_aggregate: rule_group_mode_aggregate;
  /** fetch data from the table: "rule_group_mode" using primary key columns */
  rule_group_mode_by_pk?: Maybe<rule_group_mode>;
  /** fetch data from the table in a streaming manner: "rule_group_mode" */
  rule_group_mode_stream: Array<rule_group_mode>;
  /** fetch data from the table: "script_log" */
  script_log: Array<script_log>;
  /** fetch aggregated fields from the table: "script_log" */
  script_log_aggregate: script_log_aggregate;
  /** fetch data from the table: "script_log" using primary key columns */
  script_log_by_pk?: Maybe<script_log>;
  /** fetch data from the table in a streaming manner: "script_log" */
  script_log_stream: Array<script_log>;
  /** execute function "search_accounts" which returns "account" */
  search_accounts: Array<account>;
  /** execute function "search_accounts" and query aggregates on result of table type "account" */
  search_accounts_aggregate: account_aggregate;
  /** execute function "search_data_connector_request_logs" which returns "data_connector_request_log" */
  search_data_connector_request_logs: Array<data_connector_request_log>;
  /** execute function "search_data_connector_request_logs" and query aggregates on result of table type "data_connector_request_log" */
  search_data_connector_request_logs_aggregate: data_connector_request_log_aggregate;
  /** execute function "search_deflections" which returns "deflection" */
  search_deflections: Array<deflection>;
  /** execute function "search_deflections" and query aggregates on result of table type "deflection" */
  search_deflections_aggregate: deflection_aggregate;
  /** execute function "search_offers" which returns "offer" */
  search_offers: Array<offer>;
  /** execute function "search_offers" and query aggregates on result of table type "offer" */
  search_offers_aggregate: offer_aggregate;
  /** execute function "search_platform_api_logs" which returns "platform_api_log" */
  search_platform_api_logs: Array<platform_api_log>;
  /** execute function "search_platform_api_logs" and query aggregates on result of table type "platform_api_log" */
  search_platform_api_logs_aggregate: platform_api_log_aggregate;
  /** execute function "search_platform_coupons" which returns "platform_coupon" */
  search_platform_coupons: Array<platform_coupon>;
  /** execute function "search_platform_coupons" and query aggregates on result of table type "platform_coupon" */
  search_platform_coupons_aggregate: platform_coupon_aggregate;
  /** execute function "search_properties" which returns "property" */
  search_properties: Array<property>;
  /** execute function "search_properties" and query aggregates on result of table type "property" */
  search_properties_aggregate: property_aggregate;
  /** execute function "search_segment_groups" which returns "segment_group" */
  search_segment_groups: Array<segment_group>;
  /** execute function "search_segment_groups" and query aggregates on result of table type "segment_group" */
  search_segment_groups_aggregate: segment_group_aggregate;
  /** execute function "search_segments" which returns "segment" */
  search_segments: Array<segment>;
  /** execute function "search_segments" and query aggregates on result of table type "segment" */
  search_segments_aggregate: segment_aggregate;
  /** execute function "search_subscriber_flows" which returns "subscriber_flow" */
  search_subscriber_flows: Array<subscriber_flow>;
  /** execute function "search_subscriber_flows" and query aggregates on result of table type "subscriber_flow" */
  search_subscriber_flows_aggregate: subscriber_flow_aggregate;
  /** execute function "search_subscriber_grid" which returns "subscriber_grid" */
  search_subscriber_grid: Array<subscriber_grid>;
  /** execute function "search_subscriber_grid" and query aggregates on result of table type "subscriber_grid" */
  search_subscriber_grid_aggregate: subscriber_grid_aggregate;
  /** execute function "search_subscribers" which returns "subscriber" */
  search_subscribers: Array<subscriber>;
  /** execute function "search_subscribers" and query aggregates on result of table type "subscriber" */
  search_subscribers_aggregate: subscriber_aggregate;
  /** execute function "search_webhook_request_logs" which returns "webhook_request_log" */
  search_webhook_request_logs: Array<webhook_request_log>;
  /** execute function "search_webhook_request_logs" and query aggregates on result of table type "webhook_request_log" */
  search_webhook_request_logs_aggregate: webhook_request_log_aggregate;
  /** fetch data from the table: "segment" */
  segment: Array<segment>;
  /** fetch aggregated fields from the table: "segment" */
  segment_aggregate: segment_aggregate;
  /** fetch data from the table: "segment" using primary key columns */
  segment_by_pk?: Maybe<segment>;
  /** fetch data from the table: "segment_condition" */
  segment_condition: Array<segment_condition>;
  /** fetch aggregated fields from the table: "segment_condition" */
  segment_condition_aggregate: segment_condition_aggregate;
  /** fetch data from the table: "segment_condition_boolean_operator" */
  segment_condition_boolean_operator: Array<segment_condition_boolean_operator>;
  /** fetch aggregated fields from the table: "segment_condition_boolean_operator" */
  segment_condition_boolean_operator_aggregate: segment_condition_boolean_operator_aggregate;
  /** fetch data from the table: "segment_condition_boolean_operator" using primary key columns */
  segment_condition_boolean_operator_by_pk?: Maybe<segment_condition_boolean_operator>;
  /** fetch data from the table in a streaming manner: "segment_condition_boolean_operator" */
  segment_condition_boolean_operator_stream: Array<segment_condition_boolean_operator>;
  /** fetch data from the table: "segment_condition" using primary key columns */
  segment_condition_by_pk?: Maybe<segment_condition>;
  /** fetch data from the table: "segment_condition_group" */
  segment_condition_group: Array<segment_condition_group>;
  /** fetch aggregated fields from the table: "segment_condition_group" */
  segment_condition_group_aggregate: segment_condition_group_aggregate;
  /** fetch data from the table: "segment_condition_group" using primary key columns */
  segment_condition_group_by_pk?: Maybe<segment_condition_group>;
  /** fetch data from the table: "segment_condition_group_entry" */
  segment_condition_group_entry: Array<segment_condition_group_entry>;
  /** fetch aggregated fields from the table: "segment_condition_group_entry" */
  segment_condition_group_entry_aggregate: segment_condition_group_entry_aggregate;
  /** fetch data from the table: "segment_condition_group_entry" using primary key columns */
  segment_condition_group_entry_by_pk?: Maybe<segment_condition_group_entry>;
  /** fetch data from the table in a streaming manner: "segment_condition_group_entry" */
  segment_condition_group_entry_stream: Array<segment_condition_group_entry>;
  /** fetch data from the table in a streaming manner: "segment_condition_group" */
  segment_condition_group_stream: Array<segment_condition_group>;
  /** fetch data from the table: "segment_condition_operator" */
  segment_condition_operator: Array<segment_condition_operator>;
  /** fetch aggregated fields from the table: "segment_condition_operator" */
  segment_condition_operator_aggregate: segment_condition_operator_aggregate;
  /** fetch data from the table: "segment_condition_operator" using primary key columns */
  segment_condition_operator_by_pk?: Maybe<segment_condition_operator>;
  /** fetch data from the table in a streaming manner: "segment_condition_operator" */
  segment_condition_operator_stream: Array<segment_condition_operator>;
  /** fetch data from the table: "segment_condition_property" */
  segment_condition_property: Array<segment_condition_property>;
  /** fetch aggregated fields from the table: "segment_condition_property" */
  segment_condition_property_aggregate: segment_condition_property_aggregate;
  /** fetch data from the table: "segment_condition_property" using primary key columns */
  segment_condition_property_by_pk?: Maybe<segment_condition_property>;
  /** fetch data from the table in a streaming manner: "segment_condition_property" */
  segment_condition_property_stream: Array<segment_condition_property>;
  /** fetch data from the table in a streaming manner: "segment_condition" */
  segment_condition_stream: Array<segment_condition>;
  /** fetch data from the table: "segment_group" */
  segment_group: Array<segment_group>;
  /** fetch aggregated fields from the table: "segment_group" */
  segment_group_aggregate: segment_group_aggregate;
  /** fetch data from the table: "segment_group" using primary key columns */
  segment_group_by_pk?: Maybe<segment_group>;
  /** fetch data from the table: "segment_group_operator" */
  segment_group_operator: Array<segment_group_operator>;
  /** fetch aggregated fields from the table: "segment_group_operator" */
  segment_group_operator_aggregate: segment_group_operator_aggregate;
  /** fetch data from the table: "segment_group_operator" using primary key columns */
  segment_group_operator_by_pk?: Maybe<segment_group_operator>;
  /** fetch data from the table in a streaming manner: "segment_group_operator" */
  segment_group_operator_stream: Array<segment_group_operator>;
  /** fetch data from the table: "segment_group_segment" */
  segment_group_segment: Array<segment_group_segment>;
  /** fetch aggregated fields from the table: "segment_group_segment" */
  segment_group_segment_aggregate: segment_group_segment_aggregate;
  /** fetch data from the table: "segment_group_segment" using primary key columns */
  segment_group_segment_by_pk?: Maybe<segment_group_segment>;
  /** fetch data from the table in a streaming manner: "segment_group_segment" */
  segment_group_segment_stream: Array<segment_group_segment>;
  /** fetch data from the table in a streaming manner: "segment_group" */
  segment_group_stream: Array<segment_group>;
  /** fetch data from the table: "segment_group_tsvector" */
  segment_group_tsvector: Array<segment_group_tsvector>;
  /** fetch aggregated fields from the table: "segment_group_tsvector" */
  segment_group_tsvector_aggregate: segment_group_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "segment_group_tsvector" */
  segment_group_tsvector_stream: Array<segment_group_tsvector>;
  /** fetch data from the table in a streaming manner: "segment" */
  segment_stream: Array<segment>;
  /** execute function "segment_subscriber" which returns "subscriber" */
  segment_subscriber: Array<subscriber>;
  /** execute function "segment_subscriber" and query aggregates on result of table type "subscriber" */
  segment_subscriber_aggregate: subscriber_aggregate;
  /** execute function "segment_subscriber_flow" which returns "subscriber_flow" */
  segment_subscriber_flow: Array<subscriber_flow>;
  /** execute function "segment_subscriber_flow" and query aggregates on result of table type "subscriber_flow" */
  segment_subscriber_flow_aggregate: subscriber_flow_aggregate;
  /** execute function "segment_subscriber_log" which returns "subscriber_log" */
  segment_subscriber_log: Array<subscriber_log>;
  /** execute function "segment_subscriber_log" and query aggregates on result of table type "subscriber_log" */
  segment_subscriber_log_aggregate: subscriber_log_aggregate;
  /** execute function "segment_subscription" which returns "subscription" */
  segment_subscription: Array<subscription>;
  /** execute function "segment_subscription" and query aggregates on result of table type "subscription" */
  segment_subscription_aggregate: subscription_aggregate;
  /** fetch data from the table: "segment_subscription_cache" */
  segment_subscription_cache: Array<segment_subscription_cache>;
  /** fetch aggregated fields from the table: "segment_subscription_cache" */
  segment_subscription_cache_aggregate: segment_subscription_cache_aggregate;
  /** fetch data from the table: "segment_subscription_cache" using primary key columns */
  segment_subscription_cache_by_pk?: Maybe<segment_subscription_cache>;
  /** fetch data from the table in a streaming manner: "segment_subscription_cache" */
  segment_subscription_cache_stream: Array<segment_subscription_cache>;
  /** fetch data from the table: "segment_tag" */
  segment_tag: Array<segment_tag>;
  /** fetch aggregated fields from the table: "segment_tag" */
  segment_tag_aggregate: segment_tag_aggregate;
  /** fetch data from the table in a streaming manner: "segment_tag" */
  segment_tag_stream: Array<segment_tag>;
  /** fetch data from the table: "segment_tsvector" */
  segment_tsvector: Array<segment_tsvector>;
  /** fetch aggregated fields from the table: "segment_tsvector" */
  segment_tsvector_aggregate: segment_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "segment_tsvector" */
  segment_tsvector_stream: Array<segment_tsvector>;
  /** fetch data from the table: "sentiment" */
  sentiment: Array<sentiment>;
  /** fetch aggregated fields from the table: "sentiment" */
  sentiment_aggregate: sentiment_aggregate;
  /** fetch data from the table: "sentiment" using primary key columns */
  sentiment_by_pk?: Maybe<sentiment>;
  /** fetch data from the table: "sentiment_phrase" */
  sentiment_phrase: Array<sentiment_phrase>;
  /** fetch aggregated fields from the table: "sentiment_phrase" */
  sentiment_phrase_aggregate: sentiment_phrase_aggregate;
  /** fetch data from the table: "sentiment_phrase" using primary key columns */
  sentiment_phrase_by_pk?: Maybe<sentiment_phrase>;
  /** fetch data from the table: "sentiment_phrase_normalized" */
  sentiment_phrase_normalized: Array<sentiment_phrase_normalized>;
  /** fetch aggregated fields from the table: "sentiment_phrase_normalized" */
  sentiment_phrase_normalized_aggregate: sentiment_phrase_normalized_aggregate;
  /** fetch data from the table: "sentiment_phrase_normalized" using primary key columns */
  sentiment_phrase_normalized_by_pk?: Maybe<sentiment_phrase_normalized>;
  /** fetch data from the table in a streaming manner: "sentiment_phrase_normalized" */
  sentiment_phrase_normalized_stream: Array<sentiment_phrase_normalized>;
  /** fetch data from the table in a streaming manner: "sentiment_phrase" */
  sentiment_phrase_stream: Array<sentiment_phrase>;
  /** fetch data from the table in a streaming manner: "sentiment" */
  sentiment_stream: Array<sentiment>;
  /** fetch data from the table: "state" */
  state: Array<state>;
  /** fetch aggregated fields from the table: "state" */
  state_aggregate: state_aggregate;
  /** fetch data from the table: "state" using primary key columns */
  state_by_pk?: Maybe<state>;
  /** fetch data from the table: "state_name" */
  state_name: Array<state_name>;
  /** fetch aggregated fields from the table: "state_name" */
  state_name_aggregate: state_name_aggregate;
  /** fetch data from the table: "state_name" using primary key columns */
  state_name_by_pk?: Maybe<state_name>;
  /** fetch data from the table in a streaming manner: "state_name" */
  state_name_stream: Array<state_name>;
  /** fetch data from the table in a streaming manner: "state" */
  state_stream: Array<state>;
  /** fetch data from the table: "stripe_app" */
  stripe_app: Array<stripe_app>;
  /** fetch aggregated fields from the table: "stripe_app" */
  stripe_app_aggregate: stripe_app_aggregate;
  /** fetch data from the table: "stripe_app" using primary key columns */
  stripe_app_by_pk?: Maybe<stripe_app>;
  /** fetch data from the table in a streaming manner: "stripe_app" */
  stripe_app_stream: Array<stripe_app>;
  /** fetch data from the table: "subscriber" */
  subscriber: Array<subscriber>;
  /** fetch data from the table: "subscriber_acknowledgement" */
  subscriber_acknowledgement: Array<subscriber_acknowledgement>;
  /** fetch aggregated fields from the table: "subscriber_acknowledgement" */
  subscriber_acknowledgement_aggregate: subscriber_acknowledgement_aggregate;
  /** fetch data from the table: "subscriber_acknowledgement" using primary key columns */
  subscriber_acknowledgement_by_pk?: Maybe<subscriber_acknowledgement>;
  /** fetch data from the table in a streaming manner: "subscriber_acknowledgement" */
  subscriber_acknowledgement_stream: Array<subscriber_acknowledgement>;
  /** fetch aggregated fields from the table: "subscriber" */
  subscriber_aggregate: subscriber_aggregate;
  /** fetch data from the table: "subscriber_arr" */
  subscriber_arr: Array<subscriber_arr>;
  /** fetch aggregated fields from the table: "subscriber_arr" */
  subscriber_arr_aggregate: subscriber_arr_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_arr" */
  subscriber_arr_stream: Array<subscriber_arr>;
  /** fetch data from the table: "subscriber" using primary key columns */
  subscriber_by_pk?: Maybe<subscriber>;
  /** fetch data from the table: "subscriber_campaign" */
  subscriber_campaign: Array<subscriber_campaign>;
  /** fetch aggregated fields from the table: "subscriber_campaign" */
  subscriber_campaign_aggregate: subscriber_campaign_aggregate;
  /** fetch data from the table: "subscriber_campaign" using primary key columns */
  subscriber_campaign_by_pk?: Maybe<subscriber_campaign>;
  /** fetch data from the table: "subscriber_campaign_offer" */
  subscriber_campaign_offer: Array<subscriber_campaign_offer>;
  /** fetch aggregated fields from the table: "subscriber_campaign_offer" */
  subscriber_campaign_offer_aggregate: subscriber_campaign_offer_aggregate;
  /** fetch data from the table: "subscriber_campaign_offer" using primary key columns */
  subscriber_campaign_offer_by_pk?: Maybe<subscriber_campaign_offer>;
  /** fetch data from the table: "subscriber_campaign_offer_rule" */
  subscriber_campaign_offer_rule: Array<subscriber_campaign_offer_rule>;
  /** fetch aggregated fields from the table: "subscriber_campaign_offer_rule" */
  subscriber_campaign_offer_rule_aggregate: subscriber_campaign_offer_rule_aggregate;
  /** fetch data from the table: "subscriber_campaign_offer_rule" using primary key columns */
  subscriber_campaign_offer_rule_by_pk?: Maybe<subscriber_campaign_offer_rule>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_offer_rule" */
  subscriber_campaign_offer_rule_stream: Array<subscriber_campaign_offer_rule>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_offer" */
  subscriber_campaign_offer_stream: Array<subscriber_campaign_offer>;
  /** fetch data from the table: "subscriber_campaign_override" */
  subscriber_campaign_override: Array<subscriber_campaign_override>;
  /** fetch aggregated fields from the table: "subscriber_campaign_override" */
  subscriber_campaign_override_aggregate: subscriber_campaign_override_aggregate;
  /** fetch data from the table: "subscriber_campaign_override" using primary key columns */
  subscriber_campaign_override_by_pk?: Maybe<subscriber_campaign_override>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_override" */
  subscriber_campaign_override_stream: Array<subscriber_campaign_override>;
  /** fetch data from the table: "subscriber_campaign_segment" */
  subscriber_campaign_segment: Array<subscriber_campaign_segment>;
  /** fetch aggregated fields from the table: "subscriber_campaign_segment" */
  subscriber_campaign_segment_aggregate: subscriber_campaign_segment_aggregate;
  /** fetch data from the table: "subscriber_campaign_segment" using primary key columns */
  subscriber_campaign_segment_by_pk?: Maybe<subscriber_campaign_segment>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_segment" */
  subscriber_campaign_segment_stream: Array<subscriber_campaign_segment>;
  /** fetch data from the table: "subscriber_campaign_status" */
  subscriber_campaign_status: Array<subscriber_campaign_status>;
  /** fetch aggregated fields from the table: "subscriber_campaign_status" */
  subscriber_campaign_status_aggregate: subscriber_campaign_status_aggregate;
  /** fetch data from the table: "subscriber_campaign_status" using primary key columns */
  subscriber_campaign_status_by_pk?: Maybe<subscriber_campaign_status>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_status" */
  subscriber_campaign_status_stream: Array<subscriber_campaign_status>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign" */
  subscriber_campaign_stream: Array<subscriber_campaign>;
  /** fetch data from the table: "subscriber_campaign_view" */
  subscriber_campaign_view: Array<subscriber_campaign_view>;
  /** fetch aggregated fields from the table: "subscriber_campaign_view" */
  subscriber_campaign_view_aggregate: subscriber_campaign_view_aggregate;
  /** fetch data from the table: "subscriber_campaign_view" using primary key columns */
  subscriber_campaign_view_by_pk?: Maybe<subscriber_campaign_view>;
  /** fetch data from the table: "subscriber_campaign_view_status" */
  subscriber_campaign_view_status: Array<subscriber_campaign_view_status>;
  /** fetch aggregated fields from the table: "subscriber_campaign_view_status" */
  subscriber_campaign_view_status_aggregate: subscriber_campaign_view_status_aggregate;
  /** fetch data from the table: "subscriber_campaign_view_status" using primary key columns */
  subscriber_campaign_view_status_by_pk?: Maybe<subscriber_campaign_view_status>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_view_status" */
  subscriber_campaign_view_status_stream: Array<subscriber_campaign_view_status>;
  /** fetch data from the table in a streaming manner: "subscriber_campaign_view" */
  subscriber_campaign_view_stream: Array<subscriber_campaign_view>;
  /** fetch data from the table: "subscriber_created_at" */
  subscriber_created_at: Array<subscriber_created_at>;
  /** fetch aggregated fields from the table: "subscriber_created_at" */
  subscriber_created_at_aggregate: subscriber_created_at_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_created_at" */
  subscriber_created_at_stream: Array<subscriber_created_at>;
  /** fetch data from the table: "subscriber_days_since_saved" */
  subscriber_days_since_saved: Array<subscriber_days_since_saved>;
  /** fetch aggregated fields from the table: "subscriber_days_since_saved" */
  subscriber_days_since_saved_aggregate: subscriber_days_since_saved_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_days_since_saved" */
  subscriber_days_since_saved_stream: Array<subscriber_days_since_saved>;
  /** fetch data from the table: "subscriber_flow" */
  subscriber_flow: Array<subscriber_flow>;
  /** fetch aggregated fields from the table: "subscriber_flow" */
  subscriber_flow_aggregate: subscriber_flow_aggregate;
  /** fetch data from the table: "subscriber_flow" using primary key columns */
  subscriber_flow_by_pk?: Maybe<subscriber_flow>;
  /** fetch data from the table: "subscriber_flow_deflection" */
  subscriber_flow_deflection: Array<subscriber_flow_deflection>;
  /** fetch aggregated fields from the table: "subscriber_flow_deflection" */
  subscriber_flow_deflection_aggregate: subscriber_flow_deflection_aggregate;
  /** fetch data from the table: "subscriber_flow_deflection" using primary key columns */
  subscriber_flow_deflection_by_pk?: Maybe<subscriber_flow_deflection>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_deflection" */
  subscriber_flow_deflection_stream: Array<subscriber_flow_deflection>;
  /** fetch data from the table: "subscriber_flow_export" */
  subscriber_flow_export: Array<subscriber_flow_export>;
  /** fetch aggregated fields from the table: "subscriber_flow_export" */
  subscriber_flow_export_aggregate: subscriber_flow_export_aggregate;
  /** fetch data from the table: "subscriber_flow_export" using primary key columns */
  subscriber_flow_export_by_pk?: Maybe<subscriber_flow_export>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_export" */
  subscriber_flow_export_stream: Array<subscriber_flow_export>;
  /** fetch data from the table: "subscriber_flow_flow_step" */
  subscriber_flow_flow_step: Array<subscriber_flow_flow_step>;
  /** fetch aggregated fields from the table: "subscriber_flow_flow_step" */
  subscriber_flow_flow_step_aggregate: subscriber_flow_flow_step_aggregate;
  /** fetch data from the table: "subscriber_flow_flow_step" using primary key columns */
  subscriber_flow_flow_step_by_pk?: Maybe<subscriber_flow_flow_step>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_flow_step" */
  subscriber_flow_flow_step_stream: Array<subscriber_flow_flow_step>;
  /** fetch data from the table: "subscriber_flow_offer" */
  subscriber_flow_offer: Array<subscriber_flow_offer>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer" */
  subscriber_flow_offer_aggregate: subscriber_flow_offer_aggregate;
  /** fetch data from the table: "subscriber_flow_offer" using primary key columns */
  subscriber_flow_offer_by_pk?: Maybe<subscriber_flow_offer>;
  /** fetch data from the table: "subscriber_flow_offer_group" */
  subscriber_flow_offer_group: Array<subscriber_flow_offer_group>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_group" */
  subscriber_flow_offer_group_aggregate: subscriber_flow_offer_group_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_group" using primary key columns */
  subscriber_flow_offer_group_by_pk?: Maybe<subscriber_flow_offer_group>;
  /** fetch data from the table: "subscriber_flow_offer_group_offer" */
  subscriber_flow_offer_group_offer: Array<subscriber_flow_offer_group_offer>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_group_offer" */
  subscriber_flow_offer_group_offer_aggregate: subscriber_flow_offer_group_offer_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_group_offer" using primary key columns */
  subscriber_flow_offer_group_offer_by_pk?: Maybe<subscriber_flow_offer_group_offer>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_offer_group_offer" */
  subscriber_flow_offer_group_offer_stream: Array<subscriber_flow_offer_group_offer>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_offer_group" */
  subscriber_flow_offer_group_stream: Array<subscriber_flow_offer_group>;
  /** fetch data from the table: "subscriber_flow_offer_rule" */
  subscriber_flow_offer_rule: Array<subscriber_flow_offer_rule>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_rule" */
  subscriber_flow_offer_rule_aggregate: subscriber_flow_offer_rule_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_rule" using primary key columns */
  subscriber_flow_offer_rule_by_pk?: Maybe<subscriber_flow_offer_rule>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_offer_rule" */
  subscriber_flow_offer_rule_stream: Array<subscriber_flow_offer_rule>;
  /** fetch data from the table: "subscriber_flow_offer_status" */
  subscriber_flow_offer_status: Array<subscriber_flow_offer_status>;
  /** fetch aggregated fields from the table: "subscriber_flow_offer_status" */
  subscriber_flow_offer_status_aggregate: subscriber_flow_offer_status_aggregate;
  /** fetch data from the table: "subscriber_flow_offer_status" using primary key columns */
  subscriber_flow_offer_status_by_pk?: Maybe<subscriber_flow_offer_status>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_offer_status" */
  subscriber_flow_offer_status_stream: Array<subscriber_flow_offer_status>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_offer" */
  subscriber_flow_offer_stream: Array<subscriber_flow_offer>;
  /** fetch data from the table: "subscriber_flow_on_cancel_override" */
  subscriber_flow_on_cancel_override: Array<subscriber_flow_on_cancel_override>;
  /** fetch aggregated fields from the table: "subscriber_flow_on_cancel_override" */
  subscriber_flow_on_cancel_override_aggregate: subscriber_flow_on_cancel_override_aggregate;
  /** fetch data from the table: "subscriber_flow_on_cancel_override" using primary key columns */
  subscriber_flow_on_cancel_override_by_pk?: Maybe<subscriber_flow_on_cancel_override>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_on_cancel_override" */
  subscriber_flow_on_cancel_override_stream: Array<subscriber_flow_on_cancel_override>;
  /** fetch data from the table: "subscriber_flow_override" */
  subscriber_flow_override: Array<subscriber_flow_override>;
  /** fetch aggregated fields from the table: "subscriber_flow_override" */
  subscriber_flow_override_aggregate: subscriber_flow_override_aggregate;
  /** fetch data from the table: "subscriber_flow_override" using primary key columns */
  subscriber_flow_override_by_pk?: Maybe<subscriber_flow_override>;
  /** fetch data from the table: "subscriber_flow_override_reason" */
  subscriber_flow_override_reason: Array<subscriber_flow_override_reason>;
  /** fetch aggregated fields from the table: "subscriber_flow_override_reason" */
  subscriber_flow_override_reason_aggregate: subscriber_flow_override_reason_aggregate;
  /** fetch data from the table: "subscriber_flow_override_reason" using primary key columns */
  subscriber_flow_override_reason_by_pk?: Maybe<subscriber_flow_override_reason>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_override_reason" */
  subscriber_flow_override_reason_stream: Array<subscriber_flow_override_reason>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_override" */
  subscriber_flow_override_stream: Array<subscriber_flow_override>;
  /** fetch data from the table: "subscriber_flow_question_answer" */
  subscriber_flow_question_answer: Array<subscriber_flow_question_answer>;
  /** fetch aggregated fields from the table: "subscriber_flow_question_answer" */
  subscriber_flow_question_answer_aggregate: subscriber_flow_question_answer_aggregate;
  /** fetch data from the table: "subscriber_flow_question_answer" using primary key columns */
  subscriber_flow_question_answer_by_pk?: Maybe<subscriber_flow_question_answer>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_question_answer" */
  subscriber_flow_question_answer_stream: Array<subscriber_flow_question_answer>;
  /** fetch data from the table: "subscriber_flow_segment" */
  subscriber_flow_segment: Array<subscriber_flow_segment>;
  /** fetch aggregated fields from the table: "subscriber_flow_segment" */
  subscriber_flow_segment_aggregate: subscriber_flow_segment_aggregate;
  /** fetch data from the table: "subscriber_flow_segment" using primary key columns */
  subscriber_flow_segment_by_pk?: Maybe<subscriber_flow_segment>;
  /** fetch data from the table: "subscriber_flow_segment_group" */
  subscriber_flow_segment_group: Array<subscriber_flow_segment_group>;
  /** fetch aggregated fields from the table: "subscriber_flow_segment_group" */
  subscriber_flow_segment_group_aggregate: subscriber_flow_segment_group_aggregate;
  /** fetch data from the table: "subscriber_flow_segment_group" using primary key columns */
  subscriber_flow_segment_group_by_pk?: Maybe<subscriber_flow_segment_group>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_segment_group" */
  subscriber_flow_segment_group_stream: Array<subscriber_flow_segment_group>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_segment" */
  subscriber_flow_segment_stream: Array<subscriber_flow_segment>;
  /** fetch data from the table: "subscriber_flow_status" */
  subscriber_flow_status: Array<subscriber_flow_status>;
  /** fetch aggregated fields from the table: "subscriber_flow_status" */
  subscriber_flow_status_aggregate: subscriber_flow_status_aggregate;
  /** fetch data from the table: "subscriber_flow_status_by_month" */
  subscriber_flow_status_by_month: Array<subscriber_flow_status_by_month>;
  /** fetch aggregated fields from the table: "subscriber_flow_status_by_month" */
  subscriber_flow_status_by_month_aggregate: subscriber_flow_status_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_flow_status_by_month" */
  subscriber_flow_status_by_month_stream: Array<subscriber_flow_status_by_month>;
  /** fetch data from the table: "subscriber_flow_status" using primary key columns */
  subscriber_flow_status_by_pk?: Maybe<subscriber_flow_status>;
  /** fetch data from the table in a streaming manner: "subscriber_flow_status" */
  subscriber_flow_status_stream: Array<subscriber_flow_status>;
  /** fetch data from the table in a streaming manner: "subscriber_flow" */
  subscriber_flow_stream: Array<subscriber_flow>;
  /** fetch data from the table: "subscriber_flow_tsvector" */
  subscriber_flow_tsvector: Array<subscriber_flow_tsvector>;
  /** fetch aggregated fields from the table: "subscriber_flow_tsvector" */
  subscriber_flow_tsvector_aggregate: subscriber_flow_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_flow_tsvector" */
  subscriber_flow_tsvector_stream: Array<subscriber_flow_tsvector>;
  /** fetch data from the table: "subscriber_flow_url" */
  subscriber_flow_url: Array<subscriber_flow_url>;
  /** fetch aggregated fields from the table: "subscriber_flow_url" */
  subscriber_flow_url_aggregate: subscriber_flow_url_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_flow_url" */
  subscriber_flow_url_stream: Array<subscriber_flow_url>;
  /** fetch data from the table: "subscriber_flow_version_last_60_days" */
  subscriber_flow_version_last_60_days: Array<subscriber_flow_version_last_60_days>;
  /** fetch aggregated fields from the table: "subscriber_flow_version_last_60_days" */
  subscriber_flow_version_last_60_days_aggregate: subscriber_flow_version_last_60_days_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_flow_version_last_60_days" */
  subscriber_flow_version_last_60_days_stream: Array<subscriber_flow_version_last_60_days>;
  /** fetch data from the table: "subscriber_grid" */
  subscriber_grid: Array<subscriber_grid>;
  /** fetch aggregated fields from the table: "subscriber_grid" */
  subscriber_grid_aggregate: subscriber_grid_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_grid" */
  subscriber_grid_stream: Array<subscriber_grid>;
  /** fetch data from the table: "subscriber_log" */
  subscriber_log: Array<subscriber_log>;
  /** fetch aggregated fields from the table: "subscriber_log" */
  subscriber_log_aggregate: subscriber_log_aggregate;
  /** fetch data from the table: "subscriber_log" using primary key columns */
  subscriber_log_by_pk?: Maybe<subscriber_log>;
  /** fetch data from the table: "subscriber_log_event" */
  subscriber_log_event: Array<subscriber_log_event>;
  /** fetch aggregated fields from the table: "subscriber_log_event" */
  subscriber_log_event_aggregate: subscriber_log_event_aggregate;
  /** fetch data from the table: "subscriber_log_event_by_month" */
  subscriber_log_event_by_month: Array<subscriber_log_event_by_month>;
  /** fetch aggregated fields from the table: "subscriber_log_event_by_month" */
  subscriber_log_event_by_month_aggregate: subscriber_log_event_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_log_event_by_month" */
  subscriber_log_event_by_month_stream: Array<subscriber_log_event_by_month>;
  /** fetch data from the table: "subscriber_log_event" using primary key columns */
  subscriber_log_event_by_pk?: Maybe<subscriber_log_event>;
  /** fetch data from the table in a streaming manner: "subscriber_log_event" */
  subscriber_log_event_stream: Array<subscriber_log_event>;
  /** fetch data from the table: "subscriber_log_offer" */
  subscriber_log_offer: Array<subscriber_log_offer>;
  /** fetch aggregated fields from the table: "subscriber_log_offer" */
  subscriber_log_offer_aggregate: subscriber_log_offer_aggregate;
  /** fetch data from the table: "subscriber_log_offer" using primary key columns */
  subscriber_log_offer_by_pk?: Maybe<subscriber_log_offer>;
  /** fetch data from the table: "subscriber_log_offer_group" */
  subscriber_log_offer_group: Array<subscriber_log_offer_group>;
  /** fetch aggregated fields from the table: "subscriber_log_offer_group" */
  subscriber_log_offer_group_aggregate: subscriber_log_offer_group_aggregate;
  /** fetch data from the table: "subscriber_log_offer_group" using primary key columns */
  subscriber_log_offer_group_by_pk?: Maybe<subscriber_log_offer_group>;
  /** fetch data from the table in a streaming manner: "subscriber_log_offer_group" */
  subscriber_log_offer_group_stream: Array<subscriber_log_offer_group>;
  /** fetch data from the table in a streaming manner: "subscriber_log_offer" */
  subscriber_log_offer_stream: Array<subscriber_log_offer>;
  /** fetch data from the table in a streaming manner: "subscriber_log" */
  subscriber_log_stream: Array<subscriber_log>;
  /** fetch data from the table: "subscriber_ltv" */
  subscriber_ltv: Array<subscriber_ltv>;
  /** fetch aggregated fields from the table: "subscriber_ltv" */
  subscriber_ltv_aggregate: subscriber_ltv_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_ltv" */
  subscriber_ltv_stream: Array<subscriber_ltv>;
  /** fetch data from the table: "subscriber_most_recent_subscriber_flow" */
  subscriber_most_recent_subscriber_flow: Array<subscriber_most_recent_subscriber_flow>;
  /** fetch aggregated fields from the table: "subscriber_most_recent_subscriber_flow" */
  subscriber_most_recent_subscriber_flow_aggregate: subscriber_most_recent_subscriber_flow_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_most_recent_subscriber_flow" */
  subscriber_most_recent_subscriber_flow_stream: Array<subscriber_most_recent_subscriber_flow>;
  /** fetch data from the table: "subscriber_mrr" */
  subscriber_mrr: Array<subscriber_mrr>;
  /** fetch aggregated fields from the table: "subscriber_mrr" */
  subscriber_mrr_aggregate: subscriber_mrr_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_mrr" */
  subscriber_mrr_stream: Array<subscriber_mrr>;
  /** fetch data from the table: "subscriber_property" */
  subscriber_property: Array<subscriber_property>;
  /** fetch aggregated fields from the table: "subscriber_property" */
  subscriber_property_aggregate: subscriber_property_aggregate;
  /** fetch data from the table: "subscriber_property" using primary key columns */
  subscriber_property_by_pk?: Maybe<subscriber_property>;
  /** fetch data from the table in a streaming manner: "subscriber_property" */
  subscriber_property_stream: Array<subscriber_property>;
  /** fetch data from the table: "subscriber_property_value" */
  subscriber_property_value: Array<subscriber_property_value>;
  /** fetch aggregated fields from the table: "subscriber_property_value" */
  subscriber_property_value_aggregate: subscriber_property_value_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_property_value" */
  subscriber_property_value_stream: Array<subscriber_property_value>;
  /** fetch data from the table: "subscriber_status" */
  subscriber_status: Array<subscriber_status>;
  /** fetch aggregated fields from the table: "subscriber_status" */
  subscriber_status_aggregate: subscriber_status_aggregate;
  /** fetch data from the table: "subscriber_status" using primary key columns */
  subscriber_status_by_pk?: Maybe<subscriber_status>;
  /** fetch data from the table in a streaming manner: "subscriber_status" */
  subscriber_status_stream: Array<subscriber_status>;
  /** fetch data from the table in a streaming manner: "subscriber" */
  subscriber_stream: Array<subscriber>;
  /** fetch data from the table: "subscriber_tsvector" */
  subscriber_tsvector: Array<subscriber_tsvector>;
  /** fetch aggregated fields from the table: "subscriber_tsvector" */
  subscriber_tsvector_aggregate: subscriber_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "subscriber_tsvector" */
  subscriber_tsvector_stream: Array<subscriber_tsvector>;
  /** fetch data from the table: "subscription" */
  subscription: Array<subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: subscription_aggregate;
  /** fetch data from the table: "subscription_amount_paid_by_month" */
  subscription_amount_paid_by_month: Array<subscription_amount_paid_by_month>;
  /** fetch aggregated fields from the table: "subscription_amount_paid_by_month" */
  subscription_amount_paid_by_month_aggregate: subscription_amount_paid_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_amount_paid_by_month" */
  subscription_amount_paid_by_month_stream: Array<subscription_amount_paid_by_month>;
  /** fetch data from the table: "subscription_arr" */
  subscription_arr: Array<subscription_arr>;
  /** fetch aggregated fields from the table: "subscription_arr" */
  subscription_arr_aggregate: subscription_arr_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_arr" */
  subscription_arr_stream: Array<subscription_arr>;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<subscription>;
  /** fetch data from the table: "subscription_duration" */
  subscription_duration: Array<subscription_duration>;
  /** fetch aggregated fields from the table: "subscription_duration" */
  subscription_duration_aggregate: subscription_duration_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_duration" */
  subscription_duration_stream: Array<subscription_duration>;
  /** fetch data from the table: "subscription_ltv" */
  subscription_ltv: Array<subscription_ltv>;
  /** fetch aggregated fields from the table: "subscription_ltv" */
  subscription_ltv_aggregate: subscription_ltv_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_ltv" */
  subscription_ltv_stream: Array<subscription_ltv>;
  /** fetch data from the table: "subscription_most_recent_subscriber_flow" */
  subscription_most_recent_subscriber_flow: Array<subscription_most_recent_subscriber_flow>;
  /** fetch aggregated fields from the table: "subscription_most_recent_subscriber_flow" */
  subscription_most_recent_subscriber_flow_aggregate: subscription_most_recent_subscriber_flow_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_most_recent_subscriber_flow" */
  subscription_most_recent_subscriber_flow_stream: Array<subscription_most_recent_subscriber_flow>;
  /** fetch data from the table: "subscription_product" */
  subscription_product: Array<subscription_product>;
  /** fetch aggregated fields from the table: "subscription_product" */
  subscription_product_aggregate: subscription_product_aggregate;
  /** fetch data from the table: "subscription_product" using primary key columns */
  subscription_product_by_pk?: Maybe<subscription_product>;
  /** fetch data from the table in a streaming manner: "subscription_product" */
  subscription_product_stream: Array<subscription_product>;
  /** fetch data from the table: "subscription_property" */
  subscription_property: Array<subscription_property>;
  /** fetch aggregated fields from the table: "subscription_property" */
  subscription_property_aggregate: subscription_property_aggregate;
  /** fetch data from the table: "subscription_property" using primary key columns */
  subscription_property_by_pk?: Maybe<subscription_property>;
  /** fetch data from the table in a streaming manner: "subscription_property" */
  subscription_property_stream: Array<subscription_property>;
  /** fetch data from the table: "subscription_property_value" */
  subscription_property_value: Array<subscription_property_value>;
  /** fetch aggregated fields from the table: "subscription_property_value" */
  subscription_property_value_aggregate: subscription_property_value_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_property_value" */
  subscription_property_value_stream: Array<subscription_property_value>;
  /** fetch data from the table: "subscription_revenue_by_month" */
  subscription_revenue_by_month: Array<subscription_revenue_by_month>;
  /** fetch aggregated fields from the table: "subscription_revenue_by_month" */
  subscription_revenue_by_month_aggregate: subscription_revenue_by_month_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_revenue_by_month" */
  subscription_revenue_by_month_stream: Array<subscription_revenue_by_month>;
  /** fetch data from the table: "subscription_segment" */
  subscription_segment: Array<subscription_segment>;
  /** fetch aggregated fields from the table: "subscription_segment" */
  subscription_segment_aggregate: subscription_segment_aggregate;
  /** fetch data from the table: "subscription_segment" using primary key columns */
  subscription_segment_by_pk?: Maybe<subscription_segment>;
  /** fetch data from the table: "subscription_segment_property" */
  subscription_segment_property: Array<subscription_segment_property>;
  /** fetch aggregated fields from the table: "subscription_segment_property" */
  subscription_segment_property_aggregate: subscription_segment_property_aggregate;
  /** fetch data from the table in a streaming manner: "subscription_segment_property" */
  subscription_segment_property_stream: Array<subscription_segment_property>;
  /** fetch data from the table: "subscription_segment_status" */
  subscription_segment_status: Array<subscription_segment_status>;
  /** fetch aggregated fields from the table: "subscription_segment_status" */
  subscription_segment_status_aggregate: subscription_segment_status_aggregate;
  /** fetch data from the table: "subscription_segment_status" using primary key columns */
  subscription_segment_status_by_pk?: Maybe<subscription_segment_status>;
  /** fetch data from the table in a streaming manner: "subscription_segment_status" */
  subscription_segment_status_stream: Array<subscription_segment_status>;
  /** fetch data from the table in a streaming manner: "subscription_segment" */
  subscription_segment_stream: Array<subscription_segment>;
  /** fetch data from the table: "subscription_status" */
  subscription_status: Array<subscription_status>;
  /** fetch aggregated fields from the table: "subscription_status" */
  subscription_status_aggregate: subscription_status_aggregate;
  /** fetch data from the table: "subscription_status" using primary key columns */
  subscription_status_by_pk?: Maybe<subscription_status>;
  /** fetch data from the table in a streaming manner: "subscription_status" */
  subscription_status_stream: Array<subscription_status>;
  /** fetch data from the table in a streaming manner: "subscription" */
  subscription_stream: Array<subscription>;
  /** fetch data from the table: "survey" */
  survey: Array<survey>;
  /** fetch aggregated fields from the table: "survey" */
  survey_aggregate: survey_aggregate;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<survey>;
  /** fetch data from the table in a streaming manner: "survey" */
  survey_stream: Array<survey>;
  /** fetch data from the table: "tag" */
  tag: Array<tag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: tag_aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<tag>;
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<tag>;
  /** fetch data from the table: "template_report_deflection_trends" */
  template_report_deflection_trends: Array<template_report_deflection_trends>;
  /** fetch aggregated fields from the table: "template_report_deflection_trends" */
  template_report_deflection_trends_aggregate: template_report_deflection_trends_aggregate;
  /** fetch data from the table: "template_report_deflection_trends" using primary key columns */
  template_report_deflection_trends_by_pk?: Maybe<template_report_deflection_trends>;
  /** fetch data from the table in a streaming manner: "template_report_deflection_trends" */
  template_report_deflection_trends_stream: Array<template_report_deflection_trends>;
  /** fetch data from the table: "template_report_feedback_trends" */
  template_report_feedback_trends: Array<template_report_feedback_trends>;
  /** fetch aggregated fields from the table: "template_report_feedback_trends" */
  template_report_feedback_trends_aggregate: template_report_feedback_trends_aggregate;
  /** fetch data from the table: "template_report_feedback_trends" using primary key columns */
  template_report_feedback_trends_by_pk?: Maybe<template_report_feedback_trends>;
  /** fetch data from the table in a streaming manner: "template_report_feedback_trends" */
  template_report_feedback_trends_stream: Array<template_report_feedback_trends>;
  /** fetch data from the table: "template_report_offer_accept_rate" */
  template_report_offer_accept_rate: Array<template_report_offer_accept_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_accept_rate" */
  template_report_offer_accept_rate_aggregate: template_report_offer_accept_rate_aggregate;
  /** fetch data from the table: "template_report_offer_accept_rate" using primary key columns */
  template_report_offer_accept_rate_by_pk?: Maybe<template_report_offer_accept_rate>;
  /** fetch data from the table in a streaming manner: "template_report_offer_accept_rate" */
  template_report_offer_accept_rate_stream: Array<template_report_offer_accept_rate>;
  /** fetch data from the table: "template_report_offer_group_accept_rate" */
  template_report_offer_group_accept_rate: Array<template_report_offer_group_accept_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_group_accept_rate" */
  template_report_offer_group_accept_rate_aggregate: template_report_offer_group_accept_rate_aggregate;
  /** fetch data from the table: "template_report_offer_group_accept_rate" using primary key columns */
  template_report_offer_group_accept_rate_by_pk?: Maybe<template_report_offer_group_accept_rate>;
  /** fetch data from the table in a streaming manner: "template_report_offer_group_accept_rate" */
  template_report_offer_group_accept_rate_stream: Array<template_report_offer_group_accept_rate>;
  /** fetch data from the table: "template_report_offer_group_retained_revenue" */
  template_report_offer_group_retained_revenue: Array<template_report_offer_group_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_offer_group_retained_revenue" */
  template_report_offer_group_retained_revenue_aggregate: template_report_offer_group_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_offer_group_retained_revenue" using primary key columns */
  template_report_offer_group_retained_revenue_by_pk?: Maybe<template_report_offer_group_retained_revenue>;
  /** fetch data from the table in a streaming manner: "template_report_offer_group_retained_revenue" */
  template_report_offer_group_retained_revenue_stream: Array<template_report_offer_group_retained_revenue>;
  /** fetch data from the table: "template_report_offer_rate" */
  template_report_offer_rate: Array<template_report_offer_rate>;
  /** fetch aggregated fields from the table: "template_report_offer_rate" */
  template_report_offer_rate_aggregate: template_report_offer_rate_aggregate;
  /** fetch data from the table: "template_report_offer_rate" using primary key columns */
  template_report_offer_rate_by_pk?: Maybe<template_report_offer_rate>;
  /** fetch data from the table in a streaming manner: "template_report_offer_rate" */
  template_report_offer_rate_stream: Array<template_report_offer_rate>;
  /** fetch data from the table: "template_report_offer_resolution" */
  template_report_offer_resolution: Array<template_report_offer_resolution>;
  /** fetch aggregated fields from the table: "template_report_offer_resolution" */
  template_report_offer_resolution_aggregate: template_report_offer_resolution_aggregate;
  /** fetch data from the table: "template_report_offer_resolution" using primary key columns */
  template_report_offer_resolution_by_pk?: Maybe<template_report_offer_resolution>;
  /** fetch data from the table in a streaming manner: "template_report_offer_resolution" */
  template_report_offer_resolution_stream: Array<template_report_offer_resolution>;
  /** fetch data from the table: "template_report_offer_retained_revenue" */
  template_report_offer_retained_revenue: Array<template_report_offer_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_offer_retained_revenue" */
  template_report_offer_retained_revenue_aggregate: template_report_offer_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_offer_retained_revenue" using primary key columns */
  template_report_offer_retained_revenue_by_pk?: Maybe<template_report_offer_retained_revenue>;
  /** fetch data from the table in a streaming manner: "template_report_offer_retained_revenue" */
  template_report_offer_retained_revenue_stream: Array<template_report_offer_retained_revenue>;
  /** fetch data from the table: "template_report_question_answer_radio" */
  template_report_question_answer_radio: Array<template_report_question_answer_radio>;
  /** fetch aggregated fields from the table: "template_report_question_answer_radio" */
  template_report_question_answer_radio_aggregate: template_report_question_answer_radio_aggregate;
  /** fetch data from the table: "template_report_question_answer_radio" using primary key columns */
  template_report_question_answer_radio_by_pk?: Maybe<template_report_question_answer_radio>;
  /** fetch data from the table: "template_report_question_answer_radio_inactive_mrr" */
  template_report_question_answer_radio_inactive_mrr: Array<template_report_question_answer_radio_inactive_mrr>;
  /** fetch aggregated fields from the table: "template_report_question_answer_radio_inactive_mrr" */
  template_report_question_answer_radio_inactive_mrr_aggregate: template_report_question_answer_radio_inactive_mrr_aggregate;
  /** fetch data from the table: "template_report_question_answer_radio_inactive_mrr" using primary key columns */
  template_report_question_answer_radio_inactive_mrr_by_pk?: Maybe<template_report_question_answer_radio_inactive_mrr>;
  /** fetch data from the table in a streaming manner: "template_report_question_answer_radio_inactive_mrr" */
  template_report_question_answer_radio_inactive_mrr_stream: Array<template_report_question_answer_radio_inactive_mrr>;
  /** fetch data from the table in a streaming manner: "template_report_question_answer_radio" */
  template_report_question_answer_radio_stream: Array<template_report_question_answer_radio>;
  /** fetch data from the table: "template_report_question_answer_sentiment" */
  template_report_question_answer_sentiment: Array<template_report_question_answer_sentiment>;
  /** fetch aggregated fields from the table: "template_report_question_answer_sentiment" */
  template_report_question_answer_sentiment_aggregate: template_report_question_answer_sentiment_aggregate;
  /** fetch data from the table: "template_report_question_answer_sentiment" using primary key columns */
  template_report_question_answer_sentiment_by_pk?: Maybe<template_report_question_answer_sentiment>;
  /** fetch data from the table in a streaming manner: "template_report_question_answer_sentiment" */
  template_report_question_answer_sentiment_stream: Array<template_report_question_answer_sentiment>;
  /** fetch data from the table: "template_report_retained_revenue" */
  template_report_retained_revenue: Array<template_report_retained_revenue>;
  /** fetch aggregated fields from the table: "template_report_retained_revenue" */
  template_report_retained_revenue_aggregate: template_report_retained_revenue_aggregate;
  /** fetch data from the table: "template_report_retained_revenue" using primary key columns */
  template_report_retained_revenue_by_pk?: Maybe<template_report_retained_revenue>;
  /** fetch data from the table in a streaming manner: "template_report_retained_revenue" */
  template_report_retained_revenue_stream: Array<template_report_retained_revenue>;
  /** fetch data from the table: "template_report_retention_trends" */
  template_report_retention_trends: Array<template_report_retention_trends>;
  /** fetch aggregated fields from the table: "template_report_retention_trends" */
  template_report_retention_trends_aggregate: template_report_retention_trends_aggregate;
  /** fetch data from the table: "template_report_retention_trends" using primary key columns */
  template_report_retention_trends_by_pk?: Maybe<template_report_retention_trends>;
  /** fetch data from the table in a streaming manner: "template_report_retention_trends" */
  template_report_retention_trends_stream: Array<template_report_retention_trends>;
  /** fetch data from the table: "template_report_subscriber_flow_status" */
  template_report_subscriber_flow_status: Array<template_report_subscriber_flow_status>;
  /** fetch aggregated fields from the table: "template_report_subscriber_flow_status" */
  template_report_subscriber_flow_status_aggregate: template_report_subscriber_flow_status_aggregate;
  /** fetch data from the table: "template_report_subscriber_flow_status" using primary key columns */
  template_report_subscriber_flow_status_by_pk?: Maybe<template_report_subscriber_flow_status>;
  /** fetch data from the table in a streaming manner: "template_report_subscriber_flow_status" */
  template_report_subscriber_flow_status_stream: Array<template_report_subscriber_flow_status>;
  /** fetch data from the table: "translation" */
  translation: Array<translation>;
  /** fetch aggregated fields from the table: "translation" */
  translation_aggregate: translation_aggregate;
  /** fetch data from the table: "translation" using primary key columns */
  translation_by_pk?: Maybe<translation>;
  /** fetch data from the table in a streaming manner: "translation" */
  translation_stream: Array<translation>;
  /** fetch data from the table: "translation_value" */
  translation_value: Array<translation_value>;
  /** fetch aggregated fields from the table: "translation_value" */
  translation_value_aggregate: translation_value_aggregate;
  /** fetch data from the table: "translation_value" using primary key columns */
  translation_value_by_pk?: Maybe<translation_value>;
  /** fetch data from the table: "translation_value_format" */
  translation_value_format: Array<translation_value_format>;
  /** fetch aggregated fields from the table: "translation_value_format" */
  translation_value_format_aggregate: translation_value_format_aggregate;
  /** fetch data from the table: "translation_value_format" using primary key columns */
  translation_value_format_by_pk?: Maybe<translation_value_format>;
  /** fetch data from the table in a streaming manner: "translation_value_format" */
  translation_value_format_stream: Array<translation_value_format>;
  /** fetch data from the table in a streaming manner: "translation_value" */
  translation_value_stream: Array<translation_value>;
  /** fetch data from the table: "user" */
  user: Array<user>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: user_aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<user>;
  /** fetch data from the table: "user_invitation" */
  user_invitation: Array<user_invitation>;
  /** fetch aggregated fields from the table: "user_invitation" */
  user_invitation_aggregate: user_invitation_aggregate;
  /** fetch data from the table: "user_invitation" using primary key columns */
  user_invitation_by_pk?: Maybe<user_invitation>;
  /** fetch data from the table in a streaming manner: "user_invitation" */
  user_invitation_stream: Array<user_invitation>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<user_profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: user_profile_aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<user_profile>;
  /** fetch data from the table in a streaming manner: "user_profile" */
  user_profile_stream: Array<user_profile>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<user>;
  /** fetch data from the table: "webhook" */
  webhook: Array<webhook>;
  /** fetch aggregated fields from the table: "webhook" */
  webhook_aggregate: webhook_aggregate;
  /** fetch data from the table: "webhook" using primary key columns */
  webhook_by_pk?: Maybe<webhook>;
  /** fetch data from the table: "webhook_event" */
  webhook_event: Array<webhook_event>;
  /** fetch aggregated fields from the table: "webhook_event" */
  webhook_event_aggregate: webhook_event_aggregate;
  /** fetch data from the table: "webhook_event" using primary key columns */
  webhook_event_by_pk?: Maybe<webhook_event>;
  /** fetch data from the table in a streaming manner: "webhook_event" */
  webhook_event_stream: Array<webhook_event>;
  /** fetch data from the table: "webhook_log" */
  webhook_log: Array<webhook_log>;
  /** fetch aggregated fields from the table: "webhook_log" */
  webhook_log_aggregate: webhook_log_aggregate;
  /** fetch data from the table: "webhook_log" using primary key columns */
  webhook_log_by_pk?: Maybe<webhook_log>;
  /** fetch data from the table in a streaming manner: "webhook_log" */
  webhook_log_stream: Array<webhook_log>;
  /** fetch data from the table: "webhook_request_log" */
  webhook_request_log: Array<webhook_request_log>;
  /** fetch aggregated fields from the table: "webhook_request_log" */
  webhook_request_log_aggregate: webhook_request_log_aggregate;
  /** fetch data from the table: "webhook_request_log" using primary key columns */
  webhook_request_log_by_pk?: Maybe<webhook_request_log>;
  /** fetch data from the table in a streaming manner: "webhook_request_log" */
  webhook_request_log_stream: Array<webhook_request_log>;
  /** fetch data from the table: "webhook_request_log_tsvector" */
  webhook_request_log_tsvector: Array<webhook_request_log_tsvector>;
  /** fetch aggregated fields from the table: "webhook_request_log_tsvector" */
  webhook_request_log_tsvector_aggregate: webhook_request_log_tsvector_aggregate;
  /** fetch data from the table in a streaming manner: "webhook_request_log_tsvector" */
  webhook_request_log_tsvector_stream: Array<webhook_request_log_tsvector>;
  /** fetch data from the table: "webhook_status" */
  webhook_status: Array<webhook_status>;
  /** fetch aggregated fields from the table: "webhook_status" */
  webhook_status_aggregate: webhook_status_aggregate;
  /** fetch data from the table: "webhook_status" using primary key columns */
  webhook_status_by_pk?: Maybe<webhook_status>;
  /** fetch data from the table in a streaming manner: "webhook_status" */
  webhook_status_stream: Array<webhook_status>;
  /** fetch data from the table in a streaming manner: "webhook" */
  webhook_stream: Array<webhook>;
};


export type subscription_rootaccountArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type subscription_rootaccount_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type subscription_rootaccount_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootaccount_featureArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


export type subscription_rootaccount_feature_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_order_by>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


export type subscription_rootaccount_feature_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_feature_key_enum;
};


export type subscription_rootaccount_feature_keyArgs = {
  distinct_on?: InputMaybe<Array<account_feature_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_key_order_by>>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};


export type subscription_rootaccount_feature_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_feature_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_feature_key_order_by>>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};


export type subscription_rootaccount_feature_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_feature_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_feature_key_stream_cursor_input>>;
  where?: InputMaybe<account_feature_key_bool_exp>;
};


export type subscription_rootaccount_feature_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_feature_stream_cursor_input>>;
  where?: InputMaybe<account_feature_bool_exp>;
};


export type subscription_rootaccount_modeArgs = {
  distinct_on?: InputMaybe<Array<account_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_mode_order_by>>;
  where?: InputMaybe<account_mode_bool_exp>;
};


export type subscription_rootaccount_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_mode_order_by>>;
  where?: InputMaybe<account_mode_bool_exp>;
};


export type subscription_rootaccount_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_mode_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_mode_stream_cursor_input>>;
  where?: InputMaybe<account_mode_bool_exp>;
};


export type subscription_rootaccount_revenue_modeArgs = {
  distinct_on?: InputMaybe<Array<account_revenue_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_revenue_mode_order_by>>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};


export type subscription_rootaccount_revenue_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_revenue_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_revenue_mode_order_by>>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};


export type subscription_rootaccount_revenue_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_revenue_mode_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_revenue_mode_stream_cursor_input>>;
  where?: InputMaybe<account_revenue_mode_bool_exp>;
};


export type subscription_rootaccount_settingArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


export type subscription_rootaccount_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_order_by>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


export type subscription_rootaccount_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_setting_key_enum;
};


export type subscription_rootaccount_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<account_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_key_order_by>>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};


export type subscription_rootaccount_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_setting_key_order_by>>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};


export type subscription_rootaccount_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_setting_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_setting_key_stream_cursor_input>>;
  where?: InputMaybe<account_setting_key_bool_exp>;
};


export type subscription_rootaccount_setting_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_setting_stream_cursor_input>>;
  where?: InputMaybe<account_setting_bool_exp>;
};


export type subscription_rootaccount_statusArgs = {
  distinct_on?: InputMaybe<Array<account_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_status_order_by>>;
  where?: InputMaybe<account_status_bool_exp>;
};


export type subscription_rootaccount_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_status_order_by>>;
  where?: InputMaybe<account_status_bool_exp>;
};


export type subscription_rootaccount_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_status_stream_cursor_input>>;
  where?: InputMaybe<account_status_bool_exp>;
};


export type subscription_rootaccount_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_stream_cursor_input>>;
  where?: InputMaybe<account_bool_exp>;
};


export type subscription_rootaccount_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<account_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_tsvector_order_by>>;
  where?: InputMaybe<account_tsvector_bool_exp>;
};


export type subscription_rootaccount_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_tsvector_order_by>>;
  where?: InputMaybe<account_tsvector_bool_exp>;
};


export type subscription_rootaccount_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_tsvector_stream_cursor_input>>;
  where?: InputMaybe<account_tsvector_bool_exp>;
};


export type subscription_rootaccount_userArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


export type subscription_rootaccount_user_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


export type subscription_rootaccount_user_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  user_id: Scalars['String']['input'];
};


export type subscription_rootaccount_user_settingArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


export type subscription_rootaccount_user_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


export type subscription_rootaccount_user_setting_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  key: account_user_setting_key_enum;
  user_id: Scalars['String']['input'];
};


export type subscription_rootaccount_user_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_key_order_by>>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};


export type subscription_rootaccount_user_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_key_order_by>>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};


export type subscription_rootaccount_user_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootaccount_user_setting_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_user_setting_key_stream_cursor_input>>;
  where?: InputMaybe<account_user_setting_key_bool_exp>;
};


export type subscription_rootaccount_user_setting_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_user_setting_stream_cursor_input>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


export type subscription_rootaccount_user_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<account_user_stream_cursor_input>>;
  where?: InputMaybe<account_user_bool_exp>;
};


export type subscription_rootacknowledgementArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootacknowledgement_groupArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_order_by>>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};


export type subscription_rootacknowledgement_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_order_by>>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};


export type subscription_rootacknowledgement_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootacknowledgement_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<acknowledgement_group_stream_cursor_input>>;
  where?: InputMaybe<acknowledgement_group_bool_exp>;
};


export type subscription_rootacknowledgement_group_versionArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};


export type subscription_rootacknowledgement_group_version_acknowledgementArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_group_version_acknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_acknowledgement_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_group_version_acknowledgement_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<acknowledgement_group_version_acknowledgement_stream_cursor_input>>;
  where?: InputMaybe<acknowledgement_group_version_acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_group_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_group_version_order_by>>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};


export type subscription_rootacknowledgement_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootacknowledgement_group_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<acknowledgement_group_version_stream_cursor_input>>;
  where?: InputMaybe<acknowledgement_group_version_bool_exp>;
};


export type subscription_rootacknowledgement_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<acknowledgement_stream_cursor_input>>;
  where?: InputMaybe<acknowledgement_bool_exp>;
};


export type subscription_rootacknowledgement_versionArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


export type subscription_rootacknowledgement_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<acknowledgement_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<acknowledgement_version_order_by>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


export type subscription_rootacknowledgement_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootacknowledgement_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<acknowledgement_version_stream_cursor_input>>;
  where?: InputMaybe<acknowledgement_version_bool_exp>;
};


export type subscription_rootactive_portal_sessionArgs = {
  distinct_on?: InputMaybe<Array<active_portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_portal_session_order_by>>;
  where?: InputMaybe<active_portal_session_bool_exp>;
};


export type subscription_rootactive_portal_session_aggregateArgs = {
  distinct_on?: InputMaybe<Array<active_portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_portal_session_order_by>>;
  where?: InputMaybe<active_portal_session_bool_exp>;
};


export type subscription_rootactive_portal_session_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<active_portal_session_stream_cursor_input>>;
  where?: InputMaybe<active_portal_session_bool_exp>;
};


export type subscription_rootactive_saved_mrr_by_monthArgs = {
  distinct_on?: InputMaybe<Array<active_saved_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_saved_mrr_by_month_order_by>>;
  where?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
};


export type subscription_rootactive_saved_mrr_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<active_saved_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<active_saved_mrr_by_month_order_by>>;
  where?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
};


export type subscription_rootactive_saved_mrr_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<active_saved_mrr_by_month_stream_cursor_input>>;
  where?: InputMaybe<active_saved_mrr_by_month_bool_exp>;
};


export type subscription_rootannotationArgs = {
  distinct_on?: InputMaybe<Array<annotation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_order_by>>;
  where?: InputMaybe<annotation_bool_exp>;
};


export type subscription_rootannotation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_order_by>>;
  where?: InputMaybe<annotation_bool_exp>;
};


export type subscription_rootannotation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootannotation_flow_versionArgs = {
  distinct_on?: InputMaybe<Array<annotation_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_flow_version_order_by>>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};


export type subscription_rootannotation_flow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_flow_version_order_by>>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};


export type subscription_rootannotation_flow_version_by_pkArgs = {
  annotation_id: Scalars['Int']['input'];
  flow_version_id: Scalars['Int']['input'];
};


export type subscription_rootannotation_flow_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<annotation_flow_version_stream_cursor_input>>;
  where?: InputMaybe<annotation_flow_version_bool_exp>;
};


export type subscription_rootannotation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<annotation_stream_cursor_input>>;
  where?: InputMaybe<annotation_bool_exp>;
};


export type subscription_rootannotation_typeArgs = {
  distinct_on?: InputMaybe<Array<annotation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_type_order_by>>;
  where?: InputMaybe<annotation_type_bool_exp>;
};


export type subscription_rootannotation_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<annotation_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<annotation_type_order_by>>;
  where?: InputMaybe<annotation_type_bool_exp>;
};


export type subscription_rootannotation_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootannotation_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<annotation_type_stream_cursor_input>>;
  where?: InputMaybe<annotation_type_bool_exp>;
};


export type subscription_rootapi_keyArgs = {
  distinct_on?: InputMaybe<Array<api_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_order_by>>;
  where?: InputMaybe<api_key_bool_exp>;
};


export type subscription_rootapi_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_order_by>>;
  where?: InputMaybe<api_key_bool_exp>;
};


export type subscription_rootapi_key_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootapi_key_purposeArgs = {
  distinct_on?: InputMaybe<Array<api_key_purpose_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_purpose_order_by>>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};


export type subscription_rootapi_key_purpose_aggregateArgs = {
  distinct_on?: InputMaybe<Array<api_key_purpose_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<api_key_purpose_order_by>>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};


export type subscription_rootapi_key_purpose_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootapi_key_purpose_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<api_key_purpose_stream_cursor_input>>;
  where?: InputMaybe<api_key_purpose_bool_exp>;
};


export type subscription_rootapi_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<api_key_stream_cursor_input>>;
  where?: InputMaybe<api_key_bool_exp>;
};


export type subscription_rootcampaignArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


export type subscription_rootcampaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_order_by>>;
  where?: InputMaybe<campaign_bool_exp>;
};


export type subscription_rootcampaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootcampaign_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<campaign_stream_cursor_input>>;
  where?: InputMaybe<campaign_bool_exp>;
};


export type subscription_rootcampaign_textArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


export type subscription_rootcampaign_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_order_by>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


export type subscription_rootcampaign_text_by_pkArgs = {
  campaign_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


export type subscription_rootcampaign_text_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<campaign_text_stream_cursor_input>>;
  where?: InputMaybe<campaign_text_bool_exp>;
};


export type subscription_rootcampaign_text_versionArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_version_order_by>>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};


export type subscription_rootcampaign_text_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_text_version_order_by>>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};


export type subscription_rootcampaign_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootcampaign_text_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<campaign_text_version_stream_cursor_input>>;
  where?: InputMaybe<campaign_text_version_bool_exp>;
};


export type subscription_rootcampaign_trigger_typeArgs = {
  distinct_on?: InputMaybe<Array<campaign_trigger_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_trigger_type_order_by>>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};


export type subscription_rootcampaign_trigger_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_trigger_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_trigger_type_order_by>>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};


export type subscription_rootcampaign_trigger_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootcampaign_trigger_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<campaign_trigger_type_stream_cursor_input>>;
  where?: InputMaybe<campaign_trigger_type_bool_exp>;
};


export type subscription_rootcampaign_versionArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


export type subscription_rootcampaign_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<campaign_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<campaign_version_order_by>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


export type subscription_rootcampaign_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootcampaign_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<campaign_version_stream_cursor_input>>;
  where?: InputMaybe<campaign_version_bool_exp>;
};


export type subscription_rootcancellations_by_monthArgs = {
  distinct_on?: InputMaybe<Array<cancellations_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<cancellations_by_month_order_by>>;
  where?: InputMaybe<cancellations_by_month_bool_exp>;
};


export type subscription_rootcancellations_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<cancellations_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<cancellations_by_month_order_by>>;
  where?: InputMaybe<cancellations_by_month_bool_exp>;
};


export type subscription_rootcancellations_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<cancellations_by_month_stream_cursor_input>>;
  where?: InputMaybe<cancellations_by_month_bool_exp>;
};


export type subscription_rootconfirmationArgs = {
  distinct_on?: InputMaybe<Array<confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_order_by>>;
  where?: InputMaybe<confirmation_bool_exp>;
};


export type subscription_rootconfirmation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_order_by>>;
  where?: InputMaybe<confirmation_bool_exp>;
};


export type subscription_rootconfirmation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootconfirmation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<confirmation_stream_cursor_input>>;
  where?: InputMaybe<confirmation_bool_exp>;
};


export type subscription_rootconfirmation_versionArgs = {
  distinct_on?: InputMaybe<Array<confirmation_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_version_order_by>>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};


export type subscription_rootconfirmation_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<confirmation_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<confirmation_version_order_by>>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};


export type subscription_rootconfirmation_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootconfirmation_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<confirmation_version_stream_cursor_input>>;
  where?: InputMaybe<confirmation_version_bool_exp>;
};


export type subscription_rootcountryArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};


export type subscription_rootcountry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<country_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<country_order_by>>;
  where?: InputMaybe<country_bool_exp>;
};


export type subscription_rootcountry_by_pkArgs = {
  iso: Scalars['citext']['input'];
};


export type subscription_rootcountry_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<country_stream_cursor_input>>;
  where?: InputMaybe<country_bool_exp>;
};


export type subscription_rootcoupon_durationArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_order_by>>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};


export type subscription_rootcoupon_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_order_by>>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};


export type subscription_rootcoupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootcoupon_duration_intervalArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_interval_order_by>>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};


export type subscription_rootcoupon_duration_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<coupon_duration_interval_order_by>>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};


export type subscription_rootcoupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootcoupon_duration_interval_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<coupon_duration_interval_stream_cursor_input>>;
  where?: InputMaybe<coupon_duration_interval_bool_exp>;
};


export type subscription_rootcoupon_duration_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<coupon_duration_stream_cursor_input>>;
  where?: InputMaybe<coupon_duration_bool_exp>;
};


export type subscription_rootcsv_delta_fileArgs = {
  distinct_on?: InputMaybe<Array<csv_delta_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_delta_file_order_by>>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};


export type subscription_rootcsv_delta_file_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_delta_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_delta_file_order_by>>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};


export type subscription_rootcsv_delta_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootcsv_delta_file_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<csv_delta_file_stream_cursor_input>>;
  where?: InputMaybe<csv_delta_file_bool_exp>;
};


export type subscription_rootcsv_importArgs = {
  distinct_on?: InputMaybe<Array<csv_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_order_by>>;
  where?: InputMaybe<csv_import_bool_exp>;
};


export type subscription_rootcsv_import_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_order_by>>;
  where?: InputMaybe<csv_import_bool_exp>;
};


export type subscription_rootcsv_import_by_pkArgs = {
  delta_file_id: Scalars['Int']['input'];
};


export type subscription_rootcsv_import_fileArgs = {
  distinct_on?: InputMaybe<Array<csv_import_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_file_order_by>>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};


export type subscription_rootcsv_import_file_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_file_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_file_order_by>>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};


export type subscription_rootcsv_import_file_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootcsv_import_file_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<csv_import_file_stream_cursor_input>>;
  where?: InputMaybe<csv_import_file_bool_exp>;
};


export type subscription_rootcsv_import_statusArgs = {
  distinct_on?: InputMaybe<Array<csv_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_status_order_by>>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};


export type subscription_rootcsv_import_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<csv_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<csv_import_status_order_by>>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};


export type subscription_rootcsv_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootcsv_import_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<csv_import_status_stream_cursor_input>>;
  where?: InputMaybe<csv_import_status_bool_exp>;
};


export type subscription_rootcsv_import_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<csv_import_stream_cursor_input>>;
  where?: InputMaybe<csv_import_bool_exp>;
};


export type subscription_rootcustomers_by_monthArgs = {
  distinct_on?: InputMaybe<Array<customers_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<customers_by_month_order_by>>;
  where?: InputMaybe<customers_by_month_bool_exp>;
};


export type subscription_rootcustomers_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<customers_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<customers_by_month_order_by>>;
  where?: InputMaybe<customers_by_month_bool_exp>;
};


export type subscription_rootcustomers_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<customers_by_month_stream_cursor_input>>;
  where?: InputMaybe<customers_by_month_bool_exp>;
};


export type subscription_rootdata_connectorArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


export type subscription_rootdata_connector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_order_by>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


export type subscription_rootdata_connector_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootdata_connector_request_logArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type subscription_rootdata_connector_request_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type subscription_rootdata_connector_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type subscription_rootdata_connector_request_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<data_connector_request_log_stream_cursor_input>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type subscription_rootdata_connector_request_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_tsvector_order_by>>;
  where?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
};


export type subscription_rootdata_connector_request_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<data_connector_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_tsvector_order_by>>;
  where?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
};


export type subscription_rootdata_connector_request_log_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<data_connector_request_log_tsvector_stream_cursor_input>>;
  where?: InputMaybe<data_connector_request_log_tsvector_bool_exp>;
};


export type subscription_rootdata_connector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<data_connector_stream_cursor_input>>;
  where?: InputMaybe<data_connector_bool_exp>;
};


export type subscription_rootdeflectionArgs = {
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type subscription_rootdeflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type subscription_rootdeflection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootdeflection_flow_actionArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


export type subscription_rootdeflection_flow_action_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_flow_action_order_by>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


export type subscription_rootdeflection_flow_action_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_flow_action_stream_cursor_input>>;
  where?: InputMaybe<deflection_flow_action_bool_exp>;
};


export type subscription_rootdeflection_snapshot_itemArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};


export type subscription_rootdeflection_snapshot_item_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};


export type subscription_rootdeflection_snapshot_item_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootdeflection_snapshot_item_positionArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


export type subscription_rootdeflection_snapshot_item_position_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_snapshot_item_position_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_snapshot_item_position_order_by>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


export type subscription_rootdeflection_snapshot_item_position_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_snapshot_item_position_stream_cursor_input>>;
  where?: InputMaybe<deflection_snapshot_item_position_bool_exp>;
};


export type subscription_rootdeflection_snapshot_item_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_snapshot_item_stream_cursor_input>>;
  where?: InputMaybe<deflection_snapshot_item_bool_exp>;
};


export type subscription_rootdeflection_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_stream_cursor_input>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type subscription_rootdeflection_tagArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


export type subscription_rootdeflection_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tag_order_by>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


export type subscription_rootdeflection_tag_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_tag_stream_cursor_input>>;
  where?: InputMaybe<deflection_tag_bool_exp>;
};


export type subscription_rootdeflection_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<deflection_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tsvector_order_by>>;
  where?: InputMaybe<deflection_tsvector_bool_exp>;
};


export type subscription_rootdeflection_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_tsvector_order_by>>;
  where?: InputMaybe<deflection_tsvector_bool_exp>;
};


export type subscription_rootdeflection_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_tsvector_stream_cursor_input>>;
  where?: InputMaybe<deflection_tsvector_bool_exp>;
};


export type subscription_rootdeflection_typeArgs = {
  distinct_on?: InputMaybe<Array<deflection_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_type_order_by>>;
  where?: InputMaybe<deflection_type_bool_exp>;
};


export type subscription_rootdeflection_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<deflection_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_type_order_by>>;
  where?: InputMaybe<deflection_type_bool_exp>;
};


export type subscription_rootdeflection_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootdeflection_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<deflection_type_stream_cursor_input>>;
  where?: InputMaybe<deflection_type_bool_exp>;
};


export type subscription_rootdemoArgs = {
  distinct_on?: InputMaybe<Array<demo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<demo_order_by>>;
  where?: InputMaybe<demo_bool_exp>;
};


export type subscription_rootdemo_aggregateArgs = {
  distinct_on?: InputMaybe<Array<demo_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<demo_order_by>>;
  where?: InputMaybe<demo_bool_exp>;
};


export type subscription_rootdemo_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootdemo_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<demo_stream_cursor_input>>;
  where?: InputMaybe<demo_bool_exp>;
};


export type subscription_rootdomainArgs = {
  distinct_on?: InputMaybe<Array<domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_order_by>>;
  where?: InputMaybe<domain_bool_exp>;
};


export type subscription_rootdomain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_order_by>>;
  where?: InputMaybe<domain_bool_exp>;
};


export type subscription_rootdomain_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootdomain_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<domain_stream_cursor_input>>;
  where?: InputMaybe<domain_bool_exp>;
};


export type subscription_rootdomain_typeArgs = {
  distinct_on?: InputMaybe<Array<domain_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_type_order_by>>;
  where?: InputMaybe<domain_type_bool_exp>;
};


export type subscription_rootdomain_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<domain_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<domain_type_order_by>>;
  where?: InputMaybe<domain_type_bool_exp>;
};


export type subscription_rootdomain_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootdomain_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<domain_type_stream_cursor_input>>;
  where?: InputMaybe<domain_type_bool_exp>;
};


export type subscription_rooteligibility_messageArgs = {
  distinct_on?: InputMaybe<Array<eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<eligibility_message_order_by>>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};


export type subscription_rooteligibility_message_aggregateArgs = {
  distinct_on?: InputMaybe<Array<eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<eligibility_message_order_by>>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};


export type subscription_rooteligibility_message_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rooteligibility_message_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<eligibility_message_stream_cursor_input>>;
  where?: InputMaybe<eligibility_message_bool_exp>;
};


export type subscription_rooteventArgs = {
  distinct_on?: InputMaybe<Array<event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_order_by>>;
  where?: InputMaybe<event_bool_exp>;
};


export type subscription_rootevent_aggregateArgs = {
  distinct_on?: InputMaybe<Array<event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_order_by>>;
  where?: InputMaybe<event_bool_exp>;
};


export type subscription_rootevent_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootevent_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<event_stream_cursor_input>>;
  where?: InputMaybe<event_bool_exp>;
};


export type subscription_rootevent_typeArgs = {
  distinct_on?: InputMaybe<Array<event_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_type_order_by>>;
  where?: InputMaybe<event_type_bool_exp>;
};


export type subscription_rootevent_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<event_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<event_type_order_by>>;
  where?: InputMaybe<event_type_bool_exp>;
};


export type subscription_rootevent_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootevent_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<event_type_stream_cursor_input>>;
  where?: InputMaybe<event_type_bool_exp>;
};


export type subscription_rootfeedback_trends_gridArgs = {
  distinct_on?: InputMaybe<Array<feedback_trends_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<feedback_trends_grid_order_by>>;
  where?: InputMaybe<feedback_trends_grid_bool_exp>;
};


export type subscription_rootfeedback_trends_grid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<feedback_trends_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<feedback_trends_grid_order_by>>;
  where?: InputMaybe<feedback_trends_grid_bool_exp>;
};


export type subscription_rootfeedback_trends_grid_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<feedback_trends_grid_stream_cursor_input>>;
  where?: InputMaybe<feedback_trends_grid_bool_exp>;
};


export type subscription_rootflowArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


export type subscription_rootflow_actionArgs = {
  distinct_on?: InputMaybe<Array<flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_order_by>>;
  where?: InputMaybe<flow_action_bool_exp>;
};


export type subscription_rootflow_action_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_order_by>>;
  where?: InputMaybe<flow_action_bool_exp>;
};


export type subscription_rootflow_action_appearanceArgs = {
  distinct_on?: InputMaybe<Array<flow_action_appearance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_appearance_order_by>>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};


export type subscription_rootflow_action_appearance_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_appearance_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_appearance_order_by>>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};


export type subscription_rootflow_action_appearance_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_action_appearance_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_action_appearance_stream_cursor_input>>;
  where?: InputMaybe<flow_action_appearance_bool_exp>;
};


export type subscription_rootflow_action_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_action_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_action_stream_cursor_input>>;
  where?: InputMaybe<flow_action_bool_exp>;
};


export type subscription_rootflow_action_typeArgs = {
  distinct_on?: InputMaybe<Array<flow_action_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_type_order_by>>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};


export type subscription_rootflow_action_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_action_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_action_type_order_by>>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};


export type subscription_rootflow_action_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_action_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_action_type_stream_cursor_input>>;
  where?: InputMaybe<flow_action_type_bool_exp>;
};


export type subscription_rootflow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_order_by>>;
  where?: InputMaybe<flow_bool_exp>;
};


export type subscription_rootflow_auto_cancel_atArgs = {
  distinct_on?: InputMaybe<Array<flow_auto_cancel_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_auto_cancel_at_order_by>>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};


export type subscription_rootflow_auto_cancel_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_auto_cancel_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_auto_cancel_at_order_by>>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};


export type subscription_rootflow_auto_cancel_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_auto_cancel_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_auto_cancel_at_stream_cursor_input>>;
  where?: InputMaybe<flow_auto_cancel_at_bool_exp>;
};


export type subscription_rootflow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_languageArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


export type subscription_rootflow_language_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_language_order_by>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


export type subscription_rootflow_language_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  language: language_enum;
};


export type subscription_rootflow_language_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_language_stream_cursor_input>>;
  where?: InputMaybe<flow_language_bool_exp>;
};


export type subscription_rootflow_on_cancelArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_order_by>>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};


export type subscription_rootflow_on_cancel_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_order_by>>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};


export type subscription_rootflow_on_cancel_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_on_cancel_change_planArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


export type subscription_rootflow_on_cancel_change_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_change_plan_order_by>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


export type subscription_rootflow_on_cancel_change_plan_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type subscription_rootflow_on_cancel_change_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_on_cancel_change_plan_stream_cursor_input>>;
  where?: InputMaybe<flow_on_cancel_change_plan_bool_exp>;
};


export type subscription_rootflow_on_cancel_perform_atArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_perform_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_perform_at_order_by>>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};


export type subscription_rootflow_on_cancel_perform_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_on_cancel_perform_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_on_cancel_perform_at_order_by>>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};


export type subscription_rootflow_on_cancel_perform_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_on_cancel_perform_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_on_cancel_perform_at_stream_cursor_input>>;
  where?: InputMaybe<flow_on_cancel_perform_at_bool_exp>;
};


export type subscription_rootflow_on_cancel_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_on_cancel_stream_cursor_input>>;
  where?: InputMaybe<flow_on_cancel_bool_exp>;
};


export type subscription_rootflow_route_ruleArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


export type subscription_rootflow_route_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_order_by>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


export type subscription_rootflow_route_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_route_rule_eligibility_messageArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


export type subscription_rootflow_route_rule_eligibility_message_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_eligibility_message_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_eligibility_message_order_by>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


export type subscription_rootflow_route_rule_eligibility_message_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_eligibility_message_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_eligibility_message_bool_exp>;
};


export type subscription_rootflow_route_rule_flowArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


export type subscription_rootflow_route_rule_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


export type subscription_rootflow_route_rule_flow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_flow_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_flow_bool_exp>;
};


export type subscription_rootflow_route_rule_flow_testArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


export type subscription_rootflow_route_rule_flow_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_flow_test_order_by>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


export type subscription_rootflow_route_rule_flow_test_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_flow_test_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_flow_test_bool_exp>;
};


export type subscription_rootflow_route_rule_segmentArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


export type subscription_rootflow_route_rule_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


export type subscription_rootflow_route_rule_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


export type subscription_rootflow_route_rule_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_route_rule_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_route_rule_segment_group_order_by>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


export type subscription_rootflow_route_rule_segment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_segment_group_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_segment_group_bool_exp>;
};


export type subscription_rootflow_route_rule_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_segment_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_segment_bool_exp>;
};


export type subscription_rootflow_route_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_route_rule_stream_cursor_input>>;
  where?: InputMaybe<flow_route_rule_bool_exp>;
};


export type subscription_rootflow_stepArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


export type subscription_rootflow_step_acknowledgement_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


export type subscription_rootflow_step_acknowledgement_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_acknowledgement_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_acknowledgement_group_order_by>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


export type subscription_rootflow_step_acknowledgement_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_acknowledgement_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_acknowledgement_group_stream_cursor_input>>;
  where?: InputMaybe<flow_step_acknowledgement_group_bool_exp>;
};


export type subscription_rootflow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_order_by>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


export type subscription_rootflow_step_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_confirmationArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


export type subscription_rootflow_step_confirmation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_confirmation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_confirmation_order_by>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


export type subscription_rootflow_step_confirmation_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_confirmation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_confirmation_stream_cursor_input>>;
  where?: InputMaybe<flow_step_confirmation_bool_exp>;
};


export type subscription_rootflow_step_deflection_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


export type subscription_rootflow_step_deflection_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_deflection_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_deflection_rule_group_order_by>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


export type subscription_rootflow_step_deflection_rule_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_deflection_rule_group_stream_cursor_input>>;
  where?: InputMaybe<flow_step_deflection_rule_group_bool_exp>;
};


export type subscription_rootflow_step_formArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


export type subscription_rootflow_step_form_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_form_order_by>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


export type subscription_rootflow_step_form_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_form_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_form_stream_cursor_input>>;
  where?: InputMaybe<flow_step_form_bool_exp>;
};


export type subscription_rootflow_step_interventionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


export type subscription_rootflow_step_intervention_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_intervention_order_by>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


export type subscription_rootflow_step_intervention_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_intervention_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_intervention_stream_cursor_input>>;
  where?: InputMaybe<flow_step_intervention_bool_exp>;
};


export type subscription_rootflow_step_offer_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


export type subscription_rootflow_step_offer_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_offer_rule_group_order_by>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


export type subscription_rootflow_step_offer_rule_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_offer_rule_group_stream_cursor_input>>;
  where?: InputMaybe<flow_step_offer_rule_group_bool_exp>;
};


export type subscription_rootflow_step_questionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


export type subscription_rootflow_step_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_question_order_by>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


export type subscription_rootflow_step_question_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_question_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_question_stream_cursor_input>>;
  where?: InputMaybe<flow_step_question_bool_exp>;
};


export type subscription_rootflow_step_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


export type subscription_rootflow_step_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_rule_group_order_by>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


export type subscription_rootflow_step_rule_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_rule_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_rule_group_stream_cursor_input>>;
  where?: InputMaybe<flow_step_rule_group_bool_exp>;
};


export type subscription_rootflow_step_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_stream_cursor_input>>;
  where?: InputMaybe<flow_step_bool_exp>;
};


export type subscription_rootflow_step_typeArgs = {
  distinct_on?: InputMaybe<Array<flow_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_type_order_by>>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};


export type subscription_rootflow_step_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_type_order_by>>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};


export type subscription_rootflow_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_step_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_type_stream_cursor_input>>;
  where?: InputMaybe<flow_step_type_bool_exp>;
};


export type subscription_rootflow_step_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_order_by>>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};


export type subscription_rootflow_step_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_order_by>>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};


export type subscription_rootflow_step_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_step_version_condition_question_optionArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


export type subscription_rootflow_step_version_condition_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_question_option_order_by>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


export type subscription_rootflow_step_version_condition_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_version_condition_question_option_stream_cursor_input>>;
  where?: InputMaybe<flow_step_version_condition_question_option_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segmentArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_step_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_step_version_condition_segment_group_order_by>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_version_condition_segment_group_stream_cursor_input>>;
  where?: InputMaybe<flow_step_version_condition_segment_group_bool_exp>;
};


export type subscription_rootflow_step_version_condition_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_version_condition_segment_stream_cursor_input>>;
  where?: InputMaybe<flow_step_version_condition_segment_bool_exp>;
};


export type subscription_rootflow_step_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_step_version_stream_cursor_input>>;
  where?: InputMaybe<flow_step_version_bool_exp>;
};


export type subscription_rootflow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_stream_cursor_input>>;
  where?: InputMaybe<flow_bool_exp>;
};


export type subscription_rootflow_subscriber_form_questionArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


export type subscription_rootflow_subscriber_form_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_subscriber_form_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_subscriber_form_question_order_by>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


export type subscription_rootflow_subscriber_form_question_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_subscriber_form_question_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_subscriber_form_question_stream_cursor_input>>;
  where?: InputMaybe<flow_subscriber_form_question_bool_exp>;
};


export type subscription_rootflow_tagArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


export type subscription_rootflow_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_tag_order_by>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


export type subscription_rootflow_tag_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_tag_stream_cursor_input>>;
  where?: InputMaybe<flow_tag_bool_exp>;
};


export type subscription_rootflow_testArgs = {
  distinct_on?: InputMaybe<Array<flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_order_by>>;
  where?: InputMaybe<flow_test_bool_exp>;
};


export type subscription_rootflow_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_order_by>>;
  where?: InputMaybe<flow_test_bool_exp>;
};


export type subscription_rootflow_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_test_flowArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


export type subscription_rootflow_test_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_test_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_test_flow_order_by>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


export type subscription_rootflow_test_flow_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  flow_test_id: Scalars['Int']['input'];
};


export type subscription_rootflow_test_flow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_test_flow_stream_cursor_input>>;
  where?: InputMaybe<flow_test_flow_bool_exp>;
};


export type subscription_rootflow_test_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_test_stream_cursor_input>>;
  where?: InputMaybe<flow_test_bool_exp>;
};


export type subscription_rootflow_textArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


export type subscription_rootflow_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_order_by>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


export type subscription_rootflow_text_by_pkArgs = {
  flow_id: Scalars['Int']['input'];
  key: flow_text_key_enum;
};


export type subscription_rootflow_text_keyArgs = {
  distinct_on?: InputMaybe<Array<flow_text_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_key_order_by>>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};


export type subscription_rootflow_text_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_key_order_by>>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};


export type subscription_rootflow_text_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootflow_text_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_text_key_stream_cursor_input>>;
  where?: InputMaybe<flow_text_key_bool_exp>;
};


export type subscription_rootflow_text_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_text_stream_cursor_input>>;
  where?: InputMaybe<flow_text_bool_exp>;
};


export type subscription_rootflow_text_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


export type subscription_rootflow_text_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


export type subscription_rootflow_text_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_text_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_text_version_stream_cursor_input>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


export type subscription_rootflow_versionArgs = {
  distinct_on?: InputMaybe<Array<flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_order_by>>;
  where?: InputMaybe<flow_version_bool_exp>;
};


export type subscription_rootflow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_order_by>>;
  where?: InputMaybe<flow_version_bool_exp>;
};


export type subscription_rootflow_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootflow_version_flow_stepArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


export type subscription_rootflow_version_flow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_version_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_version_flow_step_order_by>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


export type subscription_rootflow_version_flow_step_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_version_flow_step_stream_cursor_input>>;
  where?: InputMaybe<flow_version_flow_step_bool_exp>;
};


export type subscription_rootflow_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<flow_version_stream_cursor_input>>;
  where?: InputMaybe<flow_version_bool_exp>;
};


export type subscription_rootformArgs = {
  distinct_on?: InputMaybe<Array<form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_order_by>>;
  where?: InputMaybe<form_bool_exp>;
};


export type subscription_rootform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_order_by>>;
  where?: InputMaybe<form_bool_exp>;
};


export type subscription_rootform_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootform_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<form_stream_cursor_input>>;
  where?: InputMaybe<form_bool_exp>;
};


export type subscription_rootform_versionArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};


export type subscription_rootform_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_order_by>>;
  where?: InputMaybe<form_version_bool_exp>;
};


export type subscription_rootform_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootform_version_questionArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


export type subscription_rootform_version_question_aggregateArgs = {
  distinct_on?: InputMaybe<Array<form_version_question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<form_version_question_order_by>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


export type subscription_rootform_version_question_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<form_version_question_stream_cursor_input>>;
  where?: InputMaybe<form_version_question_bool_exp>;
};


export type subscription_rootform_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<form_version_stream_cursor_input>>;
  where?: InputMaybe<form_version_bool_exp>;
};


export type subscription_rootfunction_logArgs = {
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


export type subscription_rootfunction_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<function_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<function_log_order_by>>;
  where?: InputMaybe<function_log_bool_exp>;
};


export type subscription_rootfunction_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootfunction_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<function_log_stream_cursor_input>>;
  where?: InputMaybe<function_log_bool_exp>;
};


export type subscription_rootintegrationArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


export type subscription_rootintegration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<integration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_order_by>>;
  where?: InputMaybe<integration_bool_exp>;
};


export type subscription_rootintegration_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootintegration_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<integration_stream_cursor_input>>;
  where?: InputMaybe<integration_bool_exp>;
};


export type subscription_rootintegration_typeArgs = {
  distinct_on?: InputMaybe<Array<integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_type_order_by>>;
  where?: InputMaybe<integration_type_bool_exp>;
};


export type subscription_rootintegration_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<integration_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<integration_type_order_by>>;
  where?: InputMaybe<integration_type_bool_exp>;
};


export type subscription_rootintegration_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootintegration_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<integration_type_stream_cursor_input>>;
  where?: InputMaybe<integration_type_bool_exp>;
};


export type subscription_rootinterventionArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};


export type subscription_rootintervention_aggregateArgs = {
  distinct_on?: InputMaybe<Array<intervention_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_order_by>>;
  where?: InputMaybe<intervention_bool_exp>;
};


export type subscription_rootintervention_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootintervention_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<intervention_stream_cursor_input>>;
  where?: InputMaybe<intervention_bool_exp>;
};


export type subscription_rootintervention_versionArgs = {
  distinct_on?: InputMaybe<Array<intervention_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_version_order_by>>;
  where?: InputMaybe<intervention_version_bool_exp>;
};


export type subscription_rootintervention_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<intervention_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<intervention_version_order_by>>;
  where?: InputMaybe<intervention_version_bool_exp>;
};


export type subscription_rootintervention_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootintervention_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<intervention_version_stream_cursor_input>>;
  where?: InputMaybe<intervention_version_bool_exp>;
};


export type subscription_rootjobArgs = {
  distinct_on?: InputMaybe<Array<job_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<job_order_by>>;
  where?: InputMaybe<job_bool_exp>;
};


export type subscription_rootjob_aggregateArgs = {
  distinct_on?: InputMaybe<Array<job_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<job_order_by>>;
  where?: InputMaybe<job_bool_exp>;
};


export type subscription_rootjob_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type subscription_rootjob_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<job_stream_cursor_input>>;
  where?: InputMaybe<job_bool_exp>;
};


export type subscription_rootlanguageArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};


export type subscription_rootlanguage_aggregateArgs = {
  distinct_on?: InputMaybe<Array<language_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<language_order_by>>;
  where?: InputMaybe<language_bool_exp>;
};


export type subscription_rootlanguage_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootlanguage_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<language_stream_cursor_input>>;
  where?: InputMaybe<language_bool_exp>;
};


export type subscription_rootlost_mrr_by_monthArgs = {
  distinct_on?: InputMaybe<Array<lost_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<lost_mrr_by_month_order_by>>;
  where?: InputMaybe<lost_mrr_by_month_bool_exp>;
};


export type subscription_rootlost_mrr_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<lost_mrr_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<lost_mrr_by_month_order_by>>;
  where?: InputMaybe<lost_mrr_by_month_bool_exp>;
};


export type subscription_rootlost_mrr_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<lost_mrr_by_month_stream_cursor_input>>;
  where?: InputMaybe<lost_mrr_by_month_bool_exp>;
};


export type subscription_rootmost_recent_subscriber_flow_versionArgs = {
  distinct_on?: InputMaybe<Array<most_recent_subscriber_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<most_recent_subscriber_flow_version_order_by>>;
  where?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
};


export type subscription_rootmost_recent_subscriber_flow_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<most_recent_subscriber_flow_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<most_recent_subscriber_flow_version_order_by>>;
  where?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
};


export type subscription_rootmost_recent_subscriber_flow_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<most_recent_subscriber_flow_version_stream_cursor_input>>;
  where?: InputMaybe<most_recent_subscriber_flow_version_bool_exp>;
};


export type subscription_rootnaviga_cancel_linkArgs = {
  distinct_on?: InputMaybe<Array<naviga_cancel_link_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_cancel_link_order_by>>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};


export type subscription_rootnaviga_cancel_link_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_cancel_link_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_cancel_link_order_by>>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};


export type subscription_rootnaviga_cancel_link_by_pkArgs = {
  client_code: Scalars['String']['input'];
  link_text: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
};


export type subscription_rootnaviga_cancel_link_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<naviga_cancel_link_stream_cursor_input>>;
  where?: InputMaybe<naviga_cancel_link_bool_exp>;
};


export type subscription_rootnaviga_domainArgs = {
  distinct_on?: InputMaybe<Array<naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_domain_order_by>>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};


export type subscription_rootnaviga_domain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_domain_order_by>>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};


export type subscription_rootnaviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
};


export type subscription_rootnaviga_domain_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<naviga_domain_stream_cursor_input>>;
  where?: InputMaybe<naviga_domain_bool_exp>;
};


export type subscription_rootnaviga_paper_codeArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


export type subscription_rootnaviga_paper_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<naviga_paper_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<naviga_paper_code_order_by>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


export type subscription_rootnaviga_paper_code_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
};


export type subscription_rootnaviga_paper_code_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<naviga_paper_code_stream_cursor_input>>;
  where?: InputMaybe<naviga_paper_code_bool_exp>;
};


export type subscription_rootofferArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type subscription_rootoffer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type subscription_rootoffer_autopilotArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_order_by>>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};


export type subscription_rootoffer_autopilot_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_order_by>>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};


export type subscription_rootoffer_autopilot_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_autopilot_offer_autopilot_roundArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_offer_autopilot_round_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_offer_autopilot_round_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_offer_autopilot_round_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_offer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_offer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_offer_variant_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_offer_variant_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_roundArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_order_by>>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_autopilot_round_logArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_log_order_by>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_log_by_pkArgs = {
  offer_autopilot_round_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_autopilot_round_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_round_log_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_round_log_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_offer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_offer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_offer_variant_order_by>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_offer_variant_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_round_offer_variant_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_round_offer_variant_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_strategyArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_strategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_round_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_round_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_autopilot_round_strategy_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_round_strategy_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_round_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_round_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_round_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_round_bool_exp>;
};


export type subscription_rootoffer_autopilot_strategyArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_strategy_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_strategy_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_strategy_order_by>>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_strategy_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_autopilot_strategy_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_strategy_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_strategy_bool_exp>;
};


export type subscription_rootoffer_autopilot_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_bool_exp>;
};


export type subscription_rootoffer_autopilot_success_metricArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_success_metric_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_success_metric_order_by>>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};


export type subscription_rootoffer_autopilot_success_metric_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_autopilot_success_metric_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_autopilot_success_metric_order_by>>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};


export type subscription_rootoffer_autopilot_success_metric_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_autopilot_success_metric_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_autopilot_success_metric_stream_cursor_input>>;
  where?: InputMaybe<offer_autopilot_success_metric_bool_exp>;
};


export type subscription_rootoffer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_change_planArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_order_by>>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};


export type subscription_rootoffer_change_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_order_by>>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};


export type subscription_rootoffer_change_plan_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_change_plan_change_atArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_change_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_change_at_order_by>>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};


export type subscription_rootoffer_change_plan_change_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_change_plan_change_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_change_plan_change_at_order_by>>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};


export type subscription_rootoffer_change_plan_change_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_change_plan_change_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_change_plan_change_at_stream_cursor_input>>;
  where?: InputMaybe<offer_change_plan_change_at_bool_exp>;
};


export type subscription_rootoffer_change_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_change_plan_stream_cursor_input>>;
  where?: InputMaybe<offer_change_plan_bool_exp>;
};


export type subscription_rootoffer_couponArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


export type subscription_rootoffer_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_order_by>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


export type subscription_rootoffer_coupon_apply_toArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_apply_to_order_by>>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};


export type subscription_rootoffer_coupon_apply_to_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_apply_to_order_by>>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};


export type subscription_rootoffer_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_coupon_apply_to_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_coupon_apply_to_stream_cursor_input>>;
  where?: InputMaybe<offer_coupon_apply_to_bool_exp>;
};


export type subscription_rootoffer_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_coupon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_coupon_stream_cursor_input>>;
  where?: InputMaybe<offer_coupon_bool_exp>;
};


export type subscription_rootoffer_coupon_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_type_order_by>>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};


export type subscription_rootoffer_coupon_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_coupon_type_order_by>>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};


export type subscription_rootoffer_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_coupon_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_coupon_type_stream_cursor_input>>;
  where?: InputMaybe<offer_coupon_type_bool_exp>;
};


export type subscription_rootoffer_customArgs = {
  distinct_on?: InputMaybe<Array<offer_custom_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_custom_order_by>>;
  where?: InputMaybe<offer_custom_bool_exp>;
};


export type subscription_rootoffer_custom_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_custom_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_custom_order_by>>;
  where?: InputMaybe<offer_custom_bool_exp>;
};


export type subscription_rootoffer_custom_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_custom_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_custom_stream_cursor_input>>;
  where?: InputMaybe<offer_custom_bool_exp>;
};


export type subscription_rootoffer_downgradeArgs = {
  distinct_on?: InputMaybe<Array<offer_downgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_downgrade_order_by>>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};


export type subscription_rootoffer_downgrade_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_downgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_downgrade_order_by>>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};


export type subscription_rootoffer_downgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_downgrade_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_downgrade_stream_cursor_input>>;
  where?: InputMaybe<offer_downgrade_bool_exp>;
};


export type subscription_rootoffer_goalArgs = {
  distinct_on?: InputMaybe<Array<offer_goal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_goal_order_by>>;
  where?: InputMaybe<offer_goal_bool_exp>;
};


export type subscription_rootoffer_goal_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_goal_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_goal_order_by>>;
  where?: InputMaybe<offer_goal_bool_exp>;
};


export type subscription_rootoffer_goal_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_goal_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_goal_stream_cursor_input>>;
  where?: InputMaybe<offer_goal_bool_exp>;
};


export type subscription_rootoffer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_order_by>>;
  where?: InputMaybe<offer_group_bool_exp>;
};


export type subscription_rootoffer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_order_by>>;
  where?: InputMaybe<offer_group_bool_exp>;
};


export type subscription_rootoffer_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_group_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


export type subscription_rootoffer_group_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_group_offer_order_by>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


export type subscription_rootoffer_group_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_group_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_group_offer_bool_exp>;
};


export type subscription_rootoffer_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_group_stream_cursor_input>>;
  where?: InputMaybe<offer_group_bool_exp>;
};


export type subscription_rootoffer_modify_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_order_by>>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_order_by>>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_modify_subscription_modify_atArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_modify_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_modify_at_order_by>>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_modify_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_modify_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_modify_at_order_by>>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_modify_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_modify_subscription_modify_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_modify_subscription_modify_at_stream_cursor_input>>;
  where?: InputMaybe<offer_modify_subscription_modify_at_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_modify_subscription_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_modify_subscription_option_stream_cursor_input>>;
  where?: InputMaybe<offer_modify_subscription_option_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_option_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_option_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_modify_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_modify_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_modify_subscription_option_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_modify_subscription_option_type_stream_cursor_input>>;
  where?: InputMaybe<offer_modify_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_modify_subscription_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_modify_subscription_stream_cursor_input>>;
  where?: InputMaybe<offer_modify_subscription_bool_exp>;
};


export type subscription_rootoffer_pause_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_order_by>>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_order_by>>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_pause_subscription_intervalArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_interval_order_by>>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_interval_order_by>>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_pause_subscription_interval_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_pause_subscription_interval_stream_cursor_input>>;
  where?: InputMaybe<offer_pause_subscription_interval_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_pause_subscription_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_pause_subscription_option_stream_cursor_input>>;
  where?: InputMaybe<offer_pause_subscription_option_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_option_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_option_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_option_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_option_type_order_by>>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_option_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_pause_subscription_option_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_pause_subscription_option_type_stream_cursor_input>>;
  where?: InputMaybe<offer_pause_subscription_option_type_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_pause_atArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_pause_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_pause_at_order_by>>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_pause_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_pause_subscription_pause_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_pause_subscription_pause_at_order_by>>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_pause_at_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_pause_subscription_pause_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_pause_subscription_pause_at_stream_cursor_input>>;
  where?: InputMaybe<offer_pause_subscription_pause_at_bool_exp>;
};


export type subscription_rootoffer_pause_subscription_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_pause_subscription_stream_cursor_input>>;
  where?: InputMaybe<offer_pause_subscription_bool_exp>;
};


export type subscription_rootoffer_product_swapArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_order_by>>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};


export type subscription_rootoffer_product_swap_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_order_by>>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};


export type subscription_rootoffer_product_swap_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_product_swap_platform_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_platform_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_platform_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_product_swap_platform_ecommerce_product_platform_variant_order_by>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_platform_variant_by_pkArgs = {
  offer_product_swap_platform_ecommerce_product_id: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_platform_variant_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_stream_cursor_input>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_platform_variant_bool_exp>;
};


export type subscription_rootoffer_product_swap_platform_ecommerce_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_product_swap_platform_ecommerce_product_stream_cursor_input>>;
  where?: InputMaybe<offer_product_swap_platform_ecommerce_product_bool_exp>;
};


export type subscription_rootoffer_product_swap_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_product_swap_stream_cursor_input>>;
  where?: InputMaybe<offer_product_swap_bool_exp>;
};


export type subscription_rootoffer_reschedule_orderArgs = {
  distinct_on?: InputMaybe<Array<offer_reschedule_order_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_reschedule_order_order_by>>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};


export type subscription_rootoffer_reschedule_order_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_reschedule_order_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_reschedule_order_order_by>>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};


export type subscription_rootoffer_reschedule_order_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_reschedule_order_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_reschedule_order_stream_cursor_input>>;
  where?: InputMaybe<offer_reschedule_order_bool_exp>;
};


export type subscription_rootoffer_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_rule_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


export type subscription_rootoffer_rule_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_order_by>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


export type subscription_rootoffer_rule_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_rule_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_group_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_group_bool_exp>;
};


export type subscription_rootoffer_rule_group_versionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_order_by>>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};


export type subscription_rootoffer_rule_group_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_order_by>>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};


export type subscription_rootoffer_rule_group_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_rule_group_version_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_group_version_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_group_version_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_group_version_offer_rule_order_by>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_group_version_offer_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_group_version_offer_rule_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_group_version_offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_group_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_group_version_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_group_version_bool_exp>;
};


export type subscription_rootoffer_rule_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_rule_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_rule_rule_deflectionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


export type subscription_rootoffer_rule_rule_deflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_deflection_order_by>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


export type subscription_rootoffer_rule_rule_deflection_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_deflection_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_deflection_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_autopilot_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_autopilot_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_autopilot_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_offer_autopilot_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_offer_group_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_offer_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_rule_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_rule_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_rule_offer_test_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_offer_test_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_rule_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_bool_exp>;
};


export type subscription_rootoffer_rule_versionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_order_by>>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};


export type subscription_rootoffer_rule_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_order_by>>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};


export type subscription_rootoffer_rule_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_rule_version_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_autopilot_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_autopilot_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_autopilot_offer_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_autopilot_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_offer_autopilot_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_offer_autopilot_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_group_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_offer_group_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_offer_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_rule_ruleArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_rule_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_rule_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_rule_rule_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_rule_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_offer_rule_rule_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_offer_rule_rule_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_offer_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_offer_test_order_by>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_version_offer_test_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_offer_test_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_offer_test_bool_exp>;
};


export type subscription_rootoffer_rule_version_question_optionArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


export type subscription_rootoffer_rule_version_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_question_option_order_by>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


export type subscription_rootoffer_rule_version_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_question_option_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_question_option_bool_exp>;
};


export type subscription_rootoffer_rule_version_segmentArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


export type subscription_rootoffer_rule_version_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


export type subscription_rootoffer_rule_version_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_rule_version_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_rule_version_segment_group_order_by>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_segment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_segment_group_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_segment_group_bool_exp>;
};


export type subscription_rootoffer_rule_version_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_segment_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_segment_bool_exp>;
};


export type subscription_rootoffer_rule_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_rule_version_stream_cursor_input>>;
  where?: InputMaybe<offer_rule_version_bool_exp>;
};


export type subscription_rootoffer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_stream_cursor_input>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type subscription_rootoffer_styleArgs = {
  distinct_on?: InputMaybe<Array<offer_style_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_style_order_by>>;
  where?: InputMaybe<offer_style_bool_exp>;
};


export type subscription_rootoffer_style_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_style_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_style_order_by>>;
  where?: InputMaybe<offer_style_bool_exp>;
};


export type subscription_rootoffer_style_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_style_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_style_stream_cursor_input>>;
  where?: InputMaybe<offer_style_bool_exp>;
};


export type subscription_rootoffer_tagArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


export type subscription_rootoffer_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tag_order_by>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


export type subscription_rootoffer_tag_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_tag_stream_cursor_input>>;
  where?: InputMaybe<offer_tag_bool_exp>;
};


export type subscription_rootoffer_testArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


export type subscription_rootoffer_test_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_order_by>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


export type subscription_rootoffer_test_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_test_offerArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


export type subscription_rootoffer_test_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_test_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_test_offer_order_by>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


export type subscription_rootoffer_test_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  offer_test_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_test_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_test_offer_stream_cursor_input>>;
  where?: InputMaybe<offer_test_offer_bool_exp>;
};


export type subscription_rootoffer_test_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_test_stream_cursor_input>>;
  where?: InputMaybe<offer_test_bool_exp>;
};


export type subscription_rootoffer_timerArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_order_by>>;
  where?: InputMaybe<offer_timer_bool_exp>;
};


export type subscription_rootoffer_timer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_order_by>>;
  where?: InputMaybe<offer_timer_bool_exp>;
};


export type subscription_rootoffer_timer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_timer_modeArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_mode_order_by>>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};


export type subscription_rootoffer_timer_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_timer_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_timer_mode_order_by>>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};


export type subscription_rootoffer_timer_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_timer_mode_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_timer_mode_stream_cursor_input>>;
  where?: InputMaybe<offer_timer_mode_bool_exp>;
};


export type subscription_rootoffer_timer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_timer_stream_cursor_input>>;
  where?: InputMaybe<offer_timer_bool_exp>;
};


export type subscription_rootoffer_trial_extensionArgs = {
  distinct_on?: InputMaybe<Array<offer_trial_extension_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_trial_extension_order_by>>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};


export type subscription_rootoffer_trial_extension_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_trial_extension_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_trial_extension_order_by>>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};


export type subscription_rootoffer_trial_extension_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_trial_extension_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_trial_extension_stream_cursor_input>>;
  where?: InputMaybe<offer_trial_extension_bool_exp>;
};


export type subscription_rootoffer_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<offer_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tsvector_order_by>>;
  where?: InputMaybe<offer_tsvector_bool_exp>;
};


export type subscription_rootoffer_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_tsvector_order_by>>;
  where?: InputMaybe<offer_tsvector_bool_exp>;
};


export type subscription_rootoffer_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_tsvector_stream_cursor_input>>;
  where?: InputMaybe<offer_tsvector_bool_exp>;
};


export type subscription_rootoffer_typeArgs = {
  distinct_on?: InputMaybe<Array<offer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_type_order_by>>;
  where?: InputMaybe<offer_type_bool_exp>;
};


export type subscription_rootoffer_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_type_order_by>>;
  where?: InputMaybe<offer_type_bool_exp>;
};


export type subscription_rootoffer_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootoffer_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_type_stream_cursor_input>>;
  where?: InputMaybe<offer_type_bool_exp>;
};


export type subscription_rootoffer_upgradeArgs = {
  distinct_on?: InputMaybe<Array<offer_upgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_upgrade_order_by>>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};


export type subscription_rootoffer_upgrade_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_upgrade_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_upgrade_order_by>>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};


export type subscription_rootoffer_upgrade_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
};


export type subscription_rootoffer_upgrade_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_upgrade_stream_cursor_input>>;
  where?: InputMaybe<offer_upgrade_bool_exp>;
};


export type subscription_rootoffer_variantArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_order_by>>;
  where?: InputMaybe<offer_variant_bool_exp>;
};


export type subscription_rootoffer_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_order_by>>;
  where?: InputMaybe<offer_variant_bool_exp>;
};


export type subscription_rootoffer_variant_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_variant_couponArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


export type subscription_rootoffer_variant_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<offer_variant_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_variant_coupon_order_by>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


export type subscription_rootoffer_variant_coupon_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootoffer_variant_coupon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_variant_coupon_stream_cursor_input>>;
  where?: InputMaybe<offer_variant_coupon_bool_exp>;
};


export type subscription_rootoffer_variant_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<offer_variant_stream_cursor_input>>;
  where?: InputMaybe<offer_variant_bool_exp>;
};


export type subscription_rootplatformArgs = {
  distinct_on?: InputMaybe<Array<platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_order_by>>;
  where?: InputMaybe<platform_bool_exp>;
};


export type subscription_rootplatform_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


export type subscription_rootplatform_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_order_by>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


export type subscription_rootplatform_addon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_addon_planArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


export type subscription_rootplatform_addon_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_plan_order_by>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


export type subscription_rootplatform_addon_plan_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type subscription_rootplatform_addon_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_addon_plan_stream_cursor_input>>;
  where?: InputMaybe<platform_addon_plan_bool_exp>;
};


export type subscription_rootplatform_addon_priceArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


export type subscription_rootplatform_addon_price_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_order_by>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


export type subscription_rootplatform_addon_price_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_addon_price_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_addon_price_stream_cursor_input>>;
  where?: InputMaybe<platform_addon_price_bool_exp>;
};


export type subscription_rootplatform_addon_price_tierArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


export type subscription_rootplatform_addon_price_tier_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_addon_price_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_addon_price_tier_order_by>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


export type subscription_rootplatform_addon_price_tier_by_pkArgs = {
  platform_addon_price_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  up_to: Scalars['Int']['input'];
};


export type subscription_rootplatform_addon_price_tier_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_addon_price_tier_stream_cursor_input>>;
  where?: InputMaybe<platform_addon_price_tier_bool_exp>;
};


export type subscription_rootplatform_addon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_addon_stream_cursor_input>>;
  where?: InputMaybe<platform_addon_bool_exp>;
};


export type subscription_rootplatform_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_order_by>>;
  where?: InputMaybe<platform_bool_exp>;
};


export type subscription_rootplatform_api_logArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type subscription_rootplatform_api_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type subscription_rootplatform_api_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type subscription_rootplatform_api_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_api_log_stream_cursor_input>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type subscription_rootplatform_api_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_tsvector_order_by>>;
  where?: InputMaybe<platform_api_log_tsvector_bool_exp>;
};


export type subscription_rootplatform_api_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_api_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_tsvector_order_by>>;
  where?: InputMaybe<platform_api_log_tsvector_bool_exp>;
};


export type subscription_rootplatform_api_log_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_api_log_tsvector_stream_cursor_input>>;
  where?: InputMaybe<platform_api_log_tsvector_bool_exp>;
};


export type subscription_rootplatform_base_currencyArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


export type subscription_rootplatform_base_currency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_base_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_base_currency_order_by>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


export type subscription_rootplatform_base_currency_by_pkArgs = {
  value: platform_currency_enum;
};


export type subscription_rootplatform_base_currency_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_base_currency_stream_cursor_input>>;
  where?: InputMaybe<platform_base_currency_bool_exp>;
};


export type subscription_rootplatform_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_cancel_reasonArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


export type subscription_rootplatform_cancel_reason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_cancel_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_cancel_reason_order_by>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


export type subscription_rootplatform_cancel_reason_by_pkArgs = {
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_cancel_reason_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_cancel_reason_stream_cursor_input>>;
  where?: InputMaybe<platform_cancel_reason_bool_exp>;
};


export type subscription_rootplatform_connectionArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


export type subscription_rootplatform_connection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_order_by>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


export type subscription_rootplatform_connection_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootplatform_connection_naviga_domainArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_domain_order_by>>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};


export type subscription_rootplatform_connection_naviga_domain_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_domain_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_domain_order_by>>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};


export type subscription_rootplatform_connection_naviga_domain_by_pkArgs = {
  domain: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type subscription_rootplatform_connection_naviga_domain_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_connection_naviga_domain_stream_cursor_input>>;
  where?: InputMaybe<platform_connection_naviga_domain_bool_exp>;
};


export type subscription_rootplatform_connection_naviga_paper_code_endpointArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_order_by>>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};


export type subscription_rootplatform_connection_naviga_paper_code_endpoint_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_naviga_paper_code_endpoint_order_by>>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};


export type subscription_rootplatform_connection_naviga_paper_code_endpoint_by_pkArgs = {
  client_code: Scalars['String']['input'];
  media_group_code: Scalars['String']['input'];
  paper_code: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
};


export type subscription_rootplatform_connection_naviga_paper_code_endpoint_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_connection_naviga_paper_code_endpoint_stream_cursor_input>>;
  where?: InputMaybe<platform_connection_naviga_paper_code_endpoint_bool_exp>;
};


export type subscription_rootplatform_connection_shopify_storeArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_shopify_store_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_shopify_store_order_by>>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};


export type subscription_rootplatform_connection_shopify_store_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_connection_shopify_store_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_connection_shopify_store_order_by>>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};


export type subscription_rootplatform_connection_shopify_store_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
};


export type subscription_rootplatform_connection_shopify_store_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_connection_shopify_store_stream_cursor_input>>;
  where?: InputMaybe<platform_connection_shopify_store_bool_exp>;
};


export type subscription_rootplatform_connection_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_connection_stream_cursor_input>>;
  where?: InputMaybe<platform_connection_bool_exp>;
};


export type subscription_rootplatform_couponArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type subscription_rootplatform_coupon_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


export type subscription_rootplatform_coupon_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_addon_order_by>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


export type subscription_rootplatform_coupon_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_addon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_addon_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_addon_bool_exp>;
};


export type subscription_rootplatform_coupon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_toArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_to_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_to_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_apply_to_restrictionArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_restriction_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_restriction_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_to_restriction_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_apply_to_restriction_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_apply_to_restriction_order_by>>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_to_restriction_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_apply_to_restriction_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_apply_to_restriction_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_apply_to_restriction_bool_exp>;
};


export type subscription_rootplatform_coupon_apply_to_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_apply_to_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_apply_to_bool_exp>;
};


export type subscription_rootplatform_coupon_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_durationArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_order_by>>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};


export type subscription_rootplatform_coupon_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_order_by>>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};


export type subscription_rootplatform_coupon_duration_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_duration_intervalArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_interval_order_by>>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};


export type subscription_rootplatform_coupon_duration_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_duration_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_duration_interval_order_by>>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};


export type subscription_rootplatform_coupon_duration_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_duration_interval_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_duration_interval_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_duration_interval_bool_exp>;
};


export type subscription_rootplatform_coupon_duration_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_duration_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_duration_bool_exp>;
};


export type subscription_rootplatform_coupon_planArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


export type subscription_rootplatform_coupon_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_plan_order_by>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


export type subscription_rootplatform_coupon_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_plan_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_plan_bool_exp>;
};


export type subscription_rootplatform_coupon_productArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


export type subscription_rootplatform_coupon_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_order_by>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


export type subscription_rootplatform_coupon_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_id: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_product_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


export type subscription_rootplatform_coupon_product_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_product_group_order_by>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


export type subscription_rootplatform_coupon_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_coupon_id: Scalars['String']['input'];
  platform_product_group_id: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_product_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_product_group_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_product_group_bool_exp>;
};


export type subscription_rootplatform_coupon_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_product_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_product_bool_exp>;
};


export type subscription_rootplatform_coupon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type subscription_rootplatform_coupon_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_tsvector_order_by>>;
  where?: InputMaybe<platform_coupon_tsvector_bool_exp>;
};


export type subscription_rootplatform_coupon_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_tsvector_order_by>>;
  where?: InputMaybe<platform_coupon_tsvector_bool_exp>;
};


export type subscription_rootplatform_coupon_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_tsvector_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_tsvector_bool_exp>;
};


export type subscription_rootplatform_coupon_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_type_order_by>>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};


export type subscription_rootplatform_coupon_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_coupon_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_type_order_by>>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};


export type subscription_rootplatform_coupon_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_coupon_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_coupon_type_stream_cursor_input>>;
  where?: InputMaybe<platform_coupon_type_bool_exp>;
};


export type subscription_rootplatform_credentialsArgs = {
  distinct_on?: InputMaybe<Array<platform_credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_credentials_order_by>>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};


export type subscription_rootplatform_credentials_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_credentials_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_credentials_order_by>>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};


export type subscription_rootplatform_credentials_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  platform: platform_enum;
};


export type subscription_rootplatform_credentials_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_credentials_stream_cursor_input>>;
  where?: InputMaybe<platform_credentials_bool_exp>;
};


export type subscription_rootplatform_currencyArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_order_by>>;
  where?: InputMaybe<platform_currency_bool_exp>;
};


export type subscription_rootplatform_currency_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_order_by>>;
  where?: InputMaybe<platform_currency_bool_exp>;
};


export type subscription_rootplatform_currency_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_currency_exchange_rateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


export type subscription_rootplatform_currency_exchange_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_currency_exchange_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_currency_exchange_rate_order_by>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


export type subscription_rootplatform_currency_exchange_rate_by_pkArgs = {
  base: platform_base_currency_enum;
  currency: platform_currency_enum;
};


export type subscription_rootplatform_currency_exchange_rate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_currency_exchange_rate_stream_cursor_input>>;
  where?: InputMaybe<platform_currency_exchange_rate_bool_exp>;
};


export type subscription_rootplatform_currency_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_currency_stream_cursor_input>>;
  where?: InputMaybe<platform_currency_bool_exp>;
};


export type subscription_rootplatform_customerArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


export type subscription_rootplatform_customer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_customer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_customer_order_by>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


export type subscription_rootplatform_customer_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_customer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_customer_stream_cursor_input>>;
  where?: InputMaybe<platform_customer_bool_exp>;
};


export type subscription_rootplatform_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_ecommerce_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_ecommerce_product_stream_cursor_input>>;
  where?: InputMaybe<platform_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_importArgs = {
  distinct_on?: InputMaybe<Array<platform_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_order_by>>;
  where?: InputMaybe<platform_import_bool_exp>;
};


export type subscription_rootplatform_import_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_order_by>>;
  where?: InputMaybe<platform_import_bool_exp>;
};


export type subscription_rootplatform_import_by_pkArgs = {
  job_id: Scalars['String']['input'];
};


export type subscription_rootplatform_import_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_status_order_by>>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};


export type subscription_rootplatform_import_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_status_order_by>>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};


export type subscription_rootplatform_import_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_import_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_import_status_stream_cursor_input>>;
  where?: InputMaybe<platform_import_status_bool_exp>;
};


export type subscription_rootplatform_import_stepArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


export type subscription_rootplatform_import_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_order_by>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


export type subscription_rootplatform_import_step_by_pkArgs = {
  job_id: Scalars['String']['input'];
  type: platform_import_step_type_enum;
};


export type subscription_rootplatform_import_step_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_import_step_stream_cursor_input>>;
  where?: InputMaybe<platform_import_step_bool_exp>;
};


export type subscription_rootplatform_import_step_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_type_order_by>>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};


export type subscription_rootplatform_import_step_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_import_step_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_import_step_type_order_by>>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};


export type subscription_rootplatform_import_step_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_import_step_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_import_step_type_stream_cursor_input>>;
  where?: InputMaybe<platform_import_step_type_bool_exp>;
};


export type subscription_rootplatform_import_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_import_stream_cursor_input>>;
  where?: InputMaybe<platform_import_bool_exp>;
};


export type subscription_rootplatform_invoiceArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


export type subscription_rootplatform_invoice_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_order_by>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


export type subscription_rootplatform_invoice_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_invoice_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_status_order_by>>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};


export type subscription_rootplatform_invoice_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_invoice_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_invoice_status_order_by>>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};


export type subscription_rootplatform_invoice_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_invoice_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_invoice_status_stream_cursor_input>>;
  where?: InputMaybe<platform_invoice_status_bool_exp>;
};


export type subscription_rootplatform_invoice_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_invoice_stream_cursor_input>>;
  where?: InputMaybe<platform_invoice_bool_exp>;
};


export type subscription_rootplatform_newspaper_rate_codeArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_rate_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_rate_code_order_by>>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};


export type subscription_rootplatform_newspaper_rate_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_rate_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_rate_code_order_by>>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};


export type subscription_rootplatform_newspaper_rate_code_by_pkArgs = {
  interval: platform_plan_interval_enum;
  interval_count: Scalars['Int']['input'];
  platform_connection_id: Scalars['Int']['input'];
  rate_code: Scalars['String']['input'];
};


export type subscription_rootplatform_newspaper_rate_code_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_newspaper_rate_code_stream_cursor_input>>;
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_newspaper_subscription_stop_dateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_stop_date_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_stop_date_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscription_stop_date_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_newspaper_subscription_stop_date_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_newspaper_subscription_stop_date_order_by>>;
  where?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscription_stop_date_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_newspaper_subscription_stop_date_stream_cursor_input>>;
  where?: InputMaybe<platform_newspaper_subscription_stop_date_bool_exp>;
};


export type subscription_rootplatform_newspaper_subscription_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_newspaper_subscription_stream_cursor_input>>;
  where?: InputMaybe<platform_newspaper_subscription_bool_exp>;
};


export type subscription_rootplatform_planArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


export type subscription_rootplatform_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_order_by>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


export type subscription_rootplatform_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_plan_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_plan_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_plan_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
};


export type subscription_rootplatform_plan_ecommerce_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_ecommerce_product_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_plan_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_group_order_by>>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};


export type subscription_rootplatform_plan_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_group_order_by>>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};


export type subscription_rootplatform_plan_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_plan_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_group_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_group_bool_exp>;
};


export type subscription_rootplatform_plan_intervalArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_interval_order_by>>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};


export type subscription_rootplatform_plan_interval_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_interval_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_interval_order_by>>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};


export type subscription_rootplatform_plan_interval_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_plan_interval_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_interval_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_interval_bool_exp>;
};


export type subscription_rootplatform_plan_pricing_modelArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_pricing_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_pricing_model_order_by>>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};


export type subscription_rootplatform_plan_pricing_model_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_pricing_model_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_pricing_model_order_by>>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};


export type subscription_rootplatform_plan_pricing_model_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_plan_pricing_model_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_pricing_model_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_pricing_model_bool_exp>;
};


export type subscription_rootplatform_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_bool_exp>;
};


export type subscription_rootplatform_plan_tierArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


export type subscription_rootplatform_plan_tier_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_plan_tier_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_plan_tier_order_by>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


export type subscription_rootplatform_plan_tier_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  up_to: Scalars['Int']['input'];
};


export type subscription_rootplatform_plan_tier_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_plan_tier_stream_cursor_input>>;
  where?: InputMaybe<platform_plan_tier_bool_exp>;
};


export type subscription_rootplatform_productArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


export type subscription_rootplatform_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_order_by>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


export type subscription_rootplatform_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_product_groupArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


export type subscription_rootplatform_product_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_product_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_product_group_order_by>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


export type subscription_rootplatform_product_group_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_product_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_product_group_stream_cursor_input>>;
  where?: InputMaybe<platform_product_group_bool_exp>;
};


export type subscription_rootplatform_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_product_stream_cursor_input>>;
  where?: InputMaybe<platform_product_bool_exp>;
};


export type subscription_rootplatform_raw_objectArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_order_by>>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};


export type subscription_rootplatform_raw_object_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_order_by>>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};


export type subscription_rootplatform_raw_object_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
  type: platform_raw_object_type_enum;
};


export type subscription_rootplatform_raw_object_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_raw_object_stream_cursor_input>>;
  where?: InputMaybe<platform_raw_object_bool_exp>;
};


export type subscription_rootplatform_raw_object_typeArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_type_order_by>>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};


export type subscription_rootplatform_raw_object_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_raw_object_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_raw_object_type_order_by>>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};


export type subscription_rootplatform_raw_object_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_raw_object_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_raw_object_type_stream_cursor_input>>;
  where?: InputMaybe<platform_raw_object_type_bool_exp>;
};


export type subscription_rootplatform_refundArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


export type subscription_rootplatform_refund_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_refund_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_refund_order_by>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


export type subscription_rootplatform_refund_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_refund_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_refund_stream_cursor_input>>;
  where?: InputMaybe<platform_refund_bool_exp>;
};


export type subscription_rootplatform_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_stream_cursor_input>>;
  where?: InputMaybe<platform_bool_exp>;
};


export type subscription_rootplatform_subscriptionArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


export type subscription_rootplatform_subscription_addonArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


export type subscription_rootplatform_subscription_addon_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_addon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_addon_order_by>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


export type subscription_rootplatform_subscription_addon_by_pkArgs = {
  platform_addon_id: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type subscription_rootplatform_subscription_addon_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_addon_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_addon_bool_exp>;
};


export type subscription_rootplatform_subscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_order_by>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


export type subscription_rootplatform_subscription_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_subscription_next_billing_atArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_next_billing_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_next_billing_at_order_by>>;
  where?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
};


export type subscription_rootplatform_subscription_next_billing_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_next_billing_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_next_billing_at_order_by>>;
  where?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
};


export type subscription_rootplatform_subscription_next_billing_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_next_billing_at_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_next_billing_at_bool_exp>;
};


export type subscription_rootplatform_subscription_planArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


export type subscription_rootplatform_subscription_plan_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_order_by>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


export type subscription_rootplatform_subscription_plan_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type subscription_rootplatform_subscription_plan_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_plan_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_plan_bool_exp>;
};


export type subscription_rootplatform_subscription_plan_swappable_ecommerce_productArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_subscription_plan_swappable_ecommerce_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_plan_swappable_ecommerce_product_order_by>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_subscription_plan_swappable_ecommerce_product_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_ecommerce_product_id: Scalars['String']['input'];
  platform_plan_id: Scalars['String']['input'];
  platform_subscription_id: Scalars['String']['input'];
};


export type subscription_rootplatform_subscription_plan_swappable_ecommerce_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_plan_swappable_ecommerce_product_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_plan_swappable_ecommerce_product_bool_exp>;
};


export type subscription_rootplatform_subscription_statusArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_status_order_by>>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};


export type subscription_rootplatform_subscription_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_subscription_status_order_by>>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};


export type subscription_rootplatform_subscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_subscription_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_status_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_status_bool_exp>;
};


export type subscription_rootplatform_subscription_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_subscription_stream_cursor_input>>;
  where?: InputMaybe<platform_subscription_bool_exp>;
};


export type subscription_rootplatform_variantArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


export type subscription_rootplatform_variant_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_order_by>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


export type subscription_rootplatform_variant_by_pkArgs = {
  platform_connection_id: Scalars['Int']['input'];
  platform_id: Scalars['String']['input'];
};


export type subscription_rootplatform_variant_optionArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


export type subscription_rootplatform_variant_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<platform_variant_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_variant_option_order_by>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


export type subscription_rootplatform_variant_option_by_pkArgs = {
  key: Scalars['String']['input'];
  platform_connection_id: Scalars['Int']['input'];
  platform_variant_id: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type subscription_rootplatform_variant_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_variant_option_stream_cursor_input>>;
  where?: InputMaybe<platform_variant_option_bool_exp>;
};


export type subscription_rootplatform_variant_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<platform_variant_stream_cursor_input>>;
  where?: InputMaybe<platform_variant_bool_exp>;
};


export type subscription_rootportal_email_codeArgs = {
  distinct_on?: InputMaybe<Array<portal_email_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_email_code_order_by>>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};


export type subscription_rootportal_email_code_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_email_code_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_email_code_order_by>>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};


export type subscription_rootportal_email_code_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootportal_email_code_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<portal_email_code_stream_cursor_input>>;
  where?: InputMaybe<portal_email_code_bool_exp>;
};


export type subscription_rootportal_sessionArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


export type subscription_rootportal_session_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_order_by>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


export type subscription_rootportal_session_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootportal_session_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<portal_session_stream_cursor_input>>;
  where?: InputMaybe<portal_session_bool_exp>;
};


export type subscription_rootportal_session_subscriberArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


export type subscription_rootportal_session_subscriber_aggregateArgs = {
  distinct_on?: InputMaybe<Array<portal_session_subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<portal_session_subscriber_order_by>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


export type subscription_rootportal_session_subscriber_by_pkArgs = {
  portal_session_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type subscription_rootportal_session_subscriber_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<portal_session_subscriber_stream_cursor_input>>;
  where?: InputMaybe<portal_session_subscriber_bool_exp>;
};


export type subscription_rootproductArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


export type subscription_rootproduct_aggregateArgs = {
  distinct_on?: InputMaybe<Array<product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<product_order_by>>;
  where?: InputMaybe<product_bool_exp>;
};


export type subscription_rootproduct_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootproduct_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<product_stream_cursor_input>>;
  where?: InputMaybe<product_bool_exp>;
};


export type subscription_rootpropertyArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type subscription_rootproperty_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type subscription_rootproperty_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootproperty_entityArgs = {
  distinct_on?: InputMaybe<Array<property_entity_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_entity_order_by>>;
  where?: InputMaybe<property_entity_bool_exp>;
};


export type subscription_rootproperty_entity_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_entity_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_entity_order_by>>;
  where?: InputMaybe<property_entity_bool_exp>;
};


export type subscription_rootproperty_entity_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootproperty_entity_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<property_entity_stream_cursor_input>>;
  where?: InputMaybe<property_entity_bool_exp>;
};


export type subscription_rootproperty_formatArgs = {
  distinct_on?: InputMaybe<Array<property_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_format_order_by>>;
  where?: InputMaybe<property_format_bool_exp>;
};


export type subscription_rootproperty_format_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_format_order_by>>;
  where?: InputMaybe<property_format_bool_exp>;
};


export type subscription_rootproperty_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootproperty_format_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<property_format_stream_cursor_input>>;
  where?: InputMaybe<property_format_bool_exp>;
};


export type subscription_rootproperty_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<property_stream_cursor_input>>;
  where?: InputMaybe<property_bool_exp>;
};


export type subscription_rootproperty_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<property_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_tsvector_order_by>>;
  where?: InputMaybe<property_tsvector_bool_exp>;
};


export type subscription_rootproperty_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_tsvector_order_by>>;
  where?: InputMaybe<property_tsvector_bool_exp>;
};


export type subscription_rootproperty_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<property_tsvector_stream_cursor_input>>;
  where?: InputMaybe<property_tsvector_bool_exp>;
};


export type subscription_rootproperty_typeArgs = {
  distinct_on?: InputMaybe<Array<property_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_type_order_by>>;
  where?: InputMaybe<property_type_bool_exp>;
};


export type subscription_rootproperty_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<property_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_type_order_by>>;
  where?: InputMaybe<property_type_bool_exp>;
};


export type subscription_rootproperty_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootproperty_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<property_type_stream_cursor_input>>;
  where?: InputMaybe<property_type_bool_exp>;
};


export type subscription_rootquestionArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


export type subscription_rootquestion_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_order_by>>;
  where?: InputMaybe<question_bool_exp>;
};


export type subscription_rootquestion_answerArgs = {
  distinct_on?: InputMaybe<Array<question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_order_by>>;
  where?: InputMaybe<question_answer_bool_exp>;
};


export type subscription_rootquestion_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_order_by>>;
  where?: InputMaybe<question_answer_bool_exp>;
};


export type subscription_rootquestion_answer_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_answer_likelihood_to_returnArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


export type subscription_rootquestion_answer_likelihood_to_return_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_likelihood_to_return_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_likelihood_to_return_order_by>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


export type subscription_rootquestion_answer_likelihood_to_return_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


export type subscription_rootquestion_answer_likelihood_to_return_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_answer_likelihood_to_return_stream_cursor_input>>;
  where?: InputMaybe<question_answer_likelihood_to_return_bool_exp>;
};


export type subscription_rootquestion_answer_radioArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


export type subscription_rootquestion_answer_radio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_radio_order_by>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


export type subscription_rootquestion_answer_radio_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type subscription_rootquestion_answer_radio_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_answer_radio_stream_cursor_input>>;
  where?: InputMaybe<question_answer_radio_bool_exp>;
};


export type subscription_rootquestion_answer_sentiment_phraseArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


export type subscription_rootquestion_answer_sentiment_phrase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_sentiment_phrase_order_by>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


export type subscription_rootquestion_answer_sentiment_phrase_by_pkArgs = {
  begin_offset: Scalars['Int']['input'];
  question_answer_id: Scalars['Int']['input'];
  sentiment_phrase_id: Scalars['Int']['input'];
};


export type subscription_rootquestion_answer_sentiment_phrase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_answer_sentiment_phrase_stream_cursor_input>>;
  where?: InputMaybe<question_answer_sentiment_phrase_bool_exp>;
};


export type subscription_rootquestion_answer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_answer_stream_cursor_input>>;
  where?: InputMaybe<question_answer_bool_exp>;
};


export type subscription_rootquestion_answer_textArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


export type subscription_rootquestion_answer_text_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_answer_text_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_answer_text_order_by>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


export type subscription_rootquestion_answer_text_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
};


export type subscription_rootquestion_answer_text_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_answer_text_stream_cursor_input>>;
  where?: InputMaybe<question_answer_text_bool_exp>;
};


export type subscription_rootquestion_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_optionArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


export type subscription_rootquestion_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_order_by>>;
  where?: InputMaybe<question_option_bool_exp>;
};


export type subscription_rootquestion_option_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_option_stream_cursor_input>>;
  where?: InputMaybe<question_option_bool_exp>;
};


export type subscription_rootquestion_option_versionArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


export type subscription_rootquestion_option_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_option_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_option_version_order_by>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


export type subscription_rootquestion_option_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_option_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_option_version_stream_cursor_input>>;
  where?: InputMaybe<question_option_version_bool_exp>;
};


export type subscription_rootquestion_settingArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


export type subscription_rootquestion_setting_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_order_by>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


export type subscription_rootquestion_setting_by_pkArgs = {
  key: question_setting_key_enum;
  question_id: Scalars['Int']['input'];
};


export type subscription_rootquestion_setting_keyArgs = {
  distinct_on?: InputMaybe<Array<question_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_key_order_by>>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};


export type subscription_rootquestion_setting_key_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_key_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_key_order_by>>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};


export type subscription_rootquestion_setting_key_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootquestion_setting_key_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_setting_key_stream_cursor_input>>;
  where?: InputMaybe<question_setting_key_bool_exp>;
};


export type subscription_rootquestion_setting_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_setting_stream_cursor_input>>;
  where?: InputMaybe<question_setting_bool_exp>;
};


export type subscription_rootquestion_setting_versionArgs = {
  distinct_on?: InputMaybe<Array<question_setting_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_version_order_by>>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};


export type subscription_rootquestion_setting_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_setting_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_setting_version_order_by>>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};


export type subscription_rootquestion_setting_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_setting_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_setting_version_stream_cursor_input>>;
  where?: InputMaybe<question_setting_version_bool_exp>;
};


export type subscription_rootquestion_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_stream_cursor_input>>;
  where?: InputMaybe<question_bool_exp>;
};


export type subscription_rootquestion_typeArgs = {
  distinct_on?: InputMaybe<Array<question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_type_order_by>>;
  where?: InputMaybe<question_type_bool_exp>;
};


export type subscription_rootquestion_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_type_order_by>>;
  where?: InputMaybe<question_type_bool_exp>;
};


export type subscription_rootquestion_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootquestion_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_type_stream_cursor_input>>;
  where?: InputMaybe<question_type_bool_exp>;
};


export type subscription_rootquestion_versionArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};


export type subscription_rootquestion_version_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_order_by>>;
  where?: InputMaybe<question_version_bool_exp>;
};


export type subscription_rootquestion_version_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootquestion_version_condition_question_optionArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


export type subscription_rootquestion_version_condition_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_question_option_order_by>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


export type subscription_rootquestion_version_condition_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_version_condition_question_option_stream_cursor_input>>;
  where?: InputMaybe<question_version_condition_question_option_bool_exp>;
};


export type subscription_rootquestion_version_condition_segmentArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


export type subscription_rootquestion_version_condition_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_order_by>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


export type subscription_rootquestion_version_condition_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


export type subscription_rootquestion_version_condition_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_condition_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_condition_segment_group_order_by>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


export type subscription_rootquestion_version_condition_segment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_version_condition_segment_group_stream_cursor_input>>;
  where?: InputMaybe<question_version_condition_segment_group_bool_exp>;
};


export type subscription_rootquestion_version_condition_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_version_condition_segment_stream_cursor_input>>;
  where?: InputMaybe<question_version_condition_segment_bool_exp>;
};


export type subscription_rootquestion_version_question_optionArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


export type subscription_rootquestion_version_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<question_version_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<question_version_question_option_order_by>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


export type subscription_rootquestion_version_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_version_question_option_stream_cursor_input>>;
  where?: InputMaybe<question_version_question_option_bool_exp>;
};


export type subscription_rootquestion_version_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<question_version_stream_cursor_input>>;
  where?: InputMaybe<question_version_bool_exp>;
};


export type subscription_rootreport_deflection_trendsArgs = {
  args: report_deflection_trends_args;
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type subscription_rootreport_deflection_trends_aggregateArgs = {
  args: report_deflection_trends_args;
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type subscription_rootreport_feedback_trendsArgs = {
  args: report_feedback_trends_args;
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type subscription_rootreport_feedback_trends_aggregateArgs = {
  args: report_feedback_trends_args;
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type subscription_rootreport_flow_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


export type subscription_rootreport_flow_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_flow_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_flow_test_stats_order_by>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


export type subscription_rootreport_flow_test_stats_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_flow_test_stats_stream_cursor_input>>;
  where?: InputMaybe<report_flow_test_stats_bool_exp>;
};


export type subscription_rootreport_inactive_mrr_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_inactive_mrr_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_inactive_mrr_by_question_option_order_by>>;
  where?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
};


export type subscription_rootreport_inactive_mrr_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_inactive_mrr_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_inactive_mrr_by_question_option_order_by>>;
  where?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
};


export type subscription_rootreport_inactive_mrr_by_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_inactive_mrr_by_question_option_stream_cursor_input>>;
  where?: InputMaybe<report_inactive_mrr_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_accept_rateArgs = {
  args: report_offer_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type subscription_rootreport_offer_accept_rate_aggregateArgs = {
  args: report_offer_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type subscription_rootreport_offer_autopilot_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


export type subscription_rootreport_offer_autopilot_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_autopilot_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_autopilot_stats_order_by>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


export type subscription_rootreport_offer_autopilot_stats_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_offer_autopilot_stats_stream_cursor_input>>;
  where?: InputMaybe<report_offer_autopilot_stats_bool_exp>;
};


export type subscription_rootreport_offer_group_accept_rateArgs = {
  args: report_offer_group_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type subscription_rootreport_offer_group_accept_rate_aggregateArgs = {
  args: report_offer_group_accept_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type subscription_rootreport_offer_group_resolution_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_offer_group_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_group_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_group_resolution_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_group_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_group_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_group_resolution_by_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_offer_group_resolution_by_question_option_stream_cursor_input>>;
  where?: InputMaybe<report_offer_group_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_group_retained_revenueArgs = {
  args: report_offer_group_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type subscription_rootreport_offer_group_retained_revenue_aggregateArgs = {
  args: report_offer_group_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type subscription_rootreport_offer_rateArgs = {
  args: report_offer_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type subscription_rootreport_offer_rate_aggregateArgs = {
  args: report_offer_rate_args;
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type subscription_rootreport_offer_resolutionArgs = {
  args: report_offer_resolution_args;
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type subscription_rootreport_offer_resolution_aggregateArgs = {
  args: report_offer_resolution_args;
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type subscription_rootreport_offer_resolution_by_question_optionArgs = {
  distinct_on?: InputMaybe<Array<report_offer_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_resolution_by_question_option_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_resolution_by_question_option_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_resolution_by_question_option_order_by>>;
  where?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_resolution_by_question_option_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_offer_resolution_by_question_option_stream_cursor_input>>;
  where?: InputMaybe<report_offer_resolution_by_question_option_bool_exp>;
};


export type subscription_rootreport_offer_retained_revenueArgs = {
  args: report_offer_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type subscription_rootreport_offer_retained_revenue_aggregateArgs = {
  args: report_offer_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type subscription_rootreport_offer_test_statsArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


export type subscription_rootreport_offer_test_stats_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_offer_test_stats_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_offer_test_stats_order_by>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


export type subscription_rootreport_offer_test_stats_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_offer_test_stats_stream_cursor_input>>;
  where?: InputMaybe<report_offer_test_stats_bool_exp>;
};


export type subscription_rootreport_question_answer_radioArgs = {
  args: report_question_answer_radio_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type subscription_rootreport_question_answer_radio_aggregateArgs = {
  args: report_question_answer_radio_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type subscription_rootreport_question_answer_radio_inactive_mrrArgs = {
  args: report_question_answer_radio_inactive_mrr_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type subscription_rootreport_question_answer_radio_inactive_mrr_aggregateArgs = {
  args: report_question_answer_radio_inactive_mrr_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type subscription_rootreport_question_answer_sentimentArgs = {
  args: report_question_answer_sentiment_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type subscription_rootreport_question_answer_sentiment_aggregateArgs = {
  args: report_question_answer_sentiment_args;
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type subscription_rootreport_recurring_revenueArgs = {
  distinct_on?: InputMaybe<Array<report_recurring_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_recurring_revenue_order_by>>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};


export type subscription_rootreport_recurring_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<report_recurring_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<report_recurring_revenue_order_by>>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};


export type subscription_rootreport_recurring_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['date']['input'];
};


export type subscription_rootreport_recurring_revenue_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<report_recurring_revenue_stream_cursor_input>>;
  where?: InputMaybe<report_recurring_revenue_bool_exp>;
};


export type subscription_rootreport_retained_revenueArgs = {
  args: report_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type subscription_rootreport_retained_revenue_aggregateArgs = {
  args: report_retained_revenue_args;
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type subscription_rootreport_retention_trendsArgs = {
  args: report_retention_trends_args;
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type subscription_rootreport_retention_trends_aggregateArgs = {
  args: report_retention_trends_args;
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type subscription_rootreport_subscriber_flow_statusArgs = {
  args: report_subscriber_flow_status_args;
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type subscription_rootreport_subscriber_flow_status_aggregateArgs = {
  args: report_subscriber_flow_status_args;
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type subscription_rootrevenue_logArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


export type subscription_rootrevenue_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_order_by>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


export type subscription_rootrevenue_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootrevenue_log_revenue_typeArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_revenue_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_revenue_type_order_by>>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};


export type subscription_rootrevenue_log_revenue_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_revenue_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_revenue_type_order_by>>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};


export type subscription_rootrevenue_log_revenue_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootrevenue_log_revenue_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<revenue_log_revenue_type_stream_cursor_input>>;
  where?: InputMaybe<revenue_log_revenue_type_bool_exp>;
};


export type subscription_rootrevenue_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<revenue_log_stream_cursor_input>>;
  where?: InputMaybe<revenue_log_bool_exp>;
};


export type subscription_rootrevenue_log_typeArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_type_order_by>>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};


export type subscription_rootrevenue_log_type_aggregateArgs = {
  distinct_on?: InputMaybe<Array<revenue_log_type_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<revenue_log_type_order_by>>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};


export type subscription_rootrevenue_log_type_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootrevenue_log_type_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<revenue_log_type_stream_cursor_input>>;
  where?: InputMaybe<revenue_log_type_bool_exp>;
};


export type subscription_rootrule_group_modeArgs = {
  distinct_on?: InputMaybe<Array<rule_group_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<rule_group_mode_order_by>>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};


export type subscription_rootrule_group_mode_aggregateArgs = {
  distinct_on?: InputMaybe<Array<rule_group_mode_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<rule_group_mode_order_by>>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};


export type subscription_rootrule_group_mode_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootrule_group_mode_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<rule_group_mode_stream_cursor_input>>;
  where?: InputMaybe<rule_group_mode_bool_exp>;
};


export type subscription_rootscript_logArgs = {
  distinct_on?: InputMaybe<Array<script_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<script_log_order_by>>;
  where?: InputMaybe<script_log_bool_exp>;
};


export type subscription_rootscript_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<script_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<script_log_order_by>>;
  where?: InputMaybe<script_log_bool_exp>;
};


export type subscription_rootscript_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootscript_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<script_log_stream_cursor_input>>;
  where?: InputMaybe<script_log_bool_exp>;
};


export type subscription_rootsearch_accountsArgs = {
  args: search_accounts_args;
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type subscription_rootsearch_accounts_aggregateArgs = {
  args: search_accounts_args;
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


export type subscription_rootsearch_data_connector_request_logsArgs = {
  args: search_data_connector_request_logs_args;
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type subscription_rootsearch_data_connector_request_logs_aggregateArgs = {
  args: search_data_connector_request_logs_args;
  distinct_on?: InputMaybe<Array<data_connector_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<data_connector_request_log_order_by>>;
  where?: InputMaybe<data_connector_request_log_bool_exp>;
};


export type subscription_rootsearch_deflectionsArgs = {
  args: search_deflections_args;
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type subscription_rootsearch_deflections_aggregateArgs = {
  args: search_deflections_args;
  distinct_on?: InputMaybe<Array<deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<deflection_order_by>>;
  where?: InputMaybe<deflection_bool_exp>;
};


export type subscription_rootsearch_offersArgs = {
  args: search_offers_args;
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type subscription_rootsearch_offers_aggregateArgs = {
  args: search_offers_args;
  distinct_on?: InputMaybe<Array<offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<offer_order_by>>;
  where?: InputMaybe<offer_bool_exp>;
};


export type subscription_rootsearch_platform_api_logsArgs = {
  args: search_platform_api_logs_args;
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type subscription_rootsearch_platform_api_logs_aggregateArgs = {
  args: search_platform_api_logs_args;
  distinct_on?: InputMaybe<Array<platform_api_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_api_log_order_by>>;
  where?: InputMaybe<platform_api_log_bool_exp>;
};


export type subscription_rootsearch_platform_couponsArgs = {
  args: search_platform_coupons_args;
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type subscription_rootsearch_platform_coupons_aggregateArgs = {
  args: search_platform_coupons_args;
  distinct_on?: InputMaybe<Array<platform_coupon_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<platform_coupon_order_by>>;
  where?: InputMaybe<platform_coupon_bool_exp>;
};


export type subscription_rootsearch_propertiesArgs = {
  args: search_properties_args;
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type subscription_rootsearch_properties_aggregateArgs = {
  args: search_properties_args;
  distinct_on?: InputMaybe<Array<property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<property_order_by>>;
  where?: InputMaybe<property_bool_exp>;
};


export type subscription_rootsearch_segment_groupsArgs = {
  args: search_segment_groups_args;
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type subscription_rootsearch_segment_groups_aggregateArgs = {
  args: search_segment_groups_args;
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type subscription_rootsearch_segmentsArgs = {
  args: search_segments_args;
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type subscription_rootsearch_segments_aggregateArgs = {
  args: search_segments_args;
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type subscription_rootsearch_subscriber_flowsArgs = {
  args: search_subscriber_flows_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsearch_subscriber_flows_aggregateArgs = {
  args: search_subscriber_flows_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsearch_subscriber_gridArgs = {
  args: search_subscriber_grid_args;
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type subscription_rootsearch_subscriber_grid_aggregateArgs = {
  args: search_subscriber_grid_args;
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type subscription_rootsearch_subscribersArgs = {
  args: search_subscribers_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsearch_subscribers_aggregateArgs = {
  args: search_subscribers_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsearch_webhook_request_logsArgs = {
  args: search_webhook_request_logs_args;
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type subscription_rootsearch_webhook_request_logs_aggregateArgs = {
  args: search_webhook_request_logs_args;
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type subscription_rootsegmentArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type subscription_rootsegment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_order_by>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type subscription_rootsegment_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsegment_conditionArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


export type subscription_rootsegment_condition_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_order_by>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


export type subscription_rootsegment_condition_boolean_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_boolean_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_boolean_operator_order_by>>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};


export type subscription_rootsegment_condition_boolean_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_boolean_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_boolean_operator_order_by>>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};


export type subscription_rootsegment_condition_boolean_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsegment_condition_boolean_operator_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_boolean_operator_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_boolean_operator_bool_exp>;
};


export type subscription_rootsegment_condition_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsegment_condition_groupArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_order_by>>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};


export type subscription_rootsegment_condition_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_order_by>>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};


export type subscription_rootsegment_condition_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsegment_condition_group_entryArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


export type subscription_rootsegment_condition_group_entry_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_group_entry_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_group_entry_order_by>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


export type subscription_rootsegment_condition_group_entry_by_pkArgs = {
  position: Scalars['Int']['input'];
  segment_condition_group_id: Scalars['Int']['input'];
};


export type subscription_rootsegment_condition_group_entry_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_group_entry_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_group_entry_bool_exp>;
};


export type subscription_rootsegment_condition_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_group_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_group_bool_exp>;
};


export type subscription_rootsegment_condition_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_operator_order_by>>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};


export type subscription_rootsegment_condition_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_operator_order_by>>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};


export type subscription_rootsegment_condition_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsegment_condition_operator_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_operator_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_operator_bool_exp>;
};


export type subscription_rootsegment_condition_propertyArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_property_order_by>>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};


export type subscription_rootsegment_condition_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_condition_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_condition_property_order_by>>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};


export type subscription_rootsegment_condition_property_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsegment_condition_property_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_property_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_property_bool_exp>;
};


export type subscription_rootsegment_condition_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_condition_stream_cursor_input>>;
  where?: InputMaybe<segment_condition_bool_exp>;
};


export type subscription_rootsegment_groupArgs = {
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type subscription_rootsegment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_order_by>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type subscription_rootsegment_group_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsegment_group_operatorArgs = {
  distinct_on?: InputMaybe<Array<segment_group_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_operator_order_by>>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};


export type subscription_rootsegment_group_operator_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_operator_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_operator_order_by>>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};


export type subscription_rootsegment_group_operator_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsegment_group_operator_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_group_operator_stream_cursor_input>>;
  where?: InputMaybe<segment_group_operator_bool_exp>;
};


export type subscription_rootsegment_group_segmentArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


export type subscription_rootsegment_group_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_segment_order_by>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


export type subscription_rootsegment_group_segment_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
};


export type subscription_rootsegment_group_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_group_segment_stream_cursor_input>>;
  where?: InputMaybe<segment_group_segment_bool_exp>;
};


export type subscription_rootsegment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_group_stream_cursor_input>>;
  where?: InputMaybe<segment_group_bool_exp>;
};


export type subscription_rootsegment_group_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<segment_group_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_tsvector_order_by>>;
  where?: InputMaybe<segment_group_tsvector_bool_exp>;
};


export type subscription_rootsegment_group_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_group_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_group_tsvector_order_by>>;
  where?: InputMaybe<segment_group_tsvector_bool_exp>;
};


export type subscription_rootsegment_group_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_group_tsvector_stream_cursor_input>>;
  where?: InputMaybe<segment_group_tsvector_bool_exp>;
};


export type subscription_rootsegment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_stream_cursor_input>>;
  where?: InputMaybe<segment_bool_exp>;
};


export type subscription_rootsegment_subscriberArgs = {
  args: segment_subscriber_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsegment_subscriber_aggregateArgs = {
  args: segment_subscriber_args;
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsegment_subscriber_flowArgs = {
  args: segment_subscriber_flow_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsegment_subscriber_flow_aggregateArgs = {
  args: segment_subscriber_flow_args;
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsegment_subscriber_logArgs = {
  args: segment_subscriber_log_args;
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type subscription_rootsegment_subscriber_log_aggregateArgs = {
  args: segment_subscriber_log_args;
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type subscription_rootsegment_subscriptionArgs = {
  args: segment_subscription_args;
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type subscription_rootsegment_subscription_aggregateArgs = {
  args: segment_subscription_args;
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type subscription_rootsegment_subscription_cacheArgs = {
  distinct_on?: InputMaybe<Array<segment_subscription_cache_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_subscription_cache_order_by>>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};


export type subscription_rootsegment_subscription_cache_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_subscription_cache_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_subscription_cache_order_by>>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};


export type subscription_rootsegment_subscription_cache_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type subscription_rootsegment_subscription_cache_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_subscription_cache_stream_cursor_input>>;
  where?: InputMaybe<segment_subscription_cache_bool_exp>;
};


export type subscription_rootsegment_tagArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


export type subscription_rootsegment_tag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tag_order_by>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


export type subscription_rootsegment_tag_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_tag_stream_cursor_input>>;
  where?: InputMaybe<segment_tag_bool_exp>;
};


export type subscription_rootsegment_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<segment_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tsvector_order_by>>;
  where?: InputMaybe<segment_tsvector_bool_exp>;
};


export type subscription_rootsegment_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<segment_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<segment_tsvector_order_by>>;
  where?: InputMaybe<segment_tsvector_bool_exp>;
};


export type subscription_rootsegment_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<segment_tsvector_stream_cursor_input>>;
  where?: InputMaybe<segment_tsvector_bool_exp>;
};


export type subscription_rootsentimentArgs = {
  distinct_on?: InputMaybe<Array<sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_order_by>>;
  where?: InputMaybe<sentiment_bool_exp>;
};


export type subscription_rootsentiment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_order_by>>;
  where?: InputMaybe<sentiment_bool_exp>;
};


export type subscription_rootsentiment_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsentiment_phraseArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


export type subscription_rootsentiment_phrase_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_order_by>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


export type subscription_rootsentiment_phrase_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsentiment_phrase_normalizedArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_normalized_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_normalized_order_by>>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};


export type subscription_rootsentiment_phrase_normalized_aggregateArgs = {
  distinct_on?: InputMaybe<Array<sentiment_phrase_normalized_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<sentiment_phrase_normalized_order_by>>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};


export type subscription_rootsentiment_phrase_normalized_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsentiment_phrase_normalized_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<sentiment_phrase_normalized_stream_cursor_input>>;
  where?: InputMaybe<sentiment_phrase_normalized_bool_exp>;
};


export type subscription_rootsentiment_phrase_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<sentiment_phrase_stream_cursor_input>>;
  where?: InputMaybe<sentiment_phrase_bool_exp>;
};


export type subscription_rootsentiment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<sentiment_stream_cursor_input>>;
  where?: InputMaybe<sentiment_bool_exp>;
};


export type subscription_rootstateArgs = {
  distinct_on?: InputMaybe<Array<state_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_order_by>>;
  where?: InputMaybe<state_bool_exp>;
};


export type subscription_rootstate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<state_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_order_by>>;
  where?: InputMaybe<state_bool_exp>;
};


export type subscription_rootstate_by_pkArgs = {
  code: Scalars['citext']['input'];
  country: Scalars['citext']['input'];
};


export type subscription_rootstate_nameArgs = {
  distinct_on?: InputMaybe<Array<state_name_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_name_order_by>>;
  where?: InputMaybe<state_name_bool_exp>;
};


export type subscription_rootstate_name_aggregateArgs = {
  distinct_on?: InputMaybe<Array<state_name_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<state_name_order_by>>;
  where?: InputMaybe<state_name_bool_exp>;
};


export type subscription_rootstate_name_by_pkArgs = {
  country: Scalars['citext']['input'];
  name: Scalars['citext']['input'];
};


export type subscription_rootstate_name_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<state_name_stream_cursor_input>>;
  where?: InputMaybe<state_name_bool_exp>;
};


export type subscription_rootstate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<state_stream_cursor_input>>;
  where?: InputMaybe<state_bool_exp>;
};


export type subscription_rootstripe_appArgs = {
  distinct_on?: InputMaybe<Array<stripe_app_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<stripe_app_order_by>>;
  where?: InputMaybe<stripe_app_bool_exp>;
};


export type subscription_rootstripe_app_aggregateArgs = {
  distinct_on?: InputMaybe<Array<stripe_app_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<stripe_app_order_by>>;
  where?: InputMaybe<stripe_app_bool_exp>;
};


export type subscription_rootstripe_app_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootstripe_app_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<stripe_app_stream_cursor_input>>;
  where?: InputMaybe<stripe_app_bool_exp>;
};


export type subscription_rootsubscriberArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsubscriber_acknowledgementArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


export type subscription_rootsubscriber_acknowledgement_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_acknowledgement_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_acknowledgement_order_by>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


export type subscription_rootsubscriber_acknowledgement_by_pkArgs = {
  acknowledgement_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_acknowledgement_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_acknowledgement_stream_cursor_input>>;
  where?: InputMaybe<subscriber_acknowledgement_bool_exp>;
};


export type subscription_rootsubscriber_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_order_by>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsubscriber_arrArgs = {
  distinct_on?: InputMaybe<Array<subscriber_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_arr_order_by>>;
  where?: InputMaybe<subscriber_arr_bool_exp>;
};


export type subscription_rootsubscriber_arr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_arr_order_by>>;
  where?: InputMaybe<subscriber_arr_bool_exp>;
};


export type subscription_rootsubscriber_arr_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_arr_stream_cursor_input>>;
  where?: InputMaybe<subscriber_arr_bool_exp>;
};


export type subscription_rootsubscriber_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaignArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


export type subscription_rootsubscriber_campaign_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_order_by>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


export type subscription_rootsubscriber_campaign_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


export type subscription_rootsubscriber_campaign_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


export type subscription_rootsubscriber_campaign_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_campaign_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_campaign_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_offer_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_offer_rule_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_campaign_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_offer_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_offer_bool_exp>;
};


export type subscription_rootsubscriber_campaign_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


export type subscription_rootsubscriber_campaign_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_override_order_by>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


export type subscription_rootsubscriber_campaign_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_override_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_override_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_override_bool_exp>;
};


export type subscription_rootsubscriber_campaign_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


export type subscription_rootsubscriber_campaign_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_segment_order_by>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


export type subscription_rootsubscriber_campaign_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_campaign_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_segment_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_segment_bool_exp>;
};


export type subscription_rootsubscriber_campaign_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_campaign_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_status_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_bool_exp>;
};


export type subscription_rootsubscriber_campaign_viewArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


export type subscription_rootsubscriber_campaign_view_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


export type subscription_rootsubscriber_campaign_view_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_campaign_view_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_view_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_campaign_view_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_campaign_view_status_order_by>>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_view_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_campaign_view_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_view_status_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_view_status_bool_exp>;
};


export type subscription_rootsubscriber_campaign_view_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_campaign_view_stream_cursor_input>>;
  where?: InputMaybe<subscriber_campaign_view_bool_exp>;
};


export type subscription_rootsubscriber_created_atArgs = {
  distinct_on?: InputMaybe<Array<subscriber_created_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_created_at_order_by>>;
  where?: InputMaybe<subscriber_created_at_bool_exp>;
};


export type subscription_rootsubscriber_created_at_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_created_at_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_created_at_order_by>>;
  where?: InputMaybe<subscriber_created_at_bool_exp>;
};


export type subscription_rootsubscriber_created_at_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_created_at_stream_cursor_input>>;
  where?: InputMaybe<subscriber_created_at_bool_exp>;
};


export type subscription_rootsubscriber_days_since_savedArgs = {
  distinct_on?: InputMaybe<Array<subscriber_days_since_saved_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_days_since_saved_order_by>>;
  where?: InputMaybe<subscriber_days_since_saved_bool_exp>;
};


export type subscription_rootsubscriber_days_since_saved_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_days_since_saved_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_days_since_saved_order_by>>;
  where?: InputMaybe<subscriber_days_since_saved_bool_exp>;
};


export type subscription_rootsubscriber_days_since_saved_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_days_since_saved_stream_cursor_input>>;
  where?: InputMaybe<subscriber_days_since_saved_bool_exp>;
};


export type subscription_rootsubscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_flow_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_deflectionArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


export type subscription_rootsubscriber_flow_deflection_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_deflection_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_deflection_order_by>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


export type subscription_rootsubscriber_flow_deflection_by_pkArgs = {
  deflection_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_deflection_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_deflection_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_deflection_bool_exp>;
};


export type subscription_rootsubscriber_flow_exportArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_export_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_export_order_by>>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};


export type subscription_rootsubscriber_flow_export_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_export_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_export_order_by>>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};


export type subscription_rootsubscriber_flow_export_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_export_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_export_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_export_bool_exp>;
};


export type subscription_rootsubscriber_flow_flow_stepArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


export type subscription_rootsubscriber_flow_flow_step_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_flow_step_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_flow_step_order_by>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


export type subscription_rootsubscriber_flow_flow_step_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_flow_step_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_flow_step_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_flow_step_bool_exp>;
};


export type subscription_rootsubscriber_flow_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_group_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_offer_group_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_group_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_group_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_group_offer_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_group_offer_by_pkArgs = {
  flow_step_id: Scalars['Int']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_offer_group_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_offer_group_offer_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_offer_group_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_offer_group_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_ruleArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_rule_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_rule_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_rule_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_rule_by_pkArgs = {
  offer_rule_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_offer_rule_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_offer_rule_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_offer_rule_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_status_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_offer_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_offer_status_order_by>>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_flow_offer_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_offer_status_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_offer_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_offer_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_offer_bool_exp>;
};


export type subscription_rootsubscriber_flow_on_cancel_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_on_cancel_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_on_cancel_override_order_by>>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_on_cancel_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_on_cancel_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_on_cancel_override_order_by>>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_on_cancel_override_by_pkArgs = {
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_on_cancel_override_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_on_cancel_override_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_on_cancel_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_overrideArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_override_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_order_by>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_override_by_pkArgs = {
  offer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_override_reasonArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_reason_order_by>>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};


export type subscription_rootsubscriber_flow_override_reason_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_override_reason_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_override_reason_order_by>>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};


export type subscription_rootsubscriber_flow_override_reason_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_flow_override_reason_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_override_reason_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_override_reason_bool_exp>;
};


export type subscription_rootsubscriber_flow_override_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_override_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_override_bool_exp>;
};


export type subscription_rootsubscriber_flow_question_answerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


export type subscription_rootsubscriber_flow_question_answer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_question_answer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_question_answer_order_by>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


export type subscription_rootsubscriber_flow_question_answer_by_pkArgs = {
  question_answer_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_question_answer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_question_answer_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_question_answer_bool_exp>;
};


export type subscription_rootsubscriber_flow_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


export type subscription_rootsubscriber_flow_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


export type subscription_rootsubscriber_flow_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_segment_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_group_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_segment_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_segment_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_segment_group_order_by>>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_segment_group_by_pkArgs = {
  segment_group_id: Scalars['Int']['input'];
  subscriber_flow_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_flow_segment_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_segment_group_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_segment_group_bool_exp>;
};


export type subscription_rootsubscriber_flow_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_segment_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_segment_bool_exp>;
};


export type subscription_rootsubscriber_flow_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_order_by>>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_order_by>>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_status_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_by_month_order_by>>;
  where?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
};


export type subscription_rootsubscriber_flow_status_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_status_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_status_by_month_order_by>>;
  where?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
};


export type subscription_rootsubscriber_flow_status_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_status_by_month_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_status_by_month_bool_exp>;
};


export type subscription_rootsubscriber_flow_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_flow_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_status_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_status_bool_exp>;
};


export type subscription_rootsubscriber_flow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_flow_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_tsvector_order_by>>;
  where?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
};


export type subscription_rootsubscriber_flow_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_tsvector_order_by>>;
  where?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
};


export type subscription_rootsubscriber_flow_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_tsvector_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_tsvector_bool_exp>;
};


export type subscription_rootsubscriber_flow_urlArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_url_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_url_order_by>>;
  where?: InputMaybe<subscriber_flow_url_bool_exp>;
};


export type subscription_rootsubscriber_flow_url_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_url_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_url_order_by>>;
  where?: InputMaybe<subscriber_flow_url_bool_exp>;
};


export type subscription_rootsubscriber_flow_url_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_url_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_url_bool_exp>;
};


export type subscription_rootsubscriber_flow_version_last_60_daysArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_version_last_60_days_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_version_last_60_days_order_by>>;
  where?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
};


export type subscription_rootsubscriber_flow_version_last_60_days_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_flow_version_last_60_days_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_flow_version_last_60_days_order_by>>;
  where?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
};


export type subscription_rootsubscriber_flow_version_last_60_days_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_flow_version_last_60_days_stream_cursor_input>>;
  where?: InputMaybe<subscriber_flow_version_last_60_days_bool_exp>;
};


export type subscription_rootsubscriber_gridArgs = {
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type subscription_rootsubscriber_grid_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_grid_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_grid_order_by>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type subscription_rootsubscriber_grid_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_grid_stream_cursor_input>>;
  where?: InputMaybe<subscriber_grid_bool_exp>;
};


export type subscription_rootsubscriber_logArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type subscription_rootsubscriber_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_order_by>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type subscription_rootsubscriber_log_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_log_eventArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_order_by>>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};


export type subscription_rootsubscriber_log_event_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_order_by>>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};


export type subscription_rootsubscriber_log_event_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_by_month_order_by>>;
  where?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
};


export type subscription_rootsubscriber_log_event_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_event_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_event_by_month_order_by>>;
  where?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
};


export type subscription_rootsubscriber_log_event_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_log_event_by_month_stream_cursor_input>>;
  where?: InputMaybe<subscriber_log_event_by_month_bool_exp>;
};


export type subscription_rootsubscriber_log_event_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_log_event_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_log_event_stream_cursor_input>>;
  where?: InputMaybe<subscriber_log_event_bool_exp>;
};


export type subscription_rootsubscriber_log_offerArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


export type subscription_rootsubscriber_log_offer_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_order_by>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


export type subscription_rootsubscriber_log_offer_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_log_offer_groupArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_group_order_by>>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_log_offer_group_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_log_offer_group_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_log_offer_group_order_by>>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_log_offer_group_by_pkArgs = {
  subscriber_log_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_log_offer_group_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_log_offer_group_stream_cursor_input>>;
  where?: InputMaybe<subscriber_log_offer_group_bool_exp>;
};


export type subscription_rootsubscriber_log_offer_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_log_offer_stream_cursor_input>>;
  where?: InputMaybe<subscriber_log_offer_bool_exp>;
};


export type subscription_rootsubscriber_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_log_stream_cursor_input>>;
  where?: InputMaybe<subscriber_log_bool_exp>;
};


export type subscription_rootsubscriber_ltvArgs = {
  distinct_on?: InputMaybe<Array<subscriber_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_ltv_order_by>>;
  where?: InputMaybe<subscriber_ltv_bool_exp>;
};


export type subscription_rootsubscriber_ltv_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_ltv_order_by>>;
  where?: InputMaybe<subscriber_ltv_bool_exp>;
};


export type subscription_rootsubscriber_ltv_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_ltv_stream_cursor_input>>;
  where?: InputMaybe<subscriber_ltv_bool_exp>;
};


export type subscription_rootsubscriber_most_recent_subscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_most_recent_subscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_most_recent_subscriber_flow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_most_recent_subscriber_flow_stream_cursor_input>>;
  where?: InputMaybe<subscriber_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscriber_mrrArgs = {
  distinct_on?: InputMaybe<Array<subscriber_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_mrr_order_by>>;
  where?: InputMaybe<subscriber_mrr_bool_exp>;
};


export type subscription_rootsubscriber_mrr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_mrr_order_by>>;
  where?: InputMaybe<subscriber_mrr_bool_exp>;
};


export type subscription_rootsubscriber_mrr_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_mrr_stream_cursor_input>>;
  where?: InputMaybe<subscriber_mrr_bool_exp>;
};


export type subscription_rootsubscriber_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


export type subscription_rootsubscriber_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_order_by>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


export type subscription_rootsubscriber_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscriber_id: Scalars['Int']['input'];
};


export type subscription_rootsubscriber_property_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_property_stream_cursor_input>>;
  where?: InputMaybe<subscriber_property_bool_exp>;
};


export type subscription_rootsubscriber_property_valueArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


export type subscription_rootsubscriber_property_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_property_value_order_by>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


export type subscription_rootsubscriber_property_value_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_property_value_stream_cursor_input>>;
  where?: InputMaybe<subscriber_property_value_bool_exp>;
};


export type subscription_rootsubscriber_statusArgs = {
  distinct_on?: InputMaybe<Array<subscriber_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_status_order_by>>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};


export type subscription_rootsubscriber_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_status_order_by>>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};


export type subscription_rootsubscriber_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscriber_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_status_stream_cursor_input>>;
  where?: InputMaybe<subscriber_status_bool_exp>;
};


export type subscription_rootsubscriber_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_stream_cursor_input>>;
  where?: InputMaybe<subscriber_bool_exp>;
};


export type subscription_rootsubscriber_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<subscriber_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_tsvector_order_by>>;
  where?: InputMaybe<subscriber_tsvector_bool_exp>;
};


export type subscription_rootsubscriber_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscriber_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscriber_tsvector_order_by>>;
  where?: InputMaybe<subscriber_tsvector_bool_exp>;
};


export type subscription_rootsubscriber_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscriber_tsvector_stream_cursor_input>>;
  where?: InputMaybe<subscriber_tsvector_bool_exp>;
};


export type subscription_rootsubscriptionArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type subscription_rootsubscription_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type subscription_rootsubscription_amount_paid_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscription_amount_paid_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_amount_paid_by_month_order_by>>;
  where?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
};


export type subscription_rootsubscription_amount_paid_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_amount_paid_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_amount_paid_by_month_order_by>>;
  where?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
};


export type subscription_rootsubscription_amount_paid_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_amount_paid_by_month_stream_cursor_input>>;
  where?: InputMaybe<subscription_amount_paid_by_month_bool_exp>;
};


export type subscription_rootsubscription_arrArgs = {
  distinct_on?: InputMaybe<Array<subscription_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_arr_order_by>>;
  where?: InputMaybe<subscription_arr_bool_exp>;
};


export type subscription_rootsubscription_arr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_arr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_arr_order_by>>;
  where?: InputMaybe<subscription_arr_bool_exp>;
};


export type subscription_rootsubscription_arr_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_arr_stream_cursor_input>>;
  where?: InputMaybe<subscription_arr_bool_exp>;
};


export type subscription_rootsubscription_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsubscription_durationArgs = {
  distinct_on?: InputMaybe<Array<subscription_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_duration_order_by>>;
  where?: InputMaybe<subscription_duration_bool_exp>;
};


export type subscription_rootsubscription_duration_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_duration_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_duration_order_by>>;
  where?: InputMaybe<subscription_duration_bool_exp>;
};


export type subscription_rootsubscription_duration_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_duration_stream_cursor_input>>;
  where?: InputMaybe<subscription_duration_bool_exp>;
};


export type subscription_rootsubscription_ltvArgs = {
  distinct_on?: InputMaybe<Array<subscription_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_ltv_order_by>>;
  where?: InputMaybe<subscription_ltv_bool_exp>;
};


export type subscription_rootsubscription_ltv_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_ltv_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_ltv_order_by>>;
  where?: InputMaybe<subscription_ltv_bool_exp>;
};


export type subscription_rootsubscription_ltv_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_ltv_stream_cursor_input>>;
  where?: InputMaybe<subscription_ltv_bool_exp>;
};


export type subscription_rootsubscription_most_recent_subscriber_flowArgs = {
  distinct_on?: InputMaybe<Array<subscription_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscription_most_recent_subscriber_flow_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_most_recent_subscriber_flow_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_most_recent_subscriber_flow_order_by>>;
  where?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscription_most_recent_subscriber_flow_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_most_recent_subscriber_flow_stream_cursor_input>>;
  where?: InputMaybe<subscription_most_recent_subscriber_flow_bool_exp>;
};


export type subscription_rootsubscription_productArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


export type subscription_rootsubscription_product_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_product_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_product_order_by>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


export type subscription_rootsubscription_product_by_pkArgs = {
  product_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type subscription_rootsubscription_product_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_product_stream_cursor_input>>;
  where?: InputMaybe<subscription_product_bool_exp>;
};


export type subscription_rootsubscription_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


export type subscription_rootsubscription_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_order_by>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


export type subscription_rootsubscription_property_by_pkArgs = {
  property_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type subscription_rootsubscription_property_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_property_stream_cursor_input>>;
  where?: InputMaybe<subscription_property_bool_exp>;
};


export type subscription_rootsubscription_property_valueArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


export type subscription_rootsubscription_property_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_property_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_property_value_order_by>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


export type subscription_rootsubscription_property_value_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_property_value_stream_cursor_input>>;
  where?: InputMaybe<subscription_property_value_bool_exp>;
};


export type subscription_rootsubscription_revenue_by_monthArgs = {
  distinct_on?: InputMaybe<Array<subscription_revenue_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_revenue_by_month_order_by>>;
  where?: InputMaybe<subscription_revenue_by_month_bool_exp>;
};


export type subscription_rootsubscription_revenue_by_month_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_revenue_by_month_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_revenue_by_month_order_by>>;
  where?: InputMaybe<subscription_revenue_by_month_bool_exp>;
};


export type subscription_rootsubscription_revenue_by_month_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_revenue_by_month_stream_cursor_input>>;
  where?: InputMaybe<subscription_revenue_by_month_bool_exp>;
};


export type subscription_rootsubscription_segmentArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_order_by>>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};


export type subscription_rootsubscription_segment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_order_by>>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};


export type subscription_rootsubscription_segment_by_pkArgs = {
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};


export type subscription_rootsubscription_segment_propertyArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_property_order_by>>;
  where?: InputMaybe<subscription_segment_property_bool_exp>;
};


export type subscription_rootsubscription_segment_property_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_property_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_property_order_by>>;
  where?: InputMaybe<subscription_segment_property_bool_exp>;
};


export type subscription_rootsubscription_segment_property_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_segment_property_stream_cursor_input>>;
  where?: InputMaybe<subscription_segment_property_bool_exp>;
};


export type subscription_rootsubscription_segment_statusArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_status_order_by>>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};


export type subscription_rootsubscription_segment_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_segment_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_segment_status_order_by>>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};


export type subscription_rootsubscription_segment_status_by_pkArgs = {
  subscription_id: Scalars['Int']['input'];
};


export type subscription_rootsubscription_segment_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_segment_status_stream_cursor_input>>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};


export type subscription_rootsubscription_segment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_segment_stream_cursor_input>>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};


export type subscription_rootsubscription_statusArgs = {
  distinct_on?: InputMaybe<Array<subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_status_order_by>>;
  where?: InputMaybe<subscription_status_bool_exp>;
};


export type subscription_rootsubscription_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_status_order_by>>;
  where?: InputMaybe<subscription_status_bool_exp>;
};


export type subscription_rootsubscription_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootsubscription_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_status_stream_cursor_input>>;
  where?: InputMaybe<subscription_status_bool_exp>;
};


export type subscription_rootsubscription_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<subscription_stream_cursor_input>>;
  where?: InputMaybe<subscription_bool_exp>;
};


export type subscription_rootsurveyArgs = {
  distinct_on?: InputMaybe<Array<survey_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<survey_order_by>>;
  where?: InputMaybe<survey_bool_exp>;
};


export type subscription_rootsurvey_aggregateArgs = {
  distinct_on?: InputMaybe<Array<survey_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<survey_order_by>>;
  where?: InputMaybe<survey_bool_exp>;
};


export type subscription_rootsurvey_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootsurvey_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<survey_stream_cursor_input>>;
  where?: InputMaybe<survey_bool_exp>;
};


export type subscription_roottagArgs = {
  distinct_on?: InputMaybe<Array<tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<tag_order_by>>;
  where?: InputMaybe<tag_bool_exp>;
};


export type subscription_roottag_aggregateArgs = {
  distinct_on?: InputMaybe<Array<tag_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<tag_order_by>>;
  where?: InputMaybe<tag_bool_exp>;
};


export type subscription_roottag_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_roottag_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<tag_stream_cursor_input>>;
  where?: InputMaybe<tag_bool_exp>;
};


export type subscription_roottemplate_report_deflection_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type subscription_roottemplate_report_deflection_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_deflection_trends_order_by>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type subscription_roottemplate_report_deflection_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  flow_action_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_deflection_trends_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_deflection_trends_stream_cursor_input>>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};


export type subscription_roottemplate_report_feedback_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type subscription_roottemplate_report_feedback_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_feedback_trends_order_by>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type subscription_roottemplate_report_feedback_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  sentiment_phrase_normalized_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_feedback_trends_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_feedback_trends_stream_cursor_input>>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};


export type subscription_roottemplate_report_offer_accept_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_accept_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_offer_accept_rate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_accept_rate_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_accept_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_accept_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_accept_rate_order_by>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_accept_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_offer_group_accept_rate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_group_accept_rate_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_group_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_offer_group_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_offer_group_retained_revenue_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_group_retained_revenue_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_offer_rateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_rate_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_rate_order_by>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_rate_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_offer_rate_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_rate_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};


export type subscription_roottemplate_report_offer_resolutionArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type subscription_roottemplate_report_offer_resolution_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_resolution_order_by>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type subscription_roottemplate_report_offer_resolution_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_offer_resolution_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_resolution_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};


export type subscription_roottemplate_report_offer_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_offer_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_offer_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_offer_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_offer_retained_revenue_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_offer_retained_revenue_stream_cursor_input>>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radioArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radio_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radio_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_question_answer_radio_inactive_mrrArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radio_inactive_mrr_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_order_by>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radio_inactive_mrr_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};


export type subscription_roottemplate_report_question_answer_radio_inactive_mrr_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_question_answer_radio_inactive_mrr_stream_cursor_input>>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_radio_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_question_answer_radio_stream_cursor_input>>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_sentimentArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_sentiment_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_question_answer_sentiment_order_by>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type subscription_roottemplate_report_question_answer_sentiment_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_question_answer_sentiment_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_question_answer_sentiment_stream_cursor_input>>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};


export type subscription_roottemplate_report_retained_revenueArgs = {
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_retained_revenue_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retained_revenue_order_by>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_retained_revenue_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_retained_revenue_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_retained_revenue_stream_cursor_input>>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};


export type subscription_roottemplate_report_retention_trendsArgs = {
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type subscription_roottemplate_report_retention_trends_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_retention_trends_order_by>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type subscription_roottemplate_report_retention_trends_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_retention_trends_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_retention_trends_stream_cursor_input>>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};


export type subscription_roottemplate_report_subscriber_flow_statusArgs = {
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type subscription_roottemplate_report_subscriber_flow_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<template_report_subscriber_flow_status_order_by>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type subscription_roottemplate_report_subscriber_flow_status_by_pkArgs = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};


export type subscription_roottemplate_report_subscriber_flow_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<template_report_subscriber_flow_status_stream_cursor_input>>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};


export type subscription_roottranslationArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


export type subscription_roottranslation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_order_by>>;
  where?: InputMaybe<translation_bool_exp>;
};


export type subscription_roottranslation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_roottranslation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<translation_stream_cursor_input>>;
  where?: InputMaybe<translation_bool_exp>;
};


export type subscription_roottranslation_valueArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


export type subscription_roottranslation_value_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


export type subscription_roottranslation_value_by_pkArgs = {
  language: language_enum;
  translation_id: Scalars['Int']['input'];
};


export type subscription_roottranslation_value_formatArgs = {
  distinct_on?: InputMaybe<Array<translation_value_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_format_order_by>>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};


export type subscription_roottranslation_value_format_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_value_format_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_format_order_by>>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};


export type subscription_roottranslation_value_format_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_roottranslation_value_format_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<translation_value_format_stream_cursor_input>>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};


export type subscription_roottranslation_value_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<translation_value_stream_cursor_input>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


export type subscription_rootuserArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};


export type subscription_rootuser_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_order_by>>;
  where?: InputMaybe<user_bool_exp>;
};


export type subscription_rootuser_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type subscription_rootuser_invitationArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


export type subscription_rootuser_invitation_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


export type subscription_rootuser_invitation_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootuser_invitation_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<user_invitation_stream_cursor_input>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


export type subscription_rootuser_profileArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


export type subscription_rootuser_profile_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


export type subscription_rootuser_profile_by_pkArgs = {
  id: Scalars['String']['input'];
};


export type subscription_rootuser_profile_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<user_profile_stream_cursor_input>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


export type subscription_rootuser_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<user_stream_cursor_input>>;
  where?: InputMaybe<user_bool_exp>;
};


export type subscription_rootwebhookArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};


export type subscription_rootwebhook_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_order_by>>;
  where?: InputMaybe<webhook_bool_exp>;
};


export type subscription_rootwebhook_by_pkArgs = {
  id: Scalars['Int']['input'];
};


export type subscription_rootwebhook_eventArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


export type subscription_rootwebhook_event_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


export type subscription_rootwebhook_event_by_pkArgs = {
  event_type: event_type_enum;
  webhook_id: Scalars['Int']['input'];
};


export type subscription_rootwebhook_event_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_event_stream_cursor_input>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


export type subscription_rootwebhook_logArgs = {
  distinct_on?: InputMaybe<Array<webhook_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_log_order_by>>;
  where?: InputMaybe<webhook_log_bool_exp>;
};


export type subscription_rootwebhook_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_log_order_by>>;
  where?: InputMaybe<webhook_log_bool_exp>;
};


export type subscription_rootwebhook_log_by_pkArgs = {
  attempt: Scalars['Int']['input'];
  event_id: Scalars['Int']['input'];
  webhook_id: Scalars['Int']['input'];
};


export type subscription_rootwebhook_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_log_stream_cursor_input>>;
  where?: InputMaybe<webhook_log_bool_exp>;
};


export type subscription_rootwebhook_request_logArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type subscription_rootwebhook_request_log_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_order_by>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type subscription_rootwebhook_request_log_by_pkArgs = {
  id: Scalars['bigint']['input'];
};


export type subscription_rootwebhook_request_log_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_request_log_stream_cursor_input>>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};


export type subscription_rootwebhook_request_log_tsvectorArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_tsvector_order_by>>;
  where?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
};


export type subscription_rootwebhook_request_log_tsvector_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_request_log_tsvector_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_request_log_tsvector_order_by>>;
  where?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
};


export type subscription_rootwebhook_request_log_tsvector_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_request_log_tsvector_stream_cursor_input>>;
  where?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
};


export type subscription_rootwebhook_statusArgs = {
  distinct_on?: InputMaybe<Array<webhook_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_status_order_by>>;
  where?: InputMaybe<webhook_status_bool_exp>;
};


export type subscription_rootwebhook_status_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_status_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_status_order_by>>;
  where?: InputMaybe<webhook_status_bool_exp>;
};


export type subscription_rootwebhook_status_by_pkArgs = {
  value: Scalars['String']['input'];
};


export type subscription_rootwebhook_status_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_status_stream_cursor_input>>;
  where?: InputMaybe<webhook_status_bool_exp>;
};


export type subscription_rootwebhook_streamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<webhook_stream_cursor_input>>;
  where?: InputMaybe<webhook_bool_exp>;
};

/** columns and relationships of "subscription_segment" */
export type subscription_segment = {
  __typename: 'subscription_segment';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  segment: segment;
  segment_id: Scalars['Int']['output'];
  /** An object relationship */
  subscription: subscription;
  subscription_id: Scalars['Int']['output'];
};

/** aggregated selection of "subscription_segment" */
export type subscription_segment_aggregate = {
  __typename: 'subscription_segment_aggregate';
  aggregate?: Maybe<subscription_segment_aggregate_fields>;
  nodes: Array<subscription_segment>;
};

/** aggregate fields of "subscription_segment" */
export type subscription_segment_aggregate_fields = {
  __typename: 'subscription_segment_aggregate_fields';
  avg?: Maybe<subscription_segment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_segment_max_fields>;
  min?: Maybe<subscription_segment_min_fields>;
  stddev?: Maybe<subscription_segment_stddev_fields>;
  stddev_pop?: Maybe<subscription_segment_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_segment_stddev_samp_fields>;
  sum?: Maybe<subscription_segment_sum_fields>;
  var_pop?: Maybe<subscription_segment_var_pop_fields>;
  var_samp?: Maybe<subscription_segment_var_samp_fields>;
  variance?: Maybe<subscription_segment_variance_fields>;
};


/** aggregate fields of "subscription_segment" */
export type subscription_segment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_segment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_segment_avg_fields = {
  __typename: 'subscription_segment_avg_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_segment". All fields are combined with a logical 'AND'. */
export type subscription_segment_bool_exp = {
  _and?: InputMaybe<Array<subscription_segment_bool_exp>>;
  _not?: InputMaybe<subscription_segment_bool_exp>;
  _or?: InputMaybe<Array<subscription_segment_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  segment?: InputMaybe<segment_bool_exp>;
  segment_id?: InputMaybe<Int_comparison_exp>;
  subscription?: InputMaybe<subscription_bool_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "subscription_segment" */
export enum subscription_segment_constraint {
  /** unique or primary key constraint on columns "subscription_id", "segment_id" */
  subscription_segment_pkey = 'subscription_segment_pkey'
}

/** input type for incrementing numeric columns in table "subscription_segment" */
export type subscription_segment_inc_input = {
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription_segment" */
export type subscription_segment_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment?: InputMaybe<segment_obj_rel_insert_input>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription?: InputMaybe<subscription_obj_rel_insert_input>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type subscription_segment_max_fields = {
  __typename: 'subscription_segment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type subscription_segment_min_fields = {
  __typename: 'subscription_segment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "subscription_segment" */
export type subscription_segment_mutation_response = {
  __typename: 'subscription_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_segment>;
};

/** on_conflict condition type for table "subscription_segment" */
export type subscription_segment_on_conflict = {
  constraint: subscription_segment_constraint;
  update_columns?: Array<subscription_segment_update_column>;
  where?: InputMaybe<subscription_segment_bool_exp>;
};

/** Ordering options when selecting data from "subscription_segment". */
export type subscription_segment_order_by = {
  created_at?: InputMaybe<order_by>;
  segment?: InputMaybe<segment_order_by>;
  segment_id?: InputMaybe<order_by>;
  subscription?: InputMaybe<subscription_order_by>;
  subscription_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription_segment */
export type subscription_segment_pk_columns_input = {
  segment_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};

/** columns and relationships of "subscription_segment_property" */
export type subscription_segment_property = {
  __typename: 'subscription_segment_property';
  account_id?: Maybe<Scalars['Int']['output']>;
  addon_id?: Maybe<Scalars['String']['output']>;
  addon_name?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['String']['output']>;
  plan_interval?: Maybe<Scalars['String']['output']>;
  plan_interval_count?: Maybe<Scalars['Int']['output']>;
  plan_name?: Maybe<Scalars['String']['output']>;
  plan_quantity?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_name?: Maybe<Scalars['String']['output']>;
  subscriber_coupon_id?: Maybe<Scalars['String']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscriber_mrr?: Maybe<Scalars['numeric']['output']>;
  subscriber_offer_id?: Maybe<Scalars['String']['output']>;
  subscriber_status?: Maybe<Scalars['String']['output']>;
  subscription_coupon_id?: Maybe<Scalars['String']['output']>;
  subscription_duration?: Maybe<Scalars['Int']['output']>;
  subscription_free_trial?: Maybe<Scalars['Boolean']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  subscription_mrr?: Maybe<Scalars['numeric']['output']>;
  subscription_past_due?: Maybe<Scalars['Boolean']['output']>;
  subscription_status?: Maybe<Scalars['String']['output']>;
  subscription_will_renew?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "subscription_segment_property" */
export type subscription_segment_property_aggregate = {
  __typename: 'subscription_segment_property_aggregate';
  aggregate?: Maybe<subscription_segment_property_aggregate_fields>;
  nodes: Array<subscription_segment_property>;
};

/** aggregate fields of "subscription_segment_property" */
export type subscription_segment_property_aggregate_fields = {
  __typename: 'subscription_segment_property_aggregate_fields';
  avg?: Maybe<subscription_segment_property_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_segment_property_max_fields>;
  min?: Maybe<subscription_segment_property_min_fields>;
  stddev?: Maybe<subscription_segment_property_stddev_fields>;
  stddev_pop?: Maybe<subscription_segment_property_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_segment_property_stddev_samp_fields>;
  sum?: Maybe<subscription_segment_property_sum_fields>;
  var_pop?: Maybe<subscription_segment_property_var_pop_fields>;
  var_samp?: Maybe<subscription_segment_property_var_samp_fields>;
  variance?: Maybe<subscription_segment_property_variance_fields>;
};


/** aggregate fields of "subscription_segment_property" */
export type subscription_segment_property_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_segment_property_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_segment_property_avg_fields = {
  __typename: 'subscription_segment_property_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_segment_property". All fields are combined with a logical 'AND'. */
export type subscription_segment_property_bool_exp = {
  _and?: InputMaybe<Array<subscription_segment_property_bool_exp>>;
  _not?: InputMaybe<subscription_segment_property_bool_exp>;
  _or?: InputMaybe<Array<subscription_segment_property_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  addon_id?: InputMaybe<String_comparison_exp>;
  addon_name?: InputMaybe<String_comparison_exp>;
  plan_id?: InputMaybe<String_comparison_exp>;
  plan_interval?: InputMaybe<String_comparison_exp>;
  plan_interval_count?: InputMaybe<Int_comparison_exp>;
  plan_name?: InputMaybe<String_comparison_exp>;
  plan_quantity?: InputMaybe<numeric_comparison_exp>;
  product_id?: InputMaybe<String_comparison_exp>;
  product_name?: InputMaybe<String_comparison_exp>;
  subscriber_coupon_id?: InputMaybe<String_comparison_exp>;
  subscriber_days_since_saved?: InputMaybe<Int_comparison_exp>;
  subscriber_id?: InputMaybe<Int_comparison_exp>;
  subscriber_mrr?: InputMaybe<numeric_comparison_exp>;
  subscriber_offer_id?: InputMaybe<String_comparison_exp>;
  subscriber_status?: InputMaybe<String_comparison_exp>;
  subscription_coupon_id?: InputMaybe<String_comparison_exp>;
  subscription_duration?: InputMaybe<Int_comparison_exp>;
  subscription_free_trial?: InputMaybe<Boolean_comparison_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  subscription_mrr?: InputMaybe<numeric_comparison_exp>;
  subscription_past_due?: InputMaybe<Boolean_comparison_exp>;
  subscription_status?: InputMaybe<String_comparison_exp>;
  subscription_will_renew?: InputMaybe<Boolean_comparison_exp>;
};

/** aggregate max on columns */
export type subscription_segment_property_max_fields = {
  __typename: 'subscription_segment_property_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  addon_id?: Maybe<Scalars['String']['output']>;
  addon_name?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['String']['output']>;
  plan_interval?: Maybe<Scalars['String']['output']>;
  plan_interval_count?: Maybe<Scalars['Int']['output']>;
  plan_name?: Maybe<Scalars['String']['output']>;
  plan_quantity?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_name?: Maybe<Scalars['String']['output']>;
  subscriber_coupon_id?: Maybe<Scalars['String']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscriber_mrr?: Maybe<Scalars['numeric']['output']>;
  subscriber_offer_id?: Maybe<Scalars['String']['output']>;
  subscriber_status?: Maybe<Scalars['String']['output']>;
  subscription_coupon_id?: Maybe<Scalars['String']['output']>;
  subscription_duration?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  subscription_mrr?: Maybe<Scalars['numeric']['output']>;
  subscription_status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscription_segment_property_min_fields = {
  __typename: 'subscription_segment_property_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  addon_id?: Maybe<Scalars['String']['output']>;
  addon_name?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['String']['output']>;
  plan_interval?: Maybe<Scalars['String']['output']>;
  plan_interval_count?: Maybe<Scalars['Int']['output']>;
  plan_name?: Maybe<Scalars['String']['output']>;
  plan_quantity?: Maybe<Scalars['numeric']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  product_name?: Maybe<Scalars['String']['output']>;
  subscriber_coupon_id?: Maybe<Scalars['String']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscriber_mrr?: Maybe<Scalars['numeric']['output']>;
  subscriber_offer_id?: Maybe<Scalars['String']['output']>;
  subscriber_status?: Maybe<Scalars['String']['output']>;
  subscription_coupon_id?: Maybe<Scalars['String']['output']>;
  subscription_duration?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  subscription_mrr?: Maybe<Scalars['numeric']['output']>;
  subscription_status?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "subscription_segment_property". */
export type subscription_segment_property_order_by = {
  account_id?: InputMaybe<order_by>;
  addon_id?: InputMaybe<order_by>;
  addon_name?: InputMaybe<order_by>;
  plan_id?: InputMaybe<order_by>;
  plan_interval?: InputMaybe<order_by>;
  plan_interval_count?: InputMaybe<order_by>;
  plan_name?: InputMaybe<order_by>;
  plan_quantity?: InputMaybe<order_by>;
  product_id?: InputMaybe<order_by>;
  product_name?: InputMaybe<order_by>;
  subscriber_coupon_id?: InputMaybe<order_by>;
  subscriber_days_since_saved?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
  subscriber_mrr?: InputMaybe<order_by>;
  subscriber_offer_id?: InputMaybe<order_by>;
  subscriber_status?: InputMaybe<order_by>;
  subscription_coupon_id?: InputMaybe<order_by>;
  subscription_duration?: InputMaybe<order_by>;
  subscription_free_trial?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  subscription_mrr?: InputMaybe<order_by>;
  subscription_past_due?: InputMaybe<order_by>;
  subscription_status?: InputMaybe<order_by>;
  subscription_will_renew?: InputMaybe<order_by>;
};

/** select columns of table "subscription_segment_property" */
export enum subscription_segment_property_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  addon_id = 'addon_id',
  /** column name */
  addon_name = 'addon_name',
  /** column name */
  plan_id = 'plan_id',
  /** column name */
  plan_interval = 'plan_interval',
  /** column name */
  plan_interval_count = 'plan_interval_count',
  /** column name */
  plan_name = 'plan_name',
  /** column name */
  plan_quantity = 'plan_quantity',
  /** column name */
  product_id = 'product_id',
  /** column name */
  product_name = 'product_name',
  /** column name */
  subscriber_coupon_id = 'subscriber_coupon_id',
  /** column name */
  subscriber_days_since_saved = 'subscriber_days_since_saved',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  subscriber_mrr = 'subscriber_mrr',
  /** column name */
  subscriber_offer_id = 'subscriber_offer_id',
  /** column name */
  subscriber_status = 'subscriber_status',
  /** column name */
  subscription_coupon_id = 'subscription_coupon_id',
  /** column name */
  subscription_duration = 'subscription_duration',
  /** column name */
  subscription_free_trial = 'subscription_free_trial',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  subscription_mrr = 'subscription_mrr',
  /** column name */
  subscription_past_due = 'subscription_past_due',
  /** column name */
  subscription_status = 'subscription_status',
  /** column name */
  subscription_will_renew = 'subscription_will_renew'
}

/** aggregate stddev on columns */
export type subscription_segment_property_stddev_fields = {
  __typename: 'subscription_segment_property_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_segment_property_stddev_pop_fields = {
  __typename: 'subscription_segment_property_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_segment_property_stddev_samp_fields = {
  __typename: 'subscription_segment_property_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_segment_property" */
export type subscription_segment_property_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_segment_property_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_segment_property_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  addon_id?: InputMaybe<Scalars['String']['input']>;
  addon_name?: InputMaybe<Scalars['String']['input']>;
  plan_id?: InputMaybe<Scalars['String']['input']>;
  plan_interval?: InputMaybe<Scalars['String']['input']>;
  plan_interval_count?: InputMaybe<Scalars['Int']['input']>;
  plan_name?: InputMaybe<Scalars['String']['input']>;
  plan_quantity?: InputMaybe<Scalars['numeric']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  product_name?: InputMaybe<Scalars['String']['input']>;
  subscriber_coupon_id?: InputMaybe<Scalars['String']['input']>;
  subscriber_days_since_saved?: InputMaybe<Scalars['Int']['input']>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  subscriber_mrr?: InputMaybe<Scalars['numeric']['input']>;
  subscriber_offer_id?: InputMaybe<Scalars['String']['input']>;
  subscriber_status?: InputMaybe<Scalars['String']['input']>;
  subscription_coupon_id?: InputMaybe<Scalars['String']['input']>;
  subscription_duration?: InputMaybe<Scalars['Int']['input']>;
  subscription_free_trial?: InputMaybe<Scalars['Boolean']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_mrr?: InputMaybe<Scalars['numeric']['input']>;
  subscription_past_due?: InputMaybe<Scalars['Boolean']['input']>;
  subscription_status?: InputMaybe<Scalars['String']['input']>;
  subscription_will_renew?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type subscription_segment_property_sum_fields = {
  __typename: 'subscription_segment_property_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  plan_interval_count?: Maybe<Scalars['Int']['output']>;
  plan_quantity?: Maybe<Scalars['numeric']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
  subscriber_mrr?: Maybe<Scalars['numeric']['output']>;
  subscription_duration?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  subscription_mrr?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type subscription_segment_property_var_pop_fields = {
  __typename: 'subscription_segment_property_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_segment_property_var_samp_fields = {
  __typename: 'subscription_segment_property_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_segment_property_variance_fields = {
  __typename: 'subscription_segment_property_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  plan_interval_count?: Maybe<Scalars['Float']['output']>;
  plan_quantity?: Maybe<Scalars['Float']['output']>;
  subscriber_days_since_saved?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
  subscriber_mrr?: Maybe<Scalars['Float']['output']>;
  subscription_duration?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
  subscription_mrr?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "subscription_segment" */
export enum subscription_segment_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscription_id = 'subscription_id'
}

/** input type for updating data in table "subscription_segment" */
export type subscription_segment_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "subscription_segment_status" */
export type subscription_segment_status = {
  __typename: 'subscription_segment_status';
  created_at: Scalars['timestamptz']['output'];
  subscription_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "subscription_segment_status" */
export type subscription_segment_status_aggregate = {
  __typename: 'subscription_segment_status_aggregate';
  aggregate?: Maybe<subscription_segment_status_aggregate_fields>;
  nodes: Array<subscription_segment_status>;
};

/** aggregate fields of "subscription_segment_status" */
export type subscription_segment_status_aggregate_fields = {
  __typename: 'subscription_segment_status_aggregate_fields';
  avg?: Maybe<subscription_segment_status_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_segment_status_max_fields>;
  min?: Maybe<subscription_segment_status_min_fields>;
  stddev?: Maybe<subscription_segment_status_stddev_fields>;
  stddev_pop?: Maybe<subscription_segment_status_stddev_pop_fields>;
  stddev_samp?: Maybe<subscription_segment_status_stddev_samp_fields>;
  sum?: Maybe<subscription_segment_status_sum_fields>;
  var_pop?: Maybe<subscription_segment_status_var_pop_fields>;
  var_samp?: Maybe<subscription_segment_status_var_samp_fields>;
  variance?: Maybe<subscription_segment_status_variance_fields>;
};


/** aggregate fields of "subscription_segment_status" */
export type subscription_segment_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_segment_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type subscription_segment_status_avg_fields = {
  __typename: 'subscription_segment_status_avg_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "subscription_segment_status". All fields are combined with a logical 'AND'. */
export type subscription_segment_status_bool_exp = {
  _and?: InputMaybe<Array<subscription_segment_status_bool_exp>>;
  _not?: InputMaybe<subscription_segment_status_bool_exp>;
  _or?: InputMaybe<Array<subscription_segment_status_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  subscription_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "subscription_segment_status" */
export enum subscription_segment_status_constraint {
  /** unique or primary key constraint on columns "subscription_id" */
  subscription_segment_status_pkey = 'subscription_segment_status_pkey'
}

/** input type for incrementing numeric columns in table "subscription_segment_status" */
export type subscription_segment_status_inc_input = {
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "subscription_segment_status" */
export type subscription_segment_status_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type subscription_segment_status_max_fields = {
  __typename: 'subscription_segment_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type subscription_segment_status_min_fields = {
  __typename: 'subscription_segment_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "subscription_segment_status" */
export type subscription_segment_status_mutation_response = {
  __typename: 'subscription_segment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_segment_status>;
};

/** on_conflict condition type for table "subscription_segment_status" */
export type subscription_segment_status_on_conflict = {
  constraint: subscription_segment_status_constraint;
  update_columns?: Array<subscription_segment_status_update_column>;
  where?: InputMaybe<subscription_segment_status_bool_exp>;
};

/** Ordering options when selecting data from "subscription_segment_status". */
export type subscription_segment_status_order_by = {
  created_at?: InputMaybe<order_by>;
  subscription_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription_segment_status */
export type subscription_segment_status_pk_columns_input = {
  subscription_id: Scalars['Int']['input'];
};

/** select columns of table "subscription_segment_status" */
export enum subscription_segment_status_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscription_segment_status" */
export type subscription_segment_status_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type subscription_segment_status_stddev_fields = {
  __typename: 'subscription_segment_status_stddev_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_segment_status_stddev_pop_fields = {
  __typename: 'subscription_segment_status_stddev_pop_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_segment_status_stddev_samp_fields = {
  __typename: 'subscription_segment_status_stddev_samp_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_segment_status" */
export type subscription_segment_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_segment_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_segment_status_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscription_segment_status_sum_fields = {
  __typename: 'subscription_segment_status_sum_fields';
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscription_segment_status" */
export enum subscription_segment_status_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  subscription_id = 'subscription_id',
  /** column name */
  updated_at = 'updated_at'
}

export type subscription_segment_status_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_segment_status_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_segment_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_segment_status_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_segment_status_var_pop_fields = {
  __typename: 'subscription_segment_status_var_pop_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_segment_status_var_samp_fields = {
  __typename: 'subscription_segment_status_var_samp_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_segment_status_variance_fields = {
  __typename: 'subscription_segment_status_variance_fields';
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type subscription_segment_stddev_fields = {
  __typename: 'subscription_segment_stddev_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type subscription_segment_stddev_pop_fields = {
  __typename: 'subscription_segment_stddev_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type subscription_segment_stddev_samp_fields = {
  __typename: 'subscription_segment_stddev_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "subscription_segment" */
export type subscription_segment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_segment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_segment_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  segment_id?: InputMaybe<Scalars['Int']['input']>;
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type subscription_segment_sum_fields = {
  __typename: 'subscription_segment_sum_fields';
  segment_id?: Maybe<Scalars['Int']['output']>;
  subscription_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "subscription_segment" */
export enum subscription_segment_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  segment_id = 'segment_id',
  /** column name */
  subscription_id = 'subscription_id'
}

export type subscription_segment_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_segment_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_segment_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_segment_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_segment_var_pop_fields = {
  __typename: 'subscription_segment_var_pop_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type subscription_segment_var_samp_fields = {
  __typename: 'subscription_segment_var_samp_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type subscription_segment_variance_fields = {
  __typename: 'subscription_segment_variance_fields';
  segment_id?: Maybe<Scalars['Float']['output']>;
  subscription_id?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "subscription" */
export enum subscription_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  base_currency = 'base_currency',
  /** column name */
  base_mrr = 'base_mrr',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  mrr = 'mrr',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "subscription" */
export type subscription_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_currency?: InputMaybe<Scalars['String']['input']>;
  base_mrr?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<subscription_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "subscription_status" */
export type subscription_status = {
  __typename: 'subscription_status';
  /** An array relationship */
  subscriptions: Array<subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: subscription_aggregate;
  value: Scalars['String']['output'];
};


/** columns and relationships of "subscription_status" */
export type subscription_statussubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};


/** columns and relationships of "subscription_status" */
export type subscription_statussubscriptions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<subscription_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<subscription_order_by>>;
  where?: InputMaybe<subscription_bool_exp>;
};

/** aggregated selection of "subscription_status" */
export type subscription_status_aggregate = {
  __typename: 'subscription_status_aggregate';
  aggregate?: Maybe<subscription_status_aggregate_fields>;
  nodes: Array<subscription_status>;
};

/** aggregate fields of "subscription_status" */
export type subscription_status_aggregate_fields = {
  __typename: 'subscription_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<subscription_status_max_fields>;
  min?: Maybe<subscription_status_min_fields>;
};


/** aggregate fields of "subscription_status" */
export type subscription_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<subscription_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "subscription_status". All fields are combined with a logical 'AND'. */
export type subscription_status_bool_exp = {
  _and?: InputMaybe<Array<subscription_status_bool_exp>>;
  _not?: InputMaybe<subscription_status_bool_exp>;
  _or?: InputMaybe<Array<subscription_status_bool_exp>>;
  subscriptions?: InputMaybe<subscription_bool_exp>;
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_bool_exp>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "subscription_status" */
export enum subscription_status_constraint {
  /** unique or primary key constraint on columns "value" */
  subscription_status_pkey = 'subscription_status_pkey'
}

export enum subscription_status_enum {
  active = 'active',
  canceled = 'canceled',
  future = 'future',
  paused = 'paused',
  trialing = 'trialing'
}

/** Boolean expression to compare columns of type "subscription_status_enum". All fields are combined with logical 'AND'. */
export type subscription_status_enum_comparison_exp = {
  _eq?: InputMaybe<subscription_status_enum>;
  _in?: InputMaybe<Array<subscription_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<subscription_status_enum>;
  _nin?: InputMaybe<Array<subscription_status_enum>>;
};

/** input type for inserting data into table "subscription_status" */
export type subscription_status_insert_input = {
  subscriptions?: InputMaybe<subscription_arr_rel_insert_input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type subscription_status_max_fields = {
  __typename: 'subscription_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type subscription_status_min_fields = {
  __typename: 'subscription_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "subscription_status" */
export type subscription_status_mutation_response = {
  __typename: 'subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<subscription_status>;
};

/** input type for inserting object relation for remote table "subscription_status" */
export type subscription_status_obj_rel_insert_input = {
  data: subscription_status_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<subscription_status_on_conflict>;
};

/** on_conflict condition type for table "subscription_status" */
export type subscription_status_on_conflict = {
  constraint: subscription_status_constraint;
  update_columns?: Array<subscription_status_update_column>;
  where?: InputMaybe<subscription_status_bool_exp>;
};

/** Ordering options when selecting data from "subscription_status". */
export type subscription_status_order_by = {
  subscriptions_aggregate?: InputMaybe<subscription_aggregate_order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: subscription_status */
export type subscription_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "subscription_status" */
export enum subscription_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "subscription_status" */
export type subscription_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "subscription_status" */
export type subscription_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "subscription_status" */
export enum subscription_status_update_column {
  /** column name */
  value = 'value'
}

export type subscription_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_status_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_status_bool_exp;
};

/** aggregate stddev on columns */
export type subscription_stddev_fields = {
  __typename: 'subscription_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "subscription" */
export type subscription_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type subscription_stddev_pop_fields = {
  __typename: 'subscription_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "subscription" */
export type subscription_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type subscription_stddev_samp_fields = {
  __typename: 'subscription_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "subscription" */
export type subscription_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "subscription" */
export type subscription_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: subscription_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type subscription_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  base_currency?: InputMaybe<Scalars['String']['input']>;
  base_mrr?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mrr?: InputMaybe<Scalars['numeric']['input']>;
  platform_connection_id?: InputMaybe<Scalars['Int']['input']>;
  platform_id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<subscription_status_enum>;
  subscriber_id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type subscription_sum_fields = {
  __typename: 'subscription_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  base_mrr?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['numeric']['output']>;
  platform_connection_id?: Maybe<Scalars['Int']['output']>;
  subscriber_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "subscription" */
export type subscription_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** update columns of table "subscription" */
export enum subscription_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  base_currency = 'base_currency',
  /** column name */
  base_mrr = 'base_mrr',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  mrr = 'mrr',
  /** column name */
  platform_connection_id = 'platform_connection_id',
  /** column name */
  platform_id = 'platform_id',
  /** column name */
  status = 'status',
  /** column name */
  subscriber_id = 'subscriber_id',
  /** column name */
  token = 'token',
  /** column name */
  updated_at = 'updated_at'
}

export type subscription_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<subscription_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<subscription_set_input>;
  /** filter the rows which have to be updated */
  where: subscription_bool_exp;
};

/** aggregate var_pop on columns */
export type subscription_var_pop_fields = {
  __typename: 'subscription_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "subscription" */
export type subscription_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type subscription_var_samp_fields = {
  __typename: 'subscription_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "subscription" */
export type subscription_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type subscription_variance_fields = {
  __typename: 'subscription_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  base_mrr?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  platform_connection_id?: Maybe<Scalars['Float']['output']>;
  subscriber_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "subscription" */
export type subscription_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  base_mrr?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  mrr?: InputMaybe<order_by>;
  platform_connection_id?: InputMaybe<order_by>;
  subscriber_id?: InputMaybe<order_by>;
};

/** columns and relationships of "survey" */
export type survey = {
  __typename: 'survey';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "survey" */
export type survey_aggregate = {
  __typename: 'survey_aggregate';
  aggregate?: Maybe<survey_aggregate_fields>;
  nodes: Array<survey>;
};

/** aggregate fields of "survey" */
export type survey_aggregate_fields = {
  __typename: 'survey_aggregate_fields';
  avg?: Maybe<survey_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<survey_max_fields>;
  min?: Maybe<survey_min_fields>;
  stddev?: Maybe<survey_stddev_fields>;
  stddev_pop?: Maybe<survey_stddev_pop_fields>;
  stddev_samp?: Maybe<survey_stddev_samp_fields>;
  sum?: Maybe<survey_sum_fields>;
  var_pop?: Maybe<survey_var_pop_fields>;
  var_samp?: Maybe<survey_var_samp_fields>;
  variance?: Maybe<survey_variance_fields>;
};


/** aggregate fields of "survey" */
export type survey_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<survey_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type survey_avg_fields = {
  __typename: 'survey_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "survey". All fields are combined with a logical 'AND'. */
export type survey_bool_exp = {
  _and?: InputMaybe<Array<survey_bool_exp>>;
  _not?: InputMaybe<survey_bool_exp>;
  _or?: InputMaybe<Array<survey_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  title?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
};

/** unique or primary key constraints on table "survey" */
export enum survey_constraint {
  /** unique or primary key constraint on columns "account_id", "title" */
  survey_account_id_title_key = 'survey_account_id_title_key',
  /** unique or primary key constraint on columns "id" */
  survey_pkey = 'survey_pkey'
}

/** input type for incrementing numeric columns in table "survey" */
export type survey_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "survey" */
export type survey_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type survey_max_fields = {
  __typename: 'survey_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type survey_min_fields = {
  __typename: 'survey_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "survey" */
export type survey_mutation_response = {
  __typename: 'survey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<survey>;
};

/** on_conflict condition type for table "survey" */
export type survey_on_conflict = {
  constraint: survey_constraint;
  update_columns?: Array<survey_update_column>;
  where?: InputMaybe<survey_bool_exp>;
};

/** Ordering options when selecting data from "survey". */
export type survey_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  title?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** primary key columns input for table: survey */
export type survey_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "survey" */
export enum survey_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "survey" */
export type survey_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type survey_stddev_fields = {
  __typename: 'survey_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type survey_stddev_pop_fields = {
  __typename: 'survey_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type survey_stddev_samp_fields = {
  __typename: 'survey_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "survey" */
export type survey_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: survey_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type survey_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type survey_sum_fields = {
  __typename: 'survey_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "survey" */
export enum survey_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at'
}

export type survey_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<survey_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<survey_set_input>;
  /** filter the rows which have to be updated */
  where: survey_bool_exp;
};

/** aggregate var_pop on columns */
export type survey_var_pop_fields = {
  __typename: 'survey_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type survey_var_samp_fields = {
  __typename: 'survey_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type survey_variance_fields = {
  __typename: 'survey_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tag" */
export type tag = {
  __typename: 'tag';
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['citext']['output'];
};

/** aggregated selection of "tag" */
export type tag_aggregate = {
  __typename: 'tag_aggregate';
  aggregate?: Maybe<tag_aggregate_fields>;
  nodes: Array<tag>;
};

/** aggregate fields of "tag" */
export type tag_aggregate_fields = {
  __typename: 'tag_aggregate_fields';
  avg?: Maybe<tag_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<tag_max_fields>;
  min?: Maybe<tag_min_fields>;
  stddev?: Maybe<tag_stddev_fields>;
  stddev_pop?: Maybe<tag_stddev_pop_fields>;
  stddev_samp?: Maybe<tag_stddev_samp_fields>;
  sum?: Maybe<tag_sum_fields>;
  var_pop?: Maybe<tag_var_pop_fields>;
  var_samp?: Maybe<tag_var_samp_fields>;
  variance?: Maybe<tag_variance_fields>;
};


/** aggregate fields of "tag" */
export type tag_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<tag_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type tag_avg_fields = {
  __typename: 'tag_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type tag_bool_exp = {
  _and?: InputMaybe<Array<tag_bool_exp>>;
  _not?: InputMaybe<tag_bool_exp>;
  _or?: InputMaybe<Array<tag_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  name?: InputMaybe<citext_comparison_exp>;
};

/** unique or primary key constraints on table "tag" */
export enum tag_constraint {
  /** unique or primary key constraint on columns "account_id", "name" */
  tag_account_id_name_key = 'tag_account_id_name_key',
  /** unique or primary key constraint on columns "id" */
  tag_pkey = 'tag_pkey'
}

/** input type for incrementing numeric columns in table "tag" */
export type tag_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tag" */
export type tag_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate max on columns */
export type tag_max_fields = {
  __typename: 'tag_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** aggregate min on columns */
export type tag_min_fields = {
  __typename: 'tag_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['citext']['output']>;
};

/** response of any mutation on the table "tag" */
export type tag_mutation_response = {
  __typename: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<tag>;
};

/** input type for inserting object relation for remote table "tag" */
export type tag_obj_rel_insert_input = {
  data: tag_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<tag_on_conflict>;
};

/** on_conflict condition type for table "tag" */
export type tag_on_conflict = {
  constraint: tag_constraint;
  update_columns?: Array<tag_update_column>;
  where?: InputMaybe<tag_bool_exp>;
};

/** Ordering options when selecting data from "tag". */
export type tag_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
};

/** primary key columns input for table: tag */
export type tag_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tag" */
export enum tag_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

/** input type for updating data in table "tag" */
export type tag_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate stddev on columns */
export type tag_stddev_fields = {
  __typename: 'tag_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type tag_stddev_pop_fields = {
  __typename: 'tag_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type tag_stddev_samp_fields = {
  __typename: 'tag_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tag" */
export type tag_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: tag_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type tag_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate sum on columns */
export type tag_sum_fields = {
  __typename: 'tag_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "tag" */
export enum tag_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name'
}

export type tag_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<tag_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<tag_set_input>;
  /** filter the rows which have to be updated */
  where: tag_bool_exp;
};

/** aggregate var_pop on columns */
export type tag_var_pop_fields = {
  __typename: 'tag_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type tag_var_samp_fields = {
  __typename: 'tag_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type tag_variance_fields = {
  __typename: 'tag_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_deflection_trends" */
export type template_report_deflection_trends = {
  __typename: 'template_report_deflection_trends';
  account_id: Scalars['Int']['output'];
  clicked: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  flow_action_id: Scalars['Int']['output'];
};

export type template_report_deflection_trends_aggregate = {
  __typename: 'template_report_deflection_trends_aggregate';
  aggregate?: Maybe<template_report_deflection_trends_aggregate_fields>;
  nodes: Array<template_report_deflection_trends>;
};

/** aggregate fields of "template_report_deflection_trends" */
export type template_report_deflection_trends_aggregate_fields = {
  __typename: 'template_report_deflection_trends_aggregate_fields';
  avg?: Maybe<template_report_deflection_trends_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_deflection_trends_max_fields>;
  min?: Maybe<template_report_deflection_trends_min_fields>;
  stddev?: Maybe<template_report_deflection_trends_stddev_fields>;
  stddev_pop?: Maybe<template_report_deflection_trends_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_deflection_trends_stddev_samp_fields>;
  sum?: Maybe<template_report_deflection_trends_sum_fields>;
  var_pop?: Maybe<template_report_deflection_trends_var_pop_fields>;
  var_samp?: Maybe<template_report_deflection_trends_var_samp_fields>;
  variance?: Maybe<template_report_deflection_trends_variance_fields>;
};


/** aggregate fields of "template_report_deflection_trends" */
export type template_report_deflection_trends_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_deflection_trends_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_deflection_trends_avg_fields = {
  __typename: 'template_report_deflection_trends_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_deflection_trends". All fields are combined with a logical 'AND'. */
export type template_report_deflection_trends_bool_exp = {
  _and?: InputMaybe<Array<template_report_deflection_trends_bool_exp>>;
  _not?: InputMaybe<template_report_deflection_trends_bool_exp>;
  _or?: InputMaybe<Array<template_report_deflection_trends_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  clicked?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  flow_action_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_deflection_trends" */
export enum template_report_deflection_trends_constraint {
  /** unique or primary key constraint on columns "account_id", "flow_action_id", "date" */
  template_report_deflection_trends_pkey = 'template_report_deflection_trends_pkey'
}

/** input type for incrementing numeric columns in table "template_report_deflection_trends" */
export type template_report_deflection_trends_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  clicked?: InputMaybe<Scalars['Int']['input']>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_deflection_trends" */
export type template_report_deflection_trends_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  clicked?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_deflection_trends_max_fields = {
  __typename: 'template_report_deflection_trends_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  clicked?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_deflection_trends_min_fields = {
  __typename: 'template_report_deflection_trends_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  clicked?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_deflection_trends" */
export type template_report_deflection_trends_mutation_response = {
  __typename: 'template_report_deflection_trends_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_deflection_trends>;
};

/** on_conflict condition type for table "template_report_deflection_trends" */
export type template_report_deflection_trends_on_conflict = {
  constraint: template_report_deflection_trends_constraint;
  update_columns?: Array<template_report_deflection_trends_update_column>;
  where?: InputMaybe<template_report_deflection_trends_bool_exp>;
};

/** Ordering options when selecting data from "template_report_deflection_trends". */
export type template_report_deflection_trends_order_by = {
  account_id?: InputMaybe<order_by>;
  clicked?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  flow_action_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_deflection_trends */
export type template_report_deflection_trends_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  flow_action_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_deflection_trends" */
export enum template_report_deflection_trends_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  clicked = 'clicked',
  /** column name */
  date = 'date',
  /** column name */
  flow_action_id = 'flow_action_id'
}

/** input type for updating data in table "template_report_deflection_trends" */
export type template_report_deflection_trends_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  clicked?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_deflection_trends_stddev_fields = {
  __typename: 'template_report_deflection_trends_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_deflection_trends_stddev_pop_fields = {
  __typename: 'template_report_deflection_trends_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_deflection_trends_stddev_samp_fields = {
  __typename: 'template_report_deflection_trends_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_deflection_trends" */
export type template_report_deflection_trends_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_deflection_trends_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_deflection_trends_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  clicked?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  flow_action_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_deflection_trends_sum_fields = {
  __typename: 'template_report_deflection_trends_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  clicked?: Maybe<Scalars['Int']['output']>;
  flow_action_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_deflection_trends" */
export enum template_report_deflection_trends_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  clicked = 'clicked',
  /** column name */
  date = 'date',
  /** column name */
  flow_action_id = 'flow_action_id'
}

export type template_report_deflection_trends_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_deflection_trends_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_deflection_trends_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_deflection_trends_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_deflection_trends_var_pop_fields = {
  __typename: 'template_report_deflection_trends_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_deflection_trends_var_samp_fields = {
  __typename: 'template_report_deflection_trends_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_deflection_trends_variance_fields = {
  __typename: 'template_report_deflection_trends_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  clicked?: Maybe<Scalars['Float']['output']>;
  flow_action_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_feedback_trends" */
export type template_report_feedback_trends = {
  __typename: 'template_report_feedback_trends';
  account_id: Scalars['Int']['output'];
  canceled: Scalars['Int']['output'];
  deflected: Scalars['Int']['output'];
  incidents: Scalars['Int']['output'];
  lost_mrr: Scalars['numeric']['output'];
  mixed: Scalars['Int']['output'];
  negative: Scalars['Int']['output'];
  neutral: Scalars['Int']['output'];
  phrase: Scalars['String']['output'];
  positive: Scalars['Int']['output'];
  saved: Scalars['Int']['output'];
  sentiment_phrase_normalized_id: Scalars['Int']['output'];
};

export type template_report_feedback_trends_aggregate = {
  __typename: 'template_report_feedback_trends_aggregate';
  aggregate?: Maybe<template_report_feedback_trends_aggregate_fields>;
  nodes: Array<template_report_feedback_trends>;
};

/** aggregate fields of "template_report_feedback_trends" */
export type template_report_feedback_trends_aggregate_fields = {
  __typename: 'template_report_feedback_trends_aggregate_fields';
  avg?: Maybe<template_report_feedback_trends_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_feedback_trends_max_fields>;
  min?: Maybe<template_report_feedback_trends_min_fields>;
  stddev?: Maybe<template_report_feedback_trends_stddev_fields>;
  stddev_pop?: Maybe<template_report_feedback_trends_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_feedback_trends_stddev_samp_fields>;
  sum?: Maybe<template_report_feedback_trends_sum_fields>;
  var_pop?: Maybe<template_report_feedback_trends_var_pop_fields>;
  var_samp?: Maybe<template_report_feedback_trends_var_samp_fields>;
  variance?: Maybe<template_report_feedback_trends_variance_fields>;
};


/** aggregate fields of "template_report_feedback_trends" */
export type template_report_feedback_trends_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_feedback_trends_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_feedback_trends_avg_fields = {
  __typename: 'template_report_feedback_trends_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_feedback_trends". All fields are combined with a logical 'AND'. */
export type template_report_feedback_trends_bool_exp = {
  _and?: InputMaybe<Array<template_report_feedback_trends_bool_exp>>;
  _not?: InputMaybe<template_report_feedback_trends_bool_exp>;
  _or?: InputMaybe<Array<template_report_feedback_trends_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  canceled?: InputMaybe<Int_comparison_exp>;
  deflected?: InputMaybe<Int_comparison_exp>;
  incidents?: InputMaybe<Int_comparison_exp>;
  lost_mrr?: InputMaybe<numeric_comparison_exp>;
  mixed?: InputMaybe<Int_comparison_exp>;
  negative?: InputMaybe<Int_comparison_exp>;
  neutral?: InputMaybe<Int_comparison_exp>;
  phrase?: InputMaybe<String_comparison_exp>;
  positive?: InputMaybe<Int_comparison_exp>;
  saved?: InputMaybe<Int_comparison_exp>;
  sentiment_phrase_normalized_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_feedback_trends" */
export enum template_report_feedback_trends_constraint {
  /** unique or primary key constraint on columns "account_id", "sentiment_phrase_normalized_id" */
  template_report_feedback_trends_pkey = 'template_report_feedback_trends_pkey'
}

/** input type for incrementing numeric columns in table "template_report_feedback_trends" */
export type template_report_feedback_trends_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incidents?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_feedback_trends" */
export type template_report_feedback_trends_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incidents?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  phrase?: InputMaybe<Scalars['String']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_feedback_trends_max_fields = {
  __typename: 'template_report_feedback_trends_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_feedback_trends_min_fields = {
  __typename: 'template_report_feedback_trends_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  phrase?: Maybe<Scalars['String']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_feedback_trends" */
export type template_report_feedback_trends_mutation_response = {
  __typename: 'template_report_feedback_trends_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_feedback_trends>;
};

/** on_conflict condition type for table "template_report_feedback_trends" */
export type template_report_feedback_trends_on_conflict = {
  constraint: template_report_feedback_trends_constraint;
  update_columns?: Array<template_report_feedback_trends_update_column>;
  where?: InputMaybe<template_report_feedback_trends_bool_exp>;
};

/** Ordering options when selecting data from "template_report_feedback_trends". */
export type template_report_feedback_trends_order_by = {
  account_id?: InputMaybe<order_by>;
  canceled?: InputMaybe<order_by>;
  deflected?: InputMaybe<order_by>;
  incidents?: InputMaybe<order_by>;
  lost_mrr?: InputMaybe<order_by>;
  mixed?: InputMaybe<order_by>;
  negative?: InputMaybe<order_by>;
  neutral?: InputMaybe<order_by>;
  phrase?: InputMaybe<order_by>;
  positive?: InputMaybe<order_by>;
  saved?: InputMaybe<order_by>;
  sentiment_phrase_normalized_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_feedback_trends */
export type template_report_feedback_trends_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  sentiment_phrase_normalized_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_feedback_trends" */
export enum template_report_feedback_trends_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  deflected = 'deflected',
  /** column name */
  incidents = 'incidents',
  /** column name */
  lost_mrr = 'lost_mrr',
  /** column name */
  mixed = 'mixed',
  /** column name */
  negative = 'negative',
  /** column name */
  neutral = 'neutral',
  /** column name */
  phrase = 'phrase',
  /** column name */
  positive = 'positive',
  /** column name */
  saved = 'saved',
  /** column name */
  sentiment_phrase_normalized_id = 'sentiment_phrase_normalized_id'
}

/** input type for updating data in table "template_report_feedback_trends" */
export type template_report_feedback_trends_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incidents?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  phrase?: InputMaybe<Scalars['String']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_feedback_trends_stddev_fields = {
  __typename: 'template_report_feedback_trends_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_feedback_trends_stddev_pop_fields = {
  __typename: 'template_report_feedback_trends_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_feedback_trends_stddev_samp_fields = {
  __typename: 'template_report_feedback_trends_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_feedback_trends" */
export type template_report_feedback_trends_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_feedback_trends_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_feedback_trends_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incidents?: InputMaybe<Scalars['Int']['input']>;
  lost_mrr?: InputMaybe<Scalars['numeric']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  phrase?: InputMaybe<Scalars['String']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  sentiment_phrase_normalized_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_feedback_trends_sum_fields = {
  __typename: 'template_report_feedback_trends_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incidents?: Maybe<Scalars['Int']['output']>;
  lost_mrr?: Maybe<Scalars['numeric']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_feedback_trends" */
export enum template_report_feedback_trends_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  deflected = 'deflected',
  /** column name */
  incidents = 'incidents',
  /** column name */
  lost_mrr = 'lost_mrr',
  /** column name */
  mixed = 'mixed',
  /** column name */
  negative = 'negative',
  /** column name */
  neutral = 'neutral',
  /** column name */
  phrase = 'phrase',
  /** column name */
  positive = 'positive',
  /** column name */
  saved = 'saved',
  /** column name */
  sentiment_phrase_normalized_id = 'sentiment_phrase_normalized_id'
}

export type template_report_feedback_trends_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_feedback_trends_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_feedback_trends_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_feedback_trends_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_feedback_trends_var_pop_fields = {
  __typename: 'template_report_feedback_trends_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_feedback_trends_var_samp_fields = {
  __typename: 'template_report_feedback_trends_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_feedback_trends_variance_fields = {
  __typename: 'template_report_feedback_trends_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incidents?: Maybe<Scalars['Float']['output']>;
  lost_mrr?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  sentiment_phrase_normalized_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate = {
  __typename: 'template_report_offer_accept_rate';
  accept_rate: Scalars['numeric']['output'];
  accepted: Scalars['Int']['output'];
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  declined: Scalars['Int']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  presented: Scalars['Int']['output'];
};

export type template_report_offer_accept_rate_aggregate = {
  __typename: 'template_report_offer_accept_rate_aggregate';
  aggregate?: Maybe<template_report_offer_accept_rate_aggregate_fields>;
  nodes: Array<template_report_offer_accept_rate>;
};

/** aggregate fields of "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_aggregate_fields = {
  __typename: 'template_report_offer_accept_rate_aggregate_fields';
  avg?: Maybe<template_report_offer_accept_rate_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_accept_rate_max_fields>;
  min?: Maybe<template_report_offer_accept_rate_min_fields>;
  stddev?: Maybe<template_report_offer_accept_rate_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_accept_rate_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_accept_rate_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_accept_rate_sum_fields>;
  var_pop?: Maybe<template_report_offer_accept_rate_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_accept_rate_var_samp_fields>;
  variance?: Maybe<template_report_offer_accept_rate_variance_fields>;
};


/** aggregate fields of "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_accept_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_accept_rate_avg_fields = {
  __typename: 'template_report_offer_accept_rate_avg_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_accept_rate". All fields are combined with a logical 'AND'. */
export type template_report_offer_accept_rate_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_accept_rate_bool_exp>>;
  _not?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_accept_rate_bool_exp>>;
  accept_rate?: InputMaybe<numeric_comparison_exp>;
  accepted?: InputMaybe<Int_comparison_exp>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  declined?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  presented?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_accept_rate" */
export enum template_report_offer_accept_rate_constraint {
  /** unique or primary key constraint on columns "account_id", "offer_id", "date" */
  template_report_offer_accept_rate_pkey = 'template_report_offer_accept_rate_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_inc_input = {
  accept_rate?: InputMaybe<Scalars['numeric']['input']>;
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_insert_input = {
  accept_rate?: InputMaybe<Scalars['numeric']['input']>;
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_accept_rate_max_fields = {
  __typename: 'template_report_offer_accept_rate_max_fields';
  accept_rate?: Maybe<Scalars['numeric']['output']>;
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_accept_rate_min_fields = {
  __typename: 'template_report_offer_accept_rate_min_fields';
  accept_rate?: Maybe<Scalars['numeric']['output']>;
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_mutation_response = {
  __typename: 'template_report_offer_accept_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_accept_rate>;
};

/** on_conflict condition type for table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_on_conflict = {
  constraint: template_report_offer_accept_rate_constraint;
  update_columns?: Array<template_report_offer_accept_rate_update_column>;
  where?: InputMaybe<template_report_offer_accept_rate_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_accept_rate". */
export type template_report_offer_accept_rate_order_by = {
  accept_rate?: InputMaybe<order_by>;
  accepted?: InputMaybe<order_by>;
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  declined?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  presented?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_accept_rate */
export type template_report_offer_accept_rate_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_offer_accept_rate" */
export enum template_report_offer_accept_rate_select_column {
  /** column name */
  accept_rate = 'accept_rate',
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  presented = 'presented'
}

/** input type for updating data in table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_set_input = {
  accept_rate?: InputMaybe<Scalars['numeric']['input']>;
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_accept_rate_stddev_fields = {
  __typename: 'template_report_offer_accept_rate_stddev_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_accept_rate_stddev_pop_fields = {
  __typename: 'template_report_offer_accept_rate_stddev_pop_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_accept_rate_stddev_samp_fields = {
  __typename: 'template_report_offer_accept_rate_stddev_samp_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_accept_rate" */
export type template_report_offer_accept_rate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_accept_rate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_accept_rate_stream_cursor_value_input = {
  accept_rate?: InputMaybe<Scalars['numeric']['input']>;
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_accept_rate_sum_fields = {
  __typename: 'template_report_offer_accept_rate_sum_fields';
  accept_rate?: Maybe<Scalars['numeric']['output']>;
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_offer_accept_rate" */
export enum template_report_offer_accept_rate_update_column {
  /** column name */
  accept_rate = 'accept_rate',
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  presented = 'presented'
}

export type template_report_offer_accept_rate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_accept_rate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_accept_rate_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_accept_rate_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_accept_rate_var_pop_fields = {
  __typename: 'template_report_offer_accept_rate_var_pop_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_accept_rate_var_samp_fields = {
  __typename: 'template_report_offer_accept_rate_var_samp_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_accept_rate_variance_fields = {
  __typename: 'template_report_offer_accept_rate_variance_fields';
  accept_rate?: Maybe<Scalars['Float']['output']>;
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate = {
  __typename: 'template_report_offer_group_accept_rate';
  accepted: Scalars['Int']['output'];
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  declined: Scalars['Int']['output'];
  /** An object relationship */
  offer?: Maybe<offer>;
  /** An object relationship */
  offer_group?: Maybe<offer_group>;
  offer_group_id: Scalars['Int']['output'];
  offer_id: Scalars['Int']['output'];
  presented: Scalars['Int']['output'];
};

export type template_report_offer_group_accept_rate_aggregate = {
  __typename: 'template_report_offer_group_accept_rate_aggregate';
  aggregate?: Maybe<template_report_offer_group_accept_rate_aggregate_fields>;
  nodes: Array<template_report_offer_group_accept_rate>;
};

/** aggregate fields of "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_aggregate_fields = {
  __typename: 'template_report_offer_group_accept_rate_aggregate_fields';
  avg?: Maybe<template_report_offer_group_accept_rate_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_group_accept_rate_max_fields>;
  min?: Maybe<template_report_offer_group_accept_rate_min_fields>;
  stddev?: Maybe<template_report_offer_group_accept_rate_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_group_accept_rate_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_group_accept_rate_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_group_accept_rate_sum_fields>;
  var_pop?: Maybe<template_report_offer_group_accept_rate_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_group_accept_rate_var_samp_fields>;
  variance?: Maybe<template_report_offer_group_accept_rate_variance_fields>;
};


/** aggregate fields of "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_group_accept_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_group_accept_rate_avg_fields = {
  __typename: 'template_report_offer_group_accept_rate_avg_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_group_accept_rate". All fields are combined with a logical 'AND'. */
export type template_report_offer_group_accept_rate_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_group_accept_rate_bool_exp>>;
  _not?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_group_accept_rate_bool_exp>>;
  accepted?: InputMaybe<Int_comparison_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  declined?: InputMaybe<Int_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  presented?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_group_accept_rate" */
export enum template_report_offer_group_accept_rate_constraint {
  /** unique or primary key constraint on columns "account_id", "offer_id", "date", "offer_group_id" */
  template_report_offer_group_accept_rate_pkey = 'template_report_offer_group_accept_rate_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_inc_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_insert_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_group_accept_rate_max_fields = {
  __typename: 'template_report_offer_group_accept_rate_max_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_group_accept_rate_min_fields = {
  __typename: 'template_report_offer_group_accept_rate_min_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_mutation_response = {
  __typename: 'template_report_offer_group_accept_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_group_accept_rate>;
};

/** on_conflict condition type for table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_on_conflict = {
  constraint: template_report_offer_group_accept_rate_constraint;
  update_columns?: Array<template_report_offer_group_accept_rate_update_column>;
  where?: InputMaybe<template_report_offer_group_accept_rate_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_group_accept_rate". */
export type template_report_offer_group_accept_rate_order_by = {
  accepted?: InputMaybe<order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  declined?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  offer_id?: InputMaybe<order_by>;
  presented?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_group_accept_rate */
export type template_report_offer_group_accept_rate_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_offer_group_accept_rate" */
export enum template_report_offer_group_accept_rate_select_column {
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  presented = 'presented'
}

/** input type for updating data in table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_set_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_group_accept_rate_stddev_fields = {
  __typename: 'template_report_offer_group_accept_rate_stddev_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_group_accept_rate_stddev_pop_fields = {
  __typename: 'template_report_offer_group_accept_rate_stddev_pop_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_group_accept_rate_stddev_samp_fields = {
  __typename: 'template_report_offer_group_accept_rate_stddev_samp_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_group_accept_rate" */
export type template_report_offer_group_accept_rate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_group_accept_rate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_group_accept_rate_stream_cursor_value_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  presented?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_group_accept_rate_sum_fields = {
  __typename: 'template_report_offer_group_accept_rate_sum_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  presented?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_offer_group_accept_rate" */
export enum template_report_offer_group_accept_rate_update_column {
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  presented = 'presented'
}

export type template_report_offer_group_accept_rate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_group_accept_rate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_group_accept_rate_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_group_accept_rate_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_group_accept_rate_var_pop_fields = {
  __typename: 'template_report_offer_group_accept_rate_var_pop_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_group_accept_rate_var_samp_fields = {
  __typename: 'template_report_offer_group_accept_rate_var_samp_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_group_accept_rate_variance_fields = {
  __typename: 'template_report_offer_group_accept_rate_variance_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  presented?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue = {
  __typename: 'template_report_offer_group_retained_revenue';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  /** An object relationship */
  offer_group: offer_group;
  offer_group_id: Scalars['Int']['output'];
  total: Scalars['numeric']['output'];
};

export type template_report_offer_group_retained_revenue_aggregate = {
  __typename: 'template_report_offer_group_retained_revenue_aggregate';
  aggregate?: Maybe<template_report_offer_group_retained_revenue_aggregate_fields>;
  nodes: Array<template_report_offer_group_retained_revenue>;
};

/** aggregate fields of "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_aggregate_fields = {
  __typename: 'template_report_offer_group_retained_revenue_aggregate_fields';
  avg?: Maybe<template_report_offer_group_retained_revenue_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_group_retained_revenue_max_fields>;
  min?: Maybe<template_report_offer_group_retained_revenue_min_fields>;
  stddev?: Maybe<template_report_offer_group_retained_revenue_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_group_retained_revenue_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_group_retained_revenue_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_group_retained_revenue_sum_fields>;
  var_pop?: Maybe<template_report_offer_group_retained_revenue_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_group_retained_revenue_var_samp_fields>;
  variance?: Maybe<template_report_offer_group_retained_revenue_variance_fields>;
};


/** aggregate fields of "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_group_retained_revenue_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_group_retained_revenue_avg_fields = {
  __typename: 'template_report_offer_group_retained_revenue_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_group_retained_revenue". All fields are combined with a logical 'AND'. */
export type template_report_offer_group_retained_revenue_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_group_retained_revenue_bool_exp>>;
  _not?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_group_retained_revenue_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  offer_group?: InputMaybe<offer_group_bool_exp>;
  offer_group_id?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_group_retained_revenue" */
export enum template_report_offer_group_retained_revenue_constraint {
  /** unique or primary key constraint on columns "account_id", "date", "offer_group_id" */
  template_report_offer_group_retained_revenue_pkey = 'template_report_offer_group_retained_revenue_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_group?: InputMaybe<offer_group_obj_rel_insert_input>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_group_retained_revenue_max_fields = {
  __typename: 'template_report_offer_group_retained_revenue_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_group_retained_revenue_min_fields = {
  __typename: 'template_report_offer_group_retained_revenue_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_mutation_response = {
  __typename: 'template_report_offer_group_retained_revenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_group_retained_revenue>;
};

/** on_conflict condition type for table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_on_conflict = {
  constraint: template_report_offer_group_retained_revenue_constraint;
  update_columns?: Array<template_report_offer_group_retained_revenue_update_column>;
  where?: InputMaybe<template_report_offer_group_retained_revenue_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_group_retained_revenue". */
export type template_report_offer_group_retained_revenue_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  offer_group?: InputMaybe<offer_group_order_by>;
  offer_group_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_group_retained_revenue */
export type template_report_offer_group_retained_revenue_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_group_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_offer_group_retained_revenue" */
export enum template_report_offer_group_retained_revenue_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_group_retained_revenue_stddev_fields = {
  __typename: 'template_report_offer_group_retained_revenue_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_group_retained_revenue_stddev_pop_fields = {
  __typename: 'template_report_offer_group_retained_revenue_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_group_retained_revenue_stddev_samp_fields = {
  __typename: 'template_report_offer_group_retained_revenue_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_group_retained_revenue" */
export type template_report_offer_group_retained_revenue_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_group_retained_revenue_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_group_retained_revenue_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_group_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_group_retained_revenue_sum_fields = {
  __typename: 'template_report_offer_group_retained_revenue_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_group_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "template_report_offer_group_retained_revenue" */
export enum template_report_offer_group_retained_revenue_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_group_id = 'offer_group_id',
  /** column name */
  total = 'total'
}

export type template_report_offer_group_retained_revenue_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_group_retained_revenue_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_group_retained_revenue_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_group_retained_revenue_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_group_retained_revenue_var_pop_fields = {
  __typename: 'template_report_offer_group_retained_revenue_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_group_retained_revenue_var_samp_fields = {
  __typename: 'template_report_offer_group_retained_revenue_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_group_retained_revenue_variance_fields = {
  __typename: 'template_report_offer_group_retained_revenue_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_group_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_rate" */
export type template_report_offer_rate = {
  __typename: 'template_report_offer_rate';
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  offer_rate: Scalars['numeric']['output'];
  offered: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type template_report_offer_rate_aggregate = {
  __typename: 'template_report_offer_rate_aggregate';
  aggregate?: Maybe<template_report_offer_rate_aggregate_fields>;
  nodes: Array<template_report_offer_rate>;
};

/** aggregate fields of "template_report_offer_rate" */
export type template_report_offer_rate_aggregate_fields = {
  __typename: 'template_report_offer_rate_aggregate_fields';
  avg?: Maybe<template_report_offer_rate_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_rate_max_fields>;
  min?: Maybe<template_report_offer_rate_min_fields>;
  stddev?: Maybe<template_report_offer_rate_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_rate_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_rate_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_rate_sum_fields>;
  var_pop?: Maybe<template_report_offer_rate_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_rate_var_samp_fields>;
  variance?: Maybe<template_report_offer_rate_variance_fields>;
};


/** aggregate fields of "template_report_offer_rate" */
export type template_report_offer_rate_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_rate_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_rate_avg_fields = {
  __typename: 'template_report_offer_rate_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_rate". All fields are combined with a logical 'AND'. */
export type template_report_offer_rate_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_rate_bool_exp>>;
  _not?: InputMaybe<template_report_offer_rate_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_rate_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  offer_rate?: InputMaybe<numeric_comparison_exp>;
  offered?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_rate" */
export enum template_report_offer_rate_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_offer_rate_pkey = 'template_report_offer_rate_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_rate" */
export type template_report_offer_rate_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  offer_rate?: InputMaybe<Scalars['numeric']['input']>;
  offered?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_offer_rate" */
export type template_report_offer_rate_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_rate?: InputMaybe<Scalars['numeric']['input']>;
  offered?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_rate_max_fields = {
  __typename: 'template_report_offer_rate_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_rate?: Maybe<Scalars['numeric']['output']>;
  offered?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_rate_min_fields = {
  __typename: 'template_report_offer_rate_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_rate?: Maybe<Scalars['numeric']['output']>;
  offered?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_offer_rate" */
export type template_report_offer_rate_mutation_response = {
  __typename: 'template_report_offer_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_rate>;
};

/** on_conflict condition type for table "template_report_offer_rate" */
export type template_report_offer_rate_on_conflict = {
  constraint: template_report_offer_rate_constraint;
  update_columns?: Array<template_report_offer_rate_update_column>;
  where?: InputMaybe<template_report_offer_rate_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_rate". */
export type template_report_offer_rate_order_by = {
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  offer_rate?: InputMaybe<order_by>;
  offered?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_rate */
export type template_report_offer_rate_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_offer_rate" */
export enum template_report_offer_rate_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_rate = 'offer_rate',
  /** column name */
  offered = 'offered',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_offer_rate" */
export type template_report_offer_rate_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_rate?: InputMaybe<Scalars['numeric']['input']>;
  offered?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_rate_stddev_fields = {
  __typename: 'template_report_offer_rate_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_rate_stddev_pop_fields = {
  __typename: 'template_report_offer_rate_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_rate_stddev_samp_fields = {
  __typename: 'template_report_offer_rate_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_rate" */
export type template_report_offer_rate_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_rate_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_rate_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_rate?: InputMaybe<Scalars['numeric']['input']>;
  offered?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_rate_sum_fields = {
  __typename: 'template_report_offer_rate_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_rate?: Maybe<Scalars['numeric']['output']>;
  offered?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_offer_rate" */
export enum template_report_offer_rate_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_rate = 'offer_rate',
  /** column name */
  offered = 'offered',
  /** column name */
  total = 'total'
}

export type template_report_offer_rate_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_rate_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_rate_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_rate_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_rate_var_pop_fields = {
  __typename: 'template_report_offer_rate_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_rate_var_samp_fields = {
  __typename: 'template_report_offer_rate_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_rate_variance_fields = {
  __typename: 'template_report_offer_rate_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_rate?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_resolution" */
export type template_report_offer_resolution = {
  __typename: 'template_report_offer_resolution';
  accepted: Scalars['Int']['output'];
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  declined: Scalars['Int']['output'];
  no_offer: Scalars['Int']['output'];
};

export type template_report_offer_resolution_aggregate = {
  __typename: 'template_report_offer_resolution_aggregate';
  aggregate?: Maybe<template_report_offer_resolution_aggregate_fields>;
  nodes: Array<template_report_offer_resolution>;
};

/** aggregate fields of "template_report_offer_resolution" */
export type template_report_offer_resolution_aggregate_fields = {
  __typename: 'template_report_offer_resolution_aggregate_fields';
  avg?: Maybe<template_report_offer_resolution_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_resolution_max_fields>;
  min?: Maybe<template_report_offer_resolution_min_fields>;
  stddev?: Maybe<template_report_offer_resolution_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_resolution_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_resolution_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_resolution_sum_fields>;
  var_pop?: Maybe<template_report_offer_resolution_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_resolution_var_samp_fields>;
  variance?: Maybe<template_report_offer_resolution_variance_fields>;
};


/** aggregate fields of "template_report_offer_resolution" */
export type template_report_offer_resolution_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_resolution_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_resolution_avg_fields = {
  __typename: 'template_report_offer_resolution_avg_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_resolution". All fields are combined with a logical 'AND'. */
export type template_report_offer_resolution_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_resolution_bool_exp>>;
  _not?: InputMaybe<template_report_offer_resolution_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_resolution_bool_exp>>;
  accepted?: InputMaybe<Int_comparison_exp>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  declined?: InputMaybe<Int_comparison_exp>;
  no_offer?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_resolution" */
export enum template_report_offer_resolution_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_offer_resolution_pkey = 'template_report_offer_resolution_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_resolution" */
export type template_report_offer_resolution_inc_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  no_offer?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_offer_resolution" */
export type template_report_offer_resolution_insert_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  no_offer?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_resolution_max_fields = {
  __typename: 'template_report_offer_resolution_max_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  no_offer?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_resolution_min_fields = {
  __typename: 'template_report_offer_resolution_min_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  no_offer?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_offer_resolution" */
export type template_report_offer_resolution_mutation_response = {
  __typename: 'template_report_offer_resolution_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_resolution>;
};

/** on_conflict condition type for table "template_report_offer_resolution" */
export type template_report_offer_resolution_on_conflict = {
  constraint: template_report_offer_resolution_constraint;
  update_columns?: Array<template_report_offer_resolution_update_column>;
  where?: InputMaybe<template_report_offer_resolution_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_resolution". */
export type template_report_offer_resolution_order_by = {
  accepted?: InputMaybe<order_by>;
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  declined?: InputMaybe<order_by>;
  no_offer?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_resolution */
export type template_report_offer_resolution_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_offer_resolution" */
export enum template_report_offer_resolution_select_column {
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  no_offer = 'no_offer'
}

/** input type for updating data in table "template_report_offer_resolution" */
export type template_report_offer_resolution_set_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  no_offer?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_resolution_stddev_fields = {
  __typename: 'template_report_offer_resolution_stddev_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_resolution_stddev_pop_fields = {
  __typename: 'template_report_offer_resolution_stddev_pop_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_resolution_stddev_samp_fields = {
  __typename: 'template_report_offer_resolution_stddev_samp_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_resolution" */
export type template_report_offer_resolution_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_resolution_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_resolution_stream_cursor_value_input = {
  accepted?: InputMaybe<Scalars['Int']['input']>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  declined?: InputMaybe<Scalars['Int']['input']>;
  no_offer?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_resolution_sum_fields = {
  __typename: 'template_report_offer_resolution_sum_fields';
  accepted?: Maybe<Scalars['Int']['output']>;
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  declined?: Maybe<Scalars['Int']['output']>;
  no_offer?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_offer_resolution" */
export enum template_report_offer_resolution_update_column {
  /** column name */
  accepted = 'accepted',
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  date = 'date',
  /** column name */
  declined = 'declined',
  /** column name */
  no_offer = 'no_offer'
}

export type template_report_offer_resolution_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_resolution_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_resolution_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_resolution_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_resolution_var_pop_fields = {
  __typename: 'template_report_offer_resolution_var_pop_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_resolution_var_samp_fields = {
  __typename: 'template_report_offer_resolution_var_samp_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_resolution_variance_fields = {
  __typename: 'template_report_offer_resolution_variance_fields';
  accepted?: Maybe<Scalars['Float']['output']>;
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  declined?: Maybe<Scalars['Float']['output']>;
  no_offer?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue = {
  __typename: 'template_report_offer_retained_revenue';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  /** An object relationship */
  offer: offer;
  offer_id: Scalars['Int']['output'];
  total: Scalars['numeric']['output'];
};

export type template_report_offer_retained_revenue_aggregate = {
  __typename: 'template_report_offer_retained_revenue_aggregate';
  aggregate?: Maybe<template_report_offer_retained_revenue_aggregate_fields>;
  nodes: Array<template_report_offer_retained_revenue>;
};

/** aggregate fields of "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_aggregate_fields = {
  __typename: 'template_report_offer_retained_revenue_aggregate_fields';
  avg?: Maybe<template_report_offer_retained_revenue_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_offer_retained_revenue_max_fields>;
  min?: Maybe<template_report_offer_retained_revenue_min_fields>;
  stddev?: Maybe<template_report_offer_retained_revenue_stddev_fields>;
  stddev_pop?: Maybe<template_report_offer_retained_revenue_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_offer_retained_revenue_stddev_samp_fields>;
  sum?: Maybe<template_report_offer_retained_revenue_sum_fields>;
  var_pop?: Maybe<template_report_offer_retained_revenue_var_pop_fields>;
  var_samp?: Maybe<template_report_offer_retained_revenue_var_samp_fields>;
  variance?: Maybe<template_report_offer_retained_revenue_variance_fields>;
};


/** aggregate fields of "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_offer_retained_revenue_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_offer_retained_revenue_avg_fields = {
  __typename: 'template_report_offer_retained_revenue_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_offer_retained_revenue". All fields are combined with a logical 'AND'. */
export type template_report_offer_retained_revenue_bool_exp = {
  _and?: InputMaybe<Array<template_report_offer_retained_revenue_bool_exp>>;
  _not?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
  _or?: InputMaybe<Array<template_report_offer_retained_revenue_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  offer?: InputMaybe<offer_bool_exp>;
  offer_id?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<numeric_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_offer_retained_revenue" */
export enum template_report_offer_retained_revenue_constraint {
  /** unique or primary key constraint on columns "account_id", "offer_id", "date" */
  template_report_offer_retained_revenue_pkey = 'template_report_offer_retained_revenue_pkey'
}

/** input type for incrementing numeric columns in table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer?: InputMaybe<offer_obj_rel_insert_input>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type template_report_offer_retained_revenue_max_fields = {
  __typename: 'template_report_offer_retained_revenue_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type template_report_offer_retained_revenue_min_fields = {
  __typename: 'template_report_offer_retained_revenue_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_mutation_response = {
  __typename: 'template_report_offer_retained_revenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_offer_retained_revenue>;
};

/** on_conflict condition type for table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_on_conflict = {
  constraint: template_report_offer_retained_revenue_constraint;
  update_columns?: Array<template_report_offer_retained_revenue_update_column>;
  where?: InputMaybe<template_report_offer_retained_revenue_bool_exp>;
};

/** Ordering options when selecting data from "template_report_offer_retained_revenue". */
export type template_report_offer_retained_revenue_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  offer?: InputMaybe<offer_order_by>;
  offer_id?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_offer_retained_revenue */
export type template_report_offer_retained_revenue_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  offer_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_offer_retained_revenue" */
export enum template_report_offer_retained_revenue_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type template_report_offer_retained_revenue_stddev_fields = {
  __typename: 'template_report_offer_retained_revenue_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_offer_retained_revenue_stddev_pop_fields = {
  __typename: 'template_report_offer_retained_revenue_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_offer_retained_revenue_stddev_samp_fields = {
  __typename: 'template_report_offer_retained_revenue_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_offer_retained_revenue" */
export type template_report_offer_retained_revenue_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_offer_retained_revenue_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_offer_retained_revenue_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  offer_id?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type template_report_offer_retained_revenue_sum_fields = {
  __typename: 'template_report_offer_retained_revenue_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "template_report_offer_retained_revenue" */
export enum template_report_offer_retained_revenue_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  offer_id = 'offer_id',
  /** column name */
  total = 'total'
}

export type template_report_offer_retained_revenue_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_offer_retained_revenue_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_offer_retained_revenue_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_offer_retained_revenue_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_offer_retained_revenue_var_pop_fields = {
  __typename: 'template_report_offer_retained_revenue_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_offer_retained_revenue_var_samp_fields = {
  __typename: 'template_report_offer_retained_revenue_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_offer_retained_revenue_variance_fields = {
  __typename: 'template_report_offer_retained_revenue_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  offer_id?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_question_answer_radio" */
export type template_report_question_answer_radio = {
  __typename: 'template_report_question_answer_radio';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
  /** An object relationship */
  question_option: question_option;
  question_option_id: Scalars['Int']['output'];
};

export type template_report_question_answer_radio_aggregate = {
  __typename: 'template_report_question_answer_radio_aggregate';
  aggregate?: Maybe<template_report_question_answer_radio_aggregate_fields>;
  nodes: Array<template_report_question_answer_radio>;
};

/** aggregate fields of "template_report_question_answer_radio" */
export type template_report_question_answer_radio_aggregate_fields = {
  __typename: 'template_report_question_answer_radio_aggregate_fields';
  avg?: Maybe<template_report_question_answer_radio_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_question_answer_radio_max_fields>;
  min?: Maybe<template_report_question_answer_radio_min_fields>;
  stddev?: Maybe<template_report_question_answer_radio_stddev_fields>;
  stddev_pop?: Maybe<template_report_question_answer_radio_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_question_answer_radio_stddev_samp_fields>;
  sum?: Maybe<template_report_question_answer_radio_sum_fields>;
  var_pop?: Maybe<template_report_question_answer_radio_var_pop_fields>;
  var_samp?: Maybe<template_report_question_answer_radio_var_samp_fields>;
  variance?: Maybe<template_report_question_answer_radio_variance_fields>;
};


/** aggregate fields of "template_report_question_answer_radio" */
export type template_report_question_answer_radio_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_question_answer_radio_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_question_answer_radio_avg_fields = {
  __typename: 'template_report_question_answer_radio_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_question_answer_radio". All fields are combined with a logical 'AND'. */
export type template_report_question_answer_radio_bool_exp = {
  _and?: InputMaybe<Array<template_report_question_answer_radio_bool_exp>>;
  _not?: InputMaybe<template_report_question_answer_radio_bool_exp>;
  _or?: InputMaybe<Array<template_report_question_answer_radio_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  count?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_question_answer_radio" */
export enum template_report_question_answer_radio_constraint {
  /** unique or primary key constraint on columns "account_id", "question_id", "date", "question_option_id" */
  template_report_question_answer_radio_pkey = 'template_report_question_answer_radio_pkey'
}

/** columns and relationships of "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr = {
  __typename: 'template_report_question_answer_radio_inactive_mrr';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  inactive_mrr: Scalars['numeric']['output'];
  /** An object relationship */
  question: question;
  question_id: Scalars['Int']['output'];
  /** An object relationship */
  question_option: question_option;
  question_option_id: Scalars['Int']['output'];
};

export type template_report_question_answer_radio_inactive_mrr_aggregate = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_aggregate';
  aggregate?: Maybe<template_report_question_answer_radio_inactive_mrr_aggregate_fields>;
  nodes: Array<template_report_question_answer_radio_inactive_mrr>;
};

/** aggregate fields of "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_aggregate_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_aggregate_fields';
  avg?: Maybe<template_report_question_answer_radio_inactive_mrr_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_question_answer_radio_inactive_mrr_max_fields>;
  min?: Maybe<template_report_question_answer_radio_inactive_mrr_min_fields>;
  stddev?: Maybe<template_report_question_answer_radio_inactive_mrr_stddev_fields>;
  stddev_pop?: Maybe<template_report_question_answer_radio_inactive_mrr_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_question_answer_radio_inactive_mrr_stddev_samp_fields>;
  sum?: Maybe<template_report_question_answer_radio_inactive_mrr_sum_fields>;
  var_pop?: Maybe<template_report_question_answer_radio_inactive_mrr_var_pop_fields>;
  var_samp?: Maybe<template_report_question_answer_radio_inactive_mrr_var_samp_fields>;
  variance?: Maybe<template_report_question_answer_radio_inactive_mrr_variance_fields>;
};


/** aggregate fields of "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_question_answer_radio_inactive_mrr_avg_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_question_answer_radio_inactive_mrr". All fields are combined with a logical 'AND'. */
export type template_report_question_answer_radio_inactive_mrr_bool_exp = {
  _and?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_bool_exp>>;
  _not?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
  _or?: InputMaybe<Array<template_report_question_answer_radio_inactive_mrr_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  inactive_mrr?: InputMaybe<numeric_comparison_exp>;
  question?: InputMaybe<question_bool_exp>;
  question_id?: InputMaybe<Int_comparison_exp>;
  question_option?: InputMaybe<question_option_bool_exp>;
  question_option_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_question_answer_radio_inactive_mrr" */
export enum template_report_question_answer_radio_inactive_mrr_constraint {
  /** unique or primary key constraint on columns "account_id", "question_id", "date", "question_option_id" */
  template_report_question_answer_radio_inactive_mrr_pkey = 'template_report_question_answer_radio_inactive_mrr_pkey'
}

/** input type for incrementing numeric columns in table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_question_answer_radio_inactive_mrr_max_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_question_answer_radio_inactive_mrr_min_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_mutation_response = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_question_answer_radio_inactive_mrr>;
};

/** on_conflict condition type for table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_on_conflict = {
  constraint: template_report_question_answer_radio_inactive_mrr_constraint;
  update_columns?: Array<template_report_question_answer_radio_inactive_mrr_update_column>;
  where?: InputMaybe<template_report_question_answer_radio_inactive_mrr_bool_exp>;
};

/** Ordering options when selecting data from "template_report_question_answer_radio_inactive_mrr". */
export type template_report_question_answer_radio_inactive_mrr_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  inactive_mrr?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_question_answer_radio_inactive_mrr */
export type template_report_question_answer_radio_inactive_mrr_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_question_answer_radio_inactive_mrr" */
export enum template_report_question_answer_radio_inactive_mrr_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  inactive_mrr = 'inactive_mrr',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_id = 'question_option_id'
}

/** input type for updating data in table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_question_answer_radio_inactive_mrr_stddev_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_question_answer_radio_inactive_mrr_stddev_pop_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_question_answer_radio_inactive_mrr_stddev_samp_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_question_answer_radio_inactive_mrr" */
export type template_report_question_answer_radio_inactive_mrr_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_question_answer_radio_inactive_mrr_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_question_answer_radio_inactive_mrr_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  inactive_mrr?: InputMaybe<Scalars['numeric']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_question_answer_radio_inactive_mrr_sum_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  inactive_mrr?: Maybe<Scalars['numeric']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_question_answer_radio_inactive_mrr" */
export enum template_report_question_answer_radio_inactive_mrr_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  inactive_mrr = 'inactive_mrr',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_id = 'question_option_id'
}

export type template_report_question_answer_radio_inactive_mrr_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_question_answer_radio_inactive_mrr_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_question_answer_radio_inactive_mrr_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_question_answer_radio_inactive_mrr_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_question_answer_radio_inactive_mrr_var_pop_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_question_answer_radio_inactive_mrr_var_samp_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_question_answer_radio_inactive_mrr_variance_fields = {
  __typename: 'template_report_question_answer_radio_inactive_mrr_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  inactive_mrr?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** input type for incrementing numeric columns in table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<question_obj_rel_insert_input>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option?: InputMaybe<question_option_obj_rel_insert_input>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_question_answer_radio_max_fields = {
  __typename: 'template_report_question_answer_radio_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_question_answer_radio_min_fields = {
  __typename: 'template_report_question_answer_radio_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_mutation_response = {
  __typename: 'template_report_question_answer_radio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_question_answer_radio>;
};

/** on_conflict condition type for table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_on_conflict = {
  constraint: template_report_question_answer_radio_constraint;
  update_columns?: Array<template_report_question_answer_radio_update_column>;
  where?: InputMaybe<template_report_question_answer_radio_bool_exp>;
};

/** Ordering options when selecting data from "template_report_question_answer_radio". */
export type template_report_question_answer_radio_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  count?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  question?: InputMaybe<question_order_by>;
  question_id?: InputMaybe<order_by>;
  question_option?: InputMaybe<question_option_order_by>;
  question_option_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_question_answer_radio */
export type template_report_question_answer_radio_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
  question_id: Scalars['Int']['input'];
  question_option_id: Scalars['Int']['input'];
};

/** select columns of table "template_report_question_answer_radio" */
export enum template_report_question_answer_radio_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  date = 'date',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_id = 'question_option_id'
}

/** input type for updating data in table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_question_answer_radio_stddev_fields = {
  __typename: 'template_report_question_answer_radio_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_question_answer_radio_stddev_pop_fields = {
  __typename: 'template_report_question_answer_radio_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_question_answer_radio_stddev_samp_fields = {
  __typename: 'template_report_question_answer_radio_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_question_answer_radio" */
export type template_report_question_answer_radio_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_question_answer_radio_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_question_answer_radio_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  question_id?: InputMaybe<Scalars['Int']['input']>;
  question_option_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_question_answer_radio_sum_fields = {
  __typename: 'template_report_question_answer_radio_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  question_id?: Maybe<Scalars['Int']['output']>;
  question_option_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_question_answer_radio" */
export enum template_report_question_answer_radio_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  count = 'count',
  /** column name */
  date = 'date',
  /** column name */
  question_id = 'question_id',
  /** column name */
  question_option_id = 'question_option_id'
}

export type template_report_question_answer_radio_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_question_answer_radio_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_question_answer_radio_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_question_answer_radio_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_question_answer_radio_var_pop_fields = {
  __typename: 'template_report_question_answer_radio_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_question_answer_radio_var_samp_fields = {
  __typename: 'template_report_question_answer_radio_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_question_answer_radio_variance_fields = {
  __typename: 'template_report_question_answer_radio_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Float']['output']>;
  question_id?: Maybe<Scalars['Float']['output']>;
  question_option_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment = {
  __typename: 'template_report_question_answer_sentiment';
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  mixed: Scalars['Int']['output'];
  negative: Scalars['Int']['output'];
  neutral: Scalars['Int']['output'];
  positive: Scalars['Int']['output'];
};

export type template_report_question_answer_sentiment_aggregate = {
  __typename: 'template_report_question_answer_sentiment_aggregate';
  aggregate?: Maybe<template_report_question_answer_sentiment_aggregate_fields>;
  nodes: Array<template_report_question_answer_sentiment>;
};

/** aggregate fields of "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_aggregate_fields = {
  __typename: 'template_report_question_answer_sentiment_aggregate_fields';
  avg?: Maybe<template_report_question_answer_sentiment_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_question_answer_sentiment_max_fields>;
  min?: Maybe<template_report_question_answer_sentiment_min_fields>;
  stddev?: Maybe<template_report_question_answer_sentiment_stddev_fields>;
  stddev_pop?: Maybe<template_report_question_answer_sentiment_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_question_answer_sentiment_stddev_samp_fields>;
  sum?: Maybe<template_report_question_answer_sentiment_sum_fields>;
  var_pop?: Maybe<template_report_question_answer_sentiment_var_pop_fields>;
  var_samp?: Maybe<template_report_question_answer_sentiment_var_samp_fields>;
  variance?: Maybe<template_report_question_answer_sentiment_variance_fields>;
};


/** aggregate fields of "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_question_answer_sentiment_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_question_answer_sentiment_avg_fields = {
  __typename: 'template_report_question_answer_sentiment_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_question_answer_sentiment". All fields are combined with a logical 'AND'. */
export type template_report_question_answer_sentiment_bool_exp = {
  _and?: InputMaybe<Array<template_report_question_answer_sentiment_bool_exp>>;
  _not?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
  _or?: InputMaybe<Array<template_report_question_answer_sentiment_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  mixed?: InputMaybe<Int_comparison_exp>;
  negative?: InputMaybe<Int_comparison_exp>;
  neutral?: InputMaybe<Int_comparison_exp>;
  positive?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_question_answer_sentiment" */
export enum template_report_question_answer_sentiment_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_question_answer_sentiment_pkey = 'template_report_question_answer_sentiment_pkey'
}

/** input type for incrementing numeric columns in table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_question_answer_sentiment_max_fields = {
  __typename: 'template_report_question_answer_sentiment_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_question_answer_sentiment_min_fields = {
  __typename: 'template_report_question_answer_sentiment_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_mutation_response = {
  __typename: 'template_report_question_answer_sentiment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_question_answer_sentiment>;
};

/** on_conflict condition type for table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_on_conflict = {
  constraint: template_report_question_answer_sentiment_constraint;
  update_columns?: Array<template_report_question_answer_sentiment_update_column>;
  where?: InputMaybe<template_report_question_answer_sentiment_bool_exp>;
};

/** Ordering options when selecting data from "template_report_question_answer_sentiment". */
export type template_report_question_answer_sentiment_order_by = {
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  mixed?: InputMaybe<order_by>;
  negative?: InputMaybe<order_by>;
  neutral?: InputMaybe<order_by>;
  positive?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_question_answer_sentiment */
export type template_report_question_answer_sentiment_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_question_answer_sentiment" */
export enum template_report_question_answer_sentiment_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  mixed = 'mixed',
  /** column name */
  negative = 'negative',
  /** column name */
  neutral = 'neutral',
  /** column name */
  positive = 'positive'
}

/** input type for updating data in table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_question_answer_sentiment_stddev_fields = {
  __typename: 'template_report_question_answer_sentiment_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_question_answer_sentiment_stddev_pop_fields = {
  __typename: 'template_report_question_answer_sentiment_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_question_answer_sentiment_stddev_samp_fields = {
  __typename: 'template_report_question_answer_sentiment_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_question_answer_sentiment" */
export type template_report_question_answer_sentiment_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_question_answer_sentiment_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_question_answer_sentiment_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  mixed?: InputMaybe<Scalars['Int']['input']>;
  negative?: InputMaybe<Scalars['Int']['input']>;
  neutral?: InputMaybe<Scalars['Int']['input']>;
  positive?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_question_answer_sentiment_sum_fields = {
  __typename: 'template_report_question_answer_sentiment_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  mixed?: Maybe<Scalars['Int']['output']>;
  negative?: Maybe<Scalars['Int']['output']>;
  neutral?: Maybe<Scalars['Int']['output']>;
  positive?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_question_answer_sentiment" */
export enum template_report_question_answer_sentiment_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  mixed = 'mixed',
  /** column name */
  negative = 'negative',
  /** column name */
  neutral = 'neutral',
  /** column name */
  positive = 'positive'
}

export type template_report_question_answer_sentiment_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_question_answer_sentiment_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_question_answer_sentiment_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_question_answer_sentiment_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_question_answer_sentiment_var_pop_fields = {
  __typename: 'template_report_question_answer_sentiment_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_question_answer_sentiment_var_samp_fields = {
  __typename: 'template_report_question_answer_sentiment_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_question_answer_sentiment_variance_fields = {
  __typename: 'template_report_question_answer_sentiment_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  mixed?: Maybe<Scalars['Float']['output']>;
  negative?: Maybe<Scalars['Float']['output']>;
  neutral?: Maybe<Scalars['Float']['output']>;
  positive?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_retained_revenue" */
export type template_report_retained_revenue = {
  __typename: 'template_report_retained_revenue';
  account_id: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  deflected: Scalars['Int']['output'];
  saved: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type template_report_retained_revenue_aggregate = {
  __typename: 'template_report_retained_revenue_aggregate';
  aggregate?: Maybe<template_report_retained_revenue_aggregate_fields>;
  nodes: Array<template_report_retained_revenue>;
};

/** aggregate fields of "template_report_retained_revenue" */
export type template_report_retained_revenue_aggregate_fields = {
  __typename: 'template_report_retained_revenue_aggregate_fields';
  avg?: Maybe<template_report_retained_revenue_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_retained_revenue_max_fields>;
  min?: Maybe<template_report_retained_revenue_min_fields>;
  stddev?: Maybe<template_report_retained_revenue_stddev_fields>;
  stddev_pop?: Maybe<template_report_retained_revenue_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_retained_revenue_stddev_samp_fields>;
  sum?: Maybe<template_report_retained_revenue_sum_fields>;
  var_pop?: Maybe<template_report_retained_revenue_var_pop_fields>;
  var_samp?: Maybe<template_report_retained_revenue_var_samp_fields>;
  variance?: Maybe<template_report_retained_revenue_variance_fields>;
};


/** aggregate fields of "template_report_retained_revenue" */
export type template_report_retained_revenue_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_retained_revenue_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_retained_revenue_avg_fields = {
  __typename: 'template_report_retained_revenue_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_retained_revenue". All fields are combined with a logical 'AND'. */
export type template_report_retained_revenue_bool_exp = {
  _and?: InputMaybe<Array<template_report_retained_revenue_bool_exp>>;
  _not?: InputMaybe<template_report_retained_revenue_bool_exp>;
  _or?: InputMaybe<Array<template_report_retained_revenue_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  deflected?: InputMaybe<Int_comparison_exp>;
  saved?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_retained_revenue" */
export enum template_report_retained_revenue_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_retained_revenue_pkey = 'template_report_retained_revenue_pkey'
}

/** input type for incrementing numeric columns in table "template_report_retained_revenue" */
export type template_report_retained_revenue_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_retained_revenue" */
export type template_report_retained_revenue_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_retained_revenue_max_fields = {
  __typename: 'template_report_retained_revenue_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_retained_revenue_min_fields = {
  __typename: 'template_report_retained_revenue_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_retained_revenue" */
export type template_report_retained_revenue_mutation_response = {
  __typename: 'template_report_retained_revenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_retained_revenue>;
};

/** on_conflict condition type for table "template_report_retained_revenue" */
export type template_report_retained_revenue_on_conflict = {
  constraint: template_report_retained_revenue_constraint;
  update_columns?: Array<template_report_retained_revenue_update_column>;
  where?: InputMaybe<template_report_retained_revenue_bool_exp>;
};

/** Ordering options when selecting data from "template_report_retained_revenue". */
export type template_report_retained_revenue_order_by = {
  account_id?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  deflected?: InputMaybe<order_by>;
  saved?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_retained_revenue */
export type template_report_retained_revenue_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_retained_revenue" */
export enum template_report_retained_revenue_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_retained_revenue" */
export type template_report_retained_revenue_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_retained_revenue_stddev_fields = {
  __typename: 'template_report_retained_revenue_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_retained_revenue_stddev_pop_fields = {
  __typename: 'template_report_retained_revenue_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_retained_revenue_stddev_samp_fields = {
  __typename: 'template_report_retained_revenue_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_retained_revenue" */
export type template_report_retained_revenue_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_retained_revenue_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_retained_revenue_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_retained_revenue_sum_fields = {
  __typename: 'template_report_retained_revenue_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_retained_revenue" */
export enum template_report_retained_revenue_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

export type template_report_retained_revenue_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_retained_revenue_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_retained_revenue_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_retained_revenue_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_retained_revenue_var_pop_fields = {
  __typename: 'template_report_retained_revenue_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_retained_revenue_var_samp_fields = {
  __typename: 'template_report_retained_revenue_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_retained_revenue_variance_fields = {
  __typename: 'template_report_retained_revenue_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_retention_trends" */
export type template_report_retention_trends = {
  __typename: 'template_report_retention_trends';
  account_id: Scalars['Int']['output'];
  canceled: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  deflected: Scalars['Int']['output'];
  saved: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type template_report_retention_trends_aggregate = {
  __typename: 'template_report_retention_trends_aggregate';
  aggregate?: Maybe<template_report_retention_trends_aggregate_fields>;
  nodes: Array<template_report_retention_trends>;
};

/** aggregate fields of "template_report_retention_trends" */
export type template_report_retention_trends_aggregate_fields = {
  __typename: 'template_report_retention_trends_aggregate_fields';
  avg?: Maybe<template_report_retention_trends_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_retention_trends_max_fields>;
  min?: Maybe<template_report_retention_trends_min_fields>;
  stddev?: Maybe<template_report_retention_trends_stddev_fields>;
  stddev_pop?: Maybe<template_report_retention_trends_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_retention_trends_stddev_samp_fields>;
  sum?: Maybe<template_report_retention_trends_sum_fields>;
  var_pop?: Maybe<template_report_retention_trends_var_pop_fields>;
  var_samp?: Maybe<template_report_retention_trends_var_samp_fields>;
  variance?: Maybe<template_report_retention_trends_variance_fields>;
};


/** aggregate fields of "template_report_retention_trends" */
export type template_report_retention_trends_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_retention_trends_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_retention_trends_avg_fields = {
  __typename: 'template_report_retention_trends_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_retention_trends". All fields are combined with a logical 'AND'. */
export type template_report_retention_trends_bool_exp = {
  _and?: InputMaybe<Array<template_report_retention_trends_bool_exp>>;
  _not?: InputMaybe<template_report_retention_trends_bool_exp>;
  _or?: InputMaybe<Array<template_report_retention_trends_bool_exp>>;
  account_id?: InputMaybe<Int_comparison_exp>;
  canceled?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  deflected?: InputMaybe<Int_comparison_exp>;
  saved?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_retention_trends" */
export enum template_report_retention_trends_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_retention_trends_pkey = 'template_report_retention_trends_pkey'
}

/** input type for incrementing numeric columns in table "template_report_retention_trends" */
export type template_report_retention_trends_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_retention_trends" */
export type template_report_retention_trends_insert_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_retention_trends_max_fields = {
  __typename: 'template_report_retention_trends_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_retention_trends_min_fields = {
  __typename: 'template_report_retention_trends_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_retention_trends" */
export type template_report_retention_trends_mutation_response = {
  __typename: 'template_report_retention_trends_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_retention_trends>;
};

/** on_conflict condition type for table "template_report_retention_trends" */
export type template_report_retention_trends_on_conflict = {
  constraint: template_report_retention_trends_constraint;
  update_columns?: Array<template_report_retention_trends_update_column>;
  where?: InputMaybe<template_report_retention_trends_bool_exp>;
};

/** Ordering options when selecting data from "template_report_retention_trends". */
export type template_report_retention_trends_order_by = {
  account_id?: InputMaybe<order_by>;
  canceled?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  deflected?: InputMaybe<order_by>;
  saved?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_retention_trends */
export type template_report_retention_trends_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_retention_trends" */
export enum template_report_retention_trends_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_retention_trends" */
export type template_report_retention_trends_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_retention_trends_stddev_fields = {
  __typename: 'template_report_retention_trends_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_retention_trends_stddev_pop_fields = {
  __typename: 'template_report_retention_trends_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_retention_trends_stddev_samp_fields = {
  __typename: 'template_report_retention_trends_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_retention_trends" */
export type template_report_retention_trends_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_retention_trends_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_retention_trends_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_retention_trends_sum_fields = {
  __typename: 'template_report_retention_trends_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_retention_trends" */
export enum template_report_retention_trends_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

export type template_report_retention_trends_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_retention_trends_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_retention_trends_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_retention_trends_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_retention_trends_var_pop_fields = {
  __typename: 'template_report_retention_trends_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_retention_trends_var_samp_fields = {
  __typename: 'template_report_retention_trends_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_retention_trends_variance_fields = {
  __typename: 'template_report_retention_trends_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status = {
  __typename: 'template_report_subscriber_flow_status';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  canceled: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  deflected: Scalars['Int']['output'];
  incomplete: Scalars['Int']['output'];
  saved: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type template_report_subscriber_flow_status_aggregate = {
  __typename: 'template_report_subscriber_flow_status_aggregate';
  aggregate?: Maybe<template_report_subscriber_flow_status_aggregate_fields>;
  nodes: Array<template_report_subscriber_flow_status>;
};

/** aggregate fields of "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_aggregate_fields = {
  __typename: 'template_report_subscriber_flow_status_aggregate_fields';
  avg?: Maybe<template_report_subscriber_flow_status_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<template_report_subscriber_flow_status_max_fields>;
  min?: Maybe<template_report_subscriber_flow_status_min_fields>;
  stddev?: Maybe<template_report_subscriber_flow_status_stddev_fields>;
  stddev_pop?: Maybe<template_report_subscriber_flow_status_stddev_pop_fields>;
  stddev_samp?: Maybe<template_report_subscriber_flow_status_stddev_samp_fields>;
  sum?: Maybe<template_report_subscriber_flow_status_sum_fields>;
  var_pop?: Maybe<template_report_subscriber_flow_status_var_pop_fields>;
  var_samp?: Maybe<template_report_subscriber_flow_status_var_samp_fields>;
  variance?: Maybe<template_report_subscriber_flow_status_variance_fields>;
};


/** aggregate fields of "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<template_report_subscriber_flow_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type template_report_subscriber_flow_status_avg_fields = {
  __typename: 'template_report_subscriber_flow_status_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "template_report_subscriber_flow_status". All fields are combined with a logical 'AND'. */
export type template_report_subscriber_flow_status_bool_exp = {
  _and?: InputMaybe<Array<template_report_subscriber_flow_status_bool_exp>>;
  _not?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
  _or?: InputMaybe<Array<template_report_subscriber_flow_status_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  canceled?: InputMaybe<Int_comparison_exp>;
  date?: InputMaybe<String_comparison_exp>;
  deflected?: InputMaybe<Int_comparison_exp>;
  incomplete?: InputMaybe<Int_comparison_exp>;
  saved?: InputMaybe<Int_comparison_exp>;
  total?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "template_report_subscriber_flow_status" */
export enum template_report_subscriber_flow_status_constraint {
  /** unique or primary key constraint on columns "account_id", "date" */
  template_report_subscriber_flow_status_pkey = 'template_report_subscriber_flow_status_pkey'
}

/** input type for incrementing numeric columns in table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incomplete?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incomplete?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type template_report_subscriber_flow_status_max_fields = {
  __typename: 'template_report_subscriber_flow_status_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incomplete?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type template_report_subscriber_flow_status_min_fields = {
  __typename: 'template_report_subscriber_flow_status_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incomplete?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_mutation_response = {
  __typename: 'template_report_subscriber_flow_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<template_report_subscriber_flow_status>;
};

/** on_conflict condition type for table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_on_conflict = {
  constraint: template_report_subscriber_flow_status_constraint;
  update_columns?: Array<template_report_subscriber_flow_status_update_column>;
  where?: InputMaybe<template_report_subscriber_flow_status_bool_exp>;
};

/** Ordering options when selecting data from "template_report_subscriber_flow_status". */
export type template_report_subscriber_flow_status_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  canceled?: InputMaybe<order_by>;
  date?: InputMaybe<order_by>;
  deflected?: InputMaybe<order_by>;
  incomplete?: InputMaybe<order_by>;
  saved?: InputMaybe<order_by>;
  total?: InputMaybe<order_by>;
};

/** primary key columns input for table: template_report_subscriber_flow_status */
export type template_report_subscriber_flow_status_pk_columns_input = {
  account_id: Scalars['Int']['input'];
  date: Scalars['String']['input'];
};

/** select columns of table "template_report_subscriber_flow_status" */
export enum template_report_subscriber_flow_status_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  incomplete = 'incomplete',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

/** input type for updating data in table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incomplete?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type template_report_subscriber_flow_status_stddev_fields = {
  __typename: 'template_report_subscriber_flow_status_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type template_report_subscriber_flow_status_stddev_pop_fields = {
  __typename: 'template_report_subscriber_flow_status_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type template_report_subscriber_flow_status_stddev_samp_fields = {
  __typename: 'template_report_subscriber_flow_status_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "template_report_subscriber_flow_status" */
export type template_report_subscriber_flow_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: template_report_subscriber_flow_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type template_report_subscriber_flow_status_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  canceled?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deflected?: InputMaybe<Scalars['Int']['input']>;
  incomplete?: InputMaybe<Scalars['Int']['input']>;
  saved?: InputMaybe<Scalars['Int']['input']>;
  total?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type template_report_subscriber_flow_status_sum_fields = {
  __typename: 'template_report_subscriber_flow_status_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  deflected?: Maybe<Scalars['Int']['output']>;
  incomplete?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "template_report_subscriber_flow_status" */
export enum template_report_subscriber_flow_status_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  canceled = 'canceled',
  /** column name */
  date = 'date',
  /** column name */
  deflected = 'deflected',
  /** column name */
  incomplete = 'incomplete',
  /** column name */
  saved = 'saved',
  /** column name */
  total = 'total'
}

export type template_report_subscriber_flow_status_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<template_report_subscriber_flow_status_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<template_report_subscriber_flow_status_set_input>;
  /** filter the rows which have to be updated */
  where: template_report_subscriber_flow_status_bool_exp;
};

/** aggregate var_pop on columns */
export type template_report_subscriber_flow_status_var_pop_fields = {
  __typename: 'template_report_subscriber_flow_status_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type template_report_subscriber_flow_status_var_samp_fields = {
  __typename: 'template_report_subscriber_flow_status_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type template_report_subscriber_flow_status_variance_fields = {
  __typename: 'template_report_subscriber_flow_status_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  deflected?: Maybe<Scalars['Float']['output']>;
  incomplete?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type timestamptz_comparison_exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "translation" */
export type translation = {
  __typename: 'translation';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  flow_text_versions: Array<flow_text_version>;
  /** An aggregate relationship */
  flow_text_versions_aggregate: flow_text_version_aggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  translation_values: Array<translation_value>;
  /** An aggregate relationship */
  translation_values_aggregate: translation_value_aggregate;
};


/** columns and relationships of "translation" */
export type translationflow_text_versionsArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


/** columns and relationships of "translation" */
export type translationflow_text_versions_aggregateArgs = {
  distinct_on?: InputMaybe<Array<flow_text_version_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<flow_text_version_order_by>>;
  where?: InputMaybe<flow_text_version_bool_exp>;
};


/** columns and relationships of "translation" */
export type translationtranslation_valuesArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};


/** columns and relationships of "translation" */
export type translationtranslation_values_aggregateArgs = {
  distinct_on?: InputMaybe<Array<translation_value_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<translation_value_order_by>>;
  where?: InputMaybe<translation_value_bool_exp>;
};

/** aggregated selection of "translation" */
export type translation_aggregate = {
  __typename: 'translation_aggregate';
  aggregate?: Maybe<translation_aggregate_fields>;
  nodes: Array<translation>;
};

export type translation_aggregate_bool_exp = {
  count?: InputMaybe<translation_aggregate_bool_exp_count>;
};

export type translation_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<translation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<translation_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "translation" */
export type translation_aggregate_fields = {
  __typename: 'translation_aggregate_fields';
  avg?: Maybe<translation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<translation_max_fields>;
  min?: Maybe<translation_min_fields>;
  stddev?: Maybe<translation_stddev_fields>;
  stddev_pop?: Maybe<translation_stddev_pop_fields>;
  stddev_samp?: Maybe<translation_stddev_samp_fields>;
  sum?: Maybe<translation_sum_fields>;
  var_pop?: Maybe<translation_var_pop_fields>;
  var_samp?: Maybe<translation_var_samp_fields>;
  variance?: Maybe<translation_variance_fields>;
};


/** aggregate fields of "translation" */
export type translation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<translation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "translation" */
export type translation_aggregate_order_by = {
  avg?: InputMaybe<translation_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<translation_max_order_by>;
  min?: InputMaybe<translation_min_order_by>;
  stddev?: InputMaybe<translation_stddev_order_by>;
  stddev_pop?: InputMaybe<translation_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<translation_stddev_samp_order_by>;
  sum?: InputMaybe<translation_sum_order_by>;
  var_pop?: InputMaybe<translation_var_pop_order_by>;
  var_samp?: InputMaybe<translation_var_samp_order_by>;
  variance?: InputMaybe<translation_variance_order_by>;
};

/** input type for inserting array relation for remote table "translation" */
export type translation_arr_rel_insert_input = {
  data: Array<translation_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<translation_on_conflict>;
};

/** aggregate avg on columns */
export type translation_avg_fields = {
  __typename: 'translation_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "translation" */
export type translation_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "translation". All fields are combined with a logical 'AND'. */
export type translation_bool_exp = {
  _and?: InputMaybe<Array<translation_bool_exp>>;
  _not?: InputMaybe<translation_bool_exp>;
  _or?: InputMaybe<Array<translation_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  flow_text_versions?: InputMaybe<flow_text_version_bool_exp>;
  flow_text_versions_aggregate?: InputMaybe<flow_text_version_aggregate_bool_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  translation_values?: InputMaybe<translation_value_bool_exp>;
  translation_values_aggregate?: InputMaybe<translation_value_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "translation" */
export enum translation_constraint {
  /** unique or primary key constraint on columns "id" */
  translation_pkey = 'translation_pkey'
}

/** input type for incrementing numeric columns in table "translation" */
export type translation_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "translation" */
export type translation_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  flow_text_versions?: InputMaybe<flow_text_version_arr_rel_insert_input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  translation_values?: InputMaybe<translation_value_arr_rel_insert_input>;
};

/** aggregate max on columns */
export type translation_max_fields = {
  __typename: 'translation_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "translation" */
export type translation_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type translation_min_fields = {
  __typename: 'translation_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "translation" */
export type translation_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "translation" */
export type translation_mutation_response = {
  __typename: 'translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<translation>;
};

/** input type for inserting object relation for remote table "translation" */
export type translation_obj_rel_insert_input = {
  data: translation_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<translation_on_conflict>;
};

/** on_conflict condition type for table "translation" */
export type translation_on_conflict = {
  constraint: translation_constraint;
  update_columns?: Array<translation_update_column>;
  where?: InputMaybe<translation_bool_exp>;
};

/** Ordering options when selecting data from "translation". */
export type translation_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  flow_text_versions_aggregate?: InputMaybe<flow_text_version_aggregate_order_by>;
  id?: InputMaybe<order_by>;
  translation_values_aggregate?: InputMaybe<translation_value_aggregate_order_by>;
};

/** primary key columns input for table: translation */
export type translation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "translation" */
export enum translation_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id'
}

/** input type for updating data in table "translation" */
export type translation_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type translation_stddev_fields = {
  __typename: 'translation_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "translation" */
export type translation_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type translation_stddev_pop_fields = {
  __typename: 'translation_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "translation" */
export type translation_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type translation_stddev_samp_fields = {
  __typename: 'translation_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "translation" */
export type translation_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "translation" */
export type translation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: translation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type translation_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type translation_sum_fields = {
  __typename: 'translation_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "translation" */
export type translation_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "translation" */
export enum translation_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id'
}

export type translation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<translation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<translation_set_input>;
  /** filter the rows which have to be updated */
  where: translation_bool_exp;
};

/** columns and relationships of "translation_value" */
export type translation_value = {
  __typename: 'translation_value';
  created_at: Scalars['timestamptz']['output'];
  format?: Maybe<translation_value_format_enum>;
  language: language_enum;
  /** An object relationship */
  languageByLanguage: language;
  /** An object relationship */
  translation: translation;
  translation_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['jsonb']['output'];
};


/** columns and relationships of "translation_value" */
export type translation_valuevalueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "translation_value" */
export type translation_value_aggregate = {
  __typename: 'translation_value_aggregate';
  aggregate?: Maybe<translation_value_aggregate_fields>;
  nodes: Array<translation_value>;
};

export type translation_value_aggregate_bool_exp = {
  count?: InputMaybe<translation_value_aggregate_bool_exp_count>;
};

export type translation_value_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<translation_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<translation_value_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "translation_value" */
export type translation_value_aggregate_fields = {
  __typename: 'translation_value_aggregate_fields';
  avg?: Maybe<translation_value_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<translation_value_max_fields>;
  min?: Maybe<translation_value_min_fields>;
  stddev?: Maybe<translation_value_stddev_fields>;
  stddev_pop?: Maybe<translation_value_stddev_pop_fields>;
  stddev_samp?: Maybe<translation_value_stddev_samp_fields>;
  sum?: Maybe<translation_value_sum_fields>;
  var_pop?: Maybe<translation_value_var_pop_fields>;
  var_samp?: Maybe<translation_value_var_samp_fields>;
  variance?: Maybe<translation_value_variance_fields>;
};


/** aggregate fields of "translation_value" */
export type translation_value_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<translation_value_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "translation_value" */
export type translation_value_aggregate_order_by = {
  avg?: InputMaybe<translation_value_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<translation_value_max_order_by>;
  min?: InputMaybe<translation_value_min_order_by>;
  stddev?: InputMaybe<translation_value_stddev_order_by>;
  stddev_pop?: InputMaybe<translation_value_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<translation_value_stddev_samp_order_by>;
  sum?: InputMaybe<translation_value_sum_order_by>;
  var_pop?: InputMaybe<translation_value_var_pop_order_by>;
  var_samp?: InputMaybe<translation_value_var_samp_order_by>;
  variance?: InputMaybe<translation_value_variance_order_by>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type translation_value_append_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "translation_value" */
export type translation_value_arr_rel_insert_input = {
  data: Array<translation_value_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<translation_value_on_conflict>;
};

/** aggregate avg on columns */
export type translation_value_avg_fields = {
  __typename: 'translation_value_avg_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "translation_value" */
export type translation_value_avg_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "translation_value". All fields are combined with a logical 'AND'. */
export type translation_value_bool_exp = {
  _and?: InputMaybe<Array<translation_value_bool_exp>>;
  _not?: InputMaybe<translation_value_bool_exp>;
  _or?: InputMaybe<Array<translation_value_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  format?: InputMaybe<translation_value_format_enum_comparison_exp>;
  language?: InputMaybe<language_enum_comparison_exp>;
  languageByLanguage?: InputMaybe<language_bool_exp>;
  translation?: InputMaybe<translation_bool_exp>;
  translation_id?: InputMaybe<Int_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  value?: InputMaybe<jsonb_comparison_exp>;
};

/** unique or primary key constraints on table "translation_value" */
export enum translation_value_constraint {
  /** unique or primary key constraint on columns "language", "translation_id" */
  translation_value_pkey = 'translation_value_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type translation_value_delete_at_path_input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type translation_value_delete_elem_input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type translation_value_delete_key_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "translation_value_format" */
export type translation_value_format = {
  __typename: 'translation_value_format';
  value: Scalars['String']['output'];
};

/** aggregated selection of "translation_value_format" */
export type translation_value_format_aggregate = {
  __typename: 'translation_value_format_aggregate';
  aggregate?: Maybe<translation_value_format_aggregate_fields>;
  nodes: Array<translation_value_format>;
};

/** aggregate fields of "translation_value_format" */
export type translation_value_format_aggregate_fields = {
  __typename: 'translation_value_format_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<translation_value_format_max_fields>;
  min?: Maybe<translation_value_format_min_fields>;
};


/** aggregate fields of "translation_value_format" */
export type translation_value_format_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<translation_value_format_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "translation_value_format". All fields are combined with a logical 'AND'. */
export type translation_value_format_bool_exp = {
  _and?: InputMaybe<Array<translation_value_format_bool_exp>>;
  _not?: InputMaybe<translation_value_format_bool_exp>;
  _or?: InputMaybe<Array<translation_value_format_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "translation_value_format" */
export enum translation_value_format_constraint {
  /** unique or primary key constraint on columns "value" */
  translation_value_format_pkey = 'translation_value_format_pkey'
}

export enum translation_value_format_enum {
  lexical = 'lexical',
  slate = 'slate',
  text = 'text'
}

/** Boolean expression to compare columns of type "translation_value_format_enum". All fields are combined with logical 'AND'. */
export type translation_value_format_enum_comparison_exp = {
  _eq?: InputMaybe<translation_value_format_enum>;
  _in?: InputMaybe<Array<translation_value_format_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<translation_value_format_enum>;
  _nin?: InputMaybe<Array<translation_value_format_enum>>;
};

/** input type for inserting data into table "translation_value_format" */
export type translation_value_format_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type translation_value_format_max_fields = {
  __typename: 'translation_value_format_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type translation_value_format_min_fields = {
  __typename: 'translation_value_format_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "translation_value_format" */
export type translation_value_format_mutation_response = {
  __typename: 'translation_value_format_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<translation_value_format>;
};

/** on_conflict condition type for table "translation_value_format" */
export type translation_value_format_on_conflict = {
  constraint: translation_value_format_constraint;
  update_columns?: Array<translation_value_format_update_column>;
  where?: InputMaybe<translation_value_format_bool_exp>;
};

/** Ordering options when selecting data from "translation_value_format". */
export type translation_value_format_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: translation_value_format */
export type translation_value_format_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "translation_value_format" */
export enum translation_value_format_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "translation_value_format" */
export type translation_value_format_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "translation_value_format" */
export type translation_value_format_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: translation_value_format_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type translation_value_format_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "translation_value_format" */
export enum translation_value_format_update_column {
  /** column name */
  value = 'value'
}

export type translation_value_format_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<translation_value_format_set_input>;
  /** filter the rows which have to be updated */
  where: translation_value_format_bool_exp;
};

/** input type for incrementing numeric columns in table "translation_value" */
export type translation_value_inc_input = {
  translation_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "translation_value" */
export type translation_value_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  format?: InputMaybe<translation_value_format_enum>;
  language?: InputMaybe<language_enum>;
  languageByLanguage?: InputMaybe<language_obj_rel_insert_input>;
  translation?: InputMaybe<translation_obj_rel_insert_input>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type translation_value_max_fields = {
  __typename: 'translation_value_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "translation_value" */
export type translation_value_max_order_by = {
  created_at?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type translation_value_min_fields = {
  __typename: 'translation_value_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  translation_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "translation_value" */
export type translation_value_min_order_by = {
  created_at?: InputMaybe<order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "translation_value" */
export type translation_value_mutation_response = {
  __typename: 'translation_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<translation_value>;
};

/** on_conflict condition type for table "translation_value" */
export type translation_value_on_conflict = {
  constraint: translation_value_constraint;
  update_columns?: Array<translation_value_update_column>;
  where?: InputMaybe<translation_value_bool_exp>;
};

/** Ordering options when selecting data from "translation_value". */
export type translation_value_order_by = {
  created_at?: InputMaybe<order_by>;
  format?: InputMaybe<order_by>;
  language?: InputMaybe<order_by>;
  languageByLanguage?: InputMaybe<language_order_by>;
  translation?: InputMaybe<translation_order_by>;
  translation_id?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: translation_value */
export type translation_value_pk_columns_input = {
  language: language_enum;
  translation_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type translation_value_prepend_input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "translation_value" */
export enum translation_value_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  format = 'format',
  /** column name */
  language = 'language',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

/** input type for updating data in table "translation_value" */
export type translation_value_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  format?: InputMaybe<translation_value_format_enum>;
  language?: InputMaybe<language_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type translation_value_stddev_fields = {
  __typename: 'translation_value_stddev_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "translation_value" */
export type translation_value_stddev_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type translation_value_stddev_pop_fields = {
  __typename: 'translation_value_stddev_pop_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "translation_value" */
export type translation_value_stddev_pop_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type translation_value_stddev_samp_fields = {
  __typename: 'translation_value_stddev_samp_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "translation_value" */
export type translation_value_stddev_samp_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "translation_value" */
export type translation_value_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: translation_value_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type translation_value_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  format?: InputMaybe<translation_value_format_enum>;
  language?: InputMaybe<language_enum>;
  translation_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type translation_value_sum_fields = {
  __typename: 'translation_value_sum_fields';
  translation_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "translation_value" */
export type translation_value_sum_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** update columns of table "translation_value" */
export enum translation_value_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  format = 'format',
  /** column name */
  language = 'language',
  /** column name */
  translation_id = 'translation_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value'
}

export type translation_value_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<translation_value_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<translation_value_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<translation_value_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<translation_value_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<translation_value_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<translation_value_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<translation_value_set_input>;
  /** filter the rows which have to be updated */
  where: translation_value_bool_exp;
};

/** aggregate var_pop on columns */
export type translation_value_var_pop_fields = {
  __typename: 'translation_value_var_pop_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "translation_value" */
export type translation_value_var_pop_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type translation_value_var_samp_fields = {
  __typename: 'translation_value_var_samp_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "translation_value" */
export type translation_value_var_samp_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type translation_value_variance_fields = {
  __typename: 'translation_value_variance_fields';
  translation_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "translation_value" */
export type translation_value_variance_order_by = {
  translation_id?: InputMaybe<order_by>;
};

/** aggregate var_pop on columns */
export type translation_var_pop_fields = {
  __typename: 'translation_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "translation" */
export type translation_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type translation_var_samp_fields = {
  __typename: 'translation_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "translation" */
export type translation_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type translation_variance_fields = {
  __typename: 'translation_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "translation" */
export type translation_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to compare columns of type "tsvector". All fields are combined with logical 'AND'. */
export type tsvector_comparison_exp = {
  _eq?: InputMaybe<Scalars['tsvector']['input']>;
  _gt?: InputMaybe<Scalars['tsvector']['input']>;
  _gte?: InputMaybe<Scalars['tsvector']['input']>;
  _in?: InputMaybe<Array<Scalars['tsvector']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['tsvector']['input']>;
  _lte?: InputMaybe<Scalars['tsvector']['input']>;
  _neq?: InputMaybe<Scalars['tsvector']['input']>;
  _nin?: InputMaybe<Array<Scalars['tsvector']['input']>>;
};

/** columns and relationships of "user" */
export type user = {
  __typename: 'user';
  /** An array relationship */
  account_user_settings: Array<account_user_setting>;
  /** An aggregate relationship */
  account_user_settings_aggregate: account_user_setting_aggregate;
  /** An array relationship */
  account_users: Array<account_user>;
  /** An aggregate relationship */
  account_users_aggregate: account_user_aggregate;
  activated_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['String']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  given_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  owned_accounts: Array<account>;
  /** An aggregate relationship */
  owned_accounts_aggregate: account_aggregate;
  picture?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_invitations: Array<user_invitation>;
  /** An aggregate relationship */
  user_invitations_aggregate: user_invitation_aggregate;
  /** An array relationship */
  user_profiles: Array<user_profile>;
  /** An aggregate relationship */
  user_profiles_aggregate: user_profile_aggregate;
};


/** columns and relationships of "user" */
export type useraccount_user_settingsArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


/** columns and relationships of "user" */
export type useraccount_user_settings_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_setting_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_setting_order_by>>;
  where?: InputMaybe<account_user_setting_bool_exp>;
};


/** columns and relationships of "user" */
export type useraccount_usersArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


/** columns and relationships of "user" */
export type useraccount_users_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_user_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_user_order_by>>;
  where?: InputMaybe<account_user_bool_exp>;
};


/** columns and relationships of "user" */
export type userowned_accountsArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


/** columns and relationships of "user" */
export type userowned_accounts_aggregateArgs = {
  distinct_on?: InputMaybe<Array<account_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<account_order_by>>;
  where?: InputMaybe<account_bool_exp>;
};


/** columns and relationships of "user" */
export type useruser_invitationsArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


/** columns and relationships of "user" */
export type useruser_invitations_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_invitation_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_invitation_order_by>>;
  where?: InputMaybe<user_invitation_bool_exp>;
};


/** columns and relationships of "user" */
export type useruser_profilesArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};


/** columns and relationships of "user" */
export type useruser_profiles_aggregateArgs = {
  distinct_on?: InputMaybe<Array<user_profile_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<user_profile_order_by>>;
  where?: InputMaybe<user_profile_bool_exp>;
};

/** aggregated selection of "user" */
export type user_aggregate = {
  __typename: 'user_aggregate';
  aggregate?: Maybe<user_aggregate_fields>;
  nodes: Array<user>;
};

/** aggregate fields of "user" */
export type user_aggregate_fields = {
  __typename: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<user_max_fields>;
  min?: Maybe<user_min_fields>;
};


/** aggregate fields of "user" */
export type user_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type user_bool_exp = {
  _and?: InputMaybe<Array<user_bool_exp>>;
  _not?: InputMaybe<user_bool_exp>;
  _or?: InputMaybe<Array<user_bool_exp>>;
  account_user_settings?: InputMaybe<account_user_setting_bool_exp>;
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_bool_exp>;
  account_users?: InputMaybe<account_user_bool_exp>;
  account_users_aggregate?: InputMaybe<account_user_aggregate_bool_exp>;
  activated_at?: InputMaybe<timestamptz_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  family_name?: InputMaybe<String_comparison_exp>;
  given_name?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<String_comparison_exp>;
  name?: InputMaybe<String_comparison_exp>;
  owned_accounts?: InputMaybe<account_bool_exp>;
  owned_accounts_aggregate?: InputMaybe<account_aggregate_bool_exp>;
  picture?: InputMaybe<String_comparison_exp>;
  provider?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user_invitations?: InputMaybe<user_invitation_bool_exp>;
  user_invitations_aggregate?: InputMaybe<user_invitation_aggregate_bool_exp>;
  user_profiles?: InputMaybe<user_profile_bool_exp>;
  user_profiles_aggregate?: InputMaybe<user_profile_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "user" */
export enum user_constraint {
  /** unique or primary key constraint on columns "id" */
  user_pkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type user_insert_input = {
  account_user_settings?: InputMaybe<account_user_setting_arr_rel_insert_input>;
  account_users?: InputMaybe<account_user_arr_rel_insert_input>;
  activated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owned_accounts?: InputMaybe<account_arr_rel_insert_input>;
  picture?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_invitations?: InputMaybe<user_invitation_arr_rel_insert_input>;
  user_profiles?: InputMaybe<user_profile_arr_rel_insert_input>;
};

/** columns and relationships of "user_invitation" */
export type user_invitation = {
  __typename: 'user_invitation';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['String']['output'];
  email: Scalars['String']['output'];
  expires_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  is_owner: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
  /** An object relationship */
  user: user;
};

/** aggregated selection of "user_invitation" */
export type user_invitation_aggregate = {
  __typename: 'user_invitation_aggregate';
  aggregate?: Maybe<user_invitation_aggregate_fields>;
  nodes: Array<user_invitation>;
};

export type user_invitation_aggregate_bool_exp = {
  bool_and?: InputMaybe<user_invitation_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<user_invitation_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<user_invitation_aggregate_bool_exp_count>;
};

export type user_invitation_aggregate_bool_exp_bool_and = {
  arguments: user_invitation_select_column_user_invitation_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<user_invitation_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_invitation_aggregate_bool_exp_bool_or = {
  arguments: user_invitation_select_column_user_invitation_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<user_invitation_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type user_invitation_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_invitation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<user_invitation_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user_invitation" */
export type user_invitation_aggregate_fields = {
  __typename: 'user_invitation_aggregate_fields';
  avg?: Maybe<user_invitation_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<user_invitation_max_fields>;
  min?: Maybe<user_invitation_min_fields>;
  stddev?: Maybe<user_invitation_stddev_fields>;
  stddev_pop?: Maybe<user_invitation_stddev_pop_fields>;
  stddev_samp?: Maybe<user_invitation_stddev_samp_fields>;
  sum?: Maybe<user_invitation_sum_fields>;
  var_pop?: Maybe<user_invitation_var_pop_fields>;
  var_samp?: Maybe<user_invitation_var_samp_fields>;
  variance?: Maybe<user_invitation_variance_fields>;
};


/** aggregate fields of "user_invitation" */
export type user_invitation_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_invitation_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_invitation" */
export type user_invitation_aggregate_order_by = {
  avg?: InputMaybe<user_invitation_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_invitation_max_order_by>;
  min?: InputMaybe<user_invitation_min_order_by>;
  stddev?: InputMaybe<user_invitation_stddev_order_by>;
  stddev_pop?: InputMaybe<user_invitation_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<user_invitation_stddev_samp_order_by>;
  sum?: InputMaybe<user_invitation_sum_order_by>;
  var_pop?: InputMaybe<user_invitation_var_pop_order_by>;
  var_samp?: InputMaybe<user_invitation_var_samp_order_by>;
  variance?: InputMaybe<user_invitation_variance_order_by>;
};

/** input type for inserting array relation for remote table "user_invitation" */
export type user_invitation_arr_rel_insert_input = {
  data: Array<user_invitation_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_invitation_on_conflict>;
};

/** aggregate avg on columns */
export type user_invitation_avg_fields = {
  __typename: 'user_invitation_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_invitation" */
export type user_invitation_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "user_invitation". All fields are combined with a logical 'AND'. */
export type user_invitation_bool_exp = {
  _and?: InputMaybe<Array<user_invitation_bool_exp>>;
  _not?: InputMaybe<user_invitation_bool_exp>;
  _or?: InputMaybe<Array<user_invitation_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  created_by?: InputMaybe<String_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  expires_at?: InputMaybe<timestamptz_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  is_owner?: InputMaybe<Boolean_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
};

/** unique or primary key constraints on table "user_invitation" */
export enum user_invitation_constraint {
  /** unique or primary key constraint on columns "account_id", "email" */
  user_invitation_account_id_email_key = 'user_invitation_account_id_email_key',
  /** unique or primary key constraint on columns "id" */
  user_invitation_pkey = 'user_invitation_pkey',
  /** unique or primary key constraint on columns "token" */
  user_invitation_token_key = 'user_invitation_token_key'
}

/** input type for incrementing numeric columns in table "user_invitation" */
export type user_invitation_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_invitation" */
export type user_invitation_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type user_invitation_max_fields = {
  __typename: 'user_invitation_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_invitation" */
export type user_invitation_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_invitation_min_fields = {
  __typename: 'user_invitation_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_invitation" */
export type user_invitation_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user_invitation" */
export type user_invitation_mutation_response = {
  __typename: 'user_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<user_invitation>;
};

/** on_conflict condition type for table "user_invitation" */
export type user_invitation_on_conflict = {
  constraint: user_invitation_constraint;
  update_columns?: Array<user_invitation_update_column>;
  where?: InputMaybe<user_invitation_bool_exp>;
};

/** Ordering options when selecting data from "user_invitation". */
export type user_invitation_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  created_by?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  expires_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  is_owner?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
};

/** primary key columns input for table: user_invitation */
export type user_invitation_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "user_invitation" */
export enum user_invitation_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by = 'created_by',
  /** column name */
  email = 'email',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  id = 'id',
  /** column name */
  is_owner = 'is_owner',
  /** column name */
  token = 'token'
}

/** select "user_invitation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_invitation" */
export enum user_invitation_select_column_user_invitation_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  is_owner = 'is_owner'
}

/** select "user_invitation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_invitation" */
export enum user_invitation_select_column_user_invitation_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  is_owner = 'is_owner'
}

/** input type for updating data in table "user_invitation" */
export type user_invitation_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type user_invitation_stddev_fields = {
  __typename: 'user_invitation_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_invitation" */
export type user_invitation_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type user_invitation_stddev_pop_fields = {
  __typename: 'user_invitation_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_invitation" */
export type user_invitation_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type user_invitation_stddev_samp_fields = {
  __typename: 'user_invitation_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_invitation" */
export type user_invitation_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "user_invitation" */
export type user_invitation_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_invitation_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_invitation_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_owner?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type user_invitation_sum_fields = {
  __typename: 'user_invitation_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_invitation" */
export type user_invitation_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "user_invitation" */
export enum user_invitation_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by = 'created_by',
  /** column name */
  email = 'email',
  /** column name */
  expires_at = 'expires_at',
  /** column name */
  id = 'id',
  /** column name */
  is_owner = 'is_owner',
  /** column name */
  token = 'token'
}

export type user_invitation_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<user_invitation_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_invitation_set_input>;
  /** filter the rows which have to be updated */
  where: user_invitation_bool_exp;
};

/** aggregate var_pop on columns */
export type user_invitation_var_pop_fields = {
  __typename: 'user_invitation_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_invitation" */
export type user_invitation_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type user_invitation_var_samp_fields = {
  __typename: 'user_invitation_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_invitation" */
export type user_invitation_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type user_invitation_variance_fields = {
  __typename: 'user_invitation_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_invitation" */
export type user_invitation_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate max on columns */
export type user_max_fields = {
  __typename: 'user_max_fields';
  activated_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  given_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type user_min_fields = {
  __typename: 'user_min_fields';
  activated_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  given_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "user" */
export type user_mutation_response = {
  __typename: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<user>;
};

/** input type for inserting object relation for remote table "user" */
export type user_obj_rel_insert_input = {
  data: user_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<user_on_conflict>;
};

/** on_conflict condition type for table "user" */
export type user_on_conflict = {
  constraint: user_constraint;
  update_columns?: Array<user_update_column>;
  where?: InputMaybe<user_bool_exp>;
};

/** Ordering options when selecting data from "user". */
export type user_order_by = {
  account_user_settings_aggregate?: InputMaybe<account_user_setting_aggregate_order_by>;
  account_users_aggregate?: InputMaybe<account_user_aggregate_order_by>;
  activated_at?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  family_name?: InputMaybe<order_by>;
  given_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  name?: InputMaybe<order_by>;
  owned_accounts_aggregate?: InputMaybe<account_aggregate_order_by>;
  picture?: InputMaybe<order_by>;
  provider?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user_invitations_aggregate?: InputMaybe<user_invitation_aggregate_order_by>;
  user_profiles_aggregate?: InputMaybe<user_profile_aggregate_order_by>;
};

/** primary key columns input for table: user */
export type user_pk_columns_input = {
  id: Scalars['String']['input'];
};

/** columns and relationships of "user_profile" */
export type user_profile = {
  __typename: 'user_profile';
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: user;
};

/** aggregated selection of "user_profile" */
export type user_profile_aggregate = {
  __typename: 'user_profile_aggregate';
  aggregate?: Maybe<user_profile_aggregate_fields>;
  nodes: Array<user_profile>;
};

export type user_profile_aggregate_bool_exp = {
  count?: InputMaybe<user_profile_aggregate_bool_exp_count>;
};

export type user_profile_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<user_profile_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<user_profile_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "user_profile" */
export type user_profile_aggregate_fields = {
  __typename: 'user_profile_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<user_profile_max_fields>;
  min?: Maybe<user_profile_min_fields>;
};


/** aggregate fields of "user_profile" */
export type user_profile_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<user_profile_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_profile" */
export type user_profile_aggregate_order_by = {
  count?: InputMaybe<order_by>;
  max?: InputMaybe<user_profile_max_order_by>;
  min?: InputMaybe<user_profile_min_order_by>;
};

/** input type for inserting array relation for remote table "user_profile" */
export type user_profile_arr_rel_insert_input = {
  data: Array<user_profile_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<user_profile_on_conflict>;
};

/** Boolean expression to filter rows from the table "user_profile". All fields are combined with a logical 'AND'. */
export type user_profile_bool_exp = {
  _and?: InputMaybe<Array<user_profile_bool_exp>>;
  _not?: InputMaybe<user_profile_bool_exp>;
  _or?: InputMaybe<Array<user_profile_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  email?: InputMaybe<String_comparison_exp>;
  first_name?: InputMaybe<String_comparison_exp>;
  id?: InputMaybe<String_comparison_exp>;
  last_name?: InputMaybe<String_comparison_exp>;
  updated_at?: InputMaybe<timestamptz_comparison_exp>;
  user?: InputMaybe<user_bool_exp>;
};

/** unique or primary key constraints on table "user_profile" */
export enum user_profile_constraint {
  /** unique or primary key constraint on columns "id" */
  user_profile_pkey = 'user_profile_pkey'
}

/** input type for inserting data into table "user_profile" */
export type user_profile_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<user_obj_rel_insert_input>;
};

/** aggregate max on columns */
export type user_profile_max_fields = {
  __typename: 'user_profile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "user_profile" */
export type user_profile_max_order_by = {
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type user_profile_min_fields = {
  __typename: 'user_profile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "user_profile" */
export type user_profile_min_order_by = {
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
};

/** response of any mutation on the table "user_profile" */
export type user_profile_mutation_response = {
  __typename: 'user_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<user_profile>;
};

/** on_conflict condition type for table "user_profile" */
export type user_profile_on_conflict = {
  constraint: user_profile_constraint;
  update_columns?: Array<user_profile_update_column>;
  where?: InputMaybe<user_profile_bool_exp>;
};

/** Ordering options when selecting data from "user_profile". */
export type user_profile_order_by = {
  created_at?: InputMaybe<order_by>;
  email?: InputMaybe<order_by>;
  first_name?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  last_name?: InputMaybe<order_by>;
  updated_at?: InputMaybe<order_by>;
  user?: InputMaybe<user_order_by>;
};

/** primary key columns input for table: user_profile */
export type user_profile_pk_columns_input = {
  id: Scalars['String']['input'];
};

/** select columns of table "user_profile" */
export enum user_profile_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  last_name = 'last_name',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "user_profile" */
export type user_profile_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user_profile" */
export type user_profile_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_profile_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_profile_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user_profile" */
export enum user_profile_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  last_name = 'last_name',
  /** column name */
  updated_at = 'updated_at'
}

export type user_profile_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_profile_set_input>;
  /** filter the rows which have to be updated */
  where: user_profile_bool_exp;
};

/** select columns of table "user" */
export enum user_select_column {
  /** column name */
  activated_at = 'activated_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  family_name = 'family_name',
  /** column name */
  given_name = 'given_name',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  picture = 'picture',
  /** column name */
  provider = 'provider',
  /** column name */
  updated_at = 'updated_at'
}

/** input type for updating data in table "user" */
export type user_set_input = {
  activated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user" */
export type user_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: user_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type user_stream_cursor_value_input = {
  activated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user" */
export enum user_update_column {
  /** column name */
  activated_at = 'activated_at',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  family_name = 'family_name',
  /** column name */
  given_name = 'given_name',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  picture = 'picture',
  /** column name */
  provider = 'provider',
  /** column name */
  updated_at = 'updated_at'
}

export type user_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<user_set_input>;
  /** filter the rows which have to be updated */
  where: user_bool_exp;
};

/** columns and relationships of "webhook" */
export type webhook = {
  __typename: 'webhook';
  /** An object relationship */
  account: account;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  disabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
  /** An array relationship */
  webhook_events: Array<webhook_event>;
  /** An aggregate relationship */
  webhook_events_aggregate: webhook_event_aggregate;
};


/** columns and relationships of "webhook" */
export type webhookwebhook_eventsArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};


/** columns and relationships of "webhook" */
export type webhookwebhook_events_aggregateArgs = {
  distinct_on?: InputMaybe<Array<webhook_event_select_column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<webhook_event_order_by>>;
  where?: InputMaybe<webhook_event_bool_exp>;
};

/** aggregated selection of "webhook" */
export type webhook_aggregate = {
  __typename: 'webhook_aggregate';
  aggregate?: Maybe<webhook_aggregate_fields>;
  nodes: Array<webhook>;
};

export type webhook_aggregate_bool_exp = {
  bool_and?: InputMaybe<webhook_aggregate_bool_exp_bool_and>;
  bool_or?: InputMaybe<webhook_aggregate_bool_exp_bool_or>;
  count?: InputMaybe<webhook_aggregate_bool_exp_count>;
};

export type webhook_aggregate_bool_exp_bool_and = {
  arguments: webhook_select_column_webhook_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<webhook_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type webhook_aggregate_bool_exp_bool_or = {
  arguments: webhook_select_column_webhook_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<webhook_bool_exp>;
  predicate: Boolean_comparison_exp;
};

export type webhook_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<webhook_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<webhook_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "webhook" */
export type webhook_aggregate_fields = {
  __typename: 'webhook_aggregate_fields';
  avg?: Maybe<webhook_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_max_fields>;
  min?: Maybe<webhook_min_fields>;
  stddev?: Maybe<webhook_stddev_fields>;
  stddev_pop?: Maybe<webhook_stddev_pop_fields>;
  stddev_samp?: Maybe<webhook_stddev_samp_fields>;
  sum?: Maybe<webhook_sum_fields>;
  var_pop?: Maybe<webhook_var_pop_fields>;
  var_samp?: Maybe<webhook_var_samp_fields>;
  variance?: Maybe<webhook_variance_fields>;
};


/** aggregate fields of "webhook" */
export type webhook_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "webhook" */
export type webhook_aggregate_order_by = {
  avg?: InputMaybe<webhook_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<webhook_max_order_by>;
  min?: InputMaybe<webhook_min_order_by>;
  stddev?: InputMaybe<webhook_stddev_order_by>;
  stddev_pop?: InputMaybe<webhook_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<webhook_stddev_samp_order_by>;
  sum?: InputMaybe<webhook_sum_order_by>;
  var_pop?: InputMaybe<webhook_var_pop_order_by>;
  var_samp?: InputMaybe<webhook_var_samp_order_by>;
  variance?: InputMaybe<webhook_variance_order_by>;
};

/** input type for inserting array relation for remote table "webhook" */
export type webhook_arr_rel_insert_input = {
  data: Array<webhook_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<webhook_on_conflict>;
};

/** aggregate avg on columns */
export type webhook_avg_fields = {
  __typename: 'webhook_avg_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "webhook" */
export type webhook_avg_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "webhook". All fields are combined with a logical 'AND'. */
export type webhook_bool_exp = {
  _and?: InputMaybe<Array<webhook_bool_exp>>;
  _not?: InputMaybe<webhook_bool_exp>;
  _or?: InputMaybe<Array<webhook_bool_exp>>;
  account?: InputMaybe<account_bool_exp>;
  account_id?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  deleted_at?: InputMaybe<timestamptz_comparison_exp>;
  disabled?: InputMaybe<Boolean_comparison_exp>;
  id?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  webhook_events?: InputMaybe<webhook_event_bool_exp>;
  webhook_events_aggregate?: InputMaybe<webhook_event_aggregate_bool_exp>;
};

/** unique or primary key constraints on table "webhook" */
export enum webhook_constraint {
  /** unique or primary key constraint on columns "id" */
  webhook_pkey = 'webhook_pkey',
  /** unique or primary key constraint on columns "token" */
  webhook_token_key = 'webhook_token_key'
}

/** columns and relationships of "webhook_event" */
export type webhook_event = {
  __typename: 'webhook_event';
  created_at: Scalars['timestamptz']['output'];
  event_type: event_type_enum;
  /** An object relationship */
  webhook: webhook;
  /** An object relationship */
  webhook_event_type: event_type;
  webhook_id: Scalars['Int']['output'];
};

/** aggregated selection of "webhook_event" */
export type webhook_event_aggregate = {
  __typename: 'webhook_event_aggregate';
  aggregate?: Maybe<webhook_event_aggregate_fields>;
  nodes: Array<webhook_event>;
};

export type webhook_event_aggregate_bool_exp = {
  count?: InputMaybe<webhook_event_aggregate_bool_exp_count>;
};

export type webhook_event_aggregate_bool_exp_count = {
  arguments?: InputMaybe<Array<webhook_event_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<webhook_event_bool_exp>;
  predicate: Int_comparison_exp;
};

/** aggregate fields of "webhook_event" */
export type webhook_event_aggregate_fields = {
  __typename: 'webhook_event_aggregate_fields';
  avg?: Maybe<webhook_event_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_event_max_fields>;
  min?: Maybe<webhook_event_min_fields>;
  stddev?: Maybe<webhook_event_stddev_fields>;
  stddev_pop?: Maybe<webhook_event_stddev_pop_fields>;
  stddev_samp?: Maybe<webhook_event_stddev_samp_fields>;
  sum?: Maybe<webhook_event_sum_fields>;
  var_pop?: Maybe<webhook_event_var_pop_fields>;
  var_samp?: Maybe<webhook_event_var_samp_fields>;
  variance?: Maybe<webhook_event_variance_fields>;
};


/** aggregate fields of "webhook_event" */
export type webhook_event_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_event_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "webhook_event" */
export type webhook_event_aggregate_order_by = {
  avg?: InputMaybe<webhook_event_avg_order_by>;
  count?: InputMaybe<order_by>;
  max?: InputMaybe<webhook_event_max_order_by>;
  min?: InputMaybe<webhook_event_min_order_by>;
  stddev?: InputMaybe<webhook_event_stddev_order_by>;
  stddev_pop?: InputMaybe<webhook_event_stddev_pop_order_by>;
  stddev_samp?: InputMaybe<webhook_event_stddev_samp_order_by>;
  sum?: InputMaybe<webhook_event_sum_order_by>;
  var_pop?: InputMaybe<webhook_event_var_pop_order_by>;
  var_samp?: InputMaybe<webhook_event_var_samp_order_by>;
  variance?: InputMaybe<webhook_event_variance_order_by>;
};

/** input type for inserting array relation for remote table "webhook_event" */
export type webhook_event_arr_rel_insert_input = {
  data: Array<webhook_event_insert_input>;
  /** upsert condition */
  on_conflict?: InputMaybe<webhook_event_on_conflict>;
};

/** aggregate avg on columns */
export type webhook_event_avg_fields = {
  __typename: 'webhook_event_avg_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "webhook_event" */
export type webhook_event_avg_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** Boolean expression to filter rows from the table "webhook_event". All fields are combined with a logical 'AND'. */
export type webhook_event_bool_exp = {
  _and?: InputMaybe<Array<webhook_event_bool_exp>>;
  _not?: InputMaybe<webhook_event_bool_exp>;
  _or?: InputMaybe<Array<webhook_event_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  event_type?: InputMaybe<event_type_enum_comparison_exp>;
  webhook?: InputMaybe<webhook_bool_exp>;
  webhook_event_type?: InputMaybe<event_type_bool_exp>;
  webhook_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "webhook_event" */
export enum webhook_event_constraint {
  /** unique or primary key constraint on columns "webhook_id", "event_type" */
  webhook_event_pkey = 'webhook_event_pkey'
}

/** input type for incrementing numeric columns in table "webhook_event" */
export type webhook_event_inc_input = {
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "webhook_event" */
export type webhook_event_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<event_type_enum>;
  webhook?: InputMaybe<webhook_obj_rel_insert_input>;
  webhook_event_type?: InputMaybe<event_type_obj_rel_insert_input>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type webhook_event_max_fields = {
  __typename: 'webhook_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "webhook_event" */
export type webhook_event_max_order_by = {
  created_at?: InputMaybe<order_by>;
  webhook_id?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type webhook_event_min_fields = {
  __typename: 'webhook_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "webhook_event" */
export type webhook_event_min_order_by = {
  created_at?: InputMaybe<order_by>;
  webhook_id?: InputMaybe<order_by>;
};

/** response of any mutation on the table "webhook_event" */
export type webhook_event_mutation_response = {
  __typename: 'webhook_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<webhook_event>;
};

/** on_conflict condition type for table "webhook_event" */
export type webhook_event_on_conflict = {
  constraint: webhook_event_constraint;
  update_columns?: Array<webhook_event_update_column>;
  where?: InputMaybe<webhook_event_bool_exp>;
};

/** Ordering options when selecting data from "webhook_event". */
export type webhook_event_order_by = {
  created_at?: InputMaybe<order_by>;
  event_type?: InputMaybe<order_by>;
  webhook?: InputMaybe<webhook_order_by>;
  webhook_event_type?: InputMaybe<event_type_order_by>;
  webhook_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: webhook_event */
export type webhook_event_pk_columns_input = {
  event_type: event_type_enum;
  webhook_id: Scalars['Int']['input'];
};

/** select columns of table "webhook_event" */
export enum webhook_event_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  event_type = 'event_type',
  /** column name */
  webhook_id = 'webhook_id'
}

/** input type for updating data in table "webhook_event" */
export type webhook_event_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<event_type_enum>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type webhook_event_stddev_fields = {
  __typename: 'webhook_event_stddev_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "webhook_event" */
export type webhook_event_stddev_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type webhook_event_stddev_pop_fields = {
  __typename: 'webhook_event_stddev_pop_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "webhook_event" */
export type webhook_event_stddev_pop_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type webhook_event_stddev_samp_fields = {
  __typename: 'webhook_event_stddev_samp_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "webhook_event" */
export type webhook_event_stddev_samp_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "webhook_event" */
export type webhook_event_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_event_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_event_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<event_type_enum>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type webhook_event_sum_fields = {
  __typename: 'webhook_event_sum_fields';
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "webhook_event" */
export type webhook_event_sum_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** update columns of table "webhook_event" */
export enum webhook_event_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  event_type = 'event_type',
  /** column name */
  webhook_id = 'webhook_id'
}

export type webhook_event_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<webhook_event_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<webhook_event_set_input>;
  /** filter the rows which have to be updated */
  where: webhook_event_bool_exp;
};

/** aggregate var_pop on columns */
export type webhook_event_var_pop_fields = {
  __typename: 'webhook_event_var_pop_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "webhook_event" */
export type webhook_event_var_pop_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type webhook_event_var_samp_fields = {
  __typename: 'webhook_event_var_samp_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "webhook_event" */
export type webhook_event_var_samp_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type webhook_event_variance_fields = {
  __typename: 'webhook_event_variance_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "webhook_event" */
export type webhook_event_variance_order_by = {
  webhook_id?: InputMaybe<order_by>;
};

/** input type for incrementing numeric columns in table "webhook" */
export type webhook_inc_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "webhook" */
export type webhook_insert_input = {
  account?: InputMaybe<account_obj_rel_insert_input>;
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  webhook_events?: InputMaybe<webhook_event_arr_rel_insert_input>;
};

/** columns and relationships of "webhook_log" */
export type webhook_log = {
  __typename: 'webhook_log';
  attempt: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  event: event;
  event_id: Scalars['Int']['output'];
  http_response_body?: Maybe<Scalars['String']['output']>;
  http_status_code: Scalars['Int']['output'];
  status: webhook_status_enum;
  /** An object relationship */
  webhook: webhook;
  webhook_id: Scalars['Int']['output'];
};

/** aggregated selection of "webhook_log" */
export type webhook_log_aggregate = {
  __typename: 'webhook_log_aggregate';
  aggregate?: Maybe<webhook_log_aggregate_fields>;
  nodes: Array<webhook_log>;
};

/** aggregate fields of "webhook_log" */
export type webhook_log_aggregate_fields = {
  __typename: 'webhook_log_aggregate_fields';
  avg?: Maybe<webhook_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_log_max_fields>;
  min?: Maybe<webhook_log_min_fields>;
  stddev?: Maybe<webhook_log_stddev_fields>;
  stddev_pop?: Maybe<webhook_log_stddev_pop_fields>;
  stddev_samp?: Maybe<webhook_log_stddev_samp_fields>;
  sum?: Maybe<webhook_log_sum_fields>;
  var_pop?: Maybe<webhook_log_var_pop_fields>;
  var_samp?: Maybe<webhook_log_var_samp_fields>;
  variance?: Maybe<webhook_log_variance_fields>;
};


/** aggregate fields of "webhook_log" */
export type webhook_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type webhook_log_avg_fields = {
  __typename: 'webhook_log_avg_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "webhook_log". All fields are combined with a logical 'AND'. */
export type webhook_log_bool_exp = {
  _and?: InputMaybe<Array<webhook_log_bool_exp>>;
  _not?: InputMaybe<webhook_log_bool_exp>;
  _or?: InputMaybe<Array<webhook_log_bool_exp>>;
  attempt?: InputMaybe<Int_comparison_exp>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  event?: InputMaybe<event_bool_exp>;
  event_id?: InputMaybe<Int_comparison_exp>;
  http_response_body?: InputMaybe<String_comparison_exp>;
  http_status_code?: InputMaybe<Int_comparison_exp>;
  status?: InputMaybe<webhook_status_enum_comparison_exp>;
  webhook?: InputMaybe<webhook_bool_exp>;
  webhook_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "webhook_log" */
export enum webhook_log_constraint {
  /** unique or primary key constraint on columns "webhook_id", "attempt", "event_id" */
  webhook_log_pkey = 'webhook_log_pkey'
}

/** input type for incrementing numeric columns in table "webhook_log" */
export type webhook_log_inc_input = {
  attempt?: InputMaybe<Scalars['Int']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  http_status_code?: InputMaybe<Scalars['Int']['input']>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "webhook_log" */
export type webhook_log_insert_input = {
  attempt?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<event_obj_rel_insert_input>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  http_response_body?: InputMaybe<Scalars['String']['input']>;
  http_status_code?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<webhook_status_enum>;
  webhook?: InputMaybe<webhook_obj_rel_insert_input>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type webhook_log_max_fields = {
  __typename: 'webhook_log_max_fields';
  attempt?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  http_response_body?: Maybe<Scalars['String']['output']>;
  http_status_code?: Maybe<Scalars['Int']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type webhook_log_min_fields = {
  __typename: 'webhook_log_min_fields';
  attempt?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  http_response_body?: Maybe<Scalars['String']['output']>;
  http_status_code?: Maybe<Scalars['Int']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "webhook_log" */
export type webhook_log_mutation_response = {
  __typename: 'webhook_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<webhook_log>;
};

/** on_conflict condition type for table "webhook_log" */
export type webhook_log_on_conflict = {
  constraint: webhook_log_constraint;
  update_columns?: Array<webhook_log_update_column>;
  where?: InputMaybe<webhook_log_bool_exp>;
};

/** Ordering options when selecting data from "webhook_log". */
export type webhook_log_order_by = {
  attempt?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  event?: InputMaybe<event_order_by>;
  event_id?: InputMaybe<order_by>;
  http_response_body?: InputMaybe<order_by>;
  http_status_code?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  webhook?: InputMaybe<webhook_order_by>;
  webhook_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: webhook_log */
export type webhook_log_pk_columns_input = {
  attempt: Scalars['Int']['input'];
  event_id: Scalars['Int']['input'];
  webhook_id: Scalars['Int']['input'];
};

/** select columns of table "webhook_log" */
export enum webhook_log_select_column {
  /** column name */
  attempt = 'attempt',
  /** column name */
  created_at = 'created_at',
  /** column name */
  event_id = 'event_id',
  /** column name */
  http_response_body = 'http_response_body',
  /** column name */
  http_status_code = 'http_status_code',
  /** column name */
  status = 'status',
  /** column name */
  webhook_id = 'webhook_id'
}

/** input type for updating data in table "webhook_log" */
export type webhook_log_set_input = {
  attempt?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  http_response_body?: InputMaybe<Scalars['String']['input']>;
  http_status_code?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<webhook_status_enum>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type webhook_log_stddev_fields = {
  __typename: 'webhook_log_stddev_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type webhook_log_stddev_pop_fields = {
  __typename: 'webhook_log_stddev_pop_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type webhook_log_stddev_samp_fields = {
  __typename: 'webhook_log_stddev_samp_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "webhook_log" */
export type webhook_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_log_stream_cursor_value_input = {
  attempt?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  http_response_body?: InputMaybe<Scalars['String']['input']>;
  http_status_code?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<webhook_status_enum>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type webhook_log_sum_fields = {
  __typename: 'webhook_log_sum_fields';
  attempt?: Maybe<Scalars['Int']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  http_status_code?: Maybe<Scalars['Int']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "webhook_log" */
export enum webhook_log_update_column {
  /** column name */
  attempt = 'attempt',
  /** column name */
  created_at = 'created_at',
  /** column name */
  event_id = 'event_id',
  /** column name */
  http_response_body = 'http_response_body',
  /** column name */
  http_status_code = 'http_status_code',
  /** column name */
  status = 'status',
  /** column name */
  webhook_id = 'webhook_id'
}

export type webhook_log_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<webhook_log_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<webhook_log_set_input>;
  /** filter the rows which have to be updated */
  where: webhook_log_bool_exp;
};

/** aggregate var_pop on columns */
export type webhook_log_var_pop_fields = {
  __typename: 'webhook_log_var_pop_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type webhook_log_var_samp_fields = {
  __typename: 'webhook_log_var_samp_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type webhook_log_variance_fields = {
  __typename: 'webhook_log_variance_fields';
  attempt?: Maybe<Scalars['Float']['output']>;
  event_id?: Maybe<Scalars['Float']['output']>;
  http_status_code?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type webhook_max_fields = {
  __typename: 'webhook_max_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "webhook" */
export type webhook_max_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** aggregate min on columns */
export type webhook_min_fields = {
  __typename: 'webhook_min_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "webhook" */
export type webhook_min_order_by = {
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
};

/** response of any mutation on the table "webhook" */
export type webhook_mutation_response = {
  __typename: 'webhook_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<webhook>;
};

/** input type for inserting object relation for remote table "webhook" */
export type webhook_obj_rel_insert_input = {
  data: webhook_insert_input;
  /** upsert condition */
  on_conflict?: InputMaybe<webhook_on_conflict>;
};

/** on_conflict condition type for table "webhook" */
export type webhook_on_conflict = {
  constraint: webhook_constraint;
  update_columns?: Array<webhook_update_column>;
  where?: InputMaybe<webhook_bool_exp>;
};

/** Ordering options when selecting data from "webhook". */
export type webhook_order_by = {
  account?: InputMaybe<account_order_by>;
  account_id?: InputMaybe<order_by>;
  created_at?: InputMaybe<order_by>;
  deleted_at?: InputMaybe<order_by>;
  disabled?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  webhook_events_aggregate?: InputMaybe<webhook_event_aggregate_order_by>;
};

/** primary key columns input for table: webhook */
export type webhook_pk_columns_input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "webhook_request_log" */
export type webhook_request_log = {
  __typename: 'webhook_request_log';
  created_at: Scalars['timestamptz']['output'];
  har: Scalars['jsonb']['output'];
  id: Scalars['bigint']['output'];
  method: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  url: Scalars['String']['output'];
  /** An object relationship */
  webhook: webhook;
  webhook_id: Scalars['Int']['output'];
};


/** columns and relationships of "webhook_request_log" */
export type webhook_request_logharArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

export type webhook_request_log_aggregate = {
  __typename: 'webhook_request_log_aggregate';
  aggregate?: Maybe<webhook_request_log_aggregate_fields>;
  nodes: Array<webhook_request_log>;
};

/** aggregate fields of "webhook_request_log" */
export type webhook_request_log_aggregate_fields = {
  __typename: 'webhook_request_log_aggregate_fields';
  avg?: Maybe<webhook_request_log_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_request_log_max_fields>;
  min?: Maybe<webhook_request_log_min_fields>;
  stddev?: Maybe<webhook_request_log_stddev_fields>;
  stddev_pop?: Maybe<webhook_request_log_stddev_pop_fields>;
  stddev_samp?: Maybe<webhook_request_log_stddev_samp_fields>;
  sum?: Maybe<webhook_request_log_sum_fields>;
  var_pop?: Maybe<webhook_request_log_var_pop_fields>;
  var_samp?: Maybe<webhook_request_log_var_samp_fields>;
  variance?: Maybe<webhook_request_log_variance_fields>;
};


/** aggregate fields of "webhook_request_log" */
export type webhook_request_log_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_request_log_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type webhook_request_log_append_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type webhook_request_log_avg_fields = {
  __typename: 'webhook_request_log_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "webhook_request_log". All fields are combined with a logical 'AND'. */
export type webhook_request_log_bool_exp = {
  _and?: InputMaybe<Array<webhook_request_log_bool_exp>>;
  _not?: InputMaybe<webhook_request_log_bool_exp>;
  _or?: InputMaybe<Array<webhook_request_log_bool_exp>>;
  created_at?: InputMaybe<timestamptz_comparison_exp>;
  har?: InputMaybe<jsonb_comparison_exp>;
  id?: InputMaybe<bigint_comparison_exp>;
  method?: InputMaybe<String_comparison_exp>;
  status?: InputMaybe<Int_comparison_exp>;
  token?: InputMaybe<String_comparison_exp>;
  url?: InputMaybe<String_comparison_exp>;
  webhook?: InputMaybe<webhook_bool_exp>;
  webhook_id?: InputMaybe<Int_comparison_exp>;
};

/** unique or primary key constraints on table "webhook_request_log" */
export enum webhook_request_log_constraint {
  /** unique or primary key constraint on columns "id" */
  webhook_request_log_pkey = 'webhook_request_log_pkey',
  /** unique or primary key constraint on columns "token" */
  webhook_request_log_token_key = 'webhook_request_log_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type webhook_request_log_delete_at_path_input = {
  har?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type webhook_request_log_delete_elem_input = {
  har?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type webhook_request_log_delete_key_input = {
  har?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "webhook_request_log" */
export type webhook_request_log_inc_input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "webhook_request_log" */
export type webhook_request_log_insert_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  webhook?: InputMaybe<webhook_obj_rel_insert_input>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type webhook_request_log_max_fields = {
  __typename: 'webhook_request_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type webhook_request_log_min_fields = {
  __typename: 'webhook_request_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  method?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "webhook_request_log" */
export type webhook_request_log_mutation_response = {
  __typename: 'webhook_request_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<webhook_request_log>;
};

/** on_conflict condition type for table "webhook_request_log" */
export type webhook_request_log_on_conflict = {
  constraint: webhook_request_log_constraint;
  update_columns?: Array<webhook_request_log_update_column>;
  where?: InputMaybe<webhook_request_log_bool_exp>;
};

/** Ordering options when selecting data from "webhook_request_log". */
export type webhook_request_log_order_by = {
  created_at?: InputMaybe<order_by>;
  har?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
  method?: InputMaybe<order_by>;
  status?: InputMaybe<order_by>;
  token?: InputMaybe<order_by>;
  url?: InputMaybe<order_by>;
  webhook?: InputMaybe<webhook_order_by>;
  webhook_id?: InputMaybe<order_by>;
};

/** primary key columns input for table: webhook_request_log */
export type webhook_request_log_pk_columns_input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type webhook_request_log_prepend_input = {
  har?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "webhook_request_log" */
export enum webhook_request_log_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url',
  /** column name */
  webhook_id = 'webhook_id'
}

/** input type for updating data in table "webhook_request_log" */
export type webhook_request_log_set_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type webhook_request_log_stddev_fields = {
  __typename: 'webhook_request_log_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type webhook_request_log_stddev_pop_fields = {
  __typename: 'webhook_request_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type webhook_request_log_stddev_samp_fields = {
  __typename: 'webhook_request_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "webhook_request_log" */
export type webhook_request_log_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_request_log_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_request_log_stream_cursor_value_input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  har?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type webhook_request_log_sum_fields = {
  __typename: 'webhook_request_log_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "webhook_request_log_tsvector" */
export type webhook_request_log_tsvector = {
  __typename: 'webhook_request_log_tsvector';
  tsvector?: Maybe<Scalars['tsvector']['output']>;
  webhook_id?: Maybe<Scalars['Int']['output']>;
  webhook_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "webhook_request_log_tsvector" */
export type webhook_request_log_tsvector_aggregate = {
  __typename: 'webhook_request_log_tsvector_aggregate';
  aggregate?: Maybe<webhook_request_log_tsvector_aggregate_fields>;
  nodes: Array<webhook_request_log_tsvector>;
};

/** aggregate fields of "webhook_request_log_tsvector" */
export type webhook_request_log_tsvector_aggregate_fields = {
  __typename: 'webhook_request_log_tsvector_aggregate_fields';
  avg?: Maybe<webhook_request_log_tsvector_avg_fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_request_log_tsvector_max_fields>;
  min?: Maybe<webhook_request_log_tsvector_min_fields>;
  stddev?: Maybe<webhook_request_log_tsvector_stddev_fields>;
  stddev_pop?: Maybe<webhook_request_log_tsvector_stddev_pop_fields>;
  stddev_samp?: Maybe<webhook_request_log_tsvector_stddev_samp_fields>;
  sum?: Maybe<webhook_request_log_tsvector_sum_fields>;
  var_pop?: Maybe<webhook_request_log_tsvector_var_pop_fields>;
  var_samp?: Maybe<webhook_request_log_tsvector_var_samp_fields>;
  variance?: Maybe<webhook_request_log_tsvector_variance_fields>;
};


/** aggregate fields of "webhook_request_log_tsvector" */
export type webhook_request_log_tsvector_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_request_log_tsvector_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type webhook_request_log_tsvector_avg_fields = {
  __typename: 'webhook_request_log_tsvector_avg_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "webhook_request_log_tsvector". All fields are combined with a logical 'AND'. */
export type webhook_request_log_tsvector_bool_exp = {
  _and?: InputMaybe<Array<webhook_request_log_tsvector_bool_exp>>;
  _not?: InputMaybe<webhook_request_log_tsvector_bool_exp>;
  _or?: InputMaybe<Array<webhook_request_log_tsvector_bool_exp>>;
  tsvector?: InputMaybe<tsvector_comparison_exp>;
  webhook_id?: InputMaybe<Int_comparison_exp>;
  webhook_request_log_id?: InputMaybe<bigint_comparison_exp>;
};

/** aggregate max on columns */
export type webhook_request_log_tsvector_max_fields = {
  __typename: 'webhook_request_log_tsvector_max_fields';
  webhook_id?: Maybe<Scalars['Int']['output']>;
  webhook_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type webhook_request_log_tsvector_min_fields = {
  __typename: 'webhook_request_log_tsvector_min_fields';
  webhook_id?: Maybe<Scalars['Int']['output']>;
  webhook_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "webhook_request_log_tsvector". */
export type webhook_request_log_tsvector_order_by = {
  tsvector?: InputMaybe<order_by>;
  webhook_id?: InputMaybe<order_by>;
  webhook_request_log_id?: InputMaybe<order_by>;
};

/** select columns of table "webhook_request_log_tsvector" */
export enum webhook_request_log_tsvector_select_column {
  /** column name */
  tsvector = 'tsvector',
  /** column name */
  webhook_id = 'webhook_id',
  /** column name */
  webhook_request_log_id = 'webhook_request_log_id'
}

/** aggregate stddev on columns */
export type webhook_request_log_tsvector_stddev_fields = {
  __typename: 'webhook_request_log_tsvector_stddev_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type webhook_request_log_tsvector_stddev_pop_fields = {
  __typename: 'webhook_request_log_tsvector_stddev_pop_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type webhook_request_log_tsvector_stddev_samp_fields = {
  __typename: 'webhook_request_log_tsvector_stddev_samp_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "webhook_request_log_tsvector" */
export type webhook_request_log_tsvector_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_request_log_tsvector_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_request_log_tsvector_stream_cursor_value_input = {
  tsvector?: InputMaybe<Scalars['tsvector']['input']>;
  webhook_id?: InputMaybe<Scalars['Int']['input']>;
  webhook_request_log_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type webhook_request_log_tsvector_sum_fields = {
  __typename: 'webhook_request_log_tsvector_sum_fields';
  webhook_id?: Maybe<Scalars['Int']['output']>;
  webhook_request_log_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type webhook_request_log_tsvector_var_pop_fields = {
  __typename: 'webhook_request_log_tsvector_var_pop_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type webhook_request_log_tsvector_var_samp_fields = {
  __typename: 'webhook_request_log_tsvector_var_samp_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type webhook_request_log_tsvector_variance_fields = {
  __typename: 'webhook_request_log_tsvector_variance_fields';
  webhook_id?: Maybe<Scalars['Float']['output']>;
  webhook_request_log_id?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "webhook_request_log" */
export enum webhook_request_log_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  har = 'har',
  /** column name */
  id = 'id',
  /** column name */
  method = 'method',
  /** column name */
  status = 'status',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url',
  /** column name */
  webhook_id = 'webhook_id'
}

export type webhook_request_log_updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<webhook_request_log_append_input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<webhook_request_log_delete_at_path_input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<webhook_request_log_delete_elem_input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<webhook_request_log_delete_key_input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<webhook_request_log_inc_input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<webhook_request_log_prepend_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<webhook_request_log_set_input>;
  /** filter the rows which have to be updated */
  where: webhook_request_log_bool_exp;
};

/** aggregate var_pop on columns */
export type webhook_request_log_var_pop_fields = {
  __typename: 'webhook_request_log_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type webhook_request_log_var_samp_fields = {
  __typename: 'webhook_request_log_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type webhook_request_log_variance_fields = {
  __typename: 'webhook_request_log_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Float']['output']>;
  webhook_id?: Maybe<Scalars['Float']['output']>;
};

/** select columns of table "webhook" */
export enum webhook_select_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  disabled = 'disabled',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

/** select "webhook_aggregate_bool_exp_bool_and_arguments_columns" columns of table "webhook" */
export enum webhook_select_column_webhook_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  disabled = 'disabled'
}

/** select "webhook_aggregate_bool_exp_bool_or_arguments_columns" columns of table "webhook" */
export enum webhook_select_column_webhook_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  disabled = 'disabled'
}

/** input type for updating data in table "webhook" */
export type webhook_set_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "webhook_status" */
export type webhook_status = {
  __typename: 'webhook_status';
  value: Scalars['String']['output'];
};

/** aggregated selection of "webhook_status" */
export type webhook_status_aggregate = {
  __typename: 'webhook_status_aggregate';
  aggregate?: Maybe<webhook_status_aggregate_fields>;
  nodes: Array<webhook_status>;
};

/** aggregate fields of "webhook_status" */
export type webhook_status_aggregate_fields = {
  __typename: 'webhook_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<webhook_status_max_fields>;
  min?: Maybe<webhook_status_min_fields>;
};


/** aggregate fields of "webhook_status" */
export type webhook_status_aggregate_fieldscountArgs = {
  columns?: InputMaybe<Array<webhook_status_select_column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "webhook_status". All fields are combined with a logical 'AND'. */
export type webhook_status_bool_exp = {
  _and?: InputMaybe<Array<webhook_status_bool_exp>>;
  _not?: InputMaybe<webhook_status_bool_exp>;
  _or?: InputMaybe<Array<webhook_status_bool_exp>>;
  value?: InputMaybe<String_comparison_exp>;
};

/** unique or primary key constraints on table "webhook_status" */
export enum webhook_status_constraint {
  /** unique or primary key constraint on columns "value" */
  webhook_status_pkey = 'webhook_status_pkey'
}

export enum webhook_status_enum {
  failed = 'failed',
  succeeded = 'succeeded'
}

/** Boolean expression to compare columns of type "webhook_status_enum". All fields are combined with logical 'AND'. */
export type webhook_status_enum_comparison_exp = {
  _eq?: InputMaybe<webhook_status_enum>;
  _in?: InputMaybe<Array<webhook_status_enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<webhook_status_enum>;
  _nin?: InputMaybe<Array<webhook_status_enum>>;
};

/** input type for inserting data into table "webhook_status" */
export type webhook_status_insert_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type webhook_status_max_fields = {
  __typename: 'webhook_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type webhook_status_min_fields = {
  __typename: 'webhook_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "webhook_status" */
export type webhook_status_mutation_response = {
  __typename: 'webhook_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<webhook_status>;
};

/** on_conflict condition type for table "webhook_status" */
export type webhook_status_on_conflict = {
  constraint: webhook_status_constraint;
  update_columns?: Array<webhook_status_update_column>;
  where?: InputMaybe<webhook_status_bool_exp>;
};

/** Ordering options when selecting data from "webhook_status". */
export type webhook_status_order_by = {
  value?: InputMaybe<order_by>;
};

/** primary key columns input for table: webhook_status */
export type webhook_status_pk_columns_input = {
  value: Scalars['String']['input'];
};

/** select columns of table "webhook_status" */
export enum webhook_status_select_column {
  /** column name */
  value = 'value'
}

/** input type for updating data in table "webhook_status" */
export type webhook_status_set_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "webhook_status" */
export type webhook_status_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_status_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_status_stream_cursor_value_input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "webhook_status" */
export enum webhook_status_update_column {
  /** column name */
  value = 'value'
}

export type webhook_status_updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<webhook_status_set_input>;
  /** filter the rows which have to be updated */
  where: webhook_status_bool_exp;
};

/** aggregate stddev on columns */
export type webhook_stddev_fields = {
  __typename: 'webhook_stddev_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "webhook" */
export type webhook_stddev_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_pop on columns */
export type webhook_stddev_pop_fields = {
  __typename: 'webhook_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "webhook" */
export type webhook_stddev_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate stddev_samp on columns */
export type webhook_stddev_samp_fields = {
  __typename: 'webhook_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "webhook" */
export type webhook_stddev_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** Streaming cursor of the table "webhook" */
export type webhook_stream_cursor_input = {
  /** Stream column input with initial value */
  initial_value: webhook_stream_cursor_value_input;
  /** cursor ordering */
  ordering?: InputMaybe<cursor_ordering>;
};

/** Initial value of the column from where the streaming should start */
export type webhook_stream_cursor_value_input = {
  account_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type webhook_sum_fields = {
  __typename: 'webhook_sum_fields';
  account_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "webhook" */
export type webhook_sum_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** update columns of table "webhook" */
export enum webhook_update_column {
  /** column name */
  account_id = 'account_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  deleted_at = 'deleted_at',
  /** column name */
  disabled = 'disabled',
  /** column name */
  id = 'id',
  /** column name */
  token = 'token',
  /** column name */
  url = 'url'
}

export type webhook_updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<webhook_inc_input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<webhook_set_input>;
  /** filter the rows which have to be updated */
  where: webhook_bool_exp;
};

/** aggregate var_pop on columns */
export type webhook_var_pop_fields = {
  __typename: 'webhook_var_pop_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "webhook" */
export type webhook_var_pop_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate var_samp on columns */
export type webhook_var_samp_fields = {
  __typename: 'webhook_var_samp_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "webhook" */
export type webhook_var_samp_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

/** aggregate variance on columns */
export type webhook_variance_fields = {
  __typename: 'webhook_variance_fields';
  account_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "webhook" */
export type webhook_variance_order_by = {
  account_id?: InputMaybe<order_by>;
  id?: InputMaybe<order_by>;
};

export type IndexRoutesDomainRouteQueryVariables = Exact<{
  input: DomainRouteInput;
}>;


export type IndexRoutesDomainRouteQuery = {
  domainRoute: {
    __typename: 'DomainRouteOutput',
    clientId?: string | null
  }
};

export type AppLoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;


export type AppLoginUserMutation = {
  loginUser?: {
    __typename: 'LoginUserOutput',
    success: boolean,
    refresh: boolean,
    accountMode?: string | null,
    accountStatus?: string | null
  } | null
};

export type MainRoutesPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type MainRoutesPaymentMethodQuery = {
  paymentMethod: {
    __typename: 'PaymentMethodOutput',
    stripePaymentMethodId?: string | null
  }
};

export type UseConfigureCommandsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseConfigureCommandsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string
        } | null
      }>
    } | null
  }>
};

export type BackfillCancelAtPeriodEndMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  cancelAtPeriodEnd: Scalars['Boolean']['input'];
}>;


export type BackfillCancelAtPeriodEndMutation = {
  update_platform_subscription?: {
    __typename: 'platform_subscription_mutation_response',
    affected_rows: number
  } | null
};

export type BackfillDatesUpdateCustomerDatesMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  createdAt: Scalars['timestamptz']['input'];
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type BackfillDatesUpdateCustomerDatesMutation = {
  update_platform_customer_by_pk?: {
    __typename: 'platform_customer',
    platform_id: string
  } | null
};

export type BackfillMetadataCustomerMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type BackfillMetadataCustomerMutation = {
  update_platform_customer_by_pk?: {
    __typename: 'platform_customer',
    platform_id: string
  } | null
};

export type BackfillMetadataSubscriptionMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type BackfillMetadataSubscriptionMutation = {
  update_platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_id: string
  } | null
};

export type BackfillOfferDowngradePlatformConnectionIdMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type BackfillOfferDowngradePlatformConnectionIdMutation = {
  update_offer_downgrade?: {
    __typename: 'offer_downgrade_mutation_response',
    affected_rows: number
  } | null
};

export type BackfillOfferUpgradePlatformConnectionIdMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type BackfillOfferUpgradePlatformConnectionIdMutation = {
  update_offer_upgrade?: {
    __typename: 'offer_upgrade_mutation_response',
    affected_rows: number
  } | null
};

export type BackfillOfferChangePlanPlatformConnectionIdMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type BackfillOfferChangePlanPlatformConnectionIdMutation = {
  update_offer_change_plan?: {
    __typename: 'offer_change_plan_mutation_response',
    affected_rows: number
  } | null
};

export type BackfillPastDueEndMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  pastDue: Scalars['Boolean']['input'];
}>;


export type BackfillPastDueEndMutation = {
  update_platform_subscription?: {
    __typename: 'platform_subscription_mutation_response',
    affected_rows: number
  } | null
};

export type CalculateCustomerDetailsQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type CalculateCustomerDetailsQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    platform_customer?: {
      __typename: 'platform_customer',
      platform_connection_id: number,
      platform_id: string,
      name: string,
      email: string,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        platform_connection_id: number,
        platform_id: string,
        platform_customer_id: string,
        status: platform_subscription_status_enum,
        started_at: string,
        canceled_at?: string | null,
        trial_end?: string | null,
        updated_at: string,
        next_billing_at?: string | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          quantity: string,
          price?: string | null,
          platform_addon?: {
            __typename: 'platform_addon',
            platform_connection_id: number,
            platform_id: string,
            name: string,
            platform_addon_prices: Array<{
              __typename: 'platform_addon_price',
              currency: platform_currency_enum,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              pricing_model: platform_plan_pricing_model_enum,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_addon_price_tiers: Array<{
                __typename: 'platform_addon_price_tier',
                up_to: number,
                price: string
              }>,
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_addon_price?: {
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_subscription?: {
            __typename: 'platform_subscription',
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              quantity: string,
              price?: string | null,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              platform_plan?: {
                __typename: 'platform_plan',
                platform_connection_id: number,
                platform_id: string,
                platform_product_id: string,
                name: string,
                currency: platform_currency_enum,
                interval: platform_plan_interval_enum,
                interval_count: number,
                pricing_model: platform_plan_pricing_model_enum,
                platform_plan_tiers: Array<{
                  __typename: 'platform_plan_tier',
                  up_to: number,
                  price: string,
                  flat_fee: string
                }>,
                platform_product?: {
                  __typename: 'platform_product',
                  platform_connection_id: number,
                  platform_id: string,
                  name: string
                } | null,
                platform_currency: {
                  __typename: 'platform_currency',
                  platform_currency_exchange_rates: Array<{
                    __typename: 'platform_currency_exchange_rate',
                    base: platform_base_currency_enum,
                    rate: string
                  }>
                },
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null
            }>
          } | null
        }>,
        platform_invoices: Array<{
          __typename: 'platform_invoice',
          platform_connection_id: number,
          platform_id: string,
          platform_subscription_id: string,
          status: platform_invoice_status_enum,
          amount_paid: string,
          total: string,
          period_end: string,
          number?: string | null
        }>
      }>
    } | null
  } | null
};

export type FixDeflectedRevenueQueryVariables = Exact<{ [key: string]: never; }>;


export type FixDeflectedRevenueQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    started_at?: string | null,
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_id: number,
      account_id: number,
      platform_connection_id: number,
      platform_id: string
    }
  }>
};

export type FixDeflectedRevenueInvoicesQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformSubscriptionId: Scalars['String']['input'];
  after: Scalars['timestamptz']['input'];
}>;


export type FixDeflectedRevenueInvoicesQuery = {
  platform_invoice: Array<{
    __typename: 'platform_invoice',
    platform_connection_id: number,
    platform_id: string,
    amount_paid: string,
    paid_at?: string | null,
    currency: platform_currency_enum
  }>
};

export type FixDeflectedRevenueMutationVariables = Exact<{
  object: revenue_log_insert_input;
}>;


export type FixDeflectedRevenueMutation = {
  insert_revenue_log_one?: {
    __typename: 'revenue_log',
    id: number
  } | null
};

export type FixDeflectedSubscribersQueryVariables = Exact<{ [key: string]: never; }>;


export type FixDeflectedSubscribersQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    completed_at?: string | null,
    subscriber: {
      __typename: 'subscriber',
      id: number
    }
  }>
};

export type FixDeflectedSubscribersOtherQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  after: Scalars['timestamptz']['input'];
}>;


export type FixDeflectedSubscribersOtherQuery = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type FixDeflectedSubscribersMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type FixDeflectedSubscribersMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type FixMultiplePlanSubscriptionsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type FixMultiplePlanSubscriptionsQuery = {
  platform_subscription: Array<{
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_customer_id: string,
    platform_subscription_plans_aggregate: {
      __typename: 'platform_subscription_plan_aggregate',
      aggregate?: {
        __typename: 'platform_subscription_plan_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type FixSavedRevenueInvoicesQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  afterId: Scalars['String']['input'];
  startingAfter: Scalars['timestamptz']['input'];
}>;


export type FixSavedRevenueInvoicesQuery = {
  platform_invoice: Array<{
    __typename: 'platform_invoice',
    platform_connection_id: number,
    platform_id: string,
    amount_paid: string,
    period_end: string,
    paid_at?: string | null,
    currency: platform_currency_enum,
    platform_subscription?: {
      __typename: 'platform_subscription',
      subscription?: {
        __typename: 'subscription',
        id: number,
        account_id: number,
        subscriber_id: number
      } | null
    } | null
  }>
};

export type FixSavedRevenueSavedQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  periodEnd: Scalars['timestamptz']['input'];
}>;


export type FixSavedRevenueSavedQuery = {
  subscriber_log: Array<{
    __typename: 'subscriber_log',
    id: number,
    subscriber_log_offer?: {
      __typename: 'subscriber_log_offer',
      offer_id: number
    } | null
  }>
};

export type FixSavedRevenueMutationVariables = Exact<{
  object: revenue_log_insert_input;
}>;


export type FixSavedRevenueMutation = {
  insert_revenue_log_one?: {
    __typename: 'revenue_log',
    id: number
  } | null
};

export type FixSubscriberMrrQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type FixSubscriberMrrQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_customer?: {
      __typename: 'platform_customer',
      platform_connection_id: number,
      platform_id: string,
      name: string,
      email: string,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        platform_connection_id: number,
        platform_id: string,
        platform_customer_id: string,
        status: platform_subscription_status_enum,
        started_at: string,
        canceled_at?: string | null,
        trial_end?: string | null,
        updated_at: string,
        next_billing_at?: string | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          quantity: string,
          price?: string | null,
          platform_addon?: {
            __typename: 'platform_addon',
            platform_connection_id: number,
            platform_id: string,
            name: string,
            platform_addon_prices: Array<{
              __typename: 'platform_addon_price',
              currency: platform_currency_enum,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              pricing_model: platform_plan_pricing_model_enum,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_addon_price_tiers: Array<{
                __typename: 'platform_addon_price_tier',
                up_to: number,
                price: string
              }>,
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_addon_price?: {
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_subscription?: {
            __typename: 'platform_subscription',
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              quantity: string,
              price?: string | null,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              platform_plan?: {
                __typename: 'platform_plan',
                platform_connection_id: number,
                platform_id: string,
                platform_product_id: string,
                name: string,
                currency: platform_currency_enum,
                interval: platform_plan_interval_enum,
                interval_count: number,
                pricing_model: platform_plan_pricing_model_enum,
                platform_plan_tiers: Array<{
                  __typename: 'platform_plan_tier',
                  up_to: number,
                  price: string,
                  flat_fee: string
                }>,
                platform_product?: {
                  __typename: 'platform_product',
                  platform_connection_id: number,
                  platform_id: string,
                  name: string
                } | null,
                platform_currency: {
                  __typename: 'platform_currency',
                  platform_currency_exchange_rates: Array<{
                    __typename: 'platform_currency_exchange_rate',
                    base: platform_base_currency_enum,
                    rate: string
                  }>
                },
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null
            }>
          } | null
        }>,
        platform_invoices: Array<{
          __typename: 'platform_invoice',
          platform_connection_id: number,
          platform_id: string,
          platform_subscription_id: string,
          status: platform_invoice_status_enum,
          amount_paid: string,
          total: string,
          period_end: string,
          number?: string | null
        }>
      }>
    } | null
  }>
};

export type FixSubscriberMrrMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  activeMrr: Scalars['numeric']['input'];
  inactiveMrr: Scalars['numeric']['input'];
}>;


export type FixSubscriberMrrMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type FixSubscriptionMrrArgsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type FixSubscriptionMrrArgsQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    platform_subscription?: {
      __typename: 'platform_subscription',
      platform_connection_id: number,
      platform_id: string,
      platform_customer_id: string,
      status: platform_subscription_status_enum,
      started_at: string,
      canceled_at?: string | null,
      trial_end?: string | null,
      updated_at: string,
      next_billing_at?: string | null,
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        quantity: string,
        price?: string | null,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_connection_id: number,
          platform_id: string,
          platform_product_id: string,
          name: string,
          currency: platform_currency_enum,
          interval: platform_plan_interval_enum,
          interval_count: number,
          pricing_model: platform_plan_pricing_model_enum,
          platform_plan_tiers: Array<{
            __typename: 'platform_plan_tier',
            up_to: number,
            price: string,
            flat_fee: string
          }>,
          platform_product?: {
            __typename: 'platform_product',
            platform_connection_id: number,
            platform_id: string,
            name: string
          } | null,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null
      }>,
      platform_subscription_addons: Array<{
        __typename: 'platform_subscription_addon',
        quantity: string,
        price?: string | null,
        platform_addon?: {
          __typename: 'platform_addon',
          platform_connection_id: number,
          platform_id: string,
          name: string,
          platform_addon_prices: Array<{
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_addon_price?: {
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_subscription_plans: Array<{
            __typename: 'platform_subscription_plan',
            quantity: string,
            price?: string | null,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            platform_plan?: {
              __typename: 'platform_plan',
              platform_connection_id: number,
              platform_id: string,
              platform_product_id: string,
              name: string,
              currency: platform_currency_enum,
              interval: platform_plan_interval_enum,
              interval_count: number,
              pricing_model: platform_plan_pricing_model_enum,
              platform_plan_tiers: Array<{
                __typename: 'platform_plan_tier',
                up_to: number,
                price: string,
                flat_fee: string
              }>,
              platform_product?: {
                __typename: 'platform_product',
                platform_connection_id: number,
                platform_id: string,
                name: string
              } | null,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            } | null
          }>
        } | null
      }>,
      platform_invoices: Array<{
        __typename: 'platform_invoice',
        platform_connection_id: number,
        platform_id: string,
        platform_subscription_id: string,
        status: platform_invoice_status_enum,
        amount_paid: string,
        total: string,
        period_end: string,
        number?: string | null
      }>
    } | null
  }>
};

export type FixSubscriptionMrrMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  mrr: Scalars['numeric']['input'];
}>;


export type FixSubscriptionMrrMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type ImportCouponNamesMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  couponId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type ImportCouponNamesMutation = {
  update_platform_coupon_by_pk?: {
    __typename: 'platform_coupon',
    platform_id: string
  } | null
};

export type ImportSubscribersQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type ImportSubscribersQuery = {
  platform_customer: Array<{
    __typename: 'platform_customer',
    platform_id: string
  }>,
  subscriber: Array<{
    __typename: 'subscriber',
    platform_id: string
  }>
};

export type SentimentPhraseQueryVariables = Exact<{
  afterId: Scalars['Int']['input'];
}>;


export type SentimentPhraseQuery = {
  sentiment_phrase: Array<{
    __typename: 'sentiment_phrase',
    id: number,
    text: string
  }>
};

export type NormalizeSentimentPhrasesMutationVariables = Exact<{
  objects: Array<sentiment_phrase_insert_input> | sentiment_phrase_insert_input;
}>;


export type NormalizeSentimentPhrasesMutation = {
  insert_sentiment_phrase?: {
    __typename: 'sentiment_phrase_mutation_response',
    affected_rows: number
  } | null
};

export type ProcessIncompleteSessionsSubscriberFlowsQueryVariables = Exact<{
  before: Scalars['timestamptz']['input'];
}>;


export type ProcessIncompleteSessionsSubscriberFlowsQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    subscriber_id: number,
    subscription_id: number,
    started_at?: string | null
  }>
};

export type ProcessIncompleteSessionsMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type ProcessIncompleteSessionsMutation = {
  update_subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number
  } | null,
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type RecalculateSubscriptionMrrArgsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  lastId: Scalars['Int']['input'];
}>;


export type RecalculateSubscriptionMrrArgsQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    platform_subscription?: {
      __typename: 'platform_subscription',
      platform_connection_id: number,
      platform_id: string,
      platform_customer_id: string,
      status: platform_subscription_status_enum,
      started_at: string,
      canceled_at?: string | null,
      trial_end?: string | null,
      updated_at: string,
      next_billing_at?: string | null,
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        quantity: string,
        price?: string | null,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_connection_id: number,
          platform_id: string,
          platform_product_id: string,
          name: string,
          currency: platform_currency_enum,
          interval: platform_plan_interval_enum,
          interval_count: number,
          pricing_model: platform_plan_pricing_model_enum,
          platform_plan_tiers: Array<{
            __typename: 'platform_plan_tier',
            up_to: number,
            price: string,
            flat_fee: string
          }>,
          platform_product?: {
            __typename: 'platform_product',
            platform_connection_id: number,
            platform_id: string,
            name: string
          } | null,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null
      }>,
      platform_subscription_addons: Array<{
        __typename: 'platform_subscription_addon',
        quantity: string,
        price?: string | null,
        platform_addon?: {
          __typename: 'platform_addon',
          platform_connection_id: number,
          platform_id: string,
          name: string,
          platform_addon_prices: Array<{
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_addon_price?: {
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_subscription_plans: Array<{
            __typename: 'platform_subscription_plan',
            quantity: string,
            price?: string | null,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            platform_plan?: {
              __typename: 'platform_plan',
              platform_connection_id: number,
              platform_id: string,
              platform_product_id: string,
              name: string,
              currency: platform_currency_enum,
              interval: platform_plan_interval_enum,
              interval_count: number,
              pricing_model: platform_plan_pricing_model_enum,
              platform_plan_tiers: Array<{
                __typename: 'platform_plan_tier',
                up_to: number,
                price: string,
                flat_fee: string
              }>,
              platform_product?: {
                __typename: 'platform_product',
                platform_connection_id: number,
                platform_id: string,
                name: string
              } | null,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            } | null
          }>
        } | null
      }>,
      platform_invoices: Array<{
        __typename: 'platform_invoice',
        platform_connection_id: number,
        platform_id: string,
        platform_subscription_id: string,
        status: platform_invoice_status_enum,
        amount_paid: string,
        total: string,
        period_end: string,
        number?: string | null
      }>
    } | null
  }>
};

export type RecalculateSubscriptionMrrMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  mrr: Scalars['numeric']['input'];
}>;


export type RecalculateSubscriptionMrrMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type SentimentAnalysisQueryVariables = Exact<{
  where?: InputMaybe<question_answer_text_bool_exp>;
}>;


export type SentimentAnalysisQuery = {
  question_answer_text: Array<{
    __typename: 'question_answer_text',
    question_answer_id: number,
    value: string
  }>
};

export type SentimentAnalysisMutationVariables = Exact<{
  questionAnswerId: Scalars['Int']['input'];
  sentiment: sentiment_enum;
  score: Scalars['jsonb']['input'];
}>;


export type SentimentAnalysisMutation = {
  update_question_answer_text_by_pk?: {
    __typename: 'question_answer_text',
    question_answer_id: number
  } | null
};

export type SentimentAnalysisInsertSentimentPhrasesMutationVariables = Exact<{
  objects: Array<sentiment_phrase_insert_input> | sentiment_phrase_insert_input;
}>;


export type SentimentAnalysisInsertSentimentPhrasesMutation = {
  insert_sentiment_phrase?: {
    __typename: 'sentiment_phrase_mutation_response',
    affected_rows: number
  } | null
};

export type SoundStripeQueryVariables = Exact<{ [key: string]: never; }>;


export type SoundStripeQuery = {
  flow: Array<{
    __typename: 'flow',
    token: string,
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_question?: {
            __typename: 'flow_step_question',
            question: {
              __typename: 'question',
              token: string,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                title_translation?: {
                  __typename: 'translation',
                  translation_values: Array<{
                    __typename: 'translation_value',
                    value: any
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option?: {
                    __typename: 'question_option',
                    published_version?: {
                      __typename: 'question_option_version',
                      title_translation: {
                        __typename: 'translation',
                        translation_values: Array<{
                          __typename: 'translation_value',
                          value: any
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type InviteUserModalMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUserModalMutation = {
  inviteUser: {
    __typename: 'InviteUserOutput',
    success: boolean
  }
};

export type SubscriberCampaignQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SubscriberCampaignQuery = {
  subscriber_campaign: Array<{
    __typename: 'subscriber_campaign',
    id: number,
    token: string,
    version?: string | null,
    campaign: {
      __typename: 'campaign',
      id: number,
      title: string,
      default_language: language_enum,
      account: {
        __typename: 'account',
        id: number,
        title: string,
        properties: Array<{
          __typename: 'property',
          id: number,
          name: string,
          type: property_type_enum,
          format?: property_format_enum | null
        }>
      },
      campaign_texts: Array<{
        __typename: 'campaign_text',
        campaign_id: number,
        key: flow_text_key_enum,
        published_version?: {
          __typename: 'campaign_text_version',
          id: number,
          campaign_id: number,
          key: flow_text_key_enum,
          translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      published_version?: {
        __typename: 'campaign_version',
        id: number,
        campaign_id: number,
        offer_rule_group_id: number,
        trigger_type: campaign_trigger_type_enum,
        trigger_url?: string | null,
        delay?: string | null,
        css?: string | null,
        collect_payment: boolean,
        redirect_url?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      name: string,
      email: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        subscriber_id: number,
        property_id: number,
        value: any,
        property: {
          __typename: 'property',
          id: number,
          name: string,
          type: property_type_enum,
          format?: property_format_enum | null
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      subscription_properties: Array<{
        __typename: 'subscription_property',
        subscription_id: number,
        property_id: number,
        value: any,
        property: {
          __typename: 'property',
          id: number,
          name: string,
          type: property_type_enum,
          format?: property_format_enum | null
        }
      }>
    },
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer: {
        __typename: 'offer',
        id: number
      },
      first_view: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          min?: {
            __typename: 'subscriber_campaign_view_min_fields',
            created_at?: string | null
          } | null
        } | null
      }
    }>
  }>
};

export type ConnectStripeAppMutationVariables = Exact<{
  input: ConnectStripeAppInput;
}>;


export type ConnectStripeAppMutation = {
  connectStripeApp: {
    __typename: 'ConnectStripeAppOutput',
    success: boolean
  }
};

export type UseConnectBoldMutationVariables = Exact<{
  input: ConnectBoldInput;
}>;


export type UseConnectBoldMutation = {
  connectBold: {
    __typename: 'ConnectBoldOutput',
    success: boolean
  }
};

export type UseConnectBraintreeMutationVariables = Exact<{
  input: ConnectBraintreeInput;
}>;


export type UseConnectBraintreeMutation = {
  connectBraintree: {
    __typename: 'ConnectBraintreeOutput',
    success: boolean
  }
};

export type UseConnectChargebeeMutationVariables = Exact<{
  input: ConnectChargebeeInput;
}>;


export type UseConnectChargebeeMutation = {
  connectChargebee: {
    __typename: 'ConnectChargebeeOutput',
    success: boolean
  }
};

export type UseConnectCustomMutationVariables = Exact<{ [key: string]: never; }>;


export type UseConnectCustomMutation = {
  skipConnect?: {
    __typename: 'SkipConnectOutput',
    success: boolean
  } | null
};

export type UseConnectMaxioMutationVariables = Exact<{
  input: ConnectMaxioInput;
}>;


export type UseConnectMaxioMutation = {
  connectMaxio: {
    __typename: 'ConnectMaxioOutput',
    success: boolean
  }
};

export type UseConnectPaddleMutationVariables = Exact<{
  input: ConnectPaddleInput;
}>;


export type UseConnectPaddleMutation = {
  connectPaddle: {
    __typename: 'ConnectPaddleOutput',
    success: boolean
  }
};

export type UseConnectRechargeMutationVariables = Exact<{
  input: ConnectRechargeInput;
}>;


export type UseConnectRechargeMutation = {
  connectRecharge: {
    __typename: 'ConnectRechargeOutput',
    success: boolean
  }
};

export type UseConnectRecurlyMutationVariables = Exact<{
  input: ConnectRecurlyInput;
}>;


export type UseConnectRecurlyMutation = {
  connectRecurly: {
    __typename: 'ConnectRecurlyOutput',
    success: boolean
  }
};

export type UseConnectZuoraMutationVariables = Exact<{
  input: ConnectZuoraInput;
}>;


export type UseConnectZuoraMutation = {
  connectZuora: {
    __typename: 'ConnectZuoraOutput',
    success: boolean
  }
};

export type InsertPropertyTagModalQueryVariables = Exact<{ [key: string]: never; }>;


export type InsertPropertyTagModalQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type FlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
  includeUnsavedChangesData?: Scalars['Boolean']['input'];
}>;


export type FlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    logo_url?: string | null,
    redirect_uri?: string | null,
    preview_property_values: any,
    css?: string | null,
    default_language: language_enum,
    prevent_if_canceled: boolean,
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number,
      token: string,
      version?: string | null,
      status: subscriber_flow_status_enum,
      segment_values?: any | null,
      eligibility_message?: {
        __typename: 'eligibility_message',
        eligibility_header_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        eligibility_message_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      } | null,
      subscriber: {
        __typename: 'subscriber',
        id: number,
        name: string,
        email: string,
        subscriber_properties: Array<{
          __typename: 'subscriber_property',
          subscriber_id: number,
          property_id: number,
          value: any,
          property: {
            __typename: 'property',
            id: number,
            name: string,
            type: property_type_enum,
            format?: property_format_enum | null
          }
        }>
      },
      subscription: {
        __typename: 'subscription',
        id: number,
        subscription_properties: Array<{
          __typename: 'subscription_property',
          subscription_id: number,
          property_id: number,
          value: any,
          property: {
            __typename: 'property',
            id: number,
            name: string,
            type: property_type_enum,
            format?: property_format_enum | null
          }
        }>,
        platform_subscription?: {
          __typename: 'platform_subscription',
          can_cancel: boolean
        } | null
      }
    }>,
    account: {
      __typename: 'account',
      id: number,
      title: string,
      prompt_for_subscriber_details: boolean,
      intake_form_name_email: boolean,
      flow_css?: string | null,
      show_branding_feature: Array<{
        __typename: 'account_feature',
        key: account_feature_key_enum,
        value: any
      }>,
      properties: Array<{
        __typename: 'property',
        id: number,
        name: string,
        type: property_type_enum,
        format?: property_format_enum | null
      }>
    },
    flow_texts: Array<{
      __typename: 'flow_text',
      flow_id: number,
      key: flow_text_key_enum,
      published_version?: {
        __typename: 'flow_text_version',
        id: number,
        flow_id: number,
        key: flow_text_key_enum,
        translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'flow_text_version',
        id: number,
        flow_id: number,
        key: flow_text_key_enum,
        translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    flow_subscriber_form_questions: Array<{
      __typename: 'flow_subscriber_form_question',
      id: number,
      flow_id: number,
      title: string,
      hint: string,
      position: number,
      required: boolean
    }>,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          type: flow_step_type_enum,
          token: string,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number
                } | null
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number
              } | null
            } | null
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum,
              token: string,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    prevent_if_canceled_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  }>
};

export type SubscriberFlowSegmentMatchesQueryVariables = Exact<{
  input: SubscriberFlowSegmentMatchesInput;
}>;


export type SubscriberFlowSegmentMatchesQuery = {
  subscriberFlowSegmentMatches: {
    __typename: 'SubscriberFlowSegmentMatchesOutput',
    segmentIds: Array<number>,
    segmentGroupIds?: Array<number> | null
  }
};

export type AccountFeatureFragment = {
  __typename: 'account_feature',
  key: account_feature_key_enum,
  value: any
};

export type AccountSettingFragment = {
  __typename: 'account_setting',
  account_id: number,
  key: account_setting_key_enum,
  value: any
};

export type AppApiKeyFragment = {
  __typename: 'api_key',
  id: number,
  name: string,
  test_mode_key: string,
  created_at: string
};

export type AppDataConnectorFragment = {
  __typename: 'data_connector',
  id: number,
  token: string,
  url: string,
  disabled: boolean,
  created_at: string
};

export type AppDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  token: string,
  title: string,
  tag_ids: any,
  created_at: string,
  archived_at?: string | null,
  minimum_items?: number | null,
  heading_translation_id: number,
  content_translation_id: number,
  deflection_tags: Array<{
    __typename: 'deflection_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>,
  heading_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  deflection_snapshot_items: Array<{
    __typename: 'deflection_snapshot_item_position',
    position?: number | null,
    deflection_snapshot_item?: {
      __typename: 'deflection_snapshot_item',
      id: number,
      token: string,
      icon_prefix?: string | null,
      icon_name?: string | null,
      property_id?: number | null,
      property_condition_value?: any | null,
      date_format?: string | null,
      text_translation_id: number,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      property?: {
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      } | null
    } | null
  }>,
  deflection_flow_actions: Array<{
    __typename: 'deflection_flow_action',
    position?: number | null,
    flow_action?: {
      __typename: 'flow_action',
      id: number,
      token: string,
      code?: string | null,
      type: flow_action_type_enum,
      account_id: number,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow_id?: number | null,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type AppDeflectionSnapshotItemFragment = {
  __typename: 'deflection_snapshot_item',
  id: number,
  token: string,
  icon_prefix?: string | null,
  icon_name?: string | null,
  property_id?: number | null,
  property_condition_value?: any | null,
  date_format?: string | null,
  text_translation_id: number,
  text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  property?: {
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null
  } | null
};

export type AppEligibilityMessageFragment = {
  __typename: 'eligibility_message',
  id: number,
  name: string,
  deleted_at?: string | null,
  eligibility_message_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  eligibility_header_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null
};

export type AppFlowActionFragment = {
  __typename: 'flow_action',
  id: number,
  token: string,
  code?: string | null,
  type: flow_action_type_enum,
  account_id: number,
  appearance: flow_action_appearance_enum,
  url?: string | null,
  reroute_to_flow_id?: number | null,
  text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type AppOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum,
  created_at: string,
  offer_tags: Array<{
    __typename: 'offer_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type AppOfferGroupOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  name: string,
  show_banner: boolean,
  image_url?: string | null,
  goal: offer_goal_enum,
  style: offer_style_enum,
  created_at: string,
  updated_at: string,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_coupon?: {
    __typename: 'offer_coupon',
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null,
    months?: number | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    modify_at: offer_modify_subscription_modify_at_enum,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_connection_id: number,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null
    }>
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null,
      text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    }>
  } | null,
  offer_upgrade?: {
    __typename: 'offer_upgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_downgrade?: {
    __typename: 'offer_downgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_product_swap?: {
    __typename: 'offer_product_swap',
    specific_products: boolean,
    offer_product_swap_platform_ecommerce_products: Array<{
      __typename: 'offer_product_swap_platform_ecommerce_product',
      platform_ecommerce_product_id: string,
      platform_ecommerce_product?: {
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      } | null,
      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
        platform_variant?: {
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        } | null
      }>
    }>
  } | null,
  offer_reschedule_order?: {
    __typename: 'offer_reschedule_order',
    skip: boolean,
    reschedule: boolean,
    skip_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    reschedule_button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null,
  offer_timer?: {
    __typename: 'offer_timer',
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null,
  offer_autopilot?: {
    __typename: 'offer_autopilot',
    offer_id: number,
    latest_round: Array<{
      __typename: 'offer_autopilot_offer_autopilot_round',
      offer_autopilot_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        round_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            created_at: string,
            approved_at?: string | null,
            approval_user_id?: string | null,
            data?: any,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null
        }>
      } | null
    }>
  } | null
};

export type AppPlatformConnectionFragment = {
  __typename: 'platform_connection',
  id: number,
  platform: platform_enum,
  platform_id: string,
  import_started_at?: string | null,
  import_completed_at?: string | null,
  meta: any,
  publishable_key?: string | null,
  platform_connection_shopify_store?: {
    __typename: 'platform_connection_shopify_store',
    store: string,
    client_id?: string | null,
    install_link?: string | null
  } | null
};

export type AppPropertyFragment = {
  __typename: 'property',
  id: number,
  entity: property_entity_enum,
  type: property_type_enum,
  format?: property_format_enum | null,
  name: string,
  key: string,
  token: string,
  created_at: string
};

export type AppSegmentFragment = {
  __typename: 'segment',
  id: number,
  token: string,
  name: string,
  integration_id?: number | null,
  integration_segment_id?: string | null,
  created_at: string,
  primary_segment_condition_group?: {
    __typename: 'segment_condition_group',
    id: number,
    boolean_operator: segment_condition_boolean_operator_enum,
    segment_condition_group_entries: Array<{
      __typename: 'segment_condition_group_entry',
      entry_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null,
      entry_segment_condition?: {
        __typename: 'segment_condition',
        id: number,
        property: segment_condition_property_enum,
        operator: segment_condition_operator_enum,
        value: any,
        property_id?: number | null,
        custom_property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>
  } | null,
  integration?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  } | null,
  segment_tags: Array<{
    __typename: 'segment_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type AppSegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  token: string,
  name: string,
  created_at: string,
  segment_group_segments: Array<{
    __typename: 'segment_group_segment',
    segment_group_id: number,
    segment_id: number,
    operator: segment_group_operator_enum,
    segment: {
      __typename: 'segment',
      id: number,
      token: string,
      name: string,
      integration_id?: number | null,
      integration_segment_id?: string | null,
      created_at: string,
      primary_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null,
      integration?: {
        __typename: 'integration',
        id: number,
        type: integration_type_enum
      } | null,
      segment_tags: Array<{
        __typename: 'segment_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  }>
};

export type AppSubscriberFlowDeflectionFragment = {
  __typename: 'subscriber_flow_deflection',
  subscriber_flow_id: number,
  deflection_id: number,
  flow_step_id: number,
  clicked_flow_action?: {
    __typename: 'flow_action',
    id: number,
    text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  deflection: {
    __typename: 'deflection',
    id: number,
    title: string
  }
};

export type AppSubscriberFlowOfferCouponFragment = {
  __typename: 'offer_coupon',
  id: number,
  offer_id: number,
  type: offer_coupon_type_enum,
  amount_off: string,
  duration: coupon_duration_enum,
  duration_count?: number | null,
  duration_interval?: coupon_duration_interval_enum | null,
  months?: number | null
};

export type AppSubscriberFlowOfferFragment = {
  __typename: 'subscriber_flow_offer',
  subscriber_flow_id: number,
  offer_id: number,
  status?: subscriber_flow_offer_status_enum | null,
  rescheduled_to?: string | null,
  offer: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    goal: offer_goal_enum,
    metadata: any,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_id: number,
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_count?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      months?: number | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string
      } | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean
    } | null
  },
  offer_pause_subscription_option?: {
    __typename: 'offer_pause_subscription_option',
    id: number,
    type: offer_pause_subscription_option_type_enum,
    interval?: offer_pause_subscription_interval_enum | null,
    interval_count?: number | null
  } | null,
  offer_autopilot_offer_variant?: {
    __typename: 'offer_autopilot_offer_variant',
    variant_index?: number | null
  } | null
};

export type AppSubscriberFlowOfferGroupFragment = {
  __typename: 'subscriber_flow_offer_group',
  subscriber_flow_id: number,
  offer_group_id: number,
  offer_id?: number | null,
  status?: subscriber_flow_offer_status_enum | null,
  rescheduled_to?: string | null,
  offer_group: {
    __typename: 'offer_group',
    id: number,
    name: string,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        type: offer_type_enum,
        name: string,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null
      } | null
    }>
  },
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    goal: offer_goal_enum,
    metadata: any,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_id: number,
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_count?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      months?: number | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string
      } | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean
    } | null
  } | null,
  offer_pause_subscription_option?: {
    __typename: 'offer_pause_subscription_option',
    id: number,
    type: offer_pause_subscription_option_type_enum,
    interval?: offer_pause_subscription_interval_enum | null,
    interval_count?: number | null
  } | null
};

export type AppSubscriberFlowOfferOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  name: string,
  goal: offer_goal_enum,
  metadata: any,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    offer_id: number,
    pause_at: offer_pause_subscription_pause_at_enum,
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    }>
  } | null,
  offer_coupon?: {
    __typename: 'offer_coupon',
    id: number,
    offer_id: number,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    duration_count?: number | null,
    duration_interval?: coupon_duration_interval_enum | null,
    months?: number | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum,
    platform_plan?: {
      __typename: 'platform_plan',
      name: string
    } | null,
    offer_coupon_offer?: {
      __typename: 'offer',
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null
    } | null
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    modify_at: offer_modify_subscription_modify_at_enum,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_connection_id: number,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null
    }>
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null,
  offer_reschedule_order?: {
    __typename: 'offer_reschedule_order',
    skip: boolean,
    reschedule: boolean
  } | null
};

export type AppSubscriberFragment = {
  __typename: 'subscriber',
  id: number,
  token: string,
  name: string,
  email: string,
  status?: subscriber_status_enum | null,
  inactive_mrr: string,
  active_mrr: string,
  platform_id: string,
  hubspot_contact_id?: string | null,
  hubspot_company_id?: string | null,
  intercom_contact_id?: string | null,
  salesforce_contact_id?: string | null,
  klaviyo_profile_id?: string | null,
  deleted_at?: string | null,
  revenue_logs_aggregate: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  },
  subscriber_flows: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>,
  subscriber_logs: Array<{
    __typename: 'subscriber_log',
    id: number,
    event: subscriber_log_event_enum,
    occurred_at: string,
    data: any,
    subscription?: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum
    } | null,
    subscriber_log_offer?: {
      __typename: 'subscriber_log_offer',
      offer: {
        __typename: 'offer',
        id: number,
        type: offer_type_enum
      }
    } | null
  }>,
  revenue_logs: Array<{
    __typename: 'revenue_log',
    id: number,
    amount: string,
    occurred_at: string,
    platform_invoice_id: string,
    revenue_type: revenue_log_revenue_type_enum
  }>,
  subscriber_properties: Array<{
    __typename: 'subscriber_property',
    subscriber_id: number,
    property_id: number,
    value: any,
    property: {
      __typename: 'property',
      id: number,
      entity: property_entity_enum,
      type: property_type_enum,
      format?: property_format_enum | null,
      name: string,
      key: string,
      token: string,
      created_at: string
    }
  }>
};

export type AppSubscriberListFragment = {
  __typename: 'subscriber',
  id: number,
  token: string,
  name: string,
  email: string,
  platform_id: string,
  status?: subscriber_status_enum | null,
  active_mrr: string,
  inactive_mrr: string,
  created_at: string,
  deleted_at?: string | null,
  subscriber_created_at?: {
    __typename: 'subscriber_created_at',
    created_at?: string | null
  } | null,
  platform_customer?: {
    __typename: 'platform_customer',
    platform_id: string,
    platform_created_at?: string | null
  } | null
};

export type AppSubscriberPropertyFragment = {
  __typename: 'subscriber_property',
  subscriber_id: number,
  property_id: number,
  value: any,
  property: {
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }
};

export type AppSubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  platform_subscription?: {
    __typename: 'platform_subscription',
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      platform_plan?: {
        __typename: 'platform_plan',
        platform_product?: {
          __typename: 'platform_product',
          name: string
        } | null
      } | null
    }>
  } | null,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    deleted_at?: string | null
  }
};

export type AppSubscriptionPropertyFragment = {
  __typename: 'subscription_property',
  subscription_id: number,
  property_id: number,
  value: any,
  property: {
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }
};

export type AppUserFragment = {
  __typename: 'user',
  id: string,
  name?: string | null,
  email?: string | null,
  picture?: string | null,
  provider?: string | null,
  activated_at?: string | null,
  user_profiles: Array<{
    __typename: 'user_profile',
    first_name: string,
    last_name: string,
    email: string
  }>,
  owned_accounts: Array<{
    __typename: 'account',
    id: number
  }>
};

export type AppWebhookFragment = {
  __typename: 'webhook',
  id: number,
  token: string,
  url: string,
  disabled: boolean,
  webhook_events: Array<{
    __typename: 'webhook_event',
    event_type: event_type_enum
  }>
};

export type CampaignOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  name: string,
  show_banner: boolean,
  goal: offer_goal_enum,
  style: offer_style_enum,
  confirmation_enabled: boolean,
  image_url?: string | null,
  created_at: string,
  updated_at: string,
  offer_timer?: {
    __typename: 'offer_timer',
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_coupon?: {
    __typename: 'offer_coupon',
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    months?: number | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null
};

export type CampaignOfferRuleFragment = {
  __typename: 'offer_rule',
  id: number,
  published_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_ids: any,
    offer_test_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_rule_rule_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>
      } | null
    }>,
    offer_rule_version_offers: Array<{
      __typename: 'offer_rule_version_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    }>,
    offer_rule_version_offer_tests: Array<{
      __typename: 'offer_rule_version_offer_test',
      offer_rule_version_id?: number | null,
      offer_test_id?: number | null,
      offer_test?: {
        __typename: 'offer_test',
        id: number,
        name: string,
        token: string,
        control_offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        },
        offer_test_offers: Array<{
          __typename: 'offer_test_offer',
          offer_test_id: number,
          offer_id: number,
          offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          }
        }>,
        winning_offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      } | null
    }>
  } | null
};

export type CampaignOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type CampaignOfferRuleGroupVersionFragment = {
  __typename: 'offer_rule_group_version',
  id: number,
  offer_rule_group_id: number,
  offer_rule_ids: any,
  offer_rule_group_version_offer_rules: Array<{
    __typename: 'offer_rule_group_version_offer_rule',
    offer_rule?: {
      __typename: 'offer_rule',
      id: number,
      published_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_ids: any,
        offer_test_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_rule_rule_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>
          } | null
        }>,
        offer_rule_version_offers: Array<{
          __typename: 'offer_rule_version_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_version_offer_tests: Array<{
          __typename: 'offer_rule_version_offer_test',
          offer_rule_version_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>
      } | null
    } | null
  }>
};

export type CampaignOfferRuleOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum
};

export type CampaignOfferRuleRuleFragment = {
  __typename: 'offer_rule_rule',
  id: number,
  offer_rule_id: number,
  offer_ids: any,
  offer_test_ids: any,
  offer_group_ids: any,
  include_present_no_offer: boolean,
  weight: number,
  offer_rule_rule_offers: Array<{
    __typename: 'offer_rule_rule_offer',
    offer_rule_rule_id?: number | null,
    offer_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_rule_rule_offer_tests: Array<{
    __typename: 'offer_rule_rule_offer_test',
    offer_rule_rule_id?: number | null,
    offer_test_id?: number | null,
    offer_test?: {
      __typename: 'offer_test',
      id: number,
      name: string,
      token: string,
      control_offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      },
      offer_test_offers: Array<{
        __typename: 'offer_test_offer',
        offer_test_id: number,
        offer_id: number,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        }
      }>,
      winning_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    } | null
  }>
};

export type CampaignOfferRuleVersionFragment = {
  __typename: 'offer_rule_version',
  id: number,
  offer_rule_id: number,
  segment_group_ids: any,
  segment_ids: any,
  question_option_ids: any,
  offer_ids: any,
  offer_test_ids: any,
  include_present_no_offer: boolean,
  include_other_in_question_ids: any,
  offer_rule_rule_ids: any,
  offer_rule_version_segment_groups: Array<{
    __typename: 'offer_rule_version_segment_group',
    offer_rule_version_id?: number | null,
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string
    } | null
  }>,
  offer_rule_version_segments: Array<{
    __typename: 'offer_rule_version_segment',
    offer_rule_version_id?: number | null,
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  offer_rule_version_question_options: Array<{
    __typename: 'offer_rule_version_question_option',
    offer_rule_version_id?: number | null,
    question_option_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>,
  offer_rule_version_offer_rule_rules: Array<{
    __typename: 'offer_rule_version_offer_rule_rule',
    offer_rule_version_id?: number | null,
    offer_rule_rule_id?: number | null,
    offer_rule_rule?: {
      __typename: 'offer_rule_rule',
      id: number,
      offer_rule_id: number,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      include_present_no_offer: boolean,
      weight: number,
      offer_rule_rule_offers: Array<{
        __typename: 'offer_rule_rule_offer',
        offer_rule_rule_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_rule_offer_tests: Array<{
        __typename: 'offer_rule_rule_offer_test',
        offer_rule_rule_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>
    } | null
  }>,
  offer_rule_version_offers: Array<{
    __typename: 'offer_rule_version_offer',
    offer_rule_version_id?: number | null,
    offer_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_rule_version_offer_tests: Array<{
    __typename: 'offer_rule_version_offer_test',
    offer_rule_version_id?: number | null,
    offer_test_id?: number | null,
    offer_test?: {
      __typename: 'offer_test',
      id: number,
      name: string,
      token: string,
      control_offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      },
      offer_test_offers: Array<{
        __typename: 'offer_test_offer',
        offer_test_id: number,
        offer_id: number,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        }
      }>,
      winning_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    } | null
  }>
};

export type CampaignOfferTestFragment = {
  __typename: 'offer_test',
  id: number,
  name: string,
  token: string,
  control_offer: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  },
  offer_test_offers: Array<{
    __typename: 'offer_test_offer',
    offer_test_id: number,
    offer_id: number,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    }
  }>,
  winning_offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  } | null
};

export type CampaignSegmentFragment = {
  __typename: 'segment',
  id: number,
  name: string
};

export type CampaignSegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  name: string
};

export type CampaignTextFragment = {
  __typename: 'campaign_text',
  campaign_id: number,
  key: flow_text_key_enum,
  published_version?: {
    __typename: 'campaign_text_version',
    id: number,
    campaign_id: number,
    key: flow_text_key_enum,
    translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type CampaignTextVersionFragment = {
  __typename: 'campaign_text_version',
  id: number,
  campaign_id: number,
  key: flow_text_key_enum,
  translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type CampaignVersionFragment = {
  __typename: 'campaign_version',
  id: number,
  campaign_id: number,
  offer_rule_group_id: number,
  trigger_type: campaign_trigger_type_enum,
  trigger_url?: string | null,
  delay?: string | null,
  css?: string | null,
  collect_payment: boolean,
  redirect_url?: string | null
};

export type CancellationsSubscriberLogFragment = {
  __typename: 'subscriber_log',
  id: number,
  occurred_at: string,
  data: any,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    inactive_mrr: string,
    reactivation_logs: Array<{
      __typename: 'subscriber_log',
      occurred_at: string,
      data: any
    }>
  },
  subscription?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type ChildSegmentConditionGroupFragment = {
  __typename: 'segment_condition_group',
  id: number,
  boolean_operator: segment_condition_boolean_operator_enum,
  segment_condition_group_entries: Array<{
    __typename: 'segment_condition_group_entry',
    entry_segment_condition?: {
      __typename: 'segment_condition',
      id: number,
      property: segment_condition_property_enum,
      operator: segment_condition_operator_enum,
      value: any,
      property_id?: number | null,
      custom_property?: {
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      } | null
    } | null
  }>
};

export type CompleteSignUpFragment = {
  __typename: 'account',
  id: number,
  title: string,
  mode: account_mode_enum,
  platform_connection?: {
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    import_started_at?: string | null,
    import_completed_at?: string | null,
    ready: boolean,
    show_error: boolean,
    meta: any,
    platform_connection_shopify_store?: {
      __typename: 'platform_connection_shopify_store',
      connected: boolean
    } | null
  } | null,
  default_flow?: {
    __typename: 'flow',
    id: number,
    title: string,
    token: string,
    logo_url?: string | null
  } | null,
  offers: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      type: offer_coupon_type_enum
    } | null
  }>
};

export type ConversionCampaignFragment = {
  __typename: 'campaign',
  id: number,
  token: string,
  title: string,
  deleted_at?: string | null,
  published_version?: {
    __typename: 'campaign_version',
    id: number,
    trigger_type: campaign_trigger_type_enum,
    trigger_url?: string | null,
    delay?: string | null,
    collect_payment: boolean,
    redirect_url?: string | null,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type CustomerPortalAccountFragment = {
  __typename: 'account',
  id: number,
  token: string,
  title: string,
  logo_url?: string | null,
  favicon_url?: string | null,
  default_flow?: {
    __typename: 'flow',
    id: number,
    logo_url?: string | null
  } | null,
  platform_connection?: {
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum
  } | null
};

export type CustomerPortalPortalSessionFragment = {
  __typename: 'portal_session',
  return_url?: string | null,
  account: {
    __typename: 'account',
    id: number,
    token: string,
    title: string,
    logo_url?: string | null,
    favicon_url?: string | null,
    default_flow?: {
      __typename: 'flow',
      id: number,
      logo_url?: string | null
    } | null,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum
    } | null
  },
  portal_session_subscribers: Array<{
    __typename: 'portal_session_subscriber',
    subscriber: {
      __typename: 'subscriber',
      platform_id: string,
      subscriptions: Array<{
        __typename: 'subscription',
        id: number,
        platform_id: string
      }>
    }
  }>
};

export type CustomerPortalSubscriptionDetailsFragment = {
  __typename: 'PortalSubscriptionDetailsOutput',
  name: string,
  platformId: string,
  status: string,
  trialEndsAt?: string | null,
  amount: string,
  currency: string,
  interval: string,
  intervalCount: number,
  nextBillingAt?: string | null
};

export type CustomerPortalSubscriptionDetailsInvoiceFragment = {
  __typename: 'PortalSubscriptionDetailsInvoice',
  platformId: string,
  periodEnd: string,
  status: string,
  total: string,
  amountPaid: string,
  number: string
};

export type CustomerPortalSubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  platform_id: string
};

export type DeflectionDetailsFragment = {
  __typename: 'deflection',
  id: number,
  token: string,
  title: string,
  created_at: string,
  archived_at?: string | null,
  tag_ids: any,
  deflection_tags: Array<{
    __typename: 'deflection_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>,
  heading_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null
};

export type DemoFragment = {
  __typename: 'demo',
  id: number,
  token: string,
  company_name: string,
  logo_url?: string | null
};

export type FlowAcknowledgementFragment = {
  __typename: 'acknowledgement',
  id: number,
  title: string,
  published_version?: {
    __typename: 'acknowledgement_version',
    id: number,
    acknowledgement_id: number,
    label_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'acknowledgement_version',
    id: number,
    acknowledgement_id: number,
    label_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type FlowAcknowledgementGroupFragment = {
  __typename: 'acknowledgement_group',
  id: number,
  published_version?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group_id: number,
    acknowledgement_ids: any,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    acknowledgement_group_version_acknowledgements: Array<{
      __typename: 'acknowledgement_group_version_acknowledgement',
      acknowledgement_group_version_id?: number | null,
      acknowledgement_id?: number | null,
      acknowledgement?: {
        __typename: 'acknowledgement',
        id: number,
        title: string,
        published_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group_id: number,
    acknowledgement_ids: any,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    acknowledgement_group_version_acknowledgements: Array<{
      __typename: 'acknowledgement_group_version_acknowledgement',
      acknowledgement_group_version_id?: number | null,
      acknowledgement_id?: number | null,
      acknowledgement?: {
        __typename: 'acknowledgement',
        id: number,
        title: string,
        published_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null
};

export type FlowAcknowledgementGroupVersionFragment = {
  __typename: 'acknowledgement_group_version',
  id: number,
  acknowledgement_group_id: number,
  acknowledgement_ids: any,
  title_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  acknowledgement_group_version_acknowledgements: Array<{
    __typename: 'acknowledgement_group_version_acknowledgement',
    acknowledgement_group_version_id?: number | null,
    acknowledgement_id?: number | null,
    acknowledgement?: {
      __typename: 'acknowledgement',
      id: number,
      title: string,
      published_version?: {
        __typename: 'acknowledgement_version',
        id: number,
        acknowledgement_id: number,
        label_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_version',
        id: number,
        acknowledgement_id: number,
        label_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>
};

export type FlowAcknowledgementVersionFragment = {
  __typename: 'acknowledgement_version',
  id: number,
  acknowledgement_id: number,
  label_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowActionFragment = {
  __typename: 'flow_action',
  id: number,
  token: string,
  code?: string | null,
  type: flow_action_type_enum,
  account_id: number,
  appearance: flow_action_appearance_enum,
  url?: string | null,
  reroute_to_flow_id?: number | null,
  text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowConfirmationFragment = {
  __typename: 'confirmation',
  id: number,
  published_version?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation_id: number,
    show_on_cancel: boolean,
    show_on_save: boolean,
    saved_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    saved_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation_id: number,
    show_on_cancel: boolean,
    show_on_save: boolean,
    saved_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    saved_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type FlowConfirmationVersionFragment = {
  __typename: 'confirmation_version',
  id: number,
  confirmation_id: number,
  show_on_cancel: boolean,
  show_on_save: boolean,
  saved_headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  saved_content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  canceled_headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  canceled_content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  title: string,
  token: string,
  minimum_items?: number | null,
  heading_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  deflection_snapshot_items: Array<{
    __typename: 'deflection_snapshot_item_position',
    position?: number | null,
    deflection_snapshot_item?: {
      __typename: 'deflection_snapshot_item',
      id: number,
      token: string,
      icon_prefix?: string | null,
      icon_name?: string | null,
      property_id?: number | null,
      property_condition_value?: any | null,
      date_format?: string | null,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      property?: {
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      } | null
    } | null
  }>,
  deflection_flow_actions: Array<{
    __typename: 'deflection_flow_action',
    position?: number | null,
    flow_action?: {
      __typename: 'flow_action',
      id: number,
      token: string,
      code?: string | null,
      type: flow_action_type_enum,
      account_id: number,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow_id?: number | null,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type FlowDeflectionRuleFragment = {
  __typename: 'offer_rule',
  id: number,
  published_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        deflection_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_deflections: Array<{
          __typename: 'offer_rule_rule_deflection',
          offer_rule_rule_id?: number | null,
          deflection_id?: number | null,
          deflection?: {
            __typename: 'deflection',
            id: number,
            title: string,
            token: string,
            minimum_items?: number | null,
            heading_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            deflection_snapshot_items: Array<{
              __typename: 'deflection_snapshot_item_position',
              position?: number | null,
              deflection_snapshot_item?: {
                __typename: 'deflection_snapshot_item',
                id: number,
                token: string,
                icon_prefix?: string | null,
                icon_name?: string | null,
                property_id?: number | null,
                property_condition_value?: any | null,
                date_format?: string | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>,
            deflection_flow_actions: Array<{
              __typename: 'deflection_flow_action',
              position?: number | null,
              flow_action?: {
                __typename: 'flow_action',
                id: number,
                token: string,
                code?: string | null,
                type: flow_action_type_enum,
                account_id: number,
                appearance: flow_action_appearance_enum,
                url?: string | null,
                reroute_to_flow_id?: number | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          } | null
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        deflection_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_deflections: Array<{
          __typename: 'offer_rule_rule_deflection',
          offer_rule_rule_id?: number | null,
          deflection_id?: number | null,
          deflection?: {
            __typename: 'deflection',
            id: number,
            title: string,
            token: string,
            minimum_items?: number | null,
            heading_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            deflection_snapshot_items: Array<{
              __typename: 'deflection_snapshot_item_position',
              position?: number | null,
              deflection_snapshot_item?: {
                __typename: 'deflection_snapshot_item',
                id: number,
                token: string,
                icon_prefix?: string | null,
                icon_name?: string | null,
                property_id?: number | null,
                property_condition_value?: any | null,
                date_format?: string | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>,
            deflection_flow_actions: Array<{
              __typename: 'deflection_flow_action',
              position?: number | null,
              flow_action?: {
                __typename: 'flow_action',
                id: number,
                token: string,
                code?: string | null,
                type: flow_action_type_enum,
                account_id: number,
                appearance: flow_action_appearance_enum,
                url?: string | null,
                reroute_to_flow_id?: number | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          } | null
        }>
      } | null
    }>
  } | null
};

export type FlowDeflectionRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  title: string,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type FlowDeflectionRuleGroupVersionFragment = {
  __typename: 'offer_rule_group_version',
  id: number,
  offer_rule_group_id: number,
  offer_rule_ids: any,
  offer_rule_group_version_offer_rules: Array<{
    __typename: 'offer_rule_group_version_offer_rule',
    offer_rule?: {
      __typename: 'offer_rule',
      id: number,
      published_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null
    } | null
  }>
};

export type FlowDeflectionRuleRuleFragment = {
  __typename: 'offer_rule_rule',
  id: number,
  offer_rule_id: number,
  deflection_ids: any,
  include_present_no_offer: boolean,
  weight: number,
  offer_rule_rule_deflections: Array<{
    __typename: 'offer_rule_rule_deflection',
    offer_rule_rule_id?: number | null,
    deflection_id?: number | null,
    deflection?: {
      __typename: 'deflection',
      id: number,
      title: string,
      token: string,
      minimum_items?: number | null,
      heading_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      deflection_snapshot_items: Array<{
        __typename: 'deflection_snapshot_item_position',
        position?: number | null,
        deflection_snapshot_item?: {
          __typename: 'deflection_snapshot_item',
          id: number,
          token: string,
          icon_prefix?: string | null,
          icon_name?: string | null,
          property_id?: number | null,
          property_condition_value?: any | null,
          date_format?: string | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        position?: number | null,
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    } | null
  }>
};

export type FlowDeflectionRuleVersionFragment = {
  __typename: 'offer_rule_version',
  id: number,
  offer_rule_id: number,
  segment_group_ids: any,
  segment_ids: any,
  question_option_ids: any,
  include_present_no_offer: boolean,
  include_other_in_question_ids: any,
  offer_rule_rule_ids: any,
  offer_rule_version_segment_groups: Array<{
    __typename: 'offer_rule_version_segment_group',
    offer_rule_version_id?: number | null,
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  offer_rule_version_segments: Array<{
    __typename: 'offer_rule_version_segment',
    offer_rule_version_id?: number | null,
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  offer_rule_version_question_options: Array<{
    __typename: 'offer_rule_version_question_option',
    offer_rule_version_id?: number | null,
    question_option_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>,
  offer_rule_version_offer_rule_rules: Array<{
    __typename: 'offer_rule_version_offer_rule_rule',
    offer_rule_version_id?: number | null,
    offer_rule_rule_id?: number | null,
    offer_rule_rule?: {
      __typename: 'offer_rule_rule',
      id: number,
      offer_rule_id: number,
      deflection_ids: any,
      include_present_no_offer: boolean,
      weight: number,
      offer_rule_rule_deflections: Array<{
        __typename: 'offer_rule_rule_deflection',
        offer_rule_rule_id?: number | null,
        deflection_id?: number | null,
        deflection?: {
          __typename: 'deflection',
          id: number,
          title: string,
          token: string,
          minimum_items?: number | null,
          heading_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          deflection_snapshot_items: Array<{
            __typename: 'deflection_snapshot_item_position',
            position?: number | null,
            deflection_snapshot_item?: {
              __typename: 'deflection_snapshot_item',
              id: number,
              token: string,
              icon_prefix?: string | null,
              icon_name?: string | null,
              property_id?: number | null,
              property_condition_value?: any | null,
              date_format?: string | null,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>,
          deflection_flow_actions: Array<{
            __typename: 'deflection_flow_action',
            position?: number | null,
            flow_action?: {
              __typename: 'flow_action',
              id: number,
              token: string,
              code?: string | null,
              type: flow_action_type_enum,
              account_id: number,
              appearance: flow_action_appearance_enum,
              url?: string | null,
              reroute_to_flow_id?: number | null,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>
        } | null
      }>
    } | null
  }>
};

export type FlowDeflectionSnapshotItemFragment = {
  __typename: 'deflection_snapshot_item',
  id: number,
  token: string,
  icon_prefix?: string | null,
  icon_name?: string | null,
  property_id?: number | null,
  property_condition_value?: any | null,
  date_format?: string | null,
  text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  property?: {
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null
  } | null
};

export type FlowFormFragment = {
  __typename: 'form',
  id: number,
  title: string,
  published_version?: {
    __typename: 'form_version',
    id: number,
    form_id: number,
    question_ids: any,
    form_version_questions: Array<{
      __typename: 'form_version_question',
      question_id?: number | null,
      form_version_id?: number | null,
      question?: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'form_version',
    id: number,
    form_id: number,
    question_ids: any,
    form_version_questions: Array<{
      __typename: 'form_version_question',
      question_id?: number | null,
      form_version_id?: number | null,
      question?: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      } | null
    }>
  } | null
};

export type FlowFormVersionFragment = {
  __typename: 'form_version',
  id: number,
  form_id: number,
  question_ids: any,
  form_version_questions: Array<{
    __typename: 'form_version_question',
    question_id?: number | null,
    form_version_id?: number | null,
    question?: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    } | null
  }>
};

export type FlowFragment = {
  __typename: 'flow',
  id: number,
  token: string,
  title: string,
  logo_url?: string | null,
  redirect_uri?: string | null,
  preview_property_values: any,
  css?: string | null,
  default_language: language_enum,
  prevent_if_canceled: boolean,
  account: {
    __typename: 'account',
    id: number,
    title: string,
    prompt_for_subscriber_details: boolean,
    intake_form_name_email: boolean,
    flow_css?: string | null,
    show_branding_feature: Array<{
      __typename: 'account_feature',
      key: account_feature_key_enum,
      value: any
    }>,
    properties: Array<{
      __typename: 'property',
      id: number,
      name: string,
      type: property_type_enum,
      format?: property_format_enum | null
    }>
  },
  flow_texts: Array<{
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    published_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>,
  flow_languages: Array<{
    __typename: 'flow_language',
    flow_id: number,
    language: language_enum
  }>,
  flow_subscriber_form_questions: Array<{
    __typename: 'flow_subscriber_form_question',
    id: number,
    flow_id: number,
    title: string,
    hint: string,
    position: number,
    required: boolean
  }>,
  published_version?: {
    __typename: 'flow_version',
    id: number,
    flow_id: number,
    css?: string | null,
    flow_step_ids: any,
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_version',
    id: number,
    flow_id: number,
    css?: string | null,
    flow_step_ids: any,
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      } | null
    }>
  } | null,
  prevent_if_canceled_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null
};

export type FlowInterventionFragment = {
  __typename: 'intervention',
  id: number,
  token: string,
  title: string,
  offer_rule_group_id: number,
  offer_rule_group: {
    __typename: 'offer_rule_group',
    id: number,
    title: string
  },
  published_version?: {
    __typename: 'intervention_version',
    id: number,
    intervention_id: number,
    content_translation_id: number,
    continue_button_translation_id: number,
    cancel_button_translation_id: number,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    continue_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'intervention_version',
    id: number,
    intervention_id: number,
    content_translation_id: number,
    continue_button_translation_id: number,
    cancel_button_translation_id: number,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    continue_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type FlowInterventionVersionFragment = {
  __typename: 'intervention_version',
  id: number,
  intervention_id: number,
  content_translation_id: number,
  continue_button_translation_id: number,
  cancel_button_translation_id: number,
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  continue_button_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  cancel_button_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowOfferAutopilotRoundFragment = {
  __typename: 'offer_autopilot_round',
  id: number,
  offer_id: number,
  control_weight: number,
  control_variant_id: number,
  winning_variant_id?: number | null,
  offer_autopilot_round_offer_variants: Array<{
    __typename: 'offer_autopilot_round_offer_variant',
    offer_autopilot_round_id?: number | null,
    offer_variant_id?: number | null,
    offer_variant?: {
      __typename: 'offer_variant',
      id: number,
      type: offer_type_enum,
      offer_id: number,
      approved_at?: string | null,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_variant_coupon?: {
        __typename: 'offer_variant_coupon',
        offer_variant_id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null
      } | null
    } | null
  }>
};

export type FlowOfferAutopilotRoundOfferVariantFragment = {
  __typename: 'offer_autopilot_round_offer_variant',
  offer_autopilot_round_id?: number | null,
  offer_variant_id?: number | null,
  round_index?: number | null,
  offer_autopilot_round?: {
    __typename: 'offer_autopilot_round',
    id: number,
    offer_id: number,
    control_weight: number,
    control_variant_id: number,
    winning_variant_id?: number | null,
    offer_autopilot_round_offer_variants: Array<{
      __typename: 'offer_autopilot_round_offer_variant',
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        approved_at?: string | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null
    }>
  } | null,
  offer_variant?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null
};

export type FlowOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  name: string,
  show_banner: boolean,
  image_url?: string | null,
  goal: offer_goal_enum,
  style: offer_style_enum,
  created_at: string,
  updated_at: string,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_coupon?: {
    __typename: 'offer_coupon',
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null,
    months?: number | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    modify_at: offer_modify_subscription_modify_at_enum,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_connection_id: number,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null
    }>
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null,
      text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    }>
  } | null,
  offer_upgrade?: {
    __typename: 'offer_upgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_downgrade?: {
    __typename: 'offer_downgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_product_swap?: {
    __typename: 'offer_product_swap',
    specific_products: boolean,
    offer_product_swap_platform_ecommerce_products: Array<{
      __typename: 'offer_product_swap_platform_ecommerce_product',
      platform_ecommerce_product_id: string,
      platform_ecommerce_product?: {
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      } | null,
      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
        platform_variant?: {
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        } | null
      }>
    }>
  } | null,
  offer_reschedule_order?: {
    __typename: 'offer_reschedule_order',
    skip: boolean,
    reschedule: boolean,
    skip_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    reschedule_button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null,
  offer_timer?: {
    __typename: 'offer_timer',
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null,
  offer_autopilot?: {
    __typename: 'offer_autopilot',
    offer_id: number
  } | null
};

export type FlowOfferGroupFragment = {
  __typename: 'offer_group',
  id: number,
  name: string,
  token: string,
  show_images: boolean,
  randomize: boolean,
  offer_group_offers: Array<{
    __typename: 'offer_group_offer',
    position?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      show_banner: boolean,
      image_url?: string | null,
      goal: offer_goal_enum,
      style: offer_style_enum,
      created_at: string,
      updated_at: string,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        offer_id: number,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null,
        current_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null,
        winning_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null,
        offer_autopilot_offer_autopilot_rounds: Array<{
          __typename: 'offer_autopilot_offer_autopilot_round',
          offer_autopilot_round_id?: number | null,
          round_index?: number | null,
          offer_autopilot_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null
        }>
      } | null,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_coupon?: {
        __typename: 'offer_coupon',
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        }>
      } | null,
      offer_upgrade?: {
        __typename: 'offer_upgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_downgrade?: {
        __typename: 'offer_downgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_product_swap?: {
        __typename: 'offer_product_swap',
        specific_products: boolean,
        offer_product_swap_platform_ecommerce_products: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product',
          platform_ecommerce_product_id: string,
          platform_ecommerce_product?: {
            __typename: 'platform_ecommerce_product',
            platform_id: string,
            name: string,
            image_url?: string | null,
            platform_plan_ecommerce_products: Array<{
              __typename: 'platform_plan_ecommerce_product',
              discount_amount?: string | null,
              discount_type?: string | null
            }>
          } | null,
          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
            platform_variant?: {
              __typename: 'platform_variant',
              platform_id: string,
              name: string,
              price?: string | null,
              platform_variant_options: Array<{
                __typename: 'platform_variant_option',
                key: string,
                value: string
              }>
            } | null
          }>
        }>
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean,
        skip_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        reschedule_button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null
    } | null
  }>,
  headline_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  cancel_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowOfferModifySubscriptionOptionFragment = {
  __typename: 'offer_modify_subscription_option',
  type: offer_modify_subscription_option_type_enum,
  platform_connection_id: number,
  platform_plan_id?: string | null,
  platform_addon_id?: string | null
};

export type FlowOfferPauseSubscriptionOptionFragment = {
  __typename: 'offer_pause_subscription_option',
  type: offer_pause_subscription_option_type_enum,
  interval?: offer_pause_subscription_interval_enum | null,
  interval_count?: number | null,
  text_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null
};

export type FlowOfferRuleFragment = {
  __typename: 'offer_rule',
  id: number,
  published_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_ids: any,
    offer_test_ids: any,
    offer_group_ids: any,
    offer_autopilot_offer_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        deflection_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_rule_rule_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_rule_offer_groups: Array<{
          __typename: 'offer_rule_rule_offer_group',
          offer_rule_rule_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_rule_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_rule_offer_autopilot_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_rule_deflections: Array<{
          __typename: 'offer_rule_rule_deflection',
          offer_rule_rule_id?: number | null,
          deflection_id?: number | null,
          deflection?: {
            __typename: 'deflection',
            id: number,
            title: string,
            token: string,
            minimum_items?: number | null,
            heading_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            deflection_snapshot_items: Array<{
              __typename: 'deflection_snapshot_item_position',
              position?: number | null,
              deflection_snapshot_item?: {
                __typename: 'deflection_snapshot_item',
                id: number,
                token: string,
                icon_prefix?: string | null,
                icon_name?: string | null,
                property_id?: number | null,
                property_condition_value?: any | null,
                date_format?: string | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>,
            deflection_flow_actions: Array<{
              __typename: 'deflection_flow_action',
              position?: number | null,
              flow_action?: {
                __typename: 'flow_action',
                id: number,
                token: string,
                code?: string | null,
                type: flow_action_type_enum,
                account_id: number,
                appearance: flow_action_appearance_enum,
                url?: string | null,
                reroute_to_flow_id?: number | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          } | null
        }>
      } | null
    }>,
    offer_rule_version_offers: Array<{
      __typename: 'offer_rule_version_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    }>,
    offer_rule_version_offer_tests: Array<{
      __typename: 'offer_rule_version_offer_test',
      offer_rule_version_id?: number | null,
      offer_test_id?: number | null,
      offer_test?: {
        __typename: 'offer_test',
        id: number,
        name: string,
        token: string,
        goal: offer_goal_enum,
        control_weight: number,
        control_offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        },
        offer_test_offers: Array<{
          __typename: 'offer_test_offer',
          offer_test_id: number,
          offer_id: number,
          weight: number,
          offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          }
        }>,
        winning_offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      } | null
    }>,
    offer_rule_version_offer_groups: Array<{
      __typename: 'offer_rule_version_offer_group',
      offer_rule_version_id?: number | null,
      offer_group_id?: number | null,
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string,
        token: string,
        show_images: boolean,
        randomize: boolean,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            name: string,
            show_banner: boolean,
            image_url?: string | null,
            goal: offer_goal_enum,
            style: offer_style_enum,
            created_at: string,
            updated_at: string,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_coupon?: {
              __typename: 'offer_coupon',
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null,
              months?: number | null
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              modify_at: offer_modify_subscription_modify_at_enum,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_connection_id: number,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              }>
            } | null,
            offer_upgrade?: {
              __typename: 'offer_upgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_downgrade?: {
              __typename: 'offer_downgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_product_swap?: {
              __typename: 'offer_product_swap',
              specific_products: boolean,
              offer_product_swap_platform_ecommerce_products: Array<{
                __typename: 'offer_product_swap_platform_ecommerce_product',
                platform_ecommerce_product_id: string,
                platform_ecommerce_product?: {
                  __typename: 'platform_ecommerce_product',
                  platform_id: string,
                  name: string,
                  image_url?: string | null,
                  platform_plan_ecommerce_products: Array<{
                    __typename: 'platform_plan_ecommerce_product',
                    discount_amount?: string | null,
                    discount_type?: string | null
                  }>
                } | null,
                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                  platform_variant?: {
                    __typename: 'platform_variant',
                    platform_id: string,
                    name: string,
                    price?: string | null,
                    platform_variant_options: Array<{
                      __typename: 'platform_variant_option',
                      key: string,
                      value: string
                    }>
                  } | null
                }>
              }>
            } | null,
            offer_reschedule_order?: {
              __typename: 'offer_reschedule_order',
              skip: boolean,
              reschedule: boolean,
              skip_button_text_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              reschedule_button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            offer_custom?: {
              __typename: 'offer_custom',
              link_href?: string | null,
              link_text?: string | null
            } | null,
            offer_timer?: {
              __typename: 'offer_timer',
              offer_timer_mode: offer_timer_mode_enum,
              start_time?: string | null,
              end_time?: string | null,
              duration?: string | null,
              show_timer?: boolean | null
            } | null
          } | null
        }>,
        headline_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        cancel_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>,
    offer_rule_version_offer_autopilot_offers: Array<{
      __typename: 'offer_rule_version_offer_autopilot_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        offer_id: number,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null,
        current_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null,
        winning_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null,
        offer_autopilot_offer_autopilot_rounds: Array<{
          __typename: 'offer_autopilot_offer_autopilot_round',
          offer_autopilot_round_id?: number | null,
          round_index?: number | null,
          offer_autopilot_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_ids: any,
    offer_test_ids: any,
    offer_group_ids: any,
    offer_autopilot_offer_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        deflection_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_rule_rule_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_rule_offer_groups: Array<{
          __typename: 'offer_rule_rule_offer_group',
          offer_rule_rule_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_rule_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_rule_offer_autopilot_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_rule_deflections: Array<{
          __typename: 'offer_rule_rule_deflection',
          offer_rule_rule_id?: number | null,
          deflection_id?: number | null,
          deflection?: {
            __typename: 'deflection',
            id: number,
            title: string,
            token: string,
            minimum_items?: number | null,
            heading_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            deflection_snapshot_items: Array<{
              __typename: 'deflection_snapshot_item_position',
              position?: number | null,
              deflection_snapshot_item?: {
                __typename: 'deflection_snapshot_item',
                id: number,
                token: string,
                icon_prefix?: string | null,
                icon_name?: string | null,
                property_id?: number | null,
                property_condition_value?: any | null,
                date_format?: string | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>,
            deflection_flow_actions: Array<{
              __typename: 'deflection_flow_action',
              position?: number | null,
              flow_action?: {
                __typename: 'flow_action',
                id: number,
                token: string,
                code?: string | null,
                type: flow_action_type_enum,
                account_id: number,
                appearance: flow_action_appearance_enum,
                url?: string | null,
                reroute_to_flow_id?: number | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          } | null
        }>
      } | null
    }>,
    offer_rule_version_offers: Array<{
      __typename: 'offer_rule_version_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    }>,
    offer_rule_version_offer_tests: Array<{
      __typename: 'offer_rule_version_offer_test',
      offer_rule_version_id?: number | null,
      offer_test_id?: number | null,
      offer_test?: {
        __typename: 'offer_test',
        id: number,
        name: string,
        token: string,
        goal: offer_goal_enum,
        control_weight: number,
        control_offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        },
        offer_test_offers: Array<{
          __typename: 'offer_test_offer',
          offer_test_id: number,
          offer_id: number,
          weight: number,
          offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          }
        }>,
        winning_offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      } | null
    }>,
    offer_rule_version_offer_groups: Array<{
      __typename: 'offer_rule_version_offer_group',
      offer_rule_version_id?: number | null,
      offer_group_id?: number | null,
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string,
        token: string,
        show_images: boolean,
        randomize: boolean,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            name: string,
            show_banner: boolean,
            image_url?: string | null,
            goal: offer_goal_enum,
            style: offer_style_enum,
            created_at: string,
            updated_at: string,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_coupon?: {
              __typename: 'offer_coupon',
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null,
              months?: number | null
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              modify_at: offer_modify_subscription_modify_at_enum,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_connection_id: number,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              }>
            } | null,
            offer_upgrade?: {
              __typename: 'offer_upgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_downgrade?: {
              __typename: 'offer_downgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_product_swap?: {
              __typename: 'offer_product_swap',
              specific_products: boolean,
              offer_product_swap_platform_ecommerce_products: Array<{
                __typename: 'offer_product_swap_platform_ecommerce_product',
                platform_ecommerce_product_id: string,
                platform_ecommerce_product?: {
                  __typename: 'platform_ecommerce_product',
                  platform_id: string,
                  name: string,
                  image_url?: string | null,
                  platform_plan_ecommerce_products: Array<{
                    __typename: 'platform_plan_ecommerce_product',
                    discount_amount?: string | null,
                    discount_type?: string | null
                  }>
                } | null,
                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                  platform_variant?: {
                    __typename: 'platform_variant',
                    platform_id: string,
                    name: string,
                    price?: string | null,
                    platform_variant_options: Array<{
                      __typename: 'platform_variant_option',
                      key: string,
                      value: string
                    }>
                  } | null
                }>
              }>
            } | null,
            offer_reschedule_order?: {
              __typename: 'offer_reschedule_order',
              skip: boolean,
              reschedule: boolean,
              skip_button_text_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              reschedule_button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            offer_custom?: {
              __typename: 'offer_custom',
              link_href?: string | null,
              link_text?: string | null
            } | null,
            offer_timer?: {
              __typename: 'offer_timer',
              offer_timer_mode: offer_timer_mode_enum,
              start_time?: string | null,
              end_time?: string | null,
              duration?: string | null,
              show_timer?: boolean | null
            } | null
          } | null
        }>,
        headline_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        cancel_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>,
    offer_rule_version_offer_autopilot_offers: Array<{
      __typename: 'offer_rule_version_offer_autopilot_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        offer_id: number,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null,
        current_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null,
        winning_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null,
        offer_autopilot_offer_autopilot_rounds: Array<{
          __typename: 'offer_autopilot_offer_autopilot_round',
          offer_autopilot_round_id?: number | null,
          round_index?: number | null,
          offer_autopilot_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null
        }>
      } | null
    }>
  } | null
};

export type FlowOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  title: string,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type FlowOfferRuleGroupVersionFragment = {
  __typename: 'offer_rule_group_version',
  id: number,
  offer_rule_group_id: number,
  offer_rule_ids: any,
  offer_rule_group_version_offer_rules: Array<{
    __typename: 'offer_rule_group_version_offer_rule',
    offer_rule?: {
      __typename: 'offer_rule',
      id: number,
      published_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_rule_rule_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_rule_rule_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_rule_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_rule_offer_autopilot_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_version_offers: Array<{
          __typename: 'offer_rule_version_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_version_offer_tests: Array<{
          __typename: 'offer_rule_version_offer_test',
          offer_rule_version_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_version_offer_groups: Array<{
          __typename: 'offer_rule_version_offer_group',
          offer_rule_version_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_version_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_version_offer_autopilot_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_rule_rule_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_rule_rule_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_rule_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_rule_offer_autopilot_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_version_offers: Array<{
          __typename: 'offer_rule_version_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_version_offer_tests: Array<{
          __typename: 'offer_rule_version_offer_test',
          offer_rule_version_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_version_offer_groups: Array<{
          __typename: 'offer_rule_version_offer_group',
          offer_rule_version_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_version_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_version_offer_autopilot_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null
    } | null
  }>
};

export type FlowOfferRuleOfferAutopilotFragment = {
  __typename: 'offer_autopilot',
  offer_id: number,
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  } | null,
  current_round?: {
    __typename: 'offer_autopilot_round',
    id: number,
    offer_id: number,
    control_weight: number,
    control_variant_id: number,
    winning_variant_id?: number | null,
    offer_autopilot_round_offer_variants: Array<{
      __typename: 'offer_autopilot_round_offer_variant',
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        approved_at?: string | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null
    }>
  } | null,
  winning_variant?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null,
  offer_autopilot_offer_autopilot_rounds: Array<{
    __typename: 'offer_autopilot_offer_autopilot_round',
    offer_autopilot_round_id?: number | null,
    round_index?: number | null,
    offer_autopilot_round?: {
      __typename: 'offer_autopilot_round',
      id: number,
      offer_id: number,
      control_weight: number,
      control_variant_id: number,
      winning_variant_id?: number | null,
      offer_autopilot_round_offer_variants: Array<{
        __typename: 'offer_autopilot_round_offer_variant',
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        offer_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type FlowOfferRuleOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum
};

export type FlowOfferRuleRuleFragment = {
  __typename: 'offer_rule_rule',
  id: number,
  offer_rule_id: number,
  offer_ids: any,
  offer_test_ids: any,
  offer_group_ids: any,
  offer_autopilot_offer_ids: any,
  deflection_ids: any,
  include_present_no_offer: boolean,
  weight: number,
  offer_rule_rule_offers: Array<{
    __typename: 'offer_rule_rule_offer',
    offer_rule_rule_id?: number | null,
    offer_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_rule_rule_offer_tests: Array<{
    __typename: 'offer_rule_rule_offer_test',
    offer_rule_rule_id?: number | null,
    offer_test_id?: number | null,
    offer_test?: {
      __typename: 'offer_test',
      id: number,
      name: string,
      token: string,
      goal: offer_goal_enum,
      control_weight: number,
      control_offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      },
      offer_test_offers: Array<{
        __typename: 'offer_test_offer',
        offer_test_id: number,
        offer_id: number,
        weight: number,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        }
      }>,
      winning_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    } | null
  }>,
  offer_rule_rule_offer_groups: Array<{
    __typename: 'offer_rule_rule_offer_group',
    offer_rule_rule_id?: number | null,
    offer_group_id?: number | null,
    offer_group?: {
      __typename: 'offer_group',
      id: number,
      name: string,
      token: string,
      show_images: boolean,
      randomize: boolean,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          show_banner: boolean,
          image_url?: string | null,
          goal: offer_goal_enum,
          style: offer_style_enum,
          created_at: string,
          updated_at: string,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_coupon?: {
            __typename: 'offer_coupon',
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              text_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            }>
          } | null,
          offer_upgrade?: {
            __typename: 'offer_upgrade',
            platform_plan_id?: string | null,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_downgrade?: {
            __typename: 'offer_downgrade',
            platform_plan_id?: string | null,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_product_swap?: {
            __typename: 'offer_product_swap',
            specific_products: boolean,
            offer_product_swap_platform_ecommerce_products: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product',
              platform_ecommerce_product_id: string,
              platform_ecommerce_product?: {
                __typename: 'platform_ecommerce_product',
                platform_id: string,
                name: string,
                image_url?: string | null,
                platform_plan_ecommerce_products: Array<{
                  __typename: 'platform_plan_ecommerce_product',
                  discount_amount?: string | null,
                  discount_type?: string | null
                }>
              } | null,
              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                platform_variant?: {
                  __typename: 'platform_variant',
                  platform_id: string,
                  name: string,
                  price?: string | null,
                  platform_variant_options: Array<{
                    __typename: 'platform_variant_option',
                    key: string,
                    value: string
                  }>
                } | null
              }>
            }>
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean,
            skip_button_text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            reschedule_button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_timer?: {
            __typename: 'offer_timer',
            offer_timer_mode: offer_timer_mode_enum,
            start_time?: string | null,
            end_time?: string | null,
            duration?: string | null,
            show_timer?: boolean | null
          } | null
        } | null
      }>,
      headline_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>,
  offer_rule_rule_offer_autopilot_offers: Array<{
    __typename: 'offer_rule_rule_offer_autopilot_offer',
    offer_rule_rule_id?: number | null,
    offer_id?: number | null,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null,
      current_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        offer_id: number,
        control_weight: number,
        control_variant_id: number,
        winning_variant_id?: number | null,
        offer_autopilot_round_offer_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null
        }>
      } | null,
      winning_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        approved_at?: string | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null,
      offer_autopilot_offer_autopilot_rounds: Array<{
        __typename: 'offer_autopilot_offer_autopilot_round',
        offer_autopilot_round_id?: number | null,
        round_index?: number | null,
        offer_autopilot_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null
      }>
    } | null
  }>,
  offer_rule_rule_deflections: Array<{
    __typename: 'offer_rule_rule_deflection',
    offer_rule_rule_id?: number | null,
    deflection_id?: number | null,
    deflection?: {
      __typename: 'deflection',
      id: number,
      title: string,
      token: string,
      minimum_items?: number | null,
      heading_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      deflection_snapshot_items: Array<{
        __typename: 'deflection_snapshot_item_position',
        position?: number | null,
        deflection_snapshot_item?: {
          __typename: 'deflection_snapshot_item',
          id: number,
          token: string,
          icon_prefix?: string | null,
          icon_name?: string | null,
          property_id?: number | null,
          property_condition_value?: any | null,
          date_format?: string | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        position?: number | null,
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    } | null
  }>
};

export type FlowOfferRuleVersionFragment = {
  __typename: 'offer_rule_version',
  id: number,
  offer_rule_id: number,
  segment_group_ids: any,
  segment_ids: any,
  question_option_ids: any,
  offer_ids: any,
  offer_test_ids: any,
  offer_group_ids: any,
  offer_autopilot_offer_ids: any,
  include_present_no_offer: boolean,
  include_other_in_question_ids: any,
  offer_rule_rule_ids: any,
  offer_rule_version_segment_groups: Array<{
    __typename: 'offer_rule_version_segment_group',
    offer_rule_version_id?: number | null,
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  offer_rule_version_segments: Array<{
    __typename: 'offer_rule_version_segment',
    offer_rule_version_id?: number | null,
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  offer_rule_version_question_options: Array<{
    __typename: 'offer_rule_version_question_option',
    offer_rule_version_id?: number | null,
    question_option_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>,
  offer_rule_version_offer_rule_rules: Array<{
    __typename: 'offer_rule_version_offer_rule_rule',
    offer_rule_version_id?: number | null,
    offer_rule_rule_id?: number | null,
    offer_rule_rule?: {
      __typename: 'offer_rule_rule',
      id: number,
      offer_rule_id: number,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      deflection_ids: any,
      include_present_no_offer: boolean,
      weight: number,
      offer_rule_rule_offers: Array<{
        __typename: 'offer_rule_rule_offer',
        offer_rule_rule_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_rule_offer_tests: Array<{
        __typename: 'offer_rule_rule_offer_test',
        offer_rule_rule_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_rule_offer_groups: Array<{
        __typename: 'offer_rule_rule_offer_group',
        offer_rule_rule_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_rule_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_rule_offer_autopilot_offer',
        offer_rule_rule_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_rule_deflections: Array<{
        __typename: 'offer_rule_rule_deflection',
        offer_rule_rule_id?: number | null,
        deflection_id?: number | null,
        deflection?: {
          __typename: 'deflection',
          id: number,
          title: string,
          token: string,
          minimum_items?: number | null,
          heading_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          deflection_snapshot_items: Array<{
            __typename: 'deflection_snapshot_item_position',
            position?: number | null,
            deflection_snapshot_item?: {
              __typename: 'deflection_snapshot_item',
              id: number,
              token: string,
              icon_prefix?: string | null,
              icon_name?: string | null,
              property_id?: number | null,
              property_condition_value?: any | null,
              date_format?: string | null,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>,
          deflection_flow_actions: Array<{
            __typename: 'deflection_flow_action',
            position?: number | null,
            flow_action?: {
              __typename: 'flow_action',
              id: number,
              token: string,
              code?: string | null,
              type: flow_action_type_enum,
              account_id: number,
              appearance: flow_action_appearance_enum,
              url?: string | null,
              reroute_to_flow_id?: number | null,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>
        } | null
      }>
    } | null
  }>,
  offer_rule_version_offers: Array<{
    __typename: 'offer_rule_version_offer',
    offer_rule_version_id?: number | null,
    offer_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_rule_version_offer_tests: Array<{
    __typename: 'offer_rule_version_offer_test',
    offer_rule_version_id?: number | null,
    offer_test_id?: number | null,
    offer_test?: {
      __typename: 'offer_test',
      id: number,
      name: string,
      token: string,
      goal: offer_goal_enum,
      control_weight: number,
      control_offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      },
      offer_test_offers: Array<{
        __typename: 'offer_test_offer',
        offer_test_id: number,
        offer_id: number,
        weight: number,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        }
      }>,
      winning_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    } | null
  }>,
  offer_rule_version_offer_groups: Array<{
    __typename: 'offer_rule_version_offer_group',
    offer_rule_version_id?: number | null,
    offer_group_id?: number | null,
    offer_group?: {
      __typename: 'offer_group',
      id: number,
      name: string,
      token: string,
      show_images: boolean,
      randomize: boolean,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          show_banner: boolean,
          image_url?: string | null,
          goal: offer_goal_enum,
          style: offer_style_enum,
          created_at: string,
          updated_at: string,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_coupon?: {
            __typename: 'offer_coupon',
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              text_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            }>
          } | null,
          offer_upgrade?: {
            __typename: 'offer_upgrade',
            platform_plan_id?: string | null,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_downgrade?: {
            __typename: 'offer_downgrade',
            platform_plan_id?: string | null,
            change_at: offer_change_plan_change_at_enum
          } | null,
          offer_product_swap?: {
            __typename: 'offer_product_swap',
            specific_products: boolean,
            offer_product_swap_platform_ecommerce_products: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product',
              platform_ecommerce_product_id: string,
              platform_ecommerce_product?: {
                __typename: 'platform_ecommerce_product',
                platform_id: string,
                name: string,
                image_url?: string | null,
                platform_plan_ecommerce_products: Array<{
                  __typename: 'platform_plan_ecommerce_product',
                  discount_amount?: string | null,
                  discount_type?: string | null
                }>
              } | null,
              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                platform_variant?: {
                  __typename: 'platform_variant',
                  platform_id: string,
                  name: string,
                  price?: string | null,
                  platform_variant_options: Array<{
                    __typename: 'platform_variant_option',
                    key: string,
                    value: string
                  }>
                } | null
              }>
            }>
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean,
            skip_button_text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            reschedule_button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_timer?: {
            __typename: 'offer_timer',
            offer_timer_mode: offer_timer_mode_enum,
            start_time?: string | null,
            end_time?: string | null,
            duration?: string | null,
            show_timer?: boolean | null
          } | null
        } | null
      }>,
      headline_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>,
  offer_rule_version_offer_autopilot_offers: Array<{
    __typename: 'offer_rule_version_offer_autopilot_offer',
    offer_rule_version_id?: number | null,
    offer_id?: number | null,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null,
      current_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        offer_id: number,
        control_weight: number,
        control_variant_id: number,
        winning_variant_id?: number | null,
        offer_autopilot_round_offer_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null
        }>
      } | null,
      winning_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        approved_at?: string | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null,
      offer_autopilot_offer_autopilot_rounds: Array<{
        __typename: 'offer_autopilot_offer_autopilot_round',
        offer_autopilot_round_id?: number | null,
        round_index?: number | null,
        offer_autopilot_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null
      }>
    } | null
  }>
};

export type FlowOfferTestFragment = {
  __typename: 'offer_test',
  id: number,
  name: string,
  token: string,
  goal: offer_goal_enum,
  control_weight: number,
  control_offer: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  },
  offer_test_offers: Array<{
    __typename: 'offer_test_offer',
    offer_test_id: number,
    offer_id: number,
    weight: number,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    }
  }>,
  winning_offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  } | null
};

export type FlowOfferVariantFragment = {
  __typename: 'offer_variant',
  id: number,
  type: offer_type_enum,
  offer_id: number,
  approved_at?: string | null,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_variant_coupon?: {
    __typename: 'offer_variant_coupon',
    offer_variant_id: number,
    offer_id: number,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    months?: number | null,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null
  } | null
};

export type FlowPlatformConnectionFragment = {
  __typename: 'platform_connection',
  platform: platform_enum,
  platform_ecommerce_products: Array<{
    __typename: 'platform_ecommerce_product',
    platform_id: string,
    name: string,
    image_url?: string | null,
    platform_variants: Array<{
      __typename: 'platform_variant',
      platform_id: string,
      name: string,
      price?: string | null,
      platform_variant_options: Array<{
        __typename: 'platform_variant_option',
        key: string,
        value: string
      }>
    }>,
    platform_plan_ecommerce_products: Array<{
      __typename: 'platform_plan_ecommerce_product',
      discount_amount?: string | null,
      discount_type?: string | null
    }>
  }>
};

export type FlowPlatformEcommerceProductFragment = {
  __typename: 'platform_ecommerce_product',
  platform_id: string,
  name: string,
  image_url?: string | null,
  platform_variants: Array<{
    __typename: 'platform_variant',
    platform_id: string,
    name: string,
    price?: string | null,
    platform_variant_options: Array<{
      __typename: 'platform_variant_option',
      key: string,
      value: string
    }>
  }>,
  platform_plan_ecommerce_products: Array<{
    __typename: 'platform_plan_ecommerce_product',
    discount_amount?: string | null,
    discount_type?: string | null
  }>
};

export type FlowPreviewSegmentFragment = {
  __typename: 'segment',
  id: number,
  name: string,
  integration?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  } | null
};

export type FlowPropertyFragment = {
  __typename: 'property',
  id: number,
  name: string,
  entity: property_entity_enum,
  type: property_type_enum,
  format?: property_format_enum | null
};

export type FlowQuestionFragment = {
  __typename: 'question',
  id: number,
  token: string,
  type: question_type_enum,
  primary: boolean,
  published_version?: {
    __typename: 'question_version',
    id: number,
    question_id: number,
    required: boolean,
    title_translation_id: number,
    hint_translation_id: number,
    placeholder_translation_id?: number | null,
    condition_segment_ids?: any | null,
    condition_question_option_ids?: any | null,
    condition_include_other_in_question_ids?: any | null,
    question_option_ids?: any | null,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    hint_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    placeholder_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option_id?: number | null,
      question_version_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        token: string,
        reason_code?: string | null,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_question_options: Array<{
      __typename: 'question_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_segments: Array<{
      __typename: 'question_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    question_version_condition_segment_groups: Array<{
      __typename: 'question_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'question_version',
    id: number,
    question_id: number,
    required: boolean,
    title_translation_id: number,
    hint_translation_id: number,
    placeholder_translation_id?: number | null,
    condition_segment_ids?: any | null,
    condition_question_option_ids?: any | null,
    condition_include_other_in_question_ids?: any | null,
    question_option_ids?: any | null,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    hint_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    placeholder_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option_id?: number | null,
      question_version_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        token: string,
        reason_code?: string | null,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_question_options: Array<{
      __typename: 'question_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_segments: Array<{
      __typename: 'question_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    question_version_condition_segment_groups: Array<{
      __typename: 'question_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>
  } | null,
  question_settings: Array<{
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum,
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null,
    draft_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  }>
};

export type FlowQuestionOptionFragment = {
  __typename: 'question_option',
  id: number,
  question_id: number,
  token: string,
  reason_code?: string | null,
  published_version?: {
    __typename: 'question_option_version',
    id: number,
    question_option_id: number,
    title_translation_id: number,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'question_option_version',
    id: number,
    question_option_id: number,
    title_translation_id: number,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type FlowQuestionOptionVersionFragment = {
  __typename: 'question_option_version',
  id: number,
  question_option_id: number,
  title_translation_id: number,
  title_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowQuestionSettingFragment = {
  __typename: 'question_setting',
  question_id: number,
  key: question_setting_key_enum,
  published_version?: {
    __typename: 'question_setting_version',
    id: number,
    question_id: number,
    key: question_setting_key_enum,
    value: any
  } | null,
  draft_version?: {
    __typename: 'question_setting_version',
    id: number,
    question_id: number,
    key: question_setting_key_enum,
    value: any
  } | null
};

export type FlowQuestionSettingVersionFragment = {
  __typename: 'question_setting_version',
  id: number,
  question_id: number,
  key: question_setting_key_enum,
  value: any
};

export type FlowQuestionVersionFragment = {
  __typename: 'question_version',
  id: number,
  question_id: number,
  required: boolean,
  title_translation_id: number,
  hint_translation_id: number,
  placeholder_translation_id?: number | null,
  condition_segment_ids?: any | null,
  condition_question_option_ids?: any | null,
  condition_include_other_in_question_ids?: any | null,
  question_option_ids?: any | null,
  title_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  hint_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  placeholder_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  question_version_question_options: Array<{
    __typename: 'question_version_question_option',
    question_option_id?: number | null,
    question_version_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      token: string,
      reason_code?: string | null,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        question_option_id: number,
        title_translation_id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        question_option_id: number,
        title_translation_id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>,
  question_version_condition_question_options: Array<{
    __typename: 'question_version_condition_question_option',
    question_option_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>,
  question_version_condition_segments: Array<{
    __typename: 'question_version_condition_segment',
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  question_version_condition_segment_groups: Array<{
    __typename: 'question_version_condition_segment_group',
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>
};

export type FlowRouteRuleFragment = {
  __typename: 'flow_route_rule',
  id: number,
  segment_group_ids: any,
  segment_ids: any,
  flow_ids: any,
  flow_test_ids: any,
  eligibility_message_ids: any,
  flow_route_rule_segment_groups: Array<{
    __typename: 'flow_route_rule_segment_group',
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  flow_route_rule_segments: Array<{
    __typename: 'flow_route_rule_segment',
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  flow_route_rule_flows: Array<{
    __typename: 'flow_route_rule_flow',
    flow_id?: number | null
  }>,
  flow_route_rule_flow_tests: Array<{
    __typename: 'flow_route_rule_flow_test',
    flow_test_id?: number | null,
    flow_test?: {
      __typename: 'flow_test',
      id: number,
      name: string,
      control_weight: number,
      winning_flow_id?: number | null,
      control_flow: {
        __typename: 'flow',
        id: number
      },
      flow_test_flows: Array<{
        __typename: 'flow_test_flow',
        flow_test_id: number,
        flow_id: number,
        weight: number
      }>
    } | null
  }>,
  flow_route_rule_eligibility_messages: Array<{
    __typename: 'flow_route_rule_eligibility_message',
    eligibility_message_id?: number | null,
    eligibility_message?: {
      __typename: 'eligibility_message',
      id: number,
      name: string
    } | null
  }>
};

export type FlowSegmentFragment = {
  __typename: 'segment',
  id: number,
  name: string
};

export type FlowSegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  name: string,
  segment_group_segments: Array<{
    __typename: 'segment_group_segment',
    operator: segment_group_operator_enum,
    segment: {
      __typename: 'segment',
      id: number,
      name: string
    }
  }>
};

export type FlowStepFragment = {
  __typename: 'flow_step',
  id: number,
  token: string,
  type: flow_step_type_enum,
  published_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_id: number,
    condition_segment_group_ids: any,
    condition_segment_ids: any,
    condition_question_option_ids: any,
    condition_include_other_in_question_ids: any,
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    flow_step_version_condition_question_options: Array<{
      __typename: 'flow_step_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_id: number,
    condition_segment_group_ids: any,
    condition_segment_ids: any,
    condition_question_option_ids: any,
    condition_include_other_in_question_ids: any,
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    flow_step_version_condition_question_options: Array<{
      __typename: 'flow_step_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  flow_step_acknowledgement_group?: {
    __typename: 'flow_step_acknowledgement_group',
    flow_step_id: number,
    acknowledgement_group_id: number,
    acknowledgement_group: {
      __typename: 'acknowledgement_group',
      id: number,
      published_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null,
  flow_step_form?: {
    __typename: 'flow_step_form',
    flow_step_id: number,
    form_id: number,
    form: {
      __typename: 'form',
      id: number,
      title: string,
      published_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null
    }
  } | null,
  flow_step_question?: {
    __typename: 'flow_step_question',
    flow_step_id: number,
    question_id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null,
  flow_step_deflection_rule_group?: {
    __typename: 'flow_step_deflection_rule_group',
    flow_step_id?: number | null,
    offer_rule_group_id?: number | null,
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      id: number,
      title: string,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    } | null
  } | null,
  flow_step_offer_rule_group?: {
    __typename: 'flow_step_offer_rule_group',
    flow_step_id?: number | null,
    offer_rule_group_id?: number | null,
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      id: number,
      title: string
    } | null
  } | null,
  flow_step_rule_group?: {
    __typename: 'flow_step_rule_group',
    flow_step_id: number,
    offer_rule_group_id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      title: string
    }
  } | null,
  flow_step_confirmation?: {
    __typename: 'flow_step_confirmation',
    flow_step_id: number,
    confirmation_id: number,
    confirmation: {
      __typename: 'confirmation',
      id: number,
      published_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null,
  flow_step_intervention?: {
    __typename: 'flow_step_intervention',
    flow_step_id: number,
    intervention_id: number,
    intervention: {
      __typename: 'intervention',
      id: number,
      token: string,
      title: string,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      },
      published_version?: {
        __typename: 'intervention_version',
        id: number,
        intervention_id: number,
        content_translation_id: number,
        continue_button_translation_id: number,
        cancel_button_translation_id: number,
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        continue_button_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        cancel_button_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'intervention_version',
        id: number,
        intervention_id: number,
        content_translation_id: number,
        continue_button_translation_id: number,
        cancel_button_translation_id: number,
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        continue_button_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        cancel_button_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null
};

export type FlowStepVersionFragment = {
  __typename: 'flow_step_version',
  id: number,
  flow_step_id: number,
  condition_segment_group_ids: any,
  condition_segment_ids: any,
  condition_question_option_ids: any,
  condition_include_other_in_question_ids: any,
  flow_step_version_condition_segment_groups: Array<{
    __typename: 'flow_step_version_condition_segment_group',
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      name: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  flow_step_version_condition_segments: Array<{
    __typename: 'flow_step_version_condition_segment',
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  flow_step_version_condition_question_options: Array<{
    __typename: 'flow_step_version_condition_question_option',
    question_option_id?: number | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      question_id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>
};

export type FlowSubscriberFormQuestionFragment = {
  __typename: 'flow_subscriber_form_question',
  id: number,
  flow_id: number,
  title: string,
  hint: string,
  position: number,
  required: boolean
};

export type FlowSubscriberPropertyFragment = {
  __typename: 'subscriber_property',
  property_id: number,
  value: any
};

export type FlowSubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  platform_subscription?: {
    __typename: 'platform_subscription',
    platform_id: string,
    swappable_ecommerce_products: Array<{
      __typename: 'platform_subscription_plan_swappable_ecommerce_product',
      platform_ecommerce_product?: {
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        platform_variants: Array<{
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        }>,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      } | null
    }>,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      price?: string | null,
      platform_variant?: {
        __typename: 'platform_variant',
        platform_id: string,
        price?: string | null,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string
        } | null,
        platform_variant_options: Array<{
          __typename: 'platform_variant_option',
          key: string,
          value: string
        }>
      } | null
    }>
  } | null
};

export type FlowSubscriptionPropertyFragment = {
  __typename: 'subscription_property',
  property_id: number,
  value: any
};

export type FlowTestFragment = {
  __typename: 'flow_test',
  id: number,
  token: string,
  name: string,
  created_at: string,
  control_weight: number,
  control_flow: {
    __typename: 'flow',
    id: number,
    title: string,
    token: string
  },
  winning_flow?: {
    __typename: 'flow',
    id: number,
    title: string,
    token: string
  } | null,
  flow_test_flows: Array<{
    __typename: 'flow_test_flow',
    flow_test_id: number,
    flow_id: number,
    weight: number,
    flow: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    }
  }>,
  report_flow_test_stats: Array<{
    __typename: 'report_flow_test_stats',
    flow_id?: number | null,
    views?: number | null,
    cancels?: number | null,
    saves?: number | null,
    deflections?: number | null,
    retains?: number | null
  }>
};

export type FlowTextFragment = {
  __typename: 'flow_text',
  flow_id: number,
  key: flow_text_key_enum,
  published_version?: {
    __typename: 'flow_text_version',
    id: number,
    flow_id: number,
    key: flow_text_key_enum,
    translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'flow_text_version',
    id: number,
    flow_id: number,
    key: flow_text_key_enum,
    translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type FlowTextVersionFragment = {
  __typename: 'flow_text_version',
  id: number,
  flow_id: number,
  key: flow_text_key_enum,
  translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type FlowVersionFragment = {
  __typename: 'flow_version',
  id: number,
  flow_id: number,
  css?: string | null,
  flow_step_ids: any,
  flow_version_flow_steps: Array<{
    __typename: 'flow_version_flow_step',
    flow_step?: {
      __typename: 'flow_step',
      id: number,
      token: string,
      type: flow_step_type_enum,
      published_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      flow_step_acknowledgement_group?: {
        __typename: 'flow_step_acknowledgement_group',
        flow_step_id: number,
        acknowledgement_group_id: number,
        acknowledgement_group: {
          __typename: 'acknowledgement_group',
          id: number,
          published_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_form?: {
        __typename: 'flow_step_form',
        flow_step_id: number,
        form_id: number,
        form: {
          __typename: 'form',
          id: number,
          title: string,
          published_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_question?: {
        __typename: 'flow_step_question',
        flow_step_id: number,
        question_id: number,
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          published_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          question_settings: Array<{
            __typename: 'question_setting',
            question_id: number,
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null,
            draft_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null
          }>
        }
      } | null,
      flow_step_deflection_rule_group?: {
        __typename: 'flow_step_deflection_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null,
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        } | null
      } | null,
      flow_step_rule_group?: {
        __typename: 'flow_step_rule_group',
        flow_step_id: number,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        }
      } | null,
      flow_step_confirmation?: {
        __typename: 'flow_step_confirmation',
        flow_step_id: number,
        confirmation_id: number,
        confirmation: {
          __typename: 'confirmation',
          id: number,
          published_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null,
      flow_step_intervention?: {
        __typename: 'flow_step_intervention',
        flow_step_id: number,
        intervention_id: number,
        intervention: {
          __typename: 'intervention',
          id: number,
          token: string,
          title: string,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          },
          published_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null
    } | null
  }>
};

export type OfferAutopilotFragment = {
  __typename: 'offer_autopilot',
  offer_id: number,
  strategy: offer_autopilot_strategy_enum,
  success_metric: offer_autopilot_success_metric_enum,
  confidence_threshold: string,
  winning_variant_id?: number | null,
  created_at: string,
  completed_at?: string | null,
  current_round_id?: number | null,
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    override_active_offer: boolean,
    show_banner: boolean,
    type: offer_type_enum,
    goal: offer_goal_enum,
    style: offer_style_enum,
    image_url?: string | null,
    metadata: any,
    confirmation_enabled: boolean,
    created_at: string,
    archived_at?: string | null,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        name: string,
        type: property_type_enum,
        format?: property_format_enum | null
      }>,
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    },
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    confirmation_content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_confirm_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_cancel_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum,
      override_active_coupon: boolean,
      end_trial: boolean,
      platform_id?: string | null,
      platform_coupon?: {
        __typename: 'platform_coupon',
        code?: string | null
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_id?: number | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null,
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          active: boolean,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      strategy: offer_autopilot_strategy_enum
    } | null
  } | null,
  offer_autopilot_offer_autopilot_rounds: Array<{
    __typename: 'offer_autopilot_offer_autopilot_round',
    round_index?: number | null,
    offer_autopilot_round_id?: number | null,
    offer_autopilot_round?: {
      __typename: 'offer_autopilot_round',
      id: number,
      offer_id: number,
      strategy: offer_autopilot_round_strategy_enum,
      control_weight: number,
      control_variant_id: number,
      winning_variant_id?: number | null,
      started_at?: string | null,
      completed_at?: string | null,
      offer_autopilot_round_offer_variants: Array<{
        __typename: 'offer_autopilot_round_offer_variant',
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        round_index?: number | null,
        variant_index?: number | null,
        offer_variant?: {
          __typename: 'offer_variant',
          id: number,
          approved_at?: string | null
        } | null,
        report_offer_autopilot_stats?: {
          __typename: 'report_offer_autopilot_stats',
          offer_id?: number | null,
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          views?: string | null,
          accepts?: number | null,
          declines?: number | null,
          bypasses?: number | null,
          avg_mrr?: string | null,
          saved_mrr?: string | null,
          lost_mrr?: string | null
        } | null
      }>,
      offer_autopilot_round_logs: Array<{
        __typename: 'offer_autopilot_round_log',
        data: any
      }>
    } | null
  }>,
  offer_autopilot_offer_variants: Array<{
    __typename: 'offer_autopilot_offer_variant',
    offer_id?: number | null,
    variant_index?: number | null,
    offer_variant?: {
      __typename: 'offer_variant',
      id: number,
      type: offer_type_enum,
      offer_id: number,
      created_at: string,
      approved_at?: string | null,
      approval_user_id?: string | null,
      data?: any,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_variant_coupon?: {
        __typename: 'offer_variant_coupon',
        offer_variant_id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null
      } | null
    } | null
  }>,
  report_offer_autopilot_stats: Array<{
    __typename: 'report_offer_autopilot_stats',
    offer_id?: number | null,
    offer_autopilot_round_id?: number | null,
    offer_variant_id?: number | null,
    views?: string | null,
    accepts?: number | null,
    declines?: number | null,
    bypasses?: number | null
  }>
};

export type OfferAutopilotOfferAutopilotRoundFragment = {
  __typename: 'offer_autopilot_offer_autopilot_round',
  round_index?: number | null,
  offer_autopilot_round_id?: number | null,
  offer_autopilot_round?: {
    __typename: 'offer_autopilot_round',
    id: number,
    offer_id: number,
    strategy: offer_autopilot_round_strategy_enum,
    control_weight: number,
    control_variant_id: number,
    winning_variant_id?: number | null,
    started_at?: string | null,
    completed_at?: string | null,
    offer_autopilot_round_offer_variants: Array<{
      __typename: 'offer_autopilot_round_offer_variant',
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      round_index?: number | null,
      variant_index?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        approved_at?: string | null
      } | null,
      report_offer_autopilot_stats?: {
        __typename: 'report_offer_autopilot_stats',
        offer_id?: number | null,
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        views?: string | null,
        accepts?: number | null,
        declines?: number | null,
        bypasses?: number | null,
        avg_mrr?: string | null,
        saved_mrr?: string | null,
        lost_mrr?: string | null
      } | null
    }>,
    offer_autopilot_round_logs: Array<{
      __typename: 'offer_autopilot_round_log',
      data: any
    }>
  } | null
};

export type OfferAutopilotOfferVariantFragment = {
  __typename: 'offer_autopilot_offer_variant',
  offer_id?: number | null,
  variant_index?: number | null,
  offer_variant?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    created_at: string,
    approved_at?: string | null,
    approval_user_id?: string | null,
    data?: any,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null
};

export type OfferAutopilotRoundFragment = {
  __typename: 'offer_autopilot_round',
  id: number,
  offer_id: number,
  strategy: offer_autopilot_round_strategy_enum,
  control_weight: number,
  control_variant_id: number,
  winning_variant_id?: number | null,
  started_at?: string | null,
  completed_at?: string | null,
  offer_autopilot_round_offer_variants: Array<{
    __typename: 'offer_autopilot_round_offer_variant',
    offer_autopilot_round_id?: number | null,
    offer_variant_id?: number | null,
    round_index?: number | null,
    variant_index?: number | null,
    offer_variant?: {
      __typename: 'offer_variant',
      id: number,
      approved_at?: string | null
    } | null,
    report_offer_autopilot_stats?: {
      __typename: 'report_offer_autopilot_stats',
      offer_id?: number | null,
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null,
      bypasses?: number | null,
      avg_mrr?: string | null,
      saved_mrr?: string | null,
      lost_mrr?: string | null
    } | null
  }>,
  offer_autopilot_round_logs: Array<{
    __typename: 'offer_autopilot_round_log',
    data: any
  }>
};

export type OfferAutopilotRoundOfferVariantFragment = {
  __typename: 'offer_autopilot_round_offer_variant',
  offer_autopilot_round_id?: number | null,
  offer_variant_id?: number | null,
  round_index?: number | null,
  variant_index?: number | null,
  offer_variant?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    created_at: string,
    approved_at?: string | null,
    approval_user_id?: string | null,
    data?: any,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null,
  offer_autopilot_round?: {
    __typename: 'offer_autopilot_round',
    id: number,
    offer_id: number,
    strategy: offer_autopilot_round_strategy_enum,
    control_weight: number,
    control_variant_id: number,
    winning_variant_id?: number | null,
    started_at?: string | null,
    completed_at?: string | null,
    offer_autopilot_round_offer_variants: Array<{
      __typename: 'offer_autopilot_round_offer_variant',
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      round_index?: number | null,
      variant_index?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        approved_at?: string | null
      } | null,
      report_offer_autopilot_stats?: {
        __typename: 'report_offer_autopilot_stats',
        offer_id?: number | null,
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        views?: string | null,
        accepts?: number | null,
        declines?: number | null,
        bypasses?: number | null,
        avg_mrr?: string | null,
        saved_mrr?: string | null,
        lost_mrr?: string | null
      } | null
    }>,
    offer_autopilot_round_logs: Array<{
      __typename: 'offer_autopilot_round_log',
      data: any
    }>
  } | null,
  report_offer_autopilot_stats?: {
    __typename: 'report_offer_autopilot_stats',
    offer_id?: number | null,
    offer_autopilot_round_id?: number | null,
    offer_variant_id?: number | null,
    views?: string | null,
    accepts?: number | null,
    declines?: number | null,
    bypasses?: number | null,
    avg_mrr?: string | null,
    saved_mrr?: string | null,
    lost_mrr?: string | null
  } | null
};

export type OfferDetailsFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  override_active_offer: boolean,
  show_banner: boolean,
  type: offer_type_enum,
  goal: offer_goal_enum,
  style: offer_style_enum,
  image_url?: string | null,
  metadata: any,
  confirmation_enabled: boolean,
  created_at: string,
  archived_at?: string | null,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  confirmation_content_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  confirmation_confirm_button_text_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  confirmation_cancel_button_text_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  offer_coupon?: {
    __typename: 'offer_coupon',
    id: number,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null,
    months?: number | null,
    apply_to: offer_coupon_apply_to_enum,
    override_active_coupon: boolean,
    end_trial: boolean,
    platform_id?: string | null,
    platform_coupon?: {
      __typename: 'platform_coupon',
      code?: string | null
    } | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum,
    reset_billing_date: boolean,
    offer_coupon_id?: number | null,
    offer_coupon_offer?: {
      __typename: 'offer',
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null,
        platform_id?: string | null
      } | null
    } | null
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    modify_at: offer_modify_subscription_modify_at_enum,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_connection_id: number,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null,
      custom_plan_id?: string | null
    }>
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    pause_at: offer_pause_subscription_pause_at_enum,
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null,
      metadata: any,
      text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    }>
  } | null,
  offer_upgrade?: {
    __typename: 'offer_upgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum,
    platform_plan?: {
      __typename: 'platform_plan',
      name: string,
      platform_id: string,
      platform_plan_group?: {
        __typename: 'platform_plan_group',
        name: string,
        platform_id: string,
        code?: string | null
      } | null
    } | null
  } | null,
  offer_downgrade?: {
    __typename: 'offer_downgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum,
    platform_plan?: {
      __typename: 'platform_plan',
      name: string,
      platform_id: string,
      platform_plan_group?: {
        __typename: 'platform_plan_group',
        name: string,
        platform_id: string,
        code?: string | null
      } | null
    } | null
  } | null,
  offer_product_swap?: {
    __typename: 'offer_product_swap',
    specific_products: boolean,
    offer_product_swap_platform_ecommerce_products: Array<{
      __typename: 'offer_product_swap_platform_ecommerce_product',
      platform_ecommerce_product_id: string,
      platform_ecommerce_product?: {
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        active: boolean,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      } | null,
      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
        platform_variant?: {
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        } | null
      }>
    }>
  } | null,
  offer_reschedule_order?: {
    __typename: 'offer_reschedule_order',
    skip: boolean,
    reschedule: boolean,
    skip_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    reschedule_button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  account: {
    __typename: 'account',
    platform_connection?: {
      __typename: 'platform_connection',
      platform: platform_enum,
      import_completed_at?: string | null
    } | null
  },
  offer_timer?: {
    __typename: 'offer_timer',
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null,
  offer_tags: Array<{
    __typename: 'offer_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>,
  offer_autopilot?: {
    __typename: 'offer_autopilot',
    strategy: offer_autopilot_strategy_enum
  } | null
};

export type OfferGroupFragment = {
  __typename: 'offer_group',
  id: number,
  token: string,
  name: string,
  goal: offer_goal_enum,
  show_images: boolean,
  created_at: string,
  randomize: boolean,
  offer_group_offers: Array<{
    __typename: 'offer_group_offer',
    position?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      show_banner: boolean,
      image_url?: string | null,
      goal: offer_goal_enum,
      style: offer_style_enum,
      created_at: string,
      updated_at: string,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_coupon?: {
        __typename: 'offer_coupon',
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        }>
      } | null,
      offer_upgrade?: {
        __typename: 'offer_upgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_downgrade?: {
        __typename: 'offer_downgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_product_swap?: {
        __typename: 'offer_product_swap',
        specific_products: boolean,
        offer_product_swap_platform_ecommerce_products: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product',
          platform_ecommerce_product_id: string,
          platform_ecommerce_product?: {
            __typename: 'platform_ecommerce_product',
            platform_id: string,
            name: string,
            image_url?: string | null,
            platform_plan_ecommerce_products: Array<{
              __typename: 'platform_plan_ecommerce_product',
              discount_amount?: string | null,
              discount_type?: string | null
            }>
          } | null,
          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
            platform_variant?: {
              __typename: 'platform_variant',
              platform_id: string,
              name: string,
              price?: string | null,
              platform_variant_options: Array<{
                __typename: 'platform_variant_option',
                key: string,
                value: string
              }>
            } | null
          }>
        }>
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean,
        skip_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        reschedule_button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        offer_id: number,
        latest_round: Array<{
          __typename: 'offer_autopilot_offer_autopilot_round',
          offer_autopilot_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            round_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                created_at: string,
                approved_at?: string | null,
                approval_user_id?: string | null,
                data?: any,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null
        }>
      } | null
    } | null
  }>,
  headline_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  cancel_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  account?: {
    __typename: 'account',
    platform_connection?: {
      __typename: 'platform_connection',
      platform: platform_enum,
      import_completed_at?: string | null
    } | null
  } | null
};

export type OfferTestFragment = {
  __typename: 'offer_test',
  id: number,
  token: string,
  name: string,
  goal: offer_goal_enum,
  control_weight: number,
  created_at: string,
  winning_offer?: {
    __typename: 'offer',
    id: number,
    name: string,
    token: string,
    type: offer_type_enum
  } | null,
  control_offer: {
    __typename: 'offer',
    id: number,
    name: string,
    token: string,
    type: offer_type_enum
  },
  offer_test_offers: Array<{
    __typename: 'offer_test_offer',
    offer_test_id: number,
    offer_id: number,
    weight: number,
    offer: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    }
  }>,
  report_offer_test_stats: Array<{
    __typename: 'report_offer_test_stats',
    offer_id?: number | null,
    views?: string | null,
    accepts?: number | null,
    declines?: number | null
  }>
};

export type OfferTimerFragment = {
  __typename: 'offer_timer',
  offer_timer_mode: offer_timer_mode_enum,
  start_time?: string | null,
  end_time?: string | null,
  duration?: string | null,
  show_timer?: boolean | null
};

export type OfferVariantCouponFragment = {
  __typename: 'offer_variant_coupon',
  offer_variant_id: number,
  offer_id: number,
  type: offer_coupon_type_enum,
  amount_off: string,
  duration: coupon_duration_enum,
  months?: number | null,
  duration_interval?: coupon_duration_interval_enum | null,
  duration_count?: number | null
};

export type OfferVariantFragment = {
  __typename: 'offer_variant',
  id: number,
  type: offer_type_enum,
  offer_id: number,
  created_at: string,
  approved_at?: string | null,
  approval_user_id?: string | null,
  data?: any,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_variant_coupon?: {
    __typename: 'offer_variant_coupon',
    offer_variant_id: number,
    offer_id: number,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    months?: number | null,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null
  } | null
};

export type PrimarySegmentConditionGroupFragment = {
  __typename: 'segment_condition_group',
  id: number,
  boolean_operator: segment_condition_boolean_operator_enum,
  segment_condition_group_entries: Array<{
    __typename: 'segment_condition_group_entry',
    entry_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    entry_segment_condition?: {
      __typename: 'segment_condition',
      id: number,
      property: segment_condition_property_enum,
      operator: segment_condition_operator_enum,
      value: any,
      property_id?: number | null,
      custom_property?: {
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      } | null
    } | null
  }>
};

export type QuestionAnswerFragment = {
  __typename: 'question_answer',
  question: {
    __typename: 'question',
    id: number,
    type: question_type_enum
  },
  question_version: {
    __typename: 'question_version',
    id: number,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  },
  question_answer_radios: Array<{
    __typename: 'question_answer_radio',
    question_option_version: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }
  }>,
  question_answer_text?: {
    __typename: 'question_answer_text',
    value: string,
    sentiment?: sentiment_enum | null
  } | null,
  question_answer_likelihood_to_return?: {
    __typename: 'question_answer_likelihood_to_return',
    value: number
  } | null
};

export type ReportOfferAutopilotStatsFragment = {
  __typename: 'report_offer_autopilot_stats',
  offer_id?: number | null,
  offer_autopilot_round_id?: number | null,
  offer_variant_id?: number | null,
  views?: string | null,
  accepts?: number | null,
  declines?: number | null,
  bypasses?: number | null,
  avg_mrr?: string | null,
  saved_mrr?: string | null,
  lost_mrr?: string | null
};

export type SaveSubscriberLogFragment = {
  __typename: 'subscriber_log',
  id: number,
  occurred_at: string,
  data: any,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null
  },
  subscription?: {
    __typename: 'subscription',
    id: number,
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>
    }>
  } | null,
  subscriber_log_offer?: {
    __typename: 'subscriber_log_offer',
    offer: {
      __typename: 'offer',
      id: number,
      type: offer_type_enum,
      name: string
    }
  } | null
};

export type SegmentConditionCustomPropertyFragment = {
  __typename: 'property',
  id: number,
  name: string,
  entity: property_entity_enum,
  type: property_type_enum,
  format?: property_format_enum | null
};

export type SegmentConditionFragment = {
  __typename: 'segment_condition',
  id: number,
  property: segment_condition_property_enum,
  operator: segment_condition_operator_enum,
  value: any,
  property_id?: number | null,
  custom_property?: {
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null
  } | null
};

export type SegmentConditionGroupEntryFragment = {
  __typename: 'segment_condition_group_entry',
  entry_segment_condition_group?: {
    __typename: 'segment_condition_group',
    id: number,
    boolean_operator: segment_condition_boolean_operator_enum,
    segment_condition_group_entries: Array<{
      __typename: 'segment_condition_group_entry',
      entry_segment_condition?: {
        __typename: 'segment_condition',
        id: number,
        property: segment_condition_property_enum,
        operator: segment_condition_operator_enum,
        value: any,
        property_id?: number | null,
        custom_property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>
  } | null,
  entry_segment_condition?: {
    __typename: 'segment_condition',
    id: number,
    property: segment_condition_property_enum,
    operator: segment_condition_operator_enum,
    value: any,
    property_id?: number | null,
    custom_property?: {
      __typename: 'property',
      id: number,
      name: string,
      entity: property_entity_enum,
      type: property_type_enum,
      format?: property_format_enum | null
    } | null
  } | null
};

export type SessionStepFragment = {
  __typename: 'subscriber_flow_flow_step',
  started_at: string,
  completed_at?: string | null,
  flow_step: {
    __typename: 'flow_step',
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  },
  subscriber_flow_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        type: question_type_enum
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        }
      }>,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  subscriber_flow_offers: Array<{
    __typename: 'subscriber_flow_offer',
    subscriber_flow_id: number,
    offer_id: number,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    },
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null,
    offer_autopilot_offer_variant?: {
      __typename: 'offer_autopilot_offer_variant',
      variant_index?: number | null
    } | null
  }>,
  subscriber_flow_offer_groups: Array<{
    __typename: 'subscriber_flow_offer_group',
    subscriber_flow_id: number,
    offer_group_id: number,
    offer_id?: number | null,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer_group: {
      __typename: 'offer_group',
      id: number,
      name: string,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum,
          name: string,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null
        } | null
      }>
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    } | null,
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null
  }>,
  subscriber_flow_deflections: Array<{
    __typename: 'subscriber_flow_deflection',
    flow_step_id: number,
    deflection: {
      __typename: 'deflection',
      id: number,
      token: string,
      title: string,
      tag_ids: any,
      created_at: string,
      archived_at?: string | null,
      minimum_items?: number | null,
      heading_translation_id: number,
      content_translation_id: number,
      deflection_tags: Array<{
        __typename: 'deflection_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>,
      heading_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      deflection_snapshot_items: Array<{
        __typename: 'deflection_snapshot_item_position',
        position?: number | null,
        deflection_snapshot_item?: {
          __typename: 'deflection_snapshot_item',
          id: number,
          token: string,
          icon_prefix?: string | null,
          icon_name?: string | null,
          property_id?: number | null,
          property_condition_value?: any | null,
          date_format?: string | null,
          text_translation_id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        position?: number | null,
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    },
    clicked_flow_action?: {
      __typename: 'flow_action',
      id: number,
      token: string,
      code?: string | null,
      type: flow_action_type_enum,
      account_id: number,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow_id?: number | null,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type SessionSubscriberFlowFragment = {
  __typename: 'subscriber_flow',
  id: number,
  token: string,
  status: subscriber_flow_status_enum,
  automatically_deflected: boolean,
  user_agent?: string | null,
  payload?: any | null,
  segment_values?: any | null,
  created_at: string,
  updated_at: string,
  started_at?: string | null,
  completed_at?: string | null,
  flow: {
    __typename: 'flow',
    id: number,
    token: string,
    title: string
  },
  offer?: {
    __typename: 'offer',
    id: number,
    name: string
  } | null,
  subscriber_flow_offers: Array<{
    __typename: 'subscriber_flow_offer',
    subscriber_flow_id: number,
    offer_id: number,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    },
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null,
    offer_autopilot_offer_variant?: {
      __typename: 'offer_autopilot_offer_variant',
      variant_index?: number | null
    } | null
  }>,
  subscriber_flow_offer_groups: Array<{
    __typename: 'subscriber_flow_offer_group',
    subscriber_flow_id: number,
    offer_group_id: number,
    offer_id?: number | null,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer_group: {
      __typename: 'offer_group',
      id: number,
      name: string,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum,
          name: string,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null
        } | null
      }>
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    } | null,
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null
  }>,
  subscriber_flow_deflections: Array<{
    __typename: 'subscriber_flow_deflection',
    subscriber_flow_id: number,
    deflection_id: number,
    flow_step_id: number,
    clicked_flow_action?: {
      __typename: 'flow_action',
      id: number,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    deflection: {
      __typename: 'deflection',
      id: number,
      title: string
    }
  }>,
  subscription: {
    __typename: 'subscription',
    id: number,
    status: subscription_status_enum,
    mrr: string,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      trial: boolean
    }>,
    platform_subscription?: {
      __typename: 'platform_subscription',
      platform_id: string,
      code?: string | null
    } | null
  },
  subscriber: {
    __typename: 'subscriber',
    id: number,
    name: string,
    email: string,
    token: string,
    platform_id: string,
    deleted_at?: string | null
  },
  primary_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        type: question_type_enum
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        }
      }>,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  text_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        type: question_type_enum
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        }
      }>,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  subscriber_flow_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        type: question_type_enum
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        }
      }>,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  subscriber_flow_overrides: Array<{
    __typename: 'subscriber_flow_override',
    reason: subscriber_flow_override_reason_enum,
    offer: {
      __typename: 'offer',
      id: number,
      name: string
    },
    offer_group?: {
      __typename: 'offer_group',
      id: number,
      name: string
    } | null
  }>,
  subscriber_flow_flow_steps: Array<{
    __typename: 'subscriber_flow_flow_step',
    flow_step_id: number
  }>,
  flow_steps_count: {
    __typename: 'subscriber_flow_flow_step_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_flow_step_aggregate_fields',
      count: number
    } | null
  }
};

export type SubscriberGridFragment = {
  __typename: 'subscriber_grid',
  id?: number | null,
  status?: string | null,
  last_seen_at?: string | null,
  last_subscriber_flow_seen_at?: string | null,
  last_subscriber_campaign_view_seen_at?: string | null,
  last_subscriber_likelihood_to_return?: number | null,
  subscriber?: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    platform_id: string,
    status?: subscriber_status_enum | null,
    active_mrr: string,
    inactive_mrr: string,
    created_at: string,
    deleted_at?: string | null,
    subscriber_created_at?: {
      __typename: 'subscriber_created_at',
      created_at?: string | null
    } | null,
    platform_customer?: {
      __typename: 'platform_customer',
      platform_id: string,
      platform_created_at?: string | null
    } | null
  } | null,
  last_subscriber_flow?: {
    __typename: 'subscriber_flow',
    status: subscriber_flow_status_enum,
    flow: {
      __typename: 'flow',
      title: string
    }
  } | null,
  last_subscriber_campaign_view?: {
    __typename: 'subscriber_campaign_view',
    status: subscriber_campaign_view_status_enum,
    subscriber_campaign: {
      __typename: 'subscriber_campaign',
      campaign: {
        __typename: 'campaign',
        title: string
      }
    }
  } | null
};

export type TranslationFragment = {
  __typename: 'translation',
  id: number,
  translation_values: Array<{
    __typename: 'translation_value',
    translation_id: number,
    language: language_enum,
    value: any,
    format?: translation_value_format_enum | null
  }>
};

export type TranslationValueFragment = {
  __typename: 'translation_value',
  translation_id: number,
  language: language_enum,
  value: any,
  format?: translation_value_format_enum | null
};

export type ViewerFragment = {
  __typename: 'ViewerOutput',
  id: string,
  defaultRole: string,
  allowedRoles: Array<string>,
  firstName: string,
  lastName: string,
  email: string,
  account?: {
    __typename: 'account',
    id: number,
    token: string,
    title: string,
    mode: account_mode_enum,
    test_mode_for_account_id?: number | null,
    default_flow_id?: number | null,
    default_campaign_id?: number | null,
    internal_testing: boolean,
    customer_portal_enabled: boolean,
    logo_url?: string | null,
    favicon_url?: string | null,
    created_at: string,
    currency: platform_base_currency_enum,
    revenue_mode: account_revenue_mode_enum,
    sso_required: boolean,
    require_payment_method: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum,
      platform_id: string,
      import_started_at?: string | null,
      import_completed_at?: string | null,
      meta: any,
      publishable_key?: string | null,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        store: string,
        client_id?: string | null,
        install_link?: string | null
      } | null
    } | null
  } | null
};

export type SimpleUserAccountsQueryVariables = Exact<{
  accountIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SimpleUserAccountsQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string
  }>
};

export type SimpleUserUserQueryVariables = Exact<{ [key: string]: never; }>;


export type SimpleUserUserQuery = {
  viewer?: {
    __typename: 'ViewerOutput',
    firstName: string,
    lastName: string,
    email: string
  } | null
};

export type TransparentLoginHeaderTransparentLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type TransparentLoginHeaderTransparentLogoutMutation = {
  transparentLogout: {
    __typename: 'TransparentLogoutOutput',
    success: boolean
  }
};

export type UpdateAccountSettingByPkMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  key: account_setting_key_enum;
  value: Scalars['jsonb']['input'];
}>;


export type UpdateAccountSettingByPkMutation = {
  insert_account_setting_one?: {
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  } | null
};

export type UseUpdateTagsMutationVariables = Exact<{
  objects: Array<tag_insert_input> | tag_insert_input;
}>;


export type UseUpdateTagsMutation = {
  insert_tag?: {
    __typename: 'tag_mutation_response',
    affected_rows: number
  } | null
};

export type UseUpdateTagsQueryVariables = Exact<{
  tags: Array<Scalars['citext']['input']> | Scalars['citext']['input'];
}>;


export type UseUpdateTagsQuery = {
  tag: Array<{
    __typename: 'tag',
    id: number,
    name: string
  }>
};

export type UseUpdatePaymentMethodMutationVariables = Exact<{
  input: AttachPaymentMethodInput;
}>;


export type UseUpdatePaymentMethodMutation = {
  attachPaymentMethod: {
    __typename: 'AttachPaymentMethodOutput',
    success: boolean
  }
};

export type UseConnectPlatformQueryVariables = Exact<{ [key: string]: never; }>;


export type UseConnectPlatformQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    import_completed_at?: string | null,
    ready: boolean,
    show_error: boolean,
    platform_connection_shopify_store?: {
      __typename: 'platform_connection_shopify_store',
      connected: boolean
    } | null
  }>
};

export type UseConnectPlatformSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UseConnectPlatformSubscription = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    import_completed_at?: string | null,
    ready: boolean,
    show_error: boolean,
    platform_connection_shopify_store?: {
      __typename: 'platform_connection_shopify_store',
      connected: boolean
    } | null
  }>
};

export type ConnectPlatformConnectStripeApiKeyMutationVariables = Exact<{
  input: ConnectStripeApiKeyInput;
}>;


export type ConnectPlatformConnectStripeApiKeyMutation = {
  connectStripeApiKey: {
    __typename: 'ConnectStripeApiKeyOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectChargebeeMutationVariables = Exact<{
  input: ConnectChargebeeInput;
}>;


export type ConnectPlatformConnectChargebeeMutation = {
  connectChargebee: {
    __typename: 'ConnectChargebeeOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectChargifyMutationVariables = Exact<{
  input: ConnectMaxioInput;
}>;


export type ConnectPlatformConnectChargifyMutation = {
  connectMaxio: {
    __typename: 'ConnectMaxioOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectRecurlyMutationVariables = Exact<{
  input: ConnectRecurlyInput;
}>;


export type ConnectPlatformConnectRecurlyMutation = {
  connectRecurly: {
    __typename: 'ConnectRecurlyOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectPaddleMutationVariables = Exact<{
  input: ConnectPaddleInput;
}>;


export type ConnectPlatformConnectPaddleMutation = {
  connectPaddle: {
    __typename: 'ConnectPaddleOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectBraintreeMutationVariables = Exact<{
  input: ConnectBraintreeInput;
}>;


export type ConnectPlatformConnectBraintreeMutation = {
  connectBraintree: {
    __typename: 'ConnectBraintreeOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectZuoraMutationVariables = Exact<{
  input: ConnectZuoraInput;
}>;


export type ConnectPlatformConnectZuoraMutation = {
  connectZuora: {
    __typename: 'ConnectZuoraOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectRechargeMutationVariables = Exact<{
  input: ConnectRechargeInput;
}>;


export type ConnectPlatformConnectRechargeMutation = {
  connectRecharge: {
    __typename: 'ConnectRechargeOutput',
    success: boolean,
    error?: string | null
  }
};

export type ConnectPlatformConnectOrdergrooveMutationVariables = Exact<{
  input: ConnectOrdergrooveInput;
}>;


export type ConnectPlatformConnectOrdergrooveMutation = {
  connectOrdergroove: {
    __typename: 'ConnectOrdergrooveOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectNavigaMutationVariables = Exact<{
  input: ConnectNavigaInput;
}>;


export type ConnectPlatformConnectNavigaMutation = {
  connectNaviga: {
    __typename: 'ConnectNavigaOutput',
    success: boolean
  }
};

export type ConnectPlatformConnectOpenPayMutationVariables = Exact<{
  input: ConnectOpenPayInput;
}>;


export type ConnectPlatformConnectOpenPayMutation = {
  connectOpenPay: {
    __typename: 'ConnectOpenPayOutput',
    success: boolean
  }
};

export type ConnectPlatformSkipConnectMutationVariables = Exact<{ [key: string]: never; }>;


export type ConnectPlatformSkipConnectMutation = {
  skipConnect?: {
    __typename: 'SkipConnectOutput',
    success: boolean
  } | null
};

export type AccountSettingByPkQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  key: account_setting_key_enum;
}>;


export type AccountSettingByPkQuery = {
  account_setting_by_pk?: {
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  } | null
};

export type CancellationsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  trial?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CancellationsQuery = {
  subscriber_log: Array<{
    __typename: 'subscriber_log',
    id: number,
    occurred_at: string,
    data: any,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      inactive_mrr: string,
      reactivation_logs: Array<{
        __typename: 'subscriber_log',
        occurred_at: string,
        data: any
      }>
    },
    subscription?: {
      __typename: 'subscription',
      id: number
    } | null
  }>
};

export type ExistingDeflectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExistingDeflectionsQuery = {
  deflection: Array<{
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>
};

export type ExistingOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type ExistingOffersQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>
};

export type FlowOfferRulesQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FlowOfferRulesQuery = {
  account_feature: Array<{
    __typename: 'account_feature',
    key: account_feature_key_enum,
    value: any
  }>,
  flow: Array<{
    __typename: 'flow',
    id: number,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              offer_rule_group_id: number
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              offer_rule_group_id: number
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null
          } | null
        } | null
      }>
    } | null
  }>,
  offer_rule_group: Array<{
    __typename: 'offer_rule_group',
    id: number,
    title: string,
    published_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null
  }>,
  deflection_rule_group: Array<{
    __typename: 'offer_rule_group',
    id: number,
    title: string,
    published_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null
  }>,
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    name: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        name: string
      }
    }>
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null
  }>,
  inlineSegments: Array<{
    __typename: 'segment',
    id: number,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum,
    entity: property_entity_enum,
    token: string,
    name: string,
    created_at: string
  }>,
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum
  }>,
  offer_test: Array<{
    __typename: 'offer_test',
    id: number,
    name: string,
    token: string,
    goal: offer_goal_enum,
    control_weight: number,
    control_offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    },
    offer_test_offers: Array<{
      __typename: 'offer_test_offer',
      offer_test_id: number,
      offer_id: number,
      weight: number,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      }
    }>,
    winning_offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_group: Array<{
    __typename: 'offer_group',
    id: number,
    name: string,
    token: string,
    show_images: boolean,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  }>,
  offer_autopilot: Array<{
    __typename: 'offer_autopilot',
    offer_id: number,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum
    } | null,
    current_round?: {
      __typename: 'offer_autopilot_round',
      id: number,
      offer_id: number,
      control_weight: number,
      control_variant_id: number,
      winning_variant_id?: number | null,
      offer_autopilot_round_offer_variants: Array<{
        __typename: 'offer_autopilot_round_offer_variant',
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        offer_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null
      }>
    } | null,
    winning_variant?: {
      __typename: 'offer_variant',
      id: number,
      type: offer_type_enum,
      offer_id: number,
      approved_at?: string | null,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_variant_coupon?: {
        __typename: 'offer_variant_coupon',
        offer_variant_id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null
      } | null
    } | null,
    offer_autopilot_offer_autopilot_rounds: Array<{
      __typename: 'offer_autopilot_offer_autopilot_round',
      offer_autopilot_round_id?: number | null,
      round_index?: number | null,
      offer_autopilot_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        offer_id: number,
        control_weight: number,
        control_variant_id: number,
        winning_variant_id?: number | null,
        offer_autopilot_round_offer_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null
        }>
      } | null
    }>
  }>,
  deflection: Array<{
    __typename: 'deflection',
    id: number,
    title: string,
    token: string,
    minimum_items?: number | null,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>
};

export type HeaderUserQueryVariables = Exact<{
  accountIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type HeaderUserQuery = {
  viewer?: {
    __typename: 'ViewerOutput',
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    account?: {
      __typename: 'account',
      id: number,
      title: string
    } | null
  } | null,
  account: Array<{
    __typename: 'account',
    id: number,
    title: string
  }>
};

export type SetupDismissedQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupDismissedQuery = {
  account_user_setting: Array<{
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  }>
};

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = {
  viewer?: {
    __typename: 'ViewerOutput',
    id: string,
    defaultRole: string,
    allowedRoles: Array<string>,
    firstName: string,
    lastName: string,
    email: string,
    account?: {
      __typename: 'account',
      id: number,
      token: string,
      title: string,
      mode: account_mode_enum,
      test_mode_for_account_id?: number | null,
      default_flow_id?: number | null,
      default_campaign_id?: number | null,
      internal_testing: boolean,
      customer_portal_enabled: boolean,
      logo_url?: string | null,
      favicon_url?: string | null,
      created_at: string,
      currency: platform_base_currency_enum,
      revenue_mode: account_revenue_mode_enum,
      sso_required: boolean,
      require_payment_method: boolean,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number,
        platform: platform_enum,
        platform_id: string,
        import_started_at?: string | null,
        import_completed_at?: string | null,
        meta: any,
        publishable_key?: string | null,
        platform_connection_shopify_store?: {
          __typename: 'platform_connection_shopify_store',
          store: string,
          client_id?: string | null,
          install_link?: string | null
        } | null
      } | null
    } | null
  } | null
};

export type RuleDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  title: string,
  deflection_flow_actions: Array<{
    __typename: 'deflection_flow_action',
    flow_action?: {
      __typename: 'flow_action',
      reroute_to_flow_id?: number | null
    } | null
  }>
};

export type RuleFlowFragment = {
  __typename: 'flow',
  id: number,
  title: string
};

export type RuleFlowTestFragment = {
  __typename: 'flow_test',
  id: number,
  name: string
};

export type RuleInlineSegmentFragment = {
  __typename: 'segment',
  id: number,
  primary_segment_condition_group?: {
    __typename: 'segment_condition_group',
    id: number,
    boolean_operator: segment_condition_boolean_operator_enum,
    segment_condition_group_entries: Array<{
      __typename: 'segment_condition_group_entry',
      entry_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null,
      entry_segment_condition?: {
        __typename: 'segment_condition',
        id: number,
        property: segment_condition_property_enum,
        operator: segment_condition_operator_enum,
        value: any,
        property_id?: number | null,
        custom_property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>
  } | null
};

export type RuleOfferFragment = {
  __typename: 'offer',
  id: number,
  name: string,
  type: offer_type_enum
};

export type RuleOfferGroupFragment = {
  __typename: 'offer_group',
  id: number,
  name: string
};

export type RuleOfferRuleFragment = {
  __typename: 'offer_rule',
  id: number,
  published_version?: {
    __typename: 'offer_rule_version',
    id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        weight: number,
        offer_ids: any,
        offer_group_ids: any,
        offer_test_ids: any,
        include_present_no_offer: boolean,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer?: {
            __typename: 'offer',
            id: number,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_rule_offer_groups: Array<{
          __typename: 'offer_rule_rule_offer_group',
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string
          } | null
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_version',
    id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        weight: number,
        offer_ids: any,
        offer_group_ids: any,
        offer_test_ids: any,
        include_present_no_offer: boolean,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer?: {
            __typename: 'offer',
            id: number,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_rule_offer_groups: Array<{
          __typename: 'offer_rule_rule_offer_group',
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string
          } | null
        }>
      } | null
    }>
  } | null
};

export type RuleOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              weight: number,
              offer_ids: any,
              offer_group_ids: any,
              offer_test_ids: any,
              include_present_no_offer: boolean,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer?: {
                  __typename: 'offer',
                  id: number,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              weight: number,
              offer_ids: any,
              offer_group_ids: any,
              offer_test_ids: any,
              include_present_no_offer: boolean,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer?: {
                  __typename: 'offer',
                  id: number,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              weight: number,
              offer_ids: any,
              offer_group_ids: any,
              offer_test_ids: any,
              include_present_no_offer: boolean,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer?: {
                  __typename: 'offer',
                  id: number,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              weight: number,
              offer_ids: any,
              offer_group_ids: any,
              offer_test_ids: any,
              include_present_no_offer: boolean,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer?: {
                  __typename: 'offer',
                  id: number,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type RuleOfferRuleGroupVersionFragment = {
  __typename: 'offer_rule_group_version',
  id: number,
  offer_rule_group_version_offer_rules: Array<{
    __typename: 'offer_rule_group_version_offer_rule',
    offer_rule?: {
      __typename: 'offer_rule',
      id: number,
      published_version?: {
        __typename: 'offer_rule_version',
        id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            weight: number,
            offer_ids: any,
            offer_group_ids: any,
            offer_test_ids: any,
            include_present_no_offer: boolean,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer?: {
                __typename: 'offer',
                id: number,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_version',
        id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            weight: number,
            offer_ids: any,
            offer_group_ids: any,
            offer_test_ids: any,
            include_present_no_offer: boolean,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer?: {
                __typename: 'offer',
                id: number,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string
              } | null
            }>
          } | null
        }>
      } | null
    } | null
  }>
};

export type RuleOfferRuleRuleFragment = {
  __typename: 'offer_rule_rule',
  id: number,
  weight: number,
  offer_ids: any,
  offer_group_ids: any,
  offer_test_ids: any,
  include_present_no_offer: boolean,
  offer_rule_rule_offers: Array<{
    __typename: 'offer_rule_rule_offer',
    offer?: {
      __typename: 'offer',
      id: number,
      name: string,
      type: offer_type_enum
    } | null
  }>,
  offer_rule_rule_offer_tests: Array<{
    __typename: 'offer_rule_rule_offer_test',
    offer_test?: {
      __typename: 'offer_test',
      id: number,
      name: string
    } | null
  }>,
  offer_rule_rule_offer_groups: Array<{
    __typename: 'offer_rule_rule_offer_group',
    offer_group?: {
      __typename: 'offer_group',
      id: number,
      name: string
    } | null
  }>
};

export type RuleOfferRuleVersionFragment = {
  __typename: 'offer_rule_version',
  id: number,
  segment_group_ids: any,
  segment_ids: any,
  question_option_ids: any,
  offer_rule_version_offer_rule_rules: Array<{
    __typename: 'offer_rule_version_offer_rule_rule',
    offer_rule_rule?: {
      __typename: 'offer_rule_rule',
      id: number,
      weight: number,
      offer_ids: any,
      offer_group_ids: any,
      offer_test_ids: any,
      include_present_no_offer: boolean,
      offer_rule_rule_offers: Array<{
        __typename: 'offer_rule_rule_offer',
        offer?: {
          __typename: 'offer',
          id: number,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_rule_offer_tests: Array<{
        __typename: 'offer_rule_rule_offer_test',
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_rule_offer_groups: Array<{
        __typename: 'offer_rule_rule_offer_group',
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string
        } | null
      }>
    } | null
  }>
};

export type RuleOfferTestFragment = {
  __typename: 'offer_test',
  id: number,
  name: string
};

export type RulePropertyFragment = {
  __typename: 'property',
  id: number,
  key: string,
  type: property_type_enum,
  entity: property_entity_enum,
  token: string,
  name: string,
  created_at: string
};

export type RuleQuestionFragment = {
  __typename: 'question',
  id: number,
  published_version?: {
    __typename: 'question_version',
    id: number,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option?: {
        __typename: 'question_option',
        id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'question_version',
    id: number,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option?: {
        __typename: 'question_option',
        id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  question_settings: Array<{
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum,
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null,
    draft_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  }>
};

export type RuleQuestionOptionFragment = {
  __typename: 'question_option',
  id: number,
  published_version?: {
    __typename: 'question_option_version',
    id: number,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  draft_version?: {
    __typename: 'question_option_version',
    id: number,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type RuleQuestionOptionVersionFragment = {
  __typename: 'question_option_version',
  id: number,
  title_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type RuleQuestionSettingFragment = {
  __typename: 'question_setting',
  question_id: number,
  key: question_setting_key_enum,
  published_version?: {
    __typename: 'question_setting_version',
    id: number,
    question_id: number,
    key: question_setting_key_enum,
    value: any
  } | null,
  draft_version?: {
    __typename: 'question_setting_version',
    id: number,
    question_id: number,
    key: question_setting_key_enum,
    value: any
  } | null
};

export type RuleQuestionSettingVersionFragment = {
  __typename: 'question_setting_version',
  id: number,
  question_id: number,
  key: question_setting_key_enum,
  value: any
};

export type RuleQuestionVersionFragment = {
  __typename: 'question_version',
  id: number,
  title_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  question_version_question_options: Array<{
    __typename: 'question_version_question_option',
    question_option?: {
      __typename: 'question_option',
      id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>
};

export type RuleSegmentFragment = {
  __typename: 'segment',
  id: number,
  name: string,
  integration?: {
    __typename: 'integration',
    type: integration_type_enum
  } | null
};

export type RuleSegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  name: string
};

export type UseInsertInlineSegmentsMutationVariables = Exact<{
  objects: Array<segment_insert_input> | segment_insert_input;
}>;


export type UseInsertInlineSegmentsMutation = {
  insert_segment?: {
    __typename: 'segment_mutation_response',
    returning: Array<{
      __typename: 'segment',
      id: number,
      primary_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null
    }>
  } | null
};

export type UseCanDeleteRuleItemOfferRuleRuleFragment = {
  __typename: 'offer_rule_rule',
  offer_ids: any,
  offer_autopilot_offer_ids: any,
  deflection_ids: any,
  offer_rule_rule_offer_tests: Array<{
    __typename: 'offer_rule_rule_offer_test',
    offer_test?: {
      __typename: 'offer_test',
      control_offer_id: number,
      offer_test_offers: Array<{
        __typename: 'offer_test_offer',
        offer_test_id: number,
        offer_id: number
      }>
    } | null
  }>
};

export type UseCanDeleteRuleItemOfferRuleFragment = {
  __typename: 'offer_rule',
  id: number,
  published_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        offer_ids: any,
        offer_autopilot_offer_ids: any,
        deflection_ids: any,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_test?: {
            __typename: 'offer_test',
            control_offer_id: number,
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number
            }>
          } | null
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        offer_ids: any,
        offer_autopilot_offer_ids: any,
        deflection_ids: any,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_test?: {
            __typename: 'offer_test',
            control_offer_id: number,
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number
            }>
          } | null
        }>
      } | null
    }>
  } | null
};

export type UseCanDeleteRuleItemOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              offer_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  control_offer_id: number,
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              offer_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  control_offer_id: number,
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              offer_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  control_offer_id: number,
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              offer_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_test?: {
                  __typename: 'offer_test',
                  control_offer_id: number,
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type UseCanDeleteRuleItemFlowStepFragment = {
  __typename: 'flow_step',
  id: number,
  flow_step_offer_rule_group?: {
    __typename: 'flow_step_offer_rule_group',
    flow_step_id?: number | null,
    offer_rule_group_id?: number | null,
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      id: number,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    } | null
  } | null,
  flow_step_intervention?: {
    __typename: 'flow_step_intervention',
    flow_step_id: number,
    intervention_id: number,
    intervention: {
      __typename: 'intervention',
      id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      }
    }
  } | null,
  flow_step_deflection_rule_group?: {
    __typename: 'flow_step_deflection_rule_group',
    flow_step_id?: number | null,
    offer_rule_group_id?: number | null,
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      id: number,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  offer_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_test?: {
                      __typename: 'offer_test',
                      control_offer_id: number,
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    } | null
  } | null
};

export type UseCanDeleteRuleItemQueryVariables = Exact<{ [key: string]: never; }>;


export type UseCanDeleteRuleItemQuery = {
  flow: Array<{
    __typename: 'flow',
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              }
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_ids: any,
                            offer_autopilot_offer_ids: any,
                            deflection_ids: any,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                control_offer_id: number,
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              }
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_ids: any,
                          offer_autopilot_offer_ids: any,
                          deflection_ids: any,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              control_offer_id: number,
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>,
  offer_change_plan: Array<{
    __typename: 'offer_change_plan',
    offer: {
      __typename: 'offer',
      archived_at?: string | null
    },
    offer_coupon_offer?: {
      __typename: 'offer',
      id: number
    } | null
  }>,
  incomplete_offer_test: Array<{
    __typename: 'offer_test',
    control_offer_id: number,
    offer_test_offers: Array<{
      __typename: 'offer_test_offer',
      offer_test_id: number,
      offer_id: number
    }>
  }>,
  campaign: Array<{
    __typename: 'campaign',
    published_version?: {
      __typename: 'campaign_version',
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_test?: {
                        __typename: 'offer_test',
                        control_offer_id: number,
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      }
    } | null
  }>
};

export type SubscriberSegmentPropertyValuesFragment = {
  __typename: 'subscriber',
  account_id: number,
  name: string,
  email: string,
  status?: subscriber_status_enum | null,
  most_recent_subscriber_flow?: {
    __typename: 'subscriber_most_recent_subscriber_flow',
    subscriber_flow?: {
      __typename: 'subscriber_flow',
      url?: string | null,
      subscriber_flow_url?: {
        __typename: 'subscriber_flow_url',
        domain?: string | null
      } | null
    } | null
  } | null,
  most_recent_completed_subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    created_at: string,
    completed_at?: string | null
  }>,
  offer?: {
    __typename: 'offer',
    token: string
  } | null,
  platform_customer?: {
    __typename: 'platform_customer',
    country?: string | null,
    state?: string | null,
    billing_country?: string | null,
    billing_state?: string | null,
    platform_coupon_id?: string | null,
    shipping_country?: string | null,
    shipping_state?: string | null,
    tags?: any | null,
    platform_subscriptions: Array<{
      __typename: 'platform_subscription',
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        platform_plan_id: string,
        sku?: string | null,
        platform_variant?: {
          __typename: 'platform_variant',
          platform_id: string,
          sku?: string | null
        } | null
      }>,
      platform_invoices: Array<{
        __typename: 'platform_invoice',
        status: platform_invoice_status_enum,
        amount_paid: string
      }>
    }>
  } | null,
  subscriber_arr?: {
    __typename: 'subscriber_arr',
    arr?: string | null
  } | null,
  subscriber_days_since_saved?: {
    __typename: 'subscriber_days_since_saved',
    days_since_saved?: number | null
  } | null,
  subscriber_mrr?: {
    __typename: 'subscriber_mrr',
    mrr?: string | null
  } | null,
  subscriber_properties: Array<{
    __typename: 'subscriber_property',
    property_id: number,
    value: any
  }>
};

export type SubscriptionSegmentPropertyValuesFragment = {
  __typename: 'subscription',
  mrr: string,
  status: subscription_status_enum,
  most_recent_subscriber_flow?: {
    __typename: 'subscription_most_recent_subscriber_flow',
    subscriber_flow?: {
      __typename: 'subscriber_flow',
      url?: string | null,
      subscriber_flow_url?: {
        __typename: 'subscriber_flow_url',
        domain?: string | null
      } | null
    } | null
  } | null,
  most_recent_completed_subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    created_at: string,
    completed_at?: string | null
  }>,
  platform_subscription?: {
    __typename: 'platform_subscription',
    billing_country?: string | null,
    billing_state?: string | null,
    cancel_at_period_end?: boolean | null,
    next_billing_at?: string | null,
    past_due?: boolean | null,
    platform_coupon_id?: string | null,
    shipping_country?: string | null,
    shipping_state?: string | null,
    trial_end?: string | null,
    trial_start?: string | null,
    platform_coupon?: {
      __typename: 'platform_coupon',
      type: platform_coupon_type_enum,
      amount_off: string
    } | null,
    platform_invoices: Array<{
      __typename: 'platform_invoice',
      status: platform_invoice_status_enum,
      amount_paid: string
    }>,
    platform_newspaper_subscription?: {
      __typename: 'platform_newspaper_subscription',
      current_balance?: string | null,
      digital_frequency?: any | null,
      is_digital?: boolean | null,
      is_ez_pay?: boolean | null,
      is_print?: boolean | null,
      last_payment_amount?: string | null,
      paper_name?: string | null,
      paper_code?: string | null,
      print_frequency?: any | null,
      rate_code?: string | null,
      start_source?: string | null,
      stop_date?: string | null
    } | null,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      platform_addon_id: string,
      platform_addon?: {
        __typename: 'platform_addon',
        name: string
      } | null
    }>,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      platform_plan_id: string,
      quantity: string,
      sku?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        code?: string | null,
        interval: platform_plan_interval_enum,
        interval_count: number,
        name: string,
        platform_product_id: string,
        platform_product?: {
          __typename: 'platform_product',
          name: string
        } | null
      } | null,
      platform_variant?: {
        __typename: 'platform_variant',
        platform_id: string,
        sku?: string | null
      } | null
    }>
  } | null,
  subscriber: {
    __typename: 'subscriber',
    account_id: number,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    most_recent_subscriber_flow?: {
      __typename: 'subscriber_most_recent_subscriber_flow',
      subscriber_flow?: {
        __typename: 'subscriber_flow',
        url?: string | null,
        subscriber_flow_url?: {
          __typename: 'subscriber_flow_url',
          domain?: string | null
        } | null
      } | null
    } | null,
    most_recent_completed_subscriber_flow: Array<{
      __typename: 'subscriber_flow',
      created_at: string,
      completed_at?: string | null
    }>,
    offer?: {
      __typename: 'offer',
      token: string
    } | null,
    platform_customer?: {
      __typename: 'platform_customer',
      country?: string | null,
      state?: string | null,
      billing_country?: string | null,
      billing_state?: string | null,
      platform_coupon_id?: string | null,
      shipping_country?: string | null,
      shipping_state?: string | null,
      tags?: any | null,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string,
          sku?: string | null,
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            sku?: string | null
          } | null
        }>,
        platform_invoices: Array<{
          __typename: 'platform_invoice',
          status: platform_invoice_status_enum,
          amount_paid: string
        }>
      }>
    } | null,
    subscriber_arr?: {
      __typename: 'subscriber_arr',
      arr?: string | null
    } | null,
    subscriber_days_since_saved?: {
      __typename: 'subscriber_days_since_saved',
      days_since_saved?: number | null
    } | null,
    subscriber_mrr?: {
      __typename: 'subscriber_mrr',
      mrr?: string | null
    } | null,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      property_id: number,
      value: any
    }>
  },
  subscription_arr?: {
    __typename: 'subscription_arr',
    arr?: string | null
  } | null,
  subscription_duration?: {
    __typename: 'subscription_duration',
    days?: number | null
  } | null,
  subscription_ltv?: {
    __typename: 'subscription_ltv',
    ltv?: string | null
  } | null,
  subscription_properties: Array<{
    __typename: 'subscription_property',
    property_id: number,
    value: any
  }>
};

export type GetSubscriberSegmentPropertyValuesQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type GetSubscriberSegmentPropertyValuesQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    account_id: number,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    most_recent_subscriber_flow?: {
      __typename: 'subscriber_most_recent_subscriber_flow',
      subscriber_flow?: {
        __typename: 'subscriber_flow',
        url?: string | null,
        subscriber_flow_url?: {
          __typename: 'subscriber_flow_url',
          domain?: string | null
        } | null
      } | null
    } | null,
    most_recent_completed_subscriber_flow: Array<{
      __typename: 'subscriber_flow',
      created_at: string,
      completed_at?: string | null
    }>,
    offer?: {
      __typename: 'offer',
      token: string
    } | null,
    platform_customer?: {
      __typename: 'platform_customer',
      country?: string | null,
      state?: string | null,
      billing_country?: string | null,
      billing_state?: string | null,
      platform_coupon_id?: string | null,
      shipping_country?: string | null,
      shipping_state?: string | null,
      tags?: any | null,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string,
          sku?: string | null,
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            sku?: string | null
          } | null
        }>,
        platform_invoices: Array<{
          __typename: 'platform_invoice',
          status: platform_invoice_status_enum,
          amount_paid: string
        }>
      }>
    } | null,
    subscriber_arr?: {
      __typename: 'subscriber_arr',
      arr?: string | null
    } | null,
    subscriber_days_since_saved?: {
      __typename: 'subscriber_days_since_saved',
      days_since_saved?: number | null
    } | null,
    subscriber_mrr?: {
      __typename: 'subscriber_mrr',
      mrr?: string | null
    } | null,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      property_id: number,
      value: any
    }>
  } | null
};

export type GetSubscriptionSegmentPropertyValuesQueryVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
}>;


export type GetSubscriptionSegmentPropertyValuesQuery = {
  subscription_by_pk?: {
    __typename: 'subscription',
    mrr: string,
    status: subscription_status_enum,
    most_recent_subscriber_flow?: {
      __typename: 'subscription_most_recent_subscriber_flow',
      subscriber_flow?: {
        __typename: 'subscriber_flow',
        url?: string | null,
        subscriber_flow_url?: {
          __typename: 'subscriber_flow_url',
          domain?: string | null
        } | null
      } | null
    } | null,
    most_recent_completed_subscriber_flow: Array<{
      __typename: 'subscriber_flow',
      created_at: string,
      completed_at?: string | null
    }>,
    platform_subscription?: {
      __typename: 'platform_subscription',
      billing_country?: string | null,
      billing_state?: string | null,
      cancel_at_period_end?: boolean | null,
      next_billing_at?: string | null,
      past_due?: boolean | null,
      platform_coupon_id?: string | null,
      shipping_country?: string | null,
      shipping_state?: string | null,
      trial_end?: string | null,
      trial_start?: string | null,
      platform_coupon?: {
        __typename: 'platform_coupon',
        type: platform_coupon_type_enum,
        amount_off: string
      } | null,
      platform_invoices: Array<{
        __typename: 'platform_invoice',
        status: platform_invoice_status_enum,
        amount_paid: string
      }>,
      platform_newspaper_subscription?: {
        __typename: 'platform_newspaper_subscription',
        current_balance?: string | null,
        digital_frequency?: any | null,
        is_digital?: boolean | null,
        is_ez_pay?: boolean | null,
        is_print?: boolean | null,
        last_payment_amount?: string | null,
        paper_name?: string | null,
        paper_code?: string | null,
        print_frequency?: any | null,
        rate_code?: string | null,
        start_source?: string | null,
        stop_date?: string | null
      } | null,
      platform_subscription_addons: Array<{
        __typename: 'platform_subscription_addon',
        platform_addon_id: string,
        platform_addon?: {
          __typename: 'platform_addon',
          name: string
        } | null
      }>,
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        platform_plan_id: string,
        quantity: string,
        sku?: string | null,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        platform_plan?: {
          __typename: 'platform_plan',
          code?: string | null,
          interval: platform_plan_interval_enum,
          interval_count: number,
          name: string,
          platform_product_id: string,
          platform_product?: {
            __typename: 'platform_product',
            name: string
          } | null
        } | null,
        platform_variant?: {
          __typename: 'platform_variant',
          platform_id: string,
          sku?: string | null
        } | null
      }>
    } | null,
    subscriber: {
      __typename: 'subscriber',
      account_id: number,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      most_recent_subscriber_flow?: {
        __typename: 'subscriber_most_recent_subscriber_flow',
        subscriber_flow?: {
          __typename: 'subscriber_flow',
          url?: string | null,
          subscriber_flow_url?: {
            __typename: 'subscriber_flow_url',
            domain?: string | null
          } | null
        } | null
      } | null,
      most_recent_completed_subscriber_flow: Array<{
        __typename: 'subscriber_flow',
        created_at: string,
        completed_at?: string | null
      }>,
      offer?: {
        __typename: 'offer',
        token: string
      } | null,
      platform_customer?: {
        __typename: 'platform_customer',
        country?: string | null,
        state?: string | null,
        billing_country?: string | null,
        billing_state?: string | null,
        platform_coupon_id?: string | null,
        shipping_country?: string | null,
        shipping_state?: string | null,
        tags?: any | null,
        platform_subscriptions: Array<{
          __typename: 'platform_subscription',
          platform_subscription_plans: Array<{
            __typename: 'platform_subscription_plan',
            platform_plan_id: string,
            sku?: string | null,
            platform_variant?: {
              __typename: 'platform_variant',
              platform_id: string,
              sku?: string | null
            } | null
          }>,
          platform_invoices: Array<{
            __typename: 'platform_invoice',
            status: platform_invoice_status_enum,
            amount_paid: string
          }>
        }>
      } | null,
      subscriber_arr?: {
        __typename: 'subscriber_arr',
        arr?: string | null
      } | null,
      subscriber_days_since_saved?: {
        __typename: 'subscriber_days_since_saved',
        days_since_saved?: number | null
      } | null,
      subscriber_mrr?: {
        __typename: 'subscriber_mrr',
        mrr?: string | null
      } | null,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        property_id: number,
        value: any
      }>
    },
    subscription_arr?: {
      __typename: 'subscription_arr',
      arr?: string | null
    } | null,
    subscription_duration?: {
      __typename: 'subscription_duration',
      days?: number | null
    } | null,
    subscription_ltv?: {
      __typename: 'subscription_ltv',
      ltv?: string | null
    } | null,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      property_id: number,
      value: any
    }>
  } | null
};

export type SetupAccountFragment = {
  __typename: 'account',
  id: number,
  show_tour: boolean,
  platform_connection?: {
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    import_started_at?: string | null,
    import_completed_at?: string | null,
    ready: boolean,
    show_error: boolean,
    meta: any,
    platform_connection_shopify_store?: {
      __typename: 'platform_connection_shopify_store',
      connected: boolean
    } | null
  } | null,
  account_settings: Array<{
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  }>,
  offers: Array<{
    __typename: 'offer',
    id: number
  }>
};

export type SetupQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SetupQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    show_tour: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum,
      import_started_at?: string | null,
      import_completed_at?: string | null,
      ready: boolean,
      show_error: boolean,
      meta: any,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        connected: boolean
      } | null
    } | null,
    account_settings: Array<{
      __typename: 'account_setting',
      account_id: number,
      key: account_setting_key_enum,
      value: any
    }>,
    offers: Array<{
      __typename: 'offer',
      id: number
    }>
  } | null
};

export type SetupSubscriptionVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SetupSubscription = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    show_tour: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum,
      import_started_at?: string | null,
      import_completed_at?: string | null,
      ready: boolean,
      show_error: boolean,
      meta: any,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        connected: boolean
      } | null
    } | null,
    account_settings: Array<{
      __typename: 'account_setting',
      account_id: number,
      key: account_setting_key_enum,
      value: any
    }>,
    offers: Array<{
      __typename: 'offer',
      id: number
    }>
  } | null
};

export type CancellationsSubscriptionVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  trial?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CancellationsSubscription = {
  subscriber_log: Array<{
    __typename: 'subscriber_log',
    id: number,
    occurred_at: string,
    data: any,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      inactive_mrr: string,
      reactivation_logs: Array<{
        __typename: 'subscriber_log',
        occurred_at: string,
        data: any
      }>
    },
    subscription?: {
      __typename: 'subscription',
      id: number
    } | null
  }>
};

export type UseTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseTagsQuery = {
  tag: Array<{
    __typename: 'tag',
    id: number,
    name: string
  }>
};

export type UseAccountFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseAccountFeaturesQuery = {
  account_feature: Array<{
    __typename: 'account_feature',
    key: account_feature_key_enum,
    value: any
  }>
};

export type UseConnectStripeMutationVariables = Exact<{
  input: ConnectStripeInput;
}>;


export type UseConnectStripeMutation = {
  connectStripe?: {
    __typename: 'ConnectStripeOutput',
    success: boolean
  } | null
};

export type UseFlowPrimaryFormQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  includeDrafts: Scalars['Boolean']['input'];
}>;


export type UseFlowPrimaryFormQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    id: number,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_form?: {
            __typename: 'flow_step_form',
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_form?: {
            __typename: 'flow_step_form',
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type UseHasMultipleFlowsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseHasMultipleFlowsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string
  }>
};

export type UsePublishedPrimaryQuestionOptionsQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type UsePublishedPrimaryQuestionOptionsQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    id: number,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              published_version?: {
                __typename: 'form_version',
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          token: string,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    token: string,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type UseSwitchAccountMutationVariables = Exact<{
  input: SwitchAccountInput;
}>;


export type UseSwitchAccountMutation = {
  switchAccount: {
    __typename: 'SwitchAccountOutput',
    success: boolean
  }
};

export type UseSwitchTestModeMutationVariables = Exact<{
  input: SwitchTestModeInput;
}>;


export type UseSwitchTestModeMutation = {
  switchTestMode: {
    __typename: 'SwitchTestModeOutput',
    success: boolean
  }
};

export type UseTrackEventMutationVariables = Exact<{
  input: TrackEventInput;
}>;


export type UseTrackEventMutation = {
  trackEvent: {
    __typename: 'TrackEventOutput',
    success: boolean
  }
};

export type AcceptInviteQueryVariables = Exact<{
  input: UserInvitationByTokenInput;
}>;


export type AcceptInviteQuery = {
  userInvitationByToken: {
    __typename: 'UserInvitationByTokenOutput',
    valid: boolean,
    expired: boolean,
    accountId: number,
    accountTitle: string,
    invitedByName: string,
    invitedByEmail: string
  }
};

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptUserInvitationInput;
}>;


export type AcceptInviteMutation = {
  acceptUserInvitation: {
    __typename: 'AcceptUserInvitationOutput',
    success: boolean
  }
};

export type AccountTransparentLoginMutationVariables = Exact<{
  input: TransparentLoginInput;
}>;


export type AccountTransparentLoginMutation = {
  transparentLogin: {
    __typename: 'TransparentLoginOutput',
    success: boolean
  }
};

export type AccountDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type AccountDetailsQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string,
    created_at: string,
    owner_user_id?: string | null,
    internal_testing: boolean,
    stripe_customer_id: string,
    require_payment_method: boolean,
    account_features: Array<{
      __typename: 'account_feature',
      key: account_feature_key_enum,
      value: any
    }>,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        store: string,
        connected: boolean
      } | null
    } | null,
    subscribers_aggregate: {
      __typename: 'subscriber_aggregate',
      aggregate?: {
        __typename: 'subscriber_aggregate_fields',
        count: number,
        sum?: {
          __typename: 'subscriber_sum_fields',
          inactive_mrr?: string | null,
          active_mrr?: string | null
        } | null
      } | null
    },
    offers_aggregate: {
      __typename: 'offer_aggregate',
      aggregate?: {
        __typename: 'offer_aggregate_fields',
        count: number
      } | null
    },
    segments_aggregate: {
      __typename: 'segment_aggregate',
      aggregate?: {
        __typename: 'segment_aggregate_fields',
        count: number
      } | null
    }
  } | null,
  revenue_log_aggregate: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  }
};

export type AccountUpdateInternalTestingMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  internalTesting: Scalars['Boolean']['input'];
}>;


export type AccountUpdateInternalTestingMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    internal_testing: boolean
  } | null
};

export type AccountUpdateRequirePaymentMethodMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  requirePaymentMethod: Scalars['Boolean']['input'];
}>;


export type AccountUpdateRequirePaymentMethodMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    require_payment_method: boolean
  } | null
};

export type AccountUsersCardQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type AccountUsersCardQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string,
    owner_user_id?: string | null,
    account_users: Array<{
      __typename: 'account_user',
      user: {
        __typename: 'user',
        id: string,
        name?: string | null,
        email?: string | null,
        picture?: string | null,
        provider?: string | null,
        activated_at?: string | null,
        user_profiles: Array<{
          __typename: 'user_profile',
          first_name: string,
          last_name: string,
          email: string
        }>,
        owned_accounts: Array<{
          __typename: 'account',
          id: number
        }>
      }
    }>
  } | null
};

export type AccountUpdateOwnerMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  ownerUserId: Scalars['String']['input'];
}>;


export type AccountUpdateOwnerMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    owner_user_id?: string | null
  } | null
};

export type AccountsQueryVariables = Exact<{
  where: account_bool_exp;
  orderBy: Array<account_order_by> | account_order_by;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type AccountsQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string,
    created_at: string
  }>,
  account_aggregate: {
    __typename: 'account_aggregate',
    aggregate?: {
      __typename: 'account_aggregate_fields',
      count: number
    } | null
  }
};

export type AccountsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where: account_bool_exp;
  orderBy: Array<account_order_by> | account_order_by;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type AccountsSearchQuery = {
  search_accounts: Array<{
    __typename: 'account',
    id: number,
    title: string,
    created_at: string
  }>,
  search_accounts_aggregate: {
    __typename: 'account_aggregate',
    aggregate?: {
      __typename: 'account_aggregate_fields',
      count: number
    } | null
  }
};

export type ConfigureShopifyModalMutationVariables = Exact<{
  input: AdminConfigureShopifyInput;
}>;


export type ConfigureShopifyModalMutation = {
  adminConfigureShopify: {
    __typename: 'AdminConfigureShopifyOutput',
    success: boolean
  }
};

export type CreateAccountModalMutationVariables = Exact<{
  input: AdminCreateAccountInput;
}>;


export type CreateAccountModalMutation = {
  adminCreateAccount: {
    __typename: 'AdminCreateAccountOutput',
    accountId?: number | null
  }
};

export type EditFeaturesModalMutationVariables = Exact<{
  input: AdminUpdateAccountFeaturesInput;
}>;


export type EditFeaturesModalMutation = {
  adminUpdateAccountFeatures: {
    __typename: 'AdminUpdateAccountFeaturesOutput',
    success: boolean
  }
};

export type EditSettingsModalMutationVariables = Exact<{
  input: AdminUpdateAccountSettingsInput;
}>;


export type EditSettingsModalMutation = {
  adminUpdateAccountSettings: {
    __typename: 'AdminUpdateAccountSettingsOutput',
    success: boolean
  }
};

export type FeaturesCardQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type FeaturesCardQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    account_features: Array<{
      __typename: 'account_feature',
      key: account_feature_key_enum,
      value: any
    }>
  } | null
};

export type SettingsCardQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SettingsCardQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    account_settings: Array<{
      __typename: 'account_setting',
      account_id: number,
      key: account_setting_key_enum,
      value: any
    }>
  } | null
};

export type CreateDemoModalMutationVariables = Exact<{
  object: demo_insert_input;
}>;


export type CreateDemoModalMutation = {
  insert_demo_one?: {
    __typename: 'demo',
    id: number
  } | null
};

export type CreateDemoModalFlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CreateDemoModalFlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    account: {
      __typename: 'account',
      id: number,
      token: string
    }
  }>
};

export type DemosQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type DemosQuery = {
  demo: Array<{
    __typename: 'demo',
    id: number,
    token: string,
    company_name: string
  }>,
  demo_aggregate: {
    __typename: 'demo_aggregate',
    aggregate?: {
      __typename: 'demo_aggregate_fields',
      count: number
    } | null
  }
};

export type CallToActionQueryVariables = Exact<{
  sevenMonthsAgo: Scalars['String']['input'];
  thirteenMonthsAgo: Scalars['String']['input'];
}>;


export type CallToActionQuery = {
  subscription_revenue_by_month: Array<{
    __typename: 'subscription_revenue_by_month',
    month?: string | null,
    revenue?: string | null
  }>,
  customers_by_month: Array<{
    __typename: 'customers_by_month',
    month?: string | null,
    count?: string | null
  }>,
  cancellations_by_month: Array<{
    __typename: 'cancellations_by_month',
    month?: string | null,
    reason?: string | null,
    count?: string | null
  }>
};

export type ChurnBreakdownCardQueryVariables = Exact<{
  sevenMonthsAgo: Scalars['String']['input'];
}>;


export type ChurnBreakdownCardQuery = {
  subscription_revenue_by_month: Array<{
    __typename: 'subscription_revenue_by_month',
    month?: string | null,
    revenue?: string | null
  }>,
  lost_mrr_by_month: Array<{
    __typename: 'lost_mrr_by_month',
    month?: string | null,
    reason?: string | null,
    lost_mrr?: string | null
  }>,
  customers_by_month: Array<{
    __typename: 'customers_by_month',
    month?: string | null,
    count?: string | null
  }>,
  cancellations_by_month: Array<{
    __typename: 'cancellations_by_month',
    month?: string | null,
    reason?: string | null,
    count?: string | null
  }>
};

export type ChurnOverviewCardQueryVariables = Exact<{
  lastMonth: Scalars['String']['input'];
  thisMonth: Scalars['String']['input'];
}>;


export type ChurnOverviewCardQuery = {
  lastMonthRevenue: Array<{
    __typename: 'subscription_revenue_by_month',
    revenue?: string | null
  }>,
  thisMonthVoluntaryChurn: Array<{
    __typename: 'lost_mrr_by_month',
    lost_mrr?: string | null
  }>,
  thisMonthInvoluntaryChurn: Array<{
    __typename: 'lost_mrr_by_month',
    lost_mrr?: string | null
  }>
};

export type ChurnOverviewChartQueryVariables = Exact<{
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;


export type ChurnOverviewChartQuery = {
  subscription_revenue_by_month: Array<{
    __typename: 'subscription_revenue_by_month',
    month?: string | null,
    revenue?: string | null
  }>,
  lost_mrr_by_month: Array<{
    __typename: 'lost_mrr_by_month',
    month?: string | null,
    reason?: string | null,
    lost_mrr?: string | null
  }>
};

export type CampaignHomeSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignHomeSettingsQuery = {
  account_setting: Array<{
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  }>
};

export type CampaignHomeDismissSnippetMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CampaignHomeDismissSnippetMutation = {
  insert_account_setting_one?: {
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  } | null
};

export type CampaignPanelQueryVariables = Exact<{
  offerWhere: offer_bool_exp;
}>;


export type CampaignPanelQuery = {
  campaign_aggregate: {
    __typename: 'campaign_aggregate',
    aggregate?: {
      __typename: 'campaign_aggregate_fields',
      count: number
    } | null
  },
  offer_rule: Array<{
    __typename: 'offer_rule',
    id: number,
    published_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>
    } | null
  }>,
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    token: string,
    name: string,
    created_at: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment_group_id: number,
      segment_id: number,
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        token: string,
        name: string,
        integration_id?: number | null,
        integration_segment_id?: string | null,
        created_at: string,
        primary_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition_group?: {
              __typename: 'segment_condition_group',
              id: number,
              boolean_operator: segment_condition_boolean_operator_enum,
              segment_condition_group_entries: Array<{
                __typename: 'segment_condition_group_entry',
                entry_segment_condition?: {
                  __typename: 'segment_condition',
                  id: number,
                  property: segment_condition_property_enum,
                  operator: segment_condition_operator_enum,
                  value: any,
                  property_id?: number | null,
                  custom_property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>
            } | null,
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        integration?: {
          __typename: 'integration',
          id: number,
          type: integration_type_enum
        } | null,
        segment_tags: Array<{
          __typename: 'segment_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>
      }
    }>
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  inlineSegments: Array<{
    __typename: 'segment',
    id: number,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null
  }>,
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum,
    entity: property_entity_enum,
    token: string,
    name: string,
    created_at: string
  }>
};

export type CampaignPanelOfferRuleGroupVersionMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
}>;


export type CampaignPanelOfferRuleGroupVersionMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type CampaignPanelOfferRuleGroupUpdateMutationVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type CampaignPanelOfferRuleGroupUpdateMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number,
    published_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CampaignPanelPublishOfferRuleMutationVariables = Exact<{
  offerRuleId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type CampaignPanelPublishOfferRuleMutation = {
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number,
    published_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CampaignPanelCreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;


export type CampaignPanelCreateCampaignMutation = {
  createCampaign: {
    __typename: 'CreateCampaignOutput',
    campaign?: {
      __typename: 'campaign',
      id: number,
      token: string,
      title: string,
      deleted_at?: string | null,
      published_version?: {
        __typename: 'campaign_version',
        id: number,
        trigger_type: campaign_trigger_type_enum,
        trigger_url?: string | null,
        delay?: string | null,
        collect_payment: boolean,
        redirect_url?: string | null,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      } | null
    } | null
  }
};

export type CampaignPanelDeleteMutationVariables = Exact<{
  campaignId: Scalars['Int']['input'];
}>;


export type CampaignPanelDeleteMutation = {
  update_campaign_by_pk?: {
    __typename: 'campaign',
    id: number
  } | null
};

export type CampaignPanelOfferRuleGroupQueryVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
}>;


export type CampaignPanelOfferRuleGroupQuery = {
  offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number,
    published_version_id?: number | null,
    draft_version_id?: number | null
  } | null
};

export type ConversionCampaignRoutesCampaignsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  where: campaign_bool_exp;
  orderBy?: InputMaybe<Array<campaign_order_by> | campaign_order_by>;
}>;


export type ConversionCampaignRoutesCampaignsQuery = {
  campaign: Array<{
    __typename: 'campaign',
    id: number,
    token: string,
    title: string,
    deleted_at?: string | null,
    subscriber_campaigns: Array<{
      __typename: 'subscriber_campaign',
      views: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          count: number
        } | null
      }
    }>,
    subscribers: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    conversions: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    published_version?: {
      __typename: 'campaign_version',
      id: number,
      trigger_type: campaign_trigger_type_enum,
      trigger_url?: string | null,
      delay?: string | null,
      collect_payment: boolean,
      redirect_url?: string | null,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      }
    } | null
  }>,
  campaign_aggregate: {
    __typename: 'campaign_aggregate',
    aggregate?: {
      __typename: 'campaign_aggregate_fields',
      count: number
    } | null
  }
};

export type ConversionCampaignRoutesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversionCampaignRoutesQuery = {
  campaign_aggregate: {
    __typename: 'campaign_aggregate',
    aggregate?: {
      __typename: 'campaign_aggregate_fields',
      count: number
    } | null
  }
};

export type ConversionOfferArchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ConversionOfferArchiveMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type ConversionOfferUnarchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ConversionOfferUnarchiveMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type ConversionOfferDeleteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type ConversionOfferDeleteMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type ConversionOfferQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ConversionOfferQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    override_active_offer: boolean,
    show_banner: boolean,
    type: offer_type_enum,
    goal: offer_goal_enum,
    style: offer_style_enum,
    image_url?: string | null,
    metadata: any,
    confirmation_enabled: boolean,
    created_at: string,
    archived_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    confirmation_content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_confirm_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_cancel_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum,
      override_active_coupon: boolean,
      end_trial: boolean,
      platform_id?: string | null,
      platform_coupon?: {
        __typename: 'platform_coupon',
        code?: string | null
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_id?: number | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null,
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          active: boolean,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    account: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    },
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      strategy: offer_autopilot_strategy_enum
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type ConversionOfferChangePlanQueryVariables = Exact<{
  platformPlanId: Scalars['String']['input'];
}>;


export type ConversionOfferChangePlanQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    name: string
  }>
};

export type ConversionOffersGridFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum,
  created_at: string,
  accepted: {
    __typename: 'subscriber_campaign_aggregate',
    aggregate?: {
      __typename: 'subscriber_campaign_aggregate_fields',
      count: number
    } | null
  },
  total: {
    __typename: 'subscriber_campaign_aggregate',
    aggregate?: {
      __typename: 'subscriber_campaign_aggregate_fields',
      count: number
    } | null
  },
  offer_tags: Array<{
    __typename: 'offer_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type ConversionOffersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  where: offer_bool_exp;
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type ConversionOffersQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type ConversionOffersSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<offer_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type ConversionOffersSearchQuery = {
  search_offers: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_campaign_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  search_offers_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type ConversionOffersCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversionOffersCampaignQuery = {
  campaign: Array<{
    __typename: 'campaign',
    id: number,
    title: string,
    published_version?: {
      __typename: 'campaign_version',
      offer_rule_group: {
        __typename: 'offer_rule_group',
        published_version?: {
          __typename: 'offer_rule_group_version',
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              published_version?: {
                __typename: 'offer_rule_version',
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      }
    } | null
  }>
};

export type ConversionOffersHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversionOffersHomeQuery = {
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type ConnectPaymentPlatformQueryVariables = Exact<{
  accountIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type ConnectPaymentPlatformQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string
  }>
};

export type ConnectingSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ConnectingSubscription = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    import_completed_at?: string | null
  }>
};

export type CreateAccountQueryVariables = Exact<{
  accountIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type CreateAccountQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string
  }>
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = {
  createAccount?: {
    __typename: 'CreateAccountOutput',
    success: boolean,
    error?: string | null
  } | null
};

export type DashboardSubscriberFlowCountQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type DashboardSubscriberFlowCountQuery = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type DashboardNeedStripeReconnectQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardNeedStripeReconnectQuery = {
  needStripeReconnect: {
    __typename: 'NeedStripeReconnectOutput',
    needReconnect: boolean
  }
};

export type DashboardDismissSetupMutationVariables = Exact<{ [key: string]: never; }>;


export type DashboardDismissSetupMutation = {
  insert_account_user_setting_one?: {
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  } | null
};

export type AcceptRateCardQueryVariables = Exact<{
  timeZone: Scalars['String']['input'];
}>;


export type AcceptRateCardQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string
  }>,
  accept_rate: {
    __typename: 'template_report_offer_accept_rate_aggregate',
    aggregate?: {
      __typename: 'template_report_offer_accept_rate_aggregate_fields',
      sum?: {
        __typename: 'template_report_offer_accept_rate_sum_fields',
        presented?: number | null,
        accepted?: number | null,
        declined?: number | null
      } | null
    } | null
  },
  group_accept_rate: {
    __typename: 'template_report_offer_group_accept_rate_aggregate',
    aggregate?: {
      __typename: 'template_report_offer_group_accept_rate_aggregate_fields',
      sum?: {
        __typename: 'template_report_offer_group_accept_rate_sum_fields',
        presented?: number | null,
        accepted?: number | null,
        declined?: number | null
      } | null
    } | null
  },
  offer_rate: {
    __typename: 'template_report_offer_rate_aggregate',
    aggregate?: {
      __typename: 'template_report_offer_rate_aggregate_fields',
      avg?: {
        __typename: 'template_report_offer_rate_avg_fields',
        offer_rate?: number | null
      } | null
    } | null
  }
};

export type AcceptRateChartQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type AcceptRateChartQuery = {
  report_offer_resolution_by_question_option: Array<{
    __typename: 'report_offer_resolution_by_question_option',
    question_option_id?: number | null,
    resolution?: string | null,
    count?: string | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      token: string,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>
};

export type OfferResolutionCardQueryVariables = Exact<{
  timeZone: Scalars['String']['input'];
}>;


export type OfferResolutionCardQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number
  }>,
  report_offer_resolution_aggregate: {
    __typename: 'template_report_offer_resolution_aggregate',
    aggregate?: {
      __typename: 'template_report_offer_resolution_aggregate_fields',
      sum?: {
        __typename: 'template_report_offer_resolution_sum_fields',
        accepted?: number | null,
        declined?: number | null,
        no_offer?: number | null
      } | null
    } | null
  }
};

export type OfferResolutionChartQueryVariables = Exact<{
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type OfferResolutionChartQuery = {
  report_offer_resolution: Array<{
    __typename: 'template_report_offer_resolution',
    date: string,
    accepted: number,
    declined: number,
    no_offer: number
  }>
};

export type PrimaryReasonsCardQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type PrimaryReasonsCardQuery = {
  question_option: Array<{
    __typename: 'question_option',
    id: number,
    question_answer_radios_aggregate: {
      __typename: 'question_answer_radio_aggregate',
      aggregate?: {
        __typename: 'question_answer_radio_aggregate_fields',
        count: number
      } | null
    },
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type PrimaryReasonsMrrBarChartQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type PrimaryReasonsMrrBarChartQuery = {
  report: Array<{
    __typename: 'template_report_question_answer_radio_inactive_mrr',
    question_option_id: number,
    x: string,
    y: string,
    question_option: {
      __typename: 'question_option',
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  }>
};

export type PrimaryReasonsMrrPieChartQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type PrimaryReasonsMrrPieChartQuery = {
  report_inactive_mrr_by_question_option: Array<{
    __typename: 'report_inactive_mrr_by_question_option',
    inactive_mrr?: string | null,
    question_option?: {
      __typename: 'question_option',
      id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    } | null
  }>
};

export type PrimaryReasonsSessionsBarChartQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type PrimaryReasonsSessionsBarChartQuery = {
  report: Array<{
    __typename: 'template_report_question_answer_radio',
    question_id: number,
    question_option_id: number,
    x: string,
    y: number,
    question_option: {
      __typename: 'question_option',
      id: number,
      published_version?: {
        __typename: 'question_option_version',
        id: number,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  }>
};

export type PrimaryReasonsSessionsPieChartQueryVariables = Exact<{
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type PrimaryReasonsSessionsPieChartQuery = {
  question_option: Array<{
    __typename: 'question_option',
    id: number,
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    question_answer_radios_aggregate: {
      __typename: 'question_answer_radio_aggregate',
      aggregate?: {
        __typename: 'question_answer_radio_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type RetainedRevenueCardQueryVariables = Exact<{ [key: string]: never; }>;


export type RetainedRevenueCardQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number
  }>,
  saved_mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  },
  deflected_mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  }
};

export type SavedMrrRevenueCardQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedMrrRevenueCardQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number
  }>,
  saved_mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  },
  deflected_mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  },
  saved_revenue: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  },
  deflected_revenue: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  }
};

export type SavedMrrRevenueChartQueryVariables = Exact<{
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type SavedMrrRevenueChartQuery = {
  report_retained_revenue: Array<{
    __typename: 'template_report_retained_revenue',
    date: string,
    saved: number,
    deflected: number
  }>
};

export type RetentionCardQueryVariables = Exact<{
  timeZone: Scalars['String']['input'];
}>;


export type RetentionCardQuery = {
  report_retention_trends_aggregate: {
    __typename: 'template_report_retention_trends_aggregate',
    aggregate?: {
      __typename: 'template_report_retention_trends_aggregate_fields',
      sum?: {
        __typename: 'template_report_retention_trends_sum_fields',
        canceled?: number | null,
        deflected?: number | null,
        saved?: number | null
      } | null
    } | null
  },
  flow: Array<{
    __typename: 'flow',
    id: number
  }>
};

export type RetentionChartQueryVariables = Exact<{
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type RetentionChartQuery = {
  report_retention_trends: Array<{
    __typename: 'template_report_retention_trends',
    date: string,
    canceled: number,
    saved: number,
    deflected: number
  }>
};

export type SessionsCardQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionsCardQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number
  }>,
  saved: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  },
  canceled: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  },
  deflected: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  },
  incomplete: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type SessionsChartQueryVariables = Exact<{
  timeZone: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;


export type SessionsChartQuery = {
  report: Array<{
    __typename: 'template_report_subscriber_flow_status',
    canceled: number,
    saved: number,
    incomplete: number,
    deflected: number,
    x: string
  }>
};

export type DeflectionArchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeflectionArchiveMutation = {
  update_deflection_by_pk?: {
    __typename: 'deflection',
    id: number
  } | null
};

export type DeflectionUnarchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeflectionUnarchiveMutation = {
  update_deflection_by_pk?: {
    __typename: 'deflection',
    id: number
  } | null
};

export type DeflectionDeleteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeflectionDeleteMutation = {
  update_deflection_by_pk?: {
    __typename: 'deflection',
    id: number
  } | null
};

export type DeflectionQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type DeflectionQuery = {
  deflection: Array<{
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>
};

export type DeflectionPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeflectionPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type DeflectionPanelCreateDeflectionMutationVariables = Exact<{
  input: CreateDeflectionInput;
}>;


export type DeflectionPanelCreateDeflectionMutation = {
  createDeflection: {
    __typename: 'CreateDeflectionOutput',
    deflection?: {
      __typename: 'deflection',
      id: number,
      token: string,
      title: string,
      tag_ids: any,
      created_at: string,
      archived_at?: string | null,
      minimum_items?: number | null,
      heading_translation_id: number,
      content_translation_id: number,
      deflection_tags: Array<{
        __typename: 'deflection_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>,
      heading_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      deflection_snapshot_items: Array<{
        __typename: 'deflection_snapshot_item_position',
        position?: number | null,
        deflection_snapshot_item?: {
          __typename: 'deflection_snapshot_item',
          id: number,
          token: string,
          icon_prefix?: string | null,
          icon_name?: string | null,
          property_id?: number | null,
          property_condition_value?: any | null,
          date_format?: string | null,
          text_translation_id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        position?: number | null,
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    } | null
  }
};

export type DeflectionPanelUpdateDeflectionMutationVariables = Exact<{
  input: UpdateDeflectionInput;
}>;


export type DeflectionPanelUpdateDeflectionMutation = {
  updateDeflection: {
    __typename: 'UpdateDeflectionOutput',
    deflection?: {
      __typename: 'deflection',
      id: number,
      token: string,
      title: string,
      tag_ids: any,
      created_at: string,
      archived_at?: string | null,
      minimum_items?: number | null,
      heading_translation_id: number,
      content_translation_id: number,
      deflection_tags: Array<{
        __typename: 'deflection_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>,
      heading_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      deflection_snapshot_items: Array<{
        __typename: 'deflection_snapshot_item_position',
        position?: number | null,
        deflection_snapshot_item?: {
          __typename: 'deflection_snapshot_item',
          id: number,
          token: string,
          icon_prefix?: string | null,
          icon_name?: string | null,
          property_id?: number | null,
          property_condition_value?: any | null,
          date_format?: string | null,
          text_translation_id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        position?: number | null,
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    } | null
  }
};

export type DeflectionPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type DeflectionPanelQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    default_language: language_enum,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    account: {
      __typename: 'account',
      id: number
    }
  }>
};

export type DeflectionPanelPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeflectionPanelPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type DeflectionsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  where: deflection_bool_exp;
  orderBy?: InputMaybe<Array<deflection_order_by> | deflection_order_by>;
}>;


export type DeflectionsQuery = {
  deflection: Array<{
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflected: {
      __typename: 'subscriber_flow_deflection_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_deflection_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_deflection_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_deflection_aggregate_fields',
        count: number
      } | null
    },
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>,
  deflection_aggregate: {
    __typename: 'deflection_aggregate',
    aggregate?: {
      __typename: 'deflection_aggregate_fields',
      count: number
    } | null
  },
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              published_version?: {
                __typename: 'offer_rule_group_version',
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    published_version?: {
                      __typename: 'offer_rule_version',
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          deflection_ids: any
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type DeflectionsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<deflection_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<deflection_order_by> | deflection_order_by>;
}>;


export type DeflectionsSearchQuery = {
  search_deflections: Array<{
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflected: {
      __typename: 'subscriber_flow_deflection_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_deflection_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_deflection_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_deflection_aggregate_fields',
        count: number
      } | null
    },
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>,
  search_deflections_aggregate: {
    __typename: 'deflection_aggregate',
    aggregate?: {
      __typename: 'deflection_aggregate_fields',
      count: number
    } | null
  },
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              published_version?: {
                __typename: 'offer_rule_group_version',
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    published_version?: {
                      __typename: 'offer_rule_version',
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          deflection_ids: any
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type DeflectionsHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type DeflectionsHomeQuery = {
  deflection_aggregate: {
    __typename: 'deflection_aggregate',
    aggregate?: {
      __typename: 'deflection_aggregate_fields',
      count: number
    } | null
  }
};

export type DeflectionsGridDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  token: string,
  title: string,
  tag_ids: any,
  created_at: string,
  archived_at?: string | null,
  minimum_items?: number | null,
  heading_translation_id: number,
  content_translation_id: number,
  deflected: {
    __typename: 'subscriber_flow_deflection_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_deflection_aggregate_fields',
      count: number
    } | null
  },
  total: {
    __typename: 'subscriber_flow_deflection_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_deflection_aggregate_fields',
      count: number
    } | null
  },
  deflection_tags: Array<{
    __typename: 'deflection_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>,
  heading_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation?: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  } | null,
  deflection_snapshot_items: Array<{
    __typename: 'deflection_snapshot_item_position',
    position?: number | null,
    deflection_snapshot_item?: {
      __typename: 'deflection_snapshot_item',
      id: number,
      token: string,
      icon_prefix?: string | null,
      icon_name?: string | null,
      property_id?: number | null,
      property_condition_value?: any | null,
      date_format?: string | null,
      text_translation_id: number,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      property?: {
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      } | null
    } | null
  }>,
  deflection_flow_actions: Array<{
    __typename: 'deflection_flow_action',
    position?: number | null,
    flow_action?: {
      __typename: 'flow_action',
      id: number,
      token: string,
      code?: string | null,
      type: flow_action_type_enum,
      account_id: number,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow_id?: number | null,
      text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type DeflectionsGridFlowFragment = {
  __typename: 'flow',
  id: number,
  title: string,
  published_version?: {
    __typename: 'flow_version',
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            published_version?: {
              __typename: 'offer_rule_group_version',
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  published_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        deflection_ids: any
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
};

export type UpdateDeflectionMutationVariables = Exact<{
  object: deflection_insert_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateDeflectionMutation = {
  insert_deflection_one?: {
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type CreateFlowModalQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateFlowModalQuery = {
  flow: Array<{
    __typename: 'flow',
    title: string
  }>
};

export type CreateFlowModalMutationVariables = Exact<{
  input: CreateFlowInput;
}>;


export type CreateFlowModalMutation = {
  createFlow: {
    __typename: 'CreateFlowOutput',
    flow?: {
      __typename: 'flow',
      token: string
    } | null
  }
};

export type DuplicateFlowModalQueryVariables = Exact<{ [key: string]: never; }>;


export type DuplicateFlowModalQuery = {
  flow: Array<{
    __typename: 'flow',
    title: string
  }>
};

export type DuplicateFlowModalMutationVariables = Exact<{
  input: DuplicateFlowInput;
}>;


export type DuplicateFlowModalMutation = {
  duplicateFlow: {
    __typename: 'DuplicateFlowOutput',
    flow?: {
      __typename: 'flow',
      token: string
    } | null
  }
};

export type EndFlowTestModalMutationVariables = Exact<{
  flowTestId: Scalars['Int']['input'];
  winningFlowId: Scalars['Int']['input'];
}>;


export type EndFlowTestModalMutation = {
  update_flow_test_by_pk?: {
    __typename: 'flow_test',
    id: number,
    winning_flow_id?: number | null
  } | null
};

export type FlowRoutesDeleteEligibilityMessageMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FlowRoutesDeleteEligibilityMessageMutation = {
  update_eligibility_message_by_pk?: {
    __typename: 'eligibility_message',
    id: number
  } | null
};

export type FlowRoutesPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowRoutesPanelQuery = {
  flow_route_rule: Array<{
    __typename: 'flow_route_rule',
    id: number,
    segment_group_ids: any,
    segment_ids: any,
    flow_ids: any,
    flow_test_ids: any,
    eligibility_message_ids: any,
    flow_route_rule_segment_groups: Array<{
      __typename: 'flow_route_rule_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_route_rule_segments: Array<{
      __typename: 'flow_route_rule_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    flow_route_rule_flows: Array<{
      __typename: 'flow_route_rule_flow',
      flow_id?: number | null
    }>,
    flow_route_rule_flow_tests: Array<{
      __typename: 'flow_route_rule_flow_test',
      flow_test_id?: number | null,
      flow_test?: {
        __typename: 'flow_test',
        id: number,
        name: string,
        control_weight: number,
        winning_flow_id?: number | null,
        control_flow: {
          __typename: 'flow',
          id: number
        },
        flow_test_flows: Array<{
          __typename: 'flow_test_flow',
          flow_test_id: number,
          flow_id: number,
          weight: number
        }>
      } | null
    }>,
    flow_route_rule_eligibility_messages: Array<{
      __typename: 'flow_route_rule_eligibility_message',
      eligibility_message_id?: number | null,
      eligibility_message?: {
        __typename: 'eligibility_message',
        id: number,
        name: string
      } | null
    }>
  }>,
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    name: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment: {
        __typename: 'segment',
        id: number,
        name: string
      }
    }>
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null
  }>,
  inlineSegments: Array<{
    __typename: 'segment',
    id: number,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum,
    entity: property_entity_enum,
    token: string,
    name: string,
    created_at: string
  }>,
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string
  }>,
  flow_test: Array<{
    __typename: 'flow_test',
    id: number,
    name: string
  }>,
  eligibility_message: Array<{
    __typename: 'eligibility_message',
    id: number,
    name: string
  }>
};

export type FlowRoutesPanelSaveUnsavedChangesMutationVariables = Exact<{
  flowRouteRules: Array<flow_route_rule_insert_input> | flow_route_rule_insert_input;
  eligibilityRouteRules: Array<flow_route_rule_insert_input> | flow_route_rule_insert_input;
  accountId: Scalars['Int']['input'];
  defaultFlowId: Scalars['Int']['input'];
}>;


export type FlowRoutesPanelSaveUnsavedChangesMutation = {
  delete_flow_route_rule?: {
    __typename: 'flow_route_rule_mutation_response',
    affected_rows: number
  } | null,
  insertFlowRoutes?: {
    __typename: 'flow_route_rule_mutation_response',
    affected_rows: number
  } | null,
  insertEligibilityMessages?: {
    __typename: 'flow_route_rule_mutation_response',
    affected_rows: number
  } | null,
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    default_flow_id?: number | null
  } | null
};

export type FlowTestSubscriptionVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type FlowTestSubscription = {
  flow_test: Array<{
    __typename: 'flow_test',
    id: number,
    token: string,
    name: string,
    created_at: string,
    control_weight: number,
    control_flow: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    },
    winning_flow?: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    } | null,
    flow_test_flows: Array<{
      __typename: 'flow_test_flow',
      flow_test_id: number,
      flow_id: number,
      weight: number,
      flow: {
        __typename: 'flow',
        id: number,
        title: string,
        token: string
      }
    }>,
    report_flow_test_stats: Array<{
      __typename: 'report_flow_test_stats',
      flow_id?: number | null,
      views?: number | null,
      cancels?: number | null,
      saves?: number | null,
      deflections?: number | null,
      retains?: number | null
    }>
  }>
};

export type FlowTestDeleteMutationVariables = Exact<{
  flowTestId: Scalars['Int']['input'];
}>;


export type FlowTestDeleteMutation = {
  update_flow_test_by_pk?: {
    __typename: 'flow_test',
    id: number,
    deleted_at?: string | null
  } | null
};

export type FlowTestPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowTestPanelQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string
  }>,
  flow_test: Array<{
    __typename: 'flow_test',
    id: number,
    name: string
  }>
};

export type FlowTestPanelMutationVariables = Exact<{
  object: flow_test_insert_input;
}>;


export type FlowTestPanelMutation = {
  insert_flow_test_one?: {
    __typename: 'flow_test',
    id: number,
    token: string,
    name: string,
    created_at: string,
    control_weight: number,
    control_flow: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    },
    winning_flow?: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    } | null,
    flow_test_flows: Array<{
      __typename: 'flow_test_flow',
      flow_test_id: number,
      flow_id: number,
      weight: number,
      flow: {
        __typename: 'flow',
        id: number,
        title: string,
        token: string
      }
    }>,
    report_flow_test_stats: Array<{
      __typename: 'report_flow_test_stats',
      flow_id?: number | null,
      views?: number | null,
      cancels?: number | null,
      saves?: number | null,
      deflections?: number | null,
      retains?: number | null
    }>
  } | null
};

export type FlowTestUpdateTestMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  controlWeight: Scalars['Int']['input'];
}>;


export type FlowTestUpdateTestMutation = {
  update_flow_test_by_pk?: {
    __typename: 'flow_test',
    id: number,
    name: string,
    control_weight: number
  } | null
};

export type FlowTestUpdateVariantMutationVariables = Exact<{
  flowTestId: Scalars['Int']['input'];
  flowId: Scalars['Int']['input'];
  weight: Scalars['Int']['input'];
}>;


export type FlowTestUpdateVariantMutation = {
  update_flow_test_flow_by_pk?: {
    __typename: 'flow_test_flow',
    flow_test_id: number,
    flow_id: number,
    weight: number
  } | null
};

export type FlowTestsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<flow_test_order_by> | flow_test_order_by>;
}>;


export type FlowTestsQuery = {
  flow_test: Array<{
    __typename: 'flow_test',
    id: number,
    token: string,
    name: string,
    created_at: string,
    control_weight: number,
    control_flow: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    },
    winning_flow?: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string
    } | null,
    flow_test_flows: Array<{
      __typename: 'flow_test_flow',
      flow_test_id: number,
      flow_id: number,
      weight: number,
      flow: {
        __typename: 'flow',
        id: number,
        title: string,
        token: string
      }
    }>,
    report_flow_test_stats: Array<{
      __typename: 'report_flow_test_stats',
      flow_id?: number | null,
      views?: number | null,
      cancels?: number | null,
      saves?: number | null,
      deflections?: number | null,
      retains?: number | null
    }>
  }>,
  flow_test_aggregate: {
    __typename: 'flow_test_aggregate',
    aggregate?: {
      __typename: 'flow_test_aggregate_fields',
      count: number
    } | null
  },
  flow_aggregate: {
    __typename: 'flow_aggregate',
    aggregate?: {
      __typename: 'flow_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowsQueryVariables = Exact<{
  where: flow_bool_exp;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FlowsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    logo_url?: string | null,
    redirect_uri?: string | null,
    preview_property_values: any,
    css?: string | null,
    default_language: language_enum,
    prevent_if_canceled: boolean,
    flow_tags: Array<{
      __typename: 'flow_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    account: {
      __typename: 'account',
      id: number,
      title: string,
      prompt_for_subscriber_details: boolean,
      intake_form_name_email: boolean,
      flow_css?: string | null,
      show_branding_feature: Array<{
        __typename: 'account_feature',
        key: account_feature_key_enum,
        value: any
      }>,
      properties: Array<{
        __typename: 'property',
        id: number,
        name: string,
        type: property_type_enum,
        format?: property_format_enum | null
      }>
    },
    flow_texts: Array<{
      __typename: 'flow_text',
      flow_id: number,
      key: flow_text_key_enum,
      published_version?: {
        __typename: 'flow_text_version',
        id: number,
        flow_id: number,
        key: flow_text_key_enum,
        translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'flow_text_version',
        id: number,
        flow_id: number,
        key: flow_text_key_enum,
        translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    flow_subscriber_form_questions: Array<{
      __typename: 'flow_subscriber_form_question',
      id: number,
      flow_id: number,
      title: string,
      hint: string,
      position: number,
      required: boolean
    }>,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          type: flow_step_type_enum,
          token: string,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number
                } | null
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number
              } | null
            } | null
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum,
              token: string,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    prevent_if_canceled_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  }>,
  flow_aggregate: {
    __typename: 'flow_aggregate',
    aggregate?: {
      __typename: 'flow_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowsHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowsHomeQuery = {
  flow_aggregate: {
    __typename: 'flow_aggregate',
    aggregate?: {
      __typename: 'flow_aggregate_fields',
      count: number
    } | null
  },
  flow_test_aggregate: {
    __typename: 'flow_test_aggregate',
    aggregate?: {
      __typename: 'flow_test_aggregate_fields',
      count: number
    } | null
  }
};

export type StartSessionModalQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type StartSessionModalQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>,
  account_user_setting: Array<{
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  }>,
  account: Array<{
    __typename: 'account',
    id: number,
    prompt_for_subscriber_details: boolean
  }>,
  naviga_paper_code: Array<{
    __typename: 'naviga_paper_code',
    paper_code: string
  }>
};

export type StartSessionModalSignFlowPayloadMutationVariables = Exact<{
  input: SignFlowPayloadInput;
}>;


export type StartSessionModalSignFlowPayloadMutation = {
  signFlowPayload: {
    __typename: 'SignFlowPayloadOutput',
    signature: string
  }
};

export type StartSessionModalSearchQueryVariables = Exact<{
  searchBy: Scalars['String']['input'];
  query: Scalars['String']['input'];
  paperCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartSessionModalSearchQuery = {
  searchPlatformCustomers: {
    __typename: 'SearchPlatformCustomersOutput',
    customers: Array<{
      __typename: 'SearchPlatformCustomersResult',
      id: string,
      name: string,
      email: string
    }>
  }
};

export type StartSessionModalSubscriptionsQueryVariables = Exact<{
  platformCustomerId: Scalars['String']['input'];
  paperCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartSessionModalSubscriptionsQuery = {
  platformCustomerSubscriptions: {
    __typename: 'PlatformCustomerSubscriptionsOutput',
    subscriptions: Array<{
      __typename: 'PlatformCustomerSubscription',
      id: string,
      nickname: string,
      product: string
    }>
  }
};

export type StartSessionModalDismissWarningMutationVariables = Exact<{ [key: string]: never; }>;


export type StartSessionModalDismissWarningMutation = {
  insert_account_user_setting_one?: {
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  } | null
};

export type StepsCardTagsQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type StepsCardTagsQuery = {
  flow_tag: Array<{
    __typename: 'flow_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type EligibilityMessagePanelQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type EligibilityMessagePanelQuery = {
  eligibility_message_by_pk?: {
    __typename: 'eligibility_message',
    id: number,
    name: string,
    eligibility_message_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    eligibility_header_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      }>
    }
  } | null,
  flow: Array<{
    __typename: 'flow',
    id: number,
    default_language: language_enum,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    account: {
      __typename: 'account',
      id: number
    }
  }>
};

export type EligibilityMessagePanelInitEligibilityMessageContentMutationVariables = Exact<{
  objects: Array<translation_insert_input> | translation_insert_input;
}>;


export type EligibilityMessagePanelInitEligibilityMessageContentMutation = {
  insert_translation?: {
    __typename: 'translation_mutation_response',
    returning: Array<{
      __typename: 'translation',
      id: number
    }>
  } | null
};

export type EligibilityMessagePanelCreateEligibilityMessageMutationVariables = Exact<{
  object: eligibility_message_insert_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type EligibilityMessagePanelCreateEligibilityMessageMutation = {
  insert_eligibility_message_one?: {
    __typename: 'eligibility_message',
    id: number,
    name: string,
    eligibility_message_translation_id: number,
    eligibility_header_translation_id?: number | null
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type FlowEditorCreateAnnotationFlowVersionMutationVariables = Exact<{
  object: annotation_flow_version_insert_input;
}>;


export type FlowEditorCreateAnnotationFlowVersionMutation = {
  insert_annotation_flow_version_one?: {
    __typename: 'annotation_flow_version',
    annotation_id: number,
    flow_version_id: number
  } | null
};

export type FlowEditorPreviewQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowEditorPreviewQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type FlowEditorHasUnsavedChangesQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type FlowEditorHasUnsavedChangesQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    id: number,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          type: flow_step_type_enum,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number
              } | null
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number
              } | null
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number
              } | null
            } | null
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number
                          } | null
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              draft_version?: {
                __typename: 'intervention_version',
                id: number
              } | null,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number
                } | null
              }
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number
              } | null
            } | null
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number
                } | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number
    } | null,
    flow_texts: Array<{
      __typename: 'flow_text',
      flow_id: number,
      key: flow_text_key_enum,
      published_version?: {
        __typename: 'flow_text_version',
        id: number
      } | null,
      draft_version?: {
        __typename: 'flow_text_version',
        id: number
      } | null
    }>
  } | null
};

export type FlowEditorDeflectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowEditorDeflectionsQuery = {
  deflection: Array<{
    __typename: 'deflection',
    id: number,
    token: string,
    title: string,
    tag_ids: any,
    created_at: string,
    archived_at?: string | null,
    minimum_items?: number | null,
    heading_translation_id: number,
    content_translation_id: number,
    deflection_tags: Array<{
      __typename: 'deflection_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation_id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  }>
};

export type FlowSettingsPanelQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type FlowSettingsPanelQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    auto_cancel: boolean,
    auto_cancel_at: flow_auto_cancel_at_enum,
    logo_url?: string | null,
    tag_ids: any,
    signature_required: boolean,
    on_cancel: flow_on_cancel_enum,
    on_cancel_perform_at: flow_on_cancel_perform_at_enum,
    prevent_if_canceled: boolean,
    default_language: language_enum,
    account: {
      __typename: 'account',
      id: number,
      default_flow_id?: number | null,
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum
      } | null,
      properties: Array<{
        __typename: 'property',
        id: number,
        name: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null
      }>
    },
    flow_route_rule_flows_aggregate: {
      __typename: 'flow_route_rule_flow_aggregate',
      aggregate?: {
        __typename: 'flow_route_rule_flow_aggregate_fields',
        count: number
      } | null
    },
    flow_on_cancel_change_plans: Array<{
      __typename: 'flow_on_cancel_change_plan',
      platform_id: string,
      prorate: boolean,
      remove_add_ons: boolean
    }>,
    prevent_if_canceled_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    flow_tags: Array<{
      __typename: 'flow_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>
  } | null,
  tag: Array<{
    __typename: 'tag',
    id: number,
    name: string
  }>,
  deflection: Array<{
    __typename: 'deflection',
    id: number
  }>
};

export type FlowSettingsPanelInitAlreadyCanceledContentMutationVariables = Exact<{
  objects: Array<translation_insert_input> | translation_insert_input;
}>;


export type FlowSettingsPanelInitAlreadyCanceledContentMutation = {
  insert_translation?: {
    __typename: 'translation_mutation_response',
    returning: Array<{
      __typename: 'translation',
      id: number
    }>
  } | null
};

export type FlowSettingsPanelUpdateSettingsMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  set: flow_set_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type FlowSettingsPanelUpdateSettingsMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number,
    title: string,
    logo_url?: string | null,
    auto_cancel: boolean,
    auto_cancel_at: flow_auto_cancel_at_enum,
    signature_required: boolean,
    flow_tags: Array<{
      __typename: 'flow_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type FlowSettingsPanelUpdateChangePlanMutationVariables = Exact<{
  object: flow_on_cancel_change_plan_insert_input;
}>;


export type FlowSettingsPanelUpdateChangePlanMutation = {
  insert_flow_on_cancel_change_plan_one?: {
    __typename: 'flow_on_cancel_change_plan',
    flow_id: number,
    platform_connection_id: number,
    platform_id: string,
    prorate: boolean,
    remove_add_ons: boolean
  } | null
};

export type FlowSettionsPanelClearChangePlanMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type FlowSettionsPanelClearChangePlanMutation = {
  delete_flow_on_cancel_change_plan?: {
    __typename: 'flow_on_cancel_change_plan_mutation_response',
    affected_rows: number
  } | null
};

export type FlowSettingsPanelDeleteFlowMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type FlowSettingsPanelDeleteFlowMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number
  } | null
};

export type FlowTextPanelQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FlowTextPanelQuery = {
  flow_text: Array<{
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    published_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type FormQuestionConditionsPanelQueryVariables = Exact<{
  formVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FormQuestionConditionsPanelQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string
  }>,
  form_version_question: Array<{
    __typename: 'form_version_question',
    position?: number | null,
    question?: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    } | null
  }>
};

export type PreviewPropertiesModalDefaultMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  values: Scalars['jsonb']['input'];
}>;


export type PreviewPropertiesModalDefaultMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number,
    preview_property_values: any
  } | null
};

export type PreviewPropertiesModalQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
}>;


export type PreviewPropertiesModalQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    preview_property_values: any
  } | null,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum
  }>
};

export type PreviewSegmentDropdownQueryVariables = Exact<{
  input: PropertySegmentMatchesInput;
}>;


export type PreviewSegmentDropdownQuery = {
  propertySegmentMatches: {
    __typename: 'PropertySegmentMatchesOutput',
    segmentIds: Array<number>
  }
};

export type PublishFlowModalCreateAnnotationMutationVariables = Exact<{
  object: annotation_insert_input;
}>;


export type PublishFlowModalCreateAnnotationMutation = {
  insert_annotation_one?: {
    __typename: 'annotation',
    id: number
  } | null
};

export type QuestionConditionsModalQueryVariables = Exact<{
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type QuestionConditionsModalQuery = {
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    token: string,
    name: string,
    created_at: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment_group_id: number,
      segment_id: number,
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        token: string,
        name: string,
        integration_segment_id?: string | null,
        created_at: string,
        primary_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition_group?: {
              __typename: 'segment_condition_group',
              id: number,
              boolean_operator: segment_condition_boolean_operator_enum,
              segment_condition_group_entries: Array<{
                __typename: 'segment_condition_group_entry',
                entry_segment_condition?: {
                  __typename: 'segment_condition',
                  id: number,
                  property: segment_condition_property_enum,
                  operator: segment_condition_operator_enum,
                  value: any,
                  property_id?: number | null,
                  custom_property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>
            } | null,
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        integration?: {
          __typename: 'integration',
          id: number,
          type: integration_type_enum
        } | null,
        segment_tags: Array<{
          __typename: 'segment_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>
      }
    }>
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string
  }>,
  inlineSegments: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  question: Array<{
    __typename: 'question',
    id: number,
    token: string,
    type: question_type_enum,
    primary: boolean,
    published_version?: {
      __typename: 'question_version',
      id: number,
      question_id: number,
      required: boolean,
      title_translation_id: number,
      hint_translation_id: number,
      placeholder_translation_id?: number | null,
      condition_segment_ids?: any | null,
      condition_question_option_ids?: any | null,
      condition_include_other_in_question_ids?: any | null,
      question_option_ids?: any | null,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      hint_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      placeholder_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      question_version_question_options: Array<{
        __typename: 'question_version_question_option',
        question_option_id?: number | null,
        question_version_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          token: string,
          reason_code?: string | null,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_question_options: Array<{
        __typename: 'question_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_segments: Array<{
        __typename: 'question_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      question_version_condition_segment_groups: Array<{
        __typename: 'question_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'question_version',
      id: number,
      question_id: number,
      required: boolean,
      title_translation_id: number,
      hint_translation_id: number,
      placeholder_translation_id?: number | null,
      condition_segment_ids?: any | null,
      condition_question_option_ids?: any | null,
      condition_include_other_in_question_ids?: any | null,
      question_option_ids?: any | null,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      hint_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      placeholder_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      question_version_question_options: Array<{
        __typename: 'question_version_question_option',
        question_option_id?: number | null,
        question_version_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          token: string,
          reason_code?: string | null,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_question_options: Array<{
        __typename: 'question_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_segments: Array<{
        __typename: 'question_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      question_version_condition_segment_groups: Array<{
        __typename: 'question_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>
    } | null,
    question_settings: Array<{
      __typename: 'question_setting',
      question_id: number,
      key: question_setting_key_enum,
      published_version?: {
        __typename: 'question_setting_version',
        id: number,
        question_id: number,
        key: question_setting_key_enum,
        value: any
      } | null,
      draft_version?: {
        __typename: 'question_setting_version',
        id: number,
        question_id: number,
        key: question_setting_key_enum,
        value: any
      } | null
    }>
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum,
    entity: property_entity_enum,
    token: string,
    name: string,
    created_at: string
  }>
};

export type UpdateAcknowledgementGroupVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateAcknowledgementGroupVersionMutation = {
  update_acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number,
    published_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type UpdateAcknowledgementVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateAcknowledgementVersionMutation = {
  update_acknowledgement_by_pk?: {
    __typename: 'acknowledgement',
    id: number,
    title: string,
    published_version?: {
      __typename: 'acknowledgement_version',
      id: number,
      acknowledgement_id: number,
      label_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'acknowledgement_version',
      id: number,
      acknowledgement_id: number,
      label_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateAcknowledgementGroupDraftQueryVariables = Exact<{
  acknowledgementGroupId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateAcknowledgementGroupDraftQuery = {
  acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number,
    account_id: number,
    published_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CreateAcknowledgementGroupDraftMutationVariables = Exact<{
  object: acknowledgement_group_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateAcknowledgementGroupDraftMutation = {
  insert_acknowledgement_group_version_one?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group_id: number,
    acknowledgement_ids: any,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    acknowledgement_group_version_acknowledgements: Array<{
      __typename: 'acknowledgement_group_version_acknowledgement',
      acknowledgement_group_version_id?: number | null,
      acknowledgement_id?: number | null,
      acknowledgement?: {
        __typename: 'acknowledgement',
        id: number,
        title: string,
        published_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_version',
          id: number,
          acknowledgement_id: number,
          label_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null
};

export type CreateAcknowledgementDraftQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CreateAcknowledgementDraftQuery = {
  acknowledgement_by_pk?: {
    __typename: 'acknowledgement',
    id: number,
    published_version?: {
      __typename: 'acknowledgement_version',
      id: number,
      acknowledgement_id: number,
      label_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'acknowledgement_version',
      id: number,
      acknowledgement_id: number,
      label_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateAcknowledgementDraftMutationVariables = Exact<{
  object: acknowledgement_version_insert_input;
}>;


export type CreateAcknowledgementDraftMutation = {
  insert_acknowledgement_version_one?: {
    __typename: 'acknowledgement_version',
    id: number,
    acknowledgement_id: number,
    label_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type UpdateAcknowledgementGroupTitleTranslationMutationVariables = Exact<{
  objects: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateAcknowledgementGroupTitleTranslationMutation = {
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    affected_rows: number
  } | null
};

export type UpdateAcknowledgementGroupTitleTranslationRefetchQueryVariables = Exact<{
  acknowledgementGroupId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateAcknowledgementGroupTitleTranslationRefetchQuery = {
  acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number,
    published_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'acknowledgement_group_version',
      id: number,
      acknowledgement_group_id: number,
      acknowledgement_ids: any,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      acknowledgement_group_version_acknowledgements: Array<{
        __typename: 'acknowledgement_group_version_acknowledgement',
        acknowledgement_group_version_id?: number | null,
        acknowledgement_id?: number | null,
        acknowledgement?: {
          __typename: 'acknowledgement',
          id: number,
          title: string,
          published_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_version',
            id: number,
            acknowledgement_id: number,
            label_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type AddAcknowledgementMutationVariables = Exact<{
  object: acknowledgement_version_insert_input;
}>;


export type AddAcknowledgementMutation = {
  insert_acknowledgement_version_one?: {
    __typename: 'acknowledgement_version',
    id: number,
    acknowledgement_id: number
  } | null
};

export type AddAcknowledgementSetVersionMutationVariables = Exact<{
  acknowledgementId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
  acknowledgementGroupVersionId: Scalars['Int']['input'];
  appendAcknowledgementId: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddAcknowledgementSetVersionMutation = {
  update_acknowledgement_by_pk?: {
    __typename: 'acknowledgement',
    id: number
  } | null,
  update_acknowledgement_group_version_by_pk?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group: {
      __typename: 'acknowledgement_group',
      id: number,
      published_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type MoveAcknowledgementMutationVariables = Exact<{
  groupVersionId: Scalars['Int']['input'];
  ids: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type MoveAcknowledgementMutation = {
  update_acknowledgement_group_version_by_pk?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group: {
      __typename: 'acknowledgement_group',
      id: number,
      published_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateAcknowledgementTranslationsMutationVariables = Exact<{
  labelTranslation: translation_insert_input;
}>;


export type UpdateAcknowledgementTranslationsMutation = {
  insert_translation_one?: {
    __typename: 'translation',
    id: number
  } | null
};

export type UpdateAcknowledgementDraftMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  update: acknowledgement_version_set_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateAcknowledgementDraftMutation = {
  update_acknowledgement_version_by_pk?: {
    __typename: 'acknowledgement_version',
    id: number,
    acknowledgement: {
      __typename: 'acknowledgement',
      id: number,
      title: string,
      published_version?: {
        __typename: 'acknowledgement_version',
        id: number,
        acknowledgement_id: number,
        label_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_version',
        id: number,
        acknowledgement_id: number,
        label_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null
};

export type DeleteAcknowledgementMutationVariables = Exact<{
  groupVersionId: Scalars['Int']['input'];
  acknowledgementIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type DeleteAcknowledgementMutation = {
  update_acknowledgement_group_version_by_pk?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group: {
      __typename: 'acknowledgement_group',
      id: number,
      published_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'acknowledgement_group_version',
        id: number,
        acknowledgement_group_id: number,
        acknowledgement_ids: any,
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        acknowledgement_group_version_acknowledgements: Array<{
          __typename: 'acknowledgement_group_version_acknowledgement',
          acknowledgement_group_version_id?: number | null,
          acknowledgement_id?: number | null,
          acknowledgement?: {
            __typename: 'acknowledgement',
            id: number,
            title: string,
            published_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_version',
              id: number,
              acknowledgement_id: number,
              label_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateConfirmationVersionMutationVariables = Exact<{
  confirmationId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateConfirmationVersionMutation = {
  update_confirmation_by_pk?: {
    __typename: 'confirmation',
    id: number,
    published_version?: {
      __typename: 'confirmation_version',
      id: number,
      confirmation_id: number,
      show_on_cancel: boolean,
      show_on_save: boolean,
      saved_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      saved_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'confirmation_version',
      id: number,
      confirmation_id: number,
      show_on_cancel: boolean,
      show_on_save: boolean,
      saved_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      saved_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateConfirmationDraftExistingVersionQueryVariables = Exact<{
  confirmationId: Scalars['Int']['input'];
}>;


export type CreateConfirmationDraftExistingVersionQuery = {
  confirmation_by_pk?: {
    __typename: 'confirmation',
    id: number,
    published_version?: {
      __typename: 'confirmation_version',
      id: number,
      confirmation_id: number,
      show_on_cancel: boolean,
      show_on_save: boolean,
      saved_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      saved_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'confirmation_version',
      id: number,
      confirmation_id: number,
      show_on_cancel: boolean,
      show_on_save: boolean,
      saved_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      saved_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      canceled_content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateConfirmationVersionMutationVariables = Exact<{
  object: confirmation_version_insert_input;
}>;


export type CreateConfirmationVersionMutation = {
  insert_confirmation_version_one?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation_id: number,
    show_on_cancel: boolean,
    show_on_save: boolean,
    saved_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    saved_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    canceled_content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type UpdateConfirmationTranslationsMutationVariables = Exact<{
  canceledHeadlineTranslationValues: Array<translation_value_insert_input> | translation_value_insert_input;
  canceledContentTranslationValues: Array<translation_value_insert_input> | translation_value_insert_input;
  savedHeadlineTranslationValues: Array<translation_value_insert_input> | translation_value_insert_input;
  savedContentTranslationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateConfirmationTranslationsMutation = {
  canceled_headline?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null,
  canceled_content?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null,
  saved_headline?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null,
  saved_content?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type UpdateConfirmationShowOnCancelMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  showOnCancel: Scalars['Boolean']['input'];
}>;


export type UpdateConfirmationShowOnCancelMutation = {
  update_confirmation_version_by_pk?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation: {
      __typename: 'confirmation',
      id: number,
      published_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null
};

export type UpdateConfirmationShowOnSaveMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  showOnSave: Scalars['Boolean']['input'];
}>;


export type UpdateConfirmationShowOnSaveMutation = {
  update_confirmation_version_by_pk?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation: {
      __typename: 'confirmation',
      id: number,
      published_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      draft_version?: {
        __typename: 'confirmation_version',
        id: number,
        confirmation_id: number,
        show_on_cancel: boolean,
        show_on_save: boolean,
        saved_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        saved_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        canceled_content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null
};

export type PublishFlowTextMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  key: flow_text_key_enum;
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishFlowTextMutation = {
  update_flow_text_by_pk?: {
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum
  } | null
};

export type PublishQuestionSettingMutationVariables = Exact<{
  questionId: Scalars['Int']['input'];
  key: question_setting_key_enum;
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishQuestionSettingMutation = {
  update_question_setting_by_pk?: {
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum
  } | null
};

export type PublishQuestionOptionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishQuestionOptionMutation = {
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number
  } | null
};

export type PublishQuestionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishQuestionMutation = {
  update_question_by_pk?: {
    __typename: 'question',
    id: number
  } | null
};

export type PublishAcknowledgementMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishAcknowledgementMutation = {
  update_acknowledgement_by_pk?: {
    __typename: 'acknowledgement',
    id: number
  } | null
};

export type PublishAcknowledgementGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishAcknowledgementGroupMutation = {
  update_acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number
  } | null
};

export type PublishFormMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishFormMutation = {
  update_form_by_pk?: {
    __typename: 'form',
    id: number
  } | null
};

export type PublishOfferRuleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishOfferRuleMutation = {
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number
  } | null
};

export type PublishOfferRuleGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishOfferRuleGroupMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null
};

export type PublishConfirmationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishConfirmationMutation = {
  update_confirmation_by_pk?: {
    __typename: 'confirmation',
    id: number
  } | null
};

export type PublishInterventionOfferRuleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishInterventionOfferRuleMutation = {
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number
  } | null
};

export type PublishInterventionOfferRuleGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishInterventionOfferRuleGroupMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null
};

export type PublishInterventionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishInterventionMutation = {
  update_intervention_by_pk?: {
    __typename: 'intervention',
    id: number
  } | null
};

export type PublishFlowStepMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishFlowStepMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type PublishFlowMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type PublishFlowMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number
  } | null
};

export type UpdateCssMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  css: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateCssMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    id: number,
    flow: {
      __typename: 'flow',
      id: number,
      draft_version?: {
        __typename: 'flow_version',
        id: number,
        flow_id: number,
        css?: string | null,
        flow_step_ids: any,
        flow_version_flow_steps: Array<{
          __typename: 'flow_version_flow_step',
          flow_step?: {
            __typename: 'flow_step',
            id: number,
            token: string,
            type: flow_step_type_enum,
            published_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            flow_step_acknowledgement_group?: {
              __typename: 'flow_step_acknowledgement_group',
              flow_step_id: number,
              acknowledgement_group_id: number,
              acknowledgement_group: {
                __typename: 'acknowledgement_group',
                id: number,
                published_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_form?: {
              __typename: 'flow_step_form',
              flow_step_id: number,
              form_id: number,
              form: {
                __typename: 'form',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_question?: {
              __typename: 'flow_step_question',
              flow_step_id: number,
              question_id: number,
              question: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              }
            } | null,
            flow_step_deflection_rule_group?: {
              __typename: 'flow_step_deflection_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              } | null
            } | null,
            flow_step_offer_rule_group?: {
              __typename: 'flow_step_offer_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              } | null
            } | null,
            flow_step_rule_group?: {
              __typename: 'flow_step_rule_group',
              flow_step_id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              }
            } | null,
            flow_step_confirmation?: {
              __typename: 'flow_step_confirmation',
              flow_step_id: number,
              confirmation_id: number,
              confirmation: {
                __typename: 'confirmation',
                id: number,
                published_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null,
            flow_step_intervention?: {
              __typename: 'flow_step_intervention',
              flow_step_id: number,
              intervention_id: number,
              intervention: {
                __typename: 'intervention',
                id: number,
                token: string,
                title: string,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                },
                published_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateGlobalCssMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  css: Scalars['String']['input'];
}>;


export type UpdateGlobalCssMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    flow_css?: string | null
  } | null
};

export type SetFormVersionMutationVariables = Exact<{
  formId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SetFormVersionMutation = {
  update_form_by_pk?: {
    __typename: 'form',
    id: number
  } | null
};

export type CreateFormDraftQueryVariables = Exact<{
  formId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFormDraftQuery = {
  form_by_pk?: {
    __typename: 'form',
    id: number,
    published_version?: {
      __typename: 'form_version',
      id: number,
      form_id: number,
      question_ids: any,
      form_version_questions: Array<{
        __typename: 'form_version_question',
        question_id?: number | null,
        form_version_id?: number | null,
        question?: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          published_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          question_settings: Array<{
            __typename: 'question_setting',
            question_id: number,
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null,
            draft_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'form_version',
      id: number,
      form_id: number,
      question_ids: any,
      form_version_questions: Array<{
        __typename: 'form_version_question',
        question_id?: number | null,
        form_version_id?: number | null,
        question?: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          published_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          question_settings: Array<{
            __typename: 'question_setting',
            question_id: number,
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null,
            draft_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null
          }>
        } | null
      }>
    } | null
  } | null
};

export type CreateFormDraftMutationVariables = Exact<{
  object: form_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFormDraftMutation = {
  insert_form_version_one?: {
    __typename: 'form_version',
    id: number,
    form_id: number,
    question_ids: any,
    form_version_questions: Array<{
      __typename: 'form_version_question',
      question_id?: number | null,
      form_version_id?: number | null,
      question?: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      } | null
    }>
  } | null
};

export type CreateFormMutationVariables = Exact<{
  object: form_insert_input;
}>;


export type CreateFormMutation = {
  insert_form_one?: {
    __typename: 'form',
    id: number
  } | null
};

export type CreateFormVersionMutationVariables = Exact<{
  object: form_version_insert_input;
}>;


export type CreateFormVersionMutation = {
  insert_form_version_one?: {
    __typename: 'form_version',
    id: number
  } | null
};

export type ReorderQuestionsMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  questionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type ReorderQuestionsMutation = {
  update_form_version_by_pk?: {
    __typename: 'form_version',
    id: number,
    form: {
      __typename: 'form',
      id: number,
      title: string,
      published_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null
    }
  } | null
};

export type FormManagerAddExistingQuestionMutationVariables = Exact<{
  formVersionId: Scalars['Int']['input'];
  questionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FormManagerAddExistingQuestionMutation = {
  update_form_version_by_pk?: {
    __typename: 'form_version',
    id: number,
    form: {
      __typename: 'form',
      id: number,
      title: string,
      published_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null
    }
  } | null
};

export type FormManagerDeleteQuestionMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  remainingQuestionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FormManagerDeleteQuestionMutation = {
  update_form_version_by_pk?: {
    __typename: 'form_version',
    id: number,
    form: {
      __typename: 'form',
      id: number,
      title: string,
      published_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'form_version',
        id: number,
        form_id: number,
        question_ids: any,
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question_id?: number | null,
          form_version_id?: number | null,
          question?: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          } | null
        }>
      } | null
    }
  } | null
};

export type FlowHasUnsavedChangesFlowFragment = {
  __typename: 'flow',
  id: number,
  published_version?: {
    __typename: 'flow_version',
    id: number,
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        id: number,
        type: flow_step_type_enum,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number
            } | null
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number
            } | null
          } | null
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question?: {
                  __typename: 'question',
                  id: number,
                  type: question_type_enum,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number
                        } | null
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            draft_version?: {
              __typename: 'intervention_version',
              id: number
            } | null,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number
              } | null
            }
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number
            } | null
          } | null
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              draft_version?: {
                __typename: 'question_setting_version',
                id: number
              } | null
            }>
          }
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_version',
    id: number
  } | null,
  flow_texts: Array<{
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    published_version?: {
      __typename: 'flow_text_version',
      id: number
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number
    } | null
  }>
};

export type FlowHasUnsavedChangesOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  id: number,
  published_version?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    id: number
  } | null
};

export type FlowHasUnsavedChangesQuestionFragment = {
  __typename: 'question',
  id: number,
  type: question_type_enum,
  published_version?: {
    __typename: 'question_version',
    id: number,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option?: {
        __typename: 'question_option',
        id: number,
        draft_version?: {
          __typename: 'question_option_version',
          id: number
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'question_version',
    id: number
  } | null,
  question_settings: Array<{
    __typename: 'question_setting',
    draft_version?: {
      __typename: 'question_setting_version',
      id: number
    } | null
  }>
};

export type UpdateInterventionVersionMutationVariables = Exact<{
  interventionId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateInterventionVersionMutation = {
  update_intervention_by_pk?: {
    __typename: 'intervention',
    id: number,
    token: string,
    title: string,
    offer_rule_group_id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      title: string
    },
    published_version?: {
      __typename: 'intervention_version',
      id: number,
      intervention_id: number,
      content_translation_id: number,
      continue_button_translation_id: number,
      cancel_button_translation_id: number,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      continue_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'intervention_version',
      id: number,
      intervention_id: number,
      content_translation_id: number,
      continue_button_translation_id: number,
      cancel_button_translation_id: number,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      continue_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateInterventionDraftExistingVersionQueryVariables = Exact<{
  interventionId: Scalars['Int']['input'];
}>;


export type CreateInterventionDraftExistingVersionQuery = {
  intervention_by_pk?: {
    __typename: 'intervention',
    id: number,
    published_version?: {
      __typename: 'intervention_version',
      id: number,
      intervention_id: number,
      content_translation_id: number,
      continue_button_translation_id: number,
      cancel_button_translation_id: number,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      continue_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'intervention_version',
      id: number,
      intervention_id: number,
      content_translation_id: number,
      continue_button_translation_id: number,
      cancel_button_translation_id: number,
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      continue_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      cancel_button_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateInterventionDraftMutationVariables = Exact<{
  object: intervention_version_insert_input;
}>;


export type CreateInterventionDraftMutation = {
  insert_intervention_version_one?: {
    __typename: 'intervention_version',
    id: number,
    intervention_id: number,
    content_translation_id: number,
    continue_button_translation_id: number,
    cancel_button_translation_id: number,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    continue_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_button_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type UpdateInterventionTranslationsMutationVariables = Exact<{
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateInterventionTranslationsMutation = {
  content?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type SetQuestionVersionMutationVariables = Exact<{
  questionId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SetQuestionVersionMutation = {
  update_question_by_pk?: {
    __typename: 'question',
    id: number
  } | null
};

export type SetQuestionOptionVersionMutationVariables = Exact<{
  optionId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SetQuestionOptionVersionMutation = {
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number,
    question_id: number,
    token: string,
    reason_code?: string | null,
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      question_option_id: number,
      title_translation_id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'question_option_version',
      id: number,
      question_option_id: number,
      title_translation_id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateQuestionDraftQueryVariables = Exact<{
  questionId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateQuestionDraftQuery = {
  question_by_pk?: {
    __typename: 'question',
    id: number,
    published_version?: {
      __typename: 'question_version',
      id: number,
      question_id: number,
      required: boolean,
      title_translation_id: number,
      hint_translation_id: number,
      placeholder_translation_id?: number | null,
      condition_segment_ids?: any | null,
      condition_question_option_ids?: any | null,
      condition_include_other_in_question_ids?: any | null,
      question_option_ids?: any | null,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      hint_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      placeholder_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      question_version_question_options: Array<{
        __typename: 'question_version_question_option',
        question_option_id?: number | null,
        question_version_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          token: string,
          reason_code?: string | null,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_question_options: Array<{
        __typename: 'question_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_segments: Array<{
        __typename: 'question_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      question_version_condition_segment_groups: Array<{
        __typename: 'question_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'question_version',
      id: number,
      question_id: number,
      required: boolean,
      title_translation_id: number,
      hint_translation_id: number,
      placeholder_translation_id?: number | null,
      condition_segment_ids?: any | null,
      condition_question_option_ids?: any | null,
      condition_include_other_in_question_ids?: any | null,
      question_option_ids?: any | null,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      hint_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      placeholder_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      question_version_question_options: Array<{
        __typename: 'question_version_question_option',
        question_option_id?: number | null,
        question_version_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          token: string,
          reason_code?: string | null,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            question_option_id: number,
            title_translation_id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_question_options: Array<{
        __typename: 'question_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      question_version_condition_segments: Array<{
        __typename: 'question_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      question_version_condition_segment_groups: Array<{
        __typename: 'question_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>
    } | null
  } | null
};

export type CreateQuestionDraftMutationVariables = Exact<{
  object: question_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateQuestionDraftMutation = {
  insert_question_version_one?: {
    __typename: 'question_version',
    id: number,
    question_id: number,
    required: boolean,
    title_translation_id: number,
    hint_translation_id: number,
    placeholder_translation_id?: number | null,
    condition_segment_ids?: any | null,
    condition_question_option_ids?: any | null,
    condition_include_other_in_question_ids?: any | null,
    question_option_ids?: any | null,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    hint_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    placeholder_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    question_version_question_options: Array<{
      __typename: 'question_version_question_option',
      question_option_id?: number | null,
      question_version_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        token: string,
        reason_code?: string | null,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          question_option_id: number,
          title_translation_id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_question_options: Array<{
      __typename: 'question_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    question_version_condition_segments: Array<{
      __typename: 'question_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    question_version_condition_segment_groups: Array<{
      __typename: 'question_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>
  } | null
};

export type CreateQuestionOptionDraftQueryVariables = Exact<{
  optionId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateQuestionOptionDraftQuery = {
  question_option_by_pk?: {
    __typename: 'question_option',
    id: number,
    question_id: number,
    token: string,
    reason_code?: string | null,
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      question_option_id: number,
      title_translation_id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'question_option_version',
      id: number,
      question_option_id: number,
      title_translation_id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateQuestionOptionDraftMutationVariables = Exact<{
  object: question_option_version_insert_input;
}>;


export type CreateQuestionOptionDraftMutation = {
  insert_question_option_version_one?: {
    __typename: 'question_option_version',
    id: number,
    question_option_id: number,
    title_translation_id: number,
    title_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type UpdateSettingVersionMutationVariables = Exact<{
  questionId: Scalars['Int']['input'];
  key: question_setting_key_enum;
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateSettingVersionMutation = {
  update_question_setting_by_pk?: {
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum,
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null,
    draft_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  } | null
};

export type CreateSettingDraftExistingVersionQueryVariables = Exact<{
  questionId: Scalars['Int']['input'];
  key: question_setting_key_enum;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateSettingDraftExistingVersionQuery = {
  question_setting_by_pk?: {
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum,
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null,
    draft_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  } | null
};

export type CreateSettingDraftNewVersionMutationVariables = Exact<{
  object: question_setting_version_insert_input;
}>;


export type CreateSettingDraftNewVersionMutation = {
  insert_question_setting_version_one?: {
    __typename: 'question_setting_version',
    id: number,
    question_id: number,
    key: question_setting_key_enum,
    value: any
  } | null
};

export type CreateQuestionMutationVariables = Exact<{
  object: question_insert_input;
}>;


export type CreateQuestionMutation = {
  insert_question_one?: {
    __typename: 'question',
    id: number,
    token: string,
    type: question_type_enum
  } | null
};

export type CreateQuestionVersionMutationVariables = Exact<{
  object: question_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateQuestionVersionMutation = {
  insert_question_version_one?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type UpdateQuestionTranslationsMutationVariables = Exact<{
  titleTranslation: translation_insert_input;
  hintTranslation: translation_insert_input;
  placeholderTranslation: translation_insert_input;
}>;


export type UpdateQuestionTranslationsMutation = {
  title?: {
    __typename: 'translation',
    id: number
  } | null,
  hint?: {
    __typename: 'translation',
    id: number
  } | null,
  placeholder?: {
    __typename: 'translation',
    id: number
  } | null
};

export type UpdateQuestionUpdateDraftMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  update: question_version_set_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateQuestionUpdateDraftMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type UpdateQuestionOptionTranslationsMutationVariables = Exact<{
  titleTranslation: translation_insert_input;
}>;


export type UpdateQuestionOptionTranslationsMutation = {
  insert_translation_one?: {
    __typename: 'translation',
    id: number
  } | null
};

export type UpdateQuestionOptionDraftMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  update: question_option_version_set_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateQuestionOptionDraftMutation = {
  update_question_option_version_by_pk?: {
    __typename: 'question_option_version',
    id: number,
    question_option: {
      __typename: 'question_option',
      id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    }
  } | null
};

export type UpdateQuestionOptionReasonCodeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  reasonCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateQuestionOptionReasonCodeMutation = {
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number,
    reason_code?: string | null
  } | null
};

export type SetQuestionRequiredMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SetQuestionRequiredMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type SetQuestionSettingExistingSettingQueryVariables = Exact<{
  questionId: Scalars['Int']['input'];
  key: question_setting_key_enum;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SetQuestionSettingExistingSettingQuery = {
  question_setting_by_pk?: {
    __typename: 'question_setting',
    question_id: number,
    key: question_setting_key_enum,
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null,
    draft_version?: {
      __typename: 'question_setting_version',
      id: number,
      question_id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  } | null
};

export type SetQuestionSettingCreateSettingMutationVariables = Exact<{
  object: question_setting_version_insert_input;
}>;


export type SetQuestionSettingCreateSettingMutation = {
  insert_question_setting_version_one?: {
    __typename: 'question_setting_version',
    id: number
  } | null
};

export type SetQuestionSettingMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  value: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SetQuestionSettingMutation = {
  update_question_setting_version_by_pk?: {
    __typename: 'question_setting_version',
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type ReorderQuestionOptionsMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  optionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type ReorderQuestionOptionsMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type AddQuestionOptionVersionMutationVariables = Exact<{
  object: question_option_version_insert_input;
}>;


export type AddQuestionOptionVersionMutation = {
  insert_question_option_version_one?: {
    __typename: 'question_option_version',
    id: number,
    question_option_id: number
  } | null
};

export type AddQuestionOptionSetOptionIdsMutationVariables = Exact<{
  questionVersionId: Scalars['Int']['input'];
  optionIds: Scalars['jsonb']['input'];
  optionId: Scalars['Int']['input'];
  optionDraftVersionId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddQuestionOptionSetOptionIdsMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number
  } | null,
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type DeleteQuestionOptionMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  optionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type DeleteQuestionOptionMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type UpdateQuestionConditionMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  segmentIds: Scalars['jsonb']['input'];
  questionOptionIds: Scalars['jsonb']['input'];
  includeOtherInQuestionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateQuestionConditionMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number,
    question: {
      __typename: 'question',
      id: number,
      token: string,
      type: question_type_enum,
      primary: boolean,
      published_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        id: number,
        question_id: number,
        required: boolean,
        title_translation_id: number,
        hint_translation_id: number,
        placeholder_translation_id?: number | null,
        condition_segment_ids?: any | null,
        condition_question_option_ids?: any | null,
        condition_include_other_in_question_ids?: any | null,
        question_option_ids?: any | null,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        hint_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        placeholder_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        question_version_question_options: Array<{
          __typename: 'question_version_question_option',
          question_option_id?: number | null,
          question_version_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            token: string,
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              question_option_id: number,
              title_translation_id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_question_options: Array<{
          __typename: 'question_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      question_settings: Array<{
        __typename: 'question_setting',
        question_id: number,
        key: question_setting_key_enum,
        published_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null,
        draft_version?: {
          __typename: 'question_setting_version',
          id: number,
          question_id: number,
          key: question_setting_key_enum,
          value: any
        } | null
      }>
    }
  } | null
};

export type UpdateFlowLanguageSettingsMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  defaultLanguage: language_enum;
  flowLanguages: Array<flow_language_insert_input> | flow_language_insert_input;
}>;


export type UpdateFlowLanguageSettingsMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>
  } | null,
  delete_flow_language?: {
    __typename: 'flow_language_mutation_response',
    affected_rows: number
  } | null,
  insert_flow_language?: {
    __typename: 'flow_language_mutation_response',
    returning: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum,
      flow: {
        __typename: 'flow',
        id: number,
        flow_languages: Array<{
          __typename: 'flow_language',
          flow_id: number,
          language: language_enum
        }>
      }
    }>
  } | null
};

export type UpdateOfferRuleGroupVersionMutationVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateOfferRuleGroupVersionMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number,
    title: string,
    published_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CreateOfferRuleGroupDraftExistingVersionQueryVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateOfferRuleGroupDraftExistingVersionQuery = {
  offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number,
    published_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_group_version',
      id: number,
      offer_rule_group_id: number,
      offer_rule_ids: any,
      offer_rule_group_version_offer_rules: Array<{
        __typename: 'offer_rule_group_version_offer_rule',
        offer_rule?: {
          __typename: 'offer_rule',
          id: number,
          published_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_version',
            id: number,
            offer_rule_id: number,
            segment_group_ids: any,
            segment_ids: any,
            question_option_ids: any,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            include_present_no_offer: boolean,
            include_other_in_question_ids: any,
            offer_rule_rule_ids: any,
            offer_rule_version_segment_groups: Array<{
              __typename: 'offer_rule_version_segment_group',
              offer_rule_version_id?: number | null,
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            offer_rule_version_segments: Array<{
              __typename: 'offer_rule_version_segment',
              offer_rule_version_id?: number | null,
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            offer_rule_version_question_options: Array<{
              __typename: 'offer_rule_version_question_option',
              offer_rule_version_id?: number | null,
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            offer_rule_version_offer_rule_rules: Array<{
              __typename: 'offer_rule_version_offer_rule_rule',
              offer_rule_version_id?: number | null,
              offer_rule_rule_id?: number | null,
              offer_rule_rule?: {
                __typename: 'offer_rule_rule',
                id: number,
                offer_rule_id: number,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                deflection_ids: any,
                include_present_no_offer: boolean,
                weight: number,
                offer_rule_rule_offers: Array<{
                  __typename: 'offer_rule_rule_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_rule_offer_tests: Array<{
                  __typename: 'offer_rule_rule_offer_test',
                  offer_rule_rule_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_rule_offer_groups: Array<{
                  __typename: 'offer_rule_rule_offer_group',
                  offer_rule_rule_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_rule_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_rule_offer_autopilot_offer',
                  offer_rule_rule_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_rule_deflections: Array<{
                  __typename: 'offer_rule_rule_deflection',
                  offer_rule_rule_id?: number | null,
                  deflection_id?: number | null,
                  deflection?: {
                    __typename: 'deflection',
                    id: number,
                    title: string,
                    token: string,
                    minimum_items?: number | null,
                    heading_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    deflection_snapshot_items: Array<{
                      __typename: 'deflection_snapshot_item_position',
                      position?: number | null,
                      deflection_snapshot_item?: {
                        __typename: 'deflection_snapshot_item',
                        id: number,
                        token: string,
                        icon_prefix?: string | null,
                        icon_name?: string | null,
                        property_id?: number | null,
                        property_condition_value?: any | null,
                        date_format?: string | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        property?: {
                          __typename: 'property',
                          id: number,
                          name: string,
                          entity: property_entity_enum,
                          type: property_type_enum,
                          format?: property_format_enum | null
                        } | null
                      } | null
                    }>,
                    deflection_flow_actions: Array<{
                      __typename: 'deflection_flow_action',
                      position?: number | null,
                      flow_action?: {
                        __typename: 'flow_action',
                        id: number,
                        token: string,
                        code?: string | null,
                        type: flow_action_type_enum,
                        account_id: number,
                        appearance: flow_action_appearance_enum,
                        url?: string | null,
                        reroute_to_flow_id?: number | null,
                        text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_version_offers: Array<{
              __typename: 'offer_rule_version_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_version_offer_tests: Array<{
              __typename: 'offer_rule_version_offer_test',
              offer_rule_version_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_version_offer_groups: Array<{
              __typename: 'offer_rule_version_offer_group',
              offer_rule_version_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_version_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_version_offer_autopilot_offer',
              offer_rule_version_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CreateOfferRuleGroupDraftNewVersionMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateOfferRuleGroupDraftNewVersionMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number,
    offer_rule_ids: any,
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        id: number,
        published_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          id: number,
          offer_rule_id: number,
          segment_group_ids: any,
          segment_ids: any,
          question_option_ids: any,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          include_present_no_offer: boolean,
          include_other_in_question_ids: any,
          offer_rule_rule_ids: any,
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          offer_rule_version_question_options: Array<{
            __typename: 'offer_rule_version_question_option',
            offer_rule_version_id?: number | null,
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          offer_rule_version_offer_rule_rules: Array<{
            __typename: 'offer_rule_version_offer_rule_rule',
            offer_rule_version_id?: number | null,
            offer_rule_rule_id?: number | null,
            offer_rule_rule?: {
              __typename: 'offer_rule_rule',
              id: number,
              offer_rule_id: number,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              deflection_ids: any,
              include_present_no_offer: boolean,
              weight: number,
              offer_rule_rule_offers: Array<{
                __typename: 'offer_rule_rule_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_rule_offer_tests: Array<{
                __typename: 'offer_rule_rule_offer_test',
                offer_rule_rule_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_rule_offer_groups: Array<{
                __typename: 'offer_rule_rule_offer_group',
                offer_rule_rule_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_rule_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_rule_offer_autopilot_offer',
                offer_rule_rule_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_rule_deflections: Array<{
                __typename: 'offer_rule_rule_deflection',
                offer_rule_rule_id?: number | null,
                deflection_id?: number | null,
                deflection?: {
                  __typename: 'deflection',
                  id: number,
                  title: string,
                  token: string,
                  minimum_items?: number | null,
                  heading_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  deflection_snapshot_items: Array<{
                    __typename: 'deflection_snapshot_item_position',
                    position?: number | null,
                    deflection_snapshot_item?: {
                      __typename: 'deflection_snapshot_item',
                      id: number,
                      token: string,
                      icon_prefix?: string | null,
                      icon_name?: string | null,
                      property_id?: number | null,
                      property_condition_value?: any | null,
                      date_format?: string | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      property?: {
                        __typename: 'property',
                        id: number,
                        name: string,
                        entity: property_entity_enum,
                        type: property_type_enum,
                        format?: property_format_enum | null
                      } | null
                    } | null
                  }>,
                  deflection_flow_actions: Array<{
                    __typename: 'deflection_flow_action',
                    position?: number | null,
                    flow_action?: {
                      __typename: 'flow_action',
                      id: number,
                      token: string,
                      code?: string | null,
                      type: flow_action_type_enum,
                      account_id: number,
                      appearance: flow_action_appearance_enum,
                      url?: string | null,
                      reroute_to_flow_id?: number | null,
                      text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_version_offers: Array<{
            __typename: 'offer_rule_version_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_version_offer_tests: Array<{
            __typename: 'offer_rule_version_offer_test',
            offer_rule_version_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_version_offer_groups: Array<{
            __typename: 'offer_rule_version_offer_group',
            offer_rule_version_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_version_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_version_offer_autopilot_offer',
            offer_rule_version_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type UpdateOfferRuleVersionMutationVariables = Exact<{
  offerRuleId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateOfferRuleVersionMutation = {
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number,
    published_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null
  } | null
};

export type CreateOfferRuleDraftExistingVersionQueryVariables = Exact<{
  offerRuleId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateOfferRuleDraftExistingVersionQuery = {
  offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number,
    published_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null
  } | null
};

export type CreateOfferRuleFraftNewVersionMutationVariables = Exact<{
  object: offer_rule_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateOfferRuleFraftNewVersionMutation = {
  insert_offer_rule_version_one?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number,
    segment_group_ids: any,
    segment_ids: any,
    question_option_ids: any,
    offer_ids: any,
    offer_test_ids: any,
    offer_group_ids: any,
    offer_autopilot_offer_ids: any,
    include_present_no_offer: boolean,
    include_other_in_question_ids: any,
    offer_rule_rule_ids: any,
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    offer_rule_version_question_options: Array<{
      __typename: 'offer_rule_version_question_option',
      offer_rule_version_id?: number | null,
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>,
    offer_rule_version_offer_rule_rules: Array<{
      __typename: 'offer_rule_version_offer_rule_rule',
      offer_rule_version_id?: number | null,
      offer_rule_rule_id?: number | null,
      offer_rule_rule?: {
        __typename: 'offer_rule_rule',
        id: number,
        offer_rule_id: number,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        deflection_ids: any,
        include_present_no_offer: boolean,
        weight: number,
        offer_rule_rule_offers: Array<{
          __typename: 'offer_rule_rule_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_rule_offer_tests: Array<{
          __typename: 'offer_rule_rule_offer_test',
          offer_rule_rule_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_rule_offer_groups: Array<{
          __typename: 'offer_rule_rule_offer_group',
          offer_rule_rule_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_rule_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_rule_offer_autopilot_offer',
          offer_rule_rule_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_rule_deflections: Array<{
          __typename: 'offer_rule_rule_deflection',
          offer_rule_rule_id?: number | null,
          deflection_id?: number | null,
          deflection?: {
            __typename: 'deflection',
            id: number,
            title: string,
            token: string,
            minimum_items?: number | null,
            heading_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            deflection_snapshot_items: Array<{
              __typename: 'deflection_snapshot_item_position',
              position?: number | null,
              deflection_snapshot_item?: {
                __typename: 'deflection_snapshot_item',
                id: number,
                token: string,
                icon_prefix?: string | null,
                icon_name?: string | null,
                property_id?: number | null,
                property_condition_value?: any | null,
                date_format?: string | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>,
            deflection_flow_actions: Array<{
              __typename: 'deflection_flow_action',
              position?: number | null,
              flow_action?: {
                __typename: 'flow_action',
                id: number,
                token: string,
                code?: string | null,
                type: flow_action_type_enum,
                account_id: number,
                appearance: flow_action_appearance_enum,
                url?: string | null,
                reroute_to_flow_id?: number | null,
                text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          } | null
        }>
      } | null
    }>,
    offer_rule_version_offers: Array<{
      __typename: 'offer_rule_version_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        type: offer_type_enum
      } | null
    }>,
    offer_rule_version_offer_tests: Array<{
      __typename: 'offer_rule_version_offer_test',
      offer_rule_version_id?: number | null,
      offer_test_id?: number | null,
      offer_test?: {
        __typename: 'offer_test',
        id: number,
        name: string,
        token: string,
        goal: offer_goal_enum,
        control_weight: number,
        control_offer: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        },
        offer_test_offers: Array<{
          __typename: 'offer_test_offer',
          offer_test_id: number,
          offer_id: number,
          weight: number,
          offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          }
        }>,
        winning_offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      } | null
    }>,
    offer_rule_version_offer_groups: Array<{
      __typename: 'offer_rule_version_offer_group',
      offer_rule_version_id?: number | null,
      offer_group_id?: number | null,
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string,
        token: string,
        show_images: boolean,
        randomize: boolean,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            name: string,
            show_banner: boolean,
            image_url?: string | null,
            goal: offer_goal_enum,
            style: offer_style_enum,
            created_at: string,
            updated_at: string,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_coupon?: {
              __typename: 'offer_coupon',
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null,
              months?: number | null
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              modify_at: offer_modify_subscription_modify_at_enum,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_connection_id: number,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              }>
            } | null,
            offer_upgrade?: {
              __typename: 'offer_upgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_downgrade?: {
              __typename: 'offer_downgrade',
              platform_plan_id?: string | null,
              change_at: offer_change_plan_change_at_enum
            } | null,
            offer_product_swap?: {
              __typename: 'offer_product_swap',
              specific_products: boolean,
              offer_product_swap_platform_ecommerce_products: Array<{
                __typename: 'offer_product_swap_platform_ecommerce_product',
                platform_ecommerce_product_id: string,
                platform_ecommerce_product?: {
                  __typename: 'platform_ecommerce_product',
                  platform_id: string,
                  name: string,
                  image_url?: string | null,
                  platform_plan_ecommerce_products: Array<{
                    __typename: 'platform_plan_ecommerce_product',
                    discount_amount?: string | null,
                    discount_type?: string | null
                  }>
                } | null,
                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                  platform_variant?: {
                    __typename: 'platform_variant',
                    platform_id: string,
                    name: string,
                    price?: string | null,
                    platform_variant_options: Array<{
                      __typename: 'platform_variant_option',
                      key: string,
                      value: string
                    }>
                  } | null
                }>
              }>
            } | null,
            offer_reschedule_order?: {
              __typename: 'offer_reschedule_order',
              skip: boolean,
              reschedule: boolean,
              skip_button_text_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              reschedule_button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            offer_custom?: {
              __typename: 'offer_custom',
              link_href?: string | null,
              link_text?: string | null
            } | null,
            offer_timer?: {
              __typename: 'offer_timer',
              offer_timer_mode: offer_timer_mode_enum,
              start_time?: string | null,
              end_time?: string | null,
              duration?: string | null,
              show_timer?: boolean | null
            } | null
          } | null
        }>,
        headline_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        cancel_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>,
    offer_rule_version_offer_autopilot_offers: Array<{
      __typename: 'offer_rule_version_offer_autopilot_offer',
      offer_rule_version_id?: number | null,
      offer_id?: number | null,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        offer_id: number,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null,
        current_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          offer_id: number,
          control_weight: number,
          control_variant_id: number,
          winning_variant_id?: number | null,
          offer_autopilot_round_offer_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null,
        winning_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null,
        offer_autopilot_offer_autopilot_rounds: Array<{
          __typename: 'offer_autopilot_offer_autopilot_round',
          offer_autopilot_round_id?: number | null,
          round_index?: number | null,
          offer_autopilot_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null
        }>
      } | null
    }>
  } | null
};

export type AddOfferRuleMutationVariables = Exact<{
  object: offer_rule_version_insert_input;
}>;


export type AddOfferRuleMutation = {
  insert_offer_rule_version_one?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number
  } | null
};

export type AddOfferRuleSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
  offerRuleGroupVersionId: Scalars['Int']['input'];
  appendId: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddOfferRuleSetVersionMutation = {
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number,
    published_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'offer_rule_version',
      id: number,
      offer_rule_id: number,
      segment_group_ids: any,
      segment_ids: any,
      question_option_ids: any,
      offer_ids: any,
      offer_test_ids: any,
      offer_group_ids: any,
      offer_autopilot_offer_ids: any,
      include_present_no_offer: boolean,
      include_other_in_question_ids: any,
      offer_rule_rule_ids: any,
      offer_rule_version_segment_groups: Array<{
        __typename: 'offer_rule_version_segment_group',
        offer_rule_version_id?: number | null,
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      offer_rule_version_segments: Array<{
        __typename: 'offer_rule_version_segment',
        offer_rule_version_id?: number | null,
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      offer_rule_version_question_options: Array<{
        __typename: 'offer_rule_version_question_option',
        offer_rule_version_id?: number | null,
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>,
      offer_rule_version_offer_rule_rules: Array<{
        __typename: 'offer_rule_version_offer_rule_rule',
        offer_rule_version_id?: number | null,
        offer_rule_rule_id?: number | null,
        offer_rule_rule?: {
          __typename: 'offer_rule_rule',
          id: number,
          offer_rule_id: number,
          offer_ids: any,
          offer_test_ids: any,
          offer_group_ids: any,
          offer_autopilot_offer_ids: any,
          deflection_ids: any,
          include_present_no_offer: boolean,
          weight: number,
          offer_rule_rule_offers: Array<{
            __typename: 'offer_rule_rule_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          }>,
          offer_rule_rule_offer_tests: Array<{
            __typename: 'offer_rule_rule_offer_test',
            offer_rule_rule_id?: number | null,
            offer_test_id?: number | null,
            offer_test?: {
              __typename: 'offer_test',
              id: number,
              name: string,
              token: string,
              goal: offer_goal_enum,
              control_weight: number,
              control_offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              },
              offer_test_offers: Array<{
                __typename: 'offer_test_offer',
                offer_test_id: number,
                offer_id: number,
                weight: number,
                offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                }
              }>,
              winning_offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            } | null
          }>,
          offer_rule_rule_offer_groups: Array<{
            __typename: 'offer_rule_rule_offer_group',
            offer_rule_rule_id?: number | null,
            offer_group_id?: number | null,
            offer_group?: {
              __typename: 'offer_group',
              id: number,
              name: string,
              token: string,
              show_images: boolean,
              randomize: boolean,
              offer_group_offers: Array<{
                __typename: 'offer_group_offer',
                position?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  type: offer_type_enum,
                  name: string,
                  show_banner: boolean,
                  image_url?: string | null,
                  goal: offer_goal_enum,
                  style: offer_style_enum,
                  created_at: string,
                  updated_at: string,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null,
                    months?: number | null
                  } | null,
                  offer_change_plan?: {
                    __typename: 'offer_change_plan',
                    platform_plan_id?: string | null,
                    prorate: boolean,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_modify_subscription?: {
                    __typename: 'offer_modify_subscription',
                    prorate: boolean,
                    modify_at: offer_modify_subscription_modify_at_enum,
                    offer_modify_subscription_options: Array<{
                      __typename: 'offer_modify_subscription_option',
                      type: offer_modify_subscription_option_type_enum,
                      platform_connection_id: number,
                      platform_plan_id?: string | null,
                      platform_addon_id?: string | null
                    }>
                  } | null,
                  offer_trial_extension?: {
                    __typename: 'offer_trial_extension',
                    days: number
                  } | null,
                  offer_pause_subscription?: {
                    __typename: 'offer_pause_subscription',
                    offer_pause_subscription_options: Array<{
                      __typename: 'offer_pause_subscription_option',
                      type: offer_pause_subscription_option_type_enum,
                      interval?: offer_pause_subscription_interval_enum | null,
                      interval_count?: number | null,
                      text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    }>
                  } | null,
                  offer_upgrade?: {
                    __typename: 'offer_upgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_downgrade?: {
                    __typename: 'offer_downgrade',
                    platform_plan_id?: string | null,
                    change_at: offer_change_plan_change_at_enum
                  } | null,
                  offer_product_swap?: {
                    __typename: 'offer_product_swap',
                    specific_products: boolean,
                    offer_product_swap_platform_ecommerce_products: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product',
                      platform_ecommerce_product_id: string,
                      platform_ecommerce_product?: {
                        __typename: 'platform_ecommerce_product',
                        platform_id: string,
                        name: string,
                        image_url?: string | null,
                        platform_plan_ecommerce_products: Array<{
                          __typename: 'platform_plan_ecommerce_product',
                          discount_amount?: string | null,
                          discount_type?: string | null
                        }>
                      } | null,
                      offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                        platform_variant?: {
                          __typename: 'platform_variant',
                          platform_id: string,
                          name: string,
                          price?: string | null,
                          platform_variant_options: Array<{
                            __typename: 'platform_variant_option',
                            key: string,
                            value: string
                          }>
                        } | null
                      }>
                    }>
                  } | null,
                  offer_reschedule_order?: {
                    __typename: 'offer_reschedule_order',
                    skip: boolean,
                    reschedule: boolean,
                    skip_button_text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    reschedule_button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  offer_custom?: {
                    __typename: 'offer_custom',
                    link_href?: string | null,
                    link_text?: string | null
                  } | null,
                  offer_timer?: {
                    __typename: 'offer_timer',
                    offer_timer_mode: offer_timer_mode_enum,
                    start_time?: string | null,
                    end_time?: string | null,
                    duration?: string | null,
                    show_timer?: boolean | null
                  } | null
                } | null
              }>,
              headline_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>,
          offer_rule_rule_offer_autopilot_offers: Array<{
            __typename: 'offer_rule_rule_offer_autopilot_offer',
            offer_rule_rule_id?: number | null,
            offer_id?: number | null,
            offer_autopilot?: {
              __typename: 'offer_autopilot',
              offer_id: number,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null,
              current_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null,
              winning_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null,
              offer_autopilot_offer_autopilot_rounds: Array<{
                __typename: 'offer_autopilot_offer_autopilot_round',
                offer_autopilot_round_id?: number | null,
                round_index?: number | null,
                offer_autopilot_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null
              }>
            } | null
          }>,
          offer_rule_rule_deflections: Array<{
            __typename: 'offer_rule_rule_deflection',
            offer_rule_rule_id?: number | null,
            deflection_id?: number | null,
            deflection?: {
              __typename: 'deflection',
              id: number,
              title: string,
              token: string,
              minimum_items?: number | null,
              heading_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              deflection_snapshot_items: Array<{
                __typename: 'deflection_snapshot_item_position',
                position?: number | null,
                deflection_snapshot_item?: {
                  __typename: 'deflection_snapshot_item',
                  id: number,
                  token: string,
                  icon_prefix?: string | null,
                  icon_name?: string | null,
                  property_id?: number | null,
                  property_condition_value?: any | null,
                  date_format?: string | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>,
              deflection_flow_actions: Array<{
                __typename: 'deflection_flow_action',
                position?: number | null,
                flow_action?: {
                  __typename: 'flow_action',
                  id: number,
                  token: string,
                  code?: string | null,
                  type: flow_action_type_enum,
                  account_id: number,
                  appearance: flow_action_appearance_enum,
                  url?: string | null,
                  reroute_to_flow_id?: number | null,
                  text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>
            } | null
          }>
        } | null
      }>,
      offer_rule_version_offers: Array<{
        __typename: 'offer_rule_version_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          name: string,
          type: offer_type_enum
        } | null
      }>,
      offer_rule_version_offer_tests: Array<{
        __typename: 'offer_rule_version_offer_test',
        offer_rule_version_id?: number | null,
        offer_test_id?: number | null,
        offer_test?: {
          __typename: 'offer_test',
          id: number,
          name: string,
          token: string,
          goal: offer_goal_enum,
          control_weight: number,
          control_offer: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          },
          offer_test_offers: Array<{
            __typename: 'offer_test_offer',
            offer_test_id: number,
            offer_id: number,
            weight: number,
            offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            }
          }>,
          winning_offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        } | null
      }>,
      offer_rule_version_offer_groups: Array<{
        __typename: 'offer_rule_version_offer_group',
        offer_rule_version_id?: number | null,
        offer_group_id?: number | null,
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string,
          token: string,
          show_images: boolean,
          randomize: boolean,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              name: string,
              show_banner: boolean,
              image_url?: string | null,
              goal: offer_goal_enum,
              style: offer_style_enum,
              created_at: string,
              updated_at: string,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_coupon?: {
                __typename: 'offer_coupon',
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null,
                months?: number | null
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                modify_at: offer_modify_subscription_modify_at_enum,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_connection_id: number,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null
                }>
              } | null,
              offer_upgrade?: {
                __typename: 'offer_upgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_downgrade?: {
                __typename: 'offer_downgrade',
                platform_plan_id?: string | null,
                change_at: offer_change_plan_change_at_enum
              } | null,
              offer_product_swap?: {
                __typename: 'offer_product_swap',
                specific_products: boolean,
                offer_product_swap_platform_ecommerce_products: Array<{
                  __typename: 'offer_product_swap_platform_ecommerce_product',
                  platform_ecommerce_product_id: string,
                  platform_ecommerce_product?: {
                    __typename: 'platform_ecommerce_product',
                    platform_id: string,
                    name: string,
                    image_url?: string | null,
                    platform_plan_ecommerce_products: Array<{
                      __typename: 'platform_plan_ecommerce_product',
                      discount_amount?: string | null,
                      discount_type?: string | null
                    }>
                  } | null,
                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                    platform_variant?: {
                      __typename: 'platform_variant',
                      platform_id: string,
                      name: string,
                      price?: string | null,
                      platform_variant_options: Array<{
                        __typename: 'platform_variant_option',
                        key: string,
                        value: string
                      }>
                    } | null
                  }>
                }>
              } | null,
              offer_reschedule_order?: {
                __typename: 'offer_reschedule_order',
                skip: boolean,
                reschedule: boolean,
                skip_button_text_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                reschedule_button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              offer_custom?: {
                __typename: 'offer_custom',
                link_href?: string | null,
                link_text?: string | null
              } | null,
              offer_timer?: {
                __typename: 'offer_timer',
                offer_timer_mode: offer_timer_mode_enum,
                start_time?: string | null,
                end_time?: string | null,
                duration?: string | null,
                show_timer?: boolean | null
              } | null
            } | null
          }>,
          headline_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>,
      offer_rule_version_offer_autopilot_offers: Array<{
        __typename: 'offer_rule_version_offer_autopilot_offer',
        offer_rule_version_id?: number | null,
        offer_id?: number | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      }>
    } | null
  } | null,
  update_offer_rule_group_version_by_pk?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      title: string,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateRuleRulesMutationVariables = Exact<{
  objects: Array<offer_rule_rule_insert_input> | offer_rule_rule_insert_input;
}>;


export type UpdateRuleRulesMutation = {
  insert_offer_rule_rule?: {
    __typename: 'offer_rule_rule_mutation_response',
    returning: Array<{
      __typename: 'offer_rule_rule',
      id: number
    }>
  } | null
};

export type AddOfferRuleInlineSegmentsMutationVariables = Exact<{
  objects: Array<segment_insert_input> | segment_insert_input;
}>;


export type AddOfferRuleInlineSegmentsMutation = {
  insert_segment?: {
    __typename: 'segment_mutation_response',
    returning: Array<{
      __typename: 'segment',
      id: number,
      primary_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null
    }>
  } | null
};

export type UpdateRuleMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  update: offer_rule_version_set_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateRuleMutation = {
  update_offer_rule_version_by_pk?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule: {
      __typename: 'offer_rule',
      id: number,
      published_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_rule_rule_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_rule_rule_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_rule_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_rule_offer_autopilot_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_version_offers: Array<{
          __typename: 'offer_rule_version_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_version_offer_tests: Array<{
          __typename: 'offer_rule_version_offer_test',
          offer_rule_version_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_version_offer_groups: Array<{
          __typename: 'offer_rule_version_offer_group',
          offer_rule_version_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_version_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_version_offer_autopilot_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_version',
        id: number,
        offer_rule_id: number,
        segment_group_ids: any,
        segment_ids: any,
        question_option_ids: any,
        offer_ids: any,
        offer_test_ids: any,
        offer_group_ids: any,
        offer_autopilot_offer_ids: any,
        include_present_no_offer: boolean,
        include_other_in_question_ids: any,
        offer_rule_rule_ids: any,
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        offer_rule_version_question_options: Array<{
          __typename: 'offer_rule_version_question_option',
          offer_rule_version_id?: number | null,
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>,
        offer_rule_version_offer_rule_rules: Array<{
          __typename: 'offer_rule_version_offer_rule_rule',
          offer_rule_version_id?: number | null,
          offer_rule_rule_id?: number | null,
          offer_rule_rule?: {
            __typename: 'offer_rule_rule',
            id: number,
            offer_rule_id: number,
            offer_ids: any,
            offer_test_ids: any,
            offer_group_ids: any,
            offer_autopilot_offer_ids: any,
            deflection_ids: any,
            include_present_no_offer: boolean,
            weight: number,
            offer_rule_rule_offers: Array<{
              __typename: 'offer_rule_rule_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              } | null
            }>,
            offer_rule_rule_offer_tests: Array<{
              __typename: 'offer_rule_rule_offer_test',
              offer_rule_rule_id?: number | null,
              offer_test_id?: number | null,
              offer_test?: {
                __typename: 'offer_test',
                id: number,
                name: string,
                token: string,
                goal: offer_goal_enum,
                control_weight: number,
                control_offer: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                },
                offer_test_offers: Array<{
                  __typename: 'offer_test_offer',
                  offer_test_id: number,
                  offer_id: number,
                  weight: number,
                  offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  }
                }>,
                winning_offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              } | null
            }>,
            offer_rule_rule_offer_groups: Array<{
              __typename: 'offer_rule_rule_offer_group',
              offer_rule_rule_id?: number | null,
              offer_group_id?: number | null,
              offer_group?: {
                __typename: 'offer_group',
                id: number,
                name: string,
                token: string,
                show_images: boolean,
                randomize: boolean,
                offer_group_offers: Array<{
                  __typename: 'offer_group_offer',
                  position?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    type: offer_type_enum,
                    name: string,
                    show_banner: boolean,
                    image_url?: string | null,
                    goal: offer_goal_enum,
                    style: offer_style_enum,
                    created_at: string,
                    updated_at: string,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_coupon?: {
                      __typename: 'offer_coupon',
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null,
                      months?: number | null
                    } | null,
                    offer_change_plan?: {
                      __typename: 'offer_change_plan',
                      platform_plan_id?: string | null,
                      prorate: boolean,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_modify_subscription?: {
                      __typename: 'offer_modify_subscription',
                      prorate: boolean,
                      modify_at: offer_modify_subscription_modify_at_enum,
                      offer_modify_subscription_options: Array<{
                        __typename: 'offer_modify_subscription_option',
                        type: offer_modify_subscription_option_type_enum,
                        platform_connection_id: number,
                        platform_plan_id?: string | null,
                        platform_addon_id?: string | null
                      }>
                    } | null,
                    offer_trial_extension?: {
                      __typename: 'offer_trial_extension',
                      days: number
                    } | null,
                    offer_pause_subscription?: {
                      __typename: 'offer_pause_subscription',
                      offer_pause_subscription_options: Array<{
                        __typename: 'offer_pause_subscription_option',
                        type: offer_pause_subscription_option_type_enum,
                        interval?: offer_pause_subscription_interval_enum | null,
                        interval_count?: number | null,
                        text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null
                      }>
                    } | null,
                    offer_upgrade?: {
                      __typename: 'offer_upgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_downgrade?: {
                      __typename: 'offer_downgrade',
                      platform_plan_id?: string | null,
                      change_at: offer_change_plan_change_at_enum
                    } | null,
                    offer_product_swap?: {
                      __typename: 'offer_product_swap',
                      specific_products: boolean,
                      offer_product_swap_platform_ecommerce_products: Array<{
                        __typename: 'offer_product_swap_platform_ecommerce_product',
                        platform_ecommerce_product_id: string,
                        platform_ecommerce_product?: {
                          __typename: 'platform_ecommerce_product',
                          platform_id: string,
                          name: string,
                          image_url?: string | null,
                          platform_plan_ecommerce_products: Array<{
                            __typename: 'platform_plan_ecommerce_product',
                            discount_amount?: string | null,
                            discount_type?: string | null
                          }>
                        } | null,
                        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                          platform_variant?: {
                            __typename: 'platform_variant',
                            platform_id: string,
                            name: string,
                            price?: string | null,
                            platform_variant_options: Array<{
                              __typename: 'platform_variant_option',
                              key: string,
                              value: string
                            }>
                          } | null
                        }>
                      }>
                    } | null,
                    offer_reschedule_order?: {
                      __typename: 'offer_reschedule_order',
                      skip: boolean,
                      reschedule: boolean,
                      skip_button_text_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      reschedule_button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    offer_custom?: {
                      __typename: 'offer_custom',
                      link_href?: string | null,
                      link_text?: string | null
                    } | null,
                    offer_timer?: {
                      __typename: 'offer_timer',
                      offer_timer_mode: offer_timer_mode_enum,
                      start_time?: string | null,
                      end_time?: string | null,
                      duration?: string | null,
                      show_timer?: boolean | null
                    } | null
                  } | null
                }>,
                headline_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>,
            offer_rule_rule_offer_autopilot_offers: Array<{
              __typename: 'offer_rule_rule_offer_autopilot_offer',
              offer_rule_rule_id?: number | null,
              offer_id?: number | null,
              offer_autopilot?: {
                __typename: 'offer_autopilot',
                offer_id: number,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null,
                current_round?: {
                  __typename: 'offer_autopilot_round',
                  id: number,
                  offer_id: number,
                  control_weight: number,
                  control_variant_id: number,
                  winning_variant_id?: number | null,
                  offer_autopilot_round_offer_variants: Array<{
                    __typename: 'offer_autopilot_round_offer_variant',
                    offer_autopilot_round_id?: number | null,
                    offer_variant_id?: number | null,
                    offer_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null
                  }>
                } | null,
                winning_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null,
                offer_autopilot_offer_autopilot_rounds: Array<{
                  __typename: 'offer_autopilot_offer_autopilot_round',
                  offer_autopilot_round_id?: number | null,
                  round_index?: number | null,
                  offer_autopilot_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null
                }>
              } | null
            }>,
            offer_rule_rule_deflections: Array<{
              __typename: 'offer_rule_rule_deflection',
              offer_rule_rule_id?: number | null,
              deflection_id?: number | null,
              deflection?: {
                __typename: 'deflection',
                id: number,
                title: string,
                token: string,
                minimum_items?: number | null,
                heading_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                deflection_snapshot_items: Array<{
                  __typename: 'deflection_snapshot_item_position',
                  position?: number | null,
                  deflection_snapshot_item?: {
                    __typename: 'deflection_snapshot_item',
                    id: number,
                    token: string,
                    icon_prefix?: string | null,
                    icon_name?: string | null,
                    property_id?: number | null,
                    property_condition_value?: any | null,
                    date_format?: string | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>,
                deflection_flow_actions: Array<{
                  __typename: 'deflection_flow_action',
                  position?: number | null,
                  flow_action?: {
                    __typename: 'flow_action',
                    id: number,
                    token: string,
                    code?: string | null,
                    type: flow_action_type_enum,
                    account_id: number,
                    appearance: flow_action_appearance_enum,
                    url?: string | null,
                    reroute_to_flow_id?: number | null,
                    text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>
              } | null
            }>
          } | null
        }>,
        offer_rule_version_offers: Array<{
          __typename: 'offer_rule_version_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null
        }>,
        offer_rule_version_offer_tests: Array<{
          __typename: 'offer_rule_version_offer_test',
          offer_rule_version_id?: number | null,
          offer_test_id?: number | null,
          offer_test?: {
            __typename: 'offer_test',
            id: number,
            name: string,
            token: string,
            goal: offer_goal_enum,
            control_weight: number,
            control_offer: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            },
            offer_test_offers: Array<{
              __typename: 'offer_test_offer',
              offer_test_id: number,
              offer_id: number,
              weight: number,
              offer: {
                __typename: 'offer',
                id: number,
                token: string,
                name: string,
                type: offer_type_enum
              }
            }>,
            winning_offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null
          } | null
        }>,
        offer_rule_version_offer_groups: Array<{
          __typename: 'offer_rule_version_offer_group',
          offer_rule_version_id?: number | null,
          offer_group_id?: number | null,
          offer_group?: {
            __typename: 'offer_group',
            id: number,
            name: string,
            token: string,
            show_images: boolean,
            randomize: boolean,
            offer_group_offers: Array<{
              __typename: 'offer_group_offer',
              position?: number | null,
              offer?: {
                __typename: 'offer',
                id: number,
                token: string,
                type: offer_type_enum,
                name: string,
                show_banner: boolean,
                image_url?: string | null,
                goal: offer_goal_enum,
                style: offer_style_enum,
                created_at: string,
                updated_at: string,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null,
                  months?: number | null
                } | null,
                offer_change_plan?: {
                  __typename: 'offer_change_plan',
                  platform_plan_id?: string | null,
                  prorate: boolean,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_modify_subscription?: {
                  __typename: 'offer_modify_subscription',
                  prorate: boolean,
                  modify_at: offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: Array<{
                    __typename: 'offer_modify_subscription_option',
                    type: offer_modify_subscription_option_type_enum,
                    platform_connection_id: number,
                    platform_plan_id?: string | null,
                    platform_addon_id?: string | null
                  }>
                } | null,
                offer_trial_extension?: {
                  __typename: 'offer_trial_extension',
                  days: number
                } | null,
                offer_pause_subscription?: {
                  __typename: 'offer_pause_subscription',
                  offer_pause_subscription_options: Array<{
                    __typename: 'offer_pause_subscription_option',
                    type: offer_pause_subscription_option_type_enum,
                    interval?: offer_pause_subscription_interval_enum | null,
                    interval_count?: number | null,
                    text_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null
                  }>
                } | null,
                offer_upgrade?: {
                  __typename: 'offer_upgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_downgrade?: {
                  __typename: 'offer_downgrade',
                  platform_plan_id?: string | null,
                  change_at: offer_change_plan_change_at_enum
                } | null,
                offer_product_swap?: {
                  __typename: 'offer_product_swap',
                  specific_products: boolean,
                  offer_product_swap_platform_ecommerce_products: Array<{
                    __typename: 'offer_product_swap_platform_ecommerce_product',
                    platform_ecommerce_product_id: string,
                    platform_ecommerce_product?: {
                      __typename: 'platform_ecommerce_product',
                      platform_id: string,
                      name: string,
                      image_url?: string | null,
                      platform_plan_ecommerce_products: Array<{
                        __typename: 'platform_plan_ecommerce_product',
                        discount_amount?: string | null,
                        discount_type?: string | null
                      }>
                    } | null,
                    offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                      __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                      platform_variant?: {
                        __typename: 'platform_variant',
                        platform_id: string,
                        name: string,
                        price?: string | null,
                        platform_variant_options: Array<{
                          __typename: 'platform_variant_option',
                          key: string,
                          value: string
                        }>
                      } | null
                    }>
                  }>
                } | null,
                offer_reschedule_order?: {
                  __typename: 'offer_reschedule_order',
                  skip: boolean,
                  reschedule: boolean,
                  skip_button_text_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  reschedule_button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                offer_custom?: {
                  __typename: 'offer_custom',
                  link_href?: string | null,
                  link_text?: string | null
                } | null,
                offer_timer?: {
                  __typename: 'offer_timer',
                  offer_timer_mode: offer_timer_mode_enum,
                  start_time?: string | null,
                  end_time?: string | null,
                  duration?: string | null,
                  show_timer?: boolean | null
                } | null
              } | null
            }>,
            headline_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }>,
        offer_rule_version_offer_autopilot_offers: Array<{
          __typename: 'offer_rule_version_offer_autopilot_offer',
          offer_rule_version_id?: number | null,
          offer_id?: number | null,
          offer_autopilot?: {
            __typename: 'offer_autopilot',
            offer_id: number,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              name: string,
              type: offer_type_enum
            } | null,
            current_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null,
            winning_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              approved_at?: string | null,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null,
            offer_autopilot_offer_autopilot_rounds: Array<{
              __typename: 'offer_autopilot_offer_autopilot_round',
              offer_autopilot_round_id?: number | null,
              round_index?: number | null,
              offer_autopilot_round?: {
                __typename: 'offer_autopilot_round',
                id: number,
                offer_id: number,
                control_weight: number,
                control_variant_id: number,
                winning_variant_id?: number | null,
                offer_autopilot_round_offer_variants: Array<{
                  __typename: 'offer_autopilot_round_offer_variant',
                  offer_autopilot_round_id?: number | null,
                  offer_variant_id?: number | null,
                  offer_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null
                }>
              } | null
            }>
          } | null
        }>
      } | null
    }
  } | null
};

export type DeleteRuleMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  offerRuleIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type DeleteRuleMutation = {
  update_offer_rule_group_version_by_pk?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      title: string,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type MoveRuleMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  offerRuleIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type MoveRuleMutation = {
  update_offer_rule_group_version_by_pk?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      title: string,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_id: number,
        offer_rule_ids: any,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              offer_rule_id: number,
              segment_group_ids: any,
              segment_ids: any,
              question_option_ids: any,
              offer_ids: any,
              offer_test_ids: any,
              offer_group_ids: any,
              offer_autopilot_offer_ids: any,
              include_present_no_offer: boolean,
              include_other_in_question_ids: any,
              offer_rule_rule_ids: any,
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              offer_rule_version_question_options: Array<{
                __typename: 'offer_rule_version_question_option',
                offer_rule_version_id?: number | null,
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              offer_rule_version_offer_rule_rules: Array<{
                __typename: 'offer_rule_version_offer_rule_rule',
                offer_rule_version_id?: number | null,
                offer_rule_rule_id?: number | null,
                offer_rule_rule?: {
                  __typename: 'offer_rule_rule',
                  id: number,
                  offer_rule_id: number,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  deflection_ids: any,
                  include_present_no_offer: boolean,
                  weight: number,
                  offer_rule_rule_offers: Array<{
                    __typename: 'offer_rule_rule_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_rule_offer_tests: Array<{
                    __typename: 'offer_rule_rule_offer_test',
                    offer_rule_rule_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_rule_offer_groups: Array<{
                    __typename: 'offer_rule_rule_offer_group',
                    offer_rule_rule_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_rule_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_rule_offer_autopilot_offer',
                    offer_rule_rule_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_rule_deflections: Array<{
                    __typename: 'offer_rule_rule_deflection',
                    offer_rule_rule_id?: number | null,
                    deflection_id?: number | null,
                    deflection?: {
                      __typename: 'deflection',
                      id: number,
                      title: string,
                      token: string,
                      minimum_items?: number | null,
                      heading_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      deflection_snapshot_items: Array<{
                        __typename: 'deflection_snapshot_item_position',
                        position?: number | null,
                        deflection_snapshot_item?: {
                          __typename: 'deflection_snapshot_item',
                          id: number,
                          token: string,
                          icon_prefix?: string | null,
                          icon_name?: string | null,
                          property_id?: number | null,
                          property_condition_value?: any | null,
                          date_format?: string | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          property?: {
                            __typename: 'property',
                            id: number,
                            name: string,
                            entity: property_entity_enum,
                            type: property_type_enum,
                            format?: property_format_enum | null
                          } | null
                        } | null
                      }>,
                      deflection_flow_actions: Array<{
                        __typename: 'deflection_flow_action',
                        position?: number | null,
                        flow_action?: {
                          __typename: 'flow_action',
                          id: number,
                          token: string,
                          code?: string | null,
                          type: flow_action_type_enum,
                          account_id: number,
                          appearance: flow_action_appearance_enum,
                          url?: string | null,
                          reroute_to_flow_id?: number | null,
                          text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>,
              offer_rule_version_offers: Array<{
                __typename: 'offer_rule_version_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer?: {
                  __typename: 'offer',
                  id: number,
                  token: string,
                  name: string,
                  type: offer_type_enum
                } | null
              }>,
              offer_rule_version_offer_tests: Array<{
                __typename: 'offer_rule_version_offer_test',
                offer_rule_version_id?: number | null,
                offer_test_id?: number | null,
                offer_test?: {
                  __typename: 'offer_test',
                  id: number,
                  name: string,
                  token: string,
                  goal: offer_goal_enum,
                  control_weight: number,
                  control_offer: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  },
                  offer_test_offers: Array<{
                    __typename: 'offer_test_offer',
                    offer_test_id: number,
                    offer_id: number,
                    weight: number,
                    offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    }
                  }>,
                  winning_offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                } | null
              }>,
              offer_rule_version_offer_groups: Array<{
                __typename: 'offer_rule_version_offer_group',
                offer_rule_version_id?: number | null,
                offer_group_id?: number | null,
                offer_group?: {
                  __typename: 'offer_group',
                  id: number,
                  name: string,
                  token: string,
                  show_images: boolean,
                  randomize: boolean,
                  offer_group_offers: Array<{
                    __typename: 'offer_group_offer',
                    position?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      type: offer_type_enum,
                      name: string,
                      show_banner: boolean,
                      image_url?: string | null,
                      goal: offer_goal_enum,
                      style: offer_style_enum,
                      created_at: string,
                      updated_at: string,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_coupon?: {
                        __typename: 'offer_coupon',
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null,
                        months?: number | null
                      } | null,
                      offer_change_plan?: {
                        __typename: 'offer_change_plan',
                        platform_plan_id?: string | null,
                        prorate: boolean,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_modify_subscription?: {
                        __typename: 'offer_modify_subscription',
                        prorate: boolean,
                        modify_at: offer_modify_subscription_modify_at_enum,
                        offer_modify_subscription_options: Array<{
                          __typename: 'offer_modify_subscription_option',
                          type: offer_modify_subscription_option_type_enum,
                          platform_connection_id: number,
                          platform_plan_id?: string | null,
                          platform_addon_id?: string | null
                        }>
                      } | null,
                      offer_trial_extension?: {
                        __typename: 'offer_trial_extension',
                        days: number
                      } | null,
                      offer_pause_subscription?: {
                        __typename: 'offer_pause_subscription',
                        offer_pause_subscription_options: Array<{
                          __typename: 'offer_pause_subscription_option',
                          type: offer_pause_subscription_option_type_enum,
                          interval?: offer_pause_subscription_interval_enum | null,
                          interval_count?: number | null,
                          text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null
                        }>
                      } | null,
                      offer_upgrade?: {
                        __typename: 'offer_upgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_downgrade?: {
                        __typename: 'offer_downgrade',
                        platform_plan_id?: string | null,
                        change_at: offer_change_plan_change_at_enum
                      } | null,
                      offer_product_swap?: {
                        __typename: 'offer_product_swap',
                        specific_products: boolean,
                        offer_product_swap_platform_ecommerce_products: Array<{
                          __typename: 'offer_product_swap_platform_ecommerce_product',
                          platform_ecommerce_product_id: string,
                          platform_ecommerce_product?: {
                            __typename: 'platform_ecommerce_product',
                            platform_id: string,
                            name: string,
                            image_url?: string | null,
                            platform_plan_ecommerce_products: Array<{
                              __typename: 'platform_plan_ecommerce_product',
                              discount_amount?: string | null,
                              discount_type?: string | null
                            }>
                          } | null,
                          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                            platform_variant?: {
                              __typename: 'platform_variant',
                              platform_id: string,
                              name: string,
                              price?: string | null,
                              platform_variant_options: Array<{
                                __typename: 'platform_variant_option',
                                key: string,
                                value: string
                              }>
                            } | null
                          }>
                        }>
                      } | null,
                      offer_reschedule_order?: {
                        __typename: 'offer_reschedule_order',
                        skip: boolean,
                        reschedule: boolean,
                        skip_button_text_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        reschedule_button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      offer_custom?: {
                        __typename: 'offer_custom',
                        link_href?: string | null,
                        link_text?: string | null
                      } | null,
                      offer_timer?: {
                        __typename: 'offer_timer',
                        offer_timer_mode: offer_timer_mode_enum,
                        start_time?: string | null,
                        end_time?: string | null,
                        duration?: string | null,
                        show_timer?: boolean | null
                      } | null
                    } | null
                  }>,
                  headline_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }>,
              offer_rule_version_offer_autopilot_offers: Array<{
                __typename: 'offer_rule_version_offer_autopilot_offer',
                offer_rule_version_id?: number | null,
                offer_id?: number | null,
                offer_autopilot?: {
                  __typename: 'offer_autopilot',
                  offer_id: number,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null,
                  current_round?: {
                    __typename: 'offer_autopilot_round',
                    id: number,
                    offer_id: number,
                    control_weight: number,
                    control_variant_id: number,
                    winning_variant_id?: number | null,
                    offer_autopilot_round_offer_variants: Array<{
                      __typename: 'offer_autopilot_round_offer_variant',
                      offer_autopilot_round_id?: number | null,
                      offer_variant_id?: number | null,
                      offer_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null
                    }>
                  } | null,
                  winning_variant?: {
                    __typename: 'offer_variant',
                    id: number,
                    type: offer_type_enum,
                    offer_id: number,
                    approved_at?: string | null,
                    headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    button_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    offer_variant_coupon?: {
                      __typename: 'offer_variant_coupon',
                      offer_variant_id: number,
                      offer_id: number,
                      type: offer_coupon_type_enum,
                      amount_off: string,
                      duration: coupon_duration_enum,
                      months?: number | null,
                      duration_interval?: coupon_duration_interval_enum | null,
                      duration_count?: number | null
                    } | null
                  } | null,
                  offer_autopilot_offer_autopilot_rounds: Array<{
                    __typename: 'offer_autopilot_offer_autopilot_round',
                    offer_autopilot_round_id?: number | null,
                    round_index?: number | null,
                    offer_autopilot_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateFlowVersionMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateFlowVersionMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number,
    published_version_id?: number | null,
    draft_version_id?: number | null,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type UpdateFlowStepVersionMutationVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateFlowStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  } | null
};

export type CreateFlowDraftExistingVersionQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFlowDraftExistingVersionQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    id: number,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_id: number,
      css?: string | null,
      flow_step_ids: any,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CreateFlowDraftNewVersionMutationVariables = Exact<{
  object: flow_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFlowDraftNewVersionMutation = {
  insert_flow_version_one?: {
    __typename: 'flow_version',
    id: number,
    flow_id: number,
    css?: string | null,
    flow_step_ids: any,
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      } | null
    }>
  } | null
};

export type CreateFlowStepDraftQueryVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFlowStepDraftQuery = {
  flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null
  } | null
};

export type CreateFlowStepDraftNewVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateFlowStepDraftNewVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_id: number,
    condition_segment_group_ids: any,
    condition_segment_ids: any,
    condition_question_option_ids: any,
    condition_include_other_in_question_ids: any,
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    flow_step_version_condition_question_options: Array<{
      __typename: 'flow_step_version_condition_question_option',
      question_option_id?: number | null,
      question_option?: {
        __typename: 'question_option',
        id: number,
        question_id: number,
        published_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      } | null
    }>
  } | null
};

export type AddStepToFlowMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  stepIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddStepToFlowMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    flow: {
      __typename: 'flow',
      id: number,
      published_version?: {
        __typename: 'flow_version',
        id: number,
        flow_id: number,
        css?: string | null,
        flow_step_ids: any,
        flow_version_flow_steps: Array<{
          __typename: 'flow_version_flow_step',
          flow_step?: {
            __typename: 'flow_step',
            id: number,
            token: string,
            type: flow_step_type_enum,
            published_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            flow_step_acknowledgement_group?: {
              __typename: 'flow_step_acknowledgement_group',
              flow_step_id: number,
              acknowledgement_group_id: number,
              acknowledgement_group: {
                __typename: 'acknowledgement_group',
                id: number,
                published_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_form?: {
              __typename: 'flow_step_form',
              flow_step_id: number,
              form_id: number,
              form: {
                __typename: 'form',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_question?: {
              __typename: 'flow_step_question',
              flow_step_id: number,
              question_id: number,
              question: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              }
            } | null,
            flow_step_deflection_rule_group?: {
              __typename: 'flow_step_deflection_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              } | null
            } | null,
            flow_step_offer_rule_group?: {
              __typename: 'flow_step_offer_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              } | null
            } | null,
            flow_step_rule_group?: {
              __typename: 'flow_step_rule_group',
              flow_step_id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              }
            } | null,
            flow_step_confirmation?: {
              __typename: 'flow_step_confirmation',
              flow_step_id: number,
              confirmation_id: number,
              confirmation: {
                __typename: 'confirmation',
                id: number,
                published_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null,
            flow_step_intervention?: {
              __typename: 'flow_step_intervention',
              flow_step_id: number,
              intervention_id: number,
              intervention: {
                __typename: 'intervention',
                id: number,
                token: string,
                title: string,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                },
                published_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'flow_version',
        id: number,
        flow_id: number,
        css?: string | null,
        flow_step_ids: any,
        flow_version_flow_steps: Array<{
          __typename: 'flow_version_flow_step',
          flow_step?: {
            __typename: 'flow_step',
            id: number,
            token: string,
            type: flow_step_type_enum,
            published_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            flow_step_acknowledgement_group?: {
              __typename: 'flow_step_acknowledgement_group',
              flow_step_id: number,
              acknowledgement_group_id: number,
              acknowledgement_group: {
                __typename: 'acknowledgement_group',
                id: number,
                published_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_form?: {
              __typename: 'flow_step_form',
              flow_step_id: number,
              form_id: number,
              form: {
                __typename: 'form',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_question?: {
              __typename: 'flow_step_question',
              flow_step_id: number,
              question_id: number,
              question: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              }
            } | null,
            flow_step_deflection_rule_group?: {
              __typename: 'flow_step_deflection_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              } | null
            } | null,
            flow_step_offer_rule_group?: {
              __typename: 'flow_step_offer_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              } | null
            } | null,
            flow_step_rule_group?: {
              __typename: 'flow_step_rule_group',
              flow_step_id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              }
            } | null,
            flow_step_confirmation?: {
              __typename: 'flow_step_confirmation',
              flow_step_id: number,
              confirmation_id: number,
              confirmation: {
                __typename: 'confirmation',
                id: number,
                published_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null,
            flow_step_intervention?: {
              __typename: 'flow_step_intervention',
              flow_step_id: number,
              intervention_id: number,
              intervention: {
                __typename: 'intervention',
                id: number,
                token: string,
                title: string,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                },
                published_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type UpdateInterventionTitleMutationVariables = Exact<{
  interventionId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
}>;


export type UpdateInterventionTitleMutation = {
  update_intervention_by_pk?: {
    __typename: 'intervention',
    id: number,
    title: string
  } | null
};

export type UpdateFormTitleMutationVariables = Exact<{
  formId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
}>;


export type UpdateFormTitleMutation = {
  update_form_by_pk?: {
    __typename: 'form',
    id: number,
    title: string
  } | null
};

export type AddOfferRuleGroupMutationVariables = Exact<{
  object: offer_rule_group_insert_input;
}>;


export type AddOfferRuleGroupMutation = {
  insert_offer_rule_group_one?: {
    __typename: 'offer_rule_group',
    id: number,
    flow_step_rule_groups: Array<{
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      mode: rule_group_mode_enum,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string
      }
    }>
  } | null
};

export type AddOfferRuleGroupVersionMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
}>;


export type AddOfferRuleGroupVersionMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number
  } | null
};

export type AddOfferRuleGroupSetVersionMutationVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type AddOfferRuleGroupSetVersionMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null
};

export type AddOfferRuleGroupFlowStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddOfferRuleGroupFlowStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddOfferRuleGroupSetFlowStepVersionMutationVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type AddOfferRuleGroupSetFlowStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type UpdateOfferRuleGroupTitleMutationVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateOfferRuleGroupTitleMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number,
    title: string
  } | null
};

export type AddAcknowledgementGroupMutationVariables = Exact<{
  object: acknowledgement_group_insert_input;
}>;


export type AddAcknowledgementGroupMutation = {
  insert_acknowledgement_group_one?: {
    __typename: 'acknowledgement_group',
    id: number,
    flow_step_acknowledgement_groups: Array<{
      __typename: 'flow_step_acknowledgement_group',
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string
      }
    }>
  } | null
};

export type AddAcknowledgementGroupVersionMutationVariables = Exact<{
  object: acknowledgement_group_version_insert_input;
}>;


export type AddAcknowledgementGroupVersionMutation = {
  insert_acknowledgement_group_version_one?: {
    __typename: 'acknowledgement_group_version',
    id: number
  } | null
};

export type AddAcknowledgementGroupSetVersionMutationVariables = Exact<{
  acknowledgementGroupId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type AddAcknowledgementGroupSetVersionMutation = {
  update_acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number
  } | null
};

export type AddAcknowledgementGroupFlowStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddAcknowledgementGroupFlowStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddAcknowledgementGroupSetFlowStepVersionMutationVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  publishedVersionId: Scalars['Int']['input'];
}>;


export type AddAcknowledgementGroupSetFlowStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type AddExistingQuestionStepMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  questionId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddExistingQuestionStepMutation = {
  insert_flow_step_question_one?: {
    __typename: 'flow_step_question',
    flow_step_id: number,
    question_id: number,
    flow_step: {
      __typename: 'flow_step',
      id: number,
      token: string,
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        flow_steps: Array<{
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        }>
      }
    }
  } | null
};

export type AddExistingQuestionStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddExistingQuestionStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddExistingQuestionSetStepVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type AddExistingQuestionSetStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type AddExistingFormStepMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  formId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddExistingFormStepMutation = {
  insert_flow_step_form_one?: {
    __typename: 'flow_step_form',
    flow_step_id: number,
    form_id: number,
    flow_step: {
      __typename: 'flow_step',
      id: number,
      token: string,
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        flow_steps: Array<{
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_id: number,
            condition_segment_group_ids: any,
            condition_segment_ids: any,
            condition_question_option_ids: any,
            condition_include_other_in_question_ids: any,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            flow_step_version_condition_question_options: Array<{
              __typename: 'flow_step_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            flow_step_id: number,
            acknowledgement_group_id: number,
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              id: number,
              published_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_group_version',
                id: number,
                acknowledgement_group_id: number,
                acknowledgement_ids: any,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                acknowledgement_group_version_acknowledgements: Array<{
                  __typename: 'acknowledgement_group_version_acknowledgement',
                  acknowledgement_group_version_id?: number | null,
                  acknowledgement_id?: number | null,
                  acknowledgement?: {
                    __typename: 'acknowledgement',
                    id: number,
                    title: string,
                    published_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'acknowledgement_version',
                      id: number,
                      acknowledgement_id: number,
                      label_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'form_version',
                id: number,
                form_id: number,
                question_ids: any,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question_id?: number | null,
                  form_version_id?: number | null,
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    type: question_type_enum,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'question_version',
                      id: number,
                      question_id: number,
                      required: boolean,
                      title_translation_id: number,
                      hint_translation_id: number,
                      placeholder_translation_id?: number | null,
                      condition_segment_ids?: any | null,
                      condition_question_option_ids?: any | null,
                      condition_include_other_in_question_ids?: any | null,
                      question_option_ids?: any | null,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      hint_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      placeholder_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      question_version_question_options: Array<{
                        __typename: 'question_version_question_option',
                        question_option_id?: number | null,
                        question_version_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          token: string,
                          reason_code?: string | null,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            question_option_id: number,
                            title_translation_id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_question_options: Array<{
                        __typename: 'question_version_condition_question_option',
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      question_version_condition_segments: Array<{
                        __typename: 'question_version_condition_segment',
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      question_version_condition_segment_groups: Array<{
                        __typename: 'question_version_condition_segment_group',
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      question_id: number,
                      key: question_setting_key_enum,
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null,
                      draft_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        question_id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_id: number,
                offer_rule_ids: any,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      offer_rule_id: number,
                      segment_group_ids: any,
                      segment_ids: any,
                      question_option_ids: any,
                      include_present_no_offer: boolean,
                      include_other_in_question_ids: any,
                      offer_rule_rule_ids: any,
                      offer_rule_version_segment_groups: Array<{
                        __typename: 'offer_rule_version_segment_group',
                        offer_rule_version_id?: number | null,
                        segment_group_id?: number | null,
                        segment_group?: {
                          __typename: 'segment_group',
                          id: number,
                          name: string,
                          segment_group_segments: Array<{
                            __typename: 'segment_group_segment',
                            operator: segment_group_operator_enum,
                            segment: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            }
                          }>
                        } | null
                      }>,
                      offer_rule_version_segments: Array<{
                        __typename: 'offer_rule_version_segment',
                        offer_rule_version_id?: number | null,
                        segment_id?: number | null,
                        segment?: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        } | null
                      }>,
                      offer_rule_version_question_options: Array<{
                        __typename: 'offer_rule_version_question_option',
                        offer_rule_version_id?: number | null,
                        question_option_id?: number | null,
                        question_option?: {
                          __typename: 'question_option',
                          id: number,
                          question_id: number,
                          published_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          draft_version?: {
                            __typename: 'question_option_version',
                            id: number,
                            title_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        } | null
                      }>,
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_version_id?: number | null,
                        offer_rule_rule_id?: number | null,
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          id: number,
                          offer_rule_id: number,
                          deflection_ids: any,
                          include_present_no_offer: boolean,
                          weight: number,
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            offer_rule_rule_id?: number | null,
                            deflection_id?: number | null,
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              title: string,
                              token: string,
                              minimum_items?: number | null,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              deflection_snapshot_items: Array<{
                                __typename: 'deflection_snapshot_item_position',
                                position?: number | null,
                                deflection_snapshot_item?: {
                                  __typename: 'deflection_snapshot_item',
                                  id: number,
                                  token: string,
                                  icon_prefix?: string | null,
                                  icon_name?: string | null,
                                  property_id?: number | null,
                                  property_condition_value?: any | null,
                                  date_format?: string | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  property?: {
                                    __typename: 'property',
                                    id: number,
                                    name: string,
                                    entity: property_entity_enum,
                                    type: property_type_enum,
                                    format?: property_format_enum | null
                                  } | null
                                } | null
                              }>,
                              deflection_flow_actions: Array<{
                                __typename: 'deflection_flow_action',
                                position?: number | null,
                                flow_action?: {
                                  __typename: 'flow_action',
                                  id: number,
                                  token: string,
                                  code?: string | null,
                                  type: flow_action_type_enum,
                                  account_id: number,
                                  appearance: flow_action_appearance_enum,
                                  url?: string | null,
                                  reroute_to_flow_id?: number | null,
                                  text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  }
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            } | null
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            }
          } | null,
          flow_step_confirmation?: {
            __typename: 'flow_step_confirmation',
            flow_step_id: number,
            confirmation_id: number,
            confirmation: {
              __typename: 'confirmation',
              id: number,
              published_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'confirmation_version',
                id: number,
                confirmation_id: number,
                show_on_cancel: boolean,
                show_on_save: boolean,
                saved_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                saved_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                canceled_content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            flow_step_id: number,
            intervention_id: number,
            intervention: {
              __typename: 'intervention',
              id: number,
              token: string,
              title: string,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              },
              published_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'intervention_version',
                id: number,
                intervention_id: number,
                content_translation_id: number,
                continue_button_translation_id: number,
                cancel_button_translation_id: number,
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                continue_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                cancel_button_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null
        }>
      }
    }
  } | null
};

export type AddExistingFormStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddExistingFormStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddExistingFormSetStepVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type AddExistingFormSetStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type AddConfirmationMutationVariables = Exact<{
  object: confirmation_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddConfirmationMutation = {
  insert_confirmation_version_one?: {
    __typename: 'confirmation_version',
    id: number,
    confirmation: {
      __typename: 'confirmation',
      id: number,
      token: string,
      flow_step_confirmations: Array<{
        __typename: 'flow_step_confirmation',
        flow_step_id: number,
        flow_step: {
          __typename: 'flow_step',
          id: number,
          token: string,
          flow: {
            __typename: 'flow',
            id: number,
            token: string,
            flow_steps: Array<{
              __typename: 'flow_step',
              id: number,
              token: string,
              type: flow_step_type_enum,
              published_version?: {
                __typename: 'flow_step_version',
                id: number,
                flow_step_id: number,
                condition_segment_group_ids: any,
                condition_segment_ids: any,
                condition_question_option_ids: any,
                condition_include_other_in_question_ids: any,
                flow_step_version_condition_segment_groups: Array<{
                  __typename: 'flow_step_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                flow_step_version_condition_segments: Array<{
                  __typename: 'flow_step_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                flow_step_version_condition_question_options: Array<{
                  __typename: 'flow_step_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'flow_step_version',
                id: number,
                flow_step_id: number,
                condition_segment_group_ids: any,
                condition_segment_ids: any,
                condition_question_option_ids: any,
                condition_include_other_in_question_ids: any,
                flow_step_version_condition_segment_groups: Array<{
                  __typename: 'flow_step_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                flow_step_version_condition_segments: Array<{
                  __typename: 'flow_step_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                flow_step_version_condition_question_options: Array<{
                  __typename: 'flow_step_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              flow_step_acknowledgement_group?: {
                __typename: 'flow_step_acknowledgement_group',
                flow_step_id: number,
                acknowledgement_group_id: number,
                acknowledgement_group: {
                  __typename: 'acknowledgement_group',
                  id: number,
                  published_version?: {
                    __typename: 'acknowledgement_group_version',
                    id: number,
                    acknowledgement_group_id: number,
                    acknowledgement_ids: any,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    acknowledgement_group_version_acknowledgements: Array<{
                      __typename: 'acknowledgement_group_version_acknowledgement',
                      acknowledgement_group_version_id?: number | null,
                      acknowledgement_id?: number | null,
                      acknowledgement?: {
                        __typename: 'acknowledgement',
                        id: number,
                        title: string,
                        published_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_group_version',
                    id: number,
                    acknowledgement_group_id: number,
                    acknowledgement_ids: any,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    acknowledgement_group_version_acknowledgements: Array<{
                      __typename: 'acknowledgement_group_version_acknowledgement',
                      acknowledgement_group_version_id?: number | null,
                      acknowledgement_id?: number | null,
                      acknowledgement?: {
                        __typename: 'acknowledgement',
                        id: number,
                        title: string,
                        published_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>
                  } | null
                }
              } | null,
              flow_step_form?: {
                __typename: 'flow_step_form',
                flow_step_id: number,
                form_id: number,
                form: {
                  __typename: 'form',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'form_version',
                    id: number,
                    form_id: number,
                    question_ids: any,
                    form_version_questions: Array<{
                      __typename: 'form_version_question',
                      question_id?: number | null,
                      form_version_id?: number | null,
                      question?: {
                        __typename: 'question',
                        id: number,
                        token: string,
                        type: question_type_enum,
                        primary: boolean,
                        published_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        question_settings: Array<{
                          __typename: 'question_setting',
                          question_id: number,
                          key: question_setting_key_enum,
                          published_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null,
                          draft_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'form_version',
                    id: number,
                    form_id: number,
                    question_ids: any,
                    form_version_questions: Array<{
                      __typename: 'form_version_question',
                      question_id?: number | null,
                      form_version_id?: number | null,
                      question?: {
                        __typename: 'question',
                        id: number,
                        token: string,
                        type: question_type_enum,
                        primary: boolean,
                        published_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        question_settings: Array<{
                          __typename: 'question_setting',
                          question_id: number,
                          key: question_setting_key_enum,
                          published_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null,
                          draft_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }
              } | null,
              flow_step_question?: {
                __typename: 'flow_step_question',
                flow_step_id: number,
                question_id: number,
                question: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                }
              } | null,
              flow_step_deflection_rule_group?: {
                __typename: 'flow_step_deflection_rule_group',
                flow_step_id?: number | null,
                offer_rule_group_id?: number | null,
                offer_rule_group?: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'offer_rule_group_version',
                    id: number,
                    offer_rule_group_id: number,
                    offer_rule_ids: any,
                    offer_rule_group_version_offer_rules: Array<{
                      __typename: 'offer_rule_group_version_offer_rule',
                      offer_rule?: {
                        __typename: 'offer_rule',
                        id: number,
                        published_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_group_version',
                    id: number,
                    offer_rule_group_id: number,
                    offer_rule_ids: any,
                    offer_rule_group_version_offer_rules: Array<{
                      __typename: 'offer_rule_group_version_offer_rule',
                      offer_rule?: {
                        __typename: 'offer_rule',
                        id: number,
                        published_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      } | null
                    }>
                  } | null
                } | null
              } | null,
              flow_step_offer_rule_group?: {
                __typename: 'flow_step_offer_rule_group',
                flow_step_id?: number | null,
                offer_rule_group_id?: number | null,
                offer_rule_group?: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                } | null
              } | null,
              flow_step_rule_group?: {
                __typename: 'flow_step_rule_group',
                flow_step_id: number,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                }
              } | null,
              flow_step_confirmation?: {
                __typename: 'flow_step_confirmation',
                flow_step_id: number,
                confirmation_id: number,
                confirmation: {
                  __typename: 'confirmation',
                  id: number,
                  published_version?: {
                    __typename: 'confirmation_version',
                    id: number,
                    confirmation_id: number,
                    show_on_cancel: boolean,
                    show_on_save: boolean,
                    saved_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    saved_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'confirmation_version',
                    id: number,
                    confirmation_id: number,
                    show_on_cancel: boolean,
                    show_on_save: boolean,
                    saved_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    saved_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }
              } | null,
              flow_step_intervention?: {
                __typename: 'flow_step_intervention',
                flow_step_id: number,
                intervention_id: number,
                intervention: {
                  __typename: 'intervention',
                  id: number,
                  token: string,
                  title: string,
                  offer_rule_group_id: number,
                  offer_rule_group: {
                    __typename: 'offer_rule_group',
                    id: number,
                    title: string
                  },
                  published_version?: {
                    __typename: 'intervention_version',
                    id: number,
                    intervention_id: number,
                    content_translation_id: number,
                    continue_button_translation_id: number,
                    cancel_button_translation_id: number,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    continue_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'intervention_version',
                    id: number,
                    intervention_id: number,
                    content_translation_id: number,
                    continue_button_translation_id: number,
                    cancel_button_translation_id: number,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    continue_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }
              } | null
            }>
          }
        }
      }>
    }
  } | null
};

export type AddConfirmationStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddConfirmationStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddConfirmationSetStepVersionMutationVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  flowStepVersionId: Scalars['Int']['input'];
  confirmationId: Scalars['Int']['input'];
  confirmationVersionId: Scalars['Int']['input'];
}>;


export type AddConfirmationSetStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null,
  update_confirmation_by_pk?: {
    __typename: 'confirmation',
    id: number
  } | null
};

export type AddIntervetionOfferRuleGroupMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
}>;


export type AddIntervetionOfferRuleGroupMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number
    }
  } | null
};

export type AddInterventionMutationVariables = Exact<{
  offerRuleGroupId: Scalars['Int']['input'];
  offerRuleGroupVersionId: Scalars['Int']['input'];
  object: intervention_version_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type AddInterventionMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null,
  insert_intervention_version_one?: {
    __typename: 'intervention_version',
    id: number,
    intervention: {
      __typename: 'intervention',
      id: number,
      token: string,
      flow_step_interventions: Array<{
        __typename: 'flow_step_intervention',
        flow_step_id: number,
        flow_step: {
          __typename: 'flow_step',
          id: number,
          token: string,
          flow: {
            __typename: 'flow',
            id: number,
            token: string,
            flow_steps: Array<{
              __typename: 'flow_step',
              id: number,
              token: string,
              type: flow_step_type_enum,
              published_version?: {
                __typename: 'flow_step_version',
                id: number,
                flow_step_id: number,
                condition_segment_group_ids: any,
                condition_segment_ids: any,
                condition_question_option_ids: any,
                condition_include_other_in_question_ids: any,
                flow_step_version_condition_segment_groups: Array<{
                  __typename: 'flow_step_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                flow_step_version_condition_segments: Array<{
                  __typename: 'flow_step_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                flow_step_version_condition_question_options: Array<{
                  __typename: 'flow_step_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'flow_step_version',
                id: number,
                flow_step_id: number,
                condition_segment_group_ids: any,
                condition_segment_ids: any,
                condition_question_option_ids: any,
                condition_include_other_in_question_ids: any,
                flow_step_version_condition_segment_groups: Array<{
                  __typename: 'flow_step_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                flow_step_version_condition_segments: Array<{
                  __typename: 'flow_step_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                flow_step_version_condition_question_options: Array<{
                  __typename: 'flow_step_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>
              } | null,
              flow_step_acknowledgement_group?: {
                __typename: 'flow_step_acknowledgement_group',
                flow_step_id: number,
                acknowledgement_group_id: number,
                acknowledgement_group: {
                  __typename: 'acknowledgement_group',
                  id: number,
                  published_version?: {
                    __typename: 'acknowledgement_group_version',
                    id: number,
                    acknowledgement_group_id: number,
                    acknowledgement_ids: any,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    acknowledgement_group_version_acknowledgements: Array<{
                      __typename: 'acknowledgement_group_version_acknowledgement',
                      acknowledgement_group_version_id?: number | null,
                      acknowledgement_id?: number | null,
                      acknowledgement?: {
                        __typename: 'acknowledgement',
                        id: number,
                        title: string,
                        published_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_group_version',
                    id: number,
                    acknowledgement_group_id: number,
                    acknowledgement_ids: any,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    acknowledgement_group_version_acknowledgements: Array<{
                      __typename: 'acknowledgement_group_version_acknowledgement',
                      acknowledgement_group_version_id?: number | null,
                      acknowledgement_id?: number | null,
                      acknowledgement?: {
                        __typename: 'acknowledgement',
                        id: number,
                        title: string,
                        published_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'acknowledgement_version',
                          id: number,
                          acknowledgement_id: number,
                          label_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>
                  } | null
                }
              } | null,
              flow_step_form?: {
                __typename: 'flow_step_form',
                flow_step_id: number,
                form_id: number,
                form: {
                  __typename: 'form',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'form_version',
                    id: number,
                    form_id: number,
                    question_ids: any,
                    form_version_questions: Array<{
                      __typename: 'form_version_question',
                      question_id?: number | null,
                      form_version_id?: number | null,
                      question?: {
                        __typename: 'question',
                        id: number,
                        token: string,
                        type: question_type_enum,
                        primary: boolean,
                        published_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        question_settings: Array<{
                          __typename: 'question_setting',
                          question_id: number,
                          key: question_setting_key_enum,
                          published_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null,
                          draft_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'form_version',
                    id: number,
                    form_id: number,
                    question_ids: any,
                    form_version_questions: Array<{
                      __typename: 'form_version_question',
                      question_id?: number | null,
                      form_version_id?: number | null,
                      question?: {
                        __typename: 'question',
                        id: number,
                        token: string,
                        type: question_type_enum,
                        primary: boolean,
                        published_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'question_version',
                          id: number,
                          question_id: number,
                          required: boolean,
                          title_translation_id: number,
                          hint_translation_id: number,
                          placeholder_translation_id?: number | null,
                          condition_segment_ids?: any | null,
                          condition_question_option_ids?: any | null,
                          condition_include_other_in_question_ids?: any | null,
                          question_option_ids?: any | null,
                          title_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          hint_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          placeholder_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          question_version_question_options: Array<{
                            __typename: 'question_version_question_option',
                            question_option_id?: number | null,
                            question_version_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              token: string,
                              reason_code?: string | null,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                question_option_id: number,
                                title_translation_id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_question_options: Array<{
                            __typename: 'question_version_condition_question_option',
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          question_version_condition_segments: Array<{
                            __typename: 'question_version_condition_segment',
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          question_version_condition_segment_groups: Array<{
                            __typename: 'question_version_condition_segment_group',
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>
                        } | null,
                        question_settings: Array<{
                          __typename: 'question_setting',
                          question_id: number,
                          key: question_setting_key_enum,
                          published_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null,
                          draft_version?: {
                            __typename: 'question_setting_version',
                            id: number,
                            question_id: number,
                            key: question_setting_key_enum,
                            value: any
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }
              } | null,
              flow_step_question?: {
                __typename: 'flow_step_question',
                flow_step_id: number,
                question_id: number,
                question: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                }
              } | null,
              flow_step_deflection_rule_group?: {
                __typename: 'flow_step_deflection_rule_group',
                flow_step_id?: number | null,
                offer_rule_group_id?: number | null,
                offer_rule_group?: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'offer_rule_group_version',
                    id: number,
                    offer_rule_group_id: number,
                    offer_rule_ids: any,
                    offer_rule_group_version_offer_rules: Array<{
                      __typename: 'offer_rule_group_version_offer_rule',
                      offer_rule?: {
                        __typename: 'offer_rule',
                        id: number,
                        published_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_group_version',
                    id: number,
                    offer_rule_group_id: number,
                    offer_rule_ids: any,
                    offer_rule_group_version_offer_rules: Array<{
                      __typename: 'offer_rule_group_version_offer_rule',
                      offer_rule?: {
                        __typename: 'offer_rule',
                        id: number,
                        published_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        draft_version?: {
                          __typename: 'offer_rule_version',
                          id: number,
                          offer_rule_id: number,
                          segment_group_ids: any,
                          segment_ids: any,
                          question_option_ids: any,
                          include_present_no_offer: boolean,
                          include_other_in_question_ids: any,
                          offer_rule_rule_ids: any,
                          offer_rule_version_segment_groups: Array<{
                            __typename: 'offer_rule_version_segment_group',
                            offer_rule_version_id?: number | null,
                            segment_group_id?: number | null,
                            segment_group?: {
                              __typename: 'segment_group',
                              id: number,
                              name: string,
                              segment_group_segments: Array<{
                                __typename: 'segment_group_segment',
                                operator: segment_group_operator_enum,
                                segment: {
                                  __typename: 'segment',
                                  id: number,
                                  name: string
                                }
                              }>
                            } | null
                          }>,
                          offer_rule_version_segments: Array<{
                            __typename: 'offer_rule_version_segment',
                            offer_rule_version_id?: number | null,
                            segment_id?: number | null,
                            segment?: {
                              __typename: 'segment',
                              id: number,
                              name: string
                            } | null
                          }>,
                          offer_rule_version_question_options: Array<{
                            __typename: 'offer_rule_version_question_option',
                            offer_rule_version_id?: number | null,
                            question_option_id?: number | null,
                            question_option?: {
                              __typename: 'question_option',
                              id: number,
                              question_id: number,
                              published_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              draft_version?: {
                                __typename: 'question_option_version',
                                id: number,
                                title_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            } | null
                          }>,
                          offer_rule_version_offer_rule_rules: Array<{
                            __typename: 'offer_rule_version_offer_rule_rule',
                            offer_rule_version_id?: number | null,
                            offer_rule_rule_id?: number | null,
                            offer_rule_rule?: {
                              __typename: 'offer_rule_rule',
                              id: number,
                              offer_rule_id: number,
                              deflection_ids: any,
                              include_present_no_offer: boolean,
                              weight: number,
                              offer_rule_rule_deflections: Array<{
                                __typename: 'offer_rule_rule_deflection',
                                offer_rule_rule_id?: number | null,
                                deflection_id?: number | null,
                                deflection?: {
                                  __typename: 'deflection',
                                  id: number,
                                  title: string,
                                  token: string,
                                  minimum_items?: number | null,
                                  heading_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null,
                                  deflection_snapshot_items: Array<{
                                    __typename: 'deflection_snapshot_item_position',
                                    position?: number | null,
                                    deflection_snapshot_item?: {
                                      __typename: 'deflection_snapshot_item',
                                      id: number,
                                      token: string,
                                      icon_prefix?: string | null,
                                      icon_name?: string | null,
                                      property_id?: number | null,
                                      property_condition_value?: any | null,
                                      date_format?: string | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      property?: {
                                        __typename: 'property',
                                        id: number,
                                        name: string,
                                        entity: property_entity_enum,
                                        type: property_type_enum,
                                        format?: property_format_enum | null
                                      } | null
                                    } | null
                                  }>,
                                  deflection_flow_actions: Array<{
                                    __typename: 'deflection_flow_action',
                                    position?: number | null,
                                    flow_action?: {
                                      __typename: 'flow_action',
                                      id: number,
                                      token: string,
                                      code?: string | null,
                                      type: flow_action_type_enum,
                                      account_id: number,
                                      appearance: flow_action_appearance_enum,
                                      url?: string | null,
                                      reroute_to_flow_id?: number | null,
                                      text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      }
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      } | null
                    }>
                  } | null
                } | null
              } | null,
              flow_step_offer_rule_group?: {
                __typename: 'flow_step_offer_rule_group',
                flow_step_id?: number | null,
                offer_rule_group_id?: number | null,
                offer_rule_group?: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                } | null
              } | null,
              flow_step_rule_group?: {
                __typename: 'flow_step_rule_group',
                flow_step_id: number,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                }
              } | null,
              flow_step_confirmation?: {
                __typename: 'flow_step_confirmation',
                flow_step_id: number,
                confirmation_id: number,
                confirmation: {
                  __typename: 'confirmation',
                  id: number,
                  published_version?: {
                    __typename: 'confirmation_version',
                    id: number,
                    confirmation_id: number,
                    show_on_cancel: boolean,
                    show_on_save: boolean,
                    saved_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    saved_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'confirmation_version',
                    id: number,
                    confirmation_id: number,
                    show_on_cancel: boolean,
                    show_on_save: boolean,
                    saved_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    saved_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_headline_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    canceled_content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }
              } | null,
              flow_step_intervention?: {
                __typename: 'flow_step_intervention',
                flow_step_id: number,
                intervention_id: number,
                intervention: {
                  __typename: 'intervention',
                  id: number,
                  token: string,
                  title: string,
                  offer_rule_group_id: number,
                  offer_rule_group: {
                    __typename: 'offer_rule_group',
                    id: number,
                    title: string
                  },
                  published_version?: {
                    __typename: 'intervention_version',
                    id: number,
                    intervention_id: number,
                    content_translation_id: number,
                    continue_button_translation_id: number,
                    cancel_button_translation_id: number,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    continue_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'intervention_version',
                    id: number,
                    intervention_id: number,
                    content_translation_id: number,
                    continue_button_translation_id: number,
                    cancel_button_translation_id: number,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    continue_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_button_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }
              } | null
            }>
          }
        }
      }>
    }
  } | null
};

export type AddInterventionStepVersionMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type AddInterventionStepVersionMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number
  } | null
};

export type AddInterventionSetStepVersionMutationVariables = Exact<{
  flowStepId: Scalars['Int']['input'];
  flowStepVersionId: Scalars['Int']['input'];
  interventionId: Scalars['Int']['input'];
  interventionVersionId: Scalars['Int']['input'];
}>;


export type AddInterventionSetStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null,
  update_intervention_by_pk?: {
    __typename: 'intervention',
    id: number
  } | null
};

export type ReorderFlowStepsMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  flowStepIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type ReorderFlowStepsMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    id: number,
    flow: {
      __typename: 'flow',
      id: number,
      draft_version?: {
        __typename: 'flow_version',
        id: number,
        flow_id: number,
        css?: string | null,
        flow_step_ids: any,
        flow_version_flow_steps: Array<{
          __typename: 'flow_version_flow_step',
          flow_step?: {
            __typename: 'flow_step',
            id: number,
            token: string,
            type: flow_step_type_enum,
            published_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'flow_step_version',
              id: number,
              flow_step_id: number,
              condition_segment_group_ids: any,
              condition_segment_ids: any,
              condition_question_option_ids: any,
              condition_include_other_in_question_ids: any,
              flow_step_version_condition_segment_groups: Array<{
                __typename: 'flow_step_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              flow_step_version_condition_segments: Array<{
                __typename: 'flow_step_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              flow_step_version_condition_question_options: Array<{
                __typename: 'flow_step_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            flow_step_acknowledgement_group?: {
              __typename: 'flow_step_acknowledgement_group',
              flow_step_id: number,
              acknowledgement_group_id: number,
              acknowledgement_group: {
                __typename: 'acknowledgement_group',
                id: number,
                published_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_group_version',
                  id: number,
                  acknowledgement_group_id: number,
                  acknowledgement_ids: any,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  acknowledgement_group_version_acknowledgements: Array<{
                    __typename: 'acknowledgement_group_version_acknowledgement',
                    acknowledgement_group_version_id?: number | null,
                    acknowledgement_id?: number | null,
                    acknowledgement?: {
                      __typename: 'acknowledgement',
                      id: number,
                      title: string,
                      published_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'acknowledgement_version',
                        id: number,
                        acknowledgement_id: number,
                        label_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_form?: {
              __typename: 'flow_step_form',
              flow_step_id: number,
              form_id: number,
              form: {
                __typename: 'form',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'form_version',
                  id: number,
                  form_id: number,
                  question_ids: any,
                  form_version_questions: Array<{
                    __typename: 'form_version_question',
                    question_id?: number | null,
                    form_version_id?: number | null,
                    question?: {
                      __typename: 'question',
                      id: number,
                      token: string,
                      type: question_type_enum,
                      primary: boolean,
                      published_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'question_version',
                        id: number,
                        question_id: number,
                        required: boolean,
                        title_translation_id: number,
                        hint_translation_id: number,
                        placeholder_translation_id?: number | null,
                        condition_segment_ids?: any | null,
                        condition_question_option_ids?: any | null,
                        condition_include_other_in_question_ids?: any | null,
                        question_option_ids?: any | null,
                        title_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        hint_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        placeholder_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        question_version_question_options: Array<{
                          __typename: 'question_version_question_option',
                          question_option_id?: number | null,
                          question_version_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            token: string,
                            reason_code?: string | null,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              question_option_id: number,
                              title_translation_id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_question_options: Array<{
                          __typename: 'question_version_condition_question_option',
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        question_version_condition_segments: Array<{
                          __typename: 'question_version_condition_segment',
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        question_version_condition_segment_groups: Array<{
                          __typename: 'question_version_condition_segment_group',
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>
                      } | null,
                      question_settings: Array<{
                        __typename: 'question_setting',
                        question_id: number,
                        key: question_setting_key_enum,
                        published_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null,
                        draft_version?: {
                          __typename: 'question_setting_version',
                          id: number,
                          question_id: number,
                          key: question_setting_key_enum,
                          value: any
                        } | null
                      }>
                    } | null
                  }>
                } | null
              }
            } | null,
            flow_step_question?: {
              __typename: 'flow_step_question',
              flow_step_id: number,
              question_id: number,
              question: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              }
            } | null,
            flow_step_deflection_rule_group?: {
              __typename: 'flow_step_deflection_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_group_version',
                  id: number,
                  offer_rule_group_id: number,
                  offer_rule_ids: any,
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      id: number,
                      published_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null,
                      draft_version?: {
                        __typename: 'offer_rule_version',
                        id: number,
                        offer_rule_id: number,
                        segment_group_ids: any,
                        segment_ids: any,
                        question_option_ids: any,
                        include_present_no_offer: boolean,
                        include_other_in_question_ids: any,
                        offer_rule_rule_ids: any,
                        offer_rule_version_segment_groups: Array<{
                          __typename: 'offer_rule_version_segment_group',
                          offer_rule_version_id?: number | null,
                          segment_group_id?: number | null,
                          segment_group?: {
                            __typename: 'segment_group',
                            id: number,
                            name: string,
                            segment_group_segments: Array<{
                              __typename: 'segment_group_segment',
                              operator: segment_group_operator_enum,
                              segment: {
                                __typename: 'segment',
                                id: number,
                                name: string
                              }
                            }>
                          } | null
                        }>,
                        offer_rule_version_segments: Array<{
                          __typename: 'offer_rule_version_segment',
                          offer_rule_version_id?: number | null,
                          segment_id?: number | null,
                          segment?: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          } | null
                        }>,
                        offer_rule_version_question_options: Array<{
                          __typename: 'offer_rule_version_question_option',
                          offer_rule_version_id?: number | null,
                          question_option_id?: number | null,
                          question_option?: {
                            __typename: 'question_option',
                            id: number,
                            question_id: number,
                            published_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            draft_version?: {
                              __typename: 'question_option_version',
                              id: number,
                              title_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          } | null
                        }>,
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_version_id?: number | null,
                          offer_rule_rule_id?: number | null,
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            id: number,
                            offer_rule_id: number,
                            deflection_ids: any,
                            include_present_no_offer: boolean,
                            weight: number,
                            offer_rule_rule_deflections: Array<{
                              __typename: 'offer_rule_rule_deflection',
                              offer_rule_rule_id?: number | null,
                              deflection_id?: number | null,
                              deflection?: {
                                __typename: 'deflection',
                                id: number,
                                title: string,
                                token: string,
                                minimum_items?: number | null,
                                heading_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                deflection_snapshot_items: Array<{
                                  __typename: 'deflection_snapshot_item_position',
                                  position?: number | null,
                                  deflection_snapshot_item?: {
                                    __typename: 'deflection_snapshot_item',
                                    id: number,
                                    token: string,
                                    icon_prefix?: string | null,
                                    icon_name?: string | null,
                                    property_id?: number | null,
                                    property_condition_value?: any | null,
                                    date_format?: string | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    property?: {
                                      __typename: 'property',
                                      id: number,
                                      name: string,
                                      entity: property_entity_enum,
                                      type: property_type_enum,
                                      format?: property_format_enum | null
                                    } | null
                                  } | null
                                }>,
                                deflection_flow_actions: Array<{
                                  __typename: 'deflection_flow_action',
                                  position?: number | null,
                                  flow_action?: {
                                    __typename: 'flow_action',
                                    id: number,
                                    token: string,
                                    code?: string | null,
                                    type: flow_action_type_enum,
                                    account_id: number,
                                    appearance: flow_action_appearance_enum,
                                    url?: string | null,
                                    reroute_to_flow_id?: number | null,
                                    text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    }
                                  } | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              } | null
            } | null,
            flow_step_offer_rule_group?: {
              __typename: 'flow_step_offer_rule_group',
              flow_step_id?: number | null,
              offer_rule_group_id?: number | null,
              offer_rule_group?: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              } | null
            } | null,
            flow_step_rule_group?: {
              __typename: 'flow_step_rule_group',
              flow_step_id: number,
              offer_rule_group_id: number,
              offer_rule_group: {
                __typename: 'offer_rule_group',
                id: number,
                title: string
              }
            } | null,
            flow_step_confirmation?: {
              __typename: 'flow_step_confirmation',
              flow_step_id: number,
              confirmation_id: number,
              confirmation: {
                __typename: 'confirmation',
                id: number,
                published_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'confirmation_version',
                  id: number,
                  confirmation_id: number,
                  show_on_cancel: boolean,
                  show_on_save: boolean,
                  saved_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  saved_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  canceled_content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null,
            flow_step_intervention?: {
              __typename: 'flow_step_intervention',
              flow_step_id: number,
              intervention_id: number,
              intervention: {
                __typename: 'intervention',
                id: number,
                token: string,
                title: string,
                offer_rule_group_id: number,
                offer_rule_group: {
                  __typename: 'offer_rule_group',
                  id: number,
                  title: string
                },
                published_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'intervention_version',
                  id: number,
                  intervention_id: number,
                  content_translation_id: number,
                  continue_button_translation_id: number,
                  cancel_button_translation_id: number,
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  continue_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  cancel_button_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              }
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type DeleteStepMutationVariables = Exact<{
  draftVersionId: Scalars['Int']['input'];
  stepIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type DeleteStepMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    id: number,
    flow_id: number,
    css?: string | null,
    flow_step_ids: any,
    flow_version_flow_steps: Array<{
      __typename: 'flow_version_flow_step',
      flow_step?: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      } | null
    }>
  } | null
};

export type UpdateStepConditionInlineSegmentsMutationVariables = Exact<{
  objects: Array<segment_insert_input> | segment_insert_input;
}>;


export type UpdateStepConditionInlineSegmentsMutation = {
  insert_segment?: {
    __typename: 'segment_mutation_response',
    returning: Array<{
      __typename: 'segment',
      id: number
    }>
  } | null
};

export type UpdateStepConditionMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  segmentGroupIds: Scalars['jsonb']['input'];
  segmentIds: Scalars['jsonb']['input'];
  questionOptionIds: Scalars['jsonb']['input'];
  includeOtherInQuestionIds: Scalars['jsonb']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateStepConditionMutation = {
  update_flow_step_version_by_pk?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step: {
      __typename: 'flow_step',
      id: number,
      token: string,
      type: flow_step_type_enum,
      published_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      flow_step_acknowledgement_group?: {
        __typename: 'flow_step_acknowledgement_group',
        flow_step_id: number,
        acknowledgement_group_id: number,
        acknowledgement_group: {
          __typename: 'acknowledgement_group',
          id: number,
          published_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_form?: {
        __typename: 'flow_step_form',
        flow_step_id: number,
        form_id: number,
        form: {
          __typename: 'form',
          id: number,
          title: string,
          published_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_question?: {
        __typename: 'flow_step_question',
        flow_step_id: number,
        question_id: number,
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          published_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          question_settings: Array<{
            __typename: 'question_setting',
            question_id: number,
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null,
            draft_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null
          }>
        }
      } | null,
      flow_step_deflection_rule_group?: {
        __typename: 'flow_step_deflection_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null,
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        } | null
      } | null,
      flow_step_rule_group?: {
        __typename: 'flow_step_rule_group',
        flow_step_id: number,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        }
      } | null,
      flow_step_confirmation?: {
        __typename: 'flow_step_confirmation',
        flow_step_id: number,
        confirmation_id: number,
        confirmation: {
          __typename: 'confirmation',
          id: number,
          published_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null,
      flow_step_intervention?: {
        __typename: 'flow_step_intervention',
        flow_step_id: number,
        intervention_id: number,
        intervention: {
          __typename: 'intervention',
          id: number,
          token: string,
          title: string,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          },
          published_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null
    }
  } | null
};

export type UpdateFlowTextVersionMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  key: flow_text_key_enum;
  publishedVersionId: Scalars['Int']['input'];
  draftVersionId?: InputMaybe<Scalars['Int']['input']>;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateFlowTextVersionMutation = {
  update_flow_text_by_pk?: {
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    flow: {
      __typename: 'flow',
      id: number,
      flow_texts: Array<{
        __typename: 'flow_text',
        flow_id: number,
        key: flow_text_key_enum,
        published_version?: {
          __typename: 'flow_text_version',
          id: number,
          flow_id: number,
          key: flow_text_key_enum,
          translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'flow_text_version',
          id: number,
          flow_id: number,
          key: flow_text_key_enum,
          translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    },
    published_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateTextDraftExistingVersionQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  key: flow_text_key_enum;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type CreateTextDraftExistingVersionQuery = {
  flow_text_by_pk?: {
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    published_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type CreateTextDraftNewVersionMutationVariables = Exact<{
  object: flow_text_version_insert_input;
}>;


export type CreateTextDraftNewVersionMutation = {
  insert_flow_text_version_one?: {
    __typename: 'flow_text_version',
    id: number,
    flow_id: number,
    key: flow_text_key_enum,
    translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null
};

export type UpdateTextExistingFlowTextQueryVariables = Exact<{
  flowId: Scalars['Int']['input'];
  key: flow_text_key_enum;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateTextExistingFlowTextQuery = {
  flow_text_by_pk?: {
    __typename: 'flow_text',
    flow_id: number,
    key: flow_text_key_enum,
    published_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    draft_version?: {
      __typename: 'flow_text_version',
      id: number,
      flow_id: number,
      key: flow_text_key_enum,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  } | null
};

export type UpdateTextCreateFlowTextMutationVariables = Exact<{
  object: flow_text_version_insert_input;
}>;


export type UpdateTextCreateFlowTextMutation = {
  insert_flow_text_version_one?: {
    __typename: 'flow_text_version',
    id: number
  } | null
};

export type UpdateFlowTextMutationVariables = Exact<{
  objects: Array<translation_value_insert_input> | translation_value_insert_input;
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type UpdateFlowTextMutation = {
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        flow_text_versions: Array<{
          __typename: 'flow_text_version',
          flow: {
            __typename: 'flow',
            id: number,
            flow_texts: Array<{
              __typename: 'flow_text',
              flow_id: number,
              key: flow_text_key_enum,
              published_version?: {
                __typename: 'flow_text_version',
                id: number,
                flow_id: number,
                key: flow_text_key_enum,
                translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'flow_text_version',
                id: number,
                flow_id: number,
                key: flow_text_key_enum,
                translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }>
          }
        }>
      }
    }>
  } | null
};

export type UpdateTranslationMutationVariables = Exact<{
  objects: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateTranslationMutation = {
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any
    }>
  } | null
};

export type DeflectionTrendsActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type DeflectionTrendsActionsQuery = {
  flow_action: Array<{
    __typename: 'flow_action',
    id: number,
    text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  }>
};

export type DeflectionTrendsQueryVariables = Exact<{
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
  deflectionIds?: InputMaybe<Scalars['jsonb']['input']>;
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  where: template_report_deflection_trends_bool_exp;
  previousWhere: template_report_deflection_trends_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type DeflectionTrendsQuery = {
  report_deflection_trends: Array<{
    __typename: 'template_report_deflection_trends',
    date: string,
    flow_action_id: number,
    clicked: number
  }>,
  previous_report_deflection_trends: Array<{
    __typename: 'template_report_deflection_trends',
    date: string,
    flow_action_id: number,
    clicked: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type FeedbackTrendsQueryVariables = Exact<{
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
  questionIds?: InputMaybe<Scalars['jsonb']['input']>;
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  orderBy: Array<template_report_feedback_trends_order_by> | template_report_feedback_trends_order_by;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type FeedbackTrendsQuery = {
  report_feedback_trends: Array<{
    __typename: 'template_report_feedback_trends',
    phrase: string,
    incidents: number,
    positive: number,
    negative: number,
    mixed: number,
    neutral: number,
    saved: number,
    deflected: number,
    canceled: number,
    lost_mrr: string
  }>,
  report_feedback_trends_aggregate: {
    __typename: 'template_report_feedback_trends_aggregate',
    aggregate?: {
      __typename: 'template_report_feedback_trends_aggregate_fields',
      count: number
    } | null
  }
};

export type InsightsQueryVariables = Exact<{ [key: string]: never; }>;


export type InsightsQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    name: string
  }>
};

export type OfferAcceptanceTrendsOfferQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferAcceptanceTrendsOfferQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string
  }>,
  offer_group: Array<{
    __typename: 'offer_group',
    id: number,
    token: string,
    name: string
  }>
};

export type OfferAcceptanceTrendsQueryVariables = Exact<{
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  groupByAccount: Scalars['Boolean']['input'];
  groupGroupByAccount: Scalars['Boolean']['input'];
  acceptRateWhere: template_report_offer_accept_rate_bool_exp;
  groupAcceptRateWhere: template_report_offer_group_accept_rate_bool_exp;
  offerRateWhere: template_report_offer_rate_bool_exp;
  previousAcceptRateWhere: template_report_offer_accept_rate_bool_exp;
  previousGroupAcceptRateWhere: template_report_offer_group_accept_rate_bool_exp;
  previousOfferRateWhere: template_report_offer_rate_bool_exp;
  annotationWhere: annotation_bool_exp;
  offerId?: InputMaybe<Scalars['Int']['input']>;
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type OfferAcceptanceTrendsQuery = {
  Accept_rate: Array<{
    __typename: 'template_report_offer_accept_rate',
    date: string,
    accepted: number,
    presented: number,
    declined: number
  }>,
  Group_accept_rate: Array<{
    __typename: 'template_report_offer_group_accept_rate',
    date: string,
    presented: number,
    accepted: number,
    declined: number
  }>,
  Offer_rate: Array<{
    __typename: 'template_report_offer_rate',
    date: string,
    offered: number,
    total: number
  }>,
  Previous_accept_rate: Array<{
    __typename: 'template_report_offer_accept_rate',
    date: string,
    accepted: number,
    presented: number,
    declined: number
  }>,
  Previous_group_accept_rate: Array<{
    __typename: 'template_report_offer_group_accept_rate',
    date: string,
    presented: number,
    accepted: number,
    declined: number
  }>,
  Previous_offer_rate: Array<{
    __typename: 'template_report_offer_rate',
    date: string,
    offered: number,
    total: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type RetainedRevenueTrendsQueryVariables = Exact<{
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  where: template_report_retained_revenue_bool_exp;
  previousWhere: template_report_retained_revenue_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type RetainedRevenueTrendsQuery = {
  report_retained_revenue: Array<{
    __typename: 'template_report_retained_revenue',
    date: string,
    saved: number,
    deflected: number,
    total: number
  }>,
  previous_report_retained_revenue: Array<{
    __typename: 'template_report_retained_revenue',
    date: string,
    saved: number,
    deflected: number,
    total: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type RetentionTrendsQueryVariables = Exact<{
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
  where: template_report_retention_trends_bool_exp;
  previousWhere: template_report_retention_trends_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type RetentionTrendsQuery = {
  report_retention_trends: Array<{
    __typename: 'template_report_retention_trends',
    date: string,
    canceled: number,
    saved: number,
    deflected: number
  }>,
  previous_report_retention_trends: Array<{
    __typename: 'template_report_retention_trends',
    date: string,
    canceled: number,
    saved: number,
    deflected: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type SegmentsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentsDropdownQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null
  }>
};

export type SentimentTrendsQueryVariables = Exact<{
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
  where: template_report_question_answer_sentiment_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type SentimentTrendsQuery = {
  report_question_answer_sentiment: Array<{
    __typename: 'template_report_question_answer_sentiment',
    date: string,
    positive: number,
    negative: number,
    mixed: number,
    neutral: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type SessionTrendsQueryVariables = Exact<{
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  flowIds?: InputMaybe<Scalars['jsonb']['input']>;
  where: template_report_subscriber_flow_status_bool_exp;
  previousWhere: template_report_subscriber_flow_status_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type SessionTrendsQuery = {
  report_subscriber_flow_status: Array<{
    __typename: 'template_report_subscriber_flow_status',
    date: string,
    canceled: number,
    saved: number,
    deflected: number,
    incomplete: number
  }>,
  previous_report_subscriber_flow_status: Array<{
    __typename: 'template_report_subscriber_flow_status',
    date: string,
    canceled: number,
    saved: number,
    deflected: number,
    incomplete: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type SurveyAnswerTrendsFlowQueryVariables = Exact<{ [key: string]: never; }>;


export type SurveyAnswerTrendsFlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              published_version?: {
                __typename: 'form_version',
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              token: string,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>,
  question_option: Array<{
    __typename: 'question_option',
    id: number,
    question_id: number,
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type SurveyAnswerTrendsQueryVariables = Exact<{
  segmentIds?: InputMaybe<Scalars['jsonb']['input']>;
  groupBy: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  where: template_report_question_answer_radio_bool_exp;
  annotationWhere: annotation_bool_exp;
}>;


export type SurveyAnswerTrendsQuery = {
  report_question_answer_radio: Array<{
    __typename: 'template_report_question_answer_radio',
    date: string,
    question_option_id: number,
    count: number
  }>,
  annotation: Array<{
    __typename: 'annotation',
    date: string,
    label: string,
    type: annotation_type_enum,
    user?: {
      __typename: 'user',
      name?: string | null
    } | null
  }>
};

export type InsightsFlowDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  token: string,
  title: string,
  heading_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  }
};

export type InsightsFlowQuestionFragment = {
  __typename: 'question',
  id: number,
  type: question_type_enum,
  token: string,
  primary: boolean,
  published_version?: {
    __typename: 'question_version',
    id: number,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  } | null,
  question_settings: Array<{
    __typename: 'question_setting',
    published_version?: {
      __typename: 'question_setting_version',
      id: number,
      key: question_setting_key_enum,
      value: any
    } | null
  }>
};

export type InsightsFlowQuestionQueryVariables = Exact<{ [key: string]: never; }>;


export type InsightsFlowQuestionQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      questions: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              id: number,
              title: string,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    type: question_type_enum,
                    token: string,
                    primary: boolean,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null,
                    question_settings: Array<{
                      __typename: 'question_setting',
                      published_version?: {
                        __typename: 'question_setting_version',
                        id: number,
                        key: question_setting_key_enum,
                        value: any
                      } | null
                    }>
                  } | null
                }>
              } | null
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum,
              token: string,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            }
          } | null
        } | null
      }>,
      deflections: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              published_version?: {
                __typename: 'offer_rule_group_version',
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    published_version?: {
                      __typename: 'offer_rule_version',
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_rule_rule_deflections: Array<{
                            __typename: 'offer_rule_rule_deflection',
                            deflection?: {
                              __typename: 'deflection',
                              id: number,
                              token: string,
                              title: string,
                              heading_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type UseFlowFilterDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type UseFlowFilterDropdownQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string
  }>
};

export type UseSegmentFilterDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSegmentFilterDropdownQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string
  }>
};

export type ReconnectStripeMutationVariables = Exact<{
  input: ConnectStripeApiKeyInput;
}>;


export type ReconnectStripeMutation = {
  connectStripeApiKey: {
    __typename: 'ConnectStripeApiKeyOutput',
    success: boolean
  }
};

export type AcceptRateByReasonChartQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type AcceptRateByReasonChartQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string
  }>,
  report_offer_resolution_by_question_option: Array<{
    __typename: 'report_offer_resolution_by_question_option',
    resolution?: string | null,
    count?: string | null,
    question_option_id?: number | null
  }>
};

export type AcceptanceCardQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type AcceptanceCardQuery = {
  report: {
    __typename: 'template_report_offer_accept_rate_aggregate',
    aggregate?: {
      __typename: 'template_report_offer_accept_rate_aggregate_fields',
      sum?: {
        __typename: 'template_report_offer_accept_rate_sum_fields',
        presented?: number | null,
        accepted?: number | null,
        declined?: number | null
      } | null
    } | null
  }
};

export type AcceptanceChartQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type AcceptanceChartQuery = {
  Accepted: Array<{
    __typename: 'template_report_offer_accept_rate',
    x: string,
    y: number
  }>,
  Declined: Array<{
    __typename: 'template_report_offer_accept_rate',
    x: string,
    y: number
  }>
};

export type ChangePlanOfferFormQueryVariables = Exact<{ [key: string]: never; }>;


export type ChangePlanOfferFormQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string,
    name: string,
    code?: string | null,
    platform_product?: {
      __typename: 'platform_product',
      name: string
    } | null,
    platform_connection: {
      __typename: 'platform_connection',
      platform: platform_enum
    }
  }>
};

export type CouponDropdownSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type CouponDropdownSearchQuery = {
  search_platform_coupons: Array<{
    __typename: 'platform_coupon',
    platform_id: string,
    name?: string | null,
    code?: string | null
  }>
};

export type DowngradeOfferFormQueryVariables = Exact<{ [key: string]: never; }>;


export type DowngradeOfferFormQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string,
    name: string,
    paper_code?: string | null,
    code?: string | null,
    platform_product?: {
      __typename: 'platform_product',
      name: string
    } | null,
    platform_connection: {
      __typename: 'platform_connection',
      platform: platform_enum
    },
    platform_plan_group?: {
      __typename: 'platform_plan_group',
      platform_id: string,
      name: string,
      code?: string | null
    } | null
  }>,
  platform_connection: Array<{
    __typename: 'platform_connection',
    naviga_paper_codes: Array<{
      __typename: 'naviga_paper_code',
      paper_code: string
    }>
  }>
};

export type EndOfferTestModalMutationVariables = Exact<{
  offerTestId: Scalars['Int']['input'];
  winningOfferId: Scalars['Int']['input'];
}>;


export type EndOfferTestModalMutation = {
  update_offer_test_by_pk?: {
    __typename: 'offer_test',
    id: number,
    winning_offer_id?: number | null
  } | null
};

export type OfferChangePlanQueryVariables = Exact<{
  platformPlanId: Scalars['String']['input'];
}>;


export type OfferChangePlanQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    name: string
  }>
};

export type OfferGroupDeleteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OfferGroupDeleteMutation = {
  update_offer_group_by_pk?: {
    __typename: 'offer_group',
    id: number
  } | null
};

export type OfferGroupSubscriberFlowCountDataQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OfferGroupSubscriberFlowCountDataQuery = {
  subscriber_flow_offer_group_aggregate: {
    __typename: 'subscriber_flow_offer_group_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_group_aggregate_fields',
      count: number
    } | null
  }
};

export type OfferGroupQueryVariables = Exact<{
  token: Scalars['String']['input'];
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OfferGroupQuery = {
  offer_group: Array<{
    __typename: 'offer_group',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    show_images: boolean,
    created_at: string,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          latest_round: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              round_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  created_at: string,
                  approved_at?: string | null,
                  approval_user_id?: string | null,
                  data?: any,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    account?: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type OfferGroupCanDeleteQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferGroupCanDeleteQuery = {
  flow_step: Array<{
    __typename: 'flow_step',
    id: number,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_group_ids: any
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_group_ids: any
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_group_ids: any
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_group_ids: any
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null
  }>
};

export type OfferGroupAcceptRateByReasonChartQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  questionOptionIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type OfferGroupAcceptRateByReasonChartQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string
  }>,
  report_offer_group_resolution_by_question_option: Array<{
    __typename: 'report_offer_group_resolution_by_question_option',
    resolution?: string | null,
    count?: string | null,
    question_option_id?: number | null
  }>
};

export type OfferGroupAcceptanceCardQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type OfferGroupAcceptanceCardQuery = {
  report: Array<{
    __typename: 'template_report_offer_group_accept_rate',
    account_id: number,
    offer_group_id: number,
    offer_id: number,
    date: string,
    presented: number,
    accepted: number,
    declined: number
  }>,
  offer_group_by_pk?: {
    __typename: 'offer_group',
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      offer?: {
        __typename: 'offer',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type OfferGroupAcceptanceChartQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type OfferGroupAcceptanceChartQuery = {
  report: Array<{
    __typename: 'template_report_offer_group_accept_rate',
    account_id: number,
    offer_group_id: number,
    offer_id: number,
    date: string,
    presented: number,
    accepted: number,
    declined: number,
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null
  }>
};

export type OfferGroupFormOfferQueryVariables = Exact<{
  goal?: InputMaybe<offer_goal_enum>;
}>;


export type OfferGroupFormOfferQuery = {
  offer: Array<{
    __typename: 'offer',
    token: string,
    created_at: string
  }>
};

export type OfferGroupPanelQueryVariables = Exact<{
  goal: offer_goal_enum;
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OfferGroupPanelQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    default_language: language_enum,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    account: {
      __typename: 'account',
      id: number
    }
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>,
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    show_banner: boolean,
    image_url?: string | null,
    goal: offer_goal_enum,
    style: offer_style_enum,
    created_at: string,
    updated_at: string,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_coupon?: {
      __typename: 'offer_coupon',
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number,
      latest_round: Array<{
        __typename: 'offer_autopilot_offer_autopilot_round',
        offer_autopilot_round?: {
          __typename: 'offer_autopilot_round',
          id: number,
          round_variants: Array<{
            __typename: 'offer_autopilot_round_offer_variant',
            offer_variant?: {
              __typename: 'offer_variant',
              id: number,
              type: offer_type_enum,
              offer_id: number,
              created_at: string,
              approved_at?: string | null,
              approval_user_id?: string | null,
              data?: any,
              headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              button_text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              offer_variant_coupon?: {
                __typename: 'offer_variant_coupon',
                offer_variant_id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                duration_count?: number | null
              } | null
            } | null
          }>
        } | null
      }>
    } | null
  }>
};

export type OfferGroupPanelMutationVariables = Exact<{
  object: offer_group_insert_input;
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OfferGroupPanelMutation = {
  insert_offer_group_one?: {
    __typename: 'offer_group',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    show_images: boolean,
    created_at: string,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          latest_round: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              round_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  created_at: string,
                  approved_at?: string | null,
                  approval_user_id?: string | null,
                  data?: any,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    account?: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    } | null
  } | null
};

export type OfferGroupPanelUpdateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  offerGroup: offer_group_set_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OfferGroupPanelUpdateMutation = {
  update_offer_group_by_pk?: {
    __typename: 'offer_group',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    show_images: boolean,
    created_at: string,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          latest_round: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              round_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  created_at: string,
                  approved_at?: string | null,
                  approval_user_id?: string | null,
                  data?: any,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    account?: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    } | null
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    affected_rows: number
  } | null
};

export type OfferGroupSavedMrrRevenueCardQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
}>;


export type OfferGroupSavedMrrRevenueCardQuery = {
  mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  },
  revenue: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  }
};

export type OfferGroupSavedMrrRevenueChartQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type OfferGroupSavedMrrRevenueChartQuery = {
  Revenue: Array<{
    __typename: 'template_report_offer_group_retained_revenue',
    x: string,
    y: string
  }>
};

export type OfferGroupsQueryVariables = Exact<{
  goal: offer_goal_enum;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<offer_group_order_by> | offer_group_order_by>;
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OfferGroupsQuery = {
  offer_group: Array<{
    __typename: 'offer_group',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    show_images: boolean,
    created_at: string,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          latest_round: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              round_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  created_at: string,
                  approved_at?: string | null,
                  approval_user_id?: string | null,
                  data?: any,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    account?: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    } | null
  }>,
  offer_group_aggregate: {
    __typename: 'offer_group_aggregate',
    aggregate?: {
      __typename: 'offer_group_aggregate_fields',
      count: number
    } | null
  },
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowOfferGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowOfferGroupsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    flow_steps: Array<{
      __typename: 'flow_step',
      flow_step_rule_groups: Array<{
        __typename: 'flow_step_rule_group',
        flow_step_id: number,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          published_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      }>
    }>
  }>
};

export type OfferPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferPanelQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    default_language: language_enum,
    flow_languages: Array<{
      __typename: 'flow_language',
      flow_id: number,
      language: language_enum
    }>,
    account: {
      __typename: 'account',
      id: number
    }
  }>,
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type OfferSavedMrrRevenueCardQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type OfferSavedMrrRevenueCardQuery = {
  mrr: {
    __typename: 'subscriber_aggregate',
    aggregate?: {
      __typename: 'subscriber_aggregate_fields',
      sum?: {
        __typename: 'subscriber_sum_fields',
        active_mrr?: string | null
      } | null
    } | null
  },
  revenue: {
    __typename: 'revenue_log_aggregate',
    aggregate?: {
      __typename: 'revenue_log_aggregate_fields',
      sum?: {
        __typename: 'revenue_log_sum_fields',
        amount?: string | null
      } | null
    } | null
  }
};

export type OfferSavedMrrRevenueChartQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
}>;


export type OfferSavedMrrRevenueChartQuery = {
  Revenue: Array<{
    __typename: 'template_report_offer_retained_revenue',
    x: string,
    y: string
  }>
};

export type OfferTestSubscriptionVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OfferTestSubscription = {
  offer_test: Array<{
    __typename: 'offer_test',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    control_weight: number,
    created_at: string,
    winning_offer?: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    } | null,
    control_offer: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    },
    offer_test_offers: Array<{
      __typename: 'offer_test_offer',
      offer_test_id: number,
      offer_id: number,
      weight: number,
      offer: {
        __typename: 'offer',
        id: number,
        name: string,
        token: string,
        type: offer_type_enum
      }
    }>,
    report_offer_test_stats: Array<{
      __typename: 'report_offer_test_stats',
      offer_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null
    }>
  }>
};

export type OfferTestCanDeleteQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferTestCanDeleteQuery = {
  flow_step: Array<{
    __typename: 'flow_step',
    id: number,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_test_ids: any
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_test_ids: any
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_test_ids: any
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    offer_test_ids: any
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null
  }>
};

export type OfferTestDeleteMutationVariables = Exact<{
  offerTestId: Scalars['Int']['input'];
}>;


export type OfferTestDeleteMutation = {
  update_offer_test_by_pk?: {
    __typename: 'offer_test',
    id: number,
    deleted_at?: string | null
  } | null
};

export type OfferTestPanelQueryVariables = Exact<{
  goal: offer_goal_enum;
}>;


export type OfferTestPanelQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string
  }>,
  offer_test: Array<{
    __typename: 'offer_test',
    id: number,
    name: string
  }>
};

export type OfferTestPanelMutationVariables = Exact<{
  object: offer_test_insert_input;
}>;


export type OfferTestPanelMutation = {
  insert_offer_test_one?: {
    __typename: 'offer_test',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    control_weight: number,
    created_at: string,
    winning_offer?: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    } | null,
    control_offer: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    },
    offer_test_offers: Array<{
      __typename: 'offer_test_offer',
      offer_test_id: number,
      offer_id: number,
      weight: number,
      offer: {
        __typename: 'offer',
        id: number,
        name: string,
        token: string,
        type: offer_type_enum
      }
    }>,
    report_offer_test_stats: Array<{
      __typename: 'report_offer_test_stats',
      offer_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null
    }>
  } | null
};

export type OfferTestPanelUpdateTestMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  controlWeight: Scalars['Int']['input'];
}>;


export type OfferTestPanelUpdateTestMutation = {
  update_offer_test_by_pk?: {
    __typename: 'offer_test',
    id: number,
    name: string,
    control_weight: number
  } | null
};

export type OfferTestPanelUpdateVariantMutationVariables = Exact<{
  offerTestId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  weight: Scalars['Int']['input'];
}>;


export type OfferTestPanelUpdateVariantMutation = {
  update_offer_test_offer_by_pk?: {
    __typename: 'offer_test_offer',
    offer_test_id: number,
    offer_id: number,
    weight: number
  } | null
};

export type OfferTestsQueryVariables = Exact<{
  goal: offer_goal_enum;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<offer_test_order_by> | offer_test_order_by>;
}>;


export type OfferTestsQuery = {
  offer_test: Array<{
    __typename: 'offer_test',
    id: number,
    token: string,
    name: string,
    goal: offer_goal_enum,
    control_weight: number,
    created_at: string,
    winning_offer?: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    } | null,
    control_offer: {
      __typename: 'offer',
      id: number,
      name: string,
      token: string,
      type: offer_type_enum
    },
    offer_test_offers: Array<{
      __typename: 'offer_test_offer',
      offer_test_id: number,
      offer_id: number,
      weight: number,
      offer: {
        __typename: 'offer',
        id: number,
        name: string,
        token: string,
        type: offer_type_enum
      }
    }>,
    report_offer_test_stats: Array<{
      __typename: 'report_offer_test_stats',
      offer_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null
    }>
  }>,
  offer_test_aggregate: {
    __typename: 'offer_test_aggregate',
    aggregate?: {
      __typename: 'offer_test_aggregate_fields',
      count: number
    } | null
  },
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowOfferTestsQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowOfferTestsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    flow_steps: Array<{
      __typename: 'flow_step',
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          published_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null
    }>
  }>
};

export type OffersGridFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum,
  created_at: string,
  accepted: {
    __typename: 'subscriber_flow_offer_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_aggregate_fields',
      count: number
    } | null
  },
  total: {
    __typename: 'subscriber_flow_offer_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_aggregate_fields',
      count: number
    } | null
  },
  offer_tags: Array<{
    __typename: 'offer_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type OffersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  where: offer_bool_exp;
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type OffersQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type OffersSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<offer_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type OffersSearchQuery = {
  search_offers: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  search_offers_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowOffersQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            intervention: {
              __typename: 'intervention',
              offer_rule_group: {
                __typename: 'offer_rule_group',
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      published_version?: {
                        __typename: 'offer_rule_version',
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_rule_rule_offers: Array<{
                              __typename: 'offer_rule_rule_offer',
                              offer_id?: number | null,
                              offer?: {
                                __typename: 'offer',
                                id: number
                              } | null
                            }>,
                            offer_rule_rule_offer_autopilot_offers: Array<{
                              __typename: 'offer_rule_rule_offer_autopilot_offer',
                              offer_id?: number | null
                            }>,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>,
                            offer_rule_rule_offer_groups: Array<{
                              __typename: 'offer_rule_rule_offer_group',
                              offer_group?: {
                                __typename: 'offer_group',
                                offer_group_offers: Array<{
                                  __typename: 'offer_group_offer',
                                  offer_id?: number | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              }
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              published_version?: {
                __typename: 'offer_rule_group_version',
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    published_version?: {
                      __typename: 'offer_rule_version',
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_rule_rule_offers: Array<{
                            __typename: 'offer_rule_rule_offer',
                            offer_id?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number
                            } | null
                          }>,
                          offer_rule_rule_offer_autopilot_offers: Array<{
                            __typename: 'offer_rule_rule_offer_autopilot_offer',
                            offer_id?: number | null
                          }>,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>,
                          offer_rule_rule_offer_groups: Array<{
                            __typename: 'offer_rule_rule_offer_group',
                            offer_group?: {
                              __typename: 'offer_group',
                              offer_group_offers: Array<{
                                __typename: 'offer_group_offer',
                                offer_id?: number | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type OffersHomeQueryVariables = Exact<{
  goal: offer_goal_enum;
}>;


export type OffersHomeQuery = {
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  },
  offer_group_aggregate: {
    __typename: 'offer_group_aggregate',
    aggregate?: {
      __typename: 'offer_group_aggregate_fields',
      count: number
    } | null
  },
  offer_test_aggregate: {
    __typename: 'offer_test_aggregate',
    aggregate?: {
      __typename: 'offer_test_aggregate_fields',
      count: number
    } | null
  },
  offer_autopilot_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type RecentSavesCardQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type RecentSavesCardQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type UpgradeOfferFormQueryVariables = Exact<{ [key: string]: never; }>;


export type UpgradeOfferFormQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string,
    name: string,
    paper_code?: string | null,
    code?: string | null,
    platform_product?: {
      __typename: 'platform_product',
      name: string
    } | null,
    platform_connection: {
      __typename: 'platform_connection',
      platform: platform_enum
    },
    platform_plan_group?: {
      __typename: 'platform_plan_group',
      platform_id: string,
      name: string,
      code?: string | null
    } | null
  }>,
  platform_connection: Array<{
    __typename: 'platform_connection',
    naviga_paper_codes: Array<{
      __typename: 'naviga_paper_code',
      paper_code: string
    }>
  }>
};

export type AutopilotOfferApproveOfferVariantMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type AutopilotOfferApproveOfferVariantMutation = {
  update_offer_variant_by_pk?: {
    __typename: 'offer_variant',
    id: number,
    approved_at?: string | null,
    approval_user_id?: string | null
  } | null
};

export type AutopilotOfferRegenerateOfferVariantMutationVariables = Exact<{
  input: RegenerateOfferVariantInput;
}>;


export type AutopilotOfferRegenerateOfferVariantMutation = {
  regenerateOfferVariant: {
    __typename: 'RegenerateOfferVariantOutput',
    success: boolean
  }
};

export type AutopilotOfferDeclareOfferAutopilotRoundWinnerMutationVariables = Exact<{
  input: DeclareOfferAutopilotRoundWinnerInput;
}>;


export type AutopilotOfferDeclareOfferAutopilotRoundWinnerMutation = {
  declareOfferAutopilotRoundWinner: {
    __typename: 'DeclareOfferAutopilotRoundWinnerOutput',
    success: boolean
  }
};

export type AutopilotOfferPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type AutopilotOfferPanelQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type AutopilotOffersGridFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  name: string,
  type: offer_type_enum,
  created_at: string,
  accepted: {
    __typename: 'subscriber_flow_offer_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_aggregate_fields',
      count: number
    } | null
  },
  total: {
    __typename: 'subscriber_flow_offer_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_aggregate_fields',
      count: number
    } | null
  },
  offer_tags: Array<{
    __typename: 'offer_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type AutopilotOffersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  where: offer_bool_exp;
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type AutopilotOffersQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  offer_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type AutopilotOffersSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<offer_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<offer_order_by> | offer_order_by>;
}>;


export type AutopilotOffersSearchQuery = {
  search_offers: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    accepted: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    total: {
      __typename: 'subscriber_flow_offer_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_offer_aggregate_fields',
        count: number
      } | null
    },
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  search_offers_aggregate: {
    __typename: 'offer_aggregate',
    aggregate?: {
      __typename: 'offer_aggregate_fields',
      count: number
    } | null
  }
};

export type FlowAutopilotOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowAutopilotOffersQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            intervention: {
              __typename: 'intervention',
              offer_rule_group: {
                __typename: 'offer_rule_group',
                published_version?: {
                  __typename: 'offer_rule_group_version',
                  offer_rule_group_version_offer_rules: Array<{
                    __typename: 'offer_rule_group_version_offer_rule',
                    offer_rule?: {
                      __typename: 'offer_rule',
                      published_version?: {
                        __typename: 'offer_rule_version',
                        offer_rule_version_offer_rule_rules: Array<{
                          __typename: 'offer_rule_version_offer_rule_rule',
                          offer_rule_rule?: {
                            __typename: 'offer_rule_rule',
                            offer_rule_rule_offers: Array<{
                              __typename: 'offer_rule_rule_offer',
                              offer?: {
                                __typename: 'offer',
                                id: number
                              } | null
                            }>,
                            offer_rule_rule_offer_autopilot_offers: Array<{
                              __typename: 'offer_rule_rule_offer_autopilot_offer',
                              offer_id?: number | null
                            }>,
                            offer_rule_rule_offer_tests: Array<{
                              __typename: 'offer_rule_rule_offer_test',
                              offer_test?: {
                                __typename: 'offer_test',
                                offer_test_offers: Array<{
                                  __typename: 'offer_test_offer',
                                  offer_test_id: number,
                                  offer_id: number
                                }>
                              } | null
                            }>,
                            offer_rule_rule_offer_groups: Array<{
                              __typename: 'offer_rule_rule_offer_group',
                              offer_group?: {
                                __typename: 'offer_group',
                                offer_group_offers: Array<{
                                  __typename: 'offer_group_offer',
                                  offer_id?: number | null
                                }>
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    } | null
                  }>
                } | null
              }
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              published_version?: {
                __typename: 'offer_rule_group_version',
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    published_version?: {
                      __typename: 'offer_rule_version',
                      offer_rule_version_offer_rule_rules: Array<{
                        __typename: 'offer_rule_version_offer_rule_rule',
                        offer_rule_rule?: {
                          __typename: 'offer_rule_rule',
                          offer_rule_rule_offers: Array<{
                            __typename: 'offer_rule_rule_offer',
                            offer?: {
                              __typename: 'offer',
                              id: number
                            } | null
                          }>,
                          offer_rule_rule_offer_autopilot_offers: Array<{
                            __typename: 'offer_rule_rule_offer_autopilot_offer',
                            offer_id?: number | null
                          }>,
                          offer_rule_rule_offer_tests: Array<{
                            __typename: 'offer_rule_rule_offer_test',
                            offer_test?: {
                              __typename: 'offer_test',
                              offer_test_offers: Array<{
                                __typename: 'offer_test_offer',
                                offer_test_id: number,
                                offer_id: number
                              }>
                            } | null
                          }>,
                          offer_rule_rule_offer_groups: Array<{
                            __typename: 'offer_rule_rule_offer_group',
                            offer_group?: {
                              __typename: 'offer_group',
                              offer_group_offers: Array<{
                                __typename: 'offer_group_offer',
                                offer_id?: number | null
                              }>
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  } | null
                }>
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  }>
};

export type OfferAutopilotQueryVariables = Exact<{
  token: Scalars['String']['input'];
  debugEnabled: Scalars['Boolean']['input'];
}>;


export type OfferAutopilotQuery = {
  offer_autopilot: Array<{
    __typename: 'offer_autopilot',
    offer_id: number,
    strategy: offer_autopilot_strategy_enum,
    success_metric: offer_autopilot_success_metric_enum,
    confidence_threshold: string,
    winning_variant_id?: number | null,
    created_at: string,
    completed_at?: string | null,
    current_round_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      override_active_offer: boolean,
      show_banner: boolean,
      type: offer_type_enum,
      goal: offer_goal_enum,
      style: offer_style_enum,
      image_url?: string | null,
      metadata: any,
      confirmation_enabled: boolean,
      created_at: string,
      archived_at?: string | null,
      account: {
        __typename: 'account',
        properties: Array<{
          __typename: 'property',
          id: number,
          name: string,
          type: property_type_enum,
          format?: property_format_enum | null
        }>,
        platform_connection?: {
          __typename: 'platform_connection',
          platform: platform_enum,
          import_completed_at?: string | null
        } | null
      },
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      confirmation_content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      confirmation_confirm_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      confirmation_cancel_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum,
        override_active_coupon: boolean,
        end_trial: boolean,
        platform_id?: string | null,
        platform_coupon?: {
          __typename: 'platform_coupon',
          code?: string | null
        } | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        reset_billing_date: boolean,
        offer_coupon_id?: number | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null,
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any,
          text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        }>
      } | null,
      offer_upgrade?: {
        __typename: 'offer_upgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string,
          platform_id: string,
          platform_plan_group?: {
            __typename: 'platform_plan_group',
            name: string,
            platform_id: string,
            code?: string | null
          } | null
        } | null
      } | null,
      offer_downgrade?: {
        __typename: 'offer_downgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string,
          platform_id: string,
          platform_plan_group?: {
            __typename: 'platform_plan_group',
            name: string,
            platform_id: string,
            code?: string | null
          } | null
        } | null
      } | null,
      offer_product_swap?: {
        __typename: 'offer_product_swap',
        specific_products: boolean,
        offer_product_swap_platform_ecommerce_products: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product',
          platform_ecommerce_product_id: string,
          platform_ecommerce_product?: {
            __typename: 'platform_ecommerce_product',
            platform_id: string,
            name: string,
            image_url?: string | null,
            active: boolean,
            platform_plan_ecommerce_products: Array<{
              __typename: 'platform_plan_ecommerce_product',
              discount_amount?: string | null,
              discount_type?: string | null
            }>
          } | null,
          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
            platform_variant?: {
              __typename: 'platform_variant',
              platform_id: string,
              name: string,
              price?: string | null,
              platform_variant_options: Array<{
                __typename: 'platform_variant_option',
                key: string,
                value: string
              }>
            } | null
          }>
        }>
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean,
        skip_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        reschedule_button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        strategy: offer_autopilot_strategy_enum
      } | null
    } | null,
    offer_autopilot_offer_autopilot_rounds: Array<{
      __typename: 'offer_autopilot_offer_autopilot_round',
      round_index?: number | null,
      offer_autopilot_round_id?: number | null,
      offer_autopilot_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        offer_id: number,
        strategy: offer_autopilot_round_strategy_enum,
        control_weight: number,
        control_variant_id: number,
        winning_variant_id?: number | null,
        started_at?: string | null,
        completed_at?: string | null,
        offer_autopilot_round_offer_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          round_index?: number | null,
          variant_index?: number | null,
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            approved_at?: string | null
          } | null,
          report_offer_autopilot_stats?: {
            __typename: 'report_offer_autopilot_stats',
            offer_id?: number | null,
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            views?: string | null,
            accepts?: number | null,
            declines?: number | null,
            bypasses?: number | null,
            avg_mrr?: string | null,
            saved_mrr?: string | null,
            lost_mrr?: string | null
          } | null
        }>,
        offer_autopilot_round_logs: Array<{
          __typename: 'offer_autopilot_round_log',
          data: any
        }>
      } | null
    }>,
    offer_autopilot_offer_variants: Array<{
      __typename: 'offer_autopilot_offer_variant',
      offer_id?: number | null,
      variant_index?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        created_at: string,
        approved_at?: string | null,
        approval_user_id?: string | null,
        data?: any,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null
    }>,
    report_offer_autopilot_stats: Array<{
      __typename: 'report_offer_autopilot_stats',
      offer_id?: number | null,
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null,
      bypasses?: number | null
    }>
  }>
};

export type OfferAutopilotSubscriptionVariables = Exact<{
  token: Scalars['String']['input'];
  debugEnabled: Scalars['Boolean']['input'];
}>;


export type OfferAutopilotSubscription = {
  offer_autopilot: Array<{
    __typename: 'offer_autopilot',
    offer_id: number,
    strategy: offer_autopilot_strategy_enum,
    success_metric: offer_autopilot_success_metric_enum,
    confidence_threshold: string,
    winning_variant_id?: number | null,
    created_at: string,
    completed_at?: string | null,
    current_round_id?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      override_active_offer: boolean,
      show_banner: boolean,
      type: offer_type_enum,
      goal: offer_goal_enum,
      style: offer_style_enum,
      image_url?: string | null,
      metadata: any,
      confirmation_enabled: boolean,
      created_at: string,
      archived_at?: string | null,
      account: {
        __typename: 'account',
        properties: Array<{
          __typename: 'property',
          id: number,
          name: string,
          type: property_type_enum,
          format?: property_format_enum | null
        }>,
        platform_connection?: {
          __typename: 'platform_connection',
          platform: platform_enum,
          import_completed_at?: string | null
        } | null
      },
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      confirmation_content_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      confirmation_confirm_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      confirmation_cancel_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum,
        override_active_coupon: boolean,
        end_trial: boolean,
        platform_id?: string | null,
        platform_coupon?: {
          __typename: 'platform_coupon',
          code?: string | null
        } | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        reset_billing_date: boolean,
        offer_coupon_id?: number | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null,
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any,
          text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        }>
      } | null,
      offer_upgrade?: {
        __typename: 'offer_upgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string,
          platform_id: string,
          platform_plan_group?: {
            __typename: 'platform_plan_group',
            name: string,
            platform_id: string,
            code?: string | null
          } | null
        } | null
      } | null,
      offer_downgrade?: {
        __typename: 'offer_downgrade',
        platform_plan_id?: string | null,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string,
          platform_id: string,
          platform_plan_group?: {
            __typename: 'platform_plan_group',
            name: string,
            platform_id: string,
            code?: string | null
          } | null
        } | null
      } | null,
      offer_product_swap?: {
        __typename: 'offer_product_swap',
        specific_products: boolean,
        offer_product_swap_platform_ecommerce_products: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product',
          platform_ecommerce_product_id: string,
          platform_ecommerce_product?: {
            __typename: 'platform_ecommerce_product',
            platform_id: string,
            name: string,
            image_url?: string | null,
            active: boolean,
            platform_plan_ecommerce_products: Array<{
              __typename: 'platform_plan_ecommerce_product',
              discount_amount?: string | null,
              discount_type?: string | null
            }>
          } | null,
          offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
            platform_variant?: {
              __typename: 'platform_variant',
              platform_id: string,
              name: string,
              price?: string | null,
              platform_variant_options: Array<{
                __typename: 'platform_variant_option',
                key: string,
                value: string
              }>
            } | null
          }>
        }>
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean,
        skip_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        reschedule_button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>,
      offer_autopilot?: {
        __typename: 'offer_autopilot',
        strategy: offer_autopilot_strategy_enum
      } | null
    } | null,
    offer_autopilot_offer_autopilot_rounds: Array<{
      __typename: 'offer_autopilot_offer_autopilot_round',
      round_index?: number | null,
      offer_autopilot_round_id?: number | null,
      offer_autopilot_round?: {
        __typename: 'offer_autopilot_round',
        id: number,
        offer_id: number,
        strategy: offer_autopilot_round_strategy_enum,
        control_weight: number,
        control_variant_id: number,
        winning_variant_id?: number | null,
        started_at?: string | null,
        completed_at?: string | null,
        offer_autopilot_round_offer_variants: Array<{
          __typename: 'offer_autopilot_round_offer_variant',
          offer_autopilot_round_id?: number | null,
          offer_variant_id?: number | null,
          round_index?: number | null,
          variant_index?: number | null,
          offer_variant?: {
            __typename: 'offer_variant',
            id: number,
            approved_at?: string | null
          } | null,
          report_offer_autopilot_stats?: {
            __typename: 'report_offer_autopilot_stats',
            offer_id?: number | null,
            offer_autopilot_round_id?: number | null,
            offer_variant_id?: number | null,
            views?: string | null,
            accepts?: number | null,
            declines?: number | null,
            bypasses?: number | null,
            avg_mrr?: string | null,
            saved_mrr?: string | null,
            lost_mrr?: string | null
          } | null
        }>,
        offer_autopilot_round_logs: Array<{
          __typename: 'offer_autopilot_round_log',
          data: any
        }>
      } | null
    }>,
    offer_autopilot_offer_variants: Array<{
      __typename: 'offer_autopilot_offer_variant',
      offer_id?: number | null,
      variant_index?: number | null,
      offer_variant?: {
        __typename: 'offer_variant',
        id: number,
        type: offer_type_enum,
        offer_id: number,
        created_at: string,
        approved_at?: string | null,
        approval_user_id?: string | null,
        data?: any,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_variant_coupon?: {
          __typename: 'offer_variant_coupon',
          offer_variant_id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null
        } | null
      } | null
    }>,
    report_offer_autopilot_stats: Array<{
      __typename: 'report_offer_autopilot_stats',
      offer_id?: number | null,
      offer_autopilot_round_id?: number | null,
      offer_variant_id?: number | null,
      views?: string | null,
      accepts?: number | null,
      declines?: number | null,
      bypasses?: number | null
    }>
  }>
};

export type UpdateOfferVariantMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  offerVariant: offer_variant_set_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
  debugEnabled: Scalars['Boolean']['input'];
}>;


export type UpdateOfferVariantMutation = {
  update_offer_variant_by_pk?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    created_at: string,
    approved_at?: string | null,
    approval_user_id?: string | null,
    data?: any,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type OfferFormContainerCouponsQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferFormContainerCouponsQuery = {
  platform_coupon: Array<{
    __typename: 'platform_coupon',
    platform_id: string,
    name?: string | null,
    code?: string | null,
    type: platform_coupon_type_enum,
    amount_off: string,
    duration: platform_coupon_duration_enum,
    duration_interval?: platform_coupon_duration_interval_enum | null,
    duration_count?: number | null
  }>
};

export type CouponOfferFormGetCouponQueryVariables = Exact<{
  platformId: Scalars['String']['input'];
}>;


export type CouponOfferFormGetCouponQuery = {
  platform_coupon: Array<{
    __typename: 'platform_coupon',
    platform_id: string,
    type: platform_coupon_type_enum,
    amount_off: string,
    duration: platform_coupon_duration_enum,
    duration_interval?: platform_coupon_duration_interval_enum | null,
    duration_count?: number | null
  }>
};

export type OfferArchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OfferArchiveMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type CreateChangePlanOfferMutationVariables = Exact<{
  object: offer_change_plan_insert_input;
}>;


export type CreateChangePlanOfferMutation = {
  insert_offer_change_plan_one?: {
    __typename: 'offer_change_plan',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateCouponOfferOfferMutationVariables = Exact<{
  object: offer_insert_input;
}>;


export type CreateCouponOfferOfferMutation = {
  insert_offer_one?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type CreateCouponOfferProductGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateCouponOfferProductGroupsQuery = {
  platform_product_group: Array<{
    __typename: 'platform_product_group',
    platform_id: string
  }>
};

export type CreateCouponOfferOfferQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CreateCouponOfferOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    created_at: string,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type CreateCustomOfferMutationVariables = Exact<{
  object: offer_custom_insert_input;
}>;


export type CreateCustomOfferMutation = {
  insert_offer_custom_one?: {
    __typename: 'offer_custom',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateDowngradeOfferMutationVariables = Exact<{
  object: offer_downgrade_insert_input;
}>;


export type CreateDowngradeOfferMutation = {
  insert_offer_downgrade_one?: {
    __typename: 'offer_downgrade',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateModifySubscriptionOfferMutationVariables = Exact<{
  object: offer_modify_subscription_insert_input;
}>;


export type CreateModifySubscriptionOfferMutation = {
  insert_offer_modify_subscription_one?: {
    __typename: 'offer_modify_subscription',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateOfferTimerMutationVariables = Exact<{
  object: offer_timer_insert_input;
}>;


export type CreateOfferTimerMutation = {
  insert_offer_timer_one?: {
    __typename: 'offer_timer',
    offer_id: number,
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null
};

export type CreatePauseSubscriptionOfferMutationVariables = Exact<{
  object: offer_pause_subscription_insert_input;
}>;


export type CreatePauseSubscriptionOfferMutation = {
  insert_offer_pause_subscription_one?: {
    __typename: 'offer_pause_subscription',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateProductSwapOfferMutationVariables = Exact<{
  object: offer_product_swap_insert_input;
}>;


export type CreateProductSwapOfferMutation = {
  insert_offer_product_swap_one?: {
    __typename: 'offer_product_swap',
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    } | null
  } | null
};

export type CreateRescheduleOrderOfferMutationVariables = Exact<{
  object: offer_reschedule_order_insert_input;
}>;


export type CreateRescheduleOrderOfferMutation = {
  insert_offer_reschedule_order_one?: {
    __typename: 'offer_reschedule_order',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateTrialExtensionOfferMutationVariables = Exact<{
  object: offer_trial_extension_insert_input;
}>;


export type CreateTrialExtensionOfferMutation = {
  insert_offer_trial_extension_one?: {
    __typename: 'offer_trial_extension',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type CreateUpgradeOfferMutationVariables = Exact<{
  object: offer_upgrade_insert_input;
}>;


export type CreateUpgradeOfferMutation = {
  insert_offer_upgrade_one?: {
    __typename: 'offer_upgrade',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      name: string,
      type: offer_type_enum,
      created_at: string,
      offer_tags: Array<{
        __typename: 'offer_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  } | null
};

export type OfferDeleteMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OfferDeleteMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type DeleteOfferTimerMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type DeleteOfferTimerMutation = {
  delete_offer_timer_by_pk?: {
    __typename: 'offer_timer',
    offer_id: number,
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null
};

export type OfferQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OfferQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    override_active_offer: boolean,
    show_banner: boolean,
    type: offer_type_enum,
    goal: offer_goal_enum,
    style: offer_style_enum,
    image_url?: string | null,
    metadata: any,
    confirmation_enabled: boolean,
    created_at: string,
    archived_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    confirmation_content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_confirm_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_cancel_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum,
      override_active_coupon: boolean,
      end_trial: boolean,
      platform_id?: string | null,
      platform_coupon?: {
        __typename: 'platform_coupon',
        code?: string | null
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_id?: number | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null,
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          active: boolean,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    account: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    },
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      strategy: offer_autopilot_strategy_enum
    } | null
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type OfferSubscriberFlowCountQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type OfferSubscriberFlowCountQuery = {
  subscriber_flow_offer_aggregate: {
    __typename: 'subscriber_flow_offer_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_offer_aggregate_fields',
      count: number
    } | null
  }
};

export type OfferPlatformEcommerceProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferPlatformEcommerceProductsQuery = {
  platform_ecommerce_product: Array<{
    __typename: 'platform_ecommerce_product',
    platform_id: string,
    name: string,
    image_url?: string | null,
    platform_variants: Array<{
      __typename: 'platform_variant',
      platform_id: string,
      name: string,
      price?: string | null,
      platform_variant_options: Array<{
        __typename: 'platform_variant_option',
        key: string,
        value: string
      }>
    }>,
    platform_plan_ecommerce_products: Array<{
      __typename: 'platform_plan_ecommerce_product',
      discount_amount?: string | null,
      discount_type?: string | null
    }>
  }>
};

export type OfferUnarchiveMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OfferUnarchiveMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number
  } | null
};

export type UpdateOfferMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  offer: offer_set_input;
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UpdateOfferMutation = {
  update_offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    override_active_offer: boolean,
    show_banner: boolean,
    type: offer_type_enum,
    goal: offer_goal_enum,
    style: offer_style_enum,
    image_url?: string | null,
    metadata: any,
    confirmation_enabled: boolean,
    created_at: string,
    archived_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    confirmation_content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_confirm_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_cancel_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum,
      override_active_coupon: boolean,
      end_trial: boolean,
      platform_id?: string | null,
      platform_coupon?: {
        __typename: 'platform_coupon',
        code?: string | null
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_id?: number | null,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null,
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        name: string,
        platform_id: string,
        platform_plan_group?: {
          __typename: 'platform_plan_group',
          name: string,
          platform_id: string,
          code?: string | null
        } | null
      } | null
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          active: boolean,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    account: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        platform: platform_enum,
        import_completed_at?: string | null
      } | null
    },
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_tags: Array<{
      __typename: 'offer_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      strategy: offer_autopilot_strategy_enum
    } | null
  } | null,
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    }>
  } | null
};

export type UseUpdateOfferChangePlanMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
  prorate: Scalars['Boolean']['input'];
  changeAt: offer_change_plan_change_at_enum;
  resetBillingDate: Scalars['Boolean']['input'];
}>;


export type UseUpdateOfferChangePlanMutation = {
  update_offer_change_plan_by_pk?: {
    __typename: 'offer_change_plan',
    offer_id: number,
    prorate: boolean,
    change_at: offer_change_plan_change_at_enum,
    offer: {
      __typename: 'offer',
      id: number,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        offer_id: number,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        reset_billing_date: boolean
      } | null
    }
  } | null
};

export type UseUpdateOfferCouponMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  set: offer_coupon_set_input;
}>;


export type UseUpdateOfferCouponMutation = {
  update_offer_coupon_by_pk?: {
    __typename: 'offer_coupon',
    offer_id: number,
    override_active_coupon: boolean,
    offer: {
      __typename: 'offer',
      id: number,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        override_active_coupon: boolean,
        apply_to: offer_coupon_apply_to_enum
      } | null
    }
  } | null
};

export type UseUpdateOfferDowngradeMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
  changeAt: offer_change_plan_change_at_enum;
}>;


export type UseUpdateOfferDowngradeMutation = {
  update_offer_downgrade_by_pk?: {
    __typename: 'offer_downgrade',
    offer_id: number,
    change_at: offer_change_plan_change_at_enum,
    offer: {
      __typename: 'offer',
      id: number,
      offer_downgrade?: {
        __typename: 'offer_downgrade',
        offer_id: number,
        change_at: offer_change_plan_change_at_enum
      } | null
    }
  } | null
};

export type UseUpdateOfferPauseSubscriptionMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
  pauseAt: offer_pause_subscription_pause_at_enum;
}>;


export type UseUpdateOfferPauseSubscriptionMutation = {
  update_offer_pause_subscription_by_pk?: {
    __typename: 'offer_pause_subscription',
    offer_id: number,
    pause_at: offer_pause_subscription_pause_at_enum,
    offer: {
      __typename: 'offer',
      id: number,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          position: number,
          text_translation_id?: number | null
        }>
      } | null
    }
  } | null
};

export type UseUpdateOfferPauseSubscriptionCreateTranslationMutationVariables = Exact<{ [key: string]: never; }>;


export type UseUpdateOfferPauseSubscriptionCreateTranslationMutation = {
  insert_translation_one?: {
    __typename: 'translation',
    id: number
  } | null
};

export type UseUpdateOfferPauseSubscriptionSetTranslationMutationVariables = Exact<{
  offerPauseSubscriptionOptionId: Scalars['Int']['input'];
  textTranslationId: Scalars['Int']['input'];
}>;


export type UseUpdateOfferPauseSubscriptionSetTranslationMutation = {
  update_offer_pause_subscription_option_by_pk?: {
    __typename: 'offer_pause_subscription_option',
    id: number,
    text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  } | null
};

export type UseUpdateOfferPauseSubscriptionTranslationsMutationVariables = Exact<{
  translationValues: Array<translation_value_insert_input> | translation_value_insert_input;
}>;


export type UseUpdateOfferPauseSubscriptionTranslationsMutation = {
  insert_translation_value?: {
    __typename: 'translation_value_mutation_response',
    affected_rows: number,
    returning: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any
    }>
  } | null
};

export type UseUpdateOfferPauseSubscriptionOptionMetadataMutationVariables = Exact<{
  optionId: Scalars['Int']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type UseUpdateOfferPauseSubscriptionOptionMetadataMutation = {
  update_offer_pause_subscription_option_by_pk?: {
    __typename: 'offer_pause_subscription_option',
    id: number,
    metadata: any,
    offer_pause_subscription: {
      __typename: 'offer_pause_subscription',
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        name: string,
        override_active_offer: boolean,
        show_banner: boolean,
        type: offer_type_enum,
        goal: offer_goal_enum,
        style: offer_style_enum,
        image_url?: string | null,
        metadata: any,
        confirmation_enabled: boolean,
        created_at: string,
        archived_at?: string | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        confirmation_content_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        confirmation_confirm_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        confirmation_cancel_button_text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum,
          override_active_coupon: boolean,
          end_trial: boolean,
          platform_id?: string | null,
          platform_coupon?: {
            __typename: 'platform_coupon',
            code?: string | null
          } | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          reset_billing_date: boolean,
          offer_coupon_id?: number | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null,
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string,
            platform_id: string,
            platform_plan_group?: {
              __typename: 'platform_plan_group',
              name: string,
              platform_id: string,
              code?: string | null
            } | null
          } | null
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string,
            platform_id: string,
            platform_plan_group?: {
              __typename: 'platform_plan_group',
              name: string,
              platform_id: string,
              code?: string | null
            } | null
          } | null
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              active: boolean,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        account: {
          __typename: 'account',
          platform_connection?: {
            __typename: 'platform_connection',
            platform: platform_enum,
            import_completed_at?: string | null
          } | null
        },
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_tags: Array<{
          __typename: 'offer_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          strategy: offer_autopilot_strategy_enum
        } | null
      }
    }
  } | null
};

export type UseUpdateOfferRescheduleOrderMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
  skip: Scalars['Boolean']['input'];
  reschedule: Scalars['Boolean']['input'];
}>;


export type UseUpdateOfferRescheduleOrderMutation = {
  update_offer_reschedule_order_by_pk?: {
    __typename: 'offer_reschedule_order',
    offer_id: number,
    skip: boolean,
    reschedule: boolean,
    offer: {
      __typename: 'offer',
      id: number,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        offer_id: number,
        skip: boolean,
        reschedule: boolean
      } | null
    }
  } | null
};

export type UpdateOfferTimerMutationVariables = Exact<{
  object: offer_timer_insert_input;
}>;


export type UpdateOfferTimerMutation = {
  insert_offer_timer_one?: {
    __typename: 'offer_timer',
    offer_id: number,
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null
};

export type UseUpdateOfferUpgradeMutationVariables = Exact<{
  offerId: Scalars['Int']['input'];
  changeAt: offer_change_plan_change_at_enum;
}>;


export type UseUpdateOfferUpgradeMutation = {
  update_offer_upgrade_by_pk?: {
    __typename: 'offer_upgrade',
    offer_id: number,
    change_at: offer_change_plan_change_at_enum,
    offer: {
      __typename: 'offer',
      id: number,
      offer_upgrade?: {
        __typename: 'offer_upgrade',
        offer_id: number,
        change_at: offer_change_plan_change_at_enum
      } | null
    }
  } | null
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = {
  changePassword: {
    __typename: 'ChangePasswordOutput',
    success: boolean
  }
};

export type ProfileMutationVariables = Exact<{
  input: user_profile_insert_input;
}>;


export type ProfileMutation = {
  insert_user_profile_one?: {
    __typename: 'user_profile',
    id: string,
    first_name: string,
    last_name: string,
    email: string
  } | null
};

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = {
  viewer?: {
    __typename: 'ViewerOutput',
    firstName: string,
    lastName: string,
    email: string
  } | null
};

export type PropertiesQueryVariables = Exact<{
  where: property_bool_exp;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<property_order_by> | property_order_by>;
}>;


export type PropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>,
  property_aggregate: {
    __typename: 'property_aggregate',
    aggregate?: {
      __typename: 'property_aggregate_fields',
      count: number
    } | null
  }
};

export type PropertiesSearchQueryVariables = Exact<{
  where: property_bool_exp;
  search: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<property_order_by> | property_order_by>;
}>;


export type PropertiesSearchQuery = {
  search_properties: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>,
  search_properties_aggregate: {
    __typename: 'property_aggregate',
    aggregate?: {
      __typename: 'property_aggregate_fields',
      count: number
    } | null
  }
};

export type PropertyQueryVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type PropertyQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number
  }>,
  hubspot: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>,
  intercom: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>,
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    options: any
  }>,
  deflection: Array<{
    __typename: 'deflection',
    id: number
  }>
};

export type PropertyArchivePropertyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type PropertyArchivePropertyMutation = {
  update_property_by_pk?: {
    __typename: 'property',
    id: number
  } | null
};

export type PropertyDeletePropertyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type PropertyDeletePropertyMutation = {
  delete_segment_condition?: {
    __typename: 'segment_condition_mutation_response',
    affected_rows: number
  } | null,
  delete_property_by_pk?: {
    __typename: 'property',
    id: number
  } | null
};

export type PropertyPanelExistingPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertyPanelExistingPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    name: string
  }>
};

export type PropertyPanelMutationVariables = Exact<{
  object: property_insert_input;
}>;


export type PropertyPanelMutation = {
  insert_property_one?: {
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  } | null
};

export type PropertyPanelUpdateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: property_type_enum;
  format?: InputMaybe<property_format_enum>;
}>;


export type PropertyPanelUpdateMutation = {
  update_property_by_pk?: {
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  } | null
};

export type UsePropertyIntegrationMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UsePropertyIntegrationMappingsQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>,
  hubspot: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>,
  intercom: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>,
  segment: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>,
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    options: any
  }>
};

export type CampaignDiscoverOfferMutationVariables = Exact<{
  input: SubscriberCampaignOfferInput;
}>;


export type CampaignDiscoverOfferMutation = {
  subscriberCampaignOffer: {
    __typename: 'SubscriberCampaignOfferOutput',
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      show_banner: boolean,
      goal: offer_goal_enum,
      style: offer_style_enum,
      confirmation_enabled: boolean,
      image_url?: string | null,
      created_at: string,
      updated_at: string,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_coupon?: {
        __typename: 'offer_coupon',
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null
    } | null
  }
};

export type CampaignStartViewMutationVariables = Exact<{
  input: StartSubscriberCampaignViewInput;
}>;


export type CampaignStartViewMutation = {
  startSubscriberCampaignView?: {
    __typename: 'StartSubscriberCampaignViewOutput',
    token: string
  } | null
};

export type CampaignEndViewMutationVariables = Exact<{
  input: CompleteSubscriberCampaignViewInput;
}>;


export type CampaignEndViewMutation = {
  completeSubscriberCampaignView: {
    __typename: 'CompleteSubscriberCampaignViewOutput',
    success: boolean
  }
};

export type CampaignAcceptOfferMutationVariables = Exact<{
  subscriberCampaignToken: Scalars['String']['input'];
  offerId: Scalars['Int']['input'];
}>;


export type CampaignAcceptOfferMutation = {
  acceptCampaignOffer: {
    __typename: 'AcceptCampaignOfferOutput',
    success: boolean,
    needsPaymentMethod: boolean
  }
};

export type SubscriberCampaignConfirmationQueryVariables = Exact<{
  offerToken: Scalars['String']['input'];
}>;


export type SubscriberCampaignConfirmationQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    confirmation_content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_confirm_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    confirmation_cancel_button_text_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  }>
};

export type SubscriberCampaignPaymentQueryVariables = Exact<{
  input: SubscriberCampaignPaymentInfoInput;
}>;


export type SubscriberCampaignPaymentQuery = {
  subscriberCampaignPaymentInfo: {
    __typename: 'SubscriberCampaignPaymentInfoOutput',
    platform: string,
    platformId: string,
    publishableKey: string,
    addressRequirements?: string | null
  }
};

export type PaymentSetPaymentMethodMutationVariables = Exact<{
  input: SetSubscriberCampaignPaymentMethodInput;
}>;


export type PaymentSetPaymentMethodMutation = {
  setSubscriberCampaignPaymentMethod: {
    __typename: 'SetSubscriberCampaignPaymentMethodOutput',
    success: boolean,
    errorMessage?: string | null
  }
};

export type PaymentCreateStripeSetupIntentMutationVariables = Exact<{
  input: CreateSubscriberCampaignStripeSetupIntentInput;
}>;


export type PaymentCreateStripeSetupIntentMutation = {
  createSubscriberCampaignStripeSetupIntent: {
    __typename: 'CreateSubscriberCampaignStripeSetupIntentOutput',
    clientSecret: string
  }
};

export type CustomerPortalBillingDetailsQueryVariables = Exact<{
  input: PortalCustomerBillingDetailsInput;
}>;


export type CustomerPortalBillingDetailsQuery = {
  portalCustomerBillingDetails: {
    __typename: 'PortalCustomerBillingDetailsOutput',
    name?: string | null,
    card?: {
      __typename: 'PortalCustomerDetailsCard',
      brand: string,
      last4: string,
      expirationMonth: number,
      expirationYear: number
    } | null
  }
};

export type CustomerPortalHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerPortalHomeQuery = {
  portal_session: Array<{
    __typename: 'portal_session',
    return_url?: string | null,
    account: {
      __typename: 'account',
      id: number,
      token: string,
      title: string,
      logo_url?: string | null,
      favicon_url?: string | null,
      default_flow?: {
        __typename: 'flow',
        id: number,
        logo_url?: string | null
      } | null,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number,
        platform: platform_enum
      } | null
    },
    portal_session_subscribers: Array<{
      __typename: 'portal_session_subscriber',
      subscriber: {
        __typename: 'subscriber',
        platform_id: string,
        subscriptions: Array<{
          __typename: 'subscription',
          id: number,
          platform_id: string
        }>
      }
    }>
  }>
};

export type CustomerPortalHomeDeleteSessionMutationVariables = Exact<{
  sessionToken: Scalars['String']['input'];
}>;


export type CustomerPortalHomeDeleteSessionMutation = {
  update_portal_session?: {
    __typename: 'portal_session_mutation_response',
    affected_rows: number
  } | null
};

export type CustomerPortalInvoiceRowDownloadInvoiceMutationVariables = Exact<{
  input: PortalDownloadInvoiceInput;
}>;


export type CustomerPortalInvoiceRowDownloadInvoiceMutation = {
  portalDownloadInvoice: {
    __typename: 'PortalDownloadInvoiceOutput',
    url: string
  }
};

export type CustomerPortalLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerPortalLoginQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    token: string,
    title: string,
    logo_url?: string | null,
    favicon_url?: string | null,
    default_flow?: {
      __typename: 'flow',
      id: number,
      logo_url?: string | null
    } | null,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum
    } | null
  }>
};

export type LoginSendEmailVerificationMutationVariables = Exact<{
  input: SendEmailVerificationInput;
}>;


export type LoginSendEmailVerificationMutation = {
  sendEmailVerification: {
    __typename: 'SendEmailVerificationOutput',
    subscriberFound: boolean,
    rateLimited?: boolean | null
  }
};

export type LoginVerifyEmailCodeMutationVariables = Exact<{
  input: VerifyEmailCodeInput;
}>;


export type LoginVerifyEmailCodeMutation = {
  verifyEmailCode: {
    __typename: 'VerifyEmailCodeOutput',
    portalSessionToken?: string | null
  }
};

export type CustomerPortalPaymentMethodModalQueryVariables = Exact<{
  input: PortalSessionPaymentInfoInput;
}>;


export type CustomerPortalPaymentMethodModalQuery = {
  portalSessionPaymentInfo: {
    __typename: 'PortalSessionPaymentInfoOutput',
    platform: string,
    platformId: string,
    publishableKey: string,
    addressRequirements?: string | null
  }
};

export type CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutationVariables = Exact<{
  input: CreatePortalSessionStripeSetupIntentInput;
}>;


export type CustomerPortalPaymentMethodModalCreateStripeSetupIntentMutation = {
  createPortalSessionStripeSetupIntent: {
    __typename: 'CreatePortalSessionStripeSetupIntentOutput',
    clientSecret: string
  }
};

export type CustomerPortalPaymentMethodModalSetPaymentMethodMutationVariables = Exact<{
  input: SetPortalSessionPaymentMethodInput;
}>;


export type CustomerPortalPaymentMethodModalSetPaymentMethodMutation = {
  setPortalSessionPaymentMethod: {
    __typename: 'SetPortalSessionPaymentMethodOutput',
    success: boolean,
    errorMessage?: string | null
  }
};

export type CustomerPortalSubscriptionDetailsQueryVariables = Exact<{
  input: PortalSubscriptionDetailsInput;
}>;


export type CustomerPortalSubscriptionDetailsQuery = {
  portalSubscriptionDetails: {
    __typename: 'PortalSubscriptionDetailsOutput',
    name: string,
    platformId: string,
    status: string,
    trialEndsAt?: string | null,
    amount: string,
    currency: string,
    interval: string,
    intervalCount: number,
    nextBillingAt?: string | null,
    invoices: Array<{
      __typename: 'PortalSubscriptionDetailsInvoice',
      platformId: string,
      periodEnd: string,
      status: string,
      total: string,
      amountPaid: string,
      number: string
    }>
  }
};

export type CustomerPortalSubscriptionSignFlowPayloadMutationVariables = Exact<{
  input: SignCustomerPortalFlowPayloadInput;
}>;


export type CustomerPortalSubscriptionSignFlowPayloadMutation = {
  signCustomerPortalFlowPayload: {
    __typename: 'SignCustomerPortalFlowPayloadOutput',
    signature: string
  }
};

export type CustomerPortalSubscriptionQueryVariables = Exact<{
  input: PortalSubscriptionDetailsInput;
}>;


export type CustomerPortalSubscriptionQuery = {
  portalSubscriptionDetails: {
    __typename: 'PortalSubscriptionDetailsOutput',
    name: string,
    platformId: string,
    status: string,
    trialEndsAt?: string | null,
    amount: string,
    currency: string,
    interval: string,
    intervalCount: number
  }
};

export type DemoQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type DemoQuery = {
  demo: Array<{
    __typename: 'demo',
    id: number,
    token: string,
    company_name: string,
    logo_url?: string | null,
    brand_color?: string | null,
    client_id: string,
    flow_id: string,
    properties: any
  }>
};

export type FlowInitializeMutationVariables = Exact<{
  input: InitializeSubscriberFlowInput;
}>;


export type FlowInitializeMutation = {
  initializeSubscriberFlow: {
    __typename: 'InitializeSubscriberFlowOutput',
    token: string
  }
};

export type FlowCompleteMutationVariables = Exact<{
  input: CompleteSubscriberFlowInput;
}>;


export type FlowCompleteMutation = {
  completeSubscriberFlow: {
    __typename: 'CompleteSubscriberFlowOutput',
    flowSession: string
  }
};

export type FlowAcceptOfferMutationVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  offerId: Scalars['Int']['input'];
  offerVariantId?: InputMaybe<Scalars['Int']['input']>;
  selectedOptionIndex: Scalars['Int']['input'];
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
  platformVariantId?: InputMaybe<Scalars['String']['input']>;
  rescheduleTo?: InputMaybe<Scalars['String']['input']>;
  pauseReasonCode?: InputMaybe<Scalars['String']['input']>;
  pauseAt?: InputMaybe<Scalars['String']['input']>;
  resumeAt?: InputMaybe<Scalars['String']['input']>;
  flowStepToken: Scalars['String']['input'];
}>;


export type FlowAcceptOfferMutation = {
  acceptOffer: {
    __typename: 'AcceptOfferOutput',
    success: boolean
  },
  completeSubscriberFlow: {
    __typename: 'CompleteSubscriberFlowOutput',
    flowSession: string
  }
};

export type FlowDeclineOfferMutationVariables = Exact<{
  input: DeclineOfferInput;
}>;


export type FlowDeclineOfferMutation = {
  declineOffer: {
    __typename: 'DeclineOfferOutput',
    success: boolean
  }
};

export type FlowDeclineOfferGroupMutationVariables = Exact<{
  input: DeclineOfferGroupInput;
}>;


export type FlowDeclineOfferGroupMutation = {
  declineOfferGroup: {
    __typename: 'DeclineOfferGroupOutput',
    success: boolean
  }
};

export type FlowStartFlowStepMutationVariables = Exact<{
  input: StartFlowStepInput;
}>;


export type FlowStartFlowStepMutation = {
  startFlowStep: {
    __typename: 'StartFlowStepOutput',
    success: boolean
  }
};

export type FlowCompleteFlowStepMutationVariables = Exact<{
  input: CompleteFlowStepInput;
}>;


export type FlowCompleteFlowStepMutation = {
  completeFlowStep: {
    __typename: 'CompleteFlowStepOutput',
    success: boolean
  }
};

export type FlowSubmitCustomSubscriberDetailsMutationVariables = Exact<{
  input: SubmitCustomSubscriberDetailsInput;
}>;


export type FlowSubmitCustomSubscriberDetailsMutation = {
  submitCustomSubscriberDetails: {
    __typename: 'SubmitCustomSubscriberDetailsOutput',
    success: boolean,
    rerouted: boolean,
    message?: string | null,
    prevented?: boolean | null,
    subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      subscriber: {
        __typename: 'subscriber',
        id: number,
        name: string,
        email: string,
        subscriber_properties: Array<{
          __typename: 'subscriber_property',
          subscriber_id: number,
          property_id: number,
          value: any,
          property: {
            __typename: 'property',
            id: number,
            name: string,
            type: property_type_enum,
            format?: property_format_enum | null
          }
        }>
      },
      subscription: {
        __typename: 'subscription',
        id: number,
        subscription_properties: Array<{
          __typename: 'subscription_property',
          subscription_id: number,
          property_id: number,
          value: any,
          property: {
            __typename: 'property',
            id: number,
            name: string,
            type: property_type_enum,
            format?: property_format_enum | null
          }
        }>
      }
    } | null
  }
};

export type FlowClickFlowActionMutationVariables = Exact<{
  input: ClickFlowActionInput;
}>;


export type FlowClickFlowActionMutation = {
  clickFlowAction: {
    __typename: 'ClickFlowActionOutput',
    success: boolean,
    newSubscriberFlowToken?: string | null
  }
};

export type FlowSubmitQuestionAnswerMutationVariables = Exact<{
  input: SubmitQuestionAnswerInput;
}>;


export type FlowSubmitQuestionAnswerMutation = {
  submitQuestionAnswer: {
    __typename: 'SubmitQuestionAnswerOutput',
    success: boolean
  }
};

export type FlowSubmitFormAnswersMutationVariables = Exact<{
  input: SubmitFormAnswersInput;
}>;


export type FlowSubmitFormAnswersMutation = {
  submitFormAnswers: {
    __typename: 'SubmitFormAnswersOutput',
    success: boolean
  }
};

export type FlowDiscoverOfferQueryVariables = Exact<{
  input: SubscriberFlowOfferInput;
}>;


export type FlowDiscoverOfferQuery = {
  subscriberFlowOffer: {
    __typename: 'SubscriberFlowOfferOutput',
    flowOffer?: any | null,
    flowOfferGroup?: any | null,
    nextOrderDate?: string | null,
    subscription?: {
      __typename: 'subscription',
      id: number,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        swappable_ecommerce_products: Array<{
          __typename: 'platform_subscription_plan_swappable_ecommerce_product',
          platform_ecommerce_product?: {
            __typename: 'platform_ecommerce_product',
            platform_id: string,
            name: string,
            image_url?: string | null,
            platform_variants: Array<{
              __typename: 'platform_variant',
              platform_id: string,
              name: string,
              price?: string | null,
              platform_variant_options: Array<{
                __typename: 'platform_variant_option',
                key: string,
                value: string
              }>
            }>,
            platform_plan_ecommerce_products: Array<{
              __typename: 'platform_plan_ecommerce_product',
              discount_amount?: string | null,
              discount_type?: string | null
            }>
          } | null
        }>,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          price?: string | null,
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            price?: string | null,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string
            } | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      } | null
    } | null,
    platform_connection?: {
      __typename: 'platform_connection',
      platform: platform_enum,
      platform_ecommerce_products: Array<{
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        platform_variants: Array<{
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        }>,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      }>
    } | null,
    pauseReasons?: Array<{
      __typename: 'PauseReason',
      code: string,
      reason: string,
      minimumStartDate?: string | null,
      maximumStartDate?: string | null,
      maximumDays?: number | null
    }> | null
  }
};

export type FlowDiscoverTestOfferQueryVariables = Exact<{
  input: FlowTestOfferInput;
}>;


export type FlowDiscoverTestOfferQuery = {
  flowTestOffer: {
    __typename: 'FlowTestOfferOutput',
    flowOffer?: any | null,
    flowOfferGroup?: any | null,
    nextOrderDate?: string | null,
    platform_connection?: {
      __typename: 'platform_connection',
      platform: platform_enum,
      platform_ecommerce_products: Array<{
        __typename: 'platform_ecommerce_product',
        platform_id: string,
        name: string,
        image_url?: string | null,
        platform_variants: Array<{
          __typename: 'platform_variant',
          platform_id: string,
          name: string,
          price?: string | null,
          platform_variant_options: Array<{
            __typename: 'platform_variant_option',
            key: string,
            value: string
          }>
        }>,
        platform_plan_ecommerce_products: Array<{
          __typename: 'platform_plan_ecommerce_product',
          discount_amount?: string | null,
          discount_type?: string | null
        }>
      }>
    } | null,
    pauseReasons?: Array<{
      __typename: 'PauseReason',
      code: string,
      reason: string,
      minimumStartDate?: string | null,
      maximumStartDate?: string | null,
      maximumDays?: number | null
    }> | null
  }
};

export type FlowLogOfferPresentedMutationVariables = Exact<{
  input: LogOfferPresentedInput;
}>;


export type FlowLogOfferPresentedMutation = {
  logOfferPresented: {
    __typename: 'LogOfferPresentedOutput',
    success: boolean
  }
};

export type FlowLogOfferGroupPresentedMutationVariables = Exact<{
  input: LogOfferGroupPresentedInput;
}>;


export type FlowLogOfferGroupPresentedMutation = {
  logOfferGroupPresented: {
    __typename: 'LogOfferGroupPresentedOutput',
    success: boolean
  }
};

export type NewFlowSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type NewFlowSessionQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string,
    exclude_pii_in_flow_result: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      platform: platform_enum
    } | null
  }>
};

export type AddOnIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type AddOnIdDropdownQuery = {
  platform_addon: Array<{
    __typename: 'platform_addon',
    platform_id: string,
    name: string
  }>
};

export type CouponIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponIdDropdownQuery = {
  platform_coupon: Array<{
    __typename: 'platform_coupon',
    platform_id: string,
    name?: string | null
  }>
};

export type CreateSegmentPanelQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateSegmentPanelQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type CreateSegmentPanelMutationVariables = Exact<{
  input: segment_insert_input;
}>;


export type CreateSegmentPanelMutation = {
  insert_segment_one?: {
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type SegmentPanelCreateGroupMutationVariables = Exact<{
  group: segment_condition_group_insert_input;
}>;


export type SegmentPanelCreateGroupMutation = {
  insert_segment_condition_group_one?: {
    __typename: 'segment_condition_group',
    id: number
  } | null
};

export type SegmentPanelUpdateMutationVariables = Exact<{
  segmentId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  tagIds: Scalars['jsonb']['input'];
  groupId: Scalars['Int']['input'];
}>;


export type SegmentPanelUpdateMutation = {
  update_segment_by_pk?: {
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type FlowIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type FlowIdDropdownQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string
  }>
};

export type InlineSegmentInputContainerQueryVariables = Exact<{ [key: string]: never; }>;


export type InlineSegmentInputContainerQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type LocationInputCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationInputCountriesQuery = {
  country: Array<{
    __typename: 'country',
    iso: string,
    name: string
  }>
};

export type LocationInputStatesQueryVariables = Exact<{
  country: Scalars['citext']['input'];
}>;


export type LocationInputStatesQuery = {
  state: Array<{
    __typename: 'state',
    code: string,
    name: string
  }>
};

export type MultipleAddOnIdsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type MultipleAddOnIdsDropdownQuery = {
  platform_addon: Array<{
    __typename: 'platform_addon',
    platform_id: string,
    name: string
  }>
};

export type MultipleCouponIdsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type MultipleCouponIdsDropdownQuery = {
  platform_coupon: Array<{
    __typename: 'platform_coupon',
    platform_id: string,
    name?: string | null
  }>
};

export type MultipleOfferIdsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type MultipleOfferIdsDropdownQuery = {
  offer: Array<{
    __typename: 'offer',
    token: string,
    name: string,
    type: offer_type_enum
  }>
};

export type MultiplePlanIdsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type MultiplePlanIdsDropdownQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string,
    name: string,
    code?: string | null,
    platform_product?: {
      __typename: 'platform_product',
      name: string
    } | null
  }>
};

export type MultipleProductIdsDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type MultipleProductIdsDropdownQuery = {
  platform_product: Array<{
    __typename: 'platform_product',
    platform_id: string,
    name: string
  }>
};

export type OfferIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type OfferIdDropdownQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    name: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    created_at: string,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number
    } | null
  }>
};

export type PlanIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type PlanIdDropdownQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string,
    name: string,
    code?: string | null,
    platform_product?: {
      __typename: 'platform_product',
      name: string
    } | null
  }>
};

export type ProductIdDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductIdDropdownQuery = {
  platform_product: Array<{
    __typename: 'platform_product',
    platform_id: string,
    name: string
  }>
};

export type CanDeleteSegmentFlowStepFragment = {
  __typename: 'flow_step',
  id: number,
  published_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null
    }>
  } | null,
  flow_step_rule_group?: {
    __typename: 'flow_step_rule_group',
    flow_step_id: number,
    offer_rule_group_id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_ids: any
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_ids: any
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_ids: any
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_ids: any
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type DeleteSegmentMutationVariables = Exact<{
  segmentId: Scalars['Int']['input'];
}>;


export type DeleteSegmentMutation = {
  delete_segment_by_pk?: {
    __typename: 'segment',
    id: number
  } | null
};

export type SegmentDetailsQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SegmentDetailsQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>
};

export type SegmentCanDeleteQueryVariables = Exact<{
  segmentId: Scalars['Int']['input'];
}>;


export type SegmentCanDeleteQuery = {
  flow: Array<{
    __typename: 'flow',
    flow_route_rule_flows: Array<{
      __typename: 'flow_route_rule_flow',
      flow_route_rule?: {
        __typename: 'flow_route_rule',
        id: number,
        segment_ids: any
      } | null
    }>,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segments: Array<{
              __typename: 'flow_step_version_condition_segment',
              segment_id?: number | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_ids: any
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>,
  subscriber_flow_segment_aggregate: {
    __typename: 'subscriber_flow_segment_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_segment_aggregate_fields',
      count: number
    } | null
  },
  segment_group_segment: Array<{
    __typename: 'segment_group_segment',
    segment_group: {
      __typename: 'segment_group',
      id: number,
      name: string
    }
  }>
};

export type SegmentGroupDetailsQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SegmentGroupDetailsQuery = {
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    token: string,
    name: string,
    created_at: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment_group_id: number,
      segment_id: number,
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        token: string,
        name: string,
        integration_id?: number | null,
        integration_segment_id?: string | null,
        created_at: string,
        primary_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition_group?: {
              __typename: 'segment_condition_group',
              id: number,
              boolean_operator: segment_condition_boolean_operator_enum,
              segment_condition_group_entries: Array<{
                __typename: 'segment_condition_group_entry',
                entry_segment_condition?: {
                  __typename: 'segment_condition',
                  id: number,
                  property: segment_condition_property_enum,
                  operator: segment_condition_operator_enum,
                  value: any,
                  property_id?: number | null,
                  custom_property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>
            } | null,
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        integration?: {
          __typename: 'integration',
          id: number,
          type: integration_type_enum
        } | null,
        segment_tags: Array<{
          __typename: 'segment_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>
      }
    }>
  }>
};

export type DeleteSegmentGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteSegmentGroupMutation = {
  delete_segment_group_by_pk?: {
    __typename: 'segment_group',
    id: number
  } | null
};

export type SegmentGroupPanelExistingSegmentGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentGroupPanelExistingSegmentGroupsQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    name: string
  }>
};

export type SegmentGroupPanelCreateSegmentGroupMutationVariables = Exact<{
  input: CreateSegmentGroupInput;
}>;


export type SegmentGroupPanelCreateSegmentGroupMutation = {
  createSegmentGroup: {
    __typename: 'CreateSegmentGroupOutput',
    segmentGroup?: {
      __typename: 'segment_group',
      id: number,
      token: string,
      name: string,
      created_at: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        segment_group_id: number,
        segment_id: number,
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          token: string,
          name: string,
          integration_id?: number | null,
          integration_segment_id?: string | null,
          created_at: string,
          primary_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition_group?: {
                __typename: 'segment_condition_group',
                id: number,
                boolean_operator: segment_condition_boolean_operator_enum,
                segment_condition_group_entries: Array<{
                  __typename: 'segment_condition_group_entry',
                  entry_segment_condition?: {
                    __typename: 'segment_condition',
                    id: number,
                    property: segment_condition_property_enum,
                    operator: segment_condition_operator_enum,
                    value: any,
                    property_id?: number | null,
                    custom_property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>
              } | null,
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          integration?: {
            __typename: 'integration',
            id: number,
            type: integration_type_enum
          } | null,
          segment_tags: Array<{
            __typename: 'segment_tag',
            tag?: {
              __typename: 'tag',
              id: number,
              name: string
            } | null
          }>
        }
      }>
    } | null
  }
};

export type SegmentGroupPanelUpdateSegmentGroupMutationVariables = Exact<{
  input: UpdateSegmentGroupInput;
}>;


export type SegmentGroupPanelUpdateSegmentGroupMutation = {
  updateSegmentGroup: {
    __typename: 'UpdateSegmentGroupOutput',
    segmentGroup?: {
      __typename: 'segment_group',
      id: number,
      token: string,
      name: string,
      created_at: string,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        segment_group_id: number,
        segment_id: number,
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          token: string,
          name: string,
          integration_id?: number | null,
          integration_segment_id?: string | null,
          created_at: string,
          primary_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition_group?: {
                __typename: 'segment_condition_group',
                id: number,
                boolean_operator: segment_condition_boolean_operator_enum,
                segment_condition_group_entries: Array<{
                  __typename: 'segment_condition_group_entry',
                  entry_segment_condition?: {
                    __typename: 'segment_condition',
                    id: number,
                    property: segment_condition_property_enum,
                    operator: segment_condition_operator_enum,
                    value: any,
                    property_id?: number | null,
                    custom_property?: {
                      __typename: 'property',
                      id: number,
                      name: string,
                      entity: property_entity_enum,
                      type: property_type_enum,
                      format?: property_format_enum | null
                    } | null
                  } | null
                }>
              } | null,
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          integration?: {
            __typename: 'integration',
            id: number,
            type: integration_type_enum
          } | null,
          segment_tags: Array<{
            __typename: 'segment_tag',
            tag?: {
              __typename: 'tag',
              id: number,
              name: string
            } | null
          }>
        }
      }>
    } | null
  }
};

export type SegmentGroupsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<segment_group_order_by> | segment_group_order_by>;
}>;


export type SegmentGroupsQuery = {
  segment_group: Array<{
    __typename: 'segment_group',
    id: number,
    token: string,
    name: string,
    created_at: string,
    segment_group_segments_aggregate: {
      __typename: 'segment_group_segment_aggregate',
      aggregate?: {
        __typename: 'segment_group_segment_aggregate_fields',
        count: number
      } | null
    },
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment_group_id: number,
      segment_id: number,
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        token: string,
        name: string,
        integration_id?: number | null,
        integration_segment_id?: string | null,
        created_at: string,
        primary_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition_group?: {
              __typename: 'segment_condition_group',
              id: number,
              boolean_operator: segment_condition_boolean_operator_enum,
              segment_condition_group_entries: Array<{
                __typename: 'segment_condition_group_entry',
                entry_segment_condition?: {
                  __typename: 'segment_condition',
                  id: number,
                  property: segment_condition_property_enum,
                  operator: segment_condition_operator_enum,
                  value: any,
                  property_id?: number | null,
                  custom_property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>
            } | null,
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        integration?: {
          __typename: 'integration',
          id: number,
          type: integration_type_enum
        } | null,
        segment_tags: Array<{
          __typename: 'segment_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>
      }
    }>
  }>,
  segment_group_aggregate: {
    __typename: 'segment_group_aggregate',
    aggregate?: {
      __typename: 'segment_group_aggregate_fields',
      count: number
    } | null
  },
  segment_aggregate: {
    __typename: 'segment_aggregate',
    aggregate?: {
      __typename: 'segment_aggregate_fields',
      count: number
    } | null
  }
};

export type SegmentGroupsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<segment_group_order_by> | segment_group_order_by>;
}>;


export type SegmentGroupsSearchQuery = {
  search_segment_groups: Array<{
    __typename: 'segment_group',
    id: number,
    token: string,
    name: string,
    created_at: string,
    segment_group_segments: Array<{
      __typename: 'segment_group_segment',
      segment_group_id: number,
      segment_id: number,
      operator: segment_group_operator_enum,
      segment: {
        __typename: 'segment',
        id: number,
        token: string,
        name: string,
        integration_id?: number | null,
        integration_segment_id?: string | null,
        created_at: string,
        primary_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition_group?: {
              __typename: 'segment_condition_group',
              id: number,
              boolean_operator: segment_condition_boolean_operator_enum,
              segment_condition_group_entries: Array<{
                __typename: 'segment_condition_group_entry',
                entry_segment_condition?: {
                  __typename: 'segment_condition',
                  id: number,
                  property: segment_condition_property_enum,
                  operator: segment_condition_operator_enum,
                  value: any,
                  property_id?: number | null,
                  custom_property?: {
                    __typename: 'property',
                    id: number,
                    name: string,
                    entity: property_entity_enum,
                    type: property_type_enum,
                    format?: property_format_enum | null
                  } | null
                } | null
              }>
            } | null,
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        integration?: {
          __typename: 'integration',
          id: number,
          type: integration_type_enum
        } | null,
        segment_tags: Array<{
          __typename: 'segment_tag',
          tag?: {
            __typename: 'tag',
            id: number,
            name: string
          } | null
        }>
      }
    }>
  }>,
  search_segment_groups_aggregate: {
    __typename: 'segment_group_aggregate',
    aggregate?: {
      __typename: 'segment_group_aggregate_fields',
      count: number
    } | null
  }
};

export type SegmentGroupsDeleteSegmentGroupMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SegmentGroupsDeleteSegmentGroupMutation = {
  delete_segment_group_by_pk?: {
    __typename: 'segment_group',
    id: number
  } | null
};

export type SegmentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<segment_order_by> | segment_order_by>;
  where: segment_bool_exp;
}>;


export type SegmentsQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  segment_aggregate: {
    __typename: 'segment_aggregate',
    aggregate?: {
      __typename: 'segment_aggregate_fields',
      count: number
    } | null
  }
};

export type SegmentsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<segment_order_by> | segment_order_by>;
  where: segment_bool_exp;
}>;


export type SegmentsSearchQuery = {
  search_segments: Array<{
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  }>,
  search_segments_aggregate: {
    __typename: 'segment_aggregate',
    aggregate?: {
      __typename: 'segment_aggregate_fields',
      count: number
    } | null
  }
};

export type SegmentsIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentsIntegrationsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  }>
};

export type SegmentsHomeQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentsHomeQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  }>,
  segment: Array<{
    __typename: 'segment',
    id: number
  }>,
  segment_group: Array<{
    __typename: 'segment_group',
    id: number
  }>
};

export type SubscriptionsCardFragment = {
  __typename: 'subscription',
  id: number,
  subscriber_flows: Array<{
    __typename: 'subscriber_flow',
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>
  }>,
  subscriber_logs_subscriber: {
    __typename: 'subscriber',
    id: number,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      occurred_at: string,
      data: any,
      subscriber_log_offer?: {
        __typename: 'subscriber_log_offer',
        offer: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum
        }
      } | null
    }>
  },
  platform_subscription?: {
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_connection: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum
    },
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      platform_plan?: {
        __typename: 'platform_plan',
        platform_product?: {
          __typename: 'platform_product',
          name: string
        } | null
      } | null
    }>
  } | null,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    deleted_at?: string | null
  }
};

export type SubscriptionsCardQueryVariables = Exact<{
  segmentId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type SubscriptionsCardQuery = {
  subscription_segment: Array<{
    __typename: 'subscription_segment',
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_flows: Array<{
        __typename: 'subscriber_flow',
        subscriber_flow_question_answers: Array<{
          __typename: 'subscriber_flow_question_answer',
          question_answer: {
            __typename: 'question_answer',
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum
            },
            question_version: {
              __typename: 'question_version',
              id: number,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            },
            question_answer_radios: Array<{
              __typename: 'question_answer_radio',
              question_option_version: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              }
            }>,
            question_answer_text?: {
              __typename: 'question_answer_text',
              value: string,
              sentiment?: sentiment_enum | null
            } | null,
            question_answer_likelihood_to_return?: {
              __typename: 'question_answer_likelihood_to_return',
              value: number
            } | null
          }
        }>
      }>,
      subscriber_logs_subscriber: {
        __typename: 'subscriber',
        id: number,
        subscriber_logs: Array<{
          __typename: 'subscriber_log',
          id: number,
          event: subscriber_log_event_enum,
          occurred_at: string,
          data: any,
          subscriber_log_offer?: {
            __typename: 'subscriber_log_offer',
            offer: {
              __typename: 'offer',
              id: number,
              type: offer_type_enum
            }
          } | null
        }>
      },
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_connection_id: number,
        platform_id: string,
        platform_connection: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        },
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan?: {
            __typename: 'platform_plan',
            platform_product?: {
              __typename: 'platform_product',
              name: string
            } | null
          } | null
        }>
      } | null,
      subscriber: {
        __typename: 'subscriber',
        id: number,
        token: string,
        name: string,
        email: string,
        deleted_at?: string | null
      }
    }
  }>
};

export type CanDeleteSegmentGroupFlowStepFragment = {
  __typename: 'flow_step',
  id: number,
  published_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null
    }>
  } | null,
  flow_step_rule_group?: {
    __typename: 'flow_step_rule_group',
    flow_step_id: number,
    offer_rule_group_id: number,
    offer_rule_group: {
      __typename: 'offer_rule_group',
      id: number,
      published_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_group_ids: any
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_group_ids: any
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        id: number,
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            id: number,
            published_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_group_ids: any
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              id: number,
              segment_group_ids: any
            } | null
          } | null
        }>
      } | null
    }
  } | null
};

export type CanDeleteSegmentGroupQueryVariables = Exact<{ [key: string]: never; }>;


export type CanDeleteSegmentGroupQuery = {
  flow: Array<{
    __typename: 'flow',
    flow_route_rule_flows: Array<{
      __typename: 'flow_route_rule_flow',
      flow_route_rule?: {
        __typename: 'flow_route_rule',
        id: number,
        segment_group_ids: any
      } | null
    }>,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          published_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null
            }>
          } | null,
          draft_version?: {
            __typename: 'flow_step_version',
            id: number,
            flow_step_version_condition_segment_groups: Array<{
              __typename: 'flow_step_version_condition_segment_group',
              segment_group_id?: number | null
            }>
          } | null,
          flow_step_rule_group?: {
            __typename: 'flow_step_rule_group',
            flow_step_id: number,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              published_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_group_version',
                id: number,
                offer_rule_group_version_offer_rules: Array<{
                  __typename: 'offer_rule_group_version_offer_rule',
                  offer_rule?: {
                    __typename: 'offer_rule',
                    id: number,
                    published_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null,
                    draft_version?: {
                      __typename: 'offer_rule_version',
                      id: number,
                      segment_group_ids: any
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type SessionQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SessionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string,
      status: subscriber_flow_status_enum,
      automatically_deflected: boolean,
      user_agent?: string | null,
      payload?: any | null,
      segment_values?: any | null,
      created_at: string,
      updated_at: string,
      started_at?: string | null,
      completed_at?: string | null,
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        title: string
      },
      offer?: {
        __typename: 'offer',
        id: number,
        name: string
      } | null,
      subscriber_flow_offers: Array<{
        __typename: 'subscriber_flow_offer',
        subscriber_flow_id: number,
        offer_id: number,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        },
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null,
        offer_autopilot_offer_variant?: {
          __typename: 'offer_autopilot_offer_variant',
          variant_index?: number | null
        } | null
      }>,
      subscriber_flow_offer_groups: Array<{
        __typename: 'subscriber_flow_offer_group',
        subscriber_flow_id: number,
        offer_group_id: number,
        offer_id?: number | null,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer_group: {
          __typename: 'offer_group',
          id: number,
          name: string,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              type: offer_type_enum,
              name: string,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_id: number,
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  id: number,
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null
                }>
              } | null
            } | null
          }>
        },
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        } | null,
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null
      }>,
      subscriber_flow_deflections: Array<{
        __typename: 'subscriber_flow_deflection',
        subscriber_flow_id: number,
        deflection_id: number,
        flow_step_id: number,
        clicked_flow_action?: {
          __typename: 'flow_action',
          id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        deflection: {
          __typename: 'deflection',
          id: number,
          title: string
        }
      }>,
      subscription: {
        __typename: 'subscription',
        id: number,
        status: subscription_status_enum,
        mrr: string,
        subscriber_logs: Array<{
          __typename: 'subscriber_log',
          id: number,
          event: subscriber_log_event_enum,
          trial: boolean
        }>,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_id: string,
          code?: string | null
        } | null
      },
      subscriber: {
        __typename: 'subscriber',
        id: number,
        name: string,
        email: string,
        token: string,
        platform_id: string,
        deleted_at?: string | null
      },
      primary_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      text_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_overrides: Array<{
        __typename: 'subscriber_flow_override',
        reason: subscriber_flow_override_reason_enum,
        offer: {
          __typename: 'offer',
          id: number,
          name: string
        },
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string
        } | null
      }>,
      subscriber_flow_flow_steps: Array<{
        __typename: 'subscriber_flow_flow_step',
        flow_step_id: number
      }>,
      flow_steps_count: {
        __typename: 'subscriber_flow_flow_step_aggregate',
        aggregate?: {
          __typename: 'subscriber_flow_flow_step_aggregate_fields',
          count: number
        } | null
      }
    } | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type SessionDeleteSessionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SessionDeleteSessionMutation = {
  delete_subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number
  } | null
};

export type SessionSegmentPropertyValuesQueryVariables = Exact<{
  input: SubscriptionSegmentPropertyValuesInput;
}>;


export type SessionSegmentPropertyValuesQuery = {
  subscriptionSegmentPropertyValues: {
    __typename: 'SubscriptionSegmentPropertyValuesOutput',
    values: any
  }
};

export type SessionCustomPropertiesCardQueryVariables = Exact<{
  propertyIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SessionCustomPropertiesCardQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string
  }>
};

export type SessionStepsCardQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SessionStepsCardQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    session_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      },
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_offers: Array<{
        __typename: 'subscriber_flow_offer',
        subscriber_flow_id: number,
        offer_id: number,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        },
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null,
        offer_autopilot_offer_variant?: {
          __typename: 'offer_autopilot_offer_variant',
          variant_index?: number | null
        } | null
      }>,
      subscriber_flow_offer_groups: Array<{
        __typename: 'subscriber_flow_offer_group',
        subscriber_flow_id: number,
        offer_group_id: number,
        offer_id?: number | null,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer_group: {
          __typename: 'offer_group',
          id: number,
          name: string,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              type: offer_type_enum,
              name: string,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_id: number,
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  id: number,
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null
                }>
              } | null
            } | null
          }>
        },
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        } | null,
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null
      }>,
      subscriber_flow_deflections: Array<{
        __typename: 'subscriber_flow_deflection',
        flow_step_id: number,
        deflection: {
          __typename: 'deflection',
          id: number,
          token: string,
          title: string,
          tag_ids: any,
          created_at: string,
          archived_at?: string | null,
          minimum_items?: number | null,
          heading_translation_id: number,
          content_translation_id: number,
          deflection_tags: Array<{
            __typename: 'deflection_tag',
            tag?: {
              __typename: 'tag',
              id: number,
              name: string
            } | null
          }>,
          heading_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          deflection_snapshot_items: Array<{
            __typename: 'deflection_snapshot_item_position',
            position?: number | null,
            deflection_snapshot_item?: {
              __typename: 'deflection_snapshot_item',
              id: number,
              token: string,
              icon_prefix?: string | null,
              icon_name?: string | null,
              property_id?: number | null,
              property_condition_value?: any | null,
              date_format?: string | null,
              text_translation_id: number,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>,
          deflection_flow_actions: Array<{
            __typename: 'deflection_flow_action',
            position?: number | null,
            flow_action?: {
              __typename: 'flow_action',
              id: number,
              token: string,
              code?: string | null,
              type: flow_action_type_enum,
              account_id: number,
              appearance: flow_action_appearance_enum,
              url?: string | null,
              reroute_to_flow_id?: number | null,
              text_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }>
        },
        clicked_flow_action?: {
          __typename: 'flow_action',
          id: number,
          token: string,
          code?: string | null,
          type: flow_action_type_enum,
          account_id: number,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow_id?: number | null,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }>
    }>,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  } | null
};

export type SessionsExportMutationVariables = Exact<{
  where?: InputMaybe<Scalars['jsonb']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type SessionsExportMutation = {
  exportSessionsNew: {
    __typename: 'ExportSessionsNewOutput',
    token: string
  }
};

export type SessionsHasSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionsHasSessionsQuery = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type SessionsFilterQuestionOptionQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SessionsFilterQuestionOptionQuery = {
  question_option: Array<{
    __typename: 'question_option',
    id: number,
    published_version?: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null
  }>
};

export type SessionsFlowQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionsFlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    deleted_at?: string | null,
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number
    }>
  }>
};

export type UseSessionsQueryVariables = Exact<{
  where?: InputMaybe<subscriber_flow_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<subscriber_flow_order_by> | subscriber_flow_order_by>;
}>;


export type UseSessionsQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type UseSessionsAggQueryVariables = Exact<{
  where?: InputMaybe<subscriber_flow_bool_exp>;
}>;


export type UseSessionsAggQuery = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type UseSessionsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<subscriber_flow_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<subscriber_flow_order_by> | subscriber_flow_order_by>;
}>;


export type UseSessionsSearchQuery = {
  search_subscriber_flows: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>,
  search_subscriber_flows_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type SessionsSubscriptionVariables = Exact<{
  where?: InputMaybe<subscriber_flow_bool_exp>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<subscriber_flow_order_by> | subscriber_flow_order_by>;
}>;


export type SessionsSubscription = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    user_agent?: string | null,
    payload?: any | null,
    segment_values?: any | null,
    created_at: string,
    updated_at: string,
    started_at?: string | null,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      name: string
    } | null,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_id: number,
      deflection_id: number,
      flow_step_id: number,
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null,
      deflection: {
        __typename: 'deflection',
        id: number,
        title: string
      }
    }>,
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum,
      mrr: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        id: number,
        event: subscriber_log_event_enum,
        trial: boolean
      }>,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      token: string,
      platform_id: string,
      deleted_at?: string | null
    },
    primary_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    text_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_overrides: Array<{
      __typename: 'subscriber_flow_override',
      reason: subscriber_flow_override_reason_enum,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      },
      offer_group?: {
        __typename: 'offer_group',
        id: number,
        name: string
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step_id: number
    }>,
    flow_steps_count: {
      __typename: 'subscriber_flow_flow_step_aggregate',
      aggregate?: {
        __typename: 'subscriber_flow_flow_step_aggregate_fields',
        count: number
      } | null
    }
  }>
};

export type SessionsAggSubscriptionVariables = Exact<{
  where?: InputMaybe<subscriber_flow_bool_exp>;
}>;


export type SessionsAggSubscription = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type AccountCardFetchSecretQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type AccountCardFetchSecretQuery = {
  account_by_pk?: {
    __typename: 'account',
    secret: string
  } | null
};

export type AccountSubscriptionCardQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSubscriptionCardQuery = {
  accountSubscription: {
    __typename: 'AccountSubscriptionOutput',
    stripeSubscriptionId: string,
    status: string,
    name?: string | null,
    trialExpiresAt?: string | null,
    cost?: string | null,
    interval?: string | null,
    intervalCount?: number | null
  }
};

export type AccountSubscriptionCardPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSubscriptionCardPaymentMethodQuery = {
  paymentMethod: {
    __typename: 'PaymentMethodOutput',
    stripePaymentMethodId?: string | null,
    lastFour?: string | null,
    brand?: string | null,
    expireMonth?: number | null,
    expireYear?: number | null,
    name?: string | null,
    email?: string | null
  }
};

export type AccountSubscriptionCardCreateStripePortalSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type AccountSubscriptionCardCreateStripePortalSessionMutation = {
  createStripePortalSession: {
    __typename: 'CreateStripePortalSessionOutput',
    url: string
  }
};

export type ApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeysQuery = {
  api_key: Array<{
    __typename: 'api_key',
    id: number,
    name: string,
    test_mode_key: string,
    created_at: string
  }>
};

export type ApiKeysDeleteMutationVariables = Exact<{
  apiKeyId: Scalars['Int']['input'];
}>;


export type ApiKeysDeleteMutation = {
  delete_api_key_by_pk?: {
    __typename: 'api_key',
    id: number
  } | null
};

export type CreateApiKeyModalMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;


export type CreateApiKeyModalMutation = {
  createApiKey: {
    __typename: 'CreateApiKeyOutput',
    key: string,
    apiKey?: {
      __typename: 'api_key',
      id: number,
      name: string,
      test_mode_key: string,
      created_at: string
    } | null
  }
};

export type ConfiureSsoModalMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  ssoDomain: Scalars['String']['input'];
  ssoRequired: Scalars['Boolean']['input'];
}>;


export type ConfiureSsoModalMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    sso_domain?: string | null,
    sso_required: boolean
  } | null
};

export type CustomerPortalUpdateSettingMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  faviconUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type CustomerPortalUpdateSettingMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    customer_portal_enabled: boolean,
    logo_url?: string | null,
    favicon_url?: string | null
  } | null
};

export type EditAccountModalMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  revenueMode: account_revenue_mode_enum;
}>;


export type EditAccountModalMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string,
    revenue_mode: account_revenue_mode_enum
  } | null
};

export type EmailNotificationsCardSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailNotificationsCardSettingsQuery = {
  account_user_setting: Array<{
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  }>
};

export type EmailNotificationsCardUpdateSettingMutationVariables = Exact<{
  key: account_user_setting_key_enum;
  value: Scalars['jsonb']['input'];
}>;


export type EmailNotificationsCardUpdateSettingMutation = {
  insert_account_user_setting_one?: {
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  } | null
};

export type ExportsQueryVariables = Exact<{
  after: Scalars['timestamptz']['input'];
}>;


export type ExportsQuery = {
  subscriber_flow_export: Array<{
    __typename: 'subscriber_flow_export',
    id: number,
    token: string,
    url?: string | null,
    created_at: string,
    expires_at?: string | null
  }>
};

export type ExportsSubscriptionVariables = Exact<{
  after: Scalars['timestamptz']['input'];
}>;


export type ExportsSubscription = {
  subscriber_flow_export: Array<{
    __typename: 'subscriber_flow_export',
    id: number,
    token: string,
    url?: string | null,
    created_at: string,
    expires_at?: string | null
  }>
};

export type DataConnectorLogsQueryVariables = Exact<{
  where?: InputMaybe<data_connector_request_log_bool_exp>;
}>;


export type DataConnectorLogsQuery = {
  data_connector_request_log: Array<{
    __typename: 'data_connector_request_log',
    id: string,
    data_connector_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type DataConnectorLogsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<data_connector_request_log_bool_exp>;
}>;


export type DataConnectorLogsSearchQuery = {
  search_data_connector_request_logs: Array<{
    __typename: 'data_connector_request_log',
    id: string,
    data_connector_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type DataConnectorLogsSubscriptionVariables = Exact<{
  where?: InputMaybe<data_connector_request_log_bool_exp>;
}>;


export type DataConnectorLogsSubscription = {
  data_connector_request_log: Array<{
    __typename: 'data_connector_request_log',
    id: string,
    data_connector_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type DataConnectorLogsDataConnectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type DataConnectorLogsDataConnectorsQuery = {
  data_connector: Array<{
    __typename: 'data_connector',
    id: number,
    url: string
  }>
};

export type DataConnectorLogsHarQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type DataConnectorLogsHarQuery = {
  data_connector_request_log: Array<{
    __typename: 'data_connector_request_log',
    id: string,
    data_connector_id: number,
    token: string,
    har: any,
    status: number,
    method: string,
    url: string,
    created_at: string
  }>
};

export type LogsQueryVariables = Exact<{
  where?: InputMaybe<platform_api_log_bool_exp>;
}>;


export type LogsQuery = {
  platform_api_log: Array<{
    __typename: 'platform_api_log',
    id: string,
    platform_connection_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type LogsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<platform_api_log_bool_exp>;
}>;


export type LogsSearchQuery = {
  search_platform_api_logs: Array<{
    __typename: 'platform_api_log',
    id: string,
    platform_connection_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type LogsSubscriptionVariables = Exact<{
  where?: InputMaybe<platform_api_log_bool_exp>;
}>;


export type LogsSubscription = {
  platform_api_log: Array<{
    __typename: 'platform_api_log',
    id: string,
    platform_connection_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type LogsPlatformsQueryVariables = Exact<{ [key: string]: never; }>;


export type LogsPlatformsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum
  }>
};

export type LogsHarQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type LogsHarQuery = {
  platform_api_log: Array<{
    __typename: 'platform_api_log',
    id: string,
    platform_connection_id: number,
    token: string,
    har: any,
    status: number,
    method: string,
    url: string,
    created_at: string
  }>
};

export type WebhookLogsQueryVariables = Exact<{
  where?: InputMaybe<webhook_request_log_bool_exp>;
}>;


export type WebhookLogsQuery = {
  webhook_request_log: Array<{
    __typename: 'webhook_request_log',
    id: string,
    webhook_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type WebhookLogsSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<webhook_request_log_bool_exp>;
}>;


export type WebhookLogsSearchQuery = {
  search_webhook_request_logs: Array<{
    __typename: 'webhook_request_log',
    id: string,
    webhook_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type WebhookLogsSubscriptionVariables = Exact<{
  where?: InputMaybe<webhook_request_log_bool_exp>;
}>;


export type WebhookLogsSubscription = {
  webhook_request_log: Array<{
    __typename: 'webhook_request_log',
    id: string,
    webhook_id: number,
    token: string,
    method: string,
    url: string,
    status: number,
    created_at: string
  }>
};

export type WebhookLogsWebhooksQueryVariables = Exact<{ [key: string]: never; }>;


export type WebhookLogsWebhooksQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number,
    url: string
  }>
};

export type WebhookLogsHarQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type WebhookLogsHarQuery = {
  webhook_request_log: Array<{
    __typename: 'webhook_request_log',
    id: string,
    webhook_id: number,
    token: string,
    har: any,
    status: number,
    method: string,
    url: string,
    created_at: string
  }>
};

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = {
  property_aggregate: {
    __typename: 'property_aggregate',
    aggregate?: {
      __typename: 'property_aggregate_fields',
      count: number
    } | null
  },
  api_key_aggregate: {
    __typename: 'api_key_aggregate',
    aggregate?: {
      __typename: 'api_key_aggregate_fields',
      count: number
    } | null
  }
};

export type SsoCardQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SsoCardQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    sso_log_in_url?: string | null,
    sso_domain?: string | null,
    sso_required: boolean
  } | null
};

export type UpdateApiKeyModalChargebeeMutationVariables = Exact<{
  input: UpdateChargebeeApiKeyInput;
}>;


export type UpdateApiKeyModalChargebeeMutation = {
  updateChargebeeApiKey: {
    __typename: 'UpdateChargebeeApiKeyOutput',
    success: boolean
  }
};

export type UpdateApiKeyModalMaxioMutationVariables = Exact<{
  input: UpdateMaxioApiKeyInput;
}>;


export type UpdateApiKeyModalMaxioMutation = {
  updateMaxioApiKey: {
    __typename: 'UpdateMaxioApiKeyOutput',
    success: boolean
  }
};

export type UpdateApiKeyModalRecurlyMutationVariables = Exact<{
  input: UpdateRecurlyApiKeyInput;
}>;


export type UpdateApiKeyModalRecurlyMutation = {
  updateRecurlyApiKey: {
    __typename: 'UpdateRecurlyApiKeyOutput',
    success: boolean
  }
};

export type UpdateApiKeyModalRechargeMutationVariables = Exact<{
  input: UpdateRechargeApiTokenInput;
}>;


export type UpdateApiKeyModalRechargeMutation = {
  updateRechargeApiToken: {
    __typename: 'UpdateRechargeApiTokenOutput',
    success: boolean
  }
};

export type UpdateApiKeyModalOpenPayMutationVariables = Exact<{
  input: UpdateOpenPayApiTokenInput;
}>;


export type UpdateApiKeyModalOpenPayMutation = {
  updateOpenPayApiToken: {
    __typename: 'UpdateOpenPayApiTokenOutput',
    success: boolean
  }
};

export type UsersCardRemoveUserMutationVariables = Exact<{
  input: RemoveAccountUserInput;
}>;


export type UsersCardRemoveUserMutation = {
  removeAccountUser: {
    __typename: 'RemoveAccountUserOutput',
    success: boolean
  }
};

export type UsersCardQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type UsersCardQuery = {
  user: Array<{
    __typename: 'user',
    id: string,
    name?: string | null,
    email?: string | null,
    picture?: string | null,
    provider?: string | null,
    activated_at?: string | null,
    user_profiles: Array<{
      __typename: 'user_profile',
      first_name: string,
      last_name: string,
      email: string
    }>,
    owned_accounts: Array<{
      __typename: 'account',
      id: number
    }>
  }>
};

export type UsersCardSubscriptionVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type UsersCardSubscription = {
  user: Array<{
    __typename: 'user',
    id: string,
    name?: string | null,
    email?: string | null,
    picture?: string | null,
    provider?: string | null,
    activated_at?: string | null,
    user_profiles: Array<{
      __typename: 'user_profile',
      first_name: string,
      last_name: string,
      email: string
    }>,
    owned_accounts: Array<{
      __typename: 'account',
      id: number
    }>
  }>
};

export type IntegrationPlatformQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationPlatformQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    platform_id: string,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      token: string
    }
  }>
};

export type IntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IntegrationsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  }>
};

export type MetadataPropertiesQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type MetadataPropertiesQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    options: any,
    meta: any
  } | null,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type MetadataPropertiesUpdateMappingsMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type MetadataPropertiesUpdateMappingsMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    options: any,
    meta: any
  } | null
};

export type BoldIntegrationQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type BoldIntegrationQuery = {
  account_by_pk?: {
    __typename: 'account',
    secret: string
  } | null
};

export type ConnectBoldMutationVariables = Exact<{
  input: ConnectBoldInput;
}>;


export type ConnectBoldMutation = {
  connectBold: {
    __typename: 'ConnectBoldOutput',
    success: boolean
  }
};

export type ChargebeeIntegrationUpdateMetaMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ChargebeeIntegrationUpdateMetaMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    meta: any
  } | null
};

export type ChargebeeUpdatePublishableKeyMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  publishableKey: Scalars['String']['input'];
}>;


export type ChargebeeUpdatePublishableKeyMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    publishable_key?: string | null
  } | null
};

export type CreateDataConnectorMutationVariables = Exact<{
  object: data_connector_insert_input;
}>;


export type CreateDataConnectorMutation = {
  insert_data_connector_one?: {
    __typename: 'data_connector',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string
  } | null
};

export type DataConnectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type DataConnectorsQuery = {
  data_connector: Array<{
    __typename: 'data_connector',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string
  }>
};

export type DataConnectorsToggleDisabledMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  disabled: Scalars['Boolean']['input'];
}>;


export type DataConnectorsToggleDisabledMutation = {
  update_data_connector_by_pk?: {
    __typename: 'data_connector',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string
  } | null
};

export type DeleteDataConnectorMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteDataConnectorMutation = {
  update_data_connector_by_pk?: {
    __typename: 'data_connector',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string
  } | null
};

export type ConnectHubSpotMutationVariables = Exact<{
  input: ConnectHubSpotInput;
}>;


export type ConnectHubSpotMutation = {
  connectHubSpot: {
    __typename: 'ConnectHubSpotOutput',
    success: boolean
  }
};

export type HubSpotIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type HubSpotIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>
};

export type HubSpotIntegrationDisconnectMutationVariables = Exact<{ [key: string]: never; }>;


export type HubSpotIntegrationDisconnectMutation = {
  delete_integration?: {
    __typename: 'integration_mutation_response',
    affected_rows: number
  } | null
};

export type HubSpotIntegrationUpdateOptionsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
}>;


export type HubSpotIntegrationUpdateOptionsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any
  } | null
};

export type HubSpotObjectTypePropertiesQueryVariables = Exact<{
  objectType: Scalars['String']['input'];
}>;


export type HubSpotObjectTypePropertiesQuery = {
  hubSpotProperties: Array<{
    __typename: 'HubSpotProperty',
    name: string,
    type: string,
    label: string,
    description: string
  }>
};

export type HubSpotPropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  objectType: Scalars['String']['input'];
}>;


export type HubSpotPropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  hubSpotProperties: Array<{
    __typename: 'HubSpotProperty',
    name: string,
    type: string,
    label: string,
    description: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type HubSpotPropertiesUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type HubSpotPropertiesUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type HubSpotQuestionMappingsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  objectType: Scalars['String']['input'];
}>;


export type HubSpotQuestionMappingsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  hubSpotProperties: Array<{
    __typename: 'HubSpotProperty',
    name: string,
    type: string,
    label: string,
    description: string
  }>,
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step_id?: number | null,
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              id: number,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type HubSpotQuestionMappingsUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type HubSpotQuestionMappingsUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ConnectIntercomMutationVariables = Exact<{
  input: ConnectIntercomInput;
}>;


export type ConnectIntercomMutation = {
  connectIntercom: {
    __typename: 'ConnectIntercomOutput',
    success: boolean
  }
};

export type IntercomIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type IntercomIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type IntercomIntegrationDisconnectMutationVariables = Exact<{ [key: string]: never; }>;


export type IntercomIntegrationDisconnectMutation = {
  delete_integration?: {
    __typename: 'integration_mutation_response',
    affected_rows: number
  } | null
};

export type IntercomPropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type IntercomPropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  intercomDataAttributes: Array<{
    __typename: 'IntercomDataAttribute',
    name: string,
    type: string,
    label: string,
    description: string,
    isCustom: boolean
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type IntercomPropertiesUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type IntercomPropertiesUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type IntercomQuestionMappingsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type IntercomQuestionMappingsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  intercomDataAttributes: Array<{
    __typename: 'IntercomDataAttribute',
    name: string,
    type: string,
    label: string,
    description: string,
    isCustom: boolean
  }>,
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step_id?: number | null,
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              id: number,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type IntercomQuestionMappingsUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type IntercomQuestionMappingsUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ConnectKlaviyoMutationVariables = Exact<{
  input: ConnectKlaviyoInput;
}>;


export type ConnectKlaviyoMutation = {
  connectKlaviyo: {
    __typename: 'ConnectKlaviyoOutput',
    success: boolean
  }
};

export type KlaviyoGeneralSettingsUpdateOptionsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type KlaviyoGeneralSettingsUpdateOptionsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type KlaviyoIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type KlaviyoIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  }>
};

export type KlaviyoIntegrationDisconnectMutationVariables = Exact<{ [key: string]: never; }>;


export type KlaviyoIntegrationDisconnectMutation = {
  delete_integration?: {
    __typename: 'integration_mutation_response',
    affected_rows: number
  } | null
};

export type KlaviyoIntegrationCreateCodeChallengeMutationVariables = Exact<{ [key: string]: never; }>;


export type KlaviyoIntegrationCreateCodeChallengeMutation = {
  createKlaviyoCodeChallenge: {
    __typename: 'CreateKlaviyoCodeChallengeOutput',
    codeChallenge: string
  }
};

export type KlaviyoPropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type KlaviyoPropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type KlaviyoPropertiesUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type KlaviyoPropertiesUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type KlaviyoQuestionMappingsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type KlaviyoQuestionMappingsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step_id?: number | null,
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              id: number,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type KlaviyoQuestionMappingsUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type KlaviyoQuestionMappingsUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ActivateNavigaModalMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ActivateNavigaModalMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    meta: any
  } | null
};

export type AllowInGraceNavigaModalMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type AllowInGraceNavigaModalMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    meta: any
  } | null
};

export type ConfigureNavigaCustomerServiceEndpointsModalMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  objects: Array<platform_connection_naviga_paper_code_endpoint_insert_input> | platform_connection_naviga_paper_code_endpoint_insert_input;
}>;


export type ConfigureNavigaCustomerServiceEndpointsModalMutation = {
  delete_platform_connection_naviga_paper_code_endpoint?: {
    __typename: 'platform_connection_naviga_paper_code_endpoint_mutation_response',
    affected_rows: number
  } | null,
  insert_platform_connection_naviga_paper_code_endpoint?: {
    __typename: 'platform_connection_naviga_paper_code_endpoint_mutation_response',
    affected_rows: number
  } | null
};

export type ConfigureNavigaDomainsModalMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  objects: Array<platform_connection_naviga_domain_insert_input> | platform_connection_naviga_domain_insert_input;
}>;


export type ConfigureNavigaDomainsModalMutation = {
  delete_platform_connection_naviga_domain?: {
    __typename: 'platform_connection_naviga_domain_mutation_response',
    affected_rows: number
  } | null,
  insert_platform_connection_naviga_domain?: {
    __typename: 'platform_connection_naviga_domain_mutation_response',
    affected_rows: number
  } | null
};

export type NavigaImportingModalSubscriptionVariables = Exact<{
  jobId: Scalars['String']['input'];
}>;


export type NavigaImportingModalSubscription = {
  job_by_pk?: {
    __typename: 'job',
    id: string,
    data: any
  } | null
};

export type NavigaIntegrationQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  mediaGroupCode: Scalars['String']['input'];
}>;


export type NavigaIntegrationQuery = {
  platform_connection_naviga_domain: Array<{
    __typename: 'platform_connection_naviga_domain',
    platform_connection_id: number,
    domain: string
  }>,
  availableNavigaDomains: {
    __typename: 'AvailableNavigaDomainsOutput',
    domains: Array<{
      __typename: 'NavigaDomain',
      domain: string,
      clientCode: string,
      defaultPaperCode: string
    }>
  },
  naviga_paper_code: Array<{
    __typename: 'naviga_paper_code',
    media_group_code: string,
    client_code: string,
    paper_code: string,
    platform_connection_naviga_paper_code_endpoint?: {
      __typename: 'platform_connection_naviga_paper_code_endpoint',
      endpoint: string,
      access_controlled: boolean
    } | null
  }>
};

export type NavigaRateCodesHasRateCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type NavigaRateCodesHasRateCodesQuery = {
  platform_newspaper_rate_code: Array<{
    __typename: 'platform_newspaper_rate_code',
    rate_code: string
  }>
};

export type NavigaRateCodeQueryVariables = Exact<{
  where?: InputMaybe<platform_newspaper_rate_code_bool_exp>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type NavigaRateCodeQuery = {
  platform_newspaper_rate_code: Array<{
    __typename: 'platform_newspaper_rate_code',
    rate_code: string,
    interval: platform_plan_interval_enum,
    interval_count: number,
    price: string
  }>,
  platform_newspaper_rate_code_aggregate: {
    __typename: 'platform_newspaper_rate_code_aggregate',
    aggregate?: {
      __typename: 'platform_newspaper_rate_code_aggregate_fields',
      count: number
    } | null
  }
};

export type NavigaRateCodeFormDeleteMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  code: Scalars['String']['input'];
  interval: platform_plan_interval_enum;
  intervalCount: Scalars['Int']['input'];
}>;


export type NavigaRateCodeFormDeleteMutation = {
  delete_platform_newspaper_rate_code?: {
    __typename: 'platform_newspaper_rate_code_mutation_response',
    affected_rows: number
  } | null
};

export type NavigaRateCodeMappingImportMutationVariables = Exact<{
  input: ImportNavigaCsvInput;
}>;


export type NavigaRateCodeMappingImportMutation = {
  importNavigaCsv: {
    __typename: 'ImportNavigaCsvOutput',
    jobId: string
  }
};

export type UseNavigaRateCodeFormUpdateMutationVariables = Exact<{
  rateCodeMapping: platform_newspaper_rate_code_insert_input;
}>;


export type UseNavigaRateCodeFormUpdateMutation = {
  insert_platform_newspaper_rate_code_one?: {
    __typename: 'platform_newspaper_rate_code',
    rate_code: string,
    interval: platform_plan_interval_enum,
    price: string
  } | null
};

export type RechargeIntegrationQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type RechargeIntegrationQuery = {
  account_by_pk?: {
    __typename: 'account',
    secret: string
  } | null
};

export type RecurlyPropertiesQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type RecurlyPropertiesQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    options: any,
    meta: any
  } | null,
  recurlyCustomFields: Array<{
    __typename: 'RecurlyCustomField',
    name: string,
    label: string,
    description: string,
    resourceType: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type RecurlyPropertiesUpdateMappingsMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type RecurlyPropertiesUpdateMappingsMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ConnectSalesforceMutationVariables = Exact<{
  input: ConnectSalesforceInput;
}>;


export type ConnectSalesforceMutation = {
  connectSalesforce: {
    __typename: 'ConnectSalesforceOutput',
    success: boolean
  }
};

export type SalesforceIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type SalesforceIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number
  }>
};

export type SalesforceIntegrationDisconnectMutationVariables = Exact<{ [key: string]: never; }>;


export type SalesforceIntegrationDisconnectMutation = {
  delete_integration?: {
    __typename: 'integration_mutation_response',
    affected_rows: number
  } | null
};

export type SalesforcePropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SalesforcePropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  salesforceFields: Array<{
    __typename: 'SalesforceField',
    name: string,
    type: string,
    label: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type SalesforcePropertiesUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SalesforcePropertiesUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type SalesforceQuestionMappingsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SalesforceQuestionMappingsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  salesforceFields: Array<{
    __typename: 'SalesforceField',
    name: string,
    type: string,
    label: string
  }>,
  flow: Array<{
    __typename: 'flow',
    id: number,
    title: string,
    published_version?: {
      __typename: 'flow_version',
      id: number,
      flow_version_flow_steps: Array<{
        __typename: 'flow_version_flow_step',
        flow_step_id?: number | null,
        flow_step?: {
          __typename: 'flow_step',
          id: number,
          flow_step_question?: {
            __typename: 'flow_step_question',
            flow_step_id: number,
            question: {
              __typename: 'question',
              id: number,
              published_version?: {
                __typename: 'question_version',
                id: number,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null,
          flow_step_form?: {
            __typename: 'flow_step_form',
            flow_step_id: number,
            form: {
              __typename: 'form',
              id: number,
              published_version?: {
                __typename: 'form_version',
                id: number,
                form_version_questions: Array<{
                  __typename: 'form_version_question',
                  question?: {
                    __typename: 'question',
                    id: number,
                    published_version?: {
                      __typename: 'question_version',
                      id: number,
                      title_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null
                    } | null
                  } | null
                }>
              } | null
            }
          } | null
        } | null
      }>
    } | null
  }>
};

export type SalesforceQuestionMappingsUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SalesforceQuestionMappingsUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ConnectSegmentMutationVariables = Exact<{
  input: ConnectSegmentInput;
}>;


export type ConnectSegmentMutation = {
  connectSegment: {
    __typename: 'ConnectSegmentOutput',
    success: boolean
  }
};

export type SegmentIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type SegmentIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type SegmentIntegrationDisconnectSourceMutationVariables = Exact<{
  input: DisconnectSegmentSourceInput;
}>;


export type SegmentIntegrationDisconnectSourceMutation = {
  disconnectSegmentSource: {
    __typename: 'DisconnectSegmentSourceOutput',
    success: boolean
  }
};

export type SegmentPropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SegmentPropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null,
  property: Array<{
    __typename: 'property',
    id: number,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    key: string,
    token: string,
    created_at: string
  }>
};

export type SegmentPropertiesUpdateMappingsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SegmentPropertiesUpdateMappingsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any,
    meta: any
  } | null
};

export type ConnectShopifyMutationVariables = Exact<{
  input: ConnectShopifyInput;
}>;


export type ConnectShopifyMutation = {
  connectShopify: {
    __typename: 'ConnectShopifyOutput',
    success: boolean
  }
};

export type ConnectShopifyModalSubscriptionVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type ConnectShopifyModalSubscription = {
  account_by_pk?: {
    __typename: 'account',
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        client_id?: string | null
      } | null
    } | null
  } | null
};

export type RequestShopifyInstallMutationVariables = Exact<{
  input: RequestShopifyInstallInput;
}>;


export type RequestShopifyInstallMutation = {
  requestShopifyInstall: {
    __typename: 'RequestShopifyInstallOutput',
    success: boolean,
    needsInstall: boolean
  }
};

export type ConnectSlackMutationVariables = Exact<{
  input: ConnectSlackInput;
}>;


export type ConnectSlackMutation = {
  connectSlack: {
    __typename: 'ConnectSlackOutput',
    success: boolean,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum,
      options: any
    } | null
  }
};

export type SlackIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type SlackIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>
};

export type SlackIntegrationDisconnectIntegrationMutationVariables = Exact<{ [key: string]: never; }>;


export type SlackIntegrationDisconnectIntegrationMutation = {
  delete_integration?: {
    __typename: 'integration_mutation_response',
    affected_rows: number
  } | null
};

export type SlackNotificationSettingsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SlackNotificationSettingsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any
  } | null
};

export type SlackNotificationSettingsQueryMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  options: Scalars['jsonb']['input'];
}>;


export type SlackNotificationSettingsQueryMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    options: any
  } | null
};

export type UpdateStripeApiKeysModalMutationVariables = Exact<{
  input: ConnectStripeApiKeyInput;
}>;


export type UpdateStripeApiKeysModalMutation = {
  connectStripeApiKey: {
    __typename: 'ConnectStripeApiKeyOutput',
    success: boolean
  }
};

export type CreateWebhookModalMutationVariables = Exact<{
  object: webhook_insert_input;
}>;


export type CreateWebhookModalMutation = {
  insert_webhook_one?: {
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  } | null
};

export type WebhooksQueryVariables = Exact<{ [key: string]: never; }>;


export type WebhooksQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  }>
};

export type WebhooksDeleteMutationVariables = Exact<{
  webhookId: Scalars['Int']['input'];
}>;


export type WebhooksDeleteMutation = {
  delete_webhook_by_pk?: {
    __typename: 'webhook',
    id: number
  } | null
};

export type WebhooksRecreateMutationVariables = Exact<{
  previousId: Scalars['Int']['input'];
  object: webhook_insert_input;
}>;


export type WebhooksRecreateMutation = {
  insert_webhook_one?: {
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  } | null,
  update_webhook_by_pk?: {
    __typename: 'webhook',
    id: number
  } | null
};

export type CompleteSignUpAccountQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CompleteSignUpAccountQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string,
    mode: account_mode_enum,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum,
      import_started_at?: string | null,
      import_completed_at?: string | null,
      ready: boolean,
      show_error: boolean,
      meta: any,
      platform_connection_shopify_store?: {
        __typename: 'platform_connection_shopify_store',
        connected: boolean
      } | null
    } | null,
    default_flow?: {
      __typename: 'flow',
      id: number,
      title: string,
      token: string,
      logo_url?: string | null
    } | null,
    offers: Array<{
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        type: offer_coupon_type_enum
      } | null
    }>
  } | null
};

export type CompleteSignUpUpdateAccountSettingsMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
}>;


export type CompleteSignUpUpdateAccountSettingsMutation = {
  update_user_profile_by_pk?: {
    __typename: 'user_profile',
    id: string
  } | null,
  update_account_by_pk?: {
    __typename: 'account',
    id: number
  } | null
};

export type CompleteSignUpCompleteSignUpMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteSignUpCompleteSignUpMutation = {
  completeSignUp: {
    __typename: 'CompleteSignUpOutput',
    success: boolean
  }
};

export type CompleteSignUpUpdateFlowSettingsMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  logoUrl: Scalars['String']['input'];
}>;


export type CompleteSignUpUpdateFlowSettingsMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number
  } | null
};

export type CompleteSignUpPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompleteSignUpPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null
  }>
};

export type SignUpRoutesAccountFragment = {
  __typename: 'account',
  id: number,
  mode: account_mode_enum,
  default_flow?: {
    __typename: 'flow',
    token: string
  } | null
};

export type UseInitializeAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type UseInitializeAccountMutation = {
  createAccount?: {
    __typename: 'CreateAccountOutput',
    success: boolean,
    error?: string | null
  } | null
};

export type UseInitializeAccountQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type UseInitializeAccountQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    mode: account_mode_enum,
    default_flow?: {
      __typename: 'flow',
      token: string
    } | null
  } | null
};

export type UseInitializeAccountSubscriptionVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type UseInitializeAccountSubscription = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    mode: account_mode_enum,
    default_flow?: {
      __typename: 'flow',
      token: string
    } | null
  } | null
};

export type StripeConnectStripeMutationVariables = Exact<{
  input: ConnectStripeInput;
}>;


export type StripeConnectStripeMutation = {
  connectStripe?: {
    __typename: 'ConnectStripeOutput',
    success: boolean
  } | null
};

export type SubscriberByIdDetailsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SubscriberByIdDetailsQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    inactive_mrr: string,
    active_mrr: string,
    platform_id: string,
    hubspot_contact_id?: string | null,
    hubspot_company_id?: string | null,
    intercom_contact_id?: string | null,
    salesforce_contact_id?: string | null,
    klaviyo_profile_id?: string | null,
    deleted_at?: string | null,
    revenue_logs_aggregate: {
      __typename: 'revenue_log_aggregate',
      aggregate?: {
        __typename: 'revenue_log_aggregate_fields',
        sum?: {
          __typename: 'revenue_log_sum_fields',
          amount?: string | null
        } | null
      } | null
    },
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number,
      token: string,
      status: subscriber_flow_status_enum,
      automatically_deflected: boolean,
      user_agent?: string | null,
      payload?: any | null,
      segment_values?: any | null,
      created_at: string,
      updated_at: string,
      started_at?: string | null,
      completed_at?: string | null,
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        title: string
      },
      offer?: {
        __typename: 'offer',
        id: number,
        name: string
      } | null,
      subscriber_flow_offers: Array<{
        __typename: 'subscriber_flow_offer',
        subscriber_flow_id: number,
        offer_id: number,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        },
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null,
        offer_autopilot_offer_variant?: {
          __typename: 'offer_autopilot_offer_variant',
          variant_index?: number | null
        } | null
      }>,
      subscriber_flow_offer_groups: Array<{
        __typename: 'subscriber_flow_offer_group',
        subscriber_flow_id: number,
        offer_group_id: number,
        offer_id?: number | null,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer_group: {
          __typename: 'offer_group',
          id: number,
          name: string,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              type: offer_type_enum,
              name: string,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_id: number,
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  id: number,
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null
                }>
              } | null
            } | null
          }>
        },
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        } | null,
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null
      }>,
      subscriber_flow_deflections: Array<{
        __typename: 'subscriber_flow_deflection',
        subscriber_flow_id: number,
        deflection_id: number,
        flow_step_id: number,
        clicked_flow_action?: {
          __typename: 'flow_action',
          id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        deflection: {
          __typename: 'deflection',
          id: number,
          title: string
        }
      }>,
      subscription: {
        __typename: 'subscription',
        id: number,
        status: subscription_status_enum,
        mrr: string,
        subscriber_logs: Array<{
          __typename: 'subscriber_log',
          id: number,
          event: subscriber_log_event_enum,
          trial: boolean
        }>,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_id: string,
          code?: string | null
        } | null
      },
      subscriber: {
        __typename: 'subscriber',
        id: number,
        name: string,
        email: string,
        token: string,
        platform_id: string,
        deleted_at?: string | null
      },
      primary_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      text_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_overrides: Array<{
        __typename: 'subscriber_flow_override',
        reason: subscriber_flow_override_reason_enum,
        offer: {
          __typename: 'offer',
          id: number,
          name: string
        },
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string
        } | null
      }>,
      subscriber_flow_flow_steps: Array<{
        __typename: 'subscriber_flow_flow_step',
        flow_step_id: number
      }>,
      flow_steps_count: {
        __typename: 'subscriber_flow_flow_step_aggregate',
        aggregate?: {
          __typename: 'subscriber_flow_flow_step_aggregate_fields',
          count: number
        } | null
      }
    }>,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      occurred_at: string,
      data: any,
      subscription?: {
        __typename: 'subscription',
        id: number,
        status: subscription_status_enum
      } | null,
      subscriber_log_offer?: {
        __typename: 'subscriber_log_offer',
        offer: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum
        }
      } | null
    }>,
    revenue_logs: Array<{
      __typename: 'revenue_log',
      id: number,
      amount: string,
      occurred_at: string,
      platform_invoice_id: string,
      revenue_type: revenue_log_revenue_type_enum
    }>,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      subscriber_id: number,
      property_id: number,
      value: any,
      property: {
        __typename: 'property',
        id: number,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        key: string,
        token: string,
        created_at: string
      }
    }>
  } | null
};

export type SubscriberByTokenDetailsQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SubscriberByTokenDetailsQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    inactive_mrr: string,
    active_mrr: string,
    platform_id: string,
    hubspot_contact_id?: string | null,
    hubspot_company_id?: string | null,
    intercom_contact_id?: string | null,
    salesforce_contact_id?: string | null,
    klaviyo_profile_id?: string | null,
    deleted_at?: string | null,
    revenue_logs_aggregate: {
      __typename: 'revenue_log_aggregate',
      aggregate?: {
        __typename: 'revenue_log_aggregate_fields',
        sum?: {
          __typename: 'revenue_log_sum_fields',
          amount?: string | null
        } | null
      } | null
    },
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number,
      token: string,
      status: subscriber_flow_status_enum,
      automatically_deflected: boolean,
      user_agent?: string | null,
      payload?: any | null,
      segment_values?: any | null,
      created_at: string,
      updated_at: string,
      started_at?: string | null,
      completed_at?: string | null,
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        title: string
      },
      offer?: {
        __typename: 'offer',
        id: number,
        name: string
      } | null,
      subscriber_flow_offers: Array<{
        __typename: 'subscriber_flow_offer',
        subscriber_flow_id: number,
        offer_id: number,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        },
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null,
        offer_autopilot_offer_variant?: {
          __typename: 'offer_autopilot_offer_variant',
          variant_index?: number | null
        } | null
      }>,
      subscriber_flow_offer_groups: Array<{
        __typename: 'subscriber_flow_offer_group',
        subscriber_flow_id: number,
        offer_group_id: number,
        offer_id?: number | null,
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer_group: {
          __typename: 'offer_group',
          id: number,
          name: string,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              type: offer_type_enum,
              name: string,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                offer_id: number,
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  id: number,
                  type: offer_pause_subscription_option_type_enum,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null
                }>
              } | null
            } | null
          }>
        },
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          name: string,
          goal: offer_goal_enum,
          metadata: any,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            change_at: offer_change_plan_change_at_enum,
            platform_plan?: {
              __typename: 'platform_plan',
              name: string
            } | null,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                id: number,
                offer_id: number,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                duration_count?: number | null,
                duration_interval?: coupon_duration_interval_enum | null,
                months?: number | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            modify_at: offer_modify_subscription_modify_at_enum,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_connection_id: number,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_custom?: {
            __typename: 'offer_custom',
            link_href?: string | null,
            link_text?: string | null
          } | null,
          offer_reschedule_order?: {
            __typename: 'offer_reschedule_order',
            skip: boolean,
            reschedule: boolean
          } | null
        } | null,
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null
      }>,
      subscriber_flow_deflections: Array<{
        __typename: 'subscriber_flow_deflection',
        subscriber_flow_id: number,
        deflection_id: number,
        flow_step_id: number,
        clicked_flow_action?: {
          __typename: 'flow_action',
          id: number,
          text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        deflection: {
          __typename: 'deflection',
          id: number,
          title: string
        }
      }>,
      subscription: {
        __typename: 'subscription',
        id: number,
        status: subscription_status_enum,
        mrr: string,
        subscriber_logs: Array<{
          __typename: 'subscriber_log',
          id: number,
          event: subscriber_log_event_enum,
          trial: boolean
        }>,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_id: string,
          code?: string | null
        } | null
      },
      subscriber: {
        __typename: 'subscriber',
        id: number,
        name: string,
        email: string,
        token: string,
        platform_id: string,
        deleted_at?: string | null
      },
      primary_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      text_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            type: question_type_enum
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            }
          }>,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_overrides: Array<{
        __typename: 'subscriber_flow_override',
        reason: subscriber_flow_override_reason_enum,
        offer: {
          __typename: 'offer',
          id: number,
          name: string
        },
        offer_group?: {
          __typename: 'offer_group',
          id: number,
          name: string
        } | null
      }>,
      subscriber_flow_flow_steps: Array<{
        __typename: 'subscriber_flow_flow_step',
        flow_step_id: number
      }>,
      flow_steps_count: {
        __typename: 'subscriber_flow_flow_step_aggregate',
        aggregate?: {
          __typename: 'subscriber_flow_flow_step_aggregate_fields',
          count: number
        } | null
      }
    }>,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      occurred_at: string,
      data: any,
      subscription?: {
        __typename: 'subscription',
        id: number,
        status: subscription_status_enum
      } | null,
      subscriber_log_offer?: {
        __typename: 'subscriber_log_offer',
        offer: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum
        }
      } | null
    }>,
    revenue_logs: Array<{
      __typename: 'revenue_log',
      id: number,
      amount: string,
      occurred_at: string,
      platform_invoice_id: string,
      revenue_type: revenue_log_revenue_type_enum
    }>,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      subscriber_id: number,
      property_id: number,
      value: any,
      property: {
        __typename: 'property',
        id: number,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        key: string,
        token: string,
        created_at: string
      }
    }>
  }>
};

export type SubscriberDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriberDetailsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    platform: platform_enum,
    platform_id: string,
    import_started_at?: string | null,
    import_completed_at?: string | null,
    meta: any,
    publishable_key?: string | null,
    platform_connection_shopify_store?: {
      __typename: 'platform_connection_shopify_store',
      store: string,
      client_id?: string | null,
      install_link?: string | null
    } | null
  }>,
  hubspot: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>,
  intercom: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>,
  salesforce: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type SubscribersHasSubscribersQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscribersHasSubscribersQuery = {
  subscriber_grid_aggregate: {
    __typename: 'subscriber_grid_aggregate',
    aggregate?: {
      __typename: 'subscriber_grid_aggregate_fields',
      count: number
    } | null
  }
};

export type SubscribersQueryVariables = Exact<{
  where?: InputMaybe<subscriber_grid_bool_exp>;
  orderBy?: InputMaybe<Array<subscriber_grid_order_by> | subscriber_grid_order_by>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type SubscribersQuery = {
  subscriber_grid: Array<{
    __typename: 'subscriber_grid',
    id?: number | null,
    status?: string | null,
    last_seen_at?: string | null,
    last_subscriber_flow_seen_at?: string | null,
    last_subscriber_campaign_view_seen_at?: string | null,
    last_subscriber_likelihood_to_return?: number | null,
    subscriber?: {
      __typename: 'subscriber',
      id: number,
      token: string,
      name: string,
      email: string,
      platform_id: string,
      status?: subscriber_status_enum | null,
      active_mrr: string,
      inactive_mrr: string,
      created_at: string,
      deleted_at?: string | null,
      subscriber_created_at?: {
        __typename: 'subscriber_created_at',
        created_at?: string | null
      } | null,
      platform_customer?: {
        __typename: 'platform_customer',
        platform_id: string,
        platform_created_at?: string | null
      } | null
    } | null,
    last_subscriber_flow?: {
      __typename: 'subscriber_flow',
      status: subscriber_flow_status_enum,
      flow: {
        __typename: 'flow',
        title: string
      }
    } | null,
    last_subscriber_campaign_view?: {
      __typename: 'subscriber_campaign_view',
      status: subscriber_campaign_view_status_enum,
      subscriber_campaign: {
        __typename: 'subscriber_campaign',
        campaign: {
          __typename: 'campaign',
          title: string
        }
      }
    } | null
  }>,
  subscriber_grid_aggregate: {
    __typename: 'subscriber_grid_aggregate',
    aggregate?: {
      __typename: 'subscriber_grid_aggregate_fields',
      count: number
    } | null
  }
};

export type SubscribersSearchQueryVariables = Exact<{
  search: Scalars['String']['input'];
  where?: InputMaybe<subscriber_grid_bool_exp>;
  orderBy?: InputMaybe<Array<subscriber_grid_order_by> | subscriber_grid_order_by>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type SubscribersSearchQuery = {
  search_subscriber_grid: Array<{
    __typename: 'subscriber_grid',
    id?: number | null,
    status?: string | null,
    last_seen_at?: string | null,
    last_subscriber_flow_seen_at?: string | null,
    last_subscriber_campaign_view_seen_at?: string | null,
    last_subscriber_likelihood_to_return?: number | null,
    subscriber?: {
      __typename: 'subscriber',
      id: number,
      token: string,
      name: string,
      email: string,
      platform_id: string,
      status?: subscriber_status_enum | null,
      active_mrr: string,
      inactive_mrr: string,
      created_at: string,
      deleted_at?: string | null,
      subscriber_created_at?: {
        __typename: 'subscriber_created_at',
        created_at?: string | null
      } | null,
      platform_customer?: {
        __typename: 'platform_customer',
        platform_id: string,
        platform_created_at?: string | null
      } | null
    } | null,
    last_subscriber_flow?: {
      __typename: 'subscriber_flow',
      status: subscriber_flow_status_enum,
      flow: {
        __typename: 'flow',
        title: string
      }
    } | null,
    last_subscriber_campaign_view?: {
      __typename: 'subscriber_campaign_view',
      status: subscriber_campaign_view_status_enum,
      subscriber_campaign: {
        __typename: 'subscriber_campaign',
        campaign: {
          __typename: 'campaign',
          title: string
        }
      }
    } | null
  }>,
  search_subscriber_grid_aggregate: {
    __typename: 'subscriber_grid_aggregate',
    aggregate?: {
      __typename: 'subscriber_grid_aggregate_fields',
      count: number
    } | null
  }
};

export type UpgradeAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type UpgradeAccountQuery = {
  accountSubscription: {
    __typename: 'AccountSubscriptionOutput',
    name?: string | null,
    status: string
  },
  paymentMethod: {
    __typename: 'PaymentMethodOutput',
    stripePaymentMethodId?: string | null,
    lastFour?: string | null
  },
  pricingPlan: {
    __typename: 'PricingPlanOutput',
    name: string,
    monthlyCost: string
  }
};

export type UpgradeAccountAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type UpgradeAccountAccountQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string,
    mode: account_mode_enum
  }>
};

export type UpgradeAccountMutationVariables = Exact<{
  input: UpgradeAccountInput;
}>;


export type UpgradeAccountMutation = {
  upgradeAccount: {
    __typename: 'UpgradeAccountOutput',
    success: boolean
  }
};

export type AcceptCampaignOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  subscriberCampaignToken: Scalars['String']['input'];
}>;


export type AcceptCampaignOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    type: offer_type_enum,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_coupons: Array<{
      __typename: 'offer_coupon',
      platform_id?: string | null,
      platform_product_group_id?: string | null,
      apply_to: offer_coupon_apply_to_enum,
      end_trial: boolean
    }>,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        id: number,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          apply_to: offer_coupon_apply_to_enum
        } | null
      } | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    account: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        id: number,
        platform: platform_enum
      } | null
    }
  } | null,
  subscriber_campaign: Array<{
    __typename: 'subscriber_campaign',
    id: number,
    campaign: {
      __typename: 'campaign',
      id: number,
      published_version?: {
        __typename: 'campaign_version',
        id: number,
        collect_payment: boolean
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_id: number,
      status: subscription_status_enum,
      mrr: string,
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan?: {
            __typename: 'platform_plan',
            platform_product?: {
              __typename: 'platform_product',
              platform_product_group_id?: string | null
            } | null
          } | null
        }>
      } | null
    },
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer_id: number,
      first_view: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          min?: {
            __typename: 'subscriber_campaign_view_min_fields',
            created_at?: string | null
          } | null
        } | null
      }
    }>
  }>
};

export type AcceptCampaignOfferSubscriberLogOfferMutationVariables = Exact<{
  subscriberLogId: Scalars['Int']['input'];
  subscriberCampaignId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
}>;


export type AcceptCampaignOfferSubscriberLogOfferMutation = {
  insert_subscriber_log_offer_one?: {
    __typename: 'subscriber_log_offer',
    subscriber_log_id: number
  } | null,
  update_subscriber_campaign_by_pk?: {
    __typename: 'subscriber_campaign',
    id: number
  } | null
};

export type AcceptOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  subscriberFlowToken: Scalars['String']['input'];
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
  offerVariantId: Scalars['Int']['input'];
  flowStepToken: Scalars['String']['input'];
}>;


export type AcceptOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    type: offer_type_enum,
    offer_coupons: Array<{
      __typename: 'offer_coupon',
      platform_id?: string | null,
      platform_product_group_id?: string | null,
      apply_to: offer_coupon_apply_to_enum
    }>,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum,
      reset_billing_date: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        id: number,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          apply_to: offer_coupon_apply_to_enum
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_id: string,
        paper_code?: string | null
      } | null
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      change_at: offer_change_plan_change_at_enum,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_id: string,
        paper_code?: string | null
      } | null
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string
          } | null
        }>
      }>
    } | null,
    account: {
      __typename: 'account',
      platform_connection?: {
        __typename: 'platform_connection',
        id: number,
        platform: platform_enum
      } | null
    },
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      offer_group?: {
        __typename: 'offer_group',
        id: number
      } | null
    }>
  } | null,
  offer_variant_by_pk?: {
    __typename: 'offer_variant',
    id: number,
    offer_id: number,
    offer_variant_coupons: Array<{
      __typename: 'offer_variant_coupon',
      platform_id?: string | null,
      platform_product_group_id?: string | null
    }>
  } | null,
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_id: number,
      status: subscription_status_enum,
      mrr: string,
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan?: {
            __typename: 'platform_plan',
            platform_product?: {
              __typename: 'platform_product',
              platform_product_group_id?: string | null
            } | null
          } | null
        }>
      } | null
    }
  }>,
  platform_ecommerce_product: Array<{
    __typename: 'platform_ecommerce_product',
    platform_id: string,
    platform_variants: Array<{
      __typename: 'platform_variant',
      platform_id: string
    }>
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    id: number
  }>
};

export type AcceptOfferSubscriberLogOfferGroupMutationVariables = Exact<{
  subscriberLogId: Scalars['Int']['input'];
  subscriberFlowId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerGroupId: Scalars['Int']['input'];
  offerPauseSubscriptionOptionId?: InputMaybe<Scalars['Int']['input']>;
  rescheduledTo?: InputMaybe<Scalars['date']['input']>;
  pauseAt?: InputMaybe<Scalars['date']['input']>;
  resumeAt?: InputMaybe<Scalars['date']['input']>;
  flowStepId: Scalars['Int']['input'];
}>;


export type AcceptOfferSubscriberLogOfferGroupMutation = {
  insert_subscriber_log_offer_group_one?: {
    __typename: 'subscriber_log_offer_group',
    subscriber_log_id: number
  } | null,
  update_subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number
  } | null,
  update_subscriber_flow_offer_group_by_pk?: {
    __typename: 'subscriber_flow_offer_group',
    subscriber_flow_id: number
  } | null,
  update_subscriber_flow_offer_group_offer_by_pk?: {
    __typename: 'subscriber_flow_offer_group_offer',
    subscriber_flow_id: number,
    offer_autopilot_round_id?: number | null
  } | null,
  bypassed?: {
    __typename: 'subscriber_flow_offer_group_offer_mutation_response',
    affected_rows: number
  } | null
};

export type AcceptOfferSubscriberLogOfferMutationVariables = Exact<{
  subscriberLogId: Scalars['Int']['input'];
  subscriberFlowId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerPauseSubscriptionOptionId?: InputMaybe<Scalars['Int']['input']>;
  rescheduledTo?: InputMaybe<Scalars['date']['input']>;
  pauseAt?: InputMaybe<Scalars['date']['input']>;
  resumeAt?: InputMaybe<Scalars['date']['input']>;
}>;


export type AcceptOfferSubscriberLogOfferMutation = {
  insert_subscriber_log_offer_one?: {
    __typename: 'subscriber_log_offer',
    subscriber_log_id: number
  } | null,
  update_subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number
  } | null,
  update_subscriber_flow_offer_by_pk?: {
    __typename: 'subscriber_flow_offer',
    subscriber_flow_id: number,
    offer_autopilot_round_id?: number | null
  } | null
};

export type AcceptUserInvitationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type AcceptUserInvitationQuery = {
  user_invitation: Array<{
    __typename: 'user_invitation',
    id: number,
    account_id: number,
    expires_at: string,
    is_owner: boolean
  }>
};

export type AcceptUserInvitationMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  accountId: Scalars['Int']['input'];
  invitationId: Scalars['Int']['input'];
}>;


export type AcceptUserInvitationMutation = {
  insert_account_user_one?: {
    __typename: 'account_user',
    user_id: string
  } | null,
  delete_user_invitation_by_pk?: {
    __typename: 'user_invitation',
    id: number
  } | null
};

export type AcceptUserInvitationOwnerMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  accountId: Scalars['Int']['input'];
}>;


export type AcceptUserInvitationOwnerMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number
  } | null
};

export type ServerAdminCreateAccountMutationVariables = Exact<{
  input: account_insert_input;
}>;


export type ServerAdminCreateAccountMutation = {
  insert_account_one?: {
    __typename: 'account',
    id: number
  } | null
};

export type AdminCreateAccountOwnerMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type AdminCreateAccountOwnerMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number
  } | null
};

export type AdminUpdateAccountFeaturesMutationVariables = Exact<{
  objects: Array<account_feature_insert_input> | account_feature_insert_input;
}>;


export type AdminUpdateAccountFeaturesMutation = {
  insert_account_feature?: {
    __typename: 'account_feature_mutation_response',
    affected_rows: number
  } | null
};

export type AdminUpdateAccountSettingsMutationVariables = Exact<{
  objects: Array<account_setting_insert_input> | account_setting_insert_input;
}>;


export type AdminUpdateAccountSettingsMutation = {
  insert_account_setting?: {
    __typename: 'account_setting_mutation_response',
    affected_rows: number
  } | null
};

export type ClickFlowActionQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  deflectionId: Scalars['Int']['input'];
  flowActionId: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type ClickFlowActionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  }>,
  deflection_by_pk?: {
    __typename: 'deflection',
    id: number,
    title: string,
    token: string,
    minimum_items?: number | null,
    heading_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    deflection_snapshot_items: Array<{
      __typename: 'deflection_snapshot_item_position',
      position?: number | null,
      deflection_snapshot_item?: {
        __typename: 'deflection_snapshot_item',
        id: number,
        token: string,
        icon_prefix?: string | null,
        icon_name?: string | null,
        property_id?: number | null,
        property_condition_value?: any | null,
        date_format?: string | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>,
    deflection_flow_actions: Array<{
      __typename: 'deflection_flow_action',
      position?: number | null,
      flow_action?: {
        __typename: 'flow_action',
        id: number,
        token: string,
        code?: string | null,
        type: flow_action_type_enum,
        account_id: number,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow_id?: number | null,
        text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }>
  } | null,
  flow_action_by_pk?: {
    __typename: 'flow_action',
    id: number,
    type: flow_action_type_enum,
    reroute_to_flow?: {
      __typename: 'flow',
      id: number,
      token: string
    } | null
  } | null
};

export type ClickFlowActionMutationVariables = Exact<{
  object: subscriber_flow_deflection_insert_input;
}>;


export type ClickFlowActionMutation = {
  insert_subscriber_flow_deflection_one?: {
    __typename: 'subscriber_flow_deflection',
    subscriber_flow_id: number,
    deflection_id: number
  } | null
};

export type CompleteFlowStepQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
}>;


export type CompleteFlowStepQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    id: number
  }>
};

export type CompleteFlowStepMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  flowStepId: Scalars['Int']['input'];
  completedAt: Scalars['timestamptz']['input'];
}>;


export type CompleteFlowStepMutation = {
  update_subscriber_flow_flow_step_by_pk?: {
    __typename: 'subscriber_flow_flow_step',
    subscriber_flow_id: number
  } | null
};

export type CompleteSubscriberFlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CompleteSubscriberFlowQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    flow: {
      __typename: 'flow',
      on_cancel: flow_on_cancel_enum,
      on_cancel_perform_at: flow_on_cancel_perform_at_enum,
      flow_on_cancel_change_plans: Array<{
        __typename: 'flow_on_cancel_change_plan',
        platform_connection_id: number,
        platform_id: string,
        prorate: boolean,
        remove_add_ons: boolean
      }>,
      account: {
        __typename: 'account',
        id: number,
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      }
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      platform_id: string,
      status: subscription_status_enum,
      mrr: string,
      subscriber: {
        __typename: 'subscriber',
        status?: subscriber_status_enum | null,
        inactive_mrr: string,
        active_mrr: string,
        active_offer_id?: number | null,
        platform_customer?: {
          __typename: 'platform_customer',
          platform_coupon_id?: string | null,
          past_due?: boolean | null,
          platform_subscriptions: Array<{
            __typename: 'platform_subscription',
            status: platform_subscription_status_enum,
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              platform_plan_id: string
            }>
          }>
        } | null
      },
      account: {
        __typename: 'account',
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      },
      platform_subscription?: {
        __typename: 'platform_subscription',
        status: platform_subscription_status_enum,
        platform_coupon_id?: string | null,
        past_due?: boolean | null,
        can_pause: boolean,
        can_cancel: boolean,
        paper_code?: string | null,
        cancel_at_period_end?: boolean | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string,
          platform_plan?: {
            __typename: 'platform_plan',
            interval: platform_plan_interval_enum,
            interval_count: number,
            platform_product_id: string,
            platform_product?: {
              __typename: 'platform_product',
              platform_product_group_id?: string | null
            } | null
          } | null,
          swappable_ecommerce_products: Array<{
            __typename: 'platform_subscription_plan_swappable_ecommerce_product',
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              active: boolean
            } | null
          }>
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          platform_addon_id: string
        }>
      } | null
    }
  }>
};

export type CompleteSubscriberFlowOnCancelOverrideMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  reason: subscriber_flow_override_reason_enum;
}>;


export type CompleteSubscriberFlowOnCancelOverrideMutation = {
  insert_subscriber_flow_on_cancel_override_one?: {
    __typename: 'subscriber_flow_on_cancel_override',
    subscriber_flow_id: number
  } | null
};

export type CompleteSubscriberFlowCustomMutationVariables = Exact<{
  logs: Array<subscriber_log_insert_input> | subscriber_log_insert_input;
}>;


export type CompleteSubscriberFlowCustomMutation = {
  insert_subscriber_log?: {
    __typename: 'subscriber_log_mutation_response',
    affected_rows: number
  } | null
};

export type CompleteSubscriberFlowMutationVariables = Exact<{
  token: Scalars['String']['input'];
  status: subscriber_flow_status_enum;
}>;


export type CompleteSubscriberFlowMutation = {
  update_subscriber_flow?: {
    __typename: 'subscriber_flow_mutation_response',
    affected_rows: number,
    returning: Array<{
      __typename: 'subscriber_flow',
      id: number,
      subscriber_id: number
    }>
  } | null
};

export type CompleteSubscriberFlowDeflectedMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type CompleteSubscriberFlowDeflectedMutation = {
  update_subscriber?: {
    __typename: 'subscriber_mutation_response',
    affected_rows: number
  } | null
};

export type CompleteSubscriberFlowFlowSessionQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CompleteSubscriberFlowFlowSessionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  }>
};

export type ConnectBraintreeMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
}>;


export type ConnectBraintreeMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectChargebeeMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
  publishableKey: Scalars['String']['input'];
}>;


export type ConnectChargebeeMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectHubSpotIntegrationMutationVariables = Exact<{
  object: integration_insert_input;
}>;


export type ConnectHubSpotIntegrationMutation = {
  insert_integration_one?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectIntercomIntegrationQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type ConnectIntercomIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number
  }>
};

export type ConnectIntercomExistingIntegrationMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  credentials: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectIntercomExistingIntegrationMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectIntercomIntegrationMutationVariables = Exact<{
  object: integration_insert_input;
}>;


export type ConnectIntercomIntegrationMutation = {
  insert_integration_one?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectMaxioMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
}>;


export type ConnectMaxioMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectNavigaQueryVariables = Exact<{
  mediaGroupCode: Scalars['String']['input'];
}>;


export type ConnectNavigaQuery = {
  naviga_paper_code: Array<{
    __typename: 'naviga_paper_code',
    media_group_code: string,
    client_code: string,
    paper_code: string
  }>
};

export type ConnectNavigaMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectNavigaMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectOpenPayMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectOpenPayMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectPaddleMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectPaddleMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectRecurlyMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectRecurlyMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectSalesforceIntegrationMutationVariables = Exact<{
  object: integration_insert_input;
}>;


export type ConnectSalesforceIntegrationMutation = {
  insert_integration_one?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectSegmentApiKeyMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type ConnectSegmentApiKeyMutation = {
  insert_api_key_one?: {
    __typename: 'api_key',
    id: number,
    key: string
  } | null
};

export type ConnectSegmentIntegrationMutationVariables = Exact<{
  object: integration_insert_input;
}>;


export type ConnectSegmentIntegrationMutation = {
  insert_integration_one?: {
    __typename: 'integration',
    id: number,
    meta: any
  } | null
};

export type ConnectSegmentCredentialsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  credentials: Scalars['jsonb']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectSegmentCredentialsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectSlackIntegrationMutationVariables = Exact<{
  object: integration_insert_input;
}>;


export type ConnectSlackIntegrationMutation = {
  insert_integration_one?: {
    __typename: 'integration',
    id: number
  } | null
};

export type ConnectStripeQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type ConnectStripeQuery = {
  platform_credentials_by_pk?: {
    __typename: 'platform_credentials',
    data: any,
    data_text?: string | null
  } | null
};

export type ConnectStripeMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  publishableKey: Scalars['String']['input'];
}>;


export type ConnectStripeMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectStripeApiKeyMutationVariables = Exact<{
  object: platform_credentials_insert_input;
}>;


export type ConnectStripeApiKeyMutation = {
  insert_platform_credentials_one?: {
    __typename: 'platform_credentials',
    account_id: number
  } | null
};

export type ConnectStripeApiKeyReconnectQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type ConnectStripeApiKeyReconnectQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    data: any,
    data_text?: string | null
  }>
};

export type ConnectStripeApiKeyReconnectMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['jsonb']['input'];
  dataText: Scalars['String']['input'];
  publishableKey: Scalars['String']['input'];
}>;


export type ConnectStripeApiKeyReconnectMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ConnectZuoraMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  data: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type ConnectZuoraMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ServerCreateAccountMutationVariables = Exact<{
  input: account_insert_input;
}>;


export type ServerCreateAccountMutation = {
  insert_account_one?: {
    __typename: 'account',
    id: number
  } | null
};

export type CreateApiKeyMutationVariables = Exact<{
  object: api_key_insert_input;
}>;


export type CreateApiKeyMutation = {
  insert_api_key_one?: {
    __typename: 'api_key',
    id: number,
    key: string
  } | null
};

export type DeclineOfferMutationVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  offerId: Scalars['Int']['input'];
}>;


export type DeclineOfferMutation = {
  update_subscriber_flow_offer?: {
    __typename: 'subscriber_flow_offer_mutation_response',
    returning: Array<{
      __typename: 'subscriber_flow_offer',
      offer_autopilot_round_id?: number | null
    }>
  } | null
};

export type DeclineOfferGroupMutationVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  offerGroupId: Scalars['Int']['input'];
  flowStepId: Scalars['Int']['input'];
}>;


export type DeclineOfferGroupMutation = {
  update_subscriber_flow_offer_group?: {
    __typename: 'subscriber_flow_offer_group_mutation_response',
    affected_rows: number
  } | null,
  update_subscriber_flow_offer_group_offer?: {
    __typename: 'subscriber_flow_offer_group_offer_mutation_response',
    returning: Array<{
      __typename: 'subscriber_flow_offer_group_offer',
      offer_id: number,
      offer_autopilot_round_id?: number | null
    }>
  } | null
};

export type DisconnectSegmentSourceQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type DisconnectSegmentSourceQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    credentials: any,
    meta: any
  }>
};

export type DisconnectSegmentSourceMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  installName: Scalars['String']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type DisconnectSegmentSourceMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    credentials: any
  } | null
};

export type ExportCancellationsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type ExportCancellationsQuery = {
  subscriber_log: Array<{
    __typename: 'subscriber_log',
    id: number,
    occurred_at: string,
    data: any,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      active_mrr: string,
      inactive_mrr: string,
      reactivation_logs: Array<{
        __typename: 'subscriber_log',
        occurred_at: string,
        data: any
      }>
    },
    subscription?: {
      __typename: 'subscription',
      subscriber_flows: Array<{
        __typename: 'subscriber_flow',
        subscriber_flow_question_answers: Array<{
          __typename: 'subscriber_flow_question_answer',
          question_answer: {
            __typename: 'question_answer',
            question: {
              __typename: 'question',
              id: number,
              type: question_type_enum
            },
            question_version: {
              __typename: 'question_version',
              id: number,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            },
            question_answer_radios: Array<{
              __typename: 'question_answer_radio',
              question_option_version: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              }
            }>,
            question_answer_text?: {
              __typename: 'question_answer_text',
              value: string,
              sentiment?: sentiment_enum | null
            } | null,
            question_answer_likelihood_to_return?: {
              __typename: 'question_answer_likelihood_to_return',
              value: number
            } | null
          }
        }>
      }>
    } | null
  }>,
  question: Array<{
    __typename: 'question',
    id: number,
    type: question_type_enum,
    published_version?: {
      __typename: 'question_version',
      id: number,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    } | null
  }>
};

export type ExportSessionsCreateExportMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type ExportSessionsCreateExportMutation = {
  insert_subscriber_flow_export_one?: {
    __typename: 'subscriber_flow_export',
    id: number,
    token: string
  } | null
};

export type FlowTestOfferRulesQueryVariables = Exact<{
  flowStepToken: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type FlowTestOfferRulesQuery = {
  flow_step: Array<{
    __typename: 'flow_step',
    id: number,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      intervention: {
        __typename: 'intervention',
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      }
    } | null,
    flow: {
      __typename: 'flow',
      id: number,
      account: {
        __typename: 'account',
        questions: Array<{
          __typename: 'question',
          id: number,
          type: question_type_enum,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        }>,
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      }
    }
  }>
};

export type FlowTestOfferFlowOfferGroupQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  offerVariantIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type FlowTestOfferFlowOfferGroupQuery = {
  offer_group_by_pk?: {
    __typename: 'offer_group',
    id: number,
    name: string,
    token: string,
    show_images: boolean,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  offer_variant: Array<{
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  }>
};

export type FlowTestOfferFlowOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  offerVariantId: Scalars['Int']['input'];
}>;


export type FlowTestOfferFlowOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    show_banner: boolean,
    image_url?: string | null,
    goal: offer_goal_enum,
    style: offer_style_enum,
    created_at: string,
    updated_at: string,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_coupon?: {
      __typename: 'offer_coupon',
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number
    } | null
  } | null,
  offer_variant_by_pk?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null
};

export type InitializeSubscriberFlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type InitializeSubscriberFlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    account_id: number,
    prevent_if_canceled: boolean,
    account: {
      __typename: 'account',
      prosperstack: boolean,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number
      } | null
    },
    prevent_if_canceled_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  }>
};

export type InitializeSubscriberFlowMutationVariables = Exact<{
  input: subscriber_flow_insert_input;
}>;


export type InitializeSubscriberFlowMutation = {
  insert_subscriber_flow_one?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string
  } | null
};

export type InviteUserActionAccountQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type InviteUserActionAccountQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string
  } | null
};

export type InviteUserInsertUserMutationVariables = Exact<{
  userInput: user_insert_input;
  userInvitationInput: user_invitation_insert_input;
}>;


export type InviteUserInsertUserMutation = {
  insert_user_one?: {
    __typename: 'user',
    id: string
  } | null,
  insert_user_invitation_one?: {
    __typename: 'user_invitation',
    id: number
  } | null
};

export type InviteUserActionMutationVariables = Exact<{
  object: account_user_insert_input;
}>;


export type InviteUserActionMutation = {
  insert_account_user_one?: {
    __typename: 'account_user',
    user_id: string
  } | null
};

export type LogOfferGroupPresentedSubscriptionQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
}>;


export type LogOfferGroupPresentedSubscriptionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_id: number,
      status: subscription_status_enum,
      platform_id: string
    },
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_offer_group_offers: Array<{
        __typename: 'subscriber_flow_offer_group_offer',
        offer_id: number,
        offer_variant_id?: number | null
      }>
    }>
  }>
};

export type LogOfferGroupPresentedSubscriberLogOfferMutationVariables = Exact<{
  subscriberLogId: Scalars['Int']['input'];
  offerGroupId: Scalars['Int']['input'];
}>;


export type LogOfferGroupPresentedSubscriberLogOfferMutation = {
  insert_subscriber_log_offer_group_one?: {
    __typename: 'subscriber_log_offer_group',
    subscriber_log_id: number
  } | null
};

export type LogOfferPresentedSubscriptionQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
}>;


export type LogOfferPresentedSubscriptionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    subscription: {
      __typename: 'subscription',
      id: number,
      subscriber_id: number,
      status: subscription_status_enum,
      platform_id: string
    }
  }>
};

export type LogOfferPresentedSubscriberLogOfferMutationVariables = Exact<{
  subscriberLogId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
}>;


export type LogOfferPresentedSubscriberLogOfferMutation = {
  insert_subscriber_log_offer_one?: {
    __typename: 'subscriber_log_offer',
    subscriber_log_id: number
  } | null
};

export type LoginUserAccountQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type LoginUserAccountQuery = {
  account_user_by_pk?: {
    __typename: 'account_user',
    account: {
      __typename: 'account',
      id: number,
      title: string,
      mode: account_mode_enum,
      status: account_status_enum,
      stripe_customer_id: string,
      stripe_subscription_id: string,
      created_at: string,
      updated_at: string,
      prosperstack: boolean,
      test_mode_for_account_id?: number | null,
      enterprise: boolean,
      sso_required: boolean,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number
      } | null
    }
  } | null,
  testModeAccount?: {
    __typename: 'account',
    id: number,
    title: string,
    mode: account_mode_enum,
    status: account_status_enum,
    stripe_customer_id: string,
    stripe_subscription_id: string,
    created_at: string,
    updated_at: string,
    prosperstack: boolean,
    test_mode_for_account_id?: number | null,
    enterprise: boolean,
    sso_required: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  } | null
};

export type NeedStripeReconnectQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type NeedStripeReconnectQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    data: any,
    data_text?: string | null,
    account: {
      __typename: 'account',
      prosperstack: boolean
    }
  }>
};

export type PortalCustomerBillingDetailsQueryVariables = Exact<{
  portalSessionToken: Scalars['String']['input'];
  platformCustomerId: Scalars['String']['input'];
}>;


export type PortalCustomerBillingDetailsQuery = {
  active_portal_session: Array<{
    __typename: 'active_portal_session',
    portal_session?: {
      __typename: 'portal_session',
      account: {
        __typename: 'account',
        id: number,
        platform_connection?: {
          __typename: 'platform_connection',
          id: number
        } | null
      },
      portal_session_subscribers: Array<{
        __typename: 'portal_session_subscriber',
        subscriber_id: number
      }>
    } | null
  }>
};

export type PortalDownloadInvoiceQueryVariables = Exact<{
  portalSessionToken: Scalars['String']['input'];
  platformInvoiceId: Scalars['String']['input'];
}>;


export type PortalDownloadInvoiceQuery = {
  active_portal_session: Array<{
    __typename: 'active_portal_session',
    portal_session?: {
      __typename: 'portal_session',
      account: {
        __typename: 'account',
        id: number,
        platform_connection?: {
          __typename: 'platform_connection',
          id: number
        } | null
      },
      portal_session_subscribers: Array<{
        __typename: 'portal_session_subscriber',
        subscriber_id: number
      }>
    } | null
  }>
};

export type PortalSubscriptionDetailsQueryVariables = Exact<{
  portalSessionToken: Scalars['String']['input'];
  platformSubscriptionId: Scalars['String']['input'];
}>;


export type PortalSubscriptionDetailsQuery = {
  portal_session: Array<{
    __typename: 'portal_session',
    account: {
      __typename: 'account',
      id: number,
      title: string
    },
    portal_session_subscribers: Array<{
      __typename: 'portal_session_subscriber',
      subscriber: {
        __typename: 'subscriber',
        subscriptions: Array<{
          __typename: 'subscription',
          id: number,
          status: subscription_status_enum,
          platform_subscription?: {
            __typename: 'platform_subscription',
            platform_connection_id: number,
            platform_id: string,
            platform_customer_id: string,
            status: platform_subscription_status_enum,
            started_at: string,
            canceled_at?: string | null,
            trial_end?: string | null,
            updated_at: string,
            next_billing_at?: string | null,
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              quantity: string,
              price?: string | null,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              platform_plan?: {
                __typename: 'platform_plan',
                platform_connection_id: number,
                platform_id: string,
                platform_product_id: string,
                name: string,
                currency: platform_currency_enum,
                interval: platform_plan_interval_enum,
                interval_count: number,
                pricing_model: platform_plan_pricing_model_enum,
                platform_plan_tiers: Array<{
                  __typename: 'platform_plan_tier',
                  up_to: number,
                  price: string,
                  flat_fee: string
                }>,
                platform_product?: {
                  __typename: 'platform_product',
                  platform_connection_id: number,
                  platform_id: string,
                  name: string
                } | null,
                platform_currency: {
                  __typename: 'platform_currency',
                  platform_currency_exchange_rates: Array<{
                    __typename: 'platform_currency_exchange_rate',
                    base: platform_base_currency_enum,
                    rate: string
                  }>
                },
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null
            }>,
            platform_subscription_addons: Array<{
              __typename: 'platform_subscription_addon',
              quantity: string,
              price?: string | null,
              platform_addon?: {
                __typename: 'platform_addon',
                platform_connection_id: number,
                platform_id: string,
                name: string,
                platform_addon_prices: Array<{
                  __typename: 'platform_addon_price',
                  currency: platform_currency_enum,
                  interval?: platform_plan_interval_enum | null,
                  interval_count?: number | null,
                  pricing_model: platform_plan_pricing_model_enum,
                  platform_currency: {
                    __typename: 'platform_currency',
                    platform_currency_exchange_rates: Array<{
                      __typename: 'platform_currency_exchange_rate',
                      base: platform_base_currency_enum,
                      rate: string
                    }>
                  },
                  platform_addon_price_tiers: Array<{
                    __typename: 'platform_addon_price_tier',
                    up_to: number,
                    price: string
                  }>,
                  platform_connection: {
                    __typename: 'platform_connection',
                    account: {
                      __typename: 'account',
                      currency: platform_base_currency_enum
                    }
                  }
                }>,
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null,
              platform_addon_price?: {
                __typename: 'platform_addon_price',
                currency: platform_currency_enum,
                interval?: platform_plan_interval_enum | null,
                interval_count?: number | null,
                pricing_model: platform_plan_pricing_model_enum,
                platform_currency: {
                  __typename: 'platform_currency',
                  platform_currency_exchange_rates: Array<{
                    __typename: 'platform_currency_exchange_rate',
                    base: platform_base_currency_enum,
                    rate: string
                  }>
                },
                platform_addon_price_tiers: Array<{
                  __typename: 'platform_addon_price_tier',
                  up_to: number,
                  price: string
                }>,
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null,
              platform_subscription?: {
                __typename: 'platform_subscription',
                platform_subscription_plans: Array<{
                  __typename: 'platform_subscription_plan',
                  quantity: string,
                  price?: string | null,
                  interval?: platform_plan_interval_enum | null,
                  interval_count?: number | null,
                  platform_plan?: {
                    __typename: 'platform_plan',
                    platform_connection_id: number,
                    platform_id: string,
                    platform_product_id: string,
                    name: string,
                    currency: platform_currency_enum,
                    interval: platform_plan_interval_enum,
                    interval_count: number,
                    pricing_model: platform_plan_pricing_model_enum,
                    platform_plan_tiers: Array<{
                      __typename: 'platform_plan_tier',
                      up_to: number,
                      price: string,
                      flat_fee: string
                    }>,
                    platform_product?: {
                      __typename: 'platform_product',
                      platform_connection_id: number,
                      platform_id: string,
                      name: string
                    } | null,
                    platform_currency: {
                      __typename: 'platform_currency',
                      platform_currency_exchange_rates: Array<{
                        __typename: 'platform_currency_exchange_rate',
                        base: platform_base_currency_enum,
                        rate: string
                      }>
                    },
                    platform_connection: {
                      __typename: 'platform_connection',
                      account: {
                        __typename: 'account',
                        currency: platform_base_currency_enum
                      }
                    }
                  } | null
                }>
              } | null
            }>,
            platform_invoices: Array<{
              __typename: 'platform_invoice',
              platform_connection_id: number,
              platform_id: string,
              platform_subscription_id: string,
              status: platform_invoice_status_enum,
              amount_paid: string,
              total: string,
              period_end: string,
              number?: string | null
            }>
          } | null,
          canceled_sessions: Array<{
            __typename: 'subscriber_flow',
            id: number
          }>
        }>
      }
    }>
  }>
};

export type RemoveAccountUserAccountOwnerQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type RemoveAccountUserAccountOwnerQuery = {
  account_by_pk?: {
    __typename: 'account',
    owner_user_id?: string | null
  } | null
};

export type RemoveAccountUserMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type RemoveAccountUserMutation = {
  delete_account_user_by_pk?: {
    __typename: 'account_user',
    user_id: string
  } | null
};

export type SetPlatformCustomMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SetPlatformCustomMutation = {
  insert_platform_connection_one?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type SsoLogInDetailsQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;


export type SsoLogInDetailsQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    sso_log_in_url?: string | null,
    sso_connection?: string | null
  }>
};

export type StartSubscriberCampaignViewQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type StartSubscriberCampaignViewQuery = {
  subscriber_campaign: Array<{
    __typename: 'subscriber_campaign',
    id: number,
    token: string,
    status: subscriber_campaign_status_enum,
    created_at: string,
    updated_at: string,
    triggered_at?: string | null,
    completed_at?: string | null,
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer_id: number,
      first_view: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          min?: {
            __typename: 'subscriber_campaign_view_min_fields',
            created_at?: string | null
          } | null
        } | null
      },
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      }
    }>,
    campaign: {
      __typename: 'campaign',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string,
      published_version?: {
        __typename: 'campaign_version',
        offer_rule_group_id: number,
        trigger_type: campaign_trigger_type_enum,
        trigger_url?: string | null,
        delay?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null
  }>
};

export type StartSubscriberCampaignViewMutationVariables = Exact<{
  subscriberCampaignId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  object: subscriber_campaign_view_insert_input;
}>;


export type StartSubscriberCampaignViewMutation = {
  insert_subscriber_campaign_view_one?: {
    __typename: 'subscriber_campaign_view',
    id: number,
    token: string,
    status: subscriber_campaign_view_status_enum,
    created_at: string,
    updated_at: string,
    completed_at?: string | null,
    subscriber_campaign: {
      __typename: 'subscriber_campaign',
      id: number,
      token: string,
      status: subscriber_campaign_status_enum,
      created_at: string,
      updated_at: string,
      triggered_at?: string | null,
      completed_at?: string | null,
      campaign: {
        __typename: 'campaign',
        id: number,
        token: string,
        title: string,
        created_at: string,
        updated_at: string,
        published_version?: {
          __typename: 'campaign_version',
          offer_rule_group_id: number,
          trigger_type: campaign_trigger_type_enum,
          trigger_url?: string | null,
          delay?: string | null
        } | null
      },
      subscriber: {
        __typename: 'subscriber',
        id: number,
        platform_id: string,
        token: string,
        name: string,
        email: string,
        status?: subscriber_status_enum | null,
        created_at: string,
        updated_at: string,
        subscriber_properties: Array<{
          __typename: 'subscriber_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>
      },
      subscription: {
        __typename: 'subscription',
        id: number,
        token: string,
        platform_id: string,
        subscriber_id: number,
        mrr: string,
        status: subscription_status_enum,
        created_at: string,
        updated_at: string,
        subscription_properties: Array<{
          __typename: 'subscription_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>,
        subscriber: {
          __typename: 'subscriber',
          token: string
        }
      },
      subscriber_campaign_offers: Array<{
        __typename: 'subscriber_campaign_offer',
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          goal: offer_goal_enum,
          name: string,
          metadata: any,
          created_at: string,
          updated_at: string,
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            apply_to: offer_coupon_apply_to_enum
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null,
              custom_plan_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              metadata: any
            }>
          } | null
        }
      }>,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      } | null
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null
  } | null,
  update_subscriber_campaign?: {
    __typename: 'subscriber_campaign_mutation_response',
    affected_rows: number
  } | null
};

export type SubmitCustomSubscriberDetailsQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
}>;


export type SubmitCustomSubscriberDetailsQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    flow_test_id?: number | null,
    reroute_allowed: boolean,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      name: string,
      email: string
    },
    subscription: {
      __typename: 'subscription',
      id: number
    },
    flow: {
      __typename: 'flow',
      id: number,
      account_id: number,
      prevent_if_canceled: boolean,
      flow_subscriber_form_questions: Array<{
        __typename: 'flow_subscriber_form_question',
        id: number,
        property_id: number
      }>,
      account: {
        __typename: 'account',
        id: number,
        platform_id_property_id?: number | null,
        intake_require_match: boolean,
        flow_route_rules: Array<{
          __typename: 'flow_route_rule',
          id: number,
          segment_group_ids: any,
          segment_ids: any,
          flow_ids: any,
          flow_test_ids: any,
          eligibility_message_ids: any,
          flow_route_rule_segment_groups: Array<{
            __typename: 'flow_route_rule_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_route_rule_segments: Array<{
            __typename: 'flow_route_rule_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_route_rule_flows: Array<{
            __typename: 'flow_route_rule_flow',
            flow_id?: number | null
          }>,
          flow_route_rule_flow_tests: Array<{
            __typename: 'flow_route_rule_flow_test',
            flow_test_id?: number | null,
            flow_test?: {
              __typename: 'flow_test',
              id: number,
              name: string,
              control_weight: number,
              winning_flow_id?: number | null,
              control_flow: {
                __typename: 'flow',
                id: number
              },
              flow_test_flows: Array<{
                __typename: 'flow_test_flow',
                flow_test_id: number,
                flow_id: number,
                weight: number
              }>
            } | null
          }>,
          flow_route_rule_eligibility_messages: Array<{
            __typename: 'flow_route_rule_eligibility_message',
            eligibility_message_id?: number | null,
            eligibility_message?: {
              __typename: 'eligibility_message',
              id: number,
              name: string
            } | null
          }>
        }>,
        properties: Array<{
          __typename: 'property',
          id: number,
          type: property_type_enum,
          entity: property_entity_enum
        }>
      }
    }
  }>
};

export type SubmitFormAnswersQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SubmitFormAnswersQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    published_version_id?: number | null,
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  }>
};

export type SubmitFormAnswersMutationVariables = Exact<{
  objects: Array<question_answer_insert_input> | question_answer_insert_input;
}>;


export type SubmitFormAnswersMutation = {
  insert_question_answer?: {
    __typename: 'question_answer_mutation_response',
    affected_rows: number
  } | null
};

export type SubmitQueryAnswerQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SubmitQueryAnswerQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    published_version_id?: number | null,
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  }>
};

export type SubmitQuestionAnswerMutationVariables = Exact<{
  object: question_answer_insert_input;
}>;


export type SubmitQuestionAnswerMutation = {
  insert_question_answer_one?: {
    __typename: 'question_answer',
    id: number
  } | null
};

export type SubscriberCampaignAccountQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SubscriberCampaignAccountQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    token: string,
    secret: string,
    prompt_for_subscriber_details: boolean,
    default_campaign?: {
      __typename: 'campaign',
      id: number,
      token: string
    } | null,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null,
    hubspot: Array<{
      __typename: 'integration',
      id: number
    }>,
    intercom: Array<{
      __typename: 'integration',
      id: number
    }>,
    salesforce: Array<{
      __typename: 'integration',
      id: number
    }>
  }>
};

export type SubscriberCampaignCampaignQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SubscriberCampaignCampaignQuery = {
  campaign: Array<{
    __typename: 'campaign',
    id: number,
    account_id: number,
    default_language: language_enum,
    signature_required: boolean,
    published_version?: {
      __typename: 'campaign_version',
      id: number,
      trigger_type: campaign_trigger_type_enum,
      trigger_url?: string | null,
      delay?: string | null
    } | null
  }>
};

export type SubscriberCampaignMutationVariables = Exact<{
  input: subscriber_campaign_insert_input;
}>;


export type SubscriberCampaignMutation = {
  insert_subscriber_campaign_one?: {
    __typename: 'subscriber_campaign',
    id: number,
    token: string,
    status: subscriber_campaign_status_enum,
    present_after?: string | null,
    offer?: {
      __typename: 'offer',
      id: number,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null
    } | null,
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer_id: number,
      first_view: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          min?: {
            __typename: 'subscriber_campaign_view_min_fields',
            created_at?: string | null
          } | null
        } | null
      }
    }>
  } | null
};

export type SubscriberFlowOfferSubscriberFlowQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SubscriberFlowOfferSubscriberFlowQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    offer_id?: number | null,
    subscription: {
      __typename: 'subscription',
      id: number,
      platform_id: string,
      status: subscription_status_enum,
      mrr: string,
      subscriber: {
        __typename: 'subscriber',
        status?: subscriber_status_enum | null,
        inactive_mrr: string,
        active_mrr: string,
        active_offer_id?: number | null,
        platform_customer?: {
          __typename: 'platform_customer',
          platform_coupon_id?: string | null,
          past_due?: boolean | null,
          platform_subscriptions: Array<{
            __typename: 'platform_subscription',
            status: platform_subscription_status_enum,
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              platform_plan_id: string
            }>
          }>
        } | null
      },
      account: {
        __typename: 'account',
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      },
      platform_subscription?: {
        __typename: 'platform_subscription',
        status: platform_subscription_status_enum,
        platform_coupon_id?: string | null,
        past_due?: boolean | null,
        can_pause: boolean,
        can_cancel: boolean,
        paper_code?: string | null,
        cancel_at_period_end?: boolean | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string,
          platform_plan?: {
            __typename: 'platform_plan',
            interval: platform_plan_interval_enum,
            interval_count: number,
            platform_product_id: string,
            platform_product?: {
              __typename: 'platform_product',
              platform_product_group_id?: string | null
            } | null
          } | null,
          swappable_ecommerce_products: Array<{
            __typename: 'platform_subscription_plan_swappable_ecommerce_product',
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              active: boolean
            } | null
          }>
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          platform_addon_id: string
        }>
      } | null
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question_id: number,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      offer_id: number
    }>,
    flow: {
      __typename: 'flow',
      account: {
        __typename: 'account',
        platform_connection?: {
          __typename: 'platform_connection',
          id: number
        } | null
      }
    }
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    id: number,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                offer_ids: any,
                offer_test_ids: any,
                offer_group_ids: any,
                offer_autopilot_offer_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    offer_ids: any,
                    offer_test_ids: any,
                    offer_group_ids: any,
                    offer_autopilot_offer_ids: any,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_offers: Array<{
                      __typename: 'offer_rule_rule_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    }>,
                    offer_rule_rule_offer_tests: Array<{
                      __typename: 'offer_rule_rule_offer_test',
                      offer_rule_rule_id?: number | null,
                      offer_test_id?: number | null,
                      offer_test?: {
                        __typename: 'offer_test',
                        id: number,
                        name: string,
                        token: string,
                        goal: offer_goal_enum,
                        control_weight: number,
                        control_offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        },
                        offer_test_offers: Array<{
                          __typename: 'offer_test_offer',
                          offer_test_id: number,
                          offer_id: number,
                          weight: number,
                          offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          }
                        }>,
                        winning_offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      } | null
                    }>,
                    offer_rule_rule_offer_groups: Array<{
                      __typename: 'offer_rule_rule_offer_group',
                      offer_rule_rule_id?: number | null,
                      offer_group_id?: number | null,
                      offer_group?: {
                        __typename: 'offer_group',
                        id: number,
                        name: string,
                        token: string,
                        show_images: boolean,
                        randomize: boolean,
                        offer_group_offers: Array<{
                          __typename: 'offer_group_offer',
                          position?: number | null,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            type: offer_type_enum,
                            name: string,
                            show_banner: boolean,
                            image_url?: string | null,
                            goal: offer_goal_enum,
                            style: offer_style_enum,
                            created_at: string,
                            updated_at: string,
                            offer_autopilot?: {
                              __typename: 'offer_autopilot',
                              offer_id: number,
                              offer?: {
                                __typename: 'offer',
                                id: number,
                                token: string,
                                name: string,
                                type: offer_type_enum
                              } | null,
                              current_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null,
                              winning_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null,
                              offer_autopilot_offer_autopilot_rounds: Array<{
                                __typename: 'offer_autopilot_offer_autopilot_round',
                                offer_autopilot_round_id?: number | null,
                                round_index?: number | null,
                                offer_autopilot_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null
                              }>
                            } | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_coupon?: {
                              __typename: 'offer_coupon',
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null,
                              months?: number | null
                            } | null,
                            offer_change_plan?: {
                              __typename: 'offer_change_plan',
                              platform_plan_id?: string | null,
                              prorate: boolean,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_modify_subscription?: {
                              __typename: 'offer_modify_subscription',
                              prorate: boolean,
                              modify_at: offer_modify_subscription_modify_at_enum,
                              offer_modify_subscription_options: Array<{
                                __typename: 'offer_modify_subscription_option',
                                type: offer_modify_subscription_option_type_enum,
                                platform_connection_id: number,
                                platform_plan_id?: string | null,
                                platform_addon_id?: string | null
                              }>
                            } | null,
                            offer_trial_extension?: {
                              __typename: 'offer_trial_extension',
                              days: number
                            } | null,
                            offer_pause_subscription?: {
                              __typename: 'offer_pause_subscription',
                              offer_pause_subscription_options: Array<{
                                __typename: 'offer_pause_subscription_option',
                                type: offer_pause_subscription_option_type_enum,
                                interval?: offer_pause_subscription_interval_enum | null,
                                interval_count?: number | null,
                                text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null
                              }>
                            } | null,
                            offer_upgrade?: {
                              __typename: 'offer_upgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_downgrade?: {
                              __typename: 'offer_downgrade',
                              platform_plan_id?: string | null,
                              change_at: offer_change_plan_change_at_enum
                            } | null,
                            offer_product_swap?: {
                              __typename: 'offer_product_swap',
                              specific_products: boolean,
                              offer_product_swap_platform_ecommerce_products: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product',
                                platform_ecommerce_product_id: string,
                                platform_ecommerce_product?: {
                                  __typename: 'platform_ecommerce_product',
                                  platform_id: string,
                                  name: string,
                                  image_url?: string | null,
                                  platform_plan_ecommerce_products: Array<{
                                    __typename: 'platform_plan_ecommerce_product',
                                    discount_amount?: string | null,
                                    discount_type?: string | null
                                  }>
                                } | null,
                                offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                  platform_variant?: {
                                    __typename: 'platform_variant',
                                    platform_id: string,
                                    name: string,
                                    price?: string | null,
                                    platform_variant_options: Array<{
                                      __typename: 'platform_variant_option',
                                      key: string,
                                      value: string
                                    }>
                                  } | null
                                }>
                              }>
                            } | null,
                            offer_reschedule_order?: {
                              __typename: 'offer_reschedule_order',
                              skip: boolean,
                              reschedule: boolean,
                              skip_button_text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null,
                              reschedule_button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null,
                            offer_custom?: {
                              __typename: 'offer_custom',
                              link_href?: string | null,
                              link_text?: string | null
                            } | null,
                            offer_timer?: {
                              __typename: 'offer_timer',
                              offer_timer_mode: offer_timer_mode_enum,
                              start_time?: string | null,
                              end_time?: string | null,
                              duration?: string | null,
                              show_timer?: boolean | null
                            } | null
                          } | null
                        }>,
                        headline_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        cancel_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    }>,
                    offer_rule_rule_offer_autopilot_offers: Array<{
                      __typename: 'offer_rule_rule_offer_autopilot_offer',
                      offer_rule_rule_id?: number | null,
                      offer_id?: number | null,
                      offer_autopilot?: {
                        __typename: 'offer_autopilot',
                        offer_id: number,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null,
                        current_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null,
                        winning_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null,
                        offer_autopilot_offer_autopilot_rounds: Array<{
                          __typename: 'offer_autopilot_offer_autopilot_round',
                          offer_autopilot_round_id?: number | null,
                          round_index?: number | null,
                          offer_autopilot_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>,
                offer_rule_version_offers: Array<{
                  __typename: 'offer_rule_version_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer?: {
                    __typename: 'offer',
                    id: number,
                    token: string,
                    name: string,
                    type: offer_type_enum
                  } | null
                }>,
                offer_rule_version_offer_tests: Array<{
                  __typename: 'offer_rule_version_offer_test',
                  offer_rule_version_id?: number | null,
                  offer_test_id?: number | null,
                  offer_test?: {
                    __typename: 'offer_test',
                    id: number,
                    name: string,
                    token: string,
                    goal: offer_goal_enum,
                    control_weight: number,
                    control_offer: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    },
                    offer_test_offers: Array<{
                      __typename: 'offer_test_offer',
                      offer_test_id: number,
                      offer_id: number,
                      weight: number,
                      offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      }
                    }>,
                    winning_offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_groups: Array<{
                  __typename: 'offer_rule_version_offer_group',
                  offer_rule_version_id?: number | null,
                  offer_group_id?: number | null,
                  offer_group?: {
                    __typename: 'offer_group',
                    id: number,
                    name: string,
                    token: string,
                    show_images: boolean,
                    randomize: boolean,
                    offer_group_offers: Array<{
                      __typename: 'offer_group_offer',
                      position?: number | null,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        type: offer_type_enum,
                        name: string,
                        show_banner: boolean,
                        image_url?: string | null,
                        goal: offer_goal_enum,
                        style: offer_style_enum,
                        created_at: string,
                        updated_at: string,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_coupon?: {
                          __typename: 'offer_coupon',
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null,
                          months?: number | null
                        } | null,
                        offer_change_plan?: {
                          __typename: 'offer_change_plan',
                          platform_plan_id?: string | null,
                          prorate: boolean,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_modify_subscription?: {
                          __typename: 'offer_modify_subscription',
                          prorate: boolean,
                          modify_at: offer_modify_subscription_modify_at_enum,
                          offer_modify_subscription_options: Array<{
                            __typename: 'offer_modify_subscription_option',
                            type: offer_modify_subscription_option_type_enum,
                            platform_connection_id: number,
                            platform_plan_id?: string | null,
                            platform_addon_id?: string | null
                          }>
                        } | null,
                        offer_trial_extension?: {
                          __typename: 'offer_trial_extension',
                          days: number
                        } | null,
                        offer_pause_subscription?: {
                          __typename: 'offer_pause_subscription',
                          offer_pause_subscription_options: Array<{
                            __typename: 'offer_pause_subscription_option',
                            type: offer_pause_subscription_option_type_enum,
                            interval?: offer_pause_subscription_interval_enum | null,
                            interval_count?: number | null,
                            text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null
                          }>
                        } | null,
                        offer_upgrade?: {
                          __typename: 'offer_upgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_downgrade?: {
                          __typename: 'offer_downgrade',
                          platform_plan_id?: string | null,
                          change_at: offer_change_plan_change_at_enum
                        } | null,
                        offer_product_swap?: {
                          __typename: 'offer_product_swap',
                          specific_products: boolean,
                          offer_product_swap_platform_ecommerce_products: Array<{
                            __typename: 'offer_product_swap_platform_ecommerce_product',
                            platform_ecommerce_product_id: string,
                            platform_ecommerce_product?: {
                              __typename: 'platform_ecommerce_product',
                              platform_id: string,
                              name: string,
                              image_url?: string | null,
                              platform_plan_ecommerce_products: Array<{
                                __typename: 'platform_plan_ecommerce_product',
                                discount_amount?: string | null,
                                discount_type?: string | null
                              }>
                            } | null,
                            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                              platform_variant?: {
                                __typename: 'platform_variant',
                                platform_id: string,
                                name: string,
                                price?: string | null,
                                platform_variant_options: Array<{
                                  __typename: 'platform_variant_option',
                                  key: string,
                                  value: string
                                }>
                              } | null
                            }>
                          }>
                        } | null,
                        offer_reschedule_order?: {
                          __typename: 'offer_reschedule_order',
                          skip: boolean,
                          reschedule: boolean,
                          skip_button_text_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          reschedule_button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        offer_custom?: {
                          __typename: 'offer_custom',
                          link_href?: string | null,
                          link_text?: string | null
                        } | null,
                        offer_timer?: {
                          __typename: 'offer_timer',
                          offer_timer_mode: offer_timer_mode_enum,
                          start_time?: string | null,
                          end_time?: string | null,
                          duration?: string | null,
                          show_timer?: boolean | null
                        } | null
                      } | null
                    }>,
                    headline_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    content_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    },
                    cancel_text_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                }>,
                offer_rule_version_offer_autopilot_offers: Array<{
                  __typename: 'offer_rule_version_offer_autopilot_offer',
                  offer_rule_version_id?: number | null,
                  offer_id?: number | null,
                  offer_autopilot?: {
                    __typename: 'offer_autopilot',
                    offer_id: number,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null,
                    current_round?: {
                      __typename: 'offer_autopilot_round',
                      id: number,
                      offer_id: number,
                      control_weight: number,
                      control_variant_id: number,
                      winning_variant_id?: number | null,
                      offer_autopilot_round_offer_variants: Array<{
                        __typename: 'offer_autopilot_round_offer_variant',
                        offer_autopilot_round_id?: number | null,
                        offer_variant_id?: number | null,
                        offer_variant?: {
                          __typename: 'offer_variant',
                          id: number,
                          type: offer_type_enum,
                          offer_id: number,
                          approved_at?: string | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_variant_coupon?: {
                            __typename: 'offer_variant_coupon',
                            offer_variant_id: number,
                            offer_id: number,
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            months?: number | null,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null
                          } | null
                        } | null
                      }>
                    } | null,
                    winning_variant?: {
                      __typename: 'offer_variant',
                      id: number,
                      type: offer_type_enum,
                      offer_id: number,
                      approved_at?: string | null,
                      headline_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      button_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      offer_variant_coupon?: {
                        __typename: 'offer_variant_coupon',
                        offer_variant_id: number,
                        offer_id: number,
                        type: offer_coupon_type_enum,
                        amount_off: string,
                        duration: coupon_duration_enum,
                        months?: number | null,
                        duration_interval?: coupon_duration_interval_enum | null,
                        duration_count?: number | null
                      } | null
                    } | null,
                    offer_autopilot_offer_autopilot_rounds: Array<{
                      __typename: 'offer_autopilot_offer_autopilot_round',
                      offer_autopilot_round_id?: number | null,
                      round_index?: number | null,
                      offer_autopilot_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention: {
        __typename: 'intervention',
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  offer_group_ids: any,
                  offer_autopilot_offer_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      offer_autopilot_offer_ids: any,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          goal: offer_goal_enum,
                          control_weight: number,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            weight: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>,
                      offer_rule_rule_offer_groups: Array<{
                        __typename: 'offer_rule_rule_offer_group',
                        offer_rule_rule_id?: number | null,
                        offer_group_id?: number | null,
                        offer_group?: {
                          __typename: 'offer_group',
                          id: number,
                          name: string,
                          token: string,
                          show_images: boolean,
                          randomize: boolean,
                          offer_group_offers: Array<{
                            __typename: 'offer_group_offer',
                            position?: number | null,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              type: offer_type_enum,
                              name: string,
                              show_banner: boolean,
                              image_url?: string | null,
                              goal: offer_goal_enum,
                              style: offer_style_enum,
                              created_at: string,
                              updated_at: string,
                              offer_autopilot?: {
                                __typename: 'offer_autopilot',
                                offer_id: number,
                                offer?: {
                                  __typename: 'offer',
                                  id: number,
                                  token: string,
                                  name: string,
                                  type: offer_type_enum
                                } | null,
                                current_round?: {
                                  __typename: 'offer_autopilot_round',
                                  id: number,
                                  offer_id: number,
                                  control_weight: number,
                                  control_variant_id: number,
                                  winning_variant_id?: number | null,
                                  offer_autopilot_round_offer_variants: Array<{
                                    __typename: 'offer_autopilot_round_offer_variant',
                                    offer_autopilot_round_id?: number | null,
                                    offer_variant_id?: number | null,
                                    offer_variant?: {
                                      __typename: 'offer_variant',
                                      id: number,
                                      type: offer_type_enum,
                                      offer_id: number,
                                      approved_at?: string | null,
                                      headline_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      content_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      button_text_translation: {
                                        __typename: 'translation',
                                        id: number,
                                        translation_values: Array<{
                                          __typename: 'translation_value',
                                          translation_id: number,
                                          language: language_enum,
                                          value: any,
                                          format?: translation_value_format_enum | null
                                        }>
                                      },
                                      offer_variant_coupon?: {
                                        __typename: 'offer_variant_coupon',
                                        offer_variant_id: number,
                                        offer_id: number,
                                        type: offer_coupon_type_enum,
                                        amount_off: string,
                                        duration: coupon_duration_enum,
                                        months?: number | null,
                                        duration_interval?: coupon_duration_interval_enum | null,
                                        duration_count?: number | null
                                      } | null
                                    } | null
                                  }>
                                } | null,
                                winning_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null,
                                offer_autopilot_offer_autopilot_rounds: Array<{
                                  __typename: 'offer_autopilot_offer_autopilot_round',
                                  offer_autopilot_round_id?: number | null,
                                  round_index?: number | null,
                                  offer_autopilot_round?: {
                                    __typename: 'offer_autopilot_round',
                                    id: number,
                                    offer_id: number,
                                    control_weight: number,
                                    control_variant_id: number,
                                    winning_variant_id?: number | null,
                                    offer_autopilot_round_offer_variants: Array<{
                                      __typename: 'offer_autopilot_round_offer_variant',
                                      offer_autopilot_round_id?: number | null,
                                      offer_variant_id?: number | null,
                                      offer_variant?: {
                                        __typename: 'offer_variant',
                                        id: number,
                                        type: offer_type_enum,
                                        offer_id: number,
                                        approved_at?: string | null,
                                        headline_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        content_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        button_text_translation: {
                                          __typename: 'translation',
                                          id: number,
                                          translation_values: Array<{
                                            __typename: 'translation_value',
                                            translation_id: number,
                                            language: language_enum,
                                            value: any,
                                            format?: translation_value_format_enum | null
                                          }>
                                        },
                                        offer_variant_coupon?: {
                                          __typename: 'offer_variant_coupon',
                                          offer_variant_id: number,
                                          offer_id: number,
                                          type: offer_coupon_type_enum,
                                          amount_off: string,
                                          duration: coupon_duration_enum,
                                          months?: number | null,
                                          duration_interval?: coupon_duration_interval_enum | null,
                                          duration_count?: number | null
                                        } | null
                                      } | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_coupon?: {
                                __typename: 'offer_coupon',
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null,
                                months?: number | null
                              } | null,
                              offer_change_plan?: {
                                __typename: 'offer_change_plan',
                                platform_plan_id?: string | null,
                                prorate: boolean,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_modify_subscription?: {
                                __typename: 'offer_modify_subscription',
                                prorate: boolean,
                                modify_at: offer_modify_subscription_modify_at_enum,
                                offer_modify_subscription_options: Array<{
                                  __typename: 'offer_modify_subscription_option',
                                  type: offer_modify_subscription_option_type_enum,
                                  platform_connection_id: number,
                                  platform_plan_id?: string | null,
                                  platform_addon_id?: string | null
                                }>
                              } | null,
                              offer_trial_extension?: {
                                __typename: 'offer_trial_extension',
                                days: number
                              } | null,
                              offer_pause_subscription?: {
                                __typename: 'offer_pause_subscription',
                                offer_pause_subscription_options: Array<{
                                  __typename: 'offer_pause_subscription_option',
                                  type: offer_pause_subscription_option_type_enum,
                                  interval?: offer_pause_subscription_interval_enum | null,
                                  interval_count?: number | null,
                                  text_translation?: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  } | null
                                }>
                              } | null,
                              offer_upgrade?: {
                                __typename: 'offer_upgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_downgrade?: {
                                __typename: 'offer_downgrade',
                                platform_plan_id?: string | null,
                                change_at: offer_change_plan_change_at_enum
                              } | null,
                              offer_product_swap?: {
                                __typename: 'offer_product_swap',
                                specific_products: boolean,
                                offer_product_swap_platform_ecommerce_products: Array<{
                                  __typename: 'offer_product_swap_platform_ecommerce_product',
                                  platform_ecommerce_product_id: string,
                                  platform_ecommerce_product?: {
                                    __typename: 'platform_ecommerce_product',
                                    platform_id: string,
                                    name: string,
                                    image_url?: string | null,
                                    platform_plan_ecommerce_products: Array<{
                                      __typename: 'platform_plan_ecommerce_product',
                                      discount_amount?: string | null,
                                      discount_type?: string | null
                                    }>
                                  } | null,
                                  offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                    __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                    platform_variant?: {
                                      __typename: 'platform_variant',
                                      platform_id: string,
                                      name: string,
                                      price?: string | null,
                                      platform_variant_options: Array<{
                                        __typename: 'platform_variant_option',
                                        key: string,
                                        value: string
                                      }>
                                    } | null
                                  }>
                                }>
                              } | null,
                              offer_reschedule_order?: {
                                __typename: 'offer_reschedule_order',
                                skip: boolean,
                                reschedule: boolean,
                                skip_button_text_translation?: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                } | null,
                                reschedule_button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null,
                              offer_custom?: {
                                __typename: 'offer_custom',
                                link_href?: string | null,
                                link_text?: string | null
                              } | null,
                              offer_timer?: {
                                __typename: 'offer_timer',
                                offer_timer_mode: offer_timer_mode_enum,
                                start_time?: string | null,
                                end_time?: string | null,
                                duration?: string | null,
                                show_timer?: boolean | null
                              } | null
                            } | null
                          }>,
                          headline_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          cancel_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      }>,
                      offer_rule_rule_offer_autopilot_offers: Array<{
                        __typename: 'offer_rule_rule_offer_autopilot_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer_autopilot?: {
                          __typename: 'offer_autopilot',
                          offer_id: number,
                          offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null,
                          current_round?: {
                            __typename: 'offer_autopilot_round',
                            id: number,
                            offer_id: number,
                            control_weight: number,
                            control_variant_id: number,
                            winning_variant_id?: number | null,
                            offer_autopilot_round_offer_variants: Array<{
                              __typename: 'offer_autopilot_round_offer_variant',
                              offer_autopilot_round_id?: number | null,
                              offer_variant_id?: number | null,
                              offer_variant?: {
                                __typename: 'offer_variant',
                                id: number,
                                type: offer_type_enum,
                                offer_id: number,
                                approved_at?: string | null,
                                headline_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                content_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                button_text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                offer_variant_coupon?: {
                                  __typename: 'offer_variant_coupon',
                                  offer_variant_id: number,
                                  offer_id: number,
                                  type: offer_coupon_type_enum,
                                  amount_off: string,
                                  duration: coupon_duration_enum,
                                  months?: number | null,
                                  duration_interval?: coupon_duration_interval_enum | null,
                                  duration_count?: number | null
                                } | null
                              } | null
                            }>
                          } | null,
                          winning_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null,
                          offer_autopilot_offer_autopilot_rounds: Array<{
                            __typename: 'offer_autopilot_offer_autopilot_round',
                            offer_autopilot_round_id?: number | null,
                            round_index?: number | null,
                            offer_autopilot_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null
                          }>
                        } | null
                      }>,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      goal: offer_goal_enum,
                      control_weight: number,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        weight: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_groups: Array<{
                    __typename: 'offer_rule_version_offer_group',
                    offer_rule_version_id?: number | null,
                    offer_group_id?: number | null,
                    offer_group?: {
                      __typename: 'offer_group',
                      id: number,
                      name: string,
                      token: string,
                      show_images: boolean,
                      randomize: boolean,
                      offer_group_offers: Array<{
                        __typename: 'offer_group_offer',
                        position?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          type: offer_type_enum,
                          name: string,
                          show_banner: boolean,
                          image_url?: string | null,
                          goal: offer_goal_enum,
                          style: offer_style_enum,
                          created_at: string,
                          updated_at: string,
                          offer_autopilot?: {
                            __typename: 'offer_autopilot',
                            offer_id: number,
                            offer?: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            } | null,
                            current_round?: {
                              __typename: 'offer_autopilot_round',
                              id: number,
                              offer_id: number,
                              control_weight: number,
                              control_variant_id: number,
                              winning_variant_id?: number | null,
                              offer_autopilot_round_offer_variants: Array<{
                                __typename: 'offer_autopilot_round_offer_variant',
                                offer_autopilot_round_id?: number | null,
                                offer_variant_id?: number | null,
                                offer_variant?: {
                                  __typename: 'offer_variant',
                                  id: number,
                                  type: offer_type_enum,
                                  offer_id: number,
                                  approved_at?: string | null,
                                  headline_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  content_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  button_text_translation: {
                                    __typename: 'translation',
                                    id: number,
                                    translation_values: Array<{
                                      __typename: 'translation_value',
                                      translation_id: number,
                                      language: language_enum,
                                      value: any,
                                      format?: translation_value_format_enum | null
                                    }>
                                  },
                                  offer_variant_coupon?: {
                                    __typename: 'offer_variant_coupon',
                                    offer_variant_id: number,
                                    offer_id: number,
                                    type: offer_coupon_type_enum,
                                    amount_off: string,
                                    duration: coupon_duration_enum,
                                    months?: number | null,
                                    duration_interval?: coupon_duration_interval_enum | null,
                                    duration_count?: number | null
                                  } | null
                                } | null
                              }>
                            } | null,
                            winning_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null,
                            offer_autopilot_offer_autopilot_rounds: Array<{
                              __typename: 'offer_autopilot_offer_autopilot_round',
                              offer_autopilot_round_id?: number | null,
                              round_index?: number | null,
                              offer_autopilot_round?: {
                                __typename: 'offer_autopilot_round',
                                id: number,
                                offer_id: number,
                                control_weight: number,
                                control_variant_id: number,
                                winning_variant_id?: number | null,
                                offer_autopilot_round_offer_variants: Array<{
                                  __typename: 'offer_autopilot_round_offer_variant',
                                  offer_autopilot_round_id?: number | null,
                                  offer_variant_id?: number | null,
                                  offer_variant?: {
                                    __typename: 'offer_variant',
                                    id: number,
                                    type: offer_type_enum,
                                    offer_id: number,
                                    approved_at?: string | null,
                                    headline_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    content_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    button_text_translation: {
                                      __typename: 'translation',
                                      id: number,
                                      translation_values: Array<{
                                        __typename: 'translation_value',
                                        translation_id: number,
                                        language: language_enum,
                                        value: any,
                                        format?: translation_value_format_enum | null
                                      }>
                                    },
                                    offer_variant_coupon?: {
                                      __typename: 'offer_variant_coupon',
                                      offer_variant_id: number,
                                      offer_id: number,
                                      type: offer_coupon_type_enum,
                                      amount_off: string,
                                      duration: coupon_duration_enum,
                                      months?: number | null,
                                      duration_interval?: coupon_duration_interval_enum | null,
                                      duration_count?: number | null
                                    } | null
                                  } | null
                                }>
                              } | null
                            }>
                          } | null,
                          headline_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          button_text_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          offer_coupon?: {
                            __typename: 'offer_coupon',
                            type: offer_coupon_type_enum,
                            amount_off: string,
                            duration: coupon_duration_enum,
                            duration_interval?: coupon_duration_interval_enum | null,
                            duration_count?: number | null,
                            months?: number | null
                          } | null,
                          offer_change_plan?: {
                            __typename: 'offer_change_plan',
                            platform_plan_id?: string | null,
                            prorate: boolean,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_modify_subscription?: {
                            __typename: 'offer_modify_subscription',
                            prorate: boolean,
                            modify_at: offer_modify_subscription_modify_at_enum,
                            offer_modify_subscription_options: Array<{
                              __typename: 'offer_modify_subscription_option',
                              type: offer_modify_subscription_option_type_enum,
                              platform_connection_id: number,
                              platform_plan_id?: string | null,
                              platform_addon_id?: string | null
                            }>
                          } | null,
                          offer_trial_extension?: {
                            __typename: 'offer_trial_extension',
                            days: number
                          } | null,
                          offer_pause_subscription?: {
                            __typename: 'offer_pause_subscription',
                            offer_pause_subscription_options: Array<{
                              __typename: 'offer_pause_subscription_option',
                              type: offer_pause_subscription_option_type_enum,
                              interval?: offer_pause_subscription_interval_enum | null,
                              interval_count?: number | null,
                              text_translation?: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              } | null
                            }>
                          } | null,
                          offer_upgrade?: {
                            __typename: 'offer_upgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_downgrade?: {
                            __typename: 'offer_downgrade',
                            platform_plan_id?: string | null,
                            change_at: offer_change_plan_change_at_enum
                          } | null,
                          offer_product_swap?: {
                            __typename: 'offer_product_swap',
                            specific_products: boolean,
                            offer_product_swap_platform_ecommerce_products: Array<{
                              __typename: 'offer_product_swap_platform_ecommerce_product',
                              platform_ecommerce_product_id: string,
                              platform_ecommerce_product?: {
                                __typename: 'platform_ecommerce_product',
                                platform_id: string,
                                name: string,
                                image_url?: string | null,
                                platform_plan_ecommerce_products: Array<{
                                  __typename: 'platform_plan_ecommerce_product',
                                  discount_amount?: string | null,
                                  discount_type?: string | null
                                }>
                              } | null,
                              offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
                                __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
                                platform_variant?: {
                                  __typename: 'platform_variant',
                                  platform_id: string,
                                  name: string,
                                  price?: string | null,
                                  platform_variant_options: Array<{
                                    __typename: 'platform_variant_option',
                                    key: string,
                                    value: string
                                  }>
                                } | null
                              }>
                            }>
                          } | null,
                          offer_reschedule_order?: {
                            __typename: 'offer_reschedule_order',
                            skip: boolean,
                            reschedule: boolean,
                            skip_button_text_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            reschedule_button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null,
                          offer_custom?: {
                            __typename: 'offer_custom',
                            link_href?: string | null,
                            link_text?: string | null
                          } | null,
                          offer_timer?: {
                            __typename: 'offer_timer',
                            offer_timer_mode: offer_timer_mode_enum,
                            start_time?: string | null,
                            end_time?: string | null,
                            duration?: string | null,
                            show_timer?: boolean | null
                          } | null
                        } | null
                      }>,
                      headline_translation?: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      } | null,
                      content_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      },
                      cancel_text_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  }>,
                  offer_rule_version_offer_autopilot_offers: Array<{
                    __typename: 'offer_rule_version_offer_autopilot_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer_autopilot?: {
                      __typename: 'offer_autopilot',
                      offer_id: number,
                      offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null,
                      current_round?: {
                        __typename: 'offer_autopilot_round',
                        id: number,
                        offer_id: number,
                        control_weight: number,
                        control_variant_id: number,
                        winning_variant_id?: number | null,
                        offer_autopilot_round_offer_variants: Array<{
                          __typename: 'offer_autopilot_round_offer_variant',
                          offer_autopilot_round_id?: number | null,
                          offer_variant_id?: number | null,
                          offer_variant?: {
                            __typename: 'offer_variant',
                            id: number,
                            type: offer_type_enum,
                            offer_id: number,
                            approved_at?: string | null,
                            headline_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            button_text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            offer_variant_coupon?: {
                              __typename: 'offer_variant_coupon',
                              offer_variant_id: number,
                              offer_id: number,
                              type: offer_coupon_type_enum,
                              amount_off: string,
                              duration: coupon_duration_enum,
                              months?: number | null,
                              duration_interval?: coupon_duration_interval_enum | null,
                              duration_count?: number | null
                            } | null
                          } | null
                        }>
                      } | null,
                      winning_variant?: {
                        __typename: 'offer_variant',
                        id: number,
                        type: offer_type_enum,
                        offer_id: number,
                        approved_at?: string | null,
                        headline_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        button_text_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        offer_variant_coupon?: {
                          __typename: 'offer_variant_coupon',
                          offer_variant_id: number,
                          offer_id: number,
                          type: offer_coupon_type_enum,
                          amount_off: string,
                          duration: coupon_duration_enum,
                          months?: number | null,
                          duration_interval?: coupon_duration_interval_enum | null,
                          duration_count?: number | null
                        } | null
                      } | null,
                      offer_autopilot_offer_autopilot_rounds: Array<{
                        __typename: 'offer_autopilot_offer_autopilot_round',
                        offer_autopilot_round_id?: number | null,
                        round_index?: number | null,
                        offer_autopilot_round?: {
                          __typename: 'offer_autopilot_round',
                          id: number,
                          offer_id: number,
                          control_weight: number,
                          control_variant_id: number,
                          winning_variant_id?: number | null,
                          offer_autopilot_round_offer_variants: Array<{
                            __typename: 'offer_autopilot_round_offer_variant',
                            offer_autopilot_round_id?: number | null,
                            offer_variant_id?: number | null,
                            offer_variant?: {
                              __typename: 'offer_variant',
                              id: number,
                              type: offer_type_enum,
                              offer_id: number,
                              approved_at?: string | null,
                              headline_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              content_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              button_text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              offer_variant_coupon?: {
                                __typename: 'offer_variant_coupon',
                                offer_variant_id: number,
                                offer_id: number,
                                type: offer_coupon_type_enum,
                                amount_off: string,
                                duration: coupon_duration_enum,
                                months?: number | null,
                                duration_interval?: coupon_duration_interval_enum | null,
                                duration_count?: number | null
                              } | null
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      }
    } | null
  }>
};

export type SubscriberFlowOfferOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  variantIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  roundId: Scalars['Int']['input'];
}>;


export type SubscriberFlowOfferOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    show_banner: boolean,
    override_active_offer: boolean,
    style: offer_style_enum,
    goal: offer_goal_enum,
    created_at: string,
    updated_at: string,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_coupon?: {
      __typename: 'offer_coupon',
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      override_active_coupon: boolean,
      platform_coupon?: {
        __typename: 'platform_coupon',
        platform_id: string,
        apply_to: platform_coupon_apply_to_enum,
        apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
        valid: boolean,
        platform_coupon_product_groups: Array<{
          __typename: 'platform_coupon_product_group',
          platform_product_group_id: string
        }>,
        platform_coupon_products: Array<{
          __typename: 'platform_coupon_product',
          platform_product_id: string
        }>,
        platform_coupon_plans: Array<{
          __typename: 'platform_coupon_plan',
          platform_plan_id: string
        }>,
        platform_coupon_addons: Array<{
          __typename: 'platform_coupon_addon',
          platform_addon_id: string
        }>
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        override_active_offer: boolean,
        style: offer_style_enum,
        goal: offer_goal_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          override_active_coupon: boolean,
          platform_coupon?: {
            __typename: 'platform_coupon',
            platform_id: string,
            apply_to: platform_coupon_apply_to_enum,
            apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
            valid: boolean,
            platform_coupon_product_groups: Array<{
              __typename: 'platform_coupon_product_group',
              platform_product_group_id: string
            }>,
            platform_coupon_products: Array<{
              __typename: 'platform_coupon_product',
              platform_product_id: string
            }>,
            platform_coupon_plans: Array<{
              __typename: 'platform_coupon_plan',
              platform_plan_id: string
            }>,
            platform_coupon_addons: Array<{
              __typename: 'platform_coupon_addon',
              platform_addon_id: string
            }>
          } | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        platform_addon?: {
          __typename: 'platform_addon',
          platform_id: string,
          platform_addon_plans: Array<{
            __typename: 'platform_addon_plan',
            platform_plan_id: string
          }>
        } | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_id: string,
          interval: platform_plan_interval_enum,
          interval_count: number
        } | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string
      }>
    } | null
  } | null,
  offer_autopilot_round_offer_variant: Array<{
    __typename: 'offer_autopilot_round_offer_variant',
    offer_autopilot_round_id?: number | null,
    offer_variant_id?: number | null,
    round_index?: number | null,
    offer_autopilot_round?: {
      __typename: 'offer_autopilot_round',
      id: number,
      offer_id: number,
      control_weight: number,
      control_variant_id: number,
      winning_variant_id?: number | null,
      offer_autopilot_round_offer_variants: Array<{
        __typename: 'offer_autopilot_round_offer_variant',
        offer_autopilot_round_id?: number | null,
        offer_variant_id?: number | null,
        offer_variant?: {
          __typename: 'offer_variant',
          id: number,
          type: offer_type_enum,
          offer_id: number,
          approved_at?: string | null,
          headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          offer_variant_coupon?: {
            __typename: 'offer_variant_coupon',
            offer_variant_id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            duration_count?: number | null
          } | null
        } | null
      }>
    } | null,
    offer_variant?: {
      __typename: 'offer_variant',
      id: number,
      type: offer_type_enum,
      offer_id: number,
      approved_at?: string | null,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_variant_coupon?: {
        __typename: 'offer_variant_coupon',
        offer_variant_id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        duration_count?: number | null
      } | null
    } | null
  }>
};

export type SubscriberFlowOfferLogMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  offerRuleId: Scalars['Int']['input'];
  offerRuleVersionId: Scalars['Int']['input'];
  segments: Array<subscriber_flow_segment_insert_input> | subscriber_flow_segment_insert_input;
  segmentGroups: Array<subscriber_flow_segment_group_insert_input> | subscriber_flow_segment_group_insert_input;
  overrides: Array<subscriber_flow_override_insert_input> | subscriber_flow_override_insert_input;
}>;


export type SubscriberFlowOfferLogMutation = {
  insert_subscriber_flow_offer_rule_one?: {
    __typename: 'subscriber_flow_offer_rule',
    subscriber_flow_id: number
  } | null,
  insert_subscriber_flow_segment?: {
    __typename: 'subscriber_flow_segment_mutation_response',
    affected_rows: number
  } | null,
  insert_subscriber_flow_segment_group?: {
    __typename: 'subscriber_flow_segment_group_mutation_response',
    affected_rows: number
  } | null,
  insert_subscriber_flow_override?: {
    __typename: 'subscriber_flow_override_mutation_response',
    affected_rows: number
  } | null
};

export type SubscriberFlowOfferFlowOfferGroupQueryVariables = Exact<{
  offerGroupId: Scalars['Int']['input'];
  offerVariantIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SubscriberFlowOfferFlowOfferGroupQuery = {
  offer_group_by_pk?: {
    __typename: 'offer_group',
    id: number,
    name: string,
    token: string,
    show_images: boolean,
    randomize: boolean,
    offer_group_offers: Array<{
      __typename: 'offer_group_offer',
      position?: number | null,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        image_url?: string | null,
        goal: offer_goal_enum,
        style: offer_style_enum,
        created_at: string,
        updated_at: string,
        offer_autopilot?: {
          __typename: 'offer_autopilot',
          offer_id: number,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            name: string,
            type: offer_type_enum
          } | null,
          current_round?: {
            __typename: 'offer_autopilot_round',
            id: number,
            offer_id: number,
            control_weight: number,
            control_variant_id: number,
            winning_variant_id?: number | null,
            offer_autopilot_round_offer_variants: Array<{
              __typename: 'offer_autopilot_round_offer_variant',
              offer_autopilot_round_id?: number | null,
              offer_variant_id?: number | null,
              offer_variant?: {
                __typename: 'offer_variant',
                id: number,
                type: offer_type_enum,
                offer_id: number,
                approved_at?: string | null,
                headline_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                content_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                button_text_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                },
                offer_variant_coupon?: {
                  __typename: 'offer_variant_coupon',
                  offer_variant_id: number,
                  offer_id: number,
                  type: offer_coupon_type_enum,
                  amount_off: string,
                  duration: coupon_duration_enum,
                  months?: number | null,
                  duration_interval?: coupon_duration_interval_enum | null,
                  duration_count?: number | null
                } | null
              } | null
            }>
          } | null,
          winning_variant?: {
            __typename: 'offer_variant',
            id: number,
            type: offer_type_enum,
            offer_id: number,
            approved_at?: string | null,
            headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            button_text_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            offer_variant_coupon?: {
              __typename: 'offer_variant_coupon',
              offer_variant_id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              duration_count?: number | null
            } | null
          } | null,
          offer_autopilot_offer_autopilot_rounds: Array<{
            __typename: 'offer_autopilot_offer_autopilot_round',
            offer_autopilot_round_id?: number | null,
            round_index?: number | null,
            offer_autopilot_round?: {
              __typename: 'offer_autopilot_round',
              id: number,
              offer_id: number,
              control_weight: number,
              control_variant_id: number,
              winning_variant_id?: number | null,
              offer_autopilot_round_offer_variants: Array<{
                __typename: 'offer_autopilot_round_offer_variant',
                offer_autopilot_round_id?: number | null,
                offer_variant_id?: number | null,
                offer_variant?: {
                  __typename: 'offer_variant',
                  id: number,
                  type: offer_type_enum,
                  offer_id: number,
                  approved_at?: string | null,
                  headline_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  content_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  button_text_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  },
                  offer_variant_coupon?: {
                    __typename: 'offer_variant_coupon',
                    offer_variant_id: number,
                    offer_id: number,
                    type: offer_coupon_type_enum,
                    amount_off: string,
                    duration: coupon_duration_enum,
                    months?: number | null,
                    duration_interval?: coupon_duration_interval_enum | null,
                    duration_count?: number | null
                  } | null
                } | null
              }>
            } | null
          }>
        } | null,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_interval?: coupon_duration_interval_enum | null,
          duration_count?: number | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            text_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          }>
        } | null,
        offer_upgrade?: {
          __typename: 'offer_upgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_downgrade?: {
          __typename: 'offer_downgrade',
          platform_plan_id?: string | null,
          change_at: offer_change_plan_change_at_enum
        } | null,
        offer_product_swap?: {
          __typename: 'offer_product_swap',
          specific_products: boolean,
          offer_product_swap_platform_ecommerce_products: Array<{
            __typename: 'offer_product_swap_platform_ecommerce_product',
            platform_ecommerce_product_id: string,
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              name: string,
              image_url?: string | null,
              platform_plan_ecommerce_products: Array<{
                __typename: 'platform_plan_ecommerce_product',
                discount_amount?: string | null,
                discount_type?: string | null
              }>
            } | null,
            offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
              __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
              platform_variant?: {
                __typename: 'platform_variant',
                platform_id: string,
                name: string,
                price?: string | null,
                platform_variant_options: Array<{
                  __typename: 'platform_variant_option',
                  key: string,
                  value: string
                }>
              } | null
            }>
          }>
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean,
          skip_button_text_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          reschedule_button_text_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null
      } | null
    }>,
    headline_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null,
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    cancel_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    }
  } | null,
  offer_variant: Array<{
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  }>
};

export type SubscriberFlowOfferOfferGroupMutationVariables = Exact<{
  object: subscriber_flow_offer_group_insert_input;
}>;


export type SubscriberFlowOfferOfferGroupMutation = {
  insert_subscriber_flow_offer_group_one?: {
    __typename: 'subscriber_flow_offer_group',
    subscriber_flow_id: number
  } | null
};

export type SubscriberFlowOfferOfferGroupOfferAutopilotRoundMutationVariables = Exact<{
  offerAutopilotRoundIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type SubscriberFlowOfferOfferGroupOfferAutopilotRoundMutation = {
  update_offer_autopilot_round?: {
    __typename: 'offer_autopilot_round_mutation_response',
    affected_rows: number
  } | null
};

export type SubscriberFlowOfferFlowOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
  offerVariantId: Scalars['Int']['input'];
}>;


export type SubscriberFlowOfferFlowOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    show_banner: boolean,
    image_url?: string | null,
    goal: offer_goal_enum,
    style: offer_style_enum,
    created_at: string,
    updated_at: string,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_coupon?: {
      __typename: 'offer_coupon',
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        text_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string,
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          name: string,
          image_url?: string | null,
          platform_plan_ecommerce_products: Array<{
            __typename: 'platform_plan_ecommerce_product',
            discount_amount?: string | null,
            discount_type?: string | null
          }>
        } | null,
        offer_product_swap_platform_ecommerce_product_platform_variants: Array<{
          __typename: 'offer_product_swap_platform_ecommerce_product_platform_variant',
          platform_variant?: {
            __typename: 'platform_variant',
            platform_id: string,
            name: string,
            price?: string | null,
            platform_variant_options: Array<{
              __typename: 'platform_variant_option',
              key: string,
              value: string
            }>
          } | null
        }>
      }>
    } | null,
    offer_reschedule_order?: {
      __typename: 'offer_reschedule_order',
      skip: boolean,
      reschedule: boolean,
      skip_button_text_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null,
      reschedule_button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_autopilot?: {
      __typename: 'offer_autopilot',
      offer_id: number
    } | null
  } | null,
  offer_variant_by_pk?: {
    __typename: 'offer_variant',
    id: number,
    type: offer_type_enum,
    offer_id: number,
    approved_at?: string | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_variant_coupon?: {
      __typename: 'offer_variant_coupon',
      offer_variant_id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null
    } | null
  } | null
};

export type SubscriberFlowOfferOfferMutationVariables = Exact<{
  object: subscriber_flow_offer_insert_input;
}>;


export type SubscriberFlowOfferOfferMutation = {
  insert_subscriber_flow_offer_one?: {
    __typename: 'subscriber_flow_offer',
    subscriber_flow_id: number
  } | null
};

export type SubscriberFlowOfferOfferAutopilotRoundMutationVariables = Exact<{
  offerAutopilotRoundId: Scalars['Int']['input'];
}>;


export type SubscriberFlowOfferOfferAutopilotRoundMutation = {
  update_offer_autopilot_round?: {
    __typename: 'offer_autopilot_round_mutation_response',
    affected_rows: number
  } | null
};

export type SubscriberFlowSegmentMatchesSubscriberFlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type SubscriberFlowSegmentMatchesSubscriberFlowQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    subscription_id: number,
    flow: {
      __typename: 'flow',
      flow_steps: Array<{
        __typename: 'flow_step',
        published_version?: {
          __typename: 'flow_step_version',
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            published_version?: {
              __typename: 'form_version',
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question?: {
                  __typename: 'question',
                  published_version?: {
                    __typename: 'question_version',
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question?: {
                  __typename: 'question',
                  published_version?: {
                    __typename: 'question_version',
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            published_version?: {
              __typename: 'offer_rule_group_version',
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  published_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  published_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            published_version?: {
              __typename: 'offer_rule_group_version',
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  published_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  published_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null
      }>
    }
  }>,
  flow: Array<{
    __typename: 'flow',
    flow_steps: Array<{
      __typename: 'flow_step',
      published_version?: {
        __typename: 'flow_step_version',
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'flow_step_version',
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>
      } | null,
      flow_step_form?: {
        __typename: 'flow_step_form',
        form: {
          __typename: 'form',
          published_version?: {
            __typename: 'form_version',
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question?: {
                __typename: 'question',
                published_version?: {
                  __typename: 'question_version',
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'form_version',
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question?: {
                __typename: 'question',
                published_version?: {
                  __typename: 'question_version',
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          published_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null,
      flow_step_deflection_rule_group?: {
        __typename: 'flow_step_deflection_rule_group',
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          published_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                published_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null
    }>
  }>
};

export type SwitchToTestModeQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type SwitchToTestModeQuery = {
  testModeAccount: Array<{
    __typename: 'account',
    id: number,
    test_mode_for_account?: {
      __typename: 'account',
      id: number,
      account_users: Array<{
        __typename: 'account_user',
        user_id: string
      }>
    } | null
  }>,
  actualAccount?: {
    __typename: 'account',
    id: number,
    test_mode_for_account_id?: number | null,
    account_users: Array<{
      __typename: 'account_user',
      user_id: string
    }>
  } | null
};

export type TransparentLoginQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type TransparentLoginQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    owner?: {
      __typename: 'user',
      id: string
    } | null
  } | null
};

export type UpdateChargebeeApiKeyMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['String']['input'];
}>;


export type UpdateChargebeeApiKeyMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type UpdateMaxioApiKeyMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['String']['input'];
}>;


export type UpdateMaxioApiKeyMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type UpdateOpenPayApiTokenMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['String']['input'];
}>;


export type UpdateOpenPayApiTokenMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type UpdateRecurlyApiKeyMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['String']['input'];
}>;


export type UpdateRecurlyApiKeyMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ServerUpgradeAccountMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  stripeSubscriptionId: Scalars['String']['input'];
}>;


export type ServerUpgradeAccountMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number
  } | null
};

export type UserInvitationByTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type UserInvitationByTokenQuery = {
  user_invitation: Array<{
    __typename: 'user_invitation',
    expires_at: string,
    account: {
      __typename: 'account',
      id: number,
      title: string
    },
    user: {
      __typename: 'user',
      name?: string | null,
      email?: string | null
    }
  }>
};

export type ServerViewerQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ServerViewerQuery = {
  user_by_pk?: {
    __typename: 'user',
    id: string,
    given_name?: string | null,
    family_name?: string | null,
    name?: string | null,
    email?: string | null,
    user_profiles: Array<{
      __typename: 'user_profile',
      first_name: string,
      last_name: string,
      email: string
    }>
  } | null
};

export type GetEventQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetEventQuery = {
  event: Array<{
    __typename: 'event',
    id: number,
    token: string,
    type: event_type_enum,
    payload: any,
    created_at: string
  }>
};

export type GetEventIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetEventIdByTokenQuery = {
  event: Array<{
    __typename: 'event',
    id: number
  }>
};

export type ListEventsQueryVariables = Exact<{
  where: event_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListEventsQuery = {
  event: Array<{
    __typename: 'event',
    id: number,
    token: string,
    type: event_type_enum,
    payload: any,
    created_at: string
  }>
};

export type GetFlowSessionQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetFlowSessionQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  }>
};

export type GetFlowSessionIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetFlowSessionIdByTokenQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number
  }>
};

export type ListFlowSessionsQueryVariables = Exact<{
  where: subscriber_flow_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListFlowSessionsQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  }>
};

export type GetFlowQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetFlowQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    created_at: string,
    updated_at: string
  }>
};

export type GetFlowIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetFlowIdByTokenQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number
  }>
};

export type ListFlowsQueryVariables = Exact<{
  where: flow_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListFlowsQuery = {
  flow: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    created_at: string,
    updated_at: string
  }>
};

export type GetOfferQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetOfferQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  }>
};

export type GetOfferIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetOfferIdByTokenQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number
  }>
};

export type ListOffersQueryVariables = Exact<{
  where: offer_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListOffersQuery = {
  offer: Array<{
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  }>
};

export type CreatePropertyExistingQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
}>;


export type CreatePropertyExistingQuery = {
  property: Array<{
    __typename: 'property',
    id: number
  }>
};

export type CreatePropertyMutationVariables = Exact<{
  object: property_insert_input;
}>;


export type CreatePropertyMutation = {
  insert_property_one?: {
    __typename: 'property',
    id: number,
    key: string,
    token: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    created_at: string
  } | null
};

export type DeletePropertyMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type DeletePropertyMutation = {
  update_property?: {
    __typename: 'property_mutation_response',
    affected_rows: number
  } | null
};

export type GetPropertyQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetPropertyQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    token: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    created_at: string
  }>
};

export type GetPropertyIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetPropertyIdByTokenQuery = {
  property: Array<{
    __typename: 'property',
    id: number
  }>
};

export type ListPropertiesQueryVariables = Exact<{
  where: property_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    token: string,
    entity: property_entity_enum,
    type: property_type_enum,
    format?: property_format_enum | null,
    name: string,
    created_at: string
  }>
};

export type SegmentV1SetSubscriberSegmentUserIdMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  segmentUserId: Scalars['String']['input'];
}>;


export type SegmentV1SetSubscriberSegmentUserIdMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SegmentV1IntegrationQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SegmentV1IntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    options: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  }>
};

export type SoftDeleteSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type SoftDeleteSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type ApiSubscriberQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type ApiSubscriberQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    created_at: string,
    updated_at: string,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>
  }>
};

export type DeleteSubscriberPropertyMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  propertyId: Scalars['Int']['input'];
}>;


export type DeleteSubscriberPropertyMutation = {
  delete_subscriber_property_by_pk?: {
    __typename: 'subscriber_property',
    subscriber_id: number
  } | null
};

export type GetSubscriberIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSubscriberIdByTokenQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number
  }>
};

export type ListSubscribersQueryVariables = Exact<{
  where: subscriber_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListSubscribersQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    created_at: string,
    updated_at: string,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>
  }>
};

export type GetSubscriptionQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetSubscriptionQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    subscriber_id: number,
    mrr: string,
    status: subscription_status_enum,
    created_at: string,
    updated_at: string,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>,
    subscriber: {
      __typename: 'subscriber',
      token: string
    }
  }>
};

export type DeleteSubscriptionPropertyMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  propertyId: Scalars['Int']['input'];
}>;


export type DeleteSubscriptionPropertyMutation = {
  delete_subscription_property_by_pk?: {
    __typename: 'subscription_property',
    subscription_id: number
  } | null
};

export type GetSubscriptionIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetSubscriptionIdByTokenQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number
  }>
};

export type ListSubscriptionsQueryVariables = Exact<{
  where: subscription_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListSubscriptionsQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    subscriber_id: number,
    mrr: string,
    status: subscription_status_enum,
    created_at: string,
    updated_at: string,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>,
    subscriber: {
      __typename: 'subscriber',
      token: string
    }
  }>
};

export type CreateWebhookExistingQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CreateWebhookExistingQuery = {
  webhook_aggregate: {
    __typename: 'webhook_aggregate',
    aggregate?: {
      __typename: 'webhook_aggregate_fields',
      count: number
    } | null
  }
};

export type CreateWebhookMutationVariables = Exact<{
  object: webhook_insert_input;
}>;


export type CreateWebhookMutation = {
  insert_webhook_one?: {
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  } | null
};

export type DeleteWebhookMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type DeleteWebhookMutation = {
  delete_webhook?: {
    __typename: 'webhook_mutation_response',
    affected_rows: number
  } | null
};

export type GetWebhookQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetWebhookQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  }>
};

export type GetWebhookIdByTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;


export type GetWebhookIdByTokenQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number
  }>
};

export type ListWebhooksQueryVariables = Exact<{
  where: webhook_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type ListWebhooksQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string,
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  }>
};

export type DiscoverSubscriberCampaignOfferSubscriberCampaignQueryVariables = Exact<{
  subscriberCampaignToken: Scalars['String']['input'];
}>;


export type DiscoverSubscriberCampaignOfferSubscriberCampaignQuery = {
  subscriber_campaign: Array<{
    __typename: 'subscriber_campaign',
    id: number,
    status: subscriber_campaign_status_enum,
    campaign: {
      __typename: 'campaign',
      token: string,
      published_version?: {
        __typename: 'campaign_version',
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  offer_ids: any,
                  offer_test_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      offer_ids: any,
                      offer_test_ids: any,
                      offer_group_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_offers: Array<{
                        __typename: 'offer_rule_rule_offer',
                        offer_rule_rule_id?: number | null,
                        offer_id?: number | null,
                        offer?: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        } | null
                      }>,
                      offer_rule_rule_offer_tests: Array<{
                        __typename: 'offer_rule_rule_offer_test',
                        offer_rule_rule_id?: number | null,
                        offer_test_id?: number | null,
                        offer_test?: {
                          __typename: 'offer_test',
                          id: number,
                          name: string,
                          token: string,
                          control_offer: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          },
                          offer_test_offers: Array<{
                            __typename: 'offer_test_offer',
                            offer_test_id: number,
                            offer_id: number,
                            offer: {
                              __typename: 'offer',
                              id: number,
                              token: string,
                              name: string,
                              type: offer_type_enum
                            }
                          }>,
                          winning_offer?: {
                            __typename: 'offer',
                            id: number,
                            token: string,
                            name: string,
                            type: offer_type_enum
                          } | null
                        } | null
                      }>
                    } | null
                  }>,
                  offer_rule_version_offers: Array<{
                    __typename: 'offer_rule_version_offer',
                    offer_rule_version_id?: number | null,
                    offer_id?: number | null,
                    offer?: {
                      __typename: 'offer',
                      id: number,
                      token: string,
                      name: string,
                      type: offer_type_enum
                    } | null
                  }>,
                  offer_rule_version_offer_tests: Array<{
                    __typename: 'offer_rule_version_offer_test',
                    offer_rule_version_id?: number | null,
                    offer_test_id?: number | null,
                    offer_test?: {
                      __typename: 'offer_test',
                      id: number,
                      name: string,
                      token: string,
                      control_offer: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      },
                      offer_test_offers: Array<{
                        __typename: 'offer_test_offer',
                        offer_test_id: number,
                        offer_id: number,
                        offer: {
                          __typename: 'offer',
                          id: number,
                          token: string,
                          name: string,
                          type: offer_type_enum
                        }
                      }>,
                      winning_offer?: {
                        __typename: 'offer',
                        id: number,
                        token: string,
                        name: string,
                        type: offer_type_enum
                      } | null
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        }
      } | null
    },
    offer?: {
      __typename: 'offer',
      id: number,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null
    } | null,
    subscription: {
      __typename: 'subscription',
      id: number,
      platform_id: string,
      status: subscription_status_enum,
      mrr: string,
      subscriber: {
        __typename: 'subscriber',
        status?: subscriber_status_enum | null,
        inactive_mrr: string,
        active_mrr: string,
        active_offer_id?: number | null,
        platform_customer?: {
          __typename: 'platform_customer',
          platform_coupon_id?: string | null,
          past_due?: boolean | null,
          platform_subscriptions: Array<{
            __typename: 'platform_subscription',
            status: platform_subscription_status_enum,
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              platform_plan_id: string
            }>
          }>
        } | null
      },
      account: {
        __typename: 'account',
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      },
      platform_subscription?: {
        __typename: 'platform_subscription',
        status: platform_subscription_status_enum,
        platform_coupon_id?: string | null,
        past_due?: boolean | null,
        can_pause: boolean,
        can_cancel: boolean,
        paper_code?: string | null,
        cancel_at_period_end?: boolean | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string,
          platform_plan?: {
            __typename: 'platform_plan',
            interval: platform_plan_interval_enum,
            interval_count: number,
            platform_product_id: string,
            platform_product?: {
              __typename: 'platform_product',
              platform_product_group_id?: string | null
            } | null
          } | null,
          swappable_ecommerce_products: Array<{
            __typename: 'platform_subscription_plan_swappable_ecommerce_product',
            platform_ecommerce_product?: {
              __typename: 'platform_ecommerce_product',
              platform_id: string,
              active: boolean
            } | null
          }>
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          platform_addon_id: string
        }>
      } | null
    },
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      created_at: string,
      updated_at: string,
      first_view: {
        __typename: 'subscriber_campaign_view_aggregate',
        aggregate?: {
          __typename: 'subscriber_campaign_view_aggregate_fields',
          min?: {
            __typename: 'subscriber_campaign_view_min_fields',
            created_at?: string | null
          } | null
        } | null
      },
      subscriber_campaign: {
        __typename: 'subscriber_campaign',
        id: number,
        token: string,
        status: subscriber_campaign_status_enum,
        created_at: string,
        updated_at: string,
        triggered_at?: string | null,
        completed_at?: string | null,
        campaign: {
          __typename: 'campaign',
          id: number,
          token: string,
          title: string,
          created_at: string,
          updated_at: string,
          published_version?: {
            __typename: 'campaign_version',
            offer_rule_group_id: number,
            trigger_type: campaign_trigger_type_enum,
            trigger_url?: string | null,
            delay?: string | null
          } | null
        },
        subscriber: {
          __typename: 'subscriber',
          id: number,
          platform_id: string,
          token: string,
          name: string,
          email: string,
          status?: subscriber_status_enum | null,
          created_at: string,
          updated_at: string,
          subscriber_properties: Array<{
            __typename: 'subscriber_property',
            value: any,
            property: {
              __typename: 'property',
              id: number,
              key: string,
              token: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null,
              name: string,
              created_at: string
            }
          }>
        },
        subscription: {
          __typename: 'subscription',
          id: number,
          token: string,
          platform_id: string,
          subscriber_id: number,
          mrr: string,
          status: subscription_status_enum,
          created_at: string,
          updated_at: string,
          subscription_properties: Array<{
            __typename: 'subscription_property',
            value: any,
            property: {
              __typename: 'property',
              id: number,
              key: string,
              token: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null,
              name: string,
              created_at: string
            }
          }>,
          subscriber: {
            __typename: 'subscriber',
            token: string
          }
        },
        subscriber_campaign_offers: Array<{
          __typename: 'subscriber_campaign_offer',
          offer: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          }
        }>,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          goal: offer_goal_enum,
          name: string,
          metadata: any,
          created_at: string,
          updated_at: string,
          offer_timer?: {
            __typename: 'offer_timer',
            offer_timer_mode: offer_timer_mode_enum,
            start_time?: string | null,
            end_time?: string | null,
            duration?: string | null,
            show_timer?: boolean | null
          } | null,
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            apply_to: offer_coupon_apply_to_enum
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null,
              custom_plan_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              metadata: any
            }>
          } | null
        } | null
      },
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      }
    }>
  }>
};

export type DiscoverSubscriberCampaignOfferOfferQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;


export type DiscoverSubscriberCampaignOfferOfferQuery = {
  offer_by_pk?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    name: string,
    show_banner: boolean,
    override_active_offer: boolean,
    style: offer_style_enum,
    goal: offer_goal_enum,
    created_at: string,
    updated_at: string,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    headline_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    content_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    button_text_translation: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    },
    offer_coupon?: {
      __typename: 'offer_coupon',
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      override_active_coupon: boolean,
      platform_coupon?: {
        __typename: 'platform_coupon',
        platform_id: string,
        apply_to: platform_coupon_apply_to_enum,
        apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
        apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
        valid: boolean,
        platform_coupon_product_groups: Array<{
          __typename: 'platform_coupon_product_group',
          platform_product_group_id: string
        }>,
        platform_coupon_products: Array<{
          __typename: 'platform_coupon_product',
          platform_product_id: string
        }>,
        platform_coupon_plans: Array<{
          __typename: 'platform_coupon_plan',
          platform_plan_id: string
        }>,
        platform_coupon_addons: Array<{
          __typename: 'platform_coupon_addon',
          platform_addon_id: string
        }>
      } | null
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        show_banner: boolean,
        override_active_offer: boolean,
        style: offer_style_enum,
        goal: offer_goal_enum,
        created_at: string,
        updated_at: string,
        headline_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        content_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        button_text_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        },
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          override_active_coupon: boolean,
          platform_coupon?: {
            __typename: 'platform_coupon',
            platform_id: string,
            apply_to: platform_coupon_apply_to_enum,
            apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
            apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
            valid: boolean,
            platform_coupon_product_groups: Array<{
              __typename: 'platform_coupon_product_group',
              platform_product_group_id: string
            }>,
            platform_coupon_products: Array<{
              __typename: 'platform_coupon_product',
              platform_product_id: string
            }>,
            platform_coupon_plans: Array<{
              __typename: 'platform_coupon_plan',
              platform_plan_id: string
            }>,
            platform_coupon_addons: Array<{
              __typename: 'platform_coupon_addon',
              platform_addon_id: string
            }>
          } | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      modify_at: offer_modify_subscription_modify_at_enum,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_connection_id: number,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        platform_addon?: {
          __typename: 'platform_addon',
          platform_id: string,
          platform_addon_plans: Array<{
            __typename: 'platform_addon_plan',
            platform_plan_id: string
          }>
        } | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_id: string,
          interval: platform_plan_interval_enum,
          interval_count: number
        } | null
      }>
    } | null,
    offer_custom?: {
      __typename: 'offer_custom',
      link_href?: string | null,
      link_text?: string | null
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      }>
    } | null,
    offer_upgrade?: {
      __typename: 'offer_upgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_downgrade?: {
      __typename: 'offer_downgrade',
      platform_plan_id?: string | null,
      change_at: offer_change_plan_change_at_enum
    } | null,
    offer_product_swap?: {
      __typename: 'offer_product_swap',
      specific_products: boolean,
      offer_product_swap_platform_ecommerce_products: Array<{
        __typename: 'offer_product_swap_platform_ecommerce_product',
        platform_ecommerce_product_id: string
      }>
    } | null
  } | null
};

export type DiscoverSubscriberCampaignOfferLogMutationVariables = Exact<{
  subscriberCampaignId: Scalars['Int']['input'];
  offerRuleId: Scalars['Int']['input'];
  offerRuleVersionId: Scalars['Int']['input'];
  segments: Array<subscriber_campaign_segment_insert_input> | subscriber_campaign_segment_insert_input;
  overrides: Array<subscriber_campaign_override_insert_input> | subscriber_campaign_override_insert_input;
}>;


export type DiscoverSubscriberCampaignOfferLogMutation = {
  insert_subscriber_campaign_offer_rule_one?: {
    __typename: 'subscriber_campaign_offer_rule',
    subscriber_campaign_id: number
  } | null,
  insert_subscriber_campaign_segment?: {
    __typename: 'subscriber_campaign_segment_mutation_response',
    affected_rows: number
  } | null,
  insert_subscriber_campaign_override?: {
    __typename: 'subscriber_campaign_override_mutation_response',
    affected_rows: number
  } | null
};

export type DiscoverSubscriberCampaignOfferOfferMutationVariables = Exact<{
  subscriberCampaignId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerTestId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DiscoverSubscriberCampaignOfferOfferMutation = {
  insert_subscriber_campaign_offer_one?: {
    __typename: 'subscriber_campaign_offer',
    created_at: string,
    updated_at: string,
    subscriber_campaign: {
      __typename: 'subscriber_campaign',
      id: number,
      token: string,
      status: subscriber_campaign_status_enum,
      created_at: string,
      updated_at: string,
      triggered_at?: string | null,
      completed_at?: string | null,
      campaign: {
        __typename: 'campaign',
        id: number,
        token: string,
        title: string,
        created_at: string,
        updated_at: string,
        published_version?: {
          __typename: 'campaign_version',
          offer_rule_group_id: number,
          trigger_type: campaign_trigger_type_enum,
          trigger_url?: string | null,
          delay?: string | null
        } | null
      },
      subscriber: {
        __typename: 'subscriber',
        id: number,
        platform_id: string,
        token: string,
        name: string,
        email: string,
        status?: subscriber_status_enum | null,
        created_at: string,
        updated_at: string,
        subscriber_properties: Array<{
          __typename: 'subscriber_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>
      },
      subscription: {
        __typename: 'subscription',
        id: number,
        token: string,
        platform_id: string,
        subscriber_id: number,
        mrr: string,
        status: subscription_status_enum,
        created_at: string,
        updated_at: string,
        subscription_properties: Array<{
          __typename: 'subscription_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>,
        subscriber: {
          __typename: 'subscriber',
          token: string
        }
      },
      subscriber_campaign_offers: Array<{
        __typename: 'subscriber_campaign_offer',
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          goal: offer_goal_enum,
          name: string,
          metadata: any,
          created_at: string,
          updated_at: string,
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            apply_to: offer_coupon_apply_to_enum
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null,
              custom_plan_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              metadata: any
            }>
          } | null
        }
      }>,
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_timer?: {
          __typename: 'offer_timer',
          offer_timer_mode: offer_timer_mode_enum,
          start_time?: string | null,
          end_time?: string | null,
          duration?: string | null,
          show_timer?: boolean | null
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      } | null
    },
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    },
    first_view: {
      __typename: 'subscriber_campaign_view_aggregate',
      aggregate?: {
        __typename: 'subscriber_campaign_view_aggregate_fields',
        min?: {
          __typename: 'subscriber_campaign_view_min_fields',
          created_at?: string | null
        } | null
      } | null
    }
  } | null,
  update_subscriber_campaign_by_pk?: {
    __typename: 'subscriber_campaign',
    id: number
  } | null
};

export type CacheSegmentSubscriptionSegmentQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CacheSegmentSubscriptionSegmentQuery = {
  segment: Array<{
    __typename: 'segment',
    id: number
  }>
};

export type CacheSegmentSubscriptionSegmentMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  segmentId: Scalars['Int']['input'];
}>;


export type CacheSegmentSubscriptionSegmentMutation = {
  populate_segment_subscription_cache?: {
    __typename: 'function_log',
    log: any
  } | null
};

export type CheckIfUserShouldBeLinkedQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type CheckIfUserShouldBeLinkedQuery = {
  user_by_pk?: {
    __typename: 'user',
    id: string,
    account_users: Array<{
      __typename: 'account_user',
      account_id: number
    }>
  } | null
};

export type CreateFlowCreateFlowMutationVariables = Exact<{
  object: flow_version_insert_input;
}>;


export type CreateFlowCreateFlowMutation = {
  insert_flow_version_one?: {
    __typename: 'flow_version',
    id: number,
    flow_id: number
  } | null
};

export type CreateFlowSetPublishedFlowIdMutationVariables = Exact<{
  flowId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowSetPublishedFlowIdMutation = {
  update_flow_by_pk?: {
    __typename: 'flow',
    id: number
  } | null
};

export type CreateFlowCreateQuestionMutationVariables = Exact<{
  object: question_insert_input;
}>;


export type CreateFlowCreateQuestionMutation = {
  insert_question_one?: {
    __typename: 'question',
    id: number,
    question_versions: Array<{
      __typename: 'question_version',
      id: number
    }>
  } | null
};

export type CreateFlowCreateQuestionOptionMutationVariables = Exact<{
  object: question_option_insert_input;
}>;


export type CreateFlowCreateQuestionOptionMutation = {
  insert_question_option_one?: {
    __typename: 'question_option',
    id: number,
    question_option_versions: Array<{
      __typename: 'question_option_version',
      id: number
    }>
  } | null
};

export type CreateFlowSetQuestionOptionPublishedVersionMutationVariables = Exact<{
  optionId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowSetQuestionOptionPublishedVersionMutation = {
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number
  } | null
};

export type CreateFlowSetQuestionVersionOptionIdsMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  optionIds?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type CreateFlowSetQuestionVersionOptionIdsMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number
  } | null
};

export type CreateFlowSetQuestionPublishedVersionMutationVariables = Exact<{
  questionId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowSetQuestionPublishedVersionMutation = {
  update_question_by_pk?: {
    __typename: 'question',
    id: number
  } | null
};

export type CreateFlowCreateFormMutationVariables = Exact<{
  object: form_insert_input;
}>;


export type CreateFlowCreateFormMutation = {
  insert_form_one?: {
    __typename: 'form',
    id: number,
    form_versions: Array<{
      __typename: 'form_version',
      id: number
    }>
  } | null
};

export type CreateFlowSetFormPublishedVersionMutationVariables = Exact<{
  formId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowSetFormPublishedVersionMutation = {
  update_form_by_pk?: {
    __typename: 'form',
    id: number
  } | null
};

export type CreateFlowCreateOfferRuleGroupMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
}>;


export type CreateFlowCreateOfferRuleGroupMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number
  } | null
};

export type CreateFlowCreateOfferRuleGroupSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowCreateOfferRuleGroupSetVersionMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null
};

export type CreateFlowCreateAcknowledgementGroupMutationVariables = Exact<{
  object: acknowledgement_group_version_insert_input;
}>;


export type CreateFlowCreateAcknowledgementGroupMutation = {
  insert_acknowledgement_group_version_one?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group_id: number
  } | null
};

export type CreateFlowCreateAcknowledgementGroupSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowCreateAcknowledgementGroupSetVersionMutation = {
  update_acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number
  } | null
};

export type CreateFlowCreateFlowStepMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type CreateFlowCreateFlowStepMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_id: number
  } | null
};

export type CreateFlowSetFlowStepVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type CreateFlowSetFlowStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type CreateFlowCreateFlowStepsSetStepIdsMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  stepIds: Scalars['jsonb']['input'];
}>;


export type CreateFlowCreateFlowStepsSetStepIdsMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    id: number
  } | null
};

export type DailyExportQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  previousId: Scalars['Int']['input'];
}>;


export type DailyExportQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum,
    completed_at?: string | null,
    subscriber: {
      __typename: 'subscriber',
      name: string,
      email: string,
      platform_id: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          key: string,
          name: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      platform_id: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          key: string,
          name: string
        }
      }>
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>
  }>,
  question: Array<{
    __typename: 'question',
    id: number,
    type: question_type_enum,
    published_version?: {
      __typename: 'question_version',
      id: number,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    } | null
  }>
};

export type AccountFragment = {
  __typename: 'account',
  id: number,
  title: string,
  mode: account_mode_enum,
  status: account_status_enum,
  stripe_customer_id: string,
  stripe_subscription_id: string,
  created_at: string,
  updated_at: string,
  prosperstack: boolean,
  test_mode_for_account_id?: number | null,
  enterprise: boolean,
  sso_required: boolean,
  platform_connection?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type ApiCampaignFragment = {
  __typename: 'campaign',
  id: number,
  token: string,
  title: string,
  created_at: string,
  updated_at: string,
  published_version?: {
    __typename: 'campaign_version',
    offer_rule_group_id: number,
    trigger_type: campaign_trigger_type_enum,
    trigger_url?: string | null,
    delay?: string | null
  } | null
};

export type ApiDeflectionFragment = {
  __typename: 'deflection',
  id: number,
  token: string,
  title: string,
  deflection_flow_actions: Array<{
    __typename: 'deflection_flow_action',
    flow_action?: {
      __typename: 'flow_action',
      id: number,
      type: flow_action_type_enum,
      token: string,
      code?: string | null,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow?: {
        __typename: 'flow',
        token: string
      } | null
    } | null
  }>
};

export type ApiEventFragment = {
  __typename: 'event',
  id: number,
  token: string,
  type: event_type_enum,
  payload: any,
  created_at: string
};

export type ApiFlowActionFragment = {
  __typename: 'flow_action',
  id: number,
  type: flow_action_type_enum,
  token: string,
  code?: string | null,
  appearance: flow_action_appearance_enum,
  url?: string | null,
  reroute_to_flow?: {
    __typename: 'flow',
    token: string
  } | null
};

export type ApiFlowFragment = {
  __typename: 'flow',
  id: number,
  token: string,
  title: string,
  created_at: string,
  updated_at: string
};

export type ApiFlowStepFragment = {
  __typename: 'flow_step',
  id: number,
  token: string,
  type: flow_step_type_enum,
  flow_step_form?: {
    __typename: 'flow_step_form',
    form: {
      __typename: 'form',
      title: string
    }
  } | null,
  flow_step_question?: {
    __typename: 'flow_step_question',
    question: {
      __typename: 'question',
      published_version?: {
        __typename: 'question_version',
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      } | null
    }
  } | null,
  flow_step_deflection_rule_group?: {
    __typename: 'flow_step_deflection_rule_group',
    flow_step_id?: number | null,
    offer_rule_group_id?: number | null,
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      title: string
    } | null
  } | null,
  flow_step_acknowledgement_group?: {
    __typename: 'flow_step_acknowledgement_group',
    acknowledgement_group: {
      __typename: 'acknowledgement_group',
      published_version?: {
        __typename: 'acknowledgement_group_version',
        title_translation: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        }
      } | null
    }
  } | null,
  flow_step_offer_rule_group?: {
    __typename: 'flow_step_offer_rule_group',
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      title: string
    } | null
  } | null,
  flow_step_intervention?: {
    __typename: 'flow_step_intervention',
    intervention: {
      __typename: 'intervention',
      title: string
    }
  } | null
};

export type ApiOfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  goal: offer_goal_enum,
  name: string,
  metadata: any,
  created_at: string,
  updated_at: string,
  offer_coupon?: {
    __typename: 'offer_coupon',
    platform_id?: string | null,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    months?: number | null,
    apply_to: offer_coupon_apply_to_enum
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    offer_coupon_offer?: {
      __typename: 'offer',
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null
      } | null
    } | null
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null,
      custom_plan_id?: string | null
    }>
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    pause_at: offer_pause_subscription_pause_at_enum,
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      id: number,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null,
      metadata: any
    }>
  } | null
};

export type ApiOfferGroupFragment = {
  __typename: 'offer_group',
  id: number,
  token: string,
  goal: offer_goal_enum,
  name: string,
  created_at: string,
  updated_at: string,
  offer_group_offers: Array<{
    __typename: 'offer_group_offer',
    position?: number | null,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null
  }>
};

export type ApiPropertyFragment = {
  __typename: 'property',
  id: number,
  key: string,
  token: string,
  entity: property_entity_enum,
  type: property_type_enum,
  format?: property_format_enum | null,
  name: string,
  created_at: string
};

export type ApiQuestionAnswerFragment = {
  __typename: 'question_answer',
  question: {
    __typename: 'question',
    id: number,
    token: string,
    type: question_type_enum,
    primary: boolean,
    question_settings: Array<{
      __typename: 'question_setting',
      key: question_setting_key_enum,
      published_version?: {
        __typename: 'question_setting_version',
        value: any
      } | null
    }>
  },
  question_version: {
    __typename: 'question_version',
    id: number,
    title_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  },
  question_answer_text?: {
    __typename: 'question_answer_text',
    value: string,
    sentiment?: sentiment_enum | null
  } | null,
  question_answer_radios: Array<{
    __typename: 'question_answer_radio',
    question_option_id: number,
    question_option_version: {
      __typename: 'question_option_version',
      id: number,
      title_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      }
    },
    question_option: {
      __typename: 'question_option',
      id: number,
      token: string,
      reason_code?: string | null
    }
  }>,
  question_answer_likelihood_to_return?: {
    __typename: 'question_answer_likelihood_to_return',
    value: number
  } | null
};

export type ApiSubscriberCampaignFragment = {
  __typename: 'subscriber_campaign',
  id: number,
  token: string,
  status: subscriber_campaign_status_enum,
  created_at: string,
  updated_at: string,
  triggered_at?: string | null,
  completed_at?: string | null,
  campaign: {
    __typename: 'campaign',
    id: number,
    token: string,
    title: string,
    created_at: string,
    updated_at: string,
    published_version?: {
      __typename: 'campaign_version',
      offer_rule_group_id: number,
      trigger_type: campaign_trigger_type_enum,
      trigger_url?: string | null,
      delay?: string | null
    } | null
  },
  subscriber: {
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    created_at: string,
    updated_at: string,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>
  },
  subscription: {
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    subscriber_id: number,
    mrr: string,
    status: subscription_status_enum,
    created_at: string,
    updated_at: string,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>,
    subscriber: {
      __typename: 'subscriber',
      token: string
    }
  },
  subscriber_campaign_offers: Array<{
    __typename: 'subscriber_campaign_offer',
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    }
  }>,
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  } | null
};

export type ApiSubscriberCampaignOfferFragment = {
  __typename: 'subscriber_campaign_offer',
  created_at: string,
  updated_at: string,
  subscriber_campaign: {
    __typename: 'subscriber_campaign',
    id: number,
    token: string,
    status: subscriber_campaign_status_enum,
    created_at: string,
    updated_at: string,
    triggered_at?: string | null,
    completed_at?: string | null,
    campaign: {
      __typename: 'campaign',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string,
      published_version?: {
        __typename: 'campaign_version',
        offer_rule_group_id: number,
        trigger_type: campaign_trigger_type_enum,
        trigger_url?: string | null,
        delay?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      }
    }>,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null
  },
  offer: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_timer?: {
      __typename: 'offer_timer',
      offer_timer_mode: offer_timer_mode_enum,
      start_time?: string | null,
      end_time?: string | null,
      duration?: string | null,
      show_timer?: boolean | null
    } | null,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  },
  first_view: {
    __typename: 'subscriber_campaign_view_aggregate',
    aggregate?: {
      __typename: 'subscriber_campaign_view_aggregate_fields',
      min?: {
        __typename: 'subscriber_campaign_view_min_fields',
        created_at?: string | null
      } | null
    } | null
  }
};

export type ApiSubscriberCampaignViewFragment = {
  __typename: 'subscriber_campaign_view',
  id: number,
  token: string,
  status: subscriber_campaign_view_status_enum,
  created_at: string,
  updated_at: string,
  completed_at?: string | null,
  subscriber_campaign: {
    __typename: 'subscriber_campaign',
    id: number,
    token: string,
    status: subscriber_campaign_status_enum,
    created_at: string,
    updated_at: string,
    triggered_at?: string | null,
    completed_at?: string | null,
    campaign: {
      __typename: 'campaign',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string,
      published_version?: {
        __typename: 'campaign_version',
        offer_rule_group_id: number,
        trigger_type: campaign_trigger_type_enum,
        trigger_url?: string | null,
        delay?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    subscriber_campaign_offers: Array<{
      __typename: 'subscriber_campaign_offer',
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      }
    }>,
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_timer?: {
        __typename: 'offer_timer',
        offer_timer_mode: offer_timer_mode_enum,
        start_time?: string | null,
        end_time?: string | null,
        duration?: string | null,
        show_timer?: boolean | null
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null
  },
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  } | null
};

export type ApiSubscriberFlowFragment = {
  __typename: 'subscriber_flow',
  id: number,
  token: string,
  status: subscriber_flow_status_enum,
  automatically_deflected: boolean,
  url?: string | null,
  user_agent?: string | null,
  started_at?: string | null,
  completed_at?: string | null,
  created_at: string,
  updated_at: string,
  flow: {
    __typename: 'flow',
    id: number,
    token: string,
    title: string,
    created_at: string,
    updated_at: string
  },
  subscriber: {
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    token: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    created_at: string,
    updated_at: string,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>
  },
  subscription: {
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    subscriber_id: number,
    mrr: string,
    status: subscription_status_enum,
    created_at: string,
    updated_at: string,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>,
    subscriber: {
      __typename: 'subscriber',
      token: string
    }
  },
  offer?: {
    __typename: 'offer',
    id: number,
    token: string,
    type: offer_type_enum,
    goal: offer_goal_enum,
    name: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    offer_coupon?: {
      __typename: 'offer_coupon',
      platform_id?: string | null,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      months?: number | null,
      apply_to: offer_coupon_apply_to_enum
    } | null,
    offer_change_plan?: {
      __typename: 'offer_change_plan',
      platform_plan_id?: string | null,
      prorate: boolean,
      offer_coupon_offer?: {
        __typename: 'offer',
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null
        } | null
      } | null
    } | null,
    offer_modify_subscription?: {
      __typename: 'offer_modify_subscription',
      prorate: boolean,
      offer_modify_subscription_options: Array<{
        __typename: 'offer_modify_subscription_option',
        type: offer_modify_subscription_option_type_enum,
        platform_plan_id?: string | null,
        platform_addon_id?: string | null,
        custom_plan_id?: string | null
      }>
    } | null,
    offer_trial_extension?: {
      __typename: 'offer_trial_extension',
      days: number
    } | null,
    offer_pause_subscription?: {
      __typename: 'offer_pause_subscription',
      pause_at: offer_pause_subscription_pause_at_enum,
      offer_pause_subscription_options: Array<{
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null,
        metadata: any
      }>
    } | null
  } | null,
  subscriber_flow_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        question_settings: Array<{
          __typename: 'question_setting',
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            value: any
          } | null
        }>
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_id: number,
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        },
        question_option: {
          __typename: 'question_option',
          id: number,
          token: string,
          reason_code?: string | null
        }
      }>,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  subscriber_flow_offers: Array<{
    __typename: 'subscriber_flow_offer',
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    },
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null
  }>,
  subscriber_flow_offer_groups: Array<{
    __typename: 'subscriber_flow_offer_group',
    status?: subscriber_flow_offer_status_enum | null,
    offer_group: {
      __typename: 'offer_group',
      id: number,
      token: string,
      goal: offer_goal_enum,
      name: string,
      created_at: string,
      updated_at: string,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          goal: offer_goal_enum,
          name: string,
          metadata: any,
          created_at: string,
          updated_at: string,
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            apply_to: offer_coupon_apply_to_enum
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null,
              custom_plan_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              metadata: any
            }>
          } | null
        } | null
      }>
    }
  }>,
  subscriber_flow_deflections: Array<{
    __typename: 'subscriber_flow_deflection',
    deflection: {
      __typename: 'deflection',
      id: number,
      token: string,
      title: string,
      deflection_flow_actions: Array<{
        __typename: 'deflection_flow_action',
        flow_action?: {
          __typename: 'flow_action',
          id: number,
          type: flow_action_type_enum,
          token: string,
          code?: string | null,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow?: {
            __typename: 'flow',
            token: string
          } | null
        } | null
      }>
    },
    clicked_flow_action?: {
      __typename: 'flow_action',
      id: number,
      type: flow_action_type_enum,
      token: string,
      code?: string | null,
      appearance: flow_action_appearance_enum,
      url?: string | null,
      reroute_to_flow?: {
        __typename: 'flow',
        token: string
      } | null
    } | null
  }>,
  subscriber_flow_flow_steps: Array<{
    __typename: 'subscriber_flow_flow_step',
    started_at: string,
    completed_at?: string | null,
    flow_step: {
      __typename: 'flow_step',
      id: number,
      token: string,
      type: flow_step_type_enum,
      flow_step_form?: {
        __typename: 'flow_step_form',
        form: {
          __typename: 'form',
          title: string
        }
      } | null,
      flow_step_question?: {
        __typename: 'flow_step_question',
        question: {
          __typename: 'question',
          published_version?: {
            __typename: 'question_version',
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          } | null
        }
      } | null,
      flow_step_deflection_rule_group?: {
        __typename: 'flow_step_deflection_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          title: string
        } | null
      } | null,
      flow_step_acknowledgement_group?: {
        __typename: 'flow_step_acknowledgement_group',
        acknowledgement_group: {
          __typename: 'acknowledgement_group',
          published_version?: {
            __typename: 'acknowledgement_group_version',
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null,
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          title: string
        } | null
      } | null,
      flow_step_intervention?: {
        __typename: 'flow_step_intervention',
        intervention: {
          __typename: 'intervention',
          title: string
        }
      } | null
    }
  }>,
  rerouted_from_subscriber_flow?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string
  } | null
};

export type ApiSubscriberFragment = {
  __typename: 'subscriber',
  id: number,
  platform_id: string,
  token: string,
  name: string,
  email: string,
  status?: subscriber_status_enum | null,
  created_at: string,
  updated_at: string,
  subscriber_properties: Array<{
    __typename: 'subscriber_property',
    value: any,
    property: {
      __typename: 'property',
      id: number,
      key: string,
      token: string,
      entity: property_entity_enum,
      type: property_type_enum,
      format?: property_format_enum | null,
      name: string,
      created_at: string
    }
  }>
};

export type ApiSubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  token: string,
  platform_id: string,
  subscriber_id: number,
  mrr: string,
  status: subscription_status_enum,
  created_at: string,
  updated_at: string,
  subscription_properties: Array<{
    __typename: 'subscription_property',
    value: any,
    property: {
      __typename: 'property',
      id: number,
      key: string,
      token: string,
      entity: property_entity_enum,
      type: property_type_enum,
      format?: property_format_enum | null,
      name: string,
      created_at: string
    }
  }>,
  subscriber: {
    __typename: 'subscriber',
    token: string
  }
};

export type ApiWebhookFragment = {
  __typename: 'webhook',
  id: number,
  token: string,
  url: string,
  disabled: boolean,
  created_at: string,
  webhook_events: Array<{
    __typename: 'webhook_event',
    event_type: event_type_enum
  }>
};

export type CampaignFragment = {
  __typename: 'campaign',
  id: number,
  account_id: number,
  default_language: language_enum,
  signature_required: boolean,
  published_version?: {
    __typename: 'campaign_version',
    id: number,
    trigger_type: campaign_trigger_type_enum,
    trigger_url?: string | null,
    delay?: string | null
  } | null,
  account: {
    __typename: 'account',
    id: number,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  }
};

export type EligibilityMessageFragment = {
  __typename: 'eligibility_message',
  id: number,
  name: string,
  eligibility_message_translation_id: number
};

export type IntegrationFragment = {
  __typename: 'integration',
  id: number,
  type: integration_type_enum,
  account_id: number,
  credentials: any,
  options: any,
  meta: any
};

export type OfferCouponFragment = {
  __typename: 'offer_coupon',
  id: number,
  offer_id: number,
  type: offer_coupon_type_enum,
  amount_off: string,
  duration: coupon_duration_enum,
  duration_interval?: coupon_duration_interval_enum | null,
  duration_count?: number | null,
  months?: number | null,
  platform_id?: string | null,
  platform_product_group_id?: string | null,
  offer: {
    __typename: 'offer',
    id: number,
    name: string
  }
};

export type OfferFragment = {
  __typename: 'offer',
  id: number,
  token: string,
  type: offer_type_enum,
  name: string,
  show_banner: boolean,
  override_active_offer: boolean,
  style: offer_style_enum,
  goal: offer_goal_enum,
  created_at: string,
  updated_at: string,
  offer_timer?: {
    __typename: 'offer_timer',
    offer_timer_mode: offer_timer_mode_enum,
    start_time?: string | null,
    end_time?: string | null,
    duration?: string | null,
    show_timer?: boolean | null
  } | null,
  headline_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  content_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  button_text_translation: {
    __typename: 'translation',
    id: number,
    translation_values: Array<{
      __typename: 'translation_value',
      translation_id: number,
      language: language_enum,
      value: any,
      format?: translation_value_format_enum | null
    }>
  },
  offer_coupon?: {
    __typename: 'offer_coupon',
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    months?: number | null,
    override_active_coupon: boolean,
    platform_coupon?: {
      __typename: 'platform_coupon',
      platform_id: string,
      apply_to: platform_coupon_apply_to_enum,
      apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
      apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
      apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
      apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
      valid: boolean,
      platform_coupon_product_groups: Array<{
        __typename: 'platform_coupon_product_group',
        platform_product_group_id: string
      }>,
      platform_coupon_products: Array<{
        __typename: 'platform_coupon_product',
        platform_product_id: string
      }>,
      platform_coupon_plans: Array<{
        __typename: 'platform_coupon_plan',
        platform_plan_id: string
      }>,
      platform_coupon_addons: Array<{
        __typename: 'platform_coupon_addon',
        platform_addon_id: string
      }>
    } | null
  } | null,
  offer_change_plan?: {
    __typename: 'offer_change_plan',
    platform_plan_id?: string | null,
    prorate: boolean,
    offer_coupon_offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      show_banner: boolean,
      override_active_offer: boolean,
      style: offer_style_enum,
      goal: offer_goal_enum,
      created_at: string,
      updated_at: string,
      headline_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      content_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      button_text_translation: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      },
      offer_coupon?: {
        __typename: 'offer_coupon',
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        override_active_coupon: boolean,
        platform_coupon?: {
          __typename: 'platform_coupon',
          platform_id: string,
          apply_to: platform_coupon_apply_to_enum,
          apply_to_product_groups?: platform_coupon_apply_to_restriction_enum | null,
          apply_to_products?: platform_coupon_apply_to_restriction_enum | null,
          apply_to_plans?: platform_coupon_apply_to_restriction_enum | null,
          apply_to_addons?: platform_coupon_apply_to_restriction_enum | null,
          valid: boolean,
          platform_coupon_product_groups: Array<{
            __typename: 'platform_coupon_product_group',
            platform_product_group_id: string
          }>,
          platform_coupon_products: Array<{
            __typename: 'platform_coupon_product',
            platform_product_id: string
          }>,
          platform_coupon_plans: Array<{
            __typename: 'platform_coupon_plan',
            platform_plan_id: string
          }>,
          platform_coupon_addons: Array<{
            __typename: 'platform_coupon_addon',
            platform_addon_id: string
          }>
        } | null
      } | null
    } | null
  } | null,
  offer_modify_subscription?: {
    __typename: 'offer_modify_subscription',
    prorate: boolean,
    modify_at: offer_modify_subscription_modify_at_enum,
    offer_modify_subscription_options: Array<{
      __typename: 'offer_modify_subscription_option',
      type: offer_modify_subscription_option_type_enum,
      platform_connection_id: number,
      platform_plan_id?: string | null,
      platform_addon_id?: string | null,
      platform_addon?: {
        __typename: 'platform_addon',
        platform_id: string,
        platform_addon_plans: Array<{
          __typename: 'platform_addon_plan',
          platform_plan_id: string
        }>
      } | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_id: string,
        interval: platform_plan_interval_enum,
        interval_count: number
      } | null
    }>
  } | null,
  offer_custom?: {
    __typename: 'offer_custom',
    link_href?: string | null,
    link_text?: string | null
  } | null,
  offer_trial_extension?: {
    __typename: 'offer_trial_extension',
    days: number
  } | null,
  offer_pause_subscription?: {
    __typename: 'offer_pause_subscription',
    offer_pause_subscription_options: Array<{
      __typename: 'offer_pause_subscription_option',
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    }>
  } | null,
  offer_upgrade?: {
    __typename: 'offer_upgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_downgrade?: {
    __typename: 'offer_downgrade',
    platform_plan_id?: string | null,
    change_at: offer_change_plan_change_at_enum
  } | null,
  offer_product_swap?: {
    __typename: 'offer_product_swap',
    specific_products: boolean,
    offer_product_swap_platform_ecommerce_products: Array<{
      __typename: 'offer_product_swap_platform_ecommerce_product',
      platform_ecommerce_product_id: string
    }>
  } | null
};

export type PlatformAddonFragment = {
  __typename: 'platform_addon',
  platform_connection_id: number,
  platform_id: string,
  name: string,
  platform_addon_prices: Array<{
    __typename: 'platform_addon_price',
    currency: platform_currency_enum,
    interval?: platform_plan_interval_enum | null,
    interval_count?: number | null,
    pricing_model: platform_plan_pricing_model_enum,
    platform_currency: {
      __typename: 'platform_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        base: platform_base_currency_enum,
        rate: string
      }>
    },
    platform_addon_price_tiers: Array<{
      __typename: 'platform_addon_price_tier',
      up_to: number,
      price: string
    }>,
    platform_connection: {
      __typename: 'platform_connection',
      account: {
        __typename: 'account',
        currency: platform_base_currency_enum
      }
    }
  }>,
  platform_connection: {
    __typename: 'platform_connection',
    account: {
      __typename: 'account',
      currency: platform_base_currency_enum
    }
  }
};

export type PlatformAddonPriceFragment = {
  __typename: 'platform_addon_price',
  currency: platform_currency_enum,
  interval?: platform_plan_interval_enum | null,
  interval_count?: number | null,
  pricing_model: platform_plan_pricing_model_enum,
  platform_currency: {
    __typename: 'platform_currency',
    platform_currency_exchange_rates: Array<{
      __typename: 'platform_currency_exchange_rate',
      base: platform_base_currency_enum,
      rate: string
    }>
  },
  platform_addon_price_tiers: Array<{
    __typename: 'platform_addon_price_tier',
    up_to: number,
    price: string
  }>,
  platform_connection: {
    __typename: 'platform_connection',
    account: {
      __typename: 'account',
      currency: platform_base_currency_enum
    }
  }
};

export type PlatformAddonPriceTierFragment = {
  __typename: 'platform_addon_price_tier',
  up_to: number,
  price: string
};

export type PlatformConnectionFragment = {
  __typename: 'platform_connection',
  id: number,
  platform_id: string,
  account_id: number,
  platform: platform_enum,
  data: any,
  data_text?: string | null,
  import_started_at?: string | null,
  import_completed_at?: string | null,
  last_synced_at?: string | null,
  sync_disabled: boolean,
  options: any,
  meta: any,
  publishable_key?: string | null,
  account: {
    __typename: 'account',
    id: number,
    status: account_status_enum,
    token: string,
    title: string,
    prosperstack: boolean,
    test_mode_for_account_id?: number | null,
    Pauses: Array<{
      __typename: 'offer',
      id: number
    }>
  },
  platform_cancel_reasons: Array<{
    __typename: 'platform_cancel_reason',
    code: string,
    paper_code: string
  }>,
  naviga_paper_codes: Array<{
    __typename: 'naviga_paper_code',
    client_code: string,
    paper_code: string,
    platform_connection_naviga_paper_code_endpoint?: {
      __typename: 'platform_connection_naviga_paper_code_endpoint',
      endpoint: string,
      access_controlled: boolean
    } | null
  }>
};

export type PlatformCustomerFragment = {
  __typename: 'platform_customer',
  platform_connection_id: number,
  platform_id: string,
  name: string,
  email: string,
  platform_subscriptions: Array<{
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_customer_id: string,
    status: platform_subscription_status_enum,
    started_at: string,
    canceled_at?: string | null,
    trial_end?: string | null,
    updated_at: string,
    next_billing_at?: string | null,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string,
      price?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_connection_id: number,
        platform_id: string,
        platform_product_id: string,
        name: string,
        currency: platform_currency_enum,
        interval: platform_plan_interval_enum,
        interval_count: number,
        pricing_model: platform_plan_pricing_model_enum,
        platform_plan_tiers: Array<{
          __typename: 'platform_plan_tier',
          up_to: number,
          price: string,
          flat_fee: string
        }>,
        platform_product?: {
          __typename: 'platform_product',
          platform_connection_id: number,
          platform_id: string,
          name: string
        } | null,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null
    }>,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      quantity: string,
      price?: string | null,
      platform_addon?: {
        __typename: 'platform_addon',
        platform_connection_id: number,
        platform_id: string,
        name: string,
        platform_addon_prices: Array<{
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_addon_price?: {
        __typename: 'platform_addon_price',
        currency: platform_currency_enum,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        pricing_model: platform_plan_pricing_model_enum,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_addon_price_tiers: Array<{
          __typename: 'platform_addon_price_tier',
          up_to: number,
          price: string
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>
      } | null
    }>,
    platform_invoices: Array<{
      __typename: 'platform_invoice',
      platform_connection_id: number,
      platform_id: string,
      platform_subscription_id: string,
      status: platform_invoice_status_enum,
      amount_paid: string,
      total: string,
      period_end: string,
      number?: string | null
    }>
  }>
};

export type PlatformImportFragment = {
  __typename: 'platform_import',
  job_id: string,
  platform_connection_id: number,
  initial: boolean,
  status: platform_import_status_enum,
  started_at?: string | null,
  completed_at?: string | null,
  failed_at?: string | null,
  created_at: string,
  updated_at: string,
  retries: number,
  platform_import_steps: Array<{
    __typename: 'platform_import_step',
    job_id: string,
    type: platform_import_step_type_enum,
    cursor?: any | null,
    started_at: string,
    completed_at?: string | null
  }>
};

export type PlatformImportStepFragment = {
  __typename: 'platform_import_step',
  job_id: string,
  type: platform_import_step_type_enum,
  cursor?: any | null,
  started_at: string,
  completed_at?: string | null
};

export type PlatformInvoiceFragment = {
  __typename: 'platform_invoice',
  platform_connection_id: number,
  platform_id: string,
  platform_subscription_id: string,
  status: platform_invoice_status_enum,
  amount_paid: string,
  total: string,
  period_end: string,
  number?: string | null
};

export type PlatformPlanFragment = {
  __typename: 'platform_plan',
  platform_connection_id: number,
  platform_id: string,
  platform_product_id: string,
  name: string,
  currency: platform_currency_enum,
  interval: platform_plan_interval_enum,
  interval_count: number,
  pricing_model: platform_plan_pricing_model_enum,
  platform_plan_tiers: Array<{
    __typename: 'platform_plan_tier',
    up_to: number,
    price: string,
    flat_fee: string
  }>,
  platform_product?: {
    __typename: 'platform_product',
    platform_connection_id: number,
    platform_id: string,
    name: string
  } | null,
  platform_currency: {
    __typename: 'platform_currency',
    platform_currency_exchange_rates: Array<{
      __typename: 'platform_currency_exchange_rate',
      base: platform_base_currency_enum,
      rate: string
    }>
  },
  platform_connection: {
    __typename: 'platform_connection',
    account: {
      __typename: 'account',
      currency: platform_base_currency_enum
    }
  }
};

export type PlatformPlanTierFragment = {
  __typename: 'platform_plan_tier',
  up_to: number,
  price: string,
  flat_fee: string
};

export type PlatformProductFragment = {
  __typename: 'platform_product',
  platform_connection_id: number,
  platform_id: string,
  name: string
};

export type PlatformRefundFragment = {
  __typename: 'platform_refund',
  platform_connection_id: number,
  platform_id: string,
  platform_invoice_id: string,
  amount_refunded: string,
  issued_at: string,
  platform_invoice?: {
    __typename: 'platform_invoice',
    platform_connection_id: number,
    platform_id: string,
    platform_subscription_id: string,
    status: platform_invoice_status_enum,
    amount_paid: string,
    total: string,
    period_end: string,
    number?: string | null
  } | null
};

export type PlatformSubscriptionAddonFragment = {
  __typename: 'platform_subscription_addon',
  quantity: string,
  price?: string | null,
  platform_addon?: {
    __typename: 'platform_addon',
    platform_connection_id: number,
    platform_id: string,
    name: string,
    platform_addon_prices: Array<{
      __typename: 'platform_addon_price',
      currency: platform_currency_enum,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      pricing_model: platform_plan_pricing_model_enum,
      platform_currency: {
        __typename: 'platform_currency',
        platform_currency_exchange_rates: Array<{
          __typename: 'platform_currency_exchange_rate',
          base: platform_base_currency_enum,
          rate: string
        }>
      },
      platform_addon_price_tiers: Array<{
        __typename: 'platform_addon_price_tier',
        up_to: number,
        price: string
      }>,
      platform_connection: {
        __typename: 'platform_connection',
        account: {
          __typename: 'account',
          currency: platform_base_currency_enum
        }
      }
    }>,
    platform_connection: {
      __typename: 'platform_connection',
      account: {
        __typename: 'account',
        currency: platform_base_currency_enum
      }
    }
  } | null,
  platform_addon_price?: {
    __typename: 'platform_addon_price',
    currency: platform_currency_enum,
    interval?: platform_plan_interval_enum | null,
    interval_count?: number | null,
    pricing_model: platform_plan_pricing_model_enum,
    platform_currency: {
      __typename: 'platform_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        base: platform_base_currency_enum,
        rate: string
      }>
    },
    platform_addon_price_tiers: Array<{
      __typename: 'platform_addon_price_tier',
      up_to: number,
      price: string
    }>,
    platform_connection: {
      __typename: 'platform_connection',
      account: {
        __typename: 'account',
        currency: platform_base_currency_enum
      }
    }
  } | null,
  platform_subscription?: {
    __typename: 'platform_subscription',
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string,
      price?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_connection_id: number,
        platform_id: string,
        platform_product_id: string,
        name: string,
        currency: platform_currency_enum,
        interval: platform_plan_interval_enum,
        interval_count: number,
        pricing_model: platform_plan_pricing_model_enum,
        platform_plan_tiers: Array<{
          __typename: 'platform_plan_tier',
          up_to: number,
          price: string,
          flat_fee: string
        }>,
        platform_product?: {
          __typename: 'platform_product',
          platform_connection_id: number,
          platform_id: string,
          name: string
        } | null,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null
    }>
  } | null
};

export type PlatformSubscriptionFragment = {
  __typename: 'platform_subscription',
  platform_connection_id: number,
  platform_id: string,
  platform_customer_id: string,
  status: platform_subscription_status_enum,
  started_at: string,
  canceled_at?: string | null,
  trial_end?: string | null,
  updated_at: string,
  next_billing_at?: string | null,
  platform_subscription_plans: Array<{
    __typename: 'platform_subscription_plan',
    quantity: string,
    price?: string | null,
    interval?: platform_plan_interval_enum | null,
    interval_count?: number | null,
    platform_plan?: {
      __typename: 'platform_plan',
      platform_connection_id: number,
      platform_id: string,
      platform_product_id: string,
      name: string,
      currency: platform_currency_enum,
      interval: platform_plan_interval_enum,
      interval_count: number,
      pricing_model: platform_plan_pricing_model_enum,
      platform_plan_tiers: Array<{
        __typename: 'platform_plan_tier',
        up_to: number,
        price: string,
        flat_fee: string
      }>,
      platform_product?: {
        __typename: 'platform_product',
        platform_connection_id: number,
        platform_id: string,
        name: string
      } | null,
      platform_currency: {
        __typename: 'platform_currency',
        platform_currency_exchange_rates: Array<{
          __typename: 'platform_currency_exchange_rate',
          base: platform_base_currency_enum,
          rate: string
        }>
      },
      platform_connection: {
        __typename: 'platform_connection',
        account: {
          __typename: 'account',
          currency: platform_base_currency_enum
        }
      }
    } | null
  }>,
  platform_subscription_addons: Array<{
    __typename: 'platform_subscription_addon',
    quantity: string,
    price?: string | null,
    platform_addon?: {
      __typename: 'platform_addon',
      platform_connection_id: number,
      platform_id: string,
      name: string,
      platform_addon_prices: Array<{
        __typename: 'platform_addon_price',
        currency: platform_currency_enum,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        pricing_model: platform_plan_pricing_model_enum,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_addon_price_tiers: Array<{
          __typename: 'platform_addon_price_tier',
          up_to: number,
          price: string
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      }>,
      platform_connection: {
        __typename: 'platform_connection',
        account: {
          __typename: 'account',
          currency: platform_base_currency_enum
        }
      }
    } | null,
    platform_addon_price?: {
      __typename: 'platform_addon_price',
      currency: platform_currency_enum,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      pricing_model: platform_plan_pricing_model_enum,
      platform_currency: {
        __typename: 'platform_currency',
        platform_currency_exchange_rates: Array<{
          __typename: 'platform_currency_exchange_rate',
          base: platform_base_currency_enum,
          rate: string
        }>
      },
      platform_addon_price_tiers: Array<{
        __typename: 'platform_addon_price_tier',
        up_to: number,
        price: string
      }>,
      platform_connection: {
        __typename: 'platform_connection',
        account: {
          __typename: 'account',
          currency: platform_base_currency_enum
        }
      }
    } | null,
    platform_subscription?: {
      __typename: 'platform_subscription',
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        quantity: string,
        price?: string | null,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_connection_id: number,
          platform_id: string,
          platform_product_id: string,
          name: string,
          currency: platform_currency_enum,
          interval: platform_plan_interval_enum,
          interval_count: number,
          pricing_model: platform_plan_pricing_model_enum,
          platform_plan_tiers: Array<{
            __typename: 'platform_plan_tier',
            up_to: number,
            price: string,
            flat_fee: string
          }>,
          platform_product?: {
            __typename: 'platform_product',
            platform_connection_id: number,
            platform_id: string,
            name: string
          } | null,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null
      }>
    } | null
  }>,
  platform_invoices: Array<{
    __typename: 'platform_invoice',
    platform_connection_id: number,
    platform_id: string,
    platform_subscription_id: string,
    status: platform_invoice_status_enum,
    amount_paid: string,
    total: string,
    period_end: string,
    number?: string | null
  }>
};

export type PlatformSubscriptionPlanFragment = {
  __typename: 'platform_subscription_plan',
  quantity: string,
  price?: string | null,
  interval?: platform_plan_interval_enum | null,
  interval_count?: number | null,
  platform_plan?: {
    __typename: 'platform_plan',
    platform_connection_id: number,
    platform_id: string,
    platform_product_id: string,
    name: string,
    currency: platform_currency_enum,
    interval: platform_plan_interval_enum,
    interval_count: number,
    pricing_model: platform_plan_pricing_model_enum,
    platform_plan_tiers: Array<{
      __typename: 'platform_plan_tier',
      up_to: number,
      price: string,
      flat_fee: string
    }>,
    platform_product?: {
      __typename: 'platform_product',
      platform_connection_id: number,
      platform_id: string,
      name: string
    } | null,
    platform_currency: {
      __typename: 'platform_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        base: platform_base_currency_enum,
        rate: string
      }>
    },
    platform_connection: {
      __typename: 'platform_connection',
      account: {
        __typename: 'account',
        currency: platform_base_currency_enum
      }
    }
  } | null
};

export type SegmentFragment = {
  __typename: 'segment',
  id: number,
  token: string,
  name: string,
  integration_segment_id?: string | null,
  created_at: string,
  primary_segment_condition_group?: {
    __typename: 'segment_condition_group',
    id: number,
    boolean_operator: segment_condition_boolean_operator_enum,
    segment_condition_group_entries: Array<{
      __typename: 'segment_condition_group_entry',
      entry_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null,
      entry_segment_condition?: {
        __typename: 'segment_condition',
        id: number,
        property: segment_condition_property_enum,
        operator: segment_condition_operator_enum,
        value: any,
        property_id?: number | null,
        custom_property?: {
          __typename: 'property',
          id: number,
          name: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null
        } | null
      } | null
    }>
  } | null,
  integration?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum
  } | null,
  segment_tags: Array<{
    __typename: 'segment_tag',
    tag?: {
      __typename: 'tag',
      id: number,
      name: string
    } | null
  }>
};

export type SegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  token: string,
  name: string,
  created_at: string,
  segment_group_segments: Array<{
    __typename: 'segment_group_segment',
    segment_group_id: number,
    segment_id: number,
    operator: segment_group_operator_enum,
    segment: {
      __typename: 'segment',
      id: number,
      token: string,
      name: string,
      integration_segment_id?: string | null,
      created_at: string,
      primary_segment_condition_group?: {
        __typename: 'segment_condition_group',
        id: number,
        boolean_operator: segment_condition_boolean_operator_enum,
        segment_condition_group_entries: Array<{
          __typename: 'segment_condition_group_entry',
          entry_segment_condition_group?: {
            __typename: 'segment_condition_group',
            id: number,
            boolean_operator: segment_condition_boolean_operator_enum,
            segment_condition_group_entries: Array<{
              __typename: 'segment_condition_group_entry',
              entry_segment_condition?: {
                __typename: 'segment_condition',
                id: number,
                property: segment_condition_property_enum,
                operator: segment_condition_operator_enum,
                value: any,
                property_id?: number | null,
                custom_property?: {
                  __typename: 'property',
                  id: number,
                  name: string,
                  entity: property_entity_enum,
                  type: property_type_enum,
                  format?: property_format_enum | null
                } | null
              } | null
            }>
          } | null,
          entry_segment_condition?: {
            __typename: 'segment_condition',
            id: number,
            property: segment_condition_property_enum,
            operator: segment_condition_operator_enum,
            value: any,
            property_id?: number | null,
            custom_property?: {
              __typename: 'property',
              id: number,
              name: string,
              entity: property_entity_enum,
              type: property_type_enum,
              format?: property_format_enum | null
            } | null
          } | null
        }>
      } | null,
      integration?: {
        __typename: 'integration',
        id: number,
        type: integration_type_enum
      } | null,
      segment_tags: Array<{
        __typename: 'segment_tag',
        tag?: {
          __typename: 'tag',
          id: number,
          name: string
        } | null
      }>
    }
  }>
};

export type SegmentPlatformSubscriptionFragment = {
  __typename: 'platform_subscription',
  status: platform_subscription_status_enum,
  platform_subscription_plans: Array<{
    __typename: 'platform_subscription_plan',
    platform_plan_id: string,
    platform_plan?: {
      __typename: 'platform_plan',
      platform_id: string,
      platform_product_id: string,
      interval: platform_plan_interval_enum,
      interval_count: number
    } | null
  }>
};

export type SegmentSubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  platform_id: string,
  status: subscription_status_enum,
  mrr: string,
  subscriber: {
    __typename: 'subscriber',
    status?: subscriber_status_enum | null,
    inactive_mrr: string,
    active_mrr: string,
    active_offer_id?: number | null,
    platform_customer?: {
      __typename: 'platform_customer',
      platform_coupon_id?: string | null,
      past_due?: boolean | null,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        status: platform_subscription_status_enum,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string
        }>
      }>
    } | null
  },
  account: {
    __typename: 'account',
    platform_connection?: {
      __typename: 'platform_connection',
      id: number,
      platform: platform_enum
    } | null
  },
  platform_subscription?: {
    __typename: 'platform_subscription',
    status: platform_subscription_status_enum,
    platform_coupon_id?: string | null,
    past_due?: boolean | null,
    can_pause: boolean,
    can_cancel: boolean,
    paper_code?: string | null,
    cancel_at_period_end?: boolean | null,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      platform_plan_id: string,
      platform_plan?: {
        __typename: 'platform_plan',
        interval: platform_plan_interval_enum,
        interval_count: number,
        platform_product_id: string,
        platform_product?: {
          __typename: 'platform_product',
          platform_product_group_id?: string | null
        } | null
      } | null,
      swappable_ecommerce_products: Array<{
        __typename: 'platform_subscription_plan_swappable_ecommerce_product',
        platform_ecommerce_product?: {
          __typename: 'platform_ecommerce_product',
          platform_id: string,
          active: boolean
        } | null
      }>
    }>,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      platform_addon_id: string
    }>
  } | null
};

export type ServerFlowFragment = {
  __typename: 'flow',
  id: number,
  account_id: number,
  secret: string,
  signature_required: boolean,
  default_language: language_enum,
  account: {
    __typename: 'account',
    id: number,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  }
};

export type SubscriberFlowQuestionAnswerFragment = {
  __typename: 'question_answer',
  question_id: number,
  question_answer_radios: Array<{
    __typename: 'question_answer_radio',
    question_option_id: number
  }>,
  question_answer_text?: {
    __typename: 'question_answer_text',
    value: string
  } | null
};

export type SubscriberFlowSegmentMatchFlowStepFragment = {
  __typename: 'flow_step',
  published_version?: {
    __typename: 'flow_step_version',
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'flow_step_version',
    flow_step_version_condition_segment_groups: Array<{
      __typename: 'flow_step_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_step_version_condition_segments: Array<{
      __typename: 'flow_step_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>
  } | null,
  flow_step_form?: {
    __typename: 'flow_step_form',
    form: {
      __typename: 'form',
      published_version?: {
        __typename: 'form_version',
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question?: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'form_version',
        form_version_questions: Array<{
          __typename: 'form_version_question',
          question?: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    }
  } | null,
  flow_step_offer_rule_group?: {
    __typename: 'flow_step_offer_rule_group',
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      published_version?: {
        __typename: 'offer_rule_group_version',
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            published_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            published_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    } | null
  } | null,
  flow_step_deflection_rule_group?: {
    __typename: 'flow_step_deflection_rule_group',
    offer_rule_group?: {
      __typename: 'offer_rule_group',
      published_version?: {
        __typename: 'offer_rule_group_version',
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            published_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_group_version',
        offer_rule_group_version_offer_rules: Array<{
          __typename: 'offer_rule_group_version_offer_rule',
          offer_rule?: {
            __typename: 'offer_rule',
            published_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_version',
              offer_rule_version_segment_groups: Array<{
                __typename: 'offer_rule_version_segment_group',
                offer_rule_version_id?: number | null,
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>,
              offer_rule_version_segments: Array<{
                __typename: 'offer_rule_version_segment',
                offer_rule_version_id?: number | null,
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>
            } | null
          } | null
        }>
      } | null
    } | null
  } | null
};

export type SubscriberFlowSegmentMatchFlowStepVersionFragment = {
  __typename: 'flow_step_version',
  flow_step_version_condition_segment_groups: Array<{
    __typename: 'flow_step_version_condition_segment_group',
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  flow_step_version_condition_segments: Array<{
    __typename: 'flow_step_version_condition_segment',
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>
};

export type SubscriberFlowSegmentMatchFormFragment = {
  __typename: 'form',
  published_version?: {
    __typename: 'form_version',
    form_version_questions: Array<{
      __typename: 'form_version_question',
      question?: {
        __typename: 'question',
        published_version?: {
          __typename: 'question_version',
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'form_version',
    form_version_questions: Array<{
      __typename: 'form_version_question',
      question?: {
        __typename: 'question',
        published_version?: {
          __typename: 'question_version',
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type SubscriberFlowSegmentMatchFormVersionFragment = {
  __typename: 'form_version',
  form_version_questions: Array<{
    __typename: 'form_version_question',
    question?: {
      __typename: 'question',
      published_version?: {
        __typename: 'question_version',
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'question_version',
        question_version_condition_segments: Array<{
          __typename: 'question_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        question_version_condition_segment_groups: Array<{
          __typename: 'question_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>
      } | null
    } | null
  }>
};

export type SubscriberFlowSegmentMatchOfferRuleFragment = {
  __typename: 'offer_rule',
  published_version?: {
    __typename: 'offer_rule_version',
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_version',
    offer_rule_version_segment_groups: Array<{
      __typename: 'offer_rule_version_segment_group',
      offer_rule_version_id?: number | null,
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    offer_rule_version_segments: Array<{
      __typename: 'offer_rule_version_segment',
      offer_rule_version_id?: number | null,
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type SubscriberFlowSegmentMatchOfferRuleGroupFragment = {
  __typename: 'offer_rule_group',
  published_version?: {
    __typename: 'offer_rule_group_version',
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        published_version?: {
          __typename: 'offer_rule_version',
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'offer_rule_group_version',
    offer_rule_group_version_offer_rules: Array<{
      __typename: 'offer_rule_group_version_offer_rule',
      offer_rule?: {
        __typename: 'offer_rule',
        published_version?: {
          __typename: 'offer_rule_version',
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_version',
          offer_rule_version_segment_groups: Array<{
            __typename: 'offer_rule_version_segment_group',
            offer_rule_version_id?: number | null,
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          offer_rule_version_segments: Array<{
            __typename: 'offer_rule_version_segment',
            offer_rule_version_id?: number | null,
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>
        } | null
      } | null
    }>
  } | null
};

export type SubscriberFlowSegmentMatchOfferRuleGroupVersionFragment = {
  __typename: 'offer_rule_group_version',
  offer_rule_group_version_offer_rules: Array<{
    __typename: 'offer_rule_group_version_offer_rule',
    offer_rule?: {
      __typename: 'offer_rule',
      published_version?: {
        __typename: 'offer_rule_version',
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'offer_rule_version',
        offer_rule_version_segment_groups: Array<{
          __typename: 'offer_rule_version_segment_group',
          offer_rule_version_id?: number | null,
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        offer_rule_version_segments: Array<{
          __typename: 'offer_rule_version_segment',
          offer_rule_version_id?: number | null,
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>
      } | null
    } | null
  }>
};

export type SubscriberFlowSegmentMatchOfferRuleVersionFragment = {
  __typename: 'offer_rule_version',
  offer_rule_version_segment_groups: Array<{
    __typename: 'offer_rule_version_segment_group',
    offer_rule_version_id?: number | null,
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>,
  offer_rule_version_segments: Array<{
    __typename: 'offer_rule_version_segment',
    offer_rule_version_id?: number | null,
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>
};

export type SubscriberFlowSegmentMatchQuestionFragment = {
  __typename: 'question',
  published_version?: {
    __typename: 'question_version',
    question_version_condition_segments: Array<{
      __typename: 'question_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    question_version_condition_segment_groups: Array<{
      __typename: 'question_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>
  } | null,
  draft_version?: {
    __typename: 'question_version',
    question_version_condition_segments: Array<{
      __typename: 'question_version_condition_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    question_version_condition_segment_groups: Array<{
      __typename: 'question_version_condition_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>
  } | null
};

export type SubscriberFlowSegmentMatchQuestionVersionFragment = {
  __typename: 'question_version',
  question_version_condition_segments: Array<{
    __typename: 'question_version_condition_segment',
    segment_id?: number | null,
    segment?: {
      __typename: 'segment',
      id: number,
      name: string
    } | null
  }>,
  question_version_condition_segment_groups: Array<{
    __typename: 'question_version_condition_segment_group',
    segment_group_id?: number | null,
    segment_group?: {
      __typename: 'segment_group',
      id: number,
      segment_group_segments: Array<{
        __typename: 'segment_group_segment',
        operator: segment_group_operator_enum,
        segment: {
          __typename: 'segment',
          id: number,
          name: string
        }
      }>
    } | null
  }>
};

export type SubscriberFlowSegmentMatchSegmentGroupFragment = {
  __typename: 'segment_group',
  id: number,
  segment_group_segments: Array<{
    __typename: 'segment_group_segment',
    operator: segment_group_operator_enum,
    segment: {
      __typename: 'segment',
      id: number,
      name: string
    }
  }>
};

export type SubscriberFragment = {
  __typename: 'subscriber',
  id: number,
  account_id: number,
  name: string,
  email: string,
  active_mrr: string,
  inactive_mrr: string,
  active_offer_id?: number | null,
  active_offer_group_id?: number | null,
  platform_id: string,
  status?: subscriber_status_enum | null
};

export type SubscriptionFragment = {
  __typename: 'subscription',
  id: number,
  subscriber_id: number,
  status: subscription_status_enum,
  mrr: string,
  platform_id: string,
  updated_at: string,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    account_id: number,
    name: string,
    email: string,
    active_mrr: string,
    inactive_mrr: string,
    active_offer_id?: number | null,
    active_offer_group_id?: number | null,
    platform_id: string,
    status?: subscriber_status_enum | null
  },
  platform_subscription?: {
    __typename: 'platform_subscription',
    can_cancel: boolean,
    cancel_at_period_end?: boolean | null
  } | null
};

export type SyncIntegrationSubscriberFragment = {
  __typename: 'subscriber',
  id: number,
  subscriber_flows: Array<{
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum,
    completed_at?: string | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question_id: number,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option: {
            __typename: 'question_option',
            reason_code?: string | null,
            published_version?: {
              __typename: 'question_option_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>
  }>
};

export type UserFragment = {
  __typename: 'user',
  id: string,
  name?: string | null,
  given_name?: string | null,
  family_name?: string | null,
  email?: string | null,
  picture?: string | null,
  created_at: string,
  updated_at: string,
  user_profiles: Array<{
    __typename: 'user_profile',
    first_name: string,
    last_name: string,
    email: string
  }>,
  account_users: Array<{
    __typename: 'account_user',
    account: {
      __typename: 'account',
      id: number,
      title: string,
      status: account_status_enum,
      mode: account_mode_enum,
      owner_user_id?: string | null,
      stripe_customer_id: string,
      stripe_subscription_id: string,
      test_mode_for_account_id?: number | null,
      created_at: string,
      internal_testing: boolean,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number,
        platform: platform_enum
      } | null
    }
  }>
};

export type IncompleteSubscriberFlowIsDeflectedSubscriberFlowQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type IncompleteSubscriberFlowIsDeflectedSubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum,
    started_at?: string | null,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      status?: subscriber_status_enum | null
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      status: subscription_status_enum
    }
  } | null
};

export type IncompleteSubscriberFlowIsDeflectedOtherSubscriberFlowsQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
  after: Scalars['timestamptz']['input'];
}>;


export type IncompleteSubscriberFlowIsDeflectedOtherSubscriberFlowsQuery = {
  subscriber_flow_aggregate: {
    __typename: 'subscriber_flow_aggregate',
    aggregate?: {
      __typename: 'subscriber_flow_aggregate_fields',
      count: number
    } | null
  }
};

export type HubSpotDiscoverSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type HubSpotDiscoverSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    account_id: number,
    options: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string,
    platform_id: string,
    platform_customer?: {
      __typename: 'platform_customer',
      code?: string | null
    } | null
  } | null
};

export type HubSpotSyncSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type HubSpotSyncSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    account_id: number,
    options: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string,
    platform_id: string,
    platform_customer?: {
      __typename: 'platform_customer',
      code?: string | null
    } | null
  } | null
};

export type HubSpotSyncSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  set: subscriber_set_input;
  objects: Array<subscriber_property_insert_input> | subscriber_property_insert_input;
}>;


export type HubSpotSyncSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null,
  insert_subscriber_property?: {
    __typename: 'subscriber_property_mutation_response',
    affected_rows: number
  } | null
};

export type IntercomDiscoverSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type IntercomDiscoverSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    credentials: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string
  } | null
};

export type IntercomSyncSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type IntercomSyncSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    account_id: number,
    credentials: any,
    options: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string
  } | null
};

export type IntercomSyncSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  intercomContactId: Scalars['String']['input'];
  objects: Array<subscriber_property_insert_input> | subscriber_property_insert_input;
}>;


export type IntercomSyncSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null,
  insert_subscriber_property?: {
    __typename: 'subscriber_property_mutation_response',
    affected_rows: number
  } | null
};

export type KlaviyoDiscoverSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type KlaviyoDiscoverSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string,
    klaviyo_profile_id?: string | null
  } | null
};

export type KlaviyoGetSubscriberSegmentIdsQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type KlaviyoGetSubscriberSegmentIdsQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    klaviyo_profile_id?: string | null
  } | null
};

export type KlaviyoSyncSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type KlaviyoSyncSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string
  } | null
};

export type KlaviyoSyncSubscriberPropertiesMutationVariables = Exact<{
  objects: Array<subscriber_property_insert_input> | subscriber_property_insert_input;
}>;


export type KlaviyoSyncSubscriberPropertiesMutation = {
  insert_subscriber_property?: {
    __typename: 'subscriber_property_mutation_response',
    affected_rows: number
  } | null
};

export type KlaviyoSyncSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  klaviyoProfileId: Scalars['String']['input'];
}>;


export type KlaviyoSyncSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type KlaviyoUpdateSubscriberPropertiesQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type KlaviyoUpdateSubscriberPropertiesQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    klaviyo_profile_id?: string | null
  } | null
};

export type SalesforceDiscoverSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type SalesforceDiscoverSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string
  } | null
};

export type SalesforceSyncSubscriberQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type SalesforceSyncSubscriberQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null,
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    email: string
  } | null
};

export type SalesforceSyncSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  salesforceContactId: Scalars['String']['input'];
  objects: Array<subscriber_property_insert_input> | subscriber_property_insert_input;
}>;


export type SalesforceSyncSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null,
  insert_subscriber_property?: {
    __typename: 'subscriber_property_mutation_response',
    affected_rows: number
  } | null
};

export type ActivateUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ActivateUserMutation = {
  update_user?: {
    __typename: 'user_mutation_response',
    affected_rows: number
  } | null
};

export type CompletePlatformImportMutationVariables = Exact<{
  jobId: Scalars['String']['input'];
  completedAt: Scalars['timestamptz']['input'];
}>;


export type CompletePlatformImportMutation = {
  update_platform_import_by_pk?: {
    __typename: 'platform_import',
    job_id: string,
    completed_at?: string | null
  } | null
};

export type CompletePlatformImportStepMutationVariables = Exact<{
  jobId: Scalars['String']['input'];
  stepType: platform_import_step_type_enum;
  completedAt: Scalars['timestamptz']['input'];
}>;


export type CompletePlatformImportStepMutation = {
  update_platform_import_step_by_pk?: {
    __typename: 'platform_import_step',
    job_id: string,
    type: platform_import_step_type_enum
  } | null
};

export type DeletePlatformCustomerMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type DeletePlatformCustomerMutation = {
  delete_platform_customer_by_pk?: {
    __typename: 'platform_customer',
    platform_id: string
  } | null
};

export type DeletePlatformPlanMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type DeletePlatformPlanMutation = {
  delete_platform_plan_by_pk?: {
    __typename: 'platform_plan',
    platform_id: string
  } | null
};

export type DeletePlatformSubscriptionMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type DeletePlatformSubscriptionMutation = {
  delete_platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_id: string
  } | null
};

export type ExpireSubscriberCampaignMutationVariables = Exact<{
  subscriberCampaignId: Scalars['Int']['input'];
}>;


export type ExpireSubscriberCampaignMutation = {
  update_subscriber_campaign_by_pk?: {
    __typename: 'subscriber_campaign',
    id: number
  } | null
};

export type FailPlatformImportMutationVariables = Exact<{
  jobId: Scalars['String']['input'];
  failedAt: Scalars['timestamptz']['input'];
}>;


export type FailPlatformImportMutation = {
  update_platform_import_by_pk?: {
    __typename: 'platform_import',
    job_id: string
  } | null
};

export type IncrementPlatformInvoiceAmountPaidMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformInvoiceId: Scalars['String']['input'];
  inc: Scalars['numeric']['input'];
  set: platform_invoice_set_input;
}>;


export type IncrementPlatformInvoiceAmountPaidMutation = {
  update_platform_invoice_by_pk?: {
    __typename: 'platform_invoice',
    platform_id: string
  } | null
};

export type IncrementPlatformInvoiceTotalMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformInvoiceId: Scalars['String']['input'];
  inc: Scalars['numeric']['input'];
  set: platform_invoice_set_input;
}>;


export type IncrementPlatformInvoiceTotalMutation = {
  update_platform_invoice_by_pk?: {
    __typename: 'platform_invoice',
    platform_id: string
  } | null
};

export type InsertEventMutationVariables = Exact<{
  object: event_insert_input;
}>;


export type InsertEventMutation = {
  insert_event_one?: {
    __typename: 'event',
    id: number
  } | null
};

export type InsertPlatformAddonsMutationVariables = Exact<{
  objects: Array<platform_addon_insert_input> | platform_addon_insert_input;
}>;


export type InsertPlatformAddonsMutation = {
  insert_platform_addon?: {
    __typename: 'platform_addon_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformCancelReasonsMutationVariables = Exact<{
  objects: Array<platform_cancel_reason_insert_input> | platform_cancel_reason_insert_input;
}>;


export type InsertPlatformCancelReasonsMutation = {
  insert_platform_cancel_reason?: {
    __typename: 'platform_cancel_reason_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformCouponsMutationVariables = Exact<{
  objects: Array<platform_coupon_insert_input> | platform_coupon_insert_input;
}>;


export type InsertPlatformCouponsMutation = {
  insert_platform_coupon?: {
    __typename: 'platform_coupon_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformCustomersMutationVariables = Exact<{
  objects: Array<platform_customer_insert_input> | platform_customer_insert_input;
}>;


export type InsertPlatformCustomersMutation = {
  insert_platform_customer?: {
    __typename: 'platform_customer_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformEcommerceProductsMutationVariables = Exact<{
  objects: Array<platform_ecommerce_product_insert_input> | platform_ecommerce_product_insert_input;
}>;


export type InsertPlatformEcommerceProductsMutation = {
  insert_platform_ecommerce_product?: {
    __typename: 'platform_ecommerce_product_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformInvoiceMutationVariables = Exact<{
  object: platform_invoice_insert_input;
  updateColumns: Array<platform_invoice_update_column> | platform_invoice_update_column;
}>;


export type InsertPlatformInvoiceMutation = {
  insert_platform_invoice_one?: {
    __typename: 'platform_invoice',
    platform_id: string
  } | null
};

export type InsertPlatformInvoicesMutationVariables = Exact<{
  objects: Array<platform_invoice_insert_input> | platform_invoice_insert_input;
}>;


export type InsertPlatformInvoicesMutation = {
  insert_platform_invoice?: {
    __typename: 'platform_invoice_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformPlanMutationVariables = Exact<{
  object: platform_plan_insert_input;
}>;


export type InsertPlatformPlanMutation = {
  insert_platform_plan_one?: {
    __typename: 'platform_plan',
    platform_id: string
  } | null
};

export type InsertPlatformPlanGroupsMutationVariables = Exact<{
  objects: Array<platform_plan_group_insert_input> | platform_plan_group_insert_input;
}>;


export type InsertPlatformPlanGroupsMutation = {
  insert_platform_plan_group?: {
    __typename: 'platform_plan_group_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformPlansMutationVariables = Exact<{
  objects: Array<platform_plan_insert_input> | platform_plan_insert_input;
}>;


export type InsertPlatformPlansMutation = {
  insert_platform_plan?: {
    __typename: 'platform_plan_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformProductGroupsMutationVariables = Exact<{
  objects: Array<platform_product_group_insert_input> | platform_product_group_insert_input;
}>;


export type InsertPlatformProductGroupsMutation = {
  insert_platform_product_group?: {
    __typename: 'platform_product_group_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformProductsMutationVariables = Exact<{
  objects: Array<platform_product_insert_input> | platform_product_insert_input;
}>;


export type InsertPlatformProductsMutation = {
  insert_platform_product?: {
    __typename: 'platform_product_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformRawObjectsMutationVariables = Exact<{
  objects: Array<platform_raw_object_insert_input> | platform_raw_object_insert_input;
}>;


export type InsertPlatformRawObjectsMutation = {
  insert_platform_raw_object?: {
    __typename: 'platform_raw_object_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformRefundMutationVariables = Exact<{
  object: platform_refund_insert_input;
}>;


export type InsertPlatformRefundMutation = {
  insert_platform_refund_one?: {
    __typename: 'platform_refund',
    platform_id: string
  } | null
};

export type InsertPlatformRefundsMutationVariables = Exact<{
  objects: Array<platform_refund_insert_input> | platform_refund_insert_input;
}>;


export type InsertPlatformRefundsMutation = {
  insert_platform_refund?: {
    __typename: 'platform_refund_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformSubscriptionsMutationVariables = Exact<{
  objects: Array<platform_subscription_insert_input> | platform_subscription_insert_input;
}>;


export type InsertPlatformSubscriptionsMutation = {
  insert_platform_subscription?: {
    __typename: 'platform_subscription_mutation_response',
    affected_rows: number
  } | null
};

export type InsertPlatformVariantsMutationVariables = Exact<{
  objects: Array<platform_variant_insert_input> | platform_variant_insert_input;
}>;


export type InsertPlatformVariantsMutation = {
  insert_platform_variant?: {
    __typename: 'platform_variant_mutation_response',
    affected_rows: number
  } | null
};

export type InsertSubscriberLogMutationVariables = Exact<{
  input: subscriber_log_insert_input;
}>;


export type InsertSubscriberLogMutation = {
  insert_subscriber_log_one?: {
    __typename: 'subscriber_log',
    id: number
  } | null
};

export type InsertSubscriberLogOfferMutationVariables = Exact<{
  input: subscriber_log_offer_insert_input;
}>;


export type InsertSubscriberLogOfferMutation = {
  insert_subscriber_log_offer_one?: {
    __typename: 'subscriber_log_offer',
    subscriber_log_id: number,
    offer_id: number,
    created_at: string
  } | null
};

export type InsertSubscriberLogOfferGroupMutationVariables = Exact<{
  input: subscriber_log_offer_group_insert_input;
}>;


export type InsertSubscriberLogOfferGroupMutation = {
  insert_subscriber_log_offer_group_one?: {
    __typename: 'subscriber_log_offer_group',
    subscriber_log_id: number,
    offer_group_id: number,
    created_at: string
  } | null
};

export type InsertPlatformSubscriptionMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformSubscriptionId: Scalars['String']['input'];
  object: platform_subscription_insert_input;
}>;


export type InsertPlatformSubscriptionMutation = {
  delete_platform_subscription_plan?: {
    __typename: 'platform_subscription_plan_mutation_response',
    affected_rows: number
  } | null,
  delete_platform_subscription_addon?: {
    __typename: 'platform_subscription_addon_mutation_response',
    affected_rows: number
  } | null,
  insert_platform_subscription_one?: {
    __typename: 'platform_subscription',
    platform_id: string
  } | null
};

export type StartPlatformImportMutationVariables = Exact<{
  object: platform_import_insert_input;
}>;


export type StartPlatformImportMutation = {
  insert_platform_import_one?: {
    __typename: 'platform_import',
    job_id: string,
    platform_connection_id: number,
    initial: boolean,
    status: platform_import_status_enum,
    started_at?: string | null,
    completed_at?: string | null,
    failed_at?: string | null,
    created_at: string,
    updated_at: string,
    retries: number,
    platform_import_steps: Array<{
      __typename: 'platform_import_step',
      job_id: string,
      type: platform_import_step_type_enum,
      cursor?: any | null,
      started_at: string,
      completed_at?: string | null
    }>
  } | null
};

export type UpdateNullSubscriberStatusMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  status: subscriber_status_enum;
}>;


export type UpdateNullSubscriberStatusMutation = {
  update_subscriber?: {
    __typename: 'subscriber_mutation_response',
    affected_rows: number
  } | null
};

export type UpdatePlatformConnectionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  set: platform_connection_set_input;
}>;


export type UpdatePlatformConnectionMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type UpdatePlatformImportStepCursorMutationVariables = Exact<{
  object: platform_import_step_insert_input;
}>;


export type UpdatePlatformImportStepCursorMutation = {
  insert_platform_import_step_one?: {
    __typename: 'platform_import_step',
    job_id: string
  } | null
};

export type UpdateSubscriberMrrMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  activeMrr: Scalars['numeric']['input'];
  inactiveMrr: Scalars['numeric']['input'];
}>;


export type UpdateSubscriberMrrMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type UpsertAccountUserMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type UpsertAccountUserMutation = {
  insert_account_user_one?: {
    __typename: 'account_user',
    account_id: number,
    user_id: string
  } | null
};

export type UpsetSubscriberPropertiesMutationVariables = Exact<{
  objects: Array<subscriber_property_insert_input> | subscriber_property_insert_input;
}>;


export type UpsetSubscriberPropertiesMutation = {
  insert_subscriber_property?: {
    __typename: 'subscriber_property_mutation_response',
    affected_rows: number
  } | null
};

export type UpsertSubscriberMutationVariables = Exact<{
  input: subscriber_insert_input;
  updateColumns: Array<subscriber_update_column> | subscriber_update_column;
}>;


export type UpsertSubscriberMutation = {
  insert_subscriber_one?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type UpsertSubscriptionPropertiesMutationVariables = Exact<{
  objects: Array<subscription_property_insert_input> | subscription_property_insert_input;
}>;


export type UpsertSubscriptionPropertiesMutation = {
  insert_subscription_property?: {
    __typename: 'subscription_property_mutation_response',
    affected_rows: number
  } | null
};

export type UpsertUserMutationVariables = Exact<{
  input: user_insert_input;
}>;


export type UpsertUserMutation = {
  insert_user_one?: {
    __typename: 'user',
    id: string,
    created_at: string
  } | null
};

export type BraintreeApplyCouponQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  couponId: Scalars['String']['input'];
}>;


export type BraintreeApplyCouponQuery = {
  platform_coupon_by_pk?: {
    __typename: 'platform_coupon',
    amount_off: string,
    duration: platform_coupon_duration_enum
  } | null
};

export type ImportBraintreeSavedSubscribersQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  afterId: Scalars['Int']['input'];
}>;


export type ImportBraintreeSavedSubscribersQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string
    }>
  }>
};

export type ChargebeeCancelSubscriptionSubscriberFlowQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type ChargebeeCancelSubscriptionSubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type ChargebeeCancelSubscriptionV2SubscriberFlowQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type ChargebeeCancelSubscriptionV2SubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type ChargebeeChangePlanV2QueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ChargebeeChangePlanV2Query = {
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string
    }>
  } | null
};

export type ImportChargebeeAddonQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportChargebeeAddonQuery = {
  platform_addon_by_pk?: {
    __typename: 'platform_addon',
    platform_id: string
  } | null
};

export type ImportChargebeeCustomerQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportChargebeeCustomerQuery = {
  platform_customer_by_pk?: {
    __typename: 'platform_customer',
    platform_id: string
  } | null
};

export type ImportChargebeeCouponQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportChargebeeCouponQuery = {
  platform_coupon_by_pk?: {
    __typename: 'platform_coupon',
    platform_id: string
  } | null
};

export type ImportChargebeeSubscriptionQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportChargebeeSubscriptionQuery = {
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_id: string
  } | null
};

export type ChargifyApplyCouponQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformCouponId: Scalars['String']['input'];
}>;


export type ChargifyApplyCouponQuery = {
  platform_coupon_by_pk?: {
    __typename: 'platform_coupon',
    code?: string | null
  } | null
};

export type ChargifyChangePlanQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformPlanId: Scalars['String']['input'];
}>;


export type ChargifyChangePlanQuery = {
  platform_plan_by_pk?: {
    __typename: 'platform_plan',
    platform_product_id: string
  } | null
};

export type ImportChargifyCouponQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformProductGroupId: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type ImportChargifyCouponQuery = {
  platform_coupon: Array<{
    __typename: 'platform_coupon',
    platform_id: string
  }>
};

export type ImportChargifyLastTransactionIdMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  data: Scalars['jsonb']['input'];
}>;


export type ImportChargifyLastTransactionIdMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type CreateCouponMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type CreateCouponMutation = {
  update_offer_coupon_by_pk?: {
    __typename: 'offer_coupon',
    offer_id: number
  } | null
};

export type CustomImportSubscriberCampaignQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type CustomImportSubscriberCampaignQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    status?: subscriber_status_enum | null,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string
    }>
  }>
};

export type CustomImportSubscriberCampaignMutationVariables = Exact<{
  input: subscriber_insert_input;
}>;


export type CustomImportSubscriberCampaignMutation = {
  insert_subscriber_one?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type CustomImportSubscriberFlowQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
}>;


export type CustomImportSubscriberFlowQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    currency: platform_base_currency_enum,
    platform_base_currency: {
      __typename: 'platform_base_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        base: platform_base_currency_enum,
        currency: platform_currency_enum,
        rate: string
      }>
    }
  } | null,
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    status?: subscriber_status_enum | null,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string
    }>
  }>
};

export type CustomImportSubscriberFlowMutationVariables = Exact<{
  input: subscriber_insert_input;
  subscriberUpdateColumns: Array<subscriber_update_column> | subscriber_update_column;
}>;


export type CustomImportSubscriberFlowMutation = {
  insert_subscriber_one?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type ImportCustomerCustomerQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportCustomerCustomerQuery = {
  platform_customer_by_pk?: {
    __typename: 'platform_customer',
    platform_connection_id: number,
    platform_id: string,
    name: string,
    email: string,
    platform_connection: {
      __typename: 'platform_connection',
      id: number,
      account_id: number,
      platform: platform_enum
    },
    platform_subscriptions: Array<{
      __typename: 'platform_subscription',
      platform_connection_id: number,
      platform_id: string,
      platform_customer_id: string,
      status: platform_subscription_status_enum,
      started_at: string,
      canceled_at?: string | null,
      trial_end?: string | null,
      updated_at: string,
      next_billing_at?: string | null,
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        quantity: string,
        price?: string | null,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        platform_plan?: {
          __typename: 'platform_plan',
          platform_connection_id: number,
          platform_id: string,
          platform_product_id: string,
          name: string,
          currency: platform_currency_enum,
          interval: platform_plan_interval_enum,
          interval_count: number,
          pricing_model: platform_plan_pricing_model_enum,
          platform_plan_tiers: Array<{
            __typename: 'platform_plan_tier',
            up_to: number,
            price: string,
            flat_fee: string
          }>,
          platform_product?: {
            __typename: 'platform_product',
            platform_connection_id: number,
            platform_id: string,
            name: string
          } | null,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null
      }>,
      platform_subscription_addons: Array<{
        __typename: 'platform_subscription_addon',
        quantity: string,
        price?: string | null,
        platform_addon?: {
          __typename: 'platform_addon',
          platform_connection_id: number,
          platform_id: string,
          name: string,
          platform_addon_prices: Array<{
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_addon_price?: {
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        } | null,
        platform_subscription?: {
          __typename: 'platform_subscription',
          platform_subscription_plans: Array<{
            __typename: 'platform_subscription_plan',
            quantity: string,
            price?: string | null,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            platform_plan?: {
              __typename: 'platform_plan',
              platform_connection_id: number,
              platform_id: string,
              platform_product_id: string,
              name: string,
              currency: platform_currency_enum,
              interval: platform_plan_interval_enum,
              interval_count: number,
              pricing_model: platform_plan_pricing_model_enum,
              platform_plan_tiers: Array<{
                __typename: 'platform_plan_tier',
                up_to: number,
                price: string,
                flat_fee: string
              }>,
              platform_product?: {
                __typename: 'platform_product',
                platform_connection_id: number,
                platform_id: string,
                name: string
              } | null,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            } | null
          }>
        } | null
      }>,
      platform_invoices: Array<{
        __typename: 'platform_invoice',
        platform_connection_id: number,
        platform_id: string,
        platform_subscription_id: string,
        status: platform_invoice_status_enum,
        amount_paid: string,
        total: string,
        period_end: string,
        number?: string | null
      }>
    }>
  } | null
};

export type ImportCustomerExistingSubscriberQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportCustomerExistingSubscriberQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    status?: subscriber_status_enum | null
  }>
};

export type ImportCustomerExistingProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ImportCustomerExistingProductsQuery = {
  product: Array<{
    __typename: 'product',
    id: number,
    platform_id: string
  }>
};

export type ImportCustomerProductsMutationVariables = Exact<{
  input: Array<product_insert_input> | product_insert_input;
}>;


export type ImportCustomerProductsMutation = {
  insert_product?: {
    __typename: 'product_mutation_response',
    returning: Array<{
      __typename: 'product',
      id: number,
      platform_id: string
    }>
  } | null
};

export type ImportCustomerSubscriptionsMutationVariables = Exact<{
  input: Array<subscription_insert_input> | subscription_insert_input;
}>;


export type ImportCustomerSubscriptionsMutation = {
  insert_subscription?: {
    __typename: 'subscription_mutation_response',
    affected_rows: number
  } | null
};

export type ImportCustomerSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  activeMrr: Scalars['numeric']['input'];
  inactiveMrr: Scalars['numeric']['input'];
}>;


export type ImportCustomerSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type ImportPlatformQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  startingAfter: Scalars['String']['input'];
}>;


export type ImportPlatformQuery = {
  platform_customer: Array<{
    __typename: 'platform_customer',
    platform_id: string
  }>
};

export type NavigaCancelSubscriptionSubscriberFlowQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type NavigaCancelSubscriptionSubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type ImportNavigaPlanQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  offerCode: Scalars['String']['input'];
  offerCodeUpper: Scalars['String']['input'];
}>;


export type ImportNavigaPlanQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string
  }>
};

export type NavigaImportSubscriptionPlanCodeQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  offerCode: Scalars['String']['input'];
  offerCodeUpper: Scalars['String']['input'];
}>;


export type NavigaImportSubscriptionPlanCodeQuery = {
  platform_plan: Array<{
    __typename: 'platform_plan',
    platform_id: string
  }>
};

export type ImportOpenPaySetProductProductGroupIdMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
  productGroupId: Scalars['String']['input'];
}>;


export type ImportOpenPaySetProductProductGroupIdMutation = {
  update_platform_product_by_pk?: {
    __typename: 'platform_product',
    platform_connection_id: number,
    platform_id: string
  } | null
};

export type PaddleApplyCouponQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  couponId: Scalars['String']['input'];
}>;


export type PaddleApplyCouponQuery = {
  platform_coupon_by_pk?: {
    __typename: 'platform_coupon',
    amount_off: string,
    duration: platform_coupon_duration_enum
  } | null
};

export type ImportPaddleSavedAndDeflectedSubscribersQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  afterId: Scalars['Int']['input'];
}>;


export type ImportPaddleSavedAndDeflectedSubscribersQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string
    }>
  }>
};

export type RawObjectBatchIteratorQueryVariables = Exact<{
  where: platform_raw_object_bool_exp;
  limit: Scalars['Int']['input'];
}>;


export type RawObjectBatchIteratorQuery = {
  platform_raw_object: Array<{
    __typename: 'platform_raw_object',
    platform_connection_id: number,
    type: platform_raw_object_type_enum,
    platform_id: string,
    data: any
  }>
};

export type RechargeCancelSubscriptionSubscriberFlowQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type RechargeCancelSubscriptionSubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type RunPlatformImportUserQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type RunPlatformImportUserQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    account: {
      __typename: 'account',
      owner?: {
        __typename: 'user',
        email?: string | null
      } | null
    }
  } | null
};

export type SyncSubscriberMetadataQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type SyncSubscriberMetadataQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number,
    platform_customer?: {
      __typename: 'platform_customer',
      metadata?: any | null
    } | null,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null
};

export type SyncSubscriptionMetadataQueryVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
}>;


export type SyncSubscriptionMetadataQuery = {
  subscription_by_pk?: {
    __typename: 'subscription',
    id: number,
    platform_subscription?: {
      __typename: 'platform_subscription',
      metadata?: any | null
    } | null,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum
      }>
    }
  } | null
};

export type ImportZuoraSavedAndDeflectedSubscribersQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  afterId: Scalars['Int']['input'];
}>;


export type ImportZuoraSavedAndDeflectedSubscribersQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string
    }>
  }>
};

export type ImportZuoraPlanQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type ImportZuoraPlanQuery = {
  platform_plan_by_pk?: {
    __typename: 'platform_plan',
    platform_id: string
  } | null
};

export type FindSubscribersByEmailForIntegrationQueryVariables = Exact<{
  subscriberIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type FindSubscribersByEmailForIntegrationQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number,
      status: subscriber_flow_status_enum,
      completed_at?: string | null,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question_id: number,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string
          } | null,
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option: {
              __typename: 'question_option',
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          }>,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>
    }>
  }>
};

export type AccountQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type AccountQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    title: string,
    mode: account_mode_enum,
    status: account_status_enum,
    stripe_customer_id: string,
    stripe_subscription_id: string,
    created_at: string,
    updated_at: string,
    prosperstack: boolean,
    test_mode_for_account_id?: number | null,
    enterprise: boolean,
    sso_required: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  } | null
};

export type GetAccountCurrencyExchangeRateQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  currency: platform_currency_enum;
}>;


export type GetAccountCurrencyExchangeRateQuery = {
  account_by_pk?: {
    __typename: 'account',
    platform_base_currency: {
      __typename: 'platform_base_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        rate: string
      }>
    }
  } | null
};

export type GetAccountIntegrationQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  type: integration_type_enum;
}>;


export type GetAccountIntegrationQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  }>
};

export type GetAccountOwnerQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type GetAccountOwnerQuery = {
  account_by_pk?: {
    __typename: 'account',
    prosperstack: boolean,
    owner?: {
      __typename: 'user',
      id: string,
      name?: string | null,
      given_name?: string | null,
      family_name?: string | null,
      email?: string | null,
      picture?: string | null,
      created_at: string,
      updated_at: string,
      user_profiles: Array<{
        __typename: 'user_profile',
        first_name: string,
        last_name: string,
        email: string
      }>,
      account_users: Array<{
        __typename: 'account_user',
        account: {
          __typename: 'account',
          id: number,
          title: string,
          status: account_status_enum,
          mode: account_mode_enum,
          owner_user_id?: string | null,
          stripe_customer_id: string,
          stripe_subscription_id: string,
          test_mode_for_account_id?: number | null,
          created_at: string,
          internal_testing: boolean,
          platform_connection?: {
            __typename: 'platform_connection',
            id: number,
            platform: platform_enum
          } | null
        }
      }>
    } | null,
    test_mode_for_account?: {
      __typename: 'account',
      id: number,
      owner?: {
        __typename: 'user',
        id: string,
        name?: string | null,
        given_name?: string | null,
        family_name?: string | null,
        email?: string | null,
        picture?: string | null,
        created_at: string,
        updated_at: string,
        user_profiles: Array<{
          __typename: 'user_profile',
          first_name: string,
          last_name: string,
          email: string
        }>,
        account_users: Array<{
          __typename: 'account_user',
          account: {
            __typename: 'account',
            id: number,
            title: string,
            status: account_status_enum,
            mode: account_mode_enum,
            owner_user_id?: string | null,
            stripe_customer_id: string,
            stripe_subscription_id: string,
            test_mode_for_account_id?: number | null,
            created_at: string,
            internal_testing: boolean,
            platform_connection?: {
              __typename: 'platform_connection',
              id: number,
              platform: platform_enum
            } | null
          }
        }>
      } | null
    } | null
  } | null
};

export type AccountSettingQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  key: account_setting_key_enum;
}>;


export type AccountSettingQuery = {
  account_setting_by_pk?: {
    __typename: 'account_setting',
    value: any
  } | null
};

export type GetPlatformConnectionQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetPlatformConnectionQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    platform_id: string,
    account_id: number,
    platform: platform_enum,
    data: any,
    data_text?: string | null,
    import_started_at?: string | null,
    import_completed_at?: string | null,
    last_synced_at?: string | null,
    sync_disabled: boolean,
    options: any,
    meta: any,
    publishable_key?: string | null,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      token: string,
      title: string,
      prosperstack: boolean,
      test_mode_for_account_id?: number | null,
      Pauses: Array<{
        __typename: 'offer',
        id: number
      }>
    },
    platform_cancel_reasons: Array<{
      __typename: 'platform_cancel_reason',
      code: string,
      paper_code: string
    }>,
    naviga_paper_codes: Array<{
      __typename: 'naviga_paper_code',
      client_code: string,
      paper_code: string,
      platform_connection_naviga_paper_code_endpoint?: {
        __typename: 'platform_connection_naviga_paper_code_endpoint',
        endpoint: string,
        access_controlled: boolean
      } | null
    }>
  } | null
};

export type GetPlatformPlanQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type GetPlatformPlanQuery = {
  platform_plan_by_pk?: {
    __typename: 'platform_plan',
    platform_connection_id: number,
    platform_id: string,
    platform_product_id: string,
    name: string,
    currency: platform_currency_enum,
    interval: platform_plan_interval_enum,
    interval_count: number,
    pricing_model: platform_plan_pricing_model_enum,
    platform_plan_tiers: Array<{
      __typename: 'platform_plan_tier',
      up_to: number,
      price: string,
      flat_fee: string
    }>,
    platform_product?: {
      __typename: 'platform_product',
      platform_connection_id: number,
      platform_id: string,
      name: string
    } | null,
    platform_currency: {
      __typename: 'platform_currency',
      platform_currency_exchange_rates: Array<{
        __typename: 'platform_currency_exchange_rate',
        base: platform_base_currency_enum,
        rate: string
      }>
    },
    platform_connection: {
      __typename: 'platform_connection',
      account: {
        __typename: 'account',
        currency: platform_base_currency_enum
      }
    }
  } | null
};

export type GetPlatformRawObjectQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  type: platform_raw_object_type_enum;
  platformId: Scalars['String']['input'];
}>;


export type GetPlatformRawObjectQuery = {
  platform_raw_object_by_pk?: {
    __typename: 'platform_raw_object',
    data: any
  } | null
};

export type GetPlatformSubscriptionQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type GetPlatformSubscriptionQuery = {
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_customer_id: string,
    status: platform_subscription_status_enum,
    started_at: string,
    canceled_at?: string | null,
    trial_end?: string | null,
    updated_at: string,
    next_billing_at?: string | null,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string,
      price?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_connection_id: number,
        platform_id: string,
        platform_product_id: string,
        name: string,
        currency: platform_currency_enum,
        interval: platform_plan_interval_enum,
        interval_count: number,
        pricing_model: platform_plan_pricing_model_enum,
        platform_plan_tiers: Array<{
          __typename: 'platform_plan_tier',
          up_to: number,
          price: string,
          flat_fee: string
        }>,
        platform_product?: {
          __typename: 'platform_product',
          platform_connection_id: number,
          platform_id: string,
          name: string
        } | null,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null
    }>,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      quantity: string,
      price?: string | null,
      platform_addon?: {
        __typename: 'platform_addon',
        platform_connection_id: number,
        platform_id: string,
        name: string,
        platform_addon_prices: Array<{
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_addon_price?: {
        __typename: 'platform_addon_price',
        currency: platform_currency_enum,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        pricing_model: platform_plan_pricing_model_enum,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_addon_price_tiers: Array<{
          __typename: 'platform_addon_price_tier',
          up_to: number,
          price: string
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>
      } | null
    }>,
    platform_invoices: Array<{
      __typename: 'platform_invoice',
      platform_connection_id: number,
      platform_id: string,
      platform_subscription_id: string,
      status: platform_invoice_status_enum,
      amount_paid: string,
      total: string,
      period_end: string,
      number?: string | null
    }>
  } | null
};

export type GetSavedAndDeflectedSubscribersQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  afterId: Scalars['Int']['input'];
}>;


export type GetSavedAndDeflectedSubscribersQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    platform_id: string,
    subscriptions: Array<{
      __typename: 'subscription',
      id: number,
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        paper_code?: string | null
      } | null
    }>
  }>
};

export type GetSubscriberMrrQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type GetSubscriberMrrQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    status?: subscriber_status_enum | null
  } | null,
  active: {
    __typename: 'subscription_aggregate',
    aggregate?: {
      __typename: 'subscription_aggregate_fields',
      sum?: {
        __typename: 'subscription_sum_fields',
        mrr?: string | null
      } | null
    } | null
  },
  canceledSubscriptions: Array<{
    __typename: 'subscription',
    id: number,
    mrr: string,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      occurred_at: string
    }>
  }>,
  pausedSubscriptions: Array<{
    __typename: 'subscription',
    id: number,
    mrr: string,
    subscriber_logs: Array<{
      __typename: 'subscriber_log',
      id: number,
      event: subscriber_log_event_enum,
      occurred_at: string
    }>
  }>
};

export type GetSubscriptionByPlatformIdQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  includeCanceledSessions: Scalars['Boolean']['input'];
}>;


export type GetSubscriptionByPlatformIdQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    subscriber_id: number,
    status: subscription_status_enum,
    mrr: string,
    platform_id: string,
    updated_at: string,
    canceled_sessions?: Array<{
      __typename: 'subscriber_flow',
      id: number
    }>,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      account_id: number,
      name: string,
      email: string,
      active_mrr: string,
      inactive_mrr: string,
      active_offer_id?: number | null,
      active_offer_group_id?: number | null,
      platform_id: string,
      status?: subscriber_status_enum | null
    },
    platform_subscription?: {
      __typename: 'platform_subscription',
      can_cancel: boolean,
      cancel_at_period_end?: boolean | null
    } | null
  }>
};

export type SurveyEmailMetaQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SurveyEmailMetaQuery = {
  companyName?: {
    __typename: 'account_setting',
    value: any
  } | null,
  firstName?: {
    __typename: 'account_setting',
    value: any
  } | null,
  lastName?: {
    __typename: 'account_setting',
    value: any
  } | null,
  title?: {
    __typename: 'account_setting',
    value: any
  } | null,
  fromName?: {
    __typename: 'account_setting',
    value: any
  } | null,
  replyTo?: {
    __typename: 'account_setting',
    value: any
  } | null
};

export type GetTestModeAccountQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type GetTestModeAccountQuery = {
  account: Array<{
    __typename: 'account',
    id: number,
    title: string,
    mode: account_mode_enum,
    status: account_status_enum,
    stripe_customer_id: string,
    stripe_subscription_id: string,
    created_at: string,
    updated_at: string,
    prosperstack: boolean,
    test_mode_for_account_id?: number | null,
    enterprise: boolean,
    sso_required: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  }>
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetUserQuery = {
  user_by_pk?: {
    __typename: 'user',
    id: string,
    name?: string | null,
    given_name?: string | null,
    family_name?: string | null,
    email?: string | null,
    picture?: string | null,
    created_at: string,
    updated_at: string,
    user_profiles: Array<{
      __typename: 'user_profile',
      first_name: string,
      last_name: string,
      email: string
    }>,
    account_users: Array<{
      __typename: 'account_user',
      account: {
        __typename: 'account',
        id: number,
        title: string,
        status: account_status_enum,
        mode: account_mode_enum,
        owner_user_id?: string | null,
        stripe_customer_id: string,
        stripe_subscription_id: string,
        test_mode_for_account_id?: number | null,
        created_at: string,
        internal_testing: boolean,
        platform_connection?: {
          __typename: 'platform_connection',
          id: number,
          platform: platform_enum
        } | null
      }
    }>
  } | null
};

export type StartOrResumePlatformImportQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type StartOrResumePlatformImportQuery = {
  platform_import: Array<{
    __typename: 'platform_import',
    job_id: string,
    platform_connection_id: number,
    initial: boolean,
    status: platform_import_status_enum,
    started_at?: string | null,
    completed_at?: string | null,
    failed_at?: string | null,
    created_at: string,
    updated_at: string,
    retries: number,
    platform_import_steps: Array<{
      __typename: 'platform_import_step',
      job_id: string,
      type: platform_import_step_type_enum,
      cursor?: any | null,
      started_at: string,
      completed_at?: string | null
    }>
  }>
};

export type BelongsToSegmentQueryVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  segmentId: Scalars['Int']['input'];
}>;


export type BelongsToSegmentQuery = {
  subscription_by_pk?: {
    __typename: 'subscription',
    subscriber_id: number
  } | null,
  segment_by_pk?: {
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type MatchSegmentByPropertyValuesQueryVariables = Exact<{
  segmentId: Scalars['Int']['input'];
}>;


export type MatchSegmentByPropertyValuesQuery = {
  segment_by_pk?: {
    __typename: 'segment',
    id: number,
    token: string,
    name: string,
    integration_id?: number | null,
    integration_segment_id?: string | null,
    created_at: string,
    primary_segment_condition_group?: {
      __typename: 'segment_condition_group',
      id: number,
      boolean_operator: segment_condition_boolean_operator_enum,
      segment_condition_group_entries: Array<{
        __typename: 'segment_condition_group_entry',
        entry_segment_condition_group?: {
          __typename: 'segment_condition_group',
          id: number,
          boolean_operator: segment_condition_boolean_operator_enum,
          segment_condition_group_entries: Array<{
            __typename: 'segment_condition_group_entry',
            entry_segment_condition?: {
              __typename: 'segment_condition',
              id: number,
              property: segment_condition_property_enum,
              operator: segment_condition_operator_enum,
              value: any,
              property_id?: number | null,
              custom_property?: {
                __typename: 'property',
                id: number,
                name: string,
                entity: property_entity_enum,
                type: property_type_enum,
                format?: property_format_enum | null
              } | null
            } | null
          }>
        } | null,
        entry_segment_condition?: {
          __typename: 'segment_condition',
          id: number,
          property: segment_condition_property_enum,
          operator: segment_condition_operator_enum,
          value: any,
          property_id?: number | null,
          custom_property?: {
            __typename: 'property',
            id: number,
            name: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null
          } | null
        } | null
      }>
    } | null,
    integration?: {
      __typename: 'integration',
      id: number,
      type: integration_type_enum
    } | null,
    segment_tags: Array<{
      __typename: 'segment_tag',
      tag?: {
        __typename: 'tag',
        id: number,
        name: string
      } | null
    }>
  } | null
};

export type StartFlowStepQueryVariables = Exact<{
  subscriberFlowToken: Scalars['String']['input'];
  flowStepToken: Scalars['String']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type StartFlowStepQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    subscriber: {
      __typename: 'subscriber',
      account_id: number,
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  }>,
  flow_step: Array<{
    __typename: 'flow_step',
    published_version_id?: number | null,
    id: number,
    token: string,
    type: flow_step_type_enum,
    published_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    draft_version?: {
      __typename: 'flow_step_version',
      id: number,
      flow_step_id: number,
      condition_segment_group_ids: any,
      condition_segment_ids: any,
      condition_question_option_ids: any,
      condition_include_other_in_question_ids: any,
      flow_step_version_condition_segment_groups: Array<{
        __typename: 'flow_step_version_condition_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_step_version_condition_segments: Array<{
        __typename: 'flow_step_version_condition_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_step_version_condition_question_options: Array<{
        __typename: 'flow_step_version_condition_question_option',
        question_option_id?: number | null,
        question_option?: {
          __typename: 'question_option',
          id: number,
          question_id: number,
          published_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        } | null
      }>
    } | null,
    flow_step_acknowledgement_group?: {
      __typename: 'flow_step_acknowledgement_group',
      flow_step_id: number,
      acknowledgement_group_id: number,
      acknowledgement_group: {
        __typename: 'acknowledgement_group',
        id: number,
        published_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'acknowledgement_group_version',
          id: number,
          acknowledgement_group_id: number,
          acknowledgement_ids: any,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          acknowledgement_group_version_acknowledgements: Array<{
            __typename: 'acknowledgement_group_version_acknowledgement',
            acknowledgement_group_version_id?: number | null,
            acknowledgement_id?: number | null,
            acknowledgement?: {
              __typename: 'acknowledgement',
              id: number,
              title: string,
              published_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'acknowledgement_version',
                id: number,
                acknowledgement_id: number,
                label_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_form?: {
      __typename: 'flow_step_form',
      flow_step_id: number,
      form_id: number,
      form: {
        __typename: 'form',
        id: number,
        title: string,
        published_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'form_version',
          id: number,
          form_id: number,
          question_ids: any,
          form_version_questions: Array<{
            __typename: 'form_version_question',
            question_id?: number | null,
            form_version_id?: number | null,
            question?: {
              __typename: 'question',
              id: number,
              token: string,
              type: question_type_enum,
              primary: boolean,
              published_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'question_version',
                id: number,
                question_id: number,
                required: boolean,
                title_translation_id: number,
                hint_translation_id: number,
                placeholder_translation_id?: number | null,
                condition_segment_ids?: any | null,
                condition_question_option_ids?: any | null,
                condition_include_other_in_question_ids?: any | null,
                question_option_ids?: any | null,
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                hint_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                placeholder_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null,
                question_version_question_options: Array<{
                  __typename: 'question_version_question_option',
                  question_option_id?: number | null,
                  question_version_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    token: string,
                    reason_code?: string | null,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      question_option_id: number,
                      title_translation_id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_question_options: Array<{
                  __typename: 'question_version_condition_question_option',
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                question_version_condition_segments: Array<{
                  __typename: 'question_version_condition_segment',
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                question_version_condition_segment_groups: Array<{
                  __typename: 'question_version_condition_segment_group',
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>
              } | null,
              question_settings: Array<{
                __typename: 'question_setting',
                question_id: number,
                key: question_setting_key_enum,
                published_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null,
                draft_version?: {
                  __typename: 'question_setting_version',
                  id: number,
                  question_id: number,
                  key: question_setting_key_enum,
                  value: any
                } | null
              }>
            } | null
          }>
        } | null
      }
    } | null,
    flow_step_question?: {
      __typename: 'flow_step_question',
      flow_step_id: number,
      question_id: number,
      question: {
        __typename: 'question',
        id: number,
        token: string,
        type: question_type_enum,
        primary: boolean,
        published_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'question_version',
          id: number,
          question_id: number,
          required: boolean,
          title_translation_id: number,
          hint_translation_id: number,
          placeholder_translation_id?: number | null,
          condition_segment_ids?: any | null,
          condition_question_option_ids?: any | null,
          condition_include_other_in_question_ids?: any | null,
          question_option_ids?: any | null,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          hint_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          placeholder_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null,
          question_version_question_options: Array<{
            __typename: 'question_version_question_option',
            question_option_id?: number | null,
            question_version_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              token: string,
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                question_option_id: number,
                title_translation_id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_question_options: Array<{
            __typename: 'question_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>,
          question_version_condition_segments: Array<{
            __typename: 'question_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          question_version_condition_segment_groups: Array<{
            __typename: 'question_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>
        } | null,
        question_settings: Array<{
          __typename: 'question_setting',
          question_id: number,
          key: question_setting_key_enum,
          published_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null,
          draft_version?: {
            __typename: 'question_setting_version',
            id: number,
            question_id: number,
            key: question_setting_key_enum,
            value: any
          } | null
        }>
      }
    } | null,
    flow_step_deflection_rule_group?: {
      __typename: 'flow_step_deflection_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string,
        published_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'offer_rule_group_version',
          id: number,
          offer_rule_group_id: number,
          offer_rule_ids: any,
          offer_rule_group_version_offer_rules: Array<{
            __typename: 'offer_rule_group_version_offer_rule',
            offer_rule?: {
              __typename: 'offer_rule',
              id: number,
              published_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null,
              draft_version?: {
                __typename: 'offer_rule_version',
                id: number,
                offer_rule_id: number,
                segment_group_ids: any,
                segment_ids: any,
                question_option_ids: any,
                include_present_no_offer: boolean,
                include_other_in_question_ids: any,
                offer_rule_rule_ids: any,
                offer_rule_version_segment_groups: Array<{
                  __typename: 'offer_rule_version_segment_group',
                  offer_rule_version_id?: number | null,
                  segment_group_id?: number | null,
                  segment_group?: {
                    __typename: 'segment_group',
                    id: number,
                    name: string,
                    segment_group_segments: Array<{
                      __typename: 'segment_group_segment',
                      operator: segment_group_operator_enum,
                      segment: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      }
                    }>
                  } | null
                }>,
                offer_rule_version_segments: Array<{
                  __typename: 'offer_rule_version_segment',
                  offer_rule_version_id?: number | null,
                  segment_id?: number | null,
                  segment?: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  } | null
                }>,
                offer_rule_version_question_options: Array<{
                  __typename: 'offer_rule_version_question_option',
                  offer_rule_version_id?: number | null,
                  question_option_id?: number | null,
                  question_option?: {
                    __typename: 'question_option',
                    id: number,
                    question_id: number,
                    published_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null,
                    draft_version?: {
                      __typename: 'question_option_version',
                      id: number,
                      title_translation: {
                        __typename: 'translation',
                        id: number,
                        translation_values: Array<{
                          __typename: 'translation_value',
                          translation_id: number,
                          language: language_enum,
                          value: any,
                          format?: translation_value_format_enum | null
                        }>
                      }
                    } | null
                  } | null
                }>,
                offer_rule_version_offer_rule_rules: Array<{
                  __typename: 'offer_rule_version_offer_rule_rule',
                  offer_rule_version_id?: number | null,
                  offer_rule_rule_id?: number | null,
                  offer_rule_rule?: {
                    __typename: 'offer_rule_rule',
                    id: number,
                    offer_rule_id: number,
                    deflection_ids: any,
                    include_present_no_offer: boolean,
                    weight: number,
                    offer_rule_rule_deflections: Array<{
                      __typename: 'offer_rule_rule_deflection',
                      offer_rule_rule_id?: number | null,
                      deflection_id?: number | null,
                      deflection?: {
                        __typename: 'deflection',
                        id: number,
                        title: string,
                        token: string,
                        minimum_items?: number | null,
                        heading_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        },
                        content_translation?: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        } | null,
                        deflection_snapshot_items: Array<{
                          __typename: 'deflection_snapshot_item_position',
                          position?: number | null,
                          deflection_snapshot_item?: {
                            __typename: 'deflection_snapshot_item',
                            id: number,
                            token: string,
                            icon_prefix?: string | null,
                            icon_name?: string | null,
                            property_id?: number | null,
                            property_condition_value?: any | null,
                            date_format?: string | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            property?: {
                              __typename: 'property',
                              id: number,
                              name: string,
                              entity: property_entity_enum,
                              type: property_type_enum,
                              format?: property_format_enum | null
                            } | null
                          } | null
                        }>,
                        deflection_flow_actions: Array<{
                          __typename: 'deflection_flow_action',
                          position?: number | null,
                          flow_action?: {
                            __typename: 'flow_action',
                            id: number,
                            token: string,
                            code?: string | null,
                            type: flow_action_type_enum,
                            account_id: number,
                            appearance: flow_action_appearance_enum,
                            url?: string | null,
                            reroute_to_flow_id?: number | null,
                            text_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            }
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                }>
              } | null
            } | null
          }>
        } | null
      } | null
    } | null,
    flow_step_offer_rule_group?: {
      __typename: 'flow_step_offer_rule_group',
      flow_step_id?: number | null,
      offer_rule_group_id?: number | null,
      offer_rule_group?: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      } | null
    } | null,
    flow_step_rule_group?: {
      __typename: 'flow_step_rule_group',
      flow_step_id: number,
      offer_rule_group_id: number,
      offer_rule_group: {
        __typename: 'offer_rule_group',
        id: number,
        title: string
      }
    } | null,
    flow_step_confirmation?: {
      __typename: 'flow_step_confirmation',
      flow_step_id: number,
      confirmation_id: number,
      confirmation: {
        __typename: 'confirmation',
        id: number,
        published_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'confirmation_version',
          id: number,
          confirmation_id: number,
          show_on_cancel: boolean,
          show_on_save: boolean,
          saved_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          saved_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_headline_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          canceled_content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null,
    flow_step_intervention?: {
      __typename: 'flow_step_intervention',
      flow_step_id: number,
      intervention_id: number,
      intervention: {
        __typename: 'intervention',
        id: number,
        token: string,
        title: string,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        },
        published_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null,
        draft_version?: {
          __typename: 'intervention_version',
          id: number,
          intervention_id: number,
          content_translation_id: number,
          continue_button_translation_id: number,
          cancel_button_translation_id: number,
          content_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          continue_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          },
          cancel_button_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        } | null
      }
    } | null
  }>
};

export type StartFlowStepMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  object: subscriber_flow_flow_step_insert_input;
}>;


export type StartFlowStepMutation = {
  update_subscriber_flow?: {
    __typename: 'subscriber_flow_mutation_response',
    returning: Array<{
      __typename: 'subscriber_flow',
      id: number,
      token: string,
      status: subscriber_flow_status_enum,
      automatically_deflected: boolean,
      url?: string | null,
      user_agent?: string | null,
      started_at?: string | null,
      completed_at?: string | null,
      created_at: string,
      updated_at: string,
      subscriber: {
        __typename: 'subscriber',
        account_id: number,
        id: number,
        platform_id: string,
        token: string,
        name: string,
        email: string,
        status?: subscriber_status_enum | null,
        created_at: string,
        updated_at: string,
        subscriber_properties: Array<{
          __typename: 'subscriber_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>
      },
      flow: {
        __typename: 'flow',
        id: number,
        token: string,
        title: string,
        created_at: string,
        updated_at: string
      },
      subscription: {
        __typename: 'subscription',
        id: number,
        token: string,
        platform_id: string,
        subscriber_id: number,
        mrr: string,
        status: subscription_status_enum,
        created_at: string,
        updated_at: string,
        subscription_properties: Array<{
          __typename: 'subscription_property',
          value: any,
          property: {
            __typename: 'property',
            id: number,
            key: string,
            token: string,
            entity: property_entity_enum,
            type: property_type_enum,
            format?: property_format_enum | null,
            name: string,
            created_at: string
          }
        }>,
        subscriber: {
          __typename: 'subscriber',
          token: string
        }
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      } | null,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            question_settings: Array<{
              __typename: 'question_setting',
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                value: any
              } | null
            }>
          },
          question_version: {
            __typename: 'question_version',
            id: number,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null
          },
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string,
            sentiment?: sentiment_enum | null
          } | null,
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option_id: number,
            question_option_version: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            },
            question_option: {
              __typename: 'question_option',
              id: number,
              token: string,
              reason_code?: string | null
            }
          }>,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>,
      subscriber_flow_offers: Array<{
        __typename: 'subscriber_flow_offer',
        status?: subscriber_flow_offer_status_enum | null,
        rescheduled_to?: string | null,
        offer: {
          __typename: 'offer',
          id: number,
          token: string,
          type: offer_type_enum,
          goal: offer_goal_enum,
          name: string,
          metadata: any,
          created_at: string,
          updated_at: string,
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            months?: number | null,
            apply_to: offer_coupon_apply_to_enum
          } | null,
          offer_change_plan?: {
            __typename: 'offer_change_plan',
            platform_plan_id?: string | null,
            prorate: boolean,
            offer_coupon_offer?: {
              __typename: 'offer',
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null
              } | null
            } | null
          } | null,
          offer_modify_subscription?: {
            __typename: 'offer_modify_subscription',
            prorate: boolean,
            offer_modify_subscription_options: Array<{
              __typename: 'offer_modify_subscription_option',
              type: offer_modify_subscription_option_type_enum,
              platform_plan_id?: string | null,
              platform_addon_id?: string | null,
              custom_plan_id?: string | null
            }>
          } | null,
          offer_trial_extension?: {
            __typename: 'offer_trial_extension',
            days: number
          } | null,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            pause_at: offer_pause_subscription_pause_at_enum,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null,
              metadata: any
            }>
          } | null
        },
        offer_pause_subscription_option?: {
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        } | null
      }>,
      subscriber_flow_offer_groups: Array<{
        __typename: 'subscriber_flow_offer_group',
        status?: subscriber_flow_offer_status_enum | null,
        offer_group: {
          __typename: 'offer_group',
          id: number,
          token: string,
          goal: offer_goal_enum,
          name: string,
          created_at: string,
          updated_at: string,
          offer_group_offers: Array<{
            __typename: 'offer_group_offer',
            position?: number | null,
            offer?: {
              __typename: 'offer',
              id: number,
              token: string,
              type: offer_type_enum,
              goal: offer_goal_enum,
              name: string,
              metadata: any,
              created_at: string,
              updated_at: string,
              offer_coupon?: {
                __typename: 'offer_coupon',
                platform_id?: string | null,
                type: offer_coupon_type_enum,
                amount_off: string,
                duration: coupon_duration_enum,
                months?: number | null,
                apply_to: offer_coupon_apply_to_enum
              } | null,
              offer_change_plan?: {
                __typename: 'offer_change_plan',
                platform_plan_id?: string | null,
                prorate: boolean,
                offer_coupon_offer?: {
                  __typename: 'offer',
                  offer_coupon?: {
                    __typename: 'offer_coupon',
                    platform_id?: string | null
                  } | null
                } | null
              } | null,
              offer_modify_subscription?: {
                __typename: 'offer_modify_subscription',
                prorate: boolean,
                offer_modify_subscription_options: Array<{
                  __typename: 'offer_modify_subscription_option',
                  type: offer_modify_subscription_option_type_enum,
                  platform_plan_id?: string | null,
                  platform_addon_id?: string | null,
                  custom_plan_id?: string | null
                }>
              } | null,
              offer_trial_extension?: {
                __typename: 'offer_trial_extension',
                days: number
              } | null,
              offer_pause_subscription?: {
                __typename: 'offer_pause_subscription',
                pause_at: offer_pause_subscription_pause_at_enum,
                offer_pause_subscription_options: Array<{
                  __typename: 'offer_pause_subscription_option',
                  id: number,
                  interval?: offer_pause_subscription_interval_enum | null,
                  interval_count?: number | null,
                  metadata: any
                }>
              } | null
            } | null
          }>
        }
      }>,
      subscriber_flow_deflections: Array<{
        __typename: 'subscriber_flow_deflection',
        deflection: {
          __typename: 'deflection',
          id: number,
          token: string,
          title: string,
          deflection_flow_actions: Array<{
            __typename: 'deflection_flow_action',
            flow_action?: {
              __typename: 'flow_action',
              id: number,
              type: flow_action_type_enum,
              token: string,
              code?: string | null,
              appearance: flow_action_appearance_enum,
              url?: string | null,
              reroute_to_flow?: {
                __typename: 'flow',
                token: string
              } | null
            } | null
          }>
        },
        clicked_flow_action?: {
          __typename: 'flow_action',
          id: number,
          type: flow_action_type_enum,
          token: string,
          code?: string | null,
          appearance: flow_action_appearance_enum,
          url?: string | null,
          reroute_to_flow?: {
            __typename: 'flow',
            token: string
          } | null
        } | null
      }>,
      subscriber_flow_flow_steps: Array<{
        __typename: 'subscriber_flow_flow_step',
        started_at: string,
        completed_at?: string | null,
        flow_step: {
          __typename: 'flow_step',
          id: number,
          token: string,
          type: flow_step_type_enum,
          flow_step_form?: {
            __typename: 'flow_step_form',
            form: {
              __typename: 'form',
              title: string
            }
          } | null,
          flow_step_question?: {
            __typename: 'flow_step_question',
            question: {
              __typename: 'question',
              published_version?: {
                __typename: 'question_version',
                title_translation?: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                } | null
              } | null
            }
          } | null,
          flow_step_deflection_rule_group?: {
            __typename: 'flow_step_deflection_rule_group',
            flow_step_id?: number | null,
            offer_rule_group_id?: number | null,
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              title: string
            } | null
          } | null,
          flow_step_acknowledgement_group?: {
            __typename: 'flow_step_acknowledgement_group',
            acknowledgement_group: {
              __typename: 'acknowledgement_group',
              published_version?: {
                __typename: 'acknowledgement_group_version',
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          } | null,
          flow_step_offer_rule_group?: {
            __typename: 'flow_step_offer_rule_group',
            offer_rule_group?: {
              __typename: 'offer_rule_group',
              title: string
            } | null
          } | null,
          flow_step_intervention?: {
            __typename: 'flow_step_intervention',
            intervention: {
              __typename: 'intervention',
              title: string
            }
          } | null
        }
      }>,
      rerouted_from_subscriber_flow?: {
        __typename: 'subscriber_flow',
        id: number,
        token: string
      } | null
    }>
  } | null,
  insert_subscriber_flow_flow_step_one?: {
    __typename: 'subscriber_flow_flow_step',
    subscriber_flow_id: number,
    flow_step_id: number
  } | null
};

export type StartFlowStepDeflectionMutationVariables = Exact<{
  object: subscriber_flow_deflection_insert_input;
}>;


export type StartFlowStepDeflectionMutation = {
  insert_subscriber_flow_deflection_one?: {
    __typename: 'subscriber_flow_deflection',
    subscriber_flow_id: number,
    deflection_id: number
  } | null
};

export type DataConnectorsProcessSubscriptionConnectorsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type DataConnectorsProcessSubscriptionConnectorsQuery = {
  account_by_pk?: {
    __typename: 'account',
    id: number,
    secret: string,
    create_missing_properties: boolean,
    data_connectors: Array<{
      __typename: 'data_connector',
      id: number,
      token: string,
      url: string
    }>
  } | null
};

export type DataConnectorsProcessSubscriptionQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  subscriptionId: Scalars['Int']['input'];
}>;


export type DataConnectorsProcessSubscriptionQuery = {
  subscription: Array<{
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    subscriber_id: number,
    mrr: string,
    status: subscription_status_enum,
    created_at: string,
    updated_at: string,
    subscriber: {
      __typename: 'subscriber',
      token: string,
      id: number,
      platform_id: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription_properties: Array<{
      __typename: 'subscription_property',
      value: any,
      property: {
        __typename: 'property',
        id: number,
        key: string,
        token: string,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        created_at: string
      }
    }>
  }>
};

export type AccountFeatureDeletedMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  key: account_feature_key_enum;
}>;


export type AccountFeatureDeletedMutation = {
  delete_account_feature_by_pk?: {
    __typename: 'account_feature',
    key: account_feature_key_enum
  } | null
};

export type AccountFeatureInsertedMutationVariables = Exact<{
  object: account_feature_insert_input;
}>;


export type AccountFeatureInsertedMutation = {
  insert_account_feature_one?: {
    __typename: 'account_feature',
    key: account_feature_key_enum
  } | null
};

export type CreateDefaultSegmentsMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CreateDefaultSegmentsMutation = {
  insert_segment?: {
    __typename: 'segment_mutation_response',
    affected_rows: number
  } | null
};

export type AccountInsertedFeaturesQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type AccountInsertedFeaturesQuery = {
  account_feature: Array<{
    __typename: 'account_feature',
    key: account_feature_key_enum,
    value: any
  }>
};

export type AccountInsertedTestAccountMutationVariables = Exact<{
  object: account_insert_input;
}>;


export type AccountInsertedTestAccountMutation = {
  insert_account_one?: {
    __typename: 'account',
    id: number
  } | null
};

export type AccountInsertedSetAccountDefaultFlowMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  flowId: Scalars['Int']['input'];
}>;


export type AccountInsertedSetAccountDefaultFlowMutation = {
  update_account_by_pk?: {
    __typename: 'account',
    id: number
  } | null
};

export type EventInsertedSubscriberFlowQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type EventInsertedSubscriberFlowQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum,
    created_at: string,
    subscriber: {
      __typename: 'subscriber',
      account_id: number,
      token: string
    },
    subscription: {
      __typename: 'subscription',
      token: string,
      mrr: string
    },
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      subscriber_flow_flow_step?: {
        __typename: 'subscriber_flow_flow_step',
        flow_step: {
          __typename: 'flow_step',
          token: string
        }
      } | null
    }>
  }>
};

export type EventInsertedUserSettingsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  key: account_user_setting_key_enum;
}>;


export type EventInsertedUserSettingsQuery = {
  account_user_setting: Array<{
    __typename: 'account_user_setting',
    account_id: number,
    user_id: string,
    key: account_user_setting_key_enum,
    value: any
  }>
};

export type EventInsertedSlackIntegrationsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type EventInsertedSlackIntegrationsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>
};

export type EventInsertedWebhooksQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  eventType: event_type_enum;
}>;


export type EventInsertedWebhooksQuery = {
  webhook: Array<{
    __typename: 'webhook',
    id: number
  }>
};

export type EventInsertedUsersQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type EventInsertedUsersQuery = {
  account_user_setting: Array<{
    __typename: 'account_user_setting',
    user_id: string
  }>
};

export type EventInsertedIntegrationsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type EventInsertedIntegrationsQuery = {
  slack: Array<{
    __typename: 'integration',
    id: number,
    options: any
  }>
};

export type CouponInsertedQueryVariables = Exact<{
  offerCouponId: Scalars['Int']['input'];
}>;


export type CouponInsertedQuery = {
  offer_coupon_by_pk?: {
    __typename: 'offer_coupon',
    id: number,
    offer_id: number,
    type: offer_coupon_type_enum,
    amount_off: string,
    duration: coupon_duration_enum,
    duration_interval?: coupon_duration_interval_enum | null,
    duration_count?: number | null,
    months?: number | null,
    platform_id?: string | null,
    platform_product_group_id?: string | null,
    offer: {
      __typename: 'offer',
      id: number,
      name: string,
      account: {
        __typename: 'account',
        platform_connection?: {
          __typename: 'platform_connection',
          id: number
        } | null
      }
    }
  } | null
};

export type PlatformCustomerUpdatedMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type PlatformCustomerUpdatedMutation = {
  update_subscriber?: {
    __typename: 'subscriber_mutation_response',
    affected_rows: number
  } | null
};

export type PlatformInvoiceInsertedSavedQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  periodEnd: Scalars['timestamptz']['input'];
}>;


export type PlatformInvoiceInsertedSavedQuery = {
  subscriber_log: Array<{
    __typename: 'subscriber_log',
    id: number
  }>
};

export type PlatformInvoiceInsertedSubscriberFlowsQueryVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  periodEnd: Scalars['timestamptz']['input'];
}>;


export type PlatformInvoiceInsertedSubscriberFlowsQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum
  }>
};

export type PlatformInvoiceInsertedDeflectedMutationVariables = Exact<{
  subscriberFlowIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type PlatformInvoiceInsertedDeflectedMutation = {
  update_subscriber_flow?: {
    __typename: 'subscriber_flow_mutation_response',
    affected_rows: number
  } | null,
  update_subscriber?: {
    __typename: 'subscriber_mutation_response',
    affected_rows: number
  } | null
};

export type PlatformInvoiceInsertedMutationVariables = Exact<{
  object: revenue_log_insert_input;
}>;


export type PlatformInvoiceInsertedMutation = {
  insert_revenue_log_one?: {
    __typename: 'revenue_log',
    id: number
  } | null
};

export type PlatformRefundInsertedQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformInvoiceId: Scalars['String']['input'];
}>;


export type PlatformRefundInsertedQuery = {
  revenue_log: Array<{
    __typename: 'revenue_log',
    platform_invoice_id: string,
    revenue_type: revenue_log_revenue_type_enum,
    offer_id?: number | null,
    subscription: {
      __typename: 'subscription',
      platform_id: string
    }
  }>,
  platform_invoice_by_pk?: {
    __typename: 'platform_invoice',
    currency: platform_currency_enum
  } | null
};

export type PlatformRefundInsertedMutationVariables = Exact<{
  object: revenue_log_insert_input;
}>;


export type PlatformRefundInsertedMutation = {
  insert_revenue_log_one?: {
    __typename: 'revenue_log',
    id: number
  } | null
};

export type PlatformSubscriptionInsertedQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
  platformCustomerId: Scalars['String']['input'];
}>;


export type PlatformSubscriptionInsertedQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    account_id: number,
    status?: subscriber_status_enum | null
  }>,
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_customer_id: string,
    status: platform_subscription_status_enum,
    started_at: string,
    canceled_at?: string | null,
    trial_end?: string | null,
    updated_at: string,
    next_billing_at?: string | null,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string,
      price?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_connection_id: number,
        platform_id: string,
        platform_product_id: string,
        name: string,
        currency: platform_currency_enum,
        interval: platform_plan_interval_enum,
        interval_count: number,
        pricing_model: platform_plan_pricing_model_enum,
        platform_plan_tiers: Array<{
          __typename: 'platform_plan_tier',
          up_to: number,
          price: string,
          flat_fee: string
        }>,
        platform_product?: {
          __typename: 'platform_product',
          platform_connection_id: number,
          platform_id: string,
          name: string
        } | null,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null
    }>,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      quantity: string,
      price?: string | null,
      platform_addon?: {
        __typename: 'platform_addon',
        platform_connection_id: number,
        platform_id: string,
        name: string,
        platform_addon_prices: Array<{
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_addon_price?: {
        __typename: 'platform_addon_price',
        currency: platform_currency_enum,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        pricing_model: platform_plan_pricing_model_enum,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_addon_price_tiers: Array<{
          __typename: 'platform_addon_price_tier',
          up_to: number,
          price: string
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>
      } | null
    }>,
    platform_invoices: Array<{
      __typename: 'platform_invoice',
      platform_connection_id: number,
      platform_id: string,
      platform_subscription_id: string,
      status: platform_invoice_status_enum,
      amount_paid: string,
      total: string,
      period_end: string,
      number?: string | null
    }>
  } | null
};

export type PlatformSubscriptionInsertedMutationVariables = Exact<{
  object: subscription_insert_input;
}>;


export type PlatformSubscriptionInsertedMutation = {
  insert_subscription_one?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type PlatformSubscriptionUpdatedQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type PlatformSubscriptionUpdatedQuery = {
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_connection_id: number,
    platform_id: string,
    platform_customer_id: string,
    status: platform_subscription_status_enum,
    started_at: string,
    canceled_at?: string | null,
    trial_end?: string | null,
    updated_at: string,
    next_billing_at?: string | null,
    platform_customer?: {
      __typename: 'platform_customer',
      platform_connection_id: number,
      platform_id: string,
      name: string,
      email: string,
      platform_subscriptions: Array<{
        __typename: 'platform_subscription',
        platform_connection_id: number,
        platform_id: string,
        platform_customer_id: string,
        status: platform_subscription_status_enum,
        started_at: string,
        canceled_at?: string | null,
        trial_end?: string | null,
        updated_at: string,
        next_billing_at?: string | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          quantity: string,
          price?: string | null,
          platform_addon?: {
            __typename: 'platform_addon',
            platform_connection_id: number,
            platform_id: string,
            name: string,
            platform_addon_prices: Array<{
              __typename: 'platform_addon_price',
              currency: platform_currency_enum,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              pricing_model: platform_plan_pricing_model_enum,
              platform_currency: {
                __typename: 'platform_currency',
                platform_currency_exchange_rates: Array<{
                  __typename: 'platform_currency_exchange_rate',
                  base: platform_base_currency_enum,
                  rate: string
                }>
              },
              platform_addon_price_tiers: Array<{
                __typename: 'platform_addon_price_tier',
                up_to: number,
                price: string
              }>,
              platform_connection: {
                __typename: 'platform_connection',
                account: {
                  __typename: 'account',
                  currency: platform_base_currency_enum
                }
              }
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_addon_price?: {
            __typename: 'platform_addon_price',
            currency: platform_currency_enum,
            interval?: platform_plan_interval_enum | null,
            interval_count?: number | null,
            pricing_model: platform_plan_pricing_model_enum,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_addon_price_tiers: Array<{
              __typename: 'platform_addon_price_tier',
              up_to: number,
              price: string
            }>,
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null,
          platform_subscription?: {
            __typename: 'platform_subscription',
            platform_subscription_plans: Array<{
              __typename: 'platform_subscription_plan',
              quantity: string,
              price?: string | null,
              interval?: platform_plan_interval_enum | null,
              interval_count?: number | null,
              platform_plan?: {
                __typename: 'platform_plan',
                platform_connection_id: number,
                platform_id: string,
                platform_product_id: string,
                name: string,
                currency: platform_currency_enum,
                interval: platform_plan_interval_enum,
                interval_count: number,
                pricing_model: platform_plan_pricing_model_enum,
                platform_plan_tiers: Array<{
                  __typename: 'platform_plan_tier',
                  up_to: number,
                  price: string,
                  flat_fee: string
                }>,
                platform_product?: {
                  __typename: 'platform_product',
                  platform_connection_id: number,
                  platform_id: string,
                  name: string
                } | null,
                platform_currency: {
                  __typename: 'platform_currency',
                  platform_currency_exchange_rates: Array<{
                    __typename: 'platform_currency_exchange_rate',
                    base: platform_base_currency_enum,
                    rate: string
                  }>
                },
                platform_connection: {
                  __typename: 'platform_connection',
                  account: {
                    __typename: 'account',
                    currency: platform_base_currency_enum
                  }
                }
              } | null
            }>
          } | null
        }>,
        platform_invoices: Array<{
          __typename: 'platform_invoice',
          platform_connection_id: number,
          platform_id: string,
          platform_subscription_id: string,
          status: platform_invoice_status_enum,
          amount_paid: string,
          total: string,
          period_end: string,
          number?: string | null
        }>
      }>
    } | null,
    platform_subscription_plans: Array<{
      __typename: 'platform_subscription_plan',
      quantity: string,
      price?: string | null,
      interval?: platform_plan_interval_enum | null,
      interval_count?: number | null,
      platform_plan?: {
        __typename: 'platform_plan',
        platform_connection_id: number,
        platform_id: string,
        platform_product_id: string,
        name: string,
        currency: platform_currency_enum,
        interval: platform_plan_interval_enum,
        interval_count: number,
        pricing_model: platform_plan_pricing_model_enum,
        platform_plan_tiers: Array<{
          __typename: 'platform_plan_tier',
          up_to: number,
          price: string,
          flat_fee: string
        }>,
        platform_product?: {
          __typename: 'platform_product',
          platform_connection_id: number,
          platform_id: string,
          name: string
        } | null,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null
    }>,
    platform_subscription_addons: Array<{
      __typename: 'platform_subscription_addon',
      quantity: string,
      price?: string | null,
      platform_addon?: {
        __typename: 'platform_addon',
        platform_connection_id: number,
        platform_id: string,
        name: string,
        platform_addon_prices: Array<{
          __typename: 'platform_addon_price',
          currency: platform_currency_enum,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          pricing_model: platform_plan_pricing_model_enum,
          platform_currency: {
            __typename: 'platform_currency',
            platform_currency_exchange_rates: Array<{
              __typename: 'platform_currency_exchange_rate',
              base: platform_base_currency_enum,
              rate: string
            }>
          },
          platform_addon_price_tiers: Array<{
            __typename: 'platform_addon_price_tier',
            up_to: number,
            price: string
          }>,
          platform_connection: {
            __typename: 'platform_connection',
            account: {
              __typename: 'account',
              currency: platform_base_currency_enum
            }
          }
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_addon_price?: {
        __typename: 'platform_addon_price',
        currency: platform_currency_enum,
        interval?: platform_plan_interval_enum | null,
        interval_count?: number | null,
        pricing_model: platform_plan_pricing_model_enum,
        platform_currency: {
          __typename: 'platform_currency',
          platform_currency_exchange_rates: Array<{
            __typename: 'platform_currency_exchange_rate',
            base: platform_base_currency_enum,
            rate: string
          }>
        },
        platform_addon_price_tiers: Array<{
          __typename: 'platform_addon_price_tier',
          up_to: number,
          price: string
        }>,
        platform_connection: {
          __typename: 'platform_connection',
          account: {
            __typename: 'account',
            currency: platform_base_currency_enum
          }
        }
      } | null,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          quantity: string,
          price?: string | null,
          interval?: platform_plan_interval_enum | null,
          interval_count?: number | null,
          platform_plan?: {
            __typename: 'platform_plan',
            platform_connection_id: number,
            platform_id: string,
            platform_product_id: string,
            name: string,
            currency: platform_currency_enum,
            interval: platform_plan_interval_enum,
            interval_count: number,
            pricing_model: platform_plan_pricing_model_enum,
            platform_plan_tiers: Array<{
              __typename: 'platform_plan_tier',
              up_to: number,
              price: string,
              flat_fee: string
            }>,
            platform_product?: {
              __typename: 'platform_product',
              platform_connection_id: number,
              platform_id: string,
              name: string
            } | null,
            platform_currency: {
              __typename: 'platform_currency',
              platform_currency_exchange_rates: Array<{
                __typename: 'platform_currency_exchange_rate',
                base: platform_base_currency_enum,
                rate: string
              }>
            },
            platform_connection: {
              __typename: 'platform_connection',
              account: {
                __typename: 'account',
                currency: platform_base_currency_enum
              }
            }
          } | null
        }>
      } | null
    }>,
    platform_invoices: Array<{
      __typename: 'platform_invoice',
      platform_connection_id: number,
      platform_id: string,
      platform_subscription_id: string,
      status: platform_invoice_status_enum,
      amount_paid: string,
      total: string,
      period_end: string,
      number?: string | null
    }>
  } | null,
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    account_id: number,
    platform: platform_enum
  } | null
};

export type PlatformSubscriptionUpdatedMutationVariables = Exact<{
  objects: Array<subscriber_log_insert_input> | subscriber_log_insert_input;
}>;


export type PlatformSubscriptionUpdatedMutation = {
  insert_subscriber_log?: {
    __typename: 'subscriber_log_mutation_response',
    affected_rows: number
  } | null
};

export type QuestionAnswerTextInsertedMutationVariables = Exact<{
  questionAnswerId: Scalars['Int']['input'];
  sentiment: sentiment_enum;
  score: Scalars['jsonb']['input'];
}>;


export type QuestionAnswerTextInsertedMutation = {
  update_question_answer_text_by_pk?: {
    __typename: 'question_answer_text',
    question_answer_id: number
  } | null
};

export type QuestionAnswerTextInsertedSentimentPhrasesMutationVariables = Exact<{
  objects: Array<sentiment_phrase_insert_input> | sentiment_phrase_insert_input;
}>;


export type QuestionAnswerTextInsertedSentimentPhrasesMutation = {
  insert_sentiment_phrase?: {
    __typename: 'sentiment_phrase_mutation_response',
    affected_rows: number
  } | null
};

export type SubscriberFlowQuestionAnswerInsertedQueryVariables = Exact<{
  questionAnswerId: Scalars['Int']['input'];
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type SubscriberFlowQuestionAnswerInsertedQuery = {
  subscriber_flow_question_answer_by_pk?: {
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question_id: number,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string
      } | null,
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option: {
          __typename: 'question_option',
          reason_code?: string | null,
          published_version?: {
            __typename: 'question_option_version',
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      }>,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    },
    subscriber_flow: {
      __typename: 'subscriber_flow',
      subscriber: {
        __typename: 'subscriber',
        id: number,
        email: string,
        platform_id: string,
        account: {
          __typename: 'account',
          integrations: Array<{
            __typename: 'integration',
            id: number,
            type: integration_type_enum,
            account_id: number,
            credentials: any,
            options: any,
            meta: any
          }>
        }
      }
    }
  } | null
};

export type SubscriberFlowUpdatedSubscriberFlowQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SubscriberFlowUpdatedSubscriberFlowQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    automatically_deflected: boolean,
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    subscriber: {
      __typename: 'subscriber',
      account_id: number,
      klaviyo_profile_id?: string | null,
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      account: {
        __typename: 'account',
        integrations: Array<{
          __typename: 'integration',
          id: number,
          type: integration_type_enum,
          options: any,
          credentials: any
        }>
      },
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type CanceledSubscriberMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type CanceledSubscriberMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type ConvertedMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ConvertedMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SubscriberLogInsertedAccountQueryVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type SubscriberLogInsertedAccountQuery = {
  subscriber_by_pk?: {
    __typename: 'subscriber',
    account: {
      __typename: 'account',
      id: number,
      emails_disabled: boolean,
      account_features: Array<{
        __typename: 'account_feature',
        key: account_feature_key_enum,
        value: any
      }>
    }
  } | null
};

export type ReactivatedStatusMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
}>;


export type ReactivatedStatusMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SavedMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  offerId: Scalars['Int']['input'];
  offerGroupId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SavedMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SubscriptionEndedSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
}>;


export type SubscriptionEndedSubscriptionMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number,
    mrr: string
  } | null
};

export type SubscriptionPausedSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
}>;


export type SubscriptionPausedSubscriptionMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type SubscriptionResumedSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
}>;


export type SubscriptionResumedSubscriptionMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type SubscriptionUpdatedSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int']['input'];
  mrr: Scalars['numeric']['input'];
}>;


export type SubscriptionUpdatedSubscriptionMutation = {
  update_subscription_by_pk?: {
    __typename: 'subscription',
    id: number
  } | null
};

export type MaineTrustDailyExportQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  flowId: Scalars['Int']['input'];
  previousId: Scalars['Int']['input'];
}>;


export type MaineTrustDailyExportQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    completed_at?: string | null,
    flow: {
      __typename: 'flow',
      title: string
    },
    subscription: {
      __typename: 'subscription',
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        code?: string | null
      } | null
    },
    subscriber: {
      __typename: 'subscriber',
      name: string,
      email: string
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      offer: {
        __typename: 'offer',
        token: string,
        type: offer_type_enum,
        name: string,
        metadata: any
      }
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      offer?: {
        __typename: 'offer',
        token: string,
        type: offer_type_enum,
        name: string,
        metadata: any
      } | null
    }>
  }>,
  question: Array<{
    __typename: 'question',
    id: number,
    type: question_type_enum,
    primary: boolean,
    published_version?: {
      __typename: 'question_version',
      id: number,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    } | null
  }>
};

export type NewYorkPostDailyExportQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  previousId: Scalars['Int']['input'];
}>;


export type NewYorkPostDailyExportQuery = {
  subscriber_flow: Array<{
    __typename: 'subscriber_flow',
    id: number,
    status: subscriber_flow_status_enum,
    completed_at?: string | null,
    automatically_deflected: boolean,
    subscription: {
      __typename: 'subscription',
      platform_id: string,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          key: string,
          name: string
        }
      }>
    },
    subscriber: {
      __typename: 'subscriber',
      name: string,
      email: string
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      offer: {
        __typename: 'offer',
        token: string,
        type: offer_type_enum,
        name: string,
        metadata: any,
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string
        } | null
      }
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      offer?: {
        __typename: 'offer',
        token: string,
        type: offer_type_enum,
        name: string,
        metadata: any,
        offer_coupon?: {
          __typename: 'offer_coupon',
          type: offer_coupon_type_enum,
          amount_off: string
        } | null
      } | null
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>
  }>,
  question: Array<{
    __typename: 'question',
    id: number,
    type: question_type_enum,
    primary: boolean,
    published_version?: {
      __typename: 'question_version',
      id: number,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    } | null
  }>
};

export type FlowIndexAccountQueryVariables = Exact<{
  accountToken: Scalars['String']['input'];
  flowToken: Scalars['String']['input'];
  useAccountToken: Scalars['Boolean']['input'];
  useFlowToken: Scalars['Boolean']['input'];
}>;


export type FlowIndexAccountQuery = {
  account?: Array<{
    __typename: 'account',
    id: number,
    secret: string,
    prompt_for_subscriber_details: boolean,
    create_missing_properties: boolean,
    use_subscriber_id_as_subscription_id: boolean,
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null,
    default_flow?: {
      __typename: 'flow',
      id: number,
      token: string,
      signature_required: boolean,
      secret: string
    } | null,
    hubspot: Array<{
      __typename: 'integration',
      id: number
    }>,
    intercom: Array<{
      __typename: 'integration',
      id: number
    }>,
    salesforce: Array<{
      __typename: 'integration',
      id: number
    }>,
    klaviyo: Array<{
      __typename: 'integration',
      id: number
    }>,
    flow_route_rules: Array<{
      __typename: 'flow_route_rule',
      id: number,
      segment_group_ids: any,
      segment_ids: any,
      flow_ids: any,
      flow_test_ids: any,
      eligibility_message_ids: any,
      flow_route_rule_segment_groups: Array<{
        __typename: 'flow_route_rule_segment_group',
        segment_group_id?: number | null,
        segment_group?: {
          __typename: 'segment_group',
          id: number,
          name: string,
          segment_group_segments: Array<{
            __typename: 'segment_group_segment',
            operator: segment_group_operator_enum,
            segment: {
              __typename: 'segment',
              id: number,
              name: string
            }
          }>
        } | null
      }>,
      flow_route_rule_segments: Array<{
        __typename: 'flow_route_rule_segment',
        segment_id?: number | null,
        segment?: {
          __typename: 'segment',
          id: number,
          name: string
        } | null
      }>,
      flow_route_rule_flows: Array<{
        __typename: 'flow_route_rule_flow',
        flow_id?: number | null
      }>,
      flow_route_rule_flow_tests: Array<{
        __typename: 'flow_route_rule_flow_test',
        flow_test_id?: number | null,
        flow_test?: {
          __typename: 'flow_test',
          id: number,
          name: string,
          control_weight: number,
          winning_flow_id?: number | null,
          control_flow: {
            __typename: 'flow',
            id: number
          },
          flow_test_flows: Array<{
            __typename: 'flow_test_flow',
            flow_test_id: number,
            flow_id: number,
            weight: number
          }>
        } | null
      }>,
      flow_route_rule_eligibility_messages: Array<{
        __typename: 'flow_route_rule_eligibility_message',
        eligibility_message_id?: number | null,
        eligibility_message?: {
          __typename: 'eligibility_message',
          id: number,
          name: string
        } | null
      }>
    }>,
    account_settings: Array<{
      __typename: 'account_setting',
      account_id: number,
      key: account_setting_key_enum,
      value: any
    }>
  }>,
  flow?: Array<{
    __typename: 'flow',
    id: number,
    token: string,
    signature_required: boolean,
    secret: string,
    account: {
      __typename: 'account',
      id: number,
      secret: string,
      prompt_for_subscriber_details: boolean,
      create_missing_properties: boolean,
      use_subscriber_id_as_subscription_id: boolean,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number
      } | null,
      default_flow?: {
        __typename: 'flow',
        id: number,
        token: string,
        signature_required: boolean,
        secret: string
      } | null,
      hubspot: Array<{
        __typename: 'integration',
        id: number
      }>,
      intercom: Array<{
        __typename: 'integration',
        id: number
      }>,
      salesforce: Array<{
        __typename: 'integration',
        id: number
      }>,
      klaviyo: Array<{
        __typename: 'integration',
        id: number
      }>,
      flow_route_rules: Array<{
        __typename: 'flow_route_rule',
        id: number,
        segment_group_ids: any,
        segment_ids: any,
        flow_ids: any,
        flow_test_ids: any,
        eligibility_message_ids: any,
        flow_route_rule_segment_groups: Array<{
          __typename: 'flow_route_rule_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_route_rule_segments: Array<{
          __typename: 'flow_route_rule_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_route_rule_flows: Array<{
          __typename: 'flow_route_rule_flow',
          flow_id?: number | null
        }>,
        flow_route_rule_flow_tests: Array<{
          __typename: 'flow_route_rule_flow_test',
          flow_test_id?: number | null,
          flow_test?: {
            __typename: 'flow_test',
            id: number,
            name: string,
            control_weight: number,
            winning_flow_id?: number | null,
            control_flow: {
              __typename: 'flow',
              id: number
            },
            flow_test_flows: Array<{
              __typename: 'flow_test_flow',
              flow_test_id: number,
              flow_id: number,
              weight: number
            }>
          } | null
        }>,
        flow_route_rule_eligibility_messages: Array<{
          __typename: 'flow_route_rule_eligibility_message',
          eligibility_message_id?: number | null,
          eligibility_message?: {
            __typename: 'eligibility_message',
            id: number,
            name: string
          } | null
        }>
      }>,
      account_settings: Array<{
        __typename: 'account_setting',
        account_id: number,
        key: account_setting_key_enum,
        value: any
      }>
    }
  }>
};

export type FlowIndexAccountFragment = {
  __typename: 'account',
  id: number,
  secret: string,
  prompt_for_subscriber_details: boolean,
  create_missing_properties: boolean,
  use_subscriber_id_as_subscription_id: boolean,
  platform_connection?: {
    __typename: 'platform_connection',
    id: number
  } | null,
  default_flow?: {
    __typename: 'flow',
    id: number,
    token: string,
    signature_required: boolean,
    secret: string
  } | null,
  hubspot: Array<{
    __typename: 'integration',
    id: number
  }>,
  intercom: Array<{
    __typename: 'integration',
    id: number
  }>,
  salesforce: Array<{
    __typename: 'integration',
    id: number
  }>,
  klaviyo: Array<{
    __typename: 'integration',
    id: number
  }>,
  flow_route_rules: Array<{
    __typename: 'flow_route_rule',
    id: number,
    segment_group_ids: any,
    segment_ids: any,
    flow_ids: any,
    flow_test_ids: any,
    eligibility_message_ids: any,
    flow_route_rule_segment_groups: Array<{
      __typename: 'flow_route_rule_segment_group',
      segment_group_id?: number | null,
      segment_group?: {
        __typename: 'segment_group',
        id: number,
        name: string,
        segment_group_segments: Array<{
          __typename: 'segment_group_segment',
          operator: segment_group_operator_enum,
          segment: {
            __typename: 'segment',
            id: number,
            name: string
          }
        }>
      } | null
    }>,
    flow_route_rule_segments: Array<{
      __typename: 'flow_route_rule_segment',
      segment_id?: number | null,
      segment?: {
        __typename: 'segment',
        id: number,
        name: string
      } | null
    }>,
    flow_route_rule_flows: Array<{
      __typename: 'flow_route_rule_flow',
      flow_id?: number | null
    }>,
    flow_route_rule_flow_tests: Array<{
      __typename: 'flow_route_rule_flow_test',
      flow_test_id?: number | null,
      flow_test?: {
        __typename: 'flow_test',
        id: number,
        name: string,
        control_weight: number,
        winning_flow_id?: number | null,
        control_flow: {
          __typename: 'flow',
          id: number
        },
        flow_test_flows: Array<{
          __typename: 'flow_test_flow',
          flow_test_id: number,
          flow_id: number,
          weight: number
        }>
      } | null
    }>,
    flow_route_rule_eligibility_messages: Array<{
      __typename: 'flow_route_rule_eligibility_message',
      eligibility_message_id?: number | null,
      eligibility_message?: {
        __typename: 'eligibility_message',
        id: number,
        name: string
      } | null
    }>
  }>,
  account_settings: Array<{
    __typename: 'account_setting',
    account_id: number,
    key: account_setting_key_enum,
    value: any
  }>
};

export type FlowIndexPlatformSubscriptionQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type FlowIndexPlatformSubscriptionQuery = {
  platform_subscription_by_pk?: {
    __typename: 'platform_subscription',
    platform_customer_id: string
  } | null
};

export type FlowIndexQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FlowIndexQuery = {
  flow_by_pk?: {
    __typename: 'flow',
    token: string,
    prevent_if_canceled: boolean,
    id: number,
    account_id: number,
    secret: string,
    signature_required: boolean,
    default_language: language_enum,
    account: {
      __typename: 'account',
      id: number,
      platform_connection?: {
        __typename: 'platform_connection',
        id: number
      } | null,
      hubspot: Array<{
        __typename: 'integration',
        id: number
      }>,
      intercom: Array<{
        __typename: 'integration',
        id: number
      }>,
      salesforce: Array<{
        __typename: 'integration',
        id: number
      }>
    },
    prevent_if_canceled_translation?: {
      __typename: 'translation',
      id: number,
      translation_values: Array<{
        __typename: 'translation_value',
        translation_id: number,
        language: language_enum,
        value: any,
        format?: translation_value_format_enum | null
      }>
    } | null
  } | null
};

export type FlowIndexMutationVariables = Exact<{
  input: subscriber_flow_insert_input;
}>;


export type FlowIndexMutation = {
  insert_subscriber_flow_one?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string
  } | null
};

export type FlowIndexSegmentValuesMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  segmentValues: Scalars['jsonb']['input'];
}>;


export type FlowIndexSegmentValuesMutation = {
  update_subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number
  } | null
};

export type FlowIndexRecordEmbeddedMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type FlowIndexRecordEmbeddedMutation = {
  insert_account_setting_one?: {
    __typename: 'account_setting',
    key: account_setting_key_enum
  } | null
};

export type CreatePropertyFromValueMutationVariables = Exact<{
  object: property_insert_input;
}>;


export type CreatePropertyFromValueMutation = {
  insert_property_one?: {
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum
  } | null
};

export type GetPropertiesQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  entity: property_entity_enum;
}>;


export type GetPropertiesQuery = {
  property: Array<{
    __typename: 'property',
    id: number,
    key: string,
    type: property_type_enum
  }>
};

export type HealthQueryVariables = Exact<{ [key: string]: never; }>;


export type HealthQuery = {
  account: Array<{
    __typename: 'account',
    id: number
  }>
};

export type AccountHubSpotClientQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type AccountHubSpotClientQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    credentials: any
  }>
};

export type AccountHubSpotClientUpdateCredentialsMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  credentials: Scalars['jsonb']['input'];
}>;


export type AccountHubSpotClientUpdateCredentialsMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type IntercomAccountAccessTokenQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type IntercomAccountAccessTokenQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    credentials: any
  }>
};

export type SalesforceRefreshAccessTokenMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  credentials: Scalars['jsonb']['input'];
}>;


export type SalesforceRefreshAccessTokenMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any
  } | null
};

export type CheckIncompleteSessionQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type CheckIncompleteSessionQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    subscriber_id: number
  } | null
};

export type CheckIncompleteSessionMutationVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  subscriberId: Scalars['Int']['input'];
}>;


export type CheckIncompleteSessionMutation = {
  update_subscriber_flow?: {
    __typename: 'subscriber_flow_mutation_response',
    affected_rows: number
  } | null,
  update_subscriber?: {
    __typename: 'subscriber_mutation_response',
    affected_rows: number
  } | null
};

export type CompleteInProgressSessionsMutationVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type CompleteInProgressSessionsMutation = {
  update_subscriber_flow?: {
    __typename: 'subscriber_flow_mutation_response',
    affected_rows: number
  } | null
};

export type CreatePlatformCouponsOffersQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type CreatePlatformCouponsOffersQuery = {
  offer: Array<{
    __typename: 'offer',
    offer_coupon?: {
      __typename: 'offer_coupon',
      id: number,
      offer_id: number,
      type: offer_coupon_type_enum,
      amount_off: string,
      duration: coupon_duration_enum,
      duration_interval?: coupon_duration_interval_enum | null,
      duration_count?: number | null,
      months?: number | null,
      platform_id?: string | null,
      platform_product_group_id?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        name: string
      }
    } | null
  }>
};

export type DeliverSlackNotificationQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  subscriberFlowId: Scalars['Int']['input'];
}>;


export type DeliverSlackNotificationQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    credentials: any,
    options: any
  } | null,
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        trial: boolean
      }>,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null
};

export type DeliverSlackNotificationDisabledMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type DeliverSlackNotificationDisabledMutation = {
  delete_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type DeliverWebhookQueryVariables = Exact<{
  webhookId: Scalars['Int']['input'];
  eventId: Scalars['Int']['input'];
}>;


export type DeliverWebhookQuery = {
  webhook_by_pk?: {
    __typename: 'webhook',
    deleted_at?: string | null,
    id: number,
    token: string,
    url: string,
    disabled: boolean,
    created_at: string,
    account: {
      __typename: 'account',
      id: number,
      secret: string,
      owner?: {
        __typename: 'user',
        id: string,
        name?: string | null,
        email?: string | null
      } | null
    },
    webhook_events: Array<{
      __typename: 'webhook_event',
      event_type: event_type_enum
    }>
  } | null,
  event_by_pk?: {
    __typename: 'event',
    id: number,
    token: string,
    type: event_type_enum,
    payload: any
  } | null,
  webhook_log: Array<{
    __typename: 'webhook_log',
    webhook_id: number,
    event_id: number,
    attempt: number,
    created_at: string,
    http_status_code: number
  }>,
  last_success: Array<{
    __typename: 'webhook_log',
    created_at: string
  }>
};

export type DeliverWebhookDisableMutationVariables = Exact<{
  webhookId: Scalars['Int']['input'];
}>;


export type DeliverWebhookDisableMutation = {
  update_webhook_by_pk?: {
    __typename: 'webhook',
    id: number
  } | null
};

export type DeliverWebhookLogMutationVariables = Exact<{
  object: webhook_log_insert_input;
}>;


export type DeliverWebhookLogMutation = {
  insert_webhook_log_one?: {
    __typename: 'webhook_log',
    webhook_id: number
  } | null
};

export type EnqueueBoldSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueBoldSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueBraintreeSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueBraintreeSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueChargebeeSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueChargebeeSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueChargifySyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueChargifySyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueHubSpotSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueueHubSpotSyncJobsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type EnqueueIntercomSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueueIntercomSyncJobsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type EnqueueKlaviyoSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueueKlaviyoSyncJobsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type EnqueueNavigaSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueNavigaSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueOpenPaySyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueOpenPaySyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueuePaddleSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueuePaddleSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueuePlatformMetadataSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueuePlatformMetadataSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    meta: any,
    options: any
  }>
};

export type EnqueueRechargeSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueRechargeSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueRecurlyCustomFieldsSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueueRecurlyCustomFieldsSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    meta: any,
    options: any
  }>
};

export type EnqueueRecurlySyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueRecurlySyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type EnqueueSalesforceSyncJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnqueueSalesforceSyncJobsQuery = {
  integration: Array<{
    __typename: 'integration',
    id: number,
    meta: any
  }>
};

export type EnqueueZuoraSyncJobsQueryVariables = Exact<{
  timestamp: Scalars['timestamptz']['input'];
}>;


export type EnqueueZuoraSyncJobsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type ExportSessionsSubscriberFlowFragment = {
  __typename: 'subscriber_flow',
  id: number,
  token: string,
  status: subscriber_flow_status_enum,
  user_agent?: string | null,
  created_at: string,
  completed_at?: string | null,
  subscriber: {
    __typename: 'subscriber',
    id: number,
    token: string,
    platform_id: string,
    name: string,
    email: string,
    status?: subscriber_status_enum | null,
    active_mrr: string,
    inactive_mrr: string,
    subscriber_properties: Array<{
      __typename: 'subscriber_property',
      property_id: number,
      value: any
    }>
  },
  subscription: {
    __typename: 'subscription',
    id: number,
    token: string,
    platform_id: string,
    platform_subscription?: {
      __typename: 'platform_subscription',
      platform_id: string,
      code?: string | null,
      platform_subscription_plans: Array<{
        __typename: 'platform_subscription_plan',
        platform_plan_id: string
      }>,
      platform_subscription_addons: Array<{
        __typename: 'platform_subscription_addon',
        platform_addon_id: string
      }>
    } | null,
    subscription_properties: Array<{
      __typename: 'subscription_property',
      property_id: number,
      value: any
    }>
  },
  flow: {
    __typename: 'flow',
    token: string,
    title: string
  },
  subscriber_flow_question_answers: Array<{
    __typename: 'subscriber_flow_question_answer',
    question_answer: {
      __typename: 'question_answer',
      question: {
        __typename: 'question',
        id: number,
        type: question_type_enum
      },
      question_version: {
        __typename: 'question_version',
        id: number,
        title_translation?: {
          __typename: 'translation',
          id: number,
          translation_values: Array<{
            __typename: 'translation_value',
            translation_id: number,
            language: language_enum,
            value: any,
            format?: translation_value_format_enum | null
          }>
        } | null
      },
      question_answer_radios: Array<{
        __typename: 'question_answer_radio',
        question_option_version: {
          __typename: 'question_option_version',
          id: number,
          title_translation: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          }
        }
      }>,
      question_answer_text?: {
        __typename: 'question_answer_text',
        value: string,
        sentiment?: sentiment_enum | null
      } | null,
      question_answer_likelihood_to_return?: {
        __typename: 'question_answer_likelihood_to_return',
        value: number
      } | null
    }
  }>,
  subscriber_flow_offers: Array<{
    __typename: 'subscriber_flow_offer',
    subscriber_flow_id: number,
    offer_id: number,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    },
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null,
    offer_autopilot_offer_variant?: {
      __typename: 'offer_autopilot_offer_variant',
      variant_index?: number | null
    } | null
  }>,
  subscriber_flow_offer_groups: Array<{
    __typename: 'subscriber_flow_offer_group',
    subscriber_flow_id: number,
    offer_group_id: number,
    offer_id?: number | null,
    status?: subscriber_flow_offer_status_enum | null,
    rescheduled_to?: string | null,
    offer_group: {
      __typename: 'offer_group',
      id: number,
      name: string,
      offer_group_offers: Array<{
        __typename: 'offer_group_offer',
        position?: number | null,
        offer?: {
          __typename: 'offer',
          id: number,
          type: offer_type_enum,
          name: string,
          offer_pause_subscription?: {
            __typename: 'offer_pause_subscription',
            offer_id: number,
            offer_pause_subscription_options: Array<{
              __typename: 'offer_pause_subscription_option',
              id: number,
              type: offer_pause_subscription_option_type_enum,
              interval?: offer_pause_subscription_interval_enum | null,
              interval_count?: number | null
            }>
          } | null
        } | null
      }>
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      name: string,
      goal: offer_goal_enum,
      metadata: any,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        offer_id: number,
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          type: offer_pause_subscription_option_type_enum,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null
        }>
      } | null,
      offer_coupon?: {
        __typename: 'offer_coupon',
        id: number,
        offer_id: number,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        duration_count?: number | null,
        duration_interval?: coupon_duration_interval_enum | null,
        months?: number | null
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        change_at: offer_change_plan_change_at_enum,
        platform_plan?: {
          __typename: 'platform_plan',
          name: string
        } | null,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            id: number,
            offer_id: number,
            type: offer_coupon_type_enum,
            amount_off: string,
            duration: coupon_duration_enum,
            duration_count?: number | null,
            duration_interval?: coupon_duration_interval_enum | null,
            months?: number | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        modify_at: offer_modify_subscription_modify_at_enum,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_connection_id: number,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_custom?: {
        __typename: 'offer_custom',
        link_href?: string | null,
        link_text?: string | null
      } | null,
      offer_reschedule_order?: {
        __typename: 'offer_reschedule_order',
        skip: boolean,
        reschedule: boolean
      } | null
    } | null,
    offer_pause_subscription_option?: {
      __typename: 'offer_pause_subscription_option',
      id: number,
      type: offer_pause_subscription_option_type_enum,
      interval?: offer_pause_subscription_interval_enum | null,
      interval_count?: number | null
    } | null
  }>,
  subscriber_flow_flow_steps: Array<{
    __typename: 'subscriber_flow_flow_step',
    flow_step: {
      __typename: 'flow_step',
      id: number,
      token: string,
      type: flow_step_type_enum,
      published_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      draft_version?: {
        __typename: 'flow_step_version',
        id: number,
        flow_step_id: number,
        condition_segment_group_ids: any,
        condition_segment_ids: any,
        condition_question_option_ids: any,
        condition_include_other_in_question_ids: any,
        flow_step_version_condition_segment_groups: Array<{
          __typename: 'flow_step_version_condition_segment_group',
          segment_group_id?: number | null,
          segment_group?: {
            __typename: 'segment_group',
            id: number,
            name: string,
            segment_group_segments: Array<{
              __typename: 'segment_group_segment',
              operator: segment_group_operator_enum,
              segment: {
                __typename: 'segment',
                id: number,
                name: string
              }
            }>
          } | null
        }>,
        flow_step_version_condition_segments: Array<{
          __typename: 'flow_step_version_condition_segment',
          segment_id?: number | null,
          segment?: {
            __typename: 'segment',
            id: number,
            name: string
          } | null
        }>,
        flow_step_version_condition_question_options: Array<{
          __typename: 'flow_step_version_condition_question_option',
          question_option_id?: number | null,
          question_option?: {
            __typename: 'question_option',
            id: number,
            question_id: number,
            published_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'question_option_version',
              id: number,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          } | null
        }>
      } | null,
      flow_step_acknowledgement_group?: {
        __typename: 'flow_step_acknowledgement_group',
        flow_step_id: number,
        acknowledgement_group_id: number,
        acknowledgement_group: {
          __typename: 'acknowledgement_group',
          id: number,
          published_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'acknowledgement_group_version',
            id: number,
            acknowledgement_group_id: number,
            acknowledgement_ids: any,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            acknowledgement_group_version_acknowledgements: Array<{
              __typename: 'acknowledgement_group_version_acknowledgement',
              acknowledgement_group_version_id?: number | null,
              acknowledgement_id?: number | null,
              acknowledgement?: {
                __typename: 'acknowledgement',
                id: number,
                title: string,
                published_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'acknowledgement_version',
                  id: number,
                  acknowledgement_id: number,
                  label_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_form?: {
        __typename: 'flow_step_form',
        flow_step_id: number,
        form_id: number,
        form: {
          __typename: 'form',
          id: number,
          title: string,
          published_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'form_version',
            id: number,
            form_id: number,
            question_ids: any,
            form_version_questions: Array<{
              __typename: 'form_version_question',
              question_id?: number | null,
              form_version_id?: number | null,
              question?: {
                __typename: 'question',
                id: number,
                token: string,
                type: question_type_enum,
                primary: boolean,
                published_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'question_version',
                  id: number,
                  question_id: number,
                  required: boolean,
                  title_translation_id: number,
                  hint_translation_id: number,
                  placeholder_translation_id?: number | null,
                  condition_segment_ids?: any | null,
                  condition_question_option_ids?: any | null,
                  condition_include_other_in_question_ids?: any | null,
                  question_option_ids?: any | null,
                  title_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  hint_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  placeholder_translation?: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  } | null,
                  question_version_question_options: Array<{
                    __typename: 'question_version_question_option',
                    question_option_id?: number | null,
                    question_version_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      token: string,
                      reason_code?: string | null,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        question_option_id: number,
                        title_translation_id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_question_options: Array<{
                    __typename: 'question_version_condition_question_option',
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  question_version_condition_segments: Array<{
                    __typename: 'question_version_condition_segment',
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  question_version_condition_segment_groups: Array<{
                    __typename: 'question_version_condition_segment_group',
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>
                } | null,
                question_settings: Array<{
                  __typename: 'question_setting',
                  question_id: number,
                  key: question_setting_key_enum,
                  published_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null,
                  draft_version?: {
                    __typename: 'question_setting_version',
                    id: number,
                    question_id: number,
                    key: question_setting_key_enum,
                    value: any
                  } | null
                }>
              } | null
            }>
          } | null
        }
      } | null,
      flow_step_question?: {
        __typename: 'flow_step_question',
        flow_step_id: number,
        question_id: number,
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          published_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'question_version',
            id: number,
            question_id: number,
            required: boolean,
            title_translation_id: number,
            hint_translation_id: number,
            placeholder_translation_id?: number | null,
            condition_segment_ids?: any | null,
            condition_question_option_ids?: any | null,
            condition_include_other_in_question_ids?: any | null,
            question_option_ids?: any | null,
            title_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            hint_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            placeholder_translation?: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            } | null,
            question_version_question_options: Array<{
              __typename: 'question_version_question_option',
              question_option_id?: number | null,
              question_version_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                token: string,
                reason_code?: string | null,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  question_option_id: number,
                  title_translation_id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_question_options: Array<{
              __typename: 'question_version_condition_question_option',
              question_option_id?: number | null,
              question_option?: {
                __typename: 'question_option',
                id: number,
                question_id: number,
                published_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null,
                draft_version?: {
                  __typename: 'question_option_version',
                  id: number,
                  title_translation: {
                    __typename: 'translation',
                    id: number,
                    translation_values: Array<{
                      __typename: 'translation_value',
                      translation_id: number,
                      language: language_enum,
                      value: any,
                      format?: translation_value_format_enum | null
                    }>
                  }
                } | null
              } | null
            }>,
            question_version_condition_segments: Array<{
              __typename: 'question_version_condition_segment',
              segment_id?: number | null,
              segment?: {
                __typename: 'segment',
                id: number,
                name: string
              } | null
            }>,
            question_version_condition_segment_groups: Array<{
              __typename: 'question_version_condition_segment_group',
              segment_group_id?: number | null,
              segment_group?: {
                __typename: 'segment_group',
                id: number,
                name: string,
                segment_group_segments: Array<{
                  __typename: 'segment_group_segment',
                  operator: segment_group_operator_enum,
                  segment: {
                    __typename: 'segment',
                    id: number,
                    name: string
                  }
                }>
              } | null
            }>
          } | null,
          question_settings: Array<{
            __typename: 'question_setting',
            question_id: number,
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null,
            draft_version?: {
              __typename: 'question_setting_version',
              id: number,
              question_id: number,
              key: question_setting_key_enum,
              value: any
            } | null
          }>
        }
      } | null,
      flow_step_deflection_rule_group?: {
        __typename: 'flow_step_deflection_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string,
          published_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null,
          draft_version?: {
            __typename: 'offer_rule_group_version',
            id: number,
            offer_rule_group_id: number,
            offer_rule_ids: any,
            offer_rule_group_version_offer_rules: Array<{
              __typename: 'offer_rule_group_version_offer_rule',
              offer_rule?: {
                __typename: 'offer_rule',
                id: number,
                published_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null,
                draft_version?: {
                  __typename: 'offer_rule_version',
                  id: number,
                  offer_rule_id: number,
                  segment_group_ids: any,
                  segment_ids: any,
                  question_option_ids: any,
                  include_present_no_offer: boolean,
                  include_other_in_question_ids: any,
                  offer_rule_rule_ids: any,
                  offer_rule_version_segment_groups: Array<{
                    __typename: 'offer_rule_version_segment_group',
                    offer_rule_version_id?: number | null,
                    segment_group_id?: number | null,
                    segment_group?: {
                      __typename: 'segment_group',
                      id: number,
                      name: string,
                      segment_group_segments: Array<{
                        __typename: 'segment_group_segment',
                        operator: segment_group_operator_enum,
                        segment: {
                          __typename: 'segment',
                          id: number,
                          name: string
                        }
                      }>
                    } | null
                  }>,
                  offer_rule_version_segments: Array<{
                    __typename: 'offer_rule_version_segment',
                    offer_rule_version_id?: number | null,
                    segment_id?: number | null,
                    segment?: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    } | null
                  }>,
                  offer_rule_version_question_options: Array<{
                    __typename: 'offer_rule_version_question_option',
                    offer_rule_version_id?: number | null,
                    question_option_id?: number | null,
                    question_option?: {
                      __typename: 'question_option',
                      id: number,
                      question_id: number,
                      published_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null,
                      draft_version?: {
                        __typename: 'question_option_version',
                        id: number,
                        title_translation: {
                          __typename: 'translation',
                          id: number,
                          translation_values: Array<{
                            __typename: 'translation_value',
                            translation_id: number,
                            language: language_enum,
                            value: any,
                            format?: translation_value_format_enum | null
                          }>
                        }
                      } | null
                    } | null
                  }>,
                  offer_rule_version_offer_rule_rules: Array<{
                    __typename: 'offer_rule_version_offer_rule_rule',
                    offer_rule_version_id?: number | null,
                    offer_rule_rule_id?: number | null,
                    offer_rule_rule?: {
                      __typename: 'offer_rule_rule',
                      id: number,
                      offer_rule_id: number,
                      deflection_ids: any,
                      include_present_no_offer: boolean,
                      weight: number,
                      offer_rule_rule_deflections: Array<{
                        __typename: 'offer_rule_rule_deflection',
                        offer_rule_rule_id?: number | null,
                        deflection_id?: number | null,
                        deflection?: {
                          __typename: 'deflection',
                          id: number,
                          title: string,
                          token: string,
                          minimum_items?: number | null,
                          heading_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          },
                          content_translation?: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          } | null,
                          deflection_snapshot_items: Array<{
                            __typename: 'deflection_snapshot_item_position',
                            position?: number | null,
                            deflection_snapshot_item?: {
                              __typename: 'deflection_snapshot_item',
                              id: number,
                              token: string,
                              icon_prefix?: string | null,
                              icon_name?: string | null,
                              property_id?: number | null,
                              property_condition_value?: any | null,
                              date_format?: string | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              },
                              property?: {
                                __typename: 'property',
                                id: number,
                                name: string,
                                entity: property_entity_enum,
                                type: property_type_enum,
                                format?: property_format_enum | null
                              } | null
                            } | null
                          }>,
                          deflection_flow_actions: Array<{
                            __typename: 'deflection_flow_action',
                            position?: number | null,
                            flow_action?: {
                              __typename: 'flow_action',
                              id: number,
                              token: string,
                              code?: string | null,
                              type: flow_action_type_enum,
                              account_id: number,
                              appearance: flow_action_appearance_enum,
                              url?: string | null,
                              reroute_to_flow_id?: number | null,
                              text_translation: {
                                __typename: 'translation',
                                id: number,
                                translation_values: Array<{
                                  __typename: 'translation_value',
                                  translation_id: number,
                                  language: language_enum,
                                  value: any,
                                  format?: translation_value_format_enum | null
                                }>
                              }
                            } | null
                          }>
                        } | null
                      }>
                    } | null
                  }>
                } | null
              } | null
            }>
          } | null
        } | null
      } | null,
      flow_step_offer_rule_group?: {
        __typename: 'flow_step_offer_rule_group',
        flow_step_id?: number | null,
        offer_rule_group_id?: number | null,
        offer_rule_group?: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        } | null
      } | null,
      flow_step_rule_group?: {
        __typename: 'flow_step_rule_group',
        flow_step_id: number,
        offer_rule_group_id: number,
        offer_rule_group: {
          __typename: 'offer_rule_group',
          id: number,
          title: string
        }
      } | null,
      flow_step_confirmation?: {
        __typename: 'flow_step_confirmation',
        flow_step_id: number,
        confirmation_id: number,
        confirmation: {
          __typename: 'confirmation',
          id: number,
          published_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'confirmation_version',
            id: number,
            confirmation_id: number,
            show_on_cancel: boolean,
            show_on_save: boolean,
            saved_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            saved_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_headline_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            canceled_content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null,
      flow_step_intervention?: {
        __typename: 'flow_step_intervention',
        flow_step_id: number,
        intervention_id: number,
        intervention: {
          __typename: 'intervention',
          id: number,
          token: string,
          title: string,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          },
          published_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null,
          draft_version?: {
            __typename: 'intervention_version',
            id: number,
            intervention_id: number,
            content_translation_id: number,
            continue_button_translation_id: number,
            cancel_button_translation_id: number,
            content_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            continue_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            },
            cancel_button_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          } | null
        }
      } | null
    }
  }>
};

export type ExportSessionsExportQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ExportSessionsExportQuery = {
  subscriber_flow_export: Array<{
    __typename: 'subscriber_flow_export',
    id: number,
    account_id: number,
    exported_by_user?: {
      __typename: 'user',
      id: string,
      email?: string | null
    } | null
  }>
};

export type ExportSessionsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportSessionsInfoQuery = {
  question: Array<{
    __typename: 'question',
    id: number,
    type: question_type_enum,
    published_version?: {
      __typename: 'question_version',
      id: number,
      title_translation?: {
        __typename: 'translation',
        id: number,
        translation_values: Array<{
          __typename: 'translation_value',
          translation_id: number,
          language: language_enum,
          value: any,
          format?: translation_value_format_enum | null
        }>
      } | null
    } | null
  }>,
  offer: Array<{
    __typename: 'offer',
    id: number,
    name: string
  }>,
  offer_group: Array<{
    __typename: 'offer_group',
    id: number,
    name: string
  }>,
  property: Array<{
    __typename: 'property',
    id: number,
    name: string,
    entity: property_entity_enum
  }>
};

export type ExportSessionsQueryVariables = Exact<{
  useSearch: Scalars['Boolean']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  where: subscriber_flow_bool_exp;
  limit: Scalars['Int']['input'];
  includeDrafts?: Scalars['Boolean']['input'];
}>;


export type ExportSessionsQuery = {
  subscriber_flow?: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    user_agent?: string | null,
    created_at: string,
    completed_at?: string | null,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      token: string,
      platform_id: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      active_mrr: string,
      inactive_mrr: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        property_id: number,
        value: any
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          platform_addon_id: string
        }>
      } | null,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        property_id: number,
        value: any
      }>
    },
    flow: {
      __typename: 'flow',
      token: string,
      title: string
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      }
    }>
  }>,
  search_subscriber_flows?: Array<{
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    user_agent?: string | null,
    created_at: string,
    completed_at?: string | null,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      token: string,
      platform_id: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      active_mrr: string,
      inactive_mrr: string,
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        property_id: number,
        value: any
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      platform_subscription?: {
        __typename: 'platform_subscription',
        platform_id: string,
        code?: string | null,
        platform_subscription_plans: Array<{
          __typename: 'platform_subscription_plan',
          platform_plan_id: string
        }>,
        platform_subscription_addons: Array<{
          __typename: 'platform_subscription_addon',
          platform_addon_id: string
        }>
      } | null,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        property_id: number,
        value: any
      }>
    },
    flow: {
      __typename: 'flow',
      token: string,
      title: string
    },
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          type: question_type_enum
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          }
        }>,
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      subscriber_flow_id: number,
      offer_id: number,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null,
      offer_autopilot_offer_variant?: {
        __typename: 'offer_autopilot_offer_variant',
        variant_index?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      subscriber_flow_id: number,
      offer_group_id: number,
      offer_id?: number | null,
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        name: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            type: offer_type_enum,
            name: string,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              offer_id: number,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                type: offer_pause_subscription_option_type_enum,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null
              }>
            } | null
          } | null
        }>
      },
      offer?: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        name: string,
        goal: offer_goal_enum,
        metadata: any,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          offer_id: number,
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            type: offer_pause_subscription_option_type_enum,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null
          }>
        } | null,
        offer_coupon?: {
          __typename: 'offer_coupon',
          id: number,
          offer_id: number,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          duration_count?: number | null,
          duration_interval?: coupon_duration_interval_enum | null,
          months?: number | null
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          change_at: offer_change_plan_change_at_enum,
          platform_plan?: {
            __typename: 'platform_plan',
            name: string
          } | null,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              id: number,
              offer_id: number,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              duration_count?: number | null,
              duration_interval?: coupon_duration_interval_enum | null,
              months?: number | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          modify_at: offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_connection_id: number,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_custom?: {
          __typename: 'offer_custom',
          link_href?: string | null,
          link_text?: string | null
        } | null,
        offer_reschedule_order?: {
          __typename: 'offer_reschedule_order',
          skip: boolean,
          reschedule: boolean
        } | null
      } | null,
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        type: offer_pause_subscription_option_type_enum,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        published_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        draft_version?: {
          __typename: 'flow_step_version',
          id: number,
          flow_step_id: number,
          condition_segment_group_ids: any,
          condition_segment_ids: any,
          condition_question_option_ids: any,
          condition_include_other_in_question_ids: any,
          flow_step_version_condition_segment_groups: Array<{
            __typename: 'flow_step_version_condition_segment_group',
            segment_group_id?: number | null,
            segment_group?: {
              __typename: 'segment_group',
              id: number,
              name: string,
              segment_group_segments: Array<{
                __typename: 'segment_group_segment',
                operator: segment_group_operator_enum,
                segment: {
                  __typename: 'segment',
                  id: number,
                  name: string
                }
              }>
            } | null
          }>,
          flow_step_version_condition_segments: Array<{
            __typename: 'flow_step_version_condition_segment',
            segment_id?: number | null,
            segment?: {
              __typename: 'segment',
              id: number,
              name: string
            } | null
          }>,
          flow_step_version_condition_question_options: Array<{
            __typename: 'flow_step_version_condition_question_option',
            question_option_id?: number | null,
            question_option?: {
              __typename: 'question_option',
              id: number,
              question_id: number,
              published_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null,
              draft_version?: {
                __typename: 'question_option_version',
                id: number,
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            } | null
          }>
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          flow_step_id: number,
          acknowledgement_group_id: number,
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            id: number,
            published_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'acknowledgement_group_version',
              id: number,
              acknowledgement_group_id: number,
              acknowledgement_ids: any,
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              acknowledgement_group_version_acknowledgements: Array<{
                __typename: 'acknowledgement_group_version_acknowledgement',
                acknowledgement_group_version_id?: number | null,
                acknowledgement_id?: number | null,
                acknowledgement?: {
                  __typename: 'acknowledgement',
                  id: number,
                  title: string,
                  published_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'acknowledgement_version',
                    id: number,
                    acknowledgement_id: number,
                    label_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_form?: {
          __typename: 'flow_step_form',
          flow_step_id: number,
          form_id: number,
          form: {
            __typename: 'form',
            id: number,
            title: string,
            published_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'form_version',
              id: number,
              form_id: number,
              question_ids: any,
              form_version_questions: Array<{
                __typename: 'form_version_question',
                question_id?: number | null,
                form_version_id?: number | null,
                question?: {
                  __typename: 'question',
                  id: number,
                  token: string,
                  type: question_type_enum,
                  primary: boolean,
                  published_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'question_version',
                    id: number,
                    question_id: number,
                    required: boolean,
                    title_translation_id: number,
                    hint_translation_id: number,
                    placeholder_translation_id?: number | null,
                    condition_segment_ids?: any | null,
                    condition_question_option_ids?: any | null,
                    condition_include_other_in_question_ids?: any | null,
                    question_option_ids?: any | null,
                    title_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    hint_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    placeholder_translation?: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    } | null,
                    question_version_question_options: Array<{
                      __typename: 'question_version_question_option',
                      question_option_id?: number | null,
                      question_version_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        token: string,
                        reason_code?: string | null,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          question_option_id: number,
                          title_translation_id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_question_options: Array<{
                      __typename: 'question_version_condition_question_option',
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    question_version_condition_segments: Array<{
                      __typename: 'question_version_condition_segment',
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    question_version_condition_segment_groups: Array<{
                      __typename: 'question_version_condition_segment_group',
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>
                  } | null,
                  question_settings: Array<{
                    __typename: 'question_setting',
                    question_id: number,
                    key: question_setting_key_enum,
                    published_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null,
                    draft_version?: {
                      __typename: 'question_setting_version',
                      id: number,
                      question_id: number,
                      key: question_setting_key_enum,
                      value: any
                    } | null
                  }>
                } | null
              }>
            } | null
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          flow_step_id: number,
          question_id: number,
          question: {
            __typename: 'question',
            id: number,
            token: string,
            type: question_type_enum,
            primary: boolean,
            published_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'question_version',
              id: number,
              question_id: number,
              required: boolean,
              title_translation_id: number,
              hint_translation_id: number,
              placeholder_translation_id?: number | null,
              condition_segment_ids?: any | null,
              condition_question_option_ids?: any | null,
              condition_include_other_in_question_ids?: any | null,
              question_option_ids?: any | null,
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              hint_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              placeholder_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null,
              question_version_question_options: Array<{
                __typename: 'question_version_question_option',
                question_option_id?: number | null,
                question_version_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  token: string,
                  reason_code?: string | null,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    question_option_id: number,
                    title_translation_id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_question_options: Array<{
                __typename: 'question_version_condition_question_option',
                question_option_id?: number | null,
                question_option?: {
                  __typename: 'question_option',
                  id: number,
                  question_id: number,
                  published_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null,
                  draft_version?: {
                    __typename: 'question_option_version',
                    id: number,
                    title_translation: {
                      __typename: 'translation',
                      id: number,
                      translation_values: Array<{
                        __typename: 'translation_value',
                        translation_id: number,
                        language: language_enum,
                        value: any,
                        format?: translation_value_format_enum | null
                      }>
                    }
                  } | null
                } | null
              }>,
              question_version_condition_segments: Array<{
                __typename: 'question_version_condition_segment',
                segment_id?: number | null,
                segment?: {
                  __typename: 'segment',
                  id: number,
                  name: string
                } | null
              }>,
              question_version_condition_segment_groups: Array<{
                __typename: 'question_version_condition_segment_group',
                segment_group_id?: number | null,
                segment_group?: {
                  __typename: 'segment_group',
                  id: number,
                  name: string,
                  segment_group_segments: Array<{
                    __typename: 'segment_group_segment',
                    operator: segment_group_operator_enum,
                    segment: {
                      __typename: 'segment',
                      id: number,
                      name: string
                    }
                  }>
                } | null
              }>
            } | null,
            question_settings: Array<{
              __typename: 'question_setting',
              question_id: number,
              key: question_setting_key_enum,
              published_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null,
              draft_version?: {
                __typename: 'question_setting_version',
                id: number,
                question_id: number,
                key: question_setting_key_enum,
                value: any
              } | null
            }>
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string,
            published_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null,
            draft_version?: {
              __typename: 'offer_rule_group_version',
              id: number,
              offer_rule_group_id: number,
              offer_rule_ids: any,
              offer_rule_group_version_offer_rules: Array<{
                __typename: 'offer_rule_group_version_offer_rule',
                offer_rule?: {
                  __typename: 'offer_rule',
                  id: number,
                  published_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null,
                  draft_version?: {
                    __typename: 'offer_rule_version',
                    id: number,
                    offer_rule_id: number,
                    segment_group_ids: any,
                    segment_ids: any,
                    question_option_ids: any,
                    include_present_no_offer: boolean,
                    include_other_in_question_ids: any,
                    offer_rule_rule_ids: any,
                    offer_rule_version_segment_groups: Array<{
                      __typename: 'offer_rule_version_segment_group',
                      offer_rule_version_id?: number | null,
                      segment_group_id?: number | null,
                      segment_group?: {
                        __typename: 'segment_group',
                        id: number,
                        name: string,
                        segment_group_segments: Array<{
                          __typename: 'segment_group_segment',
                          operator: segment_group_operator_enum,
                          segment: {
                            __typename: 'segment',
                            id: number,
                            name: string
                          }
                        }>
                      } | null
                    }>,
                    offer_rule_version_segments: Array<{
                      __typename: 'offer_rule_version_segment',
                      offer_rule_version_id?: number | null,
                      segment_id?: number | null,
                      segment?: {
                        __typename: 'segment',
                        id: number,
                        name: string
                      } | null
                    }>,
                    offer_rule_version_question_options: Array<{
                      __typename: 'offer_rule_version_question_option',
                      offer_rule_version_id?: number | null,
                      question_option_id?: number | null,
                      question_option?: {
                        __typename: 'question_option',
                        id: number,
                        question_id: number,
                        published_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null,
                        draft_version?: {
                          __typename: 'question_option_version',
                          id: number,
                          title_translation: {
                            __typename: 'translation',
                            id: number,
                            translation_values: Array<{
                              __typename: 'translation_value',
                              translation_id: number,
                              language: language_enum,
                              value: any,
                              format?: translation_value_format_enum | null
                            }>
                          }
                        } | null
                      } | null
                    }>,
                    offer_rule_version_offer_rule_rules: Array<{
                      __typename: 'offer_rule_version_offer_rule_rule',
                      offer_rule_version_id?: number | null,
                      offer_rule_rule_id?: number | null,
                      offer_rule_rule?: {
                        __typename: 'offer_rule_rule',
                        id: number,
                        offer_rule_id: number,
                        deflection_ids: any,
                        include_present_no_offer: boolean,
                        weight: number,
                        offer_rule_rule_deflections: Array<{
                          __typename: 'offer_rule_rule_deflection',
                          offer_rule_rule_id?: number | null,
                          deflection_id?: number | null,
                          deflection?: {
                            __typename: 'deflection',
                            id: number,
                            title: string,
                            token: string,
                            minimum_items?: number | null,
                            heading_translation: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            },
                            content_translation?: {
                              __typename: 'translation',
                              id: number,
                              translation_values: Array<{
                                __typename: 'translation_value',
                                translation_id: number,
                                language: language_enum,
                                value: any,
                                format?: translation_value_format_enum | null
                              }>
                            } | null,
                            deflection_snapshot_items: Array<{
                              __typename: 'deflection_snapshot_item_position',
                              position?: number | null,
                              deflection_snapshot_item?: {
                                __typename: 'deflection_snapshot_item',
                                id: number,
                                token: string,
                                icon_prefix?: string | null,
                                icon_name?: string | null,
                                property_id?: number | null,
                                property_condition_value?: any | null,
                                date_format?: string | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                },
                                property?: {
                                  __typename: 'property',
                                  id: number,
                                  name: string,
                                  entity: property_entity_enum,
                                  type: property_type_enum,
                                  format?: property_format_enum | null
                                } | null
                              } | null
                            }>,
                            deflection_flow_actions: Array<{
                              __typename: 'deflection_flow_action',
                              position?: number | null,
                              flow_action?: {
                                __typename: 'flow_action',
                                id: number,
                                token: string,
                                code?: string | null,
                                type: flow_action_type_enum,
                                account_id: number,
                                appearance: flow_action_appearance_enum,
                                url?: string | null,
                                reroute_to_flow_id?: number | null,
                                text_translation: {
                                  __typename: 'translation',
                                  id: number,
                                  translation_values: Array<{
                                    __typename: 'translation_value',
                                    translation_id: number,
                                    language: language_enum,
                                    value: any,
                                    format?: translation_value_format_enum | null
                                  }>
                                }
                              } | null
                            }>
                          } | null
                        }>
                      } | null
                    }>
                  } | null
                } | null
              }>
            } | null
          } | null
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          } | null
        } | null,
        flow_step_rule_group?: {
          __typename: 'flow_step_rule_group',
          flow_step_id: number,
          offer_rule_group_id: number,
          offer_rule_group: {
            __typename: 'offer_rule_group',
            id: number,
            title: string
          }
        } | null,
        flow_step_confirmation?: {
          __typename: 'flow_step_confirmation',
          flow_step_id: number,
          confirmation_id: number,
          confirmation: {
            __typename: 'confirmation',
            id: number,
            published_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'confirmation_version',
              id: number,
              confirmation_id: number,
              show_on_cancel: boolean,
              show_on_save: boolean,
              saved_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              saved_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_headline_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              canceled_content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          flow_step_id: number,
          intervention_id: number,
          intervention: {
            __typename: 'intervention',
            id: number,
            token: string,
            title: string,
            offer_rule_group_id: number,
            offer_rule_group: {
              __typename: 'offer_rule_group',
              id: number,
              title: string
            },
            published_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null,
            draft_version?: {
              __typename: 'intervention_version',
              id: number,
              intervention_id: number,
              content_translation_id: number,
              continue_button_translation_id: number,
              cancel_button_translation_id: number,
              content_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              continue_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              },
              cancel_button_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null
      }
    }>
  }>
};

export type ExportSessionsSetUrlMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  url: Scalars['String']['input'];
  expiresAt: Scalars['timestamptz']['input'];
}>;


export type ExportSessionsSetUrlMutation = {
  update_subscriber_flow_export_by_pk?: {
    __typename: 'subscriber_flow_export',
    id: number
  } | null
};

export type SendFlowSessionEmailNotificationQueryVariables = Exact<{
  subscriberFlowId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type SendFlowSessionEmailNotificationQuery = {
  subscriber_flow_by_pk?: {
    __typename: 'subscriber_flow',
    id: number,
    token: string,
    status: subscriber_flow_status_enum,
    automatically_deflected: boolean,
    url?: string | null,
    user_agent?: string | null,
    started_at?: string | null,
    completed_at?: string | null,
    created_at: string,
    updated_at: string,
    subscriber: {
      __typename: 'subscriber',
      id: number,
      platform_id: string,
      token: string,
      name: string,
      email: string,
      status?: subscriber_status_enum | null,
      created_at: string,
      updated_at: string,
      account: {
        __typename: 'account',
        id: number,
        title: string
      },
      subscriber_properties: Array<{
        __typename: 'subscriber_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>
    },
    subscription: {
      __typename: 'subscription',
      id: number,
      token: string,
      platform_id: string,
      subscriber_id: number,
      mrr: string,
      status: subscription_status_enum,
      created_at: string,
      updated_at: string,
      subscriber_logs: Array<{
        __typename: 'subscriber_log',
        trial: boolean
      }>,
      subscription_properties: Array<{
        __typename: 'subscription_property',
        value: any,
        property: {
          __typename: 'property',
          id: number,
          key: string,
          token: string,
          entity: property_entity_enum,
          type: property_type_enum,
          format?: property_format_enum | null,
          name: string,
          created_at: string
        }
      }>,
      subscriber: {
        __typename: 'subscriber',
        token: string
      }
    },
    flow: {
      __typename: 'flow',
      id: number,
      token: string,
      title: string,
      created_at: string,
      updated_at: string
    },
    offer?: {
      __typename: 'offer',
      id: number,
      token: string,
      type: offer_type_enum,
      goal: offer_goal_enum,
      name: string,
      metadata: any,
      created_at: string,
      updated_at: string,
      offer_coupon?: {
        __typename: 'offer_coupon',
        platform_id?: string | null,
        type: offer_coupon_type_enum,
        amount_off: string,
        duration: coupon_duration_enum,
        months?: number | null,
        apply_to: offer_coupon_apply_to_enum
      } | null,
      offer_change_plan?: {
        __typename: 'offer_change_plan',
        platform_plan_id?: string | null,
        prorate: boolean,
        offer_coupon_offer?: {
          __typename: 'offer',
          offer_coupon?: {
            __typename: 'offer_coupon',
            platform_id?: string | null
          } | null
        } | null
      } | null,
      offer_modify_subscription?: {
        __typename: 'offer_modify_subscription',
        prorate: boolean,
        offer_modify_subscription_options: Array<{
          __typename: 'offer_modify_subscription_option',
          type: offer_modify_subscription_option_type_enum,
          platform_plan_id?: string | null,
          platform_addon_id?: string | null,
          custom_plan_id?: string | null
        }>
      } | null,
      offer_trial_extension?: {
        __typename: 'offer_trial_extension',
        days: number
      } | null,
      offer_pause_subscription?: {
        __typename: 'offer_pause_subscription',
        pause_at: offer_pause_subscription_pause_at_enum,
        offer_pause_subscription_options: Array<{
          __typename: 'offer_pause_subscription_option',
          id: number,
          interval?: offer_pause_subscription_interval_enum | null,
          interval_count?: number | null,
          metadata: any
        }>
      } | null
    } | null,
    subscriber_flow_question_answers: Array<{
      __typename: 'subscriber_flow_question_answer',
      question_answer: {
        __typename: 'question_answer',
        question: {
          __typename: 'question',
          id: number,
          token: string,
          type: question_type_enum,
          primary: boolean,
          question_settings: Array<{
            __typename: 'question_setting',
            key: question_setting_key_enum,
            published_version?: {
              __typename: 'question_setting_version',
              value: any
            } | null
          }>
        },
        question_version: {
          __typename: 'question_version',
          id: number,
          title_translation?: {
            __typename: 'translation',
            id: number,
            translation_values: Array<{
              __typename: 'translation_value',
              translation_id: number,
              language: language_enum,
              value: any,
              format?: translation_value_format_enum | null
            }>
          } | null
        },
        question_answer_text?: {
          __typename: 'question_answer_text',
          value: string,
          sentiment?: sentiment_enum | null
        } | null,
        question_answer_radios: Array<{
          __typename: 'question_answer_radio',
          question_option_id: number,
          question_option_version: {
            __typename: 'question_option_version',
            id: number,
            title_translation: {
              __typename: 'translation',
              id: number,
              translation_values: Array<{
                __typename: 'translation_value',
                translation_id: number,
                language: language_enum,
                value: any,
                format?: translation_value_format_enum | null
              }>
            }
          },
          question_option: {
            __typename: 'question_option',
            id: number,
            token: string,
            reason_code?: string | null
          }
        }>,
        question_answer_likelihood_to_return?: {
          __typename: 'question_answer_likelihood_to_return',
          value: number
        } | null
      }
    }>,
    subscriber_flow_offers: Array<{
      __typename: 'subscriber_flow_offer',
      status?: subscriber_flow_offer_status_enum | null,
      rescheduled_to?: string | null,
      offer: {
        __typename: 'offer',
        id: number,
        token: string,
        type: offer_type_enum,
        goal: offer_goal_enum,
        name: string,
        metadata: any,
        created_at: string,
        updated_at: string,
        offer_coupon?: {
          __typename: 'offer_coupon',
          platform_id?: string | null,
          type: offer_coupon_type_enum,
          amount_off: string,
          duration: coupon_duration_enum,
          months?: number | null,
          apply_to: offer_coupon_apply_to_enum
        } | null,
        offer_change_plan?: {
          __typename: 'offer_change_plan',
          platform_plan_id?: string | null,
          prorate: boolean,
          offer_coupon_offer?: {
            __typename: 'offer',
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null
            } | null
          } | null
        } | null,
        offer_modify_subscription?: {
          __typename: 'offer_modify_subscription',
          prorate: boolean,
          offer_modify_subscription_options: Array<{
            __typename: 'offer_modify_subscription_option',
            type: offer_modify_subscription_option_type_enum,
            platform_plan_id?: string | null,
            platform_addon_id?: string | null,
            custom_plan_id?: string | null
          }>
        } | null,
        offer_trial_extension?: {
          __typename: 'offer_trial_extension',
          days: number
        } | null,
        offer_pause_subscription?: {
          __typename: 'offer_pause_subscription',
          pause_at: offer_pause_subscription_pause_at_enum,
          offer_pause_subscription_options: Array<{
            __typename: 'offer_pause_subscription_option',
            id: number,
            interval?: offer_pause_subscription_interval_enum | null,
            interval_count?: number | null,
            metadata: any
          }>
        } | null
      },
      offer_pause_subscription_option?: {
        __typename: 'offer_pause_subscription_option',
        id: number,
        interval?: offer_pause_subscription_interval_enum | null,
        interval_count?: number | null
      } | null
    }>,
    subscriber_flow_offer_groups: Array<{
      __typename: 'subscriber_flow_offer_group',
      status?: subscriber_flow_offer_status_enum | null,
      offer_group: {
        __typename: 'offer_group',
        id: number,
        token: string,
        goal: offer_goal_enum,
        name: string,
        created_at: string,
        updated_at: string,
        offer_group_offers: Array<{
          __typename: 'offer_group_offer',
          position?: number | null,
          offer?: {
            __typename: 'offer',
            id: number,
            token: string,
            type: offer_type_enum,
            goal: offer_goal_enum,
            name: string,
            metadata: any,
            created_at: string,
            updated_at: string,
            offer_coupon?: {
              __typename: 'offer_coupon',
              platform_id?: string | null,
              type: offer_coupon_type_enum,
              amount_off: string,
              duration: coupon_duration_enum,
              months?: number | null,
              apply_to: offer_coupon_apply_to_enum
            } | null,
            offer_change_plan?: {
              __typename: 'offer_change_plan',
              platform_plan_id?: string | null,
              prorate: boolean,
              offer_coupon_offer?: {
                __typename: 'offer',
                offer_coupon?: {
                  __typename: 'offer_coupon',
                  platform_id?: string | null
                } | null
              } | null
            } | null,
            offer_modify_subscription?: {
              __typename: 'offer_modify_subscription',
              prorate: boolean,
              offer_modify_subscription_options: Array<{
                __typename: 'offer_modify_subscription_option',
                type: offer_modify_subscription_option_type_enum,
                platform_plan_id?: string | null,
                platform_addon_id?: string | null,
                custom_plan_id?: string | null
              }>
            } | null,
            offer_trial_extension?: {
              __typename: 'offer_trial_extension',
              days: number
            } | null,
            offer_pause_subscription?: {
              __typename: 'offer_pause_subscription',
              pause_at: offer_pause_subscription_pause_at_enum,
              offer_pause_subscription_options: Array<{
                __typename: 'offer_pause_subscription_option',
                id: number,
                interval?: offer_pause_subscription_interval_enum | null,
                interval_count?: number | null,
                metadata: any
              }>
            } | null
          } | null
        }>
      }
    }>,
    subscriber_flow_deflections: Array<{
      __typename: 'subscriber_flow_deflection',
      deflection: {
        __typename: 'deflection',
        id: number,
        token: string,
        title: string,
        deflection_flow_actions: Array<{
          __typename: 'deflection_flow_action',
          flow_action?: {
            __typename: 'flow_action',
            id: number,
            type: flow_action_type_enum,
            token: string,
            code?: string | null,
            appearance: flow_action_appearance_enum,
            url?: string | null,
            reroute_to_flow?: {
              __typename: 'flow',
              token: string
            } | null
          } | null
        }>
      },
      clicked_flow_action?: {
        __typename: 'flow_action',
        id: number,
        type: flow_action_type_enum,
        token: string,
        code?: string | null,
        appearance: flow_action_appearance_enum,
        url?: string | null,
        reroute_to_flow?: {
          __typename: 'flow',
          token: string
        } | null
      } | null
    }>,
    subscriber_flow_flow_steps: Array<{
      __typename: 'subscriber_flow_flow_step',
      started_at: string,
      completed_at?: string | null,
      flow_step: {
        __typename: 'flow_step',
        id: number,
        token: string,
        type: flow_step_type_enum,
        flow_step_form?: {
          __typename: 'flow_step_form',
          form: {
            __typename: 'form',
            title: string
          }
        } | null,
        flow_step_question?: {
          __typename: 'flow_step_question',
          question: {
            __typename: 'question',
            published_version?: {
              __typename: 'question_version',
              title_translation?: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              } | null
            } | null
          }
        } | null,
        flow_step_deflection_rule_group?: {
          __typename: 'flow_step_deflection_rule_group',
          flow_step_id?: number | null,
          offer_rule_group_id?: number | null,
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_acknowledgement_group?: {
          __typename: 'flow_step_acknowledgement_group',
          acknowledgement_group: {
            __typename: 'acknowledgement_group',
            published_version?: {
              __typename: 'acknowledgement_group_version',
              title_translation: {
                __typename: 'translation',
                id: number,
                translation_values: Array<{
                  __typename: 'translation_value',
                  translation_id: number,
                  language: language_enum,
                  value: any,
                  format?: translation_value_format_enum | null
                }>
              }
            } | null
          }
        } | null,
        flow_step_offer_rule_group?: {
          __typename: 'flow_step_offer_rule_group',
          offer_rule_group?: {
            __typename: 'offer_rule_group',
            title: string
          } | null
        } | null,
        flow_step_intervention?: {
          __typename: 'flow_step_intervention',
          intervention: {
            __typename: 'intervention',
            title: string
          }
        } | null
      }
    }>,
    rerouted_from_subscriber_flow?: {
      __typename: 'subscriber_flow',
      id: number,
      token: string
    } | null
  } | null,
  user_by_pk?: {
    __typename: 'user',
    email?: string | null,
    user_profiles: Array<{
      __typename: 'user_profile',
      email: string
    }>
  } | null
};

export type SyncHubSpotFindSubscribersByPlatformIdQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platformId: Scalars['String']['input'];
}>;


export type SyncHubSpotFindSubscribersByPlatformIdQuery = {
  subscriber: Array<{
    __typename: 'subscriber',
    id: number,
    subscriber_flows: Array<{
      __typename: 'subscriber_flow',
      id: number,
      status: subscriber_flow_status_enum,
      completed_at?: string | null,
      subscriber_flow_question_answers: Array<{
        __typename: 'subscriber_flow_question_answer',
        question_answer: {
          __typename: 'question_answer',
          question_id: number,
          question_answer_text?: {
            __typename: 'question_answer_text',
            value: string
          } | null,
          question_answer_radios: Array<{
            __typename: 'question_answer_radio',
            question_option: {
              __typename: 'question_option',
              reason_code?: string | null,
              published_version?: {
                __typename: 'question_option_version',
                title_translation: {
                  __typename: 'translation',
                  id: number,
                  translation_values: Array<{
                    __typename: 'translation_value',
                    translation_id: number,
                    language: language_enum,
                    value: any,
                    format?: translation_value_format_enum | null
                  }>
                }
              } | null
            }
          }>,
          question_answer_likelihood_to_return?: {
            __typename: 'question_answer_likelihood_to_return',
            value: number
          } | null
        }
      }>
    }>
  }>
};

export type SyncHubSpotIntegrationQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SyncHubSpotIntegrationQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    account_id: number,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum,
        name: string
      }>
    }
  } | null
};

export type SyncHubSpotSetSubscriberHubSpotContactIdMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  set: subscriber_set_input;
}>;


export type SyncHubSpotSetSubscriberHubSpotContactIdMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SyncHubSpotUpdateIntegrationMetaMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncHubSpotUpdateIntegrationMetaMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type SyncIntercomIntegrationQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SyncIntercomIntegrationQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    account_id: number,
    credentials: any,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum,
        name: string
      }>
    }
  } | null
};

export type SyncIntercomSetSubscriberIntercomContactIdMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  intercomContactId: Scalars['String']['input'];
}>;


export type SyncIntercomSetSubscriberIntercomContactIdMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SyncIntercomUpdateIntegrationMetaMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncIntercomUpdateIntegrationMetaMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type SyncKlaviyoInsertSegmentMutationVariables = Exact<{
  objects: Array<segment_insert_input> | segment_insert_input;
}>;


export type SyncKlaviyoInsertSegmentMutation = {
  insert_segment?: {
    __typename: 'segment_mutation_response',
    affected_rows: number
  } | null
};

export type SyncKlaviyoIntegrationQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SyncKlaviyoIntegrationQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      properties: Array<{
        __typename: 'property',
        id: number,
        key: string,
        type: property_type_enum,
        name: string
      }>
    }
  } | null
};

export type SyncKlaviyoSetSubscriberKlaviyoProfileIdMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  klaviyoProfileId: Scalars['String']['input'];
}>;


export type SyncKlaviyoSetSubscriberKlaviyoProfileIdMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SyncKlaviyoUpdateIntegrationMetaMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncKlaviyoUpdateIntegrationMetaMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type SyncPlatformMetadataCustomersQueryVariables = Exact<{
  where: platform_customer_bool_exp;
  offset: Scalars['Int']['input'];
}>;


export type SyncPlatformMetadataCustomersQuery = {
  platform_customer: Array<{
    __typename: 'platform_customer',
    platform_id: string,
    metadata?: any | null,
    subscriber?: {
      __typename: 'subscriber',
      id: number
    } | null
  }>
};

export type SyncPlatformMetadataSubscriptionsQueryVariables = Exact<{
  where: platform_subscription_bool_exp;
  offset: Scalars['Int']['input'];
}>;


export type SyncPlatformMetadataSubscriptionsQuery = {
  platform_subscription: Array<{
    __typename: 'platform_subscription',
    platform_id: string,
    metadata?: any | null,
    subscription?: {
      __typename: 'subscription',
      id: number
    } | null
  }>
};

export type SyncPlatformMetadataConnectionQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type SyncPlatformMetadataConnectionQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    account_id: number,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      properties: Array<{
        __typename: 'property',
        id: number,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        key: string,
        token: string,
        created_at: string
      }>
    }
  } | null
};

export type SyncPlatformMetadataUpdateSyncedMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncPlatformMetadataUpdateSyncedMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type SyncRecurlyCustomFieldsCustomersQueryVariables = Exact<{
  where: platform_customer_bool_exp;
  offset: Scalars['Int']['input'];
}>;


export type SyncRecurlyCustomFieldsCustomersQuery = {
  platform_customer: Array<{
    __typename: 'platform_customer',
    platform_id: string,
    metadata?: any | null,
    subscriber?: {
      __typename: 'subscriber',
      id: number
    } | null
  }>
};

export type SyncRecurlyCustomFieldsSubscriptionsQueryVariables = Exact<{
  where: platform_subscription_bool_exp;
  offset: Scalars['Int']['input'];
}>;


export type SyncRecurlyCustomFieldsSubscriptionsQuery = {
  platform_subscription: Array<{
    __typename: 'platform_subscription',
    platform_id: string,
    metadata?: any | null,
    subscription?: {
      __typename: 'subscription',
      id: number
    } | null
  }>
};

export type SyncRecurlyCustomFieldsConnectionQueryVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
}>;


export type SyncRecurlyCustomFieldsConnectionQuery = {
  platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number,
    account_id: number,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      properties: Array<{
        __typename: 'property',
        id: number,
        entity: property_entity_enum,
        type: property_type_enum,
        format?: property_format_enum | null,
        name: string,
        key: string,
        token: string,
        created_at: string
      }>
    }
  } | null
};

export type SyncRecurlyCustomFieldsUpdateSyncedMutationVariables = Exact<{
  platformConnectionId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncRecurlyCustomFieldsUpdateSyncedMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type SyncSalesforceIntegrationQueryVariables = Exact<{
  integrationId: Scalars['Int']['input'];
}>;


export type SyncSalesforceIntegrationQuery = {
  integration_by_pk?: {
    __typename: 'integration',
    id: number,
    type: integration_type_enum,
    account_id: number,
    credentials: any,
    options: any,
    meta: any,
    account: {
      __typename: 'account',
      id: number,
      status: account_status_enum,
      properties: Array<{
        __typename: 'property',
        id: number,
        type: property_type_enum,
        name: string
      }>
    }
  } | null
};

export type SyncSalesforceSetSubscriberSalesforceContactIdMutationVariables = Exact<{
  subscriberId: Scalars['Int']['input'];
  salesforceContactId: Scalars['String']['input'];
}>;


export type SyncSalesforceSetSubscriberSalesforceContactIdMutation = {
  update_subscriber_by_pk?: {
    __typename: 'subscriber',
    id: number
  } | null
};

export type SyncSalesforceUpdateIntegrationMetaMutationVariables = Exact<{
  integrationId: Scalars['Int']['input'];
  meta: Scalars['jsonb']['input'];
}>;


export type SyncSalesforceUpdateIntegrationMetaMutation = {
  update_integration_by_pk?: {
    __typename: 'integration',
    id: number
  } | null
};

export type UpdateExchangeRatesMutationVariables = Exact<{
  objects: Array<platform_currency_exchange_rate_insert_input> | platform_currency_exchange_rate_insert_input;
}>;


export type UpdateExchangeRatesMutation = {
  insert_platform_currency_exchange_rate?: {
    __typename: 'platform_currency_exchange_rate_mutation_response',
    affected_rows: number
  } | null
};

export type EncryptPlatformConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type EncryptPlatformConnectionsQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number,
    data: any
  }>,
  platform_credentials: Array<{
    __typename: 'platform_credentials',
    account_id: number,
    platform: platform_enum,
    data: any
  }>
};

export type EncryptPlatformConnectionsMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: Scalars['String']['input'];
}>;


export type EncryptPlatformConnectionsMutation = {
  update_platform_connection_by_pk?: {
    __typename: 'platform_connection',
    id: number
  } | null
};

export type EncryptPlatformConnectionsCredentialsMutationVariables = Exact<{
  accountId: Scalars['Int']['input'];
  platform: platform_enum;
  data: Scalars['String']['input'];
}>;


export type EncryptPlatformConnectionsCredentialsMutation = {
  update_platform_credentials_by_pk?: {
    __typename: 'platform_credentials',
    account_id: number,
    platform: platform_enum
  } | null
};

export type ScriptLogMutationVariables = Exact<{
  object: script_log_insert_input;
}>;


export type ScriptLogMutation = {
  insert_script_log_one?: {
    __typename: 'script_log',
    id: number
  } | null
};

export type SegmentAccountDetailsQueryVariables = Exact<{
  accountId: Scalars['Int']['input'];
}>;


export type SegmentAccountDetailsQuery = {
  account_setting_by_pk?: {
    __typename: 'account_setting',
    value: any
  } | null,
  offer: Array<{
    __typename: 'offer',
    id: number
  }>
};

export type TestSetupCreateOfferRuleMutationVariables = Exact<{
  object: offer_rule_version_insert_input;
}>;


export type TestSetupCreateOfferRuleMutation = {
  insert_offer_rule_version_one?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number
  } | null
};

export type TestSetupCreateOfferRuleRulesMutationVariables = Exact<{
  object: offer_rule_rule_insert_input;
}>;


export type TestSetupCreateOfferRuleRulesMutation = {
  insert_offer_rule_rule_one?: {
    __typename: 'offer_rule_rule',
    id: number
  } | null
};

export type TestSetupCreateOfferRuleSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
  offerRuleGroupVersionId: Scalars['Int']['input'];
  appendRuleId: Scalars['jsonb']['input'];
  appendRuleRuleId: Scalars['jsonb']['input'];
}>;


export type TestSetupCreateOfferRuleSetVersionMutation = {
  update_offer_rule_version_by_pk?: {
    __typename: 'offer_rule_version',
    id: number,
    offer_rule_id: number
  } | null,
  update_offer_rule_by_pk?: {
    __typename: 'offer_rule',
    id: number
  } | null,
  update_offer_rule_group_version_by_pk?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number
  } | null
};

export type TestSetupCreateAcknowledgementGroupMutationVariables = Exact<{
  object: acknowledgement_group_version_insert_input;
}>;


export type TestSetupCreateAcknowledgementGroupMutation = {
  insert_acknowledgement_group_version_one?: {
    __typename: 'acknowledgement_group_version',
    id: number,
    acknowledgement_group_id: number
  } | null
};

export type TestSetupCreateAcknowledgementsMutationVariables = Exact<{
  objects: Array<acknowledgement_version_insert_input> | acknowledgement_version_insert_input;
}>;


export type TestSetupCreateAcknowledgementsMutation = {
  insert_acknowledgement_version?: {
    __typename: 'acknowledgement_version_mutation_response',
    returning: Array<{
      __typename: 'acknowledgement_version',
      id: number,
      acknowledgement_id: number
    }>
  } | null
};

export type TestSetupCreateAcknowledgementGroupSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
  acknowledgementIds: Scalars['jsonb']['input'];
}>;


export type TestSetupCreateAcknowledgementGroupSetVersionMutation = {
  update_acknowledgement_group_by_pk?: {
    __typename: 'acknowledgement_group',
    id: number
  } | null,
  update_acknowledgement_group_version_by_pk?: {
    __typename: 'acknowledgement_group_version',
    id: number
  } | null
};

export type TestSetupCreateFlowStepMutationVariables = Exact<{
  object: flow_step_version_insert_input;
}>;


export type TestSetupCreateFlowStepMutation = {
  insert_flow_step_version_one?: {
    __typename: 'flow_step_version',
    id: number,
    flow_step_id: number
  } | null
};

export type TestSetupSetFlowStepVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type TestSetupSetFlowStepVersionMutation = {
  update_flow_step_by_pk?: {
    __typename: 'flow_step',
    id: number
  } | null
};

export type TestSetupCreateFlowStepsSetStepIdsMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  stepIds: Scalars['jsonb']['input'];
}>;


export type TestSetupCreateFlowStepsSetStepIdsMutation = {
  update_flow_version_by_pk?: {
    __typename: 'flow_version',
    id: number
  } | null
};

export type TestSetupCreateFormMutationVariables = Exact<{
  object: form_insert_input;
}>;


export type TestSetupCreateFormMutation = {
  insert_form_one?: {
    __typename: 'form',
    id: number,
    form_versions: Array<{
      __typename: 'form_version',
      id: number
    }>
  } | null
};

export type TestSetupSetFormPublishedVersionMutationVariables = Exact<{
  formId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type TestSetupSetFormPublishedVersionMutation = {
  update_form_by_pk?: {
    __typename: 'form',
    id: number
  } | null
};

export type TestSetupCreateInterventionMutationVariables = Exact<{
  object: intervention_version_insert_input;
}>;


export type TestSetupCreateInterventionMutation = {
  insert_intervention_version_one?: {
    __typename: 'intervention_version',
    id: number,
    intervention_id: number
  } | null
};

export type TestSetupCreateOfferMutationVariables = Exact<{
  object: offer_insert_input;
}>;


export type TestSetupCreateOfferMutation = {
  insert_offer_one?: {
    __typename: 'offer',
    id: number
  } | null
};

export type TestSetupCreateOfferGroupMutationVariables = Exact<{
  object: offer_group_insert_input;
}>;


export type TestSetupCreateOfferGroupMutation = {
  insert_offer_group_one?: {
    __typename: 'offer_group',
    id: number
  } | null
};

export type TestSetupCreateQuestionMutationVariables = Exact<{
  object: question_insert_input;
}>;


export type TestSetupCreateQuestionMutation = {
  insert_question_one?: {
    __typename: 'question',
    id: number,
    question_versions: Array<{
      __typename: 'question_version',
      id: number
    }>
  } | null
};

export type TestSetupCreateQuestionOptionMutationVariables = Exact<{
  object: question_option_insert_input;
}>;


export type TestSetupCreateQuestionOptionMutation = {
  insert_question_option_one?: {
    __typename: 'question_option',
    id: number,
    question_option_versions: Array<{
      __typename: 'question_option_version',
      id: number
    }>
  } | null
};

export type TestSetupSetQuestionOptionPublishedVersionMutationVariables = Exact<{
  optionId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type TestSetupSetQuestionOptionPublishedVersionMutation = {
  update_question_option_by_pk?: {
    __typename: 'question_option',
    id: number
  } | null
};

export type TestSetupSetQuestionVersionOptionIdsMutationVariables = Exact<{
  versionId: Scalars['Int']['input'];
  optionIds?: InputMaybe<Scalars['jsonb']['input']>;
}>;


export type TestSetupSetQuestionVersionOptionIdsMutation = {
  update_question_version_by_pk?: {
    __typename: 'question_version',
    id: number
  } | null
};

export type TestSetupSetQuestionPublishedVersionMutationVariables = Exact<{
  questionId: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type TestSetupSetQuestionPublishedVersionMutation = {
  update_question_by_pk?: {
    __typename: 'question',
    id: number
  } | null
};

export type TestSetupCreateOfferRuleGroupMutationVariables = Exact<{
  object: offer_rule_group_version_insert_input;
}>;


export type TestSetupCreateOfferRuleGroupMutation = {
  insert_offer_rule_group_version_one?: {
    __typename: 'offer_rule_group_version',
    id: number,
    offer_rule_group_id: number
  } | null
};

export type TestSetupCreateOfferRuleGroupSetVersionMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  versionId: Scalars['Int']['input'];
}>;


export type TestSetupCreateOfferRuleGroupSetVersionMutation = {
  update_offer_rule_group_by_pk?: {
    __typename: 'offer_rule_group',
    id: number
  } | null
};

export type TestSetupCreateSimpleSegmentFromTextPropertyMutationVariables = Exact<{
  object: segment_insert_input;
}>;


export type TestSetupCreateSimpleSegmentFromTextPropertyMutation = {
  insert_segment_one?: {
    __typename: 'segment',
    id: number
  } | null
};

export type TestSetupCreateTextPropertyMutationVariables = Exact<{
  object: property_insert_input;
}>;


export type TestSetupCreateTextPropertyMutation = {
  insert_property_one?: {
    __typename: 'property',
    id: number
  } | null
};

export type CustomerSubscriptionDeletedAccountMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['String']['input'];
}>;


export type CustomerSubscriptionDeletedAccountMutation = {
  update_account?: {
    __typename: 'account_mutation_response',
    affected_rows: number
  } | null
};

export type CustomerSubscriptionUpdatedAccountMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['String']['input'];
  status: account_status_enum;
}>;


export type CustomerSubscriptionUpdatedAccountMutation = {
  update_account?: {
    __typename: 'account_mutation_response',
    affected_rows: number
  } | null
};

export type WebhooksStripePlatformConnectionQueryVariables = Exact<{
  stripeAccountId: Scalars['String']['input'];
  liveMode: Scalars['Boolean']['input'];
}>;


export type WebhooksStripePlatformConnectionQuery = {
  platform_connection: Array<{
    __typename: 'platform_connection',
    id: number
  }>
};

export type WebhooksProsperStackPlatformConnectionQueryVariables = Exact<{ [key: string]: never; }>;


export type WebhooksProsperStackPlatformConnectionQuery = {
  account: Array<{
    __typename: 'account',
    platform_connection?: {
      __typename: 'platform_connection',
      id: number
    } | null
  }>
};

export type PaymentMethodAttachedQueryVariables = Exact<{
  stripeCustomerId: Scalars['String']['input'];
}>;


export type PaymentMethodAttachedQuery = {
  account: Array<{
    __typename: 'account',
    id: number
  }>
};
